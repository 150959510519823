import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FPAbstractComponent } from '@fp/components/base';
import { CommonString } from '@fp/constant';
import { Facility, Member, SearchPaginationRequest } from '@fp/models';
import { FacilityService, MemberService, CommonService } from '@fp/services';

@Component({
    selector: 'app-facilitator-facility-members',
    templateUrl: './facilitator-facility-members.component.html',
    styleUrls: ['./facilitator-facility-members.component.css']
})
export class FacilitatorFacilityMembersComponent extends FPAbstractComponent implements OnInit {
    displayedColumns: string[] = ['last_name', 'first_name', 'MemberStatus', 'MembershipStatus', 'MemberNumber', 'MemberId', 'PhotoLocation'];
    dataSource: MatTableDataSource<Member>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('txtFacilityMembersFilter') txtFacilityMembersFilter: ElementRef;
    parentFocus = 0;
    availableFacilities: Array<Facility> = [];
    availableFacilitiesCtrl: UntypedFormControl = new UntypedFormControl();
    isSelectedFacility: boolean = true;
    requiresInput: boolean = false;
    facilityMembers: Array<Member> = [];
    facilityIds: string = '';
    commonString = CommonString;

    length: number;
    pageSize: number;
    pageSizeOptions: number[] = [10, 20, 50];


    paging: SearchPaginationRequest = new SearchPaginationRequest();

    constructor(
        injector: Injector,
        private service: FacilityService,
        private memberService: MemberService,
        private comsvc: CommonService) {
        super(injector);
        this.dataSource = new MatTableDataSource();
    }

    ngOnInit(): void {
        this.requiresInput = false;
        this.loadAvailableFacilities();
    }

    txtFilterFacilityMembers_KeyUp(e: KeyboardEvent) {
        if (e.keyCode === 13) {
            if(this.txtFacilityMembersFilter.nativeElement.value.length > 0) {
                this.requiresInput = false;
                e.preventDefault();
                this.searchFacilityMembers();
            } else {
                this.requiresInput = true;
            }
        }
    }

    getParamsSearch() {
        // const valFaci = this.availableFacilitiesCtrl.value;
        // const searchRequest: SearchPaginationRequest = {
        //     OrderBy: 'first_name',
        //     OrderByAsc: true,
        //     PageNumber: PageNumber || 1,
        //     PageSize: PageSize || 10,
        //     Params: [
        //         {
        //             Name: 'facility_ids',
        //             Value: valFaci == null ? this.facilityIds : valFaci.toString(),
        //         }, {
        //             Name: 'name_or_id',
        //             Value: this.txtFacilityMembersFilter.nativeElement.value
        //         }
        //     ]
        // };

        // return this.paging;
    }

    gotoSearchFacilityMembers() {
        if(this.txtFacilityMembersFilter.nativeElement.value.length > 0) {
            this.requiresInput = false;
            this.searchFacilityMembers();
        } else {
            this.requiresInput = true;
        }
    }

    searchFacilityMembers() {
        this.paging.PageNumber = 1;
        this.loadFacilityMembers();
    }

    loadFacilityMembers() {
        // const searchRequest = this.getParamsSearch();
        const valFaci = this.availableFacilitiesCtrl.value;
        this.paging.Params = [
            {
                Name: 'facility_ids',
                Value: valFaci == null ? this.facilityIds : valFaci.toString(),
            }, {
                Name: 'name_or_id',
                Value: this.txtFacilityMembersFilter.nativeElement.value
            }
        ];
        this.paging.ViewColumns = [];
        this.Invoke(
            this.memberService.searchfacilitymemberpaging(this.paging),
            {
                onSuccess: (result: any) => {
                    if (result.Success) {
                        this.facilityMembers = result.Data.Results;
                        this.dataSource.data = this.facilityMembers;
                        this.length = result.Data.TotalItem;
                    } else {
                        this.HandleResponseError(result);
                    }
                }
            }
        );
    }

    paginatorChange(event: PageEvent) {
        this.paging.PageNumber = event.pageIndex + 1;
        this.paging.PageSize = event.pageSize;
        this.loadFacilityMembers()
    }

    sortChange(sort: Sort) {
        const activeSort: string = sort.active.toLowerCase();
        this.paging.OrderBy = activeSort;
        this.paging.OrderByAsc = sort.direction === 'asc' ? false : true;
        this.loadFacilityMembers();
    }

    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }
    onBlurParent(focusNumbr) {
        this.parentFocus = focusNumbr;
    }
    getClassFocused(vlFocused) {
        if (this.parentFocus == vlFocused) {
            return "focused";
        } else {
            return "";
        }
    }
    loadAvailableFacilities() {
        const searchRequest: SearchPaginationRequest = {
            OrderBy: 'name',
            OrderByAsc: true,
            PageNumber: 0,
            PageSize: 0,
            Params: [
                {
                    Name: 'user',
                    Value: this.comsvc.GetUserObject().UserId
                }
            ]
        };
        this.Invoke(
            this.service.search(searchRequest),
            {
                onSuccess: (result: any) => {
                    const dectypted_data = this.comsvc.D_FP_ResponseData(result);
                    result = JSON.parse(dectypted_data);
                    if (result.Success) {
                        this.availableFacilities = result.Data.Results;
                        this.availableFacilities.forEach((element, index, array) => {
                            if (index === (array.length - 1)) {
                                this.facilityIds += element.FacilityId;
                            } else {
                                this.facilityIds += element.FacilityId + ',';
                            }
                        }, this);
                    } else {
                        this.HandleResponseError(result);
                    }
                }
            }
        );
    }
    getStatus(status: String) {
        switch (status) {
            case 'Active':
                return 'text-success';
            case 'Inactive':
                return 'text-muted';
            case 'Pending':
                return 'text-warning';
            case 'Suspended':
                return 'text-danger';
        }
    }
}
