import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { fetchAuthSession } from 'aws-amplify/auth';
import * as CryptoJS from 'crypto-js';
import { Observable, from as observableFrom } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpDAO {
  private headers: HttpHeaders;
  private headerwithouttoken: HttpHeaders;
  private headerwithouttokenexternal: HttpHeaders;
  private token: string;
  private logedin = false;

  constructor(private http: HttpClient) {
    this.setHeader();
  }

  private async setHeader(path?: string) {
    // this.token = 'Bearer ' + localStorage.getItem('accessToken');

    let decryptedToken = '';

    if (this.token == null || this.token == undefined) {
      const session = await fetchAuthSession();
      if (!session?.tokens?.idToken) {
        this.token = localStorage.getItem('accessToken');
      } else {
        this.token = this.EncryptAES256(session.tokens.idToken.toString());
      }
    }

    if (this.token != null && this.token != undefined) {
      const jwt: JwtHelperService = new JwtHelperService();
      decryptedToken = this.DecryptAES256(this.token);
      let expired = jwt.isTokenExpired(decryptedToken);
      if (expired) {
        await this.refreshSession();
      }
    }
    const user = this.DecryptAES256ForShortString(localStorage.getItem('user'));
    if (user == 'null' || user == undefined || user == null) {
      this.logedin = false;
    } else {
      this.logedin = true;
    }
    this.headers = new HttpHeaders()
      .set('Authorization', decryptedToken)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');
  }

  private async refreshSession() {
    const user = this.DecryptAES256ForShortString(localStorage.getItem('user'));
    if (user == 'null' || user == undefined || user == null) {
      return;
    }

    // New Refresh using Amplify
    const session = await fetchAuthSession();
    if (session?.tokens?.idToken) {
      this.token = this.EncryptAES256(session.tokens.idToken.toString());
      return;
    }
  }

  public GetRefreshTokenDecrypt(): string {
    let token = localStorage.getItem('refreshToken');
    token = this.DecryptAES256(token);
    return token;
  }

  private DecryptAES256(encrypttext: string): string {
    const decrypted = CryptoJS.AES.decrypt(
      encrypttext,
      environment.NCKXXL,
    ).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }

  private EncryptAES256(plaintext: string) {
    const encrypted = CryptoJS.AES.encrypt(
      plaintext,
      environment.NCKXXL,
    ).toString();
    return encrypted;
  }

  public EncryptAES256ForShortString(plaintext: string) {
    plaintext = plaintext + '###ABC###ABC###090773668824444';
    const encrypted = CryptoJS.AES.encrypt(
      plaintext,
      environment.NCKXXL,
    ).toString();
    return encrypted;
  }

  public DecryptAES256ForShortString(encrypttext: string) {
    try {
      let encrypted: string = CryptoJS.AES.decrypt(
        encrypttext,
        environment.NCKXXL,
      ).toString(CryptoJS.enc.Utf8);
      encrypted = encrypted.replace('###ABC###ABC###090773668824444', '');
      return encrypted;
    } catch (exception) {
      return null;
    }
  }

  private setHeaderWithoutToken() {
    this.headerwithouttoken = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');
  }

  private setHeaderWithoutTokenWithUrlEncodedData() {
    this.headerwithouttokenexternal = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Accept', '*/*');
  }

  getData(apifunctionpath: string): Observable<any[]> {
    return observableFrom(this.setHeader()).pipe(
      concatMap(() => {
        if (!this.logedin) {
          return null;
        }
        const href = environment.baseURL + apifunctionpath;
        const data = this.http.get<any[]>(href, { headers: this.headers });
        return data;
      }),
    );
  }

  getBlob(apifunctionpath: string): Observable<Blob> {
    return observableFrom(this.setHeader()).pipe(
      concatMap(() => {
        if (!this.logedin) {
          return null;
        }
        const href = environment.baseURL + apifunctionpath;
        const data = this.http.get(href, { headers: this.headers, responseType: "blob" });
        return data;
      }),
    );
  }

  getSingleData(apifunctionpath: string): Observable<any> {
    return observableFrom(this.setHeader(apifunctionpath)).pipe(
      concatMap(() => {
        if (!this.logedin) {
          return null;
        }
        const ticks1 =
          '&version=' +
          (new Date().getTime() * 10000 + 621355968000000000).toString();
        const ticks2 =
          '?version=' +
          (new Date().getTime() * 10000 + 621355968000000000).toString();

        const href =
          environment.baseURL +
          apifunctionpath +
          (apifunctionpath.indexOf('?') > -1 ? ticks1 : ticks2);
        const data = this.http.get<any>(href, { headers: this.headers });
        return data;
      }),
    );
  }

  postData(apifunctionpath: string, datainput: any): Observable<any> {
    return observableFrom(this.setHeader()).pipe(
      concatMap(() => {
        if (!this.logedin) {
          return null;
        }
        const href = environment.baseURL + apifunctionpath;
        const dataoutput = this.http.post<any>(href, datainput, {
          headers: this.headers,
        });
        return dataoutput;
      }),
    );
  }

  putData(apifunctionpath: string, datainput: any): Observable<any> {
    return observableFrom(this.setHeader()).pipe(
      concatMap(() => {
        if (!this.logedin) {
          return null;
        }
        const req = new HttpRequest('PUT', apifunctionpath, datainput, {
          headers: this.headers,
          reportProgress: true, //This is required for track upload process
        });
        const dataoutput = this.http.request<any>(req);
        return dataoutput;
      }),
    );
  }

  putDataWithoutToken(
    apifunctionpath: string,
    datainput: any,
  ): Observable<any> {
    return observableFrom(this.setHeader()).pipe(
      concatMap(() => {
        if (!this.logedin) {
          return null;
        }
        /**
         * Note: Headers for request must match the generated presigned URL from backend, otherwise you get a `SignatureDoesNotMatch` error from AWS.
         * see: https://docs.aws.amazon.com/AmazonS3/latest/userguide/UsingMetadata.html?icmpid=docs_amazons3_console#object-metadata
         *  */
        const headers = new HttpHeaders().set('x-amz-acl', 'public-read');
        const req = new HttpRequest('PUT', apifunctionpath, datainput, {
          headers,
          reportProgress: true, //This is required for track upload process
        });

        const dataoutput = this.http.request<any>(req);
        return dataoutput;
      }),
    );
  }

  getDataWithoutToken(apifunctionpath: string): Observable<any[]> {
    this.setHeaderWithoutToken();
    const href = environment.baseURL + apifunctionpath;
    const data = this.http.get<any[]>(href, {
      headers: this.headerwithouttoken,
    });
    return data;
  }

  getSingleDataWithoutToken(apifunctionpath: string): Observable<any> {
    this.setHeaderWithoutToken();
    const href = environment.baseURL + apifunctionpath;
    const data = this.http.get<any>(href, { headers: this.headerwithouttoken });
    return data;
  }

  getActiveUniqueCityName(apifunctionpath: string): Observable<any> {
    return observableFrom(this.setHeader()).pipe(
      concatMap(() => {
        if (!this.logedin) {
          return null;
        }
        const href = environment.baseURL + apifunctionpath;
        const data = this.http.get<any>(href, { headers: this.headers });
        return data;
      }),
    );
  }

  postDataWithoutToken(
    apifunctionpath: string,
    datainput: any,
  ): Observable<any> {
    this.setHeaderWithoutToken();
    const href = environment.baseURL + apifunctionpath;
    const dataoutput = this.http.post<any>(href, datainput, {
      headers: this.headerwithouttoken,
    });
    return dataoutput;
  }

  postDataWithoutTokenWithUrlEncodedData(
    apifunctionpath: string,
    datainput: any,
  ): Observable<any> {
    this.setHeaderWithoutTokenWithUrlEncodedData();
    const href = apifunctionpath;
    const dataoutput = this.http.post<any>(href, datainput.toString(), {
      headers: this.headerwithouttokenexternal,
    });
    return dataoutput;
  }

  getRemoteFileData(urlref: string): Observable<any> {
    const data = this.http.get(urlref, { responseType: 'text' });
    return data;
  }
}