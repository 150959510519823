import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewChecked, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { MemberFormBase } from 'src/app/components/base';
import { MemberEmployerInfoComponent, MemberMembershipPackagesComponent, MemberPaymentDetailsComponent } from 'src/app/components/members/shared';
import { CommonMessage, CommonString, RouterConstant, StorageKey } from 'src/app/constant';
import { StringHelper, Utils } from 'src/app/helpers';
import { BankAccount, DataResult, Member, MemberSearchResultRecord, Membership, ResultModel, SearchPaginationResult, User } from 'src/app/models';
import { MessageBox, MessageBoxButton } from 'src/app/services/common-dialog.service';
import { CommonService } from 'src/app/services/common.service';
import { EmployerService } from 'src/app/services/employer.service';
import { MemberService } from 'src/app/services/member.service';
import { DialogResult } from '../../common-dialog/common-dialog.component';
import { EMemberType, EMemberStatus } from '@fp/enums';
import { MemberContainer } from '@fp/models/member-container.model';

enum tabIndex {
    EmployerDetailsTab,
    MemberShipPackagesTab,
    PaymentDetailsTab
}

@Component({
    selector: 'app-employer-transfer',
    templateUrl: './employer-transfer.component.html',
    styleUrls: ['./employer-transfer.component.css'],
    providers: [{ provide: MemberFormBase, useExisting: EmployerTransferComponent }]
})
export class EmployerTransferComponent extends MemberFormBase implements OnInit, AfterViewChecked {
    heighttabs: string;
    CommonMessage = CommonMessage;
    CommonString = CommonString;
    StringHelper = StringHelper;
    @ViewChild('stepper', {static: true}) stepper: MatStepper;
    @ViewChild('tabfpstepper', {static: true}) tabfpstepper: ElementRef;
    @ViewChild('membershipPackages') membershipPackages: MemberMembershipPackagesComponent;
    @ViewChild('employerInfo') employerInfo: MemberEmployerInfoComponent;
    @ViewChild('paymentDetails') paymentDetails: MemberPaymentDetailsComponent;
    RouterConstant = RouterConstant;
    isNextToMemberShipPackages: boolean = false;
    hasLoaded: boolean = false;

    displayed2Columns: string[] = ['PackageName', 'Members', 'Price'];
    data2Source = [
        { PackageName: 'Ultra Package Family', Members: 'Member Type, First Name, Last Name', Price: '$27.95' },
        { PackageName: '', Members: 'Member Type, First Name, Last Name', Price: '' },
        { PackageName: '', Members: 'Member Type, First Name, Last Name', Price: '$17.55' }
    ];

    tabIndex = tabIndex;

    constructor(
        injector: Injector,
        private commonservice: CommonService,
        private memberSvc: MemberService,
        private employerSvc: EmployerService,
        private router: Router
    ) {
        super(injector);
        this.form = EmployerTransferComponent.getFormGroup();
    }

    ngAfterViewInit() {
        this.OnInit();
    }

    OnInit() {
        //Author: Trinh
        this.commonservice.Header.title = 'Transfer to New Employer';
        super.OnInit();
        this.paymentDetails.OnInit();
        if(!this.hasLoaded) {
            this.hasLoaded = true;
            MessageBox.ShowCustom(this.dialog, '', '', '<p align="center">Please provide your new employee/payroll number and select your new employer</p>').subscribe(() => {
                this.loadMemberDetails();
            });
            this.LoadComplete();
        }
    }

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            EmployerInfo: MemberEmployerInfoComponent.getFormGroup(),
            MembershipPackages: MemberMembershipPackagesComponent.getFormGroup(),
            PaymentDetails: MemberPaymentDetailsComponent.getFormGroup(false)
        });
    }

    ngAfterViewChecked() {
        if (!this.commonservice.App.mobileQuery.matches) {
            this.heighttabs = this.tabfpstepper
                .nativeElement
                .querySelector('#membrsignuptb' + this.stepper.selectedIndex)
                .clientHeight + 'px';
        } else {
            this.heighttabs = '100%';
        }
    }

    currentUser: any;
    currentMember: DataResult<Member>;
    oldEmployerId: number;
    loadMemberDetails() {
        this.currentUser = <User>JSON.parse(this.commonservice.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT)));
        const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
        if(getContainer != null) {
            let container = <MemberContainer>JSON.parse(getContainer);
            this.currentMember = <DataResult<Member>>JSON.parse(this.commonSvc.D_FP_AES256(container.Member));
        }

        if (this.currentUser && this.currentUser.UserId > 0) {
            if(this.currentMember != null) {
                this.onGetMemberSuccess(this.currentMember);
            }
            else {
                this.Invoke(
                    this.memberSvc.getByUserForMember(this.currentUser.UserId),
                    {
                        onSuccess: (res: DataResult<Member>) => {
                            this.onGetMemberSuccess(res);
                        }
                    }
                );
            }
        } else {
            MessageBox.ShowError(this.dialog, 'Invalid user');
        }
    }

    onGetMemberSuccess(member: DataResult<Member>) {
        if (member.Success) {
            if (member.Data == null) {
                MessageBox.ShowError(this.dialog, 'Member info not found for current user').subscribe(() => {
                    this.router.navigate([RouterConstant.ROUTER_DASHBOARD]);
                });

            } else {
                if (member.Data != null) {
                    this.data.model = member.Data;
                    this.data.model.EmployeePayrollNo = '';
                    this.data.model.Employer = null;
                    this.oldEmployerId = member.Data.EmployerId;
                    this.data.model.EmployerId = 0;
                    this.data.model.Memberships = [];
                    this.data.model.FamilyMembers = this.sortFamilyMembers(this.data.model.FamilyMembers);
                }

                //add validation for employer
                const oldValidator = this.getControl(['EmployerInfo', 'EmployerId']).validator;
                this.getControl(['EmployerInfo', 'EmployerId']).setValidators([oldValidator, (ctrl: AbstractControl): ValidationErrors => {
                    if (ctrl.value != this.oldEmployerId) {
                        return null;
                    }
                    return { notEquals: ctrl.value };
                }]);

                try {
                    if (this.data.model.EmployerId > 0
                        && this.data.model.Memberships &&
                        this.data.model.Memberships[0]) {
                        this.data.model.Memberships[0].MembershipPackage = <any>{
                            EmployerId: this.data.model.EmployerId
                        };
                    }
                    this.PatchValue(this.data.model, { emitEvent: false });
                } catch (err) {
                    console.error(err);
                }
                this.form.markAsPristine();
                super.OnLoad();
            }
        } else {
            this.HandleResponseError(member);
        }
    }

    sortFamilyMembers(members: Array<Member>) {
        const partners: Array<Member> = members.filter(m => m.MemberTypeId == 2).sort((a, b) => a.FirstName.toLocaleLowerCase() > b.FirstName.toLocaleLowerCase() ? 1 : -1);
        const dependents: Array<Member> = members.filter(m => m.MemberTypeId == 3).sort((a, b) => a.FirstName.toLocaleLowerCase() > b.FirstName.toLocaleLowerCase() ? 1 : -1);
        return partners.concat(dependents);
    }

    employerInfoTabValidityCtrl = new UntypedFormControl(false, Validators.requiredTrue);

    LoadComplete() {
        super.LoadComplete();
        setTimeout(() => {
            this.stepper.selectionChange.subscribe(
                (value: StepperSelectionEvent) => {
                    if (value.selectedIndex === tabIndex.MemberShipPackagesTab) {
                        if (this.membershipPackages.loaded) {
                            this.membershipPackages.RefreshMembershipPackages();
                        }
                    }
                }
            );
        });

        this.stepper._stepHeader.forEach((header, i) => {
            header._getHostElement().onclick = (e) => {
                if (this.stepper.selectedIndex === tabIndex.EmployerDetailsTab && i === tabIndex.MemberShipPackagesTab) {
                    this.btnNext_Click();
                }
                if (this.stepper.selectedIndex === tabIndex.MemberShipPackagesTab && i === tabIndex.PaymentDetailsTab) {
                    if (!this.membershipPackages.valid) {
                        MessageBox.ShowCustom(this.dialog, 'Warning', 'Warning', 'Please choose 1 membership package above');
                    }
                }
            };
        });
    }

    backEmployerInfo() {
        this.stepper.previous();
    }

    stepperPreviousIndex: number;
    onStepChange(e: StepperSelectionEvent) {
        this.stepperPreviousIndex = e.previouslySelectedIndex;
        const index = e.selectedIndex;

        if (index === tabIndex.EmployerDetailsTab) {
            this.employerInfoTabValidityCtrl.setValue(false);
            this.employerInfoTabValidityCtrl.updateValueAndValidity();
        }

        if (e.selectedIndex > e.previouslySelectedIndex) {
            this.applyStepValue(e.previouslySelectedIndex);
            this.PatchValue(this.data.model, { emitEvent: false });
        }
        const com = this.children.toArray()[index];
        if (com && !com.loaded) {
            com.Load();
        }
    }

    btnNext_Click(nextButton?: boolean) {
        switch (this.stepper.selectedIndex) {
            case tabIndex.EmployerDetailsTab:
                this.employerInfo.Validate();
                if (this.employerInfo.valid) {
                    MessageBox.ShowCustom(this.dialog, 'Warning', 'Warning',
                        'You have prompted a transfer to a new employer. \
                        Please proceed to the Membership Packages tab and select a new package as per your new employer. \
                        Please note that the available facilities may change depending on your package. Do you wish to continue?',
                        MessageBoxButton.YesNo).subscribe(r => {
                            if (r.result == DialogResult.No) {
                                this.router.navigate([RouterConstant.NAVIGATOR_MEMBER_DETAILS]);
                                this.commonSvc.App.clickItem(9);
                            } else {
                                this.employerInfoTabValidityCtrl.setValue(true);
                                this.employerInfoTabValidityCtrl.updateValueAndValidity();
                                this.stepper.next();
                            }
                        });
                }
                break;
            case tabIndex.MemberShipPackagesTab:
                this.membershipPackages.Validate();
                if (!this.membershipPackages.valid) {
                    MessageBox.ShowCustom(this.dialog, 'Warning', 'Warning', 'Please choose 1 membership package above')
                }
                else if (this.stepperPreviousIndex != tabIndex.PaymentDetailsTab || nextButton) {
                    this.stepper.next();
                }
                break;
            case tabIndex.PaymentDetailsTab:
                this.paymentDetails.Validate();
                break;
        }



    }

    backToLandingPage() {
        this.router.navigate([RouterConstant.NAVIGATOR_MEMBER_DETAILS]);
        this.commonSvc.App.clickItem(9)
    }

    submit() {
        this.paymentDetails.Validate();
        if (!this.form.valid)
            return;
        const memberTransfer: IMemberTransfer = {
            MemberId: this.data.model.MemberId,
            NewEmployerId: this.getControl(['EmployerInfo', 'EmployerId']).value,
            EmployeePayrollNo: this.getControl(['EmployerInfo', 'EmployeePayrollNo']).value,
            MembershipPackageId: (<Membership>(this.getControl(['MembershipPackages', 'Memberships']).value[0])).MembershipPackage.MembershipPackageId,
            BankAccountId: this.getControl(['PaymentDetails', 'BankAccountSection', 'BankAccount']).value.BankAccountId,
            BankAccount: this.getControl(['PaymentDetails', 'BankAccountSection', 'BankAccount']).value,
            ModifiedBy: this.commonservice.GetUser()
        };
        const famObj: SearchPaginationResult<MemberSearchResultRecord> = this.commonservice.GetMemFamObj();
        if(famObj != null) {
              if(famObj.Results[0] != null) {
                if(famObj.Results[0].MembershipStatus != null) {
                     if(famObj.Results[0].MembershipStatus.Id == 3) {
                        MessageBox.ShowOKCustom(
                            this.dialog,
                            `Information`,
                            `Your current suspension from your previous membership will not be transferred to your new membership. Please reapply your suspension dates once your new membership has been approved.`
                          ).subscribe((dialogResult) => {
                                this.continueSubmit(memberTransfer);
                          });
                    } else {
                        this.continueSubmit(memberTransfer);
                    }
                } else {
                    this.continueSubmit(memberTransfer);
                }
            } else {
                this.continueSubmit(memberTransfer);
            }
        } else {
            this.continueSubmit(memberTransfer);
        }
    }

    continueSubmit(memberTransfer: IMemberTransfer) {
        this.Invoke(
            this.memberSvc.transferMember(memberTransfer),
            {
                onSuccess: (result: ResultModel) => {
                    if (result.Success) {
                        const currentUser = <User>JSON.parse(this.commonservice.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
                        this.memberSvc
                        .getByUserForMember(currentUser.UserId)
                        .subscribe((getMember) => {
                            const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
                            if (getContainer != null) {
                                let container = <MemberContainer>JSON.parse(getContainer);
                                let memberEncrypt = this.commonservice.E_FP_AES256(
                                    JSON.stringify(getMember)
                                );
                                container.Member = memberEncrypt;
                                const loginData = JSON.stringify(container);
                                localStorage.setItem(
                                    StorageKey.CONTAINER_OBJECT,
                                    loginData
                                );
                            }
                            if (getMember.Data.FamilyObject) {
                            this.commonservice.SetMemFamObj(
                                getMember.Data.FamilyObject
                            );
                            }
                        });
                        this.router.navigate([RouterConstant.NAVIGATOR_MEMBER_TRANSFER_COMPLETE]);
                    } else {
                        MessageBox.ShowCustom(this.dialog, 'Warning', 'Warning', 'Something went wrong');
                    }
                }
            }
        );
    }

    applyStepValue(index: number) {
        switch (index) {
            case tabIndex.EmployerDetailsTab:
                this.data.model = this.applyValue(this.data.model, this.employerInfo);
                break;
            case tabIndex.MemberShipPackagesTab:
                this.data.model = this.applyValue(this.data.model, this.membershipPackages);
                break;
            case tabIndex.PaymentDetailsTab:
                this.data.model = this.applyValue(this.data.model, this.paymentDetails);
                break;
        }
    }
}

export interface IMemberTransfer {
    MemberId: number;
    NewEmployerId: number;
    EmployeePayrollNo: string;
    MembershipPackageId: number;
    BankAccountId: number;
    BankAccount: BankAccount;
    ModifiedBy: string;
}
