<div [formGroup]="form">
    <ng-template #requiredFieldMsgTmpl let-ctrl="control" let-ctrlName="fieldName" let-validateOnTouch="validateOnTouch || false">
        <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
            <small *ngIf="ctrl.errors['required']" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_REQUIRED, ctrlName)}}</small>
        </div>
    </ng-template>
    <div formGroupName="BankAccount" *ngIf="form.get('BankAccount').value != null">

        <div class="form-group row">
            <label for="txtBankName" class="col-md-3 col-lg-2 col-form-label">Bank Name<span class="text-danger">*</span></label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <input type="text" class="form-control" [class.is-invalid]="!isControlValid(form.get('BankAccount.BankName'))"
                        id="txtBankName" formControlName="BankName" maxlength="200">
                    <ng-container *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('BankAccount.BankName'), fieldName: 'Bank Name'}"></ng-container>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label for="txtAccName" class="col-md-3 col-lg-2 col-form-label">Account Name<span class="text-danger">*</span></label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <input type="text" class="form-control" [class.is-invalid]="!isControlValid(form.get('BankAccount.BankAccountName'))"
                        id="txtAccName" formControlName="BankAccountName" maxlength="200">
                    <ng-container *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('BankAccount.BankAccountName'), fieldName: 'Account Name'}"></ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="txtBsb" class="col-md-3 col-lg-2 col-form-label">{{BankCodeLable}} <span class="text-danger">*</span></label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <input type="text" class="form-control" [class.is-invalid]="!isControlValid(form.get('BankAccount.BankAccountBsb'))"
                        id="txtBsb" formControlName="BankAccountBsb" maxlength="6" numberOnly  (input)=trimBSB()>
                    <ng-container *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('BankAccount.BankAccountBsb'), fieldName: isNZFac?'Bank Code':'BSB'}"></ng-container>
                </div>
            </div>
        </div>
       
        <div class="form-group row">
            <label for="txtAccNumber" class="col-md-3 col-lg-2 col-form-label">Account Number<span class="text-danger">*</span></label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <input type="text" class="form-control" [class.is-invalid]="!isControlValid(form.get('BankAccount.BankAccountNumber'))"
                        id="txtAccNumber" formControlName="BankAccountNumber" maxlength="12" numberOnly (input)=trimBankAccountNum()>
                    <ng-container *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('BankAccount.BankAccountNumber'), fieldName: 'Account Number'}"></ng-container>
                </div>
            </div>
        </div>
        
        <div *ngIf="isNZFac" class="form-group row">
            <label for="txtAccType" class="col-md-3 col-lg-2 col-form-label">Account Type/Suffix<span class="text-danger">*</span></label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <input type="text" class="form-control" [class.is-invalid]="!isControlValid(form.get('BankAccount.BankAccountType'))"
                        id="txtAccType" formControlName="BankAccountType" maxlength="3" numberOnly (input)=trimBankAccountType()>
                    <ng-container *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('BankAccount.BankAccountType'), fieldName: 'Account Type/Suffix'}"></ng-container>
                </div>
            </div>
        </div>
        
    </div>
</div>