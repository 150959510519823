import {
    Component,
    OnInit,
    ChangeDetectorRef,
    ElementRef,
    OnDestroy,
} from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    AbstractControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { MatStepper } from "@angular/material/stepper";
import { ViewChild } from "@angular/core";
import { PatternConstant } from "../../../constant/patternconstant";
import { HttpDAO } from "../../../services/httpdao.service";
import { HttpClient } from "@angular/common/http";
import { APIConstant } from "../../../constant/apiconstant";
import { UserService } from "../../../services/admin/user/user.service";
import {
    UserCreate, CommonResponse, UserEmployer, AssignFacilitatorEmployer
} from '../../../models';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { MessageBox } from 'src/app/services/common-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { RouterConstant } from 'src/app/constant/routerconstant';
import { CommonService } from 'src/app/services/common.service';
import { CommonString } from 'src/app/constant/common-string';
import { merge, empty, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, finalize } from 'rxjs/operators';
import { CommonConstants } from '@fp/constant/common-constants';

@Component({
    selector: 'app-addusers',
    templateUrl: './addusers.component.html',
    styleUrls: ['./addusers.component.css']
})
export class AddusersComponent implements OnInit, OnDestroy {
    btnNextClicked = false;
    btnNextClickedRoles = false;
    tabHeight: string;

    userDetailsFormGroup: UntypedFormGroup;
    rolesFormGroup: UntypedFormGroup;

    isEmployerFacilitator = false;
    isFacilityStaffMember = false;

    roles: CommonResponse[] = [];
    titles: CommonResponse[];
    genders: CommonResponse[];
    httpDAO: HttpDAO | null;

    roleTypesSelected: CommonResponse[] = [];
    roleTypesSelectedId: Array<number> = [];
    username: string;

    //Role: facility
    searchFacilities: CommonResponse[] = [];
    facilitiesSelected: CommonResponse[] = [];
    facilitiesSelectedId: Array<number> = [];

    //Role: employer facilitator
    searchFacilitators: UserEmployer[] = [];
    facilitatorsSelected: UserEmployer[] = [];
    assignFacilitatorEmployers: AssignFacilitatorEmployer[] = [];

    presentTime: NgbDateStruct;
    minDate: NgbDateStruct;
    facilitySpinner: object = { isBool: false };
    facilitatorSpinner: object = { isBool: false };

    invalidPhone: boolean = false;
    mobileLength: number = 10;
    mailSpinner: boolean = false;
    phoneSpinner: boolean = false;
    loadingUsername: boolean = false;

    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('stepperWrapperRef') stepperWrapperRef: ElementRef;

    commonString = CommonString;

    parentFocus: number = 0;

    statuses: CommonResponse[] = [
        {
            Id: 1,
            Name: 'Active'
        },
        {
            Id: 2,
            Name: 'Inactive'
        }
    ];
    control(name: string): AbstractControl {
        return this.userDetailsFormGroup.get(name);
    }

    ngOnDestroy() {
        this.tabHeight = null;
        this.roles = null;
        this.titles = null;
        this.genders = null;
        this.httpDAO = null;
        this.roleTypesSelected = null;
        this.roleTypesSelectedId = null;
        this.username = null;
        this.searchFacilities = null;
        this.facilitiesSelected = null;
        this.facilitiesSelectedId = null;
        this.searchFacilitators = null;
        this.facilitatorsSelected = null;
        this.assignFacilitatorEmployers = null;
        this.presentTime = null;
        this.minDate = null;
        this.mobileLength = null;
    }

    constructor(private _formBuilder: UntypedFormBuilder,
        private commonService: CommonService,
        private userService: UserService,
        private router: Router,
        private dialog: MatDialog,
        private http: HttpClient,
        private cdRef: ChangeDetectorRef,
        private ngbCalendar: NgbCalendar
    ) {
    }

    ngOnInit() {
        this.titles = CommonConstants.GET_TITLES;
        this.genders = CommonConstants.GET_GENDER_NAMES;
        this.createUserDetailsForm();
        this.createRolesForm();
        this.httpDAO = new HttpDAO(this.http);

        this.rolesFormGroup.get('addFacilitator').valueChanges.subscribe(
            data => {
                if ((typeof data) == 'undefined') {
                    return;
                }
                this.setSearchFacilitators(data);
            }
        );

        this.rolesFormGroup.get('addFacility').valueChanges.subscribe(
            data => {
                if ((typeof data) == 'undefined') {
                    return;
                }
                this.setSearchFacilities(data);
            }
        );
    }

    ngAfterViewChecked() {

        if (!this.commonService.App.mobileQuery.matches) {

            this.tabHeight = this.stepperWrapperRef
                .nativeElement
                .querySelector('#userroletb' + this.stepper.selectedIndex)
                .clientHeight + 'px';
        }
        else {
            this.tabHeight = '100%';
        }
        this.cdRef.detectChanges();
    }

    requestQueueCount: number = 0;
    invoke(source: Observable<any>, handleResultCallback: Function, mapCallback: Function = () => { }) {
        this.requestQueueCount++;
        this.commonService.StartProgressBar();
        source.pipe(
            catchError((e) => { throw e; }),
            finalize(() => {
                this.requestQueueCount--;
                if (this.requestQueueCount <= 0) {
                    this.commonService.StopProgressBar();
                    this.requestQueueCount = 0;
                }
            }),
            map(data => {
                return mapCallback(data);
            }),
        )
            .subscribe(
                res => {
                    handleResultCallback(res);
                },
                err => {
                    console.log(err);
                }
            );
    }

    loadRoles() {
        this.invoke(
            this.userService.getRoles(false),
            (data: CommonResponse[]) => {
                if (data == null)
                    return;
                this.roles = data;
            },
            (data: CommonResponse[]) => {
                return data;
            }
        );
    }

    loadTitles() {
        this.invoke(
            this.userService.getTitles(),
            (data: CommonResponse[]) => {
                if (data == null)
                    return;
                this.titles = data;
            },
            (data) => {
                return data;
            }
        );
    }

    loadGenders() {
        this.invoke(
            this.userService.getGenders(),
            (data: CommonResponse[]) => {
                if (data == null)
                    return;
                this.genders = data;
            },
            (data) => {
                return data;
            }
        );
    }

    public stepClick(event) {
        if (event.selectedIndex == 1) {
            if (this.roles.length == 0)
                this.loadRoles();
        } else if (event.selectedIndex == 2) {
            this.generateUsername();
        }
    }

    public btnNextRoles_Click() {
        this.btnNextClicked = true; // trigger the validate
    }

    public btnNextAccount_Click() {
        this.btnNextClickedRoles = true; // trigger the validate
    }

    createUserDetailsForm() {
        this.userDetailsFormGroup = this._formBuilder.group({
            slcTitle: [],
            txtFirstName: ['', [
                Validators.required,
                Validators.pattern(PatternConstant.VALID_NAME)
            ]],
            txtMiddleName: [''],
            txtLastName: ['', [
                Validators.required,
                Validators.pattern(PatternConstant.VALID_NAME)
            ]],
            txtPreferredName: [''],
            dtpDateOfBirth: [],
            slcGender: [],
            slcStatus: [, Validators.required],
            txtMobileNumber: [''],
            txtEmailAddress: ['', [
                Validators.required,
                Validators.pattern(PatternConstant.EMAIL)
            ]],
        });

        const today = this.ngbCalendar.getToday();
        this.minDate = this.ngbCalendar.getPrev(today, 'y', 100);
        this.presentTime = today;
    }

    createRolesForm() {
        this.rolesFormGroup = this._formBuilder.group({
            slcRole: [0],
            RolesId: [[], Validators.required, Validators.length > 0],
            FacilitiesId: [[]],
            FacilitatorsId: [[]],

            addFacilitator: [],
            addFacility: []
        });
    }

    public ValidateUserDetailsSection(isBool: boolean = false): boolean {
        if (isBool == true)
            return true;
        if (
            (
                this.userDetailsFormGroup.get('txtFirstName').valid &&
                this.userDetailsFormGroup.get('txtLastName').valid &&
                this.userDetailsFormGroup.get('slcStatus').valid
            ) &&
            !(
                this.userDetailsFormGroup.get('dtpDateOfBirth').value != '' &&
                this.userDetailsFormGroup.get('dtpDateOfBirth').invalid &&
                (
                    this.userDetailsFormGroup.get('dtpDateOfBirth').dirty ||
                    this.userDetailsFormGroup.get('dtpDateOfBirth').touched
                )
            )
        )
            return true;
        else
            return false;
    }

    public ValidateContactSection(isBool: boolean = false): boolean {
        if (isBool == true)
            return true;
        if (this.userDetailsFormGroup.get('txtMobileNumber').valid &&
            this.userDetailsFormGroup.get('txtEmailAddress').valid &&
            !this.phoneSpinner && !this.mailSpinner)
            return true;
        else
            return false;
    }

    public ChangeTab(index) {
        if (!this.phoneSpinner && !this.mailSpinner) {
            this.stepper.selectedIndex = index;
        }
    }

    setLinear() {
        this.rolesFormGroup.get('RolesId').setValue(this.roleTypesSelected);
        this.rolesFormGroup.get('FacilitiesId').setValue(this.facilitiesSelected);
        this.rolesFormGroup.get('FacilitatorsId').setValue(this.facilitatorsSelected);
        return true;
    }

    setValidateRolesSelected(name: string) {
        //set validator for a role
        if (name.toLowerCase().includes('facility')) {
            this.rolesFormGroup.get('FacilitiesId').setValidators([Validators.required]);
            this.rolesFormGroup.get('FacilitiesId').setValidators.length > 0;
        }
        else if (name.toLowerCase().includes('facilitator')) {
            this.rolesFormGroup.get('FacilitatorsId').setValidators([Validators.required]);
            this.rolesFormGroup.get('FacilitatorsId').setValidators.length > 0;
        }
    }

    unsetValidateRolesSelected(role: CommonResponse) {
        //set default role details
        if (role.Name.toLowerCase().includes('facility')) {
            this.searchFacilities = [];
            this.facilitiesSelected = [];
            this.rolesFormGroup.get('FacilitiesId').clearValidators();
        }
        if (role.Name.toLowerCase().includes('facilitator')) {
            this.searchFacilitators = [];
            this.facilitatorsSelected = [];
            this.rolesFormGroup.get('FacilitatorsId').clearValidators();
        }
        //End. set default role details
    }

    selectRole(id: number) {
        if (id === 0 || id == null || id == undefined)
            return;
        let currentRole = this.roles.find(rt => rt.Id === id);
        this.roleTypesSelected.push(currentRole);
        this.rolesFormGroup.get('slcRole').setValue(0);

        if (this.commonService.GetSelectedRole() === 'Admin') {
            this.setValidateRolesSelected(currentRole.Name);
        } else {
            if (currentRole.Name === 'Admin' || currentRole.Name === 'Privileged Reports Access') {
                let roleToUnset: CommonResponse;
                roleToUnset = this.roleTypesSelected.find(rt => rt.Name === currentRole.Name);
                this.unsetValidateRolesSelected(roleToUnset);
                this.userService.removeItemFromArray(this.roleTypesSelected, roleToUnset);
                MessageBox.ShowInfo(this.dialog, "Sorry, a non admin role cannot create an user with admin or privileged reports access roles.");

            } else {
                this.setValidateRolesSelected(currentRole.Name);
            }
        }
    }

    setRoleDisabled(role: CommonResponse) {
        let isBool = this.roleTypesSelected.some(r => r.Id === role.Id);
        return isBool;
    }

    unsetRoleSelected(role: CommonResponse) {
        this.userService.removeItemFromArray(this.roleTypesSelected, role);
        this.unsetValidateRolesSelected(role);
    }

    showRoleDetails(roleName: string) {
        let isBool: boolean = false;
        isBool = this.roleTypesSelected.some(rt => rt.Name.toLowerCase().includes(roleName));
        return isBool;
    }

    displayWith(item?: any): string | undefined {
        return item ? item.Name : undefined;
    }
    //Role Facility Staff Member
    setSearchFacilities(text) {
        if (text.length < 3) {
            this.searchFacilities.splice(0, this.searchFacilities.length);
        }
        else if (text.length > 2)
            this.userService.getData(
                APIConstant.API_FIND_FACILITIES_BY_NAME + text,
                (data) => {
                    this.userService.removeArray2InArray1(data, this.facilitiesSelected, 'Id');
                    this.searchFacilities = data;
                },
                () => { },
                this.facilitySpinner
            );
    }

    setFacilitiesSelected(common: CommonResponse) {
        this.facilitiesSelected.push(common);
        this.userService.removeItemFromArray(this.searchFacilities, common);
    }
    unsetFacilitiesSelected(common: CommonResponse) {
        this.searchFacilities.push(common);
        this.userService.removeItemFromArray(this.facilitiesSelected, common);
        this.searchFacilities.sort((a, b) => a.Id > b.Id ? 1 : -1);
    }

    //Role Employer Facilitator
    setSearchFacilitators(text) {
        if (text.length < 3) {
            this.searchFacilitators.splice(0, this.searchFacilitators.length);
        }
        else if (text.length > 2)
            this.userService.getData(
                APIConstant.API_FIND_EMPLOYERS_BY_NAME + text + '&includecode=true',
                (data) => {
                    this.userService.removeArray2InArray1(data, this.facilitatorsSelected, 'EmployerId');
                    this.searchFacilitators = data;
                },
                () => { },
                this.facilitatorSpinner
            );
    }

    setFacilitatorsSelected(employer: UserEmployer) {
        this.facilitatorsSelected.push(employer);
        this.userService.removeItemFromArray(this.searchFacilitators, employer);
    }
    unsetFacilitatorsSelected(employer: UserEmployer) {
        this.searchFacilitators.push(employer);
        this.userService.removeItemFromArray(this.facilitatorsSelected, employer);
        this.searchFacilitators.sort((a, b) => a.EmployerId > b.EmployerId ? 1 : -1);
    }

    setPrimaryFacilitator(popover: NgbPopover, f: any, event) {
        let x = f.PrimaryFacilitatorId;
        if (x > 1) {
            event.target.checked = false;
            if (popover.isOpen()) {
                popover.close();
            }
            popover.open();
        }
        else {
            if (event.target.checked == true)
                f.PrimaryFacilitatorId = 1;
            else
                f.PrimaryFacilitatorId = 0;
            console.log(f.PrimaryFacilitatorId);
        }
    }

    disableAllForm() {
        this.userDetailsFormGroup.disable();
        this.rolesFormGroup.disable();
        this.stepper.selectedIndex = 0;
        this.ValidateUserDetailsSection(true);
        this.ValidateContactSection(true);
    }

    checkExistPhone() {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                let number = '';
                if (this.mobileLength == 10) {
                    number = this.userDetailsFormGroup.get('txtMobileNumber').value.replace(0, '');
                } else {
                    number = this.userDetailsFormGroup.get('txtMobileNumber').value;
                }
                if (number.length > 0 && this.userDetailsFormGroup.get("txtMobileNumber").valid && !this.invalidPhone) {
                    this.phoneSpinner = true;
                    this.userDetailsFormGroup.setErrors({ waitingcheck: true });
                    return this.userService.checkExistPhonev2('+61' + number);
                } else {
                    this.phoneSpinner = false;
                }
                return empty();
            }),
            catchError((e) => { throw e; }))
            .subscribe(
                (result) => {
                    const encryptedData = result.Data;
                    const decryptedData = this.commonService.D_FP_ResponseData(encryptedData);
                    const ret = JSON.parse(decryptedData);
                    this.phoneSpinner = false;
                    this.userDetailsFormGroup.setErrors({ waitingcheck: false });
                    this.userDetailsFormGroup.updateValueAndValidity();
                    if (result.Success) {
                        if (result.Data !== null && ret.IsExist) {
                            this.userDetailsFormGroup.get("txtMobileNumber").setErrors({ duplicated: true });
                        } else {
                            this.userDetailsFormGroup.get("txtMobileNumber").setErrors(null);
                        }
                    } else {
                        MessageBox.ShowError(this.dialog, 'An error has occurred. Please contact the administrator');
                    }
                });
    }

    checkExistEmail() {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                let newName = this.userDetailsFormGroup.get("txtEmailAddress").value.trim();
                if (newName.length > 0 && this.userDetailsFormGroup.get("txtEmailAddress").valid) {
                    this.mailSpinner = true;
                    this.userDetailsFormGroup.get("txtEmailAddress").setErrors({ waitingcheck: true });
                    return this.userService.checkExistEmailv2(newName);
                } else {
                    this.mailSpinner = false;
                }
                return empty();
            }),
            catchError((e) => { throw e; }))
            .subscribe(
                (result) => {
                    const encryptedData = result.Data;
                    const decryptedData = this.commonService.D_FP_ResponseData(encryptedData);
                    const ret = JSON.parse(decryptedData);
                    this.mailSpinner = false;
                    this.userDetailsFormGroup.get("txtEmailAddress").setErrors({ waitingcheck: false });
                    this.userDetailsFormGroup.updateValueAndValidity();
                    if (result.Success) {
                        if (result.Data !== null && ret.IsExist) {
                            this.userDetailsFormGroup.get("txtEmailAddress").setErrors({ duplicated: true });
                        } else {
                            this.userDetailsFormGroup.get("txtEmailAddress").setErrors(null);
                        }
                    } else {
                        MessageBox.ShowError(this.dialog, 'An error has occurred. Please contact the administrator');
                    }
                });
    }

    private checkValidatePhone() {
        if (this.userDetailsFormGroup.get('txtMobileNumber').value.indexOf('00') == 0) {
            this.invalidPhone = true;
        } else {
            this.invalidPhone = false;
        }
    }

    public txtNumber(event) {
        const number = this.userDetailsFormGroup.get('txtMobileNumber').value.indexOf('0');
        this.checkValidatePhone();
        if (number != 0) {
            this.mobileLength = 9;
        } else {
            this.mobileLength = 10;
            this.checkValidatePhone();
        }
        let mobileNumberValue = event.target.value;
        const re = new RegExp(PatternConstant.NOT_NUMBER, 'g');
        mobileNumberValue = mobileNumberValue.replace(re, '');
        this.userDetailsFormGroup.get('txtMobileNumber').setValue(mobileNumberValue);
    }

    createUser() {
        let user = new UserCreate();
        this.roleTypesSelectedId = [];
        this.mobileLength = (<string>this.userDetailsFormGroup.get('txtMobileNumber').value).length;
        let number = '';
        if (this.mobileLength >= 11 && this.mobileLength <= 15) {
            if (this.userDetailsFormGroup.get('txtMobileNumber').value[3] === '0') {
                number = <string>this.userDetailsFormGroup.get('txtMobileNumber').value.slice(0, 3) + <string>this.userDetailsFormGroup.get('txtMobileNumber').value.slice(4);
            }
            else {
                number = <string>this.userDetailsFormGroup.get('txtMobileNumber').value.slice(0, 3) + <string>this.userDetailsFormGroup.get('txtMobileNumber').value.slice(3);
            }
        } else {
            number = this.userDetailsFormGroup.get('txtMobileNumber').value;
        }
        user.UserName = this.username;
        user.TitleId = this.userDetailsFormGroup.get('slcTitle').value;
        user.FirstName = this.userDetailsFormGroup.get('txtFirstName').value;
        user.MiddleName = this.userDetailsFormGroup.get('txtMiddleName').value;
        user.LastName = this.userDetailsFormGroup.get('txtLastName').value;
        user.PreferredName = this.userDetailsFormGroup.get('txtPreferredName').value;
        user.GenderId = this.userDetailsFormGroup.get('slcGender').value;
        user.DateOfBirth = this.userService.format_dd_mm_yyyy_ToString(this.userDetailsFormGroup.get('dtpDateOfBirth').value);

        user.MobileNumber = number;

        user.EmailAddress = this.userDetailsFormGroup.get('txtEmailAddress').value.toLowerCase().trim();
        if (this.userDetailsFormGroup.get('slcStatus').value == 1) {
            user.IsActive = true;
        }
        else if (this.userDetailsFormGroup.get('slcStatus').value == 2) {
            user.IsActive = false;
        }

        this.roleTypesSelected.filter(r => {
            this.roleTypesSelectedId.push(r.Id);
        });
        user.RoleIds = this.roleTypesSelectedId;

        this.facilitatorsSelected.forEach(f => {
            const facilitator: AssignFacilitatorEmployer = {}
            facilitator.EmployerId = f.EmployerId;
            if (f.PrimaryFacilitatorId == 1)
                facilitator.IsPrimaryFacilitator = true;
            else {
                facilitator.IsPrimaryFacilitator = false
            }
            this.assignFacilitatorEmployers.push(facilitator);
        });
        user.AssignFacilitatorEmployers = this.assignFacilitatorEmployers;

        this.facilitiesSelected.forEach(f => {
            this.facilitiesSelectedId.push(f.Id);
        });

        user.FacilityIds = this.facilitiesSelectedId;
        this.userService.postData(
            APIConstant.API_CREATE_USER,
            (data) => {
                if (data.Success == true) {
                    this.router.navigate([RouterConstant.ROUTER_DASHBOARD + '/' + RouterConstant.ROUTER_USER_COMPLETE_ADD]);
                } else {
                    this.userService.checkDataRespone(data, user.UserName).subscribe;
                    this.roleTypesSelectedId = [];
                    this.assignFacilitatorEmployers = [];
                    this.facilitiesSelectedId = [];
                }
            },
            user
        );
    }

    generateUsername() {
        this.loadingUsername = true;
        const firstName = this.userDetailsFormGroup.get('txtFirstName').value.trim().toLowerCase();
        const lastName = this.userDetailsFormGroup.get('txtLastName').value.trim().toLowerCase();
        const apiUrl = APIConstant.API_GENERATE_USERNAME(firstName, lastName);

        merge().pipe(
            startWith({}),
            switchMap(() => {
                this.commonService.StartProgressBar();
                return this.httpDAO!.getSingleData(apiUrl);
            }),
            map(data => {
                if (data.Success) {
                    this.username = data.Data;
                } else {
                    MessageBox.ShowError(this.dialog, 'Something was wrong');
                    console.log('Error: ' + data.Message + ' - Error number: ' + data.ErrorNumber);
                }
                this.commonService.StopProgressBar();
                this.loadingUsername = false;
                return data;
            }),
            catchError(() => {
                this.commonService.StopProgressBar();
                this.loadingUsername = false;
                return observableOf([]);
            }),
        ).subscribe((data) => {
        });
    }

    onFocusParent(blurNumber: number) {
        this.parentFocus = blurNumber;
    }
    onBlurParent(focusNumber: number) {
        this.parentFocus = focusNumber;
    }
    getClassFocused(vlFocused: number) {
        if (this.parentFocus == vlFocused) {
            return "focused";
        } else {
            return "";
        }
    }

}
