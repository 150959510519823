<div class="d-md-none title-wrapper text-center mobile-title-shadow">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>Employer Details</h1>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="employer-wrapper registration-stepper-new form-wrapper-new">
        <div [formGroup]="form" class="register-wrapper--from">
          <div class="form-group row">
            <label for="selectEmployerCountry" class="col-sm-4 col-form-label">Country*</label>
            <div class="col-sm-8">
              <div class="d-flex flex-column w-100">
                <select id="selectEmployerCountry" formControlName="EmployerCountry" class="form-control custom-select"
                  [ngClass]="
                    form.get('EmployerCountry').invalid &&
                    (form.get('EmployerCountry').dirty ||
                      form.get('EmployerCountry').touched ||
                      btnNextSubmit)
                      ? 'is-invalid'
                      : ''
                  " (change)="ddlEmployerCountry_Change()">
                  <option *ngFor="let country of Country" [ngValue]="country">
                    {{ country }}
                  </option>
                </select>
                <div *ngIf="
                    form.get('EmployerCountry').invalid &&
                    (form.get('EmployerCountry').dirty ||
                      form.get('EmployerCountry').touched ||
                      btnNextSubmit)
                  ">
                  <small *ngIf="
                      form.get('EmployerCountry').errors.required
                    " class="invalid-feedback d-block">Country is required</small>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="txtEmployeePayrollNo" class="col-sm-4 col-form-label">Employee/Payroll No.*</label>
            <div class="col-sm-8">
              <div class="d-flex flex-column w-100">
                <input type="text" maxlength="50" [ngClass]="
                    form.get('EmployeePayrollNo').invalid &&
                    (form.get('EmployeePayrollNo').dirty ||
                      form.get('EmployeePayrollNo').touched ||
                      btnNextSubmit)
                      ? 'form-control is-invalid'
                      : 'form-control'
                  " id="txtEmployeePayrollNo" formControlName="EmployeePayrollNo" />
                <div *ngIf="
                    form.get('EmployeePayrollNo').invalid &&
                    (form.get('EmployeePayrollNo').dirty ||
                      form.get('EmployeePayrollNo').touched ||
                      btnNextSubmit)
                  ">
                  <small *ngIf="
                      form.get('EmployeePayrollNo').errors.required
                    " class="invalid-feedback d-block">Employee/Payroll No. is required</small>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isFromAustralia" class="form-group row">
            <label for="ddlEmployerState" class="col-sm-4 col-form-label">Employer State*</label>
            <div class="col-sm-8">
              <div class="d-flex flex-column w-100">
                <select class="form-control custom-select" [class.is-invalid]="!isControlValid(this.StateCtrl, true)"
                  [compareWith]="ddlEmployerStateOption_CompareFn" (change)="ddlEmployerState_Change()"
                  [class.disabled]="this.StateCtrl.disabled" id="ddlEmployerState" formControlName="StateCtrl"
                  [ngClass]="
                  this.StateCtrl.invalid &&
                    (this.StateCtrl.dirty ||
                    this.StateCtrl.touched ||
                      btnNextSubmit)
                      ? 'is-invalid'
                      : ''
                  ">
                  <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                  <option *ngFor="let state of states" [ngValue]="state">{{state.Name}}</option>
                </select>
                <div *ngIf="
                this.StateCtrl.invalid &&
                    (this.StateCtrl.dirty ||
                    this.StateCtrl.touched ||
                      btnNextSubmit)
                  ">
                  <small *ngIf="
                  this.StateCtrl.errors.required
                    " class="invalid-feedback d-block">
                    Employer State is required</small>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="txtEmpployer" class="col-sm-4 col-form-label">Employer*</label>
            <div class="col-sm-8">
              <div class="d-flex flex-column w-100">
                <div class="input-group input-group-new"
                  [class.is-invalid]="!isControlValid(form.get('Employer'), true) || !isControlValid(EmployerIdCtrl, true)"
                  #employerSearchBox [ngClass]="
                        form.get('Employer').invalid &&
                        (form.get('Employer').dirty ||
                          form.get('Employer').touched ||
                          btnNextSubmit)
                          ? 'is-invalid'
                          : ''
                      " [class.disabled]="form.get('Employer').disabled">
                  <input class="form-control" id="txtEmpployer" type="text" [placeholder]="'Search Employer by Name'"
                    formControlName="Employer" matInput [matAutocomplete]="autoEmployer"
                    (blur)="txtEmployer_Blur($event)" (input)="txtEmployer_Input($event)" (focus)="onFocusParent(1)">
                  <div *ngIf="!isSearchingEmployer" class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                  </div>
                  <mat-spinner [diameter]="14" color="primary" *ngIf="isSearchingEmployer"></mat-spinner>
                </div>
                <div
                  *ngIf="!isControlValid(form.get('Employer'), isMemberTransfer) || !isControlValid(form.get('Employer'), isMemberTransfer)">
                  <small *ngIf="form.get('Employer').getError('unresolved'); else notEquals"
                    class="invalid-feedback d-block">The employer does not exist</small>
                  <small *ngIf="form.get('Employer').getError('required')" class="invalid-feedback d-block">
                    {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Employer')}}</small>
                  <ng-template #notEquals>
                    <small *ngIf="EmployerIdCtrl.getError('notEquals')" class="invalid-feedback d-block">
                      member currently belongs to this employer</small>
                  </ng-template>
                </div>
                <mat-autocomplete id="autoEmployer" name="autoEmployer"
                  class="height1 fpscrolly border-2 rounded-10 border-default w-100" #autoEmployer="matAutocomplete"
                  [displayWith]="AutoComplete_DisplayWithFn" (optionSelected)="SelectEmployer($event.option.value)">
                  <mat-option *ngFor="let employer of employerSearchResults" [value]="employer"
                    class="border-default border-bottom py-1">
                    <p class="m-0 d-flex align-items-center">{{employer.Name}}</p>
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>

          </div>

          <div class="form-group row">
            <label for="empWorkplaceSuburb" class="col-sm-4 col-form-label">Workplace Suburb*</label>
            <div class="col-sm-8">
              <div class="d-flex flex-column w-100">
                <div class="input-group input-group-new"
                  [class.is-invalid]="!isControlValid(WorkPlaceObjCtrl, true) || !isControlValid(WorkPlaceCtrl, true)"
                  #wrkplcSearchBox [ngClass]="getClassFocused(2)"
                  [class.disabled]="WorkPlaceObjCtrl.disabled || isSearchingWorkPlace">
                  <input class="form-control" id="txtWorkPlace" type="text" placeholder="Search City or Suburb"
                    formControlName="WorkplaceSuburbObj" matInput [matAutocomplete]="autoWorkplaceSuburb"
                    (blur)="txtWorkPlace_Blur($event)" (input)="txtWorkPlace_Input($event)" (focus)="onFocusParent(2)">
                  <div *ngIf="!isSearchingWorkPlace" class="input-group-append">
                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                  </div>
                  <mat-spinner [diameter]="14" color="primary" *ngIf="isSearchingWorkPlace"></mat-spinner>
                </div>
                <div *ngIf="!isControlValid(WorkPlaceObjCtrl, true) || !isControlValid(WorkPlaceCtrl, true)">
                  <small *ngIf="WorkPlaceObjCtrl.getError('unresolved'); else notEquals"
                    class="invalid-feedback d-block">The
                    City/Suburb does not exist</small>
                  <small *ngIf="WorkPlaceObjCtrl.getError('required')" class="invalid-feedback d-block">
                    {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Workplace Suburb')}}</small>
                </div>
                <mat-autocomplete id="autoWorkplaceSuburb" name="autoWorkplaceSuburb"
                  class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                  #autoWorkplaceSuburb="matAutocomplete" [displayWith]="AutoCompleteWorkPlace_DisplayWithFn"
                  (optionSelected)="SelectWorkPlace($event.option.value)">
                  <mat-option *ngFor="let wp of wrkplcSearchResults" [value]="wp"
                    class="border-default border-bottom py-1">
                    <p class="m-0 d-flex align-items-center"> {{wp.Name}}, {{wp.State?.Name}},
                      {{wp.Postcode?.Name}}</p>
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>

          </div>
          <div class="fixed-mobile mt-5">

            <div class="row">
              <div class="col-md-12">
                <button class="btn btn-primary btn-new d-flex align-items-center justify-content-center ml-auto ml-sm-0"
                  (click)="SubmitEmDetails()" [disabled]="!form.valid" #nxtButtonRef>
                  Next<i class="icon-next"></i>
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>