import { Component, OnInit } from '@angular/core';
import { PaymentService } from '@fp/services';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataResult } from '@fp/models';
import { TaskList } from '@fp/models/task-list.model';
import { MatTableDataSource } from '@angular/material/table';

interface FilterInterface {
  BotName: string;
  Description: string;
  StartTime: string;
  CompletedTime: string;
  Result: string;
}

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})

export class TaskListComponent implements OnInit {
  errorHandler: any;
  dataSource: MatTableDataSource<TaskList>;
  displayedColumns: string[];

  constructor(
    protected paymentSvc: PaymentService
  ) { }

  ngOnInit() {
    this.getTaskList();
  }

  public getTaskList() {
    this.displayedColumns = ['botname', 'description', 'starttime', 'completedtime', 'result'];
    this.Invoke(
      this.paymentSvc.getTaskList(),
      (result: DataResult<any>) => {
        console.log('Task List: ', result.Data);
        this.dataSource = new MatTableDataSource<any>(result.Data);
        console.log('This is dataSource: ', this.dataSource);

        const _f: FilterInterface = {
          BotName: result["Data"][0]["BotName"],
          Description: result["Data"][0]["Description"],
          StartTime: result["Data"][0]["StartTime"],
          CompletedTime: result["Data"][0]["CompletedTime"],
          Result: result["Data"][0]["Result"]
        };

      }
    );
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source.pipe(
      catchError((e) => { throw e; }))
      .subscribe(
        res => { handleResultCallback(res); },
        err => { this.errorHandler(err); }
      );
  }

}
