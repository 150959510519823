import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormControl, NgModel, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbPopover, NgbDateStruct, NgbCalendar, NgbInputDatepicker, NgbDateAdapter, NgbDateNativeUTCAdapter } from '@ng-bootstrap/ng-bootstrap';
import { empty, Observable } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap } from 'rxjs/operators';
import { DialogResult } from 'src/app/components/common-dialog/common-dialog.component';
import { CommonMessage, CommonString, RouterConstant, StorageKey } from 'src/app/constant';
import { StringHelper, DateHelper } from 'src/app/helpers';
import { Country, CreateRegionResult, DataResult, Postcode, Region, SearchPostcodeRequest, State, Suburb, User, RegionNoteModel, RegionAdditionalOption } from 'src/app/models';
import { MessageBox, MessageBoxButton } from 'src/app/services/common-dialog.service';
import { CommonService } from 'src/app/services/common.service';
import { RegionService } from 'src/app/services/region.service';
import { DatePipe } from '@angular/common';
import { CommonConstants } from '@fp/constant/common-constants';

@Component({
    selector: 'app-createnewregion',
    templateUrl: './createnewregion.component.html',
    styleUrls: ['./createnewregion.component.css'],
    providers: [
        { provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter }
    ]
})
export class CreatenewregionComponent implements OnInit, AfterViewInit, OnDestroy {

    regionName: string;
    regionDescription: string;
    countries: Country[];
    additionalOptions: RegionAdditionalOption[];
    selectedCountry: Country;
    states: State[];
    selectedState: State;
    availablePostcodes: Postcode[];
    selectedPostcodes: Postcode[];
    selectedSuburbs: Suburb[];
    isCheckingName = false;
    isSearchingPostcode = false;
    isValidated = false;

    CountryCtrl = new UntypedFormControl(null, Validators.required);
    StateCtrl = new UntypedFormControl(null, Validators.required);
    postcodeSearchTextControl = new UntypedFormControl(null);
    StatusCtrl = new UntypedFormControl(null, Validators.required);

    // Keeps track of the number of requests to show/hide progress bar.
    requestQueueCount = 0;

    @ViewChild('txtRgName') regionNameViewModel: NgModel;
    @ViewChild('postcodeSearchBox') postcodeSearchBox: ElementRef<HTMLDivElement>;
    @ViewChild('postcodeAutocomplete') postcodeAutocomplete: MatAutocomplete;

    CommonMessage = CommonMessage;
    CommonString = CommonString;
    StringHelper = StringHelper;

    parentFocus = 0;

    presentDate: NgbDateStruct;
    txtPDBDNotesControl = new UntypedFormControl();
    OpenLargeEmployersCtrl = new UntypedFormControl();
    OpenSmallAdditionsCtrl = new UntypedFormControl();
    Under50EmployeesCtrl = new UntypedFormControl();
    regionETAOpening = new UntypedFormControl();
    regionWaitListedEmployer: string;

    dataSourceNotes = new MatTableDataSource<RegionNoteModel>();
    displayedColumns1: string[] = ['dateTime', 'note', 'user'];
    private databinding: RegionNoteModel[] = [];

    constructor(
        protected svc: RegionService,
        protected commonSvc: CommonService,
        private router: Router,
        private dialog: MatDialog,
        private ngbCalendar: NgbCalendar,
        private datePipe: DatePipe) {
        this.selectedPostcodes = [];
        this.selectedSuburbs = [];
    }

    ngOnDestroy() {
        this.presentDate = null;
        this.dataSourceNotes = null;
    }

    ngOnInit() {
        this.LoadCountries();
        this.LoadAdditionalOption();
        const today = this.ngbCalendar.getToday();
        this.presentDate = today;
        this.states = CommonConstants.GET_STATES.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.regionNameViewModel.valueChanges.
                pipe(
                    debounceTime(500),
                    switchMap(() => {
                        if (this.regionNameViewModel.value.trim().length > 0) {
                            this.isCheckingName = true;
                            return this.svc.getByName(this.regionNameViewModel.value);
                        } else {
                            this.isCheckingName = false;
                        }
                        return empty();
                    }),
                    catchError((e) => { throw e; }))
                .subscribe(
                    (result: DataResult<Region>) => {
                        this.isCheckingName = false;
                        if (result.Success) {
                            if (result.Data != null) {
                                this.regionNameViewModel.control.setErrors({ duplicated: true });
                            } else {
                                this.regionNameViewModel.control.setErrors(null);
                            }
                        } else {
                            this.errorHandler(result, 'An error has occurred. Please contact the administrator.');
                        }
                    },
                    error => {
                        this.isCheckingName = false;
                        this.errorHandler(error);
                    });

            this.CountryCtrl.valueChanges.subscribe((value: Country) => {
                if (!this.selectedCountry || !value || value.CountryId != this.selectedCountry.CountryId) {
                    this.ChangeCountry(value);
                }
            });

            this.StateCtrl.valueChanges.subscribe((value: State) => {
                if (!this.selectedState || !value || value.StateId != this.selectedState.StateId) {
                    if (this.selectedPostcodes.length > 0) {
                        MessageBox.ShowCustom(this.dialog,
                            CommonMessage.STATE_CHANGE_CONFIRMATION_TITLE,
                            CommonMessage.STATE_CHANGE_CONFIRMATION_TITLE,
                            CommonMessage.STATE_CHANGE_CONFIRMATION_MESSAGE,
                            MessageBoxButton.YesNo).subscribe(r => {
                                if (r.result == DialogResult.Yes) {
                                    this.ChangeState(value);
                                } else {
                                    this.StateCtrl.setValue(this.selectedState, { emitEvent: false });
                                }
                            });
                    } else {
                        this.ChangeState(value);
                    }
                }
            });

            this.postcodeSearchTextControl.valueChanges.
                pipe(
                    debounceTime(500),
                    switchMap(() => {
                        if (this.CountryCtrl.valid && this.StateCtrl.valid && this.postcodeSearchTextControl.value.length >= 3) {
                            const input: SearchPostcodeRequest = {
                                StateId: this.selectedState.StateId,
                                SearchText: this.postcodeSearchTextControl.value
                            };
                            this.isSearchingPostcode = true;
                            return this.svc.searchPostcode(input);
                        } else {
                            this.isSearchingPostcode = false;
                        }
                        return empty();
                    }),
                    catchError((e) => { throw e; }))
                .subscribe(
                    result => {
                        this.isSearchingPostcode = false;
                        if (result != null) {
                            if (result instanceof Array) {
                                this.UpdateAvailablePostcodes(result);
                            } else {
                                this.errorHandler(result, 'An error has occurred. Please contact the administrator.');
                            }
                        }
                    },
                    error => {
                        this.isSearchingPostcode = false;
                        this.errorHandler(error);
                    });

            // Fix postcode autocomplete's width.
            this.postcodeAutocomplete.panelWidth = this.postcodeSearchBox.nativeElement.clientWidth;
        });
    }

    private errorHandler(error, message: string = null) {
        MessageBox.ShowError(this.dialog, message || 'An error occurred while trying to call a service');
        console.error(error);
    }

    Invoke(source: Observable<any>, handleResultCallback: Function) {
        this.requestQueueCount++;
        this.commonSvc.StartProgressBar();
        source.pipe(
            catchError((e) => { throw e; }),
            finalize(() => {
                this.requestQueueCount--;
                if (this.requestQueueCount <= 0) {
                    this.commonSvc.StopProgressBar();
                    this.requestQueueCount = 0;
                }
            }))
            .subscribe(
                res => { handleResultCallback(res); },
                err => { this.errorHandler(err); }
            );
    }

    LoadAdditionalOption() {
        this.Invoke(
            this.svc.getAdditionalOption(),
            (result: DataResult<RegionAdditionalOption[]>) => {
                if (typeof result.Success !== 'undefined') {
                    if (result.Success) {
                        this.additionalOptions = result.Data
                    } else {
                        console.error(result);
                    }
                } else {
                    console.error(result);
                }
            });
    }

    LoadCountries() {
        this.countries = CommonConstants.GET_COUNTRIES.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
        // TODO: should find a more generic way to determine default country.
        const defaultCountry = this.countries.find(country => country.Name === 'Australia');
        if (defaultCountry != null) {
            this.CountryCtrl.setValue(defaultCountry);
        }
    }

    UpdateAvailablePostcodes(postcodes: Postcode[]) {
        this.availablePostcodes = postcodes
            .filter(postcode => this.selectedPostcodes.findIndex(p => p.PostcodeId == postcode.PostcodeId) < 0)
            .sort((a, b) => parseInt(a.Code) - parseInt(b.Code));
    }

    RefreshSuburbList() {
        let _selectedSuburbs = <Suburb[]>[];
        this.selectedPostcodes.forEach(postcode => {
            if (postcode.Suburb instanceof Array) {
                postcode.Suburb.forEach(s => {
                    (<any>s.Postcode) = {
                        PostcodeId: postcode.PostcodeId,
                        Code: postcode.Code
                    };
                });
                _selectedSuburbs = _selectedSuburbs.concat(postcode.Suburb);
            }
        });
        this.selectedSuburbs = _selectedSuburbs;
    }

    AutoComplete_DisplayWithFn(option: any) {
        if (!option) {
            return '';
        }
        return option.Name || option.Code;
    }

    slctCountryOption_CompareFn(opt1: Country, opt2: Country) {
        return (opt1 && opt2) && opt1.CountryId == opt2.CountryId;
    }

    slctStateOption_CompareFn(opt1: State, opt2: State) {
        return (!opt1 && !opt2) || (opt1 && opt2 && opt1.StateId == opt2.StateId);
    }

    AddPostcode(postcode: Postcode, popover: NgbPopover, autoComplete: MatAutocomplete, option: MatOption) {
        if (postcode.Region != null) {
            if (popover.isOpen()) {
                popover.close();
            }
            popover.open({ regionName: postcode.Region.Name, regionId: postcode.Region.RegionId });
        } else {
            const index = this.availablePostcodes.indexOf(postcode);
            if (index > -1) {
                this.selectedPostcodes.push(postcode);
                //option._getHostElement().hidden = true;
                this.availablePostcodes.splice(index, 1);
                this.selectedPostcodes = this.selectedPostcodes.sort((a, b) => parseInt(a.Code) - parseInt(b.Code));
                this.Dirty();
                this.RefreshSuburbList();
            }
        }
    }

    RemovePostcode(postcode: Postcode) {
        const index = this.selectedPostcodes.indexOf(postcode);
        if (index > -1) {
            this.selectedPostcodes.splice(index, 1);
            if (this.availablePostcodes) {
                this.availablePostcodes.push(postcode);
                this.availablePostcodes.sort((a, b) => a.Code > b.Code ? 1 : -1);
            }
            this.Dirty();
            this.RefreshSuburbList();
        }
    }

    OpenRegion(regionId: number) {
        window.open(RouterConstant.NAVIGATOR_REGION_EDIT + '/' + regionId);
    }

    ChangeCountry(country: Country) {
        if (country) {
            if (!this.selectedCountry || this.selectedCountry.CountryId != country.CountryId) {
                this.selectedCountry = country;
                this.states = CommonConstants.GET_STATES.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
                this.ChangeState(null);
                // this.Invoke(
                //     this.svc.getStatesByCountryId(country.CountryId),
                //     (result: DataResult<State[]>) => {
                //         if (result.Success) {
                //             this.states = result.Data.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
                //             this.ChangeState(null);
                //         } else {
                //             console.error(result);
                //         }
                //     }
                // );
            }
        } else {
            this.selectedCountry = country;
            this.states = [];
            this.ChangeState(null);
        }
    }

    ChangeState(state: State) {
        this.selectedState = state;
        this.postcodeSearchTextControl.setValue('', { emitEvent: false });
        this.availablePostcodes = [];
        this.selectedPostcodes = [];
        this.RefreshSuburbList();
    }

    Validate(region: Region): boolean {
        this.isValidated = true;
        return region.Name != null &&
            this.regionNameViewModel.control.valid &&
            this.CountryCtrl.valid &&
            this.StateCtrl.valid &&
            this.StatusCtrl.valid &&
            region.Postcode.length > 0;
    }

    Dirty() {
        this.isValidated = false;
    }

    //#region Event handlers

    btnCreateRegion_Click() {
        this.postcodeSearchTextControl.markAsDirty();
        const region = new Region();
        region.Name = this.regionName;
        region.Description = this.regionDescription;
        region.Postcode = this.selectedPostcodes;
        region.IsActive = this.StatusCtrl.value;
        region.OpenForLargeEmployerId = this.OpenLargeEmployersCtrl.value;
        region.OpenForSmallAdditionId = this.OpenSmallAdditionsCtrl.value;
        region.Under50EmployerId = this.Under50EmployeesCtrl.value;
        region.EtaonOpening = this.regionETAOpening.value;
        region.WaitListedEmployer = this.regionWaitListedEmployer;
        region.RegionNotes = this.dataSourceNotes.data;
        region.ModifiedBy = this.commonSvc.GetUser();

        if (!this.Validate(region))
            return;

        region.Postcode.forEach(p => {
            delete p.Suburb;
            delete p.State;
        });
        this.Invoke(
            this.svc.create(region),
            (result: CreateRegionResult) => {
                if (result.Success && result.RegionId > 0) {
                    MessageBox.ShowCustom(this.dialog,
                        'Information',
                        'Information',
                        CommonMessage.DATA_SAVE_SUCCESS_MESSAGE)
                        .subscribe(
                            (dialogResult) => {
                                if (dialogResult.result.toLowerCase() === DialogResult.Ok) {
                                    this.router.navigate([RouterConstant.NAVIGATOR_REGION_EDIT, result.RegionId]);
                                }
                            }
                        );
                    this.regionNameViewModel.control.markAsPristine();
                    this.postcodeSearchTextControl.setValue('', { emitEvent: false });
                    this.postcodeSearchTextControl.markAsPristine();
                } else {
                    this.errorHandler(result, 'Unable to create region. Please contact administrator');
                }
            });
    }
    //#endregion Event handlers

    //#region Additional Notes
    public BtnClickAddNote() {
        const currentUser = <User>JSON.parse(this.commonSvc.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
        const dataNote = {
            RegionNoteId: 0,
            RegionId: 0,
            Username: currentUser.FirstName + ', ' + currentUser.LastName,
            UserId: Number(currentUser.UserId),
            Note: this.txtPDBDNotesControl.value,
            CreateOrUpdateDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            DateTimeDisplay: DateHelper.format(new Date(), 'DD-MMM-YYYY HH:mm')
        };

        if (!StringHelper.isNullOrEmpty(dataNote.Note)) {
            this.databinding.unshift(dataNote);
            this.dataSourceNotes = new MatTableDataSource<RegionNoteModel>(this.databinding);
        }
        // reset input text
        this.txtPDBDNotesControl.setValue('');
    }
    //#endregion

    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }
    onBlurParent(focusNumbr) {
        this.parentFocus = focusNumbr;
    }
    getClassFocused(vlFocused) {
        if (this.parentFocus == vlFocused) {
            return "focused";
        } else {
            return "";
        }
    }
}
