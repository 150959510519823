import { Component, Injector } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { ModelBasedForm } from '@fp/components/base';
import { CommonMessage } from '@fp/constants';
import { EEmployerStatus } from '@fp/enums';
import { ArrayHelper, FPValidators, Utils } from '@fp/helpers';
import { CommonData, DataResult, EmployerDetailModel } from '@fp/models';
import { EmployerService } from '@fp/services';
import { NgbDateAdapter, NgbDateNativeUTCAdapter } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-employer-additional-information',
    templateUrl: './additional-information.component.html',
    styleUrls: ['./additional-information.component.css'],
    providers: [
        ModelBasedForm.provideExisting(EmployerAdditionalInformationComponent),
        { provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter }]
})
export class EmployerAdditionalInformationComponent extends ModelBasedForm<EmployerDetailModel> {
    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        const empStatusIdCtrl = fb.control(EEmployerStatus.Active, [Validators.required]);
        const form = fb.group({
            EmployerStatusId: empStatusIdCtrl,
            DateClosed: [null, [
                FPValidators.requiredWhen(ctrl =>
                    // DateClosed is required when Employer Status is 'Close for new members' or 'Failed Launch'.
                    [EEmployerStatus.CloseForNewMembers,
                    EEmployerStatus.FailedLaunch].indexOf(empStatusIdCtrl.value) > -1
                ),
                (ctrl: AbstractControl) => {
                    if (!form) {
                        return null;
                    }
                    const dateClosed = ctrl.value as Date;
                    const startDate = form.get('EmployerStartDate').value as Date;
                    // "DateClosed" must be greater than "EmployerStartDate".
                    return dateClosed && startDate && dateClosed.getTime() <= startDate.getTime() ? { 'gt': { 'EmployerStartDate': startDate } } : null;
                }
            ]],
            EmployerStartDate: [null, [
                Validators.required,
                (ctrl: AbstractControl) => {
                    if (!form) {
                        return null;
                    }
                    const startDate = ctrl.value as Date;
                    const dateClosed = form.get('DateClosed').value as Date;
                    // "EmployerStartDate" must be less than "DateClosed".
                    return dateClosed && startDate && startDate.getTime() >= dateClosed.getTime() ? { 'lt': { 'DateClosed': dateClosed } } : null;
                },
                (ctrl: AbstractControl) => {
                    if (!form) {
                        return null;
                    }
                    const startDate = ctrl.value as Date;
                    const nextPaymentRunDate = form.get('NextPaymentRunDate').value as Date;
                    // "EmployerStartDate" must be less than or equal to "NextPaymentRunDate".
                    return nextPaymentRunDate && startDate && startDate.getTime() > nextPaymentRunDate.getTime() ?
                        { 'lte': { 'NextPaymentRunDate': nextPaymentRunDate } } :
                        null;
                }
            ]],
            IsDirectDebit: fb.control({ value: true, disabled: true }),
            NextPaymentRunDate: [null, [
                Validators.required,
                (ctrl: AbstractControl) => {
                    if (!form) {
                        return null;
                    }
                    const nextPaymentRunDate = ctrl.value as Date;
                    const startDate = form.get('EmployerStartDate').value as Date;
                    // "NextPaymentRunDate" must be greater than or equal to "EmployerStartDate".
                    return nextPaymentRunDate && startDate && nextPaymentRunDate.getTime() < startDate.getTime() ?
                        { 'gte': { 'EmployerStartDate': startDate } } :
                        null;
                }
            ]],
            PaymentPeriodId: [1, [Validators.required]],
            NoEmployeeAnswer: [null],
            EligibleEmployeeIds: [[]],
            EligibilityInformation: [null],
            TargetNumber: [null]
        });
        return form;
    }

    _statuses: CommonData[];
    _eligibleEmployees: CommonData[];
    _paymentPeriods: CommonData[];

    CommonMessage = CommonMessage;
    EEmployerStatus = EEmployerStatus;

    EligibleEmployeeCtrl = new UntypedFormControl(null);
    SelectedEligibleEmployeesCtrl = new UntypedFormControl([]);
    constructor(injector: Injector) { super(injector); }

    OnLoad() {
        this._serviceInvoker.Invoke(EmployerService, svc => {
            this.InvokeBatch([
                svc.getStatuses(),
                svc.getEligibleEmployees(),
                svc.getPaymentPeriods()
            ],
                {
                    onSuccess: (res: DataResult<any>[]) => {
                        try {
                            this.handleGetStatusesResult(res[0]);
                            this.handleGetEligibleEmployeesResult(res[1]);
                            this.handleGetPaymentPeriodsResult(res[2]);
                            super.OnLoad();
                        } catch (err) {
                            this._logger.error(err);
                        }
                    }
                });
        });
    }

    OnLoadComplete() {
        setTimeout(() => {
            this.getControl('EmployerStatusId').valueChanges.subscribe((value: number) => {
                this.getControl('DateClosed').updateValueAndValidity({ emitEvent: false });
            });

            this.getControl('DateClosed').valueChanges.subscribe((value: Date) => {
                this.getControl('EmployerStartDate').updateValueAndValidity({ emitEvent: false });
            });

            this.getControl('EmployerStartDate').valueChanges.subscribe((value: Date) => {
                this.getControl('DateClosed').updateValueAndValidity({ emitEvent: false });
                this.getControl('NextPaymentRunDate').updateValueAndValidity({ emitEvent: false });
            });

            this.getControl('NextPaymentRunDate').valueChanges.subscribe((value: Date) => {
                this.getControl('EmployerStartDate').updateValueAndValidity({ emitEvent: false });
            });

            this.EligibleEmployeeCtrl.valueChanges.subscribe((value: number) => {
                if (value !== null) {
                    let selectedEligibleEmployees = this.SelectedEligibleEmployeesCtrl.value || [];
                    Utils.swapArrayItem(value, this._eligibleEmployees, selectedEligibleEmployees);
                    selectedEligibleEmployees = Utils.sortCommonData(selectedEligibleEmployees);
                    this.SelectedEligibleEmployeesCtrl.setValue(selectedEligibleEmployees);
                    this.EligibleEmployeeCtrl.setValue(null, { emitEvent: false });
                }
            });

            this.SelectedEligibleEmployeesCtrl.valueChanges.subscribe((value: CommonData[]) => {
                this.getControl('EligibleEmployeeIds').setValue((value || []).map(_emp => _emp.Id));
            });
        });
        super.OnLoadComplete();
    }

    PatchValue(value, opts) {
        super.PatchValue(value, opts);
        if (this.loaded) {
            this.handleDataAfterLoading();
        }
    }

    private handleDataAfterLoading() {
        const eligibleEmployeeIds = <number[]>(this.getControlValue('EligibleEmployeeIds') || []);
        const selectedEligibleEmployees = this._eligibleEmployees.take(_emp => eligibleEmployeeIds.indexOf(_emp.Id) != -1);
        // this._eligibleEmployees = this._eligibleEmployees.filter(_emp => eligibleEmployeeIds.indexOf(_emp.Id) === -1);
        this.SelectedEligibleEmployeesCtrl.setValue(selectedEligibleEmployees, { emitEvent: false });
    }

    private handleGetStatusesResult(res: DataResult<CommonData[]>) {
        if (res.Success) {
            this._statuses = res.Data;
        } else {
            this.HandleResponseError(res);
        }
    }

    private handleGetEligibleEmployeesResult(res: DataResult<CommonData[]>) {
        if (res.Success) {
            this._eligibleEmployees = res.Data;
            const eligibleEmployeeIds = <number[]>(this.getControlValue('EligibleEmployeeIds') || [])
            if (eligibleEmployeeIds.length > 0) {
                this.handleDataAfterLoading();
            }
        } else {
            this.HandleResponseError(res);
        }
    }

    private handleGetPaymentPeriodsResult(res: DataResult<CommonData[]>) {
        if (res.Success) {
            this._paymentPeriods = res.Data;
        } else {
            this.HandleResponseError(res);
        }
    }

    _getEligibleEmployeeOptionTitle(name: string) {
        switch (name) {
            case 'Casual employees':
                return 'Casual employees are paid by the organisation\'s payroll department and work more than 20 hours per week and have at least 3 months consistent working history';
            case 'Contract/Temporary employees':
                return 'Contract/Temporary employees are paid by the organisation\'s payroll department and have at least 3 months consistent working history (or at least 6 months remaining on their existing contract)';
            default:
                return '';
        }
    }

    _removeEligibleEmployee(index: number) {
        let selectedEligibleEmployees = this.SelectedEligibleEmployeesCtrl.value || [];
        Utils.swapArrayItem(index, selectedEligibleEmployees, this._eligibleEmployees);
        this._eligibleEmployees = Utils.sortCommonData(this._eligibleEmployees);
        this.SelectedEligibleEmployeesCtrl.setValue(selectedEligibleEmployees);
    }
}
