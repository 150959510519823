<div class="search-wrapper">
    <div class=" search-wrapper-content">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="back-to-form-wrapper col-lg-8">
                <a href="/employer_details" class="d-flex align-items-center"><i class="icon-previous"></i> <span
                        class="d-none d-md-inline">Back to Form</span></a>
            </div>
        </div>       
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">
                <div class="search-filed form-wrapper-new">
                    <div class="input-group  input-group-new ">
                        <div class="input-group-prepend">
                            <i class="fas fa-search"></i>
                        </div>
                        <input type="text" class="form-control" placeholder="Search by Suburb or Postcode"
                            [(ngModel)]='mapLocationSearch' (keydown.enter)='searchBarFunctionality()'>
                        <div class="input-group-append bg-primary filter-button" (click)="filterSearchResultPopup()">
                            <span class="icon-filter"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 text-center">
                <button class="btn btn-new btn-primary" (click)="goToMapView('map-view')"><i class="fa fa-map mr-3"></i>
                    Map View</button>
            </div>
        </div>       
    </div>
</div>
<div class="outer-wrapper">
    <div class="table-wrapper position-relative">
        <div class="heard-background"></div>
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">
                <table>
                    <thead>
                        <th>Facility Name</th>
                        <th>24 hrs</th>
                        <th>Services</th>
                        <th>Suburb</th>
                        <th>State</th>
                        <th>Post Code</th>
        
                    </thead>
                    <tbody>
                        <tr *ngFor="let facility of mapDataSource">
                            <td>
                                <div class="facility-center">{{facility.Name}}</div>
                                <div class="facility-center-website"> <a href="{{facility.WebsiteUrl}}" target="_blank">View
                                        website</a></div><br/>
                                        <div *ngIf="facility.AdditionalInfo">
                                            <div class="facility-center-website-additional-info"> <a (click)="openCloseAdditionalInfo(facility.FacilityId)">Additional Info</a> </div>
                                            <div class="facility-additional-info" *ngIf="facility.IsShownAdditionalInfo">
                                                {{facility.AdditionalInfo}}
                                            </div>
                                        </div>
                            </td>
                            <td *ngIf="facility.Is24Hour === false">No</td>
                            <td *ngIf="facility.Is24Hour === true">Yes</td>
                            <td>
                                <ul class="service-list" *ngFor="let service of facility.Services">
                                    <li>{{service.ServiceName}}</li>
                                </ul>
                                <div class="falicliy-activity-wrapper" *ngIf="facility.Activities.length>0">
                                    <div class="facility-activity-icon" *ngFor="let activity of facility.Activities">
                                        <img src="./assets/images/facility-icon/{{activity.IconFileName}}.png" alt="Altitude"
                                            class="img-fluid" tooltip="{{activity.ActivityToolTip}}">
                                    </div>
                                </div>
                            </td>
                            <td>{{facility.Suburb.Name}}</td>
                            <td>{{facility.State.Name}}</td>
                            <td>{{facility.Postcode.Code}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>      
    </div>
</div>
