import { Address } from "./addressmodel";
import { BankAccount } from "./bank-account.model";
import { Country } from "./country.model";
import { City } from "./city.model";
import { Employer } from "./employermodel";
import { Facility } from "./facility.model";
import { MemberMedicalAnswer } from "./member-medical-answer.model";
import { MemberStatus } from "./member-status.model";
import { MemberType } from "./member-type.model";
import { Membership } from "./membership.model";
import { Postcode } from "./postcodemodel";
import { State } from "./state.model";
import { Suburb } from "./suburb.model";
import { UploadFile } from "./upload-file.model";
import { CommonResponse } from "./commonresponsemodel";
import { MemberAdditionalNotes } from "./MemberAdditionalNotes.model";
import { MemberPreSignupSurveyModel } from "./member-presignup-survey.model";

export class Member {
  MemberId: number;
  EmployerId: number;
  UserId: number;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  MobileNumber: string;
  PrimaryEmail: string;
  SecondaryEmail: string;
  GenderId: number;
  PhotoLocation: string;
  DateOfBirth: string | Date;
  EmployeePayrollNo: string;
  WorkplaceSuburb: string;
  WorkplaceSuburbObj: any = null;
  AddressId: number;
  EmergencyRelationship: string;
  EmergencyContactName: string;
  EmergencyContactNo: string;
  FavouredFacilityId: number;
  FavouredFacilityOther: string;
  IsExistingMember: boolean;
  FavouriteFacilityPersonalTrainingStatus: number;
  ExistingMemberFacilityId: number;
  ExistingFacilityOther: string;
  IsInContract: boolean;
  SortOfMembershipId: number;
  SortOfMembershipOther: string;
  ExistingFacilityApproximateCost: number;
  MemberTypeId: number;
  MemberStatusId: number;
  MemberIdPrimary: number;
  BankAccountId: number;
  TermConditionId: number;
  TermConditionDateTimeStamp: string | Date;
  PaymentTypeId: number;
  UpfrontPaymentDate: string | Date;
  DateJoined: string | Date;
  ExpiryDate: string | Date;
  DateCardIssued: string | Date;
  ImagePath: string;
  Comment: string;
  CurrentWeeklyPaymentAmount: number;
  SignupStep: number;
  SuspensionStartDate: string | Date;
  SuspensionEndDate: string | Date;
  TaxInvoiceDates: string[];
  RecentCancellationDate: string | Date;
  LastInactivationDate: string | Date;
  LastInactivationComment: string | Date;
  InactivationReasonId: number;
  IsActive: boolean;
  MemberNumber: string;
  IsMobileAppEnabled?: boolean;

  PrimaryMember: Member;
  Photo: UploadFile;
  MemberStatus: MemberStatus;
  MemberType: MemberType;
  Address: Address;
  Suburb: Suburb;
  City: City;
  Postcode: Postcode;
  State: State;
  Country: Country;
  Employer: Employer;
  BankAccount: BankAccount;
  MedicalAnswers: MemberMedicalAnswer[] = [];
  FamilyMembers: Member[] = [];
  FavouredFacility: Facility;
  ExistingFacility: Facility;
  Memberships: Membership[];
  MemberCard: any;
  ModifiedBy: string;
  IsReactivated?: boolean;
  GoCardlessMandateId: string;
  GoCardlessMandateStatus: string;
  GoCardlessCustomerId: string;
  MemberPreSignupSurvey: MemberPreSignupSurveyModel;

  MemberNotifications: MemberAdditionalNotes[];
  MembershipSuspension: any;
  FamilyObject: any;

  constructor() {
    this.IsExistingMember = false;
    this.IsInContract = false;
  }
}

export class MemberSearchResultRecord {
  MemberId: number;
  MemberIdPrimary: number;
  FirstName: string;
  LastName: string;
  MemberNumber: string;
  MemberStatus: CommonResponse;
  MembershipStatus: CommonResponse;
  MemberType: CommonResponse;
  Employer: CommonResponse;
}

export class MemberPhoto {
  Id: number;
  FileName: string;
  Data: string;
  ModifiedBy: string;
}

export class MemberVisitsViewModel {
  VisitDateTime: string;
  Facility: string;
  Service: string;
  Visits: number;
  VisitSource: string;
  VisitType: string;
  InvalidVisitReason: string;
  DuplicateVisit: boolean;
}
