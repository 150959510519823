<div [formGroup]="form">
    <div class="form-group row">
        <label for="txtPackageName" class="col-md-3 col-lg-2 col-form-label">Package Name<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control pl-0" [class.is-invalid]="!isControlValid('Name')"
                    id="txtPackageName" formControlName="Name" maxlength="200">
                <mat-spinner [diameter]="14" color="primary" *ngIf="getControl('Name').pending"></mat-spinner>
                <div *ngIf="!isControlValid('Name')">
                    <small *ngIf="getControl('Name').hasError('required')"  class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Package Name')}}</small>
                    <small *ngIf="getControl('Name').hasError('duplicated')" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_VALUE_ALREADY_EXISTED, 'Package Name')}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlStatus" class="col-md-3 col-lg-2 col-form-label">Status<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" [class.is-invalid]="!isControlValid('IsActive')" id="ddlStatus"
                    formControlName="IsActive">
                    <option [ngValue]="true">{{CommonString.STATUS_TEXT_ACTIVE}}</option>
                    <option [ngValue]="false">{{CommonString.STATUS_TEXT_INACTIVE}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlState" class="col-md-3 col-lg-2 col-form-label">State<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" [class.is-invalid]="!isControlValid('StateFilter')" id="ddlState"
                    [formControl]="StateCtrl" *ngIf="!form.disabled">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngIf="states?.length > 0" [ngValue]="-1">All States</option>
                    <option *ngFor="let state of states;let i = index" [ngValue]="i">{{state.Name}}</option>
                </select>
                <div *ngIf="!isControlValid('StateFilter')">
                    <small *ngIf="getControl('StateFilter').hasError('required')"  class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'State')}}</small>
                </div>
                <div class="mt-2 slctSelectedRoles height1 fpscrolly w-100 border-2 rounded-10 border-default text-default"
                    *ngIf="selectedStates?.length > 0">
                    <div *ngFor="let selectedState of selectedStates;let i = index"
                        class="d-flex border-default border-bottom py-1 align-content-center">
                        <span class="text-primary d-flex align-items-center" (click)="_removeState(i, selectedState)"
                            *ngIf="!form.disabled">
                            <i class="fas fa-minus-circle fa-2x"></i>
                        </span>
                        <p class="d-flex align-items-center m-0 ml-2">{{selectedState.Name}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlRegion" class="col-md-3 col-lg-2 col-form-label">Region<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" [class.is-invalid]="!isControlValid('RegionFilter')" id="ddlRegion"
                    [formControl]="RegionCtrl" *ngIf="!form.disabled">
                    <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let region of regions;let i = index" [ngValue]="i">{{region.Name}}</option>
                </select>
                <div *ngIf="!isControlValid('RegionFilter')">
                    <small *ngIf="getControl('RegionFilter').hasError('required')"  class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Region')}}</small>
                </div>
                <div class="mt-2 slctSelectedRoles height1 fpscrolly w-100 border-2 rounded-10 border-default text-default"
                    *ngIf="selectedRegions?.length > 0">
                    <div *ngFor="let selectedRegion of selectedRegions;let i = index"
                        class="d-flex border-default border-bottom py-1 align-content-center">
                        <span class="text-primary d-flex align-items-center" (click)="_removeRegion(i)"
                            *ngIf="!form.disabled">
                            <i class="fas fa-minus-circle fa-2x"></i>
                        </span>
                        <p class="d-flex align-items-center m-0 ml-2">{{selectedRegion.Name}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlService" class="col-md-3 col-lg-2 col-form-label">Product/Service Type<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" [class.is-invalid]="!isControlValid('ProductServiceTypeFilter')"
                    id="ddlService" [formControl]="ServiceTypeCtrl" *ngIf="!form.disabled">
                    <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngIf="serviceTypes?.length > 0" [ngValue]="-1">All Services</option>
                    <option *ngFor="let serviceType of serviceTypes;let i = index" [ngValue]="i">{{serviceType.Name}}
                    </option>
                </select>
                <div *ngIf="!isControlValid('ProductServiceTypeFilter')">
                    <small *ngIf="getControl('ProductServiceTypeFilter').hasError('required')"
                         class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Product/Service Type')}}</small>
                </div>
                <div class="mt-2 slctSelectedRoles height1 fpscrolly w-100 border-2 rounded-10 border-default text-default"
                    *ngIf="selectedServiceTypes?.length > 0">
                    <div *ngFor="let selectedServiceType of selectedServiceTypes;let i = index"
                        class="d-flex border-default border-bottom py-1 align-content-center">
                        <span class="text-primary d-flex align-items-center" (click)="_removeServiceType(i)"
                            *ngIf="!form.disabled">
                            <i class="fas fa-minus-circle fa-2x"></i>
                        </span>
                        <p class="d-flex align-items-center m-0 ml-2">{{selectedServiceType.Name}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>