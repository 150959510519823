import { Component, ViewEncapsulation } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { RouterConstant } from "@fp/constant";
import { CommonService } from "@fp/services";
import { startWith } from "rxjs/operators";
import { auPrefixValidator, nzPrefixValidator } from "./validators";

const NEW_ZEALAND_PREFIX = "+64"
const AUSTRALIA_PREFIX = "+61"

@Component({
  selector: "app-security-preferences-update-phone",
  templateUrl: "./security-preferences-update-phone.component.html",
  styleUrls: ["./security-preferences-update-phone.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class SecurityPreferencesUpdatePhoneComponent {
  cognitoUserState: "VALID" | "HAS_PHONE_NUMBER_REQUIRES_VERIFICATION" | "HAS_NO_PHONE_NUMBER" | "UNEXPECTED_STATE" = "VALID"
  phone: string = '';
  verifying: boolean = false;
  verified: boolean = false;
  error = null;

  mfaPhone = new FormControl('', [
    Validators.required,
    auPrefixValidator()
  ]);
  verifyCode = new FormControl('');
  countryCode = new FormControl(AUSTRALIA_PREFIX);

  constructor(
    public commonService: CommonService,
    private router: Router
  ) {
    this.subscribeToCountryCodeUpdates()
  }

  get showAlert(): boolean {
    return this.cognitoUserState !== "VALID"
  }
  get alertMessage(): string {
    if (this.cognitoUserState === "HAS_PHONE_NUMBER_REQUIRES_VERIFICATION") {
      return `<b>Mobile number isn’t verified</b><br/><br/>

For seamless and secure access to facilities, we need to have both email and mobile verified  for you to conveniently login, and be able to reset your password. `
    } else if (this.cognitoUserState === "HAS_NO_PHONE_NUMBER") {
      return `<b>We are missing your mobile details</b><br/><br/>

For seamless and secure access to facilities, we need to have both email and mobile verified  for you to conveniently login, and be able to reset your password. `
    }
    return ""
  }
  get showUnexpectedStateAlert(): boolean {
    return this.cognitoUserState === "UNEXPECTED_STATE"
  }

  get isSendCodeDisabled(): boolean {
    return !this.mfaPhone.valid;
  }

  ngOnInit() {
    this.getCognitoUserState();
  }

  public async onVerify() {
    await this.commonService.verifyCurrentUserCode(this.verifyCode.value);
    this.verifying = false;
    this.verified = true;
    await this.router.navigate([RouterConstant.NAVIGATOR_MEMBER_SECURITY_PREFERENCES]);
  }

  public async onSendCode() {
    this.verifying = true
    try {
      await this.commonService.updateCurrentUserMFAPhoneNumber(`${this.countryCode.value}${this.mfaPhone.value}`);
    } catch (e) {
      this.error = e.message
    }
  }

  private async getCognitoUserState() {
    this.cognitoUserState = await this.commonService.getCurrentUserCognitoState()

    if (this.cognitoUserState === "HAS_PHONE_NUMBER_REQUIRES_VERIFICATION" || this.cognitoUserState === "VALID") {
      // Update the country code, and phone number
      const { phone } = await this.commonService.getCurrentUserEmailAndPhone();
      const isNewZealandPhoneNumber = phone.indexOf(NEW_ZEALAND_PREFIX) === 0
      if (isNewZealandPhoneNumber) {
        this.countryCode.setValue(NEW_ZEALAND_PREFIX)
      }
      const PHONE_PREFIX_REGEX = /^\+(61|64)/;
      this.mfaPhone.setValue(phone.replace(PHONE_PREFIX_REGEX, ""))
    }
  }

  private subscribeToCountryCodeUpdates() {
    this.countryCode.valueChanges
      .pipe(startWith(this.countryCode.value))
      .subscribe(country => {
        this.mfaPhone.reset('', { emitEvent: false })

        if (country === NEW_ZEALAND_PREFIX) {
          this.mfaPhone.setValidators([
            Validators.required,
            nzPrefixValidator()
          ]);
        } else {
          this.mfaPhone.setValidators([
            Validators.required,
            auPrefixValidator()
          ]);
        }
        // Important: Need to trigger validation after changing validators
        this.mfaPhone.updateValueAndValidity();
      });
  }
}