import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { StringHelper } from "./string-helper";

export const FPValidators = {
    resolvableAutocompleteOption(control: AbstractControl): ValidationErrors | null {
        let resolved = true;
        if (control.dirty) {
            if (typeof control.value === 'string') {
                if (control.value.length > 0) {
                    resolved = false;
                }
            } else if (control.value && typeof control.value !== 'object') {
                resolved = false;
            }
        }
        return !resolved ? { 'unresolved': true } : null;
    },

    requiredWhen(fn: (ctrl: AbstractControl) => boolean): ValidatorFn {
        return (ctrl: AbstractControl): ValidationErrors | null => {
            return !ctrl.value && fn(ctrl) ? { 'required': true } : null;
        };
    },

    requiredNoWhitespace(control: AbstractControl): ValidationErrors | null {
        return StringHelper.isNullOrWhiteSpace(control.value) ? { required: true } : null;
    },
}
