declare global {
    interface String {
        format(...args): string;
    }
}

export const StringHelper = {
    Empty: '',

    isNullOrEmpty(value: string): boolean {
        try {
            return typeof value === 'undefined' || value === null || value === '' || value.length === 0 || value === undefined;
        } catch (e) {
            return false;
        }
    },

    isNullOrWhiteSpace(value: string): boolean {
        try {  
            return StringHelper.isNullOrEmpty(value) || value.trim().length === 0;
        } catch (e) {
            return false;
        }
    },

    format(value: string, ...args): string {
        try {
            return value.replace(/{(\d+(:.*)?)}/g, function (match, i) {
                const s = match.split(':');
                if (s.length > 1) {
                    i = i[0];
                    match = s[1].replace('}', '');
                }

                const arg = formatPattern(match, args[i]);
                return typeof arg !== 'undefined' && arg != null ? arg : StringHelper.Empty;
            });
        } catch (e) {
            return value;
        }
    }
}

function formatPattern(match, arg): string {
    switch (match) {
        case 'L':
            arg = arg.toLowerCase();
            break;
        case 'U':
            arg = arg.toUpperCase();
            break;
        default:
            break;
    }

    return arg;
}

if (typeof String.prototype.format !== 'function') {
    String.prototype.format = function (...args): string {
        return StringHelper.format(this, args);
    };
}