<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Payment / Direct Debit Details</h4>
<div class="mt-lg-4">
    <app-member-bank-account #bankAccount></app-member-bank-account>
    <div class="form-group row mt-4">
        <div class="col-md-8 col-lg-5 offset-md-3 offset-lg-2">
            <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="btnSave_Click()">
                <i class="fas fa-check mr-2"></i>
                Save
            </button>
        </div>
    </div>
    <br/>
    <p *ngIf="!isAustralia">Direct Debits payments are done via our payment partner:<br/>GoCardless - Sutton Yard, 65 Goswell Road, London, EC1V 7EN, GB</p>    
    <br/>
    <div class="tabFPTheme mb-5" *ngIf="!isAustralia">
        <ngb-accordion [destroyOnHide]="false" activeIds="pnlTermsConditions">
            <ngb-panel id="pnlTermsConditions">
                <ng-template ngbPanelTitle>
                    <i class="icon-fp" [ngClass]="{'fas fa-check': checkRightUI == true}"></i>
                    GoCardless Terms and Conditions
                    <i class="fas fas fa-chevron-down"></i>
                    <i class="fas fas fa-chevron-up"></i>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div [innerHtml]="termsAndConsContent"></div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
</div>