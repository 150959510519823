import { ChangeDetectorRef, ElementRef, EventEmitter, forwardRef, Injector, Input, Provider, QueryList, ViewChildren, Directive } from '@angular/core';
import { CommonService } from '@fp/services';
import { zip } from 'rxjs';
import { FPAbstractComponent } from './abstract-component';

@Directive()
export abstract class FPBaseComponent<TData extends IFPComponentData = IFPComponentData> extends FPAbstractComponent {
    @Input() data: TData;
    /** @deprecated Use `_serviceInvoker.Invoke(Type<T>, fn)` instead. */
    protected commonSvc: CommonService;

    /**
     * Indicates whether the `Load` method should not be called right after `OnInit`, but would be manually called at a later time.
     *
     * Default: `false`.
     */
    @Input() loadOnDemand = false;

    /**
     * Indicates whether this component should load asynchronously without waiting until all children complete loading.
     *
     * Default: `true`.
     */
    @Input() async = true;

    @ViewChildren(FPBaseComponent) protected readonly _children: QueryList<FPBaseComponent<TData>>;
    /**
     * List of direct descendant components.
     * @readonly
     */
    get children(): QueryList<FPBaseComponent<TData>> { return this._children; }

    /**
     * List of all descendant components. This list contains the flatten array of all descendants and their nested descendants.
     * @readonly
     */
    get allChildren(): FPBaseComponent<TData>[] {
        const flatten = (list: FPBaseComponent<TData>[]) => list.reduce(
            (a, b) => a.concat(b.children.length > 0 ? [b, ...flatten(b.children.toArray())] : b), []
        );
        return flatten(this.children.toArray());
    }

    private readonly _parent: FPBaseComponent<TData> = null;
    get parent() { return this._parent; }

    private _valid = true;
    /**
     * Indicates whether the component is valid.
     * @readonly
     */
    get valid() { return this._valid; }

    private _validated = false;
    /**
     * Indicates whether the component has been validated since the last change, if any.
     * @readonly
     */
    get validated() { return this._validated; }

    private _validatedOnce = false;
    /**
     * Indicates whether the component has been validated at least once.
     * @readonly
     */
    get validatedOnce() { return this._validatedOnce; }

    /** @deprecated For backward-compatibility. Switch to `validatedOnce` instead */
    get wasValidated() { return this._validatedOnce; }

    private _loadedState = new EventEmitter();
    loaded$ = this._loadedState.asObservable();
    private _loaded = false;
    /**
     * Indicates whether the component was loaded.
     * @readonly
     */
    get loaded() { return this._loaded; }

    private _loadCompleted = false;

    private _element: HTMLElement;
    protected get element() { return this._element; }

    static provideExisting(type: any): Provider {
        return <Provider>{ provide: FPBaseComponent, useExisting: forwardRef(() => type) };
    }

   /* constructor(injector: Injector) {
        super(injector);
        this.data = <TData>{};
        // Object.defineProperty(this.data, 'requestQueueCount', {
        //     get: () => { return this._requestQueueCount; }
        // })
        this.commonSvc = injector.get(CommonService);
        const viewData = this.changeDetectorRef['_lView'];
        console.log("viewdata" + viewData);
        if (viewData.parent && viewData.parent.component instanceof FPBaseComponent) {
            this._parent = viewData.parent.component;
            console.log("this.parent" + this.parent);
        }
        const elRef = <ElementRef>injector.get(ElementRef);
        this._element = elRef.nativeElement;
        this.loaded$.subscribe(() => { if (this._loadCompleted) return; this.OnLoadComplete(); });
        if (typeof this.ngOnInit === 'function') {
            const ngOnInit = this.ngOnInit;
            this.ngOnInit = () => {
                ngOnInit.call(this);
                this.fpOnInit();
            }
        }
        if (typeof this.ngAfterViewInit === 'function') {
            const ngAfterViewInit = this.ngAfterViewInit;
            this.ngAfterViewInit = () => {
                ngAfterViewInit.call(this);
                this.fpAfterViewInit();
            }
        }
        if (typeof this.ngOnDestroy === 'function') {
            const ngOnDestroy = this.ngOnDestroy;
            this.ngOnDestroy = () => {
                ngOnDestroy.call(this);
                this.fpOnDestroy();
            }
        }
    }*/
    
  constructor(injector: Injector) {
  super(injector);
  this.data = <TData>{};
  // Object.defineProperty(this.data, 'requestQueueCount', {
  //     get: () => { return this._requestQueueCount; }
  // })
  this.commonSvc = injector.get(CommonService);
  const elRef = <ElementRef>injector.get(ElementRef);
  this._element = elRef.nativeElement;
  this.loaded$.subscribe(() => { if (this._loadCompleted) return; this.OnLoadComplete(); });
  if (typeof this.ngOnInit === 'function') {
    const ngOnInit = this.ngOnInit;
    this.ngOnInit = () => {
      ngOnInit.call(this);
      this.fpOnInit();
      this.ngOnInit();
    }
  }
  if (typeof this.ngAfterViewInit === 'function') {
    const ngAfterViewInit = this.ngAfterViewInit;
    this.ngAfterViewInit = () => {
      ngAfterViewInit.call(this);
      this.fpAfterViewInit();
      this.ngAfterViewInit();
    }
  }
  if (typeof this.ngOnDestroy === 'function') {
    const ngOnDestroy = this.ngOnDestroy;
    this.ngOnDestroy = () => {
      ngOnDestroy.call(this);
      this.fpOnDestroy();
      this.ngOnDestroy();
    }
  }
  console.log(this.changeDetectorRef);
}

    ngOnInit() { };
    private fpOnInit() {
        // Detach view before loading to avoid ngDoCheck called too many times unnecessarily.
        this.changeDetectorRef.detach();
        this.OnPreInit();
    }

    ngAfterViewInit() { };
    private fpAfterViewInit() {
        this.changeDetectorRef.detectChanges();
        this.Init();
        if (!this.loadOnDemand && !this.parent) {
            this.Load({ loadPendingChildren: false });
        }
    }

    ngOnDestroy() { };
    private fpOnDestroy() {
        this.Dispose();
        this.unsubscribeEvent.next();
        this.unsubscribeEvent.complete();
    }

    private raiseLoaded() {
        if (this._loaded) {
            return;
        }
        this._logger.info('<' + this.element.localName + '>' + ' loaded at %s', new Date().toLocaleTimeString());

        this._loaded = true;
        this._loadedState.next();
        this._loadedState.complete();
    }

    Init() {
        this._logger.debug('Initializing ' + '<' + this.element.localName + '>');
        this.OnInit();
        this.OnPreLoad();
    }

    /**
     * Raised after this component has been initialized and any data-bound properties or component's input properties have been set,
     * but the child components haven't been projected to the view yet.
     *
     * Override this event to read or initialize component.
     * @protected
     */
    protected OnPreInit() {
        this._logger.debug('OnPreInit ' + '<' + this.element.localName + '>');
    }

    /**
     * Raised after all components have been initialized and any data-bound properties or component's input properties have been set.
     * The `Init` event of individual child components occurs before the `Init` event of the host component.
     *
     * Override this event to read or initialize component's or its children's properties.
     * @protected
     */
    protected OnInit() {
        this._logger.debug('OnInit ' + '<' + this.element.localName + '>');
    }

    /**
     * Calls the `OnLoad` method on this component, and then recursively does the same for each child component
     * until this component and its children are loaded. The `Load` event of individual child components occurs
     * before the `Load` event of the host component.
     *
     * Use the `OnLoad` event method to set properties in components or to load data from remote services.
     *
     * If this component loads on-demand (see `loadOnDemand`), this method will not be called after `Init` state.
     * Manually call this method to load this component at any later time.
     *
     * **NOTE:** This method only executes once after the `Init` event and marks the component loaded (see `loaded`)
     * excepts the load-on-demand component.
     *
     * @param {{ loadPendingChildren?: boolean }} [options] The options to determine how or which data this component
     * should load.
     * * `loadPendingChildren`: When `true`, load all the children that are pending for demand (see `loadOnDemand`);
     * otherwise, only children not marked load-on-demand will be loaded. Default: `true`.
     */
    public Load(options?: { loadPendingChildren?: boolean }) {
        if (!this.loaded) {
            this._logger.info('Loading ' + '<' + this.element.localName + '>');
            // Extends default options.
            options = Object.assign({
                loadPendingChildren: true
            }, options);
            if (this.children.length > 0) {
                let childrenToLoad = this.children.toArray();
                if (!options.loadPendingChildren) {
                    childrenToLoad = childrenToLoad.filter(c => !c.loadOnDemand);
                }
                childrenToLoad.forEach(c => { c.Load(options); });
                if (this.async) {
                    setTimeout(() => { this.OnLoad(); });
                }
            } else {
                setTimeout(() => { this.OnLoad(); });
            }
        }
    }

    protected OnPreLoad() {
        this._logger.debug('OnPreLoad ' + '<' + this.element.localName + '>');
        if ((!this.async || !this.parent) && this.children.length > 0) {
            if (!this.loadOnDemand) {
                // Ensure all child components complete loading.
                zip(...this.allChildren.filter(c => !c.loadOnDemand).map(c => c.loaded$)).subscribe(() => { this.OnLoad(); });
            } else {
                // Only need to subscribe to direct children's loaded event, since the component
                // will load its children sequentially when demanded to load.
                zip(...this.children.map(c => c.loaded$)).subscribe(() => { this.OnLoad(); });
            }
        }
    }

    protected OnLoad() {
        this._logger.debug('OnLoad ' + '<' + this.element.localName + '>');
        this.raiseLoaded();
    }

    /**
     * Raised at the end of the load stage, after the component has been loaded.
     *
     * Override this event to add more logic after the component has been loaded.
     */
    protected OnLoadComplete() {
        this._logger.debug('OnLoadComplete ' + '<' + this.element.localName + '>');
        this._loadCompleted = true;
        // Reattach view for change detection.
        this.changeDetectorRef.reattach();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Unload the component and reset the `loaded` status. This method recursively calls the `UnLoad` method
     * of each individual child component to unload them and then calls the `OnUnLoad` method on this component.
     *
     * Use the `OnUnLoad` event method to add more logic to unload the data on component if necessary.
     *
     * @param {{
     *         recursive: boolean;
     *     }} [options] The options to determine how the component should unload.
     * * `recursive`: When `true`, unload the children as well. The options will also be passed down to the children
     * if set to `true`. Default: `true`.
     */
    UnLoad(options?: {
        recursive?: boolean;
    }) {
        this._logger.debug('Unloading ' + '<' + this.element.localName + '>');
        // Extends default options.
        options = Object.assign({
            recursive: true
        }, options);
        this._loaded = false;
        this.resetValidationStatus();
        if (options.recursive) {
            this.children.forEach(_child => { _child.UnLoad(options); });
        }
        this.OnUnLoad();
    }

    protected OnUnLoad() {
        this._logger.debug('OnUnLoad ' + '<' + this.element.localName + '>');
    }

    protected Dispose() {
        this.UnLoad({ recursive: false });
        if (!this._loadedState.closed) {
            this._loadedState.unsubscribe();
        }
    }

    public Validate() {
        this.children.forEach(child => { child.Validate(); });
        this._validated = true;
        this._validatedOnce = true;
    }

    /**
     * Invalidate this component by resetting the {@link FPBaseComponent#validated} flag.
     * @see FPBaseComponent#validated
     */
    protected invalidate() {
        this._validated = false;
    }

    /**
     * Reset the {@link FPBaseComponent#validatedOnce} and {@link FPBaseComponent#validated} flags on this component.
     * @see FPBaseComponent#validatedOnce
     * @see FPBaseComponent#validated
     */
    resetValidationStatus() {
        this._validatedOnce = false;
        this._validated = false;
    }
}

export interface IFPComponentData {
    requestQueueCount: number;
}
