<ng-container *ngIf="(questionTitle && questionNumber) else control">
    <div class="question">
        <div class="question-number">{{questionNumber}}</div>
        <div class="question-content">
            <div class="question-content-title">{{questionTitle}} <span class="required" *ngIf="_required">*</span></div>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="control"></ng-container>
</ng-container>


<ng-template #control>
<div class="input-container" [class.with-question]="(questionTitle && questionNumber)">
    <i class="icon fa fa-search" [ngClass]="{'invalid-icon': !ngControl.valid && suburbInput.dirty && suburbInput.touched}"></i>
    <input class="form-control" (keyup)="HandleKeyUp($event)"
        [class.is-invalid]="!ngControl.valid && suburbInput.dirty && suburbInput.touched" type="text"
        placeholder="Filter By Postcode or Suburb" [formControl]="suburbInput" matInput [matAutocomplete]="autoSuburb">
    <div *ngIf="!ngControl.valid && suburbInput.dirty && suburbInput.touched">
        <small *ngIf="ngControl.getError('unresolved')" class="invalid-feedback d-block">The
            City/Suburb does not exist</small>
        <small *ngIf="ngControl.getError('required') && questionTitle" class="invalid-feedback d-block">
            {{StringHelper.format(CommonString.FIELD_REQUIRED, questionTitle)}}</small>
        <small *ngIf="ngControl.getError('required') && !questionTitle" class="invalid-feedback d-block">
            {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Suburb')}}</small>
    </div>
    <mat-autocomplete id="autoSuburb" name="autoMapSuburb" #autoSuburb="matAutocomplete"
        [displayWith]="getSuburbOptionText" (optionSelected)="SelectSuburb($event.option.value)">
        <mat-option *ngFor="let wp of presetSuburbs" [value]="wp" class="border-default border-bottom py-1">
            <p class="m-0 d-flex align-items-center"> {{wp.Name}}, {{wp.State?.Name}},
                {{wp.Postcode?.Name}} <span class="badge bg-primary">{{wp.Label}}</span></p>
        </mat-option>
        <mat-option *ngFor="let wp of filteredSuburbs" [value]="wp" class="border-default border-bottom py-1">
            <p class="m-0 d-flex align-items-center"> {{wp.Name}}, {{wp.State?.Name}},
                {{wp.Postcode?.Name}}</p>
        </mat-option>
    </mat-autocomplete>
</div>
</ng-template>