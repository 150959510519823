<mat-dialog-content class="form-wrapper-new add-member-popup ">
  <form [formGroup]="addFamilyMemberFormGroup">
    <div class="flex">
      <div class="add-family-member-wrapper-title position-relative mb-4">
        <h2 mat-dialog-title>
          {{ isFieldsDisabled ? 'Edit Family Member' : 'Add Family Member' }}
        </h2>        

        <div class="close ">
          <img class="popup-close" (click)="closeModal()" data-dismiss="modal" src="./assets/images/close-popup.png"
            alt="">
        </div>
      </div>
    </div>

    <div class="add-family-member-form">
      <div class="form-group row">
        <label for="inputLastName" class="col-sm-4 col-form-label">First Name*</label>
        <div class="col-sm-8">
          <div class="d-flex flex-column w-100">
            <input type="text" maxlength="50" id="txtFirstName" [readonly]="isFieldsDisabled" class="form-control" formControlName="txtFirstName"
              [ngClass]="
                            addFamilyMemberFormGroup.get('txtFirstName')
                              .invalid &&
                            (addFamilyMemberFormGroup.get('txtFirstName').dirty ||
                              addFamilyMemberFormGroup.get('txtFirstName')
                                .touched ||
                              btnNext)
                              ? 'form-control is-invalid'
                              : 'form-control'
                          " />
            <div *ngIf="
                            addFamilyMemberFormGroup.get('txtFirstName')
                              .invalid &&
                            (addFamilyMemberFormGroup.get('txtFirstName').dirty ||
                              addFamilyMemberFormGroup.get('txtFirstName')
                                .touched ||
                              btnNext)
                          ">
              <small *ngIf="
                              addFamilyMemberFormGroup.get('txtFirstName').errors
                                .required
                            " class="invalid-feedback d-block">First Name is required</small>
              <small *ngIf="
                              addFamilyMemberFormGroup.get('txtFirstName').errors
                                .pattern
                            " class="invalid-feedback d-block">This field does not accept special characters. Please
                try again</small>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputLastName" class="col-sm-4 col-form-label">Last Name*</label>
        <div class="col-sm-8">
          <div class="d-flex flex-column w-100">
            <input type="text" maxlength="50" id="txtLasttName" [readonly]="isFieldsDisabled" class="form-control" formControlName="txtLasttName"
              [ngClass]="
                            addFamilyMemberFormGroup.get('txtLasttName')
                              .invalid &&
                            (addFamilyMemberFormGroup.get('txtLasttName').dirty ||
                              addFamilyMemberFormGroup.get('txtLasttName')
                                .touched ||
                              btnNext)
                              ? 'form-control is-invalid'
                              : 'form-control'
                          " />
            <div *ngIf="
                            addFamilyMemberFormGroup.get('txtLasttName')
                              .invalid &&
                            (addFamilyMemberFormGroup.get('txtLasttName').dirty ||
                              addFamilyMemberFormGroup.get('txtLasttName')
                                .touched ||
                              btnNext)
                          ">
              <small *ngIf="
                              addFamilyMemberFormGroup.get('txtLasttName').errors
                                .required
                            " class="invalid-feedback d-block">Last Name is required</small>
              <small *ngIf="
                              addFamilyMemberFormGroup.get('txtLasttName').errors
                                .pattern
                            " class="invalid-feedback d-block">This field does not accept special characters. Please
                try again</small>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputLastName" class="col-sm-4 col-form-label">Gender*</label>
        <div class="col-sm-8">
          <div class="d-flex flex-column w-100">
            <select formControlName="txtGender" [attr.disabled]="isFieldsDisabled ? true : null" class="form-control custom-select" [ngClass]="
                          addFamilyMemberFormGroup.get('txtGender').invalid &&
                          (addFamilyMemberFormGroup.get('txtGender').dirty ||
                          addFamilyMemberFormGroup.get('txtGender').touched ||
                          btnNext)
                            ? 'is-invalid'
                            : ''
                        ">
              <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
              <option *ngFor="let gender of genders" [ngValue]="gender.GenderId">{{gender.Name}}</option>
            </select>
            <div *ngIf="
                            addFamilyMemberFormGroup.get('txtGender').invalid &&
                            (addFamilyMemberFormGroup.get('txtGender').dirty ||
                              addFamilyMemberFormGroup.get('txtGender').touched ||
                              btnNext)
                          ">
              <small *ngIf="
                              addFamilyMemberFormGroup.get('txtGender').errors
                                .required
                            " class="invalid-feedback d-block">Gender is required</small>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputFirstName" class="col-sm-4 col-form-label">Date of Birth*</label>
        <div class="col-sm-8">
          <div class="d-flex flex-column w-100">
            <div class="date-of-birth-input d-flex w-100 justify-content-between">
              <select  class="form-control mr-2 custom-select" [attr.disabled]="isFieldsDisabled ? true : null" formControlName="psdDay" [ngClass]="
                              addFamilyMemberFormGroup.get('psdDay').invalid &&
                              (addFamilyMemberFormGroup.get('psdDay').dirty ||
                                addFamilyMemberFormGroup.get('psdDay').touched ||
                                btnNext)
                                ? 'is-invalid'
                                : ''
                            " (change)="onDOBSelected();">
                <option value="" disabled selected>Day</option>
                <option *ngFor="let day of days" [ngValue]="day">{{day}}</option>
              </select>
              <select class="form-control mr-2 custom-select" [attr.disabled]="isFieldsDisabled ? true : null" formControlName="psdMonth" [ngClass]="
                              addFamilyMemberFormGroup.get('psdMonth').invalid &&
                              (addFamilyMemberFormGroup.get('psdMonth').dirty ||
                                addFamilyMemberFormGroup.get('psdMonth')
                                  .touched ||
                                btnNext)
                                ? 'is-invalid'
                                : ''
                            " (change)="onDOBSelected();">
                <option value="" disabled selected>Month</option>
                <option value="0">Jan</option>
                <option value="1">Feb</option>
                <option value="2">Mar</option>
                <option value="3">Apr</option>
                <option value="4">May</option>
                <option value="5">Jun</option>
                <option value="6">Jul</option>
                <option value="7">Aug</option>
                <option value="8">Sep</option>
                <option value="9">Oct</option>
                <option value="10">Nov</option>
                <option value="11">Dec</option>
              </select>
              <select class="form-control custom-select" [attr.disabled]="isFieldsDisabled ? true : null" formControlName="psdYear" [ngClass]="
                              addFamilyMemberFormGroup.get('psdYear').invalid &&
                              (addFamilyMemberFormGroup.get('psdYear').dirty ||
                                addFamilyMemberFormGroup.get('psdYear').touched ||
                                btnNext)
                                ? 'is-invalid'
                                : ''
                            " (change)="onDOBSelected();">
                <option value="" disabled selected>Year</option>
                <option *ngFor="let year of years" [ngValue]="year">{{year}}</option>
              </select>
            </div>

            <div *ngIf="
                            (addFamilyMemberFormGroup.get('psdDay').invalid &&
                              (addFamilyMemberFormGroup.get('psdDay').dirty ||
                                addFamilyMemberFormGroup.get('psdDay').touched ||
                                btnNext))                            
                          ">
              <small *ngIf="                             
                                addFamilyMemberFormGroup.get('psdDay')?.errors
                                ?.required
                            " class="invalid-feedback d-block">Date of Birth is required</small>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="txtMbType" class="col-sm-4 col-form-label">Member Type*</label>
        <div class="col-sm-8">
          <div class="d-flex flex-column w-100">
            <select class="form-control" [attr.disabled]="isFieldsDisabled ? true : null" [class.is-invalid]="addFamilyMemberFormGroup.get('txtMbType').invalid &&
                        (addFamilyMemberFormGroup.get('txtMbType').dirty ||
                          addFamilyMemberFormGroup.get('txtMbType').touched ||
                          btnNext)" (change)="onDOBSelected();" id="ddlFamMemType" formControlName="txtMbType"
              [compareWith]="ddlMemberTypeOption_CompareFn">
              <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
              <option *ngFor="let type of memberTypes" [ngValue]="type">{{type.Name}}</option>
            </select>
            <div *ngIf="
                            addFamilyMemberFormGroup.get('txtMbType').invalid &&
                            (addFamilyMemberFormGroup.get('txtMbType').dirty ||
                              addFamilyMemberFormGroup.get('txtMbType').touched ||
                              btnNext)
                          ">
              <small *ngIf="
                              addFamilyMemberFormGroup.get('txtMbType').errors
                                .required
                            " class="invalid-feedback d-block">Member Type is required</small>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputFirstName" class="col-sm-4 col-form-label">Primary Email*</label>
        <div class="col-sm-8">
          <div class="d-flex flex-column w-100">
            <input type="email" class="form-control" formControlName="txtPEmail" (blur)="txtPrimaryEmail_Blur($event)" [ngClass]="
                  addFamilyMemberFormGroup.get('txtPEmail').invalid &&
                      (addFamilyMemberFormGroup.get('txtPEmail').dirty ||
                      addFamilyMemberFormGroup.get('txtPEmail').touched ||
                        btnNext)
                        ? 'form-control is-invalid'
                        : 'form-control'
                    " />
            <div *ngIf="
                  addFamilyMemberFormGroup.get('txtPEmail').invalid &&
                      (addFamilyMemberFormGroup.get('txtPEmail').dirty ||
                      addFamilyMemberFormGroup.get('txtPEmail').touched ||
                        btnNext)
                    ">
              <small *ngIf="
                    addFamilyMemberFormGroup.get('txtPEmail').errors
                          .required
                      " class="invalid-feedback d-block">
                Primary Email is required</small>
              <small *ngIf="
                    addFamilyMemberFormGroup.get('txtPEmail').errors
                          .pattern
                      " class="invalid-feedback d-block">Primary Email is invalid</small>
              <small *ngIf="addFamilyMemberFormGroup.get('txtPEmail').getError('duplicated') != null" class="invalid-feedback d-block">
                Primary Email already exists</small>
            </div>
          </div>
        </div>
      </div>
      <app-mobile-number-registor [mandatory]="true" [isCountryCodeSet]="true" [isAus]="isAusMember"
        [control]="addFamilyMemberFormGroup.get('txtMobileNum')" [checkExistMobile]="true" 
        [memberTypePass]="addFamilyMemberFormGroup.get('txtMbType')" [dataModel]="data.model" 
        [primarySelectDate]="selectedDate" [primaryDependentSelectedDate]="dependentSelectedDate"
        formControlName="txtMobileNum" id="mobile-no-wrapper"
        [col_4]="col_4" #mobileNumberRegistorComponent>
      </app-mobile-number-registor>
      <div class="row form-group">
        <div class="col-md-4">
          <div *ngIf="imgUrl=='' || imgUrl==null" class="mx-auto">
            <img src="assets/images/upload-photo-image.png" alt="" class="img-fluid user-profile mx-auto" />
          </div>
          <div *ngIf="imgUrl!='' && imgUrl !=null" class="mx-auto">
            <app-member-image [imgSrc]="imgUrl" [htmlClass]="'img-fluid user-profile mx-auto'">
            </app-member-image>
          </div>
        </div>
        <div class="col-md-8">
          <div class="upload-img-wrapper pt-3">
            <input type="file" capture="camera" accept="image/*" id="cameraInput" name="cameraInput"
              id="selectedFamilyPhoto" style="display: none;" (change)="addImage($event)">
            <button class="btn btn-outline-primary btn-outline-new w-100 mt-2 d-md-none"
              onclick="document.getElementById('selectedFamilyPhoto').click();" type="button">
              Take photo with camera
            </button>
            <form #formUploadPhoto>
              <button (click)="uploadPhoto($event)" #fpfileupload type="button"
                class="btn btn-new btn-primary btn-mobile-100 d-flex d-md-none align-items-center justify-content-center mt-4">
                Upload from gallery
              </button>
            </form>
            <button (click)="uploadPhoto($event)" #fpfileupload type="button"
              class="btn btn-new d-md-flex btn-primary btn-mobile-100 align-items-center btn-upload mt-4">
              Upload Photo
              <i class="icon-upload"></i>
            </button>
            <div class="upload-img-wrapper-info">
              <p>
                Image uploaded needs to be a solo passport/portrait style photo
                with maximum size of 10mb and in formats JPEG or PNG.
                This photo will be used for your membership card.
              </p>
              <!-- <p class="mb-0">
                                This photo will be used for your membership card.
                            </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="add-family-member-wrapper-title mb-4">
      <h2 mat-dialog-title>Emergency Contact</h2>
    </div>
    <div class="add-family-member-form">
      <div class="form-group row">
        <label for="txtApName" class="col-sm-4 col-form-label">Name*</label>
        <div class="col-sm-8">
          <div class="d-flex flex-column w-100">
            <input type="text" maxlength="50" id="txtApName" class="form-control" formControlName="txtApName" [ngClass]="
                            addFamilyMemberFormGroup.get('txtApName').invalid &&
                            (addFamilyMemberFormGroup.get('txtApName').dirty ||
                              addFamilyMemberFormGroup.get('txtApName').touched ||
                              btnNext)
                              ? 'form-control is-invalid'
                              : 'form-control'
                          " />
            <div *ngIf="
                            addFamilyMemberFormGroup.get('txtApName').invalid &&
                            (addFamilyMemberFormGroup.get('txtApName').dirty ||
                              addFamilyMemberFormGroup.get('txtApName').touched ||
                              btnNext)
                          ">
              <small *ngIf="
                              addFamilyMemberFormGroup.get('txtApName').errors
                                .required
                            " class="invalid-feedback d-block">Emergency Contact Name is required</small>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="txtApRelation" class="col-sm-4 col-form-label">Relationship*</label>
        <div class="col-sm-8">
          <div class="d-flex flex-column w-100">
            <input type="text" maxlength="50" id="txtApRelation" class="form-control" formControlName="txtApRelation"
              [ngClass]="
                            addFamilyMemberFormGroup.get('txtApRelation').invalid &&
                            (addFamilyMemberFormGroup.get('txtApRelation').dirty ||
                              addFamilyMemberFormGroup.get('txtApRelation').touched ||
                              btnNext)
                              ? 'form-control is-invalid'
                              : 'form-control'
                          " />
            <div *ngIf="
                            addFamilyMemberFormGroup.get('txtApRelation').invalid &&
                            (addFamilyMemberFormGroup.get('txtApRelation').dirty ||
                              addFamilyMemberFormGroup.get('txtApRelation').touched ||
                              btnNext)
                          ">
              <small *ngIf="
                              addFamilyMemberFormGroup.get('txtApRelation').errors
                                .required
                            " class="invalid-feedback d-block">Relationship is required</small>
            </div>
          </div>
        </div>
      </div>

      <app-mobile-number-registor [mandatory]="true" [isCountryCodeSet]="true" [isAus]="isAusMember"
        [control]="addFamilyMemberFormGroup.get('txtApMobileNum')" formControlName="txtApMobileNum"
        id="mobile-no-wrapper" [col_4]="col_4" #mobileNumberRegistorComponentContact>
      </app-mobile-number-registor>
    </div>
    <div class="add-family-member-wrapper-title mb-4" *ngIf="showPreferredFacilities">
      <h2 mat-dialog-title>Preferred Facilities</h2>
      <h6>While you can use all the facilities on your FP program, we want to know which one you are most likely to
        attend on a regular basis</h6>
    </div>

    <div class="add-family-member-form" *ngIf="showPreferredFacilities">
      <div class="form-group row">
        <label for="inputLastName" class="col-sm-4 col-form-label">Favoured Facility/Gym*</label>
        <div class="col-sm-8">
          <div class="d-flex flex-column w-100">
            <input class="form-control" id="txtFavouredFacility" type="text" placeholder="Search facility by name"
              matInput [matAutocomplete]="ddlFavFacAutoComplete" formControlName="FavouredFacility"
              (blur)="txtFavouredFacility_Blur($event)" (input)="txtFavouredFacility_Input($event)"
              (focus)="onFocusParent(1)" [ngClass]="
                      addFamilyMemberFormGroup.get('FavouredFacility')
                          .invalid &&
                        (addFamilyMemberFormGroup.get('FavouredFacility').dirty ||
                        addFamilyMemberFormGroup.get('FavouredFacility')
                            .touched )
                          ? 'form-control is-invalid'
                          : 'form-control'
                      ">
            <mat-spinner [diameter]="14" *ngIf="favFacilityIsLoading"></mat-spinner>
            <div *ngIf="addFamilyMemberFormGroup.get('FavouredFacility').getError('unresolved')">
              <small class="invalid-feedback d-block">The
                facility does not exist</small>
            </div>
            <div
              *ngIf="((addFamilyMemberFormGroup.get('FavouredFacility').getError('required') || addFamilyMemberFormGroup.get('FavouredFacility').getError('required')) 
                    && (addFamilyMemberFormGroup.get('FavouredFacility')
                    .invalid && (addFamilyMemberFormGroup.get('FavouredFacility').dirty || addFamilyMemberFormGroup.get('FavouredFacility').touched)))">
              <small class="invalid-feedback d-block">
                Please start typing the name of your preferred facility and select from the drop down list
                presented</small>
            </div>
            <mat-autocomplete autoActiveFirstOption #ddlFavFacAutoComplete="matAutocomplete"
              [displayWith]="displayAutoText" (optionSelected)="SelectedFavFacility($event.option.value)"
              class="height1 fpscrolly border-2 rounded-10 border-default w-100">
              <mat-option *ngFor="let facility of favFacilitySearch" [value]="facility"
                class="border-default border-bottom py-1">
                <p class="m-0 d-flex align-items-center">{{facility.Name}}</p>
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputLastName" class="col-sm-4 col-form-label">Have you been an active
          member of a facility in
          the last two months?</label>
        <div class="col-sm-8">
          <div class="d-flex flex-column w-100">
            <mat-radio-group aria-label="Select an option" class="d-flex w-md-100 mt-3 mt-md-0 ">
              <mat-radio-button value="1" (change)="setIsExistingMember(true)" [checked]="isMemberHasExistingFacility">
                Yes</mat-radio-button>
              <mat-radio-button value="2" (change)="setIsExistingMember(false)"
                [checked]="!isMemberHasExistingFacility">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputLastName" class="col-sm-4 col-form-label" [ngClass]="{'text-muted': isOtherDacilityDisable}">If
          yes, which facility?*</label>
        <div class="col-sm-8">
          <div class="d-flex flex-column w-100">
            <div class="input-group input-group-new" [ngClass]="{'disabled': isOtherDacilityDisable}"
              [class.is-invalid]="addFamilyMemberFormGroup.get('ExistingFacility').getError('unresolved') || (addFamilyMemberFormGroup.get('ExistingFacility')
            .invalid && addFamilyMemberFormGroup.get('ExistingFacility').dirty && addFamilyMemberFormGroup.get('ExistingFacility').touched)">
              <input class="form-control" id="txtExistingFacility" type="text" placeholder="Search facility by name"
                matInput [matAutocomplete]="ddlMembrYAutoComplete" formControlName="ExistingFacility"
                (blur)="txtExistingFacility_Blur($event)" (input)="txtExistingFacility_Input($event)"
                (focus)="onFocusParent(2)">
              <div class="input-group-append">
                <span class="input-group-text" type="submit">
                  <i class="fas fa-search"></i>
                  <mat-spinner [diameter]="14" class="d-none"></mat-spinner>
                </span>
              </div>
            </div>
            <div *ngIf="addFamilyMemberFormGroup.get('ExistingFacility').getError('unresolved')">
              <small class="invalid-feedback d-block">The
                facility does not exist</small>
            </div>
            <mat-autocomplete autoActiveFirstOption #ddlMembrYAutoComplete="matAutocomplete"
              [displayWith]="displayAutoText" (optionSelected)="SelectedExistingFacility($event.option.value)"
              class="height1 fpscrolly border-2 rounded-10 border-default w-100">
              <mat-option *ngFor="let facility of existFacilitySearch" [value]="facility"
                class="border-default border-bottom py-1">
                <p class="m-0 d-flex align-items-center">{{facility.Name}}</p>
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="inputLastName" class="col-sm-4 col-form-label"
          [ngClass]="{'text-muted': isOtherDacilityDisable}">Please provide your
          existing facility if it
          wasn’t listed*</label>
        <div class="col-sm-8">
          <div class="d-flex flex-column w-100">
            <div>
              <input type="text" class="form-control" formControlName="ExistingFacilityOther"
                [class.is-invalid]="(addFamilyMemberFormGroup.get('ExistingFacility').invalid && (addFamilyMemberFormGroup.get('ExistingFacilityOther').dirty || addFamilyMemberFormGroup.get('ExistingFacilityOther').touched))">
              <div
                *ngIf="(addFamilyMemberFormGroup.get('ExistingFacility').invalid && (addFamilyMemberFormGroup.get('ExistingFacilityOther').dirty || addFamilyMemberFormGroup.get('ExistingFacilityOther').touched)) && (addFamilyMemberFormGroup.get('ExistingFacility').getError('unresolved') || (addFamilyMemberFormGroup.get('ExistingFacility')
                .invalid && addFamilyMemberFormGroup.get('ExistingFacility').dirty && addFamilyMemberFormGroup.get('ExistingFacility').touched))">
                <small class="invalid-feedback d-block">Please start typing the name of your preferred facility and
                  select from the drop down list presented</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="add-family-member-wrapper-title mb-4">
      <h2 mat-dialog-title>Medical Questions</h2>
    </div>

    <medical-questions-component [data]="data" #medicalQuestions></medical-questions-component>

    <div class="cta-wrapper">
      <button class="btn btn-new btn-primary btn-mobile-100 mt-3" [disabled]="!(addFamilyMemberFormGroup.valid) || !isApiCallSuccessful"
        (click)="familyMemberSubmit()">Confirm</button>
    </div>
  </form>
</mat-dialog-content>