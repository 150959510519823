import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CommonString } from 'src/app/constant/common-string';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { ProductServiceService } from 'src/app/services/product-service.service';
import { catchError, finalize, map } from 'rxjs/operators';
import { DataResult, SearchPaginationResult, ServiceType } from 'src/app/models';
import { RouterConstant } from 'src/app/constant/routerconstant';

class _ServiceType {
    constructor(
        public id: number = undefined,
        public name: string = undefined,
        public category: string = undefined,
        public status: boolean = undefined
    ) { }
}

class _ServiceTypes {
    constructor(
        public data: Array<_ServiceType> = new Array<_ServiceType>(),
        public totalItem: number = undefined
    ) { }
}

@Component({
    selector: 'app-viewproductsservices',
    templateUrl: './viewproductsservices.component.html',
    styleUrls: ['./viewproductsservices.component.css']
})
export class ViewproductsservicesComponent implements OnInit, OnDestroy {
    paging: any = {
        PageNumber: 1,
        PageSize: 10
    }
    requestQueueCount: number = 0;
    displayedColumns: string[] = ['category', 'name', 'status', 'edit'];
    dataSource: MatTableDataSource<any>;
    commonString = CommonString;
    routerAddProductOrService: string = "/" + RouterConstant.ROUTER_DASHBOARD + '/' + RouterConstant.ROUTER_PRODUCT_SERVICE_ADD;

    //material
    length: number;
    pageSize: number;
    pageSizeOptions: number[] = [10, 20, 50];

    routerConstant = RouterConstant;

    ngOnDestroy() {
        this.paging = null;
        this.requestQueueCount = null;
        this.displayedColumns = null;
        this.dataSource = null;
        this.length = null;
        this.pageSize = null;
        this.pageSizeOptions = null;
    }

    constructor(
        private commonService: CommonService,
        private service: ProductServiceService,
        private router: Router
    ) { }

    ngOnInit() {
        this.commonService.Header.title="View Products & Services";
        this.loadServiceTypes();
    }

    invoke(source: Observable<any>, handleResultCallback: Function, mapCallback: Function = () => { }) {
        this.requestQueueCount++;
        this.commonService.StartProgressBar();
        source.pipe(
            catchError((e) => { throw e; }),
            finalize(() => {
                this.requestQueueCount--;
                if (this.requestQueueCount <= 0) {
                    this.commonService.StopProgressBar();
                    this.requestQueueCount = 0;
                }
            }),
            map((data) => {
                return mapCallback(data);
            }),
        )
            .subscribe(
                res => {
                    handleResultCallback(res);
                },
                err => {
                    console.log(err);
                }
            )
    };

    loadServiceTypes() {
        this.invoke(
            this.service.load(this.paging),
            (data: _ServiceTypes) => {
                this.dataSource = new MatTableDataSource<any>(data.data);
                this.length = data.totalItem;
            },
            (data: DataResult<SearchPaginationResult<ServiceType>>) => {
                if (data.Success == false) {
                    console.log(data.Message);
                    return {};
                }

                let sts: _ServiceTypes = new _ServiceTypes();
                sts.totalItem = data.Data.TotalItem;
                data.Data.Results.map(
                    (st: ServiceType) => {
                        if (st.Category == null)
                            st.Category = {
                                Id: undefined,
                                Name: undefined,
                                DisplayOrder: undefined,
                                IsActive: undefined
                            };
                        const _st = {
                            id: st.Id,
                            name: st.Name,
                            category: st.Category.Name,
                            status: st.IsActive
                        }
                        sts.data.push(_st)
                    }
                );
                return sts;
            }
        );
    }

    /**
  * Running when user manipulate on material paginator.
  * @param event
  */
    paginatorChange(event: PageEvent) {
        this.paging.PageNumber = event.pageIndex + 1;
        this.paging.PageSize = event.pageSize
        this.loadServiceTypes();
    }

    /**
     * Running when user click column header of Material table.
     * @param sort
     */
    sortChange(sort: Sort) {
        const active: string = sort.active.toLowerCase();
        const isOderByAsc: boolean = sort.direction == "asc" ? false : true;
        this.paging.OrderBy = active;
        this.paging.OrderByAsc = isOderByAsc;
        this.loadServiceTypes();
    }

    createNew() {
        this.router.navigate([this.routerAddProductOrService]);
    }

}