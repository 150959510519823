import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MemberFormBase } from 'src/app/components/base';
import { DialogResult } from 'src/app/components/common-dialog/common-dialog.component';
import { Member } from 'src/app/models';
import { MessageBox, MessageBoxButton } from 'src/app/services/common-dialog.service';
import { MemberMembershipPackageCostSummaryComponent } from '../membership-package-cost-summary/membership-package-cost-summary.component';

@Component({
    selector: 'app-member-shopping-cart',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.css'],
    providers: [{ provide: MemberFormBase, useExisting: MemberShoppingCartComponent }]
})
export class MemberShoppingCartComponent extends MemberFormBase {
    @ViewChild(MemberMembershipPackageCostSummaryComponent) membershipPackageCostSummarySection: MemberMembershipPackageCostSummaryComponent;

    @Output() addFamilyMemberButtonClick = new EventEmitter();

    displayedColumns: string[] = ['PackageName', 'Members', 'Price', 'action'];
    dataSource = new MatTableDataSource<Member>();

    constructor(injector: Injector) { super(injector); }

    PatchValue(value: Member, opts) {
        const memberships = value.Memberships || [];
        this.membershipPackageCostSummarySection.membershipPackage = memberships.length > 0 && memberships[0] ? memberships[0].MembershipPackage : null;
        this.membershipPackageCostSummarySection.dataSource = [value].concat(value.FamilyMembers || []);
        super.PatchValue(value, opts);
    }

    btnAddFamilyMember_Click() {
        this.addFamilyMemberButtonClick.emit();
    }

    removeMember(index: number) {
        if (index > 0) {
            MessageBox.ShowCustom(this.dialog, 'Remove?', 'Remove?',
                'Removing this member will remove the member from the shopping cart as well as remove all details that were entered relating to this member. Would you like to proceed?',
                MessageBoxButton.YesNo).subscribe(
                    (dialogResult: DialogResult) => {
                        if (dialogResult.result == DialogResult.Yes) {
                            const members = <Member[]>this.getControl('FamilyMembers').value;
                            members.splice(index - 1, 1);
                            this.getControl('FamilyMembers').setValue(members);
                        }
                    }
                );
        }
    }
}
