import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { PatternConstant, RouterConstant } from "src/app/constant";
import { UserService } from "src/app/services/admin/user/user.service";
import { merge, empty, Observable } from "rxjs";
import { CommonService } from "src/app/services/common.service";
import {
  catchError,
  startWith,
  switchMap,
  finalize,
  map,
} from "rxjs/operators";
import {
  User,
  UserUpdating,
} from "src/app/models";
import { NgbDateStruct, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { MessageBox } from "src/app/services/common-dialog.service";
import { MatDialog } from "@angular/material/dialog";
import { CommonConstants } from "@fp/constant/common-constants";
import { MobileNumberComponent } from "@fp/components/control";

class _User {
  constructor(
    public id?: number,
    public titleId?: number,
    public firstName?: string,
    public lastName?: string,
    public middleName?: string,
    public preferredName?: string,
    public birthday?: NgbDateStruct,
    public genderId?: number,
    public isActive?: boolean,
    public phone?: string,
    public email?: string,
    public username?: string
  ) {}
}

class _CommonModel {
  constructor(public id?: number, public name?: string) {}
}

@Component({
  selector: "app-edituserdetails",
  templateUrl: "./edituserdetails.component.html",
  styleUrls: ["./edituserdetails.component.css"],
})
export class EdituserdetailsComponent implements OnInit, OnDestroy {
  parentFocus = 0;
  userFG: UntypedFormGroup;
  user: _User;
  requestQueueCount: number = 0;
  titles: _CommonModel[];
  genders: _CommonModel[];
  presentTime: NgbDateStruct;
  minTime: NgbDateStruct;
  isLoadingResults: boolean = false;
  submitClick: boolean = false;
  @ViewChild("popover") public popover: NgbPopover;
  @ViewChild("mobileNumberComponent") mobileNumberComponent: MobileNumberComponent<any>;
  mailSpinter = false;

  invalidPhone = false;
  mobileLength: number;
  phoneSpinter = false;
  editCurrentPhone = "";
  editCurrentMail = "";

  ngOnDestroy() {
    this.parentFocus = null;
    this.user = null;
    this.requestQueueCount = null;
    this.titles = null;
    this.genders = null;
    this.presentTime = null;
    this.minTime = null;
    this.editCurrentPhone = null;
    this.editCurrentMail = null;
    this.mobileLength = null;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private commonService: CommonService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.commonService.Header.title = "Edit User Details";
    this.commonService.App.isLoginSuccessDisplay = true;
    this.commonService.App.disableSideNav();
    this.createUserDetailsForm();
    this.getUser();
    this.getTitles();
    this.getGenders();
  }

  createUserDetailsForm() {
    this.userFG = this.formBuilder.group({
      id: [undefined],
      titleId: [undefined],
      firstName: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.VALID_NAME)],
      ],
      middleName: [undefined],
      lastName: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.VALID_NAME)],
      ],
      preferredName: [undefined],
      birthday: [undefined, [Validators.required]],
      genderId: [undefined, Validators.required],
      isActive: [true, Validators.required],
      phone: ["", [Validators.required]],
      email: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.EMAIL)],
      ],
      username: [""],
    });

    let time: Date = new Date();
    this.presentTime = {
      year: time.getFullYear(),
      month: time.getMonth() + 1,
      day: time.getDate() - 1,
    };
    this.minTime = {
      year: time.getFullYear() - 100,
      month: time.getMonth() + 1,
      day: time.getDate() - 1,
    };
  }

  invoke(
    source: Observable<any>,
    handleResultCallback: Function,
    mapCallback: Function = () => {}
  ) {
    this.requestQueueCount++;
    this.isLoadingResults = true;
    source
      .pipe(
        catchError((e) => {
          throw e;
        }),
        finalize(() => {
          this.requestQueueCount--;
          if (this.requestQueueCount <= 0) {
            this.isLoadingResults = false;
            this.requestQueueCount = 0;
          }
        }),
        map((data) => {
          return mapCallback(data);
        })
      )
      .subscribe(
        (res) => {
          handleResultCallback(res);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getTitles() {
    let _titles: Array<_CommonModel> = new Array<_CommonModel>();
    CommonConstants.GET_TITLES.map((title) => {
      const _title: _CommonModel = {
        id: title.Id,
        name: title.Name,
      };
      _titles.push(_title);
    });
    this.titles = _titles;
  }

  getGenders() {
    let _genders: Array<_CommonModel> = new Array<_CommonModel>();
    CommonConstants.GET_GENDER_NAMES.map((gender) => {
      const _title: _CommonModel = {
        id: gender.Id,
        name: gender.Name,
      };
      _genders.push(_title);
    });
    this.genders = _genders;
  }

  getUser() {
    const username: string = this.commonService.GetUserObject()?.UserName;
    if (username === "" || username === undefined || username === null)
      this.router.navigate([RouterConstant.ROUTER_HOME]);
    this.invoke(
      this.userService.getFirtLoginUser(username),
      (data) => {
        this.userFG.patchValue(data);
      },
      (udata) => {
        const user: User = udata.Data.UserObject;
        console.log("Got the user: ", user);
        if (user.UserId == 0)
          this.router.navigate([RouterConstant.ROUTER_HOME]);
        if (user.MobileNumber?.match(/^\++/)) {
          user.MobileNumber = user.MobileNumber.replace(/^\++/, '+');
        }
        this.editCurrentPhone = user.MobileNumber;
        this.editCurrentMail = user.EmailAddress;
        let _user;
        if (!user.MobileNumber) {
          _user = {
            id: user.UserId,
            titleId: user.TitleId == null ? undefined : user.TitleId,
            firstName: user.FirstName,
            middleName: user.MiddleName,
            lastName: user.LastName,
            preferredName: user.PreferredName,
            birthday: this.userService.parseStringTo_dd_mm_yyyy(
              user.DateOfBirth
            ),
            genderId: user.GenderId == null ? undefined : user.GenderId,
            isActive: user.IsActive,
            email: user.EmailAddress,
            username: user.UserName,
          };
        } else {
          _user = {
            id: user.UserId,
            titleId: user.TitleId == null ? undefined : user.TitleId,
            firstName: user.FirstName,
            middleName: user.MiddleName,
            lastName: user.LastName,
            preferredName: user.PreferredName,
            birthday: this.userService.parseStringTo_dd_mm_yyyy(
              user.DateOfBirth
            ),
            genderId: user.GenderId == null ? undefined : user.GenderId,
            isActive: user.IsActive,
            phone: user.MobileNumber,
            email: user.EmailAddress,
            username: user.UserName,
          };
        }
        return _user;
      }
    );
  }

  public validateUserDetailsSection(): boolean {
    if (
      this.userFG.get("firstName").valid &&
      this.userFG.get("lastName").valid &&
      this.userFG.get("birthday").valid &&
      this.userFG.get("genderId").valid
    )
      return true;
    else return false;
  }

  protected validateContactDetails() {
    if (
      this.userFG.get("phone").valid &&
      this.userFG.get("email").valid &&
      !this.phoneSpinter &&
      !this.mailSpinter
    )
      return true;
    else return false;
  }

  // Check email existed
  checkExistEmail() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          let newName = this.userFG.get("email").value.trim();
          if (newName.length > 0 && this.userFG.get("email").valid) {
            if (newName != this.editCurrentMail) {
              this.mailSpinter = true;
              this.userFG.get("email").setErrors({ waiting: true });
              return this.userService.checkExistEmailv2(newName);
            } else {
              this.mailSpinter = false;
            }
          } else {
            this.mailSpinter = false;
          }
          return empty();
        }),
        catchError((e) => {
          throw e;
        })
      )
      .subscribe((result) => {
        const enctypted_data = result.Data;
        const dectypted_data = this.commonService.D_FP_ResponseData(
          enctypted_data
        );
        const ret = JSON.parse(dectypted_data);
        this.mailSpinter = false;
        this.userFG.get("email").setErrors({ waiting: false });
        this.userFG.updateValueAndValidity();
        if (result.Success) {
          if (result.Data !== null && ret.IsExist) {
            this.userFG.get("email").setErrors({ duplicated: true });
          } else {
            this.userFG.get("email").setErrors(null);
          }
        } else {
          MessageBox.ShowError(
            this.dialog,
            "An error has occurred. Please contact the administrator"
          );
        }
      });
  }

  //Region phone field
  private checkValidatePhone() {
    if (this.userFG.get("phone").value.indexOf("00") == 0) {
      this.invalidPhone = true;
    } else {
      this.invalidPhone = false;
    }
  }

  submit() {
    if (this.userFG.invalid) {
      this.submitClick = true;
      return;
    }
    const _user: _User = this.userFG.value;
    let userUpdate: UserUpdating = new UserUpdating();
    this.mobileLength = (<string>this.userFG.get("phone").value).length;
    if (this.mobileLength >= 11 && this.mobileLength <= 15) {
      if (this.userFG.get("phone").value[3] === "0") {
        _user.phone = this.userFG.get("phone").value.slice(0, 3) + this.userFG.get("phone").value.slice(4);
      } else {
        _user.phone = this.userFG.get("phone").value.slice(0, 3) + this.userFG.get("phone").value.slice(3);
      }
    } else {
      _user.phone = this.userFG.get("phone").value;
    }

    userUpdate = {
      UserId: _user.id,
      TitleId: _user.titleId,
      FirstName: _user.firstName,
      LastName: _user.lastName,
      MiddleName: _user.middleName,
      PreferredName: _user.preferredName,
      GenderId: _user.genderId,
      DateOfBirth: this.userService.format_dd_mm_yyyy_ToString(_user.birthday),
      MobileNumber: _user.phone,
      EmailAddress: _user.email.toLowerCase().trim(),
      IsPending: false,
      RoleIds: [],
      AssignFacilitatorEmployers: [],
      FacilityIds: [],
      IsActive: _user.isActive,
      UserName: _user.username,
    };

    this.commonService.StartGlobalProgressBar();
    this.userService.updateFirstLoginUser(userUpdate).subscribe(async (encryptedResult) => {
      this.commonService.StopGlobalProgressBar();
      const decryptedData = this.commonService.D_FP_ResponseData(encryptedResult);
      const result = JSON.parse(decryptedData);
      this.userService.checkDataRespone(result, userUpdate.UserName).subscribe(async () => {
        if (result.Success === true) {
          const userObject = {
            ...this.commonService.GetUserObject(),
            ...{
              UserId: userUpdate.UserId,
              TitleId: userUpdate.TitleId,
              FirstName: userUpdate.FirstName,
              LastName: userUpdate.LastName,
              MiddleName: userUpdate.MiddleName,
              PreferredName: userUpdate.PreferredName,
              GenderId: userUpdate.GenderId,
              DateOfBirth: userUpdate.DateOfBirth,
              MobileNumber: userUpdate.MobileNumber,
              EmailAddress: userUpdate.EmailAddress,
              IsActive: userUpdate.IsActive,
              UserName: userUpdate.UserName,
            },
          };
          this.commonService.SetUserObject(userObject);
          this.commonService.Header.title = 'Fitness Passport Admin';
          this.commonService.App.enableSideNav();
          await this.router.navigate([RouterConstant.ROUTER_DASHBOARD]);
          window.location.reload(); // Force reload of the page to ensure dashboard is not blank by forcing re-evaluation
        } else {
          console.error(`Unable to update user, update first login user api result: ${JSON.stringify(result)}`);
        }
      });
    });
  }

  checkValidate(controlName: string) {
    const form = this.userFG.get(controlName);
    let isBool: boolean = false;
    isBool = form.invalid && (form.touched || form.dirty || this.submitClick);
    return isBool;
  }

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }
  onBlurParent(focusNumbr) {
    this.parentFocus = focusNumbr;
  }
  getClassFocused(vlFocused) {
    if (this.parentFocus == vlFocused) {
      return "focused";
    } else {
      return "";
    }
  }
}
