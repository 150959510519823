<header #fpheader [ngClass]="!isLogin ? 'shadow-search' : ''">
    <div class="container-fluid">
        <div class="d-lg-flex justify-content-between align-items-center position-relative">
            <div *ngIf="showSideNavToggle; else noSideNavToggleBlockFirstHeader"
                class="d-flex justify-content-between align-items-center d-lg-block col-lg-2 px-0">
                <button mat-button (click)="toggleSidenav()"
                    class="border-0 rounded-10 bg-transparent text-primary btn-sidabar d-block d-lg-none"><i
                        class="fas fa-bars"></i></button>
                <a href="/login" class="col-5 px-0 col-md-3 col-lg-9 logo text-center">
                    <img src="./assets/images/logo.png" alt="Fitness Passport">
                </a>
                <button type="button" mat-button
                    class="border-0 rounded-10 bg-transparent text-primary d-lg-none btn-collapsesearch"
                    (click)="toggleSearch()" data-toggle="collapse" data-target="#collapseExample"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                    <i class="fas fa-search"></i>
                </button>
            </div>
            <ng-template #noSideNavToggleBlockFirstHeader>
                <div class="d-flex justify-content-between align-items-center d-lg-block col-lg-2 px-0">
                    <a href="/login" class="col-5 px-0 col-lg-9 logo">
                        <img src="./assets/images/logo.png" alt="Fitness Passport">
                    </a>
                    <button type="button" mat-button
                        class="border-0 rounded-10 bg-transparent text-primary d-lg-none btn-collapsesearch"
                        (click)="toggleSearch()" data-toggle="collapse" data-target="#collapseExample"
                        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
            </ng-template>

            <div class="d-none d-lg-block">
                <app-navbar #navbarmd></app-navbar>
            </div>
        </div>
    </div>
    <div class="bg-primary shadow-title fp-title container-fluid" *ngIf="isLogin">
        <div class="toggle-and-title" *ngIf="showSideNavToggle; else noSideNavToggleBlockSecondHeader">
            <button mat-button (click)="toggleSidenav()"
                class="border-0 bg-primary text-light btn-toggle-sidebar d-none d-lg-block">
                <i class="fas fa-bars"></i>
            </button>
            <h3 class="py-2 text-light font-weight-normal m-0 text-center text-md-left">
                {{ title }}
            </h3>
        </div>
        <ng-template #noSideNavToggleBlockSecondHeader>
            <h3 class="py-2 text-light font-weight-normal m-0 fp-pl-1 text-center text-md-left">
                {{ title }}
            </h3>
        </ng-template>
    </div>
</header>