<div matDialogTitle class="modal-header justify-content-start">
    <h5 class="text-primary">
        Approve Payment Request
        <button type="button" class="close" aria-label="Close" mat-dialog-close>
            <span aria-hidden="true">&times;</span>
        </button>
    </h5>
    <!-- Close Button -->
</div>

<div matDialogContent>
    <!-- Main Mat Spinner -->
    <div class="loading-shade-global" *ngIf="isLoading">
        <mat-spinner [diameter]="75"></mat-spinner>
    </div>
    <div class="container">
        <div class="row">
            <div class="my-4">
                <div class="border-default rounded-10 border-2">
                    <div class="tablecontainer">
                        <table mat-table [dataSource]="dataSource" matSort matSortActive="ToDate" matSortDirection="asc"
                            class="rounded-10">
                            <ng-container matColumnDef="FromDate">
                                <th mat-header-cell *matHeaderCellDef>From Date</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.PaymentDateFrm | dateFormatPipe }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="ToDate">
                                <th mat-header-cell *matHeaderCellDef>To Date</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.PaymentDateTo | dateFormatPipe }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="VisitCountHistory">
                                <th mat-header-cell *matHeaderCellDef>Visit Count History</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.VisitCount }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="PaymentHistory">
                                <th mat-header-cell *matHeaderCellDef>Payment History</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.DollarAmount | currency }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Status">
                                <th mat-header-cell *matHeaderCellDef>Status</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.PRStatus }}
                                </td>
                            </ng-container>

                            <tr class="currentPaymentRequest" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr class="currentPaymentRequest" mat-row *matRowDef="let row; columns: displayedColumns">
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Payment Calc text and box (read only) -->
    <div class="form-group row">
        <label for="txtCalcMethod" class="col-md-3 col-form-label text-primary"><b>Payment Calculation
                Method:</b></label>

        <div class="col-md-4">
            <input id="txtCalcMethod" class="form-control" placeholder="{{ paymentCalculationMethod }}" readonly />
        </div>
    </div>

    <!-- Anti-Fraud text and box (read only) -->
    <div class="form-group row">
        <label for="txtrDescription" class="col-md-3 col-form-label text-primary"><b>Anti-Fraud Check:</b></label>

        <div class="col">
            <input id="txtCalcMethod" class="form-control" placeholder="{{ antiFCheck }}" readonly
                [ngStyle]="getAFWidth()" />
        </div>
    </div>

    <form [formGroup]="PaymentRequestNoteFormGroup">
        <div class="form-group row">
            <label for="paymentRequestNoteText" class="col-md-3 col-form-label text-primary"><b>Notes</b></label>
            <div class="col-md-9 col-lg-10 offset-md-3 offset-lg-2">
                <div class="d-flex flex-column w-100">
                    <div class="border-default rounded-10 border-2">
                        <div class="tablecontainer">
                            <table mat-table [dataSource]="dtmatnotes" class="rounded-10">

                                <!-- User Column -->
                                <ng-container matColumnDef="Username">
                                    <th mat-header-cell *matHeaderCellDef>User</th>
                                    <td mat-cell *matCellDef="let element"> {{element.User}} </td>
                                </ng-container>

                                <!-- DateTime Column -->
                                <ng-container matColumnDef="DateTimeDisplay">
                                    <th mat-header-cell *matHeaderCellDef>Date/Time</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DateTime }} </td>
                                </ng-container>

                                <!-- Note Column -->
                                <ng-container matColumnDef="Note">
                                    <th mat-header-cell *matHeaderCellDef>Note</th>
                                    <td mat-cell *matCellDef="let element"> {{element.Note}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumnsNotesTable;">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumnsNotesTable;">
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>


<!-- Approve, Reject and hold Buttons -->
<div matDialogActions class="modal-footer w-100 justify-content-between">
    <span></span>
    <div style="margin-top: 1em; text-align: center">
        <button id="btnApprove" (click)="approveButton()" type="button" class="btn btn-success btnApprove">
            <i class="far fa-check-circle fa-3x"></i>
        </button>
        <br />
        <label for="btnApprove" class="text-primary"><b> Approve</b></label>
    </div>
    <span></span>
    <div style="margin-top: 1em; text-align: center">
        <button id="btnReject" (click)="rejectButton()" class="btn btn-danger btnReject">
            <i class="far fa-times-circle fa-3x"></i>
        </button>
        <br />
        <label for="btnReject" class="text-primary"><b> Reject</b></label>
    </div>
    <span></span>
    <div style="margin-top: 1em; text-align: center">
        <button id="btnHold" (click)="holdButton()" class="btn btn-warning btnHold">
            <i class="far fa-question-circle fa-3x"></i>
        </button>
        <br />
        <label for="btnHold" class="text-primary"><b>Put on Hold </b></label>
    </div>
    <span></span>
</div>