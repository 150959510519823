// tslint:disable: curly
import { Injectable, Optional, Provider } from '@angular/core';
import { DateHelper } from './date-helper';

export enum LogLevel {
    OFF = 0,
    LOG = 1,
    ERROR = 2,
    WARN = 3,
    INFO = 4,
    DEBUG = 5
}

export class LoggerConfig {
    level: LogLevel;
    global: boolean;
    persistent: boolean;
    layout: string;
}

const DEFAULT_CONFIG: LoggerConfig = {
    level: LogLevel.WARN,
    global: true,
    persistent: false,
    layout: '[{date}] [{level}] {message}'
};

@Injectable()
export class Logger {
    private _level: LogLevel;
    get level(): LogLevel { return this._level; }
    set level(level: LogLevel) {
        if (this.persistent) this._storeLevel(level);
        this._level = level;
    }
    private _globalAs = 'FP.Logger';
    private _persistent: boolean;
    get persistent() { return this._persistent; }
    private _persistAs = 'fp.logger.level';
    private _layout: string;
    get layout() { return this._layout; }

    private get _isIEOrEdge() { return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent); }

    constructor(@Optional() options?: LoggerConfig) {
        const { level, global, persistent, layout } = Object.assign({}, DEFAULT_CONFIG, options);

        this._level = level;
        this._layout = layout;
        // Some browsers don't implement "debug" function, so we use "log" instead.
        this.debugFn = console.debug || console.log;

        if (global) this.initGlobal();
        if (persistent || this._getStoredLevel()) this.persistLevel();
    }

    private debugFn: (message?: any, ...params: any[]) => void;

    private _getStoredLevel(): LogLevel { return Number(localStorage.getItem(this._persistAs)); }

    private _storeLevel(level: LogLevel) { localStorage.setItem(this._persistAs, <any>level); }

    initGlobal() { window[this._globalAs] = this; }

    persistLevel() {
        this._persistent = true;
        const storedLevel = this._getStoredLevel();
        if (storedLevel > -1) {
            this._level = storedLevel;
        } else {
            this._storeLevel(this.level);
        }
    }

    unpersistLevel() {
        this._persistent = false;
        localStorage.removeItem(this._persistAs);
    }

    private formatLayout(message: any, level: LogLevel) {
        let formattedMessage = this.layout;
        formattedMessage = formattedMessage.replace(/\{level\}/g, LogLevel[level]);
        const match = formattedMessage.match(/\{date(?:\:[^\}]+)?\}/g);
        if (match) {
            const parts = match[0].replace(/[\{\}]/g, '').split(':');
            let dateFormat = 'HH:mm:ss';
            if (parts.length > 1) {
                dateFormat = parts[1];
            }
            formattedMessage = formattedMessage.replace(/\{date(?:\:[^\}]+)?\}/g, DateHelper.format(new Date(), dateFormat));
        }
        formattedMessage = formattedMessage.replace(/\{message\}/g, message);
        return formattedMessage;
    }

    error(message?: any, ...params: any[]) {
        if (this.level >= LogLevel.ERROR) {
            let sourceError: any = null;
            if (typeof message !== 'string') {
                sourceError = message;
                if (message instanceof Error) {
                    message = message.message;
                } else {
                    message = 'Unspecified error';
                }
            }
            const customError = { message: message, innerError: sourceError };
            Error.captureStackTrace(customError);
            console.error(this.formatLayout(message, LogLevel.ERROR) + '\n', ...(params || []).concat(customError));
        }
    }

    warn(message?: any, ...params: any[]) {
        if (this.level >= LogLevel.WARN) {
            if (typeof message !== 'string') {
                params = (params || []).concat(message);
            }
            console.warn(this.formatLayout(message, LogLevel.WARN), ...params);
        }
    }

    info(message?: any, ...params: any[]) {
        if (this.level >= LogLevel.INFO) {
            if (typeof message !== 'string') {
                params = (params || []).concat(message);
            }
            if (this._isIEOrEdge) {
                // IE and Edge don't support CSS styling in console.
                console.info(this.formatLayout(message, LogLevel.INFO), ...params);
            } else {
                console.info(`%c${this.formatLayout(message, LogLevel.INFO)}`, 'color:green', ...params);
            }
        }
    }

    debug(message?: any, ...params: any[]) {
        if (this.level >= LogLevel.DEBUG) {
            if (typeof message !== 'string') {
                params = (params || []).concat(message);
            }
            if (this._isIEOrEdge) {
                // IE and Edge don't support CSS styling in console.
                this.debugFn(this.formatLayout(message, LogLevel.DEBUG), ...params);
            } else {
                this.debugFn(`%c${this.formatLayout(message, LogLevel.DEBUG)}`, 'color:purple', ...params);
            }
        }
    }

    log(message?: any, ...params: any[]) {
        if (this.level >= LogLevel.LOG) {
            if (typeof message !== 'string') {
                params = (params || []).concat(message);
            }
            console.log(this.formatLayout(message, LogLevel.LOG), ...params);
        }
    }
}

export const OFF_LOGGER_PROVIDER: Provider = { provide: LoggerConfig, useValue: { level: LogLevel.OFF } };
export const LOG_LOGGER_PROVIDER: Provider = { provide: LoggerConfig, useValue: { level: LogLevel.LOG } };
export const ERROR_LOGGER_PROVIDER: Provider = { provide: LoggerConfig, useValue: { level: LogLevel.ERROR } };
export const WARN_LOGGER_PROVIDER: Provider = { provide: LoggerConfig, useValue: { level: LogLevel.WARN } };
export const INFO_LOGGER_PROVIDER: Provider = { provide: LoggerConfig, useValue: { level: LogLevel.INFO } };
export const DEBUG_LOGGER_PROVIDER: Provider = { provide: LoggerConfig, useValue: { level: LogLevel.DEBUG } };
