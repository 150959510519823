import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RouterConstant } from '@fp/constant';
import { CommonService } from '@fp/services';

@Component({
  selector: 'app-continue-reactivation',
  templateUrl: './continue-reactivation.component.html',
  styleUrls: ['./continue-reactivation.component.css']
})
export class ContinueReactivationComponent {

  constructor(private router: Router, protected commonService: CommonService) {
    const uobject = this.commonService.GetUserObject();
    const fullname = uobject.FirstName;
    this.commonService.Header.title = "Hello, " + fullname + ". Welcome back!";

  }


  ContinueReactivation() {
    this.router.navigate([RouterConstant.NAVIGATOR_REACTIVATE_MEMBER]);
  }
  isReactivationVisible(): boolean {
    return Boolean(localStorage.getItem("allowActivationFlag"));
  }
  
}
