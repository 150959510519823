import { Component, OnInit } from '@angular/core';
import { RouterConstant } from 'src/app/constant/routerconstant';

@Component({
    selector: 'app-facilities',
    templateUrl: './facilities.component.html',
    styleUrls: ['./facilities.component.css']
})
export class FacilitiesComponent implements OnInit {

    RouterConstant = RouterConstant;

    constructor() { }

    ngOnInit() {
    }

}