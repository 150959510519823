import { Component, Injector, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FacilityFormBase, FacilityFormMode } from 'src/app/components/base';
import { CommonString } from 'src/app/constant/common-string';
import { StringHelper } from 'src/app/helpers';
import { DataResult, Image, FacilityDocumentViewModel } from 'src/app/models';
import { MessageBox } from 'src/app/services/common-dialog.service';
import { FacilityService } from 'src/app/services/facility.service';
import { FpFileData, FpFileUploadComponent, FpFileEvent } from 'src/app/components/control/fp-file-upload/fp-file-upload.component';

@Component({
  selector: 'app-facility-additional-attachments',
  templateUrl: './additional-attachments.component.html',
  styleUrls: ['./additional-attachments.component.css'],
  providers: [{ provide: FacilityFormBase, useExisting: FacilityAdditionalAttachmentsComponent }]
})

export class FacilityAdditionalAttachmentsComponent extends FacilityFormBase {
  dataDocumentAttachments: FacilityDocumentViewModel[] = [];
  dataDocumentAttachmentsRemoved: number[] = [];

  get control() { return this.form.get('Photos'); }
  photos: Array<Image> = [];
  commonString = CommonString;
  @ViewChild('facilityPhoto') facilityPhoto: FpFileUploadComponent;
  @ViewChild('facilityDocument') facilityDocument: FpFileUploadComponent;

  rmImages: Array<Image> = [];

  constructor(
    injector: Injector,
    private service: FacilityService
  ) {
    super(injector);
  }

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      UpdatePhotoActivity: fb.group({
        RemovedPhotoIds: [[]],
        NewOrUpdatedPhotos: [[]],
      }),
      SaveDocument: fb.group({
        RemoveDocumentIds: [[]],
        NewOrUpdateDocuments: [[]]
      })
    });
  }

  OnLoad() {
    if (this.data.mode === FacilityFormMode.Edit) {
      this.loadPhotos();
      this.loadDocuments();
    } else {
      super.OnLoad();
    }
  }

  loadPhotos() {
    const facilityId: number = this.data.model.FacilityId;
    this.Invoke(
      this.service.getPhotos(facilityId),
      {
        onSuccess: (edata: string) => {
          const dectypted_data = this.commonSvc.D_FP_ResponseData(edata);
          const rdata: DataResult<Image[]> = JSON.parse(dectypted_data);
          if (rdata.Success) {
            this.photos = rdata.Data;
          } else {
            this.HandleResponseError(rdata);
          }
          this.changeDetectorRef.markForCheck();
          super.OnLoad();
        }
      }
    );
  }

  UnLoad() {
    this.ClearLocalData();
    super.UnLoad();
  }

  private ClearLocalData() {
    this.dataDocumentAttachments = [];
    this.dataDocumentAttachmentsRemoved = [];
    this.rmImages = [];
    this.photos = [];
    this.getControl('UpdatePhotoActivity').get('RemovedPhotoIds').patchValue([]);
    this.getControl('UpdatePhotoActivity').get('NewOrUpdatedPhotos').patchValue([]);
    this.getControl('SaveDocument').get('RemoveDocumentIds').patchValue([]);
    this.getControl('SaveDocument').get('NewOrUpdateDocuments').patchValue([]);
  }

  PatchValue(value, option) {
    this.ClearLocalData();
    super.PatchValue(value, option);
  }

  removeImage(image: Image, index: number) {
    this.facilityPhoto.isReadonly = false;
    this.facilityPhoto.ResetFileInputValue();
    const id: number = image.Id;
    if (id !== 0) {
      this.rmImages.push(image);
    }
    this.photos = this.photos.filter((p, i) => i != index);

    this.save();
  }

  photos_UploadCompleted(event) {
    this.facilityPhoto.ResetFileInputValue();
    const ret: FpFileData = event;
    const image: Image = {
      Id: 0,
      Data: null,
      FileName: ret.originfilename,
      Location: ret.filedata.Location
    };
    if (this.rmImages.length > 0) {
      this.rmImages[0].FileName = ret.originfilename;
      this.rmImages[0].Data = null;
      this.rmImages[0].Location = ret.filedata.Location;
      this.photos.push(this.rmImages[0]);
      this.rmImages = this.rmImages.filter(p => p.Id != this.rmImages[0].Id);
    } else {
      this.photos.push(image);
    }

    this.save();
  }

  _fileSelected(event: FpFileEvent) {
    if (event.file.type !== 'image/png' &&
      event.file.type !== 'image/jpg' &&
      event.file.type !== 'image/jpeg') {
      event.cancel();
      MessageBox.ShowError(this.dialog,
        StringHelper.format(CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE, 'JPEG or PNG')
      );
    } else if (this.photos.length === 6) {
      event.cancel();
      MessageBox.ShowError(this.dialog,
        'A maximum of 6 images can be uploaded and each image needs to be less than 10mb');
    } else if (event.file.size > this.commonString.MAX_UPLOAD_FILE_SIZE_10MB) {
      event.cancel();
      MessageBox.ShowError(this.dialog, 'Maximum file size is 10MB');
    }
  }

  public AttachmentAdditionalSelected(event: FpFileEvent) {
    const filesTypeAllow = [
      'application/pdf',
      'application/vnd.ms-excel',
      'application/msword',
      'text/plain',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
    if (filesTypeAllow.indexOf(event.file.type) === -1) {
      event.cancel();
      MessageBox.ShowError(this.dialog,
        StringHelper.format(CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE, 'PDF, DOCX, DOC, XLS, XLSX, TXT')
      );
    }
  }

  save() {
    const removePhotos: Array<number> = [...this.rmImages.map(p => p.Id)];
    let actionPhotos = this.photos;
    actionPhotos = JSON.parse(JSON.stringify(actionPhotos));
    this.getControl('UpdatePhotoActivity').get('RemovedPhotoIds').patchValue(removePhotos);
    this.getControl('UpdatePhotoActivity').get('NewOrUpdatedPhotos').patchValue(actionPhotos);
    this.getControl('SaveDocument').get('RemoveDocumentIds').patchValue(this.dataDocumentAttachmentsRemoved);
    this.getControl('SaveDocument').get('NewOrUpdateDocuments').patchValue(this.dataDocumentAttachments);
  }

  public AddFile_UploadCompleted(event) {
    const facilityId: number = this.data.model.FacilityId;
    const ret: FpFileData = event;
    this.dataDocumentAttachments.push({
      FacilityId: facilityId,
      DocumentLocation: ret.filedata.Location,
      FileName: ret.originfilename,
      IsActive: true,
      Id: 0
    });

    this.save();
  }

  public RemoveAttachmentByType(item: FacilityDocumentViewModel) {
    if (item.Id && item.Id > 0) { // remove file in database
      this.dataDocumentAttachmentsRemoved.push(item.Id);
    }
    const index = this.dataDocumentAttachments.indexOf(item);
    this.dataDocumentAttachments.splice(index, 1);

    this.save();
  }

  loadDocuments() {
    const facilityId: number = this.data.model.FacilityId;
    this.Invoke(
      this.service.getDocuments(facilityId),
      {
        onSuccess: (edata: string) => {
          const dectypted_data = this.commonSvc.D_FP_ResponseData(edata);
          const rdata = JSON.parse(dectypted_data);
          if (rdata.Success) {
            this.dataDocumentAttachments = rdata.Data;
          } else {
            this.HandleResponseError(rdata);
          }
          super.OnLoad();
          this.changeDetectorRef.markForCheck();
        }
      }
    );
  }

}
