export enum CommonDataType {
    Gender,
    Title,
    Country,
    UserGroup,
    EntryPointType,
    CommsType,
    DraftType,
    EmployerStatus,
    EmployerType,
    FacilityContactType,
    FacilityStatus,
    FacilityType,
    IndustrySector,
    MemberCancellationAction,
    MemberCancellationReason,
    MemberCardStatus,
    MemberCustomFieldType,
    MembershipPackageType,
    MembershipStatus,
    MemberStatus,
    MemberType,
    PaymentBatchType,
    PaymentCalculationMethod,
    PaymentDate,
    PaymentFrequency,
    PaymentMethod,
    PaymentPeriod,
    PaymentPreference,
    PaymentRequestStatus,
    PaymentStatus,
    PaymentType,
    ProductStatus,
    ServiceCategory,
    ServiceContactType,
    ServiceStatus,
    ServiceType,
    VisitRecordingMethod,
    VisitType,
    MembershipSuspensionReason,
    MembershipSuspensionType,
    MemberCardOrderReason,
    SortOfMembership,
    ScannerSIMProvider,
    ScannerCaseType,
    ScannerInternetService,
    ScannerCaseAttachment,
    InformationRequired = 47,
    EligibleEmployee,
    ScannerType = 53,
    EoMReportYears = 55,
    EmployerParentGroup = 56,
    RegionList = 57,
    FacilityCategory
}
