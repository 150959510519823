import { DataResult, Member } from "src/app/models/index";
import {
  Component,
  OnInit,
  AfterViewChecked,
} from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "../../services/common.service";
import { AuthenticationService } from "../../services/authentication.service";
import { RolePermissionService } from "src/app/services/role-permission.service";
import { APIConstant } from "../../constant/apiconstant";

import { merge, of as observableOf, forkJoin } from "rxjs";
import {
  catchError,
  map,
  startWith,
  switchMap,
  shareReplay,
} from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { HttpDAO } from "../../services/httpdao.service";
import { RouterConstant } from "src/app/constant/routerconstant";
import { MessageBox } from "../../services/common-dialog.service";
import { MatDialog } from "@angular/material/dialog";
import { MemberService } from "src/app/services/member.service";
import { EEmployerStatus, EMemberStatus } from "src/app/enums";
import { ERole } from "@fp/enums/role.enum";
import { StorageKey } from "@fp/constant/storage-key";
import { MemberContainer } from "@fp/models/member-container.model";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit, AfterViewChecked {
  public isLoadingResults = false;
  public SpinHeight: number;
  public selecteditem: number;
  public interval;

  httpdao: HttpDAO | null;

  RouterConstant = RouterConstant;

  public _authServices;
  public _authFormFields;

  constructor(
    private router: Router,
    private commonservice: CommonService,
    private rolepermission: RolePermissionService,
    private authService: AuthenticationService,
    private http: HttpClient,
    private dialog: MatDialog,
    private memberSvc: MemberService,
  ) {
    this._authServices = authService.AuthServices;
    this._authFormFields = authService.AuthFormFields;
  }

  async ngOnInit() {
    this.commonservice.DashBoard = this;
    this.httpdao = new HttpDAO(this.http);
    await this.commonservice.App.checkTokenExpired();
    await this.redirectNonMemberToEditUserDetail();

    this.SetMenuAndNavigateUser();
    if (window.innerWidth >= 1000) {
      this.commonservice.App.opened = true;
    }
    this.commonservice.App.isLoginSuccessDisplay = true;
    this.commonservice.Header.isLogin = true;
    this.commonservice.Navbarmd.isLoginSuccessDisplay = true;
    this.commonservice.Navbarmd.isLoginDisplay = false;

    this.updateHeaderTitle();
  }

  private updateHeaderTitle() {
    const role = this.commonservice.GetSelectedRole();
    if (role == ERole.Facility_Staff_Member) {
      const uobject = this.commonservice.GetUserObject();
      const fullname = uobject.FirstName;
      this.commonservice.Header.title = "Hello, " + fullname + ". Welcome back!";
    }
  }

  public StartProgressBar() {
    this.isLoadingResults = true;
  }

  public StopProgressBar() {
    this.isLoadingResults = false;
  }

  public SetMenuAndNavigateUser() {
    this.commonservice.StartProgressBar();
    let privData: any;
    const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
    if (getContainer != null) {
      let container = <MemberContainer>JSON.parse(getContainer);
      privData = container.Privileges;
    }
    if (privData != null) {
      this.rolepermission.PrivilegesAndComponentsSecurity = this.rolepermission.PrivilegesAndComponents;
      this.rolepermission.RolesObject = this.commonservice.GetUserRoleByUserName();
      const temp = this.commonservice.GetSelectedRole();
      for (let i = 0; i < this.rolepermission.RolesObject.length; i++) {
        if (temp === this.rolepermission.RolesObject[i].Name) {
          this.rolepermission.SelectedRole = temp;
          break;
        }
      }
      if (
        (temp == null || temp == undefined) &&
        this.rolepermission.RolesObject.length > 0
      ) {
        this.rolepermission.SelectedRole = this.rolepermission.RolesObject[0].Name;
      }
      this.commonservice.SetSelectedRole(this.rolepermission.SelectedRole);

      const enctypted_data = privData.Data;
      const dectypted_data = this.commonservice.D_FP_ResponseData(
        enctypted_data
      );
      const ret = JSON.parse(dectypted_data);

      this.rolepermission.Privileges = ret;

      this.rolepermission.SetPermisisonReadOnlyPrivilege();

      this.rolepermission.SetMenu();

      if (this.rolepermission.RolesObject.length > 0) {
        if (
          this.rolepermission.RolesObject[0].Name === ERole.member ||
          temp == ERole.member
        ) {
          this.commonservice.Header.SetUILoginSuccess();
          this.NavigatePage();
        } else {
          this.commonservice.StopProgressBar();
        }
      } else {
        MessageBox.ShowError(this.dialog, "The user has no role.");
        this.commonservice.StopProgressBar();
      }

      return { privData };
    } else {
      forkJoin(
        this.httpdao!.getSingleData(APIConstant.API_GET_ALL_PRIVILEGES_V2)
      )
        .pipe(
          map(([privileges]) => {
            this.rolepermission.PrivilegesAndComponentsSecurity = this.rolepermission.PrivilegesAndComponents;
            this.rolepermission.RolesObject = this.commonservice.GetUserRoleByUserName();
            const temp = this.commonservice.GetSelectedRole();
            for (let i = 0; i < this.rolepermission.RolesObject.length; i++) {
              if (temp === this.rolepermission.RolesObject[i].Name) {
                this.rolepermission.SelectedRole = temp;
                break;
              }
            }
            if (
              (temp == null || temp == undefined) &&
              this.rolepermission.RolesObject.length > 0
            ) {
              this.rolepermission.SelectedRole = this.rolepermission.RolesObject[0].Name;
            }
            this.commonservice.SetSelectedRole(
              this.rolepermission.SelectedRole
            );

            const enctypted_data = privileges.Data;
            const dectypted_data = this.commonservice.D_FP_ResponseData(
              enctypted_data
            );
            const ret = JSON.parse(dectypted_data);

            this.rolepermission.Privileges = ret;

            this.rolepermission.SetPermisisonReadOnlyPrivilege();

            this.rolepermission.SetMenu();

            if (this.rolepermission.RolesObject.length > 0) {
              if (
                this.rolepermission.RolesObject[0].Name === ERole.member ||
                temp == ERole.member
              ) {
                this.commonservice.Header.SetUILoginSuccess();
                this.NavigatePage();
              } else {
                this.commonservice.StopProgressBar();
              }
            } else {
              MessageBox.ShowError(this.dialog, "The user has no role.");
              this.commonservice.StopProgressBar();
            }

            return { privileges };
          }),
          shareReplay(1),
          catchError(() => {
            this.commonservice.StopProgressBar();
            return observableOf(null);
          })
        )
        .subscribe((results) => { });
    }
  }

  private async redirectNonMemberToEditUserDetail() {
    if((!this.commonservice.isRequiredNonMemberDetailsComplete() || await this.commonservice.getCurrentUserCognitoState() !== 'VALID') && !this.commonservice.isUserAMember()) {
      await this.router.navigate([this.RouterConstant.ROUTER_USER_EDIT_DETAILS]);
    }
  }

  private NavigatePage() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.memberSvc.checkRegistrationByName(
            this.commonservice.GetUser()
          );
        }),
        catchError((e) => {
          throw e;
        })
      )
      .subscribe((result: string) => {
        const dectypted_data = this.commonservice.D_FP_ResponseData(result);
        const rdata = JSON.parse(dectypted_data);
        if (rdata.Success) {
          if (
            rdata.Data !== null &&
            this.commonservice.GetSelectedRole() === ERole.member
          ) {
            //User is member
            localStorage.setItem(
              "memberId",
              this.commonservice.E_FP_AES256ForShortString(rdata.Data.MemberId)
            );
            // Following should likely be redundant after last changes
            //Member isn't accept T&C, Member status is Candidate Member
            if (rdata.Data.IsAcceptTermCondition) {
              if (rdata.Data.MemberStatusId == EMemberStatus.New) {
                const selectedMenu = localStorage.getItem("selectedMenuMember");
                if (selectedMenu) {
                  this.router.navigate([selectedMenu]);
                } else {
                  this.router.navigate([
                    RouterConstant.NAVIGATOR_END_MEMBER_SIGNUP,
                  ]);
                }
                this.commonservice.App.isLoginSuccessDisplay = true;
                if (rdata.Data.IsFamilyMember) {
                  this.commonservice.MenuForNewMember = true;
                  this.commonservice.MenuForNewPrimaryMember = false;
                } else if (rdata.Data.IsPrimaryMember) {
                  this.commonservice.MenuForNewMember = true;
                  this.commonservice.MenuForNewPrimaryMember = true;
                }
              } else {
                const selectedMenu = localStorage.getItem("selectedMenuMember");
                if (rdata.Data.MemberStatusId == EMemberStatus.Inactive && rdata.Data.IsPrimaryMember && Boolean(localStorage.getItem("reactivationFlag"))) {
                  this.commonservice.App.isMemberMenuDisabled = true;
                  if (this.router.url != RouterConstant.NAVIGATOR_REACTIVATE_MEMBER) {
                    setTimeout(() => {
                      this.router.navigate([RouterConstant.NAVIGATOR_CONTINUE_REACTIVATE_MEMBER]);
                    }, 1000);
                  }
                } else if (selectedMenu) {
                  this.router.navigate([selectedMenu]);
                } else if (!this.router.url.includes("membersecuritypreferences")) {
                  this.router.navigate([
                    RouterConstant.NAVIGATOR_MEMBER_DETAILS,
                  ]);
                }
                //Member menu
                if (rdata.Data.IsFamilyMember) {
                  this.commonservice.MenuForMember = true;
                  this.commonservice.MenuForFamilyMember = false;
                } else if (rdata.Data.IsPrimaryMember) {
                  this.commonservice.MenuForMember = true;
                  this.commonservice.MenuForFamilyMember = true;
                  // Check status of primary member is Active
                  if (rdata.Data.MemberStatusId == EMemberStatus.Active) {
                    this.commonservice.MenuForActivePrimaryMember = true;
                  }
                }
              }
            } else {

              if (rdata.Data.MemberStatusId && rdata.Data.MemberStatusId == EMemberStatus.SurveyCandidate) {
                const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
                if (getContainer != null) {
                  let container = <MemberContainer>JSON.parse(getContainer);
                  var currentMember = <DataResult<Member>>JSON.parse(this.commonservice.D_FP_AES256(container.Member));

                  if (currentMember && currentMember.Data.Employer.EmployerStatusId &&
                    (currentMember.Data.Employer.EmployerStatusId == EEmployerStatus.Survey || currentMember.Data.Employer.EmployerStatusId == EEmployerStatus.FailedLaunch
                      || currentMember.Data.Employer.EmployerStatusId == EEmployerStatus.CloseForNewMembers || currentMember.Data.Employer.EmployerStatusId == EEmployerStatus.ProgramDevelopment)) {
                    this.router.navigate([RouterConstant.NAVIGATOR_CONTINUE_PRE_SIGNUP_SURVEY]);
                  } else if (currentMember && currentMember.Data.Employer.EmployerStatusId
                    && (currentMember.Data.Employer.EmployerStatusId == EEmployerStatus.Active || currentMember.Data.Employer.EmployerStatusId == EEmployerStatus.Onboarding)) {
                    this.commonservice.App.isMemberSignup = true;
                    this.commonservice.App.isMemberMenuDisabled = true;
                    this.commonservice.App.opened = true;
                    this.commonservice.MenuForMember = true;
                    this.commonservice.MenuForFamilyMember = true;
                    this.router.navigate([RouterConstant.NAVIGATOR_CONTINUE_MEMBER_SIGNUP]);
                  } else {
                    this.commonservice.SignOut();
                  }
                } else {
                  this.commonservice.SignOut();
                }
              } else if (rdata.Data.IsFamilyMember) {
                this.router.navigate([
                  RouterConstant.NAVIGATOR_TERMS_CONDITIONS,
                ]);
              } else if (rdata.Data.IsPrimaryMember) {
                localStorage.setItem("signUpStep", rdata.Data.SignUpStep);
                this.router.navigate([RouterConstant.NAVIGATOR_CONTINUE_MEMBER_SIGNUP]);
                this.commonservice.App.isMemberSignup = true;
                this.commonservice.App.isMemberMenuDisabled = true;
                this.commonservice.App.opened = true;
                this.commonservice.MenuForMember = true;
                this.commonservice.MenuForFamilyMember = true;
                // Check status of primary member is Active
                if (rdata.Data.MemberStatusId == EMemberStatus.Active) {
                  this.commonservice.MenuForActivePrimaryMember = true;
                }
              }
            }
          }
        } else {
          console.error(result);
        }
        this.commonservice.StopProgressBar();
      });
  }

  public LoadRolesPrivilegesByFacilityStaff() {
    const temp = this.commonservice.GetSelectedRole();
    if (temp == ERole.Facility_Staff_Member) {
      this.commonservice.MenuForFacilityStaffMember = true;
    } else {
      this.commonservice.MenuForFacilityStaffMember = false;
    }
  }

  public LoadRolesPrivilegesByEmployerFacilitator() {
    const temp = this.commonservice.GetSelectedRole();
    if (temp == ERole.Employer_Facilitator) {
      this.commonservice.MenuForEmployerFacilitator = true;
    } else {
      this.commonservice.MenuForEmployerFacilitator = false;
    }
  }

  ngAfterViewChecked() {
    this.LoadRolesPrivilegesByFacilityStaff();
    this.LoadRolesPrivilegesByEmployerFacilitator();
  }
}
