<div [formGroup]="form">
    <ngb-accordion activeIds="panelMbrPkg,panelFacilities" [destroyOnHide]="false">
        <ngb-panel id="panelMbrPkg">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': valid}"></i>
                Membership Packages
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <p class="pb-2" *ngIf="isSignup()">Selecting a package from the option(s) available will update the map
                    and confirm the package selection for your membership. Please wait while we load your options, then
                    select your package and click 'Next' to continue.</p>
                <p class="pb-2" *ngIf="!isSignup()">Selecting a package from the option(s) available will update the map
                    and confirm the package selection for your membership.</p>
                <div class="row py-2">
                    <div *ngFor="let memPackage of dataSource" class="col-12 col-md-4 mb-4">
                        <div class="fp-packageItem"
                            [class.selected-item]="memPackage.MembershipPackageId == selectedPackage?.MembershipPackageId"
                            (click)="selectMembershipPackageAndLoadFacility(memPackage)">
                            <!--selected-item-->
                            <h5 class="text-center">{{memPackage.MembershipPackageType.Name}}</h5>
                            <div class="fp-packagemid">
                                <div class="d-flex justify-content-between py-2 px-4">
                                    <div>Single Price</div>
                                    <div class="text-price">{{Utils.convertPriceFormat(memPackage.SinglePrice)}} / Week
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between py-2 px-4">
                                    <div>Family Price</div>
                                    <div class="text-price">{{Utils.convertPriceFormat(memPackage.FamilyPrice)}} / Week
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between py-2 px-4">
                                    <div>Dependant Price <small class="d-block">(18-25)</small></div>
                                    <div class="text-price">{{Utils.convertPriceFormat(memPackage.DependantPrice)}} /
                                        Week</div>
                                </div>
                                <div class="py-3 px-4 text-descr">{{memPackage.Description}} &nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isSignup()" style="padding-left: 0px; margin-left: -0.75rem;"
                    class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                    <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                        (click)="emitButtonEventBack()">
                        <i class="fas fa-arrow-circle-left mr-2"></i>Back</button>
                    <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="emitButtonEventNext()">
                        <i class="fas fa-arrow-circle-right mr-2"></i>Next</button>
                </div>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="panelFacilities">
            <ng-template ngbPanelTitle>
                <i class="fas fa-check icon-fp"></i>
                Available Facilities
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <!-- <app-facility-google-map [data]="data" #googlemap>
                </app-facility-google-map> -->
                <app-google-map-page-view #googlemap [isAustralia]="isAustralia"></app-google-map-page-view>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>