import { Component, EventEmitter, Injector, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { FPFormBaseComponent, PackageFormBase } from '@fp/components/base';
import { CommonMessage, CommonString } from '@fp/constant';
import { CommonConstants } from '@fp/constant/common-constants';
import { StringHelper, Utils, FPValidators } from '@fp/helpers';
import { DataResult, Region, SearchPaginationRequest, SearchPaginationResult, ServiceType, State } from '@fp/models';
import { CommonDataService, PackageService, ProductServiceService, RegionService } from '@fp/services';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-package-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.css'],
    providers: [FPFormBaseComponent.provideExisting(PackageDetailsComponent)]
})
export class PackageDetailsComponent extends PackageFormBase {
    CommonMessage = CommonMessage;
    CommonString = CommonString;
    StringHelper = StringHelper;

    states: State[] = [];
    selectedStates: State[] = [];
    regions: Region[] = [];
    selectedRegions: Region[] = [];
    serviceTypes: ServiceType[] = [];
    selectedServiceTypes: ServiceType[] = [];

    currentNameSearchText: string;

    @Output() filtersChange = new EventEmitter<{ regions: number[], serviceTypes: number[] }>();

    StateCtrl = new UntypedFormControl(null);
    RegionCtrl = new UntypedFormControl(null);
    ServiceTypeCtrl = new UntypedFormControl(null);

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            Id: [0],
            Name: new UntypedFormControl(null, {
                updateOn: 'blur',
                validators: [Validators.required, FPValidators.requiredNoWhitespace]
            }),
            IsActive: [true, Validators.required],
            StateFilter: [[], [Validators.required, Validators.minLength(1)]],
            RegionFilter: [[], [Validators.required, Validators.minLength(1)]],
            ProductServiceTypeFilter: [[], [Validators.required, Validators.minLength(1)]]
        });
    }

    constructor(injector: Injector,
        private commonDataSvc: CommonDataService,
        private svc: PackageService,
        private regionSvc: RegionService,
        private productServiceSvc: ProductServiceService) {
        super(injector);
    }

    OnLoad() {
        this.InvokeBatch([
            this.productServiceSvc.getTypes()
        ],
            {
                onSuccess: (res: { ServiceTypeResult: DataResult<ServiceType[]> }) => {
                    if (res.ServiceTypeResult) {
                        this.states = CommonConstants.GET_STATES.sort(
                            (a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
                        if (res.ServiceTypeResult.Success) {
                            this.serviceTypes = <ServiceType[]>Utils.sortCommonData(res.ServiceTypeResult.Data);
                        } else {
                            this.HandleResponseError(res.ServiceTypeResult);
                        }
                        if ((this.getControl('RegionFilter').value || []).length > 0 &&
                            (this.getControl('ProductServiceTypeFilter').value || []).length > 0) {
                            // Data was patched beforehand. Refresh the filters.
                            this.refreshFilters();
                        }
                        super.OnLoad();
                    } else {
                        this.handleError(res);
                    }
                },
                projectionFn: (res: DataResult<(ServiceType[])>[]): { ServiceTypeResult: DataResult<ServiceType[]> } => {
                    return { ServiceTypeResult: <DataResult<ServiceType[]>>res[0] };
                }
            }
        );
    }

    LoadComplete() {
        setTimeout(() => {
            this.getControl('Name').setAsyncValidators((ctrl: AbstractControl): Observable<ValidationErrors | null> => {
                const value = (ctrl.value || '').trim();
                if (!value || value.length < 1 || value === this.data.model.Name || value === this.currentNameSearchText) {
                    // Cancel validation, return current errors if any.
                    return observableOf(ctrl.errors);
                }
                return this.svc.getDetailsByName(value).pipe(map(res => {
                    this.currentNameSearchText = value;
                    return res && res.Success && res.Data && res.Data.Id !== this.data.model.Id ? { duplicated: { value: value } } : null;
                }));
            });

            this.StateCtrl.valueChanges.subscribe((index: number) => {
                this._addState(index);
            });

            this.RegionCtrl.valueChanges.subscribe((index: number) => {
                this._addRegion(index);
            });

            this.ServiceTypeCtrl.valueChanges.subscribe((index: number) => {
                this._addServiceType(index);
            });

            this.getControl('RegionFilter').valueChanges.subscribe(value => {
                this.raiseFiltersChange();
            });

            this.getControl('ProductServiceTypeFilter').valueChanges.subscribe(value => {
                this.raiseFiltersChange();
            });
        });
        super.LoadComplete();
    }

    PatchValue(value, options) {
        super.PatchValue(value, options);
        if (this.loaded) {
            // This component was eagerly loaded. Refresh filters after patching.
            this.refreshFilters();
        }
    }

    private swapItem(index: number, source: any[], target: any[]) {
        const item = source.splice(index, 1)[0];
        target.push(item);
    }

    /** @internal */
    _addState(index: number) {
        console.log(this.states);
        if (index != null) {
            let appendedStateIds = [];
            if (index === -1) {
                // Add all states to selected list
                const appendedStates = this.states.splice(0);
                appendedStateIds = appendedStates.map(st => st.StateId);
                this.selectedStates.push(...appendedStates);
            } else {
                appendedStateIds.push(this.states[index].StateId);
                this.swapItem(index, this.states, this.selectedStates);
            }
            // Reset selection
            this.StateCtrl.setValue(null, { emitEvent: false });
            this.selectedStates = this.selectedStates.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
            this.getControl('StateFilter').setValue(this.selectedStates.map(_item => _item.StateId));

            const request = new SearchPaginationRequest();
            request.Params = [{ Name: 'status', Value: true }, { Name: 'state_ids', Value: appendedStateIds.join(',') }];
            request.ViewColumns = ['name', 'state_name'];
            request.PageNumber = 0;
            request.PageSize = 0;
            this.Invoke(this.regionSvc.search(request), {
                onSuccess: (res: DataResult<SearchPaginationResult<Region>>) => {
                    if (res.Success) {
                        this.selectedRegions = this.selectedRegions.concat(res.Data.Results).sort((a, b) => a.Name.toLocaleLowerCase() > b.Name.toLocaleLowerCase() ? 1 : -1);
                        this.getControl('RegionFilter').setValue(this.selectedRegions.map(_item => _item.RegionId));
                    } else {
                        this.HandleResponseError(res);
                    }
                }
            });
        }
    }
    /** @internal */
    _removeState(index: number) {
        const state = this.selectedStates[index];
        this.swapItem(index, this.selectedStates, this.states);
        this.states = this.states.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);

        this.regions = this.regions.filter(rg => rg.State.StateId !== state.StateId);
        this.selectedRegions = this.selectedRegions.filter(rg => rg.State.StateId !== state.StateId);
        this.getControl('StateFilter').setValue(this.selectedStates.map(_item => _item.StateId));
        this.getControl('RegionFilter').setValue(this.selectedRegions.map(_item => _item.RegionId));
    }

    /** @internal */
    _addRegion(index: number) {
        if (index !== null) {
            this.swapItem(index, this.regions, this.selectedRegions);
            this.selectedRegions = this.selectedRegions.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
            // Reset selection
            this.RegionCtrl.setValue(null, { emitEvent: false });
            this.getControl('RegionFilter').setValue(this.selectedRegions.map(_item => _item.RegionId));
        }
    }
    /** @internal */
    _removeRegion(index: number) {
        this.swapItem(index, this.selectedRegions, this.regions);
        this.regions = this.regions.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
        this.getControl('RegionFilter').setValue(this.selectedRegions.map(_item => _item.RegionId));
    }

    /** @internal */
    _addServiceType(index: number) {
        if (index != null) {
            if (index === -1) {
                this.selectedServiceTypes = this.selectedServiceTypes.concat(this.serviceTypes.splice(0));
            } else {
                this.swapItem(index, this.serviceTypes, this.selectedServiceTypes);
            }
            // Reset selection
            this.ServiceTypeCtrl.setValue(null, { emitEvent: false });
            this.selectedServiceTypes = <ServiceType[]>Utils.sortCommonData(this.selectedServiceTypes);
            this.getControl('ProductServiceTypeFilter').setValue(this.selectedServiceTypes.map(_item => _item.Id));
        }
    }
    /** @internal */
    _removeServiceType(index: number) {
        this.swapItem(index, this.selectedServiceTypes, this.serviceTypes);
        this.serviceTypes = <ServiceType[]>Utils.sortCommonData(this.serviceTypes);
        this.getControl('ProductServiceTypeFilter').setValue(this.selectedServiceTypes.map(_item => _item.Id));
    }

    private refreshFilters() {
        const stateIds = <number[]>this.getControl('StateFilter').value;
        if (stateIds instanceof Array && stateIds.length > 0) {
            const newStates = [];
            this.selectedStates = [];
            this.states.forEach(_item => {
                if (stateIds.indexOf(_item.StateId) > -1) {
                    this.selectedStates.push(_item);
                } else {
                    newStates.push(_item);
                }
            });
            this.states = newStates;

            const regionIds = <number[]>this.getControl('RegionFilter').value;
            if (regionIds instanceof Array && regionIds.length > 0) {
                this.selectedRegions = [];
                if (regionIds.length > 0) {
                    const request = new SearchPaginationRequest();
                    request.Params = [
                        { Name: 'status', Value: true },
                        { Name: 'state_ids', Value: stateIds.join(',') }];
                    request.ViewColumns = ['name', 'state_name'];
                    request.PageNumber = 0;
                    request.PageSize = 0;
                    this.Invoke(this.regionSvc.search(request),
                        {
                            onSuccess: (res: DataResult<SearchPaginationResult<Region>>) => {
                                if (res.Success) {
                                    this.regions = [];
                                    res.Data.Results.forEach(_item => {
                                        if (regionIds.indexOf(_item.RegionId) > -1) {
                                            this.selectedRegions.push(_item);
                                        } else {
                                            this.regions.push(_item);
                                        }
                                    });
                                    this.changeDetectorRef.detectChanges();
                                } else {
                                    this.HandleResponseError(res);
                                }
                            }
                        });
                }
            }

        }

        const serviceTypeIds = <number[]>this.getControl('ProductServiceTypeFilter').value;
        if (serviceTypeIds instanceof Array && serviceTypeIds.length > 0) {
            const newServiceTypes = [];
            this.selectedServiceTypes = [];
            this.serviceTypes.forEach(_item => {
                if (serviceTypeIds.indexOf(_item.Id) > -1) {
                    this.selectedServiceTypes.push(_item);
                } else {
                    newServiceTypes.push(_item);
                }
            });
            this.serviceTypes = newServiceTypes;
        }
    }

    private raiseFiltersChange() {
        const regionIds = <number[]>(this.getControl('RegionFilter').value || []);
        const serviceTypeIds = <number[]>(this.getControl('ProductServiceTypeFilter').value || []);
        this.filtersChange.emit({
            regions: regionIds,
            serviceTypes: serviceTypeIds
        });
    }
}
