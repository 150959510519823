import { CommonResponse } from "./commonresponsemodel";
import { ServiceType } from "./service-type.model";

export class Product1 extends CommonResponse {
    ServiceTypeId: number;
    ProductStatusId: number;
    Price?: number;
    MaxUnitsAvailable: number;
    FpproductCost: number;
    MemberSalePrice: number
    IsGstapplicable: boolean;
    Description: string
}

export class Product extends CommonResponse {
    ServiceType: ServiceType;
    ServiceTypeId?: number;
    ProductStatusId?: number;
    Status: Status;
    Price: number;
    MaxUnitsAvailable: number;
    FpproductCost: number;
    MemberSalePrice: number
    IsGstapplicable: boolean;
    Description: string
}

export class Status {
    StatusId: number;
    Name: string;
}