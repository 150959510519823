<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">
  Bulk Upload Debit/Credit
</h4>

<div class="form-align">
  <!-- Main Mat Spinner -->
  <div class="loading-shade-global" *ngIf="isLoading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>

  <div class="form-group row">
    <div class="col-sm-10">
      <textarea readonly class="form-control text-primary" rows="8">
  This screen enables you to upload bulk of debits/credits to the membership ledger. To proceed, continue as follows:
    1. Download the CSV template provided as a link by the upload button.
    2. Open file and add as many rows as required by the columns provided.
    3. Review the entered data is correct.
    4. Click "Upload File" and select the modified CSV.

  If any errors exist a pop-up dialog box will direct you to the erroneous line and no transactions will be posted.</textarea>
    </div>
  </div>
  <form>
    <div class="row">
      <app-fp-file-upload-r2 filterFile=".csv" fileCategory="bulkUploadMemberLedger"
        (UploadCompleted)="AddFile_UploadCompleted($event)" [isUploadCompletedDefault]="false"
        [columnParams]="colParameter" [requiresCheck]="checkNecessary" [showProgressBar]="true">
      </app-fp-file-upload-r2>
      <app-describe-state-machine [MachineType]="StepFunctions.BulkUpload"
        (SuccessReturned)="handleBulkUploadResponse($event)"></app-describe-state-machine>
      <div class="form-align2">
        <a href="../../../../assets/sample_csv/DC_Upload_Template.csv"><u>Download Bulk Debit/Credit Upload template</u>
        </a>
      </div>
    </div>
  </form>
</div>

<app-fp-progress-bar #progressBar></app-fp-progress-bar>