<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">
  Add Bankcodes (NZ)
</h4>

<div class="bulk-upload-wrapper">
  <!-- Main Mat Spinner -->
  <div class="loading-shade-global" *ngIf="isLoading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>

  <div class="form-group row">
    <div class="col-sm-12">
      <textarea readonly class="form-control text-primary" rows="8">
    This screen enables you to upload bulk of Bankcodes. To proceed, continue as follows:
      1. Download the CSV template provided as a link by the upload button.
      2. Open file and add as many rows as required by the columns provided.
      3. Review the entered data is correct.
      4. Click "Upload File" and select the modified CSV.
  
    If any errors exist a pop-up dialog box will direct you to the erroneous line and no transactions will be posted.</textarea>
    </div>
  </div>
  <form>
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-between align-items-center">
          <app-fp-file-upload-r2 filterFile=".csv" fileCategory="bulkUploadBankCode"
            buttonlabel="CLICK TO UPLOAD NZ BANKCODES" (UploadCompleted)="AddFile_UploadCompleted($event)"
            [isUploadCompletedDefault]="false" [columnParams]="colParameter" [requiresCheck]="checkNecessary"
            [buttonStyle]="buttonStyle" [showProgressBar]="true">
          </app-fp-file-upload-r2>

          <app-describe-state-machine [MachineType]="StepFunctions.BulkUploadBankDetail"
            (SuccessReturned)="handleBulkUploadResponse($event)"></app-describe-state-machine>
          <div class="btn-history">
            <button id="btnGenerateReport" class="btn btn-primary py-2" style="width: 16em" type="button"
              (click)="btnHistory_Click()">
              <i class="fas fa-file-invoice mr-2"></i>
              History</button>
          </div>


          <div class="form-align2">
            <a href="../../../../assets/sample_csv/Bank_Code_Upload_Template.csv"><u>Download Bulk NZ Bankcodes Upload
                template</u>
            </a>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>

<app-fp-progress-bar #progressBar></app-fp-progress-bar>