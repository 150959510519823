import {
  Component,
  ElementRef,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { FPAbstractComponent } from "@fp/components/base";
import { FpFileData, FpFileUploadComponent } from "@fp/components/control";
import { CommonString } from "@fp/constant";
import { ERole } from "@fp/enums/role.enum";
import { StringHelper } from "@fp/helpers";
import { DataResult, EmployerContractUploadFile } from "@fp/models";
import { CommonService, MemberService, MessageBox } from "@fp/services";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { CropperComponent, ImageCropperResult } from "angular-cropperjs";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { v4 as uuid } from "uuid";

enum ImageSmoothingQuality {
  Low = "low",
  Medium = "medium",
  High = "high",
}
enum DragMode {
  Crop = "crop",
  Move = "move",
  None = "none",
}

class CustomUploadFile extends EmployerContractUploadFile {
  percent: number;
}

@Component({
  selector: "app-membership-upload-photo",
  templateUrl: "./membership-upload-photo.component.html",
  styleUrls: ["./membership-upload-photo.component.css"],
})
export class MembershipUploadPhotoComponent
  extends FPAbstractComponent
  implements OnInit {
  @ViewChild("angularCropper") public angularCropper: CropperComponent;
  @ViewChild(FpFileUploadComponent)
  fpFileUploadComponent: FpFileUploadComponent;
  @ViewChild("formUploadPhoto") formUploadPhoto: HTMLFormElement;
  width;
  height;
  WLH: boolean;
  userName = this.commonService.GetUser();

  config = {
    zoomable: true,
    cropBoxResizable: false,
    cropBoxMovable: false,
    viewMode: 1,
    movable: true,
  };

  // imageUrl = "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/Bartholdi_Fountain_in_Washington%2C_D.C._2012.JPG/800px-Bartholdi_Fountain_in_Washington%2C_D.C._2012.JPG";

  resultImage: any;
  resultResult: any;
  photoSelected: EmployerContractUploadFile = {};
  @Input() memberShipCardsSelected: {
    MemberId: number;
    PhotoLocation: string;
    ModifiedBy: string;
  };
  @Input() memberVerificationSelected: { id: number; photo: string };
  @Input() memberPersonalSelected: { id: number; photo: string };
  @Input() isChargeFee: boolean;
  @ViewChild("image1") image1: ElementRef;
  @ViewChild("content") popupcontent: ElementRef;
  isDisable: boolean = false;
  isAdmin: boolean;
  isMember: boolean;
  isMemberPhotoUpload: boolean;
  private openModal: NgbModalRef;

  IsUploadCompleted;
  percent = 0;
  public pg_subject: Subject<CustomUploadFile> = new Subject();
  public pgclose_subject: Subject<CustomUploadFile> = new Subject();

  constructor(
    injetor: Injector,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
    private svMember: MemberService,
    private activeModal: NgbActiveModal,
    private modalService2: NgbModal
  ) {
    super(injetor);
  }

  ngOnInit() {
    this.isAdmin = this.commonService.GetSelectedRole() === ERole.admin;
    this.isMemberPhotoUpload = this.isAdmin;
    this.isMember = this.commonService.GetSelectedRole() === ERole.member;
    if (this.memberShipCardsSelected) {
      this.photoSelected.Location = this.memberShipCardsSelected.PhotoLocation;
    }
    if (this.memberVerificationSelected) {
      this.photoSelected.Location = this.memberVerificationSelected.photo;
    }
    if (this.memberPersonalSelected) {
      this.photoSelected.Location = null;
      this.isMemberPhotoUpload = true;
    }
    this.pg_subject.pipe(debounceTime(300)).subscribe((val) => {
      if (val.percent <= 100) {
        this.percent = val.percent;
      }
      let val1 = Object.assign({}, val);
      val1.percent = val.percent + 10;
      this.pg_subject.next(val1);
      if (val.percent == 100) {
        this.IsUploadCompleted = true;
        this.pgclose_subject.next(val);
      }
    });

    this.pgclose_subject.pipe(debounceTime(1000)).subscribe((val) => {
      this.Close();
      if(val.Location == null) {
        MessageBox.ShowError(
          this.dialog,
          CommonString.UNKNOWN_UPLOAD_FAILURE
        );
        return;
      }
      const blobBin = atob(val.Location.split(",")[1]);
      const fileType = val.Location.split(";")[0].split("/")[1];
      let array = [];
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      const file = new Blob([new Uint8Array(array)], { type: fileType });
      this.photoSelected = {
        Location: <string>(
          this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
        ),
        Base64: null,
        FileName: val.FileName,
      };
      this.IsUploadCompleted = false;
      this.percent = 0;
    });
  }

  // Require tiny delay between disabling input and getting cropper results due to 
  // possible performance lag from executing code stopping the progress wheel from
  // appearing for the user. 
  public delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  public async OnSubmitUploadPhoto() {
    this.isDisable = true;
    await this.delay(200);
    this.resultImage = this.angularCropper.cropper
      .getCroppedCanvas({
        width: this.width,
        height: this.height,
        imageSmoothingQuality: ImageSmoothingQuality.High,
        imageSmoothingEnabled: true,
      })
      .toDataURL();
    const fileType = this.resultImage.split(";")[0].split("/")[1];
    if (fileType !== "png" && fileType !== "jpg" && fileType !== "jpeg") {
      MessageBox.ShowError(
        this.dialog,
        StringHelper.format(
          CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE,
          "JPEG or PNG"
        )
      );
      this.isDisable = false;
      return;
    }

    // const blob = new Blob([this.resultImage], { type: 'binary/octet-stream' });
    const blobBin = atob(this.resultImage.split(",")[1]);
    let array = [];
    for (let i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    const file = new Blob([new Uint8Array(array)], { type: fileType });
    console.log("File, ", file);
    // file = new File([file], fileType, { type: 'image/' + fileType, lastModified: Date.now() });
    const event = {
      target: {
        files: [file],
      },
    };
    this.commonService.StartGlobalProgressBar();

    //upload member photo
    this.fpFileUploadComponent.fileEventManual(event, fileType);
  }

  private OnChangePhoto(params) {
    var functionUsed
    if(this.isMember) {
      functionUsed = this.svMember.changePhotoForMember(params);
    }
    else {
      functionUsed = this.svMember.changePhoto(params);
    }
    this.Invoke(functionUsed, {
      onSuccess: (res: DataResult<string>) => {
        if (res.Success) {
          // update on item selected
          if (this.memberShipCardsSelected) {
            this.memberShipCardsSelected.PhotoLocation = res.Data;
            const userName = this.commonService.GetUser();
            this.memberShipCardsSelected.ModifiedBy = userName;
          }
          if (this.memberVerificationSelected) {
            this.memberVerificationSelected.photo = res.Data;
          }
          if (this.memberPersonalSelected) {
            this.memberPersonalSelected.photo = res.Data;
          }

          this.photoSelected = {};
          // reload data
          if (this.isMember) {
            var wordNewString;
            if (this.isChargeFee) {
              wordNewString = " new";
            }
            else {
              wordNewString = ""
            }
              MessageBox.ShowCustom(
                this.dialog,
                "Picture Upload Successful",
                "Picture Upload Successful",
                `Thank you for uploading a${wordNewString} photo! A new membership card with your new photo will be \
                  sent to you soon. You can check the status of your card in the “Membership Cards” section`
              ).subscribe(() => {
                this.activeModal.close({
                  location: this.memberShipCardsSelected.PhotoLocation,
                });
              });
          } else {
            let PhotoLocation;
            if (this.memberShipCardsSelected) {
              PhotoLocation = this.memberShipCardsSelected.PhotoLocation;
            }
            if (this.memberVerificationSelected) {
              PhotoLocation = this.memberVerificationSelected.photo;
            }
            this.activeModal.close({
              location: PhotoLocation,
            });
          }
        } else {
          this.HandleResponseError(res);
        }
      },
    });
  }

  public UploadPhotoCompleted(event) {
    // console.log("Catch the event: ", event);
    this.commonService.StopGlobalProgressBar();
    const ret: any = event;
    //Check if file is uploaded successfully
    if (ret.filedata == undefined) {
      MessageBox.ShowError(
        this.dialog,
        "The file size of the photo you have selected is too large. Please upload another photo with a smaller file size, or reduce the size of your current photo to allow for successful upload."
      );
      this.isDisable = false;
    } else {
      console.log("Anything in ret?", ret);
      //
      //pre-signed url
      const url: string = ret.filedata.url;
      const s3path: string = url.split("?")[0];
      this.photoSelected = {
        Location: s3path,
        Base64: "",
        FileName: "image.jpeg", //hack, not proper
      };
      let params;
      if (this.memberPersonalSelected) {
        this.activeModal.close({
          location: url,
        });
      }
      else {
        var id = this.memberShipCardsSelected
        ? this.memberShipCardsSelected.MemberId
        : this.memberVerificationSelected
        ? this.memberVerificationSelected.id
        : ""
        params = {
          Id: id,
          FileName: this.photoSelected.FileName,
          Data: this.photoSelected.Location,
          IsChargeFee: this.isChargeFee ? this.isChargeFee : false,
          IsMemberChange: this.isMember ? true : false,
          ModifiedBy: this.userName,
          IsNotUpdatedStatus: this.isMember ? false : true
        };
          this.OnChangePhoto(params);
      }
    }
  }

  public ImagesAdditionalSelected(event: Blob) {}

  dismiss() {
    this.activeModal.dismiss();
  }

  // Author: Trinh update cropper image
  ZoomInMe() {
    this.angularCropper.cropper.zoom(0.1);
  }

  ZoomOutMe() {
    this.angularCropper.cropper.zoom(-0.1);
  }

  RotateRightMe() {
    this.angularCropper.cropper.rotate(90);
  }

  CropMe(callback: Function) {}

  resultImageFun(event: ImageCropperResult) {
    let urlCreator = window.URL;
    this.resultResult = this.angularCropper.cropper
      .getCroppedCanvas()
      .toDataURL("image/jpeg");
  }
  checkstatus(event: any) {
    if (event.blob === undefined) {
      return;
    }
    // this.resultResult = event.blob;
    let urlCreator = window.URL;
    this.resultResult = this.sanitizer.bypassSecurityTrustUrl(
      urlCreator.createObjectURL(new Blob(event.blob))
    );
  }

  readyCropperImage(event: any) {
    this.angularCropper.cropper.setDragMode(DragMode.Move);
    const image = this.angularCropper.cropper.getImageData();
    const containerData = this.angularCropper.cropper.getContainerData();
    if (image.width < image.height) {
      this.angularCropper.cropper.setCanvasData({
        left: 0,
        top: (containerData.height - image.height) / 2,
        width: containerData.width,
      });
      this.width = image.naturalWidth * 0.8;
      this.height = image.naturalWidth * 0.8;
    } else {
      this.angularCropper.cropper.setCanvasData({
        left: (containerData.width - image.width) / 2,
        top: 0,
        height: containerData.height,
      });
      this.width = image.naturalHeight * 0.8;
      this.height = image.naturalHeight * 0.8;
    }
    this.angularCropper.cropper.setCropBoxData({
      left: (containerData.width - containerData.height * 0.8) / 2,
      top: (containerData.height * 0.2) / 2,
      width: containerData.height * 0.8,
      height: containerData.height * 0.8,
    });
  }

  public UploadPhoto(e) {
    this.IsUploadCompleted = false;
    let CustomPhotoSelected: CustomUploadFile = {
      Base64: this.photoSelected.Base64,
      EmployerContractId: this.photoSelected.EmployerContractId,
      FileName: this.photoSelected.FileName,
      Location: this.photoSelected.Location,
      Status: this.photoSelected.Status,
      percent: 0,
    };
    //important
    this.photoSelected = {};
    const that = this;
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const item = this.memberShipCardsSelected;
    if (file) {
      const reader = new FileReader();
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg"
      ) {
        MessageBox.ShowError(
          this.dialog,
          StringHelper.format(
            CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE,
            "JPEG or PNG"
          )
        );
        this.isDisable = false;
        return;
      } else if (file.size > CommonString.MAX_UPLOAD_FILE_SIZE_10MB) {
        MessageBox.ShowError(this.dialog, "Maximum file size is 10MB.");
        this.isDisable = false;
        return;
      } else {
        this.Open();
        CustomPhotoSelected.percent = 10;
        this.pg_subject.next(CustomPhotoSelected);
        reader.readAsDataURL(file);
        const that = this;
        reader.onload = function (event) {
          // const reader = event.target;
          if (typeof this.result === "string") {
            CustomPhotoSelected.Location = this.result;
            CustomPhotoSelected.FileName = file.name;
          }
          e.target.value = "";
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
          that.formUploadPhoto.reset();
        };
      }
    }
  }

  setDisable() {
    if (!this.angularCropper) {
      return true;
    }
    return this.angularCropper.isLoading || !this.photoSelected.Location;
  }

  public Open() {
    this.openModal = this.modalService2.open(this.popupcontent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
    this.openModal.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  public Close() {
    if (this.openModal) {
      this.openModal.close();
    }
  }
}
