<h3 class="font-weight-normal pb-2 text-primary text-center text-lg-left">
    Task List
</h3>

<div id="taskListTable">
    <label for="txtRgName" class="col-md-3 col-lg-2 col-form-label-md">Task List table
    </label>
    <small class="text-muted">
        Table shows list of tasks completed by the Finance Bots.
    </small>
    <div class="form-group">
        <div class="border-default rounded-10 border-2">
            <div class="tablecontainer">
                <table mat-table matSort [dataSource]="dataSource" matSort
                    matSortActive="botname" matSortDirection="desc" matSortStart="desc" matSortDisableClear
                    class="rounded-10">
                    <ng-container matColumnDef="botname">
                        <th mat-header-cell *matHeaderCellDef>Bot Name</th>
                        <td mat-cell *matCellDef="let element"> {{element.BotName}} </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>Description</th>
                        <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
                    </ng-container>

                    <ng-container matColumnDef="starttime">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Time</th>
                        <td mat-cell *matCellDef="let element">{{element.StartTime}}</td>
                    </ng-container>

                    <ng-container matColumnDef="completedtime">
                        <th mat-header-cell *matHeaderCellDef>Completed Time</th>
                        <td mat-cell *matCellDef="let element">{{element.CompletedTime}}</td>
                    </ng-container>

                    <ng-container matColumnDef="result">
                        <th mat-header-cell *matHeaderCellDef>Result</th>
                        <td mat-cell *matCellDef="let element">{{element.Result}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
</div>