export class PatternConstant {
    public static PATTERN_PHONE = '^([0]*[1-9][0-9]{8})$';
    public static PATTERN_PHONE_2 = '^([0]*[0-9]{9})$';
    public static PATTERN_PHONE_3 = '^([0]*[0-9]{10})$';
    public static PATTERN_PHONE_4 = '^([+][6][1/4][0]*[0-9]{9})$';
    public static PATTERN_PHONE_5 = '^([+][6][1/4][0]*[0-9]{10})$';
    public static PATTERN_PHONE_7 = '^([+][6][1/4][0]*[0-9]{8,12})$';
    public static PATTERN_PHONE_8 = '^([+][6][1/4][0]*[0-9]{7,11})$';
    public static UNSIGNED_NUMBER = '^[0-9]{0,}$';
    public static EMAIL = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$';
    public static URI = '^(http|https):\/\/[^ "]+$';
    public static CURRENCY = '^\$(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$';
    public static NOT_NUMBER = '[^0-9]';
    public static WITH_PLUS_NOT_NUMBER = '[^+0-9]';
    public static NUMBER_ONLY = '^[0-9]*$';
    public static PASSWORD = '^.*(?=.{8,})(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!*@#$%^&+=]).*$';
    public static CREATE_NEW_PASSWORD =  '^.*(?=.{8,})(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+*=.\\[\\]{}()?\"/\\\\,><\':;|_~`=+-]).*$';
    public static VALID_NAME = '^[a-zA-Z0-9\-\ \']+$';
    public static PATTERN_COUNTRY_CODE_PHONE_1 = '^([0]*[0-9]{11})$';
    public static PATTERN_COUNTRY_CODE_PHONE_2 = '^([0]*[0-9]{12})$';
    public static PATTERN_COUNTRY_CODE_PHONE_3= '^([0]*[0-9]{9,13})$';
    public static PATTERN_COUNTRY_CODE_PHONE_4= '^([0]*[0-9]{10,14})$';
    public static PATTERN_PHONE_6 = '^([0]*[1-9][0-9]{6,10})$';
}
