import { Injectable } from "@angular/core";
import { Message } from "@fp/models";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    message: Message = new Message();
    private messageSource = new BehaviorSubject<Message>(this.message);
    currentMessage = this.messageSource.asObservable();
  
    changeMessage(message: Message) {
        this.messageSource.next(message);
    }

    setMessage(message: Message){
        this.currentMessage = this.messageSource.asObservable();
    }
  }