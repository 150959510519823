import { Component, OnInit, Injector } from '@angular/core';
import { FacilityFormBase } from '@fp/components/base';
import { UntypedFormBuilder, Validators, UntypedFormControl, FormGroup } from '@angular/forms';
import { StringHelper } from '@fp/helpers';
import { CommonString } from '@fp/constant';
import { Subscription } from 'rxjs';
import { MessageService } from '@fp/services';
import { Message } from '@fp/models';

@Component({
    selector: 'app-facility-bpay-details',
    templateUrl: './facility-bpay-details.component.html',
    styleUrls: ['./facility-bpay-details.component.css'],
    providers: [{ provide: FacilityFormBase, useExisting: FacilityBpayDetailsComponent }]
})
export class FacilityBpayDetailsComponent extends FacilityFormBase {
    StringHelper = StringHelper;
    CommonString = CommonString;
    subscription: Subscription;
    static isDisabledBpayDetails: boolean = false;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            Bpay: [null]
        });
    }

    static getBPayDetailsFormGroup() {
        const fb = new UntypedFormBuilder();
        if (FacilityBpayDetailsComponent.isDisabledBpayDetails) {
            return fb.group({
                BillerNumber: [null],
                RecipientReference: [null],
            });
        } else {
            return fb.group({
                BillerNumber: [null, Validators.required],
                RecipientReference: [null, Validators.required],
            });
        }
    }

    constructor(injector: Injector, private messageService: MessageService) { super(injector); }

    ngOnInit(){
        this.updateValidators(true);

        this.subscription = this.messageService.currentMessage.subscribe((message: Message) => {
            if (message && message.Identifier == CommonString.FacilityPotentialMessageIdentifier) {
                if (message.Content == 'True') {
                    FacilityBpayDetailsComponent.isDisabledBpayDetails = true;
                    this.updateValidators(false);
                } else {
                    FacilityBpayDetailsComponent.isDisabledBpayDetails = false;
                    this.updateValidators(true);
                }
            }
        });
    }
    ngOnDestroy() {
        if (this.subscription) {
          this.subscription.unsubscribe(); 
        }
    }

    ToggleBPayDetailsFormGroup(enabled: boolean) {
        if (enabled) {
            this.form.setControl('Bpay', FacilityBpayDetailsComponent.getBPayDetailsFormGroup());
            this.form.parent.get('PaymentDetails').disabled ? this.form.disable() : null;
        } else {
            this.form.setControl('Bpay', new UntypedFormControl(null));
        }
    }

    PatchValue(value, options?) {
        this.ToggleBPayDetailsFormGroup(value['Bpay'] != null);
        super.PatchValue(value, options);
    }

    private updateValidators(required: boolean): void {
        let controls = ['Bpay.BillerNumber', 'Bpay.RecipientReference'];

        controls.forEach(controlPath => {
            let control = this.form.get(controlPath);
            if (control) {
                let validators = required ? [Validators.required] : [];
                control.setValidators(validators);
                control.updateValueAndValidity();
            }
        });
    }
}
