import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FileNameService {

  public fileUuidArray = [];
  public fileNameArray = [];
  public childFileUuidArray = [];
  public childFileNameArray = [];
  public originalFileNameArray = [];
  private uploadedFileUuidSource = new BehaviorSubject(['']);
  private uploadedFileNameSource = new BehaviorSubject(['']);
  private childUploadedFileUuidSource = new BehaviorSubject(['']);
  private childUploadedFileNameSource = new BehaviorSubject(['']);
  private originalFileNameSource = new BehaviorSubject(['']);
  $currentUploadedFileUuid = this.uploadedFileUuidSource.asObservable();
  $currentUploadedFileName = this.uploadedFileNameSource.asObservable();
  $currentChildUploadedFileUuid = this.childUploadedFileUuidSource.asObservable();
  $currentChildUploadedFileName = this.childUploadedFileNameSource.asObservable();
  $originalFileName = this.originalFileNameSource.asObservable();
  pageName = '';

  constructor() { }

  changeUploadedFileName(uploadedFileName: string, uploadedFileUuid: string, currentPage: string, originalFileName?: string) {
    if (currentPage.includes("AddManual")) {
      this.childFileNameArray.push(uploadedFileName);
      this.childFileUuidArray.push(uploadedFileUuid);
      this.childUploadedFileUuidSource.next(this.childFileUuidArray);
      this.childUploadedFileNameSource.next(this.childFileNameArray);
    }
    else if (currentPage.includes("PaymentReq")) {
      this.fileNameArray.push(uploadedFileName);
      this.fileUuidArray.push(uploadedFileUuid);
      this.uploadedFileUuidSource.next(this.fileUuidArray);
      this.uploadedFileNameSource.next(this.fileNameArray);
    }
    else {
      this.fileNameArray.push(uploadedFileName);
      this.fileUuidArray.push(uploadedFileUuid);
      this.originalFileNameArray.push(originalFileName);
      this.uploadedFileUuidSource.next(this.fileUuidArray);
      this.uploadedFileNameSource.next(this.fileNameArray);
      this.originalFileNameSource.next(this.originalFileNameArray);
    }
  }

  resetFileData() {
    this.fileNameArray = [];
    this.fileUuidArray = [];
    this.childFileNameArray = [];
    this.childFileUuidArray = [];
    this.uploadedFileUuidSource.next(['']);
    this.uploadedFileNameSource.next(['']);
    this.childUploadedFileUuidSource.next(['']);
    this.childUploadedFileNameSource.next(['']);
  }
}
