import { Component, Injector, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MemberFormBase } from '@fp/components/base';
import { MemberAddressContactDetailsComponent } from '@fp/components/members/shared';
import { FamilyMemberReactivationComponent } from './family-member-reactivation/family-member-reactivation.component';

@Component({
  selector: 'app-reactivation-member-details',
  templateUrl: './reactivation-member-details.component.html',
  styleUrls: ['./reactivation-member-details.component.css'],
  providers: [{ provide: MemberFormBase, useExisting: ReactivationMemberDetailsComponent }]
})
export class ReactivationMemberDetailsComponent extends MemberFormBase {

  @ViewChild('addressContactDetails') addressContactDetails: MemberAddressContactDetailsComponent;
  @ViewChild('familyMembers') familyMembers: FamilyMemberReactivationComponent;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  static getFormGroup() {
    const fb = new FormBuilder();
    return fb.group({
      AddressContactDetails: MemberAddressContactDetailsComponent.getFormGroup(),
      FamilyMembers: FamilyMemberReactivationComponent.getFormGroup()
    });
  }

  PatchValue(value, opts) {
    super.PatchValue(value, opts);
  }
}
