import { Image } from './image.model';
export class EmployerPortalPage {
    EmployerId: number;
    PageHeading: string;
    LinkName: string;
    EnableMemberSignUp: boolean;
    ShowAvailableFacilities: boolean;
    ShowPricingPackages: boolean;
    PageText1: string;
    PageText2: string;
    PageText3: string;
    IsActive: boolean;
    AttachLogo: Image;
    ModifiedBy: string = localStorage.getItem('user');
    Country: string;
}