<div class="bg-primary-gradient fp-signup-banner">
</div>
<div class="px-md-5 d-flex justify-content-center">
    <div class="col-12 col-md-9 col-lg-5 fp-signup-content p-md-0">
        <h3 class="pt-2 pt-md-5 m-0 text-center font-weight-normal">Member Registration</h3>
        <div class="py-4 px-md-4">
            <form [formGroup]="signupFormGroup">
                <div class="form-group row">
                    <label for="txtFirstName" class="col-md-3 col-form-label">First Name<span
                            class="text-danger">*</span>
                    </label>
                    <div class="col-md-9">
                        <div class="d-flex flex-column w-100">
                            <input type="text" maxlength="50"
                                [ngClass]="(signupFormGroup.get('txtFirstName').invalid && (signupFormGroup.get('txtFirstName').dirty || signupFormGroup.get('txtFirstName').touched || btnSubmit))?'form-control is-invalid':'form-control'"
                                id="txtFirstName" formControlName="txtFirstName">
                            <div
                                *ngIf="signupFormGroup.get('txtFirstName').invalid && (signupFormGroup.get('txtFirstName').dirty || signupFormGroup.get('txtFirstName').touched || btnSubmit)">
                                <small *ngIf="signupFormGroup.get('txtFirstName').errors.required"
                                     class="invalid-feedback d-block">First
                                    Name is required</small>
                                <small *ngIf="signupFormGroup.get('txtFirstName').errors.pattern"
                                     class="invalid-feedback d-block">This
                                    field does not accept special characters. Please try again</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="txtMidleName" class="col-md-3 col-form-label">Middle Name</label>
                    <div class="col-md-9">
                        <div class="d-flex flex-column w-100">
                            <input type="text" maxlength="50" class="form-control" id="txtMidleName"
                                formControlName="txtMidleName">
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="txtLastName" class="col-md-3 col-form-label">Last Name<span class="text-danger">*</span>
                    </label>
                    <div class="col-md-9">
                        <div class="d-flex flex-column w-100">
                            <input type="text" maxlength="50"
                                [ngClass]="(signupFormGroup.get('txtLastName').invalid && (signupFormGroup.get('txtLastName').dirty || signupFormGroup.get('txtLastName').touched || btnSubmit))?'form-control is-invalid':'form-control'"
                                id="txtLastName" formControlName="txtLastName">
                            <div
                                *ngIf="signupFormGroup.get('txtLastName').invalid && (signupFormGroup.get('txtLastName').dirty || signupFormGroup.get('txtLastName').touched || btnSubmit)">
                                <small *ngIf="signupFormGroup.get('txtLastName').errors.required"
                                     class="invalid-feedback d-block">Last
                                    Name is required</small>
                                <small *ngIf="signupFormGroup.get('txtLastName').errors.pattern"
                                     class="invalid-feedback d-block">This
                                    field does not accept special characters. Please try again</small>
                            </div>
                        </div>
                    </div>
                </div>
                <app-mobile-number [mandatory]="true" [control]="signupFormGroup.get('txtMobileNum')"
                    [submitted]="btnSubmit" formControlName="txtMobileNum" (runSingupCheck)="runCheck($event)"></app-mobile-number>
                <div class="form-group row">
                    <label for="txtEmail" class="col-md-3 col-form-label">Primary (Work) Email<span
                            class="text-danger">*</span>
                    </label>
                    <div class="col-md-9">
                        <div class="d-flex flex-column w-100">
                            <input (input)="checkPhoneEmailResponsive()"
                                [ngClass]="(signupFormGroup.get('txtEmail').invalid
                                    && (signupFormGroup.get('txtEmail').dirty || signupFormGroup.get('txtEmail').touched || btnSubmit))?'form-control is-invalid':'form-control'"
                                type="email" id="txtEmail" formControlName="txtEmail" maxlength="100">
                            <div
                                *ngIf="signupFormGroup.get('txtEmail').invalid && (signupFormGroup.get('txtEmail').dirty || signupFormGroup.get('txtEmail').touched || btnSubmit)">
                                <small *ngIf="signupFormGroup.get('txtEmail').errors.required"
                                     class="invalid-feedback d-block">Primary (Work) Email
                                    is required</small>
                                <small *ngIf="signupFormGroup.get('txtEmail').errors.pattern"
                                     class="invalid-feedback d-block">Primary (Work) Email
                                    is invalid</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="txtEmail" class="col-md-3 col-form-label">Secondary (Personal) Email</label>
                    <div class="col-md-9">
                        <div class="d-flex flex-column w-100">
                            <input
                                [ngClass]="(signupFormGroup.get('secondaryEmail').invalid)?'form-control is-invalid':'form-control'"
                                type="email" id="secondaryEmail" formControlName="secondaryEmail" maxlength="100">
                            <div *ngIf="signupFormGroup.get('secondaryEmail').invalid">
                                <small *ngIf="signupFormGroup.get('secondaryEmail').errors.pattern"
                                     class="invalid-feedback d-block">Secondary (Personal) Email
                                    is invalid</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="d-flex justify-content-between offset-md-3 col-md-9">
                        <form [formGroup]="ReCaptchaFormGroup" class="w-100">
                            <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" [size]="'normal'" [hl]="'en'"
                                [theme]="'light'" [type]="'image'" formControlName="rcpSignUpCaptcha"
                                (success)="HandleCaptchaSuccess($event)">
                            </ngx-recaptcha2>
                        </form>
                    </div>
                    <div class="d-flex justify-content-between offset-md-3 col-md-9">
                        <small *ngIf="isCaptchaResponse==false && btnSubmit==true"
                            class="invalid-feedback d-block">Please
                            verify that you are
                            not a robot</small>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="pt-2 pt-md-4 d-flex justify-content-between offset-md-3 col-md-9">
                        <button class="px-md-3 px-5 py-2 btn bg-white border-primary text-primary" type="button"
                            (click)="btnCancel_Click()">
                            Cancel</button>
                        <button class="px-5 py-2 btn btn-primary" type="button" (click)="btnSubmit_Click()">
                            Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>