import { Injectable } from '@angular/core';
import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * Inherits `NgbTimeAdapter` and provides a custom adapter for `NgbTimepicker`
 * that adapts the TimeSpan string with format 'd.HH:mm:ss.ttt'.
 *
 * @class NgbTimeSpanStringAdapter
 * @extends {NgbTimeAdapter<string>}
 */
@Injectable()
export class NgbTimeSpanStringAdapter extends NgbTimeAdapter<string>  {
    fromModel(value: string): NgbTimeStruct {
        const pattern = /(\d{1,}:\d{2}(:\d{2})?)/g;
        if (!value || !value.match(pattern)) {
            return null;
        }
        value = value.match(pattern)[0];
        const split = value.split(':');
        return {
            hour: parseInt(split[0], 10),
            minute: parseInt(split[1], 10),
            second: parseInt(split[2], 10) || 0
        };
    }

    toModel(time: NgbTimeStruct): string {
        if (!time) {
            return null;
        }
        return `${this.pad(time.hour)}:${this.pad(time.minute)}:${this.pad(time.second)}`;
    }

    private pad(i: number): string {
        return i < 10 ? `0${i}` : `${i}`;
    }
}
