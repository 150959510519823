import { Component, Injector, ViewChild, ElementRef, Output} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { empty, of as observableOf } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { catchError, skipWhile, switchMap } from 'rxjs/operators';
import { FPFormBaseComponent, MemberFormBase } from 'src/app/components/base';
import { CommonMessage, CommonString, PatternConstant } from 'src/app/constant';
import { FPValidators, StringHelper, Utils } from 'src/app/helpers';
import { Address, Country, DataResult, Postcode, SearchFieldOperator, SearchPaginationCriteria, SearchPaginationResult, State, Suburb, SuburbPostcodeStateModel, SuburbSearchResultRecord } from 'src/app/models';
import { CommonDataService, SuburbService, UserService, CommonService, MessageBox } from 'src/app/services';
import { EMemberType } from '@fp/enums';
import { DialogResult } from '@fp/components/common-dialog/common-dialog.component';
import { CommonConstants } from '@fp/constant/common-constants';
import { AddressService } from '@fp/services/address.service';
import { City } from '@fp/models/city.model';
import { LocationService } from '@fp/services/location.service';
import { AddressLocation } from '@fp/models/address-location.model';
import { DefaultDeserializer } from 'v8';
import { SearchForSuggestionsResult } from 'aws-sdk/clients/location';
import { Console } from 'console';
import { MobileNumberRegistorComponent } from "@fp/components/control/mobile-number-registor/mobile-number-registor.component";
import { MemberPersonalInfoComponent } from '../personal-info/personal-info.component';

@Component({
    selector: 'app-member-address-contact-details',
    templateUrl: './address-contact-details.component.html',
    styleUrls: ['./address-contact-details.component.css'],
    providers: [
        FPFormBaseComponent.provideExisting(MemberAddressContactDetailsComponent),
        MemberFormBase.provideExisting(MemberAddressContactDetailsComponent),
        MemberPersonalInfoComponent
    ]
})
export class MemberAddressContactDetailsComponent extends MemberFormBase {
    selectedCity: City;
    parentFocus = 0;
    private cityList: City[];
    isFromAustralia = true;
    isSearchingCity = false;
    citySearchResults: City[];
    selectedCityId = 0;
    orgiCtryPhoneCode = "";
    countryPhoneCode = "";
    requireEmailValidation = true;
    countries: Country[];
    isEmailDuplicated: boolean = false;
    isMPDuplicated: boolean = false;
    private selectedCountry: Country = null;
    states: State[];
    private selectedState: State = null;
    postcodes: Postcode[] = [];
    private selectedPostcode: Postcode = null;
    suburbs: Suburb[];
    private selectedSuburb: Suburb;
    private selectedAddress1: AddressLocation;
    addressList: any[];
    filteredAdressList: SearchForSuggestionsResult[];
    countriesId = CommonConstants.GET_COUNTRIES;
    mobileLength = 10;
    isCheckingCountry: boolean = false;
    isAusSelectSelect= true;
    col_3 = true;
    memberType: any;
    newNum: string;
    dependentSelectedDate: Date;

    parentComponent: MemberPersonalInfoComponent;

    private searchSuburbBusyIndicator = {
        start: () => { this.SuburbCtrl.markAsPending(); },
        stop: () => { this.SuburbCtrl.updateValueAndValidity({ emitEvent: false }); }
    };
    private currentMobileNumberSearchText: string;
    private currentPrimaryEmailSearchText: string;
    public originalPrimaryEmail: string;
    private suburbList: SuburbSearchResultRecord[];

    CommonMessage = CommonMessage;
    CommonString = CommonString;
    StringHelper = StringHelper;

    get Addressline1Ctrl() { return <UntypedFormControl>this.form.get('Address.StreetAddress'); }
    get CountryCtrl() { return <UntypedFormControl>this.form.get('Country'); }
    get StateCtrl() { return <UntypedFormControl>this.form.get('State'); }
    get PostcodeCtrl() { return <UntypedFormControl>this.form.get('Postcode'); }
    get SuburbCtrl() { return <UntypedFormControl>this.form.get('Suburb'); }
    get CityCtrl() { return this.form.get('City'); }

    @ViewChild('citySearchBox') private citySearchBox: ElementRef<HTMLDivElement>;
    @ViewChild('autoCity') private autoCity: MatAutocomplete;
    @ViewChild("mobileNumberRegistorComponent") mobileNumberRegistor: MobileNumberRegistorComponent<any>;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            MobileNumber: ['',Validators.required],
            PrimaryEmail: [null, Validators.compose([Validators.required, Validators.pattern(PatternConstant.EMAIL)])],
            SecondaryEmail: [null, Validators.pattern(PatternConstant.EMAIL)],
            Address: fb.group({
                AddressId: [0],
                SuburbId: [0],
                StreetAddress1: [null],
                StreetAddress: [null, Validators.required],
                StreetAddress2: [null],
                IsActive: [true]
            }),
            SuburbId: [0],
            Suburb: [null, [Validators.required, FPValidators.resolvableAutocompleteOption]],
            Postcode: [null, Validators.required],
            State: [null],
            Country: [null, Validators.required],
            City: [null]
        });
    }

    constructor(injector: Injector, private commonDataSvc: CommonDataService, private suburbSvc: SuburbService,
        private userSvc: UserService, private addressService: AddressService, private commonService: CommonService,
        private locationservice: LocationService) {
        super(injector);
        const _parent: MemberPersonalInfoComponent = this.injector.get<MemberPersonalInfoComponent>(MemberPersonalInfoComponent);
        this.parentComponent = _parent;

        var today = new Date();
        today.getHours;
        var eighteenYearsAgo = new Date(
            today.getFullYear() - 18,
            today.getMonth(),
            today.getDate(),
            today.getHours(),
            today.getMinutes(),
            today.getMinutes(),
            today.getSeconds()
        );

        this.dependentSelectedDate = eighteenYearsAgo;
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.OnLoad();
        if(!this.form.get("MobileNumber").value){
            this.mobileNumberRegistor.SetDefaultCountryCode(this.isFromAustralia);
          }
    }

    getClassFocused(vlFocused) {
        if (this.parentFocus == vlFocused) {
            return "focused";
        } else {
            return "";
        }
    }

    OnLoad() {
        this.isSearchingCity = true;
        this.addressService.getCityDetail().subscribe((res) => {
            this.cityList = res.Data;
        });

        this.countries = CommonConstants.GET_COUNTRIES.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
        this.isAusSelectSelect= false;
        const prefetchedCountry = <Country>this.CountryCtrl.value;
        const defultcontryName = 'Australia';
        const defaultCountry = this.countries
            .find(country =>
                prefetchedCountry ?
                    prefetchedCountry.CountryId === country.CountryId :
                    country.Name === defultcontryName);


        this.selectedCountry = defaultCountry;
        this.CountryCtrl.setValue(this.selectedCountry, { emitEvent: false });
        if (defaultCountry != null) {
            this.loadStatesByCountry(defaultCountry.CountryId, true);

        }
        this.commonSvc.getLocationData().subscribe(
            (res) => {
                if (res.Success) {
                    this.suburbList = res.Data.suburbs;
                }
            }
        );
    }

    // LoadComplete() {
    //     let currentValue = '';
    //     setTimeout(() => {
    //         if (this.data.model.Address) {
    //             var address1 = <SearchForSuggestionsResult>{
    //                 Text: this.data.model.Address.StreetAddress1
    //             };
    //             this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });
    //         }
    //         if (!this.isFromAustralia) {
    //             this.CityCtrl.valueChanges.subscribe((value: City) => {
    //                 this.selectedCity = value;
    //                 this.SelectSuburbForBasedOnCity();
    //             })
    //         }

    //         this.CountryCtrl.valueChanges.subscribe((value: Country) => {
    //             if (!this.selectedCountry || !value || value.CountryId !== this.selectedCountry.CountryId) {
    //                 this.changeCountry(value);
    //             }
    //         });

    //         this.StateCtrl.valueChanges.subscribe((value: State) => {
    //             if (!this.selectedState || !value || value.StateId !== this.selectedState.StateId) {
    //                 this.changeState(value);
    //             }
    //         });

    //         // this.PostcodeCtrl.valueChanges.subscribe((value: Postcode) => {
    //         //     this.changePostcode(value);
    //         // });

    //         this.Addressline1Ctrl.valueChanges
    //             .pipe(
    //                 debounceTime(500),
    //                 switchMap(value => {
    //                     if (typeof value === 'string') {
    //                         this.selectedAddress1 = null;
    //                         this.filteredAdressList = [];

    //                         if (value.length > 2) {
    //                             return observableOf(value);
    //                         } else if (value.length === 0) {
    //                             return observableOf(null);
    //                         }
    //                     } else if (typeof value === 'object') {
    //                         return observableOf(value);
    //                     }
    //                     return empty();
    //                 }))
    //             .subscribe((value: string | SearchForSuggestionsResult) => {
    //                 if (value) {
    //                     if (typeof value === 'string') {
    //                         this.searchAddress(value);
    //                     } else if (typeof value === 'object') {
    //                         this.SelectAddress1(value);
    //                     }
    //                 }
    //             });

    //         if (!this.isFromAustralia) {
    //             this.CityCtrl.valueChanges.pipe(
    //                 skipWhile((value) => (value && typeof value === 'object')),
    //                 debounceTime(500),
    //                 catchError((e) => { throw e; })
    //             ).subscribe((value: string) => {
    //                 this.isSearchingCity = false;
    //                 if (currentValue != this.CityCtrl.value) {
    //                     currentValue = this.CityCtrl.value;
    //                     let cityList: City[] = this.cityList;
    //                     cityList = cityList.filter(x => x.Name.toLowerCase().trim().includes(this.CityCtrl.value?.toString().toLowerCase().trim()));
    //                     cityList.sort((a, b) => a.Name.localeCompare(b.Name));
    //                     let uniqueCities: City[] = [];
    //                     cityList.map(x => uniqueCities.filter(a => a.Name == x.Name).length > 0 ? null : uniqueCities.push(x));
    //                     this.citySearchResults = uniqueCities
    //                     this.changeDetectorRef.markForCheck();
    //                 }
    //             }, error => {
    //                 this.isSearchingCity = false;
    //                 MessageBox.ShowError(this.dialog, "Sorry, we're having trouble connecting. Let's try that again.")
    //                     .subscribe(res => {
    //                         if (res.result.toLowerCase() === DialogResult.Ok) {
    //                             window.location.reload();
    //                         }
    //                     });
    //                 this._logger.error(error);
    //             });
    //         }

    //         this.SuburbCtrl.valueChanges
    //             .pipe(
    //                 debounceTime(500),
    //                 switchMap(value => {
    //                     this.searchSuburbBusyIndicator.stop();
    //                     if (typeof value === 'string') {
    //                         // Clear previous results
    //                         this.selectedSuburb = null;
    //                         this.suburbs = [];

    //                         if (value.length > 2) {
    //                             return observableOf(value);
    //                         } else if (value.length === 0) {
    //                             return observableOf(null);
    //                         }
    //                     } else if (typeof value === 'object') {
    //                         return observableOf(value);
    //                     }
    //                     return empty();
    //                 }))
    //             .subscribe((value: string | Suburb) => {
    //                 if (value) {
    //                     if (typeof value === 'string') {
    //                         this.searchSuburbs(value);
    //                     } else if (typeof value === 'object') {
    //                         this.SelectSuburb(value);
    //                     }
    //                 }
    //             });

    //     });
    //     super.LoadComplete();
    // }

    public LoadValueByCountry() {
        let currentValue = '';
        setTimeout(() => {
            if (this.data.model.Address) {
                if(this.data.model.Address && this.data.model.Address.StreetAddress && this.data.model.Address.StreetAddress.Text){
                    this.data.model.Address.StreetAddress1 = this.data.model.Address.StreetAddress.Text
                }else if(this.data.model.Address && this.data.model.Address.StreetAddress && !this.data.model.Address.StreetAddress.Text){
                    this.data.model.Address.StreetAddress1 = String(this.data.model.Address.StreetAddress)
                }else{
                    this.data.model.Address.StreetAddress1 = this.data.model.Address.StreetAddress1
                }
                var address1 = <SearchForSuggestionsResult>{
                    Text: this.data.model.Address.StreetAddress1
                };
                this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });
            }
            if (!this.isFromAustralia) {
                this.CityCtrl.valueChanges.subscribe((value: City) => {
                    this.selectedCity = value;
                    this.SelectSuburbForBasedOnCity();
                })
            }

            this.CountryCtrl.valueChanges.subscribe((value: Country) => {
                if (!this.selectedCountry || !value || value.CountryId !== this.selectedCountry.CountryId) {
                    this.changeCountry(value);
                }
            });

            this.StateCtrl.valueChanges.subscribe((value: State) => {
                if (!this.selectedState || !value || value.StateId !== this.selectedState.StateId) {
                    this.changeState(value);
                }
            });

            this.Addressline1Ctrl.valueChanges
                .pipe(
                    debounceTime(500),
                    switchMap(value => {
                        if (typeof value === 'string') {
                            this.selectedAddress1 = null;
                            this.filteredAdressList = [];

                            if (value.length > 2) {
                                return observableOf(value);
                            } else if (value.length === 0) {
                                return observableOf(null);
                            }
                        } else if (typeof value === 'object') {
                            return observableOf(value);
                        }
                        return empty();
                    }))
                .subscribe((value: string | SearchForSuggestionsResult) => {
                    if (value) {
                        if (typeof value === 'string') {
                            this.searchAddress(value);
                        } else if (typeof value === 'object') {
                            this.SelectAddress1(value);
                        }
                    }
                });

            if (!this.isFromAustralia) {
                this.CityCtrl.valueChanges.pipe(
                    skipWhile((value) => (value && typeof value === 'object')),
                    debounceTime(500),
                    catchError((e) => { throw e; })
                ).subscribe((value: string) => {
                    this.isSearchingCity = false;
                    if (currentValue != this.CityCtrl.value) {
                        currentValue = this.CityCtrl.value;
                        let cityList: City[] = this.cityList;
                        cityList = cityList.filter(x => x.Name.toLowerCase().trim().includes(this.CityCtrl.value?.toString().toLowerCase().trim()));
                        cityList.sort((a, b) => a.Name.localeCompare(b.Name));
                        let uniqueCities: City[] = [];
                        cityList.map(x => uniqueCities.filter(a => a.Name == x.Name).length > 0 ? null : uniqueCities.push(x));
                        this.citySearchResults = uniqueCities
                        this.changeDetectorRef.markForCheck();
                    }
                }, error => {
                    this.isSearchingCity = false;
                    MessageBox.ShowError(this.dialog, "Sorry, we're having trouble connecting. Let's try that again.")
                        .subscribe(res => {
                            if (res.result.toLowerCase() === DialogResult.Ok) {
                                window.location.reload();
                            }
                        });
                    this._logger.error(error);
                });
            }

            this.SuburbCtrl.valueChanges
                .pipe(
                    debounceTime(500),
                    switchMap(value => {
                        this.searchSuburbBusyIndicator.stop();
                        if (typeof value === 'string') {
                            // Clear previous results
                            this.selectedSuburb = null;
                            this.suburbs = [];

                            if (value.length > 2) {
                                return observableOf(value);
                            } else if (value.length === 0) {
                                return observableOf(null);
                            }
                        } else if (typeof value === 'object') {
                            return observableOf(value);
                        }
                        return empty();
                    }))
                .subscribe((value: string | Suburb) => {
                    if (value) {
                        if (typeof value === 'string') {
                            this.searchSuburbs(value);
                        } else if (typeof value === 'object') {
                            this.SelectSuburb(value);
                        }
                    }
                });

        });
    }

    ddlEmployerCityOption_CompareFn(opt1: City, opt2: City) {
        return (!opt1 && !opt2) || (opt1 && opt2 && opt1.CityId == opt2.CityId);
    }

    public ddlEmployerCity_Change() {
        this.CheckCityValue();
    }

    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }

    public CheckCityValue() {
        if (this.CityCtrl !== null) {
            const val = this.CityCtrl.value;
            if (!val) {
                this.form.get('Employer').disable();
            }
        }
    }

    public PatchValue(value, opts) {
        super.PatchValue(value, opts);
        this.isFromAustralia = value.Country.Name != "New Zealand";
        if(value){
            this.mobileNumberRegistor.dataModel = value;
        }
        if (value?.DateOfBirth) {

            var dob = new Date(value?.DateOfBirth);
            this.mobileNumberRegistor.primarySelectDate = dob;
        }

        if (value?.MemberType) {
            this.mobileNumberRegistor.memberTypePass = value.MemberType;
        }
        this.mobileNumberRegistor.isAus = this.isFromAustralia;

        //FP-849.FP-848
        if (value.MobileNumber.match(/^\++/)){
            value.MobileNumber = value.MobileNumber.replace(/^\++/, '+');
        }

        this.mobileNumberRegistor.SetValueFromMobileNo(value.MobileNumber, true);

        if (this.isFromAustralia) {
            this.SuburbCtrl.setValidators(Validators.required);
            this.CityCtrl.setValidators(null);
        }
        else {
            this.CityCtrl.setValidators(Validators.required);
        }
        this.originalPrimaryEmail = value.PrimaryEmail.toLowerCase();

        const address = <Address>value['Address'];
        if (!address) {
            value['Address'] = new Address();
        }
        if(address && address.StreetAddress && address.StreetAddress.Text){
            value['Address'].StreetAddress1 = value['Address'].StreetAddress.Text
        }else if(address && address.StreetAddress && !address.StreetAddress.Text){
            value['Address'].StreetAddress1 = value['Address'].StreetAddress
        }else{
            value['Address'].StreetAddress1 = value['Address'].StreetAddress1
        }
        value['Address'].StreetAddress = <SearchForSuggestionsResult>{
            Text: value['Address'].StreetAddress1
        }

        this.selectedSuburb = <Suburb>value['Suburb'];
        this.selectedCity = <City>value['City'];

        const postcode = <Postcode>value['Postcode'];
        if (postcode) {
            this.selectedPostcode = postcode;
            this.postcodes = [postcode];
        }

        const state = <State>value['State'];
        if (state) {
            this.selectedState = state;
            this.states = this.states || [state];
        }

        const country = <Country>value['Country'];
        if (country) {
            this.isFromAustralia = value?.Country?.CountryId === 1;
            this.loadStatesByCountry(value?.Country?.CountryId, false);

            this.selectedCountry = country;
            this.countries = this.countries || [country];
        }
        else {
            this.selectedCountry = this.countries.find(country => country.Name === 'Australia');
        }

        this.LoadValueByCountry()
    }

    /** @internal */
    AutoComplete_FocusOut(e) {
        if (this.Addressline1Ctrl.value) {
            this.Addressline1Ctrl.setErrors(null);
            if (this?.filteredAdressList?.length == 0) {
                var address1 = <SearchForSuggestionsResult>{
                    Text: e.target.value
                };
                this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });
            }
        }
    }

    /** @internal */
    AutoCompleteAddress_DisplayWithFn(option: any) {
        if (!option) {
            return '';
        }else if(option.Text){
            return option.Text;
        }else{
            return option
        }
    }

    /** @internal */
    AutoComplete_DisplayWithFn(option: any) {
        if (!option) {
            return '';
        }
        return option.Name || option.Code;
    }

    /** @internal */
    AutoCompleteCity_DisplayWithFn(option: any) {
        if (option) {
            if (option.Name) {
                const val = option.Name;
                return (val);
            } else {
                return option;
            }
        } else {
            return '';
        }
    }

    /** @internal */
    SelectCity(city: City) {
        this.citySearchResults = [];
        this.CityCtrl.markAsDirty();
        this.CityCtrl.markAsTouched();
    }

    /** @internal */
    txtCity_Blur(e) {
        if (this.CityCtrl.dirty) {
            this.CityCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
            this.CityCtrl.updateValueAndValidity({ emitEvent: false });
        }
        this.parentFocus = 0;
    }

    /** @internal */
    txtCity_Input(e) {
        if (this.CityCtrl.dirty) {
            if (typeof this.CityCtrl.value === 'string' &&
                this.CityCtrl.value.length == 0 &&
                this.selectedCityId > 0) {
                this.SelectCity(null);
            }
            this.CityCtrl.setValidators(Validators.required);
        }
    }

    txtAddress_Blur(e) {
        if (this.Addressline1Ctrl.dirty) {
            this.Addressline1Ctrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
            this.Addressline1Ctrl.updateValueAndValidity({ emitEvent: false });
        }
    }

    /** @internal */
    ddlCountryOption_CompareFn(opt1: Country, opt2: Country) {
        return (opt1 && opt2) && opt1.CountryId === opt2.CountryId;
    }

    /** @internal */
    ddlStateOption_CompareFn(opt1: State, opt2: State) {
        return (!opt1 && !opt2) || (opt1 && opt2 && opt1.StateId === opt2.StateId);
    }

    /** @internal */
    ddlPostcodeOption_CompareFn(opt1: SuburbPostcodeStateModel, opt2: SuburbPostcodeStateModel) {
        return (!opt1 && !opt2) || (opt1 && opt2 && opt1.PostcodeId === opt2.PostcodeId);
    }

    /** @internal */
    txtPrimaryEmail_Blur(e) {
        this.ValidatePrimaryEmail();
    }

    /** @internal */
    txtSuburb_Blur(e) {
        if (this.SuburbCtrl.dirty) {
            this.SuburbCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
            this.SuburbCtrl.updateValueAndValidity({ emitEvent: false });
        }
    }

    /** @internal */
    txtSuburb_Input(e) {
        if (this.SuburbCtrl.dirty) {
            this.SuburbCtrl.setValidators(Validators.required);
        }
    }

    /** @internal */
    txtAddress1_Input(e) {
        if (this.Addressline1Ctrl.dirty) {
            this.Addressline1Ctrl.setValidators(Validators.required);
        }
    }

    private changeCountry(country: Country) {
        this.selectedCountry = country;

        // Update upward
        this.StateCtrl.setValue(null, { emitEvent: false });
        if (this.selectedState) {
            this.StateCtrl.markAsDirty();
        }
        this.states = [];
        this.changeState(null);

        if (country) {
            this.loadStatesByCountry(country.CountryId);
        }
    }

    private loadStatesByCountry(countryId: number, firstLoad = false) {
        this.states = CommonConstants.GET_STATES.filter(X => X.CountryId == countryId).sort((a, b) => a.StateId - b.StateId);
        if (firstLoad) {
            super.OnLoad();
        }
    }

    private changeState(state: State) {
        this.selectedState = state;

        // Update upward
        this.PostcodeCtrl.setValue(null, { emitEvent: false });
        if (this.selectedPostcode) {
            this.PostcodeCtrl.markAsDirty();
        }
        this.postcodes = [];
        this.changePostcode(null);
    }

    private changePostcode(postcode: Postcode) {
        this.selectedPostcode = postcode;

        // Update upwardlocationthis.data.model.Address
        this.SuburbCtrl.setValue(null, { emitEvent: false });
        if (this.selectedSuburb) {
            this.SuburbCtrl.markAsDirty();
        }
        this.suburbs = [];
        this.selectedSuburb = null;
    }

    private async searchAddress(text: string) {
        const country = this.isFromAustralia ? "AUS" : "NZL";

        const addressResult = await this.locationservice.getLocationSuggestions(text, country);
        this.filteredAdressList = addressResult.Results.map(a => a);
    }

    private searchSuburbs(text: string) {
        const criteria = new SearchPaginationCriteria();
        criteria.PageSize = 0;
        criteria.ViewColumns = null;
        criteria.Criteria.Fields = [{ Name: 'name', Operator: SearchFieldOperator.Contains, Value: text }];
        var countryId = this.isFromAustralia ? 1 : 2;
        this.suburbs = this.suburbList.filter(x => (x.Name.toLowerCase().trim().includes(text.toLowerCase().trim()) ||
                                                   x.Postcode.Name.includes(text.toLowerCase().trim())) &&
                                                   x.Country.Id === countryId)
            .map(_item => <Suburb>{
                SuburbId: _item.Id,
                Name: _item.Name,
                PostcodeId: _item.Postcode.Id,
                Postcode: {
                    PostcodeId: _item.Postcode.Id,
                    Code: _item.Postcode.Name,
                    StateId: _item.State.Id,
                    State: {
                        StateId: _item.State.Id,
                        Name: _item.State.Name
                    }
                }
            });
    }

   // async SelectAddress1(address1: SearchForSuggestionsResult) {
   async SelectAddress1(selectedAddressEvent: any) {
        if(selectedAddressEvent.option){
        var address1 = <SearchForSuggestionsResult>selectedAddressEvent.option.value;
        const country = this.isFromAustralia ? "AUS" : "NZL";
        const countryId = this.isFromAustralia ? this.countriesId[0].CountryId : this.countriesId[1].CountryId;
        const addressResult = await this.locationservice.getLocation(address1.Text, country);

        var resultAddress = addressResult.Results[0];

        if (this.isFromAustralia) {
            this.selectedAddress1 = new AddressLocation();
            this.selectedAddress1.Address1 = resultAddress.Place.Label;
            this.selectedAddress1.Suburb = new Suburb();
            this.selectedAddress1.Suburb.Name = resultAddress.Place.Neighborhood;
            this.selectedAddress1.Suburb.Postcode = new Postcode();
            this.selectedAddress1.Suburb.Postcode.Code = resultAddress.Place.PostalCode;
            this.selectedAddress1.Suburb.Postcode.State = new State();
            this.selectedAddress1.Suburb.Postcode.State.Name = resultAddress.Place.Region;
            this.selectedAddress1.Longitude = String(Math.round((Number(resultAddress.Place.Geometry?.Point[0].toString()) + Number.EPSILON) * 100) / 100),
                this.selectedAddress1.Latitude = String(Math.round((Number(resultAddress.Place.Geometry?.Point[1].toString()) + Number.EPSILON) * 100) / 100)
        }
        else {
            this.selectedAddress1 = new AddressLocation();
            this.selectedAddress1.Address1 = resultAddress.Place.Label;
            this.selectedAddress1.City = resultAddress.Place.Municipality;
            this.selectedAddress1.Suburb = new Suburb();
            this.selectedAddress1.Suburb.Postcode = new Postcode();
            this.selectedAddress1.Suburb.Postcode.Code = resultAddress.Place.PostalCode;
            this.selectedAddress1.Suburb.Postcode.State = new State();
            this.selectedAddress1.Suburb.Postcode.State.Name = resultAddress.Place.Region;
            this.selectedAddress1.Longitude = String(Math.round((Number(resultAddress.Place.Geometry?.Point[0].toString()) + Number.EPSILON) * 100) / 100),
                this.selectedAddress1.Latitude = String(Math.round((Number(resultAddress.Place.Geometry?.Point[1].toString()) + Number.EPSILON) * 100) / 100)
        }

        if(this.selectedAddress1 && this.selectedAddress1.Suburb && this.selectedAddress1.Suburb.Postcode && this.selectedAddress1.Suburb.Postcode.Code){
            if(this.selectedAddress1.Suburb.Postcode.Code.startsWith("0")){
                this.selectedAddress1.Suburb.Postcode.Code = resultAddress.Place.PostalCode.substring(1);
            }else{
                this.selectedAddress1.Suburb.Postcode.Code = resultAddress.Place.PostalCode;
            }
        }

        this.filteredAdressList = [];
        this.selectedSuburb = null;
        this.CityCtrl.setValue(null, { emitEvent: false });
        this.SuburbCtrl.setValue(null, { emitEvent: false });
        this.PostcodeCtrl.setValue(null, { emitEvent: false });
        if (this.isFromAustralia) {
            this.StateCtrl.setValue(null, { emitEvent: false });
        }

        var postcode;
        var selectedAddrSuburb: Suburb = null;
        var state;
        var selectedAddrCity: City;

        if (this.selectedAddress1) {
            if (this.isFromAustralia) {
                if (this.selectedAddress1.Suburb && this.selectedAddress1.Suburb.Name && this.selectedAddress1.Suburb.Postcode && this.selectedAddress1.Suburb.Postcode.Code) {
                    selectedAddrSuburb = this.suburbList.filter(sub => sub.Name.toLowerCase().trim() === this.selectedAddress1.Suburb.Name.toLowerCase().trim() && sub.Country.Id == countryId && sub.Postcode.Name == this.selectedAddress1.Suburb.Postcode.Code)
                        .map(_item => <Suburb>{
                            SuburbId: _item.Id,
                            Name: _item.Name,
                            Postcode: {
                                Code: this.selectedAddress1.Suburb.Postcode.Code,
                                StateId: _item.State.Id,
                                State: {
                                    StateId: _item.State.Id,
                                    Name: _item.State.Name
                                }
                            }
                        })[0];
                    this.suburbs = [];
                    if (selectedAddrSuburb) {
                        this.selectedSuburb = selectedAddrSuburb;
                        this.SuburbCtrl.setValue({ ...selectedAddrSuburb, Postcode: null }, { emitEvent: false });
                        this.form.get('SuburbId').setValue(selectedAddrSuburb ? selectedAddrSuburb.SuburbId : null);
                        this.form.get('Address').get('SuburbId').setValue(selectedAddrSuburb ? selectedAddrSuburb.SuburbId : null);
                        postcode = selectedAddrSuburb.Postcode;
                    }

                }
                if (postcode) {
                    this.selectedPostcode = { ...postcode, State: null, Region: null };
                    this.postcodes = [this.selectedPostcode];
                    this.PostcodeCtrl.setValue(this.selectedPostcode, { emitEvent: false });

                    state = this.states.find(s => postcode && s.Name === postcode.State.Name);
                    if (state) {
                        this.selectedState = state;
                        this.StateCtrl.setValue(this.selectedState, { emitEvent: false });
                        this.selectedCountry = this.countries.find(c => c.CountryId === state.CountryId);
                        this.CountryCtrl.setValue(this.selectedCountry, { emitEvent: false });
                    }
                }

                if (selectedAddrSuburb && postcode && state) {
                    var ausAddrInfoArr = this.selectedAddress1.Address1.split(',');
                    ausAddrInfoArr = ausAddrInfoArr.map(s => s.trim());
                    var suburbIndex = ausAddrInfoArr.indexOf(this.selectedAddress1.Suburb.Name);
                    const removeFromArray = ausAddrInfoArr.slice(0, suburbIndex);
                    const joinFromArray = removeFromArray.join(', ');
                    var address1 = <SearchForSuggestionsResult>{
                                Text: joinFromArray
                    };
                    this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });
                } else {
                    this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });
                }

            }
            if (!this.isFromAustralia) {

                var addrInfoArr = this.selectedAddress1.Address1.split(',');
                addrInfoArr = addrInfoArr.map(s => s.trim());
                var suburbName = '';
                var cityIndex;

                if (this.selectedAddress1.City) {
                    var sameCityNameCount = addrInfoArr.filter(item => item == this.selectedAddress1.City.valueOf().trim()).length;

                    if (sameCityNameCount > 1) {
                        cityIndex = addrInfoArr.indexOf(this.selectedAddress1.City.valueOf().trim(), addrInfoArr.indexOf(this.selectedAddress1.City.valueOf().trim()) + 1);
                    }
                    else {
                        cityIndex = addrInfoArr.findIndex(i => i.valueOf().toLowerCase().trim() == this.selectedAddress1.City.valueOf().toLowerCase().trim());
                    }
                    suburbName = '';

                    suburbName = addrInfoArr[cityIndex - 1];

                    if (suburbName != '' && suburbName != undefined) {
                        selectedAddrSuburb = this.suburbList.filter(sub => sub.Name.toLowerCase().trim() === suburbName.toLowerCase().trim() && sub.Country.Id == countryId)
                            .map(_item => <Suburb>{
                                SuburbId: _item.Id,
                                Name: _item.Name,
                                Postcode: {
                                    Code: this.selectedAddress1.Suburb.Postcode.Code,
                                    StateId: _item.State.Id,
                                    State: {
                                        StateId: _item.State.Id,
                                        Name: _item.State.Name
                                    }
                                }
                            })[0];

                        if (!selectedAddrSuburb) {
                            selectedAddrSuburb = this.suburbList.filter(sub => sub.Name.toLowerCase().trim() === this.selectedAddress1.City.toLowerCase().trim()
                                && sub.Postcode.Name == this.selectedAddress1.Suburb.Postcode.Code && sub.Country.Id == countryId)
                                .map(_item => <Suburb>{
                                    SuburbId: _item.Id,
                                    Name: _item.Name,
                                    Postcode: {
                                        Code: this.selectedAddress1.Suburb.Postcode.Code,
                                        StateId: _item.State.Id,
                                        State: {
                                            StateId: _item.State.Id,
                                            Name: _item.State.Name
                                        }
                                    }
                                })[0];
                        }

                        this.suburbs = [];
                        if (selectedAddrSuburb) {
                            this.selectedSuburb = selectedAddrSuburb;
                            this.SuburbCtrl.setValue({ ...selectedAddrSuburb, Postcode: null }, { emitEvent: false });
                            this.form.get('SuburbId').setValue(selectedAddrSuburb ? selectedAddrSuburb.SuburbId : null);
                            this.form.get('Address').get('SuburbId').setValue(selectedAddrSuburb ? selectedAddrSuburb.SuburbId : null);
                        }
                    }
                    else {
                        selectedAddrSuburb = this.suburbList.filter(sub => sub.Name.toLowerCase().trim() === this.selectedAddress1.City.toLowerCase().trim()
                            && sub.Postcode.Name == this.selectedAddress1.Suburb.Postcode.Code && sub.Country.Id == countryId)
                            .map(_item => <Suburb>{
                                SuburbId: _item.Id,
                                Name: _item.Name,
                                Postcode: {
                                    Code: this.selectedAddress1.Suburb.Postcode.Code,
                                    StateId: _item.State.Id,
                                    State: {
                                        StateId: _item.State.Id,
                                        Name: _item.State.Name
                                    }
                                }
                            })[0];

                        this.suburbs = [];
                        if (selectedAddrSuburb) {
                            this.selectedSuburb = selectedAddrSuburb;
                            this.SuburbCtrl.setValue({ ...selectedAddrSuburb, Postcode: null }, { emitEvent: false });
                            this.form.get('SuburbId').setValue(selectedAddrSuburb ? selectedAddrSuburb.SuburbId : null);
                            this.form.get('Address').get('SuburbId').setValue(selectedAddrSuburb ? selectedAddrSuburb.SuburbId : null);
                        }
                    }

                    selectedAddrCity = this.cityList.filter(x => x.Name.toLowerCase().trim().includes
                        (this.selectedAddress1.City.toLowerCase().trim()))
                        .map(_item => <City>{
                            CityId: _item.CityId,
                            Name: _item.Name,
                            IsActive: true,
                            PostCode: {
                                Code: this.selectedAddress1.Suburb.Postcode.Code,
                                StateId: 1,
                                State: {
                                    StateId: 1,
                                    Name: 'NZ'
                                }
                            }
                        })[0];

                    this.citySearchResults = [];
                    if (selectedAddrCity) {
                        this.selectedCity = selectedAddrCity;
                        this.CityCtrl.setValue({ ...selectedAddrCity, Postcode: null }, { emitEvent: false });
                        postcode = selectedAddrCity.PostCode;
                    }

                }

                if (postcode) {
                    this.selectedPostcode = { ...postcode, State: null, Region: null };
                    this.postcodes = [this.selectedPostcode];
                    this.PostcodeCtrl.setValue(this.selectedPostcode, { emitEvent: false });

                    state = this.states.find(s => postcode && s.Name === postcode.State.Name);
                    if (state) {
                        this.selectedState = state;
                        this.StateCtrl.setValue(this.selectedState, { emitEvent: false });
                        this.selectedCountry = this.countries.find(c => c.CountryId === state.CountryId);
                        this.CountryCtrl.setValue(this.selectedCountry, { emitEvent: false });
                    }
                }

                if (selectedAddrCity && selectedAddrSuburb && postcode && state) {
                    if (suburbName != '' && suburbName != undefined) {
                        if (this.suburbList.filter(sub => sub.Name.toLowerCase().trim() === suburbName.toLowerCase().trim() && sub.Country.Id == countryId).length > 0) {
                            const removeFromArray = addrInfoArr.slice(0, (cityIndex-1));
                            const joinFromArray = removeFromArray.join(', ');
                            var address1 = <SearchForSuggestionsResult>{
                                        Text: joinFromArray
                            };
                            this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });
                        } else {
                            const removeFromArray = addrInfoArr.slice(0, cityIndex);
                            const joinFromArray = removeFromArray.join(', ');
                            var address1 = <SearchForSuggestionsResult>{
                                        Text: joinFromArray
                            };
                            this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });
                        }
                    } else {
                        const removeFromArray = addrInfoArr.slice(0, cityIndex);
                        const joinFromArray = removeFromArray.join(', ');
                        var address1 = <SearchForSuggestionsResult>{
                                    Text: joinFromArray
                        };
                        this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });
                    }
                } else {
                    this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });
                }

            }
        }
        }
    }

    SelectSuburb(suburb: Suburb) {
        this.selectedSuburb = suburb;
        this.suburbs = [];
        this.SuburbCtrl.setValue({ ...suburb, Postcode: null }, { emitEvent: false });
        this.form.get('SuburbId').setValue(suburb ? suburb.SuburbId : null);
        this.form.get('Address').get('SuburbId').setValue(suburb ? suburb.SuburbId : null);
        if (suburb) {
            // Update the postcode, state and country downward.
            const postcode = suburb.Postcode;
            this.selectedPostcode = { ...postcode, State: null, Region: null };
            this.postcodes = [this.selectedPostcode];
            this.PostcodeCtrl.setValue(this.selectedPostcode, { emitEvent: false });

            const state = this.states.find(s => postcode && s.Name === postcode.State.Name);
            if (state) {
                this.selectedState = state;
                this.StateCtrl.setValue(this.selectedState, { emitEvent: false });
                this.selectedCountry = this.countries.find(c => c.CountryId === state.CountryId);
                this.CountryCtrl.setValue(this.selectedCountry, { emitEvent: false });
            }
        }
    }

    SelectSuburbForBasedOnCity() {
        this.postcodes = this.cityList.filter(x => x.Name == this.selectedCity.Name).map(y => y.PostCode);
        this.PostcodeCtrl.setValue(this.cityList.filter(x => x.Name == this.selectedCity.Name).map(y => y.PostCode)[0], { emitEvent: false });
    }

    ValidatePrimaryEmail(force = false) {
        this.isEmailDuplicated = false;
        this.currentPrimaryEmailSearchText = '';
        const control = this.form.get('PrimaryEmail');
        const value = (control.value || '').trim().toLowerCase();
        const currentPrimaryEmail = this.data.model.PrimaryEmail.toLowerCase();

        if (control.invalid || !value || (
            !force && (
                !this.requireEmailValidation ||
                value === this.originalPrimaryEmail ||
                value === currentPrimaryEmail ||
                value === this.currentPrimaryEmailSearchText))) {
            if (control.invalid || !value) {
                return;
            }
            if (value === this.originalPrimaryEmail) {
                this.isEmailDuplicated = false;
                this.currentPrimaryEmailSearchText = value;
                return;
            }

            if (this.data.model.PrimaryMember) {
                if (this.isEmailDuplicated) {
                    control.setErrors({ 'duplicated': { value: value } });
                    control.markAsDirty();
                }
                return;
            }
            //this.currentPrimaryEmailSearchText = value;
            // return;
        }
        control.updateValueAndValidity({ emitEvent: false });
        let allMemberFamCus = (this.data.model.FamilyMembers || []).map(_member => {
            return { PrimaryEmail: _member.PrimaryEmail, MemberTypeId: _member.MemberTypeId, DateOfBirth: _member.DateOfBirth };
        });
        const currentFMemberIndex = allMemberFamCus.findIndex(
            _member => ((_member.MemberTypeId === EMemberType.Dependant) && (Utils.calculateAge(<Date>_member.DateOfBirth) < 18))
        );
        if (currentFMemberIndex > -1) {
            allMemberFamCus.splice(currentFMemberIndex, 1);
        }
        let allMemberEmails = allMemberFamCus.map(_member => _member.PrimaryEmail.toLowerCase());
        if (this.data.model.PrimaryMember) {
            const famMemberEmails = (this.data.model.PrimaryMember.FamilyMembers || []).map(_member => _member.PrimaryEmail.toLowerCase());
            allMemberEmails = [
                this.data.model.PrimaryMember.PrimaryEmail.toLowerCase(),
                ...famMemberEmails
            ];
        }
        if (allMemberEmails.indexOf(value) > -1) {
            this.currentPrimaryEmailSearchText = value;
            control.setErrors({ 'duplicated': { value: value } });
            this.isEmailDuplicated = true;
            control.markAsDirty();
        } else {
            if (value === this.currentPrimaryEmailSearchText &&
                !this.data.model.PrimaryMember) {
                if (this.isEmailDuplicated) {
                    control.setErrors({ 'duplicated': { value: value } });
                    control.markAsDirty();
                }
                return;
            }
            control.disable({ emitEvent: false });
            control.markAsPending();
            this.userSvc.checkExistEmailv2(value).subscribe(
                (res: DataResult<string>) => {
                    const enctypted_data = res.Data;
                    const dectypted_data = this.commonSvc.D_FP_ResponseData(enctypted_data);
                    const ret = JSON.parse(dectypted_data);
                    control.enable({ emitEvent: false });
                    control.markAsDirty();
                    if (res.Success) {
                        this.currentPrimaryEmailSearchText = value;
                        if (ret.IsExist) {
                            control.setErrors({ 'duplicated': { value: value } });
                            this.isEmailDuplicated = true;
                        } else {
                            this.isEmailDuplicated = false;
                        }
                    } else {
                        this.HandleResponseError(res);
                    }
                    this.changeDetectorRef.markForCheck();
                },
                err => {
                    control.enable({ emitEvent: false });
                    this.handleError(err);
                });
        }
    }
}

