import {
  Component,
  ElementRef,
  ViewChild,
  Injector,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { empty, of as observableOf } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatStepper } from "@angular/material/stepper";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { SearchForSuggestionsResult } from "aws-sdk/clients/location";
import { DeviceDetectorService } from "ngx-device-detector";
import { catchError, debounceTime, skipWhile, switchMap } from "rxjs/operators";

import {
  CommonMessage,
  CommonString,
  PatternConstant,
  RouterConstant,
} from "@fp/constant";
import {
  Address,
  Gender,
  Postcode,
  ResultModel,
  SearchFieldOperator,
  SearchPaginationCriteria,
  State,
  Suburb,
  SuburbPostcodeStateModel,
  SuburbSearchResultRecord,
  UploadFile,
  UserModel,
} from "@fp/models";
import {
  CommonService,
  MemberService,
  MemberSignupService,
  MessageBox,
} from "@fp/services";
import { FPValidators, StringHelper, Utils } from "@fp/helpers";
import { MedicalQuestionsComponentComponent } from "@fp/medical-questions-component/medical-questions-component.component";
import { City } from "@fp/models/city.model";
import { AddressService } from "@fp/services/address.service";
import { CommonConstants } from "@fp/constant/common-constants";
import { LocationService } from "@fp/services/location.service";
import { AddressLocation } from "@fp/models/address-location.model";
import * as enums from "src/app/enums";
import { MembershipUploadPhotoComponent } from "../members/membership-cards/membership-upload-photo/membership-upload-photo.component";
import { FpFileUploadComponent } from "../control";
import { MemberFormBase } from "../base";
import { DialogResult } from "../common-dialog-new/common-dialog-new.component";
import { MobileNumberRegistorComponent } from "../control/mobile-number-registor/mobile-number-registor.component";
@Component({
  selector: "personal-details-new",
  templateUrl: "./personal-details-new.component.html",
  styleUrls: ["./personal-details-new.component.css"],
  providers: [
    { provide: MemberFormBase, useExisting: PersonalDetailsNewComponent },
  ],
})
export class PersonalDetailsNewComponent extends MemberFormBase {
  deviceInfo = null;
  parentFocus = 0;
  selectedCity: City;
  private selectedSuburb: Suburb;
  panelOpenState = false;
  private selectedState: State = null;
  private cityList: City[];
  states: State[];
  isAusMember = true;
  selectedDate: Date;
  selectedFile = null;
  genders: Gender[];
  btnNext = false;
  mobileLength = 10;
  unusedPhoneEmail: boolean = false;
  clsLableVal = "ABC";
  col_4 = true;
  imgUrl: any;
  currentImageBase64Data: string;
  persanalDetailsFormGroup: UntypedFormGroup;
  CommonMessage = CommonMessage;
  CommonString = CommonString;
  StringHelper = StringHelper;
  isSearchingCity = false;
  citySearchResults: City[];
  selectedCityId = 0;
  location = "";
  User = "";
  years: any = [];
  days: any = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  genderList: any = [];
  addressList: string[];
  filteredAdressList: SearchForSuggestionsResult[];
  country = "";
  countryCode = "";
  isAu = true;
  private suburbList: SuburbSearchResultRecord[];
  suburbs: Suburb[];
  postcodes: Postcode[] = [];
  private selectedPostcode: Postcode = null;
  private selectedAddress1: AddressLocation;
  isSearchingSurburb = false;
  isDataSubmittedSuccessfully: boolean = false;
  isFormDisabled: boolean = false;
  photoUploader: FpFileUploadComponent;
  countriesId = CommonConstants.GET_COUNTRIES;
  isMobile = false;
  private searchSuburbBusyIndicator = {
    start: () => {
      this.SuburbCtrl.markAsPending();
    },
    stop: () => {
      this.SuburbCtrl.updateValueAndValidity({ emitEvent: false });
    },
  };

  @Output() isCompleteForm: EventEmitter<any> = new EventEmitter();
  @ViewChild("stepper") stepper: MatStepper;
  @ViewChild("medicalQuestions")
  medicalQuestions: MedicalQuestionsComponentComponent;
  @ViewChild("emergencyMobileNumberRegistorComponent")
  emergencyMobileNumberRegistor: MobileNumberRegistorComponent<any>;
  @ViewChild("nxtButtonRef") nxtButtonRef: ElementRef;

  get SuburbCtrl() {
    return this.persanalDetailsFormGroup.get("txtSuburb");
  }
  get CityCtrl() {
    return this.persanalDetailsFormGroup.get("txtCity");
  }
  get PostCodeCtrl() {
    return this.persanalDetailsFormGroup.get("txtPostcode");
  }
  get StateCtrl() {
    return this.persanalDetailsFormGroup.get("txtState");
  }
  get AddressLine1Ctrl() {
    return this.persanalDetailsFormGroup.get("txtAddressLine1");
  }

  constructor(
    injector: Injector,
    private msService: MemberSignupService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private memberSvc: MemberService,
    private locationservice: LocationService,
    private router: Router,
    private addressService: AddressService,
    private cdRef: ChangeDetectorRef,
    protected dialog: MatDialog,
    private http: HttpClient,
    private deviceService: DeviceDetectorService
  ) {
    super(injector);
    this.photoUploader = new FpFileUploadComponent(
      modalService,
      dialog,
      http,
      commonService
    );
    this.photoUploader.ngOnInit();

    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();

    if (isMobile || isTablet) {
      this.isMobile = true;
    }

    this.photoUploader.UploadCompleted.subscribe((data: any) => {
      if (data && data.filedata && data.filedata.url) {
        this.imgUrl = data.filedata.url;

        const s3path = data.filedata.Location;
        const key = data.filedata.Location.substring(
          data.filedata.Location.indexOf("memberPhotos/"),
          data.filedata.Location.length
        );

        this.persanalDetailsFormGroup.get("photo").setValue({
          Id: 0,
          FileName: "Photo",
          Data: key,
          Location: s3path,
        });

        this.commonService.getDownloadUrl(key).subscribe(
          (res) => {
            this.imgUrl = res;
          },
          (err) => {
            console.error("Error in get url", err);
          }
        );
      }
    });
  }

  ngOnInit(): void {
    this.isSearchingSurburb = true;
    this.genders = CommonConstants.GET_GENDERS;
    this.commonService.getLocationData().subscribe((res) => {
      if (res.Success) {
        this.suburbList = res.Data.suburbs;
        this.isSearchingSurburb = false;
      }
    });
    this.prepopulateForm()
  }

  ngAfterViewInit() {
    this.persanalDetailsFormGroup.addControl(
      "medicalQuestions",
      this.medicalQuestions.medicalQuestionFormGroup
    );
    this.OnLoad();
  }

  OnLoad() {
    this.isSearchingCity = true;
    this.addressService.getCityDetail().subscribe((res) => {
      this.cityList = res.Data;
      this.isSearchingCity = false;
    });
  }

  LoadComplete() {
    let currentValue = "";
    setTimeout(() => {
      if (this.data.model.Address) {
        var address1 = <SearchForSuggestionsResult>{
          Text: this.data.model.Address.StreetAddress1,
        };
        this.AddressLine1Ctrl.setValue({ ...address1 }, { emitEvent: false });
      }

      if (!this.isAu) {
        this.CityCtrl.valueChanges.subscribe((value: City) => {
          this.selectedCity = value;
          this.SelectSuburbForBasedOnCity();
        });
      }

      this.StateCtrl.valueChanges.subscribe((value: State) => {
        if (
          !this.selectedState ||
          !value ||
          value.StateId !== this.selectedState.StateId
        ) {
          this.changeState(value);
        }
      });

      this.AddressLine1Ctrl.valueChanges
        .pipe(
          debounceTime(500),
          switchMap((value) => {
            if (typeof value === "string") {
              this.selectedAddress1 = null;
              this.filteredAdressList = [];

              if (value.length > 2) {
                return observableOf(value);
              } else if (value.length === 0) {
                return observableOf(null);
              }
            } else if (typeof value === "object") {
              return observableOf(value);
            }
            return empty();
          })
        )
        .subscribe((value: string | SearchForSuggestionsResult) => {
          if (value) {
            if (typeof value === "string") {
              this.searchAddress(value);
            } else if (typeof value === "object") {
              this.SelectAddress1(value);
            }
          }
        });

      if (!this.isAu) {
        this.CityCtrl.valueChanges
          .pipe(
            skipWhile((value) => value && typeof value === "object"),
            debounceTime(500),
            catchError((e) => {
              throw e;
            })
          )
          .subscribe(
            (value: string) => {
              this.isSearchingCity = false;
              if (currentValue != this.CityCtrl.value) {
                currentValue = this.CityCtrl.value;
                let cityList: City[] = this.cityList;
                cityList = cityList.filter((x) =>
                  x.Name.toLowerCase()
                    .trim()
                    .includes(
                      this.CityCtrl.value?.toString().toLowerCase().trim()
                    )
                );
                cityList.sort((a, b) => a.Name.localeCompare(b.Name));
                let uniqueCities: City[] = [];
                cityList.map((x) =>
                  uniqueCities.filter((a) => a.Name == x.Name).length > 0
                    ? null
                    : uniqueCities.push(x)
                );
                this.citySearchResults = uniqueCities;
                this.changeDetectorRef.markForCheck();
              }
            },
            (error) => {
              this.isSearchingCity = false;
              MessageBox.ShowError(
                this.dialog,
                "Sorry, we're having trouble connecting. Let's try that again."
              ).subscribe((res) => {
                if (res.result.toLowerCase() === DialogResult.Ok) {
                  window.location.reload();
                }
              });
              this._logger.error(error);
            }
          );
      }

      this.SuburbCtrl.valueChanges
        .pipe(
          debounceTime(500),
          switchMap((value) => {
            this.searchSuburbBusyIndicator.stop();
            if (typeof value === "string") {
              // Clear previous results
              this.selectedSuburb = null;
              this.suburbs = [];

              if (value.length > 2) {
                return observableOf(value);
              } else if (value.length === 0) {
                return observableOf(null);
              }
            } else if (typeof value === "object") {
              return observableOf(value);
            }
            return empty();
          })
        )
        .subscribe((value: string | Suburb) => {
          if (value) {
            if (typeof value === "string") {
              this.searchSuburbs(value);
            } else if (typeof value === "object") {
              this.SelectSuburb(value);
            }
          }
        });
    });
  }

  private prepopulateForm() {
    const builder = new UntypedFormBuilder();
    this.persanalDetailsFormGroup = builder.group({
      txtFirstName: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.VALID_NAME)],
      ],
      txtLasttName: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.VALID_NAME)],
      ],
      txtGender: ["", Validators.required],
      psdDay: ["", Validators.required],
      psdMonth: ["", Validators.required],
      psdYear: ["", Validators.required],
      txtMobileNumber: ["", Validators.required],
      txtPEmail: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.EMAIL)],
      ],
      txtSEmail: ["", Validators.pattern(PatternConstant.EMAIL)],
      AddressId: [null],
      StreetAddress1: [null],
      txtAddressLine1: ["", Validators.required],
      txtAddressLine2: [null],
      txtSuburb: ["", Validators.required],
      txtCity: [null],
      txtState: [null],
      txtCountry: [""],
      txtPostcode: ["", Validators.required],
      SuburbId: [0],
      txtEMName: ["", Validators.required],
      txtEMRelationship: ["", Validators.required],
      txtEmergencyMobileNumber: ["", Validators.required],
      photo: [""],
    });
    this.getCognitoMobile();
    this.getYearsForBirthday();
  }

  private async getCognitoMobile() {
    const { phone } = await this.commonService.getCurrentUserEmailAndPhone();
    this.persanalDetailsFormGroup.controls.txtMobileNumber.setValue(phone);
  }

  private getYearsForBirthday() {
    let currentYear = new Date().getFullYear();
    const startYear = currentYear - 100;
    let year = currentYear;
    for (let i = currentYear; startYear < i; i--) {
      this.years.push(year);
      year = year - 1;
    }
  }

  private searchSuburbs(text: string) {
    const criteria = new SearchPaginationCriteria();
    criteria.PageSize = 0;
    criteria.ViewColumns = null;
    criteria.Criteria.Fields = [
      { Name: "name", Operator: SearchFieldOperator.Contains, Value: text },
    ];
    var countryId = this.isAu ? 1 : 2;
    this.suburbs = this.suburbList
      .filter(
        (x) =>
          (x.Name.toLowerCase().trim().includes(text.toLowerCase().trim()) ||
            x.Postcode.Name.includes(text.toLowerCase().trim())) &&
          x.Country.Id === countryId
      )
      .map(
        (_item) =>
          <Suburb>{
            SuburbId: _item.Id,
            Name: _item.Name,
            PostcodeId: _item.Postcode.Id,
            Postcode: {
              PostcodeId: _item.Postcode.Id,
              Code: _item.Postcode.Name,
              StateId: _item.State.Id,
              State: {
                StateId: _item.State.Id,
                Name: _item.State.Name,
              },
            },
          }
      );
  }

  private async searchAddress(text: string) {
    const country = this.isAu ? "AUS" : "NZL";

    const addressResult = await this.locationservice.getLocationSuggestions(
      text,
      country
    );
    this.filteredAdressList = addressResult.Results.map((a) => a);
  }

  private changeState(state: State) {
    this.selectedState = state;

    // Update upward
    this.PostCodeCtrl.setValue(null, { emitEvent: false });
    if (this.selectedPostcode) {
      this.PostCodeCtrl.markAsDirty();
    }
    this.postcodes = [];
    this.changePostcode(null);
  }

  private changePostcode(postcode: Postcode) {
    this.selectedPostcode = postcode;

    // Update upwardlocationthis.data.model.Address
    this.SuburbCtrl.setValue(null, { emitEvent: false });
    if (this.selectedSuburb) {
      this.SuburbCtrl.markAsDirty();
    }
    this.suburbs = [];
    this.selectedSuburb = null;
  }

  SelectSuburbForBasedOnCity() {
    this.postcodes = this.cityList
      .filter((x) => x.Name == this.selectedCity.Name)
      .map((y) => y.PostCode);
    this.PostCodeCtrl.setValue(
      this.cityList
        .filter((x) => x.Name == this.selectedCity.Name)
        .map((y) => y.PostCode)[0],
      { emitEvent: false }
    );
  }

  public onDOBSelected(): void {
    let month = this.persanalDetailsFormGroup.get("psdMonth").value;
    let month1 = month && (parseInt(month) + 1).toString().padStart(2, "0");
    let Year = this.persanalDetailsFormGroup.get("psdYear").value;
    let day = this.persanalDetailsFormGroup.get("psdDay").value;
    let day1 = day && day.toString().padStart(2, "0");
    if (month && Year && day) {
      this.selectedDate = new Date(`${Year}-${month1}-${day1}`);
      const today = new Date();
      today.getHours;
      const sixteenYearsAgo = new Date(
        today.getFullYear() - 16,
        today.getMonth(),
        today.getDate(),
        today.getHours(),
        today.getMinutes(),
        today.getMinutes(),
        today.getSeconds()
      );
      if (this.selectedDate) {
        if (
          !(
            this.selectedDate.getFullYear() == Year &&
            this.selectedDate.getMonth() == month &&
            this.selectedDate.getDate() == day
          )
        ) {
          MessageBox.ShowError(this.dialog, "Please choose a valid Date.");
          this.persanalDetailsFormGroup.get("psdDay").setValue(null);
        } else if (this.selectedDate >= sixteenYearsAgo) {
          MessageBox.ShowError(
            this.dialog,
            "You need to be 16 years or over to sign up."
          );
          this.persanalDetailsFormGroup.get("psdDay").setValue(null);
          this.persanalDetailsFormGroup.get("psdMonth").setValue(null);
          this.persanalDetailsFormGroup.get("psdYear").setValue(null);
        }
      }
    }
  }

  getdynmicGender($event) {
    let gender = (<HTMLInputElement>(
      document.getElementById("txtGender")
    )).value.toLocaleLowerCase();
    this.genderList = ["Male", "Female"];
    let matches = [],
      i;
    for (i = 0; i < this.genderList.length; i++) {
      if (this.genderList[i].toLowerCase().match(gender)) {
        matches.push(this.genderList[i]);
      }
    }
    this.genderList = matches;
  }

  /** @internal */
  AutoComplete_FocusOut(e) {
    if (this.AddressLine1Ctrl.value) {
      this.AddressLine1Ctrl.setErrors(null);
      if (this?.filteredAdressList?.length == 0) {
        var address1 = <SearchForSuggestionsResult>{
          Text: e.target.value,
        };
        this.AddressLine1Ctrl.setValue({ ...address1 }, { emitEvent: false });
      }
    }
  }

  openUploadPhotoModal() {
    var modalRef = this.modalService.open(MembershipUploadPhotoComponent, {
      backdrop: "static",
    });

    (<MembershipUploadPhotoComponent>(
      modalRef.componentInstance
    )).memberPersonalSelected = {
      id: 0,
      photo: null,
    };

    modalRef.result.then(
      (result: { location: string }) => {
        const s3path = result.location;
        const key = result.location.substring(
          result.location.indexOf("memberPhotos/"),
          result.location.length
        );

        this.persanalDetailsFormGroup.get("photo").setValue({
          Id: 0,
          FileName: "Photo",
          Data: key,
          Location: s3path,
        });

        this.commonService.getDownloadUrl(key).subscribe(
          (res) => {
            this.imgUrl = res;
          },
          (err) => {
            console.error("Error in get url", err);
          }
        );
      },
      (reason) => { }
    );
  }

  pdSubmit() {
    if (this.persanalDetailsFormGroup.valid) {
      // Filling Personal Info

      this.data.model.FirstName =
        this.persanalDetailsFormGroup.get("txtFirstName").value;
      this.data.model.LastName =
        this.persanalDetailsFormGroup.get("txtLasttName").value;
      this.data.model.GenderId =
        this.persanalDetailsFormGroup.get("txtGender").value;
      this.data.model.DateOfBirth = this.selectedDate;
      this.data.model.MobileNumber = this.persanalDetailsFormGroup.get("txtMobileNumber").value
      this.data.model.PrimaryEmail =
        this.persanalDetailsFormGroup.get("txtPEmail").value;
      this.data.model.SecondaryEmail =
        this.persanalDetailsFormGroup.get("txtSEmail").value;
      this.data.model.Photo = this.persanalDetailsFormGroup.get("photo").value;
      this.data.model.EmergencyContactNo =
        "+" +
        Utils.convertPhoneToInternationalFormatWithCountryCode(
          this.persanalDetailsFormGroup.get("txtEmergencyMobileNumber").value
        );
      this.data.model.EmergencyContactName =
        this.persanalDetailsFormGroup.get("txtEMName").value;
      this.data.model.EmergencyRelationship =
        this.persanalDetailsFormGroup.get("txtEMRelationship").value;

      var medicalAnswers =
        this.persanalDetailsFormGroup.get("medicalQuestions").value;
      this.data.model.MedicalAnswers =
        medicalAnswers?.medicalQuestionAndAnswers;

      // Filling Address Info
      if (!this.data.model.Address) {
        this.data.model.Address = new Address();
        this.data.model.Address.AddressId = 0;
      } else {
        this.data.model.Address.AddressId =
          this.persanalDetailsFormGroup.controls.AddressId?.value;
      }
      this.data.model.Address.Pobox = this.PostCodeCtrl.value.Code;
      if (this.persanalDetailsFormGroup.controls.txtAddressLine1.value && this.persanalDetailsFormGroup.controls.txtAddressLine1.value.Text) {
        this.data.model.Address.StreetAddress1 =
          this.persanalDetailsFormGroup.controls.txtAddressLine1.value.Text;
      } else {
        this.data.model.Address.StreetAddress1 =
          this.persanalDetailsFormGroup.controls.txtAddressLine1.value;
      }
      this.data.model.Address.StreetAddress2 =
        this.persanalDetailsFormGroup.controls.txtAddressLine2.value;
      this.data.model.Address.SuburbId = this.SuburbCtrl.value.SuburbId;

      this.data.model.Suburb = this.SuburbCtrl.value;
      this.data.model.Postcode = this.PostCodeCtrl.value;
      this.data.model.State = this.StateCtrl.value;
      this.data.model.Country = CommonConstants.GET_COUNTRIES.find(
        (X) => X.CountryId == this.data.model.State.CountryId
      );

      if (!this.isAu) {
        this.data.model.Address.CityId = this.CityCtrl.value.CityId;
        if (this.data.model && this.data.model.City && this.data.model.City.Name) {
          this.data.model.City.Name = this.CityCtrl.value.Name;
        }
      }

      if (this.data.model.GenderId == null || this.data.model.DateOfBirth == null) {
        console.log("DoB : " + this.data.model.DateOfBirth);
        MessageBox.ShowError(
          this.dialog,
          "You have not selected the Date Of Birth or Gender.<br>Please make sure to fill the Date of Birth and Gender before submiting."
        ).subscribe((dialogResult) => {
          if (dialogResult.result.toLowerCase() === DialogResult.Ok) {
            localStorage.setItem("signupFlag", "false");
            this.router.navigate([RouterConstant.ROUTER_CONTINUE_MEMBER_SIGNUP]);
            location.reload();
          }
        });
        console.log("Test Gender or DOB Null");
      }
      else {
        this.Invoke(this.memberSvc.SaveSignupPersonalInfo(this.data.model), {
          onSuccess: (res: ResultModel) => {
            if (res.Success) {
              this.isDataSubmittedSuccessfully = true;

              if (this.data.model.Country.CountryId == 1) {
                this.commonService.setUserCountry(this.CommonString.AUSTRALIA);
              } else {
                this.commonService.setUserCountry(this.CommonString.NEWZEALAND);
              }

              this.isCompleteForm.emit(CommonConstants.SIGNUP_PERSONAL_DETAILS);
            } else {
              const supportLink =
                '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
              MessageBox.ShowOKCustom(
                this.dialog,
                "Error",
                "Sorry, we're unable to complete the registration. \
                            Please try again. If you continue to experience problems, send us an enquiry \
                            via our support site " +
                supportLink,
                "Take me back"
              ).subscribe((res) => {
                if (res.result.toLowerCase() === DialogResult.Ok) {
                  this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
                }
              });
              this._logger.error(res);
              this.isDataSubmittedSuccessfully = false;
            }
          },
          onError: (err) => {
            const supportLink =
              '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
            MessageBox.ShowOKCustom(
              this.dialog,
              "Error",
              "Sorry, we're unable to complete the registration. \
                        Please try again. If you continue to experience problems, send us an enquiry \
                        via our support site " +
              supportLink,
              "Take me back"
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
              }
            });
            this._logger.error(err);
            this.isDataSubmittedSuccessfully = false;
          },
        });
      }
    }
  }

  public findInvalidControlsRecursive(
    formToInvestigate: UntypedFormGroup | UntypedFormArray
  ): string[] {
    var invalidControls: string[] = [];
    let recursiveFunc = (form: UntypedFormGroup | UntypedFormArray) => {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        if (control.invalid) invalidControls.push(field);
        if (control instanceof UntypedFormGroup) {
          recursiveFunc(control);
        } else if (control instanceof UntypedFormArray) {
          recursiveFunc(control);
        }
      });
    };
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }

  public runCheck(event) {
    this.checkPhoneEmailResponsive();
  }
  public checkPhoneEmailResponsive() {
    this.unusedPhoneEmail = false;
    if (true) {
      const pin: UserModel = {};
      let number = "";
      const tempnumber: string =
        this.persanalDetailsFormGroup.get("txtEmergencyMobileNumber").value;

      if (this.mobileLength == 10 && tempnumber.indexOf("0") == 0) {
        number = this.persanalDetailsFormGroup
          .get("txtEmergencyMobileNumber")
          .value.replace(0, "");
      } else {
        number = this.persanalDetailsFormGroup.get("txtEmergencyMobileNumber").value;
      }
      pin.EmailAddress = this.persanalDetailsFormGroup
        .get("txtEmail")
        .value.toLowerCase()
        .trim();
      pin.MobileNumber = "+61" + number;
    }
  }

  uploadPhoto(e: Event) {
    this.openUploadPhotoModal();
  }

  btnUploadPhoto_Click(e: Event) {
    // Intentionally leave blank for overriding.
  }

  addImage(event) {
    const imageFile = event.target.files[0];
    var fileType = imageFile.name.split(".").pop();
    if (
      imageFile.type != "image/png" &&
      imageFile.type != "image/jpg" &&
      imageFile.type != "image/jpeg"
    ) {
      MessageBox.ShowError(
        this.dialog,
        StringHelper.format(
          CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE,
          "JPEG or PNG"
        )
      );
      return;
    } else if (imageFile.size > CommonString.MAX_UPLOAD_FILE_SIZE_10MB) {
      MessageBox.ShowError(
        this.dialog,
        StringHelper.format(CommonString.MAX_UPLOAD_FILE_SIZE_MESSAGE, "10MB")
      );
      return;
    }
    let reader = new FileReader();
    reader.onloadend = () => {
      this.currentImageBase64Data = <string>reader.result;
      const image: UploadFile = {
        FileName: imageFile.name,
        Location: null,
        Data: (<string>reader.result).replace(/^data:(.*;base64,)?/, ""),
      };

      const blobBin = atob(image.Data);
      let array = [];
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      const file = new Blob([new Uint8Array(array)], {
        type: fileType.toLowerCase(),
      });
      const event = {
        target: {
          files: [file],
        },
      };

      //upload member photo
      this.photoUploader.fileEventManual(event, fileType.toLowerCase());
    };
    reader.readAsDataURL(imageFile);
  }

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }

  /** @internal */
  ddlStateOption_CompareFn(opt1: State, opt2: State) {
    return (!opt1 && !opt2) || (opt1 && opt2 && opt1.StateId === opt2.StateId);
  }

  /** @internal */
  AutoComplete_DisplayWithFn(option: any) {
    if (!option) {
      return "";
    }
    return option.Name || option.Code;
  }

  /** @internal */
  txtSuburb_Blur(e) {
    if (this.SuburbCtrl.dirty) {
      this.SuburbCtrl.setValidators([
        Validators.required,
        FPValidators.resolvableAutocompleteOption,
      ]);
      this.SuburbCtrl.updateValueAndValidity({ emitEvent: false });
    }
  }

  /** @internal */
  txtSuburb_Input(e) {
    if (this.SuburbCtrl.dirty) {
      this.SuburbCtrl.setValidators(Validators.required);
    }
  }

  async SelectAddress1(selectedAddressEvent: any) {
    if (selectedAddressEvent.option) {
      var selectedAddress = <SearchForSuggestionsResult>selectedAddressEvent.option.value;
      const country = this.isAu ? "AUS" : "NZL";
      const countryId = this.isAu
        ? this.countriesId[0].CountryId
        : this.countriesId[1].CountryId;
      const addressResult = await this.locationservice.getLocation(
        selectedAddress.Text,
        country
      );

      var resultAddress = addressResult.Results[0];

      if (this.isAu) {
        this.selectedAddress1 = new AddressLocation();
        this.selectedAddress1.Address1 = resultAddress.Place.Label;
        this.selectedAddress1.Suburb = new Suburb();
        this.selectedAddress1.Suburb.Name = resultAddress.Place.Neighborhood;
        this.selectedAddress1.Suburb.Postcode = new Postcode();
        this.selectedAddress1.Suburb.Postcode.Code =
          resultAddress.Place.PostalCode;
        this.selectedAddress1.Suburb.Postcode.State = new State();
        this.selectedAddress1.Suburb.Postcode.State.Name =
          resultAddress.Place.Region;
      } else {
        this.selectedAddress1 = new AddressLocation();
        this.selectedAddress1.Address1 = resultAddress.Place.Label;
        this.selectedAddress1.City = resultAddress.Place.Municipality;
        this.selectedAddress1.Suburb = new Suburb();
        this.selectedAddress1.Suburb.Postcode = new Postcode();
        this.selectedAddress1.Suburb.Postcode.Code =
          resultAddress.Place.PostalCode;
        this.selectedAddress1.Suburb.Postcode.State = new State();
        this.selectedAddress1.Suburb.Postcode.State.Name =
          resultAddress.Place.Region;
      }

      if (this.selectedAddress1 && this.selectedAddress1.Suburb && this.selectedAddress1.Suburb.Postcode && this.selectedAddress1.Suburb.Postcode.Code) {
        if (this.selectedAddress1.Suburb.Postcode.Code.startsWith("0")) {
          this.selectedAddress1.Suburb.Postcode.Code = resultAddress.Place.PostalCode.substring(1);
        } else {
          this.selectedAddress1.Suburb.Postcode.Code = resultAddress.Place.PostalCode;
        }
      }

      this.filteredAdressList = [];
      this.selectedSuburb = null;
      this.CityCtrl.setValue(null, { emitEvent: false });
      this.SuburbCtrl.setValue(null, { emitEvent: false });
      this.PostCodeCtrl.setValue(null, { emitEvent: false });
      if (this.isAu) {
        this.StateCtrl.setValue(null, { emitEvent: false });
      }

      var postcode;
      var selectedAddrSuburb: Suburb = null;
      var state;
      var selectedAddrCity: City;

      if (this.selectedAddress1) {
        if (this.isAu) {
          if (!this.selectedAddress1.Suburb.Name) {
            return;
          }
          selectedAddrSuburb = this.suburbList
            .filter(
              (sub) =>
                sub.Name.toLowerCase().trim() ===
                this.selectedAddress1.Suburb.Name.toLowerCase().trim() &&
                sub.Country.Id == countryId && sub.Postcode.Name == this.selectedAddress1.Suburb.Postcode.Code
            )
            .map(
              (_item) =>
                <Suburb>{
                  SuburbId: _item.Id,
                  Name: _item.Name,
                  Postcode: {
                    Code: this.selectedAddress1.Suburb.Postcode.Code,
                    StateId: _item.State.Id,
                    State: {
                      StateId: _item.State.Id,
                      Name: _item.State.Name,
                    },
                  },
                }
            )[0];

          if (selectedAddrSuburb) {
            this.selectedSuburb = selectedAddrSuburb;
            this.suburbs = [];
            this.SuburbCtrl.setValue(
              { ...selectedAddrSuburb, Postcode: null },
              { emitEvent: false }
            );
            this.persanalDetailsFormGroup
              .get("SuburbId")
              .setValue(selectedAddrSuburb ? selectedAddrSuburb.SuburbId : null);
            postcode = selectedAddrSuburb.Postcode;
          }

          if (postcode) {
            this.selectedPostcode = { ...postcode, State: null, Region: null };
            this.postcodes = [this.selectedPostcode];
            this.PostCodeCtrl.setValue(this.selectedPostcode, { emitEvent: false });

            state = this.states.find(
              (s) => postcode && s.Name === postcode.State.Name
            );
            if (state) {
              this.selectedState = state;
              this.StateCtrl.setValue(this.selectedState, { emitEvent: false });
            }
          }

          if (selectedAddrSuburb && postcode && state) {
            var ausAddrInfoArr = this.selectedAddress1.Address1.split(',');
            ausAddrInfoArr = ausAddrInfoArr.map(s => s.trim());
            var suburbIndex = ausAddrInfoArr.indexOf(this.selectedAddress1.Suburb.Name);
            const removeFromArray = ausAddrInfoArr.slice(0, suburbIndex);
            const joinFromArray = removeFromArray.join(', ');
            var selectedAddress = <SearchForSuggestionsResult>{
              Text: joinFromArray
            };
            this.AddressLine1Ctrl.setValue({ ...selectedAddress }, { emitEvent: false });
          } else {
            this.AddressLine1Ctrl.setValue({ ...selectedAddress }, { emitEvent: false });
          }
        }
        if (!this.isAu) {
          var addrInfoArr = this.selectedAddress1.Address1.split(",");
          addrInfoArr = addrInfoArr.map((s) => s.trim());
          var suburbName = '';
          var cityIndex;

          if (this.selectedAddress1.City) {
            var sameCityNameCount = addrInfoArr.filter(
              (item) => item == this.selectedAddress1.City.valueOf().trim()
            ).length;

            if (sameCityNameCount > 1) {
              cityIndex = addrInfoArr.indexOf(
                this.selectedAddress1.City.valueOf().trim(),
                addrInfoArr.indexOf(this.selectedAddress1.City.valueOf().trim()) +
                1
              );
            } else {
              cityIndex = addrInfoArr.findIndex(
                (i) =>
                  i.valueOf().toLowerCase().trim() ==
                  this.selectedAddress1.City.valueOf().toLowerCase().trim()
              );
            }
            suburbName = '';

            suburbName = addrInfoArr[cityIndex - 1];

            if (suburbName != '' && suburbName != undefined) {
              selectedAddrSuburb = this.suburbList.filter(sub => sub.Name.toLowerCase().trim() === suburbName.toLowerCase().trim() && sub.Country.Id == countryId)
                .map(_item => <Suburb>{
                  SuburbId: _item.Id,
                  Name: _item.Name,
                  Postcode: {
                    Code: this.selectedAddress1.Suburb.Postcode.Code,
                    StateId: _item.State.Id,
                    State: {
                      StateId: _item.State.Id,
                      Name: _item.State.Name
                    }
                  }
                })[0];

              if (!selectedAddrSuburb) {
                selectedAddrSuburb = this.suburbList.filter(sub => sub.Name.toLowerCase().trim() === this.selectedAddress1.City.toLowerCase().trim()
                  && sub.Postcode.Name == this.selectedAddress1.Suburb.Postcode.Code && sub.Country.Id == countryId)
                  .map(_item => <Suburb>{
                    SuburbId: _item.Id,
                    Name: _item.Name,
                    Postcode: {
                      Code: this.selectedAddress1.Suburb.Postcode.Code,
                      StateId: _item.State.Id,
                      State: {
                        StateId: _item.State.Id,
                        Name: _item.State.Name
                      }
                    }
                  })[0];
              }
              if (selectedAddrSuburb) {
                this.selectedSuburb = selectedAddrSuburb;
                this.suburbs = [];
                this.SuburbCtrl.setValue({ ...selectedAddrSuburb, Postcode: null }, { emitEvent: false });
                this.persanalDetailsFormGroup.get('SuburbId').setValue(selectedAddrSuburb ? selectedAddrSuburb.SuburbId : null);
              }
            }
            else {
              selectedAddrSuburb = this.suburbList.filter(sub => sub.Name.toLowerCase().trim() === this.selectedAddress1.City.toLowerCase().trim()
                && sub.Postcode.Name == this.selectedAddress1.Suburb.Postcode.Code && sub.Country.Id == countryId)
                .map(_item => <Suburb>{
                  SuburbId: _item.Id,
                  Name: _item.Name,
                  Postcode: {
                    Code: this.selectedAddress1.Suburb.Postcode.Code,
                    StateId: _item.State.Id,
                    State: {
                      StateId: _item.State.Id,
                      Name: _item.State.Name
                    }
                  }
                })[0];

              if (selectedAddrSuburb) {
                this.selectedSuburb = selectedAddrSuburb;
                this.suburbs = [];
                this.SuburbCtrl.setValue({ ...selectedAddrSuburb, Postcode: null }, { emitEvent: false });
                this.persanalDetailsFormGroup.get('SuburbId').setValue(selectedAddrSuburb ? selectedAddrSuburb.SuburbId : null);
              }
            }

            selectedAddrCity = this.cityList
              .filter((x) =>
                x.Name.toLowerCase()
                  .trim()
                  .includes(this.selectedAddress1.City.toLowerCase().trim())
              )
              .map(
                (_item) =>
                  <City>{
                    CityId: _item.CityId,
                    Name: _item.Name,
                    IsActive: true,
                    PostCode: {
                      Code: this.selectedAddress1.Suburb.Postcode.Code,
                      StateId: 1,
                      State: {
                        StateId: 1,
                        Name: "NZ",
                      },
                    },
                  }
              )[0];
            if (selectedAddrCity) {
              this.selectedCity = selectedAddrCity;
              this.citySearchResults = [];
              this.CityCtrl.setValue(
                { ...selectedAddrCity, Postcode: null },
                { emitEvent: false }
              );
              postcode = selectedAddrCity.PostCode;
            }
          }
          if (postcode) {
            this.selectedPostcode = { ...postcode, State: null, Region: null };
            this.postcodes = [this.selectedPostcode];
            this.PostCodeCtrl.setValue(this.selectedPostcode, { emitEvent: false });

            state = this.states.find(
              (s) => postcode && s.Name === postcode.State.Name
            );
            if (state) {
              this.selectedState = state;
              this.StateCtrl.setValue(this.selectedState, { emitEvent: false });
            }
          }


          if (selectedAddrCity && selectedAddrSuburb && postcode && state) {
            if (suburbName != '' && suburbName != undefined) {
              if (this.suburbList.filter(sub => sub.Name.toLowerCase().trim() === suburbName.toLowerCase().trim() && sub.Country.Id == countryId).length > 0) {
                const removeFromArray = addrInfoArr.slice(0, (cityIndex - 1));
                const joinFromArray = removeFromArray.join(', ');
                var selectedAddress = <SearchForSuggestionsResult>{
                  Text: joinFromArray
                };
                this.AddressLine1Ctrl.setValue({ ...selectedAddress }, { emitEvent: false });
              } else {
                const removeFromArray = addrInfoArr.slice(0, cityIndex);
                const joinFromArray = removeFromArray.join(', ');
                var selectedAddress = <SearchForSuggestionsResult>{
                  Text: joinFromArray
                };
                this.AddressLine1Ctrl.setValue({ ...selectedAddress }, { emitEvent: false });
              }
            } else {
              const removeFromArray = addrInfoArr.slice(0, cityIndex);
              const joinFromArray = removeFromArray.join(', ');
              var selectedAddress = <SearchForSuggestionsResult>{
                Text: joinFromArray
              };
              this.AddressLine1Ctrl.setValue({ ...selectedAddress }, { emitEvent: false });
            }
          } else {
            this.AddressLine1Ctrl.setValue({ ...selectedAddress }, { emitEvent: false });
          }

        }
      }
    }
  }

  SelectSuburb(suburb: Suburb) {
    this.selectedSuburb = suburb;
    this.suburbs = [];
    this.SuburbCtrl.setValue(
      { ...suburb, Postcode: null },
      { emitEvent: false }
    );
    this.persanalDetailsFormGroup
      .get("SuburbId")
      .setValue(suburb ? suburb.SuburbId : null);
    if (suburb) {
      // Update the postcode, state and country downward.
      const postcode = suburb.Postcode;
      this.selectedPostcode = { ...postcode, State: null, Region: null };
      this.postcodes = [this.selectedPostcode];
      this.PostCodeCtrl.setValue(this.selectedPostcode, { emitEvent: false });

      const state = this.states.find(
        (s) => postcode && s.Name === postcode.State.Name
      );
      if (state) {
        this.selectedState = state;
        this.StateCtrl.setValue(this.selectedState, { emitEvent: false });
      }
    }
  }
  /** @internal */
  SelectCity(city: City) {
    this.citySearchResults = [];
    this.CityCtrl.markAsDirty();
    this.CityCtrl.markAsTouched();
  }

  txtAddress_Blur(e) {
    if (this.AddressLine1Ctrl.dirty) {
      this.AddressLine1Ctrl.setValidators([
        Validators.required,
        FPValidators.resolvableAutocompleteOption,
      ]);
      this.AddressLine1Ctrl.updateValueAndValidity({ emitEvent: false });
    }
  }

  /** @internal */
  txtAddress1_Input(e) {
    if (this.AddressLine1Ctrl.dirty) {
      this.AddressLine1Ctrl.setValidators(Validators.required);
    }
  }

  AutoCompleteCity_DisplayWithFn(option: any) {
    if (option) {
      if (option.Name) {
        const val = option.Name;
        return val;
      } else {
        return option;
      }
    } else {
      return "";
    }
  }

  /** @internal */
  txtCity_Blur(e) {
    if (this.CityCtrl.dirty) {
      this.CityCtrl.setValidators([
        Validators.required,
        FPValidators.resolvableAutocompleteOption,
      ]);
      this.CityCtrl.updateValueAndValidity({ emitEvent: false });
    }
    this.parentFocus = 0;
  }

  /** @internal */
  txtCity_Input(e) {
    if (this.CityCtrl.dirty) {
      if (
        typeof this.CityCtrl.value === "string" &&
        this.CityCtrl.value.length == 0 &&
        this.selectedCityId > 0
      ) {
        this.SelectCity(null);
      }
      this.CityCtrl.setValidators(Validators.required);
    }
  }

  getClassFocused(vlFocused) {
    if (this.parentFocus == vlFocused) {
      return "focused";
    } else {
      return "";
    }
  }

  /** @internal */
  ddlPostcodeOption_CompareFn(
    opt1: SuburbPostcodeStateModel,
    opt2: SuburbPostcodeStateModel
  ) {
    return (
      (!opt1 && !opt2) || (opt1 && opt2 && opt1.PostcodeId === opt2.PostcodeId)
    );
  }

  /** @internal */
  AutoCompleteAddress_DisplayWithFn(option: any) {
    if (!option) {
      return "";
    }
    return option.Text;
  }

  private loadStatesByCountry(countryId: number, firstLoad = false) {
    this.states = CommonConstants.GET_STATES.filter(
      (X) => X.CountryId == countryId
    ).sort((a, b) => a.StateId - b.StateId);
    if (firstLoad) {
      super.OnLoad();
    }
  }

  public PatchValue(value, opts) {
    if (value?.Employer?.State?.StateId == 9) {
      this.isAu = false;
      this.countryCode = CommonString.NewZealandCountryCode;
      this.persanalDetailsFormGroup.controls.txtCountry.setValue(
        CommonString.NEWZEALAND
      );
      this.persanalDetailsFormGroup.controls.txtState.setValue("NZ");
      this.loadStatesByCountry(2, true);
      this.StateCtrl.setValue(this.states[0], { emitEvent: false });
    } else {
      this.isAu = true;
      this.countryCode = CommonString.AustraliaCountryCode;
      this.persanalDetailsFormGroup.controls.txtCountry.setValue(
        CommonString.AUSTRALIA
      );
      this.loadStatesByCountry(1, true);
    }

    this.persanalDetailsFormGroup.controls.txtFirstName.setValue(
      value?.FirstName
    );
    this.persanalDetailsFormGroup.controls.txtLasttName.setValue(
      value?.LastName
    );
    this.persanalDetailsFormGroup.controls.txtGender.setValue(value?.GenderId);
    var dob = value?.DateOfBirth;
    if (dob) {
      this.persanalDetailsFormGroup.controls.psdYear.setValue(
        dob.getFullYear()
      );
      this.persanalDetailsFormGroup.controls.psdMonth.setValue(dob.getMonth());
      this.persanalDetailsFormGroup.controls.psdDay.setValue(dob.getDate());
      this.selectedDate = value?.DateOfBirth;
    }
    this.persanalDetailsFormGroup.controls.txtPEmail.setValue(
      value?.PrimaryEmail
    );
    this.persanalDetailsFormGroup.controls.txtSEmail.setValue(
      value?.SecondaryEmail
    );
    if (value?.Photo?.Location) {
      this.imgUrl = value?.Photo?.Location;
    }

    this.persanalDetailsFormGroup.controls.txtEMName.setValue(
      value?.EmergencyContactName
    );
    this.persanalDetailsFormGroup.controls.txtEMRelationship.setValue(
      value?.EmergencyRelationship
    );
    if (value?.EmergencyContactNo && this.emergencyMobileNumberRegistor !== undefined && this.persanalDetailsFormGroup.get("txtEmergencyMobileNumber").value == '') {
      this.emergencyMobileNumberRegistor.SetValueFromMobileNo(value?.EmergencyContactNo, false);
    } else if (this.emergencyMobileNumberRegistor !== undefined) {
      this.emergencyMobileNumberRegistor.SetDefaultCountryCode(this.isAu);
    }

    if (value?.Address) {
      this.persanalDetailsFormGroup.controls.AddressId.setValue(
        value?.Address?.AddressId
      );

      this.persanalDetailsFormGroup.controls.txtAddressLine1.setValue(
        value.Address.StreetAddress1
      );
      this.persanalDetailsFormGroup.controls.txtAddressLine2.setValue(
        value.Address.StreetAddress2
      );

      var selectedSuburb = <Suburb>{
        SuburbId: value.Suburb.SuburbId,
        Name: value.Suburb.Name,
        Postcode: {
          Code: value.Postcode.Code,
          State: {
            Name: value.State.Name,
          },
        },
      };

      var selectedState = <State>{
        StateId: value.State.StateId,
        CountryId: value?.Country?.CountryId,
      };

      var selectedPostcode = <Postcode>{
        PostcodeId: value?.Suburb?.PostcodeId,
        Code: value?.Postcode?.Code,
      };

      if (value?.City?.CityId) {
        var selectedCity = <City>{
          CityId: value?.City?.CityId,
          Name: value?.City?.Name,
        };

        this.persanalDetailsFormGroup.controls.txtCity.setValue(selectedCity);
      }
      this.persanalDetailsFormGroup.controls.txtSuburb.setValue(selectedSuburb);
      this.persanalDetailsFormGroup.controls.txtState.setValue(selectedState);
      this.postcodes = [selectedPostcode];
      this.persanalDetailsFormGroup.controls.txtPostcode.setValue(
        selectedPostcode
      );
    }

    if (value?.MedicalAnswers) {
      this.medicalQuestions.SetMedicalQuestionAnswers(value?.MedicalAnswers);
    }

    if (value?.SignupStep >= enums.MemberSignupStep.EmployerDetails) {
      this.isFormDisabled = false;
    } else {
      this.isFormDisabled = true;
    }
    this.LoadComplete();
  }
}