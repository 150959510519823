<div [formGroup]="form">
    <ngb-accordion activeIds="panelMbrPkg,panelFacilities" [destroyOnHide]="false">
        <ngb-panel id="panelMbrPkg">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': valid}"></i>
                Membership Packages
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <p class="pb-2">Selecting a package from the option(s) available will update the map
                    and confirm the package selection for your membership.</p>
                <div class="row py-2">
                    <div *ngFor="let memPackage of dataSource" class="col-md-4">
                        <div class="selected-package-detailsSection">
                            <div class="card package-details-wrapper pkg-{{memPackage.MembershipPackageType.Name | lowercase}}"
                                [class.opened]="memPackage.MembershipPackageId == selectedPackage?.MembershipPackageId"
                                [class.selected-item]="memPackage.MembershipPackageId == selectedPackage?.MembershipPackageId"
                                (click)="selectMembershipPackageAndLoadFacility(memPackage)">
                                <div class="togglebtn-package">
                                    <div class="">{{memPackage.MembershipPackageType.Name}}</div>
                                    <div class="package-price-wrapper">
                                        {{Utils.convertPriceFormat(memPackage.SinglePrice)}} / week
                                    </div>
                                </div>
                                <div class="card-body position-relative">
                                    <hr class="d-md-none card-title-border" />
                                    <h2 class="card-title text-center d-none d-md-block">
                                        {{memPackage.MembershipPackageType.Name}}</h2>
                                    <div class="package-ptice-info d-none d-md-block text-md-center">
                                        <div class="package-price-wrapper">
                                            <strong
                                                class="package-price">{{Utils.convertPriceFormat(memPackage.SinglePrice)}}
                                            </strong>/ week
                                        </div>
                                        <div class="package-members">Single Member</div>
                                    </div>
                                    <div class="package-members-price">
                                        <div class="package-family-price">
                                            <div class=" d-flex justify-content-between">
                                                <div class="pck-mbr-info">
                                                    Family
                                                </div>
                                                <div class="pkg-mbr-pre_price">
                                                    <strong
                                                        class="pkg-mbr-price">{{Utils.convertPriceFormat(memPackage.FamilyPrice)}}
                                                    </strong>/
                                                    week
                                                </div>
                                            </div>
                                            <div class="pck-mbr-info small">
                                                (You, your partner and children under 18)
                                            </div>
                                        </div>
                                        <div class="package-family-price">
                                            <div class="d-flex justify-content-between">
                                                <div class="pck-mbr-info">
                                                    Dependent
                                                </div>
                                                <div class="pkg-mbr-pre_price">
                                                    <strong
                                                        class="pkg-mbr-price">{{Utils.convertPriceFormat(memPackage.DependantPrice)}}
                                                    </strong>/
                                                    week
                                                </div>
                                            </div>
                                            <div class="pck-mbr-info small">
                                                (18-24 year olds)
                                            </div>
                                        </div>
                                    </div>
                                    <div class="package-info">
                                        <div class="pkg-info-item">
                                            <i class="fas fa-check"></i>
                                            Access to {{memPackage.TotalFacilityCount}} Facilities
                                        </div>
                                        <div class="pkg-info-item">
                                            {{memPackage.Description==='null' ? "" : memPackage.Description}}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="panelFacilities">
            <ng-template ngbPanelTitle>
                <i class="fas fa-check icon-fp"></i>
                Available Facilities
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-google-map-page-view #googlemap [isAustralia]="isAustralia"></app-google-map-page-view>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>