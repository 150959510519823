import { Component, Injector, ViewChild, ViewEncapsulation, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MemberFormBase } from 'src/app/components/base';
import { DialogResult } from 'src/app/components/common-dialog/common-dialog.component';
import { MemberFamilyMemberInfoComponent } from 'src/app/components/members/shared';
import { CommonMessage, RouterConstant } from 'src/app/constant';
import { Utils } from 'src/app/helpers';
import { DataResult, Member, ResultModel } from 'src/app/models';
import { MessageBox, MessageBoxButton } from 'src/app/services/common-dialog.service';
import { MemberService } from 'src/app/services/member.service';
import { EMemberStatus, EMemberType } from 'src/app/enums';

@Component({
  selector: 'app-member-additional-personal-details',
  templateUrl: './additional-personal-details.component.html',
  styleUrls: ['./additional-personal-details.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MemberFormBase, useExisting: AdditionalMemberPersonalDetailsDialogComponent }]
})
export class AdditionalMemberPersonalDetailsDialogComponent extends MemberFormBase implements AfterViewChecked {

  CommonMessage = CommonMessage;
  timeStamp = new Date().toISOString().replace(/[-:\.]/g, '');
  parentFocus = 0;
  isDependentOver25 = false;
  title: string;

  @ViewChild(MemberFamilyMemberInfoComponent) FamilyMemberInfoSection: MemberFamilyMemberInfoComponent;

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      FamilyMemberInfo: MemberFamilyMemberInfoComponent.getFormGroup()
    });
  }

  constructor(injector: Injector,
    public activeModal: NgbActiveModal,
    private svc: MemberService,
    private router: Router,
    private cdRef: ChangeDetectorRef) {
    super(injector);
    this.async = false;
    this.form = AdditionalMemberPersonalDetailsDialogComponent.getFormGroup();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  OnLoad() {
    if (this.data.model.MemberId > 0) {
      this.title = 'Personal Details';
      this.Invoke(this.svc.getForMember(this.data.model.MemberId),
        {
          onSuccess: (res: DataResult<Member>) => {
            if (res.Success) {
              this.checkDependendOver25(res.Data);
              const primaryMember = this.data.model.PrimaryMember;
              this.data.model = res.Data;
              this.data.model.PrimaryMember = primaryMember; 
              this.PatchValue(this.data.model, { emitEvent: false });
            } else {
              this.HandleResponseError(res);
            }
            super.OnLoad();
          }
        });
    } else {
      this.title = 'Add Family Member Details';
      super.OnLoad();
    }
  }

  //Author: Yen
  checkDependendOver25(data) {
    const age = Utils.calculateAge(data.DateOfBirth);
    if (data.MemberStatus.MemberStatusId == EMemberStatus.Invalid &&
      data.MemberType.MemberTypeId == EMemberType.Dependant &&
      age >= 25) {
      this.isDependentOver25 = true;
    } else {
      this.isDependentOver25 = false;
    }
  }

  //Author: Yen
  LoadComplete() {
    if (this.data.model.MemberId > 0) {
      this.getControl(['FamilyMemberInfo', 'MemberDetails', 'DateOfBirth']).disable({ onlySelf: true, emitEvent: false });
      this.getControl(['FamilyMemberInfo', 'MemberDetails', 'MemberType']).disable({ onlySelf: true, emitEvent: false });

      this.FamilyMemberInfoSection.memberDetails.btnUploadPhoto_Click = (e) => {
        if (this.data.model.MemberStatusId === EMemberStatus.New) {
          return;
        } else {
          e.preventDefault();
          e.stopPropagation();
          
          var changePhotoMessage = '';

          if (this.data.model.IsMobileAppEnabled) {
            changePhotoMessage = 'Photos can be uploaded in the Manage Membership Cards Section. Do you wish to continue?';
          }
          else {
            changePhotoMessage = 'Changing your photo may require creation of a new membership card. Photos can be uploaded in the Manage Membership Cards Section. Do you wish to continue?';
          } 

          MessageBox.ShowYesNo(this.dialog, changePhotoMessage).subscribe(
            (dialogResult) => {
              if (dialogResult.result.toLowerCase() === DialogResult.Yes) {
                this.router.navigate([RouterConstant.NAVIGATOR_MEMBERSHIP_CARDS]);
                this.activeModal.close();
              }
            }
          );
        }
      };
    }
    super.LoadComplete();
  }

  private processDataForSubmission(source: Member) {
    const target = Object.assign({}, source);
    target.MobileNumber = "+" + Utils.convertPhoneToInternationalFormatWithCountryCode(target.MobileNumber);
    target.EmergencyContactNo = "+" + Utils.convertPhoneToInternationalFormatWithCountryCode(target.EmergencyContactNo);
    target.PrimaryMember = null;
    if (target.MemberType) {
      target.MemberTypeId = target.MemberType.MemberTypeId;
      // delete target.MemberType;
    }
    if (target.MemberStatus) {
      target.MemberStatusId = target.MemberStatus.MemberStatusId;
      delete target.MemberStatus;
    }
    if (target.FavouredFacility) {
      target.FavouredFacilityId = target.FavouredFacility.FacilityId;
      delete target.FavouredFacility;
    }
    if (target.ExistingFacility) {
      target.ExistingMemberFacilityId = target.ExistingFacility.FacilityId;
      delete target.ExistingFacility;
    }
    if (target.MedicalAnswers instanceof Array) {
      target.MedicalAnswers.forEach(answer => {
        delete answer.MedicalQuestion;
      });
    }
    for (const key in target) {
      if (target.hasOwnProperty(key) && target[key] == null) {
        delete target[key];
      }
    }
    return target;
  }

  btnNext_Click() {
    console.log ("Addtional Member Data: ", this.data.model);
    if (this.data.model.MemberStatusId == EMemberStatus.CandidateMember){
      MessageBox.ShowCustom(this.dialog, "Information", "Information", "Please review your payment details and press submit to confirm your additional family member request", MessageBoxButton.Ok).subscribe(r => {
        if (r.result == DialogResult.Ok) {
          this.btnSubmit_Click();
          return;
        }
      });
    }
    else {
      this.btnSubmit_Click();
      return;
    }

  }

  //Author: Yen
  btnSubmit_Click() {


    this.Validate();
    if (!this.valid) {
      this.commonSvc.ShowPopupValidator(this.dialog);
      return;
    }

    this.data.model = this.applyValue(this.data.model, this);
    const processedData = this.processDataForSubmission(this.data.model);
    if (this.data.model.MemberId > 0) {
      this.Invoke(
        this.svc.updateForMember(processedData),
        {
          onSuccess: (res: ResultModel) => {
            if (res.Success) {
              this.SaveMemberAddress(this.data.model.MemberId);
            } else {
              this.HandleResponseError(res);
            }
          }
        }
      );
    } else {
      this.activeModal.close({ returnValue: processedData });
    }
  }

  private SaveMemberAddress(memberid: number) {
    this.commonSvc.StopProgressBar();
    this.Invoke(
      this.svc.updateaddressfamilyformember(memberid),
      {
        onSuccess: (res: ResultModel) => {
          if (res.Success) {
            this.activeModal.close({ returnValue: this.data.model });
          } else {
            this.HandleResponseError(res);
          }
        }
      }
    );
  }

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }
  onBlurParent(focusNumbr) {
    this.parentFocus = focusNumbr;
  }
  getClassFocused(vlFocused) {
    if (this.parentFocus == vlFocused) {
      return "focused";
    } else {
      return "";
    }
  }
}