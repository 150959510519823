import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ParentGroup } from 'src/app/models/parent-group.model';
import { ParentGroupService } from 'src/app/services/parent-group.service';
import { MessageBox } from 'src/app/services/common-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonMessage } from 'src/app/constant/common-message';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterConstant } from 'src/app/constant/routerconstant';
import { APIConstant } from 'src/app/constant/apiconstant';
import { startWith, switchMap, catchError } from 'rxjs/operators';
import { empty, merge } from 'rxjs';

@Component({
    selector: 'app-addeditparentgroups',
    templateUrl: './addeditparentgroup.component.html',
    styleUrls: ['./addeditparentgroup.component.css']
})
export class AddeditparentgroupsComponent implements OnInit, OnDestroy {

    parentGroupsFormGroup: UntypedFormGroup;
    btnSubmit = false;
    pgSpinter = false;
    categories: ParentGroup;
    routerConstant = RouterConstant;
    isCreatePG = true;
    editCurrentName = "";

    ngOnDestroy() {
        this.categories = null;
        this.editCurrentName = null;
    }

    constructor(private _formBuilder: UntypedFormBuilder,
        private pgService: ParentGroupService,
        private dialog: MatDialog,
        public router: Router,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        const id: number = +this.route.snapshot.params.id;
        if (typeof (this.route.snapshot.params.id) != "undefined") {
            this.loadParentGroups(id);
            this.isCreatePG = false;
        }
        this.createPGForm();
    }

    createPGForm() {
        this.parentGroupsFormGroup = this._formBuilder.group({
            txtParentGroupName: ['', [
                Validators.required,
                Validators.maxLength(50)
            ]],
            ddlStatus: [true, Validators.required],
        });
    }

    loadParentGroups(id: number) {
        this.pgService.getData(
            APIConstant.API_GET_PARENT_GROUP_BY_ID + id,
            (result) => {
                this.editCurrentName = result.Data.ParentGroupName;
                this.parentGroupsFormGroup.get("txtParentGroupName").setValue(result.Data.ParentGroupName);
            }
        );
    }

    Validate(): boolean {
        if (this.parentGroupsFormGroup.get("txtParentGroupName").valid) {
            return true;
        } else {
            return false;
        }
    }

    async submitParentGroup() {
        // Check the service name isn't empty before trying to submit.
        let newName = this.parentGroupsFormGroup.get("txtParentGroupName").value.trim();

        if (newName.length <= 0) {
            this.parentGroupsFormGroup.get("txtParentGroupName").setErrors({ required: true });
            return;
        }

        // Check the input Product/Service doesn't already exist in another record.
        // If it does, cancel submission and throw up a dialogue box.
        this.pgSpinter = true;
        let checkServiceExistsResult = await this.pgService.getByName(newName).toPromise();
        this.pgSpinter = false;
        if(checkServiceExistsResult.Success ) {
            if(checkServiceExistsResult.Data !== null && newName !== this.editCurrentName) {
                this.parentGroupsFormGroup.get("txtParentGroupName").setErrors({ duplicated: true });
                MessageBox.ShowError(this.dialog, "This Parent Group already exists!");
                return;
            }
            else {
                this.parentGroupsFormGroup.get("txtParentGroupName").setErrors(null);
            }
        }
        else {
            MessageBox.ShowError(this.dialog, "An error has occurred while checking the Parent Group didn't already exist. Please contact the administrator");
            return;
        }

        if (!this.Validate()) { return; }

        // If we have successfully named the service something unique, save it.
        let parentG = new ParentGroup();
        let apiString: string;
        if (this.isCreatePG) {
            parentG.ParentGroupName = this.parentGroupsFormGroup.get("txtParentGroupName").value;
            apiString = APIConstant.API_CREATE_PARENT_GROUP;
        } else {
            parentG.ParentGroupId = +this.route.snapshot.params.id;
            parentG.ParentGroupName = this.parentGroupsFormGroup.get("txtParentGroupName").value;
            apiString = APIConstant.API_EDIT_PARENT_GROUP;
        }
        this.btnSubmit = true;
        this.pgService.postData(
            apiString,
            (postNewParentGroupResult) => {
                if (postNewParentGroupResult.Success) {
                    this.showSaveSuccessDialog();
                } else {
                    MessageBox.ShowError(this.dialog, 'An error has occurred. Please contact the administrator');
                }
            },
            parentG
        );
    }

    private showSaveSuccessDialog() {
        MessageBox.ShowCustom(this.dialog, 'Information', 'Information',
            CommonMessage.DATA_SAVE_SUCCESS_MESSAGE).subscribe(
                (dialogResult) => {
                    if (dialogResult.result.toLowerCase() === 'ok') {
                        this.router.navigate(['/' + RouterConstant.ROUTER_DASHBOARD + '/' + RouterConstant.ROUTER_PARENT_GROUP_VIEW]);
                    }
                }
            );
    }
}
