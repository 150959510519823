import { CommonResponse } from '.';

export class UserCreate {
    UserName: string;
    Password: string;
    TitleId: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    PreferredName: string;
    GenderId: number;
    DateOfBirth: any;
    MobileNumber: string;
    EmailAddress: string;
    IsActive: boolean;
    RoleIds: Array<number>;
    AssignFacilitatorEmployers: AssignFacilitatorEmployer[];
    FacilityIds: Array<number>;
}
export class AssignFacilitatorEmployer {
    EmployerId?: number;
    public Code?: string;
    IsPrimaryFacilitator?: boolean;
}

export const MFAType = {
    SMS: 'SMS',
    EMAIL: 'EMAIL'
} as const;
export type MFATypeValues = (typeof MFAType)[keyof typeof MFAType];

export class User {
    constructor(
        public UserId?: number,
        public UserName?: string,
        public Password?: string,
        public TitleId?: number,
        public FirstName?: string,
        public MiddleName?: string,
        public LastName?: string,
        public PreferredName?: string,
        public GenderId?: number,
        public DateOfBirth?: any,
        public MobileNumber?: string,
        public EmailAddress?: string,
        public DateTimeStamp?: string,
        public IsActive?: boolean,
        public Member?: string,
        public UserInvitation?: string,
        public MFAPreference?: MFATypeValues,
        public Roles: CommonResponse[] = [],
        public AssignFacilitatorEmployerUpdates: AssignFacilitatorEmployerUpdate[] = [],
        public AssignFacilityUpdates: CommonResponse[] = []
    ) {

    }
}

export class AssignFacilitatorEmployerUpdate {
    constructor(
        public EmployerId?: number,
        public Name?: string,
        public Code?: string,
        public IsPrimaryFacilitator?: boolean,
        public CanCheckPrimaryFacilitator?: boolean
    ) { }
}
export class AssignFacilityUpdates {
    constructor(
        public FacilityId?: number,
        public Name?: string
    ) { }
}

export class UserEmployer {
    constructor(
        public EmployerId?: number,
        public Code?: string,
        public Name?: string,
        public PrimaryFacilitatorId?: any
    ) { }
}

export class UserUpdating {
    constructor(
        public UserId?: number,
        public UserName?: string,
        public Password?: string,
        public TitleId?: number,
        public FirstName?: string,
        public MiddleName?: string,
        public LastName?: string,
        public PreferredName?: string,
        public GenderId?: number,
        public DateOfBirth?: string,
        public MobileNumber?: string,
        public EmailAddress?: string,
        public IsActive?: boolean,
        public RoleIds?: Array<number>,
        public AssignFacilitatorEmployers?: Array<AssignFacilitatorEmployer>,
        public FacilityIds?: Array<number>,
        public MFAPreference?: MFATypeValues,
        public IsPending?: boolean
    ) { }
}


