import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MemberFormBase } from '@fp/components/base/member-form-base';
import { EMemberStatus } from '@fp/enums/member-status.enum';
import { EMemberType } from '@fp/enums/member-type.enum';
import { Utils } from '@fp/helpers/utils';
import { Member } from '@fp/models/member.model';
import { MembershipPackage } from '@fp/models/membership-package.model';

@Component({
  selector: 'app-reactivation-package-summary',
  templateUrl: './reactivation-package-summary.component.html',
  styleUrls: ['./reactivation-package-summary.component.css'],
  providers: [{ provide: MemberFormBase, useExisting: ReactivationPackageSummaryComponent }]
})
export class ReactivationPackageSummaryComponent extends MemberFormBase {

  //@Input() allowEditing = false;
  @Input() set dataSource(value) {
    this._dataSource.data = value;
  }
  @Input() membershipPackage: MembershipPackage;

  displayedColumns: string[] = ['PackageName', 'Members', 'Price'];
  _dataSource: MatTableDataSource<Member>;

  constructor(injector: Injector) {
    super(injector);
    this.form = new FormGroup({});
    this._dataSource = new MatTableDataSource();
  }

  isMemberReactivated(memberStatusId, isReactivated) {
    return ((memberStatusId === EMemberStatus.Inactive || memberStatusId === EMemberStatus.New) && isReactivated)
  }

  getPrice(member: Member) {
    let DoB = member.DateOfBirth;
    let dobDate = new Date(DoB);

    let price = 0.00;
    if (this.membershipPackage != null) {
      if (member.MemberIdPrimary == null) {
        try {
          price = this._dataSource.data.find(e =>
            ((e.MemberTypeId === EMemberType.Partner) && this.isMemberReactivated(e.MemberStatusId, e.IsReactivated)) ||
            ((e.MemberTypeId === EMemberType.Dependant) && (Utils.calculateAge(new Date(e.DateOfBirth)) < 18) && this.isMemberReactivated(e.MemberStatusId, e.IsReactivated))
          ) == null ? this.membershipPackage.SinglePrice : this.membershipPackage.FamilyPrice;
        } catch (err) {
          console.error(err);
        }
      } else if (member.MemberTypeId === EMemberType.Dependant &&
        Utils.calculateAge(dobDate) >= 18 &&
        this.isMemberReactivated(member.MemberStatusId, member.IsReactivated)) {
        try {
          price = this.membershipPackage.DependantPrice;
        } catch (err) {
          console.error(err);
        }
      }
    }
    return price;
  }

  getTotalPrice() {
    let total = 0.00;
    const MemberStatusId = this._dataSource.data[0].MemberStatusId;
    const IsReactivated = this._dataSource.data[0].IsReactivated;
    if (!this.isMemberReactivated(MemberStatusId, IsReactivated)) {
      return total;
    }
    this._dataSource.data.forEach((e, i) => {
      total += this.getPrice(e);
    });
    return total;
  }

  PatchValue(value: Member, opts) {
    super.PatchValue(value, opts);
  }
}
