import { StepperSelectionEvent } from "@angular/cdk/stepper";
import {
  AfterViewChecked,
  Component,
  ElementRef,
  Injector,
  isDevMode,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatStepper } from "@angular/material/stepper";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  FPFormBaseComponent,
  MemberFormBase,
  MemberFormSharedData,
} from "src/app/components/base";
import { DialogResult } from "src/app/components/common-dialog/common-dialog.component";
import {
  MemberCancellationComponent,
  MemberEmployerInfoComponent,
  MemberFamilyMembersComponent,
  MemberMembershipPackagesComponent,
  MemberPaymentDetailsComponent,
  MemberPersonalInfoComponent,
  MemberShoppingCartComponent,
} from "src/app/components/members/shared";
import { CommonMessage, RouterConstant, CommonString } from "src/app/constant";
import {
  ECancellationAction,
  EMembershipStatus,
  EMemberStatus,
  EMemberType,
} from "src/app/enums";
import {
  Utils,
  DataSourceHelper,
  CustomMessageBox,
  CustomMessageBoxButton,
  DateHelper,
} from "src/app/helpers";
import {
  CommonResponse,
  DataResult,
  Employer,
  Member,
  Membership,
  MembershipLedgerEntry,
  MemberVisitsViewModel,
  ReadMembershipLedger,
} from "src/app/models";
import { MessageBox, DataService } from "src/app/services";
import { MessageBoxButton } from "src/app/services/common-dialog.service";
import { MemberService } from "src/app/services/member.service";
import { MemberMembershipCardsComponent } from "./membership-cards/membership-cards.component";
import { AuditLogComponent } from "@fp/components/audit-log/audit-log.component";
import { AuditLoggingService } from "@fp/services/audit-logging.service";
import { catchError } from "rxjs/operators";
import {
  MemberVisitsPaymentsComponent,
  PeriodicElement1,
} from "./visits-payments/visits-payments.component";
import { ManuallyAddDebitCreditBoxComponent } from "./visits-payments/manually-add-debit-credit-box/manually-add-debit-credit-box.component";
import { ERole } from "@fp/enums/role.enum";
import { Observable } from "rxjs";
import { AuditLogVM } from "@fp/models/audit-logging.model";
import { RolePermissionService } from "@fp/services/role-permission.service";
import { ValidatorMessageModel } from "@fp/models/validator-message.model";
import { CommonConstants } from "@fp/constant/common-constants";
import { formatDate } from "@angular/common";

enum EMemberEditTab {
  EmployerInfo = 1,
  MembershipPackages = 2,
  PersonalDetails = 0,
  AdditionalMembers = 3,
  ShoppingCart = 4,
  PaymentDetails = 5,
  MembershipCards = 6,
  VisitsPayments = 7,
  AuditLog = 8,
}

@Component({
  selector: "app-member-edit",
  templateUrl: "./memberedit.component.html",
  styleUrls: ["./memberedit.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FPFormBaseComponent.provideExisting(MemberEditComponent),
    MemberFormBase.provideExisting(MemberEditComponent),
  ],
})
export class MemberEditComponent
  extends MemberFormBase
  implements AfterViewChecked
{
  membershipsId: Membership = new Membership();
  dataSourceVisits: MatTableDataSource<MemberVisitsViewModel> =
    new MatTableDataSource<MemberVisitsViewModel>([]);
  dataSource3: MatTableDataSource<MembershipLedgerEntry>;
  public CommonString = CommonString;
  displayedColumnsVisit: string[] = [
    "VisitDateTimeDate",
    "VisitDateTimeTime",
    "Facility",
    "ServiceType",
    "NumberofVisits",
    "VisitSource",
    "VisitType",
    "DuplicateVisit",
    "InvalidVisitReason",
  ];
  displayedColumnsLedger: string[] = [
    "CreatedDate",
    "PostDate",
    "DebitCredit",
    "Amount",
    "DebitCreditReason",
    "Description",
    "PostedBy",
  ];
  ledgerRows: string;
  sharedMessage: string;
  memberNumber: string;
  balance: any;
  currentDate: any;

  @ViewChild("visitsSort") visitsSort: MatSort;
  @ViewChild("membershipLedgerSort") membershipLedgerSort: MatSort;

  private oldHeight = "";
  private stepProgress: boolean[] = [];
  private originalData: Member;
  private checkedLedgerTab: boolean = false;
  public AuditFormGroup: UntypedFormGroup;
  public FilterItems: CommonResponse[] = [];
  nameMember = "";
  primaryMemberId = 0;
  // MemberIdPrimary = null;
  EmployerIdSaved = null;
  CurrentEmployerId = null;
  MembershipId = null;
  MemberMemberships: Membership[];
  isAustralia = true;
  //MembershipPackageSeleted: MembershipPackage;

  //MemberAudit- Using EmployerAuditModel because data is of same type
  memberAuditColumns: string[];
  memberAuditDS: MatTableDataSource<AuditLogVM> =
    new MatTableDataSource<AuditLogVM>([]);
  errorHandler: any;
  isLoading = false;
  hasInit = false;
  heighttabs: string;

  ReadonlyByStatusMode = false; // mode readonly for Employer Info, Membership Packages, Membership Card
  displayedColumns: string[];
  noMemberPaymentsMessage: boolean;
  memberPaymentsTable: boolean;
  statusDeepCloneMem: boolean = true;
  currentDOB: string | Date = new Date();
  @ViewChild("ledgerTable") ledgerTable;

  get MemberIdPrimary() {
    return this.data.model.MemberIdPrimary;
  }

  get PrimaryMember() {
    return this.data.model.PrimaryMember;
  }

  get primaryMemberSharedData() {
    if (this.PrimaryMember == null) {
      return this.data;
    }
    return <MemberFormSharedData>{
      model: this.PrimaryMember,
      requestQueueCount: this.data.requestQueueCount,
    };
  }

  get isEmployerFacilitatorRole() {
    return this.commonSvc.GetSelectedRole() === ERole.Employer_Facilitator;
  }

  MemberEditTab = EMemberEditTab;

  // #region DEBUG
  // TODO: remove after implementation completed.
  get _isDebugging() {
    return isDevMode() && window["enableDebugging"] === true;
  }
  JSON = JSON;
  // #endregion DEBUG

  @ViewChild("stepper", { static: true }) stepper: MatStepper;
  @ViewChild("stepperContainer", { static: true })
  private divStepperContainer: ElementRef;
  @ViewChild("employerInfo", { static: true })
  employerInfo: MemberEmployerInfoComponent;
  @ViewChild("membershipPackages")
  membershipPackages: MemberMembershipPackagesComponent;
  @ViewChild("personalInfo", { static: true })
  personalInfo: MemberPersonalInfoComponent;
  @ViewChild("familyMembers") familyMembers: MemberFamilyMembersComponent;
  @ViewChild("shoppingCart") shoppingCart: MemberShoppingCartComponent;
  @ViewChild("paymentDetails") paymentDetails: MemberPaymentDetailsComponent;
  @ViewChild("membershipCards") membershipCards: MemberMembershipCardsComponent;
  @ViewChild("visitsPayments") visitsPayments: MemberVisitsPaymentsComponent;
  @ViewChild(AuditLogComponent) auditLog: AuditLogComponent;
  @ViewChild("visitpagi") visit_paginator: MatPaginator;
  @ViewChild("ledgerpagi") ledger_paginator: MatPaginator;

  userName = this.commonSvc.GetUser();

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      EmployerInfo: MemberEmployerInfoComponent.getFormGroup(),
      MembershipPackages: MemberMembershipPackagesComponent.getFormGroup(),
      PersonalInfo: MemberPersonalInfoComponent.getFormGroup(),
      FamilyMembersTab: MemberFamilyMembersComponent.getFormGroup(),
      PaymentDetails: MemberPaymentDetailsComponent.getFormGroup(false),
      MembershipCards: MemberMembershipCardsComponent.getFormGroup(),
      VisitsPayments: MemberVisitsPaymentsComponent.getFormGroup(),
      AuditLog: AuditLogComponent.getFormGroup(),
    });
  }

  constructor(
    injector: Injector,
    private memberSvc: MemberService,
    private shareData: DataService,
    private route: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private modal: NgbModal,
    private auditService: AuditLoggingService,
    private rolepermission: RolePermissionService
  ) {
    super(injector);
    this.commonSvc.Header.title = "Member";
    this.form = MemberEditComponent.getFormGroup();
    this.async = false;
    this.commonSvc.IsEditMember = true;
    localStorage.setItem("adminMemberEdit", "true");
  }

  ngAfterViewChecked() {
    if (!this.commonSvc.App.mobileQuery.matches) {
      this.heighttabs =
        this.divStepperContainer.nativeElement.querySelector(
          "#membertb" + this.stepper.selectedIndex
        ).clientHeight +
        50 +
        "px";
    } else {
      this.heighttabs = "100%";
    }

    this.cdRef.detectChanges();

    this.HideTab();
    if (!this.hasInit) {
      this.OnLoad();
      this.hasInit = true;
      this.OnInit();
    }
    //let stepperContainerHeight = "";
    // try {
    //   if (!this.commonSvc.App.mobileQuery.matches) {
    //     if (this.stepper.selected && this.stepper.selected.stepLabel) {
    //       const stepHeaderElem = this.stepper._stepHeader
    //         .toArray()
    //         [this.stepper.selectedIndex]._getHostElement();
    //       const stepLabelElem = <HTMLElement>(
    //         this.stepper.selected.stepLabel.template.elementRef.nativeElement
    //       );
    //       stepperContainerHeight =
    //         (<HTMLElement>(
    //           (stepLabelElem.parentElement || stepLabelElem.parentNode)
    //         )).clientHeight +
    //         stepHeaderElem.clientHeight +
    //         "px";
    //     }
    //   } else {
    //     stepperContainerHeight = "100%";
    //   }
    // } catch (err) {
    //   console.warn(err);
    // }
    // if (this.loaded && this.oldHeight != stepperContainerHeight) {
    //   this.divStepperContainer.nativeElement.style.height = stepperContainerHeight;
    //   this.oldHeight = stepperContainerHeight;
    //   this.changeDetectorRef.markForCheck();
    // }
  }

  // https://jira.csc.com/browse/FP-4596 - [Employer Facilitator] FE change - remove two tabs and a button
  private HideTab() {
    if (this.commonSvc.GetSelectedRole() !== ERole.Employer_Facilitator) {
      return;
    }
    const htmleles = document.querySelectorAll(".mat-vertical-stepper-header");
    for (let i = 0; i < htmleles.length; i++) {
      const ele = <HTMLElement>htmleles[i];
      if (
        ele.innerText.indexOf("Shopping Cart") > -1 ||
        ele.innerText.indexOf("Payment Details") > -1 ||
        ele.innerText.indexOf("Member Visits & Payments") > -1
      ) {
        ele.style.display = "none";
      }
    }
  }
  // End

  ngOnInit() {
    this.AuditFormGroup = this._formBuilder.group({
      ddlFilter: [""],
    });
    this.loadTime(this.FilterItems);
  }

  OnInit() {
    // employer info tab to be disabled for all users/statuses due to not all users being able to employer
    this.employerInfo.form.disable({ emitEvent: false });
    this.employerInfo.StateCtrl.disable({ emitEvent: false });

    this.stepper._stepHeader.forEach((header, index) => {
      header._getHostElement().addEventListener(
        "click",
        (e: MouseEvent) => {
          if (index === EMemberEditTab.AuditLog) {
            this.auditLog.OnLoad();
          }
          if (index === EMemberEditTab.VisitsPayments) {
            if (!this.checkedLedgerTab) {
              this.populateLedgerTable(this.data.model.MemberId);
              this.populateVisitsTable(this.data.model.MemberId);
              this.checkedLedgerTab = true;
            }
          }
          const showMessage = !(
            this.stepper.selectedIndex === EMemberEditTab.MembershipPackages &&
            index === EMemberEditTab.EmployerInfo
          );
          this.membershipPackages.showMessageBox = showMessage;
          if (
            !this.validateStep(this.stepper.selectedIndex, showMessage, index)
          ) {
            if (
              !(
                this.stepper.selectedIndex ===
                  EMemberEditTab.MembershipPackages &&
                index === EMemberEditTab.EmployerInfo
              )
            ) {
              e.preventDefault();
              e.stopImmediatePropagation();
              return false;
            } else {
              this.membershipPackages.loadCompleted = false;
            }
          }
        },
        true
      );
    });
    this.personalInfo.personalDetails.MiddleNameDiv.style.setProperty(
      "display",
      "none"
    );
    this.personalInfo.personalDetails.PhotoUploaderDiv.style.setProperty(
      "display",
      "none"
    );
    this.familyMembers.form.disable({ emitEvent: false });
    this.shoppingCart.form.disable({ emitEvent: false });
    this.shoppingCart.membershipPackageCostSummarySection.form.disable({
      emitEvent: false,
    });
    this.shareData.currentMessage.subscribe(
      (sharedMessage) => (this.sharedMessage = sharedMessage)
    );
    this.dataSourceVisits.paginator = this.visit_paginator;
    super.OnInit();
    this.CheckManualAddDepitCreditPermission();
  }

  private OnGetMemberById(memberId: number, callback: Function) {
    this.Invoke(this.memberSvc.get(memberId), {
      onSuccess: (res: DataResult<Member>) => {
        if (res.Success) {
          callback(res.Data);
        } else {
          this.HandleResponseError(res);
        }
      },
    });
  }

  Invoke1(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError((e) => {
          throw e;
        })
      )
      .subscribe((res) => {
        handleResultCallback(res);
      });
  }

  private populateLedgerTable(memberID) {
    // console.log("Member Number: ", memberNumber);
    this.memberSvc.readMembershipLedger(memberID).subscribe(
      (result: DataResult<MembershipLedgerEntry[]>) => {
        if (result.Data.length !== 0) {
          this.noMemberPaymentsMessage = false;
          this.memberPaymentsTable = true;

          // Convert ledger time to local time
          let resultsWithDatesInLocalTime = result.Data.map((ml) => {
            ml.CreatedDate = this.ConvertToLocalTime(
              new Date(Date.parse(ml.CreatedDate))
            );
            ml.PostDate = this.ConvertToLocalTime(
              new Date(Date.parse(ml.PostDate))
            );
            return ml;
          });

          // Override the old data to display
          result.Data = resultsWithDatesInLocalTime;

          this.currentDate = new Date().toDateString();

          console.log(
            "Current date is:" +
              formatDate(this.currentDate, "yyyy-MM-dd", "en-US")
          );

          for (let i = 0; i < result.Data.length; i++) {
            var newDate = formatDate(
              result.Data[i]["PostDate"],
              "yyyy-MM-dd",
              "en-US"
            );
            if (newDate > formatDate(this.currentDate, "yyyy-MM-dd", "en-US")) {
              this.balance = result.Data[i + 1]["Balance"];
            } else {
              this.balance = result.Data[i]["Balance"]; //The last entry of balance (last element/row of the array)
              i = result.Data.length + 1;
            }
          }

          this.dataSource3 = new MatTableDataSource<MembershipLedgerEntry>(
            result.Data
          );
          this.dataSource3.sortingDataAccessor =
            DataSourceHelper.localeLowerCaseSortingDataAccessor;
          this.dataSource3.sort = this.membershipLedgerSort;
          this.dataSource3.paginator = this.ledger_paginator;
          this.changeDetectorRef.markForCheck();
        } else {
          this.noMemberPaymentsMessage = true;
          this.memberPaymentsTable = false;
        }
      },
      (error) => console.error(error)
    );
  }

  private populateVisitsTable(memberID: any) {
    this.isLoading = true;
    this.memberSvc.getMemberVisits(memberID).subscribe(
      (result: DataResult<MemberVisitsViewModel[]>) => {
        if (result.Data.length !== 0) {
          this.dataSourceVisits = new MatTableDataSource<MemberVisitsViewModel>(
            result.Data
          );
          this.dataSourceVisits.sortingDataAccessor =
            DataSourceHelper.localeLowerCaseSortingDataAccessor;
          this.dataSourceVisits.sort = this.visitsSort;
          this.dataSourceVisits.paginator = this.visit_paginator;
          this.changeDetectorRef.markForCheck();
        }
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      },
      (error) => {
        console.error(error);
        this.isLoading = false;
      }
    );
  }

  openDialogAddDebitCredit() {
    const url_string = this.router.url;

    var array = url_string.split("/");
    const memberID = array[array.length - 1];
    const dialogRef = this.dialog.open(ManuallyAddDebitCreditBoxComponent, {
      height: "500px",
      width: "650px",
      data: memberID,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.populateLedgerTable(this.data.model.MemberId);
    });
  }

  private ConvertToLocalTime(d: Date) {
    const localdate: Date = new Date(d + "Z");
    // Convert UTC Date (d) to Local ISO Format (ie current system time zone)
    return DateHelper.formatUtcToLocalISO(localdate);
  }

  OnLoad() {
    const memberId = this.route.snapshot.params.id;
    //this.stepProgress = Array(this.stepper._steps.length).fill(false);
    // Get Employer Facilator Role
    if (memberId && memberId > 0) {
      this.Invoke(this.memberSvc.get(memberId), {
        onSuccess: (res: DataResult<Member>) => {
          if (res.Success) {
            if (res.Data == null) {
              this.router.navigate([RouterConstant.NAVIGATOR_PAGE_NOT_FOUND]);
            } else {
              this.isAustralia =
                res.Data.Country.Name?.toUpperCase() !==
                CommonConstants.COUNTRY_NEW_ZEALAND;
              this.membershipPackages.isAustralia = this.isAustralia;
              // this.personalInfo.addressContactDetails.originalMobileNumber = Utils.convertPhoneToInternationalFormat(
              //   res.Data.MobileNumber
              // );
              this.personalInfo.addressContactDetails.originalPrimaryEmail =
                res.Data.PrimaryEmail.toLowerCase();

              const data = res.Data;
              this.data.model = res.Data;
              this.nameMember = data.FirstName + " " + data.LastName;
              this.primaryMemberId = data.MemberIdPrimary;
              this.memberNumber = data.MemberNumber;

              // Pass memberNumber via service to other components
              this.shareData.changeMessage(this.memberNumber);

              // Track old Employer and Membership Package
              /*const dataSaved = Utils.deepClone(data);
                            this.EmployerIdSaved = dataSaved.EmployerId;
                            this.CurrentEmployerId = dataSaved.EmployerId;
                            /* this.MemberMemberships = dataSaved.Memberships; */
              this.EmployerIdSaved = Utils.deepClone(data).EmployerId;
              this.CurrentEmployerId = Utils.deepClone(data).EmployerId;
              this.MemberMemberships = Utils.deepClone(data).Memberships;

              // Convert phone to local format
              // this.data.model.MobileNumber = Utils.convertPhoneToLocalFormat(
              //   this.data.model.MobileNumber
              // );

              if (data.MemberIdPrimary && data.MemberIdPrimary > 0) {
                // additional member
                // this.MemberIdPrimary = data.MemberIdPrimary;
                // call get detail by memberIdPrimary
                this.OnGetMemberById(
                  data.MemberIdPrimary,
                  (dataDetail: Member) => {
                    this.data.model.PrimaryMember = dataDetail;
                    this.data.model.SecondaryEmail = dataDetail.SecondaryEmail;
                    this.data.model.Address = dataDetail.Address;
                    this.data.model.AddressId = dataDetail.AddressId;
                    this.data.model.Suburb = dataDetail.Suburb;
                    this.data.model.Postcode = dataDetail.Postcode;
                    this.data.model.State = dataDetail.State;
                    this.data.model.Country = dataDetail.Country;

                    /* const dataDetailSaved = Utils.deepClone(dataDetail);
                                        this.EmployerIdSaved = dataDetailSaved.EmployerId;
                                        this.CurrentEmployerId = dataDetailSaved.EmployerId;
                                        this.MemberMemberships = dataDetailSaved.Memberships; */
                    this.EmployerIdSaved =
                      Utils.deepClone(dataDetail).EmployerId;
                    this.CurrentEmployerId =
                      Utils.deepClone(dataDetail).EmployerId;
                    this.MemberMemberships =
                      Utils.deepClone(dataDetail).Memberships;

                    // Patch current member's info to Personal Info tab
                    this.personalInfo.PatchValue(this.data.model, {
                      emitEvent: false,
                    });
                    // Patch primary member's info to other tabs for displaying purpose
                    this.employerInfo.OnLoad();
                    this.employerInfo.PatchValue(dataDetail, {
                      emitEvent: false,
                    });
                    this.membershipPackages.PatchValue(dataDetail, {
                      emitEvent: false,
                    });
                    this.familyMembers.PatchValue(dataDetail, {
                      emitEvent: false,
                    });
                    this.shoppingCart.PatchValue(dataDetail, {
                      emitEvent: false,
                    });
                    this.paymentDetails.PatchValue(dataDetail, {
                      emitEvent: false,
                    });
                    this.membershipCards.PatchValue(dataDetail, {
                      emitEvent: false,
                    });
                    // this.visitsPayments.PatchValue(dataDetail, {
                    //     emitEvent: false
                    // });

                    // check Membership card readonly or not by member status
                    if (
                      this.data.model.MemberStatusId === EMemberStatus.Active &&
                      dataDetail.Memberships.length > 0 &&
                      dataDetail.Memberships[0].MembershipStatusId ===
                        EMembershipStatus.Active &&
                      !this.isEmployerFacilitatorRole
                    ) {
                      // enable Employer Info, Membership package, Membership Card
                      this.ReadonlyByStatusMode = false;
                    } else {
                      // disable Employer Info, Membership package, Membership Card
                      this.ReadonlyByStatusMode = true;
                    }

                    this.setFormState(this.isEmployerFacilitatorRole, false);
                    this.setFormStateForFamilyMember();

                    // this.PatchValue(this.data.model, { emitEvent: false });
                    this.form.markAsPristine();                   

                    super.OnLoad();
                  }
                );
              } else {
                //this.PatchValue(this.data.model, { emitEvent: false });
                this.employerInfo.OnLoad();
                this.employerInfo.PatchValue(this.data.model, {
                  emitEvent: false,
                });
                this.membershipPackages.PatchValue(this.data.model, {
                  emitEvent: false,
                });
                this.familyMembers.PatchValue(this.data.model, {
                  emitEvent: false,
                });
                this.shoppingCart.PatchValue(this.data.model, {
                  emitEvent: false,
                });
                this.paymentDetails.PatchValue(this.data.model, {
                  emitEvent: false,
                });
                this.membershipCards.PatchValue(this.data.model, {
                  emitEvent: false,
                });

                // check member status and membership status
                if (
                  this.data.model.MemberStatusId === EMemberStatus.Active &&
                  this.data.model.Memberships.length > 0 &&
                  this.data.model.Memberships[0].MembershipStatusId ===
                    EMembershipStatus.Active &&
                  !this.isEmployerFacilitatorRole
                ) {
                  // enable Employer Info, Membership package, Membership Card
                  this.ReadonlyByStatusMode = false;
                } else {
                  // disable Employer Info, Membership package, Membership Card
                  this.ReadonlyByStatusMode = true;
                }
                this.setFormState(this.isEmployerFacilitatorRole, true);
                this.form.markAsPristine();
                super.OnLoad();
              }
              this.commonSvc.AddChangeListenerInput();
            }
          } else {
            this.HandleResponseError(res);
            super.OnLoad();
          }
        },
      });
    } else {
      this.router.navigate([RouterConstant.NAVIGATOR_PAGE_NOT_FOUND]);
    }
  }

  ngOnDestroy() {
    localStorage.removeItem("adminMemberEdit");
  }

  LoadComplete() {
    setTimeout(() => {
      // Override the 'selectMembershipPackage'
      // method to prevent family member from changing membership package.
      // membership package
      const selectMembershipPackageFn =
        this.membershipPackages.selectMembershipPackage;
      this.membershipPackages.selectMembershipPackage = (
        membershipPackage,
        callback
      ) => {
        if (this.data.model.MemberIdPrimary > 0) {
          return;
        }
        if (
          this.data.model.Memberships.length > 0 &&
          this.originalData.EmployerId === this.data.model.EmployerId
        ) {
          if (
            !(
              this.data.model.MemberStatusId === EMemberStatus.Active &&
              this.data.model.Memberships.length > 0 &&
              this.data.model.Memberships[0].MembershipStatusId ===
                EMembershipStatus.Active &&
              !this.isEmployerFacilitatorRole
            )
          ) {
            return;
          }
        }
        selectMembershipPackageFn.apply(this.membershipPackages, [
          membershipPackage,
          callback,
        ]);
      };

      this.stepper.selectionChange.subscribe((e: StepperSelectionEvent) => {
        const index = e.selectedIndex;
        e.previouslySelectedStep.completed = true;
        this.stepProgress[e.previouslySelectedIndex] =
          e.previouslySelectedStep.completed;
        this.applyStepValue(e.previouslySelectedIndex);
        if (this.data.model.MemberIdPrimary && this.data.model.PrimaryMember) {
          const dataDetail = this.data.model.PrimaryMember;
          // Patch current member's info to Personal Info tab
          this.personalInfo.PatchValue(this.data.model, {
            emitEvent: false,
          });
          // Patch primary member's info to other tabs for displaying purpose
          this.employerInfo.PatchValue(dataDetail, {
            emitEvent: false,
          });
          this.membershipPackages.PatchValue(dataDetail, {
            emitEvent: false,
          });
          this.familyMembers.PatchValue(dataDetail, {
            emitEvent: false,
          });
          this.shoppingCart.PatchValue(dataDetail, {
            emitEvent: false,
          });
          this.paymentDetails.PatchValue(dataDetail, {
            emitEvent: false,
          });
          this.membershipCards.PatchValue(dataDetail, {
            emitEvent: false,
          });
          // this.visitsPayments.PatchValue(dataDetail, {
          //     emitEvent: false
          // });
        } else {
          // primary member
          this.PatchValue(this.data.model, { emitEvent: false });
        }
        const com = this.children.toArray()[index];
        if (com) {
          if (!com.loaded) {
            com.Load();
          } else {
          }
        }
        switch (index) {
          case EMemberEditTab.AdditionalMembers:
            this.familyMembers.RefreshDataSource();
            break;
          case EMemberEditTab.MembershipPackages:
            if (this.membershipPackages.loaded) {
              // selected again membership package
              if (
                this.EmployerIdSaved ===
                  this.primaryMemberSharedData.model.EmployerId &&
                this.data.model.Memberships.length > 0
              ) {
                this.membershipPackages.selectMembershipPackage(
                  this.data.model.Memberships[0].MembershipPackage
                );
              }
              // Refresh membership packages with new Employer ID
              this.membershipPackages.RefreshMembershipPackages(false);
            }
            break;
        }
      });

      this.SelectedMembershipPackage();

      this.personalInfo.personalDetails
        .getControl("MemberStatusId")
        .valueChanges.subscribe((value: EMemberStatus) => {
          if (this.originalData.MemberStatusId !== value) {
            if (
              value === EMemberStatus.Inactive ||
              value === EMemberStatus.Banned
            ) {
              const modalRef = this.modal.open(MemberCancellationComponent, {
                backdrop: "static",
              });
              const component = <MemberCancellationComponent>(
                modalRef.componentInstance
              );
              component.memberId = this.data.model.MemberId;
              component.allowMembershipSuspension = false;
              component.defaultAction =
                value === EMemberStatus.Banned
                  ? ECancellationAction.BanMember
                  : ECancellationAction.CancelMembership;
              modalRef.result.then(
                (result) => {
                  //this.UnLoad();
                  this.OnLoad();
                  this.children.toArray()[this.stepper.selectedIndex].Load();
                },
                (reason) => {
                  // Revert to previous Status
                  setTimeout(() => {
                    this.personalInfo.personalDetails
                      .getControl("MemberStatusId")
                      .setValue(this.data.model.MemberStatusId, {
                        emitEvent: false,
                      });
                  });
                }
              );
            } else if (value === EMemberStatus.Active) {
              if (this.data.model.MemberTypeId === EMemberType.Partner) {
                let existePartner = false;
                this.data.model.PrimaryMember.FamilyMembers.forEach((fam) => {
                  if (
                    fam.MemberTypeId === EMemberType.Partner &&
                    (fam.MemberStatusId === EMemberStatus.Active ||
                      fam.MemberStatusId === EMemberStatus.Changed ||
                      fam.MemberStatusId === EMemberStatus.New)
                  ) {
                    existePartner = true;
                  }
                });
                if (existePartner) {
                  MessageBox.ShowCustom(
                    this.dialog,
                    "Warning",
                    "Warning",
                    "It has already an active partner, so you can't active the partner",
                    MessageBoxButton.Ok
                  ).subscribe((r) => {
                    // Revert to previous Status
                    setTimeout(() => {
                      this.personalInfo.personalDetails
                        .getControl("MemberStatusId")
                        .setValue(this.data.model.MemberStatusId, {
                          emitEvent: false,
                        });
                    });
                  });
                }
              }

              if (
                this.data.model.MemberIdPrimary > 0 &&
                this.PrimaryMember &&
                this.PrimaryMember.MemberStatusId !== EMemberStatus.Active
              ) {
                MessageBox.ShowCustom(
                  this.dialog,
                  "Warning",
                  "Warning",
                  "A partner/dependant member cannot be made active unless the primary member is active. To continue, please activate the primary member first.",
                  MessageBoxButton.Ok
                ).subscribe((r) => {
                  // Revert to previous Status
                  setTimeout(() => {
                    this.personalInfo.personalDetails
                      .getControl("MemberStatusId")
                      .setValue(this.data.model.MemberStatusId, {
                        emitEvent: false,
                      });
                  });
                });
              }
            }
          }
        });

      this.personalInfo.personalDetails
        .getControl("DateOfBirth")
        .valueChanges.subscribe((value: Date) => {
          this.personalInfo.addressContactDetails
            .getControl("MobileNumber")
            .updateValueAndValidity({ emitEvent: false });
          this.personalInfo.addressContactDetails
            .getControl("PrimaryEmail")
            .updateValueAndValidity({ emitEvent: false });
          if (
            this.personalInfo.personalDetails.getControl("DateOfBirth").invalid
          ) {
            return;
          }

          this.personalInfo.addressContactDetails.mobileNumberRegistor.primarySelectDate =
            value;
          this.personalInfo.addressContactDetails
            .getControl("MobileNumber")
            .updateValueAndValidity({ emitEvent: false });
          if (
            Utils.calculateAge(value) >= 18 ||
            this.form.get("PersonalInfo").value.PersonalDetails.MemberTypeId ==
              EMemberType.Partner
          ) {
            this.personalInfo.addressContactDetails.requireEmailValidation =
              true;
            this.personalInfo.addressContactDetails.ValidatePrimaryEmail();
            var mobile =
              this.personalInfo.addressContactDetails.getControl(
                "MobileNumber"
              ).value;
            if (mobile) {
              this.personalInfo.addressContactDetails.mobileNumberRegistor.ValidateMobileNumber(
                Utils.convertPhoneToInternationalFormatWithoutCountryCode(
                  mobile
                )
              );
            }
          } else {
            let dataModelEmail;
            if (this.data.model && this.data.model.PrimaryMember != null) {
              dataModelEmail =
                this.data.model &&
                this.data.model.PrimaryMember &&
                this.data.model.PrimaryMember.PrimaryEmail;
            } else {
              dataModelEmail = this.data.model && this.data.model.PrimaryEmail;
            }

            if (
              dataModelEmail.toLowerCase() ==
              this.form
                .get("PersonalInfo")
                .value.AddressContactDetails.PrimaryEmail.toLowerCase()
            ) {
              this.personalInfo.addressContactDetails.requireEmailValidation =
                false;
              this.personalInfo.addressContactDetails.ValidatePrimaryEmail();
            } else {
              this.personalInfo.addressContactDetails.requireEmailValidation =
                true;
              this.personalInfo.addressContactDetails.ValidatePrimaryEmail();
            }

            var mobile =
              this.personalInfo.addressContactDetails.getControl(
                "MobileNumber"
              ).value;
            if (mobile) {
              this.personalInfo.addressContactDetails.mobileNumberRegistor.ValidateMobileNumber(
                Utils.convertPhoneToInternationalFormatWithoutCountryCode(
                  mobile
                )
              );
            }
          }

          this.personalInfo.preferredFacilities.SetPrefferedFacilityValidator(
            this.data.model.MemberTypeId,
            value
          );
        });

      this.personalInfo.addressContactDetails
        .getControl("PrimaryEmail")
        .valueChanges.subscribe((value) => {
          if (
            Utils.calculateAge(
              this.form.get("PersonalInfo").value.PersonalDetails.DateOfBirth
            ) >= 18 ||
            this.form.get("PersonalInfo").value.PersonalDetails.MemberTypeId ==
              EMemberType.Partner
          ) {
            this.personalInfo.addressContactDetails.requireEmailValidation =
              true;
          } else {
            let dataModelEmail;
            if (this.data.model && this.data.model.PrimaryMember != null) {
              dataModelEmail =
                this.data.model &&
                this.data.model.PrimaryMember &&
                this.data.model.PrimaryMember.PrimaryEmail;
            } else {
              dataModelEmail = this.data.model && this.data.model.PrimaryEmail;
            }

            if (dataModelEmail.toLowerCase() == value) {
              this.personalInfo.addressContactDetails.requireEmailValidation =
                false;
              this.personalInfo.addressContactDetails.ValidatePrimaryEmail();
            } else {
              this.personalInfo.addressContactDetails.requireEmailValidation =
                true;
              this.personalInfo.addressContactDetails.ValidatePrimaryEmail();
            }
          }
        });
    });

    super.LoadComplete();

    if (this.data.model.MemberIdPrimary && this.data.model.PrimaryMember) {
      this.personalInfo.PatchValue(this.data.model, {
        emitEvent: false,
      });
    } else {
      this.PatchValue(this.data.model, { emitEvent: false });
    }
    this.personalInfo.Load();
    this.changeDetectorRef.detectChanges();
  }

  private loadTime(tempfilter: CommonResponse[]) {
    let currentYear = new Date().getFullYear();
    let yearIndex = 0;

    //Default value in dropdown will be the current year
    for (let i = 0; i < 10; i++) {
      let temp = new CommonResponse();
      let year: number = currentYear - i;
      temp.Id = yearIndex;
      temp.Name = year.toString();
      tempfilter.push(temp);
      yearIndex++;
    }
  }

  private month_Year_Change() {
    const month = this.AuditFormGroup.get("ddlFilter").value;
    let yearID = this.FilterItems.find((x) => x.Name == month);
    if (yearID == null) {
      return;
    }

    let date: Date = new Date();
    date.setFullYear(date.getFullYear() - yearID.Id);

    this.isLoading = true;

    this.InvokeBatch(this.buildVisitFetch(date.getFullYear()), {
      onSuccess: (res) => {
        let results: MemberVisitsViewModel[] = [];
        res.forEach((element) => {
          results = results.concat(element.Data);
        });
        if (results.length !== 0) {
          this.dataSourceVisits = new MatTableDataSource<MemberVisitsViewModel>(
            results
          );
          this.dataSourceVisits.sortingDataAccessor =
            DataSourceHelper.localeLowerCaseSortingDataAccessor;
          this.dataSourceVisits.sort = this.visitsSort;
          this.dataSourceVisits.paginator = this.visit_paginator;
          this.changeDetectorRef.markForCheck();
        }
        this.isLoading = false;
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  private buildVisitFetch(year: number) {
    let logList: Observable<any>[] = [];

    for (var i = 0; i < 12; i++) {
      let getDate: Date = new Date(year, 0 + i, 1, 0, 0, 0, 0);
      logList.push(
        this.memberSvc.getMemberVisitsFilter(
          this.data.model.MemberId.toString(),
          getDate
        )
      );
    }
    return logList;
  }

  public stepClick(event) {
    if (this.commonSvc.FormDataChange === true) {
      const previousindex = event.previouslySelectedIndex;
      this.commonSvc.FormDataChange = false;
      MessageBox.ShowYesNoCancel(
        this.dialog,
        "You have made changes, would you like to save ?"
      ).subscribe((ret) => {
        if (ret.result.toLowerCase() === DialogResult.Yes) {
          this.btnSave_Click();
        } else if (ret.result.toLowerCase() === DialogResult.No) {
          return;
        } else {
          this.stepper.selectedIndex = previousindex;
        }
      });
    }

    let index = event.selectedIndex;
    if (index === EMemberEditTab.AuditLog) {
      this.auditLog.OnLoad();
    }
    if (index === EMemberEditTab.VisitsPayments) {
      if (!this.checkedLedgerTab) {
        this.populateLedgerTable(this.data.model.MemberId);
        this.populateVisitsTable(this.data.model.MemberId);
        this.checkedLedgerTab = true;
      }
    }
    if (index === EMemberEditTab.EmployerInfo) {
      this.employerInfo.LoadLocations();
    }
  }

  _stepProgressBackgroundVisible(index: number) {
    return this.stepProgress[index] || this.stepper.selectedIndex === index;
  }

  _stepProgressCompleteIconVisible(index: number) {
    return this.stepProgress[index] && this.stepper.selectedIndex !== index;
  }

  _saveButtonVisible() {
    return this.MemberIdPrimary != null
      ? this.stepper.selectedIndex === EMemberEditTab.PersonalDetails
      : this.stepper.selectedIndex === EMemberEditTab.EmployerInfo ||
          this.stepper.selectedIndex === EMemberEditTab.MembershipPackages ||
          this.stepper.selectedIndex === EMemberEditTab.PersonalDetails ||
          this.stepper.selectedIndex === EMemberEditTab.PaymentDetails ||
          this.stepper.selectedIndex === EMemberEditTab.VisitsPayments;
  }

  _membershipCardDialogClose(submitted) {
    if (submitted) {
      this.membershipCards.OnLoad();
      this.OnLoad();
    }
  }

  private setFormState(isEmployerFacilitatorRole, isPrimaryMember) {
    // Set original data
    this.originalData = Utils.deepClone(this.data.model);
    const userName = this.commonSvc.GetUser();
    this.originalData.ModifiedBy = userName;
    if (this.originalData.Memberships[0]) {
      this.membershipPackages.MembershipStatusId =
        this.originalData.Memberships[0].MembershipStatusId;
    }
    // Disable the Status control if current status is New, Changed or Invalid
    if (
      [EMemberStatus.New, EMemberStatus.Changed].indexOf(
        this.data.model.MemberStatusId
      ) > -1
    ) {
      this.personalInfo.personalDetails
        .getControl("MemberStatusId")
        .disable({ emitEvent: false });
    }

    // Toggle phone and email validation for dependant
    if (Utils.calculateAge(<Date>this.data.model.DateOfBirth) >= 18) {
      this.personalInfo.addressContactDetails.requireEmailValidation = true;
    } else {
      this.personalInfo.addressContactDetails.requireEmailValidation = true;;
      let dataModelEmail;
      if (this.data.model && this.data.model.PrimaryMember != null) {
        dataModelEmail =
          this.data.model &&
          this.data.model.PrimaryMember &&
          this.data.model.PrimaryMember.PrimaryEmail;
      } else {
        dataModelEmail = this.data.model && this.data.model.PrimaryEmail;
      }

      this.personalInfo.addressContactDetails
        .getControl("PrimaryEmail")
        .valueChanges.subscribe((value) => {
          if (dataModelEmail.toLowerCase() == value.toLowerCase()) {
            this.personalInfo.addressContactDetails.requireEmailValidation =
              false;
          } else {
            this.personalInfo.addressContactDetails.requireEmailValidation =
              true;
          }
        });
    }

    // Enable modification of employer details for primary members
    if (isPrimaryMember) {
      this.employerInfo
        .getControl("EmployeePayrollNo")
        .enable({ emitEvent: false });
    }

    if (isEmployerFacilitatorRole) {
      this.personalInfo.personalDetails
        .getControl("MemberStatusId")
        .disable({ emitEvent: false });
      this.personalInfo.personalDetails
        .getControl("DateOfBirth")
        .disable({ emitEvent: false });
      this.personalInfo.medicalQuestions.form.disable({ emitEvent: false });
      this.personalInfo.preferredFacilities.form.disable({ emitEvent: false });

      this.paymentDetails.form.disable({ emitEvent: false });
    }
  }

  private setFormStateForFamilyMember() {
    // Disable Address section in Personal Info tab
    const addressDetailsSection = this.personalInfo.addressContactDetails;
    addressDetailsSection.form.disable({ emitEvent: false });
    addressDetailsSection.SuburbCtrl.disable({ emitEvent: false });
    addressDetailsSection.PostcodeCtrl.disable({ emitEvent: false });
    addressDetailsSection
      .getControl("MobileNumber")
      .enable({ emitEvent: false });
    addressDetailsSection
      .getControl("PrimaryEmail")
      .enable({ emitEvent: false });

    // Disable Payment Details tab
    this.paymentDetails.form.disable({ emitEvent: false });
  }

  applyStepValue(index: number) {
    switch (index) {
      case EMemberEditTab.EmployerInfo:
        if (this.MemberIdPrimary === null) {
          this.data.model = this.applyValue(this.data.model, this.employerInfo);
          if (this.originalData.EmployerId === this.data.model.EmployerId) {
            this.data.model.Memberships[0] = this.originalData.Memberships[0];
          }
        }
        break;
      case EMemberEditTab.MembershipPackages:
        if (this.MemberIdPrimary === null) {
          this.data.model = this.applyValue(
            this.data.model,
            this.membershipPackages
          );
        }
        break;
      case EMemberEditTab.PersonalDetails:
        this.data.model = this.applyValue(this.data.model, this.personalInfo);
        if (this.MemberIdPrimary > 0 && this.PrimaryMember) {
          // Update current member details to master Family Members list to reflects
          // changes on Additional Members, Shopping Cart and Payment Details tab.
          if (
            (<Date>this.originalData.DateOfBirth).getTime() !=
            (<Date>this.data.model.DateOfBirth).getTime()
          ) {
            if (
              (<Date>this.data.model.DateOfBirth).getTime() !=
              (<Date>this.currentDOB).getTime()
            ) {
              const currentFamMemberIndex =
                this.PrimaryMember.FamilyMembers.findIndex(
                  (_member) => _member.MemberId === this.data.model.MemberId
                );
              if (currentFamMemberIndex > -1) {
                this.PrimaryMember.FamilyMembers[currentFamMemberIndex] =
                  Utils.deepClone(this.data.model);
              }
              this.currentDOB = this.data.model.DateOfBirth;
            }
          }
          const currentFamMemberIndex =
            this.PrimaryMember.FamilyMembers.findIndex(
              (_member) => _member.MemberId === this.data.model.MemberId
            );
          if (
            this.originalData.MemberStatusId !== this.data.model.MemberStatusId
          ) {
            if (currentFamMemberIndex > -1) {
              this.PrimaryMember.FamilyMembers[
                currentFamMemberIndex
              ].MemberStatusId = this.data.model.MemberStatusId;
            }
          } else {
            if (currentFamMemberIndex > -1) {
              this.PrimaryMember.FamilyMembers[
                currentFamMemberIndex
              ].MemberStatusId = this.originalData.MemberStatusId;
            }
          }
        }
        break;
      case EMemberEditTab.PaymentDetails:
        if (this.MemberIdPrimary === null) {
          this.data.model = this.applyValue(
            this.data.model,
            this.paymentDetails
          );
        }
        break;
    }
  }

  validateStep(index, showMessageBox: boolean, selectingIndex?: number) {
    let valid = false;
    switch (index) {
      case EMemberEditTab.EmployerInfo:
        this.employerInfo.Validate();
        valid = this.employerInfo.valid;
        if (showMessageBox && valid) {
          const employerValueCtrl = <Employer>(
            this.getControl(["EmployerInfo", "Employer"]).value
          );
          if (
            this.CurrentEmployerId !== employerValueCtrl.EmployerId &&
            (selectingIndex === EMemberEditTab.MembershipPackages ||
              selectingIndex === undefined)
          ) {
            valid = false;
            MessageBox.ShowCustom(
              this.dialog,
              "Warning",
              "Warning",
              "You have prompted a transfer to a new employer. \
                            Please proceed to the Membership Packages tab and select a new package as per your new employer. \
                            Please note that the available facilities may change depending on your package. \
                            Do you wish to continue?",
              MessageBoxButton.YesNo
            ).subscribe((r) => {
              if (r.result === DialogResult.No) {
              } else {
                this.stepper.next();
              }
            });
          }
        }
        break;
      case EMemberEditTab.MembershipPackages:
        this.membershipPackages.Validate();
        valid = this.membershipPackages.valid;
        if (!valid && showMessageBox) {
          MessageBox.ShowCustom(
            this.dialog,
            "Warning",
            "Warning",
            "Please select the membership package default."
          );
        }
        break;
      case EMemberEditTab.PersonalDetails:
        this.personalInfo.Validate();
        valid = this.personalInfo.valid;
        break;
      case EMemberEditTab.AdditionalMembers:
      case EMemberEditTab.ShoppingCart:
        // No need to validate these 2 tabs because the FamilyMembers list is readonly.
        valid = true;
        break;
      case EMemberEditTab.PaymentDetails:
        this.paymentDetails.Validate();
        valid = this.paymentDetails.valid;
        break;
      case EMemberEditTab.MembershipCards:
        valid = true;
        break;
      case EMemberEditTab.VisitsPayments:
        valid = true;
        break;
      case EMemberEditTab.AuditLog:
        valid = true;
        break;
    }
    return valid;
  }

  checkHiddenTab(index: number): boolean {
    let valid = false;
    switch (index) {
      case EMemberEditTab.ShoppingCart:
        valid = true;
        break;
      case EMemberEditTab.PaymentDetails:
        valid = true;
        break;
      case EMemberEditTab.VisitsPayments:
        valid = true;
        break;
    }
    return valid;
  }

  checkNextTab() {
    switch (this.stepper.selectedIndex) {
      case EMemberEditTab.AuditLog:
        this.auditLog.OnLoad();
        break;
      case EMemberEditTab.VisitsPayments:
        if (!this.checkedLedgerTab) {
          this.populateLedgerTable(this.data.model.MemberId);
          this.populateVisitsTable(this.data.model.MemberId);
          this.checkedLedgerTab = true;
        }
        break;
    }
  }

  btnNext_Click() {
    const stepValid = this.validateStep(this.stepper.selectedIndex, true);
    if (!stepValid) {
      this.commonSvc.ShowPopupValidator(this.dialog);
      this.checkNextTab();
      return;
    }
    if (this.stepper.selectedIndex < this.stepper._steps.length - 1) {
      if (this.commonSvc.GetSelectedRole() !== ERole.Employer_Facilitator) {
        this.stepper.next();
      } else {
        if (this.checkHiddenTab(this.stepper.selectedIndex + 1)) {
          for (
            var i = this.stepper.selectedIndex + 2;
            i < this.stepper._steps.length;
            i++
          ) {
            if (!this.checkHiddenTab(i)) {
              this.stepper.selectedIndex = i;
              this.checkNextTab();
              return;
            }
          }
          console.log(
            "Notice: Next button present, but no more valid tabs for an Employer Facilitator to navigate to."
          );
        } else {
          this.stepper.next();
        }
      }
    } else {
      this.applyStepValue(this.stepper.selectedIndex);
    }
    this.checkNextTab();
  }

  private processDataForSubmission(source: Member) {
    const target = Object.assign({}, source);

    var mobileNo = this.data.model.MobileNumber.replace(/^\++/g, '');
    var emergeNo = this.data.model.EmergencyContactNo.replace(/^\++/g, '');
    target.MobileNumber =
      "+" +
      Utils.convertPhoneToInternationalFormatWithCountryCode(
        mobileNo
      );
    target.EmergencyContactNo =
      "+" +
      Utils.convertPhoneToInternationalFormatWithCountryCode(
        emergeNo
      );
    if (
      target.Address &&
      target.Address.StreetAddress &&
      target.Address.StreetAddress.Text
    ) {
      target.Address.StreetAddress1 = target.Address.StreetAddress.Text;
    } else if (
      target.Address &&
      target.Address.StreetAddress &&
      !target.Address.StreetAddress.Text
    ) {
      target.Address.StreetAddress1 = String(target.Address.StreetAddress);
    }

    if (target.Employer) {
      target.EmployerId = target.Employer.EmployerId;
      delete target.Employer;
    }
    if (target.MemberType) {
      target.MemberTypeId = target.MemberType.MemberTypeId;
      delete target.MemberType;
    }
    if (target.MemberStatus) {
      //target.MemberStatusId = target.MemberStatus.MemberStatusId;
      delete target.MemberStatus;
    }
    if (target.FavouredFacility) {
      target.FavouredFacilityId = target.FavouredFacility.FacilityId;
      delete target.FavouredFacility;
    }
    if (target.ExistingFacility) {
      target.ExistingMemberFacilityId = target.ExistingFacility.FacilityId;
      delete target.ExistingFacility;
    }
    if (target.MedicalAnswers instanceof Array) {
      target.MedicalAnswers.forEach((answer) => {
        delete answer.MedicalQuestion;
      });
    }
    if (target.FamilyMembers) {
      delete target.FamilyMembers;
    }
    if (target.MemberIdPrimary > 0) {
      target.EmployerId = null;
      target.EmployeePayrollNo = null;
      target.Memberships = null;
      target.Address = null;
      target.AddressId = null;
      target.BankAccount = null;
      target.BankAccountId = null;
    }
    if (target.PrimaryMember) {
      delete target.PrimaryMember;
    }
    if (target.UserId === 0) {
      target.UserId = null;
    }
    delete target.Suburb;
    delete target.Postcode;
    delete target.State;
    delete target.Country;
    for (const key in target) {
      if (target.hasOwnProperty(key) && target[key] == null) {
        delete target[key];
      }
    }
    return target;
  }

  btnSave_Click() {
    this.Validate();
    let valid = this.validateStep(this.stepper.selectedIndex, true); // validate current step
    if (
      !valid ||
      this.personalInfo.addressContactDetails.isEmailDuplicated ||
      this.personalInfo.addressContactDetails.isMPDuplicated
    ) {
      // TODO: remove this code block after implementation complete.
      if (this._isDebugging) {
        const invalidCtrls = this._findInvalidControls(this.form);
        this._logger.debug("Invalid controls:\n" + invalidCtrls.join("\n"));
      }
      console.log("Invalid controls: ", this._findInvalidControls(this.form));
      return;
    }
    this.applyStepValue(this.stepper.selectedIndex);
    if (!this.MemberIdPrimary && this.data.model.Memberships.length > 0) {
      // check membership package selected
      const membershipPackage =
        this.data.model.Memberships[0].MembershipPackage;
      const totalPriceSelect =
        membershipPackage.DependantPrice +
        membershipPackage.FamilyPrice +
        membershipPackage.SinglePrice;
      // valid = this.CheckMembershipPackageSelectedValid(totalPriceSelect);
      if (!valid) {
        console.log("Invalid controls: ", this._findInvalidControls(this.form));
        return;
      }
    }

    // update Membership
    if (
      !this.MemberIdPrimary &&
      this.MemberMemberships.length > 0 &&
      this.MemberMemberships[0]
    ) {
      if (
        this.EmployerIdSaved === this.data.model.EmployerId &&
        this.data.model.Memberships &&
        this.data.model.Memberships.length > 0 &&
        this.data.model.Memberships[0]
      ) {
        this.data.model.Memberships[0].Id = this.MemberMemberships[0].Id;
      } else {
        this.data.model.Memberships[0].Id = 0;
      }
    }

    this.data.model.ModifiedBy = this.userName;
    this.processDataForSubmission(this.data.model);

    const processedData = this.processDataForSubmission(this.data.model);

    this._logger.debug(processedData);

    this.Invoke(this.memberSvc.update(processedData), {
      onSuccess: (res: DataResult<Member>) => {
        if (res.Success) {
          if (this.commonSvc.MemberNoteComponent !== null) {
            if (this.commonSvc.MemberNoteComponent.IsExternalUser()) {
              MessageBox.ShowInfo(
                this.dialog,
                CommonMessage.DATA_SAVE_SUCCESS_MESSAGE
              ).subscribe((data) => {
                this.personalInfo.addressContactDetails.isEmailDuplicated =
                  false;
                this.personalInfo.addressContactDetails.isMPDuplicated = false;
                this.membershipCards.OnLoad();
                this.OnLoad();
              });
            } else {
              this.SaveMemberNote();
            }
          }
          this.SaveMemberAddress(this.data.model.MemberId);

          //Update Audit Log component
          this.auditLog.auditSearch();
        } else {
          this.HandleResponseError(res);
        }
      },
    });
  }

  private SaveMemberAddress(memberid: number) {
    this.commonSvc.StopProgressBar();
    this.commonSvc.StopGlobalProgressBar();
    this.Invoke(this.memberSvc.updateaddressfamily(memberid), {
      onSuccess: (res) => {
        if (res.Success) {
        } else {
          this.HandleResponseError(res);
        }
      },
    });
  }

  private SaveMemberNote() {
    this.Invoke(this.commonSvc.MemberNoteComponent.SaveMemberNote(), {
      onSuccess: (res) => {
        if (res.Success) {
          MessageBox.ShowInfo(
            this.dialog,
            CommonMessage.DATA_SAVE_SUCCESS_MESSAGE
          ).subscribe((data) => {
            this.membershipCards.OnLoad();
            this.OnLoad();
            this.commonSvc.MemberNoteComponent.InitForm();
          });
        } else {
          this.HandleResponseError(res);
        }
      },
    });
  }

  isPrimaryMemberValid() {
    const MemberStatusId = this.primaryMemberSharedData.model.MemberStatusId;
    return (
      MemberStatusId === EMemberStatus.Active ||
      MemberStatusId === EMemberStatus.Changed ||
      MemberStatusId === EMemberStatus.New ||
      MemberStatusId === EMemberStatus.CandidateMember
    );
  }

  private SelectedMembershipPackage() {
    this.membershipPackages
      .getControl("Memberships")
      .valueChanges.subscribe((value: Membership[]) => {
        if (value && value[0] && value[0].MembershipPackage) {
          const membershipPackage = value[0].MembershipPackage;
          const totalPrice =
            membershipPackage.DependantPrice +
            membershipPackage.FamilyPrice +
            membershipPackage.SinglePrice;
          // this.CheckMembershipPackageSelectedValid(totalPrice);
        }
      });
  }
  private CheckMembershipPackageSelectedValid(totalPriceSelected: Number) {
    let totalPriceMemberships = 0;
    if (this.membershipPackages.loadCompleted) {
      if (
        this.MemberMemberships.length > 0 &&
        this.EmployerIdSaved === this.data.model.EmployerId
      ) {
        if (!this.MemberMemberships[0].IsEnough12Months) {
          const membershipPackage = this.MemberMemberships[0].MembershipPackage;
          totalPriceMemberships =
            membershipPackage.SinglePrice +
            membershipPackage.FamilyPrice +
            membershipPackage.DependantPrice;
          if (totalPriceSelected < totalPriceMemberships) {
            MessageBox.ShowError(
              this.dialog,
              "Unable to downgrade package, member has not been active for 12 months"
            ).subscribe((r) => {
              // set back default membership package
              if (this.MemberMemberships.length > 0) {
                this.membershipPackages.selectMembershipPackage(
                  this.MemberMemberships[0].MembershipPackage
                );
              }
            });
            return false;
          }
          return true;
        }
        return true;
      }
    }
    return true;
  }

  InvokeNoCallback(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError((e) => {
          throw e;
        })
      )
      .subscribe(
        (res) => {
          handleResultCallback(res);
        },
        (err) => {
          this.errorHandler(err);
        }
      );
  }

  isAdminValid() {
    return !this.isEmployerFacilitatorRole;
  }

  btnResetBCF_Click(event) {
    const self = this;
    if (this.primaryMemberSharedData.model.Memberships) {
      if (this.primaryMemberSharedData.model.Memberships.length > 0) {
        this.membershipsId.Id =
          this.primaryMemberSharedData.model.Memberships[0].Id;
      }
    } else {
      this.membershipsId.Id = null;
    }
    CustomMessageBox.Show(
      this.dialog,
      {
        title: "Reset BCF",
        content:
          "This will reset the membership's BCF to 0." +
          "This operation is irreversible. Do you wish to continue?",
        buttons: [CustomMessageBoxButton.Cancel, CustomMessageBoxButton.Ok],
      },
      {
        height: "210px",
        width: "500px",
        afterClosed: (r) => {
          if (r.result === DialogResult.Ok) {
            console.log("Ok");
            this.membershipsId.ModifiedBy = this.userName;
            self.Invoke(
              this.memberSvc.resetMembershipsBcf(this.membershipsId),
              {
                onSuccess: (res) => {
                  if (res.Success) {
                    if (
                      this.primaryMemberSharedData.model.Memberships &&
                      this.primaryMemberSharedData.model.Memberships.length > 0
                    ) {
                      this.primaryMemberSharedData.model.Memberships[0].Bcf = 0;
                      this.changeDetectorRef.markForCheck;
                    }
                  } else {
                    this.HandleResponseError(res);
                  }
                },
              }
            );
          }
        },
      }
    );
  }

  public IsPrimary(): boolean {
    return this.data.model.MemberTypeId === EMemberType.Primary;
  }

  public SetLinearForTab() {
    //Bypass if User is not member
    const slRole = this.commonSvc.GetSelectedRole();
    if (slRole !== ERole.member && slRole !== null) {
      return false;
    }

    if (
      this.form.get("EmployerInfo") &&
      this.form.get("MembershipPackages") &&
      this.form.get("PersonalInfo")
    ) {
      if (
        this.form.get("EmployerInfo").valid &&
        this.form.get("MembershipPackages").valid &&
        this.form.get("PersonalInfo").valid
      ) {
        return false;
      } else {
        if (this.form.get("EmployerInfo").valid === false) {
          if (this.data.model.MemberTypeId !== EMemberType.Primary) {
            return false;
          } else {
            const mships = this.data.model.Memberships;
            if (
              mships.length > 0 &&
              mships[0].MembershipStatusId == EMembershipStatus.Suspended
            ) {
              return false;
            } else {
              return true;
            }
          }
        }
        return true;
      }
    } else {
      return true;
    }
  }
  openLink(memIdPrimary) {
    window.open("/dashboard/member/edit/" + memIdPrimary);
  }

  public CheckManualAddDepitCreditPermission() {
    let ret = true;
    const manuAddDebit = this.rolepermission.Privileges.find(
      (item) => item.PrivilegeName === "Manually Add Debit/Credit"
    );
    if (manuAddDebit) {
      ret = this.rolepermission.CanAccess(manuAddDebit);
    }
    if (ret === true && this.data.model.MemberTypeId === EMemberType.Primary) {
      return true;
    } else {
      return false;
    }
  }
}
