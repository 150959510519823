<div [formGroup]="formGroup">
    <div class="question">
        <div class="question-number">{{questionNumber}}</div>
        <div class="question-content">
            <div class="question-content-title">{{questionTitle}} <span class="required" *ngIf="_required">*</span></div>
        </div>
    </div>
    <div class="response">
        <textarea id="internalControl" class="form-control" formControlName="internalControl" [rows]="rows"></textarea>
    </div>
</div>