<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Products & Services</h4>
<div class="mt-lg-4">
    <form [formGroup]="productsServicesFormGroup">
        <div class="form-group row">
            <label for="ddlCategory" class="col-md-3 col-lg-2 col-form-label">Category<span class="text-danger">*</span>
            </label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <select [ngClass]="(productsServicesFormGroup.get('ddlCategory').invalid
                    && (productsServicesFormGroup.get('ddlCategory').dirty ||
                    productsServicesFormGroup.get('ddlCategory').touched
                    || btnSubmit))?'form-control is-invalid':'form-control'" id="ddlCategory"
                        formControlName="ddlCategory">
                        <option value="">Please select a value</option>
                        <option *ngFor="let c of categories" [ngValue]="c.Id">{{ c.Name }}</option>
                    </select>
                    <div *ngIf="productsServicesFormGroup.get('ddlCategory').invalid
                        && (productsServicesFormGroup.get('ddlCategory').dirty
                        || productsServicesFormGroup.get('ddlCategory').touched
                        || btnSubmit)">
                        <small *ngIf="productsServicesFormGroup.get('ddlCategory').errors['required']"
                             class="invalid-feedback d-block">Category
                            is required</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="txtProdtSerc" class="col-md-3 col-lg-2 col-form-label">Product/Service Type<span
                    class="text-danger">*</span>
            </label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <input type="text" maxlength="50" [ngClass]="(productsServicesFormGroup.get('txtProdtSerc').invalid
                    && (productsServicesFormGroup.get('txtProdtSerc').dirty ||
                    productsServicesFormGroup.get('txtProdtSerc').touched
                    || btnSubmit))?'form-control is-invalid':'form-control'" id="txtProdtSerc"
                        formControlName="txtProdtSerc">
                    <mat-spinner [diameter]="14" *ngIf="psTypeSpinter"></mat-spinner>
                    <div *ngIf="productsServicesFormGroup.get('txtProdtSerc').invalid
                        && (productsServicesFormGroup.get('txtProdtSerc').dirty
                        || productsServicesFormGroup.get('txtProdtSerc').touched
                        || btnSubmit)">
                        <small *ngIf="productsServicesFormGroup.get('txtProdtSerc').errors['required']"
                             class="invalid-feedback d-block">Product/Service
                            Type is required</small>
                        <small *ngIf="productsServicesFormGroup.get('txtProdtSerc').errors['duplicated']"
                             class="invalid-feedback d-block">The
                            product/service type already exists</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="ddlStatus" class="col-md-3 col-lg-2 col-form-label">Status<span class="text-danger">*</span>
            </label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <select [ngClass]="(productsServicesFormGroup.get('ddlStatus').invalid
                    && (productsServicesFormGroup.get('ddlStatus').dirty ||
                    productsServicesFormGroup.get('ddlStatus').touched
                    || btnSubmit))?'form-control is-invalid':'form-control'" id="ddlStatus"
                        formControlName="ddlStatus">
                        <option [ngValue]="true">Active</option>
                        <option [ngValue]="false">Inactive</option>
                    </select>
                    <div *ngIf="productsServicesFormGroup.get('ddlStatus').invalid
                        && (productsServicesFormGroup.get('ddlStatus').dirty
                        || productsServicesFormGroup.get('ddlStatus').touched
                        || btnSubmit)">
                        <small *ngIf="productsServicesFormGroup.get('ddlStatus').errors['required']"
                             class="invalid-feedback d-block">Status
                            is required</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-8 col-lg-5 offset-sm-2 justify-content-between justify-content-md-start">
                <button class="px-3 px-lg-5 py-2 btn bg-white border-primary text-primary mr-4" type="button"
                    (click)="this.router.navigate(['/' + routerConstant.ROUTER_DASHBOARD + '/' + routerConstant.ROUTER_PRODUCT_SERVICE_VIEW]);">
                    <i class="fas fa-arrow-alt-circle-left mr-2"></i>BACK</button>
                <button class="px-3 px-lg-5 py-2 btn btn-primary" type="button" (click)="submitProdtSerc()"
                    [disabled]="psTypeSpinter">
                    <i class="fas fa-check mr-2"></i> Submit</button>
            </div>
        </div>
    </form>
</div>