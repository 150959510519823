import { Component, Injector, Input } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmployerDetailModel, EmployerSurveyModel, DataResult } from '@fp/models';
import { EmployerService } from '@fp/services';
import { catchError, finalize } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EMemberSurvey } from "src/app/enums";
import { RouterConstant } from '@fp/constant';

class EmployerSurveyPageFE {
  constructor(
    public employerId?: number,
    public employerSurveyId?: number,
    public empCode?: string,
    public surveyTemplateId?: number,
    public startDate?: Date,
    public endDate?: Date,
    public isActive?: boolean

  ) { }
}

export class EmployerSurveyPageFormSharedData {
  additionalData: {
    btnNextEmployerSurveyPage: boolean;
    EmployerSurveyPageChanged: boolean;
    isSubmit: boolean;
  };

  constructor() {
    this.additionalData = {
      btnNextEmployerSurveyPage: false,
      EmployerSurveyPageChanged: false,
      isSubmit: false
    };
  }
}

@Component({
  selector: 'app-employer-surveypage',
  templateUrl: './survey-page.component.html',
  styleUrls: ['./survey-page.component.css']
})
export class SurveyPageComponent {
  [x: string]: any;
  @Input('form') surveyPageForm: UntypedFormGroup;
  parentFocus = 0;
  employerSurveyPageFE: EmployerSurveyPageFE;
  data: any;
  isEditMode: Boolean = false;

  get IsActiveCtrl() {
    return this.surveyPageForm.get("isActive");
  }
  get EndDateCtrl() {
    return this.surveyPageForm.get("endDate");
  }
  get StartDateCtrl() {
    return this.surveyPageForm.get("startDate");
  }

  constructor(
    private svc: EmployerService
  ) { }

  ngOnInit(): void {
    this.IsActiveCtrl.valueChanges.subscribe((value: any) => {
      this.validateInputsAndSetValidators();
    });
    this.EndDateCtrl.valueChanges.subscribe((value: any) => {
      this.validateInputsAndSetValidators();
    });
    this.StartDateCtrl.valueChanges.subscribe((value: any) => {
      this.validateInputsAndSetValidators();
    });
  }

  static getFormGroup() {
    const _formBuilder = new UntypedFormBuilder();

    return _formBuilder.group({
      startDate: [],
      endDate: [],
      isActive: [false],
      employerCode: [window.location.origin]
    });
  }

  validateStartDate(control: AbstractControl): { [key: string]: any } | null {
    const startDate = control.value;
    if (!startDate) return null; 

    const currentDate = new Date();
    const selectedDate = new Date(startDate.year, startDate.month - 1, startDate.day + 1);
    if (this.isEditMode) {
      return null;
    } else {
      if (selectedDate < currentDate) {
          return { 'pastDate': true };
      }
    }
    return null;
  }

  validateEndDate(control: AbstractControl): { [key: string]: any } | null {
    const endDate = control.value;
    if (!endDate) return null; 

    const currentDate = new Date();
    const selectedDate = new Date(endDate.year, endDate.month - 1, endDate.day);
    const startDateInput = this.surveyPageForm.get('startDate').value;
    const startDate = new Date(startDateInput.year, startDateInput.month - 1, startDateInput.day + 1);

    if (this.isEditMode) {
      if (selectedDate < startDate) {
        return { 'pastDate': true };
      }
    }else{
      if (selectedDate <= currentDate) {
        return { 'pastDate': true };
      }
    }
    return null;
  }

  validateDateFormat(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value !== undefined && control.value !== "") {
      try {
        const d: Date = new Date(control.value.year, control.value.month - 1, control.value.day, 0, 0, 0);
        const dc = new Date(1900, 1, 1, 0, 0, 0);
        if ((d < dc || d.toString() === 'Invalid Date' || !(d instanceof Date))
          && control.value !== null) {
          return { 'invalidDateFormat': true };
        } else {
          return null;
        }
      } catch (e) {
        return { 'invalidDateFormat': true };
      }
    } else {
      return null;
    }
  }

  LoadEmployerSurveyPage(employerObj): void {
    let empSurveyId = undefined;
    if (employerObj && employerObj.EmployerSurvey && employerObj.EmployerSurvey.length > 0) {
        const empSurvey = employerObj.EmployerSurvey.find(survey => 
            survey !== null && 
            survey !== undefined &&
            survey.SurveyTemplateId === EMemberSurvey.MemberPreSignupSurvey
        );
        if (empSurvey) {
          empSurveyId = empSurvey.EmployerSurveyId;
        }
    }
    this.employerSurveyPageFE = new EmployerSurveyPageFE(
        employerObj?.EmployerId,
        empSurveyId,
        employerObj?.Code
    );
    this.svc.getDetails(employerObj.EmployerId).pipe(
        catchError(() => observableOf(null)),
        finalize(() => {
        }),
        switchMap((data: DataResult<EmployerDetailModel>) => {
            if (!data.Success || !data.Data) {
                return observableOf({ employerSurvey: null, employerDetails: null });
            }
            const [empSurvey] = data.Data.EmployerSurvey;
            return observableOf({ employerSurvey: empSurvey, employerDetails: data.Data });
        })
    ).subscribe(
        (result: { employerSurvey: EmployerSurveyModel | null, employerDetails: EmployerDetailModel | null }) => {
            if (result.employerSurvey || result.employerDetails) {
                this.patchFormValues(result.employerSurvey);
            }
        },
        (error) => {
            console.error('Error fetching employer survey data:', error);
        }
    );
  }

  patchFormValues(employerSurveyModel: EmployerSurveyModel): void {

    if (employerSurveyModel?.StartDate && employerSurveyModel?.EndDate) {
        const startDate = new Date(employerSurveyModel?.StartDate);
        const endDate = new Date(employerSurveyModel?.EndDate);

        this.surveyPageForm.patchValue({
            startDate: {
                year: startDate?.getFullYear() ?? null,
                month: (startDate?.getMonth() ?? null) + 1,
                day: startDate?.getDate() ?? null
            },
            endDate: {
                year: endDate?.getFullYear() ?? null,
                month: (endDate?.getMonth() ?? null) + 1,
                day: endDate?.getDate() ?? null
            },
            isActive: employerSurveyModel?.IsActive || false,
            employerCode: window.location.origin + '/' + RouterConstant.ROUTER_SIGNUP_Survey + '?empcode=' + this.employerSurveyPageFE.empCode.toLowerCase(),
        }, { emitEvent: false, onlySelf: true });
        this.isEditMode = true;
        this.StartDateCtrl.clearValidators();
        this.EndDateCtrl.clearValidators();
    } else {
        this.surveyPageForm.patchValue({
            isActive: false,
            employerCode: window.location.origin + '/' + RouterConstant.ROUTER_SIGNUP_Survey +'?empcode=' + this.employerSurveyPageFE.empCode.toLowerCase(),
        }, { emitEvent: false, onlySelf: true });

        this.StartDateCtrl.setValidators([Validators.required, this.validateStartDate.bind(this), this.validateDateFormat]);
        this.EndDateCtrl.setValidators([Validators.required, this.validateEndDate.bind(this), this.validateDateFormat]);
    }
  }

  public getSurveyValues(): EmployerSurveyModel {
    let employerSurveyPage: EmployerSurveyModel;
    let startDate: Date;
    let endDate: Date;

    if (this.surveyPageForm && this.surveyPageForm.valid && this.surveyPageForm.touched) {

      const startDateInput = this.surveyPageForm.get('startDate').value;
      const endDateInput = this.surveyPageForm.get('endDate').value;

      if (startDateInput && endDateInput) {
        startDate = new Date(startDateInput.year, startDateInput.month - 1, startDateInput.day + 1);
        endDate = new Date(endDateInput.year, endDateInput.month - 1, endDateInput.day + 1);

      } else {
        console.error('startDateInput or endDateInput is null');
        return null;
      }

      employerSurveyPage = {
        EmployerId: this.employerSurveyPageFE && this.employerSurveyPageFE.employerId ? this.employerSurveyPageFE.employerId : 0,
        SurveyTemplateId: EMemberSurvey.MemberPreSignupSurvey,
        EmployerSurveyId: this.employerSurveyPageFE && this.employerSurveyPageFE.employerSurveyId ? this.employerSurveyPageFE.employerSurveyId : 0,
        StartDate: startDate,
        EndDate: endDate,
        IsActive: this.surveyPageForm.get('isActive').value,
      };
    } else {
      employerSurveyPage = null;
    }

    return employerSurveyPage;
  }

  validateInputsAndSetValidators() {

    const self = this;
    this.StartDateCtrl.clearValidators();
    this.EndDateCtrl.clearValidators();  

    const isActiveValue = this.IsActiveCtrl.value;
    const startDateValue = this.StartDateCtrl.value;
    const endDateValue = this.EndDateCtrl.value;

    if (!startDateValue && !endDateValue && !isActiveValue) {

      this.StartDateCtrl.clearValidators();
      this.EndDateCtrl.clearValidators();

    } else {

      this.StartDateCtrl.setValidators([
        Validators.required, 
        (control) => self.validateStartDate(control),
        this.validateDateFormat
      ]);
      this.EndDateCtrl.setValidators([
        Validators.required, 
        (control) => self.validateEndDate(control),
        this.validateDateFormat
      ]);
    }

    this.StartDateCtrl.updateValueAndValidity({ emitEvent: false });
    this.EndDateCtrl.updateValueAndValidity({ emitEvent: false });
  }

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }

  onBlurParent(focusNumbr) {
    this.parentFocus = focusNumbr;
  }

  getClassFocused(vlFocused) {
    return this.parentFocus == vlFocused ? 'focused' : '';
  }
}
