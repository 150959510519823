import { Component, Injector, ViewChild, ElementRef } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { FacilityFormBase, FPFormBaseComponent } from "src/app/components/base";
import { AuditLoggingService } from "@fp/services/audit-logging.service";
import { AuditLogVM } from "@fp/models/audit-logging.model";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataResult } from "@fp/models";
import { DataSourceHelper, DateHelper } from "@fp/helpers";
import { ActivatedRoute } from "@angular/router";
import { AuditLogComponent } from "@fp/components/audit-log/audit-log.component";

@Component({
  selector: "app-facility-journal-and-audit-log",
  templateUrl: "./journal-and-audit-log.component.html",
  styleUrls: ["./journal-and-audit-log.component.css"],
  providers: [
    FPFormBaseComponent.provideExisting(FacilityJournalAndAuditLogComponent),
    FacilityFormBase.provideExisting(FacilityJournalAndAuditLogComponent)
  ]
})
export class FacilityJournalAndAuditLogComponent extends FacilityFormBase {
  @ViewChild(AuditLogComponent) auditLog: AuditLogComponent;

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({});
  }

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  OnLoad() {
    super.OnLoad();
  }

}
