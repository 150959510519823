import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticatorService } from '@FitnessPassport/ui-angular';
import { AuthenticationService, CommonService, HttpDAO } from '@fp/services';
import { signInWithRedirect } from 'aws-amplify/auth';
import { RouterConstant } from 'src/app/constant/routerconstant';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  submitted: Boolean;
  loading: Boolean;
  isFederatedUserLoginLoading: Boolean;
  returnUrl: String;
  email = '';
  password = '';
  RouterConstant = RouterConstant;
  httpdao: HttpDAO | null;

  // Authenticator
  public _authServices;
  public _authFormFields;

  constructor(
    private route: ActivatedRoute,
    public authService: AuthenticationService,
    private http: HttpClient,
    public commonService: CommonService,
    private authenticator: AuthenticatorService,
  ) {
    this._authServices = authService.AuthServices;
    this._authFormFields = authService.AuthFormFields;
  }

  get signupErrors() {
    return this.authService.signupErrors;
  }

  get countryCode() {
    return this.authService.countryCode;
  }

  async ngOnInit() {
    this.submitted = false;
    this.loading = false;
    this.isFederatedUserLoginLoading = false;
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.httpdao = new HttpDAO(this.http);
    this.commonService.App.currentLogin = true;
    this.commonService.App.opened = false;
    setTimeout(() => {
      // Lifecycle does not match UI state, this ensures component is mounted.
      const currentUrl = this.route.snapshot.url.join('/');
      if (currentUrl === RouterConstant.ROUTER_SIGNUP) {
        this.authenticator.toSignUp();
      }
    }, 0);
  }

  public forgotPassword() {
    this.authenticator.toForgotPassword();
  }

  public async signInWithAzureAD() {
    await signInWithRedirect({
      provider: {
        custom: 'AzureAD',
      },
    });
  }

  public get resetPasswordDestinationInfo() {
    return `A verification code has been sent to ${this.resetPasswordDestination}`;
  }

  public get resetPasswordDestination() {
    return this.authService.resetPasswordCodeDelivery?.destination;
  }
}
