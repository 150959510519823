import { HttpClient } from "@angular/common/http";
import { AgmInfoWindow, AgmMap, MapsAPILoader } from "@agm/core";
import {
  ChangeDetectorRef,
  Component,
  Injector,
  ViewChild,
  NgZone,
  ChangeDetectionStrategy,
} from "@angular/core";
import {
  FPBaseComponent,
  IFPComponentData,
  FPFormBaseComponent,
} from "@fp/components/base";
import { Utils } from "@fp/helpers";
import { DataResult } from "@fp/models";
import { HttpDAO, SuburbService, MessageBox , CommonService} from "@fp/services";
import { merge, Observable, of as observableOf } from "rxjs";
import { startWith, switchMap, map, catchError } from "rxjs/operators";
import { APIConstant } from "@fp/constant";
import { MapFacilityData } from "@fp/models/map-facility-data";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { CommonConstants } from "@fp/constant/common-constants";
declare const google: any;

interface Location {
  lat: number;
  lng: number;
  zoom: number;
}
@Component({
  selector: "app-facility-google-map",
  templateUrl: "./facitily-google-map.component.html",
  styleUrls: ["./facitily-google-map.component.css"],
  providers: [
    FPBaseComponent.provideExisting(FacilityGoogleMapComponent),
    FPFormBaseComponent.provideExisting(FacilityGoogleMapComponent),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilityGoogleMapComponent extends FPBaseComponent<
  IFPComponentData
> {
  geocoder: any;
  ranFac = 0;
  public location: Location = {
    lat: -23.698,
    lng: 133.8807,
    zoom: 5,
  };
  public dataSource: Array<MapFacilityData> = []; // original data source from backend
  public filteredDataSource: Array<MapFacilityData> = []; // data source displayed on facility list
  public mapDataSource: Array<MapFacilityData> = []; // data source displayed on maps
  public singleFacDataSource: Array<MapFacilityData> = [];
  public multiFacDataSource: Array<MapFacilityData> = [];
  iconUrl = "assets/images/fp-map-icon.png";
  mapLocationSearch = "";
  listLocationFilter = "";
  public mapSidebarCollapsed: boolean = false;
  public mapIsLoading: boolean = false;
  private previousInfoWindow: AgmInfoWindow = null;
  disableSearchFacByArea = false;
  disableSearchFacByName = false;
  Utils = Utils;

  @ViewChild("map") private map: AgmMap;
  private httpdao2: HttpDAO | null;
  longi: number;
  lati: number;

  constructor(
    injector: Injector,
    private http: HttpClient,
    public commonservice: CommonService,
    private suburbSvc: SuburbService, // service that contains the function that calls facilities
    public mapsApiLoader: MapsAPILoader // private zone: NgZone
  ) {
    super(injector);
    this.mapsApiLoader = mapsApiLoader;
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  if(this.commonservice.getUserCountry() == "New Zealand"){
    this.location = {
      lat: -40,
      lng: 174,
      zoom: 5,
  }}
  else{
    this.location = {
      lat: -23.698,
      lng: 133.8807,
      zoom: 5,
  }
  }}
  // checks to see if theres multiple facilities in same location and adds factor to allow both dots to appear on map
  checkIfDuplicates(facility: MapFacilityData, lats, longs): any[] {
    let count = 0;
    const locCheck = true;
    let sameLoc = false;
    let newLat = 0;
    for (let i = 0; i < lats.length; i++) {
      if (
        facility.Address.Latitude === lats[i] &&
        facility.Address.Longitude === longs[i]
      ) {
        count++;
      }
    }
    if (count > 1) {
      sameLoc = true;
      newLat = +facility.Address.Latitude - 0.00002 * this.ranFac;
      this.ranFac++;
    } else {
      sameLoc = false;
    }
    return [locCheck, sameLoc, newLat];
  }

  Search(name: string, value: string, memId?: string) {
    if (memId === undefined) {
      memId = null;
    }
    this.mapIsLoading = false;
    // search with no encryption
    // console.log(name, value, memId);
    this.Invoke(this.suburbSvc.getFacilityMap(name, value, memId), {
      onSuccess: (res: DataResult<MapFacilityData[]>) => {
        if (res.Success) {
          this.previousInfoWindow = null;
          this.dataSource = res.Data;
          this.filteredDataSource = this.dataSource;
          this.mapDataSource = this.dataSource;
          this.changeDetectorRef.detectChanges();
          this.duplicateLocationCheck();
          this.commonSvc.StopGlobalProgressBar();
        } else {
          MessageBox.ShowError(
            this.dialog,
            "Sorry, something went wrong. Let's try that again."
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              window.location.reload();
            }
          });
          this._logger.error(res);
        }
      },
      onError: (err) => {
        MessageBox.ShowError(
          this.dialog,
          "Sorry, something went wrong. Let's try that again."
        ).subscribe((res) => {
          if (res.result.toLowerCase() === DialogResult.Ok) {
            window.location.reload();
          }
        });
        this._logger.error(err);
      },
    });
  }

  // marks variables LocationChecked and SameLocation in the actual facility variable based on checkIfDuplicates function output
  duplicateLocationCheck() {
    this.singleFacDataSource = [];
    this.multiFacDataSource = [];
    let latitudeArray = [];
    let longitudeArray = [];
    this.mapDataSource.forEach((element) => {
      latitudeArray.push(element.Address.Latitude);
      longitudeArray.push(element.Address.Longitude);
    });
    for (let i = 0; i < this.mapDataSource.length; i++) {
      var output;
      if (this.mapDataSource[i].LocationChecked == null) {
        output = this.checkIfDuplicates(
          this.mapDataSource[i],
          latitudeArray,
          longitudeArray
        );
        this.mapDataSource[i].LocationChecked = output[0];
        this.mapDataSource[i].SameLocation = output[1];
      }
      if (!this.mapDataSource[i].SameLocation) {
        this.singleFacDataSource.push(this.mapDataSource[i]);
      } else {
        this.multiFacDataSource.push(this.mapDataSource[i]);
        this.mapDataSource[i].Address.Latitude = "" + output[2];
      }
    }
  }

  SearchNoInvoke(name: string, value: string, memId?: string) {
    if (memId === undefined) {
      memId = null;
    }
    this.mapIsLoading = false;
    console.log(memId);
    // search with encryption
    this.httpdao2 = new HttpDAO(this.http);
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.httpdao2.getSingleDataWithoutToken(
            APIConstant.API_GET_FACILITY_MAPS +
              "?filterType=" +
              name +
              "&filterId=" +
              value +
              "&memberId=" +
              memId
          );
        }),
        map((data: DataResult<MapFacilityData[]>) => {
          if (data.Success) {
            this.dataSource = data.Data;
            this.previousInfoWindow = null;
            this.filteredDataSource = this.dataSource;
            this.mapDataSource = this.dataSource;
            this.changeDetectorRef.detectChanges();
            this.duplicateLocationCheck();
          } else {
            MessageBox.ShowError(
              this.dialog,
              "Sorry, something went wrong. Let's try that again."
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                window.location.reload();
              }
            });
            this._logger.error(data.Message);
            return observableOf([]);
          }
        }),
        catchError(() => {
          return observableOf([]);
        })
      )
      .subscribe();
  }

  clickToMaps(facility: MapFacilityData) {
    this.location.lat = +facility.Address.Latitude;
    this.location.lng = +facility.Address.Longitude;
    this.location.zoom = 16;
    this.changeDetectorRef.detectChanges();
  }

  disableOrEnableSearchBar(searchType: string) {
    if (searchType === "suburb") {
      if (this.mapLocationSearch) {
        this.disableSearchFacByName = true;
      } else {
        this.disableSearchFacByName = false;
      }
    } else {
      if (this.listLocationFilter) {
        this.disableSearchFacByArea = true;
      } else {
        this.disableSearchFacByArea = false;
      }
    }
  }

  searchBarFunctionality(searchBar: string) {

    var isAus = this.commonservice.getUserCountry()?.toUpperCase() !== CommonConstants.COUNTRY_NEW_ZEALAND;
    
    var country;
    var countryCenterCity;
    var region;

    if(isAus){
      country = " Australia";
      countryCenterCity = "Alice Springs"; // utilising geolocation of alice springs as center of australia
      region = "au";
    }
    else{
      country = " New Zealand";
      countryCenterCity = "Wellington"; // utilising geolocation of alice springs as center of new zealand
      region = "nz";
    }

    if (
      (this.mapLocationSearch === "" || this.mapLocationSearch === null) &&
      this.listLocationFilter !== ""
    ) {
      if (this.map.zoom !== 5) {
        this.mapIsLoading = true;
      }
      // search via facility name/service type and NOT via postcode/suburb
      this.filteredDataSource = this.dataSource.filter(
        (facility: MapFacilityData) =>
          facility.Name.toLocaleLowerCase().indexOf(
            this.listLocationFilter.toLocaleLowerCase()
          ) !== -1 ||
          facility.ServicesList.toLocaleLowerCase().indexOf(
            this.listLocationFilter.toLocaleLowerCase()
          ) !== -1
      );
      this.mapDataSource = this.filteredDataSource;
      // this.location = {
      //   lat: -23.6980,
      //   lng: 133.8807,
      const zoom = 5;
      // };
      // this.changeDetectorRef.detectChanges();
      this.findLocation(countryCenterCity + country, "N/A", zoom, region);
      console.log("data source: ", this.dataSource);
      console.log("filtered data source: ", this.filteredDataSource);
      console.log("map data source: ", this.mapDataSource);
    } else if (
      this.mapLocationSearch !== "" &&
      (this.listLocationFilter === "" || this.listLocationFilter === null)
    ) {
      // search via postcode/suburb and NOT via facility name/service type
      if (this.map.zoom !== 12) {
        this.mapIsLoading = true;
      }
      this.mapDataSource = this.dataSource;
      const zoom = 12;

      // check if input is 4 digits and handle as postcode
      // replace postcodes 2001, 2002, 2004 with suburb names
      // https://auspost.com.au/postcode/2001
      // workaround Google Maps API issues
      // https://issuetracker.google.com/issues/174167298
      // https://stackoverflow.com/questions/50037957/google-maps-api-geocode-not-returning-results-on-passing-postal-code-as-a-value
      const searchInput = this.mapLocationSearch.trim();
      const regexFourDigits = /^\d{4}$/;
      const isPostcode = regexFourDigits.test(searchInput);
      let locationAddress = searchInput;

      if (isPostcode) {
        switch (searchInput) {
          case "2000":
          case "2001":
            locationAddress = "Sydney, NSW";
            break;
          case "2002":
            locationAddress = "World Square, NSW";
            break;
          case "2004":
            locationAddress = "Eastern Suburbs MC, NSW";
            break;
        
          default:
            locationAddress = searchInput;
            break;
        }
      }

      this.findLocation(
        locationAddress + country,
        this.mapLocationSearch,
        zoom,
        region
      );
    } else if (
      this.mapLocationSearch !== "" &&
      this.listLocationFilter !== ""
    ) {
      // search via BOTH postcode/suburb and facility name/service type
      this.filteredDataSource = this.dataSource.filter(
        (facility: MapFacilityData) =>
          facility.Name.toLocaleLowerCase().indexOf(
            this.listLocationFilter.toLocaleLowerCase()
          ) !== -1 ||
          facility.ServicesList.toLocaleLowerCase().indexOf(
            this.listLocationFilter.toLocaleLowerCase()
          ) !== -1
      );
      this.mapDataSource = this.filteredDataSource;
      if (this.map.zoom !== 12) {
        this.mapIsLoading = true;
      }
      this.changeDetectorRef.detectChanges();
      const zoom = 12;
      this.findLocation(
        this.mapLocationSearch + country,
        this.mapLocationSearch,
        zoom,
        region
      );
    } else {
      if (this.map.zoom !== 5) {
        this.mapIsLoading = true;
      }
      // default zoom and searching for nothing
      
      const zoom = 5;
      this.findLocation(countryCenterCity + country, "N/A", zoom, region);

      this.changeDetectorRef.detectChanges();
      this.mapDataSource = this.dataSource;
      this.filteredDataSource = this.dataSource;
      console.log("data source: ", this.dataSource);
      console.log("filtered data source: ", this.filteredDataSource);
      console.log("map data source: ", this.mapDataSource);
    }
    if (searchBar === "suburb") {
      this.mapSidebarCollapsed = true;
    }

    //Ensure the search results on the sidebar to the top
    document.getElementById("card-body").scrollTop = 0;
  }

  // finds square of facilities within 10km of search point, zooms to map and filters list accordingly
  findLocation(address, postcode, zoomInp, region) {
    if (!this.geocoder) this.geocoder = new google.maps.Geocoder();
    var componentRestrictions : any = null;
    var isPostcode = Number (postcode);
    if (isPostcode){
      componentRestrictions = {
        country: region,
        postalCode: postcode
      }
    }
    this.geocoder.geocode(
      {
        address: address,
        region: region,
        componentRestrictions : componentRestrictions

      },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (zoomInp === 12) {
            console.log(results);
            const latitudeUpper = results[0].geometry.location.lat() + 0.09;
            const latitudeLower = results[0].geometry.location.lat() - 0.09;
            const longitudeUpper = results[0].geometry.location.lng() + 0.105;
            const longitudeLower = results[0].geometry.location.lng() - 0.105;

            console.log(postcode);
            this.filteredDataSource = this.mapDataSource.filter(
              (facility: MapFacilityData) =>
                (+facility.Address.Latitude <= latitudeUpper &&
                  +facility.Address.Latitude >= latitudeLower &&
                  +facility.Address.Longitude <= longitudeUpper &&
                  +facility.Address.Longitude >= longitudeLower) ||
                facility.Postcode.Code === postcode
            );
          }
          this.location.lat = results[0].geometry.location.lat();
          this.location.lng = results[0].geometry.location.lng();
          this.changeDetectorRef.detectChanges();
          console.log(this.location.lat, this.location.lng);
          // if (this.map.zoom !== 12) {
          this.location.zoom = zoomInp;
          this.mapIsLoading = false;
          this.changeDetectorRef.detectChanges();
          // }
          console.log("data source: ", this.dataSource);
          console.log("filtered data source: ", this.filteredDataSource);
          console.log("map data source: ", this.mapDataSource);
        }
        else{
          this.mapIsLoading = false;
          this.changeDetectorRef.detectChanges();
        }
      }
    );
  }

  // encrypted search for regions
  LoadFacilitiesByRegionId(id: number) {
    this.mapIsLoading = true;
    if (!id) {
      return;
    }
    this.Search("Region", "" + id);
  }

  // non-encrypted search for employer portal not logged in
  LoadFacilitiesByMembershipPackageIdv2(id: number) {
    this.mapIsLoading = true;
    if (!id) {
      return;
    }
    this.SearchNoInvoke("Membership_Package", "" + id);
  }

  // encrypted search for members logged in
  LoadFacilitiesByMembershipPackageId(id: number, memberid: number) {
    this.mapIsLoading = true;
    if (!id) {
      return;
    }
    this.Search("Membership_Package", "" + id, "" + memberid);
  }

  closePreviousInfoWindow(infoWindow) {
    if (this.previousInfoWindow) {
      this.previousInfoWindow.close();
    }
    this.previousInfoWindow = infoWindow;
  }

  formatTime(time) {
    if (time !== null) {
      const hours = time.split(":")[0];
      const mins = time.split(":")[1];
      return hours + ":" + mins;
    }
  }

  toggleMapSidebar() {
    this.mapSidebarCollapsed = !this.mapSidebarCollapsed;
    this.changeDetectorRef.detectChanges();
  }

  //When user clicks
  public collapseSearchBar() {
    this.mapSidebarCollapsed = true;
  }

  public startMapLoadSpinner() {
    this.mapIsLoading = true;
    this.changeDetectorRef.detectChanges();
  }
}
