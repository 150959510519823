import { Component, Injector, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { NgbCalendar, NgbDateAdapter, NgbDateNativeUTCAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FacilityFormBase } from 'src/app/components/base';
import { CommonString } from 'src/app/constant';
import { StringHelper, Utils } from 'src/app/helpers';
import { FpFileData, FpFileUploadComponent, FpFileEvent } from 'src/app/components/control/fp-file-upload/fp-file-upload.component';
import { MessageBox } from '@fp/services';

@Component({
    selector: 'app-facility-contract',
    templateUrl: './contract.component.html',
    styleUrls: ['./contract.component.css'],
    providers: [
        { provide: FacilityFormBase, useExisting: FacilityContractComponent },
        { provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter }]
})
export class FacilityContractComponent extends FacilityFormBase {
    parentFocus = 0;
    @ViewChild('dpStartDate') dpStartDate: any;
    @ViewChild('dpEndDate') dpEndDate: any;
    @ViewChild('signedContract') signedContract: FpFileUploadComponent;

    get durationCtrl() { return <UntypedFormControl>this.getControl(['FacilityContract', 'ContractDuration']); }
    get startDateCtrl() { return <UntypedFormControl>this.getControl(['FacilityContract', 'ContractStartDate']); }
    get endDateCtrl() { return <UntypedFormControl>this.getControl(['FacilityContract', 'ContractEndDate']); }
    get signedCtrl() { return <UntypedFormControl>this.getControl(['FacilityContract', 'SignedContract']); }
    get fileNameCtrl() { return <UntypedFormControl>this.getControl(['FacilityContract', 'FileName']); }

    Utils = Utils;
    CommonString = CommonString;
    StringHelper = StringHelper;
    firstLoad: boolean = true;
    isReadonly: boolean;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            FacilityContract: fb.group({
                ContractDuration: [, Validators.max(99)],
                ContractStartDate: [, this.RangeDateValidator],
                ContractEndDate: [, this.RangeDateValidator],
                SignedContract: [],
                FileName: []
            })
        });
    }

    public static RangeDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
        if (control.value && control.value instanceof Date) {
            let enddate: Date = control.parent.get('ContractEndDate').value;
            let startdate: Date = control.parent.get('ContractStartDate').value;
            if (startdate == null || enddate == null) {
                return null;
            } else if (enddate instanceof Date && startdate instanceof Date) {
                enddate.setHours(0, 0, 0, 0);
                startdate.setHours(0, 0, 0, 0);
                if (enddate <= startdate) {
                    return { 'rangedateinvalid': true };
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }

    constructor(injector: Injector, calendar: NgbCalendar) { super(injector); }

    LoadComplete() {
        this.isReadonly = this.form.disabled;
        super.LoadComplete();
        setTimeout(() => {
            this.startDateCtrl.valueChanges.subscribe(value => {
                this.endDateCtrl.updateValueAndValidity({ emitEvent: false });
                this._processDate(this.startDateCtrl, this.endDateCtrl);
            });

            this.endDateCtrl.valueChanges.subscribe(value => {
                this.startDateCtrl.updateValueAndValidity({ emitEvent: false });
                this._processDate(this.startDateCtrl, this.endDateCtrl);
            });

        });
    }

    _processDate(start: UntypedFormControl, end: UntypedFormControl) {
        let duration: number = 0;
        if (start.invalid || end.invalid) {
            duration = 0;
        } else if (start.value instanceof Date && end.value instanceof Date) {
            const ngbStartDate: NgbDateStruct = {
                day: (<Date>start.value).getDate(),
                month: (<Date>start.value).getMonth() + 1,
                year: (<Date>start.value).getFullYear()
            }

            const ngbEndDate: NgbDateStruct = {
                day: (<Date>end.value).getDate(),
                month: (<Date>end.value).getMonth() + 1,
                year: (<Date>end.value).getFullYear()
            }

            duration = Utils.calculateContractDuration(ngbStartDate, ngbEndDate);
        }
        this.durationCtrl.patchValue(duration);
        // this.durationCtrl.updateValueAndValidity();
    }

    SignedContract_UploadCompleted(event) {
        const ret: FpFileData = event;
        this.fileNameCtrl.patchValue(ret.originfilename);
        this.signedCtrl.patchValue(ret.filedata.Location);
    }

    _fileSelected(event: FpFileEvent) {
        if (event.file.type !== 'application/pdf') {
            event.cancel();
            MessageBox.ShowError(this.dialog,
                StringHelper.format(CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE, 'PDF')
            );
        } else if (event.file.size > this.CommonString.MAX_UPLOAD_FILE_SIZE_100MB) {
            event.cancel();
            MessageBox.ShowError(this.dialog,
                StringHelper.format(CommonString.MAX_UPLOAD_FILE_SIZE_MESSAGE, '100MB')
            );
        }
    }

    removeSignedContract() {
        this.signedCtrl.patchValue(null);
        this.fileNameCtrl.patchValue(null);
        this.signedContract.ResetFileInputValue();
    }

    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }
    onBlurParent(focusNumbr) {
        this.parentFocus = focusNumbr;
    }
    getClassFocused(vlFocused) {
        if (this.parentFocus === vlFocused) {
            return 'focused';
        } else {
            return '';
        }
    }

}
