<div [formGroup]="form">
  <div class="member-details-content">
    <table class="table">
      <thead>
        <tr>
          <th scope="col"><span class="d-none d-md-block">First Name</span><span class="d-md-none">Name</span>
          </th>
          <th scope="col" class="d-none d-md-table-cell">Last Name</th>
          <th scope="col" class="family-member-type-width ">Member Type</th>
          <th scope="col" class="family-member-close-btn-width"></th>
          <th scope="col" class="family-member-close-btn-width"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let member of memberDataSource">
          <td>{{member.FirstName}} <span class="d-md-none">{{member.LastName}}</span></td>
          <td class="d-none d-md-table-cell">{{member.LastName}} </td>
          <td>{{member.MemberIdPrimary == null ? 'Primary' :
            member.MemberType?.Name}} 
          </td>
          <td>
            <mat-checkbox type="checkbox" class="example-margin" [checked]="member.IsReactivated"
              (change)="onCheckChange($event, member)">
            </mat-checkbox>
          </td>
          <td>
            <a class="px-2" [routerLink]="" (click)="editFamilyMember(member)">
              <i class="fas fa-pen"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-new btn-primary mt-3" (click)="addMemberPopup()" type="button">
      <i class="fas fa-plus mr-2"></i>
      Add Family Member</button>
  </div>
</div>