import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@fp/services/common.service';
import { RouterConstant } from 'src/app/constant';

@Component({
  selector: 'app-continue-member-signup',
  templateUrl: './continue-membersignup.component.html',
  styleUrls: ['./continue-membersignup.component.css']
})
export class ContinueMemberSignUpComponent {

  constructor(private router: Router,protected commonService: CommonService) {
    const uobject = this.commonService.GetUserObject();
    const fullname = uobject.FirstName;
    this.commonService.Header.title = "Hello, " + fullname + ". Welcome back!";

   }

  ContinueRegistration() {
    this.router.navigate([RouterConstant.ROUTER_CONTINUE_MEMBER_SIGNUP]);
  }

}
