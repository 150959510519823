<amplify-authenticator
initialState="signUp"
[services]="_authServices"
[formFields]="_authFormFields"
>
    <!-- <ng-template amplifySlot="header">
    <div style="padding: var(--amplify-space-large); text-align: center">
        <img
        class="amplify-image"
        src="./assets/images/logo.png" alt="Fitness Passport"
        />
    </div>
    </ng-template> -->
    <ng-template
    amplifySlot="authenticated"
    >
    <div class="loading-shade-global">
        <mat-spinner [diameter]=50></mat-spinner>
    </div>
    </ng-template>
</amplify-authenticator>
<!-- <header #fpheader class="header-new">
  <div class="container-fluid">
    <div class="text-center text-md-left">
      <a href="/login">
        <img src="./assets/images/logo.png" alt="Fitness Passport" />
      </a>
    </div>
  </div>
</header> -->
<!-- <div class="container">
  <div class="row">
    <div class="col-12">
      <div class="register-wrapper mx-auto">
        <h1 class="text-center title-text d-none d-md-block">
          Membership Registration
        </h1>

        <div class="mobile-title-wrapper d-md-none text-center">
          <div class="d-flex justify-content-center">
            <div class="registration-title border-bottom-title mobile-title"
              [ngClass]="showRegisterTab && !showLoginTab ? 'active' : ''">
              <h1 class="title-text" (click)="showResigster()">Register</h1>
            </div>
            <div class="login-title mobile-title border-bottom-title"
              [ngClass]="!showRegisterTab && showLoginTab ? 'active' : ''">
              <h1 class="title-text disabled-title" (click)="showLogin()">
                Login
              </h1>
            </div>
          </div>
        </div>
        <div class="login-wrapper" [ngClass]="!showRegisterTab && showLoginTab ? 'd-block' : 'd-none'">
          <form [formGroup]="loginFormGroup" class="login-wrapper--from">
            <div class="form-group row">
              <label for="txtUserName" class="col-sm-3 col-form-label">User Name</label>
              <div class="col-sm-9">
                <div class="d-flex flex-column w-100">
                  <input type="text" maxlength="50" id="txtUserName" class="form-control"
                    formControlName="txtUserName" />
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="txtPassword" class="col-sm-3 col-form-label">Password</label>
              <div class="col-sm-9">
                <div class="d-flex flex-column w-100">
                  <input type="password" maxlength="50" id="txtPassword" class="form-control"
                    formControlName="txtPassword" />
                </div>
              </div>
            </div>
            <div class="row form-group">
              <div class="offset-md-3 col-md-9">
                <button class="btn btn-primary btn-new">Login</button>
              </div>
            </div>
          </form>
        </div>
        <div class="reg_wrapper" [ngClass]="showRegisterTab && !showLoginTab ? 'd-block' : 'd-none'">
          <form [formGroup]="registerFormGroup" class="register-wrapper--from">
            <div class=" row">
              <div class="col-md-12">
                <div class="d-block mb-4 text-center">Already registered? <a class="info-link-underline"
                    routerLink="/login"> Log in here </a> to manage or complete your membership <br>(this includes
                  adding
                  a Partner and/or Dependent/s to your membership).</div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputFirstName" class="col-sm-3 col-form-label">First Name*</label>
              <div class="col-sm-9">
                <div class="d-flex flex-column w-100">
                  <input type="text" maxlength="50" id="txtFirstName" class="form-control"
                    formControlName="txtFirstName" [ngClass]="
                      registerFormGroup.get('txtFirstName').invalid &&
                      (registerFormGroup.get('txtFirstName').dirty ||
                        registerFormGroup.get('txtFirstName').touched ||
                        btnSubmit)
                        ? 'form-control is-invalid'
                        : 'form-control'
                    " />
                  <div *ngIf="
                      registerFormGroup.get('txtFirstName').invalid &&
                      (registerFormGroup.get('txtFirstName').dirty ||
                        registerFormGroup.get('txtFirstName').touched ||
                        btnSubmit)
                    ">
                    <small *ngIf="
                        registerFormGroup.get('txtFirstName').errors.required
                      " class="invalid-feedback d-block">First Name is required</small>
                    <small *ngIf="
                        registerFormGroup.get('txtFirstName').errors.pattern
                      " class="invalid-feedback d-block">This field does not accept special characters. Please try
                      again</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputLastName" class="col-sm-3 col-form-label">Last Name*</label>
              <div class="col-sm-9">
                <div class="d-flex flex-column w-100">
                  <input type="text" maxlength="50" [ngClass]="
                      registerFormGroup.get('txtLastName').invalid &&
                      (registerFormGroup.get('txtLastName').dirty ||
                        registerFormGroup.get('txtLastName').touched ||
                        btnSubmit)
                        ? 'form-control is-invalid'
                        : 'form-control'
                    " id="txtLastName" formControlName="txtLastName" />
                  <div *ngIf="
                      registerFormGroup.get('txtLastName').invalid &&
                      (registerFormGroup.get('txtLastName').dirty ||
                        registerFormGroup.get('txtLastName').touched ||
                        btnSubmit)
                    ">
                    <small *ngIf="
                        registerFormGroup.get('txtLastName').errors.required
                      " class="invalid-feedback d-block">Last Name is required</small>
                    <small *ngIf="
                        registerFormGroup.get('txtLastName').errors.pattern
                      " class="invalid-feedback d-block">This field does not accept special characters. Please try
                      again</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail" class="col-sm-3 col-form-label">Email*</label>
              <div class="col-sm-9">
                <div class="d-flex flex-column w-100">
                  <input (input)="checkPhoneEmailResponsive()" [ngClass]="
                      registerFormGroup.get('txtEmail').invalid &&
                      (registerFormGroup.get('txtEmail').dirty ||
                        registerFormGroup.get('txtEmail').touched ||
                        btnSubmit)
                        ? 'form-control is-invalid'
                        : 'form-control'
                    " type="email" id="txtEmail" formControlName="txtEmail" maxlength="100" />
                  <div *ngIf="
                      registerFormGroup.get('txtEmail').invalid &&
                      (registerFormGroup.get('txtEmail').dirty ||
                        registerFormGroup.get('txtEmail').touched ||
                        btnSubmit)
                    ">
                    <small *ngIf="registerFormGroup.get('txtEmail').errors.required" class="invalid-feedback d-block">
                      Primary Email is required</small>
                    <small *ngIf="registerFormGroup.get('txtEmail').errors.pattern"
                      class="invalid-feedback d-block">Primary Email is invalid</small>
                  </div>
                </div>
              </div>
            </div>
            <app-mobile-number-registor [mandatory]="true" [control]="registerFormGroup.get('txtMobileNum')"
              [submitted]="btnSubmit" formControlName="txtMobileNum" (runSingupCheck)="runCheck($event)" (change)="runCheck($event)"
              id="mobile-no-wrapper"></app-mobile-number-registor>

            <div class="form-group row">
              <div class="d-flex justify-content-between offset-md-3 col-md-9">
                <form class="w-100" [formGroup]="ReCaptchaFormGroup">
                  <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" [size]="'normal'" [hl]="'en'" [theme]="'light'"
                    [type]="'image'" formControlName="rcpSignUpCaptcha" (success)="(true)">
                  </ngx-recaptcha2>
                </form>
              </div>
              <div class="d-flex justify-content-between offset-md-3 col-md-9">
                <small *ngIf="isCaptchaResponse == false && btnSubmit == true" class="invalid-feedback d-block">Please
                  verify that you are not a robot</small>
              </div>
            </div>
            <div class="row form-group">
              <div class="offset-md-3 col-md-9">
                <button class="btn btn-primary btn-new btn-mobile-100" (click)="btnSubmit_Click()"
                  [disabled]="this.registerFormGroup.invalid">
                  Submit
                </button>
              </div>
            </div>
            <div class="form-group row">
              <div class="d-flex justify-content-between offset-md-3 col-md-9">
                <p>
                  Already a member?<a class="text-primary" routerLink="/login">
                    Sign in</a>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div> -->