import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, of as observableOf } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { catchError, finalize } from 'rxjs/operators';
import { CommonDataService } from 'src/app/services/common-data.service';
import { DataResult, ResultModel, TermsAndConditions, AcceptTermsAndConditions, Member, NewTermsAndConditions } from 'src/app/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CommonString, RouterConstant, StorageKey } from 'src/app/constant';
import { MessageBox } from 'src/app/services/common-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { MemberService } from '../../../services/member.service';
import { MemberContainer } from '@fp/models/member-container.model';
import { RolePermissionService } from '@fp/services/role-permission.service';
import { CommonConstants } from '@fp/constant/common-constants';

@Component({
    selector: 'app-terms-conditions',
    templateUrl: './terms-conditions.component.html',
    styleUrls: ['./terms-conditions.component.css'],
    host: {
        '(window:resize)': 'onResize($event)'
    }
})
export class TermsConditionsComponent implements OnInit, AfterViewChecked, OnDestroy {
    currentTermsAndConditions: TermsAndConditions;
    currentKeyTermsAndConditions: TermsAndConditions;
    termsAndConsContent: SafeHtml;
    keyTermsAndConsContent: SafeHtml;
    requestQueueCount = 0;
    isTermsAndConsAccepted = false;
    isKeyTermsAndConsAccepted = false;
    termConditionId: number;
    keyTermConditionId: number;
    disablechkTermCons = true;
    disablechkKeyTermCons = true;
    checkscroll = false;
    keyCheckscroll = false;
    heightTCText: string;
    heightKeyTCText: string;
    countriesId = CommonConstants.GET_COUNTRIES;
    isFromAustralia = true;
    commonString = CommonString;
    fadeMainTerm = false;

    @ViewChild('dlgTermsConsContent') dlgTermsCons: ElementRef;
    @ViewChild('dlgKeyTermsConsContent') dlgKeyTermsCons: ElementRef;

    ngOnDestroy() {
        this.currentTermsAndConditions = null;
        this.termsAndConsContent = null;
        this.requestQueueCount = null;
        this.termConditionId = null;
        this.heightTCText = null;
    }

    constructor(private dialog: MatDialog,
        private commonSvc: CommonService,
        private commonDataSvc: CommonDataService,
        private memberSvc: MemberService,
        private sanitizer: DomSanitizer,
        private roleSvc: RolePermissionService,
        private modal: NgbModal,
        private router: Router,
        private cdRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.isFromAustralia = this.commonSvc.getUserCountry() !== this.commonString.NEWZEALAND;       
        this.loadContentTC();
    }

    ngAfterViewChecked() {
        if (this.disablechkTermCons) {
            var termscondObj = document.getElementById('fp-termscondText');
            var termscondContObj = document.getElementById('fp-tcContent');
            if (termscondObj) {
                if (termscondContObj.innerHTML && this.checkscroll) {
                    if (termscondObj.scrollHeight == termscondObj.offsetHeight) {
                        this.disablechkTermCons = false;
                    }
                }
            }
            if (!this.heightTCText) {
                this.setHeightContent();
            }
        }

        if (this.disablechkKeyTermCons) {
            var termscondObj = document.getElementById("fp-keyTermscondText");
            var termscondContObj = document.getElementById("fp-KeytcContent");
            if (termscondObj) {
              if (termscondContObj.innerHTML && this.keyCheckscroll) {
                if (termscondObj.scrollHeight == termscondObj.offsetHeight) {
                  this.disablechkKeyTermCons = false;
                }
              }
            }
            if (!this.heightKeyTCText) {
              this.keySetHeightContent();
            }
          }

        this.cdRef.detectChanges();
    }

    public Invoke(source: Observable<any>, handleResultCallback: Function) {
        this.requestQueueCount++;
        this.commonSvc.StartGlobalProgressBar();
        source.pipe(
            catchError((e) => { throw e; }),
            finalize(() => {
                this.requestQueueCount--;
                if (this.requestQueueCount <= 0) {
                    this.commonSvc.StopGlobalProgressBar();
                    this.requestQueueCount = 0;
                }
            }))
            .subscribe(
                res => { handleResultCallback(res); }
            );
    }

    //Author: Yen
    loadContentTC() {
        const countryId = this.isFromAustralia ? this.countriesId[0].CountryId : this.countriesId[1].CountryId;        
        
        this.Invoke(
            this.commonDataSvc.getAllTermsAndConditions(),
            (result: DataResult<NewTermsAndConditions[]>) => {
                if (result.Success) {
                    const secondaryTermData = result.Data.filter(term => term.CountryCode === countryId && term.TermConditionType === CommonConstants.SECONDARY_TERMS)[0];                     
                    this.currentTermsAndConditions = secondaryTermData;
                    this.termsAndConsContent = this.sanitizer.bypassSecurityTrustHtml(this.currentTermsAndConditions.Content);
                    this.termConditionId = secondaryTermData.TermConditionId;
                    this.modal.open(this.dlgTermsCons, { windowClass: 'membersignup-dialog termconditions-dialog', backdrop: 'static' });
                    this.checkscroll = true;
                } else {
                    MessageBox.ShowError(this.dialog, 'An error occurred while trying to call a service');
                    console.error(result);
                }
            }
        );
    }

    loadContentKeyTC() {      
        const countryId = this.isFromAustralia ? this.countriesId[0].CountryId : this.countriesId[1].CountryId; 
        this.Invoke(
            this.commonDataSvc.getAllTermsAndConditions(),
            (result: DataResult<NewTermsAndConditions[]>) => {
                if (result.Success) {
                    this.fadeMainTerm = true;
                    const secondaryKeyTermData = result.Data.filter(term => term.CountryCode === countryId && term.TermConditionType === CommonConstants.SECONDARY_KEY_TERMS)[0];
                    this.currentKeyTermsAndConditions = secondaryKeyTermData;
                    this.keyTermsAndConsContent = this.sanitizer.bypassSecurityTrustHtml(this.currentKeyTermsAndConditions.Content);
                    this.keyTermConditionId = secondaryKeyTermData.TermConditionId;
                    this.modal.open(this.dlgKeyTermsCons, { windowClass: 'selectPackage-add-member-dialog', backdrop: 'static' });
                    this.keyCheckscroll = true;
                } else {
                    MessageBox.ShowError(this.dialog, 'An error occurred while trying to call a service');
                    console.error(result);
                }
            }
        );
    }

    public onScroll() {
        if (this.disablechkTermCons) {
            this.checkScrollEndContent();
        }
    }

    public keyTermOnScroll() {
        if (this.disablechkKeyTermCons) {
          this.checkScrollEndKeyTermContent();
        }
      }

    public onResize() {
        this.setHeightContent();
        this.keySetHeightContent();
    }

    public checkScrollEndContent() {
        var termscondObj = document.getElementById('fp-termscondText');
        var termscondContObj = document.getElementById('fp-tcContent');
        var termscondEndContObj = document.getElementById('fp-tcEndContent');
        if (termscondContObj) {
            if (termscondContObj.innerHTML && this.checkscroll && this.disablechkTermCons) {
                if (termscondEndContObj.getBoundingClientRect().bottom < termscondObj.getBoundingClientRect().bottom) {
                    this.disablechkTermCons = false;
                    this.checkscroll = false;
                }
            }
        }
    }

    public checkScrollEndKeyTermContent() {
        var termscondObj = document.getElementById("fp-keyTermscondText");
        var termscondContObj = document.getElementById("fp-KeytcContent");
        var termscondEndContObj = document.getElementById("fp-KeytcEndContent");
    
        if (termscondContObj) {
          if (
            termscondContObj.innerHTML &&
            this.keyCheckscroll &&
            this.disablechkKeyTermCons
          ) {
            if (
              termscondEndContObj.getBoundingClientRect().bottom <
              termscondObj.getBoundingClientRect().bottom
            ) {
    
              this.disablechkKeyTermCons = false;
              this.keyCheckscroll = false;
            }
          }
        }
      }

    public setHeightContent() {
        var termscondContObj = document.getElementById('fp-tcContent');
        if (termscondContObj) {
            this.heightTCText = (window.innerHeight - document.getElementById('modalheader').offsetHeight
                - document.getElementById('chkTC').offsetHeight - document.getElementById('btnTC').offsetHeight) + 'px';
        }
    }

    public keySetHeightContent() {
        var termscondContObj = document.getElementById("fp-KeytcContent");
        if (termscondContObj) {
          this.heightKeyTCText =
            window.innerHeight -
            document.getElementById("keyModalheader").offsetHeight -
            document.getElementById("keyChkTC").offsetHeight -
            document.getElementById("keyBtnTC").offsetHeight +
            "px";
        }
      }

      keyTermModalBack(e) {
        e.dismiss();
        this.fadeMainTerm = false;
        this.disablechkKeyTermCons = true;   
        this.isKeyTermsAndConsAccepted = false;    
      }

    //Author: Yen
    btnSubmit_Click() {
        let acceptTC: AcceptTermsAndConditions;
        acceptTC = {};
        acceptTC.MemberId = +this.commonSvc.D_FP_AES256ForShortString(localStorage.getItem('memberId'));
        acceptTC.TermConditionId = this.termConditionId;
        this.Invoke(
            this.memberSvc.acceptTermAndCondition(acceptTC),
            (res: ResultModel) => {
                if (res.Success) {
                    this.router.navigate([RouterConstant.NAVIGATOR_MEMBER_DETAILS]);
                    this.modal.dismissAll();
                } else {
                    console.error(res);
                }
            }
        );
    }

    //Author: Yen
    btnBack() {
        this.roleSvc.RolesObject = [];
        this.commonSvc.SignOut();
    }

}
