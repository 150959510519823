<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Training Material</h4>
<div class="tabFPTheme mb-5" #stepperContainer>
    <div>
        <form [formGroup]="MaterialFormGroup">
            <ngb-accordion activeIds="pnlUNTrainingMaterial, pnlVETrainingMaterial" [destroyOnHide]="false">
                <ngb-panel id="pnlUNTrainingMaterial" *ngIf="CanAddnew()">
                    <ng-template ngbPanelTitle>
                        <i class="icon-fp fas fa-check"></i>
                        Upload New Training Material
                        <i class="fas fas fa-chevron-down"></i>
                        <i class="fas fas fa-chevron-up"></i>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="form-group row">
                            <label for="ddlRecipient" class="col-md-3 col-lg-2 col-form-label">Recipient(s)<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 col-lg-5">
                                <div class="d-flex flex-column w-100">
                                    <select
                                        [ngClass]="SetClassForControlManually(MaterialFormGroup,'ddlRecipient',MaterialRecipientSelected.length === 0)"
                                        id="ddlRecipient" formControlName="ddlRecipient"
                                        (change)="ddlRecipient_Select($event)">
                                        <option value="" selected>Please select recipient(s)</option>
                                        <option *ngFor="let mr of MaterialRecipient" [value]="mr.Id"
                                            [disabled]="SetMRDisabled(mr)">
                                            {{mr.Name}}
                                        </option>
                                    </select>
                                    <div class="mt-2 slctSelectedRoles w-100 border-2 rounded-10 border-default text-default"
                                        *ngIf="MaterialRecipientSelected.length>0">
                                        <div class="d-flex border-default border-bottom py-1 align-content-center"
                                            *ngFor="let item of MaterialRecipientSelected">
                                            <span class="text-primary d-flex align-items-center"
                                                (click)="UnsetMRSelected(item)">
                                                <i class="fas fa-minus-circle fa-2x"></i>
                                            </span>
                                            <p class="d-flex align-items-center m-0 ml-2">{{item.Name}}</p>
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="IsControlInvalidManually(MaterialFormGroup,'ddlRecipient',MaterialRecipientSelected.length === 0)">
                                        <small class="invalid-feedback d-block">Material Type is required</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="ddlMaterialType" class="col-md-3 col-lg-2 col-form-label">Material Type<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 col-lg-5">
                                <div class="d-flex flex-column w-100">
                                    <select [ngClass]="SetClassForControl(MaterialFormGroup,'ddlMaterialType')"
                                        id="ddlMaterialType" formControlName="ddlMaterialType">
                                        <option value="" selected>Please select type of material</option>
                                        <option *ngFor="let mt of Materialtype" [value]="mt.Id">
                                            {{mt.Name}}
                                        </option>
                                    </select>
                                    <div *ngIf="IsControlInvalid(MaterialFormGroup,'ddlMaterialType')">
                                        <small *ngIf="MaterialFormGroup.get('ddlMaterialType').hasError('required')"
                                            class="invalid-feedback d-block">Material Type is required</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="txtNameDocVideo" class="col-md-3 col-lg-2 col-form-label">Name of
                                Document/Video<span class="text-danger">*</span></label>
                            <div class="col-md-8 col-lg-5">
                                <div class="d-flex flex-column w-100">
                                    <input [ngClass]="SetClassForControl(MaterialFormGroup,'txtNameDocVideo')"
                                        id="txtNameDocVideo" type="text" maxlength="100"
                                        formControlName="txtNameDocVideo">
                                    <div *ngIf="IsControlInvalid(MaterialFormGroup,'txtNameDocVideo')">
                                        <small *ngIf="MaterialFormGroup.get('txtNameDocVideo').hasError('required')"
                                            class="invalid-feedback d-block">Name of
                                            Document/Video is required</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="txtDescription" class="col-md-3 col-lg-2 col-form-label">Description</label>
                            <div class="col-md-8 col-lg-8">
                                <div class="d-flex flex-column w-100">
                                    <textarea class="form-control" id="txtDescription" maxlength="1000" rows="3"
                                        formControlName="txtDescription"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="MaterialFormGroup.get('ddlMaterialType').value==='2'">
                            <label for="txtYouTube" class="col-md-3 col-lg-2 col-form-label">YouTube Link to
                                Video<span class="text-danger">*</span></label>
                            <div class="col-md-6 col-lg-5">
                                <div class="d-flex flex-column w-100">
                                    <input
                                        [ngClass]="SetClassForControlManually(MaterialFormGroup,'txtYouTube',MaterialFormGroup.get('txtYouTube').value === '' || MaterialFormGroup.get('txtYouTube').value === null)"
                                        id="txtYouTube" type="text" maxlength="150" formControlName="txtYouTube">
                                    <div
                                        *ngIf="IsControlInvalidManually(MaterialFormGroup,'txtYouTube',MaterialFormGroup.get('txtYouTube').value === '' || MaterialFormGroup.get('txtYouTube').value === null)">
                                        <small class="invalid-feedback d-block">YouTube Link to Video is
                                            required</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="MaterialFormGroup.get('ddlMaterialType').value==='1'">
                            <label for="txtDocFile" class="col-md-3 col-lg-2 col-form-label">Document File Name<span
                                    class="text-danger">*</span></label>
                            <div class="col-md-6 col-lg-5">
                                <div class="d-flex flex-column w-100">
                                    <input
                                        [ngClass]="SetClassForControlManually(MaterialFormGroup,'txtDocFile',MaterialFormGroup.get('txtDocFile').value === '' || MaterialFormGroup.get('txtDocFile').value === null)"
                                        id="txtDocFile" type="text" maxlength="100" readonly
                                        formControlName="txtDocFile">
                                    <div
                                        *ngIf="IsControlInvalidManually(MaterialFormGroup,'txtDocFile',MaterialFormGroup.get('txtDocFile').value === '' || MaterialFormGroup.get('txtDocFile').value === null)">
                                        <small class="invalid-feedback d-block">Document File Name is
                                            required</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3 pl-0 ml-0">
                                <div class="d-flex flex-column w-100">
                                    <div class="custom-file w-170px">
                                        <div>
                                            <app-fp-file-upload filterFile=".pdf,.doc,.docx,.xls,.xlsx,.csv"
                                                #txtDocUpload maxfilesize="100" buttonlabel="Select Document"
                                                folderfile="Training/"
                                                (UploadCompleted)="txtDocUpload_UploadCompleted($event)"
                                                (fileSelected)="txtDocUpload_Selected($event)">
                                            </app-fp-file-upload>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" id="btnSubmit"
                                (click)="btnSubmit_Click()">
                                <i class="fas fa-check mr-2"></i>Submit</button>
                        </div>
                    </ng-template>
                </ngb-panel>
                <ngb-panel id="pnlVETrainingMaterial" *ngIf="CanView()">
                    <ng-template ngbPanelTitle>
                        <i class="icon-fp fas fa-check"></i>
                        View Existing Training Material
                        <i class="fas fas fa-chevron-down"></i>
                        <i class="fas fas fa-chevron-up"></i>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="form-group row" *ngIf="IsFEorFSM()===false">
                            <label for="ddlRecipient1" class="col-md-3 col-lg-2 col-form-label">Recipient(s)</label>
                            <div class="col-md-8 col-lg-5">
                                <div class="d-flex flex-column w-100">
                                    <select class="form-control" id="ddlRecipientFilter"
                                        formControlName="ddlRecipientFilter" (change)="FilterChange()">
                                        <option value="" selected>Filter by Recipient(s)</option>
                                        <option *ngFor="let mr of MaterialRecipient" [value]="mr.Id">
                                            {{mr.Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="ddlMaterialType1" class="col-md-3 col-lg-2 col-form-label">Material Type</label>
                            <div class="col-md-8 col-lg-5">
                                <div class="d-flex flex-column w-100">
                                    <select class="form-control" id="ddlMaterialTypeFilter"
                                        formControlName="ddlMaterialTypeFilter" (change)="FilterChange()">
                                        <option value="" selected>Filter by Material Type</option>
                                        <option *ngFor="let mt of Materialtype" [value]="mt.Id">
                                            {{mt.Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="border-default rounded-10 border-2 w-100">
                                <div class="tablecontainer">
                                    <table mat-table [dataSource]="dtmatMD" class="rounded-10" matSort>

                                        <!-- Name Column -->
                                        <ng-container matColumnDef="Name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                                            <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                                        </ng-container>

                                        <!-- Type Column -->
                                        <ng-container matColumnDef="MaterialTypeName">
                                            <th mat-header-cell *matHeaderCellDef>Type</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.MaterialTypeName}} </td>
                                        </ng-container>

                                        <!-- Recipient Column -->
                                        <ng-container matColumnDef="MaterialRecipientName">
                                            <th mat-header-cell *matHeaderCellDef>Recipient</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{element.MaterialRecipientName}} </td>
                                        </ng-container>

                                        <!-- Description Column -->
                                        <ng-container matColumnDef="Description">
                                            <th mat-header-cell *matHeaderCellDef>Description</th>
                                            <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
                                        </ng-container>

                                        <!-- Date Uploaded Column -->
                                        <ng-container matColumnDef="DateUpload">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                [hidden]="CanAddnew()==false">Date Uploaded</th>
                                            <td mat-cell *matCellDef="let element" [hidden]="CanAddnew()==false">
                                                {{element.DateUploadDisplay}} </td>
                                        </ng-container>

                                        <!-- Link Column -->
                                        <ng-container matColumnDef="Link">
                                            <th mat-header-cell *matHeaderCellDef style="width: 50px;">Link</th>
                                            <td mat-cell *matCellDef="let element" style="width: 50px;">
                                                <div *ngIf="element.MaterialTypeId===1">
                                                    <a class="px-2" [href]="element.Link" target="_blank">
                                                        <i class="fas fa-eye"></i>
                                                    </a>
                                                </div>
                                                <div *ngIf="element.MaterialTypeId===2">
                                                    <a class="px-2" (click)="PopupCenter(element)">
                                                        <i class="fas fa-eye"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="Action">
                                            <th mat-header-cell *matHeaderCellDef style="width: 50px;"
                                                [hidden]="CanAddnew()==false"></th>
                                            <td mat-cell *matCellDef="let element" style="width: 50px;"
                                                [hidden]="CanAddnew()==false">
                                                <a class="px-2" (click)="DeleteMaterial(element)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </a>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                    <mat-toolbar *ngIf="dtmatMD != null && dtmatMD.data.length == 0"
                                        class="text-muted d-block p-2 bg-light text-center">
                                        {{noRecordFoundText}}</mat-toolbar>
                                </div>
                            </div>
                        </div>
                        <ng-template #ContentYouTube let-modal>
                            <div class="modal-header">
                                <h6 class="modal-title" id="modal-basic-title">{{YoutubeName}}</h6>
                                <button type="button" class="close" aria-label="Close" (click)="CloseYouTube()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <iframe width="750" height="415" [src]="YoutubeSrc" frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                        </ng-template>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </form>
    </div>
</div>