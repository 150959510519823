import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StringHelper } from '@fp/helpers';
import { DataResult, Region, SearchFieldOperator, SearchPaginationCriteria, SearchPaginationParam, SearchPaginationRequest, SearchPaginationResult, ServiceType, SuburbSearchResultRecord } from '@fp/models';
import { empty, Observable, of } from 'rxjs';
import { catchError, debounceTime, finalize, map, switchMap } from 'rxjs/operators';
import { RouterConstant } from 'src/app/constant/routerconstant';
import { CommonService } from 'src/app/services/common.service';
import { FacilityService } from 'src/app/services/facility.service';
import { RegionService } from 'src/app/services/region.service';
import { ServiceService } from 'src/app/services/service.service';
import { SuburbService } from 'src/app/services/suburb.service';

class _ServiceType {
  id: number;
  name: string;
  displayOrder?: number;
  isActive?: boolean
}

class _Common {
  constructor(
    public id: number = 0,
    public name: string = ''
  ) { }
}

class _AutocompleteOption extends _Common {
  suggestionText: string;
}

class _Facilities {
  constructor(
    public data: _Common[] = new Array<_Common>(),
    public totalItem: number = 0
  ) { }
}

@Component({
  selector: 'app-searchfacility',
  templateUrl: './searchfacility.component.html',
  styleUrls: ['./searchfacility.component.css']
})

export class SearchfacilityComponent implements OnInit {
  searchFacilityFG: UntypedFormGroup;
  serviceTypes: Array<_ServiceType>;
  requestQueueCount: number = 0;
  filter: string;
  paging: SearchPaginationRequest = new SearchPaginationRequest();
  private suburbList: SuburbSearchResultRecord[];
  regions: _Common[];
  suburbs: _AutocompleteOption[];
  filterSpinner: boolean = false;
  routerFacilitiesView = '/' + RouterConstant.ROUTER_DASHBOARD + '/' + RouterConstant.ROUTER_FACILITIES_VIEW;
  routerFacilityEdit = RouterConstant.NAVIGATOR_FACILITY_HOME_EDIT;
  parentFocus: boolean = false;
  facilityName: string;

  get regionFilter() {
    return this.searchFacilityFG.controls['region_id'];
  }
  get suburbFilter() {
    return this.searchFacilityFG.controls['suburb_id'];
  }
  get serviceTypeFilter() {
    return this.searchFacilityFG.controls['service_type_id'];
  }

  filterFG(filter: string) {
    return this.searchFacilityFG.controls[filter];
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    protected commonService: CommonService,
    private serviceService: ServiceService,
    private router: Router,
    private regionService: RegionService,
    private facilityService: FacilityService,
    private suburbService: SuburbService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.commonService.Header.title="View Facility";
    this.paging.Params = [new SearchPaginationParam()];
    this.filter = 'all';
    this.createForm();
    this.commonService.getLocationData().subscribe(
        (res) => {
            if (res.Success) {
                this.suburbList = res.Data.suburbs;
            }
        }
    );
  }

  createForm() {
    this.searchFacilityFG = this.formBuilder.group({
      filter: [0],
      region_id: [''],
      suburb_id: [''],
      service_type_id: [0]
    });
  }

  public removeValidators(form: UntypedFormGroup) {
    for (const key in form.controls) {
      form.get(key).clearValidators();
      form.get(key).updateValueAndValidity();
    }
  }

  public addValidator(control: AbstractControl, validators: ValidatorFn[], value: any) {
    //set default value when click this again.
    control.setValue(value);
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  changeFilter(event) {
    this.removeValidators(this.searchFacilityFG);
    this.filter = 'all';
    switch (+event) {
      //view ficilities by region
      case 1: {
        this.filter = 'region_id';
        this.addValidator(this.regionFilter, [Validators.required, this.ValidateObject], '');
        break;
      }
      //view ficilities by suburb
      case 2: {
        this.filter = 'suburb_id';
        this.addValidator(this.suburbFilter, [Validators.required, this.ValidateObject], '');
        break;
      }
      //view ficilities by service type
      case 3: {
        this.filter = 'service_type_id';
        if (this.serviceTypes == null || this.serviceTypes == undefined)
          this.LoadServiceTypes();
        this.addValidator(this.serviceTypeFilter, [Validators.required, this.ValidateObject], 0);
        break;
      }
      default:
        break;
    }
  }

  /**
   * custom validatiom for object
   * @param control
   */
  ValidateObject(control: AbstractControl) {
    if ((typeof control.value) == 'object') {
      return null
    }
    return { validUrl: true };
  }

  displayFn(item?: _Common) {
    return item ? item.name : null;
  }

  Invoke(source: Observable<any>, handleResultCallback: Function, mapCallback: Function = () => { }) {
    this.requestQueueCount++;
    this.commonService.StartProgressBar();
    source.pipe(
      catchError((e) => { throw e; }),
      finalize(() => {
        this.requestQueueCount--;
        if (this.requestQueueCount <= 0) {
          this.commonService.StopProgressBar();
          this.requestQueueCount = 0;
        }
      }),
      map(data => {
        return mapCallback(data);
      }),
    )
      .subscribe(
        res => {
          handleResultCallback(res);
        },
        err => {
          console.log(err);
        }
      );
  }

  ngAfterViewInit() {
    this.filterFG('region_id').valueChanges.pipe(
      debounceTime(500),
      switchMap(
        () => {
          if (this.filterFG('region_id').value != null && this.filterFG('region_id').value.length >= 3) {
            this.filterSpinner = true;
            this.paging.Params[0].Name = 'name';
            //#region no limit pageSize
            this.paging.PageSize = 0;
            this.paging.PageNumber = 0;
            //#endregion no limit pageSize
            this.paging.Params[0].Value = this.filterFG('region_id').value;
            return this.regionService.search(this.paging);
          } else {
            this.filterSpinner = false;
          }

          // let dataResult: DataResult<SearchPaginationResult<Facility>> = new DataResult<SearchPaginationResult<Facility>>();
          let dataResult: any = {
            Data: {
              Results: []
            }
          };
          return of(dataResult);
        }
      ),
      map((data: DataResult<SearchPaginationResult<Region>>) => {
        this.filterSpinner = false;
        if (data.Success == false) {
          return new Array<_Common>();
        }
        let _regions: _Common[] = new Array<_Common>();

        data.Data.Results.map((r: Region) => {
          const _f: _Common = {
            id: r.RegionId,
            name: r.Name
          }
          _regions.push(_f);
        });
        return _regions;
      }),
      catchError((e) => {
        this.filterSpinner = false;
        throw e;
      })
    ).subscribe(
      data => {
        this.regions = data;
      }
    );

    this.filterFG('suburb_id').valueChanges.pipe(
      debounceTime(500),
      switchMap(
        (value) => {
          this.filterSpinner = false;
          this.suburbs = [];
          if (typeof value === 'string' && value && value.trim().length >= 3) {
            this.filterSpinner = true;
            return value;
          }

          return empty();
        }
      ),
      catchError((e) => { throw e; })
    ).subscribe(
      res => {
        this.filterSpinner = false;
        let filterSuburb = this.suburbList.filter(x => x.Name.toLowerCase().trim().includes(this.filterFG('suburb_id').value.toLowerCase().trim()) || x.Postcode.Name.includes(this.filterFG('suburb_id').value.toLowerCase().trim()));

          this.suburbs = filterSuburb.map(_item => <_AutocompleteOption>{
            id: _item.Id,
            name: _item.Name + ' (' + _item.Postcode.Name + ')',
            suggestionText: StringHelper.format('{0}, {1}, {2}', _item.Name, _item.State.Name, _item.Postcode.Name)
          });
      },
      err => { this.filterSpinner = false; console.error(err); }
    );

    // this.filterFG('suburb_id').valueChanges.pipe(
    //   debounceTime(500),
    //   switchMap(
    //     (value) => {
    //       this.filterSpinner = false;
    //       this.suburbs = [];
    //       if (typeof value === 'string' && value && value.length >= 3) {
    //         this.filterSpinner = true;
    //         const criteria = new SearchPaginationCriteria();
    //         criteria.PageSize = 0;
    //         criteria.ViewColumns = ['name', 'state', 'postcode'];
    //         criteria.Criteria.Fields = [{ Name: 'name_or_postcode', Operator: SearchFieldOperator.Contains, Value: this.filterFG('suburb_id').value }];
    //         return this.suburbService.advancedSearch(criteria);
    //       }

    //       return empty();
    //     }
    //   ),
    //   catchError((e) => { throw e; })
    // ).subscribe(
    //   res => {
    //     this.filterSpinner = false;
    //     if (res.Success) {
    //       this.suburbs = res.Data.Results.map(_item => <_AutocompleteOption>{
    //         id: _item.Id,
    //         name: _item.Name + ' (' + _item.Postcode.Name + ')',
    //         suggestionText: StringHelper.format('{0}, {1}, {2}', _item.Name, _item.State.Name, _item.Postcode.Name)
    //       });
    //     } else {
    //       console.error(res);
    //     }
    //   },
    //   err => { this.filterSpinner = false; console.error(err); }
    // );
  }

  LoadServiceTypes() {
    this.Invoke(
      this.serviceService.getTypes(),
      (data: _ServiceType[]) => {
        if (data != null) {
          this.serviceTypes = data;
        } else {
          console.error(data);
        }
      },
      (data: DataResult<ServiceType[]>) => {
        if (data.Success == false) {
          console.log(data.Message);
          return [];
        }

        let st: _ServiceType[] = [];
        data.Data.map((_serviceType: ServiceType) => {
          if (_serviceType.IsActive == true) {
            const _st: _ServiceType = {
              id: _serviceType.Id,
              name: _serviceType.Name
            }
            st.push(_st);
          }
        })
        return st;
      }
    );
  }

  findFacilities() {
    let value: string;
    if (this.filter != 'all')
      value = this.filterFG(this.filter).value.id;
    else
      value = '';

    this.router.navigate([this.routerFacilitiesView], {
      queryParams: {
        value: value,
        filter: this.filter,
        facilityName: this.facilityName
      },
      skipLocationChange: false
    });
  }
  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }
  onBlurParent(focusNumbr) {
    this.parentFocus = focusNumbr;
  }
  getClassFocused(vlFocused) {
    if (this.parentFocus == vlFocused) {
      return "focused";
    } else {
      return "";
    }
  }
}