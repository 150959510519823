<div class="input-container" #facilitySelect>
    <i class="icon fa fa-search"
        [ngClass]="{'invalid-icon': !ngControl.valid && facilityInput.dirty && facilityInput.touched}"></i>
    <input class="form-control" (blur)="checkValidSelection()"
        [class.is-invalid]="!ngControl.valid && facilityInput.dirty && facilityInput.touched" (keyup)="HandleKeyUp($event)"
        type="text" placeholder="Search a Facility by Name" [formControl]="facilityInput" matInput
        [matAutocomplete]="autoSuburb">
    <div *ngIf="!ngControl.valid && facilityInput.dirty && facilityInput.touched">
        <small *ngIf="ngControl.getError('unresolved'); else notEquals" class="invalid-feedback d-block">The
            facility does not exist</small>
    </div>
    <mat-autocomplete id="autoSuburb" name="autoMapSuburb" #autoSuburb="matAutocomplete" [displayWith]="getOptionText"
        (optionSelected)="SelectValue($event.option.value)">
        <mat-option *ngFor="let wp of filteredFacilities" [value]="wp" class="border-default border-bottom py-1">
            <p class="m-0 d-flex align-items-center"> {{wp.Name}}, {{wp.Suburb?.Name}} {{wp.State.Name}}</p>
        </mat-option>
    </mat-autocomplete>
</div>