<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">User Roles</h4>
<div class="mt-lg-4">
    <div class="my-4">
        <div class="border-default rounded-10 border-2 p-1 fp-tablefrozen">
            <p-table [columns]="scrollableCols" [frozenColumns]="frozenCols" [value]="IEPrivilege" [scrollable]="true"
                scrollHeight="400px" frozenWidth="360px">
                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col *ngFor="let col of columns" style="width:100px">
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" style="height: 84px;width:180px">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr style="height: 84px;">
                        <td *ngFor="let col of columns">
                            <div *ngIf="col.field!='Category' && col.field!='PrivilegeDisplayName'"
                                class="custom-control custom-checkbox p-0 m-0">
                                <input type="checkbox" class="custom-control-input" [(ngModel)]="rowData[col.field]"
                                    (change)="CheckBox_Change(rowData)">
                                <label class="custom-control-label">&nbsp;</label>
                            </div>
                            <div *ngIf="col.field=='Category'">
                                {{rowData['Category']}}
                            </div>
                            <div *ngIf="col.field=='PrivilegeDisplayName'">
                                {{rowData['PrivilegeDisplayName']}}
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <button class="px-5 py-2 btn btn-primary text-light" type="button" (click)="UpdateAllPrivilege()">Save</button>
</div>