import { Component, Injector, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CommonString } from '@fp/constant';
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { FacilityFormBase } from 'src/app/components/base';
import { FPValidators, NgbTimeSpanStringAdapter, StringHelper } from 'src/app/helpers';

@Component({
    selector: 'app-facility-important-information',
    templateUrl: './facility-important-information.component.html',
    styleUrls: ['./facility-important-information.component.css'],
    providers: [
        { provide: FacilityFormBase, useExisting: FacilityImportantInformationComponent },
        { provide: NgbTimeAdapter, useClass: NgbTimeSpanStringAdapter }
    ]
})
export class FacilityImportantInformationComponent extends FacilityFormBase {
    parentFocus = 0;
    get StaffedHoursGrp() { return <UntypedFormGroup>this.form.get('FacilityStaffedHour'); }
    @ViewChild('chkStaffedHours') chkStaffedHours: ElementRef;
    @ViewChild('accessCardFee') accessCardFee: ElementRef;
    @ViewChild('importantTemplate') importantTemplate: ElementRef;
    StringHelper = StringHelper;
    CommonString = CommonString;

    static getFormGroup(): UntypedFormGroup {
        const fb = new UntypedFormBuilder();
        return fb.group({
            IsAccessCard: [false],
            AccessCardFee: [null],
            SoftwarePackage: [null],
            Is24Hour: [false],
            FacilityStaffedHourId: [null],
            FacilityStaffedHour: [null],
            VisitDuplicationWindow: [120],
            AdditionalInfo: [null]
        });
    }

    static getStaffedHoursFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            FacilityStaffedHourId: [null],
            MondayFrom: [null], MondayTo: [null],
            TuesdayFrom: [null], TuesdayTo: [null],
            WednesdayFrom: [null], WednesdayTo: [null],
            ThursdayFrom: [null], ThursdayTo: [null],
            FridayFrom: [null], FridayTo: [null],
            SaturdayFrom: [null], SaturdayTo: [null],
            SundayFrom: [null], SundayTo: [null]
        });
    }

    constructor(injector: Injector) { super(injector); }

    public PatchValue(value: { [key: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
        this.toggleStaffedHoursFormGroup(value['FacilityStaffedHour'] != null);
        super.PatchValue(value, options);
    }

    toggleStaffedHoursFormGroup(enabled) {
        if (enabled) {
            this.form.setControl('FacilityStaffedHour', FacilityImportantInformationComponent.getStaffedHoursFormGroup());
            const ctrl = this.StaffedHoursGrp;
            this.setTimeRangeValidators(ctrl.get('MondayFrom'), ctrl.get('MondayTo'));
            this.setTimeRangeValidators(ctrl.get('TuesdayFrom'), ctrl.get('TuesdayTo'));
            this.setTimeRangeValidators(ctrl.get('WednesdayFrom'), ctrl.get('WednesdayTo'));
            this.setTimeRangeValidators(ctrl.get('ThursdayFrom'), ctrl.get('ThursdayTo'));
            this.setTimeRangeValidators(ctrl.get('FridayFrom'), ctrl.get('FridayTo'));
            this.setTimeRangeValidators(ctrl.get('SaturdayFrom'), ctrl.get('SaturdayTo'));
            this.setTimeRangeValidators(ctrl.get('SundayFrom'), ctrl.get('SundayTo'));
        } else {
            this.form.setControl('FacilityStaffedHour', new UntypedFormControl(null));
        }
    }

    private setTimeRangeValidators(ctrl1: AbstractControl, ctrl2: AbstractControl) {
        ctrl1.setValidators([FPValidators.requiredWhen(() => ctrl2.value != null)]);
        ctrl1.valueChanges.subscribe(value => { ctrl2.updateValueAndValidity({ onlySelf: true, emitEvent: false }); });
        ctrl2.setValidators([FPValidators.requiredWhen(() => ctrl1.value != null)]);
        ctrl2.valueChanges.subscribe(value => { ctrl1.updateValueAndValidity({ onlySelf: true, emitEvent: false }); });
    }

    isTimeCtrlValid(name: string, dependentCtrlName: string) {
        return !(!this.isControlValid(this.StaffedHoursGrp.get(name)) && (this.StaffedHoursGrp.get(dependentCtrlName).dirty || this.validatedOnce));
    }

    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }
    onBlurParent(focusNumbr) {
        this.parentFocus = focusNumbr;
    }
    getClassFocused(vlFocused) {
        if (this.parentFocus == vlFocused) {
            return "focused";
        } else {
            return "";
        }
    }
}
