import { Component, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { RouterConstant } from "@fp/constant";
import { MFAType, MFATypeValues } from "@fp/models/user.model";
import { CommonService } from "@fp/services";

@Component({
  selector: "app-security-preferences",
  templateUrl: "./security-preferences.component.html",
  styleUrls: ["./security-preferences.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class SecurityPreferencesComponent {
  cognitoUserState: "VALID" | "HAS_PHONE_NUMBER_REQUIRES_VERIFICATION" | "HAS_NO_PHONE_NUMBER" | "UNEXPECTED_STATE"
  selectedOption: MFATypeValues;
  previousOption: MFATypeValues | null = null;
  existingMFA = {
    email: '',
    sms: '',
  }
  isUpdatingMFAPreference: boolean = false;

  constructor(
    public commonService: CommonService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getCognitoUserState();
    this.getPrefferedMfaMethod();
    this.getEmailAndPhone();
  }

  get emailOptionLabel(): string {
    if (this.previousOption === MFAType.EMAIL) {
      return `You’re currently receiving codes to <b>${this.existingMFA.email}</b>`;
    } else {
      return `Set email as multi-factor authentication to receive codes to <b>${this.existingMFA.email}</b>`;
    }
  }

  get smsOptionLabel(): string {
    if (this.previousOption === MFAType.SMS) {
      if (this.selectedOption === MFAType.SMS) {
        return `You’re currently receiving codes to `; // The mobile is populated as a clickable button in the template file
      } else {
        return `You’re currently receiving codes to <b>${this.existingMFA.sms}</b>`;
      }
    } else {
      if (this.selectedOption === MFAType.SMS) {
        return `Set mobile as multi-factor authentication to receive codes to `; // The mobile is populated as a clickable button in the template file
      } else {
        return `Set mobile as multi-factor authentication to receive codes to <b>${this.existingMFA.sms}</b>`
      }
    }
  }

  get showUpdatePhone(): boolean {
    return this.selectedOption === "SMS"
  }

  get showUnexpectedStateAlert(): boolean {
    return this.cognitoUserState === "UNEXPECTED_STATE"
  }

  get isSaveDisabled(): boolean {
    return this.selectedOption === this.previousOption || this.isUpdatingMFAPreference;
  }

  public onOptionChange(value: MFATypeValues) {
    this.selectedOption = value;
  }

  public async onSaveClick() {
    this.isUpdatingMFAPreference = true;
    await this.commonService.updateCurrentUserPrefferedMFA(this.selectedOption)
    await this.getPrefferedMfaMethod();
    this.isUpdatingMFAPreference = false;
  }

  public navigateToSecurityPreferencesUpdatePhone() {
    this.router.navigate([RouterConstant.NAVIGATOR_MEMBER_SECURITY_PREFERENCES_UPDATE_PHONE]);
  }

  private async getCognitoUserState() {
    this.cognitoUserState = await this.commonService.getCurrentUserCognitoState()
    if (this.cognitoUserState === "HAS_PHONE_NUMBER_REQUIRES_VERIFICATION" || this.cognitoUserState === "HAS_NO_PHONE_NUMBER") {
      this.router.navigate([RouterConstant.NAVIGATOR_MEMBER_SECURITY_PREFERENCES_UPDATE_PHONE]);
    }
  }

  private async getPrefferedMfaMethod() {
    const preferredMFA = await this.commonService.getCurrentUserPrefferedMFA()
    if (preferredMFA === "SMS" || preferredMFA === "EMAIL") {
      this.previousOption = preferredMFA;
      this.selectedOption = preferredMFA;
    }
  }

  private async getEmailAndPhone() {
    const { email, phone } = await this.commonService.getCurrentUserEmailAndPhone();
    this.existingMFA.email = email;
    this.existingMFA.sms = phone;
  }
}