<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Draft Facility Forms</h4>
<div class="mt-lg-4">
    <form>
        <div class="my-4">
            <div class="border-default rounded-10 border-2">
                <div class="tablecontainer">
                    <!-- Data table material -->
                </div>
            </div>
        </div>
        <button class="px-5 py-2 btn bg-white border-primary text-primary" type="button"><i
                class="fas fa-arrow-alt-circle-left mr-2"></i>BACK</button>
    </form>
</div>