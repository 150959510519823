import { SelectionModel } from '@angular/cdk/collections';
import { Component, Injector, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FPFormBaseComponent, PackageFormBase } from '@fp/components/base';
import { CommonString } from '@fp/constants';
import { EServiceCategory } from '@fp/enums';
import { CommonResponse, DataResult, Region, SearchPaginationParam, SearchPaginationRequest, SearchPaginationResult, ServiceType, State } from '@fp/models';
import { ProductServiceService } from '@fp/services';

@Component({
    selector: 'app-package-service-list',
    templateUrl: './service-list.component.html',
    styleUrls: ['./service-list.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FPFormBaseComponent.provideExisting(PackageServiceListComponent)]
})
export class PackageServiceListComponent extends PackageFormBase {
    paging: SearchPaginationRequest = {
        PageNumber: 1,
        PageSize: 0,
        OrderBy: 'facility',
        OrderByAsc: true,
        Params: [],
        ViewColumns: [
            'service_type',
            'available_for_all_employers',
            'status',
            'facility',
            'region',
            'state']
    };
    selection = new SelectionModel<ServiceFE>(true, []);
    CommonString = CommonString;

    displayedColumns: string[] = ['action', 'facilityName', 'name', 'type', 'peakStatus', 'availableForAllEmployers', 'status', 'state', 'region'];
    dataSource: MatTableDataSource<ServiceFE> = null;
    bogusDataSource = new MatTableDataSource<ServiceFE>(null);
    @ViewChild(MatSort) sort: MatSort;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            ServiceIds: [[]],
            ProductIds: [[]]
        });
    }

    constructor(
        injector: Injector,
        private productServiceSvc: ProductServiceService) {
        super(injector);
    }

    OnLoad() {
        this.dataSource = new MatTableDataSource();
        super.OnLoad();
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
        this.updateForm();
    }

    sortChange(event: Sort) {
        const data = this.dataSource.data;
        const ascending = event.direction === 'desc'; // The sort direction arrow is not rendered properly.

        this.dataSource.data = data.sort((a, b) => {
            const valueA = typeof a[event.active] === 'string' ? a[event.active].toLowerCase() : a[event.active];
            const valueB = typeof b[event.active] === 'string' ? b[event.active].toLowerCase() : b[event.active];
            return (valueA === null ?
                -1 :
                valueB === null ?
                    1 :
                    valueA === valueB ?
                        0 :
                        valueA > valueB ?
                            1 :
                            -1) * (ascending ? 1 : -1);
        });
    }

    updateForm() {
        const serviceIds: Array<number> = [];
        const productIds: Array<number> = [];
        this.selection.selected.forEach(obj => {
            if (obj.serviceCategoryId == EServiceCategory.Service) {
                serviceIds.push(obj.id);
            }
            if (obj.serviceCategoryId == EServiceCategory.Product) {
                productIds.push(obj.id);
            }
        });
        this.form.get('ServiceIds').setValue(serviceIds);
        this.form.get('ProductIds').setValue(productIds);
    }

    RefreshSearch(params?: SearchPaginationParam[],
        opts?: {
            keepSelection?: boolean,
            afterSearchCallback?: () => void
        }) {
        if (params) {
            this.paging.Params = params;
        }
        if (this.paging.Params.length < 1) {
            return;
        }
        opts = Object.assign({ keepSelection: false }, opts);
        this.Invoke(
            this.productServiceSvc.search(this.paging),
            {
                onSuccess: (res: DataResult<SearchPaginationResult<any>>) => {
                    if (res.Success) {
                        const data = this.fetchData(res.Data.Results);
                        this.dataSource.data = data;
                        if (!opts || !opts.keepSelection) {
                            this.selection.clear();
                            this.masterToggle();
                        }
                        if (typeof opts.afterSearchCallback === 'function') {
                            opts.afterSearchCallback();
                        }
                    } else {
                        this.HandleResponseError(res);
                    }
                }
            });
    }

    Clear() {
        this.form.get('ServiceIds').setValue([]);
        this.form.get('ProductIds').setValue([]);
        this.dataSource.data = [];
        this.selection.clear();
    }

    fetchData(data: Array<IProductService>) {
        const productServices: ServiceFE[] = [];
        data.forEach(obj => {
            const productService: ServiceFE = {
                serviceCategoryId: obj.CategoryId,
                id: obj.Id,
                facilityName: obj.Facility ? obj.Facility.Name : null,
                name: obj.Name,
                type: obj.ServiceType ? obj.ServiceType.Name : null,
                peakStatus: obj.IsOffPeakService === null ? null : obj.IsOffPeakService === true ? 'Off-peak' : 'Peak',
                availableForAllEmployers: obj.IsAvailableForAllEmployers,
                status: obj.Status ? obj.Status.Name : null,
                state: obj.State ? obj.State.Name : null,
                region: obj.Region ? obj.Region.Name : null
            };
            productServices.push(productService);
        });

        return productServices;
    }

    Select(selectionOpts: { ServiceIds: number[], ProductIds: number[] }) {
        if (selectionOpts) {
            this.dataSource.data.forEach(row => {
                if ((row.serviceCategoryId === EServiceCategory.Service && selectionOpts.ServiceIds.indexOf(row.id) > -1) ||
                    (row.serviceCategoryId === EServiceCategory.Product && selectionOpts.ProductIds.indexOf(row.id) > -1)) {
                    this.selection.select(row);
                }
            });
        }
    }
}

interface IProductService {
    CategoryId: number;
    Id: number;
    Name: string;
    ServiceType: ServiceType;
    Facility: CommonResponse;
    IsOffPeakService: boolean;
    IsAvailableForAllEmployers: boolean;
    Status: any;
    State: State;
    Region: Region;

}

class ServiceFE {
    serviceCategoryId: number;
    id: number;
    name: string;
    type: string;
    facilityName: string;
    peakStatus: string;
    availableForAllEmployers: boolean;
    status: string;
    state: string;
    region: string;
}
