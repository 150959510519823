<h3
  class="security-preference-component-update-phone-heading font-weight-normal pb-2 text-primary text-center text-lg-left">
  Security Preferences</h3>
<div class="security-preference-component">
  <div *ngIf="showUnexpectedStateAlert; else elseBlock">
    <ngb-alert class="security-preference-component-alert" [dismissible]="false">
      We’re experiencing an issue on our end and can’t update your MFA preferences right now. Please <a
        href="{{commonService.freshdeskNewSupportRequestUrl}}">contact
        support</a> for assistance.
    </ngb-alert>
  </div>
  <ng-template #elseBlock>
    <div>
      <div *ngIf="showAlert">
        <ngb-alert class="security-preference-component-alert" type="info" [dismissible]="false"
          [innerHtml]="alertMessage">
        </ngb-alert>
      </div>

      <label class="continue-label">To continue, add a mobile number that you’d like to include for multi-factor
        authentication.</label>

      <div class="security-preference-component-mfa-phone-submit">
        <div class="form-fields">
          <div class="country">
            <mat-form-field appearance="outline">
              <mat-label>Country</mat-label>
              <select matNativeControl [formControl]="countryCode">
                <option value="+61">Australia (+61)</option>
                <option value="+64">New Zealand (+64)</option>
              </select>
            </mat-form-field>
          </div>

          <div class="phone-number">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Mobile Number</mat-label>
              <input matInput [formControl]="mfaPhone">
              <mat-error *ngIf="!mfaPhone.valid">Invalid mobile number</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="submit">
          <button mat-raised-button color="primary" (click)="onSendCode()" [disabled]="isSendCodeDisabled">Send
            Code</button>
        </div>
      </div>

      <div class="security-preference-component-verify-phone-submit" *ngIf="verifying && !error">
        <hr />
        <div class="form-fields">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Verify Code</mat-label>
            <input matInput [formControl]="verifyCode">
          </mat-form-field>
        </div>

        <div class="submit">
          <button mat-raised-button color="primary" (click)="onVerify()">Verify</button>
        </div>

        <span class="description" *ngIf='verified'>Phone number now verified.</span>
      </div>
    </div>

    <div class="security-preference-component-verify-phone-submit" *ngIf="error">
      <span class="description">{{error}}</span>
    </div>
  </ng-template>
</div>