import { Component, Injector, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FPAbstractComponent } from '@fp/components/base';
import { APIConstant } from '@fp/constant';
import { CommonString } from '@fp/constant/common-string';
import { DataResult, SearchPaginationRequest, SearchPaginationResult } from '@fp/models';
import { AuditBankDetailRecord } from '@fp/models/audit-logging.model';
import { CommonService } from '@fp/services';
import { AuditLoggingService } from '@fp/services/audit-logging.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bulk-upload-postcode-history',
  templateUrl: './bulk-upload-postcode-history.component.html',
  styleUrls: ['./bulk-upload-postcode-history.component.css']
})
export class BulkUploadPostcodeHistoryComponent extends FPAbstractComponent implements OnInit {

  dataSourceHistory: MatTableDataSource<any>;
  bogusDataSource = new MatTableDataSource<any>(null);
  commonString = CommonString;
  displayedColumns: string[];
  pageSizeOptions: number[] = [10, 20, 50];
  pageSize: number;
  length: number;
  paging: SearchPaginationRequest = new SearchPaginationRequest();

  constructor(injector: Injector, private cmsrv: CommonService, private auditLoggingService: AuditLoggingService, public activeModal: NgbActiveModal) { super(injector); }

  ngOnInit(): void {
    this.paging.PageNumber = 1;
    this.paging.PageSize = 10;
    this.displayedColumns = ['User', 'Date']
    this.loadHistoryData();
  }

  paginatorChange(event: PageEvent) {
    this.paging.PageNumber = event.pageIndex + 1;
    this.paging.PageSize = event.pageSize
    this.loadHistoryData();
  }

  loadHistoryData() {
    
    this.cmsrv.StartProgressBar();

    const encryptedtoken = this.cmsrv.E_FP_RequestData(this.cmsrv.GetToken());

    this.paging.Params = [
      { Name: 'EventName', Value: this.commonString.AuditBulkUploadPostCode }
    ];

    const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(this.paging));
    var apiUrl = APIConstant.API_AUDIT_HISTORY;

    this.auditLoggingService.postData(
      apiUrl,
      (data: any) => {
        const rdata: DataResult<SearchPaginationResult<AuditBankDetailRecord>> = data;
        if (rdata != null) {
          if (rdata.Success) {
            this.dataSourceHistory = new MatTableDataSource<AuditBankDetailRecord>(rdata.Data.Results);
            this.length = rdata.Data.TotalItem;
          } else {
            this.dataSourceHistory = new MatTableDataSource<AuditBankDetailRecord>();
            this.HandleResponseError(rdata);
          }
        }

        this.cmsrv.StopProgressBar();
      },
      { "Data": encrypteddata, "Header": encryptedtoken }
    );

  }

}
