<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">View/Edit {{nameMember}}</h4>
<div *ngIf="primaryMemberId">
    <a style="cursor: pointer;" class="font-weight-normal pb-2 text-primary text-center text-lg-left"
        (click)="openLink(primaryMemberId);">
        <b><u>Primary member : {{ primaryMemberId }}</u></b></a>
</div>
<div class="tabFPTheme mb-5" #stepperContainer [style.height]="heighttabs">
    <mat-vertical-stepper #stepper [linear]="SetLinearForTab()" (selectionChange)="stepClick($event)">
        <mat-step [stepControl]="form.get('PersonalInfo')">
            <form id="membertb0">
                <ng-template matStepLabel>
                    <i class="icon-fp d-lg-none" [ngClass]="{
                                'fas fa-check': _stepProgressCompleteIconVisible(MemberEditTab.PersonalDetails),
                                'bg-primary': _stepProgressBackgroundVisible(MemberEditTab.PersonalDetails)}"></i>
                    Personal Details
                </ng-template>
                <app-member-personal-info [loadOnDemand]="true" [form]="form.get('PersonalInfo')" [data]="data"
                    #personalInfo>
                </app-member-personal-info>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
        <mat-step [stepControl]="form.get('EmployerInfo')">
            <form id="membertb1">
                <ng-template matStepLabel>
                    <i class="icon-fp d-lg-none" [ngClass]="{
                        'fas fa-check': _stepProgressCompleteIconVisible(MemberEditTab.EmployerInfo),
                        'bg-primary': _stepProgressBackgroundVisible(MemberEditTab.EmployerInfo)}"></i>
                    Employer
                </ng-template>
                <app-member-employer-info [shouldFetchLocations]="false" [form]="form.get('EmployerInfo')" [data]="primaryMemberSharedData"
                    #employerInfo>
                </app-member-employer-info>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
        <mat-step [stepControl]="form.get('MembershipPackages')">
            <form id="membertb2">
                <ng-template matStepLabel>
                    <i class="icon-fp d-lg-none" [ngClass]="{
                        'fas fa-check': _stepProgressCompleteIconVisible(MemberEditTab.MembershipPackages),
                        'bg-primary': _stepProgressBackgroundVisible(MemberEditTab.MembershipPackages)}"></i>
                    Membership Packages
                </ng-template>
                <app-member-membership-packages [loadOnDemand]="true" [form]="form.get('MembershipPackages')"
                    [data]="primaryMemberSharedData" #membershipPackages></app-member-membership-packages>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
        <mat-step [stepControl]="form.get('FamilyMembersTab')">
            <form id="membertb3">
                <ng-template matStepLabel>
                    <i class="icon-fp d-lg-none" [ngClass]="{
                        'fas fa-check': _stepProgressCompleteIconVisible(MemberEditTab.AdditionalMembers),
                        'bg-primary': _stepProgressBackgroundVisible(MemberEditTab.AdditionalMembers)}"></i>
                    Additional Members
                </ng-template>
                <app-member-family-members [loadOnDemand]="true" [form]="form.get('FamilyMembersTab')"
                    [data]="primaryMemberSharedData" #familyMembers></app-member-family-members>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
        <mat-step [stepControl]="form.get('FamilyMembersTab')">
            <form id="membertb4">
                <ng-template matStepLabel>
                    <i class="fas fa-check icon-fp d-lg-none" [ngClass]="{
                        'fas fa-check': _stepProgressCompleteIconVisible(MemberEditTab.ShoppingCart),
                        'bg-primary': _stepProgressBackgroundVisible(MemberEditTab.ShoppingCart)}"></i>
                    Shopping Cart
                </ng-template>
                <app-member-shopping-cart [loadOnDemand]="true" [form]="form.get('FamilyMembersTab')"
                    [data]="primaryMemberSharedData" #shoppingCart
                    (addFamilyMemberButtonClick)="familyMembers.AddMember()">
                </app-member-shopping-cart>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
        <mat-step [stepControl]="form.get('PaymentDetails')">
            <form id="membertb5">
                <ng-template matStepLabel>
                    <i class="icon-fp d-lg-none" [ngClass]="{
                        'fas fa-check': _stepProgressCompleteIconVisible(MemberEditTab.PaymentDetails),
                        'bg-primary': _stepProgressBackgroundVisible(MemberEditTab.PaymentDetails)}"></i>
                    Payment Details
                </ng-template>
                <app-member-payment-details [loadOnDemand]="true" [form]="form.get('PaymentDetails')"
                    [data]="primaryMemberSharedData" #paymentDetails></app-member-payment-details>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
        <mat-step [stepControl]="form.get('MembershipCards')">
            <form id="membertb6">
                <ng-template matStepLabel>
                    <i class="icon-fp d-lg-none" [ngClass]="{
                        'fas fa-check': _stepProgressCompleteIconVisible(MemberEditTab.MembershipCards),
                        'bg-primary': _stepProgressBackgroundVisible(MemberEditTab.MembershipCards)}"></i>
                    Membership Cards
                </ng-template>
                <app-member-membership-cards [loadOnDemand]="true" [readonly]="ReadonlyByStatusMode"
                    [form]="form.get('MembershipCards')" [data]="data"
                    (dialogClose)="_membershipCardDialogClose($event)" #membershipCards>
                </app-member-membership-cards>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
        <mat-step [stepControl]="form.get('VisitsPayments')">
            <form id="membertb7">
                <ng-template matStepLabel>
                    <i class="icon-fp d-lg-none" [ngClass]="{
                        'fas fa-check': _stepProgressCompleteIconVisible(MemberEditTab.VisitsPayments),
                        'bg-primary': _stepProgressBackgroundVisible(MemberEditTab.VisitsPayments)}"></i>
                    Member Visits & Payments
                </ng-template>
                <ngb-accordion activeIds="pnlConfirmMemberVisits,pnlConfirmMemberPayments">
                    <ngb-panel id="pnlConfirmMemberVisits">
                        <ng-template ngbPanelTitle>
                            <i class="fas fa-check icon-fp"></i>
                            Member Visits
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="d-flex flex-column w-100" [formGroup]="AuditFormGroup">
                                <select class="form-control" id="ddlAuditLogFilter" formControlName="ddlFilter" (change)="month_Year_Change()" style="margin-bottom: 1rem; align-self: flex-end; width: 33%;">
                                  <option value="" disable selected>Please select</option>
                                  <option *ngFor="let fi of FilterItems" [value]="fi.Name">
                                    {{fi.Name}}
                                  </option>
                                </select>
                            </div>
                            <div class="border-default rounded-10 border-2">
                                <div>
                                    <table mat-table matSort #visitsSort="matSort" matSortActive="VisitDateTimeDate"
                                        matSortDirection="desc" [dataSource]="dataSourceVisits" class="rounded-10">
                                        <ng-container matColumnDef="VisitDateTimeDate">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Visit Date
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.VisitDateTime | dateFormatPipe }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="VisitDateTimeTime">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Visit Time
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.VisitDateTime | timeFormatPipe }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="Facility">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Facility
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.Facility }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="ServiceType">
                                            <th mat-header-cell *matHeaderCellDef>Service Type</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.Service }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="NumberofVisits">
                                            <th mat-header-cell *matHeaderCellDef>Number of Visits</th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.Visits }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="VisitSource">
                                            <th mat-header-cell *matHeaderCellDef class="p-0">
                                                Visit Source
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="p-0">
                                                {{ element.VisitSource }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="VisitType">
                                            <th mat-header-cell *matHeaderCellDef class="p-0">
                                                Visit Type
                                            </th>
                                            <td mat-cell *matCellDef="let element" [ngSwitch]="element.VisitType"
                                                class="p-0">
                                                <span *ngSwitchCase="'Valid'" style="color: green">
                                                    {{ element.VisitType }}
                                                </span>
                                                <span *ngSwitchCase="'Invalid'" style="color: red">
                                                    {{ element.VisitType }}
                                                </span>
                                                <span *ngSwitchDefault>
                                                    {{ element.VisitType }}
                                                </span>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="DuplicateVisit">
                                            <th mat-header-cell *matHeaderCellDef class="p-0">
                                                Duplicate
                                            </th>
                                            <td mat-cell *matCellDef="let element" [ngSwitch]="element.DuplicateVisit"
                                                class="p-0">
                                                <span *ngSwitchCase="false">

                                                </span>
                                                <span *ngSwitchCase="true">
                                                    Duplicate
                                                </span>
                                                <span *ngSwitchDefault>

                                                </span>
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="InvalidVisitReason">
                                            <th mat-header-cell *matHeaderCellDef class="p-0">
                                                Invalid Visit Reason
                                            </th>
                                            <td mat-cell *matCellDef="let element" class="p-0">
                                                {{ element.InvalidVisitReason }}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumnsVisit; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumnsVisit"></tr>
                                    </table>
                                    <div *ngIf="isLoading" class="spinner-wrapper">
                                        <mat-spinner [diameter]="20"></mat-spinner>
                                    </div>
                                    <mat-paginator #visitpagi [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons>
                                    </mat-paginator>
                                    <mat-toolbar [hidden]="dataSourceVisits.data.length > 0 || isLoading"
                                        class="bg-white">
                                        <mat-toolbar-row class="text-muted d-block p-2 bg-light text-center">
                                            {{ CommonString.TABLE_NO_RECORD_FOUND }}
                                        </mat-toolbar-row>
                                    </mat-toolbar>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="pnlConfirmMemberPayments">
                        <ng-template ngbPanelTitle>
                            <i class="fas fa-check icon-fp"></i>
                            Member Payments
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div *ngIf="IsPrimary()===true">
                                <div [style.display]="noMemberPaymentsMessage ? 'block' : 'none'">
                                    <p>Payments do not exist for this member.</p>
                                </div>
                            </div>
                            <div *ngIf="IsPrimary()===false">
                                <p>
                                    Please refer to the primary member to view and interact with the membership ledger.
                                </p>
                            </div>
                            <div *ngIf="IsPrimary()===true">
                                <div class="d-flex">
                                    <p class="font-weight-bold"
                                        [style.display]="memberPaymentsTable ? 'block' : 'none'">Member Balance:
                                        {{ balance | currency }}</p>
                                    <p class="font-weight-bold ml-auto">BCF Index:
                                        {{primaryMemberSharedData.model.Memberships &&
                                            primaryMemberSharedData.model.Memberships.length > 0 && primaryMemberSharedData.model.Memberships[0] ? primaryMemberSharedData.model.Memberships[0].Bcf : ''}}
                                    </p>
                                </div>
                                <div class="pb-2" [style.display]="memberPaymentsTable ? 'block' : 'none'">
                                    <div class="border-default rounded-10 border-2">
                                        <div>
                                            <table #ledgerTable #membershipLedgerSort="matSort" mat-table matSort
                                                matSortActive="PostDate" matSortDirection="desc"
                                                [dataSource]="dataSource3">
                                                <ng-container matColumnDef="CreatedDate">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        Created Date
                                                    </th>
                                                    <td mat-cell mat-sort *matCellDef="let element">
                                                        {{ element.CreatedDate | dateFormatPipe }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="PostDate">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        Transaction Date
                                                    </th>
                                                    <td mat-cell mat-sort *matCellDef="let element">
                                                        {{ element.PostDate | dateFormatPipe }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="DebitCredit">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        Debit/Credit
                                                    </th>
                                                    <td mat-cell mat-sort *matCellDef="let element">
                                                        {{ element.DebitCredit }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="Amount">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        Amount (+/-)
                                                    </th>
                                                    <td mat-cell mat-sort
                                                        [ngStyle]="{ color: element.Amount >= '0' ? 'green' : 'red' }"
                                                        *matCellDef="let element">
                                                        {{ element.Amount | currency }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="DebitCreditReason">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        Reason
                                                    </th>
                                                    <td mat-cell mat-sort *matCellDef="let element">
                                                        {{ element.DebitCreditReason }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="Description">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Description
                                                    </th>
                                                    <td mat-cell mat-sort *matCellDef="let element">
                                                        {{ element.Description }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="PostedBy">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
                                                    <td mat-cell mat-sort *matCellDef="let element">
                                                        {{ element.PostedBy }}
                                                    </td>
                                                </ng-container>
                                                <tr mat-header-row
                                                    *matHeaderRowDef="displayedColumnsLedger; sticky: true">
                                                </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumnsLedger"></tr>
                                            </table>
                                            <mat-paginator #ledgerpagi [pageSizeOptions]="[10, 20, 50]"
                                                showFirstLastButtons>
                                            </mat-paginator>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pt-2 d-flex justify-content-between"
                                *ngIf="CheckManualAddDepitCreditPermission()===true">
                                <button mat-button (click)="openDialogAddDebitCredit()" class="btn btn-primary btn">
                                    Manually add Debit/Credit
                                </button>
                                <button (click)="btnResetBCF_Click($event)" mat-button class="btn btn-primary"
                                    *ngIf="isAdminValid()">
                                    <i class="fas fa-undo mr-2"></i>Reset BCF
                                </button>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
        <!-- ============================================================================================================================================= -->
        <mat-step [stepControl]="form.get('AuditLog')" #stepper8>
            <form id="membertb8">
                <ng-template matStepLabel><i class="icon-fp d-lg-none"></i><span>Audit Log</span>
                </ng-template>
                <ngb-accordion activeIds="documentAttachmentsId,imageAttachments">
                    <ngb-panel id="documentAttachmentsId">
                        <ng-template ngbPanelTitle>
                            <i class="fas fa-check icon-fp"></i>
                            Audit Log
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div id="memberAuditLogsTable">
                                <div>
                                    <app-audit-log [auditSource]="'Member'"></app-audit-log>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </form>
        </mat-step>

    </mat-vertical-stepper>
</div>
<ng-template #tmplButtons>
    <div
        class="pt-2 pb-0 d-flex d-lg-block justify-content-between justify-content-md-start justify-content-between justify-content-md-start">
        <button *ngIf="_saveButtonVisible()" class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3"
            type="button" (click)="btnSave_Click()">
            <i class="fas fa-check mr-2"></i>Save</button>
        <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button"
            [hidden]="stepper.selectedIndex === stepper._steps?.length - 1" (click)="btnNext_Click()">
            <i class="fas fa-arrow-circle-right mr-2"></i>Next</button>
    </div>
</ng-template>

<!-- TODO: remove after implementation completed -->
<!-- #region DEBUG -->
<div *ngIf="_isDebugging" class="tabFPTheme mt-5">
    <pre>Request count: {{ data.requestQueueCount }}</pre>
    <pre>Form valid: {{ form.valid }}</pre>
    <ngb-accordion>
        <ngb-panel id="pnlFormJson">
            <ng-template ngbPanelTitle>
                Form JSON
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <pre>{{ JSON.stringify(form.getRawValue(), null, 4) }}</pre>
            </ng-template>
        </ngb-panel>
        <ngb-panel>
            <ng-template ngbPanelTitle>
                Data JSON
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <pre>{{ JSON.stringify(data.model, null, 4) }}</pre>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>
<!-- #endregion DEBUG -->