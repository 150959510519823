import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Injector,
  ElementRef,
  isDevMode,
} from "@angular/core";
import { CommonService, MemberService, MessageBox } from "@fp/services";
import {
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { PatternConstant, RouterConstant } from "@fp/constant";
import { MemberFormBase } from "@fp/components/base/member-form-base";
import { State } from "@fp/models/state.model";
import { CommonMessage, CommonString } from "src/app/constant";
import { FPValidators, StringHelper } from "src/app/helpers";
import { CommonConstants } from "@fp/constant/common-constants";
import { Employer } from "@fp/models/employermodel";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { Member, ResultModel, SuburbSearchResultRecord } from "@fp/models";
import { catchError, debounceTime, skipWhile, switchMap } from "rxjs/operators";
import { empty, Observable, of } from "rxjs";
import { DialogResult } from "@fp/components/common-dialog-new/common-dialog-new.component";
import { Router } from "@angular/router";

@Component({
  selector: "employer-details-new",
  templateUrl: "./employer-details.component.html",
  styleUrls: ["./employer-details.component.css"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class EmployerDetailsNewComponent
  extends MemberFormBase
  implements OnInit
{
  commonString = CommonString;
  Country: any = [this.commonString.AUSTRALIA, this.commonString.NEWZEALAND];
  parentFocus = 0;
  btnNextSubmit = false;
  isFromAustralia = true;
  //StateCtrl = null;
  states: State[];
  selectedState: State;
  selectedIndex = 0;
  isSearchingEmployer = false;
  isSearchingWorkPlace = false;
  selectedEmployerId = 0;
  selectedCityId = 0;
  isMemberTransfer: boolean;
  employerSearchResults: Employer[];
  private employerList: Employer[];
  private suburbList: SuburbSearchResultRecord[];
  wrkplcSearchResults: SuburbSearchResultRecord[];

  @ViewChild("employerSearchBox")
  private employerSearchBox: ElementRef<HTMLDivElement>;
  @ViewChild("wrkplcSearchBox")
  private wrkplcSearchBox: ElementRef<HTMLDivElement>;
  @ViewChild("citySearchBox") private citySearchBox: ElementRef<HTMLDivElement>;
  @ViewChild("autoEmployer") private autoEmployer: MatAutocomplete;
  @ViewChild("autoWorkplaceSuburb")
  private autoWorkplaceSuburb: MatAutocomplete;
  @ViewChild("autoCity") private autoCity: MatAutocomplete;
  @ViewChild("nxtButtonRef") nxtButtonRef: ElementRef;

  @Output() resetEvent = new EventEmitter();
  @Output() isCompleteForm: EventEmitter<any> = new EventEmitter();
  CommonMessage = CommonMessage;
  CommonString = CommonString;
  StringHelper = StringHelper;

  get EmployerCtrl() {
    return this.form.get("Employer");
  }
  get EmployerIdCtrl() {
    return this.form.get("EmployerId");
  }
  get WorkPlaceCtrl() {
    return this.form.get("WorkplaceSuburb");
  }
  get WorkPlaceObjCtrl() {
    return this.form.get("WorkplaceSuburbObj");
  }
  get StateCtrl() {
    return this.form.get("StateCtrl");
  }
  get EmployerCountryCtrl() {
    return this.form.get("EmployerCountry");
  }

  get isDebugging() {
    return isDevMode() && window["enableDebugging"] === true;
  }
  JSON = JSON;

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      EmployerCountry: [null],
      EmployerId: [null, [Validators.required]],
      Employer: [
        null,
        [Validators.required, FPValidators.resolvableAutocompleteOption],
      ],
      EmployeePayrollNo: [null, Validators.required],
      WorkplaceSuburb: [null, Validators.required],
      WorkplaceSuburbObj: [
        null,
        [Validators.required, FPValidators.resolvableAutocompleteOption],
      ],
      StateCtrl: [null],
    });
  }

  constructor(
    injector: Injector,
    private router: Router,
    private memberSvc: MemberService,
    private commonService: CommonService,
    private _formBuilder: UntypedFormBuilder
  ) {
    super(injector);
    this.form = EmployerDetailsNewComponent.getFormGroup();
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.commonSvc.App.opened = false;
    this.isFromAustralia =
      this.commonService.getUserCountry() !== this.commonString.NEWZEALAND;

    if (this.isFromAustralia) {
      this.EmployerCountryCtrl.setValue(this.commonString.AUSTRALIA);
    }
    this.states = CommonConstants.GET_STATES.filter(
      (X) => X.CountryId == 1
    ).sort((a, b) =>
      a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1
    );
    this.isSearchingWorkPlace = true;
    this.commonSvc.getLocationData().subscribe((res) => {
      if (res.Success) {
        this.suburbList = res.Data.suburbs;
        this.isSearchingWorkPlace = false;
      }
    });
  }

  ngAfterViewInit() {
    this.OnLoad();
  }

  OnLoad() {
    super.OnLoad();
  }

  LoadComplete() {
    this.employerList = this.commonService.getEmployers();
    let currentValue = "";
    let currentSuburbValue = "";
    let currentCityValue = "";
    setTimeout(() => {
      this.form.get("EmployerId").valueChanges.subscribe((value) => {
        this.selectedEmployerId = value;
        this.employerSearchResults = [];
      });

      this.EmployerCtrl.valueChanges
        .pipe(
          skipWhile(
            (value) =>
              (this.isFromAustralia && !this.StateCtrl.value) ||
              (value && typeof value === "object")
          ),
          debounceTime(500),
          switchMap((value) => {
            if (typeof value === "string") {
              this.form.get("EmployerId").setValue(null);
              if (
                this.isFromAustralia &&
                this.StateCtrl.value &&
                value.length > 2
              ) {
                this.isSearchingEmployer = true;
                return value;
              } else if (!this.isFromAustralia && value.length > 2) {
                this.isSearchingEmployer = true;
                return value;
              } else {
                this.isSearchingEmployer = false;
              }
            }
            return empty();
          })
        )
        .subscribe((value: string) => {
          this.isSearchingEmployer = false;
          if (currentValue != this.EmployerCtrl.value) {
            currentValue = this.EmployerCtrl.value;
            let employerList: Employer[] = this.employerList;
            if (this.isFromAustralia) {
              employerList = employerList.filter(
                (x) =>
                  x.State.StateId == this.StateCtrl.value.StateId &&
                  x.Name.toLowerCase()
                    .trim()
                    .includes(this.EmployerCtrl.value.toLowerCase().trim())
              );
            } else {
              employerList = employerList.filter(
                (x) =>
                  x.State.StateId == 9 &&
                  x.Name.toLowerCase()
                    .trim()
                    .includes(this.EmployerCtrl.value.toLowerCase().trim())
              ); //set State Id to NZ
            }
            employerList.sort((a, b) => a.Name.localeCompare(b.Name));
            this.employerSearchResults = employerList;
            this.changeDetectorRef.markForCheck();
          }
        });

      this.WorkPlaceObjCtrl.valueChanges
        .pipe(
          skipWhile((value) => value && typeof value === "object"),
          debounceTime(500),
          switchMap((value) => {
            if (
              typeof value === "string" &&
              this.StateCtrl.value &&
              value.trim().length >= 3
            ) {
              this.changeDetectorRef.markForCheck();
              return value;
            } else if (
              typeof value === "string" &&
              !this.isFromAustralia &&
              value.trim().length >= 3
            ) {
              this.changeDetectorRef.markForCheck();
              return value;
            }
            return empty();
          }),
          catchError((e) => {
            throw e;
          })
        )
        .subscribe(
          (data) => {
            if (currentCityValue != this.WorkPlaceObjCtrl.value) {
              currentSuburbValue = this.WorkPlaceObjCtrl.value;
              if (this.suburbList) {
                let getList = this.suburbList.filter(
                  (x) =>
                    x.State.Name ==
                      (this.isFromAustralia
                        ? this.StateCtrl.value.Name
                        : "NZ") &&
                    x.Name.toLowerCase()
                      .trim()
                      .includes(
                        this.WorkPlaceObjCtrl.value.toLowerCase().trim()
                      )
                );
                this.wrkplcSearchResults = getList;
                this.isSearchingWorkPlace = false;
                this.changeDetectorRef.markForCheck();
              }
            }
          },
          (error) => {
            this.isSearchingWorkPlace = false;
            MessageBox.ShowError(
              this.dialog,
              "Sorry, we're having trouble connecting. Let's try that again."
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                window.location.reload();
              }
            });
            this._logger.error(error);
          }
        );

      // Fix postcode autocomplete's width.
      this.autoEmployer.panelWidth =
        this.employerSearchBox.nativeElement.clientWidth;
      this.autoWorkplaceSuburb.panelWidth =
        this.employerSearchBox.nativeElement.clientWidth;
      if (this.autoCity) {
        this.autoCity.panelWidth =
          this.employerSearchBox.nativeElement.clientWidth;
      }
    });
    super.LoadComplete();
    if (this.isFromAustralia) {
      this.CheckStateValue(null);
    }
  }

  private processDataForSubmission(source: Member) {
    const target = Object.assign({}, source);
    if (target.Employer) {
      target.EmployerId = target.Employer.EmployerId;
      delete target.Employer;
    }
    if (target.MemberType) {
      target.MemberTypeId = target.MemberType.MemberTypeId;
      delete target.MemberType;
    }
    if (target.MemberStatus) {
      target.MemberStatusId = target.MemberStatus.MemberStatusId;
      delete target.MemberStatus;
    }
    if (target.FavouredFacility) {
      target.FavouredFacilityId = target.FavouredFacility.FacilityId;
      delete target.FavouredFacility;
    }
    if (target.ExistingFacility) {
      target.ExistingMemberFacilityId = target.ExistingFacility.FacilityId;
      delete target.ExistingFacility;
    }
    // if (target.MedicalAnswers instanceof Array) {
    //   target.MedicalAnswers.forEach((answer) => {
    //     delete answer.MedicalQuestion;
    //   });
    // }
    if (target.FamilyMembers instanceof Array) {
      target.FamilyMembers = target.FamilyMembers.map((member) => {
        return this.processDataForSubmission(member);
      });
    }
    delete target.Suburb;
    delete target.Postcode;
    delete target.State;
    delete target.Country;
    for (const key in target) {
      if (target.hasOwnProperty(key) && target[key] == null) {
        delete target[key];
      }
    }
    return target;
  }

  SubmitEmDetails() {
    this.btnNextSubmit = true;
    if (this.form.valid) {
      this.Validate();
      this.data.model = this.applyValue(this.data.model, this);
      if (this.EmployerCountryCtrl.value == "Australia") {
        this.data.model.Employer.State.CountryId = 1;
      } else {
        this.data.model.Employer.State.CountryId = 2;
      }

      if (!this.valid) {
        if (this.isDebugging) {
          const invalidCtrls = this._findInvalidControls(this.form);
          console.log("Invalid controls:\n" + invalidCtrls.join("\n"));
        }
        return;
      }

      this.Invoke(this.memberSvc.SaveEmployerInfo(this.data.model), {
        onSuccess: (res: ResultModel) => {
          if (res.Success) {
            this.isCompleteForm.emit(CommonConstants.SIGNUP_EMPLOYER_DETAILS);
          } else {
            const supportLink =
              '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
            MessageBox.ShowOKCustom(
              this.dialog,
              "Error",
              "Sorry, we're unable to complete the registration. \
                        Please try again. If you continue to experience problems, send us an enquiry \
                        via our support site " +
                supportLink,
              "Take me back"
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
              }
            });
            this._logger.error(res);
          }
        },
        onError: (err) => {
          const supportLink =
            '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
          MessageBox.ShowOKCustom(
            this.dialog,
            "Error",
            "Sorry, we're unable to complete the registration. \
                    Please try again. If you continue to experience problems, send us an enquiry \
                    via our support site " +
              supportLink,
            "Take me back"
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
            }
          });
          this._logger.error(err);
        },
      });
    }
  }

  txtEmployer_Blur(e) {
    if (this.EmployerCtrl.dirty) {
      this.EmployerCtrl.setValidators([
        Validators.required,
        FPValidators.resolvableAutocompleteOption,
      ]);
      this.EmployerCtrl.updateValueAndValidity({ emitEvent: false });
    }
    this.parentFocus = 0;
  }

  txtWorkPlace_Blur(e) {
    if (this.WorkPlaceObjCtrl.dirty) {
      this.WorkPlaceObjCtrl.setValidators([
        Validators.required,
        FPValidators.resolvableAutocompleteOption,
      ]);
      this.WorkPlaceObjCtrl.updateValueAndValidity({ emitEvent: false });
    }
    this.parentFocus = 0;
  }

  txtWorkPlace_Input(e) {
    if (this.WorkPlaceObjCtrl.dirty) {
      if (
        typeof this.WorkPlaceObjCtrl.value === "string" &&
        this.WorkPlaceObjCtrl.value.length == 0
      ) {
        this.SelectWorkPlace(null);
      }
      this.WorkPlaceObjCtrl.setValidators(Validators.required);
    }
  }

  txtEmployer_Input(e) {
    if (this.EmployerCtrl.dirty) {
      if (
        typeof this.EmployerCtrl.value === "string" &&
        this.EmployerCtrl.value.length == 0 &&
        this.selectedEmployerId > 0
      ) {
        this.SelectEmployer(null);
      }
      this.EmployerCtrl.setValidators(Validators.required);
    }
  }

  getClassFocused(vlFocused) {
    if (this.parentFocus == vlFocused) {
      return "focused";
    } else {
      return "";
    }
  }

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }

  AutoComplete_DisplayWithFn(option: any) {
    return option ? option.Name : "";
  }

  AutoCompleteWorkPlace_DisplayWithFn(option: any) {
    if (option) {
      if (option.Name && option.State && option.Postcode) {
        const val =
          option.Name + ", " + option.State.Name + ", " + option.Postcode.Name;
        return val;
      } else {
        return option;
      }
    } else {
      return "";
    }
  }

  SelectWorkPlace(wrkplc: SuburbSearchResultRecord) {
    if (wrkplc) {
      if (wrkplc.Name && wrkplc.State && wrkplc.Postcode) {
        const val =
          wrkplc.Name + ", " + wrkplc.State.Name + ", " + wrkplc.Postcode.Name;
        this.form.patchValue({ WorkplaceSuburb: val }, { emitEvent: false });
      }
    }
    this.wrkplcSearchResults = [];
    this.WorkPlaceCtrl.markAsDirty();
    this.WorkPlaceCtrl.markAsTouched();
  }

  SelectEmployer(employer: Employer) {
    this.selectedEmployerId = employer ? employer.EmployerId : null;

    if (
      this.data.model.Employer &&
      this.data.model.EmployerId &&
      this.selectedEmployerId != this.data.model.EmployerId
    ) {
      this.DisplayResetMessage().subscribe((dialogResult) => {
        if (dialogResult.result.toLowerCase() === DialogResult.Yes) {
          this.ResetData();
        } else {
          this.form.controls.Employer.setValue(this.data.model.Employer);
          this.form.controls.EmployerId.setValue(this.data.model.EmployerId);
        }
      });
    } else {
      this.form.patchValue(
        { EmployerId: this.selectedEmployerId, Employer: employer },
        { emitEvent: false }
      );
      this.employerSearchResults = [];

      if (this.isMemberTransfer == true) {
        this.EmployerIdCtrl.markAsDirty();
        this.EmployerIdCtrl.markAsTouched();
      }
    }
  }

  ddlEmployerStateOption_CompareFn(opt1: State, opt2: State) {
    return (!opt1 && !opt2) || (opt1 && opt2 && opt1.StateId == opt2.StateId);
  }

  public ddlEmployerCountry_Change() {
    var selectedCountryId = CommonConstants.GET_COUNTRIES.find(
      (a) => a.Name == this.EmployerCountryCtrl.value
    ).CountryId;

    if (
      this.data.model.Employer?.State?.CountryId &&
      this.data.model.Employer?.State?.CountryId != selectedCountryId
    ) {
      this.DisplayResetMessage().subscribe((dialogResult) => {
        if (dialogResult.result.toLowerCase() === DialogResult.Yes) {
          this.ResetData();
        } else {
          if (this.data.model?.Employer?.State?.CountryId == 2) {
            this.form.controls.EmployerCountry.setValue(
              this.commonString.NEWZEALAND
            );
          } else {
            this.form.controls.EmployerCountry.setValue(
              this.commonString.AUSTRALIA
            );
          }
        }
      });
    } else {
      this.SetDataBaseOnCountry();
    }
  }

  SetDataBaseOnCountry() {
    if (this.EmployerCountryCtrl.value == "Australia") {
      this.isFromAustralia = true;
      this.CheckStateValue(null);
    } else {
      this.isFromAustralia = false;
      this.form.get("Employer").enable();
      this.form.get("WorkplaceSuburbObj").enable();
    }

    this.EmployerCtrl.setValue(null);
    this.EmployerIdCtrl.setValue(null);
    this.WorkPlaceCtrl.setValue(null);
    this.WorkPlaceObjCtrl.setValue(null);
  }

  private ResetData() {
    this.Invoke(this.memberSvc.ResetMemberData(this.data.model), {
      onSuccess: (res: ResultModel) => {
        if (res.Success) {
          window.location.reload();
        } else {
          const supportLink =
            '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
          MessageBox.ShowOKCustom(
            this.dialog,
            "Error",
            "Sorry, we're unable to complete the registration. \
                      Please try again. If you continue to experience problems, send us an enquiry \
                      via our support site " +
              supportLink,
            "Take me back"
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
            }
          });
          this._logger.error(res);
        }
      },
      onError: (err) => {
        const supportLink =
          '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
        MessageBox.ShowOKCustom(
          this.dialog,
          "Error",
          "Sorry, we're unable to complete the registration. \
                  Please try again. If you continue to experience problems, send us an enquiry \
                  via our support site " +
            supportLink,
          "Take me back"
        ).subscribe((res) => {
          if (res.result.toLowerCase() === DialogResult.Ok) {
            this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
          }
        });
        this._logger.error(err);
      },
    });
  }

  public ddlEmployerState_Change() {
    
    if(this.StateCtrl.value != null){
      var selectedStateId = CommonConstants.GET_STATES.find(
        (a) => a.StateId == this.StateCtrl.value.StateId
      ).StateId;
    }   

    if (
      this.data.model.Employer?.State?.StateId &&
      this.data.model.Employer?.State?.StateId != selectedStateId
    ) {
      this.DisplayResetMessage().subscribe((dialogResult) => {
        if (dialogResult.result.toLowerCase() === DialogResult.Yes) {
          this.ResetData();
        } else {
          this.form.controls.StateCtrl.setValue(
            this.data.model?.Employer?.State
          );
        }
      });
    } else {
      this.CheckStateValue(this.StateCtrl.value);
    }
  }

  public CheckStateValue(selectedState: State) {
    if (this.isFromAustralia) {
      if (selectedState) {
        this.selectedState = selectedState;
      }

      this.EmployerCtrl.setValue(null);
      this.EmployerIdCtrl.setValue(null);
      this.EmployerCtrl.markAsDirty();
      this.EmployerCtrl.markAsTouched();
      this.EmployerCtrl.updateValueAndValidity({ emitEvent: false });
    }

    if (!this.isFromAustralia) {
      this.form.get("WorkplaceSuburbObj").enable();
      this.form.get("Employer").enable();
      return;
    }

    if (this.StateCtrl !== null) {
      const val = this.StateCtrl.value;
      if (val === null || val === undefined || val === "") {
        this.form.get("Employer").disable();
        this.form.get("WorkplaceSuburbObj").disable();
      } else {
        this.form.get("WorkplaceSuburbObj").enable();
        this.form.get("Employer").enable();
      }
    }
  }

  public PatchValue(value, opts) {
    if (value?.Employer?.State?.CountryId == 2) {
      this.isFromAustralia = false;
      this.form.controls.EmployerCountry.setValue(this.commonString.NEWZEALAND);
      this.form.get("WorkplaceSuburbObj").enable();
      this.form.get("Employer").enable();
    } else {
      this.isFromAustralia = true;
      this.form.controls.EmployerCountry.setValue(this.commonString.AUSTRALIA);
      if (value?.Employer?.State) {
        this.form.controls.StateCtrl.setValue(value?.Employer?.State);
        this.EmployerCtrl.enable();
      }
    }

    let employer = <Employer>value['Employer'];    
    
    if (!employer && this.form.get('Employer').value) {      
      employer = this.form.get('Employer').value;
      value['Employer'] = employer;
    }    

    if (employer) {      
      if (value['EmployerId'] == null) {
        value['EmployerId'] = employer.EmployerId;
      }
           
      if (this.isFromAustralia && employer.State) {
        for(var i = 0; i < this.states.length; i++) {
          if(this.states[i].Name == employer.State.Name) {
            this.StateCtrl.setValue(this.states[i], { emitEvent: false });
            this.selectedState = this.states[i];
            this.form.get("WorkplaceSuburbObj").enable();            
            break;
          }
        }
           
        this.changeDetectorRef.markForCheck();
      }
    }

    this.form.controls.EmployeePayrollNo.setValue(value?.EmployeePayrollNo);
    this.form.controls.Employer.setValue(value?.Employer, { emitEvent: false });
    this.form.controls.EmployerId.setValue(value?.EmployerId, {
      emitEvent: false,
    });

    if (value?.WorkplaceSuburb) {
      if (value?.WorkplaceSuburbObj) {
        this.form.controls.WorkplaceSuburbObj.setValue(
          value?.WorkplaceSuburbObj,
          { emitEvent: false }
        );
      } else {
        this.form.controls.WorkplaceSuburbObj.setValue(value?.WorkplaceSuburb, {
          emitEvent: false,
        });
      }

      this.WorkPlaceCtrl.setValue(value?.WorkplaceSuburb, { emitEvent: false });
      this.wrkplcSearchResults = [];
      this.WorkPlaceCtrl.markAsDirty();
      this.WorkPlaceCtrl.markAsTouched();
      this.form.controls.WorkplaceSuburbObj.enable();
    }
  }

  private DisplayResetMessage(): Observable<DialogResult> {
    return MessageBox.ShowYesNo(
      this.dialog,
      "Changing this information will reset all previously entered data.<br>Do you wish to proceed?",
      "Confirmation"
    );
  }
}
