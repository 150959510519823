<nav>
  <div *ngIf="isLoginSuccessDisplay">
    <div class="fp-viewroles text-md-right py-1 py-sm-0 row px-4" style="align-items: center;">
      <div class="col col-12 col-sm-7">
        <mat-form-field appearance="standard" *ngIf="showRoleSelector">
          <mat-label>Viewing as:</mat-label>
          <mat-select mat-select id="ddlRoles" [(ngModel)]="rolePermission.SelectedRole"
            (selectionChange)="ddlRoles_Change($event)">
            <mat-option *ngFor="let r of rolePermission.RolesObject" [value]="r.Name">
              {{r.DisplayName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <a class="btn btn-outline-light nav-link" (click)="SignOut();"><i class="fas fa-sign-out-alt d-lg-none"></i>Log
          Out</a>
      </div>
    </div>
    <hr class="border-bottom border-default d-lg-none m-0">
  </div>
</nav>