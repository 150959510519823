<div class="d-md-flex justify-content-center align-items-center heightContent100">
    <div class="w-50 text-center">
        <p class="m-0 p-0 text-success"><i class="far fa-check-circle fa-4x"></i></p>
        <h4 class="py-2">Thank you for completing a request to transfer your membership to your new employer. Please
            review your existing email
            address in your profile to ensure that it is updated to your new employer.</h4>
        <p>Your transfer is now under review.</p>
        <div class="pt-3">
            <button class="px-5 py-2 btn btn-primary" (click)="ReturnHome()">Return to home</button>
        </div>
    </div>
</div>