// Author: Da Do
// This model is only used for Employer Detail Tab. Please do not use for other feature.
import { EmployerFacilitatorModel, EmployerContractModel, EmployerMembershipPackageModel, EmployerNoteModel, EmployerSurveyModel } from '.';

export class EmployerDetailModel {
    EmployerId: number;
    Name: string;
    AlternateNameTags: string;
    EmployerGroupId: number;
    EmployerGroupName: string;
    IndustrySectorId: number;
    AccountManagerId: number;
    AccountManagerName: string;
    BusinessDevelopmentManagerId: number;
    BusinessDevelopmentManagerName: string;
    EmployerTypeId: number;
    EmployerCategoryId: number;
    Abn: string;
    Code: string;
    PhoneNo: string;
    EmailAddress: string;
    AddressId: number;
    PhysicalAddressId: number;
    MailCardToHome: boolean;
    EmployerStatusId: number;
    DateClosed: string | Date | any;
    EmployerStartDate: string | Date | any;
    EmpNextPaymentDateChanged?: boolean;
    IsDirectDebit: boolean;
    NextPaymentRunDate: string | Date | any;
    PaymentPeriodId: number;
    NoEmployeeAnswer: number;
    TargetNumber: number;
    AddJournalEntry: string;
    ContractStartDate: string | Date | any;
    ContractEndDate: string | Date | any;
    ContractDuration: number;
    ContractSignatory: string;
    SignedContract: string;
    Logo: string;
    PrimaryFacilitatorId: number;
    FacillitatorAdditionalNote: string;
    DefaultPackageId: number;
    EligibleEmployeeIds: number[];
    EligibilityInformation: string;
    EligibleEmployeeNumber?: number;
    StreetAddress1_Phy: string;
    StreetAddress2_Phy: string;
    StreetAddress_Phy_Desc: string;
    SuburbId_Phy: number;
    CityId_Phy: number;
    City_Phy: string;
    CityId_Mail: string;
    City_Mail: string;
    StreetAddress1_Mail: string;
    StreetAddress2_Mail: string;
    StreetAddress_Mail_Desc: string;
    SuburbId_Mail: number;
    PostCodeId_Mail: number;
    PostCode_Mail: string;
    PostCodeId_Phy: number;
    PostCode_Phy: string;
    Suburb_Phy: string;
    Suburb_Mail: string;
    ModifiedBy: string = localStorage.getItem('user');
    Country: string;

    // Author: Yen
    FacilitatorEmployers: EmployerFacilitatorModel[];
    EmployerSurvey: EmployerSurveyModel[];
    EmployerContract: EmployerContractModel;

    // Author: Trang
    MembershipPackages: EmployerMembershipPackageModel[];
    EmployerNotes: EmployerNoteModel[];
}
