export enum EFacilityType {
    CouncilOwned = 1,
    Independent = 2,
    Corporate = 3,
    ManagementGroup = 4,
    Franchisee = 5,
    University = 6,
    HighSchool = 7,
    Tafe = 8,
    JointVenture = 9
}
