import { Component, OnInit } from "@angular/core";
import {
  PaymentRequestDetailsService,
  MessageBox,
  CommonService
} from "@fp/services";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FpFileUploadR2Component } from "@fp/components/control";
import { RouterConstant } from "@fp/constant";
import { FileNameService } from "@fp/services/file-name.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpClient } from "@angular/common/http";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";

@Component({
  selector: "app-edit-manual-visits",
  templateUrl: "./edit-manual-visits.component.html",
  styleUrls: ["./edit-manual-visits.component.css"]
})
export class EditManualVisitsComponent implements OnInit {
  facilityName: string;
  serviceName: string;
  toDate: string;
  fromDate: string;
  visitAmount: number;
  fileArray = [];
  manualVisitAmount: number;
  private uploadFileToS3: FpFileUploadR2Component;
  RouterConstant = RouterConstant;
  submitAllowed = false;
  page = "eAddManual";

  constructor(
    private prDetails: PaymentRequestDetailsService,
    public dialog: MatDialog,
    public thisdialog: MatDialogRef<EditManualVisitsComponent>,
    private modalService: NgbModal,
    private fileNameSvc: FileNameService,
    private commonSvc: CommonService,
    private http: HttpClient
  ) {
    this.uploadFileToS3 = new FpFileUploadR2Component(
      this.modalService,
      this.dialog,
      this.http,
      this.fileNameSvc,
      this.commonSvc
    );
  }

  ngOnInit() {
    this.prDetails.$currentFacilityName.subscribe(
      facilityName => (this.facilityName = facilityName)
    );
    this.prDetails.$currentServiceName.subscribe(
      serviceName => (this.serviceName = serviceName)
    );
    this.prDetails.$currentFromDate.subscribe(
      fromDate => (this.fromDate = fromDate)
    );
    this.prDetails.$currentToDate.subscribe(toDate => (this.toDate = toDate));
    this.prDetails.$currentVisitAmount.subscribe(
      visitAmount => (this.visitAmount = visitAmount)
    );
  }

  attachFile(event) {
    const file = (<HTMLInputElement>event.target).files[0];
    this.fileArray.push(file);
    this.checkVisitCountAndFiles();
  }

  // removing the metadata for file from 'fileArray'
  removeFile(file) {
    this.fileArray = this.fileArray.filter(value => value !== file);
    this.checkVisitCountAndFiles();
  }

  submitManualVisits() {
    const headerString = "Manually Adding Visits";
    const bodyString =
      "The additional visits will be added into the raw visit table" +
      " once you click submit. Do you wish to continue?";
    MessageBox.ShowCancelContinue(
      this.dialog,
      headerString,
      bodyString
    ).subscribe(dialogResult => {
      if (dialogResult.result.toLowerCase() === DialogResult.Continue) {
        // save files into backend (s3)
        this.fileUploadToS3();
        // recalculates visit amount
        this.manualVisitAmount = +(<HTMLInputElement>(
          document.getElementById("manualVisits")
        )).value;
        this.prDetails.recalculateVisits(
          this.visitAmount,
          this.manualVisitAmount
        );
        this.thisdialog.close();
      }
    });
  }

  checkVisitCountAndFiles() {
    const missingValues = [];
    if (this.facilityName === "" || this.facilityName === null) {
      missingValues.push("Facility Name");
    }
    if (this.serviceName === "" || this.serviceName === null) {
      missingValues.push("Service Name");
    }
    if (this.fromDate === "" || this.fromDate === null) {
      missingValues.push("Start Date");
    }
    if (this.toDate === "" || this.toDate === null) {
      missingValues.push("End Date");
    }
    if (
      (<HTMLInputElement>document.getElementById("manualVisits")).value !==
      "" &&
      +(<HTMLInputElement>document.getElementById("manualVisits")).value > 0 &&
      missingValues.length === 0
    ) {
      this.submitAllowed = true;
    } else {
      this.submitAllowed = false;
    }
  }

  fileUploadToS3() {
    let count = 0;
    let checkNecessary = false;
    if (this.fileArray.length !== 0) {
      for (let i = 0; i < this.fileArray.length; i++) {
        this.uploadFileToS3.fileEventUsingFile(this.fileArray[i], checkNecessary, this.page)
          .subscribe(result => {
            console.log(result);
          });
        count++;
      }
      if (count !== this.fileArray.length) {
        MessageBox.ShowInfo(
          this.dialog,
          "Error: One or more of your files have not been uploaded successfully"
        );
      }
    }
  }
}
