import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort, MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FPAbstractComponent } from '@fp/components/base';
import { CommonString, RouterConstant, APIConstant } from '@fp/constants';
import { DataResult, MemberSearchResultRecord, SearchPaginationRequest, SearchPaginationResult } from '@fp/models';
import { MemberService, CommonService, UserService } from '@fp/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MemberCancellationComponent } from '../shared/member-cancellation/member-cancellation.component';
import { EMemberStatus } from '@fp/enums';
import { ERole } from '@fp/enums/role.enum';
import { DataSourceHelper } from '@fp/helpers';

@Component({
    selector: 'app-membersview',
    templateUrl: './membersview.component.html',
    styleUrls: ['./membersview.component.css']
})
export class MembersviewComponent extends FPAbstractComponent implements OnInit {

    @ViewChild('MemberCancellationModal') memberCancellationModal: ElementRef;
    @ViewChild('memberSort') memberSort: MatSort;
    displayedColumns: string[];
    dataSourceMember: MatTableDataSource<any>;
    bogusDataSource = new MatTableDataSource<any>(null);

    CommonString = CommonString;
    routerConstant = RouterConstant;

    paging: SearchPaginationRequest = new SearchPaginationRequest();
    pageSizeOptions: number[] = [10, 20, 50];
    SearchMemberBasedOnEmployer = true;
    length: number;
    pageSize: number;


    isEmployerFacilitatorRole: boolean = false;

    ngOnDestroy() {
        this.displayedColumns = null;
        this.dataSourceMember = null;
        this.paging = null;
    }

    constructor(
        injector: Injector,
        public router: Router,
        private memberSvc: MemberService,
        private service: UserService,
        private route: ActivatedRoute,
        public modal: NgbModal,
        private cmsrv: CommonService

    ) {
        super(injector);
    }

    ngOnInit() {
        this.isEmployerFacilitatorRole = this.cmsrv.GetSelectedRole() === ERole.Employer_Facilitator ? true : false;
        this.paging.PageNumber = 1;
        this.paging.PageSize = 10;
        this.loadMember();
    }

    //Author: Yen
    loadMember() {
        const encryptedtoken = this.cmsrv.E_FP_RequestData(this.cmsrv.GetToken());
        const para = this.route.snapshot.params;
        this.displayedColumns = ['LastName', 'FirstName', 'EmployerForTable', 'Email', 'MemberStatusForTable', 'MembershipStatusForTable', 'MemberNumber', 'MemberId', 'MemberTypeForTable', 'MemberIdPrimary', 'action'];
        if (para.employerid !== undefined) {
            this.SearchMemberBasedOnEmployer = false;
            if (this.paging.OrderBy === undefined
                || this.paging.OrderBy === ''
                || this.paging.OrderBy === null) {
                this.paging.OrderBy = 'lastname';
            } else {
                this.paging.OrderBy = this.paging.OrderBy.replace('_', '');
                if (this.paging.OrderBy === 'employername') {
                    this.paging.OrderBy = 'emp'
                }
            }
            const id = parseInt(para.employerid, 10);
            const isfacilitatorrole = this.cmsrv.GetSelectedRole() === ERole.Employer_Facilitator ? '1' : '0';
            this.paging.Params = [
                { Name: 'employer_id', Value: id.toString() },
                { Name: 'userid', Value: this.cmsrv.GetUser() },
                { Name: 'isfacilitatorrole', Value: isfacilitatorrole },
            ];
            const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(this.paging));

            var apiUrl = '';
            // EF calls separate API (cannot view members from other employers)
            if (this.cmsrv.GetSelectedRole() === ERole.Employer_Facilitator)
            {
                apiUrl = APIConstant.API_GET_SEARCH_MEMBERS_PAGING;
            }
            // Admin call
            else{
                apiUrl = APIConstant.API_GET_SEARCH_MEMBERS_PAGING
            }
            this.service.postData(
                apiUrl,
                (data: any) => {
                    const dectypted_data = this.cmsrv.D_FP_ResponseData(data);
                    const rdata: DataResult<SearchPaginationResult<MemberSearchResultRecord>> = JSON.parse(dectypted_data);
                    if (rdata != null) {
                        if (rdata.Success) {
                            this.dataSourceMember = new MatTableDataSource<MemberSearchResultRecord>(rdata.Data.Results);
                            this.length = rdata.Data.TotalItem;
                        } else {
                            this.dataSourceMember = new MatTableDataSource<MemberSearchResultRecord>();
                            this.HandleResponseError(rdata);
                        }
                        this.dataSourceMember.sortingDataAccessor =
                        DataSourceHelper.localeLowerCaseSortingDataAccessor;
                    this.dataSourceMember.sort = this.memberSort;
                    }
                },
                { "Data": encrypteddata, "Header" : encryptedtoken }
            );
        } else if (para.valuesearch) {
            this.SearchMemberBasedOnEmployer = false;
            if (this.paging.OrderBy === undefined
                || this.paging.OrderBy === ''
                || this.paging.OrderBy === null) {
                this.paging.OrderBy = 'lastname';
            } else {
                this.paging.OrderBy = this.paging.OrderBy.replace('_', '');
                if (this.paging.OrderBy === 'employername') {
                    this.paging.OrderBy = 'emp'
                }
            }
            const valuesearch: string = para.valuesearch;
            const isfacilitatorrole = this.cmsrv.GetSelectedRole() === ERole.Employer_Facilitator ? '1' : '0';
            this.paging.Params = [
                { Name: 'membername_number_email', Value: valuesearch },
                { Name: 'userid', Value: this.cmsrv.GetUser() },
                { Name: 'isfacilitatorrole', Value: isfacilitatorrole },
            ];
            const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(this.paging));
            // EF calls separate API (cannot view members from other employers)
            if (this.cmsrv.GetSelectedRole() === ERole.Employer_Facilitator)
            {
                apiUrl = APIConstant.API_GET_SEARCH_MEMBERS_PAGING;
            }
            // Admin call
            else{
                apiUrl = APIConstant.API_GET_SEARCH_MEMBERS_PAGING
            }
            this.service.postData(
                apiUrl,
                (data: any) => {
                    const dectypted_data = this.cmsrv.D_FP_ResponseData(data);
                    const rdata: DataResult<SearchPaginationResult<MemberSearchResultRecord>> = JSON.parse(dectypted_data);
                    if (rdata != null) {
                        if (rdata.Success) {
                            this.dataSourceMember = new MatTableDataSource<MemberSearchResultRecord>(rdata.Data.Results);
                            this.length = rdata.Data.TotalItem;
                        } else {
                            this.dataSourceMember = new MatTableDataSource<MemberSearchResultRecord>();
                            this.HandleResponseError(rdata);
                        }
                        this.dataSourceMember.sortingDataAccessor =
                        DataSourceHelper.localeLowerCaseSortingDataAccessor;
                    this.dataSourceMember.sort = this.memberSort;
                    }
                },
                { "Data": encrypteddata, "Header" : encryptedtoken }
            );

        }
    }

    sortChange(sort: Sort) {
        const activeSort: string = sort.active.toLowerCase();
        this.paging.OrderBy = activeSort;
        this.paging.OrderByAsc = sort.direction === 'asc' ? false : true;
        // this.loadMember();
    }

    paginatorChange(event: PageEvent) {
        this.paging.PageNumber = event.pageIndex + 1;
        this.paging.PageSize = event.pageSize
        this.loadMember();
    }

    //Author: Yen
    selectInactiveBanMember(member) {
        const modalRef = this.modal.open(MemberCancellationComponent, {
            backdrop: 'static', windowClass: 'MemberCancellationModal'
        });
        const instance = (<MemberCancellationComponent>modalRef.componentInstance);
        instance.memberId = member.MemberId;
        instance.memberStatusId = member.MemberStatus.Id;
        instance.memberTypeId = member.MemberType.Id;
        modalRef.result.then(
            (result: { returnValue: string }) => {
                if (result && result.returnValue) {
                    this.loadMember();
                }
            },
            reason => { }
        );
    }

    checkShowBan(memberStatusId) {
        let isBool: boolean = false;
        if (!this.isEmployerFacilitatorRole && (memberStatusId == EMemberStatus.Active || memberStatusId == EMemberStatus.Changed)) {
            isBool = true;
        }
        return isBool;
    }

    getColor(membershipStatus) {
        (2)
        switch (membershipStatus) {
            case 'Active':
                return 'green';
            case 'Inactive':
                return 'red';
            case 'Suspended':
                return 'orange';
        }
    }

    openLink(memIdPrimary) {
        window.open("/dashboard/member/edit/" + memIdPrimary);
    }
}