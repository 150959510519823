<div *ngIf="!IsReport">
  <div class="container" style="z-index: 0" *ngIf="stepMachineIsRunning || stepMachineSuccess || stepMachineFailure">
    <div class="pl-3 tooltip row">
      <span class="tooltiptext">{{stepMachineTooltip}}</span>
      <div class="spinner-wrapper" *ngIf="stepMachineIsRunning">
        <span style="margin-right: 3em"><b class="text-primary">RUNNING</b></span>
        <mat-spinner [diameter]="25">
        </mat-spinner>
      </div>
      <div style="color: green" class="spinner-wrapper" *ngIf="stepMachineSuccess">
        <span style="margin-right: 2em"><b>SUCCESS</b></span>
        <i class="fas fa-check-circle fa-2x col-1"></i>
      </div>
      <div style="color: red" class="spinner-wrapper" *ngIf="stepMachineFailure">
        <span style="margin-right: 2em" *ngIf="stepMachineFailure"><b>FAILURE</b></span>
        <i class="fas fa-times-circle fa-2x col-1"></i>
      </div>
    </div>
  </div>
</div>
