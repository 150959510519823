import { forwardRef, Injector, Provider, QueryList, ViewChildren, Directive } from '@angular/core';
import { Member } from 'src/app/models';
import { FPFormBaseComponent, ModelBasedFormSharedData } from './fp-form-base-component';

@Directive()
export abstract class MemberFormBase extends FPFormBaseComponent<MemberFormSharedData> {
    @ViewChildren(MemberFormBase) protected _children: QueryList<MemberFormBase>;
    /**
     * List of direct descendant components.
     * @readonly
     */
    get children(): QueryList<MemberFormBase> { return this._children; }

    static provideExisting(type: any): Provider {
        return <Provider>{ provide: MemberFormBase, useExisting: forwardRef(() => type) };
    }

    constructor(injector: Injector) {
        super(injector);
        this.data.model = new Member();
    }
}

export class MemberFormSharedData extends ModelBasedFormSharedData<Member> {
}
