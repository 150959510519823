import { GenerateReportFPComponent } from './components/reporting/generate-report-FP/generate-report-FP.component';
import { GenerateReportsEFFSMComponent } from './components/reporting/generate-reports-EF-FSM/generate-reports-EF-FSM.component';
import { PagenotfoundComponent } from './components/error-handler-component/pagenotfound/pagenotfound.component';
import { RolePermissionService } from './services/role-permission.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home-component/home.component';
import { AboutComponent } from './components/about-component/about.component';
import { EmployerComponent } from './components/employer-components/employer/employer.component';
import { VieweditemployerComponent } from './components/employer-components/vieweditemployer/vieweditemployer.component';
import { AddemployerComponent } from './components/employer-components/addemployer/addemployer.component';
import { LoginComponent } from './components/login-component/login.component';
import { SignupComponent } from './components/signup-component/signup/signup.component';
import { EmployerportalComponent } from './components/employer-components/employerportal/employerportal.component';
import { CreatenewregionComponent } from './components/region-components/createnewregion/createnewregion.component';
import { VieweditregionsComponent } from './components/region-components/vieweditregions/vieweditregions.component';
import { DashboardComponent } from './components/dashboard-component/dashboard.component';

import { AddusersComponent } from './components/user-components/addusers/addusers.component';
import { EditusersComponent } from './components/user-components/editusers/editusers.component';
import { ViewproductsservicesComponent } from './components/productservice-components/viewproductsservices/viewproductsservices.component';
// tslint:disable-next-line:max-line-length
import { AddeditproductsservicesComponent } from './components/productservice-components/addeditproductsservices/addeditproductsservices.component';
import { EditregionsComponent } from './components/region-components/editregions/editregions.component';
import { SearchregionsComponent } from './components/region-components/searchregions/searchregions.component';
import { ViewusersComponent } from './components/user-components/viewusers/viewusers.component';
import { RouterConstant } from './constant/routerconstant';
import { PreviewemployerComponent } from './components/employer-components/previewemployer/previewemployer.component';
import { AddFacilityComponent } from './components/facility-components/addfacility/addfacility.component';
import { SearchusersComponent } from './components/user-components/searchusers/searchusers.component';
import {
  MemberSignupComponent,
  MemberChangeMembershipPackageComponent,
  MemberPaymentsHistoryReceiptsComponent,
  MemberContractsComponent,
  MemberUpdatePaymentsDetailsComponent,
} from './components/members';
import { FacilitiesComponent } from './components/facility-components/facilities/facilities.component';
import { SearchfacilityComponent } from './components/facility-components/searchfacility/searchfacility.component';
import { SearchemployerComponent } from './components/employer-components/searchemployer/searchemployer.component';
import { DraftemployerComponent } from './components/employer-components/draftemployer/draftemployer.component';
import { CompleteaddusersComponent } from './components/user-components/completeaddusers/completeaddusers.component';
import { DraftfacilityComponent } from './components/facility-components/draftfacility/draftfacility.component';
import { VieweditfacilitiesComponent } from './components/facility-components/vieweditfacilities/vieweditfacilities.component';
import { EdituserdetailsComponent } from './components/user-components/edituserdetails/edituserdetails.component';
import { EnduserscreenComponent } from './components/user-components/enduserscreen/enduserscreen.component';
import { UserrolesComponent } from './components/user-components/userroles/userroles.component';
import { ChangepasswordComponent } from './components/user-components/changepassword/changepassword.component';
import { EndmembersignupComponent } from './components/signup-component/endmembersignup/endmembersignup.component';
import { NoPermissionComponent } from './components/error-handler-component/no-permission/no-permission.component';
import { MembersVerificationComponent } from './components/members/members-verification/members-verification.component';
import { AddPackageComponent } from './components/package-components/addpackage/addpackage.component';
import { SearchpackageComponent as SearchPackageComponent } from './components/package-components/searchpackage/searchpackage.component';
import { ViewpackagesComponent as ViewPackagesComponent } from './components/package-components/viewpackages/viewpackages.component';
import { TermsConditionsComponent } from './components/signup-component/terms-conditions/terms-conditions.component';
import { PackageComponent } from './components/package-components/package/package.component';
import { MemberDetailsComponent } from './components/members/member-details/member-details.component';
import { MembershipCardsComponent } from './components/members/membership-cards/membership-cards.component';
import { CustomerSupportComponent } from './components/members/customer-support/customer-support.component';
import { EmployerTransferComponent } from './components/members/employer-transfer/employer-transfer.component';
import { EtCompleteComponent } from './components/members/employer-transfer/et-complete/et-complete.component';
import { MemberssearchComponent } from './components/members/memberssearch/memberssearch.component';
import { MembersviewComponent } from './components/members/membersview/membersview.component';
import { SuspendMembershipComponent } from './components/members/suspend-membership/suspend-membership.component';
import { MemberEditComponent } from './components/members/memberedit/memberedit.component';
import { ManuallyUploadComponent } from './components/finance-components/manually-upload/manually-upload.component';
import { ViewPaymentRequestsComponent } from './components/finance-components/view-payment-requests/view-payment-requests.component';
import { FacilitatorFacilityDetailsComponent } from './components/facilitator-components/facilitator-facility-details/facilitator-facility-details.component';
import { FacilitatorFacilityMembersComponent } from './components/facilitator-components/facilitator-facility-members/facilitator-facility-members.component';
import { FaciliatorFacilityMembersCheckInComponent } from './components/facilitator-components/faciliator-facility-members-check-in/faciliator-facility-members-check-in.component';
import { EmployerPackagePriceUpdatesComponent } from '@fp/components/employer-components/package-price-updates/package-price-updates.component';
import { ApprovePaymentRequestComponent } from './components/finance-components/view-payment-requests/approve-payment-request/approve-payment-request.component';
import { CreatePaymentRequestComponent } from './components/finance-components/view-payment-requests/create-payment-request/create-payment-request.component';
import { SystemParametersComponent } from './components/finance-components/system-parameters/system-parameters.component';
import { AddManualVisitsComponent } from './components/finance-components/view-payment-requests/create-payment-request/add-manual-visits/add-manual-visits.component';
import { ManuallyAddDebitCreditBoxComponent } from './components/members/memberedit/visits-payments/manually-add-debit-credit-box/manually-add-debit-credit-box.component';
import { TaskListComponent } from './components/finance-components/task-list/task-list.component';
import { EditPaymentRequestComponent } from './components/finance-components/view-payment-requests/edit-payment-request/edit-payment-request.component';
import { EditManualVisitsComponent } from './components/finance-components/view-payment-requests/edit-payment-request/edit-manual-visits/edit-manual-visits.component';
import { ViewPaymentsAndInvoicesComponent } from './components/finance-components/view-payments-and-invoices/view-payments-and-invoices.component';
import { DebitProcessingComponent } from './components/finance-components/debit-processing/debit-processing.component';
import { BulkUploadDebitCreditComponent } from './components/finance-components/bulk-upload-debit-credit/bulk-upload-debit-credit.component';
import { EditDebitProcessingComponent } from './components/finance-components/debit-processing/edit-debit-processing/edit-debit-processing.component';
import { MemberTestingComponent } from './components/testing/member-testing/member-testing.component';
import { TrainingMaterialComponent } from './components/training-material/training-material.component';
import { MembernotificationsComponent } from './components/members/membernotifications/membernotifications.component';
import { BotLogsComponent } from './components/bot-logs/bot-logs.component';
import { DownloadPDFComponent } from './components/reporting/download-PDF/download-PDF.component';
import { PrNotesComponent } from './components/facility-components/addfacility/visits-payment/pr-notes/pr-notes.component';
import { ViewparentgroupComponent } from './components/parentgroup-components/viewparentgroup/viewparentgroup.component';
import { AddeditparentgroupsComponent } from './components/parentgroup-components/addeditparentgroup/addeditparentgroup.component';
import { MembershipRegistrationNewComponent } from './components/membership-registration-new/membership-registration-new.component';
import { MapViewNewComponent } from './map-view-new/map-view-new.component';
import { MapListViewNewComponent } from './map-list-view-new/map-list-view-new.component';
import { AddBankCodeComponentComponent } from './components/admin-component/add-bank-code-component/add-bank-code-component.component';
import { AddBsbComponentComponent } from './components/admin-component/add-bsb-component/add-bsb-component.component';
import { BankCodeHistoryComponentComponent } from './components/admin-component/add-bank-code-component/bank-code-history-component/bank-code-history-component.component';
import { BsbHistoryComponentComponent } from './components/admin-component/add-bsb-component/bsb-history-component/bsb-history-component.component';
import { ContinueMemberSignUpComponent } from './components/signup-component/continue-membersignup/continue-membersignup.component';
import { BulkUploadPostcodeComponent } from './components/admin-component/bulk-upload-postcode/bulk-upload-postcode.component';
import { EditTermsConditionsComponent } from './components/admin-component/edit-terms-conditions/edit-terms-conditions.component';
import { TwoFactorSelectorComponent } from './components/login-component/two-factor-selector/two-factor-selector.component';
import { TwoFactorAuthenticationComponent } from './components/login-component/two-factor-selector/two-factor-authentiation/two-factor-authentiation.component';
import { MemberReactivationComponent } from './components/members/member-reactivation/member-reactivation/member-reactivation.component';
import { ContinueReactivationComponent } from './components/members/member-reactivation/continue-reactivation/continue-reactivation.component';
import { SecurityPreferencesComponent } from './components/members/security-preferences/security-preferences.component';
import { SecurityPreferencesUpdatePhoneComponent } from './components/members/security-preferences-update-phone/security-preferences-update-phone.component';
import { SurveySignupComponent } from './components/signup-component/survey-signup/survey-signup.component';
import { PreSignupMemberSurveyComponent } from './components/survey-components/pre-signup-member-survey/pre-signup-member-survey.component';
import { ContinuePreSignupSurveyComponent } from './components/survey-components/continue-pre-signup-survey/continue-pre-signup-survey.component';
import { ConfirmAccountComponent } from './components/signup-component/confirm-account/confirm-account.component';
import { ConfirmAccountCompletedComponent } from './components/signup-component/confirm-account/completed/completed.component';
import { UserRegisterComponent } from './components/signup-component/user-register/user-register.component';

const routes: Routes = [
  {
    path: RouterConstant.ROUTER_HOME,
    component: HomeComponent,
  },
  {
    path: RouterConstant.ROUTER_CONFIRM_ACCOUNT,
    component: ConfirmAccountComponent,
  },
  {
    path: RouterConstant.ROUTER_CONFIRM_ACCOUNT_COMPLETED,
    component: ConfirmAccountCompletedComponent,
  },
  {
    path: RouterConstant.ROUTER_LOGIN,
    component: LoginComponent,
  },
  {
    path: RouterConstant.ROUTER_SIGNUP,
    component: LoginComponent,
  },
  {
    path: RouterConstant.ROUTER_DOWNLOAD_PDF,
    component: DownloadPDFComponent,
  },
  {
    path: RouterConstant.ROUTER_SIGNUP,
    component: UserRegisterComponent,
  },
  {
    path: RouterConstant.ROUTER_SIGNUP_Survey,
    component: SurveySignupComponent,
  },
  {
    path: RouterConstant.ROUTER_CONTINUE_MEMBER_SIGNUP,
    component: MembershipRegistrationNewComponent,
  },
  {
    path: RouterConstant.ROUTER_MEMBER_CONTINUE_REACTIVATE_MEMBER,
    component: ContinueReactivationComponent,
  },
  {
    path: RouterConstant.ROUTER_MEMBER_CONTINUE_PRE_SIGNUP_SURVEY,
    component: ContinuePreSignupSurveyComponent,
  },
  {
    path: RouterConstant.ROUTER_REACTIVATE_MEMBER,
    component: MemberReactivationComponent,
  },
  {
    path: RouterConstant.ROUTER_MEMBER_PRE_SIGNUP_SURVEY,
    component: PreSignupMemberSurveyComponent,
  },
  {
    path: 'map-view',
    component: MapViewNewComponent,
  },
  {
    path: 'map-list-view',
    component: MapListViewNewComponent,
  },
  {
    path: RouterConstant.ROUTER_SIGNUP_CAPITAL,
    component: SignupComponent,
  },
  {
    path: RouterConstant.ROUTER_USER_CHANGE_PASSWORD,
    component: ChangepasswordComponent,
  },
  {
    path: RouterConstant.ROUTER_MEMBER_SIGNUP,
    component: MemberSignupComponent,
  },
  {
    path: RouterConstant.ROUTER_MEMBER_CONTINUE_SIGNUP,
    component: ContinueMemberSignUpComponent,
  },
  {
    path: RouterConstant.ROUTER_MEMBER_CONTINUE_REACTIVATE_MEMBER,
    component: ContinueReactivationComponent,
  },
  {
    path: RouterConstant.ROUTER_MEMBER_CONTINUE_PRE_SIGNUP_SURVEY,
    component: ContinuePreSignupSurveyComponent,
  },
  {
    path: RouterConstant.ROUTER_REACTIVATE_MEMBER,
    component: MemberReactivationComponent,
  },
  {
    path: RouterConstant.ROUTER_MEMBER_PRE_SIGNUP_SURVEY,
    component: PreSignupMemberSurveyComponent,
  },
  {
    path: RouterConstant.ROUTER_TERMS_CONDITIONS,
    component: TermsConditionsComponent,
  },
  {
    path: RouterConstant.ROUTER_USER_EDIT_DETAILS,
    component: EdituserdetailsComponent,
  },
  {
    path: RouterConstant.ROUTER_USER_END_EDIT_DETAILS,
    component: EnduserscreenComponent,
  },
  {
    path: RouterConstant.ROUTER_ABOUT,
    component: AboutComponent,
  },
  {
    path: RouterConstant.ROUTER_DASHBOARD,
    canActivate: [RolePermissionService],
    component: DashboardComponent,
    children: [
      {
        path: RouterConstant.ROUTER_MEMBER_CONTINUE_SIGNUP,
        component: ContinueMemberSignUpComponent,
      },

      {
        path: RouterConstant.ROUTER_MEMBER_CONTINUE_REACTIVATE_MEMBER,
        component: ContinueReactivationComponent,
      },

      {
        path: RouterConstant.ROUTER_MEMBER_CONTINUE_PRE_SIGNUP_SURVEY,
        component: ContinuePreSignupSurveyComponent,
      },

      {
        path: RouterConstant.ROUTER_REACTIVATE_MEMBER,
        component: MemberReactivationComponent,
      },

      {
        path: RouterConstant.ROUTER_MEMBER_PRE_SIGNUP_SURVEY,
        component: PreSignupMemberSurveyComponent,
      },

      {
        path: RouterConstant.ROUTER_END_MEMBER_SIGNUP,
        component: EndmembersignupComponent,
      },

      {
        path: RouterConstant.ROUTER_EMPLOYER,
        canActivate: [RolePermissionService],
        component: EmployerComponent,
      },

      {
        path: RouterConstant.ROUTER_SYSTEM_PARAMETERS,
        canActivate: [RolePermissionService],
        component: SystemParametersComponent,
      },

      {
        path: RouterConstant.ROUTER_MANUALLY_UPLOAD,
        canActivate: [RolePermissionService],
        component: ManuallyUploadComponent,
      },

      {
        path: RouterConstant.ROUTER_VIEW_PAYMENT_REQUESTS,
        canActivate: [RolePermissionService],
        component: ViewPaymentRequestsComponent,
      },
      {
        path: RouterConstant.ROUTER_EDIT_PAYMENT_REQUEST,
        canActivate: [RolePermissionService],
        component: EditPaymentRequestComponent,
      },

      {
        path: RouterConstant.ROUTER_VIEW_PAYMENT_REQUESTS_APPROVE,
        canActivate: [RolePermissionService],
        component: ApprovePaymentRequestComponent,
      },
      {
        path: RouterConstant.ROUTER_VIEW_PAYMENT_REQUESTS_CREATE,
        canActivate: [RolePermissionService],
        component: CreatePaymentRequestComponent,
      },

      {
        path: RouterConstant.ROUTER_VIEW_PAYMENT_REQUESTS_ADD_MANUAL_VISITS,
        canActivate: [RolePermissionService],
        component: AddManualVisitsComponent,
      },
      {
        path: RouterConstant.ROUTER_VIEW_PAYMENTS_AND_INVOICES,
        canActivate: [RolePermissionService],
        component: ViewPaymentsAndInvoicesComponent,
      },
      {
        path: RouterConstant.ROUTER_DEBIT_PROCESSING,
        canActivate: [RolePermissionService],
        component: DebitProcessingComponent,
      },
      {
        path: RouterConstant.ROUTER_EDIT_DEBIT_PROCESSING,
        canActivate: [RolePermissionService],
        component: EditDebitProcessingComponent,
      },
      {
        path: RouterConstant.ROUTER_EDIT_MANUAL_VISITS,
        canActivate: [RolePermissionService],
        component: EditManualVisitsComponent,
      },
      {
        path: RouterConstant.ROUTER_TASK_LIST,
        canActivate: [RolePermissionService],
        component: TaskListComponent,
      },
      {
        path: RouterConstant.ROUTER_BULK_UPLOAD_DEBIT_CREDIT,
        canActivate: [RolePermissionService],
        component: BulkUploadDebitCreditComponent,
      },

      {
        path: RouterConstant.ROUTER_ADD_BANK_CODE,
        canActivate: [RolePermissionService],
        component: AddBankCodeComponentComponent,
      },

      {
        path: RouterConstant.ROUTER_ADD_BSB,
        canActivate: [RolePermissionService],
        component: AddBsbComponentComponent,
      },

      {
        path: RouterConstant.ROUTER_VIEW_BANK_CODE_HISTORY,
        canActivate: [RolePermissionService],
        component: BankCodeHistoryComponentComponent,
      },

      {
        path: RouterConstant.ROUTER_VIEW_BSB_HISTORY,
        canActivate: [RolePermissionService],
        component: BsbHistoryComponentComponent,
      },
      {
        path: RouterConstant.ROUTER_UPLOAD_POSTCODE,
        canActivate: [RolePermissionService],
        component: BulkUploadPostcodeComponent,
      },
      {
        path: RouterConstant.ROUTER_EDIT_TERMS_CONDITIONS,
        canActivate: [RolePermissionService],
        component: EditTermsConditionsComponent,
      },

      {
        path: RouterConstant.ROUTER_EMPLOYER,
        canActivate: [RolePermissionService],
        component: EmployerComponent,
      },
      {
        path: RouterConstant.ROUTER_EMPLOYER_VIEWEDIT,
        canActivate: [RolePermissionService],
        component: VieweditemployerComponent,
      },
      {
        path: RouterConstant.ROUTER_EMPLOYER_VIEWEDIT_BYREGION,
        canActivate: [RolePermissionService],
        component: VieweditemployerComponent,
      },
      {
        path: RouterConstant.ROUTER_EMPLOYER_ADD,
        canActivate: [RolePermissionService],
        component: AddemployerComponent,
      },
      {
        path: RouterConstant.ROUTER_EMPLOYER_ADD_CLONE,
        canActivate: [RolePermissionService],
        component: AddemployerComponent,
      },
      {
        path: RouterConstant.ROUTER_EMPLOYER_EDIT,
        canActivate: [RolePermissionService],
        component: AddemployerComponent,
      },
      {
        path: RouterConstant.ROUTER_EMPLOYER_SEARCH,
        canActivate: [RolePermissionService],
        component: SearchemployerComponent,
      },
      {
        path: RouterConstant.ROUTER_EMPLOYER_READ_ONLY,
        canActivate: [RolePermissionService],
        component: AddemployerComponent,
      },
      {
        path: RouterConstant.ROUTER_EMPLOYER_DRAFT,
        canActivate: [RolePermissionService],
        component: DraftemployerComponent,
      },
      {
        path: RouterConstant.ROUTER_EMPLOYER_PACKAGE_PRICE_UPDATES,
        canActivate: [RolePermissionService],
        component: EmployerPackagePriceUpdatesComponent,
      },
      {
        path: RouterConstant.ROUTER_REGION_ADD,
        canActivate: [RolePermissionService],
        component: CreatenewregionComponent,
      },
      {
        path: RouterConstant.ROUTER_REGION_VIEWEDIT,
        canActivate: [RolePermissionService],
        component: VieweditregionsComponent,
      },
      {
        path: RouterConstant.ROUTER_REGION_EDIT,
        canActivate: [RolePermissionService],
        component: EditregionsComponent,
      },
      {
        path: RouterConstant.ROUTER_REGION_SEARCH,
        canActivate: [RolePermissionService],
        component: SearchregionsComponent,
      },
      {
        path: RouterConstant.ROUTER_USER_ADD,
        canActivate: [RolePermissionService],
        component: AddusersComponent,
      },
      {
        path: RouterConstant.ROUTER_USER_COMPLETE_ADD,
        canActivate: [RolePermissionService],
        component: CompleteaddusersComponent,
      },
      {
        path: RouterConstant.ROUTER_USER_EDIT,
        canActivate: [RolePermissionService],
        component: EditusersComponent,
      },
      {
        path: RouterConstant.ROUTER_USER_VIEW,
        canActivate: [RolePermissionService],
        component: ViewusersComponent,
      },
      {
        path: RouterConstant.ROUTER_USER_VIEW_PARAM,
        canActivate: [RolePermissionService],
        component: ViewusersComponent,
      },
      {
        path: RouterConstant.ROUTER_USER_SEARCH,
        canActivate: [RolePermissionService],
        component: SearchusersComponent,
      },
      {
        path: RouterConstant.ROUTER_USER_ROLES,
        canActivate: [RolePermissionService],
        component: UserrolesComponent,
      },
      {
        path: RouterConstant.ROUTER_FACILITY,
        canActivate: [RolePermissionService],
        component: FacilitiesComponent,
      },
      {
        path: RouterConstant.ROUTER_FACILITY_ADD,
        canActivate: [RolePermissionService],
        component: AddFacilityComponent,
      },
      {
        path: RouterConstant.ROUTER_FACILITY_EDIT,
        canActivate: [RolePermissionService],
        component: AddFacilityComponent,
      },
      {
        path: RouterConstant.ROUTER_FACILITY_CLONE,
        canActivate: [RolePermissionService],
        component: AddFacilityComponent,
      },
      {
        path: RouterConstant.ROUTER_FACILITY_SEARCH,
        canActivate: [RolePermissionService],
        component: SearchfacilityComponent,
      },
      {
        path: RouterConstant.ROUTER_FACILITY_DRAFT,
        component: DraftfacilityComponent,
      },
      {
        path: RouterConstant.ROUTER_FACILITIES_VIEW,
        canActivate: [RolePermissionService],
        component: VieweditfacilitiesComponent,
      },
      {
        path: RouterConstant.ROUTER_PRODUCT_SERVICE_VIEW,
        canActivate: [RolePermissionService],
        component: ViewproductsservicesComponent,
      },
      {
        path: RouterConstant.ROUTER_PRODUCT_SERVICE_ADD,
        canActivate: [RolePermissionService],
        component: AddeditproductsservicesComponent,
      },
      {
        path: RouterConstant.ROUTER_PRODUCT_SERVICE_EDIT,
        canActivate: [RolePermissionService],
        component: AddeditproductsservicesComponent,
      },
      {
        path: RouterConstant.ROUTER_EMPLOYER_PREVIEW,
        canActivate: [RolePermissionService],
        component: PreviewemployerComponent,
      },
      {
        path: RouterConstant.ROUTER_NO_PERMISSION,
        component: NoPermissionComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_VERIFICATION,
        canActivate: [RolePermissionService],
        component: MembersVerificationComponent,
      },
      {
        path: RouterConstant.ROUTER_PACKAGE,
        canActivate: [RolePermissionService],
        component: PackageComponent,
      },
      {
        path: RouterConstant.ROUTER_PACKAGE_ADD,
        canActivate: [RolePermissionService],
        component: AddPackageComponent,
      },
      {
        path: RouterConstant.ROUTER_PACKAGE_EDIT,
        canActivate: [RolePermissionService],
        component: AddPackageComponent,
      },
      {
        path: RouterConstant.ROUTER_PACKAGE_CLONE,
        canActivate: [RolePermissionService],
        component: AddPackageComponent,
      },
      {
        path: RouterConstant.ROUTER_PACKAGE_SEARCH,
        canActivate: [RolePermissionService],
        component: SearchPackageComponent,
      },
      {
        path: RouterConstant.ROUTER_PACKAGE_VIEW,
        canActivate: [RolePermissionService],
        component: ViewPackagesComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_DETAILS,
        canActivate: [RolePermissionService],
        component: MemberDetailsComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_SECURITY_PREFERENCES,
        component: SecurityPreferencesComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_SECURITY_PREFERENCES_UPDATE_PHONE,
        component: SecurityPreferencesUpdatePhoneComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBERSHIP_CARDS,
        canActivate: [RolePermissionService],
        component: MembershipCardsComponent,
      },
      {
        path: RouterConstant.ROUTER_SUSPEND_MEMBERSHIP,
        canActivate: [RolePermissionService],
        component: SuspendMembershipComponent,
      },
      {
        path: RouterConstant.ROUTER_CUSTOMER_SUPPORT,
        canActivate: [RolePermissionService],
        component: CustomerSupportComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_TRANSFER,
        canActivate: [RolePermissionService],
        component: EmployerTransferComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_PAYMENTS_HISTORY,
        canActivate: [RolePermissionService],
        component: MemberPaymentsHistoryReceiptsComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_CONTRACTS,
        canActivate: [RolePermissionService],
        component: MemberContractsComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_UPDATE_PAYMENTS_DETAILS,
        canActivate: [RolePermissionService],
        component: MemberUpdatePaymentsDetailsComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_TRANSFER_COMPLETE,
        canActivate: [RolePermissionService],
        component: EtCompleteComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_SEARCH,
        canActivate: [RolePermissionService],
        component: MemberssearchComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_VIEW,
        canActivate: [RolePermissionService],
        component: MembersviewComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_VIEW_SEARCH,
        canActivate: [RolePermissionService],
        component: MembersviewComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_EDIT,
        canActivate: [RolePermissionService],
        component: MemberEditComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_MANUALLY_ADD_DEBIT_CREDIT_BOX,
        canActivate: [RolePermissionService],
        component: ManuallyAddDebitCreditBoxComponent,
      },
      {
        path: RouterConstant.ROUTER_FACILITY_DETAILS,
        canActivate: [RolePermissionService],
        component: FacilitatorFacilityDetailsComponent,
      },
      {
        path: RouterConstant.ROUTER_FACILITY_MEMBERS,
        canActivate: [RolePermissionService],
        component: FacilitatorFacilityMembersComponent,
      },
      {
        path: RouterConstant.ROUTER_FACILITY_MEMBERS_CHECK_IN,
        canActivate: [RolePermissionService],
        component: FaciliatorFacilityMembersCheckInComponent,
      },
      {
        path: RouterConstant.ROUTER_CHANGE_MEMBERSHIP_PACKAGE,
        canActivate: [RolePermissionService],
        component: MemberChangeMembershipPackageComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_TESTING,
        component: MemberTestingComponent,
      },
      {
        path: RouterConstant.ROUTER_TRAINING_MATERIAL_VIEW,
        canActivate: [RolePermissionService],
        component: TrainingMaterialComponent,
      },
      {
        path: RouterConstant.ROUTER_TRAINING_MATERIAL_UPLOAD,
        canActivate: [RolePermissionService],
        component: TrainingMaterialComponent,
      },
      {
        path: RouterConstant.ROUTER_GENERATE_REPORTS,
        canActivate: [RolePermissionService],
        component: GenerateReportFPComponent,
      },
      {
        path: RouterConstant.ROUTER_GENERATE_REPORTS_EF_FSM,
        component: GenerateReportsEFFSMComponent,
      },
      {
        path: RouterConstant.ROUTER_MEMBER_NOTIFICATIONS,
        canActivate: [RolePermissionService],
        component: MembernotificationsComponent,
      },
      {
        path: RouterConstant.ROUTER_BOT_LOGS,
        canActivate: [RolePermissionService],
        component: BotLogsComponent,
      },
      {
        path: RouterConstant.ROUTER_FACILITY_EDIT_PR_NOTES,
        canActivate: [RolePermissionService],
        component: PrNotesComponent,
      },
      {
        path: RouterConstant.ROUTER_PARENT_GROUP_VIEW,
        canActivate: [RolePermissionService],
        component: ViewparentgroupComponent,
      },
      {
        path: RouterConstant.ROUTER_PARENT_GROUP_ADD,
        canActivate: [RolePermissionService],
        component: AddeditparentgroupsComponent,
      },
      {
        path: RouterConstant.ROUTER_PARENT_GROUP_EDIT,
        canActivate: [RolePermissionService],
        component: AddeditparentgroupsComponent,
      },
    ],
  },

  {
    path: 'pagenotfound',
    component: PagenotfoundComponent,
  },
  {
    path: '**',
    component: EmployerportalComponent,
    data: {
      title: 'no-index',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
