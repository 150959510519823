import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProductService, ServiceType } from 'src/app/models';
import { ProductServiceService } from 'src/app/services/product-service.service';
import { MessageBox } from 'src/app/services/common-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonMessage } from 'src/app/constant/common-message';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterConstant } from 'src/app/constant/routerconstant';
import { APIConstant } from 'src/app/constant/apiconstant';
import { startWith, switchMap, catchError } from 'rxjs/operators';
import { empty, merge } from 'rxjs';

@Component({
    selector: 'app-addeditproductsservices',
    templateUrl: './addeditproductsservices.component.html',
    styleUrls: ['./addeditproductsservices.component.css']
})
export class AddeditproductsservicesComponent implements OnInit, OnDestroy {

    productsServicesFormGroup: UntypedFormGroup;
    btnSubmit = false;
    psTypeSpinter = false;
    categories: ProductService;
    routerConstant = RouterConstant;
    isCreatePS = true;
    editCurrentName = "";

    ngOnDestroy() {
        this.categories = null;
        this.editCurrentName = null;
    }

    constructor(private _formBuilder: UntypedFormBuilder,
        private psService: ProductServiceService,
        private dialog: MatDialog,
        public router: Router,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        const id: number = +this.route.snapshot.params.id;
        if (typeof (this.route.snapshot.params.id) != "undefined") {
            this.loadProductService(id);
            this.isCreatePS = false;
        }
        this.getCategories();
        this.createPSForm();
    }

    createPSForm() {
        this.productsServicesFormGroup = this._formBuilder.group({
            ddlCategory: ['', Validators.required],
            txtProdtSerc: ['', [
                Validators.required,
                Validators.maxLength(50)
            ]],
            ddlStatus: [true, Validators.required],
        });
    }

    loadProductService(id: number) {
        this.psService.getData(
            APIConstant.API_GET_PS_BY_ID + id,
            (result) => {
                this.editCurrentName = result.Data.Name;
                this.productsServicesFormGroup.get("ddlCategory").setValue(result.Data.Category.Id);
                this.productsServicesFormGroup.get("txtProdtSerc").setValue(result.Data.Name);
                this.productsServicesFormGroup.get("ddlStatus").setValue(result.Data.IsActive);
            }
        );
    }

    getCategories() {
        this.psService.getData(
            APIConstant.API_GET_CATEGORIES,
            (result) => {
                this.categories = result.Data;
            }
        );
    }

    Validate(): boolean {
        if (this.productsServicesFormGroup.get("ddlCategory").valid &&
            this.productsServicesFormGroup.get("txtProdtSerc").valid &&
            this.productsServicesFormGroup.get("ddlStatus").valid) {
            return true;
        } else {
            return false;
        }
    }

    async submitProdtSerc() {
        // Check the service name isn't empty before trying to submit.
        let newName = this.productsServicesFormGroup.get("txtProdtSerc").value.trim();

        if (newName.length <= 0) {
            this.productsServicesFormGroup.get("txtProdtSerc").setErrors({ required: true });
            return;
        }

        // Check the input Product/Service doesn't already exist in another record.
        // If it does, cancel submission and throw up a dialogue box.
        this.psTypeSpinter = true;
        let checkServiceExistsResult = await this.psService.getByName(newName).toPromise();
        this.psTypeSpinter = false;
        if(checkServiceExistsResult.Success ) {
            if(checkServiceExistsResult.Data !== null && newName !== this.editCurrentName) {
                this.productsServicesFormGroup.get("txtProdtSerc").setErrors({ duplicated: true });
                MessageBox.ShowError(this.dialog, "This Product/Service Type already exists!");
                return;
            }
            else {
                this.productsServicesFormGroup.get("txtProdtSerc").setErrors(null);
            }
        }
        else {
            MessageBox.ShowError(this.dialog, "An error has occurred while checking the Product/Service didn't already exist. Please contact the administrator");
            return;
        }

        if (!this.Validate()) { return; }

        // If we have successfully named the service something unique, save it.
        let prodtSerc = new ServiceType();
        let apiString: string;
        if (this.isCreatePS) {
            prodtSerc.CategoryId = this.productsServicesFormGroup.get("ddlCategory").value;
            prodtSerc.Name = this.productsServicesFormGroup.get("txtProdtSerc").value;
            prodtSerc.IsActive = this.productsServicesFormGroup.get("ddlStatus").value;
            apiString = APIConstant.API_CREATE_PS;
        } else {
            prodtSerc.Id = +this.route.snapshot.params.id;
            prodtSerc.CategoryId = this.productsServicesFormGroup.get("ddlCategory").value;
            prodtSerc.Name = this.productsServicesFormGroup.get("txtProdtSerc").value;
            prodtSerc.IsActive = this.productsServicesFormGroup.get("ddlStatus").value;
            apiString = APIConstant.API_UPDATE_PS;
        }
        this.btnSubmit = true;
        this.psService.postData(
            apiString,
            (postNewServiceResult) => {
                if (postNewServiceResult.Success) {
                    this.showSaveSuccessDialog();
                } else {
                    MessageBox.ShowError(this.dialog, 'An error has occurred. Please contact the administrator');
                }
            },
            prodtSerc
        );
    }

    private showSaveSuccessDialog() {
        MessageBox.ShowCustom(this.dialog, 'Information', 'Information',
            CommonMessage.DATA_SAVE_SUCCESS_MESSAGE).subscribe(
                (dialogResult) => {
                    if (dialogResult.result.toLowerCase() === 'ok') {
                        this.router.navigate(['/' + RouterConstant.ROUTER_DASHBOARD + '/' + RouterConstant.ROUTER_PRODUCT_SERVICE_VIEW]);
                    }
                }
            );
    }
}
