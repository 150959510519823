<div class="fp-map d-flex max-w100">
        <div class="map-sidebar" [ngClass]="{ 'map-sidebar-collapsed': mapSidebarCollapsed }">
            <div class="map-sidebar-toggle-container">
                <button class="map-sidebar-toggle" (click)="toggleMapSidebar()"
                    title="{{mapSidebarCollapsed ? 'Expand' : 'Collapse' }} side panel" type="button">
                    <i class="fas" [ngClass]="mapSidebarCollapsed ? 'fa-caret-right' : 'fa-caret-left'"></i>
                </button>
            </div>
            <div class="card-title">
                <p><strong>Search facilities by area on map</strong></p>
                <input type="text" placeholder="Search Map by Postcode or Suburb" class="form-control"
                    [disabled]="disableSearchFacByArea" [(ngModel)]='mapLocationSearch' (input)='disableOrEnableSearchBar("suburb")' (change)='searchBarFunctionality("suburb")'>
                <hr>
                <p><strong>Search facilities by name on list</strong></p>
                <input type="text" placeholder="Search List by facility name or service" class="form-control"
                    [disabled]="disableSearchFacByName" [(ngModel)]='listLocationFilter' (input)='disableOrEnableSearchBar("facility")' (change)='searchBarFunctionality("facility")'>
                <hr>
            </div>
            <div class="card-body" id="card-body">
                <div *ngFor="let facility of filteredDataSource;let i=index">
                    <ng-container *ngTemplateOutlet="tmplFacilityDetails;context:{f: facility}"></ng-container>
                    <hr *ngIf="i < dataSource.length - 1">
                </div>
                <div *ngIf="filteredDataSource.length === 0">No results found</div>
            </div>
        </div>
        <agm-map  id ="map" (zoomChange)="collapseSearchBar()" (centerChange)="collapseSearchBar()" #map [minZoom]="4" [(zoom)]="location.zoom" class="w-100" [(latitude)]="location.lat"
            [(longitude)]="location.lng" [usePanning]='true' >
            <agm-marker-cluster
                imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
                <div *ngFor="let facility of mapDataSource">
                    <agm-marker [latitude]="facility.Address.Latitude" [longitude]="facility.Address.Longitude" 
                        (markerClick)="closePreviousInfoWindow(infoWindow)" [title]="facility.Name"
                        [iconUrl]="{url: iconUrl, scaledSize: {width: 26.38, height: 60}}">
                        <agm-info-window #infoWindow>
                            <ng-container
                                *ngTemplateOutlet="tmplFacilityDetails; context:{f: facility, displayServices: false}">
                            </ng-container>
                        </agm-info-window>
                    </agm-marker>
                </div>
            </agm-marker-cluster>
        </agm-map>
        <div class="centre" *ngIf="mapIsLoading">
            <mat-spinner [diameter]="50"></mat-spinner>
        </div>
</div>
    
    <ng-template #tmplFacilityDetails let-f="f" let-displayServices="displayServices">
        <p><strong>{{f.Name}}</strong></p>
        <p>{{f.Address.StreetAddress1}}</p>
        <p>{{f.Suburb.Name}} {{f.Postcode.Code}}</p>
        <p><strong>Phone: </strong>{{f.FacilityPhoneNumber}}</p>
        <div *ngIf="displayServices == null || (displayServices && f.Services?.length > 0)">
            <p><strong>Services:</strong></p>
            <p *ngFor="let s of f.Services">
                {{s.ServiceName}}
            </p>
        </div>
        <div *ngIf="f.Is24Hour || f.FacilityStaffedHour">
            <p><strong>Operating Hours: </strong></p>
            <div *ngIf="f.Is24Hour;else tmplStaffedHours">
                <p>Open 24 hours</p>
            </div>
            <ng-template #tmplStaffedHours>
                <div>
                    <ul>
                        <li *ngIf="f.FacilityStaffedHour.MondayFrom !== null">Mon:
                            {{formatTime(f.FacilityStaffedHour.MondayFrom)}} to
                            {{formatTime(f.FacilityStaffedHour.MondayTo)}}</li>
                        <li *ngIf="f.FacilityStaffedHour.TuesdayFrom !== null">Tue:
                            {{formatTime(f.FacilityStaffedHour.TuesdayFrom)}} to
                            {{formatTime(f.FacilityStaffedHour.TuesdayTo)}}</li>
                        <li *ngIf="f.FacilityStaffedHour.WednesdayFrom !== null">Wed:
                            {{formatTime(f.FacilityStaffedHour.WednesdayFrom)}} to
                            {{formatTime(f.FacilityStaffedHour.WednesdayTo)}}</li>
                        <li *ngIf="f.FacilityStaffedHour.ThursdayFrom !== null">Thu:
                            {{formatTime(f.FacilityStaffedHour.ThursdayFrom)}} to
                            {{formatTime(f.FacilityStaffedHour.ThursdayTo)}}</li>
                        <li *ngIf="f.FacilityStaffedHour.FridayFrom !== null">Fri:
                            {{formatTime(f.FacilityStaffedHour.FridayFrom)}} to
                            {{formatTime(f.FacilityStaffedHour.FridayTo)}}</li>
                        <li *ngIf="f.FacilityStaffedHour.SaturdayFrom !== null">Sat:
                            {{formatTime(f.FacilityStaffedHour.SaturdayFrom)}} to
                            {{formatTime(f.FacilityStaffedHour.SaturdayTo)}}</li>
                        <li *ngIf="f.FacilityStaffedHour.SundayFrom !== null">Sun:
                            {{formatTime(f.FacilityStaffedHour.SundayFrom)}} to
                            {{formatTime(f.FacilityStaffedHour.SundayTo)}}</li>
                    </ul>
                </div>
            </ng-template>
        </div>
        <p><strong>Access Card Required: </strong>{{f.IsAccessCard ? 'Yes' : 'No'}}</p>
        <p *ngIf="f.IsAccessCard"><strong>Access Card Fee:
            </strong>{{Utils.convertPriceFormat(f.AccessCardFee)}}</p>
        <a class="btn btn-primary" href="{{f.WebsiteUrl}}" target="_blank">View Website</a> <br> <br>
        <a class="btn btn-primary clicklink" (click)=clickToMaps(f)>View on Map</a>
    </ng-template>