export const DataSourceHelper = {
  localeLowerCaseSortingDataAccessor<T>(
    data: T,
    sortHeaderId: string
  ): string | number {
    // VisitDateTime is weird, sort it in a particular way
    if (sortHeaderId.toLowerCase().includes("visitdatetime")) {
      return new Date(data["VisitDateTime"]).getTime();
    }
    // PaymentPeriod is weird, sort it in a particular way
    if (sortHeaderId.toLowerCase().includes("datecreated")) {
      return new Date(data["PaymentPeriodStart"]).getTime();
    }

    // DateTime should be converted to a Date Object to be sorted
    var dateTypes = ["datetime", "date", "time"];
    if (dateTypes.includes(sortHeaderId.toLowerCase())) {
      return new Date(data[sortHeaderId]).getTime();
    }
    // Sort strings as LowerCase
    if (typeof data[sortHeaderId] === "string") {
      return data[sortHeaderId].toLocaleLowerCase();
    }

    return data[sortHeaderId];
  }
};
