<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Package Price Updates</h4>
<div class="tabFPTheme tbFPPackagePUTheme mb-5">
    <ngb-accordion [destroyOnHide]="false" activeIds="pnlPackageExporter,pnlPackageImporter">
        <ngb-panel id="pnlPackageExporter">
            <ng-template ngbPanelTitle>
                <i class="icon-fp fas fa-check"></i>
                Export Package Details
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-employer-membership-package-exporter></app-employer-membership-package-exporter>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlPackageImporter">
            <ng-template ngbPanelTitle>
                Import Bulk Package Data
                <i [ngClass]="1==1?'fas fa-check icon-fp':'icon-fp'"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-fp-csv-import #csvImporter lblSelectFile="SELECT UPLOAD FILE(S)" lblUploadFile="UPLOAD FILE(s)">
                </app-fp-csv-import>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>