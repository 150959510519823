import { MessageBox } from 'src/app/services/common-dialog.service';
import { ListPrivilegeModel } from './../../../models/listprivilege.model';
import { PrivilegeModel } from './../../../models/privilege.model';
import { APIConstant } from 'src/app/constant/apiconstant';
import { CommonService } from 'src/app/services/common.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpDAO } from '../../../services/httpdao.service';
import { CommonMessage } from 'src/app/constant';
@Component({
    selector: 'app-userroles',
    templateUrl: './userroles.component.html',
    styleUrls: ['./userroles.component.css']
})
export class UserrolesComponent implements OnInit, OnDestroy {
    IEPrivilege: PrivilegeModel[];

    frozenCols: any[];

    scrollableCols: any[];

    cols: any[];

    displayedColumns = ['Category', 'PrivilegeDisplayName', 'Admin', 'SuperUser',
        'AccountsPayableOfficerReadOnly', 'AccountsPayableOfficer', 'FinanceLevel1ReadOnly', 'FinanceLevel1',
        'FinanceLevel2', 'FinanceLevel3', 'BusinessDevelopmentReadOnly', 'BusinessDevelopmentLevel1', 'BusinessDevelopmentLevel2',
        'BusinessDevelopmentLevel3', 'ProductDevelopmentReadOnly',
        'ProductDevelopmentLevel1', 'ProductDevelopmentLevel2', 'ProductDevelopmentLevel3', 'OperationLevel1ReadOnly', 'OperationLevel1',
        'OperationLevel2', 'OperationLevel3', 'FacilityStaffMember', 'EmployerFacilitator', 'Member'];
    dataSource = new MatTableDataSource();
    httpdao: HttpDAO | null;

    ngOnDestroy() {
        this.IEPrivilege = null;
        this.frozenCols = null;
        this.scrollableCols = null;
        this.cols = null;
        this.displayedColumns = null;
        this.dataSource = null;
        this.httpdao = null;
    }

    constructor(
        private http: HttpClient,
        private commonservice: CommonService,
        private dialog: MatDialog) { }

    ngOnInit() {
        console.log(navigator.userAgent);
        this.httpdao = new HttpDAO(this.http);
        this.GetAllPrivilege();

        this.scrollableCols = [
            //{ field: 'SuperUser', header: 'Super User' },
            //{ field: 'AccountsPayableOfficerReadOnly', header: 'Accounts Payable Officer (read only)' },
            //{ field: 'AccountsPayableOfficer', header: 'Accounts Payable Officer' },
            //{ field: 'FinanceLevel1ReadOnly', header: 'Finance Level 1 (read only)' },
            //{ field: 'ProductDevelopmentReadOnly', header: 'Network Development 2 (Support Staff)' },
            //{ field: 'ProductDevelopmentLevel1', header: 'Product Development Level 1' },
            //{ field: 'ProductDevelopmentLevel2', header: 'Product Development Level 2' },
            //{ field: 'ProductDevelopmentLevel3', header: 'Product Development Level 3' },
            //{ field: 'Member', header: 'Member' },
            { field: 'Admin', header: 'Admin' },
            { field: 'FinanceLevel1', header: 'Finance 1 (Accounts Officer)' },
            { field: 'FinanceLevel2', header: 'Finance 2 (Finance Manager)' },
            { field: 'FinanceLevel3', header: 'Finance 3 (Senior Staff)' },
            { field: 'OperationLevel1', header: 'Operations 1 (Members Account Manager)' },
            { field: 'OperationLevel2', header: 'Operations 2 (Employers Account Manager)' },
            { field: 'OperationLevel3', header: 'Operations 3 (Operations Team Leader)' },
            { field: 'OperationLevel1ReadOnly', header: 'Operations 4 (Operations Australia)' },
            { field: 'BusinessDevelopmentLevel1', header: 'Business Development 1 (Customer Service)' },
            { field: 'BusinessDevelopmentLevel2', header: 'Business Development 2 (Australia)' },
            { field: 'BusinessDevelopmentLevel4', header: 'Business Development 3' },
            { field: 'BusinessDevelopmentLevel3', header: 'Network Development 1 (Program Development)' },
            { field: 'BusinessDevelopmentReadOnly', header: 'Network Development 2 (Support Staff)' },
            { field: 'BusinessDevelopmentLevel5', header: 'Network Development 3' },
            { field: 'EmployerFacilitator', header: 'Employer Facilitator (EF)' },
            { field: 'FacilityStaffMember', header: 'Facility Staff Member (FSM)' },
            { field: 'PrivilegedReportsAccess', header: 'Privileged Reports Access' }
        ];

        this.frozenCols = [
            { field: 'Category', header: 'Category' },
            { field: 'PrivilegeDisplayName', header: 'Privileges' }
        ];
    }

    private GetAllPrivilege() {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                this.commonservice.StartProgressBar();
                return this.httpdao!.getSingleData(APIConstant.API_GET_ALL_PRIVILEGES_V2);
            }),
            map(data => {
                this.commonservice.StopProgressBar();
                return data;
            }),
            catchError(() => {
                this.commonservice.StopProgressBar();
                return observableOf([]);
            })
        ).subscribe(data => {
            if (data != null) {
                const enctypted_data = data.Data;
                const dectypted_data = this.commonservice.D_FP_ResponseData(enctypted_data);
                const ret = JSON.parse(dectypted_data);

                this.dataSource = new MatTableDataSource<any>(ret);
                this.IEPrivilege = ret;
            } else {
                this.dataSource.data = [];
            }
        });
    }

    public UpdateAllPrivilege() {
        const listprivelageobj: ListPrivilegeModel = new ListPrivilegeModel();
        for (let i = 0; i < this.dataSource.data.length; i++) {
            const ret = this.dataSource.data[i];
            const ret2: PrivilegeModel = JSON.parse(JSON.stringify(ret));
            if (ret2.Changed) {
                listprivelageobj.ListOfPrivilegeViewModel.push(ret2);
            }
        }

        // const listprivelageobjIE = new ListPrivilegeModel();
        // for (let i = 0; i < this.IEPrivilege.length; i++) {
        //     const ret = this.IEPrivilege[i];
        //     const ret2: PrivilegeModel = JSON.parse(JSON.stringify(ret));
        //     listprivelageobjIE.ListOfPrivilegeViewModel.push(ret2);
        // }

        merge().pipe(
            startWith({}),
            switchMap(() => {
                this.commonservice.StartProgressBar();
                return this.httpdao!.postData(APIConstant.API_GET_UPDATE_PRIVILEGE, listprivelageobj);
            }),
            map(data => {
                this.commonservice.StopProgressBar();
                return data;
            }),
            catchError(() => {
                this.commonservice.StopProgressBar();
                return observableOf([]);
            })
        ).subscribe(data => {
            if (data.Success == true) {
                MessageBox.ShowInfo(this.dialog, CommonMessage.DATA_SAVE_SUCCESS_MESSAGE)
                    .subscribe(result => {
                        this.GetAllPrivilege();
                    });

            }
        });
    }

    public CheckBox_Change(pobj: PrivilegeModel) {
        pobj.Changed = true;
    }
}

