<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">
  Debit Processing {{countryTitle}}
</h4>
<br />

<div class="form-align">
  <!-- Main Mat Spinner -->
  <div class="loading-shade-global" *ngIf="isLoading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <!-- Add Form Group -->
  <form [formGroup]="requestForm">
    <!--Payment Processing Period-->
    <div class="row inline" style="align-items: center">
      <label for="txtrDescription" class="text-primary form-header"><b>Generate New Payment Processing Date</b></label>
      <div style="display: inline-block">
        <div name="newPaymentProcessingDate" class="input-group">
          <input name="newPaymentProcessingDate" ngbDatepicker #paymentProcessingDate="ngbDatepicker"
            class="form-control" formControlName="newPaymentProcessingDate" placeholder="DD-MMM-YYYY"
            [minDate]="{ year: 1900, month: 1, day: 1 }" [(ngModel)]="todayDate" />
          <div class="input-group-append">
            <button class="btn bg-white" (click)="paymentProcessingDate.toggle()" type="button">
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
      </div>
      <button class="ml-3 btn btn-primary form-button" [disabled]="stateMachineDesc.stepMachineIsRunning"
        (click)="feebotCheck()">
        Generate
      </button>
      <app-describe-state-machine [MachineType]="StepFunctions.CreateDebitProcessingEntry" class="inline">
      </app-describe-state-machine>
    </div>
    <!-- Step Machine State Display -->
    <br />
    <br />
    <p>
      <small>Note: Generating a new payment processing date is likely to produce
        multiple {{helpText}} files. Please enable multiple pop-ups in your browser to
        avoid any conflict.
      </small>
    </p>
    <br />

    <div class="form-group row">
      <label for="txtrDescription" class="text-primary form-header"><b>Search for Payment Processing Period</b><span
          class="text-danger">*</span></label>

      <div style="display: inline-block">
        <label style="display: block" class="text-primary"><b>From</b></label>
        <div name="FromDate" class="input-group">
          <input name="dp" ngbDatepicker #FromDate="ngbDatepicker" class="form-control"
            formControlName="debitProcessingDateFrom" placeholder="DD-MMM-YYYY" [maxDate]="selectedToDate"
            (dateSelect)="onDateFromSelected()" />
          <div class="input-group-append">
            <button class="btn bg-white" (click)="FromDate.toggle()" type="button">
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
      </div>
      <div [ngStyle]="toDateStyle()" class="pl-3">
        <label style="display: block" class="col-md-3 col-lg-2 col-form-label" class="text-primary"><b>To</b></label>
        <div name="ToDate" class="input-group">
          <input name="dp" ngbDatepicker #ToDate="ngbDatepicker" class="form-control"
            formControlName="debitProcessingDateTo" placeholder="DD-MMM-YYYY" [minDate]="selectedFromDate"
            (dateSelect)="onDateToSelected()" />
          <div class="input-group-append">
            <button class="btn bg-white" (click)="ToDate.toggle()" type="button">
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <button class="btn btn-primary" [ngStyle]="searchButtonStyle()" (click)="searchDebitProcessing()">
        search
      </button>
    </div>

    <br />

    <div id="debitTable" class="form-group">
      <div class="border-default rounded-10 border-2" [ngStyle]="pppTableStyle()">
        <div style="overflow-x: auto; width: 100%;">
          <table mat-table [dataSource]="dataSource" matSort matSortActive="ProcessingDate" borderless="true"
            matSortDirection="desc" class="rounded-10">
            <ng-container matColumnDef="ProcessingDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Processing Date
              </th>
              <td mat-cell *matCellDef="let element" style="width: 12em">
                {{ element.ProcessingDate | dateFormatPipe }}
              </td>
            </ng-container>

            <ng-container matColumnDef="DollarAmount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                $ Amount
              </th>
              <td mat-cell *matCellDef="let element" style="width: 10em">
                {{ element.DollarAmount | currency }}
              </td>
            </ng-container>

            <ng-container matColumnDef="ABAFinalised">
              <th mat-header-cell *matHeaderCellDef>{{searchFinalizeText}}</th>
              <td mat-cell *matCellDef="let element; let j = index" style="width: 2em">
                <div class="custom-control custom-checkbox p-0 m-0">
                  <div class="divider"></div>
                  <input type="checkbox" id="mycheck" disabled="true" class="custom-control-input"
                    [(ngModel)]="element.ABAFinalised" [ngModelOptions]="{ standalone: true }" />
                  <label class="custom-control-label">&nbsp;</label>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Edit">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" style="width: 2em">
                <a class="px-2" [routerLink]="
                        RouterConstant.NAVIGATOR_EDIT_DEBIT_PROCESSING
                      " *ngIf="
                        element.PeriodClosed === null ||
                        element.PeriodClosed === false
                      ">
                  <button mat-button (click)="
                          autoPopulateProcessingDate(
                            element.ProcessingDate,
                            element.ABAFinalised
                          )
                        " class="btn btn-light btn-sm">
                    <i class=" fas fa-pen fa-1x"></i>
                  </button>
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="PeriodClosed">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element; let j = index" style="width: 4em">
                <div *ngIf="element.PeriodClosed">
                  <button id="OpenPeriod" class="btn btn-outline-primary form-button"
                    (click)="periodOpenToggle($event, element)">
                    Open Period
                  </button>
                </div>
                <div *ngIf="
                        element.ABAFinalised &&
                        (element.PeriodClosed === null ||
                          element.PeriodClosed === false)
                      ">
                  <button id="ClosePeriod" class="btn btn-primary form-button"
                    (click)="periodCloseToggle($event, element)">
                    Close Period
                  </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator #dppagi [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>
    </div>
  </form>
</div>

<app-fp-progress-bar #progressBar></app-fp-progress-bar>