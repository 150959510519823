import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[allowNumbersonly]'
})
export class OnlyNumbersDirective {

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedKeys = [
      'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter', 'Control', 'Meta', 'v', 'a', 'x'
    ];

    if (allowedKeys.includes(event.key) || 
        (event.ctrlKey && ['v', 'a', 'x'].includes(event.key))) {
      return; // Allow these keys
    }

    // Prevent non-numeric keys
    if (event.key < '0' || event.key > '9') {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || window['clipboardData'];
    const pastedText = clipboardData.getData('text');

    // Check if pasted content is not numeric
    if (!/^\d*$/.test(pastedText)) {
      event.preventDefault(); // Prevent pasting non-numeric values
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Replace any non-numeric characters
    input.value = input.value.replace(/[^\d]/g, '');

    // Enforce maxLength
    if (input.maxLength && input.value.length > input.maxLength) {
      input.value = input.value.substring(0, input.maxLength);
    }
  }
}
