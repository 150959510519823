<div class="container">
  <div style="margin-top:20px">
    <h1>
      About us
    </h1>
    <p>
      Fitness Passport is a corporate health and fitness program that allows its members to access a wide range of their local
      health and fitness suppliers. Our purpose is to inspire a greater number of people to start regular exercise by delivering
      a service that represents excellent value and provides plenty of variety.
    </p>
  </div>
  <section class="py-5">
    <div class="container py-5">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <div class="card mb-2">
            <i class="py-5 far fas fa-address-card d-block mx-auto fa-5x text-success"></i>
            <h3 class="h3 font-weight-light text-center text-primary">Current
              <br>Members</h3>
            <p class="lead p-4 text-center">
              Nulla metus nibh, gravida a massa sit amet, tincidunt mollis purus. Cras eget ex cursus, dapibus ante tristique, fringilla
              sem.
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <div class="card mb-2">
            <i class="py-5 far fas fa-question-circle d-block mx-auto fa-5x text-success"></i>
            <h3 class="h3 font-weight-light text-center text-primary">Employee<br/>Enquiries
            </h3>
            <p class="lead p-4 text-center">
              Nulla metus nibh, gravida a massa sit amet, tincidunt mollis purus. Cras eget ex cursus, dapibus ante tristique, fringilla
              sem.
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <div class="card mb-2">
            <i class="py-5 fas fas fa-swimmer d-block mx-auto fa-5x text-warning"></i>
            <h3 class="h3 font-weight-light text-center text-primary">GYM/POOL
              <br>Enquiries</h3>
            <p class="lead p-4 text-center">
              Nulla metus nibh, gravida a massa sit amet, tincidunt mollis purus. Cras eget ex cursus, dapibus ante tristique, fringilla
              sem.
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <div class="card mb-2">
            <i class="py-5 fas fa-user-friends d-block mx-auto fa-5x text-info"></i>
            <h3 class="h3 font-weight-light text-center text-primary">New
              <br>Member</h3>
            <p class="lead p-4 text-center">
              Nulla metus nibh, gravida a massa sit amet, tincidunt mollis purus. Cras eget ex cursus, dapibus ante tristique, fringilla
              sem.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>