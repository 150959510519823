import { RawVisitCSV } from "./raw-visit-csv.model";

export class RawVisit {
    ErrorDataMemberNotExistCount: number;
    ErrorDataMemberStatusInvalidCount: number;
    ErrorDataMemberIneligibleCount: number;
    ErrorDataFacilityServiceInactiveCount: number;
    ErrorDataFacilityServiceNonExistentCount: number;

    TotalRows: number;
    CSVFileData: RawVisitCSV;
    TotalErrorRows: number;
    IsFutureDate: boolean;
}