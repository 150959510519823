<div class="fp-portal">
    <div class="banner text-center position-relative">
        <div class="position-absolute btn-group-fp">
            <a class="btn btn-primary px-3 px-md-5 mr-2" href="/login">Log In</a>
            <a id="sign-up-emp-portal" class="btn btn-primary px-3 px-md-5 ml-2"
                *ngIf="EmpPageObj.EnableMemberSignUp==true" (click)="SignUp_Click()">Sign
                Up</a>
        </div>
    </div>
    <div class="container mx-auto pt-5">
        <div class="row">
            <div class="col-12 col-md-8 d-flex align-items-center">
                <h2 class="text-primary">{{EmpPageObj.PageHeading}}</h2>
            </div>
            <div class="col-12 col-md-4 d-flex align-items-center">
                <img [src]="EmpPageObj.AttachLogo.Location" class="img-fluid">
            </div>
        </div>
        <div class="cdk-intro pt-3" [innerHTML]="Pagetext1">

        </div>
        <div class="tabFPEmplrPrtlTheme mb-5">
            <mat-horizontal-stepper>
                <mat-step *ngIf="!(EmpPageObj.ShowPricingPackages==false && EmpPageObj.ShowAvailableFacilities==false)">
                    <ng-template matStepLabel>Packages & Facilities</ng-template>
                    <p class="px-4 pt-3">Select a membership package to view facilities available.</p>
                    <div class="facility-package px-4 pb-2">
                        <app-facility-packages-comonent [googlemap]="googlemap"></app-facility-packages-comonent>
                    </div>
                    <div class="px-4 pb-2">
                        <div class="row py-3" *ngIf="EmpPageObj.ShowPricingPackages">
                            <div *ngIf="mbsplst.length==0">
                                <h5>There is no package</h5>
                            </div>
                        </div>
                        <div class="fp-map" *ngIf="EmpPageObj.ShowAvailableFacilities">
                            <app-google-map-page-view #googlemap [isAustralia]="isAustralia"></app-google-map-page-view>
                        </div>
                    </div>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>Additional Information</ng-template>
                    <div class="px-4 py-4">
                        <h4 class="text-uppercase text-primary m-0">Eligibility</h4>
                        <div class="cdk-eligibility pt-4" [innerHTML]="Pagetext2">

                        </div>
                        <h4 class="text-uppercase text-primary m-0">Need More Details?
                        </h4>
                        <div class="cdk-additional pt-4" [innerHTML]="Pagetext3">

                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
    <a id="sign-up-emp-portal" class="btn btn-primary w-25 mx-auto d-block" (click)="SignUp_Click()"
        *ngIf="EmpPageObj.EnableMemberSignUp==true">Sign
        Up</a>
</div>