<div class="form-group row mb-5" *ngIf="availableFacilities.length > 1">
    <label for="ddlAvailableFacilities" class="col-md-3 col-lg-2 col-form-label">Available Facilities</label>
    <div class="col-md-8 col-lg-5">
        <div class="d-flex flex-column w-100">
            <select id="ddlAvailableFacilities" class="form-control" [formControl]="availableFacilitiesCtrl">
                <option [value]="null" selected>Select Available Facility</option>
                <option *ngFor="let f of availableFacilities" [ngValue]="f">{{ f.Name }}</option>
            </select>
        </div>
    </div>
</div>
<div [hidden]="isSelectedFacility">
    <h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left" *ngIf="availableFacilities.length === 1">{{
    selectedFacility.Name }}</h4>
    <div class="tabFPTheme mb-5" #tabfpstepper [style.height]="heighttabs">
        <mat-vertical-stepper #stepper (selectionChange)="onStepChange($event)">
            <mat-step>
                <form id="facilityDetailstb0">
                    <ng-template matStepLabel><i class="fas fa-check icon-fp d-lg-none"></i>
                        <span>Facility Details</span>
                    </ng-template>
                    <ngb-accordion [destroyOnHide]="false"
                        activeIds="panelFacilityDetail, panelImportantInfo, panelContact, panelAddNotes">
                        <ngb-panel id="panelFacilityDetail">
                            <ng-template ngbPanelTitle>
                                Facility Identification Details
                                <i class="fas fas fa-chevron-down"></i>
                                <i class="fas fas fa-chevron-up"></i>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <app-facility-identification-details [form]="form.get('IdentificationDetails')"
                                    [data]="data" #identificationDetails></app-facility-identification-details>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="panelImportantInfo">
                            <ng-template ngbPanelTitle>
                                Important Information
                                <i class="fas fas fa-chevron-down"></i>
                                <i class="fas fas fa-chevron-up"></i>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <app-facility-important-information [form]="form.get('ImportantInformation')"
                                    [data]="data" #importantInformation></app-facility-important-information>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="panelContact">
                            <ng-template ngbPanelTitle>
                                Contact Details
                                <i class="fas fas fa-chevron-down"></i>
                                <i class="fas fas fa-chevron-up"></i>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <app-facility-contact-details [form]="form.get('ContactDetails')" [data]="data"
                                    #contactDetails>
                                </app-facility-contact-details>

                            </ng-template>
                        </ngb-panel>
                        <ngb-panel id="panelAddNotes">
                            <ng-template ngbPanelTitle>
                                Additional Notes
                                <i class="icon-fp fas fa-check"></i>
                                <i class="fas fas fa-chevron-down"></i>
                                <i class="fas fas fa-chevron-up"></i>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <app-facility-notes></app-facility-notes>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                    <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="stepper.next()">
                        <i class="fas fa-arrow-circle-right mr-2"></i>Next</button>
                </form>
            </mat-step>
            <mat-step>
                <form id="facilityDetailstb1">
                    <ng-template matStepLabel><i class="fas fa-check icon-fp d-lg-none"></i>
                        <span>Facility Photos</span>
                    </ng-template>
                    <ngb-accordion activeIds="panelImgAttach">
                        <ngb-panel id="panelImgAttach">
                            <ng-template ngbPanelTitle>
                                Image Attachments
                                <i class="fas fas fa-chevron-down"></i>
                                <i class="fas fas fa-chevron-up"></i>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="row image-gallery">
                                    <div class="col-md-4 thumb" *ngFor="let image of _albums; let i=index">
                                        <div
                                            class="fp-profile img-thumbnail d-flex align-items-center position-relative">
                                            <img [src]="image.thumb" (click)="open(i)"
                                                class="img-fluid cursordisplay" />
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                    <div
                        class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                        <button (click)="stepper.previous()" class="px-2 px-lg-5 py-2 btn btn-primary" type="button"><i
                                class="fas fa-arrow-circle-left mr-2"></i>Back</button>
                    </div>
                </form>
            </mat-step>
        </mat-vertical-stepper>
    </div>
</div>