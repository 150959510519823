<div [ngClass]="ngInputFile">
    <input type="file" class="custom-file-input" [disabled]="isReadonly" accept=".csv" (change)="fileEvent($event)" #fpfileupload>
    <label class="custom-file-label bg-primary text-light" [ngClass]="ngClassLabel"><i class="fas fa-upload mr-2"></i>{{lblSelectFile}}</label>
</div>
<div *ngIf="dataSourceFile?.data?.length > 0">
    <!-- Data table material here -->
    <div class="employer_note_container border-default rounded-10 border-2 w-100">
        <div class="tablecontainer">
            <table mat-table [dataSource]="dataSourceFile">
                <ng-container matColumnDef="Filename">
                    <th mat-header-cell *matHeaderCellDef>File Name</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.Filename}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="UploadStatus">
                    <th mat-header-cell *matHeaderCellDef>Upload Status
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <span [innerHTML]="element.FormatUploadStatus"></span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Error">
                    <th mat-header-cell *matHeaderCellDef>Error</th>
                    <td mat-cell *matCellDef="let element">
                        <span [innerHTML]="element.Error"></span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="DisplayUploadColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: DisplayUploadColumns;"></tr>
            </table>
        </div>
    </div>

    <button class="btn btn-primary custom-file w-230px thumb" [disabled]="IsHavingPendingFile()==false" type="button" (click)="btnUploadFile($event)"  [isUploadCompletedDefault]="false"
    [showProgressBar]="true"><i class="fas fa-file-import"></i>
        {{lblUploadFile}}
    </button>

    <app-describe-state-machine [MachineType]="StepFunctions.MembershipPackageUpdateByCSVFile"
    (SuccessReturned)="handleBulkUploadResponse($event)"></app-describe-state-machine>

    <button class="btn btn-primary custom-file w-230px thumb ml-3" style="display:none" [disabled]="this.dataSourceFile.data.length == 0" type="button" (click)="btnClearList($event)"><i
            class="fas fa-sync-alt"></i>
        {{lblClearList}}
    </button>
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <div *ngIf="IsS3Status">
            <h6 class="modal-title" id="modal-basic-title">{{status}}: {{percent}}%</h6>
        </div>
        <div *ngIf="!IsS3Status">
            <h6 class="modal-title" id="modal-basic-title">{{status}}</h6>
        </div>
    </div>
    <div class="modal-body" style="margin-bottom: 10px">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        <!-- <ngb-progressbar *ngIf="!IsUploadCompleted && IsS3Status" type="success" [value]="percent"></ngb-progressbar> -->
        <!-- <div *ngIf="IsUploadCompleted">
            <ngb-alert [dismissible]="false">
                Import Data Successfully
            </ngb-alert>
        </div> -->
    </div>
</ng-template>
<app-fp-progress-bar #progressBar></app-fp-progress-bar>