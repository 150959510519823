import { Component, Injector, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MemberFormBase } from "@fp/components/base";
import { MembershipUploadPhotoComponent } from "@fp/components/members/membership-cards/membership-upload-photo/membership-upload-photo.component";
import { CommonMessage, CommonString, PatternConstant } from "@fp/constant";
import { CommonConstants } from "@fp/constant/common-constants";
import { DataResult, Facility, Gender, Member, MemberType, SearchPaginationRequest, UploadFile } from "@fp/models";
import { CommonService, FacilityService, MessageBox, UserService } from "@fp/services";
import * as enums from "src/app/enums";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FPValidators, StringHelper, Utils } from "@fp/helpers";
import { FpFileUploadComponent } from "@fp/components/control";
import { catchError, debounceTime, switchMap } from "rxjs/operators";
import { MedicalQuestionsComponentComponent } from "@fp/medical-questions-component/medical-questions-component.component";
import { EMemberStatus } from "src/app/enums";
import { empty } from "rxjs";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { MobileNumberRegistorComponent } from "@fp/components/control/mobile-number-registor/mobile-number-registor.component";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-add-family-member-popup",
  templateUrl: "./add-family-member-popup.component.html",
  styleUrls: ["./add-family-member-popup.component.css"],
  providers: [
    { provide: MemberFormBase, useExisting: AddFamilyMemberPopupComponent },
  ],
})
export class AddFamilyMemberPopupComponent extends MemberFormBase {
  addFamilyMemberFormGroup: UntypedFormGroup;
  isAusMember = true;
  builder = new UntypedFormBuilder();
  col_4 = true;
  btnNext = false;
  days: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  years: any = [];
  selectedDate: Date;
  genders: Gender[];
  memberTypes: MemberType[];
  imgUrl: any;
  CommonMessage = CommonMessage;
  CommonString = CommonString;
  StringHelper = StringHelper;
  @ViewChild("medicalQuestions")
  medicalQuestions: MedicalQuestionsComponentComponent;
  favFacilitySelected: Facility;
  parentFocus = 0;
  favFacilitySearch: Facility[] = [];
  favFacilityIsLoading: boolean;
  facilityList: Facility[];
  showPreferredFacilities: boolean = true;
  isOtherDacilityDisable: boolean;
  existFacilityIsLoading: boolean;
  existFacilitySearch: Facility[] = [];
  existFacilitySelected: Facility;
  photoUploader:FpFileUploadComponent;  
  isEmailDuplicated: boolean = false;
  isMPDuplicated: boolean = false;
  dependentSelectedDate: Date; 
  isApiCallSuccessful = false;
  isMemberHasExistingFacility = false;
  @Input() isFieldsDisabled: boolean = false;

  get MemberTypeCtrl() {
    return this.addFamilyMemberFormGroup.get("MemberType");
  }

  get FavouredFacilityCtrl() {
    return this.addFamilyMemberFormGroup.get("FavouredFacility");
  }

  get ExistingFacilityCtrl() {
    return this.addFamilyMemberFormGroup.get("ExistingFacility");
  }

  @ViewChild("mobileNumberRegistorComponent") mobileNumberRegistor: MobileNumberRegistorComponent<any>;
  @ViewChild("mobileNumberRegistorComponentContact") mobileNumberRegistorContact: MobileNumberRegistorComponent<any>;

  constructor(
    injector: Injector,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private facilitySvc: FacilityService,
    private commonService: CommonService, // public dialogRef: MatDialogRef<AddFamilyMemberPopupComponent>
    protected dialog: MatDialog,
    private http: HttpClient,
    private userSvc: UserService
  ) {
    super(injector);

    this.photoUploader = new FpFileUploadComponent(modalService,dialog, http, commonService);
    this.photoUploader.ngOnInit();

    this.photoUploader.UploadCompleted.subscribe(
      (data: any) => {

        if(data && data.filedata && data.filedata.url){
          this.imgUrl = data.filedata.url;

          const s3path = data.filedata.Location;
          const key = data.filedata.Location.substring(
            data.filedata.Location.indexOf("memberPhotos/"),
            data.filedata.Location.length
          );

          this.addFamilyMemberFormGroup.get("photo").setValue({
            Id: 0,
            FileName: "Photo",
            Data: key,
            Location: s3path,
          });

          this.commonService.getDownloadUrl(key).subscribe(
            (res) => {
              this.imgUrl = res;
            },
            (err) => {
              console.error("Error in get url", err);
            }
          );
        }
      });
  }

  ngOnInit(): void {
    this.isAusMember =
      this.commonService.getUserCountry()?.toUpperCase() !==
      CommonConstants.COUNTRY_NEW_ZEALAND;
    this.ValidateFamilyMemberForm();
    this.genders = CommonConstants.GET_GENDERS;
    this.getyearlistforBD();
    this.handleGetMemberTypesResult(CommonConstants.GET_MEMBER_TYPES);

    //Resolve dropdown default value issue
    this.addFamilyMemberFormGroup.controls.txtGender.setValue(null);
    this.addFamilyMemberFormGroup.controls.txtMbType.setValue(null);

    var today = new Date();
        today.getHours;
    var eighteenYearsAgo = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate(),
      today.getHours(),
      today.getMinutes(),
      today.getMinutes(),
      today.getSeconds()
    );
    
    this.dependentSelectedDate = eighteenYearsAgo;
  }

  OnLoad() {
    if (this.data && this.data.model) {

      if(this.data.model.PrimaryMember && this.data.model.PrimaryMember.Memberships.length > 0 ){
        var membershipPackageId = this.data.model.PrimaryMember.Memberships[0].MembershipPackageId;
        var parameterVal = "signUp-" + membershipPackageId + "-" + "";
        this.getFacilities(parameterVal);

        if(this.data.model && this.data.model.FirstName && this.data.model.LastName && this.data.model.PrimaryEmail && this.data.model.MobileNumber){
          this.loadMemberDetail(this.data.model);
        }
      }
      
    }
    super.OnLoad();
}

  ngAfterViewInit() {
    this.addFamilyMemberFormGroup.addControl(
      "medicalQuestions",
      this.medicalQuestions.medicalQuestionFormGroup
    );

    this.OnLoad();
    this.initValidation();
    this.addFamilyMemberFormGroup.get("IsExistingMember").setValue(false);
    this.isOtherDacilityDisable = true;

    if(!this.addFamilyMemberFormGroup.get("txtMobileNum").value){
      this.mobileNumberRegistor.SetDefaultCountryCode(this.isAusMember);
    }

    if(!this.addFamilyMemberFormGroup.get("txtApMobileNum").value){
      this.mobileNumberRegistorContact.SetDefaultCountryCode(this.isAusMember);
    }
  }

  ValidateFamilyMemberForm() {
    this.addFamilyMemberFormGroup = this.builder.group({
      txtFirstName: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.VALID_NAME)],
      ],
      txtLasttName: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.VALID_NAME)],
      ],
      txtApMobileNum: ["", Validators.required],
      txtMobileNum: ["", Validators.required],
      txtGender: ["", Validators.required],
      psdDay: ["", Validators.required],
      psdMonth: ["", Validators.required],
      psdYear: ["", Validators.required],
      txtMbType: ["", Validators.required],
      txtApName: ["", Validators.required],
      txtApRelation: ["", Validators.required],
      photo: [""],
      txtPEmail: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.EMAIL)],
      ],
      FavouredFacility: [null],
      FavouredFacilityId: [null],
      IsExistingMember: [false, Validators.required],
      ExistingMemberFacilityId: [0],
      ExistingFacilityOther: [
        { value: null, disabled: true },
        CustomFacilityValidators.ExistingFacilityOtherRequiredWhen,
      ],
      ExistingFacility: [
        { value: null, disabled: true },
        [
          FPValidators.resolvableAutocompleteOption,
          CustomFacilityValidators.ExistingFacilityRequiredWhen,
        ],
      ],
    });
  }

  initValidation() {
    //init
    this.FavouredFacilityCtrl.setValidators([
      FPValidators.resolvableAutocompleteOption,
      CustomFacilityValidators.FavouredFacilityRequiredWhen,
    ]);
    this.FavouredFacilityCtrl.updateValueAndValidity({ emitEvent: false });

    this.ExistingFacilityCtrl.setValidators([
      FPValidators.resolvableAutocompleteOption,
      CustomFacilityValidators.ExistingFacilityRequiredWhen,
    ]);
    this.ExistingFacilityCtrl.updateValueAndValidity({ emitEvent: false });
    this.addFamilyMemberFormGroup.get("ExistingFacilityOther").setValidators(
      CustomFacilityValidators.ExistingFacilityOtherRequiredWhen
    );
    this.addFamilyMemberFormGroup.get(
      "ExistingFacilityOther"
    ).updateValueAndValidity({ emitEvent: false });

    this.addFamilyMemberFormGroup.get(
      "ExistingFacilityOther"
    ).updateValueAndValidity({ emitEvent: false });

    if (this.addFamilyMemberFormGroup.get("IsExistingMember").value == true) {
      this.addFamilyMemberFormGroup.get("ExistingFacility").enable({
        emitEvent: false,
      });
      this.addFamilyMemberFormGroup.get("ExistingFacilityOther").enable({
        emitEvent: false,
      });
    }

    this.ExistingFacilityCtrl.setValidators([
      FPValidators.resolvableAutocompleteOption,
      CustomFacilityValidators.ExistingFacilityRequiredWhen,
    ]);
    this.ExistingFacilityCtrl.updateValueAndValidity({ emitEvent: false });
    this.addFamilyMemberFormGroup.get("ExistingFacilityOther").setValidators(
      CustomFacilityValidators.ExistingFacilityOtherRequiredWhen
    );
    this.addFamilyMemberFormGroup.get(
      "ExistingFacilityOther"
    ).updateValueAndValidity({ emitEvent: false });
  }

  loadMemberDetail(memberData: Member) {

    if (this.addFamilyMemberFormGroup && this.addFamilyMemberFormGroup.controls) {
      this.addFamilyMemberFormGroup.controls.txtFirstName.setValue(memberData?.FirstName);
      this.addFamilyMemberFormGroup.controls.txtLasttName.setValue(memberData?.LastName);
      this.addFamilyMemberFormGroup.controls.txtGender.setValue(memberData?.GenderId);
      this.addFamilyMemberFormGroup.controls.txtMbType.setValue(memberData?.MemberType);
      this.addFamilyMemberFormGroup.controls.txtPEmail.setValue(memberData?.PrimaryEmail);
      
      if (memberData?.DateOfBirth) {

        var dob = new Date(memberData?.DateOfBirth);
        this.addFamilyMemberFormGroup.controls.psdYear.setValue(
          dob.getFullYear()
        );
        this.addFamilyMemberFormGroup.controls.psdMonth.setValue(dob.getMonth());
        this.addFamilyMemberFormGroup.controls.psdDay.setValue(dob.getDate());
        this.selectedDate = dob;
      }

      if (memberData?.MobileNumber && this.addFamilyMemberFormGroup.get("txtMobileNum").value == '') {
        this.mobileNumberRegistor.primarySelectDate = this.selectedDate;

        //FP-849.FP-848
        if (memberData.MobileNumber.match(/^\++/)){
          memberData.MobileNumber = memberData.MobileNumber.replace(/^\++/, '+');
      }
        this.mobileNumberRegistor.SetValueFromMobileNo(memberData.MobileNumber, true);
      }
      else {
        this.mobileNumberRegistor.SetDefaultCountryCode(this.isAusMember);
      }

      if (memberData?.Photo?.Location) {
        this.imgUrl = memberData?.Photo?.Location;
      }

      this.addFamilyMemberFormGroup.controls.txtApName.setValue(memberData?.EmergencyContactName);
      this.addFamilyMemberFormGroup.controls.txtApRelation.setValue(memberData?.EmergencyRelationship);

      if (memberData?.EmergencyContactNo && this.addFamilyMemberFormGroup.get("txtApMobileNum").value == '') {

      //FP-849,FP-848
         if (!memberData?.EmergencyContactNo.startsWith('+61') && !memberData?.EmergencyContactNo.startsWith('+64') && memberData?.EmergencyContactNo.length == 9) {
          memberData.EmergencyContactNo = '+61' + memberData.EmergencyContactNo;
      }
      
        if (!memberData?.EmergencyContactNo.startsWith('+61') && !memberData?.EmergencyContactNo.startsWith('+64') && memberData?.EmergencyContactNo.startsWith('0') && memberData?.EmergencyContactNo.length == 10) {
          memberData.EmergencyContactNo = '+61' + memberData.EmergencyContactNo.substring(1);
      }
        if (memberData.EmergencyContactNo.match(/^\++/)){
          memberData.EmergencyContactNo = memberData.EmergencyContactNo.replace(/^\++/, '+');
      }

        this.mobileNumberRegistorContact.SetValueFromMobileNo(memberData?.EmergencyContactNo, false);
      }

      if (memberData?.MedicalAnswers) {
        this.medicalQuestions.SetMedicalQuestionAnswers(memberData?.MedicalAnswers);
      }

      if (memberData.FavouredFacilityId || memberData.FavouredFacility) {
        this.addFamilyMemberFormGroup.get("FavouredFacility").setValue(
          this.data.model.FavouredFacility
        );
      }

      if (memberData.ExistingFacility || memberData.ExistingFacility) {
        this.addFamilyMemberFormGroup.get("ExistingFacility").setValue(
          this.data.model.ExistingFacility
        );
      }

      if (memberData.ExistingFacilityOther) {
        this.addFamilyMemberFormGroup.get("ExistingFacilityOther").setValue(
          this.data.model.ExistingFacilityOther
        );
      }

      if (memberData.IsExistingMember) {
        this.isMemberHasExistingFacility = this.data.model.IsExistingMember;
        this.addFamilyMemberFormGroup.get("IsExistingMember").setValue(
          this.data.model.IsExistingMember
        );
        if (this.isMemberHasExistingFacility) {
          this.addFamilyMemberFormGroup.get("ExistingFacility").enable({
            emitEvent: false,
          });
          this.addFamilyMemberFormGroup.get("ExistingFacilityOther").enable({
            emitEvent: false,
          });
          this.isOtherDacilityDisable = false;
        } else {
          this.addFamilyMemberFormGroup.get("ExistingFacility").disable({
            emitEvent: false,
          });
          this.addFamilyMemberFormGroup.get("ExistingFacilityOther").disable({
            emitEvent: false,
          });
          this.isOtherDacilityDisable = true;
        }
      }

      this.ValidatePrimaryEmail(memberData?.PrimaryEmail, true);
    }
  }


  getFacilities(parameterVal: string) {
    this.favFacilityIsLoading = true;
    const searchRequest: SearchPaginationRequest = {
      OrderBy: "Name",
      OrderByAsc: true,
      PageNumber: 0,
      PageSize: 0,
      Params: [
        {
          Name: "preferred_facility",
          Value: parameterVal,
        },
      ],
      ViewColumns: ["name"],
    };
    this.facilitySvc.search(searchRequest).subscribe(
      (result: string) => {
        const dectypted_data = this.commonSvc.D_FP_ResponseData(result);
        const rdata = JSON.parse(dectypted_data);
        this.favFacilityIsLoading = false;
        if (rdata.Success) {
          if (rdata.Data != null) {
            this.facilityList = rdata.Data.Results;
          }
        } else {
          MessageBox.ShowError(
            this.dialog,
            "Sorry, something went wrong. Let's try that again."
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              window.location.reload();
            }
          });
          this._logger.error(rdata);
        }
      },
      (error) => {
        this.favFacilityIsLoading = false;
        MessageBox.ShowError(
          this.dialog,
          "Sorry, something went wrong. Let's try that again."
        ).subscribe((res) => {
          if (res.result.toLowerCase() === DialogResult.Ok) {
            window.location.reload();
          }
        });
        this._logger.error(error);
      }
    );
  }

  LoadComplete() {
    super.LoadComplete();

    setTimeout(() => {
      this.FavouredFacilityCtrl.valueChanges
        .pipe(
          debounceTime(500),
          switchMap((value) => {
            // this.addFamilyMemberFormGroup.get(
            //   "FavouredFacilityOther"
            // ).updateValueAndValidity({ emitEvent: false });
            if (typeof value === "string") {
              const searchText = value;
              if (searchText.length > 2) {
                this.favFacilityIsLoading = true;
                return value;
              } else {
                this.favFacilityIsLoading = false;
              }
            }
            return empty();
          }),
          catchError((e) => {
            throw e;
          })
        )
        .subscribe((result: string) => {
          this.favFacilityIsLoading = false;
          let filterList = this.facilityList.filter((x) =>
            x.Name.toLowerCase()
              .trim()
              .includes(this.FavouredFacilityCtrl.value.toLowerCase().trim())
          );
          this.favFacilitySearch = filterList;
          this.changeDetectorRef.detectChanges();
        });

      this.ExistingFacilityCtrl.valueChanges
        .pipe(
          debounceTime(500),
          switchMap((value) => {
            this.addFamilyMemberFormGroup.get(
              "ExistingFacilityOther"
            ).updateValueAndValidity({ emitEvent: false });
            if (typeof value === "string") {
              const searchText = value;
              if (searchText.length > 2) {
                this.existFacilityIsLoading = true;
                return value;
              } else {
                this.existFacilityIsLoading = false;
              }
            }
            return empty();
          }),
          catchError((e) => {
            throw e;
          })
        )
        .subscribe((result: string) => {
          this.existFacilityIsLoading = false;
          let filterList = this.facilityList.filter((x) =>
            x.Name.toLowerCase()
              .trim()
              .includes(this.ExistingFacilityCtrl.value.toLowerCase().trim())
          );
          this.existFacilitySearch = filterList;
          this.changeDetectorRef.detectChanges();
        });

      this.addFamilyMemberFormGroup.get(
        "ExistingFacilityOther"
      ).valueChanges.subscribe((value) => {
        this.ExistingFacilityCtrl.updateValueAndValidity({ emitEvent: false });
      });

      this.addFamilyMemberFormGroup.get(
        "IsExistingMember"
      ).valueChanges.subscribe((value: boolean) => {
        if (value) {
          this.addFamilyMemberFormGroup.get("ExistingFacility").enable({
            emitEvent: false,
          });
          this.addFamilyMemberFormGroup.get("ExistingFacilityOther").enable({
            emitEvent: false,
          });
          this.isOtherDacilityDisable = false;
        } else {
          this.addFamilyMemberFormGroup.get("ExistingFacility").disable({
            emitEvent: false,
          });
          this.addFamilyMemberFormGroup.get("ExistingFacility").setValue(null, {
            emitEvent: false,
          });
          this.addFamilyMemberFormGroup.get("ExistingFacilityOther").disable({
            emitEvent: false,
          });
          this.addFamilyMemberFormGroup.get("ExistingFacilityOther").setValue(
            null,
            { emitEvent: false }
          );
          this.isOtherDacilityDisable = true;
        }
      });
    });
  }

  txtFavouredFacility_Blur(e) {
    if (this.FavouredFacilityCtrl.dirty) {
      this.FavouredFacilityCtrl.setValidators([
        FPValidators.resolvableAutocompleteOption,
        CustomFacilityValidators.FavouredFacilityRequiredWhen,
      ]);
      this.FavouredFacilityCtrl.updateValueAndValidity({ emitEvent: false });
    }
  }

  txtExistingFacility_Blur(e) {
    if (this.ExistingFacilityCtrl.dirty) {
      this.ExistingFacilityCtrl.setValidators([
        FPValidators.resolvableAutocompleteOption,
        CustomFacilityValidators.ExistingFacilityRequiredWhen,
      ]);
      this.ExistingFacilityCtrl.updateValueAndValidity({ emitEvent: false });
    }
    this.parentFocus = 0;
  }

  txtExistingFacility_Input(e) {
    if (this.ExistingFacilityCtrl.dirty) {
      if (
        typeof this.ExistingFacilityCtrl.value === "string" &&
        this.ExistingFacilityCtrl.value.length == 0 &&
        this.existFacilitySelected != null
      ) {
        this.SelectedExistingFacility(null);
      }
      this.ExistingFacilityCtrl.setValidators(
        CustomFacilityValidators.ExistingFacilityRequiredWhen
      );
    }
  }

  addImage(event) {
    const imageFile = event.target.files[0];
    var fileType = imageFile.name.split(".").pop();
    if (
      imageFile.type != "image/png" &&
      imageFile.type != "image/jpg" &&
      imageFile.type != "image/jpeg"
    ) {
      MessageBox.ShowError(
        this.dialog,
        StringHelper.format(
          CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE,
          "JPEG or PNG"
        )
      );
      return;
    } else if (imageFile.size > CommonString.MAX_UPLOAD_FILE_SIZE_10MB) {
      MessageBox.ShowError(
        this.dialog,
        StringHelper.format(CommonString.MAX_UPLOAD_FILE_SIZE_MESSAGE, "10MB")
      );
      return;
    }
    let reader = new FileReader();
    reader.onloadend = () => {
      const image: UploadFile = {
        FileName: imageFile.name,
        Location: null,
        Data: (<string>reader.result).replace(/^data:(.*;base64,)?/, ""),
      };

    const blobBin = atob(image.Data);
    let array = [];
    for (let i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    const file = new Blob([new Uint8Array(array)], { type: fileType.toLowerCase()});
    const event = {
      target: {
        files: [file],
      },
    };

    //upload member photo
    this.photoUploader.fileEventManual(event, fileType.toLowerCase());
    };
    reader.readAsDataURL(imageFile);
  }

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }

  displayAutoText(facility: Facility) {
    if (!facility) {
      return "";
    }
    return facility.Name || facility.Code;
  }

  txtFavouredFacility_Input(e) {
    if (this.FavouredFacilityCtrl.dirty) {
      if (
        typeof this.FavouredFacilityCtrl.value === "string" &&
        this.FavouredFacilityCtrl.value.length == 0 &&
        this.favFacilitySelected != null
      ) {
        this.SelectedFavFacility(null);
      }
      this.FavouredFacilityCtrl.setValidators(
        CustomFacilityValidators.FavouredFacilityRequiredWhen
      );
    }
  }

  SelectedFavFacility(facility: Facility) {
    this.favFacilitySelected = facility;
    this.addFamilyMemberFormGroup.get("FavouredFacilityId").setValue(
      facility ? facility.FacilityId : null
    );
    this.addFamilyMemberFormGroup.get("FavouredFacility").setValue(facility, {
      emitEvent: false,
    });
    // Clear current search results
    this.favFacilitySearch = [];
  }

  familyMemberSubmit() {
    if (this.addFamilyMemberFormGroup.valid) {

      this.data.model.FirstName = this.addFamilyMemberFormGroup.get("txtFirstName").value;
      this.data.model.LastName = this.addFamilyMemberFormGroup.get("txtLasttName").value;
      this.data.model.GenderId = this.addFamilyMemberFormGroup.get("txtGender").value;
      this.data.model.DateOfBirth = this.selectedDate;
      this.data.model.MemberType = this.addFamilyMemberFormGroup.get("txtMbType").value;
      this.data.model.MemberTypeId = this.data.model.MemberType.MemberTypeId;
      this.data.model.PrimaryEmail = this.addFamilyMemberFormGroup.get("txtPEmail").value;
      this.data.model.MobileNumber = "+" + Utils.convertPhoneToInternationalFormatWithCountryCode(this.addFamilyMemberFormGroup.get("txtMobileNum").value);
      this.data.model.Photo = this.addFamilyMemberFormGroup.get("photo").value;
      this.data.model.EmergencyContactNo = "+" + Utils.convertPhoneToInternationalFormatWithCountryCode(this.addFamilyMemberFormGroup.get("txtApMobileNum").value)
      this.data.model.EmergencyContactName = this.addFamilyMemberFormGroup.get("txtApName").value;
      this.data.model.EmergencyRelationship = this.addFamilyMemberFormGroup.get("txtApRelation").value;

      var medicalAnswers =this.addFamilyMemberFormGroup.get("medicalQuestions").value;
      this.data.model.MedicalAnswers = medicalAnswers?.medicalQuestionAndAnswers;

      this.data.model.FavouredFacility = this.addFamilyMemberFormGroup.get("FavouredFacility").value;
      this.data.model.IsExistingMember = this.addFamilyMemberFormGroup.get("IsExistingMember").value;
      this.data.model.ExistingFacility = this.addFamilyMemberFormGroup.get("ExistingFacility").value;
      this.data.model.ExistingFacilityOther = this.addFamilyMemberFormGroup.get("ExistingFacilityOther").value;        

      this.activeModal.close({ returnValue: this.data.model });
    }
  }

  SelectedExistingFacility(facility: Facility) {
    this.existFacilitySelected = facility;
    this.addFamilyMemberFormGroup.get("ExistingMemberFacilityId").setValue(
      facility ? facility.FacilityId : null
    );
    this.addFamilyMemberFormGroup.get("ExistingFacility").setValue(facility, {
      emitEvent: false,
    });
    // Clear current search results
    this.existFacilitySearch = [];
  }

  setIsExistingMember(isExistingMemberFacility: boolean) {
    this.addFamilyMemberFormGroup.get("IsExistingMember").setValue(
      isExistingMemberFacility
    );
  }

  uploadPhoto(e: Event) {
    // if (this.data.model.MemberStatusId === enums.EMemberStatus.New) {
    this.openUploadPhotoModal();
    // }
    this.btnUploadPhoto_Click(e);
  }

  btnUploadPhoto_Click(e: Event) {
    // Intentionally leave blank for overriding.
  }

  closeModal() {
    this.modalService.dismissAll();
    // console.log("Close modal");
  }
  openUploadPhotoModal() {
    var modalRef = this.modalService.open(MembershipUploadPhotoComponent, {
      backdrop: "static",
    });

    (<MembershipUploadPhotoComponent>(
      modalRef.componentInstance
    )).memberPersonalSelected = {
      id: 0,
      photo: null,
    };

    modalRef.result.then(
      (result: { location: string }) => {
        const s3path = result.location;
        const key = result.location.substring(
          result.location.indexOf("memberPhotos/"),
          result.location.length
        );

        this.addFamilyMemberFormGroup.get("photo").setValue({
          Id: 0,
          FileName: "Photo",
          Data: key,
          Location: s3path,
        });

        this.commonService.getDownloadUrl(key).subscribe(
          (res) => {
            this.imgUrl = res;
          },
          (err) => {
            console.error("Error in get url", err);
          }
        );
      },
      (reason) => {}
    );
  }

  getyearlistforBD() {
    let currentYear = new Date().getFullYear();
    let startYear = currentYear - 100;
    let caryyear = currentYear;
    for (let i = currentYear; startYear < i; i--) {
      this.years.push(caryyear);
      caryyear = caryyear - 1;
    }
  } 

  ddlMemberTypeOption_CompareFn(opt1: MemberType, opt2: MemberType) {
    return (
      (!opt1 && !opt2) ||
      (opt1 && opt2 && opt1.MemberTypeId === opt2.MemberTypeId)
    );
  }

  private handleGetMemberTypesResult(result: MemberType[]) {
    console.log(result);
    // if (result.Success) {
    this.memberTypes = result
      .filter((type) => {
        if (
          type.MemberTypeId !== enums.EMemberType.Partner &&
          type.MemberTypeId !== enums.EMemberType.Dependant
        ) {
          return false;
        } else if (
          type.MemberTypeId === enums.EMemberType.Partner &&
          (!this.data.model.MemberType ||
            this.data.model.MemberType.MemberTypeId !==
              enums.EMemberType.Partner)
        ) {
          const famMembers = this.data.model.PrimaryMember
            ? this.data.model.PrimaryMember.FamilyMembers
            : null;
          if (
            famMembers &&
            famMembers instanceof Array &&
            famMembers.findIndex(
              (m) =>
                m.MemberType.MemberTypeId === enums.EMemberType.Partner &&
                m.MemberStatusId !== enums.EMemberStatus.Invalid &&
                m.MemberStatusId !== enums.EMemberStatus.Inactive &&
                m.MemberStatusId !== enums.EMemberStatus.Banned
            ) > -1
          ) {
            return false;
          }
        }
        return true;
      })
      .sort((a, b) => {
        if (a.DisplayOrder != null && b.DisplayOrder != null) {
          return a.DisplayOrder - b.DisplayOrder;
        } else {
          return a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase()
            ? -1
            : 1;
        }
      });
  } 

  txtPrimaryEmail_Blur(e) {
    const value = <string>e.target.value;
    this.ValidatePrimaryEmail(value);
  }

  public ValidatePrimaryEmail(value: string, force = false) {    
    const control = this.addFamilyMemberFormGroup.get('txtPEmail');      
    value = (value || '').trim().toLowerCase();        
    let checkMemberType = this.addFamilyMemberFormGroup.get("txtMbType").value;

    if (control.invalid || !value || (!force && (value.length < 3))) {              
      return;
    }

    control.updateValueAndValidity({ emitEvent: false });
    let allMemberEmails = [];     
    
    if (this.data.model.PrimaryMember) {
      const famMemberEmails = (this.data.model.PrimaryMember.FamilyMembers || []).map(_member => _member.PrimaryEmail.toLowerCase());      
      allMemberEmails = [
          this.data.model.PrimaryMember.PrimaryEmail.toLowerCase(),
          ...famMemberEmails
      ];      
    }

    if (checkMemberType && checkMemberType.MemberTypeId == enums.EMemberType.Dependant && this.selectedDate > this.dependentSelectedDate){         
      this.isApiCallSuccessful = true;
      this.showPreferredFacilities = false;
      let dataModelEmail = this.data.model && this.data.model.PrimaryMember && this.data.model.PrimaryMember.PrimaryEmail;
      if(dataModelEmail.toLowerCase() == value){
        this.isEmailDuplicated = false;
        this.isApiCallSuccessful = true;
        console.log("check 1");
      }else{
        if((this.data.model.PrimaryEmail === value)){
            this.isEmailDuplicated = false;
            this.isApiCallSuccessful = true;
        }
        else if (allMemberEmails.indexOf(value) > -1) {        
          this.isEmailDuplicated = true;                    
          control.setErrors({ 'duplicated': { value: value } });
          control.markAsDirty();  
          this.isApiCallSuccessful = false; 
          console.log("check 2");     
        }else{        
          //control.disable({ emitEvent: false });
          control.markAsPending();              
          this.userSvc.checkExistEmailv2(value).subscribe(
            (res: DataResult<string>) => {
                const enctypted_data = res.Data;              
                const dectypted_data = this.commonSvc.D_FP_ResponseData(enctypted_data);              
                const ret = JSON.parse(dectypted_data);             
                control.enable({ emitEvent: false });
                control.markAsDirty();
                if (res.Success) {                                   
                    if (ret.IsExist) {
                        control.setErrors({ 'duplicated': { value: value } });
                        this.isEmailDuplicated = true;     
                        this.isApiCallSuccessful =false; 
                        console.log("check 3");                                     
                    } else {
                        this.isEmailDuplicated = false;  
                        this.isApiCallSuccessful =true;                                           
                    }
                   
                } else {
                    this.HandleResponseError(res);  
                    this.isApiCallSuccessful =false;
                    console.log("check 4");                 
                }
            },
            err => {
                control.enable({ emitEvent: false });
                this.handleError(err);  
                this.isApiCallSuccessful =false;
                console.log("check 5");
                MessageBox.ShowError(
                  this.dialog,
                  "Sorry, something went wrong. Please try entering your email again."
                ); 
                this.addFamilyMemberFormGroup.get('txtPEmail').setValue('');         
            });
        } 
      }
    }else{
      if ((this.data.model.MemberId > 0 && this.data.model.PrimaryEmail === value && this.data.model.PrimaryMember 
          && this.data.model.PrimaryMember.PrimaryEmail.toLowerCase() != value) ||
          (this.data.model.MemberId == 0 && this.data.model.PrimaryEmail === value)) {
        this.isEmailDuplicated = false;
        this.isApiCallSuccessful = true;
      }
      else if (allMemberEmails.indexOf(value) > -1) {        
        this.isEmailDuplicated = true;                    
        control.setErrors({ 'duplicated': { value: value } });
        control.markAsDirty();
        this.isApiCallSuccessful =false;
        console.log("check 6");       
      }else{        
        //control.disable({ emitEvent: false });
        control.markAsPending();              
        this.userSvc.checkExistEmailv2(value).subscribe(
          (res: DataResult<string>) => {
              const enctypted_data = res.Data;              
              const dectypted_data = this.commonSvc.D_FP_ResponseData(enctypted_data);              
              const ret = JSON.parse(dectypted_data);             
              control.enable({ emitEvent: false });
              control.markAsDirty();
              if (res.Success) {                                   
                  if (ret.IsExist) {
                      control.setErrors({ 'duplicated': { value: value } });
                      this.isEmailDuplicated = true;  
                      this.isApiCallSuccessful =false;
                      console.log("check 7");                                           
                  } else {
                      this.isEmailDuplicated = false; 
                      this.isApiCallSuccessful =true;                                            
                  }
              } else {
                  this.HandleResponseError(res);  
                  this.isApiCallSuccessful =false;
                  console.log("check 8");               
              }
          },
          err => {
              control.enable({ emitEvent: false });
              this.handleError(err); 
              this.isApiCallSuccessful =false;
              console.log("check 9");
              MessageBox.ShowError(
                this.dialog,
                "Sorry, something went wrong. Please try entering your email again."
              );
              this.addFamilyMemberFormGroup.get('txtPEmail').setValue('');             
          });
      }
    }   
  }

  onDOBSelected(): void {
    let memberType = this.addFamilyMemberFormGroup.get("txtMbType").value;
    let month = this.addFamilyMemberFormGroup.get("psdMonth").value;
    let month1 = month && (parseInt(month) + 1).toString().padStart(2, "0"); 
    let Year = this.addFamilyMemberFormGroup.get("psdYear").value;
    let day = this.addFamilyMemberFormGroup.get("psdDay").value;
    let day1 = day && day.toString().padStart(2, "0"); 

    if (month && Year && day) {
      this.selectedDate = new Date(`${Year}-${month1}-${day1}`);
      if (this.selectedDate) {
        if (!(this.selectedDate.getFullYear() == Year && this.selectedDate.getMonth() == month && this.selectedDate.getDate() == day)) {
          MessageBox.ShowError(
            this.dialog,
            "Please choose a valid Date."
          );
          this.addFamilyMemberFormGroup.get("psdDay").setValue(null);        
        }
      }
      if (month && Year && day && memberType) {        
        const today = new Date();
        today.getHours;
        const sixteenYearsAgo = new Date(
          today.getFullYear() - 16,
          today.getMonth(),
          today.getDate(),
          today.getHours(),
          today.getMinutes(),
          today.getMinutes(),
          today.getSeconds()
        );
  
        const eighteenYearsAgo = new Date(
          today.getFullYear() - 18,
          today.getMonth(),
          today.getDate(),
          today.getHours(),
          today.getMinutes(),
          today.getMinutes(),
          today.getSeconds()
        );
        
        this.dependentSelectedDate = eighteenYearsAgo;
        
        const twentyFiveYearsAfter = new Date(
          today.getFullYear() - 25,
          today.getMonth(),
          today.getDate(),
          today.getHours(),
          today.getMinutes(),
          today.getMinutes(),
          today.getSeconds()
        );       
  
        if (memberType.MemberTypeId == enums.EMemberType.Partner) {
         
          if (this.selectedDate) {
            if (this.selectedDate >= sixteenYearsAgo && (this.selectedDate.getFullYear() == Year && this.selectedDate.getMonth() == month && this.selectedDate.getDate() == day)) {
              MessageBox.ShowError(
                this.dialog,
                "Date of Birth is invalid / A partner must be over 16 years old"
              );              
              this.addFamilyMemberFormGroup.get("psdDay").setValue(null);
              this.addFamilyMemberFormGroup.get("psdMonth").setValue(null);
              this.addFamilyMemberFormGroup.get("psdYear").setValue(null);
            }
          }
        }
  
        if (memberType.MemberTypeId == enums.EMemberType.Dependant && this.selectedDate > eighteenYearsAgo){
          this.showPreferredFacilities = false;
          this.SetDataForDependantMember();
        }else if(memberType.MemberTypeId == enums.EMemberType.Dependant && this.selectedDate < twentyFiveYearsAfter){                 
          MessageBox.ShowError(
            this.dialog,
            "Dependant does not meet the age criteria"
          );              
          this.addFamilyMemberFormGroup.get("psdDay").setValue(null);
          this.addFamilyMemberFormGroup.get("psdMonth").setValue(null);
          this.addFamilyMemberFormGroup.get("psdYear").setValue(null);
          this.addFamilyMemberFormGroup.controls.txtPEmail.setValue(null);
          this.addFamilyMemberFormGroup.controls.txtMobileNum.setValue(null);
        }
        else{
          this.showPreferredFacilities = true;
          this.addFamilyMemberFormGroup.controls.txtPEmail.setValue(null);
          this.addFamilyMemberFormGroup.controls.txtMobileNum.setValue(null);
        }
      }
    }
   
    
    this.FavouredFacilityCtrl.setValidators([
      FPValidators.resolvableAutocompleteOption,
      CustomFacilityValidators.FavouredFacilityRequiredWhen,
    ]);
    this.FavouredFacilityCtrl.updateValueAndValidity({ emitEvent: false });
  }

  private SetDataForDependantMember()
  {
    if(this.data.model.PrimaryMember){
      this.addFamilyMemberFormGroup.controls.txtPEmail.setValue(this.data.model.PrimaryMember.PrimaryEmail);
      this.mobileNumberRegistor.SetValueFromMobileNo(this.data.model.PrimaryMember.MobileNumber, false);
      this.isApiCallSuccessful =true;
    }
  }
}

abstract class CustomFacilityValidators {
  static FavouredFacilityRequiredWhen = FPValidators.requiredWhen((ctrl) => {
    if (ctrl.parent) {
      let memberType = ctrl.parent.get("txtMbType").value;
      let month = ctrl.parent.get("psdMonth").value;
      let Year = ctrl.parent.get("psdYear").value;
      let day = ctrl.parent.get("psdDay").value;

      if (month && Year && day && memberType) {
        var selectedDate = new Date(Year, month, day);
        const today = new Date();
        today.getHours;
        const eighteenYearsAgo = new Date(
          today.getFullYear() - 18,
          today.getMonth(),
          today.getDate(),
          today.getHours(),
          today.getMinutes(),
          today.getMinutes(),
          today.getSeconds()
        );

        if (memberType.MemberTypeId == enums.EMemberType.Dependant && selectedDate > eighteenYearsAgo){
          return false;
        }
        else{
          return true;
        }
      }
    }
    return false;
  });

  static ExistingFacilityRequiredWhen = FPValidators.requiredWhen((ctrl) => {
    if (ctrl.parent) {
      const isExistingMemberCtrl = ctrl.parent.get("IsExistingMember");
      const relatedCtrl = ctrl.parent.get("ExistingFacilityOther");
      if (
        relatedCtrl &&
        (relatedCtrl.value == null || relatedCtrl.value.trim().length == 0) &&
        isExistingMemberCtrl &&
        isExistingMemberCtrl.value
      ) {
        return true;
      }
    }
    return false;
  });

  static ExistingFacilityOtherRequiredWhen = FPValidators.requiredWhen(
    (ctrl) => {
      if (ctrl.parent) {
        const isExistingMemberCtrl = ctrl.parent.get("IsExistingMember");
        const relatedCtrl = ctrl.parent.get("ExistingFacility");
        if (
          relatedCtrl &&
          relatedCtrl.value == null &&
          isExistingMemberCtrl &&
          isExistingMemberCtrl.value
        ) {
          return true;
        }
      }
      return false;
    }
  );
}
