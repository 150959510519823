import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, OnDestroy, HostBinding, Optional, Self } from '@angular/core';
import { NgControl, ControlValueAccessor, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatFormFieldControl } from '@angular/material/form-field';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MapFacilityData } from '@fp/models/map-facility-data';
import { CommonString } from '@fp/constant/common-string';
import { StringHelper } from '@fp/helpers';

interface FacilityValue {
  FacilityId: string,
  FacilityName: string,
  Location: string,
}

@Component({
  selector: 'app-facility-single-select',
  templateUrl: './facility-single-select.component.html',
  styleUrls: ['./facility-single-select.component.css'],
  providers: [{ provide: MatFormFieldControl, useExisting: FacilitySingleSelect }],
})
export class FacilitySingleSelect implements MatFormFieldControl<FacilityValue | null>, ControlValueAccessor, OnDestroy {
  
  stateChanges = new Subject<void>();
  facilityInput = new FormControl();
  filteredFacilities: MapFacilityData[] = [];
  private _value: FacilityValue | null = null;
  CommonString = CommonString;
  StringHelper = StringHelper;

  @Input() facilities: MapFacilityData[] = [];
  @Input()
  get value(): FacilityValue | null {
    return this._value;
  }
  set value(newValue: FacilityValue | null) {
    if (newValue == null || Object.keys(newValue).length === 0 ) {
      this.ClearValue();
    }
    if (newValue !== this._value) {
      this._value = newValue;
      this._onChange(newValue);
    }
  }

  constructor(
    private focusMonitor: FocusMonitor, 
    private elementRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl) { 
      this.ngControl.valueAccessor = this; 
    }

    focusMonitor.monitor(elementRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    this.facilityInput.valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
    )
    .subscribe(searchTerm => {
      if (typeof searchTerm === "string" && searchTerm.trim().length >= 3) {
        let getList = this.facilities.filter(
            (x) =>
              x.Name.toLowerCase()
                .trim()
                .includes(
                  searchTerm.toLowerCase().trim()
                )
              || 
              x.Name
              .trim()
              .includes(
                searchTerm.toLowerCase().trim()
              )
        );
        this.filteredFacilities = getList;
      }
      else if(typeof searchTerm === "string" && searchTerm.trim().length < 3) {
        this.clearFacilityList();
      }
      else{
        // Do nothing.Accept the value
      }
    }
    );
  }

  checkValidSelection() {
    if((this._value && this._value.FacilityId && this._value.FacilityName 
      && this.facilityInput.value && this._value.FacilityName === this.facilityInput.value.Name) || this.facilityInput.value == ''){
      this.ngControl.control.setErrors(null);
    }
    else {
      this.ngControl.control.setErrors({ 'unresolved': true });
    }
  }

  private clearFacilityList(){
    this.filteredFacilities = [];
  }

  focused = false;

  get errorState(): boolean {
    return !!this.ngControl.errors;
  }

  controlType = 'facility-single-select';
  static nextId = 0;
  @HostBinding() id = `${this.controlType}-${FacilitySingleSelect.nextId++}`;
  

  get empty() {
    return !this.facilityInput.value;
  }
  
  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _placeholder: string;
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(newPlaceholder) {
    this._placeholder = newPlaceholder;
    this.stateChanges.next();
  }

  private _required = false;
  @Input()
  get required() {
    return this._required;
  }
  set required(newRequired) {
    this._required = coerceBooleanProperty(newRequired);
    this.stateChanges.next();
  }

  private _disabled = false;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(newDisabled) {
    this._disabled = coerceBooleanProperty(newDisabled);
    if (this._disabled) {
      this.facilityInput.disable();
    } else {
      this.facilityInput.enable();
    }
    this.stateChanges.next();
  }


  @HostBinding('attr.aria-describedby') describedBy = '';
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).id !== 'facilitySelect') {
      this.elementRef.nativeElement.querySelector('#facilitySelect').focus();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  // ControlValueAccessor
  writeValue(newValue: FacilityValue | null): void {
    this.value = newValue;
  }
  
  _onChange: (value: FacilityValue | null) => void = () => {};
  registerOnChange(fn: (value: FacilityValue | null) => void): void {
    this._onChange = fn;
  }

  // not called!
  _onTouched = () => {};
  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }

  getOptionText(option) {
    if (option) {
      if (option.Name && option.State && option.Postcode) {
        return `${option.Name}, ${option.Suburb.Name} ${option.State.Name}`;
      } else {
        return option;
      }
    } else {
      return "";
    }
  }

  SelectValue(v) {
    this._value = {
      FacilityId : v.FacilityId,
      FacilityName: v.Name,
      Location: `${v.Suburb.Name} ${v.State.Name}`
    }
    this._onChange(this._value);
    this.ngControl.control.setErrors(null);
  }

  ClearValue(){
    this._value = null;
    this._onChange(this._value);
    this.facilityInput.setValue("");
    this.filteredFacilities = [];
  }

  HandleKeyUp(event: KeyboardEvent) {
    const key = event.keyCode || event.charCode;
    if (
      (
      key === 8 || key === 46 ||
      (key === 8 && 17) ||
      (key === 8 && 16) ||
      (key === 46 && 17)
      ) && (this._value != null)
    ){
      this.ClearValue();
    }
  }
  
}
