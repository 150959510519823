import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { catchError, startWith, switchMap } from 'rxjs/operators';
import { APIConstant } from 'src/app/constant/apiconstant';
import { DataResult, SearchPaginationResult, SearchPaginationCriteria, SearchPaginationRequest } from 'src/app/models';
import { ParentGroup } from 'src/app/models/parent-group.model';
import { CommonService } from 'src/app/services/common.service';
import { HttpDAO } from './httpdao.service';

@Injectable({
    providedIn: 'root'
})
export class ParentGroupService {
    private httpDao: HttpDAO | null;

    constructor(httpClient: HttpClient,
        private commonService: CommonService) {
        this.httpDao = new HttpDAO(httpClient);
    }

    load(searchRequest:any): Observable<DataResult<SearchPaginationResult<ParentGroup>>> {
        return this.httpDao.postData(APIConstant.API_GET_PARENT_GROUPS_PAGING,searchRequest);
    }

    getData(apiString: string, subscribeDataCallback: Function) {
        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.commonService.StartProgressBar();
                    return this.httpDao.getData(apiString);
                }),
                catchError((error) => {
                    this.commonService.StopProgressBar();
                    throw error;
                }))
            .subscribe(data => {
                subscribeDataCallback(data);
                this.commonService.StopProgressBar();
            });
    }

    getByName(name: string) {
        return this.httpDao.getSingleData(APIConstant.API_GET_PARENT_GROUP_BY_NAME + name);
    }

    postData(apiString: string, subscribeDataCallback: Function, dataInput: any) {
        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.commonService.StartProgressBar();
                    return this.httpDao.postData(apiString, dataInput);
                }),
                catchError((error) => {
                    this.commonService.StopProgressBar();
                    throw error;
                }))
            .subscribe(result => {
                this.commonService.StopProgressBar();
                subscribeDataCallback(result);
            });
    }

    deleteParentGroup(id: string){
        return this.httpDao.getSingleData(APIConstant.API_DELETE_PARENT_GROUP + id);

    }

}
