<div [formGroup]="form">
    <div class="form-group row">
        <label for="ddlProductType" class="col-md-3 col-lg-2 col-form-label">Product Type<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select id="ddlProductType" class="form-control" formControlName="ServiceType" [compareWith]="types_CompareFn"
                    [class.is-invalid]="!isControlValid1(typeControl)">
                    <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let t of types" [ngValue]="t">{{ t.Name }}</option>
                </select>
                <small *ngIf="!isControlValid1(typeControl)" class="invalid-feedback d-block">
                    Product Type is required
                </small>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtProductName" class="col-md-3 col-lg-2 col-form-label">Product Name<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input class="form-control" type="text" id="txtProductName" formControlName="Name" maxlength="100"
                    [class.is-invalid]="!isControlValid1(nameControl) ">
                <small *ngIf="!isControlValid1(nameControl)" class="invalid-feedback d-block">
                    Product Name is required
                </small>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtProductName" class="col-md-3 col-lg-2 col-form-label">Price<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="input-group" [class.is-invalid]="!isControlValid1(priceControl)" [ngClass]="getClassFocused(2)">
                    <span class="input-group-prepend">$</span>
                    <input class="form-control" type="tel" id="txtPrice" formControlName="Price" maxlength="14"
                        appCurrencyMask placeholder="0.00" (blur)="onBlurParent(0)" (focus)="onFocusParent(2)">
                </div>
                <small *ngIf="!isControlValid1(priceControl) " class="invalid-feedback d-block">
                    Price (ex. GST) is required
                </small>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlProdtStatus" class="col-md-3 col-lg-2 col-form-label">Status<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select id="ddlProdtStatus" class="form-control" formControlName="Status" [compareWith]="statuses_CompareFn"
                    [class.is-invalid]="!isControlValid1(statusControl)">
                    <option *ngFor="let s of statuses" [ngValue]="s">{{
                        s.Name }}</option>
                </select>
                <small *ngIf="!isControlValid1(statusControl) " class="invalid-feedback d-block">
                    Status is required
                </small>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtMaxUA" class="col-md-3 col-lg-2 col-form-label">Max Units Available</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input class="form-control" type="text" id="txtMaxUA" formControlName="MaxUnitsAvailable" maxlength="9"
                    [class.is-invalid]="!isValidControlMaxUnit(maxUnitsAvailable)" numberOnly>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtFPProdtCost" class="col-md-3 col-lg-2 col-form-label">FP Product Cost (ex. GST)<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="input-group" [class.is-invalid]="!isControlValid1(productCostControl)" [ngClass]="getClassFocused(3)">
                    <span class="input-group-prepend">$</span>
                    <input class="form-control" type="tel" id="txtFPProdtCost" formControlName="FpproductCost"
                        maxlength="14" appCurrencyMask placeholder="0.00" (blur)="onBlurParent(0)" (focus)="onFocusParent(3)">
                </div>
                <small *ngIf="!isControlValid1(productCostControl) " class="invalid-feedback d-block">
                    FP Product Cost (ex. GST) is required
                </small>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtMbrSPrice" class="col-md-3 col-lg-2 col-form-label">Member Sale Price (ex. GST)<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="input-group" [class.is-invalid]="!isControlValid1(memberPriceControl)" [ngClass]="getClassFocused(4)">
                    <span class="input-group-prepend">$</span>
                    <input class="form-control" type="tel" id="txtMbrSPrice" formControlName="MemberSalePrice"
                        maxlength="14" appCurrencyMask placeholder="0.00" (blur)="onBlurParent(0)" (focus)="onFocusParent(4)">
                </div>
                <small *ngIf="!isControlValid1(memberPriceControl) " class="invalid-feedback d-block">
                    Member Sale Price (ex. GST) is required
                </small>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtMbrSPrice" class="col-md-3 col-lg-2 col-form-label">GST Applicable<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="d-flex">
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" name="IsGstapplicable" id="gstY" class="custom-control-input"
                            formControlName="IsGstapplicable" [value]="true">
                        <label class="custom-control-label" for="gstY">Yes</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" name="IsGstapplicable" id="gstN" class="custom-control-input"
                            formControlName="IsGstapplicable" [value]="false">
                        <label class="custom-control-label" for="gstN">No</label>
                    </div>
                </div>
                <small *ngIf="!isControlValid1(isGsControl) " class="invalid-feedback d-block">
                    GST Applicable is required
                </small>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtDescription" class="col-md-3 col-lg-2 col-form-label">Description</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <textarea class="form-control" id="txtDescription" maxlength="5000" formControlName="Description"></textarea>
            </div>
        </div>
    </div>
</div>