import { Component, Injector } from "@angular/core";
import { MemberFormBase } from "@fp/components/base";
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, FormControl } from "@angular/forms";
import { FpCsvColumn } from "@fp/models/file-import.model";
import { Member } from "@fp/models";
import {
  NgbDateAdapter,
  NgbDateNativeUTCAdapter,
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-member-contract-details",
  templateUrl: "./contract-details.component.html",
  styleUrls: ["./contract-details.component.css"],
  providers: [
    { provide: MemberFormBase, useExisting: MemberContractDetailsComponent },
    { provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter },
  ],
})
export class MemberContractDetailsComponent extends MemberFormBase {
    isMemberSelfService: boolean = false;
    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
          TermConditionDateTimeStamp: [null],
          ContractStartDate: [null],
          ContractEndDate: [null],
          Memberships: fb.array([MemberContractDetailsComponent.getMembershipFormGroup()])
      });
    }

    static getMembershipFormGroup(): UntypedFormGroup {
        const fb = new UntypedFormBuilder();
        return fb.group({
            EmployeePayrollNo: [null],
            EndDate: [null],
            Id: [0],
            IsEnough12Months: [null],
            Member: [null],
            MemberCards: [null],
            MemberIdPrimary: [0],
            MembershipPackage: [null],
            MembershipPackageId: [0],
            MembershipStatus: [null],
            MembershipStatusId: [0],
            ModifiedDateTime: [null],
            Bcf: [0],
            StartDate: [null]
        });
    }

  get MembershipCtrl() {
    let ctrl = <UntypedFormArray>this.form.get("Memberships");
    if (!ctrl.value || ctrl.length === 0) {
      ctrl = new UntypedFormArray([
        MemberContractDetailsComponent.getMembershipFormGroup(),
      ]);
    }
    return ctrl;
  }

  constructor(injector: Injector) {
    super(injector);
  }

  PatchValue(value: Member, opts) {
    const memberships = value.Memberships || [];
    const options:Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    if (memberships.length > 0) {
      if (memberships[0].StartDate != null) {
        if (typeof memberships[0].StartDate === "string") {
          let start = new Date(memberships[0].StartDate);
          this.form
            .get("ContractStartDate")
            .setValue(
              start.toLocaleDateString("en-AU", options).replace(/\s/g, "-")
            );
        } else {
          let start = memberships[0].StartDate as Date;
          this.form
            .get("ContractStartDate")
            .setValue(
              start.toLocaleDateString("en-AU", options).replace(/\s/g, "-")
            );
        }
      }
      if (memberships[0].EndDate != null) {
        if (typeof memberships[0].EndDate === "string") {
          let end = new Date(memberships[0].EndDate);
          this.form
            .get("ContractEndDate")
            .setValue(
              end.toLocaleDateString("en-AU", options).replace(/\s/g, "-")
            );
        } else {
          let end = memberships[0].EndDate as Date;
          this.form
            .get("ContractEndDate")
            .setValue(
              end.toLocaleDateString("en-AU", options).replace(/\s/g, "-")
            );
        }
      }
    }
    try {
      super.PatchValue(value, opts);
    } catch (error) {
      console.log("Error due to Non-Membership");
    }
  }
}
