import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";
import { merge, empty } from "rxjs";
import { catchError, startWith, switchMap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";

import { BaseControlComponent, MakeProvider } from "../base/base-control";
import { PatternConstant } from "../../../constant/patternconstant";
import { UserService } from "../../../services/admin/user/user.service";

import { MessageBox } from "src/app/services/common-dialog.service";
import { CommonService } from "@fp/services/common.service";
import { LocationService } from "@fp/services/location.service";
import { CommonString } from "@fp/constant";

import * as enums from "src/app/enums";
import { DataResult, ResultModel } from "@fp/models";
import { Logger, StringHelper, Utils } from "@fp/helpers";

@Component({
  selector: "app-mobile-number-registor",
  templateUrl: "./mobile-number-registor.component.html",
  styleUrls: ["./mobile-number-registor.component.css"],
  host: { "(blur)": "onTouched()" },
  providers: [MakeProvider(MobileNumberRegistorComponent)],
})
export class MobileNumberRegistorComponent<T>
  extends BaseControlComponent<string>
  implements OnInit
{
  @Input() control: AbstractControl; // required
  @Input() label: string;
  @Input() placeholder: string;
  @Input() mandatory: boolean;
  @Input() mandotoryLabelRed: boolean;
  @Input() isAus: boolean;
  @Input() isCountryCodeSet: boolean;
  @Input() submitted: boolean; // flag when click on submit button
  @Input() readonly: boolean;
  @Input() mobileLength = 10;
  @Input() pattern = PatternConstant.PATTERN_PHONE;
  @Input() checkExist: false;
  @Input() clsLabel: string;
  @Input() clsGroup: string;
  @Input() currentPhone: string = "";
  @Input() col_4: boolean;
  @Input() col_3: boolean;
  @Output() runSingupCheck = new EventEmitter();

  @Input() checkExistMobile: boolean;
  @Input() memberTypePass: AbstractControl;
  @Input() dataModel: any;
  @Input() primarySelectDate: Date;
  @Input() primaryDependentSelectedDate: Date;

  _validators: ValidatorFn[];

  invalidPhone = false;
  position: GeolocationPosition;
  mobileNo: any = null;
  phoneSpinter = false;
  parentFocus = 0;
  countryCodes = ['+61','+64'];
  defaultCountryCode: any;
  selectedCountryCode= this.countryCodes[0].replace("+", "");
  commonString = CommonString;
  isAusValidationMessage: boolean;

  isMPDuplicated: boolean = false;
  protected readonly _logger: Logger;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private cmsrv: CommonService,
    private locationservice: LocationService
  ) {
    super();
    this._validators = [];
  }

  ngOnInit(): void {
    this._validators = [this.control.validator];
    this.defaultCountryCode = this.countryCodes[0];

    if(this.isCountryCodeSet){
      this.setDefinedCountryCode();
    }
    else{
      this.getPosition();
    }
    
    if (this.mandatory) {
      this.control.setValidators([
        Validators.required,
        Validators.pattern(PatternConstant.PATTERN_COUNTRY_CODE_PHONE_1),
      ]);
    } else {
      this.control.setValidators([
        Validators.pattern(PatternConstant.PATTERN_COUNTRY_CODE_PHONE_1),
      ]);
    }

    this.CheckIsAUMobile();
  }

  writeValue(obj: any) {
    this.mobileNo = obj;
    this.onChange(obj);
    this.CheckIsAUMobile();
  }

  private setDefinedCountryCode(){
    if(this.isAus ){
      this.defaultCountryCode = this.countryCodes[0];
    }
    else{
      this.defaultCountryCode = this.countryCodes[1];
    }
    this.CheckIsAUMobile();
  }

  private getPosition = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      if(position){
        this.position = position;
        this.getCountry();
      }
    });
  }

  private async getCountry(){

    if(this.position.coords.longitude && this.position.coords.latitude){

      var geoLocationData = await this.locationservice.getUserCountryByGeoLocation(this.position.coords.longitude, this.position.coords.latitude);

      if(geoLocationData.Results.length > 0){
        var countryCode = geoLocationData.Results[0].Place.Country;

        if(countryCode == this.commonString.AustraliaCountryCode){
          this.defaultCountryCode = this.countryCodes[0];
        }
        else if(countryCode == this.commonString.NewZealandCountryCode){
          this.defaultCountryCode = this.countryCodes[1];
        }
        else{
          this.defaultCountryCode = this.countryCodes[0];
        }
      }

      this.onChangeCountryLocation(this.defaultCountryCode);
    }
  }

  private CheckValidatePhone() {
    if (this.control.value != null) {
      if (this.control.value.indexOf("00") === 2) {
        this.invalidPhone = true;
        setTimeout(() => {
          this.control.setErrors({ invalid: true });
          this.control.markAsDirty();  
        }, 50);

      } else {
        this.invalidPhone = false;
      }
      this.CheckIsAUMobile();
    }
    this.control.updateValueAndValidity({ emitEvent: false });
  }

  txtMobileNumber_Blur(e) {
    const value = <string>e.target.value;

    this.CheckValidatePhone();

    if(!this.invalidPhone){
      this.ValidateMobileNumber(value);
    }
  }

  public ValidateMobileNumber(value: string, force = false) {         
      const controlPrimary = this.control;       
      value = (value || '').trim();
      let checkMemberType = this.memberTypePass.value ? this.memberTypePass.value : this.memberTypePass; 
      if (controlPrimary.invalid || !value || (!force && (value.length < 3))) {              
        return;
      }
      this.SetValidator();
      controlPrimary.updateValueAndValidity({ emitEvent: false });
      let allMemberPNumbers = [];
      
      if (this.dataModel.PrimaryMember) {
        const famMemberPNumbers = (this.dataModel.PrimaryMember.FamilyMembers || []).map(_member => _member.MobileNumber);       
        allMemberPNumbers = [
          this.dataModel.PrimaryMember.MobileNumber,
          ...famMemberPNumbers
        ];     
      }
  
      if (
          checkMemberType 
          && 
          (
            (checkMemberType.MemberTypeId == enums.EMemberType.Dependant && this.primarySelectDate > this.primaryDependentSelectedDate) //underage dependant
            || 
            (checkMemberType.MemberTypeId != enums.EMemberType.Primary && this.dataModel.PrimaryMember?.MemberId == this.dataModel.MemberId) //self editing non-primary
          )
        ){   
        let dataModelMobile = this.dataModel && this.dataModel.PrimaryMember && this.dataModel.PrimaryMember.MobileNumber; 
        var mobileWithoutCountryCode;

        if(this.dataModel.MobileNumber){
          mobileWithoutCountryCode = this.dataModel.MobileNumber.replace("+", "");
        }
        if(dataModelMobile == ("+" + Utils.convertPhoneToInternationalFormat(value, this.selectedCountryCode)) || (mobileWithoutCountryCode === value)){
          this.isMPDuplicated = false;
        }else{
          if (this.dataModel.PrimaryMember && this.dataModel.MobileNumber 
            && mobileWithoutCountryCode === Utils.convertPhoneToInternationalFormatWithCountryCode(this.control.value)
            && this.dataModel.PrimaryMember.MobileNumber != this.dataModel.MobileNumber) {
            this.isMPDuplicated = false;
          }
          else if (allMemberPNumbers.indexOf("+" + Utils.convertPhoneToInternationalFormat(value, this.selectedCountryCode)) > -1) {
            this.isMPDuplicated = true;
            controlPrimary.setErrors({ 'duplicated': { value: value } });
            controlPrimary.markAsDirty();                    
          }else{
            //controlPrimary.disable({ emitEvent: false });
            controlPrimary.markAsPending();          
            this.userService.checkExistPhonev2("+" + Utils.convertPhoneToInternationalFormat(value, this.selectedCountryCode)).subscribe(
              (res: DataResult<string>) => {
                  const enctypted_data = res.Data;              
                  const dectypted_data = this.cmsrv.D_FP_ResponseData(enctypted_data);              
                  const ret = JSON.parse(dectypted_data);             
                  controlPrimary.enable({ emitEvent: false });
                  controlPrimary.markAsDirty();
                  if (res.Success) {                                   
                      if (ret.IsExist) {
                          controlPrimary.setErrors({ 'duplicated': { value: value } });
                          this.isMPDuplicated = true;  
                                                                                                         
                      } else {
                        this.isMPDuplicated = false;                                                 
                                                            
                      }
                  } else {
                      this.HandleResponseErrorMobile(res);                 
                  }
              },
              err => {
                  controlPrimary.enable({ emitEvent: false });
                  this.handleErrorMobile(err);             
              });
          }  
        }    
      }else{

        var mobileWithoutCountryCode;

        if(this.dataModel.MobileNumber){
          mobileWithoutCountryCode = this.dataModel.MobileNumber.replace("+", "");
        }

        if (this.dataModel.PrimaryMember &&  this.dataModel.MobileNumber 
          && mobileWithoutCountryCode === Utils.convertPhoneToInternationalFormatWithCountryCode(this.control.value)
          && this.dataModel.PrimaryMember.MobileNumber != this.dataModel.MobileNumber ) {
          this.isMPDuplicated = false;
        }
        else if (!this.dataModel.PrimaryMember && !this.dataModel.MemberIdPrimary && mobileWithoutCountryCode === Utils.convertPhoneToInternationalFormatWithCountryCode(this.control.value)){
          this.isMPDuplicated = false;
        }
        else if (allMemberPNumbers.indexOf("+" + Utils.convertPhoneToInternationalFormat(value, this.selectedCountryCode)) > -1) {
          this.isMPDuplicated = true;
          controlPrimary.setErrors({ 'duplicated': { value: value } });
          controlPrimary.markAsDirty();          
        }else{
          //controlPrimary.disable({ emitEvent: false });
          controlPrimary.markAsPending();          
          this.userService.checkExistPhonev2("+" + Utils.convertPhoneToInternationalFormat(value, this.selectedCountryCode)).subscribe(
            (res: DataResult<string>) => {
                const enctypted_data = res.Data;              
                const dectypted_data = this.cmsrv.D_FP_ResponseData(enctypted_data);              
                const ret = JSON.parse(dectypted_data);             
                controlPrimary.enable({ emitEvent: false });
                controlPrimary.markAsDirty();
                if (res.Success) {                                   
                    if (ret.IsExist) {
                        controlPrimary.setErrors({ 'duplicated': { value: value } });
                        this.isMPDuplicated = true;                                                                                 
                    } else {
                      this.isMPDuplicated = false;                                                      
                    }
                } else {
                    this.HandleResponseErrorMobile(res);                 
                }
            },
            err => {
                controlPrimary.enable({ emitEvent: false });
                this.handleErrorMobile(err);             
            });
        }
      }       
  }

  public HandleResponseErrorMobile(response: ResultModel) {
    let message = null;
    if (response && response.ErrorNumber !== 0 && response.Message) {
      message = response.Message;
    }
    this.handleErrorMobile(response, message);
  }

  public handleErrorMobile(error, message?: string) {
    console.log("Error JSON from API: ", error);
    console.log("Error message: ", message);
    if (
      this.dialog.openDialogs.length === 0 ||
      !StringHelper.isNullOrEmpty(message)
    ) {
      message = message || "An unknown error has occurred";
      MessageBox.ShowError(this.dialog, message);
    }
    this._logger.error(error);
  }

  private setLocationValidator() {
    this.control.clearValidators();
    if (this.mandatory) {
      if (this.defaultCountryCode === '+64') {
        this.mobileLength = 12;
        this.control.setValidators([
          Validators.required,
          Validators.pattern(PatternConstant.PATTERN_COUNTRY_CODE_PHONE_3),
        ]);
      }
      else {
        this.mobileLength = 10;
        this.control.setValidators([
          Validators.required,
          Validators.pattern(PatternConstant.PATTERN_COUNTRY_CODE_PHONE_1),
        ]);
      }
    } else {
      if (this.defaultCountryCode === '+64') {
        this.mobileLength = 12;
        this.control.setValidators([
          Validators.pattern(PatternConstant.PATTERN_COUNTRY_CODE_PHONE_3),
        ]);
      }
      else {
        this.mobileLength = 10;
        this.control.setValidators([
          Validators.pattern(PatternConstant.PATTERN_COUNTRY_CODE_PHONE_1),
        ]);
      }
    }
    this.control.updateValueAndValidity();
    this.CheckIsAUMobile()
  }

  private SetValidator() {
    if (this.control.value == null) {
      return;
    } else {
      this.control.clearValidators();
      if (this.mandatory) {
        if(this.control.value.startsWith('64')){
          this.mobileLength = 12;
          if (this.control.value.indexOf("0") === 2) {
            this.control.setValidators([
              Validators.required,
              Validators.pattern(PatternConstant.PATTERN_COUNTRY_CODE_PHONE_4),
            ]);
          } else {
            this.control.setValidators([
              Validators.required,
              Validators.pattern(PatternConstant.PATTERN_COUNTRY_CODE_PHONE_3),
            ]);
          }
        }
        else{
          this.mobileLength = 10;
          if (this.control.value.indexOf("0") === 2) {
            this.control.setValidators([
              Validators.required,
              Validators.pattern(PatternConstant.PATTERN_COUNTRY_CODE_PHONE_2),
            ]);
          } else {
            this.control.setValidators([
              Validators.required,
              Validators.pattern(PatternConstant.PATTERN_COUNTRY_CODE_PHONE_1),
            ]);
          }
        }
      } else {
        if(this.control.value.startsWith('64')){
          this.mobileLength = 12;
          if (this.control.value.indexOf("0") === 2) {
            this.control.setValidators([
              Validators.pattern(PatternConstant.PATTERN_COUNTRY_CODE_PHONE_4),
            ]);
          } else {
            this.control.setValidators([
              Validators.pattern(PatternConstant.PATTERN_COUNTRY_CODE_PHONE_3),
            ]);
          }
        }
        else{
          this.mobileLength = 10;
          if (this.control.value.indexOf("0") === 2) {
            this.control.setValidators([
              Validators.pattern(PatternConstant.PATTERN_COUNTRY_CODE_PHONE_2),
            ]);
          } else {
            this.control.setValidators([
              Validators.pattern(PatternConstant.PATTERN_COUNTRY_CODE_PHONE_1),
            ]);
          }
        }
      }
      this.control.updateValueAndValidity();
      this.CheckIsAUMobile()
    }
  }

  CheckIsAUMobile(){
    if(this.selectedCountryCode && this.selectedCountryCode === '64'){
      this.isAusValidationMessage = false;
    }
    else{
      this.isAusValidationMessage = true;
    }
  }

  public checkPhoneEmailResponsive() {
    let number = this.control.value;
    if (number != null) {
      if (number.length >= 9) {
        this.SetValidator();
        this.runSingupCheck.emit("RunCheck");
      }
    }
  }

  public OnChange(event) {

    var initialValue = this.control.value;
    var changedValue = null

    this.SetValidator();

    let val = event.target.value;
    const evkey = PatternConstant.NOT_NUMBER;
    const re = new RegExp(evkey, "g");
    val = val.replace(re, "");
    if(val){
      this.control.setValue(this.selectedCountryCode + val);
      changedValue = this.selectedCountryCode + val
    }
    else{
      this.control.setValue(val);
      changedValue = val;
    }
    this.mobileNo = val;

    if(initialValue != changedValue){
      this.checkPhoneEmailResponsive();
    }
    this.CheckValidatePhone();
  }

  public CallOnChange(value) {
    this.CheckValidatePhone();

    this.control.clearValidators();

    let val = value;
    const evkey = PatternConstant.NOT_NUMBER;
    const re = new RegExp(evkey, "g");
    val = val.replace(re, "");
    if(val){
      this.control.setValue(this.selectedCountryCode + val);
    }
    else{
      this.control.setValue(val);
    }
    this.mobileNo = val;
    this.SetValidator();
  }

  OnNotCheckExistPhone() {
    this.onTouched();
    this.onBlurParent(0);
  }

  public SetValueFromMobileNo(val: string, checkValidity: boolean){
    val = val.replace("+", "");
    if(val && val.length >= 9){
      var countrycode = '+' + val.toString().slice(0, 2);
      var countryCodeIndex = this.countryCodes.findIndex(x => x == countrycode);
      if(countryCodeIndex != -1){
        this.control.setValue(val );
        this.defaultCountryCode = this.countryCodes[countryCodeIndex];
        if(this.defaultCountryCode.includes("+")){
          var codeWithoutPlus = this.defaultCountryCode.replace("+", "");
          this.selectedCountryCode = codeWithoutPlus;          
        }       
        this.mobileNo = val.toString().substring(2)
        this.SetValidator();

        if(checkValidity){
          this.ValidateMobileNumber(this.mobileNo, true);
        }
      }
    }
  }

  public SetDefaultCountryCode(isAU: boolean){
    this.defaultCountryCode = isAU ?  this.countryCodes[0] : this.countryCodes[1];
    this.selectedCountryCode = this.defaultCountryCode.replace("+", "");
  }

  CheckExistPhone() {
    this.onTouched();
    this.onBlurParent(0);
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          let number = "";
          const length = (<string>this.control.value).length;

          if(this.control.value.startWith('64')){
            if(this.control.value.charAt(2) == "0"){
              var phone = this.control.value;
              var part1 = phone.substring(0, 2);
              var part2 = phone.substring(3, phone.length);
              number = part1 + part2;
            }
            else{
              number = this.control.value;
            }
          }
          else{
            if (length === 12) {
              number = this.control.value.replace(0, "");
            } else {
              number = this.control.value;
            }
          }
          
          if (number.length > 0 && this.control.valid && !this.invalidPhone) {

            this.phoneSpinter = true;
            this.control.setErrors({ waitingcheck: true });
            return this.userService.checkExistPhonev2(number);
            
          } else {
            this.phoneSpinter = false;
          }
          return empty();
        }),
        catchError((e) => {
          throw e;
        })
      )
      .subscribe((result) => {
        const enctypted_data = result.Data;
        const dectypted_data = this.cmsrv.D_FP_ResponseData(enctypted_data);
        const ret = JSON.parse(dectypted_data);
        this.phoneSpinter = false;
        this.control.setErrors({ waitingcheck: false });
        this.control.updateValueAndValidity();
        if (result.Success) {
          if (result.Data !== null && ret.IsExist) {
            this.control.setErrors({ duplicated: true });
          } else {
            this.control.setErrors(null);
          }
        } else {
          MessageBox.ShowError(
            this.dialog,
            "An error has occurred. Please contact the administrator"
          );
        }
      });
  }

  OnChangeCountryCode(e){
    let selectedOptions = e.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;
    this.selectedCountryCode = selectElementText.replace("+", "");

    if(this.control.value && this.control.value.length > 2){
      var val = this.control.value.slice(2);
      if(val){
        this.control.setValue(this.selectedCountryCode + val);
      }
      else{
        this.control.setValue(val);
      }
    }
    else{
      this.control.setValue("")
    }

    this.mobileNo = val;
    
    this.SetValidator();
    this.CheckValidatePhone();
    if(this.mobileNo != undefined && this.checkExistMobile && !this.invalidPhone){      
      this.ValidateMobileNumber(this.control.value, true);
    }
  }

  private onChangeCountryLocation(countryCode) {
    this.selectedCountryCode = countryCode.replace("+", "");

    if (this.control.value && this.control.value.length > 2) {
      var val = this.control.value.slice(2);
      if (val) {
        this.control.setValue(this.selectedCountryCode + val);
      }
      else {
        this.control.setValue(val);
      }

      this.mobileNo = val;

      this.SetValidator();

      this.CheckValidatePhone();

      if (this.mobileNo != undefined && this.checkExistMobile && !this.invalidPhone) {
        this.ValidateMobileNumber(this.control.value, true);
      }
    }
    else {
      this.setLocationValidator();
    }
  }

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }
  onBlurParent(focusNumbr) {
    this.parentFocus = focusNumbr;
  }
  getClassFocused(vlFocused) {
    if (this.parentFocus === vlFocused) {
      return "focused";
    } else {
      return "";
    }
  }
}
