<div>
    <ngb-accordion activeIds="panelGVPayment" (click)="toggleAccordian($event)">
        <ngb-panel id="panelGVPayment" (click)="toggleAccordian($event)">
            <ng-template ngbPanelTitle>
                Gym Visit Payment
                <i class="icon-fp" [ngClass]="{'fas fa-check': true}"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="w-100">
                    <div class="border-default rounded-10 border-2">
                        <div>
                            <table mat-table [dataSource]="paymentLogDataSource" matSort matSortActive="DateCreated"
                                matSortDirection="desc" class="rounded-10">

                                <ng-container matColumnDef="Service">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Service
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.Service }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="DateCreated">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Payment Period
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.PaymentPeriodStart | dateFormatPipe }} -
                                        {{ element.PaymentPeriodEnd | dateFormatPipe }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="VisitCount">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Visit Count
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.VisitCount }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="DollarAmount">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Dollar Amount
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.DollarAmount | currency }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="PaymentCalculationMethod">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Calculation Method
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.PaymentCalculationMethod }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="PRStatus">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        PR Status
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.PRStatus }}
                                    </td>
                                </ng-container>
                                <!-- PRID is for front-end processing. Please don't delete. It is not displayed. -->
                                <ng-container matColumnDef="PRID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        PR Status
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.PaymentRequestID }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Download">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="px-2"
                                            (click)="downloadAttachements({ InvoiceAttachment: element.InvoiceAttachmentUuid, AdditionalAttachement: element.AdditionalAttachmentsUuid || '', AdditionalAttachmentsFileName: element.AdditionalAttachmentsFileName || '', InvoiceFileName: element.InvoiceFileName })"
                                            *ngIf="element.InvoiceAttachmentUuid || element.AdditionalAttachmentsUuid">
                                            <input type="image"
                                                src="../../../../assets/images/download_icon_manually_upload.png" />
                                        </a>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="PaymentRequestNotes">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Notes
                                    </th>
                                    <td mat-cell *matCellDef="let element" >
                                        <button *ngIf="element.PaymentRequestNotes.length > 0" (click)="openNotesDialog(element)" class="btn btn-outline-secondary">
                                            View notes
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                            </table>
                            <div *ngIf="isLoading" class="spinner-wrapper">
                                <mat-spinner [diameter]="20"></mat-spinner>
                            </div>
                            <mat-paginator #paymentlogpagi [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons>
                            </mat-paginator>
                            <mat-toolbar [hidden]="dataSource.length > 0 || isLoading" class="bg-white">
                                <mat-toolbar-row class="text-muted d-block p-2 bg-light text-center">
                                    {{ CommonString.TABLE_NO_RECORD_FOUND }}
                                </mat-toolbar-row>
                            </mat-toolbar>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>