<div class="modal-header px-0 justify-content-start" id="modalheader">
    <h5 class="modal-title" id="exampleModalLabel">Add/Edit Service</h5>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body py-3 noscrollx fpscrolly height100">
    <app-facility-service-details [form]="form.get('DetailsSection')" [data]="data"></app-facility-service-details>
    <div class="form-group row">
        <div class="col-md-8 col-lg-5 offset-sm-2">
            <button class="px-5 py-2 btn btn-primary" type="button" (click)="submit()">
                <i class="fas fa-check mr-2"></i> SUBMIT</button>
        </div>
    </div>
</div>
<!-- TODO: remove after implementation completed -->
<!-- #region DEBUG -->
<div *ngIf="isDebugging" class="tabFPTheme mt-5">
    <pre>Request count: {{ data.requestQueueCount }}</pre>
    <pre>Form valid: {{ form.valid }}</pre>
    <ngb-accordion>
        <ngb-panel id="pnlFormJson">
            <ng-template ngbPanelTitle>
                Form JSON
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <pre>{{ JSON.stringify(form.value, null, 4) }}</pre>
            </ng-template>
        </ngb-panel>
        <ngb-panel>
            <ng-template ngbPanelTitle>
                Data JSON
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <pre>{{ JSON.stringify(data.model, null, 4) }}</pre>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>
<!-- #endregion DEBUG -->