import { Component, OnInit, Injector, Input } from '@angular/core';
import { MemberFormBase } from 'src/app/components/base/member-form-base';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BankAccount } from 'src/app/models';
import { CommonString } from 'src/app/constant';
import { StringHelper } from 'src/app/helpers';
import { Console } from 'console';
import { CommonService } from '@fp/services';
import { FitBoundsAccessor } from '@agm/core';
import { CommonConstants } from '@fp/constant/common-constants';

@Component({
    selector: 'app-member-bank-account',
    templateUrl: './bank-account.component.html',
    styleUrls: ['./bank-account.component.css'],
    providers: [{ provide: MemberFormBase, useExisting: MemberBankAccountComponent }]
})
export class MemberBankAccountComponent extends MemberFormBase {

    CommonString = CommonString;
    StringHelper = StringHelper;
    isAus = true;
    isAdminEdit = false;
    get BankAccountNumberMaskCtrl() { return this.form.get('BankAccount.BankAccountNumberMask'); }

    static fb = new UntypedFormBuilder();
    static formGroup: UntypedFormGroup;

    public static getFormGroup() {
        const fb = new UntypedFormBuilder();
        /*const country = localStorage.getItem("user_country_obj");
        let accTypeValidator: any = [null];
        if (country?.toUpperCase() === CommonConstants.COUNTRY_NEW_ZEALAND) {
            accTypeValidator = [null, [Validators.required, Validators.maxLength(3), Validators.pattern('[0-9]*')]]
        }*/
        MemberBankAccountComponent.formGroup = MemberBankAccountComponent.fb.group({
            BankAccount: MemberBankAccountComponent.fb.group({
                BankAccountId: [0],
                BankAccountName: [null, Validators.required],
                BankAccountBsb: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
                BankAccountType: [null],
                BankAccountNumber: [null, [Validators.required]],
                BankAccountNumberMask: [null, [Validators.required]],
                BankName: [null, Validators.required],
                IsActive: [true],
                GoCardlessMandateId: [null],
                GoCardlessMandateStatus: [null],
                GoCardlessCustomerId: [null]
            })
        });
        return MemberBankAccountComponent.formGroup;
    }

    constructor(injector: Injector, private commonService: CommonService) { 
        super(injector); 

        if(Boolean(localStorage.getItem("adminMemberEdit"))){
            this.isAdminEdit = true;
        }
        else{
            this.isAdminEdit = false;
        }
    }

    PatchValue(value, options?) {
        if (value['BankAccount'] == null) {
            value['BankAccount'] = <BankAccount>{
                BankAccountId: 0,
                BankAccountName: null,
                BankAccountBsb: null,
                BankAccountNumber: null,
                BankAccountType: null,
                BankName: null
            };
        }
        super.PatchValue(value, options);
        this.FormatBankNumber();

        const accType = MemberBankAccountComponent.formGroup.get('BankAccount').get('BankAccountType');

        if(value?.State?.Name == 'NZ'){
            this.isAus = false;
            accType.setValidators([Validators.required, Validators.maxLength(3), Validators.pattern('[0-9]*')]);            
            this.BankAccountNumberMaskCtrl.setValidators( [Validators.required, Validators.minLength(7), Validators.maxLength(7)]);
            this.form.get('BankAccount.GoCardlessMandateId').setValue(value?.GoCardlessMandateId);
            this.form.get('BankAccount.GoCardlessMandateStatus').setValue(value?.GoCardlessMandateStatus);
            this.form.get('BankAccount.GoCardlessCustomerId').setValue(value?.GoCardlessCustomerId);
        }
        else{
            this.isAus = true;
            accType.setValidators(null);            
            this.BankAccountNumberMaskCtrl.setValidators( [Validators.required, Validators.maxLength(9)]);
        }
    }

    private FormatBankNumber() {
        let val: string = this.form.get('BankAccount.BankAccountNumber').value;
        if (val !== null && val !== undefined && val.length > 0 && val !== '') {
            let encode_val = val;
            if (val.indexOf('=') == -1) {
                encode_val = this.commonSvc.E_FP_AES256ForShortString(val);
            } else {
                val = this.commonSvc.D_FP_AES256ForShortString(val);
            }
            if (val.length > 4) {
                val = '****' + val.substr(4, val.length - 1);
            }
            this.form.get('BankAccount.BankAccountNumber').setValue(encode_val);
        } else {
            this.form.get('BankAccount.BankAccountNumber').setValue('');
        }
        this.form.get('BankAccount.BankAccountNumberMask').setValue(val);
    }

    public trimBSB() {
        var BSB = ""+this.form.get('BankAccount.BankAccountBsb').value;
        var trimBSB = BSB.replace(/\s/g, "");
        this.form.get('BankAccount.BankAccountBsb').setValue(trimBSB);
    }

    public trimAccType() {
        var accType = ""+this.form.get('BankAccount.BankAccountType').value;
        var trimAccType = accType.replace(/\t/g, "");
        this.form.get('BankAccount.BankAccountType').setValue(trimAccType);
    }

    public trimAccName() {
        var accName = ""+this.form.get('BankAccount.BankAccountName').value;
        var trimAccName = accName.replace(/\t/g, "");
        this.form.get('BankAccount.BankAccountName').setValue(trimAccName);
    }

    public txtAccNumber_Focus() {
        this.form.get('BankAccount.BankAccountNumberMask').setValue('');
    }

    public txtAccNumber_Focusout() {
        let val: string = this.form.get('BankAccount.BankAccountNumberMask').value;
        val = val.replace(/\s/g, "");
        val = val.replace(/'/g, "");
        if(val.includes("*")) {
            this.form.get('BankAccount.BankAccountNumberMask').setValue('');
            return;
        }
        if (val !== null && val !== undefined && val.length > 0 && val !== '' && val.indexOf('=') == -1) {
            var handleLength = val;
            if(handleLength.length == 1) { // Encrypting a single digit will result in error.
                handleLength = handleLength + "000";
            }
            const encode_val = this.commonSvc.E_FP_AES256ForShortString(handleLength);
            this.form.get('BankAccount.BankAccountNumber').setValue(encode_val);
            if (val.length > 4) {
                val = '****' + val.substr(4, val.length - 1);
            }
        } else {
            let ori_val: string = this.form.get('BankAccount.BankAccountNumber').value;
            if (ori_val !== null && ori_val !== undefined && ori_val.length > 0 && ori_val !== '' && ori_val.indexOf('=') > -1) {
                val = this.commonSvc.D_FP_AES256ForShortString(ori_val);
                val = '****' + val.substr(4, val.length - 1);
            }
        }
        this.form.get('BankAccount.BankAccountNumberMask').setValue(val);
    }
}
