import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { MemberFormBase } from "@fp/components/base";
import {
  CommonDialogComponent,
  DialogResult,
} from "@fp/components/common-dialog/common-dialog.component";
import { CommonMessage, CommonString } from "@fp/constant";
import { CommonDataType, ECardOrderReason, EMemberCardStatus } from "@fp/enums";
import { ERole } from "@fp/enums/role.enum";
import { StringHelper } from "@fp/helpers";
import {
  CommonResponse,
  DataResult,
  MembershipCardsModel,
  ResultModel,
  SearchPaginationRequest,
  SearchPaginationResult,
  EmployerContractUploadFile,
} from "@fp/models";
import {
  CommonDataService,
  MemberCardService,
  DataService,
} from "@fp/services";
import {
  MessageBox,
  MessageBoxButton,
} from "@fp/services/common-dialog.service";
import { RolePermissionService } from "@fp/services/role-permission.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { MembershipUploadPhotoComponent } from "../../membership-cards/membership-upload-photo/membership-upload-photo.component";

const ACTION_CARD = {
  ORDER_NEW_CARD: 1,
  CANCEL_CARD: 2,
};
@Component({
  selector: "app-member-membership-cards",
  templateUrl: "./membership-cards.component.html",
  styleUrls: ["./membership-cards.component.css"],
  providers: [MemberFormBase.provideExisting(MemberMembershipCardsComponent)],
})
export class MemberMembershipCardsComponent extends MemberFormBase {
  @Input() readonly = false;
  @Output() readonly dialogClose = new EventEmitter<boolean>();

  CommonMessage = CommonMessage;
  commonString = CommonString;
  stringHelper = StringHelper;
  displayedColumns = [
    "LastName",
    "FirstName",
    "MemberType",
    "MemberNumber",
    "MemberCardStatus",
    "MemberCardNumber",
    "PhotoLocation",
    "action",
  ];
  pagingMembershipCard = new SearchPaginationRequest();
  dataSourceMembershipCards = new MatTableDataSource<MembershipCardsModel>();
  memberShipCardsSelected: MembershipCardsModel = {};
  currentDate = new Date();
  photoSelected: EmployerContractUploadFile = new EmployerContractUploadFile();
  EMemberCardStatus = EMemberCardStatus;
  isCancelMembershipCard = false; // flad cancel card
  memberCardOrderReasonData: CommonResponse[];
  ddlReasonOrderCardControl = new UntypedFormControl(null, Validators.required);
  sharedMessage: string;
  userName = this.commonSvc.GetUser();

  isHideLostMembershipCard: boolean;
  @ViewChild(CommonDialogComponent) commonDialog: CommonDialogComponent;
  @ViewChild("dlgOrderNewCardContent") dlgOrderNewCardContent: ElementRef;
  isSubmit: boolean;
  private modalRef: NgbModalRef;

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      MembershipCards: [[]],
    });
  }

  constructor(
    injector: Injector,
    private route: ActivatedRoute,
    private permissionSvc: RolePermissionService,
    private commonDataService: CommonDataService,
    private svMemberCard: MemberCardService,
    private modalService: NgbModal,
    private shareData: DataService
  ) {
    super(injector);
  }

  private getParamsPaging() {
    const memberId = this.route.snapshot.params.id;
    const params = this.pagingMembershipCard;
    params.PageSize = 0;
    params.Params = [
      {
        Name: "all_by_member_id_only_active",
        Value: memberId,
      },
    ];
    params.OrderBy = "name";
    params.OrderByAsc = true;
    params.ViewColumns = ["member_type", "member_card_status"];
    return params;
  }

  OnInit() {
    this.shareData.currentMessage.subscribe(
      (sharedMessage) => (this.sharedMessage = sharedMessage)
    );
  }

  OnLoad() {
    const params = this.getParamsPaging();
    this.InvokeBatch(
      [
        this.commonDataService.getData(CommonDataType.MemberCardOrderReason),
        this.svMemberCard.search(params),
      ],
      {
        onSuccess: (res: DataResult<any>[]) => {
          if (res[0].Success) {
            this.memberCardOrderReasonData = res[0].Data;
            this.ddlReasonOrderCardControl.setValue(null);
          } else {
            this.HandleResponseError(res[0]);
          }
          if (
            (<DataResult<SearchPaginationResult<MembershipCardsModel>>>res[1])
              .Success
          ) {
            this.dataSourceMembershipCards = new MatTableDataSource<
              MembershipCardsModel
            >(res[1].Data.Results);
          } else {
            this.HandleResponseError(res[1]);
          }
          super.OnLoad();
        },
      }
    );
  }

  private OnCancelCard(item) {
    const params = {
      Id: item.MemberCardId,
      Action: ACTION_CARD.CANCEL_CARD,
      OrderReason: this.ddlReasonOrderCardControl.value,
      ModifiedBy: this.userName,
    };
    this.Invoke(this.svMemberCard.cardAcion(params), {
      onSuccess: (res: DataResult<ResultModel>) => {
        if (res.Success) {
          this.modalRef.close();
        } else {
          this.HandleResponseError(res);
        }
      },
    });
  }

  public OnLostStolenCard(item) {
    this.memberShipCardsSelected = item;
    MessageBox.ShowCustom(
      this.dialog,
      "Lost/Stolen Card",
      "Lost/Stolen Card",
      "This process cancels the functionality of the member's current membership card so it can no longer \
      be used on Fitness Passport scanners, but their membership will remain active. Do you wish to continue?",
      MessageBoxButton.YesNo
    ).subscribe((r) => {
      if (r.result === DialogResult.Yes) {
        this.isCancelMembershipCard = true;
        this.memberShipCardsSelected = item;
        this.openOrderNewCardModal();
      }
    });
  }

  public OnClickOrderNewCard(item) {
    this.isCancelMembershipCard = false;
    this.memberShipCardsSelected = item;
    this.openOrderNewCardModal();
  }

  isUploadPhoto: boolean;
  public OnSelectedUploadPhoto(item) {
    const isAdmin = this.commonSvc.GetSelectedRole() === ERole.admin;
    const isMember = this.commonSvc.GetSelectedRole() === ERole.member;
    let getPrivilege = this.permissionSvc.Privileges.find(
      (e) => e.PrivilegeName === "View/Edit members"
    );
    if (getPrivilege !== undefined) {
      this.isUploadPhoto = true;
    }
    this.memberShipCardsSelected = item;
    this.openOrderNewCardModal();
  }

  private openOrderNewCardModal() {
    this.modalRef = this.modalService.open(this.dlgOrderNewCardContent, {
      backdrop: "static",
    });
    this.modalRef.result.then(
      (result) => {
        this.dialogClose.emit(true);
        this.resetControl();
      },
      (reason) => {
        this.dialogClose.emit(false);
        this.resetControl();
      }
    );
  }

  submitReasonUploadPhoto() {
    this.isUploadPhoto = false;
    let isChargeFee: boolean;
    if (
      this.ddlReasonOrderCardControl.value ===
      ECardOrderReason.Member_Error_Charges_Fee
    ) {
      isChargeFee = true;
    } else if (
      this.ddlReasonOrderCardControl.value ===
      ECardOrderReason.FitnessPassport_Error_No_Charges_Fee
    ) {
      isChargeFee = false;
    }
    this.modalRef.dismiss();
    if (this.ddlReasonOrderCardControl.value) {
      this.openUploadPhotoModal(this.memberShipCardsSelected, isChargeFee);
    }
    this.ddlReasonOrderCardControl.setValue(null);
  }

  isInvalidReasonDropdown() {
    const control = this.ddlReasonOrderCardControl;
    return (
      control.invalid && (control.dirty || control.touched || this.isSubmit)
    );
  }

  resetControl() {
    this.isUploadPhoto = false;
    this.ddlReasonOrderCardControl.reset();
    this.isSubmit = false;
  }

  openUploadPhotoModal(item, isChargeFee = false) {
    this.modalRef = this.modalService.open(MembershipUploadPhotoComponent, {
      backdrop: "static",
    });
    (<MembershipUploadPhotoComponent>(
      this.modalRef.componentInstance
    )).memberShipCardsSelected = item;
    (<MembershipUploadPhotoComponent>(
      this.modalRef.componentInstance
    )).isChargeFee = isChargeFee;

    this.modalRef.result.then(
      (result: { location: string }) => {
        if (result && result.location) {
          this.memberShipCardsSelected.PhotoLocation = result.location;
          this.dialogClose.emit(true);
          this.resetControl();
        }
      },
      (reason) => {
        this.dialogClose.emit(false);
        this.resetControl();
      }
    );
  }

  public OnOrderNewCard() {
    if (this.isCancelMembershipCard === true) {
      // cancel after that order new card
      this.OnCancelCard(this.memberShipCardsSelected);
    } else {
      // just order new card
      const params = {
        Action: ACTION_CARD.ORDER_NEW_CARD,
        Id: this.memberShipCardsSelected.MemberId,
        OrderReason: this.ddlReasonOrderCardControl.value,
        ModifiedBy: this.userName,
      };
      this.Invoke(this.svMemberCard.cardAcion(params), {
        onSuccess: (res: DataResult<ResultModel>) => {
          if (res.Success) {
            this.modalRef.close();
          } else {
            this.HandleResponseError(res);
          }
        },
      });
    }
  }

  cancel() {
    this.modalRef.dismiss();
  }

  submit() {
    this.isSubmit = true;
    if (this.ddlReasonOrderCardControl.value === null) {
      return;
    }
    if (this.isUploadPhoto) {
      this.submitReasonUploadPhoto();
    } else {
      this.OnOrderNewCard();
    }
  }
}
