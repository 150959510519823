<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Add Region</h4>
<div class="mt-lg-4">
    <div class="form-group row">
        <label for="txtRgName" class="col-md-3 col-lg-2 col-form-label">Region Name<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="txtRgName" required [(ngModel)]="regionName"
                    #txtRgName="ngModel" (ngModelChange)="regionName = $event;" (change)="Dirty();"
                    [class.is-invalid]="txtRgName.invalid && (txtRgName.dirty || isValidated)" maxlength="50">
                <mat-spinner [diameter]="14" color="primary" *ngIf="isCheckingName"></mat-spinner>
                <div *ngIf="txtRgName.invalid && (txtRgName.dirty || isValidated)">
                    <small *ngIf="txtRgName.errors['required']" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Region Name')}}</small>
                    <small *ngIf="txtRgName.errors['duplicated']" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_VALUE_ALREADY_EXISTED, 'Region Name')}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label">Description</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <textarea class="form-control" id="txtrDescription" rows="3"
                    (ngModelChange)="regionDescription = $event;" [(ngModel)]="regionDescription"
                    maxlength="200"></textarea>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="slctCountry" class="col-md-3 col-lg-2 col-form-label">Country<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control"
                    [class.is-invalid]="CountryCtrl.invalid && (CountryCtrl.dirty || CountryCtrl.touched || isValidated)"
                    id="slctCountry" [formControl]="CountryCtrl" [compareWith]="slctCountryOption_CompareFn"
                    (change)="Dirty();">
                    <option *ngFor="let country of countries" [ngValue]="country">{{country.Name}}</option>
                </select>
                <div *ngIf="CountryCtrl.invalid && (CountryCtrl.dirty || CountryCtrl.touched || isValidated)">
                    <small *ngIf="CountryCtrl.errors['required']" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Country')}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="slctState" class="col-md-3 col-lg-2 col-form-label">State<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control"
                    [class.is-invalid]="StateCtrl.invalid && (StateCtrl.dirty || StateCtrl.touched || isValidated)"
                    id="slctState" [formControl]="StateCtrl" [compareWith]="slctStateOption_CompareFn"
                    (change)="Dirty();">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let state of states" [ngValue]="state">{{state.Name}}</option>
                </select>
                <div *ngIf="StateCtrl.invalid && (StateCtrl.dirty || StateCtrl.touched || isValidated)">
                    <small *ngIf="StateCtrl.errors['required']" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'State')}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row popover-error">
        <ng-template #popContent let-regionName="regionName" let-regionId="regionId">
            <div class="d-flex align-items-center">
                <i class="fa fa-exclamation-circle mr-2"></i>
                <span>This postcode has already been allocated to {{regionName}}
                    <br>
                    <a href="#" target="_blank" (click)="OpenRegion(regionId)" class="font-weight-bold">Click
                        to view</a></span>
            </div>
        </ng-template>
        <label for="txtPostcode" class="col-md-3 col-lg-2 col-form-label">Postcode<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="input-group" [ngbPopover]="popContent" placement="right" triggers="manual"
                    #popover="ngbPopover"
                    [class.is-invalid]="(isValidated && selectedPostcodes.length <= 0) || (postcodeSearchTextControl.dirty && selectedState == null)"
                    #postcodeSearchBox [ngClass]="getClassFocused(1)">
                    <input class="form-control" id="txtPostcode" name="txtPostcode" type="text"
                        placeholder="Search postcode by postcode, suburb" matInput
                        [matAutocomplete]="postcodeAutocomplete" [formControl]="postcodeSearchTextControl"
                        [defaultValue]='' maxlength="4" (blur)="onBlurParent(0)" (focus)="onFocusParent(1)">
                    <div class="input-group-append">
                        <span *ngIf="!isSearchingPostcode" class="input-group-text"><i class="fas fa-search"></i></span>
                        <mat-spinner [diameter]="14" *ngIf="isSearchingPostcode"></mat-spinner>
                    </div>
                </div>
                <div
                    *ngIf="(isValidated && selectedPostcodes.length <= 0) || (postcodeSearchTextControl.dirty && selectedState == null)">
                    <div *ngIf="isValidated && selectedPostcodes.length <= 0; else noSelectedState">
                        <small *ngIf="isValidated && selectedPostcodes.length <= 0" class="invalid-feedback d-block">
                            {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Postcode')}}</small>
                    </div>
                    <ng-template #noSelectedState>
                        <small *ngIf="postcodeSearchTextControl.dirty && selectedState == null"
                            class="invalid-feedback d-block">
                            Please select Country and State first</small>
                    </ng-template>
                </div>
                <mat-autocomplete id="autoPostcode" name="autoPostcode"
                    class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                    #postcodeAutocomplete="matAutocomplete" [displayWith]="AutoComplete_DisplayWithFn">
                    <mat-option *ngFor="let postcode of availablePostcodes" [value]="postcode"
                        class="border-default border-bottom py-1" [disabled]="true" #postcodeOption>
                        <p class="m-0 d-flex align-items-center">{{postcode.Code}}</p>
                        <span (click)="AddPostcode(postcode, popover, postcodeAutocomplete, postcodeOption);"
                            class="text-primary d-flex align-items-center btn-image">
                            <i class="fas fa-plus-circle fa-2x"></i></span>
                    </mat-option>
                </mat-autocomplete>
                <div [hidden]="selectedPostcodes == null || selectedPostcodes.length == 0"
                    class="slctRoles height1 fpscrolly border-2 rounded-10 border-default w-100 mt-2">
                    <div *ngFor="let postcode of selectedPostcodes"
                        class="d-flex border-default border-bottom py-1 justify-content-between">
                        <p class="d-flex align-items-center m-0">{{postcode.Code}}</p>
                        <span (click)="RemovePostcode(postcode)"
                            class="text-primary d-flex align-items-center btn-image">
                            <i class="fas fa-minus-circle fa-2x"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="slctSuburb" class="col-md-3 col-lg-2 col-form-label">Suburb<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div [hidden]="selectedSuburbs == null || selectedSuburbs.length == 0"
                    class="slctRoles height1 fpscrolly border-2 rounded-10 border-default w-100">
                    <div *ngFor="let suburb of selectedSuburbs"
                        class="d-flex border-default border-bottom py-1 justify-content-between">
                        <p class="d-flex align-items-center m-0">{{suburb.Name}}({{suburb.Postcode.Code}})</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="slctStatus" class="col-md-3 col-lg-2 col-form-label">Status<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control"
                    [class.is-invalid]="StatusCtrl.invalid && (StatusCtrl.dirty || StatusCtrl.touched || isValidated)"
                    [formControl]="StatusCtrl" (change)="Dirty()">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option [ngValue]="true">{{CommonString.STATUS_TEXT_ACTIVE}}</option>
                    <option [ngValue]="false">{{CommonString.STATUS_TEXT_INACTIVE}}</option>
                </select>
                <div *ngIf="StatusCtrl.invalid && (StatusCtrl.dirty || StatusCtrl.touched || isValidated)">
                    <small *ngIf="StatusCtrl.errors['required']" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Status')}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="tabFPTheme tbFPPackagePUTheme py-3" #stepperContainer>
        <div>
            <ngb-accordion activeIds="pnlARegionDetails">
                <ngb-panel id="pnlARegionDetails">
                    <ng-template ngbPanelTitle>
                        <i class="icon-fp fas fa-check"></i>
                        Additional Region Details
                        <i class="fas fas fa-chevron-down"></i>
                        <i class="fas fas fa-chevron-up"></i>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="form-group row">
                            <label for="ddlOpenLargeEmployers" class="col-md-3 col-lg-2 col-form-label">Open for Large
                                Employers</label>
                            <div class="col-md-8 col-lg-5">
                                <div class="d-flex flex-column w-100">
                                    <select [formControl]="OpenLargeEmployersCtrl" class="form-control"
                                        id="ddlOpenLargeEmployers">
                                        <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}
                                        </option>
                                        <option *ngFor="let additionalOption of additionalOptions"
                                            [ngValue]="additionalOption.Id">{{additionalOption.Name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="ddlOpenSmallAdditions" class="col-md-3 col-lg-2 col-form-label">Open for Small
                                Additions</label>
                            <div class="col-md-8 col-lg-5">
                                <div class="d-flex flex-column w-100">
                                    <select [formControl]="OpenSmallAdditionsCtrl" class="form-control"
                                        id="ddlOpenSmallAdditions">
                                        <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}
                                        </option>
                                        <option *ngFor="let additionalOption of additionalOptions"
                                            [ngValue]="additionalOption.Id">{{additionalOption.Name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="ddlUnder50Employees" class="col-md-3 col-lg-2 col-form-label">Under 50
                                Employees?</label>
                            <div class="col-md-8 col-lg-5">
                                <div class="d-flex flex-column w-100">
                                    <select [formControl]="Under50EmployeesCtrl" class="form-control"
                                        id="ddlUnder50Employees">
                                        <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}
                                        </option>
                                        <option *ngFor="let additionalOption of additionalOptions"
                                            [ngValue]="additionalOption.Id">{{additionalOption.Name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-lg-2 col-form-label">ETA on Opening</label>
                            <div class="col-md-8 col-lg-5">
                                <div class="d-flex flex-column w-100">
                                    <div class="input-group">
                                        <input [formControl]="regionETAOpening" class="form-control"
                                            placeholder="DD-MM-YYYY" name="dpRETAOpening" ngbDatepicker
                                            #dpRegionETAOpening="ngbDatepicker" [minDate]="presentDate">
                                        <div class="input-group-append">
                                            <button class="btn bg-white" (click)="dpRegionETAOpening.toggle()"
                                                type="button"><i class="far fa-calendar-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="txtWaitListedEmployer" class="col-md-3 col-lg-2 col-form-label">Wait Listed
                                Employers</label>
                            <div class="col-md-8 col-lg-7">
                                <div class="d-flex flex-column w-100">
                                    <textarea [(ngModel)]="regionWaitListedEmployer" class="form-control"
                                        id="txtWaitListedEmployer" rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="txtPDBDNotes" class="col-md-3 col-lg-2 col-form-label">PD/BD Notes</label>
                            <div class="col-md-8 col-lg-7">
                                <div class="d-flex flex-column w-100">
                                    <textarea [formControl]="txtPDBDNotesControl" class="form-control" id="txtPDBDNotes"
                                        rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-3 offset-md-3 offset-lg-2">
                                <div class="d-flex flex-column w-100">
                                    <button (click)="BtnClickAddNote()"
                                        class="px-2 py-2 btn btn-primary text-transform-none" type="button"
                                        [disabled]="txtPDBDNotesControl.value == '' || txtPDBDNotesControl.value == null">
                                        <i class="fas fa-plus-circle mr-2"></i>Add Note</button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-9 col-lg-10 offset-md-3 offset-lg-2">
                                <div class="d-flex flex-column w-100">
                                    <div class="border-default rounded-10 border-2">
                                        <div class="tablecontainer">
                                            <table mat-table [dataSource]="dataSourceNotes" class="rounded-10">
                                                <!-- DateTime Column -->
                                                <ng-container matColumnDef="dateTime">
                                                    <th mat-header-cell *matHeaderCellDef>Date/Time</th>
                                                    <td mat-cell *matCellDef="let element">
                                                        {{element.DateTimeDisplay}}
                                                    </td>
                                                </ng-container>

                                                <!-- Note Column -->
                                                <ng-container matColumnDef="note">
                                                    <th mat-header-cell *matHeaderCellDef style="width: 40%;">Note</th>
                                                    <td mat-cell *matCellDef="let element" class="wordbreak"
                                                        style="width: 40%;">
                                                        {{element.Note}} </td>
                                                </ng-container>

                                                <!-- User Column -->
                                                <ng-container matColumnDef="user">
                                                    <th mat-header-cell *matHeaderCellDef>User</th>
                                                    <td mat-cell *matCellDef="let element"> {{element.Username}}
                                                    </td>
                                                </ng-container>

                                                <tr mat-header-row *matHeaderRowDef="displayedColumns1;"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-12">
            <button class="px-5 py-2 btn btn-primary" type="button" (click)="btnCreateRegion_Click();"
                [disabled]="isCheckingName">
                <i class="fas fa-check"></i> SUBMIT</button>
        </div>
    </div>
</div>