export class GetExcludeRowDetails {
  FileName?: string;
  FileId?: number;
  FromRow?: number;
  ToRow?: number;
  Employer?: string;
  EmployerId?: number;
  Row?: number;
  MemberNumber?: string;
  MemberId?: number;
  InternalExclusion?: boolean;
  PaymentProcessingDate?: string;
  Country?: string;
}
