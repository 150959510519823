import { MemberStatus, MemberType, Suburb, PrimaryMember, Image } from "./index";
import { Employer } from "./employermodel";

export class MemberVerification {
    MemberId: number;
    FirstName: string;
    LastName: string;
    Employer: Employer;
    EmployeePayrollNo: string;
    MemberStatus: MemberStatus;
    MemberType: MemberType;
    MemberNumber: string;
    Suburb: Suburb;
    Photo: Image;
    PrimaryMember: PrimaryMember;
    WorkplaceSuburb: string;
}

export class ApproveOrRejectMembers {
    MemberIds: Array<number>;
    ModifiedBy: string;
}