<div class="d-md-none title-wrapper text-center mobile-title-shadow">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>Select Package</h1>
      </div>
    </div>
  </div>
</div>
<div class="select-package-wrapper  accordion-custom form-wrapper-new">
  <form [formGroup]="SelectPackageFormGroup">
    <fieldset [disabled]="isFormDisabled">
      <div class="container">
        <div class="row ">
          <div class="col-12 mobile-margin"></div>
          <div *ngFor="let memPackage of dataSource" [ngClass]="isEvenNumberOfPackages ? 'col-md-6' : 'col-md-4'"
            (click)="SelectMembershipPackage(memPackage)">
            <div class="selected-package-detailsSection " [ngClass]="isFormDisabled ? 'disabledPackage' : ''">
              <div class="card package-details-wrapper pkg-{{memPackage.MembershipPackageType.Name | lowercase}}" [class.opened]="memPackage.IsSelected">
                <div class="togglebtn-package">
                  <div class="">{{memPackage.MembershipPackageType.Name}}</div>
                  <div class="package-price-wrapper">
                    {{Utils.convertPriceFormat(memPackage.SinglePrice)}} / week
                  </div>
                </div>
                <div class="card-body position-relative d-md-flex flex-column justify-content-md-between">
                  <div class="card-body-info">
                    <hr class="d-md-none card-title-border" />
                    <h2 class="card-title text-center d-none d-md-block">{{memPackage.MembershipPackageType.Name}}</h2>
                    <div class="package-ptice-info d-none d-md-block text-md-center">
                      <div class="package-price-wrapper">
                        <strong class="package-price">{{Utils.convertPriceFormat(memPackage.SinglePrice)}} </strong>/
                        week
                      </div>
                      <div>
                        <div class="package-members">Single Member</div>
                        <div class="package-members">(Only for you)</div>
                      </div>
                    </div>
                    <div class="package-members-price">
                      <div class="package-family-price">
                        <div class=" d-flex justify-content-between">
                          <div class="pck-mbr-info">
                            Family
                          </div>
                          <div class="pkg-mbr-pre_price">
                            <strong class="pkg-mbr-price">{{Utils.convertPriceFormat(memPackage.FamilyPrice)}}
                            </strong>/
                            week
                          </div>
                        </div>
                        <div class="pck-mbr-info small">
                          (You, your partner and children under 18)
                        </div>
                      </div>
                      <div class="package-family-price">
                        <div class="d-flex justify-content-between">
                          <div class="pck-mbr-info">
                            Dependent
                          </div>
                          <div class="pkg-mbr-pre_price">
                            <strong class="pkg-mbr-price">{{Utils.convertPriceFormat(memPackage.DependantPrice)}}
                            </strong>/
                            week
                          </div>
                        </div>
                        <div class="pck-mbr-info small">
                          (18-24 year olds)
                        </div>
                      </div>
                    </div>
                    <div class="package-info">
                      <div class="pkg-info-item">
                        <i class="fas fa-check"></i>
                        Access to {{memPackage.TotalFacilityCount}} Facilities
                      </div>
                      <div class="pkg-info-item">
                        {{memPackage.Description}} &nbsp;
                      </div>
                    </div>

                  </div>

                  <div class="package-cta">
                    <button (click)="GoToPage(memPackage.MembershipPackageId);" class="btn btn-outline-new btn-pack-cta"
                      type="button"> View
                      Facilities</button>
                  </div>

                </div>
              </div>
            </div>                              
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="facility-wrapper">
              <div class="title-border mbr-registration-title">
                <h2>Additional Information</h2>                
              </div>
              <div class="facility-content">
                <div class="form-group row">
                  <label for="inputLastName" class="col-sm-4 col-form-label">Favoured Facility/Gym*</label>
                  <div class="col-sm-8">
                    <div class="d-flex flex-column w-100">
                      <input class="form-control" id="txtFavouredFacility" type="text"
                        placeholder="Search facility by name" matInput [matAutocomplete]="ddlFavFacAutoComplete"
                        formControlName="FavouredFacility" (blur)="txtFavouredFacility_Blur($event)"
                        (input)="txtFavouredFacility_Input($event)" (focus)="onFocusParent(1)" [ngClass]="
                        SelectPackageFormGroup.get('FavouredFacility')
                          .invalid &&
                        (SelectPackageFormGroup.get('FavouredFacility').dirty ||
                        SelectPackageFormGroup.get('FavouredFacility')
                            .touched )
                          ? 'form-control is-invalid'
                          : 'form-control'
                      ">
                      <mat-spinner [diameter]="14" *ngIf="favFacilityIsLoading"></mat-spinner>
                      <div *ngIf="SelectPackageFormGroup.get('FavouredFacility').getError('unresolved')">
                        <small class="invalid-feedback d-block">The
                          facility does not exist</small>
                      </div>
                      <div
                        *ngIf="((SelectPackageFormGroup.get('FavouredFacility').getError('required') || SelectPackageFormGroup.get('FavouredFacility').getError('required')) 
                    && (SelectPackageFormGroup.get('FavouredFacility')
                    .invalid && (SelectPackageFormGroup.get('FavouredFacility').dirty || SelectPackageFormGroup.get('FavouredFacility').touched)))">
                        <small class="invalid-feedback d-block">
                          Please start typing the name of your preferred facility and select from the drop down list
                          presented</small>
                      </div>
                      <mat-autocomplete autoActiveFirstOption #ddlFavFacAutoComplete="matAutocomplete"
                        [displayWith]="displayAutoText" (optionSelected)="SelectedFavFacility($event.option.value)"
                        class="height1 fpscrolly border-2 rounded-10 border-default w-100">
                        <mat-option *ngFor="let facility of favFacilitySearch" [value]="facility"
                          class="border-default border-bottom py-1">
                          <p class="m-0 d-flex align-items-center">{{facility.Name}}</p>
                        </mat-option>
                      </mat-autocomplete>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-form-label" [ngClass]="{'text-muted': isOtherDacilityDisable}">(While you
                  can use all the facilities on your FP program, we want to know which one you are most
                  likely
                  to attend on a regular basis)</label>
              </div>
              <div class="facility-content">
                <div class="form-group row">
                  <label for="inputLastName" class="col-sm-4 col-form-label">Have you been an active
                    member of a facility in
                    the last two months?</label>
                  <div class="col-sm-8">
                    <div class="d-flex flex-column w-100">
                      <mat-radio-group aria-label="Select an option" class="d-flex w-md-100 mt-3 mt-md-0 ">
                        <mat-radio-button value="1" (change)="setIsExistingMember(true)"
                          [checked]="isMemberHasExistingFacility">Yes</mat-radio-button>
                        <mat-radio-button value="2" (change)="setIsExistingMember(false)"
                          [checked]="!isMemberHasExistingFacility">No</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputLastName" class="col-sm-4 col-form-label"
                    [ngClass]="{'text-muted': isOtherDacilityDisable}">If yes, which facility?*</label>
                  <div class="col-sm-8">
                    <div class="d-flex flex-column w-100">
                      <div class="input-group input-group-new" [ngClass]="{'disabled': isOtherDacilityDisable}"
                        [class.is-invalid]="SelectPackageFormGroup.get('ExistingFacility').getError('unresolved') || (SelectPackageFormGroup.get('ExistingFacility')
                    .invalid && SelectPackageFormGroup.get('ExistingFacility').dirty && SelectPackageFormGroup.get('ExistingFacility').touched)">
                        <input class="form-control" id="txtExistingFacility" type="text"
                          placeholder="Search facility by name" matInput [matAutocomplete]="ddlMembrYAutoComplete"
                          formControlName="ExistingFacility" (blur)="txtExistingFacility_Blur($event)"
                          (input)="txtExistingFacility_Input($event)" (focus)="onFocusParent(2)">
                        <div class="input-group-append">
                          <span class="input-group-text" type="submit">
                            <i class="fas fa-search"></i>
                            <mat-spinner [diameter]="14" class="d-none"></mat-spinner>
                          </span>
                        </div>
                      </div>
                      <div *ngIf="SelectPackageFormGroup.get('ExistingFacility').getError('unresolved')">
                        <small class="invalid-feedback d-block">The
                          facility does not exist</small>
                      </div>
                      <mat-autocomplete autoActiveFirstOption #ddlMembrYAutoComplete="matAutocomplete"
                        [displayWith]="displayAutoText" (optionSelected)="SelectedExistingFacility($event.option.value)"
                        class="height1 fpscrolly border-2 rounded-10 border-default w-100">
                        <mat-option *ngFor="let facility of existFacilitySearch" [value]="facility"
                          class="border-default border-bottom py-1">
                          <p class="m-0 d-flex align-items-center">{{facility.Name}}</p>
                        </mat-option>
                      </mat-autocomplete>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputLastName" class="col-sm-4 col-form-label"
                    [ngClass]="{'text-muted': isOtherDacilityDisable}">Please provide your
                    existing facility if it
                    wasn’t listed*</label>
                  <div class="col-sm-8">
                    <div class="d-flex flex-column w-100">
                      <div>
                        <input type="text" class="form-control" formControlName="ExistingFacilityOther"
                          [class.is-invalid]="(SelectPackageFormGroup.get('ExistingFacility').invalid && (SelectPackageFormGroup.get('ExistingFacilityOther').dirty || SelectPackageFormGroup.get('ExistingFacilityOther').touched))">
                        <div
                          *ngIf="(SelectPackageFormGroup.get('ExistingFacility').invalid && (SelectPackageFormGroup.get('ExistingFacilityOther').dirty || SelectPackageFormGroup.get('ExistingFacilityOther').touched)) && (SelectPackageFormGroup.get('ExistingFacility').getError('unresolved') || (SelectPackageFormGroup.get('ExistingFacility')
                          .invalid && SelectPackageFormGroup.get('ExistingFacility').dirty && SelectPackageFormGroup.get('ExistingFacility').touched))">
                          <small class="invalid-feedback d-block">Please start typing the name of your preferred
                            facility and select from the drop down list presented</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-0">
                  <label for="inputLastName" class="col-sm-4 col-form-label">Would you be interested in Personal
                    Training sessions at your favourite facility?*</label>
                  <div class="col-sm-8">
                    <div class="d-flex flex-column w-100">
                      <mat-radio-group aria-label="Select an option" class="d-flex w-md-100 mt-3 mt-md-0 "
                        formControlName="FavouriteFacilityPersonalTrainingStatus">
                        <mat-radio-button value="0">Yes</mat-radio-button>
                        <mat-radio-button value="1">No</mat-radio-button>
                        <mat-radio-button value="2">Not applicable</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-form-label" [ngClass]="{'text-muted': isOtherDacilityDisable}">(If you
                  answer yes, we will pass your details onto your favourite facility so they can contact you
                  directly. PT will be an additional charge, paid by you directly to the facility. And remember,
                  although you have nominated a preferred facility, you can still use ANY or ALL of the facilities
                  available to you with your Fitness Passport membership!)</label>

              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="facility-wrapper">
              <div class="facility-title ">
                <h2>Member Details</h2>
              </div>
              <div class="member-details-content">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col"><span class="d-none d-md-block">First Name</span><span
                          class="d-md-none">Name</span>
                      </th>
                      <th scope="col" class="d-none d-md-table-cell">Last Name</th>
                      <th scope="col" class="family-member-type-width ">Member Type</th>
                      <th scope="col" class="family-member-close-btn-width"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let member of memberDataSource">
                      <td>{{member.FirstName}} <span class="d-md-none">{{member.LastName}}</span></td>
                      <td class="d-none d-md-table-cell">{{member.LastName}} </td>
                      <td>{{member.MemberIdPrimary == null ? 'Primary' :
                        member.MemberType?.Name}} </td>
                      <td><img src="assets/images/btn-close.png" *ngIf="member.MemberIdPrimary"
                          (click)="DeleteFamilyMember(member)" /></td>
                    </tr>
                  </tbody>
                </table>
                <button class="btn btn-new btn-primary mt-3" (click)="AddMemberPopup()" type="button">
                  <i class="fas fa-plus mr-2"></i>
                  Add Family Member</button>
              </div>
            </div>
          </div>
        </div>
        <div class="fixed-mobile with-back-btn mt-5">

          <div class="row">
            <div class="col-md-12">
              <div class="d-flex">
                <button
                  class="btn btn-outline-primary btn-outline-new d-flex align-items-center justify-content-center mr-4"
                  type="button" matStepperPrevious>
                  <i class="icon-previous"></i> Back
                </button>
                <button class="btn btn-primary btn-new d-flex align-items-center justify-content-center" type="button"
                  #nxtButtonRef (click)="SubmitPackageDetails()"
                  [disabled]="!(SelectPackageFormGroup.valid && CanNaviagateToNext)">
                  Next<i class="icon-next"></i>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </fieldset>
  </form>
</div>