// tslint:disable member-ordering
export class APIConstant {
  //#region Address
  public static API_GET_ACTIVE_CITIES = "address/getactivecities";
  public static API_GET_ACTIVE_UNIQUE_CITY_NAMES = "address/getactiveuniquecitynames";
  //#endregion Address

  //#region Common
  public static API_DESCRIBE_STEP_FUNCTION = "revenue/stepfunctions/describe";
  public static API_GET_COMMON_DATA = "general/getdata?type=";
  public static API_GET_COUNTRIES = "region/getcountries";
  public static API_GET_ADDITIONAL_OPTION = "general/getdata?type=52";
  public static API_GET_STATES = "region/getstates";
  public static API_GET_STATES_BY_COUNTRYID = "region/getstatesbycountry?id=";
  public static API_SEARCH_POSTCODE = "region/searchpostcode";
  public static API_ADVANCED_SEARCH_SUBURB = "suburb/advancedsearch";
  public static API_GET_LATEST_TERMS_AND_CONDITIONS =
    "member2/getlastesttermcondition?id=";
  public static API_GET_ALL_TERMS_AND_CONDITIONS =
    "member2/getalltermconditions";
  public static API_ACCEPT_TERMS_AND_CONDITIONS = "member2/termcondition";
  public static API_GET_SERVER_UTC_DATE = "general/getserverutccurrentdate";
  public static API_GET_CONFIGURATION = "general/getconfiguration?id=";
  public static API_SEARCH_EMPLOYER_REGION_BY_NAME =
    "region/searchemployerregion?name=";
  public static API_GET_MEMBER_NOTE_TYPE = "general/getdata?type=49";
  public static API_GET_MEMBER_NOTES = "member/getmembernotev2";
  public static API_GET_MEMBER_NOTES_FOR_MEMBER =
    "member/getmembernotev2formember";
  public static API_SAVE_MEMBER_NOTE = "member/savemembernote";
  public static API_ADD_MATERIAL = "general/addmaterial";
  public static API_GET_MATERIAL = (
    materialrecipientid: number,
    materialtypeid: number
  ) =>
    `general/getmaterial?materialrecipientid=${materialrecipientid}&materialtypeid=${materialtypeid}`;
  public static API_DEL_MATERIAL = "general/deletematerial";
  public static API_GET_TOKEN_SITE_SCANNER = "general/getsitetokenscanner";

  public static API_GET_MEMBER_NOTIFICATION = "member/getmembernotification";
  //#endregion Common

  public static API_GET_REGIONS_NAME = "region/getregionsname";
  public static API_GET_LIST_INDUSTRY_SECTOR = "general/getindustrysectors";
  public static API_GET_EMPLOYER_IN_ALL_REGION =
    "employer/getemployerinallregionv2";
  public static API_GET_EMPLOYER_IN_REGION =
    "employer/getemployersbyregion?id=";
  public static API_GET_EMPLOYER_PAGING_IN_REGION =
    "employer/getemployerpagingv2";
  public static API_GET_EMPLOYERS_BY_POSTCODEID =
    "employer/getemployersbypostcode?id=";
  public static API_GET_POSTCODE_AND_STATE_BY_SUBURBNAME =
    "suburb/getpostcodeandstatebysuburbname?name=";
  public static API_GET_EMPLOYER_PAGE = "employer/getemployerpage?id=";
  public static API_GET_EMPLOYER_DETAIL = "employer/getemployerdetailbyid?id=";
  public static API_GET_PAYMENT_PERIOD_NAME = "employer/getpaymentperiodnames";
  public static API_GET_EMPLOYER_TYPE = "general/getemployertypes";
  //FP-518
  public static API_GET_EMPLOYER_CATEGORY = "general/getemployercategories";
  public static API_GET_STATE_NAME = "general/getstatenames";
  public static API_GET_COMPOSITE_EMPLOYER =
    "employer/findcompositeemployers?name=";
  public static API_GET_COMPOSITE_REGION = "employer/findcompositeregion?name=";
  public static API_GET_SUBURB_NAME_BY_NAME = "suburb/FindSuburbsName?name=";
  public static API_GET_SUBURB_NAME_BY_NAME_DISTINCT =
    "suburb/findsuburbsnamedistinct?name=";
  public static API_GET_EMPLOYER_NAME_BY_NAME =
    "employer/getlistemployernamesbyname?name=";
  public static API_GET_EMPLOYER_GROUP_BY_NAME =
    "employer/findemployergroupsname?name=";
  public static API_GET_ACCOUNT_MANAGER_BY_NAME =
    "employer/getlistaccountmanagerbynamev2";
  public static API_GET_EMPLOYER_STATUS_TYPE = "employer/getemployerstatustype";
  public static API_GET_EXACTLY_EMP_NAME =
    "employer/findexactlyemployername?name=";
  public static API_GET_EXACTLY_EMP_CODE =
    "employer/findexactlyemployercode?name=";
  public static API_GET_EMPLOYER_BY_EMP_CODE_FOR_SURVEY  =
    "employer/getemployerdetailsbyempcodeforsurvey?name=";
  public static API_GET_LIST_COUNTRY = "employer/getlistcountry";
  public static API_SEARCH_EMPLOYERS = "employer/searchv2";
  public static API_GET_EMPLOYER_FOR_MEMBER_SIGNUP =
    "employer/getbyidformembersignup?id=";
  public static API_GET_ELIGIBLE_EMPLOYEES = "general/getdata?type=48";

  public static API_GET_EMPLOYER_BRANCHS =
    "employer/getemployerbranchsbyid?id=";
  public static API_POST_EMPLOYER_BRANCHS = "employer/insertemployerbranch";
  public static API_UPDATE_MEMBERSHIP_PACKAGE =
    "employer/updatemembershippackge";
  public static API_UPDATE_EMPLOYER_ACCESS =
    "membershippackage/updateemployeraccesstofacility?id=";

  // #Additional Attachments
  public static API_GET_EMPLOYER_IMAGES = "employer/getemployerimagesv2";
  public static API_GET_EMPLOYER_DOCUMENTS = "employer/getemployerdocumentsv2";
  public static API_UPLOAD_EMPLOYER_FILE = "employer/uploademployerfile";
  public static API_SAVE_EMPLOYER_DOC_ATTACHMENT =
    "employer/updateemployerdocumentsfile";
  public static API_SAVE_EMPLOYER_IMAGE_ATTACHMENT =
    "employer/updateemployerimagesfile";

  // #Employer membership package
  public static API_SEARCH_MEMBERSHIP_PACKAGES = "membershippackage/search";
  public static API_SEARCH_MEMBERSHIP_PACKAGES_BY_MEMBERSHIP =
    "membershippackage/searchbymembership";
  public static API_SAVE_MEMBERSHIP_PACKAGE = "membershippackage/save";
  public static API_GET_MEMBERSHIP_PACKAGE_TYPES =
    "membershippackage/getmembershippackagetypes";
  public static API_CHECK_ACTIVE_MEMBERS =
    "membershippackage/checkactivemembers?id=";
  public static API_GET_MEMBERSHIP_PACKAGE_TYPE_BY_MEMBER =
    "membershippackage/getmembershippackagetypebymemberv2";
  public static API_GENERATE_MEMBERSHIP_PACKAGE_REPORT =
    "membershippackage/generatereport";

  //#region Survey
  public static API_GET_ACTIVE_SURVEY_BY_TYPE  =
    "survey/getactivesurveytemplatebytype?id=";
  public static API_GET_FACILITY_DATA_FOR_MEMBER_SIGNUP_SURVEY = (
      country: string,
      pageNumber: number
  ) => `survey/getpremembersignupsurveyfacilitydetails?id=${encodeURIComponent(country)}&page=${pageNumber}`;
  public static API_SAVE_SURVEY_RESPONSE = "survey/addsurveyresponsedetail";

  //#region User
  public static API_CONFIRM_USER_ACCOUNT = "user/confirmaccount";
  public static API_CREATE_USER = "user/create";
  public static API_UPDATE_USER = "user/update";
  public static API_UPDATE_FISTLOGIN_USER = "user/firstlogin/updatev2";
  public static API_SIGN_IN = "user/signinforfpuser";
  public static API_GET_USERS = "user/getallusers";
  public static API_GET_ROLES = "general/getroles?includememberrole=";
  public static API_GET_USERS_BY_NAME = "user/findusersbyname?name=";
  public static API_GET_USERS_BY_FISRTNAME_LASTNAME =
    "user/getusersbyname?name=";
  public static API_GET_USERS_BY_EMPLOYER_ID = "user/findusersbyemployer?id=";
  public static API_GET_USERS_BY_ROLE_ID = "user/getuserbyrole?id=";
  public static API_DELETE_USERS = "user/changestatususer?id=";
  public static API_GET_USER_GROUPS = "user/getlistusergroups";
  public static API_GET_USER_TO_UPDATE = "user/getuserdetail?id=";
  public static API_GET_USER_TO_FISTLOGIN_UPDATE =
    "user/firstlogin/getuserdetail?name=";
  public static API_GET_USERS_PAGINATION = "user/searchuserpaging";
  public static API_GET_ALL_USERS_SQL_PAGINATION = "user/getalluserpaging";
  public static API_GET_ALL_USERS_SQL_PAGINATION_PARAM =
    "user/getalluserpagingparam";
  public static API_GET_ALL_USERS_BY_ROLE_SQL_PAGINATION =
    "user/getalluserbyroleid";
  public static API_GET_USERS_BY_NAME_EMAIL = "user/getusersbynameemail?name=";
  public static API_GET_USERS_BY_NAME_EMAIL_PAGING =
    "user/getusersbynameemailpaging";
  public static API_GET_USER_BY_USERNAME = "user/getuserdetail?name=";
  public static API_GET_USER = "user/getuserdetail?id=";
  public static API_GET_USER_BY_USERNAME_FIRSTLOGIN_V2 =
    "user/firstlogin/getuserdetailv2";

  public static API_GET_LOCATION_DATA = "user/firstlogin/getloginlocationdata";
  public static API_GET_UPLOAD_URL = "general/getuploadurl2?name=";
  public static API_GET_DOWNLOAD_URL = "general/getdownloadurl?name=";

  public static API_GET_TITLES = "general/getlisttitlenames";
  public static API_GET_GENDERS = "general/getlistgendernames";

  public static API_FIND_FACILITIES_BY_NAME = "facility/findbyname?name=";
  public static FIND_SERVICES_BY_USERNAME =
    "facility/findservicesbyusername?modifiedby=";
  public static API_FIND_EMPLOYERS_BY_NAME = "employer/findbyname?name=";

  public static API_GENERATE_USERNAME = (firstname, lastname) =>
    `user/generateusername?firstname=${firstname}&lastname=${lastname}`;
  public static API_CHECK_IS_PENDING = "user/checkispending";
  public static API_CHANGE_TEMPORARY_PASSWORD = "user/changetemporarypassword";
  public static API_CHECK_EXIST_PHONE_V2 = "user/checkexist/phoneandemailv2";
  public static API_CHECK_EXIST_EMAIL_V2 = "user/checkexist/phoneandemailv2";
  public static API_CHECK_EXIST_USER_BY_EMAIL_FEDERATION = "user/checkexistuserbyemailforfederation";

  //#endregion User

  public static API_UPDATE_EMPLOYER_DETAIL = "employer/updateemployerdetail";

  public static API_CREATE_EMPLOYER_PAGE = "employer/createemployerpage";
  public static API_CREATE_EMPLOYER_DETAIL = "employer/insertemployerdetail";
  public static API_GET_EMPLOYER_PAGE_BY_URL =
    "employer/GetEmployerPageByURL?id=";

  public static API_CHECK_DUPLICATE_LINKNAME =
    "employer/getemployerbylinkname?name=";

  //#region Region
  public static API_GET_REGION = "region/getregion?id=";
  public static API_GET_REGION_BY_NAME = "region/getregionbyname?name=";
  public static API_CREATE_REGION = "region/create";
  public static API_UPDATE_REGION = "region/update";
  public static API_SEARCH_REGIONS = "region/search";
  public static API_REASSIGN_POSTCODE_TO_REGION = "region/reassignpostcode";
  //#endregion Region

  //#region Facility
  public static API_CREATE_FACILITY = "facility/create";
  public static API_UPDATE_FACILITY = "facility/update";
  public static API_GET_FACILITY = "facility/get?id=";
  public static API_GET_FACILITY_BY_NAME = "facility/getbyname?name=";
  public static API_GET_FACILITY_TYPES = "facility/gettypes";
  public static API_GET_FACILITY_CATEGORIES = "facility/getcategories";
  public static API_GET_FACILITY_STATUSES = "facility/getstatuses";
  public static API_GET_FACILITY_CONTACT_TYPES = "facility/getcontacttypes";
  public static API_SEARCH_FACILITIES = "general/searchfacilitypagingv2";
  public static API_SEARCH_FSERVICES_FOR_FACILITIES = "general/searchservicespagingv2";
  public static API_GET_FACILITIES_BY_REGIONID =
    "facility/getfacilitiesbyregion?id=";
  public static API_GET_FACILITIES_BY_POSTCODEID =
    "facility/getfacilitiesbypostcode?id=";
  public static API_GET_PHOTOS_BY_FACILITYID = "facility/getphotosv2";
  public static API_GET_DOCUMENTS_BY_FACILITYID =
    "facility/getfacilitydocumentsv2";
  public static API_GET_FACILITY_NOTE_TYPE = "general/getdata?type=49";
  public static API_SAVE_FACILITY_NOTE = "facility/savefacilitynote";
  public static API_GET_ACTIVITIES = "activity/getactivities";

  public static API_GET_FACILITY_NOTES = (
    facilityid: number,
    isexternalonly: boolean
  ) =>
    `facility/getfacilitynote?facilityid=${facilityid}&isexternalonly=${isexternalonly}`;
  public static API_GET_FACILITY_PAYMENTDETAILS = "facility/getpaymentdetailv2";
  public static API_GET_REPORTPAYMENTCHECK = "facility/reportpaymentcheckv2";
  public static API_GET_FACILITY_PAYMENT_LOGS =
    "facility/getfacilitypaymentlogv2";
  //#endregion Facility

  //#region Product/Service
  public static API_GET_SERVICE_TYPES_PAGING = "service/getservicetypespaging";
  public static API_GET_CATEGORIES = "service/getcategories";
  public static API_GET_PSTYPE_BY_NAME = "service/getbyname?name=";
  public static API_GET_PS_BY_ID = "service/getbyid?id=";
  public static API_CREATE_PS = "service/create";
  public static API_UPDATE_PS = "service/update";
  //#endregion Product/Service

  //#region Payment
  public static API_GET_PAYMENT_DATES = "general/getpaymentdates";
  public static API_GET_PAYMENT_FREQUENCIES = "payment/getfrequencies";
  public static API_GET_PAYMENT_METHODS = "payment/getmethods";
  public static API_GET_PAYMENT_PREFERENCES = "general/getpaymentpreferences";
  public static API_CHECK_RAW_VISITS = "payment/checkrawvisits";
  public static API_GET_PRESIGNED_URL = "payment/getpresignedurls";
  public static API_SAVE_RAW_VISITS = "payment/saverawvisits";
  public static API_APPROVE_PAYMENT_REQUEST = "payment/approvemanualrequest";
  public static API_REJECT_PAYMENT_REQUEST = "payment/rejectmanualrequest";
  public static API_HOLD_PAYMENT_REQUEST = "payment/holdmanualrequest";
  public static API_GET_RAW_VISIT_COUNT = "payment/grabfromrawvisittable";
  public static API_GET_COST_CALCULATION = "payment/calculateprcosts";
  public static API_GET_PAYMENT_REQUEST_DATA = "payment/getpaymentrequestdata";
  public static API_GET_PAYMENT_REQUEST_DATA_FACILITY_STAFF =
    "payment/getpaymentrequestforfacilitystaffmember";
  public static API_GET_TASK_LIST = "hello/gettasklist";
  public static API_GET_PAYMENT_REQUEST_BY_ID =
    "payment/getpaymentrequestbyid?id=";
  public static API_GET_3_MONTH_PAYMENT_REQEUST_DATA =
    "payment/retrieve3monthpaymentrequesthistory";
  public static API_CALCULATION_METHOD_ANTI_FRAUD =
    "payment/getcalculationmethodantifraud";
  public static API_SUBMIT_PAYMENT_REQUEST = "payment/createpaymentrequest";
  public static API_SUBMIT_EDIT_PAYMENT_REQUEST = "payment/editpaymentrequest";
  public static API_GET_CHECK_USERNAME_LOA = "payment/checkusernameloa";
  public static API_POSTED_PRS = "payment/postedpaymentrequests";
  public static API_REGENERATED_PRS = "payment/regenratedpaymentrequests";
  public static API_GET_PAYMENT_REQUEST_NOTES =
    "payment/getpaymentrequestnotes";
  //#endregion Payment

  //#region Revenue
  public static API_GET_DEBIT_PROCESSING_FILTER_DATA =
    "revenue/getdebitprocessingfilterdata";
  public static API_SUBMIT_PERIOD_CLOSED = "revenue/closedebitprocessingdate";
  public static API_SUBMIT_PERIOD_OPENED = "revenue/opendebitprocessingdate";
  public static API_RETRIEVE_ABA_FILES = "revenue/retrieveabafiles";
  public static API_CREATE_DEBIT_PROCESSING_ENTRY =
    "revenue/createdebitprocessingentry";
  public static API_DOWNLOAD_ABA_FILES = "revenue/downloadabafroms3";
  public static API_BULK_UPLOAD = "revenue/bulkuploadtomembershipledger";
  public static API_GET_EXCLUDED_ROW_DETAILS = "revenue/getexcludedrowdetails";
  public static API_REGENERATE_ABA_FILES = "revenue/regenerateabafiles";
  public static API_FINALISE_ABA = "revenue/finaliseaba";
  public static API_UPLOAD_FAILED_TRANSACTIONS =
    "revenue/uploadfailedtransactions";
  public static API_CONFIRM_UPLOAD_FAILED_TRANSACTIONS =
    "revenue/confirmuploadfailedtransactions";
  public static API_CHECK_FEEBOT_COMPLETION =
    "revenue/checkfeebotcompletion";

  //#endregion Revenue

  //#region Bank
  public static API_BULK_UPLOAD_BANK_CODE = "bank/bulkuploadbankdetail";
  public static API_BULK_UPLOAD_BSB = "bank/bulkuploadtobsb";
  public static API_AUDIT_HISTORY = "auditlogging/getauditdetailpaging";
  //#endregion Bank

  //#region Postcode
  public static API_BULK_UPLOAD_POSTCODE = "postcodes/bulkuploadpostcodedetail";
  //#endregion Postcode

  //#edit Terms
  public static API_EDIT_TERMS_CONDITIONS = "member2/updatetermconditions";

  //#region Roles
  public static API_GET_ALL_PRIVILEGES_V2 = "userrole/getallprivilegesv2";
  public static API_GET_ALL_PATH_PRIVILEGES_V2 =
    "userrole/getallpathprivilegesv2";
  public static API_GET_UPDATE_PRIVILEGE = "userrole/updatelistprivilegedetail";
  public static API_SEARCH_USER_ROLES = "userrole/searchv2";
  //#endregion Roles

  //#region Member Signup
  public static API_SEND_PIN_BY_EMAIL = "pincode/sendpinbyemailv2";
  public static API_RESEND_PIN_BY_EMAIL = "pincode/resendpincode";
  public static API_DELETE_PIN_BY_EMAIL = "pincode/deletepincode?name=";
  public static API_CHECK_PIN_CODE = "pincode/checkpincodev2";
  public static API_CREATE_MEMBER = "membersignup/creatememberv2";
  public static API_CREATE_MEMBER_WITH_COGNITO_TOKEN = "membersignup/creatememberwithcognitotoken";
  public static API_CREATE_SURVEY_MEMBER = "membersignup/createsurveymember";
  public static API_CHECK_PHONE_EMAIL = "membersignup/getmemberphoneemailv2";
  //#endregion Member Signup

  //#region Member
  public static API_GET_MEMBER = "member2/getv2";
  public static API_GET_MEMBER_FOR_MEMBER = "member2/getv2formember";
  public static API_GET_MEMBER_BY_USER_ID = "member2/getbyuserv2";
  public static API_GET_MEMBER_BY_USER_ID_FOR_MEMBER =
    "member2/getbyuserv2formember";
  public static API_GET_MEMBER_BY_USER_ID_FOR_MEMBER_SIGNUP =
    "member2/getbyuserv2forsignupformember";
  public static API_SEARCH_MEMBERS_V2 = "member2/searchv2";
  public static API_SEARCH_MEMBERS_V2_FOR_MEMBER = "member2/searchv2formember";
  public static API_SEARCH_FACILITY_MEMBERS_PAGING =
    "member/searchfacilitymemberpaging";
  public static API_SEARCH_MEMBERS_FOR_MANUALLY_CHECKIN_PAGING =
    "member/searchmembersformanaullycheckin";
  public static API_SEARCH_MEMBERS_FOR_MANUALLY_CHECKIN_SUBMIT =
    "member/manualvisitsubmit";
  public static API_GET_SEARCH_MEMBERS_PAGING = "member/getmemberpaging";
  public static API_GET_SEARCH_MEMBERS_PAGING_FOR_EF =
    "fsm/getmemberpagingforef";
  public static API_GET_SEARCH_MEMBERS_PAGING_FOR_FSM =
    "fsm/getmemberpagingforfsm";
  public static API_UPDATE_MEMBER = "member2/update";
  public static API_UPDATE_MEMBER_FOR_MEMBER = "member2/updateformember";
  public static API_UPDATE_PAYMENT_DETAILS_MEMBER = "member/updatebankaccount";
  public static API_RESET_MEMBERSHIPS_BCF = "membership/resetbcf";
  public static API_UPDATE_MEMBER_SIGNUP_INFO = "membersignup/updatesignupinfo";
  public static API_SAVE_MEMBER_EMPLOYER_INFO = "membersignup/saveemployerdetails";
  public static API_RESET_SIGNUP_DATA = "membersignup/resetsignupdata";
  public static API_GET_MEMBER_TYPES = "member2/gettypes";
  public static API_GET_MEMBER_MEDICAL_QUESTIONS =
    "member2/getmedicalquestions";
  public static API_REMOVE_PHOTO_FOR_MEMBER = "member2/RemoveMemberPhotoForMember";
  public static API_CHANGE_PHOTO = "member2/changephoto";
  public static API_CHANGE_PHOTO_FOR_MEMBER = "member2/changephotoformember";
  public static API_GET_MEMBERCANCELLATION = "member/getmembercancellation?id=";
  public static API_SAVE_MEMBERCANCELLATION = "member/savemembercancellation";
  public static API_SAVE_MEMBERCANCELLATION_ACTION1 = "member/MemberCancellationScheduleUpdate";
  public static API_DISPLAY_DATA_MEMBERCANCELLATION_FORM = "member/MemberScheduledCancellationDetails?id=";
  public static API_GET_TYPE_FEE_CANCEL = "member2/gettypefeecancellation?id=";
  public static API_CALCULATE_CANCEL_FEE = "member2/calculatecancelfee";
  public static API_UPDATE_MEMBERSHIP_STARTDATE =
    "member2/updatecontractstartdate";
  public static API_CHECK_ACTIVE_MEMBER =
    "member/isactivememberinemployerid?id=";
  public static API_SEND_EMAIL_MEMBER_INFO_REQUIRED =
    "member/sendmailinformationrequired";
  public static API_GET_MEMBER_VISITS = "member/getmembervisitsbyid?id=";
  public static API_GET_MEMBER_TAX_INVOICE_DATES =
    "member/getmembertaxinvoicedatesbyid";
  public static API_GET_MEMBER_TAX_INVOICE_INFO =
    "member/getmembertaxinvoiceinformationbyid";

  public static API_GET_MEMBERS = "memberverification/memberverificationpaging";
  public static API_SET_INVALID_PHOTO = "memberverification/invalidphoto";
  public static API_APPROVE_MEMBERS = "memberverification/approve";
  public static API_REJECT_MEMBERS = "memberverification/reject";
  public static API_CHECK_REGISTRATION_BY_USERNAME =
    "member2/checkregistrationv2";
  public static API_CHECK_REGISTRATION_BY_USERNAME_WITHOUT_TOKEN =
    "general/checkregistrationwithouttoken?name=";
  public static API_TRANSFER_MEMBER_TO_NEW_EMPLOYER = "member/transferemployer";
  public static API_UPDATE_MEMBERSHIP_LEDGER =
    "membership/updatemembershipledger";
  public static API_READ_MEMBERSHIP_LEDGER = "membership/readmembershipledger";
  public static API_GENERATE_EF_FSM_REPORT = "general/querydata";
  public static API_GENERATE_REPORT = "report/getreport";
  public static API_GENERATE_MEMBER_MASTER_REPORT = "report/membermasterexport";
  public static API_SAVE_MEMBER_PACKAGE_DETAIL = "membersignup/savemembershippackagedetails";
  public static API_SAVE_MEMBER_SIGNUP_PERSONAL_DETAILS = "membersignup/savepersonalinformation";
  public static API_SAVE_MEMBER_PAYMENT_DETAILS = "membersignup/savepaymentinformation";
  public static API_SAVE_MEMBER_TERMS_AND_CONDITIONS = "membersignup/savetermsandconditions";
  public static API_REACTIVATE_MEMBER = "member2/reactivatemember";
  public static API_CHECK_MEMBER_UNIQUE_EMAIL_MOBILE = "member2/checkmembersuniqueemailmobileno";
  public static API_GENERATE_MEMBER_MASTER_REPORT_WITHOUT_SENSITIVE_DATA = "report/membermasterexportwithoutsensitivedata";
  //#endregion Member

  // #region Membership card
  public static API_SEARCH_MEMBERSHIP_CARDS = "membercard/search";
  public static API_SEARCH_MEMBERSHIP_CARDS_FOR_MEMBERS = "membercard/searchformember";
  public static API_MEMBERSHIP_CARDS_ACTION = "membercard/cardaction";
  public static API_MEMBERSHIP_CARDS_ACTION_FOR_MEMBER =
    "membercard/cardactionformember";
  public static API_UPDATE_ADDRESS_FAMILY_MEMBER =
    "member2/updateaddressfamilymember";
  public static API_UPDATE_ADDRESS_FAMILY_MEMBER_FOR_MEMBER =
    "member2/updateaddressfamilymemberformember";

  // #end Membership card

  //#region Membership photo
  public static API_GET_TOTAL_UPLOAD_PHOTO_MEMBER =
    "memberphoto/gettotaltimeuploadbymember?id=";
  //#endregion Membership photo

  // #region Membership
  public static API_GET_MEMBERSHIP_SUSPENSION =
    "membership/getactivemembershipsuspensionbymember?id=";
  public static API_SAVE_MEMBERSHIP_SUSPENSION =
    "membership/savemembershipsuspension";
  public static API_SAVE_MEMBERSHIP_SUSPENSION_FOR_MEMBER =
    "membership/savemembershipsuspensionformember";
  public static API_GET_NUMBEROF_MEMBERSHIP_CHANGE_BY_MEMBER =
    "membership/getnumofdaymembershipischangedbymember?id=";
  public static API_CANCEL_SUSPENSION = "membership/cancelsuspension";
  public static API_CANCEL_SUSPENSION_FOR_MEMBER =
    "membership/cancelsuspensionformember";
  public static API_GET_DEBIT_CREDIT_REASONS =
    "membership/getdebitcreditreasons";
  // #end Membership

  //#region visits history
  public static API_CREATE_VISITS = "service/createvisithistory";
  public static API_GET_VISITS_BY_FACILITY = "service/getvisithistories";
  //#endregion visits history

  //#region Services
  public static API_GET_SERVICE_DETAILS = "service/getdetails?id=";
  public static API_GET_SERVICE_TYPES = "service/gettypes";
  public static API_GET_SERVICE_STATUSES = "service/getstatuses";
  public static API_GET_PAYMENT_CALCULATION_METHODS =
    "service/getpaymentcalculationmethods";
  public static API_GET_VISIT_RECORDING_METHODS =
    "service/getvisitrecordingmethods";
  public static API_GET_SERVICES_BY_FACILITY =
    "service/getservicesbyfacilityid?id=";
  public static API_SEARCH_SERVICES = "service/search";
  public static API_SEARCH_PAYMENT_REQUEST = "payment/searchpaymentrequest";
  public static API_APPROVE_PAYMENT_INVOICE_REQUEST =
    "payment/approvepaymentrequest";
  //#endregion Services

  //#region Products & Services
  public static API_SEARCH_PRODUCTS_AND_SERVICES = "productservice/search";
  public static API_GET_PRODUCT_AND_SERVICE_TYPES = "productservice/gettypes";
  //#endregion Products & Services

  //#region Products
  public static API_SEARCH_PRODUCTS = "product/search";
  public static API_GET_PRODUCT_TYPES = "product/getproducttypes";
  public static API_GET_PRODUCT_DETAILS = "product/detail?id=";
  //#endregion Products

  //#region Package
  public static API_SAVE_PACKAGE = "package/save";
  public static API_GET_PACKAGE_DETAILS_BY_ID = "package/getdetails?id=";
  public static API_GET_PACKAGE_DETAILS_BY_NAME = "package/getdetails?name=";
  public static API_SEARCH_PACKAGES = "package/search";
  public static API_SEARCH_PACKAGES_NAME = "package/searchbyname";

  //#endregion Package

  //#region forget password
  public static API_CHECK_EXISTING_USER_BY_USERNAME = "user/checkexistuser";

  public static API_REQUEST_PINCODE_FROM_USERNAME =
    "pincode/sendpinforgotpassword";
  public static API_REQUEST_NEW_PASSWORD = "user/resetpassword";
  //#endregion forget password

  //#region Employer Page Google Map
  public static API_GET_MEMBERSHIP_PKG_BY_EMPID =
    "employer/getmembershippkgbyempid?id=";
  public static API_GET_FACILITIES_MEM_PACKAGE_ID =
    "facility/getbymembershippackage";
  public static API_GET_FACILITY_MAPS = "suburb/getfacilitymaps";

  //#endregion

  //#region CSV Validator
  public static API_PRIMARY_KEY_CHECK = "general/checkkeyexisting";
  public static API_PRIMARY_PK_FK_RELATION = "general/checkrelationpkfk";
  public static API_UPDATE_CSV_FILE = "membershippackage/updatebycsvfile?name=";
  //#endregion

  //#region auditing constants
  public static API_GET_AUDIT_LOGS = "auditlogging/getauditlog";
  public static API_GET_EMPLOYER_AUDIT_LOGS =
    "auditlogging/getemployerauditlog";
  public static API_GET_MEMBER_AUDIT_LOGS = "auditlogging/getmemberauditlog";
  public static API_GET_FACILITY_AUDIT_LOG = "auditlogging/getfacilityauditlog";
  public static API_GET_REGION_AUDIT_LOG = "auditlogging/getregionauditlog";
  public static API_GET_PACKAGE_AUDIT_LOG = "auditlogging/getpackageauditlog";
  // #end auditing constants

  //#region bot logs constants
  public static API_GET_BOT_LOGS = "hello/botschedule";
  //#end bot logs constants

  //Login
  public static API_LOG_IN = "login/webworker";
  //End

   //Azure AD Federation
   public static COGNITO_FEDERATION_TOKEN = "oauth2/token";
   //End

  // # system parameters constants
  public static API_GET_SYSTEM_PARAMETERS = "payment/getsystemparameters";
  public static API_POST_SYSTEM_PARAMETERS = "payment/updatesystemparameters";
  // # end system parameters constants

  public static API_REFRESH_TOKEN_INTERVAL = 5 * 60000; // 5 minute
  public static API_DEBOUNCE_TIME = 600; // 600 milisecond debounce time
  public static IDLE_TIME = 900; // 15 min Idle
  public static TIMEOUT_CONFIRM_STAY_LOGIN = 300; // 5 min timeout
  public static IDLE_PING_INTERVAL = 30; // 30s interval ping
  public static UTC_SYDNEY = 10;
  public static DATE_HOUR_FORMAT = "dd-MM-yyyy HH:mm";
  public static DATE_HOUR_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";
  public static DATE_FORMAT = "yyyy-MM-dd";

  public static MOUSE_NOT_MOVING_TIMEOUT = 5000; // 1s
  public static SITE_TOKEN_DEFAULT = "00000000-0000-0000-0000000000000000";

  //#region Parent Group
  public static API_GET_PARENT_GROUPS = "parentgroup/getparentgrouplist";
  public static API_GET_PARENT_GROUPS_PAGING = "parentgroup/getparentgrouplistbypaging";
  public static API_GET_PARENT_GROUP_BY_ID = "parentgroup/getparentgroupbyid?id=";
  public static API_GET_PARENT_GROUP_BY_NAME = "parentgroup/getparentgroupbyname?name=";
  public static API_CREATE_PARENT_GROUP = "parentgroup/createparentgroup";
  public static API_EDIT_PARENT_GROUP = "parentgroup/updateparentgroup";
  public static API_SEARCH_PARENT_GROUP = "parentgroup/searchparentgroup";
  public static API_DELETE_PARENT_GROUP = "parentgroup/deleteparentgroup?id="
  //#endregion Products & Services

  public static API_GET_REPORT_ACCESS = "report/isprivilegedtoreport"

}
