<div #DivMemberNote>
    <div *ngIf="CanAccess()">
        <form [formGroup]="MemberNoteFormGroup">
            <div class="form-group row" *ngIf="IsExternalUser()===false">
                <label for="slcNoteType" class="col-md-3 col-lg-2 col-form-label">Note Type</label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <select class="form-control" id="slcNoteType" formControlName="slcNoteType"
                            (change)="slcNoteType_Select($event)">
                            <option value="" selected>Please select a value</option>
                            <option *ngFor="let item of NoteTypes" [value]="item.Id"
                                [disabled]="SetNoteTypeDisabled(item)">
                                {{item.Name}}</option>
                        </select>
                        <div class="mt-2 slctSelectedRoles w-100 border-2 rounded-10 border-default text-default"
                            *ngIf="NoteTypesSelected.length>0">
                            <div class="d-flex border-default border-bottom py-1 align-content-center"
                                *ngFor="let nt of NoteTypesSelected">
                                <span class="text-primary d-flex align-items-center"
                                    (click)="UnsetNoteTypeSelected(nt)">
                                    <i class="fas fa-minus-circle fa-2x"></i>
                                </span>
                                <p class="d-flex align-items-center m-0 ml-2">{{nt.Name}}</p>
                            </div>
                        </div>
                        <div *ngIf="NoteTypesSelected.length===0 && btnAddNoteClick===true && buttonText==='Add Note'">
                            <small class="invalid-feedback d-block">Note Type is required</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row" *ngIf="IsExternalUser()===false && IsContainExternal()===true">
                <label for="chkSendEmail" class="col-md-3 col-lg-2 col-form-label">Send Email</label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="chkSendEmail"
                                (change)="chkSendEmail_Change($event)" formControlName="chkSendEmail">
                            <label for="chkSendEmail" class="custom-control-label">&nbsp;</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row" *ngIf="IsExternalUser()===false">
                <label for="txtMemberNotes" class="col-md-3 col-lg-2 col-form-label">Member Notes</label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <textarea class="form-control" #txtMemberNotes id="txtMemberNotes" maxlength="2000" rows="3"
                            formControlName="txtMemberNotes"></textarea>
                        <div
                            *ngIf="MemberNoteFormGroup.get('txtMemberNotes').value.trim()==='' && btnAddNoteClick===true">
                            <small class="invalid-feedback d-block">Member Notes is required</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row" *ngIf="IsExternalUser()===false">
                <div class="col-md-3 offset-md-3 offset-lg-2">
                    <div class="d-flex flex-column w-100">
                        <button class="px-2 py-2 btn btn-primary text-transform-none" type="button"
                            (click)="btnAddMemberNote_Click($event)">
                            <i class="fas fa-plus-circle mr-2"></i> {{buttonText}}</button>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col">
                    <div class="d-flex flex-column w-100">
                        <div class="border-default rounded-10 border-2">
                            <div class="tablecontainer">
                                <table mat-table [dataSource]="dtmatnotes" class="rounded-10">

                                    <!-- User Column -->
                                    <ng-container matColumnDef="Username">
                                        <th mat-header-cell *matHeaderCellDef>User</th>
                                        <td mat-cell *matCellDef="let element"> {{element.Username}} </td>
                                    </ng-container>

                                    <!-- DateTime Column -->
                                    <ng-container matColumnDef="DateTimeDisplay">
                                        <th mat-header-cell *matHeaderCellDef>Date/Time</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.DateTimeDisplay}} </td>
                                    </ng-container>

                                    <!-- Type Column -->
                                    <ng-container matColumnDef="Type">
                                        <th mat-header-cell *matHeaderCellDef [hidden]="IsExternalUser()">Type</th>
                                        <td mat-cell *matCellDef="let element" [hidden]="IsExternalUser()">
                                            {{element.Type}} </td>
                                    </ng-container>

                                    <!-- Send Email Column -->
                                    <ng-container matColumnDef="IsSentEmail">
                                        <th mat-header-cell *matHeaderCellDef [hidden]="IsExternalUser()">Send Email
                                        </th>
                                        <td mat-cell *matCellDef="let element" [hidden]="IsExternalUser()">
                                            {{element.IsSentEmail===true?'Yes':'No'}} </td>
                                    </ng-container>

                                    <!-- Note Column -->
                                    <ng-container matColumnDef="Note">
                                        <th mat-header-cell *matHeaderCellDef>Note</th>
                                        <td mat-cell *matCellDef="let element"> {{element.Note}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="Action">
                                        <th mat-header-cell *matHeaderCellDef [hidden]="selectedrole!=='Admin'"
                                            style="width: 100px;"></th>
                                        <td mat-cell *matCellDef="let element" [hidden]="selectedrole!=='Admin'"
                                            style="width: 100px;">
                                            <a class="px-2" (click)="UpdateMemberNote(element)"
                                                *ngIf="element.NoteTypeId===2 && (element.Username !== 'system' && element.Username !== 'system.admin')">
                                                <i class="fas fa-pen"></i>
                                            </a>
                                            <a class="px-2" (click)="DeleteMemberNote(element)"
                                                *ngIf="element.NoteTypeId===2 && (element.Username !== 'system' && element.Username !== 'system.admin')">
                                                <i class="fas fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns;">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <mat-paginator #notepagi [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons>
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </form>

    </div>
    <div class="alert alert-warning" role="alert" *ngIf="CanAccess()===false">
        Your assigned role does not have privilege to access this feature
    </div>
</div>