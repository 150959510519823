import { Directive, ElementRef, forwardRef, HostListener, Inject, Optional, Renderer2 } from '@angular/core';
import { COMPOSITION_BUFFER_MODE, DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const NUMBER_ONLY_INPUT_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NumberOnlyDirective),
    multi: true
};

@Directive({
    selector: 'input[numberOnly]',
    host: {
        '(input)': '$any(this)._handleInput($event.target.value)',
        '(blur)': 'onTouched()',
        '(compositionstart)': '$any(this)._compositionStart()',
        '(compositionend)': '$any(this)._compositionEnd($event.target.value)'
    },
    providers: [NUMBER_ONLY_INPUT_VALUE_ACCESSOR]
})
export class NumberOnlyDirective extends DefaultValueAccessor {
    private _element: HTMLElement;
    constructor(
        _renderer: Renderer2,
        _elementRef: ElementRef,
        @Optional() @Inject(COMPOSITION_BUFFER_MODE) _compositionMode: boolean) {
        super(_renderer, _elementRef, _compositionMode);
        this._element = _elementRef.nativeElement;
    }

    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
        const specialKeyPressed = event.ctrlKey || event.altKey || event.metaKey;
        const keyCode = event.keyCode || event.which;
        const invalidKeyPressed = (
            (keyCode >= 65 && keyCode <= 90) || // a-z
            (keyCode === 106 || keyCode === 107 || keyCode === 109 || keyCode === 111 || keyCode === 110) || // * + - / .
            (keyCode >= 186) || // other non-character keys
            (keyCode >= 48 && keyCode <= 57 && event.shiftKey) // 0->9 with Shift turned on
        );
        if (invalidKeyPressed && !specialKeyPressed) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return false;
        }
    }

    @HostListener('paste', ['$event']) onPaste(event) {
        let text = '';
        if (window['clipboardData'] && window['clipboardData'].getData) { // IE
            text = window['clipboardData'].getData('Text');
        } else if (event.clipboardData && event.clipboardData.getData) {
            text = event.clipboardData.getData('text/plain');
        }
        text = text.replace(/[^\d]/g, '');
        event.preventDefault();
        event.stopPropagation();
        const targetElement = event.target || this._element;
        this.insertValue(targetElement, text);
    }

    private insertValue(elem, value) {
        if (document['selection']) {
            // IE support
            elem.focus();
            const sel = document['selection'].createRange();
            sel.text = value;
            value = elem.value;
        } else if (elem.selectionStart || elem.selectionStart === 0) {
            // MOZILLA and others
            const startPos = elem.selectionStart;
            const endPos = elem.selectionEnd;
            value = elem.value.substring(0, startPos)
                + value
                + elem.value.substring(endPos, elem.value.length);
        } else {
            value = elem.value + value;
        }
        if (elem.maxLength) {
            value = value.substring(0, elem.maxLength);
        }
        this.writeValue(value);
        this.onChange(value);
    }
}
