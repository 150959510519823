import { State } from '.';

export class Country {
    CountryId: number;
    Name: string;
    IsActive: boolean;
    States: State[];

    constructor(ID?: number, name?: string, isActive?: boolean) {
        this.CountryId = ID;
        this.Name = name;
        this.IsActive = isActive;
    }
}