import { Component, OnInit, Inject, ElementRef, ViewChild } from "@angular/core";
import { RouterConstant } from "src/app/constant";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { PaymentService } from "@fp/services/payment.service";
import { PaymentRequestModel } from "@fp/models/payment-request.model";
import { ResultModel, NewPaymentRequestNote, DataResult } from "src/app/models";
import { DataService, CommonService } from "@fp/services";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { PaymentRequestNotesModel } from "@fp/models/edit-payment-request.model";
import { DateHelper } from "@fp/helpers";

@Component({
  selector: "app-approve-payment-request",
  templateUrl: "./approve-payment-request.component.html",
  styleUrls: ["./approve-payment-request.component.css"]
})
export class ApprovePaymentRequestComponent implements OnInit {
  public PaymentRequestNoteFormGroup = new UntypedFormGroup({
    paymentRequestNoteText: new UntypedFormControl("")
  });
  public dtmatnotes: MatTableDataSource<
    NewPaymentRequestNote
  > = new MatTableDataSource<NewPaymentRequestNote>();
  private noteList: NewPaymentRequestNote[] = [];

  public displayedColumnsNotesTable: string[] = [
    "Username",
    "DateTimeDisplay",
    "Note"
  ];
  public btnAddNoteClick = true;

  RouterConstant = RouterConstant;
  dataprint: any;

  recordsCurrent: PaymentRequestModel = {
    Username: "",
    Amount: 0,
    PID: 0
  };

  displayedColumns: string[];
  recordsOld1: PaymentRequestModel;
  recordsOld2: PaymentRequestModel;
  recordsOld3: PaymentRequestModel;
  paymentRequestDetails: any;
  loaErrorMessage: boolean;
  approveRejectPaymentRequestMessage: string;
  dataSource: any;
  paymentCalculationMethod: string;
  antiFCheck: string;
  messageLOA: string;
  isLoading = false;

  @ViewChild('afInput') afInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ApprovePaymentRequestComponent>,
    private PaymentSvc: PaymentService,
    private shareData: DataService,
    private cmsrv: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.retieve3monthdata();
    this.recordsCurrent.PID = this.data.paymentRequestId;
    this.recordsCurrent.Username = this.cmsrv.GetUser();
    this.recordsCurrent.Amount = this.data.dollarAmount;
    this.shareData.changeMessage(this.messageLOA);
    this.Invoke(
      this.PaymentSvc.getPaymentRequestNotes("" + this.recordsCurrent.PID),
      (result: DataResult<PaymentRequestNotesModel[]>) => {
        if (result.Data.length > 0) {
          for (let i = 0; i < result.Data.length; i++) {

            const tempPrNote = new NewPaymentRequestNote();
            tempPrNote.DateTime = this.ConvertFormat(result.Data[i].CreateOrUpdateDate);
            tempPrNote.Note = result.Data[i].Note;
            tempPrNote.User = "" + result.Data[i].Username;
            this.noteList.unshift(tempPrNote);
          }
          this.dtmatnotes = new MatTableDataSource<NewPaymentRequestNote>(
            this.noteList
          );
        }
      }
    );
  }

  //Change format
  private ConvertFormat(d: Date) {
    const localdate: Date = new Date(d);
    return DateHelper.format(localdate, 'DD-MMM-YYYY HH:mm'); //Without seconds required
  }

  //Change incoming time from B.E (UTC) to machine's local time
  private ConvertToLocalTime(d: Date) {
    const localdate: Date = new Date(d + 'Z');
    return DateHelper.format(localdate, 'DD-MMM-YYYY HH:mm'); //Without seconds required here
  }

  public retieve3monthdata() {
    this.antiFCheck = this.data.dataCalcMethod.Data[0]["AntiFraudCheck"];
    this.paymentCalculationMethod = this.data.dataCalcMethod.Data[0][
      "PaymentCalculationMethod"
    ];
    this.displayedColumns = [
      "FromDate",
      "ToDate",
      "VisitCountHistory",
      "PaymentHistory",
      "Status"
    ];
    this.dataSource = this.data.data3Month;
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError(e => {
          throw e;
        })
      )
      .subscribe(
        res => {
          handleResultCallback(res);
        },
        err => {
          this.errorHandler(err);
        }
      );
  }

  private errorHandler(error, message: string = null) {
    console.log(error, message);
  }

  approveButton() {
    this.paymentRequestApprove(this.recordsCurrent);
    this.showApproveHoldRejectError(this.messageLOA);
  }

  rejectButton() {
    this.paymentRequestReject(this.recordsCurrent);
    this.showApproveHoldRejectError(this.messageLOA);
  }

  holdButton() {
    this.paymentRequestHold(this.recordsCurrent);
    this.showApproveHoldRejectError(this.messageLOA);
  }

  paymentRequestApprove(paymentRequestDetails: PaymentRequestModel) {
    this.isLoading = true;
    this.Invoke(
      this.PaymentSvc.paymentRequestApprove(paymentRequestDetails),
      (data: ResultModel) => {
        this.messageLOA = data.Message;
        this.dialogRef.close();
      }
    );
  }
  paymentRequestReject(paymentRequestDetails: PaymentRequestModel) {
    this.isLoading = true;
    this.Invoke(
      this.PaymentSvc.paymentRequestReject(paymentRequestDetails),
      (data: ResultModel) => {
        this.messageLOA = data.Message;
        this.dialogRef.close();
      }
    );
  }
  paymentRequestHold(paymentRequestDetails: PaymentRequestModel) {
    this.isLoading = true;
    this.Invoke(
      this.PaymentSvc.paymentRequestHold(paymentRequestDetails),
      (data: ResultModel) => {
        this.messageLOA = data.Message;
        this.dialogRef.close();
      }
    );
  }

  showApproveHoldRejectError(message: string) {
    this.loaErrorMessage = true;
  }

  getAFWidth() {
    //Always return a width when antifraud check value is NULL for payment requests (specifically migrated PRs)
    if (this.antiFCheck !== null) {
      return { 'width': (this.antiFCheck.length * 0.55).toString() + 'em', "min-width": "16em" };
    }
    else {
      return { 'width': (1 * 0.55).toString() + 'em', "min-width": "16em" };
    }
  }
}
