<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">{{title}}</h4>
<div class="mx-auto tabProgress w-75 d-none d-lg-flex mt-3">
    <div class="flex-fill text-center">
        <div [ngClass]="HandleStepsBarBgColor(0)" (click)="ChangeTab(0);">
            <i [ngClass]="HandleTickofSteps(0)"></i>
        </div>
        <p class="card-text mb-3">Employer<br>Details</p>
    </div>
    <div class="flex-fill text-center">
        <div [ngClass]="HandleStepsBarBgColor(1)" (click)="ChangeTab(1);">
            <i [ngClass]="HandleTickofSteps(1)"></i>
        </div>
        <p class="card-text mb-3">Contracts</p>
    </div>
    <div class="flex-fill text-center">
        <div [ngClass]="HandleStepsBarBgColor(2)" (click)="ChangeTab(2);">
            <i [ngClass]="HandleTickofSteps(2)"></i>
        </div>
        <p class="card-text mb-3">Facilitators</p>
    </div>
    <div class="flex-fill text-center">
        <div [ngClass]="HandleStepsBarBgColor(3)" (click)="ChangeTab(3);">
            <i [ngClass]="HandleTickofSteps(3)"></i>
        </div>
        <p class="card-text mb-3">Branch/Locations</p>
    </div>
    <div class="flex-fill text-center">
        <div [ngClass]="HandleStepsBarBgColor(4)" (click)="ChangeTab(4);">
            <i [ngClass]="HandleTickofSteps(4)"></i>
        </div>
        <p class="card-text mb-3">Membership<br>Packages</p>
    </div>
    <div class="flex-fill text-center">
        <div [ngClass]="HandleStepsBarBgColor(5)" (click)="ChangeTab(5);">
            <i [ngClass]="HandleTickofSteps(5)"></i>
        </div>
        <p class="card-text mb-3">Employer<br>Page</p>
    </div>
    <div class="flex-fill text-center">
        <div [ngClass]="HandleStepsBarBgColor(6)" (click)="ChangeTab(6);">
            <i [ngClass]="HandleTickofSteps(6)"></i>
        </div>
        <p class="card-text mb-3">Additional<br>Attachments</p>
    </div>
    <div class="flex-fill text-center">
        <div [ngClass]="HandleStepsBarBgColor(7)" (click)="ChangeTab(7);">
            <i [ngClass]="HandleTickofSteps(7)"></i>
        </div>
        <p class="card-text mb-3">Audit<br>Log</p>
    </div>
</div>
<div class="tabFPTheme mb-5 tabAddEmployer" #tabfpstepper [style.height]="heighttabs">
    <!-- [linear]="isLinear" -->
    <mat-vertical-stepper #stepper [linear]="SetLinearForTab()" (selectionChange)="stepClick($event)">
        <mat-step [stepControl]="EmployerDtlFormGroup" #stepper1>
            <form [formGroup]="EmployerDtlFormGroup" id="employertb0">
                <ng-template matStepLabel><i class="fas fa-check icon-fp d-lg-none"></i><span
                        (click)="HeaderClick();">Employer
                        Details</span></ng-template>
                <ngb-accordion #acc="ngbAccordion"
                    activeIds="panelDetail,panelAddress,panelCode,panelAddInf,panelAddNotes">
                    <ngb-panel id="panelDetail">
                        <ng-template ngbPanelTitle>
                            <i
                                [ngClass]="ValidateEmployerDetailSection()==true || isReadonly==true?'fas fa-check icon-fp':'icon-fp'"></i>
                            Employer Details
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="form-group row">
                                <label for="txtEmployerName" class="col-md-3 col-lg-2 col-form-label">Employer Name<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" [ngClass]="SetClassForControl(EmployerDtlFormGroup,'txtEmployerName')" id="txtEmployerName"
                                            formControlName="txtEmployerName" autocomplete="off" [readonly]="isReadonly"
                                            maxlength="200" (change)="txtEmployerName_Change($event)">
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'txtEmployerName')">
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('txtEmployerName').hasError('required')"
                                                 class="invalid-feedback d-block">Employer
                                                Name is required</small>
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('txtEmployerName').hasError('duplicatename')"
                                                 class="invalid-feedback d-block">{{stringHelper.format(commonString.FIELD_VALUE_ALREADY_EXISTED,
                                                'Employer Name')}}</small>
                                        </div>
                                        <mat-spinner [diameter]="14" color="primary" *ngIf="isSpinerShowName">
                                        </mat-spinner>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtEmployerCode" class="col-md-3 col-lg-2 col-form-label">Employer Code<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="input-group"
                                            [ngClass]="SetClassForInputGroup(EmployerDtlFormGroup,'txtEmployerCode')">
                                            <!-- <input type="text" [ngClass]="SetClassForControl(EmployerDtlFormGroup,'txtEmployerCode')" id="txtEmployerCode" -->
                                            <input type="text" [restrictedPattern]="'^[A-Za-z]{0,5}$'"
                                                [ngClass]="SetClassForControl(EmployerDtlFormGroup,'txtEmployerCode')"
                                                id="txtEmployerCode" formControlName="txtEmployerCode"
                                                autocomplete="off" maxlength="10"
                                                (change)="txtEmployerCode_Change($event)"
                                                (focusout)="txtEmployerCode_Change($event)">
                                            <mat-spinner [diameter]="14" color="primary" *ngIf="isSpinerShowCode">
                                            </mat-spinner>
                                        </div>

                                        <div
                                            *ngIf="IsControlInvalid(EmployerDtlFormGroup,'txtEmployerCode') || validlong">
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('txtEmployerCode').hasError('required')"
                                                 class="invalid-feedback d-block">{{stringHelper.format(commonString.FIELD_REQUIRED,
                                                'Employer Code')}}</small>
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('txtEmployerCode').hasError('duplicatename')"
                                                 class="invalid-feedback d-block">{{stringHelper.format(commonString.FIELD_VALUE_ALREADY_EXISTED_EMPCODE,
                                                'Employer Code')}}</small>
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('txtEmployerCode').hasError('validlong')"
                                                class="invalid-feedback d-block">{{stringHelper.format(commonString.FIELD_VALUE_LONG_EMPCODE,
                                                'Employer Code')}}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtABN" class="col-md-3 col-lg-2 col-form-label">ABN/NZBN</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" id="txtABN" formControlName="txtABN" maxlength="11"
                                            class="form-control" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtAlternateNameTags" class="col-md-3 col-lg-2 col-form-label">Alternate
                                    Name Tags</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" id="txtAlternateNameTags"
                                            formControlName="txtAlternateNameTags" maxlength="500" class="form-control"
                                            autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ddlParentGroupAutoComplete" class="col-md-3 col-lg-2 col-form-label">Parent
                                    Group</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" placeholder="Search Parent Group by Employer name" matInput
                                            [formControl]="txtParentGroupControl"
                                            [matAutocomplete]="ddlParentGroupAutoComplete" name="txtParentGroup"
                                            [readonly]="isReadonly" (keyup)="txtParentGroupControl_Change($event)"
                                            [ngClass]="SetClassForFormControl(txtParentGroupControl)"
                                            (focusout)="txtParentGroupControl_Lostfocus($event)">
                                        <mat-autocomplete autoActiveFirstOption
                                            #ddlParentGroupAutoComplete="matAutocomplete"
                                            [displayWith]="displayAutoText"
                                            (optionSelected)="ddlParentGroupAutoComplete_Selected($event);"
                                            id="ddlParentGroupAutoComplete">
                                            <mat-option *ngFor="let pgroup of filteredPGroup | async" [value]="pgroup">
                                                {{pgroup.Name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <div *ngIf="(txtParentGroupControl.invalid)
                                        && (txtParentGroupControl.dirty
                                        || txtParentGroupControl.touched
                                        || btnNextClicked)">
                                            <small *ngIf="txtParentGroupControl.hasError('notexisting')"
                                                 class="invalid-feedback d-block">The
                                                Parent Group does not exist</small>
                                        </div>
                                        <mat-spinner [diameter]="14" color="primary" *ngIf="isSpinerParentGroup">
                                        </mat-spinner>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ddlIndustrySector" class="col-md-3 col-lg-2 col-form-label">Industry
                                    Sector</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select id="ddlIndustrySector" class="form-control "
                                            formControlName="ddlIndustrySector">
                                            <option value="" selected>Please select a value</option>
                                            <option *ngFor="let isector of industrysectors" [value]="isector.Id">
                                                {{isector.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ddlAccountManager" class="col-md-3 col-lg-2 col-form-label">Account
                                    Manager<span class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" placeholder="Search Account Manager by username, first name"
                                            matInput [formControl]="txtAccountManagerControl"
                                            [matAutocomplete]="ddlAccountManagerAutoComplete" name="ddlAccountManager"
                                            [readonly]="isReadonly" (keyup)="txtAccountManagerControl_Change($event)"
                                            [ngClass]="SetClassForFormControl(txtAccountManagerControl)"
                                            (focusout)="txtAccountManagerControl_Lostfocus($event)"
                                            id="ddlAccountManagerName">
                                        <mat-autocomplete autoActiveFirstOption
                                            #ddlAccountManagerAutoComplete="matAutocomplete"
                                            [displayWith]="displayAutoText"
                                            (optionSelected)="ddlAccountManagerAutoComplete_Selected($event);"
                                            id="ddlAccountManagerAutoComplete">
                                            <mat-option *ngFor="let accgroup of filteredACCGroup | async"
                                                [value]="accgroup">
                                                {{accgroup.Name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <div *ngIf="txtAccountManagerControl.invalid
                                        && (txtAccountManagerControl.dirty
                                        || txtAccountManagerControl.touched
                                        || btnNextClicked)">
                                            <small *ngIf="txtAccountManagerControl.hasError('required')"
                                                 class="invalid-feedback d-block">Account
                                                Manager is required</small>
                                            <small *ngIf="txtAccountManagerControl.hasError('notexisting')"
                                                 class="invalid-feedback d-block">The
                                                Account Manager does not exist</small>
                                        </div>
                                        <mat-spinner [diameter]="14" color="primary" *ngIf="isSpinerAcc">
                                        </mat-spinner>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ddlBDevManager" class="col-md-3 col-lg-2 col-form-label">Business
                                    Development Manager<span class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text"
                                            placeholder="Search Business Development Manager by username, first name"
                                            matInput [formControl]="txtBDevManagerControl"
                                            [matAutocomplete]="ddlBDevManagerAutoComplete" name="txtBDevManagerControl"
                                            [readonly]="isReadonly" (keyup)="txtBDevelopManagerControl_Keyup($event)"
                                            [ngClass]="SetClassForFormControl(txtBDevManagerControl)"
                                            (focusout)="txtBDevelopManagerControl_Lostfocus($event)"
                                            id="ddlBDevelopManagerName">
                                        <mat-autocomplete autoActiveFirstOption
                                            #ddlBDevManagerAutoComplete="matAutocomplete"
                                            [displayWith]="displayAutoText"
                                            (optionSelected)="ddlBDevelopManagerAutoComplete_Selected($event);"
                                            id="txtBDevManager">
                                            <mat-option *ngFor="let accgroup of filteredBDevelopManager | async"
                                                [value]="accgroup">
                                                {{accgroup.Name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <div *ngIf="txtBDevManagerControl.invalid
                                        && (txtBDevManagerControl.dirty
                                        || txtBDevManagerControl.touched
                                        || btnNextClicked)">
                                            <small *ngIf="txtBDevManagerControl.hasError('required')"
                                                 class="invalid-feedback d-block">Business
                                                Development Manager is
                                                required</small>
                                            <small *ngIf="txtBDevManagerControl.hasError('notexisting')"
                                                 class="invalid-feedback d-block">The
                                                Business Development Manager does not exist</small>
                                        </div>
                                        <mat-spinner [diameter]="14" color="primary" *ngIf="isSpinerDevelopAcc">
                                        </mat-spinner>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ddlEmployerType" class="col-md-3 col-lg-2 col-form-label">Employer Type<span
                                        class="text-danger">*</span></label>

                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select id="ddlEmployerType"
                                            [ngClass]="SetClassForControl(EmployerDtlFormGroup,'ddlEmployerType')"
                                            formControlName="ddlEmployerType">
                                            <option value="" selected>Please select a value</option>
                                            <option *ngFor="let emptype of emptypes" [value]="emptype.Id">
                                                {{emptype.Name}}
                                            </option>
                                        </select>
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'ddlEmployerType')">
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('ddlEmployerType').hasError('required')"
                                                class="invalid-feedback d-block">Employer Type is required</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <!----FP-581-->
                            <div class="form-group row">
                                <label for="ddlEmployerCategory" class="col-md-3 col-lg-2 col-form-label">Employer Category<span
                                 class="text-danger">*</span></label>
                            
                                <div class="col-md-8 col-lg-5">
                                     <div class="d-flex flex-column w-100">
                                        <select id="ddlEmployerCategory"
                                            [ngClass]="SetClassForControl(EmployerDtlFormGroup,'ddlEmployerCategory')"
                                            formControlName="ddlEmployerCategory">
                                            <option value="" selected>Please select a value</option>
                                            <option *ngFor="let emptCategories of emptCategories" [value]="emptCategories.Id">
                                            {{emptCategories.Name}}
                                            </option>
                                            </select>
                                            <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'ddlEmployerCategory')">
                                            <small
                                            *ngIf="EmployerDtlFormGroup.get('ddlEmployerCategory').hasError('required')"
                                            class="invalid-feedback d-block">Employer Category is required</small>
                                            </div>
                                        </div>
                                </div>
                            </div>                            
                         <!----FP-581-->
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="panelAddress">
                        <ng-template ngbPanelTitle>
                            Address and Contact Details
                            <i
                                [ngClass]="ValidateAddressSection()==true || isReadonly==true?'fas fa-check icon-fp':'icon-fp'"></i>
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-phone-number [mandatory]="true" [control]="EmployerDtlFormGroup.get('txtPhone')"
                                [submitted]="btnNextClicked" formControlName="txtPhone" [readonly]="isReadonly"
                                [clsLabel]="'col-md-3 col-form-label col-lg-2'" [clsGroup]="'col-md-8 col-lg-5'"
                                #mobileNumberComponent>
                            </app-phone-number>
                            <div class="form-group row">
                                <label for="txtEmail" class="col-md-3 col-lg-2 col-form-label">Email</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="email"
                                            [ngClass]="SetClassForControl(EmployerDtlFormGroup,'txtEmail')"
                                             id="txtEmail" formControlName="txtEmail" autocomplete="off" maxlength="50">
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'txtEmail')">
                                            <small *ngIf="EmployerDtlFormGroup.get('txtEmail').hasError('pattern')"
                                                 class="invalid-feedback d-block">Email
                                                is invalid</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h6 class="text-primary font-weight-bold py-3">Physical Address</h6>
                            <div class="form-group row">
                                <label for="txtStreet1" class="col-md-3 col-lg-2 col-form-label">Address Line 1<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" [ngClass]="SetClassForControl(EmployerDtlFormGroup,'txtStreet1')" id="txtStreet1"
                                            formControlName="txtStreet1" maxlength="190"
                                            (change)="txtStreet1_Change($event)" matInput
                                            [matAutocomplete]="addressAutocomplete"
                                            (ngModelChange)="searchAutoAddress()">
                                        <mat-autocomplete id="autoAddress" name="autoAddress"
                                            class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                                            #addressAutocomplete="matAutocomplete"
                                            [displayWith]="AutoCompleteAddress_DisplayWithFn"
                                            (optionSelected)="SelectAddress1($event)">
                                            <mat-option *ngFor="let address of filteredAdressList" [value]="address"
                                                class="border-default border-bottom py-1">
                                                <p class="m-0 d-flex align-items-center">{{address.Text}}</p>
                                            </mat-option>
                                        </mat-autocomplete>
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'txtStreet1')">
                                            <small *ngIf="EmployerDtlFormGroup.get('txtStreet1').hasError('required')"
                                                 class="invalid-feedback d-block">Address
                                                Line 1 is required</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtStreet2" class="col-md-3 col-lg-2 col-form-label">Address Line 2</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" id="txtStreet2" formControlName="txtStreet2" maxlength="190"
                                            class="form-control" autocomplete="off" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtCountryPhy" class="col-md-3 col-lg-2 col-form-label">Country<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select class="form-control" id="ddlCountryPhy" formControlName="ddlCountryPhy"
                                            (change)="changeCountryStatesPhy($event)">
                                            <option *ngFor="let co of countries" [value]="co.Id">
                                                {{co.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!isFromAustraliaPhy" class="form-group row">
                                <label for="txtCity" class="col-md-3 col-lg-2 col-form-label">Town/City<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="input-group" #citySearchBox
                                            [class.is-invalid]="IsControlInvalid(EmployerDtlFormGroup,'City')"
                                            [class.disabled]="CityCtrl.disabled">
                                            <input class="form-control" id="txtCity" type="text"
                                                placeholder="Search City" formControlName="City" matInput
                                                [matAutocomplete]="autoCity" (blur)="txtCity_Blur($event)"
                                                (input)="txtCity_Input($event)">
                                            <mat-spinner [diameter]="14" color="primary" *ngIf="isSearchingCity">
                                            </mat-spinner>
                                        </div>
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'City')">
                                            <small *ngIf="CityCtrl.getError('unresolved'); else notEquals"
                                                class="invalid-feedback d-block">Town/City is invalid</small>
                                            <small *ngIf="CityCtrl.getError('required')"
                                                class="invalid-feedback d-block">
                                                Town/City is required</small>
                                        </div>
                                        <mat-autocomplete id="autoCity" name="autoCity"
                                            class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                                            #autoCity="matAutocomplete" [displayWith]="AutoCompleteCity_DisplayWithFn"
                                            (optionSelected)="SelectCity($event.option.value)">
                                            <mat-option *ngFor="let city of citySearchResults" [value]="city"
                                                class="border-default border-bottom py-1">
                                                <p class="m-0 d-flex align-items-center"> {{city.Name}}</p>
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ddlSuburbPhy" class="col-md-3 col-lg-2 col-form-label">Suburb<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input class="form-control float-left" type="text"
                                            [ngClass]="SetClassForControl(EmployerDtlFormGroup,'txtSuburbPhy')" matInput
                                            autocomplete="off" formControlName="txtSuburbPhy"
                                            [matAutocomplete]="ddlSuburbPhyAutoComplete" name="txtSuburbPhy"
                                            (keyup)="txtSuburbPhy_Change($event)" [readonly]="isReadonly" (blur)="txtSuburbPhy_Blur($event)">
                                        <mat-autocomplete #ddlSuburbPhyAutoComplete="matAutocomplete"
                                            (optionSelected)="ddlSuburbPhyAutoComplete_Selected($event);"
                                            id="ddlSuburbPhyAutoComplete" [displayWith]="displayAutoSuburbText">
                                            <mat-option *ngFor="let sphy of filteredSuburbPhy | async" [value]="sphy"
                                                style="height: auto !important;">
                                                {{sphy.Name}}, {{sphy.State?.Name}}, {{sphy.Postcode?.Name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'txtSuburbPhy')">
                                            <small *ngIf="EmployerDtlFormGroup.get('txtSuburbPhy').hasError('required')"
                                                 class="invalid-feedback d-block">Suburb
                                                is required</small>
                                            <small *ngIf="EmployerDtlFormGroup.get('txtSuburbPhy').hasError('unresolved')" class="invalid-feedback d-block">
                                                {{stringHelper.format(commonString.FIELD_INVALID, 'Suburb')}}</small>
                                        </div>
                                        <mat-spinner [diameter]="14" color="primary" *ngIf="isSpinerPhysub">
                                        </mat-spinner>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ddlPostCodePhy" class="col-md-3 col-lg-2 col-form-label">Postcode<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select id="ddlPostCodePhy"
                                            [ngClass]="SetClassForControl(EmployerDtlFormGroup,'ddlPostCodePhy')"
                                            formControlName="ddlPostCodePhy" (change)="ddlPostCodePhy_Change($event)">
                                            <option value="" *ngIf="postcodesphy.length===0">Please select a value
                                            </option>
                                            <option *ngFor="let pcode of postcodesphy" [value]="pcode.Id">
                                                {{pcode.Name}}
                                            </option>
                                        </select>
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'ddlPostCodePhy')">
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('ddlPostCodePhy').hasError('required')"
                                                 class="invalid-feedback d-block">Postcode
                                                is required</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ddlStatePhy" class="col-md-3 col-lg-2 col-form-label">State<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select class="form-control" id="ddlStatePhy"
                                            [ngClass]="SetClassForControl(EmployerDtlFormGroup,'ddlStatePhy')"
                                            formControlName="ddlStatePhy">
                                            <option value="">Please select a value</option>
                                            <option *ngFor="let state of statesPhy" [value]="state.Id">
                                                {{state.Name}}
                                            </option>
                                        </select>
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'ddlStatePhy')">
                                            <small *ngIf="EmployerDtlFormGroup.get('ddlStatePhy').hasError('required')"
                                                class="invalid-feedback d-block">State
                                                is required</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtRegionPhy" class="col-md-3 col-lg-2 col-form-label">Region</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" class="form-control" id="txtRegionPhy"
                                            formControlName="txtRegionPhy" readonly="readonly">
                                    </div>
                                </div>
                            </div>
                            <h6 class="text-primary font-weight-bold py-3">Mailing Address</h6>
                            <div class="form-group row">
                                <label for="chkSameAsAbove" class="col-md-3 col-lg-2 col-form-label">Same as
                                    above</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="chkSameAsAbove"
                                                (change)="chkSameAsAbove_Change($event)"
                                                formControlName="chkSameAsAbove">
                                            <label for="chkSameAsAbove" class="custom-control-label">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtMAAddsLine1" class="col-md-3 col-lg-2 col-form-label">Address Line 1<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" [ngClass]="SetClassForControl(EmployerDtlFormGroup,'txtMAAddsLine1')" id="txtMAAddsLine1"
                                            formControlName="txtMAAddsLine1" maxlength="190" autocomplete="off"
                                            (change)="txtMAAddsLine1_Change($event)" matInput
                                            [matAutocomplete]="addressMailAutocomplete"
                                            (ngModelChange)="searchAutoAddressMail()">
                                        <mat-autocomplete id="autoAddress" name="autoAddress"
                                            class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                                            #addressMailAutocomplete="matAutocomplete"
                                            [displayWith]="AutoCompleteAddressMail_DisplayWithFn"
                                            (optionSelected)="SelectAddress1Mail($event)">
                                            <mat-option *ngFor="let address of filteredAdressMailList" [value]="address"
                                                class="border-default border-bottom py-1">
                                                <p class="m-0 d-flex align-items-center">{{address.Text}}</p>
                                            </mat-option>
                                        </mat-autocomplete>
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'txtMAAddsLine1')">
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('txtMAAddsLine1').hasError('required')"
                                                 class="invalid-feedback d-block">Address
                                                Line 1 is required</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtMAAddsLine2" class="col-md-3 col-lg-2 col-form-label">Address Line
                                    2</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" class="form-control" id="txtMAAddsLine2"
                                            formControlName="txtMAAddsLine2" autocomplete="off" maxlength="190">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtMACountry" class="col-md-3 col-lg-2 col-form-label">Country<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select class="form-control" id="ddlMACountry" formControlName="ddlMACountry"
                                            (change)="changeCountryStatesMail($event)">
                                            <option *ngFor="let co of countries" [value]="co.Id">
                                                {{co.Name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!isFromAustraliaMA" class="form-group row">
                                <label for="txtCity" class="col-md-3 col-lg-2 col-form-label">Town/City<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="input-group" #citySearchBoxMail
                                            [class.is-invalid]="IsControlInvalid(EmployerDtlFormGroup,'CityMail')"
                                            [class.disabled]="CityMailCtrl.disabled">
                                            <input class="form-control" id="txtMailCity" type="text"
                                                placeholder="Search City" formControlName="CityMail" matInput
                                                [matAutocomplete]="autoCityMail" (blur)="txtCityMail_Blur($event)"
                                                (input)="txtCityMail_Input($event)">
                                            <mat-spinner [diameter]="14" color="primary" *ngIf="isSearchingCityMail">
                                            </mat-spinner>
                                        </div>

                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'CityMail')">
                                            <small *ngIf="CityMailCtrl.getError('unresolved'); else notEquals"
                                                class="invalid-feedback d-block">Town/City is invalid</small>
                                            <small *ngIf="CityMailCtrl.getError('required')"
                                                class="invalid-feedback d-block">
                                                Town/City is required</small>
                                        </div>
                                        <mat-autocomplete id="autoCityMail" name="autoCityMail"
                                            class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                                            #autoCityMail="matAutocomplete"
                                            [displayWith]="AutoCompleteCityMail_DisplayWithFn"
                                            (optionSelected)="SelectCityMail($event.option.value)">
                                            <mat-option *ngFor="let city of cityMailSearchResults" [value]="city"
                                                class="border-default border-bottom py-1">
                                                <p class="m-0 d-flex align-items-center"> {{city.Name}}</p>
                                            </mat-option>
                                        </mat-autocomplete>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtMACity" class="col-md-3 col-lg-2 col-form-label">Suburb<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input class="form-control float-left" type="text" [ngClass]="SetClassForControl(EmployerDtlFormGroup,'txtSuburbMail')" matInput autocomplete="off"
                                            formControlName="txtSuburbMail"
                                            [matAutocomplete]="ddlSuburbMailAutoComplete" name="txtSuburbMail"
                                            (keyup)="txtSuburbMail_Change($event)" [readonly]="isReadonly" (blur)="txtSuburbMail_Blur($event)">
                                        <mat-autocomplete #ddlSuburbMailAutoComplete="matAutocomplete"
                                            (optionSelected)="ddlSuburbMailAutoComplete_Selected($event);"
                                            id="ddlSuburbMailAutoComplete" [displayWith]="displayAutoSuburbText">
                                            <mat-option *ngFor="let sphy of filteredSuburbMail | async" [value]="sphy"
                                                style="height: auto !important;">
                                                {{sphy.Name}}, {{sphy.State?.Name}}, {{sphy.Postcode?.Name}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'txtSuburbMail')">
                                            <small *ngIf="EmployerDtlFormGroup.get('txtSuburbMail').hasError('required')"
                                                 class="invalid-feedback d-block">Suburb
                                                is required</small>
                                            <small *ngIf="EmployerDtlFormGroup.get('txtSuburbMail').hasError('unresolved')" class="invalid-feedback d-block">
                                                {{stringHelper.format(commonString.FIELD_INVALID, 'Suburb')}}</small>
                                        </div>
                                        <mat-spinner [diameter]="14" color="primary" *ngIf="isSpinerPhyMail">
                                        </mat-spinner>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ddlPostCodeMail" class="col-md-3 col-lg-2 col-form-label">Postcode<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select id="ddlPostCodeMail"
                                            [ngClass]="SetClassForControl(EmployerDtlFormGroup,'ddlPostCodeMail')"
                                            formControlName="ddlPostCodeMail" (change)="ddlPostCodeMail_Change($event)">
                                            <option value="" *ngIf="postcodesmail.length === 0">Please select a value
                                            </option>
                                            <option *ngFor="let pcode of postcodesmail" [value]="pcode.Id">
                                                {{pcode.Name}}
                                            </option>
                                        </select>
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'ddlPostCodeMail')">
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('ddlPostCodeMail').hasError('required')"
                                                 class="invalid-feedback d-block">Postcode
                                                is required</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ddlStateMail" class="col-md-3 col-lg-2 col-form-label">State<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select class="form-control" id="ddlStateMail"
                                            [ngClass]="SetClassForControl(EmployerDtlFormGroup,'ddlStateMail')"
                                            formControlName="ddlStateMail">
                                            <option value="" selected>Please select a value</option>
                                            <option *ngFor="let state of statesMA" [value]="state.Id">
                                                {{state.Name}}
                                            </option>
                                        </select>
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'ddlStateMail')">
                                            <small *ngIf="EmployerDtlFormGroup.get('ddlStateMail').hasError('required')"
                                                class="invalid-feedback d-block">State
                                                is required</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="chkMailCards" class="col-md-3 col-lg-2 col-form-label">Mail cards to the
                                    employees
                                    home address</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="chkMailCards"
                                                formControlName="chkMailCards">
                                            <label for="chkMailCards" class="custom-control-label">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="panelAddInf">
                        <ng-template ngbPanelTitle>
                            <i
                                [ngClass]="ValidateAdditionalInformation()==true || isReadonly==true?'fas fa-check icon-fp':'icon-fp'"></i>
                            Additional Information
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="form-group row">
                                <label for="ddlStatus" class="col-md-3 col-lg-2 col-form-label">Employer Status<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select [ngClass]="SetClassForControl(EmployerDtlFormGroup,'ddlStatus')"
                                            id="ddlStatus" (change)="ChangeEmployerStatus($event)" class="form-control"
                                            formControlName="ddlStatus">
                                            <!-- <option value="" selected>
                                                Please select a value
                                            </option> -->
                                            <option *ngFor="let es of employerstatus" [value]="es.Id" [disabled]="EmployerDtlFormGroup.get('ddlStatus').value == EEmployerStatus.Active && [EEmployerStatus.Onboarding,EEmployerStatus.Survey ,EEmployerStatus.ProgramDevelopment].includes(es.Id)">
                                                {{es.Name}}
                                            </option>
                                        </select>
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'ddlStatus')">
                                            <small class="invalid-feedback d-block">Employer cannot be moved into
                                                ‘Failed Launch’ status since there is active member(s)
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="EmployerDtlFormGroup.get('ddlStatus').value == EEmployerStatus.CloseForNewMembers || EmployerDtlFormGroup.get('ddlStatus').value == EEmployerStatus.FailedLaunch"
                                class="form-group row">
                                <label class="col-md-3 col-lg-2 col-form-label">Date Closed<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="date-input-group"
                                            [ngClass]="SetClassForInputGroup(EmployerDtlFormGroup,'dtpDateClosed')">
                                            <input name="dp" ngbDatepicker #dtpDateClosed="ngbDatepicker"
                                                class="form-control" style="border: none !important;"
                                                formControlName="dtpDateClosed" placeholder="DD-MM-YYYY"
                                                [minDate]="{year: 1900, month: 1, day: 1}"
                                                (focusout)="UpdateValidateDateRange(['dtpStartDate'])"
                                                (dateSelect)="UpdateValidateDateRange(['dtpStartDate'])"
                                                id="dtpDateClosed">
                                            <div class="input-group-append">
                                                <button class="btn bg-white" (click)="dtpDateClosed.toggle()"
                                                    type="button"><i class="far fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'dtpDateClosed')">
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('dtpDateClosed').hasError('required')"
                                                 class="invalid-feedback d-block">Date Closed is required</small>
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('dtpDateClosed').hasError('mindateinvalid')"
                                                 class="invalid-feedback d-block">Date Closed is invalid</small>
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('dtpDateClosed').hasError('rangedateclosedinvalid')"
                                                 class="invalid-feedback d-block">The Date Closed should be greater than
                                                the Employer Start Date</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-lg-2 col-form-label">Employer Start Date<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="date-input-group"
                                            [ngClass]="SetClassForInputGroup(EmployerDtlFormGroup,'dtpStartDate')"
                                            [class.focused]="parentFocus==6">
                                            <input name="dp" ngbDatepicker #dtpStartDate="ngbDatepicker"
                                                class="form-control" style="border: none !important;"
                                                formControlName="dtpStartDate" placeholder="DD-MM-YYYY"
                                                [minDate]="{year: 1900, month: 1, day: 1}" (blur)="onBlurParent(0)"
                                                (focus)="onFocusParent(6)"
                                                (focusout)="UpdateValidateDateRange(['dtpNextPayment','dtpDateClosed']);setNextPaymentRunDate();"
                                                (dateSelect)="UpdateValidateDateRange(['dtpNextPayment','dtpDateClosed']);setNextPaymentRunDate();showChangedEmployerStartDateConfirmation(false)"
                                                (keyup)="showChangedEmployerStartDateConfirmation(true)"
                                                id="dtpStartDate">
                                            <div class="input-group-append">
                                                <button class="btn bg-white" (click)="dtpStartDate.toggle()"
                                                    type="button"><i class="far fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'dtpStartDate')">
                                            <small *ngIf="EmployerDtlFormGroup.get('dtpStartDate').hasError('required')"
                                                 class="invalid-feedback d-block">Employer Start Date is
                                                required</small>
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('dtpStartDate').hasError('mindateinvalid')"
                                                 class="invalid-feedback d-block">Employer Start Date is
                                                invalid</small>
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('dtpStartDate').hasError('rangedateclosedinvalid')"
                                                 class="invalid-feedback d-block">The Employer Start Date should be less
                                                than
                                                the Date Closed</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-lg-2 col-form-label">Payment Types</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" disabled checked
                                                id="chkDDebit">
                                            <label for="chkDDebit"
                                                class="custom-control-label font-weight-normal">Direct
                                                Debit</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-lg-2 col-form-label">Next Payment Run<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="date-input-group input-group"
                                            [ngClass]="SetClassForInputGroup(EmployerDtlFormGroup,'dtpNextPayment')"
                                            [class.focused]="parentFocus==7">
                                            <input ngbDatepicker #dtpNextPayment="ngbDatepicker" class="form-control"
                                                formControlName="dtpNextPayment"
                                                [minDate]="{year: 1900, month: 1, day: 1}" (blur)="onBlurParent(0)"
                                                (focus)="onFocusParent(7)" placeholder="DD-MM-YYYY"
                                                [disabled]="disableNextPaymentRunDate">
                                            <div class="input-group-append">
                                                <button class="btn bg-white" type="button"
                                                    (click)="dtpNextPayment.toggle()"><i
                                                        class="far fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'dtpNextPayment')">
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('dtpNextPayment').hasError('required')"
                                                class="invalid-feedback d-block">Next Payment Run is required</small>
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('dtpNextPayment').hasError('rangedateinvalid')"
                                                class="invalid-feedback d-block">The Next Payment Run date should be
                                                greater than or equal to the Employer Start date</small>
                                            <small
                                                *ngIf="EmployerDtlFormGroup.get('dtpNextPayment').hasError('invalidFormatDate')"
                                                class="invalid-feedback d-block">Next Payment Run is
                                                invalid</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ddlPayPeriod" class="col-md-3 col-lg-2 col-form-label">Payment Period<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select class="form-control" id="ddlPayPeriod" formControlName="ddlPayPeriod">
                                            <option *ngFor="let pp of paymentperiods" [value]="pp.Id">
                                                {{pp.Name}}
                                            </option>
                                        </select>
                                        <div *ngIf="IsControlInvalid(EmployerDtlFormGroup,'ddlPayPeriod')">
                                            <small *ngIf="EmployerDtlFormGroup.get('ddlPayPeriod').hasError('required')"
                                                class="invalid-feedback d-block">Payment Period is required</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtNumbrOfEmplr" class="col-md-3 col-lg-2 col-form-label">Number of
                                    employees
                                    answered survey</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input class="form-control" id="txtNumbrOfEmplr"
                                            formControlName="txtNumbrOfEmplr" autocomplete="off" maxlength="9"
                                            (keyup)="txtNumber_Keyup($event,'txtNumbrOfEmplr')"
                                            (change)="txtNumber_Change($event,'txtNumbrOfEmplr')">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="ddlEligibleEmpls" class="col-md-3 col-lg-2 col-form-label">Eligible
                                    Employees</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select id="ddlEligibleEmpls" class="form-control "
                                            formControlName="EligibleEmplsCtrl"
                                            (ngModelChange)="selectEligibleEmpl($event)">
                                            <option value="-1" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}
                                            </option>
                                            <option *ngFor="let eligible of eligibleEmps;let i = index"
                                                [title]="setOptTitle(eligible.Name)" [value]="i">
                                                {{eligible.Name}}</option>
                                        </select>
                                        <div class="mt-2 slctSelectedRoles height1 fpscrolly w-100 border-2 rounded-10 border-default text-default"
                                            *ngIf="selectedEligibleEmps?.length > 0">
                                            <div *ngFor="let selectedEligible of selectedEligibleEmps;let i = index"
                                                class="d-flex border-default border-bottom py-1 align-content-center">
                                                <span class="text-primary d-flex align-items-center"
                                                    (click)="_removeEligible(i, selectedEligible)" *ngIf="!isReadonly">
                                                    <i class="fas fa-minus-circle fa-2x"></i>
                                                </span>
                                                <p class="d-flex align-items-center m-0 ml-2">{{selectedEligible.Name}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtEligEmpNo" class="col-md-3 col-lg-2 col-form-label">Eligible Employee
                                    Number</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="number" formControlName="EligibleEmployeeNumber"
                                            class="form-control" id="txtEligEmpNo" maxlength="150">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtAddEligInfo" class="col-md-3 col-lg-2 col-form-label">Additional
                                    Eligibility Information</label>
                                <div class="col-md-6">
                                    <div class="d-flex flex-column w-100">
                                        <textarea formControlName="AddEligInfoCtrl" class="form-control"
                                            id="txtAddEligInfo" maxlength="150"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtTarNumbr" class="col-md-3 col-lg-2 col-form-label">Target Number</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input class="form-control" id="txtTarNumbr" formControlName="txtTarNumbr"
                                            autocomplete="off" maxlength="9"
                                            (keyup)="txtNumber_Keyup($event,'txtTarNumbr')"
                                            (change)="txtNumber_Change($event,'txtTarNumbr')">
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group row">
                                <label for="txtAddJEntry" class="col-md-3 col-lg-2 col-form-label">Add Journal
                                    Entry</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" class="form-control" id="txtAddJEntry"
                                            formControlName="txtAddJEntry" autocomplete="off" maxlength="2000">
                                    </div>
                                </div>
                            </div> -->
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="panelAddNotes">
                        <ng-template ngbPanelTitle>
                            Additional Notes
                            <i class="fas fa-check icon-fp"></i>
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="form-group row">
                                <label for="txtEmplNotes" class="col-md-3 col-lg-2 col-form-label">Employer
                                    Notes</label>
                                <div class="col-md-6">
                                    <div class="d-flex flex-column w-100">
                                        <textarea [formControl]="txtAdditionalEmployerNoteControl" class="form-control"
                                            id="txtEmplNotes" maxlength="2000"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-md-8 col-lg-5 offset-sm-2">
                                    <button (click)="BtnClickAddNote()"
                                        [disabled]="txtAdditionalEmployerNoteControl.value == '' || txtAdditionalEmployerNoteControl.value == null"
                                        class="px-2 py-2 btn btn-primary text-transform-none px-lg-5 mr-3"
                                        type="button"><i class="fas fa-plus-circle mr-2"></i>Add Note</button>
                                </div>
                            </div>
                            <div class="py-3">
                                <div class="form-group row">
                                    <div class="col-md-10 offset-sm-2">
                                        <!-- Data table material here -->
                                        <div class="employer_note_container border-default rounded-10 border-2 w-100">
                                            <div class="tablecontainer">
                                                <table id="list_employer_notes" mat-table
                                                    [dataSource]="dataSourceNotes">
                                                    <ng-container matColumnDef="user">
                                                        <th mat-header-cell *matHeaderCellDef>User</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.UserFullName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="dateTime">
                                                        <th mat-header-cell *matHeaderCellDef>Date/Time
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DateTimeDisplay || element.DateTime}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="note">
                                                        <th mat-header-cell *matHeaderCellDef>Note</th>
                                                        <td mat-cell *matCellDef="let element"> {{element.Note}} </td>
                                                    </ng-container>

                                                    <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                                                    <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                                                </table>
                                            </div>
                                            <mat-paginator #notepagi [pageSizeOptions]="[5, 10, 20, 50]"
                                                showFirstLastButtons>
                                            </mat-paginator>
                                            <mat-toolbar [hidden]="dataSourceNotes == null" class="bg-white">
                                                <mat-toolbar-row
                                                    *ngIf="dataSourceNotes != null && dataSourceNotes.data.length == 0"
                                                    class="text-muted d-block p-2 bg-light text-center">
                                                    {{commonString.TABLE_NO_RECORD_FOUND}}
                                                </mat-toolbar-row>
                                            </mat-toolbar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div
                    class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start justify-content-between justify-content-md-start">
                    <button *ngIf="!isEditMode && !isReadonly"
                        class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                        style="display: none"><i class="fas fa-check mr-2"></i>
                        Save as Draft</button>
                    <button *ngIf="!isReadonly && isEditMode" class="px-2 px-lg-5 py-2 btn btn-primary mr-3"
                        type="button" [disabled]="submitDisabled" (click)="SaveClick()"><i
                            class="fas fa-check mr-2"></i>Save</button>
                    <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" matStepperNext
                        (click)="btnNext_Click('EmployerDetailTab')"><i class="fas fa-arrow-circle-right mr-2"></i>
                        Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="ContractFormGroup" #stepper2>
            <form [formGroup]="ContractFormGroup" id="employertb1">
                <ng-template matStepLabel><i class="icon-fp d-lg-none"></i><span
                        (click)="HeaderClick();">Contracts</span></ng-template>
                <ngb-accordion #acc="ngbAccordion" [activeIds]="panelDetail">
                    <ngb-panel id="panelDetail">
                        <ng-template ngbPanelTitle>
                            <i class="fas fa-check icon-fp"></i>
                            Contracts
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col-sm-7">
                                    <div class="form-group row">
                                        <label for="txtDateStarted" class="col-sm-3 col-form-label">Date Started</label>
                                        <div class="col-md-9">
                                            <div class="d-flex flex-column w-100">
                                                <input type="text" class="form-control" id="txtDateStarted" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="txtCSDate" class="col-sm-3 col-form-label">Contract
                                            Start Date</label>
                                        <div class="col-md-9">
                                            <div class="d-flex flex-column w-100">
                                                <div [ngClass]="SetClassForInputGroup(ContractFormGroup,'dtpCTStartDate')"
                                                    [class.focused]="parentFocus==8">
                                                    <input class="form-control" placeholder="DD-MM-YYYY" name="dp"
                                                        ngbDatepicker #d1="ngbDatepicker"
                                                        formControlName="dtpCTStartDate"
                                                        (blur)="checkValueDate('dtpCTStartDate')"
                                                        (keyup)="checkValueDate('dtpCTStartDate')"
                                                        (dateSelect)="checkValueDate('dtpCTStartDate')"
                                                        (blur)="onBlurParent(0)" (focus)="onFocusParent(8)">
                                                    <div class="input-group-append">
                                                        <button class="btn bg-white" (click)="d1.toggle()"
                                                            type="button"><i class="far fa-calendar-alt"></i></button>
                                                    </div>
                                                </div>
                                                <div *ngIf="IsControlInvalid(ContractFormGroup,'dtpCTStartDate')">
                                                    <small
                                                        *ngIf="ContractFormGroup.get('dtpCTStartDate').hasError('invalidFormatDate')"
                                                        class="invalid-feedback d-block">Contract Start Date is
                                                        invalid</small>
                                                    <small
                                                        *ngIf="ContractFormGroup.get('dtpCTStartDate').hasError('invalidDate')"
                                                         class="invalid-feedback d-block">The Contract Start Date
                                                        should be less than the Contract End Date</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="txtCSDate"
                                            class="col-sm-3 col-form-label">Contract<br />End Date</label>
                                        <div class="col-md-9">
                                            <div class="d-flex flex-column w-100">
                                                <div [ngClass]="SetClassForInputGroup(ContractFormGroup,'dtpCTEndDate')"
                                                    [class.focused]="parentFocus==9">
                                                    <input class="form-control" placeholder="DD-MM-YYYY" name="dp"
                                                        ngbDatepicker #d2="ngbDatepicker" formControlName="dtpCTEndDate"
                                                        (blur)="checkValueDate('dtpCTEndDate')"
                                                        (keyup)="checkValueDate('dtpCTEndDate')"
                                                        (dateSelect)="checkValueDate('dtpCTEndDate')"
                                                        (blur)="onBlurParent(0)" (focus)="onFocusParent(9)">
                                                    <div class="input-group-append">
                                                        <button class="btn bg-white" (click)="d2.toggle()"
                                                            type="button"><i class="far fa-calendar-alt"></i></button>
                                                    </div>
                                                </div>
                                                <div *ngIf="IsControlInvalid(ContractFormGroup,'dtpCTEndDate')">
                                                    <small
                                                        *ngIf="ContractFormGroup.get('dtpCTEndDate').hasError('invalidFormatDate')"
                                                        class="invalid-feedback d-block">Contract End Date is
                                                        invalid</small>
                                                    <small
                                                        *ngIf="ContractFormGroup.get('dtpCTEndDate').hasError('invalidDate')"
                                                         class="invalid-feedback d-block">The Contract End Date should
                                                        be greater than the Contract Start Date</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="txtCDuration" class="col-sm-3 col-form-label">Contract
                                            Duration</label>
                                        <div class="col-md-9">
                                            <div class="d-flex flex-column w-100">
                                                <div class="input-group"
                                                    [ngClass]="SetClassForInputGroup(ContractFormGroup,'txtCDuration')">
                                                    <input type="text" class="form-control" id="txtCDuration"
                                                        maxlength="2" [readonly]="contractDurationEnable"
                                                        formControlName="txtCDuration" numberOnly
                                                        (blur)="onBlurParent(0)" (focus)="onFocusParent(1)"
                                                        #txtCDuration>
                                                    <div class="input-group-append">
                                                        <span class="input-group-text text-primary">Year(s)</span>
                                                    </div>
                                                </div>
                                                <!-- <div *ngIf="IsControlInvalid(ContractFormGroup,'txtCDuration')"> -->
                                                <small *ngIf="ContractFormGroup.get('txtCDuration').hasError('max')"
                                                     class="invalid-feedback d-block">Contract Duration must be
                                                    less than 100</small>
                                                <!-- </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="txtCSignatory" class="col-sm-3 col-form-label">Contract
                                            Signatory</label>
                                        <div class="col-md-9">
                                            <div class="d-flex flex-column w-100">
                                                <input type="text" class="form-control" id="txtCSignatory"
                                                    maxlength="50" formControlName="txtCSignatory">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="txtCSDate"
                                            class="col-sm-3 col-form-label">Signed <br />Contract</label>
                                        <div class="col-md-9">
                                            <div class="d-flex flex-column w-100">
                                                <div>
                                                    <app-fp-file-upload filterFile=".pdf" #txtSignedContract
                                                        maxfilesize="100"
                                                        (UploadCompleted)="SignedContract_UploadCompleted($event)"
                                                        (fileSelected)="_signedContractSelected($event)"
                                                        [isReadonly]="ContractFormGroup.disabled">
                                                    </app-fp-file-upload>
                                                </div>
                                                <span class="col-form-label" *ngIf="IsAllSignContractRemoved()">Upload
                                                    PDF</span>
                                                <div class="slctRoles border-2 rounded-10 mt-3 border-default"
                                                    *ngIf="!IsAllSignContractRemoved()">
                                                    <div *ngFor="let s of signedContract">
                                                        <div class="d-flex border-default border-bottom py-1 justify-content-between"
                                                            *ngIf="s.Status=='Addnew' || s.Status==null">
                                                            <a class="d-flex align-items-center m-0 wordbreak w-90"
                                                                target="_blank" [href]="s.Location">{{
                                                                s.FileName }}</a>
                                                            <!-- <span class="text-primary d-flex align-items-center"
                                                                (click)="removeSignedContract(s,signedContract)"
                                                                *ngIf="!isReadonly"><i
                                                                    class="fas fa-minus-circle fa-2x"></i></span> -->
                                                            <span class="text-primary d-flex align-items-center"
                                                                (click)="deleteFileClick(s,signedContract,2)"
                                                                *ngIf="!isReadonly"><i
                                                                    class="fas fa-minus-circle fa-2x"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="txtCSDate" class="col-sm-3 col-form-label">Attach Additional
                                            Files</label>
                                        <div class="col-md-9">
                                            <div class="d-flex flex-column w-100">
                                                <div>
                                                    <app-fp-file-upload filterFile=".pdf,image/*" #txtAttachAdditional
                                                        (UploadCompleted)="AttAdd_UploadCompleted($event)"
                                                        (fileSelected)="_AttachAdditionalSelected($event)"
                                                        [isReadonly]="ContractFormGroup.disabled">
                                                    </app-fp-file-upload>
                                                </div>
                                                <span class="col-form-label" *ngIf="IsAllAdditionalFileRemoved()">Upload
                                                    JPEG, PNG or PDF</span>
                                                <div class="slctRoles border-2 rounded-10 mt-3 border-default"
                                                    *ngIf="!IsAllAdditionalFileRemoved()">
                                                    <div *ngFor="let a of attachAdditional">
                                                        <div class="d-flex border-default border-bottom py-1 justify-content-between"
                                                            *ngIf="a.Status=='Addnew' || a.Status==null">
                                                            <a class="d-flex align-items-center m-0 wordbreak w-90"
                                                                target="_blank" [href]="a.Location">{{ a.FileName }}</a>
                                                            <!-- <span class="text-primary d-flex align-items-center"
                                                                (click)="removeAttachAdditional(a,attachAdditional)"
                                                                *ngIf="!isReadonly"><i
                                                                    class="fas fa-minus-circle fa-2x"></i></span> -->
                                                            <span class="text-primary d-flex align-items-center"
                                                                (click)="deleteFileClick(a,attachAdditional,3)"
                                                                *ngIf="!isReadonly"><i
                                                                    class="fas fa-minus-circle fa-2x"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-5">
                                    <div class="fp-profile d-flex justify-content-center">
                                        <div class="w-50">
                                            <div *ngIf="imageDefault"
                                                class="bg-secondary fp-profilephoto d-flex justify-content-center align-items-center mx-auto ng-star-inserted">
                                                <i class="fa fa-image fa-2x text-secondary"></i>
                                            </div>
                                            <div *ngFor="let l of logoContract">
                                                <div *ngIf="imageSrc != '' && (l.Status=='Addnew' || l.Status==null)"
                                                    class="position-relative container">
                                                    <div class="fp-photologo mt-3 text-center">
                                                        <img [src]="l.Base64?imageSrc:l.Location" alt=""
                                                            class="img-fluid">
                                                    </div>
                                                    <div class="overlay">
                                                        <!-- <a class="cursordisplay position-absolute position-topright"
                                                            title="Remove logo" (click)="removeLogo(l,logoContract)"
                                                            *ngIf="!isReadonly">
                                                            <i class="text-danger fas fa-times fa-2x-ex"></i>
                                                        </a> -->
                                                        <a class="cursordisplay position-absolute position-topright"
                                                            title="Remove logo"
                                                            (click)="deleteFileClick(l,logoContract,1)"
                                                            *ngIf="!isReadonly">
                                                            <i class="text-danger fas fa-times fa-2x-ex"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="custom-file mt-2">
                                                <div>
                                                    <app-fp-file-upload filterFile="image/*"
                                                        (UploadCompleted)="Logo_UploadCompleted($event)"
                                                        maxfilesize="10" #txtAttachLogo
                                                        (fileSelected)="_logoSelected($event)"
                                                        [isReadonly]="ContractFormGroup.disabled">
                                                    </app-fp-file-upload>
                                                </div>
                                            </div>

                                            <span class="col-form-label" *ngIf="imageDefault">Upload
                                                JPEG or PNG</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                    <button *ngIf="!isEditMode && !isReadonly"
                        class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                        style="display: none"><i class="fas fa-check mr-2"></i>
                        Save as Draft</button>
                    <button *ngIf="!isReadonly && isEditMode" class="px-2 px-lg-5 py-2 btn btn-primary mr-3"
                        type="button" [disabled]="submitDisabled" (click)="SaveClick()"><i
                            class="fas fa-check mr-2"></i>Save</button>
                    <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" matStepperNext
                        (click)="btnNext_Click()"><i class="fas fa-arrow-circle-right mr-2"></i>
                        Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="FacilitatorFormGroup" #stepper3>
            <form [formGroup]="FacilitatorFormGroup" id="employertb2">
                <ng-template matStepLabel><i class="icon-fp d-lg-none"></i>Facilitators</ng-template>
                <ngb-accordion #acc="ngbAccordion" [activeIds]="panelDetail">
                    <ngb-panel id="panelDetail">
                        <ng-template ngbPanelTitle>
                            <i class="fas fa-check icon-fp"></i>
                            Facilitators
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="form-group row">
                                <p class="font-italic">On this screen you have the ability to remove facilitators,
                                    select or change the primary facilitator and add notes about the facilitators of the
                                    organisation. If you wish to <strong>ADD</strong> a new facilitator you will need to
                                    use the <strong>USER</strong> functionality in the system.</p>
                            </div>
                            <div class="form-group row" *ngIf="facilitatorsSelected.length > 0">
                                <label for="txtDateStarted" class="col-md-3 col-lg-2 col-form-label">Select One Primary
                                    Facilitator</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="slctRoles height1 fpscrolly border-2 rounded-10 border-default">
                                            <div class="d-flex border-default border-bottom py-1 justify-content-between"
                                                *ngFor="let f of facilitatorsSelected">
                                                <div class="d-flex align-items-center">
                                                    <div class="custom-control custom-radio pt-0 m-0">
                                                        <input type="radio" class="custom-control-input"
                                                            [checked]="primaryFacilitator == f.UserId"
                                                            id="pryFactOpt{{f.UserId}}" name="pryFactOpt"
                                                            (click)="setPrimaryFacilitator(f)" [disabled]="isReadonly">
                                                        <label class="custom-control-label"
                                                            for="pryFactOpt{{f.UserId}}">{{
                                                            f.LastName }}, {{ f.FirstName }}</label>
                                                    </div>
                                                </div>
                                                <span *ngIf="!isReadonly" class="text-primary d-flex align-items-center"
                                                    (click)="removeFacilitator(f)"><i
                                                        class="fas fa-minus-circle fa-2x"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtrDsc" class="col-md-3 col-lg-2 col-form-label">Add Additional
                                    Notes</label>
                                <div class="col-sm-7 flex-column align-items-start">
                                    <div class="d-flex flex-column w-100">
                                        <textarea maxlength="2000" class="form-control" id="txtrDsc" rows="3"
                                            formControlName="txtrDsc"></textarea>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                    <button *ngIf="!isEditMode && !isReadonly"
                        class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                        style="display: none"><i class="fas fa-check mr-2"></i>
                        Save as Draft</button>
                    <button *ngIf="!isReadonly && isEditMode" class="px-2 px-lg-5 py-2 btn btn-primary mr-3"
                        type="button" [disabled]="submitDisabled" (click)="SaveClick()"><i
                            class="fas fa-check mr-2"></i>Save</button>
                    <button (click)="btnNext_Click()" class="px-2 px-lg-5 py-2 btn btn-primary" type="button"
                        matStepperNext><i class="fas fa-arrow-circle-right mr-2"></i>
                        Next</button>
                </div>
            </form>
        </mat-step>

        <!--#region branch/location-->
        <mat-step [stepControl]="RegionFormGroup" #stepper4>
            <form id="employertb3">
                <ng-template matStepLabel><i class="icon-fp d-lg-none"></i>Branch/Locations</ng-template>
                <ngb-accordion #acc="ngbAccordion" [activeIds]="panelDetail">
                    <ngb-panel id="panelDetail">
                        <ng-template ngbPanelTitle>
                            <i class="fas fa-check icon-fp"></i>
                            Add Branch/Location
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-employer-branchs [data]="branchsData" [form]="branchFormGroup"
                                [isReadonly]="isReadonly"></app-employer-branchs>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                    <button *ngIf="!isEditMode && !isReadonly"
                        class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                        style="display: none"><i class="fas fa-check mr-2"></i>
                        Save as Draft</button>
                    <button *ngIf="!isReadonly && isEditMode" class="px-2 px-lg-5 py-2 btn btn-primary mr-3"
                        type="button" [disabled]="submitDisabled" (click)="SaveClick()"><i
                            class="fas fa-check mr-2"></i>Save</button>
                    <button (click)="btnNext_Click()" class="px-2 px-lg-5 py-2 btn btn-primary" type="button"
                        matStepperNext><i class="fas fa-arrow-circle-right mr-2"></i>
                        Next</button>
                </div>
            </form>
        </mat-step>
        <!--#endregion branch/location-->
        <!-- #region membership package-->
        <mat-step [stepControl]="MembershipPackagesFormGroup" #stepper5>
            <form [formGroup]="MembershipPackagesFormGroup" class="formstepper" id="employertb4">
                <ng-template matStepLabel><i class="icon-fp d-lg-none"></i>Membership Packages</ng-template>
                <app-employer-membership-package [formGroup]="MembershipPackagesFormGroup" [isEditMode]="isEditMode"
                    [isReadonly]="isReadonly" [EmployerId]="getEmployerId()" #membershipPackage>
                </app-employer-membership-package>
                <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                    <button *ngIf="!isEditMode && !isReadonly"
                        class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                        style="display: none"><i class="fas fa-check mr-2"></i>
                        Save as Draft</button>
                    <button *ngIf="!isReadonly && isEditMode" class="px-2 px-lg-5 py-2 btn btn-primary mr-3"
                        type="button" [disabled]="submitDisabled" (click)="SaveClick()"><i
                            class="fas fa-check mr-2"></i>Save</button>
                    <button (click)="btnNext_Click()" class="px-2 px-lg-5 py-2 btn btn-primary" type="button"
                        matStepperNext><i class="fas fa-arrow-circle-right mr-2"></i>
                        Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="EmployerPageFormGroup" #stepper6>
            <form [formGroup]="EmployerPageFormGroup" class="formstepper" id="employertb5">
                <ng-template matStepLabel><i class="icon-fp d-lg-none"></i><span>Employer Page</span></ng-template>
                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0,ngb-panel-1">
                    <ngb-panel id="ngb-panel-0">
                        <ng-template ngbPanelTitle>
                            Employer Portal Page
                            <i
                                [ngClass]="EmployerPageFormGroup.valid || isReadonly==true?'fas fa-check icon-fp':'icon-fp'"></i>
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-employer-page [form]="EmployerPageFormGroup" [data]="pageData"
                                (processingLinkName)="_processingLinkName($event)"></app-employer-page>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="ngb-panel-1">
                        <ng-template ngbPanelTitle>
                            Survey Page
                            <i 
                            [ngClass]="(EmployerSurveyPageFormGroup.valid && EmployerSurveyPageFormGroup.get('startDate').value && EmployerSurveyPageFormGroup.get('endDate').value) ? 'fas fa-check icon-fp' : 'icon-fp'"></i>
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-employer-surveypage (surveyValuesChange)="onSurveyValuesChange($event)" [form]="EmployerSurveyPageFormGroup" [data]="surveyPageData"
                            ></app-employer-surveypage>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                    <button *ngIf="!isEditMode && !isReadonly"
                        class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                        style="display: none"><i class="fas fa-check mr-2"></i>
                        Save as Draft</button>
                    <button *ngIf="!isReadonly && isEditMode" class="px-2 px-lg-5 py-2 btn btn-primary mr-3"
                        type="button" [disabled]="submitDisabled" (click)="SaveClick()"><i
                            class="fas fa-check mr-2"></i>Save</button>
                    <button (click)="btnNext_Click('EmployerPage')" class="px-2 px-lg-5 py-2 btn btn-primary"
                        type="button" matStepperNext><i class="fas fa-arrow-circle-right mr-2"></i>
                        Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="CustomFieldsFormGroup" #stepper7>
            <form [formGroup]="CustomFieldsFormGroup" class="formstepper" id="employertb6">
                <ng-template matStepLabel><i class="icon-fp d-lg-none"></i><span
                        (click)="HeaderClick('Additional');">Additional
                        Attachments</span></ng-template>
                <app-employer-additional-attachments [EmployerId]="getEmployerId()" #additionalAttachments
                    [isReadOnly]="CustomFieldsFormGroup.disabled">
                </app-employer-additional-attachments>
                <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                    <button *ngIf="!isEditMode && !isReadonly"
                        class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                        style="display: none"><i class="fas fa-check mr-2"></i>
                        Save as Draft</button>
                    <button *ngIf="!isReadonly" class="px-2 px-lg-5 py-2 btn btn-primary" type="button"
                        [disabled]="submitDisabled" (click)="SaveClick()"><i class="fas fa-check mr-2"></i>{{isEditMode
                        ? 'SAVE' : 'SUBMIT'}}</button>
                </div>
            </form>
        </mat-step>

        <!-- ============================================================================================================================================= -->
        <mat-step #stepper8>
            <form class="formstepper" id="employertb7">
                <ng-template matStepLabel><i class="icon-fp d-lg-none"></i><span>Audit Log</span>
                </ng-template>
                <ngb-accordion activeIds="documentAttachmentsId,imageAttachments">
                    <ngb-panel id="documentAttachmentsId">
                        <ng-template ngbPanelTitle>
                            <i class="fas fa-check icon-fp"></i>
                            Audit Log
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div id="employerAuditLogsTable">
                                <div>
                                    <app-audit-log [auditSource]="'Employer'"></app-audit-log>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </form>
        </mat-step>
    </mat-vertical-stepper>
</div>