import { State } from './state.model';
import { Region } from './region.model';
import { ServiceType } from './service-type.model';

export class Package {
    Id: number;
    Name: string;
    IsActive: boolean;
}

export class PackageDetails extends Package {
    States: State[];
    Regions: Region[];
    ServiceTypes: ServiceType[];
    StateFilter: number[];
    RegionFilter: number[];
    ProductServiceTypeFilter: number[];

    ProductIds: number[];
    ServiceIds: number[];
    ModifiedBy?: string;
}

export class PackageSearchResultRecord extends Package {
    States: State;
    Regions: Region;
}