<div [formGroup]="form">
    <div class="border-default rounded-10 border-2">
        <div class="px-2 fptablefreezeheadrow">
            <mat-table #table class="fptablehead" matSort [dataSource]="bogusDataSource" matSortActive="facilityName"
                matSortDirection="desc" matSortStart="desc" (matSortChange)="sortChange($event)" matSortDisableClear>
                <!-- Name Column -->
                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="custom-control custom-checkbox m-0 p-0 d-flex align-items-center"
                            *ngIf="!form.disabled">
                            <input id="chkall" [hidden]="!dataSource" type="checkbox" class="custom-control-input"
                                (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            <label class="custom-control-label" for="chkall">&nbsp;</label>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"></mat-cell>
                </ng-container>
                <ng-container matColumnDef="facilityName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Facility</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.facilityName}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Product/Service Name</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Product/Service Type</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="peakStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Peak/Off-peak</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.peakStatus}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="availableForAllEmployers">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Available
                        for all employers</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.availableForAllEmployers}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.status}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="state">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.state }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="region">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Region</mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.region }} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </mat-table>

            <mat-table #table class="fpscrolly height3 d-block" matSort [dataSource]="dataSource">
                <!-- Name Column -->
                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index">
                        <div class="custom-control custom-checkbox m-0 p-0" *ngIf="!form.disabled">
                            <input id='chk_{{i}}' type="checkbox" class="custom-control-input"
                                (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null; updateForm()"
                                [checked]="selection.isSelected(row)">
                            <label class="custom-control-label" for='chk_{{i}}'>&nbsp;</label>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="facilityName">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.facilityName}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="peakStatus">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.peakStatus}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="availableForAllEmployers">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"
                        [ngClass]="{'text-success' : element.availableForAllEmployers, 'text-muted':!element.availableForAllEmployers}">
                        {{element.availableForAllEmployers ? 'Yes' : 'No'}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.status}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="state">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.state }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="region">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.region }} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns" [style.min-height]="0"></mat-header-row>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </mat-table>
            <mat-toolbar [hidden]="dataSource?.data.length > 0" class="bg-white">
                <mat-toolbar-row class="text-muted d-block p-2 bg-light text-center">
                    {{ CommonString.TABLE_NO_RECORD_FOUND }}
                </mat-toolbar-row>
            </mat-toolbar>
        </div>
    </div>
</div>