<div class="d-flex flex-column w-100" [formGroup]="AuditFormGroup">
  <select class="form-control" id="ddlAuditLogFilter" formControlName="ddlFilter" (change)="month_Year_Change()" style="margin-bottom: 1rem; align-self: flex-end; width: 33%;">
    <option value="" disable selected>Please select</option>
    <option *ngFor="let fi of FilterItems" [value]="fi.Name">
      {{fi.Name}}
    </option>
  </select>
</div>
<div class="border-default rounded-10 border-2" [formGroup]="ReportFormGroup">
  <div class="tablecontainer fptablefreezeheadrow px-2" style="width: 100%; overflow-y: hidden;  max-height: none;">
    <table mat-table class="fpscrolly height3 d-block" [dataSource]="AuditLogDS" matSort matSortActive="DateTime"
      matSortDirection="desc" class="rounded-10">>
      <ng-container matColumnDef="Category">
        <mat-header-cell *matHeaderCellDef mat-sort-header><b>Category</b></mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Category}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="DateTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header><b>Date/Time</b></mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.DateTime}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Event">
        <mat-header-cell *matHeaderCellDef mat-sort-header><b>Event</b></mat-header-cell>
        <mat-cell *matCellDef="let element" style="align-items: center">
          <div [ngStyle]="eventStyling(element.Event)">{{element.Event}}</div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="UserName">
        <mat-header-cell *matHeaderCellDef mat-sort-header><b>User</b></mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.UserName}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="Ref">
        <mat-header-cell *matHeaderCellDef>
          <a (click)="auditSearch()">
            <i class="fas fa-sync fa-xs"></i>
          </a>
        </mat-header-cell>
        <mat-cell *matCellDef="let element"></mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </table>
    <div *ngIf="isLoading" class="spinner-wrapper">
      <mat-spinner [diameter]="20"></mat-spinner>
    </div>
    <mat-paginator #adpagi [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons>
    </mat-paginator>
    <mat-toolbar [hidden]="dataSource?.length > 0 || isLoading" class="bg-white">
      <mat-toolbar-row class="text-muted d-block p-2 bg-light text-center" *ngIf="TimeoutError.length <= 0">
        {{ CommonString.TABLE_NO_RECORD_FOUND }}
      </mat-toolbar-row>
      <mat-toolbar-row class="text-muted d-block p-2 bg-light text-center" *ngIf="TimeoutError.length > 0">
        {{ TimeoutError }}
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
</div>