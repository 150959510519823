<div [formGroup]="form">
    <ngb-accordion [destroyOnHide]="false"
        activeIds="pnlGeneralInformation,pnlMembershipPackageTypes,pnlAllowedMembershipPackageTypes,pnlRestrictedMembershipPackageTypes">
        <ngb-panel id="pnlGeneralInformation">
            <ng-template ngbPanelTitle>
                General Information
                <i class="icon-fp fas fa-check"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <div>
                    <p>This page identifies which employers have access to this facility.</p>
                    <p>If no employer packages are listed the facility is unrestricted/available to all.</p>
                    <p>If employer packages are listed in any section the facility is restricted. Only those packages listed in the Employers and Membership Types with Access section have access to this facility</p>
                </div>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlMembershipPackageTypes">
            <ng-template ngbPanelTitle>
                Add Facility to Package/Employer
                <i class="icon-fp fas fa-check"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="form-group row">
                    <label for="ddlMembershipTypes" class="col-md-3 col-lg-2 col-form-label">Add Facility to
                        Membership Type</label>
                    <div class="col-md-8 col-lg-5">
                        <div class="d-flex flex-column w-100">
                            <select class="form-control" id="ddlMembershipTypes"
                                (change)="ddlMembershipTypes_Change($event)" [disabled]="form.disabled">
                                <option>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                                <option *ngIf="MsPkgTypesInDropdown?.length > 0" (click)="_addAllMembershipTypes()"> All Membership Types</option>
                                <option *ngFor="let type of MsPkgTypesInDropdown;let i=index" [ngValue]="i"
                                    (click)="_addMembershipType(i)">{{type.Name}}</option>
                            </select>
                            <div [hidden]="!MsPkgTypesSelected || MsPkgTypesSelected.length === 0"
                                class="mt-2 slctSelectedRoles height1 fpscrolly w-100 border-2 rounded-10 border-default text-default">
                                <div *ngFor="let selectedType of MsPkgTypesSelected;let j=index"
                                    class="d-flex border-default border-bottom py-1 align-content-center ng-star-inserted"
                                    [ngClass]="{ 'disabled': form.disabled}">
                                    <span class=" text-primary d-flex align-items-center"
                                        (click)="_removeMembershipType(j)">
                                        <i class="fas fa-minus-circle fa-2x" *ngIf="!form.disabled"></i>
                                    </span>
                                    <p class="d-flex align-items-center m-0 ml-2">{{selectedType.Name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlAllowedMembershipPackageTypes">
            <ng-template ngbPanelTitle>
                Employers and Membership Types with access
                <i class="icon-fp fas fa-check"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <form>
                    <div class="form-group row">
                        <div class="col-md-8 col-lg-5">
                            <div class="input-group">
                                <input class="form-control" type="text" name="txtAllowedMembershipPackagesFilter"
                                    id="txtAllowedMembershipPackagesFilter" placeholder="Search by Employer Name"
                                    (keyup)="txtMembershipPackagesFilter_KeyUp($event, btnAllowedMembershipPackagesFilter)"
                                    #txtAllowedMembershipPackagesFilter>
                                <div class="input-group-append">
                                    <button class="input-group-text" type="button"
                                        (click)="_filterByEmployer(txtAllowedMembershipPackagesFilter.value, allowedMembershipPackagesDS)"
                                        #btnAllowedMembershipPackagesFilter>
                                        <i class="fas fa-search text-primary"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-default rounded-10 border-2">
                        <div class="tablecontainer height2 fpscrolly">
                            <table mat-table [dataSource]="allowedMembershipPackagesDS" class="rounded-10" matSort
                                matSortActive="employer" matSortDirection="asc" matSortStart="asc" matSortDisableClear
                                #srtAllowedMembershipPackages="matSort">
                                <ng-container matColumnDef="tickBoxes">
                                    <th mat-header-cell *matHeaderCellDef class="p-0 text-center">
                                        <div class="custom-control custom-checkbox p-0 pr-2 m-0" *ngIf="!form.disabled">
                                            <input type="checkbox" class="custom-control-input"
                                                (change)="_toggleSelectAll(allowedMembershipPackagesDS, $event.target.checked)"
                                                #chkSelectAllAllowedMembershipPackages>
                                            <label class="custom-control-label">&nbsp;</label>
                                        </div>
                                    </th>
                                    <td mat-cell *matCellDef="let row" class="pl-0 text-center">
                                        <div class="custom-control custom-checkbox p-0 pr-2 m-0" *ngIf="!form.disabled">
                                            <input type="checkbox" class="custom-control-input"
                                                [(ngModel)]="row.Checked" [ngModelOptions]="{standalone: true}"
                                                (change)="_updateSelectAllCheckedState(allowedMembershipPackagesDS, true)">
                                            <label class="custom-control-label">&nbsp;</label>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="employer">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Employer</th>
                                    <td mat-cell *matCellDef="let element"> {{element.Employer?.Name}} </td>
                                </ng-container>

                                <ng-container matColumnDef="employerCode">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Employer Code</th>
                                    <td mat-cell *matCellDef="let element">{{element.Employer?.Code}} </td>
                                  </ng-container>

                                <ng-container matColumnDef="membershiptype">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Membership Type</th>
                                    <td mat-cell *matCellDef="let element"> {{element.MembershipPackageType?.Name}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="state">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
                                    <td mat-cell *matCellDef="let element"> {{element.State?.Name}} </td>
                                </ng-container>

                                <ng-container matColumnDef="region">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Region</th>
                                    <td mat-cell *matCellDef="let element"> {{element.Region?.Name}} </td>
                                </ng-container>

                                <ng-container matColumnDef="access">
                                    <th mat-header-cell *matHeaderCellDef>Access</th>
                                    <td mat-cell *matCellDef="let element" class="text-success"> Active </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="_displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: _displayedColumns; let i = index"></tr>
                            </table>
                        </div>
                        <mat-toolbar [hidden]="!loaded" class="bg-white">
                            <mat-toolbar-row [hidden]="allowedMembershipPackagesDS?.data.length == 0" class="p-0">
                                <mat-paginator class="w-100" showFirstLastButtons #pagAllowedMembershipPackages>
                                </mat-paginator>
                            </mat-toolbar-row>
                            <mat-toolbar-row *ngIf="allowedMembershipPackagesDS?.data.length == 0"
                                class="text-muted d-block p-2 bg-light text-center">
                                {{CommonString.TABLE_NO_RECORD_FOUND}}
                            </mat-toolbar-row>
                        </mat-toolbar>
                    </div>
                    <div class="pt-4">
                        <button class="px-5 py-2 btn btn-primary" type="button"
                            [disabled]="!_hasSelectedRows(allowedMembershipPackagesDS)"
                            (click)="_allowOrRestrictSelectedMembershipPackages(true)" *ngIf="!form.disabled">
                            <i class="fas fa-times-circle mr-2"></i>Remove Selected</button>
                    </div>
                </form>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlRestrictedMembershipPackageTypes">
            <ng-template ngbPanelTitle>
                Restricted Employers and Membership Types
                <i class="icon-fp fas fa-check"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <form>
                    <div class="form-group row">
                        <div class="col-md-8 col-lg-5">
                            <div class="input-group">
                                <input class="form-control" type="text" name="txtAllowedMembershipPackagesFilter"
                                    id="txtRestrictedMembershipPackagesFilter" placeholder="Search by Employer Name"
                                    (keyup)="txtMembershipPackagesFilter_KeyUp($event, btnRestrictedMembershipPackagesFilter)"
                                    #txtRestrictedMembershipPackagesFilter>
                                <div class="input-group-append">
                                    <button class="input-group-text" type="button"
                                        (click)="_filterByEmployer(txtRestrictedMembershipPackagesFilter.value, restrictedMembershipPackagesDS)"
                                        #btnRestrictedMembershipPackagesFilter>
                                        <i class="fas fa-search text-primary"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="border-default rounded-10 border-2">
                        <div class="tablecontainer height2 fpscrolly">
                            <table mat-table [dataSource]="restrictedMembershipPackagesDS" class="rounded-10" matSort
                                matSortActive="employer" matSortDirection="asc" matSortStart="asc" matSortDisableClear
                                #srtRestrictedMembershipPackages="matSort">
                                <ng-container matColumnDef="tickBoxes">
                                    <th mat-header-cell *matHeaderCellDef class="p-0 text-center">
                                        <div class="custom-control custom-checkbox p-0 m-0" *ngIf="!form.disabled">
                                            <input type="checkbox" class="custom-control-input"
                                                (change)="_toggleSelectAll(restrictedMembershipPackagesDS, $event.target.checked)"
                                                #chkSelectAllRestrictedMembershipPackages>
                                            <label class="custom-control-label">&nbsp;</label>
                                        </div>
                                    </th>
                                    <td mat-cell *matCellDef="let row" class="pl-0 text-center">
                                        <div class="custom-control custom-checkbox p-0 m-0" *ngIf="!form.disabled">
                                            <input type="checkbox" class="custom-control-input"
                                                [(ngModel)]="row.Checked" [ngModelOptions]="{standalone: true}"
                                                (change)="_updateSelectAllCheckedState(restrictedMembershipPackagesDS, false)">
                                            <label class="custom-control-label">&nbsp;</label>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="employer">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Employer</th>
                                    <td mat-cell *matCellDef="let element"> {{element.Employer?.Name}} </td>
                                </ng-container>

                                <ng-container matColumnDef="employerCode">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Employer Code</th>
                                    <td mat-cell *matCellDef="let element">{{element.Employer?.Code}}</td>
                                  </ng-container>

                                <ng-container matColumnDef="membershiptype">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Membership Type</th>
                                    <td mat-cell *matCellDef="let element"> {{element.MembershipPackageType?.Name}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="state">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
                                    <td mat-cell *matCellDef="let element"> {{element.State?.Name}} </td>
                                </ng-container>

                                <ng-container matColumnDef="region">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Region</th>
                                    <td mat-cell *matCellDef="let element"> {{element.Region?.Name}} </td>
                                </ng-container>

                                <ng-container matColumnDef="access">
                                    <th mat-header-cell *matHeaderCellDef>Access</th>
                                    <td mat-cell *matCellDef="let element" class="text-muted"> Inactive </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="_displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: _displayedColumns; let i = index"></tr>
                            </table>
                        </div>
                        <mat-toolbar [hidden]="!loaded" class="bg-white">
                            <mat-toolbar-row [hidden]="restrictedMembershipPackagesDS?.data.length == 0" class="p-0">
                                <mat-paginator class="w-100" showFirstLastButtons #pagRestrictedMembershipPackages>
                                </mat-paginator>
                            </mat-toolbar-row>
                            <mat-toolbar-row *ngIf="restrictedMembershipPackagesDS?.data.length == 0"
                                class="text-muted d-block p-2 bg-light text-center">
                                {{CommonString.TABLE_NO_RECORD_FOUND}}
                            </mat-toolbar-row>
                        </mat-toolbar>
                    </div>
                    <div class="pt-4">
                        <button class="px-5 py-2 btn btn-primary" type="button"
                            [disabled]="!_hasSelectedRows(restrictedMembershipPackagesDS)"
                            (click)="_allowOrRestrictSelectedMembershipPackages(false)" *ngIf="!form.disabled">
                            <i class="fas fa-plus-circle mr-2"></i>Add Selected</button>
                    </div>
                </form>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>