import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIConstant } from '@fp/constant';
import { DataResult } from '@fp/models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpDAO } from './httpdao.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private http: HttpClient) { }

  getUniqueCityName(): Observable<DataResult<any>> {
    let httpdao: HttpDAO | null;
    httpdao = new HttpDAO(this.http);
    return httpdao.getActiveUniqueCityName(APIConstant.API_GET_ACTIVE_UNIQUE_CITY_NAMES);
  }

  getCityDetail(): Observable<DataResult<any>> {
    let httpdao: HttpDAO | null;
    httpdao = new HttpDAO(this.http);
    return httpdao.getActiveUniqueCityName(APIConstant.API_GET_ACTIVE_CITIES);
  }
}
