import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  DebitProcessingFilterData,
  DebitProcessingDates,
  DataResult,
  ResultModel,
  CreateDebitProcessing,
  AWSStartExecutionResponse,
  AWSDescribeExecutionResponse,
} from "@fp/models";
import { PaymentService, MessageBox, DataService, CommonService, MessageBoxButton } from "@fp/services";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { Observable, generate, interval } from "rxjs";
import { catchError } from "rxjs/operators";
import { DataSourceHelper, Utils } from "@fp/helpers";
import { RouterConstant , CommonString} from "@fp/constant";
import { Router, ActivatedRoute } from "@angular/router";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { GenericIdViewModel } from "@fp/models/GenericIdView.model";
import { FpProgressBarComponent } from "@fp/components/control/fp-progress-bar/fp-progress-bar.component";
import { AwsServiceService } from "@fp/services/aws-service.service";
import { StepFunctions, AWSStateMachineStates } from "@fp/enums/step-functions.enum";
import { DescribeStateMachineComponent } from "@fp/components/describe-state-machine/describe-state-machine.component";

@Component({
  selector: "app-debit-processing",
  templateUrl: "./debit-processing.component.html",
  styleUrls: ["./debit-processing.component.css"]
})
export class DebitProcessingComponent implements OnInit {
  requestForm = new UntypedFormGroup({
    debitProcessingDateFrom: new UntypedFormControl(""),
    debitProcessingDateTo: new UntypedFormControl(""),
    newPaymentProcessingDate: new UntypedFormControl("")
  });

  dataSource: MatTableDataSource<
    DebitProcessingFilterData
  > = new MatTableDataSource<DebitProcessingFilterData>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("progressBar") progressBar: FpProgressBarComponent;
  @ViewChild(DescribeStateMachineComponent, {static: true}) stateMachineDesc: DescribeStateMachineComponent;

  requestData = new DebitProcessingDates();
  debitProcessingDataEntry = new DebitProcessingFilterData();
  displayedColumns: string[];
  messageDebit: string;
  RouterConstant = RouterConstant;
  genericId = new GenericIdViewModel();
  processingDate: string;
  seletedCountry : string;
  countryTitle : string;
  createDebitProcessingData = new CreateDebitProcessing();
  todayDate: any;
  errorHandler: any;
  selectedFromDate: any;
  selectedToDate: any;
  isLoading = false;
  StepFunctions = StepFunctions;
  searchFinalizeText = '';
  @ViewChild('dppagi') dp_paginator: MatPaginator;
  helpText: string = '';

  constructor(
    private PaymentSvc: PaymentService,
    private dialog: MatDialog,
    private dataService: DataService,
    private awsService: AwsServiceService,
    private cmsrv: CommonService,
    private route: ActivatedRoute,
    private router: Router
  ) 
  { 
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    var headerLabel="";
    this.route.queryParams.subscribe(params => {
      var routedCountry = params['country'];

      if( !(routedCountry.toString().toLowerCase() ==  'australia' || routedCountry.toString().toLowerCase() ==  'newzealand')){
        this.router.navigate([RouterConstant.NAVIGATOR_PAGE_NOT_FOUND]);
      }

      if(routedCountry){
        if(routedCountry == CommonString.AUSTRALIA.toLowerCase()){
          this.countryTitle = 'AU';
          this.seletedCountry = CommonString.AUSTRALIA.toLowerCase();
          headerLabel=" Debit Processing (AU)";
          this.searchFinalizeText = 'ABA Finalised';
          this.helpText = 'ABA';         
        }else{
          this.countryTitle = 'NZ'
          this.seletedCountry = CommonString.NEWZEALAND.toLowerCase();
          headerLabel=" Debit Processing (NZ)";
          this.searchFinalizeText = 'GoCardLess DD Finalized';
          this.helpText = 'GoCardless DD';          
        }
      }
      else{
        this.router.navigate([RouterConstant.NAVIGATOR_PAGE_NOT_FOUND]);
      }
    });

    this.todayDate = this.setTodayDate();
    this.cmsrv.Header.title=headerLabel;
  }

  searchDebitProcessing() {
    this.displayedColumns = [
      "ProcessingDate",
      "DollarAmount",
      "ABAFinalised",
      "Edit",
      "PeriodClosed",
    ];
    this.requestData.DebitProcessingDateFrm = this.convertDateFormat(
      this.requestForm.value.debitProcessingDateFrom
    );
    this.requestData.DebitProcessingDateTo = this.convertDateFormat(
      this.requestForm.value.debitProcessingDateTo
    );
    this.requestData.Country = this.seletedCountry;
    this.isLoading = true;
    this.Invoke(
      this.PaymentSvc.getDebitProcessingFilterData(this.requestData),
      (result: DataResult<DebitProcessingFilterData[]>) => {
        console.log(result.Data);
        this.dataSource = new MatTableDataSource<DebitProcessingFilterData>(
          result.Data
        );
        this.dataSource.sortingDataAccessor =
          DataSourceHelper.localeLowerCaseSortingDataAccessor;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.dp_paginator;
        this.isLoading = false;
      }
    );

    document.getElementById("debitTable").style.display = "block";
  }

  feebotCheck() {
    var feebotCheckDateId = this.convertDateFormat(
      this.requestForm.value.newPaymentProcessingDate
    );
    var popUpMessage = "";
    var stillString = ""
    this.isLoading = true
    this.Invoke(
      this.PaymentSvc.getFeebotCompletion(feebotCheckDateId, this.seletedCountry),
      (result: DataResult<string[]>) => {
        this.isLoading = false;
        if(result.Data.length === 1) {
          popUpMessage = result.Data[0];
          if(result.Data[0].includes("no membership fees")) {
            stillString = "still ";
          }
        }
        else if (result.Data.length === 0 ) {
          popUpMessage = "Feebot didn't run overnight. Please check the bot logs."
          stillString = "still ";
        }
        else {
          popUpMessage = "Feebot ran multiple times in the last 24 hours with the below results:"
          for (var p = 0; p++; p < popUpMessage.length) {
            popUpMessage = popUpMessage + "<br/>" + result.Data[p];
          }
          stillString = "still ";
        }
        popUpMessage = popUpMessage + `<br/>Would you ${stillString}like to proceed with ${this.helpText} Generation?`
        MessageBox.ShowCustom(this.dialog, 'Feebot Status', 'Feebot Status',
        popUpMessage,
        MessageBoxButton.YesNo).subscribe(r => {
          if (r.result == DialogResult.Yes) {
            this.generateDebitProcessing();
          } else {
            this.dialog.closeAll();
          }
        });
      }
    )
  }

  generateDebitProcessing() {
    this.createDebitProcessingData.id = this.convertDateFormat(
      this.requestForm.value.newPaymentProcessingDate
    );
    this.createDebitProcessingData.modifiedby = this.cmsrv.GetUser();

    this.progressBar.Open("Generating New Debit Processing Date");
    this.progressBar.StartPercentTimer(5);
    this.Invoke(
      this.awsService.StartStepExecution(
        StepFunctions.CreateDebitProcessingEntry,
        this.createDebitProcessingData.id,
        this.createDebitProcessingData.modifiedby,
        this.seletedCountry
      ),
      (result: AWSStartExecutionResponse) => {
        this.progressBar.close_subject.next(true);
        if (result.executionArn === undefined || result.executionArn === "" || result.executionArn === null) {
          MessageBox.ShowError(
            this.dialog,
            "Something has gone wrong."
          );
        } else {
          MessageBox.ShowInfo(
            this.dialog,
            "The Debit Processing Generation process has been started. " +
            "You should receive an email within the next 30 minutes with " +
            `the generated ${this.helpText} files and Direct Debit Run Report attached as links.`
          );
          this.stateMachineDesc.stepMachineIsRunning = true;
          this.stateMachineDesc.executionArn = result.executionArn;
          this.stateMachineDesc.StartDescriptionTimer(10);
        }
      }
    );
  }

  periodCloseToggle(event, debitProcessingElement) {
    const headerString = "Payment Processing Period Closure";
    const bodyString =
      "You are about to close this payment period." +
      " Do you wish to continue?";
    if (event.target.id === "ClosePeriod") {
      this.genericId.id = debitProcessingElement.DebitProcessingID;
      MessageBox.ShowCancelContinue(
        this.dialog,
        headerString,
        bodyString
      ).subscribe(dialogResult => {
        if (dialogResult.result.toLowerCase() === DialogResult.Continue) {
          this.isLoading = true;
          this.Invoke(
            this.PaymentSvc.periodClosedEdit(this.genericId),
            (data: any) => {
              console.log(data);
              this.isLoading = false;
              this.searchDebitProcessing();
            }
          );
        }
      });
    }
  }

  periodOpenToggle(event, debitProcessingElement) {
    const headerString = "Payment Processing Period Closure";
    const bodyString =
      "You are about to open this payment period." +
      " Do you wish to continue?";
    if (event.target.id === "OpenPeriod") {
      this.genericId.id = debitProcessingElement.DebitProcessingID;
      MessageBox.ShowCancelContinue(
        this.dialog,
        headerString,
        bodyString
      ).subscribe(dialogResult => {
        if (dialogResult.result.toLowerCase() === DialogResult.Continue) {
          this.isLoading = true;
          this.Invoke(
            this.PaymentSvc.periodOpenedEdit(this.genericId),
            (data: any) => {
              console.log(data);
              this.isLoading = false;
              this.searchDebitProcessing();
            }
          );
        }
      });
    }
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError(e => {
          throw e;
        })
      )
      .subscribe(
        res => {
          handleResultCallback(res);
        },
        err => {
          this.errorHandler(err);
        }
      );
  }

  convertDateFormat(date) {
    const newDate = date.year + "-" + date.month + "-" + date.day;
    return newDate;
  }

  setTodayDate() {
    let todayDate = new Date();
    let todayYear = todayDate.getFullYear();
    let todayMonth = todayDate.getMonth() + 1;
    let today = todayDate.getDate();

    return { year: todayYear, month: todayMonth, day: today };
  }

  autoPopulateProcessingDate(processingDate: string, abaFinalised: string) {
    this.dataService.changeMessage(processingDate + "_" + abaFinalised + "_" + this.seletedCountry);
    this.isLoading = true;
  }

  dateRangeChange() {
    this.selectedFromDate = this.requestForm.value.debitProcessingDateFrom;
    this.selectedToDate = this.requestForm.value.debitProcessingDateTo;
  }

  /**
   * Triggered on a new DateFrom Selected.
   * If the DateTo is Null, and the first date of a month is selected,
   * sets the DateTo to be the last date of that Month.
   * Then triggers the Search Function.
   */
  public onDateFromSelected(): void {
    if (
      this.requestForm.value.debitProcessingDateFrom.day == 1 &&
      this.requestForm.value.debitProcessingDateTo == ""
    ) {
      var year = this.requestForm.value.debitProcessingDateFrom.year;
      var month = this.requestForm.value.debitProcessingDateFrom.month;
      this.requestForm.controls.debitProcessingDateTo.setValue({
        year: year,
        month: month,
        day: Utils.getLastDateOfMonthFromInts(year, month)
      });
    }
    this.dateRangeChange();
  }

  /**
   * Triggered when the DateTo value is selected.
   * Triggers the Search Function for Payment Requests.
   */
  public onDateToSelected(): void {
    this.dateRangeChange();
  }

  private isSmallWindow(size: number = 1150) {
    return window.innerWidth < size ? true : false;
  }

  searchButtonStyle() {
    return { "width": "8em", "margin-left": "11.5em" };
  }

  pppTableStyle() {
    if (this.isSmallWindow()) {
      return { "width": "46em" };
    } else {
      return { "width": "46em", "margin-left": "11.5em" };
    }
  }
  toDateStyle() {
    if (this.isSmallWindow(800)) {
      return { "display": "inline-block", "margin-left": "11.5em" };
    } else {
      return { "display": "inline-block" };
    }
  }
}
