import { ResultModel } from './resultmodel';
import { Country } from './country.model';
import { Postcode } from './postcodemodel';
import { State } from './state.model';
import { RegionNoteModel } from './region.note.model';

export class Region {
    RegionId: number;
    Name: string;
    Description: string;
    IsActive: boolean;
    Postcode: Postcode[];

    State: State;
    Country: Country;

    OpenForLargeEmployerId: number;
    OpenForSmallAdditionId: number;
    Under50EmployerId: number;
    EtaonOpening: string | Date | any;
    WaitListedEmployer: string;
    RegionNotes: RegionNoteModel[];
    ModifiedBy?: string;

}

export class CreateRegionResult extends ResultModel {
    RegionId: number;
}