import { ERole } from "@fp/enums/role.enum";
import { Component, Injector, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, Validators, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MemberFormBase } from "src/app/components/base/member-form-base";
import { CommonString } from "src/app/constant";
import { StringHelper } from "src/app/helpers";
import {
  DataResult,
  MemberMedicalAnswer,
  MemberMedicalQuestion,
} from "src/app/models";
import { MemberService } from "src/app/services/member.service";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { CommonService, MessageBox } from "@fp/services";

@Component({
  selector: "app-member-medical-questions",
  templateUrl: "./medical-questions.component.html",
  styleUrls: ["./medical-questions.component.css"],
  providers: [
    { provide: MemberFormBase, useExisting: MemberMedicalQuestionsComponent },
  ],
})
export class MemberMedicalQuestionsComponent extends MemberFormBase {
  timeStamp = new Date().toISOString().replace(/[-:\.]/g, "");
  medicalQuestions: MemberMedicalQuestion[] = [];
  answers: MemberMedicalAnswer[] = [];

  CommonString = CommonString;
  StringHelper = StringHelper;

  get MedicalAnswersCtrl() {
    return <UntypedFormArray>this.form.get("MedicalAnswers");
  }

  @ViewChild("medQuestionNotification") medQuestionNotification: TemplateRef<
    any
  >;

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      MedicalAnswers: fb.array([]),
    });
  }

  static getAnswerFormGroup(sltrole: string) {
    const fb = new UntypedFormBuilder();
    if (sltrole !== ERole.member && sltrole !== null) {
      return fb.group({
        MemberId: [0],
        MedicalQuestion: [{ MemberMedicalQuestionId: null, Question: null }],
        Answer: [null],
        MemberMedicalQuestionId: [0],
      });
    } else {
      return fb.group({
        MemberId: [0],
        MedicalQuestion: [{ MemberMedicalQuestionId: null, Question: null }],
        Answer: [null, Validators.required],
        MemberMedicalQuestionId: [0],
      });
    }
  }

  constructor(
    injector: Injector,
    private memberSvc: MemberService,
    private conSvc: CommonService,
    private modal: NgbModal
  ) {
    super(injector);
    this.form = MemberMedicalQuestionsComponent.getFormGroup();
  }

  //--------------------------

  ngAfterViewInit() {
    this.OnLoad();
  }

  OnLoad() {
    if (this.conSvc.GetMedicalQuestions()) {
      this.medicalQuestions = this.conSvc.GetMedicalQuestions();
      this.updateQuestionsAndAnswers();
      super.OnLoad();
      return;
    }
    this.Invoke(this.memberSvc.getMedicalQuestions(), {
      onSuccess: (result: DataResult<MemberMedicalQuestion[]>) => {
        if (result.Success) {
          this.medicalQuestions = result.Data;
          this.updateQuestionsAndAnswers();
        } else {
          this.HandleResponseError(result);
        }
        super.OnLoad();
      },
      onError: (err) => {
        MessageBox.ShowError(
          this.dialog,
          "Sorry, something went wrong. Let's try that again."
        ).subscribe((res) => {
          if (res.result.toLowerCase() === DialogResult.Ok) {
            window.location.reload();
          }
        });
        this._logger.error(err);
      },
    });
  }

  PatchValue(value, options) {
    const answers = <MemberMedicalAnswer[]>value["MedicalAnswers"] || [];
    if (answers.length === 0) {
      value["MedicalAnswers"] = answers;
    }
    this.answers = answers;
    if (!this.loaded) {
      /**
       * **REMARKS:**
       * In case this component loads on demand, temporarily populate the MedicalAnswer form controls
       * using the existing MedicalAnswers data.
       *
       * Upon `Load` event of this component, the MedicalAnswer controls will be re-populated with the
       * questions from remote service and patched with existing MedicalAnswers data again.
       */
      this.updateQuestionsAndAnswers();
    }
    super.PatchValue(value, options);
  }
  //--------------------------

  private updateQuestionsAndAnswers() {
    /**
     * Whenever a new FormControl added to the FormArray instance, the FormArray instance will be re-enabled if
     * the new FormControl instance is enabled by default. It is difficult to control the status from outside
     * manipulation, so the best is to keep the disabled status and re-disable the FormArray instance after
     * all child controls are added.
     */
    const disabled = this.MedicalAnswersCtrl.disabled;
    // Reset MedicalAnswers form control
    /**
     * At the current Angular's forms version (6.1.0), the FormArray.clear() method has not been implemented yet.
     * We manually clear the array of controls here. When it is implemented, uncomment the below code and
     * remove the if statement.
     */
    // this.MedicalAnswersCtrl.clear();
    if (this.MedicalAnswersCtrl.length > 0) {
      this.MedicalAnswersCtrl.controls.splice(0);
      this.MedicalAnswersCtrl.updateValueAndValidity({ emitEvent: false });
    }

    const fb = new UntypedFormBuilder();
    if (this.medicalQuestions && this.medicalQuestions.length > 0) {
      this.medicalQuestions.forEach((question) => {
        const sltrole = this.commonSvc.GetSelectedRole();
        if (sltrole !== ERole.member && sltrole !== null) {
          this.MedicalAnswersCtrl.push(
            fb.group({
              MemberId: [this.data.model.MemberId || 0],
              MedicalQuestion: [question],
              Answer: [null],
              MemberMedicalQuestionId: [question.MemberMedicalQuestionId],
            })
          );
        } else {
          this.MedicalAnswersCtrl.push(
            fb.group({
              MemberId: [this.data.model.MemberId || 0],
              MedicalQuestion: [question],
              Answer: [null, Validators.required],
              MemberMedicalQuestionId: [question.MemberMedicalQuestionId],
            })
          );
        }
      });
    } else {
      const sltrole = this.commonSvc.GetSelectedRole();
      this.answers.forEach((_item) => {
        this.MedicalAnswersCtrl.push(
          MemberMedicalQuestionsComponent.getAnswerFormGroup(sltrole)
        );
      });
    }
    if (disabled) {
      this.MedicalAnswersCtrl.disable({ emitEvent: false });
    } else {
      this.MedicalAnswersCtrl.enable({ emitEvent: false });
    }
    this.MedicalAnswersCtrl.patchValue(this.answers, { emitEvent: false });
  }

  displayNotification(e) {
    e.srcElement.blur();
    e.preventDefault();
    let showModal = true;
    this.MedicalAnswersCtrl.controls.forEach((ctrl) => {
      const val = ctrl.get("Answer").value;
      if (val == null) {
        showModal = false;
      }
    });
    //
    if (showModal) {
      this.modal
        .open(this.medQuestionNotification, {
          windowClass: "medical-question-noti-modal",
          backdropClass: "medical-question-noti-backdrop",
        })
        .result.then(
          (result) => {},
          (reason) => {}
        );
    }
    this.commonSvc.FormDataChange = true;
  }
}
