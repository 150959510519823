import { ERole } from "@fp/enums/role.enum";
import { WarmupAPIService } from "./services/warmup-api.service";
import { MatDialog } from "@angular/material/dialog";
import { APIConstant } from "./constant/apiconstant";
import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  AfterViewChecked,
  HostListener,
} from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { environment } from "../environments/environment";
import { MatSidenav } from "@angular/material/sidenav";
import { CommonService } from "./services/common.service";
import { MediaMatcher } from "@angular/cdk/layout";
import { NavbarComponent } from "./components/navbar-component/navbar.component";
import { RouterConstant } from "./constant/routerconstant";
import { JwtHelperService } from "@auth0/angular-jwt";
import { RolePermissionService } from "./services/role-permission.service";
import { UserIdleService } from "angular-user-idle";
import { MessageBox } from "./services/common-dialog.service";
import {
  ActivatedRoute,
  Router,
  NavigationError,
  NavigationEnd,
} from '@angular/router';
import { filter } from "rxjs/operators";
import { googleAnalytics } from "src/assets/scripts/GoogleAnalyticsScript";
import { map, mergeMap } from "rxjs/operators";
import {
  DatadogService,
  HttpDAO,
  MemberService,
  UserService,
} from './services';
import {
  AuthenticatorService,
  translations,
} from '@FitnessPassport/ui-angular';
import { AuthUser, fetchAuthSession, JWT } from 'aws-amplify/auth';
import { MemberContainer } from './models/member-container.model';
import { DialogResult } from './components/common-dialog/common-dialog.component';
import { HttpClient } from '@angular/common/http';
import { EMemberStatus, EMemberType } from './enums';
import { CommonMessage, CommonString, StorageKey } from './constant';
import { CommonConstants } from './constant/common-constants';
import { I18n, Hub } from 'aws-amplify/utils';
import { DefaultTexts } from '@FitnessPassport/ui';
I18n.putVocabularies(translations);
I18n.setLanguage('en');
I18n.putVocabularies(translations);
I18n.setLanguage('en');
I18n.putVocabularies(translations);
I18n.putVocabulariesForLanguage('en', {
  Code: 'Code',
  [DefaultTexts.WE_EMAILED]: 'Verify your email',
  [DefaultTexts.CODE_EMAILED]: 'A verification code has been sent to',
  [DefaultTexts.ENTER_CODE]: 'Enter your code',
  [DefaultTexts.CODE_ARRIVAL]: 'Enter the code to verify your email',
  'Confirm TOTP Code': '2 Factor Authentication code',
  'Setup TOTP': 'Step up your security! Enable 2 factor authentication.',
});

// Under certain circumstances, when navigating away before a component is properly loaded, the ngOnDestroy can be called before the ngOnInit.
// In the case of the Amplify Angular Authenticator component, ngOnInit creates a function that is called by ngOnDestroy. This throws an error which breaks navigation. To prevent this, we ignore this specific error.
const AMPLIFY_NG_ON_DESTROY_MESSAGE = 'this.clearUserAgent is not a function';
const isIgnorableNavError = (event: NavigationError) =>
  event.error?.message === AMPLIFY_NG_ON_DESTROY_MESSAGE;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  public title: string;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('navbar') navbar: NavbarComponent;
  public isLoadingResults = false;
  public isLoginSuccessDisplay = false;
  public selecteditem: number;
  public mobileQuery: MediaQueryList;
  public opened: boolean;
  public heightHeader: number;
  public isDisabled = true;

  private MouseNotMoveTimeout;
  private IamActive = false;

  public currentLogin = false;
  public isMemberSignup: boolean = false;
  public isConfirmAccountScreen: boolean = false;
  public isMemberMenuDisabled: boolean = false;
  public isPaymentsVisible: boolean = false;
  public isReactivationVisible: boolean = false;
  public isHideSideNav: boolean = false;

  private isOpenedTimeOut = false;
  private alreadystartwatchingidle = false;

  private _mobileQueryListener: () => void;

  private _interval;

  private httpdao: HttpDAO | null;

  RouterConstant = RouterConstant;

  public constructor(
    public authenticator: AuthenticatorService,
    private userSvc: UserService,
    private memberSvc: MemberService,
    private titleService: Title,
    public commonservice: CommonService,
    public rolepermission: RolePermissionService,
    private warmupservice: WarmupAPIService,
    private cdRef: ChangeDetectorRef,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private userIdle: UserIdleService,
    public dialog: MatDialog,
    public jwt: JwtHelperService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private meta: Meta,
    private datadogService: DatadogService,
  ) {
    this.title =
      environment.envName.toUpperCase() === 'PROD'
        ? 'FP Portal'
        : `FP Portal ${environment.envName}`;
    this.titleService.setTitle(this.title);
    this.mobileQuery = media.matchMedia('(max-width: 991px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationError) {
        if (isIgnorableNavError(event)) {
          this.router.navigate([event.target.url]);
        } else {
          this.router.navigate([RouterConstant.ROUTER_PAGE_NOT_FOUND]);
        }
      }
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const url = event['url'];
        if (
          url !== null &&
          url !== undefined &&
          url !== '' &&
          url.indexOf('null') < 0
        ) {
          googleAnalytics(url);
        }
      });
  }

  async ngOnInit() {
    this.commonservice.Navbar = this.navbar;
    this.commonservice.App = this;
    this.httpdao = new HttpDAO(this.http);

    if (Boolean(localStorage.getItem('signupFlag'))) {
      this.commonservice.App.isMemberMenuDisabled = true;
      this.commonservice.App.isPaymentsVisible = false;
    }
    if (Boolean(localStorage.getItem('reactivationFlag'))) {
      this.commonservice.App.isMemberMenuDisabled = true;
    }
    this.commonservice.App.isMemberSignup = Boolean(
      localStorage.getItem('signupFlag'),
    );
    this.commonservice.CurrentUTCServerDate = new Date();
    this.warmupservice.GetCurrentUATServerDate();
    this.MouseNotMovingDetect();
    if (window.innerWidth >= 1000) {
      this.opened = true;
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((event) => {
        if (event['title'] == 'no-index') {
          this.meta.addTag({ name: 'robots', content: 'noindex,nofollow' });
        }
        this.checkTokenExpired();
      });

    this.authenticator.subscribe(async ({ authStatus, user }) => {
      if (authStatus === 'unauthenticated') {
        this.datadogService.clearUser();
      }
      if (authStatus === 'authenticated' || authStatus === 'configuring') {
        this.currentLogin = true;
        const session = await this.commonservice.getCurrentAuthSession();
        if (user && session?.tokens?.idToken) {
          this.datadogService.setUser({id: user.userId});
          this.commonservice.setCurrentAuthSession(session);
          this._afterLogin(user, session.tokens.idToken);
        }
      }
    });

    Hub.listen('auth', (data) => {
      if(data.payload.event === "tokenRefresh_failure") {
        this.SignOut(false) // Clear session data and do not redirect
        MessageBox.ShowOKCustom(
          this.dialog,
          'Session Expired',
          'For security reasons, your session has timed out.<br/>Please log in again to continue.',
          'Login'
        ).subscribe(async () => {
          await this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]); // Redirect to login page after CTA selected
        })
      }
    }, "amplifyExpiredRefreshTokenListener");
  }

  private getFormattedDates() {
    const currentDate = new Date();
    const sevenYears = new Date(
      currentDate.getFullYear() - 7,
      currentDate.getMonth(),
      currentDate.getDate(),
    );
    const formattedSevenYearsAgo = sevenYears
      .toISOString()
      .replace('Z', '')
      .slice(0, 23);
    const oneYearAgo = new Date(
      currentDate.getFullYear() - 1,
      currentDate.getMonth(),
      currentDate.getDate(),
    );
    const formattedOneYearAgo = oneYearAgo
      .toISOString()
      .replace('Z', '')
      .slice(0, 23);

    return {
      formattedSevenYearsAgo,
      formattedOneYearAgo,
    };
  }

  private _loadUserData(res, redirect: string[]) {
    if (!res.Data.UserObject) {
      MessageBox.ShowCustom(
        this.dialog,
        'Login Failed',
        '',
        CommonMessage.ACCOUNT_IS_BANNED,
      ).subscribe(() => {
        this.SignOut();
      });
      return;
    }
    let loginContainer = new MemberContainer();
    let { formattedSevenYearsAgo, formattedOneYearAgo } =
      this.getFormattedDates();
    loginContainer.User = this.commonservice.E_FP_AES256(
      JSON.stringify(res.Data.UserObject),
    );
    if (res.Data.UserObject.Country) {
      this.commonservice.setUserCountry(res.Data.UserObject.Country);
    }
    this.httpdao!.getSingleData(
      APIConstant.API_GET_ALL_PRIVILEGES_V2,
    ).subscribe((getPrivileges) => {
      loginContainer.Privileges = getPrivileges;
      this.memberSvc
        .getByUserForMember(res.Data.UserObject.UserId)
        .subscribe(async (getMember) => {
          this.rolepermission.RolesObject =
            this.commonservice.GetUserRoleByUserName();
          if (this.rolepermission.RolesObject.length > 0) {
            this.rolepermission.SelectedRole =
              this.rolepermission.RolesObject[0].Name;
          }
          if (
            this.rolepermission.SelectedRole == 'Member' &&
            getMember.Data &&
            getMember.Data.MemberStatusId
          ) {
            if (
              (getMember.Data.MemberStatusId == EMemberStatus.Inactive &&
                getMember.Data.MemberTypeId != EMemberType.Primary) ||
              getMember.Data.MemberStatusId == EMemberStatus.Banned
            ) {
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');
              MessageBox.ShowCustom(
                this.dialog,
                'Login Failed',
                '',
                CommonMessage.ACCOUNT_IS_BANNED,
              ).subscribe(() => {
                this.SignOut();
              });
              return;
            } else if (
              getMember.Data.RecentCancellationDate &&
              getMember.Data.MemberStatusId == EMemberStatus.Inactive &&
              new Date(getMember.Data.RecentCancellationDate) <
                new Date(formattedSevenYearsAgo)
            ) {
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');
              MessageBox.ShowCustom(
                this.dialog,
                'Login Failed',
                '',
                CommonMessage.ACCOUNT_IS_BANNED,
              ).subscribe(() => {
                this.SignOut();
              });
              return;
            } else if (
              getMember.Data.MemberStatusId == EMemberStatus.Inactive &&
              getMember.Data.MemberTypeId == EMemberType.Primary &&
              getMember.Data.RecentCancellationDate &&
              new Date(getMember.Data.RecentCancellationDate) >
                new Date(formattedOneYearAgo)
            ) {
              this.commonservice.App.isMemberMenuDisabled = true;
              this.commonservice.App.isPaymentsVisible = true;
              this.commonservice.App.isReactivationVisible = true;
            } else if (
              getMember.Data.RecentCancellationDate &&
              getMember.Data.MemberStatusId == EMemberStatus.Inactive &&
              new Date(getMember.Data.RecentCancellationDate) >
                new Date(formattedSevenYearsAgo) &&
              new Date(getMember.Data.RecentCancellationDate) <
                new Date(formattedOneYearAgo)
            ) {
              this.commonservice.App.isMemberMenuDisabled = true;
              this.commonservice.App.isPaymentsVisible = true;
              this.commonservice.App.isReactivationVisible = false;
            }
          }
          let memberEncrypt = this.commonservice.E_FP_AES256(
            JSON.stringify(getMember),
          );
          //Admins / staff members don't have card
          if (getMember.Data && !getMember.Data.MemberIdPrimary) {
            if (getMember.Data.MemberCard) {
              this.commonservice.setCardForMemberPortal(
                getMember.Data.MemberCard.Data.Results,
              );
            }
            if (getMember.Data.MembershipSuspension) {
              this.commonservice.SetMemSuspension(
                getMember.Data.MembershipSuspension.Data,
              );
            }
            if (getMember.Data.FamilyObject) {
              this.commonservice.SetMemFamObj(getMember.Data.FamilyObject);
            }
          }

          if (
            getMember.Data &&
            getMember.Data.MemberStatusId == EMemberStatus.Inactive &&
            getMember.Data.MemberTypeId == EMemberType.Primary &&
            getMember.Data.RecentCancellationDate &&
            new Date(getMember.Data.RecentCancellationDate) >
              new Date(formattedOneYearAgo)
          ) {
            localStorage.setItem('reactivationFlag', 'true');
            localStorage.setItem('allowActivationFlag', 'true');
          }

          if (
            getMember.Data &&
            getMember.Data.MemberStatusId == EMemberStatus.SurveyCandidate
          ) {
            this.commonservice.App.isMemberMenuDisabled = true;
            this.commonservice.App.opened = true;
            this.commonservice.MenuForMember = true;
            this.commonservice.App.isPaymentsVisible = false;

            if (
              getMember.Data.Employer.State.StateId ==
              CommonConstants.NewZealandStateId
            ) {
              this.commonservice.setUserCountry(CommonString.NEWZEALAND);
            } else {
              this.commonservice.setUserCountry(CommonString.AUSTRALIA);
            }
          }

          loginContainer.Member = memberEncrypt;
          const loginData = JSON.stringify(loginContainer);
          localStorage.setItem(StorageKey.CONTAINER_OBJECT, loginData);
          this.commonservice.SetUserObject(res.Data.UserObject);
          this.commonservice.SetTermsConditions(res.Data.TermsAndConditions);
          this.commonservice.SetSecondaryTermsConditions(
            res.Data.TermsAndConditionsSecondary,
          );
          this.commonservice.SetEmployers(res.Data.employers);
          this.commonservice.SetMedicalQuestions(res.Data.MedicalQuestions);

          // Only redirect if not in dashboard or edit user details to prevent loading multiple times
          // When resuming a session (I.E: page reload), we need to leave the navigation as it is
          // When coming from a login (IE: forgot password, signup or login page),
          // we need to redirect to the dashboard or the membership registration
          if ( this.isLoginOrSignup()) {
            await this.router.navigate(redirect);
          }
        });
    });
  }

  private isLoginOrSignup() {
    return this.router.url.startsWith(`/${RouterConstant.ROUTER_LOGIN}`)
      || this.router.url.startsWith(`/${RouterConstant.ROUTER_SIGNUP}`);
  }

  private _createMember(uname: string, idToken: JWT) {
    // Create member then redirect to continue member signup
    const email = idToken.payload.email as string;
    const firstName = (idToken.payload.given_name as string) ?? '';
    const lastName = (idToken.payload.family_name as string) ?? '';

    this.memberSvc
      .signupMemberWithCognitoToken({
        FirstName: firstName,
        LastName: lastName,
        PrimaryEmail: email,
        MobileNumber: '',
        User: {
          UserName: uname,
          EmailAddress: email,
          FirstName: firstName,
          LastName: lastName,
          MobileNumber: '',
        },
      })
      .subscribe((res) => {
        this.userSvc.getByUsernameFirstLoginV2(uname).subscribe((res) => {
          this._loadUserData(res, [
            RouterConstant.ROUTER_CONTINUE_MEMBER_SIGNUP,
          ]);
        });
      });
  }

  private _afterLogin(user: AuthUser, idToken: JWT) {
    const uname = user.username;
    this.userSvc.getByUsernameFirstLoginV2(uname).subscribe((res) => {
      if (res.Data.IsBlockLoginDependent) {
        MessageBox.ShowOKCustom(
          this.dialog,
          'Error',
          "<div style='text-align: center'>Access Denied</div>",
          'Take me back',
        ).subscribe((res) => {
          if (res.result.toLowerCase() === DialogResult.Ok) {
            this.rolepermission.RolesObject = [];
            this.commonservice.SignOut();
          }
        });
        return;
      }
      // If no user, create a new user
      if (!res.Data.UserObject) {
        this._createMember(uname, idToken);
        return;
      }

      if (
        (idToken.payload.phone_number === undefined ||
          !idToken.payload?.phone_number_verified) &&
          idToken.payload['cognito:groups'] != undefined && 
        (idToken.payload['cognito:groups'] as Array<string>).includes('Member')
      ) {
        this._loadUserData(res, [
          RouterConstant.NAVIGATOR_MEMBER_SECURITY_PREFERENCES,
        ]);
      } else {
        this._loadUserData(res, [RouterConstant.ROUTER_DASHBOARD]);
      }
    });
  }

  public toggleSidenav() {
    this.sidenav.toggle();
  }

  public openSidenav() {
    this.sidenav.open();
  }

  clickItem(selectedNumbr) {
    this.selecteditem = selectedNumbr;
    if (this.mobileQuery.matches) {
      this.opened = false;
    }
    this.navigateMember(selectedNumbr);
  }

  navigateMember(selectedNumbr: number) {
    if (selectedNumbr == 9) {
      localStorage.setItem(
        'selectedMenuMember',
        RouterConstant.NAVIGATOR_MEMBER_DETAILS,
      );
    } else if (selectedNumbr == 10) {
      localStorage.setItem(
        'selectedMenuMember',
        RouterConstant.NAVIGATOR_MEMBER_TRANSFER,
      );
    } else if (selectedNumbr == 11) {
      localStorage.setItem(
        'selectedMenuMember',
        RouterConstant.NAVIGATOR_MEMBERSHIP_CARDS,
      );
    } else if (selectedNumbr == 13) {
      localStorage.setItem(
        'selectedMenuMember',
        RouterConstant.NAVIGATOR_SUSPEND_MEMBERSHIP,
      );
    } else if (selectedNumbr == 14) {
      localStorage.setItem(
        'selectedMenuMember',
        RouterConstant.NAVIGATOR_MEMBER_PAYMENTS_HISTORY,
      );
    } else if (selectedNumbr == 15) {
      localStorage.setItem(
        'selectedMenuMember',
        RouterConstant.NAVIGATOR_MEMBER_NOTIFICATIONS,
      );
    } else if (selectedNumbr == 16) {
      localStorage.setItem(
        'selectedMenuMember',
        RouterConstant.NAVIGATOR_MEMBER_CONTRACTS,
      );
    } else if (selectedNumbr == 17) {
      localStorage.setItem(
        'selectedMenuMember',
        RouterConstant.NAVIGATOR_MEMBER_UPDATE_PAYMENTS_DETAILS,
      );
    }
  }

  ngAfterViewChecked() {
    if (this.mobileQuery.matches) {
      this.heightHeader = this.commonservice.Header.heightHeader;
    }
    this.cdRef.detectChanges();
    const user = this.commonservice.GetUser();
    if (
      user != undefined &&
      user != null &&
      this.alreadystartwatchingidle == false
    ) {
      this.WatchUserIdle();
    }
    this.commonservice.SetAutoCompleteOff();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  public async checkTokenExpired() {
    try {
      const accessToken = this.commonservice.GetAccessTokenDecrypt();
      const expirationOffsetSeconds = 900; //15 Minutes
      const accessTokenExpired = this.jwt.isTokenExpired(accessToken, expirationOffsetSeconds); // Considers token expired if expiration date is within offset window

      if (accessToken !== null && accessTokenExpired) {
        const forceRefresh = true;
        const session = await this.commonservice.getCurrentAuthSession(forceRefresh)
        this.commonservice.setCurrentAuthSession(session);
      } else {
        const c_url = document.URL.toLocaleLowerCase();
        const arr = c_url.split('/');
        if (
          accessToken !== null &&
          c_url.indexOf('/dashboard/') == -1 &&
          arr.length >= 4 &&
          arr[3].trim() === ''
        ) {
          await this.router.navigate([RouterConstant.ROUTER_DASHBOARD]);
        } else if (
          accessToken !== null &&
          c_url.indexOf('/dashboard/') == -1 &&
          arr.length >= 4 &&
          arr[3].trim() === 'login'
        ) {
          await this.router.navigate([RouterConstant.ROUTER_DASHBOARD]);
        }
        if (
          (accessToken === null || accessToken === undefined) &&
          arr.length >= 4 &&
          arr[3].trim() === ''
        ) {
          await this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  private SignOut(redirect = true) {
    clearInterval(this._interval);
    clearInterval(this.MouseNotMoveTimeout);
    this.rolepermission.RolesObject = [];
    this.commonservice.SignOut(redirect);
  }

  private MouseNotMovingDetect() {
    this.MouseNotMoveTimeout = setInterval(() => {
      localStorage.setItem('WindowActive', 'false');
      this.IamActive = false;
    }, APIConstant.MOUSE_NOT_MOVING_TIMEOUT);
  }

  private IsLoggedOut(): boolean {
    const user = this.commonservice.GetUser();
    if (user == undefined || user == null) {
      return true;
    } else {
      return false;
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    this.IamActive = true;
    clearInterval(this.MouseNotMoveTimeout);
    localStorage.setItem('WindowActive', 'true');
    this.MouseNotMovingDetect();
  }

  @HostListener('document:click', ['$event'])
  onMouseClick(e) {
    this.IamActive = true;
    clearInterval(this.MouseNotMoveTimeout);
    localStorage.setItem('WindowActive', 'true');
    this.MouseNotMovingDetect();
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(e) {
    this.IamActive = true;
    clearInterval(this.MouseNotMoveTimeout);
    localStorage.setItem('WindowActive', 'true');
    this.MouseNotMovingDetect();
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onMouseScroll(event) {
    this.IamActive = true;
    clearInterval(this.MouseNotMoveTimeout);
    localStorage.setItem('WindowActive', 'true');
    this.MouseNotMovingDetect();
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    localStorage.setItem('WindowActive', 'false');
    this.IamActive = false;
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    localStorage.setItem('WindowActive', 'true');
    this.IamActive = true;
  }

  private WatchUserIdle() {
    // Start watching for user inactivity.
    this.userIdle.startWatching();
    this.alreadystartwatchingidle = true;

    this._interval = setInterval(() => {
      //Check if another tab logged out, then I also log out.
      if (this.IsLoggedOut()) {
        if (this.userIdle != undefined && this.userIdle != null) {
          this.userIdle.stopWatching();
          this.userIdle = null;
        }
        this.SignOut();
      } //End

      if (
        localStorage.getItem('WindowActive') == 'true' &&
        this.IamActive == false
      ) {
        this.userIdle.resetTimer();
        this.userIdle.stopWatching();
        this.userIdle.startWatching();
      }
    }, 200);

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => {
      let countdown = APIConstant.TIMEOUT_CONFIRM_STAY_LOGIN;
      if (
        count == 1 &&
        this.isOpenedTimeOut == false &&
        localStorage.getItem('WindowActive') == 'false'
      ) {
        this.isOpenedTimeOut = true;
        const ret = MessageBox.ShowTimeOut(
          this.dialog,
          'Your session is about to expire.' +
            ' Please click on OK button to stay logged in.',
        ).subscribe((result) => {
          if (this.userIdle != null) {
            this.userIdle.resetTimer();
          }
          this.isOpenedTimeOut = false;
          MessageBox.TimeOutDialog.countdown = '';
        });
      }
      if (countdown > 1) {
        countdown = APIConstant.TIMEOUT_CONFIRM_STAY_LOGIN - count;
        const min = Math.floor(countdown / 60);
        const second = countdown % 60;
        const minsec =
          ('00' + min.toString()).slice(-2) +
          ':' +
          ('00' + second.toString()).slice(-2);
        if (MessageBox.TimeOutDialog) {
          MessageBox.TimeOutDialog.countdown = minsec.toString();
          MessageBox.TimeOutDialog.information =
            'Your session is about to expire.' +
            ' Please click on OK button to stay logged in.';
          if (
            localStorage.getItem('WindowActive') == 'true' &&
            this.IamActive == false
          ) {
            this.userIdle.resetTimer();
            MessageBox.TimeOutDialog.countdown = '';
            MessageBox.TimeOutDialog.btnOK_Click();
          }
        }
      }
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      if (MessageBox.TimeOutDialog) {
        MessageBox.TimeOutDialog.countdown = '';
        MessageBox.TimeOutDialog.btnOK_Click();
      }
      this.userIdle.stopWatching();
      this.userIdle = null;
      this.SignOut();
    });
  }

  matchActiveRoutes(routes: string[]) {
    return (
      this.route.routeConfig && routes.indexOf(this.route.routeConfig.path) > -1
    );
  }

  public IsEFOrFSM() {
    const slrole = this.commonservice.GetSelectedRole();
    return (
      slrole === ERole.Employer_Facilitator ||
      slrole === ERole.Facility_Staff_Member
    );
  }

  public enableSideNav() {
    this.isHideSideNav = Boolean(false);
  }

  public disableSideNav() {
    this.isHideSideNav = Boolean(true);
  }
}
