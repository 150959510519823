import { Component, Injector, ViewChild, AfterViewChecked } from '@angular/core';
import { FacilityFormBase, FPFormBaseComponent } from 'src/app/components/base';
import { UntypedFormBuilder } from '@angular/forms';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PostPaymentRequest, DataResult, SearchPaymentRequest, ResultModel } from "@fp/models";
import { CommonString } from "@fp/constant";
import { FacilityService, PaymentService } from "@fp/services";
import { ActivatedRoute } from "@angular/router";
import { DataSourceHelper } from "@fp/helpers";
import { AddFacilityComponent } from '../addfacility.component';
import { MessageBox, MessageBoxButton } from 'src/app/services';
import { PaymentRequestNotesModel } from '@fp/models/edit-payment-request.model';
import { PrNotesComponent } from './pr-notes/pr-notes.component';

@Component({
    selector: 'app-facility-visits-payment',
    templateUrl: './visits-payment.component.html',
    styleUrls: ['./visits-payment.component.css'],
    providers: [
        FPFormBaseComponent.provideExisting(FacilityVisitsPaymentComponent),
        FacilityFormBase.provideExisting(FacilityVisitsPaymentComponent)]
})
export class FacilityVisitsPaymentComponent extends FacilityFormBase implements AfterViewChecked {
    @ViewChild(MatSort) sort: MatSort;
    isLoading = false;
    displayedColumns: string[] = [
        "Service",
        "DateCreated",
        "VisitCount",
        "DollarAmount",
        "PRStatus",
        "PaymentCalculationMethod",
        "Download",
        "PaymentRequestNotes",
    ];

    paymentLogDataSource: MatTableDataSource<PostPaymentRequest> = new MatTableDataSource<PostPaymentRequest>([]);
    dataSource = [];
    CommonString = CommonString;
    paymentRequestData = new SearchPaymentRequest();
    @ViewChild('paymentlogpagi') payment_paginator: MatPaginator;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            PaymentLogs: [[]]
        });
    }

    OnLoad() {
        this.search();
    }

    LoadComplete() {
        super.LoadComplete();
        setTimeout(() => {
            this.getControl('PaymentLogs').valueChanges.subscribe(value => {
                this.paymentLogDataSource.data = value;
            });
        });
    }

    ngAfterViewChecked() {
        // const pr = <AddFacilityComponent>this.parent;
        // if (pr.stepper.selectedIndex === 3) {
        //     this.changeDetectorRef.detectChanges();
        // }
    }

    constructor(
        injector: Injector,
        private facilityService: FacilityService,
        private _route: ActivatedRoute,
        private PaymentSvc: PaymentService
    ) { super(injector); }

    private search() {
        try {
            this.isLoading = true;
            this.facilityService.getFacilityPaymentLogs(Number(this._route.snapshot.paramMap.get("id")))
                .subscribe((edata: string) => {
                    const dectypted_data = this.commonSvc.D_FP_ResponseData(edata);
                    const rdata = JSON.parse(dectypted_data);
                    if (!rdata) {
                        this.isLoading = false;
                        return;
                    }
                    this.dataSource = rdata.Data;
                    this.paymentLogDataSource = new MatTableDataSource<PostPaymentRequest>(this.dataSource);
                    this.paymentLogDataSource.sortingDataAccessor = DataSourceHelper.localeLowerCaseSortingDataAccessor;
                    this.paymentLogDataSource.sort = this.sort;
                    this.getControl('PaymentLogs').setValue(this.dataSource, { emitEvent: false });
                    this.isLoading = false;
                    this.paymentLogDataSource.paginator = this.payment_paginator;
                }, (err) => { console.log(err), this.isLoading = false; }, () => { });
        }
        catch {
            // Do Nothing
        }
    }

    downloadAttachements(event) {
        console.log("Event of download: ", event);
        var allUuidsAppended: string[] = []
        if (event.InvoiceAttachment != "") {
            allUuidsAppended.push("\"" + event.InvoiceAttachment + "\""); //There will always be just one UUID for invoice
        }

        if (event.AdditionalAttachement != null) {
            var additionalAttachments = event.AdditionalAttachement.split(","); //Double splits to split the joined UUIDs e.g case- "file1,file2","file3"
            for (let i = 0; i < additionalAttachments.length; i++) {
                if (additionalAttachments[i] != "") {
                    var singleUuid = "\"" + additionalAttachments[i] + "\"";
                    allUuidsAppended.push(singleUuid);
                }
            }
        }

        this.paymentRequestData.Uuids = allUuidsAppended;
        this.Invoke(
            this.PaymentSvc.getPreSignedURL(this.paymentRequestData),
            {
                onSuccess:
                    (data: ResultModel) => {
                        for (let i = 0; i < allUuidsAppended.length; i++) {
                            const fileLocation = data["Data"][i];
                            window.open(fileLocation, "_blank");
                        }
                    }
            });
    }

    toggleAccordian(event) {
        this.paymentLogDataSource.sortingDataAccessor = DataSourceHelper.localeLowerCaseSortingDataAccessor;
        this.paymentLogDataSource.sort = this.sort;
        this.paymentLogDataSource.paginator = this.payment_paginator;
    }

    openNotesDialog(payment: PostPaymentRequest) {
        this.dialog.open(PrNotesComponent, {
            height: "550px",
            width: "800px",
            data: { paymentReqNotes: payment.PaymentRequestNotes, service: payment.Service}
          });
    }

}

