import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, AbstractControl } from "@angular/forms";
import { NgbDatepickerConfig } from "@ng-bootstrap/ng-bootstrap";
import { DataService, MemberService, CommonService, MembershipService, MessageBox } from "@fp/services";
import { MembershipLedgerEntry, ResultModel, DataResult } from "@fp/models";
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs";
import { DebitCreditReasons } from "@fp/models/debit-credit-reasons.model";
import { ManipulateSysParamsService } from "@fp/services/manipulate-sys-params.service";
import { SystemParameters } from "@fp/models/system-parameters.model";
import { RolePermissionService } from "@fp/services/role-permission.service";

@Component({
  selector: "app-manually-add-debit-credit-box",
  templateUrl: "./manually-add-debit-credit-box.component.html",
  styleUrls: ["./manually-add-debit-credit-box.component.css"]
})
export class ManuallyAddDebitCreditBoxComponent implements OnInit {
  @Input() mobileNumber: string;

  ledgerData: MembershipLedgerEntry = {
    MemberNumber: "",
    PostDate: "",
    PostedBy: "",
    Amount: 0,
    Description: "",
    ModifiedBy:""
  };

  readledgerData: MembershipLedgerEntry = {
    MemberNumber: ""
  };

  minDate = undefined;
  sharedMessage: string;
  messageLedger: any;
  ledgerRows: any;
  dataSource: any;
  @ViewChild(MatSort) sort: MatSort;
  readonly changeDetectorRef: ChangeDetectorRef;
  displayedColumns: string[];
  descriptionRequired: boolean;
  isLoading = false;
  loaTooLow: boolean = false;
  loaTooLowMessage: string = "";

  constructor(
    public dialogRef: MatDialogRef<ManuallyAddDebitCreditBoxComponent>,
    private dialog: MatDialog,
    private shareData: DataService,
    private formBuilder: UntypedFormBuilder,
    private memberSvc: MemberService,
    private membershipSvc: MembershipService,
    private params: ManipulateSysParamsService,
    private cmsrv: CommonService,
    config: NgbDatepickerConfig
  ) {
    const current = new Date();
    config.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    config.outsideDays = "hidden";
  }

  postedDate: any;
  debitCreditForm: UntypedFormGroup;

  membershipNumber = "test";
  currentDate = new Date();
  selectedArray: any;
  debitCreditReasons: DebitCreditReasons[] = [];
  debitArray: string[] = [];
  creditArray: string[] = [];

  ngOnInit() {
    this.shareData.currentMessage.subscribe(
      sharedMessage => (this.sharedMessage = sharedMessage)
    );

    this.debitCreditForm = this.formBuilder.group({
      postDate: ["", Validators.required],
      selectPaymentType: ["", Validators.required],
      amount: ["", Validators.required],
      reason: ["", Validators.required],
      description: ["", Validators.required]
    });
    
    const positiveValidator = (ctrl: AbstractControl) => {
      let currentAmount = parseFloat(ctrl.value);
      return ctrl.value && currentAmount < 0 ? { notPositive: true } : null;
    };
    this.debitCreditForm.get('amount').setValidators([positiveValidator]);
    console.log(this.debitCreditForm.get('amount'));

    this.Invoke(
      this.params.getSystemParameters(),
      (result: DataResult<SystemParameters[]>) => {
        let role = this.cmsrv.GetSelectedRole();
        let params = result.Data;
        let param: SystemParameters;
        if(role === "Operation_Level_1") {
          param = params.find(e => e.ParameterName === "Operation L1 LOA");
        }
        if(role === "Operation_Level_2") {
          param = params.find(e => e.ParameterName === "Operation L2 LOA");
        }
        if(role === "Operation_Level_3") {
          param = params.find(e => e.ParameterName === "Operation L3 LOA");
        }
        if(role === "Operation_Level_1_ReadOnly") {
          param = params.find(e => e.ParameterName === "Operation L4 LOA");
        }
        if(role === "Finance_Level_1") {
          param = params.find(e => e.ParameterName === "Finance L1 LOA (Debit/Credit Insertion)");
        }
        if(role === "Finance_Level_2") {
          param = params.find(e => e.ParameterName === "Finance L2 LOA (Debit/Credit Insertion)");
        }
        if(role === "Finance_Level_3") {
          param = params.find(e => e.ParameterName === "Finance L3 LOA (Debit/Credit Insertion)");
        }
        console.log(param);
        if(param !== undefined) {
          let valueCap = parseInt(param.Value);
          const valueValidator = (ctrl: AbstractControl) => {
            let currentAmount = parseInt(ctrl.value);
            return ctrl.value && currentAmount > valueCap ? { overAmount: true } : null;
          };
          this.debitCreditForm.get('amount').setValidators([valueValidator, positiveValidator]);
        }
      }
    );

    this.loadDebitCreditReasons();
  }

  //Load debit credit reasons from the backend (DB)
  loadDebitCreditReasons() {
    this.Invoke(
      this.membershipSvc.getDebitCreditReasons(),
      (result: DataResult<DebitCreditReasons[]>) => {
        this.debitCreditReasons = result.Data;

        //Create debit and credit reasons array based on applies to property
        this.debitCreditReasons.forEach(reason => {
          if (reason.AppliesToDebit === true) {
            this.debitArray.push(reason.Reason);
          }

          if (reason.AppliesToCredit === true) {
            this.creditArray.push(reason.Reason)
          }
        });
      }
    );
  }

  // selectedOption is DOM event
  valueChanged(selectedOption) {
    // When credit/debit is selected for reason
    if (selectedOption == 0) {
      this.selectedArray = [];
      this.selectedArray = this.creditArray;
    } else if (selectedOption == 1) {
      this.selectedArray = [];
      this.selectedArray = this.debitArray;
    }

    //When 'Other' reason is selected & description is empty make form invalid - disable submit button
    if (this.debitCreditForm.value.reason === "Other") {
      if (this.debitCreditForm.value.description === null || this.debitCreditForm.value.description === "") {
        this.debitCreditForm.setErrors({'invalid': true});
      }
    }

    //When amount is empty make form invalid - disable submit button
    if (this.debitCreditForm.value.amount === null) {
      this.debitCreditForm.setErrors({'invalid': true});
    }

    //When user removes description and payment type is selected - ensure the list of reason remains the same
    if (this.debitCreditForm.value.selectPaymentType != null && (this.debitCreditForm.value.description === null || this.debitCreditForm.value.description === "")) {
      if (this.debitCreditForm.value.selectPaymentType == 0) {
        this.selectedArray = [];
        this.selectedArray = this.creditArray;
      } else if (this.debitCreditForm.value.selectPaymentType == 1) {
        this.selectedArray = [];
        this.selectedArray = this.debitArray;
      }
    }
  }

  convertDateFormat(date) {
    let newDate = date.year + "-" + date.month + "-" + date.day;
    return newDate;
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError(e => {
          throw e;
        })
      )
      .subscribe(res => {
        handleResultCallback(res);
      });
  }

  submitDebitCreditLog() {
    this.isLoading = true;
    this.ledgerData.MemberNumber = this.sharedMessage;
    this.ledgerData.PostDate = this.convertDateFormat(
      this.debitCreditForm.value.postDate
    );
    this.ledgerData.PostedBy = this.cmsrv.GetUser();
    //If selected payment is "Debit", make the amount negative
    if (this.debitCreditForm.value.selectPaymentType == 1) {
      var creditedAmount = this.debitCreditForm.value.amount * -1;
      this.ledgerData.Amount = creditedAmount;
    } else {
      this.ledgerData.Amount = this.debitCreditForm.value.amount;
    }

    let desc: string = this.debitCreditForm.value.description;
    desc = desc.trim();
    
    if (desc !== '' || desc !== null) {
      this.ledgerData.Description = desc;
    }

    this.ledgerData.Reason = this.debitCreditForm.value.reason;   
    this.ledgerData.ModifiedBy = this.cmsrv.GetUser();

    this.Invoke(
      this.memberSvc.updateMembershipLedger(this.ledgerData),
      (data: ResultModel) => {
        if (data.Success) {
          this.messageLedger = data.Message;
          this.isLoading = false;
          this.loaTooLow = false;
          this.close();
        } else {
          this.isLoading = false;
          this.loaTooLow = true;
          this.loaTooLowMessage = data.Message;
        }
      }
    );
  }

  close() {
    this.dialogRef.close();
  }
}
