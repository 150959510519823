import { SignupComponent } from "./../components/signup-component/signup/signup.component";
import { UserRegisterComponent } from "./../components/signup-component/user-register/user-register.component";
import { MatDialogRef } from "@angular/material/dialog";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  CommonDialogComponent,
  DialogResult,
} from "../components/common-dialog/common-dialog.component";
import { Observable } from "rxjs";
import { CommonDialogNewComponent } from "@fp/components/common-dialog-new/common-dialog-new.component";
import { SurveySignupComponent } from "@fp/components/signup-component/survey-signup/survey-signup.component";

export class MessageBox {
  public static TimeOutDialog: CommonDialogComponent;

  static ShowCustom(
    dialog: MatDialog,
    message,
    title = "Alert",
    information = "",
    button = 0,
    allow_outside_click = false,
    style = 0,
    width = "400px"
  ): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: message,
        information: information,
        button: button || 0,
        style: style || 0,
        allow_outside_click: allow_outside_click || false,
      },
      width: width,
    });
    return dialogRef.afterClosed();
  }

  static ShowOKCustom(
    dialog: MatDialog,
    message,
    information = "",
    buttonlabel = "OK",
    width = "400px"
  ): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: message,
        information: information,
        button: MessageBoxButton.OKCustom,
        style: 0,
        allow_outside_click: false,
        buttonlabel: buttonlabel,
      },
      width: width,
    });
    return dialogRef.afterClosed();
  }

  static ShowError(dialog, information): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: "Error",
        information: information,
        button: MessageBoxButton.Ok,
        style: 0,
        allow_outside_click: true,
      },
      width: "400px",
    });
    return dialogRef.afterClosed();
  }

  static ShowErrorCustomerHeader(
    dialog,
    information,
    header
  ): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: header,
        information: information,
        button: MessageBoxButton.Ok,
        style: 0,
        allow_outside_click: true,
      },
      width: "400px",
    });
    return dialogRef.afterClosed();
  }

  static ShowInfo(dialog, information): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: "Information",
        information: information,
        button: MessageBoxButton.Ok,
        style: 0,
        allow_outside_click: false,
      },
      width: "400px",
    });
    return dialogRef.afterClosed();
  }

  static MemberPackageChange(dialog, information): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: "", //no header required here
        information: information,
        button: MessageBoxButton.Ok,
        style: 0,
        allow_outside_click: false,
      },
      width: "400px",
    });
    return dialogRef.afterClosed();
  }

  static ShowTimeOut(dialog, information): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: "Timing Out",
        information: information,
        button: MessageBoxButton.Ok,
        style: 0,
        allow_outside_click: false,
      },
      width: "400px",
    });
    this.TimeOutDialog = dialogRef.componentInstance;
    return dialogRef.afterClosed();
  }

  static ShowYesNo(dialog, information , title = 'Information'): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: title,
        information: information,
        button: MessageBoxButton.YesNo,
        style: 0,
        allow_outside_click: false,
      },
      width: "400px",
    });
    return dialogRef.afterClosed();
  }

  static ShowYesNoVerification(
    dialog,
    information,
    header
  ): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: header,
        information: information,
        button: MessageBoxButton.YesNo,
        style: 0,
        allow_outside_click: false,
      },
      width: "400px",
    });
    return dialogRef.afterClosed();
  }

  static ShowConfirmLogin(dialog, information): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: "Information",
        information: information,
        button: MessageBoxButton.ConfirmLogin,
        style: 0,
        allow_outside_click: false,
      },
      width: "400px",
    });
    return dialogRef.afterClosed();
  }

  static ShowPinDialog(
    dialog,
    PinSignUpPageObj: SignupComponent
  ): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: "",
        information: "",
        button: MessageBoxButton.CancelSubmitForPin,
        style: 0,
        allow_outside_click: false,
        PinSignUpPage: PinSignUpPageObj,
      },
      width: "400px",
    });
    return dialogRef.afterClosed();
  }

  //new Register
  static ShowPinDialog_reg(
    dialog,
    PinSignUpPageObj: UserRegisterComponent
  ): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogNewComponent, {
      data: {
        message: "",
        information: "",
        button: MessageBoxButton.CancelSubmitForPin,
        style: 0,
        allow_outside_click: false,
        PinSignUpPage: PinSignUpPageObj,
      },
      width: "630px",
    });
    return dialogRef.afterClosed();
  }

  static ShowSurveyPinDialog(
    dialog,
    PinSignUpPageObj: SurveySignupComponent
  ): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogNewComponent, {
      data: {
        message: "",
        information: "",
        button: MessageBoxButton.CancelSubmitForSurveyPin,
        style: 0,
        allow_outside_click: false,
        PinSignUpPage: PinSignUpPageObj,
      },
      width: "630px",
    });
    return dialogRef.afterClosed();
  }

  static ShowCreatePwdDialog(
    dialog,
    PinSignUpPageObj: SignupComponent
  ): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: "",
        information: "",
        button: MessageBoxButton.CancelSubmitForPwd,
        style: 0,
        allow_outside_click: false,
        PinSignUpPage: PinSignUpPageObj,
      },
      width: "400px",
    });
    return dialogRef.afterClosed();
  }

  // Registor New ShowCreatePwdDialog_reg popup
  static ShowCreatePwdDialog_reg(
    dialog,
    PinSignUpPageObj: UserRegisterComponent
  ): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogNewComponent, {
      data: {
        message: "",
        information: "",
        button: MessageBoxButton.CancelSubmitForPwd,
        style: 0,
        allow_outside_click: false,
        PinSignUpPage: PinSignUpPageObj,
      },
      width: "630px",
    });
    return dialogRef.afterClosed();
  }

  static ShowSurveyCreatePwdDialog(
    dialog,
    PinSignUpPageObj: SurveySignupComponent
  ): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogNewComponent, {
      data: {
        message: "",
        information: "",
        button: MessageBoxButton.CancelSubmitForPwd,
        style: 0,
        allow_outside_click: false,
        PinSignUpPage: PinSignUpPageObj,
      },
      width: "630px",
    });
    return dialogRef.afterClosed();
  }

  static ShowCancelContinue(
    dialog,
    message,
    information
  ): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: message,
        information: information,
        button: MessageBoxButton.CancelContinue,
        style: 0,
        allow_outside_click: false,
      },
      width: "400px",
    });
    return dialogRef.afterClosed();
  }

  static ShowApproveRejectMembers(
    dialog,
    information,
    title
  ): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: title,
        information: information,
        button: MessageBoxButton.ApproveRejectMembers,
        style: 0,
        allow_outside_click: false,
      },
      width: "400px",
    });
    return dialogRef.afterClosed();
  }

  static ShowYesNoCancel(dialog, information): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: "Information",
        information: information,
        button: MessageBoxButton.YesNoCancel,
        style: 0,
        allow_outside_click: false,
      },
      width: "400px",
    });
    return dialogRef.afterClosed();
  }

  static ShowUploadRequest(dialog, information): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: "Information",
        information: information,
        button: MessageBoxButton.UploadNow,
        style: 0,
        allow_outside_click: false,
      },
      width: "600px",
    });
    return dialogRef.afterClosed();
  }

  static ShowStatusMessage(dialog, information): Observable<DialogResult> {
    const dialogRef = dialog.open(CommonDialogComponent, {
      data: {
        message: "Information",
        information: information,
        button: MessageBoxButton.StatusOnly,
        style: 0,
        allow_outside_click: false,
      },
      width: "400px",
    });
    return dialogRef.afterOpen();
  }

}

export enum MessageBoxButton {
  Ok = 0,
  OkCancel = 1,
  YesNo = 2,
  AcceptReject = 3,
  CancelSubmitForPin = 4,
  CancelSubmitForPwd = 5,
  ConfirmLogin = 6,
  CancelContinue = 7,
  ConfirmCancel = 8,
  OKCustom = 9,
  ApproveRejectMembers = 10,
  YesNoCancel = 11,
  UploadNow = 12,
  StatusOnly = 13,
  BackNext = 14,
  CancelSubmitForSurveyPin = 15
}

export enum MessageBoxStyle {
  Simple = 0,
  Full = 1,
}
