import { CommonService } from "src/app/services/common.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { APIConstant } from "@fp/constants";
import { CommonDataType } from "@fp/enums";
import {
  CommonData,
  CommonResponse,
  DataResult,
  Employer,
  EmployerDetailModel,
  EmployerFacilitatorModel,
  EmployerMembershipPackageModel,
  EmployerPage,
  EmployerRegion,
  FileAttachmentModel,
  ListOfDocsModel,
  ListOfImgFileViewModel,
  ResultModel,
  SearchPaginationRequest,
  SearchPaginationResult,
  SurveysEmployerDetailModel,
  UploadFileDocumentModel
} from "@fp/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  EmployerBranch,
  RequestEmployerBranch
} from "../models/employer-branch.model";
import { CommonDataService } from "./common-data.service";
import { HttpDAO } from "./httpdao.service";
import { DateHelper } from "@fp/helpers";

@Injectable({
  providedIn: "root"
})
export class EmployerService {
  private httpDao: HttpDAO | null;

  constructor(
    httpClient: HttpClient,
    private commonDataSvc: CommonDataService,
    private cmsrv: CommonService
  ) {
    this.httpDao = new HttpDAO(httpClient);
  }

  private convertToDataResult<T = any>(
    data: T,
    successPredicate?: () => boolean
  ) {
    const dataResult: DataResult<T> = {
      Success: true,
      Message: null,
      Errors: [],
      Data: data,
      Name: null,
      WaitInSeconds: 0
    };
    if (typeof successPredicate === "function") {
      if (!successPredicate()) {
        dataResult.Success = false;
        dataResult.Message = "Unknown error";
      }
    }
    return dataResult;
  }

  private processEmployerDetailsData(data: EmployerDetailModel) {
    if (!data) {
      return;
    }
    if (typeof data.DateClosed === "string") {
      try {
        data.DateClosed = DateHelper.parseUTC(data.DateClosed);
      } catch (err) {
        console.error(err);
      }
    }
    if (typeof data.EmployerStartDate === "string") {
      try {
        data.EmployerStartDate = DateHelper.parseUTC(data.EmployerStartDate);
      } catch (err) {
        console.error(err);
      }
    }
    if (typeof data.NextPaymentRunDate === "string") {
      try {
        data.NextPaymentRunDate = DateHelper.parseUTC(data.NextPaymentRunDate);
      } catch (err) {
        console.error(err);
      }
    }
    if (typeof data.ContractStartDate === "string") {
      try {
        data.ContractStartDate = DateHelper.parseUTC(data.ContractStartDate);
      } catch (err) {
        console.error(err);
      }
    }
    if (typeof data.ContractEndDate === "string") {
      try {
        data.ContractEndDate = DateHelper.parseUTC(data.ContractEndDate);
      } catch (err) {
        console.error(err);
      }
    }

    if (Array.isArray(data.EmployerNotes)) {
      data.EmployerNotes.forEach(_note => {
        if (typeof _note.DateTimeStamp === "string") {
          _note.DateTimeStamp = DateHelper.parseUTC(_note.DateTimeStamp);
        }
      });
    }
  }

  getDetails(id: number): Observable<DataResult<EmployerDetailModel>> {
    return this.httpDao
      .getSingleData(APIConstant.API_GET_EMPLOYER_DETAIL + id)
      .pipe(
        map((res: EmployerDetailModel) => {
          this.processEmployerDetailsData(res);
          return this.convertToDataResult(res);
        })
      );
  }

  getByRegion(regionId: number): Observable<EmployerRegion[]> {
    return this.httpDao.getSingleData(
      APIConstant.API_GET_EMPLOYER_IN_REGION + regionId
    );
  }

  getByPostcode(postcodeId: number): Observable<DataResult<EmployerRegion[]>> {
    return this.httpDao.getSingleData(
      APIConstant.API_GET_EMPLOYERS_BY_POSTCODEID + postcodeId
    );
  }

  getData(name: string): Observable<DataResult<EmployerFacilitatorModel[]>> {
    return this.httpDao.getSingleData(
      APIConstant.API_GET_USERS_BY_FISRTNAME_LASTNAME + encodeURIComponent(name)
    );
  }

  getEmployerPage(employerId: number): Observable<DataResult<EmployerPage>> {
    return this.httpDao.getSingleData(
      APIConstant.API_GET_EMPLOYER_PAGE + employerId
    );
  }
  requestEmployerPage(employerPage: EmployerPage): Observable<ResultModel> {
    return this.httpDao.postData(
      APIConstant.API_CREATE_EMPLOYER_PAGE,
      employerPage
    );
  }
  checkDuplicateLinkName(
    name: string
  ): Observable<DataResult<EmployerDetailModel>> {
    return this.httpDao.getSingleData(
      APIConstant.API_CHECK_DUPLICATE_LINKNAME + encodeURIComponent(name)
    );
  }

  search(request: SearchPaginationRequest): Observable<string> {
    const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_SEARCH_EMPLOYERS, {
      Data: encrypteddata
    });
  }

  getForMemberSignup(id: number): Observable<DataResult<Employer>> {
    return this.httpDao.getSingleData(
      APIConstant.API_GET_EMPLOYER_FOR_MEMBER_SIGNUP + id
    );
  }

  getEmployerByName(name: string): Observable<Employer[]> {
    return this.httpDao.getSingleData(
      APIConstant.API_FIND_EMPLOYERS_BY_NAME + encodeURIComponent(name)
    );
  }

  getByCode(code: string): Observable<DataResult<CommonResponse[]>> {
    return this.httpDao
      .getSingleData(
        APIConstant.API_GET_EXACTLY_EMP_CODE + encodeURIComponent(code)
      )
      .pipe(map(res => this.convertToDataResult(res)));
  }

  getByEmpCodeForMemberSurvey(code: string): Observable<DataResult<SurveysEmployerDetailModel>> {
    return this.httpDao
      .getSingleDataWithoutToken(
        APIConstant.API_GET_EMPLOYER_BY_EMP_CODE_FOR_SURVEY + encodeURIComponent(code)
      )
      .pipe(
        map((value: any) => {
          const dectypted_data = this.cmsrv.D_FP_ResponseData(value);
          const rdata: DataResult<SurveysEmployerDetailModel> = JSON.parse(dectypted_data);
          return rdata;
        })
      );
  }

  getBranchs(employerId: number): Observable<DataResult<EmployerBranch[]>> {
    return this.httpDao.getSingleData(
      APIConstant.API_GET_EMPLOYER_BRANCHS + employerId
    );
  }

  getEmployerDocuments(
    id: number
  ): Observable<DataResult<FileAttachmentModel[]>> {
    const request = { "id": id.toString() };
    const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_GET_EMPLOYER_DOCUMENTS, {
      "Data": encrypteddata
    });
  }

  getEmployerImages(id: number): Observable<DataResult<FileAttachmentModel[]>> {
    const request = { "id": id.toString() };
    const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_GET_EMPLOYER_IMAGES, {
      "Data": encrypteddata
    });
  }

  uploadFileDocument(file: UploadFileDocumentModel): Observable<ResultModel> {
    return this.httpDao.postData(APIConstant.API_UPLOAD_EMPLOYER_FILE, file);
  }

  saveEmployerDocuments(
    fileAttachments: ListOfDocsModel
  ): Observable<ResultModel> {
    return this.httpDao.postData(
      APIConstant.API_SAVE_EMPLOYER_DOC_ATTACHMENT,
      fileAttachments
    );
  }

  saveEmployerImages(
    fileAttachments: ListOfImgFileViewModel
  ): Observable<ResultModel> {
    return this.httpDao.postData(
      APIConstant.API_SAVE_EMPLOYER_IMAGE_ATTACHMENT,
      fileAttachments
    );
  }

  requestEmployerBranchs(
    branchs: RequestEmployerBranch
  ): Observable<ResultModel> {
    return this.httpDao.postData(
      APIConstant.API_POST_EMPLOYER_BRANCHS,
      branchs
    );
  }

  checkActiveMember(EmployerId: Number): Observable<ResultModel> {
    return this.httpDao.getSingleData(
      APIConstant.API_CHECK_ACTIVE_MEMBER + EmployerId
    );
  }

  updateMembershipPackage(
    memPgk: EmployerMembershipPackageModel[]
  ): Observable<ResultModel> {
    return this.httpDao.postData(
      APIConstant.API_UPDATE_MEMBERSHIP_PACKAGE,
      memPgk
    );
  }

  updateEmployerAccesstoFacility(employerid: number): Observable<ResultModel> {
    return this.httpDao.getSingleData(
      APIConstant.API_UPDATE_EMPLOYER_ACCESS + employerid.toString()
    );
  }

  findEmployerNamesByName(
    name: string
  ): Observable<DataResult<CommonResponse[]>> {
    return this.httpDao
      .getSingleData(
        APIConstant.API_GET_EMPLOYER_NAME_BY_NAME + encodeURIComponent(name)
      )
      .pipe(
        map((res: CommonResponse[]) =>
          this.convertToDataResult(res, () => Array.isArray(res))
        )
      );
  }

  findManagers(params: {
    name: string;
    roleType: number;
  }): Observable<DataResult<CommonResponse[]>> {
    return this.httpDao
      .postData(APIConstant.API_GET_ACCOUNT_MANAGER_BY_NAME, {
        id: params.roleType,
        name: params.name
      })
      .pipe(
        map((res: CommonResponse[]) =>
          this.convertToDataResult(res, () => Array.isArray(res))
        )
      );
  }

  getIndustrySectors(): Observable<DataResult<CommonResponse[]>> {
    return this.httpDao
      .getSingleData(APIConstant.API_GET_LIST_INDUSTRY_SECTOR)
      .pipe(
        map((res: CommonResponse[]) =>
          this.convertToDataResult(res, () => Array.isArray(res))
        )
      );
  }

  getStatuses(): Observable<DataResult<CommonResponse[]>> {
    return this.httpDao
      .getSingleData(APIConstant.API_GET_EMPLOYER_STATUS_TYPE)
      .pipe(
        map((res: CommonResponse[]) =>
          this.convertToDataResult(res, () => Array.isArray(res))
        )
      );
  }

  getTypes(): Observable<DataResult<CommonResponse[]>> {
    return this.httpDao
      .getSingleData(APIConstant.API_GET_EMPLOYER_TYPE)
      .pipe(
        map((res: CommonResponse[]) =>
          this.convertToDataResult(res, () => Array.isArray(res))
        )
      );
  }

  //FP-518
  getEmployerCategoryTypes(): Observable<DataResult<CommonResponse[]>> {
    return this.httpDao
      .getSingleData(APIConstant.API_GET_EMPLOYER_CATEGORY)
      .pipe(
        map((res: CommonResponse[]) =>
          this.convertToDataResult(res, () => Array.isArray(res))
        )
      );
  }

  getEligibleEmployees(): Observable<DataResult<CommonData[]>> {
    return this.commonDataSvc.getData(CommonDataType.EligibleEmployee);
  }

  getPaymentPeriods(): Observable<DataResult<CommonResponse[]>> {
    return this.httpDao
      .getSingleData(APIConstant.API_GET_PAYMENT_PERIOD_NAME)
      .pipe(
        map((res: CommonResponse[]) =>
          this.convertToDataResult(res, () => Array.isArray(res))
        )
      );
  }
}
