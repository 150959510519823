<div class="modal-header">
    <h5 class="modal-title">Postcode Upload History</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="mt-lg-4">
    <form>
        <div class="my-4">
            <div class="border-default rounded-10 border-2">
                <div class="px-2 fptablefreezeheadrow">
                    <mat-table #table matSort [dataSource]="bogusDataSource" class="fptablehead"
                        matSortActive="last_name" matSortDirection="desc" matSortStart="desc" #memberSort="matSort">
                        <!-- Name Column -->
                        <ng-container matColumnDef="User">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> User</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.User}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Date">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.EventDate}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"></mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </mat-table>

                    <mat-table #table [dataSource]="dataSourceHistory" class="fpscrolly height3 d-block">
                        <!-- Name Column -->
                        <ng-container matColumnDef="User">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.User}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Date">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.EventDate | date}} </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns" [style.min-height]="0"></mat-header-row>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </mat-table>
                    <mat-toolbar class="bg-white">
                        <mat-toolbar-row class="p-0">
                            <mat-paginator class="w-100" [length]="length" [pageSize]="pageSize"
                                [pageSizeOptions]="pageSizeOptions" (page)="paginatorChange($event)"
                                showFirstLastButtons>
                            </mat-paginator>
                        </mat-toolbar-row>
                        <mat-toolbar-row *ngIf="dataSourceHistory == null || dataSourceHistory.data.length == 0"
                            class="text-muted d-block p-2 bg-light text-center">
                            {{commonString.TABLE_NO_RECORD_FOUND}}
                        </mat-toolbar-row>
                    </mat-toolbar>
                </div>
            </div>
        </div>
    </form>
</div>