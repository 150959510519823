import { ChangeDetectionStrategy, Component, Injector, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogResult } from 'src/app/components/common-dialog/common-dialog.component';
import { FacilityProductFormBase } from 'src/app/components/facility-components/addfacility/products-services/products-list/product-form-base';
import { Product, Product1 } from 'src/app/models';
import { MessageBox, MessageBoxButton } from 'src/app/services/common-dialog.service';
import { FacilityProductDetailsComponent } from '../product-details/product-details.component';

@Component({
    selector: 'app-facility-product-details-dialog',
    templateUrl: './product-details-dialog.component.html',
    styleUrls: ['./product-details-dialog.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: FacilityProductFormBase, useExisting: FacilityProductDetailsDialogComponent }]
})
export class FacilityProductDetailsDialogComponent extends FacilityProductFormBase {
    @ViewChild(FacilityProductDetailsComponent) detailsSection: FacilityProductDetailsComponent;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            Details: FacilityProductDetailsComponent.getFormGroup()
        });
    }

    constructor(
        injector: Injector,
        private activeModal: NgbActiveModal
    ) {
        super(injector);
        this.async = false;
        this.form = FacilityProductDetailsDialogComponent.getFormGroup();
    }
    
    ngAfterViewInit() {
        this.detailsSection.OnLoad();
    }

    dismiss() {
        let flag: boolean;
        const gridProduct = <Product>this.data.model;
        console.log(this.form);
        const originProduct: IProductFE = {
            typeId: gridProduct.ServiceType ? gridProduct.ServiceType.Id : null,
            statusId: gridProduct.Status ? gridProduct.Status.StatusId : 1,
            name: gridProduct.Name ? gridProduct.Name : null,
            price: gridProduct.Price == undefined ? 0 : (<Product1>this.data.model).Price,
            maxUnitsAvailable: gridProduct.MaxUnitsAvailable == undefined ? null : gridProduct.MaxUnitsAvailable,
            productCost: gridProduct.FpproductCost ? gridProduct.FpproductCost : 0,
            memberPrice: gridProduct.MemberSalePrice ? gridProduct.MemberSalePrice : 0,
            description: gridProduct.Description ? gridProduct.Description : null,
            gst: gridProduct.IsGstapplicable ? gridProduct.IsGstapplicable : null

        };
        const formValue: Product = this.form.value.Details;
        const newProduct: IProductFE = {
            typeId: formValue.ServiceType ? formValue.ServiceType.Id : null,
            statusId: formValue.Status ? formValue.Status.StatusId : null,
            name: formValue.Name == '' ? null : formValue.Name,
            price: formValue.Price,
            maxUnitsAvailable: formValue.MaxUnitsAvailable == undefined ? null : formValue.MaxUnitsAvailable,
            productCost: formValue.FpproductCost,
            memberPrice: formValue.MemberSalePrice,
            description: formValue.Description == '' ? null : formValue.Description,
            gst: formValue.IsGstapplicable
        };

        if (!this.form.dirty) {
            this.activeModal.dismiss();
        } else {
            MessageBox.ShowCustom(this.dialog, 'Warning', 'Warning',
                'You have entered un-submitted data. Are you sure you want to close this screen?',
                MessageBoxButton.YesNo).subscribe(r => {
                    if (r.result == DialogResult.Yes) {
                        this.activeModal.dismiss();
                    }
                });
        }
    }

    handleStatus() {
        if (this.form.value.Details.originStatus == null) {
            this.data.model = this.applyValue(this.data.model, this.detailsSection);
            this.activeModal.close({ returnValue: this.data.model });
        } else if (this.form.value.Details.originStatus.StatusId == 2) {
            this.data.model = this.applyValue(this.data.model, this.detailsSection);
            this.activeModal.close({ returnValue: this.data.model });
        } else if (this.form.value.Details.Status.StatusId == 2 && this.data.model.Status.StatusId == 1) {
            MessageBox.ShowCustom(this.dialog, 'Warning', 'Warning', 'Are you sure you want to inactivate this product?',
                MessageBoxButton.YesNo).subscribe(r => {
                    if (r.result == DialogResult.Yes) {
                        this.data.model = this.applyValue(this.data.model, this.detailsSection);
                        this.activeModal.close({ returnValue: this.data.model });
                    }
                });
        } else {
            this.data.model = this.applyValue(this.data.model, this.detailsSection);
            this.activeModal.close({ returnValue: this.data.model });
        }
    }

    flag: boolean;
    submit() {
        this.flag = true;
        if (this.form.invalid) {
            return;
        }

        this.handleStatus();
    }
}

interface IProductFE {
    typeId: number;
    statusId: number;
    name: string;
    price: any;
    maxUnitsAvailable: number;
    productCost: any;
    memberPrice: any;
    gst: boolean;
    description: string;
}