export enum StepFunctions {
    CreateDebitProcessingEntry = 0,
    UploadFailedTransactions = 1,
    ConfirmUploadFailedTransactions = 2,
    CheckRawVisits = 3,
    SaveRawVisits = 4,
    RegenerateAbas = 5,
    BulkUpload = 6,
    FinaliseAbas = 7,
    MemberMasterExport = 8,
    BulkUploadBankDetail = 9,
    BulkUploadPostcodeDetail = 10,
    MemberMasterExportWithoutSensitiveData=11,
    MembershipPackageUpdateByCSVFile = 12
}

export enum AWSStateMachineStates {
    RUNNING = "RUNNING",
    SUCCEEDED = "SUCCEEDED",
    FAILED = "FAILED",
    TIMED_OUT = "TIMED_OUT",
    ABORTED = "ABORTED"
}