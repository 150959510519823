import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ModelBasedForm } from '@fp/components/base';
import { CommonMessage, StorageKey } from '@fp/constants';
import { StringHelper } from '@fp/helpers';
import { EmployerDetailModel, EmployerNoteModel, User } from '@fp/models';

@Component({
    selector: 'app-employer-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.css'],
    providers: [ModelBasedForm.provideExisting(EmployerNotesComponent)]
})
export class EmployerNotesComponent extends ModelBasedForm<EmployerDetailModel> {
    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            EmployerNotes: [[]]
        });
    }

    private _dataSource: MatTableDataSource<EmployerNoteModel>;
    get dataSource() { return this._dataSource; }

    readonly _displayedColumns = ['user', 'dateTime', 'note'];

    CommonMessage = CommonMessage;

    @ViewChild('note') private noteElement: ElementRef<HTMLTextAreaElement>;

    constructor(injector: Injector) { super(injector); }

    OnLoadComplete() {
        this._dataSource = new MatTableDataSource();
        setTimeout(() => {
            this.getControl('EmployerNotes').valueChanges.subscribe((value: EmployerNoteModel[]) => {
                this._dataSource.data = (value || []).sort((a, b) => {
                    return a.DateTimeStamp.getTime() > b.DateTimeStamp.getTime() ? -1 : 1;
                });
                this.changeDetectorRef.markForCheck();
            });
        });
        super.OnLoadComplete();
    }

    PatchValue(value, opts) {
        super.PatchValue(value, opts);
        this._dataSource = new MatTableDataSource(this.getControlValue('EmployerNotes'));
    }

    addNote() {
        const noteText = this.noteElement.nativeElement.value;
        if (StringHelper.isNullOrWhiteSpace(noteText)) {
            return;
        }
        const currentUser = <User>(JSON.parse(this.commonSvc.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || {});
        const newNote = {
            EmployerNoteId: 0,
            EmployerId: this.data.model ? this.data.model.EmployerId : 0,
            UserFullName: currentUser.FirstName + ', ' + currentUser.LastName,
            UserId: currentUser.UserId,
            Note: noteText,
            DateTimeStamp: new Date()
        };
        const notes = (this.getControlValue<EmployerNoteModel[]>('EmployerNotes') || []).concat(newNote);
        this.getControl('EmployerNotes').setValue(notes);
        this.noteElement.nativeElement.value = null;
    }
}
