export class RawVisitParameters {
    FacilityName: string;
    ServiceName: string;
    ToDate: string;
    FromDate: string;
    PostedDateFrom: string;
    PostedDateTo: string;
    ModifiedBy: string;
    CountryName: string;
}

export class RawVisitCount {
    Data: VisitCountData;
    Errors: string[];
    Success: boolean;
    Message: string;
    Name: string;
}

export class VisitCountData {
    FacilityPosts:[];
    VisitCount: number;
    NonDedupedVisits: number;
    IsFirstPaymentRequest: boolean;
    paymentRequestCalculationMethod: number;
    VisitCost: number;
}