import { Component, Injector } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { ModelBasedForm } from '@fp/components/base';
import { APIConstant, CommonMessage } from '@fp/constants';
import { FPValidators, StringHelper } from '@fp/helpers';
import { CommonResponse, DataResult, EmployerDetailModel } from '@fp/models';
import { EmployerService } from '@fp/services';
import { empty } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, skipWhile, switchMap } from 'rxjs/operators';

enum ManagerRole {
    ANY = 0,
    BUSINESS_DEVELOPMENT_OR_OPERATION = 1,
    BUSINESS_DEVELOPMENT = 2,
};

@Component({
    selector: 'app-employer-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.css'],
    providers: [ModelBasedForm.provideExisting(EmployerDetailsComponent)]
})
export class EmployerDetailsComponent extends ModelBasedForm<EmployerDetailModel> {
    _industrySectors: CommonResponse[];
    _parentGroups: CommonResponse[];
    _accountManagers: CommonResponse[];
    _businessDevManagers: CommonResponse[];
    _employerTypes: CommonResponse[];
    _employerCategories: CommonResponse[];

    ParentGroupCtrl = new UntypedFormControl(null, [FPValidators.resolvableAutocompleteOption]);
    AccountManagerCtrl = new UntypedFormControl(null, [Validators.required, FPValidators.resolvableAutocompleteOption]);
    BusinessDevManagerCtrl = new UntypedFormControl(null, [Validators.required, FPValidators.resolvableAutocompleteOption]);

    CommonMessage = CommonMessage;
    StringHelper = StringHelper;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            EmployerId: [0],
            Name: [null, Validators.required],
            AlternateNameTags: [null],
            IndustrySectorId: [null],
            EmployerGroupId: [null],
            EmployerGroupName: [null],
            AccountManagerId: [null],
            AccountManagerName: [null],
            BusinessDevelopmentManagerId: [null, [Validators.required]],
            BusinessDevelopmentManagerName: [null],
            EmployerTypeId: [null, [Validators.required]]
        });
    }

    constructor(injector: Injector) { super(injector); }

    OnInit() {
        this.getControl('EmployerGroupId').setValidators((ctrl: AbstractControl): ValidationErrors => {
            return this.ParentGroupCtrl.validator(this.ParentGroupCtrl);
        });
        this.getControl('AccountManagerId').setValidators((ctrl: AbstractControl): ValidationErrors => {
            return this.AccountManagerCtrl.validator(this.ParentGroupCtrl);
        });
        this.getControl('BusinessDevelopmentManagerId').setValidators((ctrl: AbstractControl): ValidationErrors => {
            return this.BusinessDevManagerCtrl.validator(this.ParentGroupCtrl);
        });
    }

    OnLoad() {
        this._serviceInvoker.Invoke(EmployerService, svc => {
            this.InvokeBatch([
                svc.getIndustrySectors(),
                svc.getTypes(),
                svc.getEmployerCategoryTypes()
            ], {
                    onSuccess: res => {
                        this.handleGetIndustrySectorsResult(res[0]);
                        this.handleGetEmployerTypesResult(res[1]);
                        this.handleGetEmployerCategoriesResult(res[2]);
                        super.OnLoad();
                    }
                }
            )
        });
    }

    OnLoadComplete() {
        setTimeout(() => {
            this.ParentGroupCtrl.valueChanges
                .pipe(
                    skipWhile(value => value && typeof value === 'object'),
                    debounceTime(APIConstant.API_DEBOUNCE_TIME),
                    distinctUntilChanged(),
                    filter(value => {
                        // Clear previous results
                        this._parentGroups = [];
                        return typeof value === 'string';
                    }),
                    switchMap((value) => {
                        if (typeof value === 'string' && value.length > 2) {
                            this.ParentGroupCtrl.markAsPending();
                            return this._serviceInvoker.GetServiceInstance(EmployerService).findEmployerNamesByName(value);
                        }
                        return empty();
                    }),
                    catchError((e) => { throw e; }))
                .subscribe(
                    result => {
                        this.ParentGroupCtrl.updateValueAndValidity({ emitEvent: false });
                        if (result.Success) {
                            this._parentGroups = (result.Data || []).filter(_g => _g.Id !== this.getControl('EmployerId').value);
                        } else {
                            this.HandleResponseError(result);
                        }
                    },
                    error => {
                        this.ParentGroupCtrl.updateValueAndValidity({ emitEvent: false });
                        this.handleError(error);
                    });

            this.AccountManagerCtrl.valueChanges
                .pipe(
                    skipWhile(value => typeof value === 'object'),
                    debounceTime(APIConstant.API_DEBOUNCE_TIME),
                    distinctUntilChanged(),
                    filter(value => {
                        this._accountManagers = [];
                        return typeof value === 'string';
                    }),
                    switchMap((value) => {
                        if (typeof value === 'string' && value.length > 2) {
                            this.AccountManagerCtrl.markAsPending();
                            return this._serviceInvoker.GetServiceInstance(EmployerService)
                                .findManagers({ name: value, roleType: ManagerRole.BUSINESS_DEVELOPMENT_OR_OPERATION });
                        }
                        return empty();
                    }),
                    catchError((e) => { throw e; }))
                .subscribe(
                    result => {
                        this.AccountManagerCtrl.updateValueAndValidity({ emitEvent: false });
                        if (result.Success) {
                            this._accountManagers = result.Data;
                        } else {
                            this.HandleResponseError(result);
                        }
                    },
                    error => {
                        this.AccountManagerCtrl.updateValueAndValidity({ emitEvent: false });
                        this.handleError(error);
                    });

            this.BusinessDevManagerCtrl.valueChanges
                .pipe(
                    skipWhile(value => typeof value === 'object'),
                    debounceTime(APIConstant.API_DEBOUNCE_TIME),
                    distinctUntilChanged(),
                    filter(value => {
                        this._businessDevManagers = [];
                        return typeof value === 'string';
                    }),
                    switchMap((value) => {
                        if (typeof value === 'string' && value.length > 2) {
                            this.BusinessDevManagerCtrl.markAsPending();
                            return this._serviceInvoker.GetServiceInstance(EmployerService)
                                .findManagers({ name: value, roleType: ManagerRole.BUSINESS_DEVELOPMENT });
                        }
                        return empty();
                    }),
                    catchError((e) => { throw e; }))
                .subscribe(
                    result => {
                        this.BusinessDevManagerCtrl.updateValueAndValidity({ emitEvent: false });
                        if (result.Success) {
                            this._businessDevManagers = result.Data;
                        } else {
                            this.HandleResponseError(result);
                        }
                    },
                    error => {
                        this.BusinessDevManagerCtrl.updateValueAndValidity({ emitEvent: false });
                        this.handleError(error);
                    });
        });
        super.OnLoadComplete();
    }

    private handleGetIndustrySectorsResult(res: DataResult<CommonResponse[]>) {
        if (res.Success) {
            this._industrySectors = res.Data;
        } else {
            this.HandleResponseError(res);
        }
    }

    private handleGetEmployerTypesResult(res: DataResult<CommonResponse[]>) {
        if (res.Success) {
            this._employerTypes = res.Data;
        } else {
            this.HandleResponseError(res);
        }
    }

    //FP-518
    private handleGetEmployerCategoriesResult(res: DataResult<CommonResponse[]>) {
        if (res.Success) {
            this._employerCategories = res.Data;
        } else {
            this.HandleResponseError(res);
        }
    }

    /** @internal */
    autoComplete_DisplayWithFn(obj: CommonResponse): string {
        return obj && obj.Name;
    }

    /** @internal */
    autoParentGroup_OptionSelected(option: CommonResponse) {
        const { Id, Name } = Object.assign({}, option);
        this.form.patchValue({ EmployerGroupId: Id, EmployerGroupName: Name });
        this._parentGroups = [];
    }

    /** @internal */
    autoAccountManager_OptionSelected(option: CommonResponse) {
        const { Id, Name } = Object.assign({}, option);
        this.form.patchValue({ AccountManagerId: Id, AccountManagerName: Name });
        this._accountManagers = [];
    }

    /** @internal */
    autoBusinessDevManager_OptionSelected(option: CommonResponse) {
        const { Id, Name } = Object.assign({}, option);
        this.form.patchValue({ BusinessDevelopmentManagerId: Id, BusinessDevelopmentManagerName: Name });
        this._businessDevManagers = [];
    }

    /** @internal */
    txtParentGroup_Blur(e) {
        this.ParentGroupCtrl.setValidators([FPValidators.resolvableAutocompleteOption]);
        if (this.ParentGroupCtrl.dirty) {
            if (!this.ParentGroupCtrl.pending) {
                this.ParentGroupCtrl.updateValueAndValidity({ emitEvent: false });
            }
        }
    }

    /** @internal */
    txtParentGroup_Focus(e) {
        this.ParentGroupCtrl.clearValidators();
    }

    /** @internal */
    txtAccountManager_Blur(e) {
        this.AccountManagerCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
        if (this.AccountManagerCtrl.dirty) {
            if (!this.AccountManagerCtrl.pending) {
                this.AccountManagerCtrl.updateValueAndValidity({ emitEvent: false });
            }
        }
    }

    /** @internal */
    txtAccountManager_Focus(e) {
        this.AccountManagerCtrl.setValidators([Validators.required]);
    }

    /** @internal */
    txtBusinessDevManager_Blur(e) {
        this.BusinessDevManagerCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
        if (this.BusinessDevManagerCtrl.dirty) {
            if (!this.BusinessDevManagerCtrl.pending) {
                this.BusinessDevManagerCtrl.updateValueAndValidity({ emitEvent: false });
            }
        }
    }

    /** @internal */
    txtBusinessDevManager_Focus(e) {
        this.BusinessDevManagerCtrl.setValidators([Validators.required]);
    }
}
