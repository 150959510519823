import { Component, ElementRef, Injector, ViewChild, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { CommonDataType, ECardOrderReason, EMemberCardStatus } from "@fp/enums";
import { CommonResponse } from "@fp/models";
import { MemberCardService } from "@fp/services";
import {
  FpFileData,
  FpFileEvent,
  FpFileUploadComponent,
  MobileNumberComponent,
} from "@fp/components/control";
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateNativeUTCAdapter,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { ManagedUpload } from "aws-sdk/clients/s3";
import { MemberFormBase } from "src/app/components/base/member-form-base";
import { CommonMessage, CommonString, PatternConstant, StorageKey } from "src/app/constant";
import { ECancellationAction, EMemberStatus } from "src/app/enums";
import { CustomMessageBox, StringHelper, Utils } from "src/app/helpers";
import {
  CommonData,
  DataResult,
  Gender,
  MemberStatus,
  UploadFile,
  Member,
  User,
  ResultModel,
  MembershipCardsModel,
} from "src/app/models";
import { CommonService, MemberService, MembershipService } from "src/app/services";
import { CommonDataService } from "src/app/services/common-data.service";
import { MessageBox } from "src/app/services/common-dialog.service";
import * as enums from "src/app/enums";
import { Router } from "@angular/router";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { CommonConstants } from "@fp/constant/common-constants";
import { RolePermissionService } from "@fp/services/role-permission.service";
import { MembershipUploadPhotoComponent } from "../../membership-cards/membership-upload-photo/membership-upload-photo.component";
import { ERole } from "@fp/enums/role.enum";
import { MemberCancellationComponent } from "../member-cancellation/member-cancellation.component";

const ACTION_CARD = {
  ORDER_NEW_CARD: 1,
  CANCEL_CARD: 2,
};

@Component({
  selector: "app-member-personal-details",
  templateUrl: "./personal-details.component.html",
  styleUrls: ["./personal-details.component.css"],
  providers: [
    { provide: MemberFormBase, useExisting: MemberPersonalDetailsComponent },
    { provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter },
  ],
})
export class MemberPersonalDetailsComponent extends MemberFormBase {

  @Output() readonly dialogClose = new EventEmitter<boolean>();
  ddlReasonOrderCardControl = new UntypedFormControl(null, Validators.required);
  memberShipCardsSelected: MembershipCardsModel = {};
  isSubmit: boolean;
  isCancelMembershipCard = false; // flad cancel card
  @ViewChild("dlgOrderNewCardContent") dlgOrderNewCardContent: ElementRef;
  userName = this.commonSvc.GetUser();
  memberCardOrderReasonData: CommonResponse[];

  parentFocus = 0;
  genders: Gender[];
  statuses: CommonData[];
  minDOB: NgbDateStruct;
  maxDOB: NgbDateStruct;
  currentImageBase64Data: string;
  currentMember: Member[];
  membershipStatus: string;
  private modalRef: NgbModalRef;
  memberId: number;

  previousMemberStatusID: number = 0;
  changedMemberStatusID: number = 0;
  imgUrl: any;
  get GenderCtrl() {
    return this.form.get("GenderId");
  }

  isEmployerFacilitatorRole: boolean = false;
  isMemberRole: boolean = false;

  @ViewChild("divMiddleName") private divMiddleName: ElementRef<HTMLDivElement>;
  get MiddleNameDiv() {
    return this.divMiddleName ? this.divMiddleName.nativeElement : null;
  }
  @ViewChild("divStatus") private divStatus: ElementRef<HTMLDivElement>;
  get StatusDiv() {
    return this.divStatus ? this.divStatus.nativeElement : null;
  }
  @ViewChild("dpDOB") private dpDateOfBirth: NgbInputDatepicker;
  @ViewChild("fileUploader") photoUploader: FpFileUploadComponent;
  @ViewChild("divPhotoUploader") private divPhotoUploader: ElementRef<
    HTMLDivElement
  >;
  get PhotoUploaderDiv() {
    return this.divPhotoUploader ? this.divPhotoUploader.nativeElement : null;
  }

  StringHelper = StringHelper;
  CommonMessage = CommonMessage;
  CommonString = CommonString;
  MemberStatus = EMemberStatus;

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      MemberId: [0],
      FirstName: [
        null,
        [Validators.required, Validators.pattern(PatternConstant.VALID_NAME)],
      ],
      MiddleName: [null],
      LastName: [
        null,
        [Validators.required, Validators.pattern(PatternConstant.VALID_NAME)],
      ],
      GenderId: [null, Validators.required],
      DateOfBirth: [null, Validators.required],
      MemberStatusId: [null],
      MemberStatus: [null],
      MemberTypeId: [0],
      Photo: [null],
      MemberNumber: [null],
    });
  }

  constructor(
    injector: Injector,
    private memberSvc: MemberService,
    private memberShipService: MembershipService,
    private commonService: CommonService,
    private permissionSvc: RolePermissionService,
    private ngbCalendar: NgbCalendar,
    private modalService: NgbModal,
    private router: Router,
    private svMemberCard: MemberCardService,
    private commonDataService: CommonDataService
  ) {
    super(injector);
  }

  OnInit() {
    const today = this.ngbCalendar.getToday();
    this.dpDateOfBirth.minDate = this.ngbCalendar.getPrev(today, "y", 100);
    this.dpDateOfBirth.maxDate = today;
    // this.dpDateOfBirth.maxDate = this.ngbCalendar.getPrev(today, 'y', 16);
    if (this.photoUploader != undefined || this.photoUploader != null) {
      this.photoUploader.fpfileupload.nativeElement.addEventListener(
        "click",
        (e) => {
          this.btnUploadPhoto_Click(e);
        }
      );
    }
    super.OnInit();

    if (!this.genders) {
      this.OnLoad();
    }
  }

  openUploadPhotoModal() {
    var modalRef = this.modalService.open(MembershipUploadPhotoComponent, {
      backdrop: "static",
    });

    (<MembershipUploadPhotoComponent>(
      modalRef.componentInstance
    )).memberPersonalSelected = {
      id: parseInt(this.GetMemberID()),
      photo: null
    };

    modalRef.result.then(
      (result: { location: string }) => {
        const s3path = result.location;
        const key = result.location.substring(result.location.indexOf("memberPhotos/"), result.location.length);

        this.form.get("Photo").setValue({
          Id: 0,
          FileName: 'Photo',
          Data: key,
          Location: s3path,
        });

        this.commonService.getDownloadUrl(key).subscribe(
          (res) => {
            this.imgUrl = res;
          },
          (err) => {
            console.error("Error in get url", err);
          },
        );
      },
      (reason) => {

      }
    );
  }

  openModal() {
    const modelUpload = this.modalService.open(MembershipUploadPhotoComponent, {
      backdrop: "static",
    });

    (<MembershipUploadPhotoComponent>(
      modelUpload.componentInstance
    )).memberShipCardsSelected = {
      MemberId: parseInt(this.GetMemberID()),
      PhotoLocation: this.imgUrl,
      ModifiedBy: this.commonService.GetUser()
    };
    (<MembershipUploadPhotoComponent>(
      modelUpload.componentInstance
    )).isChargeFee = true;

    modelUpload.result.then(
      (result: { location: string }) => {
        const s3path = result.location;
        const key = result.location.substring(result.location.indexOf("memberPhotos/"), result.location.length);

        this.form.get("Photo").setValue({
          Id: 0,
          FileName: 'Photo',
          Data: key,
          Location: s3path,
        });

        this.commonService.getDownloadUrl(key).subscribe(
          (res) => {
            this.imgUrl = res;
            const isMember = this.commonService.GetSelectedRole() === ERole.member;
            if (isMember) {
              this.changedMemberStatusID = EMemberStatus.Changed;
              this.form.get("MemberStatusId").setValue(EMemberStatus.Changed);
              const currentUser = <User>JSON.parse(this.commonService.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
              this.memberSvc
                .getByUserForMember(currentUser.UserId)
                .subscribe((getMember) => {
                  if (getMember.Data.FamilyObject) {
                    this.commonService.SetMemFamObj(
                      getMember.Data.FamilyObject
                    );
                  }
                });
            }
          },
          (err) => {
            console.error("Error in get url", err);
          },
        );
      },
      (reason) => {

      }
    );
  }

  OnLoad() {
    this.InvokeBatch(
      [
        this.commonDataService.getData(CommonDataType.MemberCardOrderReason),
      ],
      {
        onSuccess: (res: DataResult<any>[]) => {
          if (res[0].Success) {
            this.memberCardOrderReasonData = res[0].Data;
            this.ddlReasonOrderCardControl.setValue(null);
          } else {
            this.HandleResponseError(res[0]);
          }
          super.OnLoad();
        },
      }
    );

    this.genders = CommonConstants.GET_GENDERS;
    this.statuses = Utils.sortCommonData(
      CommonConstants.GET_MEMBER_STATUS.filter(
        (_item) => _item.Id !== EMemberStatus.CandidateMember
      )
    );

    super.OnLoad();
    // Commenting the following out until we find out why we need it

    // this.InvokeBatch([this.memberSvc.getStatuses()], {
    //   onSuccess: (res: DataResult<(Gender | CommonData)[]>[]) => {
    //     console.log("MemStat: ", res);
    //     const getStatusesResult = <DataResult<CommonData[]>>res[0];

    //     if (getStatusesResult.Success) {
    //       this.statuses = Utils.sortCommonData(
    //         getStatusesResult.Data.filter(
    //           (_item) => _item.Id !== EMemberStatus.CandidateMember
    //         )
    //       );
    //     } else {
    //       this.HandleResponseError(getStatusesResult);
    //     }
    //   },
    //   onError: (err) => {
    //     MessageBox.ShowError(
    //       this.dialog,
    //       "Sorry, something went wrong. Let's try that again."
    //     ).subscribe((res) => {
    //       if (res.result.toLowerCase() === DialogResult.Ok) {
    //         window.location.reload();
    //       }
    //     });
    //     this._logger.error(err);
    //   },
    // });

    this.previousMemberStatusID = this.data.model.MemberStatusId;
  }

  LoadComplete() {
    const mtype = this.getControl("MemberTypeId").value;
    const today = this.ngbCalendar.getToday();
    if (mtype === enums.EMemberType.Dependant) {
      this.dpDateOfBirth.minDate = this.ngbCalendar.getPrev(today, "y", 25);
      this.dpDateOfBirth.minDate.day = today.day;
      this.dpDateOfBirth.minDate.month = today.month;
    }
    setTimeout(() => {
      this.getControl("MemberStatusId").valueChanges.subscribe(
        (value: number) => {
          this.getControl("MemberStatus").setValue(
            this.statuses
              .map(
                (_item) =>
                  <MemberStatus>{ MemberStatusId: _item.Id, Name: _item.Name }
              )
              .find((_item) => _item.MemberStatusId === value)
          );
        }
      );
    });
    if (this.data.model.MemberTypeId !== enums.EMemberType.Primary) {
      if (this.data.model.PrimaryMember.Memberships.length > 0) {
        this.membershipStatus = this.data.model.PrimaryMember.Memberships[0].MembershipStatusDesc;
      }
    } else {
      if (this.data.model.Memberships.length > 0) {
        this.membershipStatus = this.data.model.Memberships[0].MembershipStatusDesc;
      }
    }

    super.LoadComplete();
  }

  ddlGenderOption_CompareFn(opt1: Gender, opt2: Gender) {
    return (!opt1 && !opt2) || (opt1 && opt2 && opt1.GenderId == opt2.GenderId);
  }

  canUploadPhoto(): boolean {
    if (this.isSignup()) {
      return true;
    } else if (this.data.model.MemberStatusId !== enums.EMemberStatus.New) {
      return true
    } else {
      return false;
    }
  }

  checkFirst(e: Event) {
    if (this.isSignup() || this.data.model.MemberStatusId === enums.EMemberStatus.New) {
      this.openUploadPhotoModal();
    }
    this.btnUploadPhoto_Click(e);
  }

  btnUploadPhoto_Click(e: Event) {
    // Intentionally leave blank for overriding.
  }

  addImage(event) {
    const file = event.target.files[0];
    if (
      file.type != "image/png" &&
      file.type != "image/jpg" &&
      file.type != "image/jpeg"
    ) {
      MessageBox.ShowError(
        this.dialog,
        StringHelper.format(
          CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE,
          "JPEG or PNG"
        )
      );
      return;
    } else if (file.size > CommonString.MAX_UPLOAD_FILE_SIZE) {
      MessageBox.ShowError(
        this.dialog,
        StringHelper.format(CommonString.MAX_UPLOAD_FILE_SIZE_MESSAGE, "2MB")
      );
      return;
    }
    let reader = new FileReader();
    reader.onloadend = () => {
      this.currentImageBase64Data = <string>reader.result;
      const logo: UploadFile = {
        FileName: file.name,
        Location: null,
        Data: (<string>reader.result).replace(/^data:(.*;base64,)?/, ""),
      };
      this.form.get("Photo").setValue(logo);
    };
    reader.readAsDataURL(file);
  }

  memberStatusDisabled(status) {
    if (this.data.model.MemberStatusId === EMemberStatus.Active) {
      if (
        [this.MemberStatus.New,
        this.MemberStatus.Invalid,
        this.MemberStatus.Inactive,
        this.MemberStatus.DeIdentified,
        this.MemberStatus.SurveyCandidate,
        ].indexOf(status.Id) >
        -1
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      this.data.model.MemberStatusId === EMemberStatus.Inactive ||
      this.data.model.MemberStatusId === EMemberStatus.Banned
    ) {
      if (
        [
          this.MemberStatus.Changed,
          this.MemberStatus.Active,
          this.MemberStatus.Invalid,
          this.MemberStatus.DeIdentified,
          this.MemberStatus.SurveyCandidate,
        ].indexOf(status.Id) > -1
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      this.data.model.MemberStatusId === EMemberStatus.SurveyCandidate
    ) {
      if (
        [
          this.MemberStatus.Changed,
          this.MemberStatus.Active,
          this.MemberStatus.Invalid,
          this.MemberStatus.New,
          this.MemberStatus.Inactive,
          this.MemberStatus.Banned

        ].indexOf(status.Id) > -1
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      this.data.model.MemberStatusId === EMemberStatus.DeIdentified
    ) {
      if (
        [
          this.MemberStatus.Changed,
          this.MemberStatus.Active,
          this.MemberStatus.Invalid,
          this.MemberStatus.New,
          this.MemberStatus.Inactive,
          this.MemberStatus.Banned

        ].indexOf(status.Id) > -1
      ) {
        return true;
      } else {
        return false;
      }
    }

    //To be confirmed
    else if (this.data.model.MemberStatusId === EMemberStatus.Invalid) {
      if (
        [
          this.MemberStatus.Active,
          this.MemberStatus.Inactive,
          this.MemberStatus.Banned,
          this.MemberStatus.Changed,
          this.MemberStatus.DeIdentified,
          this.MemberStatus.SurveyCandidate,
        ].indexOf(status.Id) > -1
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  /** @internal */
  _removePhoto() {
    const id = <number>this.form.get("Photo").value["Id"];
    this.form.get("Photo").patchValue(id > 0 ? { Location: null } : null);
    this.photoUploader.ResetFileInputValue();
  }

  public OnRemovePhoto() {
    console.log("In Remove Photo Function");

    let memberId = parseInt(this.GetMemberID());
    let modifiedby = this.commonService.GetUser()

    console.log("MemberID - " + this.GetMemberID());
    console.log("ModifiedBy - " + this.commonService.GetUser());

    this.Invoke(this.memberSvc.RemoveMemberPhotoForMember(memberId, modifiedby), {
      onSuccess: (res: ResultModel) => {
        console.log(res);
        if (res != null) {
          if (res.Success) {
            this.OnLoad();
            location.reload()
          } else {
            this.HandleResponseError(res);
          }
        }
      },
    });
  }

  /** @internal */
  _fileUploaded(file: FpFileData) {
    const url = file.filedata.url;
    //const s3path = url.substring(0, url.indexOf("memberPhotos/"));
    const s3path = url;
    const key = url.substring(url.indexOf("memberPhotos/"), url.length);

    this.form.get("Photo").setValue({
      Id: 0,
      FileName: file.originfilename,
      Data: key,
      Location: s3path,
    });

    this.commonService.getDownloadUrl(key).subscribe(
      (res) => {
        this.imgUrl = res;
      },
      (err) => {
        console.error("Error in get url", err);
      },
    );
  }



  /** @internal */
  _fileSelected(event: FpFileEvent) {
    if (
      event.file.type !== "image/png" &&
      event.file.type !== "image/jpg" &&
      event.file.type !== "image/jpeg"
    ) {
      const run = async () => {
        event.cancel();
        const afterClosed = MessageBox.ShowError(
          this.dialog,
          StringHelper.format(
            CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE,
            "JPEG or PNG"
          )
        );
        afterClosed.subscribe((r) => {
          console.log("Cancelling");
        });
        await afterClosed.toPromise();

        console.log("After await");
      };
      run();
    }

    //Reset the value of photo control in the form - forces the child component (member-image) to run each time
    // this.form.get("Photo").setValue({
    //   Id: 0,
    //   FileName: "placeholder",
    //   Data: "placeholder",
    //   Location: null,
    // });
  }

  changeMemberStatus() {
    this.changedMemberStatusID = this.form.get("MemberStatusId").value;

    let memberStatusResetContractPrompts = [EMemberStatus.Inactive, EMemberStatus.Banned];

    //If member changes their status from 'Inactive' or 'Banned' TO 'New' - for primary members only
    if (this.data.model.MemberIdPrimary === null) {
      if (
        memberStatusResetContractPrompts.includes(this.previousMemberStatusID) &&
        this.changedMemberStatusID === EMemberStatus.New
      ) {
        let msgBoxContent =
          "Reactivating this member will not automatically reset contract start and end dates. Would you like to reset the member's contract start date to the next direct debit run of their employer <strong>once they are verified</strong>?";
        MessageBox.ShowYesNoCancel(this.dialog, msgBoxContent).subscribe(
          (dialogResult) => {
            if (dialogResult.result.toLowerCase() === DialogResult.Yes) {
              this.data.model.IsReactivated = true;
            } else if (dialogResult.result.toLowerCase() === DialogResult.No) {
              this.data.model.IsReactivated = false;
            }
            //If cancel is clicked revert to original member status
            else {
              this.form
                .get("MemberStatusId")
                .setValue(this.previousMemberStatusID);
            }
          }
        );
      }
      // If member status is changed from Invalid to New -> Silently set Reactivation Flag to False.
      // This will order them a new card and reactivate their membership properly on Approval
      else if (
        this.previousMemberStatusID === EMemberStatus.Invalid &&
        this.changedMemberStatusID === EMemberStatus.New
      ) {
        this.data.model.IsReactivated = false;
      }
    }
  }

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }
  onBlurParent(focusNumbr) {
    this.parentFocus = focusNumbr;
  }
  getClassFocused(vlFocused) {
    if (this.parentFocus == vlFocused) {
      return "focused";
    } else {
      return "";
    }
  }

  public isSignup() {
    return this.commonSvc.App.isMemberSignup;
  }

  public IsAdmin() {
    const path = this.router.url;
    const memberid = this.getControl("MemberId").value;
    const role = this.commonSvc.GetSelectedRole();
    if (memberid === 0) {
      this.getControl("FirstName").enable();
      this.getControl("LastName").enable();
      this.getControl("GenderId").enable();
      return true;
    }
    else {
      if (path !== "/membersignup") {
        let getPrivilege = this.permissionSvc.Privileges.find(
          (e) => e.PrivilegeName === "View/Edit members"
        );
        if ((role === "Member") || (role === "Employer_Facilitator")) {
          this.getControl("FirstName").disable();
          this.getControl("LastName").disable();
          this.getControl("GenderId").disable();
          return false;
        }
        else {
          return true;
        }
      }
      else {
        this.getControl("FirstName").enable();
        this.getControl("LastName").enable();
        this.getControl("GenderId").enable();
        return true;
      }
    }
  }

  public GetMemberTypeName(): string {
    const mtype = this.getControl("MemberTypeId").value;
    switch (mtype) {
      case enums.EMemberType.Dependant:
        return "Dependent";
      case enums.EMemberType.Primary:
        return "Primary";
      case enums.EMemberType.Partner:
        return "Partner";
    }
  }

  public GetMemberNumber(): string {
    const mnum = this.getControl("MemberNumber").value;
    return mnum;
  }

  public GetMemberID_int(): number {
    const mid = this.getControl("MemberId").value;
    parseInt(mid);
    return mid;
  }

  public GetMemberID(): string {
    const mid = this.getControl("MemberId").value;
    return mid;
  }

  public onDOBSelected(): void {
    const selectedDate = this.getControl("DateOfBirth").value;
    const today = new Date();
    today.getHours;
    const sixteenYearsAgo = new Date(
      today.getFullYear() - 16,
      today.getMonth(),
      today.getDate(),
      today.getHours(),
      today.getMinutes(),
      today.getMinutes(),
      today.getSeconds()
    );
    if (selectedDate != "") {
      if (selectedDate >= sixteenYearsAgo && this.isSignup()) {
        MessageBox.ShowError(
          this.dialog,
          "You need to be 16 years or over to sign up."
        );
        this.getControl("DateOfBirth").setValue(null);
      }
    }
  }

  inactivateOnButton() {
    const modalRef = this.modalService.open(MemberCancellationComponent, {
      backdrop: "static",
    });
    const component = <MemberCancellationComponent>(
      modalRef.componentInstance
    );
    component.memberId = this.data.model.MemberId;
    component.allowMembershipSuspension = false;
    component.defaultAction = ECancellationAction.CancelMembership;
    modalRef.result.then(
      (result) => {
        this.OnLoad();
        location.reload();
      },
    );

  }

  checkShowBan() {
    let isBool: boolean = false;
    this.isEmployerFacilitatorRole = this.commonService.GetSelectedRole() === ERole.Employer_Facilitator ? true : false;
    if (!this.isEmployerFacilitatorRole && (this.data.model.MemberStatusId == EMemberStatus.Active || this.data.model.MemberStatusId == EMemberStatus.Changed)) {
      isBool = true;
    }
    return isBool;
  }

  get checkMemberPhoto() {
    let isBool: boolean = false;
    let isPhotoAvailable = this.imgUrl || this.form.get('Photo').value?.Location ? true : false;
    this.isEmployerFacilitatorRole = this.commonService.GetSelectedRole() === ERole.Employer_Facilitator ? true : false;
    this.isMemberRole = this.commonService.GetSelectedRole() === ERole.member ? true : false
    if (!this.isEmployerFacilitatorRole && !this.isMemberRole) {
      isBool = true;
    }
    return isBool && isPhotoAvailable;
  }

  get checkMemberPhotoForRole() {
    let isBool: boolean = false;
    this.isEmployerFacilitatorRole = this.commonService.GetSelectedRole() === ERole.Employer_Facilitator ? true : false;
    this.isMemberRole = this.commonService.GetSelectedRole() === ERole.member ? true : false
    if (!this.isEmployerFacilitatorRole && !this.isMemberRole) {
      isBool = true;
    }
    return isBool;
  }

  private OnCancelCard(item) {
    const params = {
      Id: item.MemberCardId,
      Action: ACTION_CARD.CANCEL_CARD,
      OrderReason: this.ddlReasonOrderCardControl.value,
      ModifiedBy: this.userName,
    };
    this.Invoke(this.svMemberCard.cardAcion(params), {
      onSuccess: (res: DataResult<ResultModel>) => {
        if (res.Success) {
          this.modalRef.close();
        } else {
          this.HandleResponseError(res);
        }
      },
    });
  }

  openUploadPhotoModalForMembercard(item, isChargeFee = false) {



    let memberId = parseInt(this.GetMemberID());
    let MemberNumber = this.GetMemberNumber();
    let modifiedby = this.commonService.GetUser()

    console.log("MemberCardId - " + MemberNumber);
    console.log("MemberID - " + memberId);
    console.log("ModifiedBy - " + modifiedby);

    item = {
      //MemberCardId?: number;
      MemberId: memberId,
      // LastName?: string;
      // FirstName?: string;
      MemberNumber: MemberNumber,
      // MemberCardNumber?: number;
      // PhotoLocation?: string;
      // MemberType?: MemberTypeCard;
      // MemberCardStatus?: MemberCardStatus;
      ModifiedBy: modifiedby
    };
    console.log("Printing itme : ", item)
    this.modalRef = this.modalService.open(MembershipUploadPhotoComponent, {
      backdrop: "static",
    });
    (<MembershipUploadPhotoComponent>(
      this.modalRef.componentInstance
    )).memberShipCardsSelected = item;
    (<MembershipUploadPhotoComponent>(
      this.modalRef.componentInstance
    )).isChargeFee = isChargeFee;

    this.modalRef.result.then(
      (result: { location: string }) => {
        if (result && result.location) {
          this.memberShipCardsSelected.PhotoLocation = result.location;
          this.dialogClose.emit(true);
          this.resetControl();
          window.location.reload();
        }
      },
      (reason) => {
        this.dialogClose.emit(false);
        this.resetControl();
      }
    );
  }

  submitReasonUploadPhoto() {
    this.isUploadPhoto = false;
    let isChargeFee: boolean;
    if (
      this.ddlReasonOrderCardControl.value ===
      ECardOrderReason.Member_Error_Charges_Fee
    ) {
      isChargeFee = true;
    } else if (
      this.ddlReasonOrderCardControl.value ===
      ECardOrderReason.FitnessPassport_Error_No_Charges_Fee
    ) {
      isChargeFee = false;
    }
    this.modalRef.dismiss();
    if (this.ddlReasonOrderCardControl.value) {
      this.openUploadPhotoModalForMembercard(this.memberShipCardsSelected, isChargeFee);
      console.log("Printing memberShipCardsSelected : " + this.memberShipCardsSelected)
    }
    this.ddlReasonOrderCardControl.setValue(null);
  }

  isInvalidReasonDropdown() {
    const control = this.ddlReasonOrderCardControl;
    return (
      control.invalid && (control.dirty || control.touched || this.isSubmit)
    );
  }

  public OnOrderNewCard() {
    if (this.isCancelMembershipCard === true) {
      // cancel after that order new card
      this.OnCancelCard(this.memberShipCardsSelected);
    } else {
      // just order new card
      const params = {
        Action: ACTION_CARD.ORDER_NEW_CARD,
        Id: this.memberShipCardsSelected.MemberId,
        OrderReason: this.ddlReasonOrderCardControl.value,
        ModifiedBy: this.userName,
      };
      this.Invoke(this.svMemberCard.cardAcion(params), {
        onSuccess: (res: DataResult<ResultModel>) => {
          if (res.Success) {
            this.modalRef.close();
          } else {
            this.HandleResponseError(res);
          }
        },
      });
    }
  }

  resetControl() {
    this.isUploadPhoto = false;
    this.ddlReasonOrderCardControl.reset();
    this.isSubmit = false;
  }

  private openOrderNewCardModal() {
    this.modalRef = this.modalService.open(this.dlgOrderNewCardContent, {
      backdrop: "static",
    });
    this.modalRef.result.then(
      (result) => {
        this.dialogClose.emit(true);
        this.resetControl();
      },
      (reason) => {
        this.dialogClose.emit(false);
        this.resetControl();
      }
    );
  }

  isUploadPhoto: boolean;
  public OnSelectedUploadPhoto(item) {
    const isAdmin = this.commonSvc.GetSelectedRole() === ERole.admin;
    const isMember = this.commonSvc.GetSelectedRole() === ERole.member;
    let getPrivilege = this.permissionSvc.Privileges.find(
      (e) => e.PrivilegeName === "View/Edit members"
    );
    if (getPrivilege !== undefined) {
      this.isUploadPhoto = true;
    }
    this.memberShipCardsSelected = item;
    this.openOrderNewCardModal();
  }

  cancelBox() {
    this.modalRef.dismiss();
  }

  submit() {
    this.isSubmit = true;
    if (this.ddlReasonOrderCardControl.value === null) {
      return;
    }
    if (this.isUploadPhoto) {
      this.submitReasonUploadPhoto();
    } else {
      this.OnOrderNewCard();
    }
  }
}