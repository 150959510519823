import { Component, Injector } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModelBasedForm } from '@fp/components/base';
import { CommonConstants } from '@fp/constant/common-constants';
import { APIConstant, CommonMessage } from '@fp/constants';
import { FPValidators, StringHelper } from '@fp/helpers';
import { CommonResponse, Country, DataResult, EmployerDetailModel, SearchFieldOperator, SearchPaginationCriteria, State, SuburbSearchResultRecord } from '@fp/models';
import { CommonDataService, SuburbService, CommonService } from '@fp/services';
import { empty } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, skipWhile, switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-employer-address-contact-details',
    templateUrl: './address-contact-details.component.html',
    styleUrls: ['./address-contact-details.component.css'],
    providers: [ModelBasedForm.provideExisting(EmployerAddressContactDetailsComponent)]
})
export class EmployerAddressContactDetailsComponent extends ModelBasedForm<EmployerDetailModel> {
    _suburbSearchResults: SuburbSearchResultRecord[];
    _postcodes: CommonResponse[];
    _states: State[];
    _countries: Country[];
    _mailingSuburbSearchResults: SuburbSearchResultRecord[];
    _mailingPostcodes: CommonResponse[];
    _mailingStates: State[];
    _mailingCountries: Country[];
    private suburbList: SuburbSearchResultRecord[];

    SuburbCtrl = new UntypedFormControl(null, [Validators.required, FPValidators.resolvableAutocompleteOption]);
    RegionCtrl = new UntypedFormControl(null);
    StateCtrl = new UntypedFormControl(null, [Validators.required]);
    CountryCtrl = new UntypedFormControl(null, [Validators.required]);
    MailingSuburbCtrl = new UntypedFormControl(null, [FPValidators.resolvableAutocompleteOption]);
    MailingStateCtrl = new UntypedFormControl(null);
    MailingCountryCtrl = new UntypedFormControl(null);

    readonly AddressGrp = new UntypedFormGroup({});

    CommonMessage = CommonMessage;
    StringHelper = StringHelper;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            PhoneNo: [null],
            EmailAddress: [null],
            PhysicalAddressId: [0],
            StreetAddress1_Phy: [null, [Validators.required]],
            StreetAddress2_Phy: [null],
            StreetAddress_Phy_Desc: [null],
            SuburbId_Phy: [null, [Validators.required]],
            Suburb_Phy: [null],
            PostCodeId_Phy: [null, [Validators.required]],
            PostCode_Phy: [null],
            AddressId: [0],
            StreetAddress1_Mail: [null],
            StreetAddress2_Mail: [null],
            StreetAddress_Mail_Desc: [null],
            SuburbId_Mail: [null],
            Suburb_Mail: [null],
            PostCodeId_Mail: [null],
            PostCode_Mail: [null],
            MailCardToHome: [null]
        });
    }

    constructor(injector: Injector) { super(injector); }

    OnInit() {
        this.getControl('SuburbId_Phy').setValidators(ctrl => this.SuburbCtrl.validator(this.SuburbCtrl));
        this.getControl('SuburbId_Mail').setValidators(ctrl => this.MailingSuburbCtrl.validator(this.MailingSuburbCtrl));
        // StateCtrl and CountryCtrl are not attached to this form. We need to utilize their validators to
        // determine the whole form's validity by returning their ValidationErrors.
        this.form.setValidators([
            form => this.StateCtrl.validator(this.StateCtrl),
            form => this.CountryCtrl.validator(this.CountryCtrl)
        ])
        this.AddressGrp.addControl('StreetAddress1', this.getControl('StreetAddress1_Phy'));
        this.AddressGrp.addControl('StreetAddress2', this.getControl('StreetAddress2_Phy'));
        this.AddressGrp.addControl('StreetAddress_Desc', this.getControl('StreetAddress_Phy_Desc'));
        this.AddressGrp.addControl('SuburbId', this.getControl('SuburbId_Phy'));
        this.AddressGrp.addControl('Suburb', this.getControl('Suburb_Phy'));
        this.AddressGrp.addControl('PostCodeId', this.getControl('PostCodeId_Phy'));
        this.AddressGrp.addControl('PostCode', this.getControl('PostCode_Phy'));
        this.AddressGrp.addControl('State', this.StateCtrl);
        this.AddressGrp.addControl('Country', this.CountryCtrl);
        super.OnInit();
    }

    OnLoad() {
        this._countries = CommonConstants.GET_COUNTRIES.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
        this._mailingCountries = this._countries.slice(0);
        // TODO: should find a more generic way to determine default country.
        const defaultCountry = this._countries.find(country => country.Name === 'Australia');
        this.CountryCtrl.setValue(defaultCountry && defaultCountry.CountryId);
        this.MailingCountryCtrl.setValue(defaultCountry && defaultCountry.CountryId);
        if (defaultCountry != null) {
            this.loadStatesByCountry(defaultCountry.CountryId, true);
        }
        this.commonSvc.getLocationData().subscribe(
            (res) => {
                console.log(res);
                if (res.Success) {
                    this.suburbList = res.Data.suburbs;
                }
            }
        );
    }

    OnLoadComplete() {
        let currentSuburbValue = '';
        setTimeout(() => {
            this.SuburbCtrl.valueChanges
            .pipe(
                skipWhile(value => value && typeof value === 'object'),
                debounceTime(APIConstant.API_DEBOUNCE_TIME),
                distinctUntilChanged(),
                filter(value => {
                    // Clear previous results
                    this._suburbSearchResults = [];
                    return typeof value === 'string';
                }),
                switchMap((value) => {
                    if (typeof value === 'string' && value.trim().length > 2) {
                        this.SuburbCtrl.markAsPending();
                        return value;
                    }
                    return empty();
                }),
                catchError((e) => { throw e; }))
            .subscribe(
                res => {
                    if(currentSuburbValue != this.SuburbCtrl.value) {
                        currentSuburbValue = this.SuburbCtrl.value;
                        this.SuburbCtrl.updateValueAndValidity({ emitEvent: false });
                        let getList = this.suburbList.filter(x => x.Name.toLowerCase().trim().includes(this.SuburbCtrl.value.toLowerCase().trim()) || x.Postcode.Name.includes(this.SuburbCtrl.value.toLowerCase().trim()));
                        this._suburbSearchResults = getList;
                        this.changeDetectorRef.markForCheck();
                    }
                },
                err => {
                    this.SuburbCtrl.updateValueAndValidity({ emitEvent: false });
                    this.handleError(err);
                    this.changeDetectorRef.markForCheck();
                });

            // this.SuburbCtrl.valueChanges
            //     .pipe(
            //         skipWhile(value => value && typeof value === 'object'),
            //         debounceTime(APIConstant.API_DEBOUNCE_TIME),
            //         distinctUntilChanged(),
            //         filter(value => {
            //             // Clear previous results
            //             this._suburbSearchResults = [];
            //             return typeof value === 'string';
            //         }),
            //         switchMap((value) => {
            //             if (typeof value === 'string' && value.length > 2) {
            //                 this.SuburbCtrl.markAsPending();
            //                 const criteria = new SearchPaginationCriteria();
            //                 criteria.PageSize = 0;
            //                 criteria.ViewColumns = null;
            //                 criteria.Criteria.Fields = [{ Name: 'name', Operator: SearchFieldOperator.Contains, Value: value }];
            //                 return this._serviceInvoker.GetServiceInstance(SuburbService).advancedSearch(criteria);
            //             }
            //             return empty();
            //         }),
            //         catchError((e) => { throw e; }))
            //     .subscribe(
            //         res => {
            //             this.SuburbCtrl.updateValueAndValidity({ emitEvent: false });
            //             if (res.Success) {
            //                 this._suburbSearchResults = res.Data.Results;
            //                 this.changeDetectorRef.markForCheck();
            //             } else {
            //                 this.HandleResponseError(res);
            //             }
            //             this.changeDetectorRef.markForCheck();
            //         },
            //         err => {
            //             this.SuburbCtrl.updateValueAndValidity({ emitEvent: false });
            //             this.handleError(err);
            //             this.changeDetectorRef.markForCheck();
            //         });
        });
        super.OnLoadComplete();
    }

    PatchValue(value, opts?) {
        super.PatchValue(value, opts);
        try {
            this.SuburbCtrl.setValue({ Id: value.SuburbId_Phy, Name: value.Suburb_Phy });
            this.MailingSuburbCtrl.setValue({ Id: value.SuburbId_Mail, Name: value.Suburb_Mail });

            if (!this.loaded) {
                // In case of load-on-demand, temporarily populate the lists with current options for displaying purpose.
                const postcodeId = <number>value['PostCodeId_Phy'];
                if (postcodeId) {
                    this._postcodes = [{ Id: value['PostCodeId_Phy'], Name: value['PostCode_Phy'] }];
                }

                const mailingPostcodeId = <number>value['PostCodeId_Mail'];
                if (mailingPostcodeId) {
                    this._mailingPostcodes = [{ Id: value['PostCodeId_Mail'], Name: value['PostCode_Mail'] }];
                }
            }
        } catch (error) {
            this._logger.error(error);
        }
    }

    /** @internal */
    _displayAutoText(obj?: CommonResponse) {
        return obj && obj.Name;
    }

    /** @internal */
    autoSuburb_OptionSelected(option: SuburbSearchResultRecord) {
        if (!option)
            return;
        this.form.patchValue({
            SuburbId_Phy: option.Id,
            Suburb_Phy: option.Name,
            PostCodeId_Phy: option.Postcode.Id,
            PostCode_Phy: option.Name
        });
        this._postcodes = [option.Postcode];
        this.StateCtrl.setValue(option.State.Id, { emitEvent: false });
        this.CountryCtrl.setValue(option.Country.Id, { emitEvent: false });
        this.RegionCtrl.setValue(option.Region ?
            option.Region.Name :
            'There is no region for the postcode ' + option.Postcode.Name);
    }

    /** @internal */
    autoMailingSuburb_OptionSelected(option: SuburbSearchResultRecord) {
        if (!option)
            return;
        this.form.patchValue({
            SuburbId_Mail: option.Id,
            Suburb_Mail: option.Name,
            PostCodeId_Mail: option.Postcode.Id,
            PostCode_Mail: option.Name
        });
        this._mailingPostcodes = [option.Postcode];
        this.MailingStateCtrl.setValue(option.State.Id, { emitEvent: false });
        this.MailingCountryCtrl.setValue(option.Country.Id, { emitEvent: false });
    }

    /** @internal */
    chkSameAsAbove_Change(e: Event) {
        if ((<HTMLInputElement>e.currentTarget).checked) {
            this.form.patchValue({
                StreetAddress1_Mail: this.getControlValue('StreetAddress1_Phy'),
                StreetAddress2_Mail: this.getControlValue('StreetAddress2_Phy'),
                StreetAddress_Mail_Desc: this.getControlValue('StreetAddress_Phy_Desc'),
                SuburbId_Mail: this.getControlValue('SuburbId_Phy'),
                Suburb_Mail: this.getControlValue('Suburb_Phy'),
                PostCodeId_Mail: this.getControlValue('PostCodeId_Phy'),
                PostCode_Mail: this.getControlValue('PostCode_Phy')
            }, { emitEvent: false });
        }
    }

    /** @internal */
    txtSuburb_Blur(e) {
        this.SuburbCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
        if (this.SuburbCtrl.dirty && !this.SuburbCtrl.pending) {
            this.SuburbCtrl.updateValueAndValidity({ emitEvent: false });
        }
    }

    /** @internal */
    txtSuburb_Focus(e) {
        this.SuburbCtrl.setValidators(Validators.required);
    }

    /** @internal */
    txtMASuburb_Blur(e) {
        this.MailingSuburbCtrl.setValidators([FPValidators.resolvableAutocompleteOption]);
        if (this.MailingSuburbCtrl.dirty && !this.MailingSuburbCtrl.pending) {
            this.MailingSuburbCtrl.updateValueAndValidity({ emitEvent: false });
        }
    }

    /** @internal */
    txtMASuburb_Focus(e) {
        this.MailingSuburbCtrl.setValidators(Validators.nullValidator);
    }

    private loadStatesByCountry(countryId: number, firstLoad = false) {
        this._states = CommonConstants.GET_STATES;
        this._mailingStates = this._states.slice(0);
        if (firstLoad) {
            super.OnLoad();
        }

        // this.Invoke(
        //     this._serviceInvoker.GetServiceInstance(CommonDataService).getStatesByCountryId(countryId),
        //     {
        //         onSuccess: (result: DataResult<State[]>) => {
        //             if (result.Success) {
        //                 this._states = result.Data.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
        //                 this._mailingStates = this._states.slice(0);
        //             } else {
        //                 this.HandleResponseError(result);
        //             }
        //             if (firstLoad) {
        //                 super.OnLoad();
        //             }
        //         }
        //     }
        // );
    }
}
