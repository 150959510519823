import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConstant } from 'src/app/constant/apiconstant';
import * as Models from 'src/app/models';
import { DataResult, Region, RequestPagination, SearchPaginationResult, ResultModel, CommonResponse } from 'src/app/models';
import { HttpDAO } from './httpdao.service';

@Injectable({
    providedIn: 'root'
})
export class RegionService {
    private httpDao: HttpDAO | null;

    constructor(httpClient: HttpClient) {
        this.httpDao = new HttpDAO(httpClient);
    }

    // Not in Use
    // getCountries(): Observable<Models.DataResult<Models.Country[]>> {
    //     return this.httpDao.getSingleData(APIConstant.API_GET_COUNTRIES);
    // }

    getAdditionalOption(): Observable<Models.DataResult<Models.RegionAdditionalOption[]>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_ADDITIONAL_OPTION);
    }

    // Not in Use
    // getStates(): Observable<Models.DataResult<Models.State[]>> {
    //     return this.httpDao.getSingleData(APIConstant.API_GET_STATES);
    // }

    // Not in Use
    // getStatesByCountryId(countryId: number): Observable<Models.DataResult<Models.State[]>> {
    //     return this.httpDao.getSingleData(APIConstant.API_GET_STATES_BY_COUNTRYID + countryId);
    // }

    searchPostcode(searchRequest: Models.SearchPostcodeRequest): Observable<Models.Postcode[]> {
        return this.httpDao.postData(APIConstant.API_SEARCH_POSTCODE, searchRequest);
    }

    get(id: number): Observable<Models.Region> {
        return this.httpDao.getSingleData(APIConstant.API_GET_REGION + id);
    }

    /**
     * Get the region by name.
     * @param name The name of region.
     * @returns The observable `DataResult`, with `Data` property containing the `Region` object
     * that matched the passed-in name if exists; otherwise, the value of `Data` is null.
     */
    getByName(name: string): Observable<Models.DataResult<Models.Region>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_REGION_BY_NAME + encodeURIComponent(name));
    }

    create(region: Models.Region): Observable<Models.CreateRegionResult> {
        return this.httpDao.postData(APIConstant.API_CREATE_REGION, region);
    }

    update(region: Models.Region): Observable<Models.ResultModel> {
        return this.httpDao.postData(APIConstant.API_UPDATE_REGION, region);
    }

    /**
     * Search for regions and return the results with pagination.
     *
     * @param request The request object containing the search criteria.
     * @returns {Observable<DataResult<SearchPaginationResult<Region>>>} The observable `DataResult`, with `Data` property containing the
     * `SearchPaginationResult` of type `Region`.
     * @memberof RegionService
     */
    search(request: RequestPagination): Observable<DataResult<SearchPaginationResult<Region>>> {
        return this.httpDao.postData(APIConstant.API_SEARCH_REGIONS, request);
    }

    /**
     * Re-assign postcode from a region to a new region.
     *
     * @param {Models.ReassignPostcodeToRegionRequest} request The request object containing the postcode's ID that will be re-assigned,
     * the old region's ID and the new region's ID to which the postcode will be re-assigned.
     * @returns {Observable<ResultModel>} The observable `ResultModel` containing the response from remote service.
     * @memberof RegionService
     */
    reassignPostcode(request: Models.ReassignPostcodeToRegionRequest): Observable<ResultModel> {
        return this.httpDao.postData(APIConstant.API_REASSIGN_POSTCODE_TO_REGION, request);
    }

    searchRegionOfEmployer(regionName: string): Observable<DataResult<CommonResponse>> {
        return this.httpDao.getSingleData(APIConstant.API_SEARCH_EMPLOYER_REGION_BY_NAME + encodeURIComponent(regionName));
    }
}
