import { CommonService } from 'src/app/services/common.service';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';
import { empty } from 'rxjs';
import { Employer, MemberSearchResultRecord, DataResult, SearchPaginationResult, SearchPaginationRequest, User } from 'src/app/models';
import { EmployerService } from 'src/app/services';
import { MessageBox } from 'src/app/services/common-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { RouterConstant } from 'src/app/constant/routerconstant';
import { MemberService } from 'src/app/services/member.service';
import { Router } from '@angular/router';
import { CommonString, StorageKey } from '@fp/constant';
import { ERole } from '@fp/enums/role.enum';

@Component({
    selector: 'app-memberssearch',
    templateUrl: './memberssearch.component.html',
    styleUrls: ['./memberssearch.component.css']
})
export class MemberssearchComponent implements OnInit, AfterViewInit, OnDestroy {
    currentUser: User;
    parentFocus: boolean = false;
    searchEmployerFG: UntypedFormGroup;
    isSearchingEmployer = false;
    isSearchingMember = false;
    employerName = new UntypedFormControl();
    memberSearch = new UntypedFormControl();
    employerSearchResults: Employer[];
    memberSearchResults: MemberSearchResultRecord[];
    routerConstant = RouterConstant;
    paging: SearchPaginationRequest = new SearchPaginationRequest();
    requestEmployers: SearchPaginationRequest;

    ngOnDestroy() {
        this.employerName = null;
        this.memberSearch = null;
        this.employerSearchResults = null;
        this.memberSearchResults = null;
        this.paging = null;
    }

    constructor(
        private employerSvc: EmployerService,
        private dialog: MatDialog,
        private memberSvc: MemberService,
        private router: Router,
        private comsrv: CommonService) {
        this.requestEmployers = {

            OrderBy: 'name',
            OrderByAsc: true,
            PageNumber: 0,
            PageSize: 0,
            Params: []
        };
    }

    ngOnInit() {
        this.comsrv.Header.title="View Members";
    }

    //Author: Yen
    ngAfterViewInit() {
        this.memberSearch.valueChanges.pipe(
            debounceTime(500),
            switchMap((value) => {
                const isfacilitatorrole = this.comsrv.GetSelectedRole() === ERole.Employer_Facilitator ? '1' : '0';
                if (this.paging !== null && this.paging !== undefined) {
                    this.paging.PageNumber = 1;
                    this.paging.PageSize = 500;
                    this.paging.OrderBy = 'lastname';
                    this.paging.Params = [
                        { Name: 'membername_number_email', Value: value },
                        { Name: 'userid', Value: this.comsrv.GetUser() },
                        { Name: 'isfacilitatorrole', Value: isfacilitatorrole },
                    ];
                }

                if (value != null && value.length >= 3) {
                    this.isSearchingMember = true;
                    if (this.comsrv.GetSelectedRole() === ERole.Employer_Facilitator){
                        return this.memberSvc.searchpaging(this.paging);
                    }
                    else{
                        return this.memberSvc.searchpaging(this.paging);
                    }
                } else {
                    this.memberSearchResults = [];
                    this.isSearchingMember = false;
                }
                return empty();
            }),
            catchError((e) => { throw e; })
        ).subscribe(
            (result: DataResult<SearchPaginationResult<MemberSearchResultRecord>>) => {
                this.isSearchingMember = false;
                if (result != null) {
                    if (result.Success) {
                        this.memberSearchResults = result.Data.Results;
                    } else {
                        console.error(result);
                    }
                }
            },
            error => {
                this.isSearchingMember = false;
                MessageBox.ShowError(this.dialog, 'An error occurred while trying to call a service');
                console.error(error);
            });

        this.employerName.valueChanges.pipe(
            debounceTime(500),
            switchMap((value) => {
                if (value != null && value.length >= 3) {
                    this.isSearchingEmployer = true;
                    const SelectedRole = this.comsrv.GetSelectedRole();
                    this.currentUser = <User>JSON.parse(this.comsrv.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT)));
                    if (SelectedRole === ERole.Employer_Facilitator) {
                        this.requestEmployers.Params = [
                            { Name: 'employer_facilitator', Value: this.currentUser.UserId },
                            { Name: 'name_code', Value: value }
                        ];
                    } else {
                        this.requestEmployers.Params = [{
                            Name: 'name_code',
                            Value: value
                        }];
                    }
                    return this.employerSvc.search(this.requestEmployers);
                } else {
                    this.employerSearchResults = [];
                    this.isSearchingEmployer = false;
                }
                return empty();
            }),
            catchError((e) => { throw e; })
        ).subscribe(
            (edata: string) => {
                const dectypted_data = this.comsrv.D_FP_ResponseData(edata);
                const rdata: DataResult<SearchPaginationResult<Employer>> = JSON.parse(dectypted_data);
                this.isSearchingEmployer = false;
                if (rdata != null) {
                    this.employerSearchResults = rdata.Data.Results;
                } else {
                    console.error(rdata);
                }
            },
            error => {
                this.isSearchingEmployer = false;
                MessageBox.ShowError(this.dialog, 'An error occurred while trying to call a service');
                console.error(error);
            }
        );

    }

    ddlSearchMemberAutoComplete_Selected(event) {
        if (event == null || event == undefined || event == '') {
            return;
        }
        this.router.navigate([RouterConstant.NAVIGATOR_MEMBER_EDIT + event.MemberId]);
    }

    ddlViewMemberAutoComplete_Selected(event) {
        if (event == null || event == undefined || event == '') {
            return;
        }
        this.router.navigate([RouterConstant.NAVIGATOR_MEMBER_VIEW + event.EmployerId]);
    }

    ddlSearchMember_DisplayWithFn(option: any) {
        if (!option) {
            return '';
        }
        return option.LastName + ', ' + option.FirstName;
    }

    ddlViewMember_DisplayWithFn(option: any) {
        if (!option) {
            return '';
        }
        return option.Name;
    }

    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }

    onBlurParent(focusNumbr) {
        this.parentFocus = focusNumbr;
    }

    getClassFocused(vlFocused) {
        if (this.parentFocus == vlFocused) {
            return "focused";
        } else {
            return "";
        }
    }

    btnMemberSearch_Click(event) {
        let val: string = this.memberSearch.value;
        if (val === null || val.trim() === '') {
            val = '   ';
        }
        this.router.navigate([RouterConstant.NAVIGATOR_MEMBER_VIEW_SEARCH + val]);
    }

}
