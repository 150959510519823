<div class="container-custom">
  <div class="container">
    <div class="logo-container">
      <img src="./assets/images/logo.png" class="logo" alt="Fitness Passport">
    </div>
    <h1 class="title">Confirm your Fitness Passport Account</h1>
    <form [formGroup]="confirmAccountForm" (ngSubmit)="onSubmit()" class="mt-4">
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" id="email" formControlName="email" class="form-control" placeholder="Enter your email"
          [ngClass]="confirmAccountForm.controls['email']?.value && !confirmAccountForm.controls['email']?.valid ? 'input-error' : ''">
        <div class="text-error text-sm pt-2" *ngIf="confirmAccountForm.controls['email']?.value">
          <div *ngIf="confirmAccountForm.controls['email'].errors?.email">Invalid email.</div>
          <div *ngIf="confirmAccountForm.controls['email'].errors?.required">Required.</div>
          <div *ngIf="confirmAccountForm.controls['email'].errors?.serverError">{{
            confirmAccountForm.controls['email'].errors?.serverError }}.</div>
        </div>
      </div>
      <div class="form-group">
        <label for="code">Code</label>
        <input type="text" id="code" formControlName="code" class="form-control" placeholder="Enter the code"
          [ngClass]="confirmAccountForm.controls['code']?.value && !confirmAccountForm.controls['code']?.valid ? 'input-error' : ''">
        <div class="text-error text-sm pt-2" *ngIf="confirmAccountForm.controls['code']?.value">
          <div *ngIf="confirmAccountForm.controls['code'].errors?.required">Required.</div>
          <div *ngIf="confirmAccountForm.controls['code'].errors?.serverError">{{
            confirmAccountForm.controls['code'].errors?.serverError }}.</div>
        </div>
      </div>
      <div class="form-group">
        <label for="newPassword">New Password</label>
        <div class="input-group"
          [ngClass]="(confirmAccountForm.controls['newPassword']?.dirty || confirmAccountForm.controls['confirmPassword']?.dirty) && !confirmAccountForm.controls['newPassword']?.valid ? 'input-error' : ''">
          <input [type]="fieldType['newPassword']" id="newPassword" formControlName="newPassword" class="form-control"
            placeholder="Enter new password">
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="fa cursor-pointer" [ngClass]="{
                  'fa-eye-slash': fieldType['newPassword'] === 'password',
                  'fa-eye': fieldType['newPassword'] === 'text'
                }" (click)="toggleFieldTextType('newPassword')"></i>
            </span>
          </div>
        </div>
        <div class="text-error text-sm pt-2"
          *ngIf="confirmAccountForm.controls['newPassword']?.dirty || confirmAccountForm.controls['confirmPassword']?.dirty">
          <div *ngIf="confirmAccountForm.controls['newPassword'].errors?.required">Required.</div>
        </div>
        <div class="password-rules text-sm pt-2 flex flex-col">
          <div class="block small-label">Password must contain</div>
          <ul class="small-txt password-rules-list">
            <li class="block"
              [ngClass]="confirmAccountForm.controls['newPassword']?.value?.match('^(?=.*[A-Z])') ? 'condition-met' : ''">
              At least one uppercase letter.</li>
            <li class="block"
              [ngClass]="confirmAccountForm.controls['newPassword']?.value?.match('(?=.*[a-z])') ? 'condition-met' : ''">
              At least one lowercase letter.</li>
            <li class="block"
              [ngClass]="confirmAccountForm.controls['newPassword']?.value?.match('(?=.*[0-9])') ? 'condition-met' : ''">
              At least one digit.</li>
            <li class="block"
              [ngClass]="confirmAccountForm.controls['newPassword']?.value?.match(specialCharRegex) ? 'condition-met' : ''">
              At least one special character.</li>
            <li class="block"
              [ngClass]="confirmAccountForm.controls['newPassword']?.value?.match('.{8,}') ? 'condition-met' : ''">At
              least 8 characters long.</li>
          </ul>
        </div>
      </div>
      <div class="form-group">
        <label for="confirmPassword">Confirm Password</label>
        <div class="input-group"
          [ngClass]="confirmAccountForm.controls['confirmPassword']?.dirty && !confirmAccountForm.controls['confirmPassword']?.valid ? 'input-error' : ''">
          <input [type]="fieldType['confirmPassword']" id="confirmPassword" formControlName="confirmPassword"
            class="form-control" placeholder="Confirm new password">
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="fa cursor-pointer" [ngClass]="{
                  'fa-eye-slash': fieldType['confirmPassword'] === 'password',
                  'fa-eye': fieldType['confirmPassword'] === 'text'
                }" (click)="toggleFieldTextType('confirmPassword')"></i>
            </span>
          </div>
        </div>
        <div class="text-error text-sm pt-2 flex flex-col"
          *ngIf="confirmAccountForm.controls['confirmPassword']?.dirty">
          <div *ngIf="confirmAccountForm.controls['confirmPassword'].errors?.required">Required.</div>
          <div *ngIf="confirmAccountForm.controls['confirmPassword'].errors?.passwordMismatch">Passwords do not match.
          </div>
        </div>
      </div>
      <button type="submit" [disabled]="!confirmAccountForm.valid" class="btn btn-primary">Confirm Account</button>
    </form>
    <div class="text-error mt-4" *ngIf="confirmAccountForm.errors?.serverError">
      <div>{{ confirmAccountForm.errors?.serverError }}</div>
    </div>
    <app-signup-marketing-disclaimer clickActionText="Confirm Account"></app-signup-marketing-disclaimer>
  </div>
</div>