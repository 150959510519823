<div class="member-continue-survey align-items-center heightContent100" *ngIf="isThankYouMessageVisible">
    <div class="text-center mx-auto">
        <p class="py-2 member-continue-survey-message">Thank you for completing the Fitness Passport survey! <br><br>
            You can thank your workplace for looking out for you, it was their initiative to offer one of the best
            employee benefit programs available, so you can reach your health and wellbeing goals. And your
            family can join you too! <br><br>
            If there is enough survey interest from employees, we will begin to build your Fitness Passport
            program. We look forward to communicating further details with you once your program is ready.</p>
        <span class="member-continue-survey-privacy-policy"
            (click)="openLink('https://fitnesspassport.freshdesk.com/support/solutions/articles/43000425224-privacy-policy')"
            title="Privacy Policy">Privacy Policy – Fitness Passport</span>
    </div>
</div>
<div class="member-continue-survey align-items-center heightContent100" *ngIf="isSurveyPendingdMessageVisible">
    <div class="text-center mx-auto">
        <p class="py-2 member-continue-survey-message">We noticed you haven't completed the Fitness Passport survey yet.
            Your input is valuable in helping us create a program that meets your needs and preferences <br><br>
            Completing the survey will only take a few minutes of your time.
            Your feedback will ensure that the program is tailored to you and your family's health and wellbeing goals.<br><br>
            Please take a moment to complete the survey. We appreciate your participation!</p>
        <div class="pt-3">
            <button class="px-5 py-2 btn btn-primary member-continue-survey-btn" (click)="ContinueSurvey()">CONTINUE SURVEY</button>
        </div>
        <span class="member-continue-survey-privacy-policy"
            (click)="openLink('https://fitnesspassport.freshdesk.com/support/solutions/articles/43000425224-privacy-policy')"
            title="Privacy Policy">Privacy Policy – Fitness Passport</span>
    </div>
</div>
<div class="member-continue-survey align-items-center heightContent100" *ngIf="isSurveyExpiredMessageVisible">
    <div class="text-center mx-auto">
        <p class="py-2 member-continue-survey-message">Thank you for your interest in the Fitness Passport survey. The
            survey is now closed, and we are currently out building your bespoke
            gym and pool facility list. <br><br>
            You can thank your workplace for looking out for you. It was their
            initiative to offer one of the best employee benefit programs available
            , so you can reach your health and wellbeing goals.
            And your family can join you too!  <br><br>
            Please keep your eye out for further communications on how to view
            your facilities and sign up to Fitness Passport.
            </p>
    </div>
</div>
<div class="member-continue-survey align-items-center heightContent100" *ngIf="isSurveyClosedMessageVisible">
    <div class="text-center mx-auto">
        <p class="py-2 member-continue-survey-message">Thank you for your interest in Fitness Passport.
            This survey and program is closed, please contact your workplace for
            more information.
            </p>
    </div>
</div>