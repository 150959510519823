import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { APIConstant } from 'src/app/constant/apiconstant';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterConstant } from 'src/app/constant/routerconstant';
import { UserService } from 'src/app/services/admin/user/user.service';
import { CommonString } from 'src/app/constant/common-string';
import { RequestPagination, DataResult, User, SearchPaginationResult, ConditionPagination } from 'src/app/models';
import { UserTransferData } from '../searchusers/searchusers.component';
import { CommonService } from '@fp/services';

@Component({
    selector: 'app-viewusers',
    templateUrl: './viewusers.component.html',
    styleUrls: ['./viewusers.component.css']
})
export class ViewusersComponent implements OnInit, OnDestroy {
    constructor(
        public router: Router,
        private route: ActivatedRoute,
        private service: UserService,
        private cmsrv: CommonService
    ) { }

    displayedColumns: string[] = ['LastName', 'FirstName', 'EmailAddress', 'IsActive', 'RoleNameDisplay', 'RegionNameDisplay',
        'Role', 'Region', 'EmployerName', 'action'];
    dataSource: MatTableDataSource<any>;
    searchBy: string = localStorage.getItem('searchBy');
    commonString = CommonString;

    @ViewChild(MatSort) sort: MatSort;

    idUser: number;
    firstName: string;
    lastName: string;

    action: string = '';
    id: number;

    length: number;
    pageSize: number;
    pageSizeOptions: number[] = [10, 20, 50];

    //pagging
    usersPagination: RequestPagination = new RequestPagination();

    ngOnDestroy() {
        this.displayedColumns = null;
        this.dataSource = null;
        this.searchBy = null;
        this.idUser = null;
        this.firstName = null;
        this.lastName = null;
        this.action = null;
        this.id = null;
        this.length = null;
        this.pageSize = null;
        this.pageSizeOptions = null;
        this.usersPagination = null;
    }

    ngOnInit() {
        if (this.router.url.indexOf('dashboard/viewusers') > -1) {
            this.action = this.route.snapshot.queryParamMap.get('filter');
            this.id = +this.route.snapshot.queryParamMap.get('id');
            this.usersPagination.Params[0].Value = 0;

            this.usersPagination.Params[0].Name = this.action;
            if (this.id) {
                this.usersPagination.Params[0].Value = this.id;
            }
            if (this.action == 'all') {
                this.action = 'View All';
            }
            if (this.action == 'roleId' && this.id == 8) {
                let transferData = Object.assign({}, <UserTransferData>this.route.snapshot.queryParams);
                let conditions: Array<ConditionPagination> = [];
                this.usersPagination.Params[0].Conditions = conditions;
                if (transferData.employerNameId) {
                    conditions.push({
                        SearchKey: 'EmployerNameId',
                        Value: +transferData.employerNameId
                    });
                }
                if (transferData.facilitatorId) {
                    conditions.push({
                        SearchKey: 'FacilitatorId',
                        Value: +transferData.facilitatorId
                    });
                }
                if (transferData.employerCodeId) {
                    conditions.push({
                        SearchKey: 'EmployerCodeId',
                        Value: +transferData.employerCodeId
                    });
                }
                if (transferData.employerParentId) {
                    conditions.push({
                        SearchKey: 'EmployerParentId',
                        Value: +transferData.employerParentId
                    });
                }
                if (transferData.employerRegionId) {
                    conditions.push({
                        SearchKey: 'EmployerRegionId',
                        Value: +transferData.employerRegionId
                    });
                }
            }
            this.getUsers(this.usersPagination);
        } else {
            const val = this.route.snapshot.params.searchparam;
            this.usersPagination.Params[0].Value = decodeURIComponent(val);
            this.getUsersParam(this.usersPagination);
        }
    }

    paginatorChange(event: PageEvent) {
        if (this.router.url.indexOf('dashboard/viewusers') > -1) {
            this.usersPagination.PageNumber = event.pageIndex + 1;
            this.usersPagination.PageSize = event.pageSize
            this.getUsers(this.usersPagination);
        } else {
            this.usersPagination.PageNumber = event.pageIndex + 1;
            this.usersPagination.PageSize = event.pageSize
            this.getUsersParam(this.usersPagination);
        }
    }
    sortChange(sort: Sort) {
        if (this.router.url.indexOf('dashboard/viewusers') > -1) {
            const active: string = sort.active.toLowerCase();
            const isOderByAsc: boolean = sort.direction == 'asc' ? false : true;
            this.usersPagination.OrderBy = active;
            this.usersPagination.OrderByAsc = isOderByAsc;
            this.getUsers(this.usersPagination);
        } else {
            const active: string = sort.active.toLowerCase();
            const isOderByAsc: boolean = sort.direction == 'asc' ? false : true;
            this.usersPagination.OrderBy = active;
            this.usersPagination.OrderByAsc = isOderByAsc;
            this.getUsersParam(this.usersPagination);
        }
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }


    getUsers(usersPagination: RequestPagination) {
        const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(usersPagination));
        if (usersPagination.Params[0].Name === 'all') {
            this.service.postData(
                APIConstant.API_GET_ALL_USERS_SQL_PAGINATION,
                (data: any) => {
                    const dectypted_data = this.cmsrv.D_FP_ResponseData(data);
                    const rdata: DataResult<SearchPaginationResult<User>> = JSON.parse(dectypted_data);
                    this.dataSource = new MatTableDataSource<any>(rdata.Data.Results);
                    this.length = rdata.Data.TotalItem;
                },
                { Data: encrypteddata }
            );
        } else if (usersPagination.Params[0].Name === 'roleId' && usersPagination.Params[0].Conditions === null && this.searchBy !== CommonString.ROLE_EMPLOYER_FACILITATOR) {
            this.service.postData(
                APIConstant.API_GET_ALL_USERS_BY_ROLE_SQL_PAGINATION,
                (data: any) => {
                    const dectypted_data = this.cmsrv.D_FP_ResponseData(data);
                    const rdata: DataResult<SearchPaginationResult<User>> = JSON.parse(dectypted_data);
                    this.dataSource = new MatTableDataSource<any>(rdata.Data.Results);
                    this.length = rdata.Data.TotalItem;
                },
                { Data: encrypteddata }
            );
        } else {
            this.service.postData(
                APIConstant.API_GET_USERS_PAGINATION,
                (data: any) => {
                    const dectypted_data = this.cmsrv.D_FP_ResponseData(data);
                    const rdata: DataResult<SearchPaginationResult<User>> = JSON.parse(dectypted_data);
                    this.dataSource = new MatTableDataSource<any>(rdata.Data.Results);
                    this.length = rdata.Data.TotalItem;
                },
                { Data: encrypteddata }
            );
        }
    }

    getUsersParam(usersPagination: RequestPagination) {
        const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(usersPagination));
        this.action = 'View All';
        const self = this;
        this.cmsrv.StartGlobalProgressBar();
        this.service.postData(
            APIConstant.API_GET_ALL_USERS_SQL_PAGINATION_PARAM,
            (data: any) => {
                const dectypted_data = this.cmsrv.D_FP_ResponseData(data);
                const rdata: DataResult<SearchPaginationResult<User>> = JSON.parse(dectypted_data);
                self.dataSource = new MatTableDataSource<any>(rdata.Data.Results);
                self.length = rdata.Data.TotalItem;
                this.cmsrv.StopGlobalProgressBar();
            },
            { Data: encrypteddata }
        );
    }

    editUser(userId) {
        //comment: allow route back to viewusers when data is edited
        this.router.navigate(['/' + RouterConstant.ROUTER_DASHBOARD + '/' + RouterConstant.ROUTER_USER_EDIT], {
            queryParams: {
                id: userId,
                /*action_id: this.id,
                filter: this.route.snapshot.queryParamMap.get('filter')*/
            },
            skipLocationChange: false
        });
    }

    btnBackSearchUser() {
        this.router.navigate(['/' + RouterConstant.ROUTER_DASHBOARD + '/' + RouterConstant.ROUTER_USER_SEARCH]);
    }
}
