<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Add/Edit Facility  {{facility_name}}</h4>
<div class="mx-auto tabProgress w-75 d-none d-lg-flex mt-3">
    <div class="flex-fill text-center">
        <div class="card-icon rounded-circle mx-auto" [ngClass]="HandleStepsBarBgColor(FacilityTab.FacilityDetails)">
            <i *ngIf="HandleTickofSteps(FacilityTab.FacilityDetails)" class="fas fa-check text-light"></i>
        </div>
        <p class="card-text mb-3">Facility<br>Details</p>
    </div>
    <div class="flex-fill text-center">
        <div class="card-icon rounded-circle mx-auto" [ngClass]="HandleStepsBarBgColor(FacilityTab.PaymentDetails)">
            <i *ngIf="HandleTickofSteps(FacilityTab.PaymentDetails)" class="fas fa-check text-light"></i>
        </div>
        <p class="card-text mb-3">Payment<br>Details</p>
    </div>
    <div class="flex-fill text-center">
        <div class="card-icon rounded-circle mx-auto" [ngClass]="HandleStepsBarBgColor(FacilityTab.ContractDetails)">
            <i *ngIf="HandleTickofSteps(FacilityTab.ContractDetails)" class="fas fa-check text-light"></i>
        </div>
        <p class="card-text mb-3">Facility<br>
            Contract<br>
            Details</p>
    </div>
    <div class="flex-fill text-center">
        <div class="card-icon rounded-circle mx-auto" [ngClass]="HandleStepsBarBgColor(FacilityTab.VisitsPayment)">
            <i *ngIf="HandleTickofSteps(FacilityTab.VisitsPayment)" class="fas fa-check text-light"></i>
        </div>
        <p class="card-text mb-3">Facility<br>
            Visits<br>
            Payment</p>
    </div>
    <div class="flex-fill text-center">
        <div class="card-icon rounded-circle mx-auto"
            [ngClass]="HandleStepsBarBgColor(FacilityTab.ProductsAndServices)">
            <i *ngIf="HandleTickofSteps(FacilityTab.ProductsAndServices)" class="fas fa-check text-light"></i>
        </div>
        <p class="card-text mb-3">Products &<br> Services</p>
    </div>
    <div class="flex-fill text-center">
        <div class="card-icon rounded-circle mx-auto" [ngClass]="HandleStepsBarBgColor(FacilityTab.EmployerAccess)">
            <i *ngIf="HandleTickofSteps(FacilityTab.EmployerAccess)" class="fas fa-check text-light"></i>
        </div>
        <p class="card-text mb-3">Employer<br>
            Access</p>
    </div>
    <div class="flex-fill text-center">
        <div class="card-icon rounded-circle mx-auto" [ngClass]="HandleStepsBarBgColor(FacilityTab.Photos)">
            <i *ngIf="HandleTickofSteps(FacilityTab.Photos)" class="fas fa-check text-light"></i>
        </div>
        <p class="card-text mb-3">Additional <br> Attachments</p>
    </div>
    <div class="flex-fill text-center">
        <div class="card-icon rounded-circle mx-auto" [ngClass]="HandleStepsBarBgColor(FacilityTab.AuditLog)">
            <i *ngIf="HandleTickofSteps(FacilityTab.AuditLog)" class="fas fa-check text-light"></i>
        </div>
        <p class="card-text mb-3"> Audit Log</p>
    </div>
</div>
<div class="tabFPTheme tabFPThemeExt mb-5" #stepperContainer [style.height]="heighttabs">
    <mat-vertical-stepper #stepper [linear]="SetLinearForTab()" (selectionChange)="onStepChange($event)">
        <mat-step [stepControl]="form.get('FacilityDetailsTab')">
            <form id="facilitytb0">
                <ng-template matStepLabel>
                    <i class="icon-fp d-lg-none"
                        [ngClass]="{'fas fa-check': HandleTickofSteps(FacilityTab.FacilityDetails), 'bg-primary': _stepProgressBackgroundVisible(FacilityTab.FacilityDetails)}"></i>
                    Facility Details
                </ng-template>
                <app-facility-details [form]="form.get('FacilityDetailsTab')" [data]="data" #facilityDetails>
                </app-facility-details>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
        <mat-step [stepControl]="form.get('PaymentTab')">
            <form id="facilitytb1">
                <ng-template matStepLabel>
                    <i class="icon-fp d-lg-none"
                        [ngClass]="{'fas fa-check': HandleTickofSteps(FacilityTab.PaymentDetails), 'bg-primary': _stepProgressBackgroundVisible(FacilityTab.PaymentDetails)}"></i>
                    Payment Details
                </ng-template>
                <app-facility-payment [form]="form.get('PaymentTab')" [data]="data" [loadOnDemand]="true"
                    #facilityPayment>
                </app-facility-payment>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
        <mat-step [stepControl]="form.get('ContractTab')">
            <form id="facilitytb2">
                <ng-template matStepLabel>
                    <i class="icon-fp d-lg-none"
                        [ngClass]="{'fas fa-check': HandleTickofSteps(FacilityTab.ContractDetails), 'bg-primary': _stepProgressBackgroundVisible(FacilityTab.ContractDetails)}"></i>
                    Facility Contract Details
                </ng-template>
                <app-facility-contract [form]="form.get('ContractTab')" [data]="data" [loadOnDemand]="true"
                    #facilityContract>
                </app-facility-contract>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
        <mat-step [stepControl]="form.get('VisitsPaymentTab')">
            <form id="facilitytb3">
                <ng-template matStepLabel>
                    <i class="icon-fp d-lg-none"
                        [ngClass]="{'fas fa-check': HandleTickofSteps(FacilityTab.VisitsPayment), 'bg-primary': _stepProgressBackgroundVisible(FacilityTab.VisitsPayment)}"></i>
                    Facility Visits Payment
                </ng-template>
                <app-facility-visits-payment [form]="form.get('VisitsPaymentTab')" [data]="data" [loadOnDemand]="true">
                </app-facility-visits-payment>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
        <mat-step [stepControl]="form.get('ProductsServicesTab')">
            <form id="facilitytb4">
                <ng-template matStepLabel>
                    <i class="icon-fp d-lg-none"
                        [ngClass]="{'fas fa-check': HandleTickofSteps(FacilityTab.ProductsAndServices)}"></i>
                    Products & Services
                </ng-template>
                <app-facility-products-services [form]="form.get('ProductsServicesTab')" [data]="data"
                    [loadOnDemand]="true" #facilityProductsServices></app-facility-products-services>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
        <mat-step [stepControl]="form.get('EmployerAccessTab')">
            <form id="facilitytb5">
                <ng-template matStepLabel>
                    <i class="icon-fp d-lg-none"
                        [ngClass]="{'fas fa-check': HandleTickofSteps(FacilityTab.EmployerAccess)}"></i>
                    Employer Access
                </ng-template>
                <app-facility-employer-access [form]="form.get('EmployerAccessTab')" [data]="data" [loadOnDemand]="true"
                    #employerAccess>
                </app-facility-employer-access>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
        <mat-step>
            <form id="facilitytb6">
                <ng-template matStepLabel>
                    <i class="icon-fp d-lg-none"
                        [ngClass]="{'fas fa-check': HandleTickofSteps(FacilityTab.Photos)}"></i>Additional
                    Attachments</ng-template>
                <app-facility-additional-attachments [form]="form.get('PhotosTab')" [data]="data" [loadOnDemand]="true"
                    #facilityPhoto>
                </app-facility-additional-attachments>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
        <mat-step [stepControl]="form.get('AuditLogTab')">
            <form id="facilitytb7">
                <ng-template matStepLabel>
                    <i class="icon-fp d-lg-none"
                        [ngClass]="{'fas fa-check': HandleTickofSteps(FacilityTab.AuditLog), 'bg-primary': _stepProgressBackgroundVisible(FacilityTab.JournalAndAuditLog)}"></i>
                    Audit Log
                </ng-template>
                <app-facility-journal-and-audit-log [form]="form.get('AuditLogTab')" [data]="data"
                    [loadOnDemand]="true">
                </app-facility-journal-and-audit-log>
                <ng-container *ngTemplateOutlet="tmplButtons"></ng-container>
            </form>
        </mat-step>
    </mat-vertical-stepper>
</div>
<ng-template #tmplButtons>
    <div
        class="pt-2 pb-0 d-flex d-lg-block justify-content-between justify-content-md-start justify-content-between justify-content-md-start">
        <ng-template #tmplSaveAndNextButtons>
            <button *ngIf="!isReadonly && isEditMode" class="px-2 px-lg-5 py-2 btn btn-primary mr-3" type="button"
                (click)="btnSubmit_Click()">
                <i class="fas fa-check mr-2"></i>Save</button>
            <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="nextStep()">
                <i class="fas fa-arrow-circle-right mr-2"></i>Next</button>
        </ng-template>
        <button *ngIf="stepper.selectedIndex === stepper._steps?.length - 1; else tmplSaveAndNextButtons"
            class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="btnSubmit_Click(true)"
            [ngClass]="{ 'd-none': isReadonly }">
            <i class="fas fa-check mr-2"></i>{{isEditMode ? 'Save' : 'Submit'}}</button>
    </div>
</ng-template>

<!-- TODO: remove after implementation completed -->
<!-- #region DEBUG -->
<div *ngIf="_isDebugging" class="tabFPTheme mt-5">
    <pre>Request count: {{ data.requestQueueCount }}</pre>
    <pre>Form valid: {{ form.valid }}</pre>
    <ngb-accordion>
        <ngb-panel id="pnlFormJson">
            <ng-template ngbPanelTitle>
                Form JSON
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <pre>{{ JSON.stringify(form.getRawValue(), null, 4) }}</pre>
            </ng-template>
        </ngb-panel>
        <ngb-panel>
            <ng-template ngbPanelTitle>
                Data JSON
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <pre>{{ JSON.stringify(data.model, null, 4) }}</pre>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>
<!-- #endregion DEBUG -->