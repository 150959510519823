import {
  Component,
  Injector,
  isDevMode,
  ViewChild,
  ChangeDetectionStrategy
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FPFormBaseComponent,
  PackageFormBase,
  PackageFormMode
} from "@fp/components/base";
import { CommonMessage, RouterConstant } from "@fp/constant";
import { DataResult, PackageDetails, SearchPaginationParam } from "@fp/models";
import { MessageBox, PackageService, CommonService } from "@fp/services";
import { PackageDetailsComponent } from "./details/details.component";
import { PackageServiceListComponent } from "./service-list/service-list.component";
import { RolePermissionService } from '@fp/services/role-permission.service';

@Component({
  selector: "app-add-package",
  templateUrl: "./addpackage.component.html",
  styleUrls: ["./addpackage.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FPFormBaseComponent.provideExisting(AddPackageComponent)]
})
export class AddPackageComponent extends PackageFormBase {
  // TODO: remove after implementation completed.
  get isDebugging() {
    return isDevMode() && window["enableDebugging"] === true;
  }
  JSON = JSON;
  existingPackage = false;
  hasInit = false;
  isReadonly: boolean;

  @ViewChild("details") detailsSection: PackageDetailsComponent;
  @ViewChild("serviceList") serviceListSection: PackageServiceListComponent;

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      DetailsSection: PackageDetailsComponent.getFormGroup(),
      ServiceListSection: PackageServiceListComponent.getFormGroup()
    });
  }

  constructor(
    injector: Injector,
    private route: ActivatedRoute,
    private router: Router,
    private svc: PackageService,
    private cmsrv: CommonService
  ) {
    super(injector);
    this.async = false;
    this.form = AddPackageComponent.getFormGroup();
  }

  ngAfterViewChecked() {
    if(!this.hasInit) {
      this.hasInit = true;
      this.OnLoad();
    }
  }

  OnLoad() {
    const path = this.route.routeConfig.path;
    switch (path) {
      case RouterConstant.ROUTER_PACKAGE_ADD:
        this.data.mode = PackageFormMode.Add;
        this.existingPackage = false;
        break;
      case RouterConstant.ROUTER_PACKAGE_EDIT:
        this.data.mode = PackageFormMode.Edit;
        this.existingPackage = true;
        break;
      case RouterConstant.ROUTER_PACKAGE_CLONE:
        this.data.mode = PackageFormMode.Clone;
        this.existingPackage = false;
        break;
      default:
        this.data.mode = PackageFormMode.View;
        this.existingPackage = true;
        break;
    }
    switch (this.data.mode) {
      case PackageFormMode.Add:
        this.data.model.Id = 0;
        super.OnLoad();
        this.serviceListSection.OnLoad();
        this.detailsSection.OnLoad();
        break;
      case PackageFormMode.Edit:
      case PackageFormMode.Clone:
        let id = 0;
        if (this.data.mode === PackageFormMode.Edit) {
          id = parseInt(this.route.snapshot.params["id"], 10);
        } else {
          id = parseInt(this.route.snapshot.queryParams["sourceId"], 10);
        }
        if (id > 0) {
          this.Invoke(this.svc.getDetails(id), {
            onSuccess: (result: DataResult<PackageDetails>) => {
              if (result.Success) {
                if (result.Data == null) {
                  MessageBox.ShowError(this.dialog, "Package not found");
                } else {
                  this.data.model = result.Data;
                  if (this.data.mode === PackageFormMode.Clone) {
                    this.data.model.Id = 0;
                    this.data.model.Name = "";
                  }
                  this.PatchValue(this.data.model, { emitEvent: false });
                  this.form.markAsPristine();
                  try {
                    // Try loading the previous filter.
                    const params: SearchPaginationParam[] = [
                      {
                        Name: "region_ids",
                        Value:
                          this.data.model.RegionFilter === null
                            ? ""
                            : this.data.model.RegionFilter.join(",")
                      },
                      {
                        Name: "service_type_ids",
                        Value:
                          this.data.model.ProductServiceTypeFilter === null
                            ? ""
                            : this.data.model.ProductServiceTypeFilter.join(",")
                      },
                      {
                        Name: "packageid",
                        Value: id.toString()
                      }
                    ];
                    this.serviceListSection.OnLoad();
                    this.serviceListSection.RefreshSearch(params, {
                      keepSelection: true,
                      afterSearchCallback: () => {
                        this.serviceListSection.selection.clear();
                        this.serviceListSection.Select({
                          ServiceIds: this.data.model.ServiceIds,
                          ProductIds: this.data.model.ProductIds
                        });
                      }
                    });
                    this.detailsSection.OnLoad();
                  } catch (err) {
                    this._logger.error(err);
                  }
                }
              } else {
                this.HandleResponseError(result);
              }
              super.OnLoad();
            }
          });
        } else {
          this.form.disable({ emitEvent: false });
          super.OnLoad();
        }
        break;
      default:
        this.form.disable({ emitEvent: false });
        super.OnLoad();
        break;
    }
    this.checkPrivilegesForReadOnly();
  }

  handleSearchFiltersChanged(e: {
    regions: number[];
    serviceTypes: number[];
    offPeaks: boolean[];
  }) {
    if (e.regions.length === 0 || e.serviceTypes.length === 0) {
      this.serviceListSection.Clear();
      return;
    }
    const params: SearchPaginationParam[] = [
      {
        Name: "region_ids",
        Value: e.regions.join(",")
      },
      {
        Name: "service_type_ids",
        Value: e.serviceTypes.join(",")
      }
    ];
    this.serviceListSection.RefreshSearch(params);
  }

  btnSubmit_Click() {
    this.Validate();
    const data = this.applyValue(this.data.model);
    data.ModifiedBy = this.cmsrv.GetUser();
    this._logger.debug(data);

    if (!this.valid) {
      // TODO: remove this code block after implementation complete.
      if (this.isDebugging) {
        const invalidCtrls = this._findInvalidControls(this.form);
        console.log("Invalid controls:\n" + invalidCtrls.join("\n"));
      }
      return;
    }
    this.data.model = Object.assign(this.data.model, data);

    // check Facilitied and Services check or not
    if (data.ServiceIds.length == 0 && data.ProductIds.length == 0) {
      MessageBox.ShowError(
        this.dialog,
        "Please select at least one item from the Facilities and Services list"
      );
      return;
    }
    this.Invoke(this.svc.save(data), {
      onSuccess: (res: DataResult<number>) => {
        if (res.Success && res.Data > 0) {
          MessageBox.ShowInfo(
            this.dialog,
            CommonMessage.DATA_SAVE_SUCCESS_MESSAGE
          ).subscribe(r => {
            if (
              this.data.mode === PackageFormMode.Add ||
              this.data.mode === PackageFormMode.Clone
            ) {
              this.router.navigate([
                RouterConstant.NAVIGATOR_PACKAGE_EDIT,
                res.Data
              ]);
            } else if (this.data.mode === PackageFormMode.Edit) {
              window.location.reload();
            }
          });
        } else {
          this.HandleResponseError(res);
        }
      }
    });
  }

  /**
   * Sets the view and all fields to read only if any read only attribute on user role is true
   */
  private checkPrivilegesForReadOnly(): void {
    const rolePermissionService: RolePermissionService = this.injector.get(RolePermissionService);

    this.isReadonly = rolePermissionService.checkPrivilegesForReadOnly('View/edit packages (Restrict to Read Only)');

    if (this.isReadonly) {
      this.form.disable();
    }
  }
}
