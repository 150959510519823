<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">View Users</h4>
<!-- View Users -->

<div class="mt-lg-4">
    <form>
        <div *ngIf="router.url.indexOf('dashboard/viewusers') > -1">
            <div class="mt-lg-4" *ngIf="action.includes('All'); else notAll">
                View Users by <span class="text-primary">{{ action }}</span>
            </div>
            <ng-template #notAll>
                View Users by <span class="text-primary">{{ action.replace('Id', '') }}: {{ searchBy }}</span>
            </ng-template>
        </div>
        <div class="my-4">
            <div class="border-default rounded-10 border-2">
                <div class="tablecontainer">
                    <table mat-table matSort [dataSource]="dataSource" class="rounded-10"
                        (matSortChange)="sortChange($event)" matSort matSortActive="LastName" matSortDirection="desc"
                        matSortStart="desc" matSortDisableClear>
                        <!-- Name Column -->
                        <ng-container matColumnDef="UserId">
                            <th mat-header-cell *matHeaderCellDef> User Id </th>
                            <td mat-cell *matCellDef="let element"> {{element.UserId}} </td>
                        </ng-container>

                        <ng-container matColumnDef="LastName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="FirstName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="EmailAddress">
                            <th mat-header-cell *matHeaderCellDef>Email</th>
                            <td mat-cell *matCellDef="let element"> {{element.EmailAddress}} </td>
                        </ng-container>

                        <ng-container matColumnDef="IsActive">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element"
                                [ngClass]="element.IsActive === true || element.IsActive==='True' ? 'text-success':'text-muted'">
                                {{element.IsActive===true || element.IsActive==='True' ? "Active" : "Inactive"}} </td>
                        </ng-container>

                        <ng-container matColumnDef="RoleNameDisplay">
                            <th mat-header-cell
                                [ngClass]="action == 'View All' || (action == 'roleId' && id != 8) ? '' : 'hidden'"
                                *matHeaderCellDef>Role</th>
                            <td mat-cell
                                [ngClass]="action == 'View All' || (action == 'roleId' && id != 8) ? '' : 'hidden'"
                                *matCellDef="let element">
                                <div *ngIf="element.RoleNameDisplay != null">
                                    <span *ngIf="element.RoleNameDisplay.split(',').length == 1; else multipleRole">
                                        {{ element.RoleNameDisplay }}
                                    </span>
                                    <ng-template #multipleRole>
                                        <span [ngbPopover]="popContent" placement="top" triggers="mouseenter:mouseleave"
                                            #popover="ngbPopover">
                                            {{ element.RoleNameDisplay.split(',').length > 0 ? element.RoleNameDisplay.split(',')[0] : '' }}{{ element.RoleNameDisplay.split(',').length > 1 ? ", ..." : "" }}
                                        </span>
                                        <ng-template #popContent>
                                            <ul class="pl-4">
                                                <li *ngFor="let r of element.RoleNameDisplay.split(',')">
                                                    {{ r ? r : "" }}</li>
                                            </ul>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="RegionNameDisplay">
                            <th mat-header-cell
                                [ngClass]="(action === 'View All' || (action === 'roleId' && id !== 8)) ? '' : 'hidden'"
                                *matHeaderCellDef>Region</th>
                            <td mat-cell
                                [ngClass]="(action === 'View All' || (action === 'roleId' && id !== 8)) ? '' : 'hidden'"
                                *matCellDef="let element">
                                <div *ngIf="element.RegionNameDisplay != null">
                                    <span *ngIf="element.RegionNameDisplay.split(',').length == 1; else multipleRegion">
                                        {{ element.RegionNameDisplay }}
                                    </span>
                                    <ng-template #multipleRegion>
                                        <span [ngbPopover]="popContent" placement="top" triggers="mouseenter:mouseleave"
                                            #popover="ngbPopover">
                                            {{ element.RegionNameDisplay.split(',').length > 0 ? element.RegionNameDisplay.split(',')[0] : '' }}{{ element.RegionNameDisplay.split(',').length > 1 ? ", ..." : "" }}
                                        </span>
                                        <ng-template #popContent>
                                            <ul class="pl-4">
                                                <li *ngFor="let r of element.RegionNameDisplay.split(',')">
                                                    {{ r ? r : "" }}</li>
                                            </ul>
                                        </ng-template>
                                    </ng-template>

                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Role">
                            <th mat-header-cell [ngClass]="(action === 'employerId' || 
                                action === 'regionId' || 
                                action === 'facilityId' || 
                                (action === 'roleId' && id === 8)) ? '' : 'hidden'" *matHeaderCellDef>Role</th>
                            <td mat-cell [ngClass]="(action === 'employerId' || 
                            action === 'regionId' || 
                            action === 'facilityId' || 
                            (action === 'roleId' && id === 8)) ? '' : 'hidden'" *matCellDef="let element">
                                <div *ngIf="element.Roles != null">
                                    <span *ngIf="element.Roles.length == 1; else multipleRole">
                                        {{ element.Roles[0].Name }}
                                    </span>
                                    <ng-template #multipleRole>
                                        <span [ngbPopover]="popContent" placement="top" triggers="mouseenter:mouseleave"
                                            #popover="ngbPopover">
                                            {{ element.Roles.length > 0 ? element.Roles[0].Name : '' }}{{ element.Roles.length > 1 ? ", ..." : "" }}
                                        </span>
                                        <ng-template #popContent>
                                            <ul class="pl-4">
                                                <li *ngFor="let r of element.Roles">{{ r.Name ? r.Name : "" }}</li>
                                            </ul>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Region">
                            <th mat-header-cell [ngClass]="(action === 'employerId' || 
                            action === 'regionId' || 
                            action === 'facilityId' || 
                            (action === 'roleId' && id === 8)) ? '' : 'hidden'" *matHeaderCellDef>Region</th>
                            <td mat-cell [ngClass]="(action === 'employerId' || 
                            action === 'regionId' || 
                            action === 'facilityId' || 
                            (action === 'roleId' && id === 8)) ? '' : 'hidden'" *matCellDef="let element">
                                <div *ngIf="element.Regions != null">
                                    <span *ngIf="element.Regions.length == 1; else multipleRegion">
                                        {{ element.Regions[0].Name }}
                                    </span>
                                    <ng-template #multipleRegion>
                                        <span [ngbPopover]="popContent" placement="top" triggers="mouseenter:mouseleave"
                                            #popover="ngbPopover">
                                            {{ element.Regions.length > 0 ? element.Regions[0].Name : '' }}{{ element.Regions.length > 1 ? ", ..." : "" }}
                                        </span>
                                        <ng-template #popContent>
                                            <ul class="pl-4">
                                                <li *ngFor="let r of element.Regions">{{ r.Name ? r.Name : "" }}</li>
                                            </ul>
                                        </ng-template>
                                    </ng-template>

                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="EmployerName">
                            <th mat-header-cell [ngClass]="action == 'roleId' && id == 8 ? '' : 'hidden'"
                                *matHeaderCellDef>Employer Name</th>
                            <td mat-cell [ngClass]="action == 'roleId' && id == 8 ? '' : 'hidden'"
                                *matCellDef="let element">
                                <div *ngIf="element.Employers != null">
                                    <span *ngIf="element.Employers.length == 1; else multipleRole">
                                        {{ element.Employers[0].Name }}
                                    </span>
                                    <ng-template #multipleRole>
                                        <span [ngbPopover]="popContent" placement="top" triggers="mouseenter:mouseleave"
                                            #popover="ngbPopover">
                                            {{ element.Employers.length > 0 ? element.Employers[0].Name : '' }}{{ element.Employers.length > 1 ? ", ..." : "" }}
                                        </span>
                                        <ng-template #popContent>
                                            <ul class="pl-4">
                                                <li *ngFor="let r of element.Employers">{{ r.Name ? r.Name : "" }}</li>
                                            </ul>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <a class="px-2" (click)="editUser(element.UserId)">
                                    <i class="fas fa-pen"></i>
                                </a>
                            </td>
                        </ng-container>
                        <!-- Trinh fixed [Bug][AM1] View Users - The layout of View Users is broken. -->
                        <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr> -->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <mat-toolbar [hidden]="dataSource == null" class="bg-white">
                    <mat-toolbar-row [hidden]="dataSource != null && dataSource.data.length == 0" class="p-0">
                        <mat-paginator class="w-100" [length]="length" [pageSize]="pageSize"
                            [pageSizeOptions]="pageSizeOptions" (page)="paginatorChange($event)" showFirstLastButtons>
                        </mat-paginator>
                    </mat-toolbar-row>
                    <mat-toolbar-row *ngIf="dataSource != null && dataSource.data.length == 0"
                        class="text-muted d-block p-2 bg-light text-center">
                        {{commonString.TABLE_NO_RECORD_FOUND}}
                    </mat-toolbar-row>
                </mat-toolbar>
            </div>
        </div>
        <button class="px-5 py-2 btn bg-white border-primary text-primary" type="button"
            (click)="btnBackSearchUser();"><i class="fas fa-arrow-alt-circle-left mr-2"></i>BACK</button>
    </form>
</div>