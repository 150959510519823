import { Component, Injector } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ModelBasedForm } from '@fp/components/base';
import { EmployerService } from '@fp/services';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-employer-code',
    templateUrl: './code.component.html',
    styleUrls: ['./code.component.css'],
    providers: [ModelBasedForm.provideExisting(EmployerCodeComponent)]
})
export class EmployerCodeComponent<T extends { Code: string, Abn: string }> extends ModelBasedForm<T> {
    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            Abn: [null],
            Code: new UntypedFormControl(null, { updateOn: 'blur', validators: [Validators.required, Validators.minLength(3), Validators.pattern(/^[A-Za-z]{0,3}$/)] })
        })
    }

    private originalEmployerCode: string;

    constructor(injector: Injector) { super(injector); }

    OnLoadComplete() {
        this.getControl('Code').setAsyncValidators(ctrl =>
            !ctrl.value || this.originalEmployerCode === ctrl.value ?
                null :
                this._serviceInvoker.Invoke(EmployerService, svc =>
                    svc.getByCode((<string>ctrl.value).toLowerCase())
                        .pipe(map(res => res.Success && (res.Data || []).length > 0 ? { duplicated: true } : null)))
        );
        super.OnLoadComplete();
    }

    txtEmployerCode_Focus(e: Event) {
        //this.getControl('Code').setValidators(Validators.required);
    }

    txtEmployerCode_Blur(e: Event) {
        console.log('Employer Code field blurred.');
        // const control = this.getControl('Code');
        // if (control.dirty) {
        //     control.setValidators([Validators.required, Validators.minLength(3), Validators.pattern(/^[A-Za-z]{0,3}$/)]);
        //     if (!control.pending) {
        //         control.updateValueAndValidity({ emitEvent: false });
        //     }
        // }
    }
}
