import { Component, Injector } from "@angular/core";
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { FacilityFormBase } from "@fp/components/base/facility-form-base";
import { FPFormBaseComponent } from "@fp/components/base/fp-form-base-component";
import { ActivityConstant } from "@fp/constant/activity-constants";
import { FacilityActivityType } from "@fp/models/facility-activity-type.model";
import { FacilityService } from "@fp/services/facility.service";
import { CommonMessage, CommonString } from 'src/app/constant';
import { FPValidators, StringHelper } from 'src/app/helpers';
import { Subscription } from "rxjs";
import { Message } from "@fp/models";
import { MessageService } from "@fp/services";

@Component({
  selector: "app-facility-activities-details",
  templateUrl: "./facility-activities-details.component.html",
  styleUrls: ["./facility-activities-details.component.css"],
  providers: [
    FPFormBaseComponent.provideExisting(FacilityActivitiesDetailsComponent),
    FacilityFormBase.provideExisting(FacilityActivitiesDetailsComponent),
  ],
})
export class FacilityActivitiesDetailsComponent extends FacilityFormBase {
  activityDetails: FacilityActivityType[];
  activityDetailsAccessOption: FacilityActivityType[];
  CommonMessage = CommonMessage;
  CommonString = CommonString;
  StringHelper = StringHelper;
  activityAccessoptionCheck = false;
  notActivityAccessoptionCheck = false;
  facilityAccesbilityId = 0;
  subscription: Subscription;


  constructor(injector: Injector, private svc: FacilityService, private messageService: MessageService) {
    super(injector);
  }

  static getFormGroup(): UntypedFormGroup {
    const fb = new UntypedFormBuilder();
    return fb.group({
      Activities: new UntypedFormArray([], [Validators.required, Validators.minLength(1)]),
    });
  }
  ngOnInit() {
    this.updateValidators(true);
    this.subscription = this.messageService.currentMessage.subscribe((message: Message) => {
      if (message && message.Identifier == CommonString.FacilityPotentialMessageIdentifier) {
        if (message.Content == 'True') {
          this.updateValidators(false);

        }
        else {
          this.updateValidators(true);
        }
      }
    });
  }
ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe(); 
    }
}
  ngAfterViewInit() {
    this.OnLoad();
  }

  OnLoad() {
    this.Invoke(this.svc.getActivities(), {
      onSuccess: (result) => {
        if (result.Success) {
          this.activityDetails = result.Data;        
          this.activityDetails = this.activityDetails.map((item) => {
            return { ...item, IsCheckedActivity: false };
          });  
          var AccessObject = this.activityDetails.find(item => item.Name === ActivityConstant.FACILITY_ACCESSIBLE_OPTIONS); 
          if(AccessObject && AccessObject.Id != null){
            this.facilityAccesbilityId = AccessObject.Id
          }          
          this.activityDetailsAccessOption = [AccessObject];       
          const indexOfAccessObject = this.activityDetails.findIndex(object => {
            return object.Name === ActivityConstant.FACILITY_ACCESSIBLE_OPTIONS;
          });
          this.activityDetails.splice(indexOfAccessObject, 1);                  
        } else {
          this.HandleResponseError(result);
        }
        super.OnLoad();
      },
    });
  }

  onCheckChange(event: MatCheckboxChange) {
    const formArray: UntypedFormArray = this.form.get("Activities") as UntypedFormArray;
    
    if (event.checked) {
      formArray.push(new UntypedFormControl({ "Id": event.source.value }));      
      if(formArray.value.some(el => el.Id === this.facilityAccesbilityId)){
         this.activityAccessoptionCheck = true;
         this.notActivityAccessoptionCheck = false;         
      }else{
         this.activityAccessoptionCheck = false;  
         this.notActivityAccessoptionCheck = true;       
      }
    } else {
      let i: number = 0;

      formArray.controls.forEach((ctrl: UntypedFormControl) => {
        if (ctrl.value.Id == event.source.value) {
          formArray.removeAt(i);
          return;
        }

        i++;
      });

      if(formArray.value.some(el => el.Id === this.facilityAccesbilityId)){
        this.activityAccessoptionCheck = true;  
        this.notActivityAccessoptionCheck = false;       
      }else{
        this.activityAccessoptionCheck = false;   
        this.notActivityAccessoptionCheck = true;     
      }
    }
  }

  public PatchValue(value: { [key: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
    const formArray: UntypedFormArray = this.form.get("Activities") as UntypedFormArray;
    if(formArray.value.some(el => el.Id === this.facilityAccesbilityId)){
      this.activityAccessoptionCheck = true;  
      this.notActivityAccessoptionCheck = false;      
    }else{
      this.activityAccessoptionCheck = false;  
      this.notActivityAccessoptionCheck = true;      
    }
    var initialActivities = value.Activities;
    
    if(value.Activities.find(item => item.Name === ActivityConstant.FACILITY_ACCESSIBLE_OPTIONS)){
      var AccessObject = initialActivities.find(item => item.Name === ActivityConstant.FACILITY_ACCESSIBLE_OPTIONS);
      var accessActivity = [AccessObject];
      const indexOfAccessObject = initialActivities.findIndex(object => {
        return object.Name === ActivityConstant.FACILITY_ACCESSIBLE_OPTIONS;
      });
      initialActivities.splice(indexOfAccessObject, 1); 
      
    }else{
      
      initialActivities = value.Activities;
    }    
   
    if (initialActivities.length > 0) {
      for (var i = 0; i < initialActivities.length; i++) {
        var activityId1 = initialActivities[i].Id;
        for (var j = 0; j < this.activityDetails.length; j++) {
          var activityId2 = this.activityDetails[j].Id;
          if (activityId2 === activityId1) {
            this.activityDetails[j].IsCheckedActivity = true;
            if (!formArray.value.some(el => el.Id === activityId1)) {
              formArray.push(new UntypedFormControl({ "Id": activityId1 }));
            }

          }
        }
      }
    }

    if (accessActivity && accessActivity.length > 0) {
      for (var i = 0; i < accessActivity.length; i++) {
        var activityId1 = accessActivity[i].Id;
        for (var j = 0; j < this.activityDetailsAccessOption.length; j++) {
          var activityId2 = this.activityDetailsAccessOption[j].Id;
          if (activityId2 === activityId1) {
            this.activityDetailsAccessOption[j].IsCheckedActivity = true;
            if (!formArray.value.some(el => el.Id === activityId1)) {
              formArray.push(new UntypedFormControl({ "Id": activityId1 }));
            }

          }
        }
      }
    }
  }
  private updateValidators(required: boolean) {
    const formArray = this.getControl('Activities') as FormArray;
    if(!required){
      formArray.clearValidators();

  }else{
    formArray?.setValidators([Validators.required, Validators.minLength(1)]);
  }
  formArray?.updateValueAndValidity();
}
}
