import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild
} from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MemberFormBase } from "@fp/components/base";
import { Utils } from "@fp/helpers";
import {
  DataResult,
  Membership,
  MembershipPackage,
  SearchPaginationRequest,
  SearchPaginationResult
} from "@fp/models";
import { MembershipPackagesService, MessageBox } from "@fp/services";
import { FacilityGoogleMapComponent } from "../facitily-google-map/facitily-google-map.component";
import { Router, ActivatedRoute } from "@angular/router";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { RouterConstant } from "@fp/constant";
import { CommonConstants } from "@fp/constant/common-constants";
import { GoogleMapPageViewComponent } from "@fp/google-map-page-view/google-map-page-view.component";

@Component({
  selector: "app-member-membership-packages",
  templateUrl: "./membership-packages.component.html",
  styleUrls: ["./membership-packages.component.css"],
  providers: [
    { provide: MemberFormBase, useExisting: MemberMembershipPackagesComponent }
  ]
})
export class MemberMembershipPackagesComponent extends MemberFormBase {
  @Output() readonly packageSelect = new EventEmitter<MembershipPackage>();
  selectedPackage: MembershipPackage;
  dataSource: MembershipPackage[];
  showMessageBox: boolean = false;
  loadCompleted: boolean = false;
  MembershipStatusId: number;
  isAustralia = true; 
  get MembershipPackages() {
    return this.dataSource || [];
  }

  Utils = Utils;

  @ViewChild("googlemap") googlemap: GoogleMapPageViewComponent;
  @Output() buttonNextEvent: EventEmitter<boolean> = new EventEmitter();

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      Memberships: [[], [Validators.required, Validators.minLength(1)]]
    });
  }

  constructor(injector: Injector, private svc: MembershipPackagesService, private router: Router, private route: ActivatedRoute
  ) {
    super(injector);
  }

  OnLoad() {
    this.RefreshMembershipPackages(true);
  }

  emitButtonEventNext() {
    this.buttonNextEvent.emit(true);
  }

  emitButtonEventBack() {
    this.buttonNextEvent.emit(false);
  }

  public isSignup() {
    return this.commonSvc.App.isMemberSignup;
  }

  PatchValue(value, opts) {    
    this.isAustralia = value.Country.Name?.toUpperCase() !==
    CommonConstants.COUNTRY_NEW_ZEALAND;    
    const memberships = <Membership[]>value["Memberships"] || [];
    if (memberships.length > 0 && memberships[0]) {
      this.selectedPackage = <any>{
        MembershipPackageId: memberships[0].MembershipPackageId
      };
    }
    super.PatchValue(value, opts);
  }

  private getSearchParams() {
    const params = new SearchPaginationRequest();
    params.PageSize = 0;
    params.Params = [
      {
        Name: "employer_id",
        Value: this.data.model.EmployerId
      },
      {
        Name: "status",
        Value: true
      }
    ];
    params.OrderBy = "name";
    params.ViewColumns = ["membership_package_type"];
    return params;
  }

  public getDataSource() {
    return this.dataSource;
  }

  RefreshMembershipPackages(firstLoad = false) {
    const currentMembership = this.getControl("Memberships").value
      ? (<Membership[]>this.getControl("Memberships").value)[0]
      : null;
    if (!this.data.model.EmployerId) {
      if (firstLoad) {
        super.OnLoad();
      }
      return;
    }
    const params = this.getSearchParams();
    this.dataSource = [];
    this.Invoke(this.svc.search(params), {
      onSuccess: (
        res: DataResult<SearchPaginationResult<MembershipPackage>>
      ) => {
        if (res.Success) {
          this.dataSource = res.Data.Results;
          if (currentMembership) {
            if (
              this.selectedPackage &&
              currentMembership.MembershipPackage.EmployerId ===
              this.data.model.EmployerId
            ) {
              this.GetFacilityIdBySelectedPkgId(
                currentMembership.MembershipPackageId
              );
              this.selectMembershipPackage(
                res.Data.Results.find(
                  e =>
                    e.MembershipPackageId ===
                    currentMembership.MembershipPackageId
                )
              );
            } else {
              this.SubRMPackages(currentMembership, res);
            }
          } else {
            this.SubRMPackages(currentMembership, res);
          }
          this.AutoSelectTheHighestPackage(firstLoad);
        } else {
          this.HandleResponseError(res);
        }
        if (firstLoad) {
          super.OnLoad();
        }
      },
      onComplete: () => {
        this.loadCompleted = true;
        if (
          !this.data.model.MemberIdPrimary &&
          (!this.dataSource || this.dataSource.length === 0)
        ) {
          const supportLink = '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
          MessageBox.ShowOKCustom(this.dialog, "Error", "Your employer doesn't have any membership\
           packages to choose from yet. Please contact your Workplace Facilitator or send\
           us an enquiry via our support site " + supportLink, "OK")
        }
      },
      onError: (err) => {
        MessageBox.ShowError(this.dialog, "Sorry, we're having trouble connecting. Let's try that again.")
          .subscribe(res => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              window.location.reload();
            }
          });
        this._logger.error(err);
      }
    });
  }

  SubRMPackages(
    currentMembership: Membership,
    res: DataResult<SearchPaginationResult<MembershipPackage>>
  ) {
    if (res.Data.Results.length > 0) {
      this.selectMembershipPackage(res.Data.Results.find(e => e.IsDefault));
      const memPack = res.Data.Results.find(e => e.IsDefault);
      if (memPack) {
        this.GetFacilityIdBySelectedPkgId(memPack.MembershipPackageId);
      } else {
        this.getControl("Memberships").setValue([]);
      }
    } else {
      this.getControl("Memberships").setValue([]);
    }
  }

  selectMembershipPackage(
    membershipPackage: MembershipPackage,
    callback?: Function
  ) {
    this.selectedPackage = membershipPackage;
    const memberships =
      <Membership[]>this.getControl("Memberships").value || [];
    const membership = memberships[0] || <Membership>{};
    if (this.MembershipStatusId) {
      membership.MembershipStatusId = this.MembershipStatusId;
    }
    if (membershipPackage) {
      try {
        membership.MembershipPackageId = membershipPackage.MembershipPackageId;
        membership.MembershipPackage = membershipPackage;
        memberships[0] = membership;
        this.getControl("Memberships").setValue(memberships);
        if (typeof callback === "function") {
          callback();
        }
      } catch (err) {
        MessageBox.ShowError(this.dialog, "Sorry, we're having trouble connecting. Let's try that again.")
          .subscribe(res => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              window.location.reload();
            }
          });
        this._logger.error(err);
      }
    }
    this.packageSelect.emit(membershipPackage);
  }

  selectMembershipPackageAndLoadFacility(membershipPackage: MembershipPackage) {
    this.selectMembershipPackage(membershipPackage, () => {
      this.GetFacilityIdBySelectedPkgId(membershipPackage.MembershipPackageId);
    });
  }

  // # Map available Facilities
  public GetFacilityIdBySelectedPkgId(menPkgId: number) {
    const memberId = this.route.snapshot.params.id;
    if (!menPkgId) {
      return;
    }
    this.googlemap.startMapLoadSpinner();
    this.googlemap.LoadFacilitiesByMembershipPackageId(menPkgId, memberId);
  }

  private AutoSelectTheHighestPackage(firstLoad: boolean) {
    const memPack = this.dataSource.find(e => e.IsDefault);
    if (this.router.url === '/membersignup' && this.dataSource.length > 0 && (memPack === undefined || memPack === null)) {
      this.dataSource = this.dataSource.sort((a, b) => b.SinglePrice - a.SinglePrice);
      if (firstLoad) {
        this.selectMembershipPackage(this.dataSource[0]);
      }
    }
  }
}
