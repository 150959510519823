import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConstant } from 'src/app/constant/apiconstant';
import { CommonResponse, DataResult, SearchPaginationCriteria, SearchPaginationResult, SuburbSearchResultRecord  } from 'src/app/models';
import { HttpDAO } from './httpdao.service';

@Injectable({
    providedIn: 'root'
})

export class SuburbService {
    private httpDao: HttpDAO | null;

    constructor(httpClient: HttpClient) {
        this.httpDao = new HttpDAO(httpClient);
    }

    searchByName(name: string): Observable<CommonResponse[]> {
        return this.httpDao.getSingleData(APIConstant.API_GET_SUBURB_NAME_BY_NAME + encodeURIComponent(name));
    }

    advancedSearch(criteria: SearchPaginationCriteria): Observable<DataResult<SearchPaginationResult<SuburbSearchResultRecord>>> {
        return this.httpDao.postData(APIConstant.API_ADVANCED_SEARCH_SUBURB, criteria);
    }

    getFacilityMap(searchParameter: string, id: string, memberId?: string): Observable<any> {
        return this.httpDao.getSingleData(APIConstant.API_GET_FACILITY_MAPS + "?filterType=" + searchParameter + "&filterId=" + id + "&memberId=" + memberId);
    }
}