export enum EFacilityCategory {
    Gym = 1,
    PoolAquaticCentre = 2,
    LeisureCentre = 3,
    RecreationCentre = 4,
    SmallGroupTraining = 5,
    YogaPilates = 6,
    ClimbingCentre = 7,
    Other = 8
}
