<!-- Note: Else block syntax doesn't appear to work in root component app. -->
<div *ngIf="isConfirmAccountScreen;">
  <router-outlet></router-outlet>
</div>
<div *ngIf="isHideSideNav;">
  <app-header></app-header>
  <div class="loading-shade-global" *ngIf="commonservice.isGlobalLoadingResults">
    <mat-spinner [diameter]=50></mat-spinner>
  </div>
  <div class="container-fluid" style="overflow: auto;">
    <div class="row">
      <div class="fpsideContent pb-4 h-100vh w-100" [style.marginTop.px]="heightHeader">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<div *ngIf="!isConfirmAccountScreen && !isHideSideNav;" class="overflow-hidden" id="divFPMainContent">
  <app-header [ngClass]="(currentLogin && !isLoginSuccessDisplay && !isMemberSignup) ? 'd-none': '' "></app-header>

  <div class="loading-shade-global" *ngIf="commonservice.isGlobalLoadingResults">
    <mat-spinner [diameter]=50></mat-spinner>
  </div>

  <div class="container-fluid" style="overflow: auto;">
    <div class="row">
      <mat-sidenav-container class="example-container w-100" [class.example-is-mobile]="mobileQuery.matches">
        <mat-sidenav #sidenav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches"
          fixedTopGap={{heightHeader}} [opened]="opened" class="fpsidebar" [ngClass]="!mobileQuery.matches ? '':'w-75'">
          <div class="d-block d-lg-none">
            <app-navbar #navbar></app-navbar>
          </div>
          <nav class="sidebar pt-2" *ngIf="isLoginSuccessDisplay">
            <mat-accordion multi="true">
              <mat-expansion-panel *ngIf="!(rolepermission.MenuCreateEmployer==false &&
                                rolepermission.MenuUpdateEmployer==false &&
                                rolepermission.MenuUploadMembershipPackagePrice==false)">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  routerLinkActive="active" [ngClass]="(selecteditem == 1)?'active':''">
                  <span><i class="fas fa-user-tie mr-2"></i>Employers</span>
                </mat-expansion-panel-header>
                <ul>
                  <li routerLinkActive="active"><a routerLink="/dashboard/addemployer" (click)="clickItem(1)"
                      *ngIf="rolepermission.MenuCreateEmployer">Add
                      Employer</a></li>
                  <li routerLinkActive="active"><a routerLink="/dashboard/searchemployer" (click)="clickItem(1)"
                      *ngIf="rolepermission.MenuUpdateEmployer">{{rolepermission.MenuTextViewEditEmployer}}</a>
                  </li>
                  <li routerLinkActive="active" *ngIf="false"><a>Bulk Package Update</a></li>
                  <li routerLinkActive="active"><a routerLink="/dashboard/packagepriceupdates"
                      *ngIf="rolepermission.MenuUploadMembershipPackagePrice">Package Price
                      Updates</a></li>
                </ul>
              </mat-expansion-panel>
              <mat-expansion-panel
                *ngIf="!(rolepermission.MenuAddRegion==false && rolepermission.MenuViewEditRegion==false)">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  [ngClass]="(selecteditem == 2)?'active':''">
                  <span class="align-items-center"><i class="fas fa-globe-americas mr-2"></i>Regions</span>
                </mat-expansion-panel-header>
                <ul>
                  <li routerLinkActive="active"><a routerLink="/dashboard/createnewregion" (click)="clickItem(2)"
                      *ngIf="rolepermission.MenuAddRegion">Add
                      Region</a></li>
                  <li routerLinkActive="active"><a routerLink="/dashboard/searchregions" (click)="clickItem(2)"
                      *ngIf="rolepermission.MenuViewEditRegion">View/Edit
                      Region</a></li>
                </ul>
              </mat-expansion-panel>
              <mat-expansion-panel
                *ngIf="!(rolepermission.MenuAddUser==false && rolepermission.MenuViewEditUser==false)">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  [ngClass]="(selecteditem == 3)?'active':''">
                  <span class="align-items-center"><i class="fas fa-users mr-2"></i>Users</span>
                </mat-expansion-panel-header>
                <ul>
                  <li routerLinkActive="active"><a routerLink="/dashboard/addusers" (click)="clickItem(3)"
                      *ngIf="rolepermission.MenuAddUser">Add
                      User</a></li>
                  <li routerLinkActive="active"><a routerLink="/dashboard/searchusers" (click)="clickItem(3)"
                      *ngIf="rolepermission.MenuViewEditUser">View/Edit
                      User</a></li>
                </ul>
              </mat-expansion-panel>
              <mat-expansion-panel [disabled]="isDisabled" *ngIf="rolepermission.MenuViewProdServ">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  [ngClass]="(selecteditem == 4)?'active':''">
                  <a routerLink="/dashboard/viewproductsservices" (click)="clickItem(4)"><i
                      class="fab fa-servicestack mr-2"></i>Products
                    &
                    Services</a>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
              <mat-expansion-panel
                *ngIf="!(rolepermission.MenuViewEditFacility==false && rolepermission.MenuAddFacility==false)">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  [ngClass]="(selecteditem == 5)?'active':''">
                  <span class="align-items-center"><i class="fas fa-dumbbell mr-2"></i>Facilities</span>
                </mat-expansion-panel-header>
                <ul>
                  <li routerLinkActive="active">
                    <a [routerLink]="[RouterConstant.NAVIGATOR_FACILITY_ADD]" (click)="clickItem(5)"
                      *ngIf="rolepermission.MenuAddFacility">
                      Add Facility</a>
                  </li>
                  <li routerLinkActive="active">
                    <a [routerLink]="[RouterConstant.NAVIGATOR_FACILITY_SEARCH]" (click)="clickItem(5)"
                      *ngIf="rolepermission.MenuViewEditFacility">
                      {{rolepermission.MenuTextViewEditFacility}}</a>
                  </li>
                  <li routerLinkActive="active">
                    <a [routerLink]="[RouterConstant.NAVIGATOR_PARENT_GROUP_VIEW]" (click)="clickItem(5)"
                      *ngIf="rolepermission.MenuAddFacility">
                      Parent Groups</a>
                  </li>
                </ul>
              </mat-expansion-panel>
              <mat-expansion-panel [disabled]="isDisabled" *ngIf="rolepermission.MenuUserRole">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  [ngClass]="(selecteditem == 6)?'active':''">
                  <a routerLink="/dashboard/userroles" (click)="clickItem(6)"><i class="fas fa-users-cog mr-2"></i>User
                    Roles</a>
                </mat-expansion-panel-header>
              </mat-expansion-panel>

              <mat-expansion-panel
                *ngIf="!(rolepermission.MenuMemberVerify==false && rolepermission.MenuMemberViewEdit==false)">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  [ngClass]="(selecteditem == 7)?'active':''">
                  <span class="align-items-center"><i class="fas fa-user-check mr-2"></i>Members</span>
                </mat-expansion-panel-header>
                <ul>
                  <li routerLinkActive="active">
                    <a [routerLink]="[RouterConstant.NAVIGATOR_MEMBER_SEARCH]" (click)="clickItem(7)"
                      *ngIf="rolepermission.MenuMemberViewEdit">View/Edit
                      Member</a>
                  </li>
                  <li routerLinkActive="active">
                    <a [routerLink]="[RouterConstant.NAVIGATOR_MEMBER_VERIFICATION]" (click)="clickItem(7)"
                      *ngIf="rolepermission.MenuMemberVerify">
                      Member Verification</a>
                  </li>
                </ul>
              </mat-expansion-panel>
              <mat-expansion-panel
                *ngIf="!(rolepermission.MenuAddPackage==false && rolepermission.MenuEditPackage==false)">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  [ngClass]="(selecteditem == 8)?'active':''">
                  <span class="align-items-center"><i class="fas fa-cube mr-2"></i>Packages</span>
                </mat-expansion-panel-header>
                <ul>
                  <li routerLinkActive="active" *ngIf="rolepermission.MenuAddPackage">
                    <a [routerLink]="[RouterConstant.NAVIGATOR_PACKAGE]" (click)="clickItem(8)">Add
                      Package</a>
                  </li>
                  <li routerLinkActive="active" *ngIf="rolepermission.MenuEditPackage">
                    <a [routerLink]="[RouterConstant.NAVIGATOR_PACKAGE_SEARCH]"
                      (click)="clickItem(8)">{{rolepermission.MenuTextViewEditPackage}}</a>
                  </li>
                </ul>
              </mat-expansion-panel>
              <mat-expansion-panel
                *ngIf="!(rolepermission.ManuallyUpload==false && rolepermission.CreatePaymentRequest==false && rolepermission.ViewPaymentRequests==false && rolepermission.ViewPaymentsandInvoices==false && rolepermission.DebitProcessing==false && rolepermission.SystemParameters==false)">

                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  routerLinkActive="active" [ngClass]="(selecteditem == 9)?'active':''">
                  <span><i class="fas fa-coins mr-2"></i>Finance</span>
                </mat-expansion-panel-header>
                <ul>
                  <li routerLinkActive="active" *ngIf="rolepermission.ManuallyUpload"><a
                      routerLink="/dashboard/manuallyupload">Manually Upload Raw Visits</a>
                  </li>
                  <li routerLinkActive="active" *ngIf="rolepermission.CreatePaymentRequest"><a
                      routerLink="/dashboard/createpaymentrequest">Create Payment Request</a>
                  </li>
                  <li routerLinkActive="active" *ngIf="rolepermission.ViewPaymentRequests"><a
                      routerLink="/dashboard/viewpaymentrequests" [queryParams]="{country: 'austalia'}">View Payment
                      Requests (AU)</a>
                  </li>
                  <li routerLinkActive="active" *ngIf="rolepermission.ViewPaymentRequests"><a
                      routerLink="/dashboard/viewpaymentrequests" [queryParams]="{country: 'newZealand'}">View Payment
                      Requests (NZ)</a>
                  </li>
                  <li routerLinkActive="active" *ngIf="rolepermission.SystemParameters"><a
                      routerLink="/dashboard/systemparameters">System
                      Parameters</a></li>
                  <li routerLinkActive="active" *ngIf="rolepermission.ViewPaymentsandInvoices"> <a
                      routerLink="/dashboard/viewpaymentsandinvoices">
                      View Payments and Invoices</a></li>
                  <li routerLinkActive="active" *ngIf="rolepermission.DebitProcessing"> <a
                      routerLink="/dashboard/debitprocessing" [queryParams]="{country: 'australia'}">
                      Debit Processing (AU)</a></li>
                  <li routerLinkActive="active" *ngIf="rolepermission.DebitProcessing"> <a
                      routerLink="/dashboard/debitprocessing" [queryParams]="{country: 'newZealand'}">
                      Debit Processing (NZ)</a></li>
                  <li routerLinkActive="active" *ngIf="rolepermission.DebitProcessing">
                    <a routerLink="/dashboard/bulkupload">
                      Bulk Debit/Credit Upload</a>
                  </li>
                </ul>
              </mat-expansion-panel>

              <mat-expansion-panel [disabled]="isDisabled"
                *ngIf="commonservice.MenuForMember || commonservice.MenuForNewMember">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  routerLinkActive="!this.commonservice.App.isMemberMenuDisabled?'active'"
                  [ngClass]="{'active':matchActiveRoutes([RouterConstant.ROUTER_CHANGE_MEMBERSHIP_PACKAGE])}">
                  <a [routerLink]="this.commonservice.App.isMemberMenuDisabled?null:RouterConstant.NAVIGATOR_MEMBER_DETAILS"
                    (click)="clickItem(9)"><i class="fas fa-users-cog mr-2"></i>Member
                    Details</a>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
              <mat-expansion-panel *ngIf="commonservice.MenuForMember">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  [ngClass]="(selecteditem == 11 || selecteditem == 13)?'active':''">
                  <span class="align-items-center"><i class="fas fa-id-card mr-2"></i>Manage
                    Membership</span>
                </mat-expansion-panel-header>
                <ul>
                  <li routerLinkActive="!this.commonservice.App.isMemberMenuDisabled?'active'">
                    <a [routerLink]="this.commonservice.App.isMemberMenuDisabled?null:[RouterConstant.NAVIGATOR_MEMBERSHIP_CARDS]"
                      (click)="clickItem(11)">Membership
                      Cards</a>
                  </li>
                  <li routerLinkActive="!this.commonservice.App.isMemberMenuDisabled?'active'"
                    *ngIf="commonservice.MenuForFamilyMember && commonservice.MenuForActivePrimaryMember">
                    <a [routerLink]="this.commonservice.App.isMemberMenuDisabled?null:[RouterConstant.NAVIGATOR_SUSPEND_MEMBERSHIP]"
                      (click)="clickItem(13)">Suspend
                      Membership</a>
                  </li>
                </ul>
              </mat-expansion-panel>

              <mat-expansion-panel [disabled]="isDisabled"
                *ngIf="(commonservice.MenuForMember && commonservice.MenuForFamilyMember) || commonservice.MenuForNewPrimaryMember">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  routerLinkActive="!this.commonservice.App.isMemberMenuDisabled?'active'">
                  <a [routerLink]="this.commonservice.App.isMemberMenuDisabled?null:RouterConstant.NAVIGATOR_MEMBER_TRANSFER"
                    (click)="clickItem(10)"><i class="fas fa-exchange-alt mr-2"></i>Employer
                    Transfer</a>
                </mat-expansion-panel-header>
              </mat-expansion-panel>

              <mat-expansion-panel [disabled]="isDisabled"
                *ngIf="commonservice.MenuForMember || commonservice.MenuForNewPrimaryMember || commonservice.MenuForNewMember">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  routerLinkActive="!this.commonservice.App.isMemberMenuDisabled?'active'">
                  <a [routerLink]="this.commonservice.App.isMemberMenuDisabled?null:RouterConstant.NAVIGATOR_MEMBER_NOTIFICATIONS"
                    (click)="clickItem(15)"><i class="far fa-envelope-open mr-2"></i>Notifications</a>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
              <mat-expansion-panel
                *ngIf="(commonservice.MenuForMember && commonservice.MenuForFamilyMember) || commonservice.MenuForNewPrimaryMember">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'">
                  <span class="align-items-center"><i class="fas fa-file-contract mr-2"></i>Payments &
                    Contracts</span>
                </mat-expansion-panel-header>
                <ul>
                  <li routerLinkActive="this.commonservice.App.IsPaymentsVisible ? 'active'">
                    <a [routerLink]="this.commonservice.App.IsPaymentsVisible?null:[RouterConstant.NAVIGATOR_MEMBER_PAYMENTS_HISTORY] "
                      (click)="clickItem(14)">
                      Payment History & Receipts
                    </a>
                  </li>
                  <li routerLinkActive="!this.commonservice.App.isMemberMenuDisabled?'active'">
                    <a [routerLink]="this.commonservice.App.isMemberMenuDisabled?null:[RouterConstant.NAVIGATOR_MEMBER_CONTRACTS]"
                      (click)="clickItem(16)">Contracts</a>
                  </li>
                  <li routerLinkActive="!this.commonservice.App.isMemberMenuDisabled?'active'">
                    <a [routerLink]="this.commonservice.App.isMemberMenuDisabled?null:[RouterConstant.NAVIGATOR_MEMBER_UPDATE_PAYMENTS_DETAILS]"
                      (click)="clickItem(17)">Payment / Direct Debit Details</a>
                  </li>
                </ul>
              </mat-expansion-panel>
              <mat-expansion-panel hideToggle [disabled]="isDisabled">
                <mat-expansion-panel-header [expandedHeight]="'0px'" [collapsedHeight]="'40px'">
                  <a [routerLink]="
                      this.commonservice.App.isMemberMenuDisabled
                        ? null
                        : RouterConstant.NAVIGATOR_MEMBER_SECURITY_PREFERENCES
                    " (click)="clickItem(9)"><i class="fas fa-user-shield mr-2"></i>Security Preferences</a>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
              <mat-expansion-panel [disabled]="isDisabled"
                *ngIf="commonservice.MenuForMember || commonservice.MenuForNewMember">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  routerLinkActive="active">
                  <a href="https://fitnesspassport.freshdesk.com/support/home" target="_blank"
                    (click)="clickItem(12)"><i class="fas fa-headset mr-2"></i>Customer
                    Support</a>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
              <mat-expansion-panel [disabled]="isDisabled" *ngIf="commonservice.MenuForFacilityStaffMember">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  routerLinkActive="active">
                  <a [routerLink]="RouterConstant.NAVIGATOR_FACILITY_DETAILS"><i
                      class="fas fa-info-circle mr-2"></i>Facility Details</a>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
              <mat-expansion-panel [disabled]="isDisabled" *ngIf="commonservice.MenuForFacilityStaffMember">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  routerLinkActive="active">
                  <a [routerLink]="RouterConstant.NAVIGATOR_FACILITY_MEMBERS"><i
                      class="fas fa-user-check mr-2"></i>Member Status Check</a>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
              <mat-expansion-panel [disabled]="isDisabled" *ngIf="commonservice.MenuForFacilityStaffMember">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  routerLinkActive="active">
                  <a [routerLink]="RouterConstant.NAVIGATOR_FACILITY_MEMBERS_CHECKIN"><i
                      class="fas fa-user-plus mr-2"></i>Member Manual Check-in</a>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
              <mat-expansion-panel [disabled]="isDisabled" *ngIf="commonservice.MenuForFacilityStaffMember">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  routerLinkActive="active">
                  <a href="https://fitnesspassport.freshdesk.com/support/tickets/new" target="_blank"><i
                      class="fas fa-headset mr-2"></i>Contact Fitness
                    Passport</a>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
              <mat-expansion-panel [disabled]="isDisabled" *ngIf="commonservice.MenuForEmployerFacilitator">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  routerLinkActive="active">
                  <a href="https://fitnesspassport.freshdesk.com/support/home" target="_blank"><i
                      class="fas fa-headset mr-2"></i>Fitness Passport Support</a>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
              <!-- raining Material Begin-->
              <mat-expansion-panel [disabled]="isDisabled"
                *ngIf="rolepermission.MenuViewTrainingMaterial===true && rolepermission.MenuUploadTrainingMaterial===false">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  routerLinkActive="active">
                  <a [routerLink]="[RouterConstant.NAVIGATOR_TRAINING_MATERIAL_VIEW]"><i
                      class="fas fa-chalkboard-teacher mr-2"></i>Training
                    Material</a>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
              <mat-expansion-panel [disabled]="isDisabled"
                *ngIf="rolepermission.MenuViewTrainingMaterial===false && rolepermission.MenuUploadTrainingMaterial===true">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  routerLinkActive="active">
                  <a [routerLink]="[RouterConstant.NAVIGATOR_TRAINING_MATERIAL_UPLOAD]"><i
                      class="fas fa-chalkboard-teacher mr-2"></i>Training
                    Material</a>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
              <mat-expansion-panel [disabled]="isDisabled"
                *ngIf="rolepermission.MenuViewTrainingMaterial===true && rolepermission.MenuUploadTrainingMaterial===true">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  routerLinkActive="active">
                  <a [routerLink]="[RouterConstant.NAVIGATOR_TRAINING_MATERIAL_UPLOAD]"><i
                      class="fas fa-chalkboard-teacher mr-2"></i>Training
                    Material</a>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
              <!-- raining Material End-->
              <mat-expansion-panel *ngIf="rolepermission.MenuGenerateReport===true">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'">
                  <span class=" align-items-center"><i class="fas fa-file-invoice mr-2"></i>Reporting</span>
                </mat-expansion-panel-header>
                <ul>
                  <li routerLinkActive="active">
                    <a [routerLink]="[RouterConstant.NAVIGATOR_GENERATE_REPORTS]">Generate
                      Reports</a>
                  </li>
                </ul>
              </mat-expansion-panel>
              <!-- Report for EF and FSM -->
              <mat-expansion-panel *ngIf="IsEFOrFSM()">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'">
                  <span class=" align-items-center"><i class="fas fa-file-invoice mr-2"></i>Reporting</span>
                </mat-expansion-panel-header>
                <ul>
                  <li routerLinkActive="active">
                    <a [routerLink]="[RouterConstant.NAVIGATOR_GENERATE_REPORTS_EF_FSM]">Generate
                      Reports</a>
                  </li>
                </ul>
              </mat-expansion-panel>
              <!-- End -->
              <mat-expansion-panel *ngIf="rolepermission.MenuGenerateReport===true">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'">
                  <span class=" align-items-center"><i class="fas fa-robot mr-2"></i>Bot
                    Logs</span>
                </mat-expansion-panel-header>
                <ul>
                  <li routerLinkActive="active">
                    <a [routerLink]="[RouterConstant.NAVIGATOR_BOT_LOGS]">View Bot Logs</a>
                  </li>
                </ul>
              </mat-expansion-panel>
              <mat-expansion-panel *ngIf="(rolepermission.AddBankDetail || rolepermission.EditTermsConditions)">
                <mat-expansion-panel-header [expandedHeight]="'40px'" [collapsedHeight]="'40px'"
                  [ngClass]="(selecteditem == 3)?'active':''">
                  <span class="align-items-center"><i class="fas fa-users mr-2"></i>Admin</span>
                </mat-expansion-panel-header>
                <ul>
                  <li routerLinkActive="active"><a routerLink="/dashboard/uploadpostcode"
                      *ngIf="rolepermission.AddBankDetail">Add Postcodes</a></li>
                  <li routerLinkActive="active"><a routerLink="/dashboard/addbsb"
                      *ngIf="rolepermission.AddBankDetail">Add
                      BSBs (AU)</a></li>
                  <li routerLinkActive="active"><a routerLink="/dashboard/addbankcode"
                      *ngIf="rolepermission.AddBankDetail">Add
                      Bank Code (NZ)</a></li>
                  <li routerLinkActive="active"><a routerLink="/dashboard/edittermsconditions"
                      *ngIf="rolepermission.EditTermsConditions">Edit Terms and Conditions</a></li>
                </ul>
              </mat-expansion-panel>
            </mat-accordion>
          </nav>
        </mat-sidenav>
        <mat-sidenav-content>
          <div class="fpsideContent pb-4" [style.marginTop.px]="heightHeader"
            [ngClass]="(currentLogin && !isLoginSuccessDisplay && !isMemberSignup) ? 'h-100vh': '' ">
            <router-outlet></router-outlet>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  </div>

  <app-footer [ngClass]="(currentLogin && !isLoginSuccessDisplay) ? 'd-none': '' "></app-footer>
</div>