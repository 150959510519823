<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">
    Edit Terms and Conditions
</h4>

<form [formGroup]="EditTermFormGroup" class="row justify-content-between mt-4">
    <div class="col-lg-6">
        <div class="form-group row">
            <label for="txtSelectTerm" class="col-md-6 col-form-label">Select Terms and Conditions Type</label>
            <div class="col-lg-6">
                <div class="d-flex flex-column w-100">
                    <select class="form-control" id="selectTerm" formControlName="selectTerm"
                        (ngModelChange)="checkTerm($event)">
                        <option *ngFor="let term of selectTermsRegions" [ngValue]="term">{{term.Name}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-12" *ngIf="!selectGoCardlessOnly">
        <div class="form-group row">
            <label for="txtMainTerm" class="col-md-6 col-form-label">Main Terms and Conditions Text</label>
            <div class="col-lg-12">
                <ckeditor name="txtMainTermHtml" [editor]="Editor" formControlName="mainTerm" [config]="ckeConfig" (ready)="onReady($event)">
                </ckeditor>
            </div>
        </div>
    </div>

    <div class="col-lg-12" *ngIf="!selectGoCardlessOnly">
        <div class="form-group row">
            <label for="txtKeyTerm" class="col-md-6 col-form-label">Key Terms and Conditions Text</label>
            <div class="col-lg-12">
                <ckeditor name="txtKeyTermHtml" [editor]="Editor" formControlName="keyTerm" [config]="ckeConfig" (ready)="onReady($event)">
                </ckeditor>
            </div>
        </div>
    </div>

    <div class="col-lg-12" *ngIf="selectGoCardlessOnly">
        <div class="form-group row">
            <label for="txtGoCardlessTerm" class="col-md-6 col-form-label">GoCardless Terms and Conditions Text</label>
            <div class="col-lg-12">
                <ckeditor name="txtGoCardlessTermHtml" [editor]="Editor" formControlName="goCardlessTerm" [config]="ckeConfig" (ready)="onReady($event)">
                </ckeditor>
            </div>
        </div>
    </div>

    <div class="col-lg-12">
        <button class="px-2 px-lg-5 py-2 btn btn-primary mr-3" type="button" (click)="btnSubmit_Click()">
            Save</button>
    </div>

</form>
