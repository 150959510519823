import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FPAbstractComponent } from '@fp/components/base';
import { DialogResult } from '@fp/components/common-dialog/common-dialog.component';
import { APIConstant, CommonString, StorageKey } from '@fp/constant';
import { CommonConstants } from '@fp/constant/common-constants';
import { RouterConstant } from '@fp/constant/routerconstant';
import { EMemberSurvey } from '@fp/enums';
import { DataResult, Member, ResultModel, State, SuburbSearchResultRecord, SurveyResponse, SurveyTemplate, User } from '@fp/models';
import { MapFacilityData, PreSignupFacilityData } from '@fp/models/map-facility-data';
import { MemberContainer } from '@fp/models/member-container.model';
import { SurveyTemplateQuestion as SurveyTemplateQuestion } from '@fp/models/survey-template-question.model';
import { CommonService, HttpDAO, MemberService, MessageBox } from '@fp/services';
import { SurveyService } from '@fp/services/survey.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { SurveyTemplateContent } from '@fp/models/survey-template-content.model';
import { ActivityConstant } from '@fp/constant/activity-constants';

interface Location {
  lat: number;
  lng: number;
  zoom: number;
}

@Component({
  selector: 'app-pre-signup-member-survey',
  templateUrl: './pre-signup-member-survey.component.html',
  styleUrls: ['./pre-signup-member-survey.component.css']
})
export class PreSignupMemberSurveyComponent extends FPAbstractComponent implements OnInit {

  httpdao: HttpDAO | null;
  formGroup: FormGroup;
  CommonString = CommonString;
  suburbList: SuburbSearchResultRecord[];
  filteredSuburbList: SuburbSearchResultRecord[];
  surveyQuestions: SurveyTemplateQuestion[];
  surveyPageQuestions: SurveyTemplateContent;
  facilityList: MapFacilityData[];
  isSuburbsLoaded : boolean;
  isFacilitiesLoaded : boolean;
  isSurveyTemplateLoaded : boolean;
  memberId : number = 0;
  employerId : number = 0;
  firstTabId : number = 1;
  lastTabId : number = 0;
  pageNumber: number = 1;
  facilitySelectionTabId : number = 0;
  memberCountry : string;
  presetSuburbs : any[] = [];
  selectedTab : number = 1;
  workPlaceSuburb : string;
  currentPageTitle : string;
  currentPageDescription : string;
  empState: State;


  public location: Location = {
    lat: -23.698,
    lng: 133.8807,
    zoom: 4,
  };

  constructor(
    injector: Injector,
    private router: Router,
    private http: HttpClient,
    private commonService: CommonService,
    private memberService: MemberService,
    private surveyService: SurveyService,
    private formBuilder: FormBuilder
  ) {
    super(injector);
    const username = this.commonService.GetUser();
    if (!username) {
      this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
    }
    this.commonService.Header.title = "";
    this.formGroup = formBuilder.group({});
  }

  ngOnInit(): void {
    let isSurveyComplete = localStorage.getItem('surveyComplete');
    if (isSurveyComplete === 'true') {
      this.router.navigate([RouterConstant.NAVIGATOR_CONTINUE_PRE_SIGNUP_SURVEY]);
      return;
    }
    this.memberCountry = this.commonService.getUserCountry();

    if(this.memberCountry.toLowerCase() == CommonConstants.COUNTRY_NEW_ZEALAND.toLowerCase()){
      this.location = {
        lat: -40,
        lng: 174,
        zoom: 5,
      };
    }
    this.commonService.MenuForMember = false;
    this.commonService.MenuForFamilyMember = false;
    this.commonService.App.opened = false;
    this.commonService.Header.isLogin = false;
    this.httpdao = new HttpDAO(this.http);

    var userContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
    var memberContainer = <MemberContainer>JSON.parse(userContainer);
    var currentMember = <DataResult<Member>>JSON.parse(this.commonService.D_FP_AES256(memberContainer.Member));
    if(currentMember){
      this.memberId = currentMember.Data.MemberId;
      this.employerId = currentMember.Data.EmployerId;
      this.empState = currentMember.Data.Employer.State
    }
    else{
      this.showErrorAndSignout();
    }
    
    if(!this.isSuburbsLoaded){
      this.loadSuburbData();
    }

    if (!this.isFacilitiesLoaded) {
      this.loadFacilityData();
    }

    if(!this.isSurveyTemplateLoaded){
      this.loadSurveyData();
    }
  }

  private setPresetSuburbus(){
    this.formGroup.get("homeSuburb").valueChanges.pipe(
      distinctUntilChanged(),
    )
      .subscribe(value => {
        let index = this.presetSuburbs.findIndex(el => el.Label === "Home")
        if (value) {
          if (index > -1) {
            this.presetSuburbs.splice(index, 1);
          }
          let suburb = Object.assign({}, value);
          suburb.Label = "Home";
          this.presetSuburbs.push(suburb);
        }
        else {
          if (index > -1) {
            this.presetSuburbs.splice(index, 1);
          }
        }
      }
      );

    this.formGroup.get("workplaceSuburb").valueChanges.pipe(
      distinctUntilChanged(),
    )
      .subscribe(value => {
        let index = this.presetSuburbs.findIndex(el => el.Label === "Work")
        if (value) {
          if (index > -1) {
            this.presetSuburbs.splice(index, 1);
          }
          let suburb = Object.assign({}, value);
          suburb.Label = "Work";
          this.presetSuburbs.push(suburb);
        }
        else {
          if (index > -1) {
            this.presetSuburbs.splice(index, 1);
          }
        }
      }
      );
  }

  SubmitSurvey(){
    if(this.formGroup.valid){
      this.submitSurveyResponse();
    }
  }

  NextSurveyPageClicked() {
      if (this.selectedTab < this.lastTabId) {
          this.selectedTab++;
      }
      this.setCurrentPageTitleAndDescription();
      this.scrollToTop();
  }

  BackSurveyPageClicked() {
    if (this.selectedTab > this.firstTabId) {
        this.selectedTab--;
    }
    this.setCurrentPageTitleAndDescription();
    this.scrollToTop();
  }

  SurveyTabSelection(tabId: number): void {
    let isFirstTwoTabsCompleted = this.isTabCompleted(this.firstTabId) && this.isTabCompleted(this.facilitySelectionTabId);
    if (isFirstTwoTabsCompleted || (this.isTabCompleted(this.selectedTab) && tabId === this.selectedTab + 1)) {
        this.selectedTab = tabId;
    }
    else if(tabId === this.selectedTab - 1 && this.isTabCompleted(tabId)) {
        this.selectedTab = tabId;
    }
    this.setCurrentPageTitleAndDescription();
  }

  setCurrentPageTitleAndDescription() {
    let currentPage = this.surveyPageQuestions.Pages.find(page => page.PageNo === this.selectedTab);

    if (currentPage) {
      this.currentPageTitle = currentPage.Title;
      this.currentPageDescription = currentPage.Description
    }
  }

  isTabCompleted(tabId): boolean {

    if(!this.isFacilitiesLoaded && tabId == this.facilitySelectionTabId && this.selectedTab == this.facilitySelectionTabId){
      this.commonService.StartGlobalProgressBar();
    }

    let page = this.surveyPageQuestions.Pages.find(page => page.PageNo === tabId);
    if (!page) {
        return false; 
    }
    for (let question of page.Questions) {
        if (question.Mandatory) {
            let control = this.formGroup.get(question.UniqueIdentifier);
            if (!control || control.invalid || control.value === null || control.value === '') {
                return false;
            }
        }else{
            let control = this.formGroup.get(question.UniqueIdentifier);
            if (!control || control.invalid) {
                return false;
            }
        }
    }
    return true;
  }

  private transformAnswersToJsonString(): string {
    
    if (!this.formGroup || !this.formGroup.value) {
        console.error("Form group value is not defined.");
        return;
    }
    let surveyResponseObj: any = {};
    let resData = this.formGroup.value;
    this.workPlaceSuburb = resData.workplaceSuburb.Name + ", " + resData.workplaceSuburb.State.Name + ", " + resData.workplaceSuburb.Postcode.Name;
    
    for (let page of this.surveyPageQuestions.Pages) {
      for (let question of page.Questions) {

        if (question.Type === "SuburbSelection" && question.QuestionNo === 1) {
          surveyResponseObj[question.QuestionText] = {
              "SuburbId": resData.workplaceSuburb.Id
          };
        }
        else if (question.Type === "SuburbSelection" && question.QuestionNo === 2) {
          surveyResponseObj[question.QuestionText] = {
              "SuburbId": resData.homeSuburb.Id
          };
        }
        else if (question.Type === "RadioButton" && question.QuestionNo === 3) {
          surveyResponseObj[question.QuestionText] = resData.workStatus         
        }
        else if (question.Type === "RadioButton" && question.QuestionNo === 4) {
          surveyResponseObj[question.QuestionText] = resData.interestedMembership          
        }
        else if (question.CustomQuestionType === "FacilitySelection" && question.QuestionNo === 5) {
          let nominatedFacilities: any = {};
      
          if (resData.facility.NominatedFacilities && Array.isArray(resData.facility.NominatedFacilities)) {
              resData.facility.NominatedFacilities.forEach((facility: any, index: number) => {
                  nominatedFacilities[`NominatedFacilityOption${index + 1}`] = {
                      "FacilityId": facility.Id
                  };
              });
          }
          for (let i = 0; i < 3; i++) {
            let key = `NominatedFacilityOption${i + 1}`;
            if (!nominatedFacilities[key]) {
                nominatedFacilities[key] = null;
            }
          }
          if (resData.facility.NonlistedFacility !== null) {
              nominatedFacilities[question.SubQuestions[0].QuestionText] = resData.facility.NonlistedFacility;
          }else{
            nominatedFacilities[question.SubQuestions[0].QuestionText] = null
          }
          surveyResponseObj[question.QuestionText] = nominatedFacilities;
        }
        else if (question.CustomQuestionType === "ExistingFacilitySelection" && question.QuestionNo === 6) {
          let existingFacilities: any = {};
      
          if (resData.familtyMemberships.hasFamilyMembership === "no") {
              existingFacilities['I am a member at'] = null;
              existingFacilities['My family is a member at'] = null;
              existingFacilities['Currently registered out of FP'] = null;

          } else if (resData.familtyMemberships.hasFamilyMembership === "yes") {
              existingFacilities['I am a member at'] = resData.familtyMemberships.myMemberships ? {
                  "FacilityId": resData.familtyMemberships.myMemberships.FacilityId
              } : null;
      
              existingFacilities['My family is a member at'] = resData.familtyMemberships.familyMemberships ? {
                  "FacilityId": resData.familtyMemberships.familyMemberships.FacilityId

              } : null;
      
              existingFacilities['Currently registered out of FP'] = resData.familtyMemberships.otherMemberships ? resData.familtyMemberships.otherMemberships : null;
          }
          surveyResponseObj[question.QuestionText] = existingFacilities;
        }           
        else if (question.Type === "RadioButton" && question.QuestionNo === 7) {
          surveyResponseObj["In the past week, how frequently have you engaged in physical activity?"] = resData.physicalActivity         
        }
        else if (question.Type === "CheckBox" && question.QuestionNo === 8) {
          surveyResponseObj[question.QuestionText] = resData.reason;
        }
        else if (question.Type === "FreeText" && question.QuestionNo === 9) {
          if (resData.comments == null) {
              surveyResponseObj["Comments"] = null; 
          } else {
              surveyResponseObj["Comments"] = resData.comments;
          }
        }              
      }
    }
    
    surveyResponseObj['MemberId'] = this.memberId;
    surveyResponseObj['EmployerId'] = this.employerId;
    surveyResponseObj['Response Date'] = new Date();

    return JSON.stringify(surveyResponseObj);
  }

  private loadSuburbData(){

    this.Invoke(this.commonService.getLocationData(), {
      onSuccess: (res: DataResult<{suburbs:SuburbSearchResultRecord[]}>) => {
        if (res.Success) {
          this.isSuburbsLoaded = true;
          this.checkAndStopBusyIndicator();
          var suburbs = res.Data.suburbs;
          this.filteredSuburbList = suburbs.filter((sub) => sub.State.Id== this.empState.StateId);
          if(this.memberCountry.toLowerCase() == CommonConstants.COUNTRY_NEW_ZEALAND.toLowerCase() ){
            this.suburbList = suburbs.filter((sub) => sub.State.Id== CommonConstants.NewZealandStateId);
          }
          else{
            this.suburbList = suburbs.filter((sub) => sub.State.Id != CommonConstants.NewZealandStateId);
          }
        }
        else {
          this.showErrorAndSignout();
        }
      },
      onError: (err: any) => {
        this.showErrorAndSignout();
      },
    });
  }

  loadFacilityData() {
    this.surveyService.GetPreMemberSignupSurveyFacilityDetails(this.memberCountry, this.pageNumber)
      .subscribe(
        (res: DataResult<PreSignupFacilityData>) => {
            if (res.Success) {
                if (res.Data.CurrentPage === 1) {
                    this.facilityList = [];
                }
                this.facilityList = [...this.facilityList, ...res.Data.Facilities];
                this.formatFacilityInfo(this.facilityList);

                if (res.Data.CurrentPage < res.Data.TotalPages) {
                    this.pageNumber++;
                    this.loadFacilityData();
                } else {
                    this.isFacilitiesLoaded = true;
                    this.checkAndStopBusyIndicatorForFacilityMap();
                }    
            } else {
                this.showErrorAndSignout();
            }
        },
        (err: any) => {
            this.showErrorAndSignout(); 
        }
      );
  }

  formatFacilityInfo(facilities: MapFacilityData[]){
    //Setting tooltip
    if(facilities.length > 0){
      facilities.forEach( (facility) => {
        if(facility.Activities.length > 0){
          facility.Activities.forEach( (activity) => {

            if(activity.ActivityName.toLocaleLowerCase() == ActivityConstant.FACILITY_ACCESSIBLE_OPTIONS.toLocaleLowerCase()){
              activity.ActivityToolTip = "Accessible Options<br>(See Additional Info)"
            }
            else{
              activity.ActivityToolTip =activity.ActivityName;
            }
          })
        }

        if(!facility.Is24Hour){
          if(facility.FacilityStaffedHour.MondayFrom != null){
            facility.FacilityStaffedHour.MondayFrom = this.formatTime(facility.FacilityStaffedHour.MondayFrom);
          }
          if(facility.FacilityStaffedHour.MondayTo != null){
            facility.FacilityStaffedHour.MondayTo = this.formatTime(facility.FacilityStaffedHour.MondayTo);
          }

          if(facility.FacilityStaffedHour.TuesdayFrom != null){
            facility.FacilityStaffedHour.TuesdayFrom = this.formatTime(facility.FacilityStaffedHour.TuesdayFrom);
          }
          if(facility.FacilityStaffedHour.TuesdayTo != null){
            facility.FacilityStaffedHour.TuesdayTo = this.formatTime(facility.FacilityStaffedHour.TuesdayTo);
          }

          if(facility.FacilityStaffedHour.WednesdayFrom != null){
            facility.FacilityStaffedHour.WednesdayFrom = this.formatTime(facility.FacilityStaffedHour.WednesdayFrom);
          }
          if(facility.FacilityStaffedHour.WednesdayTo != null){
            facility.FacilityStaffedHour.WednesdayTo = this.formatTime(facility.FacilityStaffedHour.WednesdayTo);
          }

          if(facility.FacilityStaffedHour.ThursdayFrom != null){
            facility.FacilityStaffedHour.ThursdayFrom = this.formatTime(facility.FacilityStaffedHour.ThursdayFrom);
          }
          if(facility.FacilityStaffedHour.ThursdayTo != null){
            facility.FacilityStaffedHour.ThursdayTo = this.formatTime(facility.FacilityStaffedHour.ThursdayTo);
          }

          if(facility.FacilityStaffedHour.FridayFrom != null){
            facility.FacilityStaffedHour.FridayFrom = this.formatTime(facility.FacilityStaffedHour.FridayFrom);
          }
          if(facility.FacilityStaffedHour.FridayTo != null){
            facility.FacilityStaffedHour.FridayTo = this.formatTime(facility.FacilityStaffedHour.FridayTo);
          }
        }
      });
    } 
  }

  formatTime(time) {
    if (time !== null) {
      const hours = time.split(":")[0];
      const mins = time.split(":")[1];
      return hours + ":" + mins;
    }
  }

  private loadSurveyData(){

    this.Invoke(this.surveyService.GetActiveSurveyTemplateByType(EMemberSurvey.MemberPreSignupSurvey), {
      onSuccess: (res: DataResult<SurveyTemplate>) => {
        if (res.Success) {
          this.surveyPageQuestions = JSON.parse(res.Data.Template);
          this.setFormControls()
          this.isSurveyTemplateLoaded = true;
          this.setTabs();
          this.setCurrentPageTitleAndDescription();
          this.checkAndStopBusyIndicator();
        }
        else {
          this.showErrorAndSignout();
        }
      },
      onError: (err: any) => {
        this.showErrorAndSignout();
      },
    });
  }

  private setTabs() {
    for (let page of this.surveyPageQuestions.Pages) {
      if (this.lastTabId < page.PageNo) {
        this.lastTabId = page.PageNo;
      }
      for (let question of page.Questions) {
        if (question.CustomQuestionType === "FacilitySelection" && question.QuestionNo === 5) {
          this.facilitySelectionTabId = page.PageNo;
        }
      }
    }
  }

  private setFormControls(){
    this.surveyPageQuestions.Pages.forEach(templaePage => {
      templaePage.Questions.forEach(question => {

        if(question.Mandatory){
          this.formGroup.addControl(question.UniqueIdentifier, new FormControl(null, Validators.required));
        } 
        else{
          this.formGroup.addControl(question.UniqueIdentifier, new FormControl(null));
        }
        
      });
    })
  }

  private loadMemberDetails() {
    var currentUser = <User>JSON.parse(this.commonService.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT)));
    
    if (currentUser && currentUser.UserId > 0) {
      this.Invoke(
        this.memberService.getByUserForMember(currentUser.UserId),
        {
          onSuccess: (getMember: DataResult<Member>) => {
            let memberEncrypt = this.commonService.E_FP_AES256(
              JSON.stringify(getMember)
            );
            if (getMember.Data && !getMember.Data.MemberIdPrimary) {
              if (getMember.Data.MemberCard) {
                this.commonService.setCardForMemberPortal(
                  getMember.Data.MemberCard.Data.Results
                );
              }
              if (getMember.Data.MembershipSuspension) {
                this.commonService.SetMemSuspension(
                  getMember.Data.MembershipSuspension.Data
                );
              }
              if (getMember.Data.FamilyObject) {
                this.commonService.SetMemFamObj(
                  getMember.Data.FamilyObject
                );
              }
            }
    
            this.httpdao!.getSingleData(
              APIConstant.API_GET_ALL_PRIVILEGES_V2
            ).subscribe((getPrivileges) => {
              let newContainer = new MemberContainer();
              newContainer.Privileges = getPrivileges;
              newContainer.Member = memberEncrypt;
              const loginData = JSON.stringify(newContainer);
              localStorage.setItem(
                StorageKey.CONTAINER_OBJECT,
                loginData
              );
              console.log("Success setting Member");
              this.router.navigate([RouterConstant.NAVIGATOR_CONTINUE_PRE_SIGNUP_SURVEY]);
            });
          },
          onError: (err: any) => {
            this.commonService.SignOut();
          },
          onComplete: () => {
            this.scrollToTop();
          }
        }
      );
    } else {
      this.commonService.SignOut();
    }
  }

  private submitSurveyResponse() {

    var formattedSurveyResponse = this.transformAnswersToJsonString();

    if (formattedSurveyResponse) {
      let surveyResponse: SurveyResponse = {
        SurveyTemplateId: EMemberSurvey.MemberPreSignupSurvey,
        Response: formattedSurveyResponse,
        ResponseDate: new Date(),
        MemberId: this.memberId,
        WorkPlaceSuburb: this.workPlaceSuburb
      };

      this.Invoke(this.surveyService.saveSurveyResponse(surveyResponse), {
        onSuccess: (res: ResultModel) => {
          if (res.Success) {
            this.loadMemberDetails();
            localStorage.setItem('surveyComplete', 'true');
          } else {
            this.showErrorAndSignout();
          }
        },
        onError: (err) => {
          this.showErrorAndSignout();
        },
      });
    }
  }

  private checkAndStopBusyIndicator(){
    if(this.isSuburbsLoaded && this.isSurveyTemplateLoaded){
      this.setPresetSuburbus();
      this.commonService.StopGlobalProgressBar();
    }
  }

  private checkAndStopBusyIndicatorForFacilityMap(){
    if(this.isSuburbsLoaded && this.isSurveyTemplateLoaded && this.isFacilitiesLoaded){
      this.commonService.StopGlobalProgressBar();
    }
  }

  private showErrorAndSignout(){
    this.commonService.StopGlobalProgressBar();
    MessageBox.ShowError(
      this.dialog,
      "Sorry, there was a connection issue. Please try submitting the information again."
    )
      .subscribe((res) => {
        if (res.result.toLowerCase() === DialogResult.Ok) {
          this.commonService.SignOut();
        }
      });
  }

  private scrollToTop() {
    window.scrollTo(0, 0);
  }
}