<div class="d-md-none title-wrapper text-center mobile-title-shadow">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Payment Details</h1>
            </div>
        </div>
    </div>
</div>
<div class="payment-details-wrapper  form-wrapper-new">
    <div class="container">
        <form [formGroup]="paymentDetailsFormGroup">
            <fieldset [disabled]="isFormDisabled">
            <div class="row">
                <div class="col-12 mobile-margin"></div>
                <div class="col-12">
                    <div class="title-border mbr-registration-title">
                        <h2>Confirm Membership Package</h2>
                    </div>
                    <div class="member-details-content">
                        <package-cost-summary [loadOnDemand]="loadOnDemand"
                            [dataSource]="[data.model].concat(data.model.FamilyMembers || [])"
                            [membershipPackage]="data.model.Memberships ? data.model.Memberships[0]?.MembershipPackage : null">
                        </package-cost-summary>
                        <br>

                        <p class="table-intro">
                            The total price indicates your current weekly membership fee. This is the
                            minimum amount payable per week for your 12 month contract term.
                        </p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="title-border mbr-registration-title">
                        <h2>{{bankHeaderText }}</h2>
                    </div>
                    <div class="bankDetails-info member-details-content" *ngIf="isAus">
                        <p>
                            Payment for your Fitness Passport membership will be debited from your bank account.
                            <br /> Please provide the required details below:
                        </p>
                    </div>
                    <div class="bankDetails-info member-details-content" *ngIf="!isAus">
                        <p>
                            Payment for your Fitness Passport membership will be a direct debit from your bank account and processed by our payments partner – GoCardless.
                            <br /><br />Please provide the required details below in order to setup a Direct Debit Instruction:
                        </p>
                    </div>
                    <div class="bank-details-form-wrapper">
                        <div class="form-group row">
                            <label for="txtBankName" class="col-sm-4 col-form-label">Bank Name*</label>
                            <div class="col-sm-8">
                                <div class="d-flex flex-column w-100">
                                    <input type="text" maxlength="50" id="txtBankName" class="form-control"
                                        formControlName="txtBankName" [ngClass]="
                                        paymentDetailsFormGroup.get('txtBankName').invalid &&
                                        (paymentDetailsFormGroup.get('txtBankName').dirty ||
                                          paymentDetailsFormGroup.get('txtBankName').touched )
                                          ? 'form-control is-invalid'
                                          : 'form-control'
                                      " [maxlength]="200" />
                                    <div *ngIf="
                                        paymentDetailsFormGroup.get('txtBankName').invalid &&
                                        (paymentDetailsFormGroup.get('txtBankName').dirty ||
                                          paymentDetailsFormGroup.get('txtBankName').touched )
                                      ">
                                        <small *ngIf="
                                          paymentDetailsFormGroup.get('txtBankName').errors
                                            .required
                                        " class="invalid-feedback d-block">Bank Name is required</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="txtAccountName" class="col-sm-4 col-form-label">Account Name*</label>
                            <div class="col-sm-8">
                                <div class="d-flex flex-column w-100">
                                    <input type="text" maxlength="50" id="txtAccountName" class="form-control"
                                        formControlName="txtAccountName" [ngClass]="
                                        paymentDetailsFormGroup.get('txtAccountName').invalid &&
                                        (paymentDetailsFormGroup.get('txtAccountName').dirty ||
                                          paymentDetailsFormGroup.get('txtAccountName').touched )
                                          ? 'form-control is-invalid'
                                          : 'form-control'
                                      " />
                                    <div *ngIf="
                                        paymentDetailsFormGroup.get('txtAccountName').invalid &&
                                        (paymentDetailsFormGroup.get('txtAccountName').dirty ||
                                          paymentDetailsFormGroup.get('txtAccountName').touched )
                                      ">
                                        <small *ngIf="
                                          paymentDetailsFormGroup.get('txtAccountName').errors
                                            .required
                                        " class="invalid-feedback d-block">Account Name is required</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="txtBankCode" class="col-sm-4 col-form-label">{{bankCodeText}}*</label>
                            <div class="col-sm-8">
                                <div class="d-flex flex-column w-100">
                                    <input type="text" maxlength="50" id="txtBankCode" class="form-control"
                                        formControlName="txtBankCode" maxlength="6" numberOnly [ngClass]="
                                        paymentDetailsFormGroup.get('txtBankCode').invalid &&
                                        (paymentDetailsFormGroup.get('txtBankCode').dirty ||
                                          paymentDetailsFormGroup.get('txtBankCode').touched )
                                          ? 'form-control is-invalid'
                                          : 'form-control'
                                      " />
                                    <div *ngIf="
                                        paymentDetailsFormGroup.get('txtBankCode').invalid &&
                                        (paymentDetailsFormGroup.get('txtBankCode').dirty ||
                                          paymentDetailsFormGroup.get('txtBankCode').touched )
                                      ">
                                        <small *ngIf="
                                          paymentDetailsFormGroup.get('txtBankCode').errors
                                            .required
                                        " class="invalid-feedback d-block">{{bankCodeText}} is required</small>
                                        <small *ngIf="paymentDetailsFormGroup.get('txtBankCode').hasError('minlength')"
                                            class="invalid-feedback d-block">
                                            {{bankCodeText}} must be 6 digits long
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="txtAccountNo" class="col-sm-4 col-form-label">Account Number*</label>
                            <div class="col-sm-8">
                                <div class="d-flex flex-column w-100">
                                    <input type="text" maxlength="50" id="txtAccountNo" class="form-control"
                                        formControlName="BankAccountNumberMask" [ngClass]="
                                        paymentDetailsFormGroup.get('BankAccountNumberMask').invalid &&
                                        (paymentDetailsFormGroup.get('BankAccountNumberMask').dirty ||
                                          paymentDetailsFormGroup.get('BankAccountNumberMask').touched )
                                          ? 'form-control is-invalid'
                                          : 'form-control'
                                      " maxlength="9" numberOnly (focus)="txtAccNumber_Focus()"
                                        (focusout)="txtAccNumber_Focusout()" (keyup)="setBankAccountNo()"/>
                                    <input type="hidden" formControlName="BankAccountNumber">
                                    <div *ngIf="
                                        paymentDetailsFormGroup.get('BankAccountNumberMask').invalid &&
                                        (paymentDetailsFormGroup.get('BankAccountNumberMask').dirty ||
                                          paymentDetailsFormGroup.get('BankAccountNumberMask').touched )
                                      ">
                                        <small *ngIf="
                                          paymentDetailsFormGroup.get('BankAccountNumberMask').errors
                                            .required
                                        " class="invalid-feedback d-block">Account Number is required</small>
                                        <small *ngIf="paymentDetailsFormGroup.get('BankAccountNumberMask').hasError('minlength') ||
                                            paymentDetailsFormGroup.get('BankAccountNumberMask').hasError('maxlength')"
                                            class="invalid-feedback d-block">
                                            Account number invalid (should be 7 characters)
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!isAus" class="form-group row">
                            <label for="txtAccountType" class="col-sm-4 col-form-label">Account Type/Suffix*</label>
                            <div class="col-sm-8">
                                <div class="d-flex flex-column w-100">
                                    <input type="text" pattern="\d*" id="txtAccountType" class="form-control"
                                        [required]="!isAus" formControlName="txtAccountType" [ngClass]="
                                        paymentDetailsFormGroup.get('txtAccountType').invalid &&
                                        (paymentDetailsFormGroup.get('txtAccountType').dirty ||
                                          paymentDetailsFormGroup.get('txtAccountType').touched )
                                          ? 'form-control is-invalid'
                                          : 'form-control'" minlength="1" maxlength="3" numberOnly />
                                    <div *ngIf="
                                        paymentDetailsFormGroup.get('txtAccountType').invalid &&
                                        (paymentDetailsFormGroup.get('txtAccountType').dirty ||
                                          paymentDetailsFormGroup.get('txtAccountType').touched )
                                      ">
                                        <small *ngIf="
                                          paymentDetailsFormGroup.get('txtAccountType').errors
                                            .required
                                        " class="invalid-feedback d-block">Account Type/Suffix is required</small>
                                        <small *ngIf="paymentDetailsFormGroup.get('txtAccountType').errors && 
                                        !paymentDetailsFormGroup.get('txtAccountType').errors.required"                                     
                                        class="invalid-feedback d-block">Account Type/Suffix is invalid</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bankDetails-info" *ngIf="!isAus">
                            <p>
                                Payments by GoCardless. Read the <a href="https://gocardless.com/privacy/" class="bank-gocardless-hyperlink">GoCardless privacy notice</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fixed-mobile with-back-btn mt-5">

                <div class="row">
                    <div class="col-md-12">
                        <div class="d-flex">
                            <button
                                class="btn btn-outline-primary btn-outline-new d-flex align-items-center justify-content-center mr-4"
                                matStepperPrevious>
                                <i class="icon-previous"></i> Back
                            </button>
                            <button class="btn btn-primary btn-new d-flex align-items-center justify-content-center"
                                (click)="submitPaymentDetails()" [disabled]="!(paymentDetailsFormGroup.valid && data.model.SignupStep >= SignupStepEnum.PackageDetails)">
                                Confirm
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </fieldset>
        </form>
    </div>
</div>