import { CommonResponse } from "./../../../models/commonresponsemodel";
import { StorageKey } from "src/app/constant/storage-key";
import { environment } from "./../../../../environments/environment";
import { CommonDialogComponent } from "./../../common-dialog/common-dialog.component";
import { MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, ViewChild, OnDestroy, Inject } from "@angular/core";
import { MessageBox } from "src/app/services/common-dialog.service";
import { MatDialog } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { catchError, finalize, map } from "rxjs/operators";
import { Observable, of as observableOf } from "rxjs";
import { MemberSignupService } from "src/app/services/member-signup.service";
import { PatternConstant } from "../../../constant/patternconstant";
import {
  PinModel,
  ResultModel,
  DataResult,
  MemberModel,
  UserModel,
  User,
} from "src/app/models";
import { CommonService } from "src/app/services/common.service";
import { ReCaptcha2Component } from "ngx-captcha";
import { Router } from "@angular/router";
import { RouterConstant } from "src/app/constant";
import { DialogResult } from "src/app/components/common-dialog/common-dialog.component";
import { AuthenticationService } from "../../../services/authentication.service";
import { UserService } from "src/app/services/admin/user/user.service";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
})
export class SignupComponent implements OnInit, OnDestroy {
  signupFormGroup: UntypedFormGroup;
  btnSubmit = false;
  invalidPhone = false;
  mobileLength = 10;
  public codeVerified: boolean = false;
  unusedPhoneEmail: boolean = false;
  fitnessPassportWebsite: string = "https://www.fitnesspassport.com.au/";

  requestQueueCount = 0;

  // Author: Da Do - ReCaptcha - Plugin
  @ViewChild("captchaElem") captchaElem: ReCaptcha2Component;
  isCaptchaResponse = false;
  public siteKey = environment.CaptchaSiteKey;
  public ReCaptchaFormGroup: UntypedFormGroup;
  // End -------------------

  ngOnDestroy() {
    this.mobileLength = null;
    this.requestQueueCount = null;
  }

  constructor(
    private dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private msService: MemberSignupService,
    private commonSvc: CommonService,
    private router: Router,
    private authService: AuthenticationService,
    private userSvc: UserService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.CreateSignupForm();
    this.CreateCaptchaForm();
    localStorage.removeItem("user");
    this.commonSvc.App.currentLogin = false;
    this.commonSvc.App.opened = false;
  }

  private CreateSignupForm() {
    this.signupFormGroup = this._formBuilder.group({
      txtFirstName: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.VALID_NAME)],
      ],
      txtMidleName: [""],
      txtLastName: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.VALID_NAME)],
      ],
      txtMobileNum: ["", Validators.required],
      txtEmail: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.EMAIL)],
      ],
      secondaryEmail: [, Validators.pattern(PatternConstant.EMAIL)],
    });
  }

  private CreateCaptchaForm() {
    this.ReCaptchaFormGroup = this._formBuilder.group({
      rcpSignUpCaptcha: ["", Validators.required],
    });
  }

  public Invoke(source: Observable<any>, handleResultCallback: Function) {
    this.requestQueueCount++;
    this.commonSvc.StartGlobalProgressBar();
    source
      .pipe(
        catchError((e) => {
          MessageBox.ShowError(
            this.dialog,
            "Sorry, there was a connection issue. Please try submitting the information again."
          );
          console.log(e);
          throw e;
        }),
        finalize(() => {
          this.requestQueueCount--;
          if (this.requestQueueCount <= 0) {
            this.commonSvc.StopGlobalProgressBar();
            this.requestQueueCount = 0;
          }
        })
      )
      .subscribe((res) => {
        handleResultCallback(res);
      });
  }

  public btnCancel_Click() {
    this.document.location.href =  environment.logoutUrl;//this.fitnessPassportWebsite;
  }

  public runCheck(event) {
    this.checkPhoneEmailResponsive();
  }

  public checkPhoneEmailResponsive() {
    this.unusedPhoneEmail = false;
    if (
      this.signupFormGroup.get("txtEmail").dirty &&
      this.signupFormGroup.get("txtEmail").valid &&
      this.signupFormGroup.get("txtMobileNum").dirty
    ) {
      const pin: UserModel = {};
      let number = "";
      const tempnumber: string = this.signupFormGroup.get("txtMobileNum").value;

      if (this.mobileLength == 10 && tempnumber.indexOf("0") == 0) {
        number = this.signupFormGroup.get("txtMobileNum").value.replace(0, "");
      } else {
        number = this.signupFormGroup.get("txtMobileNum").value;
      }
      pin.EmailAddress = this.signupFormGroup
        .get("txtEmail")
        .value.toLowerCase()
        .trim();
      pin.MobileNumber = "+61" + number;

      this.msService.checkPhoneEmail(pin).subscribe((res) => {
        const ret = this.commonSvc.D_FP_ResponseData(res);
        const dtresult: DataResult<CommonResponse> = JSON.parse(ret);

        // pin has been verified
        if (dtresult.Success) {
          if (dtresult.Data != null) {
            if (dtresult.Data.Name === "There is no user") {
              console.log("Phone Email Verified unused ");
              this.unusedPhoneEmail = true;
            } else {
              console.log("Account pre-exists");
            }
          }
        }
      });
    }
  }

  public btnSubmit_Click() {
    this.btnSubmit = true;
    window.scrollTo(0, 0);
    if (!this.signupFormGroup.invalid) {
      // this.isCaptchaResponse = this.IsCaptchaComplete();
      if (this.isCaptchaResponse == true) {
        if (this.unusedPhoneEmail) {
          this.SendPin();
        } else {
          MessageBox.ShowConfirmLogin(
            this.dialog,
            "Your account already exists in the system. Please sign in to complete member sign up."
          ).subscribe((dialogResult) => {
            if (dialogResult.result.toLowerCase() === DialogResult.Ok) {
              localStorage.setItem("signupFlag", "true");
              this.router.navigate([RouterConstant.ROUTER_LOGIN]);
            } else {
              this.ReloadCaptcha();
            }
          });
        }
      }
    }
  }

  async SendPin() {
    const pin: PinModel = {};
    pin.IsSendEmail=true;
    pin.IsSendSMS=true;
    var txtMobileNum: string = this.signupFormGroup.get("txtMobileNum").value;
    if(txtMobileNum[0] == "0") {
      txtMobileNum = txtMobileNum.slice(1, txtMobileNum.length);
    }

    pin.Email = this.signupFormGroup.get("txtEmail").value.toLowerCase().trim();
    pin.MobileNumber = "+61" + txtMobileNum;
    this.Invoke(this.msService.sendPinCode(pin), (result: string) => {
      const dectypted_data = this.commonSvc.D_FP_ResponseData(result);
      const rdata: ResultModel = JSON.parse(dectypted_data);

      //   if (rdata.Success) {
      //     MessageBox.ShowPinDialog(this.dialog, this);
      //   } else {
      //     MessageBox.ShowError(
      //       this.dialog,
      //       "Sorry, there was a connection issue. Please try submitting the information again."
      //     );
      //     console.log(error);
      //   }
    });
    // Decoupled the display of dialog box from sendpin api call's return
    // Enhances UX
    this.commonSvc.StopGlobalProgressBar();
    MessageBox.ShowPinDialog(this.dialog, this);
  }

  // public verfiyPinCodeResponsive(codeEntered): Observable<boolean> {
  //   const pin: PinModel = {};
  //   pin.Pin1 = codeEntered;
  //   pin.Email = this.signupFormGroup.get("txtEmail").value.toLowerCase().trim();
  //   return this.msService.checkPinCode(pin).pipe(
  //     map((res) => {
  //       const ret = this.commonSvc.D_FP_ResponseData(res);
  //       const dtresult: DataResult<PinModel> = JSON.parse(ret);
  //       if (dtresult.Success) {
  //         // pin has been verified
  //         if (dtresult.Data.Status) {
  //           this.codeVerified = true;
  //         }
  //       }
  //       return this.codeVerified;
  //     })
  //   );
  // }

  public SubmitPinClick(pincode, popup: MatDialogRef<CommonDialogComponent>) {
    const pin: PinModel = {};
    pin.Email = this.signupFormGroup.get("txtEmail").value;
    pin.Pin1 = pincode;
    this.Invoke(this.msService.checkPinCode(pin), (result: string) => {
      const ret = this.commonSvc.D_FP_ResponseData(result);
      const dtresult: DataResult<PinModel> = JSON.parse(ret);
      if (dtresult.Success) {
        if (dtresult.Data.Status) {
          popup.close();
          MessageBox.ShowCreatePwdDialog(this.dialog, this);
        } else {
          popup.componentInstance.PinInvalid = true;
          this.codeVerified = false;
        }
      } else {
        MessageBox.ShowError(
          this.dialog,
          "Sorry, there was a connection issue. Please try submitting the information again."
        );
        console.log(dtresult.Errors);
      }
    });
  }

  // public SubmitPinClick(pin, popup: MatDialogRef<CommonDialogComponent>) {
  //   if (this.codeVerified == true) {
  //     popup.close();
  //     MessageBox.ShowCreatePwdDialog(this.dialog, this);
  //   } else {
  //     popup.componentInstance.PinInvalid = true;
  //     this.codeVerified = false;
  //   }
  // }

  public ResendPinClick() {
    const pin: PinModel = {};
    pin.IsSendEmail=true;
    pin.IsSendSMS=true;
    var txtMobileNum: string = this.signupFormGroup.get("txtMobileNum").value;
    if(txtMobileNum[0] == "0") {
      txtMobileNum = txtMobileNum.slice(1, txtMobileNum.length);
    }

    pin.Email = this.signupFormGroup.get("txtEmail").value.toLowerCase().trim();
    pin.MobileNumber = "+61" + txtMobileNum;
    this.Invoke(
      this.msService.resendPinCode(pin),
      (result: DataResult<PinModel>) => {
        if (result.Success) {
          console.log(result.Name);
        } else {
          MessageBox.ShowError(
            this.dialog,
            "Sorry, there was a connection issue. Please try submitting the information again."
          );
          console.log(result.Errors);
        }
      }
    );
  }

  public CreateMemberClick(
    password,
    popup: MatDialogRef<CommonDialogComponent>
  ) {
    let number = "";
    let pin: MemberModel = {};
    let user: UserModel = {};
    const tempnumber: string = this.signupFormGroup.get("txtMobileNum").value;
    if (this.mobileLength == 10 && tempnumber.indexOf("0") == 0) {
      number = this.signupFormGroup.get("txtMobileNum").value.replace(0, "");
    } else {
      number = this.signupFormGroup.get("txtMobileNum").value;
    }

    user.FirstName = this.signupFormGroup.get("txtFirstName").value;
    user.LastName = this.signupFormGroup.get("txtLastName").value;
    user.MiddleName = this.signupFormGroup.get("txtMidleName").value;
    user.EmailAddress = this.signupFormGroup
      .get("txtEmail")
      .value.trim()
      .toLowerCase();
    user.MobileNumber = "+61" + number;
    user.Password = password;
    user.SecondaryEmail = this.signupFormGroup.get("secondaryEmail").value
      ? this.signupFormGroup.get("secondaryEmail").value.trim().toLowerCase()
      : null;

    pin.FirstName = this.signupFormGroup.get("txtFirstName").value;
    pin.LastName = this.signupFormGroup.get("txtLastName").value;
    pin.MiddleName = this.signupFormGroup.get("txtMidleName").value;
    pin.PrimaryEmail = this.signupFormGroup
      .get("txtEmail")
      .value.trim()
      .toLowerCase();
    pin.MobileNumber = "+61" + number;
    pin.SecondaryEmail = this.signupFormGroup.get("secondaryEmail").value
      ? this.signupFormGroup.get("secondaryEmail").value.trim().toLowerCase()
      : null;
    pin.User = user;
    this.Invoke(this.msService.createMember(pin), (result: string) => {
      const ret = this.commonSvc.D_FP_ResponseData(result);
      const dtresult: DataResult<UserModel> = JSON.parse(ret);
      if (dtresult.Success) {
        this.Invoke(
          this.Login(dtresult.Data.UserName, dtresult.Data.Password, popup),
          (res) => {}
        );
      } else {
        MessageBox.ShowError(
          this.dialog,
          "Sorry, there was a connection issue. Please try submitting the information again."
        );
        console.log(dtresult.Errors);
      }
    });
  }

  private Login(
    username: string,
    password: string,
    popup
  ): Observable<boolean> {
    const userPool = this.authService.getUserPool();
    const cognitoUser = this.authService.getUser(userPool, username);
    const authenticationDetails = this.authService.getAuthenticationDetails(
      username,
      password
    );

    return Observable.create(
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {},
        onFailure: (err) => {
          MessageBox.ShowError(this.dialog, err.message);
          return observableOf(false);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          cognitoUser.completeNewPasswordChallenge(
            password,
            requiredAttributes,
            {
              onSuccess: async (result) => {
                const newAuthenticationDetails = this.authService.getAuthenticationDetails(
                  username,
                  password
                );
                this.GetCognitoToken(
                  cognitoUser,
                  newAuthenticationDetails,
                  username,
                  popup
                );
                return observableOf(true);
              },
              onFailure: function (err) {
                MessageBox.ShowError(this.dialog, err.message);
                return observableOf(false);
              },
            }
          );
        },
      })
    );
  }

  public GetCognitoToken(cognitoUser, authenticationDetails, user, popup) {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (result) => {
        this.commonSvc.SetUser(user);
        this.authService.setSessionData(result);

        this.userSvc.getByUsernameFirstLoginV2(user).subscribe(
          (res) => {
            if (res.Data) {
              this.commonSvc.SetUserObject(res.Data.UserObject);
              this.commonSvc.SetTermsConditions(res.Data.TermsAndConditions);
              this.commonSvc.SetSecondaryTermsConditions(
                res.Data.TermsAndConditionsSecondary
              );
              this.commonSvc.SetMedicalQuestions(res.Data.MedicalQuestions);
              this.commonSvc.SetEmployers(res.Data.employers);
              // this.commonSvc.SetFacilities(res.Data.AllFacilities); ##
            }
          },
          (err) => {
            console.error(err);
          },
          () => {
            this.commonSvc.Header.SetUILoginSuccess();
            popup.close();
            this.router.navigate([RouterConstant.NAVIGATOR_MEMBER_SIGNUP]);
            this.commonSvc.StopGlobalProgressBar();
          }
        );
      },
      onFailure: (err) => {
        this.commonSvc.StopGlobalProgressBar();
        MessageBox.ShowError(this.dialog, err.message);
      },
    });
  }

  //#region ReCaptcha
  // Author: Da Do
  private IsCaptchaComplete(): boolean {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      return false;
    } else {
      return true;
    }
  }

  public HandleCaptchaSuccess(captchaResponse: string): void {
    this.isCaptchaResponse = true;
  }

  private ReloadCaptcha() {
    this.captchaElem.reloadCaptcha();
    this.isCaptchaResponse = false;
    this.btnSubmit = false;
  }

  public CancelPinClick() {
    this.Invoke(
      this.msService.deletePinCode(
        this.signupFormGroup
          .get("txtEmail")
          .value.toLowerCase()
          .toLowerCase()
          .trim()
      ),
      (result) => {
        if (result.Success) {
          this.ReloadCaptcha();
        } else {
          MessageBox.ShowError(
            this.dialog,
            "Sorry, there was a connection issue. Please try submitting the information again."
          );
          console.log(result.Errors);
        }
      }
    );
  }

  public CancelPasswordClick() {
    this.ReloadCaptcha();
  }
  //#endregion
}