import { Injector, Input, Provider, forwardRef, Directive } from '@angular/core';
import { FPFormBaseComponent, ModelBasedFormSharedData } from '@fp/components/base/fp-form-base-component';
import { PackageDetails } from '@fp/models';

@Directive()
export abstract class PackageFormBase extends FPFormBaseComponent<PackageFormSharedData> {
    static provideExisting(type: any): Provider {
        return <Provider>{ provide: PackageFormBase, useExisting: forwardRef(() => type) };
    }

    constructor(injector: Injector) {
        super(injector);
        this.data.model = new PackageDetails();
    }
}

export enum PackageFormMode {
    View,
    Add,
    Edit,
    Clone
}

export class PackageFormSharedData extends ModelBasedFormSharedData<PackageDetails> {
    mode: PackageFormMode;
    constructor() {
        super();
        this.model.Id = 0;
        this.mode = PackageFormMode.View;
    }
}