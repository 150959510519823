/**
 * A core event that allow cancellation.
 */
export class FPEvent {
    private _cancelled = false;
    get cancelled() { return this._cancelled; }
    private _cancelable = true;
    get cancelable() { return this._cancelable; }
    constructor(eventInitOpts = { cancelable: true }) {
        if (eventInitOpts) {
            this._cancelable = eventInitOpts.cancelable;
        }
    }
    cancel() {
        if (!this.cancelable) {
            // TODO: should throw an instance of Error or just return.
            return;
        }
        this._cancelled = true;
    }
}
