import { skipWhile, startWith, switchMap, map, catchError, shareReplay, filter, debounceTime } from 'rxjs/operators';
import { merge, of as observableOf, forkJoin, Subject, empty, Observable } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployerService, CommonService, HttpDAO, MessageBox, FacilityService, UserService } from '@fp/services';
import { SearchPaginationRequest, Employer, ResultModel, CommonResponse, RequestPagination, Facility, AWSStartExecutionResponse, body } from '@fp/models';
import { CustomMessageBox, StringHelper, FPValidators, DateHelper } from '@fp/helpers';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Sort, MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonDataType } from '@fp/enums';
import { HttpClient } from '@angular/common/http';
import { APIConstant, CommonString } from '@fp/constant';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { NgbDateAdapter, NgbDateNativeUTCAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AwsServiceService } from "@fp/services/aws-service.service";
import { StepFunctions } from "@fp/enums/step-functions.enum";
import { DescribeStateMachineComponent } from "@fp/components/describe-state-machine/describe-state-machine.component";
import { Console } from 'console';
import { CommonConstants } from '@fp/constant/common-constants';
import { CheckReportAccess } from '@fp/models/check-report-access-model';
import { UserRole } from '@fp/models/user-role.model';


@Component({
  selector: 'app-generate-report-FP',
  templateUrl: './generate-report-FP.component.html',
  styleUrls: ['./generate-report-FP.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter }
  ]
})
export class GenerateReportFPComponent implements OnInit {
  private httpdao: HttpDAO | null;
  public ReportGroupData = [
    {
      Id: ReportGroup.MemberData, Name: 'Member Data', Reports: [
        { Id: ReportFPOptions.MemberFacilityPreference, Name: 'Member Facility Preference' },
        { Id: ReportFPOptions.MembersWhoBelongedFacilityBeforeJoining, Name: 'Members who belonged to a facility before joining' },
        { Id: ReportFPOptions.MemberMasterExport, Name: 'Member Master Export' },
        { Id: ReportFPOptions.ListofContactsforMembers, Name: 'List of Contacts for Members' },
        { Id: ReportFPOptions.MemberSuspension, Name: 'Member Suspension' },
        { Id: ReportFPOptions.NonPrimaryMembersTurning1825, Name: 'Non Primary Members Turning 18 - 25' },
        { Id: ReportFPOptions.MemberTally, Name: 'Member Tally' },
        { Id: ReportFPOptions.MemberReactivation, Name: 'Member Reactivation' },
        { Id: ReportFPOptions.MemberCancellationReason, Name: 'Member Cancellation Reason' },
        { Id: ReportFPOptions.CancelledFPMembersWhoHaveVisited, Name: 'Cancelled FP Members who have visited' },
        { Id: ReportFPOptions.MemberTransfers, Name: 'Member Transfers' },
        { Id: ReportFPOptions.UserDropouts, Name: 'Member Registration Status' },
        { Id: ReportFPOptions.MemberMasterExportWithoutSensitiveData, Name: 'Member Master Export without sensitive data' },
        { Id: ReportFPOptions.ListOfUsers, Name: 'List of Users' }
      ]
    },
    {
      Id: ReportGroup.FacilityData, Name: 'Facility Data', Reports: [
        { Id: ReportFPOptions.FacilityAttendance, Name: 'Facility Attendance' },
        { Id: ReportFPOptions.FacilityMasterExport, Name: 'Facility Master Export' },
        { Id: ReportFPOptions.FacilityChangeLog, Name: 'Facility Change Log' },
        { Id: ReportFPOptions.FacilityMasterExportWithoutSensitiveData, Name: 'Facility Master Export without sensitive data' }
      ]
    },
    {
      Id: ReportGroup.VisitationData, Name: 'Visitation Data', Reports: [
        { Id: ReportFPOptions.MemberVisits, Name: 'Member Visits' },
        { Id: ReportFPOptions.MemberVisitLiveFeed, Name: 'Member Visit Live Feed' },
      ]
    },
    {
      Id: ReportGroup.EmployerData, Name: 'Employer Data', Reports: [
        { Id: ReportFPOptions.ChurnReport, Name: 'Churn Report' },
        { Id: ReportFPOptions.EmployerFacilitatorMasterReport, Name: 'Employer Facilitator Master Report' },
        { Id: ReportFPOptions.EmployerPackageAccess, Name: 'Employer Package Access' },
        { Id: ReportFPOptions.EmployerFacilitatorMasterReportWithoutSensitiveData, Name: 'Employer Facilitator Master Report Without Sensitive Data' }
      ]
    },
    {
      Id: ReportGroup.RegionData, Name: 'Region Data', Reports: [
        { Id: ReportFPOptions.RegionStatus, Name: 'Region Status' },
      ]
    },
    {
      Id: ReportGroup.FinanceReport, Name: 'Financial Data', Reports: [
        { Id: ReportFPOptions.FacilityPaymentStatements, Name: 'Facility Payment Statements' },
        { Id: ReportFPOptions.FacilitiesPendingPayment, Name: 'Facilities Pending Payment' },
        { Id: ReportFPOptions.PaymentsWithNoInvoices, Name: 'Payments with no invoices' },
        { Id: ReportFPOptions.ListOfPayments, Name: 'List Of Payments' },
        { Id: ReportFPOptions.StaffCreatedCredits, Name: 'Staff Created Credits' },
        { Id: ReportFPOptions.DebtDishonourReport, Name: 'Debt Dishonour Report' }
      ]
    },
  ];
  public reports = [{ Id: null, Name: null }];
  public ReportFormGroup: UntypedFormGroup;
  public isSearchingEmployer = false;
  public isSearchingFacility = false;
  public isSearchingRole = false;
  public employerSearchResults: Employer[];
  public facilitySearchResults: CommonResponse[];
  public userRoleSearchResults: UserRole[];
  public selectedEmployerId = 0;
  public selectedFacilityId = 0;
  public selectedRoleId = 0;
  public btnGenerateClicked = false;
  public IsFilterEmployerSearch = false;
  public IsFilterFacilitySearch = false;
  public IsFilterUserRoleSearch = false;
  public IsFilter = false;
  public IsReportDeveloped = true;
  public IsFilterNormalDLL1 = false;
  public IsFilterNormalDLL2 = false;
  public IsFilterNormalDLL3 = false;
  public IsFilter1Required = false;
  public FilterLabel1 = '';
  public FilterLabel2 = '';
  public FilterLabel3 = '';
  public FilterItems: CommonResponse[] = [];
  public FilterItems1: CommonResponse[] = [];
  public FilterItems2: CommonResponse[] = [];
  public FilterItems3: CommonResponse[] = [];
  public IsEmpRequired = false;
  public IsFacRequired = false;
  public IsRoleRequired = false;
  public IsStartDateRequired = false;
  public IsEndDateRequired = false;
  public IsCSVOnly = false;
  public IsEmailOnly = false;
  public IsEmailOnlyWithoutSensitiveData = false;
  public IsShowingExport = false;
  public IsNoDataFirstTable = false;
  public IsNoDataFirstTableAU = false;
  public IsNoDataFirstTableNZ = false;
  public IsNoDataSecondTable = false;
  public IsNoDataSecondTableAU = false;
  public IsNoDataSecondTableNZ = false;
  public IsNoDataServerTable = false;
  public IsShowingServerDataPaging = false
  public IsShowingHeaderTable1 = false;
  public IsShowingHeaderTable1AU = false;
  public IsShowingHeaderTable1NZ = false;
  public IsShowingHeaderTable2 = false;
  public IsShowingHeaderTable2AU = false;
  public IsShowingHeaderTable2NZ = false;
  public IsFilterDate = false;
  public IsFilterDateMonth = false;
  public Header1 = '';
  public Header1AU = '';
  public Header1NZ = '';
  public Header2 = '';
  public Header2AU = '';
  public Header2NZ = '';
  public TextWhenEmpty1 = 'All';
  public TextWhenEmpty2 = 'All';
  public TextWhenEmpty3 = 'All';
  public displayedColumns: string[] = [];
  public displayedColumnsAU: string[] = [];
  public displayedColumnsNZ: string[] = [];
  public seconddisplayedColumns: string[] = [];
  public seconddisplayedColumnsAU: string[] = [];
  public seconddisplayedColumnsNZ: string[] = [];
  public serverdisplayedColumns: string[] = [];
  public dataSource = new MatTableDataSource();
  public dataSourceAU = new MatTableDataSource();
  public dataSourceNZ = new MatTableDataSource();
  public seconddataSource = new MatTableDataSource();
  public seconddataSourceAU = new MatTableDataSource();
  public seconddataSourceNZ = new MatTableDataSource();
  public serverpagingSource = new MatTableDataSource();
  // Paging server
  public length: number;
  public pageNum: number;
  public pageSize: number;
  public PageOrder: string;
  public PageOrderASC: boolean;
  public pageSizeOptions: number[];
  public pageEvent: PageEvent;
  public pagingreq: RequestPagination = new RequestPagination();
  //End
  public ReportTypeName = '';
  private Url_CSV = '';
  private WAITING_TIME = 15000;
  public DownloadFileSubject: Subject<boolean> = new Subject();

  //Employer Churn Report
  public DefaultFilter = '';
  public DefaultFilter2 = '';

  //check report access
  public IsAccessed=false;

  @ViewChild('rppagi') rp_paginator: MatPaginator;
  @ViewChild('rpsecondpagi') rp_second_paginator: MatPaginator;
  @ViewChild('rppagiAU') rpAU_paginator: MatPaginator;
  @ViewChild('rpsecondpagiAU') rp_secondAU_paginator: MatPaginator;
  @ViewChild('rppagiNZ') rpNZ_paginator: MatPaginator;
  @ViewChild('rpsecondpagiNZ') rp_secondNZ_paginator: MatPaginator;

  @ViewChild('firstSort') firstSort: MatSort;
  @ViewChild('secondSort') secondSort: MatSort;
  @ViewChild('firstSortAU') firstSortAU: MatSort;
  @ViewChild('secondSortAU') secondSortUA: MatSort;
  @ViewChild('firstSortNZ') firstSortNZ: MatSort;
  @ViewChild('secondSortNZ') secondSortNZ: MatSort;

  @ViewChild('memberMasterExportDesc') memberMasterExportDescSpinner: DescribeStateMachineComponent;
  @ViewChild('memberMasterExportWithoutSnesitiveDataDesc') memberMasterExportDescWithoutSensitiveDataSpinner: DescribeStateMachineComponent;
  StepFunctions = StepFunctions;
  errorHandler: any;
  public ShowMemberMasterProgress = false;
  EmployerRecord: Employer;
  UserRoleRecord: UserRole;

  get noRecordFoundText(): string {
    return CommonString.TABLE_NO_RECORD_FOUND;
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private employerSvc: EmployerService,
    private facilitySvc: FacilityService,
    private userroleSvc: UserService,
    private comsvr: CommonService,
    private dialog: MatDialog,
    private http: HttpClient,
    private awsService: AwsServiceService
  ) { }

  ngOnInit() {
    this.comsvr.Header.title="Generate Reports";
    this.httpdao = new HttpDAO(this.http);
    this.pageSizeOptions = CommonString.PAGE_SIZE_OPTIONS;

    this.ReportFormGroup = this._formBuilder.group({
      ddlGroup: ["", Validators.required],
      ddlReport: ["", Validators.required],
      ddlEmployer: ["", FPValidators.resolvableAutocompleteOption],
      ddlFacility: ["", FPValidators.resolvableAutocompleteOption],
      ddlRole : ["", FPValidators.resolvableAutocompleteOption],
      ddlFilter: [""],
      ddlFilter1: [""],
      ddlFilter2: [""],
      ddlFilter3: [""],
      dtpStartDate: [null, [this.MinDateValidator, this.RangeDateValidator]],
      dtpEndDate: [null, [this.MinDateValidator, this.RangeDateValidator]],
    });
    this.InitAutoEmployerSearch();
    this.InitAutoFacilitySearch()
    this.InitAutoUserRoleSearch()
    this.DownloadFileSubject.pipe(
      debounceTime(this.WAITING_TIME)
    ).subscribe(val => {
      this.comsvr.StopProgressBar();
    });
  }

  public AutoComplete_DisplayWithFn(option: any) {
    return option ? option.Name : '';
  }

  public Role_AutoComplete_DisplayWithFn(option: any) {
    return option ? option.DisplayName : '';
  }

  public MinDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value !== undefined && control.value !== "") {
      try {
        const d: Date = new Date(control.value);
        const dc = new Date(1900, 1, 1, 0, 0, 0);
        if ((d < dc || d.toString() === 'Invalid Date' || !(control.value instanceof Date))
          && control.value !== null) {
          return { 'mindateinvalid': true };
        } else {
          return null;
        }
      } catch (e) {
        return { 'mindateinvalid': true };
      }
    } else {
      return null;
    }
  }

  public RangeDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value !== undefined && control.parent !== undefined && control.parent !== null) {
      const enddate = new Date(control.parent.get('dtpEndDate').value);
      const startdate = new Date(control.parent.get('dtpStartDate').value);
      if (startdate == null || enddate == null || !(control.value instanceof Date)) {
        return null;
      } else {
        enddate.setHours(0, 0, 0, 0);
        startdate.setHours(0, 0, 0, 0);
        if (enddate < startdate) {
          return { 'rangedateinvalid': true };
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  }

  public UpdateValidateDateRange(ctrnames: string[]) {
    for (let i = 0; i < ctrnames.length; i++) {
      this.ReportFormGroup.get(ctrnames[i]).updateValueAndValidity();
    }
  }

  private InitAutoEmployerSearch() {
    this.ReportFormGroup.get('ddlEmployer').valueChanges
      .pipe(
        skipWhile((value) => (value && typeof value === 'object')),
        debounceTime(500),
        switchMap((value) => {
          if (typeof value === 'string') {
            if (value.length > 2) {
              this.isSearchingEmployer = true;
              const searchRequest: SearchPaginationRequest = {
                OrderBy: 'name',
                OrderByAsc: true,
                PageNumber: 0,
                PageSize: 0,
                Params: [
                  {
                    Name: 'name',
                    Value: value
                  }
                ]
              };
              return this.employerSvc.search(searchRequest);
            } else {
              this.isSearchingEmployer = false;
            }
          }
          return empty();
        }),
        catchError((e) => { throw e; }))
      .subscribe(
        (edata: string) => {
          this.isSearchingEmployer = false;
          const dectypted_data = this.comsvr.D_FP_ResponseData(edata);
          const rdata = JSON.parse(dectypted_data);
          if (rdata.Success) {
            if (rdata.Data) {
              this.employerSearchResults = rdata.Data.Results;
            }
          } else {
            this.HandleResponseError(rdata);
          }
        },
        error => {
          this.isSearchingEmployer = false;
          this.handleError(error);
        });
  }

  private InitAutoFacilitySearch() {
    this.ReportFormGroup.get('ddlFacility').valueChanges
      .pipe(
        skipWhile((value) => (value && typeof value === 'object')),
        debounceTime(500),
        switchMap((value) => {
          if (typeof value === 'string') {
            if (value.length >= 3) {
              this.isSearchingFacility = true;
              const searchRequest: SearchPaginationRequest = {
                OrderBy: 'name',
                OrderByAsc: true,
                PageNumber: 1,
                PageSize: 200,
                Params: [{ Name: 'name', Value: value }]
              };

              return this.facilitySvc.search(searchRequest);
            } else {
              this.isSearchingFacility = false;
            }
          }
          return empty();
        }),
        catchError((e) => { throw e; }))
      .subscribe(
        (edata: string) => {
          this.isSearchingFacility = false;
          const dectypted_data = this.comsvr.D_FP_ResponseData(edata);
          const rdata = JSON.parse(dectypted_data);
          if (rdata.Success) {
            if (rdata.Data) {
              this.facilitySearchResults = rdata.Data.Results.map(item =>
                <CommonResponse>{ Id: item.FacilityId, Name: item.Name });
            }
          } else {
            this.HandleResponseError(rdata);
          }
        },
        error => {
          this.isSearchingFacility = false;
          this.handleError(error);
        });
  }

  private InitAutoUserRoleSearch() {
    this.ReportFormGroup.get('ddlRole').valueChanges
      .pipe(
        skipWhile((value) => (value && typeof value === 'object')),
        debounceTime(500),
        switchMap((value) => {
          if (typeof value === 'string') {
            if (value.length > 2) {
              this.isSearchingRole = true;
              const searchRequest: SearchPaginationRequest = {
                OrderBy: 'name',
                OrderByAsc: true,
                PageNumber: 0,
                PageSize: 0,
                Params: [
                  {
                    Name: 'name',
                    Value: value
                  }
                ]
              };
              return this.userroleSvc.search(searchRequest);
            } else {
              this.isSearchingRole = false;
            }
          }
          return empty();
        }),
        catchError((e) => { throw e; }))
      .subscribe(
        (edata: string) => {
          this.isSearchingRole = false;
          const dectypted_data = this.comsvr.D_FP_ResponseData(edata);
          const rdata = JSON.parse(dectypted_data);
          if (rdata.Success) {
            if (rdata.Data) {
              this.userRoleSearchResults = rdata.Data.Results;
              console.log( this.userRoleSearchResults);
            }
          } else {
            this.HandleResponseError(rdata);
          }
        },
        error => {
          this.isSearchingRole = false;
          this.handleError(error);
        });
  }

  public ddlGroup_Change(event) {
    this.ResetAllOptions();
    const rps = event.target.value;
    if (rps !== '') {
      const id = parseInt(rps, 10);
      const arr_rp = this.ReportGroupData.find(rgd => rgd.Id === id);
      if (arr_rp) {
        this.reports = arr_rp.Reports;
      }
    }
  }

  private ResetAllOptions() {
    this.dataSource = new MatTableDataSource();
    this.dataSourceAU = new MatTableDataSource();
    this.dataSourceNZ = new MatTableDataSource();
    this.seconddataSource = new MatTableDataSource();
    this.seconddataSourceAU = new MatTableDataSource();
    this.seconddataSourceNZ = new MatTableDataSource();
    this.serverpagingSource = new MatTableDataSource();
    this.pagingreq = new RequestPagination();
    this.IsFilter = false;
    this.IsFilterEmployerSearch = false;
    this.IsFilterFacilitySearch = false;
    this.IsFilterUserRoleSearch = false;
    this.IsFilterNormalDLL1 = false;
    this.IsFilterNormalDLL2 = false;
    this.IsFilterNormalDLL3 = false;
    this.IsFilterDate = false;
    this.IsReportDeveloped = true;
    this.displayedColumns = [];
    this.displayedColumnsAU = [];
    this.displayedColumnsNZ = [];
    this.seconddisplayedColumns = [];
    this.seconddisplayedColumnsAU = [];
    this.seconddisplayedColumnsNZ = [];
    this.serverdisplayedColumns = [];
    this.FilterItems1 = [];
    this.FilterItems2 = [];
    this.FilterItems3 = [];
    this.FilterLabel1 = '';
    this.FilterLabel2 = '';
    this.FilterLabel3 = '';
    this.TextWhenEmpty1 = 'All';
    this.TextWhenEmpty2 = 'All';
    this.TextWhenEmpty3 = 'All';
    this.selectedEmployerId = 0;
    this.selectedFacilityId = 0;
    this.selectedRoleId =0;
    this.ReportFormGroup.get('ddlFilter').setValue('');
    this.ReportFormGroup.get('ddlFilter1').setValue('');
    this.ReportFormGroup.get('ddlFilter2').setValue('');
    this.ReportFormGroup.get('ddlFilter3').setValue('');
    this.ReportFormGroup.get('ddlFilter1').clearValidators();
    this.ReportFormGroup.get('ddlFilter1').updateValueAndValidity({ onlySelf: false });

    this.ReportFormGroup.get('ddlEmployer').setValue('');
    this.ReportFormGroup.get('ddlEmployer').clearValidators();
    this.ReportFormGroup.get('ddlEmployer').updateValueAndValidity({ onlySelf: false });

    this.ReportFormGroup.get('ddlFacility').setValue('');
    this.ReportFormGroup.get('ddlFacility').clearValidators();
    this.ReportFormGroup.get('ddlFacility').updateValueAndValidity({ onlySelf: false });

    this.ReportFormGroup.get('ddlRole').setValue('');
    this.ReportFormGroup.get('ddlRole').clearValidators();
    this.ReportFormGroup.get('ddlRole').updateValueAndValidity({ onlySelf: false });

    this.ReportFormGroup.get('dtpStartDate').setValue(null);
    this.ReportFormGroup.get('dtpEndDate').setValue(null);
    this.ReportFormGroup.get('dtpStartDate').clearValidators();
    this.ReportFormGroup.get('dtpStartDate').updateValueAndValidity({ onlySelf: false });
    this.ReportFormGroup.get('dtpEndDate').clearValidators();
    this.ReportFormGroup.get('dtpEndDate').updateValueAndValidity({ onlySelf: false });

    this.IsFilter1Required = false;
    this.IsEmpRequired = false;
    this.IsFacRequired = false;
    this.IsRoleRequired = false;
    this.IsCSVOnly = false;
    this.IsEmailOnly = false;
    this.IsEmailOnlyWithoutSensitiveData = false;
    this.IsShowingExport = false;
    this.IsEndDateRequired = false;
    this.IsStartDateRequired = false;

    this.Url_CSV = '';
    this.IsShowingServerDataPaging = false;
    this.IsShowingHeaderTable1 = false;
    this.IsShowingHeaderTable1AU = false;
    this.IsShowingHeaderTable1NZ = false;
    this.IsShowingHeaderTable2 = false;
    this.IsShowingHeaderTable2AU = false;
    this.IsShowingHeaderTable2NZ = false;

    this.ShowMemberMasterProgress = false;
    this.IsFilterDateMonth = false;
  }

  public ddlReport_Change(event) {
    const val = parseInt(event.target.value, 10);
    this.ReportTypeName = ReportFPOptions[val];
    this.ResetAllOptions();
    switch (val) {
      case ReportFPOptions.MemberFacilityPreference:
        this.IsFilter = true;
        this.IsFilterEmployerSearch = true;
        this.IsFilterNormalDLL1 = true;
        this.IsFilterNormalDLL2 = true;
        this.FilterLabel1 = 'Member Status';
        this.FilterLabel2 = 'Member Type';
        this.LoadCommonData(val, 'ddlFilter1', CommonDataType.MemberStatus);
        this.LoadCommonData(val, 'ddlFilter2', CommonDataType.MemberType);
        this.ReportFormGroup.get('ddlEmployer').setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
        this.ReportFormGroup.get('ddlEmployer').updateValueAndValidity({ onlySelf: false });
        this.IsEmpRequired = true;
        this.IsShowingExport = true;
        this.IsShowingServerDataPaging = true;
        break;
      case ReportFPOptions.MembersWhoBelongedFacilityBeforeJoining:
        this.IsFilter = true;
        this.IsFilterEmployerSearch = true;
        this.IsFilterNormalDLL1 = true;
        this.IsFilterDate = true;
        this.FilterLabel1 = 'Member Status';
        this.IsEmpRequired = false;
        this.IsFilterFacilitySearch = true;
        this.IsFacRequired = false;
        this.LoadCommonData(val, 'ddlFilter1', CommonDataType.MemberStatus);
        this.ReportFormGroup.get('dtpStartDate').setValidators([this.MinDateValidator, this.RangeDateValidator]);
        this.ReportFormGroup.get('dtpStartDate').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('dtpEndDate').setValidators([this.MinDateValidator, this.RangeDateValidator]);
        this.ReportFormGroup.get('dtpEndDate').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('ddlEmployer').setValidators([FPValidators.resolvableAutocompleteOption]);
        this.ReportFormGroup.get('ddlEmployer').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('ddlFacility').setValidators([FPValidators.resolvableAutocompleteOption]);
        this.ReportFormGroup.get('ddlFacility').updateValueAndValidity({ onlySelf: false });
        this.IsCSVOnly = true;
        break;
      case ReportFPOptions.MemberVisitLiveFeed:
        this.IsFilter = true;
        this.IsFilterFacilitySearch = true;
        this.IsFacRequired = true;
        this.IsShowingExport = false;
        this.IsShowingServerDataPaging = true;
        this.pagingreq.OrderBy = 'visittime';
        this.pagingreq.OrderByAsc = true;
        this.ReportFormGroup.get('ddlFacility').setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
        this.ReportFormGroup.get('ddlFacility').updateValueAndValidity({ onlySelf: false });
        break;
      case ReportFPOptions.MemberMasterExport:
        this.IsFilter = true;
        this.IsFilterEmployerSearch = true;
        this.IsFilterNormalDLL1 = true;
        this.FilterLabel1 = 'Member Status';
        this.IsEmailOnly = true;
        this.IsCSVOnly = true;
        this.LoadCommonData(val, 'ddlFilter1', CommonDataType.MemberStatus);
        break;
      case ReportFPOptions.FacilityAttendance:
        this.IsFilter = true;
        this.IsFilterEmployerSearch = true;
        this.IsFilterFacilitySearch = true;
        this.IsFilterDate = true;
        this.IsCSVOnly = true;
        this.IsEndDateRequired = true;
        this.IsStartDateRequired = true;
        this.ReportFormGroup.get('dtpStartDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpStartDate').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('dtpEndDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpEndDate').updateValueAndValidity({ onlySelf: false });
        break;
      case ReportFPOptions.ListofContactsforMembers:
        this.IsFilter = true;
        this.IsFilterEmployerSearch = true;
        this.IsCSVOnly = true;
        this.IsEmpRequired = true;
        this.IsFilterNormalDLL1 = true;
        this.FilterLabel1 = 'Member Status';
        this.ReportFormGroup.get('ddlEmployer').setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
        this.ReportFormGroup.get('ddlEmployer').updateValueAndValidity({ onlySelf: false });
        this.LoadCommonData(val, 'ddlFilter1', CommonDataType.MemberStatus);
        break;
      case ReportFPOptions.MemberSuspension:
        this.IsFilter = true;
        this.IsCSVOnly = true;
        this.IsFilterDate = true;
        this.IsEndDateRequired = true;
        this.IsStartDateRequired = true;
        this.ReportFormGroup.get('dtpStartDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpStartDate').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('dtpEndDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpEndDate').updateValueAndValidity({ onlySelf: false });
        break;
      case ReportFPOptions.NonPrimaryMembersTurning1825:
        this.IsFilter = true;
        this.IsCSVOnly = true;
        this.IsFilterEmployerSearch = true;
        this.IsFilterDate = true;
        this.IsEndDateRequired = true;
        this.IsStartDateRequired = true;
        this.IsFilterNormalDLL1 = true;
        this.FilterLabel1 = 'Member Status';
        this.ReportFormGroup.get('dtpStartDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpStartDate').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('dtpEndDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpEndDate').updateValueAndValidity({ onlySelf: false });
        this.LoadCommonData(val, 'ddlFilter1', CommonDataType.MemberStatus);
        break;
      case ReportFPOptions.MemberTally:
        this.IsFilter = true;
        this.IsFilterEmployerSearch = true;
        this.IsFilterNormalDLL1 = true;
        this.FilterLabel1 = 'Parent group';
        this.ReportFormGroup.get('ddlEmployer').setValidators([FPValidators.resolvableAutocompleteOption]);
        this.ReportFormGroup.get('ddlEmployer').updateValueAndValidity({ onlySelf: false });
        this.LoadCommonData(val, 'ddlFilter1', CommonDataType.EmployerParentGroup);
        break;
        case ReportFPOptions.MemberReactivation:
        this.IsFilter = true;
        this.IsCSVOnly = true;
        this.IsFilterDate = true;
        this.IsEndDateRequired = true;
        this.IsStartDateRequired = true;
        this.ReportFormGroup.get('dtpStartDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpStartDate').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('dtpEndDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpEndDate').updateValueAndValidity({ onlySelf: false });
        break;
      case ReportFPOptions.ChurnReport:
        this.IsFilter = true;
        this.IsFilterEmployerSearch = true;
        // this.IsFilterDate = true;
        // this.DefaultFilter = 'January';
        this.IsFilterDateMonth = true;
        this.IsCSVOnly = true;
        // this.IsEndDateRequired = true;
        // this.IsStartDateRequired = true;
        //Haris
        this.DefaultFilter2 = new Date().getFullYear().toString();

        let tempfilter: CommonResponse[] = [];
        let tempfilter2: CommonResponse[] = [];
        this.FilterItems = [];
        this.FilterItems2 = [];

        this.LoadYears(tempfilter2);
        this.LoadMonths(tempfilter);

        for (let j = 0; j < tempfilter.length; j++) {
          this.FilterItems.push(tempfilter[j]);
        }

        for (let j = 0; j < tempfilter2.length; j++) {
          this.FilterItems2.push(tempfilter2[j]);
        }
        //
        this.ReportFormGroup.get('ddlEmployer').setValidators([FPValidators.resolvableAutocompleteOption]);
        this.ReportFormGroup.get('ddlEmployer').updateValueAndValidity({ onlySelf: false });
        // this.ReportFormGroup.get('dtpStartDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        // this.ReportFormGroup.get('dtpStartDate').updateValueAndValidity({ onlySelf: false });
        // this.ReportFormGroup.get('dtpEndDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        // this.ReportFormGroup.get('dtpEndDate').updateValueAndValidity({ onlySelf: false });
        // this.ReportFormGroup.get('ddlFilter').updateValueAndValidity({ onlySelf: false });
        // this.ReportFormGroup.get('ddlFilter2').updateValueAndValidity({ onlySelf: false });
        break;
      case ReportFPOptions.MemberCancellationReason:
        this.IsFilter = true;
        this.IsFilterEmployerSearch = true;
        this.IsFilterDate = true;
        this.IsEndDateRequired = true;
        this.IsStartDateRequired = true;
        this.IsCSVOnly = true;
        this.ReportFormGroup.get('ddlEmployer').setValidators([FPValidators.resolvableAutocompleteOption]);
        this.ReportFormGroup.get('ddlEmployer').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('dtpStartDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpStartDate').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('dtpEndDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpEndDate').updateValueAndValidity({ onlySelf: false });
        break;
      case ReportFPOptions.EmployerFacilitatorMasterReport:
        this.IsFilter = false;
        this.IsCSVOnly = true;
        break;
      case ReportFPOptions.FacilityMasterExport:
        this.IsFilter = true;
        this.IsCSVOnly = true;
        this.IsFilterNormalDLL1 = true;
        this.IsFilterNormalDLL2 = true;
        this.FilterLabel1 = 'Facility Status';
        this.FilterLabel2 = 'Region';
        this.LoadCommonData(val, 'ddlFilter1', CommonDataType.FacilityStatus);
        this.LoadCommonData(val, 'ddlFilter2', CommonDataType.RegionList);
        break;
      case ReportFPOptions.PaymentsWithNoInvoices:
        this.IsFilter = true;
        this.IsCSVOnly = true;
        this.IsFilterDate = true;
        this.IsEndDateRequired = true;
        this.IsStartDateRequired = true;
        this.ReportFormGroup.get('dtpStartDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpStartDate').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('dtpEndDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpEndDate').updateValueAndValidity({ onlySelf: false });
        break;
      case ReportFPOptions.FacilitiesPendingPayment:
        this.IsFilter = false;
        this.IsCSVOnly = true;
        break;
      case ReportFPOptions.FacilityPaymentStatements:
      case ReportFPOptions.ListOfPayments:
        this.IsFilter = true;
        this.IsCSVOnly = true;
        this.IsFilterFacilitySearch = true;
        this.IsFilterDate = true;
        this.IsEndDateRequired = true;
        this.IsStartDateRequired = true;
        this.ReportFormGroup.get('dtpStartDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpStartDate').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('dtpEndDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpEndDate').updateValueAndValidity({ onlySelf: false });
        break;
      case ReportFPOptions.EmployerPackageAccess:
        this.IsFilter = true;
        this.IsCSVOnly = true;
        this.IsFilterEmployerSearch = true;
        this.IsEmpRequired = true;
        this.IsFilterNormalDLL1 = true;
        this.FilterLabel1 = 'Membership Package Type';
        this.TextWhenEmpty1 = 'Select an item';
        this.IsFilter1Required = true;
        this.ReportFormGroup.get('ddlEmployer').setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
        this.ReportFormGroup.get('ddlEmployer').updateValueAndValidity({ onlySelf: false });
        this.LoadCommonData(val, 'ddlFilter1', CommonDataType.MembershipPackageType);
        this.ReportFormGroup.get('ddlFilter1').setValidators([Validators.required]);
        this.ReportFormGroup.get('ddlFilter1').updateValueAndValidity({ onlySelf: false });
        break;
      case ReportFPOptions.RegionStatus:
        this.IsFilter = true;
        this.IsCSVOnly = true;
        this.IsFilterNormalDLL1 = true;
        this.FilterLabel1 = 'Region Status';
        this.LoadCommonDataManual('ddlFilter1', [{ Id: 1, Name: 'Active' }, { Id: 0, Name: 'Inactive' }], '1');
        break;
      case ReportFPOptions.MemberVisits:
        this.IsFilter = true;
        this.IsCSVOnly = true;
        this.IsFilterEmployerSearch = true;
        this.IsFilterFacilitySearch = true;
        this.IsFilterNormalDLL1 = true;
        this.IsFilterNormalDLL2 = true;
        this.FilterLabel1 = 'Visit Type';
        this.FilterLabel2 = 'Service';
        this.IsFilterDate = true;
        this.IsEndDateRequired = true;
        this.IsStartDateRequired = true;
        this.ReportFormGroup.get('dtpStartDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpStartDate').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('dtpEndDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpEndDate').updateValueAndValidity({ onlySelf: false });

        this.ReportFormGroup.get('ddlEmployer').setValidators([FPValidators.resolvableAutocompleteOption]);
        this.ReportFormGroup.get('ddlEmployer').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('ddlFacility').setValidators([FPValidators.resolvableAutocompleteOption]);
        this.ReportFormGroup.get('ddlFacility').updateValueAndValidity({ onlySelf: false });
        this.LoadCommonData(val, 'ddlFilter1', CommonDataType.VisitType);
        break;
      case ReportFPOptions.FacilityChangeLog:
        this.IsFilter = true;
        this.IsCSVOnly = true;
        this.IsFilterFacilitySearch = true;
        this.IsFilterDate = true;
        this.IsEndDateRequired = true;
        this.IsStartDateRequired = true;
        this.ReportFormGroup.get('dtpStartDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpStartDate').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('dtpEndDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpEndDate').updateValueAndValidity({ onlySelf: false });
        break;
      case ReportFPOptions.StaffCreatedCredits:
        this.IsFilter = true;
        this.IsCSVOnly = true;
        this.IsFilterDate = true;
        this.IsEndDateRequired = true;
        this.IsStartDateRequired = true;
        this.ReportFormGroup.get('dtpStartDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpStartDate').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('dtpEndDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpEndDate').updateValueAndValidity({ onlySelf: false });
        break;
      case ReportFPOptions.DebtDishonourReport:
        this.IsFilter = true;
        this.IsCSVOnly = true;
        this.IsFilterNormalDLL1 = true;
        this.FilterLabel1 = 'Membership Status';
        this.LoadCommonData(val, 'ddlFilter1', CommonDataType.MembershipStatus);
        break;
      case ReportFPOptions.CancelledFPMembersWhoHaveVisited:
        this.IsFilter = true;
        this.IsFilterFacilitySearch = true;
        this.IsShowingExport = true;
        this.IsShowingServerDataPaging = true;
        break;
      case ReportFPOptions.MemberTransfers:
        this.IsFilter = true;
        this.IsCSVOnly = true;
        this.IsFilterEmployerSearch = true;
        this.IsFilterDate = true;
        this.IsEndDateRequired = true;
        this.IsStartDateRequired = true;
        this.ReportFormGroup.get('dtpStartDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpStartDate').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('dtpEndDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
        this.ReportFormGroup.get('dtpEndDate').updateValueAndValidity({ onlySelf: false });
        break;
      case ReportFPOptions.UserDropouts:
          this.IsFilter = true;
          this.IsCSVOnly = true;
          this.IsFilterDate = true;
          this.IsEndDateRequired = true;
          this.IsStartDateRequired = true;
          this.ReportFormGroup.get('dtpStartDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
          this.ReportFormGroup.get('dtpStartDate').updateValueAndValidity({ onlySelf: false });
          this.ReportFormGroup.get('dtpEndDate').setValidators([this.MinDateValidator, this.RangeDateValidator, Validators.required]);
          this.ReportFormGroup.get('dtpEndDate').updateValueAndValidity({ onlySelf: false });
          break;
      case ReportFPOptions.MemberMasterExportWithoutSensitiveData:
        this.IsFilter = true;
        this.IsFilterEmployerSearch = true;
        this.IsFilterNormalDLL1 = true;
        this.FilterLabel1 = 'Member Status';
        this.IsEmailOnlyWithoutSensitiveData = true;
        this.IsCSVOnly = true;
        this.LoadCommonData(val, 'ddlFilter1', CommonDataType.MemberStatus);
        break;
      case ReportFPOptions.EmployerFacilitatorMasterReportWithoutSensitiveData:
        this.IsFilter = false;
        this.IsCSVOnly = true;
        break;
      case ReportFPOptions.FacilityMasterExportWithoutSensitiveData:
        this.IsFilter = true;
        this.IsCSVOnly = true;
        this.IsFilterNormalDLL1 = true;
        this.IsFilterNormalDLL2 = true;
        this.FilterLabel1 = 'Facility Status';
        this.FilterLabel2 = 'Region';
        this.LoadCommonData(val, 'ddlFilter1', CommonDataType.FacilityStatus);
        this.LoadCommonData(val, 'ddlFilter2', CommonDataType.RegionList);
        break;
      case ReportFPOptions.ListOfUsers:
        this.IsFilter = true;
        this.IsFilterFacilitySearch = true;
        this.IsFilterEmployerSearch = true;
        this.IsFilterUserRoleSearch = true;
        this.ReportFormGroup.get('ddlFacility').setValidators([FPValidators.resolvableAutocompleteOption]);
        this.ReportFormGroup.get('ddlFacility').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('ddlEmployer').setValidators([FPValidators.resolvableAutocompleteOption]);
        this.ReportFormGroup.get('ddlEmployer').updateValueAndValidity({ onlySelf: false });
        this.ReportFormGroup.get('ddlRole').setValidators([FPValidators.resolvableAutocompleteOption]);
        this.ReportFormGroup.get('ddlRole').updateValueAndValidity({ onlySelf: false });
        this.IsCSVOnly = true;
        break;
      default:
      this.IsReportDeveloped = false;
      break;
    }
  }

  public txtEmployer_Input(e) {
    if (this.ReportFormGroup.get('ddlEmployer').dirty) {
      if (typeof this.ReportFormGroup.get('ddlEmployer').value === 'string' &&
        this.ReportFormGroup.get('ddlEmployer').value.length == 0 &&
        this.selectedEmployerId > 0) {
        this.SelectEmployer(null);
      }
    }
  }

  public SelectEmployer(employer: Employer) {
    this.selectedEmployerId = employer ? employer.EmployerId : 0;
    this.EmployerRecord = employer;
    this.employerSearchResults = [];
    this.ReportFormGroup.get('ddlEmployer').markAsDirty();
    this.ReportFormGroup.get('ddlEmployer').markAsTouched();
  }

  public txtFacility_Input(e) {
    if (this.ReportFormGroup.get('ddlFacility').dirty) {
      if (typeof this.ReportFormGroup.get('ddlFacility').value === 'string' &&
        this.ReportFormGroup.get('ddlFacility').value.length == 0 &&
        this.selectedFacilityId > 0) {
        this.SelectFacility(null);
      }
    }
  }

  public SelectFacility(fac: CommonResponse) {
    this.selectedFacilityId = fac ? fac.Id : 0;
    this.facilitySearchResults = [];
    this.ReportFormGroup.get('ddlFacility').markAsDirty();
    this.ReportFormGroup.get('ddlFacility').markAsTouched();
    const valstr = this.ReportFormGroup.get('ddlReport').value;
    const val = parseInt(valstr, 10);
    if (val === ReportFPOptions.MemberVisits) {
      this.LoadCommonData(0, 'ddlFilter2', 1001, this.selectedFacilityId.toString());
    }
  }

    //FP-372
  public txtRole_Input(e) {
    if (this.ReportFormGroup.get('ddlRole').dirty) {
      if (typeof this.ReportFormGroup.get('ddlRole').value === 'string' &&
          this.ReportFormGroup.get('ddlRole').value.length == 0 &&
          this.selectedRoleId > 0) {
          console.log(this.selectedRoleId);
          this.SelectRole(null);
      }
    }
  }
  
  public SelectRole(role: UserRole) {
      this.selectedRoleId = role ? role.RoleId : 0;
      console.log(this.selectedRoleId);
      this.UserRoleRecord = role;
      this.userRoleSearchResults = [];
      console.log(this.userRoleSearchResults);
      this.ReportFormGroup.get('ddlRole').markAsDirty();
      this.ReportFormGroup.get('ddlRole').markAsTouched();
  }

  private HandleResponseError(response: ResultModel) {
    let message = null;
    if (response && response.ErrorNumber !== 0 && response.Message) {
      message = response.Message;
    }
    this.handleError(response, message);
  }

  private handleError(error, message?: string) {
    if (this.dialog.openDialogs.length === 0 || !StringHelper.isNullOrEmpty(message)) {
      message = message || 'An unknown error has occurred';
      CustomMessageBox.Show(this.dialog, {
        content: message
      });
    }
  }

  public SetClassForControl(tmpformgroup: UntypedFormGroup, controlname: string): string {
    if (tmpformgroup.get(controlname).invalid
      && (tmpformgroup.get(controlname).dirty ||
        tmpformgroup.get(controlname).touched ||
        this.btnGenerateClicked)) {
      return 'form-control is-invalid';
    } else {
      return 'form-control';
    }
  }

  public SetClassForAutoFill(tmpformgroup: UntypedFormGroup, controlname: string): string {
    if (tmpformgroup.get(controlname).invalid
      && (tmpformgroup.get(controlname).dirty ||
        tmpformgroup.get(controlname).touched ||
        this.btnGenerateClicked)) {
      return 'input-group is-invalid';
    } else {
      return 'input-group';
    }
  }

  public IsControlInvalid(tmpformgroup: UntypedFormGroup, controlname: string): boolean {
    return (tmpformgroup.get(controlname).invalid
      && (tmpformgroup.get(controlname).dirty ||
        tmpformgroup.get(controlname).touched ||
        this.btnGenerateClicked));
  }

  private LoadCommonData(rptype: number, ddlFilterName: string, CommonDataTypeParam: number, parentid: string = '') {
    this.comsvr.StartProgressBar();
    this.ReportFormGroup.get(ddlFilterName).setValue('');
    forkJoin(
      CommonDataTypeParam === 1001 ?
        this.httpdao!.getData(APIConstant.API_GET_SERVICES_BY_FACILITY + parentid) :
        this.httpdao!.getData(APIConstant.API_GET_COMMON_DATA + CommonDataTypeParam.toString())

    ).pipe(
      map(([cmdata]) => {
        return { cmdata };
      }),
      shareReplay(1),
      catchError(() => {
        this.comsvr.StopProgressBar();
        return observableOf(null);
      })
    ).subscribe(results => {
      if (results != null) {
        switch (ddlFilterName) {
          case 'ddlFilter1':
            this.FilterItems1 = results.cmdata.Data;
            break;
          case 'ddlFilter2':
            this.FilterItems2 = results.cmdata.Data;
            break;
          case 'ddlFilter3':
            this.FilterItems3 = results.cmdata.Data;
            break;
        }
      }
      this.comsvr.StopProgressBar();
    });
  }

  private LoadCommonDataManual(ddlFilterName: string, data: CommonResponse[], defaultval: string) {
    switch (ddlFilterName) {
      case 'ddlFilter1':
        this.FilterItems1 = data;
        break;
      case 'ddlFilter2':
        this.FilterItems2 = data;
        break;
      case 'ddlFilter3':
        this.FilterItems3 = data;
        break;
    }
    this.ReportFormGroup.get(ddlFilterName).setValue(defaultval);
  }

  private GetReportRequest(report_type: number): string {
    let request;
   
    const month = this.ReportFormGroup.get('ddlFilter').value;
    const numberMonth = this.getMonthFromString(month)
    const year = parseInt(this.ReportFormGroup.get('ddlFilter2').value);
    const startDateChurn = DateHelper.format((new Date(year, numberMonth-1, 1)), 'YYYY-MM-DD');
    const endDateChurn = DateHelper.format((new Date(year, numberMonth, 0)), 'YYYY-MM-DD'); //0 gives the last day of the selected month
    //
    const param_ddl1: string = this.ReportFormGroup.get('ddlFilter1').value;
    const param_ddl2: string = this.ReportFormGroup.get('ddlFilter2').value;
    let mstatus, mtype;
    let enddate = DateHelper.format(new Date(this.ReportFormGroup.get('dtpEndDate').value), 'YYYY-MM-DD') + ' 23:59:59';
    const startdate = DateHelper.format(new Date(this.ReportFormGroup.get('dtpStartDate').value), 'YYYY-MM-DD');
    const slrole = this.comsvr.GetSelectedRole();
    var isCountyAU=false;
    const userCountry=this.comsvr.getUserCountry();
    if(userCountry==CommonConstants.GET_COUNTRIES.find(a => a.CountryId == 1).Name){
      isCountyAU=true;
    }
    switch (report_type) {
      case ReportFPOptions.MemberFacilityPreference:
        mstatus = param_ddl1;
        mtype = param_ddl2;
        request = { ReportType: report_type, Param1: this.selectedEmployerId, Param2: mstatus, Param3: mtype, Header: slrole };
        break;
      case ReportFPOptions.MemberFacilityPreference_ShowGrid:
        mstatus = param_ddl1;
        mtype = param_ddl2;
        request = {
          ReportType: report_type,
          Param1: this.pagingreq.PageSize,
          Param2: this.pagingreq.PageNumber,
          Param3: this.pagingreq.OrderBy,
          Param4: this.pagingreq.OrderByAsc === true ? 'A' : 'D',
          Param5: this.selectedEmployerId.toString(),
          Param6: mstatus === '' ? '-1' : mstatus,
          Param7: mtype === '' ? '-1' : mtype,
          Header: slrole
        };
        break;
      case ReportFPOptions.MembersWhoBelongedFacilityBeforeJoining:
        mstatus = param_ddl1;
        request = {
          ReportType: report_type,
          Param1: this.selectedEmployerId == 0 ? '' : this.selectedEmployerId.toString(),
          Param2: mstatus,
          Param3: startdate === '1970-01-01' ? '' : startdate,
          Param4: enddate === '1970-01-01 23:59:59' ? '' : enddate,
          Param5: this.selectedFacilityId == 0 ? '' : this.selectedFacilityId.toString(),
          Header: slrole
        };
        break;
        case ReportFPOptions.MemberReactivation:
        request = {
          ReportType: report_type,    
          Param3: startdate === '1970-01-01' ? '' : startdate,
          Param4: enddate === '1970-01-01 23:59:59' ? '' : enddate,
          Header: slrole
        };
        console.log(startdate);
        console.log(enddate);
        break;
      case ReportFPOptions.MemberVisitLiveFeed:
        request = {
          ReportType: report_type,
          Param1: this.pagingreq.PageSize,
          Param2: this.pagingreq.PageNumber,
          Param3: this.pagingreq.OrderBy,
          Param4: this.pagingreq.OrderByAsc === true ? 'A' : 'D',
          Param5: this.selectedFacilityId.toString(),
          Header: slrole
        };
        break;
      case ReportFPOptions.MemberMasterExport:
        mstatus = param_ddl1;
        const empval = this.ReportFormGroup.get('ddlEmployer').value;
        if (typeof empval === 'string' && empval.trim() === '') {
          this.selectedEmployerId = 0;
        }
        const username = this.comsvr.GetUser();
        request = {
          ReportType: report_type,
          Param1: this.selectedEmployerId == 0 ? '' : this.selectedEmployerId.toString(),
          Param2: mstatus,
          Header: slrole,
          UserName: username
        };
        break;
      case ReportFPOptions.FacilityAttendance:
        request = {
          ReportType: report_type,
          Param1: this.selectedFacilityId == 0 ? '' : this.selectedFacilityId.toString(),
          Param2: this.selectedEmployerId == 0 ? '' : this.selectedEmployerId.toString(),
          Param3: startdate,
          Param4: enddate,
          Header: slrole
        };
        break;
      case ReportFPOptions.ListofContactsforMembers:
        mstatus = param_ddl1;
        request = {
          ReportType: report_type,
          Param1: this.selectedEmployerId == 0 ? '' : this.selectedEmployerId.toString(),
          Param2: mstatus,
          Header: slrole
        };
        break;
      case ReportFPOptions.MemberSuspension:
        request = {
          ReportType: report_type,
          Param1: enddate,
          Param2: startdate,
          // Timezone Offset in minutes -> JS does this backwards and gives +10:00UTC as -600.
          // But the SQL Server expects the format timezone as +10:00UTC to be 600.
          Param3: -(new Date().getTimezoneOffset()),
          Header: slrole
        };
        break;
      case ReportFPOptions.NonPrimaryMembersTurning1825:
        mstatus = param_ddl1;
        request = {
          ReportType: report_type,
          Param1: enddate,
          Param2: startdate,
          Param3: this.selectedEmployerId == 0 ? '' : this.selectedEmployerId.toString(),
          Param4: mstatus,
          Header: slrole
        };
        break;
      case ReportFPOptions.MemberTally:
        const parentgroup = param_ddl1;
        request = {
          ReportType: report_type,
          Param1: this.selectedEmployerId == 0 ? '-1' : this.selectedEmployerId.toString(),
          Param2: parentgroup,
          Header: slrole
        };
        break;
      case ReportFPOptions.ChurnReport:
        request = {
          ReportType: report_type,
          Param1: this.selectedEmployerId == 0 ? '' : this.selectedEmployerId.toString(),
          Param2: startDateChurn,
          Param3: endDateChurn,
          Header: slrole
        };
        break;
      case ReportFPOptions.MemberCancellationReason:
        request = {
          ReportType: report_type,
          Param1: this.selectedEmployerId == 0 ? '' : this.selectedEmployerId.toString(),
          Param2: startdate,
          Param3: enddate,
          Header: slrole
        };
        break;
      case ReportFPOptions.EmployerFacilitatorMasterReport:
        request = {
          ReportType: report_type,
          Header: slrole
        };
        break;

      case ReportFPOptions.FacilityMasterExport:
        const fstatus = param_ddl1;
        const rid = param_ddl2;
        request = {
          ReportType: report_type,
          Param1: fstatus,
          Param2: rid,
          Header: slrole
        };
        break;
      case ReportFPOptions.FacilitiesPendingPayment:
        request = {
          ReportType: report_type,
          Header: slrole
        };
        break;
      case ReportFPOptions.PaymentsWithNoInvoices:
        request = {
          ReportType: report_type,
          Param1: startdate,
          Param2: enddate,
          Header: slrole
        };
        break;
      case ReportFPOptions.FacilityPaymentStatements:
      case ReportFPOptions.ListOfPayments:
        request = {
          ReportType: report_type,
          Param1: this.selectedFacilityId == 0 ? '' : this.selectedFacilityId.toString(),
          Param2: startdate,
          Param3: enddate,
          Header: slrole
        };
        break;
      case ReportFPOptions.EmployerPackageAccess:
        const mspt = param_ddl1;
        request = {
          ReportType: report_type,
          Param1: this.selectedEmployerId.toString(),
          Param2: mspt,
          Header: slrole
        };
        break;
      case ReportFPOptions.RegionStatus:
        const regionstatus = param_ddl1;
        request = {
          ReportType: report_type,
          Param1: regionstatus,
          Header: slrole
        };
        break;
      case ReportFPOptions.MemberVisits:
        const vtype = param_ddl1;
        const serviceid = param_ddl2;
        request = {
          ReportType: report_type,
          Param1: this.selectedFacilityId == 0 ? '' : this.selectedFacilityId.toString(),
          Param2: this.selectedEmployerId == 0 ? '' : this.selectedEmployerId.toString(),
          Param3: startdate,
          Param4: enddate,
          Param5: vtype,
          Param6: serviceid,
          Header: slrole
        };
        break;
      case ReportFPOptions.FacilityChangeLog:
        request = {
          ReportType: report_type,
          Param1: this.selectedFacilityId == 0 ? '' : this.selectedFacilityId.toString(),
          Param2: startdate,
          Param3: enddate,
          Header: slrole
        };
        break;
      case ReportFPOptions.StaffCreatedCredits:
        request = {
          ReportType: report_type,
          Param1: startdate,
          Param2: enddate,
          Header: slrole
        };
        break;
      case ReportFPOptions.DebtDishonourReport:
        const msst = param_ddl1
        request = {
          ReportType: report_type,
          Param1: msst,
          Header: slrole
        };
        break;
      case ReportFPOptions.CancelledFPMembersWhoHaveVisited:
        request = {
          ReportType: report_type,
          Param1: this.selectedFacilityId == 0 ? '' : this.selectedFacilityId.toString(),
          Header: slrole
        };
        break;
      case ReportFPOptions.CancelledFPMembersWhoHaveVisited_ShowGrid:
        request = {
          ReportType: report_type,
          Param1: this.pagingreq.PageSize,
          Param2: this.pagingreq.PageNumber,
          Param3: this.pagingreq.OrderBy,
          Param4: this.pagingreq.OrderByAsc === true ? 'A' : 'D',
          Param5: this.selectedFacilityId == 0 ? '-1' : this.selectedFacilityId,
          Header: slrole
        };
        break;
      case ReportFPOptions.MemberTransfers:
        request = {
          ReportType: report_type,
          Param1: startdate,
          Param2: enddate,
          Param3: this.selectedEmployerId == 0 ? '' : this.selectedEmployerId.toString(),
          Header: slrole
        };
        break;
      case ReportFPOptions.UserDropouts:
          request = {
            ReportType: report_type,
            Param1: startdate,
            Param2: enddate,
            Header: slrole
          };
          break;
          case ReportFPOptions.MemberMasterExportWithoutSensitiveData:
            mstatus = param_ddl1;
            const empval2 = this.ReportFormGroup.get('ddlEmployer').value;
            if (typeof empval2 === 'string' && empval2.trim() === '') {
              this.selectedEmployerId = 0;
            }
          const username2 = this.comsvr.GetUser();
          request = {
            ReportType: report_type,
            Param1: this.selectedEmployerId == 0 ? '' : this.selectedEmployerId.toString(),
            Param2: mstatus,
            Header: slrole,
            UserName: username2
          };
            break;
  
          case ReportFPOptions.FacilityMasterExportWithoutSensitiveData:
            const fstatus2 = param_ddl1;
            const rid2 = param_ddl2;
            request = {
              ReportType: report_type,
              Param1: fstatus2,
              Param2: rid2,
              Header: slrole
            };
              break;

          case ReportFPOptions.ListOfUsers:
            request = {
              ReportType: report_type,
              Param1: this.selectedFacilityId == 0 ? '' : this.selectedFacilityId.toString(),
              Param2: this.selectedEmployerId == 0 ? '' : this.selectedEmployerId.toString(),
              Param3: this.selectedRoleId == 0 ? '' : this.selectedRoleId.toString(),
              Header: slrole
            };
                break;
  
          case ReportFPOptions.EmployerFacilitatorMasterReportWithoutSensitiveData:
              request = {
                ReportType: report_type,
                Header: slrole
              };
               break;
    }
    const e_dta = this.comsvr.E_FP_RequestData(JSON.stringify(request));
    return e_dta;
  }

  private SaveFileDialog(url: string, filename: string) {
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.comsvr.StopProgressBar();
  }

  private GetMemberMasterReportFP(report_type: number) {
    const e_dta = this.GetReportRequest(report_type);
    var memberMasterExportInput =  { body: {Data: e_dta}}
    //Statemachine Step Function trigger
    this.Invoke(
      this.awsService.StartStepExecution(
        StepFunctions.MemberMasterExport,
        null,
        null,
        null,
        memberMasterExportInput
      ),
      (result: AWSStartExecutionResponse) => {
        if (
          result.executionArn === undefined ||
          result.executionArn === "" ||
          result.executionArn === null
        ) {
          MessageBox.ShowError(this.dialog, "Something has gone wrong.");
        } else {
          this.memberMasterExportDescSpinner.stepMachineIsRunning = true;
          this.memberMasterExportDescSpinner.executionArn = result.executionArn;
          this.memberMasterExportDescSpinner.StartDescriptionTimer(10);
        }
      }
    );

    return null;
  }


  private GetReportFP(report_type: number) {
    const e_dta = this.GetReportRequest(report_type);
    const encryptedtoken = this.comsvr.E_FP_RequestData(this.comsvr.GetToken());
    this.comsvr.StartProgressBar();
    merge().pipe(
      startWith({}),
      switchMap(() => {
        return this.httpdao!.postData(APIConstant.API_GENERATE_REPORT, {
          Data: e_dta,
          Header: encryptedtoken,
        });
      }),
      map(edata => {
        const dectypted_data = this.comsvr.D_FP_ResponseData(edata);
        const rdata = JSON.parse(dectypted_data);
        if (rdata.Success) {
          if (this.IsCSVOnly) {
            if (rdata.Message === 'no data found') {
              MessageBox.ShowInfo(this.dialog, this.noRecordFoundText);
              this.comsvr.StopProgressBar();
            } else {
              if(report_type == ReportFPOptions.ChurnReport) {
                let report: any = JSON.parse(rdata.Data);
                if(report.fileName == undefined) {
                  MessageBox.ShowInfo(this.dialog, this.noRecordFoundText);
                  this.comsvr.StopProgressBar();
                  return;
                }
                if(this.selectedEmployerId == 0) {
                  this.SaveFileDialog(report.fileSignedURL, report.fileName);
                } else {
                  this.httpdao!.getRemoteFileData(report.fileSignedURL).subscribe(results => {
                    const content: string = results;
                    const arr_cont: string[] = content.split('\n');
                    var ReportHeader = arr_cont[0];
                    var ReportBody = "";
                    for(var i = 0; i < arr_cont.length; i++) {
                      var empCode = arr_cont[i].split(",")[1];
                      if(empCode != undefined) {
                        empCode = empCode.replace(/"/g,"");
                        if(empCode == this.EmployerRecord.Code) {
                          ReportBody = arr_cont[i];
                          break;
                        }
                      }
                    }
                    if(ReportBody == "") {
                      MessageBox.ShowInfo(this.dialog, this.noRecordFoundText);
                      this.comsvr.StopProgressBar();
                      return;
                    }
                    var reportCSV = ReportHeader + "\n" + ReportBody;
                    var reportName = this.EmployerRecord.Code + "_" + report.fileName;
                    this.download(reportCSV, reportName);
                  });
                }
                this.comsvr.StopProgressBar();
              } else {
                this.Url_CSV = rdata.Data;
                this.SaveFileDialog(this.Url_CSV, this.ReportTypeName + new Date().toDateString() + CommonString.CSV_EXTENSION);
              }
            }
          } else {
            switch (report_type) {
              case ReportFPOptions.MemberFacilityPreference:
                if (rdata.Message === 'no data found') {
                  MessageBox.ShowInfo(this.dialog, this.noRecordFoundText);
                  this.comsvr.StopProgressBar();
                } else {
                  this.Url_CSV = rdata.Data;
                  this.GetReportFP(ReportFPOptions.MemberFacilityPreference_ShowGrid);
                }
                break;
              case ReportFPOptions.CancelledFPMembersWhoHaveVisited:
                if (rdata.Message === 'no data found') {
                  MessageBox.ShowInfo(this.dialog, this.noRecordFoundText);
                  this.comsvr.StopProgressBar();
                } else {
                  this.Url_CSV = rdata.Data;
                  this.GetReportFP(ReportFPOptions.CancelledFPMembersWhoHaveVisited_ShowGrid);
                }
                break;
              case ReportFPOptions.MemberFacilityPreference_ShowGrid:
              case ReportFPOptions.MemberVisitLiveFeed:
              case ReportFPOptions.CancelledFPMembersWhoHaveVisited_ShowGrid:
                const ret = JSON.parse(rdata.Data);
                if (ret.length > 0) {
                  this.pagingreq = new RequestPagination();
                  this.length = ret[0].TotalRecords;
                }
                this.DisplayDataBasedOnReportType(report_type, ret);
                this.comsvr.StopProgressBar();
                break;
              case ReportFPOptions.MemberTally:
                const ret1 = JSON.parse(rdata.Data);
                this.DisplayDataBasedOnReportType(report_type, ret1);
                this.comsvr.StopProgressBar();
                break;
            }
          }
        } else {
          MessageBox.ShowError(this.dialog, rdata.Message || 'An error has been occured while getting report');
          this.comsvr.StopProgressBar();
        }
        return rdata;
      }),
      catchError(() => {
        this.comsvr.StopProgressBar();
        return observableOf([]);
      })
    ).subscribe();
  }

  private DisplayDataBasedOnReportType(report_type: number, ret: any) {
    switch (report_type) {
      case ReportFPOptions.MemberFacilityPreference_ShowGrid:
      case ReportFPOptions.MemberVisitLiveFeed:
      case ReportFPOptions.CancelledFPMembersWhoHaveVisited_ShowGrid:
        this.SetDataToServerPagingGrid(ret, ['TotalRecords']);
        break;
      case ReportFPOptions.MemberTally:
        this.IsShowingHeaderTable1 = true;
        this.IsShowingHeaderTable1AU = true;
        this.IsShowingHeaderTable1NZ = true;
        this.IsShowingHeaderTable2 = true;
        this.IsShowingHeaderTable2AU = true;
        this.IsShowingHeaderTable2NZ = true;
        this.Header1 = 'Number of Memberships based on Membership Type';       
        this.Header2 = 'Number of Members based on Member Type';
        const first_ret = [];
        const firstAU_ret = [];
        const firstNZ_ret = [];

        for (let i = 0; i < 5; i++) {
          first_ret.push(ret[i]);
        }  
        for (let i = 5; i < 10; i++) {
          firstAU_ret.push(ret[i]);
          this.Header1AU = 'Australia';
        }  
        for (let i = 10; i < 15; i++) {
          firstNZ_ret.push(ret[i]);          
          this.Header1NZ = 'New Zealand';
        }  
        
        const second_ret = [];
        const secondAU_ret = [];
        const secondNZ_ret = [];
        for (let i = 15; i <20; i++) {
          second_ret.push(ret[i]);
        }
        for (let i = 20; i <25 ; i++) {
          secondAU_ret.push(ret[i]);          
        this.Header2AU = 'Australia';
        }
        for (let i = 25; i <ret.length ; i++) {
          secondNZ_ret.push(ret[i]);          
        this.Header2NZ = 'New Zealand';
        }
          
       
       
        this.SetDataToFirstGrid(first_ret);
        this.SetDataToFirstAUGrid(firstAU_ret);
        this.SetDataToFirstNZGrid(firstNZ_ret);
        this.SetDataToSecondGrid(second_ret);
        this.SetDataToSecondAUGrid(secondAU_ret);
        this.SetDataToSecondNZGrid(secondNZ_ret);
        break;
    }
  }

  private download(data, csvFileName) {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", csvFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private SetDataToFirstGrid(ret: any, exceptColumns: string[] = []) {
    this.DefineDisplayColumnsForFirstTable(ret, exceptColumns);
    this.dataSource = new MatTableDataSource(ret);
    this.dataSource.sort = this.firstSort;
    this.dataSource.paginator = this.rp_paginator;
  }
  private SetDataToFirstAUGrid(ret: any, exceptColumns: string[] = []) {
    this.DefineDisplayColumnsForFirstTableAU(ret, exceptColumns);
    this.dataSourceAU = new MatTableDataSource(ret);
    this.dataSourceAU.sort = this.firstSortAU;
    this.dataSourceAU.paginator = this.rpAU_paginator;
  }
  private SetDataToFirstNZGrid(ret: any, exceptColumns: string[] = []) {
    this.DefineDisplayColumnsForFirstTableNZ(ret, exceptColumns);
    this.dataSourceNZ = new MatTableDataSource(ret);
    this.dataSourceNZ.sort = this.firstSortNZ;
    this.dataSourceNZ.paginator = this.rpNZ_paginator;
  }

  private SetDataToSecondGrid(second_ret: any) {
    this.DefineDisplayColumnsForSecondTable(second_ret);
    this.seconddataSource = new MatTableDataSource(second_ret);
    this.seconddataSource.sort = this.secondSort;
    this.seconddataSource.paginator = this.rp_second_paginator;
  }
  private SetDataToSecondAUGrid(second_ret: any) {
    this.DefineDisplayColumnsForSecondAUTable(second_ret);
    this.seconddataSourceAU = new MatTableDataSource(second_ret);
    this.seconddataSourceAU.sort = this.secondSort;
    this.seconddataSourceAU.paginator = this.rp_second_paginator;
  }
  private SetDataToSecondNZGrid(second_ret: any) {
    this.DefineDisplayColumnsForSecondNZTable(second_ret);
    this.seconddataSourceNZ = new MatTableDataSource(second_ret);
    this.seconddataSourceNZ.sort = this.secondSort;
    this.seconddataSourceNZ.paginator = this.rp_second_paginator;
  }


  private SetDataToServerPagingGrid(ret: any, exceptColumns: string[] = []) {
    this.DefineDisplayColumnsForServerTable(ret, exceptColumns);
    this.serverpagingSource = new MatTableDataSource(ret);
  }


  private DefineDisplayColumnsForServerTable(jsondata: any, exceptColumns: string[] = []) {
    this.serverdisplayedColumns = [];
    if (jsondata.length > 0) {
      this.IsNoDataServerTable = false;
      var columnsIn = jsondata[0];
      for (var key in columnsIn) {
        if (exceptColumns.indexOf(key) === -1) {
          this.serverdisplayedColumns.push(key);
        }
      }
    } else {
      this.serverdisplayedColumns = [];
      this.IsNoDataServerTable = true;
    }
  }

  private DefineDisplayColumnsForFirstTable(jsondata: any, exceptColumns: string[] = []) {
    this.displayedColumns = [];
    if (jsondata.length > 0) {
      this.IsNoDataFirstTable = false;
      var columnsIn = jsondata[0];
      for (var key in columnsIn) {
        if (exceptColumns.indexOf(key) === -1) {
          if(key!='OrderNo'){
          this.displayedColumns.push(key);
          }
        }
      }
    } else {
      this.displayedColumns = [];
      this.IsNoDataFirstTable = true;
    }
  }

  private DefineDisplayColumnsForFirstTableAU(jsondata: any, exceptColumns: string[] = []) {
    this.displayedColumnsAU = [];
    if (jsondata.length > 0) {
      this.IsNoDataFirstTableAU = false;
      var columnsIn = jsondata[0];
      for (var key in columnsIn) {
        if (exceptColumns.indexOf(key) === -1 ) {
          if(key!='OrderNo'){
          this.displayedColumnsAU.push(key);
          }
        }
      }
    } else {
      this.displayedColumnsAU = [];
      this.IsNoDataFirstTableAU = true;
    }
  }

  private DefineDisplayColumnsForFirstTableNZ(jsondata: any, exceptColumns: string[] = []) {
    this.displayedColumnsNZ = [];
    if (jsondata.length > 0) {
      this.IsNoDataFirstTableNZ = false;
      var columnsIn = jsondata[0];
      for (var key in columnsIn) {
        if (exceptColumns.indexOf(key) === -1) {
          if(key!='OrderNo'){
          this.displayedColumnsNZ.push(key);
          }
        }
      }
    } else {
      this.displayedColumnsNZ = [];
      this.IsNoDataFirstTableNZ = true;
    }
  }

  private DefineDisplayColumnsForSecondTable(jsondata: any) {
    this.seconddisplayedColumns = [];
    if (jsondata.length > 0) {
      this.IsNoDataSecondTable = false;
      var columnsIn = jsondata[0];
      for (var key in columnsIn) {
        if(key!='OrderNo'){
          if(key!='Country Code'){
        this.seconddisplayedColumns.push(key);
          }
        }
      }
    } else {
      this.seconddisplayedColumns = [];
      this.IsNoDataSecondTable = true;
    }
  }

  private DefineDisplayColumnsForSecondNZTable(jsondata: any) {
    this.seconddisplayedColumnsNZ = [];
    if (jsondata.length > 0) {
      this.IsNoDataSecondTableNZ = false;
      var columnsIn = jsondata[0];
      for (var key in columnsIn) {
        if(key!='OrderNo'){
          if(key!='Country Code'){
        this.seconddisplayedColumnsNZ.push(key);
        }
      }
      }
    } else {
      this.seconddisplayedColumnsNZ = [];
      this.IsNoDataSecondTableNZ = true;
    }
  }

  private DefineDisplayColumnsForSecondAUTable(jsondata: any) {
    this.seconddisplayedColumnsAU = [];
    if (jsondata.length > 0) {
      this.IsNoDataSecondTableAU = false;
      var columnsIn = jsondata[0];
      for (var key in columnsIn) {
        if(key!='OrderNo'){
          if(key!='Country Code'){
        this.seconddisplayedColumnsAU.push(key);
          }
        }
      }
    } else {
      this.seconddisplayedColumnsAU = [];
      this.IsNoDataSecondTableAU = true;
    }
  }

  public async btnGenerateReport_Click() {

    const valstr = this.ReportFormGroup.get('ddlReport').value;
    // this.Url_CSV = '';
    const val = parseInt(valstr, 10);
    this.btnGenerateClicked = true;
    const slrole=this.comsvr.GetSelectedRole();

    let res=await this.CheckReportAccess(val,slrole).toPromise();
    if(!res.Data.IsAuthorized){
      MessageBox.ShowInfo(this.dialog, 'Sorry you are not authorized to generate this report');
      this.comsvr.StopProgressBar();
    }else{
      if (this.ReportFormGroup.valid) {
        if (val === ReportFPOptions.MemberMasterExport) {
          //invoke step function for Member Master Export
          this.GetMemberMasterReportFP(val);
          this.ShowMemberMasterProgress = true;
        }else if(val===ReportFPOptions.MemberMasterExportWithoutSensitiveData){
          this.GetMemberMasterReportWithoutSenstiveDataFP(val);
          this.ShowMemberMasterProgress = true;
        }
        else if(val===ReportFPOptions.MembersWhoBelongedFacilityBeforeJoining){
          const newValue1 = this.ReportFormGroup.get('ddlEmployer').value;
          const newValue2 = this.ReportFormGroup.get('ddlFacility').value;
          if(newValue1 === '' && newValue2 === ''){
            MessageBox.ShowError(this.dialog, "Please select an employer or facility to proceed");
          }
          else{
            this.GetReportFP(val);
          }
        }
        else {
          this.GetReportFP(val);
        }
      }
    }
  }

  public async btnExportExcel_Click() {
    const valstr = this.ReportFormGroup.get('ddlReport').value;
    const val = parseInt(valstr, 10);

    const slrole=this.comsvr.GetSelectedRole();
    let res=await this.CheckReportAccess(val,slrole).toPromise();

    if(!res.Data.IsAuthorized){
      MessageBox.ShowInfo(this.dialog, 'Sorry you are not authorized to generate this report');
      this.comsvr.StopProgressBar();
    }else{
      switch (val) {
        case ReportFPOptions.MemberFacilityPreference:
          this.ExportExcel(this.dataSource.data);
          break;
      }
    }

  }

  public btnExportExcelServer_Click() {
    // this.comsvr.StartProgressBar();
    // this.DownloadFileSubject.next(true);
    // FileSaver.saveAs(this.Url_CSV, this.ReportTypeName + new Date().toDateString() + CommonString.CSV_EXTENSION);
    this.SaveFileDialog(this.Url_CSV, this.ReportTypeName + new Date().toDateString() + CommonString.CSV_EXTENSION);
  }

  private ExportExcel(json: any) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    var wscols = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const wcol = { wch: 20 };
      wscols.push(wcol)
    }
    worksheet['!cols'] = wscols;
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: CommonString.EXCEL_TYPE });
    const reptype = FileSaver.saveAs(data, this.ReportTypeName + new Date().toDateString() + CommonString.EXCEL_EXTENSION);
  }

  public paginatorChange(event: PageEvent) {
    this.pagingreq.PageNumber = event.pageIndex + 1;
    this.pagingreq.PageSize = event.pageSize;
    this.pageSize = event.pageSize;
    this.pageNum = event.pageIndex + 1;
    if(this.PageOrder != undefined) {
      this.pagingreq.OrderBy = this.PageOrder;
    }
    if(this.PageOrderASC != undefined) {
      this.pagingreq.OrderByAsc = this.PageOrderASC;
    }
    const val = parseInt(this.ReportFormGroup.get('ddlReport').value);
    switch (val) {
      case ReportFPOptions.MemberFacilityPreference:
        //this paging for show in grid but report is MemberFacilityPreference
        this.GetReportFP(ReportFPOptions.MemberFacilityPreference_ShowGrid);
        break;
      case ReportFPOptions.CancelledFPMembersWhoHaveVisited:
        //this sort for show in grid but report is CancelledFPMembersWhoHaveVisited
        this.GetReportFP(ReportFPOptions.CancelledFPMembersWhoHaveVisited_ShowGrid);
        break;
      case ReportFPOptions.MemberVisitLiveFeed:
        this.GetReportFP(ReportFPOptions.MemberVisitLiveFeed);
        break;
    }
  }

  public sortChange(sort: Sort) {
    const activeSort: string = sort.active.toLowerCase();
    this.pagingreq.OrderBy = activeSort;
    this.pagingreq.OrderByAsc = sort.direction === 'asc' ? false : true;
    this.PageOrder = activeSort;
    this.PageOrderASC = this.pagingreq.OrderByAsc;
    if(this.pageSize != undefined) {
      this.pagingreq.PageSize = this.pageSize;
    }
    if(this.pageNum != undefined) {
      this.pagingreq.PageNumber = this.pageNum;
    }
    const val = parseInt(this.ReportFormGroup.get('ddlReport').value);
    switch (val) {
      case ReportFPOptions.MemberFacilityPreference:
        //this sort for show in grid but report is MemberFacilityPreference
        this.GetReportFP(ReportFPOptions.MemberFacilityPreference_ShowGrid);
        break;
      case ReportFPOptions.CancelledFPMembersWhoHaveVisited:
        //this sort for show in grid but report is CancelledFPMembersWhoHaveVisited
        this.GetReportFP(ReportFPOptions.CancelledFPMembersWhoHaveVisited_ShowGrid);
        break;
      case ReportFPOptions.MemberVisitLiveFeed:
        this.GetReportFP(ReportFPOptions.MemberVisitLiveFeed);
        break;
    }
  }

  public ddlFilter_Change(event) {
    this.dataSource = new MatTableDataSource();
    this.dataSourceAU = new MatTableDataSource();
    this.dataSourceNZ = new MatTableDataSource();
    this.seconddataSource = new MatTableDataSource();
    this.seconddataSourceAU = new MatTableDataSource();
    this.seconddataSourceNZ = new MatTableDataSource();
    this.serverpagingSource = new MatTableDataSource();
    this.pagingreq = new RequestPagination();
    this.displayedColumns = [];
    this.seconddisplayedColumns = [];
    this.seconddisplayedColumnsAU = [];
    this.seconddisplayedColumnsNZ = [];
    this.serverdisplayedColumns = [];
    this.IsShowingHeaderTable1 = false;
    this.IsShowingHeaderTable1AU = false;
    this.IsShowingHeaderTable1NZ = false;
    this.IsShowingHeaderTable2 = false;
    this.IsShowingHeaderTable2AU = false;
    this.IsShowingHeaderTable2NZ = false;
  }

  public replace(content: string) {
    return content.replace(/ /g, "_");
  }

  public getMonthFromString(mon){
    return new Date(Date.parse(mon +" 1, 2012")).getMonth()+1
 }

  public month_Year_Change()
  {
    const month = this.ReportFormGroup.get('ddlFilter').value;
    const numberMonth = this.getMonthFromString(month)
    const year = parseInt(this.ReportFormGroup.get('ddlFilter2').value);
    const startDate = DateHelper.format((new Date(year, numberMonth-1, 1)), 'YYYY-MM-DD');
    const endDate = DateHelper.format((new Date(year, numberMonth, 0)), 'YYYY-MM-DD'); //0 gives the last day of the selected month
    // console.log ("Month : ", month);
    // console.log ("Year : ", year);
    // console.log ("Start date: ", startDate);
    // console.log ("End date: ", endDate);

  }

  private LoadYears(tempfilter: CommonResponse[]) {
    let currentYear = new Date().getFullYear();

    //Default value in dropdown will be the current year
    for (let i = 0; i < 10; i++) {
      let temp = new CommonResponse;
      let year: number = currentYear - i;
      temp.Id = year;
      temp.Name = year.toString();
      tempfilter.push(temp);
    }
  }

  private LoadMonths(tempfilter: CommonResponse[]) {
    let months: string[] = []
    months = ['January','February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    
    //Default value in dropdown will be January
    for (let i = 0; i < months.length; i++) {
      let temp = new CommonResponse;
      temp.Name = months[i];

      tempfilter.push(temp);
    }
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError(e => {
          throw e;
        })
      )
      .subscribe(
        res => {
          handleResultCallback(res);
        },
        err => {
          this.errorHandler(err);
        }
      );
  }

  handleMemberMasterExportReturn($event) {
    console.log('Event: ', $event);
  }

  handleMemberMasterExportWithoutSensitiveDataReturn($event){
    console.log('Event: ', $event);
  }

  private CheckReportAccess(reportId:number,userRole:string){
    let apiString=APIConstant.API_GET_REPORT_ACCESS+'?reportId='+reportId+'&&userRole='+userRole;
    return this.httpdao.getSingleData(apiString);
  }

  private GetMemberMasterReportWithoutSenstiveDataFP(report_type: number) {
    const e_dta = this.GetReportRequest(report_type);
    var memberMasterExportInput =  { body: {Data: e_dta}}
    //Statemachine Step Function trigger
    this.Invoke(
      this.awsService.StartStepExecution(
        StepFunctions.MemberMasterExportWithoutSensitiveData,
        null,
        null,
        null,
        memberMasterExportInput
      ),
      (result: AWSStartExecutionResponse) => {
        if (
          result.executionArn === undefined ||
          result.executionArn === "" ||
          result.executionArn === null
        ) {
          MessageBox.ShowError(this.dialog, "Something has gone wrong.");
        } else {
          this.memberMasterExportDescWithoutSensitiveDataSpinner.stepMachineIsRunning = true;
          this.memberMasterExportDescWithoutSensitiveDataSpinner.executionArn = result.executionArn;
          this.memberMasterExportDescWithoutSensitiveDataSpinner.StartDescriptionTimer(10);
        }
      }
    );

    return null;
  }

}

export enum ReportGroup {
  MemberData = 1,
  FacilityData = 2,
  VisitationData = 3,
  EmployerData = 4,
  RegionData = 5,
  FinanceReport = 6
}

export enum ReportFPOptions {
  MemberFacilityPreference = 1,
  MembersWhoBelongedFacilityBeforeJoining = 2,
  MemberSuspension = 3,
  ChurnReport = 4,
  NonPrimaryMembersTurning1825 = 5,
  MemberTally = 6,
  MemberEmergencyContactHealthQuestions = 7,
  WhoCanBeConvertedtoDirectMembership = 8,
  FacilityPaymentStatements = 9,
  FacilitiesPendingPayment = 10,
  PaymentsWithNoInvoices = 11,
  ListOfPayments = 12,
  GymAttendanceReport = 13,
  EmployerPackageAccess = 14,
  RegionStatus = 15,
  MemberVisitLiveFeed = 16,
  MemberMasterExport = 17,
  FacilityAttendance = 18,
  ListofContactsforMembers = 19,
  MemberCancellationReason = 20,
  EmployerFacilitatorMasterReport = 21,
  FacilityMasterExport = 22,
  MemberVisits = 23,
  FacilityChangeLog = 24,
  StaffCreatedCredits = 25,
  DebtDishonourReport = 26,
  CancelledFPMembersWhoHaveVisited = 27,
  MemberTransfers = 28,
  UserDropouts = 105,
  MemberFacilityPreference_ShowGrid = 101,
  CancelledFPMembersWhoHaveVisited_ShowGrid = 102,
  MemberMasterExportWithoutSensitiveData=106,
  FacilityMasterExportWithoutSensitiveData=107,
  EmployerFacilitatorMasterReportWithoutSensitiveData=108,
  ListOfUsers = 109,
  MemberReactivation = 110,
}