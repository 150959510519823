import { SelectPackagePageComponentComponent } from './../select-package-page-component/select-package-page-component.component';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Injector, OnInit, ViewChild } from "@angular/core";
import { CommonService, EmployerService, MemberService, MessageBox } from "@fp/services";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { StepperSelectionEvent, STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { MatStepper } from "@angular/material/stepper";
import { DataResult, ResultModel, Member, MemberSearchResultRecord, User, TermsAndConditions, NewTermsAndConditions, Employer } from "@fp/models";
import { MatTableDataSource } from "@angular/material/table";
import { Utils } from "@fp/helpers";
import { RouterConstant, StorageKey } from "@fp/constant";
import { DialogResult } from "../common-dialog-new/common-dialog-new.component";
import { Router } from "@angular/router";
import { MemberFormBase } from "../base/member-form-base";
import { CommonConstants } from "@fp/constant/common-constants";
import { EmployerDetailsNewComponent } from "../employer-components/employer-details/employer-details.component";
import { PersonalDetailsNewComponent } from "../personal-details-new/personal-details-new.component";
import { MemberContainer } from "@fp/models/member-container.model";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as enums from "src/app/enums";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonDataService } from "src/app/services/common-data.service";
import { APIConstant } from '../../constant/apiconstant';
import { HttpDAO } from "@fp/services";
import { HttpClient } from '@angular/common/http';
import { PaymentDetailsComponent } from '../payment-details/payment-details.component';
import { Observable } from 'rxjs';

@Component({
  selector: "app-membership-registration-new",
  templateUrl: "./membership-registration-new.component.html",
  styleUrls: ["./membership-registration-new.component.css"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class MembershipRegistrationNewComponent extends MemberFormBase
  implements AfterViewChecked {
  firstFormGroup: UntypedFormGroup;
  employerCompleted = false;
  personalCompleted = false;
  packageCompleted = false;
  paymentCompleted = false;
  checkscroll = false;
  keyCheckscroll = false;
  primaryMemberResult: Member;
  dataSource = new MatTableDataSource<MemberSearchResultRecord>();
  currentUser: User;
  hasInit = false;
  httpdao: HttpDAO | null;
  retry: number = 0;

  currentTermsAndConditions: TermsAndConditions;
  currentKeyTermsAndConditions: TermsAndConditions;
  termsAndConsContent: SafeHtml;
  keyTermsAndConsContent: SafeHtml;
  isTermsAndConsAccepted = false;
  isKeyTermsAndConsAccepted = false;
  disablechkTermCons = true;
  disablechkKeyTermCons = true;
  heighttabs: string;
  heightTCText: string;
  heightKeyTCText: string;
  openKeyModal = false;
  countriesId = CommonConstants.GET_COUNTRIES;
  fadeMainTerm = false;


  @ViewChild("stepper") stepper: MatStepper;
  @ViewChild("employerInfo", { static: true }) employerInfo: EmployerDetailsNewComponent;
  @ViewChild("personalInfo", { static: true }) personalInfo: PersonalDetailsNewComponent;
  @ViewChild("selectPackagePageInfo", { static: true }) selectPackagePageInfo: SelectPackagePageComponentComponent;
  @ViewChild("paymentInfo", { static: true }) paymentInfo: PaymentDetailsComponent;
  @ViewChild("dlgTermsConsContent", { static: true }) dlgTermsCons: ElementRef;
  @ViewChild("dlgKeyTermsConsContent", { static: true }) dlgKeyTermsCons: ElementRef;

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      EmployerInfo: EmployerDetailsNewComponent.getFormGroup()
    });
  }

  constructor(
    injector: Injector,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private commonservice: CommonService,
    private _formBuilder: UntypedFormBuilder,
    private memberSvc: MemberService,
    private sanitizer: DomSanitizer,
    private modal: NgbModal,
    private commonDataSvc: CommonDataService,
    private http: HttpClient,
    private employerSvc: EmployerService,
  ) {
    super(injector);
    const username = this.commonservice.GetUser();
    if (!username) {
      this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
    }
    this.form = MembershipRegistrationNewComponent.getFormGroup();
    this.async = false;
    this.commonservice.Header.title = "";
  }

  ngOnInit(): void {
    this.commonservice.MenuForMember = false;
    this.commonservice.MenuForFamilyMember = false;

    this.commonservice.App.opened = false;
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ["", Validators.required],
    });

    this.httpdao = new HttpDAO(this.http);
  }

  ngAfterViewChecked() {
    if (this.disablechkTermCons) {
      var termscondObj = document.getElementById("fp-termscondText");
      var termscondContObj = document.getElementById("fp-tcContent");
      if (termscondObj) {
        if (termscondContObj.innerHTML && this.checkscroll) {
          if (termscondObj.scrollHeight == termscondObj.offsetHeight) {
            this.disablechkTermCons = false;
          }
        }
      }
      if (!this.heightTCText) {
        this.setHeightContent();
      }
    }

    if (this.disablechkKeyTermCons) {
      var termscondObj = document.getElementById("fp-keyTermscondText");
      var termscondContObj = document.getElementById("fp-KeytcContent");
      if (termscondObj) {
        if (termscondContObj.innerHTML && this.keyCheckscroll) {
          if (termscondObj.scrollHeight == termscondObj.offsetHeight) {
            this.disablechkKeyTermCons = false;
          }
        }
      }
      if (!this.heightKeyTCText) {
        this.keySetHeightContent();
      }
    }

    this.cdRef.detectChanges();
    if (!this.hasInit) {
      this.hasInit = true;
      this.OnInit();
      this.OnLoad();
    }
  }

  OnLoad() {
    var signUpStep = localStorage.getItem("signUpStep");

    this.currentUser = <User>(
      JSON.parse(
        this.commonservice.D_FP_AES256(
          localStorage.getItem(StorageKey.USER_OBJECT)
        )
      )
    );
    console.log("current user: ", this.currentUser);
    if (this.currentUser && this.currentUser.UserId > 0) {
      this.Invoke(this.memberSvc.getByUserForMemberSignup(this.currentUser.UserId), {
        onSuccess: (res: DataResult<Member>) => {
          if (res.Success) {
            let isSigningUp = Boolean(localStorage.getItem("signupFlag"));
            if (res.Data == null && !isSigningUp) {
              this.retry = this.retry + 1;
              const supportLink =
                '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
              MessageBox.ShowOKCustom(
                this.dialog,
                "Error",
                "Sorry, we're unable to complete the registration. \
                                Please try again. If you continue to experience problems, send us an enquiry \
                                via our support site " +
                supportLink,
                "Take me back"
              ).subscribe((res) => {
                if (res.result.toLowerCase() === DialogResult.Ok) {
                  if (this.retry < 2) {
                    this.OnLoad();
                  } else {
                    this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
                  }
                }
              });
              super.OnLoad();
            } else {
              if (res.Data != null) {
                this.data.model = res.Data;
                signUpStep = this.data.model.SignupStep.toString()
                if (this.data.model.IsExistingMember == null) {
                  this.data.model.IsExistingMember = false;
                }
                if (this.data.model.IsInContract == null) {
                  this.data.model.IsInContract = false;
                }
              }

              const employerId = parseInt(
                this.commonservice.EmployerIDForMemberSignUp,
                10
              );              

              if (employerId > 0) {                             
                this.Invoke(this.employerSvc.getForMemberSignup(employerId), {
                  onSuccess: (getEmployerResult: DataResult<Employer>) => {
                    if (getEmployerResult.Success) {
                      const employer = getEmployerResult.Data;                                         
                      this.data.model.Employer = employer;
                      this.data.model.EmployerId = employerId;                      
                      this.SetSignupStep(signUpStep);
                      this.PatchValue(this.data.model, { emitEvent: false });                                          
                      this.form.markAsPristine();
                    } else {
                      MessageBox.ShowOKCustom(
                        this.dialog,
                        "Error",
                        "Sorry, something went wrong before we could complete your \
                                                    registration. Please log in and complete the registration process. You can use \
                                                    your email and new password to login.",
                        "Take me back"
                      ).subscribe((res) => {
                        if (res.result.toLowerCase() === DialogResult.Ok) {
                          this.router.navigate([
                            RouterConstant.NAVIGATOR_LOGIN,
                          ]);
                        }
                      });
                      this._logger.error(getEmployerResult);
                    }
                    super.OnLoad();
                  },
                  onError: (err: any) => {
                    MessageBox.ShowOKCustom(
                      this.dialog,
                      "Error",
                      "Sorry, something went wrong before we could complete your \
                                                registration. Please log in and complete the registration process. You can use \
                                                your email and new password to login.",
                      "Take me back"
                    ).subscribe((res) => {
                      if (res.result.toLowerCase() === DialogResult.Ok) {
                        this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
                      }
                    });
                    this._logger.error(err);
                    super.OnLoad();
                  },
                });
              }else{                            
                this.SetSignupStep(signUpStep);
                this.PatchValue(this.data.model, { emitEvent: false })
              }              
            }
          } else {
            this.retry = this.retry + 1;
            const supportLink =
              '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
            MessageBox.ShowOKCustom(
              this.dialog,
              "Error",
              "Sorry, we're unable to complete the registration. \
                            Please try again. If you continue to experience problems, send us an enquiry \
                            via our support site " +
              supportLink,
              "Take me back"
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                if (this.retry < 2) {
                  this.OnLoad();
                } else {
                  this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
                }
              }
            });
            this._logger.error(res);
          }
        },
        onError: (err: any) => {
          this.retry = this.retry + 1;
          MessageBox.ShowOKCustom(
            this.dialog,
            "Error",
            "Sorry, something went wrong before we could complete your \
                        registration. Please log in and complete the registration process. You can use \
                        your email and new password to login.",
            "Take me back"
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              if (this.retry < 2) {
                this.OnLoad();
              } else {
                this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
              }
            }
          });
          this._logger.error(err);
          super.OnLoad();
        },
      });

    }

  }

  private SetSignupStep(signUpStep: string) {
    if (Number(signUpStep) - 1 == 1) {
      this.employerCompleted = true;
    }
    else if (Number(signUpStep) - 1 == 2) {
      this.employerCompleted = true;
      this.personalCompleted = true;
    }
    else if (Number(signUpStep) - 1 == 3) {
      this.employerCompleted = true;
      this.personalCompleted = true;
      this.packageCompleted = true;
    }
    else if (Number(signUpStep) - 1 == 4) {
      this.employerCompleted = true;
      this.personalCompleted = true;
      this.packageCompleted = true;
      this.paymentCompleted = true;
    }

    if (Number(signUpStep) - 1 <= 3) {
      this.stepper.selectedIndex = Number(signUpStep) - 1;
    }
    else {
      this.stepper.selectedIndex = 3;
    }

  }

  btnSubmit_Click() {

    if (this.isKeyTermsAndConsAccepted) {
      this.data.model.TermConditionDateTimeStamp = new Date();
      this.data.model.TermConditionId = this.currentKeyTermsAndConditions.TermConditionId;
    } else {
      return;
    }

    MessageBox.ShowInfo(
      this.dialog,
      '<p align="center">Thank you for submitting your application. You will soon receive an email outlining next steps.<br>Don’t press ‘back’ or refresh your screen.</p>'
    )
    this.Invoke(this.memberSvc.SaveTermsAndConditions(this.data.model), {
      onSuccess: (res: ResultModel) => {
        if (res.Success) {
          this.completeSignup();
        } else {
          const supportLink =
            '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
          MessageBox.ShowOKCustom(
            this.dialog,
            "Error",
            "Sorry, we're unable to complete the registration. \
                        Please try again. If you continue to experience problems, send us an enquiry \
                        via our support site " +
            supportLink,
            "Take me back"
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
            }
          });
          this._logger.error(res);
        }
      },
      onError: (err) => {
        const supportLink =
          '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
        MessageBox.ShowOKCustom(
          this.dialog,
          "Error",
          "Sorry, we're unable to complete the registration. \
                    Please try again. If you continue to experience problems, send us an enquiry \
                    via our support site " +
          supportLink,
          "Take me back"
        ).subscribe((res) => {
          if (res.result.toLowerCase() === DialogResult.Ok) {
            this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
          }
        });
        this._logger.error(err);
      },
    });
  }

  private completeSignup() {
    this.data.model.SignupStep = 6;
    localStorage.removeItem("signupFlag");
    this.commonservice.App.isLoginSuccessDisplay = true;
    this.commonservice.App.isMemberSignup = false;
    console.log("Setting Member to Container again");
    const currentUser = <User>JSON.parse(this.commonservice.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
    this.memberSvc.getByUserForMember(currentUser.UserId)
      .subscribe((getMember) => {
        let memberEncrypt = this.commonservice.E_FP_AES256(
          JSON.stringify(getMember)
        );
        if (getMember.Data && !getMember.Data.MemberIdPrimary) {
          if (getMember.Data.MemberCard) {
            this.commonservice.setCardForMemberPortal(
              getMember.Data.MemberCard.Data.Results
            );
          }
          if (getMember.Data.MembershipSuspension) {
            this.commonservice.SetMemSuspension(
              getMember.Data.MembershipSuspension.Data
            );
          }
          if (getMember.Data.FamilyObject) {
            this.commonservice.SetMemFamObj(
              getMember.Data.FamilyObject
            );
          }
        }

        this.httpdao!.getSingleData(
          APIConstant.API_GET_ALL_PRIVILEGES_V2
        ).subscribe((getPrivileges) => {
          let newContainer = new MemberContainer();
          newContainer.Privileges = getPrivileges;
          newContainer.Member = memberEncrypt;
          const loginData = JSON.stringify(newContainer);
          localStorage.setItem(
            StorageKey.CONTAINER_OBJECT,
            loginData
          );
          console.log("Success setting Member");
          this.modal.dismissAll();
          this.router.navigate([RouterConstant.NAVIGATOR_END_MEMBER_SIGNUP]);
        });
      });
  }

  public onScroll() {
    if (this.disablechkTermCons) {
      this.checkScrollEndContent();
    }
  }

  public keyTermOnScroll() {
    if (this.disablechkKeyTermCons) {
      this.checkScrollEndKeyTermContent();
    }
  }

  public onResize() {
    this.setHeightContent();
    this.keySetHeightContent();
  }

  public checkScrollEndContent() {
    var termscondObj = document.getElementById("fp-termscondText");
    var termscondContObj = document.getElementById("fp-tcContent");
    var termscondEndContObj = document.getElementById("fp-tcEndContent");
    if (termscondContObj) {
      if (
        termscondContObj.innerHTML &&
        this.checkscroll &&
        this.disablechkTermCons
      ) {       
        if (
          termscondEndContObj.getBoundingClientRect().bottom <
          termscondObj.getBoundingClientRect().bottom
        ) {          
          this.disablechkTermCons = false;
          this.checkscroll = false;
        }
      }
    }
  }

  public checkScrollEndKeyTermContent() {
    var termscondObj = document.getElementById("fp-keyTermscondText");
    var termscondContObj = document.getElementById("fp-KeytcContent");
    var termscondEndContObj = document.getElementById("fp-KeytcEndContent");

    if (termscondContObj) {
      if (
        termscondContObj.innerHTML &&
        this.keyCheckscroll &&
        this.disablechkKeyTermCons
      ) {
        if (
          termscondEndContObj.getBoundingClientRect().bottom <
          termscondObj.getBoundingClientRect().bottom
        ) {

          this.disablechkKeyTermCons = false;
          this.keyCheckscroll = false;
        }
      }
    }
  }

  public setHeightContent() {
    var termscondContObj = document.getElementById("fp-tcContent");
    if (termscondContObj) {
      this.heightTCText =
        window.innerHeight -
        document.getElementById("modalheader").offsetHeight -
        document.getElementById("chkTC").offsetHeight -
        document.getElementById("btnTC").offsetHeight +
        "px";
    }
  }

  public keySetHeightContent() {
    var termscondContObj = document.getElementById("fp-KeytcContent");
    if (termscondContObj) {
      this.heightKeyTCText =
        window.innerHeight -
        document.getElementById("keyModalheader").offsetHeight -
        document.getElementById("keyChkTC").offsetHeight -
        document.getElementById("keyBtnTC").offsetHeight +
        "px";
    }
  }

  termModalBack(e) {
    e.dismiss()
    this.disablechkTermCons = true;
  }

  keyTermModalBack(e) {
    e.dismiss();
    this.fadeMainTerm = false;
    this.disablechkKeyTermCons = true;
    this.isKeyTermsAndConsAccepted = false;
    //this.openKeyModal = false;
  }

  isCompleteForm(data) {
    if (data == CommonConstants.SIGNUP_EMPLOYER_DETAILS) {
      this.data.model.SignupStep = this.data.model.SignupStep < enums.MemberSignupStep.EmployerDetails ? enums.MemberSignupStep.EmployerDetails : this.data.model.SignupStep;
      this.selectPackagePageInfo.RefreshMembershipPackages(true);
      this.employerCompleted = true;
      this.stepper.selectedIndex = CommonConstants.SIGNUP_PERSONAL_DETAILS;
    } else if (data == CommonConstants.SIGNUP_PERSONAL_DETAILS) {
      this.data.model.SignupStep = this.data.model.SignupStep < enums.MemberSignupStep.PersonalDetails ? enums.MemberSignupStep.PersonalDetails : this.data.model.SignupStep;
      this.personalCompleted = true;
      this.stepper.selectedIndex = CommonConstants.SIGNUP_SELECT_PACKAGE;
    } else if (data == CommonConstants.SIGNUP_SELECT_PACKAGE) {
      this.data.model.SignupStep = enums.MemberSignupStep.PackageDetails;
      this.packageCompleted = true;
      this.stepper.selectedIndex = CommonConstants.SIGNUP_PAYMENT_DETAILS;
    } else {
      this.paymentCompleted = true;
      this.showTermsAndConditions();
    }
    this.PatchValue(this.data.model, null);
  }

  onStepperSelectedChange() {
    this.PatchValue(this.data.model, null);
    window.scrollTo(0, 0);
  }

  signuptabselection(tabid) {
    if (this.stepper.selectedIndex < this.data.model.SignupStep - 1) {
      this.DisplayResetMessage().subscribe(
        (dialogResult) => {
          if (dialogResult.result.toLowerCase() === DialogResult.Yes) {
            this.stepper.selectedIndex = Number(tabid);
          }
          else {
            if (this.stepper.selectedIndex == 0) {
              this.employerInfo.nxtButtonRef.nativeElement.focus();
            }
            else if (this.stepper.selectedIndex == 1) {
              this.personalInfo.nxtButtonRef.nativeElement.focus();
            }
            else if (this.stepper.selectedIndex == 2) {
              this.selectPackagePageInfo.nxtButtonRef.nativeElement.focus();
            }
            return false;
          }
        });
    }
    else {
      this.stepper.selectedIndex = Number(tabid);
    }
  }

  private DisplayResetMessage(): Observable<DialogResult> {
    return MessageBox.ShowYesNo(this.dialog, 'If you leave this page, your unsaved changes will be lost. Are you sure you want to leave this page?', 'Unsaved Changes!');
  }

  showTermsAndConditions() {
    const countryId = this.employerInfo.isFromAustralia ? this.countriesId[0].CountryId : this.countriesId[1].CountryId;

     this.Invoke(
      this.commonDataSvc.getAllTermsAndConditions(),
      {
        onSuccess: (res: DataResult<NewTermsAndConditions[]>) => {          
          if (res.Success) {            
            const primaryTermData = res.Data.filter(term => term.CountryCode === countryId && term.TermConditionType === CommonConstants.PREMIUM_TERMS)[0];                       
            this.currentTermsAndConditions = primaryTermData;
            this.termsAndConsContent = this.sanitizer.bypassSecurityTrustHtml(
              this.currentTermsAndConditions.Content
            );
            this.modal.open(this.dlgTermsCons, {
              windowClass: "membersignup-dialog termconditions-dialog",
              backdrop: "static",
            });
            this.checkscroll = true;
          } else {
            MessageBox.ShowOKCustom(
              this.dialog,
              "Error",
              "Sorry, something went wrong before we could complete your \
                            registration. Please log in and complete the registration process. You can use \
                            your email and new password to login.",
              "Take me back"
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
              }
            });
            this._logger.error(res);
          }
        },
        onError: (err) => {
          const errMsg =
            "Sorry, there was a connection issue. Please try submitting the information again.";
          this.handleError(err, errMsg);
        },
      }
    );
  }

  showKeyTermsAndConditions() {
    const countryId = this.employerInfo.isFromAustralia ? this.countriesId[0].CountryId : this.countriesId[1].CountryId;
 
    this.Invoke(
      this.commonDataSvc.getAllTermsAndConditions(),
      {
        onSuccess: (res: DataResult<NewTermsAndConditions[]>) => {
          if (res.Success) {
            this.fadeMainTerm = true;
            const primaryKeyTermData = res.Data.filter(term => term.CountryCode === countryId && term.TermConditionType === CommonConstants.PREMIUM_KEY_TERMS)[0];             
            this.currentKeyTermsAndConditions = primaryKeyTermData;
              this.keyTermsAndConsContent = this.sanitizer.bypassSecurityTrustHtml(
                this.currentKeyTermsAndConditions.Content
              );  
              this.openKeyModal = true;    
              this.modal.open(this.dlgKeyTermsCons, {
                windowClass: "selectPackage-add-member-dialog",
                backdrop: "static",
              });
              this.keyCheckscroll = true;
          } else {
            MessageBox.ShowOKCustom(
              this.dialog,
              "Error",
              "Sorry, something went wrong before we could complete your \
                            registration. Please log in and complete the registration process. You can use \
                            your email and new password to login.",
              "Take me back"
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
              }
            });
            this._logger.error(res);
          }
        },
        onError: (err) => {
          const errMsg =
            "Sorry, there was a connection issue. Please try submitting the information again.";
          this.handleError(err, errMsg);
        },
      }
    );
  }

  public PatchValue(value, opts) {
    this.employerInfo.PatchValue(value, { emitEvent: false });
    this.personalInfo.PatchValue(value, { emitEvent: false });
    this.selectPackagePageInfo.PatchValue(value, { emitEvent: false });
    this.paymentInfo.PatchValue(value, { emitEvent: false });
  }

}
