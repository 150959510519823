<div [formGroup]="form">
    <ng-template #tmplValidationErrors let-ctrl="control" let-ctrlName="fieldName"
        let-validateOnTouch="validateOnTouch || false">
        <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
            <small *ngIf="ctrl.getError('required')" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_REQUIRED, ctrlName)}}</small>
            <small
                *ngIf="((ctrl.getError('pattern') != null || ctrl.getError('unresolved') != null) && !isCheckingCountry)"
                class="invalid-feedback d-block">{{ StringHelper.format(CommonString.FIELD_INVALID, ctrlName) }}</small>
            <small
                *ngIf="((ctrl.getError('pattern') != null || ctrl.getError('unresolved') != null && form.get('PrimaryEmail').pending) && isCheckingCountry && ctrlName !== 'Primary Email')"
                class="invalid-feedback d-block">{{ StringHelper.format(CommonString.NZ_MOBILE_NUMBER_CHECK, ctrlName)
                }}</small>
            <small
                *ngIf="((ctrl.getError('pattern') != null || ctrl.getError('unresolved') != null) && isCheckingCountry && ctrlName === 'Primary Email')"
                class="invalid-feedback d-block">{{ StringHelper.format(CommonString.FIELD_INVALID, ctrlName) }}</small>
            <small *ngIf="ctrl.getError('duplicated') != null" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_VALUE_ALREADY_EXISTED, ctrlName)}}</small>
        </div>
    </ng-template>

    <app-mobile-number-registor [mandatory]="true" [control]="form.get('MobileNumber')" [isAus]="isAusMember"
        [col_3]="col_3" [isCountryCodeSet]="true" [checkExistMobile]="true" [dataModel]="data.model"
        formControlName="MobileNumber" id="mobile-no-wrapper" [mandotoryLabelRed]="true"
        [primaryDependentSelectedDate]="dependentSelectedDate" #mobileNumberRegistorComponent>
    </app-mobile-number-registor>
    <div class="form-group row">
        <label for="txtFamPrimEmail" class="col-md-3 col-lg-2 col-form-label">Primary Email<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="email" class="form-control" [class.is-invalid]="!isControlValid('PrimaryEmail')"
                    id="txtFamPrimEmail" maxlength="100" formControlName="PrimaryEmail"
                    (blur)="primaryEmailOnBlur($event)" autocomplete="nope">
                <mat-spinner [diameter]="14" color="primary" *ngIf="form.get('PrimaryEmail').pending"></mat-spinner>
                <ng-container
                    *ngTemplateOutlet="tmplValidationErrors;context:{control: form.get('PrimaryEmail'), fieldName: 'Primary Email'}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtEmerContName" class="col-md-3 col-lg-2 col-form-label">Emergency Contact Name<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" [class.is-invalid]="!isControlValid('EmergencyContactName')"
                    id="txtEmerContName" maxlength="100" formControlName="EmergencyContactName" autocomplete="nope">
                <ng-container
                    *ngTemplateOutlet="tmplValidationErrors;context:{control: form.get('EmergencyContactName'), fieldName: 'Emergency Contact Name'}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtRel" class="col-md-3 col-lg-2 col-form-label">Relationship<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input class="form-control" [class.is-invalid]="!isControlValid('EmergencyRelationship')" type="text"
                    id="txtRel" maxlength="100" formControlName="EmergencyRelationship" autocomplete="nope">
                <ng-container
                    *ngTemplateOutlet="tmplValidationErrors;context:{control: form.get('EmergencyRelationship'), fieldName: 'Relationship'}">
                </ng-container>
            </div>
        </div>
    </div>

    <app-mobile-number-registor [mandatory]="true" [isCountryCodeSet]="true" [isAus]="isAusMember"
        [label]="'Emergency Contact No.'" [control]="form.get('EmergencyContactNo')"
        formControlName="EmergencyContactNo" id="mobile-no-wrapper" [col_3]="col_3" [mandotoryLabelRed]="true"
        #mobileNumberRegistorComponentContact>
    </app-mobile-number-registor>
</div>