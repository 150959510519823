import { Component, Injector,ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { CommonService } from '@fp/services';
import { MemberFormBase } from 'src/app/components/base/member-form-base';
import { CommonMessage, CommonString, PatternConstant } from 'src/app/constant';
import { StringHelper } from 'src/app/helpers';
import { MobileNumberRegistorComponent } from "@fp/components/control/mobile-number-registor/mobile-number-registor.component";
import { MemberPersonalInfoComponent } from '../personal-info/personal-info.component';

@Component({
    selector: 'app-member-emergency-contact',
    templateUrl: './emergency-contact.component.html',
    styleUrls: ['./emergency-contact.component.css'],
    providers: [{ provide: MemberFormBase, useExisting: MemberEmergencyContactComponent }]
})
export class MemberEmergencyContactComponent extends MemberFormBase {
    orgiCtryPhoneCode = "";
    countryPhoneCode = "";
    StringHelper = StringHelper;
    CommonMessage = CommonMessage;
    CommonString = CommonString;
    mobileLength = 10;
    isCheckingCountry: boolean = false;
    parentComponent: MemberPersonalInfoComponent;
    col_3 = true;

    @ViewChild("emergencyMobileNumberRegistorComponent") EmmobileNumberRegistor: MobileNumberRegistorComponent<any>;

    static getFormGroup() {

        const fb = new UntypedFormBuilder();
        return fb.group({
            EmergencyRelationship: [null, Validators.required],
            EmergencyContactName: [null, Validators.required],
            EmergencyContactNo: [null, Validators.required]
        });
    }

    constructor(injector: Injector,private cmsrv: CommonService) { super(injector);
        const _parent: MemberPersonalInfoComponent = this.injector.get<MemberPersonalInfoComponent>(MemberPersonalInfoComponent); 
        this.parentComponent = _parent;
     }
    ngAfterViewInit() {
        this.countryPhoneCode =this.cmsrv.SetMobileCountryCode();       
        this.orgiCtryPhoneCode='+'.concat(this.countryPhoneCode) ;
    }


    get EmergencyContactNameCtrl() { return <UntypedFormControl>this.form.get('EmergencyContactName'); }
    get EmergencyRelationshipCtrl() { return <UntypedFormControl>this.form.get('EmergencyRelationship'); }
    get EmergencyContactNoCtrl() { return <UntypedFormControl>this.form.get('EmergencyContactNo'); }

    PatchValue(value, opts) {
        if(value){
            this.EmmobileNumberRegistor.dataModel = value;
        }
        this.EmmobileNumberRegistor.memberTypePass = this.parentComponent.personalDetails.getControl('MemberTypeId');

        //FP-849,FP-848
        if (!value.EmergencyContactNo.startsWith('+61') && !value.EmergencyContactNo.startsWith('+64') && value.EmergencyContactNo.length == 9) {
            value.EmergencyContactNo = '+61' + value.EmergencyContactNo;
        }
        
        if (!value.EmergencyContactNo.startsWith('+61') && !value.EmergencyContactNo.startsWith('+64') && value.EmergencyContactNo.startsWith('0') && value.EmergencyContactNo.length == 10) {
            value.EmergencyContactNo = '+61' + value.EmergencyContactNo.substring(1);
        }
        if (value.EmergencyContactNo.match(/^\++/)){
            value.EmergencyContactNo = value.EmergencyContactNo.replace(/^\++/, '+');
        }

        this.EmmobileNumberRegistor.SetValueFromMobileNo(value.EmergencyContactNo, false);
        this.EmergencyContactNameCtrl.setValue(value.EmergencyContactName, { emitEvent: false });
        this.EmergencyRelationshipCtrl.setValue(value.EmergencyRelationship, { emitEvent: false }) 
    }

    
}
