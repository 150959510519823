import { Component, Injector, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import {
  FpFileData,
  FpFileEvent,
  FpFileUploadComponent,
} from "@fp/components/control";
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateNativeUTCAdapter,
  NgbInputDatepicker,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { ManagedUpload } from "aws-sdk/clients/s3";
import { MemberFormBase } from "src/app/components/base/member-form-base";
import { CommonMessage, CommonString, PatternConstant, StorageKey } from "src/app/constant";
import * as enums from "src/app/enums";
import { CustomMessageBox, StringHelper } from "src/app/helpers";
import { DataResult, Gender, MemberType, UploadFile, User } from "src/app/models";
import { CommonDataService } from "src/app/services/common-data.service";
import { MessageBox } from "src/app/services/common-dialog.service";
import { MemberService } from "src/app/services/member.service";
import { EMemberStatus } from "src/app/enums";
import { Router } from "@angular/router";
import { CommonConstants } from "@fp/constant/common-constants";
import { CommonService, MembershipService } from "@fp/services";
import { MembershipUploadPhotoComponent } from "../../membership-cards/membership-upload-photo/membership-upload-photo.component";
import { ERole } from "@fp/enums/role.enum";

@Component({
  selector: "app-member-family-member-details",
  templateUrl: "./family-member-details.component.html",
  styleUrls: ["./family-member-details.component.css"],
  providers: [
    {
      provide: MemberFormBase,
      useExisting: MemberFamilyMemberDetailsComponent,
    },
    { provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter },
  ],
})
export class MemberFamilyMemberDetailsComponent extends MemberFormBase {
  parentFocus = 0;
  genders: Gender[];
  memberTypes: MemberType[];
  selectedMemberType: MemberType;
  currentImageBase64Data: string;
  private modalRef: NgbModalRef;
  hasInit: boolean = false;

  GenderCtrl = new UntypedFormControl(null, Validators.required);
  imgUrl: any;
  get DateOfBirthCtrl() {
    return this.form.get("DateOfBirth");
  }
  get MemberTypeCtrl() {
    return this.form.get("MemberType");
  }
  // get CanChangeDetailsCtrl() { return this.form.get('CouldChangeAccountDetail'); }

  @ViewChild("dpDOB") private dpDateOfBirth: NgbInputDatepicker;
  @ViewChild("fileUploader") photoUploader: FpFileUploadComponent;

  CommonMessage = CommonMessage;
  CommonString = CommonString;
  StringHelper = StringHelper;
  MemberStatus = EMemberStatus;
  isPartner = false;
  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      MemberId: [0],
      FirstName: [
        null,
        [Validators.required, Validators.pattern(PatternConstant.VALID_NAME)],
      ],
      LastName: [
        null,
        [Validators.required, Validators.pattern(PatternConstant.VALID_NAME)],
      ],
      GenderId: [null, Validators.required],
      DateOfBirth: [null, [Validators.required]],
      MemberTypeId: [0],
      MemberType: [null, Validators.required],
      Photo: [null],
    });
  }

  constructor(
    injector: Injector,
    private commonDataSvc: CommonDataService,
    private memberShipService: MembershipService,
    private commonService: CommonService,
    private memberSvc: MemberService,
    private ngbCalendar: NgbCalendar,
    private modalService: NgbModal,
    private router: Router
  ) {
    super(injector);
  }
  
  ngAfterViewInit() {
    if(!this.hasInit) {
      this.OnInit();
      this.OnLoad();
    }
  }

  OnInit() {
    const today = this.ngbCalendar.getToday();
    this.dpDateOfBirth.minDate = this.ngbCalendar.getPrev(today, "y", 100);
    this.dpDateOfBirth.maxDate = today;
    if (this.photoUploader != undefined || this.photoUploader != null) {
      this.photoUploader.fpfileupload.nativeElement.addEventListener(
        "click",
        (e) => {
          //this.openUploadPhotoModal();
          this.btnUploadPhoto_Click(e);
        }
      );
    }
    super.OnInit();
  }

  OnLoad() {
    this.handleGetGendersResult();
    this.handleGetMemberTypesResult(CommonConstants.GET_MEMBER_TYPES);
    super.OnLoad();
  }
  
  openUploadPhotoModal() {
    this.modalRef = this.modalService.open(MembershipUploadPhotoComponent, {
      backdrop: "static",
    });
    
    (<MembershipUploadPhotoComponent>(
      this.modalRef.componentInstance
    )).memberPersonalSelected = {
      id: parseInt(this.GetMemberID()),
      photo: null
    };

    this.modalRef.result.then(
      (result: { location: string }) => {
        const s3path = result.location; 
        const key = result.location.substring(result.location.indexOf("memberPhotos/"), result.location.length);

        this.form.get("Photo").setValue({
          Id: 0,
          FileName: 'Photo',
          Data: key,
          Location: s3path,
        });

        this.commonService.getDownloadUrl(key).subscribe(
          (res) => {
            this.imgUrl = res;
          },
          (err) => {
            console.error("Error in get url", err);
          },
        );
      },
      (reason) => {
        
      }
    );
  }

  public isSignup() {
    return this.commonSvc.App.isMemberSignup;
  }
  
  openModal() {
    const modelUpload = this.modalService.open(MembershipUploadPhotoComponent, {
      backdrop: "static",
    });

    (<MembershipUploadPhotoComponent>(
      modelUpload.componentInstance
    )).memberShipCardsSelected = {
      MemberId: parseInt(this.GetMemberID()),
      PhotoLocation: this.imgUrl,
      ModifiedBy: this.commonService.GetUser()
    };
    (<MembershipUploadPhotoComponent>(
      modelUpload.componentInstance
    )).isChargeFee = true;
    
    modelUpload.result.then(
      (result: { location: string }) => {
        const s3path = result.location; 
        const key = result.location.substring(result.location.indexOf("memberPhotos/"), result.location.length);

        this.form.get("Photo").setValue({
          Id: 0,
          FileName: 'Photo',
          Data: key,
          Location: s3path,
        });

        this.commonService.getDownloadUrl(key).subscribe(
          (res) => {
            this.imgUrl = res;
            const isMember = this.commonService.GetSelectedRole() === ERole.member;
            if(isMember) {
              const currentUser = <User>JSON.parse(this.commonService.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
              this.memberSvc
              .getByUserForMember(currentUser.UserId)
              .subscribe((getMember) => {
                  if (getMember.Data.FamilyObject) {
                  this.commonService.SetMemFamObj(
                      getMember.Data.FamilyObject
                  );
                  }
              });
            }
          },
          (err) => {
            console.error("Error in get url", err);
          },
        );
      },
      (reason) => {
        
      }
    );
  }

  public GetMemberID(): string {
    const mid = this.getControl("MemberId").value;
    return mid;
  }

  LoadComplete() {
    super.LoadComplete();
    setTimeout(() => {
      this.GenderCtrl.valueChanges.subscribe((value: Gender) => {
        this.form.get("GenderId").setValue(value ? value.GenderId : null);
        this.form.get("GenderId").markAsDirty();
      });

      this.MemberTypeCtrl.valueChanges.subscribe((value: MemberType) => {
        this.selectedMemberType = value;
        const today = this.ngbCalendar.getToday();
        this.dpDateOfBirth.minDate = this.ngbCalendar.getPrev(today, "y", 100);
        this.dpDateOfBirth.maxDate = today;
        if (value && value.MemberTypeId === enums.EMemberType.Partner) {
          this.isPartner = true;
          this.dpDateOfBirth.maxDate = this.ngbCalendar.getPrev(today, "y", 16);
          this.dpDateOfBirth.maxDate.day = today.day;
          this.dpDateOfBirth.maxDate.month = today.month;
        } else {
          this.isPartner = false;
          if (value && value.MemberTypeId === enums.EMemberType.Dependant) {
            this.dpDateOfBirth.minDate = this.ngbCalendar.getPrev(
              today,
              "y",
              25
            );
            this.dpDateOfBirth.minDate.day = today.day;
            this.dpDateOfBirth.minDate.month = today.month;
            console.log(this.dpDateOfBirth.minDate);
          }
          //this.CanChangeDetailsCtrl.setValue(false);
        }
        this.form.get("MemberTypeId").setValue(value ? value.MemberTypeId : 0);
        this.getControl("DateOfBirth").updateValueAndValidity({
          emitEvent: false,
        });
      });
    });
  }

  PatchValue(value, options) {
    const memberType = <MemberType>value["MemberType"];
    if (memberType && memberType.MemberTypeId === enums.EMemberType.Dependant) {
      const today = this.ngbCalendar.getToday();
      this.dpDateOfBirth.minDate = this.ngbCalendar.getPrev(today, "y", 25);
      this.dpDateOfBirth.minDate.day = today.day;
      this.dpDateOfBirth.minDate.month = today.month;
      console.log(this.dpDateOfBirth.minDate);
    }
    super.PatchValue(value, options);
  }

  private handleGetGendersResult() {
    this.genders = CommonConstants.GET_GENDERS.sort((a, b) => {
      if (a.DisplayOrder != null && b.DisplayOrder != null) {
        return a.DisplayOrder - b.DisplayOrder;
      } else {
        return a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1;
      }
    });
  }

  private handleGetMemberTypesResult(result: MemberType[]) {
    console.log(result);
    // if (result.Success) {
    this.memberTypes = result
      .filter((type) => {
        if (
          type.MemberTypeId !== enums.EMemberType.Partner &&
          type.MemberTypeId !== enums.EMemberType.Dependant
        ) {
          return false;
        } else if (
          type.MemberTypeId === enums.EMemberType.Partner &&
          (!this.data.model.MemberType ||
            this.data.model.MemberType.MemberTypeId !==
              enums.EMemberType.Partner)
        ) {
          const famMembers = this.data.model.PrimaryMember
            ? this.data.model.PrimaryMember.FamilyMembers
            : null;
          if (
            famMembers &&
            famMembers instanceof Array &&
            famMembers.findIndex(
              (m) =>
                m.MemberType.MemberTypeId === enums.EMemberType.Partner &&
                m.MemberStatusId !== enums.EMemberStatus.Invalid &&
                m.MemberStatusId !== enums.EMemberStatus.Inactive &&
                m.MemberStatusId !== enums.EMemberStatus.Banned &&
                m.MemberStatusId !== enums.EMemberStatus.DeIdentified
            ) > -1
          ) {
            return false;
          }
        }
        return true;
      })
      .sort((a, b) => {
        if (a.DisplayOrder != null && b.DisplayOrder != null) {
          return a.DisplayOrder - b.DisplayOrder;
        } else {
          return a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase()
            ? -1
            : 1;
        }
      });
    // } else {
    //     this.HandleResponseError(result);
    // }
  }

  ddlGenderOption_CompareFn(opt1: Gender, opt2: Gender) {
    return (
      (!opt1 && !opt2) || (opt1 && opt2 && opt1.GenderId === opt2.GenderId)
    );
  }

  ddlMemberTypeOption_CompareFn(opt1: MemberType, opt2: MemberType) {
    return (
      (!opt1 && !opt2) ||
      (opt1 && opt2 && opt1.MemberTypeId === opt2.MemberTypeId)
    );
  }

  canUploadPhoto(): boolean {
    if(this.isSignup()) {
      return true;
    } else if (this.data.model.MemberStatusId !== enums.EMemberStatus.New) {
      return true
    } else {
      return false;
    }
  }

  checkFirst(e: Event) {
    if(this.isSignup() || this.data.model.MemberStatusId === enums.EMemberStatus.New || this.data.model.MemberStatusId === enums.EMemberStatus.CandidateMember) {
      this.openUploadPhotoModal();
    }
    this.btnUploadPhoto_Click(e);
  }

  btnUploadPhoto_Click(e: Event) {
    // Intentionally leave blank for overriding.
  }

  addImage(event) {
    const file = event.target.files[0];
    if (
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/jpeg"
    ) {
      MessageBox.ShowError(
        this.dialog,
        StringHelper.format(
          CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE,
          "JPEG or PNG"
        )
      );
      return;
    } else if (file.size > CommonString.MAX_UPLOAD_FILE_SIZE) {
      MessageBox.ShowError(
        this.dialog,
        StringHelper.format(CommonString.MAX_UPLOAD_FILE_SIZE_MESSAGE, "2MB")
      );
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      this.currentImageBase64Data = <string>reader.result;
      const logo: UploadFile = {
        FileName: file.name,
        Location: null,
        Data: (<string>reader.result).replace(/^data:(.*;base64,)?/, ""),
      };
      this.form.get("Photo").setValue(logo);
    };
    reader.readAsDataURL(file);
  }

  /** @internal */
  _removePhoto() {
    const id = <number>this.form.get("Photo").value["Id"];
    this.form.get("Photo").patchValue(id > 0 ? { Location: null } : null);
    this.photoUploader.ResetFileInputValue();
  }

  /** @internal */
  _fileUploaded(file: FpFileData) {
    // this.form.get("Photo").setValue({
    //   Id: 0,
    //   FileName: file.originfilename,
    //   Data: (<ManagedUpload.SendData>file.filedata).Key,
    //   Location: (<ManagedUpload.SendData>file.filedata).Location,
    // });

    //pre-signed url
    const url = file.filedata.url;
    //const s3path = url.substring(0, url.indexOf("memberPhotos/"));
    const s3path = url; 
    const key = url.substring(url.indexOf("memberPhotos/"), url.length);
    this.form.get("Photo").setValue({
      Id: 0,
      FileName: file.originfilename,
      Data: key,
      Location: s3path,
    });

    //Pre-signed url way
    this.commonService.getDownloadUrl(key).subscribe(
      (res) => {
        this.imgUrl = res;
      },
      (err) => {
        console.error("Error in get url", err);
      }
    );
  }

  /** @internal */
  _fileSelected(event: FpFileEvent) {
    if (
      event.file.type !== "image/png" &&
      event.file.type !== "image/jpg" &&
      event.file.type !== "image/jpeg"
    ) {
      event.cancel();
      MessageBox.ShowError(
        this.dialog,
        StringHelper.format(
          CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE,
          "JPEG or PNG"
        )
      );
    }

    //Reset the value of photo control in the form - forces the child component (member-image) to run each time
    this.form.get("Photo").setValue({
      Id: 0,
      FileName: "placeholder",
      Data: "placeholder",
      Location: null,
    });
  }

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }
  onBlurParent(focusNumbr) {
    this.parentFocus = focusNumbr;
  }
  getClassFocused(vlFocused) {
    if (this.parentFocus == vlFocused) {
      return "focused";
    } else {
      return "";
    }
  }

  public IsAdmin() {
    const path = this.router.url;
    const memberid = this.getControl("MemberId").value;
    const role = this.commonSvc.GetSelectedRole();
    if (memberid === 0) {
      this.getControl("FirstName").enable();
      this.getControl("LastName").enable();
      this.getControl("GenderId").enable();
      return true;
    } else {
      if (path !== "/membersignup") {
        if (role === "Member" || (role === "Employer_Facilitator")) { 
          this.getControl("FirstName").disable();
          this.getControl("LastName").disable();
          this.getControl("GenderId").disable();
          return false;
        }
        else {
          return true;
        } 
      } else {
        this.getControl("FirstName").enable();
        this.getControl("LastName").enable();
        this.getControl("GenderId").enable();
        return true;
      }
    }
  }
}
