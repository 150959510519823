<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{ isMemberPhotoUpload ? 'Upload Member Photo'  : 'Change Membership Photo' }}</h5>
</div>
<div class="modal-body py-4">
  <div class="loading-shade-global" *ngIf="isDisable">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div class="fp-profile d-flex justify-content-center">
    <div class="w-100 px-2 overflow-hidden">
      <!-- <div *ngIf="photoSelected.Location == null"
                            class="bg-secondary fp-profilephoto d-flex justify-content-center align-items-center mx-auto ng-star-inserted">
                            <i class="fa fa-image fa-2x text-secondary"></i></div> 
                        <div *ngIf="photoSelected.Location != null" class="position-relative">
                            <img [src]="photoSelected.Location" alt="" class="img-fluid">
                        </div>
                        -->
      <!-- <div *ngIf="photoSelected.Location == null"
        class="bg-secondary fp-profilephoto d-flex justify-content-center align-items-center mx-auto ng-star-inserted">
        <i class="fa fa-image fa-2x text-secondary"></i></div> -->
      <div *ngIf="photoSelected.Location != null; else noPhoto" class="position-relative overflow-hidden">
        <angular-cropper #angularCropper (ready)="readyCropperImage($event)" [imageUrl]="photoSelected?.Location"
          [cropperOptions]="config"></angular-cropper>
        <div class="fpzomm position-absolute position-bottomright rounded-10 p-1 mr-1 mb-2 bg-primary">
          <p (click)="ZoomInMe()" class="m-0 cursordisplay"><i class="fas fa-plus-circle text-white"></i></p>
          <p (click)="ZoomOutMe()" class="m-0 cursordisplay"><i class="fas fa-minus-circle text-white"></i>
          </p>
        </div>
        <p class="position-absolute position-bottomleft rounded-10 px-1 mb-2 ml-1 bg-primary cursordisplay"
          (click)="RotateRightMe()"><i class="fas fa-redo-alt text-white"></i></p>
      </div>
      <ng-template #noPhoto>
        <div class="bg-secondary fp-profilephoto d-flex justify-content-center align-items-center mx-auto">
          <i class="fa fa-image fa-2x text-secondary"></i>
        </div>
      </ng-template>
      <div class="w-50 mx-auto">
        <div class="custom-file mt-2">
          <app-fp-file-upload buttonlabel="Upload Photo" ngInputFile="w-100" filterFile="image/jpeg,image/png"
            maxfilesize="10" (fileSelected)="ImagesAdditionalSelected($event)"
            (UploadCompleted)="UploadPhotoCompleted($event)">
          </app-fp-file-upload>
          <form #formUploadPhoto>
            <input #txtUploadPhoto type="file" class="custom-file-input" id="txtUploadPhoto"
              (change)="UploadPhoto($event)" [disabled]="false" accept="image/*">
            <label class="custom-file-label bg-primary text-light" for="txtAttachLogo"><i
                class="fas fa-upload mr-2"></i>Upload Photo</label>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 d-flex justify-content-center">
    <button id="ms_cards_change_photo_close" class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3"
      type="button" (click)="dismiss()">
      <i class="fas fa-ban mr-2"></i>Cancel</button>
    <button (click)="OnSubmitUploadPhoto()" class="px-2 px-lg-5 py-2 btn btn-primary" type="button"
      [disabled]="setDisable() || isDisable">
      <i class="fas fa-check-circle mr-2"></i>
      Submit</button>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Uploading...{{ percent }}%</h4>
  </div>
  <div class="modal-body" style="margin-bottom: 10px">
    <ngb-progressbar *ngIf="!IsUploadCompleted" type="success" [value]="percent"></ngb-progressbar>
    <div *ngIf="IsUploadCompleted">
      <ngb-alert [dismissible]="false">
        Upload successfully.
      </ngb-alert>
    </div>
  </div>
</ng-template>