import { Component, OnInit, ViewChild, AfterContentInit } from "@angular/core";
import { RouterConstant, CommonString } from "src/app/constant";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { FacilityService, PaymentService, DataService, CommonService } from "src/app/services";
import { MessageBox } from "./../../../services/common-dialog.service";
import { catchError, flatMap } from "rxjs/operators";
import { Observable ,  forkJoin } from "rxjs";
import {
  SearchPaymentRequest,
  Facility,
  PostPaymentRequest,
  DataResult,
  ApproveRejectRecords,
  ApproveRejectService,
  CalculationMethodAntiFraudSend,
  ResultModel,
  Service,
  ResultDataModel,
  PaymentRequestDataResult
} from "src/app/models";
import { ApprovePaymentRequestComponent } from "./approve-payment-request/approve-payment-request.component";
import { DataSourceHelper } from "@fp/helpers/data-source-helper";
import { PaymentRequestModel } from "@fp/models/payment-request.model";
import { RawVisitParameters } from "@fp/models/raw-visit-parameters.model";
import { PostedPaymentRequests } from "@fp/models/posted-payment-requests.model";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { Utils } from "@fp/helpers";
import { EditPaymentRequestComponent } from "./edit-payment-request/edit-payment-request.component";
import { CommonConstants } from "@fp/constant/common-constants";

@Component({
  selector: "app-view-payment-requests",
  templateUrl: "./view-payment-requests.component.html",
  styleUrls: ["./view-payment-requests.component.css"]
})
export class ViewPaymentRequestsComponent implements OnInit, AfterContentInit {
  paymentRequestForm = new UntypedFormGroup({
    facility: new UntypedFormControl(""),
    status: new UntypedFormControl(""),
    paymentDateFrmForm: new UntypedFormControl(""),
    paymentDateToForm: new UntypedFormControl(""),
    postedDateFrmForm: new UntypedFormControl(""),
    postedDateToForm: new UntypedFormControl("")
  });

  displayedColumns: string[] = [
    "Facility",
    "Service",
    "DateCreated",
    "VisitCount",
    "DollarAmount",
    "PaymentCalculationMethod",
    "AntiFraudStatus",
    "PRStatus",
    "Download",
    "Edit",
    "ApproveReject"
  ];
  CommonString = CommonString;
  public IsShowMandatoryToDate = false;
  public IsShowMandatoryFromDate = false;

  dataSource: MatTableDataSource<PostPaymentRequest> = new MatTableDataSource<
    PostPaymentRequest
  >();
  @ViewChild(MatSort) sort: MatSort;
  paymentRequestData = new SearchPaymentRequest();
  paymentRequestData3Month = new ApproveRejectService();
  calculationMethodAntiFraudSend = new CalculationMethodAntiFraudSend();
  recordsCurrent = new PaymentRequestModel();
  private listOfInvalidFacilities:string[];
  public dataSource3Month: any;
  public dataSourceCalcMethod: any;
  RouterConstant = RouterConstant;
  selected = [];
  selectedFacility = "";
  seletedCountry : string;
  countryTitle : string;
  routedCountry : string;
  facilitySearchResult: Facility[] = [];
  result: any;
  facilityName: string;
  serviceName: string;
  toDate: string;
  fromDate: string;
  finalVisit = 0;
  isSearchingFacility = false;
  messageLOA: string;
  isLoading = false;
  approvedFlag = false;
  fileName: string;
  csvFile: PostedPaymentRequests[] = [];
  selectedPmtFromDate: any;
  selectedPmtToDate: any;
  approvedResults: number = 0;
  postedResults: number = 0;
  totalResults: number = 0;
  searched = false;

  defaultMatSelectValue = [102]; //Default selected value in mat-select (dropdown) is "All Active Statuses"
  @ViewChild('prpagi') pr_paginator: MatPaginator;
  currentPageIndex: number;

  constructor(
    private facilitySvc: FacilityService,
    private PaymentSvc: PaymentService,
    private dialog: MatDialog,
    private shareData: DataService,
    private cmsrv:CommonService,
    private route: ActivatedRoute,
    private router: Router,
  ) 
  { 
    // force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }


  ngOnInit() {
var headerLableValue="";
    this.route.queryParams.subscribe(params => {
      this.routedCountry = params['country'];
      if(this.routedCountry == 'newZealand'){
        this.seletedCountry = CommonString.NEWZEALAND;
        this.countryTitle = 'NZ';
        headerLableValue="View Payment Requests (NZ)";
      }
      else{
        this.routedCountry = CommonString.AUSTRALIA.toLowerCase();
        this.seletedCountry = CommonString.AUSTRALIA;
        this.countryTitle = 'AU';
        headerLableValue="View Payment Requests (AU)";
      }
    });
    this.cmsrv.Header.title = headerLableValue;
    // set initial focus to facility field
    document.getElementById("facilityName").focus();
  }

  ngAfterContentInit() {
    this.findFacilityByName();
  }

  /**
   * Validates the Date Picker value - if either value is null, returns false.
   * @param dateFrom The From Date Value
   * @param dateTo The To Date Value
   * @returns true if the dates are both not null
   */
  private validateDateInput(dateFrom, dateTo): boolean {
    if (
      dateFrom !== "" &&
      dateTo !== "" &&
      dateFrom !== undefined &&
      dateTo !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * This function fetches data from backend in real time and populates the table as and when filter values are changed
   */
  public search(pageNumber?: number): void {
    //Limits the FROM/TO date-range according to the selected date
    this.selectedPmtFromDate = this.paymentRequestForm.value.paymentDateFrmForm;
    this.selectedPmtToDate = this.paymentRequestForm.value.paymentDateToForm;

    if (this.selectedPmtFromDate === '') {
      this.IsShowMandatoryFromDate = true;
    } else {
      this.IsShowMandatoryFromDate = false;;
    }

    if (this.selectedPmtToDate === '') {
      this.IsShowMandatoryToDate = true;
    } else {
      this.IsShowMandatoryToDate = false;;
    }

    // If either Date Pickers have a null value, quits execution early
    if (
      !this.validateDateInput(this.selectedPmtFromDate, this.selectedPmtToDate)
    ) {
      return;
    }

    this.paymentRequestData.Facility = this.paymentRequestForm.value.facility;
    this.paymentRequestData.Status = this.paymentRequestForm.value.status;
    this.paymentRequestData.PaymentDateFrm = this.convertDateFormat(
      this.paymentRequestForm.value.paymentDateFrmForm
    );
    this.paymentRequestData.PaymentDateTo = this.convertDateFormat(
      this.paymentRequestForm.value.paymentDateToForm
    );

    // To make sure the date is left as an empty string when it is null
    if (
      this.paymentRequestForm.value.postedDateFrmForm === "" ||
      this.paymentRequestForm.value.postedDateToForm === "" ||
      (this.paymentRequestForm.value.postedDateFrmForm === undefined ||
        this.paymentRequestForm.value.postedDateToForm === undefined)
    ) {
      this.paymentRequestData.PostedDateFrm = "";
      this.paymentRequestData.PostedDateTo = "";
    } else if (
      this.paymentRequestForm.value.postedDateFrmForm !== undefined ||
      this.paymentRequestForm.value.postedDateToForm !== undefined
    ) {
      this.paymentRequestData.PostedDateFrm = this.convertDateFormat(
        this.paymentRequestForm.value.postedDateFrmForm
      );
      this.paymentRequestData.PostedDateTo = this.convertDateFormat(
        this.paymentRequestForm.value.postedDateToForm
      );
    }

    this.isLoading = true;
    this.searched = true;

    this.postedResults = 0;
    this.approvedResults = 0;
    this.totalResults = 0;

    console.log('Data to send: ', this.paymentRequestData);
       
    this.Invoke(
      
      this.PaymentSvc.getPaymentRequestFromBackend(this.paymentRequestData),
      (result: DataResult<PaymentRequestDataResult>) => {

        
        result.Data.PaymentRequestData = this.filterPaymentRequestDataByCountry(result.Data.PaymentRequestData);
        this.totalResults = result.Data.PaymentRequestData.length;
        //Only show results if payment requests returned is less than 5000
        if (this.totalResults <= 5000) {
          this.dataSource = new MatTableDataSource<PostPaymentRequest>(
            result.Data.PaymentRequestData
          );
          console.log(result);
  
          this.dataSource.sortingDataAccessor =
            DataSourceHelper.localeLowerCaseSortingDataAccessor;
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.pr_paginator;
  
          this.postedResults = this.countResultStatus(result.Data.PaymentRequestData, "Posted");
          this.approvedResults = this.countResultStatus(result.Data.PaymentRequestData, "Approved");
        }
        else {
          MessageBox.ShowInfo(
            this.dialog,
            "There are more than 5000 matching PRs. Please narrow the search by refining the search parameters and try again."
          );
        }
        this.isLoading = false;
      }
    );

    //Set payment request table to 1st page for new search, refresh etc.
    if (pageNumber === 0) {
      this.pr_paginator.pageIndex = pageNumber;
    }
  }

  filterPaymentRequestDataByCountry(result :PostPaymentRequest[]){
    if(this.seletedCountry == CommonString.NEWZEALAND){
      result  = result.filter(x => x.FacilityCountry == CommonString.NEWZEALAND);
    }
    else{
      
      result =  result.filter(x => x.FacilityCountry != CommonString.NEWZEALAND);
    }

    return result;
  }

   /**
   * Counts the number of results returned with the PRStatus given
   * @param data - The PostPaymentRequest list
   * @param status - The Status to test for
   */
  private countResultStatus(
    data: Array<PostPaymentRequest>,
    status: string
  ): number {
    var statusCount = 0;
    data.forEach(element => {
      if (element.PRStatus === status) {
        statusCount += 1;
      }
    });

    return statusCount;
  }

  getSelectedOptions(selected) {
    this.selected = selected;
  }

  Invoke(source: Observable<any>, handleResultCallback: Function, handleErrorCallback?: Function) {
    source
      .pipe(
        catchError(e => {
          handleErrorCallback();
          throw e;
        })
      )
      .subscribe(res => {
        handleResultCallback(res);
      });
  }

  findFacilityByName(event?: KeyboardEvent) {
    // this excludes functionality for keycodes for arrow keys (37, 38, 39, 40)
    if (event && event.keyCode < 41 && event.keyCode > 36) {
      return;
    } else {
      this.isSearchingFacility = true;
      const text = (<HTMLInputElement>document.getElementById("facilityName"))
        .value;
      this.selectedFacility = text;
      this.Invoke(this.facilitySvc.findByName(text), (data: Facility[]) => {
        this.isSearchingFacility = false;
        this.facilitySearchResult = this.filterFacilitybyCountry(data);
      });
    }
  }

  filterFacilitybyCountry(facilities: Facility[]){
    if(this.seletedCountry == CommonString.NEWZEALAND){
      facilities  = facilities.filter(x => x.FacilityCountryName == CommonString.NEWZEALAND);
    }
    else{
      
      facilities =  facilities.filter(x => x.FacilityCountryName != CommonString.NEWZEALAND);
    }
    return facilities;
  }

  paginatorChange(event: PageEvent) {
    this.currentPageIndex = event.pageIndex;
  }

  editPaymentRequest(input) {
    for (let i = 0; i < this.dataSource.data.length; i++) {
      if (this.dataSource.data[i].PaymentRequestID === input) {
        this.shareData.changeMessage(input);
        console.log(input);

        //WIP- Change from route to new page to dialog
        const dialogRef = this.dialog.open(EditPaymentRequestComponent, {
          width: "800px"
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
          this.search(this.currentPageIndex);
        });
      }
    }
  }

  /**
   * Checks the user's permission levels, and shows a dialog box if the perms level is too low.
   * Otherwise opens the ApprovePaymentRequestComponent with a list of all the Approved Payment Requests from the
   * last 3 months for this Service.
   *
   * Re-fetches the main Payment Request table on Dialogue Close.
   *
   * @param event The event details that this function is called by.
   */
  fetchValuesFor3MonthHistory(event): void {
    this.recordsCurrent.FacilityName = event.FacilityName;
    this.recordsCurrent.ServiceName = event.Service;
    this.recordsCurrent.CreatedDate = event.DateCreated;
    this.recordsCurrent.Username = this.cmsrv.GetUser();
    this.recordsCurrent.Amount = event.Amount;
    this.isLoading = true;
    this.Invoke(
      this.PaymentSvc.checkUserPermissionLoa(this.recordsCurrent),
      (data: ResultModel) => {
        this.isLoading = false;
        this.messageLOA = data.Message;
        if (
          this.messageLOA ===
          "Your Level of Authority(LOA) is insufficient to approve or reject this Payment Request"
        ) {
          MessageBox.ShowError(
            this.dialog,
            "Your Level of Authority(LOA) is insufficient to approve or reject this Payment Request"
          );
          //when user is allowed, open the dialog and fetch required data from backend
        } else if (this.messageLOA === " User has permission as per LOA") {
          this.calculationMethodAntiFraudSend.PaymentRequestID = event.PRID;
          this.paymentRequestData3Month.Service = event.Service;
          this.paymentRequestData3Month.PaymentRequestID = event.PRID;
          this.isLoading = true;
          forkJoin(
            this.PaymentSvc.getCalculationMethodAntiFraud(
              this.calculationMethodAntiFraudSend
            ),
            this.PaymentSvc.get3MonthPaymentRequestFromBackend(
              this.paymentRequestData3Month
            )
          ).subscribe(([dataSourceCalcMethod, dataSource3Month]) => {
            this.isLoading = false;
            const dialogRef = this.dialog.open(ApprovePaymentRequestComponent, {
              width: "800px",
              data: {
                data3Month: new MatTableDataSource<ApproveRejectRecords>(
                  dataSource3Month.Data
                ),
                dataCalcMethod: dataSourceCalcMethod,
                paymentRequestId: event.PRID,
                dollarAmount: event.Amount,
                style: 0
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              console.log(`Dialog result: ${result}`);
              this.search(this.currentPageIndex);
            });
          });
        }
      }
    );
  }

  /**
   * Re-formats the Date format to YYYY-MMM-DD format
   * @param date - The date object to convert
   * @returns The correctly formatted Date as a String
   */
  convertDateFormat(date) {
    const newDate = date.year + "-" + date.month + "-" + date.day;
    return newDate;
  }

  validateFacilityIDs() {
    this.listOfInvalidFacilities = [];
    let postIsValid: boolean = true;
    for(var i = 0; i < this.dataSource.data.length; i++) {
      let facilityID = this.dataSource.data[i].FacilityFinanceSystemID;
      if(facilityID == undefined || facilityID == null || facilityID == "") {
        postIsValid = false;
        this.listOfInvalidFacilities.push(this.dataSource.data[i].Facility);
      }
    }
    this.listOfInvalidFacilities = this.listOfInvalidFacilities.filter((x, i, a) => a.indexOf(x) == i)
    return postIsValid;
  }

  postedPRs() {
    let approvedFlag = false;
    for (let i = 0; i < this.paymentRequestForm.value.status.length; i++) {
      if (
        this.paymentRequestForm.value.status[i] == 6 ||
        this.paymentRequestForm.value.status[i] == 101 ||
        this.paymentRequestForm.value.status[i] == 102
      ) {
        approvedFlag = true;
      } else {
        approvedFlag = false;
      }
    }
    if (approvedFlag) {
      if(this.validateFacilityIDs() == false) {
        let facilityList: string = "";
        for(var i = 0; i < this.listOfInvalidFacilities.length; i++) {
          if(i + 1 < this.listOfInvalidFacilities.length) {
            facilityList = facilityList + this.listOfInvalidFacilities[i] + ", ";
          } else {
            facilityList = facilityList + this.listOfInvalidFacilities[i];
          }
        }

        MessageBox.ShowError(
          this.dialog,
          "The following facilities have no Finance System ID: " + facilityList.bold() + ". PRs cannot be posted without this ID as this field is critical data for Xero. Please try again once the data is valid.");
        return;
      }
      let postingData: RawVisitParameters = new RawVisitParameters();
      postingData.FromDate = this.paymentRequestData.PaymentDateFrm;
      postingData.ToDate = this.paymentRequestData.PaymentDateTo;
      postingData.FacilityName= this.paymentRequestData.Facility==''? '':this.paymentRequestData.Facility;//search all
      postingData.ModifiedBy = this.cmsrv.GetUser();
      postingData.CountryName = this.seletedCountry;
      console.log(postingData);
      
      const headerString = "Post Approved PRs Confirmation";
      const bodyString =
        "All approved PRs in the tabulated list are to be posted. " +
        "All files required for Xero will subsequently be downloaded." +
        " Do you wish to continue?";
      MessageBox.ShowCancelContinue(
        this.dialog,
        headerString,
        bodyString
      ).subscribe(dialogResult => {
        if (dialogResult.result.toLowerCase() === DialogResult.Continue) {
          this.isLoading = true;
          this.Invoke(
            this.PaymentSvc.getPostedPaymentRequests(postingData),
            (result: DataResult<PostedPaymentRequests[]>) => {
              if (result.Data != undefined && result.Data.length == 0) {
                console.log("No Data was returned for this Query");
                this.isLoading = false;
                MessageBox.ShowError(
                  this.dialog,
                  "Posting Payment Requests has failed, please try again.");
                return;
              }
              console.log(result);
              this.csvFile = result.Data;
              let todayDate = new Date().toISOString().slice(0, 10);
              if(this.seletedCountry === CommonConstants.GET_COUNTRIES.find(a => a.CountryId == 2).Name){
                this.fileName = todayDate + "NZXeroInput.csv";//set file name
              }else{
              this.fileName = todayDate + "XeroInput.csv";
              }

              const csvData = this.objectToCsv(this.csvFile);
              console.log("CSV Data: ", this.csvFile);
              this.isLoading = false;
              this.download(csvData, this.fileName);
              this.search(0);
            },
            () => {
              this.isLoading = false;
              MessageBox.ShowError(
                this.dialog,
                "Posting Payment Requests has failed, please try again.");
            }
          );
        }
      });
    }
  }

  regeneratePRs() {
    let approvedFlag = false;
    for (let i = 0; i < this.paymentRequestForm.value.status.length; i++) {
      if (
        this.paymentRequestForm.value.status[i] == 8 ||
        this.paymentRequestForm.value.status[i] == 101 ||
        this.paymentRequestForm.value.status[i] == 102
      ) {
        approvedFlag = true;
      } else {
        approvedFlag = false;
      }
    }
    if (approvedFlag) {
      let postingData: RawVisitParameters = new RawVisitParameters();
      postingData.FromDate = this.paymentRequestData.PaymentDateFrm;
      postingData.ToDate = this.paymentRequestData.PaymentDateTo;
      postingData.FacilityName = this.paymentRequestData.Facility==''? '%':this.paymentRequestData.Facility;//set all search
      postingData.ModifiedBy = this.cmsrv.GetUser();
      postingData.CountryName = this.seletedCountry;
      console.log(postingData);
      this.isLoading = true;
      this.Invoke(
        this.PaymentSvc.getRegeneratedPaymentRequests(postingData),
        (result: DataResult<PostedPaymentRequests[]>) => {
          console.log(result);

          if (result.Data != undefined && result.Data.length == 0) {
            console.log("No Data was returned for this Query");
            this.isLoading = false;
            MessageBox.ShowError(
              this.dialog,
              "Regenerating Payment Requests has failed, please try again.");
            return;
          }

          this.csvFile = result.Data;
          let todayDate = new Date().toISOString().slice(0, 10);          
          if(this.seletedCountry === CommonConstants.GET_COUNTRIES.find(a => a.CountryId == 2).Name){
            this.fileName = todayDate + "NZXeroInput.csv";// set file name
          }else{
          this.fileName = todayDate + "XeroInput.csv";
          }
          const csvData = this.objectToCsv(this.csvFile);
          console.log("CSV Data: ", this.csvFile);
          this.isLoading = false;
          this.download(csvData, this.fileName);
          this.search(0);
        },
        () => {
          this.isLoading = false;
          MessageBox.ShowError(
          this.dialog,
          "Regenerating Payment Requests has failed, please try again.");
        }
      );
    }
  }

  objectToCsv(data) {
    const csvRows = [];

    // get the headers
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));

    // loop over the rows (add 1 to a counter which would be 'Rows Affected'
    for (const row of data) {
      const values = headers.map(header => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  }

  download(data, csvFileName) {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", csvFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  /**
   * Triggered on a new DateFrom Selected.
   * If the DateTo is Null, and the first date of a month is selected,
   * sets the DateTo to be the last date of that Month.
   * Then triggers the Search Function.
   */
  public onDateFromSelected(): void {
    if (
      this.paymentRequestForm.value.paymentDateFrmForm.day == 1 &&
      this.paymentRequestForm.value.paymentDateToForm == ""
    ) {
      var year = this.paymentRequestForm.value.paymentDateFrmForm.year;
      var month = this.paymentRequestForm.value.paymentDateFrmForm.month;
      this.paymentRequestForm.controls.paymentDateToForm.setValue({
        year: year,
        month: month,
        day: Utils.getLastDateOfMonthFromInts(year, month)
      });
    }

    this.search(0);
  }

  /**
   * Triggered when the DateTo value is selected.
   * Triggers the Search Function for Payment Requests.
   */
  public onDateToSelected(): void {
    this.search(0);
  }

  // commit search when "enter" key is pressed
  enterSearch(event: KeyboardEvent) {
    if (event.keyCode === 13
      && this.paymentRequestForm.value.paymentDateFrmForm.replace(/\s/g, "") !== ""
      && this.paymentRequestForm.value.paymentDateToForm.replace(/\s/g, "") !== "") {
      this.search(0);
    }
  }  

  paymentRequestReject(paymentRequestDetails: PaymentRequestModel) {
    this.isLoading = true;
    this.Invoke(
      this.PaymentSvc.paymentRequestReject(paymentRequestDetails),
      (data: ResultModel) => {
        this.messageLOA = data.Message.slice(1); //(1) to omit the redundant PRStatus code that comes with message
        this.isLoading = false;
        MessageBox.ShowInfo(this.dialog, this.messageLOA);
        this.search(this.currentPageIndex);
      }
    );
  }

  rejectButton(event) {
    const headerString = "Reject Payment Request";
    const bodyString =
      "This <b>approved</b> Payment Request will be rejected. However, the recorded visits will not be removed from the Raw Visit History Table. This operation is irreversible. Do you wish to proceed?";
    MessageBox.ShowCancelContinue(
      this.dialog,
      headerString,
      bodyString
    ).subscribe(dialogResult => {
      if (dialogResult.result.toLowerCase() === DialogResult.Continue) {
        this.recordsCurrent.PID = event.PRID;
        this.recordsCurrent.Username = this.cmsrv.GetUser();
        this.recordsCurrent.Amount = event.Amount;
        this.paymentRequestReject(this.recordsCurrent);
      }
    });
  }

  downloadAttachements(event) {
    console.log("Event of download: ", event);
    var allUuidsAppended: string[] = [];
    if (event.InvoiceAttachment != "") {
      allUuidsAppended.push('"' + event.InvoiceAttachment + '"'); //There will always be just one UUID for invoice
    }

    var additionalAttachments = event.AdditionalAttachement.split(","); //Double splits to split the joined UUIDs e.g case- "file1,file2","file3"
    for (let i = 0; i < additionalAttachments.length; i++) {
      if (additionalAttachments[i] != "") {
        var singleUuid = '"' + additionalAttachments[i] + '"';
        allUuidsAppended.push(singleUuid);
      }
    }
    this.paymentRequestData.Uuids = allUuidsAppended;
    this.Invoke(
      this.PaymentSvc.getPreSignedURL(this.paymentRequestData),
      (data: ResultModel) => {
        for (let i = 0; i < allUuidsAppended.length; i++) {
          const fileLocation = data["Data"][i];
          window.open(fileLocation, "_blank");
        }
      }
    );
  }

  smallWidth(): boolean {
    return window.innerWidth < 1150;
  }

  addManualPrStyle() {
    if (this.smallWidth()) {
      return { float: "left" };
    } else {
      return { float: "right" };
    }
  }

  standardButtonsStyle() {
    if (this.smallWidth()) {
      return { float: "left" };
    } else {
      return { float: "left", "margin-left": "9.8em" };
    }
  }

  buttonsDivStyle() {
    if (this.smallWidth()) {
      return { height: "14em" };
    } else {
      return { height: "10em", "margin-top": "2em" };
    }
  }

  labelStyle() {
    return { width: "9em" };
  }
}
