<div [formGroup]="form.get('FacilityContract')">
  <ngb-accordion activeIds="panelCDetails">
    <ngb-panel id="panelCDetails">
      <ng-template ngbPanelTitle>
        Contract Details
        <i class="icon-fp fas fa-check"></i>
        <i class="fas fas fa-chevron-down"></i>
        <i class="fas fas fa-chevron-up"></i>
      </ng-template>
      <ng-template ngbPanelContent>
        <!-- <div class="form-group row">
              <label for="txtDateStarted" class="col-md-3 col-lg-2 col-form-label">Date Started</label>
              <div class="col-md-8 col-lg-5">
                  <div class="d-flex flex-column w-100">
                      <input type="text" class="form-control" id="txtDateStarted" readonly>
                  </div>
              </div>
          </div> -->
        <div class="form-group row">
          <label for="txtCSDate" class="col-md-3 col-lg-2 col-form-label">Contract
            <br>Start Date</label>
          <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
              <div class="input-group" [ngClass]="getClassFocused(1)" [class.is-invalid]="startDateCtrl.invalid"
                [class.disabled]="startDateCtrl.disabled">
                <input class="form-control" placeholder="DD-MM-YYYY" formControlName="ContractStartDate"
                  name="dpStartDate" ngbDatepicker #dpStartDate="ngbDatepicker"
                  [minDate]="{day: 01, month: 01, year: 1900}" (blur)="onBlurParent(0)" (focus)="onFocusParent(1)">
                <div class="input-group-append">
                  <button class="btn bg-white" (click)="dpStartDate.toggle()" type="button"><i
                      class="far fa-calendar-alt"></i></button>
                </div>
              </div>
              <small *ngIf="startDateCtrl.getError('ngbDate'); else requiredBeforeStartDate"
                class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_INVALID, 'Contract Start Date')}}
              </small>
              <ng-template #requiredBeforeStartDate>
                <small *ngIf="startDateCtrl.getError('rangedateinvalid')" class="invalid-feedback d-block">
                  The Contract Start Date should be less than the Contract End Date
                </small>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="txtCSDate" class="col-md-3 col-lg-2 col-form-label">Contract <br>End Date</label>
          <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
              <div class="input-group" [class.is-invalid]="endDateCtrl.invalid" [ngClass]="getClassFocused(2)"
                [class.disabled]="endDateCtrl.disabled">
                <input class="form-control" placeholder="DD-MM-YYYY" name="dpEndDate" ngbDatepicker
                  #dpEndDate="ngbDatepicker" (blur)="onBlurParent(0)" (focus)="onFocusParent(2)"
                  formControlName="ContractEndDate">
                <div class="input-group-append">
                  <button class="btn bg-white" (click)="dpEndDate.toggle()" type="button"><i
                      class="far fa-calendar-alt"></i></button>
                </div>
              </div>
              <small *ngIf="endDateCtrl.getError('ngbDate'); else requiredBefore" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_INVALID, 'Contract End Date')}}
              </small>
              <ng-template #requiredBefore>
                <small *ngIf="endDateCtrl.getError('rangedateinvalid')" class="invalid-feedback d-block">
                  The Contract End Date should be greater than the Contract Start Date
                </small>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="txtCDuration" class="col-md-3 col-lg-2 col-form-label">Contract
            Duration (Years)</label>
          <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
              <input type="text" [class.is-invalid]="durationCtrl.invalid" class="form-control"
                formControlName="ContractDuration" id="txtCDuration"
                [readOnly]="(startDateCtrl.value || endDateCtrl.value)" maxlength="2" numberOnly>
              <small *ngIf="durationCtrl.getError('max')" class="invalid-feedback d-block">
                Contract Duration must be less than 100
              </small>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="txtCSDate" class="col-md-3 col-lg-2 col-form-label">Signed Contract</label>
          <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
              <app-fp-file-upload [filterFile]="'application/pdf'" #signedContract
                (UploadCompleted)="SignedContract_UploadCompleted($event)" (fileSelected)="_fileSelected($event)"
                [isReadonly]="signedCtrl.disabled">
              </app-fp-file-upload>
              <span class="col-form-label" *ngIf="!signedCtrl.value">Upload PDF</span>
              <div class="slctRoles border-2 rounded-10 mt-3 border-default" *ngIf="signedCtrl.value">
                <div class="d-flex border-default border-bottom py-1 justify-content-between">
                  <a class="d-flex align-items-center m-0" target="_blank"
                    [href]="signedCtrl.value">{{ fileNameCtrl.value }}</a>
                  <span class="text-primary d-flex align-items-center" (click)="removeSignedContract()"
                    *ngIf="!isReadonly"><i class="fas fa-minus-circle fa-2x"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>