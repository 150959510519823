<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Contract Duration</h4>
<div class="mt-lg-4">
    <div class="form-group row">
        <label for="txtMinCDuration" class="col-md-3 col-lg-2 col-form-label">Minimum Contract
            Duration</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control"  id="txtMinCDuration" autocomplete="off" disabled
                    value="12 months">
            </div>
        </div>
    </div>
    <!-- <div class="form-group row">
        <label for="txtContractSD" class="col-md-3 col-lg-2 col-form-label">Contract Start
            Date</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="txtContractSD" formControlName="StartDate" ngbDatepicker
                    readonly>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtContractED" class="col-md-3 col-lg-2 col-form-label">Contract End
            Date</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="txtContractED" formControlName="EndDate" ngbDatepicker
                    readonly>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtViewTC" class="col-md-3 col-lg-2 col-form-label">View Terms &
            Conditions</label>
        <div class="col-md-6 col-lg-3">
            <div class="d-flex flex-column w-100">
                <a class="px-2 py-2 btn btn-primary text-transform-none px-lg-5 mr-3"
                    href="https://www.fitnesspassport.com.au/termsandconditions" target="_blank">View T & C</a>
            </div>
        </div>
    </div> -->
    <app-member-contract-details #contractDetails></app-member-contract-details>
    <div class="form-group row">
        <label for="txtViewTC" class="col-md-3 col-lg-2 col-form-label">View Terms &
            Conditions</label>
        <div class="col-md-6 col-lg-3">
            <div class="d-flex flex-column w-100">
                <a class="px-2 py-2 btn btn-primary text-transform-none px-lg-5 mr-3"
                    href="https://www.fitnesspassport.com.au/termsandconditions" target="_blank">View T & C</a>
            </div>
        </div>
    </div>
</div>