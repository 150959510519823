<div [formGroup]="form">
    <div class="form-group row">
        <label for="txtEmployerName" class="col-md-3 col-lg-2 col-form-label">Employer Name<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="txtEmployerName" formControlName="Name" autocomplete="off" maxlength="200"
                    [fpFormControlValidation]="{controlLabel: 'Employer Name'}" restrictedPattern="^[A-Za-z]{0,4}$">
                <mat-spinner [diameter]="14" color="primary" *ngIf="getControl('Name').pending">
                </mat-spinner>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtAlternateNameTags" class="col-md-3 col-lg-2 col-form-label">Alternate Name Tags</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" id="txtAlternateNameTags" formControlName="AlternateNameTags" maxlength="300" class="form-control"
                    autocomplete="off">
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtParentGroup" class="col-md-3 col-lg-2 col-form-label">Parent Group</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" id="txtParentGroup" class="form-control" name="txtParentGroup"
                    placeholder="Search Parent Group by Employer name" matInput [matAutocomplete]="autoParentGroup"
                    [formControl]="ParentGroupCtrl" (blur)="txtParentGroup_Blur($event)" (focus)="txtParentGroup_Focus($event)"
                    [fpFormControlValidation]="{errorTemplates: {'unresolved': 'The {0} does not exist'}}">
                <mat-spinner [diameter]="14" color="primary" *ngIf="ParentGroupCtrl.pending">
                </mat-spinner>
                <mat-autocomplete #autoParentGroup="matAutocomplete" [displayWith]="autoComplete_DisplayWithFn"
                    (optionSelected)="autoParentGroup_OptionSelected($event)" autoActiveFirstOption>
                    <mat-option *ngFor="let group of _parentGroups" class="border-default border-bottom py-1" [value]="group">
                        <p class="m-0 d-flex align-items-center">{{group.Name}}</p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlIndustrySector" class="col-md-3 col-lg-2 col-form-label">Industry Sector</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select id="ddlIndustrySector" class="form-control " formControlName="IndustrySectorId">
                    <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let sector of _industrySectors" [ngValue]="sector.Id">
                        {{sector.Name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtAccountManager" class="col-md-3 col-lg-2 col-form-label">Account Manager<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" id="txtAccountManager" name="txtAccountManager" class="form-control"
                    placeholder="Search Account Manager by username, first name" matInput [matAutocomplete]="autoAccountManager"
                    [formControl]="AccountManagerCtrl" (blur)="txtAccountManager_Blur($event)" (focus)="txtAccountManager_Focus($event)"
                    [fpFormControlValidation]="{controlLabel: 'Account Manager', errorTemplates: {'unresolved': 'The {0} does not exist'}}">
                <mat-spinner [diameter]="14" color="primary" *ngIf="AccountManagerCtrl.pending"></mat-spinner>
                <mat-autocomplete #autoAccountManager="matAutocomplete" [displayWith]="autoComplete_DisplayWithFn"
                    (optionSelected)="autoAccountManager_OptionSelected($event)" autoActiveFirstOption>
                    <mat-option *ngFor="let manager of _accountManagers" class="border-default border-bottom py-1" [value]="manager">
                        <p class="m-0 d-flex align-items-center">{{manager.Name}} </p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtBusinessDevManager" class="col-md-3 col-lg-2 col-form-label">Business
            Development Manager<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" id="txtBusinessDevManager" name="txtBusinessDevManager" class="form-control"
                    placeholder="Search Business Development Manager by username, first name" matInput
                    [matAutocomplete]="autoBusinessDevManager" [formControl]="BusinessDevManagerCtrl"
                    (blur)="txtBusinessDevManager_Blur($event)" (focus)="txtBusinessDevManager_Focus($event)"
                    [fpFormControlValidation]="{controlLabel: 'Business Development Manager', errorTemplates: {'unresolved': 'The {0} does not exist'}}">
                <mat-spinner [diameter]="14" color="primary" *ngIf="BusinessDevManagerCtrl.pending">
                </mat-spinner>
                <mat-autocomplete #autoBusinessDevManager="matAutocomplete" [displayWith]="autoComplete_DisplayWithFn"
                    (optionSelected)="autoBusinessDevManager_OptionSelected($event)" autoActiveFirstOption>
                    <mat-option *ngFor="let manager of _businessDevManagers" class="border-default border-bottom py-1" [value]="manager">
                        <p class="m-0 d-flex align-items-center"> {{manager.Name}} </p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlEmployerType" class="col-md-3 col-lg-2 col-form-label">Employer Type<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select id="ddlEmployerType" class="form-control" formControlName="EmployerTypeId"
                    [fpFormControlValidation]="{controlLabel: 'Employer Type', options: {onTouched: true}}">
                    <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let type of _employerTypes" [ngValue]="type.Id">
                        {{type.Name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>