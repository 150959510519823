import { GenerateReportFPComponent } from "./components/reporting/generate-report-FP/generate-report-FP.component";
import { GenerateReportsEFFSMComponent } from "./components/reporting/generate-reports-EF-FSM/generate-reports-EF-FSM.component";
import { WarmupAPIService } from "./services/warmup-api.service";
import { DatePipe } from "@angular/common";
import { PagenotfoundComponent } from "./components/error-handler-component/pagenotfound/pagenotfound.component";
import { BrowserModule, Title } from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LightboxModule } from "ngx-lightbox";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { TableModule } from "primeng/table";
import {
  FormsModule,
  ReactiveFormsModule,
  NgForm,
} from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./components/navbar-component/navbar.component";
import { FooterComponent } from "./components/footer-component/footer.component";
import { HomeComponent } from "./components/home-component/home.component";
import { AppRoutingModule } from "./app-routing.module";

import { Router } from "@angular/router";

import { AboutComponent } from "./components/about-component/about.component";
import { EmployerComponent } from "./components/employer-components/employer/employer.component";
import { VieweditemployerComponent } from "./components/employer-components/vieweditemployer/vieweditemployer.component";
import { AddemployerComponent } from "./components/employer-components/addemployer/addemployer.component";
import { EmployerDetailsNewComponent } from "./components/employer-components/employer-details/employer-details.component";
import { LoginComponent } from "./components/login-component/login.component";
import { SignupComponent } from "./components/signup-component/signup/signup.component";
import { UserRegisterComponent } from "./components/signup-component/user-register/user-register.component";
import { EmployerportalComponent } from "./components/employer-components/employerportal/employerportal.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MatSelectModule } from "@angular/material/select";
import { CommonService } from "./services/common.service";
import { RolePermissionService } from "./services/role-permission.service";

import { CreatenewregionComponent } from "./components/region-components/createnewregion/createnewregion.component";
import { VieweditregionsComponent } from "./components/region-components/vieweditregions/vieweditregions.component";
import { SearchregionsComponent } from "./components/region-components/searchregions/searchregions.component";
import { EditregionsComponent } from "./components/region-components/editregions/editregions.component";

import { DashboardComponent } from "./components/dashboard-component/dashboard.component";
import { NoPermissionComponent } from "./components/error-handler-component/no-permission/no-permission.component";

import { AddusersComponent } from "./components/user-components/addusers/addusers.component";
import { EditusersComponent } from "./components/user-components/editusers/editusers.component";
import { ViewusersComponent } from "./components/user-components/viewusers/viewusers.component";
import { SearchusersComponent } from "./components/user-components/searchusers/searchusers.component";

import { ViewproductsservicesComponent } from "./components/productservice-components/viewproductsservices/viewproductsservices.component";
// tslint:disable-next-line:max-line-length
import { AddeditproductsservicesComponent } from "./components/productservice-components/addeditproductsservices/addeditproductsservices.component";

import { ViewparentgroupComponent } from "./components/parentgroup-components/viewparentgroup/viewparentgroup.component";
import { AddeditparentgroupsComponent } from "./components/parentgroup-components/addeditparentgroup/addeditparentgroup.component";

import { MatSnackBarModule } from "@angular/material/snack-bar";
import { PreviewemployerComponent } from "./components/employer-components/previewemployer/previewemployer.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatToolbarModule } from "@angular/material/toolbar";
import { CommonDialogComponent } from "./components/common-dialog/common-dialog.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";

import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateFRParserFormatter } from "./helpers/ngb-date-fr-parser-formatter";
import { FacilitiesComponent } from "./components/facility-components/facilities/facilities.component";
import { SearchfacilityComponent } from "./components/facility-components/searchfacility/searchfacility.component";
import { VieweditfacilitiesComponent } from "./components/facility-components/vieweditfacilities/vieweditfacilities.component";
import { DraftfacilityComponent } from "./components/facility-components/draftfacility/draftfacility.component";
import { SearchemployerComponent } from "./components/employer-components/searchemployer/searchemployer.component";
import { DraftemployerComponent } from "./components/employer-components/draftemployer/draftemployer.component";
import { CompleteaddusersComponent } from "./components/user-components/completeaddusers/completeaddusers.component";
import { HeaderComponent } from "./components/header-components/header.component";

import { NgxCaptchaModule } from "ngx-captcha";

import { AngularCropperjsModule } from "angular-cropperjs";

import {
  AddFacilityComponent,
  FacilityDetailsComponent,
  FacilityIdentificationDetailsComponent,
  FacilityImportantInformationComponent,
  FacilityAddressDetailsComponent,
  FacilityContactDetailsComponent,
  FacilityPaymentComponent,
  FacilityPaymentDetailsComponent,
  FacilityBankAccountDetailsComponent,
  FacilityContractComponent,
  FacilityElectronicVisitCaptureComponent,
  FacilityJournalAndAuditLogComponent,
  FacilityVisitsPaymentComponent,
  FacilityProductsServicesComponent,
  FacilityProductsListComponent,
  FacilityServicesListComponent,
  FacilityProductDetailsDialogComponent,
  FacilityProductDetailsComponent,
  FacilityServiceDetailsDialogComponent,
  FacilityServiceDetailsComponent,
  FacilityEmployerAccessComponent,
  FacilityAdditionalAttachmentsComponent,
  FacilityBpayDetailsComponent,
  FacilityChequeDetailsComponent,
} from "./components/facility-components";
import { UserIdleModule } from "angular-user-idle";
import { APIConstant } from "./constant";
import { EdituserdetailsComponent } from "./components/user-components/edituserdetails/edituserdetails.component";
import { EnduserscreenComponent } from "./components/user-components/enduserscreen/enduserscreen.component";
import { JwtModule } from "@auth0/angular-jwt";
import * as CryptoJS from "crypto-js";
import { environment } from "src/environments/environment";
export function jwtTokenGetter() {
  if (localStorage.getItem("accessToken") == null) {
    return null;
  }
  try {
    const decrypted = CryptoJS.AES.decrypt(
      localStorage.getItem("accessToken"),
      environment.NCKXXL
    ).toString(CryptoJS.enc.Utf8);
    return decrypted;
  } catch (e) {
    return null;
  }
}

import { UserrolesComponent } from "./components/user-components/userroles/userroles.component";
import { ChangepasswordComponent } from "./components/user-components/changepassword/changepassword.component";
import { LocationService } from './services/location.service';
import {
  MemberEditComponent,
  MemberSignupComponent,
  MemberEmployerInfoComponent,
  MemberPersonalInfoComponent,
  MemberPersonalDetailsComponent,
  MemberAddressContactDetailsComponent,
  MemberEmergencyContactComponent,
  MemberMedicalQuestionsComponent,
  MemberPreferredFacilitiesComponent,
  MemberFamilyMemberInfoComponent,
  MemberFamilyMemberDetailsComponent,
  MemberFamilyMemberContactDetailsComponent,
  MemberPaymentDetailsComponent,
  MemberMembershipPackagesComponent,
  MemberMembershipPackageCostSummaryComponent,
  MemberBankAccountComponent,
  MemberFamilyMembersComponent,
  MemberShoppingCartComponent,
  MemberFamilyMemberDetailsDialogComponent,
  MemberPrimaryPersonalDetailsDialogComponent,
  AdditionalMemberPersonalDetailsDialogComponent,
  MemberCancellationComponent,
  MemberMembershipCardsComponent,
  MemberVisitsPaymentsComponent,
  MemberChangeMembershipPackageComponent,
  MemberContractDetailsComponent,
  MemberAdditionalNotesComponent,
  MemberContractsComponent,
  MemberPaymentsHistoryReceiptsComponent,
  MemberUpdatePaymentsDetailsComponent,
} from "./components/members";
import { EndmembersignupComponent } from "./components/signup-component/endmembersignup/endmembersignup.component";
import { TextboxComponent } from "./components/user-components/common/components/textbox/textbox.component";
import { SelectComponent } from "./components/user-components/common/components/select/select.component";
import { MembersVerificationComponent } from "./components/members/members-verification/members-verification.component";
import {
  AddPackageComponent,
  PackageDetailsComponent,
  PackageServiceListComponent,
} from "./components/package-components";
import { SearchpackageComponent as SearchPackageComponent } from "./components/package-components/searchpackage/searchpackage.component";
import { ViewpackagesComponent as ViewPackagesComponent } from "./components/package-components/viewpackages/viewpackages.component";
import { CheckboxModule } from "primeng/checkbox";
import { TermsConditionsComponent } from "./components/signup-component/terms-conditions/terms-conditions.component";
import { MobileNumberComponent } from "./components/control/mobile-number/mobile-number.component";
import { FpFileUploadComponent } from "./components/control/fp-file-upload/fp-file-upload.component";
import { FpFileUploadR2Component } from "./components/control/fp-file-upload-r2/fp-file-upload-r2.component";
import { PackageComponent } from "./components/package-components/package/package.component";
import { PhoneNumberComponent } from "./components/control/phone-number/phone-number.component";
import { MemberDetailsComponent } from "./components/members/member-details/member-details.component";
import { MembershipCardsComponent } from "./components/members/membership-cards/membership-cards.component";
import { CustomerSupportComponent } from "./components/members/customer-support/customer-support.component";
import { EmployerTransferComponent } from "./components/members/employer-transfer/employer-transfer.component";
import { EtCompleteComponent } from "./components/members/employer-transfer/et-complete/et-complete.component";

import { CurrencyMaskModule } from "./directives/currentMask/currency-mask.module";
import { CurrencyTestModule } from "./directives/currency-new-format/currency.module";
import { MemberssearchComponent } from "./components/members/memberssearch/memberssearch.component";
import { MembersviewComponent } from "./components/members/membersview/membersview.component";
import { DirectivesModule } from './directives/directives.module';
import { SuspendMembershipComponent } from './components/members/suspend-membership/suspend-membership.component';
import { FacilitatorFacilityDetailsComponent } from './components/facilitator-components/facilitator-facility-details/facilitator-facility-details.component';
import { FacilitatorFacilityMembersComponent } from './components/facilitator-components/facilitator-facility-members/facilitator-facility-members.component';

// Angular Map Modules
import { AgmCoreModule } from "@agm/core";
import { AgmSnazzyInfoWindowModule } from "@agm/snazzy-info-window";
import {
  AgmJsMarkerClustererModule,
} from "@agm/js-marker-clusterer";
import { FacilityGoogleMapComponent } from "./components/members/shared/facitily-google-map/facitily-google-map.component";
import { EmployerMembershipPackageComponent } from "./components/employer-components/addemployer/membership-package/membership-package.component";
import { EmployerAdditionalAttachmentsComponent } from "./components/employer-components/addemployer/additional-attachments/additional-attachments.component";
import { EmployerBranchsComponent } from "./components/employer-components/addemployer/branchs/branchs.component";
import { ServicesScannerDetailsComponent } from "./components/facility-components/addfacility/products-services/services-list/services-scanner-details/services-scanner-details.component";
import { EmployerPageComponent } from "./components/employer-components/addemployer/page/page.component";
import { FpCsvImportComponent } from "./components/control/fp-csv-import/fp-csv-import.component";
import { MembershipUploadPhotoComponent } from "./components/members/membership-cards/membership-upload-photo/membership-upload-photo.component";
import {
  CustomMessageBoxDialog,
  Logger,
  INFO_LOGGER_PROVIDER,
} from "@fp/helpers";
import {
  EmployerPackagePriceUpdatesComponent,
  EmployerMembershipPackageExporterComponent,
  EmployerDetailsComponent,
  EmployerAddressContactDetailsComponent,
  EmployerCodeComponent,
  EmployerAdditionalInformationComponent,
  EmployerNotesComponent,
  EmployerDetailsFormComponent,
  EmployerContractComponent,
  EmployerContractsFormComponent,
} from "@fp/components/employer-components";
import { ManuallyUploadComponent } from "./components/finance-components/manually-upload/manually-upload.component";
import { ViewPaymentRequestsComponent } from "./components/finance-components/view-payment-requests/view-payment-requests.component";
import { ApprovePaymentRequestComponent } from "./components/finance-components/view-payment-requests/approve-payment-request/approve-payment-request.component";
import { CreatePaymentRequestComponent } from "./components/finance-components/view-payment-requests/create-payment-request/create-payment-request.component";
import { AuditLogComponent } from "./components/audit-log/audit-log.component";
import { SystemParametersComponent } from "./components/finance-components/system-parameters/system-parameters.component";
import { AddManualVisitsComponent } from "./components/finance-components/view-payment-requests/create-payment-request/add-manual-visits/add-manual-visits.component";
import { PaymentRequestDetailsService } from "./services/payment-request-details.service";
import { FileNameService } from "./services/file-name.service";
import { ManuallyAddDebitCreditBoxComponent } from "./components/members/memberedit/visits-payments/manually-add-debit-credit-box/manually-add-debit-credit-box.component";
import { TaskListComponent } from "./components/finance-components/task-list/task-list.component";
import { EditPaymentRequestComponent } from "./components/finance-components/view-payment-requests/edit-payment-request/edit-payment-request.component";
import { EditManualVisitsComponent } from "./components/finance-components/view-payment-requests/edit-payment-request/edit-manual-visits/edit-manual-visits.component";
import { ViewPaymentsAndInvoicesComponent } from "./components/finance-components/view-payments-and-invoices/view-payments-and-invoices.component";
import { DebitProcessingComponent } from "./components/finance-components/debit-processing/debit-processing.component";
import { DataService } from './services';
import { FacilityNotesComponent } from './components/facility-components/addfacility/facility-details/facility-notes/facility-notes.component';

import { FPFormControlErrorsTemplateComponent } from '@fp/components/control';
import { BulkUploadDebitCreditComponent } from './components/finance-components/bulk-upload-debit-credit/bulk-upload-debit-credit.component';
import { EditDebitProcessingComponent } from './components/finance-components/debit-processing/edit-debit-processing/edit-debit-processing.component';
import { MemberTestingComponent } from './components/testing/member-testing/member-testing.component';
import { dateFormatPipe } from './pipes/date-format-pipe.pipe';
import { dateTimeFormatPipe } from './pipes/date-time-format-pipe.pipe';
import { dateTimeWithoutSecondsPipe } from './pipes/date-time-without-seconds-pipe.pipe';
import { TrainingMaterialComponent } from './components/training-material/training-material.component';
import { GenerateReportsComponent } from './components/reporting';
import { FpProgressBarComponent } from './components/control/fp-progress-bar/fp-progress-bar.component';
import { MembernotificationsComponent } from './components/members/membernotifications/membernotifications.component';
import { PrNotesComponent } from './components/facility-components/addfacility/visits-payment/pr-notes/pr-notes.component';
import { BotLogsComponent } from './components/bot-logs/bot-logs.component';
import { timeFormatPipe } from './pipes/time-format-pipe.pipe';
import { DescribeStateMachineComponent } from './components/describe-state-machine/describe-state-machine.component';
import { DownloadPDFComponent } from './components/reporting/download-PDF/download-PDF.component';
import { MemberImageComponent } from './components/members/shared/member-image/member-image.component';
import { MobileNumberRegistorComponent } from './components/control/mobile-number-registor/mobile-number-registor.component';
import { CommonDialogNewComponent } from './components/common-dialog-new/common-dialog-new.component';
import { HeaderComponentNewComponent } from './components/header-component-new/header-component-new.component';
import { MembershipRegistrationNewComponent } from './components/membership-registration-new/membership-registration-new.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PersonalDetailsNewComponent } from './components/personal-details-new/personal-details-new.component';
import { MatRadioModule } from '@angular/material/radio';
import { HealthDisclaimerComponent } from './components/health-disclaimer/health-disclaimer.component';
import { SelectPackagePageComponentComponent } from './components/select-package-page-component/select-package-page-component.component';
import { AddFamilyMemberPopupComponent } from './add-family-member-popup/add-family-member-popup.component';
import { MedicalQuestionsComponentComponent } from './medical-questions-component/medical-questions-component.component';
import { PaymentDetailsComponent } from './components/payment-details/payment-details.component';
import { FillAllFieldsPopupComponent } from './fill-all-fields-popup/fill-all-fields-popup.component';
import { MapViewNewComponent } from './map-view-new/map-view-new.component';
import { FacilityGoogleMapNewComponent } from './facility-google-map-new/facility-google-map-new.component';
import { FilterSearchResultPopupComponent } from './filter-search-result-popup/filter-search-result-popup.component';
import { MapListViewNewComponent } from './map-list-view-new/map-list-view-new.component';
import { SearchInputMapComponent } from './search-input-map/search-input-map.component';
import { AddBankCodeComponentComponent } from './components/admin-component/add-bank-code-component/add-bank-code-component.component';
import { BankCodeHistoryComponentComponent } from './components/admin-component/add-bank-code-component/bank-code-history-component/bank-code-history-component.component';
import { AddBsbComponentComponent } from './components/admin-component/add-bsb-component/add-bsb-component.component';
import { BsbHistoryComponentComponent } from './components/admin-component/add-bsb-component/bsb-history-component/bsb-history-component.component';
import { ContinueMemberSignUpComponent } from './components/signup-component/continue-membersignup/continue-membersignup.component';
import { PackageCostSummaryComponent } from './components/signup-component/package-cost-summary/package-cost-summary.component';
import { FacilityActivitiesDetailsComponent } from './components/facility-components/addfacility/facility-details/facility-activities-details/facility-activities-details.component';
import { BulkUploadPostcodeComponent } from './components/admin-component/bulk-upload-postcode/bulk-upload-postcode.component';
import { BulkUploadPostcodeHistoryComponent } from './components/admin-component/bulk-upload-postcode/bulk-upload-postcode-history/bulk-upload-postcode-history.component';
import { GoogleMapPageListViewComponent } from './google-map-page-list-view/google-map-page-list-view.component';
import { GoogleMapPageViewComponent } from './google-map-page-view/google-map-page-view.component';
import { FacilityPackagesComonentComponent } from './components/facility-packages-comonent/facility-packages-comonent.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { MobileNumberCountryComponent } from './components/control/mobile-number-country/mobile-number-country.component';
import { EditTermsConditionsComponent } from './components/admin-component/edit-terms-conditions/edit-terms-conditions.component';
import { FaciliatorFacilityMembersCheckInComponent } from './components/facilitator-components/faciliator-facility-members-check-in/faciliator-facility-members-check-in.component';
import { TwoFactorSelectorComponent } from './components/login-component/two-factor-selector/two-factor-selector.component';
import { TwoFactorAuthenticationComponent } from './components/login-component/two-factor-selector/two-factor-authentiation/two-factor-authentiation.component';
import { MemberReactivationComponent } from './components/members/member-reactivation/member-reactivation/member-reactivation.component';
import { ContinueReactivationComponent } from './components/members/member-reactivation/continue-reactivation/continue-reactivation.component';
import { ReactivationEmployerDetailsComponent } from './components/members/member-reactivation/member-reactivation/reactivation-employer-details/reactivation-employer-details.component';
import { ReactivationMemberDetailsComponent } from './components/members/member-reactivation/member-reactivation/reactivation-member-details/reactivation-member-details.component';
import { FamilyMemberReactivationComponent } from './components/members/member-reactivation/member-reactivation/reactivation-member-details/family-member-reactivation/family-member-reactivation.component';
import { ReactivationMembershipPackageDetailsComponent } from './components/members/member-reactivation/member-reactivation/reactivation-membership-package-details/reactivation-membership-package-details.component';
import { ReactivationPaymentDetailsComponent } from './components/members/member-reactivation/member-reactivation/reactivation-payment-details/reactivation-payment-details.component';
import { ReactivationPackageSummaryComponent } from './components/members/member-reactivation/member-reactivation/reactivation-payment-details/reactivation-package-summary/reactivation-package-summary.component';
import { SecurityPreferencesComponent } from './components/members/security-preferences';
import { SecurityPreferencesUpdatePhoneComponent } from './components/members/security-preferences-update-phone/security-preferences-update-phone.component';
import { SurveyPageComponent } from './components/employer-components/addemployer/survey-page/survey-page.component';
import { SurveySignupComponent } from './components/signup-component/survey-signup/survey-signup.component';
import { PreSignupMemberSurveyComponent } from './components/survey-components/pre-signup-member-survey/pre-signup-member-survey.component';
import { ContinuePreSignupSurveyComponent } from './components/survey-components/continue-pre-signup-survey/continue-pre-signup-survey.component';
import { FacilityPickerComponent } from './components/survey-components/shared/facility-picker/facility-picker.component';
import { SuburbSelectComponent } from './components/survey-components/shared/suburb-select/suburb-select.component';
import { FacilitySingleSelect } from './components/survey-components/shared/facility-single-select/facility-single-select.component';
import { FamilyMembershipPickerComponent } from './components/survey-components/shared/family-membership-picker/family-membership-picker.component';
import { MultipleChoiceInputComponent } from './components/survey-components/shared/multiple-choice-input/multiple-choice-input.component';
import { TextInputComponent } from './components/survey-components/shared/text-input/text-input.component';

import * as Sentry from '@sentry/angular';
import { AmplifyAuthenticatorModule } from '@FitnessPassport/ui-angular';
import { ConfirmAccountComponent } from './components/signup-component/confirm-account/confirm-account.component';
import { ConfirmAccountCompletedComponent } from './components/signup-component/confirm-account/completed/completed.component';
import { AmplifyAuthenticatorAccountCreatedMessageComponent } from './components/amplify-authenticator/account-created-message/amplify-authenticator-account-created-message.component';
import { SignupMarketingDisclaimerComponent } from './components/signup-marketing-disclaimer-component/signup-marketing-disclaimer.component';
import { ConfigService } from './services/config.service';

function bootrapConfig(config: ConfigService) {
  return () => config.loadConfig().toPromise();
}

@NgModule({
  declarations: [
    AppComponent,
    dateFormatPipe,
    timeFormatPipe,
    dateTimeFormatPipe,
    dateTimeWithoutSecondsPipe,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    EmployerComponent,
    VieweditemployerComponent,
    AddemployerComponent,
    EmployerDetailsNewComponent,
    LoginComponent,
    SignupComponent,
    SignupMarketingDisclaimerComponent,
    EmployerportalComponent,
    CreatenewregionComponent,
    VieweditregionsComponent,
    DashboardComponent,
    AddusersComponent,
    EditusersComponent,
    ViewproductsservicesComponent,
    AddeditproductsservicesComponent,
    SearchregionsComponent,
    EditregionsComponent,
    ViewusersComponent,
    SearchusersComponent,
    PreviewemployerComponent,
    AddFacilityComponent,
    FacilityDetailsComponent,
    FacilityIdentificationDetailsComponent,
    FacilityImportantInformationComponent,
    FacilityAddressDetailsComponent,
    FacilityContactDetailsComponent,
    FacilityPaymentComponent,
    FacilityPaymentDetailsComponent,
    FacilityBankAccountDetailsComponent,
    FacilityContractComponent,
    FacilityElectronicVisitCaptureComponent,
    FacilityJournalAndAuditLogComponent,
    FacilityVisitsPaymentComponent,
    FacilityProductsServicesComponent,
    FacilityProductsListComponent,
    FacilityProductDetailsDialogComponent,
    FacilityProductDetailsComponent,
    FacilityServicesListComponent,
    FacilityServiceDetailsDialogComponent,
    FacilityServiceDetailsComponent,
    FacilityEmployerAccessComponent,
    FacilityAdditionalAttachmentsComponent,
    FacilityBpayDetailsComponent,
    FacilityChequeDetailsComponent,
    MemberEditComponent,
    MemberSignupComponent,
    MemberEmployerInfoComponent,
    MemberPersonalInfoComponent,
    MemberPersonalDetailsComponent,
    MemberAddressContactDetailsComponent,
    MemberEmergencyContactComponent,
    MemberMedicalQuestionsComponent,
    MemberPreferredFacilitiesComponent,
    MemberFamilyMembersComponent,
    MemberShoppingCartComponent,
    MemberPaymentDetailsComponent,
    MemberFamilyMemberDetailsDialogComponent,
    MemberFamilyMemberInfoComponent,
    MemberFamilyMemberDetailsComponent,
    MemberFamilyMemberContactDetailsComponent,
    MemberMembershipPackagesComponent,
    MemberMembershipPackageCostSummaryComponent,
    MemberBankAccountComponent,
    MemberMembershipCardsComponent,
    MemberVisitsPaymentsComponent,
    MemberContractDetailsComponent,
    MemberAdditionalNotesComponent,
    MemberContractsComponent,
    MemberPaymentsHistoryReceiptsComponent,
    MemberUpdatePaymentsDetailsComponent,
    SecurityPreferencesComponent,
    SecurityPreferencesUpdatePhoneComponent,
    PackageCostSummaryComponent,
    CommonDialogComponent,
    FacilitiesComponent,
    SearchfacilityComponent,
    VieweditfacilitiesComponent,
    DraftfacilityComponent,
    SearchemployerComponent,
    DraftemployerComponent,
    CompleteaddusersComponent,
    HeaderComponent,
    EdituserdetailsComponent,
    EnduserscreenComponent,
    UserrolesComponent,
    ChangepasswordComponent,
    EndmembersignupComponent,
    ContinueMemberSignUpComponent,
    TextboxComponent,
    SelectComponent,
    NoPermissionComponent,
    PagenotfoundComponent,
    MembersVerificationComponent,
    TermsConditionsComponent,
    MobileNumberComponent,
    FpFileUploadComponent,
    FpFileUploadR2Component,
    FpCsvImportComponent,
    AddPackageComponent,
    PackageDetailsComponent,
    PackageServiceListComponent,
    SearchPackageComponent,
    ViewPackagesComponent,
    TermsConditionsComponent,
    PackageComponent,
    PhoneNumberComponent,
    MemberDetailsComponent,
    MembershipCardsComponent,
    EmployerTransferComponent,
    CustomerSupportComponent,
    MemberPrimaryPersonalDetailsDialogComponent,
    AdditionalMemberPersonalDetailsDialogComponent,
    EtCompleteComponent,
    MemberssearchComponent,
    MembersviewComponent,
    SuspendMembershipComponent,
    MemberMembershipCardsComponent,
    MemberVisitsPaymentsComponent,
    MemberCancellationComponent,
    FacilitatorFacilityDetailsComponent,
    FacilitatorFacilityMembersComponent,
    FacilityGoogleMapComponent,
    EmployerMembershipPackageComponent,
    EmployerAdditionalAttachmentsComponent,
    EmployerBranchsComponent,
    ServicesScannerDetailsComponent,
    EmployerBranchsComponent,
    EmployerPageComponent,
    EmployerDetailsComponent,
    EmployerAddressContactDetailsComponent,
    EmployerCodeComponent,
    EmployerAdditionalInformationComponent,
    EmployerNotesComponent,
    EmployerDetailsFormComponent,
    EmployerContractComponent,
    EmployerContractsFormComponent,
    MemberChangeMembershipPackageComponent,
    MembershipUploadPhotoComponent,
    CustomMessageBoxDialog,
    EmployerPackagePriceUpdatesComponent,
    EmployerMembershipPackageExporterComponent,
    ManuallyUploadComponent,
    ViewPaymentRequestsComponent,
    ApprovePaymentRequestComponent,
    CreatePaymentRequestComponent,
    AuditLogComponent,
    SystemParametersComponent,
    AddManualVisitsComponent,
    ManuallyAddDebitCreditBoxComponent,
    TaskListComponent,
    EditPaymentRequestComponent,
    EditManualVisitsComponent,
    ViewPaymentsAndInvoicesComponent,
    DebitProcessingComponent,
    FPFormControlErrorsTemplateComponent,
    FacilityNotesComponent,
    BulkUploadDebitCreditComponent,
    EditDebitProcessingComponent,
    MemberTestingComponent,
    TrainingMaterialComponent,
    GenerateReportsComponent,
    GenerateReportsEFFSMComponent,
    GenerateReportFPComponent,
    FpProgressBarComponent,
    MembernotificationsComponent,
    PrNotesComponent,
    BotLogsComponent,
    DescribeStateMachineComponent,
    DownloadPDFComponent,
    MemberImageComponent,
    ViewparentgroupComponent,
    AddeditparentgroupsComponent,
    UserRegisterComponent,
    MobileNumberRegistorComponent,
    CommonDialogNewComponent,
    HeaderComponentNewComponent,
    MembershipRegistrationNewComponent,
    PersonalDetailsNewComponent,
    HealthDisclaimerComponent,
    SelectPackagePageComponentComponent,
    AddFamilyMemberPopupComponent,
    MedicalQuestionsComponentComponent,
    PaymentDetailsComponent,
    FillAllFieldsPopupComponent,
    MapViewNewComponent,
    FacilityGoogleMapNewComponent,
    FilterSearchResultPopupComponent,
    MapListViewNewComponent,
    SearchInputMapComponent,
    AddBankCodeComponentComponent,
    BankCodeHistoryComponentComponent,
    AddBsbComponentComponent,
    BsbHistoryComponentComponent,
    FacilityActivitiesDetailsComponent,
    BulkUploadPostcodeComponent,
    BulkUploadPostcodeHistoryComponent,
    GoogleMapPageListViewComponent,
    GoogleMapPageViewComponent,
    FacilityPackagesComonentComponent,
    MobileNumberCountryComponent,
    EditTermsConditionsComponent,
    FaciliatorFacilityMembersCheckInComponent,
    TwoFactorSelectorComponent,
    TwoFactorAuthenticationComponent,
    MemberReactivationComponent,
    ContinueReactivationComponent,
    ReactivationEmployerDetailsComponent,
    ReactivationMemberDetailsComponent,
    FamilyMemberReactivationComponent,
    ReactivationMembershipPackageDetailsComponent,
    ReactivationPaymentDetailsComponent,
    ReactivationPackageSummaryComponent,
    SurveyPageComponent,
    SurveySignupComponent,
    PreSignupMemberSurveyComponent,
    ContinuePreSignupSurveyComponent,
    FacilityPickerComponent,
    SuburbSelectComponent,
    FacilitySingleSelect,
    FamilyMembershipPickerComponent,
    MultipleChoiceInputComponent,
    TextInputComponent,
    ConfirmAccountComponent,
    ConfirmAccountCompletedComponent,
    AmplifyAuthenticatorAccountCreatedMessageComponent,
  ],
  imports: [
    AmplifyAuthenticatorModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    TooltipModule,
    TableModule,
    BrowserAnimationsModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatSidenavModule,
    MatMenuModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatDialogModule,
    MatCardModule,
    MatToolbarModule,
    CKEditorModule,
    MatRadioModule,
    UserIdleModule.forRoot({
      idle: environment.IdleTime,
      timeout: APIConstant.TIMEOUT_CONFIRM_STAY_LOGIN,
      ping: APIConstant.IDLE_PING_INTERVAL,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        skipWhenExpired: true,
      },
    }),
    NgxCaptchaModule,
    CheckboxModule,
    CurrencyMaskModule,
    CurrencyTestModule,
    DirectivesModule,
    AgmCoreModule.forRoot({
      apiKey: environment.MapsAPIKey,
    }),
    AgmSnazzyInfoWindowModule,
    AgmJsMarkerClustererModule,
    LightboxModule,
    AngularCropperjsModule,
    MatTabsModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    Title,
    CommonService,
    RolePermissionService,
    WarmupAPIService,
    NgForm,
    DataService,
    DatePipe,
    PaymentRequestDetailsService,
    LocationService,
    FileNameService,
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
    Logger,
    INFO_LOGGER_PROVIDER,
    {
      provide: APP_INITIALIZER,
      useFactory: bootrapConfig,
      deps: [ConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [DescribeStateMachineComponent],
})
export class AppModule {}
