<h1>This page for testing purpose</h1>
<p>This page is created to update member information to test complex test cases regarding to timestamp or time schedule
</p>
<ngb-tabset>
  <ngb-tab title="Contract Start Date">
    <ng-template ngbTabContent>
      <p>This is used to change the Membership contract start date</p>
      <form [formGroup]="MembershipForm">
        <div class="form-group row">
          <label for="txtMemberId" class="col-md-3 col-lg-2 col-form-label">Member Id<span
              class="text-danger">*</span></label>
          <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
              <input type="text" class="form-control" formControlName="txtMemberId" autocomplete="off" maxlength="200"
                numberOnly>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="txtContractStartDate" class="col-md-3 col-lg-2 col-form-label">Contract Start Date<span
              class="text-danger">*</span></label>
          <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
              <input type="text" class="form-control" formControlName="txtContractStartDate" autocomplete="off"
                maxlength="200" placeholder="dd/MM/yyyy">
            </div>
          </div>
        </div>
        <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
          <button class="px-2 px-lg-5 py-2 btn btn-primary mr-3" type="button" (click)="SaveClick()"><i
              class="fas fa-check mr-2"></i>Save</button>
          <button class="px-2 px-lg-5 py-2 btn btn-primary mr-3" type="button" (click)="GetPricePerWeek()"><i
              class="fas fa-check mr-2"></i>Get Price Per Week</button>
        </div>
      </form>
    </ng-template>
  </ngb-tab>
  <ngb-tab title="Query Data">
    <ng-template ngbTabContent>
      <p>This is used to query data in database</p>
      <form [formGroup]="SQLForm">
        <div class="form-group row">
          <label for="txtSQL" class="col-md-3 col-lg-2 col-form-label">SQL Statement</label>
          <div class="col-md-12">
            <div class="d-flex flex-column w-100" style="width: 100% !important">
              <textarea  formControlName="txtSQL" class="form-control" id="txtSQL" maxlength="5000" rows="6"></textarea>
            </div>
          </div>
        </div>
        <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
          <button class="px-2 px-lg-5 py-2 btn btn-primary mr-3" type="button" (click)="QueryData()"><i
              class="fas fa-check mr-2"></i>Query</button>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-lg-2 col-form-label">JSON Result</label>
          <div class="col-md-12">
            <div class="d-flex flex-column w-100" style="background-color: black; width: 100% !important">
              <pre style="color: white">{{DataResult | json}}</pre>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </ngb-tab>
  <ngb-tab title="Change Suspension Start/End Date">
    <ng-template ngbTabContent>
      <form [formGroup]="SuspensionForm">
        <div class="form-group row">
          <label for="txtMemberId" class="col-md-3 col-lg-2 col-form-label">Member Id<span
              class="text-danger">*</span></label>
          <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
              <input type="text" class="form-control" formControlName="txtMemberIdforSuspension" autocomplete="off"
                maxlength="200" numberOnly>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="txtSuspensionStartDate" class="col-md-3 col-lg-2 col-form-label">Suspension Start Date<span
              class="text-danger">*</span></label>
          <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
              <input type="text" class="form-control" formControlName="txtSuspensionStartDate" autocomplete="off"
                maxlength="200" placeholder="dd/MM/yyyy">

            </div>
            <div class="d-flex flex-column w-100">
              <button class="px-2 px-lg-5 py-2 btn btn-primary mr-3" type="button"
                (click)="SaveSuspensionStartDateClick()"><i class="fas fa-check mr-2"></i>Save start</button>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="txtSuspensionEndDate" class="col-md-3 col-lg-2 col-form-label">Suspension End Date<span
              class="text-danger">*</span></label>
          <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
              <input type="text" class="form-control" formControlName="txtSuspensionEndDate" autocomplete="off"
                maxlength="200" placeholder="dd/MM/yyyy">

            </div>
            <div class="d-flex flex-column w-100">
              <button class="px-2 px-lg-5 py-2 btn btn-primary mr-3" type="button"
                (click)="SaveSuspensionEndDateClick()"><i class="fas fa-check mr-2"></i>Save end</button>
            </div>
          </div>
        </div>
        <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
          <button class="px-2 px-lg-5 py-2 btn btn-primary mr-3" type="button" (click)="GetSuspensionStatus()"><i
              class="fas fa-check mr-2"></i>Get Suspension Status of the Member Id above</button>
        </div>
        <p>
          Active = 1<br>
          Inactive = 2<br>
          Suspended = 3<br>
        </p>
      </form>
    </ng-template>
  </ngb-tab>
</ngb-tabset>