import { Component, Injector, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogResult } from '@fp/components/common-dialog/common-dialog.component';
import { FacilityServiceFormBase } from '@fp/components/facility-components/addfacility/products-services/services-list/service-form-base';
import { CommonMessage, CommonString } from '@fp/constant';
import { CommonDataType, EServiceStatus } from '@fp/enums';
import { CustomMessageBox, CustomMessageBoxButton, FPValidators, NgbTimeSpanStringAdapter, StringHelper, Utils } from '@fp/helpers';
import { DataResult, PaymentCalculationMethod, ScannerCaseAttachment, ScannerCaseType, ScannerInternetService, ScannerSIMProvider, ServiceDetails, ServiceStatus, ServiceType, VisitRecordingMethod, CommonResponse, PaymentFrequency } from '@fp/models';
import { CommonDataService, PaymentService, ServiceService } from '@fp/services';
import { NgbAccordion, NgbDateAdapter, NgbDateNativeUTCAdapter, NgbDateStruct, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { v4 as uuid } from 'uuid';
import { MessageBox, MessageBoxButton } from '@fp/services/common-dialog.service';

@Component({
    selector: 'app-facility-service-details',
    templateUrl: './service-details.component.html',
    styleUrls: ['./service-details.component.css'],
    providers: [
        { provide: FacilityServiceFormBase, useExisting: FacilityServiceDetailsComponent },
        { provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter },
        { provide: NgbTimeAdapter, useClass: NgbTimeSpanStringAdapter }
    ]
})
export class FacilityServiceDetailsComponent extends FacilityServiceFormBase {
    // #region Constants
    CommonMessage = CommonMessage;
    CommonString = CommonString;
    StringHelper = StringHelper;
    // #endregion Constants

    types: ServiceType[];
    statuses: ServiceStatus[];
    paymentCalculationMethods: PaymentCalculationMethod[];
    visitRecordingMethods: VisitRecordingMethod[];
    selectedVisitRecordingMethods: VisitRecordingMethod[];
    simProviders: ScannerSIMProvider[];
    internetServices: ScannerInternetService[];
    caseTypes: ScannerCaseType[];
    caseAttachments: ScannerCaseAttachment[];
    private _maxDate: NgbDateStruct;
    get maxDate() { return this._maxDate; }

    VisitRecordingMethodCtrl = new UntypedFormControl(null, Validators.required);
    get ScannersListCtrl() { return <UntypedFormArray>this.getControl('Scanners'); }
    get LinkApiListCtrl() { return <UntypedFormArray>this.getControl('LinkApis'); }
    get PhysicalListCtrl() { return <UntypedFormArray>this.getControl('PhysicalDevices'); }

    parentFocus = 0;
    public DeviceOrSiteTokenChanged = false;

    @ViewChild('accordionScanners') private accordionScanners: NgbAccordion;
    @ViewChild('accordionPhysicalDevice') private accordionPhysicalDevice: NgbAccordion;
    @ViewChild('accordionLinkApi') private accordionLinkApi: NgbAccordion;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            Id: [0],
            Name: [null, Validators.required],
            ServiceTypeId: [null, Validators.required],
            ServiceType: [null],
            ServiceStatusId: [EServiceStatus.Active, Validators.required],
            Status: [null],
            MaxCapacity: [null],
            CurrentCapacity: [null],
            FacilitySqm: [null],
            PaymentCalculationMethodId: [1, Validators.required],
            PaymentCalculationMethod: [null],
            PricePerVisit: [null],
            OtherNotes: [null],
            IsOffPeakService: [false],
            OffPeakStartTime: ['00:00:00', FPValidators.requiredWhen(ctrl => {
                const parent = ctrl.parent;
                return parent && parent.get('IsOffPeakService').value === true;
            })],
            OffPeakEndTime: ['00:00:00', FPValidators.requiredWhen(ctrl => {
                const parent = ctrl.parent;
                return parent && parent.get('IsOffPeakService').value === true;
            })],
            VisitRecordingMethodIds: [[], Validators.required],
            VisitRecordingMethods: [[]],
            Scanners: fb.array([]),
            LinkApis: fb.array([]),
            PhysicalDevices: fb.array([]),
            IsLimitVisits: [false],
            MaxVisitsPerPaymentPeriod: [null],
            AdditionalVisitCharge: [null],
            AdditionalInformation: [null],
            HwmNumberMemberships: [null, Validators.required],
            HwmVisitsPerMembership: [null, Validators.required],
            HwmValue: [null],
            HwmPaymentValue: [null],
            HwmPricePerMembership: [null, Validators.required],
            DeductionAmount: [null],
            txtFPFre: [null]
        });
    }

    static getScannerFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            Id: [0],
            ServiceId: [0],
            InstallationDate: [null, Validators.required],
            DeviceName: [null, Validators.required],
            AppleId: [null, Validators.required],
            SIMProviderId: [4, Validators.required], // Default provider is "None" = 4
            InternetServiceId: [null, Validators.required],
            CaseTypeId: [4, Validators.required], // Default Case Type is "None" = 4
            CaseAttachmentId: [null, Validators.required],
            SimID: [null, FPValidators.requiredWhen(ctrl => {
                const parent = ctrl.parent;
                const simProviderId = parent ? parent.get('SIMProviderId').value : null;
                return parent && simProviderId !== null && simProviderId !== 4;
            })],
            IsSecurityCable: [null, Validators.required],
            IsCaseKeyStatusIn: [null, Validators.required],
            IsCableKeyStatusIn: [null, Validators.required],
            DeviceUsername: [null, Validators.required],
            DevicePassword: [null, Validators.required],
            AdditionalScannerInformation: [null],
            IsActive: [true]
        });
    }

    static getLinkApiFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            Id: [0],
            TokenName: [null, Validators.required],
            SiteToken: [null],
            DeviceToken: [null],
            IsActive: [true]
        });
    }

    static getPhysicalDeviceFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            Id: [0],
            PhysicalDeviceName: [null, Validators.required],
            SuccessTriggerURL: [null],
            FailureTriggerURL: [null],
            IsActive: [true]
        });
    }

    constructor(injector: Injector, private paymentSvc: PaymentService, private commonDataSvc: CommonDataService, private svc: ServiceService) {
        super(injector);
        this.selectedVisitRecordingMethods = [];
        const adapter = new NgbDateNativeUTCAdapter();
        this._maxDate = adapter.fromModel(new Date());
    }

    OnLoad() {
        this.DeviceOrSiteTokenChanged = false;
        this.InvokeBatch([
            this.svc.getTypes(),
            this.svc.getStatuses(),
            this.svc.getPaymentCalculationMethods(),
            this.svc.getVisitRecordingMethods(),
            this.paymentSvc.getFrequencies()],
            {
                onSuccess: (res: DataResult<any>[]) => {
                    this.handleGetTypesResult(res[0]);
                    this.handleGetStatusesResult(res[1]);
                    this.handleGetPaymentCalculationMethodsResult(res[2]);
                    this.handleGetVisitRecordingMethodsResult(res[3]);
                    this.SetPaymentFrequencyName(res[4]);
                    if (this.getControl('Scanners').value instanceof Array && this.getControl('Scanners').value.length > 0) {
                        // Load common data for Scanner if value was previously patched.
                        this.loadScannerCommonData(true);
                    } else {
                        super.OnLoad();
                    }
                    this.isReadyToPatch(this.data.model, { emitEvent: false });
                }
            });
    }

    LoadComplete() {
        super.LoadComplete();
        setTimeout(() => {
            // #region Conditional validation
            this.getControl('PaymentCalculationMethodId').valueChanges.subscribe((value: number) => {
                this.setConditionalValidatorsByPaymentCalculationMethod(value);
                this.SetBlankForZeroValue(value);
            });

            this.getControl('IsLimitVisits').valueChanges.subscribe((value: boolean) => {
                this.setConditionalValidatorsByLimitVisits(value);
            });
            // #endregion Conditional validation

            this.getControl('ServiceTypeId').valueChanges.subscribe((value: number) => {
                if (value != null) {
                    this.getControl('ServiceType').setValue(this.types.find(t => t.Id === value));
                }
            });

            this.getControl('ServiceStatusId').valueChanges.subscribe((value: number) => {
                if (value != null) {
                    this.getControl('Status').setValue(this.statuses.find(t => t.Id === value));
                }
            });

            this.getControl('PaymentCalculationMethodId').valueChanges.subscribe((value: number) => {
                if (value != null) {
                    this.getControl('PaymentCalculationMethod').setValue(this.paymentCalculationMethods.find(t => t.Id === value));
                }
            });

            this.getControl('IsOffPeakService').valueChanges.subscribe((value: boolean) => {
                this.getControl('OffPeakStartTime').updateValueAndValidity();
                this.getControl('OffPeakEndTime').updateValueAndValidity();
            });

            this.getControl('VisitRecordingMethods').valueChanges.subscribe((value: VisitRecordingMethod[]) => {
                this.getControl('VisitRecordingMethodIds').setValue(value.map(e => e.Id));
            });

            this.VisitRecordingMethodCtrl.valueChanges.subscribe((value: VisitRecordingMethod) => {
                if (value != null) {
                    this._addVisitRecordingMethod(value);
                }
            });
        });
    }

    isReadyToPatch(value: ServiceDetails, opt) {
        if(this.visitRecordingMethods != undefined) {
            this.PatchValue(value, opt);
        }
    }

    PatchValue(value: ServiceDetails, opt) {
        this.setConditionalValidatorsByPaymentCalculationMethod(value.PaymentCalculationMethodId);
        this.selectedVisitRecordingMethods = value.VisitRecordingMethods || [];
        this.visitRecordingMethods = this.visitRecordingMethods.filter(m => this.selectedVisitRecordingMethods.find(_m => _m.Id === m.Id) == null);
        if (value.ServiceType == null) {
            value.ServiceType = this.types.find(s => s.Id === value.ServiceTypeId);
        }
        if (value.Status == null) {
            value.Status = this.statuses.find(s => s.Id === value.ServiceStatusId);
        }
        this.setConditionalValidatorsByLimitVisits(value.IsLimitVisits);

        if (value.Scanners instanceof Array && value.Scanners.length > 0) {
            value.Scanners.forEach(_scanner => {
                this.addScannerInternal();
            });
            if (!this.loadOnDemand && this.loaded) {
                this.loadScannerCommonData();
            }
        }
        if (value.LinkApis instanceof Array && value.LinkApis.length > 0) {
            value.LinkApis.forEach(_link => {
                this.addLinkApiInternal();
            });
        }
        if (value.PhysicalDevices instanceof Array && value.PhysicalDevices.length > 0) {
            value.PhysicalDevices.forEach(pd => {
                this.addPhysicalDeviceInternal();
            });
        }
        super.PatchValue(value, opt);
        this.CalculateHWMValue();
    }
    
    private SetPaymentFrequencyName(frequencies: DataResult<PaymentFrequency[]>) {
        let frename = '';
        let freid = this.commonSvc.PaymentFrequencyID;
        for (let i = 0; i < frequencies.Data.length; i++) {
            if (freid == frequencies.Data[i].PaymentFrequencyId) {
                frename = frequencies.Data[i].PaymentFrequencyName;
                break;
            }
        }
        this.getControl('txtFPFre').setValue(frename);
    }

    onServiceTypeSelect(event) {
        if ((this.getControlValue('Name') == null) || (this.getControlValue('Name') == "")) {
            console.log(event.target[String(event.target.value).split(":")[0]].label);
            this.getControl('Name').setValue(event.target[String(event.target.value).split(":")[0]].label);
            this.changeDetectorRef.markForCheck();
        }
    }

    private loadScannerCommonData(firstLoad = false) {
        if (!this.simProviders || !this.internetServices || !this.caseTypes || !this.caseAttachments) {
            this.InvokeBatch([
                this.commonDataSvc.getData(CommonDataType.ScannerSIMProvider),
                this.commonDataSvc.getData(CommonDataType.ScannerInternetService),
                this.commonDataSvc.getData(CommonDataType.ScannerCaseType),
                this.commonDataSvc.getData(CommonDataType.ScannerCaseAttachment)
            ],
                {
                    onSuccess: (scannerCommonDataRes: DataResult<any>[]) => {
                        this.handleGetScannerSIMProvidersResult(scannerCommonDataRes[0]);
                        this.handleGetScannerInternetServicesResult(scannerCommonDataRes[1]);
                        this.handleGetScannerCaseTypesResult(scannerCommonDataRes[2]);
                        this.handleGetScannerCaseAttachmentsResult(scannerCommonDataRes[3]);
                        if (firstLoad) {
                            super.OnLoad();
                        }
                    }
                });
        }
    }

    //Site Token for Link API
    private loadSiteTokenGlobal() {
        const st = localStorage.getItem('SiteToken');
        if (st === '' || st === undefined || st === null) {
            this.InvokeBatch([
                this.commonDataSvc.getTokenSiteScanner()
            ],
                {
                    onSuccess: (linkCommonDataRes: DataResult<any>[]) => {
                        this.handleSiteTokenGlobal(linkCommonDataRes[0].Data);
                    }
                });
        } else {
            this.handleSiteTokenGlobal(st);
        }
    }

    private handleSiteTokenGlobal(SiteTokentmp: string) {
        for (let i = 0; i < this.LinkApiListCtrl.controls.length; i++) {
            const fgtemp: UntypedFormGroup = <UntypedFormGroup>this.LinkApiListCtrl.controls[i];
            fgtemp.get('SiteToken').setValue(SiteTokentmp);
        }
    }

    private handleGetTypesResult(res: DataResult<ServiceType[]>) {
        if (res.Success) {
            this.types = res.Data.sort((a, b) => a.Name.toLocaleLowerCase() > b.Name.toLocaleLowerCase() ? 1 : -1);
        } else {
            this.HandleResponseError(res);
        }
    }

    private handleGetStatusesResult(res: DataResult<ServiceStatus[]>) {
        if (res.Success) {
            this.statuses = res.Data;
            this.getControl('Status').setValue(this.statuses.find(s => s.Id === EServiceStatus.Active));

            // Reset pristine status
            this.getControl('Status').markAsPristine();
        } else {
            this.HandleResponseError(res);
        }
    }

    private handleGetPaymentCalculationMethodsResult(res: DataResult<PaymentCalculationMethod[]>) {
        if (res.Success) {
            this.paymentCalculationMethods = res.Data.sort((a, b) => a.DisplayOrder > b.DisplayOrder ? 1 : -1);

            const temppaymentcal: PaymentCalculationMethod[] = [];
            for (let i = 0; i < this.paymentCalculationMethods.length; i++) {
                if (this.paymentCalculationMethods[i].Name !== 'Deduction') {
                    temppaymentcal.push(this.paymentCalculationMethods[i]);
                }
            }
            this.paymentCalculationMethods = temppaymentcal;

            this.getControl('PaymentCalculationMethodId').setValue(1);
            this.getControl('PaymentCalculationMethod').setValue(this.paymentCalculationMethods.find(t => t.Id === 1));
            this.setConditionalValidatorsByPaymentCalculationMethod(1);

            // Reset pristine status
            this.getControl('PaymentCalculationMethodId').markAsPristine();
            this.getControl('PaymentCalculationMethod').markAsPristine();
        } else {
            this.HandleResponseError(res);
        }
    }

    private handleGetVisitRecordingMethodsResult(res: DataResult<VisitRecordingMethod[]>) {
        if (res.Success) {
            this.visitRecordingMethods = res.Data.sort((a, b) => a.DisplayOrder > b.DisplayOrder ? 1 : -1);
        } else {
            this.HandleResponseError(res);
        }
    }

    private handleGetScannerSIMProvidersResult(res: DataResult<ScannerSIMProvider[]>) {
        if (res.Success) {
            this.simProviders = Utils.sortCommonData(res.Data);
        } else {
            this.HandleResponseError(res);
        }
    }

    private handleGetScannerInternetServicesResult(res: DataResult<ScannerInternetService[]>) {
        if (res.Success) {
            this.internetServices = Utils.sortCommonData(res.Data);
        } else {
            this.HandleResponseError(res);
        }
    }

    private handleGetScannerCaseTypesResult(res: DataResult<ScannerCaseType[]>) {
        if (res.Success) {
            this.caseTypes = Utils.sortCommonData(res.Data);
        } else {
            this.HandleResponseError(res);
        }
    }

    private handleGetScannerCaseAttachmentsResult(res: DataResult<ScannerCaseAttachment[]>) {
        if (res.Success) {
            this.caseAttachments = Utils.sortCommonData(res.Data);
        } else {
            this.HandleResponseError(res);
        }
    }

    /** @internal */
    _addVisitRecordingMethod(item: VisitRecordingMethod) {
        const index = this.visitRecordingMethods.indexOf(item);
        if (index > -1) {
            this.visitRecordingMethods.splice(index, 1);
            this.selectedVisitRecordingMethods.push(item);
            this.selectedVisitRecordingMethods = this.selectedVisitRecordingMethods.sort((a, b) => a.DisplayOrder > b.DisplayOrder ? 1 : -1);
            if (item.Id === 2) {
                this._addScanner();
                this.loadScannerCommonData();
            }
            if (item.Id === 4) {
                this._addLinkApi();
            }
            if (item.Id === 5) {
                this._addPhysicalDevice();
            }

            this.getControl('VisitRecordingMethods').setValue(this.selectedVisitRecordingMethods);
            this.VisitRecordingMethodCtrl.setValue(null, { emitEvent: false });
        }
    }
    /** @internal */
    _removeVisitRecordingMethod(item: VisitRecordingMethod) {
        const index = this.selectedVisitRecordingMethods.indexOf(item);
        if (index > -1) {
            if (item.Id === 2 && (this.form.get('Scanners').value || []).length > 0) {
                CustomMessageBox.Show(this.dialog, {
                    title: 'Remove Scanners as a Visit Recording Method',
                    content: 'You are about to remove scanners as a Visit Recording Method. This action will automatically remove all configured scanners for this service. This operation in irreversible and all scanners will have to be reconfigured. Do you wish to continue?',
                    buttons: [
                        CustomMessageBoxButton.Cancel,
                        {
                            label: 'Yes, Remove All Scanners',
                            isPrimary: true,
                            dialogResult: DialogResult.Ok
                        }]
                }, {
                    width: '500px',
                    afterClosed: r => {
                        if (r.result === DialogResult.Ok) {
                            this.visitRecordingMethods.push(...this.selectedVisitRecordingMethods.splice(index, 1));
                            this.visitRecordingMethods = this.visitRecordingMethods.sort((a, b) => a.DisplayOrder > b.DisplayOrder ? 1 : -1);
                            this.form.setControl('Scanners', new UntypedFormArray([]));
                            this.getControl('VisitRecordingMethods').setValue(this.selectedVisitRecordingMethods);
                            this.getControl('VisitRecordingMethods').markAsDirty();
                        }
                    }
                });
            } else if (item.Id === 4 && (this.form.get('LinkApis').value || []).length > 0) {
                CustomMessageBox.Show(this.dialog, {
                    title: 'Remove Link APIs as a Visit Recording Method',
                    content: 'You are about to remove Link APIs as a Visit Recording Method. This action will automatically remove all configured Link APIs for this service. This operation in irreversible and all Link APIs will have to be reconfigured. Do you wish to continue?',
                    buttons: [
                        CustomMessageBoxButton.Cancel,
                        {
                            label: 'Yes, Remove All',
                            isPrimary: true,
                            dialogResult: DialogResult.Ok
                        }]
                }, {
                    width: '500px',
                    afterClosed: r => {
                        if (r.result === DialogResult.Ok) {
                            this.visitRecordingMethods.push(...this.selectedVisitRecordingMethods.splice(index, 1));
                            this.visitRecordingMethods = this.visitRecordingMethods.sort((a, b) => a.DisplayOrder > b.DisplayOrder ? 1 : -1);
                            this.form.setControl('LinkApis', new UntypedFormArray([]));
                            this.getControl('VisitRecordingMethods').setValue(this.selectedVisitRecordingMethods);
                            this.getControl('VisitRecordingMethods').markAsDirty();
                        }
                    }
                });
            }
            else if (item.Id === 5 && (this.form.get('PhysicalDevices').value || []).length > 0) {
                CustomMessageBox.Show(this.dialog, {
                    title: 'Remove Physical Devices as a Visit Recording Method',
                    content: 'You are about to remove Physical Devices as a Visit Recording Method. This action will automatically remove all configured Physical Devices for this service. This operation in irreversible and all Physical Devices will have to be reconfigured. Do you wish to continue?',
                    buttons: [
                        CustomMessageBoxButton.Cancel,
                        {
                            label: 'Yes, Remove All',
                            isPrimary: true,
                            dialogResult: DialogResult.Ok
                        }]
                }, {
                    width: '500px',
                    afterClosed: r => {
                        if (r.result === DialogResult.Ok) {
                            this.visitRecordingMethods.push(...this.selectedVisitRecordingMethods.splice(index, 1));
                            this.visitRecordingMethods = this.visitRecordingMethods.sort((a, b) => a.DisplayOrder > b.DisplayOrder ? 1 : -1);
                            this.form.setControl('PhysicalDevices', new UntypedFormArray([]));
                            this.getControl('VisitRecordingMethods').setValue(this.selectedVisitRecordingMethods);
                            this.getControl('VisitRecordingMethods').markAsDirty();
                        }
                    }
                });
            }
            else {
                this.visitRecordingMethods.push(...this.selectedVisitRecordingMethods.splice(index, 1));
                this.visitRecordingMethods = this.visitRecordingMethods.sort((a, b) => a.DisplayOrder > b.DisplayOrder ? 1 : -1);
                this.getControl('VisitRecordingMethods').setValue(this.selectedVisitRecordingMethods);
                this.getControl('VisitRecordingMethods').markAsDirty();
            }
        }
    }

    /** @internal */
    _addScanner() {
        this.addScannerInternal();
        this.ScannersListCtrl.markAsDirty();
        this.accordionScanners.activeIds = 'pnlScanner#' + this.ScannersListCtrl.length;
    }

    private addScannerInternal() {
        this.ScannersListCtrl.push(FacilityServiceDetailsComponent.getScannerFormGroup());
        const newScannerFrmGrp = <UntypedFormGroup>this.ScannersListCtrl.at(this.ScannersListCtrl.length - 1);
        if (newScannerFrmGrp) {
            newScannerFrmGrp.get('SIMProviderId').valueChanges.subscribe((value: number) => {
                if (value === 4) {
                    newScannerFrmGrp.get('SimID').setValue(null, { emitEvent: false });
                } else {
                    newScannerFrmGrp.get('SimID').updateValueAndValidity({ emitEvent: false });
                }
            });
        }
    }

    _addLinkApi() {
        this.addLinkApiInternal();
        this.LinkApiListCtrl.markAsDirty();
        this.accordionLinkApi.activeIds = 'pnlLinkApi#' + this.LinkApiListCtrl.length;
    }

    private addLinkApiInternal() {
        this.LinkApiListCtrl.push(FacilityServiceDetailsComponent.getLinkApiFormGroup());
        const newLinkApiFrmGrp = <UntypedFormGroup>this.LinkApiListCtrl.at(this.LinkApiListCtrl.length - 1);
        if (newLinkApiFrmGrp) {
            newLinkApiFrmGrp.get('DeviceToken').setValue(this.GetUUID());
            this.loadSiteTokenGlobal();
        }
    }

    _addPhysicalDevice() {
        this.addPhysicalDeviceInternal();
        this.PhysicalListCtrl.markAsDirty();
        this.accordionPhysicalDevice.activeIds = 'pnlPhysicalDevice#' + this.PhysicalListCtrl.length;
    }

    private addPhysicalDeviceInternal() {
        this.PhysicalListCtrl.push(FacilityServiceDetailsComponent.getPhysicalDeviceFormGroup());
    }

    /** @internal */
    _removeScanner(index: number) {
        CustomMessageBox.Show(this.dialog, {
            title: 'Remove a scanner',
            content: 'You are about to remove the current scanner. All data related to this scanner will be deleted and cannot be reproduced. Do you wish to continue?',
            buttons: [
                CustomMessageBoxButton.Cancel,
                {
                    label: 'Yes, Remove Scanner',
                    isPrimary: true,
                    dialogResult: DialogResult.Ok
                }]
        }, {
            width: '500px',
            afterClosed: r => {
                if (r.result === DialogResult.Ok) {
                    this.ScannersListCtrl.removeAt(index);
                    this.ScannersListCtrl.markAsDirty();
                }
            }
        });
    }

    _removeLinkApi(index: number) {
        CustomMessageBox.Show(this.dialog, {
            title: 'Remove a Link API',
            content: 'You are about to remove the current Link API. All data related to this Link API will be deleted and cannot be reproduced. Do you wish to continue?',
            buttons: [
                CustomMessageBoxButton.Cancel,
                {
                    label: 'Yes, Remove Link API',
                    isPrimary: true,
                    dialogResult: DialogResult.Ok
                }]
        }, {
            width: '500px',
            afterClosed: r => {
                if (r.result === DialogResult.Ok) {
                    this.LinkApiListCtrl.removeAt(index);
                    this.LinkApiListCtrl.markAsDirty();
                }
            }
        });
    }

    _removePhysicalDevice(index: number) {
        CustomMessageBox.Show(this.dialog, {
            title: 'Remove a Physical Device',
            content: 'You are about to remove the current Physical Device. All data related to this Physical Device will be deleted and cannot be reproduced. Do you wish to continue?',
            buttons: [
                CustomMessageBoxButton.Cancel,
                {
                    label: 'Yes, Remove Physical Device',
                    isPrimary: true,
                    dialogResult: DialogResult.Ok
                }]
        }, {
            width: '500px',
            afterClosed: r => {
                if (r.result === DialogResult.Ok) {
                    this.PhysicalListCtrl.removeAt(index);
                    this.PhysicalListCtrl.markAsDirty();
                }
            }
        });
    }

    confirmRegenerateLinkApi(type: string, id: string) {
        MessageBox.ShowCustom(this.dialog,
            'Confirmation',
            'Confirmation',
            'Are you sure you want to change this data?', MessageBoxButton.YesNo).subscribe(
                (dialogResult: DialogResult) => {
                    if (dialogResult.result === DialogResult.Yes) {
                        this.GenerateUUID(type, id);
                    }
                }
            );
    }

    private setConditionalValidatorsByPaymentCalculationMethod(methodId: number) {
        this.getControl('HwmNumberMemberships').clearValidators();
        this.getControl('HwmVisitsPerMembership').clearValidators();
        this.getControl('HwmPricePerMembership').clearValidators();
        this.getControl('PricePerVisit').clearValidators();
        this.getControl('OtherNotes').clearValidators();
        switch (methodId) {
            case 1:
                this.getControl('HwmNumberMemberships').setValidators([Validators.required, Validators.min(0)]);
                this.getControl('HwmVisitsPerMembership').setValidators([Validators.required, Validators.min(0)]);
                this.getControl('HwmPricePerMembership').setValidators([Validators.required, Validators.min(0)]);
                break;
            case 2:
                this.getControl('PricePerVisit').setValidators([Validators.required, Validators.min(0)]);
                break;
            case 3:
                this.getControl('OtherNotes').setValidators([Validators.required]);
                break;
        }
        this.getControl('HwmNumberMemberships').updateValueAndValidity({ emitEvent: false });
        this.getControl('HwmVisitsPerMembership').updateValueAndValidity({ emitEvent: false });
        this.getControl('HwmPricePerMembership').updateValueAndValidity({ emitEvent: false });
        this.getControl('PricePerVisit').updateValueAndValidity({ emitEvent: false });
        this.getControl('OtherNotes').updateValueAndValidity({ emitEvent: false });
    }

    private SetBlankForZeroValue(methodId: number) {
        switch (methodId) {
            case 1:
                this.getControl('HwmNumberMemberships').value === 0 ? this.getControl('HwmNumberMemberships').setValue('') : this.getControl('HwmNumberMemberships').value;
                this.getControl('HwmVisitsPerMembership').value === 0 ? this.getControl('HwmVisitsPerMembership').setValue('') : this.getControl('HwmVisitsPerMembership').value;
                this.getControl('HwmValue').value === 0 ? this.getControl('HwmValue').setValue('') : this.getControl('HwmValue').value;
                this.getControl('HwmPaymentValue').value === 0 ? this.getControl('HwmPaymentValue').setValue('') : this.getControl('HwmPaymentValue').value;

                break;
        }
    }

    private setConditionalValidatorsByLimitVisits(isLimited: boolean) {
        if (isLimited) {
            this.getControl('MaxVisitsPerPaymentPeriod').setValidators([Validators.required, Validators.min(0)]);
            this.getControl('AdditionalVisitCharge').setValidators([Validators.required]);
        } else {
            this.getControl('MaxVisitsPerPaymentPeriod').clearValidators();
            this.getControl('AdditionalVisitCharge').clearValidators();
        }
        this.getControl('MaxVisitsPerPaymentPeriod').updateValueAndValidity({ emitEvent: false });
        this.getControl('AdditionalVisitCharge').updateValueAndValidity({ emitEvent: false });
    }

    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }
    onBlurParent(focusNumbr) {
        this.parentFocus = focusNumbr;       
    }
    getClassFocused(vlFocused) {
        if (this.parentFocus == vlFocused) {
            return "focused";
        } else {
            return "";
        }
    }

    txtNumMems_Change(event) {
        this.CalculateHWMValue();
    }

    txtNumVMem_Change(event) {
        this.CalculateHWMValue();
    }

    txtNumVMem_Change2(event) {
        this.CalculateHWMValue();
    }

    private CalculateHWMValue() {
        const nummem = this.getControl('HwmNumberMemberships').value;
        const numvmem = this.getControl('HwmVisitsPerMembership').value;
        const pricePerMembership = this.getControl('HwmPricePerMembership').value;
        const hwm = nummem * numvmem;
        const hwmAmountValue = pricePerMembership * nummem;
        console.log(hwmAmountValue);
        this.getControl('HwmPaymentValue').setValue(hwmAmountValue);
        this.getControl('HwmValue').setValue(hwm);
    }

    public GenerateUUID(type: string, id: string) {
        const token: string = this.GetUUID();
        const fg: UntypedFormGroup = <UntypedFormGroup>this.LinkApiListCtrl.controls[id];
        if (type === 'DeviceToken') {
            fg.get('DeviceToken').setValue(token);
        } else if (type === 'SiteToken') {
            fg.get('SiteToken').setValue(token);
            localStorage.setItem('SiteToken', token);
            for (let i = 0; i < this.LinkApiListCtrl.controls.length; i++) {
                const fgtemp: UntypedFormGroup = <UntypedFormGroup>this.LinkApiListCtrl.controls[i];
                fgtemp.get('SiteToken').setValue(token);
            }
        }
        this.DeviceOrSiteTokenChanged = true;
    }

    private GetUUID(): string {
        let token: string = uuid();
        const n = token.lastIndexOf("-");
        const str1 = token.substring(0, n);
        token = str1 + token.substring(n + 1, token.length);
        // Convert token to upper case
        token = token.toUpperCase();
        return token;
    }
}

