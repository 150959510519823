<div [formGroup]="form">
    <ng-template #requiredFieldMsgTmpl let-ctrl="control" let-ctrlName="fieldName"
        let-validateOnTouch="validateOnTouch || false">
        <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
            <small *ngIf="ctrl.errors['required']" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_REQUIRED, ctrlName)}}</small>
                <small
                *ngIf="((ctrl.getError('pattern') != null || ctrl.getError('unresolved') !=null) && !isCheckingCountry)"
                class="invalid-feedback d-block">{{ StringHelper.format(CommonString.FIELD_INVALID, ctrlName) }}</small>
            <small
                *ngIf="((ctrl.getError('pattern') != null || ctrl.getError('unresolved') !=null) && isCheckingCountry)"
                class="invalid-feedback d-block">{{ StringHelper.format(CommonString.NZ_MOBILE_NUMBER_CHECK, ctrlName) }}</small>
            <small *ngIf="ctrl.getError('duplicated') != null" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_VALUE_ALREADY_EXISTED, ctrlName)}}</small>
        </div>
    </ng-template>
    <div class="form-group row">
        <label for="txtEmecyCName" class="col-md-3 col-lg-2 col-form-label">Emergency Contact Name<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input class="form-control" type="text" id="txtEmecyCName" formControlName="EmergencyContactName"
                    maxlength="100" required [class.is-invalid]="!isControlValid('EmergencyContactName')"
                    autocomplete="nope">
                <ng-container
                    *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('EmergencyContactName'), fieldName: 'Emergency Contact Name'}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtRelshp" class="col-md-3 col-lg-2 col-form-label">Relationship<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input class="form-control" type="text" id="txtRelshp" formControlName="EmergencyRelationship"
                    maxlength="100" required [class.is-invalid]="!isControlValid('EmergencyRelationship')">
                <ng-container
                    *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('EmergencyRelationship'), fieldName: 'Relationship'}">
                </ng-container>
            </div>
        </div>
    </div>
    <app-mobile-number-registor [label]="'Emergency Contact No.'" [mandatory]="true" [control]="form.get('EmergencyContactNo')"
     formControlName="EmergencyContactNo" [dataModel]="data.model" [isCountryCodeSet]="true" [col_3]="col_3" [mandotoryLabelRed]="true" #emergencyMobileNumberRegistorComponent
        ></app-mobile-number-registor>
    </div>