<footer class="bg-primary-gradient">
    <!-- <div class="container-fluid text-center bg-primary py-3">
        <img class="footer-logo" src="../../../assets/images/logo_white_landscape.png" width="150" alt="Fitness Passport's White Landscape Logo">
    </div> -->
    <div class="container-fluid pt-4">
        <div class="footer-links px-sm-5">
            <div class="d-sm-flex justify-content-around text-light">
                <div>
                    <h5 class="text-uppercase pb-2">Policies</h5>
                    <ul class="list-unstyled footer-list-style">
                        <li>
                            <span class="fakelink"
                                (click)="openLink('https://fitnesspassport.freshdesk.com/support/solutions/articles/43000425224-privacy-policy')"
                                title="Privacy Policy">Privacy Policy</span>
                        </li>
                    </ul>
                </div>
                <div>
                    <h5 class="text-uppercase pb-2">Terms</h5>
                    <ul class="list-unstyled footer-list-style">
                        <li>
                            <span class="fakelink"
                                (click)="openLink('https://fitnesspassport.freshdesk.com/support/solutions/articles/43000521194-terms-conditions')"
                                title=" Terms and Conditions">Terms and Conditions</span>
                        </li>
                    </ul>
                </div>
                <div>
                    <h5 class="text-uppercase pb-2">Questions?</h5>
                    <ul class="list-unstyled footer-list-style">
                        <li>
                            <span class="fakelink"
                                (click)="openLink('http://fitnesspassport.freshdesk.com/support/home')"
                                title="FAQs">FAQs</span>
                        </li>
                        <li>
                            <span class='fakelink'
                                (click)="openLink('http://fitnesspassport.freshdesk.com/support/home')"
                                title="Contact Us">Contact Us</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <hr class="bg-light m-0 p-0">
    <div class="container-fluid py-3">
        <p class="text-center m-0 p-0 text-light">&copy; Copyright 2019 by
            <a href="#" title="Go to Fitness Passport's Homepage">Fitness Passport</a>
            <br> ABN 50 118 426 029</p>
    </div>
</footer>