<div class="form-group row phone">
    <label [attr.for]="getName(control)"
        [ngClass]="clsLabel ? clsLabel : 'col-md-3 col-lg-2 col-form-label'">{{ label ? label : 'Phone' }}<span
            class="text-danger">{{ mandatory ? '*' : '' }}</span></label>
    <div [ngClass]="clsGroup ? clsGroup : 'col-md-8 col-lg-5'">
          <div class=" d-flex flex-column w-100">
        <input type="text" [readOnly]="readonly"
            [ngClass]="(invalidPhone || (control.invalid && (control.dirty || control.touched || submitted))) ? 'form-control is-invalid':'form-control'"
            autocomplete="off" numberOnly [(ngModel)]="value" [attr.id]="getName(control)" [attr.placeholder]="placeholder"
            [maxlength]="phoneLength" [pattern]="pattern" (blur)="onTouched()" (keyup)="OnChange($event)"
            [disabled]="control.disabled" (change)="OnChange($event)">
        <div *ngIf="control.invalid && (control.dirty || control.touched || submitted)">
            <small *ngIf="control.errors.required"  class="invalid-feedback d-block">{{ label ? label : 'Phone' }} is
                required</small>
            <small *ngIf="control.errors.pattern && IsAUMobile"  class="invalid-feedback d-block">{{ label ? label : 'Phone' }} is
                invalid</small>
            <small *ngIf="control.errors.pattern && !IsAUMobile"  class="invalid-feedback d-block">{{ label ? label : 'Phone' }} is
                invalid. Please enter a valid number with 7-12 digits.</small>
        </div>
        <small *ngIf="control.valid && invalidPhone && IsAUMobile"  class="invalid-feedback d-block">{{ label ? label : 'Phone' }} is
            invalid</small>
        <small *ngIf="control.valid && invalidPhone && !IsAUMobile"  class="invalid-feedback d-block">{{ label ? label : 'Phone' }} is
            invalid. Please enter a valid number with 7-12 digits.</small>
    </div>
</div>
</div>