<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">View/Edit Packages</h4>
<!-- Search Users -->
<div class="mt-lg-4">
    <div class="form-group row">
        <label for="txtSearch" class="col-md-3 col-lg-2 col-form-label">Search Package</label>
        <div class="col-md-8 col-lg-5">
            <div class="input-group" [ngClass] = "getClassFocused(1)">
                <input class="form-control" type="text" placeholder="Search by Package Name" [(ngModel)]="packageName" (blur)="onBlurParent(0)" (focus)="onFocusParent(1)">
            </div>
        </div>
    </div>
    <form [formGroup]="searchPackageFG" (ngSubmit)="findPackages()">
        <div class="form-group row">
            <label for="slctViewPackages" class="col-md-3 col-lg-2 col-form-label">View Packages by</label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <select class="form-control" id="slctViewPackages" formControlName="filter" (ngModelChange)="changeFilter()">
                        <option [value]="0">View All</option>
                        <option [value]="1">State</option>
                        <option [value]="2">Region</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group row" [hidden]="!(searchPackageFG.value.filter == 1)">
            <label for="slctState" class="col-md-3 col-lg-2 col-form-label">State</label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <select class="form-control" id="slctState" formControlName="state">
                        <option [ngValue]="0" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                        <option *ngFor="let state of states" [ngValue]="state">{{state.Name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group row" [hidden]="!(searchPackageFG.value.filter == 2)">
            <label for="txtRegion" class="col-md-3 col-lg-2 col-form-label">Region</label>
            <div class="col-md-8 col-lg-5">
                <div class="input-group" [ngClass] = "getClassFocused(2)">
                    <input class="form-control" type="text" placeholder="Search by Region Name" [matAutocomplete]="regionAutocomplete" formControlName="region" (blur)="onBlurParent(0)" (focus)="onFocusParent(2)">
                    <div class="input-group-append">
                        <span class="input-group-text" *ngIf="!isSearchingRegion">
                            <i class="fas fa-search text-primary"></i></span>
                        <mat-spinner *ngIf="isSearchingRegion" [diameter]="14"></mat-spinner>
                    </div>
                </div>
                <mat-autocomplete #regionAutocomplete="matAutocomplete" [displayWith]="AutoComplete_DisplayWithFn">
                    <mat-option *ngFor="let region of regionSearchResults" [value]="region">
                        <p class="d-flex align-items-center m-0 ml-2 w-100">
                            <span>
                                {{region.Name}}
                            </span>
                        </p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-8 col-lg-5 offset-sm-2">
                <button class="btn btn-primary px-5 text-white" type="submit" [disabled]="searchPackageFG.invalid">SEARCH</button>
            </div>
        </div>
    </form>
</div>
