import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConstant } from '@fp/constants';
import { DataResult, EmployerMembershipPackageModel, MembershipPackageTypeModel, SearchPaginationRequest, SearchPaginationResult, SearchResultRow, SearchPaginationCriteria, ReportRequest } from '@fp/models';
import { HttpDAO } from './httpdao.service';

@Injectable({
    providedIn: 'root'
})
export class MembershipPackagesService {
    private httpDao: HttpDAO | null;

    constructor(httpClient: HttpClient,
        private cmsvc: CommonService) {
        this.httpDao = new HttpDAO(httpClient);
    }

    search(params: SearchPaginationRequest): Observable<DataResult<SearchPaginationResult<EmployerMembershipPackageModel>>> {
        return this.httpDao.postData(APIConstant.API_SEARCH_MEMBERSHIP_PACKAGES, params);
    }

    search2(params: SearchPaginationRequest): Observable<DataResult<SearchPaginationResult<SearchResultRow>>> {
        return this.httpDao.postData(APIConstant.API_SEARCH_MEMBERSHIP_PACKAGES_BY_MEMBERSHIP, params);
    }

    getTypes(): Observable<DataResult<MembershipPackageTypeModel[]>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_MEMBERSHIP_PACKAGE_TYPES);
    }

    save(params: EmployerMembershipPackageModel): Observable<DataResult<EmployerMembershipPackageModel>> {
        return this.httpDao.postData(APIConstant.API_SAVE_MEMBERSHIP_PACKAGE, params);
    }

    checkActiveMembers(id: number): Observable<DataResult<any>> {
        const encrypteddata = this.cmsvc.E_FP_RequestData(
            JSON.stringify(id.toString())
          );
        return this.httpDao.postData(APIConstant.API_CHECK_ACTIVE_MEMBERS , { Data: encrypteddata });
    }

    generateReport(request: ReportRequest): Observable<DataResult<string>> {
        return this.httpDao.postData(APIConstant.API_GENERATE_MEMBERSHIP_PACKAGE_REPORT, request);
    }
}
