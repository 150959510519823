<!-- Progress Bar Modal -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">
      {{ status }}: {{ percent }}%
    </h6>
  </div>
  <div class="modal-body" style="margin-bottom: 10px">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</ng-template>