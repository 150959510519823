<div [formGroup]="form">
    <ngb-accordion [destroyOnHide]="false"
        activeIds="pnlIdentificationDetails,pnlImportantInformation,pnlAddressDetails,pnlContactDetails,pnlActivities,panelAddNotes">
        <ngb-panel id="pnlIdentificationDetails">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': identificationDetails?.valid}"></i>
                Facility Identification Details
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-facility-identification-details [form]="form.get('IdentificationDetails')" [data]="data"
                    #identificationDetails></app-facility-identification-details>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlImportantInformation">
            <ng-template ngbPanelTitle>
                Important Information
                <i class="icon-fp" [ngClass]="{'fas fa-check': importantInformation?.valid}"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-facility-important-information [form]="form.get('ImportantInformation')" [data]="data"
                    #importantInformation></app-facility-important-information>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlAddressDetails">
            <ng-template ngbPanelTitle>
                Address Details
                <i class="icon-fp" [ngClass]="{'fas fa-check': addressDetails?.valid}"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-facility-address-details [form]="form.get('AddressDetails')" [data]="data" #addressDetails>
                </app-facility-address-details>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlContactDetails">
            <ng-template ngbPanelTitle>
                Contact Details (Internal Use Only)
                <i class="icon-fp" [ngClass]="{'fas fa-check': contactDetails?.valid}"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-facility-contact-details [form]="form.get('ContactDetails')" [data]="data" #contactDetails>
                </app-facility-contact-details>
            </ng-template>
        </ngb-panel>


        <ngb-panel id="pnlActivities">
            <ng-template ngbPanelTitle>
                Activities
                <i class="icon-fp" [ngClass]="{'fas fa-check': contactDetails?.valid}"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-facility-activities-details [form]="form.get('ActivityDetails')" [data]="data" #activityDetails></app-facility-activities-details>
            </ng-template>
        </ngb-panel>

        <ngb-panel id="panelAddNotes">
            <ng-template ngbPanelTitle>
                Additional Notes
                <i class="icon-fp fas fa-check"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-facility-notes></app-facility-notes>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>