import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { catchError, startWith, switchMap } from 'rxjs/operators';
import { APIConstant } from 'src/app/constant/apiconstant';
import { DataResult, SearchPaginationResult, ServiceType, SearchPaginationCriteria, SearchPaginationRequest } from 'src/app/models';
import { CommonService } from 'src/app/services/common.service';
import { HttpDAO } from './httpdao.service';

@Injectable({
    providedIn: 'root'
})
export class ProductServiceService {
    private httpDao: HttpDAO | null;

    constructor(httpClient: HttpClient,
        private commonService: CommonService) {
        this.httpDao = new HttpDAO(httpClient);
    }

    load(searchRequest: any): Observable<DataResult<SearchPaginationResult<ServiceType>>> {
        return this.httpDao.postData(APIConstant.API_GET_SERVICE_TYPES_PAGING, searchRequest);
    }

    postData(apiString: string, subscribeDataCallback: Function, dataInput: any) {
        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.commonService.StartProgressBar();
                    return this.httpDao.postData(apiString, dataInput);
                }),
                catchError((error) => {
                    this.commonService.StopProgressBar();
                    throw error;
                }))
            .subscribe(result => {
                this.commonService.StopProgressBar();
                subscribeDataCallback(result);
            });
    }

    getData(apiString: string, subscribeDataCallback: Function) {
        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.commonService.StartProgressBar();
                    return this.httpDao.getData(apiString);
                }),
                catchError((error) => {
                    this.commonService.StopProgressBar();
                    throw error;
                }))
            .subscribe(data => {
                subscribeDataCallback(data);
                this.commonService.StopProgressBar();
            });
    }

    getByName(name: string) {
        return this.httpDao.getSingleData(APIConstant.API_GET_PSTYPE_BY_NAME + encodeURIComponent(name));
    }

    search(request: SearchPaginationRequest): Observable<DataResult<SearchPaginationResult<any>>> {
        return this.httpDao.postData(APIConstant.API_SEARCH_PRODUCTS_AND_SERVICES, request);
    }

    getTypes(): Observable<DataResult<ServiceType[]>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_PRODUCT_AND_SERVICE_TYPES);
    }
}
