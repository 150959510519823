import { Injector, ViewChildren, QueryList, Directive } from "@angular/core";
import { FPFormBaseComponent, ModelBasedFormSharedData } from "src/app/components/base";

@Directive()
export abstract class FacilityProductFormBase extends FPFormBaseComponent<FacilityProductFormSharedData> {
    @ViewChildren(FacilityProductFormBase) private __children: QueryList<FacilityProductFormBase>;
    /**
     * List of direct descendant components.
     * @readonly
     */
    get children(): QueryList<FacilityProductFormBase> { return this.__children; }

    constructor(injector: Injector) {
        super(injector);
        this.data = new FacilityProductFormSharedData();
    }
}

export class FacilityProductFormSharedData extends ModelBasedFormSharedData<any> {
    mode: FacilityProductFormFormMode;
    constructor() {
        super();
        this.model.Id = 0;
        this.mode = FacilityProductFormFormMode.Add;
    }
}

export enum FacilityProductFormFormMode {
    Add,
    Edit
}
