// tslint:disable: max-line-length
import { forwardRef, Injector, Input, Provider, QueryList, ViewChildren, Directive } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FPFormControlValidationDirective } from '@fp/directives/form-control-validation.directive';
import { FPBaseComponent, IFPComponentData } from './fp-base-component';

/**
 * **NOTE:** Do not manually implements `OnInit`, `OnDestroy` and `AfterViewInit` since it will break this component's life-cycle handling.
 * @abstract
 * @class FPFormBaseComponent
 * @extends {FPBaseComponent<TData>}
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 * @template TData
 */
@Directive()
export abstract class FPFormBaseComponent<TData extends IFormSharedData> extends FPBaseComponent<TData> {
    @ViewChildren(FPFormBaseComponent) protected readonly _children: QueryList<FPFormBaseComponent<TData>>;
    /**
     * List of direct descendant components.
     * @readonly
     */
    get children(): QueryList<FPFormBaseComponent<TData>> { return this._children; }

    private _form: UntypedFormGroup;
    get form() { return this._form; }
    @Input() set form(value) { this._form = value; }

    /**
     * Indicates whether the form is valid.
     * @readonly
     */
    get valid() { return this.form ? !(this.form.invalid || this.form.pending) : false; }

    @ViewChildren(FPFormControlValidationDirective, { read: FPFormControlValidationDirective }) private _formControlValidations: QueryList<FPFormControlValidationDirective>;

    static provideExisting(type: any): Provider {
        return <Provider>{ provide: FPFormBaseComponent, useExisting: forwardRef(() => type) };
    }

    constructor(injector: Injector, config?: { form?: UntypedFormGroup }) {
        super(injector);
        const { form } = Object.assign({}, config);
        this._form = typeof (<any>this.constructor).getFormGroup === 'function' ? (<any>this.constructor).getFormGroup() : form || new UntypedFormGroup({});
    }

    /**
     * Raised at the end of the load stage, after the component has been loaded.
     *
     * This is where all the event listeners are subscribed after the form controls have been
     * initiated and applied with value to prevent the events from firing during `PatchValue` call.
     *
     * Override this event to add more logic after the component has been loaded.
     */
    protected OnLoadComplete() {
        if (this._formControlValidations) {
            this._formControlValidations.forEach(_d => {
                const options = _d.options;
                const oldToggleFn = options.toggleFn;
                options.toggleFn = () => this.validatedOnce || (typeof oldToggleFn == 'function' && oldToggleFn());
                _d.options = options;
            });
        }
        this.LoadComplete();
        this.form.statusChanges.subscribe(r => { this.invalidate(); this.changeDetectorRef.markForCheck(); });
        super.OnLoadComplete();
    }

    /**
     * @deprecated Use OnLoadComplete instead.
     * @see {FPFormBaseComponent.OnLoadComplete}
     */
    protected LoadComplete() {
        // TODO: log warning message to remind the consumer to change to OnLoadComplete method
        this._logger.debug('LoadComplete ' + '<' + this.element.localName + '>');
    }

    public Validate() {
        this.form.updateValueAndValidity({ onlySelf: true });
        super.Validate();
    }

    public getControl(ctrlName: string | (string | number)[]): AbstractControl {
        return !ctrlName ? null : this.form.get(ctrlName);
    }

    getControlValue<T>(ctrlName: string | (string | number)[]): T {
        const ctrl = this.getControl(ctrlName);
        return ctrl && ctrl.value;
    }

    public isControlValid(ctrlOrName: AbstractControl | string | (string | number)[], validateOnTouch = false) {
        if (!ctrlOrName) {
            this._logger.error('Control or name cannot be null or empty.');
            return false;
        }

        let valid = false;
        try {
            let control: AbstractControl = null;
            if (ctrlOrName instanceof AbstractControl) {
                control = ctrlOrName;
            } else {
                control = this.form.get(ctrlOrName);
            }
            valid = !(control.invalid && (control.dirty || (validateOnTouch && control.touched) || (this.validatedOnce || this.validated)));
        } catch (e) {
            this._logger.error(e);
            valid = false;
        }
        return valid;
    }

    public PatchValue(value: { [key: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
        this.form.patchValue(value, options);
        this.children.forEach(child => { child.PatchValue(value, options); });
    }

    /** @internal */
    _findInvalidControls(parent: UntypedFormGroup, padLevel = 1) {
        let invalidCtrls = [];
        const controls = parent.controls;
        for (const name of Object.keys(controls)) {
            const ctrl = controls[name];
            if (ctrl.invalid) {
                const errors = ctrl.errors || {};
                invalidCtrls.push(name.padStart(name.length + padLevel * 2) + ': ' + Object.keys(errors).join(', '));
                if (ctrl instanceof UntypedFormGroup) {
                    invalidCtrls = invalidCtrls.concat(this._findInvalidControls(<UntypedFormGroup>ctrl, padLevel + 1));
                }
            }
        }
        return invalidCtrls;
    }

    applyValue(target, source?: FPFormBaseComponent<TData>) {
        if (!source) {
            source = this;
        }
        if (source.children.length === 0) {
            const obj = source.form.getRawValue();
            if (Object.keys(obj).length > 0 && obj.constructor === Object) {
                target = Object.assign(target, obj);
            }
        } else {
            source.children.forEach(child => {
                target = this.applyValue(target, child);
            });
        }
        return target;
    }
}

// tslint:disable-next-line no-empty-interface
export interface IFormSharedData extends IFPComponentData { }

export abstract class ModelBasedFormSharedData<TModel> implements IFormSharedData {
    model: TModel;
    requestQueueCount: number;
    constructor() {
        this.model = <TModel>{};
        //this.requestQueueCount = 0;
    }
}

@Directive()
export abstract class ModelBasedForm<TModel> extends FPFormBaseComponent<ModelBasedFormSharedData<TModel>> {
    @ViewChildren(ModelBasedForm) protected _children: QueryList<ModelBasedForm<TModel>>;
    /**
     * List of direct descendant components.
     * @readonly
     */
    get children(): QueryList<ModelBasedForm<TModel>> { return this._children; }

    static provideExisting(type: any): Provider {
        return <Provider>{ provide: ModelBasedForm, useExisting: forwardRef(() => type) };
    }

    PatchValue(value: { [key in keyof TModel]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
        super.PatchValue(value, options);
    }
}
