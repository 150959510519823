<div class="form-group row">
  <label [attr.for]="getName(control)" class="col-sm-3 col-form-label mobile-lable-padding" [ngClass]="col_4 ? 'col-sm-4' : (col_3 ? 'col-md-3 col-lg-2' :'col-sm-3')">{{
    label ? label : "Mobile Number" }} <span ngif = "mandatory" [ngClass]="mandotoryLabelRed ? 'text-danger' : ''">*</span>
  </label>
  <div class="align-items-start" [ngClass]="col_4 ? 'col-sm-8' : (col_3 ? 'col-md-8 col-lg-5' : 'col-sm-9')">
    <div class="counry-code">
      <select id="inputState" #selectedCountryCode class="form-control w-auto custom-select" [ngModel]="defaultCountryCode" (change)="OnChangeCountryCode($event)">
        <option *ngFor="let countryCode of countryCodes; let i = index"
          [ngValue]="countryCode">
          {{countryCode}}
        </option>
      </select>
    </div>
    <div class="d-flex flex-column mobile-no w-100 pl-2">
      <div [class.focused]="parentFocus == 61">
        <input [ngClass]="
            invalidPhone ||
            (control.invalid && (control.dirty || control.touched || submitted))
              ? 'is-invalid'
              : ''
          " type="text" class="form-control pl-0" [(ngModel)]="mobileNo" [attr.id]="getName(control)"
          [attr.placeholder]="placeholder" [maxlength]="mobileLength" [minlength]="mobileLength" [readonly]="readonly"
          [pattern]="pattern" (blur)="checkExistMobile && txtMobileNumber_Blur($event)"
          (keyup)="OnChange($event)" (change)="OnChange($event)" (focus)="onFocusParent(61)" />
        <mat-spinner [diameter]="14" *ngIf="phoneSpinter && checkExist"></mat-spinner>
      </div>
      <div *ngIf="
          control.invalid && (control.dirty || control.touched || submitted)
        ">
        <small *ngIf="control.errors.required" class="invalid-feedback d-block">{{ label ? label : "Mobile Number" }} is
          required</small>
        <!-- <small *ngIf="control.errors.minlength" class="invalid-feedback d-block">Mobile Number is invalid</small>
              <small *ngIf="control.errors.maxlength" class="invalid-feedback d-block">Mobile Number is invalid</small> -->
        <small *ngIf="control.errors.pattern && isAusValidationMessage" class="invalid-feedback d-block">{{ label ? label : "Mobile Number" }} is
          invalid</small>
        <small *ngIf="control.errors.pattern && !isAusValidationMessage" class="invalid-feedback d-block">{{ label ? label : "Mobile Number" }} is
          invalid. Please enter a valid number with 7-12 digits. Leading zero will be removed.</small>
        <small *ngIf="control.errors['duplicated']" class="invalid-feedback d-block">{{ label ? label : "Mobile Number"
          }} already exists</small>
      </div>
      <small *ngIf="invalidPhone && isAusValidationMessage && !control.errors.pattern" class="invalid-feedback d-block">{{ label ? label : "Mobile Number"
        }} is invalid</small>
      <small *ngIf="invalidPhone && !isAusValidationMessage && !control.errors.pattern" class="invalid-feedback d-block">{{ label ? label : "Mobile Number" }} is
        invalid. Please enter a valid number with 7-12 digits. Leading zero will be removed.</small>
    </div>
  </div>
</div>