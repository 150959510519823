import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonString, StorageKey } from "@fp/constant";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { merge, of as observableOf, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, startWith, switchMap, shareReplay } from 'rxjs/operators';
import { CommonService, MessageBox } from '@fp/services';
import { HttpDAO } from '../../../services/httpdao.service';
import { APIConstant } from '@fp/constant';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SearchPaginationRequest, DataResult, SearchPaginationResult, Facility, CommonResponse, User } from "@fp/models";
import { PaymentInvoiceModel, InvoiceSearchRequest, ListApprovePaymentRequestModel, ApprovePaymentRequestModel } from "@fp/models/payment.invoice.model";
import { DateHelper, Utils } from "@fp/helpers";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { PdfPaymentInvoiceReport } from "@fp/services/report/payment-invoice.report";
import { FpFileUploadComponent, FpFileData } from "@fp/components/control";
import { S3Configuration } from "@fp/models/s3configuration.model";

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean
    }
}

export interface PInvoiceElement {
    Service: string;
    Date: string;
    Visit_Count: number;
    Calculation_Method: string;
    Amount: number;
    Invoice: string;
    Status: string;
}

@Component({
    selector: "app-view-payments-and-invoices",
    templateUrl: "./view-payments-and-invoices.component.html",
    styleUrls: ["./view-payments-and-invoices.component.css"]
})
export class ViewPaymentsAndInvoicesComponent implements OnInit {
    public CommonString = CommonString;
    public AvailableFacilities: Facility[] = [];
    public AvailableServices: CommonResponse[] = [];
    public SelectedServices: CommonResponse[] = [];
    private httpdao: HttpDAO | null;
    public paymentRequestForm: UntypedFormGroup;
    private btnSearchClicked = false;
    private PaymentInvoinceData: PaymentInvoiceModel[] = [];
    private lstApprovedPI: PaymentInvoiceModel[] = [];
    private isrobj: InvoiceSearchRequest;
    private ppir: PdfPaymentInvoiceReport = new PdfPaymentInvoiceReport();
    private lstaprm: ListApprovePaymentRequestModel;
    private uploadfilecount = 0;
    private configuration: S3Configuration;
    public serviceSpinner = false;
    @ViewChild('ReportUpload') ReportUpload: FpFileUploadComponent;

    public displayedColumns: string[] = ['ServiceName', 'PaymentPeriodEndDisplay', 'PaymentCalculationMethod', 'Amount', 'InvoiceNumber', 'PaymentRequestStatusDisplay'];
    public InvoiceMatDataSource = new MatTableDataSource<PaymentInvoiceModel>();
    bogusDataSource = new MatTableDataSource<any>(null);

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private http: HttpClient,
        private commonservice: CommonService,
        protected dialog: MatDialog
    ) { }

    ngOnInit() {
        this.httpdao = new HttpDAO(this.http);
        this.InitForm();
        this.paymentRequestForm = this._formBuilder.group({
            ddlService: [''],
            dtpFromDate: ['', [Validators.required, this.RangeDateValidator, this.MinDateValidator]],
            dtpToDate: ['', [Validators.required, this.RangeDateValidator, this.MinDateValidator]],
            ddlFacility: [''],
            chkAll: [false]
        });
        this.LoadConfiguration();
    }

    private InitForm() {
        this.isrobj = new InvoiceSearchRequest();
        this.lstaprm = new ListApprovePaymentRequestModel();
        this.lstaprm.ModifiedBy = this.commonservice.GetUser();
    }

    public LoadAvailableFacilities() {
        const searchRequest: SearchPaginationRequest = {
            OrderBy: 'name',
            OrderByAsc: true,
            PageNumber: 0,
            PageSize: 0,
            Params: [
                {
                    Name: 'user',
                    Value: this.commonservice.GetUserObject().UserId
                }
            ]
        };
        const encrypteddata = this.commonservice.E_FP_RequestData(JSON.stringify(searchRequest));
        merge().pipe(
            startWith({}),
            switchMap(() => {
                return this.httpdao!.postData(APIConstant.API_SEARCH_FACILITIES, { "Data": encrypteddata });
            }),
            map(data => {
                const dectypted_data = this.commonservice.D_FP_ResponseData(data);
                const rdata = JSON.parse(dectypted_data);
                let res: DataResult<SearchPaginationResult<Facility>> = rdata;
                this.commonservice.StopProgressBar();
                if (res.Success) {
                    this.AvailableFacilities = res.Data.Results;
                    if (this.AvailableFacilities.length == 1) {
                        this.LoadServiceByFacility(-1);
                    }
                } else {
                    MessageBox.ShowError(this.dialog, data.Message || 'An error has been occured while getting available Facilities');
                }
                return data;
            }),
            catchError(() => {
                this.commonservice.StopProgressBar();
                return observableOf([]);
            })
        ).subscribe();
    }

    public ddlFacility_Change(event) {
        if (event.target.value === '' || event.target.value === null || event.target.value === undefined) {
            this.AvailableServices = [];
            this.paymentRequestForm.get('ddlService').setValue('');
            this.SelectedServices = [];
            return;
        }
        const fid = parseInt(event.target.value, 10);
        this.LoadServiceByFacility(fid);
    }

    private LoadConfiguration() {
        this.commonservice.StartProgressBar();
        const self = this;
        forkJoin(
            this.httpdao!.getSingleData(APIConstant.API_GET_CONFIGURATION + environment.ENVKEY),
        ).pipe(
            map(([conf]) => {
                return { conf };
            }),
            catchError(() => {
                return observableOf(null);
            })
        ).subscribe(res => {
            self.configuration = res.conf.Data;
            this.LoadAvailableFacilities();
        });
    }

    private LoadServiceByFacility(fid: number) {
        let searchRequest: SearchPaginationRequest = null;
        if (fid === -1) {
            const currentUser = <User>JSON.parse(this.commonservice.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
            searchRequest = {
                OrderBy: 'name',
                OrderByAsc: true,
                PageNumber: 0,
                ViewColumns: ['status'],
                PageSize: 0,
                Params: [{ Name: 'facility_staff_member_user_id', Value: currentUser.UserId }]
            };
        }
        else {
            searchRequest = {
                OrderBy: 'name',
                OrderByAsc: true,
                PageNumber: 0,
                ViewColumns: ['status'],
                PageSize: 0,
                Params: [{ Name: 'facility_id', Value: fid }]
            };
        }

        merge().pipe(
            startWith({}),
            switchMap(() => {
                this.serviceSpinner = true;
                this.paymentRequestForm.get('ddlService').disable();
                return this.httpdao!.postData(APIConstant.API_SEARCH_SERVICES, searchRequest);
            }),
            map(data => {
                let res: DataResult<SearchPaginationResult<CommonResponse>> = data;
                if (res.Success) {
                    this.AvailableServices = res.Data.Results;
                } else {
                    MessageBox.ShowError(this.dialog, data.Message || 'An error has been occured while getting services of Facilities');
                }
                this.serviceSpinner = false;
                this.paymentRequestForm.get('ddlService').enable();
                return data;
            }),
            catchError(() => {
                this.serviceSpinner = false;
                return observableOf([]);
            })
        ).subscribe();
    }

    private SearchPaymentRequest() {
        const ret = this.paymentRequestForm.valid;
        if (ret === false) {
            return;
        }
        this.paymentRequestForm.get('chkAll').setValue(false);
        const currentUser = <User>JSON.parse(this.commonservice.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
        const ServiceIds: number[] = [];
        for (let i = 0; i < this.SelectedServices.length; i++) {
            ServiceIds.push(this.SelectedServices[i].Id);
        }
        const ngbToDate = this.paymentRequestForm.get('dtpToDate').value;
        const toDt: Date = new Date(Date.UTC(ngbToDate.year, ngbToDate.month - 1, ngbToDate.day));

        const ngbStartDate = this.paymentRequestForm.get('dtpFromDate').value;
        const fromDt: Date = new Date(Date.UTC(ngbStartDate.year, ngbStartDate.month - 1, ngbStartDate.day));

        if (this.isrobj === null) {
            this.isrobj = new InvoiceSearchRequest();
        }
        this.isrobj.ServiceIds = ServiceIds;
        this.isrobj.FacilityStaffMemberUserId = currentUser.UserId;
        this.isrobj.FromDate = fromDt;
        this.isrobj.ToDate = toDt;
        this.isrobj.FacilityId = this.paymentRequestForm.get('ddlFacility').value;
        if (this.isrobj.FacilityId === '-1') {
            this.isrobj.FacilityId = '';
        }

        merge().pipe(
            startWith({}),
            switchMap(() => {
                this.commonservice.StartProgressBar();
                return this.httpdao!.postData(APIConstant.API_SEARCH_PAYMENT_REQUEST, this.isrobj);
            }),
            map(res => {
                if (res.Success) {
                    this.PaymentInvoinceData = res.Data;
                    for (let i = 0; i < this.PaymentInvoinceData.length; i++) {
                        const dtend = new Date(this.PaymentInvoinceData[i].PaymentPeriodEnd + 'Z');
                        this.PaymentInvoinceData[i].PaymentPeriodEndDisplay = DateHelper.format(dtend, 'DD-MMM-YYYY');

                        const dtstart = new Date(this.PaymentInvoinceData[i].PaymentPeriodStart + 'Z');
                        this.PaymentInvoinceData[i].PaymentPeriodStartDisplay = DateHelper.format(dtstart, 'DD-MMM-YYYY');

                        this.PaymentInvoinceData[i].PaymentRequestStatusDisplay = this.PaymentInvoinceData[i].PaymentRequestStatusId === 5 ?
                            'Pending'
                            : this.PaymentInvoinceData[i].PaymentRequestStatus;

                    }
                    this.InvoiceMatDataSource = new MatTableDataSource<PaymentInvoiceModel>(this.PaymentInvoinceData);
                } else {
                    MessageBox.ShowError(this.dialog, res.Message || 'An error has been occured while searching payment request');
                }
                this.commonservice.StopProgressBar();
                return res;
            }),
            catchError(() => {
                this.commonservice.StopProgressBar();
                return observableOf([]);
            })
        ).subscribe();
    }

    public btnApprove_Click(event) {
        this.CollectSelectedPendingPayment();
        if (this.lstaprm.ApprovePaymentRequests.length === 0) {
            MessageBox.ShowInfo(this.dialog, 'No payment statements were selected for approval');
        } else {
            if (this.lstaprm.ApprovePaymentRequests.length > 1) {
                MessageBox.ShowCancelContinue(this.dialog, 'Payment Approval Confirmation'
                    , 'Multiple payment statements have been selected.<br/> Are you sure you wish to continue?')
                    .subscribe(res => {
                        if (res.result.toLowerCase() === DialogResult.Continue) {
                            this.ShowConfirmApprove();
                        }
                    });
            } else {
                this.ShowConfirmApprove();
            }
        }
    }

    private ShowConfirmApprove() {
        MessageBox.ShowCancelContinue(this.dialog, 'Payment Approval Confirmation'
            , 'Upon confirmation, this payment statement will be approved. This operation is irreversible and' +
            ' will automatically generate a supplier tax invoice which will be used by Fitness Passport for payment.<br/>' +
            'Do you wish to continue?')
            .subscribe(res => {
                if (res.result.toLowerCase() === DialogResult.Continue) {
                    this.GetBlobReport();
                }
            });
    }

    private CollectSelectedPendingPayment() {
        this.lstaprm = new ListApprovePaymentRequestModel();
        this.lstaprm.ModifiedBy = this.commonservice.GetUser();
        this.lstApprovedPI = [];
        this.uploadfilecount = 0;
        for (let i = 0; i < this.PaymentInvoinceData.length; i++) {
            if (this.PaymentInvoinceData[i].CheckedForApproval === true) {
                this.lstApprovedPI.push(this.PaymentInvoinceData[i]);
                const aprm: ApprovePaymentRequestModel = new ApprovePaymentRequestModel();
                aprm.FacilityCode = this.PaymentInvoinceData[i].FacilityInfo.Code;
                aprm.PaymentRequestId = this.PaymentInvoinceData[i].PaymentRequestId;
                aprm.ServiceId = this.PaymentInvoinceData[i].ServiceId;
                this.lstaprm.ApprovePaymentRequests.push(aprm);
            }
        }
    }

    private ApprovePayment() {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                return this.httpdao!.postData(APIConstant.API_APPROVE_PAYMENT_INVOICE_REQUEST, this.lstaprm);
            }),
            map(res => {
                if (res.Success) {
                    MessageBox.ShowInfo(this.dialog, 'All selected payment statements are now approved and invoiced successfully')
                        .subscribe(res => {
                            if (res.result.toLowerCase() === DialogResult.Ok) {
                                this.SearchPaymentRequest();
                            }
                        });
                } else {
                    MessageBox.ShowError(this.dialog, res.Message || 'An error has been occured while searching payment request');
                }
                this.commonservice.StopGlobalProgressBar();
                return res;
            }),
            catchError(() => {
                this.commonservice.StopGlobalProgressBar();
                return observableOf([]);
            })
        ).subscribe();
    }

    public CheckingBox_Change(event, ele: PaymentInvoiceModel) {
        if (ele.PaymentRequestStatusId === 5) {
            ele.CheckedForApproval = event.target.checked;
        } else {
            MessageBox.ShowError(this.dialog, 'This payment is not Pending Status. Cannot select it.');
        }
    }

    public CheckingAll_Change(event) {
        const ret = event.target.checked;
        for (let i = 0; i < this.PaymentInvoinceData.length; i++) {
            if (this.PaymentInvoinceData[i].PaymentRequestStatusId === 5) {
                this.PaymentInvoinceData[i].CheckedForApproval = ret;
            }
        }
    }

    public sortChange(sort: Sort) {
        const activeSort: string = sort.active.toLowerCase();
        this.isrobj.OrderBy = activeSort === 'paymentperiodenddisplay' ? 'date' : 'name';
        this.isrobj.OrderByAsc = sort.direction === 'asc' ? false : true;
        this.SearchPaymentRequest();
    }


    public ddlService_Change(event) {
        this.SelectService(parseInt(event.target.value, 10));
    }

    private SelectService(serviceid: number) {
        if (serviceid === null || serviceid === undefined || serviceid === 0) {
            return;
        }
        let selectedservice = this.AvailableServices.find(item => item.Id === serviceid);
        this.SelectedServices.push(selectedservice);
        this.paymentRequestForm.get('ddlService').setValue('');
    }

    public ServiceDisabled(cr: CommonResponse) {
        let isBool = this.SelectedServices.some(ss => ss.Id === cr.Id);
        return isBool;
    }


    public UnsetSelectedService(ss: CommonResponse) {
        this.RemoveItemFromArray(this.SelectedServices, ss);
    }

    private RemoveItemFromArray(arr: Array<any>, item: any) {
        var index = arr.indexOf(item);
        if (index > -1) {
            arr.splice(index, 1);
        }
    }

    public RangeDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
        try {
            if (control.value !== undefined && control.parent !== undefined) {
                const ngbToDate = control.parent.get('dtpToDate').value;
                const enddate: Date = new Date(ngbToDate.year, ngbToDate.month - 1, ngbToDate.day);

                const ngbStartDate = control.parent.get('dtpFromDate').value;
                const startdate: Date = new Date(ngbStartDate.year, ngbStartDate.month - 1, ngbStartDate.day);
                if (startdate == null || enddate == null || control.value === null || control.value.year === undefined) {
                    return null;
                } else {
                    enddate.setHours(0, 0, 0, 0);
                    startdate.setHours(0, 0, 0, 0);
                    if (enddate < startdate) {
                        return { 'rangedateinvalid': true };
                    } else {
                        return null;
                    }
                }
            } else {
                return null;
            }
        } catch (ex) {
            return null;
        }
    }

    public MinDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
        if (control.value !== undefined && control.value !== "") {
            try {
                const ngbdt = control.value;
                if (ngbdt === null) {
                    return null;
                }
                const d: Date = new Date(ngbdt.year, ngbdt.month - 1, ngbdt.day);
                const dc = new Date(1900, 1, 1, 0, 0, 0);
                if ((d < dc || d.toString() === 'Invalid Date')
                    && control.value !== null) {
                    return { 'mindateinvalid': true };
                } else {
                    return null;
                }
            } catch (e) {
                return { 'mindateinvalid': true };
            }
        } else {
            return null;
        }
    }

    public IsControlInvalid(tmpformgroup: UntypedFormGroup, controlname: string): boolean {
        return (tmpformgroup.get(controlname).invalid
            && (tmpformgroup.get(controlname).dirty ||
                tmpformgroup.get(controlname).touched ||
                this.btnSearchClicked));
    }

    public btnSearch_Click(event) {
        this.btnSearchClicked = true;
        const ret = this.paymentRequestForm.valid;
        if (ret) {
            this.SearchPaymentRequest();
        }
    }

    public UpdateValidateDateRange(ctrnames: string[]) {
        for (let i = 0; i < ctrnames.length; i++) {
            this.paymentRequestForm.get(ctrnames[i]).updateValueAndValidity();
        }
    }

    private GetBlobReport() {
        this.commonservice.StartGlobalProgressBar();
        if (this.lstApprovedPI[this.uploadfilecount] === undefined) {
            this.ApprovePayment();
            return;
        } else {
            const blob: Blob = this.ppir.ExportReportToBlob(this.lstApprovedPI[this.uploadfilecount]);
            const event = {
                target: {
                    files: [blob],
                }
            };
            const invnum = this.GenerateInvoiceNumber(this.lstApprovedPI[this.uploadfilecount]);
            const filename = invnum + '.pdf';
            const keyid = this.lstApprovedPI[this.uploadfilecount].PaymentRequestId.toString();
            this.ReportUpload.fileEventUploadReports(event, 'pdf', filename, keyid);
        }
    }

    private GenerateInvoiceNumber(elem: PaymentInvoiceModel): string {
        const dtnow = DateHelper.format(this.commonservice.CurrentUTCServerDate, 'DDMMYY');
        const invnum = elem.FacilityInfo.Code + dtnow + elem.PaymentRequestId.toString();
        return invnum;
    }

    public AllCompleted(event) {
        const data: FpFileData = event;
        for (let i = 0; i < this.lstaprm.ApprovePaymentRequests.length; i++) {
            const arrret: string[] = data.originfilename.split('#');
            const keyid: string = arrret[1];
            const filename: string = arrret[0];
            if (this.lstaprm.ApprovePaymentRequests[i].PaymentRequestId.toString() === keyid) {
                const fullurl: string = data.filedata.Location;
                this.lstaprm.ApprovePaymentRequests[i].InvoiceAttachment = fullurl.split('/').pop();
                this.lstaprm.ApprovePaymentRequests[i].InvoiceFileName = filename;
                this.uploadfilecount++;
                this.GetBlobReport();
                console.log(this.uploadfilecount);
            }
        }
    }

    public saveAs(elem: PaymentInvoiceModel) {
        let fullurl: string = '';
        const region = this.commonservice.D_FP_AES256(this.configuration.AwsRegion);
        const bucket = this.commonservice.D_FP_AES256(this.configuration.Bucket);
        fullurl = 'https://s3.' + region + '.amazonaws.com/' + bucket + '/Reports/' + elem.InvoiceAttachment;
        merge().pipe(
            startWith({}),
            switchMap(() => {
                return Utils.getFileBlob(fullurl);
            }),
            map(blob => {
                const url = URL.createObjectURL(blob);
                const linkElem = document.createElement('a');
                linkElem.setAttribute('href', url);
                linkElem.setAttribute('download', elem.InvoiceFileName);

                if (typeof linkElem.download === 'string') {
                    document.body.appendChild(linkElem); // Firefox requires the link to be in the body

                    if (document.createEvent) {
                        const event = document.createEvent('MouseEvents');
                        event.initEvent('click', true, true);
                        linkElem.dispatchEvent(event);
                    } else {
                        linkElem.click();
                    }

                    document.body.removeChild(linkElem); // remove the link when done
                } else if (typeof navigator.msSaveBlob === 'function') {
                    // IE does not support 'download' attribute of <a> element. Use the built-in "msSaveBlob" method instead.
                    if (!navigator.msSaveBlob(blob, elem.InvoiceFileName)) {

                    }
                } else {
                    // Fallback to open the file from address bar.
                    location.replace(url);
                }
            }),
            catchError(() => {
                this.commonservice.StopGlobalProgressBar();
                return observableOf([]);
            })
        ).subscribe();
    }

}
