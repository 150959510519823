import { CommonService } from 'src/app/services/common.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpDAO } from './httpdao.service';
import { Observable } from 'rxjs';
import { DataResult, MembershipPackageTypeModel, CommonData, NormalMembershipSuspension, ResultModel } from 'src/app/models';
import { APIConstant } from 'src/app/constant';
import { CommonDataType } from 'src/app/enums';
import { CommonDataService } from './common-data.service';
import { TypeFeeCancellation } from '@fp/models/TypeFeeCancellation.model';
import { DebitCreditReasons } from '@fp/models/debit-credit-reasons.model';

@Injectable({
    providedIn: 'root'
})
export class MembershipService {
    private httpDao: HttpDAO | null;

    constructor(
        httpClient: HttpClient,
        private commonSVC: CommonDataService,
        private cmsvc: CommonService
    ) {
        this.httpDao = new HttpDAO(httpClient);
    }

    getMembershipPackageTypeByMember(MemberId: number): Observable<DataResult<MembershipPackageTypeModel>> {
        var user = this.cmsvc.GetUser();
        var dateTime = new Date();
        const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify({ 'id': MemberId.toString(), 'modifiedBy' :  user, 'name' : dateTime.toLocaleString()}));
        return this.httpDao.postData(APIConstant.API_GET_MEMBERSHIP_PACKAGE_TYPE_BY_MEMBER, { "Data": encrypteddata });
    }

    getSortOfMembership(): Observable<DataResult<CommonData[]>> {
        return this.commonSVC.getData(CommonDataType.SortOfMembership);
    }

    getMembershipSuspensionReason(): Observable<DataResult<CommonData[]>> {
        return this.commonSVC.getData(CommonDataType.MembershipSuspensionReason);
    }

    getMembershipSuspensionType(): Observable<DataResult<CommonData[]>> {
        return this.commonSVC.getData(CommonDataType.MembershipSuspensionType);
    }

    getMembershipSuspension(id: number): Observable<DataResult<NormalMembershipSuspension>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_MEMBERSHIP_SUSPENSION + id);
    }

    saveMembershipSuspension(data: NormalMembershipSuspension): Observable<ResultModel> {
        return this.httpDao.postData(APIConstant.API_SAVE_MEMBERSHIP_SUSPENSION, data);
    }

    getNumberOfMembershipChangeByMember(MemberId: number): Observable<ResultModel> {
        return this.httpDao.getSingleData(APIConstant.API_GET_NUMBEROF_MEMBERSHIP_CHANGE_BY_MEMBER + MemberId);
    }

    getTotalUploadPhotoMember(memberId: number): Observable<DataResult<number>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_TOTAL_UPLOAD_PHOTO_MEMBER + memberId);
    }

    getTypeFeeCancellation(memberId: number): Observable<DataResult<TypeFeeCancellation>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_TYPE_FEE_CANCEL + memberId);
    }

    calculateCancelFee(data: TypeFeeCancellation): Observable<DataResult<TypeFeeCancellation>> {
        return this.httpDao.postData(APIConstant.API_CALCULATE_CANCEL_FEE, data);
    }

    cancelSuspension(data: NormalMembershipSuspension): Observable<ResultModel> {
        const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(data));
        const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
        return this.httpDao.postData(APIConstant.API_CANCEL_SUSPENSION, { "Data": encrypteddata, "Header": encryptedtoken });
    }

    getDebitCreditReasons(): Observable<DataResult<DebitCreditReasons>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_DEBIT_CREDIT_REASONS);
    }

}
