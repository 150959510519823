import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonService } from '@fp/services';

@Component({
    selector: 'app-employer-package-price-updates',
    templateUrl: './package-price-updates.component.html',
    styleUrls: ['./package-price-updates.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployerPackagePriceUpdatesComponent implements OnInit {

    constructor(private commonservice: CommonService) { }

    ngOnInit() {
        this.commonservice.Header.title="Package Price Update";
    }

}
