import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { EmployerPage } from '../../../models/employerpagemodel';

@Component({
  selector: 'app-previewemployer',
  templateUrl: './previewemployer.component.html',
  styleUrls: ['./previewemployer.component.css']
})
export class PreviewemployerComponent implements OnInit {

  public emppage: any;

  constructor(private commonservice: CommonService) {

  }

  ngOnInit() {
    this.emppage = JSON.parse(localStorage.getItem("employerpage"));

  }

}
