<div class="search-wrapper">
    <div class=" search-wrapper-content">
        <div class="back-to-form-wrapper ">
            <a href="/employer_details" class="d-flex align-items-center"><i class="icon-previous"></i> <span
                    class="d-none d-md-inline">Back to Form</span></a>
        </div>
        <div class="search-filed form-wrapper-new">
            <div class="input-group  input-group-new ">
                <div class="input-group-prepend">
                    <i class="fas fa-search"></i>
                </div>
                <input type="text" class="form-control" placeholder="Search by Suburb or Postcode"
                    [(ngModel)]='mapLocationSearch' (keydown.enter)='searchBarFunctionality()'>
                <div class="input-group-append bg-primary filter-button" (click)="filterSearchResultPopup()">
                    <span class="icon-filter"></span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="fp-map d-flex max-w100 ">
    <agm-map id="map" #map [minZoom]="4" [(zoom)]="location.zoom" class="w-100" [(latitude)]="location.lat"
        [(longitude)]="location.lng" [usePanning]='true'>
        <agm-marker-cluster
            imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
            <div *ngFor="let facility of mapDataSource">
                <agm-marker [latitude]="facility.Address.Latitude" [longitude]="facility.Address.Longitude"
                    (markerClick)="closePreviousInfoWindow(infoWindow)" [title]="facility.Name"
                    [iconUrl]="{url: iconUrl, scaledSize: {width: 26.38, height: 60}}">
                    <agm-info-window #infoWindow>
                        <ng-container
                            *ngTemplateOutlet="tmplFacilityDetails; context:{f: facility, displayServices: false}">
                        </ng-container>
                    </agm-info-window>
                </agm-marker>
            </div>
        </agm-marker-cluster>
    </agm-map>
    <div class="centre" *ngIf="mapIsLoading">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <div class="map-list-view-btn">
        <button class="btn btn-new btn-primary" (click)="goToListView('map-list-view')"><i class="fa fa-list mr-3"></i>
            List View</button>
    </div>

</div>

<ng-template #tmplFacilityDetails let-f="f" let-displayServices="displayServices">
    <div class="facility-dialog-wrapper" >
        <p><strong>{{f.Name}}</strong></p>
        <p>{{f.Address.StreetAddress1}}</p>
        <p>{{f.Suburb.Name}} {{f.Postcode.Code}}</p>
        <p><strong>Phone: </strong>{{f.FacilityPhoneNumber}}</p>
        <div *ngIf="displayServices == null || (displayServices && f.Services?.length > 0)">
            <p><strong>Services:</strong></p>
            <p *ngFor="let s of f.Services">
                {{s.ServiceName}}
            </p>
        </div>
        <div *ngIf="f.Is24Hour || f.FacilityStaffedHour">
            <p><strong>Operating Hours: </strong></p>
            <div *ngIf="f.Is24Hour;else tmplStaffedHours">
                <p>Open 24 hours</p>
            </div>
            <ng-template #tmplStaffedHours>
                <div>
                    <ul>
                        <li *ngIf="f.FacilityStaffedHour.MondayFrom !== null">Mon:
                            {{formatTime(f.FacilityStaffedHour.MondayFrom)}} to
                            {{formatTime(f.FacilityStaffedHour.MondayTo)}}</li>
                        <li *ngIf="f.FacilityStaffedHour.TuesdayFrom !== null">Tue:
                            {{formatTime(f.FacilityStaffedHour.TuesdayFrom)}} to
                            {{formatTime(f.FacilityStaffedHour.TuesdayTo)}}</li>
                        <li *ngIf="f.FacilityStaffedHour.WednesdayFrom !== null">Wed:
                            {{formatTime(f.FacilityStaffedHour.WednesdayFrom)}} to
                            {{formatTime(f.FacilityStaffedHour.WednesdayTo)}}</li>
                        <li *ngIf="f.FacilityStaffedHour.ThursdayFrom !== null">Thu:
                            {{formatTime(f.FacilityStaffedHour.ThursdayFrom)}} to
                            {{formatTime(f.FacilityStaffedHour.ThursdayTo)}}</li>
                        <li *ngIf="f.FacilityStaffedHour.FridayFrom !== null">Fri:
                            {{formatTime(f.FacilityStaffedHour.FridayFrom)}} to
                            {{formatTime(f.FacilityStaffedHour.FridayTo)}}</li>
                        <li *ngIf="f.FacilityStaffedHour.SaturdayFrom !== null">Sat:
                            {{formatTime(f.FacilityStaffedHour.SaturdayFrom)}} to
                            {{formatTime(f.FacilityStaffedHour.SaturdayTo)}}</li>
                        <li *ngIf="f.FacilityStaffedHour.SundayFrom !== null">Sun:
                            {{formatTime(f.FacilityStaffedHour.SundayFrom)}} to
                            {{formatTime(f.FacilityStaffedHour.SundayTo)}}</li>
                    </ul>
                </div>
            </ng-template>
        </div>
        <p><strong>Access Card Required: </strong>{{f.IsAccessCard ? 'Yes' : 'No'}}</p>
        <p *ngIf="f.IsAccessCard"><strong>Access Card Fee:
            </strong>{{Utils.convertPriceFormat(f.AccessCardFee)}}</p>
        <a class="btn btn-primary facility-dialog-button" href="{{f.WebsiteUrl}}" target="_blank">View Website</a> <br> <br>    
        <div *ngIf="f.AdditionalInfo" class="facility-additional-info">
            <button class="btn btn-primary facility-dialog-button" (click)="openCloseAdditionalInfo(f.FacilityId)">Additional Info</button> <br> <br>
            <span *ngIf="f.IsShownAdditionalInfo">
                {{f.AdditionalInfo}}
            </span>
        </div>
        <div class="falicliy-activity-wrapper" *ngIf="f.Activities.length>0">
            <div class="facility-activity-icon" *ngFor="let activity of f.Activities">
                <img src="./assets/images/facility-icon/{{activity.IconFileName}}.png" alt="Altitude" class="img-fluid" tooltip="{{activity.ActivityToolTip}}">
            </div>
        </div>
    </div>
</ng-template>