import { Component, Injector, ViewChild, ViewEncapsulation, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MemberFormBase } from 'src/app/components/base';
import { DialogResult } from 'src/app/components/common-dialog/common-dialog.component';
import { MemberPersonalInfoComponent } from 'src/app/components/members/shared';
import { CommonMessage, RouterConstant } from 'src/app/constant';
import { Utils } from 'src/app/helpers';
import { Member, ResultModel } from 'src/app/models';
import { MessageBox } from 'src/app/services/common-dialog.service';
import { MemberService } from 'src/app/services/member.service';
import { EMemberStatus } from 'src/app/enums';

@Component({
    selector: 'app-member-primary-personal-details',
    templateUrl: './primary-personal-details.component.html',
    styleUrls: ['./primary-personal-details.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: MemberFormBase, useExisting: MemberPrimaryPersonalDetailsDialogComponent }]
})
export class MemberPrimaryPersonalDetailsDialogComponent extends MemberFormBase implements AfterViewChecked {

    CommonMessage = CommonMessage;
    timeStamp = new Date().toISOString().replace(/[-:\.]/g, '');
    hasInit: boolean = false;

    @ViewChild(MemberPersonalInfoComponent) personalInfoSection: MemberPersonalInfoComponent;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            PersonalInfo: MemberPersonalInfoComponent.getFormGroup()
        });
    }

    constructor(injector: Injector,
        public activeModal: NgbActiveModal,
        private svc: MemberService,
        private router: Router,
        private cdRef: ChangeDetectorRef) {
        super(injector);
        this.async = false;
        this.form = MemberPrimaryPersonalDetailsDialogComponent.getFormGroup();
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
        if(!this.hasInit) {
            if (this.data.model.MemberId > 0) {
                this.personalInfoSection.PatchValue(this.data.model, { emitEvent: false });
                this.personalInfoSection.personalDetails.StatusDiv.style.setProperty('display', 'none');
                this.personalInfoSection.personalDetails.btnUploadPhoto_Click = (e) => {
                    if (this.data.model.MemberStatusId === EMemberStatus.New) {
                        return;
                    } else {
                        e.preventDefault();
                        e.stopPropagation();
                        
                        var changePhotoMessage = '';

                        if(this.data.model.IsMobileAppEnabled){
                            changePhotoMessage = 'Photos can be uploaded in the Manage Membership Cards Section. Do you wish to continue?';
                        }
                        else{
                            changePhotoMessage = 'Changing your photo may require creation of a new membership card. Photos can be uploaded in the Manage Membership Cards Section. Do you wish to continue?';
                        } 

                        MessageBox.ShowYesNo(this.dialog, changePhotoMessage).subscribe(
                            (dialogResult) => {
                                if (dialogResult.result.toLowerCase() === DialogResult.Yes) {
                                    this.router.navigate([RouterConstant.NAVIGATOR_MEMBERSHIP_CARDS]);
                                    this.activeModal.close();
                                }
                            }
                        );
                    }
                };
                this.hasInit = true;
            }
        }
    }

    OnLoad() {
        super.OnLoad();
    }

    //Author: Yen
    //Handle navigate when click on Upload Photo Button
    LoadComplete() {
        this.getControl(['PersonalInfo', 'PersonalDetails', 'DateOfBirth']).disable({ onlySelf: true, emitEvent: false });
        
        super.LoadComplete();
    }

    private processDataForSubmission(source: Member) {
        const target = Object.assign({}, source);
        target.EmergencyContactNo = "+" + Utils.convertPhoneToInternationalFormatWithCountryCode(target.EmergencyContactNo);
        target.MobileNumber = "+" + Utils.convertPhoneToInternationalFormatWithCountryCode(target.MobileNumber);
        if (target.Employer) {
            target.EmployerId = target.Employer.EmployerId;
            delete target.Employer;
        }
        if (target.MemberType) {
            target.MemberTypeId = target.MemberType.MemberTypeId;
            delete target.MemberType;
        }
        if (target.MemberStatus) {
            target.MemberStatusId = target.MemberStatus.MemberStatusId;
            delete target.MemberStatus;
        }
        if (target.FavouredFacility) {
            target.FavouredFacilityId = target.FavouredFacility.FacilityId;
            delete target.FavouredFacility;
        }
        if (target.ExistingFacility) {
            target.ExistingMemberFacilityId = target.ExistingFacility.FacilityId;
            delete target.ExistingFacility;
        }
        if (target.MedicalAnswers instanceof Array) {
            target.MedicalAnswers.forEach(answer => {
                delete answer.MedicalQuestion;
            });
        }
        // if (target.Address) {
        //     target.Address.StreetAddress1 = target.Address.StreetAddress.Text;
        // }
        if (target.Address && target.Address.StreetAddress && target.Address.StreetAddress.Text) {
            target.Address.StreetAddress1 = target.Address.StreetAddress.Text;
        }else if(target.Address && target.Address.StreetAddress && !target.Address.StreetAddress.Text){
            target.Address.StreetAddress1 = String(target.Address.StreetAddress) 
        }
        if (target.FamilyMembers instanceof Array) {
            target.FamilyMembers = target.FamilyMembers.map(member => {
                return this.processDataForSubmission(member);
            });
        }
        
        delete target.Suburb;
        delete target.Postcode;
        delete target.State;
        delete target.Country;
        for (const key in target) {
            if (target.hasOwnProperty(key) && target[key] == null) {
                delete target[key];
            }
        }
        return target;
    }

    //Author: Yen
    btnSubmit_Click() {
        this.Validate();
        if (!this.valid ||
            this.personalInfoSection.addressContactDetails.isEmailDuplicated ||
            this.personalInfoSection.addressContactDetails.isMPDuplicated) {
            this.commonSvc.ShowPopupValidator(this.dialog);
            return;
        }
        this.data.model = this.applyValue(this.data.model, this);
        this.data.model.MobileNumber = this.personalInfoSection.addressContactDetails.getControl('MobileNumber').value;
        const processedData = this.processDataForSubmission(this.data.model);
        this.Invoke(
            this.svc.updateForMember(processedData),
            {
                onSuccess: (res: ResultModel) => {
                    if (res.Success) {
                        this.SaveMemberAddress(this.data.model.MemberId);
                    } else {
                        console.error(res);
                        this.HandleResponseError(res);
                    }
                }
            }
        );
    }

    private SaveMemberAddress(memberid: number) {
        this.commonSvc.StopProgressBar();
        this.Invoke(
            this.svc.updateaddressfamilyformember(memberid),
            {
                onSuccess: (res: ResultModel) => {
                    if (res.Success) {
                        this.activeModal.close({ returnValue: this.data.model });
                    } else {
                        this.HandleResponseError(res);
                    }
                }
            }
        );
    }
}