import { HttpErrorResponse } from "@angular/common/http";
import { EventEmitter, Injector, Type, ChangeDetectorRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CustomMessageBox, Logger, StringHelper } from "@fp/helpers";
import { ResultModel } from "@fp/models";
import { CommonService, MessageBox } from "@fp/services";
import { forkJoin, Observable } from "rxjs";
import {
  catchError,
  finalize,
  map,
  shareReplay,
  takeUntil,
} from "rxjs/operators";

/**
 * An abstract class that provides the helper methods to invoke one or more services and
 * handle success or failure of the service call response with custom handlers.
 * @abstract
 * @class FPAbstractComponent
 */
export abstract class FPAbstractComponent {
  /**
   * Track the number of requests across the components that inherit the `FPAbstractComponent` class.
   * @private
   * @static
   * @memberof FPAbstractComponent
   */
  private static requestQueueCount = 0;
  protected get _requestQueueCount() {
    return FPAbstractComponent.requestQueueCount;
  }
  protected readonly dialog: MatDialog;
  protected readonly _logger: Logger;
  readonly changeDetectorRef: ChangeDetectorRef;

  protected get injector() {
    return this._injector;
  }
  protected readonly _serviceInvoker = {
    Invoke<T, R>(type: Type<T>, fn: (svc: T) => R) {
      return fn(this.GetServiceInstance(type));
    },
    GetServiceInstance: <T>(type: Type<T>): T => this._injector.get(type),
  };
  protected readonly unsubscribeEvent = new EventEmitter();

  constructor(private readonly _injector: Injector) {
    this.dialog = this._injector.get(MatDialog);
    this._logger = this._injector.get(Logger);
    this.changeDetectorRef = this._injector.get(ChangeDetectorRef);
  }

  private BeginRequest(busyIndicator?: { start: () => void }) {
    this._logger.debug(this.constructor.name + " > Begin Request");
    if (busyIndicator !== null) {
      try {
        if (busyIndicator && typeof busyIndicator.start === "function") {
          busyIndicator.start();
        } else {
          FPAbstractComponent.requestQueueCount++;
          this._logger.debug(
            "BEGIN > %s, request count: %s",
            this.constructor.name,
            FPAbstractComponent.requestQueueCount
          );
          this._serviceInvoker.Invoke(CommonService, (svc) =>
            svc.StartGlobalProgressBar()
          );
        }
      } catch (err) {
        this._logger.warn(
          "Error occurred when starting the busy indicator.\n",
          err
        );
      }
    }
  }

  private EndRequest(busyIndicator?: { stop: () => void }) {
    this._logger.debug(this.constructor.name + " > End Request");
    if (busyIndicator !== null) {
      try {
        if (busyIndicator && typeof busyIndicator.stop === "function") {
          busyIndicator.stop();
        } else {
          FPAbstractComponent.requestQueueCount--;
          this._logger.debug(
            "END > %s, request count: %s",
            this.constructor.name,
            FPAbstractComponent.requestQueueCount
          );
          if (FPAbstractComponent.requestQueueCount <= 0) {
            this._serviceInvoker.Invoke(CommonService, (svc) =>
              svc.StopGlobalProgressBar()
            );
            FPAbstractComponent.requestQueueCount = 0;
          }
        }
      } catch (err) {
        this._logger.warn(
          "Error occurred when stopping the busy indicator.\n",
          err
        );
      }
    }
  }

  protected handleError(error, message?: string) {
    console.log("Error JSON from API: ", error);
    console.log("Error message: ", message);
    if (
      this.dialog.openDialogs.length === 0 ||
      !StringHelper.isNullOrEmpty(message)
    ) {
      message = message || "An unknown error has occurred";
      MessageBox.ShowError(this.dialog, message);
    }
    this._logger.error(error);
  }

  protected HandleResponseError(response: ResultModel) {
    let message = null;
    if (response && response.ErrorNumber !== 0 && response.Message) {
      message = response.Message;
    }
    this.handleError(response, message);
  }

  protected Invoke<TSource = any, TMap = any>(
    source: Observable<TSource>,
    opt: {
      onSuccess: (res: TSource | TMap | any) => void;
      onError?: (err: any) => void;
      onComplete?: () => void;
      projectionFn?: (res: TSource) => TMap;
      busyIndicator?: { start: () => void; stop: () => void };
    }
  ) {
    // console.log(source, opt)
    this.BeginRequest(opt.busyIndicator);
    return source
      .pipe(
        takeUntil(this.unsubscribeEvent),
        map((res: TSource): TSource | TMap => {
          return typeof opt.projectionFn === "function"
            ? opt.projectionFn(res)
            : res;
        }),
        catchError((e) => {
          throw e;
        }),
        finalize(() => {
          this.EndRequest(opt.busyIndicator);
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe({
        next: (res) => {
          opt.onSuccess(res);
        },
        error: (err) => {
          if (typeof opt.onError === "function") {
            opt.onError(err);
          } else {
            let msg = "An error occurred while trying to call a service";
            if (err instanceof HttpErrorResponse && err.error) {
              msg = err.error.message;
            } else if (err instanceof Response) {
              msg = err.statusText;
            }
            this.handleError(err, msg);
          }
        },
        complete: () => {
          if (typeof opt.onComplete === "function") {
            opt.onComplete();
          }
        },
      });
  }

  protected InvokeBatch<TSource = any, TMap = any>(
    source: Observable<TSource | any>[],
    opt: {
      onSuccess: (res: TSource[] | TMap) => void;
      onError?: (err: any) => void;
      onComplete?: () => void;
      projectionFn?: (res: TSource[]) => TMap;
      busyIndicator?: { start: () => void; stop: () => void };
    }
  ) {
    this.BeginRequest(opt.busyIndicator);
    return forkJoin(source)
      .pipe(
        takeUntil(this.unsubscribeEvent),
        map((res: TSource[]): TSource[] | TMap => {
          return typeof opt.projectionFn === "function"
            ? opt.projectionFn(res)
            : res;
        }),
        shareReplay(1),
        catchError((e) => {
          throw e;
        }),
        finalize(() => {
          this.EndRequest(opt.busyIndicator);
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe({
        next: (res) => {
          opt.onSuccess(res);
        },
        error: (err) => {
          if (typeof opt.onError === "function") {
            opt.onError(err);
          } else {
            let msg = "An error occurred while trying to call a service";
            if (err instanceof HttpErrorResponse && err.error) {
              msg = err.error.message;
            } else if (err instanceof Response) {
              msg = err.statusText;
            }
            this.handleError(err, msg);
          }
        },
        complete: () => {
          if (typeof opt.onComplete === "function") {
            opt.onComplete();
          }
        },
      });
  }

  /** @deprecated Use `Invoke` instead, with "busyIndicator = null" to disable the loading indicator. */
  protected InvokeNotProcess(
    source: Observable<any>,
    opt: { onSuccess: (res: any) => void; onError?: (err: any) => void }
  ) {
    this.Invoke(source, { ...opt, busyIndicator: null });
  }
}
