import { MessageBox } from "./../../../services/common-dialog.service";
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { Subject, merge, of as observableOf, Observable, forkJoin } from "rxjs";
import {
  debounceTime,
  startWith,
  switchMap,
  map,
  catchError,
  shareReplay,
  tap,
} from "rxjs/operators";
import { environment } from "src/environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { v4 as uuid } from "uuid";
import { MatDialog } from "@angular/material/dialog";
import { FPEvent } from "@fp/components/base";
import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { HttpDAO, CommonService } from "@fp/services";
import { APIConstant } from "@fp/constant";
import { Configuration } from "@fp/models/configuration.model";
import { FileNameService } from "@fp/services/file-name.service";

export class FpFileEventR2 extends FPEvent {
  file: File;
}

@Component({
  selector: "app-fp-file-upload-r2",
  templateUrl: "./fp-file-upload-r2.component.html",
  styleUrls: ["./fp-file-upload-r2.component.css"],
})
export class FpFileUploadR2Component implements OnInit {
  private conf: Configuration = new Configuration();

  public pg_subject: Subject<number> = new Subject();
  public upload_status_subject: Subject<string> = new Subject();
  public pgend_subject: Subject<any> = new Subject();
  public pgclose_subject: Subject<boolean> = new Subject();
  public Filevalue = "";
  public status = "";

  @Input() buttonlabel = "CLICK TO UPLOAD";
  @Input() isReadonly = false;
  @Input() filterFile = ".pdf,image/*,.csv";
  @Input() fileCategory = "";
  @Input() ngClassLabel = "";
  @Input() ngInputFile = "custom-file w-170px";
  @Input() maxfilesize = "";
  @Input() columnParams: string[];
  @Input() fileProcessingComponent = "";
  @Input() requiresCheck: boolean;
  /** Set to false if an UploadComplete function has been set that has a custom Validator attached. */
  @Input() isUploadCompletedDefault = true;
  /** Set to variable to allow disabling */
  @Input() isDisabled: boolean = false;
  @Input() showProgressBar: boolean = false;
  @Input() buttonStyle = { width: "18em", height: "2.8em" };

  @Output() UploadCompleted: EventEmitter<FpFileDataR2> = new EventEmitter<
    FpFileDataR2
  >();
  @Output() fileSelected = new EventEmitter<FpFileEventR2>(false);
  public percent = 0;
  public IsUploadCompleted = false;
  @ViewChild("content") popupcontent: ElementRef;
  @ViewChild("fpfileupload") fpfileupload: ElementRef;
  private filedata: FpFileDataR2 = { originfilename: "", filedata: null };
  private originfilename = "";
  private httpdao: HttpDAO | null;

  successFlag = false;
  documentName = "No File Selected";
  csvContent: string;
  col_status: string;
  col_flag: boolean;
  my_csv: string | ArrayBuffer;
  invalidMemberNumberRows = 0;
  blankRawVisitRows = 0;
  checkBlankMemberNumAndMemberId = 0;

  public uploadedFileName = [];

  constructor(
    private modalService: NgbModal,
    protected dialog: MatDialog,
    private http: HttpClient,
    protected fileNameSvc: FileNameService,
    private commonservice: CommonService
  ) {}

  ngOnInit() {
    this.httpdao = new HttpDAO(this.http);
    this.pg_subject.pipe(debounceTime(200)).subscribe((val) => {
      this.percent = val;
    });

    this.upload_status_subject.pipe(debounceTime(200)).subscribe((val) => {
      this.status = val;
    });

    this.pgend_subject.pipe(debounceTime(200)).subscribe((val) => {
      this.IsUploadCompleted = true;
      this.filedata.originfilename = this.originfilename;
      this.filedata.filedata = val;
      this.UploadCompleted.emit(this.filedata);
      this.pgclose_subject.next(true);
    });

    this.pgclose_subject.pipe(debounceTime(200)).subscribe((val) => {
      this.Close();
      this.ResetConfig();
      this.ResetFileInputValue();
    });
  }

  public GetConfiguration(): Observable<Configuration> {
    this.httpdao = new HttpDAO(this.http);
    this.startProgressBar();
    return forkJoin(
      this.httpdao!.getSingleData(
        APIConstant.API_GET_CONFIGURATION + environment.ENVKEY
      )
    ).pipe(
      map(([conf]) => {
        this.stopProgressBar();
        if (conf.Success) {
          return conf.Data;
        } else {
          MessageBox.ShowError(
            this.dialog,
            "Cannot get configuration for upload file"
          );
        }
      }),
      shareReplay(1),
      catchError((err) => {
        console.error(err);
        this.stopProgressBar();
        MessageBox.ShowError(
          this.dialog,
          "Cannot get configuration for upload file"
        );
        return observableOf([]);
      })
    );
  }

  // this function utilises the event of the input to uplaod the file to s3
  public fileEvent(event, checkNeeded) {
    const file = (<HTMLInputElement>event.target).files[0];
    const pageName = this.fileCategory;
    this.fileEventUsingFile(file, checkNeeded, pageName).subscribe((result) => {
      console.log(result);
    });
  }

  // this function utilises the file selected in the event of the input to upload the file to s3
  public fileEventUsingFile(file, checkNeeded, pageName): Observable<any> {
    return new Observable<boolean>((resolve) => {
      console.log(file);
      if (file !== undefined && file !== null) {
        this.originfilename = file.name;
        const filename = uuid() + "." + file.name.split(".").pop();
        if (this.fileCategory == "") {
          this.fileCategory = pageName;
        }
        this.fileNameSvc.changeUploadedFileName(
          file.name,
          filename,
          this.fileCategory,
          this.originfilename
        );
        merge()
          .pipe(
            startWith({}),
            switchMap(() => {
              return this.GetConfiguration();
            }),
            map((result) => {
              if (this.showProgressBar) {
                this.Open();
                this.upload_status_subject.next("Opening File");
              }

              const reader = new FileReader();
              reader.onload = () => {
                this.checkFile(
                  file,
                  filename,
                  reader.result,
                  checkNeeded
                ).subscribe((result) => {
                  resolve.next(result);
                });
              };
              reader.readAsText(file);
            }),
            catchError((err) => {
              console.error(err);
              this.pgclose_subject.next(true);
              return observableOf([]);
            })
          )
          .subscribe((data) => {});
      } else {
        resolve.next(true);
      }
    });
  }

  checkFile(file, fileName, fileContent, isCheckReq): Observable<boolean> {
    if (isCheckReq) {
      const lower_csv_data = fileContent.toLowerCase();
      this.upload_status_subject.next("Checking File Size");

      // checks the file size
      if (this.maxfilesize !== "") {
        const size = parseInt(this.maxfilesize, 10) * 1024 * 1024;
        if (file.size > size) {
          this.pgclose_subject.next(true);
          MessageBox.ShowError(
            this.dialog,
            "Maximum file size is " + this.maxfilesize + " MB"
          );
          this.col_flag = false;
          return observableOf(false);
        }
      }

      // checks if file is of correct format
      this.upload_status_subject.next("Checking File Type");
      if (
        !(file.type == "application/vnd.ms-excel" || file.type == "text/csv")
      ) {
        this.pgclose_subject.next(true);
        this.documentName = "No File Selected"; // name of the file gets removed so it is not shown on the page if format is wrong
        MessageBox.ShowInfo(
          this.dialog,
          "Only CSV files can be uploaded. Please try again by selecting a CSV file."
        );
        this.col_flag = false;
        return observableOf(false);
      }

      let contentArray = lower_csv_data.split(/\r|\n|\r/);
      let count = 0;

      // removes empty rows
      contentArray = contentArray.filter((item) => item !== "");
      contentArray = contentArray.filter((item) => item !== ",,,,");

      // checks if the file is empty
      if (contentArray.length <= 1) {
        this.pgclose_subject.next(true);
        MessageBox.ShowInfo(
          this.dialog,
          "The CSV file you are attempting to upload is either empty or corrupt. Please upload a different file. If the problem persists, use the template above"
        );
        this.col_flag = false;
        return observableOf(false);
      }

      // separates the content cells into separate array objects
      for (let i = 0; i < contentArray.length; i++) {
        let tempContent = contentArray[i].split(",");

        if (i > 0) {
          // checks the content of the cells specifically for upload raw visits
          this.upload_status_subject.next("Tallying Invalid Rows");
          if (this.fileCategory === "uploadRawVisits") {
            let memberNumber = tempContent[0];
            let memberId = tempContent[1];
            let serviceName = tempContent[3];
            let facilityCode = tempContent[4];
            let visitTime = tempContent[5];
            let visitCount = tempContent[6];

            if (
              serviceName === "" ||
              facilityCode === "" ||
              visitTime === "" ||
              visitCount === ""
            ) {
              this.blankRawVisitRows++;
            }

            if(!memberNumber && !memberId){
              this.checkBlankMemberNumAndMemberId++;
            }

            // if visit count is less than or equal to 0 - then mark row as invalid for upload
            if (visitCount <= 0) {
              this.invalidMemberNumberRows++;
            }
          }
        }

        if (i === 0) {
          const unmatchedColumns = [];

          for (let k = 0; k < this.columnParams.length; k++) {
              tempContent[k] = tempContent[k] ? tempContent[k].trim() : '';

              if (tempContent[k] !== this.columnParams[k]) {
                  unmatchedColumns.push(this.columnParams[k]);
              }
          }

          // Print only the columns not in tempContent
          const columnsNotInTempContent = this.columnParams.filter(column => !tempContent.includes(column));
          console.log("Columns not in tempContent: " + columnsNotInTempContent.join(', '));

          if (unmatchedColumns.length > 0) {
            this.pgclose_subject.next(true);
            this.col_flag = false;

            if(this.fileProcessingComponent === "NZFailedFileUploadVerification"){
              MessageBox.ShowInfo(
                this.dialog,
                'Missing or incorrect column(s) in the selected file. Please review required column(s): "' + columnsNotInTempContent.join(', ') + '" and try again.<br><br>' +
                "Columns should be in the order of:<br>" +
                "id, created_at, charge_date, amount, description, currency, status," +
                "amount_refunded, reference, transaction_fee, payout_date, app_fee," +
                "fund_settlement, links.mandate, links.creditor, links.payout, links.subscription," +
                "customer.id, customers.created_at, customers.email, customers.given_name," +
                "customers.family_name, customers.company_name, customers.address_line1," +
                "customers.address_line2, customers.address_line3, customers.city," +
                "customers.region, customers.postal_code, customers.country_code," +
                "customers.language, customers.swedish_identity_number," +
                "customers.active_mandates, transaction_fee_tax, metadata.BCF," +
                "metadata.member_ID, metadata.membership_ID," +
                "customers.metadata.member_ID, customers.metadata.membership_ID"
              );
            }
            else{
              MessageBox.ShowInfo(
                this.dialog,
                "Missing or incorrect column(s) in selected file. Please review required columns and try again."
              );
            }
            return observableOf(false);
          }
        }

        //For the case of BulkUploadDebitCredit. Check on last column is not required. PostDate can be empty
        else if (
          !(
            this.fileCategory === "uploadFailedTransactions" ||
            this.fileCategory === "uploadRawVisits"
          )
        ) {
          // -2 to avoid F.E check on the last 2 columns (Description and PostDate) can be empty
          for (let j = 0; j < tempContent.length - 2; j++) {
            if (tempContent[j] === "") {
              count++;
            }
          }
        }
        //Normal check for cases when the uploaded file is not BulkUploadDebitCredit
        else {
          for (let j = 0; j < tempContent.length; j++) {
            if (tempContent[j] === "") {
              count++;
            }
          }
        }
      }

      if (
        !(
          this.fileCategory === "uploadFailedTransactions" ||
          this.fileCategory === "uploadRawVisits"
        )
      ) {
        if (count > 0) {
          this.pgclose_subject.next(true);
          this.col_flag = false;
          MessageBox.ShowInfo(
            this.dialog,
            `Your upload file contains ${count} empty cell/s. Please fix these and upload again.`
          );
          return observableOf(false);
        }
      }

      if (this.fileCategory == "uploadRawVisits") {
        if (this.invalidMemberNumberRows > 0 || this.blankRawVisitRows > 0 || this.checkBlankMemberNumAndMemberId > 0) {
          this.pgclose_subject.next(true);
          this.col_flag = false;
          MessageBox.ShowInfo(
            this.dialog,
            `The CSV you have tried to upload does not follow the predesigned template. Please download the template using the link on this page and try again.`
          );
          return observableOf(false);
        }
      }
      if (this.isUploadCompletedDefault) {
        MessageBox.ShowInfo(
          this.dialog,
          "The file you uploaded is valid. Please proceed to check its integrity by clicking the 'Check File Integrity' button."
        );
      }
    }
    this.col_flag = true;

    if ((this.col_flag && isCheckReq) || !isCheckReq) {
      this.upload_status_subject.next("Uploading File... ");
      this.fileNameSvc.$currentUploadedFileUuid.subscribe(
        (uploadedFileName) => (this.uploadedFileName = uploadedFileName)
      );
      this.originfilename = file.name;
      const fileEvent = new FpFileEventR2();
      fileEvent.file = file;
      this.fileSelected.emit(fileEvent);
      if (fileEvent.cancelled) {
        // Reset control if event was cancelled
        this.ResetFileInputValue();
        this.ResetConfig();
        return observableOf(false);
      }

      return this.uploadFile(file, fileName).pipe(
        map((res) => {
          //Reset control after file upload
          this.ResetFileInputValue();
          this.ResetConfig();
          return res;
        })
      );
    }
  }

  public Open() {
    this.modalService
      .open(this.popupcontent, {
        backdrop: "static",
        keyboard: false,
        centered: true,
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }

  uploadFile(file, filename): Observable<boolean> {
    return new Observable<boolean>((resolve) => {
      try {
        this.uploadToS3SignedURL(filename, file);
        resolve.next(true);
      } catch (e) {
        resolve.next(false);
      }
    });
  }

  // Upload method - signed URL
  private uploadToS3SignedURL(filename: string, file: File): void {
    this.commonservice
      .getUploadUrl(filename)
      .pipe(tap(() => this.startProgressBar()))
      .subscribe((res) => {
        this.stopProgressBar();
        this.uploadfileAWSS3(res, file)
          .pipe(
            tap((event) => this.handleUploadProgressMessage(event as any, file))
          )
          .subscribe();
      });
  }

  private uploadfileAWSS3(fileuploadurl, file): any {
    return this.httpdao.putDataWithoutToken(fileuploadurl, file);
  }

  private handleUploadProgressMessage(event: HttpEvent<any>, file: File) {
    switch (event.type) {
      case HttpEventType.Sent:
        this.pg_subject.next(0);
        if (process.env.NODE_ENV === "development") {
          console.log(`Uploading file "${file.name}" of size ${file.size}.`);
        }
        return;

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round((100 * event.loaded) / event.total);
        this.pg_subject.next(percentDone);
        if (process.env.NODE_ENV === "development") {
          console.log(`File "${file.name}" is ${percentDone}% uploaded.`);
        }
        return;

      case HttpEventType.Response:
        // The returned download URL has a bunch of token data attached.
        // We need to remove it, and put it into an object that contains a "Location" key
        let outputInfo = {
          Location: event.url.split("?")[0],
          url: event.url.split("?")[0],
        };
        this.pgend_subject.next(outputInfo);

        if (process.env.NODE_ENV === "development") {
          console.log(`File "${file.name}" was completely uploaded!`);
        }
        return;

      default:
        if (process.env.NODE_ENV === "development") {
          console.log(
            `File "${file.name}" surprising upload event: ${event.type}.`
          );
        }
        return;
    }
  }

  Close() {
    this.modalService.dismissAll();
  }

  private ResetConfig() {
    this.percent = 0;
    this.IsUploadCompleted = false;
    this.filedata = { originfilename: "", filedata: null };
    this.originfilename = "";
  }

  public ResetFileInputValue() {
    if (this.fpfileupload !== undefined) {
      this.fpfileupload.nativeElement.value = "";
    }
  }

  private startProgressBar() {
    if (this.commonservice.DashBoard) {
      this.commonservice.StartProgressBar();
    } else {
      this.commonservice.StartGlobalProgressBar();
    }
  }

  private stopProgressBar() {
    if (this.commonservice.DashBoard) {
      this.commonservice.StopProgressBar();
    } else {
      this.commonservice.StopGlobalProgressBar();
    }
  }
}

export class FpFileDataR2 {
  public originfilename: string;
  public filedata: any;
}
