import { Component, Injector, ViewChild, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MemberFormBase } from "../../../base";
import { MemberService } from "@fp/services";

export interface PeriodicElement1 {
  VisitDate: string;
  VisitTime: string;
  Facility: string;
  ServiceType: string;
  NumberofVisits: string;
  VisitType: string;
  VisitSource: string;
}

const ELEMENT_DATA1: PeriodicElement1[] = [
  {
    VisitDate: "08-03-2019",
    VisitTime: "10:20",
    Facility: "Facility",
    ServiceType: "ServiceType",
    NumberofVisits: "NumberofVisits",
    VisitType: "VisitType",
    VisitSource: "VisitSource"
  },
  {
    VisitDate: "08-03-2019",
    VisitTime: "10:20",
    Facility: "Facility",
    ServiceType: "ServiceType",
    NumberofVisits: "NumberofVisits",
    VisitType: "VisitType",
    VisitSource: "VisitSource"
  }
];

// ];
@Component({
  selector: "app-member-visits-payments",
  templateUrl: "./visits-payments.component.html",
  styleUrls: ["./visits-payments.component.css"]
})
export class MemberVisitsPaymentsComponent extends MemberFormBase
  implements OnInit {
  dataSource = ELEMENT_DATA1;
  displayedColumns: string[] = [
    "VisitDate",
    "VisitTime",
    "Facility",
    "ServiceType",
    "NumberofVisits",
    "VisitType",
    "VisitSource"
  ];

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      VisitsPayments: []
    });
  }
  constructor(
    injector: Injector,
    private route: ActivatedRoute,
    private memberSvc: MemberService,
    private router: Router
  ) {
    super(injector);
  }

  ngOnInit() { }


}
