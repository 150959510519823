<div class="container-fluid">
    <div class="pt-3 fpsideCont-px-3">
        <div class="mx-auto tabProgress w-50 d-none d-lg-flex mt-3">
            <div class="flex-fill text-center">
                <div class="card-icon rounded-circle mx-auto"
                    [ngClass]="{'bg-primary': _stepProgressBackgroundVisible(MemberSignupTabIndex.EmployerInfo)}">
                    <i *ngIf="_stepProgressCompleteIconVisible(MemberSignupTabIndex.EmployerInfo)"
                        class="fas fa-check text-light"></i>
                </div>
                <p class="card-text mb-3">Employer</p>
            </div>
            <div class="flex-fill text-center">
                <div class="card-icon rounded-circle mx-auto"
                    [ngClass]="{'bg-primary': _stepProgressBackgroundVisible(MemberSignupTabIndex.MembershipPackages)}">
                    <i *ngIf="_stepProgressCompleteIconVisible(MemberSignupTabIndex.MembershipPackages)"
                        class="fas fa-check text-light"></i>
                </div>
                <p class="card-text mb-3">Membership Packages</p>
            </div>
            <div class="flex-fill text-center">
                <div class="card-icon rounded-circle mx-auto"
                    [ngClass]="{'bg-primary': _stepProgressBackgroundVisible(MemberSignupTabIndex.PersonalDetails)}">
                    <i *ngIf="_stepProgressCompleteIconVisible(MemberSignupTabIndex.PersonalDetails)"
                        class="fas fa-check text-light"></i>
                </div>
                <p class="card-text mb-3">Personal Details</p>
            </div>
            <div class="flex-fill text-center">
                <div class="card-icon rounded-circle mx-auto"
                    [ngClass]="{'bg-primary': _stepProgressBackgroundVisible(MemberSignupTabIndex.FamilyMembers)}">
                    <i *ngIf="_stepProgressCompleteIconVisible(MemberSignupTabIndex.FamilyMembers)"
                        class="fas fa-check text-light"></i>
                </div>
                <p class="card-text mb-3">Additional Members</p>
            </div>
            <div class="flex-fill text-center">
                <div class="card-icon rounded-circle mx-auto"
                    [ngClass]="{'bg-primary': _stepProgressBackgroundVisible(MemberSignupTabIndex.ShoppingCart)}">
                    <i *ngIf="_stepProgressCompleteIconVisible(MemberSignupTabIndex.ShoppingCart)"
                        class="fas fa-check text-light"></i>
                </div>
                <p class="card-text mb-3">Shopping Cart</p>
            </div>
            <div class="flex-fill text-center">
                <div class="card-icon rounded-circle mx-auto"
                    [ngClass]="{'bg-primary': _stepProgressBackgroundVisible(MemberSignupTabIndex.PaymentDetails)}">
                    <i *ngIf="_stepProgressCompleteIconVisible(MemberSignupTabIndex.PaymentDetails)"
                        class="fas fa-check text-light"></i>
                </div>
                <p class="card-text mb-3">Payment Details</p>
            </div>
        </div>
        <div class="tabFPTheme mb-5" #tabfpstepper [style.height]="heighttabs">
            <mat-vertical-stepper #stepper [linear]="true">
                <mat-step [stepControl]="form.get('EmployerInfo')">
                    <form id="membrsignuptb0">
                        <ng-template matStepLabel>
                            <i class="icon-fp d-lg-none" [ngClass]="{'fas fa-check': employerInfo?.valid}"></i>Employer
                        </ng-template>
                        <app-member-employer-info [form]="form.get('EmployerInfo')" [data]="data" (resetEvent)="clearMembershipPackage($event)" #employerInfo>
                        </app-member-employer-info>
                    </form>
                </mat-step>
                <mat-step [stepControl]="form.get('MembershipPackages')">
                    <form id="membrsignuptb1">
                        <ng-template matStepLabel>
                            <i class="icon-fp d-lg-none"
                                [ngClass]="{'fas fa-check': membershipPackages?.valid}"></i>Membership
                            Packages
                        </ng-template>
                        <app-member-membership-packages [loadOnDemand]="true" [form]="form.get('MembershipPackages')"
                            [data]="data" #membershipPackages (buttonNextEvent)="buttonNextEventHandler($event)"></app-member-membership-packages>
                    </form>
                </mat-step>
                <mat-step [stepControl]="form.get('PersonalInfo')">
                    <form id="membrsignuptb2">
                        <ng-template matStepLabel>
                            <i class="icon-fp d-lg-none" [ngClass]="{'fas fa-check': personalInfo?.valid}"></i>Personal
                            Details</ng-template>
                        <app-member-personal-info [loadOnDemand]="true" [form]="form.get('PersonalInfo')" [data]="data"
                            #personalInfo></app-member-personal-info>
                    </form>
                </mat-step>
                <mat-step [stepControl]="form.get('FamilyMembersTab')">
                    <form id="membrsignuptb3">
                        <ng-template matStepLabel>
                            <i class="icon-fp d-lg-none"
                                [ngClass]="{'fas fa-check': familyMembers?.valid}"></i>Additional
                            Members</ng-template>
                        <app-member-family-members [loadOnDemand]="true" [form]="form.get('FamilyMembersTab')"
                            [data]="data"
                            (primaryMemberEditIconClick)="stepper.selectedIndex = MemberSignupTabIndex.PersonalDetails"
                            #familyMembers></app-member-family-members>
                    </form>
                </mat-step>
                <mat-step [stepControl]="form.get('ShoppingCart')">
                    <form id="membrsignuptb4">
                        <ng-template matStepLabel>
                            <i class="fas fa-check icon-fp d-lg-none"
                                [ngClass]="{'fas fa-check': shoppingCart?.valid}"></i>Shopping
                            Cart</ng-template>
                        <app-member-shopping-cart [loadOnDemand]="true" [form]="form.get('FamilyMembersTab')"
                            [data]="data" #shoppingCart (addFamilyMemberButtonClick)="familyMembers.AddMember()">
                        </app-member-shopping-cart>
                    </form>
                </mat-step>
                <mat-step [stepControl]="form.get('PaymentDetails')">
                    <form id="membrsignuptb5">
                        <ng-template matStepLabel>
                            <i class="icon-fp d-lg-none" [ngClass]="{'fas fa-check': paymentDetails?.valid}"></i>Payment
                            Details</ng-template>
                        <app-member-payment-details [loadOnDemand]="true" [form]="form.get('PaymentDetails')"
                            [data]="data" #paymentDetails></app-member-payment-details>
                    </form>
                </mat-step>
            </mat-vertical-stepper>
        </div>
        <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
            <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                style="display: none">
                Save as Draft</button>
            <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button" (click)="btnBack_Click()" *ngIf='canGoBack()'>
                <i class="fas fa-arrow-circle-left mr-2"></i>Back</button>
            <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="btnNext_Click()" [disabled]='disableNextButton()'>
                <i class="fas fa-arrow-circle-right mr-2"></i>Next</button>
        </div>
    </div>
</div>

<!-- Terms and Conditions Modal -->

<div class="modal fade" #termsConditionsModalctrl id="termsConditionsModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" #dlgTermsCons data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <ng-template #dlgTermsConsContent let-modal>
                <div class="modal-header" id="modalheader">
                    <h5 class="modal-title" id="exampleModalLabel">Terms and Conditions</h5>
                </div>
                <div class="modal-body">
                    <div class="fpscrolly py-3" id="fp-termscondText" [style.height]="heightTCText"
                        (scroll)="onScroll($event)">
                        <div [innerHtml]="termsAndConsContent" id="fp-tcContent"></div>
                        <div id="fp-tcEndContent">&nbsp;</div>
                    </div>
                    <div class="position-relative pt-4" id="chkTC">
                        <span class="font-weight-bold" style="color: red; text-align: center;">I have read and accepted the terms and
                            conditions<br />(in order to accept you must read to the<br /> bottom of the document)</span>
                        <div class="d-inline-block ml-3">
                            <div class="custom-control custom-checkbox">
                                <input [disabled]="disablechkTermCons" type="checkbox" class="custom-control-input"
                                    id="chkTermsCons" #chkTermsCons [checked]="isTermsAndConsAccepted"
                                    (change)="isTermsAndConsAccepted = $event.target.checked">
                                <label class="custom-control-label" for="chkTermsCons">Yes</label>
                            </div>
                        </div>
                    </div>
                    <div class="py-4 d-flex justify-content-center" id="btnTC">
                        <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                            data-dismiss="modal" (click)="modal.dismiss()">
                            <i class="fas fa-arrow-circle-left mr-2"></i>Back</button>
                        <div class="tooltip">
                            <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button"
                                [disabled]="!isTermsAndConsAccepted" data-dismiss="modal"
                                data-target="dlgTermsConsContent" (click)="btnSubmit_Click();modal.close();">
                                Submit</button>
                            <span *ngIf="disablechkTermCons" class="tooltiptext">Please read all of the Terms and
                                Conditions before accepting them.</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class="modal fade" #declarationModalctrl id="declarationModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Declaration</h5>
            </div>
            <div class="modal-body py-4">
                <p>I,
                    &lt;MEMBER NAME&gt;
                    <!--{{memberShipCardsSelected.FirstName + ' ' +
                    memberShipCardsSelected.LastName}}-->,
                    do solemnly and sincerely declare that I have undertaken an
                    extensive
                    search, and
                    cannot find the Fitness Passport card(s) with which I was issued.</p>
                <p>I understand that if the lost card is found that it will not be able to be used and will be
                    destroyed immediately.</p>
                <p>I understand that a breach of this declaration may result in a financial penalty consistent with
                    the penalties listed in the terms and conditions (Hyperlink to the FP T & C's page -
                    <a class="color-link" href="https://www.fitnesspassport.com.au/termsandconditions"
                        target="_blank">https://www.fitnesspassport.com.au/termsandconditions</a>)
                    of
                    Fitness Passport membership that I agreed to when I initially joined the program.</p>
                <p>I understand that a person who intentionally makes a false statement in a statutory declaration is
                    guilty of an offence under section 11 of the Statutory Declarations Act 1959, and I believe that
                    the statements in this declaration are true in every particular.</p>
                <p>I understand that $10 will be charged for each replacement card</p>
                <p>Declared on
                    &lt;DATE AND TIMESTAMP&gt;
                    <!--{{currentDate.getTime() | date: 'dd/MM/yyyy HH:mm'}}-->
                </p>
                <div class="py-4 d-flex justify-content-center">
                    <button id="ms_cards_newcard_btn_close"
                        class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                        data-dismiss="modal">
                        <i class="fas fa-arrow-circle-left mr-2"></i>Back</button>
                    <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button">
                        Accept</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" #changeMSPhotoModalctrl id="changeMSPhotoModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Change Membership Photo</h5>
            </div>
            <div class="modal-body py-4">
                <div class="fp-profile d-flex justify-content-center">
                    <div class="w-50">
                        <div *ngIf="1!=1"
                            class="bg-secondary fp-profilephoto d-flex justify-content-center align-items-center mx-auto ng-star-inserted">
                            <i class="fa fa-image fa-2x text-secondary"></i></div>
                        <div>
                            <div *ngIf="1==1" class="position-relative container">
                                <div class="fp-photologo mt-3 text-center">
                                    <img src="../../../../assets/images/avatar.png" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>
                        <div class="custom-file mt-2">
                            <input #txtUploadPhoto type="file" class="custom-file-input" id="txtUploadPhoto"
                                accept="image/*">
                            <label class="custom-file-label bg-primary text-light" for="txtAttachLogo"><i
                                    class="fas fa-upload mr-2"></i>Upload
                                Photo</label>
                        </div>
                    </div>
                </div>
                <div class="py-4 d-flex justify-content-center">
                    <button id="ms_cards_change_photo_close"
                        class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                        data-dismiss="modal">
                        <i class="fas fa-ban mr-2"></i>Cancel</button>
                    <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button">
                        <i class="fas fa-check-circle mr-2"></i>
                        Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- TODO: remove after implementation completed -->
<!-- #region DEBUG -->
<div *ngIf="isDebugging" class="tabFPTheme mt-5">
    <pre>Request count: {{ data.requestQueueCount }}</pre>
    <pre>Form valid: {{ form.valid }}</pre>
    <ngb-accordion>
        <ngb-panel id="pnlFormJson">
            <ng-template ngbPanelTitle>
                Form JSON
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <pre>{{ JSON.stringify(form.value, null, 4) }}</pre>
            </ng-template>
        </ngb-panel>
        <ngb-panel>
            <ng-template ngbPanelTitle>
                Data JSON
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <pre>{{ JSON.stringify(data.model, null, 4) }}</pre>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>
<!-- #endregion DEBUG -->