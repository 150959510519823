import { EServiceStatus } from 'src/app/enums';
import { PaymentCalculationMethod } from './payment-calculation-method.model';
import { Scanner, PhysicalDevice } from './scanner.model';
import { ServiceStatus } from './service-status.model';
import { ServiceType } from './service-type.model';
import { VisitRecordingMethod } from './visit-recording-method.model';
import { LinkApi } from './link-api.model';

export class Service {
    Id: number;
    Name: string;
    ServiceTypeId: number;
    ServiceType: ServiceType;
    ServiceStatusId: number | EServiceStatus;
    Status: ServiceStatus;

    // References
    FacilityId: number;
    PackageId: number;
}

export class ServiceDetails extends Service {
    MaxCapacity: number | null;
    CurrentCapacity: number | null;
    FacilitySqm: number | null;
    PaymentCalculationMethodId: number;
    PaymentCalculationMethod: PaymentCalculationMethod;
    VisitsAllowedPerMembership: number | null;
    MonthlyMembershipCost: number | null;
    PricePerVisit: number | null;
    OtherNotes: string;
    IsOffPeakService: boolean;
    OffPeakStartTime: string;
    OffPeakEndTime: string;
    VisitRecordingMethodIds: number[];
    VisitRecordingMethods: VisitRecordingMethod[];
    Scanners: Scanner[];
    PhysicalDevices: PhysicalDevice[];
    LinkApis: LinkApi[];
    IsLimitVisits: boolean;
    MaxVisitsPerPaymentPeriod: number | null;
    AdditionalVisitCharge: number | null;
    AdditionalInformation: string;
    HwmNumberMemberships: number = 0;
    HwmVisitsPerMembership: number = 0;
    HwmPricePerMembership: number = 0.0;
    HwmValue: number = 0;
    DeductionAmount: number = 0.0;
    HwmEffectiveDate = new Date(); 

    constructor() {
        super();
        this.IsOffPeakService = false;
        this.IsLimitVisits = false;
    }
}