import { CommonService } from 'src/app/services/common.service';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { FPAbstractComponent } from '@fp/components/base';
import { empty, merge } from 'rxjs';
import { catchError, debounceTime, startWith, switchMap } from 'rxjs/operators';
import { APIConstant } from 'src/app/constant/apiconstant';
import { RouterConstant } from 'src/app/constant/routerconstant';
import { CommonResponse, DataResult, Employer, Facility, RequestPagination, SearchPaginationRequest, SearchPaginationResult, User, ParamPagination } from 'src/app/models';
import { EmployerService, FacilityService, MessageBox, RegionService } from 'src/app/services';
import { UserService } from 'src/app/services/admin/user/user.service';
import { Utils } from '@fp/helpers';

export class UserTransferData {
    id: number;
    filter: string;
    employerNameId?: number;
    facilitatorId?: number;
    employerCodeId?: number;
    employerParentId?: number;
    employerRegionId?: number;
}
@Component({
    selector: 'app-searchusers',
    templateUrl: './searchusers.component.html',
    styleUrls: ['./searchusers.component.css']
})
export class SearchusersComponent extends FPAbstractComponent implements OnInit, OnDestroy {
    parentFocus = 0;
    searchUsersByNameFG: UntypedFormGroup;
    searchUsersFG: UntypedFormGroup;
    employers: CommonResponse[];
    locations: CommonResponse[];
    facilities: CommonResponse[];
    roles: CommonResponse[];
    facilitySearchResult: CommonResponse[] = [];
    users: Array<any>;
    routerViewUsers = '/' + RouterConstant.ROUTER_DASHBOARD + '/' + RouterConstant.ROUTER_USER_VIEW;
    routerEditUser = '/' + RouterConstant.ROUTER_DASHBOARD + '/' + RouterConstant.ROUTER_USER_EDIT;

    usersPagination: RequestPagination = new RequestPagination();
    id: number;
    nameSpinter: any = { isBool: false };
    employerSpinter: any = { isBool: false };
    locationSpinter: any = { isBool: false };
    isSearchingFacility = false;
    paging: SearchPaginationRequest = new SearchPaginationRequest();

    get employerNameCtrl() { return this.searchUsersFG.get('EmployerRole').get('employerName'); }
    get facilitatorCtrl() { return this.searchUsersFG.get('EmployerRole').get('facilitator'); }
    get employerCodeCtrl() { return this.searchUsersFG.get('EmployerRole').get('employerCode'); }
    get employerParentCtrl() { return this.searchUsersFG.get('EmployerRole').get('employerParent'); }
    get employerRegionCtrl() { return this.searchUsersFG.get('EmployerRole').get('employerRegion'); }

    facilitatorEmployers: Array<Employer>;
    facilitatorEmployersSpinner: boolean;
    facilitators: Array<User>;
    facilitatorsSpinner: boolean;
    employerCodes: Array<Employer>;
    employerCodesSpinner: boolean;
    employersParent: Array<Employer>;
    employersParentSpinner: boolean;
    employerRegions: Array<CommonResponse>;
    employerRegionsSpinner: boolean;
    requestEmployers: SearchPaginationRequest;

    ngOnDestroy() {
        this.parentFocus = null;
        this.employers = null;
        this.locations = null;
        this.facilities = null;
        this.roles = null;
        this.facilitySearchResult = null;
        this.users = null;
        this.usersPagination = null;
        this.id = null;
        this.paging = null;
    }

    constructor(
        injector: Injector,
        private _formBuilder: UntypedFormBuilder,
        private route: Router,
        private service: UserService,
        private facilitySvc: FacilityService,
        private employerService: EmployerService,
        private regionService: RegionService,
        private cmsrv: CommonService
    ) {
        super(injector);
        this.requestEmployers = {
            OrderBy: 'name',
            OrderByAsc: true,
            PageNumber: 0,
            PageSize: 0,
            Params: []
        };
    }

    ngOnInit() {
        // used for transfering employer name to viewusers page. this variable will remove when this component is run.
        localStorage.removeItem('searchBy');
        this.createForm();

        this.searchUsersByNameFG.get('searchName').valueChanges
            .pipe(
                debounceTime(500)
            )
            .subscribe(
                data => {
                    if ((typeof data) == 'object') {
                        this.route.navigate([this.routerEditUser], {
                            queryParams: {
                                id: data.UserId
                            },
                            skipLocationChange: false
                        });
                    }
                    if (data.length > 2) {
                        this.getUsersByName(data);
                    } else {
                        this.users = [];
                    }
                }
            );
    }

    public ddlUserAutoComplete_Selected(event) {
        if (event.option.value == null || event.option.value == undefined || event.option.value.Id == '') {
            return;
        }
        // this.route.navigate([this.routerEditUser], {
        //     queryParams: {
        //         id: event.option.value.Id
        //     },
        //     skipLocationChange: false
        // });
    }

    createForm() {
        this.searchUsersFG = this._formBuilder.group({
            selectFilter: [0],
            selectRole: [0],
            selectEmployer: [''],
            selectLocation: [''],
            selectFacility: [''],

            EmployerRole: this._formBuilder.group({
                employerName: [],
                facilitator: [],
                employerCode: [],
                employerParent: [],
                employerRegion: []
            })

        });

        this.searchUsersByNameFG = this._formBuilder.group({
            searchName: ['', [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(125)
            ]]
        });

        this.employerNameCtrl.valueChanges.pipe(
            debounceTime(600)
        ).subscribe(employerName => {
            if (!employerName) {
                return;
            }
            this.employers = [];
            if (employerName.length > 2) {
                this.facilitatorEmployersSpinner = true;
                this.requestEmployers.Params = [{
                    Name: 'name',
                    Value: employerName
                }];
                this.InvokeNotProcess(
                    this.employerService.search(this.requestEmployers),
                    {
                        onSuccess: (edata: string) => {
                            const dectypted_data = this.cmsrv.D_FP_ResponseData(edata);
                            const rdata = JSON.parse(dectypted_data);
                            if (rdata !== null) {
                                this.facilitatorEmployers = rdata.Data.Results;
                                this.facilitatorEmployersSpinner = false;
                            }
                        },
                        onError: err => {
                            console.error(err);
                            this.facilitatorEmployersSpinner = false;
                        }
                    }
                );
            }
        });

        this.facilitatorCtrl.valueChanges.pipe(
            debounceTime(600)
        ).subscribe(facilitatorName => {
            if (!facilitatorName) {
                return;
            }
            this.facilitators = [];
            if (facilitatorName.length > 2) {
                this.facilitatorsSpinner = true;
                this.InvokeNotProcess(
                    this.service.getUsersByNameOrEmail(facilitatorName, 8),
                    {
                        onSuccess: (edata: any) => {
                            const dectypted_data = this.cmsrv.D_FP_ResponseData(edata);
                            const result: DataResult<User[]> = JSON.parse(dectypted_data);
                            if (result !== null) {
                                this.facilitators = result.Data;
                                this.facilitatorsSpinner = false;
                            }
                        },
                        onError: err => {
                            console.error(err);
                            this.facilitatorsSpinner = false;
                        }
                    }
                );
            }
        });

        this.employerCodeCtrl.valueChanges.pipe(
            debounceTime(600)
        ).subscribe(employerCode => {
            if (!employerCode) {
                return;
            }
            this.employerCodes = [];
            if (employerCode.length > 2) {
                this.employerCodesSpinner = true;
                let requestEmployerCodes = Object.assign({}, this.requestEmployers);
                requestEmployerCodes.OrderBy = 'code';
                requestEmployerCodes.Params = [{
                    Name: 'code',
                    Value: employerCode
                }];
                this.InvokeNotProcess(
                    this.employerService.search(requestEmployerCodes),
                    {
                        onSuccess: (edata: string) => {
                            const dectypted_data = this.cmsrv.D_FP_ResponseData(edata);
                            const rdata = JSON.parse(dectypted_data);
                            if (rdata !== null) {
                                this.employerCodes = rdata.Data.Results;
                                this.employerCodesSpinner = false;
                            }
                        },
                        onError: err => {
                            console.error(err);
                            this.employerCodesSpinner = false;
                        }
                    }
                );
            }
        });

        this.employerParentCtrl.valueChanges.pipe(
            debounceTime(600)
        ).subscribe(employerParent => {
            if (!employerParent) {
                return;
            }
            this.employersParent = [];
            if (employerParent.length > 2) {
                this.employersParentSpinner = true;
                this.requestEmployers.Params = [{
                    Name: 'name',
                    Value: employerParent
                }];
                this.InvokeNotProcess(
                    this.employerService.search(this.requestEmployers),
                    {
                        onSuccess: (edata: string) => {
                            const dectypted_data = this.cmsrv.D_FP_ResponseData(edata);
                            const rdata = JSON.parse(dectypted_data);
                            if (rdata !== null) {
                                this.employersParent = rdata.Data.Results;
                                this.employersParentSpinner = false;
                            }
                        },
                        onError: err => {
                            console.error(err);
                            this.employersParentSpinner = false;
                        }
                    }
                );
            }
        });

        this.employerRegionCtrl.valueChanges.pipe(
            debounceTime(600)
        ).subscribe(employerRegion => {
            if (!employerRegion) {
                return;
            }
            this.employerRegions = [];
            if (employerRegion.length > 2) {
                this.employerRegionsSpinner = true;
                this.InvokeNotProcess(
                    this.regionService.searchRegionOfEmployer(employerRegion).pipe(
                        debounceTime(5000)
                    ),
                    {
                        onSuccess: (result: DataResult<CommonResponse[]>) => {
                            if (result !== null) {
                                if (employerRegion.length > 2) {
                                    this.employerRegions = Utils.sortASConName(result.Data, 'Name');
                                } else {
                                    this.employerRegions = [];
                                }
                                this.employerRegionsSpinner = false;
                            }
                        },
                        onError: err => {
                            console.error(err);
                            this.employerRegionsSpinner = false;
                        }
                    }
                );
            }
        });
    }

    displayLastnameAndFirstname(item?: any): string | undefined {
        return item ? item.LastName + ', ' + item.FirstName : undefined;
    }

    displayFn(item?: any): string | undefined {
        return item ? item.Name : undefined;
    }

    displayCodeFn(item?: any): string | undefined {
        return item ? item.Code : undefined;
    }

    displayFacilitator(item?: User): string | undefined {
        return item ? (item.LastName + ', ' + item.FirstName) : undefined;
    }

    changeFilter(event) {
        switch (+event) {
            case 1: {
                this.getRoles();
                this.searchUsersFG.controls['selectRole'].setValue(0);
                break;
            }
            case 2: {
                this.searchUsersFG.controls['selectEmployer'].setValue('');
                break;
            }
            case 3: {
                this.searchUsersFG.controls['selectLocation'].setValue('');
                break;
            }
            case 4: {
                this.searchUsersFG.controls['selectFacility'].setValue('');
                break;
            }
            default:
                break;
        }
    }

    isSearchBool() {
        let isBool: boolean = false;
        switch (+this.searchUsersFG.value.selectFilter) {
            case 1: {
                isBool = !(this.searchUsersFG.value.selectRole != 0);
                break;
            }
            case 2: {
                isBool = !((typeof this.searchUsersFG.get('selectEmployer').value) == 'object');
                break;
            }
            case 3: {
                isBool = !((typeof this.searchUsersFG.get('selectLocation').value) == 'object');
                break;
            }
            case 4: {
                isBool = !((typeof this.searchUsersFG.get('selectFacility').value) == 'object');
                break;
            }
        }
        return isBool;
    }

    isSearchFacilitatorBool() {
        if (this.employerNameCtrl.value === '') {
            this.employerNameCtrl.setValue(null);
        }
        if (this.facilitatorCtrl.value === '') {
            this.facilitatorCtrl.setValue(null);
        }
        if (this.employerCodeCtrl.value === '') {
            this.employerCodeCtrl.setValue(null);
        }
        if (this.employerParentCtrl.value === '') {
            this.employerParentCtrl.setValue(null);
        }
        if (this.employerRegionCtrl.value === '') {
            this.employerRegionCtrl.setValue(null);
        }

        return !(typeof this.employerNameCtrl.value === 'object') ||
            !(typeof this.facilitatorCtrl.value === 'object') ||
            !(typeof this.employerCodeCtrl.value === 'object') ||
            !(typeof this.employerParentCtrl.value === 'object') ||
            !(typeof this.employerRegionCtrl.value === 'object');
    }

    getEmployersByName(text) {
        if (text.length > 2) {
            this.service.getData(
                APIConstant.API_FIND_EMPLOYERS_BY_NAME + text,
                (data) => {
                    this.employers = data;
                    this.searchUsersFG.get('selectEmployer').valueChanges.subscribe(
                        obj => {
                            this.id = obj.EmployerId;
                            // used for transfering employer name to viewusers page. this variable will remove when this component is run.
                            localStorage.setItem('searchBy', obj.Name);
                        }
                    );
                },
                () => { },
                this.employerSpinter
            );
        } else {
            this.employers = undefined;
        }
    }

    getLocationByName(text) {
        if (text.length > 2) {
            this.usersPagination.OrderBy = 'name';
            this.usersPagination.Params[0].Name = 'name_postcode_suburb';
            this.usersPagination.Params[0].Value = text;
            //#region no limit pageSize
            this.usersPagination.PageSize = 0;
            this.usersPagination.PageNumber = 0;
            //#endregion no limit pageSize
            this.service.postData(
                APIConstant.API_SEARCH_REGIONS,
                (data) => {
                    this.locations = data.Data.Results;
                    this.searchUsersFG.get('selectLocation').valueChanges.subscribe(
                        obj => {
                            this.id = obj.RegionId;
                            // used for transfering region name to viewusers page. this variable will remove when this component is run.
                            localStorage.setItem('searchBy', obj.Name);
                        }
                    );
                },
                this.usersPagination,
                () => { },
                this.locationSpinter
            );
        } else {
            this.locations = undefined;
        }
    }

    getRoles() {
        if (this.roles == null) {
            this.service.getData(
                APIConstant.API_GET_ROLES + 'true',
                (data) => {
                    this.roles = data;
                    this.searchUsersFG.get('selectRole').valueChanges.subscribe(
                        obj => {
                            this.id = obj
                            let roleName: any = this.roles.filter(r => r.Id == obj);
                            // used for transfering role name to viewusers page. this variable will remove when this component is run.
                            localStorage.setItem('searchBy', roleName[0].Name);
                        }
                    );
                }
            );
        }
    }

    getUsersByName(name: string = '') {
        const request = { id: name.toString() };
        const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(request));
        this.service.postData(
            APIConstant.API_GET_USERS_BY_NAME_EMAIL_PAGING,
            (edata: string) => {
                const dectypted_data = this.cmsrv.D_FP_ResponseData(edata);
                const rdata:DataResult<User[]> = JSON.parse(dectypted_data);
                this.users = rdata.Data;
            },
            { Data: encrypteddata },
            () => { },
            this.nameSpinter
        );
    }

    // Author: Yen
    txtSelectGym_Input(text) {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                this.paging.PageNumber = 0;
                this.paging.PageSize = 0;
                this.paging.Params = [{ Name: 'name', Value: text }];
                if (text != null && text.length >= 3) {
                    this.isSearchingFacility = true;
                    return this.facilitySvc.search(this.paging);
                } else {
                    this.facilitySearchResult = [];
                    this.isSearchingFacility = false;
                }
                return empty();
            }),
            catchError((e) => { throw e; }))
            .subscribe(
                (result: string) => {
                    const dectypted_data = this.cmsrv.D_FP_ResponseData(result);
                    const rdata = JSON.parse(dectypted_data);
                    this.isSearchingFacility = false;
                    if (rdata != null) {
                        if (rdata.Success) {
                            this.facilitySearchResult = rdata.Data.Results.map(item =>
                                <CommonResponse>{ Id: item.FacilityId, Name: item.Name });
                            this.searchUsersFG.get('selectFacility').valueChanges.subscribe(
                                obj => {
                                    this.id = obj.Id;
                                    localStorage.setItem('searchBy', obj.Name);
                                }
                            );
                        } else {
                            console.error(result);
                        }
                    }
                },
                error => {
                    this.isSearchingFacility = false;
                    MessageBox.ShowError(this.dialog, 'An error occurred while trying to call a service');
                    console.error(error);
                });
    }

    // getUsersByName() {
    //   this.route.navigate(['/dashboard/' + RouterConstant.ROUTER_USER_VIEW], {
    //     queryParams: {
    //         name: this.searchUsersByNameFG.value.searchName,
    //         filter: 'name'
    //     }
    //   });
    // }

    getUsers(id: number) {
        let filter: string = '';
        let _id: number;
        let isFacilitatorRole: boolean;

        if (id == 0) {
            filter = 'all';
        } else if (id == 1) {
            _id = this.searchUsersFG.value.selectRole;
            filter = 'roleId';
            isFacilitatorRole = true;
        } else if (id == 2) {
            filter = 'employerId';
            _id = this.id;
        } else if (id == 3) {
            _id = this.id;
            filter = 'regionId';
        } else if (id == 4) {
            _id = this.id;
            filter = 'facilityId';
        }

        let params: UserTransferData;
        if (isFacilitatorRole) {
            params = {
                id: _id,
                filter: filter,
                employerNameId: this.employerNameCtrl.value ? (<Employer>this.employerNameCtrl.value).EmployerId : null,
                facilitatorId: this.facilitatorCtrl.value ? (<User>this.facilitatorCtrl.value).UserId : null,
                employerCodeId: this.employerCodeCtrl.value ? (<Employer>this.employerCodeCtrl.value).EmployerId : null,
                employerParentId: this.employerParentCtrl.value ? (<Employer>this.employerParentCtrl.value).EmployerId : null,
                employerRegionId: this.employerRegionCtrl.value ? (<CommonResponse>this.employerRegionCtrl.value).Id : null
            }
        } else {
            params = {
                id: _id,
                filter: filter
            }
        }

        this.route.navigate([this.routerViewUsers], {
            queryParams: params,
            skipLocationChange: false
        });

    }

    searchUsers() {
        this.getUsers(this.searchUsersFG.value.selectFilter);
    }
    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }
    onBlurParent(focusNumbr) {
        this.parentFocus = focusNumbr;
    }
    getClassFocused(vlFocused) {
        if (this.parentFocus == vlFocused) {
            return 'focused';
        } else {
            return '';
        }
    }

    public btnUserSearch_Click(event) {
        const val: string = this.searchUsersByNameFG.get('searchName').value;
        if (val.trim() === '') {
            this.getUsers(0);
        } else {
            this.route.navigate([RouterConstant.NAVIGATOR_USER_VIEW_PARAM + encodeURIComponent(val)]);
        }
    }
}

