<div class="container-custom">
  <div class="container">
    <div class="logo-container">
      <img src="./assets/images/logo.png" class="logo" alt="Fitness Passport">
    </div>
    <h1 class="title">Congratulations</h1>
    <div class="text-center content">
      <p>Your Fitness Passport account is now live!</p>
      <br/>
      <p>Please download our application from your favorite store;</p>
    </div>
    <div class="stores-container">
      <a [href]="googlePlayUrl" target="_blank">
        <img src="./assets/google-play-badge-logo.svg" alt="Google Play">
      </a>
      <a [href]="appleStoreUrl" target="_blank">
        <img src="./assets/app-store-badge-logo.svg" alt="App Store">
      </a>
    </div>
  </div>
</div>