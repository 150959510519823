import { CommonResponse } from './commonresponsemodel';

export class CommonData extends CommonResponse {
    IsActive: boolean | null;
    DisplayOrder: number | null;

    constructor(ID?: number, name?: string, active?: boolean, order?: number) {
        super();
        this.Id = ID;
        this.Name = name;
        this.IsActive = active;
        this.DisplayOrder = order;
    }
}
