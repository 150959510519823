<div [formGroup]="form">
    <ngb-accordion activeIds="pnlDetails,pnlAddressDetails,pnlCode,pnlAdditionalInfo,pnlNotes">
        <ngb-panel id="pnlDetails">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': details?.valid}"></i>
                Employer Details
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-employer-details [data]="data" [form]="form.get('PersonalDetailsSection')" #details></app-employer-details>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlAddressDetails">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': addressDetails?.valid}"></i>
                Address and Contact Details
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-employer-address-contact-details [data]="data" [form]="form.get('AddressDetailsSection')" #addressDetails>
                </app-employer-address-contact-details>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlCode">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': codes?.valid}"></i>
                Codes
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-employer-code [data]="data" [form]="form.get('CodesSection')" #codes></app-employer-code>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlAdditionalInfo">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': additionalInformation?.valid}"></i>
                Additional Information
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-employer-additional-information [data]="data" [form]="form.get('AdditionalInformationSection')" #additionalInformation>
                </app-employer-additional-information>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlNotes">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': notes?.valid}"></i>
                Additional Notes
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-employer-notes [data]="data" [form]="form.get('NotesSection')" #notes></app-employer-notes>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>