<div [formGroup]="form">
    <ng-template #requiredFieldMsgTmpl let-ctrl="control" let-ctrlName="fieldName" let-validateOnTouch="validateOnTouch || false">
        <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
            <small *ngIf="ctrl.getError('required')" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_REQUIRED, ctrlName)}}</small>
            <small
                *ngIf="((ctrl.getError('pattern') != null || ctrl.getError('unresolved') !=null) && !isCheckingCountry)"
                class="invalid-feedback d-block">{{ StringHelper.format(CommonString.FIELD_INVALID, ctrlName) }}</small>
            <small
                *ngIf="((ctrl.getError('pattern') != null || ctrl.getError('unresolved') !=null && (!form.get('PrimaryEmail').pending && !form.get('SecondaryEmail').pending)) && isCheckingCountry && (ctrlName !== 'Primary Email' && ctrlName !== 'Secondary Email'))"
                class="invalid-feedback d-block">{{ StringHelper.format(CommonString.NZ_MOBILE_NUMBER_CHECK,ctrlName) }}</small>
            <small
                *ngIf="((ctrl.getError('pattern') != null || ctrl.getError('unresolved') !=null) && isCheckingCountry && ctrlName === 'Primary Email')"
                class="invalid-feedback d-block">{{ StringHelper.format(CommonString.FIELD_INVALID,ctrlName) }}</small>
                <small
                *ngIf="((ctrl.getError('pattern') != null || ctrl.getError('unresolved') !=null) && isCheckingCountry && ctrlName === 'Secondary Email')"
                class="invalid-feedback d-block">{{ StringHelper.format(CommonString.FIELD_INVALID,ctrlName) }}</small>
            <small *ngIf="ctrl.getError('duplicated') != null" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_VALUE_ALREADY_EXISTED, ctrlName)}}</small>
        </div>
    </ng-template>
   <app-mobile-number-registor [label]="'Mobile Number'" [mandatory]="true" [control]="form.get('MobileNumber')"
    formControlName="MobileNumber" [isCountryCodeSet]="true" [isAus]="isFromAustralia" [dataModel]="data.model"  [primaryDependentSelectedDate]="dependentSelectedDate"
    [checkExistMobile]="true" [col_3]="col_3" [mandotoryLabelRed]="true" #mobileNumberRegistorComponent id="mobile-no-wrapper"></app-mobile-number-registor> 

    <div class="form-group row">
        <label for="txtPrimaryEmail" class="col-md-3 col-lg-2 col-form-label">Primary Email<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="email" class="form-control" id="txtPrimaryEmail" formControlName="PrimaryEmail" maxlength="100" required
                    [class.is-invalid]="!isControlValid('PrimaryEmail')" (blur)="txtPrimaryEmail_Blur($event)">
                <mat-spinner [diameter]="14" color="primary" *ngIf="form.get('PrimaryEmail').pending"></mat-spinner>
                <ng-container *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('PrimaryEmail'), fieldName: 'Primary Email'}"></ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtSecEmail" class="col-md-3 col-lg-2 col-form-label">Secondary Email
        </label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="email" class="form-control" [class.is-invalid]="!isControlValid('SecondaryEmail')" id="txtSecEmail" formControlName="SecondaryEmail"
                    maxlength="100">
                <mat-spinner [diameter]="14" color="primary" *ngIf="form.get('SecondaryEmail').pending"></mat-spinner>
                <ng-container *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('SecondaryEmail'), fieldName: 'Secondary Email'}"></ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row" formGroupName="Address">
        <input type="hidden" class="form-control" id="txtAddress3" formControlName="StreetAddress1" maxlength="100">
    </div>
    <div class="form-group row" formGroupName="Address">
        <label for="txtAddress1" class="col-md-3 col-lg-2 col-form-label">Address 1<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" [class.is-invalid]="!isControlValid('Address.StreetAddress')" id="txtAddress1" matInput [matAutocomplete]="addressAutocomplete"
                    formControlName="StreetAddress" maxlength="50" (blur)="txtAddress_Blur($event)" (input)="txtAddress1_Input($event)" (focusout)="AutoComplete_FocusOut($event)">
                <mat-spinner [diameter]="14" color="primary" *ngIf="getControl('Address.StreetAddress').pending"></mat-spinner>
                <ng-container *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: getControl('Address.StreetAddress'), fieldName: 'Address 1'}"></ng-container>
                <mat-autocomplete id="autoAddress" name="autoAddress" class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                    #addressAutocomplete="matAutocomplete" [displayWith]="AutoCompleteAddress_DisplayWithFn" (optionSelected)="SelectAddress1($event)">
                    <mat-option *ngFor="let address of filteredAdressList" [value]="address" class="border-default border-bottom py-1">
                        <p class="m-0 d-flex align-items-center">{{address.Text}}</p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>
    <div class="form-group row" formGroupName="Address">
        <label for="txtAddress2" class="col-md-3 col-lg-2 col-form-label">Address 2</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="txtAddress2" formControlName="StreetAddress2" maxlength="100">
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtCitySubb" class="col-md-3 col-lg-2 col-form-label">Suburb<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" [class.is-invalid]="!isControlValid('Suburb')" id="txtCitySubb" matInput [matAutocomplete]="suburbAutocomplete"
                    formControlName="Suburb" maxlength="50" (blur)="txtSuburb_Blur($event)" (input)="txtSuburb_Input($event)">
                <mat-spinner [diameter]="14" color="primary" *ngIf="getControl('Suburb').pending"></mat-spinner>
                <ng-container *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: getControl('Suburb'), fieldName: 'City/Suburb'}"></ng-container>
                <mat-autocomplete id="autoSuburb" name="autoSuburb" class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                    #suburbAutocomplete="matAutocomplete" [displayWith]="AutoComplete_DisplayWithFn" (optionSelected)="SelectSuburb($event.option.value)">
                    <mat-option *ngFor="let suburb of suburbs" [value]="suburb" class="border-default border-bottom py-1">
                        <p class="m-0 d-flex align-items-center">{{suburb.Name + ', ' + suburb.Postcode.State.Name + ', ' + suburb.Postcode.Code }}</p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>

    <div *ngIf="!isFromAustralia" class="form-group row">
        <label for="txtCity" class="col-md-3 col-lg-2 col-form-label">Town/City<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="input-group"
                    [class.is-invalid]="!isControlValid(CityCtrl, true)"
                    #citySearchBox [ngClass]="getClassFocused(3)"
                    [class.disabled]="CityCtrl.disabled">
                    <input class="form-control" id="txtCity" type="text"
                        placeholder="Search City" formControlName="City" matInput
                        [matAutocomplete]="autoCity" (blur)="txtCity_Blur($event)"
                        (input)="txtCity_Input($event)" (focus)="onFocusParent(3)"
                        (change)="ddlEmployerCity_Change()">
                    <mat-spinner [diameter]="14" color="primary" *ngIf="isSearchingCity"></mat-spinner>
                </div>
                <div
                    *ngIf="!isControlValid(CityCtrl, true)">
                    <small *ngIf="CityCtrl.getError('unresolved'); else notEquals"
                        class="invalid-feedback d-block">Town/City is invalid</small>
                    <small *ngIf="CityCtrl.getError('required')" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'City')}}</small>
                </div>
                <mat-autocomplete id="autoCity" name="autoCity"
                    class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                    #autoCity="matAutocomplete"
                    [displayWith]="AutoCompleteCity_DisplayWithFn"
                    (optionSelected)="SelectCity($event.option.value)">
                    <mat-option *ngFor="let city of citySearchResults" [value]="city"
                        class="border-default border-bottom py-1">
                        <p class="m-0 d-flex align-items-center"> {{city.Name}}</p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>

    <div class="form-group row">
        <label for="txtPostcode" class="col-md-3 col-lg-2 col-form-label">Postcode<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" [class.is-invalid]="!isControlValid('Postcode', true)" id="ddlPostcode" formControlName="Postcode"
                    [compareWith]="ddlPostcodeOption_CompareFn">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let postcode of postcodes" [ngValue]="postcode">{{postcode.Code}}</option>
                </select>
                <ng-container *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: getControl('Postcode'), fieldName: 'Postcode', validatOnTouch: true}">
                </ng-container>
            </div>
        </div>
    </div>

    <div class="form-group row" *ngIf="isFromAustralia">
        <label for="ddlState" class="col-md-3 col-lg-2 col-form-label">State<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" [class.is-invalid]="!isControlValid('State', true)" id="ddlState" formControlName="State"
                    [compareWith]="ddlStateOption_CompareFn">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let state of states" [ngValue]="state">{{state.Name}}</option>
                </select>
                <ng-container *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: getControl('State'), fieldName: 'State', validateOnTouch: true}"></ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlCountry" class="col-md-3 col-lg-2 col-form-label">Country<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" [class.is-invalid]="!isControlValid('Country', true)" id="ddlCountry" formControlName="Country"
                    [compareWith]="ddlCountryOption_CompareFn" disabled="isDisabled" >
                    <option *ngFor="let country of countries" [ngValue]="country">{{country.Name}}</option>
                </select>
                <ng-container *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: getControl('Country'), fieldName: 'Country', validateOnTouch: true}">
                </ng-container>
            </div>
        </div>
    </div>
</div>