import { MessageBox } from "./../../../services/common-dialog.service";
// Author: Da Do
import { CommonData, CommonResponse, DataResult, NormalMembershipSuspension, User } from "src/app/models";
import { APIConstant } from "./../../../constant/apiconstant";
import { CommonService } from "./../../../services/common.service";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
  Form,
  ValidatorFn,
} from "@angular/forms";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import {
  merge,
  Observable,
  of as observableOf,
  forkJoin,
  Subject,
  empty,
} from "rxjs";
import {
  catchError,
  map,
  startWith,
  switchMap,
  shareReplay,
} from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { HttpDAO } from "../../../services/httpdao.service";
import { CommonDataType } from "src/app/enums";
import { RouterConstant } from "../../../constant/routerconstant";
import { Router } from "@angular/router";
import { DatePipe, Location } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { CustomMessageBox, CustomMessageBoxButton } from "@fp/helpers";
import { CommonConstants } from "@fp/constant/common-constants";
import { MemberService, MembershipService } from "@fp/services";
import { StorageKey } from "@fp/constant";
import { MemberContainer } from "@fp/models/member-container.model";
@Component({
  selector: "app-suspend-membership",
  templateUrl: "./suspend-membership.component.html",
  styleUrls: ["./suspend-membership.component.css"],
})
export class SuspendMembershipComponent implements OnInit {
  parentFocus = 0;
  public IsMsgShow = false;
  public IsWaiting = true;
  public MemberSuspendFormGroup: UntypedFormGroup;
  private submitClick = false;
  public startdate: NgbDateStruct;
  public enddate: NgbDateStruct;
  private httpdao: HttpDAO | null;
  public reasonlist: CommonResponse[] = [];
  private PrimaryMemberId = "";
  private submitStarted = false;
  private membersuspenobj: NormalMembershipSuspension;
  public currentsystemdate: string = new Date().toString();
  public StartDateReadonly = false;
  private cancelSuspensionBtn = false;
  private isCancelSuspension = false;
  RouterConstant = RouterConstant;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private commonservice: CommonService,
    private memberSvc: MemberService,
    private membershipSvc: MembershipService,
    private http: HttpClient,
    private router: Router,
    private datePipe: DatePipe,
    protected dialog: MatDialog,
    private _location: Location
  ) {}

  ngOnInit() {
    this.httpdao = new HttpDAO(this.http);
    this.SetValidator();
    this.LoadReasonData();
    this.PrimaryMemberId = this.commonservice.D_FP_AES256ForShortString(
      localStorage.getItem("memberId")
    );
  }

  private SetValidator() {
    this.MemberSuspendFormGroup = this._formBuilder.group({
      ddlReason: ["", Validators.required],
      // ----------------------
      dtpStartDate: [
        "",
        [
          Validators.required,
          this.MinDateValidator(this.currentsystemdate),
          this.RangeDateValidator,
        ],
      ],
      // ----------------------
      dtpEndDate: [
        "",
        [
          Validators.required,
          this.MinDateValidator(this.currentsystemdate),
          this.RangeDateValidator,
          this.SuspensionValidator,
        ],
      ],
    });
  }

  //#region "Custom Validator"
  public MinDateValidator(currentutcdate: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && isNaN(control.value)) {
        const dstr: NgbDateStruct = control.value;
        const d = new Date(dstr.year, dstr.month - 1, dstr.day, 0, 0, 0, 0);
        let dc = new Date(currentutcdate);
        dc.setHours(0, 0, 0, 0);
        if (d < dc || d.toString() == "Invalid Date") {
          return { mindateinvalid: true };
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  public RangeDateValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value !== undefined && isNaN(control.value)) {
      const enddate: NgbDateStruct = control.parent.get("dtpEndDate").value;
      const startdate: NgbDateStruct = control.parent.get("dtpStartDate").value;
      if (startdate == null || enddate == null) {
        return null;
      } else {
        const d1: Date = new Date(
          startdate.year,
          startdate.month - 1,
          startdate.day,
          0,
          0,
          0,
          0
        );
        const d2: Date = new Date(
          enddate.year,
          enddate.month - 1,
          enddate.day,
          0,
          0,
          0,
          0
        );
        if (d2 <= d1) {
          return { rangedateinvalid: true };
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  }

  public SuspensionValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value !== undefined && isNaN(control.value)) {
      const enddate: NgbDateStruct = control.parent.get("dtpEndDate").value;
      const startdate: NgbDateStruct = control.parent.get("dtpStartDate").value;
      if (startdate == null || enddate == null) {
        return null;
      } else {
        const d1: Date = new Date(
          startdate.year,
          startdate.month - 1,
          startdate.day,
          0,
          0,
          0,
          0
        );
        const d2: Date = new Date(
          enddate.year,
          enddate.month - 1,
          enddate.day,
          0,
          0,
          0,
          0
        );
        var timeDiff = d2.getTime() - d1.getTime();
        let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        if (diffDays < 14 && diffDays > 0) {
          return { suspensioninvalid_2w: true };
        } else if (diffDays > 60) {
          return { suspensioninvalid_2m: true };
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  }
  //#endregion

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }
  onBlurParent(focusNumbr) {
    this.parentFocus = focusNumbr;
  }
  getClassFocused(vlFocused) {
    if (this.parentFocus == vlFocused) {
      return "focused";
    } else {
      return "";
    }
  }

  private ShowMsg() {
    this.IsMsgShow = true;
  }

  private HideMsg() {
    this.IsMsgShow = false;
  }

  public btnContinue_Click() {
    this.HideMsg();
  }

  public SetClassForControl(
    tmpformgroup: UntypedFormGroup,
    controlname: string
  ): string {
    if (
      tmpformgroup.get(controlname).invalid &&
      (tmpformgroup.get(controlname).dirty ||
        tmpformgroup.get(controlname).touched ||
        this.submitClick)
    ) {
      return "form-control is-invalid";
    } else {
      return "form-control";
    }
  }

  public SetClassForInputGroup(
    tmpformgroup: UntypedFormGroup,
    controlname: string
  ): string {
    if (
      tmpformgroup.get(controlname).invalid &&
      (tmpformgroup.get(controlname).dirty ||
        tmpformgroup.get(controlname).touched ||
        this.submitClick)
    ) {
      return "is-invalid";
    } else {
      return "";
    }
  }

  public IsControlInvalid(
    tmpformgroup: UntypedFormGroup,
    controlname: string
  ): boolean {
    if (
      controlname == "dtpEndDate" &&
      tmpformgroup.get("dtpEndDate").errors != undefined &&
      (tmpformgroup.get("dtpEndDate").errors.mindateinvalid ||
        tmpformgroup.get("dtpEndDate").errors.rangedateinvalid)
    ) {
      return (
        tmpformgroup.get(controlname).invalid &&
        (tmpformgroup.get(controlname).dirty ||
          tmpformgroup.get(controlname).touched ||
          tmpformgroup.get("dtpStartDate").dirty ||
          tmpformgroup.get("dtpStartDate").touched ||
          this.submitClick)
      );
    } else {
      return (
        tmpformgroup.get(controlname).invalid &&
        (tmpformgroup.get(controlname).dirty ||
          tmpformgroup.get(controlname).touched ||
          this.submitClick)
      );
    }
  }

  public GetCurrentDate(): NgbDateStruct {
    return this.ConvertDateToNgbDate(this.currentsystemdate);
  }

  private ConvertDateToNgbDate(str: string): NgbDateStruct {
    const arr: string[] = str.split("-");
    let d: NgbDateStruct = {
      year: Number.parseInt(arr[0]),
      month: Number.parseInt(arr[1]), // must plus 1 because the month run from 0
      day: Number.parseInt(arr[2]),
    };
    return d;
  }

  private ConvertNgbDateToDateString(d: NgbDateStruct): string {
    const dt = new Date(d.year, d.month - 1, d.day);
    const str = this.datePipe.transform(dt, APIConstant.DATE_FORMAT);
    return str;
  }

  private TransferToLocalDate(str: string): string {
    const d = new Date(str);
    const offseth = APIConstant.UTC_SYDNEY + d.getTimezoneOffset() / 60 + 1;
    d.setHours(d.getHours() - offseth);
    const res = this.datePipe.transform(d, APIConstant.DATE_FORMAT);
    return res;
  }

  public btnSubmit_Click() {
    this.submitClick = true;
    if (!this.submitStarted && this.MemberSuspendFormGroup.valid) {
      this.submitStarted = true;
      this.SaveMemberSuspend();
    }
  }

  public btnCancel_Click() {
    this.router.navigate([RouterConstant.NAVIGATOR_MEMBERSHIP_CARDS]);
  }

  public btnBackOut_Click() {
    this._location.back();
  }

  //#region "Load Data"
  private LoadReasonData() {
    this.Invoke(
      this.membershipSvc.getMembershipSuspensionReason(),
      (result: DataResult<CommonData[]>) => {
        for (let i = 0; i < result.Data.length; i++) {
          if (result.Data[i].Name !== "Suspension Extension") {
            if(result.Data[i].Name === "Other") {
              this.reasonlist.push({Id: -1, Name: result.Data[i].Name});
            } else {
              this.reasonlist.push({Id: result.Data[i].Id, Name: result.Data[i].Name});
            }
          }
        }
        this.LoadMemberSuspension();
      }
    );
  }

  private LoadMemberSuspension() {
    if (this.commonservice.getMemSuspension()) {
      // Using local storage - no API call
      this.membersuspenobj = this.commonservice.getMemSuspension();
      this.currentsystemdate = this.TransferToLocalDate(
        this.membersuspenobj.CurrentTime
      );
      this.IsWaiting = false;
      this.DisplayDataToUI();
      this.commonservice.StopProgressBar();
      // API call
    } else if (Number.parseInt(this.PrimaryMemberId) != NaN) {
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.httpdao!.getSingleData(
              APIConstant.API_GET_MEMBERSHIP_SUSPENSION + this.PrimaryMemberId
            );
          }),
          map((result) => {
            return result;
          }),
          catchError(() => {
            this.commonservice.StopProgressBar();
            return observableOf([]);
          })
        )
        .subscribe((result) => {
          //   console.log("Match this: ", result.Data);
          this.membersuspenobj = result.Data;
          this.currentsystemdate = this.TransferToLocalDate(
            this.membersuspenobj.CurrentTime
          );
          this.IsWaiting = false;
          this.DisplayDataToUI();
          this.commonservice.StopProgressBar();
        });
    }
  }

  private DisplayDataToUI() {
    if (this.membersuspenobj.MembershipSuspensionId == 0) {
      this.MemberSuspendFormGroup.get("dtpStartDate").setValue("");
      this.MemberSuspendFormGroup.get("dtpEndDate").setValue("");
      this.MemberSuspendFormGroup.get("ddlReason").setValue("");
      this.cancelSuspensionBtn = false;
      if (!this.isCancelSuspension) {
        this.ShowMsg();
      }
    } else {
      this.cancelSuspensionBtn = true;
      this.HideMsg();
      const stratd: NgbDateStruct = this.ConvertDateToNgbDate(
        this.membersuspenobj.SuspensionStartDate
      );
      const endd: NgbDateStruct = this.ConvertDateToNgbDate(
        this.membersuspenobj.SuspensionEndDate
      );
      this.MemberSuspendFormGroup.get("dtpStartDate").setValue(stratd);
      this.MemberSuspendFormGroup.get("dtpEndDate").setValue(endd);
      if (this.membersuspenobj.MembershipSuspensionReasonId == 0) {
        this.MemberSuspendFormGroup.get("ddlReason").setValue("");
      }
      else {
      this.MemberSuspendFormGroup.get("ddlReason").setValue(
        this.membersuspenobj.MembershipSuspensionReasonId
      );
      }
      if (this.IsSuspendStarted()) {
        this.MemberSuspendFormGroup.get("dtpStartDate").disable();
        this.StartDateReadonly = true;
      } else {
        this.MemberSuspendFormGroup.get("dtpStartDate").enable();
        this.StartDateReadonly = false;
      }
    }
  }

  private IsSuspendStarted(): boolean {
    const startd = new Date(this.membersuspenobj.SuspensionStartDate);
    const cdate = new Date(this.currentsystemdate);
    startd.setHours(0, 0, 0, 0);
    if (startd <= cdate) {
      return true;
    } else {
      return false;
    }
  }

  private SaveMemberSuspend() {
    const tmp: NgbDateStruct = this.MemberSuspendFormGroup.get("dtpStartDate")
      .value;
    const d1: Date = new Date(this.ConvertNgbDateToDateString(tmp));
    d1.setHours(0, 0, 0, 0);
    const d2: Date = new Date(this.membersuspenobj.SuspensionStartDate);
    d2.setHours(0, 0, 0, 0);
    if (
      this.membersuspenobj.MembershipSuspensionId > 0 &&
      this.IsSuspendStarted() &&
      d1.getTime() != d2.getTime()
    ) {
      MessageBox.ShowInfo(
        this.dialog,
        "The Suspension was started. Cannot change the start date."
      ).subscribe((data) => {
        this.reasonlist = [];
        this.LoadReasonData();
        this.submitStarted = false;
      });
      return;
    } else {
      this.SetDataToObject();
      if (Number.parseInt(this.PrimaryMemberId)) {
        merge()
          .pipe(
            startWith({}),
            switchMap(() => {
              this.commonservice.StartProgressBar();
              // member initiated
              this.membersuspenobj.SelectedRole = "Member";
              const encrypteddata = this.commonservice.E_FP_RequestData(
                JSON.stringify(this.membersuspenobj)
              );
              const encryptedtoken = this.commonservice.E_FP_RequestData(
                this.commonservice.GetToken()
              );
              return this.httpdao!.postData(
                APIConstant.API_SAVE_MEMBERSHIP_SUSPENSION_FOR_MEMBER,
                { Data: encrypteddata, Header: encryptedtoken }
              );
            }),
            map((result) => {
              return result;
            }),
            catchError(() => {
              this.commonservice.StopProgressBar();
              return observableOf([]);
            })
          )
          .subscribe((result) => {
            const res = result;
            if (result.Success) {
              MessageBox.ShowCustom(
                this.dialog,
                null,
                null,
                'Your suspension has been accepted and will automatically start \
                            and end on the dates you have requested. Please remember that these \
                            dates are inclusive. If you wish to cancel this suspension please \
                            click on the "<strong>Cancel Suspension</strong>" button in your member profile at any time.'
              ).subscribe(() => {
                this.reasonlist = [];
                const currentUser = <User>JSON.parse(this.commonservice.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
                this.memberSvc
                  .getByUserForMember(currentUser.UserId)
                  .subscribe((getMember) => {
                    if (getMember.Data.FamilyObject) {
                      this.commonservice.SetMemFamObj(
                        getMember.Data.FamilyObject
                      );
                    }
                    if (getMember.Data.MembershipSuspension) {
                      this.commonservice.SetMemSuspension(
                        getMember.Data.MembershipSuspension.Data
                      );
                    }
                    this._location.back();
                  });
              });
            } else {
              MessageBox.ShowInfo(
                this.dialog,
                "Cannot save data due to: " + result.Message
              ).subscribe((data) => {
                this.reasonlist = [];
                this.LoadReasonData();
              });
            }
            this.commonservice.StopProgressBar();
            console.log(res);
          });
      }
    }
  }

  private SetDataToObject() {
    this.membersuspenobj.MembershipSuspensionReasonId = this.MemberSuspendFormGroup.get(
      "ddlReason"
    ).value;
    const sd = this.ConvertNgbDateToDateString(
      this.MemberSuspendFormGroup.get("dtpStartDate").value
    );
    const ed = this.ConvertNgbDateToDateString(
      this.MemberSuspendFormGroup.get("dtpEndDate").value
    );
    this.membersuspenobj.SuspensionStartDate = sd;
    this.membersuspenobj.SuspensionEndDate = ed;
    this.membersuspenobj.MembershipSuspensionTypeId = 2;
    if (this.membersuspenobj.MembershipSuspensionId == 0) {
      this.membersuspenobj.IsChargeSuspensionFee = true;
    } else {
      this.membersuspenobj.IsChargeSuspensionFee = null;
    }
    this.membersuspenobj.ModifiedBy = this.commonservice.GetUser();
  }

  confirmCancelSuspension() {
    CustomMessageBox.Show(
      this.dialog,
      {
        title: "Suspension Cancellation Confirmation",
        content:
          "<p>Cancelling the current suspension will reset all suspension fields, including suspension dates. \
            This action is irreversible and a new suspension period will have to be created if necessary. \
            A new suspension period will incur another suspension fee. The suspension fee for the period you are \
            cancelling is non-refundable. Do you wish to continue?</p>",
        buttons: [
          {
            label: "CANCEL",
            dialogResult: "cancel",
          },
          {
            label: "CONTINUE",
            isPrimary: true,
            dialogResult: "continue",
          },
        ],
      },
      {
        width: "450px",
        afterClosed: (r) => {
          if (r.result === "continue") {
            this.cancelSuspension();
          }
        },
      }
    );
  }

  private cancelSuspension() {
    let cancelSuspension: NormalMembershipSuspension = new NormalMembershipSuspension();
    cancelSuspension.MembershipSuspensionId = this.membersuspenobj.MembershipSuspensionId;
    cancelSuspension.ModifiedBy = this.commonservice.GetUser();
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.commonservice.StartProgressBar();
          const encrypteddata = this.commonservice.E_FP_RequestData(
            JSON.stringify(cancelSuspension)
          );
          const encryptedtoken = this.commonservice.E_FP_RequestData(
            this.commonservice.GetToken()
          );
          //member initiated
          return this.httpdao!.postData(
            APIConstant.API_CANCEL_SUSPENSION_FOR_MEMBER,
            { Data: encrypteddata, Header: encryptedtoken }
          );
        }),
        map((result) => {
          return result;
        }),
        catchError(() => {
          this.commonservice.StopProgressBar();
          return observableOf([]);
        })
      )
      .subscribe((result) => {
        const res = result;
        if (result.Success) {
          this.CancelSuspensionSuccess();
        } else {
          MessageBox.ShowInfo(
            this.dialog,
            "Cannot cancel suspension due to: " + result.Message
          ).subscribe((data) => {
            this.reasonlist = [];
            this.LoadReasonData();
          });
        }
        this.commonservice.StopProgressBar();
      });
  }

  private CancelSuspensionSuccess() {
    CustomMessageBox.Show(
      this.dialog,
      {
        title: "Suspension Cancellation Confirmation",
        content:
          "Your suspension is now cancelled. If you wish to suspend your membership for another period, please select new dates and your suspension reason.",
        buttons: [CustomMessageBoxButton.Ok],
      },
      {
        width: "450px",
        afterClosed: (r) => {
          this.isCancelSuspension = true;
          const currentUser = <User>JSON.parse(this.commonservice.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
          this.memberSvc
            .getByUserForMember(currentUser.UserId)
            .subscribe((getMember) => {
              if (getMember.Data.FamilyObject) {
                this.commonservice.SetMemFamObj(
                  getMember.Data.FamilyObject
                );
              }
              if (getMember.Data.MembershipSuspension) {
                this.commonservice.SetMemSuspension(
                  getMember.Data.MembershipSuspension.Data
                );
              }
              this.LoadMemberSuspension();
              this.MemberSuspendFormGroup.get("dtpStartDate").enable();
              this.StartDateReadonly = false;
            });
        },
      }
    );
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError((e) => {
          throw e;
        })
      )
      .subscribe(
        (res) => {
          handleResultCallback(res);
        },
        (err) => {
          this.errorHandler(err);
        }
      );
  }

  private errorHandler(error, message: string = null) {
    console.error(error);
  }
  //#endregion
}
