<div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [diameter]=50></mat-spinner>
</div>
<div class="d-flex justify-content-center align-items-center heightContent100">
    <div class="py-3 col-12 col-lg-4 border-default rounded-10 border-2">
        <h4 class="py-3 text-center text-primary font-weight-normal">Create Password</h4>
        <form [formGroup]="changePasswordFormGroup" (ngSubmit)="Save()">
            <div class="form-group row">
                <label for="txtPassword" class="col-md-3 col-form-label">Password</label>
                <div class="col-md-7">
                    <div class="d-flex flex-column w-100">
                        <input type="password" maxlength="20" [ngClass]="IsInvalidControl('txtPassword') ?'form-control is-invalid':'form-control'"
                            id="txtPassword" formControlName="txtPassword"  [(ngModel)]="password">
                        <div *ngIf="IsInvalidControl('txtPassword')">
                                <small *ngIf="changePasswordFormGroup.get('txtPassword').errors.required"  class="invalid-feedback d-block">
                                    Password is required
                                </small>
                                <small *ngIf="changePasswordFormGroup.get('txtPassword').errors?.pattern" class="invalid-feedback d-block">
                                    Password must be minimum of 8 characters, include at least one number, include at least one upper case and one lower case letter 
                                    and contain at least one special character
                                    <br>
                                    The following characters are considered as special characters: {{ getSpecialCharacters() }}
                                </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="txtConfirmPassword" class="col-md-3 col-form-label">Confirm Password </label>
                <div class="col-md-7">
                    <div class="d-flex flex-column w-100">
                        <input type="password" maxlength="20" [ngClass]="IsInvalidControl('txtConfirmPassword') || (!IsValidConfirmPassword() && changePasswordFormGroup.valid) ?'form-control is-invalid':'form-control'"
                            id="txtConfirmPassword" formControlName="txtConfirmPassword" [(ngModel)]="confirmPassword">
                        <div *ngIf="IsInvalidControl('txtConfirmPassword')">
                            <small *ngIf="changePasswordFormGroup.get('txtConfirmPassword').errors!= null && changePasswordFormGroup.get('txtConfirmPassword').errors.required"  class="invalid-feedback d-block">
                                Confirm Password is required</small>
                            <small *ngIf="changePasswordFormGroup.get('txtConfirmPassword').errors.pattern"  class="invalid-feedback d-block">
                                Password must be minimum of 8 characters, include at least one number, include at least one upper case and one lower case letter 
                                and contain at least one special character
                                <br>
                                The following characters are considered as special characters: {{ getSpecialCharacters() }}
                            </small>
                        </div>
                        <small *ngIf="!IsValidConfirmPassword() && changePasswordFormGroup.valid"  class="invalid-feedback d-block">
                            The passwords do not match, please try again</small>
                    </div>
                </div>
            </div>
            <div class="pt-2 form-group text-center">
                <button class="px-2 px-lg-5 py-2 btn btn-primary" type="submit">
                    Submit</button>
            </div>
        </form>
    </div>
</div>