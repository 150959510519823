export abstract class CommonString {
    public static TABLE_NO_RECORD_FOUND = 'No record found';
    public static FIELD_REQUIRED = '{0} is required';
    public static FIELD_INVALID = '{0} is invalid';
    public static FIELD_VALUE_ALREADY_EXISTED = 'The {0} already exists';
    public static NZ_MOBILE_NUMBER_CHECK = '{0} is invalid. Please enter a valid number with 7-12 digits. Leading zero will be removed';
    public static FIELD_VALUE_ALREADY_EXISTED_EMPCODE = '{0} already exists, please select a unique code';
    public static FIELD_VALUE_LONG_EMPCODE = '{0} should be 3-5 letters long';
    public static STATUS_TEXT_ACTIVE = 'Active';
    public static STATUS_TEXT_INACTIVE = 'Inactive';
    public static STATUS_TEXT_PENDING = 'Pending';
    public static PAGE_SIZE_OPTIONS: number[] = [10, 20, 50];
    public static INIT_PAGE_SIZE = 10;
    public static EMPLOYER_STATUS_DEFAULT = 'Survey';
    public static MAX_UPLOAD_FILE_SIZE = 2097152;
    public static MAX_UPLOAD_FILE_SIZE_5MB = 5242880;
    public static MAX_UPLOAD_FILE_SIZE_10MB = 10485760;
    public static MAX_UPLOAD_FILE_SIZE_100MB = 104857600;
    public static MAX_UPLOAD_FILE_SIZE_MESSAGE = 'Maximum file size is {0}';
    public static WRONG_UPLOAD_FILE_TYPE_MESSAGE = 'Only {0} file type(s) are allowed';
    public static NOT_ACCEPT_APPROVE = 'Please approve primary member first before approving additional members';
    public static COGNITO_NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException';
    public static USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException';
    public static UNKNOWN_UPLOAD_FAILURE = 'Something went wrong. Please attempt to upload your photo again.';
    public static DATE_FORMAT = 'DD-MM-YYYY';
    public static DATE_TIME_FORMAT = 'dd-MM-yyyy HH:mm';
    public static FORMAT_TYPE_FILE_ATTACHMENT = ['PDF', 'DOCX', 'DOC', 'XLS', 'XLSX', 'TXT'];
    public static IMPORT_CONTROL_WRONG_FILE_TYPE = 'Only CSV files can be uploaded. Please try again by selecting a CSV file';
    public static IMPORT_CONTROL_WRONG_FILE_TYPE_HEADER = 'Error- Non-compatible file selected';
    public static IMPORT_PENDING_STATUS = 'Pending Upload Confirmation';
    public static ROLE_EMPLOYER_FACILITATOR = 'Employer Facilitator';
    public static EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    public static EXCEL_EXTENSION = '.xlsx';
    public static CSV_EXTENSION = '.csv';
    public static AUSTRALIA = 'Australia';
    public static NEWZEALAND = 'New Zealand';
    public static AuditBulkUploadBankCode = 'BulkUploadBankCode';
    public static AuditBulkUploadBSB = 'BulkUploadBSB';
    public static AuditBulkUploadPostCode = 'BulkUploadPostcode';
    public static AustraliaCountryCode = 'AUS';
    public static NewZealandCountryCode = 'NZL';
    public static AustraliaPhoneCode = '61';
    public static NewZealandPhoneCode = '64';
    public static AuditBulkUploadPostcode = 'BulkUploadPostcode';
    public static FacilityPotentialMessageIdentifier = 'IsPontetialFacility';
}
