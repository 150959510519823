import { Postcode } from '.';

export class State {
    StateId: number;
    Name: string;
    IsActive: boolean;
    Postcode: Postcode[];
    CountryId: number;

    constructor(ID?: number, name?: string, isActive?: boolean, countryID?: number) {
        this.StateId = ID;
        this.Name = name;
        this.IsActive = isActive;
        this.CountryId = countryID;
    }
}