import { MessageBox } from './common-dialog.service';
import { SafeHtml } from '@angular/platform-browser';
import { HttpDAO } from '../services/httpdao.service';
import {
  confirmUserAttribute,
  sendUserAttributeVerificationCode,
  updateUserAttribute,
  updateMFAPreference,
  fetchMFAPreference,
  fetchUserAttributes,
  UpdateMFAPreferenceInput,
  UpdateUserAttributeInput,
  SendUserAttributeVerificationCodeInput,
  ConfirmUserAttributeInput,
  signOut, fetchAuthSession, AuthSession,
} from 'aws-amplify/auth';
import { Injectable, Inject } from '@angular/core';
import { NavbarComponent } from '../components/navbar-component/navbar.component';
import { DashboardComponent } from '../components/dashboard-component/dashboard.component';
import { EmployerPage } from '../models/employerpagemodel';
import { HeaderComponent } from '../components/header-components/header.component';
import { HeaderComponentNewComponent } from '../components/header-component-new/header-component-new.component';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { MemberAdditionalNotesComponent } from '@fp/components/members/shared';
import { FacilityNotesComponent } from '@fp/components/facility-components/addfacility/facility-details/facility-notes/facility-notes.component';
import { UntypedFormGroup } from '@angular/forms';
import { ValidatorMessageModel } from '@fp/models/validator-message.model';
import { MatDialog } from '@angular/material/dialog';
import * as jwt_decode from 'jwt-decode';
import { UserRole } from '@fp/models/user-role.model';
import { Observable } from 'rxjs';
import { APIConstant } from '@fp/constant/apiconstant';
import { DOCUMENT } from '@angular/common';
import { StorageKey } from '@fp/constant';
import { MemberContainer } from '@fp/models/member-container.model';
import { DataResult } from '@fp/models/data-result';
import { Member } from '@fp/models/member.model';
import { EMemberStatus } from '@fp/enums/member-status.enum';
import { CommonConstants } from '@fp/constant/common-constants';
import { map } from 'rxjs/operators';
import { EMemberType } from '@fp/enums';
import { CookieService } from 'ngx-cookie-service';
import { ERole } from '@fp/enums/role.enum';
import { User } from '@fp/models';
@Injectable()
export class CommonService {
  public AddressID: string;
  public RegionID: number;
  public EmployerName: string;
  public EmployerID: string;
  public EmployerCommand: string;
  public Navbarmd: NavbarComponent;
  public Navbar: NavbarComponent;
  public DashBoard: DashboardComponent;
  public EmpPage: EmployerPage;
  public Header: HeaderComponent;
  public HeaderNew: HeaderComponentNewComponent;
  public App: AppComponent;
  public isGlobalLoadingResults = false;
  public EmployerIDForMemberSignUp = '';
  public TermAndConditionText: SafeHtml;
  public TermAndConditionVersion: string;
  public CurrentUTCServerDate: Date = new Date();

  public Username: string;
  public Password: string;
  public SelectedRole: string;
  public MenuForMember = false;
  public MenuForFamilyMember = false;
  public MenuForNewMember = false;
  public MenuForNewPrimaryMember = false;
  public MenuForActivePrimaryMember = false;
  public MenuForFacilityStaffMember = false;
  public MenuForEmployerFacilitator = false;

  public MemberIdForNote: number = -1;
  public MemberNoteComponent: MemberAdditionalNotesComponent = null;

  public FacilityIdForNote: number = -1;
  public FacilityNoteComponent: FacilityNotesComponent = null;

  public PaymentFrequencyID = 0;
  public PaymentFrequencyName = '';

  public dialog: MatDialog;

  public IsEditMember: boolean = false;

  public FormDataChange = false;

  fitnessPassportWebsite: string = 'https://www.fitnesspassport.com.au/';
  freshdeskNewSupportRequestUrl: string =
    'https://fitnesspassport.freshdesk.com/support/tickets/new';

  public ValidatorMessage: ValidatorMessageModel[] = [
    { ControlId: 'txtEmployerName', Message: 'Employer Name is required' },
    {
      ControlId: 'ddlAccountManagerName',
      Message: 'Account Manager is required',
    },
    {
      ControlId: 'ddlBDevelopManagerName',
      Message: 'Business Development Manager is required',
    },
    { ControlId: 'ddlEmployerType', Message: 'Employer Type is required' },
    { ControlId: 'txtEmployerCode', Message: 'Employer Code is required' },
    { ControlId: 'txtPhone', Message: 'Mobile Number is required' },
    { ControlId: 'txtStreet1', Message: 'Address Line 1 is required' },
    { ControlId: 'txtMAAddsLine1', Message: 'Address Line 1 is required' },
    { ControlId: 'ddlPostCodePhy', Message: 'City/Suburb is required' },
    { ControlId: 'ddlPostCodeMail', Message: 'City/Suburb is required' },
    { ControlId: 'dtpStartDate', Message: 'Employer Start Date is required' },
    { ControlId: 'dtpDateClosed', Message: 'Date Closed is required' },
  ];

  constructor(
    private router: Router,
    private httpDao: HttpDAO,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public StartProgressBar() {
    this.DashBoard.StartProgressBar();
  }

  public StopProgressBar() {
    this.DashBoard.StopProgressBar();
  }

  public StartGlobalProgressBar() {
    this.isGlobalLoadingResults = true;
  }

  public StopGlobalProgressBar() {
    this.isGlobalLoadingResults = false;
  }

  public async SignOut(redirect = true) {
    this.MenuForMember = false;
    this.MenuForFamilyMember = false;
    this.MenuForNewMember = false;
    this.MenuForNewPrimaryMember = false;
    this.MenuForActivePrimaryMember = false;
    this.MenuForFacilityStaffMember = false;
    this.MenuForEmployerFacilitator = false;

    if (this.Navbar) {
      this.Navbar.isLoginDisplay = true;
      this.Navbar.isLoginSuccessDisplay = false;
    }
    if (this.Navbarmd) {
      this.Navbarmd.isLoginDisplay = true;
      this.Navbarmd.isLoginSuccessDisplay = false;
    }
    if (this.Header) {
      this.Header.isLogin = false;
    }
    if (this.App) {
      this.App.isLoginSuccessDisplay = false;
      this.App.opened = false;
    }
    this.deleteCookies();
    await this.cognitoLogout();
    if (redirect) {
      this.document.location.href = environment.logoutUrl;
    }
  }

  private deleteCookies() {
    //FP-816
    this.cookieService.deleteAll();
  }

  private async cognitoLogout() {
    await signOut();
    localStorage.length;
    const toDelete = [];
    for (let i = 0; i < localStorage.length; i++) {
      if (!localStorage.key(i).startsWith('CognitoIdentityServiceProvider')) {
        toDelete.push(localStorage.key(i));
      }
    }
    toDelete.forEach((key) => localStorage.removeItem(key));
  }

  public async getCurrentAuthSession(forceRefresh: boolean = false) {
    try {
      return await fetchAuthSession({forceRefresh: forceRefresh});
    } catch (error) {
      console.error(`Unable to fetch auth session, error: ${error}`);
      if(error.name === "TokenRefreshException" || error.name === "UserUnAuthenticatedException") {
        await this.SignOut();
      }
    }
  }

  public setCurrentAuthSession(session: AuthSession) {
    if (session?.tokens?.idToken) {
      const accessToken = session.tokens.idToken.toString();
      const encryptedAccessToken = this.E_FP_AES256(accessToken);
      const {aud, "cognito:username": username} = session.tokens.idToken.payload;
      const refreshTokenKey = `CognitoIdentityServiceProvider.${aud}.${username}.refreshToken`;
      const refreshToken = localStorage.getItem(refreshTokenKey);
      const encryptedRefreshToken = this.E_FP_AES256(refreshToken);
      localStorage.setItem('accessToken', encryptedAccessToken);
      localStorage.setItem('refreshToken', encryptedRefreshToken);
      this.SetUser(username);
      return session;
    }
  }

  public async getCurrentUserEmailAndPhone(): Promise<{
    email: string;
    phone: string;
  }> {
    try {
      const userAttributes = await fetchUserAttributes();
      const { email, phone_number: phone } = userAttributes;
      return {
        email,
        phone,
      };
    } catch (error) {
      console.log(error);
    }
  }

  public async getCurrentUserCognitoState(): Promise<
    | 'VALID'
    | 'HAS_PHONE_NUMBER_REQUIRES_VERIFICATION'
    | 'HAS_NO_PHONE_NUMBER'
    | 'UNEXPECTED_STATE'
  > {
    try {
      await this.App.checkTokenExpired();
      const userAttributes = await fetchUserAttributes();
      if (userAttributes.phone_number === undefined) {
        return 'HAS_NO_PHONE_NUMBER';
      }
      if (
        userAttributes.phone_number !== undefined &&
        userAttributes.phone_number_verified === 'false'
      ) {
        return 'HAS_PHONE_NUMBER_REQUIRES_VERIFICATION';
      }
      if (
        userAttributes.phone_number !== undefined &&
        userAttributes.phone_number_verified === 'true'
      ) {
        return 'VALID';
      }
    } catch (error) {
      console.log(error);
    }
    return 'UNEXPECTED_STATE';
  }

  public async getCurrentUserPrefferedMFA(): Promise<
    'SMS' | 'EMAIL' | undefined
  > {
    try {
      await this.App.checkTokenExpired();
      const mfaPreference = await fetchMFAPreference();
      if (mfaPreference.preferred === 'TOTP') {
        throw new Error(
          'TOTP is not an expected Cognito User state for this application',
        );
      }
      return mfaPreference.preferred;
    } catch (error) {
      console.log(error);
    }
  }

  public async updateCurrentUserPrefferedMFA(preferredMFA: 'SMS' | 'EMAIL') {
    try {
      await this.App.checkTokenExpired();
      const updateMFAPreferencePayload: UpdateMFAPreferenceInput =
        preferredMFA === 'SMS'
          ? {
              email: 'NOT_PREFERRED',
              sms: 'PREFERRED',
            }
          : {
              email: 'PREFERRED',
              sms: 'NOT_PREFERRED',
            };
      await updateMFAPreference(updateMFAPreferencePayload);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * @param phoneNumber Expected format includes the `+` and country code. e.g. for an
   * australian number the expected format is `+61412493222`
   */
  public async updateCurrentUserMFAPhoneNumber(phoneNumber: string) {
    await this.App.checkTokenExpired();
    const updateMFAPreferencePayload: UpdateUserAttributeInput = {
      userAttribute: {
        attributeKey: 'phone_number',
        value: phoneNumber,
      },
    };
    await updateUserAttribute(updateMFAPreferencePayload);
    const sendUserAttributeVerificationCodePayload: SendUserAttributeVerificationCodeInput =
      {
        userAttributeKey: 'phone_number',
      };
    await sendUserAttributeVerificationCode(
      sendUserAttributeVerificationCodePayload,
    );
    await this.updateCurrentUserPrefferedMFA('EMAIL');
    return true;
  }

  public async verifyCurrentUserCode(code: string) {
    try {
      await this.App.checkTokenExpired();
      const confirmUserAttributePayload: ConfirmUserAttributeInput = {
        userAttributeKey: 'phone_number',
        confirmationCode: code,
      };
      const result = await confirmUserAttribute(confirmUserAttributePayload);
    } catch (error) {
      console.log(error);
    }
  }

  private HideContent() {
    const elem = document.getElementById('divFPMainContent');
    if (elem) {
      elem.style.display = 'none';
    }
  }

  public D_FP_AES256(encrypttext: string) {
    try {
      if (encrypttext === null) {
        return null;
      }
      const encrypted = CryptoJS.AES.decrypt(
        encrypttext,
        environment.NCKXXL,
      ).toString(CryptoJS.enc.Utf8);
      return encrypted;
    } catch (exception) {
      return null;
    }
  }

  public E_FP_AES256(plaintext: string) {
    const encrypted = CryptoJS.AES.encrypt(
      plaintext,
      environment.NCKXXL,
    ).toString();
    return encrypted;
  }

  public E_FP_AES256ForShortString(plaintext: string) {
    plaintext = plaintext + '###ABC###ABC###090773668824444';
    const encrypted = CryptoJS.AES.encrypt(
      plaintext,
      environment.NCKXXL,
    ).toString();
    return encrypted;
  }

  public D_FP_AES256ForShortString(encrypttext: string) {
    try {
      if (encrypttext === null) {
        return null;
      }
      let encrypted: string = CryptoJS.AES.decrypt(
        encrypttext,
        environment.NCKXXL,
      ).toString(CryptoJS.enc.Utf8);
      encrypted = encrypted.replace('###ABC###ABC###090773668824444', '');
      return encrypted;
    } catch (exception) {
      return null;
    }
  }

  public GetSelectedRole() {
    const temp = localStorage.getItem('SelectedRole');
    if (temp === null) {
      return null;
    }
    const ret = this.D_FP_AES256ForShortString(temp);
    if (ret === null) {
      console.log(
        "Error 101: Invalid Data. Couldn't retrieve selected role details",
      );
    } else {
      return ret;
    }
  }

  public SetSelectedRole(slrole: string) {
    const ret = this.E_FP_AES256ForShortString(slrole);
    if (ret === null) {
      console.log(
        "Error 101: Invalid Data. Couldn't set selected role details",
      );
    } else {
      localStorage.setItem('SelectedRole', ret);
    }
  }

  public GetUser() {
    const temp = localStorage.getItem('user');
    if (temp === null) {
      return null;
    }
    const ret = this.D_FP_AES256ForShortString(temp);
    if (ret === null) {
      console.log("Error 101: Invalid Data. Couldn't retrieve user details");
      return null;
    } else {
      return ret;
    }
  }

  public SetUser(username) {
    const ret = this.E_FP_AES256ForShortString(username);
    if (ret === null) {
      console.log("Error 101: Invalid Data. Couldn't set user details");
    } else {
      localStorage.setItem('user', ret);
    }
  }

  public GetUserObject(): User | null {
    const temp = localStorage.getItem(StorageKey.USER_OBJECT);
    if (temp === null) {
      return null;
    }
    const ret = this.D_FP_AES256(temp);
    if (ret === null) {
      console.log(
        "Error 101: Invalid Data. Couldn't retrieve user object details",
      );
      return null;
    } else {
      return JSON.parse(ret);
    }
  }

  public GetTermsConditions() {
    const temp = localStorage.getItem('terms_obj');
    if (temp === null) {
      return null;
    }
    const ret = this.D_FP_AES256(temp);
    if (ret === null) {
      console.log(
        "Error 101: Invalid Data. Couldn't retrieve terms and conditions details",
      );
    } else {
      return JSON.parse(ret);
    }
  }

  public GetSecondaryTermsConditions() {
    const temp = localStorage.getItem('terms_sec_obj');
    if (temp === null) {
      return null;
    }
    const ret = this.D_FP_AES256(temp);
    if (ret === null) {
      console.log(
        "Error 101: Invalid Data.  Couldn't retrieve secondary terms and conditions details",
      );
    } else {
      return JSON.parse(ret);
    }
  }

  public GetMedicalQuestions() {
    const temp = localStorage.getItem('medi_obj');
    if (temp === null) {
      return null;
    }
    const ret = this.D_FP_AES256(temp);
    if (ret === null) {
      console.log(
        "Error 101: Invalid Data. Couldn't retrieve medical questions details",
      );
    } else {
      return JSON.parse(ret);
    }
  }

  public getEmployers() {
    const temp = localStorage.getItem('emp_obj');
    if (temp === null) {
      return null;
    }
    const ret = this.D_FP_AES256(temp);
    if (ret === null) {
      console.log(
        "Error 101: Invalid Data. Couldn't retrieve employers details",
      );
    } else {
      return JSON.parse(ret);
    }
  }

  public getUserCountry() {
    const temp = localStorage.getItem('user_country_obj');
    if (temp === null) {
      return null;
    }
    const ret = this.D_FP_AES256(temp);
    if (ret === null) {
      console.log(
        "Error 101: Invalid Data. Couldn't retrieve user country details",
      );
    } else {
      return ret;
    }
  }

  public setUserCountry(country: string) {
    const ret = this.E_FP_AES256(country);
    if (ret === null) {
      console.log('Error 101: Invalid Data.');
    } else {
      localStorage.setItem('user_country_obj', ret);
    }
  }

  public setCardForMemberPortal(res: any) {
    const data = JSON.stringify(res);
    const ret = this.E_FP_AES256(data);
    if (ret === null) {
      console.log('Error 101: Invalid Data.');
    } else {
      localStorage.setItem('card_obj', ret);
    }
  }

  public getMember() {
    const temp = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
    if (temp === null) {
      return null;
    }
    let container = <MemberContainer>JSON.parse(temp);
    var currentMember = <DataResult<Member>>(
      JSON.parse(this.D_FP_AES256(container.Member))
    );
    if (currentMember === null) {
      console.log('Error 101: Invalid Data.');
      return null;
    } else {
      return currentMember.Data;
    }
  }

  public getMemCard() {
    const temp = localStorage.getItem('card_obj');
    if (temp === null) {
      return null;
    }
    const ret = this.D_FP_AES256(temp);
    if (ret === null) {
      console.log('Error 101: Invalid Data.');
    } else {
      return JSON.parse(ret);
    }
  }

  public SetUserObject(res: any) {
    const data = JSON.stringify(res);
    const ret = this.E_FP_AES256(data);
    if (ret === null) {
      console.log('Error 101: Invalid Data.');
    } else {
      localStorage.setItem(StorageKey.USER_OBJECT, ret);
    }
  }
  public SetTermsConditions(res: any) {
    const data = JSON.stringify(res);
    const ret = this.E_FP_AES256(data);
    if (ret === null) {
      console.log('Error 101: Invalid Data.');
    } else {
      localStorage.setItem('terms_obj', ret);
    }
  }
  public SetSecondaryTermsConditions(res: any) {
    const data = JSON.stringify(res);
    const ret = this.E_FP_AES256(data);
    if (ret === null) {
      console.log('Error 101: Invalid Data.');
    } else {
      localStorage.setItem('terms_sec_obj', ret);
    }
  }
  public SetMedicalQuestions(res: any) {
    const data = JSON.stringify(res);
    const ret = this.E_FP_AES256(data);
    if (ret === null) {
      console.log('Error 101: Invalid Data.');
    } else {
      localStorage.setItem('medi_obj', ret);
    }
  }

  public SetMemSuspension(res: any) {
    const data = JSON.stringify(res);
    const ret = this.E_FP_AES256(data);
    if (ret === null) {
      console.log('Error 101: Invalid Data.');
    } else {
      localStorage.setItem('mem_sus_obj', ret);
    }
  }

  public SetMemFamObj(res: any) {
    const data = JSON.stringify(res);
    const ret = this.E_FP_AES256(data);
    if (ret === null) {
      console.log('Error 101: Invalid Data.');
    } else {
      localStorage.setItem('mem_fam_obj', ret);
    }
  }

  public GetMemFamObj() {
    const temp = localStorage.getItem('mem_fam_obj');
    if (temp === null) {
      return null;
    }
    const ret = this.D_FP_AES256(temp);
    if (ret === null) {
      console.log('Error 101: Invalid Data.');
    } else {
      return JSON.parse(ret);
    }
  }

  public getMemSuspension() {
    const temp = localStorage.getItem('mem_sus_obj');
    if (temp === null) {
      return null;
    }
    const ret = this.D_FP_AES256(temp);
    if (ret === null) {
      console.log('Error 101: Invalid Data.');
    } else {
      return JSON.parse(ret);
    }
  }
  public SetEmployers(res: any) {
    const data = JSON.stringify(res);
    const ret = this.E_FP_AES256(data);
    if (ret === null) {
      console.log('Error 101: Invalid Data.');
    } else {
      localStorage.setItem('emp_obj', ret);
    }
  }

  //   getData(type: CommonDataType): Observable<Models.DataResult<Models.CommonData[]>> {
  //     return this.httpDao.getSingleData(APIConstant.API_GET_COMMON_DATA + type);
  // }

  public getLocationData(): any {
    return this.httpDao.getData(APIConstant.API_GET_LOCATION_DATA);
  }

  public getAllRegions(): any {
    return this.httpDao.getData(APIConstant.API_GET_REGIONS_NAME);
  }

  public getUploadUrl(fileNameInS3: string): any {
    return this.httpDao.getData(
      APIConstant.API_GET_UPLOAD_URL + encodeURIComponent(fileNameInS3),
    );
  }

  public getDownloadUrl(fileNameInS3: string): any {
    return this.httpDao.getData(
      APIConstant.API_GET_DOWNLOAD_URL + encodeURIComponent(fileNameInS3),
    );
  }

  public getImageBlobForMemberPhoto(s3ImageUrl: string) {
    return this.httpDao.getBlob(
      APIConstant.API_GET_DOWNLOAD_URL +
        encodeURIComponent(
          s3ImageUrl.substring(s3ImageUrl.lastIndexOf('memberPhotos/')),
        ),
    );
  }

  updateTerm(data: any): Observable<any> {
    const encrypteddata = this.E_FP_RequestData(JSON.stringify(data));
    const encryptedtoken = this.E_FP_RequestData(this.GetToken());
    return this.httpDao
      .postData(APIConstant.API_EDIT_TERMS_CONDITIONS, {
        Data: encrypteddata,
        Header: encryptedtoken,
      })
      .pipe(
        map((value: any) => {
          const dectypted_data = this.D_FP_ResponseData(value);
          const rdata: DataResult<any> = JSON.parse(dectypted_data);
          console.log('rdata', rdata);
          return rdata;
        }),
      );
  }

  public GetToken() {
    const temp = localStorage.getItem('accessToken');
    if (temp === null) {
      return null;
    }
    const ret = this.D_FP_AES256(temp);
    if (ret === null) {
      console.log("Error 101: Invalid Data. Couldn't retrieve accesstoken");
    } else {
      return ret;
    }
  }

  public E_FP_RequestData(plaintext: string) {
    const key = CryptoJS.enc.Utf8.parse(environment.NCKXXL2);
    const iv = CryptoJS.enc.Utf8.parse(environment.NCKXXL2);
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(plaintext),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      },
    );
    return encrypted.toString();
  }

  public D_FP_ResponseData(encrypted: string) {
    const key = CryptoJS.enc.Utf8.parse(environment.NCKXXL3);
    const iv = CryptoJS.enc.Utf8.parse(environment.NCKXXL3);
    const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  public GetAccessTokenDecrypt(): string {
    let token = localStorage.getItem('accessToken');
    token = this.D_FP_AES256(token);
    return token;
  }

  public GetRefreshTokenDecrypt(): string {
    let token = localStorage.getItem('refreshToken');
    token = this.D_FP_AES256(token);
    return token;
  }

  public GetValidateMessages(fg: UntypedFormGroup): ValidatorMessageModel[] {
    const wholemessage: ValidatorMessageModel[] = [];
    Object.keys(fg.controls).forEach((key) => {
      if (fg.get(key).errors !== null) {
        const vm = this.ValidatorMessage.find((it) => it.ControlId === key);
        if (vm !== null && vm !== undefined) {
          wholemessage.push(vm);
        }
      }
    });
    if (wholemessage.length > 0) {
      const ctrl = document.getElementById(wholemessage[0].ControlId);
      if (ctrl !== null && ctrl !== undefined) {
        ctrl.focus();
      }
    }
    return wholemessage;
  }

  public FormatValidateMessage(param: ValidatorMessageModel[]): string {
    let ret: string = '<div style="font-size: 11px; color: red"><ul>';
    for (let i = 0; i < param.length; i++) {
      ret = ret + '<li>' + param[i].Message + '</li>';
    }
    ret = ret + '</ul></div>';
    return ret;
  }

  public ShowPopupValidator(dialog: MatDialog) {
    const InvalidInputElements = document.querySelectorAll(
      'input.form-control.ng-invalid',
    );
    const InvalidSelectElements = document.querySelectorAll(
      'select.form-control.ng-invalid',
    );
    const InvalidElements = document.querySelectorAll('small.invalid-feedback');
    if (InvalidInputElements.length > 0) {
      const ele = <HTMLInputElement>InvalidInputElements[0];
      if (ele.value === '') {
        ele.focus();
        MessageBox.ShowError(dialog, 'Required field is missing');
        return;
      }
    }
    if (InvalidSelectElements.length > 0) {
      const ele = <HTMLSelectElement>InvalidSelectElements[0];
      if (ele.value === '' || ele.value === null || ele.value === undefined) {
        MessageBox.ShowError(dialog, 'Required field is missing');
        ele.focus();
        ele.scrollIntoView();
        return;
      }
    }
    if (InvalidElements.length > 0) {
      const ele = <HTMLElement>InvalidElements[0];
      MessageBox.ShowError(dialog, 'Required field is missing');
      ele.scrollIntoView();
      return;
    }
  }

  public ShowPopupRemindPhoto(dialog: MatDialog) {
    const PhotoUploadElement = document.querySelectorAll(
      '.fas.fa-user-tie.fa-2x.text-secondary',
    );
    if (PhotoUploadElement.length > 0) {
      const ele = <HTMLElement>PhotoUploadElement[0];
      MessageBox.ShowInfo(
        dialog,
        'Do you want to upload a photo for your membership card? ' +
          'You may need to bring secondary ID to access facilities if your card does not have a photo. ' +
          'We highly recommend you upload one before you finish sign up in the Personal Details tab.',
      );
      return;
    }
  }

  public SetAutoCompleteOff() {
    const TextElement = document.querySelectorAll('input[type=text]');
    for (let i = 0; i < TextElement.length; i++) {
      const ele = <HTMLElement>TextElement[i];
      ele.setAttribute('autocomplete', 'nope');
    }

    const EmailElement = document.querySelectorAll('input[type=email]');
    for (let i = 0; i < EmailElement.length; i++) {
      const ele = <HTMLElement>EmailElement[i];
      ele.setAttribute('autocomplete', 'nope');
    }
  }

  public GetUserRoleByUserName(): UserRole[] {
    const finalrolematrix = [
      'Admin',
      'Finance_Level_1',
      'Finance_Level_2',
      'Finance_Level_3',
      'Operation_Level_1',
      'Operation_Level_2',
      'Operation_Level_3',
      'Operation_Level_1_ReadOnly',
      'Business_Development_Level_1',
      'Business_Development_Level_2',
      'Business_Development_Level_3',
      'Business_Development_ReadOnly',
      'Employer_Facilitator',
      'Facility_Staff_Member',
      'Member',
      'Privileged_Reports_Access',
      'Business_Development_Level_4',
      'Business_Development_Level_5',
    ];
    const encryptedtoken = localStorage.getItem('accessToken');
    const decryptedtoken = this.D_FP_AES256(encryptedtoken);
    const ret = jwt_decode(decryptedtoken);
    const groups: string[] = ret['cognito:groups'] ?? [ERole.member];
    groups.sort((a, b) => 0 - (a > b ? -1 : 1));

    var getMember = this.getMember();
    var hasInactiveMember = false;
    if (getMember !== null) {
      if (
        (getMember.MemberStatusId == EMemberStatus.Inactive &&
          getMember.MemberTypeId != EMemberType.Primary) ||
        getMember.MemberStatusId == EMemberStatus.Banned
      ) {
        hasInactiveMember = true;
      }
    }

    const urs: UserRole[] = [];

    for (let i = 0; i < groups.length; i++) {
      const ur: UserRole = new UserRole();
      ur.Name = groups[i];
      ur.DisplayName = groups[i];
      if (ur.Name == 'Member' && hasInactiveMember) {
        continue;
      }
      switch (ur.DisplayName) {
        case 'Finance_Level_1':
          ur.DisplayName = 'Finance 1 (Accounts Officer)';
          break;
        case 'Finance_Level_2':
          ur.DisplayName = 'Finance 2 (Finance Manager)';
          break;
        case 'Finance_Level_3':
          ur.DisplayName = 'Finance 3 (Senior Staff)';
          break;
        case 'Operation_Level_1':
          ur.DisplayName = 'Operations 1 (Members Account Manager)';
          break;
        case 'Operation_Level_2':
          ur.DisplayName = 'Operations 2 (Employers Account Manager)';
          break;
        case 'Operation_Level_3':
          ur.DisplayName = 'Operations 3 (Operations Team Leader)';
          break;
        case 'Operation_Level_1_ReadOnly':
          ur.DisplayName = 'Operations 4 (Operations Australia)';
          break;
        case 'Business_Development_Level_1':
          ur.DisplayName = 'Business Development 1 (Customer Service)';
          break;
        case 'Business_Development_Level_2':
          ur.DisplayName = 'Business Development 2 (Australia)';
          break;
        case 'Business_Development_Level_3':
          ur.DisplayName = 'Network Development 1 (Program Development)';
          break;
        case 'Business_Development_ReadOnly':
          ur.DisplayName = 'Network Development 2 (Support Staff)';
          break;
        case 'Employer_Facilitator':
          ur.DisplayName = 'Employer Facilitator (EF)';
          break;
        case 'Facility_Staff_Member':
          ur.DisplayName = 'Facility Staff Member (FSM)';
          break;
        case 'Privileged_Reports_Access':
          ur.DisplayName = 'Privileged Reports Access';
          break;
        case 'Business_Development_Level_4':
          ur.DisplayName = 'Business Development 3';
          break;
        case 'Business_Development_Level_5':
          ur.DisplayName = 'Network Development 3';
          break;
      }
      if (finalrolematrix.indexOf(ur.Name) > -1) {
        urs.push(ur);
      }
    }
    return urs;
  }

  public isRequiredNonMemberDetailsComplete() {
    const userObject = this.GetUserObject();
    if (
      userObject.FirstName &&
      userObject.LastName &&
      userObject.DateOfBirth &&
      userObject.GenderId &&
      userObject.MobileNumber &&
      userObject.EmailAddress
    )
      return true;
    else return false;
  }

  public isUserAMember() {
    const currentRole = this.GetSelectedRole();
    if (currentRole === ERole.member) {
      return true;
    } else {
      return false;
    }
  }

  public DecodeJWTToken(AccessToken: string): any {
    const ret = jwt_decode(AccessToken);
    return ret;
  }

  public AddChangeListenerInput() {
    this.FormDataChange = false;
    const htmleles = document.querySelectorAll('.form-control');

    for (let i = 0; i < htmleles.length; i++) {
      const ele = <HTMLElement>htmleles[i];
      if (ele.id !== 'ddlAuditLogFilter') {
        ele.addEventListener('change', (event) => {
          this.FormDataChange = true;
        });
      }
    }
  }

  public SetMobileCountryCode() {
    // set Austalian Country Code
    if (
      this.getUserCountry() &&
      this.getUserCountry().toLowerCase() ===
        CommonConstants.GET_COUNTRIES.find(
          (a) => a.CountryId == 1,
        ).Name.toLowerCase()
    ) {
      return '61';
    } else if (
      this.getUserCountry() &&
      this.getUserCountry().toLowerCase() ===
        CommonConstants.GET_COUNTRIES.find(
          (a) => a.CountryId == 2,
        ).Name.toLowerCase()
    ) {
      return '64';
    } else {
      return '61';
    }
  }
}
