import { PdfReportingService } from "@fp/services/report/pdfreporting.service";
import { PositionTextModel, PositionRectModel } from "@fp/services/report/report-object.model";
import {jsPDF} from 'jspdf';
export class EoMScannerVisitLogPDFConvert {
    private prsrv: PdfReportingService = new PdfReportingService();
    private PaddingLeft = 2;
    private PaddingTop = 6;
    private PaddingTop2Line = 4;
    private FontSizeNormal = 9;
    private FontName = 'helvetica';
    private FirstPageSize = 40;
    private footerIndex = 0;
    constructor() {

    }

    public ReportTitle: PositionTextModel = {
        HorizonX: 10,
        VerticalY: 20,
        FontSize: 14,
        FontName: this.FontName,
        Text: '',
        LimitWrapText: 80
    };

    public HeaderTable: PositionRectModel = {
        X: 10,
        Y: this.ReportTitle.VerticalY + 10,
        Width: 195,
        Height: 10
    }

    public FirstNameCol: PositionTextModel = {
        HorizonX: this.HeaderTable.X + this.PaddingLeft,
        VerticalY: this.HeaderTable.Y + this.PaddingTop,
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: 'First Name'
    };

    public FirstNameRow: PositionTextModel = {
        HorizonX: this.FirstNameCol.HorizonX,
        VerticalY: this.FirstNameCol.VerticalY + this.PaddingTop,
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: '',
        LimitWrapText: 18
    };

    public LastNameCol: PositionTextModel = {
        HorizonX: this.FirstNameCol.HorizonX + 30 + this.PaddingLeft,
        VerticalY: this.HeaderTable.Y + this.PaddingTop,
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: 'Last Name'
    };

    public LastNameRow: PositionTextModel = {
        HorizonX: this.LastNameCol.HorizonX,
        VerticalY: this.FirstNameRow.VerticalY, // follow First name Y
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: '',
        LimitWrapText: 20
    };

    public MemberNumberCol: PositionTextModel = {
        HorizonX: this.LastNameCol.HorizonX + 30 + this.PaddingLeft,
        VerticalY: this.HeaderTable.Y + this.PaddingTop2Line,
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: 'Member Number',
        LimitWrapText: 12
    };

    public MemberNumberRow: PositionTextModel = {
        HorizonX: this.MemberNumberCol.HorizonX,
        VerticalY: this.FirstNameRow.VerticalY,  // follow First name Y
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: ''
    };

    public DateCol: PositionTextModel = {
        HorizonX: this.MemberNumberCol.HorizonX + 30 + this.PaddingLeft,
        VerticalY: this.HeaderTable.Y + this.PaddingTop,
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: 'Date'
    };

    public DateRow: PositionTextModel = {
        HorizonX: this.DateCol.HorizonX,
        VerticalY: this.FirstNameRow.VerticalY,  // follow First name Y
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: ''
    };

    public TimeCol: PositionTextModel = {
        HorizonX: this.DateCol.HorizonX + 20 + this.PaddingLeft,
        VerticalY: this.HeaderTable.Y + this.PaddingTop,
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: 'Time'
    };

    public TimeRow: PositionTextModel = {
        HorizonX: this.TimeCol.HorizonX,
        VerticalY: this.FirstNameRow.VerticalY,  // follow First name Y
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: ''
    };

    public VisitTypeCol: PositionTextModel = {
        HorizonX: this.TimeCol.HorizonX + 20 + this.PaddingLeft,
        VerticalY: this.HeaderTable.Y + this.PaddingTop,
        FontSize: this.FontSizeNormal,
        FontName: 'helvetica',
        Text: 'Visit Type'
    };

    public VisitTypeRow: PositionTextModel = {
        HorizonX: this.VisitTypeCol.HorizonX,
        VerticalY: this.FirstNameRow.VerticalY,  // follow First name Y
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: ''
    };

    public VisitSourceCol: PositionTextModel = {
        HorizonX: this.VisitTypeCol.HorizonX + 20 + this.PaddingLeft,
        VerticalY: this.HeaderTable.Y + this.PaddingTop,
        FontSize: this.FontSizeNormal,
        FontName: 'helvetica',
        Text: 'Visit Source'
    };

    public VisitSourceRow: PositionTextModel = {
        HorizonX: this.VisitSourceCol.HorizonX,
        VerticalY: this.FirstNameRow.VerticalY,  // follow First name Y
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: ''
    };

    public TotalLabel: PositionTextModel = {
        HorizonX: this.HeaderTable.X,
        VerticalY: this.FirstNameRow.VerticalY,  // follow First name Y
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: 'Totals',
        FontStyle: 'bold'
    };

    public TotalValidVisitsLabel: PositionTextModel = {
        HorizonX: this.HeaderTable.X,
        VerticalY: this.TotalLabel.VerticalY + this.PaddingTop,  // follow First name Y
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: 'Valid Visits: ',
        FontStyle: 'bold'
    };

    public TotalValidVisits: PositionTextModel = {
        HorizonX: this.TotalValidVisitsLabel.HorizonX + 30,
        VerticalY: this.TotalLabel.VerticalY + this.PaddingTop,  // follow First name Y
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: '',
        FontStyle: 'bold'
    };

    public TotalInvalidVisitsLabel: PositionTextModel = {
        HorizonX: this.HeaderTable.X,
        VerticalY: this.TotalValidVisitsLabel.VerticalY + this.PaddingTop,  // follow First name Y
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: 'Invalid Visits:',
        FontStyle: 'bold'
    };

    public TotalInvalidVisits: PositionTextModel = {
        HorizonX: this.TotalInvalidVisitsLabel.HorizonX + 30,
        VerticalY: this.TotalLabel.VerticalY + this.PaddingTop,  // follow First name Y
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: '',
        FontStyle: 'bold'
    };

    public TotalPayableLabel: PositionTextModel = {
        HorizonX: this.HeaderTable.X,
        VerticalY: this.TotalValidVisitsLabel.VerticalY + this.PaddingTop,  // follow First name Y
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: 'Total Payable Scanner Visits:',
        FontStyle: 'bold'
    };

    public TotalPayable: PositionTextModel = {
        HorizonX: this.TotalPayableLabel.HorizonX + 50,
        VerticalY: this.TotalLabel.VerticalY + this.PaddingTop,  // follow First name Y
        FontSize: this.FontSizeNormal,
        FontName: this.FontName,
        Text: '',
        FontStyle: 'bold'
    };

    private PaintHeader(haveTitle = true) {
        if (haveTitle) {
            this.prsrv.PaintText(this.ReportTitle);
        }
        this.prsrv.PaintRect(this.HeaderTable);
        this.prsrv.PaintText(this.FirstNameCol);
        this.prsrv.PaintText(this.LastNameCol);
        this.prsrv.PaintText(this.MemberNumberCol, true);
        this.prsrv.PaintText(this.DateCol);
        this.prsrv.PaintText(this.TimeCol);
        this.prsrv.PaintText(this.VisitTypeCol);
        this.prsrv.PaintText(this.VisitSourceCol);
    }

    private PaintRecords(row: string, index: number) {
        const arr_val: string[] = row.split(',');
        let firstnameval = (arr_val[0] && arr_val[0].length > 2) ? arr_val[0].substring(1, arr_val[0].length - 1) : '';
        let lastnameval = (arr_val[1] && arr_val[1].length > 2) ? arr_val[1].substring(1, arr_val[1].length - 1) : '';
        const membernoval = (arr_val[2] && arr_val[2].length > 2) ? arr_val[2].substring(1, arr_val[2].length - 1) : '';
        const dateval = (arr_val[3] && arr_val[3].length > 2) ? arr_val[3].substring(1, arr_val[3].length - 1) : '';
        const timeval = (arr_val[4] && arr_val[4].length > 2) ? arr_val[4].substring(1, arr_val[4].length - 1) : '';
        const visittypeval = (arr_val[5] && arr_val[5].length > 2) ? arr_val[5].substring(1, arr_val[5].length - 1) : '';
        const visitsrcval = (arr_val[6] && arr_val[6].length > 2) ? arr_val[6].substring(1, arr_val[6].length - 1) : '';


        //check if it is Total row
        if (arr_val[0] && arr_val[0].indexOf('"') === -1 && arr_val[0].trim() === 'Totals') {
            return;
        }

        if (arr_val[0] && arr_val[0].indexOf('"') === -1 && arr_val[0].trim() === 'Valid Visits') {
            this.TotalValidVisits.Text = arr_val[1]
            return;
        }

        if (arr_val[0] && arr_val[0].indexOf('"') === -1 && arr_val[0].trim() === 'Invalid Visits') {
            this.TotalInvalidVisits.Text = arr_val[1];
            return;
        }

        if (arr_val[0] && arr_val[0].indexOf('"') === -1 && arr_val[0].trim() === 'Total Payable Scanner Visits') {
            this.TotalPayable.Text = arr_val[1];
            return;
        }
        // End
        this.footerIndex++;

        if (firstnameval.length > 18) {
            if (firstnameval.indexOf(' ') === -1) {
                firstnameval = firstnameval.slice(0, 18) + '...';
            }
        }

        if (lastnameval.length > 18) {
            if (lastnameval.indexOf(' ') === -1) {
                lastnameval = lastnameval.slice(0, 18) + '...';
            }
        }

        this.FirstNameRow.Text = firstnameval;
        this.LastNameRow.Text = lastnameval;
        this.MemberNumberRow.Text = membernoval;
        this.DateRow.Text = dateval;
        this.TimeRow.Text = timeval;
        this.VisitTypeRow.Text = visittypeval;
        this.VisitSourceRow.Text = visitsrcval;

        // First record in the First page
        this.FirstNameRow.VerticalY = index == 2 ? (this.FirstNameRow.VerticalY + this.PaddingTop) : this.FirstNameRow.VerticalY;
        this.LastNameRow.VerticalY = this.FirstNameRow.VerticalY;
        this.MemberNumberRow.VerticalY = this.FirstNameRow.VerticalY;
        this.DateRow.VerticalY = this.FirstNameRow.VerticalY;
        this.TimeRow.VerticalY = this.FirstNameRow.VerticalY;
        this.VisitTypeRow.VerticalY = this.FirstNameRow.VerticalY;
        this.VisitSourceRow.VerticalY = this.FirstNameRow.VerticalY;
        //End

        this.prsrv.PaintText(this.FirstNameRow, true);
        this.prsrv.PaintText(this.LastNameRow, true);
        this.prsrv.PaintText(this.MemberNumberRow);
        this.prsrv.PaintText(this.DateRow);
        this.prsrv.PaintText(this.TimeRow);
        this.prsrv.PaintText(this.VisitTypeRow);
        this.prsrv.PaintText(this.VisitSourceRow);

        this.FirstNameRow.VerticalY = this.FirstNameRow.VerticalY + this.PaddingTop;
        this.LastNameRow.VerticalY = this.FirstNameRow.VerticalY;
        this.MemberNumberRow.VerticalY = this.FirstNameRow.VerticalY;
        this.DateRow.VerticalY = this.FirstNameRow.VerticalY;
        this.TimeRow.VerticalY = this.FirstNameRow.VerticalY;
        this.VisitTypeRow.VerticalY = this.FirstNameRow.VerticalY;
        this.VisitSourceRow.VerticalY = this.FirstNameRow.VerticalY;

        if (index % this.FirstPageSize == 0) {
            this.prsrv.pdf.addPage();
            this.PaintHeader(false);
            this.FirstNameRow.VerticalY = this.HeaderTable.Y + (3 * this.PaddingTop);
        }
    }

    private PaintTotals(row: PositionTextModel, index: number, addNewline: boolean) {
        row.VerticalY = this.FirstNameRow.VerticalY + this.PaddingTop;
        this.prsrv.PaintText(row);
        if(addNewline) {
            this.FirstNameRow.VerticalY = this.FirstNameRow.VerticalY + this.PaddingTop;
            this.footerIndex++;

            if (index >= this.FirstPageSize-1) {
                this.prsrv.pdf.addPage();
                this.FirstNameRow.VerticalY = this.HeaderTable.Y + (1 * this.PaddingTop);
                this.footerIndex = 0;
            }
        }
    }

    private ExportPdfReport(title: string, records: string[]) {
        this.prsrv = new PdfReportingService();
        this.prsrv.pdf = new jsPDF ('p', 'mm', 'a4');
        this.ReportTitle.Text = title;
        this.PaintHeader();
        // Ignore Title, header and 3 row Summary
        this.footerIndex = 2;
        for (let i = 2; i < records.length; i++) {
            if (records[i].trim() !== '') {
                this.PaintRecords(records[i], this.footerIndex);
            }
        }
        
        //Paint Total
        this.footerIndex = this.footerIndex % this.FirstPageSize;
        this.PaintTotals(this.TotalLabel, this.footerIndex, true);

        this.PaintTotals(this.TotalValidVisitsLabel, this.footerIndex, false);
        this.PaintTotals(this.TotalValidVisits, this.footerIndex, true);

        this.PaintTotals(this.TotalInvalidVisitsLabel, this.footerIndex, false);
        this.PaintTotals(this.TotalInvalidVisits, this.footerIndex, true);

        this.PaintTotals(this.TotalPayableLabel, this.footerIndex, false);
        this.PaintTotals(this.TotalPayable, this.footerIndex, true);
    }

    public SaveReportToPDFFile(title: string, records: string[], csvfilename: string) {
        this.ExportPdfReport(title, records);
        this.prsrv.SavePdfToFile(csvfilename.replace('.csv', '.pdf'));
    }
}