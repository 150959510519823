import { CommonService } from "src/app/services/common.service";
import { Injectable } from "@angular/core";
import { ERole } from '@fp/enums/role.enum';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { APIConstant, StorageKey } from "src/app/constant";
import {
  AcceptTermsAndConditions,
  CommonData,
  DataResult,
  Member,
  MemberCancellation,
  MemberMedicalQuestion,
  MemberPhoto,
  MemberSearchResultRecord,
  MemberType,
  ResultModel,
  SearchPaginationRequest,
  SearchPaginationResult,
  MembershipLedgerEntry,
  Membership,
  MemberVisitsViewModel,
  BankAccount,
  ReadMembershipLedger,
  ManualVisitSubmit,
  MemberModel,
  UserModel
} from "src/app/models";
import { IMemberTransfer } from "../components/members/employer-transfer/employer-transfer.component";
import { CommonDataType } from "../enums";
import { Utils } from "../helpers";
import {
  ApproveOrRejectMembers,
  MemberVerification,
} from "../models/member-verification.model";
import { CommonDataService } from "./common-data.service";
import { HttpDAO } from "./httpdao.service";
import { MemberRequestInfo } from "@fp/models/member-request-info.model";
import { MemberAdditionalNotes } from "@fp/models/MemberAdditionalNotes.model";
import {
  MemberInvoiceDates,
  MemberTaxInvoiceVM,
} from "@fp/models/member-tax-invoice.model";
import { Router, ActivatedRoute } from "@angular/router";
import { CheckMembersUniqueEmailMobileReponse } from "@fp/models/check-unique-email-mobile-response.model";
import { CheckMembersUniqueEmailMobile } from "@fp/models/check-unique-email-mobile-request.model";

@Injectable({
  providedIn: "root",
})
export class MemberService {
  constructor(
    private httpDao: HttpDAO,
    private commonSVC: CommonDataService,
    private cmsvc: CommonService
  ) {}

  private processMemberData(data: Member) {
    if (!data) {
      return;
    }
    if (typeof data.DateCardIssued === "string") {
      try {
        data.DateCardIssued = Utils.parseUTCDate(data.DateCardIssued);
      } catch (err) {
        console.error(err);
      }
    }
    if (typeof data.DateJoined === "string") {
      try {
        data.DateJoined = Utils.parseUTCDate(data.DateJoined);
      } catch (err) {
        console.error(err);
      }
    }
    if (typeof data.DateOfBirth === "string") {
      try {
        data.DateOfBirth = Utils.parseUTCDate(data.DateOfBirth);
      } catch (err) {
        console.error(err);
      }
    }
    if (typeof data.ExpiryDate === "string") {
      try {
        data.ExpiryDate = Utils.parseUTCDate(data.ExpiryDate);
      } catch (err) {
        console.error(err);
      }
    }
    if (typeof data.LastInactivationDate === "string") {
      try {
        data.LastInactivationDate = Utils.parseUTCDate(
          data.LastInactivationDate
        );
      } catch (err) {
        console.error(err);
      }
    }
    if (typeof data.SuspensionEndDate === "string") {
      try {
        data.SuspensionEndDate = Utils.parseUTCDate(data.SuspensionEndDate);
      } catch (err) {
        console.error(err);
      }
    }
    if (typeof data.SuspensionStartDate === "string") {
      try {
        data.SuspensionStartDate = Utils.parseUTCDate(data.SuspensionStartDate);
      } catch (err) {
        console.error(err);
      }
    }
    if (typeof data.TermConditionDateTimeStamp === "string") {
      try {
        data.TermConditionDateTimeStamp = Utils.parseUTCDate(
          data.TermConditionDateTimeStamp
        );
      } catch (err) {
        console.error(err);
      }
    }
    if (typeof data.UpfrontPaymentDate === "string") {
      try {
        data.UpfrontPaymentDate = Utils.parseUTCDate(data.UpfrontPaymentDate);
      } catch (err) {
        console.error(err);
      }
    }

    data.Memberships = data.Memberships || [];

    if (data.Memberships.length > 0) {
      if (typeof data.Memberships[0].StartDate === "string") {
        try {
          let sd: any = data.Memberships[0].StartDate;
          sd = Utils.parseUTCDate(sd);
          data.Memberships[0].StartDate = sd;
        } catch (err) {
          console.error(err);
        }
      }
      if (typeof data.Memberships[0].EndDate === "string") {
        try {
          let ed: any = data.Memberships[0].EndDate;
          ed = Utils.parseUTCDate(ed);
          data.Memberships[0].EndDate = ed;
        } catch (err) {
          console.error(err);
        }
      }
    }

    if (data.FamilyMembers instanceof Array) {
      data.FamilyMembers.forEach((_member) => {
        this.processMemberData(_member);
      });
    }
  }

  get(id: number): Observable<DataResult<Member>> {
    // const request = { id: id.toString() };
    const memId = id.toString();
    const paging: SearchPaginationRequest = new SearchPaginationRequest();
    if (paging !== null && paging !== undefined)
    {
        paging.Params = [
            { Name: 'caller', Value: this.cmsvc.GetUser() },
            { Name: 'id', Value: memId },
        ];
    }
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(paging));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());

    //********************************** */
    return this.httpDao
      .postData(APIConstant.API_GET_MEMBER, {
        Data: encrypteddata,
        Header: encryptedtoken,
      })
      .pipe(
        map((value: any) => {
          const dectypted_data = this.cmsvc.D_FP_ResponseData(value);
          const rdata: DataResult<Member> = JSON.parse(dectypted_data);
          if (rdata.Success && rdata.Data) {
            this.processMemberData(rdata.Data);
          }
          return rdata;
        })
      );
  }

  getForMember(id: number): Observable<DataResult<Member>> {
    const request = { id: id.toString() };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao
      .postData(APIConstant.API_GET_MEMBER_FOR_MEMBER, {
        Data: encrypteddata,
        Header: encryptedtoken,
      })
      .pipe(
        map((value: any) => {
          const dectypted_data = this.cmsvc.D_FP_ResponseData(value);
          const rdata: DataResult<Member> = JSON.parse(dectypted_data);
          if (rdata.Success && rdata.Data) {
            this.processMemberData(rdata.Data);
          }
          return rdata;
        })
      );
  }

  getByUser(userId: number): Observable<DataResult<Member>> {
    const request = { id: userId.toString() };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao
      .postData(APIConstant.API_GET_MEMBER_BY_USER_ID, {
        Data: encrypteddata,
        Header: encryptedtoken,
      })
      .pipe(
        map((value: any) => {
          const dectypted_data = this.cmsvc.D_FP_ResponseData(value);
          const rdata: DataResult<Member> = JSON.parse(dectypted_data);
          if (rdata.Success && rdata.Data) {
            this.processMemberData(rdata.Data);
          }
          return rdata;
        })
      );
  }

  getByUserForMember(userId: number): Observable<DataResult<Member>> {
    const request = { id: userId.toString() };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao
      .postData(APIConstant.API_GET_MEMBER_BY_USER_ID_FOR_MEMBER, {
        Data: encrypteddata,
        Header: encryptedtoken,
      })
      .pipe(
        map((value: any) => {
          const dectypted_data = this.cmsvc.D_FP_ResponseData(value);
          const rdata: DataResult<Member> = JSON.parse(dectypted_data);
          if (rdata.Success && rdata.Data) {
            this.processMemberData(rdata.Data);
          }
          return rdata;
        })
      );
  }

  getByUserForMemberSignup(userId: number): Observable<DataResult<Member>> {
    const request = { id: userId.toString() };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao
      .postData(APIConstant.API_GET_MEMBER_BY_USER_ID_FOR_MEMBER_SIGNUP, {
        Data: encrypteddata,
        Header: encryptedtoken,
      })
      .pipe(
        map((value: any) => {
          const dectypted_data = this.cmsvc.D_FP_ResponseData(value);
          const rdata: DataResult<Member> = JSON.parse(dectypted_data);
          if (rdata.Success && rdata.Data) {
            this.processMemberData(rdata.Data);
          }
          return rdata;
        })
      );
  }

  update(member: Member): Observable<ResultModel> {
    const bacc = member.BankAccount;
    if (
      bacc !== null &&
      bacc !== undefined &&
      bacc.BankAccountNumber !== null &&
      bacc.BankAccountNumber !== undefined &&
      bacc.BankAccountNumber !== "" &&
      bacc.BankAccountNumber.indexOf("=") > -1
    ) {
      bacc.BankAccountNumber = this.cmsvc.D_FP_AES256ForShortString(
        bacc.BankAccountNumber
      );
    }
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_UPDATE_MEMBER, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }

  updateForMember(member: Member): Observable<ResultModel> {
    const bacc = member.BankAccount;
    if (
      bacc !== null &&
      bacc !== undefined &&
      bacc.BankAccountNumber !== null &&
      bacc.BankAccountNumber !== undefined &&
      bacc.BankAccountNumber !== "" &&
      bacc.BankAccountNumber.indexOf("=") > -1
    ) {
      bacc.BankAccountNumber = this.cmsvc.D_FP_AES256ForShortString(
        bacc.BankAccountNumber
      );
    }
    member.ModifiedBy = this.cmsvc.GetUser();
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_UPDATE_MEMBER_FOR_MEMBER, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }

  updateaddressfamily(memberid: number): Observable<ResultModel> {
    const param = { id: memberid.toString() };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(param));
    return this.httpDao
      .postData(APIConstant.API_UPDATE_ADDRESS_FAMILY_MEMBER, {
        Data: encrypteddata,
      })
      .pipe(
        map((value: any) => {
          const dectypted_data = this.cmsvc.D_FP_ResponseData(value);
          const rdata: DataResult<Member> = JSON.parse(dectypted_data);
          return rdata;
        })
      );
  }

  checkUniqueMobileEmail(request: CheckMembersUniqueEmailMobile[]): Observable<DataResult<string>> {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());

    return this.httpDao
      .postData(APIConstant.API_CHECK_MEMBER_UNIQUE_EMAIL_MOBILE, {
        Data: encrypteddata,
        Header: encryptedtoken,
      });
  }

  updateaddressfamilyformember(memberid: number): Observable<ResultModel> {
    const param = { id: memberid.toString() };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(param));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao
      .postData(APIConstant.API_UPDATE_ADDRESS_FAMILY_MEMBER_FOR_MEMBER, {
        Data: encrypteddata,
        Header: encryptedtoken,
      })
      .pipe(
        map((value: any) => {
          const dectypted_data = this.cmsvc.D_FP_ResponseData(value);
          const rdata: DataResult<Member> = JSON.parse(dectypted_data);
          return rdata;
        })
      );
  }

  updatePaymentDetail(bankAccount: BankAccount): Observable<ResultModel> {
    const bacc = bankAccount;
    if (
      bacc !== null &&
      bacc !== undefined &&
      bacc.BankAccountNumber !== null &&
      bacc.BankAccountNumber !== undefined &&
      bacc.BankAccountNumber !== "" &&
      bacc.BankAccountNumber.indexOf("=") > -1
    ) {
      bacc.BankAccountNumber = this.cmsvc.D_FP_AES256ForShortString(
        bacc.BankAccountNumber
      );
    }
    const encrypteddata = this.cmsvc.E_FP_RequestData(
      JSON.stringify(bankAccount)
    );

    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(
      APIConstant.API_UPDATE_PAYMENT_DETAILS_MEMBER,
      { Data: encrypteddata, Header: encryptedtoken }
    );
  }

  resetMembershipsBcf(membershipId: Membership): Observable<ResultModel> {
    return this.httpDao.postData(
      APIConstant.API_RESET_MEMBERSHIPS_BCF,
      membershipId
    );
  }

  updateSignupInfo(member: Member): Observable<ResultModel> {
    const bacc = member.BankAccount;
    if (
      bacc !== null &&
      bacc !== undefined &&
      bacc.BankAccountNumber !== null &&
      bacc.BankAccountNumber !== undefined &&
      bacc.BankAccountNumber !== "" &&
      bacc.BankAccountNumber.indexOf("=") > -1
    ) {
      bacc.BankAccountNumber = this.cmsvc.D_FP_AES256ForShortString(
        bacc.BankAccountNumber
      );
    }
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_UPDATE_MEMBER_SIGNUP_INFO, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }

  ResetMemberData(member: Member): Observable<ResultModel> {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_RESET_SIGNUP_DATA, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }


  SaveEmployerInfo(member: Member): Observable<ResultModel> {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_SAVE_MEMBER_EMPLOYER_INFO, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }

  SaveMemberPackageDetail(member: Member): Observable<ResultModel> {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_SAVE_MEMBER_PACKAGE_DETAIL, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });

  }

  ReactivateMember(member: Member): Observable<ResultModel> {

    const bacc = member.BankAccount;
    if (
      bacc !== null &&
      bacc !== undefined &&
      bacc.BankAccountNumber !== null &&
      bacc.BankAccountNumber !== undefined &&
      bacc.BankAccountNumber !== "" &&
      bacc.BankAccountNumber.indexOf("=") > -1
    ) {
      bacc.BankAccountNumber = this.cmsvc.D_FP_AES256ForShortString(
        bacc.BankAccountNumber
      );
    }

    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_REACTIVATE_MEMBER, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });

  }

  SaveSignupPersonalInfo(member: Member): Observable<ResultModel> {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_SAVE_MEMBER_SIGNUP_PERSONAL_DETAILS, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }

  SavePaymentInfo(member: Member): Observable<ResultModel> {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_SAVE_MEMBER_PAYMENT_DETAILS, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }

  SaveTermsAndConditions(member: Member): Observable<ResultModel> {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_SAVE_MEMBER_TERMS_AND_CONDITIONS, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }

  getTypes(): Observable<DataResult<MemberType[]>> {
    return this.httpDao.getSingleData(APIConstant.API_GET_MEMBER_TYPES);
  }

  getStatuses(): Observable<DataResult<CommonData[]>> {
    return this.commonSVC.getData(CommonDataType.MemberStatus);
  }

  getMedicalQuestions(): Observable<DataResult<MemberMedicalQuestion[]>> {
    return this.httpDao.getSingleData(
      APIConstant.API_GET_MEMBER_MEDICAL_QUESTIONS
    );
  }

  getMembers(
    searchRequest: SearchPaginationRequest
  ): Observable<DataResult<SearchPaginationResult<MemberVerification>>> {
    const encrypteddata = this.cmsvc.E_FP_RequestData(
      JSON.stringify(searchRequest)
    );
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_GET_MEMBERS, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }

  getInformationRequired(): Observable<DataResult<CommonData[]>> {
    return this.commonSVC.getData(CommonDataType.InformationRequired);
  }

  setInvalidPhoto(memberId: number) {
    var memberIds = new ApproveOrRejectMembers();
    memberIds.MemberIds = [memberId];
    memberIds.ModifiedBy = this.cmsvc.Username;
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(memberIds));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_SET_INVALID_PHOTO, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }

  sendMemberEmail(mri: MemberRequestInfo) {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(mri));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(
      APIConstant.API_SEND_EMAIL_MEMBER_INFO_REQUIRED,
      {
        Data: encrypteddata,
        Header: encryptedtoken,
      }
    );
  }

  approveOrJejectSelectedMember(
    memberIds: ApproveOrRejectMembers,
    isApprove: boolean
  ) {
    let endpoint: string;
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(memberIds));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    if (isApprove) endpoint = APIConstant.API_APPROVE_MEMBERS;
    else if (isApprove == false) endpoint = APIConstant.API_REJECT_MEMBERS;
    return this.httpDao!.postData(endpoint, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }

  //Check Member type and Accept T&C
  checkRegistrationByName(name: string): Observable<string> {
    const request = { name: name };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(
      APIConstant.API_CHECK_REGISTRATION_BY_USERNAME,
      { Data: encrypteddata, Header: encryptedtoken }
    );
  }

  checkRegistrationByNameWithoutToken(
    name: string
  ): Observable<DataResult<any>> {
    return this.httpDao.getSingleDataWithoutToken(
      APIConstant.API_CHECK_REGISTRATION_BY_USERNAME_WITHOUT_TOKEN +
        encodeURIComponent(name)
    );
  }

  // getByUsernameFirstLoginV2(username: string) {
  //   const encrypteddata = this.cmsvc.E_FP_RequestData(username);
  //   const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
  //   return this.httpDao.postData(APIConstant.API_GET_USER_BY_USERNAME_FIRSTLOGIN_V2, { "Data": encrypteddata, "Header": encryptedtoken });
  // }

  signupMemberWithCognitoToken(
    member: MemberModel
  ): Observable<DataResult<UserModel>> {
    return this.httpDao.postData(APIConstant.API_CREATE_MEMBER_WITH_COGNITO_TOKEN, member);
  }

  //For family member login first time
  acceptTermAndCondition(
    member: AcceptTermsAndConditions
  ): Observable<ResultModel> {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_ACCEPT_TERMS_AND_CONDITIONS, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }

  searchv2(
    member: SearchPaginationRequest
  ): Observable<DataResult<SearchPaginationResult<MemberSearchResultRecord>>> {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_SEARCH_MEMBERS_V2, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }

  searchv2formember(
    member: SearchPaginationRequest
  ): Observable<DataResult<SearchPaginationResult<MemberSearchResultRecord>>> {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_SEARCH_MEMBERS_V2_FOR_MEMBER, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }

  searchfacilitymemberpaging(member: SearchPaginationRequest) {
    return this.httpDao.postData(
      APIConstant.API_SEARCH_FACILITY_MEMBERS_PAGING,
      member
    );
  }

    //FP-618
  searchmembersformanaullycheckin(member: SearchPaginationRequest) {
    return this.httpDao.postData(
      APIConstant.API_SEARCH_MEMBERS_FOR_MANUALLY_CHECKIN_PAGING,
      member
    );
  }

  ManualVisitSubmit(params: ManualVisitSubmit): Observable<ResultModel> {
    const encrypteddata = this.cmsvc.E_FP_RequestData(
        JSON.stringify(params)
    );
    return this.httpDao.postData(APIConstant.API_SEARCH_MEMBERS_FOR_MANUALLY_CHECKIN_SUBMIT, { Data: encrypteddata });
  }

  searchpaging(member: SearchPaginationRequest) {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao
      .postData(APIConstant.API_GET_SEARCH_MEMBERS_PAGING, {
        Data: encrypteddata,
        Header: encryptedtoken
      })
      .pipe(
        map((value: any) => {
          const dectypted_data = this.cmsvc.D_FP_ResponseData(value);
          const rdata: DataResult<SearchPaginationResult<
            MemberSearchResultRecord
          >> = JSON.parse(dectypted_data);
          return rdata;
        })
      );
  }

   // Same as above but for EF (different access check)
  searchpagingforef(member: SearchPaginationRequest) {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    return this.httpDao
      .postData(APIConstant.API_GET_SEARCH_MEMBERS_PAGING_FOR_EF, {
        Data: encrypteddata,
      })
      .pipe(
        map((value: any) => {
          const dectypted_data = this.cmsvc.D_FP_ResponseData(value);
          const rdata: DataResult<SearchPaginationResult<
            MemberSearchResultRecord
          >> = JSON.parse(dectypted_data);
          return rdata;
        })
      );
  }

  // Same as above but for FSM (different access check)
  searchpagingforfsm(member: SearchPaginationRequest) {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(member));
    return this.httpDao
      .postData(APIConstant.API_GET_SEARCH_MEMBERS_PAGING_FOR_FSM, {
        Data: encrypteddata,
      })
      .pipe(
        map((value: any) => {
          const dectypted_data = this.cmsvc.D_FP_ResponseData(value);
          const rdata: DataResult<SearchPaginationResult<
            MemberSearchResultRecord
          >> = JSON.parse(dectypted_data);
          return rdata;
        })
      );
  }

  getCancellationReasons(): Observable<DataResult<CommonData[]>> {
    return this.commonSVC.getData(CommonDataType.MemberCancellationReason);
  }

  getCancellationActions(): Observable<DataResult<CommonData[]>> {
    return this.commonSVC.getData(CommonDataType.MemberCancellationAction);
  }

  getMemberCancelation(
    memberId: number
  ): Observable<DataResult<MemberCancellation>> {
    return this.httpDao.getSingleData(
      APIConstant.API_GET_MEMBERCANCELLATION + memberId
    );
  }

  saveMemberCancelation(member: MemberCancellation): Observable<ResultModel> {
    return this.httpDao.postData(
      APIConstant.API_SAVE_MEMBERCANCELLATION,
      member
    );
  }

  MemberCancellationScheduleUpdate(member: MemberCancellation): Observable<ResultModel> {
    return this.httpDao.postData(
      APIConstant.API_SAVE_MEMBERCANCELLATION_ACTION1,
      member
    );
  }

  getMemberCancellationLoad(memberId: number): Observable<any> {
    return this.httpDao.getSingleData(APIConstant.API_DISPLAY_DATA_MEMBERCANCELLATION_FORM + memberId);
  }

  RemoveMemberPhotoForMember(memberId: number, modifiedby: string){
    var queryString = "?id=" + memberId + "&ModifiedBy=" + modifiedby;
    return this.httpDao.getSingleData(APIConstant.API_REMOVE_PHOTO_FOR_MEMBER + queryString);
  }

  changePhoto(params: MemberPhoto): Observable<ResultModel> {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(params));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_CHANGE_PHOTO, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }

  changePhotoForMember(params: MemberPhoto): Observable<ResultModel> {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(params));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(APIConstant.API_CHANGE_PHOTO_FOR_MEMBER, {
      Data: encrypteddata,
      Header: encryptedtoken,
    });
  }

  transferMember(param: IMemberTransfer) {
    const bacc = param.BankAccount;
    if (
      bacc !== null &&
      bacc !== undefined &&
      bacc.BankAccountNumber !== null &&
      bacc.BankAccountNumber !== undefined &&
      bacc.BankAccountNumber !== ""
    ) {
      bacc.BankAccountNumber = this.cmsvc.D_FP_AES256ForShortString(
        bacc.BankAccountNumber
      );
    }
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(param));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao.postData(
      APIConstant.API_TRANSFER_MEMBER_TO_NEW_EMPLOYER,
      { Data: encrypteddata, Header: encryptedtoken }
    );
  }

  updateMembershipLedger(
    MembershipLedgerEntry: MembershipLedgerEntry
  ): Observable<any> {
    return this.httpDao.postData(
      APIConstant.API_UPDATE_MEMBERSHIP_LEDGER,
      MembershipLedgerEntry
    );
  }

  getMemberNotification(userName: string) {
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(userName));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao
      .postData(APIConstant.API_GET_MEMBER_NOTIFICATION, {
        Data: encrypteddata,
        Header: encryptedtoken,
      })
      .pipe(
        map((value: any) => {
          const dectypted_data = this.cmsvc.D_FP_ResponseData(value);
          const rdata: Observable<DataResult<
            MemberAdditionalNotes
          >> = JSON.parse(dectypted_data);
          return rdata;
        })
      );
  }

  readMembershipLedger(
    memberID: string
  ): Observable<DataResult<MembershipLedgerEntry[]>> {
    let apistring =
      APIConstant.API_READ_MEMBERSHIP_LEDGER + "?membernumber=" + memberID;
    return this.httpDao.getSingleData(apistring);
  }

  getMemberVisits(
    MemberId: string
  ): Observable<DataResult<MemberVisitsViewModel[]>> {
    let apistring = APIConstant.API_GET_MEMBER_VISITS + MemberId;
    return this.httpDao.getSingleData(apistring);
  }

  getMemberVisitsFilter(
    MemberId: string,
    period: Date
  ): Observable<DataResult<MemberVisitsViewModel[]>> {
    let apistring = APIConstant.API_GET_MEMBER_VISITS + MemberId + "&name=" + period.getFullYear() + " / " + (period.getMonth()+1);
    return this.httpDao.getSingleData(apistring);
  }

  getMemberTaxInvoiceDatesById(MemberId: string) {
    const encrypteddata = this.cmsvc.E_FP_RequestData(MemberId);
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao
      .postData(APIConstant.API_GET_MEMBER_TAX_INVOICE_DATES, {
        Data: encrypteddata,
        Header: encryptedtoken,
      })
      .pipe(
        map((value: any) => {
          const dectypted_data = this.cmsvc.D_FP_ResponseData(value);
          const rdata: DataResult<MemberInvoiceDates[]> = JSON.parse(
            dectypted_data
          );
          return rdata;
        })
      );
  }
  /**
   * Gets the data for a single Invoice
   * @param MemberId The ID of the Member to get the invoice for
   * @param PaymentEndDate The Last Date of the payment period in format "yyyy/mm/dd"
   */
  getMemberTaxInvoiceDataById(
    MemberId: string,
    PaymentEndDate: string
  ): Observable<DataResult<MemberTaxInvoiceVM>> {
    const request = { id: MemberId, name: PaymentEndDate };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
    return this.httpDao
      .postData(APIConstant.API_GET_MEMBER_TAX_INVOICE_INFO, {
        Data: encrypteddata,
        Header: encryptedtoken,
      })
      .pipe(
        map((value: any) => {
          // const dectypted_data = this.cmsvc.D_FP_ResponseData(value);
          const rdata = value;
          return rdata;
        })
      );
  }
}

class ResultModel1 extends ResultModel {
  MembeUnactiveIds: Array<number>;
}

const DATA = {
  MembeUnactiveIds: null,
  Name: null,
  Message:
    "An error occurred while updating the entries. See the inner exception for details.\nCannot insert the value NULL into column 'MemberNumber', table 'rds_fpdev.dbo.MemberCard'; column does not allow nulls. INSERT fails.\nThe statement has been terminated.",
  WaitInSeconds: 0,
  Success: false,
  Errors: [
    "An error occurred while updating the entries. See the inner exception for details.",
    "   at Microsoft.EntityFrameworkCore.Update.ReaderModificationCommandBatch.Execute(IRelationalConnection connection)\n   at Microsoft.EntityFrameworkCore.Update.Internal.BatchExecutor.Execute(DbContext _, ValueTuple`2 parameters)\n   at Microsoft.EntityFrameworkCore.SqlServer.Storage.Internal.SqlServerExecutionStrategy.Execute[TState,TResult](TState state, Func`3 operation, Func`3 verifySucceeded)\n   at Microsoft.EntityFrameworkCore.Update.Internal.BatchExecutor.Execute(IEnumerable`1 commandBatches, IRelationalConnection connection)\n   at Microsoft.EntityFrameworkCore.ChangeTracking.Internal.StateManager.SaveChanges(IReadOnlyList`1 entriesToSave)\n   at Microsoft.EntityFrameworkCore.ChangeTracking.Internal.StateManager.SaveChanges(Boolean acceptAllChangesOnSuccess)\n   at Microsoft.EntityFrameworkCore.DbContext.SaveChanges(Boolean acceptAllChangesOnSuccess)\n   at FP.Serverless.Tasks.MemberVerificationTasks.Approve(LambdaRequest input, ILambdaContext context) in /codebuild/output/src018046682/src/FP.Serverless/FP.Serverless/Tasks/MemberVerificationTasks.cs:line 315",
  ],
  ErrorNumber: 0,
};
