<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">
    Add Postcodes
</h4>

<div class="bulk-upload-wrapper">
    <!-- Main Mat Spinner -->
    <div class="loading-shade-global" *ngIf="isLoading">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <div class="form-group row">
        <div class="col-sm-12">
            <textarea readonly class="form-control text-primary" rows="8">
                This screen enables you to upload bulk of Postcodes. To proceed, continue as follows:
                1. Select "Country", "State", "FP Region" for the new postcodes.
                2. Download the CSV template provided as a link by the upload button.
                2. Open file and add as many rows as required by the columns provided.
                3. Review the entered data is correct.
                4. Click "Upload File" and select the modified CSV.
            
              If any errors exist a pop-up dialog box will direct you to the erroneous line and no transactions will be posted.</textarea>
        </div>
    </div>

    <div [formGroup]="BulkUploadFormGroup" class="row justify-content-between mt-4">
        <div class="col-md-6 col-lg-5">
            <div class="form-group row">
                <label for="txtCountryPhy" class="col-md-3 col-form-label">Country<span
                        class="text-danger">*</span></label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <select class="form-control" id="ddlCountryPhy" formControlName="ddlCountryPhy"
                            (ngModelChange)="checkCountry($event)">
                            <option *ngFor="let country of countries" [ngValue]="country">
                                {{ country.Name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-5">
            <div class="form-group row">
                <label for="txtStatePhy" class="col-md-3 col-form-label">State<span class="text-danger">*</span></label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <select class="form-control" id="ddlStatePhy" formControlName="ddlStatePhy">
                            <option [ngValue]="null" *ngIf="stateSelection" selected>
                                {{ CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT }}
                            </option>
                            <option *ngFor="let state of states" [ngValue]="state">
                                {{ state.Name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-100 mt-3"></div>
        <div class="col-md-6 col-lg-5">
            <div class="form-group row">
                <label for="txtRegionPhy" class="col-md-3 col-form-label">FP Region<span
                        class="text-danger">*</span></label>
                <div class="col-md-9">
                    <div class="input-group">
                        <input class="form-control" formControlName="searchRegionName" type="text" matInput
                            [matAutocomplete]="regionAutocomplete" [(ngModel)]="searchRegion"
                            (ngModelChange)="searchRegions()" />
                        <div class="input-group-append">
                            <button class="input-group-text" type="submit">
                                <i class="fas fa-search text-primary"></i>
                            </button>
                        </div>
                        <mat-autocomplete class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                            #regionAutocomplete="matAutocomplete" [displayWith]="AutoComplete_DisplayWithFn">
                            <mat-option *ngFor="let region of regions" [value]="region"
                                class="border-default border-bottom py-1">
                                <p class="m-0 d-flex align-items-center">
                                    {{ region.Name }}
                                </p>
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-lg-5">
            <div class="form-group row justify-content-end">
                <div class="col-md-9 text-right">
                    <div class="form-align2 ml-md-auto">
                        <a href="../../../../assets/sample_csv/Post_Code_Upload_Template.csv"><u>Download Bulk Postcode
                                Upload template</u>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-100 mt-4"></div>
        <div class="col-md-6 col-lg-5">
            <div class="row justify-content-end">
                <div class="col-md-9">
                    <app-fp-file-upload-r2 filterFile=".csv" fileCategory="bulkUploadPostode"
                        buttonlabel="CLICK TO UPLOAD POSTCODES" (UploadCompleted)="AddFile_UploadCompleted($event)"
                        [isUploadCompletedDefault]="false" [columnParams]="colParameter"
                        [requiresCheck]="checkNecessary" [buttonStyle]="buttonStyle" [showProgressBar]="true"
                        [isDisabled]="!BulkUploadFormGroup.valid">
                    </app-fp-file-upload-r2>
                    <div class="mt-4">
                        <app-describe-state-machine [MachineType]="StepFunctions.BulkUploadPostcodeDetail"
                            (SuccessReturned)="handleBulkUploadResponse($event)"></app-describe-state-machine>
                    </div>

                </div>
                <div class="col-md-9 mt-2">
                    <div class="btn-history">
                        <button id="btnGenerateReport" class="btn btn-primary py-2" style="width: 100%" type="button"
                            (click)="btnHistory_Click()">
                            <i class="fas fa-file-invoice mr-2"></i>
                            History
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-fp-progress-bar #progressBar></app-fp-progress-bar>