import { ERole } from './../../../enums/role.enum';
import { RequestPagination, ParamPagination } from 'src/app/models';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpDAO } from '../../../services/httpdao.service';
import { CommonService } from '../../../services/common.service';
import { ActivatedRoute } from '@angular/router';
import { APIConstant } from '../../../constant/apiconstant';
import { Router } from '@angular/router';
import { RouterConstant } from '../../../constant/routerconstant';
import { CommonResponse } from '../../../models/commonresponsemodel';
import { CommonString } from 'src/app/constant/common-string';

@Component({
  selector: 'app-vieweditemployer',
  templateUrl: './vieweditemployer.component.html',
  styleUrls: ['./vieweditemployer.component.css']
})
export class VieweditemployerComponent implements OnInit {
  displayedColumns: string[] = ['Name', 'FullName', 'RegionName', 'Status', 'Code', 'Edit'];
  dataSource = new MatTableDataSource();
  httpdao: HttpDAO | null;
  routerConstant: RouterConstant;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  regions: CommonResponse[] = [];
  selectedRegionID: number;
  isCompleteSearch = false;

  // Paging
  length: number;
  pageSize: number;
  pageSizeOptions: number[];
  pageEvent: PageEvent;
  paging: RequestPagination = new RequestPagination();

  get noRecordFoundText(): string {
    return CommonString.TABLE_NO_RECORD_FOUND;
  }

  constructor(
    private http: HttpClient,
    private commonservice: CommonService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.routerConstant = RouterConstant;
    this.pageSizeOptions = CommonString.PAGE_SIZE_OPTIONS;
    this.paging.PageSize = CommonString.INIT_PAGE_SIZE;

    this.httpdao = new HttpDAO(this.http);
    const regionid = this.route.snapshot.params.regionid;
    this.commonservice.RegionID = parseInt(regionid, 10);
    const searchparam = this.route.snapshot.params.searchparam;


    if (regionid !== undefined && regionid !== null) {
      this.GetEmployer(this.commonservice.RegionID);
    } else if (searchparam !== undefined && searchparam !== null) {
      this.GetAllRegion(searchparam);
    } else {
      this.router.navigate([RouterConstant.ROUTER_PAGE_NOT_FOUND]);
    }


    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public btnEdit_Click(row) {
    this.router.navigate([RouterConstant.NAVIGATOR_EMPLOYER_EDIT + row.EmployerId]);
    // window.open(RouterConstant.NAVIGATOR_EMPLOYER_EDIT + row.EmployerId,"_blank");
  }

  private GetAllRegion(search: string) {
    this.paging.Params[0].Name = 'search';
    this.paging.Params[0].Value = search;
    this.paging.OrderBy = 'name';
    const selectrole = this.commonservice.GetSelectedRole();
    if (selectrole === ERole.Employer_Facilitator) {
      const pp = new ParamPagination();
      pp.Name = 'userid';
      pp.Value = this.commonservice.GetUserObject().UserId;
      this.paging.Params.push(pp);
    }
    merge().pipe(
      startWith({}),
      switchMap(() => {
        this.commonservice.StartProgressBar();
        const encrypteddata = this.commonservice.E_FP_RequestData(JSON.stringify(this.paging));
        return this.httpdao.postData(APIConstant.API_GET_EMPLOYER_IN_ALL_REGION, { "Data": encrypteddata });
      }),
      map(data => {
        return data;
      }),
      catchError(() => {
        this.commonservice.StopProgressBar();
        return observableOf([]);
      })
    ).subscribe(edata => {
      const dectypted_data = this.commonservice.D_FP_ResponseData(edata);
      const rdata = JSON.parse(dectypted_data);
      this.isCompleteSearch = true;
      this.length = rdata.Data.TotalItem;
      if (rdata != null) {
        this.dataSource = new MatTableDataSource<any>(rdata.Data.Results);
        this.dataSource.sort = this.sort;
      } else {
        this.dataSource.data = [];
      }
      this.commonservice.StopProgressBar();
    });
  }

  private GetEmployer(regionid: number) {
    this.paging.Params[0].Name = 'id';
    this.paging.Params[0].Value = regionid.toString();
    this.paging.OrderBy = 'name';
    const selectrole = this.commonservice.GetSelectedRole();
    if (selectrole === ERole.Employer_Facilitator) {
      const pp = new ParamPagination();
      pp.Name = 'userid';
      pp.Value = this.commonservice.GetUserObject().UserId;
      this.paging.Params.push(pp);
    }
    merge().pipe(
      startWith({}),
      switchMap(() => {
        this.isCompleteSearch = false;
        this.commonservice.StartProgressBar();
        if (regionid.toString() === '' || regionid == null || regionid == undefined || regionid === 0) {
          this.router.navigate([RouterConstant.ROUTER_PAGE_NOT_FOUND]);
        } else {
          const encrypteddata = this.commonservice.E_FP_RequestData(JSON.stringify(this.paging));
          return this.httpdao!.postData(APIConstant.API_GET_EMPLOYER_PAGING_IN_REGION, { "Data": encrypteddata });
        }
      }),
      map(data => {
        this.commonservice.StopProgressBar();
        return data;
      }),
      catchError(() => {
        this.commonservice.StopProgressBar();
        return observableOf([]);
      })
    ).subscribe(edata => {
      const dectypted_data = this.commonservice.D_FP_ResponseData(edata);
      const rdata = JSON.parse(dectypted_data);
      this.selectedRegionID = regionid;
      this.isCompleteSearch = true;
      this.length = rdata.Data.TotalItem;
      if (rdata != null) {
        this.dataSource = new MatTableDataSource<any>(rdata.Data.Results);
        this.dataSource.sort = this.sort;
      } else {
        this.dataSource.data = [];
      }
    });
    this.selectedRegionID = regionid;
  }

  public ddlLocation_Change(event) {
    this.GetEmployer(event.target.value);
  }

  public btnAddNewEmployer_Click() {
    this.router.navigate([RouterConstant.NAVIGATOR_EMPLOYER_ADDNEW]);
  }

  public btnbackEmployer_Click() {
    this.router.navigate(['/' + RouterConstant.ROUTER_DASHBOARD + '/' + RouterConstant.ROUTER_EMPLOYER_SEARCH]);
  }

  paginatorChange(event: PageEvent) {
    this.paging.PageNumber = event.pageIndex + 1;
    this.paging.PageSize = event.pageSize;
    const regionid = this.route.snapshot.params.regionid;
    this.commonservice.RegionID = parseInt(regionid, 10);
    const searchparam = this.route.snapshot.params.searchparam;
    if (regionid !== undefined && regionid !== null) {
      this.GetEmployer(this.commonservice.RegionID);
    } else if (searchparam !== undefined && searchparam !== null) {
      this.GetAllRegion(searchparam);
    } else {
      this.router.navigate([RouterConstant.ROUTER_PAGE_NOT_FOUND]);
    }

  }
}

