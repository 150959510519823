import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { CommonString } from 'src/app/constant/common-string';
import { RouterConstant } from 'src/app/constant/routerconstant';
import { DataSourceHelper } from 'src/app/helpers';
import { DataResult, Region, State, RequestPagination, SearchPaginationResult, SearchPaginationRequest } from 'src/app/models';
import { MessageBox } from 'src/app/services/common-dialog.service';
import { CommonService } from 'src/app/services/common.service';
import { RegionService } from 'src/app/services/region.service';

@Component({
    selector: 'app-vieweditregions',
    templateUrl: './vieweditregions.component.html',
    styleUrls: ['./vieweditregions.component.css']
})
export class VieweditregionsComponent implements OnInit {
    stateName = '';
    currentState: State;
    regions: Observable<Region[]>;

    displayedColumns: string[] = ['Name', 'Country', 'State', 'Status', 'edit'];
    dataSource: MatTableDataSource<Region>;
    paging: SearchPaginationRequest = new SearchPaginationRequest();

    length: number;
    pageSize: number;
    pageSizeOptions: number[] = [10, 20, 50];
    pageEvent: PageEvent;

    // #region Constant references
    CommonString = CommonString;
    RouterConstant = RouterConstant;
    // #endregion Constant references

    constructor(
        private svc: RegionService,
        private commonSvc: CommonService,
        public location: Location,
        private route: ActivatedRoute,
        public router: Router,
        private dialog: MatDialog) { }

    ngOnInit() {
        this.paging.OrderBy = 'name';
        this.paging.ViewColumns = ['name', 'state_name', 'country_name'];
        this.LoadState();
    }

    private errorHandler(error, message: string = null) {
        MessageBox.ShowError(this.dialog, message || 'An error occurred while trying to call a service');
        console.error(error);
    }

    Invoke(source: Observable<any>, handleResultCallback: Function) {
        this.commonSvc.StartProgressBar();
        source.pipe(
            catchError((e) => { throw e; }),
            finalize(() => { this.commonSvc.StopProgressBar(); }))
            .subscribe(
                res => { handleResultCallback(res); },
                err => { this.errorHandler(err); }
            );
    }

    LoadState() {
        const id = parseInt(this.route.snapshot.queryParamMap.get('id'), 10);
        this.stateName = this.route.snapshot.queryParamMap.get('name');
        if (id > 0) {
            this.LoadRegionByStateId(id);
        }
    }

    LoadRegionByStateId(id: number) {
        this.paging.Params = [{ Name: 'state_id', Value: id }];
        this.Invoke(
            this.svc.search(this.paging),
            (result: DataResult<SearchPaginationResult<Region>>) => {
                if (result.Success) {
                    this.dataSource = new MatTableDataSource<Region>(result.Data.Results);
                    this.length = result.Data.TotalItem;
                    this.dataSource.sortingDataAccessor = DataSourceHelper.localeLowerCaseSortingDataAccessor;
                } else {
                    //this.errorHandler(result);
                    // Return 'No record found' when not any Region in that State be selected
                    this.dataSource = new MatTableDataSource<Region>();
                }
            }
        );
    }

    paginatorChange(event: PageEvent) {
        this.paging.PageNumber = event.pageIndex + 1;
        this.paging.PageSize = event.pageSize;
        this.LoadState();
    }
    sortChange(sort: Sort) {
        const active: string = sort.active.toLowerCase();
        const isOderByAsc: boolean = sort.direction == 'asc' ? false : true;
        this.paging.OrderBy = active;
        this.paging.OrderByAsc = isOderByAsc;
        this.LoadState();
    }
}
