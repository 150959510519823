import { Injectable } from '@angular/core';
import { HttpDAO } from ".";
import { Observable } from "rxjs";
import { AWSDescribeExecutionResponse, AWSStartExecutionResponse, CreateDebitProcessing, DataResult, CDPEState, SaveRawVisit } from "@fp/models";
import { StepFunctions } from "@fp/enums/step-functions.enum";
import { APIConstant } from "@fp/constant";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AwsServiceService {
  private httpDao: HttpDAO | null;

  constructor(httpClient: HttpClient) {
    this.httpDao = new HttpDAO(httpClient);
  }

  /**
   * Returns the object describing the execution of the state machine linked.
   * @param ExecutionArn The Arn of the Exection of the State Machine to Query
   */
  DescribeStepExecution(ExecutionArn: string): Observable<AWSDescribeExecutionResponse<any>> {
    return this.httpDao.getSingleData(
      APIConstant.API_DESCRIBE_STEP_FUNCTION + "?executionarn=" + ExecutionArn);
  }

  /**
   * Starts a version of the Listed Step Function
   * @param StepFunction The Step Function to Start
   * @param id The ID Value to give the Step Fn
   * @param modifiedby The ModifiedBy Value to give the Step Fn
   * @param name The Name value to give the Step Fn
   */
  StartStepExecution(
    StepFunction: StepFunctions,
    id?, modifiedby?, name?, data?
  ): Observable<AWSStartExecutionResponse> {
    switch (StepFunction) {
      case StepFunctions.CreateDebitProcessingEntry:
        return this.httpDao.getSingleData(
          APIConstant.API_CREATE_DEBIT_PROCESSING_ENTRY +
          "?id=" + id +
          "&modifiedby=" + modifiedby + "&name=" +
          encodeURIComponent(name)
        );

      case StepFunctions.UploadFailedTransactions:
        return this.httpDao.getSingleData(
          APIConstant.API_UPLOAD_FAILED_TRANSACTIONS +
          "?id=" + encodeURIComponent(id) +
          "&name=" + name 
        );

      case StepFunctions.ConfirmUploadFailedTransactions:
        return this.httpDao.getSingleData(APIConstant.API_CONFIRM_UPLOAD_FAILED_TRANSACTIONS +
          "?id=" + encodeURIComponent(id) +
          "&name=" + name +
          "&modifiedby=" + modifiedby);

      case StepFunctions.CheckRawVisits:
        return this.httpDao.getSingleData(
          APIConstant.API_CHECK_RAW_VISITS +
          "?name=" + name
        );

      case StepFunctions.SaveRawVisits:
        return this.httpDao.postData(
          APIConstant.API_SAVE_RAW_VISITS, data);

      case StepFunctions.RegenerateAbas:
        return this.httpDao.postData(
          APIConstant.API_REGENERATE_ABA_FILES, data);

      case StepFunctions.BulkUpload:
        return this.httpDao.getSingleData(
          APIConstant.API_BULK_UPLOAD +
          "?name=" + name +
          "&modifiedby=" + modifiedby
        );

      case StepFunctions.BulkUploadBankDetail:
        return this.httpDao.getSingleData(
          APIConstant.API_BULK_UPLOAD_BANK_CODE +
          "?id=" + id +
          "&name=" + name +
          "&modifiedby=" + modifiedby
        );

      case StepFunctions.BulkUploadPostcodeDetail:
        return this.httpDao.postData(
          APIConstant.API_BULK_UPLOAD_POSTCODE, data
        );

      case StepFunctions.FinaliseAbas:
        return this.httpDao.postData(
          APIConstant.API_FINALISE_ABA, data);

      case StepFunctions.MemberMasterExport:
        return this.httpDao.postData(
          APIConstant.API_GENERATE_MEMBER_MASTER_REPORT, data);
      
      case StepFunctions.MemberMasterExportWithoutSensitiveData:
        return this.httpDao.postData(
          APIConstant.API_GENERATE_MEMBER_MASTER_REPORT_WITHOUT_SENSITIVE_DATA, data);

      case StepFunctions.MemberMasterExportWithoutSensitiveData:
        return this.httpDao.postData(
          APIConstant.API_GENERATE_MEMBER_MASTER_REPORT_WITHOUT_SENSITIVE_DATA, data);

      case StepFunctions.MembershipPackageUpdateByCSVFile:
        return this.httpDao.getSingleData(
          APIConstant.API_UPDATE_CSV_FILE + data      
        );

      default:
        return null;
    }
  }
}
