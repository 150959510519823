import { CheckRawVisitCriteria } from "./check-raw-visit-criteria.model";
import { RawVisitCSV } from "./raw-visit-csv.model";

export class SaveRawVisit {
    body: body;
}
export class body {
    ErrorDataMemberNotExistCount: number;
    ErrorDataMemberStatusInvalidCount: number;
    ErrorDataMemberIneligibleCount: number;
    ErrorDataFacilityServiceInactiveCount: number;
    ErrorDataFacilityServiceNonExistentCount: number;

    TotalRows: number;

    CheckRawVisitCriteria: CheckRawVisitCriteria;
    CSVFileData: RawVisitCSV;
    TotalErrorRows: number;
    InsertedBy: string;
}
