<div matDialogTitle class="modal-header justify-content-start">
    <h5 class="text-primary">
        Edit Payment Request
        <button type="button" class="close" aria-label="Close" mat-dialog-close>
            <span aria-hidden="true">&times;</span>
        </button>
    </h5>
</div>

<div matDialogContent>
    <!-- Main Mat Spinner -->
    <div class="loading-shade-global" *ngIf="isLoading">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <div class="form-align">
        <!--Facility and the text box-->
        <br />
        <div class="form-group row">
            <label class="col-md-3 col-lg-2 col-form-label" class="text-primary form-header"><b>Facility</b></label>
            <div class="col-md-3 col-lg-6">
                <input class="form-control" type="text" [value]="facilityName" readonly />
            </div>
        </div>

        <br />

        <!--Service and the text box-->
        <div class="form-group row">
            <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label"
                class="text-primary form-header"><b>Service</b></label>
            <div class="col-md-3 col-lg-6">
                <input class="form-control" type="text" [value]="serviceName" readonly />
            </div>
        </div>

        <br />

        <!--Payment Period-->

        <div class="form-group row">
            <label for="txtrDescription" class="text-primary payment-period-desc"><b>Payment Period</b></label>
            <div style="display: inline-block">
                <label style="display: block" class="text-primary"><b>From</b></label>
                <div name="fromDate" class="input-group">
                    <input class="form-control" type="text" [value]="fromDate | dateFormatPipe" readonly />
                </div>
            </div>

            &nbsp;
            <div style="display: inline-block">
                <label style="display: block" class="text-primary"><b>To</b></label>
                <div name="toDate" class="input-group">
                    <input class="form-control" type="text" [value]="toDate | dateFormatPipe" readonly />
                </div>
            </div>
        </div>
        <br />

        <!--Visits in the System - Text Box and Button to Add manual visits-->

        <div class="form-group row">
            <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label"
                class="text-primary form-header"><b>Visits
                    in the System</b></label>
            <div class="col-md-5 col-lg-2">
                <div class="d-flex flex-column w-100">
                    <input class="form-control" type="text" readonly [value]="visitAmount" />
                </div>
            </div>

            <button mat-button (click)="openDialog()" class="btn btn-primary btn-sm">
                Manually Add Visits
            </button>
        </div>

        <!--Total Visits for this payment text box-->
        <div class="form-group row">
            <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label"
                class="text-primary form-header"><b>Total
                    Visits for this Payment</b><span class="text-danger"> *</span></label>

            <div class="col-md-5 col-lg-2">
                <div class="d-flex flex-column w-100">
                    <input class="form-control" type="number" min="0" required [value]="additionalVisitsAmount"
                        id="finalVisit" (keyup)="perVisitCalc()"/>
                </div>
            </div>
        </div>
        <br />
        <div class="form-group row">
            <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label" class="text-primary"><b>System
                    Calculated
                    Payment</b></label>

            <div class="col-md-5 col-lg-2">
                <div class="d-flex flex-column w-100">
                    <input class="form-control" type="text" id="calculatedPayment" readonly
                        [value]="paymentAmount | currency" />
                </div>
            </div>
            <div *ngIf = "isFirstPR">
                    <button type="button" class="btn btn-primary form-button" (click)="finalChange()">
                        Recalculate Payment by Total
                    </button>
                </div>
        </div>

        <!-- Payment Method and Payment Visit Info -->
        <div class="form-group row">
                <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label"
                    class="text-primary form-header"><b>Payment Method</b></label>
                <div class="col-md-5 col-lg-3">
                    <div class="d-flex flex-column w-100">
                        <input class="form-control" type="text" id="paymentMethod" readonly
                            [value]="PRCalculationMethod" />
                    </div>
                </div>
            </div>

        <!-- Sanity Check Calculation info -->
        <div class="form-group row">
                <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label"
                    class="text-primary form-header"><b>Per Visit Calculation Check</b></label>
                <div class="col-md-5 col-lg-2">
                    <div class="d-flex flex-column w-100">
                        <input class="form-control" type="text" id="calcCheck" readonly
                            [value]="perVisitCalcCheck | currency" />
                    </div>
                </div>
                Final Amount to Pay / Total Visits for this Payment
            </div>

        <!--Amount to Pay text box and Recalculate Payment by Total-->
        <div class="form-group row">
            <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label"
                class="text-primary form-header"><b>Final
                    Amount to Pay</b><span class="text-danger"> *</span></label>
            <div class="col-md-5 col-lg-2">
                <div class="d-flex flex-column w-100">
                        <input class="form-control" type="text" min="0" id="finalPayment" (change)="manualTotalPayChange()" 
                        [value]="finalPayment | currency" (keyup)="perVisitCalc()"/>
                </div>
            </div>
        </div>

        <!--Invoice number text box, attach invoice button and show file name-->
        <div class="form-group row">
            <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label" class="text-primary form-header"
                required><b>Invoice Number</b></label>

            <div class="col-md-5 col-lg-2">
                <div class="d-flex flex-column w-100">
                    <input class="form-control" type="text" id="invoiceNumber" required />
                </div>
            </div>

            <div>
                <label for="Attach_invoice" class="px-5 py-2 btn btn-primary btn-sm">
                    <input type="file" class="hidden-file-input" id="Attach_invoice" (change)="attachInvoice($event)" onclick="this.value=null;"/>
                    <i class="fas fa-upload"></i>
                    Attach Invoice
                </label>
                <label for="Attach_invoice" class="col-md-3 col-lg-2 col-form-label d-table">{{ documentName }}</label>

                <!--bin button-->
                <button class="btn btn-light" (click)="removeInvoice()">
                    <i class="fas fa-trash-alt" style="color:red"></i>
                </button>
                &nbsp;&nbsp;
            </div>
        </div>

        <form [formGroup]="PaymentRequestNoteFormGroup">
            <div class="form-group row">
                <label for="paymentRequestNoteText" class="col-md-3 col-lg-2 col-form-label"
                    class="text-primary form-header"><b>Notes</b></label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <textarea class="form-control" id="paymentRequestNoteText" rows="3"
                            formControlName="paymentRequestNoteText" (change)="noteInputs($event)"></textarea>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-3 offset-md-3 offset-lg-2">
                    <div class="d-flex flex-column w-100">
                        <button class="px-2 py-2 btn btn-primary text-transform-none" type="button"
                            (click)="addPaymentRequestNotes()" [disabled]="btnAddNoteClick">
                            <i class="fas fa-plus-circle mr-2"></i>Add Note</button>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-9 col-lg-10 offset-md-3 offset-lg-2">
                    <div class="d-flex flex-column w-100">
                        <div class="border-default rounded-10 border-2">
                            <div class="tablecontainer">
                                <table mat-table [dataSource]="dtmatnotes" class="rounded-10">

                                    <!-- User Column -->
                                    <ng-container matColumnDef="Username">
                                        <th mat-header-cell *matHeaderCellDef>User</th>
                                        <td mat-cell *matCellDef="let element"> {{element.User}} </td>
                                    </ng-container>

                                    <!-- DateTime Column -->
                                    <ng-container matColumnDef="DateTimeDisplay">
                                        <th mat-header-cell *matHeaderCellDef>Date/Time</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.DateTime }} </td>
                                    </ng-container>

                                    <!-- Note Column -->
                                    <ng-container matColumnDef="Note">
                                        <th mat-header-cell *matHeaderCellDef>Note</th>
                                        <td mat-cell *matCellDef="let element"> {{element.Note}} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns;">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>


        <!--Submit button-->
    </div>
</div>
<div mat-dialog-actions class="modal-footer">
    <button mat-button (click)="submitButton()" class="btn btn-primary">
        <b>Submit</b>
    </button>
</div>