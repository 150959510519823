import { Component, OnInit, ChangeDetectorRef, ElementRef, OnDestroy, Injector } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ViewChild } from '@angular/core';
import { PatternConstant } from 'src/app/constant/patternconstant';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import {
    User, CommonResponse, UserEmployer,
    UserUpdating, AssignFacilitatorEmployer, AssignFacilitatorEmployerUpdate, DataResult,
    MFAType
} from 'src/app/models';
import { FPAbstractComponent } from '@fp/components/base';

import { UserService } from 'src/app/services/admin/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { APIConstant } from 'src/app/constant/apiconstant';
import { MessageBox } from 'src/app/services/common-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { CommonString } from 'src/app/constant/common-string';
import { merge, empty, Observable } from 'rxjs';
import { catchError, startWith, switchMap, finalize, map } from 'rxjs/operators';
import { DialogResult } from '@fp/components/common-dialog/common-dialog.component';
import { RequestPincode } from '@fp/models/forget-password.request-pincode.model';
import { CommonConstants } from '@fp/constant/common-constants';
import { MobileNumberComponent } from '@fp/components/control/mobile-number/mobile-number.component';

@Component({
    selector: 'app-editusers',
    templateUrl: './editusers.component.html',
    styleUrls: ['./editusers.component.css']
})
export class EditusersComponent extends FPAbstractComponent implements OnInit, OnDestroy {
    parentFocus: number = 0;
    tabHeight: string;
    btnNextClicked: boolean = false;
    userDetailsFormGroup: UntypedFormGroup;
    rolesFormGroup: UntypedFormGroup;
    accountFormGroup: UntypedFormGroup;
    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('stepperWrapperRef') stepperWrapperRef: ElementRef;
    @ViewChild("mobileNumberComponent") mobileNumberComponent: MobileNumberComponent<any>;
    user: User = new User;
    roles: CommonResponse[] = [];
    titles: CommonResponse[] = CommonConstants.GET_TITLES;
    genders: CommonResponse[] = CommonConstants.GET_GENDER_NAMES;
    roleTypesSelected: CommonResponse[] = [];
    roleTypesSelectedId: Array<number> = [];
    commonString = CommonString;

    //Role: employer facilitator
    facilitators: CommonResponse[];
    searchFacilitators: AssignFacilitatorEmployerUpdate[] = [];
    facilitatorsSelected: AssignFacilitatorEmployerUpdate[] = [];

    //Role: facility
    facilities: CommonResponse[];
    searchFacilities: CommonResponse[] = [];
    facilitiesSelected: CommonResponse[] = [];

    presentTime: NgbDateStruct;
    minDate: NgbDateStruct;
    addFacility: UntypedFormControl = new UntypedFormControl();
    facilitySpinner: object = { isBool: false };
    facilitatorSpinner: object = { isBool: false };

    invalidPhone = false;
    mobileLength: number;
    mailSpinner = false;
    phoneSpinner = false;
    editCurrentMail = "";
    editCurrentPhone = "";
    requestPincodeInput: RequestPincode;

    mfaEmailOption = {
        value: MFAType.EMAIL,
        label: 'Email'
    } as const
    mfaSMSOption = {
        value: MFAType.SMS,
        label: 'SMS'
    } as const

    ngOnDestroy() {
        this.parentFocus = null;
        this.tabHeight = null;
        this.user = null;
        this.roles = null;
        this.titles = null;
        this.genders = null;
        this.roleTypesSelected = null;
        this.roleTypesSelectedId = null;
        this.facilitators = null;
        this.searchFacilitators = null;
        this.facilitatorsSelected = null;
        this.facilities = null;
        this.searchFacilities = null;
        this.facilitiesSelected = null;
        this.presentTime = null;
        this.minDate = null;
        this.addFacility = null;
        this.mobileLength = null;
        this.editCurrentMail = null;
        this.editCurrentPhone = null;
    }

    constructor(
        injector: Injector,
        private formBuilder: UntypedFormBuilder,
        private commonService: CommonService,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        dialog: MatDialog,
        private cdRef: ChangeDetectorRef,
        private ngbCalendar: NgbCalendar
    ) {
        super(injector);
    }

    ngOnInit() {
        this.createUserDetailsForm();
        this.loadUser();
        this.createRolesForm();

        this.rolesFormGroup.get('addFacilitator').valueChanges.subscribe(
            data => {
                if ((typeof data) == 'undefined') {
                    return;
                }
                this.setSearchFacilitators(data);
            }
        );
        this.addFacility.valueChanges.subscribe(
            data => {
                if ((typeof data) == 'undefined') {
                    return;
                }
                this.setSearchFacilities(data);
            }
        );
    }

    ngAfterViewChecked() {

        if (!this.commonService.App.mobileQuery.matches) {

            this.tabHeight = this.stepperWrapperRef
                .nativeElement
                .querySelector('#userRoleTab' + this.stepper.selectedIndex)
                .clientHeight + 'px';
        }
        else {
            this.tabHeight = '100%';
        }
        this.cdRef.detectChanges();

        this.stepper._stepHeader.forEach((header, i) => {
            header._getHostElement().onclick = () => {
                this.ChangeTab(-1);
            }
        })
    }

    createUserDetailsForm() {
        this.userDetailsFormGroup = this.formBuilder.group({
            UserId: [],
            TitleId: [undefined],
            FirstName: ['', [
                Validators.required,
                Validators.pattern(PatternConstant.VALID_NAME)
            ]],
            MiddleName: [''],
            LastName: ['', [
                Validators.required,
                Validators.pattern(PatternConstant.VALID_NAME)
            ]],
            PreferredName: [''],
            DateOfBirth: [],
            GenderId: [undefined],
            IsActive: [true, Validators.required],
            MobileNumber: [''],
            EmailAddress: ['', [
                Validators.required,
                Validators.pattern(PatternConstant.EMAIL)
            ]],
            MFAPreference: [MFAType.EMAIL, [
                Validators.required
            ]],
        });

        const today = this.ngbCalendar.getToday();
        this.minDate = this.ngbCalendar.getPrev(today, 'y', 100);
        this.presentTime = today;
    }

    createRolesForm() {
        this.rolesFormGroup = this.formBuilder.group({
            Role: [0],
            RolesId: [[], Validators.required, Validators.length > 0],
            FacilitiesId: [[]],
            FacilitatorsId: [[]],

            addFacilitator: []
        });
    }

    public ValidateUserDetailsSection(): boolean {
        var ret: boolean = false;
        if (this.userDetailsFormGroup.get('TitleId').valid &&
            this.userDetailsFormGroup.get('FirstName').valid &&
            this.userDetailsFormGroup.get('LastName').valid &&
            this.userDetailsFormGroup.get('DateOfBirth').valid &&
            this.userDetailsFormGroup.get('GenderId').valid)
            return true;
        else
            return false;
    }

    public ValidateContactSection(): boolean {
        var ret: boolean = false;
        if (this.userDetailsFormGroup.get('MobileNumber').valid &&
            this.userDetailsFormGroup.get('EmailAddress').valid &&
            !this.phoneSpinner && !this.mailSpinner)
            return true;
        else
            return false;
    }

    public ChangeTab(index: number) {
        if (index != -1)
            this.stepper.selectedIndex = index;
        this.btnNextClicked = true;
    }

    setLinear() {
        this.rolesFormGroup.get('RolesId').setValue(this.roleTypesSelected);
        this.rolesFormGroup.get('FacilitiesId').setValue(this.facilitiesSelected);
        this.rolesFormGroup.get('FacilitatorsId').setValue(this.facilitatorsSelected);
        return true;
    }

    public stepClick(event) {
        this.btnNextClicked = false;
        if (event.selectedIndex == 1) {
            if (this.roles.length === 0) {
                this.loadRoles();
                this.roleTypesSelected = this.user.Roles;
                this.roleTypesSelected.forEach(r => {
                    this.setValidateRolesSelected(r.Name);
                });

                if (this.user.AssignFacilityUpdates.length > 0 &&
                    this.user.AssignFacilityUpdates[0] !== null) {
                    this.facilitiesSelected = this.user.AssignFacilityUpdates;
                }

                if (this.user.AssignFacilitatorEmployerUpdates.length > 0 &&
                    this.user.AssignFacilitatorEmployerUpdates[0] != null) {
                    this.facilitatorsSelected = this.user.AssignFacilitatorEmployerUpdates;
                }
            }
        }
    }

    setMaxlength(event) {
        let target = event.target;
        if (target.value.length > target.maxLength)
            event.target.value = target.value.slice(0, target.maxLength);
    }

    requestQueueCount: number = 0;
    invoke(source: Observable<any>, handleResultCallback: Function, mapCallback: Function = () => { }) {
        this.requestQueueCount++;
        this.commonService.StartProgressBar();
        source.pipe(
            catchError((e) => { throw e; }),
            finalize(() => {
                this.requestQueueCount--;
                if (this.requestQueueCount <= 0) {
                    this.commonService.StopProgressBar();
                    this.requestQueueCount = 0;
                }
            }),
            map(data => {
                return mapCallback(data);
            }),
        )
            .subscribe(
                res => {
                    handleResultCallback(res);
                },
                err => {
                    console.log(err);
                }
            );
    }

    loadUser() {
        let id: number = +this.route.snapshot.queryParamMap.get('id');
        this.invoke(
            this.userService.getUser(id),
            (data) => {
                if (data == null)
                    return;
                this.userDetailsFormGroup.patchValue(data);
                this.user = data;
            },
            (data) => {
                if (data.UserId == 0) {
                    this.router.navigate(['/dashboard']);
                    console.error("User not exist.");
                    return null;
                }
                let user = data;
                if (user.TitleId == null)
                    user.TitleId = undefined;
                if (user.GenderId == null)
                    user.GenderId = undefined;
                user.DateOfBirth = this.userService.parseStringTo_dd_mm_yyyy(user.DateOfBirth);
                this.editCurrentMail = user.EmailAddress;
                if (user.MobileNumber?.match(/^\++/)) {
                  user.MobileNumber = user.MobileNumber.replace(/^\++/, '+');
                }
                this.editCurrentPhone = user.MobileNumber;
                user.MobileNumber = user.MobileNumber ? user.MobileNumber : '';

                return user;
            }
        );
    }

    loadRoles() {
        this.invoke(
            this.userService.getRoles(false),
            (data: CommonResponse[]) => {
                if (data == null)
                    return;
                this.roles = data;
                this.rolesFormGroup.patchValue(this.user);
            },
            (data: CommonResponse[]) => {
                return data;
            }
        );
    }

    setValidateRolesSelected(name: string) {
        if (name.toLowerCase().includes('facility')) {
            this.rolesFormGroup.get('FacilitiesId').setValidators([Validators.required]);
            this.rolesFormGroup.get('FacilitiesId').setValidators.length > 0;
        }
        else if (name.toLowerCase().includes('facilitator')) {
            this.rolesFormGroup.get('FacilitatorsId').setValidators([Validators.required]);
            this.rolesFormGroup.get('FacilitatorsId').setValidators.length > 0;
        }
    }

    unsetValidateRolesSelected(role: CommonResponse) {
        if (role.Name.toLowerCase().includes('facility')) {
            this.searchFacilities = [];
            this.facilitiesSelected = [];
            this.rolesFormGroup.get('FacilitiesId').clearValidators();
        }
        if (role.Name.toLowerCase().includes('facilitator')) {
            this.searchFacilitators = [];
            this.facilitatorsSelected = [];
            this.rolesFormGroup.get('FacilitatorsId').clearValidators();
        }
    }

    selectRole(id: number) {
        if (id === 0 || id == null || id == undefined)
            return;
        let currentRole = this.roles.find(rt => rt.Id === id);
        this.roleTypesSelected.push(currentRole);
        this.rolesFormGroup.get('Role').setValue(0);

        if (this.commonService.GetSelectedRole() === 'Admin') {
            this.setValidateRolesSelected(currentRole.Name);
        } else {
            if (currentRole.Name === 'Admin' || currentRole.Name === "Privileged Reports Access") {
                let roleToUnset: CommonResponse;
                roleToUnset = this.roleTypesSelected.find(rt => rt.Name === currentRole.Name);
                this.unsetValidateRolesSelected(roleToUnset);
                this.userService.removeItemFromArray(this.roleTypesSelected, roleToUnset);
                MessageBox.ShowInfo(this.dialog, "Sorry, a non admin role cannot modify an user with admin or privileged reports access roles.");

            } else {
                this.setValidateRolesSelected(currentRole.Name);
            }
        }
    }

    setRoleDisabled(role: CommonResponse) {
        let isBool = this.roleTypesSelected.some(r => r.Id === role.Id);
        return isBool;
    }

    unsetRoleSelected(role: CommonResponse) {
        this.unsetValidateRolesSelected(role);
        this.userService.removeItemFromArray(this.roleTypesSelected, role);
    }

    showRoleDetails(roleName: string) {
        let isBool: boolean = false;
        isBool = this.roleTypesSelected.some(rt => rt.Name.toLowerCase().includes(roleName));
        return isBool;
    }

    displayFn(item?: any): string | undefined {
        return item ? item.Name : undefined;
    }
    //role facility
    setSearchFacilities(text) {
        if (text.length < 3) {
            this.searchFacilities.splice(0, this.searchFacilities.length);
        }
        else if (text.length > 2)
            this.userService.getData(
                APIConstant.API_FIND_FACILITIES_BY_NAME + text,
                (data) => {
                    this.userService.removeArray2InArray1(data, this.facilitiesSelected, 'Id');
                    this.searchFacilities = data;
                },
                () => { },
                this.facilitySpinner
            );
    }

    setFacilitiesSelected(common: CommonResponse) {
        this.facilitiesSelected.push(common);
        this.userService.removeItemFromArray(this.searchFacilities, common);
    }
    unsetFacilitiesSelected(common: CommonResponse) {
        this.searchFacilities.push(common);
        this.userService.removeItemFromArray(this.facilitiesSelected, common);
        this.searchFacilities.sort((a, b) => a.Id > b.Id ? 1 : -1);
    }
    //End. role facility

    //role employer facilitator
    setSearchFacilitators(text: string) {
        if (text.length < 3) {
            this.searchFacilitators.splice(0, this.searchFacilitators.length);
        }
        else if (text.length > 2) {
            this.userService.getData(
                //now data not enough to check, delete when data is ok
                APIConstant.API_FIND_EMPLOYERS_BY_NAME + text + '&includecode=true',
                (data) => {
                    this.userService.removeArray2InArray1(data, this.facilitatorsSelected, 'EmployerId')

                    this.searchFacilitators = [];
                    data.filter(f => {
                        const facilitator: AssignFacilitatorEmployerUpdate = {}
                        facilitator.Name = f.Name;
                        facilitator.Code = f.Code;
                        facilitator.EmployerId = f.EmployerId;
                        if (f.PrimaryFacilitatorId != null) {
                            facilitator.IsPrimaryFacilitator = false;
                            facilitator.CanCheckPrimaryFacilitator = false;
                        }
                        else {
                            facilitator.IsPrimaryFacilitator = false;
                            facilitator.CanCheckPrimaryFacilitator = true;
                        }
                        this.searchFacilitators.push(facilitator);
                    });
                },
                () => { },
                this.facilitatorSpinner
            );
        }
    }

    setFacilitatorsSelected(employer: UserEmployer) {
        this.facilitatorsSelected.push(employer);
        this.userService.removeItemFromArray(this.searchFacilitators, employer);
    }
    unsetFacilitatorsSelected(employer: UserEmployer) {
        this.searchFacilitators.push(employer);
        this.userService.removeItemFromArray(this.facilitatorsSelected, employer);
        this.searchFacilitators.sort((a, b) => a.EmployerId > b.EmployerId ? 1 : -1);
    }

    setPrimaryFacilitator(popover: NgbPopover, f: any, event) {
        let x = f.PrimaryFacilitatorId;
        let y = f.CanCheckPrimaryFacilitator;
        if ((x !== undefined && x != null) || y !== undefined && y === false) {
            event.target.checked = false;
            if (popover.isOpen()) {
                popover.close();
            }
            popover.open();
        }
        else {
            f.IsPrimaryFacilitator = event.target.checked;
        }

    }

    checkExistPhone() {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                let number = '';
                if (this.mobileLength == 10) {
                    number = this.userDetailsFormGroup.get('MobileNumber').value.replace(0, '');
                } else {
                    number = this.userDetailsFormGroup.get('MobileNumber').value;
                }
                if (number.length > 0 && this.userDetailsFormGroup.get("MobileNumber").valid && !this.invalidPhone) {
                    number = '+61' + number.trim();
                    if (number != this.editCurrentPhone) {
                        this.phoneSpinner = true;
                        this.userDetailsFormGroup.setErrors({ waitingcheck: true });
                        return this.userService.checkExistPhonev2(number);
                    } else {
                        this.phoneSpinner = false;
                    }
                } else {
                    this.phoneSpinner = false;
                }
                return empty();
            }),
            catchError((e) => { throw e; }))
            .subscribe(
                (result) => {
                    const encryptedData = result.Data;
                    const decryptedData = this.commonService.D_FP_ResponseData(encryptedData);
                    const ret = JSON.parse(decryptedData);
                    this.phoneSpinner = false;
                    this.userDetailsFormGroup.setErrors({ waitingcheck: false });
                    this.userDetailsFormGroup.updateValueAndValidity();
                    if (result.Success) {
                        if (result.Data !== null && ret.IsExist) {
                            this.userDetailsFormGroup.get("MobileNumber").setErrors({ duplicated: true });
                        } else {
                            this.userDetailsFormGroup.get("MobileNumber").setErrors(null);
                        }
                    } else {
                        MessageBox.ShowError(this.dialog, 'An error has occurred. Please contact the administrator');
                    }
                });
    }

    checkExistEmail() {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                let newName = this.userDetailsFormGroup.get("EmailAddress").value.trim();
                if (newName.length > 0 && this.userDetailsFormGroup.get("EmailAddress").valid) {
                    if (newName != this.editCurrentMail) {
                        this.mailSpinner = true;
                        this.userDetailsFormGroup.get("EmailAddress").setErrors({ waitingcheck: true });
                        return this.userService.checkExistEmailv2(newName);
                    } else {
                        this.mailSpinner = false;
                    }
                } else {
                    this.mailSpinner = false;
                }
                return empty();
            }),
            catchError((e) => { throw e; }))
            .subscribe(
                (result) => {
                    const encryptedData = result.Data;
                    const decryptedData = this.commonService.D_FP_ResponseData(encryptedData);
                    const ret = JSON.parse(decryptedData);
                    this.mailSpinner = false;
                    this.userDetailsFormGroup.get("EmailAddress").setErrors({ waitingcheck: false });
                    this.userDetailsFormGroup.updateValueAndValidity();
                    if (result.Success) {
                        if (result.Data !== null && ret.IsExist) {
                            this.userDetailsFormGroup.get("EmailAddress").setErrors({ duplicated: true });
                        } else {
                            this.userDetailsFormGroup.get("EmailAddress").setErrors(null);
                        }
                    } else {
                        MessageBox.ShowError(this.dialog, 'An error has occurred. Please contact the administrator');
                    }
                });
    }

    private checkValidatePhone() {
        if (this.userDetailsFormGroup.get('MobileNumber').value.indexOf('00') == 0) {
            this.invalidPhone = true;
        } else {
            this.invalidPhone = false;
        }
    }

    public txtNumber(event) {
        const number = this.userDetailsFormGroup.get('MobileNumber').value.indexOf('0');
        this.checkValidatePhone();
        if (number != 0) {
            this.mobileLength = 9;
        } else {
            this.mobileLength = 10;
            this.checkValidatePhone();
        }
        let mobileNumberValue = event.target.value;
        const re = new RegExp(PatternConstant.NOT_NUMBER, 'g');
        mobileNumberValue = mobileNumberValue.replace(re, '');
        this.userDetailsFormGroup.get('MobileNumber').setValue(mobileNumberValue);
    }

    saveUser() {
        let user: UserUpdating = new UserUpdating();
        let roles: Array<number> = [];
        let facilities: Array<number> = [];
        let facilitators: Array<AssignFacilitatorEmployer> = [];
        let number = '';
        this.mobileLength = (<string>this.userDetailsFormGroup.get('MobileNumber').value).length;
        if (this.mobileLength >= 11 && this.mobileLength <= 15) {
            if (this.userDetailsFormGroup.get('MobileNumber').value[3] === '0') {
                number = this.userDetailsFormGroup.get('MobileNumber').value.slice(0, 3) + this.userDetailsFormGroup.get('MobileNumber').value.slice(4);
            }
            else {
                number = this.userDetailsFormGroup.get('MobileNumber').value.slice(0, 3) + this.userDetailsFormGroup.get('MobileNumber').value.slice(3);
            }
        } else {
            number = this.userDetailsFormGroup.get('MobileNumber').value;
        }

        this.roleTypesSelected.filter(r => {
            roles.push(r.Id);
        });
        this.facilitiesSelected.filter(f => {
            facilities.push(f.Id);
        });
        this.facilitatorsSelected.forEach(f => {
            const facilitator: AssignFacilitatorEmployer = {}
            facilitator.EmployerId = f.EmployerId;
            facilitator.Code = f.Code;
            facilitator.IsPrimaryFacilitator = f.IsPrimaryFacilitator;
            facilitators.push(facilitator);
        });

        user.UserId = this.userDetailsFormGroup.get('UserId').value;
        user.UserName = this.user.UserName;
        user.TitleId = this.userDetailsFormGroup.get('TitleId').value;
        user.FirstName = this.userDetailsFormGroup.get('FirstName').value;
        user.MiddleName = this.userDetailsFormGroup.get('MiddleName').value;
        user.LastName = this.userDetailsFormGroup.get('LastName').value;
        user.PreferredName = this.userDetailsFormGroup.get('PreferredName').value;
        user.GenderId = this.userDetailsFormGroup.get('GenderId').value;
        user.DateOfBirth = this.userService.format_dd_mm_yyyy_ToString(this.userDetailsFormGroup.get('DateOfBirth').value);
        user.MobileNumber = number;
        user.EmailAddress = this.userDetailsFormGroup.get('EmailAddress').value.toLowerCase().trim();
        user.IsActive = this.userDetailsFormGroup.get('IsActive').value;
        user.RoleIds = roles;
        user.FacilityIds = facilities;
        user.AssignFacilitatorEmployers = facilitators;

        this.userService.postData(
            APIConstant.API_UPDATE_USER,
            (data) => {
                this.userService.checkDataRespone(data, user.UserName).subscribe(
                    (dialogResult) => {
                        if (data.Success == true) {
                            if (dialogResult.result.toLowerCase() === 'ok') {
                                this.stepper.selectedIndex = 0;
                                this.editCurrentPhone = user.MobileNumber;
                            }
                            // Note: we reload the page to refresh the in memory model of the user to ensure it reflects the server correctly.
                            window.location.reload()
                        }
                    }
                );
            },
            user
        );
    }

    resetPassword() {
        let msgContent = "You are about to reset the user's password. If you click yes, the user will receive an email to reset their password and be unable to login with their existing password. Do you wish to continue?";
        MessageBox.ShowYesNoVerification(this.dialog, msgContent, "Reset Password").subscribe(dialogResult => {
            if (dialogResult.result.toLowerCase() === DialogResult.Yes) {
                this.requestPincodeInput = new RequestPincode();
                this.requestPincodeInput.IsSendByEmail = true;
                this.requestPincodeInput.UserName = this.user.UserName;
                this.requestPincodeInput.AdminReset = true;

                this.Invoke(
                    this.userService.requestPincode(this.requestPincodeInput),
                    {
                        onSuccess: (result: DataResult<any>) => {
                            if (result.Success) {
                                console.log("Success");
                            } else {
                                MessageBox.ShowError(this.dialog, result.Message);
                            }
                        }
                    }
                );
            }
        });
    }

    onFocusParent(blurNumber: number) {
        this.parentFocus = blurNumber;
    }
    onBlurParent(focusNumber: number) {
        this.parentFocus = focusNumber;
    }
    getClassFocused(vlFocused: number) {
        if (this.parentFocus == vlFocused) {
            return "focused";
        } else {
            return "";
        }
    }
}
