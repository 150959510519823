import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConstant } from '@fp/constants';
import { DataResult, PaymentCalculationMethod, SearchPaginationRequest, SearchPaginationResult, ServiceDetails, ServiceStatus, ServiceType, VisitRecordingMethod } from '@fp/models';
import { HttpDAO } from './httpdao.service';
import { Utils } from '@fp/helpers';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class ServiceService {
    private httpDao: HttpDAO | null;

    private processDetailsData(data: ServiceDetails) {
        if (data.Scanners instanceof Array) {
            data.Scanners.forEach(_scanner => {
                if (typeof _scanner.InstallationDate === 'string') {
                    _scanner.InstallationDate = Utils.parseUTCDate(_scanner.InstallationDate);
                }
            });
        }
    }

    constructor(httpClient: HttpClient) {
        this.httpDao = new HttpDAO(httpClient);
    }

    getDetails(id: number): Observable<DataResult<ServiceDetails>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_SERVICE_DETAILS + id).pipe(
            map((res: DataResult<ServiceDetails>) => {
                if (res.Success && res.Data) {
                    this.processDetailsData(res.Data);
                }
                return res;
            }));
    }

    /**
     * Get service types with category being 'Service'.
     * @returns {Observable<DataResult<ServiceType[]>>}
     */
    getTypes(): Observable<DataResult<ServiceType[]>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_SERVICE_TYPES);
    }

    getStatuses(): Observable<DataResult<ServiceStatus[]>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_SERVICE_STATUSES);
    }

    getPaymentCalculationMethods(): Observable<DataResult<PaymentCalculationMethod[]>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_PAYMENT_CALCULATION_METHODS);
    }

    getVisitRecordingMethods(): Observable<DataResult<VisitRecordingMethod[]>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_VISIT_RECORDING_METHODS);
    }

    getServicesByFacility(id: number): Observable<DataResult<ServiceType[]>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_SERVICES_BY_FACILITY + id);
    }

    search(request: SearchPaginationRequest): Observable<DataResult<SearchPaginationResult<{ [key: string]: any }>>> {
        return this.httpDao.postData(APIConstant.API_SEARCH_SERVICES, request);
    }
}
