<div [formGroup]="form">
    <ng-template #requiredFieldMsgTmpl let-ctrl="control" let-ctrlName="fieldName"
        let-validateOnTouch="validateOnTouch || false">
        <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
            <small *ngIf="ctrl.errors['required']" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_REQUIRED, ctrlName)}}</small>
        </div>
    </ng-template>
    <div formGroupName="BankAccount"> 
        
        <div class="form-group row">
            <label for="txtBankName" class="col-md-3 col-lg-2 col-form-label">Bank Name<span
                    class="text-danger">*</span></label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <input type="text" class="form-control"
                        [class.is-invalid]="!isControlValid(form.get('BankAccount.BankName'))" id="txtBankName"
                        formControlName="BankName" [maxlength]="200">
                    <ng-container
                        *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('BankAccount.BankName'), fieldName: 'Bank Name'}">
                    </ng-container>
                </div>
            </div>
        </div>    


        <div class="form-group row">
            <label for="txtAccName" class="col-md-3 col-lg-2 col-form-label">Account
                Name<span class="text-danger">*</span></label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <input type="text" class="form-control" id="txtAccName"
                        [class.is-invalid]="!isControlValid(form.get('BankAccount.BankAccountName'))" maxlength="200"
                        formControlName="BankAccountName" (input) = trimAccName()>
                    <ng-container
                        *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('BankAccount.BankAccountName'), fieldName: 'Account Name'}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="txtBsb" class="col-md-3 col-lg-2 col-form-label">{{isAus ? 'BSB' : 'Bank Code'}}<span class="text-danger">*</span></label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <input type="text" class="form-control"
                        [class.is-invalid]="!isControlValid(form.get('BankAccount.BankAccountBsb'))" id="txtBsb"
                        formControlName="BankAccountBsb" maxlength="6" numberOnly (input)=trimBSB()>
                    <ng-container
                        *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('BankAccount.BankAccountBsb'), fieldName: 'BSB'}">
                    </ng-container>
                    <small *ngIf="form.get('BankAccount.BankAccountBsb').hasError('minlength')"
                        class="invalid-feedback d-block">
                        BSB must be 6 digits long
                    </small>
                </div>
            </div>
        </div>
        
        <div class="form-group row">
            <label for="txtAccNumber" class="col-md-3 col-lg-2 col-form-label">Account
                Number<span class="text-danger">*</span></label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <input type="text" class="form-control"
                        [class.is-invalid]="!isControlValid(form.get('BankAccount.BankAccountNumberMask'))"
                        id="txtAccNumber" formControlName="BankAccountNumberMask" maxlength="9" numberOnly
                        (focus)="txtAccNumber_Focus()" (focusout)="txtAccNumber_Focusout()">
                    <input type="hidden" formControlName="BankAccountNumber">
                    <ng-container
                        *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('BankAccount.BankAccountNumberMask'), fieldName: 'Account Number'}">
                    </ng-container>
                    <small *ngIf="form.get('BankAccount.BankAccountNumberMask').hasError('minlength') ||
                        form.get('BankAccount.BankAccountNumberMask').hasError('maxlength')"
                        class="invalid-feedback d-block">
                        Account number invalid (should be 7 characters)
                    </small>
                </div>
            </div>
        </div>

        <div *ngIf="!isAus" class="form-group row">
            <label for="txtAccType" class="col-md-3 col-lg-2 col-form-label">Account
                Type/Suffix<span class="text-danger">*</span></label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <input type="text" class="form-control"
                        [class.is-invalid]="!isControlValid(form.get('BankAccount.BankAccountType'))"
                        id="txtAccType" [required]="!isAus" formControlName="BankAccountType" (input) = trimAccType() maxlength="3" numberOnly>
                    <ng-container
                        *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('BankAccount.BankAccountType'), fieldName: 'Account Type/Suffix'}">
                    </ng-container>
                </div>
            </div>
        </div>

        <div *ngIf="!isAus && isAdminEdit">
            <div class="form-group row">
                <label for="txtAccType" class="col-md-3 col-lg-2 col-form-label">GoCardless Customer ID</label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtGoCardlessCustomerId"  formControlName="GoCardlessCustomerId" disabled>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="txtAccType" class="col-md-3 col-lg-2 col-form-label">Mandate ID</label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtGoCardlessMandateId"  formControlName="GoCardlessMandateId" disabled>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="txtAccType" class="col-md-3 col-lg-2 col-form-label">Mandate Status</label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtGoCardlessMandateStatus"  formControlName="GoCardlessMandateStatus" disabled>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
