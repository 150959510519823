import { Injectable } from '@angular/core';
import { HttpDAO } from 'src/app/services/httpdao.service';
import { HttpClient } from '@angular/common/http';
import { startWith, switchMap, catchError, map, debounceTime } from 'rxjs/operators';
import { merge, of, Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MessageBox } from 'src/app/services/common-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { APIConstant } from 'src/app/constant';
import { CommonResponse, UserUpdating, User, DataResult } from 'src/app/models';
import * as Models from 'src/app/models';
import { RequestPincode } from 'src/app/models/forget-password.request-pincode.model';
import { ResquestNewPass } from 'src/app/models/forget-password.request-newpass.model';
import {SearchPaginationRequest} from "src/app/models";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  httpDao: HttpDAO | null;

  constructor(
    private httpClient: HttpClient,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {
    this.httpDao = new HttpDAO(this.httpClient);
  }

  postData(apiString: string, subscribeDataCallback: Function, dataInput: any, mapDataCallback: Function = () => { }, params: any = {}) {
    this.httpDao = new HttpDAO(this.httpClient);
    if (Object.keys(params).length == 0)
      this.commonService.StartProgressBar();
    else
      params.isBool = true;
    this.httpDao.postData(apiString, dataInput).pipe(
      map(data => {
        mapDataCallback(data);
        return data
      }),
      catchError(
        err => {
          if ((params.isBool == true))
            params.isBool = false;
          else
            this.commonService.StopProgressBar();
          // console.log("error of api: " + apiString);
          // console.log(err.message);
          throw err;
        }
      )
    ).subscribe(data => {
      subscribeDataCallback(data);
      if ((params.isBool == true))
        params.isBool = false;
      else
        this.commonService.StopProgressBar();
    });
  }


  //not active because sync or async
  getSingleData(apiString: string, subscribeDataCallback: Function, mapDataCallback: Function = () => { }, params: any = {}) {
    this.httpDao = new HttpDAO(this.httpClient);
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          if (Object.keys(params).length == 0)
            this.commonService.StartProgressBar();
          else
            params.isBool = true;
          return this.httpDao.getSingleData(apiString);
        }),
        map(data => {
          mapDataCallback(data);
          return data
        }),
        catchError(error => {
          if (params.isBool == true)
            params = {};
          else
            this.commonService.StopProgressBar();
          // console.log("Error at endpoint: " + apiString);
          // console.log(error.message);
          throw error;
        }))
      .subscribe(data => {
        subscribeDataCallback(data);
        if (params.isBool == true)
          params = {};
        else
          this.commonService.StopProgressBar();
      });
  }

  //not active because sync or async
  getData(apiString: string, subscribeDataCallback: Function, mapDataCallback: Function = () => { }, params: any = {}) {
    this.httpDao = new HttpDAO(this.httpClient);
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          if (Object.keys(params).length == 0)
            this.commonService.StartProgressBar();
          else
            params.isBool = true;
          return this.httpDao.getData(apiString);
        }),
        map(data => {
          mapDataCallback(data);
          return data
        }),
        catchError((error) => {
          if ((params.isBool == true))
            params.isBool = false;
          else
            this.commonService.StopProgressBar();
          // console.log("Error at endpoint: " + apiString);
          // console.log(error.message);
          throw error;
        }))
      .subscribe(data => {
        subscribeDataCallback(data);
        if ((params.isBool == true))
          params.isBool = false;
        else
          this.commonService.StopProgressBar();
      });
  }

  getUser(id: number): Observable<any> {
    return this.httpDao.getSingleData(APIConstant.API_GET_USER_TO_UPDATE + id);
  }
  getFirtLoginUser(username: string): Observable<any> {
    const encrypteddata = this.commonService.E_FP_RequestData(username);
    const encryptedtoken = this.commonService.E_FP_RequestData(this.commonService.GetToken());
    return this.httpDao.postData(APIConstant.API_GET_USER_BY_USERNAME_FIRSTLOGIN_V2, { "Data": encrypteddata, "Header": encryptedtoken });
  }

  updateUser(user: UserUpdating) {
    return this.httpDao.postData(APIConstant.API_UPDATE_USER, user);
  }
  updateFirstLoginUser(user: UserUpdating) {
    const encrypteddata = this.commonService.E_FP_RequestData(JSON.stringify(user));
    const encryptedtoken = this.commonService.E_FP_RequestData(this.commonService.GetToken());
    return this.httpDao.postData(APIConstant.API_UPDATE_FISTLOGIN_USER, { "Data": encrypteddata, "Header": encryptedtoken });
  }

  getTitles(): Observable<CommonResponse[]> {
    return this.httpDao.getData(APIConstant.API_GET_TITLES);
  }

  getGenders(): Observable<CommonResponse[]> {
    return this.httpDao.getData(APIConstant.API_GET_GENDERS);
  }

  getRoles(hasMember: boolean) {
    const _hasMember: string = hasMember.toString();
    return this.httpDao.getData(APIConstant.API_GET_ROLES + _hasMember);
  }

  getByUsernameFirstLoginV2(username: string) {
    const encrypteddata = this.commonService.E_FP_RequestData(username);
    const encryptedtoken = this.commonService.E_FP_RequestData(this.commonService.GetToken());
    return this.httpDao.postData(APIConstant.API_GET_USER_BY_USERNAME_FIRSTLOGIN_V2, { "Data": encrypteddata, "Header": encryptedtoken });
  }

  getUsersByNameOrEmail(nameOrEmail: string, roleId?: number) {
    const request = { "id": nameOrEmail, "roleid": roleId };
    const encrypteddata = this.commonService.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_GET_USERS_BY_NAME_EMAIL_PAGING, {
      "Data": encrypteddata
    });
  }

  getUsersByNameOrEmailPaging(nameOrEmail: string) {
    const requestString = encodeURIComponent(nameOrEmail);
    const request = { "id": requestString };
    const encrypteddata = this.commonService.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_GET_USERS_BY_NAME_EMAIL_PAGING, {
      "Data": encrypteddata
    });
  }


  removeItemFromArray(arr: Array<any>, item: any) {
    var index = arr.indexOf(item);
    if (index > -1) {
      arr.splice(index, 1);
    }
  }
  removeArray2InArray1(arr: Array<any>, removeArr: Array<any>, idName: string) {
    for (let i = arr.length - 1; i >= 0; i--) {
      for (let j = 0; j < removeArr.length; j++) {
        if (arr[i] && (arr[i][idName] === removeArr[j][idName])) {
          arr.splice(i, 1);
        }
      }
    }
  }

  parseStringTo_dd_mm_yyyy(date: string) {
    if (date == null || date == undefined || (typeof date) != 'string')
      return null;
    const dob = new Date(date);
    const dobNgbDate: NgbDateStruct = {
      day: dob.getDate(),
      month: dob.getMonth() + 1,
      year: dob.getFullYear()
    }
    return dobNgbDate;
  }
  format_dd_mm_yyyy_ToString(date: NgbDateStruct) {
    if (date == null)
      return '';
    let stringDate = date['year'] + '-' + date['month'] + '-' + date['day'];
    return stringDate;
  }

  checkDataRespone(data, userName: string) {
    let messageReponse: any;
    if (data.ErrorNumber == -1)
      messageReponse = MessageBox.ShowError(this.dialog, 'Something was wrong');
    else if (data.ErrorNumber == 1) {
      messageReponse = MessageBox.ShowError(this.dialog, 'Request body error');
    }
    else if (data.ErrorNumber == 2) {
      messageReponse = MessageBox.ShowError(this.dialog, "UserName '" + userName + "' exists");
    }
    else if (data.ErrorNumber == 3) {
      messageReponse = MessageBox.ShowError(this.dialog, "UserName '" + userName + "' invalid");
    }
    else if (data.ErrorNumber == 4) {
      messageReponse = MessageBox.ShowError(this.dialog, 'Role does not exist');
    }
    else if (data.ErrorNumber == 5) {
      messageReponse = MessageBox.ShowError(this.dialog, 'Facility does not exist');
    }
    else if (data.ErrorNumber == 6) {
      messageReponse = MessageBox.ShowError(this.dialog, 'Employer does not exist');
    }
    else if (data.ErrorNumber == 7) {
      messageReponse = MessageBox.ShowError(this.dialog, 'User does not exist');
    }
    else if (data.ErrorNumber == 8) {
      messageReponse = MessageBox.ShowError(this.dialog, 'Primary facilitator has already been selected for this employer');
    }
    else if (data.ErrorNumber == 9) {
      messageReponse = MessageBox.ShowError(this.dialog, 'Cognito error');
    }
    else if (data.Success == true) {
      messageReponse = MessageBox.ShowCustom(this.dialog, 'Information', 'Information', 'Data has been saved successfully');
    }
    return messageReponse;
  }

  checkExistPhonev2(name: string): Observable<Models.DataResult<any>> {
    const param = { MobileNumber: name };
    const encrypteddata = this.commonService.E_FP_RequestData(JSON.stringify(param));
    const encryptedtoken = this.commonService.E_FP_RequestData(this.commonService.GetToken());
    return this.httpDao.postData(APIConstant.API_CHECK_EXIST_PHONE_V2, { "Data": encrypteddata, "Header": encryptedtoken });
  }

  checkExistEmailv2(name: string): Observable<Models.DataResult<any>> {
    const param = { EmailAddress: name };
    const encrypteddata = this.commonService.E_FP_RequestData(JSON.stringify(param));
    const encryptedtoken = this.commonService.E_FP_RequestData(this.commonService.GetToken());
    return this.httpDao.postData(APIConstant.API_CHECK_EXIST_EMAIL_V2, { "Data": encrypteddata, "Header": encryptedtoken });
  }

  checkExistUserByEmailForFederation(name: string): Observable<Models.DataResult<any>> {
    const param = { EmailAddress: name };
    const encrypteddata = this.commonService.E_FP_RequestData(JSON.stringify(param));
    const encryptedtoken = this.commonService.E_FP_RequestData(this.commonService.GetToken());
    return this.httpDao.postData(APIConstant.API_CHECK_EXIST_USER_BY_EMAIL_FEDERATION, { "Data": encrypteddata, "Header": encryptedtoken });
  }

  checkExistingUser(username: string, email: string): Observable<string> {
    const param = { UserName: username, Email: email };
    const encrypteddata = this.commonService.E_FP_RequestData(JSON.stringify(param));
    if (username) {
      return this.httpDao.postDataWithoutToken(APIConstant.API_CHECK_EXISTING_USER_BY_USERNAME, { Data: encrypteddata });
    } else if (email) {
      return this.httpDao.postDataWithoutToken(APIConstant.API_CHECK_EXISTING_USER_BY_USERNAME, { Data: encrypteddata });
    }
  }

  requestPincode(requestPincode: RequestPincode): Observable<DataResult<any>> {
    return this.httpDao.postDataWithoutToken(APIConstant.API_REQUEST_PINCODE_FROM_USERNAME, requestPincode);
  }

  requestNewPassword(requestNewPass: ResquestNewPass): Observable<DataResult<any>> {
    return this.httpDao.postDataWithoutToken(APIConstant.API_REQUEST_NEW_PASSWORD, requestNewPass);
  }

  // searches the database to find all user role - Role table (active or non-active)
  search(searchRequest: SearchPaginationRequest): Observable<string> {
    const encrypteddata = this.commonService.E_FP_RequestData(
        JSON.stringify(searchRequest)
      );
       return this.httpDao.postData(APIConstant.API_SEARCH_USER_ROLES, {
          Data: encrypteddata,
        });
      }
}
