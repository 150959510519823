<div [formGroup]="form" #identificationTemplate>
    <div class="form-group row">
        <label for="txtUFCode" class="col-md-3 col-lg-2 col-form-label">Unique Facility Code<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="txtUFCode" formControlName="Code" readonly
                    style="text-transform: uppercase !important;">
            </div>
        </div>
    </div>
    <div class="form-group row" id="facility-name">
        <label for="txtFName" class="col-md-3 col-lg-2 col-form-label">Facility Name<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" [class.is-invalid]="!isControlValid('Name')" id="txtFName"
                    name="FacilityName" formControlName="Name" maxlength="200">
                <mat-spinner [diameter]="14" color="primary" *ngIf="isCheckingName"></mat-spinner>
                <div *ngIf="!isControlValid('Name')">
                    <small *ngIf="FacilityNameCtrl.errors['required']" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Facility Name')}}</small>
                    <small *ngIf="FacilityNameCtrl.errors['duplicated']" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_VALUE_ALREADY_EXISTED, 'Facility Name')}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row" id="company-name">
        <label for="txtComName" class="col-md-3 col-lg-2 col-form-label">Company Name<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" [class.is-invalid]="!isControlValid('CompanyName')"
                    id="txtComName" formControlName="CompanyName" maxlength="200">
                <div *ngIf="!isControlValid('CompanyName')">
                    <small *ngIf="CompanyNameCtrl.errors['required']" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Company Name')}}</small>
                </div>
            </div>
        </div>
    </div><div class="form-group row" id="facility-owner-group">
        <label for="txtFacOwnGroup" class="col-md-3 col-lg-2 col-form-label">Facility Owner Group<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" [class.is-invalid]="!isControlValid('FacilityOwnerGroup')"
                    id="txtFacOwnGroup" formControlName="FacilityOwnerGroup" maxlength="200">
                <div *ngIf="!isControlValid('FacilityOwnerGroup')">
                    <small *ngIf="FacilityOwnerGroupCtrl.errors['required']" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Facility Owner Group')}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="chkHeadOffice" class="col-md-3 col-lg-2 col-form-label">Is This Head Office?</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="chkHeadOffice"
                        formControlName="IsHeadOffice">
                    <label class="custom-control-label" for="chkHeadOffice">&nbsp;</label>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtParentGroup" class="col-md-3 col-lg-2 col-form-label">Parent Group</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input class="form-control" [class.is-invalid]="!isControlValid(ParentGroupCtrl)" type="text"
                    placeholder="Search Parent Group by Facility Name" formControlName="ParentGroup" matInput
                    [matAutocomplete]="parentFacilityAutocomplete" maxlength="200" (blur)="txtParentGroup_Blur($event)"
                    (input)="txtParentGroup_Input($event)">
                <mat-spinner [diameter]="14" color="primary" *ngIf="isSearchingParent"></mat-spinner>
                <div *ngIf="!isControlValid(ParentGroupCtrl)">
                    <small *ngIf="ParentGroupCtrl.getError('unresolved')" class="invalid-feedback d-block">The Parent
                        Group does not exist</small>
                </div>
                <mat-autocomplete id="autoParentFacility" name="autoParentFacility"
                    class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                    #parentFacilityAutocomplete="matAutocomplete" [displayWith]="AutoComplete_DisplayWithFn"
                    (optionSelected)="SelectParentFacility($event.option.value)">
                    <mat-option *ngFor="let parentGroup of availableParentGroupResults" [value]="parentGroup"
                        class="border-default border-bottom py-1">
                        <p class="m-0 d-flex align-items-center">{{parentGroup.ParentGroupName}}</p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="dllType" class="col-md-3 col-lg-2 col-form-label">Facility Type<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" id="dllType" formControlName="FacilityType"
                    [class.is-invalid]="!isControlValid('FacilityType', true)" [compareWith]="ddlTypeOption_CompareFn">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let type of types" [ngValue]="type">{{type.Name}}
                    </option>
                </select>
                <div *ngIf="!isControlValid('FacilityType', true)">
                    <small *ngIf="FacilityTypeCtrl.errors['required']" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Facility Type')}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="dllCategory" class="col-md-3 col-lg-2 col-form-label">Facility Category<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" id="dllCategory" formControlName="FacilityCategory"
                    [class.is-invalid]="!isControlValid('FacilityCategory', true)" [compareWith]="ddlCategoryOption_CompareFn">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let category of categories" [ngValue]="category">{{category.Name}}
                    </option>
                </select>
                <div *ngIf="!isControlValid('FacilityCategory', true)">
                    <small *ngIf="FacilityCategoryCtrl.errors['required']" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Facility Category')}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row" id="facility-website-url">
        <label for="txtFWebsite" class="col-md-3 col-lg-2 col-form-label">Facility Website URL</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="txtFWebsite" formControlName="WebsiteUrl" maxlength="500">
            </div>
        </div>
    </div>
    <div class="form-group row" id="facebook-url">
        <label for="txtFaceUrl" class="col-md-3 col-lg-2 col-form-label">Facebook URL</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="txtFaceUrl" formControlName="FacebookUrl" maxlength="500">
            </div>
        </div>
    </div>
    <div class="form-group row" id="facility-phone-number">
        <label for="txtFacePNum" class="col-md-3 col-lg-2 col-form-label">Facility Phone Number<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" [class.is-invalid]="!isControlValid('FacilityPhoneNumber')"
                    id="txtFacePNum" formControlName="FacilityPhoneNumber" [maxlength]="facilitPhoneMaxLenght" [minlength]="facilitPhoneMinLenght" numberOnly>
                <div *ngIf="!isControlValid('FacilityPhoneNumber')">
                    <small *ngIf="FacilityPhoneNumberCtrl.errors['required']" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Facility Phone Number')}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtStartDate" class="col-md-3 col-lg-2 col-form-label">Facility Start Date<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="txtStartDate" formControlName="FacilityStartDate" readonly
                    style="text-transform: uppercase !important;">
            </div>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-3 col-lg-2">
            <label for="chkCritF" class=" col-form-label">Is this a Critical Facility?</label>
            <button type="button" class="btn btn-light" data-toggle="tooltip" data-placement="top"
                title="This is a facility which is critical to the success to the FP Program in that region and should be dealt with by senior FP Staff">
                <i class="fas fa-info-circle"></i>
            </button></div>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="chkCritF"
                        formControlName="IsCriticalFacility">
                    <label class="custom-control-label" for="chkCritF">&nbsp;</label>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-3 col-lg-2">
            <label for="chkFraudRsk" class=" col-form-label">Fraud Risk?</label>
            <button type="button" class="btn btn-light" data-toggle="tooltip" data-placement="top"
                title="This is a facility that could have potential fraud risks">
                <i class="fas fa-info-circle"></i>
            </button></div>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="chkFraudRsk"
                        formControlName="IsFraudRisk">
                    <label class="custom-control-label" for="chkFraudRsk">&nbsp;</label>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row" *ngIf="this.data.model.FacilityId">
        <label for="restrictedFacility" class="col-md-3 col-lg-2 col-form-label">Restricted Facility?</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" read-only class="custom-control-input" id="restrictedFacility"
                        onclick="return false;" formControlName="IsRestrictedFacility">
                    <label class="custom-control-label" for="restrictedFacility">&nbsp;</label>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtAbn" class="col-md-3 col-lg-2 col-form-label">ABN/NZBN<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" [class.is-invalid]="!isControlValid('Abn')" id="txtAbn"
                    formControlName="Abn" maxlength="20">
                <div *ngIf="!isControlValid('Abn')">
                    <small *ngIf="AbnCtrl.errors['required']" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'ABN/NZBN')}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlStatus" class="col-md-3 col-lg-2 col-form-label">Status<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" id="ddlStatus" formControlName="FacilityStatus"
                    [class.is-invalid]="!isControlValid(FacilityStatusCtrl, true)" (focus)="ddlStatus_Focus($event)"
                    [compareWith]="ddlStatusOption_CompareFn">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let status of statuses" [ngValue]="status"
                        [disabled]="status.FacilityStatusId === EFacilityStatus.NoNewMembers">
                        {{status.Name}}</option>
                </select>
                <div *ngIf="!isControlValid(FacilityStatusCtrl, true)">
                    <small *ngIf="FacilityStatusCtrl.errors['required']" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Status')}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtNNMbrDate" class="col-md-3 col-lg-2 col-form-label">'No new members' Date</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="input-group" [ngClass]="{
                        'focused': getClassFocused(1) === 'focused',
                        'disabled': form.get('NoNewMembersDate').disabled
                    }">
                    <input class="form-control" [class.is-invalid]="!isControlValid('NoNewMembersDate')"
                        formControlName="NoNewMembersDate" id="txtNNMbrDate" placeholder="DD-MM-YYYY"
                        [minDate]="minDate" name="dpNNM" ngbDatepicker #dpNNM="ngbDatepicker" (blur)="onBlurParent(0)"
                        (focus)="onFocusParent(1)" [readonly]="true">
                    <div class="input-group-append">
                        <button class="btn bg-white" (click)="dpNNM.toggle()" type="button"><i
                                class="far fa-calendar-alt"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 pl-0">
            <p class="d-flex align-items-center justify-content-center m-0 rounded-circle bg-secondary fp-warning"
                placement="right"
                ngbPopover="Facility becomes hidden and unavailable for use for new members/packages after the selected date.">
                <i class="fas fa-question text-primary"></i></p>
        </div>
    </div>
    <div class="form-group row" id="facility-description">
        <label for="txtFDes" class="col-md-3 col-lg-2 col-form-label">Facility Description</label>
        <div class="col-sm-7">
            <div class="d-flex flex-column w-100">
                <textarea class="form-control" id="txtFDes" rows="3" formControlName="FacilityDesc"
                    maxlength="2000"></textarea>
            </div>
        </div>
    </div>
</div>