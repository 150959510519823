import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  DataResult,
  FileAttachmentModel,
  BulkUploadBankResult,
  AWSStartExecutionResponse
} from "@fp/models";
import { Observable } from "rxjs";
import { PaymentService, MessageBox, CommonService } from "@fp/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { catchError } from "rxjs/operators";
import { FpFileDataR2 } from "@fp/components/control";
import { FileNameService } from "@fp/services/file-name.service";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { FpProgressBarComponent } from "@fp/components/control/fp-progress-bar/fp-progress-bar.component";
import { StepFunctions } from "@fp/enums/step-functions.enum";
import { AwsServiceService } from "@fp/services/aws-service.service";
import { DescribeStateMachineComponent } from "@fp/components/describe-state-machine/describe-state-machine.component";
import { BankCodeHistoryComponentComponent } from "./bank-code-history-component/bank-code-history-component.component";

@Component({
  selector: 'app-add-bank-code-component',
  templateUrl: './add-bank-code-component.component.html',
  styleUrls: ['./add-bank-code-component.component.css']
})
export class AddBankCodeComponentComponent implements OnInit {

  dataDocumentAttachments: FileAttachmentModel[] = [];
  buttonStyle = { width: "100%" };
  documentName = "No File Selected";
  colParameter = [
    "bankcode",
    "bank name",
    "bank address line 1",
    "post code"
  ];

  StepFunctions = StepFunctions;
  uploadedFileName: string[];
  errorHandler: any;
  bulkUploadConfirmationMsg: string;
  errorMsg: string;
  checkNecessary = true;
  isLoading = false;

  /** The Progress Bar Modal in this Page */
  @ViewChild('progressBar') progressBar: FpProgressBarComponent;
  @ViewChild(DescribeStateMachineComponent) describeSpinner: DescribeStateMachineComponent;

  constructor(
    protected fileSvc: FileNameService,
    private paymentSvc: PaymentService,
    private awsService: AwsServiceService,
    protected dialog: MatDialog,
    private cmsrv: CommonService,
    private modal: NgbModal,
  ) { }

  ngOnInit() { 
    this.cmsrv.Header.title="Add Bankcodes (NZ)";
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError(e => {
          throw e;
        })
      )
      .subscribe(
        res => {
          handleResultCallback(res);
        },
        err => {
          console.log(err, 'error')
          this.errorHandler(err);
        }
      );
  }

  public AddFile_UploadCompleted(event) {
    const ret: FpFileDataR2 = event;
    this.dataDocumentAttachments.push({
      Location: ret.filedata.Location,
      Base64: "",
      FileName: ret.originfilename,
      Status: "Addnew",
      Id: 0
    });
    this.documentName = ret.originfilename;
    console.log(this.dataDocumentAttachments);

    this.CheckBulkUpload();
  }

  // this function checks the Raw Visits from CSV and populates the number of Errors on the page
  public CheckBulkUpload() {
    this.bulkUploadConfirmationMsg =
      "You are about to upload bankcode data, do you wish to continue?";
    MessageBox.ShowCancelContinue(
      this.dialog,
      "Bulk Bankcode Upload Confirmation",
      this.bulkUploadConfirmationMsg
    ).subscribe(dialogResult => {
      if (dialogResult.result.toLowerCase() === DialogResult.Continue) {
        // Open the Progress Bar
        this.progressBar.Open("Grabbing File");

        this.fileSvc.$currentUploadedFileUuid.subscribe(
          uploadedFileName => (this.uploadedFileName = uploadedFileName)
        );
        const fileNameString = this.uploadedFileName[0];
        const modifiedBy = this.cmsrv.GetUser();

        if (fileNameString !== null) {
          // Update Progress Bar Status and increment the Percentage every 1/5 of a second
          this.progressBar.upload_status_subject.next("Uploading File: " + this.dataDocumentAttachments[0].FileName);
          this.progressBar.StartPercentTimer();

          this.Invoke(
            this.awsService.StartStepExecution(StepFunctions.BulkUploadBankDetail,
              'NZ', modifiedBy, fileNameString, null),
            (result: AWSStartExecutionResponse) => {
              // Kill the Progress Bar
              this.progressBar.upload_percent_subject.next(100);
              this.progressBar.upload_status_subject.next("Upload Complete!");
              this.progressBar.close_subject.next(true);

              this.describeSpinner.stepMachineIsRunning = true;
              this.describeSpinner.executionArn = result.executionArn;
              this.describeSpinner.StartDescriptionTimer(5);
            }
          );
        }
      }
    });
  }

  public btnHistory_Click() {
    const modalRef = this.modal.open(
      BankCodeHistoryComponentComponent,
      {
        windowClass: "fp-modal-window",
        backdrop: "static",
      }
    );
  }

  handleBulkUploadResponse(result: DataResult<BulkUploadBankResult>) {
    if (!result.Data.ErrorMessage ) {
      MessageBox.ShowInfo(
        this.dialog,
        "Upload is successful. All rows posted to Bank Code."
      ).subscribe(dialogResult => {
        if (dialogResult.result.toLowerCase() === DialogResult.Ok) {
          this.fileSvc.resetFileData();
          window.location.reload();
        }
      });
    } else {
      var errorMsg = result.Data.ErrorMessage;
      this.fileSvc.resetFileData();
      this.errorMsg =
        `<strong>${errorMsg}</strong> Other records saved`;
      MessageBox.ShowError(this.dialog, this.errorMsg);
    }
  }

}
