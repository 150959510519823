import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '.input-group'
})
export class HighlightedInputGroupDirective implements AfterViewInit {

    constructor(private _el: ElementRef<HTMLDivElement>) {
    }

    ngAfterViewInit() {
        const input = <HTMLInputElement>this._el.nativeElement.getElementsByClassName('form-control').item(0);
        if (input) {
            input.addEventListener('focus', this.onFocus);
            input.addEventListener('blur', this.onBlur);
        }
    }

    private onFocus(event: Event) {
        const elem = (<HTMLElement>event.srcElement).parentElement;
        if (elem && elem.classList.contains('input-group') && !elem.classList.contains('focused')) {
            elem.classList.add('focused');
        }
    }

    private onBlur(event: Event) {
        const elem = (<HTMLElement>event.srcElement).parentElement;
        if (elem && elem.classList.contains('input-group') && elem.classList.contains('focused')) {
            elem.classList.remove('focused');
        }
    }
}
