<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Membership Card Details</h4>
<div class="mt-lg-4">
    <div class="my-4">
        <div class="border-default rounded-10 border-2">
            <div class="tablecontainer">
                <table mat-table matSort [dataSource]="dataSourceMembershipCards" class="rounded-10">
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="LastName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
                        <td mat-cell *matCellDef="let element"> {{element.LastName}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="FirstName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
                        <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="MemberType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Member Type</th>
                        <td mat-cell *matCellDef="let element"> {{element.MemberType.Name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="MemberNumber">
                        <th mat-header-cell *matHeaderCellDef>Member Number</th>
                        <td mat-cell *matCellDef="let element"> {{element.MemberNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="MemberCardStatus">
                        <th mat-header-cell *matHeaderCellDef>Card Status</th>
                        <td mat-cell *matCellDef="let element"> {{element.MemberCardStatus.Name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="MemberCardNumber">
                        <th mat-header-cell *matHeaderCellDef>Card Number</th>
                        <td mat-cell *matCellDef="let element"> {{element.MemberCardNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="PhotoLocation">
                        <th mat-header-cell *matHeaderCellDef class="p-0"></th>
                        <td mat-cell *matCellDef="let element" class="p-0" style="width: 183px;">
                            <div
                                [ngClass]="element.PhotoLocation!='' && element.PhotoLocation !=null? 'my-2 border border-primary' : 'my-2'">
                                <div *ngIf="element.PhotoLocation=='' || element.PhotoLocation==null"
                                    class="bg-secondary fp-profilephoto d-flex justify-content-center align-items-center mx-auto">
                                    <i class="fa fa-image fa-2x text-secondary"></i>
                                </div>
                                <div *ngIf="element.PhotoLocation!='' && element.PhotoLocation !=null"
                                    class="fpPictureCover d-flex align-items-center justify-content-center">
                                    <app-member-image [imgSrc]="element.PhotoLocation" [htmlClass]="'maxWH1'">
                                    </app-member-image>
                                </div>
                                <button (click)="element.IsMobileAppEnabled ? OnSelectedUploadPhotoForMobile(element) : OnSelectedUploadPhoto(element)" for="txtUploadPhoto"
                                    class="btn bg-primary text-light py-1 d-block text-center w-100 photo rounded-0" *ngIf="newCardActions">
                                    Upload Photo
                                </button>

                            </div>
                            <!-- <ng-template>
                                <div class="my-2 border border-primary">
                                    <div class="fpPictureCover d-flex align-items-center justify-content-center">
                                        <img src="../../../../assets/images/avatar.png" class="maxWH1" alt="Membership Photo">
                                    </div>
                                    <button for="txtUploadPhoto" class="btn bg-primary text-light py-1 d-block text-center w-100 photo rounded-0">Upload
                                        Photo</button>
                                </div>
                            </ng-template> -->
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="p-0" style="width:120px;"></th>
                        <td mat-cell *matCellDef="let element" class="p-0 text-center">
                            <div *ngIf="element.MemberCardStatus.Id == EMemberCardStatus.Active && element.IsMobileAppEnabled">
                                <a (click)="ClickOrderNewCardForMobileButton(element)" id="btnOrderNewCard" class="text-center d-inline-block">
                                    <i class="fas fa-check-circle text-primary"></i><br>Order New Card</a>
                            </div>
                            <div *ngIf="element.MemberCardStatus.Id == EMemberCardStatus.Active && !element.IsMobileAppEnabled">
                                <a (click)="ClickOrderNewCardButton(element)" id="btnOrderNewCard" class="text-center d-inline-block">
                                    <i class="fas fa-check-circle text-primary"></i><br>Order New Card</a>
                            </div>
                            <div
                                *ngIf="element.MemberCardStatus.Id == EMemberCardStatus.Active && newCardActions && element.IsMobileAppEnabled">
                                <a (click)="ClickLostStolenCardForMobileButton(element)" id="btnOrderNewCard" class="text-center d-inline-block"><i
                                        class="fas fa-low-vision text-danger"></i><br>Lost/Stolen Card</a>
                            </div>
                            <div *ngIf="element.MemberCardStatus.Id == EMemberCardStatus.Active && newCardActions && !element.IsMobileAppEnabled">
                                                        <a 
                                                            (click)=" ClickLostStolenCardButton(element)"
                                class="text-center d-inline-block mt-1"><i class="fas fa-low-vision text-danger"></i><br>Lost/Stolen Card</a>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <mat-toolbar [hidden]="dataSourceMembershipCards == null" class="bg-white">
                <mat-toolbar-row *ngIf="dataSourceMembershipCards != null && dataSourceMembershipCards.data.length == 0"
                    class="text-muted d-block p-2 bg-light text-center">
                    {{commonString.TABLE_NO_RECORD_FOUND}}
                </mat-toolbar-row>
            </mat-toolbar>
        </div>
    </div>
    <button (click)="OnBack()" class="px-5 py-2 btn bg-white border-primary text-primary" type="button"><i
            class="fas fa-arrow-alt-circle-left mr-2"></i>BACK</button>
</div>