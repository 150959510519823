<div class="container">
    <div class="row">
        <div class="col-12" *ngIf="!isUserLogged; else loggedInMessage">
            <div class="register-wrapper mx-auto">
                <h1 class="text-center title-text d-md-block">
                    Survey Registration
                </h1>
                <div class=" row">
                    <div class="col-md-12">
                        <div class="d-block mb-4 text-center">Thank you for taking the Fitness Passport survey.
                            <br><br>The information you provide will be used for the development of this program and is
                            kept strictly
                            confidential. Once you have registered, there are three short sections to complete
                          </div>
                    </div>
                </div>
                <div class="reg_wrapper" class='d-block'>
                    <form [formGroup]="registerFormGroup" class="register-wrapper--from">
                        <div class="form-group row">
                            <label for="inputEmployerName" class="col-sm-3 col-form-label">Employer*</label>
                            <div class="col-sm-9">
                                <div class="d-flex flex-column w-100">
                                    <input type="text" id="txtEmployerName" class='form-control'
                                        formControlName="txtEmployerName" disabled />
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputFirstName" class="col-sm-3 col-form-label">First Name*</label>
                            <div class="col-sm-9">
                                <div class="d-flex flex-column w-100">
                                    <input type="text" maxlength="50" id="txtFirstName" class="form-control"
                                        formControlName="txtFirstName" [ngClass]="
                        registerFormGroup.get('txtFirstName').invalid &&
                        (registerFormGroup.get('txtFirstName').dirty ||
                          registerFormGroup.get('txtFirstName').touched ||
                          btnSubmit)
                          ? 'form-control is-invalid'
                          : 'form-control'
                      " />
                                    <div *ngIf="
                        registerFormGroup.get('txtFirstName').invalid &&
                        (registerFormGroup.get('txtFirstName').dirty ||
                          registerFormGroup.get('txtFirstName').touched ||
                          btnSubmit)
                      ">
                                        <small *ngIf="
                          registerFormGroup.get('txtFirstName').errors.required
                        " class="invalid-feedback d-block">First Name is required</small>
                                        <small *ngIf="
                          registerFormGroup.get('txtFirstName').errors.pattern
                        " class="invalid-feedback d-block">This field does not accept special characters. Please try
                                            again</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputLastName" class="col-sm-3 col-form-label">Last Name*</label>
                            <div class="col-sm-9">
                                <div class="d-flex flex-column w-100">
                                    <input type="text" maxlength="50" [ngClass]="
                        registerFormGroup.get('txtLastName').invalid &&
                        (registerFormGroup.get('txtLastName').dirty ||
                          registerFormGroup.get('txtLastName').touched ||
                          btnSubmit)
                          ? 'form-control is-invalid'
                          : 'form-control'
                      " id="txtLastName" formControlName="txtLastName" />
                                    <div *ngIf="
                        registerFormGroup.get('txtLastName').invalid &&
                        (registerFormGroup.get('txtLastName').dirty ||
                          registerFormGroup.get('txtLastName').touched ||
                          btnSubmit)
                      ">
                                        <small *ngIf="
                          registerFormGroup.get('txtLastName').errors.required
                        " class="invalid-feedback d-block">Last Name is required</small>
                                        <small *ngIf="
                          registerFormGroup.get('txtLastName').errors.pattern
                        " class="invalid-feedback d-block">This field does not accept special characters. Please try
                                            again</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputEmail" class="col-sm-3 col-form-label">Work Email*</label>
                            <div class="col-sm-9">
                                <div class="d-flex flex-column w-100">
                                    <input (input)="checkPhoneEmailResponsive()" [ngClass]="
                        registerFormGroup.get('txtEmail').invalid &&
                        (registerFormGroup.get('txtEmail').dirty ||
                          registerFormGroup.get('txtEmail').touched ||
                          btnSubmit)
                          ? 'form-control is-invalid'
                          : 'form-control'
                      " type="email" id="txtEmail" formControlName="txtEmail" maxlength="100" />
                                    <div *ngIf="
                        registerFormGroup.get('txtEmail').invalid &&
                        (registerFormGroup.get('txtEmail').dirty ||
                          registerFormGroup.get('txtEmail').touched ||
                          btnSubmit)
                      ">
                                        <small *ngIf="registerFormGroup.get('txtEmail').errors.required"
                                            class="invalid-feedback d-block">
                                            Primary Email is required</small>
                                        <small *ngIf="registerFormGroup.get('txtEmail').errors.pattern"
                                            class="invalid-feedback d-block">Primary Email is invalid</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <app-mobile-number-registor [mandatory]="true" [control]="registerFormGroup.get('txtMobileNum')"
                            [submitted]="btnSubmit" formControlName="txtMobileNum" (runSingupCheck)="runCheck($event)"
                            (change)="runCheck($event)" id="mobile-no-wrapper"></app-mobile-number-registor>

                        <div class="form-group row">
                            <div class="d-flex justify-content-between offset-md-3 col-md-9">
                                <form class="w-100" [formGroup]="ReCaptchaFormGroup">
                                    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" [size]="'normal'" [hl]="'en'"
                                        [theme]="'light'" [type]="'image'" formControlName="rcpSignUpCaptcha"
                                        (success)="(true)">
                                    </ngx-recaptcha2>
                                </form>
                            </div>
                            <div class="d-flex justify-content-between offset-md-3 col-md-9">
                                <small *ngIf="isCaptchaResponse == false && btnSubmit == true"
                                    class="invalid-feedback d-block">Please
                                    verify that you are not a robot</small>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="offset-md-3 col-md-9">
                                <button class="btn btn-primary btn-new btn-mobile-100" (click)="btnSubmit_Click()"
                                    [disabled]="this.registerFormGroup.invalid">
                                    Submit
                                </button>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="d-flex justify-content-between offset-md-3 col-md-9">
                                <p>
                                    Already a member?<a class="text-primary" routerLink="/login">
                                        Sign in</a>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div> 
    </div>
    <div>
        <ng-template #loggedInMessage>
            <!-- Display message if the user is logged in -->
            <div class="col-12">
                <h2 class="text-center">Sorry, This link is not accessible to you</h2>
            </div>
        </ng-template>
    </div>
</div>