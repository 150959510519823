<div mat-dialog-container>
    <div class="message text-center">
        <p *ngIf="button!=4 && button!=5">
            {{message}}
        </p>
        <p *ngIf="button==4">
            Member Confirmation
        </p>
        <p *ngIf="button==5">
            Create Password
        </p>
    </div>
    <hr />
    <div class="info" *ngIf="information!=='' && button!=4">
        <p [innerHTML]="information"></p>
        <p style="text-align: center" *ngIf="countdown!==''"><strong>{{countdown}}</strong></p>
    </div>
    <div class="info" *ngIf="button==4">
        <p style="text-align: center">
            An Authorisation Code has been sent to your email and mobile number. <br />
            Please enter to confirm registration.
        </p>
        <div class="form-group row">
            <label for="txtPin" class="col-md-3 col-form-label">Code<span class="text-danger">*</span>
            </label>
            <div class="col-md-9">
                <div class="d-flex flex-column w-100">
                    <input type="text" [ngClass]="PinRequired || PinInvalid?'form-control is-invalid':'form-control'"
                        id="txtPin" [(ngModel)]="Pincode">
                    <div *ngIf="PinRequired">
                        <small class="invalid-feedback d-block">Authorisation Code is required</small>
                    </div>
                    <div *ngIf="PinInvalid">
                        <small class="invalid-feedback d-block">The security code you entered is incorrect. Please try again </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row" style="margin-left: 0px;">
            <div class="d-flex justify-content-between">
                <form [formGroup]="ReCaptchaFormGroup" class="w-100">
                    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" [size]="'normal'" [hl]="'en'" [theme]="'light'"
                        [type]="'image'" formControlName="rcpSignUpCaptcha" (success)="HandleCaptchaSuccess($event)">
                    </ngx-recaptcha2>
                </form>
            </div>
            <div class="d-flex justify-content-between">
                <small *ngIf="isCaptchaResponse==false && (btnSubmit==true || btnResent==true)"
                    class="invalid-feedback d-block">Please
                    verify that you are
                    not a robot</small>
            </div>
        </div>
        <div class="form-group row">
            <div class="d-flex flex-column w-100">
                <button class="btn btn-link" role="link" (click)="ResendPinClick()" value="">Resend Code</button>
            </div>
        </div>
    </div>
    <div class="info" *ngIf="button==5">
        <form [formGroup]="passwordFormGroup" (keydown.enter)="btnOK_Click()">
            <div class="form-group row">
                <label for="txtPassword" class="col-md-3 col-form-label">Password<span class="text-danger">*</span>
                </label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <input type="Password" maxlength="20"
                            [ngClass]="(passwordFormGroup.get('txtPassword').invalid && (passwordFormGroup.get('txtPassword').dirty || btnSubmit))?'form-control is-invalid':'form-control'"
                            id="txtPassword" formControlName="txtPassword" (keyup)="checkMatchPassword()">
                        <div
                            *ngIf="passwordFormGroup.get('txtPassword').invalid && (passwordFormGroup.get('txtPassword').dirty || btnSubmit)">
                            <small *ngIf="passwordFormGroup.get('txtPassword').errors.required"
                                 class="invalid-feedback d-block">Password is required</small>
                            <small *ngIf="passwordFormGroup.get('txtPassword').errors.pattern"
                                 class="invalid-feedback d-block">Password must be at least 8 characters including one
                                number, one special character, one upper case and one lower case letter</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="txtPasswordConf" class="col-md-3 col-form-label">Confirm Password<span
                        class="text-danger">*</span>
                </label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <input type="Password" maxlength="20"
                            [ngClass]="(ConfPasswordNotMatch || (passwordFormGroup.get('txtPasswordConf').invalid && (passwordFormGroup.get('txtPasswordConf').dirty || btnSubmit)))?'form-control is-invalid':'form-control'"
                            id="txtPasswordConf" formControlName="txtPasswordConf" (keyup)="checkMatchPassword()">
                        <div
                            *ngIf="passwordFormGroup.get('txtPasswordConf').invalid && (passwordFormGroup.get('txtPasswordConf').dirty || btnSubmit)">
                            <small *ngIf="passwordFormGroup.get('txtPasswordConf').errors.required"
                                 class="invalid-feedback d-block">Confirm Password is required</small>
                            <small *ngIf="passwordFormGroup.get('txtPasswordConf').errors.pattern"
                                 class="invalid-feedback d-block">Confirm Password must be at least 8 characters
                                including one number, one special character, one upper case and one lower case
                                letter</small>
                        </div>
                        <small *ngIf="ConfPasswordNotMatch" class="invalid-feedback d-block">The passwords do not match,
                            please try again</small>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="d-flex justify-content-center pt-2">
        <div *ngIf="button==0">
            <button (click)="btnOK_Click()" class="btn btn-primary px-5">OK</button>
        </div>
        <div *ngIf="button==1">
            <button mat-button (click)="btnCancel_Click()" tabindex="-1"
                class="btn bg-white px-5 border-primary text-primary">CANCEL</button>
            <button mat-button (click)="btnOK_Click()" class="btn btn-primary px-5 ml-3">OK</button>
        </div>
        <div *ngIf="button==2">
            <button mat-button (click)="btnNo_Click()" tabindex="-1"
                class="btn bg-white px-5 border-primary text-primary">NO</button>
            <button mat-button (click)="btnYes_Click()" class="btn btn-primary px-5 ml-3">YES</button>
        </div>
        <div *ngIf="button==3">
            <button mat-button (click)="btnReject_Click()" tabindex="-1"
                class="btn bg-white px-5 border-primary text-primary">REJECT</button>
            <button mat-button (click)="btnAccept_Click()" class="btn btn-primary px-5 ml-3">ACCEPT</button>
        </div>
        <div *ngIf="button==4">
            <button mat-button (click)="btnCancel_Click()" tabindex="-1"
                class="btn bg-white px-md-5 px-3 border-primary text-primary">Cancel</button>
            <button mat-button (click)="btnOK_Click()" class="btn btn-primary px-5 ml-3">Submit</button>
        </div>
        <div *ngIf="button==5">
            <button mat-button (click)="btnCancel_Click()"
                class="btn bg-white px-md-5 px-3 border-primary text-primary">Cancel</button>
            <button mat-button (click)="btnOK_Click()" class="btn btn-primary px-5 ml-3">Submit</button>
        </div>
        <div *ngIf="button==6">
            <button mat-button (click)="btnCancel_Click()" tabindex="-1"
                class="btn bg-white px-5 border-primary text-primary">Cancel</button>
            <button mat-button (click)="btnOK_Click()" class="btn btn-primary px-5 ml-3">Login</button>
        </div>
        <div *ngIf="button==7">
            <button mat-button (click)="btnCancel_Click()" tabindex="-1"
                class="btn bg-white px-5 border-primary text-primary">Cancel</button>
            <button mat-button (click)="btnContinue_Click()" class="btn btn-primary px-5 ml-3">Continue</button>
        </div>
        <div *ngIf="button==8">
            <button mat-button (click)="btnNo_Click()" tabindex="-1"
                class="btn bg-white px-5 border-primary text-primary">Cancel</button>
            <button mat-button (click)="btnYes_Click()" class="btn btn-primary px-5 ml-3">Confirm</button>
        </div>
        <div *ngIf="button==9">
            <button (click)="btnOK_Click()" class="btn btn-primary px-5">{{buttonlabel}}</button>
        </div>
        <div *ngIf="button==10">
            <button mat-button (click)="btnNo_Click()" tabindex="-1"
                class="btn bg-white px-5 border-primary text-primary">CANCEL</button>
            <button mat-button (click)="btnYes_Click()" class="btn btn-primary px-4 ml-3"
                style="text-transform: none; background-color: red !important">Confirm Rejection</button>
        </div>
        <div *ngIf="button==11">
            <button mat-button (click)="btnYes_Click()" class="btn btn-primary px-4 ml-2">YES</button>
            <button mat-button (click)="btnNo_Click()" class="btn btn-primary px-4 ml-2">NO</button>
            <button mat-button (click)="btnContinue_Click()" class="btn btn-primary px-2 ml-2 mr-3">CANCEL</button>
        </div>
        <div *ngIf="button==12">
            <button mat-button (click)="btnYes_Click()" class="btn btn-primary px-4 ml-2">Yes, Upload Now</button>
            <button mat-button (click)="btnNo_Click()" class="btn btn-primary px-4 ml-2">No, Upload Later</button>
        </div>
        <div *ngIf="button==13">
            <button (click)="btnOK_Click()" class="btn btn-primary px-5">OK</button>
        </div>
        <div *ngIf="button==14" class="row">
            <div class="d-flex">
                <button mat-button (click)="btnCancel_Click()" class="btn btn-outline-primary cancel-btn d-flex align-items-center justify-content-center mr-4" ><i class="icon-previous-white"></i>Cancel</button>
                <button mat-button (click)="btnNext_Click()" class="btn btn-primary btn-new d-flex align-items-center justify-content-center">Next<i class="icon-next"></i></button>
            </div>
        </div>
    </div>

</div>