// Extend global Array type.
declare global {
    interface Array<T> {
        distinct(matcher?: (value: T, index: number, items: Array<T>) => boolean): Array<T>;
        take(findPredicate: (value: T, index: number, items: Array<T>) => boolean): Array<T>;
    }
}

export const ArrayHelper = {
    distinct<T>(items: Array<T>, matcher?: (value: T, index: number, items: Array<T>) => boolean): Array<T> {
        matcher = matcher || ((value: T, index: number, items: Array<T>) => items.indexOf(value) === index);
        return items.filter(matcher);
    },
    take<T>(items: Array<T>, findPredicate: (value: T, index: number, items: Array<T>) => boolean) {
        if (typeof findPredicate !== 'function') return [];
        return items.reduce((accumulatedArray, currentValue, currentIndex, array) => {
            if (findPredicate(currentValue, currentIndex, array)) {
                accumulatedArray.push(array.splice(currentIndex, 1));
            }
            return accumulatedArray;
        }, []);
    }
};

if (typeof Array.prototype.distinct !== 'function') {
    Array.prototype.distinct = function <T>(matcher?: (value: T, index: number, items: Array<T>) => boolean) {
        return ArrayHelper.distinct(this, matcher);
    }
}

if (typeof Array.prototype.take !== 'function') {
    Array.prototype.take = function <T>(findPredicate: (value: T, index: number, items: Array<T>) => boolean) {
        return ArrayHelper.take(this, findPredicate);
    }
}