<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">
    View/Edit Payment Requests {{countryTitle}}
</h4>

<div class="form-align">
    <!-- Main Mat Spinner -->
    <div class="loading-shade-global" *ngIf="isLoading">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <form [formGroup]="paymentRequestForm">
        <!--Facility and the text box-->
        <div class="form-group row">
            <label for="facilityName" class="col-md-3 col-lg-2 col-form-label" [ngStyle]="labelStyle()"
                class="text-primary"><b>Facility</b></label>
            <div style="width: 32em">
                <div class="input-group">
                    <input id="facilityName" class="form-control" formControlName="facility" type="text"
                        placeholder="Search facility by facility name" [matAutocomplete]="facilityAutocomplete"
                        [value]="selectedFacility" (keyup)="findFacilityByName($event); search(0)" tabindex="1"/>
                    <div class="input-group-append">
                        <button class="input-group-text" type="submit">
                            <i class="fas fa-search text-primary" *ngIf="!isSearchingFacility"></i>
                            <mat-spinner *ngIf="isSearchingFacility" [diameter]="14"></mat-spinner>
                        </button>
                    </div>
                </div>
                <mat-autocomplete #facilityAutocomplete="matAutocomplete">
                    <mat-option *ngFor="let facility of facilitySearchResult" (click)="search(0)"
                        [value]="facility.Name">
                        <p class="d-flex align-items-center m-0 ml-2 w-100">
                            {{ facility.Name }}
                        </p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>

        <div class="form-group row">
            <label for="slctViewFac" class="col-md-3 col-lg-2 col-form-label" [ngStyle]="labelStyle()"
                class="text-primary"><b>Status</b></label>
            <div style="width: 32em">
                <div class="input-group">
                    <!-- [Values] for the dropdown options are inconsistent. This is required. Please leave it as is. -->
                    <mat-select multiple class="form-control" placeholder="Select payment request status"
                        id="slctViewFac" formControlName="status" [(ngModel)]="defaultMatSelectValue"
                        (selectionChange)="search(0)" tabindex="2">
                        <mat-option [value]="101">All</mat-option>
                        <mat-option [value]="102">All Active Statuses</mat-option>
                        <mat-option [value]="1">Requires Anti-Fraud Check</mat-option>
                        <mat-option [value]="2">Awaiting Auto Approval</mat-option>
                        <mat-option [value]="5">Approved; Pending Invoice</mat-option>
                        <mat-option [value]="6">Approved</mat-option>
                        <mat-option [value]="3">Awaiting Manual Approval</mat-option>
                        <mat-option [value]="7">On Hold</mat-option>
                        <mat-option [value]="8">Posted</mat-option>
                        <mat-option [value]="9">Rejected</mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
        <!--Payment Period-->
        <div class="form-group row">
            <label for="txtrDescription" class="text-primary" [ngStyle]="labelStyle()"><b>Payment Period</b><span
                    class="text-danger">* &nbsp;
                    &nbsp;</span></label>

            <div style="display: inline-block; width: 16em">
                <label style="display: block" class="text-primary"><b>From</b></label>
                <div name="paymentFromDate" class="input-group">
                    <input name="dp" ngbDatepicker #paymentFromDate="ngbDatepicker" class="form-control"
                        formControlName="paymentDateFrmForm" placeholder="DD-MMM-YYYY" [maxDate]="selectedPmtToDate"
                        (dateSelect)="onDateFromSelected()" tabindex="3" />
                    <div class="input-group-append">
                        <button class="btn bg-white" (click)="paymentFromDate.toggle()" type="button">
                            <i class="far fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <p style="color: red;" *ngIf="IsShowMandatoryFromDate">From Date required</p>
            </div>
            &nbsp;
            <div style="display: inline-block; width: 16em">
                <label style="display: block" class="col-md-3 col-lg-2 col-form-label"
                    class="text-primary"><b>To</b></label>
                <div name="paymentToDate" class="input-group">
                    <input name="dp" ngbDatepicker #paymentToDate="ngbDatepicker" class="form-control"
                        formControlName="paymentDateToForm" placeholder="DD-MMM-YYYY" [minDate]="selectedPmtFromDate"
                        (dateSelect)="onDateToSelected()" tabindex="4" (keydown)="enterSearch($event)" />
                    <div class="input-group-append">
                        <button class="btn bg-white" (click)="paymentToDate.toggle()" type="button">
                            <i class="far fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
                <p style="color: red;" *ngIf="IsShowMandatoryToDate">To Date required</p>
            </div>
        </div>
    </form>
</div>
<div [ngStyle]="buttonsDivStyle()">
    <div [ngStyle]="standardButtonsStyle()" *ngIf="!searched">
        <button id="searchBtn" (click)="search(0)" style="width: 16em" class="btn btn-primary">
            SEARCH
        </button>
    </div>
    <div *ngIf="!searched">
        <br />
        <br />
    </div>
    <!-- Post Approved PRs Button -->
    <div [ngStyle]="standardButtonsStyle()">
        <button type="button" class="btn btn-primary" style="width: 16em" (click)="postedPRs()"
            [disabled]="approvedResults <= 0">
            POST APPROVED PRs
        </button>
        <!-- Info Butto-->
        <button type="button" class="btn btn-light" data-toggle="tooltip" data-placement="top"
            title="Post all approved payment requests which appear in this search and generate all required files for Xero.">
            <i class="fas fa-info-circle"></i>
        </button>
        <label class="text-muted">
            {{ approvedResults }} / {{ totalResults }} Approved PRs
        </label>
    </div>
    <br />
    <br />
    <div [ngStyle]="standardButtonsStyle()">
        <!-- Regenerate Posted PRs Button -->
        <button type="button" class="btn btn-primary" style="width: 16em" (click)="regeneratePRs()"
            [disabled]="postedResults <= 0">
            REGENERATE POSTED PRs
        </button>
        <!-- Info Butto-->
        <button type="button" class="btn btn-light" data-toggle="tooltip" data-placement="top"
            title="Regenerate Xero-related files of posted payment requests which appear in this search.">
            <i class="fas fa-info-circle"></i>
        </button>
        <label class="text-muted">
            {{ postedResults }} / {{ totalResults }} Posted PRs
        </label>
    </div>
    <div *ngIf="smallWidth()">
        <br />
        <br />
    </div>
    <div [ngStyle]="addManualPrStyle()">
        <a class="btn btn-primary" style="width: 20em"
            [routerLink]="RouterConstant.NAVIGATOR_VIEW_PAYMENT_REQUESTS_CREATE" [queryParams]="{country: routedCountry}"><i class="fas fa-plus-circle"></i>
            Add Manual Payment Request
        </a>
    </div>
</div>

<div class="w-100">
    <div class="form-group">
        <div class="border-default rounded-10 border-2">
            <div style="overflow-x: auto; width: 100%;">
                <table mat-table [dataSource]="dataSource" matSort matSortActive="DateCreated"
                    matSortDirection="desc" class="rounded-10">
                    <ng-container matColumnDef="Facility">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Facility
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.Facility }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Service">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Service
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.Service }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="DateCreated">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Payment Period
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.PaymentPeriodStart | dateFormatPipe }} -
                            {{ element.PaymentPeriodEnd | dateFormatPipe }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="VisitCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Visit Count
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.VisitCount }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="DollarAmount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Dollar Amount
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.DollarAmount | currency }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="PaymentCalculationMethod">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Calculation Method
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.PaymentCalculationMethod }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="AntiFraudStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Anti Fraud Status
                        </th>
                        <td mat-cell [ngStyle]="{
                    color: element.AntiFraudStatus === 'OK' ? 'green' : 'red'
                  }" *matCellDef="let element">
                            {{ element.AntiFraudStatus }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="PRStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            PR Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.PRStatus }}
                        </td>
                    </ng-container>
                    <!-- PRID is for front-end processing. Please don't delete. It is not displayed. -->
                    <ng-container matColumnDef="PRID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            PR Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.PaymentRequestID }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Download">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <a class="px-2"
                                (click)="downloadAttachements({ InvoiceAttachment: element.InvoiceAttachmentUuid, AdditionalAttachement: element.AdditionalAttachmentsUuid, AdditionalAttachmentsFileName: element.AdditionalAttachmentsFileName, InvoiceFileName: element.InvoiceFileName })"
                                *ngIf="element.InvoiceAttachmentUuid || element.AdditionalAttachmentsUuid">
                                <input type="image" src="../../../../assets/images/download_icon_manually_upload.png" />
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Edit">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <a class="px-2" (click)="editPaymentRequest(element.PaymentRequestID)" *ngIf="
                      element.PRStatus === 'Requires Anti-Fraud Check' ||
                      element.PRStatus === 'Awaiting Auto Approval' ||
                      element.PRStatus === 'Awaiting Manual Approval' ||
                      element.PRStatus === 'On Hold'
                    ">
                                <i class="fas fa-pen fa-1x"></i>
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="ApproveReject">
                        <th mat-header-cell *matHeaderCellDef>
                            <a (click)="search(0)">
                                <i class="fas fa-sync fa-xs" style="float: right"></i>
                            </a>
                        </th>


                        <td mat-cell *matCellDef="let element">
                            <button mat-button (click)="
                      fetchValuesFor3MonthHistory({
                        Service: element.Service,
                        Amount: element.DollarAmount,
                        PRID: element.PaymentRequestID,
                        FacilityName: element.Facility,
                        ServiceName: element.Service,
                        DateCreated: element.DateCreated
                      })
                    " *ngIf="
                      element.PRStatus === 'On Hold' ||
                      element.PRStatus === 'Awaiting Manual Approval'
                    " class="btn btn-primary btn-sm">
                                Approve/Reject
                            </button>

                            <button mat-button (click)="rejectButton({
                                        Amount: element.DollarAmount,
                                        PRID: element.PaymentRequestID})" *ngIf="
                      element.PRStatus === 'Approved' ||
                      element.PRStatus === 'Approved - Pending Invoice'
                    " class="btn btn-primary btn-sm">
                                Reject
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <mat-paginator #prpagi [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons (page)="paginatorChange($event)">
                </mat-paginator>
                <mat-toolbar [hidden]="dataSource.data.length > 0 || isLoading" class="bg-white">
                    <mat-toolbar-row class="text-muted d-block p-2 bg-light text-center">
                        {{ CommonString.TABLE_NO_RECORD_FOUND }}
                    </mat-toolbar-row>
                </mat-toolbar>
            </div>
        </div>
    </div>
</div>