<div [formGroup]="form">
    <div class="border-default rounded-10 border-2">
        <div class="tablecontainer height2 fpscrolly">
            <table mat-table matSort [dataSource]="dataSource" class="rounded-10" matSort matSortActive="productname"
                matSortDirection="desc" matSortStart="desc" matSortDisableClear (matSortChange)="sortChange($event)">
                <!-- Name Column -->
                <ng-container matColumnDef="productname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Product Name</th>
                    <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element"> {{element.Status.Name}} </td>
                </ng-container>

                <ng-container matColumnDef="producttype">
                    <th mat-header-cell *matHeaderCellDef>Product Type</th>
                    <td mat-cell *matCellDef="let element"> {{element.ServiceType.Name}} </td>
                </ng-container>

                <ng-container matColumnDef="fpproductcost">
                    <th mat-header-cell *matHeaderCellDef>FP Product Cost (ex GST)</th>
                    <td mat-cell *matCellDef="let element"> {{element.FpproductCost}} </td>
                </ng-container>

                <ng-container matColumnDef="membersaleprice">
                    <th mat-header-cell *matHeaderCellDef>Member Sale Price (Ex GST)</th>
                    <td mat-cell *matCellDef="let element"> {{element.MemberSalePrice}} </td>
                </ng-container>

                <ng-container matColumnDef="gstapplicable">
                    <th mat-header-cell *matHeaderCellDef>GST Applicable</th>
                    <td mat-cell *matCellDef="let element"> {{element.IsGstapplicable? "Yes" : "No"}} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef style="width:50px;"></th>
                    <td mat-cell *matCellDef="let element;let i=index">
                        <a class="px-2" data-toggle="modal" (click)="openDetailsDialog(i)" *ngIf="!form.disabled">
                            <i class="fas fa-pen"></i>
                        </a>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
            </table>
        </div>
        <mat-toolbar [hidden]="!loaded || dataSource.data.length > 0" class="bg-white">
            <mat-toolbar-row *ngIf="dataSource != null && dataSource.data.length == 0"
                class="text-muted d-block p-2 bg-light text-center">
                {{CommonString.TABLE_NO_RECORD_FOUND}}
            </mat-toolbar-row>
        </mat-toolbar>
    </div>

    <div class="pt-4">
        <button class="btn btn-primary" type="button" data-toggle="modal" (click)="openDetailsDialog()"
            *ngIf="!form.disabled">
            <i class="fas fa-plus-circle mr-2"></i>
            Add Product</button>
    </div>

</div>