<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Add New Employer</h4>
<div class="py-4"><a class="d-block d-sm-inline-block py-2 btn btn-primary btn-fp" routerLink="/dashboard/addemployer">Create New</a><span class="ml-md-2 d-block d-sm-inline-block text-center text-sm-left">Create from new form</span></div>
<div class="pb-4"><a class="d-block d-sm-inline-block py-2 btn btn-primary btn-fp" routerLink="/dashboard/searchemployer">Clone Existing</a><span class="ml-md-2 d-block d-sm-inline-block text-center text-sm-left">Create from existing employer</span></div>
<div class="pb-4"><a class="d-block d-sm-inline-block py-2 btn btn-primary btn-fp" routerLink="/dashboard/draftemployer">Continue from saved draft</a><span class="ml-md-2 d-block d-sm-inline-block text-center text-sm-left">Continue incomplete saved forms</span></div>
<!-- <div class="fpcard w-75 mx-auto mt-5 d-lg-flex">
    <a class="d-block text-center px-3" routerLink="/dashboard/employer/addemployer">
        <h5 class="fpcard-title mx-auto d-flex align-items-center justify-content-center font-weight-normal rounded-circle border-default border-2">Create New</h5>
        <p>Create from<br> new form</p>
    </a>
    <a class="d-block text-center px-3" routerLink="/dashboard/employer/searchemployer">
        <h5 class="fpcard-title mx-auto d-flex align-items-center justify-content-center font-weight-normal rounded-circle border-default border-2">Clone Existing</h5>
        <p>Create from<br> existing employer</p>
    </a>
    <a class="d-block text-center px-3" routerLink="/dashboard/employer/draftemployer">
        <h5 class="fpcard-title mx-auto d-flex align-items-center justify-content-center font-weight-normal rounded-circle border-default border-2">Continue from saved draft</h5>
        <p>Continue incomplete<br> saved forms</p>
    </a>
</div> -->