<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">View/Edit Packages</h4>
<!-- View Users -->
<div class="mt-lg-4">
    <form>
        <div class="my-4">
            <div class="border-default rounded-10 border-2">
                <div class="tablecontainer">
                    <table mat-table matSort [dataSource]="dataSource" class="rounded-10" matSort matSortActive="name"
                        matSortDirection="desc" matSortStart="desc" matSortDisableClear
                        (matSortChange)="sortChange($event)">
                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Package Name</th>
                            <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                        </ng-container>

                        <!-- <ng-container matColumnDef="state">
                            <th mat-header-cell *matHeaderCellDef>State</th>
                            <td mat-cell *matCellDef="let element"> {{element.States?.Name}} </td>
                        </ng-container> -->

                        <ng-container matColumnDef="state">
                            <th mat-header-cell *matHeaderCellDef>State</th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.States.length == 1; else multipleRegion">
                                    {{ element.States[0].Name }}
                                </span>
                                <ng-template #multipleRegion>
                                    <span [ngbPopover]="popContent" placement="top" triggers="mouseenter:mouseleave"
                                        #popover="ngbPopover">
                                        {{ element.States.length > 0 ? element.States[0].Name : '' }}{{
                                        element.States.length > 1 ? ", ..." : "" }}
                                    </span>
                                    <ng-template #popContent>
                                        <ul class="pl-4">
                                            <li *ngFor="let s of element.States">{{ s.Name ? s.Name : "" }}</li>
                                        </ul>
                                    </ng-template>
                                </ng-template>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="region">
                            <th mat-header-cell *matHeaderCellDef>Region</th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.Regions.length == 1; else multipleRegion">
                                    {{ element.Regions[0].Name }}
                                </span>
                                <ng-template #multipleRegion>
                                    <span [ngbPopover]="popContent" placement="top" triggers="mouseenter:mouseleave"
                                        #popover="ngbPopover">
                                        {{ element.Regions.length > 0 ? element.Regions[0].Name : '' }}{{
                                        element.Regions.length > 1 ? ", ..." : "" }}
                                    </span>
                                    <ng-template #popContent>
                                        <ul class="pl-4">
                                            <li *ngFor="let r of element.Regions">{{ r.Name ? r.Name : "" }}</li>
                                        </ul>
                                    </ng-template>
                                </ng-template>
                            </td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="region">
                            <th mat-header-cell *matHeaderCellDef>Region</th>
                            <td mat-cell *matCellDef="let element"> {{element.Regions?.Name}} </td>
                        </ng-container> -->

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width:100px;"></th>
                            <td mat-cell *matCellDef="let element">
                                <!-- <a class="px-2"
                                    href="{{RouterConstant.NAVIGATOR_PACKAGE_CLONE + '?sourceId=' + element.Id}}"
                                    *ngIf="!isReadonly">
                                    <i class="fas fa-clone"></i>
                                </a> -->
                                <a class="px-2" [routerLink]="[RouterConstant.NAVIGATOR_PACKAGE_EDIT, element.Id]">
                                    <i class="fas fa-pen"></i>
                                </a>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <mat-toolbar [hidden]="dataSource == null" class="bg-white">
                    <mat-toolbar-row [hidden]="dataSource != null && dataSource.data.length == 0" class="p-0">
                        <mat-paginator class="w-100" [length]="length" [pageSize]="pageSize"
                            [pageSizeOptions]="pageSizeOptions" (page)="paginatorChange($event)" showFirstLastButtons>
                        </mat-paginator>
                    </mat-toolbar-row>
                    <mat-toolbar-row *ngIf="dataSource != null && dataSource.data.length == 0"
                        class="text-muted d-block p-2 bg-light text-center">
                        {{CommonString.TABLE_NO_RECORD_FOUND}}
                    </mat-toolbar-row>
                </mat-toolbar>
            </div>
        </div>
        <button class="px-5 py-2 btn bg-white border-primary text-primary" type="button"
            (click)="this.router.navigate([RouterConstant.NAVIGATOR_PACKAGE_SEARCH]);"><i
                class="fas fa-arrow-alt-circle-left mr-2"></i>BACK</button>
    </form>
</div>