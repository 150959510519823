import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  DataResult,
  FileAttachmentModel,
  BulkUploadPostcodeResult,
  AWSStartExecutionResponse,
  Country,
  CommonResponse,
  State,
} from "@fp/models";
import { Observable, Subject, interval } from "rxjs";
import { PaymentService, MessageBox, CommonService } from "@fp/services";
import { catchError, debounceTime } from "rxjs/operators";
import { FPValidators } from 'src/app/helpers';
import { FpFileDataR2 } from "@fp/components/control";
import { FileNameService } from "@fp/services/file-name.service";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { FpProgressBarComponent } from "@fp/components/control/fp-progress-bar/fp-progress-bar.component";
import { StepFunctions } from "@fp/enums/step-functions.enum";
import { AwsServiceService } from "@fp/services/aws-service.service";
import { DescribeStateMachineComponent } from "@fp/components/describe-state-machine/describe-state-machine.component";
import { CommonConstants } from "@fp/constant/common-constants";
import { CommonMessage } from "@fp/constant/common-message";
import { BulkUploadPostcodeHistoryComponent } from "./bulk-upload-postcode-history/bulk-upload-postcode-history.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-bulk-upload-postcode",
  templateUrl: "./bulk-upload-postcode.component.html",
  styleUrls: ["./bulk-upload-postcode.component.css"],
})
export class BulkUploadPostcodeComponent implements OnInit {

  public BulkUploadFormGroup: UntypedFormGroup;
  regionList: CommonResponse[];
  regions: CommonResponse[];
  countries: Country[];
  private selectedCountry: Country = null;
  stateSelection = true;
  public selectedRegionName: '';
  public selectedStateId: number;
  searchRegion = "";  

  states: State[];
  private selectedState: State = null;
  dataDocumentAttachments: FileAttachmentModel[] = [];
  documentName = "No File Selected";
  colParameter = ["postcode", "town/city", "suburb"];

  StepFunctions = StepFunctions;
  uploadedFileName: string[];
  errorHandler: any;
  bulkUploadConfirmationMsg: string;
  errorMsg: string;
  checkNecessary = true;
  isLoading = false;
  buttonStyle = { width: "100%" };

  get CountryCtrl() {
    return <UntypedFormControl>this.BulkUploadFormGroup.get("ddlCountryPhy");
  }
  get StateCtrl() {
    return <UntypedFormControl>this.BulkUploadFormGroup.get("ddlStatePhy");
  }
  get RegionCtrl() {
    return <UntypedFormControl>this.BulkUploadFormGroup.get("searchRegionName");
  }

  CommonMessage = CommonMessage;

  /** The Progress Bar Modal in this Page */
  @ViewChild("progressBar") progressBar: FpProgressBarComponent;
  @ViewChild(DescribeStateMachineComponent)
  describeSpinner: DescribeStateMachineComponent;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    protected fileSvc: FileNameService,
    private paymentSvc: PaymentService,
    private awsService: AwsServiceService,
    protected dialog: MatDialog,
    private cmsrv: CommonService,
    private modal: NgbModal
  ) { }

  ngOnInit() {
    this.cmsrv.Header.title="Add Postcodes";
    this.BulkUploadFormGroup = this._formBuilder.group({
      ddlCountryPhy: [null, Validators.required],
      ddlStatePhy: [null, Validators.required],
      searchRegionName: [null, [Validators.required, FPValidators.resolvableAutocompleteOption]],
    });
    this.countries = CommonConstants.GET_COUNTRIES.sort((a, b) =>
      a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1
    );

    this.cmsrv.getAllRegions().subscribe((res) => {
      if (res.Success) {
        this.regionList = res.Data;
      }
    });
    const prefetchedCountry = <Country>this.CountryCtrl.value;
    const defaultCountry = this.countries.find((country) =>
      prefetchedCountry
        ? prefetchedCountry.CountryId === country.CountryId
        : country.Name === "Australia"
    );

    if (defaultCountry != null) {
      this.selectedCountry = defaultCountry;
      this.CountryCtrl.setValue(defaultCountry, { emitEvent: false });

      this.loadStatesByCountry(defaultCountry.CountryId, true);
    }
  }

  private loadStatesByCountry(countryId: number, firstLoad = false) {
    this.states = CommonConstants.GET_STATES.filter(
      (X) => X.CountryId == countryId
    ).sort((a, b) => a.StateId - b.StateId);
  }

  checkCountry(e) {
    this.loadStatesByCountry(e.CountryId, true);
    if (e.Name === "Australia") {
      this.stateSelection = true;
      this.StateCtrl.setValue(null, { emitEvent: false })
    } else {
      this.stateSelection = false;
      const state = this.states.find(s => s.StateId === 9);
      if (state) {
        this.selectedState = state;
        this.StateCtrl.setValue(this.selectedState, { emitEvent: false });
      }
      this.StateCtrl.markAsDirty();
    }
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError((e) => {
          throw e;
        })
      )
      .subscribe(
        (res) => {
          handleResultCallback(res);
        },
        (err) => {          
          this.errorHandler(err);
        }
      );
  }

  public AddFile_UploadCompleted(event) {
    const ret: FpFileDataR2 = event;
    this.dataDocumentAttachments.push({
      Location: ret.filedata.Location,
      Base64: "",
      FileName: ret.originfilename,
      Status: "Addnew",
      Id: 0,
    });
    this.documentName = ret.originfilename;
    console.log(this.dataDocumentAttachments);

    this.CheckBulkUpload();
  }

  public btnHistory_Click() {
    const modalRef = this.modal.open(BulkUploadPostcodeHistoryComponent, {
      windowClass: "fp-modal-window",
      backdrop: "static",
    });
  }

  // this function checks the Raw Visits from CSV and populates the number of Errors on the page
  public CheckBulkUpload() {
    this.bulkUploadConfirmationMsg =
      "You are about to upload postcode data, do you wish to continue?";
    MessageBox.ShowCancelContinue(
      this.dialog,
      "Bulk Postcode Upload Confirmation",
      this.bulkUploadConfirmationMsg
    ).subscribe((dialogResult) => {
      if (dialogResult.result.toLowerCase() === DialogResult.Continue) {
        // Open the Progress Bar
        this.progressBar.Open("Grabbing File");

        this.fileSvc.$currentUploadedFileUuid.subscribe(
          (uploadedFileName) => (this.uploadedFileName = uploadedFileName)
        );
        const fileNameString = this.uploadedFileName[0];
        const modifiedBy = this.cmsrv.GetUser();

        if (fileNameString !== null) {
          // Update Progress Bar Status and increment the Percentage every 1/5 of a second
          this.progressBar.upload_status_subject.next(
            "Uploading File: " + this.dataDocumentAttachments[0].FileName
          );
          this.progressBar.StartPercentTimer();

          var postCodeData = {
            id: "",
            ModifiedBy: modifiedBy,
            name: fileNameString,
            CountryCode: this.CountryCtrl.value.CountryId,
            RegionName: this.RegionCtrl.value.Name,
            StateCode: String(this.StateCtrl.value.StateId),
          };
          
          this.Invoke(
            this.awsService.StartStepExecution(
              StepFunctions.BulkUploadPostcodeDetail,
              null,
              null,
              null,
              postCodeData
            ),
            (result: AWSStartExecutionResponse) => {
              // Kill the Progress Bar
              this.progressBar.upload_percent_subject.next(100);
              this.progressBar.upload_status_subject.next("Upload Complete!");
              this.progressBar.close_subject.next(true);

              this.describeSpinner.stepMachineIsRunning = true;
              this.describeSpinner.executionArn = result.executionArn;
              this.describeSpinner.StartDescriptionTimer(5);
            }
          );
        }
      }
    });
  }

  handleBulkUploadResponse(result: DataResult<BulkUploadPostcodeResult>) {    
    if (!result.Data.ErrorMessage) {
      MessageBox.ShowInfo(
        this.dialog,
        "Upload is successful. All rows posted to postcode."
      ).subscribe((dialogResult) => {
        if (dialogResult.result.toLowerCase() === DialogResult.Ok) {
          this.fileSvc.resetFileData();
          window.location.reload();
        }
      });
    } else {
      var errorMsg = result.Data.ErrorMessage;
      this.fileSvc.resetFileData();
      this.errorMsg = `<strong>${errorMsg}</strong> Other records saved`;
      MessageBox.ShowError(this.dialog, this.errorMsg);
    }
  }

  /** @internal */
  AutoComplete_DisplayWithFn(option: any) {
    if (!option) {
      return "";
    }
    return option.Name || option.Code;
  } 

  searchRegions() {
    if (this.searchRegion.length >= 3) {
      this.regions = this.regionList.filter((x) =>
        x.Name.toLowerCase()
          .trim()
          .includes(this.searchRegion.toLowerCase().trim())
      );
    } else {
      this.regions = [];
    }
  }
}
