import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as AWSCognito from 'amazon-cognito-identity-js';
import { CommonService } from './common.service';
import {
  JWTTokenViewModel,
  JWT_AWS_TokenViewModel,
} from '@fp/models/jwttoken.model';
import {
  ConfirmResetPasswordInput,
  confirmSignUp,
  ConfirmSignUpInput,
  signIn,
  SignInInput,
  signUp,
  SignUpInput,
  signOut,
  resetPassword,
  ResetPasswordInput,
  SignInOutput,
  ResetPasswordOutput,
} from 'aws-amplify/auth';
import { v4 } from 'uuid';
import { Subject } from 'rxjs';
import { DatadogService } from './datadog.service';

const totpIssuer =
  environment.envName.toLocaleLowerCase() === 'prod'
    ? 'Fitness Passport'
    : `FP ${environment.envName}`;

enum CountryCode {
  AU = '+61',
  NZ = '+64',
}
const defaultCountryCode = CountryCode.AU;
const countryCodes = Object.values(CountryCode);

const COGNITO_IDENTITY_SERVICE_PROVIDER = 'CognitoIdentityServiceProvider';
const DEVICE_KEY_TOKEN = 'deviceKey';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public isSignupCompleted: boolean = false;
  public signupCompletionChanged = new Subject<boolean>();
  public nextSignInStep: SignInOutput['nextStep']['signInStep'];
  public resetPasswordCodeDelivery: ResetPasswordOutput['nextStep']['codeDeliveryDetails'];

  private _username: string;
  private _password: string;
  signupErrors: Record<string, string>;
  countryCode: string = defaultCountryCode;

  constructor(
    private commonsrv: CommonService,
    private datadogService: DatadogService,
  ) { }

  public AuthFormFields = {
    signIn: {
      username: {
        label: 'Username / Email',
        placeholder: 'Enter your username or email',
      },
      password: {
        placeholder: 'Enter your password',
      },
    },
    forgotPassword: {
      username: {
        label: 'Username / Email',
        placeholder: 'Enter your username or email',
      },
    },
    signUp: {
      username: {
        label: 'Email',
        placeholder: 'Enter your email',
      },
      password: {
        placeholder: 'Enter your password',
      },
      confirm_password: {
        placeholder: 'Confirm your password',
      },
      // Will be removed once new userpool is in place
      given_name: {
        placeholder: 'Enter your first name',
        label: 'First Name',
      },
      family_name: {
        placeholder: 'Enter your last name',
        label: 'Last Name',
      },
      phone_number: {
        placeholder: 'Enter your mobile phone number',
        label: 'Mobile Number',
        dialCode: defaultCountryCode,
        dialCodeList: countryCodes,
      },
    },
    setupTotp: {
      QR: {
        totpIssuer,
      },
    },
  };

  public setSignupCompletion(isCompleted: boolean) {
    this.isSignupCompleted = isCompleted;
    this.signupCompletionChanged.next(this.isSignupCompleted);
  }

  private async handleConfirmSignUp(input: ConfirmSignUpInput) {
    // Get the username from local storage if it exists
    // Clear the local storage after getting the username
    const username = this._username ?? input.username;
    return this.datadogService.trackAttempt('confirm_signup', () =>
      confirmSignUp({
        ...input,
        username,
        options: {
          ...input.options,
          authFlowType: 'USER_PASSWORD_AUTH', // Required with the new Cognito user pool and JIT lambda migration
          clientMetadata: {
            password: this._password,
          },
        },
      }).then(async (res) => {
        if (res.isSignUpComplete) {
          this.setSignupCompletion(true);
          await signOut({
            global: true,
          });
          res.nextStep = {
            signUpStep: 'DONE',
          };
        }
        return res;
      }),
    );
  }

  private findInLocalStorage(
    finder: (key: string, value: string | null) => boolean,
  ): [string, string] | undefined {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key);
      if (finder(key, value)) {
        return [key, value];
      }
    }
    return undefined;
  }

  private deviceKeyPresent() {
    return !!this.findInLocalStorage(
      (key) =>
        key.startsWith(COGNITO_IDENTITY_SERVICE_PROVIDER) &&
        key.endsWith(DEVICE_KEY_TOKEN),
    );
  }

  // For JIT migration, we need to pass the password in the auth request, instead of using SRP
  private async handleSignIn(input: SignInInput) {
    const { username: _username } = input;
    const username = _username.toLocaleLowerCase();

    const signInInput = {
      ...input,
      username,
    };

    const result: SignInOutput = await this.datadogService.trackAttempt(
      'signin',
      () => this.performSignIn(signInInput),
    );
    this.nextSignInStep = result.nextStep.signInStep;
    this.setSignupCompletion(false);
    return result;
  }

  private async performSignIn(signInInput) {
    if (this.deviceKeyPresent()) {
      try {
        return await this.signInSrp(signInInput);
      } catch (error) {
        return await this.signInUserPassword(signInInput);
      }
    }
    return await this.signInUserPassword(signInInput);
  }

  private signInUserPassword(signInInput): Promise<SignInOutput> {
    return signIn({
      ...signInInput,
      options: {
        ...signInInput.options,
        authFlowType: 'USER_PASSWORD_AUTH',
      },
    });
  }

  private signInSrp(signInInput): Promise<SignInOutput> {
    return signIn({
      ...signInInput,
      options: {
        authFlowType: 'USER_SRP_AUTH',
      },
    });
  }

  async handleSignUp(input: SignUpInput) {
    const name = [
      input.options?.userAttributes?.given_name,
      input.options?.userAttributes?.family_name,
    ].join(' ');
    const username = v4().split('-').join('.');
    input.options.userAttributes = {
      ...input.options.userAttributes,
      email: input?.username.toLocaleLowerCase() ?? '',
      name,
    };

    // We have to store the username and password to use in the confirm sign up flow
    this._username = username;
    this._password = input.password;

    return this.datadogService.trackAttempt('signup', () =>
      signUp({
        ...input,
        username,
        options: {
          ...input.options,
        },
      }).catch((e) => {
        const message = e.message;
        if (message.startsWith('PreSignUp failed with error ')) {
          const errorMessage = message.replace('PreSignUp failed with error ', '');
          throw { message: errorMessage };
        }
        throw e;
      }),
    );
  }

  async handleForgotPassword(input: ResetPasswordInput) {
    const result = await this.datadogService.trackAttempt(
      'forgotpassword',
      () =>
        resetPassword({
          ...input,
          username: input.username.toLocaleLowerCase(),
        }),
    );
    this.resetPasswordCodeDelivery = result.nextStep.codeDeliveryDetails;
    return result;
  }
  async validateCustomSignUp(formData: Record<string, string>) {
    const errors: Record<string, string> = {};
    const phoneNumber = formData['phone_number'];
    this.countryCode = formData['country_code'] ?? defaultCountryCode;

    if (phoneNumber) {
      const isAustralian = this.countryCode.startsWith(CountryCode.AU);
      const hasLeadingZero = phoneNumber.startsWith('0');
      if (isAustralian && (hasLeadingZero || !/^[0-9]{9}$/.test(phoneNumber))) {
        errors['phone_number'] =
          'Please enter a valid phone number (e.g. +61 412345678).';
      }

      const isNewZealand = this.countryCode.startsWith(CountryCode.NZ);
      if (
        isNewZealand &&
        (hasLeadingZero || !/^[0-9]{7,12}$/.test(phoneNumber))
      ) {
        errors['phone_number'] =
          'Please enter a valid phone number (e.g. +64 91234567).';
      }

      if (formData['phone_number'] !== formData['confirm_phone_number']) {
        errors['confirm_phone_number'] = 'Phone numbers do not match.';
      }
    }
    this.signupErrors = errors;
    return Object.keys(errors).length > 0 ? errors : null;
  }

  public AuthServices = {
    // To keep the user pool in sync, we need to spy on the forgot password flow and update the old user pool with the new password
    async handleForgotPasswordSubmit(input: ConfirmResetPasswordInput) {
      const { username: _username, newPassword } = input;
      const username = _username.toLocaleLowerCase();

      // This is temporary work around to update the password in both user pools
      const res = await fetch(
        `${environment.baseURL}user/resetcurrentpassword`,
        {
          method: 'POST',
          body: JSON.stringify({
            UserName: username,
            NewPassword: newPassword,
            PinCode: input.confirmationCode,
          }),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );

      if (res.ok) {
        return;
      }
      throw new Error('Failed to reset password');
    },
    handleSignIn: this.handleSignIn.bind(this),
    // In order to support the old userpool, we currently need to provide a username that is different from the email
    // Could be removed once we have the new userpool and we can use the email as the username
    handleSignUp: this.handleSignUp.bind(this),
    handleConfirmSignUp: this.handleConfirmSignUp.bind(this),
    handleForgotPassword: this.handleForgotPassword.bind(this),
    validateCustomSignUp: this.validateCustomSignUp.bind(this),
  };

  //Author: Da Do
  //Fix Refresh Token
  //2018-23-09 3:26 PM
  public refreshSession() {
    const user = this.commonsrv.GetUser();
    if (user == 'null' || user == undefined || user == null) {
      return;
    }
    const userPool = this.getUserPool();
    const refreshToken = this.commonsrv.GetRefreshTokenDecrypt();
    const cognitoUser = this.getUser(userPool, this.commonsrv.GetUser());
    cognitoUser.refreshSession(
      new AWSCognito.CognitoRefreshToken({ RefreshToken: refreshToken }),
      (error: any, res: any) => {
        if (error) {
          console.log('Cannot refresh token due to the error: ' + error);
        } else {
          this.setSessionData(res); //Set session data and optionally return accessToken as result
        }
      },
    );
  }

  public setSessionDataNewLogin(data: JWT_AWS_TokenViewModel) {
    let accessToken = data.Encode_IdToken;
    accessToken = this.commonsrv.E_FP_AES256(accessToken);
    let refreshToken = data.Encode_RefreshToken;
    refreshToken = this.commonsrv.E_FP_AES256(refreshToken);
    const user = this.commonsrv.GetUser();

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    if (user != undefined && user != '' && user != null) {
      this.commonsrv.SetUser(user);
    } else {
      localStorage.setItem('user', null);
    }
    return accessToken;
  }

  public setSessionData(data) {
    let accessToken = data.getIdToken().getJwtToken();
    accessToken = this.commonsrv.E_FP_AES256(accessToken);
    let refreshToken = data.getRefreshToken().getToken();
    refreshToken = this.commonsrv.E_FP_AES256(refreshToken);
    const user = this.commonsrv.GetUser();

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    if (user != undefined && user != '' && user != null) {
      this.commonsrv.SetUser(user);
    } else {
      localStorage.setItem('user', null);
    }
    return accessToken;
  }

  public setSignInUserSession(sessionData: JWTTokenViewModel) {
    const userPool = this.getUserPool();
    const username = this.commonsrv.GetUser();
    const currentUser = this.getUser(userPool, username);

    if (currentUser) {
      const userSession = new AWSCognito.CognitoUserSession({
        IdToken: new AWSCognito.CognitoIdToken({
          IdToken: sessionData.IdToken,
        }),
        AccessToken: new AWSCognito.CognitoAccessToken({
          AccessToken: sessionData.AccessToken,
        }),
        RefreshToken: new AWSCognito.CognitoRefreshToken({
          RefreshToken: sessionData.RefreshToken,
        }),
      });

      currentUser.setSignInUserSession(userSession);
    }
  }

  public logout() {
    const userPool = this.getUserPool();
    const username = this.commonsrv.GetUser();
    if (userPool !== null && username !== null) {
      const currentUser = this.getUser(userPool, username);
      currentUser.getSession((e, s) => { });

      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');

      if (currentUser) {
        currentUser.globalSignOut({
          onSuccess: (msg: string) => { },
          onFailure: (msg: Error) => { },
        });
        currentUser.signOut();
      }
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    }
  }

  public getUserPool() {
    const poolData = {
      UserPoolId: environment.cognitoUserPoolId,
      ClientId: environment.ClientId,
    };

    const userPool = new AWSCognito.CognitoUserPool(poolData);
    return userPool;
  }

  public getUser(userPool, username) {
    const userdata = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new AWSCognito.CognitoUser(userdata);
    return cognitoUser;
  }

  public getAuthenticationDetails(username, password) {
    const authenticationData = {
      Username: username,
      Password: password,
    };

    const authenticationDetails = new AWSCognito.AuthenticationDetails(
      authenticationData,
    );
    return authenticationDetails;
  }
}