import {
  Component,
  ElementRef,
  Injector,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { NavigationExtras, Router } from "@angular/router";
import { MemberFormBase } from "@fp/components/base";
import { RouterConstant, StorageKey } from "@fp/constant";
import { EMemberStatus, EMemberType } from "@fp/enums";
import {
  CommonService,
  MemberService,
  MembershipService,
  MessageBox,
} from "@fp/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  DataResult,
  Member,
  MemberSearchResultRecord,
  MembershipPackageTypeModel,
  RequestPagination,
  ResultDataModel,
  SearchPaginationResult,
} from "src/app/models";
import { AdditionalMemberPersonalDetailsDialogComponent } from "./additional-personal-details/additional-personal-details.component";
import { MemberPrimaryPersonalDetailsDialogComponent } from "./primary-personal-details/primary-personal-details.component";
import { ERole } from "@fp/enums/role.enum";
import { MemberContainer } from "@fp/models/member-container.model";
import { CommonConstants } from "@fp/constant/common-constants";
import { DialogResult } from "@fp/components/common-dialog-new/common-dialog-new.component";
import { GoogleMapPageViewComponent } from "@fp/google-map-page-view/google-map-page-view.component";

@Component({
  selector: "app-member-details",
  templateUrl: "./member-details.component.html",
  styleUrls: ["./member-details.component.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MemberFormBase,
      useExisting: AdditionalMemberPersonalDetailsDialogComponent,
    },
  ],
})
export class MemberDetailsComponent extends MemberFormBase {
  displayedColumns: string[] = [
    "firstName",
    "lastName",
    "memberType",
    "memberStatus",
    "MembershipStatus",
    "action",
  ];
  @ViewChild("dlgTermsConsContent") dlgTermsCons: ElementRef;
  @ViewChild("googlemap") googlemap: GoogleMapPageViewComponent;

  paging: RequestPagination = new RequestPagination();
  dataSource = new MatTableDataSource<MemberSearchResultRecord>();
  membershipPackageTypes: MembershipPackageTypeModel = {};
  membershipPackageName: string;
  isActivePrimaryMember = false;
  isAustralia = true;

  RouterConstant = RouterConstant;
  primaryMemberResult: Member;

  public MembershipStatus: string = "";

  constructor(
    injector: Injector,
    private modal: NgbModal,
    protected commonService: CommonService,
    private memberSvc: MemberService,
    private membershipSvc: MembershipService,
    public dialog: MatDialog,
    private router: Router
  ) {
    super(injector);
  }

  ngOnInit() {
    this.isAustralia = this.commonService.getUserCountry()?.toUpperCase() !==
      CommonConstants.COUNTRY_NEW_ZEALAND;
    this.updateHeaderTitle();
    this.initPaging()
  }

  ngAfterViewInit() {
    this.initMemberUser();
  }

  private updateHeaderTitle() {
    const uobject = this.commonService.GetUserObject();
    const fullname = uobject.FirstName;
    this.commonService.Header.title = "Hello, " + fullname + ". Welcome back!";
  }

  private initPaging() {
    this.paging.PageNumber = 0;
    this.paging.PageSize = 0;
    this.paging.Params[0].Name = "all_by_member_id";
    this.paging.Params[0].Value = this.commonService.D_FP_AES256ForShortString(
      localStorage.getItem("memberId")
    );
    this.paging.OrderBy = "primary_member";
  }

  private initMemberUser() {
    const isMember = this.commonService.GetSelectedRole() === ERole.member;
    if (!isMember) {
      this.InvokeBatch(
        [
          this.memberSvc.searchv2(this.paging),
        ],
        {
          onSuccess: (res: any[]) => {
            try {
              this.handleSearchMembersResult(res[0]);
              this.handleGetMembershipPackageTypesResult(res[0]);
            } catch (err) {
              this._logger.error(res);
              console.error(res);
            }
            super.OnLoad();
          },
        }
      );
    } else if (isMember) {
      const MemberId: number = +this.commonService.D_FP_AES256ForShortString(
        localStorage.getItem("memberId")
      );
      if (this.commonService.GetMemFamObj()) {
        const ret: SearchPaginationResult<MemberSearchResultRecord> = this.commonService.GetMemFamObj();
        this.dataSource = new MatTableDataSource<MemberSearchResultRecord>(
          ret.Results
        );
        for (let i = 0; i < this.dataSource.data.length; i++) {
          const item = this.dataSource.data[i];
          if (item.MemberType !== null && item.MemberType !== undefined) {
            if (item.MemberType.Id === EMemberType.Primary) {
              if (
                item.MembershipStatus !== null &&
                item.MembershipStatus !== undefined &&
                item.MembershipStatus.Name !== undefined &&
                item.MembershipStatus.Name !== null
              ) {
                this.MembershipStatus = item.MembershipStatus.Name;
                break;
              } else {
                if (item.MemberStatus.Id == EMemberStatus.New) {
                  this.MembershipStatus = "Inactive";
                  break;
                }
              }
            }
          }
        }
        const memberId: number = +this.commonService.D_FP_AES256ForShortString(
          localStorage.getItem("memberId")
        );
        const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
        if (getContainer != null) {
          let container = <MemberContainer>JSON.parse(getContainer);
          let membershipPackage = (<DataResult<Member>>(
            JSON.parse(this.commonService.D_FP_AES256(container.Member))
          )).Data.Memberships[0];
          if (membershipPackage != undefined) {
            this.membershipPackageName =
              membershipPackage.MembershipPackage.MembershipPackageType.Name;
            this.googlemap.LoadFacilitiesByMembershipPackageId(
              membershipPackage.MembershipPackageId,
              memberId
            );
          } else {
            this.InvokeBatch(
              [
                this.membershipSvc.getMembershipPackageTypeByMember(MemberId),
              ],
              {
                onSuccess: (res: any[]) => {
                  try {
                    this.handleGetMembershipPackageTypesResult(res[0]);
                  } catch (err) {
                    this._logger.error(res);
                    console.error(res);
                  }
                },
              }
            );
          }
        }
        this.isPrimaryMemberActive();
      } else {
        this.InvokeBatch(
          [
            this.memberSvc.searchv2formember(this.paging),
            this.membershipSvc.getMembershipPackageTypeByMember(MemberId),
          ],
          {
            onSuccess: (res: any[]) => {
              try {
                this.handleSearchMembersResult(res[0]);
                this.handleGetMembershipPackageTypesResult(res[1]);
              } catch (err) {
                this._logger.error(res);
                console.error(res);
              }
            },
          }
        );
      }
      super.OnLoadComplete();
    }
  }

  private handleSearchMembersResult(result: DataResult<string>) {
    if (result.Success) {
      const enctypted_data = result.Data;
      const dectypted_data = this.commonSvc.D_FP_ResponseData(enctypted_data);
      const ret: SearchPaginationResult<MemberSearchResultRecord> = JSON.parse(
        dectypted_data
      );
      this.dataSource = new MatTableDataSource<MemberSearchResultRecord>(
        ret.Results
      );
      for (let i = 0; i < this.dataSource.data.length; i++) {
        const item = this.dataSource.data[i];
        if (item.MemberType !== null && item.MemberType !== undefined) {
          if (
            item.MembershipStatus !== null &&
            item.MembershipStatus !== undefined &&
            item.MembershipStatus.Name !== undefined &&
            item.MembershipStatus.Name !== null
          ) {
            this.MembershipStatus = item.MembershipStatus.Name;
            break;
          }
        }
      }
      this.isPrimaryMemberActive();
    } else {
      this.HandleResponseError(result);
    }
  }

  private handleGetMembershipPackageTypesResult(result: string) {
    const memberId: number = +this.commonSvc.D_FP_AES256ForShortString(
      localStorage.getItem("memberId")
    );
    const dectypted_data = this.commonSvc.D_FP_ResponseData(result);
    const rdata: DataResult<MembershipPackageTypeModel> = JSON.parse(
      dectypted_data
    );
    if (rdata.Success) {
      this.membershipPackageTypes = rdata.Data;
      this.membershipPackageName = rdata.Data.Name;
      this.googlemap.LoadFacilitiesByMembershipPackageId(
        rdata.Data.MembershipPackageId[0],
        memberId
      );
    } else {
      const supportLink =
        '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
      MessageBox.ShowOKCustom(
        this.dialog,
        "Error",
        "Sorry, we're unable to complete the operation. \
          Please send us an enquiry via our support site. " +
        supportLink,
        "Take me back"
      ).subscribe((res) => {
        if (res.result.toLowerCase() === DialogResult.Ok) {
          this.commonService.SignOut();
        }
      });
    }
  }

  isPrimaryMemberActive() {
    if (
      this.dataSource.data[0].MemberType.Id == EMemberType.Primary &&
      (this.dataSource.data[0].MemberStatus.Id == EMemberStatus.Active ||
        this.dataSource.data[0].MemberStatus.Id == EMemberStatus.New ||
        this.dataSource.data[0].MemberStatus.Id == EMemberStatus.Changed)
    ) {
      this.isActivePrimaryMember = true;
    } else {
      this.isActivePrimaryMember = false;
    }
  }

  editMember(index: number) {
    const member = this.dataSource.data[index];
    this.getPrimaryMember(() => {
      this.openEditDialog(member, this.primaryMemberResult);
    });
  }

  openEditDialog(member: MemberSearchResultRecord, primaryMember = null) {
    this.commonService.MemberIdForNote = member.MemberId;
    if (member.MemberType.Id == EMemberType.Primary) {
      const modalRef = this.modal.open(
        MemberPrimaryPersonalDetailsDialogComponent,
        {
          windowClass: "fp-modal-window",
          backdrop: "static",
        }
      );
      const instance = <MemberPrimaryPersonalDetailsDialogComponent>(
        modalRef.componentInstance
      );
      instance.data.model = primaryMember;
      instance.OnLoad();
      modalRef.result.then(
        (result: { returnValue: Member }) => {
          if (result && result.returnValue) {
            this.primaryMemberResult = result.returnValue;

            member.FirstName = this.primaryMemberResult.FirstName;
            member.LastName = this.primaryMemberResult.LastName;
            member.MemberType.Name = this.primaryMemberResult.MemberType.Name;
            member.MemberStatus.Name = this.primaryMemberResult.MemberStatus.Name;
            member.MemberType.Id = this.primaryMemberResult.MemberType.MemberTypeId;
            member.MemberStatus.Id = this.primaryMemberResult.MemberStatus.MemberStatusId;
          }
        },
        (reason) => { }
      );
    } else {
      const modalRef = this.modal.open(
        AdditionalMemberPersonalDetailsDialogComponent,
        {
          windowClass: "fp-modal-window",
          backdrop: "static",
        }
      );
      const editedMember = <any>{
        MemberId: member.MemberId,
        PrimaryMember: primaryMember,
      };
      editedMember.PrimaryMember.FamilyMembers = primaryMember.FamilyMembers.filter(
        (_member) => _member.MemberId !== editedMember.MemberId
      );
      (<AdditionalMemberPersonalDetailsDialogComponent>(
        modalRef.componentInstance
      )).data.model = editedMember;
      const instance = <MemberPrimaryPersonalDetailsDialogComponent>(
        modalRef.componentInstance
      );
      instance.OnLoad();
      modalRef.result.then(
        (result: { returnValue: Member }) => {
          if (result && result.returnValue) {
            const updatedMember = result.returnValue;
            member.FirstName = updatedMember.FirstName;
            member.LastName = updatedMember.LastName;
            member.MemberType.Name = updatedMember.MemberType.Name;
            member.MemberStatus.Name = updatedMember.MemberStatus.Name;
            member.MemberType.Id = updatedMember.MemberType.MemberTypeId;
            member.MemberStatus.Id = updatedMember.MemberStatus.MemberStatusId;
          }
        },
        (reason) => { }
      );
    }
  }

  getPrimaryMember(callbackFn?: Function) {
    let primaryMember = this.dataSource.data[0];
    this.Invoke(this.memberSvc.getForMember(primaryMember.MemberId), {
      onSuccess: (result: DataResult<Member>) => {
        if (result.Success) {
          this.primaryMemberResult = result.Data;
          if (typeof callbackFn === "function") {
            callbackFn();
          }
        } else {
          this.HandleResponseError(result);
        }
      },
    });
  }

  addMember() {
    const callback = () => {
      const modalRef = this.modal.open(
        AdditionalMemberPersonalDetailsDialogComponent,
        {
          windowClass: "membersignup-dialog",
          backdrop: "static",
        }
      );
      const member = new Member();
      member.MemberId = 0;
      member.MemberIdPrimary = this.primaryMemberResult.MemberId;
      member.PrimaryMember = this.primaryMemberResult;
      member.MemberStatusId = EMemberStatus.CandidateMember;
      (<AdditionalMemberPersonalDetailsDialogComponent>(
        modalRef.componentInstance
      )).data.model = member;
      modalRef.result.then(
        (result: { returnValue: Member }) => {
          if (result && result.returnValue) {
            window["ChangeMembershipPackage_FamilyMemberData"] =
              result.returnValue;
            const navigationExtras: NavigationExtras = {
              queryParams: {
                openTab: 1,
                allowAdd: 1
              },
              skipLocationChange: true,
            };
            this.router.navigate(
              [RouterConstant.NAVIGATOR_CHANGE_MEMBERSHIP_PACKAGE],
              navigationExtras
            );
          }
        },
        (reason) => { }
      );
    };
    this.getPrimaryMember(() => {
      callback();
    });
  }

  OnChangeMembershipPackage() {
    // check ineligibility to change membership package
    const MemberId: number = +this.commonSvc.D_FP_AES256ForShortString(
      localStorage.getItem("memberId")
    );
    if (!MemberId) {
      return;
    }
    // remove data add family member
    delete window["ChangeMembershipPackage_FamilyMemberData"];
    this.Invoke(
      this.membershipSvc.getNumberOfMembershipChangeByMember(MemberId),
      {
        onSuccess: (result: DataResult<ResultDataModel>) => {
          if (result.Success) {
            const daysChange = result.Data;
            this.commonService.StopGlobalProgressBar();
            if (
              Number(daysChange) >= 30 ||
              this.dataSource.data[0].MemberStatus.Id === EMemberStatus.New
            ) {
              // go to page change membership package
              this.router.navigate(
                [RouterConstant.NAVIGATOR_CHANGE_MEMBERSHIP_PACKAGE],
                {
                  skipLocationChange: true,
                }
              );
            } else {
              MessageBox.ShowOKCustom(
                this.dialog,
                "Unable to Change Membership Package",
                "Membership packages can only be changed every 30 days.\
                                Since your membership package was changed less than 30 days ago, \
                                you are ineligible to change it again at this time. \
                                Please contact our Customer Support for more details.",
                "Back",
                "450px"
              );
            }
          } else {
            this.HandleResponseError(result);
          }
        },
      }
    );
  }
}
