<div [formGroup]="membershipPkgform">
    <div class="form-group row">
        <label for="ddlEmployerState" class="col-md-3 col-lg-2 col-form-label">Employer State</label>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex flex-column w-100">
                <select class="form-control" id="ddlEmployerState" [class.is-invalid]="!isControlValid('States')" [formControl]="StateCtrl">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngIf="states?.length > 0" [ngValue]="-1">All States</option>
                    <option *ngFor="let state of states;let i = index" [ngValue]="i">{{state.Name}}</option>
                </select>
                <div *ngIf="!isControlValid('States')">
                    <small *ngIf="getControl('States').hasError('required')"  class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'State')}}</small>
                </div>
                <div *ngIf="selectedStates?.length > 0"
                    class="mt-2 slctSelectedRoles w-100 border-2 rounded-10 border-default text-default">
                    <div *ngFor="let selectedState of selectedStates;let i=index"
                        class="d-flex border-default border-bottom py-1 align-content-center">
                        <span class="text-primary d-flex align-items-center" (click)="_removeState(i)">
                            <i class="fas fa-minus-circle fa-2x"></i>
                        </span>
                        <p class="d-flex align-items-center m-0 ml-2">{{selectedState.Name}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlEmployerStatus" class="col-md-3 col-lg-2 col-form-label">Employer Status</label>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex flex-column w-100">
                <select class="form-control" id="ddlEmployerState" formControlName="Status" (change)="onStatusSelectionChange($event.target.value)">
                    <option [ngValue]="null" selected>All Statuses</option>
                    <option *ngFor="let status of statuses" [ngValue]="status.Id">{{status.Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-md-3 col-lg-2 col-form-label">Last Price Change</label>
        <div class="col-md-8 col-lg-6">
            <div class="d-flex flex-column w-100">
                <div class="d-flex align-items-center">
                    <div class="mr-2">From</div>
                    <div class="input-group pr-2" [class.is-invalid]="!isControlValid('LastPriceChangeFrom')">
                        <input class="form-control" ngbDatepicker placeholder="DD-MM-YYYY" formControlName="LastPriceChangeFrom"
                            #dpLastPriceChangeFrom="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn bg-white" (click)="dpLastPriceChangeFrom.toggle()" type="button"><i
                                    class="far fa-calendar-alt"></i></button>
                        </div>
                    </div>
                    <div class="mx-2">to</div>
                    <div class="input-group pl-2" [class.is-invalid]="!isControlValid('LastPriceChangeTo')">
                        <input class="form-control" ngbDatepicker placeholder="DD-MM-YYYY" formControlName="LastPriceChangeTo"
                            #dpLastPriceChangeTo="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn bg-white" (click)="dpLastPriceChangeTo.toggle()" type="button"><i
                                    class="far fa-calendar-alt"></i></button>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isControlValid('LastPriceChangeFrom') || !isControlValid('LastPriceChangeTo')">
                    <small
                        *ngIf="getControl('LastPriceChangeFrom').hasError('ngbDate') || getControl('LastPriceChangeTo').hasError('ngbDate')"
                         class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_INVALID, 'Last Price Change')}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row mt-3">
        <div class="col-md-8 col-lg-6 offset-md-3 offset-lg-2">
            <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" [disabled]="form.invalid" (click)="Export()">
                <i class="fas fa-file-export mr-2"></i>Export</button>
        </div>
    </div>
</div>
