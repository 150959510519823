import { Postcode } from './postcodemodel';
import { CommonResponse } from './commonresponsemodel';

export class Suburb {
    SuburbId: number;
    Name: string;
    IsActive: boolean;
    Latitude?: string;
    Longtitude?: string;

    PostcodeId: number;
    Postcode: Postcode;
}

export class SuburbSearchResultRecord extends CommonResponse {
    Postcode: CommonResponse;
    State: CommonResponse;
    Country: CommonResponse;
    Region: CommonResponse;
}