<div [formGroup]="form">
  <ngb-accordion
    [destroyOnHide]="false"
    activeIds="pnlFamilyMemberDetails,pnlContactDetails,pnlMedicalQuestions,pnlPreferredFacilities,pnlAdditionalNotes"
  >
    <ngb-panel id="pnlFamilyMemberDetails">
      <ng-template ngbPanelTitle>
        <i
          class="icon-fp"
          [ngClass]="{ 'fas fa-check': memberDetails?.valid == true }"
        ></i>
        Add Family Member
        <i class="fas fas fa-chevron-down"></i>
        <i class="fas fas fa-chevron-up"></i>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-member-family-member-details
          [form]="form.get('MemberDetails')"
          [data]="data"
          #memberDetails
        >
        </app-member-family-member-details>
      </ng-template>
    </ngb-panel>
    <ngb-panel id="pnlContactDetails">
      <ng-template ngbPanelTitle>
        <i
          class="icon-fp"
          [ngClass]="{ 'fas fa-check': contactDetails?.valid == true }"
        ></i>
        Family Member Contact Details
        <i class="fas fas fa-chevron-down"></i>
        <i class="fas fas fa-chevron-up"></i>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-member-family-member-contact-details
          [isFamilyMemberUnderAgeDependent]="isFamilyMemberUnderAgeDependent"
          [form]="form.get('ContactDetails')"
          [data]="data"
          #contactDetails
        ></app-member-family-member-contact-details>
      </ng-template>
    </ngb-panel>
    <ngb-panel id="pnlMedicalQuestions">
      <ng-template ngbPanelTitle>
        <i
          class="icon-fp"
          [ngClass]="{ 'fas fa-check': medicalQuestions?.valid == true }"
        ></i>
        Family Member Medical Questions
        <i class="fas fas fa-chevron-down"></i>
        <i class="fas fas fa-chevron-up"></i>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-member-medical-questions
          [form]="form.get('MedicalQuestionsGrp')"
          [data]="data"
          #medicalQuestions
        >
        </app-member-medical-questions>
      </ng-template>
    </ngb-panel>
    <ngb-panel id="pnlPreferredFacilities" *ngIf="showPreferredFacilities">
      <ng-template ngbPanelTitle>
        <i
          class="icon-fp"
          [ngClass]="{ 'fas fa-check': preferredFacilities?.valid == true }"
        ></i>
        Family Member Preferred Facilities
        <i class="fas fas fa-chevron-down"></i>
        <i class="fas fas fa-chevron-up"></i>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-member-preferred-facilities
          [form]="form.get('PreferredFacilities')"
          [data]="data"
          #preferredFacilities
        ></app-member-preferred-facilities>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
