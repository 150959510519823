<div class="close position-relative">
    <img class="popup-close" mat-dialog-close src="./assets/images/close-popup.png" alt="">
</div>
<mat-dialog-content class="mat-typography filter-search-popup-content">
    <div class="filter-search-popup form-wrapper-new">
        <div class="filter-search-popup-title text-center">
            <h1 class="filter-name">
                Filter search results
            </h1>
        </div>
        <div class="filter-search-input  form-wrapper-new">
            <div class="form-group">
                <label for="empPayrollNo" class="col-form-label facility-name">Facility</label>
                <div class="input-group input-group-new ">
                    <div class="input-group-prepend">
                        <i class="fas fa-search"></i>
                    </div>
                    <input type="text" class="form-control" [(ngModel)]='listLocationFilter' [disabled]="isChecked"
                    placeholder="Type Facility Name"  (ngModelChange)='searchBarFunctionality()'>
                </div>
            </div>
        </div>
        <div class="facility-services">
            <label for="empPayrollNo" class="col-form-label facility-name">Activities</label>
            <div class="tag-wrapper">
                <div class="tag-wrapper" *ngFor="let item of services" class="activity-items">
                    <div class="tag">
                        <label>
                            <input type="checkbox" [name]="item.Name" value={{item.Name}}
                                [disabled]="(isChecked && (checkedName!=item.Name)) || (disableSearchFacByName)"
                                (change)="checkBoxFunctionality($event)"><span>{{item.Name}}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="filter-cta text-center">
            <button class="btn btn-primary btn-new btn-mobile-100" [mat-dialog-close]="this.combinedArray"
                cdkFocusInitial [disabled]="!isChecked && !disableSearchFacByName">Apply Filter</button>
        </div>
    </div>
</mat-dialog-content>