<div [formGroup]="form" #contactTemplate>
    <ng-template #requiredFieldMsgTmpl let-ctrl="control" let-ctrlName="fieldName"
        let-validateOnTouch="validateOnTouch || false">
        <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
            <small *ngIf="ctrl.errors['required']" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_REQUIRED, ctrlName)}}</small>
        </div>
    </ng-template>
    <div formArrayName="ListFacilityContact">
        <div *ngFor="let itemRow of contactListForm?.controls; let i=index" [formGroupName]="i"
            class="position-relative" [ngClass]="i > 0 ? 'border-top pt-3 mt-3' : ''">
            <div class="form-group row contact-type">
                <label for="slcContactType" class="col-md-3 col-lg-2 col-form-label">Contact Type<span
                        class="text-danger">*</span></label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <select class="form-control" id="slcContactType" formControlName="FacilityContactType"
                            [class.is-invalid]="!isControlValid(itemRow.get('FacilityContactType'), true)"
                            [compareWith]="slcContactType_CompareFn">
                            <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                            <option *ngFor="let contactType of contactTypes" [ngValue]="contactType">
                                {{contactType.Name}}</option>
                        </select>
                        <ng-container
                            *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: itemRow.get('FacilityContactType'), fieldName: 'Contact Type', validateOnTouch: true}">
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="form-group row name">
                <label for="txtName" class="col-md-3 col-lg-2 col-form-label">Name<span
                        class="text-danger">*</span></label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtName" formControlName="FacilityContactName"
                            [class.is-invalid]="!isControlValid(itemRow.get('FacilityContactName'))" maxlength="200">
                        <ng-container
                            *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: itemRow.get('FacilityContactName'), fieldName: 'Name'}">
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="form-group row position">
                <label for="txtPosition" class="col-md-3 col-lg-2 col-form-label">Position<span
                        class="text-danger">*</span></label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtPosition"
                            formControlName="FacilityContactPosition"
                            [class.is-invalid]="!isControlValid(itemRow.get('FacilityContactPosition'))"
                            maxlength="200">
                        <ng-container
                            *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: itemRow.get('FacilityContactPosition'), fieldName: 'Position'}">
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- <div class="form-group row">
                <label for="txtPhone" class="col-md-3 col-lg-2 col-form-label">Phone<span class="text-danger">*</span></label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <input class="form-control" type="tel" id="txtPhone" formControlName="FacilityContactPhone"
                            [class.is-invalid]="!isControlValid(itemRow.get('FacilityContactPhone'))" numberOnly maxlength="12">
                        <ng-container *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: itemRow.get('FacilityContactPhone'), fieldName: 'Phone'}"></ng-container>
                    </div>
                </div>
            </div> -->
            <app-phone-number class="phone" *ngIf="contactListForm.controls?.length > 0" [label]="'Phone'" #mobileNumberComponent
                [mandatory]="true" [control]="itemRow.get('FacilityContactPhone')" [submitted]="wasValidated"
                formControlName="FacilityContactPhone">
            </app-phone-number>
            <div class="form-group row email">
                <label for="txtEmail" class="col-md-3 col-lg-2 col-form-label">Email</label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <input class="form-control" type="tel" id="txtEmail" formControlName="FacilityContactEmail"
                            [class.is-invalid]="!isControlValid(itemRow.controls['FacilityContactEmail'])"
                            maxlength="200">
                        <ng-container
                            *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: itemRow.get('FacilityContactEmail'), fieldName: 'Email'}">
                        </ng-container>
                        <div *ngIf="!isControlValid(itemRow.get('FacilityContactEmail'))">
                            <!-- <small *ngIf="itemRow.get('FacilityContactEmail')?.errors['required']" class="invalid-feedback d-block">
                                {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Email')}}
                            </small> -->
                            <small *ngIf="itemRow.get('FacilityContactEmail')?.errors['pattern']"
                                class="invalid-feedback d-block">
                                {{StringHelper.format(CommonString.FIELD_INVALID, 'Email')}}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <span *ngIf="i > 0" class="pointer-cursor position-absolute pos-right-top mt-3"
                    (click)="removeContact(i)">
                    <i class="fas fa-2x fa-minus-circle text-primary"></i>
                </span>
            </div>
            <div class="form-group row">
                <label for="txtAddNote" class="col-md-3 col-lg-2 col-form-label">Add Note</label>
                <div class="col-sm-7">
                    <textarea class="form-control" id="txtAddNote" rows="3" formControlName="FacilityContactNote"
                        maxlength="200"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-8 col-lg-5 offset-sm-2">
            <button class="px-2 py-2 btn btn-primary text-transform-none" type="button" (click)="addContact()"
                [disabled]="form.disabled">
                <i class="fas fa-plus-circle mr-2"></i>
                Add additional contact</button>
        </div>
    </div>
</div>