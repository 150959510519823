<div class="modal-header">
    <h5 class="modal-title">Personal Details</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body py-3 px-md-3 fpscrolly height100">
    <div class="tabFPTheme mb-5">
        <app-member-personal-info [form]="form.get('PersonalInfo')" [data]="data"></app-member-personal-info>
    </div>

    <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
        <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button" (click)="activeModal.dismiss()">
            <i class="fas fa-arrow-circle-left mr-2"></i>Back</button>
        <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="btnSubmit_Click()">
            <i class="fas fa-check-circle mr-2"></i>
            Submit</button>
    </div>
</div>