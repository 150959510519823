import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { FPAbstractComponent } from "@fp/components/base";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { CommonString, RouterConstant, StorageKey } from "@fp/constants";
import { ECardOrderReason, EMemberCardStatus } from "@fp/enums";
import { ERole } from "@fp/enums/role.enum";
import {
  CustomMessageBox,
  CustomMessageBoxButton,
  CustomMessageBoxIcon,
  DateHelper,
  StringHelper,
} from "@fp/helpers";
import {
  DataResult,
  MembershipCardsModel,
  RequestPagination,
  ResultModel,
  SearchPaginationResult,
  User,
} from "@fp/models";
import { MemberCardService, MemberService, MembershipService } from "@fp/services";
import {
  MessageBox,
  MessageBoxButton,
} from "@fp/services/common-dialog.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { MembershipUploadPhotoComponent } from "./membership-upload-photo/membership-upload-photo.component";

const ACTION_CARD = {
  ORDER_NEW_CARD: 1,
  CANCEL_CARD: 2,
};

@Component({
  selector: "app-membership-cards",
  templateUrl: "./membership-cards.component.html",
  styleUrls: ["./membership-cards.component.css"],
})
export class MembershipCardsComponent
  extends FPAbstractComponent
  implements OnInit {
  commonString = CommonString;
  stringHelper = StringHelper;
  displayedColumns: string[] = [
    "LastName",
    "FirstName",
    "MemberType",
    "MemberNumber",
    "MemberCardStatus",
    "MemberCardNumber",
    "PhotoLocation",
    "action",
  ];
  pagingMembershipCard: RequestPagination = new RequestPagination();
  dataSourceMembershipCards = new MatTableDataSource<MembershipCardsModel>();
  newCardActions = false;

  memberShipCardsSelected: MembershipCardsModel = {};
  currentDate = new Date();
  EMemberCardStatus = EMemberCardStatus;
  currentUser = <User>JSON.parse(this.commonService.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
  newLocation: string;
  userName = this.commonService.GetUser();

  @ViewChild("txtUploadPhoto") txtUploadPhoto: ElementRef;

  constructor(
    injector: Injector,
    private router: Router,
    private commonService: CommonService,
    private memberService: MemberService,
    private svMemberCard: MemberCardService,
    private memberShipService: MembershipService,
    private modalService: NgbModal
  ) {
    super(injector);
  }

  ngOnInit() {
    this.commonService.Header.title = "Manage Membership Cards";
    this.OnLoad();
  }

  private getParamsPaging = function() {
    const memberId = this.commonService.D_FP_AES256ForShortString(
      localStorage.getItem("memberId")
    );
    const params = this.pagingMembershipCard;
    params.ModifiedBy = this.commonService.GetUser();
    params.pageSize = 0;
    params.Params = [
      {
        Name: "all_by_member_id_only_active",
        Value: memberId,
      },
    ];
    params.OrderBy = "name";
    params.OrderByAsc = true;
    params.ViewColumns = ["member_type", "member_card_status"];
    return params;
  };

  OnLoad() {
    const userType = this.commonService.GetSelectedRole();
    const params = this.getParamsPaging();
    var primaryMemberStatus;
    var membershipStatus;

    if (userType === 'Member') {
      // gets member data to see member and membership status for card editability
      this.memberService
        .getByUserForMember(this.currentUser.UserId)
        .subscribe((member) => {
          primaryMemberStatus = member.Data.MemberStatusId;
          if (member.Data.MemberIdPrimary) {
            // secondary member if memberidprimary is not null
            this.memberShipService.getMembershipSuspension(member.Data.MemberIdPrimary)
              .subscribe((primarymemSuspension) => {
                if (primarymemSuspension.Data.MembershipSuspensionId != 0) {
                  membershipStatus = 3
                }
                else {
                  membershipStatus = null
                }
                if (primaryMemberStatus == 5 || membershipStatus == 3) {
                  this.newCardActions = false;
                }
                else {
                  this.newCardActions = true;
                }
              })
          }
          else {
            // primary member if memberidprimary is null
            membershipStatus = member.Data.Memberships[0] ? member.Data.Memberships[0].MembershipStatusId : null;
            if (primaryMemberStatus == 5 || membershipStatus == 3) {
              this.newCardActions = false;
            }
            else {
              this.newCardActions = true;
            }
          }
        });

      this.Invoke(this.svMemberCard.searchformember(params), {
        onSuccess: (
          res: DataResult<SearchPaginationResult<MembershipCardsModel>>
        ) => {
          if (res.Success) {
            this.dataSourceMembershipCards = new MatTableDataSource<
              MembershipCardsModel
            >(res.Data.Results);
          } else {
            this.HandleResponseError(res);
          }
        },
      });
    }
    else {
      this.Invoke(this.svMemberCard.search(params), {
        onSuccess: (
          res: DataResult<SearchPaginationResult<MembershipCardsModel>>
        ) => {
          if (res.Success) {
            this.dataSourceMembershipCards = new MatTableDataSource<
              MembershipCardsModel
            >(res.Data.Results);
          } else {
            this.HandleResponseError(res);
          }
        },
      });
    }
  }

  public ClickLostStolenCardButton(item) {
    MessageBox.ShowCustom(
      this.dialog,
      "Lost/Stolen Card",
      "Lost/Stolen Card",
      "Marking your card as Lost/Stolen will deactivate your current membership card. \
            Your Fitness Passport membership will remain active. When you visit a facility, \
            you can manually enter details into the Fitness Passport scanners to continue \
            using your Fitness Passport membership. You will be able to order a new membership \
            card in the next step. Do you wish to continue?",
      MessageBoxButton.YesNo
    ).subscribe((r) => {
      if (r.result === DialogResult.Yes) {
        this.ConfirmLostStolenCard(item);
      }
    });
  }

  private ConfirmLostStolenCard(item) {
    CustomMessageBox.Show(
      this.dialog,
      {
        title: "Declaration",
        content:
          "<p>I, " +
          item.FirstName +
          " " +
          item.LastName +
          ', confirm that I have undertaken an extensive search, \
            and cannot find the Fitness Passport card(s) with which I was issued.\
            I understand that if the lost card is found that it will not be able to be used and I will destroy \
            the card immediately.</p>\
            <p>I understand that a breach of this declaration may result in a financial penalty consistent with \
            the penalties listed in the <a class="text-primary" href="https://www.fitnesspassport.com.au/termsandconditions" target="_blank">\
            terms and conditions</a> of Fitness Passport membership that I agreed to when I initially joined the program.\
            I also understand that there are other legal penalties that may apply if I provide a false declaration.</p>\
            <p>I understand that $10 will be charged for each replacement card</p>\
            <p>Declared on ' +
          DateHelper.format(this.currentDate, "DD-MMM-YYYY HH:mm") +
          "</p>",
        buttons: [
          {
            label: "BACK",
            dialogResult: "back",
          },
          {
            label: "ACCEPT",
            isPrimary: true,
            dialogResult: "accept",
          },
        ],
      },
      {
        width: "450px",
        afterClosed: (r) => {
          if (r.result === "accept") {
            const params = {
              Id: item.MemberCardId,
              Action: ACTION_CARD.CANCEL_CARD,
              OrderReason: ECardOrderReason.Member_Error_Charges_Fee,
              ModifiedBy: this.userName,
            };
            this.AcceptOrderNewCard(params);
          }
        },
      }
    );
  }

  public ClickOrderNewCardButton(item) {
    CustomMessageBox.Show(
      this.dialog,
      {
        content:
          'If you want a new photo on your membership card,\
            please press "<strong>Cancel</strong>" and then "<strong>Upload Photo</strong>". \
            If you need to order a new card with the same photo, please press "<strong>Continue</strong>".',
        buttons: [
          {
            label: "CANCEL",
            dialogResult: "cancel",
          },
          {
            label: "CONTINUE",
            isPrimary: true,
            dialogResult: "continue",
          },
        ],
        icon: CustomMessageBoxIcon.Error,
      },
      {
        width: "450px",
        afterClosed: (r) => {
          if (r.result === "continue") {
            this.ConfirmOrderNewCard(item);
          }
        },
      }
    );
  }

  private ConfirmOrderNewCard(item) {
    CustomMessageBox.Show(
      this.dialog,
      {
        title: "Order new card",
        content:
          "<p>I, " +
          item.FirstName +
          " " +
          item.LastName +
          ", confirm that I am requesting a new Fitness Passport card. \
            I acknowledge that previous versions of my Fitness Passport card have either been lost or destroyed and will no longer be able to be used to access facilities. \
            I understand the terms applicable to Fitness Passport membership cards as per the terms and conditions of Fitness Passport.</p>\
            <p>I understand that $10 will be charged for each replacement card</p>\
            <p>Declared on " +
          DateHelper.format(this.currentDate, "DD-MMM-YYYY HH:mm") +
          "</p>",
        buttons: [
          {
            label: "BACK",
            dialogResult: "back",
          },
          {
            label: "ACCEPT",
            isPrimary: true,
            dialogResult: "accept",
          },
        ],
      },
      {
        width: "450px",
        afterClosed: (r) => {
          if (r.result === "accept") {
            this.memberShipCardsSelected = item;

            const params = {
              Id: item.MemberCardId,
              Action: ACTION_CARD.CANCEL_CARD,
              OrderReason: ECardOrderReason.Member_Error_Charges_Fee,
              ModifiedBy: this.userName,
            };

            this.AcceptOrderNewCard(params);
          }
        },
      }
    );
  }

  private AcceptOrderNewCard(params) {
    this.Invoke(this.svMemberCard.cardAcionForMember(params), {
      onSuccess: (res: DataResult<ResultModel>) => {
        if (res.Success) {
          this.CreateOrderNewCardSuccess();
        } else {
          this.HandleResponseError(res);
        }
      },
    });
  }

  private CreateOrderNewCardSuccess() {
    CustomMessageBox.Show(
      this.dialog,
      {
        title: "Order new card",
        content:
          "Thank you for ordering \
            a new card! Your new card is on its way. If you require further assistance \
            please contact our Customer Support.",
        buttons: [CustomMessageBoxButton.Ok],
      },
      {
        width: "450px",
        afterClosed: (r) => {
          this.OnLoad();
        },
      }
    );
  }

  //FP-903-Part-3
  public ClickOrderNewCardForMobileButton(item) {
    CustomMessageBox.Show(
      this.dialog,
      {
        title: "Order New Card",
        content:
          'Fitness Passport membership cards are now digital! \
             Download the Fitness Passport MyFP app to access your card.',
        buttons: [
          {
            label: "CLOSE",
            dialogResult: "close",
          },
        ],
      },
      {
        width: "450px",
      }
    );
  }

  //FP-903-Part-3
  public ClickLostStolenCardForMobileButton(item) {
    CustomMessageBox.Show(
      this.dialog,
      {
        title: "Lost/Stolen Card",
        content:
          'Fitness Passport membership cards are now digital! \
             Download the Fitness Passport MyFP app to access your card.',
        buttons: [
          {
            label: "CLOSE",
            dialogResult: "close",
          },
        ],
      },
      {
        width: "450px",
      }
    );
  }

  public OnBack() {
    this.router.navigate([RouterConstant.NAVIGATOR_MEMBER_DETAILS]);
  }

  public OnSelectedUploadPhoto(item) {
    const isAdmin = this.commonService.GetSelectedRole() === ERole.admin;
    const isMember = this.commonService.GetSelectedRole() === ERole.member;
    if (isMember) {
      this.Invoke(
        this.memberShipService.getTotalUploadPhotoMember(item.MemberId),
        {
          onSuccess: (result: DataResult<number>) => {
            if (result.Data >= 1) {
              CustomMessageBox.Show(
                this.dialog,
                {
                  title: "Card Reorder Fee Confirmation",
                  content:
                    "Thank you for wanting to upload a new photo! Note that any update to your \
                      photo requires us to send you a new membership card. The fee for ordering a new card is $10 \
                      which will automatically be deducted from your payment method. Do you wish to proceed?",
                  buttons: [
                    {
                      label: "CANCEL",
                      isPrimary: false,
                      dialogResult: "cancel",
                    },
                    {
                      label: "PROCEED",
                      isPrimary: true,
                      dialogResult: "proceed",
                    },
                  ],
                },
                {
                  width: "500px",
                  afterClosed: (r) => {
                    if (r.result === "proceed") {
                      this.openModal(item, true);
                    }
                  },
                }
              );
            } else {
              this.openModal(item);
            }
          },
        }
      );
    }
    this.memberShipCardsSelected = item;
    const userName = this.commonService.GetUser();
    this.memberShipCardsSelected.ModifiedBy = userName;
  }

  //FP-903-Part-2
  public OnSelectedUploadPhotoForMobile(item) {
    const isAdmin = this.commonService.GetSelectedRole() === ERole.admin;
    const isMember = this.commonService.GetSelectedRole() === ERole.member;
    if (isMember) {
      this.Invoke(
        this.memberShipService.getTotalUploadPhotoMember(item.MemberId),
        {
          onSuccess: (result: DataResult<number>) => {
            if (result.Data >= 1) {
              this.openModal(item, true);
            } else {
              this.openModal(item);
            }
          },
        }
      );
    }
    this.memberShipCardsSelected = item;
    const userName = this.commonService.GetUser();
    this.memberShipCardsSelected.ModifiedBy = userName;
  }



  openModal(item, isChargeFee?: boolean) {
    const modalRef = this.modalService.open(MembershipUploadPhotoComponent, {
      backdrop: "static",
    });
    (<MembershipUploadPhotoComponent>(
      modalRef.componentInstance
    )).memberShipCardsSelected = item;
    if (isChargeFee) {
      (<MembershipUploadPhotoComponent>(
        modalRef.componentInstance
      )).isChargeFee = true;
    }

    modalRef.result.then(
      (result: { location: string }) => {
        if (result && result.location) {
          const userName = this.commonService.GetUser();
          this.memberShipCardsSelected.ModifiedBy = userName;

          this.memberShipCardsSelected.PhotoLocation = result.location;

          const isMember = this.commonService.GetSelectedRole() === ERole.member;
          if (isMember) {
            this.memberService
              .getByUserForMember(this.currentUser.UserId)
              .subscribe((getMember) => {
                if (getMember.Data.FamilyObject) {
                  this.commonService.SetMemFamObj(
                    getMember.Data.FamilyObject
                  );
                }
              });
          }
          this.OnLoad();
        }
      },
      (reason) => { }
    );
  }
}