
export class FacilityAdditionalNotes {
    Username: string;
    UserId: number = null;
    CreateOrUpdateDate: Date;
    NoteTypeId: number;
    Note: string;
    Action: string;
    FacilityNoteId: number;
    FacilityId: number;
    DateTimeDisplay: string = '';
    HoursofNote: number;
    Type: string;
    Key: string;
}

export class ListFacilityNotes {
    FacilityNotes: FacilityAdditionalNotes[] = [];
}
