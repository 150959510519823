import { Input, forwardRef, OnInit, Directive } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  AbstractControl,
} from '@angular/forms';

export function MakeProvider(type: any) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => type),
    multi: true,
  };
}

@Directive()
export abstract class BaseControlComponent<T> implements ControlValueAccessor, OnInit {
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() mandatory: boolean;
  @Input() placeholder: string;

  constructor() { }

  _value: T;

  get value(): T {
    return this._value;
  }

  set value(v: T) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  getName(control: AbstractControl): string | null {
    const group = <UntypedFormGroup>control.parent;
    if (!group) {
      return null;
    }
    let name: string;
    Object.keys(group.controls).forEach(key => {
      const childControl = group.get(key);
      if (childControl !== control) {
        return;
      }
      name = key;
    });
    return name;
  }


  writeValue(value: T) {
    this._value = value;
    this.onChange(value);
  }

  onChange = _ => { };
  onTouched = () => { };
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
