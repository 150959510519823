import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { merge, empty } from 'rxjs';
import { catchError, startWith, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { BaseControlComponent, MakeProvider } from '../base/base-control';
import { PatternConstant } from '../../../constant/patternconstant';
import { UserService } from '../../../services/admin/user/user.service';

import { MessageBox } from 'src/app/services/common-dialog.service';
import { CommonService } from '@fp/services/common.service';
import { CommonData } from '@fp/models';
import { CommonDataService } from '@fp/services';

@Component({
  selector: 'app-mobile-number',
  templateUrl: './mobile-number.component.html',
  styleUrls: ['./mobile-number.component.css'],
  host: { '(blur)': 'onTouched()' },
  providers: [MakeProvider(MobileNumberComponent)]
 // providers: [{ provide: MemberFormBase, useExisting: MemberFamilyMemberContactDetailsComponent }]
})

export class MobileNumberComponent<T> extends BaseControlComponent<string> implements OnInit {
  countryPhoneCode="";
  countryCodeSelect = '+61';
  @Input() countryCodeSelectEmergency: string;
  @Input() control: AbstractControl; // required
  @Input() label: string;
  @Input() placeholder: string;
  @Input() mandatory: boolean;
  @Input() submitted: boolean; // flag when click on submit button
  @Input() readonly: boolean;
  @Input() mobileLength = 10;
  @Input() pattern = PatternConstant.PATTERN_PHONE;
  @Input() checkExist: false;
  @Input() clsLabel: string;
  @Input() clsGroup: string;
  @Input() currentPhone: string = "";
  @Output() runSingupCheck = new EventEmitter();

  _validators: ValidatorFn[];

  invalidPhone = false;
  phoneSpinter = false;
  parentFocus = 0;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private cmsrv: CommonService
  ) {
    super();
    this._validators = [];
  }

  ngAfterViewInit() {
    this.countryPhoneCode =this.cmsrv.SetMobileCountryCode();       
    this.countryCodeSelect='+'.concat(this.countryPhoneCode) ;
    console.log("country"+this.countryCodeSelect);
    this.countryCodeSelectEmergency='+'.concat(this.countryPhoneCode);
    this.SetValidator();
}



  ngOnInit() {
    this._validators = [this.control.validator];
    if (this.mandatory) {
      this.control.setValidators([Validators.required, Validators.pattern(PatternConstant.PATTERN_PHONE_2)]);
    } else {
      this.control.setValidators([Validators.pattern(PatternConstant.PATTERN_PHONE_2)]);
    }
    this.CheckIsAUMobile();
  }

  public setCountryCodeManually(countryCode :string){
    this.countryCodeSelectEmergency= countryCode ;
  }

  public setCountryCodeWithoutUserCountry(countryCode :string){
    this.countryPhoneCode = countryCode;
    this.countryCodeSelectEmergency='+'.concat(this.countryPhoneCode);
    this.countryCodeSelect = '+'.concat(this.countryPhoneCode);
    this.SetValidator();
  }


  private CheckValidatePhone() {
    if (this.control.value != null) {
      if (this.control.value.indexOf('00') === 0) {
        this.invalidPhone = true;
      } else {
        this.invalidPhone = false;
      }
    }
  }

  private SetValidator() {
    if (this.control.value == null) {
      return;
    } else {
      if (this.mandatory) {
        if(this.countryCodeSelect.startsWith('+64')){
          this.mobileLength = 12;
            this.control.setValidators([
              Validators.required,
              Validators.pattern(PatternConstant.PATTERN_PHONE_6),
            ]);
        }
        else{
          this.mobileLength = 10;
            this.control.setValidators([
              Validators.required,
              Validators.pattern(PatternConstant.PATTERN_PHONE_2),
            ]);
        }
      } else {
        if(this.countryCodeSelect.startsWith('+64')){
          this.mobileLength = 12;
            this.control.setValidators([
              Validators.pattern(PatternConstant.PATTERN_PHONE_6),
            ]);
        }
        else{
          this.mobileLength = 10;
            this.control.setValidators([
              Validators.pattern(PatternConstant.PATTERN_PHONE_2),
            ]);
        }
      }

      this.control.updateValueAndValidity();
    }
  }

  private CheckIsAUMobile(){ 
    if(this.countryCodeSelect && this.countryCodeSelect.startsWith('+64')){
      return false}
      else{
        return true;      
      }
    }

  public checkPhoneEmailResponsive() {
    let number = this.control.value;
    if(number != null) {
      if(number.length >= 8) {
        this.runSingupCheck.emit('RunCheck');
      }
    }
  }

  public OnChange(event) {
    this.checkPhoneEmailResponsive();
    this.CheckValidatePhone();

    this.control.clearValidators();

    this.SetValidator();

    let val = event.target.value;
    const evkey = PatternConstant.NOT_NUMBER;
    const re = new RegExp(evkey, 'g');
    val = val.replace(re, '');
    this.control.setValue(val);
  }

  public CallOnChange(value) {
    this.CheckValidatePhone();

    this.control.clearValidators();

    let val = value;
    const evkey = PatternConstant.NOT_NUMBER;
    const re = new RegExp(evkey, 'g');
    val = val.replace(re, '');
    this.control.setValue(val);
    this.SetValidator();
  }

  OnNotCheckExistPhone() {
    this.onTouched();
    this.onBlurParent(0);
  }

  CheckExistPhone() {
    this.onTouched();
    this.onBlurParent(0);
    merge().pipe(
      startWith({}),
      switchMap(() => {
        let number = '';
        const length = (<string>this.control.value).length;
        if (length === 10) {
          number = this.control.value.replace(0, '');
        } else {
          number = this.control.value;
        }
        if (number.length > 0 && this.control.valid && !this.invalidPhone) {
          if (this.currentPhone.replace('+61', '') != number) {
            this.phoneSpinter = true;
            this.control.setErrors({ waitingcheck: true });
            return this.userService.checkExistPhonev2('+61' + number);
          }
        } else {
          this.phoneSpinter = false;
        }
        return empty();
      }),
      catchError((e) => { throw e; }))
      .subscribe(
        (result) => {
          const enctypted_data = result.Data;
          const dectypted_data = this.cmsrv.D_FP_ResponseData(enctypted_data);
          const ret = JSON.parse(dectypted_data);
          this.phoneSpinter = false;
          this.control.setErrors({ waitingcheck: false });
          this.control.updateValueAndValidity();
          if (result.Success) {
            if (result.Data !== null && ret.IsExist) {
              this.control.setErrors({ duplicated: true });
            } else {
              this.control.setErrors(null);
            }
          } else {
            MessageBox.ShowError(this.dialog, 'An error has occurred. Please contact the administrator');
          }
        });
  }
  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }
  onBlurParent(focusNumbr) {
    this.parentFocus = focusNumbr;
  }
  getClassFocused(vlFocused) {
    if (this.parentFocus === vlFocused) {
      return 'focused';
    } else {
      return '';
    }
  }

}
