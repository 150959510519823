import { Component, EventEmitter, Injector, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MemberFormBase } from 'src/app/components/base/member-form-base';
import { Member } from 'src/app/models';
import { MemberFamilyMemberDetailsDialogComponent } from './add-family-member/family-member-details-dialog.component';
import { EMemberStatus } from '@fp/enums';
import { CommonService } from '@fp/services';

@Component({
    selector: 'app-member-family-members',
    templateUrl: './family-members.component.html',
    styleUrls: ['./family-members.component.css'],
    providers: [{ provide: MemberFormBase, useExisting: MemberFamilyMembersComponent }]
})
export class MemberFamilyMembersComponent extends MemberFormBase {
    @Output() primaryMemberEditIconClick = new EventEmitter();
    _displayedColumns: string[] = ['FirstName', 'LastName', 'MemberType', 'MemberStatusId'];
    get displayedColumns(): string[] {
        return this.form.enabled ? this._displayedColumns.concat(['action']) : this._displayedColumns;
    }
    dataSource = new MatTableDataSource<Member>();
    EMemberStatus = EMemberStatus;
    IsMemberSignup: boolean = false;
    MembershipPackageId: number;
    DisplayAddFamilyMemberButton = false;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            FamilyMembers: [[]]
        });
    }

    constructor(
        injector: Injector,
        private modalService: NgbModal
    ) {
        super(injector);
    }

    OnLoad() {
        // https://jira.csc.com/browse/FP-4612 - Hide Add Family Member
        this.DisplayAddFamilyMemberButton = this.form.enabled;
        // End
        super.OnLoad();
        if (this.IsMemberSignup) {
            this._displayedColumns = ['FirstName', 'LastName', 'MemberType'];
        }
    }

    LoadComplete() {
        super.LoadComplete();
        setTimeout(() => {
            this.form.get('FamilyMembers').valueChanges.subscribe((value: any[]) => {
                this.RefreshDataSource();
            });
        });
    }

    PatchValue(value, options) {
        if (value['FamilyMembers'] == null) {
            value['FamilyMembers'] = [];
        }
        super.PatchValue(value, options);
    }

    editMember(i: number) {
        const members = <Array<Member>>this.form.get('FamilyMembers').value;
        const member = Object.assign({}, members[i]);
        if (member) {
            const modalRef = this.modalService.open(MemberFamilyMemberDetailsDialogComponent, {
                windowClass: 'membersignup-dialog',
                backdrop: 'static'
            });
            member.PrimaryMember = Object.assign({}, this.data.model, this.form.value);
            (<MemberFamilyMemberDetailsDialogComponent>modalRef.componentInstance).data.model = member;
            if (this.IsMemberSignup) {
                (<MemberFamilyMemberDetailsDialogComponent>modalRef.componentInstance).memberInfo.isSignUp = true;
                (<MemberFamilyMemberDetailsDialogComponent>modalRef.componentInstance).memberInfo.memPkgId = this.MembershipPackageId;
            }
            console.log(this.MembershipPackageId);
            modalRef.result.then(
                (result: { returnValue: Member }) => {
                    if (result && result.returnValue) {
                        // Clear the PrimaryMember object to reduce object size.
                        result.returnValue.PrimaryMember = null;
                        members[i] = result.returnValue;
                        this.form.get('FamilyMembers').setValue(members);
                        this.changeDetectorRef.markForCheck();
                    }
                },
                reason => { }
            );
        }
    }
    
    public isSignup() {
        return this.commonSvc.App.isMemberSignup;
      }

    AddMember() {
        const modalRef = this.modalService.open(MemberFamilyMemberDetailsDialogComponent, {
            windowClass: 'membersignup-dialog',
            backdrop: 'static'
        });
        const member = new Member();
        member.MemberId = 0;
        member.PrimaryMember = Object.assign({}, this.data.model, this.form.value);
        member.MemberStatusId = EMemberStatus.CandidateMember;
        (<MemberFamilyMemberDetailsDialogComponent>modalRef.componentInstance).data.model = member;
        (<MemberFamilyMemberDetailsDialogComponent>modalRef.componentInstance).OnLoad();
        if (this.IsMemberSignup) {
            (<MemberFamilyMemberDetailsDialogComponent>modalRef.componentInstance).memberInfo.isSignUp = true;
            (<MemberFamilyMemberDetailsDialogComponent>modalRef.componentInstance).memberInfo.memPkgId = this.MembershipPackageId;
        }
        console.log(this.MembershipPackageId);
        modalRef.result.then(
            (result: { returnValue: Member }) => {
                if (result && result.returnValue) {
                    result.returnValue.MemberIdPrimary = this.data.model.MemberId;
                    // Clear the PrimaryMember object to reduce object size.
                    result.returnValue.PrimaryMember = null;
                    const members = <Array<Member>>this.form.get('FamilyMembers').value;
                    members.push(result.returnValue);
                    this.form.get('FamilyMembers').setValue(members);
                    this.changeDetectorRef.markForCheck();
                }
            },
            reason => { }
        );
    }

    RefreshDataSource() {
        this.dataSource.data = [this.data.model].concat(this.form.get('FamilyMembers').value || []);
    }

    changeMemStatusIdToString(statusId) {
        switch (statusId) {
            case EMemberStatus.Active:
                return "Active";
            case EMemberStatus.Banned:
                return "Banned";
            case EMemberStatus.Changed:
                return "Changed";
            case EMemberStatus.Inactive:
                return "Inactive";
            case EMemberStatus.Invalid:
                return "Invalid";
            case EMemberStatus.New:
                return "New";
            case EMemberStatus.CandidateMember:
                return "Candidate Member";
        }
    }
}
