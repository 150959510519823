<div class="d-md-none title-wrapper text-center mobile-title-shadow">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>Personal Details</h1>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="persanal-details-wrapper registration-stepper-new accordion-custom form-wrapper-new">
        <form [formGroup]="persanalDetailsFormGroup">
          <fieldset [disabled]="isFormDisabled">
            <mat-accordion multi="true">
              <mat-expansion-panel expanded>
                <mat-expansion-panel-header class="accordion-custom--panel-header">
                  <mat-panel-title>
                    <h2>Basic Information</h2>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="according-custom--panel-content">
                  <div class="form-group row">
                    <label for="inputFirstName" class="col-sm-4 col-form-label">First Name*</label>
                    <div class="col-sm-8">
                      <div class="d-flex flex-column w-100">
                        <input type="text" maxlength="50" id="txtFirstName" class="form-control"
                          formControlName="txtFirstName" [ngClass]="
                          persanalDetailsFormGroup.get('txtFirstName')
                            .invalid &&
                          (persanalDetailsFormGroup.get('txtFirstName').dirty ||
                            persanalDetailsFormGroup.get('txtFirstName')
                              .touched ||
                            btnNext)
                            ? 'form-control is-invalid'
                            : 'form-control'
                        " />
                        <div *ngIf="
                          persanalDetailsFormGroup.get('txtFirstName')
                            .invalid &&
                          (persanalDetailsFormGroup.get('txtFirstName').dirty ||
                            persanalDetailsFormGroup.get('txtFirstName')
                              .touched ||
                            btnNext)
                        ">
                          <small *ngIf="
                            persanalDetailsFormGroup.get('txtFirstName').errors
                              .required
                          " class="invalid-feedback d-block">First Name is required</small>
                          <small *ngIf="
                            persanalDetailsFormGroup.get('txtFirstName').errors
                              .pattern
                          " class="invalid-feedback d-block">This field does not accept special characters. Please
                            try again</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="txtLasttName" class="col-sm-4 col-form-label">Last Name*</label>
                    <div class="col-sm-8">
                      <div class="d-flex flex-column w-100">
                        <input type="text" maxlength="50" id="txtLasttName" class="form-control"
                          formControlName="txtLasttName" [ngClass]="
                          persanalDetailsFormGroup.get('txtLasttName')
                            .invalid &&
                          (persanalDetailsFormGroup.get('txtLasttName').dirty ||
                            persanalDetailsFormGroup.get('txtLasttName')
                              .touched ||
                            btnNext)
                            ? 'form-control is-invalid'
                            : 'form-control'
                        " />
                        <div *ngIf="
                          persanalDetailsFormGroup.get('txtLasttName')
                            .invalid &&
                          (persanalDetailsFormGroup.get('txtLasttName').dirty ||
                            persanalDetailsFormGroup.get('txtLasttName')
                              .touched ||
                            btnNext)
                        ">
                          <small *ngIf="
                            persanalDetailsFormGroup.get('txtLasttName').errors
                              .required
                          " class="invalid-feedback d-block">Last Name is required</small>
                          <small *ngIf="
                            persanalDetailsFormGroup.get('txtLasttName').errors
                              .pattern
                          " class="invalid-feedback d-block">This field does not accept special characters. Please
                            try again</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputFirstName" class="col-sm-4 col-form-label">Gender*</label>
                    <div class="col-sm-8">
                      <div class="d-flex flex-column w-100">
                        <select formControlName="txtGender" class="form-control custom-select" [ngClass]="
                            persanalDetailsFormGroup.get('txtGender').invalid &&
                          (persanalDetailsFormGroup.get('txtGender').dirty ||
                          persanalDetailsFormGroup.get('txtGender').touched ||
                          btnNext)
                            ? 'is-invalid'
                            : ''
                        ">
                          <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                          <option *ngFor="let gender of genders" [ngValue]="gender.GenderId">{{gender.Name}}</option>
                        </select>
                        <div *ngIf="
                          persanalDetailsFormGroup.get('txtGender').invalid &&
                          (persanalDetailsFormGroup.get('txtGender').dirty ||
                            persanalDetailsFormGroup.get('txtGender').touched ||
                            btnNext)
                        ">
                          <small *ngIf="
                            persanalDetailsFormGroup.get('txtGender').errors
                              .required
                          " class="invalid-feedback d-block">Gender is required</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputFirstName" class="col-sm-4 col-form-label">Date of Birth*</label>
                    <div class="col-sm-8">
                      <div class="d-flex flex-column w-100">
                        <div class="date-of-birth-input d-flex w-100 justify-content-between">
                          <select class="form-control mr-2 custom-select" formControlName="psdDay" [ngClass]="
                            persanalDetailsFormGroup.get('psdDay').invalid &&
                            (persanalDetailsFormGroup.get('psdDay').dirty ||
                              persanalDetailsFormGroup.get('psdDay').touched ||
                              btnNext)
                              ? 'is-invalid'
                              : ''
                          " (change)="onDOBSelected();">
                            <option value="" disabled selected>Day</option>
                            <option *ngFor="let day of days" [ngValue]="day">{{day}}</option>

                          </select>
                          <select class="form-control mr-2 custom-select" formControlName="psdMonth" [ngClass]="
                            persanalDetailsFormGroup.get('psdMonth').invalid &&
                            (persanalDetailsFormGroup.get('psdMonth').dirty ||
                              persanalDetailsFormGroup.get('psdMonth')
                                .touched ||
                              btnNext)
                              ? 'is-invalid'
                              : ''
                          " (change)="onDOBSelected();">
                            <option value="" disabled selected>Month</option>
                            <option value="0">January</option>
                            <option value="1">February</option>
                            <option value="2">March</option>
                            <option value="3">April</option>
                            <option value="4">May</option>
                            <option value="5">June</option>
                            <option value="6">July</option>
                            <option value="7">August</option>
                            <option value="8">September</option>
                            <option value="9">October</option>
                            <option value="10">November</option>
                            <option value="11">December</option>
                          </select>
                          <select class="form-control custom-select" formControlName="psdYear" [ngClass]="
                            persanalDetailsFormGroup.get('psdYear').invalid &&
                            (persanalDetailsFormGroup.get('psdYear').dirty ||
                              persanalDetailsFormGroup.get('psdYear').touched ||
                              btnNext)
                              ? 'is-invalid'
                              : ''
                          " (change)="onDOBSelected();">
                            <option value="" disabled selected>Year</option>
                            <option *ngFor="let year of years" [ngValue]="year">{{year}}</option>
                          </select>
                        </div>

                        <div *ngIf="
                          (persanalDetailsFormGroup.get('psdDay').invalid &&
                            (persanalDetailsFormGroup.get('psdDay').dirty ||
                              persanalDetailsFormGroup.get('psdDay').touched ||
                              btnNext))                        
                        ">
                          <small *ngIf="                           
                            persanalDetailsFormGroup.get('psdDay').errors
                            ?.required
                          " class="invalid-feedback d-block">Date of Birth is required</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="txtMobileNumber" class="col-sm-4 col-form-label">Mobile Phone</label>
                    <div class="col-sm-8">
                      <div class="d-flex flex-column w-100">
                        <input class="form-control" formControlName="txtMobileNumber" disabled />
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputFirstName" class="col-sm-4 col-form-label">Primary Email*</label>
                    <div class="col-sm-8">
                      <div class="d-flex flex-column w-100">
                        <input type="email" class="form-control" formControlName="txtPEmail" [ngClass]="
                          persanalDetailsFormGroup.get('txtPEmail').invalid &&
                          (persanalDetailsFormGroup.get('txtPEmail').dirty ||
                            persanalDetailsFormGroup.get('txtPEmail').touched ||
                            btnNext)
                            ? 'form-control is-invalid'
                            : 'form-control'
                        " disabled />
                        <div *ngIf="
                          persanalDetailsFormGroup.get('txtPEmail').invalid &&
                          (persanalDetailsFormGroup.get('txtPEmail').dirty ||
                            persanalDetailsFormGroup.get('txtPEmail').touched ||
                            btnNext)
                        ">
                          <small *ngIf="
                            persanalDetailsFormGroup.get('txtPEmail').errors
                              .required
                          " class="invalid-feedback d-block">
                            Primary Email is required</small>
                          <small *ngIf="
                            persanalDetailsFormGroup.get('txtPEmail').errors
                              .pattern
                          " class="invalid-feedback d-block">Primary Email is invalid</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputFirstName" class="col-sm-4 col-form-label">Secondary Email</label>
                    <div class="col-sm-8">
                      <div class="d-flex flex-column w-100">
                        <input type="email" class="form-control" formControlName="txtSEmail" [ngClass]="
                          persanalDetailsFormGroup.get('txtSEmail').invalid &&
                          (persanalDetailsFormGroup.get('txtSEmail').dirty ||
                            persanalDetailsFormGroup.get('txtSEmail').touched ||
                            btnNext)
                            ? 'form-control is-invalid'
                            : 'form-control'
                        " />
                        <div *ngIf="
                          persanalDetailsFormGroup.get('txtSEmail').invalid &&
                          (persanalDetailsFormGroup.get('txtSEmail').dirty ||
                            persanalDetailsFormGroup.get('txtSEmail').touched ||
                            btnNext)
                        ">
                          <small *ngIf="
                            persanalDetailsFormGroup.get('txtSEmail').errors
                              .pattern
                          " class="invalid-feedback d-block">Secondary Email is invalid</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-md-4">
                      <div *ngIf="imgUrl=='' || imgUrl==null" class="mx-auto">
                        <img src="assets/images/upload-photo-image.png" alt="" class="img-fluid user-profile " />
                      </div>
                      <div *ngIf="imgUrl!='' && imgUrl !=null" class="mx-auto">
                        <app-member-image [imgSrc]="imgUrl" [htmlClass]="'img-fluid user-profile mx-auto'">
                        </app-member-image>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="upload-img-wrapper pt-3">
                        <input type="file" capture="camera" accept="image/*" name="cameraInput" id="selectedFile"
                          style="display: none;" (change)="addImage($event)">
                        <button *ngIf="isMobile" class="btn btn-camera btn-outline-primary btn-outline-new w-100 mt-2 "
                          type="button" onclick="document.getElementById('selectedFile').click();">
                          Take photo with camera
                        </button>
                        <form *ngIf="isMobile" #formUploadPhoto>
                          <button (click)="uploadPhoto($event)" #fpfileupload type="button"
                            class="btn btn-new btn-primary w-100  align-items-center justify-content-center mt-4">
                            Upload from gallery
                          </button>
                        </form>
                        <button *ngIf="!isMobile" (click)="uploadPhoto($event)" #fpfileupload type="button"
                          class="btn btn-new btn-primary btn-mobile-100 align-items-center btn-upload mt-4">
                          Upload Photo
                          <i class="icon-upload"></i>
                        </button>
                        <div class="upload-img-wrapper-info">
                          <p>
                            Image uploaded needs to be a solo passport/portrait
                            style photo with maximum size of 10mb and in formats
                            JPEG or PNG. This photo will be used for your
                            membership card.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel class="accordion-custom--panel-header" expanded (opened)="panelOpenState = true"
                (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h2>Address</h2>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <p class="font-italic" style="font-size: 13px;">If you can't find your exact address, you can simply
                  fill in the address fields manually.</p>
                <div class="according-custom--panel-content">
                  <input type="hidden" class="form-control" id="txtAddress3" formControlName="StreetAddress1"
                    maxlength="100">
                  <div class="form-group row">
                    <label for="inputAddressLine" class="col-sm-4 col-form-label">Address 1*</label>
                    <div class="col-sm-8">
                      <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" [class.is-invalid]="!isControlValid(AddressLine1Ctrl)"
                          id="txtAddress1" matInput [matAutocomplete]="addressAutocomplete"
                          formControlName="txtAddressLine1" maxlength="50" (blur)="txtAddress_Blur($event)"
                          (input)="txtAddress1_Input($event)" (focusout)="AutoComplete_FocusOut($event)">
                        <mat-spinner [diameter]="14" color="primary" *ngIf="AddressLine1Ctrl.pending"></mat-spinner>
                        <div *ngIf="
                      persanalDetailsFormGroup.get('txtAddressLine1').invalid &&
                      (persanalDetailsFormGroup.get('txtAddressLine1').dirty ||
                        persanalDetailsFormGroup.get('txtAddressLine1').touched ||
                        btnNext)">
                          <small *ngIf="
                        persanalDetailsFormGroup.get('txtAddressLine1').errors
                          .required
                      " class="invalid-feedback d-block">Address 1 is required</small>
                        </div>
                        <mat-autocomplete id="autoAddress" name="autoAddress"
                          class="height1 address-autocomplete fpscrolly border-2 rounded-10 border-default w-100"
                          #addressAutocomplete="matAutocomplete" [displayWith]="AutoCompleteAddress_DisplayWithFn"
                          (optionSelected)="SelectAddress1($event)">
                          <mat-option *ngFor="let address of filteredAdressList" [value]="address"
                            class="border-default border-bottom py-1">
                            <p class="m-0 d-flex align-items-center">{{address.Text}}</p>
                          </mat-option>
                        </mat-autocomplete>
                      </div>

                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="txtAddress2" class="col-sm-4 col-form-label">Address 2</label>
                    <div class="col-sm-8">
                      <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtAddress2" formControlName="txtAddressLine2"
                          maxlength="100">
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputSuburb" class="col-sm-4 col-form-label">Suburb*</label>
                    <div class="col-sm-8">
                      <div class="d-flex flex-column w-100">
                        <input type="text" [ngClass]="
                        persanalDetailsFormGroup.get('txtSuburb').invalid &&
                        (persanalDetailsFormGroup.get('txtSuburb').dirty ||
                          persanalDetailsFormGroup.get('txtSuburb').touched ||
                          btnNext)
                          ? 'form-control is-invalid'
                          : 'form-control'" [class.is-invalid]="!isControlValid(SuburbCtrl)" id="txtCitySubb" matInput
                          [matAutocomplete]="suburbAutocomplete" formControlName="txtSuburb" maxlength="50"
                          (blur)="txtSuburb_Blur($event)" (input)="txtSuburb_Input($event)">
                        <mat-spinner [diameter]="14" color="primary" *ngIf="isSearchingSurburb"></mat-spinner>
                        <div *ngIf="
                        persanalDetailsFormGroup.get('txtSuburb').invalid &&
                        (persanalDetailsFormGroup.get('txtSuburb').dirty ||
                          persanalDetailsFormGroup.get('txtSuburb').touched ||
                          btnNext)">
                          <small *ngIf="
                          persanalDetailsFormGroup.get('txtSuburb').errors
                            .required
                        " class="invalid-feedback d-block">Suburb is required</small>
                        </div>
                        <mat-autocomplete id="autoSuburb" name="autoSuburb"
                          class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                          #suburbAutocomplete="matAutocomplete" [displayWith]="AutoComplete_DisplayWithFn"
                          (optionSelected)="SelectSuburb($event.option.value)">
                          <mat-option *ngFor="let suburb of suburbs" [value]="suburb"
                            class="border-default border-bottom py-1">
                            <p class="m-0 d-flex align-items-center">{{suburb.Name + ', ' + suburb.Postcode.State.Name +
                              ', ' + suburb.Postcode.Code }}</p>
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!isAu" class="form-group row">
                    <label for="txtCity" class="col-sm-4 col-form-label">Town/City*</label>
                    <div class="col-md-8">
                      <div class="d-flex flex-column w-100">
                        <input type="text" id="txtCity" [ngClass]="
                        persanalDetailsFormGroup.get('txtCity').invalid &&
                        (persanalDetailsFormGroup.get('txtCity').dirty ||
                          persanalDetailsFormGroup.get('txtCity').touched ||
                          btnNext)
                          ? 'form-control is-invalid'
                          : 'form-control'" [class.is-invalid]="!isControlValid(CityCtrl, true)" #citySearchBox
                          [class.disabled]="CityCtrl.disabled" placeholder="Search City" formControlName="txtCity"
                          matInput [matAutocomplete]="autoCity" (blur)="txtCity_Blur($event)"
                          (input)="txtCity_Input($event)">

                        <mat-spinner [diameter]="14" color="primary" *ngIf="isSearchingCity"></mat-spinner>

                        <div *ngIf="!isControlValid(CityCtrl, true)">
                          <small *ngIf="CityCtrl.getError('unresolved'); else notEquals"
                            class="invalid-feedback d-block">Town/City is invalid</small>
                          <small *ngIf="CityCtrl.getError('required')" class="invalid-feedback d-block">
                            {{StringHelper.format(CommonString.FIELD_REQUIRED, 'City')}}</small>
                        </div>
                        <mat-autocomplete id="autoCity" name="autoCity"
                          class="height1 fpscrolly border-2 rounded-10 border-default w-100" #autoCity="matAutocomplete"
                          [displayWith]="AutoCompleteCity_DisplayWithFn"
                          (optionSelected)="SelectCity($event.option.value)">
                          <mat-option *ngFor="let city of citySearchResults" [value]="city"
                            class="border-default border-bottom py-1">
                            <p class="m-0 d-flex align-items-center"> {{city.Name}}</p>
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="txtPostcode" class="col-sm-4 col-form-label">Postcode*</label>
                    <div class="col-sm-8">
                      <div class="d-flex flex-column w-100">
                        <select class="form-control" [ngClass]="
                          persanalDetailsFormGroup.get('txtPostcode').invalid &&
                          (persanalDetailsFormGroup.get('txtPostcode').dirty ||
                            persanalDetailsFormGroup.get('txtPostcode').touched ||
                            btnNext)
                            ? 'form-control is-invalid'
                            : 'form-control'
                        " [class.is-invalid]="!isControlValid(PostCodeCtrl, true)" id="ddlPostcode"
                          formControlName="txtPostcode" [compareWith]="ddlPostcodeOption_CompareFn">
                          <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                          <option *ngFor="let postcode of postcodes" [ngValue]="postcode">{{postcode.Code}}</option>
                        </select>
                        <div *ngIf="
                          persanalDetailsFormGroup.get('txtPostcode').invalid &&
                          (persanalDetailsFormGroup.get('txtPostcode').dirty ||
                            persanalDetailsFormGroup.get('txtPostcode').touched ||
                            btnNext)
                        ">
                          <small *ngIf="
                            persanalDetailsFormGroup.get('txtPostcode').errors
                              .required
                          " class="invalid-feedback d-block">Postcode is required</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="isAu">
                    <label for="txtState" class="col-sm-4 col-form-label">State*</label>
                    <div class="col-sm-8">
                      <div class="d-flex flex-column w-100">
                        <select class="form-control" [class.is-invalid]="!isControlValid(StateCtrl, true)" [ngClass]="
                          persanalDetailsFormGroup.get('txtState').invalid &&
                          (persanalDetailsFormGroup.get('txtState').dirty ||
                            persanalDetailsFormGroup.get('txtState').touched ||
                            btnNext)
                            ? 'form-control is-invalid'
                            : 'form-control'" id="ddlState" formControlName="txtState"
                          [compareWith]="ddlStateOption_CompareFn">
                          <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                          <option *ngFor="let state of states" [ngValue]="state">{{state.Name}}</option>
                        </select>
                        <div *ngIf="
                          persanalDetailsFormGroup.get('txtState').invalid &&
                          (persanalDetailsFormGroup.get('txtState').dirty ||
                            persanalDetailsFormGroup.get('txtState').touched ||
                            btnNext)
                        ">
                          <small *ngIf="
                            persanalDetailsFormGroup.get('txtState').errors
                              .required
                          " class="invalid-feedback d-block">Suburb is required</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputCountry" class="col-sm-4 col-form-label">Country</label>
                    <div class="col-sm-8">
                      <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" formControlName="txtCountry" disabled />
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel expanded>
                <mat-expansion-panel-header class="accordion-custom--panel-header">
                  <mat-panel-title>
                    <h2>Emergency Contact</h2>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="according-custom--panel-content">
                  <div class="form-group row">
                    <label for="inputEMCName" class="col-sm-4 col-form-label">Name*</label>
                    <div class="col-sm-8">
                      <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" formControlName="txtEMName" [ngClass]="
                          persanalDetailsFormGroup.get('txtEMName').invalid &&
                          (persanalDetailsFormGroup.get('txtEMName').dirty ||
                            persanalDetailsFormGroup.get('txtEMName').touched ||
                            btnNext)
                            ? 'form-control is-invalid'
                            : 'form-control'
                        " />
                        <div *ngIf="
                          persanalDetailsFormGroup.get('txtEMName').invalid &&
                          (persanalDetailsFormGroup.get('txtEMName').dirty ||
                            persanalDetailsFormGroup.get('txtEMName').touched ||
                            btnNext)
                        ">
                          <small *ngIf="
                            persanalDetailsFormGroup.get('txtEMName').errors
                              .required
                          " class="invalid-feedback d-block">Emergency Contact Name is required</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputEMCRelationship" class="col-sm-4 col-form-label">Relationship*</label>
                    <div class="col-sm-8">
                      <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" formControlName="txtEMRelationship" [ngClass]="
                          persanalDetailsFormGroup.get('txtEMRelationship')
                            .invalid &&
                          (persanalDetailsFormGroup.get('txtEMRelationship')
                            .dirty ||
                            persanalDetailsFormGroup.get('txtEMRelationship')
                              .touched ||
                            btnNext)
                            ? 'form-control is-invalid'
                            : 'form-control'
                        " />
                        <div *ngIf="
                          persanalDetailsFormGroup.get('txtEMRelationship')
                            .invalid &&
                          (persanalDetailsFormGroup.get('txtEMRelationship')
                            .dirty ||
                            persanalDetailsFormGroup.get('txtEMRelationship')
                              .touched ||
                            btnNext)
                        ">
                          <small *ngIf="
                            persanalDetailsFormGroup.get('txtEMRelationship')
                              .errors.required
                          " class="invalid-feedback d-block">Relationship is required</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <app-mobile-number-registor [mandatory]="true" [isCountryCodeSet]="true" [isAus]="isAu"
                    [control]="persanalDetailsFormGroup.get('txtEmergencyMobileNumber')"
                    formControlName="txtEmergencyMobileNumber" #emergencyMobileNumberRegistorComponent
                    id="mobile-no-wrapper" [col_4]="col_4" [clsLabel]="clsLableVal">
                  </app-mobile-number-registor>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel expanded>
                <mat-expansion-panel-header class="accordion-custom--panel-header">
                  <mat-panel-title>
                    <h2>Medical Questions</h2>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <medical-questions-component [data]="data" #medicalQuestions></medical-questions-component>
              </mat-expansion-panel>
            </mat-accordion>
            <div class="fixed-mobile with-back-btn">
              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex">
                    <button
                      class="btn btn-outline-primary btn-outline-new d-flex align-items-center justify-content-center mr-4"
                      matStepperPrevious type="button">
                      <i class="icon-previous"></i> Back
                    </button>
                    <button class="btn btn-primary btn-new d-flex align-items-center justify-content-center"
                      #nxtButtonRef (click)="pdSubmit()" [disabled]="!persanalDetailsFormGroup.valid" type="button">
                      Next<i class="icon-next"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>