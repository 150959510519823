<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Add/Edit Package</h4>
<div class="tabFPTheme tabFPThemeExt mb-5">
    <ngb-accordion [destroyOnHide]="false" activeIds="panelUserDetail, panelContact">
        <ngb-panel id="panelUserDetail">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': detailsSection?.valid}"></i>
                Package Details
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-package-details [form]="form.get('DetailsSection')" [data]="data"
                    (filtersChange)="handleSearchFiltersChanged($event)" #details></app-package-details>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="panelContact">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': serviceListSection?.valid}"></i>
                Facilities and Services
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-package-service-list [form]="form.get('ServiceListSection')" [data]="data" #serviceList>
                </app-package-service-list>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="panelAudit" *ngIf="existingPackage">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': true}"></i>
                Audit Log
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-audit-log [auditSource]="'Package'" [loadOnInit]=true #auditLog></app-audit-log>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
    <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start"
        *ngIf="!isReadonly">
        <button class="px-2 px-lg-5 ml-2 py-2 btn btn-primary" type="button" (click)="btnSubmit_Click()">
            <i class="fas fa-check mr-2"></i>Submit</button>
    </div>
</div>

<!-- TODO: remove after implementation completed -->
<!-- #region DEBUG -->
<div *ngIf="isDebugging" class="tabFPTheme mt-5">
    <pre>Request count: {{ data.requestQueueCount }}</pre>
    <pre>Form valid: {{ form.valid }}</pre>
    <ngb-accordion>
        <ngb-panel id="pnlFormJson">
            <ng-template ngbPanelTitle>
                Form JSON
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <pre>{{ JSON.stringify(form.value, null, 4) }}</pre>
            </ng-template>
        </ngb-panel>
        <ngb-panel>
            <ng-template ngbPanelTitle>
                Data JSON
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <pre>{{ JSON.stringify(data.model, null, 4) }}</pre>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>
<!-- #endregion DEBUG -->