<div [formGroup]="form">
    <ngb-accordion activeIds="facilityDocAttachmentsId,panelAEFacility">
        <ngb-panel id="facilityDocAttachmentsId">
            <ng-template ngbPanelTitle>
                <i class="fas fa-check icon-fp"></i>
                Document Attachments
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="form-group row">
                    <label for="fileAAttachment" class="col-md-3 col-lg-2 col-form-label d-table">Additional
                        Attachments</label>
                    <div class="col-md-8 col-lg-6">
                        <div class="d-flex flex-column w-100">
                            <div class="d-flex" *ngIf="!form.disabled">
                                <div>
                                    <app-fp-file-upload
                                        filterFile="application/pdf,application/vnd.ms-excel,text/plain,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.docx"
                                        (UploadCompleted)="AddFile_UploadCompleted($event)" maxfilesize="100"
                                        (fileSelected)="AttachmentAdditionalSelected($event)" #facilityDocument>
                                    </app-fp-file-upload>
                                </div>
                                <span class="col-form-label ml-2">PDF, DOCX, DOC, XLS, XLSX, TXT</span>
                            </div>
                            <div *ngIf="dataDocumentAttachments.length>0"
                                class="slctRoles border-2 rounded-10 mt-3 border-default">
                                <div *ngFor="let document of dataDocumentAttachments"
                                    class="d-flex border-default border-bottom py-1 justify-content-between">
                                    <a class="d-inline-block align-items-center m-0 w-90 wordbreak" target="_blank"
                                        [href]="document.DocumentLocation">{{document.FileName}}</a>
                                    <span class="text-primary d-flex align-items-center" *ngIf="!form.disabled"><i
                                            (click)="RemoveAttachmentByType(document, 'Attachment')"
                                            class="fas fa-minus-circle fa-2x"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="panelAEFacility">
            <ng-template ngbPanelTitle>
                Facility Photos
                <i class="icon-fp fas fa-check"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <!-- <div class="custom-file mb-2 mx-auto mx-sm-0 w-50 w-sm-150px text-center">
            <input class="custom-file-input" id="txtAttachLogo" type="file" multiple="multiple" accept="image/*"
              (change)="addPhotos($event)" #fileInput (click)="fileInput.value = null">
            <label class="custom-file-label bg-primary text-light w-100" for="txtAttachLogo">
              <i class="fas fa-plus-circle mr-2"></i>Upload Image</label>
          </div> -->

                <app-fp-file-upload [filterFile]="'.png, .jpg, .jpeg'" #facilityPhoto
                    (UploadCompleted)="photos_UploadCompleted($event)" (fileSelected)="_fileSelected($event)"
                    *ngIf="!form.disabled">
                </app-fp-file-upload>

                <div class="row image-gallery">
                    <div class="col-md-4 thumb" *ngFor="let p of photos; let i = index">
                        <div class="fp-profile img-thumbnail d-flex align-items-center position-relative">
                            <img [attr.src]="p.Data == null ? p.Location : p.Data" [attr.alt]="" class="img-fluid">
                            <div class="overlay" *ngIf="!form.disabled">
                                <div class="position-absolute position-center row mx-0">
                                    <a class="cursordisplay bg-white w-h-30px d-flex align-items-center justify-content-center rounded-10"
                                        title="Remove the image" (click)="removeImage(p, i)">
                                        <i class="text-danger fas fa-times"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>