<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Generate Reports</h4>
<div class="mt-lg-4" *ngIf="IsEFOrFSM()">
  <form [formGroup]="ReportFormGroup">
    <div class="form-group row" *ngIf="IsEF()">
      <label for="ddlEmployer" class="col-md-3 col-lg-2 col-form-label">Employer<span class="text-danger"
          *ngIf="IsEmpRequired">*</span></label>
      <div class="col-md-8 col-lg-6">
        <div class="d-flex flex-column w-100">
          <select class="form-control" id="ddlEmployer" formControlName="ddlEmployer"
            [ngClass]="SetClassForControl(ReportFormGroup,'ddlEmployer')">
            <option value="" selected>Select employer</option>
            <option *ngFor="let e of AvailableEmployers" [value]="e.EmployerId">
              {{e.Name}}
            </option>
          </select>
          <div *ngIf="IsControlInvalid(ReportFormGroup,'ddlEmployer')">
            <small *ngIf="ReportFormGroup.get('ddlEmployer').hasError('required')"
              class="invalid-feedback d-block">Employer is required</small>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row" *ngIf="IsFSM()">
      <label for="ddlFacility" class="col-md-3 col-lg-2 col-form-label">Facilities<span class="text-danger"
          *ngIf="IsFacRequired">*</span></label>
      <div class="col-md-8 col-lg-6">
        <div class="d-flex flex-column w-100">
          <select class="form-control" id="ddlFacility" formControlName="ddlFacility"
            [ngClass]="SetClassForControl(ReportFormGroup,'ddlFacility')" (change)="ddlFacility_Change($event)">
            <option value="" selected>Select facility</option>
            <option *ngFor="let f of AvailableFacilities" [value]="f.FacilityId">
              {{f.Name}}
            </option>
          </select>
          <div *ngIf="IsControlInvalid(ReportFormGroup,'ddlFacility')">
            <small *ngIf="ReportFormGroup.get('ddlFacility').hasError('required')"
              class="invalid-feedback d-block">Facility is required</small>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="ddlReport" class="col-md-3 col-lg-2 col-form-label">Report<span class="text-danger">*</span></label>
      <div class="col-md-8 col-lg-6">
        <div class="d-flex flex-column w-100">
          <select class="form-control" id="ddlReport" formControlName="ddlReport" (change)="ddlReport_Change($event)"
            [ngClass]="SetClassForControl(ReportFormGroup,'ddlReport')">
            <option value="" selected>Select a report</option>
            <option *ngFor="let ri of ReportItems" [value]="ri.Id">
              {{ri.Name}}
            </option>
          </select>
          <div *ngIf="IsControlInvalid(ReportFormGroup,'ddlReport')">
            <small *ngIf="ReportFormGroup.get('ddlReport').hasError('required')" class="invalid-feedback d-block">Report
              is required</small>
          </div>
        </div>
      </div>
    </div>
    <!-- Filter Region -->
    <div class="form-group row">
      <label class="col-md-3 col-lg-2 col-form-label"></label>
      <div class="col-md-8 col-lg-8" style="width: 100%;">
        <div class="card" style="width: 100%; border: 0px;" *ngIf="IsFilter">
          <div class="card-body">
            <!-- Normal DDL filter -->
            <div class="form-group row" *ngIf="IsFilterNormalDLL">
              <label for="ddlFilter" class="col-md-3 col-lg-4 col-form-label">{{FilterLabel}}</label>
              <div class="col-md-8 col-lg-8">
                <div class="d-flex flex-column w-100">
                  <select class="form-control" id="ddlFilter" formControlName="ddlFilter">
                    <option value="" disable selected>Please select</option>
                    <option *ngFor="let fi of FilterItems" [value]="fi.Id">
                      {{fi.Name}}
                    </option>
                  </select>
                  <div *ngIf="IsControlInvalid(ReportFormGroup,'ddlFilter')">
                    <small *ngIf="ReportFormGroup.get('ddlFilter').hasError('required')" class="invalid-feedback d-block">Service is required</small>
                  </div>
                </div>
              </div>
            </div>
            <!-- Normal DDL filter -->
            <div class="form-group row" *ngIf="IsFilterNormalDLL2">
              <label for="ddlFilter2" class="col-md-3 col-lg-4 col-form-label">{{FilterLabel2}}</label>
              <div class="col-md-8 col-lg-8">
                <div class="d-flex flex-column w-100">
                  <select class="form-control" id="ddlFilter2" formControlName="ddlFilter2">
                    <option value="" disable selected>Please select</option>
                    <option *ngFor="let fi of FilterItems2" [value]="fi.Name">
                      {{fi.Name}}
                    </option>
                  </select>
                  <div *ngIf="IsControlInvalid(ReportFormGroup,'ddlFilter2')">
                    <small *ngIf="ReportFormGroup.get('ddlFilter2').hasError('required')" class="invalid-feedback d-block">Year is required</small>
                  </div>
                </div>
              </div>
            </div>
            <!-- Normal DDL filter -->
            <div class="form-group row" *ngIf="IsFilterNormalDLL2">
              <label for="ddlFilter3" class="col-md-3 col-lg-4 col-form-label">{{FilterLabel3}}</label>
              <div class="col-md-8 col-lg-8">
                <div class="d-flex flex-column w-100">
                  <select class="form-control" id="ddlFilter3" formControlName="ddlFilter3">
                    <option value="" disable selected>Please select</option>
                    <option *ngFor="let fi of FilterItems3" [value]="fi.Name">
                      {{fi.Name}}
                    </option>
                  </select>
                  <div *ngIf="IsControlInvalid(ReportFormGroup,'ddlFilter3')">
                    <small *ngIf="ReportFormGroup.get('ddlFilter3').hasError('required')" class="invalid-feedback d-block">Month is required</small>
                  </div>
                </div>
              </div>
            </div>
            <!-- End -->
            <!-- Date Time Filter -->
            <div class="form-group row" *ngIf="IsFilterDate">
              <label class="col-md-3 col-lg-4 col-form-label">Start Date</label>
              <div class="date-input-group col-md-8 col-lg-8">
                <input ngbDatepicker #dtpStartDate="ngbDatepicker" class="form-control" formControlName="dtpStartDate"
                  [minDate]="{year: 1900, month: 1, day: 1}" placeholder="DD-MM-YYYY">
                <div class="input-group-append">
                  <button class="btn bg-white" type="button" (click)="dtpStartDate.toggle()"><i
                      class="far fa-calendar-alt"></i></button>
                </div>
              </div>
            </div>
            <div class="form-group row" *ngIf="IsFilterDate">
              <label class="col-md-3 col-lg-4 col-form-label">End Date</label>
              <div class="date-input-group col-md-8 col-lg-8">
                <input ngbDatepicker #dtpEndDate="ngbDatepicker" class="form-control" formControlName="dtpEndDate"
                  [minDate]="{year: 1900, month: 1, day: 1}" placeholder="DD-MM-YYYY">
                <div class="input-group-append">
                  <button class="btn bg-white" type="button" (click)="dtpEndDate.toggle()"><i
                      class="far fa-calendar-alt"></i></button>
                </div>
              </div>
            </div>
            <!-- End -->
            <!-- Normal Text filter -->
            <div class="form-group row" *ngIf="IsFilterNormalTXT">
              <label for="txtFilter" class="col-md-3 col-lg-4 col-form-label">{{FilterLabel}}</label>
              <div class="col-md-8 col-lg-8">
                <div class="d-flex flex-column w-100">
                  <input class="form-control" type="text" id="txtFilter" formControlName="txtFilter"
                    [placeholder]="FilterLabel">
                </div>
              </div>
            </div>
            <!-- End -->
            <!-- Auto Complete Member ID/ Member Number -->
            <div class="form-group row" *ngIf="IsFilterAutoMember">
              <label for="searchMember" class="col-md-3 col-lg-4 col-form-label">Member Number or Member ID<span
                  class="text-danger">*</span></label>
              <div class="col-md-8 col-lg-8">
                <div class="d-flex flex-column w-100">
                  <div [ngClass]="SetClassForAutoFill(ReportFormGroup,'ddlMemberSearch')">
                    <input class="form-control" type="text" placeholder="Member Number or Member ID"
                      formControlName="ddlMemberSearch" matInput [matAutocomplete]="ddlSearchMember"
                      name="searchMember">
                    <mat-autocomplete #ddlSearchMember="matAutocomplete" [displayWith]="ddlSearchMember_DisplayWithFn"
                      (optionSelected)="ddlSearchMemberAutoComplete_Selected($event.option.value);">
                      <mat-option *ngFor="let member of memberSearchResults" [value]="member">
                        <p class="d-flex align-items-center m-0 ml-2 w-100">
                          <span>{{member.LastName}}, {{member.FirstName}}</span></p>
                      </mat-option>
                    </mat-autocomplete>
                    <div class="input-group-append">
                      <button *ngIf="!isSearchingMember" class="input-group-text" type="submit">
                        <i class="fas fa-search text-primary"></i>
                      </button>
                      <mat-spinner *ngIf="isSearchingMember" [diameter]="14"></mat-spinner>
                    </div>
                  </div>
                  <div *ngIf="IsControlInvalid(ReportFormGroup,'ddlMemberSearch')">
                    <small *ngIf="ReportFormGroup.get('ddlMemberSearch').hasError('required')"
                      class="invalid-feedback d-block">Member is required</small>
                    <small
                      *ngIf="ReportFormGroup.get('ddlMemberSearch').hasError('unresolved') && memberSearchResults.length == 0"
                      class="invalid-feedback d-block">The Member does not exist</small>
                  </div>
                </div>
              </div>
            </div>
            <!-- End -->
          </div>
        </div>
      </div>
    </div>

    <!-- End -->
    <div class="form-group row mt-4">
      <div class=" col-md-8 col-lg-5 offset-md-3 offset-lg-2" *ngIf="!IsCSVOnly">
        <button id="btnGenerateReport" class="btn btn-primary" style="width: 16em" type="button"
          (click)="btnGenerateReport_Click()">
          <i class="fas fa-file-invoice mr-2"></i>
          Generate Report</button>
        <button id="btnExportExcel" class="btn btn-primary" style="width: 16em; margin-left: 16px;" type="button"
          (click)="btnExportExcel_Click()" *ngIf="(IsShowingExport && displayedColumns.length > 0)">
          <i class="fas fa-file-invoice mr-2"></i>
          Export Report</button>
      </div>
      <div class=" col-md-8 col-lg-5 offset-md-3 offset-lg-2" *ngIf="IsCSVOnly">
        <button id="generateAndExport" class="btn btn-primary" style="width: 16em" type="button"
          (click)="btnGenerateReport_Click()">
          <i class="fas fa-file-invoice mr-2"></i>
          Export Report</button>
      </div>
    </div>
    <!-- Table -->
    <div *ngIf="!IsCSVOnly">
      <!-- Table first -->
      <h5 *ngIf="IsShowingHeaderTable1">{{Header1}}</h5>
      <div class="border-default rounded-10 border-2" [hidden]="!(displayedColumns.length > 0)">
        <div class="tablecontainer">
          <table mat-table [dataSource]="dataSource" #firstSort="matSort" matSort>
            <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{replace(column)}}</th>
              <td mat-cell *matCellDef="let element">
                <a *ngIf="column.indexOf('FileName')>-1" [href]="element['ReportURL']" target="_blank">
                  {{element[column]}}
                </a>
                <span *ngIf="column.indexOf('FileName')==-1">
                  {{element[column]}}
                </span>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator #rppagi [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons>
        </mat-paginator>
      </div>
      <mat-toolbar *ngIf="IsNoDataFirstTable" class="text-muted d-block p-2 bg-light text-center">
        {{noRecordFoundText}}</mat-toolbar>
      <!-- End -->
      <!-- Table Second -->
      <h5 *ngIf="IsShowingHeaderTable2" style="margin-top: 20px;">{{Header2}}</h5>
      <div class="border-default rounded-10 border-2" [hidden]="!(seconddisplayedColumns.length > 0)">
        <div class="tablecontainer">
          <table mat-table [dataSource]="seconddataSource" #secondSort="matSort" matSort>
            <ng-container *ngFor="let column of seconddisplayedColumns" [matColumnDef]="column">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</th>
              <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="seconddisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: seconddisplayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator #rpsecondpagi [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons>
        </mat-paginator>
      </div>
      <mat-toolbar *ngIf="IsNoDataSecondTable" class="text-muted d-block p-2 bg-light text-center">
        {{noRecordFoundText}}</mat-toolbar>
      <!-- End -->
      <!-- Table Server Paging -->
      <div class="border-default rounded-10 border-2"
        *ngIf="IsShowingServerDataPaging && serverdisplayedColumns.length > 0">
        <div class="tablecontainer">
          <table mat-table [dataSource]="serverpagingSource" matSort (matSortChange)="sortChange($event)">
            <ng-container *ngFor="let column of serverdisplayedColumns" [matColumnDef]="column">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 200px !important;">{{replace(column)}}
              </th>
              <td mat-cell *matCellDef="let element" style="width: 200px !important;">
                {{element[column]}}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="serverdisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: serverdisplayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
          (page)="paginatorChange($event)" showFirstLastButtons></mat-paginator>
      </div>
      <mat-toolbar *ngIf="IsNoDataServerTable" class="text-muted d-block p-2 bg-light text-center">
        {{noRecordFoundText}}</mat-toolbar>
      <!-- End -->
      <ng-container *ngIf="listReportData.length > 0">
        <ng-container *ngFor="let dataObject of listReportData">
          <div class="form-group row mb-4" *ngFor="let field of dataObject | keyvalue: sortNull">
            <div class="col-md-8 col-lg-6"><strong>{{ formatObjectKeyToLabel(field.key + ':') }}</strong></div>
            <div class="col-md-4 col-lg-4">{{field.value}}</div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <!-- End all table -->
  </form>
</div>