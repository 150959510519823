import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-signup-marketing-disclaimer',
  templateUrl: './signup-marketing-disclaimer.component.html',
  styleUrls: ['./signup-marketing-disclaimer.component.css']
})
export class SignupMarketingDisclaimerComponent {
  @Input() clickActionText: string = 'Create Account'

  constructor() { }
}
