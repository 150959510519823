import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpDAO } from "./httpdao.service";
import { CommonService } from "src/app/services/common.service";
import { APIConstant } from "src/app/constant/apiconstant";
import { Observable } from "rxjs";
import * as Models from "src/app/models";

@Injectable({
  providedIn: "root",
})
export class MemberSignupService {
  private httpDao: HttpDAO | null;

  constructor(httpClient: HttpClient, private commonService: CommonService) {
    this.httpDao = new HttpDAO(httpClient);
  }

  checkPhoneEmail(request: Models.PinModel): any {
    const encrypteddata = this.commonService.E_FP_RequestData(
      JSON.stringify(request)
    );
    return this.httpDao.postDataWithoutToken(
      APIConstant.API_CHECK_PHONE_EMAIL,
      { Data: encrypteddata }
    );
  }

  sendPinCode(mail: Models.PinModel): Observable<Models.PinModel> {
    const encrypteddata = this.commonService.E_FP_RequestData(
      JSON.stringify(mail)
    );
    return this.httpDao.postDataWithoutToken(
      APIConstant.API_SEND_PIN_BY_EMAIL,
      { Data: encrypteddata }
    );
  }

  resendPinCode(mail: Models.PinModel): Observable<Models.PinModel> {
    return this.httpDao.postDataWithoutToken(
      APIConstant.API_RESEND_PIN_BY_EMAIL,
      mail
    );
  }

  checkPinCode(
    pinmodel: Models.PinModel
  ): Observable<Models.DataResult<Models.PinModel>> {
    const encrypteddata = this.commonService.E_FP_RequestData(
      JSON.stringify(pinmodel)
    );
    return this.httpDao.postDataWithoutToken(APIConstant.API_CHECK_PIN_CODE, {
      Data: encrypteddata,
    });
  }

  createMember(
    membermodel: Models.MemberModel
  ): Observable<Models.DataResult<Models.UserModel>> {
    const encrypteddata = this.commonService.E_FP_RequestData(
      JSON.stringify(membermodel)
    );
    return this.httpDao.postDataWithoutToken(APIConstant.API_CREATE_MEMBER, {
      Data: encrypteddata,
    });
  }

  createSurveyMember(
    membermodel: Models.SurveyMemberModel
  ): Observable<Models.DataResult<Models.UserModel>> {
    const encrypteddata = this.commonService.E_FP_RequestData(
      JSON.stringify(membermodel)
    );
    return this.httpDao.postDataWithoutToken(APIConstant.API_CREATE_SURVEY_MEMBER, {
      Data: encrypteddata,
    });
  }

  deletePinCode(name: string): Observable<Models.DataResult<Models.PinModel>> {
    return this.httpDao.getSingleDataWithoutToken(
      APIConstant.API_DELETE_PIN_BY_EMAIL + encodeURIComponent(name)
    );
  }
}
