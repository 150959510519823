<div [formGroup]="form">
    <ng-template #requiredFieldMsgTmpl let-ctrl="control" let-ctrlName="fieldName"
        let-validateOnTouch="validateOnTouch || false">
        <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
            <small *ngIf="ctrl.errors['required']" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_REQUIRED, ctrlName)}}</small>
        </div>
    </ng-template>
    <div class="form-group row">
        <label for="FinanceSID" class="col-md-3 col-lg-2 col-form-label">Finance System ID</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="FinanceSID" maxlength="200"
                    formControlName="FinanceSystemId" [class.is-invalid]="!isControlValid(form.get('FinanceSystemId'))">
                <ng-container
                    *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('FinanceSystemId'), fieldName: 'Finance System ID'}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlPayMethod" class="col-md-3 col-lg-2 col-form-label">Payment Method<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" id="ddlPayMethod" formControlName="PaymentMethodId"
                    [class.is-invalid]="!isControlValid(form.get('PaymentMethodId'), true)">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let method of methods" [ngValue]="method.PaymentMethodId"
                        [selected]="form.get('PaymentMethodId').value == method.PaymentMethodId">{{method.Name}}
                    </option>
                </select>
                <ng-container
                    *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('PaymentMethodId'), fieldName: 'Payment Method', validateOnTouch: true}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlPayFreqcy" class="col-md-3 col-lg-2 col-form-label">Payment Frequency<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" id="ddlPayFreqcy" formControlName="PaymentFrequencyId"
                    [class.is-invalid]="!isControlValid(form.get('PaymentFrequencyId'), true)"
                    (change)="ddlPayFreqcy_Change($event)">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let frequency of frequencies" [ngValue]="frequency.PaymentFrequencyId"
                        [selected]="form.get('PaymentFrequencyId').value == frequency.PaymentFrequencyId">
                        {{frequency.PaymentFrequencyName}}</option>
                </select>
                <ng-container
                    *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('PaymentFrequencyId'), fieldName: 'Payment Frequency', validateOnTouch: true}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlPayPref" class="col-md-3 col-lg-2 col-form-label">Payment Preference<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" id="ddlPayPref" formControlName="PaymentPreferenceId"
                    [class.is-invalid]="!isControlValid(form.get('PaymentPreferenceId'), true)">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let preference of preferences" [ngValue]="preference.PaymentPreferenceId"
                        [selected]="form.get('PaymentPreferenceId').value == preference.PaymentPreferenceId">
                        {{preference.Name}}</option>
                </select>
                <ng-container
                    *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('PaymentPreferenceId'), fieldName: 'Payment Preference', validateOnTouch: true}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlPayDate" class="col-md-3 col-lg-2 col-form-label">Payment Date<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" id="ddlPayDate" formControlName="PaymentDateId"
                    [class.is-invalid]="!isControlValid(form.get('PaymentDateId'), true)">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let date of dates" [ngValue]="date.PaymentDateId"
                        [selected]="form.get('PaymentDateId').value == date.PaymentDateId">{{date.Name}}</option>
                </select>
                <ng-container
                    *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('PaymentDateId'), fieldName: 'Payment Date', validateOnTouch: true}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row" style="display: none">
        <div class="col-md-8 col-lg-5 offset-md-3 offset-lg-2">
            <div class="d-flex flex-column w-100">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="chkAllowFacility"
                        formControlName="IsAllowToSeePaymentCalculation">
                    <label class="custom-control-label" for="chkAllowFacility">Allow facility to see payment
                        calculation</label>
                </div>
            </div>
        </div>
    </div>
</div>