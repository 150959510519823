import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MapFacilityData } from "@fp/models/map-facility-data";
import { CommonConstants } from "@fp/constant/common-constants";
import { ActivityConstant } from "@fp/constant/activity-constants";

@Component({
  selector: "app-filter-search-result-popup",
  templateUrl: "./filter-search-result-popup.component.html",
  styleUrls: ["./filter-search-result-popup.component.css"],
})
export class FilterSearchResultPopupComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  public filteredDataSource = this.data.filteredDataSource;
  listLocationFilter = this.data.listLocationFilter;
  checkedName = this.data.checkedName;
  disableSearchFacByName = this.data.disableSearchFacByName;
  isChecked = this.data.isChecked;

  public combinedArray = [];

  public activityList = [];

  public services = CommonConstants.GET_MAP_ACTIVITIES;

  ngOnInit(): void { }

  searchBarFunctionality() {
    this.checkedName = "";
    this.combinedArray = [];
    if (this.listLocationFilter !== "") {
      this.listLocationFilter = this.listLocationFilter.trim();
      if (this.listLocationFilter !== "") {
        this.disableSearchFacByName = true;
      }
      this.filteredDataSource = this.data.dataSource.filter(
        (facility: MapFacilityData) =>
          facility.Name.toLocaleLowerCase().indexOf(
            this.listLocationFilter.toLocaleLowerCase()
          ) !== -1
      );

      var object = {
        resultMap: this.filteredDataSource,
        resultChecked: this.checkedName,
      };

      this.combinedArray.push(object);
    } else {
      this.disableSearchFacByName = false;
    }
  }

  checkBoxFunctionality(e) {
    this.combinedArray = [];
    this.activityList = [];
    this.isChecked = !this.isChecked;

    if(e.target.name.toLowerCase() == ActivityConstant.ACCESSIBLE_OPTIONS.toLowerCase()){
      e.target.name = ActivityConstant.FACILITY_ACCESSIBLE_OPTIONS
    }
    this.checkedName = e.target.name;

    var removeEmptyArray = this.data.dataSource.filter(
      (facility: MapFacilityData) => facility.Activities.length !== 0
    );

    for (var i = 0; i < removeEmptyArray.length; i++) {
      for (var j = 0; j < removeEmptyArray[i].Activities.length; j++) {
        if (
          removeEmptyArray[i].Activities[j].ActivityName.toLocaleLowerCase() ===
          this.checkedName.toLocaleLowerCase()
        ) {
          this.activityList.push(removeEmptyArray[i]);
          break;
        }
      }
    }

    this.filteredDataSource = this.activityList;

    var object = {
      resultMap: this.filteredDataSource,
      resultChecked: this.checkedName,
    };

    this.combinedArray.push(object);
  }
}
