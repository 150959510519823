<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">View/Edit Facility</h4>
<div class="mt-lg-4">
    <div class="form-group row">
        <label for="searchName" class="col-md-3 col-lg-2 col-form-label">Search Facility</label>
        <div class="col-md-8 col-lg-5">
            <div class="input-group" [ngClass]="getClassFocused(1)">
                <input class="form-control" type="text" [(ngModel)]="facilityName"
                    placeholder="Search facility by facility name, facility code, postcode" (blur)="onBlurParent(0)"
                    (focus)="onFocusParent(1)" (keydown.enter)="findFacilities()">
            </div>
        </div>
    </div>
    <form [formGroup]="searchFacilityFG" (ngSubmit)="findFacilities()">
        <div class="form-group row">
            <label for="slctViewFac" class="col-md-3 col-lg-2 col-form-label">View Facilities by</label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <select class="form-control" id="slctViewFac" formControlName="filter"
                        (ngModelChange)="changeFilter($event)">
                        <option [value]="0">View All</option>
                        <option [value]="1">Region</option>
                        <option [value]="2">Suburb/postcode</option>
                        <option [value]="3">Service Type</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group row" [hidden]="!(searchFacilityFG.value.filter == 1)">
            <label for="slctRegion" class="col-md-3 col-lg-2 col-form-label">Region</label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <div class="input-group" [ngClass]="getClassFocused(2)">
                        <input class="form-control" id="slctRegion" type="text" formControlName="region_id"
                            placeholder="Search region by region name" [matAutocomplete]="regionAutocomplete"
                            (blur)="onBlurParent(0)" (focus)="onFocusParent(2)">
                        <div class="input-group-append">
                            <button class="input-group-text" type="submit" [disabled]="searchFacilityFG.invalid">
                                <i class="fas fa-search text-primary" *ngIf="!filterSpinner"></i>
                                <mat-spinner [diameter]="14" *ngIf="filterSpinner"></mat-spinner>
                            </button>
                        </div>
                    </div>
                    <mat-autocomplete class="height1 fpscrolly border-2 rounded-10 border-default"
                        #regionAutocomplete="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let r of regions" [value]="r">
                            <p class="d-flex align-items-center m-0 ml-2 w-100">
                                <span>{{r.name}}</span></p>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
        </div>
        <div class="form-group row" [hidden]="!(searchFacilityFG.value.filter == 2)">
            <label for="slctSuburb" class="col-md-3 col-lg-2 col-form-label">Suburb/postcode</label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <div class="input-group" [ngClass]="getClassFocused(3)">
                        <input maxlength="50" class="form-control" id="slctSuburb" type="text"
                            formControlName="suburb_id" placeholder="Search Suburb/postcode by Suburb or post code"
                            [matAutocomplete]="suburbAutocomplete" (blur)="onBlurParent(0)" (focus)="onFocusParent(3)">
                        <div class="input-group-append">
                            <button class="input-group-text" type="submit" [disabled]="searchFacilityFG.invalid">
                                <i class="fas fa-search text-primary" *ngIf="!filterSpinner"></i>
                                <mat-spinner [diameter]="14" *ngIf="filterSpinner"></mat-spinner>
                            </button>
                        </div>
                    </div>
                    <mat-autocomplete class="height1 fpscrolly border-2 rounded-10 border-default"
                        #suburbAutocomplete="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let s of suburbs" [value]="s">
                            <p class="d-flex align-items-center m-0 ml-2 w-100">
                                <span>{{s.suggestionText}}</span></p>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
        </div>
        <div class="form-group row" [hidden]="!(searchFacilityFG.value.filter == 3)">
            <label for="slctSer" class="col-md-3 col-lg-2 col-form-label">Service Type</label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <select class="form-control" id="slctViewFac" formControlName="service_type_id">
                        <option [ngValue]="0">Please select a value</option>
                        <option *ngFor="let st of serviceTypes" [ngValue]="st">{{ st.name }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-8 col-lg-5 offset-sm-2">
                <button class="btn btn-primary px-5 text-white" type="submit"
                    [disabled]="searchFacilityFG.invalid">SEARCH</button>
            </div>
        </div>
    </form>
</div>
