import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AddFamilyMemberPopupComponent } from "@fp/add-family-member-popup/add-family-member-popup.component";
import { RouterConstant } from "@fp/constant";
import * as enums from "src/app/enums";
import { CommonConstants } from "@fp/constant/common-constants";
import { EMemberStatus, MembershipPackageType } from "@fp/enums";
import { FPValidators, Utils } from "@fp/helpers";
import {
  DataResult,
  Facility,
  Membership,
  MembershipPackage,
  ResultModel,
  SearchPaginationRequest,
  SearchPaginationResult,
  Member,
} from "@fp/models";
import {
  FacilityService,
  MemberService,
  MembershipPackagesService,
  MessageBox,
} from "@fp/services";
import { empty } from "rxjs";
import { catchError, debounceTime, switchMap } from "rxjs/operators";
import { MemberFormBase } from "../base/member-form-base";
import { DialogResult } from "../common-dialog-new/common-dialog-new.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "select-package-page-component",
  templateUrl: "./select-package-page-component.component.html",
  styleUrls: ["./select-package-page-component.component.css"],
  providers: [
    {
      provide: MemberFormBase,
      useExisting: SelectPackagePageComponentComponent,
    },
  ],
})
export class SelectPackagePageComponentComponent extends MemberFormBase {
  SelectPackageFormGroup: UntypedFormGroup;
  selectedPackage: MembershipPackage;
  MembershipStatusId: number;
  panelOpenState = false;
  isMemberHasExistingFacility = false;
  MembershipPackageType = MembershipPackageType;
  openPackage = 0;
  isEvenNumberOfPackages = false;
  CanNaviagateToNext = false;
  dataSource: MembershipPackage[];
  loadCompleted: boolean = false;
  favFacilitySearch: Facility[] = [];
  Utils = Utils;
  @Output() isCompleteForm: EventEmitter<any> = new EventEmitter();
  favFacilityIsLoading: boolean;
  existFacilityIsLoading: boolean;
  facilityList: Facility[];
  existFacilitySearch: Facility[] = [];
  favFacilitySelected: Facility;
  parentFocus = 0;
  membershipPackageId: string;
  timeStamp = new Date().toISOString().replace(/[-:\.]/g, "");
  isOtherDacilityDisable: boolean;
  existFacilitySelected: Facility;
  memberDataSource: Member[] = [];
  isFormDisabled: boolean = false;
  @ViewChild('nxtButtonRef') nxtButtonRef: ElementRef;

  get FavouredFacilityCtrl() {
    return this.SelectPackageFormGroup.get("FavouredFacility");
  }

  get ExistingFacilityCtrl() {
    return this.SelectPackageFormGroup.get("ExistingFacility");
  }

  constructor(
    injector: Injector,
    private memberPackageSvc: MembershipPackagesService,
    private router: Router,
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private memberSvc: MemberService,
    private facilitySvc: FacilityService,
    private modalService: NgbModal
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.SelectPackageFormGroup = this._formBuilder.group({
      FavouredFacilityId: [null],
      FavouredFacilityOther: [null],
      FavouredFacility: [null],
      FavouriteFacilityPersonalTrainingStatus: ["", Validators.required],
      IsExistingMember: [false, Validators.required],
      ExistingMemberFacilityId: [0],
      ExistingFacilityOther: [
        { value: null, disabled: true },
        CustomFacilityValidators.ExistingFacilityOtherRequiredWhen,
      ],
      ExistingFacility: [
        { value: null, disabled: true },
        [
          FPValidators.resolvableAutocompleteOption,
          CustomFacilityValidators.ExistingFacilityRequiredWhen,
        ],
      ],
      Memberships: [[], [Validators.required, Validators.minLength(1)]],
    });
  }

  ngAfterViewInit() {
    this.OnLoad();
    this.initValidation();
    this.isOtherDacilityDisable = true;
    this.SelectPackageFormGroup.get("IsExistingMember").setValue(false);
  }

  LoadComplete() {
    super.LoadComplete();

    setTimeout(() => {
      this.FavouredFacilityCtrl.valueChanges
        .pipe(
          debounceTime(500),
          switchMap((value) => {
            this.SelectPackageFormGroup.get(
              "FavouredFacilityOther"
            ).updateValueAndValidity({ emitEvent: false });
            if (typeof value === "string") {
              const searchText = value;
              if (searchText.length > 2) {
                this.favFacilityIsLoading = true;
                return value;
              } else {
                this.favFacilityIsLoading = false;
              }
            }
            return empty();
          }),
          catchError((e) => {
            throw e;
          })
        )
        .subscribe((result: string) => {
          this.favFacilityIsLoading = false;
          let filterList = this.facilityList.filter((x) =>
            x.Name.toLowerCase()
              .trim()
              .includes(this.FavouredFacilityCtrl.value.toLowerCase().trim())
          );
          this.favFacilitySearch = filterList;
          this.changeDetectorRef.detectChanges();
        });

      this.SelectPackageFormGroup.get(
        "FavouredFacilityOther"
      ).valueChanges.subscribe((value) => {
        this.FavouredFacilityCtrl.updateValueAndValidity({ emitEvent: false });
      });

      this.ExistingFacilityCtrl.valueChanges
        .pipe(
          debounceTime(500),
          switchMap((value) => {
            this.SelectPackageFormGroup.get(
              "ExistingFacilityOther"
            ).updateValueAndValidity({ emitEvent: false });
            if (typeof value === "string") {
              const searchText = value;
              if (searchText.length > 2) {
                this.existFacilityIsLoading = true;
                return value;
              } else {
                this.existFacilityIsLoading = false;
              }
            }
            return empty();
          }),
          catchError((e) => {
            throw e;
          })
        )
        .subscribe((result: string) => {
          this.existFacilityIsLoading = false;
          let filterList = this.facilityList.filter((x) =>
            x.Name.toLowerCase()
              .trim()
              .includes(this.ExistingFacilityCtrl.value.toLowerCase().trim())
          );
          this.existFacilitySearch = filterList;
          this.changeDetectorRef.detectChanges();
        });

      this.SelectPackageFormGroup.get(
        "ExistingFacilityOther"
      ).valueChanges.subscribe((value) => {
        this.ExistingFacilityCtrl.updateValueAndValidity({ emitEvent: false });
      });

      this.SelectPackageFormGroup.get(
        "IsExistingMember"
      ).valueChanges.subscribe((value: boolean) => {
        if (value) {
          this.SelectPackageFormGroup.get("ExistingFacility").enable({
            emitEvent: false,
          });
          this.SelectPackageFormGroup.get("ExistingFacilityOther").enable({
            emitEvent: false,
          });
          this.isOtherDacilityDisable = false;
        } else {
          this.SelectPackageFormGroup.get("ExistingFacility").disable({
            emitEvent: false,
          });
          this.SelectPackageFormGroup.get("ExistingFacility").setValue(null, {
            emitEvent: false,
          });
          this.SelectPackageFormGroup.get("ExistingFacilityOther").disable({
            emitEvent: false,
          });
          this.SelectPackageFormGroup.get("ExistingFacilityOther").setValue(
            null,
            { emitEvent: false }
          );
          this.isOtherDacilityDisable = true;
        }
      });
    });
  }

  txtFavouredFacility_Input(e) {
    if (this.FavouredFacilityCtrl.dirty) {
      if (
        typeof this.FavouredFacilityCtrl.value === "string" &&
        this.FavouredFacilityCtrl.value.length == 0 &&
        this.favFacilitySelected != null
      ) {
        this.SelectedFavFacility(null);
      }
      this.FavouredFacilityCtrl.setValidators(
        CustomFacilityValidators.FavouredFacilityRequiredWhen
      );
    }
  }

  initValidation() {
    //init
    this.FavouredFacilityCtrl.setValidators([
      FPValidators.resolvableAutocompleteOption,
      CustomFacilityValidators.FavouredFacilityRequiredWhen,
    ]);
    this.FavouredFacilityCtrl.updateValueAndValidity({ emitEvent: false });
    this.SelectPackageFormGroup.get("FavouredFacilityOther").setValidators(
      CustomFacilityValidators.FavouredFacilityOtherRequiredWhen
    );
    this.SelectPackageFormGroup.get(
      "FavouredFacilityOther"
    ).updateValueAndValidity({ emitEvent: false });

    this.ExistingFacilityCtrl.setValidators([
      FPValidators.resolvableAutocompleteOption,
      CustomFacilityValidators.ExistingFacilityRequiredWhen,
    ]);
    this.ExistingFacilityCtrl.updateValueAndValidity({ emitEvent: false });
    this.SelectPackageFormGroup.get("ExistingFacilityOther").setValidators(
      CustomFacilityValidators.ExistingFacilityOtherRequiredWhen
    );
    this.SelectPackageFormGroup.get(
      "ExistingFacilityOther"
    ).updateValueAndValidity({ emitEvent: false });

    this.SelectPackageFormGroup.get(
      "ExistingFacilityOther"
    ).updateValueAndValidity({ emitEvent: false });

    if (this.SelectPackageFormGroup.get("IsExistingMember").value == true) {
      this.SelectPackageFormGroup.get("ExistingFacility").enable({
        emitEvent: false,
      });
      this.SelectPackageFormGroup.get("ExistingFacilityOther").enable({
        emitEvent: false,
      });
    }

    this.ExistingFacilityCtrl.setValidators([
      FPValidators.resolvableAutocompleteOption,
      CustomFacilityValidators.ExistingFacilityRequiredWhen,
    ]);
    this.ExistingFacilityCtrl.updateValueAndValidity({ emitEvent: false });
    this.SelectPackageFormGroup.get("ExistingFacilityOther").setValidators(
      CustomFacilityValidators.ExistingFacilityOtherRequiredWhen
    );
    this.SelectPackageFormGroup.get(
      "ExistingFacilityOther"
    ).updateValueAndValidity({ emitEvent: false });
  }

  txtFavouredFacility_Blur(e) {
    if (this.FavouredFacilityCtrl.dirty) {
      this.FavouredFacilityCtrl.setValidators([
        FPValidators.resolvableAutocompleteOption,
        CustomFacilityValidators.FavouredFacilityRequiredWhen,
      ]);
      this.FavouredFacilityCtrl.updateValueAndValidity({ emitEvent: false });
    }
  }

  setIsExistingMember(isExistingMemberFacility: boolean) {
    this.SelectPackageFormGroup.get("IsExistingMember").setValue(
      isExistingMemberFacility
    );
  }

  SelectedFavFacility(facility: Facility) {
    this.favFacilitySelected = facility;
    this.SelectPackageFormGroup.get("FavouredFacilityId").setValue(
      facility ? facility.FacilityId : null
    );
    this.SelectPackageFormGroup.get("FavouredFacility").setValue(facility, {
      emitEvent: false,
    });
    // Clear current search results
    this.favFacilitySearch = [];
  }

  displayAutoText(facility: Facility) {
    if (!facility) {
      return "";
    }
    return facility.Name || facility.Code;
  }

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }

  getFacilities(parameterVal: string) {
    this.favFacilityIsLoading = true;
    const searchRequest: SearchPaginationRequest = {
      OrderBy: "Name",
      OrderByAsc: true,
      PageNumber: 0,
      PageSize: 0,
      Params: [
        {
          Name: "preferred_facility",
          Value: parameterVal,
        },
      ],
      ViewColumns: ["name"],
    };
    this.facilitySvc.search(searchRequest).subscribe(
      (result: string) => {
        const dectypted_data = this.commonSvc.D_FP_ResponseData(result);
        const rdata = JSON.parse(dectypted_data);
        this.favFacilityIsLoading = false;
        if (rdata.Success) {
          if (rdata.Data != null) {
            this.facilityList = rdata.Data.Results;
          }
        } else {
          MessageBox.ShowError(
            this.dialog,
            "Sorry, something went wrong. Let's try that again."
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              window.location.reload();
            }
          });
          this._logger.error(rdata);
        }
      },
      (error) => {
        this.favFacilityIsLoading = false;
        MessageBox.ShowError(
          this.dialog,
          "Sorry, something went wrong. Let's try that again."
        ).subscribe((res) => {
          if (res.result.toLowerCase() === DialogResult.Ok) {
            window.location.reload();
          }
        });
        this._logger.error(error);
      }
    );
  }

  txtExistingFacility_Input(e) {
    if (this.ExistingFacilityCtrl.dirty) {
      if (
        typeof this.ExistingFacilityCtrl.value === "string" &&
        this.ExistingFacilityCtrl.value.length == 0 &&
        this.existFacilitySelected != null
      ) {
        this.SelectedExistingFacility(null);
      }
      this.ExistingFacilityCtrl.setValidators(
        CustomFacilityValidators.ExistingFacilityRequiredWhen
      );
    }
  }

  SelectedExistingFacility(facility: Facility) {
    this.existFacilitySelected = facility;
    this.SelectPackageFormGroup.get("ExistingMemberFacilityId").setValue(
      facility ? facility.FacilityId : null
    );
    this.SelectPackageFormGroup.get("ExistingFacility").setValue(facility, {
      emitEvent: false,
    });
    // Clear current search results
    this.existFacilitySearch = [];
  }

  txtExistingFacility_Blur(e) {
    if (this.ExistingFacilityCtrl.dirty) {
      this.ExistingFacilityCtrl.setValidators([
        FPValidators.resolvableAutocompleteOption,
        CustomFacilityValidators.ExistingFacilityRequiredWhen,
      ]);
      this.ExistingFacilityCtrl.updateValueAndValidity({ emitEvent: false });
    }
    this.parentFocus = 0;
  }

  SubmitPackageDetails() {
    if (this.SelectPackageFormGroup.valid && this.CanNaviagateToNext) {
     
      this.data.model.Memberships =
        this.SelectPackageFormGroup.get("Memberships").value;
      this.data.model.IsExistingMember =
        this.SelectPackageFormGroup.get("IsExistingMember").value;
      this.data.model.FavouredFacility =
        this.SelectPackageFormGroup.get("FavouredFacility").value;
      this.data.model.ExistingFacility =
        this.SelectPackageFormGroup.get("ExistingFacility").value;
      this.data.model.FavouriteFacilityPersonalTrainingStatus =
        Number(this.SelectPackageFormGroup.get("FavouriteFacilityPersonalTrainingStatus").value);
      this.data.model.ExistingFacilityOther = this.SelectPackageFormGroup.get(
        "ExistingFacilityOther"
      ).value;    

      this.Invoke(this.memberSvc.SaveMemberPackageDetail(this.data.model), {
        onSuccess: (res: ResultModel) => {
          if (res.Success) {
            this.data.model.SignupStep = enums.MemberSignupStep.PackageDetails;
            this.isCompleteForm.emit(CommonConstants.SIGNUP_SELECT_PACKAGE);
          } else {
            const supportLink =
              '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
            MessageBox.ShowOKCustom(
              this.dialog,
              "Error",
              "Sorry, we're unable to complete the registration. \
                          Please try again. If you continue to experience problems, send us an enquiry \
                          via our support site " +
              supportLink,
              "Take me back"
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
              }
            });
            this._logger.error(res);
          }
        },
        onError: (err) => {
          const supportLink =
            '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
          MessageBox.ShowOKCustom(
            this.dialog,
            "Error",
            "Sorry, we're unable to complete the registration. \
                      Please try again. If you continue to experience problems, send us an enquiry \
                      via our support site " +
            supportLink,
            "Take me back"
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
            }
          });
          this._logger.error(err);
        },
      });
    }
  }

  RefreshMembershipPackages(firstLoad = false) {
    var currentMembership: Membership = null;
    if (
      this.SelectPackageFormGroup.get("Memberships").value &&
      this.SelectPackageFormGroup.get("Memberships").value.length > 0
    ) {
      currentMembership =
        this.SelectPackageFormGroup.get("Memberships").value[0];
    }

    if (!this.data.model.EmployerId) {
      if (firstLoad) {
        super.OnLoad();
      }
      return;
    }

    const params = this.getSearchParams();
    this.dataSource = [];
    this.Invoke(this.memberPackageSvc.search(params), {
      onSuccess: (
        res: DataResult<SearchPaginationResult<MembershipPackage>>
      ) => {
        if (res.Success) {
          if (res.Data.Results.length % 2 == 0) {
            this.isEvenNumberOfPackages = true;
          } else {
            this.isEvenNumberOfPackages = false;
          }

          this.dataSource = res.Data.Results;
          if (currentMembership) {
            if (
              currentMembership.MembershipPackage.EmployerId ===
              this.data.model.EmployerId
            ) {
              this.SelectMembershipPackage(
                res.Data.Results.find(
                  (e) =>
                    e.MembershipPackageId ===
                    currentMembership.MembershipPackageId
                )
              );
            } else {
              this.SetDefaultPackage(res);
            }
          } else {
            this.SetDefaultPackage(res);
          }
        } else {
          this.HandleResponseError(res);
        }
        if (firstLoad) {
          super.OnLoad();
        }
      },
      onComplete: () => {
        this.loadCompleted = true;
        if (
          !this.data.model.MemberIdPrimary &&
          (!this.dataSource || this.dataSource.length === 0)
        ) {
          const supportLink =
            '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
          MessageBox.ShowOKCustom(
            this.dialog,
            "Error",
            "Your employer doesn't have any membership\
           packages to choose from yet. Please contact your Workplace Facilitator or send\
           us an enquiry via our support site " +
            supportLink,
            "OK"
          );
        }
      },
      onError: (err) => {
        MessageBox.ShowError(
          this.dialog,
          "Sorry, we're having trouble connecting. Let's try that again."
        ).subscribe((res) => {
          if (res.result.toLowerCase() === DialogResult.Ok) {
            window.location.reload();
          }
        });
        this._logger.error(err);
      },
    });
  }

  SetDefaultPackage(
    res: DataResult<SearchPaginationResult<MembershipPackage>>
  ) {
    if (res.Data.Results.length > 0) {
      this.SelectMembershipPackage(res.Data.Results.find((e) => e.IsDefault));
    }
  }

  SelectMembershipPackage(
    membershipPackage: MembershipPackage,
    callback?: Function
  ) {
    if (!this.isFormDisabled) {
      this.selectedPackage = membershipPackage;
      this.ToggleSelectedPackage();
      const memberships = [];
      const membership = memberships[0] || <Membership>{};
      if (this.MembershipStatusId) {
        membership.MembershipStatusId = this.MembershipStatusId;
      }
      if (membershipPackage) {
        try {
          membership.MembershipPackageId =
            membershipPackage.MembershipPackageId;
          membership.MembershipPackage = membershipPackage;
          memberships[0] = membership;
          this.membershipPackageId = membership.MembershipPackageId;
          this.SelectPackageFormGroup.controls.Memberships.setValue(
            memberships
          );
          var parameterVal = "signUp-" + this.membershipPackageId + "-" + "";
          this.getFacilities(parameterVal);
          this.data.model.Memberships = memberships;
          if (typeof callback === "function") {
            callback();
          }
        } catch (err) {
          MessageBox.ShowError(
            this.dialog,
            "Sorry, we're having trouble connecting. Let's try that again."
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              window.location.reload();
            }
          });
          this._logger.error(err);
        }
      }
    }
    //this.packageSelect.emit(membershipPackage);
  }

  ToggleSelectedPackage() {
    if (this.selectedPackage) {

      this.dataSource.forEach(item => {
        if (item.MembershipPackageId == this.selectedPackage.MembershipPackageId) {
          item.IsSelected = true;
        }
        else {
          item.IsSelected = false;
        }
      });
    }
  }

  private getSearchParams() {
    const params = new SearchPaginationRequest();
    params.PageSize = 0;
    params.Params = [
      {
        Name: "employer_id",
        Value: this.data.model.EmployerId,
      },
      {
        Name: "status",
        Value: true,
      },
    ];
    params.OrderBy = "name";
    params.ViewColumns = ["membership_package_type"];
    return params;
  }

  TogglePackage(index) {
    console.log(index, "index");
    this.openPackage = index;
  }

  AddMemberPopup() {
    let modalRef = this.modalService.open(AddFamilyMemberPopupComponent, {
      windowClass: "selectPackage-add-member-dialog",
      backdrop: "static",
    });

    var member = {} as Member;
    member.MemberId = 0;
    member.PrimaryMember = Object.assign({}, this.data.model, this.form.value);
    member.MemberStatusId = EMemberStatus.CandidateMember;
    (<AddFamilyMemberPopupComponent>modalRef.componentInstance).data.model = member;
    (<AddFamilyMemberPopupComponent>modalRef.componentInstance).OnLoad();

    modalRef.result.then(
      (result: { returnValue: Member }) => {
        if (result && result.returnValue) {
          result.returnValue.MemberIdPrimary = this.data.model.MemberId;
          // Clear the PrimaryMember object to reduce object size.
          result.returnValue.PrimaryMember = null;

          if (this.data.model.FamilyMembers && this.data.model.FamilyMembers.length >= 0) {
            this.data.model.FamilyMembers.push(result.returnValue);
          }
          else {
            let familyMembers: Member[] = [result.returnValue];
            this.data.model.FamilyMembers = familyMembers;
          }
          this.memberDataSource.push(result.returnValue);
        }
      },
      reason => { }
    );
  }

  GoToPage(membershipPackageId: number) {
    this.router.navigate(["/map-view"], {
      queryParams: { packageId: membershipPackageId },
    });
  }

  DeleteFamilyMember(memberData: Member) {
    const indexOfMember = this.memberDataSource.findIndex((member) => {
      return (
        member.PrimaryEmail == memberData.PrimaryEmail &&
        member.FirstName == memberData.FirstName &&
        member.LastName == memberData.LastName
      );
    });
    this.memberDataSource.splice(indexOfMember, 1);

    const indexOfFamilyMember = this.data.model.FamilyMembers.findIndex(
      (member) => {
        return (
          member.PrimaryEmail == memberData.PrimaryEmail &&
          member.FirstName == memberData.FirstName &&
          member.LastName == memberData.LastName
        );
      }
    );
    this.data.model.FamilyMembers.splice(indexOfFamilyMember, 1);
  }

  PatchValue(
    memberData: Member,
    options?: { onlySelf?: boolean; emitEvent?: boolean }
  ) {
    if (
      memberData &&
      memberData.FirstName &&
      memberData.LastName &&
      memberData.PrimaryEmail
    ) {
      //Primary account check for patch value
      const indexOfPrimaryMember = this.memberDataSource.findIndex((member) => {
        return member.MemberId == memberData.MemberId;
      });

      //Primary member not updated
      if (indexOfPrimaryMember == -1) {
        this.memberDataSource.push(memberData);
      }

      if (memberData.FamilyMembers.length > 0) {
        memberData.FamilyMembers.forEach((familyMember) => {
          var member = this.memberDataSource.find(
            (x) =>
              x.PrimaryEmail == familyMember.PrimaryEmail &&
              x.LastName == familyMember.LastName &&
              x.FirstName == familyMember.FirstName
          );
          if (!member) {
            this.memberDataSource.push(familyMember);
          }
        });
      }

      if (memberData.FavouriteFacilityPersonalTrainingStatus !== null) {            
        this.SelectPackageFormGroup.get("FavouriteFacilityPersonalTrainingStatus").setValue(
          String(this.data.model.FavouriteFacilityPersonalTrainingStatus)
        );
      }
      

      if (memberData.FavouredFacility) {
        this.SelectPackageFormGroup.get("FavouredFacility").setValue(
          this.data.model.FavouredFacility
        );
      }

      if (memberData.ExistingFacility) {
        this.SelectPackageFormGroup.get("ExistingFacility").setValue(
          this.data.model.ExistingFacility
        );
      }

      if (memberData.ExistingFacilityOther) {
        this.SelectPackageFormGroup.get("ExistingFacilityOther").setValue(
          this.data.model.ExistingFacilityOther
        );
      }

      if (memberData.Memberships && memberData.Memberships.length > 0) {
        this.SelectPackageFormGroup.get("Memberships").setValue(
          this.data.model.Memberships
        );
      }

      if (memberData.SignupStep) {
        if (
          this.data.model.SignupStep >= enums.MemberSignupStep.PersonalDetails
        ) {
          this.CanNaviagateToNext = true;
        } else {
          this.CanNaviagateToNext = false;
        }
      }

      if (memberData.IsExistingMember) {
        this.isMemberHasExistingFacility = this.data.model.IsExistingMember;
        this.SelectPackageFormGroup.get("IsExistingMember").setValue(
          this.data.model.IsExistingMember
        );
        if (this.isMemberHasExistingFacility) {
          this.SelectPackageFormGroup.get("ExistingFacility").enable({
            emitEvent: false,
          });
          this.SelectPackageFormGroup.get("ExistingFacilityOther").enable({
            emitEvent: false,
          });
          this.isOtherDacilityDisable = false;
        } else {
          this.SelectPackageFormGroup.get("ExistingFacility").disable({
            emitEvent: false,
          });
          this.SelectPackageFormGroup.get("ExistingFacilityOther").disable({
            emitEvent: false,
          });
          this.isOtherDacilityDisable = true;
        }
      }

      if (memberData.EmployerId) {
        this.RefreshMembershipPackages(true);
      }
      if (memberData.SignupStep >= enums.MemberSignupStep.PersonalDetails) {
        this.isFormDisabled = false;
      } else {
        this.isFormDisabled = true;
      }
    } else {
      this.isFormDisabled = true;
    }
  }
}

abstract class CustomFacilityValidators {
  static FavouredFacilityRequiredWhen = FPValidators.requiredWhen((ctrl) => {
    if (ctrl.parent) {
      const relatedCtrl = ctrl.parent.get("FavouredFacilityOther");
      if (
        relatedCtrl &&
        (relatedCtrl.value == null || relatedCtrl.value.trim().length == 0)
      ) {
        return true;
      }
    }
    return false;
  });

  static FavouredFacilityOtherRequiredWhen = FPValidators.requiredWhen(
    (ctrl) => {
      if (ctrl.parent) {
        const relatedCtrl = ctrl.parent.get("FavouredFacility");
        if (relatedCtrl && relatedCtrl.value == null) {
          return true;
        }
      }
      return false;
    }
  );

  static ExistingFacilityRequiredWhen = FPValidators.requiredWhen((ctrl) => {
    if (ctrl.parent) {
      const isExistingMemberCtrl = ctrl.parent.get("IsExistingMember");
      const relatedCtrl = ctrl.parent.get("ExistingFacilityOther");
      if (
        relatedCtrl &&
        (relatedCtrl.value == null || relatedCtrl.value.trim().length == 0) &&
        isExistingMemberCtrl &&
        isExistingMemberCtrl.value
      ) {
        return true;
      } 
    }
    return false;
  });

  static ExistingFacilityOtherRequiredWhen = FPValidators.requiredWhen(
    (ctrl) => {
      if (ctrl.parent) {
        const isExistingMemberCtrl = ctrl.parent.get("IsExistingMember");
        const relatedCtrl = ctrl.parent.get("ExistingFacility");
        if (
          relatedCtrl &&
          relatedCtrl.value == null &&
          isExistingMemberCtrl &&
          isExistingMemberCtrl.value
        ) {
          return true;
        }
      }
      return false;
    }
  );
}
