export abstract class SearchPaginationBase {
    PageNumber: number;
    PageSize: number;
    OrderBy: string;
    OrderByAsc: boolean;
    ViewColumns?: string[];
    constructor() {
        this.PageNumber = 1;
        this.PageSize = 10;
        this.OrderByAsc = true;
        this.ViewColumns = ['name'];
    }
}

export class SearchPaginationRequest extends SearchPaginationBase {
    Params: SearchPaginationParam[];
    constructor() {
        super();
        this.Params = [];
    }
}

export class SearchPaginationParam {
    Name: string;
    Value: any;
}

export class SearchPaginationCriteria extends SearchPaginationBase {
    Criteria: SearchExpression;
    constructor() {
        super();
        this.Criteria = new SearchExpression();
    }
}

export class SearchField {
    Name: string;
    Operator: SearchFieldOperator;
    Value: any;
}

export enum SearchFieldOperator {
    Equals = 1,
    NotEquals = 2,
    Contains = 3,
    NotContains = 4,
    GreaterThan = 5,
    LessThan = 6,
    GreaterThanOrEquals = 7,
    LessThanOrEquals = 8,
    In = 9,
    NotIn = 10,
}

export class SearchExpression {
    LogicGroup: SearchExpressionLogicGroup;
    Fields: SearchField[];
    Expressions: SearchExpression[];
    constructor() {
        this.Fields = [];
        this.Expressions = [];
        this.LogicGroup = SearchExpressionLogicGroup.Or;
    }
}

export enum SearchExpressionLogicGroup {
    And = 1,
    Or = 2
}