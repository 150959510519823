<div [formGroup]="form">
    <div class="border-default rounded-10 border-2">
        <div class="tablecontainer height2 fpscrolly">
            <table mat-table matSort [dataSource]="dataSource" class="rounded-10" matSort matSortActive="servicename"
                matSortDirection="desc" matSortStart="desc" matSortDisableClear (matSortChange)="sortChange($event)">
                <!-- Name Column -->
                <ng-container matColumnDef="servicename">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Service Name</th>
                    <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let element"> {{element.Status?.Name}} </td>
                </ng-container>

                <ng-container matColumnDef="servicetype">
                    <th mat-header-cell *matHeaderCellDef>Service Type</th>
                    <td mat-cell *matCellDef="let element"> {{element.ServiceType?.Name}} </td>
                </ng-container>

                <ng-container matColumnDef="paymentcalcmethod">
                    <th mat-header-cell *matHeaderCellDef>Payment Calculation Method</th>
                    <td mat-cell *matCellDef="let element"> {{element.PaymentCalculationMethod?.Name}} </td>
                </ng-container>

                <ng-container matColumnDef="visitrecmethod">
                    <th mat-header-cell *matHeaderCellDef>Visit Recording Method</th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.VisitRecordingMethods.length == 1; else multipleRegion">
                            {{ element.VisitRecordingMethods[0].Name }}
                        </span>
                        <ng-template #multipleRegion>
                            <span [ngbPopover]="popContent" placement="top" triggers="mouseenter:mouseleave"
                                #popover="ngbPopover">
                                {{ element.VisitRecordingMethods.length > 0 ? element.VisitRecordingMethods[0].Name :
                                ''
                                }}{{
                                element.VisitRecordingMethods.length > 1 ? ", ..." : "" }}
                            </span>
                            <ng-template #popContent>
                                <ul class="pl-4">
                                    <li *ngFor="let v of element.VisitRecordingMethods">{{ v.Name ? v.Name : "" }}</li>
                                </ul>
                            </ng-template>
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="limitedvisits">
                    <th mat-header-cell *matHeaderCellDef>Limited Visits</th>
                    <td mat-cell *matCellDef="let element"> {{element.IsLimitVisits? "Yes" : "No"}} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef style="width:50px;"></th>
                    <td mat-cell *matCellDef="let element;let i=index">
                        <a class="px-2" (click)="openDialog(i)" *ngIf="!form.disabled">
                            <i class="fas fa-pen"></i>
                        </a>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <mat-toolbar [hidden]="!loaded || dataSource.data.length > 0" class="bg-white">
            <mat-toolbar-row *ngIf="dataSource != null && dataSource.data.length == 0"
                class="text-muted d-block p-2 bg-light text-center">
                {{CommonString.TABLE_NO_RECORD_FOUND}}
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
    <div class="pt-4">
        <button class="btn btn-primary" type="button" (click)="openDialog()" *ngIf="!form.disabled">
            <i class="fas fa-plus-circle mr-2"></i>
            Add Service</button>
    </div>
</div>