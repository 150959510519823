import { Component, EventEmitter, Injector, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { StorageKey } from '@fp/constant';
import { MemberContainer } from '@fp/models/member-container.model';
import { FPBaseComponent } from 'src/app/components/base';
import { MemberFormBase } from 'src/app/components/base/member-form-base';
import { EMemberType, EMemberStatus } from 'src/app/enums';
import { Utils } from 'src/app/helpers';
import { DataResult, Member, MembershipPackage } from 'src/app/models';

@Component({
    selector: 'package-cost-summary',
    templateUrl: './package-cost-summary.component.html',
    styleUrls: ['./package-cost-summary.component.css'],
    providers: [
        { provide: FPBaseComponent, useExisting: PackageCostSummaryComponent, multi: true },
        { provide: MemberFormBase, useExisting: PackageCostSummaryComponent, multi: true }]
})
export class PackageCostSummaryComponent extends MemberFormBase {
    //@Input() allowEditing = false;
    @Input() set dataSource(value) {
        this._dataSource.data = value;
    }
    @Input() membershipPackage: MembershipPackage;

    @Output() rowRemoveClick = new EventEmitter<number>();

    @ViewChild('divNewPriceAlert') readonly divNewPriceAlert: ElementRef<HTMLDivElement>;
    get NewPriceAlertDiv() { return this.divNewPriceAlert.nativeElement; }

    displayedColumns: string[] = ['PackageName', 'Members','MemberType', 'Price'];


    _dataSource: MatTableDataSource<Member>;

    constructor(injector: Injector) {
        super(injector);
        this.form = new UntypedFormGroup({});
        this._dataSource = new MatTableDataSource();
    }

    isMemberValid(MemberStatusId) {
        return (MemberStatusId === EMemberStatus.Active ||
            MemberStatusId === EMemberStatus.Changed ||
            MemberStatusId === EMemberStatus.New ||
            MemberStatusId === EMemberStatus.CandidateMember)
    }

    getPrice(member: Member) {
        let DoB = member.DateOfBirth;
        let dobDate = new Date(DoB);

        let price = 0.00;
        if (this.membershipPackage != null) {
            if (member.MemberIdPrimary == null) {
                try {
                    price = this._dataSource.data.find(e =>
                        ((e.MemberTypeId === EMemberType.Partner) && this.isMemberValid(e.MemberStatusId)) ||
                        ((e.MemberTypeId === EMemberType.Dependant) && (Utils.calculateAge(new Date(e.DateOfBirth)) < 18) && this.isMemberValid(e.MemberStatusId))
                    ) == null ? this.membershipPackage.SinglePrice : this.membershipPackage.FamilyPrice;
                } catch (err) {
                    console.error(err);
                }
            } else if (member.MemberTypeId === EMemberType.Dependant &&
                Utils.calculateAge(dobDate) >= 18 &&
                this.isMemberValid(member.MemberStatusId)) {
                try {
                    price = this.membershipPackage.DependantPrice;
                } catch (err) {
                    console.error(err);
                }
            }
        }
        return price;
    }

    getTotalPrice() {
        let total = 0.00;
        const MemberStatusId = this._dataSource.data[0].MemberStatusId;
        if (!this.isMemberValid(MemberStatusId)) {
            return total;
        }
        this._dataSource.data.forEach((e, i) => {
            total += this.getPrice(e);
        });
        return total;
    }

}
