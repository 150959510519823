<div [formGroup]="form">
    <div class="form-group row">
        <label for="ddlStatus" class="col-md-3 col-lg-2 col-form-label">Employer Status<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select id="ddlStatus" class="form-control" formControlName="EmployerStatusId" [fpFormControlValidation]="{
                        options: {onTouched: true},
                        errorsTemplate: {
                            'notAllowed': CommonMessage.EMPLOYER_FAILED_LAUNCH_STATUS_CHANGED_ERROR
                        }}">
                    <option *ngFor="let status of _statuses" [ngValue]="status.Id">
                        {{status.Name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div *ngIf="[EEmployerStatus.CloseForNewMembers, EEmployerStatus.FailedLaunch].indexOf(getControl('EmployerStatusId').value) > -1"
        class="form-group row">
        <label class="col-md-3 col-lg-2 col-form-label">Date Closed<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="input-group date-input-group" [fpFormControlValidation]="{control: getControl('DateClosed'),
                    controlLabel: 'Date Closed',
                    errorTemplates: {
                        'ngbDate': {
                            'requiredBefore': CommonMessage.FIELD_INVALID
                        },
                        'gt': {
                            'EmployerStartDate': 'The {0} should be greater than the Employer Start Date'
                        }
                    }}">
                    <input name="dp" ngbDatepicker #dtpDateClosed="ngbDatepicker" class="form-control" formControlName="DateClosed"
                        placeholder="DD-MM-YYYY" [minDate]="{year: 1900, month: 1, day: 1}">
                    <div class="input-group-append">
                        <button class="btn bg-white" (click)="dtpDateClosed.toggle()" type="button">
                            <i class="far fa-calendar-alt"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtEmployerStartDate" class="col-md-3 col-lg-2 col-form-label">Employer Start Date<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="input-group date-input-group" [fpFormControlValidation]="{control: getControl('EmployerStartDate'),
                    controlLabel: 'Employer Start Date',
                    errorTemplates: {
                        'ngbDate': {
                            'requiredBefore': CommonMessage.FIELD_INVALID
                        },
                        'lt': {
                            'DateClosed': 'The {0} should be less than the Date Closed'
                        },
                        'lte': {
                            'NextPaymentRunDate': 'The {0} should be less than or equal to the Next Payment Run date'
                        }
                    }}">
                    <input id="txtEmployerStartDate" class="form-control" placeholder="DD-MM-YYYY" ngbDatepicker
                        #dtpStartDate="ngbDatepicker" formControlName="EmployerStartDate" [minDate]="{year: 1900, month: 1, day: 1}">
                    <div class="input-group-append">
                        <button class="btn bg-white" (click)="dtpStartDate.toggle()" type="button">
                            <i class="far fa-calendar-alt"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="chkIsDirectDebit" class="col-md-3 col-lg-2 col-form-label">Payment Types</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="form-control custom-control-input" id="chkIsDirectDebit" formControlName="IsDirectDebit">
                    <label for="chkIsDirectDebit" class="custom-control-label font-weight-normal">Direct Debit</label>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtNextPaymentRunDate" class="col-md-3 col-lg-2 col-form-label">Next Payment Run<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="input-group date-input-group" [fpFormControlValidation]="{control: getControl('NextPaymentRunDate'),
                    controlLabel: 'Next Payment Run',
                    errorTemplates: {
                        'ngbDate': {
                            'requiredBefore': CommonMessage.FIELD_INVALID
                        },
                        'gte': {
                            'EmployerStartDate': 'The {0} date should be greater than or equal to the Employer Start Date'
                        }
                    }}">
                    <input class="form-control" id="txtNextPaymentRunDate" name="txtNextPaymentRunDate" placeholder="DD-MM-YYYY"
                        formControlName="NextPaymentRunDate" ngbDatepicker #dtpNextPaymentRunDate="ngbDatepicker"
                        [minDate]="{year: 1900, month: 1, day: 1}">
                    <div class="input-group-append">
                        <button class="btn bg-white" (click)="dtpNextPaymentRunDate.toggle()" type="button">
                            <i class="far fa-calendar-alt"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlPaymentPeriod" class="col-md-3 col-lg-2 col-form-label">Payment Period<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" id="ddlPaymentPeriod" formControlName="PaymentPeriodId" fpFormControlValidation>
                    <option *ngFor="let period of _paymentPeriods" [ngValue]="period.Id">
                        {{period.Name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtNumOfAnsweredEmployees" class="col-md-3 col-lg-2 col-form-label">Number of employees answered survey</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input class="form-control" id="txtNumOfAnsweredEmployees" formControlName="NoEmployeeAnswer" autocomplete="off"
                    maxlength="9" numberOnly>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlEligibleEmployees" class="col-md-3 col-lg-2 col-form-label">Eligible Employees</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select id="ddlEligibleEmployees" class="form-control " [formControl]="EligibleEmployeeCtrl">
                    <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let employee of _eligibleEmployees;let i = index"
                        [title]="_getEligibleEmployeeOptionTitle(employee.Name)" [ngValue]="i">
                        {{employee.Name}}</option>
                </select>
                <div class="mt-2 slctSelectedRoles height1 fpscrolly w-100 border-2 rounded-10 border-default text-default"
                    *ngIf="SelectedEligibleEmployeesCtrl.value?.length > 0">
                    <div *ngFor="let employee of SelectedEligibleEmployeesCtrl.value;let i = index"
                        class="d-flex border-default border-bottom py-1 align-content-center">
                        <span *ngIf="form.enabled" class="text-primary d-flex align-items-center" (click)="_removeEligibleEmployee(i)">
                            <i class="fas fa-minus-circle fa-2x"></i></span>
                        <p class="d-flex align-items-center m-0 ml-2">{{employee.Name}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtEligibilityInfo" class="col-md-3 col-lg-2 col-form-label">Additional
            Eligibility Information</label>
        <div class="col-md-6">
            <div class="d-flex flex-column w-100">
                <textarea class="form-control" id="txtEligibilityInfo" maxlength="150" formControlName="EligibilityInformation"></textarea>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtTargetNumber" class="col-md-3 col-lg-2 col-form-label">Target Number</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input class="form-control" id="txtTargetNumber" formControlName="TargetNumber" autocomplete="off" maxlength="9">
            </div>
        </div>
    </div>
</div>