import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIConstant } from "src/app/constant";
import { PaymentRequestModel } from "@fp/models/payment-request.model";
import {
  RawVisitParameters,
  RawVisitCount
} from "@fp/models/raw-visit-parameters.model";
import {
  DataResult,
  PaymentDate,
  PaymentFrequency,
  PaymentMethod,
  PaymentPreference,
  RawVisit,
  PostPaymentRequest,
  SearchPaymentRequest,
  SaveRawVisit,
  ApproveRejectRecords,
  ApproveRejectService,
  CalculationMethodAntiFraudSend,
  CalculationMethodAntiFraud,
  CreatePaymentRequest,
  ResultModel,
  SearchPaymentRequestForFacilityStaff,
  CreateDebitProcessing,
  BulkUploadResult,
  GetExcludeRowDetails,
  PaymentRequestDataResult
} from "src/app/models";
import { HttpDAO } from "./httpdao.service";
import { TaskList } from "aws-sdk/clients/datasync";
import { EditPaymentRequestModel } from "@fp/models/edit-payment-request.model";
import {
  CostCalculationParameters,
  CalculatedCost
} from "@fp/models/cost-calculation-parameters.model";
import { DebitProcessingDates } from "@fp/models/debit-processing-dates.model";
import { DebitProcessingFilterData } from "@fp/models/debit-processing-filter-data.model";
import { GenericIdViewModel } from "@fp/models/GenericIdView.model";
import { RetrieveABAFiles } from "@fp/models/RetrieveABAFiles.model";
import { PostedPaymentRequests } from "@fp/models/posted-payment-requests.model";
import { FinaliseAba } from "@fp/models/finalise.aba.model";

@Injectable({
  providedIn: "root"
})
export class PaymentService {
  private httpDao: HttpDAO;

  constructor(httpClient: HttpClient) {
    this.httpDao = new HttpDAO(httpClient);
  }

  /**
   * Get all payment dates.
   * @returns {Observable<DataResult<PaymentDate[]>>} An observable object of type `DataResult<T>`,
   * whose property `Data` is an array of `PaymentDate` objects.
   * @memberof PaymentService
   */
  getDates(): Observable<DataResult<PaymentDate[]>> {
    return this.httpDao.getSingleData(APIConstant.API_GET_PAYMENT_DATES);
  }

  /**
   * Get all payment frequencies.
   * @returns {Observable<DataResult<PaymentFrequency[]>>} An observable object of type `DataResult<T>`,
   * whose property `Data` is an array of `PaymentFrequency` objects.
   * @memberof PaymentService
   */
  getFrequencies(): Observable<DataResult<PaymentFrequency[]>> {
    return this.httpDao.getSingleData(APIConstant.API_GET_PAYMENT_FREQUENCIES);
  }

  /**
   * Get all payment methods.
   * @returns {Observable<DataResult<PaymentMethod[]>>} An observable object of type `DataResult<T>`,
   * whose property `Data` is an array of `PaymentMethod` objects.
   * @memberof PaymentService
   */
  getMethods(): Observable<DataResult<PaymentMethod[]>> {
    return this.httpDao.getSingleData(APIConstant.API_GET_PAYMENT_METHODS);
  }

  /**
   * Get all payment preferences.
   *
   * @returns {Observable<DataResult<PaymentPreference[]>>} An observable object of type `DataResult<T>`,
   * whose property `Data` is an array of `PaymentPreference` objects.
   * @memberof PaymentService
   */
  getPreferences(): Observable<DataResult<PaymentPreference[]>> {
    return this.httpDao.getSingleData(APIConstant.API_GET_PAYMENT_PREFERENCES);
  }

  checkRawVisits(fileName: string): Observable<DataResult<RawVisit>> {
    return this.httpDao.getSingleData(
      APIConstant.API_CHECK_RAW_VISITS + fileName
    );
  }

  saveRawVisits(data: SaveRawVisit): Observable<DataResult<SaveRawVisit>> {
    return this.httpDao.postData(APIConstant.API_SAVE_RAW_VISITS, data);
  }

  paymentRequestApprove(
    payementRequestDetails: PaymentRequestModel
  ): Observable<any> {
    return this.httpDao.postData(
      APIConstant.API_APPROVE_PAYMENT_REQUEST,
      payementRequestDetails
    );
  }

  paymentRequestReject(
    payementRequestDetails: PaymentRequestModel
  ): Observable<any> {
    return this.httpDao.postData(
      APIConstant.API_REJECT_PAYMENT_REQUEST,
      payementRequestDetails
    );
  }

  paymentRequestHold(
    payementRequestDetails: PaymentRequestModel
  ): Observable<any> {
    return this.httpDao.postData(
      APIConstant.API_HOLD_PAYMENT_REQUEST,
      payementRequestDetails
    );
  }

  checkUserPermissionLoa(
    payementRequestDetails: PaymentRequestModel
  ): Observable<any> {
    let apistring =
      APIConstant.API_GET_CHECK_USERNAME_LOA +
      "?facilityname=" +
      encodeURIComponent(payementRequestDetails.FacilityName) +
      "&servicename=" +
      encodeURIComponent(payementRequestDetails.ServiceName) +
      "&createddate=" +
      payementRequestDetails.CreatedDate +
      "&username=" +
      payementRequestDetails.Username +
      "&amount=" +
      payementRequestDetails.Amount;
    return this.httpDao.getSingleData(apistring);
  }

  getRawVisitAmount(
    rawVisitData: RawVisitParameters
  ): Observable<DataResult<RawVisitCount>> {
    let apistring =
      APIConstant.API_GET_RAW_VISIT_COUNT +
      "?facilityname=" +
      encodeURIComponent(rawVisitData.FacilityName) +
      "&servicename=" +
      encodeURIComponent(rawVisitData.ServiceName) +
      "&todate=" +
      rawVisitData.ToDate +
      "&fromdate=" +
      rawVisitData.FromDate;
    return this.httpDao.getSingleData(apistring);
  }

  getCostCalculations(
    costCalculationData: CostCalculationParameters
  ): Observable<DataResult<CalculatedCost>> {
    let apistring =
      APIConstant.API_GET_COST_CALCULATION +
      "?facilityname=" +
      encodeURIComponent(costCalculationData.FacilityName) +
      "&servicename=" +
      encodeURIComponent(costCalculationData.ServiceName) +
      "&todate=" +
      costCalculationData.ToDate +
      "&fromdate=" +
      costCalculationData.FromDate +
      "&visitcount=" +
      costCalculationData.VisitCount +
      "&pagecategory=" +
      costCalculationData.PageCategory;
    return this.httpDao.getSingleData(apistring);
  }

  getPaymentRequestFromBackend(
    searchPaymentRequest: SearchPaymentRequest
  ): Observable<DataResult<PaymentRequestDataResult>> {
    let apistring =
      APIConstant.API_GET_PAYMENT_REQUEST_DATA +
      "?facility=" +
      encodeURIComponent(searchPaymentRequest.Facility) +
      "&status=" +
      "[" +
      searchPaymentRequest.Status +
      "]" +
      "&paymentdatefrm=" +
      searchPaymentRequest.PaymentDateFrm +
      "&paymentdateto=" +
      searchPaymentRequest.PaymentDateTo +
      "&posteddatefrm=" +
      searchPaymentRequest.PostedDateFrm +
      "&posteddateto=" +
      searchPaymentRequest.PostedDateTo;
    return this.httpDao.getSingleData(apistring);
  }

  getPreSignedURL(
    searchPaymentRequest: SearchPaymentRequest
  ): Observable<DataResult<String[]>> {
    return this.httpDao.getSingleData(
      APIConstant.API_GET_PRESIGNED_URL +
        "?uuids=" +
        "[" +
        searchPaymentRequest.Uuids +
        "]"
    );
  }

  getTaskList(): Observable<DataResult<TaskList[]>> {
    return this.httpDao.getSingleData(APIConstant.API_GET_TASK_LIST);
  }

  get3MonthPaymentRequestFromBackend(
    search3MonthPaymentRequest: ApproveRejectService
  ): Observable<DataResult<ApproveRejectRecords[]>> {
    let apistring =
      APIConstant.API_GET_3_MONTH_PAYMENT_REQEUST_DATA +
      "?service=" +
      encodeURIComponent(search3MonthPaymentRequest.Service) +
      "&paymentrequestid=" +
      search3MonthPaymentRequest.PaymentRequestID;

    return this.httpDao.getSingleData(apistring);
  }

  getCalculationMethodAntiFraud(
    paymentCalculationAntiFraud: CalculationMethodAntiFraudSend
  ): Observable<DataResult<CalculationMethodAntiFraud[]>> {
    let apistring =
      APIConstant.API_CALCULATION_METHOD_ANTI_FRAUD +
      "?paymentrequestid=" +
      paymentCalculationAntiFraud.PaymentRequestID;
    return this.httpDao.getSingleData(apistring);
  }

  submitPaymentRequest(
    submitPaymentRequestDetails: CreatePaymentRequest
  ): Observable<ResultModel> {
    return this.httpDao.postData(
      APIConstant.API_SUBMIT_PAYMENT_REQUEST,
      submitPaymentRequestDetails
    );
  }

  submitEditPaymentRequest(
    submitEditPaymentRequestDetails: EditPaymentRequestModel
  ): Observable<ResultModel> {
    return this.httpDao.postData(
      APIConstant.API_SUBMIT_EDIT_PAYMENT_REQUEST,
      submitEditPaymentRequestDetails
    );
  }

  getDebitProcessingFilterData(
    debitProcessingDates: DebitProcessingDates
  ): Observable<DataResult<DebitProcessingFilterData[]>> {
    let apistring =
      APIConstant.API_GET_DEBIT_PROCESSING_FILTER_DATA +
      "?debitprocessingdatefrm=" +
      debitProcessingDates.DebitProcessingDateFrm +
      "&debitprocessingdateto=" +
      debitProcessingDates.DebitProcessingDateTo +
      "&country=" +
      debitProcessingDates.Country;
    return this.httpDao.getSingleData(apistring);
  }

  getPaymentRequestForFacilityStaffMember(
    searchPaymentRequest: SearchPaymentRequestForFacilityStaff
  ): Observable<DataResult<PostPaymentRequest[]>> {
    let apistring =
      APIConstant.API_GET_PAYMENT_REQUEST_DATA_FACILITY_STAFF +
      "?services=" +
      "[" +
      searchPaymentRequest.Services +
      "]" +
      "&paymentdatefrm=" +
      searchPaymentRequest.PaymentDateFrm +
      "&paymentdateto=" +
      searchPaymentRequest.PaymentDateTo;
    return this.httpDao.getSingleData(apistring);
  }

  periodClosedEdit(
    debitProcessingId: GenericIdViewModel
  ): Observable<ResultModel> {
    return this.httpDao.postData(
      APIConstant.API_SUBMIT_PERIOD_CLOSED,
      debitProcessingId
    );
  }

  periodOpenedEdit(
    debitProcessingId: GenericIdViewModel
  ): Observable<ResultModel> {
    return this.httpDao.postData(
      APIConstant.API_SUBMIT_PERIOD_OPENED,
      debitProcessingId
    );
  }

  getPaymentRequestById(
    paymentRequestId: number
  ): Observable<DataResult<EditPaymentRequestModel>> {
    return this.httpDao.getSingleData(
      APIConstant.API_GET_PAYMENT_REQUEST_BY_ID + paymentRequestId
    );
  }

  getABAFiles(AbaFilesData: string, selectedCountry: string): Observable<DataResult<RetrieveABAFiles>> {
    return this.httpDao.getSingleData(
      APIConstant.API_RETRIEVE_ABA_FILES + "?name=" + AbaFilesData +
      "&id=" +
      selectedCountry
    );
  }

  downloadABAFiles(fileName): Observable<DataResult<string>> {
    return this.httpDao.getSingleData(
      APIConstant.API_DOWNLOAD_ABA_FILES + "?name=" + fileName
    );
  }

  getExcludeRowDetails(
    excludeRowDetails: GetExcludeRowDetails
  ): Observable<DataResult<GetExcludeRowDetails>> {
    return this.httpDao.getSingleData(
      APIConstant.API_GET_EXCLUDED_ROW_DETAILS +
        "?filename=" +
        excludeRowDetails.FileName +
        "&fromrow=" +
        excludeRowDetails.FromRow +
        "&torow=" +
        excludeRowDetails.ToRow +
        "&paydate=" +
        excludeRowDetails.PaymentProcessingDate +
        "&country=" +
        excludeRowDetails.Country
    );
  }

  getPostedPaymentRequests(
    postedPRs: RawVisitParameters
  ): Observable<DataResult<PostedPaymentRequests>> {
    return this.httpDao.getSingleData(
      APIConstant.API_POSTED_PRS +
      "?todate=" +
      postedPRs.ToDate +
      "&fromdate=" +
      postedPRs.FromDate +
      "&facilityname=" +
      encodeURIComponent(postedPRs.FacilityName) +
      "&modifiedby=" +
      postedPRs.ModifiedBy +
      "&countryname=" +
      encodeURIComponent(postedPRs.CountryName)
    );
  }

  getRegeneratedPaymentRequests(
    postedPRs: RawVisitParameters
  ): Observable<DataResult<PostedPaymentRequests>> {
    return this.httpDao.getSingleData(
      APIConstant.API_REGENERATED_PRS +
        "?todate=" +
        postedPRs.ToDate +
        "&fromdate=" +
        postedPRs.FromDate +
        "&facilityname=" +
        encodeURIComponent(postedPRs.FacilityName) +
        "&countryname=" +
        encodeURIComponent(postedPRs.CountryName)
    );
  }

  getPaymentRequestNotes(
    paymentRequestId: string
  ): Observable<DataResult<PostedPaymentRequests>> {
    return this.httpDao.getSingleData(
      APIConstant.API_GET_PAYMENT_REQUEST_NOTES + "?id=" + paymentRequestId
    );
  }

  getFeebotCompletion(
    processingDateId: string,
    countryName: string
  ): Observable<DataResult<string[]>> {
    return this.httpDao.getSingleData(
      APIConstant.API_CHECK_FEEBOT_COMPLETION + "?id=" + processingDateId + "&name=" +
      encodeURIComponent(countryName)
    );
  }
}
