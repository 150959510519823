import { Component, Injector, ViewChild, Input } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MemberFormBase } from "src/app/components/base/member-form-base";
import { Member } from "src/app/models";
import { MemberBankAccountComponent } from "../bank-account/bank-account.component";
import { MemberMembershipPackageCostSummaryComponent } from "../membership-package-cost-summary/membership-package-cost-summary.component";
import { MemberContractDetailsComponent } from "../contract-details/contract-details.component";
import { ERole } from "@fp/enums/role.enum";

@Component({
  selector: "app-member-payment-details",
  templateUrl: "./payment-details.component.html",
  styleUrls: ["./payment-details.component.css"],
  providers: [
    { provide: MemberFormBase, useExisting: MemberPaymentDetailsComponent },
  ],
})
export class MemberPaymentDetailsComponent extends MemberFormBase {
  @ViewChild(MemberMembershipPackageCostSummaryComponent)
  membershipPackageCostSummarySection: MemberMembershipPackageCostSummaryComponent;
  @ViewChild(MemberBankAccountComponent)
  bankAccountSection: MemberBankAccountComponent;

  static getFormGroup(isSignup: boolean) {
    const fb = new UntypedFormBuilder();
    if(isSignup) {
      return fb.group({
        BankAccountSection: MemberBankAccountComponent.getFormGroup()
      });
    } else {
      return fb.group({
        BankAccountSection: MemberBankAccountComponent.getFormGroup(),
        ContractSection: MemberContractDetailsComponent.getFormGroup(),
      });
    }
  }

  constructor(injector: Injector) {
    super(injector);
  }

  OnInit() {
    this.membershipPackageCostSummarySection.form.disable({ emitEvent: false });
    super.OnInit();
  }

  isEditMemAdminValid() {
    const isEmployerFacilitatorRole =
      this.commonSvc.GetSelectedRole() === ERole.Employer_Facilitator;
    return this.commonSvc.IsEditMember && !isEmployerFacilitatorRole;
  }

  PatchValue(value: Member, opts) {
    const memberships = value.Memberships || [];
    this.membershipPackageCostSummarySection.membershipPackage =
      memberships.length > 0 && memberships[0]
        ? memberships[0].MembershipPackage
        : null;
    this.membershipPackageCostSummarySection.dataSource = [value].concat(
      value.FamilyMembers
    );
    super.PatchValue(value, opts);
  }

  public IsEF() {
    const slrole = this.commonSvc.GetSelectedRole();
    return slrole === ERole.Employer_Facilitator;
  }
}
