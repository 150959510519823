import { Component, Injector, ViewChild, Input, } from '@angular/core';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { MemberFormBase } from '@fp/components/base';
import { EMemberType } from '@fp/enums';
import { Utils } from '@fp/helpers';
import { MemberAddressContactDetailsComponent } from '../address-contact-details/address-contact-details.component';
import { MemberEmergencyContactComponent } from '../emergency-contact/emergency-contact.component';
import { MemberMedicalQuestionsComponent } from '../medical-questions/medical-questions.component';
import { MemberPersonalDetailsComponent } from '../personal-details/personal-details.component';
import { MemberPreferredFacilitiesComponent } from '../preferred-facilities/preferred-facilities.component';
import { MemberAdditionalNotesComponent } from '../additional-notes/additional-notes.component';
import { ERole } from '@fp/enums/role.enum';
import { CommonService } from '@fp/services';
@Component({
    selector: 'app-member-personal-info',
    templateUrl: './personal-info.component.html',
    styleUrls: ['./personal-info.component.css'],
    providers: [MemberFormBase.provideExisting(MemberPersonalInfoComponent)]
})
export class MemberPersonalInfoComponent extends MemberFormBase {
    @ViewChild('personalDetails') personalDetails: MemberPersonalDetailsComponent;
    @ViewChild('addressContactDetails') addressContactDetails: MemberAddressContactDetailsComponent;
    @ViewChild('emergencyContact') emergencyContact: MemberEmergencyContactComponent;
    @ViewChild('medicalQuestions') medicalQuestions: MemberMedicalQuestionsComponent;
    @ViewChild('preferredFacilities') preferredFacilities: MemberPreferredFacilitiesComponent;
    @ViewChild('isSignup') isSignup: boolean;
    seeAdditionalNotes: boolean = false;
    membershipPkg: number = 0;
    isEmployerFacilitator = true

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            PersonalDetails: MemberPersonalDetailsComponent.getFormGroup(),
            AddressContactDetails: MemberAddressContactDetailsComponent.getFormGroup(),
            EmergencyContact: MemberEmergencyContactComponent.getFormGroup(),
            MedicalQuestionsTab: MemberMedicalQuestionsComponent.getFormGroup(),
            PreferredFacilities: MemberPreferredFacilitiesComponent.getFormGroup()
        });
    }

    constructor(injector: Injector,private cmsrv: CommonService) { super(injector); }

    PatchValue(value, opts) {
        let role = this.cmsrv.GetSelectedRole();
        if(role != "Member") {
            this.seeAdditionalNotes = true;
        }
        this.isSignup = this.personalDetails.isSignup();
        this.personalDetails.OnInit();
        this.preferredFacilities.membershipPackageId = this.membershipPkg;
        this.preferredFacilities.signUp = this.isSignup;
        this.preferredFacilities.OnLoad();
        super.PatchValue(value, opts);

        if(role == ERole.Employer_Facilitator){
            this.isEmployerFacilitator = false;
            this.seeAdditionalNotes = false;
        }

        if (!this.isUnder18Dependent()) {
            const originalPrimaryEmailValidators = this.addressContactDetails.getControl('PrimaryEmail').validator;
            const duplicatedPrimaryMemberPrimaryEmailValidator = (ctrl: AbstractControl) => {
                return ctrl.value && this.data.model.PrimaryMember != null &&
                    ctrl.value.trim() === this.data.model.PrimaryMember.PrimaryEmail ? { duplicated: true } : null;
            };
            this.addressContactDetails.getControl('PrimaryEmail').setValidators([originalPrimaryEmailValidators, duplicatedPrimaryMemberPrimaryEmailValidator]);
        }
    }

    private isUnder18Dependent() {
        const dob = <Date>this.getControl(['PersonalDetails', 'DateOfBirth']).value;
        const memberTypeId = <EMemberType>this.getControl(['PersonalDetails', 'MemberTypeId']).value;
        return dob && memberTypeId && Utils.calculateAge(dob) < 18 && memberTypeId === EMemberType.Dependant;
    }
}
