import { Component, EventEmitter, Injector, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FacilityFormBase } from 'src/app/components/base';
import { CommonMessage, CommonString } from 'src/app/constant';
import { StringHelper } from 'src/app/helpers';
import { DataResult, PaymentDate, PaymentFrequency, PaymentMethod, PaymentPreference, Message } from 'src/app/models';
import { PaymentService } from 'src/app/services/payment.service';
import { Subscription } from 'rxjs';
import { MessageService } from '@fp/services';

@Component({
    selector: 'app-facility-payment-details',
    templateUrl: './facility-payment-details.component.html',
    styleUrls: ['./facility-payment-details.component.css'],
    providers: [{ provide: FacilityFormBase, useExisting: FacilityPaymentDetailsComponent }]
})
export class FacilityPaymentDetailsComponent extends FacilityFormBase {
    subscription: Subscription;
    methods: PaymentMethod[];
    frequencies: PaymentFrequency[];
    preferences: PaymentPreference[];
    dates: PaymentDate[];

    @Output() methodChanged = new EventEmitter<PaymentMethod>();

    CommonMessage = CommonMessage;
    CommonString = CommonString;
    StringHelper = StringHelper;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            FinanceSystemId: [null],
            PaymentMethodId: [null, Validators.required],
            PaymentFrequencyId: [null, Validators.required],
            PaymentPreferenceId: [null, Validators.required],
            PaymentDateId: [null, Validators.required],
            IsAllowToSeePaymentCalculation: [false]
        });
    }

    constructor(injector: Injector, private paymentSvc: PaymentService, private messageService: MessageService) { super(injector); }

    ngOnInit(){
    this.updateValidators(true);
    this.subscription = this.messageService.currentMessage.subscribe((message: Message) => {
      if (message && message.Identifier == CommonString.FacilityPotentialMessageIdentifier) {
        if (message.Content == 'True') {
          this.updateValidators(false);

        }
        else {
          this.updateValidators(true);
        }
      }
    });
    }
    ngOnDestroy() {
        if (this.subscription) {
          this.subscription.unsubscribe(); 
        }
    }

    OnLoad() {
        this.InvokeBatch([
            this.paymentSvc.getMethods(),
            this.paymentSvc.getFrequencies(),
            this.paymentSvc.getPreferences(),
            this.paymentSvc.getDates()],
            {
                onSuccess: res => {
                    this.handleGetMethodsResult(res[0]);
                    this.handleGetFrequenciesResult(res[1]);
                    this.handleGetPreferencesResult(res[2]);
                    this.handleGetDatesResult(res[3]);
                    this.GetPaymentFrequencyName();
                    super.OnLoad();
                }
            });
    }

    LoadComplete() {
        super.LoadComplete();
        setTimeout(() => {
            this.form.get('PaymentMethodId').valueChanges.subscribe(value => {
                this.onPaymentMethodIdChange(value);
            });
        });
    }

    public ddlPayFreqcy_Change(event) {
        this.GetPaymentFrequencyName();
    }

    private GetPaymentFrequencyName() {
        let freid = 0;
        let frename = '';
        for (let i = 0; i < this.frequencies.length; i++) {
            if (this.form.get('PaymentFrequencyId').value == this.frequencies[i].PaymentFrequencyId) {
                frename = this.frequencies[i].PaymentFrequencyName;
                freid = this.frequencies[i].PaymentFrequencyId;
                break;
            }
        }
        this.commonSvc.PaymentFrequencyID = freid;
        this.commonSvc.PaymentFrequencyName = frename;
    }

    private handleGetMethodsResult(result: DataResult<PaymentMethod[]>) {
        if (result.Success) {
            this.methods = result.Data.sort((a, b) => {
                if (a.DisplayOrder != null && b.DisplayOrder != null) {
                    return a.DisplayOrder - b.DisplayOrder;
                } else {
                    return a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1;
                }
            });
        } else {
            console.error(result);
        }
    }

    private handleGetFrequenciesResult(result: DataResult<PaymentFrequency[]>) {
        if (result.Success) {
            this.frequencies = result.Data.sort((a, b) => {
                if (a.DisplayOrder != null && b.DisplayOrder != null) {
                    return a.DisplayOrder - b.DisplayOrder;
                } else {
                    return a.PaymentFrequencyName.toLocaleLowerCase() < b.PaymentFrequencyName.toLocaleLowerCase() ? -1 : 1;
                }
            });
        } else {
            console.error(result);
        }
    }

    private handleGetPreferencesResult(result: DataResult<PaymentPreference[]>) {
        if (result.Success) {
            this.preferences = result.Data.sort((a, b) => {
                if (a.DisplayOrder != null && b.DisplayOrder != null) {
                    return a.DisplayOrder - b.DisplayOrder;
                } else {
                    return a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1;
                }
            });
        } else {
            console.error(result);
        }
    }

    private handleGetDatesResult(result: DataResult<PaymentDate[]>) {
        if (result.Success) {
            this.dates = result.Data.sort((a, b) => {
                if (a.DisplayOrder != null && b.DisplayOrder != null) {
                    return a.DisplayOrder - b.DisplayOrder;
                } else {
                    return a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1;
                }
            });
        } else {
            console.error(result);
        }
    }

    private updateValidators(required: boolean) {
        let requiredFields = ['PaymentMethodId', 'PaymentFrequencyId', 'PaymentPreferenceId', 'PaymentDateId'];
        requiredFields.forEach(fieldName => {
          let control = this.getControl(fieldName);
          if (control) {
            let validators = required ? [Validators.required] : [];
            control.setValidators(validators);
            control.updateValueAndValidity();
          }
        });
      }

    onPaymentMethodIdChange(methodId: number) {
        const method = methodId ? this.methods.find(m => m.PaymentMethodId == methodId) : null;
        this.methodChanged.emit(method);
    }
}
