import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { CommonData } from "../models";
import { AbstractControl } from "@angular/forms";
import { DateHelper } from "./date-helper";
import { Observable, from as observableFrom } from "rxjs";
import { Member } from "src/app/models";
import { EMemberType } from "@fp/enums";

export type DateLike = Date | string | NgbDateStruct;

export abstract class Utils {
  static convertPhoneToLocalFormat(
    phone: string,
    countryCode: string = "61",
    prependDummyCode = false
  ) {
    if (phone && phone.length > 0) {
      if (phone.startsWith(`+${countryCode}`)) {
        phone = phone.substring(3);
        if (prependDummyCode) {
          phone = "0" + phone;
        }
      }
    }
    return phone;
  }

  static convertPhoneToInternationalFormat(
    phone: string,
    countryCode: string = "+61"
  ) {
    if (phone && phone.length > 0) {
      if (!phone.startsWith(`${countryCode}`)) {
        if (phone.startsWith("0")) {
          phone = phone.substring(1);
        }
        phone = `${countryCode}` + phone;
      }
    }
    return phone;
  }

  static convertPhoneToInternationalFormatWithCountryCode(phone: string) {
    if (phone && phone.startsWith('61') && phone.length == 12 && phone.charAt(2) == "0") {
      var part1 = phone.substring(0, 2);
      var part2 = phone.substring(3, phone.length);
      phone = part1 + part2;
    }

    if (phone && phone.startsWith('64') && phone.charAt(2) == "0" && phone.length >= 10) {
      var part1 = phone.substring(0, 2);
      var part2 = phone.substring(3, phone.length);
      phone = part1 + part2;
    }
    return phone;
  }

  static convertPhoneToInternationalFormatWithoutCountryCode(phone: string) {
    if (phone.includes("61")) {
      phone = phone.replace('61', '');
    }
    if (phone.includes("64")) {
      phone = phone.replace('64', '');
    }
    return phone;
  }

  // public static convertPriceFormat(price: any) {
  //     if (price == '') {
  //         price = 0;
  //     }
  //     let newValue = parseFloat(price).toFixed(2);
  //     return '$' + newValue;
  // }

  public static convertPriceFormat(
    value: string,
    allowNegative = false,
    decimalPrecision: number = 2,
    decimalSeparator: string = ".",
    thousandsSeparator: string = ",",
    prefix: string = "$"
  ) {
    if (value == undefined || value === "") {
      return null;
    }
    if (allowNegative) {
      value = value.toString();
      if (value.startsWith("(") || value.startsWith("-")) {
        value = "-" + value.substr(1, value.length).replace(/\(|\)|\$|\-/g, "");
      } else {
        value = value.replace(/\(|\)|\$|\-/g, "");
      }
    }
    let [integer, fraction = ""] = (value || "")
      .toString()
      .split(decimalSeparator);
    fraction =
      decimalPrecision > 0
        ? decimalSeparator + (fraction + "000000").substring(0, 2)
        : "";
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
    // If user types .xx we can display 0.xx
    if (integer === "") {
      integer = "0";
    } else if (integer.startsWith("$")) {
      // If there are multiple transforms, remove the previous dollar sign (blur and change at the same time)
      integer = integer.substr(1, integer.length);
    } else if (allowNegative && integer.startsWith("-")) {
      // If user inputs negative number set to paranthesis format
      integer = integer.substr(1, integer.length);
      return "(" + prefix + integer + fraction + ")";
    }
    return prefix + integer + fraction;
  }

  static calculateAge(birthDateValue: DateLike): number {
    // REMARKS: this function doesn't calculate age accurately because the leap years factor was ignored.
    // TODO: fix me when you come up with a more accurate method.
    const birthDate = Utils.toDate(birthDateValue);
    const today = new Date();
    let age = today.getUTCFullYear() - birthDate.getUTCFullYear();
    age += (today.getUTCMonth() - birthDate.getUTCMonth()) / 12;
    age += (today.getUTCDate() - birthDate.getUTCDate()) / 365;
    return age;
  }

  static toDate(date: unknown): Date {
    if (date instanceof Date) {
      return date;
    }
    if (typeof date === 'string') {
      return new Date(date);
    }
    if (date instanceof Object) {
      const ngbDate = date as NgbDateStruct;
      if (ngbDate.year && ngbDate.month && ngbDate.day) {
        return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
      }
    }

    throw new Error(
      `Cannot convert ${typeof date} '${JSON.stringify(date)}' to Date`,
    );
  }

  //Author: Yen
  static calculateContractDuration(
    startDate: NgbDateStruct,
    endDate: NgbDateStruct
  ): number {
    let year = endDate.year - startDate.year;
    let month = endDate.month - startDate.month;
    if (year > 0) {
      if (month < -6) {
        year--;
      } else if (month == -6 && endDate.day < startDate.day) {
        year--;
      }
    } else if (year == 0) {
      if (month > 6) {
        year++;
      } else if (month == 6 && endDate.day >= startDate.day) {
        year++;
      }
    }
    return year;
  }

  public static sortASConName(arr: Array<any>, sortedName: string) {
    arr = arr.sort((a, b) => {
      if (a[sortedName] > b[sortedName]) {
        return 1;
      }
      if (a[sortedName] < b[sortedName]) {
        return -1;
      }
      return 0;
    });
    return arr;
  }

  static sortCommonData(target: CommonData[], ascending = true) {
    return target.sort(
      (a, b) =>
        (a.DisplayOrder != null && b.DisplayOrder != null
          ? a.DisplayOrder === b.DisplayOrder
            ? 0
            : a.DisplayOrder < b.DisplayOrder
            ? -1
            : 1
          : a.Name === b.Name
          ? 0
          : a.Name < b.Name
          ? -1
          : 1) * (ascending ? 1 : -1)
    );
  }

  static deepClone(source) {
    if (!source || typeof source !== "object") {
      return source;
    }

    let target;

    // Source is Array type. Perform deep copy on each item.
    if (source instanceof Array) {
      target = [];
      for (let i = 0; i < source.length; i++) {
        target[i] = this.deepClone(source[i]);
      }
      return target;
    }

    // Source is Date type. Get time value from source.
    if (source instanceof Date) {
      target = new Date();
      target.setTime(source.getTime());
      return target;
    }

    // Source is Object type. Perform deep copy on each property.
    if (source instanceof Object) {
      target = {};
      for (const key in source) {
        if (source.hasOwnProperty(key)) {
          target[key] = this.deepClone(source[key]);
        }
      }
      return target;
    }

    throw new Error("Unsupported type: " + typeof source);
  }

  /**
   * @see DateHelper.parseUTC(utcDateString: string)
   */
  static parseUTCDate(utcDateString: string): Date {
    return DateHelper.parseUTC(utcDateString);
  }

  static ValidateObject(control: AbstractControl) {
    if (typeof control.value == "object") {
      return null;
    }
    return { validObj: true };
  }

  static getFileBlob(url: string): Observable<Blob> {
    return observableFrom(
      fetch(url, { headers: { "Content-Type": "application/octet-stream" } })
        .then(
          (response) =>
            response.status !== 200
              ? Promise.reject(response)
              : response.blob(),
          (err) => Promise.reject(err)
        )
        .catch((err) => Promise.reject(err))
    );
  }

  static blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  };

  static swapArrayItem(index: number, source: any[], target: any[]) {
    const item = source.splice(index, 1)[0];
    target.push(item);
  }

  /**
   * Gets the Last Numeric Date of the Month - ie 31, 30 or 28/29, depending on Year.
   * @param date - The Year + Month to get the Date for, in a Date format.
   * @returns The last Numeric Date of the Month, given the input Year and Month,
   */
  static getLastDateOfMonthFromDate(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  /**
   * Gets the Last Numeric Date of the Month - ie 31, 30 or 28/29, depending on Year.
   * @param year - The Year to get the Date for, as a Number.
   * @param month - The month to get the Date for, as a Number. Note - Months are 1 Offset (ie Jan is the 1st Month)
   * @returns The last Numeric Date of the Month, given the input Year and Month,
   */
  static getLastDateOfMonthFromInts(year: number, month: number): number {
    return new Date(year, month, 0).getDate();
  }

  static getPrice(member: Member, primaryMember) {
    let price = 0.0;
    const primaryMemModel = primaryMember;
    const members = [primaryMemModel].concat(primaryMemModel.FamilyMembers);
    let membershipPackage;
    if (primaryMemModel.Memberships != null) {
      membershipPackage = primaryMemModel.Memberships[0]
        ? primaryMemModel.Memberships[0].MembershipPackage
        : null;
    }
    if (membershipPackage != null) {
      if (member.MemberIdPrimary == null) {
        try {
          price =
            members.find(
              (e) =>
                e.MemberTypeId === EMemberType.Partner ||
                (e.MemberTypeId === EMemberType.Dependant &&
                  Utils.calculateAge(e.DateOfBirth as Date) < 18)
            ) == null
              ? membershipPackage.SinglePrice
              : membershipPackage.FamilyPrice;
        } catch (err) {
          console.error(err);
        }
      } else if (
        member.MemberTypeId === EMemberType.Dependant &&
        Utils.calculateAge(member.DateOfBirth as Date) >= 18
      ) {
        try {
          price = membershipPackage.DependantPrice;
        } catch (err) {
          console.error(err);
        }
      }
    }
    return price;
  }

  static getTotalPrice(primaryMember) {
    let total = 0.0;
    const primaryMemModel = primaryMember;
    const familyMembers = primaryMemModel.FamilyMembers;
    const members = [primaryMemModel].concat(familyMembers);
    members.forEach((e, i) => {
      total += this.getPrice(e, primaryMember);
    });
    return total;
  }
}
