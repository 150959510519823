//----------------------------------------DO NOT UPDATE ANY THING IN THIS CLASS WITHOUT APPROVAL
//----------------------------------------YOU CAN ADD NEW CONSTANT BUT DO NOT UPDATE ANY CONSTANT WITHOUT APPROVAL
export class RouterConstant {
  //#region Routers
  public static ROUTER_HOME = "";
  public static ROUTER_LOGIN = "login";
  public static ROUTER_DOWNLOAD_PDF = "fpdownloadpdf/:csvfilename";
  public static ROUTER_CONFIRM_ACCOUNT = "confirm-account";
  public static ROUTER_CONFIRM_ACCOUNT_COMPLETED = "confirm-account/completed";

  public static ROUTER_SIGNUP = "signup";
  public static ROUTER_SIGNUP_CAPITAL = "Signup";
  public static ROUTER_SIGNUP_Survey = "survey";
  public static ROUTER_ABOUT = "about";
  public static ROUTER_DASHBOARD = "dashboard";
  public static ROUTER_TERMS_CONDITIONS = "termsconditions";
  public static ROUTER_CONTINUE_MEMBER_SIGNUP = "employer_details";
  //---------------------------------------------------------------------------------------------------------------------
  public static ROUTER_EMPLOYER = "employer";
  public static ROUTER_EMPLOYER_VIEWEDIT = "vieweditemployer/:searchparam";
  public static ROUTER_EMPLOYER_VIEWEDIT_BYREGION = "vieweditemployerbyregion/:regionid";
  public static ROUTER_EMPLOYER_ADD = "addemployer";
  public static ROUTER_EMPLOYER_ADD_CLONE = "addclone/:id";
  public static ROUTER_EMPLOYER_EDIT = "editemployer/:id";
  public static ROUTER_EMPLOYER_PREVIEW = "previewemployer";
  public static ROUTER_EMPLOYER_READ_ONLY = "readonlyemployer/:id";
  public static ROUTER_EMPLOYER_SEARCH = "searchemployer";
  public static ROUTER_EMPLOYER_DRAFT = "draftemployer";
  public static ROUTER_NO_PERMISSION = "nopermission";
  public static ROUTER_EMPLOYER_PACKAGE_PRICE_UPDATES = "packagepriceupdates";
  //---------------------------------------------------------------------------------------------------------------------
  public static ROUTER_REGION_ADD = "createnewregion";
  public static ROUTER_REGION_VIEWEDIT = "vieweditregions";
  public static ROUTER_REGION_EDIT = "editregions/:id";
  public static ROUTER_REGION_SEARCH = "searchregions";
  //---------------------------------------------------------------------------------------------------------------------
  public static ROUTER_USER_ADD = "addusers";
  public static ROUTER_USER_EDIT = "edituser";
  public static ROUTER_USER_VIEW = "viewusers";
  public static ROUTER_USER_VIEW_PARAM = "listusersparam/:searchparam";
  public static ROUTER_USER_SEARCH = "searchusers";
  public static ROUTER_USER_EDIT_DETAILS = "edituserdetails";
  public static ROUTER_USER_COMPLETE_ADD = "completeaddusers";
  public static ROUTER_USER_END_EDIT_DETAILS = "endedituserdetails";
  public static ROUTER_USER_ROLES = "userroles";
  public static ROUTER_USER_CHANGE_PASSWORD = "changepassword";
  //---------------------------------------------------------------------------------------------------------------------
  public static ROUTER_FACILITY_DETAILS = "facilitydetails";
  public static ROUTER_FACILITY_MEMBERS = "facilitymembers";
  public static ROUTER_FACILITY_MEMBERS_CHECK_IN = "membermanuallycheckin";
  //---------------------------------------------------------------------------------------------------------------------
  public static ROUTER_FACILITY = "facilities";
  public static ROUTER_FACILITY_ADD = "facility/add";
  public static ROUTER_FACILITY_DRAFT = "facility/draft";
  public static ROUTER_FACILITY_EDIT = "facility/edit/:id";
  public static ROUTER_FACILITY_EDIT_PR_NOTES = "facility/edit/pr-notes/:id";
  public static ROUTER_FACILITY_CLONE = "facility/clone";
  public static ROUTER_FACILITY_SEARCH = "facility/search";
  public static ROUTER_FACILITIES_VIEW = "facilities/view";
  public static ROUTER_FACILITIES_HOME_EDIT = "facility/homeedit";
  public static ROUTER_FACILITIES_INPUT_MANUAL = "facility/inputmanual/:id";
  //---------------------------------------------------------------------------------------------------------------------
  public static ROUTER_PRODUCT_SERVICE_VIEW = "viewproductsservices";
  public static ROUTER_PRODUCT_SERVICE_ADD = "createnewproductsservices";
  public static ROUTER_PRODUCT_SERVICE_EDIT = "editproductservice/:id";
  //---------------------------------------------------------------------------------------------------------------------
  public static ROUTER_PACKAGE = "package";
  public static ROUTER_PACKAGE_ADD = "package/add";
  public static ROUTER_PACKAGE_EDIT = "package/edit/:id";
  public static ROUTER_PACKAGE_CLONE = "package/clone";
  public static ROUTER_PACKAGE_SEARCH = "package/search";
  public static ROUTER_PACKAGE_VIEW = "package/view";
  //---------------------------------------------------------------------------------------------------------------------

  public static ROUTER_MANUALLY_UPLOAD = "manuallyupload";
  public static ROUTER_VIEW_PAYMENT_REQUESTS = "viewpaymentrequests";
  public static ROUTER_VIEW_PAYMENT_REQUESTS_APPROVE = "approvepaymentrequest";
  public static ROUTER_VIEW_PAYMENT_REQUESTS_CREATE = "createpaymentrequest";
  public static ROUTER_SYSTEM_PARAMETERS = "systemparameters";
  public static ROUTER_VIEW_PAYMENT_REQUESTS_ADD_MANUAL_VISITS = "addmanualvisits";
  public static ROUTER_EDIT_MANUAL_VISITS = "editmanualvisits";
  public static ROUTER_TASK_LIST = "tasklist";
  public static ROUTER_EDIT_PAYMENT_REQUEST = "editpaymentrequest";
  public static ROUTER_VIEW_PAYMENTS_AND_INVOICES = "viewpaymentsandinvoices";
  public static ROUTER_DEBIT_PROCESSING = "debitprocessing";
  public static ROUTER_BULK_UPLOAD_DEBIT_CREDIT = "bulkupload";
  public static ROUTER_EDIT_DEBIT_PROCESSING = "editdebitprocessing";
  //---------------------------------------------------------------------------------------------------------------------

  public static ROUTER_MEMBER_DETAILS = "memberdetails";
  public static ROUTER_REACTIVATE_MEMBER = "reactivatemember";
  public static ROUTER_MEMBER_PRE_SIGNUP_SURVEY = "memberpresignupsurvey";
  public static ROUTER_MEMBERSHIP_CARDS = "membershipcards";
  public static ROUTER_SUSPEND_MEMBERSHIP = "suspendmembership";
  public static ROUTER_CUSTOMER_SUPPORT = "customersupport";
  public static ROUTER_MEMBER_TRANSFER = "membertransfer";
  public static ROUTER_MEMBER_TRANSFER_COMPLETE = "membertransfercomplete";
  public static ROUTER_MEMBER_PAYMENTS_CONTRACTS = "memberpaymentscontracts";
  public static ROUTER_MEMBER_VERIFICATION = "memberverification";
  public static ROUTER_MEMBER_SEARCH = "member/search";
  public static ROUTER_MEMBER_VIEW = "member/view/:employerid";
  public static ROUTER_MEMBER_VIEW_SEARCH = "member/viewsearch/:valuesearch";
  public static ROUTER_MEMBER_EDIT = "member/edit/:id";
  public static ROUTER_MEMBER_MANUALLY_ADD_DEBIT_CREDIT_BOX = "member/adddebitcredit";
  public static ROUTER_MEMBER_SIGNUP = "membersignup";
  public static ROUTER_MEMBER_CONTINUE_SIGNUP = "continuemembersignup";
  public static ROUTER_MEMBER_CONTINUE_REACTIVATE_MEMBER = "continuereactivatemember";
  public static ROUTER_MEMBER_CONTINUE_PRE_SIGNUP_SURVEY = "continuepresignupsurvey";
  public static ROUTER_END_MEMBER_SIGNUP = "endmembersignup";
  public static ROUTER_CHANGE_MEMBERSHIP_PACKAGE = "member/changemembershippackage";
  public static ROUTER_MEMBER_SECURITY_PREFERENCES = "membersecuritypreferences";
  public static ROUTER_MEMBER_SECURITY_PREFERENCES_UPDATE_PHONE = "membersecuritypreferencesupdatephone";
  public static ROUTER_MEMBER_TESTING = "test/member";
  public static ROUTER_MEMBER_NOTIFICATIONS = "membernotifications";
  public static ROUTER_MEMBER_PAYMENTS_HISTORY = "memberpaymentshistory";
  public static ROUTER_MEMBER_CONTRACTS = "membercontracts";
  public static ROUTER_MEMBER_UPDATE_PAYMENTS_DETAILS = "memberupdatepaymentdetails";
  //---------------------------------------------------------------------------------------------------------------------
  public static ROUTER_EMPLOYER_PORTAL = "employerportal";
  public static ROUTER_PAGE_NOT_FOUND = "pagenotfound";
  //---------------------------------------------------------------------------------------------------------------------
  public static ROUTER_FORGET_PASSWORD = "forget-password";
  public static ROUTER_FORMAT_PASSWORD_INPUT_INFO = "input-info";
  public static ROUTER_RESET_PASSWORD_REQUIRED = "resetpassword";
  public static ROUTER_FORGET_PASSWORD_REQUEST_PINCODE = "request-pincode";
  public static ROUTER_FORGET_PASSWORD_SEND_EMAIL = "send-email";
  public static ROUTER_FORGET_PASSWORD_CREATE_NEW_PASSWORD = "create-new-password";
  public static ROUTER_FORGET_PASSWORD_COMPLETE = "complete";
  public static ROUTER_TWO_FACTOR_SELECTOR = "login-component/two-factor-selector";
  public static ROUTER_TWO_FACTOR_AUTHENTICATOR = "login-component/two-factor-authenticator";


  //---------------------------------------------------------------------------------------------------------------------
  public static ROUTER_AUDIT_LOG = "audit-log";
  //--------------------------------------------------------------------------------------------------------------------
  public static ROUTER_TRAINING_MATERIAL_VIEW = "trainingmaterialview";
  public static ROUTER_TRAINING_MATERIAL_UPLOAD = "trainingmaterialupload";
  //--------------------------------------------------------------------------------------------------------------------
  public static ROUTER_GENERATE_REPORTS = "generatereports";
  public static ROUTER_GENERATE_REPORTS_EF_FSM = "generatereports-ef-fsm";
  public static ROUTER_GENERATE_REPORTS_FP = "generatereports-fp";
  //--------------------------------------------------------------------------------------------------------------------
  public static ROUTER_BOT_LOGS = "bot-logs";
  //#endregion Routers

  //#region Navigators
  //---------------------------------------------------------------------------------------------------------------------
  public static NAVIGATOR_EMPLOYER_ADDNEW = "dashboard/addemployer";
  public static NAVIGATOR_EMPLOYER_EDIT = "dashboard/editemployer/";
  public static NAVIGATOR_EMPLOYER_VIEW_BY_REGION = "dashboard/vieweditemployerbyregion/";
  public static NAVIGATOR_EMPLOYER_VIEW_ALL = "dashboard/vieweditemployer/";
  public static NAVIGATOR_EMPLOYER_ADDCLONE = "dashboard/addclone/";
  public static NAVIGATOR_EMPLOYER_READ_ONLY = "dashboard/readonlyemployer/";
  public static NAVIGATOR_EMPLOYER_SEARCH = "dashboard/searchemployer/";
  //---------------------------------------------------------------------------------------------------------------------
  public static NAVIGATOR_REGION_VIEWLIST = "/dashboard/vieweditregions";
  public static NAVIGATOR_REGION_EDIT = "/dashboard/editregions";
  public static NAVIGATOR_REGION_SEARCH = "/dashboard/searchregions";
  //---------------------------------------------------------------------------------------------------------------------
  public static NAVIGATOR_FACILITY_DETAILS = "/dashboard/facilitydetails";
  public static NAVIGATOR_FACILITY_MEMBERS = "/dashboard/facilitymembers";
  public static NAVIGATOR_FACILITY_MEMBERS_CHECKIN = "/dashboard/membermanuallycheckin";
  //---------------------------------------------------------------------------------------------------------------------
  public static NAVIGATOR_FACILITY = "/dashboard/facilities";
  public static NAVIGATOR_FACILITY_ADD = "/dashboard/facility/add";
  public static NAVIGATOR_FACILITY_EDIT = "/dashboard/facility/edit";
  public static NAVIGATOR_FACILITY_EDIT_PR_NOTES = "facility/edit/pr-notes";
  public static NAVIGATOR_FACILITY_CLONE = "/dashboard/facility/clone";
  public static NAVIGATOR_FACILITY_DRAFT = "/dashboard/facility/draft";
  public static NAVIGATOR_FACILITY_SEARCH = "/dashboard/facility/search";
  public static NAVIGATOR_FACILITY_HOME_EDIT = "/dashboard/facility/homeedit";
  public static NAVIGATOR_FACILITY_INPUT_MANUAL = "/dashboard/facility/inputmanual";
  //---------------------------------------------------------------------------------------------------------------------
  public static NAVIGATOR_PRODUCT_SERVICE_EDIT = "/dashboard/editproductservice";
  //---------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------------------
  public static NAVIGATOR_MEMBER_NOTIFICATIONS = "/dashboard/membernotifications";
  //---------------------------------------------------------------------------------------------------------------------
  public static NAVIGATOR_PACKAGE = "/dashboard/package/add";
  public static NAVIGATOR_PACKAGE_ADD = "/dashboard/package/add";
  public static NAVIGATOR_PACKAGE_EDIT = "/dashboard/package/edit";
  public static NAVIGATOR_PACKAGE_CLONE = "/dashboard/package/clone";
  public static NAVIGATOR_PACKAGE_SEARCH = "/dashboard/package/search";
  public static NAVIGATOR_PACKAGE_VIEW = "/dashboard/package/view";
  public static NAVIGATOR_USER_VIEW_PARAM = "/dashboard/listusersparam/";
  //---------------------------------------------------------------------------------------------------------------------
  public static NAVIGATOR_MEMBER_DETAILS = "/dashboard/memberdetails";
  public static NAVIGATOR_MEMBER_SECURITY_PREFERENCES =
    "/dashboard/membersecuritypreferences";
  public static NAVIGATOR_MEMBER_SECURITY_PREFERENCES_UPDATE_PHONE =
    "/dashboard/membersecuritypreferencesupdatephone";
  //---------------------------------------------------------------------------------------------------------------------
  public static NAVIGATOR_LOGIN = "/login";
  public static NAVIGATOR_MEMBER_SIGNUP = "/membersignup";
  public static NAVIGATOR_MEMBER_VERIFICATION = "/dashboard/memberverification";
  public static NAVIGATOR_MEMBER_SEARCH = "/dashboard/member/search";
  public static NAVIGATOR_MEMBER_VIEW = "/dashboard/member/view/";
  public static NAVIGATOR_MEMBER_VIEW_SEARCH = "/dashboard/member/viewsearch/";

  public static NAVIGATOR_MEMBER_EDIT = "/dashboard/member/edit/";
  public static NAVIGATOR_MEMBER_MANUALLY_ADD_DEBIT_CREDIT_BOX = "/dashboard/member/adddebitcredit";
  public static NAVIGATOR_END_MEMBER_SIGNUP = "/dashboard/endmembersignup";
  public static NAVIGATOR_CONTINUE_MEMBER_SIGNUP = "/dashboard/continuemembersignup";
  public static NAVIGATOR_CONTINUE_REACTIVATE_MEMBER = "/dashboard/continuereactivatemember";
  public static NAVIGATOR_CONTINUE_PRE_SIGNUP_SURVEY = "/dashboard/continuepresignupsurvey";
  public static NAVIGATOR_TERMS_CONDITIONS = "/termsconditions";
  public static NAVIGATOR_NO_PERMISSION = "/dashboard/nopermission";
  public static NAVIGATOR_CHANGE_MEMBERSHIP_PACKAGE = "/dashboard/member/changemembershippackage";
  public static NAVIGATOR_REACTIVATE_MEMBER = "/dashboard/reactivatemember";
  public static NAVIGATOR_PRE_SIGNUP_SURVEY = "/dashboard/memberpresignupsurvey";
  //---------------------------------------------------------------------------------------------------------------------
  public static NAVIGATOR_MEMBERSHIP_CARDS = "/dashboard/membershipcards";
  public static NAVIGATOR_SUSPEND_MEMBERSHIP = "/dashboard/suspendmembership";
  public static NAVIGATOR_MEMBER_TRANSFER = "/dashboard/membertransfer";
  public static NAVIGATOR_MEMBER_TRANSFER_COMPLETE = "/dashboard/membertransfercomplete";
  public static NAVIGATOR_PAGE_NOT_FOUND = "pagenotfound";
  public static NAVIGATOR_MEMBER_PAYMENTS_HISTORY = "/dashboard/memberpaymentshistory";
  public static NAVIGATOR_MEMBER_CONTRACTS = "/dashboard/membercontracts";
  public static NAVIGATOR_MEMBER_UPDATE_PAYMENTS_DETAILS = "/dashboard/memberupdatepaymentdetails";
  //---------------------------------------------------------------------------------------------------------------------
  public static NAVIGATOR_FORMAT_PASSWORD_INPUT_INFO = "/forget-password/input-info";
  public static NAVIGATOR_FORGET_PASSWORD_REQUEST_PINCODE = "/forget-password/request-pincode";
  public static NAVIGATOR_FORGET_PASSWORD_SEND_EMAIL = "/forget-password/send-email";
  public static NAVIGATOR_FORGET_PASSWORD_CREATE_NEW_PASSWORD = "/forget-password/create-new-password";
  public static NAVIGATOR_FORGET_PASSWORD_COMPLETE = "/forget-password/complete";

  //---------------------------------------------------------------------------------------------------------------------

  public static NAVIGATOR_MANUALLY_UPLOAD = "/dashboard/manuallyupload";
  public static NAVIGATOR_VIEW_PAYMENT_REQUESTS = "/dashboard/viewpaymentrequests";
  public static NAVIGATOR_VIEW_PAYMENT_REQUESTS_APPROVE = "/dashboard/approvepaymentrequest";
  public static NAVIGATOR_VIEW_PAYMENT_REQUESTS_CREATE = "/dashboard/createpaymentrequest";
  public static NAVIGATOR_SYSTEM_PARAMETERS = "/dashboard/systemparameters";
  public static NAVIGATOR_VIEW_PAYMENT_REQUESTS_ADD_MANUAL_VISITS = "/dashboard/addmanualvisits";
  public static NAVIGATOR_EDIT_MANUAL_VISITS = "/dashboard/editmanualvisits";
  public static NAVIGATOR_EDIT_PAYMENT_REQUEST = "/dashboard/editpaymentrequest";
  public static NAVIGATOR_VIEW_PAYMENTS_AND_INVOICES = "/dashboard/viewpaymentsandinvoices";
  public static NAVIGATOR_DEBIT_PROCESSING = "/dashboard/debitprocessing";
  public static NAVIGATOR_BULK_UPLOAD_DEBIT_CREDIT = "/dashboard/bulkupload";
  public static NAVIGATOR_EDIT_DEBIT_PROCESSING = "/dashboard/editdebitprocessing";
  //---------------------------------------------------------------------------------------------------------------------
  public static NAVIGATOR_AUDIT_LOG = "/dashboard/audit-log";
  //---------------------------------------------------------------------------------------------------------------------
  public static NAVIGATOR_TRAINING_MATERIAL_VIEW = "/dashboard/trainingmaterialview";
  public static NAVIGATOR_TRAINING_MATERIAL_UPLOAD = "/dashboard/trainingmaterialupload";

  //---------------------------------------------------------------------------------------------------------------------
  public static NAVIGATOR_GENERATE_REPORTS = "/dashboard/generatereports";
  public static NAVIGATOR_GENERATE_REPORTS_EF_FSM = "/dashboard/generatereports-ef-fsm";
  public static NAVIGATOR_GENERATE_REPORTS_FP = "/dashboard/generatereports-fp";

  //---------------------------------------------------------------------------------------------------------------------
  public static NAVIGATOR_BOT_LOGS = "/dashboard/bot-logs";
  //---------------------
  public static NAVIGATOR_RESET_PASSWORD_REQUIRED = "/forget-password/resetpassword";


  //----------------------------------------------------------------------------------------------------------------------
  public static ROUTER_PARENT_GROUP_VIEW = "viewparentgroups";
  public static ROUTER_PARENT_GROUP_ADD = "createnewparentgroup";
  public static ROUTER_PARENT_GROUP_EDIT = "editparentgroup/:id";

  public static NAVIGATOR_PARENT_GROUP_EDIT = "/dashboard/editparentgroup"
  public static NAVIGATOR_PARENT_GROUP_VIEW = "/dashboard/viewparentgroups"

  //----------------------------------------------------------------------------------------------------------------------
  public static ROUTER_ADD_BANK_CODE = "addbankcode";
  public static ROUTER_ADD_BSB = "addbsb";
  public static ROUTER_VIEW_BANK_CODE_HISTORY = "viewbankcodehistory";
  public static ROUTER_VIEW_BSB_HISTORY = "viewbsbhistory";
  //#endregion Navigators
  public static ROUTER_UPLOAD_POSTCODE = "uploadpostcode";
  public static ROUTER_EDIT_TERMS_CONDITIONS = "edittermsconditions";
}
