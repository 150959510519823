import { MessageBox } from "@fp/services/common-dialog.service";
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
} from "@angular/core";
import {
  AWSStartExecutionResponse
} from "@fp/models";
import { Subject, merge, of as observableOf, Observable, forkJoin } from "rxjs";
import {
  debounceTime,
  startWith,
  switchMap,
  map,
  catchError,
  shareReplay,
  tap,
} from "rxjs/operators";
import { environment } from "src/environments/environment";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { v4 as uuid } from "uuid";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { HttpDAO, CommonService } from "@fp/services";
import { APIConstant, CommonString } from "@fp/constant";
import { Configuration } from "@fp/models/configuration.model";
import {
  FileImportModel,
  RealColumn,
  FpCsvColumn,
  ErrorType,
  ErrorPosition,
  ExistingValidatorColumnModel,
  ExistingValidatorModel,
  PkFkValidatorColumnModel,
  PkFkValidatorViewModel,
} from "@fp/models/file-import.model";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Utils } from "@fp/helpers/utils";
import { StepFunctions } from "@fp/enums/step-functions.enum";
import { AwsServiceService } from "@fp/services/aws-service.service";
import { DescribeStateMachineComponent } from "@fp/components/describe-state-machine/describe-state-machine.component";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { FpProgressBarComponent } from "@fp/components/control/fp-progress-bar/fp-progress-bar.component";

@Component({
  selector: "app-fp-csv-import",
  templateUrl: "./fp-csv-import.component.html",
  styleUrls: ["./fp-csv-import.component.css"],
})
export class FpCsvImportComponent implements OnInit {
  public pg_subject: Subject<number> = new Subject();
  public pg_subject_status: Subject<string> = new Subject();
  public pgnextfile_subject: Subject<any> = new Subject();
  public pgclose_subject: Subject<boolean> = new Subject();
  public pgallclose_subject: Subject<boolean> = new Subject();
  public Filevalue: string = "";
  public status: string = "";
  public dataSourceFile = new MatTableDataSource<FileImportModel>();
  public DisplayUploadColumns: string[] = ["Filename", "UploadStatus", "Error"];
  public IsS3Status = false;
  ErrorTypeEnum = ErrorType;

  @Input() lblSelectFile: string = "SELECT UPLOAD FILE(S)";
  @Input() lblUploadFile: string = "UPLOAD FILE(s)";
  @Input() lblClearList: string = "CLEAR FILE(s)";

  @Input() isReadonly: boolean = false;
  @Input() ngClassLabel: string = "";
  @Input() ngInputFile: string = "custom-file w-230px thumb";
  @Input() maxfilesize: string = "2";
  @Input() colvalidate: FpCsvColumn[] = [
    {
      colname: "Employer ID",
      maxlength: 200,
      type: "number",
      valueexisting: { ColumnName: "EmployerId", TableName: "Employer" },
    },
    {
      colname: "MembershipPackageID",
      maxlength: 200,
      type: "number",
      primarykey: true,
      valueexisting: {
        ColumnName: "MembershipPackageId",
        TableName: "MembershipPackage",
      },
      pkfkrelation: {
        FkColumnName: "EmployerId",
        FkColumnIndex: 0,
        DisplayColumName: "EmployerId",
        PkColumnName: "MembershipPackageId",
        ColumnType: "number",
        TableName: "MembershipPackage",
      },
    },
    {
      colname: "Current Single Membership Price",
      maxlength: 6,
      type: "number",
    },
    {
      colname: "Current Family Membership Price",
      maxlength: 6,
      type: "number",
    },
    {
      colname: "Current 18-24 year old Dependent Price",
      maxlength: 6,
      type: "number",
    },
  ];

  public percent = 0;
  public IsUploadCompleted = false;
  @ViewChild("content") popupcontent: ElementRef;
  @ViewChild("fpfileupload") fpfileupload: ElementRef;
  @ViewChild('progressBar') progressBar: FpProgressBarComponent;
  @ViewChild(DescribeStateMachineComponent) describeSpinner: DescribeStateMachineComponent;

  private httpdao: HttpDAO | null;
  private openModal: NgbModalRef;
  private dtsf: FileImportModel[] = [];
  private indexfile = 0;
  private allselectedfiles = null;
  private IsCorrectFileType = false;
  private lstColIndexCheckExisting: number[] = [];
  private lstColIndexCheckPkFkRelation: number[] = [];

  StepFunctions = StepFunctions;
  errorMsg: string;
  bulkUploadConfirmationMsg: string;
  errorHandler: any;
  uploadedFileName: string[];

  constructor(
    private modalService: NgbModal,
    protected dialog: MatDialog,
    private http: HttpClient,
    private commonservice: CommonService,
    private sanitized: DomSanitizer,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    private awsService: AwsServiceService
  ) {}

  ngOnInit() {
    this.httpdao = new HttpDAO(this.http);
    this.InitDataSource();
    const self = this;
    this.pg_subject.pipe(debounceTime(200)).subscribe((val) => {
      self.percent = val;
    });

    this.pg_subject_status.pipe(debounceTime(200)).subscribe((val) => {
      self.IsS3Status = false;
      self.status = val;
    });

    this.pgnextfile_subject.pipe(debounceTime(1000)).subscribe((data) => {
      self.IsUploadCompleted = true;
      self.dtsf[self.indexfile].Location = data.Location;

      if (self.dtsf[self.indexfile].ErrorPos.length > 0) {
        self.dtsf[self.indexfile].UploadStatus = "Rejected";
        self.dtsf[self.indexfile].FormatUploadStatus = self.FormatStatus(
          "Rejected",
          "error"
        );
        self.dtsf[self.indexfile].Errors.push(
          '<a href="' +
            self.dtsf[self.indexfile].Location +
            '" target="_blank" style="text-decoration: underline !important;color:red !important">Error Detail</a>'
        );
        self.SetError(
          self.dtsf[self.indexfile].Errors,
          self.dtsf[self.indexfile].Errortype
        );
        self.pgclose_subject.next(true);
      } else {
        self.dtsf[self.indexfile].UploadStatus = "Ready";
        self.ImportData(self.dtsf[self.indexfile].Location);
      }
    });

    this.pgclose_subject.pipe(debounceTime(500)).subscribe((val) => {
      if (self.ShouldClosePopup()) {
        self.pgallclose_subject.next(true);
      } else {
        self.ResetConfig();
        self.GoNextFile();
      }
    });

    this.pgallclose_subject.pipe(debounceTime(500)).subscribe((val) => {
      self.status = "";
      self.Close();
      self.ResetAllConfig();
      this.cdRef.markForCheck();
    });
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError(e => {
          throw e;
        })
      )
      .subscribe(
        res => {
          handleResultCallback(res);
        },
        err => {
          console.log(err, 'error')
          this.errorHandler(err);
        }
      );
  }

  private ShouldClosePopup() {
    if (this.indexfile >= this.dtsf.length - 1) {
      return true;
    }
    for (let i = 0; i < this.dtsf.length; i++) {
      if (this.dtsf[i].UploadStatus === "Pending") {
        return false;
      }
    }
    return true;
  }

  private InitDataSource() {
    this.dtsf = [];
    this.dataSourceFile = new MatTableDataSource<FileImportModel>(this.dtsf);
  }

  public fileEvent(event) {
    if ((<HTMLInputElement>event.target).files.length == 0) {
      //This is for IE
      return;
    }
    merge()
      .pipe(
        startWith({}),
        map((result) => {
          this.allselectedfiles = (<HTMLInputElement>event.target).files;
          this.PrepareUpload();
        }),
        catchError((err) => {
          return observableOf([]);
        })
      )
      .subscribe((data) => {});
  }

  public btnUploadFile(event) {
    if (this.dtsf.length > 0) {
      this.Open();
      this.pg_subject_status.next("initializing");
      this.CheckFile();
    }
  }

  public btnClearList(event) {
    this.InitDataSource();
  }

  private PrepareUpload() {
    const file = this.allselectedfiles[this.indexfile];
    if (file != undefined) {
      this.IsS3Status = false;
      this.IsCorrectFileType = this.CheckFilesType(file);
      if (this.IsCorrectFileType === false) {
        MessageBox.ShowErrorCustomerHeader(
          this.dialog,
          CommonString.IMPORT_CONTROL_WRONG_FILE_TYPE,
          CommonString.IMPORT_CONTROL_WRONG_FILE_TYPE_HEADER
        );
      }
    }
  }

  private UploadtoS3(blobToUpload: Blob, IsErrorFile: boolean) {
    const self = this;
    this.IsS3Status = true;
    this.status = "Collecting data file: " + this.dtsf[this.indexfile].Filename;

    const d = new Date();
    const n = d.getTime();

    const filename = uuid() + n.toString() + ".csv";
    const fileFromBlob = Utils.blobToFile(blobToUpload, filename);

    let fileuploadpath = "";
    if (IsErrorFile) {
      fileuploadpath = "ImportsError/" + filename;
    } else {
      fileuploadpath = "Imports/" + filename;
    }

    this.uploadToS3SignedURL(fileuploadpath, fileFromBlob);
  }

  // Upload method - signed URL
  private uploadToS3SignedURL(filename: string, file: File): void {
    this.commonservice.getUploadUrl(filename).subscribe((res) => {
      this.uploadfileAWSS3(res, file)
        .pipe(
          tap((event) => this.handleUploadProgressMessage(event as any, file))
        )
        .subscribe();
    });
  }

  // Upload via Data Access Object put
  private uploadfileAWSS3(fileuploadurl, file): any {
    return this.httpdao.putDataWithoutToken(fileuploadurl, file);
  }

  // React to Http responses from AWS
  private handleUploadProgressMessage(event: HttpEvent<any>, file: File) {
    switch (event.type) {
      case HttpEventType.Sent:
        this.pg_subject.next(0);
        if (process.env.NODE_ENV === "development") {
          console.log(`Uploading file "${file.name}" of size ${file.size}.`);
        }
        return;

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round((100 * event.loaded) / event.total);
        this.pg_subject.next(percentDone);
        if (process.env.NODE_ENV === "development") {
          console.log(`File "${file.name}" is ${percentDone}% uploaded.`);
        }
        return;

      case HttpEventType.Response:
        // The returned download URL has a bunch of token data attached.
        // We need to remove it, and put it into an object that contains a "Location" key
        let outputInfo = {
          Location: event.url.split("?")[0],
          url: event.url.split("?")[0],
        };
        this.pgnextfile_subject.next(outputInfo);
        this.lstColIndexCheckExisting = [];
        this.lstColIndexCheckPkFkRelation = [];
        if (process.env.NODE_ENV === "development") {
          console.log(`File "${file.name}" was completely uploaded!`);
        }
        return;

      default:
        if (process.env.NODE_ENV === "development") {
          console.log(
            `File "${file.name}" surprising upload event: ${event.type}.`
          );
        }
        return;
    }
  }

  private ImportData(happycaseurlfile: string) {
    //FP-896
    const self = this;
    self.pgclose_subject.next(true);
    this.bulkUploadConfirmationMsg =
      "You are about to upload Membership package prices, do you wish to continue?";
    MessageBox.ShowCancelContinue(
      this.dialog,
      "Membership Package Prices Upload Confirmation",
      this.bulkUploadConfirmationMsg
    ).subscribe(dialogResult => {
      if (dialogResult.result.toLowerCase() === DialogResult.Continue) {
        if(this.progressBar){
        this.progressBar.Open("Grabbing File");       
        this.progressBar.StartPercentTimer();
        }
        this.Invoke(
            this.awsService.StartStepExecution(StepFunctions.MembershipPackageUpdateByCSVFile,
            null,null,null,encodeURIComponent(happycaseurlfile)),
            (result: AWSStartExecutionResponse) => {
              if(this.progressBar){
              this.progressBar.upload_percent_subject.next(100);
              this.progressBar.upload_status_subject.next("Upload Complete!");
              self.pgclose_subject.next(true);
              this.progressBar.close_subject.next(true);
              }

              this.describeSpinner.stepMachineIsRunning = true;
              this.describeSpinner.executionArn = result.executionArn;
              this.describeSpinner.StartDescriptionTimer(5);
            }
          );
        }      
    });
  }

  //FP-896
  handleBulkUploadResponse(result) {
    const self = this;
    if (result.Message == "uploaded successfully") {
      MessageBox.ShowInfo(
        this.dialog,
        "The upload was successful, and all records have been inserted."
      ).subscribe(dialogResult => {
        if (dialogResult.result.toLowerCase() === DialogResult.Ok) {
          window.location.reload();
        }
      });
      self.dtsf[self.indexfile].FormatUploadStatus = this.FormatStatus(
        "Upload Complete",
        "success"
      );
    } else {
      var errorMsg = result.Message;
      this.errorMsg =
        `<strong>${errorMsg}</strong>`;
      MessageBox.ShowError(this.dialog, this.errorMsg);
      self.dtsf[self.indexfile].FormatUploadStatus = this.FormatStatus(
        "Upload Fail",
        "Failure"
      );
    }
  }

  private CheckFilesType(file): boolean {
    if (file.name.split(".").pop() != "csv") {
      return false;
    } else {
      this.AddFileTotheList(file);
      this.dataSourceFile = new MatTableDataSource<FileImportModel>(this.dtsf);
      this.cdRef.markForCheck();
      return true;
    }
  }

  private AddFileTotheList(file) {
    for (let i = 0; i < this.dtsf.length; i++) {
      if (this.dtsf[i].Filename == file.name) {
        this.dtsf[i] = {
          Filename: file.name,
          UploadStatus: "Pending",
          Error: "",
          Location: "",
          FormatUploadStatus: this.FormatStatus(
            CommonString.IMPORT_PENDING_STATUS,
            "pending"
          ),
          ContentFile: [],
          ExtractContentFile: [],
          relcols: [],
          Errortype: null,
          ErrorPos: [],
          CsvContentError: "",
          CsvContent: "",
          Errors: [],
          FileObject: file,
        };
        return;
      }
    }
    this.dtsf.push({
      Filename: file.name,
      UploadStatus: "Pending",
      Error: "",
      Location: "",
      FormatUploadStatus: this.FormatStatus(
        CommonString.IMPORT_PENDING_STATUS,
        "pending"
      ),
      ContentFile: [],
      ExtractContentFile: [],
      relcols: [],
      Errortype: null,
      ErrorPos: [],
      CsvContentError: "",
      CsvContent: "",
      Errors: [],
      FileObject: file,
    });
  }

  private SetError(errors: string[], errortype: ErrorType[]) {
    this.dtsf[this.indexfile].Error = this.FormatError(errors);
    this.dtsf[this.indexfile].Errors = errors;
    this.dtsf[this.indexfile].UploadStatus = "Rejected";
    this.dtsf[this.indexfile].FormatUploadStatus = this.FormatStatus(
      "Upload Failed",
      "error"
    );
    this.dtsf[this.indexfile].Errortype = errortype;
  }

  private FormatStatus(text: string, type: string): SafeHtml {
    if (type == "success") {
      return this.sanitized.bypassSecurityTrustHtml(
        '<span style="color:green">' + text + "</span>"
      );
    } else if (type == "pending") {
      return this.sanitized.bypassSecurityTrustHtml(
        '<span style="color:blue">' + text + "</span>"
      );
    } else {
      return this.sanitized.bypassSecurityTrustHtml(
        '<span style="color:red">' + text + "</span>"
      );
    }
  }

  private FormatError(errors: string[]): SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(
      '<span style="color:red">' + errors.join("<br/>") + "</span>"
    );
  }

  private CheckFile() {
    if (this.dtsf.length > 0 && this.indexfile < this.dtsf.length) {
      this.IsS3Status = false;
      this.status = "Validating file: " + this.dtsf[this.indexfile].Filename;
      if (this.colvalidate.length == 0) {
        this.SetError(
          ["Cannot import because of missing validation"],
          [ErrorType.MissingValidation]
        );
      } else {
        if (this.dtsf[this.indexfile].UploadStatus != "Pending") {
          this.GoNextFile();
        } else {
          const fileReader = new FileReader();
          fileReader.readAsText(this.dtsf[this.indexfile].FileObject, "UTF-8");
          fileReader.onload = (e) => {
            const textFromFileLoaded: string = fileReader.result.toString();
            const rows: string[] = textFromFileLoaded
              .replace(/\r/g, "")
              .split(/\n/);
            this.dtsf[this.indexfile].ContentFile = rows;
            let goodtogo = this.CheckDataRow(rows);
            if (goodtogo) {
              goodtogo = this.CheckHeaderColumns(rows[0]);
              if (goodtogo) {
                goodtogo = this.CheckContent(rows);
                if (goodtogo) {
                  goodtogo = this.CheckUnique(rows);
                  if (goodtogo) {
                    this.CheckValueExistingInDB(rows);
                    //this.ExtractCorrectDataToFile();
                  } else {
                    // Error for Primary key and Max Length Issue.
                    this.ExtractErrorToFile();
                  }
                } else {
                  // Error for DataMissing and Max Length Issue.
                  this.ExtractErrorToFile();
                }
              } else {
                this.GoNextFile();
              }
            } else {
              this.GoNextFile();
            }
          };
        }
      }
    } else {
      // check all files,there is no file left.
      if (this.ShouldClosePopup()) {
        this.pgallclose_subject.next(true);
      }
    }
  }

  private GoNextFile() {
    this.indexfile++;
    this.CheckFile();
  }

  private CheckContent(rows: string[]): boolean {
    const relcols: RealColumn[] = this.dtsf[this.indexfile].relcols;
    let ret = true;
    let errorrownum = 0;
    const errtps: ErrorType[] = [];
    for (let i = 1; i < rows.length; i++) {
      const rowdata: string[] = this.CSVtoArray(rows[i]);
      if (rowdata == null) {
        ret = false;
        errtps.push(ErrorType.InvalidRow);
        errorrownum++;
      } else {
        for (let j = 0; j < relcols.length; j++) {
          if (rowdata.length == 0) {
            //if the row is empty, go to next row
            break;
          }
          if (
            this.colvalidate[j].required ||
            this.colvalidate[j].required == undefined
          ) {
            if (rowdata[relcols[j].index] == undefined) {
              this.dtsf[this.indexfile].ContentFile[i] =
                this.dtsf[this.indexfile].ContentFile[i] + ","; //Add missing column
              this.dtsf[this.indexfile].ErrorPos.push(
                new ErrorPosition(
                  i,
                  this.colvalidate[j].colname,
                  relcols[j].index,
                  ErrorType.MissingData,
                  ""
                )
              );
              errtps.push(ErrorType.MissingData);
              ret = false;
              errorrownum++;
            } else {
              const data = rowdata[relcols[j].index].trim();
              if (data == "") {
                this.dtsf[this.indexfile].ErrorPos.push(
                  new ErrorPosition(
                    i,
                    this.colvalidate[j].colname,
                    relcols[j].index,
                    ErrorType.MissingData,
                    ""
                  )
                );
                errtps.push(ErrorType.MissingData);
                ret = false;
                errorrownum++;
              } else {
                if (this.colvalidate[j].type == "number") {
                  if (isNaN(+rowdata[relcols[j].index])) {
                    //Putting a + in front of the string value returns the number representation of the object.
                    this.dtsf[this.indexfile].ErrorPos.push(
                      new ErrorPosition(
                        i,
                        this.colvalidate[j].colname,
                        relcols[j].index,
                        ErrorType.ErrorDataType,
                        rowdata[relcols[j].index]
                      )
                    );
                    errtps.push(ErrorType.ErrorDataType);
                    ret = false;
                    errorrownum++;
                  } else {
                    let val = rowdata[relcols[j].index];
                    if (val.indexOf(".") > 0) {
                      val = val.split(".")[0];
                      rowdata[relcols[j].index] = val;
                    }
                    const num = Number.parseInt(rowdata[relcols[j].index], 10);
                    if (num < 0) {
                      this.dtsf[this.indexfile].ErrorPos.push(
                        new ErrorPosition(
                          i,
                          this.colvalidate[j].colname,
                          relcols[j].index,
                          ErrorType.ErrorDataType,
                          rowdata[relcols[j].index]
                        )
                      );
                      errtps.push(ErrorType.ErrorDataType);
                      ret = false;
                      errorrownum++;
                    }
                  }
                }
                if (
                  rowdata[relcols[j].index].length >
                  this.colvalidate[j].maxlength
                ) {
                  this.dtsf[this.indexfile].ErrorPos.push(
                    new ErrorPosition(
                      i,
                      this.colvalidate[j].colname,
                      relcols[j].index,
                      ErrorType.MaxLengthIssue,
                      this.colvalidate[j].maxlength.toString()
                    )
                  );
                  errtps.push(ErrorType.MaxLengthIssue);
                  ret = false;
                }
              }
            }
          }
        }
      }
    }
    if (ret == false) {
      //this.dtsf[this.indexfile].Errors.push('Found ' + errorrownum.toString() + ' rows errors');
      this.dtsf[this.indexfile].Errortype = errtps;
    }
    return ret;
  }

  private CheckUnique(rows: string[]): boolean {
    const relcols: RealColumn[] = this.dtsf[this.indexfile].relcols;
    let ret = true;
    let primarykeyindex = 0;
    const errtps: ErrorType[] = [];
    const uniquearray: string[] = [];

    for (let j = 0; j < relcols.length; j++) {
      if (this.colvalidate[j].primarykey) {
        for (let i = 1; i < rows.length; i++) {
          const rowdata: string[] = this.CSVtoArray(rows[i]);
          if (rowdata.length > 0) {
            const data = rowdata[relcols[j].index].trim();
            uniquearray.push(data);
          }
        }
        primarykeyindex = j;
        break;
      }
    }

    for (let i = 0; i < uniquearray.length - 1; i++) {
      for (let j = i + 1; j < uniquearray.length; j++) {
        if (uniquearray[i] == uniquearray[j]) {
          this.dtsf[this.indexfile].ErrorPos.push(
            new ErrorPosition(
              i + 1,
              this.colvalidate[primarykeyindex].colname,
              relcols[primarykeyindex].index,
              ErrorType.DuplicateKey,
              uniquearray[i]
            )
          );
          errtps.push(ErrorType.DuplicateKey);
          ret = false;
          break;
        }
      }
    }

    if (ret == false) {
      this.dtsf[this.indexfile].Errortype = errtps;
    }
    return ret;
  }

  private CheckValueExistingInDB(rows: string[]) {
    const relcols: RealColumn[] = this.dtsf[this.indexfile].relcols;
    this.lstColIndexCheckExisting = [];
    for (let j = 0; j < relcols.length; j++) {
      let evc: ExistingValidatorColumnModel;
      if (this.colvalidate[j].valueexisting != null) {
        evc = this.colvalidate[j].valueexisting;
        evc.ListValues = [];
        evc.ColumnType = this.colvalidate[j].type;
        evc.DisplayColumName = this.colvalidate[j].colname;
        for (let i = 1; i < rows.length; i++) {
          const rowdata: string[] = this.CSVtoArray(rows[i]);
          if (rowdata.length > 0) {
            const data = rowdata[relcols[j].index].trim();
            const evm: ExistingValidatorModel = new ExistingValidatorModel();
            evm.RowNum = i;
            evm.RowVal = data;
            evm.IsExisting = false;
            evc.ListValues.push(evm);
          }
        }
        this.colvalidate[j].valueexisting = evc;
        this.lstColIndexCheckExisting.push(j);
      }
    }
    if (this.lstColIndexCheckExisting.length > 0) {
      this.CallAPItoValidateExisting(0, rows);
    }
  }

  private CallAPItoValidateExisting(index: number, rows: string[]) {
    if (this.lstColIndexCheckExisting[index] != undefined) {
      const columnindex = this.lstColIndexCheckExisting[index];
      let evc: ExistingValidatorColumnModel = this.colvalidate[columnindex]
        .valueexisting;
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.httpdao!.postData(
              APIConstant.API_PRIMARY_KEY_CHECK,
              evc
            );
          }),
          map((result) => {
            return result;
          }),
          catchError(() => {
            return observableOf([]);
          })
        )
        .subscribe((result) => {
          if (result.Success) {
            evc = result.Data;
            this.AddErrorForPrimaryKey(evc, columnindex);
            index++;

            this.CallAPItoValidateExisting(index, rows);
          } else {
            MessageBox.ShowError(this.dialog, "Cannot Check Primary key");
          }
        });
    } else {
      if (index >= this.lstColIndexCheckExisting.length) {
        if (
          this.dtsf[this.indexfile].Errortype != null &&
          this.dtsf[this.indexfile].Errortype.length > 0
        ) {
          this.ExtractErrorToFile();
        } else {
          this.CheckPkFkRelation(rows);
        }
      }
    }
  }

  private AddErrorForPrimaryKey(
    evc: ExistingValidatorColumnModel,
    columnindex: number
  ) {
    const relcols: RealColumn[] = this.dtsf[this.indexfile].relcols;
    const errtps: ErrorType[] = [];
    for (let i = 0; i < evc.ListValues.length; i++) {
      const pkvm: ExistingValidatorModel = evc.ListValues[i];
      if (pkvm.IsExisting === false) {
        this.dtsf[this.indexfile].ErrorPos.push(
          new ErrorPosition(
            i + 1,
            evc.DisplayColumName,
            relcols[columnindex].index,
            ErrorType.ValueNotExistingInDB,
            pkvm.RowVal
          )
        );
        errtps.push(ErrorType.ValueNotExistingInDB);
        this.dtsf[this.indexfile].Errortype = errtps;
      }
    }
  }

  private CheckPkFkRelation(rows: string[]) {
    const relcols: RealColumn[] = this.dtsf[this.indexfile].relcols;
    this.lstColIndexCheckPkFkRelation = [];
    for (let j = 0; j < relcols.length; j++) {
      let pvc: PkFkValidatorColumnModel;
      if (this.colvalidate[j].pkfkrelation != null) {
        pvc = this.colvalidate[j].pkfkrelation;
        pvc.ListValues = [];
        pvc.ColumnType = this.colvalidate[j].type;
        pvc.DisplayColumName = this.colvalidate[j].colname;
        for (let i = 1; i < rows.length; i++) {
          const rowdata: string[] = this.CSVtoArray(rows[i]);
          if (rowdata.length > 0) {
            const datapk = rowdata[relcols[j].index].trim();
            const datafk = rowdata[relcols[pvc.FkColumnIndex].index].trim();
            const evm: PkFkValidatorViewModel = new PkFkValidatorViewModel();
            evm.PkFkRowNum = i;
            evm.PkRowVal = datapk;
            evm.FkRowVal = datafk;
            evm.IsExisting = false;
            pvc.ListValues.push(evm);
          }
        }
        this.colvalidate[j].pkfkrelation = pvc;
        this.lstColIndexCheckPkFkRelation.push(j);
      }
    }
    if (this.lstColIndexCheckPkFkRelation.length > 0) {
      this.CallAPItoValidatePkFkRelation(0);
    }
  }

  private CallAPItoValidatePkFkRelation(index: number) {
    if (this.lstColIndexCheckPkFkRelation[index] != undefined) {
      const columnindex = this.lstColIndexCheckPkFkRelation[index];
      let pvc: PkFkValidatorColumnModel = this.colvalidate[columnindex]
        .pkfkrelation;
      merge()
        .pipe(
          startWith({}),
          switchMap(() => {
            return this.httpdao!.postData(
              APIConstant.API_PRIMARY_PK_FK_RELATION,
              pvc
            );
          }),
          map((result) => {
            return result;
          }),
          catchError(() => {
            return observableOf([]);
          })
        )
        .subscribe((result) => {
          if (result.Success) {
            pvc = result.Data;
            this.AddErrorForPkFkRelation(pvc, columnindex);
            index++;

            this.CallAPItoValidatePkFkRelation(index);
          } else {
            MessageBox.ShowError(
              this.dialog,
              "Cannot Check Primary key Foreign key Relation"
            );
          }
        });
    } else {
      if (index >= this.lstColIndexCheckPkFkRelation.length) {
        if (
          this.dtsf[this.indexfile].Errortype != null &&
          this.dtsf[this.indexfile].Errortype.length > 0
        ) {
          this.ExtractErrorToFile();
        } else {
          this.ExtractCorrectDataToFile();
        }
      }
    }
  }

  private AddErrorForPkFkRelation(
    pvc: PkFkValidatorColumnModel,
    columnindex: number
  ) {
    const relcols: RealColumn[] = this.dtsf[this.indexfile].relcols;
    const errtps: ErrorType[] = [];
    for (let i = 0; i < pvc.ListValues.length; i++) {
      const pfvvm: PkFkValidatorViewModel = pvc.ListValues[i];
      if (pfvvm.IsExisting === false) {
        this.dtsf[this.indexfile].ErrorPos.push(
          new ErrorPosition(
            i + 1,
            pvc.DisplayColumName,
            relcols[columnindex].index,
            ErrorType.NoReleation,
            pvc.FkColumnName
          )
        );
        errtps.push(ErrorType.NoReleation);
        this.dtsf[this.indexfile].Errortype = errtps;
      }
    }
  }

  public DisplayErrorDetail(ele: FileImportModel) {
    const encodedUri = encodeURI(ele.CsvContentError);
    const link = this.renderer.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Error-" + ele.Filename);
    link.click();
  }

  private ExtractErrorToFile() {
    const fim = this.dtsf[this.indexfile];
    const errtp: ErrorType[] = fim.Errortype;
    if (
      errtp.indexOf(ErrorType.MissingData) > -1 ||
      errtp.indexOf(ErrorType.MaxLengthIssue) > -1 ||
      errtp.indexOf(ErrorType.InvalidRow) > -1 ||
      errtp.indexOf(ErrorType.ErrorDataType) > -1 ||
      errtp.indexOf(ErrorType.DuplicateKey) > -1 ||
      errtp.indexOf(ErrorType.ValueNotExistingInDB) > -1 ||
      errtp.indexOf(ErrorType.NoReleation) > -1
    ) {
      fim.ContentFile[0] = fim.ContentFile[0] + ",Errors"; //Add Error Detail Column
      for (let r = 1; r < fim.ContentFile.length; r++) {
        if (fim.ContentFile[r].trim() != "") {
          fim.ContentFile[r] = fim.ContentFile[r] + ",";
          const errors = [];
          for (let er = 0; er < fim.ErrorPos.length; er++) {
            if (
              r == fim.ErrorPos[er].rownum &&
              fim.ErrorPos[er].errtype == ErrorType.MissingData
            ) {
              // found the error line number of missing data
              errors.push("[" + fim.ErrorPos[er].colname + "] is missing"); //Add Error Detail
            } else if (
              r == fim.ErrorPos[er].rownum &&
              fim.ErrorPos[er].errtype == ErrorType.MaxLengthIssue
            ) {
              // found the error line number of max length
              errors.push(
                "[" +
                  fim.ErrorPos[er].colname +
                  "] only accepts max length of " +
                  fim.ErrorPos[er].addinfo +
                  " digits"
              ); //Add Error Detail
            } else if (
              r == fim.ErrorPos[er].rownum &&
              fim.ErrorPos[er].errtype == ErrorType.ErrorDataType
            ) {
              // found the error line number of error data type
              errors.push(
                "[" +
                  fim.ErrorPos[er].colname +
                  "] can only be a value which is equal or greater than zero"
              );
            } else if (
              r == fim.ErrorPos[er].rownum &&
              fim.ErrorPos[er].errtype == ErrorType.DuplicateKey
            ) {
              // found the error line number of error duplicate key
              errors.push(
                "[" +
                  fim.ErrorPos[er].colname +
                  "] is present in more than one row has value: " +
                  fim.ErrorPos[er].addinfo
              );
            } else if (
              r == fim.ErrorPos[er].rownum &&
              fim.ErrorPos[er].errtype == ErrorType.ValueNotExistingInDB
            ) {
              // found the error line number of error Primary key not existing
              errors.push(
                "[" +
                  fim.ErrorPos[er].colname +
                  "] value " +
                  fim.ErrorPos[er].addinfo +
                  " does not exist in system"
              );
            } else if (
              r == fim.ErrorPos[er].rownum &&
              fim.ErrorPos[er].errtype == ErrorType.NoReleation
            ) {
              // found the error line number of error Primary key not existing
              errors.push(
                "There is no relation between [" +
                  fim.ErrorPos[er].colname +
                  "] and [" +
                  fim.ErrorPos[er].addinfo +
                  "]"
              );
            }
          }
          if (errtp.indexOf(ErrorType.InvalidRow) > -1) {
            // found the error line number of invalid row data
            errors.push(
              '*This row is invalid. It might contains double quotation marks e.g. "" '
            );
          }
          fim.ContentFile[r] += '"' + errors.join(", ") + '"';
        }
      }
      const csvContent = fim.ContentFile.join("\n");
      fim.CsvContentError = csvContent;
      const contentType = "text/csv";
      const csvFile = new Blob([fim.CsvContentError], { type: contentType });
      this.UploadtoS3(csvFile, true);
    }
  }

  private ExtractCorrectDataToFile() {
    const cf: string[] = this.dtsf[this.indexfile].ContentFile;
    for (let r = 0; r < cf.length; r++) {
      const rarr: string[] = this.CSVtoArray(cf[r]);
      const colrels: RealColumn[] = this.dtsf[this.indexfile].relcols;
      const row: string[] = [];
      for (let j = 0; j < colrels.length; j++) {
        if (rarr[colrels[j].index]) {
          row.push('"' + rarr[colrels[j].index] + '"');
        }
      }
      this.dtsf[this.indexfile].ExtractContentFile.push(row.join(","));
    }
    this.dtsf[this.indexfile].CsvContent = this.dtsf[
      this.indexfile
    ].ExtractContentFile.join("\n");
    const contentType = "text/csv";

    const csvFile = new Blob([this.dtsf[this.indexfile].CsvContent], {
      type: contentType,
    });
    this.UploadtoS3(csvFile, false);
  }

  private CheckDataRow(rows: string[]): boolean {
    this.pg_subject_status.next(
      "Checking File: " + this.dtsf[this.indexfile].Filename
    );
    if (rows.length > 0) {
      return true;
    } else {
      this.SetError(
        ["The csv file does not contain any data row"],
        [ErrorType.FileEmpty]
      );
      return false;
    }
  }

  private CheckHeaderColumns(headers: string): boolean {
    const allarr: string[] = headers.split(/\r|\n|\r/);
    const relcol: RealColumn[] = [];
    for (let i = 0; i < this.colvalidate.length; i++) {
      relcol.push({ colname: this.colvalidate[i].colname, index: -1 });
    }
    if (allarr.length > 0) {
      const cols: string[] = this.CSVtoArray(allarr[0]);

      for (let i = 0; i < this.colvalidate.length; i++) {
        for (let j = 0; j < cols.length; j++) {
          if (this.colvalidate[i].colname == cols[j]) {
            relcol[i].index = j;
            break;
          }
        }
      }

      let ret = true;
      const lstmisscol: string[] = [];
      for (let i = 0; i < relcol.length; i++) {
        if (relcol[i].index == -1) {
          lstmisscol.push(
            'The column <strong> "' +
              relcol[i].colname +
              '"</strong> is missing from the file'
          );
          ret = false;
        }
      }
      if (ret == false) {
        this.SetError([lstmisscol.join("<br/>")], [ErrorType.MissingHeader]);
      } else {
        this.dtsf[this.indexfile].relcols = relcol;
      }
      return ret;
    } else {
      this.SetError(
        ["The csv file does not contain any column"],
        [ErrorType.MissingHeader]
      );
      return false;
    }
  }

  private CSVtoArray(text: string): string[] {
    text = text.replace(/\'/g, "").replace(/\"\"/g, "");
    const re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
    const re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;

    if (!re_valid.test(text)) {
      return null;
    }
    const arr: string[] = [];
    text.replace(re_value, function (m0, m1, m2, m3) {
      // Remove backslash from \' in single quoted values.
      if (m1 !== undefined) {
        // tslint:disable-next-line:quotemark
        arr.push(m1.replace(/\\'/g, "'"));
      } else if (m2 !== undefined) {
        arr.push(m2.replace(/\\"/g, '"'));
      } else if (m3 !== undefined) {
        arr.push(m3);
      }
      return "";
    });

    if (/,\s*$/.test(text)) {
      arr.push("");
    }
    return arr;
  }

  public IsHavingPendingFile(): boolean {
    if (this.dtsf.length == 0) {
      return false;
    } else {
      for (let i = 0; i < this.dtsf.length; i++) {
        if (this.dtsf[i].UploadStatus == "Pending") {
          return true;
        }
      }
    }

    return false;
  }

  public Open() {
    this.openModal = this.modalService.open(this.popupcontent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
    this.openModal.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  private Close() {
    if (this.openModal) {
      this.openModal.close();
    }
  }

  private ResetConfig() {
    this.percent = 0;
    this.IsUploadCompleted = false;
  }

  private ResetAllConfig() {
    this.indexfile = 0;
    this.percent = 0;
    this.IsUploadCompleted = false;
    this.ResetFileInputValue();
  }

  public ResetFileInputValue() {
    this.fpfileupload.nativeElement.value = "";
  }
}
