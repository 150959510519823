<div [formGroup]="form">
    <ng-template #requiredFieldMsgTmpl let-ctrl="control" let-ctrlName="fieldName"
        let-validateOnTouch="validateOnTouch || false">
        <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
            <small *ngIf="ctrl.errors['required']" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_REQUIRED, ctrlName)}}</small>
            <small *ngIf="ctrl.getError('pattern')"  class="invalid-feedback d-block">This field does not accept
                special characters. Please try again</small>
        </div>
    </ng-template>
    <div class="row">
        <div class="col-md-7">
            <div class="form-group row">
                <label for="txtFstName" class="col-md-3 col-form-label">First Name<span class="text-danger">*</span>
                </label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtFstName" formControlName="FirstName"
                            maxlength="50" required [class.is-invalid]="!isControlValid('FirstName')"
                            [readonly]='!IsAdmin()' autocomplete="nope">
                        <ng-container
                            *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('FirstName'), fieldName: 'First Name'}">
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="form-group row" #divMiddleName>
                <label for="txtMidleName" class="col-md-3 col-form-label">Middle Name</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtMidleName" formControlName="MiddleName"
                            maxlength="50" [readonly]='!IsAdmin()' autocomplete="nope">
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="txtLstName" class="col-md-3 col-form-label">Last Name<span class="text-danger">*</span>
                </label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtLstName" formControlName="LastName"
                            maxlength="50" required [class.is-invalid]="!isControlValid('LastName')"
                            [readonly]='!IsAdmin()' autocomplete="nope">
                        <ng-container
                            *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('LastName'), fieldName: 'Last Name'}">
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="ddlGender" class="col-md-3 col-form-label">Gender<span class="text-danger">*</span>
                </label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <select class="form-control" [class.is-invalid]="!isControlValid(GenderCtrl, true)"
                            id="ddlGender" formControlName="GenderId">
                            <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                            <option *ngFor="let gender of genders" [ngValue]="gender.GenderId">{{gender.Name}}</option>
                        </select>
                        <ng-container
                            *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: GenderCtrl, fieldName: 'Gender', validateOnTouch: true}">
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 col-form-label">Date of Birth<span class="text-danger">*</span>
                </label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <div class="input-group date-input-group" [class.is-invalid]="!isControlValid('DateOfBirth')"
                            [ngClass]="getClassFocused(1)">
                            <input class="form-control" placeholder="DD-MM-YYYY" name="dpDateOfBirth"
                                formControlName="DateOfBirth" required ngbDatepicker #dpDOB="ngbDatepicker"
                                (dateSelect)="onDOBSelected()" (keyup)="onDOBSelected()" (blur)="onBlurParent(0)"
                                (focus)="onFocusParent(1)">
                            <div class="input-group-append">
                                <button class="btn bg-white" (click)="dpDOB.toggle()" type="button">
                                    <i class="far fa-calendar-alt"></i>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="!isControlValid('DateOfBirth')">
                            <small *ngIf="form.get('DateOfBirth').errors['required']" class="invalid-feedback d-block">
                                {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Date of Birth')}}</small>
                            <small
                                *ngIf="form.get('DateOfBirth').getError('ngbDate')?.invalid || form.get('DateOfBirth').getError('ngbDate')?.requiredBefore || form.get('DateOfBirth').getError('ngbDate')?.requiredAfter"
                                class="invalid-feedback d-block">
                                {{StringHelper.format(CommonString.FIELD_INVALID, 'Date of Birth')}}</small>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Member Status -->
            <div class="form-group row" #divStatus>
                <label for="ddlStatus" class="col-md-3 col-form-label">Member Status</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <select class="form-control" [class.is-invalid]="!isControlValid('MemberStatusId', true)"
                            id="ddlStatus" formControlName="MemberStatusId" (change)="changeMemberStatus()">
                            <option *ngFor="let status of statuses" [ngValue]="status.Id"
                                [disabled]="memberStatusDisabled(status)">
                                {{status.Name}}</option>
                        </select>
                    </div>
                    <a *ngIf="checkShowBan()" class="px-2" role="button" (click)="inactivateOnButton()">
                        <i class="fas fa-ban text-danger"></i>
                    </a>
                </div>
            </div>
            <!-- Membership Status -->
            <div class="form-group row" #divMembershipStatus>
                <label for="ddlStatus" class="col-md-3 col-form-label">Membership Status</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtMembershipStatus"
                            [value]="membershipStatus===undefined?'':membershipStatus" readonly>
                    </div>
                </div>
            </div>
            <!-- Member Type -->
            <div class="form-group row">
                <label for="txtMemberType" class="col-md-3 col-form-label">Member Type</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtMemberType" [value]="GetMemberTypeName()"
                            readonly>
                    </div>
                </div>
            </div>
            <!-- Member Number -->
            <div class="form-group row">
                <label for="txtMemberNumber" class="col-md-3 col-form-label">Member Number</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtMemberNumber" [value]="GetMemberNumber()"
                            readonly>
                    </div>
                </div>
            </div>
            <!-- Member ID -->
            <div class="form-group row">
                <label for="txtMemberID" class="col-md-3 col-form-label">Member ID</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtMemberID" [value]="GetMemberID()" readonly>
                    </div>
                </div>
            </div>
        </div>
        <div class=" col-md-5">
            <div class="fp-profile d-flex justify-content-center">
                <div class="w-230px">
                    <div *ngIf="form.get('Photo').value == null || form.get('Photo').value?.Location == null || form.get('Photo').value?.Location == ''; else hasImage"
                        class="bg-secondary fp-profilephoto d-flex justify-content-center align-items-center mx-auto">
                        <i class="fas fa-user-tie fa-2x text-secondary"></i>
                    </div>
                    <ng-template #hasImage>
                        <div class="container">
                            <app-member-image [imgSrc]="this.imgUrl? this.imgUrl : form.get('Photo').value?.Location"
                                [htmlClass]="'img-fluid'">
                            </app-member-image>
                            <!-- <div class="overlay">
                                <a class="cursordisplay position-absolute position-topright" title="Remove logo" (click)="_removePhoto($event)">
                                    <i class="text-danger fas fa-times fa-2x-ex"></i>
                                </a>
                            </div> -->
                        </div>
                    </ng-template>
                    <div class="custom-file mt-2" #divPhotoUploader>
                        <div>
                            <button class="custom-file-input" (click)="checkFirst($event)"
                             #fpfileupload style="height: 100% !important"></button>
                            <label class="custom-file-label text-light" [ngStyle]="{ 'background-color': '#FF0000' }"><i
                                    class="fas fa-upload mr-2"></i>Upload Profile Photo</label>
                        </div>
                        <!-- <app-fp-file-upload [memberPhoto]="true" class="customButtonColor"
                            [buttonStyle]="{ 'background-color': '#FF0000' }" buttonlabel="Upload Profile Photo"
                            filterFile=".png, .jpg, .jpeg" (UploadCompleted)="_fileUploaded($event)" maxfilesize="10"
                            (fileSelected)="_fileSelected($event)" ngInputFile="custom-file w-100" #fileUploader>
                        </app-fp-file-upload> -->
                    </div>
                    <div *ngIf="checkMemberPhoto">
                        <div class="px-1 px-lg-1 py-1">
                        <button class="px-5 px-lg-5 py-2 btn btn-primary text-nowrap" type="button" (click)="OnRemovePhoto()">
                            <i class="fas fa-check-circle mr-3"></i>
                            Remove Photo</button>
                        </div>
                    </div>
                    <div *ngIf="checkMemberPhotoForRole">
                        <!-- <app-fp-file-upload [memberPhoto]="true" class="customButtonColor"
                        [buttonStyle]="{ 'background-color': '#FF0000' }" buttonlabel="Upload Profile Photo"
                        filterFile=".png, .jpg, .jpeg" (UploadCompleted)="_fileUploaded($event)" maxfilesize="10"
                        (fileSelected)="_fileSelected($event)" ngInputFile="custom-file w-100" #fileUploader>
                    </app-fp-file-upload> -->
                    <div class="px-1 px-lg-1 py-1">
                        <button class="px-5 px-lg-5 py-2 btn btn-primary text-nowrap" type="button" (click)=" OnSelectedUploadPhoto(element)">
                            <i class="fas fa-check-circle mr-3"></i>
                            Upload Photo</button>
                        </div>
                         <!-- <label class="custom-file-label text-light" [ngStyle]="{ 'background-color': '#FF0000' }"><i
                                    class="fas fa-upload mr-3"></i>Upload Profile Photo</label> -->
                    </div>
                    <p class="pt-2 m-0 text-center">{{CommonMessage.PROFILE_PHOTO_UPLOAD_CAPTION}}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" #dlgOrderNewCard tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <ng-template #dlgOrderNewCardContent let-modal>
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Order Membership Card Fee</h5>
        </div>
        <div class="modal-body py-4">
          <form>
            <div class="form-group row">
              <label for="ddlReasonOrderCard" class="col-md-3 col-lg-4 col-form-label">Select Reason for Card
                Replacement<span class="text-danger">*</span></label>
              <div class="col-md-9 col-lg-8">
                <div class="d-flex flex-column w-100">
                  <select class="form-control" [class.is-invalid]="isInvalidReasonDropdown()"
                    [formControl]="ddlReasonOrderCardControl" id="ddlReasonOrderCard">
                    <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let reason of memberCardOrderReasonData" [ngValue]="reason.Id">
                      {{reason.Name}}</option>
                  </select>
                  <div *ngIf="isInvalidReasonDropdown()">
                    <small *ngIf="ddlReasonOrderCardControl.hasError('required')" class="invalid-feedback d-block">
                      {{StringHelper.format(commonString.FIELD_REQUIRED, 'Reason for Card Replacement')}}
                    </small></div>
                </div>
              </div>
            </div>
          </form>
          <div class="py-4 d-flex justify-content-center">
            <button id="member_ms_cards_newcard_btn_close"
              class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button" (click)="cancelBox()">
              Cancel</button>
            <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="submit()">
              Submit</button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>