import { AgmInfoWindow, MapsAPILoader, AgmMap } from "@agm/core";
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, OnDestroy, HostBinding, Optional, Self, ViewChild, OnInit, Injector } from '@angular/core';
import { FormBuilder, NgControl, ControlValueAccessor, FormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { SuburbSearchResultRecord, SurveyTemplateQuestion } from "@fp/models";
import { SuburbService} from "@fp/services";
import { MapFacilityData } from "@fp/models/map-facility-data";
import { LatLngLiteral } from "@agm/core";
import { debounceTime, distinctUntilChanged} from 'rxjs/operators';


import {
  FPBaseComponent,
  IFPComponentData,
} from "@fp/components/base";
import { ActivityConstant } from "@fp/constant/activity-constants";
import { Utils } from "@fp/helpers/utils";
import { CommonString } from "@fp/constant/common-string";
import { StringHelper } from "@fp/helpers/string-helper";

declare const google: any;

interface Location {
  lat: number;
  lng: number;
  zoom: number;
}

interface Marker extends LatLngLiteral {
  added: boolean;
  data : MapFacilityData;
}

interface PreSetSuburb extends SuburbSearchResultRecord {
  Label?: string
}

@Component({
  selector: 'facility-picker',
  templateUrl: 'facility-picker.component.html',
  styleUrls: [ 'facility-picker.component.css' ],
  providers: [ { provide: MatFormFieldControl, useExisting: FacilityPickerComponent } ]
  })
export class FacilityPickerComponent extends FPBaseComponent<IFPComponentData> implements MatFormFieldControl<any>, ControlValueAccessor, OnInit, OnDestroy {
  
  geocoder: any;
  stateChanges = new Subject<void>();
  Utils = Utils;

  @Input() suburbList: SuburbSearchResultRecord[];
  @Input() facilityList: Array<MapFacilityData> = [];
  @Input() maxValues:number = 3;
  @Input() minValues:number = 0; // 0 disabled, >0 threshold
  @Input() facilitySelectionQuestion: SurveyTemplateQuestion;
  @Input() mapHomeLocation: Location = {
    lat: -23.698,
    lng: 133.8807,
    zoom: 4,
  };

  public allMarkers: Array<Marker> = [];
  public inviewMarkers: Array<Marker> = [];
  public inviewFilteredMarkers: Array<Marker> = [];
  public maxLimitReached:boolean = false;
  public inviewListEmpty:boolean = false;
  showFacilityNameRequiredMessage:boolean = false;
  showFacilityEelectionErrorMessage:boolean = false;
  CommonString = CommonString;
  StringHelper = StringHelper;
  previosNonlistedFacility: any;
  
  iconUrl = ActivityConstant.DEFAULT_ICONURL;
  

  nonlistedSuburb = new FormControl();
  mapSuburb = new FormControl();
  nonListedFacilityNameInput = new FormControl('');
  filterByName = new FormControl('');

  private previousInfoWindow: AgmInfoWindow = null;

  mapInviewItems: Array<MapFacilityData> = []

  @ViewChild("map") private map: AgmMap;

  private _value: {
    NominatedFacilities: any
    NonlistedFacility: {
      Suburb : {
        SuburbId
      },
      FacilityName: string | null
    }
  } = {
    NominatedFacilities: [],
    NonlistedFacility: {
      Suburb: null,
      FacilityName: null
    }
  };


  NominatedFacilities: any = [];
  NonlistedFacility: {
    Suburb : {
      SuburbId : string | null
    },
    FacilityName: string | null
  } = { Suburb: null, FacilityName: null}
  MapSuburbs: SuburbSearchResultRecord[];
  @Input() presetSuburbs: PreSetSuburb[];
  selectedMarker: Marker;
  showInfoWindow: boolean = false;

  @Input()
  get value(): any {
    return this._value;
  }
  set value(newValue: any) {
    if (newValue && newValue !== this._value) {
      this._value = newValue;
      this._onChange(newValue);
      if (this.ngControl.control){
        this.handleValidation(this._value);
      }
    }
  }

  constructor(
    injector: Injector,
    formBuilder: FormBuilder,
    private focusMonitor: FocusMonitor, 
    private elementRef: ElementRef,
    private suburbSvc: SuburbService,
    public mapsApiLoader: MapsAPILoader, // private zone: NgZone
    @Optional() @Self() public ngControl: NgControl,
  ) {
    super(injector);

    this.mapsApiLoader = mapsApiLoader;
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
    
    if (this.ngControl) { 
      this.ngControl.valueAccessor = this; 
    }

    focusMonitor.monitor(elementRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });


  this.filterByName.valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
    )
    .subscribe(filterTerm => {
      this.mapSuburb.setValue(null)
      if (filterTerm.length > 2){
        this.mapSuburb.setValue(null)
        this.inviewFilteredMarkers = this.inviewMarkers.filter(
          (x) =>
            x.data.Name.toLowerCase()
              .trim()
              .includes(
                filterTerm.toLowerCase().trim()
              )
          );
      }
      else {
        this.inviewFilteredMarkers = this.inviewMarkers
      }
    })

    this.mapSuburb.valueChanges.pipe(
      distinctUntilChanged(),
    )
    .subscribe(v => {
        this.filterByName.setValue("");
        if (v) {
          this.zoomToSuburb(`${v.Name} ${v.Postcode.Name} ${v.State.Name} ${v.Country.Name}`)
        }
      }
    );    

    this.nonlistedSuburb.valueChanges.pipe(
      distinctUntilChanged(),
    )
    .subscribe(value => {
      if (value) {
        this.NonlistedFacility.Suburb = {
            SuburbId : value.Id
        }
        this._value.NonlistedFacility = this.NonlistedFacility
      }
      else {
        this._value.NonlistedFacility = null
      }
      this._onChange(this._value)
      this.handleValidation(this._value)
      }
    );

    this.nonListedFacilityNameInput.valueChanges
    .pipe(
      debounceTime(300),
      distinctUntilChanged(),
    )
      .subscribe(value => {
        if (value != null) {
          this.NonlistedFacility.FacilityName = value;
          if(!this._value.NonlistedFacility){
            this._value.NonlistedFacility = {
              Suburb: null,
              FacilityName: null
            }
          }
          this._value.NonlistedFacility.FacilityName = this.NonlistedFacility.FacilityName
        }
        this._onChange(this._value)
        this.handleValidation(this._value)
      }
      );
  }

  focused = false;

  get errorState(): boolean {
    return !!this.ngControl.errors;
  }

  controlType = 'facility-picker';

  static nextId = 0;
  @HostBinding() id = `${this.controlType}-${FacilityPickerComponent.nextId++}`;

  get empty() {
    return this.NominatedFacilities.length
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _placeholder: string;
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(newPlaceholder) {
    this._placeholder = newPlaceholder;
    this.stateChanges.next();
  }

  _required = false;
  @Input()
  get required() {
    return this._required;
  }
  set required(newRequired) {
    this._required = coerceBooleanProperty(newRequired);
    this.stateChanges.next();
  }

  private _disabled = false;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(newDisabled) {
    this._disabled = coerceBooleanProperty(newDisabled);
    this.stateChanges.next();
  }
  
  @HostBinding('attr.aria-describedby') describedBy = '';
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).id !== 'internalMatselect') {
      this.elementRef.nativeElement.querySelector('#internalMatselect').focus();
    }
  }

  ngOnInit(): void {
    if (!(this.minValues == 0) && this.ngControl.control){
      this.ngControl.control.setErrors({'required':`Please select at least ${this.minValues} ${this.minValues > 1 ? 'facilities' : 'facility'}`})
    }  
    this.allMarkers = this.facilityList.map(f => ({
      lat: Number(f.Address.Latitude),
      lng: Number(f.Address.Longitude),
      data: f
    } as Marker))
    this.inviewFilteredMarkers = this.allMarkers
    let facilityNameFilter = document.getElementById('facilityNameFilter');
    if (facilityNameFilter) {
      facilityNameFilter.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
          event.preventDefault();
        }
      });
    }
  }

  updateMarkers(newBounds) {
    this.filterByName.setValue("");
    this.inviewMarkers = this.allMarkers.filter(
      marker => newBounds.contains(marker)
    );
    this.inviewFilteredMarkers = this.inviewMarkers
    this.inviewListEmpty = this.inviewFilteredMarkers.length == 0
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  // ControlValueAccessor
  writeValue(newValue: any): void {
    this.value = newValue;
  }
  
  _onChange: (value: any) => void = () => {};
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  // not called!
  _onTouched = () => {};
  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }

  clearSelection(i:number, fid: number) {
    let index = this.NominatedFacilities.findIndex(facility => facility.Id === fid);
    if (index !== -1) {
        this.NominatedFacilities.splice(index, 1);
        this._value.NominatedFacilities = this.NominatedFacilities;
    }
    this._onChange(this._value);
    this.maxLimitReached = this.NominatedFacilities.length === this.maxValues;

    if(!this.maxLimitReached && this.previosNonlistedFacility){
      this._value.NonlistedFacility = this.previosNonlistedFacility;
      this._onChange(this._value);
    }
    this.handleValidation(this._value);
  }

  isFacilitySelected(facilityId: number): boolean {
    return this.NominatedFacilities.some(facility => facility.Id === facilityId);
  }

  addToSelection(m:Marker){

    if(this.isFacilitySelected(m.data.FacilityId)){
      return;
    }
    m.added = true;
    const f = m.data;
    this.NominatedFacilities.push({
      Id: f.FacilityId,
      FacilityName: f.Name,
      Location: `${f.Suburb.Name}, ${f.State.Name}`
    })
    this._value.NominatedFacilities = this.NominatedFacilities
    this.maxLimitReached = this.NominatedFacilities.length == this.maxValues
    this._onChange(this._value);
    this.handleValidation(this._value);
  }

  clearNoListedFacilities(){
    this.previosNonlistedFacility = this._value.NonlistedFacility;
    this._value.NonlistedFacility = null;
    this._onChange(this._value);
  }

  handleValidation(v){
    this.showFacilityEelectionErrorMessage = false;
    const nominatedCount = v.NominatedFacilities?.length || 0;
    const hasNonListed = v.NonlistedFacility?.FacilityName && v.NonlistedFacility?.Suburb;

    if (nominatedCount > 0 && (nominatedCount < this.minValues)) {
      this.showFacilityEelectionErrorMessage = true;
    }

    if ( nominatedCount == this.maxValues){
      this.ngControl.control.setErrors(null);
      this.clearNoListedFacilities();
    }
    else if (nominatedCount < this.minValues) {
      this.ngControl.control.setErrors({'required':`Please select at least ${this.minValues} ${this.minValues > 1 ? 'facilities' : 'facility'}`})
    }
    if (nominatedCount >= this.minValues && nominatedCount < this.maxValues && hasNonListed){
      this.ngControl.control.setErrors(null)
    }
    if (nominatedCount >= this.minValues && nominatedCount < this.maxValues && !hasNonListed){
      this.ngControl.control.setErrors({'required':`Please select another facility or enter the details of a facility that is not listed here.`})
    }

    if (this.ngControl.control.invalid && this.nonListedFacilityNameInput && this.nonListedFacilityNameInput.dirty && this.nonListedFacilityNameInput.touched
      && this.nonListedFacilityNameInput.value === '') {
        this.showFacilityNameRequiredMessage = true;
    }
    else {
      this.showFacilityNameRequiredMessage = false;
    }
  }

  formatTime(time) {
    if (time !== null) {
      const hours = time.split(":")[0];
      const mins = time.split(":")[1];
      return hours + ":" + mins;
    }
  }

  openWebsite(url: string) {
    window.open(url, "_blank");
  }

  openCloseAdditionalInfo(id){    
    var additionalInfoObj = this.facilityList.find(item => item.FacilityId === id);
    additionalInfoObj.IsShownAdditionalInfo = !additionalInfoObj.IsShownAdditionalInfo;    
  }

  showFacilityInfoWindow(infoWindow: AgmInfoWindow, marker : Marker) {
    this.showInfoWindow = true;
    this.selectedMarker = marker;
    if (this.previousInfoWindow) {
      this.previousInfoWindow.close();
    }
    this.previousInfoWindow = infoWindow;
  }

  zoomToSuburb(address) {
    if (!this.geocoder) this.geocoder = new google.maps.Geocoder();
    this.geocoder.geocode(
      {
        address: address,
      },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          this.mapHomeLocation.lat = lat;
          this.mapHomeLocation.lng = lng;
          this.mapHomeLocation.zoom = 12;
          this.changeDetectorRef.detectChanges();
        }
      }
    );
  }
}