//Author: Da Do

import { SearchForSuggestionsResult } from "aws-sdk/clients/location";
import { AddressLocation } from "./address-location.model";

//Description: Entity for Address.
export class Address {
    AddressId: number;
    Pobox: string;
    StreetAddress:SearchForSuggestionsResult;
    StreetAddress1: string;
    StreetAddress2: string;
    StreetAddressDesc: string;
    SuburbId: number;
    Latitude: string;
    Longitude: string;
    CityId: number ;
}

