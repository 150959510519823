// Author: Da Do
// Description: Entity for common model that just requires 2 properties Name and Id
export class CommonResponse {
    Id: number;
    Name: string;

    constructor(ID?: number, name?: string) {
        this.Id = ID;
        this.Name = name;
    }
}
