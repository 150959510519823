<ngb-accordion [destroyOnHide]="false" activeIds="panelMPackages">
    <ngb-panel id="panelMPackages">
        <ng-template ngbPanelTitle>
            <i class="fas fa-check icon-fp"></i>
            Add New Membership Package
            <i class="fas fas fa-chevron-down"></i>
            <i class="fas fas fa-chevron-up"></i>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="border-default rounded-10 border-2">
                <div class="tablecontainer">
                    <table mat-table matSort [dataSource]="dataSourceMembershipPackage" class="rounded-10" matSort
                        matSortActive="LastName" matSortDirection="desc" matSortStart="desc" matSortDisableClear>
                        <ng-container matColumnDef="default">
                            <th mat-header-cell *matHeaderCellDef class="p-0 text-center">Default</th>
                            <td mat-cell *matCellDef="let element" class="p-0 text-center">
                                <div class="custom-control custom-radio p-0 m-0">
                                    <input
                                        id="rd_membership_package_default_{{dataSourceMembershipPackage.data.indexOf(element)}}"
                                        type="radio" name="default" [disabled]="isReadonly"
                                        [checked]="(element.IsDefault == true)"
                                        (click)="handleClickDefault($event, element)"
                                        (change)="handleChangeDefault($event, element)" class="custom-control-input">
                                    <label
                                        for="rd_membership_package_default_{{dataSourceMembershipPackage.data.indexOf(element)}}"
                                        class="custom-control-label">&nbsp;</label>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef>Type</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.MembershipPackageType.Name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>Status</th>
                            <td mat-cell *matCellDef="let element"> {{element.IsActive ? 'Active' : 'Inactive'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="familyprice">
                            <th mat-header-cell *matHeaderCellDef>Family Price</th>
                            <td mat-cell *matCellDef="let element">
                                {{Utils.convertPriceFormat(element.FamilyPrice)}}/ Week </td>
                        </ng-container>

                        <ng-container matColumnDef="singleprice">
                            <th mat-header-cell *matHeaderCellDef>Single Price</th>
                            <td mat-cell *matCellDef="let element">
                                {{Utils.convertPriceFormat(element.SinglePrice)}}/ Week </td>
                        </ng-container>

                        <ng-container matColumnDef="dependentprice">
                            <th mat-header-cell *matHeaderCellDef>Dependant Price</th>
                            <td mat-cell *matCellDef="let element">
                                {{Utils.convertPriceFormat(element.DependantPrice)}}/ Week
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="datepkgprice">
                            <th mat-header-cell *matHeaderCellDef>Date Package Price Last Changed</th>
                            <td mat-cell *matCellDef="let element">
                                {{_formatDate(element.LastPriceChangeDate)}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="width:100px;"></th>
                            <td mat-cell *matCellDef="let element">
                                <a class="px-2" *ngIf="!isReadonly" (click)="OnEditMembershipPackage(element)">
                                    <i class="fas fa-pen"></i>
                                </a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <mat-toolbar [hidden]="dataSourceMembershipPackage == null" class="bg-white">
                    <mat-toolbar-row
                        *ngIf="dataSourceMembershipPackage != null && dataSourceMembershipPackage.data.length == 0"
                        class="text-muted d-block p-2 bg-light text-center">
                        {{commonString.TABLE_NO_RECORD_FOUND}}
                    </mat-toolbar-row>
                </mat-toolbar>
            </div>
            <div class="pt-4">
                <button id="btn-add-membership-package" *ngIf="!isReadonly" (click)="OnAddMembershipPackage()"
                    class="btn btn-primary" type="button" data-toggle="modal" data-target="#addEditMShipModal"><i
                        class="fas fa-plus-circle mr-2"></i>
                    Add Membership Package</button>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>

<!-- #region dialog new membership package -->
<div class="modal fade membersignup-dialog" id="addEditMShipModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header px-0 justify-content-start" id="modalheader">
                <h5 class="modal-title" id="exampleModalLabel">Add/Edit Membership Package</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="btnCloseModal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body py-4">
                <form [formGroup]="MembershipPackageFormGroup" id="membership-package">
                    <div class="form-group row">
                        <label for="ddlMshipType"  class="col-md-3 col-lg-3 col-form-label">Membership Type<span
                                class="text-danger">*</span></label>
                        <div class="col-md-8 col-lg-5">
                            <div class="d-flex flex-column w-100">
                                <select id="ddlMshipType" formControlName="ddlMshipType"
                                    (change)="isDuplicateMembershipType=false"
                                    [ngClass]="IsInvalidFieldMemberPackage('ddlMshipType') || isDuplicateMembershipType ? 'form-control is-invalid' : 'form-control'">
                                    <option value="" selected>Please select a value</option>
                                    <option *ngFor="let type of membershipPackageTypes"
                                        [ngValue]="type.MembershipPackageTypeId">{{type.Name}}</option>
                                </select>
                                <div *ngIf="IsInvalidFieldMemberPackage('ddlMshipType')">
                                    <small *ngIf="MembershipPackageFormGroup.get('ddlMshipType').hasError('required')"
                                        class="invalid-feedback d-block">
                                        {{stringHelper.format(commonString.FIELD_REQUIRED, 'Membership Type')}}
                                    </small>
                                </div>
                                <div *ngIf="isDuplicateMembershipType">
                                    <small class="invalid-feedback d-block">
                                        This membership type has already been allocated to another membership package
                                        for this employer. Please select another one.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="ddlSelectPkage"  class="col-md-3 col-lg-3 col-form-label">Select Package<span
                                class="text-danger">*</span></label>
                        <div class="col-md-8 col-lg-5">
                            <div class="d-flex flex-column w-100">
                                <div
                                    [ngClass]="(membershipPackagePackages.length == 0 )&& (this.isClickAddMembershipPackage) ? 'input-group is-invalid' : 'input-group'">
                                    <input type="text" id="ddlSelectPkage" name="ddlSelectPkage"
                                        placeholder="Search package by package name" class="form-control" matInput
                                        formControlName="ddlSelectPkage" [matAutocomplete]="packageAutocomplete"
                                        (keyup)="SearchPackageByName($event)">

                                    <div class="input-group-append">
                                        <span class="input-group-text"><i class="fas fa-search"
                                                *ngIf="!isSpinerPackage"></i></span>
                                        <mat-spinner [diameter]="14" color="primary" *ngIf="isSpinerPackage">
                                        </mat-spinner>
                                    </div>
                                </div>
                                <div *ngIf="(membershipPackagePackages.length == 0)
                                && (this.isClickAddMembershipPackage)">
                                    <small class="invalid-feedback d-block">
                                        {{stringHelper.format(commonString.FIELD_REQUIRED, 'Select Package')}}
                                    </small>
                                </div>
                                <mat-autocomplete #packageAutocomplete="matAutocomplete" id="packageAutocomplete"
                                    class="height1 fpscrolly border-2 rounded-10 border-default w-100 matautocomplete-wraptext">
                                    <mat-option class="border-default border-bottom py-1"
                                        *ngFor="let package of packageSearchResults" [value]="package"
                                        [disabled]="true">
                                        <p class="m-0 d-flex align-items-center wordbreak mr-2 w-90">{{package.Name}}
                                        </p>
                                        <span (click)="AddPackageOnModal(package)"
                                            class="text-primary d-flex align-items-center btn-image">
                                            <i class="fas fa-plus-circle fa-2x"></i></span>
                                    </mat-option>
                                </mat-autocomplete>
                                <div [hidden]="1 != 1" *ngIf="membershipPackagePackages.length > 0"
                                    class="slctRoles height1 fpscrolly border-2 rounded-10 border-default w-100 mt-2">
                                    <div *ngFor="let package of membershipPackagePackages"
                                        class="d-flex border-default border-bottom py-1 auto-complete-display-text">
                                        <span (click)="RemovePackageOnModal(package)"
                                            class="text-primary d-flex align-items-center btn-image pr-0">
                                            <i class="fas fa-minus-circle fa-2x"></i></span>
                                        <p class="d-flex align-items-center m-0">{{package.Name || package.PackageName}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="ddlMembershipStatus"  class="col-md-3 col-lg-3 col-form-label">Status<span
                                class="text-danger">*</span></label>
                        <div class="col-md-8 col-lg-5">
                            <div class="d-flex flex-column w-100">
                                <select id="ddlMembershipStatus" formControlName="ddlMembershipStatus"
                                    [ngClass]="IsInvalidFieldMemberPackage('ddlMembershipStatus') ? 'form-control is-invalid' : 'form-control'">
                                    <option selected value="true">Active</option>
                                    <option value="false">Inactive</option>
                                </select>
                                <div *ngIf="IsInvalidFieldMemberPackage('ddlMembershipStatus')">
                                    <small
                                        *ngIf="MembershipPackageFormGroup.get('ddlMembershipStatus').hasError('required')"
                                        class="invalid-feedback d-block">
                                        {{stringHelper.format(commonString.FIELD_REQUIRED, 'Status')}}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtFmlPrice" class="col-md-3 col-lg-3 col-form-label">Family Price<span
                                class="text-danger">*</span></label>
                        <div class="col-md-8 col-lg-5">
                            <div class="d-flex flex-column w-100">
                                <div [ngClass]="IsInvalidFieldMemberPackage('txtFmlPrice') ? 'input-group is-invalid' : 'input-group'"
                                    [class.focused]="parentFocus==1">
                                    <span class="input-group-prepend">$</span>
                                    <input min="0" appCurrencyMask class="form-control pl-0"
                                        formControlName="txtFmlPrice" placeholder="0.00" type="text" autocomplete="off"
                                        id="txtFmlPrice" maxlength="6" (blur)="onBlurParent(0)"
                                        (focus)="onFocusParent(1)">
                                    <div class="input-group-append">
                                        <span class="input-group-text text-primary">/ week</span>
                                    </div>
                                </div>
                                <div *ngIf="IsInvalidFieldMemberPackage('txtFmlPrice')">
                                    <small *ngIf="MembershipPackageFormGroup.get('txtFmlPrice').hasError('required')"
                                        class="invalid-feedback d-block">
                                        {{stringHelper.format(commonString.FIELD_REQUIRED, 'Family Price')}}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtSglPrice" class="col-md-3 col-lg-3 col-form-label">Single Price<span
                                class="text-danger">*</span></label>
                        <div class="col-md-8 col-lg-5">
                            <div class="d-flex flex-column w-100">
                                <div [ngClass]="IsInvalidFieldMemberPackage('txtSglPrice') ? 'input-group is-invalid' : 'input-group'"
                                    [class.focused]="parentFocus==1">
                                    <span class="input-group-prepend">$</span>
                                    <input class="form-control pl-0" appCurrencyMask autocomplete="off" min="0"
                                        placeholder="0.00" formControlName="txtSglPrice" type="text" id="txtSglPrice"
                                        maxlength="6" (blur)="onBlurParent(0)" (focus)="onFocusParent(1)">
                                    <div class="input-group-append">
                                        <span class="input-group-text text-primary">/ week</span>
                                    </div>
                                </div>
                                <div *ngIf="IsInvalidFieldMemberPackage('txtSglPrice')">
                                    <small *ngIf="MembershipPackageFormGroup.get('txtSglPrice').hasError('required')"
                                        class="invalid-feedback d-block">
                                        {{stringHelper.format(commonString.FIELD_REQUIRED, 'Single Price')}}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtDptPrice" class="col-md-3 col-lg-3 col-form-label">Dependant Price (18­-25)
                            <span class="text-danger">*</span></label>
                        <div class="col-md-8 col-lg-5">
                            <div class="d-flex flex-column w-100">
                                <div [ngClass]="IsInvalidFieldMemberPackage('txtDptPrice') ? 'input-group is-invalid' : 'input-group'"
                                    [class.focused]="parentFocus==1">
                                    <span class="input-group-prepend">$</span>
                                    <input appCurrencyMask autocomplete="off" class="form-control pl-0" min="0"
                                        placeholder="0.00" formControlName="txtDptPrice" type="text" id="txtDptPrice"
                                        maxlength="6" (blur)="onBlurParent(0)" (focus)="onFocusParent(1)">
                                    <div class="input-group-append">
                                        <span class="input-group-text text-primary">/ week</span>
                                    </div>
                                </div>
                                <div *ngIf="IsInvalidFieldMemberPackage('txtDptPrice')">
                                    <small *ngIf="MembershipPackageFormGroup.get('txtDptPrice').hasError('required')"
                                        class="invalid-feedback d-block">
                                        {{stringHelper.format(commonString.FIELD_REQUIRED, 'Dependant Price (18­-25)')}}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtPkgDesc" class="col-md-3 col-lg-3 col-form-label">Membership Description</label>
                        <div class="col-sm-7">
                            <textarea class="form-control" id="txtPkgDesc" formControlName="txtPkgDesc" rows="3"
                                maxlength="500"></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-8 col-lg-5 offset-sm-2">
                            <button class="px-5 py-2 btn btn-primary" type="button"
                                (click)="SubmitAddMembershipPackage()">
                                <i class="fas fa-check mr-2"></i> SUBMIT</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>