import { Component, Injector, ViewChild, ElementRef, Input , OnInit} from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FacilityFormBase } from 'src/app/components/base';
import { CommonMessage, CommonString, PatternConstant } from 'src/app/constant';
import { StringHelper } from 'src/app/helpers';
import { DataResult, FacilityContactType, Message } from 'src/app/models';
import { FacilityService } from 'src/app/services/facility.service';
import { PhoneNumberComponent } from '@fp/components/control';
import { EFacilityStatus } from '@fp/enums';
import { Subscription } from 'rxjs';
import { MessageService } from '@fp/services';
@Component({
    selector: 'app-facility-contact-details',
    templateUrl: './facility-contact-details.component.html',
    styleUrls: ['./facility-contact-details.component.css'],
    providers: [{ provide: FacilityFormBase, useExisting: FacilityContactDetailsComponent }]
})
export class FacilityContactDetailsComponent extends FacilityFormBase implements OnInit{
    subscription: Subscription;

    get contactListForm() { return <UntypedFormArray>this.form.get('ListFacilityContact'); }
    @ViewChild('contactTemplate') contactTemplate: ElementRef;
    @ViewChild("mobileNumberComponent") mobileNumber: PhoneNumberComponent<any>;

    contactTypes: FacilityContactType[];

    CommonMessage = CommonMessage;
    CommonString = CommonString;
    StringHelper = StringHelper;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({ ListFacilityContact: fb.array([FacilityContactDetailsComponent.getContactFormGroup()]) });
    }

    static getContactFormGroup(): UntypedFormGroup {
        const fb = new UntypedFormBuilder();
        return fb.group({
            FacilityContactId: [0],
            FacilityId: [0],
            //FacilityContactTypeId: [0, Validators.required],
            FacilityContactType: [null, Validators.required],
            FacilityContactName: [null, Validators.required],
            FacilityContactPosition: [null, Validators.required],
            FacilityContactEmailId: [0],
            FacilityContactEmail: [null],
            FacilityContactPhone: [null, Validators.required],
            FacilityContactNote: [null],
            IsActive: [true]
        });
    }

    constructor(injector: Injector, private svc: FacilityService, private messageService: MessageService) {
        super(injector);
    }
    ngOnInit(){
    this.updateValidators(true);
    this.subscription = this.messageService.currentMessage.subscribe((message: Message) => {
      if (message && message.Identifier == CommonString.FacilityPotentialMessageIdentifier) {
        if (message.Content == 'True') {
          this.updateValidators(false);

        }
        else {
          this.updateValidators(true);
        }
      }
    });
    }
    ngOnDestroy() {
        if (this.subscription) {
          this.subscription.unsubscribe(); 
        }
    }
    ngAfterViewInit() {
        this.OnLoad();
    }

    OnLoad() {
        this.Invoke(
            this.svc.getContactTypes(),
            {
                onSuccess: (result: DataResult<FacilityContactType[]>) => {
                    if (result.Success) {
                        this.contactTypes = result.Data;
                    } else {
                        this.HandleResponseError(result);
                    }
                    super.OnLoad();
                }
            });
    }

    PatchValue(value, options) {
        const list = <Array<{}>>value['ListFacilityContact'];
        // Reset Contact List by removing all controls except the first one because at least one contact is required.
        while (this.contactListForm.length > 1) {
            this.contactListForm.removeAt(1);
        }
        for (let index = 1; index < list.length; index++) {
            this.contactListForm.push(FacilityContactDetailsComponent.getContactFormGroup());
        }
        super.PatchValue(value, options);
    }

    slcContactType_CompareFn(opt1: FacilityContactType, opt2: FacilityContactType) {
        return (!opt1 && !opt2) || (opt1 && opt2 && opt1.FacilityContactTypeId == opt2.FacilityContactTypeId);
    }

    addContact() {
        this.contactListForm.push(FacilityContactDetailsComponent.getContactFormGroup());
    }

    removeContact(index: number) {
        this.contactListForm.removeAt(index);
    }

    private updateValidators(required: boolean) {
        let requiredFields = ['FacilityContactType', 'FacilityContactName','FacilityContactPosition', 'FacilityContactPhone'];
        let formArray = this.getControl('ListFacilityContact') as FormArray;
        requiredFields.forEach(fieldName => {
          let control = formArray.at(0).get(fieldName)
          if (control) {
            const validators = required ? [Validators.required] : [];
            control.setValidators(validators);
            control.updateValueAndValidity();
          }
        });
      }
}
