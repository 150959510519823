<h4 class="security-preference-component-heading font-weight-normal pb-2 text-center text-lg-left">Setup Multi-Factor
  Authentication method</h4>
<div class="security-preference-component">
  <div *ngIf="showUnexpectedStateAlert; else elseBlock">
    <ngb-alert class="security-preference-component-alert" [dismissible]="false">
      We’re experiencing an issue on our end and can’t update your MFA preferences right now. Please <a
        href="{{commonService.freshdeskNewSupportRequestUrl}}">contact
        support</a> for assistance.
    </ngb-alert>
  </div>
  <ng-template #elseBlock>
    <mat-radio-group aria-labelledby="security-preference-component" [(ngModel)]="selectedOption"
      (ngModelChange)="onOptionChange($event)">
      <mat-radio-button value="EMAIL">
        <span class="title">Email</span>
        <span class="description" [innerHTML]="emailOptionLabel"></span>
      </mat-radio-button>
      <mat-radio-button value="SMS">
        <span class="title">SMS</span>
        <span class="description">
          <span [innerHTML]="smsOptionLabel"></span>
          <button *ngIf="showUpdatePhone" class="edit-phone-button"
            (click)="navigateToSecurityPreferencesUpdatePhone()">
            <span class="phone">{{existingMFA.sms}}</span>
            <span class="svg">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 18.0013H3.74948L14.8079 6.94284L11.0585 3.19336L0 14.2518V18.0013ZM1.99972 15.0817L11.0585 6.02297L11.9783 6.94284L2.9196 16.0016H1.99972V15.0817Z"
                  fill="#666666" />
                <path
                  d="M15.3675 0.292459C14.9775 -0.0974865 14.3476 -0.0974865 13.9577 0.292459L12.1279 2.12221L15.8774 5.87169L17.7072 4.04194C18.0971 3.65199 18.0971 3.02208 17.7072 2.63213L15.3675 0.292459Z"
                  fill="#666666" />
              </svg>
            </span>
          </button>
        </span>
      </mat-radio-button>
    </mat-radio-group>

    <div *ngIf="isUpdatingMFAPreference; else updatingMFAelseBlock">
      <div class="form-actions">
        <button class="loading-button" mat-raised-button color="primary" [disabled]="true">
          <mat-spinner diameter="25"></mat-spinner>
        </button>
        <span>Updating MFA Preference</span>
      </div>
    </div>
    <ng-template #updatingMFAelseBlock>
      <div class="form-actions">
        <button mat-raised-button color="primary" (click)="onSaveClick()" [disabled]="isSaveDisabled">
          Save
        </button>
      </div>
    </ng-template>
  </ng-template>
</div>