import { Component, OnInit } from '@angular/core';
import { CommonService } from '@fp/services';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.css', '../confirm-account.component.css']
})
export class ConfirmAccountCompletedComponent implements OnInit {

  public readonly appleStoreUrl = 'https://apps.apple.com/app/fitness-passport-myfp/id1664126803';
  public readonly googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.fitnesspassport.androidapp';

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.commonService.App.isConfirmAccountScreen = true;
  }
}
