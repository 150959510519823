// tslint:disable:max-line-length
export const CommonMessage = {
    // #region Information
    DRAFT_SAVE_SUCCESS_MESSAGE: 'Draft has been saved successfully',
    DATA_SAVE_SUCCESS_MESSAGE: 'Data has been saved successfully',
    DATA_SAVE_CHANGE_MEMBERSHIP: 'Changes have been made successfully',
    // #endregion Information

    // #region Question / Confirmation
    DISCARD_CHANGES_CONFIRMATION_TITLE: 'Discard change(s)?',
    DISCARD_CHANGES_CONFIRMATION_MESSAGE: 'You have unsaved change(s) on this page. Are you sure you want to discard your change(s)?',
    DROP_DOWN_LIST_DEFAULT_TEXT: 'Please select a value',
    STATE_CHANGE_CONFIRMATION_TITLE: 'Change state?',
    STATE_CHANGE_CONFIRMATION_MESSAGE: 'The postcode(s) already selected for another state. Do you want to change state?',
    // #endregion Question / Confirmation

    // #region Caption / Instruction / Placeholder
    TABLE_NO_RECORD_FOUND: 'No record found',
    POSTCODE_NOT_ALLOCATED_TO_REGION_VALIDATION_MESSAGE: 'This postcode does not belong to a region. Please allocate the postcode to a Region to proceed',
    PROFILE_PHOTO_UPLOAD_CAPTION: 'Image uploaded needs to be a solo passport/portrait style photo with maximum size of 10mb and in formats JPEG or PNG',
    SEARCH_INSTRUCTION_PLACEHOLDER_TEXT: 'Search {0} by {1}',
    MAX_UPLOAD_FILE_SIZE_MESSAGE: 'Maximum file size is {0}',
    // #endregion Caption / Instruction / Placeholder

    // #region Error / Validation
    // #region Common
    FIELD_REQUIRED: '{0} is required',
    FIELD_INVALID: '{0} is invalid',
    FIELD_VALUE_ALREADY_EXISTED: 'The {0} already exists',
    INVALID_USERNAME_PASSWORD: "Invalid username or password.",
    ACCOUNT_IS_BANNED: 'Your access to Fitness Passport is inactive. Please <a href=\"https://fitnesspassport.freshdesk.com/support/tickets/new\" style=\"text-decoration: underline; color: blue;\"> contact our support team</a> if you require access.',
    WRONG_UPLOAD_FILE_TYPE_MESSAGE: 'Only {0} file type(s) are allowed',
    // #endregion Common

    // #region Employer
    EMPLOYER_FAILED_LAUNCH_STATUS_CHANGED_ERROR: 'Employer cannot be moved into ‘Failed Launch’ status since there is active member(s)',
    // #endregion Employer
    // #endregion Error / Validation
}
