<ngb-accordion activeIds="documentAttachmentsId,imageAttachments">
    <ngb-panel id="documentAttachmentsId">
        <ng-template ngbPanelTitle>
            <i class="fas fa-check icon-fp"></i>
            Document Attachments
            <i class="fas fas fa-chevron-down"></i>
            <i class="fas fas fa-chevron-up"></i>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="form-group row">
                <label for="fileAAttachment" class="col-md-3 col-lg-2 col-form-label d-table">Additional
                    Attachments</label>
                <div class="col-md-8 col-lg-6">
                    <div class="d-flex flex-column w-100">
                        <div class="d-flex">
                            <div>
                                <app-fp-file-upload maxfilesize="100"
                                    filterFile="application/pdf,application/vnd.ms-excel,text/plain,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.docx"
                                    (fileSelected)="AttachmentAdditionalSelected($event)"
                                    (UploadCompleted)="AddFile_UploadCompleted($event)" [isReadonly]="isReadOnly">
                                </app-fp-file-upload>
                            </div>
                            <span class="col-form-label ml-2">PDF, DOCX, DOC, XLS, XLSX, TXT</span>
                        </div>
                        <div *ngIf="dataDocumentAttachments.length>0"
                            class="slctRoles border-2 rounded-10 mt-3 border-default">
                            <div *ngFor="let document of dataDocumentAttachments"
                                class="d-flex border-default border-bottom py-1 justify-content-between">
                                <a class="d-inline-block align-items-center m-0 w-90 wordbreak" target="_blank"
                                    [href]="document.Location">{{document.FileName}}</a>
                                <span class="text-primary d-flex align-items-center"><i
                                        (click)="RemoveAttachmentByType(document, 'Attachment')"
                                        class="fas fa-minus-circle fa-2x"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
    <ngb-panel id="imageAttachments">
        <ng-template ngbPanelTitle>
            <i class="fas fa-check icon-fp"></i>
            Image Attachments
            <i class="fas fas fa-chevron-down"></i>
            <i class="fas fas fa-chevron-up"></i>
        </ng-template>
        <ng-template ngbPanelContent>
            <div>
                <app-fp-file-upload buttonlabel="Upload Image" maxfilesize="10" filterFile="image/*"
                    (fileSelected)="ImagesAdditionalSelected($event)"
                    (UploadCompleted)="AddImage_UploadCompleted($event)" [isReadonly]="isReadOnly">
                </app-fp-file-upload>
            </div>
            <div class="row image-gallery">
                <div *ngFor="let image of dataImagesAttachments" class="col-md-4 thumb">
                    <div class="fp-profile img-thumbnail d-flex align-items-center position-relative">
                        <img [src]="image.Location" alt="" class="img-fluid">
                        <div class="overlay">
                            <div *ngIf="!isReadonly" class="position-absolute position-center row mx-0">
                                <a (click)="RemoveAttachmentByType(image, 'image')"
                                    class="cursordisplay bg-white w-h-30px d-flex align-items-center justify-content-center rounded-10"
                                    title="Remove the image">
                                    <i class="text-danger fas fa-times"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>