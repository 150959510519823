<div [formGroup]="form">
    <ng-template #requiredFieldMsgTmpl let-ctrl="control" let-ctrlName="fieldName"
        let-validateOnTouch="validateOnTouch || false">
        <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
            <small *ngIf="ctrl.errors['required']" class="invalid-feedback d-block">
                {{StringHelper.format(commonString.FIELD_REQUIRED, ctrlName)}}</small>
        </div>
    </ng-template>
    <ngb-accordion activeIds="pnlEmployerDetails" [destroyOnHide]="false">
        <ngb-panel id="pnlEmployerDetails">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': valid }"></i>
                Employer Details
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <div style="min-height: 240px;">
                    <div class="form-group row">
                        <label for="txtEmployeePayrollNo"
                            class="col-10 col-md-3 col-lg-2 col-form-label">Employee/Payroll
                            No.<span class="text-danger">*</span></label>
                        <div class="col-md-8 col-lg-5 order-last order-md-0">
                            <div class="d-flex flex-column w-100">
                                <input type="text" class="form-control"
                                    [class.is-invalid]="!isControlValid('EmployeePayrollNo', isMemberTransfer)"
                                    id="txtEmployeePayrollNo" formControlName="EmployeePayrollNo" maxlength="20">
                                <ng-container
                                    *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('EmployeePayrollNo'), fieldName: 'Employee/Payroll No.', validateOnTouch: isMemberTransfer}">
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-md-1 pl-lg-0 mb-2">
                            <p class="d-flex align-items-center justify-content-center m-0 rounded-circle bg-secondary fp-warning"
                                placement="right" ngbPopover="Needed for verification purposes"><i
                                    class="fas fa-question text-primary"></i></p>
                        </div>
                    </div>

                    <div *ngIf="!isFromAustralia" class="form-group row">
                        <label for="ddlEmployerState" class="col-md-3 col-lg-2 col-form-label">Country<span
                                class="text-danger">*</span></label>
                        <div class="col-md-8 col-lg-5">
                            <div class="d-flex flex-column w-100">
                                <input type="text" class="form-control" id="txtCountryName"
                                    formControlName="CountryName" disabled>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isFromAustralia" class="form-group row">
                        <label for="ddlEmployerState" class="col-md-3 col-lg-2 col-form-label">Employer State<span
                                class="text-danger">*</span></label>
                        <div class="col-md-8 col-lg-5">
                            <div class="d-flex flex-column w-100">
                                <select class="form-control" [class.is-invalid]="!isControlValid(StateCtrl, true)"
                                    [class.disabled]="StateCtrl.disabled" id="ddlEmployerState"
                                    [formControl]="StateCtrl" [compareWith]="ddlEmployerStateOption_CompareFn"
                                    (change)="ddlEmployerState_Change()">
                                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}
                                    </option>
                                    <option *ngFor="let state of states" [ngValue]="state">{{state.Name}}</option>
                                </select>
                                <ng-container
                                    *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: StateCtrl, fieldName: 'Employer State', validateOnTouch: true}">
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-md-1 pl-lg-0 mb-2">
                            <p class="d-flex align-items-center justify-content-center m-0 rounded-circle bg-secondary fp-warning"
                                placement="right" ngbPopover="Enter your current employer state"><i
                                    class="fas fa-question text-primary"></i></p>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtEmpployer" class="col-md-3 col-lg-2 col-form-label">Employer<span
                                class="text-danger">*</span></label>
                        <div class="col-md-8 col-lg-5">
                            <div class="d-flex flex-column w-100">
                                <div class="input-group"
                                    [class.is-invalid]="!isControlValid(EmployerCtrl, isMemberTransfer) || !isControlValid(EmployerIdCtrl, isMemberTransfer)"
                                    #employerSearchBox [ngClass]="getClassFocused(1)"
                                    [class.disabled]="EmployerCtrl.disabled">
                                    <input class="form-control" id="txtEmpployer" type="text"
                                        [placeholder]="isMemberTransfer == true ? 'Search Employer by Employer Name, Alternative Name Tags' : 'Search Employer by Name'"
                                        formControlName="Employer" matInput [matAutocomplete]="autoEmployer"
                                        (blur)="txtEmployer_Blur($event)" (input)="txtEmployer_Input($event)"
                                        (focus)="onFocusParent(1)">
                                    <div *ngIf="!isSearchingEmployer" class="input-group-append">
                                        <span class="input-group-text"><i class="fas fa-search"></i></span>
                                    </div>
                                    <mat-spinner [diameter]="14" color="primary"
                                        *ngIf="isSearchingEmployer"></mat-spinner>
                                </div>
                                <div
                                    *ngIf="!isControlValid(EmployerCtrl, isMemberTransfer) || !isControlValid(EmployerIdCtrl, isMemberTransfer)">
                                    <small *ngIf="EmployerCtrl.getError('unresolved'); else notEquals"
                                        class="invalid-feedback d-block">Invalid Employer</small>
                                    <small *ngIf="EmployerCtrl.getError('required')" class="invalid-feedback d-block">
                                        {{StringHelper.format(commonString.FIELD_REQUIRED, 'Employer')}}</small>
                                    <ng-template #notEquals>
                                        <small *ngIf="EmployerIdCtrl.getError('notEquals')"
                                            class="invalid-feedback d-block">
                                            member currently belongs to this employer</small>
                                    </ng-template>

                                </div>
                                <mat-autocomplete id="autoEmployer" name="autoEmployer"
                                    class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                                    #autoEmployer="matAutocomplete" [displayWith]="AutoComplete_DisplayWithFn"
                                    (optionSelected)="SelectEmployer($event.option.value)">
                                    <mat-option *ngFor="let employer of employerSearchResults" [value]="employer"
                                        class="border-default border-bottom py-1">
                                        <p class="m-0 d-flex align-items-center">{{employer.Name}}</p>
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                        <div class="col-md-1 pl-lg-0 mb-2">
                            <p class="d-flex align-items-center justify-content-center m-0 rounded-circle bg-secondary fp-warning"
                                placement="right" ngbPopover="Enter your current employer"><i
                                    class="fas fa-question text-primary"></i></p>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtWorkPlace" class="col-md-3 col-lg-2 col-form-label">Workplace Suburb<span
                                class="text-danger">*</span></label>
                        <div class="col-md-8 col-lg-5">
                            <div class="d-flex flex-column w-100">
                                <div class="input-group"
                                    [class.is-invalid]="!isControlValid(WorkPlaceObjCtrl, true) || !isControlValid(WorkPlaceCtrl, true)"
                                    #wrkplcSearchBox [ngClass]="getClassFocused(2)"
                                    [class.disabled]="WorkPlaceObjCtrl.disabled">
                                    <input class="form-control" id="txtWorkPlace" type="text"
                                        placeholder="Search City or Suburb" formControlName="WorkplaceSuburbObj"
                                        matInput [matAutocomplete]="autoWorkplaceSuburb"
                                        (blur)="txtWorkPlace_Blur($event)" (input)="txtWorkPlace_Input($event)"
                                        (focus)="onFocusParent(2)">
                                    <div *ngIf="!isSearchingWorkPlace" class="input-group-append">
                                        <span class="input-group-text"><i class="fas fa-search"></i></span>
                                    </div>
                                    <mat-spinner [diameter]="14" color="primary"
                                        *ngIf="isSearchingWorkPlace"></mat-spinner>
                                </div>
                                <div
                                    *ngIf="!isControlValid(WorkPlaceObjCtrl, true) || !isControlValid(WorkPlaceCtrl, true)">
                                    <small *ngIf="WorkPlaceObjCtrl.getError('unresolved'); else notEquals"
                                        class="invalid-feedback d-block">The
                                        City/Suburb does not exist</small>
                                    <small *ngIf="WorkPlaceObjCtrl.getError('required')"
                                        class="invalid-feedback d-block">
                                        {{StringHelper.format(commonString.FIELD_REQUIRED, 'Workplace Suburb')}}</small>
                                </div>
                                <mat-autocomplete id="autoWorkplaceSuburb" name="autoWorkplaceSuburb"
                                    class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                                    #autoWorkplaceSuburb="matAutocomplete"
                                    [displayWith]="AutoCompleteWorkPlace_DisplayWithFn"
                                    (optionSelected)="SelectWorkPlace($event.option.value)">
                                    <mat-option *ngFor="let wp of wrkplcSearchResults" [value]="wp"
                                        class="border-default border-bottom py-1">
                                        <p class="m-0 d-flex align-items-center"> {{wp.Name}}, {{wp.State?.Name}},
                                            {{wp.Postcode?.Name}}</p>
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>