import { Component, Injector, Input, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MobileNumberRegistorComponent } from '@fp/components/control/mobile-number-registor/mobile-number-registor.component';
import { CommonConstants } from '@fp/constant/common-constants';
import { CommonService } from '@fp/services';
import { MemberFormBase } from 'src/app/components/base/member-form-base';
import { CommonString, PatternConstant } from 'src/app/constant';
import { StringHelper } from 'src/app/helpers';
import { DataResult, Member, MemberType } from 'src/app/models';
import { UserService } from 'src/app/services/admin/user/user.service';
import { MemberFamilyMemberInfoComponent } from '../family-member-info/family-member-info.component';

@Component({
  selector: 'app-member-family-member-contact-details',
  templateUrl: './family-member-contact-details.component.html',
  styleUrls: ['./family-member-contact-details.component.css'],
  providers: [
    {
      provide: MemberFormBase,
      useExisting: MemberFamilyMemberContactDetailsComponent,
    },
  ],
})
export class MemberFamilyMemberContactDetailsComponent extends MemberFormBase {
  @Input() isFamilyMemberUnderAgeDependent: boolean = false;

  countryPhoneCode = '';
  countryCodeSelectMobile = '';
  countryCodeSelectEmergency = '';
  currentMobileNumberSearchText: string;
  currentPrimaryEmailSearchText: string;
  isMPDuplicated: boolean = false;
  isEmailDuplicated: boolean = false;
  mobileLength = 10;
  isCheckingCountry: boolean = false;
  col_3 = true;
  isAusMember = true;
  dependentSelectedDate: Date;
  selectedDate: Date;
  memberType: MemberType;

  CommonString = CommonString;
  StringHelper = StringHelper;

  @ViewChild('mobileNumberRegistorComponent')
  mobileNumberRegistor: MobileNumberRegistorComponent<any>;
  @ViewChild('mobileNumberRegistorComponentContact')
  mobileNumberRegistorContact: MobileNumberRegistorComponent<any>;
  parentComponent: MemberFamilyMemberInfoComponent;

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      MobileNumber: ['', Validators.required],
      PrimaryEmail: [
        null,
        [Validators.required, Validators.pattern(PatternConstant.EMAIL)],
      ],
      EmergencyContactName: [null, Validators.required],
      EmergencyContactNo: [null, Validators.required],
      EmergencyRelationship: [null, Validators.required],
    });
  }

  constructor(
    injector: Injector,
    private commonService: CommonService,
    private userSvc: UserService,
  ) {
    super(injector);

    const _parent: MemberFamilyMemberInfoComponent =
      this.injector.get<MemberFamilyMemberInfoComponent>(
        MemberFamilyMemberInfoComponent,
      );
    this.parentComponent = _parent;
    var today = new Date();
    today.getHours;
    var eighteenYearsAgo = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate(),
      today.getHours(),
      today.getMinutes(),
      today.getMinutes(),
      today.getSeconds(),
    );

    this.dependentSelectedDate = eighteenYearsAgo;
  }

  ngAfterViewInit() {
    this.countryPhoneCode = this.commonService.SetMobileCountryCode();
    this.countryCodeSelectMobile = '+'.concat(this.countryPhoneCode);
    this.countryCodeSelectEmergency = '+'.concat(this.countryPhoneCode);
    this.isAusMember =
      this.commonService.getUserCountry()?.toUpperCase() !==
      CommonConstants.COUNTRY_NEW_ZEALAND;

    if (!this.form.get('MobileNumber').value) {
      this.mobileNumberRegistor.SetDefaultCountryCode(this.isAusMember);
    }

    if (!this.form.get('EmergencyContactNo').value) {
      this.mobileNumberRegistorContact.SetDefaultCountryCode(this.isAusMember);
    }
  }

  get MobileNumberCtrl() {
    return <UntypedFormControl>this.form.get('MobileNumber');
  }
  get PrimaryEmailCtrl() {
    return <UntypedFormControl>this.form.get('PrimaryEmail');
  }
  get EmergencyContactNameCtrl() {
    return <UntypedFormControl>this.form.get('EmergencyContactName');
  }
  get EmergencyRelationshipCtrl() {
    return <UntypedFormControl>this.form.get('EmergencyRelationship');
  }
  get EmergencyContactNoCtrl() {
    return <UntypedFormControl>this.form.get('EmergencyContactNo');
  }

  public PatchValue(value, opts) {
    if (value) {
      this.mobileNumberRegistor.dataModel = value;
    }
    if (value?.DateOfBirth) {
      var dob = new Date(value?.DateOfBirth);
      this.mobileNumberRegistor.primarySelectDate = dob;
    }

    if (value?.MemberType) {
      this.mobileNumberRegistor.memberTypePass =
        this.parentComponent.memberDetails.getControl('MemberType');
    }

    if (!value.MobileNumber.value) {
      this.mobileNumberRegistor.SetDefaultCountryCode(this.isAusMember);
    }

    //FP-849.FP-848
    if (value?.MobileNumber.match(/^\++/)) {
      value.MobileNumber = value.MobileNumber.replace(/^\++/, '+');
    }

    if (value?.MobileNumber && !this.form.get('MobileNumber').value) {
      this.mobileNumberRegistor.SetValueFromMobileNo(value.MobileNumber, true);
    } else {
      this.mobileNumberRegistor.SetDefaultCountryCode(this.isAusMember);
    }

    if (
      value?.EmergencyContactNo &&
      !this.form.get('EmergencyContactNo').value
    ) {
      if (
        !value?.EmergencyContactNo.startsWith('+61') &&
        !value?.EmergencyContactNo.startsWith('+64') &&
        value?.EmergencyContactNo.length == 9
      ) {
        value.EmergencyContactNo = '+61' + value.EmergencyContactNo;
      }

      if (
        !value?.EmergencyContactNo.startsWith('+61') &&
        !value?.EmergencyContactNo.startsWith('+64') &&
        value?.EmergencyContactNo.startsWith('0') &&
        value?.EmergencyContactNo.length == 10
      ) {
        value.EmergencyContactNo =
          '+61' + value.EmergencyContactNo.substring(1);
      }

      if (value.EmergencyContactNo.match(/^\++/)) {
        value.EmergencyContactNo = value.EmergencyContactNo.replace(
          /^\++/,
          '+',
        );
      }
      this.mobileNumberRegistorContact.SetValueFromMobileNo(
        value?.EmergencyContactNo,
        false,
      );
    } else {
      this.mobileNumberRegistorContact.SetDefaultCountryCode(this.isAusMember);
    }
    this.PrimaryEmailCtrl.setValue(value.PrimaryEmail, { emitEvent: false });
    this.EmergencyContactNameCtrl.setValue(value.EmergencyContactName, {
      emitEvent: false,
    });
    this.EmergencyRelationshipCtrl.setValue(value.EmergencyRelationship, {
      emitEvent: false,
    });
  }

  primaryEmailOnBlur(e) {
    const value = <string>e.target.value;
    this.ValidatePrimaryEmail(value);
  }

  public ValidatePrimaryEmail(value: string) {
    /**
     * this.data.model will refer to the form model for the new secondary member, or the existing secondary member when updating.
     * this.data.model.PrimaryMember will refer to the primary member, when creating a new secondary member, or updating an existing secondary member.
     */
    this.currentPrimaryEmailSearchText = '';
    const control = this.form.get('PrimaryEmail');
    value = (value || '').trim().toLowerCase();
    const primaryMemberEmail =
      this.data.model.PrimaryMember.PrimaryEmail.toLowerCase();
    const currentPrimaryEmail = this.data.model.PrimaryEmail;
    const currentMemberId = this.data.model.MemberId;

    if (
      value === currentPrimaryEmail ||
      value === '' ||
      (value === primaryMemberEmail && this.isFamilyMemberUnderAgeDependent)
    ) {
      this.currentPrimaryEmailSearchText = value;
      return;
    }

    control.updateValueAndValidity({ emitEvent: false });

    const allMemberEmails = this.getFamilyMemberEmails(currentMemberId);
    const emailExistsAgainstOtherMember = allMemberEmails.indexOf(value) > -1;
    const emailMatchesPrimaryMemberWhenNotUnderageDependent =
      !this.isFamilyMemberUnderAgeDependent && value === primaryMemberEmail;

    if (
      emailExistsAgainstOtherMember ||
      emailMatchesPrimaryMemberWhenNotUnderageDependent
    ) {
      this.isEmailDuplicated = true;
      this.currentPrimaryEmailSearchText = value;
      control.setErrors({ duplicated: { value: value } });
      control.markAsDirty();
      return;
    }

    // Unable to determine email validity with local information, running remote email validation
    control.disable({ emitEvent: false });
    control.markAsPending();
    this.userSvc.checkExistEmailv2(value).subscribe(
      (res: DataResult<string>) => {
        const encryptedData = res.Data;
        const decryptedData =
          this.commonService.D_FP_ResponseData(encryptedData);
        const ret = JSON.parse(decryptedData);
        control.enable({ emitEvent: false });
        control.markAsDirty();
        if (res.Success) {
          this.currentPrimaryEmailSearchText = value;
          if (ret.IsExist) {
            control.setErrors({ duplicated: { value: value } });
            this.isEmailDuplicated = true;
          } else {
            this.isEmailDuplicated = false;
          }
        } else {
          this.HandleResponseError(res);
        }
      },
      (err) => {
        control.enable({ emitEvent: false });
        this.handleError(err);
      },
    );
  }

  private getFamilyMemberEmails(currentMemberId: number): string[] {
    const otherFamilyMembers: Member[] =
      this.data.model.PrimaryMember.FamilyMembers.filter(
        (member) => member.MemberId != currentMemberId,
      ) || [];

    const familyMemberEmails = otherFamilyMembers.map((member) =>
      member.PrimaryEmail.toLowerCase(),
    );
    return familyMemberEmails;
  }
}
