export class MaterialModel {
    public MaterialId: number;
    public MaterialRecipientId: number;
    public MaterialRecipientName: string;
    public MaterialTypeId: number;
    public MaterialTypeName: string;
    public Name: string;
    public Description: string;
    public Link: string;
    public DocumentFileName: string;
    public DateUpload: Date;
    public DateUploadDisplay: string;
}

export class ListMaterialModel {
    public MaterialViewModels: MaterialModel[] = [];
}