<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Generate Reports</h4>
<div class="mt-lg-4">
  <form [formGroup]="ReportFormGroup">
    <div class="form-group row">
      <label for="ddlGroup" class="col-md-3 col-lg-2 col-form-label">Group<span class="text-danger">*</span></label>
      <div class="col-md-8 col-lg-6">
        <div class="d-flex flex-column w-100">
          <select class="form-control" id="ddlGroup" formControlName="ddlGroup" (change)="ddlGroup_Change($event)"
            [ngClass]="SetClassForControl(ReportFormGroup,'ddlGroup')" (change)="ddlGroup_Change($event)">
            <option value="" selected>Select report group</option>
            <option *ngFor="let gr of ReportGroupData" [value]="gr.Id">
              {{gr.Name}}
            </option>
          </select>
          <div *ngIf="IsControlInvalid(ReportFormGroup,'ddlGroup')">
            <small *ngIf="ReportFormGroup.get('ddlGroup').hasError('required')" class="invalid-feedback d-block">Report
              Group is required</small>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="ddlReport" class="col-md-3 col-lg-2 col-form-label">Report<span class="text-danger">*</span></label>
      <div class="col-md-8 col-lg-6">
        <div class="d-flex flex-column w-100">
          <select id="ddlReport" formControlName="ddlReport" (change)="ddlReport_Change($event)"
            [ngClass]="SetClassForControl(ReportFormGroup,'ddlReport')">
            <option value="" selected>Select report</option>
            <option *ngFor="let irp of reports" [value]="irp.Id">
              {{irp.Name}}
            </option>
          </select>
          <div *ngIf="IsControlInvalid(ReportFormGroup,'ddlReport')">
            <small *ngIf="ReportFormGroup.get('ddlReport').hasError('required')" class="invalid-feedback d-block">Report
              is required</small>
          </div>
        </div>
      </div>
    </div>
    <!-- Filter Region -->
    <div class="form-group row">
      <label class="col-md-3 col-lg-2 col-form-label"></label>
      <div class="col-md-8 col-lg-8" style="width: 100%;">
        <div class="card" style="width: 100%; border: 0px;" *ngIf="IsFilter">
          <div class="card-body">
            <!-- Auto Complete Employer Search -->
            <div class="form-group row" *ngIf="IsFilterEmployerSearch">
              <label for="txtEmployer" class="col-md-3 col-lg-4 col-form-label">Employer<span class="text-danger"
                  *ngIf="IsEmpRequired">*</span></label>
              <div class="col-md-8 col-lg-8">
                <div class="d-flex flex-column w-100">
                  <div [ngClass]="SetClassForAutoFill(ReportFormGroup,'ddlEmployer')">
                    <input class="form-control" id="txtEmployer" type="text" placeholder="Search an employer by name"
                      formControlName="ddlEmployer" matInput [matAutocomplete]="autoEmployer"
                      (input)="txtEmployer_Input($event)">
                    <div *ngIf="!isSearchingEmployer" class="input-group-append">
                      <span class="input-group-text"><i class="fas fa-search"></i></span>
                    </div>
                    <mat-spinner [diameter]="14" color="primary" *ngIf="isSearchingEmployer"></mat-spinner>
                  </div>
                  <div *ngIf="IsControlInvalid(ReportFormGroup,'ddlEmployer')">
                    <small *ngIf="ReportFormGroup.get('ddlEmployer').hasError('required')"
                      class="invalid-feedback d-block">Employer is required</small>
                    <small *ngIf="ReportFormGroup.get('ddlEmployer').getError('unresolved')s"
                      class="invalid-feedback d-block">The employer does not exist</small>
                  </div>
                  <mat-autocomplete id="autoEmployer" name="autoEmployer"
                    class="height1 fpscrolly border-2 rounded-10 border-default w-100" #autoEmployer="matAutocomplete"
                    [displayWith]="AutoComplete_DisplayWithFn" (optionSelected)="SelectEmployer($event.option.value)">
                    <mat-option *ngFor="let employer of employerSearchResults" [value]="employer"
                      class="border-default border-bottom py-1">
                      <p class="m-0 d-flex align-items-center">{{employer.Name}}</p>
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>
            </div>
            <!-- End -->
            <!-- Auto Complete Employer Search -->
            <div class="form-group row" *ngIf="IsFilterFacilitySearch">
              <label for="txtFacility" class="col-md-3 col-lg-4 col-form-label">Facility<span class="text-danger"
                  *ngIf="IsFacRequired">*</span></label>
              <div class="col-md-8 col-lg-8">
                <div class="d-flex flex-column w-100">
                  <div [ngClass]="SetClassForAutoFill(ReportFormGroup,'ddlFacility')">
                    <input class="form-control" id="txtFacility" type="text" placeholder="Search a Facility by name"
                      formControlName="ddlFacility" matInput [matAutocomplete]="autoFacility"
                      (input)="txtFacility_Input($event)">
                    <div *ngIf="!isSearchingFacility" class="input-group-append">
                      <span class="input-group-text"><i class="fas fa-search"></i></span>
                    </div>
                    <mat-spinner [diameter]="14" color="primary" *ngIf="isSearchingFacility"></mat-spinner>
                  </div>
                  <div *ngIf="IsControlInvalid(ReportFormGroup,'ddlFacility')">
                    <small *ngIf="ReportFormGroup.get('ddlFacility').hasError('required')"
                      class="invalid-feedback d-block">Facility is required</small>
                    <small *ngIf="ReportFormGroup.get('ddlFacility').getError('unresolved')s"
                      class="invalid-feedback d-block">The Facility does not exist</small>
                  </div>
                  <mat-autocomplete id="autoFacility" name="autoFacility"
                    class="height1 fpscrolly border-2 rounded-10 border-default w-100" #autoFacility="matAutocomplete"
                    [displayWith]="AutoComplete_DisplayWithFn" (optionSelected)="SelectFacility($event.option.value)">
                    <mat-option *ngFor="let fac of facilitySearchResults" [value]="fac"
                      class="border-default border-bottom py-1">
                      <p class="m-0 d-flex align-items-center">{{fac.Name}}</p>
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>
            </div>
            <!-- End -->
            <!-- Auto Complete User Roles Search -->
            <div class="form-group row" *ngIf="IsFilterUserRoleSearch">
              <label for="txtRole" class="col-md-3 col-lg-4 col-form-label">User Role<span class="text-danger"
                  *ngIf="IsRoleRequired">*</span></label>
              <div class="col-md-8 col-lg-8">
                <div class="d-flex flex-column w-100">
                  <div [ngClass]="SetClassForAutoFill(ReportFormGroup,'ddlRole')">
                    <input class="form-control" id="txtRole" type="text" placeholder="Search an User Role by name"
                      formControlName="ddlRole" matInput [matAutocomplete]="autoUserRole"
                      (input)="txtRole_Input($event)">
                    <div *ngIf="!isSearchingRole" class="input-group-append">
                      <span class="input-group-text"><i class="fas fa-search"></i></span>
                    </div>
                    <mat-spinner [diameter]="14" color="primary" *ngIf="isSearchingRole"></mat-spinner>
                  </div>
                  <div *ngIf="IsControlInvalid(ReportFormGroup,'ddlRole')">
                    <small *ngIf="ReportFormGroup.get('ddlRole').hasError('required')"
                      class="invalid-feedback d-block">User role is required</small>
                    <small *ngIf="ReportFormGroup.get('ddlRole').getError('unresolved')s"
                      class="invalid-feedback d-block">The User role does not exist</small>
                  </div>
                  <mat-autocomplete id="autoUserRole" name="autoUserRole"
                    class="height1 fpscrolly border-2 rounded-10 border-default w-100" #autoUserRole="matAutocomplete"
                    [displayWith]="Role_AutoComplete_DisplayWithFn" (optionSelected)="SelectRole($event.option.value)">
                    <mat-option *ngFor="let UserRole of userRoleSearchResults" [value]="UserRole"
                      class="border-default border-bottom py-1">
                      <p class="m-0 d-flex align-items-center">{{UserRole.DisplayName}}</p>
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>
            </div>
            <!-- End -->
            <!-- Drop Down List Filter 1 -->
            <div class="form-group row" *ngIf="IsFilterNormalDLL1">
              <label for="ddlFilter1" class="col-md-3 col-lg-4 col-form-label">{{FilterLabel1}}<span class="text-danger"
                  *ngIf="IsFilter1Required">*</span></label>
              <div class="col-md-8 col-lg-8">
                <div class="d-flex flex-column w-100">
                  <select [ngClass]="SetClassForControl(ReportFormGroup,'ddlFilter1')" id="ddlFilter1"
                    formControlName="ddlFilter1" (change)="ddlFilter_Change($event)">
                    <option value="" selected>{{TextWhenEmpty1}}</option>
                    <option *ngFor="let fi of FilterItems1" [value]="fi.Id">
                      {{fi.Name}}
                    </option>
                  </select>
                  <div *ngIf="IsControlInvalid(ReportFormGroup,'ddlFilter1')">
                    <small *ngIf="ReportFormGroup.get('ddlFilter1').hasError('required')"
                      class="invalid-feedback d-block">{{FilterLabel1}} is required</small>
                  </div>
                </div>
              </div>
            </div>
            <!-- End -->
            <!-- Drop Down List Filter 2-->
            <div class="form-group row" *ngIf="IsFilterNormalDLL2">
              <label for="ddlFilter2" class="col-md-3 col-lg-4 col-form-label">{{FilterLabel2}}</label>
              <div class="col-md-8 col-lg-8">
                <div class="d-flex flex-column w-100">
                  <select class="form-control" id="ddlFilter2" formControlName="ddlFilter2"
                    (change)="ddlFilter_Change($event)">
                    <option value="" selected>{{TextWhenEmpty2}}</option>
                    <option *ngFor="let fi of FilterItems2" [value]="fi.Id">
                      {{fi.Name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- End -->
            <!-- Drop Down List Filter 3-->
            <div class="form-group row" *ngIf="IsFilterNormalDLL3">
              <label for="ddlFilter3" class="col-md-3 col-lg-4 col-form-label">{{FilterLabel3}}</label>
              <div class="col-md-8 col-lg-8">
                <div class="d-flex flex-column w-100">
                  <select class="form-control" id="ddlFilter3" formControlName="ddlFilter3"
                    (change)="ddlFilter_Change($event)">
                    <option value="" selected>{{TextWhenEmpty3}}</option>
                    <option *ngFor="let fi of FilterItems3" [value]="fi.Id">
                      {{fi.Name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- End -->
            <!-- Date Range Filter -->
            <div *ngIf="IsFilterDate">
              <div class="form-group row">
                <label class="col-md-3 col-lg-4 col-form-label">Start Date<span class="text-danger"
                    *ngIf="IsStartDateRequired">*</span></label>
                <div class="col-md-8 col-lg-8">
                  <div class="d-flex flex-column w-100">
                    <div class="date-input-group" [ngClass]="SetClassForAutoFill(ReportFormGroup,'dtpStartDate')">
                      <input ngbDatepicker #dtpStartDate="ngbDatepicker" class="form-control"
                        formControlName="dtpStartDate" [minDate]="{year: 1900, month: 1, day: 1}"
                        placeholder="DD-MM-YYYY" style="width:90%; float:left"
                        (focusout)="UpdateValidateDateRange(['dtpStartDate','dtpEndDate']);"
                        (dateSelect)="UpdateValidateDateRange(['dtpStartDate','dtpEndDate']);">
                      <div class="input-group-append">
                        <button class="btn bg-white" type="button" (click)="dtpStartDate.toggle()"><i
                            class="far fa-calendar-alt"></i></button>
                      </div>
                    </div>
                    <div *ngIf="IsControlInvalid(ReportFormGroup,'dtpStartDate')">
                      <small *ngIf="ReportFormGroup.get('dtpStartDate').hasError('mindateinvalid')"
                         class="invalid-feedback d-block">Start Date is invalid</small>
                      <small *ngIf="ReportFormGroup.get('dtpStartDate').hasError('rangedateinvalid')"
                         class="invalid-feedback d-block">The Start Date should be less than the End Date</small>
                      <small *ngIf="ReportFormGroup.get('dtpStartDate').hasError('required')"
                        class="invalid-feedback d-block">Start Date is required</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-3 col-lg-4 col-form-label">End Date<span class="text-danger"
                    *ngIf="IsEndDateRequired">*</span></label>
                <div class="col-md-8 col-lg-8">
                  <div class="d-flex flex-column w-100">
                    <div class="date-input-group" [ngClass]="SetClassForAutoFill(ReportFormGroup,'dtpEndDate')">
                      <input ngbDatepicker #dtpEndDate="ngbDatepicker" class="form-control" formControlName="dtpEndDate"
                        [minDate]="{year: 1900, month: 1, day: 1}" placeholder="DD-MM-YYYY"
                        style="width:90%; float:left"
                        (focusout)="UpdateValidateDateRange(['dtpStartDate','dtpEndDate']);"
                        (dateSelect)="UpdateValidateDateRange(['dtpStartDate','dtpEndDate']);">
                      <div class="input-group-append">
                        <button class="btn bg-white" type="button" (click)="dtpEndDate.toggle()"><i
                            class="far fa-calendar-alt"></i></button>
                      </div>
                    </div>
                    <div *ngIf="IsControlInvalid(ReportFormGroup,'dtpEndDate')">
                      <small *ngIf="ReportFormGroup.get('dtpEndDate').hasError('mindateinvalid')"
                         class="invalid-feedback d-block">End Date is invalid</small>
                      <small *ngIf="ReportFormGroup.get('dtpEndDate').hasError('rangedateinvalid')"
                         class="invalid-feedback d-block">The End Date should be greater than the Start Date</small>
                      <small *ngIf="ReportFormGroup.get('dtpEndDate').hasError('required')"
                        class="invalid-feedback d-block">End Date is required</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End -->

             <!-- Normal DDL filter for year and month (Employer Churn Report) -->
         <div class="form-group row" *ngIf="IsFilterDateMonth">
          <label for="ddlFilter" class="col-md-3 col-lg-4 col-form-label">Month<span class="text-danger"
            *ngIf="IsFilterDateMonth">*</span></label>
          <div class="col-md-8 col-lg-8">
            <div class="d-flex flex-column w-100">
              <select class="form-control" id="ddlFilter" formControlName="ddlFilter" (change)="month_Year_Change()">
                <option value="" disable selected>Please select</option>
                <option *ngFor="let fi of FilterItems" [value]="fi.Name">
                  {{fi.Name}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-group row" *ngIf="IsFilterDateMonth">
          <label for="ddlFilter2" class="col-md-3 col-lg-4 col-form-label">Year<span class="text-danger"
            *ngIf="IsFilterDateMonth">*</span></label>
          <div class="col-md-8 col-lg-8">
            <div class="d-flex flex-column w-100">
              <select class="form-control" id="ddlFilter2" formControlName="ddlFilter2" (change)="month_Year_Change()">
                <option value="" disable selected>Please select</option>
                <option *ngFor="let fi of FilterItems2" [value]="fi.Name">
                  {{fi.Name}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!-- End -->

          </div>
        </div>
      </div>
    </div>
    <!-- End -->
    <!-- Button -->

    <div class="form-group row">
      <label class="col-md-3 col-lg-2 col-form-label"></label>
      <div class="col-md-8 col-lg-8" style="width: 100%;">
        <div class="card" style="width: 100%; border: 0px;" *ngIf="IsFilter">
            <!-- Member Master Export Message -->
            <div *ngIf="IsEmailOnly">
              <p class="text-danger"><strong>Note! Due to the large number of member records, this report can only be downloaded via a link sent to your email. Click the "Export Report" button below to commence export.</strong></p>
            </div>
            <!-- End -->
            <!-- Member Master Export Message -->
            <div *ngIf="IsEmailOnlyWithoutSensitiveData">
              <p class="text-danger"><strong>Note! Due to the large number of member records, this report can only be downloaded via a link sent to your email. Click the "Export Report" button below to commence export.</strong></p>
            </div>
            <!-- End -->
        </div>
      </div>
    </div>

    <div class="form-group row mt-4" *ngIf="IsReportDeveloped">
      <div class=" col-md-8 col-lg-10 offset-md-3 offset-lg-2" *ngIf="!IsCSVOnly">
        <button id="btnGenerateReport" class="btn btn-primary" style="width: 16em" type="button"
          (click)="btnGenerateReport_Click()">
          <i class="fas fa-file-invoice mr-2"></i>
          Generate Report</button>
        <button id="btnExportExcel" class="btn btn-primary" style="width: 16em; margin-left: 16px;" type="button"
          (click)="btnExportExcel_Click()" *ngIf="(IsShowingExport && displayedColumns.length > 0)">
          <i class="fas fa-file-invoice mr-2"></i>
          Export Report</button>
        <button id="btnExportExcelServer" class="btn btn-primary" style="width: 16em; margin-left: 16px;" type="button"
          (click)="btnExportExcelServer_Click()" *ngIf="(IsShowingExport && serverdisplayedColumns.length > 0)">
          <i class="fas fa-file-invoice mr-2"></i>
          Export Report</button>
      </div>
      <div class=" col-md-8 col-lg-10 offset-md-3 offset-lg-2" *ngIf="IsCSVOnly">
        <button id="generateAndExport" class="btn btn-primary" style="width: 16em" type="button"
          (click)="btnGenerateReport_Click()">
          <i class="fas fa-file-invoice mr-2"></i>
          Export Report</button>
        <div class="col-10" *ngIf="ShowMemberMasterProgress">
          <p class="text-danger">Report is being generated. An email will be sent shortly to your inbox.</p>
        </div>
      </div>
      <!-- Member Master Export Report -->
      <div *ngIf="IsEmailOnly">
        <app-describe-state-machine [MachineType]="StepFunctions.MemberMasterExport"
            (SuccessReturned)="handleMemberMasterExportReturn($event)" [IsReport]="true" #memberMasterExportDesc>
        </app-describe-state-machine>
      </div>
      <!-- Member Master Export Report without sensitive data -->
      <div *ngIf="IsEmailOnlyWithoutSensitiveData">
        <app-describe-state-machine [MachineType]="StepFunctions.MemberMasterExportWithoutSensitiveData"
            (SuccessReturned)="handleMemberMasterExportWithoutSensitiveDataReturn($event)" [IsReport]="true" #memberMasterExportWithoutSnesitiveDataDesc>
        </app-describe-state-machine>
      </div>
    </div>
    <!-- End -->
    <!-- Table -->
    <div *ngIf="!IsCSVOnly">
      <!-- Table first -->
      <h5 *ngIf="IsShowingHeaderTable1">{{Header1}}</h5>
      <div class="border-default rounded-10 border-2" [hidden]="!(displayedColumns.length > 0)">
        <div class="tablecontainer">
          <table mat-table [dataSource]="dataSource" #firstSort="matSort" matSort>
            <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</th>
              <td mat-cell *matCellDef="let element">
                <a *ngIf="column.indexOf('FileName')>-1" [href]="element['ReportURL']" target="_blank">
                  {{element[column]}}
                </a>
                <span *ngIf="column.indexOf('FileName')==-1">
                  {{element[column]}}
                </span>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator #rppagi [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons>
        </mat-paginator>
      </div>
      <mat-toolbar *ngIf="IsNoDataFirstTable" class="text-muted d-block p-2 bg-light text-center">
        {{noRecordFoundText}}</mat-toolbar>
      <!-- End -->
        <!-- Table first AU -->
        <h5 *ngIf="IsShowingHeaderTable1AU" style="margin-top: 20px;">{{Header1AU}}</h5>
        <div class="border-default rounded-10 border-2" [hidden]="!(displayedColumnsAU.length > 0)">
          <div class="tablecontainer">
            <table mat-table [dataSource]="dataSourceAU" #firstSort="matSort" matSort>
              <ng-container *ngFor="let column of displayedColumnsAU" [matColumnDef]="column">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</th>
                <td mat-cell *matCellDef="let element">
                  <a *ngIf="column.indexOf('FileName')>-1" [href]="element['ReportURL']" target="_blank">
                    {{element[column]}}
                  </a>
                  <span *ngIf="column.indexOf('FileName')==-1">
                    {{element[column]}}
                  </span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumnsAU"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsAU;"></tr>
            </table>
          </div>
          <mat-paginator #rppagiAU [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons>
          </mat-paginator>
        </div>
        <mat-toolbar *ngIf="IsNoDataFirstTableAU" class="text-muted d-block p-2 bg-light text-center">
          {{noRecordFoundText}}</mat-toolbar>
        <!-- End -->

         <!-- Table first NZ -->
         <h5 *ngIf="IsShowingHeaderTable1NZ" style="margin-top: 20px;">{{Header1NZ}}</h5>
         <div class="border-default rounded-10 border-2" [hidden]="!(displayedColumnsNZ.length > 0)">
           <div class="tablecontainer">
             <table mat-table [dataSource]="dataSourceNZ" #firstSort="matSort" matSort>
               <ng-container *ngFor="let column of displayedColumnsNZ" [matColumnDef]="column">
                 <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</th>
                 <td mat-cell *matCellDef="let element">
                   <a *ngIf="column.indexOf('FileName')>-1" [href]="element['ReportURL']" target="_blank">
                     {{element[column]}}
                   </a>
                   <span *ngIf="column.indexOf('FileName')==-1">
                     {{element[column]}}
                   </span>
                 </td>
               </ng-container>
               <tr mat-header-row *matHeaderRowDef="displayedColumnsNZ"></tr>
               <tr mat-row *matRowDef="let row; columns: displayedColumnsNZ;"></tr>
             </table>
           </div>
           <mat-paginator #rppagiNZ [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons>
           </mat-paginator>
         </div>
         <mat-toolbar *ngIf="IsNoDataFirstTableNZ" class="text-muted d-block p-2 bg-light text-center">
           {{noRecordFoundText}}</mat-toolbar>
         <!-- End -->


      <!-- Table Second -->
      <h5 *ngIf="IsShowingHeaderTable2" style="margin-top: 20px;">{{Header2}}</h5>
      <div class="border-default rounded-10 border-2" [hidden]="!(seconddisplayedColumns.length > 0)">
        <div class="tablecontainer">
          <table mat-table [dataSource]="seconddataSource" #secondSort="matSort" matSort>
            <ng-container *ngFor="let column of seconddisplayedColumns" [matColumnDef]="column">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</th>
              <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="seconddisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: seconddisplayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator #rpsecondpagi [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons>
        </mat-paginator>
      </div>
      <mat-toolbar *ngIf="IsNoDataSecondTable" class="text-muted d-block p-2 bg-light text-center">
        {{noRecordFoundText}}</mat-toolbar>
      <!-- End -->
   
         <!-- Table Second AU-->
         <h5 *ngIf="IsShowingHeaderTable2AU" style="margin-top: 20px;">{{Header2AU}}</h5>
         <div class="border-default rounded-10 border-2" [hidden]="!(seconddisplayedColumnsAU.length > 0)">
           <div class="tablecontainer">
             <table mat-table [dataSource]="seconddataSourceAU" #secondSort="matSort" matSort>
               <ng-container *ngFor="let column of seconddisplayedColumnsAU" [matColumnDef]="column">
                 <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</th>
                 <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
               </ng-container>
               <tr mat-header-row *matHeaderRowDef="seconddisplayedColumnsAU"></tr>
               <tr mat-row *matRowDef="let row; columns: seconddisplayedColumnsAU;"></tr>
             </table>
           </div>
           <mat-paginator #rpsecondpagiAU [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons>
           </mat-paginator>
         </div>
         <mat-toolbar *ngIf="IsNoDataSecondTableAU" class="text-muted d-block p-2 bg-light text-center">
           {{noRecordFoundText}}</mat-toolbar>
         <!-- End -->

           <!-- Table Second NZ-->
           <h5 *ngIf="IsShowingHeaderTable2NZ" style="margin-top: 20px;">{{Header2NZ}}</h5>
           <div class="border-default rounded-10 border-2" [hidden]="!(seconddisplayedColumnsNZ.length > 0)">
             <div class="tablecontainer">
               <table mat-table [dataSource]="seconddataSourceNZ" #secondSort="matSort" matSort>
                 <ng-container *ngFor="let column of seconddisplayedColumnsNZ" [matColumnDef]="column">
                   <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</th>
                   <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
                 </ng-container>
                 <tr mat-header-row *matHeaderRowDef="seconddisplayedColumnsNZ"></tr>
                 <tr mat-row *matRowDef="let row; columns: seconddisplayedColumnsNZ;"></tr>
               </table>
             </div>
             <mat-paginator #rpsecondpagiNZ [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons>
             </mat-paginator>
           </div>
           <mat-toolbar *ngIf="IsNoDataSecondTableNZ" class="text-muted d-block p-2 bg-light text-center">
             {{noRecordFoundText}}</mat-toolbar>
           <!-- End -->
        
            <!-- Table Server Paging -->
      <div class="border-default rounded-10 border-2"
      *ngIf="IsShowingServerDataPaging && serverdisplayedColumns.length > 0">
      <div class="tablecontainer">
        <table mat-table [dataSource]="serverpagingSource" matSort (matSortChange)="sortChange($event)">
          <ng-container *ngFor="let column of serverdisplayedColumns" [matColumnDef]="column">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 200px !important;">{{replace(column)}}
            </th>
            <td mat-cell *matCellDef="let element" style="width: 200px !important;">
              {{element[column]}}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="serverdisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: serverdisplayedColumns;"></tr>
        </table>
      </div>
      <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="paginatorChange($event)" showFirstLastButtons></mat-paginator>
    </div>
    <mat-toolbar *ngIf="IsNoDataServerTable" class="text-muted d-block p-2 bg-light text-center">
      {{noRecordFoundText}}</mat-toolbar>
    <!-- End -->

    </div>
  </form>
</div>