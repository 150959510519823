<div [formGroup]="form">
    <div class="row" formGroupName="EmployerContract">
        <div class="col-sm-7">
            <div class="form-group row">
                <label for="txtDateStarted" class="col-sm-3 col-form-label">Date Started</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtDateStarted" readonly>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="txtContractStartDate" class="col-sm-3 col-form-label">Contract Start Date</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <div class="input-group date-input-group" [fpFormControlValidation]="{control: getControl('ContractStartDate'),
                            controlLabel: 'Contract Start Date',
                            errorTemplates: {
                                'ngbDate': {
                                    'requiredBefore': CommonMessage.FIELD_INVALID
                                },
                                'lt': {
                                    'ContractEndDate': 'The {0} should be less than the Contract End Date'
                                }
                            }}">
                            <input class="form-control" id="txtContractStartDate" name="txtContractStartDate" placeholder="DD-MM-YYYY"
                                ngbDatepicker #dpContractStartDate="ngbDatepicker" formControlName="ContractStartDate">
                            <div class="input-group-append">
                                <button class="btn bg-white" (click)="dpContractStartDate.toggle()" type="button">
                                    <i class="far fa-calendar-alt"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="txtContractEndDate" class="col-sm-3 col-form-label">Contract<br />End Date</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <div class="input-group date-input-group" [fpFormControlValidation]="{control: getControl('ContractEndDate'),
                            controlLabel: 'Contract End Date',
                            errorTemplates: {
                                'ngbDate': {
                                    'requiredBefore': CommonMessage.FIELD_INVALID
                                },
                                'gt': {
                                    'ContractStartDate': 'The {0} should be greater than the Contract Start Date'
                                }
                            }}">
                            <input class="form-control" id="txtContractEndDate" name="txtContractEndDate" placeholder="DD-MM-YYYY"
                                ngbDatepicker #dpContractEndDate="ngbDatepicker" formControlName="ContractEndDate">
                            <div class="input-group-append">
                                <button class="btn bg-white" (click)="dpContractEndDate.toggle()" type="button">
                                    <i class="far fa-calendar-alt"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="txtContractDuration" class="col-sm-3 col-form-label">Contract Duration</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <div class="input-group" [class.disabled]="txtContractDuration.disabled" [fpFormControlValidation]="{
                            control: getControl('ContractDuration'),
                            controlLabel: 'Contract Duration',
                            errorTemplates: {
                                'max': '{0} must be less than 100'
                            }}">
                            <input type="text" class="form-control" id="txtContractDuration" maxlength="2"
                                formControlName="ContractDuration" numberOnly #txtContractDuration>
                            <div class="input-group-append">
                                <span class="input-group-text text-primary">Year(s)</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="txtContractSignatory" class="col-sm-3 col-form-label">Contract Signatory</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtContractSignatory" maxlength="50"
                            formControlName="ContractSignatory">
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="txtCSDate" class="col-sm-3 col-form-label">Signed Contract</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <app-fp-file-upload filterFile=".pdf" #txtSignedContract maxfilesize="100"
                            (UploadCompleted)="SignedContract_FileUploaded($event)" (fileSelected)="SignedContract_FileSelected($event)">
                        </app-fp-file-upload>
                        <div *ngIf="getControl('EmployerContract.EmployerSignedContract').value?.length > 0;else tmplBlankSignedContract"
                            class="slctRoles border-2 rounded-10 mt-3 border-default">
                            <div *ngFor="let file of _filterAvailableFiles(getControl('EmployerContract.EmployerSignedContract').value)">
                                <div class="d-flex border-default border-bottom py-1 justify-content-between">
                                    <a class="d-flex align-items-center m-0 wordbreak w-90" target="_blank" [href]="file.Location">
                                        {{file.FileName}}</a>
                                    <span *ngIf="form.enabled" class="text-primary d-flex align-items-center"
                                        (click)="_removeFile(getControl('EmployerContract.EmployerSignedContract'), file)">
                                        <i class="fas fa-minus-circle fa-2x"></i></span>
                                </div>
                            </div>
                        </div>
                        <ng-template #tmplBlankSignedContract>
                            <span class="col-form-label">Upload PDF</span>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="txtCSDate" class="col-sm-3 col-form-label">Attach Additional Files</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <app-fp-file-upload filterFile=".pdf,image/*" #txtAttachAdditional
                            (UploadCompleted)="AdditionalFile_FileUploaded($event)" (fileSelected)="AdditionalFile_FileSelected($event)">
                        </app-fp-file-upload>
                        <div *ngIf="getControl('EmployerContract.EmployerContractAttachFile').value?.length > 0;else tmplBlankAdditionalFiles"
                            class="slctRoles border-2 rounded-10 mt-3 border-default">
                            <div
                                *ngFor="let file of _filterAvailableFiles(getControl('EmployerContract.EmployerContractAttachFile').value)">
                                <div class="d-flex border-default border-bottom py-1 justify-content-between">
                                    <a class="d-flex align-items-center m-0 wordbreak w-90" target="_blank"
                                        [href]="file.Location">{{ file.FileName }}</a>
                                    <span *ngIf="form.enabled" class="text-primary d-flex align-items-center"
                                        (click)="_removeFile(getControl('EmployerContract.EmployerContractAttachFile'), file)">
                                        <i class="fas fa-minus-circle fa-2x"></i></span>
                                </div>
                            </div>
                        </div>
                        <ng-template #tmplBlankAdditionalFiles>
                            <span class="col-form-label">Upload JPEG, PNG or PDF</span>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-5">
            <div class="fp-profile d-flex justify-content-center">
                <div class="w-50">
                    <div *ngIf="getControl('EmployerContract.EmployerContractLogo').value?.length > 0;else tmplBlankProfilePhoto">
                        <div *ngFor="let logo of _filterAvailableFiles(getControl('EmployerContract.EmployerContractLogo').value)">
                            <div *ngIf="logo.Location" class="position-relative container">
                                <div class="fp-photologo mt-3 text-center">
                                    <img [src]="logo.Location" alt="" class="img-fluid">
                                </div>
                                <div class="overlay">
                                    <a *ngIf="form.enabled" class="cursordisplay position-absolute position-topright" title="Remove logo"
                                        (click)="_removeFile(getControl('EmployerContract.EmployerContractLogo'), logo)">
                                        <i class="text-danger fas fa-times fa-2x-ex"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #tmplBlankProfilePhoto>
                        <div class="bg-secondary fp-profilephoto d-flex justify-content-center align-items-center mx-auto ng-star-inserted">
                            <i class="fa fa-image fa-2x text-secondary"></i></div>
                    </ng-template>
                    <div class="custom-file mt-2">
                        <app-fp-file-upload filterFile="image/*" maxfilesize="10" (UploadCompleted)="Logo_FileUploaded($event)"
                            (fileSelected)="Logo_FileSelected($event)" #txtAttachLogo>
                        </app-fp-file-upload>
                    </div>

                    <span class="col-form-label" *ngIf="!(getControl('EmployerContract.EmployerContractLogo').value?.length > 0)">Upload
                        JPEG or PNG</span>
                </div>
            </div>
        </div>
    </div>
</div>