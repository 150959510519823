import { Component, Injector } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ModelBasedForm } from '@fp/components/base';
import { EmployerDetailModel } from '@fp/models';
import { EmployerContractComponent } from '../../groups';

@Component({
    selector: 'app-employer-contracts-form',
    templateUrl: './contracts.component.html',
    styleUrls: ['./contracts.component.css'],
    providers: [ModelBasedForm.provideExisting(EmployerContractsFormComponent)]
})
export class EmployerContractsFormComponent extends ModelBasedForm<EmployerDetailModel> {
    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            ContractSection: EmployerContractComponent.getFormGroup()
        });
    }

    constructor(injector: Injector) { super(injector); }

}
