<ng-template #dlgTermsConsContent let-modal>
  <div class="{{fadeMainTerm ? 'main-term-test-fade' : 'main-term-test-show'}}">
    <div class="modal-header" id="modalheader">
      <h5 class="modal-title" id="exampleModalLabel" ngbAutofocus>Terms and Conditions</h5>
    </div>
    <div class="modal-body">
      <!-- <div [innerHtml]="termsAndConsContent" class="fpscrolly py-3" id="fp-termscondText" (scroll)="onScroll($event)" [style.height]="heightTCText"></div> -->
      <div class="fpscrolly py-3" id="fp-termscondText" [style.height]="heightTCText" (scroll)="onScroll($event)">
        <div [innerHtml]="termsAndConsContent" id="fp-tcContent"></div>
        <div id="fp-tcEndContent">&nbsp;</div>
      </div>
      <div class="position-relative pt-4" id="chkTC">
        <span class="font-weight-bold" style="color: red; text-align: center;">
          Please scroll to the bottom and read
          all the Terms and Conditions.<br />Please click Next to proceed.
        </span>
        <!-- <div class="d-inline-block ml-3">
          <div class="custom-control custom-checkbox">
            <input [disabled]="disablechkTermCons" type="checkbox" class="custom-control-input" id="chkTermsCons"
              #chkTermsCons [checked]="isTermsAndConsAccepted" (change)="isTermsAndConsAccepted = $event.target.checked">
            <label class="custom-control-label" for="chkTermsCons">Yes</label>
          </div>
        </div> -->
      </div>
      <div class="py-4 d-flex justify-content-center" id="btnTC">
        <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button" data-dismiss="modal"
          (click)="btnBack();modal.close();">
          <i class="fas fa-arrow-circle-left mr-2"></i>Back</button>
        <!-- <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" [disabled]="!isTermsAndConsAccepted"
          data-dismiss="modal" data-target="dlgTermsConsContent" (click)="btnSubmit_Click();modal.close();">
          Submit</button> -->
          <div class="tooltip">
            <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
              data-dismiss="modal" [disabled]="disablechkTermCons" (click)="loadContentKeyTC()">
              Next&nbsp;<i class="fas fa-arrow-circle-right mr-2"></i></button>          
          </div>
      </div>
    </div>
  </div>  
</ng-template>

<ng-template #dlgKeyTermsConsContent let-modal>
  <div class="modal-header" id="keyModalheader">
    <h5 class="modal-title" id="exampleModalLabel" ngbAutofocus>Key Terms and Conditions</h5>
  </div>
  <div class="modal-body">    
    <div class="fpscrolly py-3" id="fp-keyTermscondText" [style.height]="heightKeyTCText" (scroll)="keyTermOnScroll($event)">
      <div [innerHtml]="keyTermsAndConsContent" id="fp-KeytcContent"></div>
      <div id="fp-KeytcEndContent">&nbsp;</div>
    </div>
    <div class="position-relative pt-4" id="keyChkTC">
      <span class="font-weight-bold" style="color: red; text-align: center;">
        I have read and accepted the terms and conditions
      </span>
      <div class="d-inline-block ml-3">
        <div class="custom-control custom-checkbox">
          <input [disabled]="disablechkKeyTermCons" type="checkbox" class="custom-control-input" id="chkKeyTermsCons"
            #chkKeyTermsCons [checked]="isKeyTermsAndConsAccepted" (change)="isKeyTermsAndConsAccepted = $event.target.checked">
          <label class="custom-control-label" for="chkKeyTermsCons">Yes</label>
        </div>
      </div>
    </div>
    <div class="py-4 d-flex justify-content-center" id="keyBtnTC">
      <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button" data-dismiss="modal"
        (click)="keyTermModalBack(modal)">
        <i class="fas fa-arrow-circle-left mr-2"></i>Back</button>
      <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" [disabled]="!isKeyTermsAndConsAccepted"
        data-dismiss="modal" data-target="dlgKeyTermsConsContent" (click)="btnSubmit_Click()">
        Submit</button>
    </div>
  
  </div>
</ng-template>