import { Injectable } from '@angular/core';

@Injectable()
export class CurrencyTestService {
    private prefix: string;
    private decimalSeparator: string;
    private thousandsSeparator: string;
    constructor() {
        this.prefix = '';
        this.decimalSeparator = '.';
        this.thousandsSeparator = ',';

    }

    transform(value: string) {
        if (value == undefined || value === '') {
            return null;
        }
        let [integer, fraction = ''] = (value || '').toString().split(this.decimalSeparator);
        if (integer === '') {
            integer = '0000';
            /* } else if (integer.length == 1) {
                integer = '000' + integer;
            } else if (integer.length == 2) {
                integer = '00' + integer;
            } else if (integer.length == 3) {
                integer = '0' + integer;
                */
        } else {
            fraction = integer.length > 4 ? integer.substring(4, 6) : fraction;
            integer = integer.substring(0, 4);
        }
        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandsSeparator);
        fraction = this.decimalSeparator + (fraction + '000000').substring(0, 2);
        return this.prefix + integer + fraction;
    }

    parse(value: string) {
        let [integer, fraction = ''] = (value || '').split(this.decimalSeparator);
        integer = integer.replace(new RegExp(/[^\d\.]/, 'g'), '');
        fraction = parseInt(fraction, 10) > 0 && 2 > 0 ? this.decimalSeparator + (fraction + '000000').substring(0, 2) : '';
        return integer + fraction;
    }
}