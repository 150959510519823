<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Cancel / Ban / Suspend Member</h5>
</div>
<div class="modal-body py-4">
    <form [formGroup]="IBMemberFG">
        <div class="form-group row">
            <label for="slctAction" class="col-md-3 col-form-label">Select Action<span class="text-danger">*</span>
            </label>
            <div class="col-md-9">
                <div class="d-flex flex-column w-100">
                    <select
                        [ngClass]="checkValidateIBMemberFG('MemberCancellationActionId') ? 'form-control is-invalid':'form-control'"
                        id="slctAction" formControlName="MemberCancellationActionId">
                        <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                        <option *ngFor="let action of CancellationActions" [ngValue]="action.Id">{{action.Name}}
                        </option>
                        <option [ngValue]="0" *ngIf="allowMembershipSuspension">Suspend Membership</option>
                    </select>
                    <div *ngIf="checkValidateIBMemberFG('MemberCancellationActionId')">
                        <small *ngIf="IBMemberFG.get('MemberCancellationActionId').errors.required"
                            class="invalid-feedback d-block">
                            {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Select Action')}}
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row" *ngIf="isBanOrCancelMember">
            <label for="slctCancellationReason" class="col-md-3 col-form-label">Cancellation Reason<span
                    class="text-danger">*</span>
            </label>
            <div class="col-md-9">
                <div class="d-flex flex-column w-100">
                    <select
                        [ngClass]="checkValidateIBMemberFG('MemberCancellationReasonId') ? 'form-control is-invalid':'form-control'"
                        id="slctCancellationReason" formControlName="MemberCancellationReasonId"
                        (ngModelChange)="selectCancellationReason($event)">
                        <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                        <option *ngFor="let reason of CancellationReasons" [ngValue]="reason.Id">{{reason.Name}}
                        </option>
                    </select>
                    <div *ngIf="checkValidateIBMemberFG('MemberCancellationReasonId')">
                        <small *ngIf="IBMemberFG.get('MemberCancellationReasonId').errors.required"
                            class="invalid-feedback d-block">
                            {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Cancellation Reason')}}
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isJoinedGymDirectly">
            <div class="form-group row">
                <label for="ddlSelectGym" class="col-md-3 col-form-label">Select Facility</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <div [ngClass]="checkValidateIBMemberFG('Facility') ? 'input-group is-invalid' : 'input-group'"
                            [class.focused]="parentFocus==3">
                            <input type="text" id="ddlSelectGym" name="ddlSelectGym"
                                placeholder="{{StringHelper.format(CommonMessage.SEARCH_INSTRUCTION_PLACEHOLDER_TEXT, 'facility', 'facility name')}}"
                                class="form-control" matInput [matAutocomplete]="gymAutocomplete"
                                formControlName="Facility" (input)="txtSelectGym_Input($event)"
                                (focusout)="txtSelectGym_Lostfocus($event)" (blur)="onBlurParent(0)"
                                (focus)="onFocusParent(3)">
                            <mat-autocomplete #gymAutocomplete="matAutocomplete" id="gymAutocomplete"
                                [displayWith]="AutoComplete_DisplayWithFn">
                                <mat-option *ngFor="let facility of facilitySearchResult" [value]="facility"
                                    class="border-default border-bottom py-1">
                                    <p class="m-0 d-flex align-items-center">
                                        {{facility.Name}}
                                    </p>
                                </mat-option>
                            </mat-autocomplete>

                            <div class="input-group-append">
                                <span class="input-group-text" *ngIf="!isSearchingFacility">
                                    <i class="fas fa-search"></i>
                                </span>
                                <mat-spinner [diameter]="14" color="primary" *ngIf="isSearchingFacility">
                                </mat-spinner>
                            </div>
                        </div>
                        <div *ngIf="checkValidateIBMemberFG('Facility')">
                            <small class="invalid-feedback d-block">Select Facility does not exist</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="txtEFName" class="col-md-3 col-form-label">Enter Facility Name</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <input id="txtFName"
                            [ngClass]="checkValidateIBMemberFG('FacilityName') ? 'form-control is-invalid':'form-control'"
                            maxlength="80" formControlName="FacilityName" (input)="txtFacilityName_Input()">
                    </div>
                </div>
            </div>
            <div class="form-group"
                *ngIf="checkValidateIBMemberFG('Facility') || checkValidateIBMemberFG('FacilityName')">
                <small
                    *ngIf="IBMemberFG.get('Facility').errors?.required || IBMemberFG.get('FacilityName').errors?.required"
                    class="invalid-feedback d-block">
                    You must select an existing facility</small>
            </div>
        </div>

        <div class="form-group row" *ngIf="isCancelMembership">
            <label for="txtCancellationFee" class="col-md-3 col-form-label">Cancellation Date<span
                    class="text-danger">*</span>
            </label>
            <div class="col-md-9">
                <div class="d-flex flex-column w-100">
                        <input placeholder="DD-MM-YYYY" name="dp" ngbDatepicker #d1="ngbDatepicker" required
                            formControlName="CancellationDate" class="form-control date-picker"
                            [minDate]="GetCurrentDate()" (blur)="onBlurParent(0)" (focus)="onFocusParent(1)"
                            [readonly]="StartDateReadonly">
                        <div class="input-group-append">
                            <button class="btn bg-white" (click)="d1.toggle()" type="button">
                                <i class="far fa-calendar-alt"></i>
                            </button>
                        </div>
                </div>
            </div>
        </div>
        
        <div class="form-group row" *ngIf="isBanOrCancelMember">
            <label for="txtCancellationFee" class="col-md-3 col-form-label">Cancellation Fee<span
                    class="text-danger">*</span>
            </label>
            <div class="col-md-9">
                <div class="d-flex flex-column w-100">
                    <div class="row">
                        <div class="col-md-10 col-lg-7">
                            <div class="input-group">
                                <span class="input-group-prepend">$</span>
                                <input type="text" class="form-control pl-0" id="txtCancellationFee"
                                    formControlName="CancellationFee" autocomplete="off" maxlength="10" appCurrencyMask
                                    placeholder="0.00">
                            </div>
                        </div>
                        <div class="col-md-2 col-lg-5 pl-0">
                            <button class="py-2 btn btn-primary w-100 btn-calculate" type="button"
                                (click)="btnCalculateFee_Click($event)" [disabled]="IBMemberFG.controls.CancellationDate.hasError('required')">
                                <i class="fas fa-calculator mr-2"></i>
                                <span class="display-small">Calculate<br /> Cancellation Fee</span></button>
                        </div>
                    </div>
                    <div *ngIf="checkValidateIBMemberFG('CancellationFee')">
                        <small *ngIf="IBMemberFG.get('CancellationFee').errors.required"
                            class="invalid-feedback d-block">
                            {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Cancellation Fee')}}
                        </small>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row" *ngIf="isCancelMembership">
            <label for="slctCutUpCardRecieve" class="col-md-3 col-form-label">Cut-Up Card Recieved<span
                    class="text-danger">*</span>
            </label>
            <div class="col-md-9">
                <div class="d-flex flex-column w-100">
                    <select
                        [ngClass]="checkValidateIBMemberFG('CutupCardRecieved') ? 'form-control is-invalid':'form-control'"
                        id="slctCancellationReason" formControlName="CutupCardRecieved" (ngModelChange)="selectCancellationReason($event)">
                        <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                        <option [value]="true">Yes</option>
                        <option [value]="false">No</option>
                    </select>
                    <div *ngIf="checkValidateIBMemberFG('CutupCardRecieved')">
                        <small *ngIf="IBMemberFG.get('CutupCardRecieved').errors.required"
                            class="invalid-feedback d-block">
                            {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Cut-Up Card Recieved or Not')}}
                        </small>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row" *ngIf="isBanOrCancelMember">
            <label for="txtAddNotes" class="col-md-3 col-form-label">Additional Notes</label>
            <div class="col-md-9">
                <div class="d-flex flex-column w-100">
                    <textarea id="txtAddNotes" maxlength="1000" class="form-control"
                        formControlName="AdditionalNotes"></textarea>
                        <div *ngIf="checkValidateIBMemberFG('AdditionalNotes')">
                            <small class="invalid-feedback d-block">Do not include * [ ] - , special characters in additional notes field</small>
                        </div>
                </div>
            </div>
        </div>

        <div class="form-group row" *ngIf="isCancelMembershipButtons && isCancelMembership">
            <div class="offset-3 col-md-9">
                <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button" (click)="updateSubmitCancellation()">
                    <i class="fas fa-check-circle mr-2"></i>
                    Update</button>
                <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="revokeSubmitCancellation()">
                    <i class="fas fa-check-circle mr-2"></i>
                    Revoke</button>
            </div>
        </div>

    </form>
    <div *ngIf="isSuspendMembership">
        <form [formGroup]="suspensionFG">
            <div class="form-group row">
                <label class="col-md-3 col-form-label">Suspension Type<span class="text-danger">*</span>
                </label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <select
                            [ngClass]="checkValidatesuspendFG('MembershipSuspensionTypeId') ? 'form-control is-invalid':'form-control'"
                            formControlName="MembershipSuspensionTypeId">
                            <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                            <option *ngFor="let type of suspensionType" [ngValue]="type.Id">{{type.Name}}</option>
                        </select>
                        <div *ngIf="checkValidatesuspendFG('MembershipSuspensionTypeId')">
                            <small *ngIf="suspensionFG.get('MembershipSuspensionTypeId').errors.required"
                                class="invalid-feedback d-block">
                                {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Suspension Type')}}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 col-form-label">Suspension Reason<span class="text-danger">*</span>
                </label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <select
                            [ngClass]="checkValidatesuspendFG('MembershipSuspensionReasonId') ? 'form-control is-invalid':'form-control'"
                            formControlName="MembershipSuspensionReasonId">
                            <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                            <option *ngFor="let reason of suspensionReason" [ngValue]="reason.Id">{{reason.Name}}
                            </option>
                        </select>
                        <div *ngIf="checkValidatesuspendFG('MembershipSuspensionReasonId')">
                            <small *ngIf="suspensionFG.get('MembershipSuspensionReasonId').errors.required"
                                class="invalid-feedback d-block">
                                {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Suspension Reason')}}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="dtpStartDate" class="col-md-3 col-form-label">Start Date<span class="text-danger">*</span>
                </label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <div [ngClass]="checkValidatesuspendFG('SuspensionStartDate')?'input-group is-invalid date-input-group':'input-group date-input-group'"
                            [class.focused]="parentFocus==1">
                            <input placeholder="DD-MM-YYYY" name="dp" ngbDatepicker #d1="ngbDatepicker"
                                formControlName="SuspensionStartDate" class="form-control date-picker"
                                [minDate]="GetCurrentDate()" (blur)="onBlurParent(0)" (focus)="onFocusParent(1)"
                                [readonly]="StartDateReadonly">
                            <div class="input-group-append">
                                <button class="btn bg-white" (click)="d1.toggle()" type="button">
                                    <i class="far fa-calendar-alt"></i>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="checkValidatesuspendFG('SuspensionStartDate')">
                            <small *ngIf="suspensionFG.get('SuspensionStartDate').errors.required"
                                class="invalid-feedback d-block">
                                {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Start Date')}}
                            </small>
                            <small *ngIf="suspensionFG.get('SuspensionStartDate').errors.invalidFormatDate"
                                class="invalid-feedback d-block">
                                {{StringHelper.format(CommonString.FIELD_INVALID, 'Start Date')}}
                            </small>
                            <small *ngIf="suspensionFG.get('SuspensionStartDate').errors.rangedateinvalid"
                                class="invalid-feedback d-block">
                                The Start Date should be less than the End Date
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="dtpEndDate" class="col-md-3 col-form-label">End Date<span class="text-danger">*</span>
                </label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <div [ngClass]="checkValidatesuspendFG('SuspensionEndDate')?'input-group is-invalid':'input-group'"
                            [class.focused]="parentFocus==2">
                            <input placeholder="DD-MM-YYYY" name="dp" ngbDatepicker #d2="ngbDatepicker"
                                formControlName="SuspensionEndDate" class="form-control date-picker"
                                [minDate]="GetCurrentDate()" (blur)="onBlurParent(0)" (focus)="onFocusParent(2)">
                            <div class="input-group-append">
                                <button class="btn bg-white" (click)="d2.toggle()" type="button">
                                    <i class="far fa-calendar-alt"></i>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="checkValidatesuspendFG('SuspensionEndDate')">
                            <small *ngIf="suspensionFG.get('SuspensionEndDate').errors.required"
                                class="invalid-feedback d-block">
                                {{StringHelper.format(CommonString.FIELD_REQUIRED, 'End Date')}}
                            </small>
                            <small *ngIf="suspensionFG.get('SuspensionEndDate').errors.invalidFormatDate"
                                class="invalid-feedback d-block">
                                {{StringHelper.format(CommonString.FIELD_INVALID, 'End Date')}}
                            </small>
                            <small *ngIf="suspensionFG.get('SuspensionEndDate').errors.rangedateinvalid"
                                class="invalid-feedback d-block">
                                The End Date should be greater than the Start Date
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="dtpEndDate" class="col-md-3 col-form-label">Charge Suspension Fee</label>
                <div class="col-md-9">
                    <div class="d-flex flex-column w-100">
                        <div class="row">
                            <div class="col-md-8" *ngIf="cancelSuspensionBtn">
                                <div class="d-flex">
                                    <button class="px-2 py-1 btn btn-danger text-light" type="button"
                                        (click)="confirmCancelSuspension()">
                                        Cancel Suspension</button>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="!cancelSuspensionBtn">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" formControlName="IsChargeSuspensionFee"
                                        class="custom-control-input" id="chkActiveYes">
                                    <label class="custom-control-label" for="chkActiveYes">Yes</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="form-group row pt-3" >
        <div class="offset-3 col-md-9">
            <button id="ms_cards_change_photo_close"
                class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                (click)="Cancel()">
                <i class="fas fa-ban mr-2"></i>Cancel</button>
            <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="submitAction()" *ngIf="isCancelMemberSubmitButton">
                <i class="fas fa-check-circle mr-2"></i>
                Submit</button>
        </div>
    </div>
</div>