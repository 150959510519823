import { AfterViewInit, ApplicationRef, Attribute, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, EmbeddedViewRef, Injector, Input, OnDestroy, OnInit, Optional, Renderer2, TemplateRef } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { FPFormControlErrorsTemplateComponent } from '@fp/components/control/form-control-errors-template';

interface IValidationOptions {
    onTouched: boolean;
    onDirty: boolean;
    toggleFn?: () => boolean;
}

interface IValidationConfig {
    control?: AbstractControl;
    controlLabel?: string;
    options: IValidationOptions;
    errorTemplates?: { [key: string]: any };
}

const DEFAULT_VALIDATION_CONFIG: IValidationConfig = {
    options: {
        onDirty: true,
        onTouched: false
    }
};

@Directive({
    selector: '[fpFormControlValidation]',
    exportAs: 'fpFormControlValidation'
})
export class FPFormControlValidationDirective implements OnInit, AfterViewInit, OnDestroy {
    @Input('fpFormControlValidation') private set config(value: IValidationConfig) {
        if (!value)
            return;
        const { control, controlLabel, options, errorTemplates } = Object.assign({}, DEFAULT_VALIDATION_CONFIG, value);
        this.options = options;
        this._errorTemplates = errorTemplates;
        this.setControl({ control, label: controlLabel });
    }

    @Input() errorsPlacement: TemplateRef<any> | ElementRef | Comment;

    private _errorClasses = 'is-invalid'
    @Input() private set errorClasses(value: string | string[]) {
        this.setErrorClasses(value);
    }
    setErrorClasses(value: string | string[]) {
        if (value != null && typeof value !== 'string' && !Array.isArray(value))
            throw Error('Invalid type of "errorClasses". Only accept value of type "string" or array of string.')
        if (Array.isArray(value)) {
            this._errorClasses = value.join(' ');
        } else {
            this._errorClasses = value || '';
        }
    };
    getErrorClasses(): string { return this._errorClasses; }

    private _options: IValidationOptions = {
        onDirty: true,
        onTouched: false
    };

    get options() {
        return this._options;
    }
    set options(value: IValidationOptions) {
        this._options = Object.assign({}, this._options, value);
        if (this._errorsTemplateRef) {
            this._errorsTemplateRef.instance.options = Object.assign({}, this._options);
        }
    }

    private _control: AbstractControl;
    private _controlLabelForErrorsTemplate: string;
    private _errorTemplates: { [key: string]: any };
    private _errorsTemplateRef: ComponentRef<FPFormControlErrorsTemplateComponent>;
    private _errorsTemplateViewAttached = false;

    constructor(private _injector: Injector, private _elRef: ElementRef<HTMLElement>, private _renderer: Renderer2,
        private _resolver: ComponentFactoryResolver, @Optional() private ngControl: NgControl, @Optional() @Attribute('formControlName') private _controlName: string,
        private _appRef: ApplicationRef) {
    }

    ngOnInit() {
        this._control = this._control || (this.ngControl && this.ngControl.control);
        if (!this._control)
            return;

        // Find the associated label element to get the control's display name.
        if (this._elRef.nativeElement.id) {
            const scopeAttr = Array.from(this._elRef.nativeElement.attributes).find(_attr => _attr.name.indexOf('_ngcontent') > -1);
            const labelNodes = document.querySelectorAll<HTMLLabelElement>(`label[for=${this._elRef.nativeElement.id}]${scopeAttr ? '[' + scopeAttr.name + ']' : ''}`);
            if (labelNodes.length === 1) {
                Array.from(labelNodes[0].childNodes).forEach(_child => {
                    if (_child instanceof Text) {
                        this._controlName = _child.textContent || this._controlName;
                    }
                });
            }
        }
        this._controlLabelForErrorsTemplate = this._controlName || this._controlLabelForErrorsTemplate;

        // Monkey-patching: work-around to listen to the 'dirty', 'touched' status change events of the control.
        const oriMarkAsTouched = this._control.markAsTouched;
        this._control.markAsTouched = (opts?: any) => {
            oriMarkAsTouched.call(this._control, opts);
            this.toggleError();
        }
        const oriMarkAsUntouched = this._control.markAsUntouched;
        this._control.markAsUntouched = (opts?: any) => {
            oriMarkAsUntouched.call(this._control, opts);
            this.toggleError();
        }
        const oriMarkAsDirty = this._control.markAsDirty;
        this._control.markAsDirty = (opts?: any) => {
            oriMarkAsDirty.call(this._control, opts);
            this.toggleError();
        }
        const oriMarkAsPristine = this._control.markAsPristine;
        this._control.markAsPristine = (opts?: any) => {
            oriMarkAsPristine.call(this._control, opts);
            this.toggleError();
        }
        const oriUpdateValueAndValidity = this._control.updateValueAndValidity;
        this._control.updateValueAndValidity = (opts?: any) => {
            oriUpdateValueAndValidity.call(this._control, opts);
            this.toggleError();
        }

        // Dynamically create and insert the FormControlErrorsTemplateComponent into DOM tree.
        const factory = this._resolver.resolveComponentFactory(FPFormControlErrorsTemplateComponent);
        this._errorsTemplateRef = factory.create(this._injector);
        const templateNode = (this._errorsTemplateRef.hostView as EmbeddedViewRef<any>).rootNodes[0];
        try {
            this._appRef.attachView(this._errorsTemplateRef.hostView);
            this._errorsTemplateViewAttached = true;
        } catch (err) {
            console.warn(err);
        }
        if (this.errorsPlacement) {
            let element: HTMLElement | Comment;
            if (this.errorsPlacement instanceof Comment || this.errorsPlacement instanceof HTMLElement) {
                element = this.errorsPlacement;
            } else if (this.errorsPlacement instanceof TemplateRef) {
                element = this.errorsPlacement.elementRef.nativeElement;
            } else if (this.errorsPlacement instanceof ElementRef) {
                element = this.errorsPlacement.nativeElement;
            }
            if (element) {
                const parentElem = element.parentNode;
                const newChild = parentElem.appendChild(templateNode);
                parentElem.replaceChild(newChild, element);
            }
        } else {
            this._elRef.nativeElement.parentElement.appendChild(templateNode);
        }
        if (this._errorsTemplateRef) {
            const instance = this._errorsTemplateRef.instance;
            instance.target = this._control;
            instance.label = this._controlLabelForErrorsTemplate || instance.label;
            instance.options = Object.assign({}, this._options);
            instance.errorTemplates = this._errorTemplates;
            this._errorsTemplateRef.changeDetectorRef.detectChanges();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this._control) {
                // this._control.valueChanges.subscribe((value) => {
                //     this.toggleError();
                // });
                this._control.statusChanges.subscribe((status) => {
                    this.toggleError();
                });
            }
        });
    }

    ngOnDestroy() {
        if (this._errorsTemplateRef && this._errorsTemplateViewAttached) {
            this._appRef.detachView(this._errorsTemplateRef.hostView);
            this._errorsTemplateRef.destroy();
        }
    }

    private setControl(value: { control: AbstractControl, label?: string }) {
        const { control, label } = value;
        this._control = control || this._control;
        this._controlLabelForErrorsTemplate = label || this._controlLabelForErrorsTemplate;
        if (this._errorsTemplateRef) {
            this._errorsTemplateRef.instance.target = this._control;
            this._errorsTemplateRef.instance.label = this._controlLabelForErrorsTemplate || this._errorsTemplateRef.instance.label;
        }
    }

    private shouldToggle() {
        const control = this._control;
        return control && control.invalid && (
            (this._options.onDirty && control.dirty) ||
            (this._options.onTouched && control.touched) ||
            (typeof this._options.toggleFn === 'function' && this._options.toggleFn()));
    }

    private toggleError() {
        if (this._errorsTemplateRef) {
            const toggle = this.shouldToggle();
            if (toggle) {
                this._renderer.addClass(this._elRef.nativeElement, this.getErrorClasses());
            } else {
                this._renderer.removeClass(this._elRef.nativeElement, this.getErrorClasses());
            }
        }
    }
}
