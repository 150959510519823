

import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { NewPaymentRequestNote } from '@fp/models';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DateHelper } from '@fp/helpers';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-pr-notes',
  templateUrl: './pr-notes.component.html',
  styleUrls: ['./pr-notes.component.css']
})
export class PrNotesComponent implements OnInit {
  public displayedColumns: string[] = ['Username', 'DateTimeDisplay', 'Note'];
  public dtmatnotes: MatTableDataSource<NewPaymentRequestNote> = new MatTableDataSource<NewPaymentRequestNote>();
  public PaymentRequestNoteFormGroup: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: UntypedFormBuilder,
    protected dialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef) {
  }

  ngOnInit() {
      this.CreateMemberNoteForm();
      this.LoadData();
  }

  private LoadData() {
        var noteList: NewPaymentRequestNote[] = [];
        console.log(this.data.paymentReqNotes);
        for(let i = 0; i < this.data.paymentReqNotes.length; i++) {
          var tempNote = new NewPaymentRequestNote();
          tempNote.DateTime = this.ConvertToLocalTime(this.data.paymentReqNotes[i].CreateOrUpdateDate);
          tempNote.Note = this.data.paymentReqNotes[i].Note;
          tempNote.User = this.data.paymentReqNotes[i].Username;
          noteList.unshift(tempNote);
        }
        this.dtmatnotes = new MatTableDataSource<NewPaymentRequestNote>(noteList);
        this.changeDetectorRefs.detectChanges();
  }

  private ConvertToLocalTime(d: Date) {
    const localdate: Date = new Date(d.toString() + 'Z');
    return DateHelper.format(localdate, 'DD-MMM-YYYY HH:mm');
  }

  private CreateMemberNoteForm() {
    this.PaymentRequestNoteFormGroup = this._formBuilder.group({
      slcNoteType: [''],
      txtMemberNotes: ['']
    });
  }

}
