import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PdfMemberInvoiceReport } from "@fp/services/report/member-invoice.service";
import { MemberService, CommonService } from "@fp/services";
import { MatTableDataSource } from "@angular/material/table";
import { MemberInvoiceDates, MemberTaxInvoiceVM } from "@fp/models/member-tax-invoice.model";
import { DataResult, Member } from "@fp/models";
import { CommonString, StorageKey } from "@fp/constant";
import { MemberContainer } from '@fp/models/member-container.model';

@Component({
  selector: 'app-member-payments-history-receipts',
  templateUrl: './payments-history-receipts.component.html',
  styleUrls: ['./payments-history-receipts.component.css']
})
export class MemberPaymentsHistoryReceiptsComponent implements OnInit {
  displayedColumns: string[] = ['DateTime', 'DateTime2', 'Amount', 'action'];
  dataSource = new MatTableDataSource<MemberInvoiceDates>([]);
  location: string = environment.TemplateTax;
  isLoading = false;
  CommonString = CommonString;

  constructor(
    private invoiceService: PdfMemberInvoiceReport,
    private memberService: MemberService,
    private commonSvc: CommonService
  ) { }

  ngOnInit() {
    this.getPeriodList();
    this.commonSvc.Header.title = "Payment History & Receipts";
  }

  private getPeriodList() {
    this.isLoading = true;
    let currentMember;
    
    const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
    if(getContainer != null) {
      let container = <MemberContainer>JSON.parse(getContainer);
      currentMember = <DataResult<Member>>JSON.parse(this.commonSvc.D_FP_AES256(container.Member));
    }

    if(currentMember.Data.TaxInvoiceDates != undefined) {
      this.dataSource.data = currentMember.Data.TaxInvoiceDates;
      this.isLoading = false;
      return;
    }

    this.memberService.getMemberTaxInvoiceDatesById(this.commonSvc.D_FP_AES256ForShortString(localStorage.getItem("memberId"))).subscribe((result: DataResult<MemberInvoiceDates[]>) => {
      this.dataSource.data = result.Data.filter(a => a.Amount != undefined);
      this.isLoading = false;
    },
      (err) => {
        console.log(err);
        this.isLoading = false;
      });
  }

  generateMemberInvoice(data: MemberInvoiceDates) {
    this.isLoading = true;
    var endDate = new Date(Date.parse(data.PeriodEndDate));
    this.memberService.getMemberTaxInvoiceDataById(this.commonSvc.D_FP_AES256ForShortString(localStorage.getItem("memberId")), this.formatDateYMD(endDate)).subscribe(
      (result: DataResult<MemberTaxInvoiceVM>) => {
        let invoiceData = result.Data.InvoiceData;
        // https://jira.csc.com/browse/FP-4662 - [Member Tax Invoice] File name should be the tax invoice number, not the date
        const invnumber = result.Data.InvoiceData.InvoiceNumber;
        // End
        let transactionData = result.Data.TransactionsData;
        invoiceData.PeriodOpenDate = new Date(Date.parse(result.Data.InvoiceData.PeriodOpenDate));
        invoiceData.PeriodCloseDate = new Date(Date.parse(result.Data.InvoiceData.PeriodCloseDate));
        this.invoiceService.SavePdfToFile(
          // https://jira.csc.com/browse/FP-4662 - [Member Tax Invoice] File name should be the tax invoice number, not the date
          "FPTaxInvoice_" + invnumber + ".pdf",
          // End
          invoiceData,
          transactionData);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        console.log(error);
      }
    )
  }

  private formatDateYMD(endDate: Date): string {
    return endDate.getFullYear().toString() + "/" + (endDate.getMonth() + 1).toString() + "/" + endDate.getDate().toString();
  }

  getWindowHeight() {
    // 300 is roughly the px height of the top bar
    return window.innerHeight - 300;
  }

  tableStyle(inner: boolean = false) {
    if (inner) {
      return { "max-height": (this.getWindowHeight() - 10).toString() + "px" };
    }
    else {
      return { "max-width": "60em", "max-height": this.getWindowHeight().toString() + "px" };
    }
  }

}
