import { Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { empty } from 'rxjs';
import { catchError, debounceTime, skipWhile, switchMap } from 'rxjs/operators';
import { MemberFormBase } from 'src/app/components/base/member-form-base';
import { CommonMessage, CommonString } from 'src/app/constant';
import { FPValidators, StringHelper } from 'src/app/helpers';
import { Employer, State, SuburbSearchResultRecord } from 'src/app/models';
import { MessageBox, CommonService } from '@fp/services';
import { DialogResult } from '@fp/components/common-dialog/common-dialog.component';
import { CommonConstants } from '@fp/constant/common-constants';
import { AddressService } from '@fp/services/address.service';
import { City } from '@fp/models/city.model';
@Component({
  selector: 'app-reactivation-employer-details',
  templateUrl: './reactivation-employer-details.component.html',
  styleUrls: ['./reactivation-employer-details.component.css'],
  providers: [{ provide: MemberFormBase, useExisting: ReactivationEmployerDetailsComponent }]
})
export class ReactivationEmployerDetailsComponent extends MemberFormBase {
  parentFocus = 0;
  states: State[];
  selectedState: State;
  selectedCity: City;
  isSearchingEmployer = false;
  isSearchingWorkPlace = false;
  isSearchingCity = false;
  employerSearchResults: Employer[];
  private employerList: Employer[];
  isFromAustralia = true;
  private suburbList: SuburbSearchResultRecord[];
  wrkplcSearchResults: SuburbSearchResultRecord[];
  citySearchResults: City[];
  selectedEmployerId = 0;
  selectedCityId = 0;
  StateCtrl = null;
  countryName = "";
  nzStatus = "";
  get EmployerCtrl() { return this.form.get('Employer'); }
  get EmployerIdCtrl() { return this.form.get('EmployerId'); }
  get nzStatusCtrl() { return this.form.get('nzStatus'); }
  get WorkPlaceCtrl() { return this.form.get('WorkplaceSuburb'); }
  get WorkPlaceObjCtrl() { return this.form.get('WorkplaceSuburbObj'); }

  @ViewChild('employerSearchBox') private employerSearchBox: ElementRef<HTMLDivElement>;
  @ViewChild('autoEmployer') private autoEmployer: MatAutocomplete;
  @ViewChild('autoWorkplaceSuburb') private autoWorkplaceSuburb: MatAutocomplete;
  @ViewChild('autoCity') private autoCity: MatAutocomplete;
  @Output() resetEvent = new EventEmitter();
  @Input() isMemberTransfer: boolean;

  CommonMessage = CommonMessage;
  commonString = CommonString;
  StringHelper = StringHelper;

  static getFormGroup() {
    const fb = new FormBuilder();
    return fb.group({
      EmployerId: [null, [Validators.required]],
      Employer: [null, [Validators.required, FPValidators.resolvableAutocompleteOption]],
      EmployeePayrollNo: [null, Validators.required],
      WorkplaceSuburb: [null, Validators.required],
      WorkplaceSuburbObj: [null, [Validators.required, FPValidators.resolvableAutocompleteOption]],
      CountryName: [null, null],
      nzStatus: [null, null]
    });
  }

  constructor(injector: Injector,
    private commonService: CommonService,
    private addressService: AddressService
  ) {
    super(injector);

    //member transfer
    if (this.isMemberTransfer == undefined)
      this.isMemberTransfer = false;
  }

  ngOnInit() {

    this.isFromAustralia = this.commonService.getUserCountry() !== this.commonString.NEWZEALAND;
    if (this.isFromAustralia) {
      this.StateCtrl = new FormControl(null, Validators.required);
    }
  }

  ngAfterViewInit() {
    this.OnLoad();
  }

  OnLoad() {
    this.countryName = this.commonService.getUserCountry();
    this.nzStatus = "NZ";
    this.form.patchValue({ nzStatus: this.nzStatus }, { emitEvent: false });
    this.form.patchValue({ CountryName: this.countryName }, { emitEvent: false });
    this.states = CommonConstants.GET_STATES.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
    this.isSearchingWorkPlace = true
    this.commonService.getLocationData().subscribe(
      (res) => {
        if (res.Success) {
          this.suburbList = res.Data.suburbs;
          this.isSearchingWorkPlace = false;
        }
      }
    );;
    this.isSearchingCity = true;
    this.addressService.getUniqueCityName().subscribe((res) => {
      this.isSearchingCity = false;
    });
    super.OnLoad();
  }

  LoadComplete() {
    this.employerList = this.commonService.getEmployers();
    let currentValue = '';
    let currentSuburbValue = '';
    let currentCityValue = '';
    setTimeout(() => {
      if (this.isFromAustralia) {
        this.StateCtrl.valueChanges.subscribe((value: State) => {
          this.selectedState = value;
          this.EmployerCtrl.setValue(null);
        });
      } 
      console.log(this.employerList);
      this.form.get('EmployerId').valueChanges.subscribe(value => {
        this.selectedEmployerId = value;
        this.employerSearchResults = [];
      });

      
      this.EmployerCtrl.valueChanges
        .pipe(
          skipWhile((value) => (this.isFromAustralia && !this.StateCtrl.value) || (value && typeof value === 'object')),
          debounceTime(500),
          switchMap((value) => {
            if (typeof value === 'string') {
              this.form.get('EmployerId').setValue(null);
              if (this.isFromAustralia && this.StateCtrl.value && value.length > 2) {
                this.isSearchingEmployer = true;
                return value;
              } else if (!this.isFromAustralia && value.length > 2) {
                this.isSearchingEmployer = true;
                return value;

              }
              else {
                this.isSearchingEmployer = false;
              }
            }
            return empty();
          })
        ).subscribe(() => {
          this.isSearchingEmployer = false;
          if (currentValue != this.EmployerCtrl.value) {
            currentValue = this.EmployerCtrl.value;
            let employerList: Employer[] = this.employerList;
            if (this.isFromAustralia) {
              employerList = employerList.filter(x => x.State.StateId == this.StateCtrl.value.StateId && x.Name.toLowerCase().trim().includes(this.EmployerCtrl.value.toLowerCase().trim()));
            } else {
              employerList = employerList.filter(x => x.State.StateId == 9 && x.Name.toLowerCase().trim().includes(this.EmployerCtrl.value.toLowerCase().trim()));//set State Id to NZ
            }
            employerList.sort((a, b) => a.Name.localeCompare(b.Name));
            this.employerSearchResults = employerList;
            this.changeDetectorRef.markForCheck();
          }
        }
        );

      this.WorkPlaceObjCtrl.valueChanges.pipe(
        skipWhile((value) => (value && typeof value === 'object')),
        debounceTime(500),
        switchMap((value) => {
          if (typeof value === 'string'
            && value.trim().length >= 3) {
            this.changeDetectorRef.markForCheck();
            return value;
          }
          return empty();
        }),
        catchError((e) => { throw e; })
      ).subscribe(() => {
        if (currentCityValue != this.WorkPlaceObjCtrl.value) {
          currentSuburbValue = this.WorkPlaceObjCtrl.value;
          let getList = this.suburbList.filter(x => x.State.Name == (this.isFromAustralia ? this.StateCtrl.value.Name : 'NZ') && x.Name.toLowerCase().trim().includes(this.WorkPlaceObjCtrl.value.toLowerCase().trim()));
          this.wrkplcSearchResults = getList;
          this.isSearchingWorkPlace = false;
          this.changeDetectorRef.markForCheck();
        }
      }, error => {
        this.isSearchingWorkPlace = false;
        MessageBox.ShowError(this.dialog, "Sorry, we're having trouble connecting. Let's try that again.")
          .subscribe(res => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              window.location.reload();
            }
          });
        this._logger.error(error);
      });

      // Fix postcode autocomplete's width.
      this.autoEmployer.panelWidth = this.employerSearchBox.nativeElement.clientWidth;
      this.autoWorkplaceSuburb.panelWidth = this.employerSearchBox.nativeElement.clientWidth;
      if (this.autoCity) {
        this.autoCity.panelWidth = this.employerSearchBox.nativeElement.clientWidth;
      }
    });
    super.LoadComplete();
    if (this.isFromAustralia) {
      this.CheckStateValue();
    }
  }

  PatchValue(value: { [key: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
    let employer = <Employer>value['Employer'];
    if (!employer && this.form.get('Employer').value) {
      employer = this.form.get('Employer').value;
      value['Employer'] = employer;
    }
    if (employer) {
      if (value['EmployerId'] == null) {
        value['EmployerId'] = employer.EmployerId;
      }
      if (this.isFromAustralia && employer.State) {
        for (var i = 0; i < this.states.length; i++) {
          if (this.states[i].Name == employer.State.Name) {
            this.StateCtrl.setValue(this.states[i], { emitEvent: false });
            this.selectedState = this.states[i];
            break;
          }
        }
        this.StateCtrl.enable();
        this.EmployerCtrl.enable();
        this.changeDetectorRef.markForCheck();
      }
    }
    const wrkplcresponse: string = value['WorkplaceSuburb'];
    if (wrkplcresponse) {
      const arr = wrkplcresponse.split(', ');
      if (arr.length === 3 && (value['WorkplaceSuburbObj'] === null || value['WorkplaceSuburbObj'] === undefined)) {
        const temp: SuburbSearchResultRecord = new SuburbSearchResultRecord();
        temp.Name = arr[0];
        temp.State = { Id: null, Name: arr[1] };
        temp.Postcode = { Id: null, Name: arr[2] };
        value['WorkplaceSuburbObj'] = temp;
      }
    }
    super.PatchValue(value, options);
    this.clearData()
  }
  
  private clearData() {
    const currentEmployerId = this.form.get('EmployerId').value;
      console.log(currentEmployerId);
      const currentEmployer = this.employerList.find(employer => employer.EmployerId === currentEmployerId);
      if (!currentEmployer) {
          this.form.reset();
          this.StateCtrl.reset();
  }
  }

  ddlEmployerStateOption_CompareFn(opt1: State, opt2: State) {
    return (!opt1 && !opt2) || (opt1 && opt2 && opt1.StateId == opt2.StateId);
  }

  ddlEmployerCityOption_CompareFn(opt1: City, opt2: City) {
    return (!opt1 && !opt2) || (opt1 && opt2 && opt1.CityId == opt2.CityId);
  }

  AutoComplete_DisplayWithFn(option: any) {
    return option ? option.Name : '';
  }

  AutoCompleteWorkPlace_DisplayWithFn(option: any) {
    if (option) {
      if (option.Name && option.State && option.Postcode) {
        const val = option.Name + ', ' + option.State.Name + ", " + option.Postcode.Name;
        return (val);
      } else {
        return option;
      }
    } else {
      return '';
    }
  }

  AutoCompleteCity_DisplayWithFn(option: any) {
    if (option) {
      if (option.Name) {
        const val = option.Name;
        return (val);
      } else {
        return option;
      }
    } else {
      return '';
    }
  }


  SelectEmployer(employer: Employer) {
    this.selectedEmployerId = employer ? employer.EmployerId : null;
    console.log('Selected Employer ID:', this.selectedEmployerId);
    this.form.patchValue({ EmployerId: this.selectedEmployerId, Employer: employer }, { emitEvent: false });
    this.employerSearchResults = [];
    this.WorkPlaceCtrl.markAsDirty();
    this.WorkPlaceCtrl.markAsTouched();
  }

  SelectWorkPlace(wrkplc: SuburbSearchResultRecord) {
    if (wrkplc) {
      if (wrkplc.Name && wrkplc.State && wrkplc.Postcode) {
        const val = wrkplc.Name + ', ' + wrkplc.State.Name + ", " + wrkplc.Postcode.Name;
        this.form.patchValue({ WorkplaceSuburb: val }, { emitEvent: false });
      }
    }
    this.wrkplcSearchResults = [];
    this.WorkPlaceCtrl.markAsDirty();
    this.WorkPlaceCtrl.markAsTouched();
  }

  txtEmployer_Blur() {
    if (this.EmployerCtrl.dirty) {
      this.EmployerCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
      this.EmployerCtrl.updateValueAndValidity({ emitEvent: false });
    }
    this.parentFocus = 0;
  }

  txtWorkPlace_Blur() {
    if (this.WorkPlaceObjCtrl.dirty) {
      this.WorkPlaceObjCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
      this.WorkPlaceObjCtrl.updateValueAndValidity({ emitEvent: false });
    }
    this.parentFocus = 0;
  }

  txtEmployer_Input() {
    if (this.EmployerCtrl.dirty) {
      if (typeof this.EmployerCtrl.value === 'string' &&
        this.EmployerCtrl.value.length == 0 &&
        this.selectedEmployerId > 0) {
        this.SelectEmployer(null);
      }
      this.EmployerCtrl.setValidators(Validators.required);
    }
  }

  txtWorkPlace_Input() {
    if (this.WorkPlaceObjCtrl.dirty) {
      if (typeof this.WorkPlaceObjCtrl.value === 'string' &&
        this.WorkPlaceObjCtrl.value.length == 0) {
        this.SelectWorkPlace(null);
      }
      this.WorkPlaceObjCtrl.setValidators(Validators.required);
    }
  }


  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }

  getClassFocused(vlFocused) {
    if (this.parentFocus == vlFocused) {
      return "focused";
    } else {
      return "";
    }
  }

  public ddlEmployerState_Change() {
    this.CheckStateValue();
  }

  public CheckStateValue() {
    if (!this.isFromAustralia) {
      return;
    }

    if (this.StateCtrl !== null) {
      const val = this.StateCtrl.value;
      if (val === null || val === undefined || val === '') {
        this.form.get('Employer').disable();
      } else {
        this.form.get('Employer').enable();
      }
    }
  }

}

