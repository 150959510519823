<div [formGroup]="form">
    <ngb-accordion [destroyOnHide]="false" activeIds="pnlConfirmMembershipPackage,pnlConfirmPayment,pnlContract">
        <ngb-panel id="pnlConfirmMembershipPackage">
            <ng-template ngbPanelTitle>
                <i class="fas fa-check icon-fp"></i>
                Confirm Membership Package
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-member-membership-package-cost-summary [loadOnDemand]="loadOnDemand"
                    [dataSource]="[data.model].concat(data.model.FamilyMembers || [])"
                    [membershipPackage]="data.model.Memberships ? data.model.Memberships[0]?.MembershipPackage : null">
                </app-member-membership-package-cost-summary>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlConfirmPayment" *ngIf="!IsEF()">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': bankAccountSection?.valid}"></i>
                Bank Details
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <p>Payment for your Fitness Passport membership will be debited from your bank
                    account. Please provide the required details below:</p>
                <app-member-bank-account [loadOnDemand]="loadOnDemand" [form]="form.get('BankAccountSection')"
                    [data]="data" #bankAccount></app-member-bank-account>
            </ng-template>
        </ngb-panel>
        <ng-container *ngIf="isEditMemAdminValid()">
            <ngb-panel id="pnlContract">
                <ng-template ngbPanelTitle>
                    <i class="icon-fp fas fa-check"></i>
                    Contract
                    <i class="fas fas fa-chevron-down"></i>
                    <i class="fas fas fa-chevron-up"></i>
                </ng-template>
                <ng-template ngbPanelContent>
                    <app-member-contract-details [loadOnDemand]="loadOnDemand" [form]="form.get('ContractSection')"
                        [data]="data">
                    </app-member-contract-details>
                </ng-template>
            </ngb-panel>
        </ng-container>
    </ngb-accordion>
</div>