import { Injectable, ViewChild } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { PaymentService } from "./payment.service";
import {
  RawVisitParameters,
  RawVisitCount
} from "@fp/models/raw-visit-parameters.model";
import {
  CostCalculationParameters,
  CalculatedCost,
  CalculationData
} from "@fp/models/cost-calculation-parameters.model";
import { DataResult, PostPaymentRequest } from "@fp/models";
import { MatTableDataSource } from "@angular/material/table";
import { DataSourceHelper } from "@fp/helpers";
import { MatSort } from "@angular/material/sort";

@Injectable()
export class PaymentRequestDetailsService {
  private facilityNameSource = new BehaviorSubject("facility");
  private serviceNameSource = new BehaviorSubject("service");
  private fromDateSource = new BehaviorSubject("DD/MM/YYYY");
  private toDateSource = new BehaviorSubject("DD/MMM/YYYY");
  private additionalVisitsSource = new BehaviorSubject(0);
  private visitAmountSource = new BehaviorSubject(0);
  private paymentAmountSource = new BehaviorSubject(0);
  private finalPaymentAmountSource = new BehaviorSubject(0);
  private newHwmNumMemSource = new BehaviorSubject(0);
  private costFlagSource = new BehaviorSubject(false);
  private newHwmFlagSource = new BehaviorSubject(false);
  private uncheckedVisitsSource = new BehaviorSubject(0);
  private isFirstPRSource = new BehaviorSubject(false);
  private visitCostSource = new BehaviorSubject(0);
  private PRCalculationMethodSource = new BehaviorSubject("");

  visitsFromBackend = 0;
  paymentAmount = 0;
  calcMethod = "";
  visitCostResult = 0;
  tempVisitAmt = 0;

  private loadingInitialTable = new BehaviorSubject(false);
  initialLoadingTable = false;
  $currentInitialLoadingTable = this.loadingInitialTable.asObservable();

  private loadingInitialData = new BehaviorSubject(false);
  initialLoadingData = false;
  $currentInitialLoadingData = this.loadingInitialData.asObservable();

  private dataSourceInitial = new BehaviorSubject([]);
  initialDataSource = [];
  $currentInitialDataSource = this.dataSourceInitial.asObservable();

  private paymentLogDataSourceInitial = new BehaviorSubject(new MatTableDataSource<PostPaymentRequest>([]));
  initialPaymentLogDataSource: MatTableDataSource<PostPaymentRequest> = new MatTableDataSource<PostPaymentRequest>([]);
  $currentInitialPaymentLogDataSource = this.paymentLogDataSourceInitial.asObservable();  

  $currentAdditionalVisits = this.additionalVisitsSource.asObservable();
  $currentFacilityName = this.facilityNameSource.asObservable();
  $currentServiceName = this.serviceNameSource.asObservable();
  $currentFromDate = this.fromDateSource.asObservable();
  $currentToDate = this.toDateSource.asObservable();
  $currentVisitAmount = this.visitAmountSource.asObservable();
  $currentPaymentAmount = this.paymentAmountSource.asObservable();
  $currentNewHwmMem = this.newHwmNumMemSource.asObservable();
  $currentFinalPaymentAmount = this.finalPaymentAmountSource.asObservable();
  $currentCostFlag = this.costFlagSource.asObservable();
  $currentNewHwmFlag = this.newHwmFlagSource.asObservable();
  $currentUncheckedVisits = this.uncheckedVisitsSource.asObservable();
  $currentIsFirstPR = this.isFirstPRSource.asObservable();
  $currentVisitCost = this.visitCostSource.asObservable();
  $currentPRCalculationMethod = this.PRCalculationMethodSource.asObservable();

  constructor(private paymentservice: PaymentService) {}

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError(e => {
          throw e;
        })
      )
      .subscribe(
        res => {
          handleResultCallback(res);
        },
        err => {
          this.errorHandler(err);
        }
      );
  }

  private errorHandler(error, message: string = null) {
    // MessageBox.ShowError(this.dialog, message || 'An error occurred while trying to call a service');
    console.error(error);
  }

  // populates the values in add manual visits modal service
  populateValues(
    facilityName: string,
    serviceName: string,
    fromDate: string,
    toDate: string
  ) {
    this.facilityNameSource.next(facilityName);
    this.serviceNameSource.next(serviceName);
    this.fromDateSource.next(fromDate);
    this.toDateSource.next(toDate);
  }

  // calculates vists based on backend db value for corresponding parameters
  calculateVisits(
    facility: string,
    service: string,
    fromDate: string,
    toDate: string,
    pageIndex: number,
    user: string
  ) {
    const rawVisitValues = new RawVisitParameters();
    rawVisitValues.ModifiedBy = user;
    rawVisitValues.FacilityName = facility;
    rawVisitValues.ServiceName = service;
    rawVisitValues.FromDate = this.convertDateFormat(fromDate);
    rawVisitValues.ToDate = this.convertDateFormat(toDate);
    // result of the api call
    this.Invoke(
      this.paymentservice.getRawVisitAmount(rawVisitValues),
      (result: RawVisitCount) => {
        this.initialLoadingTable = true;
        this.loadingInitialTable.next(this.initialLoadingTable); 
        this.initialLoadingData = true;
        this.loadingInitialData.next(this.initialLoadingData);         
        this.initialDataSource = result.Data.FacilityPosts;
        this.dataSourceInitial.next(this.initialDataSource);
        this.initialPaymentLogDataSource = new MatTableDataSource<PostPaymentRequest>(this.initialDataSource);           
        this.paymentLogDataSourceInitial.next(this.initialPaymentLogDataSource);             
        this.visitsFromBackend = result.Data.VisitCount;
        this.visitAmountSource.next(this.visitsFromBackend);
        this.additionalVisitsSource.next(0);
        this.uncheckedVisitsSource.next(result.Data.NonDedupedVisits);
        this.isFirstPRSource.next(result.Data.IsFirstPaymentRequest);
        if(result.Data.paymentRequestCalculationMethod === 1) {
          this.calcMethod = "High Water Mark";
        }
        else if(result.Data.paymentRequestCalculationMethod === 2) {
          var visitDollarAmount = (Math.round(result.Data.VisitCost * 100) / 100).toFixed(2);
            this.calcMethod = "Per Visit ($" + visitDollarAmount + ")";
        }
        else {
          this.calcMethod = "Other";
        }

        this.visitCostResult = result.Data.VisitCost;
        this.PRCalculationMethodSource.next(this.calcMethod);
        this.visitCostSource.next(this.visitCostResult);
        console.log(result.Data);
        var isFinalCalc = false;
        this.calculateCost(
          facility,
          service,
          fromDate,
          toDate,
          result.Data.VisitCount,
          pageIndex,
          isFinalCalc
        );
      }
    );
  }

  perVisitCalc(fullCost: number, fullVisitAmount: number) {
    var perVisitCalcCheck = 0;
      if(fullVisitAmount >0) {
        perVisitCalcCheck = fullCost/fullVisitAmount;
      }
      else {
        perVisitCalcCheck = 0;
      }
      this.visitCostSource.next(perVisitCalcCheck);
  }

  // recalculates based on add manual visit amount
  recalculateVisits(currentVisits: number, manualVisits: number) {
    currentVisits += manualVisits;
    this.visitAmountSource.next(currentVisits);
    this.$currentAdditionalVisits.subscribe(
      additionalVisits => (this.tempVisitAmt = additionalVisits)
    );
    this.additionalVisitsSource.next(manualVisits + this.tempVisitAmt);
  }
  // calculates the cost based on the visit amount
  calculateCost(
    facility: string,
    service: string,
    fromDate: string,
    toDate: string,
    visits: number,
    pageIndex: number,
    isFinalCalcButton: boolean,
  ) {
    const costCalculationValues = new CostCalculationParameters();
    costCalculationValues.FacilityName = facility;
    costCalculationValues.FromDate = fromDate;
    costCalculationValues.ServiceName = service;
    costCalculationValues.ToDate = toDate;
    costCalculationValues.VisitCount = visits;
    costCalculationValues.PageCategory = pageIndex;
    console.log(costCalculationValues);
    this.Invoke(
      this.paymentservice.getCostCalculations(costCalculationValues),
      (result: DataResult<CalculationData>) => {
        console.log(result.Data);
        this.paymentAmount = result.Data.CalculationCost;
        this.paymentAmountSource.next(this.paymentAmount);
        this.finalPaymentAmountSource.next(this.paymentAmount);
        this.newHwmNumMemSource.next(result.Data.NewHWMNumMem);
        this.newHwmFlagSource.next(result.Data.IsNewHWM);
        this.costFlagSource.next(result.Data.IsThereCost);
        this.visitCostSource.next(this.visitCostResult);
        if(isFinalCalcButton) {
          this.perVisitCalc(this.paymentAmount, visits);
        } else {
          if(this.tempVisitAmt > 0) {
            this.perVisitCalc(this.paymentAmount, this.tempVisitAmt);
          }
          else {
            this.perVisitCalc(this.paymentAmount, visits);
          }
        }
      }
    );
  }

  // function to receive visits for edit payment request
  retrieveVisitsCount(visits: number, additionalVisits: number) {
    this.visitAmountSource.next(visits);
    this.additionalVisitsSource.next(additionalVisits);
  }

  reinitialiseValues() {
    this.facilityNameSource.next("");
    this.serviceNameSource.next("");
    this.fromDateSource.next("");
    this.toDateSource.next("");
    this.additionalVisitsSource.next(0);
    this.visitAmountSource.next(0);
    this.paymentAmountSource.next(0);
    this.finalPaymentAmountSource.next(0);
    this.newHwmNumMemSource.next(0);
    this.costFlagSource.next(false);
    this.newHwmFlagSource.next(false);
    this.uncheckedVisitsSource.next(0);
    this.isFirstPRSource.next(false);
    this.visitCostSource.next(0);
    this.PRCalculationMethodSource.next("");

    this.loadingInitialTable.next(false);
    this.loadingInitialData.next(false);
    this.dataSourceInitial.next([]);
    this.paymentLogDataSourceInitial.next(new MatTableDataSource<PostPaymentRequest>([]));
  }

  convertDateFormat(dateOld: string) {
    let dateNew = "";
    const monthArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    const monthNumArray = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12"
    ];
    for (let i = 0; i < monthArray.length; i++) {
      if (dateOld.indexOf(monthArray[i]) > -1) {
        dateNew =
          dateOld.substring(7, 11) +
          "-" +
          monthNumArray[i] +
          "-" +
          dateOld.substring(0, 2);
      }
    }
    return dateNew;
  }
}
