<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">View/Edit Members</h4>
<!-- View Users -->
<div class="mt-lg-4">
    <form>
        <div class="my-4">
            <div class="border-default rounded-10 border-2">
                <div class="px-2 fptablefreezeheadrow">
                    <mat-table #table matSort [dataSource]="bogusDataSource" class="fptablehead"
                        matSortActive="last_name" matSortDirection="desc" matSortStart="desc" 
                        #memberSort="matSort">
                        <!-- Name Column -->
                        <ng-container matColumnDef="LastName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.LastName}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="FirstName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.FirstName}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="EmployerForTable">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Employer</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.EmployerForTable}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Email">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.Email}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="MemberStatusForTable">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Member <br /> Status </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element.MemberStatusForTable}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="MembershipStatusForTable">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Membership <br /> Status
                            </mat-header-cell>

                            <mat-cell [ngStyle]="{'color':getColor(element.MembershipStatus)}"
                                *matCellDef="let element">
                                <div *ngIf="element.MemberNumber">
                                    {{element.MembershipStatusForTable}} </div>
                            </mat-cell>

                        </ng-container>

                        <ng-container matColumnDef="MemberNumber">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Member <br /> Number</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.MemberNumber}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="MemberId">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Member ID</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.MemberId}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="MemberTypeForTable">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Member <br /> Type</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.MemberTypeForTable}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="MemberIdPrimary">
                                <mat-header-cell *matHeaderCellDef >Primary Member<br /> ID</mat-header-cell>
                                <mat-cell *matCellDef="let element"> 
                                        <div *ngIf="element.MemberIdPrimary" class="row">
                                        <span class="col" style="color: blue; cursor: pointer;"
                                            (click)="openLink(element.MemberIdPrimary)">{{ element.MemberIdPrimary }}</span>
                                        </div>
                                 </mat-cell>
                            </ng-container>

                        <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"></mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </mat-table>



                    <mat-table #table [dataSource]="dataSourceMember" class="fpscrolly height3 d-block">
                        <!-- Name Column -->
                        <ng-container matColumnDef="LastName">
                            <mat-header-cell *matHeaderCellDef ></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.LastName}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="FirstName">
                            <mat-header-cell *matHeaderCellDef ></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.FirstName}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="EmployerForTable">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.EmployerForTable}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Email">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.Email}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="MemberStatusForTable">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element.MemberStatusForTable}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="MembershipStatusForTable">
                            <mat-header-cell *matHeaderCellDef ></mat-header-cell>

                            <mat-cell [ngStyle]="{'color':getColor(element.MembershipStatusForTable)}"
                                *matCellDef="let element">
                                <div *ngIf="element.MemberNumber">
                                    {{element.MembershipStatusForTable}} </div>
                            </mat-cell>

                        </ng-container>

                        <ng-container matColumnDef="MemberNumber">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.MemberNumber}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="MemberId">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.MemberId}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="MemberTypeForTable">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.MemberTypeForTable}} </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="MemberIdPrimary">
                            <mat-header-cell *matHeaderCellDef ></mat-header-cell>
                            <mat-cell *matCellDef="let element"> 
                                <div *ngIf="element.MemberIdPrimary" class="row">
                                <span class="col" style="color: blue; cursor: pointer;"
                                    (click)="openLink(element.MemberIdPrimary)">{{ element.MemberIdPrimary }}</span>
                                </div>
                             </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element" class="justify-content-end">
                                <a *ngIf="checkShowBan(element.MemberStatus.Id)" class="px-2"
                                    (click)="selectInactiveBanMember(element)">
                                    <i class="fas fa-ban text-danger"></i>
                                </a>
                                <a class="px-2" [routerLink]="[routerConstant.NAVIGATOR_MEMBER_EDIT, element.MemberId]">
                                    <i class="fas fa-pen"></i>
                                </a>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns" [style.min-height]="0"></mat-header-row>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </mat-table>
                    <mat-toolbar [hidden]="dataSourceMember == null" class="bg-white">
                        <mat-toolbar-row
                            [hidden]="(dataSourceMember != null && dataSourceMember.data.length == 0) || SearchMemberBasedOnEmployer==true"
                            class="p-0">
                            <mat-paginator class="w-100" [length]="length" [pageSize]="pageSize"
                                [pageSizeOptions]="pageSizeOptions" (page)="paginatorChange($event)"
                                showFirstLastButtons>
                            </mat-paginator>
                        </mat-toolbar-row>
                        <mat-toolbar-row *ngIf="dataSourceMember != null && dataSourceMember.data.length == 0"
                            class="text-muted d-block p-2 bg-light text-center">
                            {{CommonString.TABLE_NO_RECORD_FOUND}}
                        </mat-toolbar-row>
                    </mat-toolbar>
                </div>
            </div>
        </div>
        <button class="px-5 py-2 btn bg-white border-primary text-primary" type="button"
            (click)="this.router.navigate([routerConstant.NAVIGATOR_MEMBER_SEARCH]);">
            <i class="fas fa-arrow-alt-circle-left mr-2"></i>BACK</button>
    </form>
</div>