<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Add/Edit User</h4>
<div class="mx-auto tabProgress w-75 d-none d-lg-flex mt-3">
    <div class="flex-fill text-center">
        <div class='card-icon rounded-circle mx-auto btn'
            [ngClass]="!user || stepper.selectedIndex == 0 ? 'bg-primary' : userDetailsFormGroup.valid ? 'bg-primary' : null "
            (click)="ChangeTab(0);">
            <i [ngClass]="stepper.selectedIndex != 0 ?'fas fa-check text-light':''"></i>
        </div>
        <p class="card-text mb-3">User Details</p>
    </div>
    <div class="flex-fill text-center">
        <div class='card-icon rounded-circle mx-auto btn'
            [ngClass]="roles.length == 0  || stepper.selectedIndex == 1 ? 'bg-primary' : rolesFormGroup.valid ? 'bg-primary' : null "
            (click)="ChangeTab(1);">
            <i [ngClass]="(stepper.selectedIndex != 1)?'fas fa-check text-light':''"></i>
        </div>
        <p class="card-text mb-3">Roles</p>
    </div>
    <div class="flex-fill text-center">
        <div class='card-icon rounded-circle mx-auto btn' [ngClass]="'bg-primary'" (click)="ChangeTab(2);">
            <i [ngClass]="stepper.selectedIndex != 2?'fas fa-check text-light':''"></i>
        </div>
        <p class="card-text mb-3">Account</p>
    </div>
</div>
<div class="tabFPTheme mb-5" #stepperWrapperRef [style.height]="tabHeight">
    <mat-vertical-stepper [linear]="setLinear()" #stepper (selectionChange)="stepClick($event)">
        <mat-step [stepControl]="userDetailsFormGroup" #stepper1>
            <form [formGroup]="userDetailsFormGroup" id="userRoleTab0">
                <ng-template matStepLabel><i class="fas fa-check icon-fp d-lg-none"></i><span
                        (click)="ChangeTab(-1)">User
                        Details</span></ng-template>
                <ngb-accordion [destroyOnHide]="false" activeIds="panelUserDetail, panelContact, panelMFA">
                    <ngb-panel id="panelUserDetail">
                        <ng-template ngbPanelTitle>
                            <i [ngClass]="ValidateUserDetailsSection()==true?'fas fa-check icon-fp':'icon-fp'"></i>
                            User Details
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="form-group row">
                                <label for="TitleId" class="col-md-3 col-lg-2 col-form-label">Title</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select class="form-control" id="TitleId" formControlName="TitleId">
                                            <option [ngValue]="undefined">Please select a value</option>
                                            <option *ngFor="let t of titles" [ngValue]="t.Id">{{ t.Name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="FirstName" class="col-md-3 col-lg-2 col-form-label">First Name<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" maxlength="50" [ngClass]="(userDetailsFormGroup.get('FirstName').invalid
                                            && (userDetailsFormGroup.get('FirstName').dirty ||
                                            userDetailsFormGroup.get('FirstName').touched
                                            || btnNextClicked))?'form-control is-invalid':'form-control'"
                                            id="FirstName" formControlName="FirstName">
                                        <div *ngIf="userDetailsFormGroup.get('FirstName').invalid
                                            && (userDetailsFormGroup.get('FirstName').dirty
                                            || userDetailsFormGroup.get('FirstName').touched
                                            || btnNextClicked)">
                                            <small *ngIf="userDetailsFormGroup.get('FirstName').errors.required"
                                                class="invalid-feedback d-block">First
                                                Name is required</small>
                                            <small *ngIf="userDetailsFormGroup.get('FirstName').errors.pattern"
                                                class="invalid-feedback d-block">This
                                                field does not accept special characters. Please try again.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="MiddleName" class="col-md-3 col-lg-2 col-form-label">Middle Name</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" maxlength="50" class="form-control" id="MiddleName"
                                            formControlName="MiddleName">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="LastName" class="col-md-3 col-lg-2 col-form-label">Last Name<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" id="LastName" formControlName="LastName" maxlength="50"
                                            [ngClass]="(userDetailsFormGroup.get('LastName').invalid
                                            && (userDetailsFormGroup.get('LastName').dirty ||
                                            userDetailsFormGroup.get('LastName').touched
                                            || btnNextClicked))?'form-control is-invalid':'form-control'">
                                        <div *ngIf="userDetailsFormGroup.get('LastName').invalid
                                            && (userDetailsFormGroup.get('LastName').dirty
                                            || userDetailsFormGroup.get('LastName').touched
                                            || btnNextClicked)">
                                            <small *ngIf="userDetailsFormGroup.get('LastName').errors.required"
                                                class="invalid-feedback d-block">Last
                                                Name is required</small>
                                            <small *ngIf="userDetailsFormGroup.get('LastName').errors.pattern"
                                                class="invalid-feedback d-block">This
                                                field does not accept special characters. Please try again.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="PreferredName" class="col-md-3 col-lg-2 col-form-label">Preferred
                                    Name</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" class="form-control" id="PreferredName" maxlength="50"
                                            formControlName="PreferredName">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row date-birthday">
                                <label class="col-md-3 col-lg-2 col-form-label">Date of Birth</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="input-group"
                                            [ngClass]="(userDetailsFormGroup.get('DateOfBirth').value != null && userDetailsFormGroup.get('DateOfBirth').invalid
                                            && (userDetailsFormGroup.get('DateOfBirth').dirty ||
                                            userDetailsFormGroup.get('DateOfBirth').touched || userDetailsFormGroup.get('DateOfBirth').untouched))?'is-invalid':''"
                                            [class.focused]="parentFocus==1">
                                            <input placeholder="DD-MM-YYYY" name="dp" ngbDatepicker #d="ngbDatepicker"
                                                formControlName="DateOfBirth" class="form-control date-picker"
                                                [maxDate]="presentTime" [minDate]="minDate" (blur)="onBlurParent(0)"
                                                (focus)="onFocusParent(1)">
                                            <div class="input-group-append">
                                                <button class="btn bg-white" (click)="d.toggle()" type="button"><i
                                                        class="far fa-calendar-alt"></i></button>
                                            </div>
                                        </div>
                                        <small
                                            *ngIf="userDetailsFormGroup.get('DateOfBirth').value != null && userDetailsFormGroup.get('DateOfBirth').invalid
                                        && (userDetailsFormGroup.get('DateOfBirth').dirty ||
                                        userDetailsFormGroup.get('DateOfBirth').touched || userDetailsFormGroup.get('DateOfBirth').untouched)"
                                            class="invalid-feedback d-block">Date
                                            of Birth is invalid</small>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="GenderId" class="col-md-3 col-lg-2 col-form-label">Gender</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select class="form-control" id="GenderId" formControlName="GenderId">
                                            <option [ngValue]="undefined">Please select a value</option>
                                            <option *ngFor="let g of genders" [ngValue]="g.Id">{{ g.Name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="status" class="col-md-3 col-lg-2 col-form-label">Status<span
                                        class="text-danger">*</span>
                                </label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select class="form-control" id="status" formControlName="IsActive" [ngClass]="(userDetailsFormGroup.get('IsActive')?.invalid
                                            && (userDetailsFormGroup.get('IsActive').dirty ||
                                            userDetailsFormGroup.get('IsActive').touched
                                            || btnNextClicked))?'form-control is-invalid':'form-control'">
                                            <option [ngValue]="true">Active</option>
                                            <option [ngValue]="false">Inactive</option>
                                        </select>

                                        <div *ngIf="userDetailsFormGroup.get('IsActive')?.invalid
                                            && (userDetailsFormGroup.get('IsActive').dirty
                                            || userDetailsFormGroup.get('IsActive').touched
                                            || btnNextClicked)">
                                            <small class="invalid-feedback d-block">Roles is required</small>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="panelContact">
                        <ng-template ngbPanelTitle>
                            Contact Details
                            <i [ngClass]="ValidateContactSection()==true?'fas fa-check icon-fp':'icon-fp'"></i>
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-mobile-number-country [mandatory]="false"
                                [control]="userDetailsFormGroup.get('MobileNumber')" formControlName="MobileNumber"
                                [clsLabel]="'col-md-3 col-form-label col-lg-2'" [clsGroup]="'col-md-9 col-lg-5'"
                                [label]="'Mobile Number'" [checkExist]="true" [currentPhone]="editCurrentPhone">
                            </app-mobile-number-country>

                            <div class="form-group row">
                                <label for="EmailAddress" class="col-md-3 col-lg-2 col-form-label">Email Address<span
                                        class="text-danger">*</span></label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" maxlength="100" [ngClass]="(userDetailsFormGroup.get('EmailAddress').invalid
                                            && (userDetailsFormGroup.get('EmailAddress').dirty ||
                                            userDetailsFormGroup.get('EmailAddress').touched
                                            || btnNextClicked))?'form-control is-invalid':'form-control'"
                                            id="EmailAddress" formControlName="EmailAddress" (blur)="checkExistEmail()">
                                        <mat-spinner [diameter]="14" *ngIf="mailSpinner"></mat-spinner>
                                        <div *ngIf="userDetailsFormGroup.get('EmailAddress').invalid
                                            && (userDetailsFormGroup.get('EmailAddress').dirty
                                            || userDetailsFormGroup.get('EmailAddress').touched
                                            || btnNextClicked)">
                                            <small *ngIf="userDetailsFormGroup.get('EmailAddress').errors.required"
                                                class="invalid-feedback d-block">Email Address is required</small>
                                            <small *ngIf="userDetailsFormGroup.get('EmailAddress').errors.pattern"
                                                class="invalid-feedback d-block">Email Address is invalid</small>
                                            <small *ngIf="userDetailsFormGroup.get('EmailAddress').errors['duplicated']"
                                                class="invalid-feedback d-block">The email address already
                                                exists</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                    <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" matStepperNext
                        (click)="ChangeTab(-1)"><i class="fas fa-arrow-circle-right mr-2"></i>
                        Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="rolesFormGroup" #stepper2>
            <form [formGroup]="rolesFormGroup" id="userRoleTab1">
                <ng-template matStepLabel><i class="fas fa-check icon-fp d-lg-none"></i><span
                        (click)="ChangeTab(-1)">Roles</span></ng-template>
                <ngb-accordion activeIds="panelRoles">
                    <ngb-panel id="panelRoles">
                        <ng-template ngbPanelTitle>
                            Roles
                            <i class="icon-fp"></i>
                            <i [ngClass]="rolesFormGroup.valid?'fas fa-check icon-fp':'icon-fp'"></i>
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="form-group row">
                                <label for="Role" class="col-md-3 col-lg-2 col-form-label">Role<span
                                        class="text-danger">*</span>
                                </label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select class="form-control" id="Role" formControlName="Role"
                                            (ngModelChange)="selectRole($event)" [ngClass]="(rolesFormGroup.get('RolesId')?.invalid
                                            && (rolesFormGroup.get('Role').dirty ||
                                            rolesFormGroup.get('Role').touched
                                            || btnNextClicked))?'form-control is-invalid':'form-control'">
                                            <option [ngValue]="0">Please select a value</option>
                                            <option [disabled]="setRoleDisabled(role)" *ngFor="let role of roles"
                                                [ngValue]="role.Id">{{ role?.Name }}</option>
                                        </select>

                                        <div *ngIf="rolesFormGroup.get('RolesId')?.invalid
                                            && (rolesFormGroup.get('Role').dirty
                                            || rolesFormGroup.get('Role').touched
                                            || btnNextClicked)">
                                            <small class="invalid-feedback d-block">Role is required</small>
                                        </div>

                                        <div class="mt-2 slctSelectedRoles w-100 border-2 rounded-10 border-default text-default"
                                            *ngIf="roleTypesSelected.length > 0">
                                            <div class="d-flex border-default border-bottom py-1 align-content-center"
                                                *ngFor="let rt of roleTypesSelected">
                                                <button class="text-primary d-flex align-items-center btnRole"
                                                    (click)="unsetRoleSelected(rt)">
                                                    <i class="fas fa-minus-circle fa-2x"></i>
                                                </button>
                                                <p class="d-flex align-items-center m-0 ml-2">{{ rt.Name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Employer facilitator -->
                            <div class="employer-facilitator" *ngIf="showRoleDetails('facilitator')">
                                <!-- Add Employer -->
                                <div class="form-group row">
                                    <label for="txtAddEmployer" class="col-md-3 col-lg-2 col-form-label">Add
                                        Employer<span class="text-danger">*</span>
                                    </label>
                                    <div class="col-md-8 col-lg-5">
                                        <div class="d-flex flex-column w-100">
                                            <div class="input-group" [ngClass]="(rolesFormGroup.get('FacilitatorsId')?.invalid
                                                && (rolesFormGroup.get('FacilitatorsId').dirty ||
                                                rolesFormGroup.get('FacilitatorsId').touched
                                                || btnNextClicked))?'is-invalid':''" [class.focused]="parentFocus==2">
                                                <input class="form-control" type="text"
                                                    placeholder="Search employer by employer name" id="txtAddEmployer"
                                                    formControlName="addFacilitator" [matAutocomplete]="auto"
                                                    (blur)="onBlurParent(0)" (focus)="onFocusParent(2)">

                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="fas fa-search text-primary"
                                                            *ngIf="!facilitatorSpinner.isBool"></i>
                                                        <mat-spinner *ngIf="facilitatorSpinner.isBool" [diameter]="14">
                                                        </mat-spinner>
                                                    </span>
                                                </div>
                                            </div>

                                            <div *ngIf="rolesFormGroup.get('FacilitatorsId')?.invalid
                                                && (rolesFormGroup.get('FacilitatorsId').dirty
                                                || rolesFormGroup.get('FacilitatorsId').touched
                                                || btnNextClicked)">
                                                <small class="invalid-feedback d-block">Employer is required</small>
                                            </div>

                                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                                <mat-option *ngFor="let f of searchFacilitators" [value]="">
                                                    <p class="m-0 d-flex align-items-center" title={{f.Name}}
                                                        style="text-overflow: ellipsis; white-space: nowrap;overflow: hidden;"
                                                        (click)="setFacilitatorsSelected(f);">
                                                        {{ f.Name }} ({{f.Code }})</p>
                                                    <span class="text-primary d-flex align-items-center"
                                                        (click)="setFacilitatorsSelected(f)">
                                                        <i class="fas fa-plus-circle fa-2x"></i>
                                                    </span>
                                                </mat-option>
                                            </mat-autocomplete>
                                        </div>
                                    </div>
                                </div>
                                <!-- End. Add Employer -->
                                <!-- Select Primary Facilitator -->
                                <div class="form-group row popover-error" *ngIf="facilitatorsSelected.length > 0;">
                                    <label for="txtSearch" class="col-md-3 col-lg-2 col-form-label">Select Primary
                                        Facilitator</label>
                                    <div class="col-md-8 col-lg-5">
                                        <div class="d-flex flex-column w-100">
                                            <div
                                                class="slctRoles height1 fpscrolly border-2 rounded-10 border-default mt-3">
                                                <div class="d-flex justify-content-between border-default border-bottom py-1 align-content-center"
                                                    *ngFor="let f of facilitatorsSelected" [ngbPopover]="popContent"
                                                    placement="right" triggers="manual" #popover="ngbPopover">
                                                    <div class="d-flex align-items-center">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input"
                                                                [checked]="f.IsPrimaryFacilitator"
                                                                (click)="setPrimaryFacilitator(popover,f,$event)">
                                                            <label class="custom-control-label">{{ f.Name }}
                                                                ({{f.Code}})</label>
                                                        </div>
                                                    </div>
                                                    <span class="text-primary d-flex align-items-center"
                                                        (click)="unsetFacilitatorsSelected(f)">
                                                        <i class="fas fa-minus-circle fa-2x"></i>
                                                    </span>
                                                    <!-- <p class="d-flex align-items-center m-0 ml-2">{{ f.Name }}</p> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-template #popContent>
                                        <div class="d-flex align-items-center">
                                            <i class="fa fa-exclamation-circle mr-2"></i>
                                            <span>ERROR - <br>Primary facilitator has already been selected for this
                                                employer.</span>
                                        </div>
                                    </ng-template>
                                </div>
                                <!-- End. Select Primary Facilitator -->
                            </div>
                            <!-- End. Employer facilitator -->

                            <!-- Facility staff member-->
                            <div class="facility-staff-member form-group row" *ngIf="showRoleDetails('facility')">
                                <label for="txtFacility" class="col-md-3 col-lg-2 col-form-label">Facility<span
                                        class="text-danger">*</span>
                                </label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="input-group" [ngClass]="(rolesFormGroup.get('FacilitiesId')?.invalid
                                            && (rolesFormGroup.get('FacilitiesId').dirty ||
                                            rolesFormGroup.get('FacilitiesId').touched
                                            || btnNextClicked))?'is-invalid':''" [class.focused]="parentFocus==3">
                                            <input class="form-control" type="text"
                                                placeholder="Search facility by facility name" id="txtFacility"
                                                [formControl]="addFacility" [matAutocomplete]="auto1"
                                                (blur)="onBlurParent(0)" (focus)="onFocusParent(3)">
                                            <div class="input-group-append">
                                                <span class="input-group-text">
                                                    <i class="fas fa-search text-primary"
                                                        *ngIf="!facilitySpinner.isBool"></i>
                                                    <mat-spinner *ngIf="facilitySpinner.isBool" [diameter]="14">
                                                    </mat-spinner>
                                                </span>
                                            </div>
                                        </div>

                                        <div *ngIf="rolesFormGroup.get('FacilitiesId')?.invalid
                                            && (rolesFormGroup.get('FacilitiesId').dirty
                                            || rolesFormGroup.get('FacilitiesId').touched
                                            || btnNextClicked)">
                                            <small class="invalid-feedback d-block">Facility is required</small>
                                        </div>

                                        <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFn">
                                            <mat-option *ngFor="let f of searchFacilities" [value]="">
                                                <p class="m-0 d-flex align-items-center" title={{f.Name}}
                                                    style="text-overflow: ellipsis; white-space: nowrap;overflow: hidden;"
                                                    (click)="setFacilitiesSelected(f);">
                                                    {{ f.Name }}</p>
                                                <span class="text-primary d-flex align-items-center"
                                                    (click)="setFacilitiesSelected(f)">
                                                    <i class="fas fa-plus-circle fa-2x"></i>
                                                </span>
                                            </mat-option>
                                        </mat-autocomplete>
                                        <div class="mt-2 slctSelectedRoles w-100 border-2 rounded-10 border-default text-default"
                                            *ngIf="facilitiesSelected.length > 0;">
                                            <div class="d-flex border-default border-bottom py-1 align-content-center"
                                                *ngFor="let f of facilitiesSelected">
                                                <span class="text-primary d-flex align-items-center"
                                                    (click)="unsetFacilitiesSelected(f)">
                                                    <i class="fas fa-minus-circle fa-2x"></i>
                                                </span>
                                                <p class="d-flex align-items-center m-0 ml-2">{{ f.Name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End. Facility staff member -->

                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                    <button mat-button matStepperNext class="px-2 px-lg-5 py-2 btn btn-primary" type="button"
                        (click)="ChangeTab(-1)">
                        <i class="fas fa-arrow-circle-right mr-2"></i>
                        Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="accountFormGroup" #stepper3>
            <form [formGroup]="accountFormGroup" id="userRoleTab2">
                <ng-template matStepLabel><i class="fas fa-check icon-fp d-lg-none"></i><span
                        (click)="ChangeTab(-1)">Account</span></ng-template>
                <ngb-accordion activeIds="panelAccount">
                    <ngb-panel id="panelAccount">
                        <ng-template ngbPanelTitle>
                            Account
                            <i [ngClass]="rolesFormGroup.valid?'fas fa-check icon-fp':'icon-fp'"></i>
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="form-group row">
                                <label for="txtUserName" class="col-md-3 col-lg-2 col-form-label">Username</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="faux-text-input-field">
                                            <span>{{user.UserName}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtPassword" class="col-md-3 col-lg-2 col-form-label">Password</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="faux-text-input-field user-select-none">
                                            <span>***********</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtConfirmPassword" class="col-md-3 col-lg-2 col-form-label">Confirm
                                    Password</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="faux-text-input-field user-select-none">
                                            <span>***********</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div
                    class="btn-toolbar card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                    <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="saveUser()"><i
                            class="fas fa-check mr-2"></i>
                        Submit</button>
                    <button class="px-2 px-lg-4 py-2 btn btn-danger" type="button" (click)="resetPassword()">Reset
                        Password</button>
                </div>
            </form>
        </mat-step>
    </mat-vertical-stepper>
</div>