<div class="form-group row">
    <label [attr.for]="getName(control)" class="col-md-3 col-lg-2 col-form-label">
        {{ name }}<span class="text-danger">{{ mandatory ? '*' : '' }}</span>
    </label>
    <div class="col-md-8 col-lg-5">
        <div class="d-flex flex-column w-100">
            <input 
                type="text" 
                [attr.maxlength]="maxlength" 
                [ngClass]="checkValidate() ? 'form-control is-invalid':'form-control'"
                [attr.id]="getName(control)" 
                [attr.placeholder]="placeholder"
                [(ngModel)]="value"
                (blur)="onTouched()"
            >
            <div *ngIf="checkValidate()">
                <small *ngIf="control.errors.required" class="invalid-feedback d-block">
                    {{ name }} is required
                </small>
                <small *ngIf="control.errors.pattern" class="invalid-feedback d-block">
                    This field does not accept special characters. Please try again.
                </small>
            </div>
        </div>
    </div>
</div>