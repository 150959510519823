import { CommonService } from './common.service';
import { SearchPaginationRequest } from 'src/app/models';
import { APIConstant } from './../constant/apiconstant';
import { RequestPagination } from './../models/request-paging.model';
import { HttpDAO } from '../services/httpdao.service';
import { Injectable } from '@angular/core';
import { merge, of as observableOf, forkJoin } from 'rxjs';
import { startWith, switchMap, catchError, map, shareReplay, retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class WarmupAPIService {
    constructor(private http: HttpClient,
        private commonservice: CommonService) { }

    public GetCurrentUATServerDate() {
        let httpdao: HttpDAO | null;
        httpdao = new HttpDAO(this.http);
        merge().pipe(
            startWith({}),
            switchMap(() => {
                return httpdao!.getSingleDataWithoutToken(APIConstant.API_GET_SERVER_UTC_DATE);
            }),
            map(result => {
                return result;
            }),
            retry(5),
            catchError(() => {
                return observableOf([]);
            })
        ).subscribe((result) => {
            const dt: Date = new Date(result.Data);
            this.commonservice.CurrentUTCServerDate = dt;
        });
    }
}