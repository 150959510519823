export class BankAccount {
    BankAccountId: number;
    BankAccountName: string;
    BankAccountBsb: string;
    BankAccountNumber: string;
    BankAccountType: string;
    BankAccountNumberMask: string;
    BankName: string;
    ModifiedBy: string    
}
