<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Facility Members</h4>
<div class="mt-lg-4">
    <p>From this page you can search for a member to check the status of their Fitness Passport membership. Only members
        who can access your facility via their chosen membership package will show up in your search. To search, you can
        enter the members Name or ID number.</p>
    <div class="form-group row" *ngIf="availableFacilities.length > 1">
        <label for="ddlAvailableFacilities" class="col-md-3 col-lg-2 col-form-label">Available Facilities</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select id="ddlAvailableFacilities" class="form-control" [formControl]="availableFacilitiesCtrl">
                    <option [ngValue]="null" selected>All facilities</option>
                    <option *ngFor="let f of availableFacilities" [ngValue]="f.FacilityId">{{ f.Name }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="searchName" class="col-md-3 col-lg-2 col-form-label">Search Members<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5" style="flex-direction: column;">
            <div class="input-group" [ngClass]="getClassFocused(1)">
                <input class="form-control" type="text" placeholder="Search by Member Name or Member ID"
                    (blur)="onBlurParent(0)" (focus)="onFocusParent(1)" #txtFacilityMembersFilter
                    (keyup)="txtFilterFacilityMembers_KeyUp($event)">
                <div class="input-group-append">
                    <span class="input-group-text" (click)="gotoSearchFacilityMembers()"><i
                            class="fas fa-search"></i></span>
                </div>
            </div>
            <small *ngIf="requiresInput" class="invalid-feedback d-block">Please enter a value into this field.</small>
        </div>
    </div>
    <div class="my-4">
        <div class="border-default rounded-10 border-2">
            <div class="tablecontainer">
                <table mat-table matSort [dataSource]="dataSource" class="rounded-10" matSortActive="last_name"
                    matSortDirection="desc" matSortStart="desc" matSortDisableClear (matSortChange)="sortChange($event)">
                    <!-- Name Column -->
                    <ng-container matColumnDef="last_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="first_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="MemberStatus">
                        <th mat-header-cell *matHeaderCellDef> Member Status </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="getStatus(element.MemberStatus.Name)">
                            {{element.MemberStatus.Name}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="MembershipStatus">
                        <th mat-header-cell *matHeaderCellDef> Membership Status </th>
                        <td mat-cell  *matCellDef="let element" [ngClass]="getStatus(element.MembershipStatusName)">
                            {{element.MembershipStatusName}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="MemberNumber">
                        <th mat-header-cell *matHeaderCellDef>Membership No.</th>
                        <td mat-cell *matCellDef="let element"> {{element.MemberNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="MemberId">
                        <th mat-header-cell *matHeaderCellDef>Member ID</th>
                        <td mat-cell *matCellDef="let element"> {{element.MemberId}} </td>
                    </ng-container>

                    <ng-container matColumnDef="PhotoLocation">
                        <th mat-header-cell *matHeaderCellDef>Photo</th>
                        <td mat-cell *matCellDef="let element" class="p-0" style="width: 183px;">
                            <div
                                [ngClass]="element.PhotoLocation!='' && element.PhotoLocation !=null? 'my-2 border border-primary' : 'my-2'">
                                <div *ngIf="element.PhotoLocation=='' || element.PhotoLocation==null"
                                    class="bg-secondary fp-profilephoto d-flex justify-content-center align-items-center mx-auto">
                                    <i class="fas fa-user-tie fa-4x text-secondary"></i>
                                </div>
                                <div *ngIf="element.PhotoLocation!='' && element.PhotoLocation !=null"
                                    class="fpPictureCover d-flex align-items-center justify-content-center">
                                    <img [src]="element.PhotoLocation" class="maxWH1" alt="Membership card Photo">
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <mat-toolbar [hidden]="dataSource == null" class="bg-white">
                <!-- <mat-toolbar-row [hidden]="dataSource != null && dataSource.data.length == 0" class="p-0">
                    <mat-paginator class="w-100 mat-paginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
                    </mat-paginator>
                </mat-toolbar-row> -->
                <mat-toolbar-row [hidden]="dataSource != null && dataSource.data.length == 0" class="p-0">
                    <mat-paginator class="w-100" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                        (page)="paginatorChange($event)" showFirstLastButtons>
                    </mat-paginator>
                </mat-toolbar-row>
                <mat-toolbar-row *ngIf="dataSource != null && dataSource.data.length == 0"
                    class="text-muted d-block p-2 bg-light text-center">
                    {{commonString.TABLE_NO_RECORD_FOUND}}
                </mat-toolbar-row>
            </mat-toolbar>
        </div>
    </div>
</div>