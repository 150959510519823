import { Directive, forwardRef, Input, Renderer2, ElementRef, Optional, Inject, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR, DefaultValueAccessor, COMPOSITION_BUFFER_MODE } from '@angular/forms';

export const RESTRICTED_PATTERN_INPUT_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RestrictedPatternInputDirective),
    multi: true
};

@Directive({
    selector: 'input[restrictedPattern]',
    host: {
        '(input)': '$any(this)._handleInput($event.target.value)',
        '(blur)': 'onTouched()',
        '(compositionstart)': '$any(this)._compositionStart()',
        '(compositionend)': '$any(this)._compositionEnd($event.target.value)'
    },
    providers: [RESTRICTED_PATTERN_INPUT_VALUE_ACCESSOR]
})
export class RestrictedPatternInputDirective extends DefaultValueAccessor {
    @Input() restrictedPattern: string;
    private _element: HTMLElement;
    constructor(_renderer: Renderer2, _elementRef: ElementRef,
        @Optional() @Inject(COMPOSITION_BUFFER_MODE) _compositionMode: boolean) {
        super(_renderer, _elementRef, _compositionMode);
        this._element = _elementRef.nativeElement;
    }

    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
        let value = (<HTMLInputElement>event.srcElement).value;
        const specialKeyPressed = event.ctrlKey || event.altKey || event.metaKey;
        const keyCode = event.keyCode || event.which;
        const isCharacterKey = (event.key || '').length === 1;
        const isCopyingOrPasting = event.ctrlKey && (keyCode === 67 || keyCode === 86);
        if (!isCopyingOrPasting && isCharacterKey) {
            value = <string>this.getInsertedValue(event.srcElement, event.key);
        }
        if (this.restrictedPattern && value && !value.match(this.restrictedPattern)) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return false;
        }
    }

    @HostListener('change', ['$event']) nativeOnChange(event: Event) {
        let value = (<HTMLInputElement>event.srcElement).value;
        this.writeValue(value);
        this.onChange(value);
    }

    @HostListener('paste', ['$event']) onPaste(event) {
        let value = '';
        if (window['clipboardData'] && window['clipboardData'].getData) { // IE
            value = window['clipboardData'].getData('Text');
        } else if (event.clipboardData && event.clipboardData.getData) {
            value = event.clipboardData.getData('text/plain');
        }
        event.preventDefault();
        event.stopPropagation();
        const targetElement = event.target || this._element;
        value = this.getInsertedValue(targetElement, value);
        if (!this.restrictedPattern || value.match(this.restrictedPattern)) {
            this.writeValue(value);
            this.onChange(value);
        }
    }

    private getInsertedValue(elem, value) {
        if (document['selection']) {
            // IE support
            elem.focus();
            const sel = document['selection'].createRange();
            sel.text = value;
            value = elem.value;
        } else if (elem.selectionStart || elem.selectionStart === 0) {
            // MOZILLA and others
            const startPos = elem.selectionStart;
            const endPos = elem.selectionEnd;
            value = elem.value.substring(0, startPos)
                + value
                + elem.value.substring(endPos, elem.value.length);
        } else {
            value = elem.value + value;
        }
        if (elem.maxLength) {
            value = value.substring(0, elem.maxLength);
        }
        return value;
    }
}
