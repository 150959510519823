import { Component, Injector, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MemberFormBase } from '@fp/components/base/member-form-base';
import { Member } from '@fp/models/member.model';
import { ReactivationPackageSummaryComponent } from './reactivation-package-summary/reactivation-package-summary.component';
import { MemberBankAccountComponent } from '@fp/components/members/shared/bank-account/bank-account.component';

@Component({
  selector: 'app-reactivation-payment-details',
  templateUrl: './reactivation-payment-details.component.html',
  styleUrls: ['./reactivation-payment-details.component.css'],
  providers: [{ provide: MemberFormBase, useExisting: ReactivationPaymentDetailsComponent }]
})
export class ReactivationPaymentDetailsComponent extends MemberFormBase {

  @ViewChild(ReactivationPackageSummaryComponent) membershipPackageCostSummarySection: ReactivationPackageSummaryComponent;
  @ViewChild(MemberBankAccountComponent) bankAccountSection: MemberBankAccountComponent;
  
  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  OnInit() {
    this.membershipPackageCostSummarySection.form.disable({ emitEvent: false });
    super.OnInit();
  }

  static getFormGroup() {
    const fb = new FormBuilder();
    return fb.group({
      BankAccountSection: MemberBankAccountComponent.getFormGroup()
    });
  }

  OnLoad() {
    var a = "";
  }


  PatchValue(value: Member, opts) {
    const memberships = value.Memberships || [];
    this.membershipPackageCostSummarySection.membershipPackage =
      memberships.length > 0 && memberships[0]
        ? memberships[0].MembershipPackage
        : null;
    this.membershipPackageCostSummarySection.dataSource = [value].concat(
      value.FamilyMembers.filter( x=> x.IsReactivated)
    );
    super.PatchValue(value, opts);
  }

}
