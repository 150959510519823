import { Component, Input } from '@angular/core';
import { BaseComponent, MakeProvider } from '../base/base';

@Component({
  selector: 'app-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.css'],
  host: { '(blur)': 'onTouched()' },
  providers: [ MakeProvider(TextboxComponent) ]
})
export class TextboxComponent extends BaseComponent<string> {
  @Input() maxlength: number;
  @Input() placeholder: string;

}
