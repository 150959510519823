import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { FPFormBaseComponent, MemberFormBase } from '@fp/components/base';
import { MemberContractDetailsComponent } from '../shared';
import { CommonService, MemberService, MessageBox } from '@fp/services';
import { Router } from '@angular/router';
import { User, DataResult, Member } from '@fp/models';
import { StorageKey, RouterConstant } from '@fp/constant';
import { MemberContainer } from '@fp/models/member-container.model';

@Component({
  selector: 'app-member-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.css'],
  providers: [
    FPFormBaseComponent.provideExisting(MemberContractsComponent),
    MemberFormBase.provideExisting(MemberContractsComponent)]
})
export class MemberContractsComponent extends MemberFormBase {

  currentUser: any;
  @ViewChild('contractDetails') contractDetails: MemberContractDetailsComponent;

  constructor(injector: Injector,
    private memberSvc: MemberService,
    private router: Router) {
    super(injector);
  }

  ngAfterViewInit() {
    this.OnInit();
    this.LoadComplete();
  }

  OnInit() {
    this.contractDetails.isMemberSelfService = true;
    this.commonSvc.Header.title = "Contracts";
    super.OnInit();
  }

  LoadComplete() {
    super.LoadComplete();
    this.loadMemberDetails();
  }

  currentMember: any;
  loadMemberDetails() {
    this.currentUser = <User>JSON.parse(this.commonSvc.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT)));
    const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
    if(getContainer != null) {
      let container = <MemberContainer>JSON.parse(getContainer);
      this.currentMember = <DataResult<Member>>JSON.parse(this.commonSvc.D_FP_AES256(container.Member));
    }

    if (this.currentUser && this.currentUser.UserId > 0) {
      if(this.currentMember != null) {
        this.loadDetails(this.currentMember);
      }
      else {
        this.Invoke(
          this.memberSvc.getByUserForMember(this.currentUser.UserId),
          {
            onSuccess: (res: DataResult<Member>) => {
              this.loadDetails(res);
            }
          }
        );
      }
    } else {
      MessageBox.ShowError(this.dialog, 'Invalid user');
    }
  }

  loadDetails(res: DataResult<Member>) {
    if (res.Success) {
      if (res.Data == null) {
        MessageBox.ShowError(this.dialog, 'Member info not found for current user').subscribe(() => {
          this.router.navigate([RouterConstant.ROUTER_DASHBOARD]);
        });

      } else {
        if (res.Data != null) {
          this.PatchValue(res.Data, { emitEvent: false });
        }
        this.form.markAsPristine();
        super.OnLoad();
      }
    } else {
      this.HandleResponseError(res);
    }
  }

}
