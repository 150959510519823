import { Component, Injector, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FacilityFormBase, FPFormBaseComponent } from 'src/app/components/base';
import { CommonMessage, CommonString } from 'src/app/constant';
import { StringHelper } from 'src/app/helpers';
import { CommonResponse, DataResult, FacilityUpdateDetails, MembershipAccess, MembershipPackageType, SearchPaginationResult, SearchResultRow, EmployerDetailModel } from 'src/app/models';
import { MembershipPackagesService } from 'src/app/services/membership-packages.service';

class MembershipPackageSearchResultRow extends SearchResultRow {
    Id: number;
    MembershipPackageType: CommonResponse;
    Employer: EmployerDetailModel;
    Region: CommonResponse;
    State: CommonResponse;
}

class MembershipPackageSearchResultCheckBoxRow extends MembershipPackageSearchResultRow {
    Checked: boolean;
}

@Component({
    selector: 'app-facility-employer-access',
    templateUrl: './employer-access.component.html',
    styleUrls: ['./employer-access.component.css'],
    providers: [
        FPFormBaseComponent.provideExisting(FacilityEmployerAccessComponent),
        FacilityFormBase.provideExisting(FacilityEmployerAccessComponent)]
})
export class FacilityEmployerAccessComponent extends FacilityFormBase {
    private currentRegionId: number;
    /** All Employers X MsPkgTypes (IE Rows that will go into Allowed or Restricted table if MsPkgType is selected) */
    private allEmployersForAllMsPkgTypes: MembershipPackageSearchResultCheckBoxRow[];
    /** Unselected Membership Package Types (ie Bronze/Silver Etc) in the Dropdown List */
    MsPkgTypesInDropdown: MembershipPackageType[];
    /** Selected Membership Package Types (ie Bronze/Silver Etc) with the delete button on them */
    MsPkgTypesSelected: MembershipPackageType[];
    /** Employers X MsPkgTypes with service access ALLOWED (Table Data Source) */
    allowedMembershipPackagesDS: MatTableDataSource<MembershipPackageSearchResultCheckBoxRow>;
    /** Employers X MsPkgsTypes with service access NOT ALLOWED (Table Data Source) */
    restrictedMembershipPackagesDS: MatTableDataSource<MembershipPackageSearchResultCheckBoxRow>;
    _displayedColumns: string[] = ['tickBoxes', 'employer', 'employerCode', 'membershiptype', 'state', 'region', 'access'];

    hasInit = false;

    @ViewChild('chkSelectAllAllowedMembershipPackages') private chkSelectAllAllowedMembershipPackages: ElementRef<HTMLInputElement>;
    @ViewChild('chkSelectAllRestrictedMembershipPackages') private chkSelectAllRestrictedMembershipPackages: ElementRef<HTMLInputElement>;
    @ViewChild('srtAllowedMembershipPackages') private srtAllowedMembershipPackages: MatSort;
    @ViewChild('srtRestrictedMembershipPackages') private srtRestrictedMembershipPackages: MatSort;
    @ViewChild('pagAllowedMembershipPackages') private pagAllowedMembershipPackages: MatPaginator;
    @ViewChild('pagRestrictedMembershipPackages') private pagRestrictedMembershipPackages: MatPaginator;

    CommonMessage = CommonMessage;
    CommonString = CommonString;
    StringHelper = StringHelper;

    static getFromGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            IsAvailableForAllEmployers: [null],
            MembershipAccesses: [[]]
        });
    }

    constructor(injector: Injector, private membershipPackageService: MembershipPackagesService) { super(injector); }

    OnInit() {
        const localeLowerCaseSortingDataAccessor = (data: MembershipPackageSearchResultCheckBoxRow, sortHeaderId: string): string | number => {
            console.log(data.Employer);
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
            } else if (sortHeaderId === 'employer'){
                return data.Employer.Name.toLocaleLowerCase();
            } else if (sortHeaderId === 'employerCode') {
                return data.Employer.Code.toLocaleLowerCase();
            } else if (sortHeaderId === 'membershiptype') {
                return data.MembershipPackageType.Name.toLocaleLowerCase();
            } else if (sortHeaderId === 'state') {
                return data.State.Name.toLocaleLowerCase();
            } else if (sortHeaderId === 'region') {
                return data.Region.Name.toLocaleLowerCase();
            }
            return data[sortHeaderId];
        };
        const filterPredicate = (data: MembershipPackageSearchResultCheckBoxRow, filter: string) => data.Employer.Code.toLocaleLowerCase().indexOf(filter) !== -1 ||
        data.Employer.Name.toLocaleLowerCase().indexOf(filter) !== -1;

        this.pagAllowedMembershipPackages.pageSizeOptions = CommonString.PAGE_SIZE_OPTIONS;
        this.pagAllowedMembershipPackages.pageSize = CommonString.INIT_PAGE_SIZE;
        this.pagRestrictedMembershipPackages.pageSizeOptions = CommonString.PAGE_SIZE_OPTIONS;
        this.pagRestrictedMembershipPackages.pageSize = CommonString.INIT_PAGE_SIZE;

        this.allowedMembershipPackagesDS = new MatTableDataSource<MembershipPackageSearchResultCheckBoxRow>();
        this.allowedMembershipPackagesDS.filterPredicate = filterPredicate;
        this.allowedMembershipPackagesDS.sort = this.srtAllowedMembershipPackages;
        this.allowedMembershipPackagesDS.sortingDataAccessor = localeLowerCaseSortingDataAccessor;
        this.allowedMembershipPackagesDS.paginator = this.pagAllowedMembershipPackages;

        this.restrictedMembershipPackagesDS = new MatTableDataSource<MembershipPackageSearchResultCheckBoxRow>();
        this.restrictedMembershipPackagesDS.filterPredicate = filterPredicate;
        this.restrictedMembershipPackagesDS.sort = this.srtRestrictedMembershipPackages;
        this.restrictedMembershipPackagesDS.sortingDataAccessor = localeLowerCaseSortingDataAccessor;
        this.restrictedMembershipPackagesDS.paginator = this.pagRestrictedMembershipPackages;
        super.OnInit();
    }

    OnLoad() {
        if(!this.hasInit) {
            this.hasInit = true;
            this.OnInit();
          }
        // Search Membership Packages for all Membership Packages (MPs) in the region
        this.InvokeBatch(
            [
                this.membershipPackageService.search2({
                    OrderBy: null,
                    OrderByAsc: true,
                    Params: [{ Name: 'region_id', Value: this.data.model.Region.RegionId }],
                    PageSize: 0,
                    PageNumber: 1,
                    ViewColumns: ['employer', 'employerCode', 'membership_package_type', 'state', 'region', 'suburb']
                }),
                // Get the list of Types of Packages (ie Bronze, Silver, Etc)
                this.membershipPackageService.getTypes()
            ],
            {
                onSuccess: (res: DataResult<(MembershipPackageType[] | SearchPaginationResult<MembershipPackageSearchResultCheckBoxRow>)>[]) => {
                    this.currentRegionId = this.data.model.Region.RegionId;
                    const searchResult = <DataResult<SearchPaginationResult<MembershipPackageSearchResultCheckBoxRow>>>res[0];
                    const getTypesResult = <DataResult<MembershipPackageType[]>>res[1];

                    // Handle Search result - Store results with boxes unchecked in this.allMembershipPackages
                    if (searchResult.Success) {
                        this.allEmployersForAllMsPkgTypes = searchResult.Data.Results.map(item => {
                            (<MembershipPackageSearchResultCheckBoxRow>item).Checked = false;
                            return <MembershipPackageSearchResultCheckBoxRow>item;
                        });
                    } else {
                        this.HandleResponseError(searchResult);
                    }

                    // Handle GetTypes result - Store all types in dropdown and clear the selected types.
                    if (getTypesResult.Success) {
                        this.MsPkgTypesInDropdown = getTypesResult.Data;
                        this.MsPkgTypesSelected = [];
                    } else {
                        this.HandleResponseError(getTypesResult);
                    }

                    // Reload the form
                    if (this.loadOnDemand) {
                        this.updateFiltersAndResults(this.data.model);
                    }
                    super.OnLoad();
                }
            });
    }

    PatchValue(value: FacilityUpdateDetails, opts) {
        super.PatchValue(value, opts);
        if (!this.loadOnDemand) {
            this.updateFiltersAndResults(value);
        }
    }

    // #region Event handlers

    // When clicking on an option from the Membership Types dropdown (ie Bronze, Silver, etc)
    ddlMembershipTypes_Change(e) {
        if (e.target.selectedIndex > 0) {
            if (e.target.selectedIndex === 1) {
                this._addAllMembershipTypes();
            } else {
                this._addMembershipType(e.target.selectedIndex - 2);
            }
        }
    }

    // When Enter Key is pressed -> Search
    txtMembershipPackagesFilter_KeyUp(e: KeyboardEvent, searchButton: HTMLButtonElement) {
        if (e.keyCode === 13) {
            e.preventDefault();
            searchButton.click();
        }
    }

    // #endregion Event handlers

    // #region Private methods
    private updateFiltersAndResults(dataModel: FacilityUpdateDetails) {
        const membershipAccesses = dataModel.MembershipAccesses || [];
        // If membership access list is empty, do nothing.
        // Else, Filter the packages that allow access to the facility
        if (membershipAccesses.length > 0) {
            const restrictedMembershipPackageIds = membershipAccesses.filter(item => !item.AllowAccess).map(item => item.MembershipPackageId);
            const allowedMembershipPackageIds = membershipAccesses.filter(item => item.AllowAccess).map(item => item.MembershipPackageId);
            let allowedMembershipPackages: MembershipPackageSearchResultCheckBoxRow[] = [];
            let restrictedMembershipPackages: MembershipPackageSearchResultCheckBoxRow[] = [];
            // Array of MembershipPackageTypeIDs
            let arrmspt: number[] = [];

            // Add all restricted MSPkgs to the restricted Data Source
            if (restrictedMembershipPackageIds instanceof Array && restrictedMembershipPackageIds.length > 0) {
                restrictedMembershipPackages = this.allEmployersForAllMsPkgTypes.filter(item => restrictedMembershipPackageIds.indexOf(item.Id) > -1);
                this.restrictedMembershipPackagesDS.data = restrictedMembershipPackages;
                const restrictedMembershipPackageTypeIds = restrictedMembershipPackages
                    .map(item => item.MembershipPackageType.Id)
                    .filter((value, index, self) => self.indexOf(value) === index);
                arrmspt = arrmspt.concat(restrictedMembershipPackageTypeIds);
            }
            // Add all allowed MSPkgs to the allowed DS
            if (allowedMembershipPackageIds instanceof Array && allowedMembershipPackageIds.length > 0) {
                allowedMembershipPackages = this.allEmployersForAllMsPkgTypes.filter(item => allowedMembershipPackageIds.indexOf(item.Id) > -1);
                this.allowedMembershipPackagesDS.data = allowedMembershipPackages;
                const allowedMembershipPackageTypeIds = allowedMembershipPackages
                    .map(item => item.MembershipPackageType.Id)
                    .filter((value, index, self) => self.indexOf(value) === index);
                arrmspt = arrmspt.concat(allowedMembershipPackageTypeIds);
            }

            // Select distinct MSPkgTypeIds
            arrmspt = arrmspt.filter((v, i, a) => a.indexOf(v) === i);

            this.MsPkgTypesSelected = this.MsPkgTypesInDropdown.filter(item => arrmspt.indexOf(item.MembershipPackageTypeId) > -1);
            this.MsPkgTypesInDropdown = this.MsPkgTypesInDropdown.filter(item => arrmspt.indexOf(item.MembershipPackageTypeId) === -1);
        }
        // Tomorrow will discuss with Don and Chris
        // else {
        //     this.restrictedMembershipPackagesDS.data = this.allMembershipPackages;
        // }
    }

    /**
     * Pushes data into the Facility form
     */
    private updateFormValue() {

        let membershipAccesses = [];
        const allowedPackages = this.allowedMembershipPackagesDS.data;
        const restrictedPackages = this.restrictedMembershipPackagesDS.data;

        if (allowedPackages.length > 0 || restrictedPackages.length > 0) {
            // Access is allowed or denied based on which package IDs are in which table.
            membershipAccesses = [
                ...allowedPackages.map(item => <MembershipAccess>{
                    MembershipPackageId: item.Id,
                    AllowAccess: true
                }),
                ...restrictedPackages.map(item => <MembershipAccess>{
                    MembershipPackageId: item.Id,
                    AllowAccess: false
                })];
        }

        // If there are 0 employers and no membership package types selected on Employer Access tab - set 'Available for All Employers' to true
        let availableForAllEmployers = (allowedPackages.length + restrictedPackages.length + this.MsPkgTypesSelected.length) === 0;

        // Push these details back into the Model.
        this.data.model.IsAvailableForAllEmployers = availableForAllEmployers;
        this.data.model.MembershipAccesses = membershipAccesses;
        this.form.setValue({
            IsAvailableForAllEmployers: availableForAllEmployers,
            MembershipAccesses: membershipAccesses
        });
    }

    private moveMembershipPackageType(index: number, source: MembershipPackageType[], target: MembershipPackageType[]) {
        const item = source.splice(index, 1)[0];
        target.push(item);
        target = target.sort((a, b) =>
            (a.DisplayOrder != null && b.DisplayOrder != null) ?
                (a.DisplayOrder < b.DisplayOrder ? -1 : 1) :
                (a.Name < b.Name ? -1 : 1));
    }
    // #endregion Private methods

    // #region Internal methods
    _addAllMembershipTypes() {
        this.MsPkgTypesSelected.push(...this.MsPkgTypesInDropdown.splice(0));
        this.MsPkgTypesSelected = this.MsPkgTypesSelected.sort((a, b) =>
            (a.DisplayOrder != null && b.DisplayOrder != null) ?
                (a.DisplayOrder < b.DisplayOrder ? -1 : 1) :
                (a.Name < b.Name ? -1 : 1));
        this.RefreshDataSource();
    }
    _addMembershipType(index: number) {
        this.moveMembershipPackageType(index, this.MsPkgTypesInDropdown, this.MsPkgTypesSelected);
        this.RefreshDataSource();
    }
    _removeMembershipType(index: number) {
        this.moveMembershipPackageType(index, this.MsPkgTypesSelected, this.MsPkgTypesInDropdown);
        this.RefreshDataSource(true);
    }

    _filterByEmployer(text: string, dataSource: MatTableDataSource<MembershipPackageSearchResultCheckBoxRow>) {
        dataSource.filter = (text || '').toLowerCase();
        dataSource.paginator.firstPage();
    }

    _allowOrRestrictSelectedMembershipPackages(allowed: boolean) {
        let source: MatTableDataSource<MembershipPackageSearchResultCheckBoxRow>;
        let target: MatTableDataSource<MembershipPackageSearchResultCheckBoxRow>;
        if (allowed) {
            source = this.allowedMembershipPackagesDS;
            target = this.restrictedMembershipPackagesDS;
        } else {
            source = this.restrictedMembershipPackagesDS;
            target = this.allowedMembershipPackagesDS;
        }
        const selectedItems = source.data.filter(item => item.Checked);
        source.data = source.data.filter(item => !item.Checked);
        target.data.push(...selectedItems);
        target._updateChangeSubscription();
        this.updateFormValue();
    }

    _toggleSelectAll(dataSource: MatTableDataSource<MembershipPackageSearchResultCheckBoxRow>, checked: boolean) {
        dataSource.filteredData.forEach(item => {
            item.Checked = checked;
        });
    }

    _updateSelectAllCheckedState(dataSource: MatTableDataSource<MembershipPackageSearchResultCheckBoxRow>, allowed) {
        const chkSelectAll = allowed ? this.chkSelectAllAllowedMembershipPackages.nativeElement : this.chkSelectAllRestrictedMembershipPackages.nativeElement;
        if (chkSelectAll) {
            chkSelectAll.checked = !(dataSource.data.findIndex(item => !item.Checked) > -1);
        }
    }

    _hasSelectedRows(dataSource: MatTableDataSource<MembershipPackageSearchResultCheckBoxRow>) {
        return dataSource && dataSource.data.findIndex(item => item.Checked) > -1;
    }
    // #endregion Internal methods

    // #region Public methods
    RefreshDataSource(remove: boolean = false) {
        const availableMembershipPackageTypeIds = this.MsPkgTypesInDropdown.map(msPkgType => msPkgType.MembershipPackageTypeId);
        const selectedMembershipPackageTypeIds = this.MsPkgTypesSelected.map(msPkgType => msPkgType.MembershipPackageTypeId);
        /// Clear all tables if no membershipPackageTypes are selected
        if (selectedMembershipPackageTypeIds.length === 0) {
            this.allowedMembershipPackagesDS.data = [];
            this.restrictedMembershipPackagesDS.data = [];
            this.allEmployersForAllMsPkgTypes.forEach(item => {
                item.Checked = false;
            });
        } else {
            // If not removing package types, allowed MsPkgTypes should stay as is.
            // The rest should go into restricted, and there should be no repeats.
            if (remove === false) {
                this.allowedMembershipPackagesDS.data = this.allowedMembershipPackagesDS.data.filter(item => this.containedIn(selectedMembershipPackageTypeIds, item.MembershipPackageType.Id));
                const allowedMsPkgIds = this.allowedMembershipPackagesDS.data.map(mspkg => mspkg.Id);
                this.restrictedMembershipPackagesDS.data = this.allEmployersForAllMsPkgTypes.filter(item => this.containedIn(selectedMembershipPackageTypeIds, item.MembershipPackageType.Id) && !this.containedIn(allowedMsPkgIds, item.Id));
            } else {
                // If we are removing package types, tables should stay the same apart from the items of the given MSPkgType
                this.restrictedMembershipPackagesDS.data = this.restrictedMembershipPackagesDS.data.filter(item => this.containedIn(selectedMembershipPackageTypeIds, item.MembershipPackageType.Id));
                this.allowedMembershipPackagesDS.data = this.allowedMembershipPackagesDS.data.filter(item => this.containedIn(selectedMembershipPackageTypeIds, item.MembershipPackageType.Id));
            }

        }
        this.updateFormValue();
    }

    RefreshMembershipPackagesSearchResults(regionId: number) {
        if (this.currentRegionId > 0 && regionId > 0 && this.currentRegionId !== regionId) {
            this.Invoke(
                this.membershipPackageService.search2({
                    OrderBy: null,
                    OrderByAsc: true,
                    Params: [{ Name: 'region_id', Value: regionId }],
                    PageSize: 0,
                    PageNumber: 1,
                    ViewColumns: ['employer', 'employerCode', 'membership_package_type', 'state', 'region', 'suburb']
                }),
                {
                    onSuccess: (res: DataResult<SearchPaginationResult<MembershipPackageSearchResultRow>>) => {
                        if (res.Success) {
                            this.allEmployersForAllMsPkgTypes = res.Data.Results.map(item => {
                                (<MembershipPackageSearchResultCheckBoxRow>item).Checked = false;
                                return <MembershipPackageSearchResultCheckBoxRow>item;
                            });
                            this.currentRegionId = regionId;
                            this.RefreshDataSource();
                        } else {
                            this.HandleResponseError(res);
                        }
                    }
                });
        }
    }
    // #endregion Public methods

    private containedIn<T>(collection : Array<T>, value : T) : boolean
    {
        return collection.indexOf(value) > -1;
    }
}