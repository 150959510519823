import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { MessageBox } from 'src/app/services/common-dialog.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-enduserscreen',
  templateUrl: './enduserscreen.component.html',
  styleUrls: ['./enduserscreen.component.css']
})
export class EnduserscreenComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    window.scroll(0, 0);
    // this.commonService.App.isLoginSuccessDisplay = false;
    this.commonService.Header.title = 'User Details';
  }

  comingSoon() {
    MessageBox.ShowCustom(this.dialog, 'Warning!', 'Warning', 'Coming soon for this feature');
  }

}
