import { NewPaymentRequestNote } from "./create-payment-request.model";

export class EditPaymentRequestModel {
  PaymentRequestId: number;
  PaymentPeriodStart: string;
  PaymentPeriodEnd: string;
  ServiceId: number;
  AntiFraudCheck: string;
  CreatedDate: string;
  CreatedBy: string;
  ApprovedDate: string;
  ApprovedBy: string;
  PostedDate: string;
  PostedBy: string;
  InvoiceNumber: string;
  InvoiceName: string;
  InvoiceUuid: string;
  AttachmentsName: string;
  AttachmentsUuid: string;
  NotesInformation: NewPaymentRequestNote[];
  Amount: number;
  VisitCount: number;
  SystemCalculatedAmount: number;
  SystemCalculatedVisitCount: number;
  PaymentRequestStatusId: number;
  HwmNumberMemberships: number;
  HwmVisitsPerMember: number;
  HwmPricePerMembership: number;
  HwmChangedFromLastMonth: boolean;
  PerVisitRate: number;
  PaymentCalculationMethodId: number;
  AdditionalManualVisits?: number;
  FacilityName: string;
  ServiceName: string;
  PaymentRequestCount: number;
}

export class PaymentRequestNotesModel {
  PaymentRequestNoteId: number;
  PaymentRequestId: number;
  NoteTypeId: number;
  Note: string;
  UserId: number;
  Username: string;
  CreateOrUpdateDate: Date;
}
