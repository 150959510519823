<div>
  <ngb-accordion activeIds="pnlMembershipCards">
    <ngb-panel id="pnlMembershipCards">
      <ng-template ngbPanelTitle>
        <i class="fas fa-check icon-fp"></i>
        Membership Cards
        <i class="fas fas fa-chevron-down"></i>
        <i class="fas fas fa-chevron-up"></i>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="border-default rounded-10 border-2">
          <div class="tablecontainer">
            <table mat-table matSort [dataSource]="dataSourceMembershipCards" class="rounded-10">
              <ng-container matColumnDef="LastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
                <td mat-cell *matCellDef="let element">{{ element.LastName }}</td>
              </ng-container>

              <ng-container matColumnDef="FirstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  First Name
                </th>
                <td mat-cell *matCellDef="let element">{{ element.FirstName }}</td>
              </ng-container>

              <ng-container matColumnDef="MemberType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  Member Type
                </th>
                <td mat-cell *matCellDef="let element">{{ element.MemberType?.Name }}</td>
              </ng-container>

              <ng-container matColumnDef="MemberNumber">
                <th mat-header-cell *matHeaderCellDef>Member Number</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.MemberNumber }}
                </td>
              </ng-container>

              <ng-container matColumnDef="MemberCardNumber">
                <th mat-header-cell *matHeaderCellDef>Card Number</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.MemberCardNumber }}
                </td>
              </ng-container>

              <ng-container matColumnDef="MemberCardStatus">
                <th mat-header-cell *matHeaderCellDef>Card Status</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.MemberCardStatus?.Name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="PhotoLocation">
                <th mat-header-cell *matHeaderCellDef class="p-0"></th>
                <td mat-cell *matCellDef="let element" class="p-0" style="width: 183px;">
                  <div
                    [ngClass]="element.PhotoLocation!='' && element.PhotoLocation !=null? 'my-2 border border-primary' : 'my-2'">
                    <div *ngIf="element.PhotoLocation=='' || element.PhotoLocation==null"
                      class="bg-secondary fp-profilephoto d-flex justify-content-center align-items-center mx-auto">
                      <i class="fa fa-image fa-2x text-secondary"></i>
                    </div>
                    <div *ngIf="element.PhotoLocation!='' && element.PhotoLocation !=null"
                      class="fpPictureCover d-flex align-items-center justify-content-center">
                      <app-member-image [imgSrc]="element.PhotoLocation" [htmlClass]="'img-fluid'">
                      </app-member-image>
                    </div>
                    <button *ngIf="!readonly" (click)="OnSelectedUploadPhoto(element)"
                      class="btn bg-primary text-light py-1 d-block text-center w-100 photo rounded-0">
                      Upload Photo
                    </button>

                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="p-0" style="width:120px;"></th>
                <td mat-cell *matCellDef="let element" class="p-0 text-center">
                  <div *ngIf="element.MemberCardStatus.Id == EMemberCardStatus.Active && !readonly && element.IsMobileAppEnabled">
                  <a id="btnMemberOrderNewCard"
                    class="text-center d-inline-block"><i class="fas fa-check-circle text-primary"></i><br>Order
                    New Card</a>
                  </div>
                  <div *ngIf="element.MemberCardStatus.Id == EMemberCardStatus.Active && !readonly && !element.IsMobileAppEnabled">
                    <a 
                      (click)="OnClickOrderNewCard(element)" id="btnMemberOrderNewCard"
                      class="text-center d-inline-block"><i class="fas fa-check-circle text-primary"></i><br>Order
                      New Card</a>
                  </div>
                  <div *ngIf="element.MemberCardStatus.Id == EMemberCardStatus.Active && !readonly && element.IsMobileAppEnabled">
                  <a class="text-center d-inline-block mt-1"
                    [ngClass]="isHideLostMembershipCard ? 'hidden' : ''"><i
                      class="fas fa-low-vision text-danger"></i><br>Lost/Stolen Card</a>
                  </div>
                  <div *ngIf="element.MemberCardStatus.Id == EMemberCardStatus.Active && !readonly && !element.IsMobileAppEnabled">
                    <a (click)="OnLostStolenCard(element)" class="text-center d-inline-block mt-1"
                      [ngClass]="isHideLostMembershipCard ? 'hidden' : ''"><i
                        class="fas fa-low-vision text-danger"></i><br>Lost/Stolen Card</a>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <mat-toolbar [hidden]="dataSourceMembershipCards == null" class="bg-white">
            <mat-toolbar-row *ngIf="dataSourceMembershipCards != null && dataSourceMembershipCards.data.length == 0"
              class="text-muted d-block p-2 bg-light text-center">
              {{commonString.TABLE_NO_RECORD_FOUND}}
            </mat-toolbar-row>
          </mat-toolbar>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>


<div class="modal fade" #dlgOrderNewCard tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <ng-template #dlgOrderNewCardContent let-modal>
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Order Membership Card Fee</h5>
        </div>
        <div class="modal-body py-4">
          <form>
            <div class="form-group row">
              <label for="ddlReasonOrderCard" class="col-md-3 col-lg-4 col-form-label">Select Reason for Card
                Replacement<span class="text-danger">*</span></label>
              <div class="col-md-9 col-lg-8">
                <div class="d-flex flex-column w-100">
                  <select class="form-control" [class.is-invalid]="isInvalidReasonDropdown()"
                    [formControl]="ddlReasonOrderCardControl" id="ddlReasonOrderCard">
                    <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let reason of memberCardOrderReasonData" [ngValue]="reason.Id">
                      {{reason.Name}}</option>
                  </select>
                  <div *ngIf="isInvalidReasonDropdown()">
                    <small *ngIf="ddlReasonOrderCardControl.hasError('required')" class="invalid-feedback d-block">
                      {{stringHelper.format(commonString.FIELD_REQUIRED, 'Reason for Card Replacement')}}
                    </small></div>
                </div>
              </div>
            </div>
          </form>
          <div class="py-4 d-flex justify-content-center">
            <button id="member_ms_cards_newcard_btn_close"
              class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button" (click)="cancel()">
              Cancel</button>
            <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="submit()">
              Submit</button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>