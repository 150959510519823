<div matDialogTitle class="modal-header justify-content-start">
  <h5 class="text-primary">
    {{data.service}}
      <button type="button" class="close" aria-label="Close" mat-dialog-close>
          <span aria-hidden="true">&times;</span>
      </button>
  </h5>
</div>
  <form [formGroup]="PaymentRequestNoteFormGroup">
    <div class="form-group row">
      <div class="col-md-12 ">
        <div class="d-flex flex-column w-100">
          <div class="border-default rounded-10 border-2">
            <div class="tablecontainer">
              <table mat-table [dataSource]="dtmatnotes" class="rounded-10"> -->
                <!-- User Column -->
                <ng-container matColumnDef="Username">
                  <th mat-header-cell *matHeaderCellDef>User</th>
                  <td mat-cell *matCellDef="let element"> {{element.User}} </td>
                </ng-container>
                <!-- DateTime Column -->
                <ng-container matColumnDef="DateTimeDisplay">
                  <th mat-header-cell *matHeaderCellDef>Date/Time</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.DateTime | dateTimeWithoutSecondsPipe}} </td>
                </ng-container>
                <!-- Note Column -->
                <ng-container matColumnDef="Note">
                  <th mat-header-cell *matHeaderCellDef>Note</th>
                  <td mat-cell *matCellDef="let element"> {{element.Note}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns;">
                </tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
