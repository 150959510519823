import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { RouterConstant, CommonString } from "src/app/constant";
import { forkJoin, merge, Observable } from "rxjs";
import { AuditLoggingService } from "@fp/services/audit-logging.service";
import { catchError, finalize, map, shareReplay, takeUntil } from "rxjs/operators";
import { User, DataResult, CommonResponse } from "@fp/models";
import { MessageBox, CommonService } from "@fp/services";
import { AuditLog, AuditLogVM } from "@fp/models/audit-logging.model";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { AuditAreas } from "@fp/enums/audit-areas.enum";
import { ActivatedRoute } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSourceHelper, DateHelper } from "@fp/helpers";
import { HttpErrorResponse } from "@angular/common/http";
@Component({
  selector: "app-audit-log",
  templateUrl: "./audit-log.component.html",
  styleUrls: ["./audit-log.component.css"]
})
export class AuditLogComponent implements OnInit {
  RouterConstant = RouterConstant;
  dataprint: any;

  @Input() auditSource: string = "User";
  @Input() inputId: string = "0";
  @Input() loadOnInit: boolean = false;
  public displayedColumns: string[];
  public dataSource: AuditLogVM[] = [];
  public AuditLogDS = new MatTableDataSource<AuditLogVM>(this.dataSource);
  public CommonString = CommonString;
  public FilterItems: CommonResponse[] = [];
  public isLoading = true;
  public TimeoutError: string = "";
  public ReportFormGroup: UntypedFormGroup;
  public AuditFormGroup: UntypedFormGroup;
  @ViewChild('adpagi') ad_paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private auditService: AuditLoggingService,
    private _formBuilder: UntypedFormBuilder,
    private _route: ActivatedRoute,
    private cmsrv: CommonService
  ) { }

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      AuditLog: []
    });
  }

  ngOnInit() {
    // tslint:disable-next-line: member-ordering
    this.ReportFormGroup = this._formBuilder.group({
      AuditLog: []
    });
    this.AuditFormGroup = this._formBuilder.group({
      ddlFilter: [""]
    });
    const currentUser = this.cmsrv.GetUser();
    this.displayedColumns = ["Category", "DateTime", "Event", "UserName", "Ref"];
    this.loadTime(this.FilterItems);

    if (
      this.inputId === null ||
      this.inputId === undefined ||
      this.inputId === "0"
    ) {
      if (this._route.snapshot.paramMap.get("id") !== null) {
        this.inputId = this._route.snapshot.paramMap.get("id").toString();
      } else {
        return;
      }
    }
    if(this.loadOnInit) {
      this.auditSearch();
    }
  }

  OnLoad() {
    if(this.dataSource.length == 0) {
      this.auditSearch();
    }
  }

  auditSearch() {
    this.isLoading = true;
    this.TimeoutError = "";
    let date: Date = new Date();

    switch (this.auditSource) {
      case AuditAreas.User:
        this.GetAuditLogByUsername();
        this.CreateSortingObj();
        this.isLoading = false;
        break;
      case AuditAreas.Facility:
        this.Invoke(
          this.auditService.getFacilityAuditLog(this.inputId.toString()),
          (result: DataResult<AuditLogVM[]>) => {
            this.isLoading = false;
            this.dataSource = this.CreateAuditList(result);
            this.CreateSortingObj();
          });
        break;
      case AuditAreas.Member:
        this.Invoke(
          this.auditService.getMemberAuditLog(this.inputId.toString()),
          (result: DataResult<AuditLogVM[]>) => {
            this.isLoading = false;
            this.dataSource = this.CreateAuditList(result);
            this.CreateSortingObj();
          });
        break;
      case AuditAreas.Package:
        this.Invoke(
          this.auditService.getPackageAuditLog(this.inputId.toString()),
          (result: DataResult<AuditLogVM[]>) => {
            this.isLoading = false;
            this.dataSource = this.CreateAuditList(result);
            this.CreateSortingObj();
          });
        break;
      case AuditAreas.Region:
        this.Invoke(
          this.auditService.getRegionAuditLog(this.inputId.toString()),
          (result: DataResult<AuditLogVM[]>) => {
            this.isLoading = false;
            this.dataSource = this.CreateAuditList(result);
            this.CreateSortingObj();
          });
        break;
      case AuditAreas.Employer:
        this.Invoke(
          this.auditService.getEmployerAuditLog(this.inputId.toString()),
          (result: DataResult<AuditLogVM[]>) => {
            this.isLoading = false;
            this.dataSource = this.CreateAuditList(result);
            this.CreateSortingObj();
          }
        );
        break;
      default:
        this.GetAuditLogByUsername();
        this.isLoading = false;
        this.CreateSortingObj();
        break;
    }
  }

  private buildAuditFetch(year: number) {
    let logList: Observable<any>[] = [];

    if(this.auditSource == AuditAreas.Employer) {
      let getDate: Date = new Date(year, 1, 1, 0, 0, 0, 0);
      logList.push(this.auditService.getEmployerAuditLogFilter(this.inputId.toString(), getDate));
    } else {
      for(var i = 0; i < 12; i++) {
        let getDate: Date = new Date(year, 12 - i, 1, 0, 0, 0, 0);
        
        switch (this.auditSource) {
          case AuditAreas.Member:
            logList.push(this.auditService.getMemberAuditLogFilter(this.inputId.toString(), getDate));
            break;
          case AuditAreas.Facility:
            logList.push(this.auditService.getFacilityAuditLogFilter(this.inputId.toString(), getDate));
            break;
          case AuditAreas.Package:
            logList.push(this.auditService.getPackageAuditLogFilter(this.inputId.toString(), getDate));
            break;
          case AuditAreas.Region:
            logList.push(this.auditService.getRegionAuditLogFilter(this.inputId.toString(), getDate));
            break;
          default :
            break;
        }
      }
    }
    return logList;
  }

  private CreateList(result: AuditLogVM[]) {
    let newData: AuditLogVM[] = [];
    result.forEach(element => {
      let thisEl = element;
      thisEl.DateTime = this.ConvertToLocalTime(thisEl.DateTime);
      newData.push(thisEl);
    });
    return newData;
  }

  private CreateAuditList(result: DataResult<AuditLogVM[]>) {
    let newData: AuditLogVM[] = [];
    result.Data.forEach(element => {
      let thisEl = element;
      thisEl.DateTime = this.ConvertToLocalTime(thisEl.DateTime);
      newData.push(thisEl);
    });
    return newData;
  }

  private ConvertToLocalTime(d: string) {
    const localdate: Date = new Date(new Date(Date.parse(d)).toString() + 'Z');
    return DateHelper.format(localdate, 'DD-MMM-YYYY HH:mm');
  }

  private loadTime(tempfilter: CommonResponse[]) {
    let currentYear = new Date().getFullYear();
    let yearIndex = 0;

    //Default value in dropdown will be the current year
    for (let i = 0; i < 10; i++) {
        let temp = new CommonResponse;
        let year: number = currentYear - i;
        temp.Id = yearIndex;
        temp.Name = year.toString();
        tempfilter.push(temp);
        yearIndex++;
    }
  }

  public month_Year_Change()
  {
    const month = this.AuditFormGroup.get('ddlFilter').value;
    let yearID = this.FilterItems.find(x => x.Name == month);
    if(yearID == null) {
      return;
    }
    
    let date: Date = new Date();
    date.setFullYear(date.getFullYear() - yearID.Id);

    this.isLoading = true;

    this.InvokeBatch(this.buildAuditFetch(date.getFullYear()), {
      onSuccess: res => {
        this.isLoading = false;
        let results: AuditLogVM[] = [];
        res.forEach(element => {
          results = results.concat(element.Data);
        });
        this.dataSource = this.CreateList(results);
        this.CreateSortingObj();
        this.AuditLogDS.paginator.pageIndex = 0;
      }
    });
  }

  private CreateSortingObj() {
    this.AuditLogDS.data = this.dataSource;
    this.AuditLogDS.sortingDataAccessor =
      DataSourceHelper.localeLowerCaseSortingDataAccessor;
    this.AuditLogDS.sort = this.sort;
    this.AuditLogDS.paginator = this.ad_paginator;
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError(e => {
          throw e;
        })
      )
      .subscribe(
        res => {
          handleResultCallback(res);
        },
        err => {
          this.errorHandler(err);
        }
      );
  }

  private InvokeBatch<TSource = any, TMap = any>(
    source: Observable<TSource | any>[],
    opt: {
      onSuccess: (res: TSource[] | TMap) => void;
      onError?: (err: any) => void;
      onComplete?: () => void;
      projectionFn?: (res: TSource[]) => TMap;
      busyIndicator?: { start: () => void; stop: () => void };
    }
  ) {
    return forkJoin(source)
      .pipe(
        map((res: TSource[]): TSource[] | TMap => {
          return typeof opt.projectionFn === "function"
            ? opt.projectionFn(res)
            : res;
        }),
        shareReplay(1),
        catchError((e) => {
          throw e;
        }),
        finalize(() => {
        })
      )
      .subscribe({
        next: (res) => {
          opt.onSuccess(res);
        },
        error: (err) => {
          if (typeof opt.onError === "function") {
            opt.onError(err);
          } else {
            let msg = "An error occurred while trying to call a service";
            if (err instanceof HttpErrorResponse && err.error) {
              msg = err.error.message;
            } else if (err instanceof Response) {
              msg = err.statusText;
            }
            this.handleError(err, msg);
          }
        },
        complete: () => {
          if (typeof opt.onComplete === "function") {
            opt.onComplete();
          }
        },
      });
  }

  private handleError(error, message?: string) {
    console.log("Error JSON from API: ", error);
    console.log("Error message: ", message);
  }

  private errorHandler(error, message: string = null) {
    // MessageBox.ShowError(this.dialog, message || 'An error occurred while trying to call a service');
    console.error(error);
    this.isLoading = false;
    this.TimeoutError = "This Audit fetch has Timed Out";
  }

  private GetAuditLogByUsername() {
    this.Invoke(this.auditService.GetAuditLog(), (data: AuditLog[]) => {
      this.isLoading = false;
      var VMS = data.map(x => x.toAuditLogVM());
      this.dataSource = VMS;
    });
  }

  eventStyling(event: string) {
    let lineCount = event.split(',').length;

    if (lineCount < 3) {
      return {};
    }
    return { 'overflow-y': 'auto', 'height': '5em', 'width': '100%', 'padding-top': '0.2em' };
  }
}
