export class EmployerPageConstant {
    public static pageText: string = `
    <p>Welcome to an exciting employee benefit. Fitness Passport entitles you to visit a multitude of health facilities, whenever you like and without being limited to just one. Your exclusive Fitness Passport card paves the way for you to reach your health and fitness goals; allowing you (and your family) passport access to ALL the gyms, pools and facilities available on this program, as often as you like.</p>\n\n<h5>What do you need to join?</h5>\n\n<ul>\n\t<li>Your bank account details</li>\n\t<li>Your employee number</li>\n\t<li>A digital photo of each person you are signing up (or you can log in later and upload this)</li>\n</ul>\n\n<p>When you commence the sign up process, you will receive an email/SMS with your username and temporary password to login and complete your registration. &nbsp;</p>\n`;
    public static eligibilityText: string = `
    <p>
  <meta charset="utf-8">
</p>
<p><u><strong>Eligibility</strong></u></p>
<p>Fitness Passport is only eligible for employees with a valid employee number who meet the below criteria.</p>
<p><strong>SINGLE MEMBERSHIP Criteria:</strong>
  <br><strong>Employees who are:</strong></p>
<ul>
  <li>Full time permanent employees</li>
  <li>Part time permanent employees</li>
  <li>Contract/Temporary employees who are paid by the organisation's payroll department and have at least 3 months consistent working history (or at least 6 months remaining on their existing contract)</li>
  <li>Casual employees who are paid by the organisation's payroll department and work more than 20 hours per week and have at least 3 months consistent working history</li>
</ul>
<p><strong>FAMILY MEMBERSHIP Criteria:</strong>
  <br><strong>Provided that the Single membership is met</strong>, Family members need to meet the following to be eligible:</p>
<ul>
  <li>Partners may be married or unmarried but must reside at the same address as the primary member and must be a genuine partner – i.e. NOT a flatmate or friend</li>
  <li>Dependant children under 18 years of age must reside at the same address as the primary member</li>
  <li>Dependant children that are aged between 18 years and up to 25 years must reside at the same address as the primary member and must be
    <ul>
      <li>financially dependant on the primary member (e.g. studying full time or a recipient of a Government benefit).</li>
      <li><strong>Each eligible dependant that is aged between 18 years and up to 25 years old is charged at an additional single membership rate</strong></li>
    </ul>
  </li>
  <li>Primary members may be required to show evidence to fulfil these requirements</li>
</ul>`;

    public static additionalDetailsText: string = `
    <p>For more information about Fitness Passport please contact the Fitness Passport facilitator via&nbsp;<u>email</u>.</p>\n\n<p>You will be signing up for a one year subscription.&nbsp; Your first fortnightly payment is the administration fee and your card will be sent to you within two weeks of signing up.&nbsp; You can add any family members at any time, but you are not able to reduce the number of family members during the first 12 months.&nbsp; If you cease working for us you are no longer eligible for Fitness Passport and your card must be returned to the <u>Fitness Passport Facilitator</u>; or destroy the card yourself and email photo evidence to <u>Fitness Passport directly</u>.</p>\n\n<p>24/7 Facility Access: Fitness Passport members must purchase an electronic out of hours access key for a small fee paid directly to the gym on their first visit. The electronic key permits access during un-staffed hours and is also used to keep a record of visitation and so must be purchased whether you intend to use the gym outside of hours or not. Fees may vary depending on the location. Please contact your gym of choice directly to verify additional cost. 24/7 gyms are open 24 hours per day, 7 days per week (no excuses!).</p>\n\n<p>Please note you MUST swipe your Fitness Passport membership card on Fitness Passport scanners where available at facilities. This includes staffed hour and after-hours access - even if you have swiped in using the gym&#39;s own key tag as well. A $15 administration charge may be charged for every visit not registered on the Fitness Passport scanner for all members associated with Fitness Passport memberships.<br />\n<br />\n<strong>CONTACT DETAILS</strong></p>\n\n<p>Contact Name:</p>\n\n<p>Contact Phone Number:</p>\n\n<p>Email Address:</p>\n`;
}