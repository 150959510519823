<div [formGroup]="form">
    <ngb-accordion [destroyOnHide]="false"
        activeIds="pnlPersonalDetails,pnlAddressContactDetails,pnlEmergencyContact,pnlMedicalQuestions,pnlPreferredFacilities,pnlAdditionalNotes">
        <ngb-panel id="pnlPersonalDetails">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': personalDetails?.valid == true}"></i>
                Personal Details
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-member-personal-details [form]="form.get('PersonalDetails')" [data]="data"
                    [loadOnDemand]="loadOnDemand" #personalDetails></app-member-personal-details>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlAddressContactDetails">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': addressContactDetails?.valid == true}"></i>
                Address and Contact Details
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-member-address-contact-details [form]="form.get('AddressContactDetails')" [data]="data"
                    [loadOnDemand]="loadOnDemand" #addressContactDetails></app-member-address-contact-details>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlEmergencyContact">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': emergencyContact?.valid == true}"></i>
                Emergency Contact
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-member-emergency-contact [form]="form.get('EmergencyContact')" [data]="data"
                    [loadOnDemand]="loadOnDemand" #emergencyContact></app-member-emergency-contact>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlMedicalQuestions" *ngIf="isEmployerFacilitator">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': medicalQuestions?.valid == true}"></i>
                Medical Questions
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-member-medical-questions [form]="form.get('MedicalQuestionsTab')" [data]="data"
                    [loadOnDemand]="loadOnDemand" #medicalQuestions></app-member-medical-questions>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlPreferredFacilities">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': preferredFacilities?.valid == true}"></i>
                Preferred Facilities
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-member-preferred-facilities [form]="form.get('PreferredFacilities')" [data]="data"
                    [loadOnDemand]="loadOnDemand" #preferredFacilities></app-member-preferred-facilities>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlAdditionalNotes" *ngIf="!isSignup && seeAdditionalNotes">
            <ng-template ngbPanelTitle>
                <i class="icon-fp fas fa-check"></i>
                Additional Notes
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-member-additional-notes *ngIf="!isSignup"></app-member-additional-notes>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>