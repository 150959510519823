export class CostCalculationParameters {
    FacilityName: string;
    ServiceName: string;
    ToDate: string;
    FromDate: string;
    VisitCount: number;
    PageCategory: number;
}

export class CalculatedCost {
    Data: CalculationData; // this is the visit count
    Errors: string[];
    Success: boolean;
    Message: string;
    Name: string;
}

export class CalculationData {
    CalculationCost: number;
    IsNewHWM: boolean;
    NewHWMNumMem: number;
    IsThereCost: boolean;
}