<div mat-dialog-container class="message-PopNew">
  <div class="message">
    <p *ngIf="button != 4 && button != 5">
      {{ message }}
    </p>
    <h2 *ngIf="button == 4">Membership Confirmation</h2>
    <h2 *ngIf="button == 5">Create Password</h2>
    <h2 *ngIf="button == 15">Survey Registration</h2>
  </div>

  <div class="info" *ngIf="information !== '' && button != 4">
    <p [innerHTML]="information"></p>
    <p style="text-align: center" *ngIf="countdown !== ''">
      <strong>{{ countdown }}</strong>
    </p>
  </div>
  <div class="info" *ngIf="button == 4">
    <p>
      An authorisation code has been sent to your email and mobile number.
      Please enter it below to confirm your registration.
    </p>
    <div class="form-group row">
      <!-- <label for="txtPin" class="col-md-3 col-form-label">Code<span class="text-danger">*</span>
            </label> -->
      <div class="col-md-12">
        <div class="d-flex flex-column mb_conformation">
          <input
            type="text" maxlength="4" allowNumbersonly
            [ngClass]="
              PinRequired || PinInvalid
                ? 'form-control form-control--new is-invalid'
                : 'form-control form-control--new'
            "
            id="txtPin"
            [(ngModel)]="Pincode"
            (input)="OnPinChange()"
          />
          <div *ngIf="PinRequired">
            <small class="invalid-feedback d-block"
              >Authorisation Code is required</small
            >
          </div>
          <div *ngIf="PinInvalid">
            <small class="invalid-feedback d-block"
              >The security code you entered is incorrect. Please try again
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="form-group row col-md-12">
          <button class="btn btn-link" role="link" (click)="ResendPinClick()" value="">Resend Code</button>
      </div>
  </div>
  </div>
  <div class="info" *ngIf="button == 5">
    <p>
      Enter a password below. Your password must be at least 8 characters long,
      contain an uppercase and lowercase character, and a special character.
    </p>
    <form
      [formGroup]="passwordFormGroup"
      (keydown.enter)="btnOK_Click()"
      class="create_password--form"
    >
      <div class="form-group row">
        <label for="txtPassword" class="col-md-12 col-form-label"
          >Enter Password*
        </label>
        <div class="col-md-12">
          <div class="d-flex flex-column w-100">
            <input
              type="Password"
              maxlength="20"
              [ngClass]="
                passwordFormGroup.get('txtPassword').invalid &&
                (passwordFormGroup.get('txtPassword').dirty || btnSubmit)
                  ? 'form-control form-control--new is-invalid'
                  : 'form-control  form-control--new'
              "
              id="txtPassword"
              formControlName="txtPassword"
              (keyup)="checkMatchPassword()"
            />
            <div
              *ngIf="
                passwordFormGroup.get('txtPassword').invalid &&
                (passwordFormGroup.get('txtPassword').dirty || btnSubmit)
              "
            >
              <small
                *ngIf="passwordFormGroup.get('txtPassword').errors.required"
                class="invalid-feedback d-block"
                >Password is required</small
              >
              <small
                *ngIf="passwordFormGroup.get('txtPassword').errors.pattern"
                class="invalid-feedback d-block"
                >Password must be minimum of 8 characters, include at least one number, include at least one upper case and one lower case letter 
                and contain at least one special character
                <br>
                The following characters are considered as special characters: {{ getSpecialCharacters() }}
                </small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="txtPasswordConf" class="col-md-12 col-form-label"
          >Confirm Password*
        </label>
        <div class="col-md-12">
          <div class="d-flex flex-column w-100">
            <input
              type="Password"
              maxlength="20"
              [ngClass]="
                ConfPasswordNotMatch ||
                (passwordFormGroup.get('txtPasswordConf').invalid &&
                  (passwordFormGroup.get('txtPasswordConf').dirty || btnSubmit))
                  ? 'form-control  form-control--new is-invalid'
                  : 'form-control  form-control--new'
              "
              id="txtPasswordConf"
              formControlName="txtPasswordConf"
              (keyup)="checkMatchPassword()"
            />
            <div
              *ngIf="
                passwordFormGroup.get('txtPasswordConf').invalid &&
                (passwordFormGroup.get('txtPasswordConf').dirty || btnSubmit)
              "
            >
              <small
                *ngIf="passwordFormGroup.get('txtPasswordConf').errors.required"
                class="invalid-feedback d-block"
                >Confirm Password is required</small
              >
              <small
                *ngIf="passwordFormGroup.get('txtPasswordConf').errors.pattern"
                class="invalid-feedback d-block"
                >Confirm Password must be minimum of 8 characters, include at least one number, include at least one upper case and one lower case letter 
                and contain at least one special character
                <br>
                The following characters are considered as special characters: {{ getSpecialCharacters() }}                
                </small
              >
            </div>
            <small *ngIf="ConfPasswordNotMatch" class="invalid-feedback d-block"
              >The passwords do not match, please try again</small
            >
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="info" *ngIf="button == 15">
    <p>
      An authorisation code has been sent to your email and mobile number.
      Please enter it below to confirm your registration.
    </p>
    <div class="form-group row">
      <div class="col-md-12">
        <div class="d-flex flex-column mb_conformation">
          <input
            type="text" maxlength="4" numberOnly
            [ngClass]="
              PinRequired || PinInvalid
                ? 'form-control form-control--new is-invalid'
                : 'form-control form-control--new'
            "
            id="txtPin"
            [(ngModel)]="Pincode"
            (keyup)="OnPinChange()" (change)="OnPinChange()"
          />
          <div *ngIf="PinRequired">
            <small class="invalid-feedback d-block"
              >Authorisation Code is required</small
            >
          </div>
          <div *ngIf="PinInvalid">
            <small class="invalid-feedback d-block"
              >The security code you entered is incorrect. Please try again
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="form-group row col-md-12">
          <button class="btn btn-link" role="link" (click)="ResendPinClick()" value="">Resend Code</button>
      </div>
  </div>
  </div>
  <div
    class="d-flex"
    [ngClass]="
      button == 4 || button == 5 || button == 15 ? 'reg_btnWrapper' : 'justify-content-center'
    "
  >
    <div *ngIf="button == 0">
      <button (click)="btnOK_Click()" class="btn btn-primary px-5">OK</button>
    </div>
    <div *ngIf="button == 1">
      <button
        mat-button
        (click)="btnCancel_Click()"
        tabindex="-1"
        class="btn bg-white px-5 border-primary text-primary"
      >
        CANCEL
      </button>
      <button
        mat-button
        (click)="btnOK_Click()"
        class="btn btn-primary px-5 ml-3"
      >
        OK
      </button>
    </div>
    <div *ngIf="button == 2">
      <button
        mat-button
        (click)="btnNo_Click()"
        tabindex="-1"
        class="btn bg-white px-5 border-primary text-primary"
      >
        NO
      </button>
      <button
        mat-button
        (click)="btnYes_Click()"
        class="btn btn-primary px-5 ml-3"
      >
        YES
      </button>
    </div>
    <div *ngIf="button == 3">
      <button
        mat-button
        (click)="btnReject_Click()"
        tabindex="-1"
        class="btn bg-white px-5 border-primary text-primary"
      >
        REJECT
      </button>
      <button
        mat-button
        (click)="btnAccept_Click()"
        class="btn btn-primary px-5 ml-3"
      >
        ACCEPT
      </button>
    </div>
    <div *ngIf="button == 4">
      <!-- <button
        mat-button
        (click)="btnCancel_Click()"
        tabindex="-1"
        class="btn bg-white px-md-5 px-3 border-primary text-primary"
      >
        Cancel
      </button> -->
      <button
        mat-button
        [disabled]="IsPinSubmitDisabled"
        (click)="btnOK_Click()"
        class="btn btn-new btn-primary px-5 w-100"
      >
        Submit
      </button>
    </div>
    <div *ngIf="button == 5">
      <!-- <button
        mat-button
        (click)="btnCancel_Click()"
        class="btn bg-white px-md-5 px-3 border-primary text-primary"
      >
        Cancel
      </button> -->
      <button
        mat-button
        (click)="btnOK_Click()"
        class="btn btn-new btn-primary px-5 w-100"
      >
        Confirm
      </button>
    </div>
    <div *ngIf="button == 6">
      <button
        mat-button
        (click)="btnCancel_Click()"
        tabindex="-1"
        class="btn bg-white px-5 border-primary text-primary"
      >
        Cancel
      </button>
      <button
        mat-button
        (click)="btnOK_Click()"
        class="btn btn-primary px-5 ml-3"
      >
        Login
      </button>
    </div>
    <div *ngIf="button == 7">
      <button
        mat-button
        (click)="btnCancel_Click()"
        tabindex="-1"
        class="btn bg-white px-5 border-primary text-primary"
      >
        Cancel
      </button>
      <button
        mat-button
        (click)="btnContinue_Click()"
        class="btn btn-primary px-5 ml-3"
      >
        Continue
      </button>
    </div>
    <div *ngIf="button == 8">
      <button
        mat-button
        (click)="btnNo_Click()"
        tabindex="-1"
        class="btn bg-white px-5 border-primary text-primary"
      >
        Cancel
      </button>
      <button
        mat-button
        (click)="btnYes_Click()"
        class="btn btn-primary px-5 ml-3"
      >
        Confirm
      </button>
    </div>
    <div *ngIf="button == 9">
      <button (click)="btnOK_Click()" class="btn btn-primary px-5">
        {{ buttonlabel }}
      </button>
    </div>
    <div *ngIf="button == 10">
      <button
        mat-button
        (click)="btnNo_Click()"
        tabindex="-1"
        class="btn bg-white px-5 border-primary text-primary"
      >
        CANCEL
      </button>
      <button
        mat-button
        (click)="btnYes_Click()"
        class="btn btn-primary px-4 ml-3"
        style="text-transform: none; background-color: red !important"
      >
        Confirm Rejection
      </button>
    </div>
    <div *ngIf="button == 11">
      <button
        mat-button
        (click)="btnYes_Click()"
        class="btn btn-primary px-4 ml-2"
      >
        YES
      </button>
      <button
        mat-button
        (click)="btnNo_Click()"
        class="btn btn-primary px-4 ml-2"
      >
        NO
      </button>
      <button
        mat-button
        (click)="btnContinue_Click()"
        class="btn btn-primary px-2 ml-2 mr-3"
      >
        CANCEL
      </button>
    </div>
    <div *ngIf="button == 12">
      <button
        mat-button
        (click)="btnYes_Click()"
        class="btn btn-primary px-4 ml-2"
      >
        Yes, Upload Now
      </button>
      <button
        mat-button
        (click)="btnNo_Click()"
        class="btn btn-primary px-4 ml-2"
      >
        No, Upload Later
      </button>
    </div>
    <div *ngIf="button == 13">
      <button (click)="btnOK_Click()" class="btn btn-primary px-5">OK</button>
    </div>
    <div *ngIf="button == 15">
      <button
        mat-button
        [disabled]="IsPinSubmitDisabled"
        (click)="btnOK_Click()"
        class="btn btn-new btn-primary px-5 w-100"
      >
        Submit
      </button>
    </div>
  </div>
</div>
