import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateTimeFormatPipe',
})
export class dateTimeFormatPipe implements PipeTransform {
    transform(value: string) {
        if (value === "NaN-NaN-NaN" || value === "NaN:NaN:NaN") {
            return "";
        }
        var datePipe = new DatePipe("en-US");
        value = datePipe.transform(value, 'dd-MMM-yyyy HH:mm:ss');
        return value;
    }
}