import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { FPAbstractComponent } from '@fp/components/base';
import { FpFileData, FpFileEvent } from '@fp/components/control';
import { CommonString } from '@fp/constant';
import { StringHelper } from '@fp/helpers';
import { DataResult, FileAttachmentModel, ListOfDocsModel } from '@fp/models';
import { EmployerService, MessageBox, CommonService } from '@fp/services';

@Component({
    selector: 'app-employer-additional-attachments',
    templateUrl: './additional-attachments.component.html',
    styleUrls: ['./additional-attachments.component.css']
})
export class EmployerAdditionalAttachmentsComponent extends FPAbstractComponent implements OnInit, OnDestroy {

    @Input() EmployerId: number;
    @Input() isReadOnly: boolean;

    dataDocumentAttachments: FileAttachmentModel[] = [];
    dataImagesAttachments: FileAttachmentModel[] = [];
    dataDocumentAttachmentsRemoved: FileAttachmentModel[] = [];
    dataImagesAttachmentsRemoved: FileAttachmentModel[] = [];
    documentAttachmentChange = false;
    imageAttachmentChange = false;

    ngOnDestroy(): void {
        this.dataDocumentAttachments = null;
        this.dataImagesAttachments = null;
        this.dataDocumentAttachmentsRemoved = null;
        this.dataImagesAttachmentsRemoved = null;
    }

    constructor(
        injector: Injector,
        private svc: EmployerService,
        private cmsrv: CommonService
    ) {
        super(injector);
    }

    ngOnInit() {
    }

    public RemoveAttachmentByType(item: FileAttachmentModel, type) {
        item.Status = 'Remove';
        if (type === 'Attachment') {
            if (item.Id && item.Id > 0) { // remove file in database
                this.dataDocumentAttachmentsRemoved.push(item);
            }
            const index = this.dataDocumentAttachments.indexOf(item);
            this.dataDocumentAttachments.splice(index, 1);
        } else {
            if (item.Id && item.Id > 0) { // remove file in database
                this.dataImagesAttachmentsRemoved.push(item);
            }
            const index = this.dataImagesAttachments.indexOf(item);
            this.dataImagesAttachments.splice(index, 1);
        }
    }

    // Search membership packages
    public LoadDocumentAttachments() {
        this.dataDocumentAttachmentsRemoved = []; // reset to empty
        this.Invoke(
            this.svc.getEmployerDocuments(this.EmployerId),
            {
                onSuccess: (edata: any) => {
                    const dectypted_data = this.cmsrv.D_FP_ResponseData(edata);
                    const rdata: DataResult<FileAttachmentModel[]> = JSON.parse(dectypted_data);
                    if (rdata.Success) {
                        this.dataDocumentAttachments = rdata.Data;
                    } else {
                        this.HandleResponseError(rdata);
                    }
                }
            },
        );
    }

    public LoadImageAttachments() {
        this.Invoke(
            this.svc.getEmployerImages(this.EmployerId),
            {
                onSuccess: (edata: any) => {
                    const dectypted_data = this.cmsrv.D_FP_ResponseData(edata);
                    const rdata: DataResult<FileAttachmentModel[]> = JSON.parse(dectypted_data);
                    if (rdata.Success) {
                        this.dataImagesAttachments = rdata.Data;
                    } else {
                        this.HandleResponseError(rdata);
                    }
                }
            },
        );
    }

    public GetDataImagesAttachments() {
        return this.dataImagesAttachments;
    }

    public GetDataDocumentAttachments() {
        return this.dataDocumentAttachments;
    }

    private SaveImageAttachments(EmployerId: number, callback: Function) {
        let ListOfImages = this.dataImagesAttachments;
        if (this.dataImagesAttachmentsRemoved.length > 0) {
            ListOfImages = ListOfImages.concat(this.dataImagesAttachmentsRemoved);
        }
        ListOfImages.filter(item => {
            item.EmployerId = EmployerId;
            if (item.Id === 0 || (item.Id > 0 && item.Status === 'Remove')) {
                this.imageAttachmentChange = true;
            }
        });
        const params = {
            ListOfImages: ListOfImages,
            ModifiedBy: this.cmsrv.GetUser()
        };
        if (ListOfImages.length > 0 && this.imageAttachmentChange) {
            this.Invoke(
                this.svc.saveEmployerImages(params),
                {
                    onSuccess: (res: DataResult<FileAttachmentModel[]>) => {
                        if (res.Success) {
                            // update data document attachment
                            this.dataImagesAttachments = res.Data;
                            this.dataImagesAttachmentsRemoved = [];
                            this.imageAttachmentChange = false;
                            callback();
                        } else {
                            this.HandleResponseError(res);
                        }
                    }
                },
            );
        } else {
            callback();
        }
    }

    private SaveDocumentAttachments(EmployerId: number, callback: Function) {
        let ListOfDocs = this.dataDocumentAttachments;
        if (this.dataDocumentAttachmentsRemoved.length > 0) {
            ListOfDocs = ListOfDocs.concat(this.dataDocumentAttachmentsRemoved);
        }
        ListOfDocs.filter(item => {
            item.EmployerId = EmployerId;
            if (item.Id === 0 || (item.Id > 0 && item.Status === 'Remove')) {
                this.documentAttachmentChange = true;
            }
        });
        const params: ListOfDocsModel = {
            ListOfDocs: ListOfDocs,
            ModifiedBy: this.cmsrv.GetUser()
        };
        if (ListOfDocs.length > 0 && this.documentAttachmentChange) {
            this.Invoke(
                this.svc.saveEmployerDocuments(params),
                {
                    onSuccess: (res: DataResult<FileAttachmentModel[]>) => {
                        if (res.Success) {
                            // update data document attachment
                            this.dataDocumentAttachments = res.Data;
                            this.dataDocumentAttachmentsRemoved = [];
                            this.documentAttachmentChange = false;
                            callback();
                        } else {
                            this.HandleResponseError(res);
                        }
                    }
                },
            );
        } else {
            callback();
        }
    }

    public SaveAdditionalAttachment(EmployerId: number, callback: Function) {
        const that = this;
        this.SaveDocumentAttachments(EmployerId, function () {
            that.SaveImageAttachments(EmployerId, function () {
                callback();
            });
        });
    }
    //#endregion Additional Attachments

    public AddFile_UploadCompleted(event) {
        const ret: FpFileData = event;
        this.dataDocumentAttachments.push({
            EmployerId: this.EmployerId,
            Location: ret.filedata.Location,
            Base64: '',
            FileName: ret.originfilename,
            Status: 'Addnew',
            Id: 0
        });
        console.log(event);
    }
    public AddImage_UploadCompleted(event) {
        const ret: FpFileData = event;
        this.dataImagesAttachments.push({
            EmployerId: this.EmployerId,
            Location: ret.filedata.Location,
            Base64: '',
            FileName: ret.originfilename,
            Status: 'Addnew',
            Id: 0
        });
        console.log(event);
    }

    public ImagesAdditionalSelected(event: FpFileEvent) {
        if (event.file.type !== 'image/png' &&
            event.file.type !== 'image/jpg' &&
            event.file.type !== 'image/jpeg') {
            event.cancel();
            MessageBox.ShowError(this.dialog,
                StringHelper.format(CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE, 'JPEG or PNG')
            );
        }
    }

    public AttachmentAdditionalSelected(event: FpFileEvent) {
        const filesTypeAllow = [
            'application/pdf',
            'application/vnd.ms-excel',
            'application/msword',
            'text/plain',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];
        if (filesTypeAllow.indexOf(event.file.type) === -1) {
            event.cancel();
            MessageBox.ShowError(this.dialog,
                StringHelper.format(CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE, 'PDF, DOCX, DOC, XLS, XLSX, TXT')
            );
        }
    }

}
