import { CommonData, User, Employer, RequestPagination, ConditionPagination, FilterPagination } from '@fp/models';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, empty } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { catchError, finalize, debounceTime, switchMap } from 'rxjs/operators';
import { MemberService } from 'src/app/services/member.service';
import { SearchPaginationRequest, DataResult, ResultModel, SearchPaginationResult } from 'src/app/models';
import { ApproveOrRejectMembers, MemberVerification } from 'src/app/models/member-verification.model';
import { CommonString } from 'src/app/constant/common-string';
import { MessageBox } from 'src/app/services/common-dialog.service';
import { EApproveMemberErrorNumber } from 'src/app/enums/approve-members-number';
import { DialogResult } from '@fp/components/common-dialog/common-dialog.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { MemberRequestInfo } from '@fp/models/member-request-info.model';
import { ERole } from '@fp/enums/role.enum';
import { StorageKey } from '@fp/constant';
import { EmployerService } from '@fp/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MembershipUploadPhotoComponent } from '../membership-cards/membership-upload-photo/membership-upload-photo.component';
import { Router } from "@angular/router";

@Component({
    selector: 'app-members-verification',
    templateUrl: './members-verification.component.html',
    styleUrls: ['./members-verification.component.css']
})
export class MembersVerificationComponent implements OnInit, AfterViewInit, OnDestroy {
    /* paging = <SearchPaginationRequest>{
        PageNumber: 1,
        PageSize: 10,
        OrderBy: null,
        OrderByAsc: true,
        Params: [{
            Name: '',
            Value: null
        }]
    } */

    paging: RequestPagination = new RequestPagination();

    displayedColumns: string[] = ['tickBoxes', 'sortby', 'lastName', 'firstName', 'status', 'employer'];
    dataSource: MatTableDataSource<MemberVerificationFE>;
    selection: PageMemberIds[] = [];
    commonString = CommonString;
    //material
    length: number;
    pageSize: number;
    pageSizeOptions: number[] = [10, 20, 50];
    pageEvent: PageEvent;
    //material

    members: MembersVerificationFE;
    requestQueueCount: number = 0;

    AdditionalInfoFormGroup: UntypedFormGroup;
    btnSubmitClicked = false;
    InformationRequired: CommonData[] = [];
    RequestInfoMember: MemberVerificationFE;
    MsgEmpMem = '';
    parentFocus: boolean = false;
    isSearchingEmployer = false;
    employerName = new UntypedFormControl();
    memberCtrl = new UntypedFormControl();
    employerSearchResults: Employer[];
    currentUser: User;
    requestEmployers: SearchPaginationRequest;
    memberVerificationSelected: MemberVerificationFE;

    @ViewChild('paginator') paginator: MatPaginator;

    ngOnDestroy() {
        this.employerName = null;
        this.paging = null;
    }
    constructor(
        private employerSvc: EmployerService,
        private commonService: CommonService,
        private service: MemberService,
        private dialog: MatDialog,
        private _formBuilder: UntypedFormBuilder,
        private modalService: NgbModal) {
        this.requestEmployers = {
            OrderBy: 'name',
            OrderByAsc: true,
            PageNumber: 0,
            PageSize: 0,
            Params: []
        };
    }

    ngOnInit() {
        const selectedRole = this.commonService.GetSelectedRole();
        this.paging.OrderBy = 'status';
        this.paging.OrderByAsc = true;
        if (selectedRole === ERole.admin) {
            this.paging.Params[0].Name = '';
        }
        if (selectedRole === ERole.Employer_Facilitator) {
            this.paging.Params[0].Name = selectedRole;
        }
        this.paging.Params[0].Value = this.commonService.GetUserObject().UserId;
        let conditions: Array<ConditionPagination> = [];
        this.paging.Params[0].Conditions = conditions;
        let filters: Array<FilterPagination> = [];
        this.paging.Params[0].Filters = filters;
        this.loadMembers();
        this.SetValidator();
        this.LoadInformationRequired();
        this.MsgEmpMem = selectedRole === ERole.Employer_Facilitator ? 'Message to Employee' : 'Message to Member';
    }

    ngAfterViewInit() {
        this.employerName.valueChanges.pipe(
            debounceTime(500),
            switchMap((value) => {
                if (value != null && value.length >= 3) {
                    this.isSearchingEmployer = true;
                    const SelectedRole = this.commonService.GetSelectedRole();
                    this.currentUser = <User>JSON.parse(this.commonService.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT)));
                    if (SelectedRole === ERole.Employer_Facilitator) {
                        this.requestEmployers.Params = [
                            { Name: 'employer_facilitator', Value: this.currentUser.UserId },
                            { Name: 'name_code', Value: value }
                        ];
                    } else {
                        this.requestEmployers.Params = [{
                            Name: 'name_code',
                            Value: value
                        }];
                    }
                    return this.employerSvc.search(this.requestEmployers);
                } else {
                    this.employerSearchResults = [];
                    this.isSearchingEmployer = false;
                }
                return empty();
            }),
            catchError((e) => { throw e; })
        ).subscribe(
            (edata: string) => {
                const dectypted_data = this.commonService.D_FP_ResponseData(edata);
                const rdata: DataResult<SearchPaginationResult<Employer>> = JSON.parse(dectypted_data);
                this.isSearchingEmployer = false;
                if (rdata != null) {
                    this.employerSearchResults = rdata.Data.Results;
                } else {
                    console.error(rdata);
                }
            },
            error => {
                this.isSearchingEmployer = false;
                MessageBox.ShowError(this.dialog, 'An error occurred while trying to call a service');
                console.error(error);
            }
        );
    }

    ddlViewMember_DisplayWithFn(option: any) {
        if (!option) {
            return '';
        }
        return option.Name;
    }

    private SetValidator() {
        this.AdditionalInfoFormGroup = this._formBuilder.group({
            ddlInfoRequired: ['', Validators.required],
            txtMsgEmpMem: ['', Validators.required]
        });
    }

    private LoadInformationRequired() {
        this.invoke(
            this.service.getInformationRequired(),
            (result: DataResult<CommonData[]>) => {
                if (result.Success) {
                    this.InformationRequired = result.Data;
                }
            }
        );
    }

    public btnSubmit_Click(event) {
        this.btnSubmitClicked = true;
        if (this.AdditionalInfoFormGroup.invalid) {
            return;
        } else {
            const mri: MemberRequestInfo = new MemberRequestInfo();
            const user_obj = this.commonService.GetUserObject();
            mri.InformationRequired = this.AdditionalInfoFormGroup.get('ddlInfoRequired').value;
            mri.SelectedRole = this.commonService.GetSelectedRole();
            mri.FPStaffEmail = user_obj.EmailAddress;
            mri.Message = this.AdditionalInfoFormGroup.get('txtMsgEmpMem').value;
            mri.MemberId = this.RequestInfoMember.id;
            this.SendMemberEmail(mri);
        }
    }

    private SendMemberEmail(mri: MemberRequestInfo) {
        this.invoke(
            this.service.sendMemberEmail(mri),
            (data: ResultModel) => {
                if (data.Success) {
                    //MessageBox.ShowInfo(this.dialog, 'The email has been sent successfully to the Member');
                    document.getElementById('btnCloseModalRequest').click();
                } else {
                    MessageBox.ShowError(this.dialog, 'Something went wrong');
                    console.error(data.Message);
                }
            }
        );
    }

    public IsControlInvalid(tmpformgroup: UntypedFormGroup, controlname: string): boolean {
        return (tmpformgroup.get(controlname).invalid
            && (tmpformgroup.get(controlname).dirty ||
                tmpformgroup.get(controlname).touched ||
                this.btnSubmitClicked));
    }

    public SetClassForControl(tmpformgroup: UntypedFormGroup, controlname: string): string {
        if (tmpformgroup.get(controlname).invalid
            && (tmpformgroup.get(controlname).dirty ||
                tmpformgroup.get(controlname).touched ||
                this.btnSubmitClicked)) {
            return 'form-control is-invalid';
        } else {
            return 'form-control';
        }
    }

    public btnRequestInfoClick(member?: MemberVerificationFE) {
        this.RequestInfoMember = member;
        this.btnSubmitClicked = false;
        this.AdditionalInfoFormGroup.get('ddlInfoRequired').setValue('');
        this.AdditionalInfoFormGroup.get('txtMsgEmpMem').setValue('');
        this.AdditionalInfoFormGroup.reset();
        this.AdditionalInfoFormGroup.get('ddlInfoRequired').setValue('');
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        let currentPageMemberIds: Array<number> = [];
        if (this.dataSource != null)
            this.dataSource.data.forEach(m => { currentPageMemberIds.push(m.id) });

        let numbers: number[] = [];
        this.selection.forEach(obj => {
            if (obj != undefined)
                if (obj.page == this.paging.PageNumber) {
                    obj.memberIds.forEach(id => {
                        currentPageMemberIds.forEach(i => {
                            i == id ? numbers.push(i) : null
                        })
                    })
                }

        });
        return numbers.length == 0 ? false : numbers.length == currentPageMemberIds.length;
    }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        const currentPage: number = this.paging.PageNumber - 1;
        if (this.isAllSelected()) {
            try {
                if (this.selection[currentPage].page == this.paging.PageNumber)
                    this.selection[currentPage].memberIds = [];
            }
            catch {
                let _memberIds: Array<number> = [];
                this.members.members.forEach(m => {
                    _memberIds.push(m.id)
                });
                this.selection[currentPage] = {
                    memberIds: _memberIds,
                    page: this.paging.PageNumber
                };
            }
        } else {
            let _memberIds: Array<number> = [];
            this.members.members.forEach(m => {
                _memberIds.push(m.id)
            });
            this.selection[currentPage] = {
                memberIds: _memberIds,
                page: this.paging.PageNumber
            };
        }
    }
    toggle(event, id: number) {
        const currentPage: number = this.paging.PageNumber - 1;
        if (this.selection[currentPage] == undefined) {
            this.selection[currentPage] = {
                memberIds: [],
                page: this.paging.PageNumber
            }
        }
        if (event.target.checked == false)
            this.selection[currentPage].memberIds.splice(this.selection[currentPage].memberIds.indexOf(id), 1);
        else
            this.selection[currentPage].memberIds.push(id);
    }
    /**
     * Running when user manipulate on material paginator.
     * @param event
     */

    paginatorChange(event: PageEvent) {
        this.paging.PageNumber = event.pageIndex + 1;
        this.paging.PageSize = event.pageSize;
        this.loadMembers(() => {
            if (this.one.length > 0) {
                const currentPage = this.paging.PageNumber - 1;
                if (this.selection[currentPage] == undefined) {
                    this.selection[currentPage] = {
                        memberIds: [],
                        page: this.paging.PageNumber
                    }
                }

                this.dataSource.data.forEach(m => {
                    this.one = this.one.filter(id => {
                        if (id == m.id)
                            this.selection[currentPage].memberIds.push(id);
                        else
                            return true;
                    })
                })
            }
        });
    }
    /**
     * Running when user click column header of Material table.
     * @param sort
     */

    one: Array<number> = [];
    sortChange(sort: Sort) {
        const active: string = sort.direction == '' ? null : sort.active.toLowerCase();
        const isOderByAsc: boolean = sort.direction == 'asc' ? false : true;
        this.paging.OrderBy = active;
        this.paging.OrderByAsc = isOderByAsc;
        this.loadMembers(() => {
            const currentPage: number = this.paging.PageNumber - 1;
            this.dataSource.data.forEach(m => {
                this.selection.forEach((obj, index) => {
                    this.selection[index].memberIds = obj.memberIds.filter(id => {
                        if (id != m.id) {
                            this.one.includes(id) ? null : this.one = this.one.concat(id);
                        }
                        else
                            return true
                    });
                });
            });

            this.dataSource.data.forEach(m => {
                this.one = this.one.filter(id => {
                    if (id == m.id)
                        this.selection[currentPage].memberIds.push(id);
                    else
                        return true;
                })
            })
        });
    }

    disabledButton() {
        let isBool: boolean = true;
        if (this.selection.length > 0) {
            this.selection.forEach((obj) => {
                if (obj != undefined) {
                    if (obj.memberIds.length > 0) {
                        isBool = false;
                        return;
                    }
                }
            })
        }
        return isBool;
    }

    invoke(source: Observable<any>, handleResultCallback: Function) {
        this.requestQueueCount++;
        this.commonService.StartProgressBar();
        source.pipe(
            catchError((e) => { throw e; }),
            finalize(() => {
                this.requestQueueCount--;
                if (this.requestQueueCount <= 0) {
                    this.commonService.StopProgressBar();
                    this.requestQueueCount = 0;
                }
            }),
        ).subscribe(
            res => {
                handleResultCallback(res);
            },
            err => {
                console.log(err);
            }
        );
    }

    loadMembers(callBack?: Function) {
        this.invoke(
            this.service.getMembers(this.paging),
            (result: DataResult<SearchPaginationResult<MemberVerification>>) => {
                const d = this.processMembers(result);
                this.members = d.Data;
                if (this.dataSource == null) {
                    this.dataSource = new MatTableDataSource();
                }
                this.dataSource.data = d.Data.members;
                this.length = d.Data.totalItem;

                if (callBack != undefined) {
                    callBack();
                }
            }
        );
    }

    searchMemberVerification() {
        this.paging.PageNumber = 1;
        this.paginator.pageIndex = 0;
        const employerVal = this.employerName.value;
        const memberVal = this.memberCtrl.value;
        let filters: Array<FilterPagination> = [];
        if (employerVal) {
            const employerId = employerVal.EmployerId;
            if (employerId) {
                filters.push({
                    Name: 'EmployerId',
                    Value: employerId
                });
            }
        }
        if (memberVal) {
            filters.push({
                Name: 'Member',
                Value: memberVal
            });
        }
        this.paging.Params[0].Filters = filters;
        this.loadMembers();
    }

    processMembers(data: DataResult<SearchPaginationResult<MemberVerification>>) {
        if (data == null) {
            MessageBox.ShowError(this.dialog, 'Something went wrong');
            console.error(data)
            return new DataResult<MembersVerificationFE>();
        }
        else if (data.Success == false) {
            MessageBox.ShowError(this.dialog, 'Something went wrong');
            console.error(data)
            return new DataResult<MembersVerificationFE>();
        }
        let _members: DataResult<MembersVerificationFE> = {
            Data: {
                members: [],
                totalItem: data.Data.TotalItem
            },
            Errors: data.Errors,
            Message: data.Message,
            Name: data.Name,
            Success: data.Success,
            WaitInSeconds: data.WaitInSeconds
        }

        data.Data.Results.forEach(member => {
            var _member = this.memberResultToMemberFE({ ...member })
            _members.Data.members.push(_member);
        });

        return _members;
    }


    private memberResultToMemberFE(member: MemberVerification): MemberVerificationFE {
        return {
            id: member.MemberId,
            WorkplaceSuburb: member.WorkplaceSuburb,
            lastName: member.LastName,
            firstName: member.FirstName,
            status: member.MemberStatus?.Name ?? null,
            employer: member.Employer?.Name ?? null,
            employeeNo: member.MemberType?.MemberTypeId === 1 ? member.EmployeePayrollNo : null,
            employerSuburb: member.Suburb?.Name ?? null,
            memberType: member.MemberType?.Name ?? null,
            memberNo: member.MemberNumber,
            photo: member.Photo.Location,
            primaryMember: member.PrimaryMember ? {
                id: member.PrimaryMember.MemberId,
                status: {
                    id: member.PrimaryMember.MemberStatus.MemberStatusId,
                    name: member.PrimaryMember.MemberStatus.Name
                }
            } : null
        };
    }

    setInvalidPhoto(member: MemberVerificationFE) {
        this.invoke(
            this.service.setInvalidPhoto(member.id),
            (data: ResultModel) => {
                if (data.Success) {
                    member.photo = null;
                } else {
                    MessageBox.ShowError(this.dialog, 'Something went wrong');
                    console.error(data.Message);
                }
            }
        );
    }

    ApproveOrReject_Click(isApprove: boolean, member?: MemberVerificationFE) {
        var dialogtext = "";
        var dialogheader = "";
        if (isApprove === false) {
            dialogtext = 'Are you sure you want to reject the member’s Fitness Passport application(s)?';
            dialogheader = 'Verification';
        } else {
            dialogtext = "Are you sure you want to approve the member's Fitness Passport application(s)?"
            dialogheader = 'Verification';
        }
        MessageBox.ShowYesNoVerification(
            this.dialog,
            dialogtext,
            dialogheader).subscribe(res => {
                if (res.result.toLowerCase() === DialogResult.Yes) {
                    this.approveOrRejectSelected(isApprove, member);
                } else {
                    return;
                }
            });
    }

    approveOrRejectSelected(isApprove: boolean, member?: MemberVerificationFE) {
        let selectedMemberIds: ApproveOrRejectMembers = new ApproveOrRejectMembers();
        selectedMemberIds.ModifiedBy = this.commonService.GetUser();
        selectedMemberIds.MemberIds = [];
        if (member != undefined) {
            selectedMemberIds.MemberIds.push(member.id);
            this.selection.forEach((obj, index) => {
                this.selection[index].memberIds = obj.memberIds.filter(id => {
                    if (id != member.id) {
                        return true;
                    }
                });
            })
        }
        else {
            this.selection.forEach(s => {
                selectedMemberIds.MemberIds = selectedMemberIds.MemberIds.concat(s.memberIds);
            })
        }

        this.invoke(
            this.service.approveOrJejectSelectedMember(selectedMemberIds, isApprove),
            (data: DataResult<number>) => {
                if (data.Success) {
                    const endingPage = Math.ceil(this.members.totalItem / this.paging.PageSize);
                    const updatedEndingPage = Math.ceil((this.members.totalItem - (data.Data ? data.Data : 0)) / this.paging.PageSize);
                    if (endingPage != updatedEndingPage) {
                        if (this.paging.PageNumber == endingPage) {
                            this.paging.PageNumber = updatedEndingPage;
                            this.paginator.pageIndex = 0;
                        }
                    }

                    this.loadMembers(() => {
                        member == undefined ? this.selection = [] : null;
                    });
                }
                else {
                    if (data.ErrorNumber == EApproveMemberErrorNumber.SystemError) {
                        console.error(data.Message);
                        MessageBox.ShowError(this.dialog, 'Something went wrong');
                    }
                    else {
                        MessageBox.ShowCustom(this.dialog, "Warning", "Warning", data.Message).subscribe(() => {
                            if (data.ErrorNumber == EApproveMemberErrorNumber.StatusChanged) {
                                this.loadMembers(() => {
                                    member == undefined ? this.selection = [] : null;
                                });
                            }
                        })
                    }
                }
            }
        );
    }
    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }

    onBlurParent(focusNumbr) {
        this.parentFocus = focusNumbr;
    }

    getClassFocused(vlFocused) {
        if (this.parentFocus == vlFocused) {
            return "focused";
        } else {
            return "";
        }
    }

    public OnSelectedUploadPhoto(item) {
        this.openModal(item);
    }

    openModal(item, isChargeFee?: boolean) {
        const modalRef = this.modalService.open(MembershipUploadPhotoComponent, {
            backdrop: 'static'
        });
        (<MembershipUploadPhotoComponent>modalRef.componentInstance).memberVerificationSelected = item;
        if (isChargeFee) {
            (<MembershipUploadPhotoComponent>modalRef.componentInstance).isChargeFee = true;
        }

        modalRef.result.then(
            (result: { location: string }) => {
                if (result && result.location) {
                    console.log('ok');
                    //Reload members to remove CSS from exif tag images
                    this.loadMembers();
                }
            },
            reason => { }
        );
    }

    openLink(member) {
        window.open("/dashboard/member/edit/" + member.primaryMember.id);
    }
}

export class MemberVerificationFE {
    id: number;
    lastName: string;
    firstName: string;
    status: string;
    employer: string;
    employeeNo: string;
    employerSuburb: string;
    memberType: string;
    photo: string;
    primaryMember: PrimaryMemberFE;
    memberNo: string;
    WorkplaceSuburb: string;
}

export class MembersVerificationFE {
    constructor(
        public members: MemberVerificationFE[] = [],
        public totalItem: number
    ) { }
}

class MemberStatusFE {
    id: number;
    name: string;
}

class PrimaryMemberFE {
    id: number;
    status: MemberStatusFE
}

class MemberIds {
    memberIds: Array<number>;
}

class PageMemberIds extends MemberIds {
    page: number;
}