<h3 class="font-weight-normal text-primary">View/Edit Employer</h3>
<!-- <div class="d-flex">
        <form>
            <div class="form-group row">
                <label for="ddlLocation" class="col-sm-4 col-form-label">Search Keyword</label>
                <div class="col-sm-8">
                    <select class="form-control" name="ddlLocation" [(ngModel)]="selectedRegionID" (change)="ddlLocation_Change($event)">
                        <option value="0">View All</option>
                        <option *ngFor="let region of regions" [value]="region.Id">{{region.Name}}</option>
                    </select>
                </div>
            </div>
        </form>
        <button type="button" class="btn btn-primary my-3 px-5" (click)="btnAddNewEmployer_Click();" style="display:none">Add
            New Employer</button>
    </div> -->
<div class="py-3">
    <!-- Data table material here -->
    <div class="border-default rounded-10 border-2">
        <div class="tablecontainer">
            <!-- <mat-form-field>
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                </mat-form-field> -->
            <table mat-table [dataSource]="dataSource" matSort>
                <!-- Employer Name Column -->
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:30%">Employer</th>
                    <td mat-cell *matCellDef="let element" style="width:30%"> {{element.Name}} </td>
                </ng-container>

                <!-- Facilitator Full name -->
                <ng-container matColumnDef="FullName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Primary<br />Facilitator</th>
                    <td mat-cell *matCellDef="let element"> {{element.FullName}} </td>
                </ng-container>

                <!-- Region Column -->
                <ng-container matColumnDef="RegionName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Region </th>
                    <td mat-cell *matCellDef="let element"> {{element.RegionName}} </td>
                </ng-container>

                <!-- Employer status Column -->
                <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let element"
                        [ngClass]="element.Status == 'Active' ? 'text-success':'text-muted'">
                        {{element.Status}} </td>
                </ng-container>


                <!-- Employer Code Column -->
                <ng-container matColumnDef="Code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
                    <td mat-cell *matCellDef="let element"> {{element.Code}} </td>
                </ng-container>

                <!-- Employer command Column -->
                <ng-container matColumnDef="Edit">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let row">
                        <!-- <a href="#" class="px-2"><i class="fas fa-trash-alt"></i></a> -->
                        <a routerLink="{{'/' + routerConstant.NAVIGATOR_EMPLOYER_EDIT + row.EmployerId}}"
                            class="px-2"><i class="fas fa-pen"></i></a>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <mat-toolbar *ngIf="dataSource != null && dataSource.data.length == 0 && isCompleteSearch==true"
            class="text-muted d-block p-2 bg-light text-center">
            {{noRecordFoundText}}</mat-toolbar>
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="paginatorChange($event)" showFirstLastButtons></mat-paginator>
    </div>
</div>
<button class="px-5 py-2 btn bg-white border-primary text-primary" type="button" (click)="btnbackEmployer_Click();"><i
        class="fas fa-arrow-alt-circle-left mr-2"></i>BACK</button>