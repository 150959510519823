import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FPAbstractComponent } from '@fp/components/base/abstract-component';
import { DialogResult } from '@fp/components/common-dialog/common-dialog.component';
import { APIConstant, StorageKey } from '@fp/constant';
import { RouterConstant } from '@fp/constant/routerconstant';
import { EEmployerStatus } from '@fp/enums';
import { DataResult, Member, User } from '@fp/models';
import { MemberContainer } from '@fp/models/member-container.model';
import { CommonService, HttpDAO, MemberService, MessageBox } from '@fp/services';

@Component({
  selector: 'app-continue-pre-signup-survey',
  templateUrl: './continue-pre-signup-survey.component.html',
  styleUrls: ['./continue-pre-signup-survey.component.css']
})
export class ContinuePreSignupSurveyComponent extends FPAbstractComponent implements OnInit{

  isSurveyPendingdMessageVisible: Boolean;
  isThankYouMessageVisible: Boolean;
  isSurveyExpiredMessageVisible: Boolean;
  isSurveyClosedMessageVisible: Boolean;
  httpdao: HttpDAO | null;

  constructor(injector: Injector, 
    private router: Router,
     protected commonService: CommonService,
      private memberSvc: MemberService,
      private http: HttpClient,) {
    super(injector);
    const uobject = this.commonService.GetUserObject();
    const fullname = uobject.FirstName;
    this.commonService.Header.title = "Hello, " + fullname + ". Welcome back!";

  }

  ngOnInit(): void {
    this.httpdao = new HttpDAO(this.http);
    this.commonService.App.opened = true;
    this.commonService.MenuForMember = true;
    this.commonService.App.isPaymentsVisible = false;
    this.commonService.Header.isLogin = true;
    this.commonService.App.isMemberMenuDisabled = true;
    this.commonService.App.isLoginSuccessDisplay = true;
    const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);

    if(getContainer){
      this.loadMenuAndDisplayMessage(getContainer);
    }
    else{
      MessageBox.ShowError(
        this.dialog,
        "Sorry, there was a connection issue. Please try submitting the information again."
      )
        .subscribe((res) => {
          if (res.result.toLowerCase() === DialogResult.Ok) {
            this.commonService.SignOut();
          }
        });
    }
  }

  loadMenuAndDisplayMessage(memberContainer: string) {
    let container = <MemberContainer>JSON.parse(memberContainer);
    var currentMember = <DataResult<Member>>JSON.parse(this.commonService.D_FP_AES256(container.Member));

    if (currentMember && currentMember.Data.Employer.EmployerStatusId && currentMember.Data.MemberPreSignupSurvey) {
      if (currentMember.Data.MemberPreSignupSurvey.HasMemberCompletedSurvey &&
        (currentMember.Data.Employer.EmployerStatusId == EEmployerStatus.Survey || currentMember.Data.Employer.EmployerStatusId == EEmployerStatus.ProgramDevelopment
          || currentMember.Data.Employer.EmployerStatusId == EEmployerStatus.CloseForNewMembers || currentMember.Data.Employer.EmployerStatusId == EEmployerStatus.FailedLaunch
        )) {
        this.isThankYouMessageVisible = true;
        this.isSurveyPendingdMessageVisible = false;
        this.isSurveyExpiredMessageVisible = false;
        this.isSurveyClosedMessageVisible = false;
      }
      else if (currentMember.Data.Employer.EmployerStatusId == EEmployerStatus.Survey &&
        !currentMember.Data.MemberPreSignupSurvey.HasMemberCompletedSurvey
        && currentMember.Data.MemberPreSignupSurvey.IsPreSignupSurveyEligible) {
        this.isThankYouMessageVisible = false;
        this.isSurveyPendingdMessageVisible = true;
        this.isSurveyExpiredMessageVisible = false;
        this.isSurveyClosedMessageVisible = false;
      }
      else if (!currentMember.Data.MemberPreSignupSurvey.HasMemberCompletedSurvey && ((currentMember.Data.Employer.EmployerStatusId == EEmployerStatus.Survey
        && !currentMember.Data.MemberPreSignupSurvey.IsPreSignupSurveyEligible)
        || currentMember.Data.Employer.EmployerStatusId == EEmployerStatus.ProgramDevelopment)) {
        this.isThankYouMessageVisible = false;
        this.isSurveyPendingdMessageVisible = false;
        this.isSurveyExpiredMessageVisible = true;
        this.isSurveyClosedMessageVisible = false;
      }
      else if (!currentMember.Data.MemberPreSignupSurvey.HasMemberCompletedSurvey && (currentMember.Data.Employer.EmployerStatusId == EEmployerStatus.FailedLaunch
        || currentMember.Data.Employer.EmployerStatusId == EEmployerStatus.CloseForNewMembers)) {
        this.isThankYouMessageVisible = false;
        this.isSurveyPendingdMessageVisible = false;
        this.isSurveyExpiredMessageVisible = false;
        this.isSurveyClosedMessageVisible = true;
      }
      else {
        this.isThankYouMessageVisible = false;
        this.isSurveyPendingdMessageVisible = false;
        this.isSurveyExpiredMessageVisible = false;
        this.isSurveyClosedMessageVisible = false;
      }
    }
  }

  openLink(link: string) {
    window.open(link);
  }
  
  ContinueSurvey() {
    this.router.navigate([RouterConstant.NAVIGATOR_PRE_SIGNUP_SURVEY]);
  }
}
