<div [formGroup]="formGroup">
    <div class="question">
        <div class="question-number">{{existingFacilitySelectionQuestion.QuestionNo}}</div>
        <div class="question-content">
            <div class="question-content-title">{{existingFacilitySelectionQuestion.QuestionText}} <span class="required" *ngIf="_required">*</span></div>
            <div *ngIf="helpText" class="question-content-helptext text-muted">{{helpText}}</div>
        </div>
    </div>
    <div class="response">
        <mat-radio-group formControlName="hasFamilyMembership" aria-label="Select an option">
            <div><mat-radio-button value="yes">Yes</mat-radio-button></div>
            <div><mat-radio-button value="no">No</mat-radio-button></div>
        </mat-radio-group>
    
        <div class="option-yes mt-3" [class.d-block]="hasMemberships" [class.d-none]="!hasMemberships">
            <strong>{{existingFacilitySelectionQuestion.SubQuestionText}}</strong>
            <div class="row">
                <div class="col-sm-6 mt-2">
                    <label>{{existingFacilitySelectionQuestion.SubQuestions[0].QuestionText}}</label>
                    <app-facility-single-select id="myMemberships" formControlName="myMemberships" [facilities]="facilities"></app-facility-single-select>
                </div>
                <div class="col-sm-6 mt-2">
                    <label>{{existingFacilitySelectionQuestion.SubQuestions[1].QuestionText}}</label>
                    <app-facility-single-select id="familyMemberships" formControlName="familyMemberships" [facilities]="facilities"></app-facility-single-select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-sm-6">
                    <label>{{existingFacilitySelectionQuestion.SubQuestions[2].QuestionText}}</label>
                    <input formControlName="otherMemberships" type="text" class="form-control" id="otherMemberships">
                </div>
            </div>
        </div>
    </div>
</div>