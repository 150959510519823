export const DateHelper = {
  /**
   * Format the Date value using the provided format string, and return the formatted date string.
   * @param {Date} value The `Date` object to be formatted.
   * @param {string} format The format string. e.g. DD/MM/YYYY
   *
   * Possible format tokens:
   * * Year: _YY_ or _YYYY_.
   * * Month: _M_, _MM_, _MMM_ or _MMMM_.
   * * Day: _D_ or _DD_.
   * * Weekday: _ddd_ or _dddd_.
   * * Hour: 24-hour format: _H_ or _HH_. 12-hour format: _h_ or _hh_.
   * * Minute: _m_ or _mm_.
   * * Second: _s_ or _ss_.
   * * Day period (AM/PM): _a_ or _A_ (lower-case or UPPER-CASE).
   * * Timezone offset: _Z_, _ZZ_ or _ZZZ_.
   *
   * **NOTE:** Currently, only support **en-US** locale.
   * @returns {String} Returns the formatted date string.
   * @example
   * // Format the date and return date string with numeric month value.
   * DateHelper.format(new Date(2019, 3, 19), 'DD/MM/YYYY');
   * // returns 19/04/2019
   *
   * // Format the date and return date string with short month name.
   * DateHelper.format(new Date(2019, 3, 19), 'DD-MMM-YYYY');
   * // returns 19-Apr-2019
   */
  format(value: Date, format: string): string {
    if (!value || !(value instanceof Date)) return "";
    if (
      !format ||
      format.match(
        /\bd{1,2}\b|d{5,}|D{3,}|M{5,}|\bY{1}\b|\bY{3}\b|Y{5,}|H{3,}|h{3,}|m{3,}|s{3,}|a{2,}|A{2,}|Z{4,}/g
      )
    ) {
      throw new Error("Invalid date time format: " + format);
    }
    // Wrap the tokens inside "{ }" pairs to distinguish them from other characters.
    let dateString = format.replace(/d+|D+|M+|Y+|H+|h+|m+|s+|a|A|Z+/g, "{$&}");
    let match = null;
    const _getMatchedToken = (text: string, token: string) => {
      const _pattern = "{(" + token + "+)}";
      const _matches = text.match(_pattern);
      return _matches ? _matches[1] : null;
    };
    // Year: 'YY' or 'YYYY'
    match = _getMatchedToken(dateString, "Y");
    if (match) {
      dateString = dateString.replace(
        /\{Y+\}/g,
        value.toLocaleString("en-US", {
          year: match.length > 3 ? "numeric" : "2-digit"
        })
      );
    }
    // Month: 'M', 'MM', 'MMM' or 'MMMM'
    match = _getMatchedToken(dateString, "M");
    if (match) {
      dateString = dateString.replace(
        /\{M+\}/g,
        value.toLocaleString("en-US", {
          month:
            match.length > 3
              ? "long"
              : match.length > 2
              ? "short"
              : match.length > 1
              ? "2-digit"
              : "numeric"
        })
      );
    }
    // Day: 'D' or 'DD'
    match = _getMatchedToken(dateString, "D");
    if (match) {
      dateString = dateString.replace(
        /\{D+\}/g,
        value.toLocaleString("en-US", {
          day: match.length > 1 ? "2-digit" : "numeric"
        })
      );
    }
    // Weekday: 'ddd' or 'dddd'
    match = _getMatchedToken(dateString, "d");
    if (match) {
      dateString = dateString.replace(
        /\{d+\}/g,
        value.toLocaleString("en-US", {
          weekday: match.length > 3 ? "long" : "short"
        })
      );
    }
    // Hour 24-hour format: 'H', 'HH'
    match = _getMatchedToken(dateString, "H");
    if (match) {
      dateString = dateString.replace(
        /\{H+\}/g,
        value
          .getHours()
          .toString()
          .padStart(match.length, "0")
      );
    }
    // Hour: 'h', 'hh'
    match = _getMatchedToken(dateString, "h");
    if (match) {
      const hour = value.getHours();
      dateString = dateString.replace(
        /\{h+\}/g,
        (hour > 12 ? hour % 12 : hour === 0 ? 12 : hour)
          .toString()
          .padStart(match.length, "0")
      );
    }
    // Minute: 'm' or 'mm'
    match = _getMatchedToken(dateString, "m");
    if (match) {
      dateString = dateString.replace(
        /\{m+\}/g,
        value
          .getMinutes()
          .toString()
          .padStart(match.length, "0")
      );
    }
    // Second: 's' or 'ss'
    match = _getMatchedToken(dateString, "s");
    if (match) {
      dateString = dateString.replace(
        /\{s+\}/g,
        value
          .getSeconds()
          .toString()
          .padStart(match.length, "0")
      );
    }
    // Period (AM/PM): 'a' or 'A'
    if (dateString.match(/\{[aA]\}/g)) {
      // NOTE: 'Intl.NumberFormat.formatToParts' wouldn't be available until TypeScript 3.0
      // #region Uncomment below when TypeScript version is upgraded to 3.0
      // const formatter = Intl.DateTimeFormat('en-US', { hour12: true, hour: 'numeric' });
      // const period = formatter.formatToParts(value).find(_part => _part.type === 'dayPeriod').value;
      // #endregion

      // #region Remove below when the above code become available
      // Temporarily hard-code the period text using en-US locale: AM/PM
      const period = value.getHours() >= 12 ? "PM" : "AM";
      // #endregion

      dateString = dateString.replace(/\{a\}/g, period.toLowerCase());
      dateString = dateString.replace(/\{A\}/g, period.toUpperCase());
    }
    // Timezone offset: 'Z', 'ZZ' or 'ZZZ'
    match = _getMatchedToken(dateString, "Z");
    if (match) {
      const offset = value.getTimezoneOffset();
      let offsetString = "";
      if (match.length > 2) {
        offsetString =
          Math.abs(offset / 60)
            .toFixed(0)
            .padStart(2, "0") +
          ":" +
          Math.abs(offset % 60)
            .toString()
            .padStart(2, "0");
      } else {
        offsetString = Math.abs(offset / 60)
          .toFixed(0)
          .padStart(match.length, "0");
      }
      dateString = dateString.replace(
        /\{Z+\}/g,
        (offset < 0 ? "+" : "-") + offsetString
      );
    }
    // NOTE: In IE, the Date.toLocaleString (or toLocaleDateString, toLocaleTimeString) API
    // also includes the LTR mark (U+200E) in the returned text. We need to strip the LTR mark
    // in order to produce the correct string for later use.
    return dateString.replace(/\u200E/g, "");
  },

  /**
   * Parse the date string in ISO format to `Date` object. Returns null if the passed-in string is
   * empty or not in a correct format.
   */
  parseUTC(isoDateString: string): Date {
    if (
      !isoDateString ||
      !isoDateString.match(
        /^\d{4}-\d{2}-\d{2}T\d{2}\:\d{2}\:\d{2}(\.\d{1,3})?[Z]?$/g
      )
    ) {
      return null;
    }
    if (!isoDateString.endsWith("Z")) {
      isoDateString += "Z";
    }
    let value = null;
    try {
      value = new Date(isoDateString);
    } catch (e) {
      console.error(e);
    }
    return value;
  },

  diff(
    date1: Date,
    date2: Date,
    returnToken:
      | "year"
      | "month"
      | "day"
      | "hour"
      | "minute"
      | "second" = "year"
  ): number {
    if (!date1 || !date2 || isNaN(date1.getTime()) || isNaN(date2.getTime()))
      return NaN;
    let diffDate = date1.getTime() - date2.getTime();
    switch (returnToken) {
      case "year":
        diffDate /= 12;
      case "month":
        diffDate = 365.25 / 12;
      case "day":
        diffDate /= 24;
      case "hour":
        diffDate /= 60;
      case "minute":
        diffDate /= 60;
      case "second":
        diffDate /= 1000;
        break;
    }
    diffDate;
  },

  /**
   * Takes a date object and converts it to the ISO-8601 format for the local machine's timezone.
   * Exmaple: for the Date("2021-01-06 5:18:33 PM AEDT"), it produces: "2021-01-06T17:18:33+11:00"
   */
  formatUtcToLocalISO(utcDate : Date) : string {
    var tzo = -utcDate.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num) {
            var norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };
    return utcDate.getFullYear() +
        '-' + pad(utcDate.getMonth() + 1) +
        '-' + pad(utcDate.getDate()) +
        'T' + pad(utcDate.getHours()) +
        ':' + pad(utcDate.getMinutes()) +
        ':' + pad(utcDate.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
  }
};
