import { DataResult } from './data-result';
import { Member } from './member.model';
import { MemberAdditionalNotes } from './MemberAdditionalNotes.model';
import { PrivilegeModel } from './privilege.model';

export class MemberContainer {
    User: string; // User Object
    Member: string; // Member Object
    Privileges: PrivilegeModel[] = []

    /// Possible Additions
    // MemberSearchRecord: DataResult<MemberSearchResultRecord[]> // Get all associated Members
    // MembershipPackageTypes: DataResult<MembershipPackageTypeModel>; // Get current Package Types
    // MemberInvoiceDatas: DataResult<MemberInvoiceDates[]> // Get Invoices
}
