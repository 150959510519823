import { Component, Injector, Input } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { empty } from "rxjs";
import { catchError, debounceTime, switchMap } from "rxjs/operators";
import { MemberFormBase } from "src/app/components/base/member-form-base";
import { CommonMessage, CommonString, StorageKey } from "src/app/constant";
import { FPValidators, StringHelper } from "src/app/helpers";
import * as enums from "src/app/enums";
import { Utils } from '@fp/helpers';
import {
  DataResult,
  Facility,
  SearchPaginationRequest,
  SearchPaginationResult,
  CommonData, Member, MembershipPackageTypeModel
} from "src/app/models";
import { FacilityService } from "src/app/services/facility.service";
import { MembershipService, MessageBox, CommonService } from "src/app/services";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { CommonConstants } from "@fp/constant/common-constants";
import { MemberContainer } from "@fp/models/member-container.model";
import { String } from "aws-sdk/clients/appstream";
import { bool } from "aws-sdk/clients/signer";

@Component({
  selector: "app-member-preferred-facilities",
  templateUrl: "./preferred-facilities.component.html",
  styleUrls: ["./preferred-facilities.component.css"],
  providers: [
    {
      provide: MemberFormBase,
      useExisting: MemberPreferredFacilitiesComponent,
    },
  ],
})
export class MemberPreferredFacilitiesComponent extends MemberFormBase {
  get FavouredFacilityCtrl() {
    return this.form.get("FavouredFacility");
  }
  get ExistingFacilityCtrl() {
    return this.form.get("ExistingFacility");
  }

  StringHelper = StringHelper;
  CommonMessage = CommonMessage;
  CommonString = CommonString;

  membershipPackageId: number;
  signUp: boolean;
  timeStamp = new Date().toISOString().replace(/[-:\.]/g, "");

  private facilityList: Facility[];

  //Facility: Favoured
  favFacilitySearch: Facility[] = [];
  favFacilitySelected: Facility;
  favFacilityIsLoading = false;

  //Facility: existing
  existFacilitySearch: Facility[] = [];
  existFacilitySelected: Facility;
  existFacilityIsLoading = false;

  IsFacTextDisplay = false;
  IsPrefferedFacilityMandotory = false;
  userId: number;
  parentFocus = 0;

  SortsOfMembership: CommonData[] = [];

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      FavouredFacilityId: [null],
      FavouredFacilityOther: [null],
      FavouredFacility: [null],

      IsExistingMember: [false, Validators.required],
      ExistingMemberFacilityId: [0],
      ExistingFacilityOther: [
        { value: null, disabled: true },
        CustomFacilityValidators.ExistingFacilityOtherRequiredWhen,
      ],
      ExistingFacility: [
        { value: null, disabled: true },
        [
          FPValidators.resolvableAutocompleteOption,
          CustomFacilityValidators.ExistingFacilityRequiredWhen,
        ],
      ],
      IsInContract: [{ value: false, disabled: true }],
      SortOfMembershipId: [{ value: null, disabled: true }],
      SortOfMembershipOther: [
        null,
        CustomFacilityValidators.WhatTypeOfMembershipOtherRequiredWhen,],
      ExistingFacilityApproximateCost: [{ value: null, disabled: true }],
      // ExistingFacilityApproximateCost: [null]
    });
  }

  constructor(
    injector: Injector,
    private facilitySvc: FacilityService,
    private commSvc: CommonService,
    private membershipSVC: MembershipService
  ) {
    super(injector);
  }

  // ngAfterViewInit() {
  //   this.OnLoad();
  // }

  //--------------------------

  OnLoad() {
    super.OnLoad();
    this.userId = this.commSvc.GetUserObject().UserId;
    this.signUp = this.isSignup();
    console.log("uid: ", this.userId);
    console.log("mem pkg: ", this.membershipPackageId);
    console.log("is signup? ", this.signUp);
    this.SortsOfMembership = CommonConstants.GET_SORT_OF_MEMBERSHIP;
    super.OnLoad();

    if(this.signUp) {
      var parameterVal = "signUp-" + this.membershipPackageId + "-" + "";
      this.getFacilities(parameterVal);
    } else {
      if(this.membershipPackageId == undefined) {
        const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
        if(getContainer != null) {
            let container = <MemberContainer>JSON.parse(getContainer);
            let member = <DataResult<Member>>JSON.parse(this.commonSvc.D_FP_AES256(container.Member));
            if(member.Data.Memberships.length >= 1) {
              this.membershipPackageId = (<DataResult<Member>>JSON.parse(this.commonSvc.D_FP_AES256(container.Member))).Data.Memberships[0].MembershipPackageId;
              var parameterVal = "signUp-" + this.membershipPackageId + "-" + "";
              this.getFacilities(parameterVal);
            } else {
              this.Invoke(this.membershipSVC.getMembershipPackageTypeByMember(member.Data.MemberId), {
                onSuccess: (result: string) => {
                  const dectypted_data = this.commonSvc.D_FP_ResponseData(result);
                  const rdata: DataResult<MembershipPackageTypeModel> = JSON.parse(
                    dectypted_data
                  );
                  this.membershipPackageId = rdata.Data.MembershipPackageId[0];
                  var parameterVal = "signUp-" + this.membershipPackageId + "-" + "";
                  this.getFacilities(parameterVal);
                },
              });
          }
        } else {
          let memID: number = +this.commSvc.D_FP_AES256ForShortString(localStorage.getItem("memberId"));
          this.Invoke(this.membershipSVC.getMembershipPackageTypeByMember(memID), {
            onSuccess: (result: string) => {
              const dectypted_data = this.commonSvc.D_FP_ResponseData(result);
              const rdata: DataResult<MembershipPackageTypeModel> = JSON.parse(
                dectypted_data
              );
              this.membershipPackageId = rdata.Data.MembershipPackageId[0];
              var parameterVal = "signUp-" + this.membershipPackageId + "-" + "";
              this.getFacilities(parameterVal);
            },
          });
        }
      } else {
        this.Invoke(this.membershipSVC.getMembershipPackageTypeByMember(this.data.model.MemberId), {
          onSuccess: (result: string) => {
            const dectypted_data = this.commonSvc.D_FP_ResponseData(result);
            const rdata: DataResult<MembershipPackageTypeModel> = JSON.parse(
              dectypted_data
            );
            this.membershipPackageId = rdata.Data.MembershipPackageId[0];
            var parameterVal = "signUp-" + this.membershipPackageId + "-" + "";
            this.getFacilities(parameterVal);
          },
        });
      }
    }

    this.initValidation();
  }

  private checkIsUnder18Dependent(memberTypeId : number, dateOfBirth : string | Date) {
    const dob = <Date>dateOfBirth;
    if(dob && memberTypeId){
      return dob && memberTypeId && Utils.calculateAge(dob) < 18 && memberTypeId === enums.EMemberType.Dependant;
    }
    return false;
}

  public isSignup() {
    return this.commonSvc.App.isMemberSignup;
  }

  getFacilities(parameterVal: string) {
    this.favFacilityIsLoading = true;
    const searchRequest: SearchPaginationRequest = {
        OrderBy: "Name",
        OrderByAsc: true,
        PageNumber: 0,
        PageSize: 0,
        Params: [
          {
            Name: "preferred_facility",
            Value: parameterVal,
          },
        ],
        ViewColumns: ["name"],
      };
      this.facilitySvc.search(searchRequest).subscribe(
        (result: string) => {
          const dectypted_data = this.commonSvc.D_FP_ResponseData(result);
          const rdata = JSON.parse(dectypted_data);
          this.favFacilityIsLoading = false;
          if (rdata.Success) {
            if (rdata.Data != null) {
              this.facilityList = rdata.Data.Results;
            }
          } else {
            MessageBox.ShowError(
              this.dialog,
              "Sorry, something went wrong. Let's try that again."
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                window.location.reload();
              }
            });
            this._logger.error(rdata);
          }
        },
        (error) => {
          this.favFacilityIsLoading = false;
          MessageBox.ShowError(
            this.dialog,
            "Sorry, something went wrong. Let's try that again."
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              window.location.reload();
            }
          });
          this._logger.error(error);
        }
      );
  }

  LoadComplete() {
    super.LoadComplete();

    setTimeout(() => {

      this.FavouredFacilityCtrl.valueChanges
        .pipe(
          debounceTime(500),
          switchMap((value) => {
            this.form
              .get("FavouredFacilityOther")
              .updateValueAndValidity({ emitEvent: false });
            if (typeof value === "string") {
              const searchText = value;
              if (searchText.length > 2) {
                this.favFacilityIsLoading = true;
                return value;
              } else {
                this.favFacilityIsLoading = false;
              }
            }
            return empty();
          }),
          catchError((e) => {
            throw e;
          })
        )
        .subscribe(
          (result: string) => {
            this.favFacilityIsLoading = false;
            let filterList = this.facilityList.filter(x => x.Name.toLowerCase().trim().includes(this.FavouredFacilityCtrl.value.toLowerCase().trim()));
            this.favFacilitySearch = filterList;
            this.changeDetectorRef.detectChanges();
          }
        );

      this.form.get("FavouredFacilityOther").valueChanges.subscribe((value) => {
        this.FavouredFacilityCtrl.updateValueAndValidity({ emitEvent: false });
      });

      this.ExistingFacilityCtrl.valueChanges
        .pipe(
          debounceTime(500),
          switchMap((value) => {
            this.form
              .get("ExistingFacilityOther")
              .updateValueAndValidity({ emitEvent: false });
            if (typeof value === "string") {
              const searchText = value;
              if (searchText.length > 2) {
                this.existFacilityIsLoading = true;
                return value;
              } else {
                this.existFacilityIsLoading = false;
              }
            }
            return empty();
          }),
          catchError((e) => {
            throw e;
          })
        )
        .subscribe(
          (result: string) => {
            this.existFacilityIsLoading = false;
            let filterList = this.facilityList.filter(x => x.Name.toLowerCase().trim().includes(this.ExistingFacilityCtrl.value.toLowerCase().trim()));
            this.existFacilitySearch = filterList;
            this.changeDetectorRef.detectChanges();
          }
        );

      // this.ExistingFacilityCtrl.valueChanges
      //   .pipe(
      //     debounceTime(500),
      //     switchMap((value) => {
      //       this.form
      //         .get("ExistingFacilityOther")
      //         .updateValueAndValidity({ emitEvent: false });
      //       if (typeof value === "string") {
      //         const searchText = value;
      //         var parameterVal = "";
      //         if (this.signUp) {
      //           parameterVal =
      //             "signUp-" + this.membershipPackageId + "-" + searchText;
      //         } else {
      //           parameterVal = "notSignUp-" + this.userId + "-" + searchText;
      //         }
      //         if (searchText.length > 2) {
      //           this.existFacilityIsLoading = true;
      //           const searchRequest: SearchPaginationRequest = {
      //             OrderBy: "Name",
      //             OrderByAsc: true,
      //             PageNumber: 0,
      //             PageSize: 0,
      //             Params: [
      //               {
      //                 Name: "preferred_facility",
      //                 Value: parameterVal,
      //               },
      //             ],
      //             ViewColumns: ["name"],
      //           };
      //           return this.facilitySvc.search(searchRequest);
      //         } else {
      //           this.existFacilityIsLoading = false;
      //         }
      //       }
      //       return empty();
      //     }),
      //     catchError((e) => {
      //       throw e;
      //     })
      //   )
      //   .subscribe(
      //     (result: string) => {
      //       const dectypted_data = this.commSvc.D_FP_ResponseData(result);
      //       const rdata = JSON.parse(dectypted_data);
      //       this.existFacilityIsLoading = false;
      //       if (rdata.Success) {
      //         if (rdata.Data != null) {
      //           this.existFacilitySearch = rdata.Data.Results;
      //           this.changeDetectorRef.detectChanges();
      //         }
      //       } else {
      //         MessageBox.ShowError(
      //           this.dialog,
      //           "Sorry, something went wrong. Let's try that again."
      //         ).subscribe((res) => {
      //           if (res.result.toLowerCase() === DialogResult.Ok) {
      //             window.location.reload();
      //           }
      //         });
      //         this._logger.error(rdata);
      //       }
      //     },
      //     (error) => {
      //       this.existFacilityIsLoading = false;
      //       MessageBox.ShowError(
      //         this.dialog,
      //         "Sorry, something went wrong. Let's try that again."
      //       ).subscribe((res) => {
      //         if (res.result.toLowerCase() === DialogResult.Ok) {
      //           window.location.reload();
      //         }
      //       });
      //       this._logger.error(error);
      //     }
      //   );
      //
      this.form.get("ExistingFacilityOther").valueChanges.subscribe((value) => {
        this.ExistingFacilityCtrl.updateValueAndValidity({ emitEvent: false });
      });
      //
      this.form
        .get("IsExistingMember")
        .valueChanges.subscribe((value: boolean) => {
          if (value) {
            this.form.get("ExistingFacility").enable({ emitEvent: false });
            this.form.get("ExistingFacilityOther").enable({ emitEvent: false });
          } else {
            this.form.get("ExistingFacility").disable({ emitEvent: false });
            this.form
              .get("ExistingFacility")
              .setValue(null, { emitEvent: false });
            this.form
              .get("ExistingFacilityOther")
              .disable({ emitEvent: false });
            this.form
              .get("ExistingFacilityOther")
              .setValue(null, { emitEvent: false });
          }
        });
    });
  }
  //--------------------------

  displayAutoText(facility: Facility) {
    if (!facility) {
      return "";
    }
    return facility.Name || facility.Code;
  }

  SelectedFavFacility(facility: Facility) {
    this.favFacilitySelected = facility;
    this.form
      .get("FavouredFacilityId")
      .setValue(facility ? facility.FacilityId : null);
    this.form.get("FavouredFacility").setValue(facility, { emitEvent: false });
    // Clear current search results
    this.favFacilitySearch = [];
  }

  SelectedExistingFacility(facility: Facility) {
    this.existFacilitySelected = facility;
    this.form
      .get("ExistingMemberFacilityId")
      .setValue(facility ? facility.FacilityId : null);
    this.form.get("ExistingFacility").setValue(facility, { emitEvent: false });
    // Clear current search results
    this.existFacilitySearch = [];
  }

  public PatchValue(value, options) {
    super.PatchValue(value, options);
    //init after PatchValue
    this.initValidation();
  }

  initValidation() {
    //init
    this.form
      .get("FavouredFacilityOther")
      .setValidators(
        CustomFacilityValidators.FavouredFacilityOtherRequiredWhen
      );
    this.form
      .get("FavouredFacilityOther")
      .updateValueAndValidity({ emitEvent: false });

    this.SetPrefferedFacilityValidator(this.data.model.MemberTypeId, this.data.model.DateOfBirth);

    if (this.form.get("IsExistingMember").value == true) {
      this.form.get("ExistingFacility").enable({ emitEvent: false });
      this.form.get("ExistingFacilityOther").enable({ emitEvent: false });
    }

    this.ExistingFacilityCtrl.setValidators([
      FPValidators.resolvableAutocompleteOption,
      CustomFacilityValidators.ExistingFacilityRequiredWhen,
    ]);
    this.ExistingFacilityCtrl.updateValueAndValidity({ emitEvent: false });
    this.form
      .get("ExistingFacilityOther")
      .setValidators(
        CustomFacilityValidators.ExistingFacilityOtherRequiredWhen
      );
    this.form
      .get("ExistingFacilityOther")
      .updateValueAndValidity({ emitEvent: false });
  }

  public SetPrefferedFacilityValidator(memberTypeId : number, dateOfBirth : string | Date){

    if(dateOfBirth && memberTypeId){
      var isUnder18Dependent = this.checkIsUnder18Dependent(memberTypeId, dateOfBirth);

      if(isUnder18Dependent){
        this.FavouredFacilityCtrl.setValidators(null);
        this.IsPrefferedFacilityMandotory = false;
      }
      else{
        this.FavouredFacilityCtrl.setValidators(Validators.required);
        this.IsPrefferedFacilityMandotory = true;
      }
    }
    else{
      this.FavouredFacilityCtrl.setValidators(null);
      this.IsPrefferedFacilityMandotory = false;
    }

    this.FavouredFacilityCtrl.updateValueAndValidity({ emitEvent: false });
  }

  txtFavouredFacility_Input(e) {
    if (this.FavouredFacilityCtrl.dirty) {
      if (
        typeof this.FavouredFacilityCtrl.value === "string" &&
        this.FavouredFacilityCtrl.value.length == 0 &&
        this.favFacilitySelected != null
      ) {
        this.SelectedFavFacility(null);
      }
    }
  }

  txtExistingFacility_Blur(e) {
    if (this.ExistingFacilityCtrl.dirty) {
      this.ExistingFacilityCtrl.setValidators([
        FPValidators.resolvableAutocompleteOption,
        CustomFacilityValidators.ExistingFacilityRequiredWhen,
      ]);
      this.ExistingFacilityCtrl.updateValueAndValidity({ emitEvent: false });
    }
    this.parentFocus = 0;
  }

  txtExistingFacility_Input(e) {
    if (this.ExistingFacilityCtrl.dirty) {
      if (
        typeof this.ExistingFacilityCtrl.value === "string" &&
        this.ExistingFacilityCtrl.value.length == 0 &&
        this.existFacilitySelected != null
      ) {
        this.SelectedExistingFacility(null);
      }
      this.ExistingFacilityCtrl.setValidators(
        CustomFacilityValidators.ExistingFacilityRequiredWhen
      );
    }
  }

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }

  getClassFocused(vlFocused) {
    if (this.parentFocus == vlFocused) {
      return "focused";
    } else {
      return "";
    }
  }
}

abstract class CustomFacilityValidators {

  static FavouredFacilityOtherRequiredWhen = FPValidators.requiredWhen(
    (ctrl) => {
      if (ctrl.parent) {
        const relatedCtrl = ctrl.parent.get("FavouredFacility");
        if (relatedCtrl && relatedCtrl.value == null) {
          return false;
        }
      }
      return false;
    }
  );

  static ExistingFacilityRequiredWhen = FPValidators.requiredWhen((ctrl) => {
    if (ctrl.parent) {
      const isExistingMemberCtrl = ctrl.parent.get("IsExistingMember");
      const relatedCtrl = ctrl.parent.get("ExistingFacilityOther");
      if (
        relatedCtrl &&
        (relatedCtrl.value == null || relatedCtrl.value.trim().length == 0) &&
        isExistingMemberCtrl &&
        isExistingMemberCtrl.value
      ) {
        return true;
      }
    }
    return false;
  });

  static ExistingFacilityOtherRequiredWhen = FPValidators.requiredWhen(
    (ctrl) => {
      if (ctrl.parent) {
        const isExistingMemberCtrl = ctrl.parent.get("IsExistingMember");
        const relatedCtrl = ctrl.parent.get("ExistingFacility");
        if (
          relatedCtrl &&
          relatedCtrl.value == null &&
          isExistingMemberCtrl &&
          isExistingMemberCtrl.value
        ) {
          return true;
        }
      }
      return false;
    }
  );

  static WhatTypeOfMembershipOtherRequiredWhen = FPValidators.requiredWhen(
    (ctrl) => {
      if (ctrl.parent) {
        return ctrl.parent.get("SortOfMembershipId").value === 4
      }
      return false;
    }
  );

}
