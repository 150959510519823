<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">View/Edit Facilities</h4>
<div class="mt-lg-4">
    <form>
        <div class="my-4">
            <div class="border-default rounded-10 border-2">
                <div class="tablecontainer">
                    <table mat-table matSort [dataSource]="dataSource" class="rounded-10"
                        (matSortChange)="sortChange($event)" matSort matSortActive="name" matSortDirection="desc"
                        matSortStart="desc" matSortDisableClear>
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
                            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                        </ng-container>

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Facility</th>
                            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="serviceType_name">
                            <th mat-header-cell *matHeaderCellDef>Service Type</th>

                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.serviceTypes.length == 1; else multipleRegion">
                                    {{ element.serviceTypes[0].name }}
                                </span>
                                <ng-template #multipleRegion>
                                    <span [ngbPopover]="popContent" placement="top" triggers="mouseenter:mouseleave"
                                        #popover="ngbPopover">
                                        {{ element.serviceTypes.length > 0 ? element.serviceTypes[0].name : '' }}{{
                                        element.serviceTypes.length > 1 ? ", ..." : "" }}
                                    </span>
                                    <ng-template #popContent>
                                        <ul class="pl-4">
                                            <li *ngFor="let r of element.serviceTypes">{{ r.name ? r.name : "" }}</li>
                                        </ul>
                                    </ng-template>
                                </ng-template>
                            </td>

                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>Status</th>
                            <td mat-cell *matCellDef="let element" [ngClass]="getStatus(element.status)">
                                {{element.status}} </td>
                        </ng-container>

                        <ng-container matColumnDef="region_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Region</th>
                            <td mat-cell *matCellDef="let element">{{ element.region != null ? element.region.name : ""
                                }}</td>
                        </ng-container>

                        <ng-container matColumnDef="suburb_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Suburb</th>
                            <td mat-cell *matCellDef="let element"> {{ element.suburb != null ? element.suburb.name :
                                "" }} </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element" style="width: 100px;">
                                <a class="px-2" [routerLink]="[routerFacilityEdit, element.id]">
                                    <i class="fas fa-pen"></i>
                                </a>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>

                <mat-toolbar [hidden]="dataSource == null" class="bg-white">
                    <mat-toolbar-row [hidden]="dataSource != null && dataSource.data.length == 0" class="p-0">
                        <mat-paginator class="w-100" [length]="length" [pageSize]="pageSize"
                            [pageSizeOptions]="pageSizeOptions" (page)="paginatorChange($event)" showFirstLastButtons>
                        </mat-paginator>
                    </mat-toolbar-row>
                    <mat-toolbar-row *ngIf="dataSource != null && dataSource.data.length == 0"
                        class="text-muted d-block p-2 bg-light text-center">
                        {{commonString.TABLE_NO_RECORD_FOUND}}
                    </mat-toolbar-row>
                </mat-toolbar>

            </div>
        </div>
        <button class="px-5 py-2 btn bg-white border-primary text-primary" type="button" (click)="goBack()"><i
                class="fas fa-arrow-alt-circle-left mr-2"></i>BACK</button>
    </form>
</div>