<div class="member-registration-thank-you align-items-center heightContent100">
  <div class="member-registration-thank-you-info">
    <div class="list-group">
      <div class="list-group-item">
        <h4 class="my-0">Registration almost complete!</h4>
      </div>

      <div class="list-group-item">
        <div class="d-flex align-items-start">
          <div style="width: 24px; height: 24px; margin-right: 16px">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_17_5605"
                style="mask-type: alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="25"
              >
                <rect y="0.399902" width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_17_5605)">
                <path
                  d="M12 22.3999C9.68333 21.8166 7.77083 20.4874 6.2625 18.4124C4.75417 16.3374 4 14.0332 4 11.4999V5.3999L12 2.3999L20 5.3999V11.4999C20 14.0332 19.2458 16.3374 17.7375 18.4124C16.2292 20.4874 14.3167 21.8166 12 22.3999ZM12 20.2999C13.7333 19.7499 15.1667 18.6499 16.3 16.9999C17.4333 15.3499 18 13.5166 18 11.4999V6.7749L12 4.5249L6 6.7749V11.4999C6 13.5166 6.56667 15.3499 7.7 16.9999C8.83333 18.6499 10.2667 19.7499 12 20.2999ZM10 16.3999H14C14.2833 16.3999 14.5208 16.3041 14.7125 16.1124C14.9042 15.9207 15 15.6832 15 15.3999V12.3999C15 12.1166 14.9042 11.8791 14.7125 11.6874C14.5208 11.4957 14.2833 11.3999 14 11.3999V10.3999C14 9.8499 13.8042 9.37907 13.4125 8.9874C13.0208 8.59574 12.55 8.3999 12 8.3999C11.45 8.3999 10.9792 8.59574 10.5875 8.9874C10.1958 9.37907 10 9.8499 10 10.3999V11.3999C9.71667 11.3999 9.47917 11.4957 9.2875 11.6874C9.09583 11.8791 9 12.1166 9 12.3999V15.3999C9 15.6832 9.09583 15.9207 9.2875 16.1124C9.47917 16.3041 9.71667 16.3999 10 16.3999ZM11 11.3999V10.3999C11 10.1166 11.0958 9.87907 11.2875 9.6874C11.4792 9.49574 11.7167 9.3999 12 9.3999C12.2833 9.3999 12.5208 9.49574 12.7125 9.6874C12.9042 9.87907 13 10.1166 13 10.3999V11.3999H11Z"
                  fill="#0079C9"
                />
              </g>
            </svg>
          </div>
          <p class="mb-0" style="max-width: 544px">
            For seamless and secure access to facilities, if you haven’t
            already, we encourage you to setup your preferred method to receive
            your two factor authentication codes.
          </p>
        </div>
      </div>

      <div class="list-group-item">
        <button
          class="btn change-method-button"
          (click)="NavigateToSecurityPreferences()"
        >
          Setup multi-factor authentication method
        </button>
      </div>

      <div class="list-group-item">
        <h4 class="my-0">Next Steps</h4>
      </div>

      <div class="list-group-item">
        <span style="margin-right: 16px">
          <svg
            xmlns=" http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <mask
              id="mask0_17_5612"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="25"
            >
              <rect y="0.399902" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_17_5612)">
              <path
                d="M8 20.3999H16V17.3999C16 16.2999 15.6083 15.3582 14.825 14.5749C14.0417 13.7916 13.1 13.3999 12 13.3999C10.9 13.3999 9.95833 13.7916 9.175 14.5749C8.39167 15.3582 8 16.2999 8 17.3999V20.3999ZM4 22.3999V20.3999H6V17.3999C6 16.3832 6.2375 15.4291 6.7125 14.5374C7.1875 13.6457 7.85 12.9332 8.7 12.3999C7.85 11.8666 7.1875 11.1541 6.7125 10.2624C6.2375 9.37074 6 8.41657 6 7.3999V4.3999H4V2.3999H20V4.3999H18V7.3999C18 8.41657 17.7625 9.37074 17.2875 10.2624C16.8125 11.1541 16.15 11.8666 15.3 12.3999C16.15 12.9332 16.8125 13.6457 17.2875 14.5374C17.7625 15.4291 18 16.3832 18 17.3999V20.3999H20V22.3999H4Z"
                fill="#6C757D"
              />
            </g>
          </svg>
        </span>
        <span>Your Employer reviews and approves your application</span>
      </div>
      <div class="list-group-item">
        <span style="margin-right: 16px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <mask
              id="mask0_17_5617"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="25"
            >
              <rect y="0.399902" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_17_5617)">
              <path
                d="M12 22.3999C11.45 22.3999 10.9792 22.2041 10.5875 21.8124C10.1958 21.4207 10 20.9499 10 20.3999H14C14 20.9499 13.8042 21.4207 13.4125 21.8124C13.0208 22.2041 12.55 22.3999 12 22.3999ZM4 19.3999V17.3999H6V10.3999C6 9.01657 6.41667 7.7874 7.25 6.7124C8.08333 5.6374 9.16667 4.93324 10.5 4.5999V3.8999C10.5 3.48324 10.6458 3.12907 10.9375 2.8374C11.2292 2.54574 11.5833 2.3999 12 2.3999C12.4167 2.3999 12.7708 2.54574 13.0625 2.8374C13.3542 3.12907 13.5 3.48324 13.5 3.8999V4.2249C13.3167 4.59157 13.1833 4.96657 13.1 5.3499C13.0167 5.73324 12.9833 6.1249 13 6.5249C12.8333 6.49157 12.6708 6.4624 12.5125 6.4374C12.3542 6.4124 12.1833 6.3999 12 6.3999C10.9 6.3999 9.95833 6.79157 9.175 7.5749C8.39167 8.35824 8 9.2999 8 10.3999V17.3999H16V10.9749C16.3 11.1082 16.6208 11.2124 16.9625 11.2874C17.3042 11.3624 17.65 11.3999 18 11.3999V17.3999H20V19.3999H4ZM18 9.3999C17.1667 9.3999 16.4583 9.10824 15.875 8.5249C15.2917 7.94157 15 7.23324 15 6.3999C15 5.56657 15.2917 4.85824 15.875 4.2749C16.4583 3.69157 17.1667 3.3999 18 3.3999C18.8333 3.3999 19.5417 3.69157 20.125 4.2749C20.7083 4.85824 21 5.56657 21 6.3999C21 7.23324 20.7083 7.94157 20.125 8.5249C19.5417 9.10824 18.8333 9.3999 18 9.3999Z"
                fill="#565757"
              />
            </g>
          </svg>
        </span>
        <span
          >You get notified by email and SMS when your membership is
          approved</span
        >
      </div>
      <div class="list-group-item">
        <span style="margin-right: 16px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <mask
              id="mask0_17_5622"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="25"
            >
              <rect y="0.399902" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_17_5622)">
              <path
                d="M16 11.3999L12 15.3999L8 11.3999L9.4 9.9999L11 11.5499V7.3999H13V11.5499L14.6 9.9999L16 11.3999ZM10 20.3999H14V19.3999H10V20.3999ZM7 23.3999C6.45 23.3999 5.97917 23.2041 5.5875 22.8124C5.19583 22.4207 5 21.9499 5 21.3999V3.3999C5 2.8499 5.19583 2.37907 5.5875 1.9874C5.97917 1.59574 6.45 1.3999 7 1.3999H17C17.55 1.3999 18.0208 1.59574 18.4125 1.9874C18.8042 2.37907 19 2.8499 19 3.3999V21.3999C19 21.9499 18.8042 22.4207 18.4125 22.8124C18.0208 23.2041 17.55 23.3999 17 23.3999H7ZM7 18.3999V21.3999H17V18.3999H7ZM7 16.3999H17V6.3999H7V16.3999ZM7 4.3999H17V3.3999H7V4.3999Z"
                fill="#565757"
              />
            </g>
          </svg>
        </span>
        <span
          >Download the MyFP app from Apple’s App Store or Google’s Play store
          on your mobile device</span
        >
      </div>
      <div class="list-group-item">
        <span style="margin-right: 16px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <mask
              id="mask0_17_5627"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="25"
            >
              <rect y="0.399902" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_17_5627)">
              <path
                d="M3 20.3999V17.5999C3 17.0332 3.14583 16.5124 3.4375 16.0374C3.72917 15.5624 4.11667 15.1999 4.6 14.9499C5.63333 14.4332 6.68333 14.0457 7.75 13.7874C8.81667 13.5291 9.9 13.3999 11 13.3999C11.3333 13.3999 11.6667 13.4124 12 13.4374C12.3333 13.4624 12.6667 13.4999 13 13.5499C12.9333 14.5166 13.1083 15.4291 13.525 16.2874C13.9417 17.1457 14.55 17.8499 15.35 18.3999V20.3999H3ZM19 23.3999L17.5 21.8999V17.2499C16.7667 17.0332 16.1667 16.6207 15.7 16.0124C15.2333 15.4041 15 14.6999 15 13.8999C15 12.9332 15.3417 12.1082 16.025 11.4249C16.7083 10.7416 17.5333 10.3999 18.5 10.3999C19.4667 10.3999 20.2917 10.7416 20.975 11.4249C21.6583 12.1082 22 12.9332 22 13.8999C22 14.6499 21.7875 15.3166 21.3625 15.8999C20.9375 16.4832 20.4 16.8999 19.75 17.1499L21 18.3999L19.5 19.8999L21 21.3999L19 23.3999ZM11 12.3999C9.9 12.3999 8.95833 12.0082 8.175 11.2249C7.39167 10.4416 7 9.4999 7 8.3999C7 7.2999 7.39167 6.35824 8.175 5.5749C8.95833 4.79157 9.9 4.3999 11 4.3999C12.1 4.3999 13.0417 4.79157 13.825 5.5749C14.6083 6.35824 15 7.2999 15 8.3999C15 9.4999 14.6083 10.4416 13.825 11.2249C13.0417 12.0082 12.1 12.3999 11 12.3999ZM18.5 14.3999C18.7833 14.3999 19.0208 14.3041 19.2125 14.1124C19.4042 13.9207 19.5 13.6832 19.5 13.3999C19.5 13.1166 19.4042 12.8791 19.2125 12.6874C19.0208 12.4957 18.7833 12.3999 18.5 12.3999C18.2167 12.3999 17.9792 12.4957 17.7875 12.6874C17.5958 12.8791 17.5 13.1166 17.5 13.3999C17.5 13.6832 17.5958 13.9207 17.7875 14.1124C17.9792 14.3041 18.2167 14.3999 18.5 14.3999Z"
                fill="#565757"
              />
            </g>
          </svg>
        </span>
        <span
          >Login and access hundreds of facilities to unlock endless fitness
          possibilities.</span
        >
      </div>
    </div>
  </div>
</div>
