import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConstant } from 'src/app/constant';
import * as Models from 'src/app/models';
import { HttpDAO } from './httpdao.service';
import { map } from 'rxjs/operators';
import { CommonDataType } from 'src/app/enums';

@Injectable({
    providedIn: 'root'
})
export class CommonDataService {
    private httpDao: HttpDAO;
    constructor(client: HttpClient) {
        this.httpDao = new HttpDAO(client);
    }

    getData(type: CommonDataType): Observable<Models.DataResult<Models.CommonData[]>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_COMMON_DATA + type);
    }

    getTokenSiteScanner(): Observable<Models.DataResult<string>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_TOKEN_SITE_SCANNER);
    }

    getGenders(): Observable<Models.DataResult<Models.Gender[]>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_GENDERS).pipe(map(res => {
            const dataResult: Models.DataResult<Models.Gender[]> = {
                Success: false,
                Message: null,
                Errors: [],
                Data: [],
                Name: null,
                WaitInSeconds: 0
            };
            if (res && res instanceof Array) {
                dataResult.Data = (<Array<Models.CommonResponse>>res)
                    .map((val, i) => <Models.Gender>{ GenderId: val.Id, Name: val.Name, DisplayOrder: i + 1 });
                dataResult.Success = true;
            } else {
                dataResult.Message = 'Unknown error';
            }
            return dataResult;
        }));
    }

    getLatestTermAndCondition(id: string): Observable<Models.DataResult<Models.TermsAndConditions>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_LATEST_TERMS_AND_CONDITIONS + id);
    }

    getAllTermsAndConditions(): Observable<Models.DataResult<Models.TermsAndConditions>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_ALL_TERMS_AND_CONDITIONS);
    }

    /**
     * Get all countries. - CURRENTLY NOT IN USE
     * @returns {Observable<Models.DataResult<Models.Country[]>>}
     */
    // getCountries(): Observable<Models.DataResult<Models.Country[]>> {
    //     return this.httpDao.getSingleData(APIConstant.API_GET_COUNTRIES);
    // }

    /**
     * Get all states. - CURRENTLY NOT IN USE
     * @returns {Observable<Models.DataResult<Models.State[]>>}
     */
    // getStates(): Observable<Models.DataResult<Models.State[]>> {
    //     return this.httpDao.getSingleData(APIConstant.API_GET_STATES);
    // }

    // getStatesByCountryId(countryId: number): Observable<Models.DataResult<Models.State[]>> {
    //     return this.httpDao.getSingleData(APIConstant.API_GET_STATES_BY_COUNTRYID + countryId);
    // }

    getPostcodeAndStateBySuburbName(name: string): Observable<Models.DataResult<Models.SuburbPostcodeStateModel[]>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_POSTCODE_AND_STATE_BY_SUBURBNAME + encodeURIComponent(name))
            .pipe(map((value: Models.SuburbPostcodeStateModel) => {
                const result: Models.DataResult<Models.SuburbPostcodeStateModel[]> = {
                    Success: false,
                    Errors: [],
                    Message: null,
                    Name: null,
                    WaitInSeconds: 0,
                    Data: []
                };
                if (value instanceof Array) {
                    result.Success = true;
                    result.Data = value;
                } else {
                    result.Message = 'Unknown error';
                }
                return result;
            }));
    }

    findSuburbByNameDistinct(name: string): Observable<Models.DataResult<Models.Suburb[]>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_SUBURB_NAME_BY_NAME_DISTINCT + encodeURIComponent(name))
            .pipe(map((value: Models.CommonResponse[]) => {
                const result: Models.DataResult<Models.Suburb[]> = {
                    Success: false,
                    Errors: [],
                    Message: null,
                    Name: null,
                    WaitInSeconds: 0,
                    Data: []
                };
                if (value instanceof Array) {
                    result.Success = true;
                    value.forEach(e => {
                        const suburb = new Models.Suburb();
                        suburb.SuburbId = e.Id;
                        suburb.Name = e.Name;
                        result.Data.push(suburb);
                    });
                } else {
                    result.Message = 'Unknown error';
                }
                return result;
            }));
    }
}
