import { PositionTextModel, PositionRectModel } from "./report-object.model";
import { PdfReportingService } from "./pdfreporting.service";
import { DateHelper } from "@fp/helpers";
import { PaymentInvoiceModel } from "@fp/models/payment.invoice.model";

export class PdfPaymentInvoiceReport {

    private prsrv: PdfReportingService = new PdfReportingService();
    constructor() {

    }
    public TaxInvoicePos: PositionTextModel = {
        HorizonX: 10,
        VerticalY: 20,
        FontSize: 22,
        FontName: 'helvetica',
        Text: '',
        LimitWrapText: 40
    };
    public CurrentDate: PositionTextModel = {
        HorizonX: 250,
        VerticalY: 20,
        FontSize: 12,
        FontName: 'helvetica',
        Text: new Date().toString()
    };
    public InvoiceNumber: PositionTextModel = {
        HorizonX: 250,
        VerticalY: 25,
        FontSize: 12,
        FontName: 'helvetica',
        Text: ''
    };

    public Receiver: PositionTextModel = {
        HorizonX: 200,
        VerticalY: 40,
        FontSize: 12,
        FontName: 'helvetica',
        Text: 'Receiver:',
        FontStyle: 'bold'
    };

    public ReceiverUnderLine: PositionRectModel = {
        X: 200,
        Y: 40.5,
        Width: 20,
        Height: 0
    };

    public ReceiverName: PositionTextModel = {
        HorizonX: 200,
        VerticalY: this.Receiver.VerticalY + 5,
        FontSize: 12,
        FontName: 'helvetica',
        Text: 'Fitness Passport Pty Ltd'
    };

    public ReceiverCusID: PositionTextModel = {
        HorizonX: 200,
        VerticalY: this.ReceiverName.VerticalY + 5,
        FontSize: 12,
        FontName: 'helvetica',
        Text: 'PO 775, Customer ID HO627'
    };

    public ReceiverAddress: PositionTextModel = {
        HorizonX: 200,
        VerticalY: this.ReceiverCusID.VerticalY + 5,
        FontSize: 12,
        FontName: 'helvetica',
        Text: 'Cherrybrook NSW 2126'
    };

    public ReceiverEmail: PositionTextModel = {
        HorizonX: 200,
        VerticalY: this.ReceiverAddress.VerticalY + 5,
        FontSize: 12,
        FontName: 'helvetica',
        Text: 'info@fitnesspassport.com.au'
    };

    public ReceiverABN: PositionTextModel = {
        HorizonX: 200,
        VerticalY: this.ReceiverEmail.VerticalY + 5,
        FontSize: 12,
        FontName: 'helvetica',
        Text: 'ABN 12 345 678 910'
    };

    public Supplier: PositionTextModel = {
        HorizonX: 10,
        VerticalY: 40,
        FontSize: 12,
        FontName: 'helvetica',
        FontStyle: 'bold',
        Text: 'Supplier:'
    };

    public SupplierUnderLine: PositionRectModel = {
        X: 10,
        Y: 40.5,
        Width: 20,
        Height: 0
    };

    public FacilityNameDetail: PositionTextModel = {
        HorizonX: 10,
        VerticalY: this.Supplier.VerticalY + 5,
        FontSize: 12,
        FontName: 'helvetica',
        Text: ''
    };

    public CompanyNameDetail: PositionTextModel = {
        HorizonX: 10,
        VerticalY: this.FacilityNameDetail.VerticalY + 5,
        FontSize: 12,
        FontName: 'helvetica',
        Text: ''
    };
    public Street: PositionTextModel = {
        HorizonX: 10,
        VerticalY: this.CompanyNameDetail.VerticalY + 5,
        FontSize: 12,
        FontName: 'helvetica',
        Text: ''
    };
    public SuburbRegion: PositionTextModel = {
        HorizonX: 10,
        VerticalY: this.Street.VerticalY + 5,
        FontSize: 12,
        FontName: 'helvetica',
        Text: ''
    };
    public Email: PositionTextModel = {
        HorizonX: 10,
        VerticalY: this.SuburbRegion.VerticalY + 5,
        FontSize: 12,
        FontName: 'helvetica',
        Text: ''
    };
    public ABN: PositionTextModel = {
        HorizonX: 10,
        VerticalY: this.Email.VerticalY + 5,
        FontSize: 12,
        FontName: 'helvetica',
        Text: ''
    };

    public BoxTableParent: PositionRectModel = {
        X: 10,
        Y: this.ABN.VerticalY + 10,
        Width: 270,
        Height: 110
    };

    public BoxHeader: PositionRectModel = {
        X: this.BoxTableParent.X,
        Y: this.BoxTableParent.Y,
        Width: this.BoxTableParent.Width,
        Height: 8
    };

    public DescriptionHeader: PositionTextModel = {
        HorizonX: 15,
        VerticalY: this.BoxHeader.Y + 6,
        FontSize: 12,
        FontName: 'helvetica',
        FontStyle: 'bold',
        Text: 'Description'
    };

    public DateHeader: PositionTextModel = {
        HorizonX: 160,
        VerticalY: this.DescriptionHeader.VerticalY,
        FontSize: 12,
        FontName: 'helvetica',
        FontStyle: 'bold',
        Text: 'Transaction Date'
    };

    public TotalPaidHeader: PositionTextModel = {
        HorizonX: 240,
        VerticalY: this.DescriptionHeader.VerticalY,
        FontSize: 12,
        FontName: 'helvetica',
        FontStyle: 'bold',
        Text: 'Total Paid'
    };

    public ServiceName: PositionTextModel = {
        HorizonX: this.DescriptionHeader.HorizonX,
        VerticalY: this.DescriptionHeader.VerticalY + 8,
        FontSize: 12,
        FontName: 'helvetica',
        Text: '',
        LimitWrapText: 40
    };

    public DateTrans: PositionTextModel = {
        HorizonX: this.DateHeader.HorizonX,
        VerticalY: this.ServiceName.VerticalY,
        FontSize: 12,
        FontName: 'helvetica',
        Text: ''
    };

    public TotalPaid: PositionTextModel = {
        HorizonX: this.TotalPaidHeader.HorizonX,
        VerticalY: this.ServiceName.VerticalY,
        FontSize: 12,
        FontName: 'helvetica',
        Text: ''
    };

    public SumaryParentBox: PositionRectModel = {
        X: 159,
        Y: 165,
        Width: 121,
        Height: 25
    };

    public SumarySepareteBox: PositionRectModel = {
        X: 159,
        Y: this.SumaryParentBox.Y + 13,
        Width: 121,
        Height: 12
    };

    public TotalPriceGTSText: PositionTextModel = {
        HorizonX: this.SumaryParentBox.X + 2,
        VerticalY: this.SumaryParentBox.Y + 7,
        FontSize: 12,
        FontName: 'helvetica',
        Text: 'Total Price Including GST'
    };

    public TotalPriceGTSValue: PositionTextModel = {
        HorizonX: this.TotalPaidHeader.HorizonX,
        VerticalY: this.TotalPriceGTSText.VerticalY,
        FontSize: 12,
        FontName: 'helvetica',
        Text: ''
    };

    public GTSText: PositionTextModel = {
        HorizonX: this.TotalPriceGTSText.HorizonX,
        VerticalY: this.SumarySepareteBox.Y + 7,
        FontSize: 12,
        FontName: 'helvetica',
        Text: 'GST'
    };

    public GTSTextValue: PositionTextModel = {
        HorizonX: this.TotalPaidHeader.HorizonX,
        VerticalY: this.GTSText.VerticalY,
        FontSize: 12,
        FontName: 'helvetica',
        Text: ''
    };

    private ExportPdfReport(elem: PaymentInvoiceModel) {
        this.prsrv = new PdfReportingService();
        this.PaintHeaderDetail(elem);
        this.PaintDetailInvoice(elem);
        this.PaintSummary(elem);
    }

    public SaveReportToPDFFile(elem: PaymentInvoiceModel) {
        this.ExportPdfReport(elem);
        this.prsrv.SavePdfToFile(elem.InvoiceNumber + '.pdf');
    }

    public ExportReportToBlob(elem: PaymentInvoiceModel): Blob {
        this.ExportPdfReport(elem);
        return this.prsrv.ExportBlob();
    }

    private PaintHeaderDetail(elem: PaymentInvoiceModel) {
        this.TaxInvoicePos.Text = 'Tax Invoice';
        this.prsrv.PaintText(this.TaxInvoicePos, true);

        this.CurrentDate.Text = DateHelper.format(new Date(), 'DD-MMM-YYYY');
        this.prsrv.PaintText(this.CurrentDate);

        const dtnow = DateHelper.format(new Date(), 'DDMMYY');
        this.InvoiceNumber.Text = elem.FacilityInfo.Code + dtnow + elem.PaymentRequestId.toString();
        this.prsrv.PaintText(this.InvoiceNumber);

        this.prsrv.PaintText(this.InvoiceNumber);

        this.prsrv.PaintText(this.Receiver);
        this.prsrv.PaintRect(this.ReceiverUnderLine);
        this.prsrv.PaintText(this.ReceiverName);
        this.prsrv.PaintText(this.ReceiverCusID);
        this.prsrv.PaintText(this.ReceiverAddress);
        this.prsrv.PaintText(this.ReceiverEmail);
        this.prsrv.PaintText(this.ReceiverABN);

        this.prsrv.PaintText(this.Supplier);
        this.prsrv.PaintRect(this.SupplierUnderLine);
        this.FacilityNameDetail.Text = elem.FacilityInfo.Name;
        this.prsrv.PaintText(this.FacilityNameDetail);

        this.CompanyNameDetail.Text = elem.FacilityInfo.CompanyName;
        this.prsrv.PaintText(this.CompanyNameDetail);

        this.Street.Text = elem.FacilityInfo.StreetAddress;
        this.prsrv.PaintText(this.Street);

        this.SuburbRegion.Text = elem.FacilityInfo.Suburb + ' ' + elem.FacilityInfo.State + ' ' + elem.FacilityInfo.PostCode;
        this.prsrv.PaintText(this.SuburbRegion);

        this.Email.Text = elem.FacilityInfo.Email || " ";
        this.prsrv.PaintText(this.Email);

        this.ABN.Text = 'ABN: ' + elem.FacilityInfo.ABN;
        this.prsrv.PaintText(this.ABN);
    }

    private PaintDetailInvoice(elem: PaymentInvoiceModel) {
        this.prsrv.PaintRect(this.BoxTableParent);
        this.prsrv.PaintRect(this.BoxHeader);

        this.prsrv.PaintText(this.DescriptionHeader);
        this.prsrv.PaintText(this.DateHeader);
        this.prsrv.PaintText(this.TotalPaidHeader);


        const srvname = elem.ServiceName + ', ' + elem.VisitCount + ' visits';
        this.ServiceName.Text = srvname;
        this.prsrv.PaintText(this.ServiceName, true);

        this.DateTrans.Text = elem.PaymentPeriodStartDisplay + ' - ' + elem.PaymentPeriodEndDisplay;
        this.prsrv.PaintText(this.DateTrans);

        this.TotalPaid.Text = '$' + elem.Amount.toString();
        this.prsrv.PaintText(this.TotalPaid);
    }

    private PaintSummary(elem: PaymentInvoiceModel) {
        this.prsrv.PaintRect(this.SumaryParentBox);
        this.prsrv.PaintRect(this.SumarySepareteBox);

        this.prsrv.PaintText(this.TotalPriceGTSText)
        this.TotalPriceGTSValue.Text = '$' + elem.Amount.toString();
        this.prsrv.PaintText(this.TotalPriceGTSValue);

        this.prsrv.PaintText(this.GTSText);
        this.GTSTextValue.Text = '$' + (elem.Amount / 11).toFixed(2).toString();
        this.prsrv.PaintText(this.GTSTextValue);
    }

}