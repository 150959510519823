import { startWith } from 'rxjs/operators';
import { Component, Injector, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ModelBasedFormSharedData, FPFormBaseComponent } from '@fp/components/base';
import { DialogResult } from '@fp/components/common-dialog/common-dialog.component';
import { MobileNumberComponent } from '@fp/components/control';
import { CommonConstants } from '@fp/constant/common-constants';
import { CommonString, PatternConstant } from '@fp/constants';
import { StringHelper, Utils } from '@fp/helpers';
import { CommonResponse, DataResult, EmployerBranch, EmployerDetailModel, RequestEmployerBranch, ResultModel, SearchExpressionLogicGroup, SearchFieldOperator, SearchPaginationCriteria, SearchPaginationResult, State, SuburbPostcodeStateModel, SuburbSearchResultRecord } from '@fp/models';
import { CommonDataService, CommonService, EmployerService, MessageBox, MessageBoxButton, SuburbService } from '@fp/services';
import { debounceTime } from 'rxjs/operators';

export class EmployerBranchFormSharedData extends ModelBasedFormSharedData<EmployerDetailModel> {
  additionalData: AdditionalData
  constructor() {
    super();
    this.additionalData = new AdditionalData();
  }
}

class AdditionalData {
  states: Array<CommonResponse>;
  countries: Array<CommonResponse>;
}

@Component({
  selector: 'app-employer-branchs',
  templateUrl: './branchs.component.html',
  styleUrls: ['./branchs.component.css'],
  providers: [FPFormBaseComponent.provideExisting(EmployerBranchsComponent)]
})
export class EmployerBranchsComponent extends FPFormBaseComponent<EmployerBranchFormSharedData> {
  @ViewChild('branchPhone') branchPhone: MobileNumberComponent<string>;
  @Input('form') branchForm: UntypedFormGroup;
  @Input() isReadonly: boolean;
  private suburbList: SuburbSearchResultRecord[];
  states: State[];
  allStates: State[];
  countries: CommonResponse[];


  commonString = CommonString;
  stringHelper = StringHelper;
  userName: string;
  branchCountryCode : string = '';
  nzStateId : number = 9;


  static getFormGroup() {
    const _formBuilder = new UntypedFormBuilder
    return _formBuilder.group({
      EmployerBranchId: [],
      EmployerId: [],
      BranchName: [, Validators.required],
      EligibleEmployerNo: [, Validators.required],
      AddressId: [],
      StreetAddress1: [],
      StreetAddress2: [],
      SuburbId: [],
      Suburb: [, [Validators.required, Utils.ValidateObject]],
      PostCode: [, Validators.required],
      PostCodeId: [],

      StateId: [, Validators.required],
      StateName: [],
      CountryId: [1, Validators.required],
      RegionName: [],

      ContactName: [],
      ContactPhoneNumber: [, Validators.pattern(PatternConstant.PATTERN_PHONE)],
      ContactEmailAddress: [, Validators.pattern(PatternConstant.EMAIL)],
      AdditionalNote: [],
      Status: []
    });
  }

  constructor(
    injector: Injector,
    private employerService: EmployerService,
    private suburbService: SuburbService,
    private commonService: CommonService,
    private commonDataService: CommonDataService,
  ) {
    super(injector);
    this.allStates = CommonConstants.GET_STATES.sort((a, b) => a.StateId - b.StateId);
    this.data = new EmployerBranchFormSharedData();
  }

  //#region branch/location
  displayColums_location = [
    'BranchName',
    'Suburb',
    'StateName',
    'EligibleEmployerNo',
    'ContactName',
    'ContactPhoneNumber',
    'ContactEmailAddress',
    'action'
  ];
  dataSource_branch: MatTableDataSource<any>;
  employerBranchs: EmployerBranch[];

  branchSuburbs: SuburbSearchResultRecord[] = [];
  branchPostcodes: SuburbPostcodeStateModel[] = [];
  suburbSpinner: boolean = false;
  removedBranchs: EmployerBranch[] = [];
  clickBranchSubmit: boolean = false;

  @ViewChild("mobileNumberComponent") mobileNumberComponent: MobileNumberComponent<any>;

  get branchNameCtrl() { return <UntypedFormControl>this.branchForm.get('BranchName') }
  get branchEmployeeNoCtrl() { return <UntypedFormControl>this.branchForm.get('EligibleEmployerNo') }
  get branchAddress1Ctrl() { return <UntypedFormControl>this.branchForm.get('StreetAddress1') }
  get branchSuburbCtrl() { return <UntypedFormControl>this.branchForm.get('Suburb') }
  get branchPostcodeCtrl() { return <UntypedFormControl>this.branchForm.get('PostCode') }
  get branchPostcodeIdCtrl() { return <UntypedFormControl>this.branchForm.get('PostCodeId') }
  get branchStateIdCtrl() { return <UntypedFormControl>this.branchForm.get('StateId') }
  get branchStateNameCtrl() { return <UntypedFormControl>this.branchForm.get('StateName') }
  get branchCountryIdCtrl() { return <UntypedFormControl>this.branchForm.get('CountryId') }
  get branchRegionNameCtrl() { return <UntypedFormControl>this.branchForm.get('RegionName') }
  get branchPhoneCtrl() { return <UntypedFormControl>this.branchForm.get('ContactPhoneNumber') }
  get branchContactNameCtrl() { return <UntypedFormControl>this.branchForm.get('ContactName') }
  get branchStatusCtrl() { return <UntypedFormControl>this.branchForm.get('Status') }
  get branchEmailCtrl() { return <UntypedFormControl>this.branchForm.get('ContactEmailAddress') }

  public displayAutoPostCodeText(obj?: SuburbPostcodeStateModel): string | undefined {
    return obj ? obj.Postcode : undefined;
  }
  public displayAutoSuburbText(obj?: SuburbSearchResultRecord): string | undefined {
    return obj ? obj.Name : undefined;
  }

  BranchControlValidate(control: UntypedFormControl, clickFlag: boolean = false) {
    let isBool: boolean = false;
    isBool = control.invalid && (control.touched || control.dirty || clickFlag);
    return isBool;
  }

  private CloseModalBranch(isSubmit: boolean = false) {
    let currentBranch = this.employerBranchs[this.currentEditingBranchIndex];
    if (this.currentEditingBranchIndex && !isSubmit && !currentBranch.Status) {
      currentBranch.Status = null;
      this.dataSource_branch = new MatTableDataSource<any>(this.employerBranchs);
    }

    this.branchSuburbs = [];
    document.getElementById('btnCloseModalBranch').click();
  }

  processBranchData(data: any[]) {
    if (data.length > 0) {
      data = data.map(b => {
        if (typeof b.Suburb === 'string') {
          b.Suburb = {
            Id: b.SuburbId,
            Name: b.Suburb
          };
        }
        if (typeof b.SuburbName === 'string') {
          b.Suburb = {
            Id: b.SuburbId,
            Name: b.SuburbName
          };
        }
        if (typeof b.ContactPhoneNumber === 'string') {

          if(b.ContactPhoneNumber.startsWith('+61')){
            b.ContactPhoneNumber = b.ContactPhoneNumber.replace('+61', '');
          }

          if(b.ContactPhoneNumber.startsWith('+64')){
            b.ContactPhoneNumber = b.ContactPhoneNumber.replace('+64', '');
          }
          
        }
        return b;
      })
    }
  }

  LoadLocations(callback?: Function) {
    this.states = CommonConstants.GET_STATES.sort((a, b) => a.StateId - b.StateId);
    this.nzStateId = this.allStates.find( x => x.Name.toLowerCase() == 'nz').StateId;
    this.countries = this.data.additionalData.countries;;
    this.commonService.getLocationData().subscribe(
      (res) => {
          console.log(res);
          if (res.Success) {
              this.suburbList = res.Data.suburbs;
          }
      }
    );
    const employerId: number = this.data.model.EmployerId;
    if (this.employerBranchs) {
      if (callback) {
        callback();
      }
      return;
    }
    if (!employerId) {
      this.employerBranchs = [];
      this.dataSource_branch = new MatTableDataSource<any>([]);
      if (callback) {
        callback();
      }
    } else {
      this.Invoke(
        this.employerService.getBranchs(employerId),
        {
          onSuccess: (result: DataResult<EmployerBranch[]>) => {
            this.processBranchData(result.Data);
            this.employerBranchs = Utils.sortASConName(result.Data, 'BranchName');
            this.dataSource_branch = new MatTableDataSource<any>(this.employerBranchs);
            if (callback) {
              callback();
            }
          }
        }
      );
    }

    setTimeout(() => {
      this.branchSuburbCtrl.valueChanges.pipe(
        debounceTime(500)
      ).subscribe(value => {
        // reset controls of the address
        this.branchPostcodeCtrl.reset();
        this.branchStateIdCtrl.reset();
        this.branchRegionNameCtrl.reset();
        this.branchPostcodes = [];

        if (typeof value === 'string') {
          this.branchSuburbs = [];
          if (value.trim().length > 2) {
            this.suburbSpinner = true;
            let filterSuburb = this.suburbList.filter(x => x.Name.toLowerCase().trim().includes(value.toLowerCase().trim()) || x.Postcode.Name.includes(value.toLowerCase().trim()));
            this.branchSuburbs = filterSuburb;
            this.suburbSpinner = false;

            // const criteria: SearchPaginationCriteria = {
            //   PageNumber: 0,
            //   PageSize: 0,
            //   OrderBy: 'name',
            //   OrderByAsc: true,
            //   Criteria: {
            //     LogicGroup: SearchExpressionLogicGroup.And,
            //     Fields: [
            //       {
            //         Name: 'name',
            //         Operator: SearchFieldOperator.Contains,
            //         Value: value
            //       }
            //     ],
            //     Expressions: []
            //   }
            // }

            // this.InvokeNotProcess(
            //   this.suburbService.advancedSearch(criteria),
            //   {
            //     onSuccess: (result: DataResult<SearchPaginationResult<SuburbSearchResultRecord>>) => {
            //       this.branchSuburbs = result.Data.Results;
            //       this.suburbSpinner = false;
            //     },
            //     onError: err => {
            //       this.suburbSpinner = false;
            //     }
            //   }
            // );
          }
        } else if (typeof value === 'object' && value) {
          this.employerBranchs = this.employerBranchs.map(b => {
            if (typeof b.Suburb == 'object') {
              b.SuburbId = value.Id;
            }
            return b;
          });

          this.Invoke(
            this.commonDataService.getPostcodeAndStateBySuburbName(value.Name),
            {
              onSuccess: (result: DataResult<SuburbPostcodeStateModel[]>) => {
                let postcodeStateCountryRegion;
                if (!value.Postcode) {
                  postcodeStateCountryRegion =
                    result.Data.filter(b => b.PostcodeId === this.branchPostcodeIdCtrl.value).pop();
                } else {
                  postcodeStateCountryRegion = result.Data.filter(b => b.PostcodeId === value.Postcode.Id).pop();
                }

                if (postcodeStateCountryRegion) {
                  this.branchPostcodes = [postcodeStateCountryRegion];
                  this.branchPostcodeCtrl.patchValue(postcodeStateCountryRegion);
                }
              }
            }
          );
        }
      });

      this.branchPostcodeCtrl.valueChanges.subscribe((value: SuburbPostcodeStateModel) => {
        if (value && typeof value == 'object') {
          this.branchStateIdCtrl.setValue(value.StateId, { emitEvent: false });
          this.branchCountryIdCtrl.setValue(value.CountryId, { emitEvent: false });
          this.setBranchMobileNoCountryCode(value.CountryId);
          this.branchStateNameCtrl.setValue(value.StateName, { emitEvent: false });
          if (value.RegionName == null) {
            const regionName: string = 'There is no region for the postcode ' + value.Postcode;
            this.branchRegionNameCtrl.setValue(regionName, { emitEvent: false });
          } else {
            this.branchRegionNameCtrl.setValue(value.RegionName, { emitEvent: false });
          }
        }
      });

      this.branchCountryIdCtrl.valueChanges.subscribe((countryId: number) => {
        if (countryId) {
          this.setBranchMobileNoCountryCode(countryId);
        }
      });
    });
  }

  private setBranchMobileNoCountryCode(countryId: number){
    if (countryId) {
      if(countryId == 1){
        this.mobileNumberComponent.setCountryCodeWithoutUserCountry("61");
        this.branchCountryCode = '+61';
        this.states = this.allStates.filter( s => s.StateId != this.nzStateId).sort((a, b) => a.StateId - b.StateId);
      }
      else{
        this.mobileNumberComponent.setCountryCodeWithoutUserCountry("64");
        this.branchCountryCode = '+64';
        this.states = this.allStates.filter( s => s.StateId == this.nzStateId).sort((a, b) => a.StateId - b.StateId);
      }
    }
  }

  removeEmployerBranch(branch: EmployerBranch) {
    MessageBox.ShowCustom(this.dialog, 'Warning', 'Warning', 'This action will\
     permanently remove this branch/location. Proceed?', MessageBoxButton.YesNo, null, null, '420px').subscribe(r => {
      if (r.result == DialogResult.Yes) {
        this.employerBranchs = this.employerBranchs.filter(b => {
          if (b == branch) {
            b.Status = 'Remove';
            this.removedBranchs.push(b);
          } else {
            return true;
          }
        });
        this.dataSource_branch = new MatTableDataSource<any>(this.employerBranchs);
      }
    })
  }

  currentEditingBranchIndex: number;
  editEmployerBranch(branch: EmployerBranch, index: number) {
    this.branchForm.reset();
    this.clickBranchSubmit = false;
    if (typeof branch.PostCode == 'string') {
      branch.PostCode = null;
    }
    if (typeof branch.PostCode === 'object' && branch.PostCode) {
      branch.PostCodeId = branch.PostCode.PostcodeId;
    }
    this.branchForm.patchValue(branch);

    if (branch.StateId == this.nzStateId) {
      this.mobileNumberComponent.setCountryCodeWithoutUserCountry("64");
      this.branchCountryCode = '+64';
    }
    else {
      this.mobileNumberComponent.setCountryCodeWithoutUserCountry("61");
      this.branchCountryCode = '+61';
    }
    this.currentEditingBranchIndex = index;
    if (branch.EmployerBranchId && branch.Status != 'Addnew') {
      this.branchStatusCtrl.setValue('Update');
    }
  }

  addBranch() {
    this.branchForm.reset();
    this.branchCountryIdCtrl.setValue(1);
    this.setBranchMobileNoCountryCode(1);
    this.currentEditingBranchIndex = undefined;
    this.branchStatusCtrl.setValue('Addnew');
    this.clickBranchSubmit = false;
  }

  submitBranch() {
    if (this.branchForm.invalid) {
      this.clickBranchSubmit = true;
      return;
    }

    let existBranchName: boolean = false;
    let existAddress1: boolean = false;
    this.employerBranchs
      .filter(b => b != this.employerBranchs[this.currentEditingBranchIndex])
      .forEach((b: EmployerBranch) => {
        if (b.BranchName.toLocaleLowerCase() == (<EmployerBranch>this.branchForm.value).BranchName.toLocaleLowerCase()) {
          existBranchName = true;
        }
        if ((<EmployerBranch>this.branchForm.value).StreetAddress1 !== null && b.StreetAddress1 !== null) {
          if (b.StreetAddress1.toLocaleLowerCase() == (<EmployerBranch>this.branchForm.value).StreetAddress1.toLocaleLowerCase()) {
            existAddress1 = true;
          }
        }
      });

    if (!existBranchName && !existAddress1) {
      if (this.branchPhoneCtrl.value) {
        
        var phone = this.branchPhoneCtrl.value;
        if(this.branchCountryCode == '+61'){
          if ((<string>this.branchPhoneCtrl.value).length === 10) {
            phone = this.branchPhoneCtrl.value.replace(0, '');
          }
          this.branchPhone.CallOnChange(phone);
        }

        if(this.branchCountryCode == '+64'){
          if ((<string>this.branchPhoneCtrl.value).length > 6 && (<string>this.branchPhoneCtrl.value).startsWith('0')) {
            phone = this.branchPhoneCtrl.value.replace(0, '');
          }
          this.branchPhone.CallOnChange(phone);
        }
      }
      //Edit existing branch in system
      if ((<EmployerBranch>this.branchForm.value).EmployerBranchId) {
        this.employerBranchs = this.employerBranchs.map(b => {
          if (b.EmployerBranchId == this.branchForm.value.EmployerBranchId) {
            b = this.branchForm.value;
            if (typeof b.PostCode === 'object') {
              b.PostCodeId = b.PostCode.PostcodeId;
            }
          }
          return b;
        });
      } else {
        //Edit adding branch under local
        if (this.currentEditingBranchIndex != null || this.currentEditingBranchIndex != undefined) {
          this.employerBranchs[this.currentEditingBranchIndex] = this.branchForm.value;
        }
        //Add new branch
        else {
          this.employerBranchs.push(this.branchForm.value);
        }
      }
    }

    if (existBranchName)
      MessageBox.ShowCustom(this.dialog, "Error", "Error", "A branch/location with this name already exists", MessageBoxButton.Ok).subscribe(r => {
        if (r.result == DialogResult.Ok) {
          return;
        }
      });
    else if (existAddress1) {
      MessageBox.ShowCustom(this.dialog, "Error", "Error", "A branch/location with this address already exists", MessageBoxButton.Ok).subscribe(r => {
        if (r.result == DialogResult.Ok) {
          return;
        }
      });
    } else {
      this.dataSource_branch = new MatTableDataSource<any>(this.employerBranchs);

      this.branchSuburbs = [];
      this.CloseModalBranch(true);
    }

  }

  processRequestDate(employerId: number): RequestEmployerBranch {
    const updateAddbranchs = this.dataSource_branch ? this.dataSource_branch.data.filter(b => b.Status !== null) : [];
    const removedBranch = this.removedBranchs.filter(b => b.EmployerBranchId !== null)
    const branchs: EmployerBranch[] = Object.assign([], updateAddbranchs.concat(removedBranch));
    let userName = this.commonSvc.GetUser();
    console.log('UserName Process Request Date: ', userName);

    let requestBranchs: EmployerBranch[] = [];
    branchs.forEach(b => {
      let branch: EmployerBranch = {
        EmployerBranchId: b.EmployerBranchId ? b.EmployerBranchId : -1,
        EmployerId: employerId,
        BranchName: b.BranchName,
        EligibleEmployerNo: b.EligibleEmployerNo,
        AddressId: b.AddressId ? b.AddressId : -1,
        StreetAddress1: b.StreetAddress1,
        StreetAddress2: b.StreetAddress2 ? b.StreetAddress2 : '',
        SuburbId: (typeof b.Suburb == 'object') ? b.Suburb.Id : -1,
        PostCodeId: b.PostCodeId ? b.PostCodeId : -1,
        StateId: b.StateId ? b.StateId : -1,
        CountryId: b.CountryId ? b.CountryId : -1,
        ContactName: b.ContactName ? b.ContactName : '',
        ContactPhoneNumber: b.ContactPhoneNumber ?
          (b.ContactPhoneNumber.length > 0 ? (b.StateId != this.nzStateId ? '+61' + b.ContactPhoneNumber : '+64' + b.ContactPhoneNumber) : null) : null,
        ContactEmailAddress: b.ContactEmailAddress ? b.ContactEmailAddress : '',
        Status: b.Status,
        AdditionalNote: b.AdditionalNote ? b.AdditionalNote : '',
        PostCode: (typeof b.PostCode == 'object') ? b.PostCode.Postcode : (b.PostCode ? b.PostCode : ''),
        Suburb: (typeof b.Suburb == 'object') ? b.Suburb.Name : (b.Suburb ? b.Suburb : ''),
        ModifiedBy: userName
      }
      requestBranchs.push(branch);
    })
    console.log('Branches to send: ', requestBranchs);
    let RequestEmployerBranchs: RequestEmployerBranch = {
      ListOfBranchs: requestBranchs
    }
    return RequestEmployerBranchs;
  }

  public SaveBranchs(employerId: number, callback: Function) {
    if (this.employerBranchs) {
      const requestBranchData = this.processRequestDate(employerId);
      if (requestBranchData.ListOfBranchs.length > 0) {
        this.Invoke(
          this.employerService.requestEmployerBranchs(requestBranchData),
          {
            onSuccess: (result: ResultModel) => {
              if (result.Success) {
                this.employerBranchs = null;
                this.LoadLocations(() => {
                  callback();
                });
              } else {
                MessageBox.ShowError(this.dialog, 'Something went wrong in Employer Branch');
                console.error(result.Message);
              }
              return;
            }
          }
        );
      } else {
        callback();
      }
    } else {
      callback();
    }
  }
}

