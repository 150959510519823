import { Component, Injectable, Injector, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Country, Message } from '@fp/models';
import { FacilityFormBase } from 'src/app/components/base';
import { FacilityActivitiesDetailsComponent } from './facility-activities-details/facility-activities-details.component';
import { FacilityAddressDetailsComponent } from './facility-address-details/facility-address-details.component';
import { FacilityContactDetailsComponent } from './facility-contact-details/facility-contact-details.component';
import { FacilityIdentificationDetailsComponent } from './facility-identification-details/facility-identification-details.component';
import { FacilityImportantInformationComponent } from './facility-important-information/facility-important-information.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
  })
@Component({
    selector: 'app-facility-details',
    templateUrl: './facility-details.component.html',
    styleUrls: ['./facility-details.component.css'],
    providers: [{ provide: FacilityFormBase, useExisting: FacilityDetailsComponent }]
})
export class FacilityDetailsComponent extends FacilityFormBase {
    @ViewChild('identificationDetails') public identificationDetails: FacilityIdentificationDetailsComponent;
    @ViewChild('importantInformation') public importantInformation: FacilityImportantInformationComponent;
    @ViewChild('addressDetails') private addressDetails: FacilityAddressDetailsComponent;
    @ViewChild('contactDetails') public contactDetails: FacilityContactDetailsComponent;
    @ViewChild('activityDetails') public activityDetails: FacilityActivitiesDetailsComponent;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            IdentificationDetails: FacilityIdentificationDetailsComponent.getFormGroup(),
            ImportantInformation: FacilityImportantInformationComponent.getFormGroup(),
            AddressDetails: FacilityAddressDetailsComponent.getFormGroup(),
            ContactDetails: FacilityContactDetailsComponent.getFormGroup(),
            ActivityDetails: FacilityActivitiesDetailsComponent.getFormGroup()
        });
    }

    constructor(injector: Injector) { super(injector); }

    public getCountry(): Country {
        return this.addressDetails.getCountry();
    }
}
