import { Component, OnInit, Input } from '@angular/core';
import { BaseControlComponent, MakeProvider } from '../base/base-control';
import { AbstractControl, FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { PatternConstant } from '../../../constant/patternconstant';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.css'],
  host: { '(blur)': 'onTouched()' },
  providers: [MakeProvider(PhoneNumberComponent)]
})
export class PhoneNumberComponent<T> extends BaseControlComponent<string> implements OnInit {
  @Input() submitted: boolean; // flag when click on submit button
  @Input() phoneLength = 10;
  @Input() pattern = PatternConstant.PATTERN_PHONE;
  @Input() clsLabel: string;
  @Input() clsGroup: string;
  @Input() label: string;
  @Input() readonly: boolean = false;

  _validators: ValidatorFn[];
  invalidPhone = false;
  IsAUMobile: boolean;

  constructor() {
    super();
    this._validators = [];
  }

  private CheckValidatePhone() {
    if (this.control.value && this.control.value.indexOf('00') === 0) {
      this.invalidPhone = true;
    } else {
      this.invalidPhone = false;
    }
  }

  ngOnInit() {
    if (this.control != null && this.control.validator != null) {
      this._validators = [this.control.validator];
    }
  }

  getValidators() {
    return this._validators[0] != null ? [...this._validators, Validators.pattern(PatternConstant.PATTERN_PHONE_2)] :
      [Validators.pattern(PatternConstant.PATTERN_PHONE_2)];
  }

  private ReplaceNotNumber(val) {
    const evkey = PatternConstant.NOT_NUMBER;
    const re = new RegExp(evkey, 'g');
    val = val.replace(re, '');
    return val;
  }

  public OnChange(event) {
    this.CheckValidatePhone();

    let val = event.target.value;
    val = this.ReplaceNotNumber(val);
    this.control.setValue(val);
  }

  public SetMobileLenghtValidators(isAu : boolean){
    if(!isAu){
      this.IsAUMobile = false;
      this.phoneLength = 12;
      this.control.setValidators([
        Validators.required,
        Validators.pattern(PatternConstant.PATTERN_PHONE_6),
      ]);
    }
    else{
      this.IsAUMobile = true;
      this.phoneLength = 10;
      this.control.setValidators([
        Validators.required,
        Validators.pattern(PatternConstant.PATTERN_PHONE_2),
      ]);
    }

    this.control.updateValueAndValidity();
  }

}
