import { Component, Injector } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ModelBasedForm } from '@fp/components/base';
import { EmployerDetailModel } from '@fp/models';
import { EmployerAdditionalInformationComponent, EmployerAddressContactDetailsComponent, EmployerCodeComponent, EmployerDetailsComponent, EmployerNotesComponent } from '../../groups';

@Component({
    selector: 'app-employer-details-form',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.css'],
    providers: [ModelBasedForm.provideExisting(EmployerDetailsFormComponent)]
})
export class EmployerDetailsFormComponent extends ModelBasedForm<EmployerDetailModel> {
    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            PersonalDetailsSection: EmployerDetailsComponent.getFormGroup(),
            AddressDetailsSection: EmployerAddressContactDetailsComponent.getFormGroup(),
            CodesSection: EmployerCodeComponent.getFormGroup(),
            AdditionalInformationSection: EmployerAdditionalInformationComponent.getFormGroup(),
            NotesSection: EmployerNotesComponent.getFormGroup()
        });
    }

    constructor(injector: Injector) { super(injector); }
}
