import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CurrencyTestDirective } from './currency.directive';
import { CurrencyTestService } from './currency.service';

@NgModule({
    imports: [CommonModule, FormsModule],
    exports: [CurrencyTestDirective],
    declarations: [CurrencyTestDirective],
    providers: [CurrencyTestService]
})
export class CurrencyTestModule { }