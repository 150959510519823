import { ChangeDetectorRef, Component, Injector, OnInit } from "@angular/core";
import { FormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MemberFormBase } from "@fp/components/base";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { HealthDisclaimerComponent } from "@fp/components/health-disclaimer/health-disclaimer.component";
import { DataResult, MemberMedicalAnswer, MemberMedicalQuestion } from "@fp/models";
import { CommonService, MemberService, MessageBox } from "@fp/services";

@Component({
  selector: "medical-questions-component",
  templateUrl: "./medical-questions-component.component.html",
  styleUrls: ["./medical-questions-component.component.css"],
  providers: [
    { provide: MemberFormBase, useExisting: MedicalQuestionsComponentComponent },
  ],
})
export class MedicalQuestionsComponentComponent extends MemberFormBase {
  medicalQuestionAnswers: MemberMedicalAnswer[] = [];
  medicalQuestions: MemberMedicalQuestion[] = [];
  medicalQuestionFormGroup: UntypedFormGroup;

  constructor(injector: Injector,
    private cdRef: ChangeDetectorRef,
    private memberSvc: MemberService,
    private conSvc: CommonService,
    private _formBuilder: UntypedFormBuilder,) {
      super(injector);
    }

  ngOnInit(): void {
    this.OnLoad();
    this.medicalQuestionFormGroup = this._formBuilder.group({
      medicalQuestionAndAnswers: [[], [Validators.required]]
    });
  }

  markYesAnswer(questionId : number){
    this.medicalQuestionAnswers.find(question => question.MemberMedicalQuestionId == questionId).Answer = true;
    this.checkAndOpenDisclairmer();
  }

  markNoAnswer(questionId : number){
    this.medicalQuestionAnswers.find(question => question.MemberMedicalQuestionId == questionId).Answer = false;
    this.checkAndOpenDisclairmer();
  }

  private checkAndOpenDisclairmer(){

    var answerCount = this.medicalQuestionAnswers.filter(question => question.Answer != null).length;

    if(answerCount == this.medicalQuestionAnswers.length){
      this.medicalQuestionFormGroup.controls.medicalQuestionAndAnswers.setValue(this.medicalQuestionAnswers);
      this.dialog.open(HealthDisclaimerComponent);
    }
  }

  OnLoad() {
    if (this.conSvc.GetMedicalQuestions()) {
      this.medicalQuestions = this.conSvc.GetMedicalQuestions();

      if(this.medicalQuestions && this.medicalQuestions.length > 0){
        this.updateQuestionsAndAnswers();
        super.OnLoad();
        return;
      }
    }
    this.Invoke(this.memberSvc.getMedicalQuestions(), {
      onSuccess: (result: DataResult<MemberMedicalQuestion[]>) => {
        if (result.Success) {
          this.medicalQuestions = result.Data;
          this.updateQuestionsAndAnswers();
        } else {
          this.HandleResponseError(result);
        }
        super.OnLoad();
      },
      onError: (err) => {
        MessageBox.ShowError(
          this.dialog,
          "Sorry, something went wrong. Let's try that again."
        ).subscribe((res) => {
          if (res.result.toLowerCase() === DialogResult.Ok) {
            window.location.reload();
          }
        });
        this._logger.error(err);
      },
    });
  }

  updateQuestionsAndAnswers() {
    if (this.medicalQuestions && this.medicalQuestions.length > 0) {
      this.medicalQuestions.forEach((question) => {
        var medicalAnswer = new MemberMedicalAnswer();
        medicalAnswer.MedicalQuestion = question;
        medicalAnswer.MemberMedicalQuestionId = question.MemberMedicalQuestionId;
        this.medicalQuestionAnswers.push(medicalAnswer);
      });
    }
  }

  SetMedicalQuestionAnswers(medicalQuestionAnswers: MemberMedicalAnswer[]){
    this.medicalQuestionAnswers = medicalQuestionAnswers;
    this.medicalQuestionFormGroup.updateValueAndValidity();
    this.medicalQuestionFormGroup.markAsDirty();
    this.medicalQuestionFormGroup.controls.medicalQuestionAndAnswers.setValue(medicalQuestionAnswers);
  }
}
