import { Component, Injector, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService, MessageBox, SuburbService } from "@fp/services";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import {
  FPBaseComponent,
  IFPComponentData,
  FPFormBaseComponent,
} from "@fp/components/base";
import { DataResult } from "@fp/models/data-result";
import { MapFacilityData } from "@fp/models/map-facility-data";
import { CommonConstants } from "@fp/constant/common-constants";
import { FilterSearchResultPopupComponent } from "@fp/filter-search-result-popup/filter-search-result-popup.component";
import { ActivityConstant } from "@fp/constant/activity-constants";

@Component({
  selector: "app-map-list-view-new",
  templateUrl: "./map-list-view-new.component.html",
  styleUrls: ["./map-list-view-new.component.css"],
})
export class MapListViewNewComponent
  extends FPBaseComponent<IFPComponentData>
  implements OnInit {
  public dataSource: Array<MapFacilityData> = [];
  public filteredDataSource: Array<MapFacilityData> = [];
  public mapDataSource: Array<MapFacilityData> = [];
  mapLocationSearch = "";
  listLocationFilter = "";
  checkedName = "";
  disableSearchFacByArea = false;
  disableSearchFacByName = false;
  isChecked = false; 

  constructor(
    injector: Injector,
    protected commonSvc: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private suburbSvc: SuburbService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.commonSvc.App.currentLogin = false;
    this.commonSvc.App.opened = false;
  }

  ngAfterViewInit() {
    this.OnLoad();
  }

  OnLoad() {
    const memberId: number = +this.commonSvc.D_FP_AES256ForShortString(
      localStorage.getItem("memberId")
    );
    let membershipMapPackageId =
      this.route.snapshot.queryParamMap.get("packageId");
    this.LoadFacilitiesByMembershipPackageId(
      Number(membershipMapPackageId),
      memberId
    );
  }

  LoadFacilitiesByMembershipPackageId(id: number, memberid: number) {
    if (!id) {
      return;
    }
    this.Search("Membership_Package", "" + id, "" + memberid);
  }

  Search(name: string, value: string, memId?: string) {
    if (memId === undefined) {
      memId = null;
    }
    this.Invoke(this.suburbSvc.getFacilityMap(name, value, memId), {
      onSuccess: (res: DataResult<MapFacilityData[]>) => {
        if (res.Success) {
          this.dataSource = res.Data;
          this.setToolTip();
          this.filteredDataSource = this.dataSource;
          this.mapDataSource = this.dataSource;
          this.mapDataSource = this.mapDataSource.map((item) => {
            return { ...item, IsShownAdditionalInfo: false };
          });            
        } else {
          MessageBox.ShowError(
            this.dialog,
            "Sorry, something went wrong. Let's try that again."
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              window.location.reload();
            }
          });
          this._logger.error(res);
        }
      },
      onError: (err) => {
        MessageBox.ShowError(
          this.dialog,
          "Sorry, something went wrong. Let's try that again."
        ).subscribe((res) => {
          if (res.result.toLowerCase() === DialogResult.Ok) {
            window.location.reload();
          }
        });
        this._logger.error(err);
      },
    });
  }

  setToolTip(){
    //Setting tooltip
    if(this.dataSource.length > 0){
      this.dataSource.forEach( (facility) => {
        if(facility.Activities.length > 0){
          facility.Activities.forEach( (activity) => {

            if(activity.ActivityName.toLocaleLowerCase() == ActivityConstant.FACILITY_ACCESSIBLE_OPTIONS.toLocaleLowerCase()){
              activity.ActivityToolTip = "Accessible Options<br>(See Additional Info)"
            }
            else{
              activity.ActivityToolTip =activity.ActivityName;
            }
          })
        }
      });
    } 
  }

  searchBarFunctionality() {
    var isAus =
      this.commonSvc.getUserCountry()?.toUpperCase() !==
      CommonConstants.COUNTRY_NEW_ZEALAND;

    var country;
    var countryCenterCity;
    var region;

    if (isAus) {
      country = " Australia";
      countryCenterCity = "Alice Springs";
      region = "au";
    } else {
      country = " New Zealand";
      countryCenterCity = "Wellington";
      region = "nz";
    }

    if (this.mapLocationSearch !== "") {
      this.mapLocationSearch = this.mapLocationSearch.trim();
      this.filteredDataSource = this.dataSource.filter(
        (facility: MapFacilityData) =>
          facility.Postcode.Code === this.mapLocationSearch ||
          facility.Suburb.Name.toLocaleLowerCase() === this.mapLocationSearch.toLocaleLowerCase()
      );

      this.mapDataSource = this.filteredDataSource;
    } else {
      this.mapDataSource = this.dataSource;
      this.filteredDataSource = this.dataSource;
    }
  }

  openCloseAdditionalInfo(id){    
    var AdditionalInfoObj = this.mapDataSource.find(item => item.FacilityId === id);
    AdditionalInfoObj.IsShownAdditionalInfo = !AdditionalInfoObj.IsShownAdditionalInfo;    
  }

  filterSearchResultPopup() {
    this.mapDataSource = this.dataSource;   
    this.mapLocationSearch = "";
    let dialogRef = this.dialog.open(FilterSearchResultPopupComponent, {
      width: "966px",
      data: {
        dataSource: this.dataSource,
        filteredDataSource: this.filteredDataSource,
        listLocationFilter: this.listLocationFilter,
        disableSearchFacByName: this.disableSearchFacByName,
        isChecked: this.isChecked,
        checkedName: this.checkedName,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.mapDataSource = result[0].resultMap;
      }
    });
  }

  goToMapView(pageName: string) {
    this.router.navigate([`${pageName}`], {
      queryParams: {
        packageId: this.route.snapshot.queryParamMap.get("packageId"),
      },
    });
  }
}
