import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common.service";
import { Observable } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import {
  DataResult,
  Facility,
  Region,
  Suburb,
  SearchPaginationRequest
} from "src/app/models";
import { FacilityService } from "src/app/services/facility.service";
import { PageEvent } from "@angular/material/paginator";
import { Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CommonString } from "src/app/constant/common-string";
import { Router, ActivatedRoute } from "@angular/router";
import { RouterConstant } from "src/app/constant/routerconstant";
import { MatDialog } from "@angular/material/dialog";
import { Service } from "src/app/models/service.model";

interface FacilityInterface {
  id: number;
  name: string;
  serviceTypes: Array<commonModel>;
  status: string;
  region: commonModel;
  suburb: commonModel;
}

class _Facilities {
  constructor(
    public data: FacilityInterface[] = [],
    public totalItem: number = 0
  ) { }
}

class commonModel {
  constructor(public id: number = 0, public name: string = "") { }
}

@Component({
  selector: "app-vieweditfacilities",
  templateUrl: "./vieweditfacilities.component.html",
  styleUrls: ["./vieweditfacilities.component.css"]
})
export class VieweditfacilitiesComponent implements OnInit {
  routerFacilityEdit = RouterConstant.NAVIGATOR_FACILITY_EDIT;
  requestQueueCount: number = 0;
  facilities: _Facilities;
  paging = new SearchPaginationRequest();
  displayedColumns: string[] = [
    "name",
    "serviceType_name",
    "status",
    "region_name",
    "suburb_name",
    "action"
  ];
  dataSource: MatTableDataSource<any>;
  commonString = CommonString;
  RouterConstant = RouterConstant;
  //material
  length: number;
  pageSize: number;
  pageSizeOptions: number[] = [10, 20, 50];
  pageEvent: PageEvent;

  //parameters
  filter: string;
  value: string;
  facilityName: string;

  constructor(
    private commonService: CommonService,
    private service: FacilityService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.addParamsSearch();

    this.loadFacilities();
  }

  //Author: Yen
  addParamsSearch() {
    this.paging.OrderBy = "name";
    this.paging.ViewColumns = ["region", "suburb", "services"];
    this.filter = this.route.snapshot.queryParamMap.get("filter");
    this.value = this.route.snapshot.queryParamMap.get("value");
    this.facilityName = this.route.snapshot.queryParamMap.get("facilityName");
    if (this.facilityName && this.filter == "all") {
      this.paging.Params = [{ Name: "name", Value: this.facilityName }];
    } else if (!this.facilityName) {
      this.paging.Params = [{ Name: this.filter, Value: this.value }];
    } else {
      this.paging.Params = [
        { Name: this.filter, Value: this.value },
        { Name: "name", Value: this.facilityName }
      ];
    }
  }

  goBack() {
    this.router.navigate([
      "/" +
      RouterConstant.ROUTER_DASHBOARD +
      "/" +
      RouterConstant.ROUTER_FACILITY_SEARCH
    ]);
  }

  /**
   * subroutine of loadFacilities function
   * map function to set new result for fields that be needed in
   * @param source
   * @param handleResultCallback
   */
  invoke(
    source: Observable<any>,
    handleResultCallback: Function,
    mapCallback: Function
  ) {
    this.requestQueueCount++;
    this.commonService.StartProgressBar();
    source
      .pipe(
        catchError(e => {
          throw e;
        }),
        finalize(() => {
          this.requestQueueCount--;
          if (this.requestQueueCount <= 0) {
            this.commonService.StopProgressBar();
            this.requestQueueCount = 0;
          }
        }),
        map(data => {
          return mapCallback(data);
        })
      )
      .subscribe(
        res => {
          handleResultCallback(res);
        },
        err => {
          console.log(err);
        }
      );
  }

  /**
   * call invokeFacilities function to take data from backend api.
   * map function to set new result for fields that be needed.
   */
  loadFacilities() {
    this.invoke(
      this.service.search(this.paging),
      (data: _Facilities) => {
        this.dataSource = new MatTableDataSource<any>(data.data);
        this.length = data.totalItem;
      },
      (data: string) => {
        const dectypted_data = this.commonService.D_FP_ResponseData(data);
        const rdata: DataResult<{ Results: Facility[]; TotalItem: number }> = JSON.parse(dectypted_data);
        if (rdata.Success == false) {
          console.log(rdata.Message);
          return {};
        }

        let f: _Facilities = new _Facilities();
        f.totalItem = rdata.Data.TotalItem;

        rdata.Data.Results.map((facility: Facility) => {
          let _serviceTypes: Array<commonModel> = new Array<commonModel>();

          if (facility.Region == null) facility.Region = new Region();
          if (facility.Suburb == null) facility.Suburb = new Suburb();

          let _serviceType: commonModel;
          facility.Services.map((s: Service) => {
            _serviceType = new commonModel(
              s.ServiceType.Id,
              s.ServiceType.Name
            );
            _serviceTypes.push(_serviceType);
          });
          const _f: FacilityInterface = {
            id: facility.FacilityId,
            name: facility.Name,
            serviceTypes: this.removeDuplicates(_serviceTypes, "id"),
            status: facility.FacilityStatus.Name,
            region: new commonModel(
              facility.Region.RegionId,
              facility.Region.Name
            ),
            suburb: new commonModel(
              facility.Suburb.SuburbId,
              facility.Suburb.Name
            )
          };
          f.data.push(_f);
        });
        return f;
      }
    );
  }

  /**
   * Running when user manipulate on material paginator.
   * @param event
   */
  paginatorChange(event: PageEvent) {
    this.paging.PageNumber = event.pageIndex + 1;
    this.paging.PageSize = event.pageSize;
    this.loadFacilities();
  }

  /**
   * Running when user click column header of Material table.
   * @param sort
   */
  sortChange(sort: Sort) {
    const active: string = sort.active.toLowerCase();
    const isOderByAsc: boolean = sort.direction == "asc" ? false : true;
    this.paging.OrderBy = active;
    this.paging.OrderByAsc = isOderByAsc;
    this.loadFacilities();
  }

  getStatus(status: String) {
    switch (status) {
      case "Active":
        return "text-success";
      case "Inactive":
        return "text-muted";
      case "Pending":
        return "text-warning";
    }
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }
}
