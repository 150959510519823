import { Component, ChangeDetectorRef, OnInit, AfterViewInit, ElementRef, ViewChild, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { CommonService, HttpDAO, MessageBox } from "@fp/services";
import { ActivatedRoute } from "@angular/router";
import { RolePermissionService } from "@fp/services/role-permission.service";
import { FacilityAdditionalNotes, ListFacilityNotes } from "@fp/models/facility-notes.model";
import { HttpClient } from "@angular/common/http";
import { CommonResponse, User } from "@fp/models";
import { ERole } from "@fp/enums/role.enum";
import { of as observableOf, forkJoin, Observable } from "rxjs";
import { APIConstant, StorageKey } from "@fp/constant";
import { map, catchError } from "rxjs/operators";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { DateHelper } from "@fp/helpers";
import { v4 as uuid } from 'uuid';

@Component({
    selector: "app-facility-notes",
    templateUrl: "./facility-notes.component.html",
    styleUrls: ["./facility-notes.component.css"]
})
export class FacilityNotesComponent implements OnInit, AfterViewInit {
    displayedColumns: string[] = ['Username', 'DateTimeDisplay', 'Type', 'Note', 'Action'];
    dtmatnotes: MatTableDataSource<FacilityAdditionalNotes> = new MatTableDataSource<FacilityAdditionalNotes>();
    buttonText: any = 'Add Note';
    FacilityNoteFormGroup: UntypedFormGroup;
    NoteTypes: CommonResponse[] = [];
    NoteTypesSelected: CommonResponse[] = [];
    selectedrole: any = '';
    btnAddNoteClick = false;
    datasending: ListFacilityNotes = new ListFacilityNotes();
    ERole = ERole;
    private facilityid: number = -1;
    private httpdao: HttpDAO | null;
    private databinding: FacilityAdditionalNotes[] = [];
    private updateditem: FacilityAdditionalNotes = null;
    @ViewChild('txtFacilityNotes') txtFacilityNotes: ElementRef;
    @ViewChild('facilityNotes') eleFacilityNotes: ElementRef;
    @ViewChild('notepagi') note_paginator: MatPaginator;
    private isReadonly: boolean;
    constructor(
        private rolepermission: RolePermissionService,
        private comsvc: CommonService,
        private route: ActivatedRoute,
        private _formBuilder: UntypedFormBuilder,
        private http: HttpClient,
        protected dialog: MatDialog,
        private changeDetectorRefs: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.httpdao = new HttpDAO(this.http);
        this.CreateFacilityNoteForm();
        this.InitForm();
    }

    ngAfterViewInit() {
    }

    InitForm(loaddt: boolean = true) {
        this.comsvc.FacilityNoteComponent = this;
        this.CanAccess();
        const mid = this.route.snapshot.params.id;
        if (mid !== undefined) {
            this.facilityid = mid;
        } else {
            this.facilityid = this.comsvc.FacilityIdForNote;
        }
        if (loaddt) {
            this.LoadData(this.facilityid, this.IsExternalUser());
        }
        this.btnAddNoteClick = false;
        this.NoteTypesSelected = [];
        this.buttonText = 'Add Note';
        this.updateditem = null;
        this.datasending.FacilityNotes = [];
        this.FacilityNoteFormGroup.get('slcNoteType').enable();
        this.checkPrivilegesForReadOnly();
    }

    private CreateFacilityNoteForm() {
        this.FacilityNoteFormGroup = this._formBuilder.group({
            slcNoteType: [''],
            txtFacilityNotes: ['']
        });
    }

    private LoadData(facilityid: number, isexternalonly: boolean) {
        forkJoin(
            this.httpdao!.getSingleData(APIConstant.API_GET_FACILITY_NOTE_TYPE),
            this.httpdao!.getSingleData(APIConstant.API_GET_FACILITY_NOTES(facilityid, isexternalonly))
        ).pipe(
            map(([typenotes, notes]) => {
                return { typenotes, notes };
            }),
            catchError(() => {
                return observableOf(null);
            })
        ).subscribe(res => {
            if (res != null) {
                this.NoteTypes = res.typenotes.Data;
                this.databinding = res.notes.Data;
                for (let i = 0; i < this.databinding.length; i++) {
                    this.databinding[i].Type = this.databinding[i].NoteTypeId === 1 ? 'Internal' : 'External';
                    this.databinding[i].DateTimeDisplay = this.ConvertToLocalTime(this.databinding[i].CreateOrUpdateDate);
                    this.databinding[i].HoursofNote = new Date(this.databinding[i].CreateOrUpdateDate.toString() + 'Z').getTime();
                    this.databinding[i].Key = uuid();
                }
                this.dtmatnotes = new MatTableDataSource<FacilityAdditionalNotes>(this.databinding);
                this.dtmatnotes.paginator = this.note_paginator;
                this.HideFacilityNoteSection();

                if (this.NoteTypes.find(a => a.Name.includes('Internal'))) {
                    this.SelectNoteType(this.NoteTypes.find(a => a.Name.includes('Internal')).Id);
                }
                
                this.changeDetectorRefs.detectChanges();
            }
        });
    }

    private ConvertToLocalTime(d: Date) {
        const localdate: Date = new Date(d.toString() + 'Z');
        return DateHelper.format(localdate, 'DD-MMM-YYYY HH:mm');
    }

    private HideFacilityNoteSection() {
        if (this.dtmatnotes.data.length == 0 && this.IsExternalUser() === true) {
            this.eleFacilityNotes.nativeElement.parentElement.parentElement.parentElement.parentElement.hidden = true;
        } else {
            this.eleFacilityNotes.nativeElement.parentElement.parentElement.parentElement.parentElement.hidden = false;;
        }
    }

    btnAddFacilityNote_Click(event) {
        this.btnAddNoteClick = true;
        if (this.buttonText === 'Add Note') {
            if (this.NoteTypesSelected.length > 0
                && this.FacilityNoteFormGroup.get('txtFacilityNotes').value.trim() !== '') {
                const textnoteforsub = this.FacilityNoteFormGroup.get('txtFacilityNotes').value;
                if (this.NoteTypesSelected.length > 1) {
                    for (let i = 0; i < this.NoteTypesSelected.length; i++) {
                        if (this.NoteTypesSelected[i].Id === 2) {
                            const self = this;
                            MessageBox.ShowCancelContinue(this.dialog, 'External Note Confirmation',
                                'An external note is about to be posted on the facility\'s profile.' +
                                ' This note will be visible to the facility. Do you wish to continue?').subscribe(res => {
                                    if (res.result.toLowerCase() === DialogResult.Continue) {
                                        const item1: FacilityAdditionalNotes = self.CreateNewFacilityNote(2);
                                        item1.Note = textnoteforsub;
                                        self.databinding.unshift(item1);
                                        self.datasending.FacilityNotes.push(item1);

                                        const item2: FacilityAdditionalNotes = this.CreateNewFacilityNote(1);
                                        this.databinding.unshift(item2);
                                        this.datasending.FacilityNotes.push(item2);

                                        this.dtmatnotes = new MatTableDataSource<FacilityAdditionalNotes>(this.databinding);
                                        this.FacilityNoteFormGroup.get('txtFacilityNotes').setValue('');
                                        this.NoteTypesSelected = [];
                                        this.changeDetectorRefs.markForCheck();
                                        this.SaveFacilityNoteLocal();
                                    }
                                });
                        }
                    }
                } else {
                    if (this.NoteTypesSelected[0].Id === 2) {
                        const self = this;
                        MessageBox.ShowCancelContinue(this.dialog, 'External Note Confirmation',
                            'An external note is about to be posted on the facility\'s profile.' +
                            ' This note will be visible to the facility. Do you wish to continue?').subscribe(res => {
                                if (res.result.toLowerCase() === DialogResult.Continue) {
                                    const item: FacilityAdditionalNotes = self.CreateNewFacilityNote(2);
                                    item.Note = textnoteforsub;
                                    self.databinding.unshift(item);
                                    self.datasending.FacilityNotes.push(item);
                                    self.dtmatnotes = new MatTableDataSource<FacilityAdditionalNotes>(self.databinding);
                                    self.FacilityNoteFormGroup.get('txtFacilityNotes').setValue('');
                                    self.NoteTypesSelected = [];
                                    self.changeDetectorRefs.markForCheck();
                                    this.SaveFacilityNoteLocal();
                                }
                            });
                    } else {
                        const item: FacilityAdditionalNotes = this.CreateNewFacilityNote(1);
                        this.databinding.unshift(item);
                        this.datasending.FacilityNotes.push(item);
                        this.dtmatnotes = new MatTableDataSource<FacilityAdditionalNotes>(this.databinding);
                        this.FacilityNoteFormGroup.get('txtFacilityNotes').setValue('');
                        this.NoteTypesSelected = [];
                        this.changeDetectorRefs.markForCheck();
                        this.SaveFacilityNoteLocal();
                    }
                }
                this.btnAddNoteClick = false;
            }
        }
        else {
            if (this.FacilityNoteFormGroup.get('txtFacilityNotes').value.trim() !== '') {
                const self = this;
                MessageBox.ShowCancelContinue(this.dialog, 'External Note Confirmation',
                    'An external note is about to be posted on the facility\'s profile.' +
                    ' This note will be visible to the facility. Do you wish to continue?').subscribe(res => {
                        if (res.result.toLowerCase() === DialogResult.Continue) {
                            const noteobj = self.datasending.FacilityNotes.find(item => item.Key === self.updateditem.Key);
                            self.updateditem.Note = self.FacilityNoteFormGroup.get('txtFacilityNotes').value;
                            const currentUser = <User>JSON.parse(this.comsvc.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
                            if (noteobj === undefined) {
                                self.updateditem.Action = 'update';
                                self.updateditem.UserId = currentUser.UserId;
                                self.updateditem.Username = currentUser.UserName;
                                self.updateditem.DateTimeDisplay = DateHelper.format(new Date(), 'DD-MMM-YYYY HH:mm');
                                self.updateditem.HoursofNote = new Date().getTime();
                                self.datasending.FacilityNotes.push(self.updateditem);
                            } else {
                                noteobj.UserId = currentUser.UserId;
                                noteobj.Username = currentUser.UserName;
                                noteobj.Note = self.FacilityNoteFormGroup.get('txtFacilityNotes').value;
                                noteobj.DateTimeDisplay = DateHelper.format(new Date(), 'DD-MMM-YYYY HH:mm');
                                noteobj.HoursofNote = new Date().getTime();
                            }
                            self.databinding = self.databinding.sort((a, b) => {
                                return <any>b.HoursofNote - <any>a.HoursofNote;
                            });
                            self.dtmatnotes = new MatTableDataSource<FacilityAdditionalNotes>(self.databinding);
                            self.changeDetectorRefs.markForCheck();
                            self.btnAddNoteClick = false;
                            self.NoteTypesSelected = [];
                            self.buttonText = 'Add Note';
                            self.FacilityNoteFormGroup.get('txtFacilityNotes').setValue('');
                            self.FacilityNoteFormGroup.get('slcNoteType').enable();
                            this.SaveFacilityNoteLocal();
                        }
                    });
            }
        }
    }

    public DeleteFacilityNote(item: FacilityAdditionalNotes) {
        MessageBox.ShowCancelContinue(this.dialog, 'Delete Note Confirmation',
            'You are about to delete this external note. This operation is irreversible. Do you wish to continue?').subscribe(res => {
                if (res.result.toLowerCase() === DialogResult.Continue) {
                    this.RemoveItemFromArray(this.databinding, item);
                    this.dtmatnotes = new MatTableDataSource<FacilityAdditionalNotes>(this.databinding);
                    this.changeDetectorRefs.markForCheck();
                    if (item.FacilityNoteId !== undefined && item.FacilityNoteId !== null) {
                        item.Action = 'delete';
                        this.datasending.FacilityNotes.push(item);
                        this.SaveFacilityNoteLocal();
                    } else {
                        this.RemoveItemFromArray(this.datasending.FacilityNotes, item);
                    }
                } else {
                    return;
                }
            });
    }

    UpdateFacilityNote(item: FacilityAdditionalNotes) {
        this.NoteTypesSelected = [];
        this.buttonText = 'Update Note';
        this.FacilityNoteFormGroup.get('txtFacilityNotes').setValue(item.Note);
        this.txtFacilityNotes.nativeElement.focus();
        this.updateditem = item;
        this.FacilityNoteFormGroup.get('slcNoteType').disable();
    }

    SaveFacilityNote(): Observable<any> {
        const dataoutput = this.httpdao!.postData(APIConstant.API_SAVE_FACILITY_NOTE, this.datasending);
        return dataoutput;
    }

    private SaveFacilityNoteLocal() {
        this.comsvc.StartProgressBar();
        forkJoin(
            this.httpdao!.postData(APIConstant.API_SAVE_FACILITY_NOTE, this.datasending)
        ).pipe(
            map(([data]) => {
                return { data };
            }),
            catchError(() => {
                this.comsvc.StopProgressBar();
                return observableOf(null);
            })
        ).subscribe(res => {
            if (res.data.Success) {
                this.InitForm();
            }
            this.comsvc.StopProgressBar();
        });
    }

    private CreateNewFacilityNote(noteid: number): FacilityAdditionalNotes {
        const item: FacilityAdditionalNotes = new FacilityAdditionalNotes();
        item.FacilityId = this.facilityid;
        item.NoteTypeId = noteid;
        item.Note = this.FacilityNoteFormGroup.get('txtFacilityNotes').value;
        const currentUser = <User>JSON.parse(this.comsvc.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
        item.UserId = currentUser.UserId;
        item.Username = currentUser.UserName;
        item.Type = item.NoteTypeId === 1 ? 'Internal' : 'External';
        item.Action = 'addnew';
        item.DateTimeDisplay = DateHelper.format(new Date(), 'DD-MMM-YYYY HH:mm');
        item.HoursofNote = new Date().getTime();
        item.Key = uuid();
        return item;
    }

    IsExternalUser(): boolean {
        if (this.selectedrole === ERole.Facility_Staff_Member ||
            this.selectedrole === undefined ||
            this.selectedrole === null ||
            this.selectedrole === '') {
            return true;
        }
        else {
            return false;
        }
    }

    CanAccess(): boolean {
        const temp = this.comsvc.GetSelectedRole();
        for (let i = 0; i < this.rolepermission.RolesObject.length; i++) {
            if (temp === this.rolepermission.RolesObject[i].Name) {
                this.selectedrole = temp;
                return true;
            }
        }
        if (this.selectedrole === '') {
            return false;
        }
    }

    slcNoteType_Select(event) {
        const id: number = parseInt(event.target.value, 10);
        this.SelectNoteType(id);
    }

    private SelectNoteType(id: number) {
        if (id === null || id === undefined || id === 0) {
            return;
        }
        let selectedtype = this.NoteTypes.find(item => item.Id === id);
        this.NoteTypesSelected.push(selectedtype);
        this.FacilityNoteFormGroup.get('slcNoteType').setValue('');
    }

    SetNoteTypeDisabled(nt: CommonResponse) {
        let isBool = this.NoteTypesSelected.some(nts => nts.Id === nt.Id);
        return isBool;
    }

    UnsetNoteTypeSelected(nt: CommonResponse) {
        this.RemoveItemFromArray(this.NoteTypesSelected, nt);
    }

    private RemoveItemFromArray(arr: Array<any>, item: any) {
        var index = arr.indexOf(item);
        if (index > -1) {
            arr.splice(index, 1);
        }
    }

    private checkPrivilegesForReadOnly(): void {
        this.isReadonly = this.rolepermission.checkPrivilegesForReadOnly('View/edit facilities (Restrict to Read Only)');
        if (this.isReadonly) {
            this.FacilityNoteFormGroup.disable();
        }
    }
}