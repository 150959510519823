import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightedInputGroupDirective, NgbTimeBoundaryDirective, NumberOnlyDirective, NumericInputDirective, RestrictedPatternInputDirective, FPFormControlValidationDirective, OnlyNumbersDirective  } from '@fp/directives';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        HighlightedInputGroupDirective,
        NgbTimeBoundaryDirective,
        NumberOnlyDirective,
        NumericInputDirective,
        RestrictedPatternInputDirective,
        FPFormControlValidationDirective,
        OnlyNumbersDirective
    ],
    exports: [
        HighlightedInputGroupDirective,
        NgbTimeBoundaryDirective,
        NumberOnlyDirective,
        NumericInputDirective,
        RestrictedPatternInputDirective,
        FPFormControlValidationDirective,
        OnlyNumbersDirective
    ]
})
export class DirectivesModule { }
