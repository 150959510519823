import { Component, Injector, OnInit, Input } from '@angular/core';
import { FacilityProductFormBase } from 'src/app/components/facility-components/addfacility/products-services/products-list/product-form-base';
import { DataResult, CommonResponse, Product1, Status, ServiceType } from 'src/app/models';
import { ProductService } from 'src/app/services/product.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, AbstractControl } from '@angular/forms';
import { ProductFE } from '../products-list.component';
import { CommonMessage, CommonString } from 'src/app/constant';
import { StringHelper } from 'src/app/helpers';

@Component({
    selector: 'app-facility-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.css'],
    providers: [{ provide: FacilityProductFormBase, useExisting: FacilityProductDetailsComponent }]
})
export class FacilityProductDetailsComponent extends FacilityProductFormBase {
    // #region Constants
    CommonMessage = CommonMessage;
    CommonString = CommonString;
    StringHelper = StringHelper;
    // #endregion Constants
    types: ServiceType[];
    statuses: Status[] = [
        {
            StatusId: 1,
            Name: 'Active'
        },
        {
            StatusId: 2,
            Name: 'Inactive'
        }
    ];
    get nameControl() { return <UntypedFormControl>this.form.get('Name'); }
    get typeControl() { return <UntypedFormControl>this.form.get('ServiceType'); }
    get statusControl() { return <UntypedFormControl>this.form.get('Status'); }
    get priceControl() { return <UntypedFormControl>this.form.get('Price'); }
    get productCostControl() { return <UntypedFormControl>this.form.get('FpproductCost'); }
    get memberPriceControl() { return <UntypedFormControl>this.form.get('MemberSalePrice'); }
    get isGsControl() { return <UntypedFormControl>this.form.get('IsGstapplicable'); }
    get maxUnitsAvailable() { return <UntypedFormControl>this.form.get('MaxUnitsAvailable'); }
    @Input() flag: boolean;
    parentFocus = 0;

    static getFormGroup(): UntypedFormGroup {
        const fb = new UntypedFormBuilder();
        return fb.group({
            Id: [0],
            ServiceTypeId: [null],
            ServiceType: [, Validators.required],
            ServiceStatusId: [null],
            Status: [{
                StatusId: 1,
                Name: 'Active'
            }, Validators.required],
            Name: [, Validators.required],
            Price: [null, [Validators.required, Validators.min(0)]],
            MaxUnitsAvailable: [, [Validators.min(0)]],
            FpproductCost: ['', [Validators.required, Validators.min(0)]],
            MemberSalePrice: ['', [Validators.required, Validators.min(0)]],
            IsGstapplicable: [, [Validators.required, Validators.min(0)]],
            Description: [],
            flag: [],
            statusChanged: [],
            originStatus: []
        });
    }

    constructor(
        injector: Injector,
        private service: ProductService) {
        super(injector);
    }

    OnLoad() {
        this.loadTypes();
    }

    // LoadComplete() {
    //     super.LoadComplete();
    //     setTimeout(() => {
    //         this.getControl('Status').valueChanges.subscribe((value: ServiceStatus) => {
    //             if (value != null && value.Id == 2 && !this.form.get('statusChanged').value) {
    //                 this.form.get('statusChanged').setValue(true);
    //             }
    //         });
    //     });
    // }


    loadTypes() {
        this.Invoke(
            this.service.getTypes(),
            {
                onSuccess: (result: DataResult<ServiceType[]>) => {
                    if (result.Success) {
                        this.types = result.Data.sort((a, b) => {
                            if (a.Name > b.Name) {
                                return 1;
                            }
                            if (a.Name < b.Name) {
                                return -1;
                            }
                            return 0;
                        });

                        console.log(this.data);
                        if (this.data.model.flag == 3)
                            this.loadDetails();
                        else {
                            const product = Object.assign({}, this.data.model);
                            if (product.Id != 0) {
                                this.form.patchValue(product);
                            }
                            else if (product.Name == null) {
                                // this.priceControl.setValue(null);
                                // this.productCostControl.setValue(null);
                                // this.memberPriceControl.setValue(null);
                            }
                            else {
                                this.form.patchValue(product);
                            }
                        }
                    } else {
                        this.HandleResponseError(result);
                    }
                    super.OnLoad();
                }
            }
        );
    }

    loadDetails() {
        this.Invoke(
            this.service.getDetails(this.data.model.Id),
            {
                onSuccess: (result: DataResult<Product1>) => {
                    if (result.Success) {
                        const product = this.fetchData(result.Data);
                        this.form.patchValue(product);
                        this.form.get('originStatus').patchValue(this.form.value.Status);
                    } else {
                        this.HandleResponseError(result);
                    }
                }
            }
        );
    }

    fetchData(product: Product1) {
        let status: Status;
        let type: ServiceType;
        this.statuses.forEach(st => {
            if (st.StatusId == product.ProductStatusId)
                status = Object.assign({}, st);
        });
        this.types.forEach(t => {
            if (t.Id == product.ServiceTypeId)
                type = Object.assign({}, t);
        });
        let _product: ProductFE = {
            Id: product.Id,
            Name: product.Name,
            ServiceType: type,
            Status: status,
            Price: product.Price,
            MaxUnitsAvailable: product.MaxUnitsAvailable,
            Description: product.Description,
            FpproductCost: product.FpproductCost,
            MemberSalePrice: product.MemberSalePrice,
            IsGstapplicable: product.IsGstapplicable
        };
        return _product;
    }

    types_CompareFn(opt1: CommonResponse, opt2: CommonResponse) {
        return (!opt1 && !opt2) || (opt1 && opt2 && opt1.Id == opt2.Id);
    }

    statuses_CompareFn(opt1: Status, opt2: Status) {
        return (!opt1 && !opt2) || (opt1 && opt2 && opt1.StatusId == opt2.StatusId);
    }

    isControlValid1(control: AbstractControl) {
        const flag = this.flag;
        let valid: boolean;
        valid = !(control.invalid && (control.dirty || control.touched || this.flag));
        return valid;
    }

    isValidControlMaxUnit(control: AbstractControl) {
        let valid: boolean;
        valid = !(control.invalid && (control.dirty || control.touched || this.flag) && this.maxUnitsAvailable.errors.min);
        return valid;
    }

    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }
    onBlurParent(focusNumbr) {
        this.parentFocus = focusNumbr;
    }
    getClassFocused(vlFocused) {
        if (this.parentFocus == vlFocused) {
            return "focused";
        } else {
            return "";
        }
    }
}