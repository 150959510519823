
import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FPAbstractComponent } from '@fp/components/base';
import {MatDialogConfig} from  '@angular/material/dialog';
import { CommonString } from '@fp/constant';
import { Facility,Service, Member, SearchPaginationRequest, DataResult, ResultModel } from '@fp/models';
import { FacilityService, MemberService, CommonService, MessageBox } from '@fp/services';
import {CustomMessageBox} from "@fp/helpers";
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-faciliator-facility-members-check-in',
  templateUrl: './faciliator-facility-members-check-in.component.html',
  styleUrls: ['./faciliator-facility-members-check-in.component.css']
})
export class FaciliatorFacilityMembersCheckInComponent extends FPAbstractComponent implements OnInit {
  displayedColumns: string[] = ['last_name', 'first_name', 'MemberStatus', 'MembershipStatus', 'MemberNumber','MemberId','PhotoLocation','actions'];
    dataSource: MatTableDataSource<Member>;
    userName = this.comsvc.GetUser();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('txtMembershipNumber') txtMembershipNumber: ElementRef;
    @ViewChild('txtFirstName') txtFirstName: ElementRef;
    @ViewChild('txtSurName') txtSurName: ElementRef;
    @ViewChild('txtDateOFBirth') txtDateOFBirth: ElementRef;
    @ViewChild('txtFacilityID') txtFacilityID: ElementRef;
    @ViewChild('txtServiceID') txtServiceID: ElementRef;
    parentFocus = 0;
    availableFacilities: Array<Facility> = [];
    availableServices: Array<Service> = [];
    availableFacilitiesCtrl: UntypedFormControl = new UntypedFormControl();
    availableServicesCtrl: UntypedFormControl = new UntypedFormControl();
    selectedFacilities: Facility;
    isSelectedFacility: boolean = true;
    requiresInput: boolean = false;
    facilityMembers: Array<Member> = [];
    facilityIds: string = '';
    commonString = CommonString;

    MembershipNumber: string = "";
    FirstName: string ="";
    SurName: string ="";
    DateOFBirth: Date;


    length: number;
    pageSize: number;
    pageSizeOptions: number[] = [10, 20, 50];


    paging: SearchPaginationRequest = new SearchPaginationRequest();

    constructor(
        injector: Injector,
        private service: FacilityService,
        private memberService: MemberService,
        private comsvc: CommonService) {
        super(injector);
        this.dataSource = new MatTableDataSource();
    }

    ngOnInit(): void {
        this.requiresInput = false;
        this.loadAvailableFacilities();
        this.availableServicesCtrl.disable();
    }

    //to identify the search type

    txtFilterFacilityMembers_KeyUp(e: KeyboardEvent) {
        if (e.keyCode === 13) {
            if(this.txtMembershipNumber.nativeElement.value.length > 0) {
                this.requiresInput = false;
                e.preventDefault();
                this.searchFacilityMembers();
            } else {
                this.requiresInput = true;
            }
        }
    }

    gotoSearchFacilityMembers() {
        this.searchFacilityMembers();
    }

    searchFacilityMembers() {
      this.paging.PageNumber = 1;
      if(this.MembershipNumber && this.DateOFBirth && (!this.FirstName && !this.SurName)){
        this.loadFacilityMembers();
      }
      else if((!this.MembershipNumber) && (this.FirstName && this.SurName && this.DateOFBirth)){
        //another api
        console.log("Hey iam 2");
        this.loadFacilityMembers();
      }
      else if((!this.MembershipNumber) && (this.FirstName && !this.SurName && !this.DateOFBirth)){
          this.showValidationDialogForFname();
      }
      else if((!this.MembershipNumber) && (!this.FirstName && this.SurName && !this.DateOFBirth)){
          this.showValidationDialogForSname();
      }
      else if((!this.MembershipNumber) && (!this.FirstName && !this.SurName && this.DateOFBirth)){
          this.showValidationDialogForDob();
      }
      else if((!this.MembershipNumber) && (!this.FirstName && this.SurName && this.DateOFBirth)){
          this.showValidationDialogForNotFirstName();
      }
      else if((!this.MembershipNumber) && (this.FirstName && !this.SurName && this.DateOFBirth)){
          this.showValidationDialogForNotSurname();
      }
      else if((!this.MembershipNumber) && (this.FirstName && this.SurName && !this.DateOFBirth)){
          this.showValidationDialogForNotDOB();
      }
      else if((this.MembershipNumber && !this.DateOFBirth) && (!this.FirstName && !this.SurName)){
          this.showDateOfBirth();
      }
      else if(!(this.FirstName && this.SurName && this.DateOFBirth && this.MembershipNumber)){
        this.showValidationDialogForEmpty();
      }
      else{
          this.showValidationDialog();
      }  
    }

    showValidationDialog(){
        MessageBox.ShowError(
            this.dialog,
            "Please use only one search at a time"
          ); 
    }

    showValidationDialogForEmpty(){
        MessageBox.ShowError(
            this.dialog,
            "Please enter member details to continue"
          ); 
    }

    showValidationDialogForFname(){
        MessageBox.ShowError(
            this.dialog,
            "Please enter surname and date of birth"
          ); 
    }
    showValidationDialogForSname(){
        MessageBox.ShowError(
            this.dialog,
            "Please enter firstname and date of birth"
          ); 
    }
    showValidationDialogForDob(){
        MessageBox.ShowError(
            this.dialog,
            "Please enter first name and surname"
          ); 
    }
    showValidationDialogForNotFirstName(){
      MessageBox.ShowError(
          this.dialog,
          "Please enter first name"
        ); 
    }
    showValidationDialogForNotSurname(){
      MessageBox.ShowError(
          this.dialog,
          "Please enter surname"
        ); 
    }
    showValidationDialogForNotDOB(){
      MessageBox.ShowError(
          this.dialog,
          "Please enter date of birth"
        ); 
    }
    showDateOfBirth(){
      MessageBox.ShowError(
          this.dialog,
          "Please enter Date of Birth"
        ); 
  }
    //API 1
    loadFacilityMembers() {
      console.log("hi");
      const valFaci = this.availableFacilitiesCtrl.value;
      this.paging.Params = [
          {
              Name: 'facility_ids',
              Value: valFaci == null ? this.facilityIds : valFaci.toString(),
          }
      ];
      if(this.MembershipNumber && this.DateOFBirth){
          console.log("m number");
          this.paging.Params.push({
              Name: 'name_or_id',
              Value: this.txtMembershipNumber.nativeElement.value
          });
          this.paging.Params.push({
            Name: 'date_of_birth',
            Value: formatDate(this.txtDateOFBirth.nativeElement.value, 'yyyy-MM-dd', 'en-US')
        });
      }
      else if(this.FirstName && this.SurName && this.DateOFBirth){
          console.log("other");
          this.paging.Params.push({
              Name: 'first_name',
              Value: this.txtFirstName.nativeElement.value
          });
          this.paging.Params.push({
              Name: 'sur_name',
              Value: this.txtSurName.nativeElement.value
          });
          this.paging.Params.push({
              Name: 'date_of_birth',
              Value: formatDate(this.txtDateOFBirth.nativeElement.value, 'yyyy-MM-dd', 'en-US')
          });
          console.log("Date of Birth"+formatDate(this.txtDateOFBirth.nativeElement.value, 'yyyy-MM-dd', 'en-US'));
      }
      this.paging.ViewColumns = [];
      this.Invoke(
          this.memberService.searchmembersformanaullycheckin(this.paging),
          {
              onSuccess: (result: any) => {
                  if (result.Success) {
                      this.facilityMembers = result.Data.Results;
                      this.dataSource.data = this.facilityMembers;
                      this.length = result.Data.TotalItem;
                  } else {
                      this.HandleResponseError(result);
                  }
              }
          }
      );
    }

    paginatorChange(event: PageEvent) {
        this.paging.PageNumber = event.pageIndex + 1;
        this.paging.PageSize = event.pageSize;
        this.loadFacilityMembers()
    }

    sortChange(sort: Sort) {
        const activeSort: string = sort.active.toLowerCase();
        this.paging.OrderBy = activeSort;
        this.paging.OrderByAsc = sort.direction === 'asc' ? false : true;
        this.loadFacilityMembers();
    }

    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }
    onBlurParent(focusNumbr) {
        this.parentFocus = focusNumbr;
    }
    getClassFocused(vlFocused) {
        if (this.parentFocus == vlFocused) {
            return "focused";
        } else {
            return "";
        }
    }
    loadAvailableFacilities() {
        const searchRequest: SearchPaginationRequest = {
            OrderBy: 'name',
            OrderByAsc: true,
            PageNumber: 0,
            PageSize: 0,
            Params: [
                {
                    Name: 'user',
                    Value: this.comsvc.GetUserObject().UserId
                }
            ]
        };
        this.Invoke(
            this.service.search(searchRequest),
            {
                onSuccess: (result: any) => {
                    const dectypted_data = this.comsvc.D_FP_ResponseData(result);
                    result = JSON.parse(dectypted_data);
                    if (result.Success) {
                        this.availableFacilities = result.Data.Results;
                        this.availableFacilities.forEach((element, index, array) => {
                            if (index === (array.length - 1)) {
                                this.facilityIds += element.FacilityId;
                            } else {
                                this.facilityIds += element.FacilityId + ',';
                            }
                        }, this);
                    } else {
                        this.HandleResponseError(result);
                    }
                }
            }
        );
    }

    loadAvailableServices(facilityid: number) {
        const searchRequest: SearchPaginationRequest = {
            OrderBy: 'name',
            OrderByAsc: true,
            PageNumber: 0,
            PageSize: 0,
            Params: [
                {
                  Name:'facilityid',
                  Value:facilityid.toString()  
                }
            ]
        };
        this.Invoke(
            this.service.searchServices(searchRequest),
            {
                onSuccess: (result: any) =>{
                    const dectypted_data = this.comsvc.D_FP_ResponseData(result);
                    result = JSON.parse(dectypted_data);
                    if (result.Success) {
                        this.availableServices = result.Data.Results;
                    }
                    else{
                        this.HandleResponseError(result);
                    }
                }
            }
        )
    }

    onFacilityChange(facilityid: number):void{
        if(facilityid){
            console.log("Facility ID is"+facilityid);
            this.loadAvailableServices(Number(facilityid));
            this.availableServicesCtrl.enable();
    }
}
    getStatus(status: String) {
        switch (status) {
            case 'Active':
                return 'text-success';
            case 'Suspended':
                return 'text-danger';
        }
    }

    public ClickManuallyCheckInButton(item) {
        const selectedFacilityID = this.txtFacilityID.nativeElement.value;
        const selectedServiceID = this.txtServiceID.nativeElement.value;
        console.log("Facility ID:"+selectedFacilityID);
        console.log("Service ID:"+selectedServiceID);
        const convertedFacilityID = parseInt(selectedFacilityID,10);
        const convertedServiceID = parseInt(selectedServiceID,10);
        const dob = new Date(item.DateOfBirth);
        const ageInMs = Date.now() - dob.getTime();
        const ageInYears = Math.floor(ageInMs/31557600000);
        console.log("user is"+this.userName);
        CustomMessageBox.Show(
            this.dialog,
            {
              title: "Manual Visit Entry",
              content:
                "<h6>Do you wish to register a manual entry visit?</h6> <br>" +
                "<img src='"+item.PhotoLocation+"' width='50px' height='50px'>" + ""+
                item.MemberNumber + "" +
                "|" + 
                item.FirstName + " " +item.LastName +
                "|" + "Age:" + " " +  ageInYears,
              buttons: [
                {
                  label: "NO",
                  dialogResult: "no",
                },
                {
                  label: "YES",
                  isPrimary: true,
                  dialogResult: "yes",
                },
              ],
            },
            {
              width: "450px",
              afterClosed: (r) => {
                if (r.result === "yes") {
                    console.log("User clicks yes");
                    const selectedFacility = this.availableFacilities.find(f=>f.FacilityId ===convertedFacilityID);
                    const params = {
                        MemberId: item.MemberId,
                        MemberNumber:item.MemberNumber,
                        FacilityID:convertedFacilityID,
                        ServiceID:convertedServiceID,
                        ModifyBy:this.userName,
                        AgeInYears: ageInYears,
                        LastName: item.LastName,
                        FirstName:item.FirstName,
                        Photo:item.PhotoLocation,
                        FacilityName:selectedFacilityID ? selectedFacility.Name : "All facilities"
                      };
                    this.ConfirmNewManualVisit(params);
                  };
                }
              },
          );
    }

   private ConfirmNewManualVisit(params) {
        
        this.Invoke(this.memberService.ManualVisitSubmit(params), {
            onSuccess: (res: DataResult<ResultModel>) => {
              if (res.Success) {
                console.log("success");
                this.SubmitVisitSuccessfully(params.AgeInYears,params.MemberNumber, params.FirstName, params.LastName,params.Photo,params.FacilityName);               
              } else {             
                this.SubmitVisitFailed(res.Message,params.MemberNumber,params.FirstName,params.LastName,params.Photo,params.AgeInYears);
                console.log("failed");
              }
            },
          });
    }

    private SubmitVisitSuccessfully(ageInYears, memberNumber, firstname, lastname, photo, facilityname) {
      console.log("print 3")
      const content = `
        <div style="max-width:400px;min-width:400px;display:flex;flex-direction:column;justify-content:center;align-items:center;text-align:center;">
          <img src="${photo}" width="70px" height="70px">
          <div>${memberNumber}</div>
          <div>${firstname} ${lastname}</div>
          <div>Age: ${ageInYears}</div>
          <div style="margin-top:10px;"><button id="close-button" style="background-color:#4CAF50;border-top-left-radius:8px;border-top-right-radius:8px;color:white;border:none;padding:10px;border-radius:5px;font-size:1.2em;"><i class="fas fa-check-circle" style="margin-right:5px;"></i>Access Granted</button></div>
          <div style="margin-top:10px;">Facility: ${facilityname}</div>
        </div>
      `;
      const dialog = CustomMessageBox.Show(
        this.dialog,
        {
          title: "Manual Visit Entry",
          content: content,
        },
        {
          width: "450px",
          afterClosed: (r) => {},
        }
      );
    }

      private SubmitVisitFailed(msg, memberNumber, firstname, lastname, photo, ageInYears) {
        console.log("print 1");
        const content = `
        <div style="max-width:400px;min-width:400px;display:flex;flex-direction:column;justify-content:center;align-items:center;text-align:center;">
            <img src="${photo}" width="70px" height="70px">
            <div>${memberNumber}</div>
            <div>${firstname} ${lastname}</div>
            <div>Age: ${ageInYears}</div>
            <div style="margin-top:10px;"><button id="close-button" style="background-color:#FF0000;border-top-left-radius:8px;border-top-right-radius:8px;color:#FFFFFF;border:none;padding:10px;border-radius:4px;font-size:1.2em;font-weight:bold;"><i class="fas fa-times-circle" style="margin-right:8px;"></i>Not Allowed</button></div>
            <div style="margin-top:10px;">Reason: ${msg}</div>
          </div>
        `;
        const dialog = CustomMessageBox.Show(
          this.dialog,
          {
            title: "Manual Visit Entry",
            content: content,
          },
          {
            width: "450px",
            afterClosed: (r) => {},
          }
        );
      }
}

