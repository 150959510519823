<div [formGroup]="form" #importantTemplate>
    <ng-template #requiredFieldMsgTmpl let-ctrl="control" let-ctrlName="fieldName"
        let-validateOnTouch="validateOnTouch || false">
        <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
            <small *ngIf="ctrl.errors['required']" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_REQUIRED, ctrlName)}}</small>
        </div>
    </ng-template>
    <div class="form-group row" id="access-card-required">
        <label for="chkAccCardReq" class="col-md-3 col-lg-2 col-form-label">Access Card Required</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="chkAccCardReq"
                        formControlName="IsAccessCard">
                    <label class="custom-control-label" for="chkAccCardReq">&nbsp;</label>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row" id="access-card-fee">
        <label for="txtAccCardFee" class="col-md-3 col-lg-2 col-form-label">Access Card Fee</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="input-group" [class.disabled]="getControl('AccessCardFee').disabled"
                    [ngClass]="getClassFocused(1)">
                    <span class="input-group-prepend">$</span>
                    <input type="text" min="0" class="form-control pl-0" id="txtAccCardFee" placeholder="0.00"
                        formControlName="AccessCardFee" maxlength="14" appCurrencyMask (blur)="onBlurParent(0)"
                        (focus)="onFocusParent(1)" #accessCardFee>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtSpcRls" class="col-md-3 col-lg-2 col-form-label">Software Package</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="txtSpcRls" formControlName="SoftwarePackage"
                    maxlength="1000">
            </div>
        </div>
    </div>
    <div class="form-group row" id="hours-of-operation">
        <label class="col-md-3 col-lg-2 col-form-label">Hours of Operation</label>
        <div class="col-sm-6 pt-2">
            <div class="d-flex flex-column w-100">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="chk24HourAccess" formControlName="Is24Hour">
                    <label class="custom-control-label" for="chk24HourAccess">24/7 Access</label>
                </div>
                <div class="custom-control custom-checkbox mt-2">
                    <input type="checkbox" class="custom-control-input" id="chkStaffedHours"
                        [checked]="StaffedHoursGrp.value != null" #chkStaffedHours
                        (change)="toggleStaffedHoursFormGroup($event.target.checked)"
                        [disabled]="form.get('FacilityStaffedHourId').disabled">
                    <label class="custom-control-label" for="chkStaffedHours">Staffed Hours</label>
                </div>
                <div class="py-1" formGroupName="FacilityStaffedHour" *ngIf="StaffedHoursGrp.value != null"
                    [style.display]="form.get('FacilityStaffedHourId').disabled ? 'none' : 'block'">
                    <div class="form-group row">
                        <label for="txtMonday" class="col-sm-3 d-flex align-items-center m-0">Monday</label>
                        <div class="col-sm-9">
                            <div class="d-flex align-items-center">
                                <div class="mr-1">From</div>
                                <div class="pr-2">
                                    <!-- <label for="" class="p-0 m-0">From</label> -->
                                    <ngb-timepicker formControlName="MondayFrom" [minuteStep]="15"
                                        [timeBoundary]="{max: monTo.model}"
                                        [class.is-invalid]="!isTimeCtrlValid('MondayFrom', 'MondayTo')" numberOnly
                                        #monFrom></ngb-timepicker>
                                </div>
                                <div class="mx-1">to</div>
                                <div class="pl-2">
                                    <!-- <label for="" class="p-0 m-0">To</label> -->
                                    <ngb-timepicker formControlName="MondayTo" [minuteStep]="15"
                                        [timeBoundary]="{min: monFrom.model}"
                                        [class.is-invalid]="!isTimeCtrlValid('MondayTo', 'MondayFrom')" numberOnly
                                        #monTo></ngb-timepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtTuesday" class="col-sm-3 d-flex align-items-center m-0">Tuesday</label>
                        <div class="col-sm-9">
                            <div class="d-flex align-items-center">
                                <div class="mr-1">From</div>
                                <div class="pr-2">
                                    <!-- <label for="" class="p-0 m-0">From</label> -->
                                    <ngb-timepicker formControlName="TuesdayFrom" [minuteStep]="15"
                                        [timeBoundary]="{max: tueTo.model}"
                                        [class.is-invalid]="!isTimeCtrlValid('TuesdayFrom', 'TuesdayTo')" numberOnly
                                        #tueFrom></ngb-timepicker>
                                </div>
                                <div class="mx-1">to</div>
                                <div class="pl-2">
                                    <!-- <label for="" class="p-0 m-0">To</label> -->
                                    <ngb-timepicker formControlName="TuesdayTo" [minuteStep]="15"
                                        [timeBoundary]="{min: tueFrom.model}"
                                        [class.is-invalid]="!isTimeCtrlValid('TuesdayTo', 'TuesdayFrom')" numberOnly
                                        #tueTo></ngb-timepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtWednesday" class="col-sm-3 d-flex align-items-center m-0">Wednesday</label>
                        <div class="col-sm-9">
                            <div class="d-flex align-items-center">
                                <div class="mr-1">From</div>
                                <div class="pr-2">
                                    <!-- <label for="" class="p-0 m-0">From</label> -->
                                    <ngb-timepicker formControlName="WednesdayFrom" [minuteStep]="15"
                                        [timeBoundary]="{max: wedTo.model}"
                                        [class.is-invalid]="!isTimeCtrlValid('WednesdayFrom', 'WednesdayTo')" numberOnly
                                        #wedFrom></ngb-timepicker>
                                </div>
                                <div class="mx-1">to</div>
                                <div class="pl-2">
                                    <!-- <label for="" class="p-0 m-0">To</label> -->
                                    <ngb-timepicker formControlName="WednesdayTo" [minuteStep]="15"
                                        [timeBoundary]="{min: wedFrom.model}"
                                        [class.is-invalid]="!isTimeCtrlValid('WednesdayTo', 'WednesdayFrom')" numberOnly
                                        #wedTo></ngb-timepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtThursday" class="col-sm-3 d-flex align-items-center m-0">Thursday</label>
                        <div class="col-sm-9">
                            <div class="d-flex align-items-center">
                                <div class="mr-1">From</div>
                                <div class="pr-2">
                                    <!-- <label for="" class="p-0 m-0">From</label> -->
                                    <ngb-timepicker formControlName="ThursdayFrom" [minuteStep]="15"
                                        [timeBoundary]="{max: thuTo.model}"
                                        [class.is-invalid]="!isTimeCtrlValid('ThursdayFrom', 'ThursdayTo')" numberOnly
                                        #thuFrom></ngb-timepicker>
                                </div>
                                <div class="mx-1">to</div>
                                <div class="pl-2">
                                    <!-- <label for="" class="p-0 m-0">To</label> -->
                                    <ngb-timepicker formControlName="ThursdayTo" [minuteStep]="15"
                                        [timeBoundary]="{min: thuFrom.model}"
                                        [class.is-invalid]="!isTimeCtrlValid('ThursdayTo', 'ThursdayFrom')" numberOnly
                                        #thuTo></ngb-timepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtFriday" class="col-sm-3 d-flex align-items-center m-0">Friday</label>
                        <div class="col-sm-9">
                            <div class="d-flex align-items-center">
                                <div class="mr-1">From</div>
                                <div class="pr-2">
                                    <!-- <label for="" class="p-0 m-0">From</label> -->
                                    <ngb-timepicker formControlName="FridayFrom" [minuteStep]="15"
                                        [timeBoundary]="{max: friTo.model}"
                                        [class.is-invalid]="!isTimeCtrlValid('FridayFrom', 'FridayTo')" numberOnly
                                        #friFrom></ngb-timepicker>
                                </div>
                                <div class="mx-1">to</div>
                                <div class="pl-2">
                                    <!-- <label for="" class="p-0 m-0">To</label> -->
                                    <ngb-timepicker formControlName="FridayTo" [minuteStep]="15"
                                        [timeBoundary]="{min: friFrom.model}"
                                        [class.is-invalid]="!isTimeCtrlValid('FridayTo', 'FridayFrom')" numberOnly
                                        #friTo></ngb-timepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtSaturday" class="col-sm-3 d-flex align-items-center m-0">Saturday</label>
                        <div class="col-sm-9">
                            <div class="d-flex align-items-center">
                                <div class="mr-1">From</div>
                                <div class="pr-2">
                                    <!-- <label for="" class="p-0 m-0">From</label> -->
                                    <ngb-timepicker formControlName="SaturdayFrom" [minuteStep]="15"
                                        [timeBoundary]="{max: satTo.model}"
                                        [class.is-invalid]="!isTimeCtrlValid('SaturdayFrom', 'SaturdayTo')" numberOnly
                                        #satFrom></ngb-timepicker>
                                </div>
                                <div class="mx-1">to</div>
                                <div class="pl-2">
                                    <!-- <label for="" class="p-0 m-0">To</label> -->
                                    <ngb-timepicker formControlName="SaturdayTo" [minuteStep]="15"
                                        [timeBoundary]="{min: satFrom.model}"
                                        [class.is-invalid]="!isTimeCtrlValid('SaturdayTo', 'SaturdayFrom')" numberOnly
                                        #satTo></ngb-timepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtSunday" class="col-sm-3 d-flex align-items-center m-0">Sunday</label>
                        <div class="col-sm-9">
                            <div class="d-flex align-items-center">
                                <div class="mr-1">From</div>
                                <div class="pr-2">
                                    <!-- <label for="" class="p-0 m-0">From</label> -->
                                    <ngb-timepicker formControlName="SundayFrom" [minuteStep]="15"
                                        [timeBoundary]="{max: sunTo.model}"
                                        [class.is-invalid]="!isTimeCtrlValid('SundayFrom', 'SundayTo')" numberOnly
                                        #sunFrom></ngb-timepicker>
                                </div>
                                <div class="mx-1">to</div>
                                <div class="pl-2">
                                    <!-- <label for="" class="p-0 m-0">To</label> -->
                                    <ngb-timepicker formControlName="SundayTo" [minuteStep]="15"
                                        [timeBoundary]="{min: sunFrom.model}"
                                        [class.is-invalid]="!isTimeCtrlValid('SundayTo', 'SundayFrom')" numberOnly
                                        #sunTo></ngb-timepicker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtSpcRls" class="col-md-3 col-lg-2 col-form-label">Visit De-duplication Parameter<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="txtSpcRls" formControlName="VisitDuplicationWindow"
                    placeholder="xxx Minutes" maxlength="1000">
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtAddInfo" class="col-md-3 col-lg-2 col-form-label"><b>Additional Information</b> (IMPORTANT: Anything in this section will be visible on the map)</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <textarea class="form-control" id="txtAddInfo" rows="3" formControlName="AdditionalInfo" maxlength="500" [class.is-invalid]="!isControlValid('AdditionalInfo', true)"></textarea>
                <ng-container
                    *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: getControl('AdditionalInfo'), fieldName: 'Additional Information', validateOnTouch: true}">
                </ng-container>
            </div>
        </div>
    </div>
</div>