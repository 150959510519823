import { Component, Injector, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService, MessageBox, SuburbService } from "@fp/services";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import {
  FPBaseComponent,
  IFPComponentData,
  FPFormBaseComponent,
} from "@fp/components/base";
import { DataResult } from "@fp/models/data-result";
import { MapFacilityData } from "@fp/models/map-facility-data";
import { CommonConstants } from "@fp/constant/common-constants";
import { FilterSearchResultPopupComponent } from "@fp/filter-search-result-popup/filter-search-result-popup.component";

@Component({
  selector: "app-google-map-page-list-view",
  templateUrl: "./google-map-page-list-view.component.html",
  styleUrls: ["./google-map-page-list-view.component.css"],
})
export class GoogleMapPageListViewComponent implements OnInit {

  @Input() mapDataSource: Array<MapFacilityData> = [];  

  constructor() {}

  ngOnInit(): void {}

  openCloseAdditionalInfo(id){       
    var AdditionalInfoObj = this.mapDataSource.find(item => item.FacilityId === id);    
    AdditionalInfoObj.IsShownAdditionalInfo = !AdditionalInfoObj.IsShownAdditionalInfo;    
  }
}
