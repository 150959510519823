import { Component, Injector } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonConstants } from '@fp/constant/common-constants';
import { City } from '@fp/models/city.model';
import { AddressService } from '@fp/services/address.service';
import { Subscription, empty, of as observableOf } from 'rxjs';
import { FacilityFormBase, FacilityFormMode, FPFormBaseComponent } from 'src/app/components/base';
import { CommonMessage, CommonString } from 'src/app/constant';
import { FPValidators, StringHelper } from 'src/app/helpers';
import { Address, Country, DataResult, Postcode, SearchFieldOperator, SearchPaginationCriteria, SearchPaginationResult, State, Suburb, SuburbPostcodeStateModel, SuburbSearchResultRecord } from 'src/app/models';
import { RegionService } from 'src/app/services/region.service';
import { SuburbService } from 'src/app/services/suburb.service';
import { catchError, skipWhile, debounceTime, switchMap } from 'rxjs/operators';
import { MessageBox } from '@fp/services/common-dialog.service';
import { DialogResult } from '@fp/components/common-dialog/common-dialog.component';
import { AddressLocation } from '@fp/models/address-location.model';
import { LocationService } from '@fp/services/location.service';
import { SearchForSuggestionsResult } from 'aws-sdk/clients/location';
import { FacilityDetailsComponent } from '../facility-details.component';
import { MessageService } from '@fp/services';

@Component({
    selector: 'app-facility-address-details',
    templateUrl: './facility-address-details.component.html',
    styleUrls: ['./facility-address-details.component.css'],
    providers: [
        FPFormBaseComponent.provideExisting(FacilityAddressDetailsComponent),
        FacilityFormBase.provideExisting(FacilityAddressDetailsComponent)]
})
export class FacilityAddressDetailsComponent extends FacilityFormBase {
    countries: Country[];
    private selectedCountry: Country = null;
    states: State[];
    private selectedState: State = null;
    postcodes: Postcode[] = [];
    private selectedPostcode: Postcode = null;
    suburbs: Suburb[];
    private suburbList: SuburbSearchResultRecord[];
    private selectedSuburb: Suburb;
    private searchSuburbBusyIndicator = {
        start: () => { this.SuburbCtrl.markAsPending(); },
        stop: () => { this.SuburbCtrl.updateValueAndValidity({ emitEvent: false }); }
    };
    private cityList: City[];
    isFromAustralia = true;
    isSearchingCity = false;
    citySearchResults: City[];
    selectedCityId = 0;
    selectedCity: City;
    private selectedAddress1: AddressLocation;
    addressList: any[];
    filteredAdressList: SearchForSuggestionsResult[];
    countriesId = CommonConstants.GET_COUNTRIES;

    CommonMessage = CommonMessage;
    CommonString = CommonString;
    StringHelper = StringHelper;
    subscription: Subscription;
    isValidateEnabled: boolean;

    get Addressline1Ctrl() { return <UntypedFormControl>this.form.get('Address.StreetAddress'); }
    get CountryCtrl() { return <UntypedFormControl>this.form.get('Country'); }
    get StateCtrl() { return <UntypedFormControl>this.form.get('State'); }
    get PostcodeCtrl() { return <UntypedFormControl>this.form.get('Postcode'); }
    get SuburbCtrl() { return <UntypedFormControl>this.form.get('Suburb'); }
    get CityCtrl() { return this.form.get('City'); }
    get LatitudeCtrl() { return this.form.get('Address.Latitude'); }
    get LongitudeCtrl() { return this.form.get('Address.Longitude'); }

    parentComponent: FacilityDetailsComponent;

    static getFormGroup(): UntypedFormGroup {
        const fb = new UntypedFormBuilder();
        return fb.group({
            Address: fb.group({
                AddressId: [0],
                SuburbId: [0],
                Pobox: [null],
                StreetAddress1: [null],
                StreetAddress: [null, Validators.required],
                StreetAddress2: [null],
                Latitude: [null, Validators.required],
                Longitude: [null, Validators.required],
                IsActive: [true]
            }),
            SuburbId: [0],
            Suburb: [null, [Validators.required, FPValidators.resolvableAutocompleteOption]],
            Postcode: [null, Validators.required],
            State: [],
            Country: [null, Validators.required],
            Region: [null, Validators.required],
            City: [null]
        });
    }

    constructor(injector: Injector, private suburbSvc: SuburbService, private locationservice: LocationService,
        private regionSvc: RegionService, private addressService: AddressService, private messageService: MessageService)  {
        super(injector);

        const _parent: FacilityDetailsComponent = this.injector.get<FacilityDetailsComponent>(FacilityDetailsComponent);
        this.parentComponent = _parent;
    }
    ngOnInit(){
        this.subscription = this.messageService.currentMessage.subscribe((message) => {
        this.isValidateEnabled = !message
    });}
    ngOnDestroy() {
        if (this.subscription) {
          this.subscription.unsubscribe(); 
        }
    }

    ngAfterViewInit() {
        this.OnLoad();
    }

    OnLoad() {

        this.isSearchingCity = true;
        this.addressService.getCityDetail().subscribe((res) => {
            this.cityList = res.Data;
            this.isSearchingCity = false;
        });

        this.countries = CommonConstants.GET_COUNTRIES.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
        this.commonSvc.getLocationData().subscribe(
            (res) => {
                if (res.Success) {
                    this.suburbList = res.Data.suburbs;
                }
            }
        );
        const prefetchedCountry = <Country>this.CountryCtrl.value;
        const defaultCountry = this.countries
            .find(country =>
                prefetchedCountry ?
                    prefetchedCountry.CountryId === country.CountryId :
                    country.Name === 'Australia');
        if (defaultCountry != null) {
            this.selectedCountry = defaultCountry;
            this.CountryCtrl.setValue(defaultCountry, { emitEvent: false });

            this.loadStatesByCountry(defaultCountry.CountryId, true);
        }        
    }

    private setFacilityPhoneNumberValidation(){
        if(this.CountryCtrl && this.CountryCtrl.value && this.CountryCtrl.value.CountryId
            && this.parentComponent && this.parentComponent.identificationDetails && this.isValidateEnabled){

            var country = CommonConstants.GET_COUNTRIES.find( x =>x.CountryId == this.CountryCtrl.value.CountryId);

            if(country.Name.toLocaleLowerCase() == this.CommonString.AUSTRALIA.toLocaleLowerCase()){
                this.parentComponent.identificationDetails.SetFacilityPhoneNumber(true);
                this.parentComponent.contactDetails.mobileNumber.SetMobileLenghtValidators(true);
            }
            else if(country.Name.toLocaleLowerCase() == this.CommonString.NEWZEALAND.toLocaleLowerCase()){
                this.parentComponent.identificationDetails.SetFacilityPhoneNumber(false);
                this.parentComponent.contactDetails.mobileNumber.SetMobileLenghtValidators(false);
            }
            else{
                this.parentComponent.identificationDetails.SetFacilityPhoneNumber(true);
                this.parentComponent.contactDetails.mobileNumber.SetMobileLenghtValidators(true);
            }
        }
    }


    LoadComplete() {
        let currentValue = '';
        setTimeout(() => {

            // if (this.data.model.Address) {
            //     var savedAddress = <SearchForSuggestionsResult>{
            //         Text: this.data.model.Address.StreetAddress1
            //     };
            //     this.Addressline1Ctrl.setValue({ ...savedAddress }, { emitEvent: false });
            // }

            if (!this.isFromAustralia) {
                this.CityCtrl.valueChanges.subscribe((value: City) => {
                    this.selectedCity = value;
                    this.SelectSuburbForBasedOnCity();
                })
            }

            this.CountryCtrl.valueChanges.subscribe((value: Country) => {
                if (!this.selectedCountry || !value || value.CountryId !== this.selectedCountry.CountryId) {
                    this.changeCountry(value);
                    this.setFacilityPhoneNumberValidation();
                }
            });

            this.StateCtrl.valueChanges.subscribe((value: State) => {
                if (!this.selectedState || !value || value.StateId !== this.selectedState.StateId) {
                    this.changeState(value);
                }
            });

            this.PostcodeCtrl.valueChanges.subscribe((value: Postcode) => {
                //this.changePostcode(value);
            });

            this.Addressline1Ctrl.valueChanges
                .pipe(
                    debounceTime(500),
                    switchMap(value => {
                        if (typeof value === 'string') {
                            this.selectedAddress1 = null;
                            this.filteredAdressList = [];

                            if (value.length > 2) {
                                return observableOf(value);
                            } else if (value.length === 0) {
                                return observableOf(null);
                            }
                        } else if (typeof value === 'object') {
                            return observableOf(value);
                        }
                        return empty();
                    }))
                .subscribe((value: string | SearchForSuggestionsResult) => {
                    if (value) {
                        if (typeof value === 'string') {
                            this.searchAddress(value);
                        } else if (typeof value === 'object') {
                            this.SelectAddress1(value);
                        }
                    }
                });

            if (!this.isFromAustralia) {
                this.CityCtrl.valueChanges.pipe(
                    skipWhile((value) => (value && typeof value === 'object')),
                    debounceTime(500),
                    catchError((e) => { throw e; })
                ).subscribe((value: string) => {
                    this.isSearchingCity = false;
                    if (currentValue != this.CityCtrl.value) {
                        currentValue = this.CityCtrl.value;
                        let cityList: City[] = this.cityList;
                        cityList = cityList.filter(x => x.Name.toLowerCase().trim().includes(this.CityCtrl.value?.toString().toLowerCase().trim()));
                        cityList.sort((a, b) => a.Name.localeCompare(b.Name));
                        let uniqueCities: City[] = [];
                        cityList.map(x => uniqueCities.filter(a => a.Name == x.Name).length > 0 ? null : uniqueCities.push(x));
                        this.citySearchResults = uniqueCities
                        this.changeDetectorRef.markForCheck();
                    }
                }, error => {
                    this.isSearchingCity = false;
                    MessageBox.ShowError(this.dialog, "Sorry, we're having trouble connecting. Let's try that again.")
                        .subscribe(res => {
                            if (res.result.toLowerCase() === DialogResult.Ok) {
                                window.location.reload();
                            }
                        });
                    this._logger.error(error);
                });
            }

            this.SuburbCtrl.valueChanges
                .pipe(
                    debounceTime(500),
                    switchMap(value => {
                        this.searchSuburbBusyIndicator.stop();
                        if (typeof value === 'string') {
                            // Clear previous results
                            this.selectedSuburb = null;
                            this.suburbs = [];

                            if (value.trim().length > 2) {
                                return observableOf(value);
                            } else if (value.trim().length === 0) {
                                return observableOf(null);
                            }
                        } else if (typeof value === 'object') {
                            return observableOf(value);
                        }
                        return empty();
                    }))
                .subscribe((value: string | Suburb) => {
                    if (value) {
                        if (typeof value === 'string') {
                            this.searchSuburbs(value);
                        } else if (typeof value === 'object') {
                            this.SelectSuburb(value);
                        }
                    }
                });

            this.setFacilityPhoneNumberValidation();
        });
        super.LoadComplete();
    }

   //async SelectAddress1(address1: SearchForSuggestionsResult) {
    async SelectAddress1(selectedAddressEvent: any) {
        if(selectedAddressEvent.option){
        var address1 = <SearchForSuggestionsResult>selectedAddressEvent.option.value;
        const country = this.isFromAustralia ? "AUS" : "NZL";
        const countryId = this.isFromAustralia ? this.countriesId[0].CountryId : this.countriesId[1].CountryId;
        const addressResult = await this.locationservice.getLocation(address1.Text, country);

        var resultAddress = addressResult.Results[0];

        if (this.isFromAustralia) {
            this.selectedAddress1 = new AddressLocation();
            this.selectedAddress1.Address1 = resultAddress.Place.Label;
            this.selectedAddress1.Suburb = new Suburb();
            this.selectedAddress1.Suburb.Name = resultAddress.Place.Neighborhood;
            this.selectedAddress1.Suburb.Postcode = new Postcode();
            this.selectedAddress1.Suburb.Postcode.Code = resultAddress.Place.PostalCode;
            this.selectedAddress1.Suburb.Postcode.State = new State();
            this.selectedAddress1.Suburb.Postcode.State.Name = resultAddress.Place.Region;
            this.selectedAddress1.Longitude = String(resultAddress.Place.Geometry?.Point[0].toFixed(10)),
                this.selectedAddress1.Latitude = String(resultAddress.Place.Geometry?.Point[1].toFixed(10))
        }
        else {
            this.selectedAddress1 = new AddressLocation();
            this.selectedAddress1.Address1 = resultAddress.Place.Label;
            this.selectedAddress1.City = resultAddress.Place.Municipality;
            this.selectedAddress1.Suburb = new Suburb();
            this.selectedAddress1.Suburb.Postcode = new Postcode();
            this.selectedAddress1.Suburb.Postcode.Code = resultAddress.Place.PostalCode;
            this.selectedAddress1.Suburb.Postcode.State = new State();
            this.selectedAddress1.Suburb.Postcode.State.Name = resultAddress.Place.Region;
            this.selectedAddress1.Longitude = String(resultAddress.Place.Geometry?.Point[0].toFixed(10)),
            this.selectedAddress1.Latitude = String(resultAddress.Place.Geometry?.Point[1].toFixed(10))
        }

        if(this.selectedAddress1 && this.selectedAddress1.Suburb && this.selectedAddress1.Suburb.Postcode && this.selectedAddress1.Suburb.Postcode.Code){
            if(this.selectedAddress1.Suburb.Postcode.Code.startsWith("0")){
                this.selectedAddress1.Suburb.Postcode.Code = resultAddress.Place.PostalCode.substring(1);
            }else{
                this.selectedAddress1.Suburb.Postcode.Code = resultAddress.Place.PostalCode;
            }
        }

        this.filteredAdressList = [];
        this.selectedSuburb = null;
        this.CityCtrl.setValue(null, { emitEvent: false });
        this.SuburbCtrl.setValue(null, { emitEvent: false });
        this.PostcodeCtrl.setValue(null, { emitEvent: false });
        this.LatitudeCtrl.setValue(null, { emitEvent: false });
        this.LongitudeCtrl.setValue(null, { emitEvent: false });
        this.form.get('Region').setValue(null, { emitEvent: false });
        this.StateCtrl.setValue(null, { emitEvent: false });

        //this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });
        var postcode;
        var selectedAddrSuburb: Suburb = null;
        var state;
        var selectedAddrCity: City; 
        if (this.selectedAddress1) {
            if (this.isFromAustralia) {
                if (this.selectedAddress1.Suburb && this.selectedAddress1.Suburb.Name && this.selectedAddress1.Suburb.Postcode && this.selectedAddress1.Suburb.Postcode.Code) {
                    selectedAddrSuburb = this.suburbList.filter(sub => sub.Name.toLowerCase().trim() === this.selectedAddress1.Suburb.Name.toLowerCase().trim() && sub.Country.Id == countryId && sub.Postcode.Name == this.selectedAddress1.Suburb.Postcode.Code)
                        .map(_item => <Suburb>{
                            SuburbId: _item.Id,
                            Name: _item.Name,
                            Postcode: {
                                Code: this.selectedAddress1.Suburb.Postcode.Code,
                                StateId: _item.State.Id,
                                State: {
                                    StateId: _item.State.Id,
                                    Name: _item.State.Name
                                },
                                RegionId: _item.Region ? _item.Region.Id : null,
                                Region: _item.Region ? {
                                    RegionId: _item.Region.Id,
                                    Name: _item.Region.Name
                                } : null
                            }
                        })[0];

                    if (selectedAddrSuburb) {
                        this.selectedSuburb = selectedAddrSuburb;
                        this.suburbs = [];
                        this.SuburbCtrl.setValue({ ...selectedAddrSuburb, Postcode: null }, { emitEvent: false });
                        this.form.get('SuburbId').setValue(selectedAddrSuburb ? selectedAddrSuburb.SuburbId : null);
                        this.form.get('Address').get('SuburbId').setValue(selectedAddrSuburb ? selectedAddrSuburb.SuburbId : null);
                        postcode = selectedAddrSuburb.Postcode;
                        this.form.get('Region').setValue(selectedAddrSuburb.Postcode ? selectedAddrSuburb.Postcode.Region : null, { emitEvent: false });
                        this.form.get('Region').markAsDirty();
                    }
                }

                if (postcode) {
                    this.selectedPostcode = { ...postcode, State: null, Region: null };
                    this.postcodes = [this.selectedPostcode];
                    this.PostcodeCtrl.setValue(this.selectedPostcode, { emitEvent: false });
    
                    state = this.states.find(s => postcode && s.Name === postcode.State.Name);
                    if (state) {
                        this.selectedState = state;
                        this.StateCtrl.setValue(this.selectedState, { emitEvent: false });
                        this.selectedCountry = this.countries.find(c => c.CountryId === state.CountryId);
                        this.CountryCtrl.setValue(this.selectedCountry, { emitEvent: false });
                        this.setFacilityPhoneNumberValidation();
                    }    
                }

                if(selectedAddrSuburb && postcode && state){
                    var ausAddrInfoArr = this.selectedAddress1.Address1.split(',');
                    ausAddrInfoArr = ausAddrInfoArr.map(s => s.trim());
                    var suburbIndex = ausAddrInfoArr.indexOf(this.selectedAddress1.Suburb.Name);
                    const removeFromArray = ausAddrInfoArr.slice(0, suburbIndex);
                    const joinFromArray = removeFromArray.join(', ');                   
                    var address1 = <SearchForSuggestionsResult>{
                                Text: joinFromArray
                    };
                    this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });                    
                  }else{
                    this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });
                  }
            }
            if (!this.isFromAustralia) {

                var addrInfoArr = this.selectedAddress1.Address1.split(',');
                addrInfoArr = addrInfoArr.map(s => s.trim());
                var suburbName = '';
                var cityIndex;

                if (this.selectedAddress1.City) {

                    var sameCityNameCount = addrInfoArr.filter(item => item == this.selectedAddress1.City.valueOf().trim()).length;
                   // var cityIndex;
                    if (sameCityNameCount > 1) {
                        cityIndex = addrInfoArr.indexOf(this.selectedAddress1.City.valueOf().trim(), addrInfoArr.indexOf(this.selectedAddress1.City.valueOf().trim()) + 1);
                    }
                    else {
                        cityIndex = addrInfoArr.findIndex(i => i.valueOf().toLowerCase().trim() == this.selectedAddress1.City.valueOf().toLowerCase().trim());
                    }

                    suburbName  = '';

                    suburbName = addrInfoArr[cityIndex - 1];

                    // if(addrInfoArr.length == 6){
                    //   suburbName = addrInfoArr[cityIndex - 1];
                    // }
                    //let selectedAddrSuburb: Suburb = null;

                    if(suburbName != '' && suburbName != undefined){
                            selectedAddrSuburb = this.suburbList.filter(sub => sub.Name.toLowerCase().trim() === suburbName.toLowerCase().trim() && sub.Country.Id == countryId)
                            .map(_item => <Suburb>{
                                SuburbId: _item.Id,
                                Name: _item.Name,
                                Postcode: {
                                    Code: this.selectedAddress1.Suburb.Postcode.Code,
                                    StateId: _item.State.Id,
                                    State: {
                                        StateId: _item.State.Id,
                                        Name: _item.State.Name
                                    },
                                    RegionId: _item.Region ? _item.Region.Id : null,
                                    Region: _item.Region ? {
                                        RegionId: _item.Region.Id,
                                        Name: _item.Region.Name
                                    } : null
                                }
                            })[0];

                            if (!selectedAddrSuburb){
                                selectedAddrSuburb = this.suburbList.filter(sub => sub.Name.toLowerCase().trim() === this.selectedAddress1.City.toLowerCase().trim() 
                                && sub.Postcode.Name == this.selectedAddress1.Suburb.Postcode.Code && sub.Country.Id == countryId)
                                .map(_item => <Suburb>{
                                    SuburbId: _item.Id,
                                    Name: _item.Name,
                                    Postcode: {
                                        Code: this.selectedAddress1.Suburb.Postcode.Code,
                                        StateId: _item.State.Id,
                                        State: {
                                            StateId: _item.State.Id,
                                            Name: _item.State.Name
                                        },
                                        RegionId: _item.Region ? _item.Region.Id : null,
                                        Region: _item.Region ? {
                                            RegionId: _item.Region.Id,
                                            Name: _item.Region.Name
                                        } : null
                                    }
                                })[0];
                            }
                        if (selectedAddrSuburb) {
                            this.selectedSuburb = selectedAddrSuburb;
                            this.suburbs = [];
                            this.SuburbCtrl.setValue({ ...selectedAddrSuburb, Postcode: null }, { emitEvent: false });
                            this.form.get('SuburbId').setValue(selectedAddrSuburb ? selectedAddrSuburb.SuburbId : null);
                            this.form.get('Address').get('SuburbId').setValue(selectedAddrSuburb ? selectedAddrSuburb.SuburbId : null);
                        }
                    }
                    else{
                        selectedAddrSuburb = this.suburbList.filter(sub => sub.Name.toLowerCase().trim() === this.selectedAddress1.City.toLowerCase().trim() 
                        && sub.Postcode.Name == this.selectedAddress1.Suburb.Postcode.Code && sub.Country.Id == countryId)
                            .map(_item => <Suburb>{
                                SuburbId: _item.Id,
                                Name: _item.Name,
                                Postcode: {
                                    Code: this.selectedAddress1.Suburb.Postcode.Code,
                                    StateId: _item.State.Id,
                                    State: {
                                        StateId: _item.State.Id,
                                        Name: _item.State.Name
                                    },
                                    RegionId: _item.Region ? _item.Region.Id : null,
                                    Region: _item.Region ? {
                                        RegionId: _item.Region.Id,
                                        Name: _item.Region.Name
                                    } : null
                                }
                            })[0];

                        if (selectedAddrSuburb) {
                            this.selectedSuburb = selectedAddrSuburb;
                            this.suburbs = [];
                            this.SuburbCtrl.setValue({ ...selectedAddrSuburb, Postcode: null }, { emitEvent: false });
                            this.form.get('SuburbId').setValue(selectedAddrSuburb ? selectedAddrSuburb.SuburbId : null);
                            this.form.get('Address').get('SuburbId').setValue(selectedAddrSuburb ? selectedAddrSuburb.SuburbId : null);
                        }
                    }
                    

                    selectedAddrCity = this.cityList.filter(x => x.Name.toLowerCase().trim().includes
                        (this.selectedAddress1.City.toLowerCase().trim()))
                        .map(_item => <City>{
                            CityId: _item.CityId,
                            Name: _item.Name,
                            IsActive: true,
                            PostCode: {
                                Code: this.selectedAddress1.Suburb.Postcode.Code,
                                StateId: 1,
                                State: {
                                    StateId: 1,
                                    Name: 'NZ'
                                }
                            }
                        })[0];

                    if (selectedAddrCity) {
                        this.selectedCity = selectedAddrCity;
                        this.citySearchResults = [];
                        this.CityCtrl.setValue({ ...selectedAddrCity, Postcode: null }, { emitEvent: false });
                    }

                    if (selectedAddrCity && selectedAddrCity.PostCode) {
                        postcode = selectedAddrCity.PostCode;
                    }

                    if (selectedAddrSuburb) {
                        this.form.get('Region').setValue(selectedAddrSuburb.Postcode ? selectedAddrSuburb.Postcode.Region : null, { emitEvent: false });
                        this.form.get('Region').markAsDirty();
                    }
                }

                if (postcode) {
                    this.selectedPostcode = { ...postcode, State: null, Region: null };
                    this.postcodes = [this.selectedPostcode];
                    this.PostcodeCtrl.setValue(this.selectedPostcode, { emitEvent: false });
    
                    state = this.states.find(s => postcode && s.Name === postcode.State.Name);
                    if (state) {
                        this.selectedState = state;
                        this.StateCtrl.setValue(this.selectedState, { emitEvent: false });
                        this.selectedCountry = this.countries.find(c => c.CountryId === state.CountryId);
                        this.CountryCtrl.setValue(this.selectedCountry, { emitEvent: false });
                        this.setFacilityPhoneNumberValidation();
                    }    
                }

                if (selectedAddrCity && selectedAddrSuburb && postcode && state) {         
                    if (suburbName != '' && suburbName != undefined) {
                        if (this.suburbList.filter(sub => sub.Name.toLowerCase().trim() === suburbName.toLowerCase().trim() && sub.Country.Id == countryId).length > 0) {                                          
                            const removeFromArray = addrInfoArr.slice(0, (cityIndex-1));
                            const joinFromArray = removeFromArray.join(', ');
                            var address1 = <SearchForSuggestionsResult>{
                                        Text: joinFromArray
                            };
                            this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });                                      
                        } else {                   
                            const removeFromArray = addrInfoArr.slice(0, cityIndex);
                            const joinFromArray = removeFromArray.join(', ');
                            var address1 = <SearchForSuggestionsResult>{
                                        Text: joinFromArray
                            };
                            this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });            
                        }
                    } else {                
                        const removeFromArray = addrInfoArr.slice(0, cityIndex);
                        const joinFromArray = removeFromArray.join(', ');
                        var address1 = <SearchForSuggestionsResult>{
                                    Text: joinFromArray
                        };
                        this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });                
                    }                                      
                } else {               
                    this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });                    
                }
            }           
          
            this.LatitudeCtrl.setValue(this.selectedAddress1.Latitude);
            this.LongitudeCtrl.setValue(this.selectedAddress1.Longitude);
        }
    }
    }

    SelectSuburbForBasedOnCity() {
        if(!this.isFromAustralia){
            if(this.selectedCity && this.selectedCity.Name){
                this.postcodes = this.cityList.filter(x => x.Name == this.selectedCity.Name).map(y => y.PostCode);
                this.PostcodeCtrl.setValue(this.cityList.filter(x => x.Name == this.selectedCity.Name).map(y => y.PostCode)[0], { emitEvent: false });
            }            
        }       
    }

    txtAddress_Blur(e) {
        if (this.Addressline1Ctrl.dirty) {
            this.Addressline1Ctrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
            this.Addressline1Ctrl.updateValueAndValidity({ emitEvent: false });
        }
    }

    /** @internal */
    txtAddress1_Input(e) {
        if (this.Addressline1Ctrl.dirty) {
            this.Addressline1Ctrl.setValidators(Validators.required);
        }
    }

    /** @internal */
    AutoComplete_FocusOut(e) {
        if (this.Addressline1Ctrl.value) {
            this.Addressline1Ctrl.setErrors(null);
            if (this?.filteredAdressList?.length == 0) {
                var address1 = <SearchForSuggestionsResult>{
                    Text: e.target.value
                };
                this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });
            }
        }
    }

    /** @internal */
    AutoCompleteAddress_DisplayWithFn(option: any) {
        if (!option) {
            return '';
        }
        return option.Text;
    }
    PatchValue(value, opts) {
        if (this.isFromAustralia) {
            this.SuburbCtrl.setValidators(Validators.required);
            this.CityCtrl.setValidators(null);
        }
        else {
            this.CityCtrl.setValidators(Validators.required);
        }

        const address = <Address>value['Address'];
        if (!address) {
            value['Address'] = new Address();
        }
        value['Address'].StreetAddress = <SearchForSuggestionsResult>{
            Text: value['Address'].StreetAddress1
        }

        this.selectedSuburb = <Suburb>value['Suburb'];
        this.selectedCity = <City>value['City'];

        const postcode = <Postcode>value['Postcode'];
        if (postcode) {
            postcode.Region = value['Region'];
            this.selectedPostcode = postcode;
            this.postcodes = [postcode];
        }

        const state = <State>value['State'];
        if (state) {
            this.selectedState = state;
            this.states = this.states || [state];
        }

        const country = <Country>value['Country'];
        if (country) {
            this.isFromAustralia = value?.Country?.CountryId === 1;
            this.loadStatesByCountry(value?.Country?.CountryId, false);

            this.selectedCountry = country;
            this.countries = this.countries || [country];
        }
        else {
            this.selectedCountry = this.countries.find(country => country.Name === 'Australia');
        }

        super.PatchValue(value, opts);
        this.LoadComplete();
    }


    public ddlEmployerCity_Change() {
        this.CheckCityValue();
    }

    public CheckCityValue() {
        if (this.CityCtrl !== null) {
            const val = this.CityCtrl.value;
            if (!val) {
                this.form.get('Employer').disable();
            }
        }
    }

    /** @internal */
    AutoComplete_DisplayWithFn(option: any) {
        if (!option) {
            return '';
        }
        return option.Name || option.Code;
    }

    /** @internal */
    ddlCountryOption_CompareFn(opt1: Country, opt2: Country) {
        return (opt1 && opt2) && opt1.CountryId === opt2.CountryId;
    }

    /** @internal */
    ddlStateOption_CompareFn(opt1: State, opt2: State) {
        return (!opt1 && !opt2) || (opt1 && opt2 && opt1.StateId === opt2.StateId);
    }

    /** @internal */
    ddlPostcodeOption_CompareFn(opt1: SuburbPostcodeStateModel, opt2: SuburbPostcodeStateModel) {
        return (!opt1 && !opt2) || (opt1 && opt2 && opt1.PostcodeId === opt2.PostcodeId);
    }

    /** @internal */
    txtSuburb_Blur(e) {
        if (this.SuburbCtrl.dirty) {
            this.SuburbCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
            this.SuburbCtrl.updateValueAndValidity({ emitEvent: false });
        }
    }

    /** @internal */
    txtSuburb_Input(e) {
        if (this.SuburbCtrl.dirty) {
            this.SuburbCtrl.setValidators(Validators.required);
        }
    }

    /** @internal */
    SelectCity(city: City) {
        this.citySearchResults = [];
        this.CityCtrl.markAsDirty();
        this.CityCtrl.markAsTouched();
    }

    /** @internal */
    txtCity_Blur(e) {
        if (this.CityCtrl.dirty) {
            this.CityCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
            this.CityCtrl.updateValueAndValidity({ emitEvent: false });
        }
    }

    /** @internal */
    txtCity_Input(e) {
        if (this.CityCtrl.dirty) {
            if (typeof this.CityCtrl.value === 'string' &&
                this.CityCtrl.value.length == 0 &&
                this.selectedCityId > 0) {
                this.SelectCity(null);
            }
            this.CityCtrl.setValidators(Validators.required);
        }
    }

    private changeCountry(country: Country) {
        this.selectedCountry = country;

        if (country.CountryId == 1) {
            this.isFromAustralia = true;
            this.CityCtrl.setValidators(null);
            this.CityCtrl.clearValidators();
            this.CityCtrl.updateValueAndValidity();
        }
        else {
            this.isFromAustralia = false;
            this.CityCtrl.setValidators(Validators.required);
            this.CityCtrl.updateValueAndValidity();
        }

        // Update upward
        
        this.Addressline1Ctrl.setValue(null, { emitEvent: false });
        this.Addressline1Ctrl.markAsDirty();
        this.LatitudeCtrl.setValue(null, { emitEvent: false });
        this.LatitudeCtrl.markAsDirty();
        this.LongitudeCtrl.setValue(null, { emitEvent: false });
        this.LongitudeCtrl.markAsDirty();
        this.CityCtrl.setValue(null, { emitEvent: false });
        this.CityCtrl.markAsDirty();
        this.StateCtrl.setValue(null, { emitEvent: false });
        if (this.selectedState) {
            this.StateCtrl.markAsDirty();
        }
        this.states = [];
        this.changeState(null);

        if (country) {
            this.loadStatesByCountry(country.CountryId);
        }
        this.LoadComplete();
    }

    private loadStatesByCountry(countryId: number, firstLoad = false) {
        this.states = CommonConstants.GET_STATES.filter(X => X.CountryId == countryId).sort((a, b) => a.StateId - b.StateId);
        if (firstLoad) {
            super.OnLoad();
        }
    }

    private changeState(state: State) {
        this.selectedState = state;

        // Update upward
        this.PostcodeCtrl.setValue(null, { emitEvent: false });
        if (this.selectedPostcode) {
            this.PostcodeCtrl.markAsDirty();
        }
        this.postcodes = [];
        this.changePostcode(null);
    }

    private changePostcode(postcode: Postcode) {
        this.selectedPostcode = postcode;
        this.form.get('Region').setValue(postcode ? postcode.Region : null, { emitEvent: false });
        this.form.get('Region').markAsDirty();

        // Update upward
        this.SuburbCtrl.setValue(null, { emitEvent: false });
        if (this.selectedSuburb) {
            this.SuburbCtrl.markAsDirty();
        }
        this.suburbs = [];
        this.selectedSuburb = null;
    }

    /** @internal */
    AutoCompleteCity_DisplayWithFn(option: any) {
        if (option) {
            if (option.Name) {
                const val = option.Name;
                return (val);
            } else {
                return option;
            }
        } else {
            return '';
        }
    }

    private async searchAddress(text: string) {
        const country = this.isFromAustralia ? "AUS" : "NZL";

        const addressResult = await this.locationservice.getLocationSuggestions(text, country);
        this.filteredAdressList = addressResult.Results.map(a => a);
    }

    private searchSuburbs(text: string) {
        const criteria = new SearchPaginationCriteria();
        criteria.PageSize = 0;
        criteria.ViewColumns = null;
        criteria.Criteria.Fields = [{ Name: 'name', Operator: SearchFieldOperator.Contains, Value: text }];

        let filterSuburb = this.suburbList.filter(x => x.Name.toLowerCase().trim().includes(text.toLowerCase().trim()) || x.Postcode.Name.includes(text.toLowerCase().trim()));
        this.suburbs = filterSuburb.map(_item => <Suburb>{
            SuburbId: _item.Id,
            Name: _item.Name,
            PostcodeId: _item.Postcode.Id,
            Postcode: {
                PostcodeId: _item.Postcode.Id,
                Code: _item.Postcode.Name,
                StateId: _item.State.Id,
                State: {
                    StateId: _item.State.Id,
                    Name: _item.State.Name
                },
                RegionId: _item.Region ? _item.Region.Id : null,
                Region: _item.Region ? {
                    RegionId: _item.Region.Id,
                    Name: _item.Region.Name
                } : null
            }
        });
        this.changeDetectorRef.markForCheck();


    }

    public getCountry(): Country {
        return this.selectedCountry;
    }

    SelectSuburb(suburb: Suburb) {
        this.selectedSuburb = suburb;
        this.suburbs = [];
        this.SuburbCtrl.setValue({ ...suburb, Postcode: null }, { emitEvent: false });
        this.form.get('SuburbId').setValue(suburb ? suburb.SuburbId : null);
        this.form.get('Address').get('SuburbId').setValue(suburb ? suburb.SuburbId : null);
        if (suburb) {
            // Update the postcode, state and country downward.
            const postcode = suburb.Postcode;
            this.selectedPostcode = { ...postcode, State: null };
            this.postcodes = [this.selectedPostcode];
            this.PostcodeCtrl.setValue(this.selectedPostcode, { emitEvent: false });

            const state = this.states.find(s => postcode && s.Name === postcode.State.Name);
            if (state) {
                this.selectedState = state;
                this.StateCtrl.setValue(this.selectedState, { emitEvent: false });

                this.selectedCountry = this.countries.find(c => c.CountryId === state.CountryId);
                this.CountryCtrl.setValue(this.selectedCountry, { emitEvent: false });
                this.setFacilityPhoneNumberValidation();
            }
            this.form.get('Region').setValue(suburb.Postcode ? suburb.Postcode.Region : null, { emitEvent: false });
            this.form.get('Region').markAsDirty();
        }
    }
}