import { Component, OnInit } from '@angular/core';
import { RouterConstant } from 'src/app/constant';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.css']
})
export class PackageComponent implements OnInit {

  RouterConstant = RouterConstant;
  constructor() { }

  ngOnInit() {
  }

}
