<div class="row facility-activity-wrapper" [formGroup]="form">
    <div formGroupName="Activities" class="row">
        <div class="facility-acttivity-items col-lg-8 col-sm-12" >
            <div class="colums" *ngFor="let activity of activityDetails">
                <div class="facility-acttivity-item">
                    <div class="facility-acttivity-item-icon">
                        <img src="./assets/images/facility-icon/{{ activity.LogoIconName }}.png"
                            alt="{{ activity.LogoIconName }}" />
                    </div>
                    <div class="facility-acttivity-item-checkbox">
                        <mat-checkbox type="checkbox" class="example-margin" [checked]="activity.IsCheckedActivity"
                            [value]="activity.Id" (change)="onCheckChange($event)">
                            {{ activity.Name }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="facility-acttivity-items col-lg-4 col-sm-12">
        <div class="colums" *ngFor="let activity of activityDetailsAccessOption">
                <div class="facility-acttivity-item">
                    <div class="facility-acttivity-item-icon">
                        <img src="./assets/images/facility-icon/{{ activity.LogoIconName }}.png"
                            alt="{{ activity.LogoIconName }}" />
                    </div>
                    <div class="facility-acttivity-item-checkbox">
                        <mat-checkbox type="checkbox" class="example-margin" [checked]="activity.IsCheckedActivity"
                            [value]="activity.Id" (change)="onCheckChange($event)">
                            {{ activity.Name }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isControlValid('Activities')">
    <small *ngIf="getControl('Activities').hasError('required')" class="invalid-feedback d-block">
        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Atleast one activity')}}</small>
</div>

<!-- ./assets/images/facility-icon/Activity_altitude.png -->