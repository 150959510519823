import { Component, Injector, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CommonConstants } from '@fp/constant/common-constants';
import { MemberFormBase } from 'src/app/components/base/member-form-base';
import { EMemberType } from 'src/app/enums';
import { Utils } from 'src/app/helpers';
import { MemberType } from 'src/app/models';
import { MemberFamilyMemberContactDetailsComponent } from '../family-member-contact-details/family-member-contact-details.component';
import { MemberFamilyMemberDetailsComponent } from '../family-member-details/family-member-details.component';
import { MemberMedicalQuestionsComponent } from '../medical-questions/medical-questions.component';
import { MemberPreferredFacilitiesComponent } from '../preferred-facilities/preferred-facilities.component';

@Component({
  selector: 'app-member-family-member-info',
  templateUrl: './family-member-info.component.html',
  styleUrls: ['./family-member-info.component.css'],
  providers: [
    { provide: MemberFormBase, useExisting: MemberFamilyMemberInfoComponent },
  ],
})
export class MemberFamilyMemberInfoComponent extends MemberFormBase {
  @ViewChild('memberDetails', { static: false })
  memberDetails: MemberFamilyMemberDetailsComponent;
  @ViewChild('contactDetails', { static: false })
  contactDetails: MemberFamilyMemberContactDetailsComponent;
  @ViewChild('medicalQuestions', { static: false })
  medicalQuestions: MemberMedicalQuestionsComponent;
  @ViewChild('preferredFacilities', { static: false })
  preferredFacilities: MemberPreferredFacilitiesComponent;

  isSignUp: boolean = false;
  hasInit: boolean = false;
  showPreferredFacilities: boolean = true;
  memPkgId: number;
  replaceMobileNumber: any;
  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      MemberDetails: MemberFamilyMemberDetailsComponent.getFormGroup(),
      ContactDetails: MemberFamilyMemberContactDetailsComponent.getFormGroup(),
      MedicalQuestionsGrp: MemberMedicalQuestionsComponent.getFormGroup(),
      PreferredFacilities: MemberPreferredFacilitiesComponent.getFormGroup(),
    });
  }

  get isFamilyMemberUnderAgeDependent(): boolean {
    const dob = <Date>this.getControl(['MemberDetails', 'DateOfBirth']).value;
    const memberType = <MemberType>(
      this.getControl(['MemberDetails', 'MemberType']).value
    );
    const isMemberUnder18Dependent =
      dob &&
      memberType &&
      Utils.calculateAge(dob) < CommonConstants.DEPENDANT_AGE &&
      memberType.MemberTypeId == EMemberType.Dependant;
    return isMemberUnder18Dependent;
  }

  constructor(injector: Injector) {
    super(injector);
  }

  ngAfterViewInit() {
    if (!this.hasInit) {
      this.hasInit = true;
      this.OnInit();
    }
  }

  OnInit() {
    this.preferredFacilities.signUp = this.isSignUp;
    this.preferredFacilities.membershipPackageId = this.memPkgId;
    this.preferredFacilities.OnLoad();
    this.LoadComplete();
  }

  LoadComplete() {
    setTimeout(() => {
      this.memberDetails
        .getControl('DateOfBirth')
        .valueChanges.subscribe((value: Date) => {
          if (this.memberDetails.getControl('DateOfBirth').valid) {
            this.contactDetails.mobileNumberRegistor.primarySelectDate = value;
            if (this.isUnderAgeDependent()) {
              this.applyPhoneAndEmailFromPrimary();
            } else {
              var mobile = this.form
                .get('ContactDetails')
                .get('MobileNumber').value;
              if (mobile) {
                this.contactDetails.mobileNumberRegistor.ValidateMobileNumber(
                  Utils.convertPhoneToInternationalFormatWithoutCountryCode(
                    mobile,
                  ),
                );
              }
            }
          }
        });

      this.memberDetails
        .getControl('MemberType')
        .valueChanges.subscribe((value: MemberType) => {
          this.contactDetails.mobileNumberRegistor.memberTypePass =
            this.memberDetails.getControl('MemberType');
          if (this.isUnderAgeDependent()) {
            this.applyPhoneAndEmailFromPrimary();
          } else {
            var mobile = this.form
              .get('ContactDetails')
              .get('MobileNumber').value;
            if (mobile) {
              this.contactDetails.mobileNumberRegistor.ValidateMobileNumber(
                Utils.convertPhoneToInternationalFormatWithoutCountryCode(
                  mobile,
                ),
              );
            }
          }
        });
    }, 10);
    super.LoadComplete();
  }

  PatchValue(value, opts) {
    this.memberDetails.PatchValue(value, opts);
    this.contactDetails.PatchValue(value, opts);
    this.medicalQuestions.PatchValue(value, opts);

    if (!this.isUnderAgeDependent()) {
      this.preferredFacilities.PatchValue(value, opts);
    }
  }

  private isUnderAgeDependent() {
    const dob = <Date>this.getControl(['MemberDetails', 'DateOfBirth']).value;
    const memberType = <MemberType>(
      this.getControl(['MemberDetails', 'MemberType']).value
    );
    var isMemberUnder18Dependent =
      dob &&
      memberType &&
      Utils.calculateAge(dob) < CommonConstants.DEPENDANT_AGE &&
      memberType.MemberTypeId == EMemberType.Dependant;

    if (isMemberUnder18Dependent) {
      this.showPreferredFacilities = false;
    } else {
      this.showPreferredFacilities = true;
    }

    setTimeout(() => {
      if (
        dob &&
        memberType &&
        this.showPreferredFacilities &&
        this.preferredFacilities &&
        !this.preferredFacilities.membershipPackageId
      ) {
        this.preferredFacilities.OnLoad();
      }
    }, 100);

    return isMemberUnder18Dependent;
  }

  private applyPhoneAndEmailFromPrimary() {
    if (this.data.model.PrimaryMember) {
      this.contactDetails.getControl('MobileNumber').setErrors(null);
      this.contactDetails.getControl('PrimaryEmail').setErrors(null);

      if (!this.getControl(['ContactDetails', 'PrimaryEmail']).value) {
        this.getControl(['ContactDetails', 'PrimaryEmail']).setValue(
          this.data.model.PrimaryMember.PrimaryEmail,
        );
        this.getControl(['ContactDetails', 'PrimaryEmail']).markAsDirty();
      }
      this.contactDetails.mobileNumberRegistor.SetValueFromMobileNo(
        this.data.model.PrimaryMember.MobileNumber,
        false,
      );
      this.getControl(['ContactDetails', 'MobileNumber']).setErrors(null);
      this.getControl(['ContactDetails', 'PrimaryEmail']).setErrors(null);
    }
  }
}
