import { Component, Injector } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FacilityFormBase, FacilityFormMode } from 'src/app/components/base';
import { CommonString } from 'src/app/constant/common-string';
import { RouterConstant } from 'src/app/constant/routerconstant';
import { DataResult, SearchPaginationRequest, SearchPaginationResult, Product, CommonResponse, Product1 } from 'src/app/models';
import { ProductService } from 'src/app/services/product.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FacilityProductDetailsDialogComponent } from './product-details-dialog/product-details-dialog.component';

@Component({
    selector: 'app-facility-products-list',
    templateUrl: './products-list.component.html',
    styleUrls: ['./products-list.component.css'],
    providers: [{ provide: FacilityFormBase, useExisting: FacilityProductsListComponent }]
})
export class FacilityProductsListComponent extends FacilityFormBase {

    dataSource: MatTableDataSource<any>;
    length: number;
    pageSize: number;
    pageSizeOptions: number[] = [10, 20, 50];
    paging: SearchPaginationRequest = new SearchPaginationRequest();
    displayedColumns: string[] = ['productname', 'status', 'producttype', 'fpproductcost', 'membersaleprice', 'gstapplicable', 'action'];
    products: ProductFE[] = [];

    // #region Constant references
    CommonString = CommonString;
    RouterConstant = RouterConstant;
    // #endregion Constant references

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            Products: [[]]
        });
    }

    constructor(injector: Injector,
        private productSvc: ProductService,
        private route: ActivatedRoute,
        private modalService: NgbModal
    ) { super(injector); }

    OnLoad() {
        if (this.data.mode === FacilityFormMode.Edit) {
            this.LoadProducts(true);
        } else {
            this.dataSource = new MatTableDataSource<any>();
            super.OnLoad();
        }
    }

    LoadComplete() {
        super.LoadComplete();
        setTimeout(() => {
            this.getControl('Products').valueChanges.subscribe(value => {
                this.dataSource.data = value;
            });
        });
    }


    //Author: Yen
    LoadProducts(firstLoad = false) {
        const id = parseInt(this.route.snapshot.params.id, 10);
        this.paging.PageNumber = 0;
        this.paging.PageSize = 0;
        this.paging.Params = [{ Name: 'facility_id', Value: id }];
        this.paging.ViewColumns = ['status', 'fp_product_cost', 'member_sale_price', 'service_type'];
        this.Invoke(
            this.productSvc.search(this.paging),
            {
                onSuccess: (result: DataResult<SearchPaginationResult<any>>) => {
                    if (result.Success) {
                        this.products = result.Data.Results;
                        this.dataSource = new MatTableDataSource<any>(result.Data.Results);
                        this.length = result.Data.TotalItem;
                    } else {
                        this.dataSource = new MatTableDataSource<any>();
                        this.HandleResponseError(result);
                    }
                    if (firstLoad) {
                        super.OnLoad();
                    }
                }
            });
    }

    sortChange(sort: Sort) {
        const active: string = sort.active.toLowerCase();
        const isOderByAsc: boolean = sort.direction === 'asc' ? false : true;
        this.paging.OrderBy = active;
        this.paging.OrderByAsc = isOderByAsc;
        this.LoadProducts();
    }

    openDetailsDialog(index?: number) {
        let model = new ProductFE();
        if (index >= 0) {
            if (this.products[index].flag == undefined) {
                this.products[index].flag = 3;
            }
            model = Object.assign({}, this.products[index]);
        } else {
            model.Id = 0;
        }

        const modalRef = this.modalService.open(FacilityProductDetailsDialogComponent, {
            size: 'lg',
            windowClass: 'fp-modal-window',
            backdrop: 'static',
            keyboard: false
        });
        (<FacilityProductDetailsDialogComponent>modalRef.componentInstance).data.model = model;

        modalRef.result.then(
            (result: { returnValue: ProductFE }) => {
                if (result && result.returnValue) {
                    let product: ProductFE = Object.assign({}, result.returnValue);
                    product.flag = 2;
                    if (index >= 0) {
                        this.products[index] = product;
                    } else {
                        this.products.push(product);
                    }
                    this.dataSource = new MatTableDataSource<any>(this.products);
                    this.products.forEach(p => {
                        p.ProductStatusId = p.Status.StatusId;
                        p.ServiceTypeId = p.ServiceType.Id;
                    })
                    this.form.get('Products').setValue(this.products);

                }
            },
            reason => { }
        );
    }
}

export class ProductFE extends Product {
    flag?: number;
}

export enum EFlag {
    add = 1,
    edit = 2,
    load = 3
}