<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">View/Edit Regions</h4>
<div class="mt-lg-4">
    <div class="mb-4">
        <div class="border-default rounded-10 border-2 p-2">
            <div class="tablecontainer">
                <table mat-table [dataSource]="dataSource" matSort matSortActive="Name" matSortDirection="desc"
                    matSortStart="desc" matSortDisableClear (matSortChange)="sortChange($event)">
                    <!-- Name Column -->
                    <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Region Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                    </ng-container>

                    <!-- Country Column -->
                    <ng-container matColumnDef="Country">
                        <th mat-header-cell *matHeaderCellDef> Country </th>
                        <td mat-cell *matCellDef="let element"> {{element.Country?.Name}} </td>
                    </ng-container>

                    <!-- State Column -->
                    <ng-container matColumnDef="State">
                        <th mat-header-cell *matHeaderCellDef> State </th>
                        <td mat-cell *matCellDef="let element"> {{element.State?.Name}} </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{'text-success': element.IsActive, 'text-muted': !element.IsActive}">
                            {{element.IsActive ? CommonString.STATUS_TEXT_ACTIVE : CommonString.STATUS_TEXT_INACTIVE}}
                        </td>
                    </ng-container>

                    <!-- Edit Column -->
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">
                            <a class="px-2" href="#" [routerLink]="[RouterConstant.NAVIGATOR_REGION_EDIT, element.RegionId]">
                                <i class="fas fa-pen"></i>
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <mat-toolbar [hidden]="dataSource == null" class="bg-white">
                <mat-toolbar-row [hidden]="dataSource != null && dataSource.data.length == 0" class="p-0">
                    <mat-paginator class="w-100" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                        (page)="paginatorChange($event)" showFirstLastButtons>
                    </mat-paginator>
                </mat-toolbar-row>
                <mat-toolbar-row *ngIf="dataSource != null && dataSource.data.length == 0" class="text-muted d-block p-2 bg-light text-center">
                    {{CommonString.TABLE_NO_RECORD_FOUND}}
                </mat-toolbar-row>
            </mat-toolbar>
        </div>
    </div>
    <button class="px-5 py-2 btn bg-white border-primary text-primary" type="button" (click)="this.router.navigate([RouterConstant.NAVIGATOR_REGION_SEARCH]);"><i
            class="fas fa-arrow-alt-circle-left mr-2"></i>BACK</button>
</div>