import { Component, Injector, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FacilityFormBase } from 'src/app/components/base';
import { Country, PaymentMethod } from 'src/app/models';
import { FacilityBankAccountDetailsComponent } from './facility-bank-account-details/facility-bank-account-details.component';
import { FacilityPaymentDetailsComponent } from './facility-payment-details/facility-payment-details.component';
import { FacilityBpayDetailsComponent } from './facility-bpay-details/facility-bpay-details.component';
import { FacilityChequeDetailsComponent } from './cheque-details/cheque-details.component';

@Component({
    selector: 'app-facility-payment',
    templateUrl: './facility-payment.component.html',
    styleUrls: ['./facility-payment.component.css'],
    providers: [{ provide: FacilityFormBase, useExisting: FacilityPaymentComponent }]
})
export class FacilityPaymentComponent extends FacilityFormBase {
    @ViewChild('paymentDetails') private paymentDetails: FacilityBankAccountDetailsComponent;
    @ViewChild('bankAccountDetails') private bankAccountDetails: FacilityBankAccountDetailsComponent;
    @ViewChild('bPayDetails') private bPayDetails: FacilityBpayDetailsComponent;
    @ViewChild('chequeDetails') private chequeDetails: FacilityChequeDetailsComponent;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            PaymentDetails: FacilityPaymentDetailsComponent.getFormGroup(),
            BankAccountDetails: FacilityBankAccountDetailsComponent.getFormGroup(),
            BPayDetails: FacilityBpayDetailsComponent.getFormGroup(),
            ChequeDetails: FacilityChequeDetailsComponent.getFormGroup(),
        });
    }

    constructor(injector: Injector) { super(injector); }

    onPaymentMethodChange(paymentMethod: PaymentMethod) {
        this.bankAccountDetails.ToggleBankAccountFormGroup(paymentMethod && paymentMethod.Name.toLowerCase() === 'eft');
        this.bPayDetails.ToggleBPayDetailsFormGroup(paymentMethod && paymentMethod.Name.toLowerCase() === 'bpay');
        this.chequeDetails.ToggleChequeDetailsFormGroup(paymentMethod && paymentMethod.Name.toLowerCase() === 'cheque');
    }

    setFacilityCountry(country: Country) {
        this.bankAccountDetails.setFacilityCountry(country);
    }
}
