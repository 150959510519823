import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { CommonService } from "@fp/services";

const EXIF = require("exif-js");

@Component({
  selector: "app-member-image",
  templateUrl: "./member-image.component.html",
  styleUrls: ["./member-image.component.css"],
})
export class MemberImageComponent implements OnInit {
  @ViewChild("memberImage") memberImage: ElementRef;
  @Input() imgSrc: string;
  @Input() htmlClass: string;

  imageClass = "";
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private commonSvc: CommonService
  ) { }

  ngOnInit() {
    if (this.imgSrc) {
      let imgCfUrl = this.imgSrc.replace(
        environment.imageS3Url,
        environment.imageCFUrl
      );

      this.commonSvc.getImageBlobForMemberPhoto(imgCfUrl).subscribe((blob) => {
        this.loadExifData(blob);
      });
    }
  }

  private loadExifData(data: Blob) {
    // console.log("skip?:", !this.commonSvc.App.isMemberSignup);
    // guard against client side rotation if not sign up
    if (!this.commonSvc.App.isMemberSignup) return;

    let componentRef = this;
    EXIF.getData(data, function() {
      // in portrait mode for phones the default orientation value is `6`
      // `6` is basis for class rotations below
      let orientation: number = EXIF.getTag(this, "Orientation");
      console.log("Exif data loaded, Orientation:", orientation);
      // CSS image rotation doesn't modify image actual dimensions or metadata
      // retrieved image will display in default dimensions unless same `switch`
      // statement is applied to the image render component throughout
      // TODO: (?) show error if image is not portrait to force user correct orientation
      switch (orientation) {
        case 1:
          // landscape left
          componentRef.setImageClass("rotate-90");
        case 2:
          componentRef.setImageClass("flip-hor");
          componentRef.setImageClass("rotate-90");
          break;
        case 3:
          // landscape right
          componentRef.setImageClass("rotate-90l");
          break;
        case 4:
          componentRef.setImageClass("flip-hor");
          componentRef.setImageClass("rotate-90l");
          break;
        case 5:
          componentRef.setImageClass("flip-vert");
          break;
        case 6:
          // portrait
          break;
        case 7:
          componentRef.setImageClass("flip-vert");
          break;
        case 8:
          // upsidedown portrait
          break;
        default:
          componentRef.removeImageClass();
          break;
      }
    });
  }

  private setImageClass(imageClass: string) {
    this.imageClass = imageClass;
    this.changeDetectorRef.detectChanges();
  }

  private removeImageClass() {
    this.memberImage.nativeElement.classList.remove("flip-hor");
    this.memberImage.nativeElement.classList.remove("rotate-180l");
    this.memberImage.nativeElement.classList.remove("flip-vert");
    this.memberImage.nativeElement.classList.remove("flip-vert-rotate-90");
    this.memberImage.nativeElement.classList.remove("rotate-90");
    this.memberImage.nativeElement.classList.remove("flip-hor-rotate-90");
    this.memberImage.nativeElement.classList.remove("rotate-90l");
    this.changeDetectorRef.detectChanges();
  }
}