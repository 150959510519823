import { Component, OnInit } from '@angular/core';
import { RouterConstant } from 'src/app/constant';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-et-complete',
  templateUrl: './et-complete.component.html',
  styleUrls: ['./et-complete.component.css']
})
export class EtCompleteComponent implements OnInit {

  constructor(private router: Router,
    private service: CommonService) { }

  ngOnInit() {
  }

  ReturnHome() {
    this.router.navigate([RouterConstant.NAVIGATOR_MEMBER_DETAILS]);
    this.service.App.clickItem(9);
  }

}
