<div class="row" [formGroup]="form">
    <ng-template #requiredFieldMsgTmpl let-ctrl="control" let-ctrlName="fieldName"
        let-validateOnTouch="validateOnTouch || false">
        <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
            <small *ngIf="ctrl.getError('required')" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_REQUIRED, ctrlName)}}</small>
            <small *ngIf="ctrl.getError('pattern')"  class="invalid-feedback d-block">This field does not accept special
                characters. Please
                try again</small>
        </div>
    </ng-template>
    <div class="col-md-7">
        <div class="form-group row">
            <label for="txtFamFstName" class="col-md-4 col-form-label">First Name
                <span class="text-danger">*</span>
            </label>
            <div class="col-md-8">
                <div class="d-flex flex-column w-100">
                    <input type="text" class="form-control" [class.is-invalid]="!isControlValid('FirstName')"
                        id="txtFamFstName" maxlength="50" formControlName="FirstName" [readonly]='!IsAdmin()'
                        autocomplete="nope">
                    <ng-container
                        *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('FirstName'), fieldName: 'First Name'}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="txtFamLstName" class="col-md-4 col-form-label">Last Name
                <span class="text-danger">*</span>
            </label>
            <div class="col-md-8">
                <div class="d-flex flex-column w-100">
                    <input type="text" class="form-control" [class.is-invalid]="!isControlValid('LastName')"
                        id="txtFamLstName" maxlength="50" formControlName="LastName" [readonly]='!IsAdmin()'
                        autocomplete="nope">
                    <ng-container
                        *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('LastName'), fieldName: 'Last Name'}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="ddlFamGender" class="col-md-4 col-form-label">Gender
                <span class="text-danger">*</span>
            </label>
            <div class="col-md-8">
                <div class="d-flex flex-column w-100">
                    <select class="form-control" [class.is-invalid]="!isControlValid('GenderId', true)"
                        id="ddlFamGender" formControlName="GenderId">
                        <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                        <option *ngFor="let gender of genders" [ngValue]="gender.GenderId">{{gender.Name}}</option>
                    </select>
                    <ng-container
                        *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('GenderId'), fieldName: 'Gender', validateOnTouch: true}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-4 col-form-label">Date of Birth
                <span class="text-danger">*</span>
            </label>
            <div class="col-md-8">
                <div class="d-flex flex-column w-100">
                    <div class="input-group date-input-group" [class.is-invalid]="!isControlValid('DateOfBirth')"
                        [ngClass]="getClassFocused(1)">
                        <input class="form-control" placeholder="DD-MM-YYYY" name="dpDOB" ngbDatepicker
                            #dpDOB="ngbDatepicker" formControlName="DateOfBirth" (blur)="onBlurParent(0)"
                            (focus)="onFocusParent(1)">
                        <div class="input-group-append">
                            <button class="btn bg-white" (click)="dpDOB.toggle()" type="button">
                                <i class="far fa-calendar-alt"></i>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!isControlValid('DateOfBirth')">
                        <small *ngIf="form.get('DateOfBirth').errors['required']" class="invalid-feedback d-block">
                            {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Date of Birth')}}</small>
                        <div *ngIf="form.get('DateOfBirth').getError('ngbDate')">
                            <small *ngIf="!form.get('DateOfBirth').getError('ngbDate').requiredBefore"
                                class="invalid-feedback d-block">
                                {{StringHelper.format(CommonString.FIELD_INVALID, 'Date of Birth')}}</small>
                            <small *ngIf="form.get('DateOfBirth').getError('ngbDate').requiredBefore"
                                class="invalid-feedback d-block">
                                Error - Dependant does not meet the age criteria</small>
                            <small *ngIf="isPartner && form.get('DateOfBirth').getError('ngbDate').requiredAfter"
                                class="invalid-feedback d-block">
                                Error - A partner must be over 16 years old</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="ddlFamMemType" class="col-md-4 col-form-label">Member Type
                <span class="text-danger">*</span>
            </label>
            <div class="col-md-8">
                <div class="d-flex flex-column w-100">
                    <select class="form-control" [class.is-invalid]="!isControlValid(MemberTypeCtrl, true)"
                        id="ddlFamMemType" formControlName="MemberType" [compareWith]="ddlMemberTypeOption_CompareFn">
                        <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                        <option *ngFor="let type of memberTypes" [ngValue]="type">{{type.Name}}</option>
                    </select>
                    <ng-container
                        *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: MemberTypeCtrl, fieldName: 'Member Type', validateOnTouch: true}">
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-5">
        <div class="fp-profile d-flex justify-content-center">
            <div class="w-230px">
                <div *ngIf="form.get('Photo').value == null || form.get('Photo').value?.Location == null || form.get('Photo').value?.Location == ''; else hasImage"
                    class="bg-secondary fp-profilephoto d-flex justify-content-center align-items-center mx-auto">
                    <i class="fas fa-user-tie fa-2x text-secondary"></i>
                </div>
                <ng-template #hasImage>
                    <div class="container">
                        <app-member-image [imgSrc]="this.imgUrl? this.imgUrl : form.get('Photo').value?.Location"
                            [htmlClass]="'img-fluid'">
                        </app-member-image>
                    </div>
                </ng-template>
                <div class="custom-file mt-2" #divPhotoUploader>
                    <div>
                        <button class="custom-file-input" (click)="checkFirst($event)"
                         #fpfileupload style="height: 100% !important"></button>
                        <label class="custom-file-label text-light" [ngStyle]="{ 'background-color': '#FF0000' }"><i
                                class="fas fa-upload mr-2"></i>Upload Profile Photo</label>
                    </div>
                    <!-- <app-fp-file-upload [memberPhoto]="true" buttonlabel="Upload Profile Photo"
                        [buttonStyle]="{'background-color': '#FF0000'}" filterFile=".png, .jpg, .jpeg"
                        (UploadCompleted)="_fileUploaded($event)" (fileSelected)="_fileSelected($event)"
                        maxfilesize="10" ngInputFile="custom-file w-100" #fileUploader>
                    </app-fp-file-upload> -->
                </div>
                <p class="pt-2 m-0 text-center">{{CommonMessage.PROFILE_PHOTO_UPLOAD_CAPTION}}</p>
            </div>
        </div>
    </div>
</div>