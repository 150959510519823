<div class="container-custom">
  <amplify-authenticator
    [loginMechanisms]="['username']"
    [services]="_authServices"
    [formFields]="_authFormFields"
  >
    <ng-template amplifySlot="header">
      <div style="padding: var(--amplify-space-large); text-align: center">
        <img
          class="amplify-image"
          src="./assets/images/logo.png"
          alt="Fitness Passport"
        />
      </div>
    </ng-template>
    <ng-template amplifySlot="authenticated">
      <div class="loading-shade-global">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
    </ng-template>

    <ng-template amplifySlot="sign-in-header">
      <app-amplify-authenticator-account-created-message></app-amplify-authenticator-account-created-message>
    </ng-template>
    <ng-template amplifySlot="confirm-sign-in-header">
      <h1 class="amplify-heading amplify-authenticator__heading ng-star-inserted">
        Verify
        {{
           authService.nextSignInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE'
             ? 'mobile phone code'
             : authService.nextSignInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
             ? 'your code'
             : 'email code'
         }}
      </h1>
      <div class="amplify-authenticator__subtitle" [innerHTML]="
        authService.nextSignInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
        ? 'A verification code has been sent to your preferred Multi-Factor Authentication. Please check your email or mobile.'
        : 'A verification code has been sent to your ' +
        (authService.nextSignInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE' ? 'phone' : 'email') +
        '.<br />Enter the code to continue.'
        "></div>
    </ng-template>

    <ng-template amplifySlot="confirm-reset-password-header">
      <h1 class="amplify-heading amplify-heading--3">Reset Password</h1>
      <div class="amplify-authenticator__subtitle">
        {{ resetPasswordDestinationInfo }}<br />Enter the code to reset your
        password
      </div>
    </ng-template>

    <ng-template amplifySlot="confirm-reset-password-footer">
      <a
        class="request-support-link"
        href="{{ commonService.freshdeskNewSupportRequestUrl }}"
        >Didn't receive a code? Request Support</a
      >
    </ng-template>

    <ng-template amplifySlot="confirm-sign-in-footer">
      <a
        class="request-support-link"
        href="{{ commonService.freshdeskNewSupportRequestUrl }}"
        >Didn't receive a code? Request Support</a
      >
    </ng-template>

    <ng-template amplifySlot="sign-in-footer">
      <a
        href="javascript:void(0)"
        class="forgot-password-link"
        (click)="forgotPassword()"
        >Forgot password?</a
      >
      <hr data-content="OR" class="hr-text" />
      <div class="form-group">
        <a
          href="javascript:void(0)"
          class="btn btn-block active-directory-button"
          (click)="signInWithAzureAD()"
        >
          Fitness Passport Staff login
        </a>
        <div class="d-flex justify-content-center">
          <mat-spinner
            *ngIf="isFederatedUserLoginLoading"
            [diameter]="25"
          ></mat-spinner>
        </div>
      </div>
    </ng-template>

    <!-- Sign up -->
    <ng-template
      amplifySlot="sign-up-form-fields"
      let-validationErrors="validationErrors"
    >
      <!-- Re-use "zero configuration" `Authenticator.SignUp.FormFields` -->
      <amplify-sign-up-form-fields></amplify-sign-up-form-fields>
      <label class="amplify-label" for="confirm_mobile_number"> Confirm Mobile Number
        <i class="icon-fp fas fa-question-circle"
          tooltip="Your mobile number will be used to verify your account. Please check that you have entered the same valid number before creating an account."></i>
      </label>
      <div class="amplify-flex amplify-field-group amplify-field-group--horizontal">
        <div class="amplify-field-group__outer-start">
          <div class="amplify-flex amplify-field amplify-selectfield amplify-dialcodeselect" style="flex-direction: column;">
            <amplify-form-select name="country_code" label="Country Code"><label class="amplify-label amplify-visually-hidden"
                for="amplify-field-z2UhBxaUa_vH">Country Code</label>
              <div class="amplify-select__wrapper"><select disabled autocomplete="tel-country-code"
                  class="amplify-select amplify-field-group__control" name="country_code">
                  <option> {{countryCode}} </option>
                </select>
                <div class="amplify-flex amplify-select__icon" style="align-items: center; justify-content: center;"><svg
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-size="large" fill="currentColor"
                    class="amplify-icon">
                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                  </svg></div>
              </div>
            </amplify-form-select>
          </div>
        </div>
        <div class="amplify-field-group__field-wrapper amplify-field-group__field-wrapper--horizontal">
          <amplify-text-field [required]="true" name="confirm_phone_number" type="tel" placeholder="Confirm your phone number"
            [errorMessage]="signupErrors?.['confirm_phone_number']"
            [hasError]="!!signupErrors?.['confirm_phone_number']"></amplify-text-field>
        </div>
        </div>
      <div data-amplify-sign-up-errors *ngIf="!!signupErrors?.['confirm_phone_number']">
        <div class="amplify-text amplify-text--error" data-variation="error">
          {{ signupErrors?.['confirm_phone_number'] }}
        </div>
      </div>

    </ng-template>

    <ng-template amplifySlot="sign-up-footer">
      <app-signup-marketing-disclaimer
        clickActionText="Create Account"
      ></app-signup-marketing-disclaimer>
    </ng-template>
  </amplify-authenticator>
</div>
