import { Component, Injector } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FacilityFormBase, FacilityFormMode } from 'src/app/components/base';
import { CommonString } from 'src/app/constant/common-string';
import { RouterConstant } from 'src/app/constant/routerconstant';
import { DataResult, SearchPaginationRequest, SearchPaginationResult, ServiceDetails } from 'src/app/models';
import { ServiceService } from 'src/app/services/service.service';
import { FacilityServiceDetailsDialogComponent } from './service-details-dialog/service-details-dialog.component';
import { FacilityServiceFormMode } from './service-form-base';

@Component({
    selector: 'app-facility-services-list',
    templateUrl: './services-list.component.html',
    styleUrls: ['./services-list.component.css'],
    providers: [{ provide: FacilityFormBase, useExisting: FacilityServicesListComponent }]
})
export class FacilityServicesListComponent extends FacilityFormBase {

    paging: SearchPaginationRequest = new SearchPaginationRequest();
    dataSource: MatTableDataSource<any>;
    length: number;
    pageSize: number;
    pageSizeOptions: number[] = [10, 20, 50];
    displayedColumns: string[] = ['servicename', 'status', 'servicetype', 'paymentcalcmethod', 'visitrecmethod', 'limitedvisits', 'action'];

    // #region Constant references
    CommonString = CommonString;
    RouterConstant = RouterConstant;
    // #endregion Constant references

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            Services: [[]]
        });
    }

    constructor(injector: Injector,
        private serviceSvc: ServiceService,
        private route: ActivatedRoute,
        private modal: NgbModal) { super(injector); }

    OnLoad() {
        if (this.data.mode === FacilityFormMode.Edit) {
            this.LoadServices(true);
        } else {
            this.dataSource = new MatTableDataSource<any>();
            super.OnLoad();
        }
    }

    LoadComplete() {
        super.LoadComplete();
        setTimeout(() => {
            this.getControl('Services').valueChanges.subscribe(value => {
                this.dataSource.data = value;
            });
        });
    }

    //Author: Yen
    LoadServices(firstLoad = false) {
        const id = parseInt(this.route.snapshot.params.id, 10);
        this.paging.PageNumber = 0;
        this.paging.PageSize = 0;
        this.paging.Params = [{ Name: 'facility_id', Value: id }];
        this.paging.ViewColumns = ['status', 'service_type', 'payment_calculation_method', 'visit_recording_method'];
        this.Invoke(
            this.serviceSvc.search(this.paging),
            {
                onSuccess: (result: DataResult<SearchPaginationResult<any>>) => {
                    if (result.Success) {
                        this.dataSource = new MatTableDataSource<any>(result.Data.Results);
                        this.getControl('Services').setValue(this.dataSource.data, { emitEvent: false });
                        this.length = result.Data.TotalItem;
                    } else {
                        this.dataSource = new MatTableDataSource<any>();
                        this.HandleResponseError(result);
                    }
                    if (firstLoad) {
                        super.OnLoad();
                    }
                }
            }
        );
    }

    sortChange(sort: Sort) {
        const activeSort: string = sort.active.toLowerCase();
        this.paging.OrderBy = activeSort;
        this.paging.OrderByAsc = sort.direction === 'asc' ? false : true;
        this.LoadServices();
    }

    openDialog(index?: number) {
        const services = <ServiceDetailsTrackingModel[]>this.dataSource.data;
        let model = new ServiceDetailsTrackingModel();
        if (index != null) {
            model = Object.assign({}, services[index]);
        } else {
            model.Id = 0;
        }

        const modalRef = this.modal.open(FacilityServiceDetailsDialogComponent, {
            windowClass: 'fp-modal-window',
            backdrop: 'static'
        });
        const instance = (<FacilityServiceDetailsDialogComponent>modalRef.componentInstance);
        instance.data.mode = index != null ? FacilityServiceFormMode.Edit : FacilityServiceFormMode.Add;
        instance.data.model = model;
        instance.fetched = model.Fetched || false;
        modalRef.result.then(
            (result: { returnValue: ServiceDetailsTrackingModel }) => {
                if (result && result.returnValue) {
                    model = Object.assign(model, result.returnValue);
                    model.Fetched = true;
                    model.Changed = true;
                    if (index >= 0) {
                        services[index] = model;
                    } else {
                        services.push(model);
                    }
                    this.form.get('Services').setValue(services);
                }
            },
            reason => {
                if (reason && reason.returnValue) {
                    if (index >= 0) {
                        model = Object.assign(model, reason.returnValue);
                        model.Fetched = true;
                        services[index] = model;
                    }
                }
            }
        );
    }
}

class ServiceDetailsTrackingModel extends ServiceDetails {
    Fetched: boolean;
    Changed: boolean;
}