import { ChangeDetectionStrategy, Component, Injector, isDevMode, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DialogResult } from '@fp/components/common-dialog/common-dialog.component';
import { EServiceStatus } from '@fp/enums';
import { DataResult, ServiceDetails } from '@fp/models';
import { ServiceService } from '@fp/services';
import { MessageBox, MessageBoxButton } from '@fp/services/common-dialog.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FacilityServiceDetailsComponent } from '../service-details/service-details.component';
import { FacilityServiceFormBase } from '../service-form-base';

@Component({
    selector: 'app-facility-service-details-dialog',
    templateUrl: './service-details-dialog.component.html',
    styleUrls: ['./service-details-dialog.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: FacilityServiceFormBase, useExisting: FacilityServiceDetailsDialogComponent }]
})
export class FacilityServiceDetailsDialogComponent extends FacilityServiceFormBase {
    // TODO: remove after implementation completed.
    get isDebugging() { return isDevMode() && window['enableDebugging'] === true; }
    JSON = JSON;
    fetched = false;

    @ViewChild(FacilityServiceDetailsComponent, {static: true}) detailsSection: FacilityServiceDetailsComponent;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            DetailsSection: FacilityServiceDetailsComponent.getFormGroup()
        });
    }

    constructor(injector: Injector,
        private activeModal: NgbActiveModal,
        private svc: ServiceService) {
        super(injector);
        this.async = false;
        this.form = FacilityServiceDetailsDialogComponent.getFormGroup();
    }

    ngAfterViewInit() {
        this.detailsSection.OnLoad();
        this.OnLoad();
    }

    OnLoad() {
        if (!this.fetched) {
            if (this.data.model.Id > 0) {
                this.Invoke(
                    this.svc.getDetails(this.data.model.Id),
                    {
                        onSuccess: (res: DataResult<ServiceDetails>) => {
                            if (res.Success) {
                                this.data.model = Object.assign(this.data.model, res.Data);
                                if (!this.data.model.IsOffPeakService) {
                                    this.data.model.OffPeakStartTime = '00:00';
                                    this.data.model.OffPeakEndTime = '00:00';
                                }
                                this.detailsSection.isReadyToPatch(this.data.model, { emitEvent: false });
                            } else {
                                this.HandleResponseError(res);
                            }
                            super.OnLoad();
                        }
                    });
            } else {
                super.OnLoad();
            }
        } else {
            this.PatchValue(this.data.model, { emitEvent: false });
            super.OnLoad();
        }
    }

    dismiss() {
        // TODO: display warning before closing
        if (this.form.dirty || this.detailsSection.DeviceOrSiteTokenChanged) {
            MessageBox.ShowCustom(this.dialog,
                'Confirmation',
                'Confirmation',
                'You have entered un-submitted data. Are you sure you want to close this screen?', MessageBoxButton.YesNo).subscribe(
                    (dialogResult: DialogResult) => {
                        if (dialogResult.result === DialogResult.Yes) {
                            localStorage.removeItem('SiteToken');
                            this.activeModal.dismiss({ returnValue: this.data.model });
                        }
                    }
                );
        } else {
            this.activeModal.dismiss({ returnValue: this.data.model });
        }
    }

    submit() {
        this.Validate();
        const previousStatusId = this.data.model.ServiceStatusId;
        // Clone current model to keep its initial values unchanged.
        let model = Object.assign({}, this.data.model);
        model = <ServiceDetails>this.applyValue(model, this);
        this._logger.debug(model);
        if (!this.valid) {
            if (this.isDebugging) {
                const invalidCtrls = this._findInvalidControls(this.form);
                this._logger.debug('Invalid controls:\n' + invalidCtrls.join('\n'));
            }
            return;
        }

        if (this.data.model.Id > 0 &&
            previousStatusId === EServiceStatus.Active &&
            model.ServiceStatusId === EServiceStatus.Inactive) {
            MessageBox.ShowCustom(this.dialog,
                'Warning',
                'Warning',
                'Are you sure you want to inactivate this service?',
                MessageBoxButton.YesNo).subscribe((dialogResult: DialogResult) => {
                    if (dialogResult.result === DialogResult.Yes) {
                        this.activeModal.close({ returnValue: model });
                    }
                });
        } else {
            this.activeModal.close({ returnValue: model });
        }
    }
}
