import { Directive, Input, OnInit } from '@angular/core';
import { NgbTimeAdapter, NgbTimepicker, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * Specifies the time boundary for `NgbTimepicker`.
 *
 * @class NgbTimeBoundaryDirective
 * @example
 * // Using NgbTimeStruct:
 * <ngb-timepicker [timeBoundary]="{minTime: {hour: 17, minute: 30, second: 00}}"></ngb-timepicker>
 * // Using time string:
 * <ngb-timepicker [timeBoundary]="{maxTime: '17:30:00'}"></ngb-timepicker>
 */
@Directive({
    selector: 'ngb-timepicker[timeBoundary]'
})
export class NgbTimeBoundaryDirective implements OnInit {
    private _config: TimeBoundaryStruct = {
        min: null,
        max: null
    };

    private get _adapter() { return <NgbTimeAdapter<any>>this.el['_ngbTimeAdapter']; }

    @Input() set timeBoundary(config: TimeBoundaryStruct) {
        if (config.min != null) {
            if (typeof config.min === 'string') {
                const matches = config.min.match(/(\d{1,}:\d{2}:\d{2})/g);
                if (matches.length === 0) {
                    throw new Error('Invalid time format.');
                }
                const split = config.min.split(':');
                this._config.min = {
                    hour: parseInt(split[0], 10),
                    minute: parseInt(split[1], 10),
                    second: parseInt(split[2], 10)
                };
            } else if (typeof config.min === 'object') {
                this._config.min = config.min;
            } else {
                throw new Error("The property 'minTime' only accepts value of type 'TimeBoundaryStruct' or 'string'.");
            }
        }
        if (config.max != null) {
            if (typeof config.max === 'string') {
                const matches = config.max.match(/(\d{1,}:\d{2}:\d{2})/g);
                if (matches.length === 0) {
                    throw new Error('Invalid time format.');
                }
                const split = config.max.split(':');
                this._config.max = {
                    hour: parseInt(split[0], 10),
                    minute: parseInt(split[1], 10),
                    second: parseInt(split[2], 10)
                };
            } else if (typeof config.max === 'object') {
                this._config.max = config.max;
            } else {
                throw new Error("The property 'maxTime' only accepts value of type 'TimeBoundaryStruct' or 'string'.");
            }
        }

        if (this._config.min != null && this._config.max != null) {
            const totalMinSeconds = getTotalSeconds(<NgbTimeStruct>this._config.min);
            const totalMaxSeconds = getTotalSeconds(<NgbTimeStruct>this._config.max);
            if (totalMinSeconds > totalMaxSeconds) {
                throw new Error("The value of 'minTime' must be less than or equal to that of 'maxTime'.");
            }
        }
    }

    constructor(private el: NgbTimepicker) { }

    ngOnInit() {
        this.el['timeBoundary'] = this._config;
        const pickerOnChange = this.el.onChange;
        this.el.registerOnChange(value => {
            const evalResult = this.evaluate(value);
            pickerOnChange(value);
            if (evalResult.updated) {
                setTimeout(() => {
                    /*
                    Because the control wouldn't reflect the updated value immediately if the value was
                    re-evaluated during onChange event and new value remained same as before re-evaluation,
                    we have to let the value change first, then re-evaluate and update the value again.
                    */
                    this.el.writeValue(evalResult.value);
                    pickerOnChange(evalResult.value);
                });
            }
        });
    }

    private evaluate(value: any): { updated: boolean, value: any } {
        let timeUpdated = false;
        const adapter = this._adapter;
        let timeStruct = adapter.fromModel(value);
        if (!value && this.el.model && !isNaN(this.el.model.hour) &&
            (isNaN(this.el.model.minute) || isNaN(this.el.model.second))) {
            timeStruct = Object.assign({}, this.el.model);
            if (isNaN(timeStruct.minute)) {
                timeStruct.minute = 0;
            }
            if (isNaN(timeStruct.second)) {
                timeStruct.second = 0;
            }
            timeUpdated = true;
        } else if (!this.el.model.isValid(true)) {
            return { updated: timeUpdated, value: value };
        }
        const minTime = <NgbTimeStruct>this._config.min;
        const maxTime = <NgbTimeStruct>this._config.max;
        if (minTime && getTotalSeconds(timeStruct) < getTotalSeconds(minTime)) {
            timeStruct = Object.assign(timeStruct, minTime);
            timeUpdated = true;
        }
        if (maxTime && getTotalSeconds(timeStruct) > getTotalSeconds(maxTime)) {
            timeStruct = Object.assign(timeStruct, maxTime);
            timeUpdated = true;
        }
        value = adapter.toModel(timeStruct);
        return { updated: timeUpdated, value: value };
    }
}

function getTotalSeconds(time: NgbTimeStruct): number {
    return time.hour * 3600 + time.minute * 60 + time.second;
}

interface TimeBoundaryStruct {
    min: NgbTimeStruct | string;
    max: NgbTimeStruct | string;
}
