import { Component, OnInit } from "@angular/core";
import {
  PaymentRequestDetailsService,
  MessageBox,
  CommonService,
  PaymentService,
  DataService
} from "@fp/services";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { FpFileUploadR2Component } from "@fp/components/control";
import { FileNameService } from "@fp/services/file-name.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EditManualVisitsComponent } from "./edit-manual-visits/edit-manual-visits.component";
import { HttpClient } from "@angular/common/http";
import {
  EditPaymentRequestModel,
  PaymentRequestNotesModel
} from "@fp/models/edit-payment-request.model";
import { ResultModel, DataResult, NewPaymentRequestNote } from "@fp/models";
import { Observable } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { RouterConstant } from "@fp/constant";
import { Router } from "@angular/router";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { DateHelper } from "@fp/helpers";

@Component({
  selector: "app-edit-payment-request",
  templateUrl: "./edit-payment-request.component.html",
  styleUrls: ["./edit-payment-request.component.css"]
})
export class EditPaymentRequestComponent implements OnInit {
  public PaymentRequestNoteFormGroup = new UntypedFormGroup({
    paymentRequestNoteText: new UntypedFormControl("")
  });
  public dtmatnotes: MatTableDataSource<
    NewPaymentRequestNote
  > = new MatTableDataSource<NewPaymentRequestNote>();
  private noteListNew: NewPaymentRequestNote[] = [];
  private noteListOld: NewPaymentRequestNote[] = [];
  private noteList: NewPaymentRequestNote[] = [];

  public displayedColumns: string[] = ["Username", "DateTimeDisplay", "Note"];
  public btnAddNoteClick = true;

  currentUser = this.commonSvc.GetUser();
  facilityName: string;
  serviceName: string;
  toDate: string;
  fromDate: string;
  visitAmount = 0;
  documentName = "No File Selected";
  file: any;
  paymentAmount = 0;
  finalPayment = 0;
  finalVisit = 0;
  prId: string;
  pageIndex = 2;
  searchChanged = false;
  private uploadFileToS3: FpFileUploadR2Component;
  EditPaymentRequestDataToSend = new EditPaymentRequestModel();
  invoiceNumber: string;
  uploadedFileName: string;
  description: string;
  incompleteMessage = "";
  additionalVisitsAmount: number;
  newHwmNumMem = 0;
  isThereCost = false;
  isNewHwm = false;
  isLoading = false;
  page = "ePaymentReq";
  attachmentNameString = "";
  attachmentUuidString = "";
  uploadedInvoiceName: string[];
  uploadedInvoiceUuid: string[];
  uploadedManualVisitsAttchName: string[];
  uploadedManualVisitsAttchUuid: string[];
  initialVisitAmount = 0;
  uncheckedVisitCount: number;
  isFirstPR: boolean;
  PRCalculationMethod = "";
  perVisitCalcCheck: number;
  paymentRequestCount: number;
  messageRead = false;

  constructor(
    private prDetailsSvc: PaymentRequestDetailsService,
    public dialog: MatDialog,
    public thisDialog: MatDialogRef<EditPaymentRequestComponent>,
    public router: Router,
    private modalSvc: NgbModal,
    private fileNameSvc: FileNameService,
    private commonSvc: CommonService,
    private paymentSvc: PaymentService,
    private shareData: DataService,
    private http: HttpClient
  ) {
    this.uploadFileToS3 = new FpFileUploadR2Component(
      this.modalSvc,
      this.dialog,
      this.http,
      this.fileNameSvc,
      this.commonSvc
    );
  }

  ngOnInit() {
    this.isLoading = true;
    // initialising values and associating to clones in service
    this.shareData.currentMessage.subscribe(prId => (this.prId = prId));
    this.Invoke(
      this.paymentSvc.getPaymentRequestById(+this.prId),
      (result: DataResult<EditPaymentRequestModel>) => {
        this.fromDate = result.Data.PaymentPeriodStart;
        this.toDate = result.Data.PaymentPeriodEnd;
        this.facilityName = result.Data.FacilityName;
        this.serviceName = result.Data.ServiceName;
        this.visitAmount = result.Data.SystemCalculatedVisitCount;
        this.finalVisit = result.Data.VisitCount;
        this.initialVisitAmount = result.Data.VisitCount;
        this.paymentAmount = result.Data.SystemCalculatedAmount;
        this.finalPayment = result.Data.Amount;
        this.invoiceNumber = result.Data.InvoiceNumber;
        this.uploadedFileName = result.Data.InvoiceName;
        this.paymentRequestCount = result.Data.PaymentRequestCount;
        this.prDetailsSvc.retrieveVisitsCount(
          this.visitAmount,
          this.finalVisit
        );
        this.Invoke(
          this.paymentSvc.getPaymentRequestNotes(this.prId),
          (result: DataResult<PaymentRequestNotesModel[]>) => {
            if (result.Data.length > 0) {
              console.log("Notes Data, check date: ", result.Data);
              for (let i = 0; i < result.Data.length; i++) {
                const tempPrNote = new NewPaymentRequestNote();
                tempPrNote.DateTime = this.ConvertFormat(
                  result.Data[i].CreateOrUpdateDate
                );
                tempPrNote.Note = result.Data[i].Note;
                tempPrNote.User = "" + result.Data[i].Username;
                this.noteList.unshift(tempPrNote);
                this.noteListOld.unshift(tempPrNote);
              }
              this.dtmatnotes = new MatTableDataSource<NewPaymentRequestNote>(
                this.noteList
              );
            }
            // kill the matt spinner
            this.isLoading = false;
          }
        );
        if (result.Data.PaymentCalculationMethodId === 1) {
          this.PRCalculationMethod = "High Water Mark";
        }
        else if (result.Data.PaymentCalculationMethodId === 2) {
          var visitDollarAmount = (Math.round(result.Data.PerVisitRate * 100) / 100).toFixed(2);
          this.PRCalculationMethod = "Per Visit ($" + visitDollarAmount + ")";
        }
        else {
          this.PRCalculationMethod = "Other";
        }
        if (this.paymentRequestCount > 1) {
          this.isFirstPR = false;
        }
        else {
          this.isFirstPR = true;
        }
        console.log(result);
      }
    );

    this.prDetailsSvc.$currentVisitAmount.subscribe(
      visitAmount => (this.visitAmount = visitAmount)
    );
    this.prDetailsSvc.$currentPaymentAmount.subscribe(
      paymentAmount => (this.paymentAmount = paymentAmount)
    );
    this.prDetailsSvc.$currentFinalPaymentAmount.subscribe(
      finalPayment => (this.finalPayment = finalPayment)
    );
    this.prDetailsSvc.$currentNewHwmMem.subscribe(
      newHwmNumMem => (this.newHwmNumMem = newHwmNumMem)
    );
    this.prDetailsSvc.$currentCostFlag.subscribe(
      costFlag => (this.isThereCost = costFlag)
    );
    this.prDetailsSvc.$currentNewHwmFlag.subscribe(
      hwmFlag => (this.isNewHwm = hwmFlag)
    );
    this.prDetailsSvc.$currentAdditionalVisits.subscribe(
      additionalVisits => (this.additionalVisitsAmount = additionalVisits)
    );
    this.fileNameSvc.$currentUploadedFileUuid.subscribe(
      uploadedInvoiceUuid => (this.uploadedInvoiceUuid = uploadedInvoiceUuid)
    );
    this.fileNameSvc.$currentUploadedFileName.subscribe(
      uploadedInvoiceName => (this.uploadedInvoiceName = uploadedInvoiceName)
    );
    this.fileNameSvc.$currentChildUploadedFileUuid.subscribe(
      manualAttchUuid => (this.uploadedManualVisitsAttchUuid = manualAttchUuid)
    );
    this.fileNameSvc.$currentChildUploadedFileName.subscribe(
      manualAttchName => (this.uploadedManualVisitsAttchName = manualAttchName)
    );
    this.prDetailsSvc.$currentUncheckedVisits.subscribe(
      uncheckedVisits => (this.uncheckedVisitCount = uncheckedVisits)
    );
    this.prDetailsSvc.$currentVisitCost.subscribe(
      visitCost => (this.perVisitCalcCheck = visitCost)
    );
  }

  //Change incoming time from B.E (UTC) to machine's local time
  private ConvertToLocalTime(d: Date) {
    const localdate: Date = new Date(d + "Z");
    return DateHelper.format(localdate, "DD-MMM-YYYY HH:mm"); //Without seconds required
  }

  //Change format
  private ConvertFormat(d: Date) {
    const localdate: Date = new Date(d);
    return DateHelper.format(localdate, "DD-MMM-YYYY HH:mm"); //Without seconds required
  }

  // creating a function to attach invoices by assigning file metadata to 'fileArray'
  attachInvoice(event) {
    const headerString = "Uploading an Additional Invoice";
    const bodyString =
      "Only one invoice can be uploaded per payment request." +
      " Uploading another invoice will result in discarding the existing one." +
      " Do you wish to continue with this operation?";
    if (this.documentName !== "No File Selected") {
      MessageBox.ShowCancelContinue(
        this.dialog,
        headerString,
        bodyString
      ).subscribe(dialogResult => {
        if (dialogResult.result.toLowerCase() === DialogResult.Continue) {
          this.file = (<HTMLInputElement>event.target).files[0];
          this.documentName = this.file.name;
        }
      });
    } else {
      this.file = (<HTMLInputElement>event.target).files[0];
      this.documentName = this.file.name;
    }
  }

  removeInvoice() {
    this.file = null;
    this.documentName = "No File Selected";
    console.log(this.fromDate);
  }

  manualTotalPayChange() {
    this.finalPayment = +(<HTMLInputElement>(
      document.getElementById("finalPayment")
    )).value.replace("$","").replace(",", "");
    this.searchChanged = false;
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError(e => {
          throw e;
        })
      )
      .subscribe(
        res => {
          handleResultCallback(res);
        },
        err => {
          this.errorHandler(err);
        }
      );
  }

  private errorHandler(error, message: string = null) { }

  submitButton() {
    console.log(this.uncheckedVisitCount);
    if (this.uncheckedVisitCount > 0 && this.messageRead === false) {
      MessageBox.ShowOKCustom(
        this.dialog,
        "Warning: Duplicate Visits May Exist",
        `This service has ${this.uncheckedVisitCount} visits that have not yet been checked for\
        duplicates and are not included in the 'Visits in System' total.\
        Recommended action is to create this payment request once all visits have been checked.`,
        "OK",
        "450px"
      );
      this.messageRead = true;
    }
    else {
      this.isLoading = true;
      // for payment request table
      if (this.paymentAmount !== this.finalPayment && !this.searchChanged) {
        this.searchChanged = false;
      } else {
      }

      this.invoiceNumber = (<HTMLInputElement>(
        document.getElementById("invoiceNumber")
      )).value;
      var finalVisitString: string;
      finalVisitString = (<HTMLInputElement>document.getElementById("finalVisit"))
        .value;
      this.finalVisit = +finalVisitString;
      const missingValues = [];
      if (this.finalVisit < 0 || this.finalVisit === null) {
        missingValues.push(" Visit Count");
      }
      if (finalVisitString === "") {
        missingValues.push("Visit Count");
      }
      if (
        (this.finalPayment <= 0 || this.finalPayment === null) &&
        this.isThereCost
      ) {
        missingValues.push(" Final Cost Amount");
      }
      if (missingValues.length > 0) {
        MessageBox.ShowInfo(
          this.dialog,
          `Unable to submit payment request.${missingValues} are missing.`
        );
        // Kill the Mat Spinner
        this.isLoading = false;
      } else {
        const tempFinalPayment =
          "" + (<HTMLInputElement>document.getElementById("finalPayment")).value;
        this.finalPayment = +tempFinalPayment.replace("$", "").replace(",", "");
        for (let i = 0; i < this.uploadedManualVisitsAttchName.length; i++) {
          this.attachmentNameString +=
            this.uploadedManualVisitsAttchName[i] + ",";
          this.attachmentUuidString +=
            this.uploadedManualVisitsAttchUuid[i] + ",";
        }
        if (this.file === undefined) {
          this.EditPaymentRequestDataToSend.VisitCount = this.finalVisit;
          this.EditPaymentRequestDataToSend.SystemCalculatedVisitCount = this.visitAmount;
          this.EditPaymentRequestDataToSend.Amount = +this.finalPayment;
          this.EditPaymentRequestDataToSend.SystemCalculatedAmount = +this
            .paymentAmount;
          this.EditPaymentRequestDataToSend.InvoiceNumber = this.invoiceNumber;
          this.EditPaymentRequestDataToSend.CreatedBy = this.currentUser;
          this.EditPaymentRequestDataToSend.NotesInformation = this.noteListNew;
          this.EditPaymentRequestDataToSend.InvoiceName = this.uploadedInvoiceName[0];
          this.EditPaymentRequestDataToSend.InvoiceUuid = this.uploadedInvoiceUuid[0];
          this.EditPaymentRequestDataToSend.AttachmentsName = this.attachmentNameString.slice(
            0,
            -1
          ); //slice() to get rid of the appended comma at the end
          this.EditPaymentRequestDataToSend.AttachmentsUuid = this.attachmentUuidString.slice(
            0,
            -1
          ); //slice() to get rid of the appended comma at the end
          this.EditPaymentRequestDataToSend.HwmNumberMemberships = this.newHwmNumMem;
          this.EditPaymentRequestDataToSend.HwmChangedFromLastMonth = this.isNewHwm;
          this.EditPaymentRequestDataToSend.AdditionalManualVisits =
            this.additionalVisitsAmount - this.initialVisitAmount;
          this.EditPaymentRequestDataToSend.PaymentRequestId = +this.prId;
          console.log(this.EditPaymentRequestDataToSend);
          this.Invoke(
            this.paymentSvc.submitEditPaymentRequest(
              this.EditPaymentRequestDataToSend
            ),
            (data: ResultModel) => {
              this.isLoading = false;
              console.log(data);
              MessageBox.ShowOKCustom(
                this.dialog,
                `Information`,
                `Success! Your payment request has been edited!`
              ).subscribe(dialogResult => {
                if (dialogResult.result.toLowerCase() === DialogResult.Ok) {
                  this.fileNameSvc.resetFileData();
                  window.location.reload();
                }
              });
            }
          );
        }
        else {
          const checkNecessary = false;
          this.Invoke(
            this.uploadFileToS3.fileEventUsingFile(
              this.file,
              checkNecessary,
              this.page
            ),
            (uploadResult: boolean) => {
              if (uploadResult == true) {
                this.EditPaymentRequestDataToSend.VisitCount = this.finalVisit;
                this.EditPaymentRequestDataToSend.SystemCalculatedVisitCount = this.visitAmount;
                this.EditPaymentRequestDataToSend.Amount = +this.finalPayment;
                this.EditPaymentRequestDataToSend.SystemCalculatedAmount = +this
                  .paymentAmount;
                this.EditPaymentRequestDataToSend.InvoiceNumber = this.invoiceNumber;
                this.EditPaymentRequestDataToSend.CreatedBy = this.currentUser;
                this.EditPaymentRequestDataToSend.NotesInformation = this.noteListNew;
                this.EditPaymentRequestDataToSend.InvoiceName = this.uploadedInvoiceName[0];
                this.EditPaymentRequestDataToSend.InvoiceUuid = this.uploadedInvoiceUuid[0];
                this.EditPaymentRequestDataToSend.AttachmentsName = this.attachmentNameString.slice(
                  0,
                  -1
                ); //slice() to get rid of the appended comma at the end
                this.EditPaymentRequestDataToSend.AttachmentsUuid = this.attachmentUuidString.slice(
                  0,
                  -1
                ); //slice() to get rid of the appended comma at the end
                this.EditPaymentRequestDataToSend.HwmNumberMemberships = this.newHwmNumMem;
                this.EditPaymentRequestDataToSend.HwmChangedFromLastMonth = this.isNewHwm;
                this.EditPaymentRequestDataToSend.AdditionalManualVisits =
                  this.additionalVisitsAmount - this.initialVisitAmount;
                this.EditPaymentRequestDataToSend.PaymentRequestId = +this.prId;
                console.log(this.EditPaymentRequestDataToSend);
                this.Invoke(
                  this.paymentSvc.submitEditPaymentRequest(
                    this.EditPaymentRequestDataToSend
                  ),
                  (data: ResultModel) => {
                    this.isLoading = false;
                    console.log(data);
                    if(data.Success) {
                      MessageBox.ShowOKCustom(
                        this.dialog,
                        `Information`,
                        `Success! Your payment request has been edited!`
                      ).subscribe(dialogResult => {
                        if (dialogResult.result.toLowerCase() === DialogResult.Ok) {
                          this.fileNameSvc.resetFileData();
                          window.location.reload();
                        }
                      });
                    } else {
                      MessageBox.ShowOKCustom(
                        this.dialog,
                        `Information`,
                        `Sorry, your attempt to edit this payment request has failed.`
                      ).subscribe(dialogResult => {
                        if (dialogResult.result.toLowerCase() === DialogResult.Ok) {
                          this.fileNameSvc.resetFileData();
                          window.location.reload();
                        }
                      });
                    }
                  }
                );
              }
              else {
                this.isLoading = false;
                console.log(uploadResult);
                MessageBox.ShowError(
                  this.dialog,
                  `The file "${this.uploadedInvoiceName[0]}" failed to upload. Please try again.`
                );
              }
            }
          )
        };
      }
    }
  }

  finalChange() {
    this.finalVisit = +(<HTMLInputElement>document.getElementById("finalVisit"))
      .value;
    var isFinalCalc = true;
    this.prDetailsSvc.calculateCost(
      this.facilityName,
      this.serviceName,
      this.fromDate,
      this.toDate,
      this.finalVisit,
      this.pageIndex,
      isFinalCalc
    );
  }

  openDialog() {
    // settings for dialog box
    const dialogRef = this.dialog.open(EditManualVisitsComponent, {
      height: "550px",
      width: "800px"
    });

    // functions to complete after dialog box is closed
    dialogRef.afterClosed().subscribe(result => {
      this.finalVisit = +(<HTMLInputElement>(
        document.getElementById("finalVisit")
      )).value;
      var isFinalCalc = false;
      this.prDetailsSvc.calculateCost(
        this.facilityName,
        this.serviceName,
        this.fromDate,
        this.toDate,
        this.finalVisit,
        this.pageIndex,
        isFinalCalc
      );
    });
    this.autoPopulateValues();
  }

  autoPopulateValues() {
    this.prDetailsSvc.populateValues(
      this.facilityName,
      this.serviceName,
      this.fromDate,
      this.toDate
    );
  }

  perVisitCalc() {
    var finalAmountToPay = (<HTMLInputElement>(
      document.getElementById("finalPayment")
    )).value;
    var finalAmountToPayNum = +finalAmountToPay.replace("$", "").replace(",", "");
    var totalVisitsForPayment = +(<HTMLInputElement>(
      document.getElementById("finalVisit")
    )).value;
    this.prDetailsSvc.perVisitCalc(finalAmountToPayNum, totalVisitsForPayment)
  }

  addPaymentRequestNotes() {
    const item: NewPaymentRequestNote = new NewPaymentRequestNote();
    item.DateTime = this.ConvertFormat(new Date());

    item.Note = this.PaymentRequestNoteFormGroup.get(
      "paymentRequestNoteText"
    ).value;
    item.User = this.currentUser;
    this.noteListNew.unshift(item);
    this.noteList.unshift(item);
    this.dtmatnotes = new MatTableDataSource<NewPaymentRequestNote>(
      this.noteList
    );
    this.PaymentRequestNoteFormGroup.get("paymentRequestNoteText").setValue("");
    this.btnAddNoteClick = true;
  }

  noteInputs(event: KeyboardEvent) {
    if (
      this.PaymentRequestNoteFormGroup.get("paymentRequestNoteText").value
        .length > 0
    ) {
      this.btnAddNoteClick = false;
    } else {
      this.btnAddNoteClick = true;
    }
    // adds non-empty notes when "enter" key is pressed
    if (event.keyCode === 13 && this.PaymentRequestNoteFormGroup.get("paymentRequestNoteText").value.replace(/\s/g, "") !== "") {
      this.addPaymentRequestNotes();
    }
  }
}
