<div class="border-default rounded-10 border-2">
    <div class="tablecontainer">
        <table mat-table [dataSource]="dataSource_branch">
            <ng-container matColumnDef="BranchName">
                <th mat-header-cell *matHeaderCellDef>Office/Branch Name</th>
                <td mat-cell *matCellDef="let element"> {{element.BranchName}} </td>
            </ng-container>
            <ng-container matColumnDef="Suburb">
                <th mat-header-cell *matHeaderCellDef>Suburb</th>
                <td mat-cell *matCellDef="let element">
                    {{element.Suburb ? element.Suburb.Name : ''}}
                </td>
            </ng-container>
            <ng-container matColumnDef="StateName">
                <th mat-header-cell *matHeaderCellDef>State</th>
                <td mat-cell *matCellDef="let element"> {{element.StateName}} </td>
            </ng-container>
            <ng-container matColumnDef="EligibleEmployerNo">
                <th mat-header-cell *matHeaderCellDef>Eligible Employee No.</th>
                <td mat-cell *matCellDef="let element"> {{element.EligibleEmployerNo}} </td>
            </ng-container>
            <ng-container matColumnDef="ContactName">
                <th mat-header-cell *matHeaderCellDef>Contact Name</th>
                <td mat-cell *matCellDef="let element"> {{element.ContactName}} </td>
            </ng-container>
            <ng-container matColumnDef="ContactPhoneNumber">
                <th mat-header-cell *matHeaderCellDef>Contact Number</th>
                <td mat-cell *matCellDef="let element">
                    {{element.ContactPhoneNumber ? (element.ContactPhoneNumber.length > 0 ? (element.StateId != nzStateId ? '+61' + element.ContactPhoneNumber : '+64' + element.ContactPhoneNumber):
          null) : null }}
                </td>
            </ng-container>
            <ng-container matColumnDef="ContactEmailAddress">
                <th mat-header-cell *matHeaderCellDef>Contact Email</th>
                <td mat-cell *matCellDef="let element"> {{element.ContactEmailAddress}}
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="min-width:100px;"></th>
                <td mat-cell *matCellDef="let element;let i=index">
                    <a class="px-2" (click)="removeEmployerBranch(element)" *ngIf="!isReadonly">
                        <i class="fas fa-trash-alt"></i>
                    </a>
                    <a class="px-2" *ngIf="!isReadonly" (click)="editEmployerBranch(element, i)" data-toggle="modal"
                        data-target="#addBranchModal">
                        <i class="fas fa-pen"></i>
                    </a>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColums_location"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColums_location;"></tr>
        </table>
    </div>
    <mat-toolbar [hidden]="dataSource_branch == null" class="bg-white">
        <mat-toolbar-row *ngIf="dataSource_branch != null && dataSource_branch.data.length == 0"
            class="text-muted d-block p-2 bg-light text-center">
            {{commonString.TABLE_NO_RECORD_FOUND}}
        </mat-toolbar-row>
    </mat-toolbar>
</div>
<div class="pt-3">
    <button class="px-2 py-2 btn btn-primary text-transform-none" type="button" data-toggle="modal"
        data-target="#addBranchModal" (click)="addBranch()" *ngIf="!isReadonly"><i
            class="fas fa-plus-circle mr-2"></i>Add</button>
</div>

<!--Add branch/Location-->
<div class="modal fade" #addBranchModalctrl id="addBranchModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog modaldialog-md" role="document">
        <div class="modal-content">
            <div class="modal-header px-0">
                <h5 class="modal-title" id="exampleModalLabel">Add Branch/Location</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="btnCloseModalBranch">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="modal-body py-4">
                <form [formGroup]="branchForm" id="branch-location">
                    <div class="form-group row">
                        <label for="txtOfficeBranchName" class="col-md-3 col-form-label">Office/Branch Name<span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <div class="d-flex flex-column w-100">
                                <input id="txtOfficeBranchName" formControlName="BranchName" class="form-control"
                                    maxlength="100"
                                    [ngClass]="BranchControlValidate(branchNameCtrl, clickBranchSubmit) ? ' is-invalid' : ''">
                                <small *ngIf="BranchControlValidate(branchNameCtrl, clickBranchSubmit)"
                                    class="invalid-feedback d-block">
                                    {{stringHelper.format(commonString.FIELD_REQUIRED, 'Office/Branch Name')}}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtEEmpNo" class="col-md-3 col-form-label">Eligible Employee No.<span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <div class="d-flex flex-column w-100">
                                <input id="txtEEmpNo" formControlName="EligibleEmployerNo" class="form-control"
                                    maxlength="5" numberOnly
                                    [ngClass]="BranchControlValidate(branchEmployeeNoCtrl, clickBranchSubmit) ? ' is-invalid' : ''">
                                <small *ngIf="BranchControlValidate(branchEmployeeNoCtrl, clickBranchSubmit)"
                                    class="invalid-feedback d-block">
                                    {{stringHelper.format(commonString.FIELD_REQUIRED, 'Eligible Employee No.')}}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtBStreet1" class="col-md-3 col-form-label">Address Line 1</label>
                        <div class="col-md-9">
                            <div class="d-flex flex-column w-100">
                                <input type="text" class="form-control" formControlName="StreetAddress1"
                                     id="txtBStreet1" maxlength="50" autocomplete="off"
                                    [ngClass]="BranchControlValidate(branchAddress1Ctrl, clickBranchSubmit) ? ' is-invalid' : ''">
                                <small *ngIf="BranchControlValidate(branchAddress1Ctrl, clickBranchSubmit)"
                                    class="invalid-feedback d-block">
                                    {{stringHelper.format(commonString.FIELD_REQUIRED, 'Address Line 1')}}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtBStreet2" class="col-md-3 col-form-label">Address Line 2</label>
                        <div class="col-md-9">
                            <div class="d-flex flex-column w-100">
                                <input type="text" id="txtBStreet2" formControlName="StreetAddress2" maxlength="50"
                                    class="form-control" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="ddlBSuburbPhy" class="col-md-3 col-form-label">City/Suburb<span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <div class="d-flex flex-column w-100">
                                <div class="position-relative input-group-extend">
                                    <input class="form-control" type="text" matInput
                                        [matAutocomplete]="branchSuburbAutoComplete" name="txtSuburbPhy"
                                        formControlName="Suburb" [readonly]="isReadonly"
                                        [ngClass]="BranchControlValidate(branchSuburbCtrl, clickBranchSubmit) ? ' is-invalid' : ''"
                                        autocomplete="off">
                                    <mat-spinner [diameter]="14" color="primary" *ngIf="suburbSpinner"></mat-spinner>
                                </div>

                                <mat-autocomplete #branchSuburbAutoComplete="matAutocomplete"
                                    id="ddlBSuburbPhyAutoComplete" [displayWith]="displayAutoSuburbText">
                                    <mat-option *ngFor="let s of branchSuburbs" [value]="s">
                                        {{ s.Name }}, {{ s.State?.Name }}, {{ s.Postcode?.Name }}
                                    </mat-option>
                                </mat-autocomplete>
                                <div *ngIf="BranchControlValidate(branchSuburbCtrl, clickBranchSubmit)">
                                    <small *ngIf="branchSuburbCtrl.hasError('required'); else templateValidObject"
                                        class="invalid-feedback d-block">
                                        {{stringHelper.format(commonString.FIELD_REQUIRED, 'City/Suburb')}}
                                    </small>
                                    <ng-template #templateValidObject>
                                        <small *ngIf="branchSuburbCtrl.hasError('validObj')"
                                            class="invalid-feedback d-block">
                                            {{stringHelper.format(commonString.FIELD_INVALID, 'City/Suburb')}}
                                        </small>
                                    </ng-template>


                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="ddlBPostCodePhy" class="col-md-3 col-form-label">Postcode<span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <div class="d-flex flex-column w-100">
                                <select class="form-control" id="ddlBPostCodePhy" formControlName="PostCode"
                                    [ngClass]="BranchControlValidate(branchPostcodeCtrl, clickBranchSubmit) ? ' is-invalid' : ''">
                                    <option [ngValue]="null">Please select a value</option>
                                    <option *ngFor="let p of branchPostcodes" [ngValue]="p">
                                        {{ p.Postcode }}
                                    </option>
                                </select>
                                <small *ngIf="BranchControlValidate(branchPostcodeCtrl, clickBranchSubmit)"
                                    class="invalid-feedback d-block">
                                    {{stringHelper.format(commonString.FIELD_REQUIRED, 'Postcode')}}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="ddlBStatePhy" class="col-md-3 col-form-label">State<span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <div class="d-flex flex-column w-100">
                                <select class="form-control" id="ddlBStatePhy" formControlName="StateId"
                                    [ngClass]="BranchControlValidate(branchStateIdCtrl, clickBranchSubmit) ? ' is-invalid' : ''">
                                    <option [ngValue]="null">Please select a value</option>
                                    <option *ngFor="let s of states" [value]="s.StateId">{{ s.Name }}</option>
                                </select>
                                <small *ngIf="BranchControlValidate(branchStateIdCtrl, clickBranchSubmit)"
                                    class="invalid-feedback d-block">
                                    {{stringHelper.format(commonString.FIELD_REQUIRED, 'State')}}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtBCountryPhy" class="col-md-3 col-form-label">Country<span
                                class="text-danger">*</span></label>
                        <div class="col-md-9">
                            <div class="d-flex flex-column w-100">
                                <select class="form-control" id="txtBCountryPhy" formControlName="CountryId"
                                    [ngClass]="BranchControlValidate(branchCountryIdCtrl, clickBranchSubmit) ? ' is-invalid' : ''">
                                    <option *ngFor="let c of countries" [value]="c.Id">
                                        {{ c.Name }}
                                    </option>
                                </select>
                                <small *ngIf="BranchControlValidate(branchCountryIdCtrl, clickBranchSubmit)"
                                    class="invalid-feedback d-block">
                                    {{stringHelper.format(commonString.FIELD_REQUIRED, 'Country')}}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtBRegionPhy" class="col-md-3 col-form-label">Region</label>
                        <div class="col-md-9">
                            <div class="d-flex flex-column w-100">
                                <input type="text" class="form-control" formControlName="RegionName" id="txtBRegionPhy"
                                    readonly="readonly">
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtContactName" class="col-md-3 col-form-label">Contact Name</label>
                        <div class="col-md-9">
                            <div class="d-flex flex-column w-100">
                                <input type="text" class="form-control" id="txtContactName"
                                    formControlName="ContactName" maxlength="100"
                                    [ngClass]="BranchControlValidate(branchContactNameCtrl, clickBranchSubmit) ? ' is-invalid' : ''">
                                <small *ngIf="BranchControlValidate(branchContactNameCtrl, clickBranchSubmit)"
                                    class="invalid-feedback d-block">
                                    {{stringHelper.format(commonString.FIELD_REQUIRED, 'Contact Name')}}
                                </small>
                            </div>
                        </div>
                    </div>
                    <app-mobile-number [mandatory]="false" #branchPhone [control]="branchPhoneCtrl"
                        formControlName="ContactPhoneNumber" [readonly]="isReadonly" #mobileNumberComponent
                        [clsLabel]="'col-md-3 col-form-label'" [clsGroup]="'col-md-9'" [label]="'Contact Phone Number'">
                    </app-mobile-number>

                    <!-- <div class="form-group row">
                        <label for="txtContactPhoneNumber" class="col-md-3 col-form-label">Contact Phone Number</label>
                        <div class="col-md-9">
                            <div class="d-flex flex-column w-100">
                                <input type="text" class="form-control" id="txtContactPhoneNumber">
                            </div>
                        </div>
                    </div> -->

                    <div class="form-group row">
                        <label for="txtContactEmailAddress" class="col-md-3 col-form-label">Contact Email
                            Address</label>
                        <div class="col-md-9">
                            <div class="d-flex flex-column w-100">
                                <input type="text" class="form-control" formControlName="ContactEmailAddress"
                                    id="txtContactEmailAddress" maxlength="50"
                                    [ngClass]="BranchControlValidate(branchEmailCtrl, clickBranchSubmit) ? ' is-invalid' : ''">
                                <div *ngIf="BranchControlValidate(branchEmailCtrl, clickBranchSubmit)">
                                    <small *ngIf="branchEmailCtrl.hasError('')" class="invalid-feedback d-block">
                                        {{stringHelper.format(commonString.FIELD_INVALID, 'Contact Email
                    Address')}}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtAdditionalNotes" class="col-md-3 col-form-label">Additional Notes</label>
                        <div class="col-md-9">
                            <div class="d-flex flex-column w-100">
                                <textarea class="form-control" formControlName="AdditionalNote" id="txtAdditionalNotes"
                                    maxlength="2000"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row pt-3">
                        <div class="offset-3 col-md-9">
                            <button id="ms_cards_change_photo_close"
                                class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                                data-dismiss="modal">
                                <i class="fas fa-ban mr-2"></i>Cancel</button>
                            <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="submitBranch()">
                                <i class="fas fa-check-circle mr-2"></i>
                                Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>