import { filter } from 'rxjs/operators';
import { Component, ElementRef, Injector, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MemberFormBase } from 'src/app/components/base';
import { MessageBox, MessageBoxButton } from '@fp/services/common-dialog.service';
import { DialogResult } from '@fp/components/common-dialog/common-dialog.component';
import { RouterConstant, StorageKey } from '@fp/constant';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { DataResult, Member, TermsAndConditions, NewTermsAndConditions, User, ResultModel } from '@fp/models';
import { MemberContainer } from '@fp/models/member-container.model';
import * as enums from "src/app/enums";
import { CommonService, MemberService } from '@fp/services';
import { ReactivationMemberDetailsComponent } from './reactivation-member-details/reactivation-member-details.component';
import { FormBuilder } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ReactivationMembershipPackageDetailsComponent } from './reactivation-membership-package-details/reactivation-membership-package-details.component';
import { ReactivationPaymentDetailsComponent } from './reactivation-payment-details/reactivation-payment-details.component';
import { ReactivationEmployerDetailsComponent } from './reactivation-employer-details/reactivation-employer-details.component';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonDataService } from "src/app/services/common-data.service";
import { CommonConstants } from '@fp/constant/common-constants';
import { Utils } from '@fp/helpers/utils';
import { CheckMembersUniqueEmailMobile } from '@fp/models/check-unique-email-mobile-request.model';
import { CheckMembersUniqueEmailMobileReponse } from '@fp/models/check-unique-email-mobile-response.model';
import { EMemberType } from 'src/app/enums';

enum tabIndex {
  MemberDetailTab,
  EmployerDetailsTab,
  MemberShipPackagesTab,
  PaymentDetailsTab
}

@Component({
  selector: 'app-member-reactivation',
  templateUrl: './member-reactivation.component.html',
  styleUrls: ['./member-reactivation.component.css'],
  providers: [{ provide: MemberFormBase, useExisting: MemberReactivationComponent }]
})
export class MemberReactivationComponent extends MemberFormBase {
  currentTermsAndConditions: TermsAndConditions;
  currentKeyTermsAndConditions: TermsAndConditions;
  termsAndConsContent: SafeHtml;
  keyTermsAndConsContent: SafeHtml;
  isTermsAndConsAccepted = false;
  isKeyTermsAndConsAccepted = false;
  isDataSubmitted = false;
  disablechkTermCons = true;
  openKeyModal = false;
  disablechkKeyTermCons = true;
  checkscroll = false;
  keyCheckscroll = false;
  heightTCText: string;
  heightKeyTCText: string;
  countriesId = CommonConstants.GET_COUNTRIES;
  fadeMainTerm = false;
  hasInvalidFamilyMemberDetails = false;
  selectedPartnerCount: number = 0;
  familyMemberValidationText: string = '';
  partnerValidationText: string = '';
  dependantAgeValidationText: string = '';
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  @ViewChild('tabfpstepper', { static: true }) tabfpstepper: ElementRef;
  @ViewChild('paymentDetails') paymentDetails: ReactivationPaymentDetailsComponent;
  @ViewChild('memberDetails') memberDetails: ReactivationMemberDetailsComponent;
  @ViewChild('membershipDetails') membershipDetails: ReactivationMembershipPackageDetailsComponent;
  @ViewChild('employerDetails') employerDetails: ReactivationEmployerDetailsComponent;
  @ViewChild("dlgTermsConsContent", { static: true }) dlgTermsCons: ElementRef;
  @ViewChild("dlgKeyTermsConsContent", { static: true }) dlgKeyTermsCons: ElementRef;

  tabIndex = tabIndex;
  heighttabs: string;
  currentStepIndex: number = 0;

  constructor(
    injector: Injector,
    private router: Router,
    private commonservice: CommonService,
    private commonDataSvc: CommonDataService,
    private sanitizer: DomSanitizer,
    private modal: NgbModal,
    private memberSvc: MemberService,
  ) {
    super(injector);
    this.commonservice.Header.title = 'Reactivate Membership';
    this.form = MemberReactivationComponent.getFormGroup();
  }

  ngOnInit(): void {
    MessageBox.ShowCustom(this.dialog, 'Reactivation Request', 'Reactivation Request',
      "<div style='text-align: center'>It\'s great to hear that you want to renew your Fitness Passport membership! . <br><br>\
                        Please also ensure to read the terms and conditions provided at the end of the  reactivation steps. <br><br>\
                        Once we receive this information, we will reactivate your membership and respond with confirmation details. Your membership will also need to be approved by your Workplace Facilitator. <br><br><br>\
                        Click Next to Proceed with your renewal.<br><br><br></div>",
      MessageBoxButton.BackNext).subscribe(r => {
        if (r.result == DialogResult.Cancel) {
          this.router.navigate([RouterConstant.NAVIGATOR_CONTINUE_REACTIVATE_MEMBER]);
        }
      });
  }

  loadInitialData(): void {
    this.loadMemberDetails();
  }

  static getFormGroup() {
    const fb = new FormBuilder();
    return fb.group({
      MemberDetails: ReactivationMemberDetailsComponent.getFormGroup(),
      EmployerDetails: ReactivationEmployerDetailsComponent.getFormGroup(),
      MembershipDetails: ReactivationMembershipPackageDetailsComponent.getFormGroup(),
      PaymentDetails: ReactivationPaymentDetailsComponent.getFormGroup()
    });
  }

  ngAfterViewInit() {
    this.loadInitialData();
    super.OnInit();
    this.LoadComplete();
  }

  ngAfterViewChecked() {

    if (!this.commonservice.App.mobileQuery.matches) {
      this.heighttabs = this.tabfpstepper
        .nativeElement
        .querySelector('#membrreactivationtb' + this.stepper.selectedIndex)
        .clientHeight + 'px';
    } else {
      this.heighttabs = '100%';
    }
    if (this.disablechkTermCons) {
      var termscondObj = document.getElementById("fp-termscondText");
      var termscondContObj = document.getElementById("fp-tcContent");
      if (termscondObj) {
        if (termscondContObj.innerHTML && this.checkscroll) {
          if (termscondObj.scrollHeight == termscondObj.offsetHeight) {
            this.disablechkTermCons = false;
          }
        }
      }
      if (!this.heightTCText) {
        this.setHeightContent();
      }
    }

    if (this.disablechkKeyTermCons) {
      var termscondObj = document.getElementById("fp-keyTermscondText");
      var termscondContObj = document.getElementById("fp-KeytcContent");
      if (termscondObj) {
        if (termscondContObj.innerHTML && this.keyCheckscroll) {
          if (termscondObj.scrollHeight == termscondObj.offsetHeight) {
            this.disablechkKeyTermCons = false;
          }
        }
      }
      if (!this.heightKeyTCText) {
        this.keySetHeightContent();
      }
    }
  }

  OnDestroy() {
    this.modal.dismissAll();
  }

  LoadComplete() {
    super.LoadComplete();
    setTimeout(() => {
      this.stepper.steps.forEach((step, idx) => {
        step.select = () => {

          if (idx === 0) {
            this.currentStepIndex = 0;
            this.stepper.selectedIndex = 0;
          }
          else if ((idx === this.currentStepIndex + 1) || idx <= this.currentStepIndex) {
            this.stepper.selectedIndex = idx;
            this.currentStepIndex = idx;
          }
          else {
            this.stepper.selectedIndex = this.currentStepIndex;
          }
        };
      });
       
        this.memberDetails.addressContactDetails.getControl('PrimaryEmail').valueChanges.subscribe((value) => {          
          if (Utils.calculateAge(<Date>this.data.model.DateOfBirth) >= 18 || this.data.model.MemberTypeId == EMemberType.Partner) {
            this.memberDetails.addressContactDetails.requireEmailValidation = true;           
          } else{ 
            let dataModelEmail;
            if(this.data.model && this.data.model.PrimaryMember != null){
              dataModelEmail = this.data.model && this.data.model.PrimaryMember && this.data.model.PrimaryMember.PrimaryEmail;
            }else{
              dataModelEmail = this.data.model && this.data.model.PrimaryEmail;
            }           
            
            if(dataModelEmail.toLowerCase() == value){
              this.memberDetails.addressContactDetails.requireEmailValidation = false;        
              this.memberDetails.addressContactDetails.ValidatePrimaryEmail();                       
            }else{                
              this.memberDetails.addressContactDetails.requireEmailValidation = true;                
              this.memberDetails.addressContactDetails.ValidatePrimaryEmail();                
            }     
          }
        });  
    });
  }

  loadMemberDetails() {
    var currentUser = <User>JSON.parse(this.commonservice.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT)));
    const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
    if (getContainer != null) {
      let container = <MemberContainer>JSON.parse(getContainer);
      var currentMember = <DataResult<Member>>JSON.parse(this.commonSvc.D_FP_AES256(container.Member));
    }

    if (currentUser && currentUser.UserId > 0) {
      if (currentMember != null) {
        this.bindMemberData(currentMember);
      }
      else {
        this.Invoke(
          this.memberSvc.getByUserForMember(currentUser.UserId),
          {
            onSuccess: (res: DataResult<Member>) => {
              this.bindMemberData(res);
            }
          }
        );
      }
    } else {
      MessageBox.ShowError(this.dialog, 'Invalid user');
    }
  }

  bindMemberData(member: DataResult<Member>) {

    this.data.model = member.Data;
    this.data.model.IsReactivated = true;

    if(this.data.model.DateOfBirth && typeof this.data.model.DateOfBirth === 'string'){
      this.data.model.DateOfBirth = new Date(this.data.model.DateOfBirth);
    }

    if(this.data.model.FamilyMembers.length > 0){
      this.data.model.FamilyMembers.forEach(member => {
        if(this.data.model.DateOfBirth && typeof member.DateOfBirth === 'string'){
          member.DateOfBirth = new Date(member.DateOfBirth);
        }
      });
    }
    this.PatchValue(this.data.model, {
      emitEvent: false,
    });
  }


  reactivateMember() {
    this.data.model = this.applyValue(this.data.model, this);

    const processedData = this.processDataForSubmission(this.data.model);

    this.Invoke(
      this.memberSvc.ReactivateMember(processedData),
      {
        onSuccess: (result: ResultModel) => {
          if (result.Success) {
            const currentUser = <User>JSON.parse(this.commonservice.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
            this.memberSvc
              .getByUserForMember(currentUser.UserId)
              .subscribe((getMember) => {
                const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
                if (getContainer != null) {
                  let container = <MemberContainer>JSON.parse(getContainer);
                  let memberEncrypt = this.commonservice.E_FP_AES256(
                    JSON.stringify(getMember)
                  );
                  container.Member = memberEncrypt;
                  const loginData = JSON.stringify(container);
                  localStorage.setItem(
                    StorageKey.CONTAINER_OBJECT,
                    loginData
                  );
                }
                if (getMember.Data.FamilyObject) {
                  this.commonservice.SetMemFamObj(
                    getMember.Data.FamilyObject
                  );
                }

                var currentDate = new Date().toLocaleDateString('en-GB', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric'
                });
                MessageBox.ShowCustom(this.dialog, 'We’ve received your reactivation request!', 'We’ve received your reactivation request!',
                  "<div style='text-align: center'>Your application is now pending approval by your workplace. Once approved,\
                                           you’ll receive an email with information on your first payment and accessing facilities.<br><br>\
                                           We're glad to have you back onboard and hope you enjoy the program! <br><br><br></div>",
                  MessageBoxButton.Ok, false, 0, "600px").subscribe(r => {
                    if (r.result == DialogResult.Ok) {
                      localStorage.removeItem("reactivationFlag");
                      this.router.navigate([RouterConstant.NAVIGATOR_MEMBER_DETAILS]);
                      this.modal.dismissAll();
                    }
                  });
              });
          } else {
            MessageBox.ShowOKCustom(
              this.dialog,
              "Error",
              "Sorry, something went wrong before we could complete your \
                            reactivation. Please log in and complete the reactivation process. You can use \
                            your email and new password to login.",
              "Take me back"
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
              }
            });
            this._logger.error(result);
          }
        },
        onError: (err) => {
          const errMsg =
            "Sorry, there was a connection issue. Please try submitting the information again.";
          this.handleError(err, errMsg);
        },
      }
    );
  }

  btnSubmit_Click() {
    if (this.isKeyTermsAndConsAccepted && !this.isDataSubmitted) {
      this.isDataSubmitted = true;
      this.data.model.TermConditionDateTimeStamp = new Date();
      this.data.model.TermConditionId = this.currentKeyTermsAndConditions.TermConditionId;
      this.reactivateMember();
    } else {
      return;
    }

  }

  checkFamilyMemberDataValidity(callBackFunction: Function) {
    if (this.data.model.FamilyMembers.length > 0) {

      const today = new Date();
      today.getHours;

      const eighteenYearsAgo = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate(),
        today.getHours(),
        today.getMinutes(),
        today.getMinutes(),
        today.getSeconds()
      );

      const twentyFiveYearsAgo = new Date(
        today.getFullYear() - 25,
        today.getMonth(),
        today.getDate(),
        today.getHours(),
        today.getMinutes(),
        today.getMinutes(),
        today.getSeconds()
      );

      var listOfMembersToCheck: CheckMembersUniqueEmailMobile[] = [];
      this.familyMemberValidationText = '';
      this.partnerValidationText = '';
      this.dependantAgeValidationText = '';
      this.hasInvalidFamilyMemberDetails = false;

        this.selectedPartnerCount = 0;
        this.data.model.FamilyMembers.forEach((member) => {
          if (member.MemberTypeId === enums.EMemberType.Partner && member.IsReactivated) {
            this.selectedPartnerCount++;
          }
        });
        if (this.selectedPartnerCount > 1) {
          this.partnerValidationText = 'A family package includes one partner. Please select one partner.';
        }

        this.data.model.FamilyMembers.forEach((member) => {
            var memberAge = new Date(member.DateOfBirth);
            if (member.MemberTypeId === enums.EMemberType.Dependant && memberAge < twentyFiveYearsAgo && member.IsReactivated) {
              this.dependantAgeValidationText = 'Dependants are only eligible if they are under 25 years old.';
            }
          });

        if (this.partnerValidationText.length > 0) {
          this.hasInvalidFamilyMemberDetails = true;
          callBackFunction();
          return;
        }

        if (this.dependantAgeValidationText.length > 0) {
          this.hasInvalidFamilyMemberDetails = true;
          callBackFunction();
          return;
        }

      this.data.model.FamilyMembers.forEach((member) => {

        var memberAge = new Date(member.DateOfBirth);
        if (((member.MemberTypeId === enums.EMemberType.Partner) || (member.MemberTypeId === enums.EMemberType.Dependant && memberAge < eighteenYearsAgo))
          && member.IsReactivated && !member.UserId) {
          if (member.PrimaryEmail.toLowerCase() === this.data.model.PrimaryEmail.toLowerCase() || member.MobileNumber === this.data.model.MobileNumber) {
            this.hasInvalidFamilyMemberDetails = true;

            if (!this.familyMemberValidationText) {
              this.familyMemberValidationText = 'Primary Email and Mobile Number needs to be updated. <br/>' + 'Please update member(s): '
                + member.FirstName + ' ' + member.LastName;
            }
            else {
              this.familyMemberValidationText = this.familyMemberValidationText + ',' + member.FirstName + ' ' + member.LastName;
            }
          }
          else {
            var memberDetail = {} as CheckMembersUniqueEmailMobile;
            memberDetail.MemberId = member.MemberId;
            memberDetail.EmailAddress = member.PrimaryEmail;
            memberDetail.MobileNumber = member.MobileNumber;
            listOfMembersToCheck.push(memberDetail);
          }
        }
      });

      if (listOfMembersToCheck.length > 0) {
        this.getEligibleMembersForUserAccountCreation(listOfMembersToCheck, callBackFunction);
      }
      else {
        callBackFunction();
      }
    }
    else {
      callBackFunction();
    }
  }

  private getEligibleMembersForUserAccountCreation(request: CheckMembersUniqueEmailMobile[], callBackFunction: Function) {
    this.Invoke(
      this.memberSvc.checkUniqueMobileEmail(request),
      {
        onSuccess: (res: DataResult<string>) => {
          if (res.Success) {

            const enctypted_data = res.Data;
            const dectypted_data = this.commonservice.D_FP_ResponseData(enctypted_data);
            const memberDetails: CheckMembersUniqueEmailMobileReponse[] = JSON.parse(dectypted_data);
            var uniqueMemberDetails = memberDetails.filter(m => m.IsUnique == false);

            if (uniqueMemberDetails.length > 0) {

              this.hasInvalidFamilyMemberDetails = true;
              uniqueMemberDetails.forEach(memberData => {

                var member = this.data.model.FamilyMembers.find(m => m.MemberId == memberData.MemberId);
                if (!this.familyMemberValidationText) {
                  this.familyMemberValidationText = 'Primay Email and Mobile Number already exists.<br/>' + 'Please update member(s): '
                    + member.FirstName + ' ' + member.LastName;
                }
                else {
                  this.familyMemberValidationText = this.familyMemberValidationText + ',' + member.FirstName + ' ' + member.LastName;
                }
              });
            }
            else {
              this.hasInvalidFamilyMemberDetails = false;
              this.familyMemberValidationText = '';
            }

          } else {
            MessageBox.ShowOKCustom(
              this.dialog,
              "Error",
              "Sorry, something went wrong before we could complete your \
                            reactivation. Please log in and complete the reactivation process. You can use \
                            your email and new password to login.",
              "Take me back"
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
              }
            });
            this._logger.error(res);
          }
        },
        onError: (err) => {
          const errMsg =
            "Sorry, there was a connection issue. Please try submitting the information again.";
          this.handleError(err, errMsg);
        },
        onComplete: () => {
          callBackFunction();
        }
      }
    );
  }

  private goToEmployerDetailsTab() {
    if (this.memberDetails?.valid && !this.hasInvalidFamilyMemberDetails) {
      this.currentStepIndex = tabIndex.EmployerDetailsTab;
      this.stepper.next();
    }
  }

  private goToTermsAndConditions() {
    if (this.paymentDetails?.valid && this.form?.valid && !this.hasInvalidFamilyMemberDetails) {
      this.currentStepIndex = tabIndex.PaymentDetailsTab;
      this.applyStepValue(tabIndex.PaymentDetailsTab);
      this.showTermsAndConditions();
    }
    else if (this.hasInvalidFamilyMemberDetails) {
      this.currentStepIndex = tabIndex.MemberDetailTab;
      this.stepper.selectedIndex = tabIndex.MemberDetailTab;
    }
  }

  btnNext_Click() {

    switch (this.stepper.selectedIndex) {
      case tabIndex.MemberDetailTab:
        this.memberDetails?.Validate();
        this.checkFamilyMemberDataValidity(this.goToEmployerDetailsTab.bind(this));
        break;
      case tabIndex.EmployerDetailsTab:
        this.employerDetails?.Validate();
        if (this.employerDetails?.valid) {
          this.currentStepIndex = tabIndex.MemberShipPackagesTab;
          this.stepper.next();
        }
        break;
      case tabIndex.MemberShipPackagesTab:
        this.membershipDetails?.Validate();
        if (this.membershipDetails?.valid) {
          this.currentStepIndex = tabIndex.PaymentDetailsTab;
          this.stepper.next();
        }
        else {
          MessageBox.ShowCustom(this.dialog, 'Warning', 'Warning', 'Please choose 1 membership package above');
        }
        break;
      case tabIndex.PaymentDetailsTab:
        this.paymentDetails?.Validate();
        this.checkFamilyMemberDataValidity(this.goToTermsAndConditions.bind(this));
        break;
    }
  }

  btnBack_Click() {
    switch (this.stepper.selectedIndex) {
      case tabIndex.MemberDetailTab:
        this.currentStepIndex = tabIndex.MemberDetailTab;
        this.router.navigate([RouterConstant.NAVIGATOR_CONTINUE_REACTIVATE_MEMBER]);
        break;
      case tabIndex.EmployerDetailsTab:
        this.currentStepIndex = tabIndex.EmployerDetailsTab;
        this.applyStepValue(tabIndex.EmployerDetailsTab);
        this.stepper.previous();
        break;
      case tabIndex.MemberShipPackagesTab:
        this.currentStepIndex = tabIndex.MemberShipPackagesTab;
        this.applyStepValue(tabIndex.MemberShipPackagesTab);
        this.stepper.previous();
        break;
      case tabIndex.PaymentDetailsTab:
        this.currentStepIndex = tabIndex.PaymentDetailsTab;
        this.applyStepValue(tabIndex.PaymentDetailsTab);
        this.stepper.previous();
        break;
    }
  }

  onStepChange(e: StepperSelectionEvent) {

    if (e.selectedIndex != 0) {
      this.currentStepIndex = e.selectedIndex;
    }
    else {
      this.currentStepIndex = 0;
    }
    this.data.model.MobileNumber = '+' + this.memberDetails.addressContactDetails.getControl('MobileNumber').value;
    const index = e.selectedIndex;
    if (e.selectedIndex > e.previouslySelectedIndex) {
      this.applyStepValue(e.previouslySelectedIndex);
      this.PatchValue(this.data.model, { emitEvent: false });
    }

    const com = this.children.toArray()[index];
    if (com && !com.loaded) {
      com.Load();
    }
  }

  applyStepValue(index: number) {
    switch (index) {
      case tabIndex.MemberDetailTab:
        this.data.model = this.applyValue(this.data.model, this.memberDetails);
        break;
      case tabIndex.EmployerDetailsTab:
        this.data.model = this.applyValue(this.data.model, this.employerDetails);
        break;
      case tabIndex.MemberShipPackagesTab:
        this.data.model = this.applyValue(this.data.model, this.membershipDetails);
        break;
      case tabIndex.PaymentDetailsTab:
        this.data.model = this.applyValue(this.data.model, this.paymentDetails);
        break;
    }
  }

  showTermsAndConditions() {
    const countryId = this.employerDetails.isFromAustralia ? this.countriesId[0].CountryId : this.countriesId[1].CountryId;

    this.Invoke(
      this.commonDataSvc.getAllTermsAndConditions(),
      {
        onSuccess: (res: DataResult<NewTermsAndConditions[]>) => {
          if (res.Success) {
            const primaryTermData = res.Data.filter(term => term.CountryCode === countryId && term.TermConditionType === CommonConstants.PREMIUM_TERMS)[0];
            this.currentTermsAndConditions = primaryTermData;
            this.termsAndConsContent = this.sanitizer.bypassSecurityTrustHtml(
              this.currentTermsAndConditions.Content
            );
            this.modal.open(this.dlgTermsCons, {
              windowClass: "membersignup-dialog termconditions-dialog",
              backdrop: "static",
            });
            this.checkscroll = true;
          } else {
            MessageBox.ShowOKCustom(
              this.dialog,
              "Error",
              "Sorry, something went wrong before we could complete your \
                            reactivation. Please log in and complete the reactivation process. You can use \
                            your email and new password to login.",
              "Take me back"
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
              }
            });
            this._logger.error(res);
          }
        },
        onError: (err) => {
          const errMsg =
            "Sorry, there was a connection issue. Please try submitting the information again.";
          this.handleError(err, errMsg);
        },
      }
    );
  }

  showKeyTermsAndConditions() {
    const countryId = this.employerDetails.isFromAustralia ? this.countriesId[0].CountryId : this.countriesId[1].CountryId;

    this.Invoke(
      this.commonDataSvc.getAllTermsAndConditions(),
      {
        onSuccess: (res: DataResult<NewTermsAndConditions[]>) => {
          if (res.Success) {
            this.fadeMainTerm = true;
            const primaryKeyTermData = res.Data.filter(term => term.CountryCode === countryId && term.TermConditionType === CommonConstants.PREMIUM_KEY_TERMS)[0];
            this.currentKeyTermsAndConditions = primaryKeyTermData;
            this.keyTermsAndConsContent = this.sanitizer.bypassSecurityTrustHtml(
              this.currentKeyTermsAndConditions.Content
            );
            this.openKeyModal = true;
            this.modal.open(this.dlgKeyTermsCons, {
              windowClass: "selectPackage-add-member-dialog",
              backdrop: "static",
            });
            this.keyCheckscroll = true;
          } else {
            MessageBox.ShowOKCustom(
              this.dialog,
              "Error",
              "Sorry, something went wrong before we could complete your \
                            reactivation. Please log in and complete the reactivation process. You can use \
                            your email and new password to login.",
              "Take me back"
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
              }
            });
            this._logger.error(res);
          }
        },
        onError: (err) => {
          const errMsg =
            "Sorry, there was a connection issue. Please try submitting the information again.";
          this.handleError(err, errMsg);
        },
      }
    );
  }

  public onScroll() {
    if (this.disablechkTermCons) {
      this.checkScrollEndContent();
    }
  }

  public keyTermOnScroll() {
    if (this.disablechkKeyTermCons) {
      this.checkScrollEndKeyTermContent();
    }
  }

  public onResize() {
    this.setHeightContent();
    this.keySetHeightContent();
  }

  public checkScrollEndContent() {
    var termscondObj = document.getElementById("fp-termscondText");
    var termscondContObj = document.getElementById("fp-tcContent");
    var termscondEndContObj = document.getElementById("fp-tcEndContent");
    if (termscondContObj) {
      if (
        termscondContObj.innerHTML &&
        this.checkscroll &&
        this.disablechkTermCons
      ) {
        if (
          termscondEndContObj.getBoundingClientRect().bottom <
          termscondObj.getBoundingClientRect().bottom
        ) {
          this.disablechkTermCons = false;
          this.checkscroll = false;
        }
      }
    }
  }

  public checkScrollEndKeyTermContent() {
    var termscondObj = document.getElementById("fp-keyTermscondText");
    var termscondContObj = document.getElementById("fp-KeytcContent");
    var termscondEndContObj = document.getElementById("fp-KeytcEndContent");

    if (termscondContObj) {
      if (
        termscondContObj.innerHTML &&
        this.keyCheckscroll &&
        this.disablechkKeyTermCons
      ) {
        if (
          termscondEndContObj.getBoundingClientRect().bottom <
          termscondObj.getBoundingClientRect().bottom
        ) {

          this.disablechkKeyTermCons = false;
          this.keyCheckscroll = false;
        }
      }
    }
  }

  public setHeightContent() {
    var termscondContObj = document.getElementById("fp-tcContent");
    if (termscondContObj) {
      this.heightTCText =
        window.innerHeight -
        document.getElementById("modalheader").offsetHeight -
        document.getElementById("chkTC").offsetHeight -
        document.getElementById("btnTC").offsetHeight +
        "px";
    }
  }

  public keySetHeightContent() {
    var termscondContObj = document.getElementById("fp-KeytcContent");
    if (termscondContObj) {
      this.heightKeyTCText =
        window.innerHeight -
        document.getElementById("keyModalheader").offsetHeight -
        document.getElementById("keyChkTC").offsetHeight -
        document.getElementById("keyBtnTC").offsetHeight +
        "px";
    }
  }

  termModalBack(e) {
    e.dismiss()
    this.disablechkTermCons = true;
  }

  keyTermModalBack(e) {
    e.dismiss();
    this.fadeMainTerm = false;
    this.disablechkKeyTermCons = true;
    this.isKeyTermsAndConsAccepted = false;
    this.isDataSubmitted = false;
  }

  private processDataForSubmission(source: Member) {
    const target = Object.assign({}, source);
    target.MobileNumber = '+' + Utils.convertPhoneToInternationalFormatWithCountryCode(target.MobileNumber);

    if (target.Address && target.Address.StreetAddress && target.Address.StreetAddress.Text) {
      target.Address.StreetAddress1 = target.Address.StreetAddress.Text;
    } else if (target.Address && target.Address.StreetAddress && !target.Address.StreetAddress.Text) {
      target.Address.StreetAddress1 = String(target.Address.StreetAddress)
    }

    if (target.Employer) {
      target.EmployerId = target.Employer.EmployerId;
      delete target.Employer;
    }
    if (target.MemberType) {
      target.MemberTypeId = target.MemberType.MemberTypeId;
      delete target.MemberType;
    }
    if (target.MemberStatus) {
      target.MemberStatusId = target.MemberStatus.MemberStatusId;
      delete target.MemberStatus;
    }
    if (target.FavouredFacility) {
      target.FavouredFacilityId = target.FavouredFacility.FacilityId;
      delete target.FavouredFacility;
    }
    if (target.ExistingFacility) {
      target.ExistingMemberFacilityId = target.ExistingFacility.FacilityId;
      delete target.ExistingFacility;
    }
    if (target.MedicalAnswers instanceof Array) {
      target.MedicalAnswers.forEach((answer) => {
        delete answer.MedicalQuestion;
      });
    }
    if (target.MemberIdPrimary > 0) {
      target.EmployerId = null;
      target.EmployeePayrollNo = null;
      target.Memberships = null;
      target.Address = null;
      target.AddressId = null;
      target.BankAccount = null;
      target.BankAccountId = null;
    }
    if (target.PrimaryMember) {
      delete target.PrimaryMember;
    }

    if (target.UserId === 0) {
      target.UserId = null;
    }

    if (target.FamilyMembers && target.FamilyMembers.length > 0) {
      target.FamilyMembers.forEach((member) => {
        if (member.UserId === 0) {
          member.UserId = null;
        }
        member.FamilyMembers = null;
        member.PrimaryMember = null;
      });
    }

    delete target.Suburb;
    delete target.Postcode;
    delete target.State;
    delete target.Country;
    for (const key in target) {
      if (target.hasOwnProperty(key) && target[key] == null) {
        delete target[key];
      }
    }
    return target;
  }

}
