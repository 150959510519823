import { Component, OnInit } from '@angular/core';
import { AuditLoggingService } from '@fp/services/audit-logging.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BotLogs } from '@fp/models/audit-logging.model';
import { MatTableDataSource } from '@angular/material/table';
import { DataResult } from '@fp/models';
import { CommonService } from '@fp/services';

@Component({
  selector: 'app-bot-logs',
  templateUrl: './bot-logs.component.html',
  styleUrls: ['./bot-logs.component.css']
})
export class BotLogsComponent implements OnInit {
  dataSource: MatTableDataSource<BotLogs>;
  displayedColumns: string[];

  constructor(private BotLogsService: AuditLoggingService,private comsvr: CommonService) { }

  ngOnInit() {
    this.comsvr.Header.title="Bot Logs";
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError(e => {
          throw e;
        })
      )
      .subscribe(res => {
        handleResultCallback(res);
      });
  }

  refreshLogs() {
    this.getBotLogs();
  }

  getBotLogs() {
    this.displayedColumns = [
      "BotName",
      "BotNameStartTime",
      "BotNameEndTime",
      "BotNameStatus"
    ];

    this.Invoke(
      this.BotLogsService.getBotLogs(),
      (result: DataResult<BotLogs[]>) => {
        this.dataSource = new MatTableDataSource<BotLogs>(
          result.Data
        );
        console.log("Bot logs: ", result.Data);
      }
    );

  }

}
