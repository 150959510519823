import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function nzPrefixValidator(): ValidatorFn {
  const NEW_ZEALAND_LOCAL_PREFIXES = ["021", "022", "027"];
  
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const phone = control.value.toString();
    const isValidPrefix = NEW_ZEALAND_LOCAL_PREFIXES.some(prefix => 
      phone.startsWith(prefix) || phone.startsWith(prefix.substring(1))
    );
    const isValidLength = phone.length === 9 || phone.length === 10;
    const isValid = isValidPrefix && isValidLength
    return isValid ? null : { isValidPrefix, isValidLength };
  };
}

export function auPrefixValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const phone = control.value.toString();
    const isValidPrefix = phone.startsWith("04") || phone.startsWith("4");
    const isValidLength = phone.length === 9 || phone.length === 10;
    const isValid = isValidPrefix && isValidLength
  
    return isValid ? null : { isValidPrefix, isValidLength };
  };
}