<div [formGroup]="form">
    <div class="mx-auto tabProgress w-50 d-none d-lg-flex mt-3">
        <div class="flex-fill text-center">
            <div class="card-icon rounded-circle mx-auto"
                [ngClass]="stepper.selectedIndex == tabIndex.EmployerDetailsTab || employerInfo?.valid ? 'fas fa-checked' : null">
                <i [ngClass]="employerInfo?.valid && stepper.selectedIndex != tabIndex.EmployerDetailsTab ? 'fas fa-check text-light' : null"></i>
            </div>
            <p class="card-text mb-3">Employer</p>
        </div>
        <div class="flex-fill text-center">
            <div class="card-icon rounded-circle mx-auto"
                [ngClass]="{'fas fa-checked': membershipPackages?.valid || stepper.selectedIndex == tabIndex.MemberShipPackagesTab }">
                <i
                    [ngClass]="membershipPackages?.valid && stepper.selectedIndex != tabIndex.MemberShipPackagesTab ? 'fas fa-check text-light' : null"></i>
            </div>
            <p class="card-text mb-3">Membership Packages</p>
        </div>
        <div class="flex-fill text-center">
            <div class="card-icon rounded-circle mx-auto"
                [ngClass]="{'fas fa-checked': (paymentDetails?.valid && membershipPackages?.valid) || stepper.selectedIndex == tabIndex.PaymentDetailsTab}">
                <i
                    [ngClass]="(paymentDetails?.valid && membershipPackages?.valid) && stepper.selectedIndex != tabIndex.PaymentDetailsTab ? 'fas fa-check text-light' : null"></i>
            </div>
            <p class="card-text mb-3">Payment Details</p>
        </div>
    </div>
    <div class="tabFPTheme mb-5" #tabfpstepper [style.height]="heighttabs">
        <mat-vertical-stepper #stepper [linear]="true" (selectionChange)="onStepChange($event)">
            <mat-step [stepControl]="employerInfoTabValidityCtrl">
                <form id="membrsignuptb0">
                    <ng-template matStepLabel>
                        <i class="icon-fp d-lg-none"
                            [ngClass]="{'fas fa-check': employerInfo?.valid}"></i><span>Employer</span>
                    </ng-template>
                    <app-member-employer-info [form]="form.get('EmployerInfo')" [data]="data" #employerInfo
                    [isMemberTransfer]="true"></app-member-employer-info>
                    <div
                        class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                        <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                            (click)="backToLandingPage()">
                            <i class="fas fa-arrow-circle-left mr-2"></i>Back</button>
                        <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="btnNext_Click()">
                            <i class="fas fa-arrow-circle-right mr-2"></i>Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="form.get('MembershipPackages')">
                <form id="membrsignuptb1">
                    <ng-template matStepLabel>
                        <i class="icon-fp d-lg-none"
                            [ngClass]="{'fas fa-check': membershipPackages?.valid}"></i>Membership
                        Packages
                    </ng-template>
                    <app-member-membership-packages [form]="form.get('MembershipPackages')" [data]="data"
                        #membershipPackages [loadOnDemand]="true"></app-member-membership-packages>
                    <div
                        class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                        <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                            (click)="backEmployerInfo()">
                            <i class="fas fa-arrow-circle-left mr-2"></i>Back</button>
                        <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="btnNext_Click(true)">
                            <i class="fas fa-arrow-circle-right mr-2"></i>Next</button>
                    </div>
                </form>

            </mat-step>
            <mat-step [stepControl]="form.get('PaymentDetails')">
                <form id="membrsignuptb2">
                    <ng-template matStepLabel>
                        <i class="icon-fp d-lg-none" [ngClass]="{'fas fa-check': paymentDetails?.valid}"></i>Payment
                        Details</ng-template>
                    <app-member-payment-details [loadOnDemand]="true" [form]="form.get('PaymentDetails')" [data]="data"
                        #paymentDetails></app-member-payment-details>
                    <div
                        class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                        <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="submit()">
                            <i class="fas fa-arrow-circle-right mr-2"></i>Submit</button>
                    </div>
                </form>

            </mat-step>
        </mat-vertical-stepper>
    </div>

</div>