import { Component, OnInit, ElementRef, ViewChild, AfterViewChecked, Injector } from '@angular/core';
import { CommonService } from '@fp/services/common.service';
import { MatStepper } from '@angular/material/stepper';
import { UntypedFormBuilder } from '@angular/forms';
import { MemberFormBase, FPFormBaseComponent } from '@fp/components/base';
import { MemberMembershipPackagesComponent, MemberPaymentDetailsComponent } from '../../shared';
import { MemberService, MessageBox, MessageBoxButton, MembershipService } from '@fp/services';
import { DataResult, Member, Membership, ResultDataModel, User } from '@fp/models';
import { RouterConstant, CommonMessage, StorageKey } from '@fp/constant';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Utils } from '@fp/helpers';
import { DialogResult } from '@fp/components/common-dialog/common-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { EMemberType, EMemberStatus } from '@fp/enums';
import { MemberContainer } from '@fp/models/member-container.model';

enum EChangeMembershipPackTab {
    MembershipPackages = 0,
    PaymentDetails = 1,
}

@Component({
    selector: 'app-change-membership-package',
    templateUrl: './change-membership-package.component.html',
    styleUrls: ['./change-membership-package.component.css'],
    providers: [
        FPFormBaseComponent.provideExisting(MemberChangeMembershipPackageComponent),
        MemberFormBase.provideExisting(MemberChangeMembershipPackageComponent)]
})
export class MemberChangeMembershipPackageComponent extends MemberFormBase implements AfterViewChecked {
    heighttabs: string;
    @ViewChild('stepper', {static: true}) stepper: MatStepper;
    @ViewChild('tabfpstepper', {static: true}) tabfpstepper: ElementRef;
    @ViewChild('membershipPackages') membershipPackages: MemberMembershipPackagesComponent;
    @ViewChild('paymentDetails') paymentDetails: MemberPaymentDetailsComponent;

    stepProgress: boolean[] = [];
    hasInit: boolean = false;
    MemberMemberships: Membership[];
    EChangeMembershipPackTab = EChangeMembershipPackTab;
    isAddingFamilyMember: boolean = false;

    constructor(
        injector: Injector,
        private commonservice: CommonService,
        private memberSvc: MemberService,
        private route: ActivatedRoute,
        private router: Router,
        private membershipSvc: MembershipService,
    ) {
        super(injector);
        this.async = false;
        this.form = MemberChangeMembershipPackageComponent.getFormGroup();
    }

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            MembershipPackages: MemberMembershipPackagesComponent.getFormGroup(),
            PaymentDetails: MemberPaymentDetailsComponent.getFormGroup(false)
        });
    }

    ngAfterViewChecked() {
        if (!this.commonservice.App.mobileQuery.matches) {
            this.heighttabs = this.tabfpstepper
                .nativeElement
                .querySelector('#changemempack' + this.stepper.selectedIndex)
                .clientHeight + 'px';
        } else {
            this.heighttabs = '100%';
        }
        if(!this.hasInit) {
            this.hasInit = true;
            this.OnLoad();
            this.OnInit();
        }
    }

    OnInit() {
        this.stepper._stepHeader.forEach((header, index) => {
            header._getHostElement().addEventListener('click', (e: MouseEvent) => {
                const showMessage = !(this.stepper.selectedIndex === EChangeMembershipPackTab.MembershipPackages
                    && index < this.stepper.selectedIndex);
                this.membershipPackages.showMessageBox = showMessage;
                if (!this.validateStep(this.stepper.selectedIndex)) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    return false;
                }
                if (this.stepper.selectedIndex == EChangeMembershipPackTab.MembershipPackages && index > this.stepper.selectedIndex) {
                    // check change membership package in a month
                    this.CheckMemPakgChangeInMonth((isValid) => {
                        if (isValid) {
                            // check change membership or not
                            const membershipPackage = this.data.model.Memberships[0].MembershipPackage;
                            if (this.MemberMemberships.length > 0 &&
                                membershipPackage.MembershipPackageId !== this.MemberMemberships[0].MembershipPackageId) {
                                MessageBox.ShowCustom(this.dialog,
                                    'Change of Membership Package Confirmation',
                                    'Change of Membership Package Confirmation',
                                    'By clicking the below "Confirm" button you confirm you have agreed to change your family\'s \
                            Fitness Passport membership package. \
                            You will not be able to change this package for a minimum of one month from the date of this change request.',
                                    MessageBoxButton.ConfirmCancel, false, 0, '500px').subscribe(r => {
                                        if (r.result === DialogResult.Yes) {
                                            this.stepper.selectedIndex = index;
                                        }
                                    });
                            } else {
                                this.stepper.selectedIndex = index;
                            }
                        }
                    });
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    return false
                }
            }, true);
        });
        this.stepper.selectionChange.subscribe(
            (e: StepperSelectionEvent) => {
                const index = e.selectedIndex;
                this.stepProgress[e.previouslySelectedIndex] = e.previouslySelectedStep.completed;
                this.applyStepValue(e.previouslySelectedIndex);
                this.PatchValue(this.data.model, { emitEvent: false });
                const com = this.children.toArray()[index];
                if (com && !com.loaded) {
                    com.Load();
                }
            }
        );
        super.OnInit();
    }

    OnLoad() {
        this.stepProgress = Array(this.stepper._steps.length).fill(true);
        const memberId = this.commonSvc.D_FP_AES256ForShortString(localStorage.getItem('memberId'));
        if (memberId && Number(memberId) > 0) {
            this.Invoke(
                this.memberSvc.getForMember(Number(memberId)),
                {
                    onSuccess: (res: DataResult<Member>) => {
                        if (res.Success) {
                            if (res.Data == null) {
                                this.router.navigate([RouterConstant.NAVIGATOR_PAGE_NOT_FOUND]);
                            } else {
                                const data = res.Data;
                                this.data.model = data;
                                const dataMember = Utils.deepClone(data);
                                this.MemberMemberships = dataMember.Memberships;
                                this.PatchValue(this.data.model, { emitEvent: false });
                                (<MemberMembershipPackagesComponent>this.children.toArray()[0]).RefreshMembershipPackages();

                                // go to payment tab
                                this.route.queryParams.subscribe(params => {
                                    const openTab = params['openTab'];
                                    if (openTab) {
                                        this.GoToPaymentDetail();
                                    }
                                    const isAdd = params['allowAdd'];
                                    if (isAdd) {
                                        this.isAddingFamilyMember = true;
                                    }
                                });

                                // add family member
                                const familyMemData = window['ChangeMembershipPackage_FamilyMemberData'];
                                if (familyMemData) {
                                    this.paymentDetails.membershipPackageCostSummarySection.divNewPriceAlert.nativeElement.style.removeProperty('display');
                                    this.data.model.FamilyMembers.push(familyMemData);
                                    this.PatchValue(this.data.model, { emitEvent: false });
                                }
                            }
                            super.OnLoad();
                        } else {
                            this.HandleResponseError(res);
                            super.OnLoad();
                        }
                    }
                }
            );
        } else {
            this.router.navigate([RouterConstant.NAVIGATOR_PAGE_NOT_FOUND]);
        }
    }

    LoadComplete() {
        setTimeout(() => {
            this.SelectedMembershipPackage();
        });
        super.LoadComplete();
    }

    GoToPaymentDetail() {
        this.stepper.selectedIndex = EChangeMembershipPackTab.PaymentDetails;
    }

    CheckMemPakgChangeInMonth(callback: Function) {
        const memberId = this.commonSvc.D_FP_AES256ForShortString(localStorage.getItem('memberId'));
        const membershipPackage = this.data.model.Memberships[0].MembershipPackage;
        if (this.MemberMemberships.length > 0 &&
            membershipPackage.MembershipPackageId !== this.MemberMemberships[0].MembershipPackageId) {
            this.Invoke(
                this.membershipSvc.getNumberOfMembershipChangeByMember(Number(memberId)),
                {
                    onSuccess: (result: DataResult<ResultDataModel>) => {
                        if (result.Success) {
                            const daysChange = result.Data;
                            if (Number(daysChange) >= 30 ||
                                (this.data.model.MemberTypeId === EMemberType.Primary && this.data.model.MemberStatusId === EMemberStatus.New)
                                || (this.data.model.MemberTypeId === EMemberType.Primary && this.isAddingFamilyMember)
                            ) {
                                // go to page change membership package
                                callback(true);
                            } else {
                                MessageBox.ShowOKCustom(this.dialog, 'Unable to Change Membership Package',
                                    'Membership packages can only be changed every 30 days.\
                                    Since your membership package was changed less than 30 days ago, \
                                    you are ineligible to change it again at this time. \
                                    Please contact our Customer Support for more details.', 'Back', '450px');
                                callback(false);
                            }
                        } else {
                            this.HandleResponseError(result);
                            callback(false);
                        }
                    }
                }
            );
        } else {
            callback(true);
        }
    }

    applyStepValue(index: number) {
        switch (index) {
            case EChangeMembershipPackTab.MembershipPackages:
                this.data.model = this.applyValue(this.data.model, this.membershipPackages);
                break;
            case EChangeMembershipPackTab.PaymentDetails:
                this.data.model = this.applyValue(this.data.model, this.paymentDetails);
                break;
        }
    }

    validateStep(index) {
        let valid = false;
        switch (index) {
            case EChangeMembershipPackTab.MembershipPackages:
                this.membershipPackages.Validate();
                valid = this.membershipPackages.valid;
                /* if (!this.membershipPackages.getDataSource() || this.membershipPackages.getDataSource().length === 0) {
                    MessageBox.ShowError(this.dialog,
                        'There is no membership package for the selected employer. \
                        Please select employer have data for membership package.');
                    valid = false;
                } */
                // check membership package selected
                const membershipPackage = this.data.model.Memberships[0].MembershipPackage;
                const totalPriceSelect = membershipPackage.DependantPrice + membershipPackage.FamilyPrice +
                    membershipPackage.SinglePrice;
                valid = this.CheckMembershipPackageSelectedValid(totalPriceSelect);
                break;
            case EChangeMembershipPackTab.PaymentDetails:
                this.paymentDetails.Validate();
                valid = this.paymentDetails.valid;
                break;
        }
        return valid;
    }

    btnNext_Click() {
        const stepValid = this.validateStep(this.stepper.selectedIndex);
        if (!stepValid) {
            return;
        }
        // check change membership package in a month
        this.CheckMemPakgChangeInMonth((isValid) => {
            if (isValid) {
                // check change membership or not
                const membershipPackage = this.data.model.Memberships[0].MembershipPackage;
                if (this.MemberMemberships.length > 0 &&
                    membershipPackage.MembershipPackageId !== this.MemberMemberships[0].MembershipPackageId) {
                    MessageBox.ShowCustom(this.dialog,
                        'Change of Membership Package Confirmation',
                        'Change of Membership Package Confirmation',
                        'By clicking the below "Confirm" button you confirm you have agreed to change your family\'s \
                            Fitness Passport membership package. \
                            You will not be able to change this package for a minimum of one month from the date of this change request.',
                        MessageBoxButton.ConfirmCancel, false, 0, '500px').subscribe(r => {
                            if (r.result === DialogResult.Yes) {
                                this.stepper.next();
                            }
                        });
                } else {
                    this.stepper.next();
                }
            }
        });
    }

    _stepProgressBackgroundVisible(index: number) {
        return this.stepProgress[index] || this.stepper.selectedIndex === index;
    }

    _stepProgressCompleteIconVisible(index: number) {
        return this.stepProgress[index] && this.stepper.selectedIndex !== index;
    }

    private SelectedMembershipPackage() {
        this.membershipPackages.getControl('Memberships').valueChanges.subscribe((value: Membership[]) => {
            if (value && value[0]) {
                const membershipPackage = value[0].MembershipPackage;
                const totalPrice = membershipPackage.DependantPrice
                    + membershipPackage.FamilyPrice + membershipPackage.SinglePrice;
                this.CheckMembershipPackageSelectedValid(totalPrice);
            }
        });
    }
    private CheckMembershipPackageSelectedValid(totalPriceSelected: Number) {
        let totalPriceMemberships = 0;
        if (this.MemberMemberships.length > 0) {
            if (!this.MemberMemberships[0].IsEnough12Months && this.data.model.MemberStatusId !== EMemberStatus.Changed) {
                const membershipPackage = this.MemberMemberships[0].MembershipPackage;
                totalPriceMemberships = membershipPackage.SinglePrice
                    + membershipPackage.FamilyPrice + membershipPackage.DependantPrice;
                if (totalPriceSelected < totalPriceMemberships) {
                    MessageBox.ShowError(this.dialog,
                        'Unable to downgrade package, member has not been active for 12 months').subscribe(r => {
                            // set back defauld membership package
                            if (this.MemberMemberships.length > 0) {
                                this.membershipPackages.selectMembershipPackage(this.MemberMemberships[0].MembershipPackage);
                            }
                        });
                    return false;
                }
                return true;
            }
            return true;
        }
        return true;
    }

    private processDataForSubmission(source: Member) {
        const target = Object.assign({}, source);
        //target.MobileNumber = Utils.convertPhoneToInternationalFormat(target.MobileNumber);
        if (target.Employer) {
            target.EmployerId = target.Employer.EmployerId;
            delete target.Employer;
        }
        if (target.MemberType) {
            target.MemberTypeId = target.MemberType.MemberTypeId;
            delete target.MemberType;
        }
        if (target.MemberStatus) {
            delete target.MemberStatus;
        }
        if (target.FavouredFacility) {
            target.FavouredFacilityId = target.FavouredFacility.FacilityId;
            delete target.FavouredFacility;
        }
        if (target.ExistingFacility) {
            target.ExistingMemberFacilityId = target.ExistingFacility.FacilityId;
            delete target.ExistingFacility;
        }
        if (target.MedicalAnswers instanceof Array) {
            target.MedicalAnswers.forEach(answer => {
                delete answer.MedicalQuestion;
            });
        }
        if (target.FamilyMembers) {
            if (target.FamilyMembers instanceof Array) {
                target.FamilyMembers = target.FamilyMembers.map(member => {
                    return this.processDataForSubmission(member);
                });
            }
        }
        if (target.MemberIdPrimary > 0) {
            target.EmployerId = null;
            target.EmployeePayrollNo = null;
            target.Memberships = null;
            target.Address = null;
            target.AddressId = null;
            target.BankAccount = null;
            target.BankAccountId = null;
        }
        if (target.PrimaryMember) {
            delete target.PrimaryMember;
        }
        if (target.UserId === 0) {
            target.UserId = null;
        }
        delete target.Suburb;
        delete target.Postcode;
        delete target.State;
        delete target.Country;
        for (const key in target) {
            if (target.hasOwnProperty(key) && target[key] == null) {
                delete target[key];
            }
        }
        return target;
    }

    btnSubmit_Click() {
        const valid = this.validateStep(this.stepper.selectedIndex); // validate current step
        this.applyStepValue(this.stepper.selectedIndex);
        if (!this.valid || !valid) {
            return;
        }
        const processedData = this.processDataForSubmission(this.data.model);
        // check change membership package in a month
        this.CheckMemPakgChangeInMonth((isValid) => {
            if (isValid) {
                this.Invoke(
                    this.memberSvc.updateForMember(processedData),
                    {
                        onSuccess: (res: DataResult<Member>) => {
                            if (res.Success) {
                                this.SaveMemberAddress(this.data.model.MemberId);
                            } else {
                                this.HandleResponseError(res);
                            }
                        }
                    }
                );
            }
        });
    }


    private SaveMemberAddress(memberid: number) {
      this.commonservice.StopProgressBar();
      this.Invoke(
            this.memberSvc.updateaddressfamilyformember(memberid),
            {
              onSuccess: (res) => {
                  if (res.Success) {
                        MessageBox.MemberPackageChange(this.dialog, CommonMessage.DATA_SAVE_CHANGE_MEMBERSHIP).subscribe(
                      data => {
                                this.commonservice.StartProgressBar();
                                const currentUser = <User>JSON.parse(this.commonservice.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
                                this.memberSvc
                                .getByUserForMember(currentUser.UserId)
                                .subscribe((getMember) => {
                                    const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
                                    if (getContainer != null) {
                                        let container = <MemberContainer>JSON.parse(getContainer);
                                        let memberEncrypt = this.commonservice.E_FP_AES256(
                                            JSON.stringify(getMember)
                                        );
                                        container.Member = memberEncrypt;
                                        const loginData = JSON.stringify(container);
                                        localStorage.setItem(
                                            StorageKey.CONTAINER_OBJECT,
                                            loginData
                                        );
                                    }
                                    if (getMember.Data.FamilyObject) {
                                    this.commonservice.SetMemFamObj(
                                        getMember.Data.FamilyObject
                                    );
                                    }
                                    if (getMember.Data.MembershipSuspension) {
                                    this.commonservice.SetMemSuspension(
                                        getMember.Data.MembershipSuspension.Data
                                    );
                                    }
                                    this.commonservice.StopProgressBar();
                                    window.location.reload();
                                });
                            }
                        );
                    } else {
                        this.HandleResponseError(res);
                    }
                }
            }
        );
    }

    btnBack_Click() {
        delete window['ChangeMembershipPackage_FamilyMemberData'];
        this.router.navigate([RouterConstant.NAVIGATOR_MEMBER_DETAILS]);
    }

}
