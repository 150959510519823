<div [formGroup]="formGroup">
    <div class="question">
        <div class="question-number">{{questionNumber}}</div>
        <div class="question-content">
            <div class="question-content-title">{{questionTitle}} <span class="required" *ngIf="_required">*</span></div>
        </div>
    </div>
    <div class="input-container">
        <ng-container *ngIf="!multiple">
            <mat-radio-group id='internalControl' [(ngModel)]="internalControl" formControlName="internalControl" *ngFor="let option of options">
                <div>
                    <mat-radio-button [value]="option"><span class="wrap-mat-radio-label">{{option}}</span></mat-radio-button>
                </div>
            </mat-radio-group>
        </ng-container>
        <ng-container *ngIf="multiple">
            <div id='internalControl' *ngFor="let option of options">
                <mat-checkbox (change)="checkBoxValueChange($event.checked,option)">
                    <span class="wrap-mat-radio-label">{{option}}</span></mat-checkbox>
            </div>
        </ng-container>
    </div>
</div>