import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Observable, empty } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { catchError, finalize, debounceTime, switchMap } from 'rxjs/operators';
import { RegionService } from 'src/app/services/region.service';
import { DataResult, State, SearchPaginationResult, SearchPaginationRequest, Region } from 'src/app/models';
import { CommonMessage } from 'src/app/constant';
import { RouterConstant } from 'src/app/constant/routerconstant';
import { MessageBox } from 'src/app/services/common-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonConstants } from '@fp/constant/common-constants';

@Component({
  selector: 'app-searchpackage',
  templateUrl: './searchpackage.component.html',
  styleUrls: ['./searchpackage.component.css']
})
export class SearchpackageComponent implements OnInit, AfterViewInit {
  searchPackageFG: UntypedFormGroup;
  requestQueueCount: number = 0;
  CommonMessage = CommonMessage;
  routerConstant = RouterConstant;
  states: State[];
  regionSearchResults: Region[];
  isSearchingRegion = false;
  paging: SearchPaginationRequest = new SearchPaginationRequest();
  filter: string;
  parentFocus = 0;
  packageName: string;

  constructor(private formBuilder: UntypedFormBuilder,
    protected commonService: CommonService,
    private regionSvc: RegionService,
    private dialog: MatDialog,
    private router: Router) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.searchPackageFG = this.formBuilder.group({
      filter: [0],
      state: [0],
      region: ['']
    });
  }

  ngAfterViewInit() {

    this.searchPackageFG.get('region').valueChanges.pipe(
      debounceTime(500),
      switchMap((value) => {
        if (value != null && value.length >= 3) {
          this.isSearchingRegion = true;
          this.paging.PageNumber = 0;
          this.paging.PageSize = 0;
          this.paging.Params = [{ Name: 'name', Value: value }];
          return this.regionSvc.search(this.paging);
        } else {
          this.isSearchingRegion = false;
        }
        return empty();
      }),
      catchError((e) => { throw e; })
    ).subscribe(
      (result: DataResult<SearchPaginationResult<Region>>) => {
        this.isSearchingRegion = false;
        if (result != null) {
          if (result.Success) {
            this.regionSearchResults = result.Data.Results;
          } else {
            console.error(result);
          }
        }
      },
      error => {
        this.isSearchingRegion = false;
        MessageBox.ShowError(this.dialog, 'An error occurred while trying to call a service');
        console.error(error);
      });
  }

  AutoComplete_DisplayWithFn(option: any) {
    if (!option) {
      return '';
    }
    return option.Name;
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    this.requestQueueCount++;
    this.commonService.StartProgressBar();
    source.pipe(
      catchError((e) => { throw e; }),
      finalize(() => {
        this.requestQueueCount--;
        if (this.requestQueueCount <= 0) {
          this.commonService.StopProgressBar();
          this.requestQueueCount = 0;
        }
      }))
      .subscribe(
        res => { handleResultCallback(res); },
        err => { console.log(err); }
      );
  }

  //Author: Yen
  changeFilter() {
    this.removeValidators(this.searchPackageFG);
    if (this.searchPackageFG.get('filter').value == 1) {
      this.filter = 'state_id';
      this.getStates();
      this.setValidator('state', [Validators.required, this.ValidateObject], 0);
    } else if (this.searchPackageFG.get('filter').value == 2) {
      this.filter = 'region_id';
      this.setValidator('region', [Validators.required, this.ValidateObject], '');
    }
  }

  removeValidators(form: UntypedFormGroup) {
    for (const key in form.controls) {
      form.get(key).clearValidators();
      form.get(key).updateValueAndValidity();
    }
  }

  setValidator(controlname, validators: ValidatorFn[], value: any) {
    this.searchPackageFG.get(controlname).setValue(value);
    this.searchPackageFG.get(controlname).setValidators(validators);
    this.searchPackageFG.get(controlname).updateValueAndValidity();
  }

  /**
   * custom validation for object
   * @param control
   */
  ValidateObject(control: AbstractControl) {
    if ((typeof control.value) == 'object') {
      return null
    }
    return { validUrl: true };
  }

  //Author: Yen
  getStates() {
    this.states = CommonConstants.GET_STATES.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
    // this.Invoke(
    //   this.regionSvc.getStates(),
    //   (res: DataResult<State[]>) => {
    //     if (res.Success) {
    //       this.states = res.Data.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
    //     } else {
    //       console.error(res);
    //     }
    //   }
    // );
  }

  filterFG(filter: string) {
    return this.searchPackageFG.controls[filter];
  }

  //Author: Yen
  findPackages() {
    let value: string;
    if (this.filter == 'state_id') {
      value = this.searchPackageFG.get('state').value.StateId;
    } else if (this.filter == 'region_id') {
      value = this.searchPackageFG.get('region').value.RegionId;
    } else {
      this.filter = 'all';
      value = '';
    }
    this.router.navigate(
      [RouterConstant.NAVIGATOR_PACKAGE_VIEW],
      {
        queryParams: {
          value: value,
          filter: this.filter,
          packageName: this.packageName
        },
        skipLocationChange: true
      });
  }

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }
  onBlurParent(focusNumbr) {
    this.parentFocus = focusNumbr;
  }
  getClassFocused(vlFocused) {
    if (this.parentFocus == vlFocused) {
      return "focused";
    } else {
      return "";
    }
  }

}
