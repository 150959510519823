import { PrivilegeModel } from './../models/privilege.model';
import { RouterConstant } from 'src/app/constant/routerconstant';
import { RolePrivilegeConstant } from './../constant/role-previlege-constant';
import { HttpDAO } from './httpdao.service';
import { APIConstant } from './../constant/apiconstant';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf, forkJoin } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { PrivilegesComponentSecutiry } from '../models/privileges-components.model';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { UserRole } from '@fp/models/user-role.model';
import { ERole } from '@fp/enums/role.enum';
import { StorageKey } from '@fp/constant/storage-key';
import { MemberContainer } from '@fp/models/member-container.model';

@Injectable()
export class RolePermissionService implements CanActivate {

    private httpdao: HttpDAO | null;
    public RolesObject: UserRole[] = [];
    public Privileges: PrivilegeModel[] = [];
    public SelectedRole = '';
    public Feature = '';
    public MenuCreateEmployer = false;
    public MenuUpdateEmployer = false;
    public MenuAddRegion = false;
    public MenuViewEditRegion = false;
    public MenuAddUser = false;
    public MenuViewEditUser = false;
    public MenuViewProductService = false;
    public MenuAddFacility = false;
    public MenuViewEditFacility = false;
    public MenuUserRole = false;
    public MenuViewProdServ = false;
    public MenuMemberVerify = false;
    public MenuMemberViewEdit = false;
    public MenuAddPackage = false;
    public MenuEditPackage = false;
    public MenuUploadMembershipPackagePrice = false;
    public MenuUploadTrainingMaterial = false;
    public MenuViewTrainingMaterial = false;
    public MenuGenerateReport = false;
    //----------
    public MenuTextViewEditEmployer = 'View/Edit Employer';
    public MenuTextViewEditFacility = 'View/Edit Facility';
    public MenuTextViewEditPackage = 'View/Edit Packager';

    public PrivilegesAndComponents: PrivilegesComponentSecutiry[] = [
        { 'Id': 1, 'PrivilegeName': 'Add new user', 'ComponentPath': RouterConstant.ROUTER_USER_ADD },
        { 'Id': 2, 'PrivilegeName': 'View/edit user', 'ComponentPath': RouterConstant.ROUTER_USER_SEARCH },
        { 'Id': 3, 'PrivilegeName': 'View/edit user', 'ComponentPath': RouterConstant.ROUTER_USER_EDIT },
        { 'Id': 4, 'PrivilegeName': 'View/edit user', 'ComponentPath': RouterConstant.ROUTER_USER_VIEW },
        { 'Id': 5, 'PrivilegeName': 'View/edit user role privileges', 'ComponentPath': RouterConstant.ROUTER_USER_ROLES },
        { 'Id': 6, 'PrivilegeName': 'Add new employer', 'ComponentPath': RouterConstant.ROUTER_EMPLOYER_ADD },
        { 'Id': 7, 'PrivilegeName': 'Add new employer', 'ComponentPath': RouterConstant.ROUTER_EMPLOYER },
        { 'Id': 8, 'PrivilegeName': 'View/edit employers', 'ComponentPath': RouterConstant.ROUTER_EMPLOYER_EDIT },
        { 'Id': 9, 'PrivilegeName': 'View/edit employers', 'ComponentPath': RouterConstant.ROUTER_EMPLOYER_SEARCH },
        { 'Id': 10, 'PrivilegeName': 'View/edit employers', 'ComponentPath': RouterConstant.ROUTER_EMPLOYER_READ_ONLY },
        { 'Id': 11, 'PrivilegeName': 'Upload Membership Package Price', 'ComponentPath': RouterConstant.ROUTER_EMPLOYER_PACKAGE_PRICE_UPDATES },
        { 'Id': 12, 'PrivilegeName': 'Add new facility', 'ComponentPath': RouterConstant.ROUTER_FACILITY_ADD },
        { 'Id': 13, 'PrivilegeName': 'Add new facility', 'ComponentPath': RouterConstant.ROUTER_FACILITY },
        { 'Id': 14, 'PrivilegeName': 'View/Edit facilities', 'ComponentPath': RouterConstant.ROUTER_FACILITY_EDIT },
        { 'Id': 15, 'PrivilegeName': 'View/Edit facilities', 'ComponentPath': RouterConstant.ROUTER_FACILITY_SEARCH },
        { 'Id': 16, 'PrivilegeName': 'View/Edit facilities', 'ComponentPath': RouterConstant.ROUTER_FACILITIES_VIEW },
        { 'Id': 17, 'PrivilegeName': 'View/Edit facilities', 'ComponentPath': RouterConstant.ROUTER_FACILITIES_HOME_EDIT },
        { 'Id': 18, 'PrivilegeName': 'View/Edit Regions', 'ComponentPath': RouterConstant.ROUTER_REGION_VIEWEDIT },
        { 'Id': 19, 'PrivilegeName': 'View/Edit Regions', 'ComponentPath': RouterConstant.ROUTER_REGION_SEARCH },
        { 'Id': 20, 'PrivilegeName': 'View/Edit Regions', 'ComponentPath': RouterConstant.ROUTER_REGION_EDIT },
        { 'Id': 21, 'PrivilegeName': 'Add New Region', 'ComponentPath': RouterConstant.ROUTER_REGION_ADD },
        { 'Id': 22, 'PrivilegeName': 'View/edit Products and Services', 'ComponentPath': RouterConstant.ROUTER_PRODUCT_SERVICE_VIEW },
        { 'Id': 23, 'PrivilegeName': 'View/edit Products and Services', 'ComponentPath': RouterConstant.ROUTER_PRODUCT_SERVICE_EDIT },
        { 'Id': 24, 'PrivilegeName': 'View/edit Products and Services', 'ComponentPath': RouterConstant.ROUTER_PRODUCT_SERVICE_ADD },
        { 'Id': 25, 'PrivilegeName': 'Input manual visit data', 'ComponentPath': RouterConstant.ROUTER_FACILITIES_INPUT_MANUAL },
        { 'Id': 26, 'PrivilegeName': 'View/approve/reject pending members', 'ComponentPath': RouterConstant.ROUTER_MEMBER_VERIFICATION },
        { 'Id': 27, 'PrivilegeName': 'Add new package', 'ComponentPath': RouterConstant.ROUTER_PACKAGE_ADD },
        { 'Id': 28, 'PrivilegeName': 'View/Edit packages', 'ComponentPath': RouterConstant.ROUTER_PACKAGE_EDIT },
        { 'Id': 29, 'PrivilegeName': 'View/Edit packages', 'ComponentPath': RouterConstant.ROUTER_PACKAGE_SEARCH },
        { 'Id': 30, 'PrivilegeName': 'View/Edit packages', 'ComponentPath': RouterConstant.ROUTER_PACKAGE_VIEW },
        { 'Id': 31, 'PrivilegeName': 'Add new package', 'ComponentPath': RouterConstant.ROUTER_PACKAGE },
        { 'Id': 32, 'PrivilegeName': 'View/Edit members', 'ComponentPath': RouterConstant.ROUTER_MEMBER_SEARCH },
        { 'Id': 33, 'PrivilegeName': 'View/Edit members', 'ComponentPath': RouterConstant.ROUTER_MEMBER_EDIT },
        { 'Id': 34, 'PrivilegeName': 'View/Edit members', 'ComponentPath': RouterConstant.ROUTER_MEMBER_VIEW },
        { 'Id': 35, 'PrivilegeName': 'View/Edit members', 'ComponentPath': RouterConstant.ROUTER_MEMBER_VIEW_SEARCH },
        { 'Id': 36, 'PrivilegeName': 'Create payment request', 'ComponentPath': RouterConstant.ROUTER_VIEW_PAYMENT_REQUESTS_CREATE },
        { 'Id': 37, 'PrivilegeName': 'View payment requests', 'ComponentPath': RouterConstant.ROUTER_VIEW_PAYMENT_REQUESTS },
        { 'Id': 38, 'PrivilegeName': 'Manually upload raw visits', 'ComponentPath': RouterConstant.ROUTER_MANUALLY_UPLOAD },
        { 'Id': 39, 'PrivilegeName': 'System Parameters', 'ComponentPath': RouterConstant.ROUTER_SYSTEM_PARAMETERS },
        { 'Id': 40, 'PrivilegeName': 'View Payments and Invoices', 'ComponentPath': RouterConstant.ROUTER_VIEW_PAYMENTS_AND_INVOICES },
        { 'Id': 41, 'PrivilegeName': 'Debit Processing', 'ComponentPath': RouterConstant.ROUTER_DEBIT_PROCESSING },
        { 'Id': 42, 'PrivilegeName': 'Bulk Upload', 'ComponentPath': RouterConstant.ROUTER_BULK_UPLOAD_DEBIT_CREDIT },
        { 'Id': 43, 'PrivilegeName': 'Upload New Training Material', 'ComponentPath': RouterConstant.ROUTER_TRAINING_MATERIAL_UPLOAD },
        { 'Id': 44, 'PrivilegeName': 'View Existing Training Material', 'ComponentPath': RouterConstant.ROUTER_TRAINING_MATERIAL_VIEW },
        { 'Id': 45, 'PrivilegeName': 'Generate Reports', 'ComponentPath': RouterConstant.ROUTER_GENERATE_REPORTS },
        { 'Id': 46, 'PrivilegeName': 'Add Bank Detail', 'ComponentPath': RouterConstant.ROUTER_ADD_BANK_CODE },
        { 'Id': 47, 'PrivilegeName': 'Edit Terms Conditions', 'ComponentPath': RouterConstant.ROUTER_EDIT_TERMS_CONDITIONS },
    ];

    public PrivilegesAndComponentsSecurity: PrivilegesComponentSecutiry[] = [];
    SystemParameters: boolean = false;
    CreatePaymentRequest: boolean = false;
    ViewPaymentRequests: boolean = false;
    ManuallyUpload: boolean = false;
    ViewPaymentsandInvoices: boolean = false;
    DebitProcessing: boolean = false;
    BulkUploadDebitCreditComponent: boolean;
    AddBankDetail: boolean;
    EditTermsConditions: boolean;

    constructor(private commonservice: CommonService,
        private http: HttpClient,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        let ret = false;
        const pt = route.routeConfig.path;

        if (localStorage.getItem('accessToken') == null || localStorage.getItem('accessToken') == undefined) {
            this.router.navigate(['/login']);
            return observableOf(false);
        }

        if (pt == 'dashboard') {
            if (localStorage.getItem('accessToken') == null || localStorage.getItem('accessToken') == undefined) {
                this.router.navigate(['/login']);
                return observableOf(false);
            }
        }

        if (
            pt == 'memberdetails' || pt == 'membershipcards' || pt == 'suspendmembership' || pt == 'membertransfer' || pt == 'membernotifications'
            || pt == 'memberpaymentshistory' || pt == 'membercontracts' || pt == 'memberupdatepaymentdetails'
        ) {
            const temp = this.commonservice.GetSelectedRole();
            if (temp === ERole.member) {
                return observableOf(true);
            } else {
                this.router.navigate([RouterConstant.NAVIGATOR_NO_PERMISSION]);
                return observableOf(false);
            }
        }

        if (pt == 'facilitydetails' || pt == 'facilitymembers') {
            const temp = this.commonservice.GetSelectedRole();
            if (temp == ERole.Facility_Staff_Member) {
                return observableOf(true);
            } else {
                this.router.navigate([RouterConstant.NAVIGATOR_NO_PERMISSION]);
                return observableOf(false);
            }
        }

        this.commonservice.isGlobalLoadingResults = true;
        this.httpdao = new HttpDAO(this.http);

        let privData: any;
        const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
        if (getContainer != null) {
            let container = <MemberContainer>JSON.parse(getContainer);
            privData = container.Privileges;
        }

        if (privData != null) {
            this.PrivilegesAndComponentsSecurity = this.PrivilegesAndComponents;
            this.RolesObject = this.commonservice.GetUserRoleByUserName();
            const temp: string = this.commonservice.GetSelectedRole();
            for (let i = 0; i < this.RolesObject.length; i++) {
                if (temp === this.RolesObject[i].Name) {
                    this.SelectedRole = temp;
                }
            }
            if (this.SelectedRole === '' && this.RolesObject.length > 0) {
                this.SelectedRole = this.RolesObject[0].Name;
            }
            this.commonservice.SetSelectedRole(this.SelectedRole);

            const enctypted_data = privData.Data;
            const dectypted_data = this.commonservice.D_FP_ResponseData(enctypted_data);
            const retdedata = JSON.parse(dectypted_data);

            this.Privileges = retdedata;

            this.SetPermisisonReadOnlyPrivilege();

            ret = this.AssignPermissionToComponent(route.routeConfig.path);
            this.commonservice.isGlobalLoadingResults = false;
            return observableOf(ret);
        } else {
            return forkJoin(
                this.httpdao!.getSingleData(APIConstant.API_GET_ALL_PRIVILEGES_V2),
            ).pipe(
                map(([privileges]) => {
                    //this.PrivilegesAndComponentsSecurity = pathpri.Data;
                    this.PrivilegesAndComponentsSecurity = this.PrivilegesAndComponents;
                    this.RolesObject = this.commonservice.GetUserRoleByUserName();
                    const temp: string = this.commonservice.GetSelectedRole();
                    for (let i = 0; i < this.RolesObject.length; i++) {
                        if (temp === this.RolesObject[i].Name) {
                            this.SelectedRole = temp;
                        }
                    }
                    if (this.SelectedRole === '' && this.RolesObject.length > 0) {
                        this.SelectedRole = this.RolesObject[0].Name;
                    }
                    this.commonservice.SetSelectedRole(this.SelectedRole);

                    const enctypted_data = privileges.Data;
                    const dectypted_data = this.commonservice.D_FP_ResponseData(enctypted_data);
                    const retdedata = JSON.parse(dectypted_data);

                    this.Privileges = retdedata;

                    this.SetPermisisonReadOnlyPrivilege();

                    ret = this.AssignPermissionToComponent(route.routeConfig.path);
                    this.commonservice.isGlobalLoadingResults = false;
                    return ret;
                }),
                shareReplay(1),
                catchError(() => {
                    this.commonservice.isGlobalLoadingResults = false;
                    return observableOf(true);
                })
            );
        }
    }

    private AssignPermissionToComponent(path): boolean {
        if (this.Privileges === undefined || this.Privileges === null) {
            this.RolesObject = [];
            this.commonservice.SignOut();
            alert('The Privilege data is invaid. System will be logged out. Please contact Administrator');
        }

        if (this.Privileges[0].PrivilegeName === undefined) {
            this.RolesObject = [];
            this.commonservice.SignOut();
            alert('The Privilege data is invaid. System will be logged out. Please contact Administrator');
        }

        for (let i = 0; i < this.PrivilegesAndComponentsSecurity.length; i++) {
            if (this.PrivilegesAndComponentsSecurity[i].ComponentPath == path) {
                for (let j = 0; j < this.Privileges.length; j++) {
                    if (this.Privileges[j].PrivilegeName.trim() == this.PrivilegesAndComponentsSecurity[i].PrivilegeName.trim()) {
                        const ret = this.CanAccess(this.Privileges[j]);
                        if (ret == false) {
                            this.Feature = this.Privileges[j].PrivilegeName; // This is used in the message inform no permission
                            this.router.navigate([RouterConstant.NAVIGATOR_NO_PERMISSION]);
                            return false;
                        } else {
                            return true;
                        }
                    }
                }
            }
        }
        return true;
    }
    public SetMenu() {
        for (let i = 0; i < this.PrivilegesAndComponentsSecurity.length; i++) {
            for (let j = 0; j < this.Privileges.length; j++) {
                if (this.Privileges[j].PrivilegeName.trim() == this.PrivilegesAndComponentsSecurity[i].PrivilegeName.trim()) {
                    const ret = this.CanAccess(this.Privileges[j]);
                    switch (this.PrivilegesAndComponentsSecurity[i].ComponentPath) {
                        case RouterConstant.ROUTER_EMPLOYER_ADD:
                            this.MenuCreateEmployer = ret;
                            break;
                        case RouterConstant.ROUTER_EMPLOYER_EDIT:
                            this.MenuUpdateEmployer = ret;
                            break;
                        case RouterConstant.ROUTER_EMPLOYER_PACKAGE_PRICE_UPDATES:
                            this.MenuUploadMembershipPackagePrice = ret;
                            break;
                        case RouterConstant.ROUTER_REGION_VIEWEDIT:
                        case RouterConstant.ROUTER_REGION_EDIT:
                            this.MenuViewEditRegion = ret;
                            break;
                        case RouterConstant.ROUTER_REGION_ADD:
                            this.MenuAddRegion = ret;
                            break;
                        case RouterConstant.ROUTER_USER_ADD:
                            this.MenuAddUser = ret;
                            break;
                        case RouterConstant.ROUTER_USER_EDIT:
                            this.MenuViewEditUser = ret;
                            break;
                        case RouterConstant.ROUTER_USER_ROLES:
                            this.MenuUserRole = ret;
                            break;
                        case RouterConstant.ROUTER_FACILITY_ADD:
                            this.MenuAddFacility = ret;
                            break;
                        case RouterConstant.ROUTER_FACILITY_EDIT:
                            this.MenuViewEditFacility = ret;
                            break;
                        case RouterConstant.ROUTER_PRODUCT_SERVICE_VIEW:
                            this.MenuViewProdServ = ret;
                            break;
                        case RouterConstant.ROUTER_MEMBER_VERIFICATION:
                            this.MenuMemberVerify = ret;
                            break;
                        case RouterConstant.ROUTER_MEMBER_VIEW:
                        case RouterConstant.ROUTER_MEMBER_EDIT:
                        case RouterConstant.ROUTER_MEMBER_SEARCH:
                            this.MenuMemberViewEdit = ret;
                            break;
                        case RouterConstant.ROUTER_PACKAGE_ADD:
                            this.MenuAddPackage = ret;
                            break;
                        case RouterConstant.ROUTER_PACKAGE_EDIT:
                            this.MenuEditPackage = ret;
                            break;
                        case RouterConstant.ROUTER_VIEW_PAYMENT_REQUESTS_CREATE:
                            this.CreatePaymentRequest = ret;
                            break;
                        case RouterConstant.ROUTER_VIEW_PAYMENT_REQUESTS:
                            this.ViewPaymentRequests = ret;
                            break;
                        case RouterConstant.ROUTER_MANUALLY_UPLOAD:
                            this.ManuallyUpload = ret;
                            break;
                        case RouterConstant.ROUTER_SYSTEM_PARAMETERS:
                            this.SystemParameters = ret;
                            break;
                        case RouterConstant.ROUTER_VIEW_PAYMENTS_AND_INVOICES:
                            this.ViewPaymentsandInvoices = ret;
                            break;
                        case RouterConstant.ROUTER_DEBIT_PROCESSING:
                            this.DebitProcessing = ret;
                            break;
                        case RouterConstant.ROUTER_BULK_UPLOAD_DEBIT_CREDIT:
                            this.BulkUploadDebitCreditComponent = ret;
                            break;
                        case RouterConstant.ROUTER_ADD_BANK_CODE:
                            this.AddBankDetail = ret;
                            break;
                        case RouterConstant.ROUTER_EDIT_TERMS_CONDITIONS:
                            this.EditTermsConditions = ret;
                            break;
                        case RouterConstant.ROUTER_TRAINING_MATERIAL_UPLOAD:
                            this.MenuUploadTrainingMaterial = ret;
                            break;
                        case RouterConstant.ROUTER_TRAINING_MATERIAL_VIEW:
                            this.MenuViewTrainingMaterial = ret;
                            break;
                        case RouterConstant.ROUTER_GENERATE_REPORTS:
                            this.MenuGenerateReport = ret;
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    }

    public CanAccess(obj: PrivilegeModel): boolean {
        const selectedrole = this.commonservice.GetSelectedRole();
        this.SelectedRole = selectedrole;
        switch (selectedrole) {
            case RolePrivilegeConstant.ADMIN:
                return obj.Admin;
            case RolePrivilegeConstant.SUPER_USER:
                return obj.SuperUser;
            case RolePrivilegeConstant.ACCOUNTS_PAYABLE_OFFICER_READONLY:
                return obj.AccountsPayableOfficerReadOnly;
            case RolePrivilegeConstant.ACCOUNTS_PAYABLE_OFFICER:
                return obj.AccountsPayableOfficer;
            case RolePrivilegeConstant.FINANCE_LEVEL_1_READONLY:
                return obj.FinanceLevel1ReadOnly;
            case RolePrivilegeConstant.FINANCE_LEVEL_1:
                return obj.FinanceLevel1;
            case RolePrivilegeConstant.FINANCE_LEVEL_2:
                return obj.FinanceLevel2;
            case RolePrivilegeConstant.FINANCE_LEVEL_3:
                return obj.FinanceLevel3;
            case RolePrivilegeConstant.BUSINESS_DEVELOPMENT_READONLY:
                return obj.BusinessDevelopmentReadOnly;
            case RolePrivilegeConstant.BUSINESS_DEVELOPMENT_LEVEL_1:
                return obj.BusinessDevelopmentLevel1;
            case RolePrivilegeConstant.BUSINESS_DEVELOPMENT_LEVEL_2:
                return obj.BusinessDevelopmentLevel2;
            case RolePrivilegeConstant.BUSINESS_DEVELOPMENT_LEVEL_3:
                return obj.BusinessDevelopmentLevel3;
            case RolePrivilegeConstant.PRODUCT_DEVELOPMENT_READONLY:
                return obj.ProductDevelopmentReadOnly;
            case RolePrivilegeConstant.PRODUCT_DEVELOPMENT_LEVEL_1:
                return obj.ProductDevelopmentLevel1;
            case RolePrivilegeConstant.PRODUCT_DEVELOPMENT_LEVEL_2:
                return obj.ProductDevelopmentLevel2;
            case RolePrivilegeConstant.PRODUCT_DEVELOPMENT_LEVEL_3:
                return obj.ProductDevelopmentLevel3;
            case RolePrivilegeConstant.OPERATION_LEVEL_1_READONLY:
                return obj.OperationLevel1ReadOnly;
            case RolePrivilegeConstant.OPERATION_LEVEL_1:
                return obj.OperationLevel1;
            case RolePrivilegeConstant.OPERATION_LEVEL_2:
                return obj.OperationLevel2;
            case RolePrivilegeConstant.OPERATION_LEVEL_3:
                return obj.OperationLevel3;
            case RolePrivilegeConstant.FACILITY_STAFF_MEMBER:
                return obj.FacilityStaffMember;
            case RolePrivilegeConstant.EMPLOYER_FACILITATOR:
                return obj.EmployerFacilitator;
            case RolePrivilegeConstant.MEMBER:
                return obj.Member;
            case RolePrivilegeConstant.PRIVILEGED_REPORTS_ACCESS:
                return obj.PrivilegedReportsAccess;
            case RolePrivilegeConstant.BUSINESS_DEVELOPMENT_LEVEL_4:
                return obj.BusinessDevelopmentLevel4;
            case RolePrivilegeConstant.BUSINESS_DEVELOPMENT_LEVEL_5:
                return obj.BusinessDevelopmentLevel5;
            default:
                return false;
        }
    }

    /**
     * Checkes if any read only attribute on user role is true
     */
    public checkPrivilegesForReadOnly(privilegeName: string): boolean {
        const readOnlyPrivilege: PrivilegeModel[] = this.Privileges.filter(
            privilege => privilege.PrivilegeName.toLowerCase() === privilegeName.toLowerCase()
        );

        return readOnlyPrivilege.some((privilege: PrivilegeModel) => {
            // get selected role and remove underscores
            const selectedRole: string = this.commonservice.GetSelectedRole().replace(/_/g, '');
            return privilege[selectedRole];
        });
    }

    public SetPermisisonReadOnlyPrivilege() {
        const prires1 = this.Privileges.find(e => e.PrivilegeName === 'View/edit employers (Restrict to Read Only)');
        const prires2 = this.Privileges.find(e => e.PrivilegeName === 'View/edit facilities (Restrict to Read Only)');
        const prires3 = this.Privileges.find(e => e.PrivilegeName === 'View/Edit packages (Restrict to Read Only)');
        if (prires1[this.SelectedRole.replace(/_/g, '')] === true) {
            //Has permisison readonly
            const vee = this.Privileges.find(e => e.PrivilegeName === 'View/edit employers');
            vee[this.SelectedRole.replace(/_/g, '')] = true; // Set in memory [View/edit employers] = true
            this.MenuTextViewEditEmployer = 'View/Edit Employer (Read Only)';
        } else {
            this.MenuTextViewEditEmployer = 'View/Edit Employer';
        }

        if (prires2[this.SelectedRole.replace(/_/g, '')] === true) {
            //Has permisison readonly
            const vef = this.Privileges.find(e => e.PrivilegeName === 'View/Edit facilities');
            vef[this.SelectedRole.replace(/_/g, '')] = true; // Set in memory [View/Edit facilities] = true
            this.MenuTextViewEditFacility = 'View/Edit Facility (Read Only)';
        } else {
            this.MenuTextViewEditFacility = 'View/Edit Facility';
        }

        if (prires3[this.SelectedRole.replace(/_/g, '')] === true) {
            //Has permisison readonly
            const vep = this.Privileges.find(e => e.PrivilegeName === 'View/Edit packages');
            vep[this.SelectedRole.replace(/_/g, '')] = true; // Set in memory [View/Edit packages] = true
            this.MenuTextViewEditPackage = 'View/Edit Package (Read Only)';
        } else {
            this.MenuTextViewEditPackage = 'View/Edit Package';
        }
    }

}
