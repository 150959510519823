import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APIConstant, RouterConstant } from '@fp/constant';
import { CommonService } from '@fp/services';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.css']
})
export class ConfirmAccountComponent implements OnInit {
  confirmAccountForm: FormGroup;
  fieldType: Record<string, string> = {
    newPassword: 'password',
    confirmPassword: 'password'
  };

  public specialCharRegex = /(?=.*[\^\$\*\.\[\]\{\}\(\)\?\-\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`\+\=])/;
  public passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^\$\*\.\[\]\{\}\(\)\?\-\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`\+\=])[A-Za-z\d\^\$\*\.\[\]\{\}\(\)\?\-\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`\+\=]{8,}$/;

  private passwordMatchValidator(form: FormGroup) {
    if (form.get('confirmPassword')?.value == null || form.get('confirmPassword')?.value?.length === 0) {
      return;
    }
    if (form.get('confirmPassword').value.length > 0 && form.get('newPassword').value !== form.get('confirmPassword').value) {
      form.get('confirmPassword').setErrors({ passwordMismatch: true });
    } else {
      form.get('confirmPassword').setErrors(null);
    }
  }

  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.commonService.App.isConfirmAccountScreen = true;
    this.commonService.SignOut(false);
    this.initConfirmAccountForm();
  }

  private initConfirmAccountForm() {
    const params = this.route.snapshot.queryParams;
    this.confirmAccountForm = this.fb.group({
      email: [params['email'] ?? '', [Validators.required, Validators.email]],
      code: [params['code'] ?? '', Validators.required],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(this.passwordRegex)
      ]],
      confirmPassword: ['', Validators.required]
    }, { validator: this.passwordMatchValidator });
  }

  onSubmit(): void {
    if (this.confirmAccountForm.valid) {
      fetch(`${environment.baseURL}${APIConstant.API_CONFIRM_USER_ACCOUNT}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          UserName: this.confirmAccountForm.value.email,
          PinCode: this.confirmAccountForm.value.code,
          NewPassword: this.confirmAccountForm.value.newPassword
        })
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          if (data.Success && data.Data?.IsMember) {
            this.confirmAccountForm.reset();
            this.router.navigate([RouterConstant.ROUTER_CONFIRM_ACCOUNT_COMPLETED]);
          } else if (data.Success) {
            this.confirmAccountForm.reset();
            this.router.navigate([RouterConstant.ROUTER_LOGIN]).then(() => {
              // Force full page reload to ensure that amplify authenticator services are properly applied
              window.location.reload();
            });
          } else {
            this.parseError(data.Message);
          }
        })
        .catch(error => {
          console.error({ error });
          this.confirmAccountForm.setErrors({ serverError: error?.message ?? 'Something went wrong' });
        });
    }
  }

  parseError(message: string) {
    if (message.toLowerCase().includes('user')) {
      this.confirmAccountForm.get('email').setErrors({ serverError: message });
    } else if (message.toLowerCase().includes('pin code')) {
      this.confirmAccountForm.get('code').setErrors({ serverError: message });
    } else {
      this.confirmAccountForm.setErrors({ serverError: message });
    }
  }

  toggleFieldTextType(field: string): void {
    this.fieldType[field] = this.fieldType[field] === 'password' ? 'text' : 'password';
  }
}