<div class="text-center health-disclaimer-wrapper">
  <p>
    If you answered 'Yes' to any of the medical questions, please consult a GP
    or appropriate Allied Health Professional to seek clearance/approval to
    undertake physical activity/exercise in participating facilities.
  </p>
  <p>
    If a future change in your health, medical condition or physical capacity
    would lead you to respond 'Yes' to any of the questions, please consult your
    GP or Allied Health Professional before undertaking further physical
    activity or exercise in any participating facilities.
  </p>
  <button
    class="btn btn-primary btn-new"
    mat-dialog-close
    mat-dialog-close="true"
  >
    OK
  </button>
</div>
