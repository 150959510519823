import { Component, OnInit, Injector } from '@angular/core';
import { FacilityFormBase } from '@fp/components/base';
import { StringHelper } from '@fp/helpers';
import { CommonString } from '@fp/constant';
import { UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from '@fp/services';
import { Message } from '@fp/models';

@Component({
    selector: 'app-facility-cheque-details',
    templateUrl: './cheque-details.component.html',
    styleUrls: ['./cheque-details.component.css'],
    providers: [{ provide: FacilityFormBase, useExisting: FacilityChequeDetailsComponent }]
})
export class FacilityChequeDetailsComponent extends FacilityFormBase {
    StringHelper = StringHelper;
    CommonString = CommonString;
    subscription: Subscription;
    static isDisabledChequeDetails: boolean;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            Cheque: [null]
        });
    }

    static getChequeDetailsFormGroup() {
        const fb = new UntypedFormBuilder();
        if(FacilityChequeDetailsComponent.isDisabledChequeDetails){
        return fb.group({
            PayeeId: [null],
        }); } else {
        return fb.group({
            PayeeId: [null, Validators.required],
        });}
    }

    constructor(injector: Injector, private messageService: MessageService) { super(injector); }

    ngOnInit(){
        this.updateValidators(true);

        this.subscription = this.messageService.currentMessage.subscribe((message: Message) => {
            if (message && message.Identifier == CommonString.FacilityPotentialMessageIdentifier) {
                if (message.Content == 'True') {
                    FacilityChequeDetailsComponent.isDisabledChequeDetails = true;
                    this.updateValidators(false);
                } else {
                    FacilityChequeDetailsComponent.isDisabledChequeDetails = false;
                    this.updateValidators(true);
                }
            }
        });
    }
    ngOnDestroy() {
        if (this.subscription) {
          this.subscription.unsubscribe(); 
        }
    }

    ToggleChequeDetailsFormGroup(enabled: boolean) {
        if (enabled) {
            this.form.setControl('Cheque', FacilityChequeDetailsComponent.getChequeDetailsFormGroup());
            this.form.parent.get('PaymentDetails').disabled ? this.form.disable() : null;
        } else {
            this.form.setControl('Cheque', new UntypedFormControl(null));
        }
    }

    PatchValue(value, options?) {
        this.ToggleChequeDetailsFormGroup(value['Cheque'] != null);
        super.PatchValue(value, options);
    }

    private updateValidators(required: boolean): void {
        let controlPath = 'Cheque.PayeeId';
        let control = this.form.get(controlPath);

        if (control) {
            let validators = required ? [Validators.required] : [];
            control.setValidators(validators);
            control.updateValueAndValidity();
        }
    }
}
