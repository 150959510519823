import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FPEvent } from '@fp/components/base/fp-event';
import { DialogResult } from '@fp/components/common-dialog/common-dialog.component';

export class CustomMessageBoxData {
    title?: string;
    content: string;
    icon?: CustomMessageBoxIcon;
    iconSize?: CustomMessageBoxIconSize;
    buttons?: CustomMessageBoxButton[] | CustomMessageBoxButtonOptions;
}

export class CustomMessageBoxButton {
    static Close = <CustomMessageBoxButton>{
        label: 'Close',
        isPrimary: true,
        dialogResult: DialogResult.Ok
    };
    static Ok = <CustomMessageBoxButton>{
        label: 'Ok',
        isPrimary: true,
        dialogResult: DialogResult.Ok
    };
    static Cancel = <CustomMessageBoxButton>{
        label: 'Cancel',
        dialogResult: DialogResult.Cancel
    };
    static OkCancel = <CustomMessageBoxButton[]>[
        Object.assign({ order: 1 }, CustomMessageBoxButton.Cancel),
        Object.assign({ order: 2 }, CustomMessageBoxButton.Ok)
    ];
    static Yes = <CustomMessageBoxButton>{
        label: 'Yes',
        isPrimary: true,
        dialogResult: DialogResult.Yes
    };
    static No = <CustomMessageBoxButton>{
        label: 'No',
        dialogResult: DialogResult.No
    };
    static YesNo = <CustomMessageBoxButton[]>[
        Object.assign({ order: 1 }, CustomMessageBoxButton.No),
        Object.assign({ order: 2 }, CustomMessageBoxButton.Yes)
    ];

    label: string;
    cssClass?: string;
    isPrimary?: boolean;
    order?: number;
    onClick?: (e: FPEvent) => void;
    dialogResult: string;
}
export enum CustomMessageBoxButtonOptions {
    OkCancel,
    YesNo
}
export enum CustomMessageBoxIcon {
    None,
    Info,
    Warning,
    Error,
    Question
}
export enum CustomMessageBoxIconSize {
    Small,
    Medium,
    Large,
    ExtraLarge
}

export const CustomMessageBox = {
    Show(dialog: MatDialog, data: CustomMessageBoxData, config?: {
        height?: string,
        width?: string,
        beforeClose?: (r: DialogResult) => void,
        afterClosed?: (r: DialogResult) => void,
    }) {
        config = Object.assign({}, config);
        data = Object.assign({
            icon: CustomMessageBoxIcon.None
        }, data);
        const ref = dialog.open<CustomMessageBoxDialog<DialogResult>>(CustomMessageBoxDialog, {
            disableClose: true,
            width: config.width,
            height: config.height,
            data: data,
            panelClass: 'fp-custom-message-dialog-overlay'
        });
        if (typeof config.beforeClose === 'function') {
            ref.beforeClosed().subscribe(r => { config.beforeClose(r); });
        }
        if (typeof config.afterClosed === 'function') {
            ref.afterClosed().subscribe(r => { config.afterClosed(r); });
        }
    }
}

@Component({
    selector: 'app-custom-message-dialog',
    template: `
    <style>
        ::ng-deep .fp-custom-message-dialog-overlay .mat-dialog-container {
            border-radius: 10px;
        }
        .mat-dialog-title {
            font-weight: 500;
            font-size: 1.3rem;
        }
    </style>
    <div mat-dialog-container>
        <h1 *ngIf="data.title" class="text-center" mat-dialog-title>{{data.title}}</h1>
        <hr *ngIf="data.title" class="m-0" />
        <div class="pt-3 d-flex align-items-center info" mat-dialog-content>
            <i *ngIf="_hasIcon" class="fa {{_getIconCssClasses()}} mx-4"></i><span [innerHTML]="contentHtml"></span>
        </div>
        <div class="d-flex justify-content-center pt-3" mat-dialog-actions>
            <button *ngFor="let button of data.buttons;let i = index"
                class="btn {{button.isPrimary ? 'btn-primary' : 'bg-white border-primary text-primary'}} px-5 {{i > 0 ? 'ml-3' : ''}} {{button.cssClass || ''}}"
                (click)="_handleButtonClick(button, $event)" mat-button>{{button.label}}</button>
        </div>
    </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomMessageBoxDialog<R = any> {
    get _hasIcon() { return this.data.icon > 0; }
    get contentHtml(): SafeHtml { return this.domSanitizer.bypassSecurityTrustHtml(this.data.content); }
    constructor(
        private dialogRef: MatDialogRef<CustomMessageBoxDialog>,
        @Inject(MAT_DIALOG_DATA) public data: CustomMessageBoxData,
        private domSanitizer: DomSanitizer) {
        if (Array.isArray(data.buttons)) {
            data.buttons = data.buttons.sort(_ => _.order || 0);
        } else if (typeof data.buttons === 'number') {
            switch (data.buttons) {
                case CustomMessageBoxButtonOptions.OkCancel:
                    data.buttons = CustomMessageBoxButton.OkCancel;
                    break;
                case CustomMessageBoxButtonOptions.YesNo:
                    data.buttons = CustomMessageBoxButton.YesNo;
                    break;
            }
        } else {
            // TODO: construct error and throw exception.
            // Currently, a "Close" button is added by default if user didn't specify any button.
            data.buttons = [CustomMessageBoxButton.Close];
        }
    }

    _getIconCssClasses() {
        if (!this._hasIcon) {
            return '';
        }
        let css = '';
        switch (this.data.icon) {
            case CustomMessageBoxIcon.Info:
                css = 'fa-info-circle text-info';
                break;
            case CustomMessageBoxIcon.Warning:
                css = 'fa-exclamation-triangle text-warning';
                break;
            case CustomMessageBoxIcon.Error:
                css = 'fa-exclamation-circle text-danger';
                break;
            case CustomMessageBoxIcon.Question:
                css = 'fa-question-circle text-primary';
                break;
        }
        if (css !== '') {
            switch (this.data.iconSize) {
                case CustomMessageBoxIconSize.Small:
                    css += ' fa-3x';
                    break;
                case CustomMessageBoxIconSize.Large:
                    css += ' fa-5x';
                    break;
                case CustomMessageBoxIconSize.ExtraLarge:
                    css += ' fa-6x';
                    break;
                case CustomMessageBoxIconSize.Medium:
                default:
                    css += ' fa-4x';
                    break;
            }
        }
        return css;
    }

    _handleButtonClick(button: CustomMessageBoxButton, event) {
        const fpEvent = new FPEvent();
        if (typeof button.onClick === 'function') {
            button.onClick(fpEvent);
        }
        if (!fpEvent.cancelled) {
            this.dialogRef.close({ result: button.dialogResult });
        }
    }
}