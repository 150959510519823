import { Component, EventEmitter, Injector, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MemberFormBase } from "../base/member-form-base";
import { CommonService, MemberService, MemberSignupService, MessageBox } from "@fp/services";
import { PersonalDetailsNewComponent } from "../personal-details-new/personal-details-new.component";
import { Address, Country, DataResult, Gender, Member, Postcode, ResultModel, SearchFieldOperator, SearchPaginationCriteria, State, Suburb, SuburbPostcodeStateModel, SuburbSearchResultRecord, UploadFile, User, UserModel } from "@fp/models";
import { Router } from "@angular/router";
import { CommonConstants } from "@fp/constant/common-constants";
import { RouterConstant } from "../../constant/routerconstant";
import { DialogResult } from "../common-dialog/common-dialog.component";
import { MemberBankAccountComponent } from "../members/shared/bank-account/bank-account.component";
import { BankAccount } from "@fp/models";
import * as enums from "src/app/enums";
import { PatternConstant } from "@fp/constant";

@Component({
  selector: "payment-details",
  templateUrl: "./payment-details.component.html",
  styleUrls: ["./payment-details.component.css"],
  providers: [
    { provide: MemberFormBase, useExisting: PaymentDetailsComponent }
  ]
})
export class PaymentDetailsComponent extends MemberFormBase implements OnInit {

  SignupStepEnum = enums.MemberSignupStep;
  paymentDetailsFormGroup: UntypedFormGroup;
  @Output() isCompleteForm: EventEmitter<any> = new EventEmitter();
  isFormDisabled: boolean = false;
  bankCodeText: string = "BSB";
  bankHeaderText: string = "";

  isAus: boolean = true;

  get BankAccountNoCtrl() { return this.paymentDetailsFormGroup.get('BankAccountNumber'); }
  get BankAccountNumberMaskCtrl() { return this.paymentDetailsFormGroup.get('BankAccountNumberMask'); }

  constructor(injector: Injector,
    private router: Router,
    private memberSvc: MemberService) {
    super(injector);
  }

  ngOnInit(): void {
    const builder = new UntypedFormBuilder();
    this.paymentDetailsFormGroup = builder.group({
      txtBankName: ["", Validators.required],
      txtAccountName: ["", Validators.required],
      txtBankCode: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      BankAccountNumber: [null, [Validators.required, Validators.pattern(PatternConstant.NUMBER_ONLY)]],
      BankAccountNumberMask: [null, [Validators.required]],
      txtAccountType: [null],
    });
  }

  submitPaymentDetails() {
    if (this.paymentDetailsFormGroup.valid) {
      this.data.model.BankAccount = new BankAccount();
      var bankAccountId = 0;

      if(this.data.model.BankAccount && this.data.model.BankAccount.BankAccountId &&  this.data.model.BankAccount.BankAccountId > 0){
            bankAccountId = this.data.model.BankAccount.BankAccountId;
      }
      this.data.model.BankAccount.BankAccountId = bankAccountId;
      this.data.model.BankAccount.BankAccountBsb = this.paymentDetailsFormGroup.controls.txtBankCode.value;
      this.data.model.BankAccount.BankAccountNumber = this.paymentDetailsFormGroup.controls.BankAccountNumber.value;
      this.data.model.BankAccount.BankAccountType = this.paymentDetailsFormGroup.controls.txtAccountType.value;
      this.data.model.BankAccount.BankAccountName = this.paymentDetailsFormGroup.controls.txtAccountName.value;
      this.data.model.BankAccount.BankName = this.paymentDetailsFormGroup.controls.txtBankName.value;

      this.Invoke(this.memberSvc.SavePaymentInfo(this.data.model), {
        onSuccess: (res: ResultModel) => {
          if (res.Success) {
            this.data.model.SignupStep = enums.MemberSignupStep.PaymentDetails;
            this.isCompleteForm.emit(CommonConstants.SIGNUP_PAYMENT_DETAILS);
          } else {
            const supportLink =
              '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
            MessageBox.ShowOKCustom(
              this.dialog,
              "Error",
              "Sorry, we're unable to complete the registration. \
                          Please try again. If you continue to experience problems, send us an enquiry \
                          via our support site " +
                supportLink,
              "Take me back"
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
              }
            });
            this._logger.error(res);
          }
        },
        onError: (err) => {
          const supportLink =
            '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
          MessageBox.ShowOKCustom(
            this.dialog,
            "Error",
            "Sorry, we're unable to complete the registration. \
                      Please try again. If you continue to experience problems, send us an enquiry \
                      via our support site " +
              supportLink,
            "Take me back"
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
            }
          });
          this._logger.error(err);
        },
      });
    }
  }

  public txtAccNumber_Focus() {
    this.paymentDetailsFormGroup.get('BankAccountNumberMask').setValue('');
    this.paymentDetailsFormGroup.get('BankAccountNumber').setValue('');
  }

  public txtAccNumber_Focusout() {
    let val: string = this.paymentDetailsFormGroup.get('BankAccountNumberMask').value;
    val = val.replace(/\s/g, "");
    val = val.replace(/'/g, "");
    if (val.includes("*")) {
      this.paymentDetailsFormGroup.get('BankAccountNumberMask').setValue('');
      this.paymentDetailsFormGroup.get('BankAccountNumber').setValue('');
      return;
    }
    if (val !== null && val !== undefined && val.length > 0 && val !== '' && val.indexOf('=') == -1) {
      var handleLength = val;
      if (handleLength.length == 1) { // Encrypting a single digit will result in error.
        handleLength = handleLength + "000";
      }
      this.paymentDetailsFormGroup.get('BankAccountNumber').setValue(handleLength);
      if (val.length > 4) {
        val = '****' + val.substr(4, val.length - 1);
      }
    } else {
      let ori_val: string = this.paymentDetailsFormGroup.get('BankAccountNumber').value;
      if (ori_val !== null && ori_val !== undefined && ori_val.length > 0 && ori_val !== '' && ori_val.indexOf('=') > -1) {
        ori_val = '****' + val.substr(4, ori_val.length - 1);
      }
    }
    this.paymentDetailsFormGroup.get('BankAccountNumberMask').setValue(val);
  }

  public setBankAccountNo(){
    let val: string = this.paymentDetailsFormGroup.get('BankAccountNumberMask').value;
    this.paymentDetailsFormGroup.get('BankAccountNumber').setValue(val);
  }

  PatchValue( memberData: Member , options?: { onlySelf?: boolean; emitEvent?: boolean; }) {

    if(memberData && memberData.FirstName && memberData.LastName && memberData.PrimaryEmail){
      if(memberData.SignupStep >= enums.MemberSignupStep.PackageDetails){
        this.isFormDisabled = false;
      }
      else{
        this.isFormDisabled = true;
      }

      if(memberData?.Employer?.State?.StateId == 9){
        this.isAus = false;
        this.bankCodeText = "Bank Code";
        this.BankAccountNoCtrl.setValidators( [Validators.required, Validators.minLength(7), Validators.maxLength(7), Validators.pattern(PatternConstant.NUMBER_ONLY)]);
        this.BankAccountNumberMaskCtrl.setValidators( [Validators.required, Validators.minLength(7), Validators.maxLength(7)]);
        this.bankHeaderText = "Bank Details - Direct Debit Instruction"
      }
      else{
        this.isAus = true;
        this.bankCodeText = "BSB";
        this.BankAccountNoCtrl.setValidators( [Validators.required, Validators.maxLength(9), Validators.pattern(PatternConstant.NUMBER_ONLY)]);
        this.BankAccountNumberMaskCtrl.setValidators( [Validators.required, Validators.maxLength(9)]);
        this.bankHeaderText = "Bank Details"
      }
      
      if (memberData.BankAccount && memberData.BankAccount.BankAccountName) {
        this.paymentDetailsFormGroup.get("txtBankName").setValue(memberData.BankAccount.BankName);
      }
      
      if (memberData.BankAccount && memberData.BankAccount.BankAccountName) {
        this.paymentDetailsFormGroup.get("txtAccountName").setValue(memberData.BankAccount.BankAccountName);
      } 

      if (memberData.BankAccount && memberData.BankAccount.BankAccountName) {
        this.paymentDetailsFormGroup.get("txtBankCode").setValue(memberData.BankAccount.BankAccountBsb);
      } 

      if (memberData.BankAccount && memberData.BankAccount.BankAccountName) {
        this.paymentDetailsFormGroup.get("txtAccountType").setValue(memberData.BankAccount.BankAccountType);
      } 

      if (memberData.BankAccount && memberData.BankAccount.BankAccountName) {
        this.paymentDetailsFormGroup.get("BankAccountNumber").setValue(memberData.BankAccount.BankAccountNumber);
        this.paymentDetailsFormGroup.get("BankAccountNumberMask").setValue(memberData.BankAccount.BankAccountNumber);
        this.txtAccNumber_Focusout();
      } 
    }
    else{
      this.isFormDisabled = true;
    }
    
  }
}
