import { Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { empty } from 'rxjs';
import { catchError, debounceTime, skipWhile, switchMap } from 'rxjs/operators';
import { MemberFormBase } from 'src/app/components/base/member-form-base';
import { CommonMessage, CommonString } from 'src/app/constant';
import { FPValidators, StringHelper } from 'src/app/helpers';
import { DataResult, Employer, SearchPaginationRequest, State, SuburbSearchResultRecord, SearchPaginationCriteria, SearchFieldOperator, Suburb } from 'src/app/models';
import { EmployerService } from 'src/app/services/employer.service';
import { RegionService } from 'src/app/services/region.service';
import { EEmployerStatus } from 'src/app/enums';
import { CommonDataService, SuburbService, MessageBox, CommonService } from '@fp/services';
import { DialogResult } from '@fp/components/common-dialog/common-dialog.component';
import { CommonConstants } from '@fp/constant/common-constants';
import { truncateSync } from 'fs';
import { AddressService } from '@fp/services/address.service';
import { City } from '@fp/models/city.model';

@Component({
    selector: 'app-member-employer-info',
    templateUrl: './employer-info.component.html',
    styleUrls: ['./employer-info.component.css'],
    providers: [{ provide: MemberFormBase, useExisting: MemberEmployerInfoComponent }]
})
export class MemberEmployerInfoComponent extends MemberFormBase {
    @Input() shouldFetchLocations: boolean = true;
    parentFocus = 0;
    states: State[];
    selectedState: State;
    selectedCity: City;
    isSearchingEmployer = false;
    isSearchingWorkPlace = false;
    isSearchingCity = false;
    employerSearchResults: Employer[];
    private employerList: Employer[];
    private cityList: City[];
    private isFromAustralia = true;
    private suburbList: SuburbSearchResultRecord[];
    private isSignUp: Boolean = false;
    wrkplcSearchResults: SuburbSearchResultRecord[];
    citySearchResults: City[];
    selectedEmployerId = 0;
    selectedCityId = 0;
    StateCtrl = null;
    countryName="";
    nzStatus="";
    get EmployerCtrl() { return this.form.get('Employer'); }
    get EmployerIdCtrl() { return this.form.get('EmployerId'); }
    get nzStatusCtrl() { return this.form.get('nzStatus'); }
    get WorkPlaceCtrl() { return this.form.get('WorkplaceSuburb'); }
    get WorkPlaceObjCtrl() { return this.form.get('WorkplaceSuburbObj'); }

    //get CityCtrl() { return this.form.get('City'); }

    @ViewChild('employerSearchBox') private employerSearchBox: ElementRef<HTMLDivElement>;
    @ViewChild('wrkplcSearchBox') private wrkplcSearchBox: ElementRef<HTMLDivElement>;
    @ViewChild('citySearchBox') private citySearchBox: ElementRef<HTMLDivElement>;
    @ViewChild('autoEmployer') private autoEmployer: MatAutocomplete;
    @ViewChild('autoWorkplaceSuburb') private autoWorkplaceSuburb: MatAutocomplete;
    @ViewChild('autoCity') private autoCity: MatAutocomplete;
    @Output() resetEvent = new EventEmitter();
    @Input() isMemberTransfer: boolean;

    CommonMessage = CommonMessage;
    CommonString = CommonString;
    StringHelper = StringHelper;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            EmployerId: [null, [Validators.required]],
            Employer: [null, [Validators.required, FPValidators.resolvableAutocompleteOption]],
            EmployeePayrollNo: [null, Validators.required],
            WorkplaceSuburb: [null, Validators.required],
            WorkplaceSuburbObj: [null, [Validators.required, FPValidators.resolvableAutocompleteOption]],
            //City : [null, null],
            CountryName: [null,  null],
            nzStatus : [null,  null]
        });
    }

    constructor(injector: Injector,
        private employerSvc: EmployerService,
        private commonService: CommonService,
        private commonDataSvc: CommonDataService,
        private addressService: AddressService,
        private suburbService: SuburbService) {
        super(injector);

        //member transfer
        if (this.isMemberTransfer == undefined)
            this.isMemberTransfer = false;
    }

    ngOnInit() {

        this.isFromAustralia = this.commonService.getUserCountry() !== "New Zealand";
        if(this.isFromAustralia) {
            this.StateCtrl = new UntypedFormControl(null, Validators.required);
        }
    }

    ngAfterViewInit() {
        this.OnLoad();
    }

    OnLoad() {
        if(this.commonService.App.isMemberSignup) {
            this.isSignUp = true;
        }

        this.countryName=this.commonService.getUserCountry();
        this.nzStatus="NZ";
        this.form.patchValue({ nzStatus: this.nzStatus }, { emitEvent: false });
        this.form.patchValue({ CountryName: this.countryName }, { emitEvent: false });
        this.states = CommonConstants.GET_STATES.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
        
        if (this.shouldFetchLocations) {
            this.isSearchingWorkPlace = true
            this.commonSvc.getLocationData().subscribe(
                (res) => {
                    if (res.Success) {
                        this.suburbList = res.Data.suburbs;
                        this.isSearchingWorkPlace = false;
                    }
                }
            );
            this.isSearchingCity = true;
            this.addressService.getUniqueCityName().subscribe((res) => {
                this.cityList = res.Data;
                this.isSearchingCity = false;
            });
        }
        
        super.OnLoad();
/*
         this.Invoke(
             this.commonDataSvc.getStates(),
             {
                 onSuccess: (res: DataResult<State[]>): void => {
                     if (res.Success) {
                         this.states = res.Data.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
                        super.OnLoad();
                     } else {
                         MessageBox.ShowError(this.dialog, "Sorry, we're having trouble connecting. Let's try that again.")
                        .subscribe(res => {
                             if (res.result.toLowerCase() === DialogResult.Ok) {
                                window.location.reload();
                             }
                        });
                         this._logger.error(res);
                     }
                 },
                 onError: (err) => {
                     MessageBox.ShowError(this.dialog, "Sorry, we're having trouble connecting. Let's try that again.")
                   .subscribe(res => {
                         if (res.result.toLowerCase() === DialogResult.Ok) {
                             window.location.reload();
                         }
                     });
                    this._logger.error(err);
                }

            }
         );*/
    }

    LoadLocations() {
        this.isSearchingWorkPlace = true
        this.commonSvc.getLocationData().subscribe(
            (res) => {
                if (res.Success) {
                    this.suburbList = res.Data.suburbs;
                    this.isSearchingWorkPlace = false;
                }
            }
        );
        this.isSearchingCity = true;
        this.addressService.getUniqueCityName().subscribe((res) => {
            this.cityList = res.Data;
            this.isSearchingCity = false;
        });
    }

    LoadComplete() {
        this.employerList = this.commonService.getEmployers();
        let currentValue = '';
        let currentSuburbValue = '';
        let currentCityValue = '';
        setTimeout(() => {
            if(this.isFromAustralia) {
                this.StateCtrl.valueChanges.subscribe((value: State) => {
                    this.selectedState = value;
                    this.EmployerCtrl.setValue(null);
                });
            } else {
             /*  this.CityCtrl.valueChanges.subscribe((value: City) => {
                    this.selectedCity = value;
                    this.EmployerCtrl.setValue(null);
                }) */
            }

            this.form.get('EmployerId').valueChanges.subscribe(value => {
                this.selectedEmployerId = value;
                this.employerSearchResults = [];
            });

            this.EmployerCtrl.valueChanges
                .pipe(
                    skipWhile((value) =>  (this.isFromAustralia && !this.StateCtrl.value) || (value && typeof value === 'object')),
                    debounceTime(500),
                    switchMap((value) => {
                        if (typeof value === 'string') {
                            this.form.get('EmployerId').setValue(null);
                            if (this.isFromAustralia && this.StateCtrl.value && value.length > 2) {
                                this.isSearchingEmployer = true;
                                return value;
                            } else if (!this.isFromAustralia  && value.length > 2){
                                this.isSearchingEmployer = true;
                                return value;

                            } 
                            else {
                                this.isSearchingEmployer = false;
                            }
                        }
                        return empty();
                    })
                ).subscribe((value: string) => {
                    this.isSearchingEmployer = false;
                    if(currentValue != this.EmployerCtrl.value) {
                        currentValue = this.EmployerCtrl.value;
                        let employerList: Employer[] = this.employerList;
                        if(this.isFromAustralia){
                            employerList = employerList.filter(x => x.State.StateId == this.StateCtrl.value.StateId && x.Name.toLowerCase().trim().includes(this.EmployerCtrl.value.toLowerCase().trim()));
                        } else {
                            employerList = employerList.filter(x =>x.State.StateId == 9 && x.Name.toLowerCase().trim().includes(this.EmployerCtrl.value.toLowerCase().trim()));//set State Id to NZ
                        }
                        employerList.sort((a, b) => a.Name.localeCompare(b.Name));
                        this.employerSearchResults = employerList;
                        this.changeDetectorRef.markForCheck();
                    }
                }
            );

            /* this.EmployerCtrl.valueChanges
                 .pipe(
                     skipWhile((value) => !this.StateCtrl.value || (value && typeof value === 'object')),
                     debounceTime(500),
                     switchMap((value) => {
                         if (typeof value === 'string') {
                             this.form.get('EmployerId').setValue(null);
                             if (this.StateCtrl.value && value.length > 2) {
                                 this.isSearchingEmployer = true;
                                 const searchRequest: SearchPaginationRequest = {
                                     OrderBy: 'name',
                                     OrderByAsc: true,
                                     PageNumber: 0,
                                     PageSize: 0,
                                     Params: [
                                         {
                                             Name: 'state_id',
                                             Value: this.StateCtrl.value.StateId
                                         },
                                         {
                                             Name: 'name',
                                             Value: value
                                         },
                                         {
                                             Name: 'status_ids',
                                             Value: EEmployerStatus.Active + ', ' + EEmployerStatus.Onboarding
                                         }
                                     ]
                                 };
                                 return this.employerSvc.search(searchRequest);
                             } else {
                                 this.isSearchingEmployer = false;
                             }
                         }
                         return empty();
                     }),
                     catchError((e) => { throw e; }))
                 .subscribe(
                     (edata: string) => {
                         this.isSearchingEmployer = false;
                         const dectypted_data = this.commonSvc.D_FP_ResponseData(edata);
                         const rdata = JSON.parse(dectypted_data);
                         if (rdata.Success) {
                             if (rdata.Data) {
                                 this.employerSearchResults = rdata.Data.Results;
                                 this.changeDetectorRef.markForCheck();
                             }
                         } else {
                             MessageBox.ShowError(this.dialog, "Sorry, we're having trouble connecting. Let's try that again.")
                             .subscribe(res => {
                                 if (res.result.toLowerCase() === DialogResult.Ok) {
                                     window.location.reload();
                                 }
                             });
                             this._logger.error(rdata);
                         }
                     },
                     error => {
                         this.isSearchingEmployer = false;
                         MessageBox.ShowError(this.dialog, "Sorry, we're having trouble connecting. Let's try that again.")
                         .subscribe(res => {
                             if (res.result.toLowerCase() === DialogResult.Ok) {
                                 window.location.reload();
                             }
                         });
                         this._logger.error(error);
                     }); */

            this.WorkPlaceObjCtrl.valueChanges.pipe(
                skipWhile((value) => (value && typeof value === 'object')),
                debounceTime(500),
                switchMap((value) => {
                    if (typeof value === 'string' && (this.StateCtrl.value) 
                        && value.trim().length >= 3) {
                        this.changeDetectorRef.markForCheck();
                        return value;
                    }
                    return empty();
                }),
                catchError((e) => { throw e; })
            ).subscribe(data => {
                if(currentCityValue != this.WorkPlaceObjCtrl.value) {
                    currentSuburbValue = this.WorkPlaceObjCtrl.value;
                    let getList = this.suburbList.filter(x => x.State.Name == (this.isFromAustralia ? this.StateCtrl.value.Name : 'NZ') && x.Name.toLowerCase().trim().includes(this.WorkPlaceObjCtrl.value.toLowerCase().trim()));
                    this.wrkplcSearchResults = getList;
                    this.isSearchingWorkPlace = false;
                    this.changeDetectorRef.markForCheck();
                }
            }, error => {
                this.isSearchingWorkPlace = false;
                MessageBox.ShowError(this.dialog, "Sorry, we're having trouble connecting. Let's try that again.")
                .subscribe(res => {
                    if (res.result.toLowerCase() === DialogResult.Ok) {
                        window.location.reload();
                    }
                });
                this._logger.error(error);
            });

          /*  this.CityCtrl.valueChanges.pipe(
                skipWhile((value) => (value && typeof value === 'object')),
              debounceTime(500),
                catchError((e) => { throw e; })
            ).subscribe((value: string) => {
                this.isSearchingCity = false;
                if(currentValue != this.CityCtrl.value) {
                    currentValue = this.CityCtrl.value;
                   let cityList: City[] = this.cityList;
                  cityList = cityList.filter(x => x.Name.toLowerCase().trim().includes(this.CityCtrl.value?.toLowerCase().trim()));
                  cityList.sort((a, b) => a.Name.localeCompare(b.Name));
                  this.citySearchResults = cityList;
                  this.changeDetectorRef.markForCheck();
              }
            }, error => {
                this.isSearchingCity = false;
                MessageBox.ShowError(this.dialog, "Sorry, we're having trouble connecting. Let's try that again.")
                .subscribe(res => {
                   if (res.result.toLowerCase() === DialogResult.Ok) {
                       window.location.reload();
                   }
               });
                this._logger.error(error);
            });

             this.WorkPlaceObjCtrl.valueChanges.pipe(
                 skipWhile((value) => (value && typeof value === 'object')),
                 debounceTime(500),
                 switchMap((value) => {
                     if (typeof value === 'string' && value.length >= 3) {
                         this.isSearchingWorkPlace = true;
                         this.changeDetectorRef.markForCheck();
                         const criteria = new SearchPaginationCriteria();
                         criteria.PageNumber = 0;
                         criteria.PageSize = 0;
                         criteria.OrderBy = 'name';
                         criteria.OrderByAsc = false;
                         criteria.ViewColumns = null;
                         criteria.Criteria = {
                             Fields: [{ Name: 'name', Operator: SearchFieldOperator.Contains, Value: value }],
                             Expressions: [],
                             LogicGroup: 1
                         };
                         return this.suburbService.advancedSearch(criteria);
                     }
                     return empty();
                 }),
                 catchError((e) => { throw e; })
             ).subscribe(data => {
                 console.log(data.Data.Results);
                 this.wrkplcSearchResults = data.Data.Results;
                 this.isSearchingWorkPlace = false;
                 this.changeDetectorRef.markForCheck();
             }, error => {
                 this.isSearchingWorkPlace = false;
                 MessageBox.ShowError(this.dialog, "Sorry, we're having trouble connecting. Let's try that again.")
                 .subscribe(res => {
                     if (res.result.toLowerCase() === DialogResult.Ok) {
                         window.location.reload();
                     }
                 });
                 this._logger.error(error);
             });*/
            // Fix postcode autocomplete's width.
            this.autoEmployer.panelWidth = this.employerSearchBox.nativeElement.clientWidth;
            this.autoWorkplaceSuburb.panelWidth = this.employerSearchBox.nativeElement.clientWidth;
            if(this.autoCity) {
                this.autoCity.panelWidth = this.employerSearchBox.nativeElement.clientWidth;
            }
        });
        super.LoadComplete();
        if (this.isFromAustralia) {
            this.CheckStateValue();
        } else {
            this.CheckCityValue();
        }        
    }

    PatchValue(value: { [key: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean; }) {
        let employer = <Employer>value['Employer'];
        if (!employer && this.form.get('Employer').value) {
            employer = this.form.get('Employer').value;
            value['Employer'] = employer;
        }
        if (employer) {
            if (value['EmployerId'] == null) {
                value['EmployerId'] = employer.EmployerId;
            }
            if (this.isFromAustralia && employer.State) {
                for(var i = 0; i < this.states.length; i++) {
                    if(this.states[i].Name == employer.State.Name) {
                        this.StateCtrl.setValue(this.states[i], { emitEvent: false });
                        this.selectedState = this.states[i];
                        break;
                    }
                }
                //this.StateCtrl.setValue(employer.State, { emitEvent: false });
                this.StateCtrl.disable();
                this.changeDetectorRef.markForCheck();
            }
        }
        const wrkplcresponse: string = value['WorkplaceSuburb'];
        if (wrkplcresponse) {
            const arr = wrkplcresponse.split(', ');
            if (arr.length === 3 && (value['WorkplaceSuburbObj'] === null || value['WorkplaceSuburbObj'] === undefined)) {
                const temp: SuburbSearchResultRecord = new SuburbSearchResultRecord();
                temp.Name = arr[0];
                temp.State = { Id: null, Name: arr[1] };
                temp.Postcode = { Id: null, Name: arr[2] };
                value['WorkplaceSuburbObj'] = temp;
            }
        }
        super.PatchValue(value, options);

    }

    ddlEmployerStateOption_CompareFn(opt1: State, opt2: State) {
        return (!opt1 && !opt2) || (opt1 && opt2 && opt1.StateId == opt2.StateId);
    }

    ddlEmployerCityOption_CompareFn(opt1: City, opt2: City) {
        return (!opt1 && !opt2) || (opt1 && opt2 && opt1.CityId == opt2.CityId);
    }

    AutoComplete_DisplayWithFn(option: any) {
        return option ? option.Name : '';
    }

    AutoCompleteWorkPlace_DisplayWithFn(option: any) {
        if (option) {
            if (option.Name && option.State && option.Postcode) {
                const val = option.Name + ', ' + option.State.Name + ", " + option.Postcode.Name;
                return (val);
            } else {
                return option;
            }
        } else {
            return '';
        }
    }

    AutoCompleteCity_DisplayWithFn(option: any) {
        if (option) {
            if (option.Name) {
                const val = option.Name;
                return (val);
            } else {
                return option;
            }
        } else {
            return '';
        }
    }


    SelectEmployer(employer: Employer) {
        this.selectedEmployerId = employer ? employer.EmployerId : null;
        this.form.patchValue({ EmployerId: this.selectedEmployerId, Employer: employer }, { emitEvent: false });
        this.employerSearchResults = [];
        if(this.isSignUp) {
            this.resetEvent.emit('ResetPackages');
        }

        if (this.isMemberTransfer == true) {
            this.EmployerIdCtrl.markAsDirty();
            this.EmployerIdCtrl.markAsTouched();
        }
    }

    SelectWorkPlace(wrkplc: SuburbSearchResultRecord) {
        if (wrkplc) {
            if (wrkplc.Name && wrkplc.State && wrkplc.Postcode) {
                const val = wrkplc.Name + ', ' + wrkplc.State.Name + ", " + wrkplc.Postcode.Name;
                this.form.patchValue({ WorkplaceSuburb: val }, { emitEvent: false });
            }
        }
        this.wrkplcSearchResults = [];
        this.WorkPlaceCtrl.markAsDirty();
        this.WorkPlaceCtrl.markAsTouched();
    }

    SelectCity(city: City) {
    /*    if (city) {            
            if (city.Name) {
               this.form.get('Employer').enable();
           }
       }
       this.citySearchResults = [];
        this.CityCtrl.markAsDirty();
        this.CityCtrl.markAsTouched();*/
    }

    txtEmployer_Blur(e) {
        if (this.EmployerCtrl.dirty) {
            this.EmployerCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
            this.EmployerCtrl.updateValueAndValidity({ emitEvent: false });
        }
        this.parentFocus = 0;
    }

    txtWorkPlace_Blur(e) {
        if (this.WorkPlaceObjCtrl.dirty) {
            this.WorkPlaceObjCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
            this.WorkPlaceObjCtrl.updateValueAndValidity({ emitEvent: false });
        }
        this.parentFocus = 0;
    }

    txtCity_Blur(e) {
    /*    if (this.CityCtrl.dirty) {
            this.CityCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
           this.CityCtrl.updateValueAndValidity({ emitEvent: false });
       }
       this.parentFocus = 0;*/
    }

    txtEmployer_Input(e) {
        if (this.EmployerCtrl.dirty) {
            if (typeof this.EmployerCtrl.value === 'string' &&
                this.EmployerCtrl.value.length == 0 &&
                this.selectedEmployerId > 0) {
                this.SelectEmployer(null);
            }
            this.EmployerCtrl.setValidators(Validators.required);
        }
    }

    txtWorkPlace_Input(e) {
        if (this.WorkPlaceObjCtrl.dirty) {
            if (typeof this.WorkPlaceObjCtrl.value === 'string' &&
                this.WorkPlaceObjCtrl.value.length == 0) {
                this.SelectWorkPlace(null);
            }
            this.WorkPlaceObjCtrl.setValidators(Validators.required);
        }
    }

    txtCity_Input(e) {
    /*    if (this.CityCtrl.dirty) {
            if (typeof this.CityCtrl.value === 'string' &&
               this.CityCtrl.value.length == 0 &&
                this.selectedCityId > 0) {
                this.SelectCity(null);
            }
            this.CityCtrl.setValidators(Validators.required);
        }*/
    }

    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }

    getClassFocused(vlFocused) {
        if (this.parentFocus == vlFocused) {
            return "focused";
        } else {
            return "";
        }
    }

    public ddlEmployerState_Change() {
        this.CheckStateValue();
    }

    public CheckStateValue() {
        if (!this.isFromAustralia){
            return;
        }

        if (this.StateCtrl !== null) {
            const val = this.StateCtrl.value;
            if (val === null || val === undefined || val === '') {
                this.form.get('Employer').disable();
            } else {
                this.form.get('Employer').enable();
            }
        }
    }

    public ddlEmployerCity_Change() {
        this.CheckCityValue();
    }

    public CheckCityValue() {
      /*  if (this.CityCtrl !== null) {
            const val = this.CityCtrl.value;
            if (!val) {
                this.form.get('Employer').disable();
            }
        }*/
    }

    // utilised logic that dictates if a field is valid to determine whether or not 'Next' button is disabled
    DisableNextButton () {
        if((!this.isControlValid('EmployeePayrollNo', this.isMemberTransfer)) || // logic for Employee/Payroll No.
       // (this.isFromAustralia ? !this.isControlValid(this.StateCtrl, true) : !this.isControlValid(this.CityCtrl, true)) || // logic for Employer State
        (!this.isControlValid(this.EmployerCtrl, this.isMemberTransfer) || !this.isControlValid(this.EmployerIdCtrl, this.isMemberTransfer)) || //logic for Employer
        (!this.isControlValid(this.WorkPlaceObjCtrl, true) || !this.isControlValid(this.WorkPlaceCtrl, true)) // logic for Workplace Suburb
        ) {
            return true;
        }
        else {
            return false;
        }
    }
}
