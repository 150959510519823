<div [formGroup]="form">
    <div class="form-group row">
        <label for="txtNote" class="col-md-3 col-lg-2 col-form-label">Employer Notes</label>
        <div class="col-md-6">
            <div class="d-flex flex-column w-100">
                <textarea class="form-control" id="txtNote" maxlength="2000" #note></textarea>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-8 col-lg-5 offset-sm-2">
            <button type="button" class="px-2 py-2 btn btn-primary text-transform-none px-lg-5 mr-3"
                [disabled]="!note.value" (click)="addNote()">
                <i class="fas fa-plus-circle mr-2"></i>Add Note</button>
        </div>
    </div>
    <div class="py-3">
        <div class="form-group row">
            <div class="col-md-10 offset-sm-2">
                <div class="employer_note_container border-default rounded-10 border-2 w-100">
                    <div class="tablecontainer">
                        <table mat-table [dataSource]="dataSource">
                            <ng-container matColumnDef="user">
                                <th mat-header-cell *matHeaderCellDef>User</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.UserFullName}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="dateTime">
                                <th mat-header-cell *matHeaderCellDef>Date/Time
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.DateTimeStamp}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="note">
                                <th mat-header-cell *matHeaderCellDef>Note</th>
                                <td mat-cell *matCellDef="let element"> {{element.Note}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="_displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: _displayedColumns;"></tr>
                        </table>
                    </div>
                    <mat-toolbar [hidden]="!dataSource || dataSource.data.length > 0" class="bg-white">
                        <mat-toolbar-row *ngIf="dataSource && dataSource.data.length == 0"
                            class="text-muted d-block p-2 bg-light text-center">
                            {{CommonMessage.TABLE_NO_RECORD_FOUND}}
                        </mat-toolbar-row>
                    </mat-toolbar>
                </div>
            </div>
        </div>
    </div>
</div>