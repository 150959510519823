<div [formGroup]="form">
    <ngb-accordion [destroyOnHide]="false" activeIds="pnlPaymentDetails">
        <ngb-panel id="pnlPaymentDetails">
            <ng-template ngbPanelTitle>
                Payment Details
                <i class="icon-fp" [ngClass]="{'fas fa-check': paymentDetails?.valid}"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-facility-payment-details [form]="form.get('PaymentDetails')" [data]="data"
                    [loadOnDemand]="loadOnDemand" (methodChanged)="onPaymentMethodChange($event)" #paymentDetails>
                </app-facility-payment-details>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
    <ngb-accordion [destroyOnHide]="false" activeIds="pnlBankAccountDetails"
        [hidden]="form.get('BankAccountDetails.BankAccount')?.value == null">
        <ngb-panel id="pnlBankAccountDetails">
            <ng-template ngbPanelTitle>
                Bank Account Details
                <i class="icon-fp" [ngClass]="{'fas fa-check': bankAccountDetails?.valid}"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-facility-bank-account-details [form]="form.get('BankAccountDetails')" [data]="data"
                    [loadOnDemand]="loadOnDemand" #bankAccountDetails></app-facility-bank-account-details>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
    <ngb-accordion [destroyOnHide]="false" activeIds="pnlBPayDetails"
        [hidden]="form.get('BPayDetails.Bpay')?.value == null">
        <ngb-panel id="pnlBPayDetails">
            <ng-template ngbPanelTitle>
                BPay Details
                <i class="icon-fp" [ngClass]="{'fas fa-check': bPayDetails?.valid}"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-facility-bpay-details [form]="form.get('BPayDetails')" [data]="data" [loadOnDemand]="loadOnDemand"
                    #bPayDetails></app-facility-bpay-details>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
    <ngb-accordion [destroyOnHide]="false" activeIds="pnlChequeDetails"
        [hidden]="form.get('ChequeDetails.Cheque')?.value == null">
        <ngb-panel id="pnlChequeDetails">
            <ng-template ngbPanelTitle>
                Cheque Details
                <i class="icon-fp" [ngClass]="{'fas fa-check': chequeDetails?.valid}"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-facility-cheque-details [form]="form.get('ChequeDetails')" [data]="data"
                    [loadOnDemand]="loadOnDemand" #chequeDetails></app-facility-cheque-details>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>