<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Member Notifications</h4>

<div class="border-default rounded-10 border-2">
    <div class="tablecontainer">
        <table mat-table [dataSource]="dataSource" matSort matSortDirection="desc" class="rounded-10">
            <ng-container matColumnDef="CreateOrUpdateDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Date/Time
                </th>
                <td mat-cell mat-sort *matCellDef="let element">
                    {{ element.CreateOrUpdateDate | dateTimeFormatPipe }}
                </td>
            </ng-container>

            <ng-container matColumnDef="Note">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Notification
                </th>
                <td mat-cell mat-sort *matCellDef="let element">
                    {{ element.Note }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</div>