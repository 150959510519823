import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { User as DatadogUser } from '@datadog/browser-core';
import { environment } from '../../environments/environment';
import { ConfigService } from './config.service';

type AttemptData = {
  name: string;
  context?: object;
};

export type AttemptInput = AttemptData | string;

export type AttemptHandler<T> = () => T | Promise<T>;

@Injectable({
  providedIn: 'root',
})
export class DatadogService {
  constructor(private configService: ConfigService) {
    if (environment.datadog.enabled) {
      datadogRum.init({
        ...environment.datadog,
        version: this.configService.version,
      });
    } else {
      console.log('Datadog is disabled');
    }
  }

  public addAction(actionName: string, context?: object) {
    datadogRum.addAction(actionName, context);
  }

  public addError(error: Error, context?: object) {
    datadogRum.addError(error, context);
  }

  public async trackAttempt<T>(
    attempt: AttemptInput,
    handler: AttemptHandler<T>,
  ): Promise<T> {
    const { name, context } =
      typeof attempt === 'string' ? { name: attempt, context: {} } : attempt;

    this.addAction(`${name}_attempt`, context);
    try {
      const result = await handler();
      this.addAction(`${name}_success`, context);
      return result;
    } catch (error) {
      this.addError(error, context);
      this.addAction(`${name}_failure`, {
        ...context,
        error: error.message ?? error,
      });
      throw error;
    }
  }

  public setUser(user: DatadogUser) {
    datadogRum.setUser(user);
  }

  public clearUser() {
    datadogRum.clearUser();
  }
}
