<div *ngIf="isSuburbsLoaded && isSurveyTemplateLoaded">
    <div class="survey-title text-center">{{ currentPageTitle }}</div>
    <div class="survey-progress">
        <div *ngFor="let page of surveyPageQuestions.Pages; let i = index"
            class="survey-progress-step"
            (click)="SurveyTabSelection(i + 1)"
            [ngClass]="{'completed': isTabCompleted(i + 1), 'active': selectedTab === (i + 1)}">
            <i class="fas fa-check" *ngIf="isTabCompleted(i + 1)"></i>
        </div>
    </div>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="intro-heading">
                        {{ currentPageDescription }}                    
                    </div>
                </div>
            </div>
            <form [formGroup]="formGroup">
                <div *ngFor="let page of surveyPageQuestions.Pages">
                    <div *ngFor="let question of page.Questions">
                        <div class="question-container" *ngIf="question?.Type === 'SuburbSelection'" [hidden]="selectedTab != page?.PageNo">
                            <app-suburb-select required="{{question.Mandatory}}"  questionTitle="{{question.QuestionText}}" questionNumber="{{question?.QuestionNo}}"
                                id="{{question.UniqueIdentifier}}" formControlName ="{{question.UniqueIdentifier}}"[suburbs]="filteredSuburbList"></app-suburb-select>
                        </div>
                        <div class="question-container" *ngIf="question?.Type === 'RadioButton'" [hidden]="selectedTab != page?.PageNo">
                            <app-multiple-choice-input required="{{question.Mandatory}}"  questionTitle="{{question.QuestionText}}" formControlName ="{{question.UniqueIdentifier}}" questionNumber="{{question?.QuestionNo}}" [options]="question?.Choices"></app-multiple-choice-input>
                        </div>
                        <div class="question-container" *ngIf="question?.CustomQuestionType === 'FacilitySelection' && isFacilitiesLoaded" [hidden]="selectedTab != page?.PageNo">
                            <facility-picker required="{{question.Mandatory}}"  [mapHomeLocation]="location" [presetSuburbs]="presetSuburbs" [suburbList]="suburbList"
                                [facilityList]="facilityList" #facilityPicker [maxValues]="3" [minValues]="2"
                                formControlName ="{{question.UniqueIdentifier}}" [facilitySelectionQuestion]="question"></facility-picker>
                        </div>
                        <div class="question-container" *ngIf="question?.CustomQuestionType === 'ExistingFacilitySelection'" [hidden]="selectedTab != page?.PageNo">
                            <app-family-membership-picker required="{{question.Mandatory}}"  [facilities]="facilityList" formControlName ="{{question.UniqueIdentifier}}"
                                [existingFacilitySelectionQuestion]="question"></app-family-membership-picker>
                            <div class="mt-3 family-membership-error"
                                *ngIf="formGroup.get('familtyMemberships').touched || formGroup.get('familtyMemberships').dirty">
                                <div class="alert alert-danger" *ngIf="formGroup.get('familtyMemberships').errors?.required">
                                    {{formGroup.get('familtyMemberships').errors.required}}
                                </div>
                            </div>
                        </div>
                        <div class="question-container" *ngIf="question?.Type === 'CheckBox'" [hidden]="selectedTab != page?.PageNo">
                            <app-multiple-choice-input required="{{question.Mandatory}}"  questionTitle="{{question.QuestionText}}" formControlName ="{{question.UniqueIdentifier}}" questionNumber="{{question?.QuestionNo}}" [options]="question?.Choices" multiple></app-multiple-choice-input>
                        </div>
                        <div class="question-container" *ngIf="question?.Type === 'FreeText'" [hidden]="selectedTab != page?.PageNo">
                            <app-text-input required="{{question.Mandatory}}"  questionTitle="{{question.QuestionText}}" formControlName ="{{question.UniqueIdentifier}}" questionNumber="{{question?.QuestionNo}}"></app-text-input>
                        </div>
                    </div>
                </div>
            </form>
            
            <div class="survey-navigation" *ngIf="selectedTab == firstTabId || (selectedTab == facilitySelectionTabId && isFacilitiesLoaded)">
                <button class="btn back" (click)="BackSurveyPageClicked()" *ngIf="selectedTab > firstTabId">Back</button>
                <button class="btn btn-primary next" [disabled]="!isTabCompleted(selectedTab)" (click)="NextSurveyPageClicked()">Next</button>
            </div>
            <div class="survey-navigation" *ngIf="selectedTab == lastTabId">
                <button class="btn back" (click)="BackSurveyPageClicked()" *ngIf="selectedTab > firstTabId">Back</button>
                <button class="btn btn-primary next" [disabled]="!formGroup.valid" (click)="SubmitSurvey()">Submit</button>
            </div>
        </div>
</div>