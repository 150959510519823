// tslint:disable member-ordering
import { CommonData, Country, Gender, MemberType, State } from 'src/app/models';
import { CommonResponse, DataResult } from 'src/app/models';
import { ActivityConstant } from './activity-constants';
import { TermTypes } from './term-types-constant';

export class CommonConstants {
  public static COUNTRY_NEW_ZEALAND = "NEW ZEALAND";
  // States
  public static GET_STATES: State[] = [
    new State(1, "NSW", true, 1),
    new State(2, "ACT", true, 1),
    new State(3, "NT", true, 1),
    new State(4, "QLD", true, 1),
    new State(5, "SA", true, 1),
    new State(6, "TAS", true, 1),
    new State(7, "VIC", true, 1),
    new State(8, "WA", true, 1),
    new State(9, "NZ", true, 2),
  ];
  // Countries
  public static GET_COUNTRIES: Country[] = [
    new Country(1, "Australia", true),
    new Country(2, "New Zealand", true)
  ]
  // Gender
  public static GET_GENDERS: Gender[] = [
    new Gender(1, "Male", true, 1),
    new Gender(2, "Female", true, 1),
    new Gender(3, "Intersex/Other", true, 1),
    new Gender(4, "Prefer not to say", true, 1)
  ]
  // Title Names
  public static GET_TITLES: CommonResponse[] = [
    new CommonResponse(1, "Mr"),
    new CommonResponse(2, "Mrs"),
    new CommonResponse(3, "Miss"),
    new CommonResponse(4, "Ms"),
    new CommonResponse(5, "Master"),
    new CommonResponse(6, "Dr"),
    new CommonResponse(7, "Prof"),
    new CommonResponse(8, "Hon"),
    new CommonResponse(9, "Rev"),
    new CommonResponse(10, "Other")
  ]
  // Gender Names
  public static GET_GENDER_NAMES: CommonResponse[] = [
    new CommonResponse(1, "Male"),
    new CommonResponse(2, "Female"),
    new CommonResponse(3, "Intersex/Other"),
    new CommonResponse(4, "Prefer not to say")
  ]
  // State Names
  public static GET_STATE_NAMES: CommonResponse[] = [
    new CommonResponse(1, "NSW"),
    new CommonResponse(2, "ACT"),
    new CommonResponse(3, "NT"),
    new CommonResponse(4, "QLD"),
    new CommonResponse(5, "SA"),
    new CommonResponse(6, "TAS"),
    new CommonResponse(7, "VIC"),
    new CommonResponse(8, "WA")
  ];

  // State Names
  public static GET_NZ_STATE_NAMES: CommonResponse[] = [
    new CommonResponse(9, "NZ")
  ];
  
  // Member Types
  public static GET_MEMBER_TYPES: MemberType[] = [
    new MemberType(1, "Primary", 1, true),
    new MemberType(2, "Partner", 2, true),
    new MemberType(3, "Dependant", 3, true)
  ];
  // Sort of Membership
  public static GET_SORT_OF_MEMBERSHIP: CommonData[] = [
    new CommonData(1, "Monthly direct debit", true, 1),
    new CommonData(2, "Bulk payment upfront", true, 2),
    new CommonData(3, "Casual visit passes", true, 3),
    new CommonData(4, "Other", true, 4)
  ]
  // Suspension Reason
  public static GET_MEMBERSHIP_SUSPENSION_REASON: CommonResponse[] = [
    new CommonResponse(1, "Financial Difficulty/Can't afford"),
    new CommonResponse(2, "Too Expensive"),
    new CommonResponse(3, "Not Using It"),
    new CommonResponse(4, "No Suitable Facility"),
    new CommonResponse(5, "Medical reason"),
    new CommonResponse(5, "Holiday"),
    new CommonResponse(6, "Work location change"),
  ]
  // Note Types
  public static GET_NOTE_TYPES: CommonResponse[] = [
    new CommonResponse(1, "Internal"),
    new CommonResponse(2, "External")
  ]
  // Member Status
  public static GET_MEMBER_STATUS: CommonData[] = [
    new CommonData(1, "Candidate Member", true, 1),
    new CommonData(2, "New", true, 2),
    new CommonData(3, "Active", true, 3),
    new CommonData(4, "Invalid", true, 4),
    new CommonData(5, "Changed", true, 5),
    new CommonData(6, "Inactive", true, 6),
    new CommonData(7, "Banned", true, 7),
    new CommonData(8, "DeIdentified", true, 8),
    new CommonData(9, "SurveyCandidate", true, 9)
  ];

  // State Names
  public static GET_MAP_ACTIVITIES: CommonResponse[] = [
    new CommonResponse(1, ActivityConstant.CYCLING),
    new CommonResponse(2, ActivityConstant.GROUP_FITNESS),
    new CommonResponse(3, ActivityConstant.GYM_ACCESS),
    new CommonResponse(4, ActivityConstant.HIIT),
    new CommonResponse(5, ActivityConstant.LADIES_ONLY),
    new CommonResponse(6, ActivityConstant.PILATES),
    new CommonResponse(7, ActivityConstant.POOL),
    new CommonResponse(8, ActivityConstant.YOGA),
    new CommonResponse(9, ActivityConstant.ACCESSIBLE_OPTIONS)
  ];

  // TermTypes
  public static GET_TERM_TYPES: CommonResponse[] = [
    new CommonResponse(1, TermTypes.PRIMARY_AU),
    new CommonResponse(2, TermTypes.SECONDARY_AU),
    new CommonResponse(3, TermTypes.PRIMARY_NZ),
    new CommonResponse(4, TermTypes.SECONDARY_NZ),
    new CommonResponse(5, TermTypes.GOCARDLESS_NZ) 
  ];

  public static DEPENDANT_AGE: number = 18;

  public static SIGNUP_EMPLOYER_DETAILS = 0;
  public static SIGNUP_PERSONAL_DETAILS = 1;
  public static SIGNUP_SELECT_PACKAGE = 2;
  public static SIGNUP_PAYMENT_DETAILS = 3;


  public static PREMIUM_TERMS = 1;
  public static PREMIUM_KEY_TERMS = 2;
  public static SECONDARY_TERMS = 3;
  public static SECONDARY_KEY_TERMS = 4;
  public static GOCARDLESS_TERMS = 5;
  public static NewZealandStateId = 9;
}
