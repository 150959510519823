import { Component, ElementRef, ViewChild, ChangeDetectorRef, Injector, AfterViewChecked } from '@angular/core';
import { CommonService, FacilityService } from 'src/app/services';
import { MatStepper } from '@angular/material/stepper';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { FacilityFormBase } from '../../base';
import {
    FacilityIdentificationDetailsComponent, FacilityImportantInformationComponent,
    FacilityContactDetailsComponent
} from '../../facility-components';
import { DataResult, Image, SearchPaginationRequest, Facility, SearchPaginationResult } from 'src/app/models';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
    selector: 'app-facilitator-facility-details',
    templateUrl: './facilitator-facility-details.component.html',
    styleUrls: ['./facilitator-facility-details.component.css'],
    providers: [{ provide: FacilityFormBase, useExisting: FacilitatorFacilityDetailsComponent }]
})
export class FacilitatorFacilityDetailsComponent extends FacilityFormBase implements AfterViewChecked {
    heighttabs: string;
    _albums: Array<any> = [];
    photos: Array<Image>;
    selectedFacility: Facility;

    availableFacilities: Array<Facility> = [];
    availableFacilitiesCtrl: UntypedFormControl = new UntypedFormControl();
    isSelectedFacility: boolean = true;
    hasInit: boolean = false;

    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('tabfpstepper') tabfpstepper: ElementRef;
    @ViewChild('importantInformation') importantInformation: FacilityImportantInformationComponent;
    @ViewChild('contactDetails') contactDetails: FacilityContactDetailsComponent;
    @ViewChild('identificationDetails') identificationDetails: FacilityIdentificationDetailsComponent;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            IdentificationDetails: FacilityIdentificationDetailsComponent.getFormGroup(),
            ImportantInformation: FacilityImportantInformationComponent.getFormGroup(),
            ContactDetails: FacilityContactDetailsComponent.getFormGroup()
        });
    }

    constructor(
        injector: Injector,
        private service: FacilityService,
        private commonservice: CommonService,
        private cdRef: ChangeDetectorRef,
        private _lightbox: Lightbox,
        private _lighboxConfig: LightboxConfig) {
        super(injector);
        _lighboxConfig.enableTransition = false;
        _lighboxConfig.fitImageInViewPort = true;
        this.form = FacilitatorFacilityDetailsComponent.getFormGroup();
    }

    OnLoad() {
        let ColumnNames: string;
        let childrens: Array<HTMLElement>;
        //hide some field in identification details
        ColumnNames = 'facility-name; company-name; facility-website-url; facebook-url; facility-phone-number; facility-description';
        childrens =
            Array.from<HTMLElement>(
                (this.identificationDetails.identificationTemplate.nativeElement).children
            );
        childrens.forEach(c => {
            c.children[0].innerHTML = c.children[0].innerHTML.replace('*', '');
            const id = c.id !== '' ? c.id : null;
            if (ColumnNames.indexOf(id) === -1) {
                c.style.display = 'none';
            }
        });
        //hide some field in important information
        ColumnNames = 'access-card-required; access-card-fee; hours-of-operation';
        childrens =
            Array.from<HTMLElement>(
                this.importantInformation.importantTemplate.nativeElement.children
            );

        childrens.forEach(c => {
            c.children[0].innerHTML = c.children[0].innerHTML.replace('*', '');
            const id = c.id !== '' ? c.id : null;
            if (ColumnNames.indexOf(id) === -1) {
                c.style.display = 'none';
            }
        });

        this.availableFacilitiesCtrl.valueChanges.subscribe((facility: Facility) => {
            this.photos = undefined;
            this._albums = undefined;
            this.stepper.selectedIndex = 0;
            if (typeof facility === 'object' && facility) {
                this.selectFacility(facility);
            } else {
                this.isSelectedFacility = true;
            }
        });
        this.loadAvailableFacilities();
    }

    LoadComplete() {
        super.LoadComplete();
    }

    loadAvailableFacilities() {
        const searchRequest: SearchPaginationRequest = {
            OrderBy: 'name',
            OrderByAsc: true,
            PageNumber: 0,
            PageSize: 0,
            Params: [
                {
                    Name: 'user',
                    Value: this.commonservice.GetUserObject().UserId
                }
            ]
        };
        this.Invoke(
            this.service.search(searchRequest),
            {
                onSuccess: (result: any) => {
                    const dectypted_data = this.commonservice.D_FP_ResponseData(result);
                    const rdata = JSON.parse(dectypted_data);
                    if (rdata.Success) {
                        this.availableFacilities = rdata.Data.Results;
                        if (this.availableFacilities.length > 1) {
                            this.isSelectedFacility = true;
                        } else if (this.availableFacilities.length === 1) {
                            this.selectFacility(this.availableFacilities[0]);
                        }
                    } else {
                        this.HandleResponseError(rdata);
                    }
                    super.OnLoad();
                }
            }
        );
    }

    selectFacility(facility: Facility) {
        this.isSelectedFacility = false;
        this.selectedFacility = facility;
        this.Invoke(
            this.service.get(facility.FacilityId),
            {
                onSuccess: (result: DataResult<Facility>) => {
                    this.PatchValue(result.Data, { emitEvent: false });
                    this.commonSvc.FacilityIdForNote = result.Data.FacilityId;
                    this.commonSvc.FacilityNoteComponent.InitForm();
                    setTimeout(() => {
                        this.editContactTemplate();
                        this.form.disable();
                        //disable
                        this.importantInformation.chkStaffedHours.nativeElement.disabled = true;
                        this.importantInformation.accessCardFee.nativeElement.disabled = true;
                    });
                    this.form.markAsPristine();
                }
            }
        );
    }

    editContactTemplate() {
        const ColumnNames = ['contact-type', 'name', 'position', 'phone', 'email'];
        this.contactDetails.contactTemplate.nativeElement.children[1].style.display = 'none';
        const childrens =
            Array.from<HTMLElement>(
                this.contactDetails.contactTemplate.nativeElement.children[0].children
            );

        childrens.forEach(c => {
            Array.from(c.children).forEach(ch => {
                const _class = ch.className !== '' ? ch.className : '';
                const isPhoneLocalName = ch.localName === 'app-phone-number' ? true : false;
                if (ColumnNames instanceof Array) {
                    if (_class.includes(ColumnNames[0]) ||
                        _class.includes(ColumnNames[1]) ||
                        _class.includes(ColumnNames[2]) ||
                        _class.includes(ColumnNames[3]) ||
                        _class.includes(ColumnNames[4])
                    ) {
                        if (isPhoneLocalName) {
                            ch.children[0].children[0].innerHTML = ch.children[0].children[0].innerHTML.replace('*', '');
                        } else {
                            ch.children[0].innerHTML = ch.children[0].innerHTML.replace('*', '');
                        }
                    } else {
                        (<HTMLElement>ch).style.display = 'none';
                    }

                }
            })

        });
    }

    loadPhotos() {
        const facilityId: number = this.selectedFacility.FacilityId;
        if (!this.photos) {
            this.Invoke(
                this.service.getPhotos(facilityId),
                {
                    onSuccess: (edata: string) => {
                        const dectypted_data = this.commonSvc.D_FP_ResponseData(edata);
                        const rdata: DataResult<Image[]> = JSON.parse(dectypted_data);
                        if (rdata.Success) {
                            this.photos = rdata.Data;
                            this._albums = this.photos.map(item => <any>{
                                src: item.Location,
                                thumb: item.Location
                            })
                        } else {
                            this.HandleResponseError(rdata);
                        }
                    }
                }
            );
        }

    }

    onStepChange(event: StepperSelectionEvent) {
        if (event.selectedIndex === 1) {
            this.loadPhotos();
        }
    }

    open(index: number) {
        // open lightbox
        this._lightbox.open(this._albums, index);
    }

    close() {
        // close lightbox programmatically
        this._lightbox.close();
    }

    ngAfterViewChecked() {
        if (!this.commonservice.App.mobileQuery.matches) {

            this.heighttabs = this.tabfpstepper
                .nativeElement
                .querySelector('#facilityDetailstb' + this.stepper.selectedIndex)
                .clientHeight + 'px';
        } else {
            this.heighttabs = '100%';
        }
        this.cdRef.detectChanges();
        if(!this.hasInit) {
            this.hasInit = true;
            this.OnLoad();
        }
    }
}