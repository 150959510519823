<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Member Manual Visit Entry</h4>
<div class="mt-lg-4">
    <div class="form-group row" *ngIf="availableFacilities.length > 0">
        <label for="ddlAvailableFacilities" class="col-md-3 col-lg-2 col-form-label">Available Facilities<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select id="ddlAvailableFacilities" class="form-control" [formControl]="availableFacilitiesCtrl" #txtFacilityID (change)="onFacilityChange($event.target.value)" required>
                    <option [ngValue]="null" selected>All facilities</option>
                    <option *ngFor="let f of availableFacilities" value="{{f.FacilityId}}">{{ f.Name }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group row" *ngIf="isSelectedFacility">
        <label for="ddlAvailableServices" class="col-md-3 col-lg-2 col-form-label">Available Services<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select id="ddlAvailableServices" class="form-control" [formControl]="availableServicesCtrl" #txtServiceID required>
                    <option [ngValue]="null" selected>Select a service</option>
                    <option *ngFor="let s of availableServices" value="{{s.ServiceId}}">{{ s.Name }}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="searchName" class="col-md-3 col-lg-2 col-form-label">Membership Number<button type="button" class="btn btn-light" data-toggle="tooltip" data-placement="top"
            title="This can be Membership Number (eg: FPT.209) or Member ID (eg: 267827)">
            <i class="fas fa-info-circle"></i>
        </button></label>
        <div class="col-md-5 col-lg-3" style="flex-direction: column;">
            <div class="input-group" [ngClass]="getClassFocused(1)">
                <input class="form-control" type="text" placeholder="Search by Membership Number"
                    (blur)="onBlurParent(0)" (focus)="onFocusParent(1)" #txtMembershipNumber [(ngModel)]="MembershipNumber"
                    (keyup)="txtFilterFacilityMembers_KeyUp($event)">
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="Ors" class="col-md-3 col-lg-2 col-form-label"><b>OR</b></label>
    </div>
    <div class="form-group row">
        <label for="searchName" class="col-md-3 col-lg-2 col-form-label">First Name</label>
        <div class="col-md-5 col-lg-3" style="flex-direction: column;">
            <div class="input-group" [ngClass]="getClassFocused(2)">
                <input class="form-control" type="text" placeholder="Search by First Name"
                    (blur)="onBlurParent(0)" (focus)="onFocusParent(2)" #txtFirstName [(ngModel)]="FirstName"
                    (keyup)="txtFilterFacilityMembers_KeyUp($event)">
            </div>

        </div>
    </div>
    <div class="form-group row">
        <label for="searchName" class="col-md-3 col-lg-2 col-form-label">Surname</label>
        <div class="col-md-5 col-lg-3" style="flex-direction: column;">
            <div class="input-group" [ngClass]="getClassFocused(3)">
                <input class="form-control" type="text" placeholder="Search by Surname"
                    (blur)="onBlurParent(0)" (focus)="onFocusParent(3)" #txtSurName [(ngModel)]="SurName"
                    (keyup)="txtFilterFacilityMembers_KeyUp($event)">
            </div>

        </div>
    </div>
    <div class="form-group row">
        <label for="Ors" class="col-md-3 col-lg-2 col-form-label"><b>AND</b></label>
    </div>
    <div class="form-group row">
        <label for="searchName" class="col-md-3 col-lg-2 col-form-label">Date Of Birth</label>
        <div class="col-md-5 col-lg-3" style="flex-direction: column;">
            <div class="input-group" [ngClass]="getClassFocused(4)">
                <input class="form-control" type="text" placeholder="Select Date Of Birth" [minDate]="{year:1920,month:1,day:1}"
                (blur)="onBlurParent(0)" (focus)="onFocusParent(4)"
                [ngClass]="getClassFocused(4)"
                ngbDatepicker #d="ngbDatepicker" #txtDateOFBirth [(ngModel)]="DateOFBirth" [ngModelOptions]="{updateOn: 'blur'}"
                (keyup)="txtFilterFacilityMembers_KeyUp($event)">
                   <button class="btn btn-primary" (click)="d.toggle()" type="button">
                   <i class="fa fa-calendar"></i>
                   </button>
            </div>
        </div>
    </div>

    <div class="ml-2 mt-3">
        <button class="btn btn-primary px-4 text-white btnMenberSearch" id="btnMemberSearch"
        (click)="gotoSearchFacilityMembers()" #txtFacilityMembersFilter [disabled]="!(availableFacilitiesCtrl.valid && availableServicesCtrl.valid)"
        style="width:200px;" >SEARCH</button>
    </div>

 <div class="my-4">
        <div class="border-default rounded-10 border-2">
            <div class="tablecontainer">
                <table mat-table matSort [dataSource]="dataSource" class="rounded-10" matSortActive="last_name"
                    matSortDirection="desc" matSortStart="desc" matSortDisableClear (matSortChange)="sortChange($event)">
                    <ng-container matColumnDef="last_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="first_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="MemberStatus">
                        <th mat-header-cell *matHeaderCellDef> Member Status </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="getStatus(element.MemberStatus.Name)">
                            {{element.MemberStatus.Name}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="MembershipStatus">
                        <th mat-header-cell *matHeaderCellDef> Membership Status </th>
                        <td mat-cell  *matCellDef="let element" [ngClass]="getStatus(element.MembershipStatusName)">
                            {{element.MembershipStatusName}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="MemberNumber">
                        <th mat-header-cell *matHeaderCellDef>Membership No.</th>
                        <td mat-cell *matCellDef="let element"> {{element.MemberNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="MemberId">
                        <th mat-header-cell *matHeaderCellDef>Member ID</th>
                        <td mat-cell *matCellDef="let element"> {{element.MemberId}} </td>
                    </ng-container>

                    <ng-container matColumnDef="DateOfBirth">
                        <td mat-cell *matCellDef="let element" class="dob"> {{element.DateOfBirth}} </td>
                    </ng-container>

                    <ng-container matColumnDef="PhotoLocation">
                        <th mat-header-cell *matHeaderCellDef>Photo</th>
                        <td mat-cell *matCellDef="let element" class="p-0" style="width: 183px;">
                            <div
                                [ngClass]="element.PhotoLocation!='' && element.PhotoLocation !=null? 'my-2 border border-primary' : 'my-2'">
                                <div *ngIf="element.PhotoLocation=='' || element.PhotoLocation==null"
                                    class="bg-secondary fp-profilephoto d-flex justify-content-center align-items-center mx-auto">
                                    <i class="fas fa-user-tie fa-4x text-secondary"></i>
                                </div>
                                <div *ngIf="element.PhotoLocation!='' && element.PhotoLocation !=null"
                                    class="fpPictureCover d-flex align-items-center justify-content-center">
                                    <img [src]="element.PhotoLocation" class="maxWH1" alt="Membership card Photo">
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <button class="btn btn-primary" width="50px" height="10px" (click)="ClickManuallyCheckInButton(element)" id="btnCheckIn">CHECK-IN</button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <mat-toolbar [hidden]="dataSource == null" class="bg-white">
                <mat-toolbar-row [hidden]="dataSource != null && dataSource.data.length == 0" class="p-0">
                    <mat-paginator class="w-100" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                        (page)="paginatorChange($event)" showFirstLastButtons>
                    </mat-paginator>
                </mat-toolbar-row>
                <mat-toolbar-row *ngIf="dataSource != null && dataSource.data.length == 0"
                    class="text-muted d-block p-2 bg-light text-center">
                    {{commonString.TABLE_NO_RECORD_FOUND}}
                </mat-toolbar-row>
            </mat-toolbar>
        </div>
    </div>
</div>


