<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">View/Edit Regions</h4>
<div class="mt-lg-4">
    <div class="form-group row">
        <label for="txtRegionSearchText" class="col-md-3 col-lg-2 col-form-label">Search Region</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="input-group" #regionSearchBox [ngClass] = "getClassFocused(1)">
                    <input id="txtRegionSearchText" name="txtRegionSearchText" class="form-control" type="text"
                        placeholder="Search Region by Region Name, Postcode" matInput [matAutocomplete]="regionAutocomplete"
                        [formControl]="searchTextControl" [defaultValue]='' (blur)="onBlurParent(0)" (focus)="onFocusParent(1)">
                    <div class="input-group-append">
                        <span *ngIf="!isSearchingRegion" class="input-group-text"><i class="fas fa-search text-primary"></i></span>
                        <mat-spinner [diameter]="14" *ngIf="isSearchingRegion"></mat-spinner>
                    </div>
                </div>
                <mat-autocomplete id="autoRegion" name="autoRegion" class="height1 fpscrolly border-2 rounded-10 border-default"
                    #regionAutocomplete="matAutocomplete" [displayWith]="AutoComplete_DisplayWithFn">
                    <mat-option *ngFor="let region of regionSearchResults" [value]="region">
                        <p class="d-flex align-items-center m-0 ml-2 w-100" [routerLink]="[routerConstant.NAVIGATOR_REGION_EDIT, region.RegionId]">
                            <span>{{region.Name}}</span></p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>
    <p class="mb-0 py-2 h6">OR</p>
    <div class="form-group row">
        <label for="slctState" class="col-md-3 col-lg-2 col-form-label">View Regions by State</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" id="slctState" name="slctState" [ngModel]="selectedState" (ngModelChange)="selectedState = $event;">
                    <option [ngValue]="undefined" selected>Please select a value</option>
                    <option *ngFor="let state of states" [ngValue]="state">{{state.Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-8 col-lg-5 offset-sm-2">
            <button class="px-5 py-2 btn btn-primary" type="button" [disabled]="selectedState == null" (click)="ViewByState();">Search</button>
        </div>
    </div>
</div>
