<div [ngClass]="ngInputFile">
    <input type="file" class="custom-file-input" [disabled]="isReadonly" [accept]="filterFile"
        (change)="fileEvent($event)" #fpfileupload style="height: 100% !important">
    <label class="custom-file-label text-light" [ngStyle]='buttonStyle' [ngClass]="ngClassLabel"><i
            class="fas fa-upload mr-2"></i>{{buttonlabel}}</label>
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{status}}...{{percent}}%</h4>
    </div>
    <div class="modal-body" style="margin-bottom: 10px">
        <ngb-progressbar *ngIf="!IsUploadCompleted" type="success" [value]="percent"></ngb-progressbar>
        <div *ngIf="IsUploadCompleted">
            <ngb-alert [dismissible]="false">
                Upload successfully.
            </ngb-alert>
        </div>
    </div>
</ng-template>