<h5 class="font-weight-normal pb-2 text-primary text-center text-lg-left">
  <b> Manually Add Visits </b>
</h5>

<div class="form-align">
  <!--Facility and the text box-->
  <br />
  <div class="form-group row">
    <label class="col-md-3 col-lg-2 col-form-label" class="text-primary"><b>Facility</b></label>
    <div class="col-md-3 col-lg-7">
      &nbsp;
      <input class="form-control" type="text" [placeholder]="facilityName" readonly disabled />
    </div>
  </div>

  <br />

  <!--Service and the text box-->
  <div class="form-group row">
    <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label" class="text-primary"><b>Service</b></label>
    <div class="col-md-3 col-lg-7">
      <input class="form-control" type="text" [placeholder]="serviceName" readonly disabled />
    </div>
  </div>

  <br />

  <!--Payment Period-->

  <div class="form-group row">
    <label for="txtrDescription" class="text-primary"><b>Payment Period:</b></label>
  </div>
  <div style="display: inline-block">
    <label style="display: block" class="text-primary"><b>From</b></label>
    <div name="fromDate" class="input-group">
      <input class="form-control" type="text" [placeholder]="fromDate" readonly disabled />
    </div>
  </div>

  &nbsp;
  <div style="display: inline-block">
    <label style="display: block" class="text-primary"><b>To</b></label>
    <div name="toDate" class="input-group">
      <input class="form-control" type="text" [placeholder]="toDate" readonly disabled />
    </div>
  </div>
  <br />
  <br />
  <br /><br />

  <!--Visits to add to total - Text Box-->

  <div class="form-group row">
    <label class="col-md-3 col-lg-2 col-form-label" class="text-primary"><b>Visits to Add to Total</b></label>
    &nbsp; &nbsp;

    <div class="col-md-5 col-lg-2">
      <div class="d-flex flex-column w-100">
        <input class="form-control" type="number" id="manualVisits" (keyup)="checkVisitCountAndFiles($event)" tabindex="6"/>
      </div>
    </div>
  </div>

  <!--Related Files and attach files button-->

  <div class="form-group row">
    <label class="col-md-3 col-lg-2 col-form-label" class="text-primary"><b>Related Files</b></label>

    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

    <div>
      <label class="px-5 py-2 btn btn-primary btn-sm text-light">
        <input type="file" class="hidden-file-input" (change)="attachFile($event)" tabindex="-1" onclick="this.value=null;"/>
        <i class="fas fa-upload"></i>
        Attach File
      </label>

      <!--Attaching and deleting Multiple Files-->

      <div *ngFor="let file of fileArray">
        <label class="col-md-3 col-lg-2 col-form-label d-table">{{
          file.name
        }}</label>
        <button class="btn btn-light" (click)="removeFile(file)">
          <i class="fas fa-trash-alt" style="color:red"></i>
        </button>
        <br />
      </div>
    </div>
  </div>
  <br />
  <!--Submit and cancel button-->
  <mat-dialog-actions>
    <button mat-button [mat-dialog-close] class="btn btn-ouline-light" tabindex="-1">
      <b>Cancel</b>
    </button>
    &nbsp;&nbsp;
    <button mat-button [disabled]="!submitAllowed" (click)="submitManualVisits()" class="btn btn-primary" tabindex="7">
      <b>Submit</b>
    </button>
  </mat-dialog-actions>
</div>