import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { DataService, PaymentService, MessageBoxButton, CommonService } from "@fp/services";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { RetrieveABAFiles } from "@fp/models/RetrieveABAFiles.model";
import { MessageBox } from "@fp/services";
import {
  DataResult,
  DebitProcessingFilterData,
  GetExcludeRowDetails,
  FileAttachmentModel,
  ResultModel,
  AWSStartExecutionResponse
} from "@fp/models";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DataSourceHelper } from "@fp/helpers";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { FinaliseAba } from "@fp/models/finalise.aba.model";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { FpFileDataR2 } from "@fp/components/control";
import { FpProgressBarComponent } from "@fp/components/control/fp-progress-bar/fp-progress-bar.component";
import { AwsServiceService } from "@fp/services/aws-service.service";
import { StepFunctions } from "@fp/enums/step-functions.enum";
import { DescribeStateMachineComponent } from "@fp/components/describe-state-machine/describe-state-machine.component";
import { FileNameService } from "@fp/services/file-name.service";
import { CommonString } from "@fp/constant";

@Component({
  selector: "app-edit-debit-processing",
  templateUrl: "./edit-debit-processing.component.html",
  styleUrls: ["./edit-debit-processing.component.css"]
})
export class EditDebitProcessingComponent implements OnInit {
  commonString = CommonString;
  uploadedFileName: string[];
  processingDate: any;
  fileName: string;
  Rows: number;
  Amount: number;
  input = new Input();
  getABAData = new RetrieveABAFiles();
  debitProcessingDataEntry = new DebitProcessingFilterData();
  displayedColumns: string[];
  fileProcessingComponent: string;
  dataSource: MatTableDataSource<RetrieveABAFiles> = new MatTableDataSource<
    RetrieveABAFiles
  >();
  excludedRowdDisplayedColumns: string[];
  excludeRowDataSource: MatTableDataSource<GetExcludeRowDetails> = new MatTableDataSource<GetExcludeRowDetails>();
  isLoading = false;
  isAbaFinalised = false;

  totalRows = 0;
  totalAmount = 0;
  abaFileNames: string[] = [];
  retrievingAbaFiles = false;
  abaFiles: RetrieveABAFiles[] = [];
  checkNecessary = true;

  dataSourceExcludeFilter: GetExcludeRowDetails = new GetExcludeRowDetails();
  excludeRowData = new GetExcludeRowDetails();
  excludeList = [];
  excludeForm = new UntypedFormGroup({
    fileNameSelected: new UntypedFormControl(""),
    fromRow: new UntypedFormControl(""),
    toRow: new UntypedFormControl(""),
    internalExclusion: new UntypedFormControl("")
  });

  abaFileName: string;
  internalExclusion: boolean;
  StepFunctions = StepFunctions;

  dataDocumentAttachments: FileAttachmentModel[] = [];
  documentName = "No File Selected";
  colParameter = [];
  finalizeButtonText = '';
  finalizeButtonDescription = '';
  fileSelectDropdownText = '';
  generatedFileType = '';
  helpText: string = '';

  auColParameter = [
    "account description",
    "account number",
    "currency",
    "date",
    "description of transaction",
    "debits",
    "credits",
    "balance"
  ];

  nzColParameter = [
    "id",
    "created_at",
    "charge_date",
    "amount",
    "description",
    "currency",
    "status",
    "amount_refunded",
    "reference",
    "transaction_fee",
    "payout_date",
    "app_fee",
    "funds_settlement",
    "links.mandate",
    "links.creditor",
    "links.customer",
    "links.payout",
    "links.subscription",
    "customers.id",
    "customers.created_at",
    "customers.email",
    "customers.given_name",
    "customers.family_name",
    "customers.company_name",
    "customers.address_line1",
    "customers.address_line2",
    "customers.address_line3",
    "customers.city",
    "customers.region",
    "customers.postal_code",
    "customers.country_code",
    "customers.language",
    "customers.swedish_identity_number",
    "customers.active_mandates",
    "transaction_fee_tax",
    "metadata.bcf",
    "metadata.member_id",
    "metadata.membership_id",
    "customers.metadata.member_id",
    "customers.metadata.membership_id"
  ];

  totalFailedAmount: string;
  totalFailiures: string;

  @ViewChild('excludeSort') excludeSort: MatSort;
  @ViewChild('abaSort') abaSort: MatSort;

  @ViewChild("progressBar") progressBar: FpProgressBarComponent;
  @ViewChild("uploadDesc") uploadDescSpinner: DescribeStateMachineComponent;
  @ViewChild("confirmDesc") confirmDescSpinner: DescribeStateMachineComponent;
  @ViewChild("regenDesc") regenDescSpiner: DescribeStateMachineComponent;
  @ViewChild("finaliseDesc") finaliseDescSpinner: DescribeStateMachineComponent;
  uuidName: string;
  valueToRow: string;
  valueFromRow: string;
  valueInternalExclusion: string;
  valueFileDropDown: string = "";
  allFileData: RetrieveABAFiles[];
  seletedCountry : string;
  filesRowLimit: any;
  counter: number = 0;
  @ViewChild('filepagi') file_paginator: MatPaginator;
  @ViewChild('rawpagi') raw_paginator: MatPaginator;

  constructor(
    private dataService: DataService,
    private dialog: MatDialog,
    private paymentSvc: PaymentService,
    protected filesvc: FileNameService,
    private awsService: AwsServiceService,
    private cmsrv: CommonService
  ) { }

  ngOnInit() {
    this.colParameter = this.nzColParameter;
    this.dataService.currentMessage.subscribe(messageService => {
      if (messageService !== "default message") {
        let splitMessage = messageService.split("_");
        this.processingDate = splitMessage[0];
        // Is this ABA Finalised? Get it from the Message
        this.isAbaFinalised =
          splitMessage[1].toLowerCase() == "true" ? true : false;

        this.seletedCountry = splitMessage[2].toLowerCase();

        if(this.seletedCountry.toLocaleLowerCase() == this.commonString.AUSTRALIA.toLocaleLowerCase()){
          this.colParameter = this.auColParameter;
          this.finalizeButtonText = 'Finalise ABAs';
          this.finalizeButtonDescription = 'ABAs will be finalised and all credits will be posted to the Membership Ledger. This operation is irreversible.';
          this.fileSelectDropdownText = 'Select ABA file';
          this.generatedFileType = 'ABA';
          this.helpText = 'ABA';
        }
        else{
          this.colParameter = this.nzColParameter;
          this.fileProcessingComponent = "NZFailedFileUploadVerification";
          this.finalizeButtonText = 'Finalize GoCardLess DD';
          this.finalizeButtonDescription = 'GCardLess DDs will be finalised and all credits will be posted to the Membership Ledger. This operation is irreversible.';
          this.fileSelectDropdownText = 'Select CSV file';
          this.generatedFileType = 'CSV';
          this.helpText = 'GoCardless DD';
        }

          console.log("this is a test to see if deploy works")
        if (this.isAbaFinalised) {
          this.disableAbaRowExclusion();
        }
      }
    });
    this.getABAFilesData();
  }

  /**
   * Disables the ABA Row Exclusion elements.
   */
  private disableAbaRowExclusion() {
    this.excludeForm.controls.fileNameSelected.disable();
    this.excludeForm.controls.internalExclusion.disable();
    this.excludeForm.controls.fromRow.disable();
    this.excludeForm.controls.toRow.disable();
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError(e => {
          throw e;
        })
      )
      .subscribe(res => {
        handleResultCallback(res);
      });
  }

  convertDateFormat(date) {
    let dateFormat = new Date(date);
    let month = dateFormat.getMonth() + 1;
    let day = dateFormat.getDate();
    let year = dateFormat.getFullYear();
    return year + "-" + month + "-" + day;
  }

  /**
   * Fills the ABA Files Table with the ABAs related to this Payment Period Date
   */
  getABAFilesData() {
    this.retrievingAbaFiles = true;

    this.displayedColumns = ["FileName", "Rows", "Amount", "Download"];

    this.processingDate = this.convertDateFormat(this.processingDate);

    this.Invoke(
      this.paymentSvc.getABAFiles(this.processingDate, this.seletedCountry),
      (result: DataResult<RetrieveABAFiles[]>) => {
        this.retrievingAbaFiles = false;
        this.allFileData = result.Data;
        this.dataSource = new MatTableDataSource<RetrieveABAFiles>(result.Data);
        this.dataSource.sortingDataAccessor =
          DataSourceHelper.localeLowerCaseSortingDataAccessor;
        this.dataSource.sort = this.abaSort;
        this.dataSource.paginator = this.file_paginator;
        for (let i = 0; i < result.Data.length; i++) {
          this.totalRows = this.totalRows + result.Data[i].Rows;
          this.totalAmount = this.totalAmount + result.Data[i].Amount;
          this.abaFileNames.push(result.Data[i].FileName);
          this.abaFiles.push(result.Data[i]);
        }
      }
    );

    document.getElementById("abaFilesTable").style.display = "block";
  }

  /**
   * Clears the contents of the ABA File Table
   */
  private clearAbaTable() {
    this.allFileData = [];
    this.dataSource = new MatTableDataSource<RetrieveABAFiles>([]);
    this.totalRows = 0;
    this.totalAmount = 0;
    this.abaFileNames = [];
    this.abaFiles = [];
  }

  getTotalRows() {
    return this.totalRows;
  }

  getTotalAmount() {
    return this.totalAmount;
  }

  downloadABAFile(fileName: string) {
    this.retrieveFilesFromS3(fileName);
  }

  downloadABAFiles() {
    this.abaFileNames.forEach(abaFileName => {
      this.retrieveFilesFromS3(abaFileName);
    });
  }

  retrieveFilesFromS3(fileName: string) {
    this.Invoke(
      this.paymentSvc.downloadABAFiles(fileName),
      (result: DataResult<string>) => {
        window.open(result.Data);
      }
    );
  }

  fileSelected() {
    var fileNameWithExtension = this.excludeForm.value.fileNameSelected;
    var files = this.allFileData;
    for (const row in files) {
      var matchedFilename = files[row]["FileName"];
      if (matchedFilename === fileNameWithExtension) {
        this.filesRowLimit = Number(files[row]["Rows"]);
      }
    }
  }

  validateExcludeFilter() {
    this.progressBar.Open("Validating ABA Exclusion");
    this.excludedRowdDisplayedColumns = [
      "FileName",
      "Row",
      "Amount",
      "Employer",
      "MemberNumber",
      "InternalExclusion"
    ];
    var fileNameWithExtension = this.excludeForm.value.fileNameSelected;
    var fileName = fileNameWithExtension
      .split(".")
      .slice(0, -1)
      .join(".");
    this.excludeRowData.FileName = fileName;
    this.excludeRowData.FromRow = Number(this.excludeForm.value.fromRow);
    this.excludeRowData.ToRow = Number(this.excludeForm.value.toRow);
    this.excludeRowData.Country = this.seletedCountry;
    if (!(this.excludeForm.value.internalExclusion)) { this.internalExclusion = false } else { this.internalExclusion = true };
    //Check if the FROM_ROW is within 2 and the total rows of the selected file
    if (
      this.excludeRowData.FromRow < 2 ||
      this.excludeRowData.FromRow > this.filesRowLimit + 1
    ) {
      this.progressBar.close_subject.next(true);
      MessageBox.ShowInfo(
        this.dialog,
        `Rows entered are incorrect. Rows have to be in the range of 2 and ${this
          .filesRowLimit + 1} for this file.`
      );
      //Resets all values of the input fields
      this.valueToRow = "";
      this.valueFromRow = "";
      this.valueInternalExclusion = "";
      this.valueFileDropDown = "";
    }
    //Check if the TO_ROW is within FROM_ROW and the total rows of the selected file
    else if (
      this.excludeRowData.ToRow < this.excludeRowData.FromRow ||
      this.excludeRowData.ToRow > this.filesRowLimit + 1
    ) {
      this.progressBar.close_subject.next(true);
      MessageBox.ShowInfo(
        this.dialog,
        `Rows entered are incorrect. Rows have to be in the range of 2 and ${this
          .filesRowLimit + 1} for this file.`
      );
      //Resets all values of the input fields
      this.valueToRow = "";
      this.valueFromRow = "";
      this.valueInternalExclusion = "";
      this.valueFileDropDown = "";
    } else {
      this.progressBar.upload_status_subject.next(
        "Getting Excluded Row Details"
      );
      this.progressBar.StartPercentTimer(15);
      this.excludeRowData.PaymentProcessingDate = this.processingDate;
      this.Invoke(
        this.paymentSvc.getExcludeRowDetails(this.excludeRowData),
        (result: DataResult<GetExcludeRowDetails[]>) => {
          if(result.Data.length > 0) {
            this.excludeList.push({
              FileId: result.Data[0].FileId,
              FromRow: this.excludeRowData.FromRow,
              ToRow: this.excludeRowData.ToRow,
              InternalExclusion: this.internalExclusion});
          }
          for (const row of result.Data) {
            this.counter = 0;
            //If a particular row exists, don't add it to the list to be displayed in the exclusion table
            if (this.excludeRowDataSource.data.length >= 1) {
              for (const i of this.excludeRowDataSource.data) {
                if (
                  i["FileName"] === row["FileName"] &&
                  i["Row"] === row["Row"]
                ) {
                  this.counter += 1;
                }
              }
              if (this.counter == 0) {
                row.InternalExclusion = this.internalExclusion;
                this.excludeRowDataSource.data = this.excludeRowDataSource.data.concat(
                  row
                );
              }
            } else {
              row.InternalExclusion = this.internalExclusion;
              this.excludeRowDataSource.data = this.excludeRowDataSource.data.concat(
                row
              );
            }
          }
          this.excludeRowDataSource.sortingDataAccessor = DataSourceHelper.localeLowerCaseSortingDataAccessor;
          this.excludeRowDataSource.sort = this.excludeSort;
          this.excludeRowDataSource.paginator = this.raw_paginator;
          document.getElementById("excludeFilterTable").style.display = "block";
          document.getElementById("regenrateAbaBtn").style.display = "block";
          document.getElementById("finaliseAbaBtn").style.display = "none";

          this.progressBar.close_subject.next(true);

          //Resets all values of the input fields
          this.valueToRow = "";
          this.valueFromRow = "";
          this.valueInternalExclusion = "";
          this.valueFileDropDown = "";
        }
      );
    }
  }

  regenerateAbaFiles() {
    this.progressBar.Open(`Regenerating ${this.helpText} File`);
    this.progressBar.StartPercentTimer(3);

    var regenerateAbaData = { body: { lists: this.excludeList, modifiedBy:  this.cmsrv.GetUser() } };
    this.Invoke(
      this.awsService.StartStepExecution(StepFunctions.RegenerateAbas,
        null, null, null,
        regenerateAbaData),
      (result: AWSStartExecutionResponse) => {
        this.progressBar.close_subject.next(true);
        if (result.executionArn === undefined || result.executionArn === "" || result.executionArn === null) {
          MessageBox.ShowError(
            this.dialog,
            "Something has gone wrong."
          );
        } else {
          MessageBox.ShowInfo(this.dialog,
            `Regeneration has begun. On completion, the new ${this.generatedFileType}s will be emailed to you, and this page will download a copy of the ${this.helpText}. ` +
            "You will still get the email if you navigate away from this page.");

          this.regenDescSpiner.stepMachineIsRunning = true;
          this.regenDescSpiner.executionArn = result.executionArn;
          this.regenDescSpiner.StartDescriptionTimer(5);
          this.excludeList = [];
        }
      }
    );
  }

  handleRegenResponse(result) {
    if (result.Success === true) {
      for (let i = 0; i < result.Errors.length; i++) {
        let abaFileLink = result.Errors[i];
        window.open(abaFileLink, "_blank");
      }
      this.clearAbaTable();
      this.getABAFilesData();
      MessageBox.ShowInfo(this.dialog, result["Message"]);
      document.getElementById("finaliseAbaBtn").style.display = "block";
      document.getElementById("excludeFilterTable").style.display = "none";
      document.getElementById("regenrateAbaBtn").style.display = "none";

      this.excludeRowDataSource.data = [];
    } else {
      // Failure Case: Show error box
      MessageBox.ShowErrorCustomerHeader(
        this.dialog,
        `One of the functions linked to ${this.generatedFileType} Generation failed. The ABAs have not been modified. Please try regenerating these files.`,
        "Regeneration Failure"
      );
    }

  }

  finaliseAbaFiles() {
    let finaliseAbaData: FinaliseAba = new FinaliseAba();
    finaliseAbaData.UserName =  this.cmsrv.GetUser();
    finaliseAbaData.ProcessingDate = this.processingDate;
    finaliseAbaData.Country = this.seletedCountry;
    MessageBox.ShowCustom(
      this.dialog,
      "Confirmation",
      "Confirmation",
      `You are about to finalise all ${this.helpText}s. This operation is irreversible and once completed` +
      " all credits will be posted to the membership ledger. Do you wish to continue?",
      MessageBoxButton.YesNo
    ).subscribe((dialogResult: DialogResult) => {
      if (dialogResult.result === DialogResult.Yes) {
        this.isAbaFinalised = true;
        this.progressBar.Open(`Finalising ${this.helpText}s`);
        this.progressBar.StartPercentTimer(15);
        try{
        this.Invoke(
          this.awsService.StartStepExecution(StepFunctions.FinaliseAbas, null, null, null, { "body": finaliseAbaData}),
          (data: AWSStartExecutionResponse) => {
            this.progressBar.close_subject.next(true);
            this.finaliseDescSpinner.stepMachineIsRunning = true;
            this.finaliseDescSpinner.StartDescriptionTimer(10);
            this.finaliseDescSpinner.executionArn = data.executionArn;
          }
        );
        }
        catch(error){
          this.isAbaFinalised = false;
        }
      }
      else if(dialogResult.result === DialogResult.No){
        this.isAbaFinalised = false;
      }
    });
  }

  handleFinaliseResult(result: ResultModel) {
    // Only lock Finalise buttons if the finalisation succeeded.
    if (result.Success === true) {
      this.isAbaFinalised = true;
      this.disableAbaRowExclusion();
    }
    MessageBox.ShowCustom(this.dialog, result.Message);
  }

  handleFinaliseResultForFailure(result: ResultModel) {
    // to handle the failure scenarios
    console.log("this is called after the failure");
    if (result.Success === false) {
      this.isAbaFinalised = false;
    }
  }

  public addFileUploadCompleted(event) {
    const ret: FpFileDataR2 = event;
    this.dataDocumentAttachments.push({
      Location: ret.filedata.Location,
      Base64: "",
      FileName: ret.originfilename,
      Status: "Addnew",
      Id: 0
    });
    this.documentName = ret.originfilename;
    this.progressBar.Open("Uploading File: " + ret.originfilename + "  ");
    this.progressBar.StartPercentTimer(15);
    let nameInS3 = this.dataDocumentAttachments[0]["Location"].split("/");
    this.uuidName = nameInS3[nameInS3.length - 1];
    this.progressBar.close_subject.next(true);

    this.filesvc.$currentUploadedFileUuid.subscribe(
      uploadedFileName => (this.uploadedFileName = uploadedFileName)
    );

    //Retrieve the recently selected CSV to check file integrity
    const fileNameString = this.uploadedFileName[this.uploadedFileName.length - 1];


    //Prelim Upload
    this.Invoke(
      this.awsService.StartStepExecution(
        StepFunctions.UploadFailedTransactions,
        this.seletedCountry, null,
        fileNameString
      ),
      (result: AWSStartExecutionResponse) => {
        this.progressBar.close_subject.next(true);
        if (result.executionArn === undefined || result.executionArn === "" || result.executionArn === null) {
          MessageBox.ShowError(
            this.dialog,
            "Something has gone wrong."
          );
        } else {
          this.uploadDescSpinner.stepMachineIsRunning = true;
          this.uploadDescSpinner.executionArn = result.executionArn;
          this.uploadDescSpinner.StartDescriptionTimer(5);
        }
      }
    );
  }

  handleUploadReturn(data) {
    MessageBox.ShowInfo(this.dialog, data.Message);
    this.totalFailedAmount = data["Data"][0];
    this.totalFailiures = data["Data"][1];
    document.getElementById("resultUploadFailedTransactions").style.display = "block";
  }

  //Confirm Upload and Membership Ledger Update
  confirmFailedTransactionUpload() {
    this.progressBar.Open("Uploading Failed Transactions");
    this.progressBar.StartPercentTimer(10);
    let modifiedBy = this.cmsrv.GetUser();

    this.filesvc.$currentUploadedFileUuid.subscribe(
      uploadedFileName => (this.uploadedFileName = uploadedFileName)
    );

    //Retrieve the recently selected CSV to check file integrity
    const fileNameString = this.uploadedFileName[this.uploadedFileName.length - 1];

    this.Invoke(
      this.awsService.StartStepExecution(
        StepFunctions.ConfirmUploadFailedTransactions,
        this.seletedCountry,
        modifiedBy,
        fileNameString
      ),
      (result: AWSStartExecutionResponse) => {
        this.progressBar.close_subject.next(true);
        if (result.executionArn === undefined || result.executionArn === "" || result.executionArn === null) {
          MessageBox.ShowError(
            this.dialog,
            "Something has gone wrong."
          );
        } else {
          this.confirmDescSpinner.stepMachineIsRunning = true;
          this.confirmDescSpinner.executionArn = result.executionArn;
          this.confirmDescSpinner.StartDescriptionTimer(5);
        }
      }
    );
  }

  handleConfirmReturn(data) {
    MessageBox.ShowInfo(this.dialog, data.Message);
    this.totalFailedAmount = undefined;
    this.totalFailiures = undefined;
    document.getElementById(
      "resultUploadFailedTransactions"
    ).style.display = "none";
  }
}
