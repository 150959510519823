<h3 class="font-weight-normal pb-2 text-primary text-center text-lg-left">
  View/Edit System Parameters
</h3>

<div class="container-fluid">
  <!-- Main Mat Spinner -->
  <div class="loading-shade-global" *ngIf="isLoading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div class="row">
    <div class="my-4 col-xs-auto">
      <div class="border-default rounded-10 border-2">
        <div class="tablecontainer">
          <table class="table-striped" frame="void" rules="rows">
            <thead>
              <tr class="text-primary">
                <th>System Parameter</th>
                <th>Current Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr class="align-middle" *ngFor="let item of systemParameters; let i = index">
                <td class="pr-5">
                  <b>{{ item.Field }}</b>
                </td>
                <td *ngIf="item.Edit">
                  <select class="form-control" [id]="item.Id" [(ngModel)]="item.Input" (change)="editParameter(item)"
                    *ngIf="item.Options.length > 0">
                    <option *ngFor="let option of item.Options" [ngValue]="option.name">{{ option.name }}</option>
                  </select>
                  <input #thisInput class="form-control" type="text" [id]="item.Id" *ngIf="item.Options.length === 0"
                    [placeholder]="item.Input" [(ngModel)]="item.Input" (blur)="onFocusLost(thisInput.value, item, i)"
                    (keyup.enter)="onFocusLost(thisInput.value, item, i)" />
                  <div [id]="i" hidden>
                    <span class="text-danger" *ngIf="item.Type === paramType.Number">This Value must be a Number</span>
                    <span class="text-danger" *ngIf="item.Type === paramType.Currency">This Value must be a
                      Number</span>
                    <span class="text-danger" *ngIf="item.Type === paramType.Percentage">This Value must be a Number
                      between 0 and 100</span>
                    <span class="text-danger" *ngIf="item.Type === paramType.PercentageExtended">This Value must be a
                      Number between 0 and 1000</span>
                    <span class="text-danger" *ngIf="item.Type === paramType.Date">This value must be a date</span>
                  </div>
                </td>
                <td class="pr-5" *ngIf="!item.Edit && item.Type === paramType.Currency">
                  ${{ item.Input }}
                </td>
                <td class="pr-5" *ngIf="
                    !item.Edit &&
                    (item.Type === paramType.Percentage ||
                      item.Type === paramType.PercentageExtended)
                  ">
                  {{ item.Input }}%
                </td>
                <td class="pr-5" *ngIf="
                    !item.Edit &&
                    (item.Type === paramType.TrueFalse ||
                      item.Type === paramType.Number)
                  ">
                  {{ item.Input }}
                </td>
                <td class="pr-5" *ngIf="!item.Edit && item.Type === paramType.Date">
                  {{ item.Input }}
                </td>
                <td class="pr-5" *ngIf="!item.Edit && item.Type === paramType.String">
                  {{ item.Input }}
                </td>
                <td *ngIf="!item.Edit">
                  <button class="btn btn-primary btn-sm btn-edit" (click)="editParameter(item)" [disabled]="isEditing">
                    <i class="fas fa-pen"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <button id="btn-save" class="px-5 py-2 btn btn-primary text-light" (click)="saveChanges()" [disabled]="isEditing">
    SAVE
  </button>
</div>