import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { merge, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { RouterConstant } from "src/app/constant/routerconstant";
import { CommonService } from "../../services/common.service";
import { MatDialog } from "@angular/material/dialog";
import { MessageBox } from "src/app/services/common-dialog.service";
import {
  Image,
  Facility,
  FacilityMap,
  MembershipPackage,
  DataResult,
} from "src/app/models";
import { HttpDAO } from "../../services/httpdao.service";
import { APIConstant } from "../../constant/apiconstant";
import { Utils } from "src/app/helpers";
import { EFacilityStatus } from "../../enums";
import { EmployerPortalPage } from "@fp/models/employer-portal.model";
import { GoogleMapPageViewComponent } from "@fp/google-map-page-view/google-map-page-view.component";

@Component({
  selector: "app-facility-packages-comonent",
  templateUrl: "./facility-packages-comonent.component.html",
  styleUrls: ["./facility-packages-comonent.component.css"],
})
export class FacilityPackagesComonentComponent implements OnInit {
  panelOpenState = false;
  openPackage = 0;

  public Utils = Utils;
  public EmpCode = "";
  public EmpPageObj: EmployerPortalPage = new EmployerPortalPage();
  private httpdao: HttpDAO | null;
  public Pagetext1: SafeHtml;
  public Pagetext2: SafeHtml;
  public Pagetext3: SafeHtml;
  private fids: string[] = [];
  private fs: Facility[] = [];
  public selectedPackage: MembershipPackage = null;
  isAustralia = true;

  public addFacilitytoMapList: string = "";
  public accessCardYesNo: string;
  public servicesHtmlString: string;
  public operatingHoursHtmlString: string;
  public accessCardFeeHtmlString: string;
  public mbsplst: MembershipPackage[] = [];
  public facilityMapData: Array<FacilityMap> = [];
  public filteredFacilityMapData: Array<FacilityMap> = [];
  private _mapListFilter = "";
  public allFacilitiesVisibleMap = true;
  private previousInfoWindow = null;
  @ViewChild("fmap") fmap: ElementRef;
  @Input() googlemap: GoogleMapPageViewComponent;

  constructor(
    private route: ActivatedRoute,
    private commonservice: CommonService,
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private sanitized: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.EmpCode = this.route.snapshot.url.toString();
    this.httpdao = new HttpDAO(this.http);
    this.InitEmployerPageObject();
    this.GetEmployerPage();
    if (this.commonservice.App) {
      this.commonservice.App.opened = false;
    }
    if (this.commonservice.Navbar) {
      this.commonservice.Navbar.isLoginDisplay = false;
    }
    if (this.commonservice.Navbarmd) {
      this.commonservice.Navbarmd.isLoginDisplay = false;
    }
  }
  togglePackage(index) {
    this.openPackage = index;
  }

  private GetEmployerPage() {
    console.log("Inside GetEmployerPage()");
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.commonservice.StartGlobalProgressBar();
          return this.httpdao!.getSingleDataWithoutToken(
            APIConstant.API_GET_EMPLOYER_PAGE_BY_URL + this.EmpCode
          );
        }),
        map((result) => {
          if (result.Success) {
            if (result.Data == null) {
              this.EmpPageObj.EmployerId = null;
              this.EmpPageObj.AttachLogo = new Image();
              this.EmpPageObj.AttachLogo.Data = null;
              this.EmpPageObj.AttachLogo.FileName = "";
              this.EmpPageObj.AttachLogo.Location = "";
              this.EmpPageObj.EnableMemberSignUp = false;
              this.EmpPageObj.ShowAvailableFacilities = false;
              this.EmpPageObj.ShowPricingPackages = false;
              this.EmpPageObj.LinkName = "";
              this.EmpPageObj.PageHeading = "";
              this.EmpPageObj.PageText1 = "";
              this.EmpPageObj.PageText2 = "";
              this.EmpPageObj.PageText3 = "";
            } else {
              this.EmpPageObj = result.Data;
              if (this.EmpPageObj.Country === "Australia") {
                this.isAustralia = true;
              } else {
                this.isAustralia = false;
              }
              if (this.EmpPageObj.AttachLogo == null) {
                this.EmpPageObj.AttachLogo = new Image();
                this.EmpPageObj.AttachLogo.Data = null;
                this.EmpPageObj.AttachLogo.FileName = "";
                this.EmpPageObj.AttachLogo.Location = "";
              }
              this.Pagetext1 = this.sanitized.bypassSecurityTrustHtml(
                this.EmpPageObj.PageText1
              );
              this.Pagetext2 = this.sanitized.bypassSecurityTrustHtml(
                this.EmpPageObj.PageText2
              );
              this.Pagetext3 = this.sanitized.bypassSecurityTrustHtml(
                this.EmpPageObj.PageText3
              );
            }

            if (
              this.EmpPageObj == null ||
              this.EmpPageObj.EmployerId == null ||
              this.EmpPageObj.IsActive == false
            ) {
              this.router.navigate([RouterConstant.ROUTER_PAGE_NOT_FOUND]);
            }
            this.GetMembershipPkg();
            return result;
          } else {
            MessageBox.ShowError(this.dialog, "Something was wrong");
            console.log("Before stopping global progress bar");
            this.commonservice.StopGlobalProgressBar();
          }
          return result;
        }),
        catchError(() => {
          console.log("Before stopping global progress bar");
          this.commonservice.StopGlobalProgressBar();
          return observableOf([]);
        })
      )
      .subscribe((data) => { });
  }

  private InitEmployerPageObject() {
    this.EmpPageObj.EmployerId = null;
    this.EmpPageObj.AttachLogo = new Image();
    this.EmpPageObj.AttachLogo.Data = null;
    this.EmpPageObj.AttachLogo.FileName = "";
    this.EmpPageObj.AttachLogo.Location = "";
    this.EmpPageObj.EnableMemberSignUp = false;
    this.EmpPageObj.ShowAvailableFacilities = false;
    this.EmpPageObj.ShowPricingPackages = false;
    this.EmpPageObj.LinkName = "";
    this.EmpPageObj.PageHeading = "";
    this.EmpPageObj.PageText1 = "";
    this.EmpPageObj.PageText2 = "";
    this.EmpPageObj.PageText3 = "";
  }

  public SignUp_Click() {
    if (this.EmpPageObj.EmployerId != null) {
      this.commonservice.EmployerIDForMemberSignUp =
        this.EmpPageObj.EmployerId.toString();
    } else {
      this.commonservice.EmployerIDForMemberSignUp = "";
    }
    this.router.navigate([RouterConstant.ROUTER_SIGNUP]);
  }

  private GetFacilityBySelectedPkg() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          const params = {
            Params: [
              {
                Name: "id",
                Value: this.selectedPackage.MembershipPackageId,
              },
              {
                Name: "status",
                Value: EFacilityStatus.Active,
              },
            ],
          };
          return this.httpdao!.postDataWithoutToken(
            APIConstant.API_GET_FACILITIES_MEM_PACKAGE_ID,
            params
          );
        }),
        map((result: DataResult<any>) => {
          if (result.Success) {
            this.fmap.nativeElement.focus();
            return result;
          }
        }),
        catchError(() => {
          this.commonservice.StopGlobalProgressBar();
          return observableOf([]);
        })
      )
      .subscribe((data) => { });
  }

  private GetMembershipPkg() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.httpdao!.getSingleDataWithoutToken(
            APIConstant.API_GET_MEMBERSHIP_PKG_BY_EMPID +
            this.EmpPageObj.EmployerId
          );
        }),
        map((result) => {
          if (result.Success) {
            this.mbsplst = result.Data;
            console.log(this.mbsplst);
            if (this.mbsplst.length > 0) {
              this.selectedPackage = this.mbsplst[0];
              this.mbsplst.forEach((element) => {
                if (element.IsDefault) {
                  this.selectedPackage = element;
                }
              });
              this.GetFacilityBySelectedPkg();
              this.googlemap.startMapLoadSpinner();
              this.googlemap.LoadFacilitiesByMembershipPackageId(
                this.selectedPackage.MembershipPackageId
              );
            } else {
              this.commonservice.StopGlobalProgressBar();
            }
            return result;
          }
        }),
        catchError(() => {
          this.commonservice.StopGlobalProgressBar();
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.commonservice.StopGlobalProgressBar();
      });
  }

  public selectMembershipPackage(membershipPackage: MembershipPackage) {
    this.googlemap.startMapLoadSpinner();
    this.previousInfoWindow = null;
    this.selectedPackage = membershipPackage;
    this.facilityMapData = [];
    this.fids = [];
    this.googlemap.LoadFacilitiesByMembershipPackageId(
      this.selectedPackage.MembershipPackageId
    );
  }

  ToggleSelectedPackage() {
    if (this.selectedPackage) {
      this.mbsplst.forEach((item) => {
        if (
          item.MembershipPackageId == this.selectedPackage.MembershipPackageId
        ) {
          item.IsSelected = true;
        } else {
          item.IsSelected = false;
        }
      });
    }
  }

  formatTime(time) {
    const hours = time.split(":")[0];
    const mins = time.split(":")[1];
    return hours + ":" + mins;
  }
}
