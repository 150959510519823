<div>
    <div class="question">
        <div class="question-number">{{facilitySelectionQuestion.QuestionNo}}</div>
        <div class="question-content">
            <div class="question-content-title">{{facilitySelectionQuestion.QuestionText}} <span class="required" *ngIf="_required">*</span></div>
            <div class="question-content-helptext text-muted">{{facilitySelectionQuestion.SubQuestionText}}</div>
        </div>
    </div>
    <div>
        <small  class="invalid-feedback d-block facility-selection-error" *ngIf="showFacilityEelectionErrorMessage">
            Please select at least 2 choices here</small>
    </div>
    <div class="facility-selection">
        <div class="facility-selection-label">
            <b>Your Selection:</b>
            <div class="text-muted">Pick up to {{maxValues}}</div>
        </div>
        <div class="facility-selection-items">
            <ng-container *ngFor="let e of [].constructor(maxValues); let i = index">
                <div class="facility-selection-item" [ngClass]="{'picked':!!NominatedFacilities[i]}">
                    <div class="facility-selection-item-content" *ngIf="NominatedFacilities[i]">
                        <div class="selection-name">{{NominatedFacilities[i].FacilityName}}</div>
                        <div class="selection-location"><i class="fa fa-map-marker-alt"></i> {{NominatedFacilities[i].Location}}</div>
                        <button class="btn btn-primary btn-clear-selection" type="button" (click)="clearSelection(i,NominatedFacilities[i].Id)"><i class="fa fa-times"></i></button>
                    </div>
                    <div class="text-muted" *ngIf="!(NominatedFacilities[i])">Selection {{i+1}}</div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="map-container">
        <div class="row mb-2">
            <div class="col-sm-5">
                <app-suburb-select [presetSuburbs]="presetSuburbs" id="mapSuburb" [formControl]="mapSuburb" [suburbs]="suburbList" [showRequiredMessage] = "false"></app-suburb-select>
            </div>
        </div>
        <div class="row g-1">
            <div class="col-sm-5">
                <agm-map id="map" #map [minZoom]="2" class="w-100 map" [(zoom)]="mapHomeLocation.zoom"
                    [(latitude)]="mapHomeLocation.lat" [(longitude)]="mapHomeLocation.lng" [usePanning]='true'
                    (boundsChange)="updateMarkers($event)">
                    
                    <agm-marker-cluster
                        imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
                        <div *ngFor="let marker of allMarkers">
                            <agm-marker [latitude]="marker.lat" [longitude]="marker.lng"
                                (markerClick)="showFacilityInfoWindow(infoWindow, marker)" [title]="marker.data.Name"
                                [iconUrl]="{url: iconUrl, scaledSize: {width: 26.38, height: 60}}">
                                <agm-info-window #infoWindow>
                                    <div class="agm-info-window">
                                        <div *ngIf="selectedMarker && selectedMarker.data && selectedMarker.data.FacilityId == marker.data.FacilityId">
                                            <ng-container *ngTemplateOutlet="tmplFacilityDetails; context:{f: marker}">
                                            </ng-container>
                                        </div>
                                    </div>
                                </agm-info-window>
                </agm-marker>
                        </div>
                    </agm-marker-cluster>
                </agm-map>
            </div>
            <div class="col-sm-7">
                <div class="list-view-wrapper">
                    <div class="filter-by-name-wrapper">
                        <i class="icon fa fa-search"></i>
                        <input [formControl]="filterByName" placeholder="Filter By Facility Name" type="text" class="form-control" id="facilityNameFilter">
                    </div>
                    <div class="list-view">
                        <div *ngFor="let marker of inviewFilteredMarkers">
                            <div class="list-view-item" [ngClass]="{'added': isFacilitySelected(marker.data.FacilityId)}">
                                <div class="list-view-item-content">
                                    <div class="list-view-name">{{marker.data.Name}}</div>
                                    <div class="list-view-location"><i class="fa fa-map-marker-alt"></i> {{marker.data.Suburb.Name}}, {{marker.data.State.Name}}</div>
                                </div>
                                <div class="list-view-item-actions">
                                    <button class="btn btn-sm btn-primary" type="button" [disabled]="maxLimitReached" (click)="addToSelection(marker)">
                                        <i class="fa" [ngClass]="{'fa-check': isFacilitySelected(marker.data.FacilityId), 'fa-plus': !isFacilitySelected(marker.data.FacilityId)}"></i> 
                                        {{ isFacilitySelected(marker.data.FacilityId) ? "Selected" : "Select" }}
                                    </button>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="facilitySelectionQuestion.SubQuestions && facilitySelectionQuestion.SubQuestions.length == 1">
        <div class="non-listed-facility-selection" [class.d-none]="maxLimitReached">
            <div class="question">
                <div class="question-number">{{facilitySelectionQuestion.QuestionNo}}a</div>
                <div class="question-content">
                    <div class="question-content-title">{{facilitySelectionQuestion.SubQuestions[0].QuestionText}}</div>
                </div>
            </div>
            <div class="response">
                <div class="row">
                    <div class="col-sm-6 mb-3">
                        <label class="form-label">Suburb</label>
                        <app-suburb-select [presetSuburbs]="presetSuburbs" id="nonListedSuburb" [formControl]="nonlistedSuburb" [suburbs]="suburbList"></app-suburb-select>
                    </div>
                    <div class="col-sm-6">
                        <label for="facilityName" class="form-label">Facility</label>
                        <input [formControl]="nonListedFacilityNameInput" type="text" class="form-control" id="facilityName"
                            [class.is-invalid]="showFacilityNameRequiredMessage">
                        <div *ngIf="showFacilityNameRequiredMessage">
                            <small  class="invalid-feedback d-block">
                                {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Facility')}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    
</div>  

<ng-template #tmplFacilityDetails let-f="f" let-displayServices="displayServices">
    <p><strong>{{f.data.Name}}</strong>
    <br/>{{f.data.Address.StreetAddress1}}
    <br/>{{f.data.Suburb.Name}} {{f.data.Postcode.Code}}</p>
    <p><strong>Phone: </strong>{{f.data.FacilityPhoneNumber}}</p>
    <div *ngIf="f.data.Is24Hour || f.data.FacilityStaffedHour">
        <p><strong>Operating Hours: </strong>
            <span *ngIf="f.data.Is24Hour">Open 24 hours</span>
        </p>        
        <div *ngIf="!f.data.Is24Hour">
            <ul>
                <li *ngIf="f.data.FacilityStaffedHour.MondayFrom !== null">Mon:
                    {{f.data.FacilityStaffedHour.MondayFrom}} to
                    {{f.data.FacilityStaffedHour.MondayTo}}</li>
                <li *ngIf="f.data.FacilityStaffedHour.TuesdayFrom !== null">Tue:
                    {{f.data.FacilityStaffedHour.TuesdayFrom}} to
                    {{f.data.FacilityStaffedHour.TuesdayTo}}</li>
                <li *ngIf="f.data.FacilityStaffedHour.WednesdayFrom !== null">Wed:
                    {{f.data.FacilityStaffedHour.WednesdayFrom}} to
                    {{f.data.FacilityStaffedHour.WednesdayTo}}</li>
                <li *ngIf="f.data.FacilityStaffedHour.ThursdayFrom !== null">Thu:
                    {{f.data.FacilityStaffedHour.ThursdayFrom}} to
                    {{f.data.FacilityStaffedHour.ThursdayTo}}</li>
                <li *ngIf="f.data.FacilityStaffedHour.FridayFrom !== null">Fri:
                    {{f.data.FacilityStaffedHour.FridayFrom}} to
                    {{f.data.FacilityStaffedHour.FridayTo}}</li>
                <li *ngIf="f.data.FacilityStaffedHour.SaturdayFrom !== null">Sat:
                    {{f.data.FacilityStaffedHour.SaturdayFrom}} to
                    {{f.data.FacilityStaffedHour.SaturdayTo}}</li>
                <li *ngIf="f.data.FacilityStaffedHour.SundayFrom !== null">Sun:
                    {{f.data.FacilityStaffedHour.SundayFrom}} to
                    {{f.data.FacilityStaffedHour.SundayTo}}</li>
            </ul>
        </div>
    </div>
    <p><strong>Access Card Required: </strong>{{f.data.IsAccessCard ? 'Yes' : 'No'}}</p>
    <p *ngIf="f.data.IsAccessCard"><strong>Access Card Fee:
        </strong>{{Utils.convertPriceFormat(f.data.AccessCardFee)}}</p>
    <button class="btn btn-sm btn-block btn-primary" type="button" (click)="openWebsite(f.data.WebsiteUrl)">View Website</button>
    <div *ngIf="f.data.AdditionalInfo" class="facility-additional-info">
        <button class="btn btn-sm btn-block btn-primary" type="button" (click)="openCloseAdditionalInfo(f.data.FacilityId)">Additional Info</button>
        <span *ngIf="f.data.IsShownAdditionalInfo">
            {{f.data.AdditionalInfo}}
        </span>
    </div>
    <button class="btn btn-sm btn-block btn-primary" type="button" [disabled]="isFacilitySelected(f.data.FacilityId) || maxLimitReached" (click)="addToSelection(f)">
        <i class="fa" [ngClass]="{'fa-check':isFacilitySelected(f.data.FacilityId), 'fa-plus':!isFacilitySelected(f.data.FacilityId)}"></i> {{isFacilitySelected(f.data.FacilityId) ? "Selected" : "Select"}}
    </button>
    <div class="falicliy-activity-wrapper" *ngIf="f.data.Activities.length>0">
        <div class="facility-activity-icon" *ngFor="let activity of f.data.Activities">
            <img src="./assets/images/facility-icon/{{activity.IconFileName}}.png" alt="Altitude" class="img-fluid" tooltip="{{activity.ActivityToolTip}}">
        </div>
    </div>
</ng-template>