import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export type Config = {
  version: string;
};

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public version = '';

  constructor(private http: HttpClient) {}

  loadConfig() {
    return forkJoin([this.loadVersion()]);
  }

  private loadVersion() {
    return this.http
      .get<Config>('/assets/config.json')
      .pipe(map((config) => config.version))
      .pipe(tap((version) => (this.version = version)));
  }
}
