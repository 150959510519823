import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonService } from "@fp/services";
import { FacilityGoogleMapNewComponent } from "@fp/facility-google-map-new/facility-google-map-new.component";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-map-view-new",
  templateUrl: "./map-view-new.component.html",
  styleUrls: ["./map-view-new.component.css"],
})
export class MapViewNewComponent implements OnInit {

  @ViewChild("googlemap") googlemap: FacilityGoogleMapNewComponent;

  constructor(private commonSvc: CommonService, protected commonService: CommonService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.commonSvc.App.currentLogin = false;
    this.commonSvc.App.opened = false;
  }

  ngAfterViewInit() {
    this.OnLoad();
  }

  OnLoad() {
    const memberId: number = +this.commonService.D_FP_AES256ForShortString(
      localStorage.getItem("memberId")
    );
    let membershipMapPackageId = this.route.snapshot.queryParamMap.get('packageId')
    this.googlemap.startMapLoadSpinner();
    this.googlemap.LoadFacilitiesByMembershipPackageId(
      Number(membershipMapPackageId),
      memberId
    );
  }

}
