<div class="form-group row">
    <label [attr.for]="getName(control)" [ngClass]="clsLabel ? clsLabel : 'col-md-3 col-form-label'">{{ label ? label :
        'Mobile Number' }}<span class="text-danger">{{ mandatory ? '*' : '' }}</span>
    </label>
    <div [ngClass]="clsGroup ? clsGroup : 'col-md-9'">
        <div class="d-flex flex-column w-100">
            <div [ngClass]="(invalidPhone || (control.invalid && (control.dirty || control.touched || submitted))) ? 'input-group is-invalid pl-0' : 'input-group' "
                [class.focused]="parentFocus==61">
                <input type="text" class="form-control" [(ngModel)]="value" [attr.id]="getName(control)"
                    [attr.placeholder]="placeholder" [maxlength]="mobileLength" [minlength]="mobileLength"
                    [readonly]="readonly" [pattern]="pattern"
                    (blur)="checkExist ? CheckExistPhone() : OnNotCheckExistPhone()" (keyup)="OnChange($event)"
                    (change)="OnChange($event)" (focus)="onFocusParent(61)">
                <mat-spinner [diameter]="14" *ngIf="phoneSpinter && checkExist"></mat-spinner>
            </div>
            <div *ngIf="control.invalid && (control.dirty || control.touched || submitted)">
                <small *ngIf="control.errors.required" class="invalid-feedback d-block">{{ label ? label : 'Mobile
                    Number' }}
                    is required</small>
                <small
                    *ngIf="control.errors.pattern && !control.errors.pattern.actualValue.startsWith('0') && isAusValidationMessage"
                     class="invalid-feedback d-block">{{ label ? label : 'Mobile
                    Number' }} is
                    invalid</small>
                <small
                    *ngIf="control.errors.pattern && !control.errors.pattern.actualValue.startsWith('0') && !isAusValidationMessage"
                     class="invalid-feedback d-block">{{ label ? label : 'Mobile
                    Number' }} is
                    invalid. Please enter a valid number with 7-12 digits. Leading zero will be removed.</small>
                <small *ngIf="control.errors.pattern && control.errors.pattern.actualValue.startsWith('0')"
                     class="invalid-feedback d-block">Invalid mobile format. Please include your country code. Example
                    +61400000000</small>
                <small *ngIf="control.errors['duplicated']"  class="invalid-feedback d-block">{{ label ? label : 'Mobile
                    Number' }} already exists</small>
            </div>
            <small *ngIf="control.valid && invalidPhone"  class="invalid-feedback d-block">{{ label ? label : 'Mobile
                Number' }} is invalid</small>
        </div>
    </div>
</div>
