<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Member Verification</h4>
<div class="mt-4 mt-lg-8">
    <form>
        <div class="form-group row">
            <label for="txtFilterMember" class="col-md-3 col-lg-2 col-form-label">Search by Member</label>
            <div class="col-md-8 col-lg-7">
                <div class="d-flex flex-column w-100">
                    <input [formControl]="memberCtrl" id="txtFilterMember" class="form-control" type="text"
                        placeholder="Search by Member Name, ID/Number, Email or Employee/Payroll No."
                        autocomplete="nope" (keydown.enter)="searchMemberVerification()">
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="txtFilterEmployers" class="col-md-3 col-lg-2 col-form-label">Search by Employer</label>
            <div class="col-md-8 col-lg-7">
                <div class="d-flex flex-column w-100">
                    <div class="input-group" [ngClass]="getClassFocused(1)">
                        <input id="txtFilterEmployers" class="form-control" type="text"
                            placeholder="Search by Employer Name/Code" [formControl]="employerName" matInput
                            [matAutocomplete]="ddlViewMember" (blur)="onBlurParent(0)" (focus)="onFocusParent(1)"
                            (keydown.enter)="searchMemberVerification()">
                        <mat-autocomplete #ddlViewMember="matAutocomplete" [displayWith]="ddlViewMember_DisplayWithFn">
                            <mat-option *ngFor="let employer of employerSearchResults" [value]="employer">
                                <p class="d-flex align-items-center m-0 ml-2 w-100">
                                    <span>{{employer.Name}}</span></p>
                            </mat-option>
                        </mat-autocomplete>
                        <div class="input-group-append">
                            <button *ngIf="!isSearchingEmployer" class="input-group-text" type="submit">
                                <i class="fas fa-search text-primary"></i>
                            </button>
                            <mat-spinner *ngIf="isSearchingEmployer" [diameter]="14"></mat-spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-8 col-lg-6 offset-md-3 offset-lg-2">
                <button class="btn btn-primary px-5 text-white" type="submit"
                    (click)="searchMemberVerification()">SEARCH</button>
            </div>
        </div>
        <div class="form-group py-4">
            <div class="border-default rounded-10 border-2 px-2">
                <div class="tablecontainer px-0">
                    <table mat-table matSort [dataSource]="dataSource" class="rounded-10"
                        (matSortChange)="sortChange($event)" matSort matSortStart="desc" matSortDirection="desc">
                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="tickBoxes">
                            <th mat-header-cell *matHeaderCellDef class="p-0 text-center">
                                <div class="custom-control custom-checkbox p-0 m-0 tooltip">
                                    <input type="checkbox" class="custom-control-input"
                                        (change)="$event ? masterToggle() : null"
                                        [checked]="selection[paging.PageNumber-1] != undefined ? selection[paging.PageNumber-1].memberIds.length > 0 && isAllSelected() : false"
                                        [indeterminate]="selection[paging.PageNumber-1] != undefined ? selection[paging.PageNumber-1].memberIds.length > 0 && !isAllSelected() : false">
                                    <label class="custom-control-label">&nbsp;
                                    </label>
                                    <span class="tooltiptext" style="position: absolute;top: 30px;left: -15%;">Select
                                        All</span>
                                </div>
                                <!-- <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection[paging.PageNumber-1]?.memberIds?.length > 0 && isAllSelected()"
                                            [indeterminate]="selection[paging.PageNumber-1]?.memberIds?.length > 0 && !isAllSelected()">
                                        </mat-checkbox> -->
                            </th>
                            <td mat-cell *matCellDef="let row" class="pl-0 text-center">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="sortby">
                            <th mat-header-cell *matHeaderCellDef>Sort By:</th>
                            <td mat-cell *matCellDef="let element" class="wordbreak">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="lastName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="btn btn-outline-primary">
                                    Last Name
                                </p>
                            </th>
                            <td mat-cell *matCellDef="let element" class="wordbreak"> {{element.lastName}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="firstName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="btn btn-outline-primary">
                                    First Name
                                </p>
                            </th>
                            <td mat-cell *matCellDef="let element" class="wordbreak"> {{element.firstName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="pr-0">
                                <p class="btn btn-outline-primary">
                                    Status
                                </p>
                            </th>
                            <td mat-cell *matCellDef="let element" class="pr-0"> {{element.status}} </td>
                        </ng-container>

                        <ng-container matColumnDef="employer">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <p class="btn btn-outline-primary">
                                    Employer
                                </p>
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.employer}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
                        <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
                    </table>
                    <mat-card *ngFor="let row of (dataSource ? dataSource.data : [])">
                        <div class="row" style="align-items: center;">
                            <div class="custom-control custom-checkbox p-0 m-0" style="width: 1em;">
                                <input type="checkbox" class="custom-control-input" (change)="toggle($event, row.id)"
                                    [checked]="selection[paging.PageNumber-1] != undefined ? selection[paging.PageNumber-1].memberIds.includes(row.id) : false">
                                <label class="custom-control-label">&nbsp;</label>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <span class="col text-primary">Last Name: </span>
                                    <span class="col">{{ row.lastName }}</span>
                                </div>
                                <div class="row">
                                    <span class="col text-primary">First Name: </span>
                                    <span class="col">{{ row.firstName }}</span>
                                </div>
                                <div class="row">
                                    <span class="col text-primary">Status: </span>
                                    <span class="col">{{ row.status }}</span>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <span class="col text-primary">Employer: </span>
                                    <span class="col">{{ row.employer }}</span>
                                </div>
                                <div class="row">
                                    <span class="col text-primary">Workplace Suburb: </span>
                                    <span class="col">{{ row.WorkplaceSuburb }}</span>
                                </div>
                                <div class="row">
                                    <span class="col text-primary">Employee Number: </span>
                                    <span class="col">{{ row.employeeNo }}</span>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <span class="col text-primary">Member Number: </span>
                                    <span class="col">{{ row.memberNo }}</span>
                                </div>
                                <div class="row">
                                    <span class="col text-primary">Member Id: </span>
                                    <span class="col">{{ row.id }}</span>
                                </div>
                                <div class="row">
                                    <span class="col text-primary">Member Type: </span>
                                    <span class="col">{{ row.memberType }}</span>
                                </div>
                                <div *ngIf="row.primaryMember" class="row">
                                    <span class="col text-primary">Primary Member: </span>
                                    <span class="col" style="color: blue; cursor: pointer;"
                                        (click)="openLink(row)">{{ row.primaryMember.id }}</span>
                                </div>
                            </div>

                            <div class="col-2"
                                style="max-width: 215px; text-align: center;justify-content: space-between;">
                                <div *ngIf="(row.photo == undefined || row.photo == '' || row.photo == null); else hasImage"
                                    class="my-2 NoImage">
                                    <div
                                        class="bg-secondary fp-profilephoto d-flex justify-content-center align-items-center mx-auto">
                                        <i class="fas fa-user-tie fa-2x text-secondary"></i>
                                    </div>
                                    <button [disabled]="true" class="btn invalidPhoto w-100 rounded-0">Invalid Photo</button>
                                    <button [disabled]="true" class="btn invalidPhoto w-100 rounded-0">Edit Photo</button>
                                </div>
                                <ng-template #hasImage>
                                    <div class="my-2">
                                        <div
                                            class="fpPictureCover border border-primary d-flex align-items-center justify-content-center">
                                            <app-member-image [imgSrc]="row.photo" [htmlClass]="'maxWH1'">
                                            </app-member-image>
                                        </div>
                                        <button
                                            class="btn bg-primary text-light py-1 d-block text-center w-100 photo rounded-0"
                                            (click)="setInvalidPhoto(row)">Invalid Photo</button>
                                        <button (click)="OnSelectedUploadPhoto(row)"
                                            class="btn bg-primary text-light py-1 d-block text-center w-100 photo rounded-0 mt-1">Edit Photo</button>

                                    </div>
                                </ng-template>
                                <a class="text-center tooltip" (click)="ApproveOrReject_Click(true, row)">
                                    <i class="fas fa-check-circle" style="color: green; font-size: 3em;"></i>
                                    <span class="tooltiptext">Approve</span>
                                </a>
                                <a class="text-center tooltip" data-toggle="modal" data-target="#requestInfoModal"
                                    (click)="btnRequestInfoClick(row)">
                                    <i class="fas fa-question-circle text-warning fa-4x" style="font-size: 3em;"></i>
                                    <span class="tooltiptext">Request More Information</span>
                                </a>
                                <a class="text-center tooltip" (click)="ApproveOrReject_Click(false, row)">
                                    <i class="fas fa-times-circle text-danger fa-4x" style="font-size: 3em;"></i>
                                    <span class="tooltiptext">Reject</span>
                                </a>
                            </div>
                        </div>

                    </mat-card>
                    <mat-toolbar [hidden]="dataSource == null" class="bg-white">
                        <mat-toolbar-row [hidden]="dataSource != null && dataSource.data.length == 0" class="p-0">
                            <mat-paginator #paginator class="w-100" [length]="length" [pageSize]="pageSize"
                                [pageSizeOptions]="pageSizeOptions" (page)="paginatorChange($event)"
                                showFirstLastButtons>
                            </mat-paginator>
                        </mat-toolbar-row>
                        <mat-toolbar-row *ngIf="dataSource != null && dataSource.data.length == 0"
                            class="text-muted d-block p-2 bg-light text-center">
                            {{ commonString.TABLE_NO_RECORD_FOUND }}
                        </mat-toolbar-row>
                    </mat-toolbar>
                </div>
            </div>
        </div>
        <button class="px-5 py-2 btn btn-primary text-light" type="button" (click)="ApproveOrReject_Click(true)"
            [disabled]="disabledButton()"><i class="fas fa-check-circle mr-2"></i>Approve
            Selected</button>
        <button class="px-5 py-2 btn btn-danger text-light ml-3" type="button" (click)="ApproveOrReject_Click(false)"
            [disabled]="disabledButton()"><i class="fas fa-times-circle mr-2"></i>Reject
            Selected</button>
    </form>
</div>
<div class="modal fade" #requestInfoModalctrl id="requestInfoModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">More Information Required</h5>
            </div>
            <div class="modal-body py-4">
                <div class="d-flex align-items-center mb-3">
                    <i class="fas fa-info-circle text-primary mt-1 mr-2 fa-2x"></i>
                    Use this section to email the potential member with any questions or requests for additional
                    information. When you click 'Submit' the message will be emailed to the applicant.
                </div>
                <form [formGroup]="AdditionalInfoFormGroup" id="AInfoFormGorup">
                    <div class="form-group row">
                        <label for="ddlInfoRequired" class="col-md-4 col-form-label">Information <br>required
                            <span class="text-danger">*</span>
                        </label>
                        <div class="col-md-8">
                            <div class="d-flex flex-column w-100">
                                <select class="form-control" id="ddlInfoRequired" formControlName="ddlInfoRequired"
                                    [ngClass]="SetClassForControl(AdditionalInfoFormGroup,'ddlInfoRequired')">
                                    <option value="" selected>Please select a value</option>
                                    <option *ngFor="let ir of InformationRequired" [value]="ir.Name">
                                        {{ ir.Name }}
                                    </option>
                                </select>
                                <div *ngIf="IsControlInvalid(AdditionalInfoFormGroup,'ddlInfoRequired')">
                                    <small *ngIf="AdditionalInfoFormGroup.get('ddlInfoRequired').hasError('required')"
                                         class="invalid-feedback d-block">Information is required</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="txtMsgEmpMem" class="col-md-4 col-form-label">{{MsgEmpMem}}<span
                                class="text-danger">*</span></label>
                        <div class="col-md-8">
                            <div class="d-flex flex-column w-100">
                                <textarea class="form-control" id="txtMsgEmpMem" maxlength="1000"
                                    formControlName="txtMsgEmpMem"
                                    [ngClass]="SetClassForControl(AdditionalInfoFormGroup,'txtMsgEmpMem')"></textarea>
                                <div *ngIf="IsControlInvalid(AdditionalInfoFormGroup,'txtMsgEmpMem')">
                                    <small *ngIf="AdditionalInfoFormGroup.get('txtMsgEmpMem').hasError('required')"
                                         class="invalid-feedback d-block">{{MsgEmpMem}} is required</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="py-4 d-flex justify-content-center">
                    <button id="ms_cards_change_photo_close"
                        class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                        data-dismiss="modal">
                        <i class="fas fa-ban mr-2"></i>Cancel</button>
                    <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="btnSubmit_Click($event)">
                        <i class="fas fa-check-circle mr-2"></i>
                        Submit</button>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        id="btnCloseModalRequest">
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>