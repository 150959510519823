<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Payment History</h4>
<!-- Main Mat Spinner -->
<div class="loading-shade-global" *ngIf="isLoading">
  <mat-spinner [diameter]="50"></mat-spinner>
</div>
<div class="mt-lg-4">
  <div class="border-default rounded-10 border-2" [ngStyle]="tableStyle()">
    <div class="tablecontainer fpscrolly" [ngStyle]="tableStyle(true)">
      <table mat-table [dataSource]="dataSource" class="rounded-10" matSort matSortActive="DateTime"
        matSortDirection="desc">
        <!-- Name Column -->
        <ng-container matColumnDef="DateTime">
          <th mat-header-cell *matHeaderCellDef > From Date </th>
          <td mat-cell *matCellDef="let element"> {{element.PeriodStartDate | dateFormatPipe}} </td>
        </ng-container>

        <ng-container matColumnDef="DateTime2">
          <th mat-header-cell *matHeaderCellDef > To Date </th>
          <td mat-cell *matCellDef="let element"> {{element.PeriodEndDate | dateFormatPipe}} </td>
        </ng-container>

        <ng-container matColumnDef="Amount">
          <th mat-header-cell *matHeaderCellDef > Amount </th>
          <td mat-cell *matCellDef="let element"> {{element.Amount | currency}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="width: 10em;"> Download Tax Invoice/Receipt</th>
          <td mat-cell *matCellDef="let element" style="width: 10em;" class="text-center">
            <a>
              <i (click)="generateMemberInvoice(element)" class="fas fa-eye"></i>
            </a>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-toolbar [hidden]="dataSource.data.length > 0 || isLoading" class="bg-white">
        <mat-toolbar-row class="text-muted d-block p-2 bg-light text-center">
          {{ CommonString.TABLE_NO_RECORD_FOUND }}
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
  </div>
</div>