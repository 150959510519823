<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Search Users</h4>
<!-- Search Users -->
<div class="mt-lg-4">
    <form [formGroup]="searchUsersByNameFG" (ngSubmit)="getUsersByName('-1')">
        <div class="form-group row">
            <label for="txtSearch" class="col-md-3 col-lg-2 col-form-label">Search User</label>
            <div class="col-md-8 col-lg-5">
                <div class="input-group" [ngClass]="getClassFocused(1)">
                    <input class="form-control" formControlName="searchName" type="text"
                        placeholder="Search user by first name, last name, email, mobile number" [matAutocomplete]="auto0"
                        (blur)="onBlurParent(0)" (focus)="onFocusParent(1)"
                        (keydown.enter)="btnUserSearch_Click($event)">
                    <div class="input-group-append">
                        <button class="input-group-text" [disabled]="searchUsersByNameFG.invalid" type="submit">
                            <i class="fas fa-search text-primary" *ngIf="!nameSpinter.isBool"></i>
                        </button>
                        <mat-spinner *ngIf="nameSpinter.isBool" [diameter]="14"></mat-spinner>
                    </div>
                </div>
                <mat-autocomplete #auto0="matAutocomplete" [displayWith]="displayLastnameAndFirstname"
                    (optionSelected)="ddlUserAutoComplete_Selected($event);">
                    <mat-option *ngFor="let user of users" [value]="user">
                        <p class="d-flex align-items-center m-0 ml-2 w-100" [routerLink]="[routerEditUser]"
                            [queryParams]="{ id: user.UserId }" skipLocationChange>
                            <span>
                                {{ user.LastName }}, {{ user.FirstName }}
                            </span>
                        </p>
                    </mat-option>
                </mat-autocomplete>
            </div>
            <div class="ml-2">
                <button class="btn btn-primary px-4 text-white btnMenberSearch" id="btnUserSearch"
                    (click)="btnUserSearch_Click($event)">SEARCH</button>
            </div>
        </div>
    </form>
    <p class="mb-0 py-2 h5">OR</p>
    <form [formGroup]="searchUsersFG" (ngSubmit)="searchUsers()">
        <div class="form-group row">
            <label for="slctViewUsers" class="col-md-3 col-lg-2 col-form-label">View Users</label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <select class="form-control" id="slctViewUsers" formControlName="selectFilter"
                        (ngModelChange)="changeFilter($event)">
                        <option [value]="0">View All</option>
                        <option [value]="1">View by Role</option>
                        <option [value]="2">View by Employer</option>
                        <option [value]="3">View by Location</option>
                        <option [value]="4">View by Facility</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group row" [hidden]="!(searchUsersFG.value.selectFilter == 1)">
            <label for="slctRole" class="col-md-3 col-lg-2 col-form-label">Role</label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <select class="form-control" id="slctRole" formControlName="selectRole">
                        <option [value]="0">Please select a value</option>
                        <option *ngFor="let role of roles" [value]="role.Id">{{ role.Name }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group row" [hidden]="!(searchUsersFG.value.selectFilter == 2)">
            <label for="slctEmployer" class="col-md-3 col-lg-2 col-form-label">Employer</label>
            <div class="col-md-8 col-lg-5">
                <div class="input-group" [ngClass]="getClassFocused(2)">
                    <input class="form-control" formControlName="selectEmployer" type="text"
                        placeholder="Search employer by employer name" [matAutocomplete]="auto"
                        (ngModelChange)="getEmployersByName($event)" (blur)="onBlurParent(0)"
                        (focus)="onFocusParent(2)">
                    <div class="input-group-append">
                        <button class="input-group-text" [disabled]="isSearchBool()" type="submit">
                            <i class="fas fa-search text-primary" *ngIf="!employerSpinter.isBool"></i>
                            <mat-spinner *ngIf="employerSpinter.isBool" [diameter]="14"></mat-spinner>
                        </button>
                    </div>
                </div>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let e of employers" [value]="e">
                        <p class="d-flex align-items-center m-0 ml-2 w-100">
                            <span>
                                {{ e.Name }}
                            </span>
                        </p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
        <div class="form-group row" [hidden]="!(searchUsersFG.value.selectFilter == 3)">
            <label for="slctRegion" class="col-md-3 col-lg-2 col-form-label">Location</label>
            <div class="col-md-8 col-lg-5">
                <div class="input-group" [ngClass]="getClassFocused(3)">
                    <input class="form-control" formControlName="selectLocation" type="text"
                        placeholder="Search region by region, suburb, postcode" [matAutocomplete]="auto1"
                        (ngModelChange)="getLocationByName($event)" (blur)="onBlurParent(0)" (focus)="onFocusParent(3)">
                    <div class="input-group-append">
                        <button class="input-group-text" [disabled]="isSearchBool()" type="submit">
                            <i class="fas fa-search text-primary" *ngIf="!locationSpinter.isBool"></i>
                            <mat-spinner *ngIf="locationSpinter.isBool" [diameter]="14"></mat-spinner>
                        </button>
                    </div>
                </div>
                <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let l of locations" [value]="l">
                        <p class="d-flex align-items-center m-0 ml-2 w-100">
                            <span>
                                {{ l.Name }}
                            </span>
                        </p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
        <div class="form-group row" [hidden]="!(searchUsersFG.value.selectFilter == 4)">
            <label for="slctEmployer" class="col-md-3 col-lg-2 col-form-label">Facility</label>
            <div class="col-md-8 col-lg-5">

                <div class="input-group" [ngClass]="getClassFocused(4)">
                    <input class="form-control" formControlName="selectFacility" type="text"
                        placeholder="Search facility by facility name" [matAutocomplete]="gymAutocomplete"
                        (blur)="onBlurParent(0)" (focus)="onFocusParent(4)"
                        (ngModelChange)="txtSelectGym_Input($event)">
                    <div class="input-group-append">
                        <button class="input-group-text" type="submit">
                            <i class="fas fa-search text-primary" *ngIf="!isSearchingFacility"></i>
                            <mat-spinner *ngIf="isSearchingFacility" [diameter]="14"></mat-spinner>
                        </button>
                    </div>
                </div>
                <mat-autocomplete #gymAutocomplete="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let facility of facilitySearchResult" [value]="facility">
                        <p class="d-flex align-items-center m-0 ml-2 w-100">
                            {{facility.Name}}
                        </p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>

        <div formGroupName="EmployerRole"
            *ngIf="searchUsersFG.value.selectRole == 8 && searchUsersFG.value.selectFilter == 1">
            <div class="form-group row">
                <label for="slctEmployerName" class="col-md-3 col-lg-2 col-form-label">Employer Name</label>
                <div class="col-md-8 col-lg-5">
                    <div class="input-group" [ngClass]="getClassFocused(5)">
                        <input class="form-control" type="text" formControlName="employerName"
                            placeholder="Search by Employer Name..." [matAutocomplete]="employerNameAutocomplete"
                            (blur)="onBlurParent(0)" (focus)="onFocusParent(5)">
                        <div class="input-group-append">
                            <button class="input-group-text" [disabled]="isSearchFacilitatorBool()" type="submit">
                                <i class="fas fa-search text-primary" *ngIf="!facilitatorEmployersSpinner"></i>
                                <mat-spinner *ngIf="facilitatorEmployersSpinner" [diameter]="14"></mat-spinner>
                            </button>
                        </div>
                    </div>
                    <mat-autocomplete #employerNameAutocomplete="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let e of facilitatorEmployers" [value]="e">
                            <p class="d-flex align-items-center m-0 ml-2 w-100">
                                {{ e.Name }}
                            </p>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
            <div class="form-group row">
                <label for="slctFacilitatorName" class="col-md-3 col-lg-2 col-form-label">Facilitator Name</label>
                <div class="col-md-8 col-lg-5">
                    <div class="input-group" [ngClass]="getClassFocused(6)">
                        <input class="form-control" type="text" formControlName="facilitator"
                            placeholder="Search by Facilitator Name..." [matAutocomplete]="facilitatorNameAutocomplete"
                            (blur)="onBlurParent(0)" (focus)="onFocusParent(6)">
                        <div class="input-group-append">
                            <button class="input-group-text" [disabled]="isSearchFacilitatorBool()" type="submit">
                                <i class="fas fa-search text-primary" *ngIf="!facilitatorsSpinner"></i>
                                <mat-spinner *ngIf="facilitatorsSpinner" [diameter]="14"></mat-spinner>
                            </button>
                        </div>
                    </div>
                    <mat-autocomplete #facilitatorNameAutocomplete="matAutocomplete" [displayWith]="displayFacilitator">
                        <mat-option *ngFor="let f of facilitators" [value]="f">
                            <p class="d-flex align-items-center m-0 ml-2 w-100">
                                {{ f.LastName }}, {{ f.FirstName }}
                            </p>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
            <div class="form-group row">
                <label for="slctEmployerCode" class="col-md-3 col-lg-2 col-form-label">Employer Code</label>
                <div class="col-md-8 col-lg-5">
                    <div class="input-group" [ngClass]="getClassFocused(7)">
                        <input class="form-control" type="text" formControlName="employerCode"
                            placeholder="Search by Employer Code..." [matAutocomplete]="employerCodeAutocomplete"
                            (blur)="onBlurParent(0)" (focus)="onFocusParent(7)">
                        <div class="input-group-append">
                            <button class="input-group-text" [disabled]="isSearchFacilitatorBool()" type="submit">
                                <i class="fas fa-search text-primary" *ngIf="!employerCodesSpinner"></i>
                                <mat-spinner [diameter]="14" *ngIf="employerCodesSpinner"></mat-spinner>
                            </button>
                        </div>
                    </div>
                </div>
                <mat-autocomplete #employerCodeAutocomplete="matAutocomplete" [displayWith]="displayCodeFn">
                    <mat-option *ngFor="let c of employerCodes" [value]="c">
                        <p class="d-flex align-items-center m-0 ml-2 w-100">
                            {{ c.Code }}
                        </p>
                    </mat-option>
                </mat-autocomplete>
            </div>
            <div class="form-group row">
                <label for="slctEmployerParent" class="col-md-3 col-lg-2 col-form-label">Employer Parent</label>
                <div class="col-md-8 col-lg-5">
                    <div class="input-group" [ngClass]="getClassFocused(8)">
                        <input class="form-control" type="text" formControlName="employerParent"
                            placeholder="Search by Employer Parent..." [matAutocomplete]="employerParentAutocomplete"
                            (blur)="onBlurParent(0)" (focus)="onFocusParent(8)">
                        <div class=" input-group-append">
                            <button class="input-group-text" [disabled]="isSearchFacilitatorBool()" type="submit">
                                <i class="fas fa-search text-primary" *ngIf="!employersParentSpinner"></i>
                                <mat-spinner [diameter]="14" *ngIf="employersParentSpinner"></mat-spinner>
                            </button>
                        </div>
                    </div>
                    <mat-autocomplete #employerParentAutocomplete="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let e of employersParent" [value]="e">
                            <p class="d-flex align-items-center m-0 ml-2 w-100">
                                {{ e.Name }}
                            </p>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
            <div class="form-group row">
                <label for="slctEmployerRegion" class="col-md-3 col-lg-2 col-form-label">Employer Region</label>
                <div class="col-md-8 col-lg-5">
                    <div class="input-group" [ngClass]="getClassFocused(9)">
                        <input class="form-control" type="text" formControlName="employerRegion"
                            placeholder="Search by Employer Region..." [matAutocomplete]="employerRegionAutocomplete"
                            (blur)="onBlurParent(0)" (focus)="onFocusParent(9)">
                        <div class="input-group-append">
                            <button class="input-group-text" [disabled]="isSearchFacilitatorBool()" type="submit">
                                <i class="fas fa-search text-primary" *ngIf="!employerRegionsSpinner"></i>
                                <mat-spinner [diameter]="14" *ngIf="employerRegionsSpinner"></mat-spinner>
                            </button>
                        </div>
                    </div>
                    <mat-autocomplete #employerRegionAutocomplete="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let e of employerRegions" [value]="e">
                            <p class="d-flex align-items-center m-0 ml-2 w-100">
                                {{ e.Name }}
                            </p>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-8 col-lg-5 offset-sm-2">
                <button class="btn btn-primary px-5 text-white" [disabled]="isSearchBool() || isSearchFacilitatorBool()"
                    type="submit">SEARCH</button>
            </div>
        </div>
    </form>
</div>
