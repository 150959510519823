<div [formGroup]="form">
    <ngb-accordion activeIds="panelIDetails">
        <ngb-panel id="panelIDetails">
            <ng-template ngbPanelTitle>
                <i class="fas fa-check icon-fp"></i>
                Audit Log
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <div id="facilityAuditLogsTable">
                    <div>
                        <app-audit-log [loadOnInit]=true [auditSource]="'Facility'"></app-audit-log>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>