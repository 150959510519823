export class CreatePaymentRequest {
  FacilityName: string;
  ServiceName: string;
  ToDate: string;
  FromDate: string;
  VisitInSystem: number;
  TotalVisits: number;
  SystemCalculatedAmount: number;
  FinalAmount: number;
  InvoiceNumber: string;
  InvoiceName: string;
  InvoiceUuid: string;
  AttachmentsName: string;
  AttachmentsUuid: string;
  NotesInformation: NewPaymentRequestNote[];
  CreatedBy: string;
  HWM_NumberMemberships?: number;
  HWM_ChangedFromLastMonth?: boolean;
  AdditionalManualVisits?: number;
}

export class NewPaymentRequestNote {
  User: string;
  DateTime: string;
  Note: string;
}
