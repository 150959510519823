<div [formGroup]="form">
    <ng-template #requiredFieldMsgTmpl let-ctrl="control" let-ctrlName="fieldName"
        let-validateOnTouch="validateOnTouch || false">
        <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
            <small *ngIf="ctrl.hasError('required')" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_REQUIRED, ctrlName)}}</small>
        </div>
    </ng-template>
    <div formGroupName="Address">
        <input type="hidden" class="form-control" id="txtAddress3" formControlName="StreetAddress1" maxlength="100" >
    </div>    
    <div class="form-group row" formGroupName="Address">
        <label for="txtAddress1" class="col-md-3 col-lg-2 col-form-label">Address Line 1<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" [class.is-invalid]="!isControlValid('Address.StreetAddress')" id="txtAddress1" matInput [matAutocomplete]="addressAutocomplete"
                    formControlName="StreetAddress" maxlength="50" (blur)="txtAddress_Blur($event)" (input)="txtAddress1_Input($event)" (focusout)="AutoComplete_FocusOut($event)">
                <mat-spinner [diameter]="14" color="primary" *ngIf="getControl('Address.StreetAddress').pending"></mat-spinner>
                <ng-container *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: getControl('Address.StreetAddress'), fieldName: 'Address 1'}"></ng-container>
                <mat-autocomplete id="autoAddress" name="autoAddress" class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                    #addressAutocomplete="matAutocomplete" [displayWith]="AutoCompleteAddress_DisplayWithFn" (optionSelected)="SelectAddress1($event)">
                    <mat-option *ngFor="let address of filteredAdressList" [value]="address" class="border-default border-bottom py-1">
                        <p class="m-0 d-flex align-items-center">{{address.Text}}</p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>
    <div class="form-group row" formGroupName="Address">
        <label for="txtAddsLine2" class="col-md-3 col-lg-2 col-form-label">Address Line 2</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="txtAddsLine2" formControlName="StreetAddress2"
                    maxlength="200">
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtCity" class="col-md-3 col-lg-2 col-form-label">Suburb<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" [class.is-invalid]="!isControlValid('Suburb')" id="txtCity"
                    matInput [matAutocomplete]="suburbAutocomplete" formControlName="Suburb" maxlength="200"
                    (blur)="txtSuburb_Blur($event)" (input)="txtSuburb_Input($event)">
                <mat-spinner [diameter]="14" color="primary" *ngIf="getControl('Suburb').pending"></mat-spinner>
                <div *ngIf="!isControlValid('Suburb')">
                    <small *ngIf="getControl('Suburb').hasError('required')" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'City/Suburb')}}</small>
                    <small *ngIf="getControl('Suburb').hasError('unresolved')" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_INVALID, 'City/Suburb')}}</small>
                </div>
                <mat-autocomplete id="autoSuburb" name="autoSuburb"
                    class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                    #suburbAutocomplete="matAutocomplete" [displayWith]="AutoComplete_DisplayWithFn"
                    (optionSelected)="SelectSuburb($event.option.value)">
                    <mat-option *ngFor="let suburb of suburbs" [value]="suburb"
                        class="border-default border-bottom py-1">
                        <p class="m-0 d-flex align-items-center">
                            {{suburb.Name + ', ' + suburb.Postcode.State.Name + ', ' + suburb.Postcode.Code }}
                        </p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>
    <div *ngIf="!isFromAustralia" class="form-group row">
        <label for="txtCity" class="col-md-3 col-lg-2 col-form-label">Town/City<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="input-group"
                    [class.is-invalid]="!isControlValid(CityCtrl, true)"
                    #citySearchBox 
                    [class.disabled]="CityCtrl.disabled">
                    <input class="form-control" id="txtCity" type="text"
                        placeholder="Search City" formControlName="City" matInput
                        [matAutocomplete]="autoCity" (blur)="txtCity_Blur($event)"
                        (input)="txtCity_Input($event)"
                        (change)="ddlEmployerCity_Change()">
                    <mat-spinner [diameter]="14" color="primary" *ngIf="isSearchingCity"></mat-spinner>
                </div>
                <div
                    *ngIf="!isControlValid(CityCtrl, true)">
                    <small *ngIf="CityCtrl.getError('unresolved'); else notEquals"
                        class="invalid-feedback d-block">Town/City is invalid</small>
                    <small *ngIf="CityCtrl.getError('required')" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'City')}}</small>
                </div>
                <mat-autocomplete id="autoCity" name="autoCity"
                    class="height1 fpscrolly border-2 rounded-10 border-default w-100"
                    #autoCity="matAutocomplete"
                    [displayWith]="AutoCompleteCity_DisplayWithFn"
                    (optionSelected)="SelectCity($event.option.value)">
                    <mat-option *ngFor="let city of citySearchResults" [value]="city"
                        class="border-default border-bottom py-1">
                        <p class="m-0 d-flex align-items-center"> {{city.Name}}</p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlPostcode" class="col-md-3 col-lg-2 col-form-label">Postcode<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" [class.is-invalid]="!isControlValid('Postcode', true)" id="ddlPostcode"
                    formControlName="Postcode" [compareWith]="ddlPostcodeOption_CompareFn">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let postcode of postcodes" [ngValue]="postcode">
                        {{postcode.Code}}</option>
                </select>
                <div *ngIf="!isControlValid('Postcode', true)">
                    <small *ngIf="getControl('Postcode').hasError('required')" class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Postcode')}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row" *ngIf="isFromAustralia">
        <label for="ddlState" class="col-md-3 col-lg-2 col-form-label">State<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" [class.is-invalid]="!isControlValid('State', true)" id="ddlState"
                    formControlName="State" [compareWith]="ddlStateOption_CompareFn">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let state of states" [ngValue]="state">{{state.Name}}</option>
                </select>
                <ng-container
                    *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: getControl('State'), fieldName: 'State', validateOnTouch: true}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlCountry" class="col-md-3 col-lg-2 col-form-label">Country<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" [class.is-invalid]="!isControlValid('Country', true)" id="ddlCountry"
                    formControlName="Country" [compareWith]="ddlCountryOption_CompareFn">
                    <option *ngFor="let country of countries" [ngValue]="country">{{country.Name}}</option>
                </select>
                <ng-container
                    *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: getControl('Country'), fieldName: 'Country', validateOnTouch: true}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtRegion" class="col-md-3 col-lg-2 col-form-label">Region<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input class="form-control" [class.is-invalid]="!isControlValid('Region')" id="txtRegion"
                    disabled="disabled" [value]="form.get('Region').value?.Name" />
                <div *ngIf="!isControlValid('Region')">
                    <small *ngIf="form.get('Region').errors['required']" class="invalid-feedback d-block">
                        {{CommonMessage.POSTCODE_NOT_ALLOCATED_TO_REGION_VALIDATION_MESSAGE}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row" formGroupName="Address">
        <label for="txtLatitude" class="col-md-3 col-lg-2 col-form-label">Latitude<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" [class.is-invalid]="!isControlValid('Address.Latitude')"
                    id="txtLatitude" formControlName="Latitude" maxlength="15" placeholder="Example: -/+37.816812">
                <ng-container
                    *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('Address.Latitude'), fieldName: 'Latitude'}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row" formGroupName="Address">
        <label for="txtLongitude" class="col-md-3 col-lg-2 col-form-label">Longitude<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" [class.is-invalid]="!isControlValid('Address.Longitude')"
                    id="txtLongitude" formControlName="Longitude" maxlength="15" placeholder="Example: +/-144.963208">
                <ng-container
                    *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('Address.Longitude'), fieldName: 'Longitude'}">
                </ng-container>
            </div>
        </div>
    </div>
</div>