import { City } from "./city.model";
import { State } from "./state.model";

//Author: Da Do
//Description: Entity for employer

export class Employer {
    EmployerId: number;
    AlternateNameTags: string;
    Name: string;
    IndustrySectorId: number;
    Code: string;
    ParentEmpoyerId: number;
    EmailAddress: string;
    EmployerStartDate: string;
    EmployerTypeId: number;
    EmployerGroupId: number;
    AddressId: number;
    IsActive: boolean;
    Acn: string;
    Abn: string;
    DateTimeStamp: string;
    PostcodeId: number;
    City: City;
    State: State;
    EligibleEmployeeNumber?: number;
    EmployerStatusId?: number;
}