import { StepperSelectionEvent } from "@angular/cdk/stepper";
import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Injector,
    isDevMode,
    ViewChild
} from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, Router } from "@angular/router";
import {
    FacilityFormBase,
    FacilityFormMode,
    FPFormBaseComponent
} from "@fp/components/base";
import { CommonMessage, CommonString, RouterConstant } from "@fp/constant";
import { Utils } from "@fp/helpers";
import {
    Address,
    DataResult,
    Facility,
    FacilityUpdateDetails,
    MembershipAccess,
    Message,
    Service
} from "@fp/models";
import { FacilityService, MessageBox, MessageService } from "@fp/services";
import { FacilityAdditionalAttachmentsComponent } from "./additional-attachments/additional-attachments.component";
import { FacilityContractComponent } from "./contract/contract.component";
import { FacilityEmployerAccessComponent } from "./employer-access/employer-access.component";
import { FacilityDetailsComponent } from "./facility-details/facility-details.component";
import { FacilityPaymentComponent } from "./facility-payment/facility-payment.component";
import { FacilityJournalAndAuditLogComponent } from "./journal-and-audit-log/journal-and-audit-log.component";
import { FacilityProductsServicesComponent } from "./products-services/products-services.component";
import { FacilityVisitsPaymentComponent } from "./visits-payment/visits-payment.component";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { RolePermissionService } from '@fp/services/role-permission.service';
import { PrivilegeModel } from '@fp/models/privilege.model';
import { CommonConstants } from "@fp/constant/common-constants";
import { AgmBicyclingLayer } from "@agm/core";
import { EFacilityStatus } from "../../../enums";
import { EFacilityType } from "@fp/enums/facility-type.enum";
import { EFacilityCategory } from "@fp/enums/facility-category.enum";
import { EPaymentMethod } from "@fp/enums/payment-method.enum";
import { EPaymentFrequency } from "@fp/enums/payment-frequency.enum";
import { EPaymentPreference } from "@fp/enums/payment-preference.enum";
import { EPaymentDate } from "@fp/enums/payment-date.enum";

enum FacilityTab {
    FacilityDetails,
    PaymentDetails,
    ContractDetails,
    VisitsPayment,
    ProductsAndServices,
    EmployerAccess,
    Photos,
    AuditLog
}

@Component({
    selector: "app-addfacility",
    templateUrl: "./addfacility.component.html",
    styleUrls: ["./addfacility.component.css"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        FPFormBaseComponent.provideExisting(AddFacilityComponent),
        FacilityFormBase.provideExisting(AddFacilityComponent)
    ]
})
export class AddFacilityComponent extends FacilityFormBase
    implements AfterViewChecked {
    private oldHeight = "";
    private stepProgress: boolean[] = [];
    private originalValues: { [key: string]: any };
    FacilityTab = FacilityTab;
    private ProductAndServiceTabSelected = false;
    private currentParentGroupId;
    private currentFacility: Facility;
    hasInit = false;
    public isReadonly: boolean;
    CommonString = CommonString;
    facility_name = "";
    get isEditMode() {
        return this.data.mode === FacilityFormMode.Edit;
    }

    @ViewChild("stepperContainer", {static: true}) private divStepperContainer: ElementRef;
    @ViewChild("stepper", {static: true}) stepper: MatStepper;
    @ViewChild("facilityDetails") facilityDetails: FacilityDetailsComponent;
    @ViewChild("facilityPayment") paymentDetails: FacilityPaymentComponent;
    heighttabs: string;

    // TODO: remove after implementation completed.
    get _isDebugging() {
        return isDevMode() && window["enableDebugging"] === true;
    }

    // #region Template internal references
    JSON = JSON;
    // #endregion Template internal references

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            FacilityDetailsTab: FacilityDetailsComponent.getFormGroup(),
            PaymentTab: FacilityPaymentComponent.getFormGroup(),
            ContractTab: FacilityContractComponent.getFormGroup(),
            AuditLogTab: FacilityJournalAndAuditLogComponent.getFormGroup(),
            VisitsPaymentTab: FacilityVisitsPaymentComponent.getFormGroup(),
            ProductsServicesTab: FacilityProductsServicesComponent.getFormGroup(),
            EmployerAccessTab: FacilityEmployerAccessComponent.getFromGroup(),
            PhotosTab: FacilityAdditionalAttachmentsComponent.getFormGroup()
        });
    }

    constructor(
        injector: Injector,
        private svc: FacilityService,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private router: Router
    ) {
        super(injector);
        this.async = false;
        // Initialize form
        this.form = AddFacilityComponent.getFormGroup();
        this.originalValues = {};
    }

    ngAfterViewChecked() {

        if (!this.commonSvc.App.mobileQuery.matches) {
            this.heighttabs = this.divStepperContainer
                .nativeElement
                .querySelector('#facilitytb' + this.stepper.selectedIndex)
                .clientHeight + 50 + 'px';
          } else {
              this.heighttabs = '100%';
          }

          this.changeDetectorRef.detectChanges();

          //this.HideTab();
          if(!this.hasInit) {
            this.OnInit();
            this.OnLoad();
            this.hasInit = true;
          }

          if(this.facilityDetails.activityDetails.activityAccessoptionCheck){
            this.facilityDetails.importantInformation.getControl('AdditionalInfo').setValidators(Validators.required);
            this.facilityDetails.importantInformation.getControl('AdditionalInfo').updateValueAndValidity();
            this.facilityDetails.activityDetails.activityAccessoptionCheck = false;
          }

          if(this.facilityDetails.activityDetails.notActivityAccessoptionCheck){
            this.facilityDetails.importantInformation.getControl('AdditionalInfo').setValidators(null);
            this.facilityDetails.importantInformation.getControl('AdditionalInfo').updateValueAndValidity();
            this.facilityDetails.activityDetails.notActivityAccessoptionCheck = false;
          }
        // let stepperContainerHeight = "";
        // try {
        //     if (!this.commonSvc.App.mobileQuery.matches) {
        //         if (this.stepper.selected && this.stepper.selected.stepLabel) {
        //             const stepHeaderElem = this.stepper._stepHeader
        //                 .toArray()
        //             [this.stepper.selectedIndex]._getHostElement();
        //             const stepLabelElem = <HTMLElement>(
        //                 this.stepper.selected.stepLabel.template.elementRef.nativeElement
        //             );
        //             stepperContainerHeight =
        //                 (<HTMLElement>(
        //                     (stepLabelElem.parentElement || stepLabelElem.parentNode)
        //                 )).clientHeight +
        //                 stepHeaderElem.clientHeight +
        //                 "px";
        //         }
        //     } else {
        //         stepperContainerHeight = "100%";
        //     }
        // } catch (err) {
        //     console.warn(err);
        // }
        // if (this.loaded && this.oldHeight != stepperContainerHeight) {
        //     this.divStepperContainer.nativeElement.style.height = stepperContainerHeight;
        //     this.oldHeight = stepperContainerHeight;
        //     this.changeDetectorRef.markForCheck();
        // }
    }

    OnInit() {
        this.ProductAndServiceTabSelected = false;
        this.stepper._stepHeader.forEach((header, index) => {
            header._getHostElement().addEventListener(
                "click",
                (e: MouseEvent) => {
                    if (index > this.stepper.selectedIndex) {
                        // Validate all steps starting from current step before the next step is selected
                        for (let i = this.stepper.selectedIndex; i < index; i++) {
                            if (!this.validateStep(i)) {
                                e.preventDefault();
                                e.stopImmediatePropagation();
                                return false;
                            }
                        }
                    }
                },
                true
            );
        });

        const path = this.route.routeConfig.path;
        switch (path) {
            case RouterConstant.ROUTER_FACILITY_ADD:
                this.data.mode = FacilityFormMode.Add;
                break;
            case RouterConstant.ROUTER_FACILITY_EDIT:
                this.data.mode = FacilityFormMode.Edit;
                break;
            case RouterConstant.ROUTER_FACILITY_CLONE:
                this.data.mode = FacilityFormMode.Clone;
                break;
            default:
                this.data.mode = FacilityFormMode.View;
                break;
        }
        localStorage.removeItem('SiteToken');
        this.checkPrivilegesForReadOnly();
        super.OnInit();
    }

    OnLoad() {
        switch (this.data.mode) {
            case FacilityFormMode.Add:
                super.OnLoad();
                this.stepProgress = Array(this.stepper._steps.length).fill(false); // default false when add new
                break;
            case FacilityFormMode.Edit:
            case FacilityFormMode.Clone:
                let id = 0;
                if (this.data.mode === FacilityFormMode.Edit) {
                    id = parseInt(this.route.snapshot.params["id"], 10);
                } else {
                    id = parseInt(this.route.snapshot.queryParams["sourceId"], 10);
                }
                if (id > 0) {
                    this.Invoke(this.svc.get(id), {
                        onSuccess: (result: DataResult<FacilityUpdateDetails>) => {
                            if (result.Success) {
                                console.log("Facility Start Date", result.Data.FacilityStartDate);
                                if (result.Data == null) {
                                    this.router.navigate([
                                        RouterConstant.NAVIGATOR_PAGE_NOT_FOUND
                                    ]);
                                } else {
                                    this.facility_name = result.Data.Name;
                                    this.commonSvc.PaymentFrequencyID = result.Data.PaymentFrequencyId;
                                    this.data.model.FacilityStartDate = result.Data.FacilityStartDate;
                                    if (this.data.mode === FacilityFormMode.Clone) {
                                        // Author: Yen - Clone facility
                                        this.data.model = new FacilityUpdateDetails();
                                        this.data.model.FacilityDesc = result.Data.FacilityDesc;
                                        this.data.model.Is24Hour = result.Data.Is24Hour;
                                        this.data.model.Suburb = result.Data.Suburb;
                                        this.data.model.SuburbId = result.Data.SuburbId;
                                        this.data.model.Address = new Address();
                                        this.data.model.Address.SuburbId =
                                            result.Data.Address.SuburbId;
                                        this.data.model.Postcode = result.Data.Postcode;
                                        this.data.model.PostcodeId = result.Data.PostcodeId;
                                        this.data.model.State = result.Data.State;
                                        this.data.model.Country = result.Data.Country;
                                        this.data.model.Region = result.Data.Region;
                                        this.data.model.RegionId = result.Data.RegionId;
                                        // employer in the region tab
                                        this.data.model.MembershipAccesses =
                                            result.Data.MembershipAccesses;
                                        this.data.model.ListFacilityContact = result.Data.ListFacilityContact.map(
                                            c => {
                                                const _c = Object.assign({}, c);
                                                _c.FacilityContactNote = null;
                                                return _c;
                                            }
                                        );

                                        this.data.model.FacilityId = 0;
                                        this.data.model.Name = "";
                                        this.stepper._steps.forEach((item, i) =>
                                            this.stepProgress.push(false)
                                        );
                                    } else {
                                        this.data.model = result.Data;
                                        this.stepper._steps.forEach((item, i) =>
                                            this.stepProgress.push(true)
                                        );
                                    }

                                    this.PatchValue(this.data.model, { emitEvent: false });
                                    if (this.data.model.FacilityStatusId === EFacilityStatus.Potential) {
                                        let message: Message = {
                                          Identifier: CommonString.FacilityPotentialMessageIdentifier,
                                          Content: 'True',
                                        };
                                        this.messageService.changeMessage(message);
                                      } else {
                                        let message: Message = {
                                          Identifier: CommonString.FacilityPotentialMessageIdentifier,
                                          Content: 'False',
                                        };
                                        this.messageService.changeMessage(message);
                                      }
                                    this.form.markAsPristine();
                                }
                            } else {
                                this.HandleResponseError(result);
                            }
                            super.OnLoad();
                            if (this.data.mode === FacilityFormMode.Edit) {
                                this.commonSvc.AddChangeListenerInput();
                            }
                        }
                    });
                } else {
                    this.router.navigate([RouterConstant.NAVIGATOR_PAGE_NOT_FOUND]);
                }
                break;
            default:
                this.router.navigate([RouterConstant.NAVIGATOR_PAGE_NOT_FOUND]);
                break;
        }
    }

    LoadComplete() {
        this.originalValues = Utils.deepClone(this.data.model);
        this.currentParentGroupId = this.originalValues.ParentGroup ? this.originalValues.ParentGroup.ParentGroupId : -1;
        super.LoadComplete();
    }

    _stepProgressBackgroundVisible(index: number) {
        return this.stepProgress[index] || this.stepper.selectedIndex === index;
    }

    public HandleStepsBarBgColor(index): string {
        if (this._stepProgressBackgroundVisible(index)) {
            return "bg-primary";
        }
        return "";
    }

    public HandleTickofSteps(index): boolean {
        if (this.stepProgress[index] && this.stepper.selectedIndex !== index) {
            const country = this.facilityDetails.getCountry();
            this.paymentDetails.setFacilityCountry(country);
            return true;
        }
        return false;
    }

    validateStep(index) {
        let valid = false;
        const childComponent = this.children.toArray()[index];
        if (childComponent) {
            childComponent.Validate();
            valid = childComponent.valid;
            if (valid && index == FacilityTab.EmployerAccess) {
                const services =
                    <Service[]>(
                        this.getControl(["ProductsServicesTab", "ServicesList", "Services"])
                            .value
                    ) || [];
                const membershipAccesses =
                    <MembershipAccess[]>(
                        this.getControl(["EmployerAccessTab", "MembershipAccesses"]).value
                    ) || [];
                if (services.length === 0 && membershipAccesses.length > 0 && this.ProductAndServiceTabSelected === true) {
                    MessageBox.ShowError(
                        this.dialog,
                        "Services are required. Please add services into the facility"
                    );
                    valid = false;
                }
            }
        } else {
            valid = true;
        }
        return valid;
    }

    applyStepValue(index: number) {
        const childComponent = this.children.toArray()[index];
        if (childComponent) {
            this.data.model = this.applyValue(this.data.model, childComponent);
        }
    }

    nextStep() {
        console.log("Add Data",this.data.model);
        if (!this.validateStep(this.stepper.selectedIndex)) {
            this.commonSvc.ShowPopupValidator(this.dialog);
            return;
        }
        if (this.stepper.selectedIndex === 0) {
            const country = this.facilityDetails.getCountry();
            this.paymentDetails.setFacilityCountry(country);
        }
        this.stepper.next();
    }

    onStepChange(e: StepperSelectionEvent) {
        const index = e.selectedIndex;
        if (e.selectedIndex === FacilityTab.ProductsAndServices) {
            this.ProductAndServiceTabSelected = true;
        }
        this.stepProgress[e.previouslySelectedIndex] =
            e.previouslySelectedStep.completed;
        this.currentFacility = this.data.model;
        this.applyStepValue(e.previouslySelectedIndex);
        const com = this.children.toArray()[index];
        if (com) {
            if (!com.loaded) {
                com.Load();
            } else {
                if (e.selectedIndex === FacilityTab.EmployerAccess) {
                    (<FacilityEmployerAccessComponent>(
                        com
                    )).RefreshMembershipPackagesSearchResults(
                        this.data.model.Region.RegionId
                    );
                }
            }
        }
        if (this.commonSvc.FormDataChange === true && this.data.mode === FacilityFormMode.Edit) {
            const previousindex = e.previouslySelectedIndex;
            this.commonSvc.FormDataChange = false;
            MessageBox.ShowYesNoCancel(this.dialog, 'You have made changes, would you like to save ?').subscribe(ret => {
                if (ret.result.toLowerCase() === DialogResult.Yes) {
                    this.btnSubmit_Click(true);
                } else if (ret.result.toLowerCase() === DialogResult.No) {
                    return;
                } else {
                    this.stepper.selectedIndex = previousindex;
                }
            });
        }
    }

    private applyPaymentDetailsValue(facility) {
        if (this.data.model.FacilityId > 0) {
            this.data.model.BankAccount = null;
            this.data.model.Bpay = null;
            this.data.model.Cheque = null;
        } else {
            switch (facility.PaymentMethodId) {
                case 1:
                    this.data.model.Bpay = null;
                    this.data.model.Cheque = null;
                    break;
                case 2:
                    this.data.model.BankAccount = null;
                    this.data.model.Cheque = null;
                    break;
                case 3:
                    this.data.model.Bpay = null;
                    this.data.model.BankAccount = null;
                    this.data.model.Cheque = null;
                    break;
                case 4:
                    this.data.model.BankAccount = null;
                    this.data.model.Bpay = null;
                    break;
            }
        }
        this.data.model.FinanceSystemId = facility.FinanceSystemId;
        this.data.model.PaymentMethodId = facility.PaymentMethodId;
        switch (facility.PaymentMethodId) {
            case 1:
                this.data.model.BankAccount = facility.BankAccount;
                break;
            case 2:
                this.data.model.Bpay = facility.Bpay;
                break;
            case 4:
                this.data.model.Cheque = facility.Cheque;
                break;
        }
        this.data.model.PaymentFrequencyId = facility.PaymentFrequencyId;
        this.data.model.PaymentPreferenceId = facility.PaymentPreferenceId;
        this.data.model.PaymentDateId = facility.PaymentDateId;
        this.data.model.IsAllowToSeePaymentCalculation = facility.IsAllowToSeePaymentCalculation;
        this.PatchValue(this.data.model, { emitEvent: false });
    }

    private _processDataForSubmission(source: FacilityUpdateDetails) {
        const target = Object.assign({}, source);
        // if (target.Address.StreetAddress) {
        //     target.Address.StreetAddress1 = target.Address.StreetAddress.Text;
        //     delete target.Address.StreetAddress;
        // }

        if (target.Address && target.Address.StreetAddress && target.Address.StreetAddress.Text) {
            target.Address.StreetAddress1 = target.Address.StreetAddress.Text;
            delete target.Address.StreetAddress;
        }else if(target.Address && target.Address.StreetAddress && !target.Address.StreetAddress.Text){
            target.Address.StreetAddress1 = String(target.Address.StreetAddress)
        }

        if (target.Services instanceof Array) {
            target.Services = target.Services.filter(s => s["Changed"] === true);
            target.Services.forEach(_service => {
                delete _service["Changed"];
                if (_service.HwmNumberMemberships === null || _service.HwmNumberMemberships.toString() === '') {
                    _service.HwmNumberMemberships = 0;
                }
                if (_service.HwmVisitsPerMembership === null || _service.HwmVisitsPerMembership.toString() === '') {
                    _service.HwmVisitsPerMembership = 0;
                }
                if (_service.HwmPricePerMembership === null || _service.HwmPricePerMembership.toString() === '') {
                    _service.HwmPricePerMembership = 0.0;
                }
                if (_service.DeductionAmount === null || _service.DeductionAmount.toString() === '') {
                    _service.DeductionAmount = 0.0;
                }
                if (_service.Scanners instanceof Array) {
                    _service.Scanners.forEach(_scanner => {
                        delete _scanner.SIMProvider;
                        delete _scanner.InternetService;
                        delete _scanner.CaseType;
                        delete _scanner.CaseAttachment;
                    });
                }
            });
        }
        if (target.Products instanceof Array) {
            target.Products = target.Products.filter(
                p => p["flag"] !== undefined
            ).map(p => {
                delete p["flag"];
                return p;
            });
        }
        return target;
    }

    protected _getChangedProperties(): {
        [key: string]: { OldValue: any; NewValue: any };
    } {
        const changedProperties: {
            [key: string]: { OldValue: any; NewValue: any };
        } = {};
        const _getChangedProperties = (source: FacilityFormBase) => {
            if (source.children.length === 0) {
                const controls = source.form.controls;
                for (const name of Object.keys(controls)) {
                    const ctrl = controls[name];
                    // TODO: handle complex types such as ArrayItem, Date
                    if (ctrl.dirty && ctrl.value != this.originalValues[name]) {
                        changedProperties[name] = {
                            OldValue: this.originalValues[name],
                            NewValue: ctrl.value
                        };
                    }
                }
            } else {
                source.children.forEach(child => {
                    _getChangedProperties(child);
                });
            }
        };
        _getChangedProperties(this);
        return changedProperties;
    }

    btnSubmit_Click(isSubmit: boolean) {
        this.Validate();
        let data = this.applyValue(this.data.model, this);
        console.log("facility",this.data.model)
        
        if (this.data.model.FacilityStatusId === EFacilityStatus.Potential) {
            // Set default values only if the fields are empty
            if (!this.data.model.FacilityType) {
                this.data.model.FacilityType = {"FacilityTypeId":2,"Name":"Independent","DisplayOrder":2,"IsActive":true};
            }
            if (!this.data.model.FacilityTypeId) {
                this.data.model.FacilityTypeId = EFacilityType.Independent;
            }
            if (!this.data.model.FacilityCategory) {
                this.data.model.FacilityCategory = {"FacilityCategoryId":1,"Name":"Gym","DisplayOrder":1,"IsActive":true};
            }
            if (!this.data.model.FacilityCategoryId) {
                this.data.model.FacilityCategoryId = EFacilityCategory.Gym;
            }
            if (!this.data.model.Abn) {
                this.data.model.Abn = '1111';
            }
            if (!this.data.model.PaymentMethodId) {
                this.data.model.PaymentMethodId = EPaymentMethod.CreditCard;
            }
            if (!this.data.model.PaymentFrequencyId) {
                this.data.model.PaymentFrequencyId = EPaymentFrequency.Monthly;
            }
            if (!this.data.model.PaymentPreferenceId) {
                this.data.model.PaymentPreferenceId = EPaymentPreference.VisitDataOnly;
            }
            if (!this.data.model.PaymentDateId) {
                this.data.model.PaymentDateId = EPaymentDate.Other;
            }
        }
        data = this._processDataForSubmission(data);
        data.ModifiedBy = this.commonSvc.GetUser();
        this._logger.debug(data);
        localStorage.removeItem('SiteToken');
        if (!this.valid) {
            this.commonSvc.ShowPopupValidator(this.dialog);
            if (this._isDebugging) {
                const invalidCtrls = this._findInvalidControls(this.form);
                this._logger.debug("Invalid controls:\n" + invalidCtrls.join("\n"));
            }
            return;
        }

        if (
            this.data.mode === FacilityFormMode.Add ||
            this.data.mode === FacilityFormMode.Clone
        ) {
            this.Invoke(this.svc.create(data), {
                onSuccess: (result: DataResult<Facility>) => {
                    if (result.Success && result.Data.FacilityId > 0) {
                        this.CreateFacilityNote(result);
                    } else {
                        this.HandleResponseError(result);
                    }
                }
            });
        } else if (this.data.mode === FacilityFormMode.Edit) {
            this.commonSvc.FormDataChange = false;
            this.Invoke(this.svc.update(data), {
                onSuccess: (result: DataResult<FacilityUpdateDetails>) => {
                    if (result.Success) {
                        this.UpdateFacilityNote(result);
                    } else {
                        this.HandleResponseError(result);
                    }
                }
            });
        }

        this.LoadComplete();
    }

    private CreateFacilityNote(result) {
        this.commonSvc.FacilityNoteComponent.datasending.FacilityNotes.map((item, index) => {
            this.commonSvc.FacilityNoteComponent.datasending.FacilityNotes[index].FacilityId = result.Data.FacilityId;
        });
        this.Invoke(this.commonSvc.FacilityNoteComponent.SaveFacilityNote(), {
            onSuccess: (res) => {
                if (res.Success) {
                    MessageBox.ShowInfo(
                        this.dialog,
                        CommonMessage.DATA_SAVE_SUCCESS_MESSAGE
                    ).subscribe(data => {
                        this.router.navigate([
                            RouterConstant.NAVIGATOR_FACILITY_EDIT,
                            result.Data.FacilityId
                        ]);
                        this.commonSvc.FacilityNoteComponent.InitForm();
                    });
                } else {
                    this.HandleResponseError(res);
                }
            }
        });
    }

    private UpdateFacilityNote(result) {
        this.Invoke(this.commonSvc.FacilityNoteComponent.SaveFacilityNote(), {
            onSuccess: (res) => {
                if (res.Success) {
                    MessageBox.ShowInfo(
                        this.dialog,
                        CommonMessage.DATA_SAVE_SUCCESS_MESSAGE
                    ).subscribe(r => {
                        this.data.model = result.Data;
                        this.PatchValue(this.data.model, { emitEvent: false });
                        this.form.markAsPristine();

                        this.commonSvc.FacilityNoteComponent.InitForm();

                        // The following tabs need to be un-loaded so the next time the tab is opened,
                        // the "Load" method will be called and date will be refreshed: Products & Services,
                        // Employers in the Region, Photos.
                        const arrChildren = this.children.toArray();
                        arrChildren[FacilityTab.ProductsAndServices].UnLoad();
                        arrChildren[FacilityTab.EmployerAccess].UnLoad();
                        arrChildren[FacilityTab.Photos].UnLoad();

                        // Load current tab
                        arrChildren[this.stepper.selectedIndex].Load();

                        // In case the current tab is after the "Products & Services" tab, it needs to be
                        // loaded so the validation won't fail if user grants access for membership package(s).
                        if (this.stepper.selectedIndex >= FacilityTab.ProductsAndServices) {
                            arrChildren[FacilityTab.ProductsAndServices].Load();
                        }
                    });
                } else {
                    this.HandleResponseError(res);
                }
            }
        });
    }

    public SetLinearForTab() {
        if (this.data.mode === FacilityFormMode.Edit) {
            if (this.form.get('FacilityDetailsTab') && this.form.get('PaymentTab')) {
                if (this.form.get('FacilityDetailsTab').valid && this.form.get('PaymentTab').valid) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    /**
     * Sets the view and all fields to read only if any read only attribute on user role is true
     */
    private checkPrivilegesForReadOnly(): void {
        const rolePermissionService: RolePermissionService = this.injector.get(RolePermissionService);

        this.isReadonly = rolePermissionService.checkPrivilegesForReadOnly('View/edit facilities (Restrict to Read Only)');

        if (this.isReadonly) {
            this.form.disable();
        }
    }
}
