<div class="custom-file" [ngStyle]="buttonStyle">
  <input type="file" class="custom-file-input" [disabled]="isReadonly || isDisabled" [accept]="filterFile"
    (change)="fileEvent($event, requiresCheck)" onclick="this.value=null;" (click)="fpfileupload.value = null" #fpfileupload />
  <label class="px-5 py-2 btn btn-primary text-light" [ngStyle]="buttonStyle"
    [ngClass]="{ ngClassLabel: true, disabled: isDisabled }"><i class="fas fa-upload mr-2"></i>{{ buttonlabel }}</label>
</div>

<!-- Progress Bar Modal -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">
      {{ status }}: {{ percent }}%
    </h6>
  </div>
  <div class="modal-body" style="margin-bottom: 10px">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</ng-template>