import { CommonService } from 'src/app/services/common.service';
import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { FPAbstractComponent } from '@fp/components/base';
import { CommonString } from '@fp/constants';
import { StringHelper, Utils } from '@fp/helpers';
import { NgbDateFRParserFormatter } from '@fp/helpers/ngb-date-fr-parser-formatter';
import {
    CommonResponse, DataResult,
    EmployerMembershipPackageModel, MembershipPackagePackageViewModel, MembershipPackageTypeModel,
    Package, RequestPagination, ResultDataModel, SearchPaginationResult, ResultModel
} from '@fp/models';
import { EmployerService, MembershipPackagesService, MessageBox, PackageService } from '@fp/services';


@Component({
    selector: 'app-employer-membership-package',
    templateUrl: './membership-package.component.html',
    styleUrls: ['./membership-package.component.css']
})
export class EmployerMembershipPackageComponent extends FPAbstractComponent implements OnInit, OnDestroy {
    @Input() formGroup: UntypedFormGroup;
    @Input() isEditMode: boolean;
    @Input() EmployerId: number;
    @Input() isReadonly: boolean;

    Utils = Utils;
    commonString = CommonString;
    stringHelper = StringHelper;

    parentFocus = 0;

    // # Begin Membership Packages
    // Author: Trang Nguyen

    pagingPackage: RequestPagination = new RequestPagination();
    packageSearchResults: any[];
    isSpinerPackage = false;
    isClickAddMembershipPackage = false;
    isEditMembershipPackage = false;
    dataSourceMembershipPackage = new MatTableDataSource<EmployerMembershipPackageModel>();
    membershipPackagePackages: MembershipPackagePackageViewModel[] = [];
    indexDataUpdateMember: number;
    itemMembershipPackageSelected: EmployerMembershipPackageModel;
    isDuplicateMembershipType = false;
    membershipPackageIdDefault: number = null;
    membershipPackageChangedModal = false;
    displayedColumns: string[] = ['default', 'type', 'status', 'familyprice', 'singleprice', 'dependentprice', 'datepkgprice', 'action'];

    membershipPackageTypes: MembershipPackageTypeModel[] = [];
    pagingMembershipPackage: RequestPagination = new RequestPagination();
    MembershipPackageFormGroup: UntypedFormGroup;
    dateParserFormatter = new NgbDateFRParserFormatter();

    ngOnDestroy(): void {
        // throw new Error('Method not implemented.');
        this.dataSourceMembershipPackage = null;
        this.membershipPackagePackages = null;
        this.membershipPackageTypes = null;
        this.pagingMembershipPackage = null;
        this.membershipPackageIdDefault = null;
        this.parentFocus = null;
    }

    constructor(
        injector: Injector,
        private _formBuilder: UntypedFormBuilder,
        private memPackageService: MembershipPackagesService,
        private packageSvc: PackageService,
        private svc: EmployerService,
        private comsvr: CommonService
    ) {
        super(injector);
    }

    ngOnInit() {
        this.SetValidator();
    }

    private SetValidator() {
        this.MembershipPackageFormGroup = this._formBuilder.group({
            ddlMshipType: ['', Validators.required],
            ddlSelectPkage: [''],
            ddlMembershipStatus: ['', Validators.required],
            txtFmlPrice: ['', Validators.required],
            txtSglPrice: ['', Validators.required],
            txtDptPrice: ['', Validators.required],
            txtPkgDesc: [''],
        });
    }

    SearchPackageByName(event) {
        const val = event.target.value;
        this.pagingPackage.PageNumber = 0;
        this.pagingPackage.PageSize = 0;
        this.pagingPackage.OrderBy = 'name';
        this.pagingPackage.Params = [{ Name: 'name', Value: val }, { Name: 'status', Value: true }];
        if (val.length >= 3 && event.key !== 'ArrowUp' && event.key !== 'ArrowDown'
            && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && event.key !== 'Enter') {
            this.isSpinerPackage = true;
            this.InvokeNotProcess(
                this.packageSvc.search(this.pagingPackage),
                {
                    onSuccess: (res: DataResult<SearchPaginationResult<Package>>) => {
                        if (res.Success) {
                            this.isSpinerPackage = false;
                            this.UpdateAvailablePackages(res.Data.Results);
                        }
                    }
                },
            );
        }
    }

    public displayAutoPackageText(obj?: CommonResponse): string | undefined {
        return obj ? obj.Name : undefined;
    }

    public AddPackageOnModal(item: Package) {
        const index = this.packageSearchResults.indexOf(item);
        if (index > -1) {
            this.MembershipPackageFormGroup.get('ddlSelectPkage').setValue('');
            this.membershipPackagePackages.push({
                MembershipPackageId: 0,
                PackageId: item.Id,
                PackageName: item.Name,
            });
            this.packageSearchResults.splice(index, 1);
            this.membershipPackagePackages = this.membershipPackagePackages
                .sort((a, b) => parseInt(a.PackageName) - parseInt(b.PackageName));
        }
    }

    public RemovePackageOnModal(item: any) {
        const itemPackage = {
            Id: item.Id || item.PackageId,
            Name: item.Name || item.PackageName,
        };
        const index = this.membershipPackagePackages.indexOf(item);
        if (index > -1) {
            this.membershipPackagePackages.splice(index, 1);
            if (this.packageSearchResults) {
                this.packageSearchResults.push(itemPackage);
                this.packageSearchResults.sort((a, b) => a.Name > b.Name ? 1 : -1);
            }
        }

    }

    private UpdateAvailablePackages(packages: Package[]) {
        this.packageSearchResults = packages
            .filter(item => this.membershipPackagePackages.findIndex(p => p.PackageId == item.Id) < 0)
            .sort((a, b) => parseInt(a.Name) - parseInt(b.Name));
    }

    LoadDataInitMembershipPackage() {
        this.Invoke(
            this.memPackageService.getTypes(),
            {
                onSuccess: (res: DataResult<MembershipPackageTypeModel[]>) => {
                    if (res.Success) {
                        this.membershipPackageTypes = res.Data;
                    }
                }
            },
        );

        // check form employer page change or or
        this.MembershipPackageFormGroup.valueChanges.subscribe(data => {
            this.membershipPackageChangedModal = true;
        });
    }

    public getMembershipPackageTypesData() {
        return this.membershipPackageTypes;
    }

    private getParamsPaging = function (EmployerId) {
        const params = this.pagingMembershipPackage;
        params.pageSize = 0;
        params.Params = [
            {
                Name: 'employer_id',
                Value: EmployerId
            }
        ];
        params.OrderBy = 'name';
        params.ViewColumns = [
            'membership_package_type',
            'packages'
        ];
        return params;
    }

    LoadMembershipPackages() {
        const params = this.getParamsPaging(this.EmployerId);
        this.Invoke(
            this.memPackageService.search(params),
            {
                onSuccess: (res: DataResult<SearchPaginationResult<EmployerMembershipPackageModel>>) => {
                    if (res.Success) {
                        res.Data.Results.forEach(_item => { _item.LastPriceChangeDate = Utils.parseUTCDate(<string>_item.LastPriceChangeDate) });
                        this.dataSourceMembershipPackage = new MatTableDataSource<EmployerMembershipPackageModel>(res.Data.Results);
                        this.setMembershipPackageIdDefauld();
                    } else {
                        this.HandleResponseError(res);
                    }
                }
            },
        );
    }

    public getMembershipPackageData() {
        return this.dataSourceMembershipPackage.data;
    }

    private getNameFormMemberShipPackage(name: string) {
        return this.MembershipPackageFormGroup.get(name);
    }

    private getNameMembershipTypeById(id: number) {
        if (id) {
            return this.membershipPackageTypes.filter(item => item.MembershipPackageTypeId == id)[0].Name;
        }
        return '';
    }

    public isAddNewMembershipPackage = function () {
        for (let i = 0; i < this.dataSourceMembershipPackage.data.length; i++) {
            const item = this.dataSourceMembershipPackage.data[i];
            if (item.MembershipPackageId === 0) {
                return true;
            }
        }
        return false;
    };

    public IsInvalidFieldMemberPackage(name: string) {
        // if (this.stepper.selectedIndex == EmployerTab.MembershipPackages) {
        return this.getNameFormMemberShipPackage(name).invalid &&
            (this.getNameFormMemberShipPackage(name).dirty || this.getNameFormMemberShipPackage(name).touched
                || this.isClickAddMembershipPackage);
        // }
    }

    private isValidFormAddMemPackage() {
        return !this.MembershipPackageFormGroup.invalid && this.membershipPackagePackages.length > 0;
    }

    private isCheckDefauld() {
        for (let i = 0; i < this.dataSourceMembershipPackage.data.length; i++) {
            if (this.dataSourceMembershipPackage.data[i].IsDefault) {
                return true;
            }
        }
        return false;

    }

    private isDuplicatePackages(items) {
        const uniq = items
            .map((item) => {
                return { count: 1, PackageId: item.PackageId }
            })
            .reduce((a, b) => {
                a[b.PackageId] = (a[b.PackageId] || 0) + b.count;
                return a;
            }, {});

        const duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1);
        return duplicates.length > 0;
    }

    private CheckDuplicateMembershipType() {
        const dataSource = this.dataSourceMembershipPackage.data;
        const type = this.getNameFormMemberShipPackage('ddlMshipType').value;
        this.isDuplicateMembershipType = false;

        for (let i = 0; i < dataSource.length; i++) {
            if (dataSource[i].MembershipPackageTypeId == type) {
                if (this.isEditMembershipPackage && this.indexDataUpdateMember == i) {
                    this.isDuplicateMembershipType = false;
                } else {
                    const packages = dataSource[i].MembershipPackagePackages.concat(this.membershipPackagePackages);
                    this.isDuplicateMembershipType = this.isDuplicatePackages(packages);
                    if (this.isDuplicateMembershipType == true) {
                        return this.isDuplicateMembershipType;
                    }
                }

            }
        }
        return false;
    }

    private CheckActiveMembers(MembershipPackageId: number, callback: Function) {
        this.Invoke(
            this.memPackageService.checkActiveMembers(MembershipPackageId),
            {
                onSuccess: (res: DataResult<any>) => {
                    if (res.Success) {
                        callback(res.Data);
                    }
                }
            },
        );
    }

    private CloseModalAddMembershipPackage() {
        document.getElementById('btnCloseModal').click();
    }

    public SubmitAddMembershipPackage() {
        this.isClickAddMembershipPackage = true;
        if (!this.isValidFormAddMemPackage()) {
            return;
        }

        // check duplicate Membership Type and PackageId
        if (this.CheckDuplicateMembershipType()) {
            return;
        }

        let username = this.comsvr.GetUser();
        const params = {
            MembershipPackageId: 0,
            EmployerId: this.EmployerId,
            MembershipPackageType: {
                MembershipPackageTypeId: this.getNameFormMemberShipPackage('ddlMshipType').value,
                Name: this.getNameMembershipTypeById(this.getNameFormMemberShipPackage('ddlMshipType').value),
            },
            MembershipPackageTypeId: this.getNameFormMemberShipPackage('ddlMshipType').value,
            MembershipPackagePackages: this.membershipPackagePackages,
            IsDefault: false,
            IsActive: this.getNameFormMemberShipPackage('ddlMembershipStatus').value == 'true' ||
                this.getNameFormMemberShipPackage('ddlMembershipStatus').value == true ? true : false,
            FamilyPrice: Number(this.getNameFormMemberShipPackage('txtFmlPrice').value),
            SinglePrice: Number(this.getNameFormMemberShipPackage('txtSglPrice').value),
            DependantPrice: Number(this.getNameFormMemberShipPackage('txtDptPrice').value),
            Description: this.getNameFormMemberShipPackage('txtPkgDesc').value,
            ModifiedBy: username
        };

        if (this.isEditMembershipPackage) {
            const lenDataSource = this.dataSourceMembershipPackage.data.length;
            for (let j = 0; j < lenDataSource; j++) {
                // update item selected
                if (j == this.indexDataUpdateMember) {
                    // membership package existed
                    const MembershipPackageId = this.itemMembershipPackageSelected.MembershipPackageId;
                    const IsDefaultMemPackage = this.itemMembershipPackageSelected.IsDefault;
                    // check memebership package default when change status to inactive
                    if (this.itemMembershipPackageSelected.IsDefault == true && params.IsActive == false) {
                        MessageBox.ShowError(this.dialog,
                            'This membership package is currently set as the default package for the employer. \
                            Please select another package as the default package. \
                            You will then be able to change the status of this package to inactive');
                        return;
                    }
                    if (MembershipPackageId > 0) {
                        // check active members
                        if (!params.IsActive) {
                            const that = this;
                            this.CheckActiveMembers(MembershipPackageId, function (active) {
                                if (active) {
                                    params.IsActive = true; // reset
                                    const memberSelected = Object.assign(that.itemMembershipPackageSelected, params);
                                    memberSelected.MembershipPackageId = MembershipPackageId;
                                    memberSelected.IsDefault = IsDefaultMemPackage;
                                    if (!that.isCheckDefauld()) {
                                        if (memberSelected.IsActive) {
                                            memberSelected.IsDefault = true;
                                        }
                                    }
                                    that.dataSourceMembershipPackage.data[j] = memberSelected;
                                    MessageBox.ShowError(that.dialog,
                                        'This package cannot be made inactive as there are \
                                        active employers and members using this package.');
                                    return;
                                } else {
                                    that.CloseModalAddMembershipPackage();
                                }
                            });
                        } else {
                            this.CloseModalAddMembershipPackage();
                        }
                        const memberSelected = Object.assign(this.itemMembershipPackageSelected, params);
                        memberSelected.MembershipPackageId = MembershipPackageId;
                        memberSelected.IsDefault = IsDefaultMemPackage;
                        if (!this.isCheckDefauld()) {
                            if (memberSelected.IsActive) {
                                memberSelected.IsDefault = true;
                            }
                        }
                        this.dataSourceMembershipPackage.data[j] = memberSelected;
                    } else {
                        // membership not yet existed
                        const memberSelected = Object.assign(this.itemMembershipPackageSelected, params);
                        memberSelected.IsDefault = IsDefaultMemPackage;
                        if (!this.isCheckDefauld()) {
                            if (memberSelected.IsActive) {
                                memberSelected.IsDefault = true;
                            }
                        }
                        this.dataSourceMembershipPackage.data[j] = memberSelected;
                        this.CloseModalAddMembershipPackage();
                    }
                }
            }
            this.dataSourceMembershipPackage.data = this.dataSourceMembershipPackage.data;
        } else {
            if (!this.isCheckDefauld()) {
                // add defauld
                if (params.IsActive) {
                    // params.IsDefault = true;
                }
            }
            this.dataSourceMembershipPackage.data = this.dataSourceMembershipPackage.data.concat([params]);
            this.CloseModalAddMembershipPackage();
        }
    }

    public OnAddMembershipPackage() {
        // reset data
        this.isEditMembershipPackage = false;
        this.MembershipPackageFormGroup.reset();
        this.packageSearchResults = [];

        this.getNameFormMemberShipPackage('ddlMshipType').setValue('');
        this.getNameFormMemberShipPackage('ddlMshipType').setValue('');
        this.getNameFormMemberShipPackage('ddlMembershipStatus').setValue(true);
        this.getNameFormMemberShipPackage('txtFmlPrice').setValue('');
        this.getNameFormMemberShipPackage('txtSglPrice').setValue('');
        this.getNameFormMemberShipPackage('txtDptPrice').setValue('');
        this.getNameFormMemberShipPackage('txtPkgDesc').setValue('');

        this.membershipPackagePackages = [];
        this.isClickAddMembershipPackage = false; // reset flag
        this.itemMembershipPackageSelected = null;
        this.isDuplicateMembershipType = false;
    }

    public OnEditMembershipPackage(item) {
        // const item = Object.assign({}, itemData);
        document.getElementById('btn-add-membership-package').click();

        this.isClickAddMembershipPackage = false; // reset flag

        const index = this.dataSourceMembershipPackage.data.indexOf(item);
        this.indexDataUpdateMember = index;
        this.itemMembershipPackageSelected = item;

        // set data to form
        this.getNameFormMemberShipPackage('ddlMshipType').setValue(item.MembershipPackageTypeId);
        this.getNameFormMemberShipPackage('ddlMshipType').setValue(item.MembershipPackageTypeId);
        this.getNameFormMemberShipPackage('ddlMembershipStatus').setValue(item.IsActive);
        this.getNameFormMemberShipPackage('txtFmlPrice').setValue(item.FamilyPrice);
        this.getNameFormMemberShipPackage('txtSglPrice').setValue(item.SinglePrice);
        this.getNameFormMemberShipPackage('txtDptPrice').setValue(item.DependantPrice);
        this.getNameFormMemberShipPackage('txtPkgDesc').setValue(item.Description);
        this.membershipPackagePackages = item.MembershipPackagePackages.slice(0);
        this.isEditMembershipPackage = true;
    }

    public handleClickDefault(event: Event, item) {
        this.membershipPackageChangedModal = true;
        if (!item.IsActive) {
            event.preventDefault();
            event.stopImmediatePropagation();
            MessageBox.ShowError(this.dialog, 'Inactive packages cannot be made as default.').subscribe(r => {
                // fixbug on IE
                const dataSource = this.dataSourceMembershipPackage.data;
                for (let i = 0; i < dataSource.length; i++) {
                    if (i == this.membershipPackageIdDefault && dataSource[i].IsActive) {
                        // if (dataSource[i].MembershipPackageId == this.membershipPackageIdDefault && dataSource[i].IsActive) {
                        dataSource[i].IsDefault = true;
                        this.dataSourceMembershipPackage.data[i] = dataSource[i];
                        (<HTMLInputElement>document.getElementById('rd_membership_package_default_' + i)).checked = true;
                    }
                }
                this.dataSourceMembershipPackage.data = this.dataSourceMembershipPackage.data;
            });
            return;
        }
    }
    public handleChangeDefault(event, item) {
        const target = event.target;
        const index = this.dataSourceMembershipPackage.data.indexOf(item);
        if (target.checked) {
            this.dataSourceMembershipPackage.data[index] = this.dataSourceMembershipPackage.data
                .filter((itemMp, i) => (i == index) ? itemMp.IsDefault = true : itemMp.IsDefault = false)[0];
        }
        this.dataSourceMembershipPackage.data = this.dataSourceMembershipPackage.data;
        if (item.IsActive) {
            this.setMembershipPackageIdDefauld();
        }
    }

    private setMembershipPackageIdDefauld() {
        const dataSource = this.dataSourceMembershipPackage.data;
        if (!dataSource) {
            return '';
        }
        for (let i = 0; i < dataSource.length; i++) {
            if (dataSource[i].IsDefault == true) {
                // this.membershipPackageIdDefault = dataSource[i].MembershipPackageId;
                this.membershipPackageIdDefault = i;
                return;
            }
        }
    }

    public UpdateMembershipPackage(EmployerId: number, callback?: Function) {
        const memPgksData = this.dataSourceMembershipPackage.data;
        const userName = this.comsvr.GetUser();
        console.log('Update Membership Package User Name: ', userName);

        memPgksData.forEach(membershipPackage => {
            membershipPackage.ModifiedBy = userName;
        });

        if (memPgksData && memPgksData.length > 0 && this.membershipPackageChangedModal) {
            memPgksData.filter(memPgk => memPgk.EmployerId = EmployerId);
            this.Invoke(
                this.svc.updateMembershipPackage(memPgksData),
                {
                    onSuccess: (res: ResultDataModel) => {
                        if (res.Success) {
                            this.Invoke(this.svc.updateEmployerAccesstoFacility(this.EmployerId), {
                                onSuccess: (ret: ResultModel) => {
                                    if (ret.Success) {
                                        if (callback) {
                                            callback();
                                        }
                                        this.membershipPackageChangedModal = false;
                                    }
                                }
                            });
                        } else {
                            this.HandleResponseError(res);
                        }
                    },
                    onError: err => {
                    }
                },
            );
        } else {
            if (callback) {
                callback();
            }
        }
    }

    _formatDate(date: Date) {
        if (!date) {
            return null;
        }
        return this.dateParserFormatter.format({
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear()
        })
    }

    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }
    onBlurParent(focusNumbr) {
        this.parentFocus = focusNumbr;
    }
    getClassFocused(vlFocused) {
        if (this.parentFocus == vlFocused) {
            return 'focused';
        } else {
            return '';
        }
    }

}
