import { forwardRef, Injector, Provider, QueryList, ViewChildren, Directive } from '@angular/core';
import { FacilityUpdateDetails } from 'src/app/models';
import { FPFormBaseComponent, ModelBasedFormSharedData } from './fp-form-base-component';

@Directive()
export abstract class FacilityFormBase extends FPFormBaseComponent<FacilityFormSharedData> {
    @ViewChildren(FacilityFormBase) protected _children: QueryList<FacilityFormBase>;
    /**
     * List of direct descendant components.
     * @readonly
     */
    get children(): QueryList<FacilityFormBase> { return this._children; }

    static provideExisting(type: any): Provider {
        return <Provider>{ provide: FacilityFormBase, useExisting: forwardRef(() => type) };
    }

    constructor(injector: Injector) {
        super(injector);
        this.data.model = new FacilityUpdateDetails();
    }
}

export class FacilityFormSharedData extends ModelBasedFormSharedData<FacilityUpdateDetails> {
    mode: FacilityFormMode;
    constructor() {
        super();
        this.model.FacilityId = 0;
        this.mode = FacilityFormMode.View;
    }
}

export enum FacilityFormMode {
    View,
    Add,
    Edit,
    Clone
}
