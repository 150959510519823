<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Bot Logs</h4>

<div>
    <button id="refreshBtn" (click)="refreshLogs()" class="btn btn-primary">
        Refresh
    </button>
</div>

<div class="border-default rounded-10 border-2">
    <div class="tablecontainer">
        <table mat-table [dataSource]="dataSource" matSort matSortDirection="desc" class="rounded-10">

            <ng-container matColumnDef="BotName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Bot Name
                </th>
                <td mat-cell *matCellDef="let element">
                    <!-- {{ element.BotNameStartTime | dateTimeFormatPipe }} -->
                    {{ element.BotNameStartTime }}
                </td>
            </ng-container>

            <ng-container matColumnDef="BotNameStartTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Start Time
                </th>
                <td mat-cell *matCellDef="let element">
                    <!-- {{ element.BotNameStartTime | dateTimeFormatPipe }} -->
                    {{ element.BotNameStartTime }}
                </td>
            </ng-container>

            <ng-container matColumnDef="BotNameEndTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    End Time
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.BotNameEndTime }}
                </td>
            </ng-container>

            <ng-container matColumnDef="BotNameStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Status
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.BotNameStatus }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</div>