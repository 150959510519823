<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">
    View Payments and Invoices
</h4>
<div class="mt-lg-4">
    <form [formGroup]="paymentRequestForm">
        <div class="bg-secondary p-3 mb-4 rounded-10">
            <p>This screen is used to view payments made by Fitness Passport to you. To view the payment details, please
                complete the following steps:</p>
            <ol>

                <li>Select your facility (option only displayed if you have access to multiple facilities).</li>
                <li>Select the service from the list.</li>
                <li>Select the payment period you wish to review.</li>
                <li>Click the SEARCH button.</li>
            </ol>
            <p>The results displayed will show you a summary of payments and visitation made to your facility for the
                selected time period. If you have any questions or concerns please contact your facility support team at
                <a style="color: blue;" href="mailto:fitness@fitnesspassport.com.au">fitness@fitnesspassport.com.au</a>
            </p>
        </div>
        <div class="form-group row" [hidden]="AvailableFacilities.length===1">
            <label for="ddlFacility" class="col-md-3 col-lg-2 col-form-label">Available Facilities</label>
            <div class="col-md-8 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <select class="form-control" id="ddlFacility" formControlName="ddlFacility"
                        (change)="ddlFacility_Change($event)">
                        <option value="" selected>Select available facilities</option>
                        <option value="-1">View All</option>
                        <option *ngFor="let f of AvailableFacilities" [value]="f.FacilityId">{{ f.Name }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="ddlService" class="col-md-3 col-lg-2 col-form-label">Service</label>
            <div class="col-md-8 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <select class="form-control" id="ddlService" formControlName="ddlService"
                        (change)="ddlService_Change($event)">
                        <option value="" selected>Select once or more service(s)</option>
                        <option *ngFor="let s of AvailableServices" [value]="s.Id" [disabled]="ServiceDisabled(s)">
                            {{ s.Name }}</option>
                    </select>
                    <div class="mt-2 slctSelectedRoles w-100 border-2 rounded-10 border-default text-default"
                        *ngIf="SelectedServices.length>0">
                        <div class="d-flex border-default border-bottom py-1 align-content-center"
                            *ngFor="let ss of SelectedServices">
                            <span class="text-primary d-flex align-items-center" (click)="UnsetSelectedService(ss)">
                                <i class="fas fa-minus-circle fa-2x"></i>
                            </span>
                            <p class="d-flex align-items-center m-0 ml-2">{{ss.Name}}</p>
                        </div>
                    </div>
                    <mat-spinner [diameter]="14" color="primary" *ngIf="serviceSpinner"></mat-spinner>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-lg-2 col-form-label">Payment Period<span class='text-danger'>*</span></label>
            <div class="col-md-8 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <div class="d-flex align-items-center">
                        <div class="mr-2">From</div>
                        <div class="input-group pr-2">
                            <input class="form-control" ngbDatepicker #paymentFromDate="ngbDatepicker"
                                placeholder="DD-MMM-YYYY" formControlName="dtpFromDate"
                                [minDate]="{year: 1900, month: 1, day: 1}"
                                (focusout)="UpdateValidateDateRange(['dtpFromDate','dtpToDate'])"
                                (dateSelect)="UpdateValidateDateRange(['dtpFromDate','dtpToDate'])">
                            <div class="input-group-append">
                                <button class="btn bg-white" (click)="paymentFromDate.toggle()" type="button"><i
                                        class="far fa-calendar-alt"></i></button>
                            </div>
                        </div>
                        <div class="mx-2">To</div>
                        <div class="input-group pl-2">
                            <input class="form-control" ngbDatepicker #paymentToDate="ngbDatepicker"
                                class="form-control" formControlName="dtpToDate" placeholder="DD-MMM-YYYY"
                                [minDate]="{year: 1900, month: 1, day: 1}"
                                (focusout)="UpdateValidateDateRange(['dtpFromDate','dtpToDate'])"
                                (dateSelect)="UpdateValidateDateRange(['dtpFromDate','dtpToDate'])">
                            <div class="input-group-append">
                                <button class="btn bg-white" (click)="paymentToDate.toggle()" type="button"><i
                                        class="far fa-calendar-alt"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-md-3 col-lg-2 col-form-label"></label>
            <div class="col-md-8 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <div class="d-flex align-items-center">
                        <div class="mr-5"></div>
                        <div class="pr-2" id="msgFromDate" style="width:270px !important">
                            <div *ngIf="IsControlInvalid(paymentRequestForm,'dtpFromDate')">
                                <small *ngIf="paymentRequestForm.get('dtpFromDate').hasError('required')"
                                     class="invalid-feedback d-block">From Date is
                                    required</small>
                                <small *ngIf="paymentRequestForm.get('dtpFromDate').hasError('rangedateinvalid')"
                                     class="invalid-feedback d-block">From Date should be
                                    less than or equal to the To Date</small>
                                <small *ngIf="paymentRequestForm.get('dtpFromDate').hasError('mindateinvalid')"
                                     class="invalid-feedback d-block">From Date is
                                    invalid</small>
                            </div>
                        </div>
                        <div class="mx-2"></div>
                        <div class="pl-2" id="msgToDate" style="width:270px !important">
                            <div *ngIf="IsControlInvalid(paymentRequestForm,'dtpToDate')">
                                <small *ngIf="paymentRequestForm.get('dtpToDate').hasError('required')"
                                     class="invalid-feedback d-block">To Date is
                                    required</small>
                                <small *ngIf="paymentRequestForm.get('dtpToDate').hasError('rangedateinvalid')"
                                     class="invalid-feedback d-block">To Date should be
                                    greater than or equal to the From Date</small>
                                <small *ngIf="paymentRequestForm.get('dtpToDate').hasError('mindateinvalid')"
                                     class="invalid-feedback d-block">To Date is
                                    invalid</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row mt-4">
            <div class="col-md-8 col-lg-5 offset-md-3 offset-lg-2">
                <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="btnSearch_Click($event)"><i
                        class="fas fa-search mr-2"></i>
                    Search</button>
            </div>
        </div>
        <div class="my-4">
            <div class="border-default rounded-10 border-2">
                <div class="px-2 fptablefreezeheadrow">
                    <mat-table #table class="fptablehead fptablehead-scrolly" matSort [dataSource]="bogusDataSource"
                        matSortActive="Service" matSortDirection="desc" matSortStart="desc" matSortDisableClear
                        (matSortChange)="sortChange($event)">
                        <!-- Name Column -->
                        <ng-container matColumnDef="ServiceName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Service</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.ServiceName}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="PaymentPeriodEndDisplay">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.PaymentPeriodEndDisplay}} </mat-cell>
                        </ng-container>

                        <!-- <ng-container matColumnDef="VisitCount">
                            <mat-header-cell *matHeaderCellDef>Visit Count</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.VisitCount}} </mat-cell>
                        </ng-container> -->

                        <ng-container matColumnDef="PaymentCalculationMethod">
                            <mat-header-cell *matHeaderCellDef>Calculation Method</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.PaymentCalculationMethod}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Amount">
                            <mat-header-cell *matHeaderCellDef>$ Amount</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element.Amount}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="InvoiceNumber">
                            <mat-header-cell *matHeaderCellDef>Invoice</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.InvoiceNumber}} <a href="#"><i
                                        class="fas fa-download"></i></a>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="PaymentRequestStatusDisplay">
                            <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ element.PaymentRequestStatusDisplay }}
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </mat-table>

                    <mat-table #table class="fpscrolly height3 d-block" matSort [dataSource]="InvoiceMatDataSource">
                        <!-- Name Column -->

                        <ng-container matColumnDef="ServiceName">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.ServiceName}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="PaymentPeriodEndDisplay">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.PaymentPeriodEndDisplay}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="VisitCount">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.VisitCount}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="PaymentCalculationMethod">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.PaymentCalculationMethod}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Amount">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.Amount}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="InvoiceNumber">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.InvoiceNumber}}
                                <a (click)="saveAs(element)" *ngIf="element.PaymentRequestStatusId===6"><i
                                        class="fas fa-download"></i></a>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="PaymentRequestStatusDisplay">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{ element.PaymentRequestStatusDisplay }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="tickBoxes">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let row; let i = index; let element">
                                <div class="custom-control custom-checkbox" *ngIf="element.PaymentRequestStatusId===5">
                                    <input id='chk_{{i}}' type="checkbox" class="custom-control-input"
                                        (change)="CheckingBox_Change($event,element)"
                                        [checked]="element.CheckedForApproval">
                                    <label class="custom-control-label" for='chk_{{i}}'>&nbsp;</label>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns" [style.min-height]="0"></mat-header-row>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </mat-table>
                    <mat-toolbar [hidden]="InvoiceMatDataSource.data.length>0" class="bg-white">
                        <mat-toolbar-row class="text-muted d-block p-2 bg-light text-center">
                            {{ CommonString.TABLE_NO_RECORD_FOUND }}
                        </mat-toolbar-row>
                    </mat-toolbar>
                </div>
            </div>
        </div>
        <!-- <button *ngIf="InvoiceMatDataSource.data.length>0" class="px-2 px-lg-5 py-2 btn btn-primary" type="button"
            (click)="btnApprove_Click($event)"><i class="fas fa-check-circle mr-2"></i>
            Approve & Generate tax invoice</button> -->
        <div [hidden]="true">
            <app-fp-file-upload #ReportUpload buttonlabel="Upload Photo" ngInputFile="w-100" maxfilesize="10"
                (AllCompleted)="AllCompleted($event)" ignoredebouce="true"
                style="width: 0px !important; height: 0px !important;">
            </app-fp-file-upload>
        </div>
    </form>
</div>