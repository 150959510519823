<div [formGroup]="form">
    <ng-template #requiredFieldMsgTmpl let-ctrl="control" let-ctrlName="fieldName"
        let-validateOnTouch="validateOnTouch || false">
        <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
            <small *ngIf="ctrl.errors['required']" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_REQUIRED, ctrlName)}}</small>
        </div>
    </ng-template>
    <div formGroupName="Cheque" *ngIf="form.get('Cheque').value != null">
        <div class="form-group row">
            <label for="txtPayeeID" class="col-md-3 col-lg-2 col-form-label">Payee ID<span
                    class="text-danger">*</span></label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <input type="text" class="form-control" formControlName="PayeeId"
                        [restrictedPattern]="'^[a-zA-Z0-9]{0,20}$'"
                        [class.is-invalid]="!isControlValid(form.get('Cheque.PayeeId'))" id="txtPayeeID" maxlength="20">
                    <ng-container
                        *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('Cheque.PayeeId'), fieldName: 'Payee ID'}">
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>