import { Component, OnInit ,Injector } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource } from '@angular/material/table';
import { SearchPaginationRequest } from '@fp/models/search-pagination-request.model';
import { CommonService } from '@fp/services';
import { AuditLoggingService } from "@fp/services/audit-logging.service";
import { PageEvent } from '@angular/material/paginator';
import { CommonString, RouterConstant, APIConstant } from '@fp/constants';
import { DataResult, SearchPaginationResult } from '@fp/models';
import { AuditBankDetailRecord } from '@fp/models/audit-logging.model';
import { FPAbstractComponent } from '@fp/components/base';

@Component({
  selector: 'app-bank-code-history-component',
  templateUrl: './bank-code-history-component.component.html',
  styleUrls: ['./bank-code-history-component.component.css']
})
export class BankCodeHistoryComponentComponent extends FPAbstractComponent implements OnInit {

  dataSourceHistory: MatTableDataSource<any>;
  bogusDataSource = new MatTableDataSource<any>(null);
  commonString = CommonString;
  displayedColumns: string[];
  pageSizeOptions: number[] = [10, 20, 50];
  pageSize: number;
  length: number;
  paging: SearchPaginationRequest = new SearchPaginationRequest();

  constructor(
    injector: Injector,
    public activeModal: NgbActiveModal, 
    private cmsrv: CommonService, 
    private auditLoggingService: AuditLoggingService) {
    super(injector);
   }

  ngOnInit(): void {
    this.paging.PageNumber = 1;
    this.paging.PageSize = 10;
    this.displayedColumns = ['User', 'Date']
    this.loadHistoryData();
  }

  paginatorChange(event: PageEvent) {
    this.paging.PageNumber = event.pageIndex + 1;
    this.paging.PageSize = event.pageSize
    this.loadHistoryData();
  }

  loadHistoryData() {

    this.cmsrv.StartProgressBar();

    const encryptedtoken = this.cmsrv.E_FP_RequestData(this.cmsrv.GetToken());

        this.paging.Params = [
            { Name: 'EventName', Value: this.commonString.AuditBulkUploadBankCode}
        ];

        const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(this.paging));
        var apiUrl = APIConstant.API_AUDIT_HISTORY;
        this.auditLoggingService.postData(
            apiUrl,
            (data: any) => {
                const rdata: DataResult<SearchPaginationResult<AuditBankDetailRecord>> = data;
                if (rdata != null) {
                    if (rdata.Success) {
                        this.dataSourceHistory = new MatTableDataSource<AuditBankDetailRecord>(rdata.Data.Results);
                        this.length = rdata.Data.TotalItem;
                    } else {
                        this.dataSourceHistory = new MatTableDataSource<AuditBankDetailRecord>();
                        this.HandleResponseError(rdata);
                    }
                }

                this.cmsrv.StopProgressBar();
            },
            { "Data": encrypteddata, "Header" : encryptedtoken }
        );
    
  }

}
