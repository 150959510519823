<div [formGroup]="form">
    <ngb-accordion #acc="ngbAccordion" activeIds="pnlContract">
        <ngb-panel id="pnlContract">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fa fa-check': contractSection?.valid}"></i>
                Contracts
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-employer-contract [data]="data" [form]="getControl('ContractSection')" #contractSection></app-employer-contract>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>