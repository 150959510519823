import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, OnDestroy, HostBinding, Optional, Self } from '@angular/core';
import { FormBuilder, FormGroup, NgControl, ControlValueAccessor } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

import { Subject } from 'rxjs';

@Component({
  selector: 'app-multiple-choice-input',
  templateUrl: './multiple-choice-input.component.html',
  styleUrls: ['./multiple-choice-input.component.css'],
  providers: [ { provide: MatFormFieldControl, useExisting: MultipleChoiceInputComponent } ]
  })
export class MultipleChoiceInputComponent implements MatFormFieldControl<any>, ControlValueAccessor, OnDestroy {
  formGroup: FormGroup;
  stateChanges = new Subject<void>();

  @Input() questionTitle: string;
  @Input() questionNumber: string;
  @Input() options: any = []

  private _value: any = [];
  @Input()
  get value(): any {
    return this._value;
  }
  set value(newValue: any) {
    if (newValue !== this._value) {
      this._value = newValue;
      this._onChange(newValue);
    }

    if (newValue !== this.formGroup.get('internalControl').value) {
      this.formGroup.setValue({ internalControl: newValue });
    }
  }

  constructor(
    formBuilder: FormBuilder,
    private focusMonitor: FocusMonitor, 
    private elementRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl) { 
      this.ngControl.valueAccessor = this; 
    }

    this.formGroup = formBuilder.group({
      internalControl: []
    });

    focusMonitor.monitor(elementRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    this.formGroup.controls['internalControl'].valueChanges.subscribe(newValue => {
      this.value = newValue;
    });
  }

  focused = false;

  get errorState(): boolean {
    return !!this.ngControl.errors;
  }

  controlType = 'multiple-choice';

  static nextId = 0;
  @HostBinding() id = `{controlType}-${MultipleChoiceInputComponent.nextId++}`;

  get empty() {
    if (this.multiple) {
      return !Array.isArray(this.formGroup.value.internalControl) 
          || this.formGroup.value.internalControl.length == 0 ;
    }
    return !this.formGroup.value.internalControl
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _placeholder: string;
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(newPlaceholder) {
    this._placeholder = newPlaceholder;
    this.stateChanges.next();
  }

  _required = false;
  @Input()
  get required() {
    return this._required;
  }
  set required(newRequired) {
    this._required = coerceBooleanProperty(newRequired);
    this.stateChanges.next();
  }

  private _disabled = false;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(newDisabled) {
    this._disabled = coerceBooleanProperty(newDisabled);
    if (this._disabled) {
      this.formGroup.controls['internalControl'].disable();
    } else {
      this.formGroup.controls['internalControl'].enable();
    }
    this.stateChanges.next();
  }
  
  private _multiple = false;
  @Input()
  get multiple() {
    return this._multiple;
  }
  set multiple(newMultiple) {
    this._multiple = coerceBooleanProperty(newMultiple);
    this.stateChanges.next();
  }


  @HostBinding('attr.aria-describedby') describedBy = '';
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).id !== 'internalControl') {
      this.elementRef.nativeElement.querySelector('#internalControl').focus();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  // ControlValueAccessor
  writeValue(newValue: any): void {
    if(newValue){
      this.value = newValue;
    }
  }
  
  _onChange: (value: any) => void = () => {};
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  // not called!
  _onTouched = () => {};
  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }

  checkBoxValueChange(checked, value) {
    let v = this._value || []
    if (checked){
      v.push(value);
    }
    else {
      const i = v.indexOf(value)
      if(i > -1){
        v.splice(i, 1);
      }
    }
    this._value = v
    this._onChange(v)
  }

}

