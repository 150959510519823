<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100" src="./assets/images/fitnesspassport-home.jpg" alt="First slide">
      <div class="carousel-caption bg-dark-50 py-5 px-5 d-none d-md-block">
        <h5>Lorem ipsum dolor sit amet</h5>
        <p>Aenean aliquam elit eu mauris ornare,quis malesuada est semper.</p>
        <p>
          <a role="button" href="#" class="btn btn-primary">More information</a>
        </p>
      </div>
    </div>
    <!-- <div class="carousel-item">
      <img class="d-block w-100" src="./assets/images/swimming-slider-size.jpg" alt="First slide">
      <div class="carousel-caption bg-dark-50 py-5 px-5 d-none d-md-block">
        <h5>Pellentesque finibus quam quis risus fermentum ullamcorper</h5>
        <p>Curabitur non felis ex. Morbi ut nunc at ipsum dapibus faucibus. Mauris id pharetra magna. Donec tristique turpis
          nec congue efficitur. Ut interdum lacus purus, in efficitur nibh iaculis sed. Nulla enim orci, lobortis molestie
          euismod id, posuere vitae lectus.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="./assets/images/weights-slider-size.jpg" alt="First slide">
      <div class="carousel-caption bg-dark-50 py-5 px-5 d-none d-md-block">
        <h5>Mauris rutrum lorem et rutrum lobortist</h5>
        <p>Cras justo ipsum, mollis ac mauris sed, vestibulum bibendum neque. Mauris rutrum lorem et rutrum lobortis. Donec
          maximus, ex in tempor molestie, nunc quam tristique nunc, sed malesuada tellus eros ut lacus. Nulla metus nibh,
          gravida a massa sit amet, tincidunt mollis purus. Cras eget ex cursus, dapibus ante tristique, fringilla sem. Donec
          at fringilla eros. Pellentesque dolor dolor, tristique nec pellentesque quis, tincidunt vel dui.</p>
      </div>
    </div> -->
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>

<section class="py-5">
  <div class="container py-5">
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="card mb-2">
          <i class="py-5 far fas fa-cubes d-block mx-auto fa-5x text-success"></i>
          <h3 class="h3 font-weight-light text-center text-primary">Affordable
            <br>Packages</h3>
          <p class="lead p-4 text-center">
            Nulla metus nibh, gravida a massa sit amet, tincidunt mollis purus. Cras eget ex cursus, dapibus ante tristique, fringilla
            sem.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="card mb-2">
          <i class="py-5 fab fa-buromobelexperte d-block mx-auto fa-5x text-secondary"></i>
          <h3 class="h3 font-weight-light text-center text-primary">Flexible
            <br>Options</h3>
          <p class="lead p-4 text-center">
            Nulla metus nibh, gravida a massa sit amet, tincidunt mollis purus. Cras eget ex cursus, dapibus ante tristique, fringilla
            sem.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="card mb-2">
          <i class="py-5 fas fa-tasks d-block mx-auto fa-5x text-warning"></i>
          <h3 class="h3 font-weight-light text-center text-primary">Program
            <br>Variety</h3>
          <p class="lead p-4 text-center">
            Nulla metus nibh, gravida a massa sit amet, tincidunt mollis purus. Cras eget ex cursus, dapibus ante tristique, fringilla
            sem.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
        <div class="card mb-2">
          <i class="py-5 fas fa-user-friends d-block mx-auto fa-5x text-info"></i>
          <h3 class="h3 font-weight-light text-center text-primary">Personalised
            <br>Service</h3>
          <p class="lead p-4 text-center">
            Nulla metus nibh, gravida a massa sit amet, tincidunt mollis purus. Cras eget ex cursus, dapibus ante tristique, fringilla
            sem.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="py-5 bg-light">
  <div class="container py-5 container-quote-icon">
    <div id="peopelComment" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner people-comment" role="listbox">
        <div class="carousel-item active text-center p-4">
          <blockquote class="blockquote">
            <p class="mb-0 lead font-italic">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eu libero felis.
              Duis rhoncus, ligula vel viverra sodales, dolor purus dictum enim, vitae dapibus mauris ligula quis mauris.
              Sed magna ante, iaculis in ante at, scelerisque lacinia dolor. Nulla sit amet lobortis lorem. Nunc quis eros
              vel sapien facilisis bibendum vitae id nibh. Fusce eget enim id diam pellentesque mattis. Duis interdum, leo
              finibus suscipit accumsan, mi sapien convallis dolor, sit amet aliquet ante purus sit amet magna. Nunc vehicula
              lorem sed sapien iaculis venenatis id a lorem. Nam sit amet lectus erat. Etiam eu mauris erat. Ut dapibus eu
              metus hendrerit blandit. Praesent suscipit metus erat, sed posuere quam laoreet sed.</p>
            <footer class="blockquote-footer"> Balbina Marika a
              <cite title="Housewife">Housewife</cite>
            </footer>
          </blockquote>
        </div>
        <div class="carousel-item text-center p-4">
          <blockquote class="blockquote">
            <p class="mb-0 lead font-italic"> Cras justo ipsum, mollis ac mauris sed, vestibulum bibendum neque. Mauris rutrum
              lorem et rutrum lobortis. Donec maximus, ex in tempor molestie, nunc quam tristique nunc, sed malesuada tellus
              eros ut lacus. Nulla metus nibh, gravida a massa sit amet, tincidunt mollis purus. Cras eget ex cursus, dapibus
              ante tristique, fringilla sem. Donec at fringilla eros. Pellentesque dolor dolor, tristique nec pellentesque
              quis, tincidunt vel dui .</p>
            <footer class="blockquote-footer"> Seweryn Hyeon-Ju a
              <cite title="Font-end Developer">Font-end Developer</cite>
            </footer>
          </blockquote>
        </div>
        <div class="carousel-item text-center p-4">
          <blockquote class="blockquote">
            <p class="mb-0 lead font-italic"> Nunc non tellus iaculis, cursus purus pretium, porta dolor. Maecenas eu est
              leo. Maecenas lectus tellus, elementum in nunc in, porta gravida enim. Proin et eros euismod, congue risus
              nec, consequat lorem. Nam lacinia augue mauris, id sollicitudin ligula pellentesque vel. Nullam congue sapien
              quis imperdiet mollis. Aliquam et rhoncus sem. In augue mauris, blandit sit amet mauris et, aliquet pharetra
              metus. Curabitur sit amet gravida leo. Mauris convallis mi erat, malesuada mollis ligula scelerisque a. Donec
              enim lorem, gravida in venenatis vitae, dignissim et ante. Aliquam ac nisl nulla. Aliquam interdum nec tellus
              sit amet rutrum. Nam vitae erat id leo pretium aliquam. Duis lectus nunc, venenatis in cursus a, pulvinar sit
              amet eros. </p>
            <footer class="blockquote-footer"> Branimir Tullius a
              <cite title="Personal Bodyguard">Personal Bodyguard</cite>
            </footer>
          </blockquote>
        </div>
      </div>
      <a class="carousel-control-prev" href="#peopelComment" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#peopelComment" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</section>