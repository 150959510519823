<div [formGroup]="form">
    <ng-template #requiredFieldMsgTmpl let-ctrl="control" let-ctrlName="fieldName"
        let-validateOnTouch="validateOnTouch || false">
        <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
            <small *ngIf="ctrl.errors['required']" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_REQUIRED, ctrlName)}}</small>
        </div>
    </ng-template>
    <div class="form-group row">
        <label for="txtFavouredFacility" class="col-10 col-md-3 col-lg-2 col-form-label">Favoured
            Facility/Gym<span class="text-danger" *ngIf="IsPrefferedFacilityMandotory">*</span></label>
        <div class="col-md-8 col-lg-5 order-last order-md-0">
            <div class="d-flex flex-column w-100">
                <div class="input-group" [class.disabled]="getControl('FavouredFacility').disabled"
                    [class.is-invalid]="!isControlValid('FavouredFacility')" [ngClass]="getClassFocused(1)">
                    <input class="form-control" id="txtFavouredFacility" type="text"
                        placeholder="Search facility by name" matInput [matAutocomplete]="ddlFavFacAutoComplete"
                        formControlName="FavouredFacility" 
                        (input)="txtFavouredFacility_Input($event)" (focus)="onFocusParent(1)">
                    <div *ngIf="!favFacilityIsLoading" class="input-group-append">
                        <span class="input-group-text"><i class="fas fa-search"></i></span>
                    </div>
                    <mat-spinner [diameter]="14" *ngIf="favFacilityIsLoading"></mat-spinner>
                </div>
                <div *ngIf="!isControlValid('FavouredFacility')">
                    <small *ngIf="form.get('FavouredFacility').getError('unresolved')"
                        class="invalid-feedback d-block">The
                        facility does not exist</small>
                </div>
                <mat-autocomplete autoActiveFirstOption #ddlFavFacAutoComplete="matAutocomplete"
                    [displayWith]="displayAutoText" (optionSelected)="SelectedFavFacility($event.option.value)"
                    class="height1 fpscrolly border-2 rounded-10 border-default w-100">
                    <mat-option *ngFor="let facility of favFacilitySearch" [value]="facility"
                        class="border-default border-bottom py-1">
                        <p class="m-0 d-flex align-items-center">{{facility.Name}}</p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
        <div class="col-md-1 pl-lg-0 mb-2">
            <p class="d-flex align-items-center justify-content-center m-0 rounded-circle bg-secondary fp-warning"
                placement="right"
                ngbPopover="This identifies the facility you are most likely to attend but does not limit you to attending this one, you can attend as many facilities as you like on your program">
                <i class="fas fa-question text-primary"></i></p>
        </div>
    </div>
    <div class="form-group" *ngIf="IsFacTextDisplay">
        <label for="txtFavFac" class="d-block">Please provide your favoured facility if it wasn’t listed</label>
        <div class="row">
            <div class="col-md-8 col-lg-5 offset-md-3 offset-lg-2">
                <div class="d-flex flex-column w-100">
                    <input class="form-control" [class.is-invalid]="!isControlValid('FavouredFacilityOther')"
                        type="text" id="txtFavFac" formControlName="FavouredFacilityOther" maxlength="200">
                </div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row" *ngIf="!isControlValid('FavouredFacility') || !isControlValid('FavouredFacilityOther')">
            <small
                *ngIf="form.get('FavouredFacility').getError('required') || form.get('FavouredFacility').getError('required')"
                class="invalid-feedback d-block">
                Please start typing the name of your preferred facility and select from the drop down list
                presented</small>
        </div>
    </div>
    <div class="form-group">
        <label for="txtMembr" class="d-block">Have you been an active member of a facility in the last two
            months?</label>
        <div class="row">
            <div class="col-md-8 col-lg-5 offset-md-3 offset-lg-2">
                <div class="d-flex">
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="{{timeStamp}}_chkMembrY" class="custom-control-input"
                            formControlName="IsExistingMember" [value]="true" #chkIsExistingMember>
                        <label class="custom-control-label" for="{{timeStamp}}_chkMembrY">Yes</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="{{timeStamp}}_chkMembrN" class="custom-control-input"
                            formControlName="IsExistingMember" [value]="false">
                        <label class="custom-control-label" for="{{timeStamp}}_chkMembrN">No</label>
                    </div>
                </div>
                <ng-container
                    *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: form.get('IsExistingMember'), fieldName: 'Existing Member'}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label for="txtExistingFacility" class="d-block">If yes, which facility?<span
                class="text-danger">*</span></label>
        <div class="row">
            <div class="col-md-8 col-lg-5 offset-md-3 offset-lg-2">
                <div class="d-flex flex-column w-100">
                    <div class="input-group" [class.is-invalid]="!isControlValid('ExistingFacility')"
                        [class.disabled]="form.get('ExistingFacility').disabled" [ngClass]="getClassFocused(2)">
                        <input class="form-control" id="txtExistingFacility" type="text"
                            placeholder="Search facility by name" matInput [matAutocomplete]="ddlMembrYAutoComplete"
                            formControlName="ExistingFacility" (blur)="txtExistingFacility_Blur($event)"
                            (input)="txtExistingFacility_Input($event)" (focus)="onFocusParent(2)">
                        <div *ngIf="!existFacilityIsLoading" class="input-group-append">
                            <span class="input-group-text"><i class="fas fa-search"></i></span>
                        </div>
                        <mat-spinner [diameter]="14" *ngIf="existFacilityIsLoading"></mat-spinner>
                    </div>
                    <div *ngIf="!isControlValid('ExistingFacility')">
                        <small *ngIf="form.get('ExistingFacility').getError('unresolved')"
                            class="invalid-feedback d-block">The
                            facility does not exist</small>
                    </div>
                    <mat-autocomplete autoActiveFirstOption #ddlMembrYAutoComplete="matAutocomplete"
                        [displayWith]="displayAutoText" (optionSelected)="SelectedExistingFacility($event.option.value)"
                        class="height1 fpscrolly border-2 rounded-10 border-default w-100">
                        <mat-option *ngFor="let facility of existFacilitySearch" [value]="facility"
                            class="border-default border-bottom py-1">
                            <p class="m-0 d-flex align-items-center">{{facility.Name}}</p>
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label for="txtMembrN" class="d-block">Please provide your existing facility if
            it wasn’t listed<span class="text-danger">*</span></label>
        <div class="row">
            <div class="col-md-8 col-lg-5 offset-md-3 offset-lg-2">
                <div class="d-flex flex-column w-100">
                    <input class="form-control" [class.is-invalid]="!isControlValid('ExistingFacilityOther')"
                        type="text" id="txtMembrN" formControlName="ExistingFacilityOther" maxlength="200">
                    <div *ngIf="!isControlValid('ExistingFacility') || !isControlValid('ExistingFacilityOther')">
                        <small
                            *ngIf="form.get('ExistingFacility').getError('required') || form.get('ExistingFacilityOther').getError('required')"
                            class="invalid-feedback d-block">
                            Please start typing the name of your preferred facility and select from the drop down list
                            presented</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>