import { UploadFile } from './upload-file.model';

export class EmployerContractModel {
    EmployerContractId?: number;
    ContractStartDate?: string;
    ContractEndDate?: string;
    ContractDuration?: number;
    ContractSignatory?: string;
    EmployerContractAttachFile?: EmployerContractUploadFile[];
    EmployerContractLogo?: EmployerContractUploadFile[];
    EmployerSignedContract?: EmployerContractUploadFile[];
}

export class EmployerContractUploadFile extends UploadFile {
    EmployerContractId?: number;
    Base64?: string;
    Status?: string | any;
}