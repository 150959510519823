import { Component, Injector } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AddFamilyMemberPopupComponent } from '@fp/add-family-member-popup/add-family-member-popup.component';
import { MemberFormBase } from '@fp/components/base';
import { EMemberStatus } from '@fp/enums/member-status.enum';
import { Member } from '@fp/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReactivationMemberDetailsComponent } from '../reactivation-member-details.component';
import { Utils } from '@fp/helpers/utils';

@Component({
  selector: 'app-family-member-reactivation',
  templateUrl: './family-member-reactivation.component.html',
  styleUrls: ['./family-member-reactivation.component.css'],
  providers: [{ provide: MemberFormBase, useExisting: FamilyMemberReactivationComponent }]
})
export class FamilyMemberReactivationComponent extends MemberFormBase {

  memberDataSource: Member[] = [];
  parentComponent: ReactivationMemberDetailsComponent;

  constructor(
    injector: Injector,
    private modalService: NgbModal
  ) {
    super(injector);
    const _parent: ReactivationMemberDetailsComponent = this.injector.get<ReactivationMemberDetailsComponent>(ReactivationMemberDetailsComponent); 
    this.parentComponent = _parent;
  }

  static getFormGroup() {
    const fb = new FormBuilder();
    return fb.group({
      FamilyMembers: [[]]
    });;
  }


  PatchValue(memberData: Member, options) {
    super.PatchValue(memberData, options);
    this.refreshDataSource(memberData);
  }

  addMemberPopup() {
    let modalRef = this.modalService.open(AddFamilyMemberPopupComponent, {
      windowClass: "selectPackage-add-member-dialog",
      backdrop: "static",
    });

    var member = {} as Member;
    member.MemberId = 0;
    member.PrimaryMember = Object.assign({}, this.data.model, this.form.value);
    member.PrimaryMember.MobileNumber = Utils.convertPhoneToInternationalFormat(member.PrimaryMember.MobileNumber,this.parentComponent.addressContactDetails.orgiCtryPhoneCode);
    member.MemberStatusId = EMemberStatus.CandidateMember;
    (<AddFamilyMemberPopupComponent>modalRef.componentInstance).data.model = member;
    (<AddFamilyMemberPopupComponent>modalRef.componentInstance).OnLoad();

    modalRef.result.then(
      (result: { returnValue: Member }) => {
        if (result && result.returnValue) {
          result.returnValue.MemberIdPrimary = this.data.model.MemberId;
          // Clear the PrimaryMember object to reduce object size.
          result.returnValue.PrimaryMember = null;
          result.returnValue.MemberId = 0;
          result.returnValue.IsReactivated = true;
          result.returnValue.MemberStatusId = EMemberStatus.New;

          if (this.data.model.FamilyMembers && this.data.model.FamilyMembers.length >= 0) {
            this.data.model.FamilyMembers.push(result.returnValue);
          }
          else {
            let familyMembers: Member[] = [result.returnValue];
            this.data.model.FamilyMembers = familyMembers;
          }
          this.memberDataSource.push(result.returnValue);
        }
      },
      reason => { }
    );
  }

  onCheckChange(event: MatCheckboxChange, memberDetail: Member) {

    if (memberDetail) {
      var indexOfFamilyMember = this.data.model.FamilyMembers.findIndex(
        (member) => {
          return (
            member.MemberId == memberDetail.MemberId &&
            member.PrimaryEmail == memberDetail.PrimaryEmail &&
            member.FirstName == memberDetail.FirstName &&
            member.LastName == memberDetail.LastName &&
            member.MobileNumber == memberDetail.MobileNumber
          );
        }
      );

      if (event.checked) {
        this.data.model.FamilyMembers[indexOfFamilyMember].IsReactivated = true;
      } else {
        this.data.model.FamilyMembers[indexOfFamilyMember].IsReactivated = false;
      }

    }
  }

  refreshDataSource(memberData: Member) {

    this.memberDataSource = [];
    if (memberData.FamilyMembers.length > 0) {
      memberData.FamilyMembers.forEach((familyMember) => {
        var member = this.memberDataSource.find(
          (x) =>
            x.PrimaryEmail == familyMember.PrimaryEmail &&
            x.LastName == familyMember.LastName &&
            x.FirstName == familyMember.FirstName &&
            x.MemberId == familyMember.MemberId &&
            x.IsReactivated == false
        );
        if (!member) {
          this.memberDataSource.push(familyMember);
        }
      });
    }
  }

  editFamilyMember(memberDetail: Member) {
    let modalRef = this.modalService.open(AddFamilyMemberPopupComponent, {
      windowClass: "selectPackage-add-member-dialog",
      backdrop: "static",
    });

    (<AddFamilyMemberPopupComponent>modalRef.componentInstance).isFieldsDisabled = true;

    var member = memberDetail;

    var indexOfFamilyMember = this.data.model.FamilyMembers.findIndex(
      (member) => {
        return (
          member.MemberId == memberDetail.MemberId &&
          member.PrimaryEmail == memberDetail.PrimaryEmail &&
          member.FirstName == memberDetail.FirstName &&
          member.LastName == memberDetail.LastName &&
          member.MobileNumber == memberDetail.MobileNumber
        );
      }
    );

    var indexOfFamilyMemberDataSource = this.memberDataSource.findIndex(
      (member) => {
        return (
          member.MemberId == memberDetail.MemberId &&
          member.PrimaryEmail == memberDetail.PrimaryEmail &&
          member.FirstName == memberDetail.FirstName &&
          member.LastName == memberDetail.LastName &&
          member.MobileNumber == memberDetail.MobileNumber
        );
      }
    );

    member.PrimaryMember = Object.assign({}, this.data.model, this.form.value);
    member.PrimaryMember.MobileNumber = Utils.convertPhoneToInternationalFormat(member.PrimaryMember.MobileNumber,this.parentComponent.addressContactDetails.orgiCtryPhoneCode);
    (<AddFamilyMemberPopupComponent>modalRef.componentInstance).data.model = member;
    (<AddFamilyMemberPopupComponent>modalRef.componentInstance).OnLoad();

    modalRef.result.then(
      (result: { returnValue: Member }) => {
        if (result && result.returnValue) {
          result.returnValue.MemberIdPrimary = this.data.model.MemberId;
          result.returnValue.PrimaryMember = null;
          this.data.model.FamilyMembers[indexOfFamilyMember] = result.returnValue;
          this.memberDataSource[indexOfFamilyMemberDataSource] = result.returnValue;
        }
      },
      reason => { }
    );
  }
}
