import { MemberCard } from './member-card.model';
import { EMembershipStatus } from 'src/app/enums';
import { MembershipPackage } from './membership-package.model';

export class Membership {
    Id: number;
    MembershipStatusId: number | EMembershipStatus;
    MembershipStatusDesc: string;
    MemberIdPrimary: number;
    MembershipPackageId: number | null;
    EmployeePayrollNo: string;
    MembershipNumber: string;
    ContractVersionId: number | null;
    SignupAmount: number | null;
    Price: number | null;
    PaymentFrequencyId: number | null;
    SuspensionWeeklyAmount: number | null;
    Months: number | null;
    StartDate: Date | string | null;
    EndDate: Date | string | null;
    StopDate: Date | string | null;
    AgreementDate: Date | string | null;
    DateTimeStamp: Date | string;
    IsEnough12Months: boolean;
    Bcf: number | null;
    MemberCards: MemberCard[];
    MembershipPackage: MembershipPackage;
    ModifiedBy: string;
}

export class NormalMembershipSuspension {
    MembershipSuspensionId: number;
    MembershipId: number;
    PreviousMembershipStatusId: number;
    MembershipSuspensionTypeId: number;
    MembershipSuspensionReasonId: number;
    SuspensionStartDate: string;
    SuspensionEndDate: string;
    IsChargeSuspensionFee: boolean;
    IsCancelled: boolean;
    CancellationDate: string;
    IsEnded: boolean;
    DateTimeStamp: string;
    CurrentTime: string;
    ModifiedBy: string;
    SelectedRole: string;
}
