import { RouterConstant } from './../../constant/routerconstant';
import { APIConstant } from 'src/app/constant/apiconstant';
import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { of as observableOf, forkJoin } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpDAO } from '../../services/httpdao.service';
import { RolePermissionService } from 'src/app/services/role-permission.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewChecked {

    isLoginDisplay = true;
    isLoginSuccessDisplay = false;
    httpDAO: HttpDAO | null;
    public mobileQuery: MediaQueryList;
    public opened: boolean;
    private _mobileQueryListener: () => void;

    get showRoleSelector(): boolean {
        return this.rolePermission.RolesObject && this.rolePermission.RolesObject.length > 1 && !this.commonService.App.isHideSideNav
    }

    constructor(private router: Router,
        private cdRef: ChangeDetectorRef,
        private commonService: CommonService,
        private rolePermission: RolePermissionService,
        private http: HttpClient) { }

    ngOnInit() {
        this.isLoginSuccessDisplay = this.commonService.Navbarmd.isLoginSuccessDisplay;
        this.isLoginDisplay = this.commonService.Navbarmd.isLoginDisplay;
        this.httpDAO = new HttpDAO(this.http);
    }

    public SignOut() {
        this.rolePermission.RolesObject = [];
        this.commonService.SignOut();
    }

    clickItem() {
        this.commonService.App.opened = false;
    }

    ngAfterViewChecked() {
        this.isLoginSuccessDisplay = this.commonService.Navbarmd.isLoginSuccessDisplay;
        this.isLoginDisplay = this.commonService.Navbarmd.isLoginDisplay;
        this.cdRef.detectChanges();
    }

    public ddlRoles_Change(event) {
        this.rolePermission.SelectedRole = event.value;
        this.commonService.SetSelectedRole(this.rolePermission.SelectedRole);
        this.commonService.SelectedRole = event.value;
        this.cdRef.markForCheck();
        if (this.rolePermission.SelectedRole == 'Member') {
            this.LoadRolesPrivilegesByUsername();
            this.commonService.DashBoard.SetMenuAndNavigateUser();
        } else {
            this.LoadRolesPrivilegesByUsername();
            this.commonService.MenuForMember = false;
            this.commonService.MenuForFamilyMember = false;
        }
    }

    private LoadRolesPrivilegesByUsername() {
        this.commonService.StartProgressBar();
        this.httpDAO = new HttpDAO(this.http);
        forkJoin(
            this.httpDAO!.getSingleData(APIConstant.API_GET_ALL_PRIVILEGES_V2)
        ).pipe(
            map(([privileges]) => {
                const encryptedData = privileges.Data;
                const decryptedData = this.commonService.D_FP_ResponseData(encryptedData);
                const ret = JSON.parse(decryptedData);

                this.rolePermission.Privileges = ret;

                this.rolePermission.SetPermisisonReadOnlyPrivilege();

                this.rolePermission.SetMenu();
                this.router.navigate([RouterConstant.ROUTER_DASHBOARD]);
                this.commonService.StopProgressBar();

                return { privileges };
            }),
            shareReplay(1),
            catchError(() => {
                this.commonService.StopProgressBar();
                return observableOf(null);
            })
        ).subscribe(results => {
            this.commonService.StopProgressBar();
        });
    }

    ngOnDestroy(): void {
        this.mobileQuery?.removeListener(this._mobileQueryListener);
    }
}