import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, OnDestroy, HostBinding, Optional, Self, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgControl, ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MapFacilityData } from '@fp/models/map-facility-data';
import { SurveyTemplateQuestion } from "@fp/models";

@Component({
  selector: 'app-family-membership-picker',
  templateUrl: './family-membership-picker.component.html',
  styleUrls: ['./family-membership-picker.component.css'],
  providers: [{ provide: MatFormFieldControl, useExisting: FamilyMembershipPickerComponent }],
})
export class FamilyMembershipPickerComponent implements MatFormFieldControl<any>, ControlValueAccessor, OnInit, OnDestroy
{
  formGroup: FormGroup;
  stateChanges = new Subject<void>();
  hasMemberships:boolean = false;

  @Input() title: string;
  @Input() questionNo: string;
  @Input() helpText: string;
  @Input() facilities: MapFacilityData[] = [];
  @Input() existingFacilitySelectionQuestion: SurveyTemplateQuestion;

  private _value: any = [];
  @Input()
  get value(): any {
    return this._value;
  }
  set value(newValue: any) {
    if (newValue !== this._value) {
      this._value = newValue;
      this._onChange(newValue);
    }
  }

  constructor(
    formBuilder: FormBuilder,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this.formGroup = formBuilder.group({
      hasFamilyMembership: [],
      myMemberships: [],
      familyMemberships: [],
      otherMemberships: []
    });

    focusMonitor.monitor(elementRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    this.formGroup.get('hasFamilyMembership').valueChanges
    .subscribe(
      (newValue) => {
        this.hasMemberships = newValue === "yes";
        if (newValue === "no") {
          this._value = {'hasFamilyMembership' : "no"}
          this._onChange(this._value);
        }
        else {
          this._value = this.formGroup.value
          this._value.hasFamilyMembership = "yes"
          this._onChange(this._value);
        }
        this.checkValidation();
      }
    );

    this.formGroup.get('myMemberships').valueChanges
    .subscribe(
      (newValue) => {
        this._value.myMemberships = newValue
        this._onChange(this._value);
        this.checkValidation();
      }
    );

    this.formGroup.get('familyMemberships').valueChanges
    .subscribe(
      (newValue) => {
        this._value.familyMemberships = newValue
        this._onChange(this._value);
        this.checkValidation();
      }
    );

    this.formGroup.get('otherMemberships').valueChanges
    .subscribe(
      (newValue) => {
        this._value.otherMemberships = newValue
        this._onChange(this._value);
        this.checkValidation();
      }
    );

    this.formGroup.get('myMemberships').statusChanges.subscribe(() => {
      this.checkValidation();
    });

    this.formGroup.get('familyMemberships').statusChanges.subscribe(() => {
      this.checkValidation();
    });
  }

  focused = false;

  get errorState(): boolean {
    return !!this.ngControl.errors;
  }

  controlType = 'family-membership';

  static nextId = 0;
  @HostBinding() id = `${this.controlType}-${FamilyMembershipPickerComponent.nextId++}`;

  get empty() {
    if (this.multiple) {
      return (
        !Array.isArray(this.formGroup.value.internalMatselect) ||
        this.formGroup.value.internalMatselect.length == 0
      );
    }
    return !this.formGroup.value.internalMatselect;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _placeholder: string;
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(newPlaceholder) {
    this._placeholder = newPlaceholder;
    this.stateChanges.next();
  }

  _required = false;
  @Input()
  get required() {
    return this._required;
  }
  set required(newRequired) {
    this._required = coerceBooleanProperty(newRequired);
    this.stateChanges.next();
  }

  private _disabled = false;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(newDisabled) {
    this._disabled = coerceBooleanProperty(newDisabled);
    if (this._disabled) {
      this.formGroup.controls['internalMatselect'].disable();
    } else {
      this.formGroup.controls['internalMatselect'].enable();
    }
    this.stateChanges.next();
  }

  private _multiple = false;
  @Input()
  get multiple() {
    return this._multiple;
  }
  set multiple(newMultiple) {
    this._multiple = coerceBooleanProperty(newMultiple);
    this.stateChanges.next();
  }

  @HostBinding('attr.aria-describedby') describedBy = '';
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).id !== 'internalMatselect') {
      this.elementRef.nativeElement.querySelector('#internalMatselect').focus();
    }
  }

  ngOnInit(): void {
    if (this.ngControl){
      this.ngControl.control.setErrors({"required":"Please select an option"})
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  // ControlValueAccessor
  writeValue(newValue: any): void {
    this.value = newValue;
  }

  _onChange: (value: any) => void = () => {};
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  // not called!
  _onTouched = () => {};
  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }

  checkValidation() {
    let hasFamilyMembership = this.formGroup.get('hasFamilyMembership').value;
    let myMembershipsStatus = this.formGroup.get('myMemberships').status;
    let familyMembershipsStatus = this.formGroup.get('familyMemberships').status;
    let v = this._value
    if (hasFamilyMembership === 'yes') {
      if (v.myMemberships || v.familyMemberships || v.otherMemberships){
        if (myMembershipsStatus === 'INVALID' || familyMembershipsStatus === 'INVALID') {
          this.ngControl.control.setErrors({ "invalid": true });
        } else {
          this.ngControl.control.setErrors(null);
        }
      }else{
      this.ngControl.control.setErrors({ "required": "Please fill in the name(s) of the facility you/your family are members at" });
      }
    } else if (hasFamilyMembership === 'no') {
      this.ngControl.control.setErrors(null);
    } else {
      this.ngControl.control.setErrors({ "required": "Please select an option" });
    }
  }
}
