import { SafeHtml } from '@angular/platform-browser';
export class FileImportModel {
    public Filename = '';
    public UploadStatus = '';
    public Error: SafeHtml = null;
    public Errors: string[] = [];
    public Location = '';
    public FormatUploadStatus: SafeHtml = null;
    public ContentFile: string[] = [];
    public CsvContentError = '';
    public CsvContent = '';
    public ExtractContentFile: string[] = [];
    public relcols: RealColumn[] = [];
    public Errortype: ErrorType[] = [];
    public ErrorPos: ErrorPosition[] = [];
    public FileObject = null;
}

export class FpCsvColumn {
    public colname = '';
    public maxlength = 0;
    public type = 'text';
    public required?= true;
    public primarykey?= false;
    public valueexisting?: ExistingValidatorColumnModel;
    public pkfkrelation?: PkFkValidatorColumnModel;
}

export class RealColumn {
    public colname = '';
    public index = -1;
}

export class ErrorPosition {
    public rownum = -1;
    public colname = '';
    public colnum = -1;
    public errtype: ErrorType;
    public addinfo = ''
    constructor(rnum, cname, cnum, errtype, addinfo) {
        this.rownum = rnum;
        this.colname = cname;
        this.colnum = cnum;
        this.errtype = errtype;
        this.addinfo = addinfo;
    }
}

export enum ErrorType {
    MissingHeader = 1,
    MissingData = 2,
    MaxLengthIssue = 3,
    ErrorDataType = 4,
    MissingValidation = 5,
    IncorrectFileType = 6,
    ExceedMaxFileSize = 7,
    FileEmpty = 8,
    InvalidRow = 9,
    DuplicateKey = 10,
    ValueNotExistingInDB = 11,
    NoReleation = 12
}

export class ExistingValidatorModel {
    RowNum: number;
    RowVal: string;
    IsExisting: boolean;
}

export class ExistingValidatorColumnModel {
    ColumnName: string;
    DisplayColumName?: string;
    TableName: string;
    ListValues?: ExistingValidatorModel[];
    ColumnType?:string;
}

export class PkFkValidatorViewModel {
    PkFkRowNum: number;
    PkRowVal: string;
    FkRowVal: string;
    IsExisting: boolean;
}

export class PkFkValidatorColumnModel {
    FkColumnName: string;
    FkColumnIndex: number;
    PkColumnName: string;
    DisplayColumName?: string;
    TableName: string;
    ListValues?: PkFkValidatorViewModel[];
    ColumnType?:string;
}