import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FPEvent } from "@fp/components/base";
import { Utils } from "@fp/helpers";
import { Configuration } from "@fp/models/configuration.model";
import { CommonService, HttpDAO } from "@fp/services";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { merge, of as observableOf, Subject } from "rxjs";
import { catchError, debounceTime, map, startWith, tap } from "rxjs/operators";
import { v4 as uuid } from "uuid";
import { MessageBox } from "./../../../services/common-dialog.service";

export class FpFileEvent extends FPEvent {
  file: File;
}

@Component({
  selector: "app-fp-file-upload",
  templateUrl: "./fp-file-upload.component.html",
  styleUrls: ["./fp-file-upload.component.css"],
})
export class FpFileUploadComponent implements OnInit {
  private conf: Configuration = new Configuration();

  public pg_subject: Subject<number> = new Subject();
  public pgend_subject: Subject<any> = new Subject();
  public pgclose_subject: Subject<boolean> = new Subject();
  public Filevalue: string = "";
  private httpdao: HttpDAO | null;
  public status: string = "";

  @Input() buttonlabel: string = "Click to Upload";
  @Input() isReadonly: boolean = false;
  @Input() filterFile: string = ".pdf,image/*";
  @Input() ngClassLabel: string = "";
  @Input() ngInputFile: string = "custom-file w-170px";
  @Input() maxfilesize: string = "";
  @Input() folderfile: string = "";
  @Input() ignoredebouce: boolean = false;
  @Input() buttonStyle = { "background-color": "rgb(0, 147, 211)" };
  @Input() memberPhoto: boolean = false;

  @Output() UploadCompleted: EventEmitter<FpFileData> = new EventEmitter<
    FpFileData
  >();
  @Output() AllCompleted: EventEmitter<FpFileData> = new EventEmitter<
    FpFileData
  >();
  @Output() fileSelected = new EventEmitter<FpFileEvent>(false);
  public percent = 0;
  public IsUploadCompleted = false;
  @ViewChild("content") popupcontent: ElementRef;
  @ViewChild("fpfileupload") fpfileupload: ElementRef;
  private filedata: FpFileData = { originfilename: "", filedata: null };
  private originfilename = "";
  private openModal: NgbModalRef;
  private DeboucePercentFile = 200;
  private DebouceEnd = 1000;
  private DebouceClose = 1000;

  constructor(
    private modalService: NgbModal,
    protected dialog: MatDialog,
    private http: HttpClient,
    private commonservice: CommonService
  ) {}

  ngOnInit() {
    if (this.ignoredebouce) {
      this.DebouceClose = 0;
      this.DebouceEnd = 0;
      this.DeboucePercentFile = 0;
    } else {
      this.DeboucePercentFile = 200;
      this.DebouceEnd = 1000;
      this.DebouceClose = 1000;
    }
    this.httpdao = new HttpDAO(this.http);
    this.pg_subject
      .pipe(debounceTime(this.DeboucePercentFile))
      .subscribe((val) => {
        this.percent = val;
      });

    this.pgend_subject.pipe(debounceTime(this.DebouceEnd)).subscribe((val) => {
      this.IsUploadCompleted = true;
      this.filedata.originfilename = this.originfilename;
      this.filedata.filedata = val;
      //console.log(this.filedata);
      this.UploadCompleted.emit(this.filedata);
      this.pgclose_subject.next(true);
    });

    this.pgclose_subject
      .pipe(debounceTime(this.DebouceClose))
      .subscribe((val) => {
        const fd: FpFileData = new FpFileData();
        fd.filedata = this.filedata.filedata;
        fd.originfilename = this.filedata.originfilename;
        this.AllCompleted.emit(fd);
        this.Close();
        this.ResetConfig();
        this.commonservice.FormDataChange = true;
      });
  }

  public uploadfileAWSS3(fileuploadurl: string, file: any): any {
    return this.httpdao.putDataWithoutToken(fileuploadurl, file);
  }

  private handleUploadProgressMessage(event: HttpEvent<any>, file: File) {
    switch (event.type) {
      case HttpEventType.Sent:
        this.pg_subject.next(0);
        if (process.env.NODE_ENV === "development") {
          console.log(`Uploading file "${file.name}" of size ${file.size}.`);
        }
        return;

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round((100 * event.loaded) / event.total);
        this.pg_subject.next(percentDone);
        if (process.env.NODE_ENV === "development") {
          console.log(`File "${file.name}" is ${percentDone}% uploaded.`);
        }
        return;

      case HttpEventType.Response:
        // The returned download URL has a bunch of token data attached.
        // We need to remove it, and put it into an object that contains a "Location" key
        let outputInfo = {
          Location: event.url.split("?")[0],
          url: event.url.split("?")[0],
        };
        this.pgend_subject.next(outputInfo);
        if (process.env.NODE_ENV === "development") {
          console.log(`File "${file.name}" was completely uploaded!`);
        }
        return;

      default:
        if (process.env.NODE_ENV === "development") {
          console.log(
            `File "${file.name}" surprising upload event: ${event.type}.`
          );
        }
        return;
    }
  }

  // Upload method - signed URL
  private uploadToS3SignedURL(filename: string, file: File): void {
    this.commonservice
      .getUploadUrl(filename)
      .pipe(tap(() => this.startProgressBar()))
      .subscribe((res) => {
        this.stopProgressBar();
        this.uploadfileAWSS3(res, file)
          .pipe(
            tap((event) => this.handleUploadProgressMessage(event as any, file))
          )
          .subscribe();
      });
  }

  public fileEvent(event, notPopup?: boolean) {
    merge()
      .pipe(
        startWith({}),
        map((result) => {
          const file = (<HTMLInputElement>event.target).files[0];
          if (file != undefined) {
            if (this.maxfilesize != "") {
              const size = parseInt(this.maxfilesize, 10) * 1024 * 1024;
              if (file.size > size) {
                MessageBox.ShowError(
                  this.dialog,
                  "Maximum file size is " + this.maxfilesize + "MB"
                );
                return;
              }
            }

            this.originfilename = file.name;
            const fileEvent = new FpFileEvent();
            fileEvent.file = file;
            this.fileSelected.emit(fileEvent);
            if (fileEvent.cancelled) {
              // Reset control if event was cancelled
              this.ResetFileInputValue();
              this.ResetConfig();
              return;
            }

            if (!notPopup) {
              this.Open();
              this.status = "Uploading";
            }

            const d = new Date();
            const n = d.getTime();

            let filename =
              this.folderfile +
              uuid() +
              n.toString() +
              "." +
              file.name.split(".").pop();

            // If the uploaded file is member photo - upload this photo to Member Photos folder in S3 (for scanners and Unicard functionality)
            if (this.memberPhoto === true) {
              filename = "memberPhotos/" + filename;
            }

            this.uploadToS3SignedURL(filename, file);

            // Upload duplicate photo with "_web" in file name - required for Scanner devices
            if (this.memberPhoto === true) {
              filename = filename.replace(".", "_web.");
              this.uploadToS3SignedURL(filename, file);
            }
          }
        }),
        catchError((err) => {
          console.error(err);
          return observableOf([]);
        })
      )
      .subscribe((data) => { });
  }

  public fileEventManual(event, fileType: string) {
    merge()
      .pipe(
        startWith({}),
        map((result) => {
          const file = (<HTMLInputElement>event.target).files[0];
          if (file != undefined) {
            if (this.maxfilesize != "") {
              // const size = parseInt(this.maxfilesize, 10) * 1024 * 1024;
              // if (file.size > size) {
              //   MessageBox.ShowError(
              //     this.dialog,
              //     "Maximum file size is " + this.maxfilesize + "MB"
              //   );
              //   this.commonservice.StopGlobalProgressBar();
              //   return;
              // }
            }

            this.originfilename = file.name;
            const fileEvent = new FpFileEvent();
            fileEvent.file = file;
            this.fileSelected.emit(fileEvent);
            if (fileEvent.cancelled) {
              // Reset control if event was cancelled
              this.ResetFileInputValue();
              this.ResetConfig();
              return;
            }

            const d = new Date();
            const n = d.getTime();

            const fileUuid = uuid();

            // Added memberPhotos to upload the photo into memberPhotos folder in S3
            let filename =
              "memberPhotos/" + fileUuid + n.toString() + "." + fileType;

            const lfile = Utils.blobToFile(file, filename);

            this.uploadToS3SignedURL(filename, lfile);

            // Upload duplicate photo with "_web" in file name - required for Scanner devices
            filename =
              "memberPhotos/" +
              fileUuid +
              n.toString() +
              "_web" +
              "." +
              fileType;

            const sfile = Utils.blobToFile(file, filename);
            this.uploadToS3SignedURL(filename, sfile);
          }
        }),
        catchError((err) => {
          console.error(err);
          return observableOf([]);
        })
      )
      .subscribe((data) => { });
  }

  public fileEventUploadReports(
    event,
    fileType: string,
    reportfilename: string,
    keyid: string
  ) {
    merge()
      .pipe(
        startWith({}),
        map((result) => {
          const file = (<HTMLInputElement>event.target).files[0];
          if (file != undefined) {
            if (this.maxfilesize != "") {
              const size = parseInt(this.maxfilesize, 10) * 1024 * 1024;
              if (file.size > size) {
                MessageBox.ShowError(
                  this.dialog,
                  "Maximum file size is " + this.maxfilesize + "MB"
                );
                return;
              }
            }

            this.originfilename = reportfilename + "#" + keyid;
            const fileEvent = new FpFileEvent();
            fileEvent.file = file;
            this.fileSelected.emit(fileEvent);
            if (fileEvent.cancelled) {
              // Reset control if event was cancelled
              this.ResetFileInputValue();
              this.ResetConfig();
              return;
            }

            const d = new Date();
            const n = d.getTime();

            const filename = uuid() + n.toString() + "." + fileType;

            this.uploadToS3SignedURL("Reports/" + filename, file);
          }
        }),
        catchError((err) => {
          console.error(err);
          return observableOf([]);
        })
      )
      .subscribe((data) => { });
  }

  public Open() {
    this.openModal = this.modalService.open(this.popupcontent, {
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
    this.openModal.result.then(
      (result) => { },
      (reason) => { }
    );
  }

  private Close() {
    if (this.openModal) {
      this.openModal.close();
    }
  }

  private ResetConfig() {
    this.percent = 0;
    this.IsUploadCompleted = false;
    this.filedata = { originfilename: "", filedata: null };
    this.originfilename = "";
  }

  public ResetFileInputValue() {
    this.fpfileupload.nativeElement.value = "";
  }

  private startProgressBar() {
    if (this.commonservice.DashBoard) {
      this.commonservice.StartProgressBar();
    } else {
      this.commonservice.StartGlobalProgressBar();
    }
  }

  private stopProgressBar() {
    if (this.commonservice.DashBoard) {
      this.commonservice.StopProgressBar();
    } else {
      this.commonservice.StopGlobalProgressBar();
    }
  }
}

export class FpFileData {
  public originfilename: string = "";
  public filedata: any;
}