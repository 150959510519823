<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Parent Groups</h4>
<div class="mt-lg-4">
    <form [formGroup]="parentGroupsFormGroup">
        <div class="form-group row">
            <label for="txtParentGroupName" class="col-md-3 col-lg-2 col-form-label">Parent Group<span
                    class="text-danger">*</span>
            </label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <input type="text" maxlength="50" [ngClass]="(parentGroupsFormGroup.get('txtParentGroupName').invalid
                    && (parentGroupsFormGroup.get('txtParentGroupName').dirty ||
                    parentGroupsFormGroup.get('txtParentGroupName').touched
                    || btnSubmit))?'form-control is-invalid':'form-control'" id="txtParentGroupName"
                        formControlName="txtParentGroupName">
                    <mat-spinner [diameter]="14" *ngIf="pgSpinter"></mat-spinner>
                    <div *ngIf="parentGroupsFormGroup.get('txtParentGroupName').invalid
                        && (parentGroupsFormGroup.get('txtParentGroupName').dirty
                        || parentGroupsFormGroup.get('txtParentGroupName').touched
                        || btnSubmit)">
                        <small *ngIf="parentGroupsFormGroup.get('txtParentGroupName').errors['required']"
                             class="invalid-feedback d-block">Parent Group Name is required</small>
                        <small *ngIf="parentGroupsFormGroup.get('txtParentGroupName').errors['duplicated']"
                             class="invalid-feedback d-block">The
                            parent group already exists</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-8 col-lg-5 offset-sm-2 justify-content-between justify-content-md-start">
                <button class="px-3 px-lg-5 py-2 btn bg-white border-primary text-primary mr-4" type="button"
                    (click)="this.router.navigate(['/' + routerConstant.ROUTER_DASHBOARD + '/' + routerConstant.ROUTER_PARENT_GROUP_VIEW]);">
                    <i class="fas fa-arrow-alt-circle-left mr-2"></i>BACK</button>
                <button class="px-3 px-lg-5 py-2 btn btn-primary" type="button" (click)="submitParentGroup()"
                    [disabled]="pgSpinter">
                    <i class="fas fa-check mr-2"></i> Submit</button>
            </div>
        </div>
    </form>
</div>