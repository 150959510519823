import { Component, OnInit } from "@angular/core";
import {
  SystemParametersFrontEnd,
  SystemParameters,
  UpdateSysPara,
  UpdatedSystemParameter,
} from "@fp/models/system-parameters.model";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { ManipulateSysParamsService } from "@fp/services/manipulate-sys-params.service";
import { DataResult, ServiceStaffedHour } from "@fp/models";
import { MessageBox } from "@fp/services";
import { MatDialog } from "@angular/material/dialog";
import { SystemParamDataTypes } from "@fp/enums/system-param-data-types.enum";

@Component({
  selector: "app-system-parameters",
  templateUrl: "./system-parameters.component.html",
  styleUrls: ["./system-parameters.component.css"],
})
export class SystemParametersComponent implements OnInit {
  originalSystemParameters = [];
  tempSysPara: SystemParametersFrontEnd = new SystemParametersFrontEnd();
  systemParameters: SystemParametersFrontEnd[] = [];
  isLoading = false;
  isEditing = false;
  paramType: any = SystemParamDataTypes;

  constructor(
    private manSysParaSvc: ManipulateSysParamsService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getSystemParameters();
  }

  /**
   * Toggles the edit parameter option
   */
  editParameter(parameter) {
    parameter.Edit = !parameter.Edit;
    this.isEditing = parameter.Edit;
  }

  /**
   * Pushes the changes made in each field into the system parameters service when save button pressed
   */
  saveChanges() {
    const changesMade: UpdateSysPara = new UpdateSysPara();
    const changedSysPara = [];
    for (let i = 0; i < this.systemParameters.length; i++) {
      if (this.systemParameters[i].Input !== this.originalSystemParameters[i]) {
        const updatedSysParam: UpdatedSystemParameter = {
          ParameterName: this.systemParameters[i].Field,
          DataType: this.systemParameters[i].Type,
          Value: this.systemParameters[i].Input,
        };
        changedSysPara.push(updatedSysParam);
        changesMade.SystemParameters = changedSysPara;
      }
      this.originalSystemParameters[i] = this.systemParameters[i].Input;
    }
    this.updateSystemParameters(changesMade);
  }

  /**
   * Updates system parameters using api call.
   */
  updateSystemParameters(sysPara) {
    this.isLoading = true;
    this.Invoke(
      this.manSysParaSvc.updateSystemParameters(sysPara),
      (result: any) => {
        console.log(result);
        MessageBox.ShowInfo(
          this.dialog,
          "Your changes have been successfully saved!"
        );
        this.isLoading = false;
      }
    );
  }

  /**
   * Gets system parameters from api call
   */
  getSystemParameters() {
    this.isLoading = true;
    this.Invoke(
      this.manSysParaSvc.getSystemParameters(),
      (result: DataResult<SystemParameters[]>) => {
        for (let i = 0; i < result.Data.length; i++) {
          this.tempSysPara.Field = result.Data[i].ParameterName;
          this.tempSysPara.Legend = result.Data[i].ParameterID;
          this.tempSysPara.Input = result.Data[i].Value;
          this.tempSysPara.Type = result.Data[i].DataType;
          this.tempSysPara.Id = this.tempSysPara.Field.match(/\b(\w)/g).join(
            ""
          );
          if (this.tempSysPara.Type === "TrueFalse") {
            this.tempSysPara.Options = [
              {
                value: 0,
                name: "True",
              },
              {
                value: 1,
                name: "False",
              },
            ];
          } else {
            this.tempSysPara.Options = [];
          }
          this.systemParameters.push({
            Field: this.tempSysPara.Field,
            Edit: this.tempSysPara.Edit,
            Legend: this.tempSysPara.Legend,
            Id: this.tempSysPara.Id,
            Input: this.tempSysPara.Input,
            Type: this.tempSysPara.Type,
            Options: this.tempSysPara.Options,
          });
          this.originalSystemParameters.push(this.systemParameters[i].Input);
          this.isLoading = false;
        }
      }
    );
  }

  /**
   * Validates the textbox value against its Data Type.
   * If the value is Invalid, show the item with item Id errDivId.
   * @param value The text Value to validate
   * @param item The item being edited
   * @param errDivId The ID of the value to show on Div Invalid
   */
  public onFocusLost(
    value: string,
    item: SystemParametersFrontEnd,
    errDivId: string
  ): void {
    var isValid = true;

    switch (item.Type) {
      case SystemParamDataTypes.Number:
        // Numbers must be Non-Null Numeric values
        if (value == null || value.trim() === "" || isNaN(Number(value))) {
          isValid = false;
        }
        break;

      case SystemParamDataTypes.Percentage:
        // Percentages must be Non-Null Numeric values between 0 and 100
        if (value == null || value.trim() === "" || isNaN(Number(value))) {
          isValid = false;
        } else if (Number(value) < 0 || Number(value) > 100) {
          isValid = false;
        }
        break;

      case SystemParamDataTypes.PercentageExtended:
        // PercentageExtendeds must be Non-Null Numeric values between 0 and 1000
        if (value == null || value.trim() === "" || isNaN(Number(value))) {
          isValid = false;
        } else if (Number(value) < 0 || Number(value) > 1000) {
          isValid = false;
        }
        break;

      case SystemParamDataTypes.Currency:
        // Currency must be Non-Null Numeric values
        if (value == null || value.trim() === "" || isNaN(Number(value))) {
          isValid = false;
        }
        break;

      case SystemParamDataTypes.String:
        // Currency must be not-Null String values
        if (value == null || value.trim() === "") {
          isValid = false;
        }
        break;

      default:
        break;
    }

    // If it's valid, hide the invalid message and close the form.
    if (isValid) {
      this.editParameter(item);

      // If it's invalid, show the invalid message
    } else {
      // Do Nothing
    }
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError((e) => {
          throw e;
        })
      )
      .subscribe(
        (res) => {
          handleResultCallback(res);
        },
        (err) => {
          this.errorHandler(err);
        }
      );
  }

  private errorHandler(error, message: string = null) {
    console.error(error);
  }
}
