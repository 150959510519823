<div class="modal-header px-0 justify-content-start">
  <h5 class="modal-title">Manually Add Debit/Credit</h5>

  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<br />

<!-- Main Mat Spinner -->
<div class="loading-shade-global" *ngIf="isLoading">
  <mat-spinner [diameter]="75"></mat-spinner>
</div>

<div class="modal-body py-3 noscrollx fpscrolly height100">
  <form [formGroup]="debitCreditForm" (onchange)="valueChanged($event)">
    <!--Membership Number and the text box-->
    <div class="form-group row">
      <label for="txtMemberNum" class="col-md-4 col-form-label text-primary"><b>Membership No.</b></label>
      <div class="col-md-7">
        <div class="d-flex flex-column w-100">
          <input id="txtMemberNum" class="form-control" type="text" placeholder="{{ sharedMessage }}" readonly />
        </div>
      </div>
    </div>

    <!--Post Date and the text box-->
    <div class="form-group row">
      <label for="dpPostDate" class="col-md-4 col-form-label text-primary"><b>Post Date</b><span
          class="text-danger">*</span></label>
      <div class="col-md-7">
        <div name="postDate" class="input-group d-flex d-inline-flex w-100">
          <input id="dpPostDate" name="dp" ngbDatepicker #postDate="ngbDatepicker" class="form-control"
            formControlName="postDate" placeholder="DD-MMM-YYYY" [minDate]="minDate"
            (onchange)="valueChanged($event)" />
          <div class="input-group-append">
            <button class="btn bg-white" (click)="postDate.toggle()" type="button">
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Amount and the text box-->
    <div class="form-group row">
      <label for="selectPaymentType" class="col-md-4 col-form-label text-primary"><b>Amount ($)</b><span
          class="text-danger">*</span></label>

      <div class="col-md-7">
        <div class="d-flex flex-inline-column w-100">
          <input formControlName="amount" class="form-control" required type="text" />
          <select class="form-control" id="selectPaymentType" (change)="valueChanged($event.target.value)"
            formControlName="selectPaymentType">
            <option value="" selected disabled>Debit/Credit</option>
            <option [value]="0">Credit</option>
            <option [value]="1">Debit</option>
          </select>
        </div>
      </div>
    </div>
    <small *ngIf="debitCreditForm.get('amount').getError('overAmount')" class="invalid-feedback d-block"
      style="text-align: center;">
      This amount exceeds your LoA.
    </small>
    <small *ngIf="debitCreditForm.get('amount').getError('notPositive')" class="invalid-feedback d-block"
      style="text-align: center;">
      Amount cannot be negative.
    </small>

    <!--Reason and the text box-->
    <div class="form-group row">
      <label for="reason" class="col-md-4 col-form-label text-primary"><b>Reason</b><span
          class="text-danger">*</span></label>

      <!--Dropdown Menu for Reason-->

      <div class="col-md-7">
        <div class="d-flex flex-column w-100">
          <select formControlName="reason" class="form-control" id="reason"
            (change)="valueChanged($event.target.value)">
            <option value="" selected disabled>Please select a reason</option>
            <option *ngFor="let value of selectedArray; let i = index" [value]="value">{{ value }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="txtaDescription" class="col-md-4 col-form-label text-primary"><b>Description</b><span
        class="text-danger">*</span></label>
      <div class="col-md-7">
        <div class="d-flex flex-column w-100">
          <textarea id="txtaDescription" formControlName="description" class="form-control" rows="2"
            (input)="valueChanged($event.target.value)"></textarea>
        </div>
      </div>
    </div>
    <br />
    <br />
  </form>

  <div *ngIf="loaTooLow">
    <div class="alert alert-danger">
      <h4 class="alert-heading">
        <i class="far fa-times-circle"></i>
        Insert Failed
      </h4>
      {{ loaTooLowMessage }}
    </div>
  </div>

  <!--Submit and cancel button-->
  <div class="modal-footer justify-content-center">
    <button mat-button class="btn btn-ouline-light px-5" (click)="close()">
      <b>Cancel</b>
    </button>
    <button mat-button [disabled]="!debitCreditForm.valid" (click)="submitDebitCreditLog()"
      class="btn btn-primary px-5">
      <b>Submit</b>
    </button>
  </div>
</div>