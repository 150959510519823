<div [formGroup]="form">
    <ngb-accordion [destroyOnHide]="false" activeIds="pnlShoppingCart">
        <ngb-panel id="pnlShoppingCart">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': valid}"></i>
                Shopping Cart
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-member-membership-package-cost-summary [loadOnDemand]="loadOnDemand"
                    [dataSource]="[data.model].concat(data.model.FamilyMembers || [])"
                    [membershipPackage]="data.model.Memberships ? data.model.Memberships[0]?.MembershipPackage : null"
                    (rowRemoveClick)="removeMember($event)"></app-member-membership-package-cost-summary>
                    <br>
                    <div class="alert alert-primary" role="alert">
                        <strong>*The total price indicates your current weekly membership fee. This is the minimum amount payable per week for your 12 month contract term.</strong>
                    </div>
                <div *ngIf="form.enabled" class="pt-4">
                    <button class="px-4 py-2 btn btn-primary text-transform-none" type="button"
                        (click)="btnAddFamilyMember_Click()">
                        <i class="fas fa-plus-circle mr-2"></i>Add another Family Member</button>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>