import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModelBasedFormSharedData, FPFormBaseComponent } from '@fp/components/base';
import { FpFileEvent } from '@fp/components/control';
import { CommonString } from '@fp/constant';
import { StringHelper } from '@fp/helpers';
import { DataResult, EmployerDetailModel, EmployerPage, ResultModel } from '@fp/models';
import { EmployerService, MessageBox } from '@fp/services';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class EmployerPageFE {
  constructor(
    public employerId?: number,
    public heading?: string,
    public link?: string,
    public linkName?: string,
    public isActive?: boolean,
    public isMemberSignup?: boolean,
    public isAvailableFacilities?: boolean,
    public isPricingPackages?: boolean,
    public introduction?: string,
    public eligibility?: string,
    public additionInfo?: string,
    public logo?: LogoFE
  ) { }
}

class LogoFE {
  constructor(
    public fileName?: string,
    public location?: string,
    public data?: string
  ) { }
}

export class EmployerPageFormSharedData extends ModelBasedFormSharedData<EmployerDetailModel> {
  additionalData: AdditionalData
  constructor() {
    super();
    this.additionalData = new AdditionalData();
  }
}

class AdditionalData {
  btnNextEmployerPage: boolean;
  EmployerPageChanged: boolean;
  isSubmit: boolean;
}

@Component({
  selector: 'app-employer-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css'],
  providers: [FPFormBaseComponent.provideExisting(EmployerPageComponent)]
})
export class EmployerPageComponent extends FPFormBaseComponent<EmployerPageFormSharedData> {
  @Input('form') pageForm: UntypedFormGroup;
  @Output() processingLinkName: EventEmitter<boolean> = new EventEmitter<boolean>();
  public Editor = ClassicEditor;
  ckeConfig: any;
  parentFocus = 0;
  requestQueueCount: number = 0;
  employerPageFE: EmployerPageFE;
  spinnerLinkName: boolean = false;
  employerPageDisabled: boolean = false;
  submitDisabled: boolean = false;
  commonString = CommonString;
  userName: string;

  static getFormGroup() {
    const _formBuilder = new UntypedFormBuilder
    return _formBuilder.group({
      heading: [, Validators.required],
      link: [window.location.origin],
      linkName: ['', Validators.required],
      isActive: [true],
      isMemberSignup: [true],
      isAvailableFacilities: [true],
      isPricingPackages: [true],
      introduction: [''],
      eligibility: [''],
      additionInfo: [''],
      logo: []
    });
  }

  constructor(
    injector: Injector,
    private svc: EmployerService
  ) {
    super(injector);
    this.data = new EmployerPageFormSharedData();
    this.ckeConfig = {
      allowedContent: true,
      forcePasteAsPlainText: true,
      removeButtons: 'Radio,TextField,Textarea,Checkbox,Form,Select,Button,' +
        'ImageButton,HiddenField,CreateDiv,Flash,Iframe,PasteFromWord,Language' +
        ',Anchor,PageBreak,Maximize,ShowBlocks,About,Source,Save,NewPage,Print'
    };
  }

  controlPageFG(name: string): AbstractControl {
    return this.pageForm.get(name);
  }

  checkValidate(controlName: string) {
    const form = this.pageForm.get(controlName);
    let isBool: boolean = false;
    isBool = form.invalid && (form.touched || form.dirty || (this.data && (this.data.additionalData.isSubmit ||
      this.data.additionalData.btnNextEmployerPage)));
    return isBool;
  }

  setDisable() {
    this.pageForm.disable({ emitEvent: false });
    this.employerPageDisabled = true;
  }

  invoke(source: Observable<any>, handleResultCallback: Function, mapCallback: Function = () => { }) {
    this.requestQueueCount++;
    this.commonSvc.StartProgressBar();
    source.pipe(
      catchError((e) => {
        this.commonSvc.StopProgressBar();
        return observableOf([]);
      }),
      finalize(() => {
        this.requestQueueCount--;
        if (this.requestQueueCount <= 0) {
          this.commonSvc.StopProgressBar();
          this.requestQueueCount = 0;
        }
      }),
      map(data => {
        return mapCallback(data);
      }),
    ).subscribe(
      res => {
        handleResultCallback(res);
      },
      err => {
        console.log(err);
      }
    );
  }

  invokeDuplicate(source: Observable<any>, handleResultCallback: Function, mapCallback: Function = () => { }) {
    this.requestQueueCount++;
    this.submitDisabled = true;
    this.processingLinkName.emit(this.submitDisabled);
    this.spinnerLinkName = true;
    source.pipe(
      catchError((e) => {
        this.spinnerLinkName = false;
        this.submitDisabled = false;
        return observableOf([]);
      }),
      finalize(() => {
        this.requestQueueCount--;
        if (this.requestQueueCount <= 0) {
          this.spinnerLinkName = false;
          this.submitDisabled = false;
          this.processingLinkName.emit(this.submitDisabled)
          this.requestQueueCount = 0;
        }
      }),
      map(data => {
        return mapCallback(data);
      }),
    ).subscribe(
      res => {
        handleResultCallback(res);
      },
      err => {
        console.log(err);
      }
    );
  }

  public LoadEmployerPage(employerId: number) {
    if (this.employerPageFE == undefined) {
      this.invoke(
        this.svc.getEmployerPage(employerId),
        (data: EmployerPageFE) => {
          this.employerPageFE = data;
          this.pageForm.patchValue(data);
        },
        (data: DataResult<EmployerPage>) => {
          if (data.Success == false || data.Data == null) {
            return {};
          }
          let _employerPage: EmployerPageFE = new EmployerPageFE();
          _employerPage = {
            employerId: data.Data.EmployerId,
            heading: data.Data.PageHeading,
            link: window.location.origin,
            linkName: (data.Data.LinkName == '' || data.Data.LinkName == null) ?
              this.data.model.Code.replace(/ /g, '').toLowerCase() : data.Data.LinkName,
            isActive: data.Data.IsActive,
            isMemberSignup: data.Data.EnableMemberSignUp,
            isAvailableFacilities: data.Data.ShowAvailableFacilities,
            isPricingPackages: data.Data.ShowPricingPackages,
            introduction: data.Data.PageText1,
            eligibility: data.Data.PageText2,
            additionInfo: data.Data.PageText3,
            logo: data.Data.AttachLogo == null ? null : {
              fileName: data.Data.AttachLogo.FileName,
              location: data.Data.AttachLogo.Location,
              data: data.Data.AttachLogo.Data
            }
          }
          return _employerPage;
        }
      );
    }

    // check form employer page change or or
    this.pageForm.valueChanges.subscribe(data => {
      this.data.additionalData.EmployerPageChanged = true;
    });
  }

  public LogoEmpPage_UploadCompleted(event) {
    const FileName: string = event.originfilename;
    const loc: string = event.filedata.Location;
    const logo: LogoFE = new LogoFE(FileName, loc, null);
    this.controlPageFG('logo').patchValue(logo);
  }

  _fileSelected(event: FpFileEvent) {
    if (
      event.file.type !== 'image/png' &&
      event.file.type !== 'image/jpg' &&
      event.file.type !== 'image/jpeg'
    ) {
      event.cancel();
      MessageBox.ShowError(this.dialog,
        StringHelper.format(CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE, 'JPEG or PNG')
      );
    } else if (event.file.size > this.commonString.MAX_UPLOAD_FILE_SIZE_10MB) {
      event.cancel();
      MessageBox.ShowError(this.dialog,
        StringHelper.format(CommonString.MAX_UPLOAD_FILE_SIZE_MESSAGE, '10MB')
      );
    }
  }

  checkDuplicateLinkName() {
    const linkName: string = this.controlPageFG('linkName').value;
    let currentEmployerId: number = this.employerPageFE ? this.employerPageFE.employerId : undefined;

    if (linkName.length > 0) {
      this.invokeDuplicate(
        this.svc.checkDuplicateLinkName(linkName),
        (data: DataResult<EmployerDetailModel>) => {
          let employerIdFromLinkName: number = 0;
          if (data.Data == null) {
            this.controlPageFG('linkName').setErrors(null);
          } else {
            employerIdFromLinkName = data.Data.EmployerId;
            if (currentEmployerId == 0 || currentEmployerId != employerIdFromLinkName) {
              this.controlPageFG('linkName').setErrors({ duplicate: true });
            }
          }
        },
        (data) => {
          return data;
        }
      );
    }
  }

  public CreateEmployerPage(requestEmpId: number, callback: Function) {
    let employerPage: EmployerPage;
    const value: EmployerPageFE = this.pageForm.value;
    let username = this.commonSvc.GetUser();

    employerPage = {
      PageHeading: value.heading,
      LinkName: value.linkName.toLowerCase(),
      IsActive: value.isActive,
      EmployerId: requestEmpId,
      EnableMemberSignUp: value.isMemberSignup,
      ShowAvailableFacilities: value.isAvailableFacilities,
      ShowPricingPackages: value.isPricingPackages,
      PageText1: value.introduction,
      PageText2: value.eligibility,
      PageText3: value.additionInfo,
      AttachLogo: value.logo == null ? null : {
        FileName: value.logo.fileName,
        Location: value.logo.location,
        Data: value.logo.data == null ? null : value.logo.data.replace(/^data:(.*;base64,)?/, '')
      },
      ModifiedBy: username
    };

    this.Invoke(
      this.svc.requestEmployerPage(employerPage),
      {
        onSuccess: (res: ResultModel) => {
          if (res.Success == true) {
            callback();
            this.data.additionalData.EmployerPageChanged = false;
          } else {
            MessageBox.ShowError(this.dialog, 'Something went wrong in employer page tab');
            console.log(res.Message);
          }
        }
      },
    );
  }

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }
  onBlurParent(focusNumbr) {
    this.parentFocus = focusNumbr;
  }
  getClassFocused(vlFocused) {
    if (this.parentFocus == vlFocused) {
      return 'focused';
    } else {
      return '';
    }
  }

}

