<div [formGroup]="form">
    <div class="mx-auto tabProgress w-50 d-none d-lg-flex mt-3">
        <div class="flex-fill text-center">
            <div class="card-icon rounded-circle mx-auto"
            [ngClass]="{'bg-primary': _stepProgressBackgroundVisible(EChangeMembershipPackTab.MembershipPackages)}">
                <i *ngIf="_stepProgressCompleteIconVisible(EChangeMembershipPackTab.MembershipPackages)" class="fas fa-check text-light"></i>
            </div>
            <p class="card-text mb-3">Membership Packages</p>
        </div>
        <div class="flex-fill text-center">
            <div class="card-icon rounded-circle mx-auto"
            [ngClass]="{'bg-primary': _stepProgressBackgroundVisible(EChangeMembershipPackTab.PaymentDetails)}">
                <i *ngIf="_stepProgressCompleteIconVisible(EChangeMembershipPackTab.PaymentDetails)" class="fas fa-check text-light"></i>
            </div>
            <p class="card-text mb-3">Payment Details</p>
        </div>
    </div>
    <div class="tabFPTheme mb-5" #tabfpstepper [style.height]="heighttabs">
        <mat-vertical-stepper #stepper [linear]="true">
            <mat-step>
                <form id="changemempack0">
                    <ng-template matStepLabel>
                        <i class="icon-fp d-lg-none"
                            [ngClass]="{'fas fa-check': membershipPackages?.valid}"></i>Membership
                        Packages
                    </ng-template>
                    <app-member-membership-packages [form]="form.get('MembershipPackages')" [data]="data"
                        #membershipPackages></app-member-membership-packages>
                    <div
                        class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                        <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button" (click)="btnBack_Click()">
                            <i class="fas fa-arrow-circle-left mr-2"></i>Back</button>
                        <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="btnNext_Click()">
                            <i class="fas fa-arrow-circle-right mr-2"></i>Next</button>
                    </div>
                </form>

            </mat-step>
            <mat-step>
                <form id="changemempack1">
                    <ng-template matStepLabel>
                        <i class="icon-fp d-lg-none" [ngClass]="{'fas fa-check': paymentDetails?.valid}"></i>Payment
                        Details</ng-template>
                    <app-member-payment-details [loadOnDemand]="true" [form]="form.get('PaymentDetails')" [data]="data"
                        #paymentDetails></app-member-payment-details>
                    <div
                        class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                        <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="btnSubmit_Click()">
                            <i class="fas fa-arrow-circle-right mr-2"></i>Submit</button>
                    </div>
                </form>

            </mat-step>
        </mat-vertical-stepper>
    </div>

</div>