<div class="text-center">
    <h3>{{ emppage.PageHeading }}</h3>
</div>

<div>
    <div class="form-group row">
        <label for="txtLinkName" class="col-sm-2 col-form-label">Link Name</label>
        <div class="col-sm-5">
            <input readonly type="text" class="form-control" [value]="emppage.LinkName">
        </div>
    </div>
    <div class="form-group row">
        <label for="txtDescription" class="col-sm-2 col-form-label">Description</label>
        <div class="w-100" style="margin-left:100px">
            <p>
                {{emppage.Description}}
            </p>
            <!-- <textarea readonly class="form-control" [value]="emppage.Description" rows="3"></textarea> -->
        </div>
    </div>
    <div class="form-group row">
        <label for="txtContact" class="col-sm-2 col-form-label">Contact</label>
        <div class="col-sm-5">
            <input readonly type="text" class="form-control" [value]="emppage.ContactName">
        </div>
    </div>
</div>