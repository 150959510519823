import { Component, Injector, isDevMode, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MemberFormBase } from 'src/app/components/base';
import { Utils } from 'src/app/helpers';
import { Member } from 'src/app/models';
import { MemberFamilyMemberInfoComponent } from '../../family-member-info/family-member-info.component';

@Component({
    selector: 'app-member-signup-family-member-details',
    templateUrl: './family-member-details-dialog.component.html',
    styleUrls: ['./family-member-details-dialog.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: MemberFormBase, useExisting: MemberFamilyMemberDetailsDialogComponent }]
})
export class MemberFamilyMemberDetailsDialogComponent extends MemberFormBase {
    // TODO: remove after implementation completed.
    get isDebugging() { return isDevMode() && window['enableDebugging'] == true; }
    JSON = JSON;
    hasInit = false;
    @ViewChild('memberInfo', {static: true}) memberInfo: MemberFamilyMemberInfoComponent;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            FamilyMemberInfo: MemberFamilyMemberInfoComponent.getFormGroup()
        });
    }

    constructor(injector: Injector, private activeModal: NgbActiveModal) {
        super(injector);
        this.form = MemberFamilyMemberDetailsDialogComponent.getFormGroup();
        this.async = false;
    }

    ngAfterViewInit() {
        if(!this.hasInit) {
            this.hasInit = true;
            this.sendValues();
        }
    }

    sendValues() {
        console.log("Send Values");
        //this.memberInfo.PatchValue(this.data.model, { emitEvent: false });
    }

    OnLoad() {
        console.log("FM Dialog Loaded");
        if (this.data && this.data.model) {
            this.data.model.MobileNumber = Utils.convertPhoneToLocalFormat(this.data.model.MobileNumber);
            this.form.markAsPristine();
        }
        super.OnLoad();
    }

    LoadMemberData(member: Member) {
        if (member) {
            this.PatchValue(member, { emitEvent: false });
        }
    }

    dismissDialog() {
        this.activeModal.dismiss();
    }

    btnConfirm_Click() {
        this.Validate();
        if (!this.valid) {
            // TODO: remove this code block after implementation complete.
            if (isDevMode()) {
                const invalidCtrls = this._findInvalidControls(this.form);
                console.log('Invalid controls:\n' + invalidCtrls.join('\n'));
            }
            return;
        }
        this.data.model = this.applyValue(this.data.model, this.memberInfo);
        if (this.data.model.MemberId == 0) {
            this.data.model.MemberId = - (new Date()).getMilliseconds();
        }

        this.data.model.MobileNumber = Utils.convertPhoneToInternationalFormat(this.data.model.MobileNumber);
        this.activeModal.close({ returnValue: this.data.model });
    }
}
