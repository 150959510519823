import { CommonService, HttpDAO, MessageBox } from "@fp/services";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import { RolePermissionService } from "@fp/services/role-permission.service";
import {
  MemberAdditionalNotes,
  ListMemberNotes,
} from "@fp/models/MemberAdditionalNotes.model";
import { ERole } from "@fp/enums/role.enum";
import { CommonResponse, User } from "@fp/models";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { DateHelper } from "@fp/helpers";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { of as observableOf, forkJoin, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { APIConstant, StorageKey } from "@fp/constant";
import { catchError, map } from "rxjs/operators";
import { v4 as uuid } from "uuid";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { CommonConstants } from "@fp/constant/common-constants";

@Component({
  selector: "app-member-additional-notes",
  templateUrl: "./additional-notes.component.html",
  styleUrls: ["./additional-notes.component.css"],
})
export class MemberAdditionalNotesComponent implements OnInit, AfterViewInit {
  public displayedColumns: string[] = [
    "Username",
    "DateTimeDisplay",
    "Type",
    "IsSentEmail",
    "Note",
    "Action",
  ];
  private databinding: MemberAdditionalNotes[] = [];
  private datasending: ListMemberNotes = new ListMemberNotes();
  public dtmatnotes: MatTableDataSource<
    MemberAdditionalNotes
  > = new MatTableDataSource<MemberAdditionalNotes>();
  public NoteTypes: CommonResponse[] = [];
  public NoteTypesSelected: CommonResponse[] = [];
  public selectedrole: string = "";
  private MemberNoteFormGroup: UntypedFormGroup;
  public btnAddNoteClick = false;
  private memberid: number = -1;
  private httpdao: HttpDAO | null;
  public buttonText = "Add Note";
  private updateditem: MemberAdditionalNotes = null;

  @ViewChild("txtMemberNotes") txtMemberNotes: ElementRef;
  @ViewChild("DivMemberNote") DivMemberNote: ElementRef;
  @ViewChild("notepagi") note_paginator: MatPaginator;

  constructor(
    private rolepermission: RolePermissionService,
    private comsvc: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private _formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    protected dialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const path = this.router.url;
    if (path == "/membersignup") {
      return;
    }
    this.httpdao = new HttpDAO(this.http);
    this.CreateMemberNoteForm();
    this.InitForm();
  }

  ngAfterViewInit() {}

  private HideMemberNoteSection() {
    if (this.dtmatnotes.data.length == 0 && this.IsExternalUser() === true) {
      this.DivMemberNote.nativeElement.parentElement.parentElement.parentElement.parentElement.hidden = true;
    } else {
      this.DivMemberNote.nativeElement.parentElement.parentElement.parentElement.parentElement.hidden = false;
    }
  }

  public InitForm(loaddt: boolean = true) {
    this.comsvc.MemberNoteComponent = this;
    this.CanAccess();
    const mid = this.route.snapshot.params.id;
    if (mid !== undefined) {
      this.memberid = mid;
    } else {
      this.memberid = this.comsvc.MemberIdForNote;
    }
    if (loaddt) {
      if (this.selectedrole === ERole.member) {
        //Member
        this.LoadDataForMember(this.memberid, this.IsExternalUser());
      }
      //Admin
      else {
        this.LoadData(this.memberid, this.IsExternalUser());
      }
    }
    this.btnAddNoteClick = false;
    this.NoteTypesSelected = [];
    this.buttonText = "Add Note";
    this.updateditem = null;
    this.datasending.MemberNotes = [];
    this.MemberNoteFormGroup.get("slcNoteType").enable();
    this.MemberNoteFormGroup.get("chkSendEmail").setValue(false);
  }

  public IsExternalUser(): boolean {
    if (
      this.selectedrole === ERole.member ||
      this.selectedrole === ERole.Employer_Facilitator ||
      this.selectedrole === undefined ||
      this.selectedrole === null ||
      this.selectedrole === ""
    ) {
      return true;
    } else {
      return false;
    }
  }
  private LoadData(memberid: number, isexternalonly: boolean) {
    const dt = { MemberId: memberid, IsExternalOnly: isexternalonly };
    const encrypteddata = this.comsvc.E_FP_RequestData(JSON.stringify(dt));
    const encryptedtoken = this.comsvc.E_FP_RequestData(this.comsvc.GetToken());
    forkJoin(
      this.httpdao!.postData(APIConstant.API_GET_MEMBER_NOTES, {
        Data: encrypteddata,
        Header: encryptedtoken,
      })
    )
      .pipe(
        map(([notes]) => {
          return { notes };
        }),
        catchError(() => {
          return observableOf(null);
        })
      )
      .subscribe((res) => {
        if (res != null) {
          this.NoteTypes = CommonConstants.GET_NOTE_TYPES;
          const dectypted_data = this.comsvc.D_FP_ResponseData(res.notes);
          const rdata = JSON.parse(dectypted_data);
          this.databinding = rdata.Data;
          for (let i = 0; i < this.databinding.length; i++) {
            this.databinding[i].Type =
              this.databinding[i].NoteTypeId === 1 ? "Internal" : "External";
            this.databinding[i].DateTimeDisplay = this.ConvertToLocalTime(
              this.databinding[i].CreateOrUpdateDate
            );
            this.databinding[i].HoursofNote = new Date(
              this.databinding[i].CreateOrUpdateDate.toString() + "Z"
            ).getTime();
            this.databinding[i].Key = uuid();
          }
          this.dtmatnotes = new MatTableDataSource<MemberAdditionalNotes>(
            this.databinding
          );
          this.dtmatnotes.paginator = this.note_paginator;
          this.changeDetectorRefs.detectChanges();
          this.HideMemberNoteSection();
          this.SelectNoteType(
            this.NoteTypes.find((a) => a.Name.includes("Internal")).Id
          );
        }
      });
  }

  private LoadDataForMember(memberid: number, isexternalonly: boolean) {
    const dt = { MemberId: memberid, IsExternalOnly: isexternalonly };
    const encrypteddata = this.comsvc.E_FP_RequestData(JSON.stringify(dt));
    const encryptedtoken = this.comsvc.E_FP_RequestData(this.comsvc.GetToken());
    forkJoin(
      this.httpdao!.postData(APIConstant.API_GET_MEMBER_NOTES_FOR_MEMBER, {
        Data: encrypteddata,
        Header: encryptedtoken,
      })
    )
      .pipe(
        map(([notes]) => {
          return { notes };
        }),
        catchError(() => {
          return observableOf(null);
        })
      )
      .subscribe((res) => {
        if (res != null) {
          this.NoteTypes = CommonConstants.GET_NOTE_TYPES;
          const dectypted_data = this.comsvc.D_FP_ResponseData(res.notes);
          const rdata = JSON.parse(dectypted_data);
          this.databinding = rdata.Data;
          for (let i = 0; i < this.databinding.length; i++) {
            this.databinding[i].Type =
              this.databinding[i].NoteTypeId === 1 ? "Internal" : "External";
            this.databinding[i].DateTimeDisplay = this.ConvertToLocalTime(
              this.databinding[i].CreateOrUpdateDate
            );
            this.databinding[i].HoursofNote = new Date(
              this.databinding[i].CreateOrUpdateDate.toString() + "Z"
            ).getTime();
            this.databinding[i].Key = uuid();
          }
          this.dtmatnotes = new MatTableDataSource<MemberAdditionalNotes>(
            this.databinding
          );
          this.dtmatnotes.paginator = this.note_paginator;
          this.changeDetectorRefs.detectChanges();
          this.HideMemberNoteSection();
          this.SelectNoteType(
            this.NoteTypes.find((a) => a.Name.includes("Internal")).Id
          );
        }
      });
  }

  private ConvertToLocalTime(d: Date) {
    const localdate: Date = new Date(d.toString() + "Z");
    return DateHelper.format(localdate, "DD-MMM-YYYY HH:mm");
  }

  public CanAccess(): boolean {
    const temp = this.comsvc.GetSelectedRole();
    for (let i = 0; i < this.rolepermission.RolesObject.length; i++) {
      if (temp === this.rolepermission.RolesObject[i].Name) {
        this.selectedrole = temp;
        return true;
      }
    }
    if (this.selectedrole === "") {
      return false;
    }
  }

  public slcNoteType_Select(event) {
    const id: number = parseInt(event.target.value, 10);
    this.SelectNoteType(id);
  }

  private SelectNoteType(id: number) {
    if (id === null || id === undefined || id === 0) {
      return;
    }
    let selectedtype = this.NoteTypes.find((item) => item.Id === id);
    this.NoteTypesSelected.push(selectedtype);
    this.MemberNoteFormGroup.get("slcNoteType").setValue("");
  }

  public SetNoteTypeDisabled(nt: CommonResponse) {
    let isBool = this.NoteTypesSelected.some((nts) => nts.Id === nt.Id);
    return isBool;
  }

  private CreateMemberNoteForm() {
    this.MemberNoteFormGroup = this._formBuilder.group({
      slcNoteType: [""],
      txtMemberNotes: [""],
      chkSendEmail: [false],
    });
  }

  public UnsetNoteTypeSelected(nt: CommonResponse) {
    this.RemoveItemFromArray(this.NoteTypesSelected, nt);
  }

  private RemoveItemFromArray(arr: Array<any>, item: any) {
    var index = arr.indexOf(item);
    if (index > -1) {
      arr.splice(index, 1);
    }
  }

  private AddInternoteInCaseMultiNoteType() {
    const ret = this.NoteTypesSelected.find((nt) => nt.Id === 1);
    if (ret !== undefined) {
      const item: MemberAdditionalNotes = this.CreateNewMemberNote(1);
      item.WasAddedInCancelMode = true;
      this.databinding.unshift(item);
      this.datasending.MemberNotes.push(item);
    }
  }

  private ShowExtNoteConfirm() {
    const self = this;
    const textnoteforsub = this.MemberNoteFormGroup.get("txtMemberNotes").value;
    const msg1 =
      "An external note is about to be posted on the member's profile." +
      " This note will be visible to the member." +
      " Do you wish to continue?";

    const msg2 =
      "An external note is about to be posted on the member's profile." +
      " This note will be visible to the member." +
      " The member will also receive the note's content via email. Do you wish to continue?";

    let msg = "";
    const sende = this.MemberNoteFormGroup.get("chkSendEmail").value;
    msg = sende === true ? msg2 : msg1;

    MessageBox.ShowCancelContinue(
      this.dialog,
      "External Note Confirmation",
      msg
    ).subscribe((res) => {
      if (res.result.toLowerCase() === DialogResult.Continue) {
        const item: MemberAdditionalNotes = self.CreateNewMemberNote(2);
        item.Note = textnoteforsub;
        self.databinding.unshift(item);
        self.datasending.MemberNotes.push(item);
        //Add Internal if exist
        self.AddInternoteInCaseMultiNoteType();
        //-------

        self.dtmatnotes = new MatTableDataSource<MemberAdditionalNotes>(
          self.databinding
        );
        self.MemberNoteFormGroup.get("txtMemberNotes").setValue("");
        self.MemberNoteFormGroup.get("chkSendEmail").setValue(false);
        self.NoteTypesSelected = [];
        self.changeDetectorRefs.markForCheck();
        self.SaveMemberNoteLocal();
      }
    });
  }

  public btnAddMemberNote_Click(event) {
    this.btnAddNoteClick = true;
    if (this.buttonText === "Add Note") {
      if (
        this.NoteTypesSelected.length > 0 &&
        this.MemberNoteFormGroup.get("txtMemberNotes").value.trim() !== ""
      ) {
        if (this.NoteTypesSelected.length == 1) {
          if (this.NoteTypesSelected[0].Id === 2) {
            this.ShowExtNoteConfirm();
          } else {
            const item: MemberAdditionalNotes = this.CreateNewMemberNote(1);
            this.databinding.unshift(item);
            this.datasending.MemberNotes.push(item);
            this.dtmatnotes = new MatTableDataSource<MemberAdditionalNotes>(
              this.databinding
            );
            this.MemberNoteFormGroup.get("txtMemberNotes").setValue("");
            this.NoteTypesSelected = [];
            this.SaveMemberNoteLocal();
          }
        } else {
          this.ShowExtNoteConfirm();
        }
        this.btnAddNoteClick = false;
      }
    } else {
      if (this.MemberNoteFormGroup.get("txtMemberNotes").value.trim() !== "") {
        const self = this;
        MessageBox.ShowCancelContinue(
          this.dialog,
          "External Note Confirmation",
          "An external note is about to be posted on the member's profile." +
            " This note will be visible to the member. Do you wish to continue?"
        ).subscribe((res) => {
          if (res.result.toLowerCase() === DialogResult.Continue) {
            const noteobj = self.datasending.MemberNotes.find(
              (item) => item.Key === self.updateditem.Key
            );
            self.updateditem.Note = self.MemberNoteFormGroup.get(
              "txtMemberNotes"
            ).value;
            const currentUser =
              <User>(
                JSON.parse(
                  this.comsvc.D_FP_AES256(
                    localStorage.getItem(StorageKey.USER_OBJECT)
                  )
                )
              ) || <User>{};
            if (noteobj === undefined) {
              self.updateditem.Action = "update";
              self.updateditem.UserId = currentUser.UserId;
              self.updateditem.Username = currentUser.UserName;
              self.updateditem.DateTimeDisplay = DateHelper.format(
                new Date(),
                "DD-MMM-YYYY HH:mm"
              );
              self.updateditem.HoursofNote = new Date().getTime();
              self.datasending.MemberNotes.push(self.updateditem);
            } else {
              noteobj.UserId = currentUser.UserId;
              noteobj.Username = currentUser.UserName;
              noteobj.Note = self.MemberNoteFormGroup.get(
                "txtMemberNotes"
              ).value;
              noteobj.DateTimeDisplay = DateHelper.format(
                new Date(),
                "DD-MMM-YYYY HH:mm"
              );
              noteobj.HoursofNote = new Date().getTime();
            }
            self.databinding = self.databinding.sort((a, b) => {
              return <any>b.HoursofNote - <any>a.HoursofNote;
            });
            self.dtmatnotes = new MatTableDataSource<MemberAdditionalNotes>(
              self.databinding
            );
            self.changeDetectorRefs.markForCheck();

            self.btnAddNoteClick = false;
            self.NoteTypesSelected = [];
            self.buttonText = "Add Note";
            self.MemberNoteFormGroup.get("txtMemberNotes").setValue("");
            self.MemberNoteFormGroup.get("slcNoteType").enable();
            this.SaveMemberNoteLocal();
          }
        });
      }
    }
  }

  public DeleteMemberNote(item: MemberAdditionalNotes) {
    MessageBox.ShowCancelContinue(
      this.dialog,
      "Delete Note Confirmation",
      "You are about to delete this external note. This operation is irreversible. Do you wish to continue?"
    ).subscribe((res) => {
      if (res.result.toLowerCase() === DialogResult.Continue) {
        this.RemoveItemFromArray(this.databinding, item);
        this.dtmatnotes = new MatTableDataSource<MemberAdditionalNotes>(
          this.databinding
        );
        this.changeDetectorRefs.markForCheck();
        if (item.MemberNoteId !== undefined && item.MemberNoteId !== null) {
          item.Action = "delete";
          this.datasending.MemberNotes.push(item);
          this.SaveMemberNoteLocal();
        } else {
          this.RemoveItemFromArray(this.datasending.MemberNotes, item);
        }
      } else {
        return;
      }
    });
  }

  public UpdateMemberNote(item: MemberAdditionalNotes) {
    this.NoteTypesSelected = [];
    this.buttonText = "Update Note";
    this.MemberNoteFormGroup.get("txtMemberNotes").setValue(item.Note);
    this.txtMemberNotes.nativeElement.focus();
    this.updateditem = item;
    this.MemberNoteFormGroup.get("slcNoteType").disable();
    this.MemberNoteFormGroup.get("chkSendEmail").disable();
  }

  public SaveMemberNote(): Observable<any> {
    const dataoutput = this.httpdao!.postData(
      APIConstant.API_SAVE_MEMBER_NOTE,
      this.datasending
    );
    return dataoutput;
  }

  private SaveMemberNoteLocal() {
    this.comsvc.StartProgressBar();
    forkJoin(
      this.httpdao!.postData(APIConstant.API_SAVE_MEMBER_NOTE, this.datasending)
    )
      .pipe(
        map(([data]) => {
          return { data };
        }),
        catchError(() => {
          this.comsvc.StopProgressBar();
          return observableOf(null);
        })
      )
      .subscribe((res) => {
        if (res.data.Success) {
          this.InitForm();
        }
        this.comsvc.StopProgressBar();
      });
  }

  private CreateNewMemberNote(noteid: number): MemberAdditionalNotes {
    const item: MemberAdditionalNotes = new MemberAdditionalNotes();
    item.MemberId = this.memberid;
    item.NoteTypeId = noteid;
    item.Note = this.MemberNoteFormGroup.get("txtMemberNotes").value;
    const currentUser =
      <User>(
        JSON.parse(
          this.comsvc.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))
        )
      ) || <User>{};
    item.UserId = currentUser.UserId;
    item.Username = currentUser.UserName;
    item.Type = item.NoteTypeId === 1 ? "Internal" : "External";
    item.Action = "addnew";
    item.DateTimeDisplay = DateHelper.format(new Date(), "DD-MMM-YYYY HH:mm");
    item.HoursofNote = new Date().getTime();
    item.Key = uuid();
    item.IsSentEmail = this.MemberNoteFormGroup.get("chkSendEmail").value;
    return item;
  }

  public chkSendEmail_Change(event) {}

  public IsContainExternal() {
    const fi = this.NoteTypesSelected.find((it) => it.Id === 2);
    if (fi === undefined) {
      return false;
    } else {
      return true;
    }
  }
}
