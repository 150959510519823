import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Injector,
  OnInit,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { FPAbstractComponent } from "@fp/components/base";
import { CommonMessage, CommonString, RouterConstant } from "@fp/constants";
import { ECancellationAction, EMemberStatus, EMemberType } from "@fp/enums";
import {
  FPValidators,
  StringHelper,
  Utils,
  CustomMessageBox,
} from "@fp/helpers";
import {
  CommonData,
  CommonResponse,
  DataResult,
  Facility,
  MemberCancellation,
  NormalMembershipSuspension,
  ResultModel,
  SearchPaginationRequest,
  SearchPaginationResult,
} from "@fp/models";
import {
  CommonService,
  FacilityService,
  MemberService,
  MembershipService,
  MessageBox,
  MessageBoxButton,
} from "@fp/services";
import {
  NgbActiveModal,
  NgbDateAdapter,
  NgbDateNativeUTCAdapter,
  NgbDateStruct,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { empty, merge } from "rxjs";
import { catchError, startWith, switchMap } from "rxjs/operators";
import { TypeFeeCancellation } from "@fp/models/TypeFeeCancellation.model";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { ManipulateSysParamsService } from "@fp/services/manipulate-sys-params.service";
import { SystemParameters } from "@fp/models/system-parameters.model";

@Component({
  selector: "app-member-cancellation",
  templateUrl: "./member-cancellation.component.html",
  styleUrls: ["./member-cancellation.component.css"],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter }],
})
export class MemberCancellationComponent
  extends FPAbstractComponent
  implements OnInit, AfterViewInit, AfterViewChecked {
  IBMemberFG: UntypedFormGroup;
  suspensionFG: UntypedFormGroup;
  memberId: number;
  membershipId: number;
  membershipSuspensionId: number;
  memberStatusId: number;
  memberTypeId: number;
  startDate: string;
  CancellationActions: CommonData[] = [];
  CancellationReasons: CommonData[] = [];
  suspensionType: CommonData[] = [];
  suspensionReason: CommonData[] = [];
  facilitySearchResult: CommonResponse[] = [];
  suspensionIsEnded = true;
  cancellationDate = Date;
  isCancelMembershipButtons = false;
  isCancelMemberSubmitButton = true;

  CommonString = CommonString;
  CommonMessage = CommonMessage;
  StringHelper = StringHelper;
  routerConstant = RouterConstant;

  isSuspendMembership = false;

  get isCancelMembershipTest() {
    return this.isCancelMembership ? this.isCancelMemberSubmitButton : true;
  }

  get isCancelMembership() {
    return (
      this.IBMemberFG.get("MemberCancellationActionId").value ===
      ECancellationAction.CancelMembership
    );
  }

  get isUpdateCancelMembership() {
    return (
      this.IBMemberFG.get("MemberCancellationActionId").value ===
      ECancellationAction.UpdateCancelMembership
    );
  }
  get isRevokeCancelMembership() {
    return (
      this.IBMemberFG.get("MemberCancellationActionId").value ===
      ECancellationAction.RevokeCancelMembership
    );
  }

  get isBanMember() {
    return (
      this.IBMemberFG.get("MemberCancellationActionId").value ===
      ECancellationAction.BanMember
    );
  }
  isBanOrCancelMember = false;
  isCancelMemberOnly = false;
  isJoinedGymDirectly = false;
  submitClick = false;
  isSearchingFacility = false;
  allowMembershipSuspension = true;
  cancelSuspensionBtn = false;
  StartDateReadonly = false;
  parentFocus = 0;
  defaultAction: ECancellationAction;
  idCancellation: number;

  paging: SearchPaginationRequest = new SearchPaginationRequest();

  tfc: TypeFeeCancellation = null;

  currentDateForCancelAndBan: any;

  constructor(
    injector: Injector,
    public router: Router,
    private memberSvc: MemberService,
    private formBuilder: UntypedFormBuilder,
    private facilitySvc: FacilityService,
    public modal: NgbModal,
    private membershipSVC: MembershipService,
    public activeModal: NgbActiveModal,
    private commonservice: CommonService,
    private cdref: ChangeDetectorRef,
    private manSysParaSvc: ManipulateSysParamsService
  ) {
    super(injector);
    this.createIBMemberFG();
    this.createSuspensionFG();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.memberTypeId && this.memberTypeId != EMemberType.Primary) {
        this.allowMembershipSuspension = false;
      }
      this.loadCancellationMember(this.memberId);
      this.IBMemberFG.get("MemberCancellationActionId").valueChanges.subscribe(
        (value) => {
          this.selectAction(value);
        }
      );
      this.isBanOrCancelMember = (this.isBanMember || this.isCancelMembership);
      this.Invoke(this.manSysParaSvc.getSystemParameters(), {
        onSuccess: (result: DataResult<SystemParameters[]>) => {
          const param = result.Data.find((val) => val["ParameterId"] === 19);
          const offsetDays = parseInt(param.Value);
          const serverDate = this.GetCurrentDate();
          const currentDate = new Date(
            `${serverDate.year}/${serverDate.month}/${serverDate.day}`
          );
          currentDate.setHours(12, 0, 0, 0);
          currentDate.setDate(currentDate.getDate() + offsetDays);
          this.IBMemberFG.get("CancellationDate").setValue(currentDate);
        },
      });
    });
  }

  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  createIBMemberFG() {
    this.IBMemberFG = this.formBuilder.group({
      MemberCancellationActionId: [null, Validators.required],
      MemberCancellationReasonId: [null],
      CancellationFee: [""],
      CancellationDate: [""],
      CutupCardRecieved: [""],
      Facility: [null],
      FacilityName: [""],
      AdditionalNotes: ["",Validators.pattern("[a-zA-Z0-9 .:/!@#$%^&(+=_?<>;{})]*")],
      Reason: [""],
    });
  }

  createSuspensionFG() {
    this.suspensionFG = this.formBuilder.group({
      MembershipSuspensionTypeId: [null],
      MembershipSuspensionReasonId: [null],
      SuspensionStartDate: [""],
      SuspensionEndDate: [""],
      IsChargeSuspensionFee: [true],
    });
  }

  //Author: Yen
  txtSelectGym_Input(event) {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          const name = event.target.value;
          this.IBMemberFG.get("FacilityName").updateValueAndValidity({
            emitEvent: false,
          });
          this.paging.PageNumber = 0;
          this.paging.PageSize = 0;
          this.paging.Params = [{ Name: "name", Value: name }];
          if (name != null && name.length >= 3) {
            this.isSearchingFacility = true;
            return this.facilitySvc.search(this.paging);
          } else {
            this.facilitySearchResult = [];
            this.isSearchingFacility = false;
          }
          return empty();
        }),
        catchError((e) => {
          throw e;
        })
      )
      .subscribe(
        (result: string) => {
          const dectypted_data = this.commonservice.D_FP_ResponseData(result);
          const rdata = JSON.parse(dectypted_data);
          this.isSearchingFacility = false;
          if (result != null) {
            if (rdata.Success) {
              this.facilitySearchResult = rdata.Data.Results.map(
                (item) =>
                  <CommonResponse>{ Id: item.FacilityId, Name: item.Name }
              );
              if (rdata.Data.Results.length === 0) {
                this.IBMemberFG.get("Facility").updateValueAndValidity({
                  // onlySelf: true,
                });
                this.IBMemberFG.setErrors({ notexisting: true });
                this.IBMemberFG.get("Facility").setErrors({
                  notexisting: true,
                });
              }
            } else {
              console.error(result);
            }
          }
        },
        (error) => {
          this.isSearchingFacility = false;
          MessageBox.ShowError(
            this.dialog,
            "An error occurred while trying to call a service"
          );
          console.error(error);
        }
      );
  }

  txtSelectGym_Lostfocus(event) {
    const val = event.target.value.trim();
    if (!StringHelper.isNullOrEmpty(val)) {
      if (typeof this.IBMemberFG.value.Facility === "string") {
        this.IBMemberFG.setErrors({ notexisting: true });
        this.IBMemberFG.get("Facility").setErrors({ notexisting: true });
      }
    }
    if (val === "" && this.IBMemberFG.get("Facility").errors == null) {
      this.IBMemberFG.setErrors({ notexisting: false });
      this.IBMemberFG.updateValueAndValidity({
        onlySelf: true,
      });

      this.IBMemberFG.get("Facility").setErrors({ notexisting: false });
      this.IBMemberFG.get("Facility").updateValueAndValidity({
        onlySelf: true,
      });
    }
  }

  txtFacilityName_Input() {
    this.IBMemberFG.get("Facility").updateValueAndValidity({
      emitEvent: false,
    });
  }

  //Author: Yen
  loadCancellationMember(memberId: number) {
    this.InvokeBatch(
      [
        this.memberSvc.getCancellationActions(),
        this.memberSvc.getMemberCancelation(memberId),
      ],
      {
        onSuccess: (res) => {
          this.handleGetCancelationActionsResult(res[0]);
          if (this.defaultAction != null) {
            this.IBMemberFG.get("MemberCancellationActionId").setValue(
              this.defaultAction
            );
          } else {
            this.handleGetMemberCancellationResult(res[1]);
          }
        },
      }
    );
  }

  //Author: Yen
  handleGetMemberCancellationResult(result: DataResult<MemberCancellation>) {
    if (result != null) {
      if (result.Success) {
        if (
          result.Data &&
          this.memberStatusId != EMemberStatus.Active &&
          result.Data.MemberCancellationActionId !=
            ECancellationAction.SuspendMembership
        ) {
          this.IBMemberFG.patchValue(result.Data);
          this.idCancellation = result.Data.Id;
        } else {
          this.Invoke(
            this.membershipSVC.getMembershipSuspension(this.memberId),
            {
              onSuccess: (res: DataResult<NormalMembershipSuspension>) => {
                if (res != null) {
                  if (res.Success) {
                    console.log(res.Data);
                    this.cancelSuspensionBtn = false;
                    this.membershipId = res.Data.MembershipId;
                    this.membershipSuspensionId =
                      res.Data.MembershipSuspensionId;
                    if (
                      res.Data.MembershipSuspensionId != 0 &&
                      this.allowMembershipSuspension
                    ) {
                      this.cancelSuspensionBtn = true;
                      this.loadMasterDataSuspension();
                      this.toggleSection(ECancellationAction.SuspendMembership);
                      this.suspensionFG.patchValue(res.Data);
                      this.startDate = res.Data.SuspensionStartDate + "Z";
                      this.suspensionFG
                        .get("SuspensionStartDate")
                        .setValue(new Date(res.Data.SuspensionStartDate + "Z"));
                      this.suspensionFG
                        .get("SuspensionEndDate")
                        .setValue(new Date(res.Data.SuspensionEndDate + "Z"));
                      this.IBMemberFG.get(
                        "MemberCancellationActionId"
                      ).setValue(ECancellationAction.SuspendMembership, {
                        emitEvent: false,
                      });
                    }

                    if (this.IsSuspendStarted()) {
                      this.suspensionFG.get("SuspensionStartDate").disable();
                      this.StartDateReadonly = true;
                    } else {
                      this.suspensionFG.get("SuspensionStartDate").enable();
                      this.StartDateReadonly = false;
                    }
                  } else {
                    this.HandleResponseError(res);
                  }
                }
              },
            }
          );
        }
      } else {
        this.HandleResponseError(result);
      }
    }
  }

  //Author: Yen
  handleGetCancelationActionsResult(result: DataResult<CommonData[]>) {
    if (result != null) {
      if (result.Success) {
        this.CancellationActions = Utils.sortCommonData(result.Data);
      } else {
        this.HandleResponseError(result);
      }
    }
  }

  //Author: Yen
  getCancelationReasons() {
    this.Invoke(this.memberSvc.getCancellationReasons(), {
      onSuccess: (res: DataResult<CommonData[]>) => {
        if (res != null) {
          if (res.Success) {
            this.CancellationReasons = Utils.sortCommonData(res.Data);
            const selectedReason = this.IBMemberFG.get(
              "MemberCancellationReasonId"
            ).value;
            if (selectedReason && (selectedReason == 10 || selectedReason == 9)) {
              this.isJoinedGymDirectly = true;
            }
          } else {
            this.HandleResponseError(res);
          }
        }
      },
    });
  }

  //Author: Yen
  loadMasterDataSuspension() {
    this.InvokeBatch(
      [
        this.membershipSVC.getMembershipSuspensionType(),
        this.membershipSVC.getMembershipSuspensionReason(),
      ],
      {
        onSuccess: (res) => {
          this.handleSuspensionTypeResult(res[0]);
          this.handleSuspensionReasonResult(res[1]);
        },
      }
    );
  }

  getMembershipSuspension() {
    console.log("Called by member");
    this.Invoke(this.membershipSVC.getMembershipSuspension(this.memberId), {
      onSuccess: (res: DataResult<NormalMembershipSuspension>) => {
        if (res != null) {
          console.log(res);
          if (res.Success) {
            console.log(res.Data);
            this.cancelSuspensionBtn = false;
            this.membershipId = res.Data.MembershipId;
            this.membershipSuspensionId = res.Data.MembershipSuspensionId;
            if (res.Data.MembershipSuspensionId != 0) {
              this.cancelSuspensionBtn = true;
              this.suspensionFG.patchValue(res.Data);
              this.startDate = res.Data.SuspensionStartDate + "Z";
              this.suspensionFG
                .get("SuspensionStartDate")
                .setValue(new Date(res.Data.SuspensionStartDate + "Z"));
              this.suspensionFG
                .get("SuspensionEndDate")
                .setValue(new Date(res.Data.SuspensionEndDate + "Z"));
            }

            if (this.IsSuspendStarted()) {
              this.suspensionFG.get("SuspensionStartDate").disable();
              this.StartDateReadonly = true;
            } else {
              this.suspensionFG.get("SuspensionStartDate").enable();
              this.StartDateReadonly = false;
            }
            // active suspension exists in db and cancel suspension button has not been initiated
            if(res.Data.IsEnded === false && !this.cancelSuspensionBtn) {
              this.suspensionIsEnded = false;
            }
          } else {
            this.HandleResponseError(res);
          }
        }
      },
    });
  }

  IsSuspendStarted(): boolean {
    const startd = new Date(this.startDate);
    const cdate = new Date(this.commonservice.CurrentUTCServerDate);
    startd.setHours(0, 0, 0, 0);
    if (startd <= cdate) {
      return true;
    } else {
      return false;
    }
  }

  //Author: Yen
  handleSuspensionTypeResult(result: DataResult<CommonData[]>) {
    if (result != null) {
      if (result.Success) {
        this.suspensionType = result.Data;
      } else {
        this.HandleResponseError(result);
      }
    }
  }

  //Author: Yen
  handleSuspensionReasonResult(result: DataResult<CommonData[]>) {
    if (result != null) {
      if (result.Success) {
        this.suspensionReason = result.Data;
      } else {
        this.HandleResponseError(result);
      }
    }
  }

  //Author: Yen
  selectAction(actionId: number) {
    console.log("Action ID ", actionId);
    this.toggleSection(actionId);
    if (
      actionId == ECancellationAction.SuspendMembership &&
      this.allowMembershipSuspension
    ) {
      this.loadMasterDataSuspension();
      this.getMembershipSuspension();
    } else {
      if (actionId == ECancellationAction.CancelMembership) {

        this.loadMemberCancellationDataToForm(this.memberId);
      }
      else if (actionId == ECancellationAction.BanMember)
      {
        this.isCancelMemberSubmitButton = true;
        this.IBMemberFG.get("MemberCancellationActionId").setValue(ECancellationAction.BanMember, {
          emitEvent: false,
        });
        console.log("Setting Action ID " + actionId);
      }
      this.isBanOrCancelMember = true;
      this.getCancelationReasons();
    }
  }

  toggleSection(action: ECancellationAction) {
    this.reloadPopup();
    this.createSuspensionFG();
    this.IBMemberFG.get("Reason").clearValidators();
    this.IBMemberFG.get("MemberCancellationReasonId").clearValidators();
    this.IBMemberFG.get("MemberCancellationReasonId").setValue(null);
    this.IBMemberFG.get("CancellationFee").clearValidators();
    this.IBMemberFG.get("CancellationFee").setValue("");
    this.IBMemberFG.get("Facility").setValue(null);
    this.IBMemberFG.get("FacilityName").setValue("");
    this.IBMemberFG.get("CutupCardRecieved").clearValidators();
    this.IBMemberFG.get("CutupCardRecieved").setValue(null);
    this.IBMemberFG.get("AdditionalNotes").setValue("");
    this.IBMemberFG.get("Reason").setValue("");
    if (
      action == ECancellationAction.SuspendMembership &&
      this.allowMembershipSuspension
    ) {
      this.isSuspendMembership = true;
      this.suspensionFG
        .get("MembershipSuspensionTypeId")
        .setValidators([Validators.required]);
      this.suspensionFG
        .get("MembershipSuspensionReasonId")
        .setValidators([Validators.required]);
      this.suspensionFG
        .get("SuspensionStartDate")
        .setValidators([
          Validators.required,
          CustomFacilityValidators.ValidateDate,
          CustomFacilityValidators.MinDateValidator(
            this.commonservice.CurrentUTCServerDate
          ),
          CustomFacilityValidators.RangeDateValidator,
        ]);
      this.suspensionFG
        .get("SuspensionEndDate")
        .setValidators([
          Validators.required,
          CustomFacilityValidators.ValidateDate,
          CustomFacilityValidators.MinDateValidator(
            this.commonservice.CurrentUTCServerDate
          ),
          CustomFacilityValidators.RangeDateValidator,
        ]);
    } else {
      this.IBMemberFG.get("MemberCancellationReasonId").setValidators([
        Validators.required,
      ]);
      this.IBMemberFG.get("CancellationFee").setValidators([
        Validators.required,
      ]);
      this.IBMemberFG.get("CutupCardRecieved").setValidators([
        Validators.required,
      ]);
    }
    this.IBMemberFG.get("Reason").updateValueAndValidity({ emitEvent: false });
    this.IBMemberFG.get("MemberCancellationReasonId").updateValueAndValidity({
      emitEvent: false,
    });
    this.suspensionFG
      .get("SuspensionStartDate")
      .updateValueAndValidity({ emitEvent: false });
    this.suspensionFG
      .get("SuspensionEndDate")
      .updateValueAndValidity({ emitEvent: false });
  }

  //Author: Yen
  selectCancellationReason(reasonId) {
    this.IBMemberFG.get("Facility").clearValidators();
    this.IBMemberFG.get("FacilityName").clearValidators();
    this.isJoinedGymDirectly = false;
    //id 10: Joined gym directly
    if (reasonId == 10 || reasonId == 9) {
      this.isJoinedGymDirectly = true;
      this.IBMemberFG.get("Facility").setValidators([
        CustomFacilityValidators.SelectGymRequiredWhen,
      ]);
      this.IBMemberFG.get("FacilityName").setValidators([
        CustomFacilityValidators.FacilityNameRequiredWhen,
      ]);
    }
    this.IBMemberFG.get("Facility").updateValueAndValidity({
      emitEvent: false,
    });
    this.IBMemberFG.get("FacilityName").updateValueAndValidity({
      emitEvent: false,
    });
  }

  //Author: Yen
  Cancel() {
    this.activeModal.dismiss();
  }

  //Author: Yen
  reloadPopup() {
    this.submitClick = false;
    this.isSuspendMembership = false;
    this.isJoinedGymDirectly = false;
  }

  //Author: Yen
  submitAction() {
    this.submitClick = true;
    if (this.suspensionFG.invalid || this.IBMemberFG.invalid) return;
    const actionId = this.IBMemberFG.get("MemberCancellationActionId").value;
    if (actionId == ECancellationAction.SuspendMembership) {
      this.submitSuspension();
    } else {
      this.submitCancellation();
    }
  }

  //Author: Yen
  submitSuspension() {
    let suspension: NormalMembershipSuspension;
    suspension = this.suspensionFG.value;
    suspension.MembershipId = this.membershipId;
    suspension.MembershipSuspensionId = this.membershipSuspensionId;
    suspension.ModifiedBy = this.commonservice.GetUser();
    if (
      suspension.SuspensionStartDate == undefined ||
      suspension.SuspensionStartDate == ""
    ) {
      suspension.SuspensionStartDate = this.startDate;
    }
    const d1: Date = new Date(this.startDate);
    const d2: Date = new Date(
      this.suspensionFG.get("SuspensionStartDate").value
    );
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
    if (
      this.membershipSuspensionId != 0 &&
      this.IsSuspendStarted() &&
      d1.getTime() != d2.getTime()
    ) {
      MessageBox.ShowInfo(
        this.dialog,
        "The suspension was started. Cannot change the start date."
      ).subscribe((data) => {
        this.getMembershipSuspension();
      });
      return;
    }
    if(!this.suspensionIsEnded) {
      MessageBox.ShowCustom(this.dialog, 'Warning', 'Warning',
      `An active suspension currently exists. This action will end any active suspensions. Are you sure you want to continue?`,
      MessageBoxButton.YesNo).subscribe(r => {
          if (r.result == DialogResult.Yes) {
            this.Invoke(this.membershipSVC.saveMembershipSuspension(suspension), {
              onSuccess: (res: ResultModel) => {
                if (res != null) {
                  if (res.Success) {
                    this.activeModal.close({ returnValue: "success!" });
                  } else {
                    this.HandleResponseError(res);
                  }
                }
              },
            });
          } else {
            this.dialog.closeAll();
          }
      });
    }
    else {
      this.Invoke(this.membershipSVC.saveMembershipSuspension(suspension), {
        onSuccess: (res: ResultModel) => {
          if (res != null) {
            if (res.Success) {
              this.activeModal.close({ returnValue: "success!" });
            } else {
              this.HandleResponseError(res);
            }
          }
        },
      });
    }
  }

  loadMemberCancellationDataToForm(memberId: number) {
    console.log("Test Load member " + memberId);
    this.Invoke(this.memberSvc.getMemberCancellationLoad(memberId), {
      onSuccess: (res: DataResult<MemberCancellation>) => {
        if (res != null && res.Data != null) {
          let memberCancellationdate =
            res.Data.MemberCancellationScheduleDate.toString();
          console.log(
            "Member Cancellation Date String : " + memberCancellationdate
          );

          let splitMemberCancellationDate =
            memberCancellationdate.split("T")[0];
          var today = new Date(splitMemberCancellationDate);
          console.log("today : " + today);

          console.log("Getting updated");
          console.log("Resource : ", res);
          if (res != null) {
            console.log(res.Data.AdditionalNotes);
            if (res.Success) {
              this.isCancelMembershipButtons = true;
              this.isCancelMemberSubmitButton = false;
              this.IBMemberFG.get("MemberCancellationReasonId").setValue(
                res.Data.MemberCancellationReasonId
              );
              this.IBMemberFG.get("Facility").setValue(res.Data.Facility);
              this.IBMemberFG.get("FacilityName").setValue(
                res.Data.FacilityName
              );
              this.IBMemberFG.get("MemberCancellationReasonId").setValue(
                res.Data.MemberCancellationReasonId
              );
              this.IBMemberFG.get("CancellationFee").setValue(
                res.Data.MemberCancellationFee
              );
              this.IBMemberFG.get("AdditionalNotes").setValue(
                res.Data.AdditionalNotes
              );
              this.IBMemberFG.get("CancellationDate").setValue(today);
              //this.activeModal.close({ returnValue: "success!" });
              this.IBMemberFG.get("CutupCardRecieved").setValue(res.Data.CutUpCardRecieve);
            } else {
              this.HandleResponseError(res);
            }
          }
        }
      },
    });
  }

  //Author: Yen
  submitCancellation() {
    let cutUpCardRecieve = this.IBMemberFG.get("CutupCardRecieved").value;
    console.log("cutUpCardRecieve : " + cutUpCardRecieve);

    let cancelationDate = this.IBMemberFG.get("CancellationDate").value;
    cancelationDate.setHours(12, 0, 0, 0);

    let currentDate = new Date();
    currentDate.setHours(12, 0, 0, 0);

    console.log("Current Date " + currentDate);
    console.log("Cancellation Date" + cancelationDate);

    let member: MemberCancellation;
    member = this.IBMemberFG.value;
    member.MemberStatusId = 1;
    member.MemberId = this.memberId;
    member.Id = this.idCancellation;
    member.ModifiedBy = this.commonservice.GetUser();
    member.Reason = member.AdditionalNotes;
    member.MemberCancellationScheduleDate = cancelationDate.toISOString();
    member.CutUpCardRecieve = cutUpCardRecieve;

    let CancelFee: number = this.IBMemberFG.get("CancellationFee").value;
    if (CancelFee === undefined || CancelFee === null) {
      CancelFee = 0.0;
    }
    member.MemberCancellationFee = CancelFee;
    if (member.Facility == null) {
      member.Facility = {
        Name: null,
        Id: 0,
      };
    }

    console.log("MemberCancellationScheduleDate : " + member.MemberCancellationScheduleDate);

    if (cancelationDate.toISOString().split("T")[0] == currentDate.toISOString().split("T")[0] || this.isBanMember ) {
      this.Invoke(this.memberSvc.saveMemberCancelation(member), {
        onSuccess: (res: ResultModel) => {
          console.log(res);
          if (res != null) {
            if (res.Success) {
              this.activeModal.close({ returnValue: "success!" });
            } else {
              this.HandleResponseError(res);
            }
          }
        },
      });
    } else {
      this.Invoke(this.memberSvc.MemberCancellationScheduleUpdate(member), {
        onSuccess: (res: ResultModel) => {
          console.log(res);
          if (res != null) {
            if (res.Success) {
              this.activeModal.close({ returnValue: "success!" });
            } else {
              this.HandleResponseError(res);
            }
          }
        },
      });
    }
  }

  updateSubmitCancellation() {
    if (this.IBMemberFG.invalid) return;

    let cutUpCardRecieve = this.IBMemberFG.get("CutupCardRecieved").value;
    console.log("cutUpCardRecieve : " + cutUpCardRecieve);

    let cancelationDate = this.IBMemberFG.get("CancellationDate").value;
    cancelationDate.setHours(12, 0, 0, 0);

    let currentDate = new Date();
    currentDate.setHours(12, 0, 0, 0);

    if (this.isBanMember) {
      this.IBMemberFG.setValue({ cancelationDate: currentDate });
    }

    let member: MemberCancellation;
    member = this.IBMemberFG.value;
    member.MemberStatusId = 2;
    member.MemberId = this.memberId;
    member.Id = this.idCancellation;
    member.ModifiedBy = this.commonservice.GetUser();
    member.Reason = member.AdditionalNotes;
    member.MemberCancellationScheduleDate = cancelationDate.toISOString();
    member.CutUpCardRecieve = cutUpCardRecieve;

    let CancelFee: number = this.IBMemberFG.get("CancellationFee").value;
    if (CancelFee === undefined || CancelFee === null) {
      CancelFee = 0.0;
    }
    member.MemberCancellationFee = CancelFee;
    if (member.Facility == null) {
      member.Facility = {
        Name: null,
        Id: 0,
      };
    }

    console.log("Cancellation Date : " + cancelationDate.toISOString().split("T")[0]);
    console.log("Current Date : " + currentDate.toISOString().split("T")[0])

    if (cancelationDate.toISOString().split("T")[0] == currentDate.toISOString().split("T")[0]) {
      member.MemberStatusId = 4;

      this.Invoke(this.memberSvc.saveMemberCancelation(member), {
        onSuccess: (res: ResultModel) => {
          console.log(res);
          if (res != null) {
            if (res.Success) {
              this.Invoke(this.memberSvc.MemberCancellationScheduleUpdate(member), {
                onSuccess: (res: ResultModel) => {
                  console.log(res);
                  if (res != null) {
                    if (res.Success) {
                      this.activeModal.close({ returnValue: "success!" });
                    } else {
                      this.HandleResponseError(res);
                    }
                  }
                },
              });
            } else {
              this.HandleResponseError(res);
            }
          }
        },
      });
    } else {
      this.Invoke(this.memberSvc.MemberCancellationScheduleUpdate(member), {
        onSuccess: (res: ResultModel) => {
          console.log(res);
          if (res != null) {
            if (res.Success) {
              this.activeModal.close({ returnValue: "success!" });
            } else {
              this.HandleResponseError(res);
            }
          }
        },
      });
    }
  }

  revokeSubmitCancellation() {
    let member: MemberCancellation;
    member = this.IBMemberFG.value;
    member.MemberId = this.memberId;
    member.Id = this.idCancellation;
    member.ModifiedBy = this.commonservice.GetUser();
    member.Reason = member.AdditionalNotes;
    member.MemberStatusId = 3;

    this.Invoke(this.memberSvc.MemberCancellationScheduleUpdate(member), {
      onSuccess: (res: ResultModel) => {
        if (res != null) {
          if (res.Success) {
            this.activeModal.close({ returnValue: "success!" });
          } else {
            this.HandleResponseError(res);
          }
        }
      },
    });
  }


  GetCurrentDate(): NgbDateStruct {
    const cd = this.commonservice.CurrentUTCServerDate;
    let d: NgbDateStruct = {
      year: cd.getFullYear(),
      month: cd.getMonth() + 1, // must plus 1 because the month run from 0
      day: cd.getDate(),
    };
    return d;
  }

  checkValidateIBMemberFG(controlName: string) {
    const form = this.IBMemberFG.get(controlName);
    let isBool: boolean = false;
    isBool = form.invalid && (form.touched || form.dirty || this.submitClick);
    return isBool;
  }

  checkValidatesuspendFG(controlName: string) {
    const form = this.suspensionFG.get(controlName);
    let isBool: boolean = false;
    isBool = form.invalid && (form.touched || form.dirty || this.submitClick);
    return isBool;
  }

  AutoComplete_DisplayWithFn(option: any) {
    if (!option) {
      return "";
    }
    return option.Name;
  }

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }

  onBlurParent(focusNumbr) {
    this.parentFocus = focusNumbr;
  }

  public btnCalculateFee_Click(event) {
    this.GetFeeCancellation();
  }

  private GetFeeCancellation() {
    this.Invoke(this.membershipSVC.getTypeFeeCancellation(this.memberId), {
      onSuccess: (res: DataResult<TypeFeeCancellation>) => {
        if (res != null) {
          if (res.Success) {
            const data: TypeFeeCancellation = res.Data;
            this.CalculateCancelFee(data);
          } else {
            this.HandleResponseError(res);
          }
        }
      },
    });
  }

  private CalculateCancelFee(data: TypeFeeCancellation) {
    let cancelationDateForCalculateFees = this.IBMemberFG.get("CancellationDate").value;
    cancelationDateForCalculateFees.setHours(12, 0, 0, 0);
    console.log("Selected date is: "+cancelationDateForCalculateFees);

    if(!this.isBanMember){
      data.MemberCancellationScheduleDate = cancelationDateForCalculateFees;
      console.log("After assigned(Cancel): "+data.MemberCancellationScheduleDate);
    }

    else
    {
      this.currentDateForCancelAndBan = new Date().toDateString();
      data.MemberCancellationScheduleDate = this.currentDateForCancelAndBan;
      console.log("After assigned(Ban): "+data.MemberCancellationScheduleDate);
    }

    this.Invoke(this.membershipSVC.calculateCancelFee(data), {
      onSuccess: (res: DataResult<TypeFeeCancellation>) => {
        if (res != null) {
          if (res.Success) {
            this.tfc = res.Data;
            this.IBMemberFG.get("CancellationFee").setValue(this.tfc.FinalFee);
          } else {
            this.HandleResponseError(res);
          }
        }
      },
    });
  }

  confirmCancelSuspension() {
    CustomMessageBox.Show(
      this.dialog,
      {
        title: "Suspension Cancellation Confirmation",
        content:
          "<p>Cancelling the current suspension period will reset all suspension fields, including suspension dates. This action is irreversible and a new suspension period will have to be created if necessary. Do you wish to continue?</p>",
        buttons: [
          {
            label: "CANCEL",
            dialogResult: "cancel",
          },
          {
            label: "CONTINUE",
            isPrimary: true,
            dialogResult: "continue",
          },
        ],
      },
      {
        width: "450px",
        afterClosed: (r) => {
          if (r.result === "continue") {
            this.cancelSuspension();
          }
        },
      }
    );
  }

  private cancelSuspension() {
    let cancelSuspension: NormalMembershipSuspension = new NormalMembershipSuspension();
    cancelSuspension.MembershipSuspensionId = this.membershipSuspensionId;
    cancelSuspension.ModifiedBy = this.commonservice.GetUser();
    this.Invoke(this.membershipSVC.cancelSuspension(cancelSuspension), {
      onSuccess: (res: ResultModel) => {
        if (res != null) {
          if (res.Success) {
            this.startDate = "";
            this.selectAction(ECancellationAction.SuspendMembership);
            this.activeModal.close({ returnValue: "success!" });
          } else {
            this.HandleResponseError(res);
          }
        }
      },
    });
  }
}

abstract class CustomFacilityValidators {
  static SelectGymRequiredWhen = FPValidators.requiredWhen((ctrl) => {
    if (ctrl.parent) {
      const relatedCtrl = ctrl.parent.get("FacilityName");
      if (
        relatedCtrl &&
        (relatedCtrl.value == null || relatedCtrl.value.trim().length == 0)
      ) {
        return true;
      }
    }
    return false;
  });

  static FacilityNameRequiredWhen = FPValidators.requiredWhen((ctrl) => {
    if (ctrl.parent) {
      const relatedCtrl = ctrl.parent.get("Facility");
      if (
        relatedCtrl &&
        (relatedCtrl.value == null || relatedCtrl.value == "")
      ) {
        return true;
      }
    }
    return false;
  });

  static MinDateValidator(currentutcdate: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined) {
        const dstr = new Date(control.value);
        let dc = currentutcdate;
        dstr.setHours(0, 0, 0, 0);
        dc.setHours(0, 0, 0, 0);
        if (dstr < dc) {
          return { mindateinvalid: true };
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  static ValidateDate(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (
      control.value == null ||
      control.value == "" ||
      control.value instanceof Object
    ) {
      return null;
    } else {
      return { invalidFormatDate: true };
    }
  }

  static RangeDateValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value !== undefined) {
      const enddate: Date = new Date(
        control.parent.get("SuspensionEndDate").value
      );
      const startdate: Date = new Date(
        control.parent.get("SuspensionStartDate").value
      );
      enddate.setHours(0, 0, 0, 0);
      startdate.setHours(0, 0, 0, 0);
      if (startdate == null || enddate == null) {
        return null;
      } else {
        if (enddate <= startdate && control.value != null) {
          return { rangedateinvalid: true };
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  }
}