<div [formGroup]="form">
    <div *ngIf="!isMemberSelfService">
        <div class="form-group row">
            <label for="txtDateATCs" class="col-md-3 col-lg-2 col-form-label">Date Accepted T&Cs</label>
            <div class="col-md-8 col-lg-5">
                <div class="d-flex flex-column w-100">
                    <input type="text" class="form-control" id="txtDateATCs"
                        formControlName="TermConditionDateTimeStamp" ngbDatepicker readonly>
                </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="MembershipCtrl.length > 0" formArrayName="Memberships"> -->
    <!-- <div [formControl]="MembershipCtrl"> -->
    <div>
        <div>
            <div class="form-group row">
                <label for="txtContractSD" class="col-md-3 col-lg-2 col-form-label">Contract Start Date (Date of first
                    payment)</label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control"  id="txtContractSD" formControlName="ContractStartDate" readonly>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="txtContractED" class="col-md-3 col-lg-2 col-form-label">Contract End Date</label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <input type="text" class="form-control" id="txtContractED" formControlName="ContractEndDate" readonly>
                    </div>
                </div>
            </div>
            <div class="form-group mt-4" *ngIf="!isMemberSelfService">
                <a class="btn btn-primary" href="https://www.fitnesspassport.com.au/termsandconditions" target="_blank">
                    <i class="fas fa-eye mr-2"></i>View Contract
                </a>
            </div>
        </div>
    </div>
</div>