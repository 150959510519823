import { jsPDF } from 'jspdf';
import { PositionTextModel, PositionRectModel } from "./report-object.model";
import { FpFileUploadComponent } from '@fp/components/control';
import { ViewChild } from '@angular/core';

export class PdfReportingService {

    public pdf = new jsPDF('l', 'mm', 'a4');
    constructor() {

    }

    public PaintText(objtext: PositionTextModel, iswarptext = false) {
        const { FontName, FontSize, FontStyle } = objtext
        this.pdf.setFontSize(FontSize);
        this.pdf.setFont(FontName, FontStyle);
        if (iswarptext === true) {
            objtext.Text = this.Wrap(objtext.Text, objtext.LimitWrapText);
        }
        this.pdf.text(objtext.Text, objtext.HorizonX, objtext.VerticalY);
    }

    public PaintRect(objrect: PositionRectModel) {
        this.pdf.rect(objrect.X, objrect.Y, objrect.Width, objrect.Height);
    }

    public SavePdfToFile(filename: string) {
        this.pdf.save(filename);
    }

    public ExportBlob(): Blob {
        const blob: Blob = this.pdf.output('blob');
        return blob;
    }

    private Wrap(text, limit) {
        if (text.length > limit) {
            // find the last space within limit
            const edge = text.slice(0, limit).lastIndexOf(' ');
            if (edge > 0) {
                const line = text.slice(0, edge);
                const remainder = text.slice(edge + 1);
                return line + '\n' + this.Wrap(remainder, limit);
            }
        }
        return text;
    }

}