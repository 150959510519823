import { Component, Injector, ViewChild } from '@angular/core';
import { MemberFormBase, FPFormBaseComponent } from '@fp/components/base';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MemberBankAccountComponent } from '../shared/bank-account/bank-account.component';
import { User, DataResult, Member, BankAccount, NewTermsAndConditions, TermsAndConditions } from '@fp/models';
import { StorageKey, RouterConstant, CommonMessage } from '@fp/constant';
import { CommonDataService, MemberService, MessageBox } from '@fp/services';
import { Router } from '@angular/router';
import { CommonService } from "src/app/services/common.service";
import { MemberContainer } from '@fp/models/member-container.model';
import { CommonConstants } from '@fp/constant/common-constants';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'app-member-update-payments-details',
	templateUrl: './update-payments-details.component.html',
	styleUrls: ['./update-payments-details.component.css'],
	providers: [
		FPFormBaseComponent.provideExisting(MemberUpdatePaymentsDetailsComponent),
		MemberFormBase.provideExisting(MemberUpdatePaymentsDetailsComponent)]
})
export class MemberUpdatePaymentsDetailsComponent extends MemberFormBase {

	currentUser: any;
	@ViewChild('bankAccount') bankAccount: MemberBankAccountComponent;
	bankFormGroup: UntypedFormGroup;
	currentTermsAndConditions: TermsAndConditions;
	termsAndConsContent: SafeHtml;
	checkRightUI:boolean = false;
	isAustralia = true;
	updateHeading: string = "";
	countriesId = CommonConstants.GET_COUNTRIES;

	constructor(injector: Injector,
		private memberSvc: MemberService,
		private router: Router, private commonservice: CommonService,
		private commonDataSvc: CommonDataService,
		private sanitizer: DomSanitizer,
		) {
		super(injector);
	}

	ngOnInit() {
		this.isAustralia = this.commonservice.getUserCountry()?.toUpperCase() !==
		  CommonConstants.COUNTRY_NEW_ZEALAND; 		
	  }

	ngAfterViewInit() {
		this.OnInit();
		this.LoadComplete();
		if(!this.isAustralia){
			this.LoadTermsConditions();
		}		
	}

	OnInit() {
		super.OnInit();
		this.commonSvc.Header.title = "Payment Details";
		this.bankFormGroup = MemberBankAccountComponent.getFormGroup();
	}

	LoadComplete() {
		super.LoadComplete();
		this.loadMemberDetails();
	}

	LoadTermsConditions(){
		this.Invoke(
			this.commonDataSvc.getAllTermsAndConditions(),
			{
			  onSuccess: (res: DataResult<NewTermsAndConditions[]>) => {          
				if (res.Success) {            
					const goCardlessData = res.Data.filter(term => term.CountryCode === this.countriesId[1].CountryId && term.TermConditionType === CommonConstants.GOCARDLESS_TERMS)[0];                       
					this.currentTermsAndConditions = goCardlessData;					
					this.termsAndConsContent = this.sanitizer.bypassSecurityTrustHtml(
						this.currentTermsAndConditions.Content
					);
					this.checkRightUI = true;
				} else {
					MessageBox.ShowError(this.dialog, 'An error occurred while trying to call a service');
                    console.error(res);
					this.checkRightUI = false;
				}
			  },
			  onError: (err) => {
				MessageBox.ShowError(this.dialog, 'An error occurred while trying to call a service');
                console.error(err);
				this.checkRightUI = false;
			  },
			}
		  );
	}

	currentMember: any;
	loadMemberDetails() {
		this.currentUser = <User>JSON.parse(this.commonSvc.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT)));
		const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
		if(getContainer != null) {
			let container = <MemberContainer>JSON.parse(getContainer);
			this.currentMember = <DataResult<Member>>JSON.parse(this.commonSvc.D_FP_AES256(container.Member));
		}
	
		if (this.currentUser && this.currentUser.UserId > 0) {
			if(this.currentMember != null) {
			  this.loadDetails(this.currentMember);
			}
			else {
				this.Invoke(
					this.memberSvc.getByUserForMember(this.currentUser.UserId),
					{
						onSuccess: (res: DataResult<Member>) => {
							this.loadDetails(res);
						}
					}
				);
			}
		} else {
			MessageBox.ShowError(this.dialog, 'Invalid user');
		}
	}

	loadDetails(res: DataResult<Member>) {
		if (res.Success) {
			if (res.Data == null) {
				MessageBox.ShowError(this.dialog, 'Member info not found for current user').subscribe(() => {
					this.router.navigate([RouterConstant.ROUTER_DASHBOARD]);
				});

			} else {
				if (res.Data != null) {
					this.PatchValue(res.Data, { emitEvent: false });
				}
				this.form.markAsPristine();
				super.OnLoad();
			}
		} else {
			this.HandleResponseError(res);
		}
	}

	btnSave_Click() {
		console.log("Submit");
		this.Validate();
		if(!this.bankAccount.valid) {
			return;
		}
		this.data.model = this.applyValue(
			this.data.model,
			this.bankAccount
		);
		this.data.model.BankAccount.ModifiedBy = this.commonservice.GetUser();
		this.Invoke(this.memberSvc.updatePaymentDetail(this.data.model.BankAccount), {
			onSuccess: (res: DataResult<BankAccount>) => {
				if (res.Success) {
					const currentUser = <User>JSON.parse(this.commonservice.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
					const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
					if(getContainer != null) {
						let container = <MemberContainer>JSON.parse(getContainer);
						this.memberSvc
							.getByUserForMember(currentUser.UserId)
							.subscribe((getMember) => {
								let memberEncrypt = this.commonservice.E_FP_AES256(
									JSON.stringify(getMember)
								);
								container.Member = memberEncrypt;
								const loginData = JSON.stringify(container);
								localStorage.setItem(
									StorageKey.CONTAINER_OBJECT,
									loginData
								);
								MessageBox.ShowInfo(
									this.dialog,
									CommonMessage.DATA_SAVE_SUCCESS_MESSAGE
									).subscribe(data => {
										super.OnLoad();
									});
								});
					} else {
						MessageBox.ShowInfo(
							this.dialog,
							CommonMessage.DATA_SAVE_SUCCESS_MESSAGE
							).subscribe(data => {
								super.OnLoad();
							});
					}
				} else {
					this.HandleResponseError(res);
				}
			}
		});
	}

}
