import { CommonService } from './common.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConstant } from 'src/app/constant';
import {
    DataResult, ResultModel, SearchPaginationRequest, SearchPaginationResult,
    MembershipCardsModel, MemberCardActionModel
} from '../models';
import { HttpDAO } from './httpdao.service';

@Injectable({
    providedIn: 'root'
})
export class MemberCardService {

    constructor(
        private httpDao: HttpDAO,
        private cmsvc: CommonService
    ) {
    }

    search(request: SearchPaginationRequest): Observable<DataResult<SearchPaginationResult<MembershipCardsModel>>> {

        // request.Params.push({ Name: 'caller', Value: this.cmsvc.GetUser() })
        // console.log(request);
        const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken())
        const encrypteddata = this.cmsvc.E_FP_RequestData(
            JSON.stringify(request)
        );
        return this.httpDao.postData(APIConstant.API_SEARCH_MEMBERSHIP_CARDS, { "Data": encrypteddata, "Header": encryptedtoken  });
    }

    searchformember (request: SearchPaginationRequest): Observable<DataResult<SearchPaginationResult<MembershipCardsModel>>> {
        const encrypteddata = this.cmsvc.E_FP_RequestData(
            JSON.stringify(request)
        );
        const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
        return this.httpDao.postData(APIConstant.API_SEARCH_MEMBERSHIP_CARDS_FOR_MEMBERS, { "Data": encrypteddata, "Header": encryptedtoken });
    }

    cardAcion(params: MemberCardActionModel): Observable<ResultModel> {
        const encrypteddata = this.cmsvc.E_FP_RequestData(
            JSON.stringify(params)
        );
        return this.httpDao.postData(APIConstant.API_MEMBERSHIP_CARDS_ACTION, { Data: encrypteddata });
    }

    cardAcionForMember(params: MemberCardActionModel): Observable<ResultModel> {
        const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(params));
        const encryptedtoken = this.cmsvc.E_FP_RequestData(this.cmsvc.GetToken());
        return this.httpDao.postData(APIConstant.API_MEMBERSHIP_CARDS_ACTION_FOR_MEMBER, { "Data": encrypteddata, "Header": encryptedtoken });
    }
}
