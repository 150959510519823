import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-health-disclaimer',
  templateUrl: './health-disclaimer.component.html',
  styleUrls: ['./health-disclaimer.component.css']
})
export class HealthDisclaimerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
