// Author: Da Do
// Description: This form is used to display menu, filter for employers.

import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { merge, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, debounceTime ,  shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { APIConstant } from '../../../constant/apiconstant';
import { RouterConstant } from '../../../constant/routerconstant';
import { MessageBox } from '../../../services/common-dialog.service';
import { CommonResponse } from '../../../models/commonresponsemodel';
import { EmployerComposite } from '../../../models/employer.composite.search.model';
import { CommonService } from '../../../services/common.service';
import { HttpDAO } from '../../../services/httpdao.service';

@Component({
  selector: 'app-employer',
  templateUrl: './employer.component.html',
  styleUrls: ['./employer.component.css']
})
export class EmployerComponent implements OnInit {
  myControl = new UntypedFormControl();
  txtRegionControl = new UntypedFormControl();
  filteredEmployers: Observable<EmployerComposite[]>;
  filteredRegions: Observable<CommonResponse[]>;
  httpdao: HttpDAO | null;
  emps: EmployerComposite[] = [];
  regions: CommonResponse[] = [];
  isShowSpinter: boolean;
  isShowSpinterRegion: boolean;

  isEmployerSearch = false;
  isRegionSearch = false;

  emp_subject: Subject<string> = new Subject();
  reg_subject: Subject<string> = new Subject();

  @ViewChild('EmployerSearchModel') EmployerSearchModel: ElementRef;
  @ViewChild('EmployerCloneModel') EmployerCloneModel: ElementRef;

  constructor(private router: Router,
    private commonservice: CommonService, private http: HttpClient, private dialog: MatDialog) { }

  ngOnInit() {
    this.httpdao = new HttpDAO(this.http);
    this.isShowSpinter = false;
    this.commonservice.AddressID = '';
    this.commonservice.EmployerName = '';
    this.commonservice.EmployerID = '';
    this.commonservice.RegionID = 0;

    // Debounce Time for Employer
    this.emp_subject.pipe(
      debounceTime(APIConstant.API_DEBOUNCE_TIME)
    ).subscribe(val => {
      this.LoadEmpoyerToAutoText(val);
    });
    // -------------

    // Debounce Time for Region
    this.reg_subject.pipe(
      debounceTime(APIConstant.API_DEBOUNCE_TIME)
    ).subscribe(val => {
      this.LoadRegionToDDL(val);
    });
    // -------------
  }

  //#region functions
  private LoadEmpoyerToAutoText(paramsearch) {
    merge().pipe(
      startWith({}),
      switchMap(() => {
        this.commonservice.StartProgressBar();
        this.isShowSpinter = true;
        return this!.httpdao.getData(APIConstant.API_GET_COMPOSITE_EMPLOYER + encodeURIComponent(paramsearch));
      }),
      map(data => {
        if (data != null && data != undefined && data.length > 0) {
          this.filteredEmployers = this.myControl.valueChanges.pipe(
            startWith<string | EmployerComposite>(''),
            map(value => typeof value === 'string' ? value : value.Name),
            map(name => name ? this._filter(name) : data.slice())
          );
        }

        this.commonservice.StopProgressBar();
        this.isShowSpinter = false;
        this.isEmployerSearch = true;
        return data;
      }),
      shareReplay(1), // Consider to use shareReplay later.
      catchError(() => {
        this.commonservice.StopProgressBar();
        this.isShowSpinter = false;

        MessageBox.ShowError(this.dialog, 'An error has been occured while loading Employers name');
        return observableOf([]);
      })
    ).subscribe(data => this.emps = data);
  }

  private _filter(name: string): EmployerComposite[] {
    const filterValue = name.toLowerCase();
    return this.emps.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterRegions(name: string): CommonResponse[] {
    const filterValue = name.toLowerCase();
    return this.regions.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
  }

  private LoadRegionToDDL(paramsearch) {
    merge().pipe(
      startWith({}),
      switchMap(() => {
        this.commonservice.StartProgressBar();
        this.isShowSpinterRegion = true;
        return this.httpdao!.getData(APIConstant.API_GET_COMPOSITE_REGION + encodeURIComponent(paramsearch));
      }),
      map(data => {
        if (data != null && data != undefined && data.length > 0) {
          this.filteredRegions = this.txtRegionControl.valueChanges.pipe(
            startWith<string | CommonResponse>(''),
            map(value => typeof value === 'string' ? value : value.Name),
            map(name => name ? this._filterRegions(name) : data.slice())
          );
        }

        this.commonservice.StopProgressBar();
        this.isShowSpinterRegion = false;
        this.isRegionSearch = true;
        return data;
      }),
      shareReplay(1),
      catchError(() => {
        this.commonservice.StopProgressBar();
        this.isShowSpinterRegion = false;
        MessageBox.ShowError(this.dialog, 'An error has been occured while loading Regions');
        return observableOf([]);
      })
    ).subscribe(data => this.regions = data);

  }
  // #endregion

  // #region events binding in Angular
  public ddlExistingEmp_Change(event) {
    this.commonservice.EmployerID = event.target.value;
    this.EmployerCloneModel.nativeElement.click();
    this.router.navigate([RouterConstant.NAVIGATOR_EMPLOYER_ADDCLONE + this.commonservice.EmployerID]);
  }

  public btnAddNewEmployer_Click() {
    this.router.navigate([RouterConstant.NAVIGATOR_EMPLOYER_ADDNEW]);

  }

  public ddlEmployerAutoComplete_Selected(event) {
    if (event.option.value == null || event.option.value == undefined || event.option.value.Id == '') {
      return;
    }
    this.commonservice.EmployerID = event.option.value.Id;
    this.EmployerSearchModel.nativeElement.click();
    this.router.navigate([RouterConstant.NAVIGATOR_EMPLOYER_EDIT + this.commonservice.EmployerID]);
  }

  public ddlRegionAutoComplete_Selected(event) {
    if (event.option.value == null || event.option.value == undefined || event.option.value.Id == '') {
      return;
    }
    this.commonservice.RegionID = event.option.value.Id;
    this.EmployerSearchModel.nativeElement.click();
    this.router.navigate([RouterConstant.NAVIGATOR_EMPLOYER_VIEW_BY_REGION + this.commonservice.RegionID]);
  }

  public displayFn(emp?: EmployerComposite): string | undefined {
    return emp ? emp.Name : undefined;
  }

  public displayFnRegion(region?: CommonResponse): string | undefined {
    return region ? region.Name : undefined;
  }

  public txtEmployerSearch_Change(event) {
    const val = event.target.value;
    if (val.length >= 3 && event.code != 'ArrowUp' && event.code != 'ArrowDown'
      && event.code != 'ArrowLeft' && event.code != 'ArrowRight') {
      // this.LoadEmpoyerToAutoText(val);
      this.emp_subject.next(val);
    } else if (val.length < 3) {
      this.filteredEmployers = this.myControl.valueChanges.pipe(
        startWith<string | EmployerComposite>(''),
        map(value => typeof value === 'string' ? value : ''),
        map(name => name ? this._filter(name) : [])
      );
    }
  }

  public txtRegion_Change(event) {
    const val = event.target.value;
    if (val.length >= 3 && event.code != 'ArrowUp' && event.code != 'ArrowDown'
      && event.code != 'ArrowLeft' && event.code != 'ArrowRight') {
      // this.LoadRegionToDDL(val);
      this.reg_subject.next(val);
    } else if (val.length < 3) {
      this.filteredRegions = this.txtRegionControl.valueChanges.pipe(
        startWith<string | CommonResponse>(''),
        map(value => typeof value === 'string' ? value : value.Name),
        map(name => name ? this._filterRegions(name) : [])
      );
    }
  }
  //#endregion
}

