import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  ValidatorFn,
} from "@angular/forms";
import { SignupComponent } from "./../signup-component/signup/signup.component";
import {
  Component,
  Inject,
  EventEmitter,
  Output,
  OnInit,
  ViewChild,
  AfterViewChecked,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PatternConstant } from "../../constant/patternconstant";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { ReCaptcha2Component } from "ngx-captcha";

@Component({
  selector: "app-common-dialog",
  templateUrl: "./common-dialog.component.html",
  styleUrls: ["./common-dialog.component.css"],
})

export class CommonDialogComponent implements OnInit, AfterViewChecked {
  style: number;
  title: string;
  message: string;
  information: SafeHtml;
  button: number;
  allow_outside_click: boolean;
  countdown: string;
  Pincode = "";
  PinRequired = false;
  PinInvalid = false;
  PinVerified = false;
  ConfPasswordNotMatch = false;
  PinSignUpPage: SignupComponent;
  passwordFormGroup: UntypedFormGroup;
  btnSubmit = false;
  buttonlabel = "OK";

  // Author: Da Do - ReCaptcha - Plugin
  @ViewChild("captchaElem") captchaElem: ReCaptcha2Component;

  isCaptchaResponse = false;
  public siteKey = environment.CaptchaSiteKey;
  public ReCaptchaFormGroup: UntypedFormGroup;
  btnResent = false;
  // End -

  constructor(
    private sanitized: DomSanitizer,
    public dialogRef: MatDialogRef<CommonDialogComponent>,
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.style = data.style || 0;
    this.title = data.title;
    this.message = data.message;
    this.information = this.sanitized.bypassSecurityTrustHtml(data.information);
    this.button = data.button;
    this.dialogRef.disableClose = !data.allow_outside_click || false;
    this.PinSignUpPage = data.PinSignUpPage;
    this.buttonlabel = data.buttonlabel;
  }

  ngOnInit() {
    this.CreatePasswordForm();
    this.CreateCaptchaForm();
  }

  ngAfterViewChecked() {}

  private CreatePasswordForm() {
    this.passwordFormGroup = this._formBuilder.group({
      txtPassword: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.PASSWORD)],
      ],
      txtPasswordConf: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.PASSWORD)],
      ],
    });
  }

  public ResendPinClick() {
    this.btnResent = true;
    if (this.isCaptchaResponse === true) {
      this.PinSignUpPage.ResendPinClick();
      this.ReloadCaptcha();
    }
  }

  public SubmitPinClick(pincode, popup: MatDialogRef<CommonDialogComponent>) {
    if (this.isCaptchaResponse === true) {
      this.PinSignUpPage.SubmitPinClick(pincode, popup);
      this.ReloadCaptcha();
    }
  }

  public SubmitPasswordClick(
    password,
    popup: MatDialogRef<CommonDialogComponent>
  ) {
    this.PinSignUpPage.CreateMemberClick(password, popup);
  }

  public CancelPinClick() {
    this.PinSignUpPage.CancelPinClick();
  }

  public CancelPasswordClick() {
    this.PinSignUpPage.CancelPasswordClick();
  }

  checkMatchPassword() {
    const password = this.passwordFormGroup.get("txtPassword").value;
    const cfpassword = this.passwordFormGroup.get("txtPasswordConf").value;
    if (password != cfpassword && !this.passwordFormGroup.invalid) {
      this.ConfPasswordNotMatch = true;
    } else {
      this.ConfPasswordNotMatch = false;
    }
  }

  private CreateCaptchaForm() {
    this.ReCaptchaFormGroup = this._formBuilder.group({
      rcpSignUpCaptcha: ["", Validators.required],
    });
  }

  public HandleCaptchaSuccess(captchaResponse: string): void {
    this.isCaptchaResponse = true;
  }

  private ReloadCaptcha() {
    if (this.captchaElem !== undefined) {
      this.captchaElem.reloadCaptcha();
      this.isCaptchaResponse = false;
      this.btnSubmit = false;
      this.btnResent = false;
    }
  }

  btnOK_Click() {
    this.btnSubmit = true;
    if (this.button == 4) {
      if (this.Pincode.trim() == "") {
        this.PinRequired = true;
      } else {
        this.PinRequired = false;
        this.SubmitPinClick(this.Pincode, this.dialogRef);
      }
    } else if (this.button == 5) {
      if (!this.passwordFormGroup.invalid && !this.ConfPasswordNotMatch) {
        this.SubmitPasswordClick(
          this.passwordFormGroup.get("txtPassword").value,
          this.dialogRef
        );
      }
    } else {
      this.dialogRef.close({ result: DialogResult.Ok });
    }
  }
  btnCancel_Click() {
    if (this.button == 4) {
      this.CancelPinClick();
    }
    if (this.button == 5) {
      this.CancelPasswordClick();
    }
    this.dialogRef.close({ result: DialogResult.Cancel });
  }
  btnYes_Click() {
    this.dialogRef.close({ result: DialogResult.Yes });
  }
  btnNo_Click() {
    this.dialogRef.close({ result: DialogResult.No });
  }
  btnAccept_Click() {
    this.dialogRef.close({ result: DialogResult.Accept });
  }
  btnReject_Click() {
    this.dialogRef.close({ result: DialogResult.Reject });
  }
  btnContinue_Click() {
    this.dialogRef.close({ result: DialogResult.Continue });
  }
  btnNext_Click() {
    this.dialogRef.close({ result: DialogResult.Next });
  }
}

export abstract class DialogResult {
  public static Ok = "ok";
  public static Cancel = "cancel";
  public static Yes = "yes";
  public static No = "no";
  public static Accept = "accept";
  public static Reject = "reject";
  public static Continue = "continue";
  public static Next = "next";
  result: string;
}
