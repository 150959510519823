import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonString } from 'src/app/constant/common-string';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { ParentGroupService } from 'src/app/services/parent-group.service';
import { catchError, finalize, map } from 'rxjs/operators';
import { DataResult, SearchPaginationResult } from 'src/app/models';
import { ParentGroup } from 'src/app/models/parent-group.model';
import { RouterConstant } from 'src/app/constant/routerconstant';
import { MessageBox, MessageBoxButton } from '@fp/services';
import { MatDialog } from '@angular/material/dialog';

class _ParentGroup {
    constructor(
        public id: number = undefined,
        public name: string = undefined,
    ) { }
}

class _ParentGroups {
    constructor(
        public data: Array<_ParentGroup> = new Array<_ParentGroup>(),
        public totalItem: number = undefined
    ) { }
}

@Component({
    selector: 'app-viewparentgroups',
    templateUrl: './viewparentgroup.component.html',
    styleUrls: ['./viewparentgroup.component.css']
})
export class ViewparentgroupComponent implements OnInit, OnDestroy {
    paging: any = {
        PageNumber: 1,
        PageSize: 10
    }
    requestQueueCount: number = 0;
    displayedColumns: string[] = ['name', 'edit', 'delete'];
    dataSource: MatTableDataSource<any>;
    commonString = CommonString;
    routerAddParentGroup: string = "/" + RouterConstant.ROUTER_DASHBOARD + '/' + RouterConstant.ROUTER_PARENT_GROUP_ADD;


    //material
    length: number;
    pageSize: number;
    pageSizeOptions: number[] = [10, 20, 50];

    routerConstant = RouterConstant;

    ngOnDestroy() {
        this.paging = null;
        this.requestQueueCount = null;
        this.displayedColumns = null;
        this.dataSource = null;
        this.length = null;
        this.pageSize = null;
        this.pageSizeOptions = null;
    }

    constructor(
        private commonService: CommonService,
        private service: ParentGroupService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.loadParentGroups();
    }

    invoke(source: Observable<any>, handleResultCallback: Function, mapCallback: Function = () => { }) {
        this.requestQueueCount++;
        this.commonService.StartProgressBar();
        source.pipe(
            catchError((e) => { throw e; }),
            finalize(() => {
                this.requestQueueCount--;
                if (this.requestQueueCount <= 0) {
                    this.commonService.StopProgressBar();
                    this.requestQueueCount = 0;
                }
            }),
            map((data) => {
                return mapCallback(data);
            }),
        )
            .subscribe(
                res => {
                    handleResultCallback(res);
                },
                err => {
                    console.log(err);
                }
            )
    };

    loadParentGroups() {
        this.invoke(
            this.service.load(this.paging),
            (data: _ParentGroups) => {
                this.dataSource = new MatTableDataSource<any>(data.data);
                this.length = data.totalItem;
            },
            (data: DataResult<SearchPaginationResult<ParentGroup>>) => {
                if (data.Success == false) {
                    return {};
                }

                let pgs: _ParentGroups = new _ParentGroups();
                pgs.totalItem = data.Data.TotalItem;
                data.Data.Results.map(
                    (pg: ParentGroup) => {
                        const _pg = {
                            id: pg.ParentGroupId,
                            name: pg.ParentGroupName,
                        }
                        pgs.data.push(_pg)
                    }
                );
                return pgs;
            }
        );
    }

    /**
  * Running when user manipulate on material paginator.
  * @param event
  */
    paginatorChange(event: PageEvent) {
        this.paging.PageNumber = event.pageIndex + 1;
        this.paging.PageSize = event.pageSize
        this.loadParentGroups();
    }

    /**
     * Running when user click column header of Material table.
     * @param sort
     */
    sortChange(sort: Sort) {
        const active: string = sort.active.toLowerCase();
        const isOderByAsc: boolean = sort.direction == "asc" ? false : true;
        this.paging.OrderBy = active;
        this.paging.OrderByAsc = isOderByAsc;
        this.loadParentGroups();
    }

    createNew() {
        this.router.navigate([this.routerAddParentGroup]);
    }

    pressDeleteButton(pgId: number) {

        let confirmBoxResult = MessageBox.ShowCustom(this.dialog, 'Warning !', '', 'Are you sure you want to delete this parent group ?', MessageBoxButton.YesNoCancel, false, 0, '400px');

        confirmBoxResult.subscribe(result => {
            if (result.result == 'yes') {
                this.deleteParentGroup(pgId);
            }
        });


    }

    async deleteParentGroup(pgId:number) {
        let isPgDeleted=await this.service.deleteParentGroup(pgId.toString()).toPromise();
        if(isPgDeleted.Success){
            if(isPgDeleted.Message==="Parent group deleted successfully!"){
                MessageBox.ShowInfo(this.dialog,isPgDeleted.Message);
                this.loadParentGroups();
            }else if(isPgDeleted.Message==="Can't delete the parent group because there are facilities under this parent group!"){
                MessageBox.ShowInfo(this.dialog,isPgDeleted.Message);
            }else{
                MessageBox.ShowError(this.dialog,"An error has occurred.");
            }
        }else{
            MessageBox.ShowError(this.dialog,'An error has occurred.');
        }
    }

}
