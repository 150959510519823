import { CommonString, StorageKey } from '@fp/constants';
import { CommonResponse } from "./../../../models/commonresponsemodel";
import { environment } from "./../../../../environments/environment";
import { MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, ViewChild, Inject, Injector } from "@angular/core";
import { MessageBox } from "src/app/services/common-dialog.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Observable, of as observableOf } from "rxjs";
import { MemberSignupService } from "src/app/services/member-signup.service";
import { PatternConstant } from "../../../constant/patternconstant";
import {
  PinModel,
  ResultModel,
  DataResult,
  UserModel,
  SurveyMemberModel,
  SurveysEmployerDetailModel,
  State,
  Employer,
} from "src/app/models";
import { CommonService } from "src/app/services/common.service";
import { ReCaptcha2Component } from "ngx-captcha";
import { ActivatedRoute, Router } from "@angular/router";
import { RouterConstant } from "src/app/constant";
import { DialogResult } from "src/app/components/common-dialog/common-dialog.component";
import { AuthenticationService } from "../../../services/authentication.service";
import { UserService } from "src/app/services/admin/user/user.service";
import { DOCUMENT } from "@angular/common";
import { CommonDialogNewComponent } from "@fp/components/common-dialog-new/common-dialog-new.component";
import { Utils } from '@fp/helpers';
import { EmployerService } from '@fp/services';
import { FPAbstractComponent } from '@fp/components/base';
import { CommonConstants } from '@fp/constant/common-constants';
import { MemberContainer } from '@fp/models/member-container.model';

@Component({
  selector: 'app-survey-signup',
  templateUrl: './survey-signup.component.html',
  styleUrls: ['./survey-signup.component.css']
})
export class SurveySignupComponent extends FPAbstractComponent implements OnInit {

  registerFormGroup: UntypedFormGroup;
  loginFormGroup: UntypedFormGroup;
  commonString = CommonString;

  btnSubmit = false;
  invalidPhone = false;
  public codeVerified: boolean = false;
  unusedPhoneEmail: boolean = false;
  isPhoneEmailValiating: boolean = false;
  isUserLogged: boolean = false;
  requestQueueCount = 0;
  empId = 0;
  empCode = '';
  empState: State;

  @ViewChild("captchaElem") captchaElem: ReCaptcha2Component;
  isCaptchaResponse = false;
  public siteKey = environment.CaptchaSiteKey;
  public ReCaptchaFormGroup: UntypedFormGroup;

  ngOnDestroy() {
    this.requestQueueCount = null;
  }

  constructor(
    injector: Injector,
    private _formBuilder: UntypedFormBuilder,
    private msService: MemberSignupService,
    private commonSvc: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private userSvc: UserService,
    private employerSvc: EmployerService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.commonSvc.Navbar) {
      this.commonSvc.Navbar.isLoginDisplay = false;
    }
    if (this.commonSvc.Navbarmd) {
      this.commonSvc.Navbarmd.isLoginDisplay = false;
    }

    if( this.checkIsLoggedIn()){
      this.isUserLogged = true;
      return;
    }

    this.CreateSignupForm();
    this.CreateCaptchaForm();
    localStorage.removeItem("user");
    this.commonSvc.App.currentLogin = false;
    this.commonSvc.App.opened = false;
    this.getEmployerDetailsByEmpCode();
  }

  CreateSignupForm() {
    this.registerFormGroup = this._formBuilder.group({
      txtFirstName: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.VALID_NAME)],
      ],

      txtLastName: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.VALID_NAME)],
      ],
      txtMobileNum: ["", Validators.required],
      txtEmail: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.EMAIL)],
      ],
      txtEmployerName: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.VALID_NAME)],
      ],
    });
  }

  private CreateCaptchaForm() {
    this.ReCaptchaFormGroup = this._formBuilder.group({
      rcpSignUpCaptcha: ["", Validators.required],
    });
  }

  public runCheck(event) {
    this.checkPhoneEmailResponsive();
  }

  public checkPhoneEmailResponsive() {
    this.unusedPhoneEmail = false;
    console.log("Check phone email - start");
    if (
      this.registerFormGroup.get("txtEmail").dirty &&
      this.registerFormGroup.get("txtEmail").valid &&
      this.registerFormGroup.get("txtMobileNum").dirty
    ) {
      const pin: UserModel = {};

      let number = Utils.convertPhoneToInternationalFormatWithCountryCode(this.registerFormGroup.get("txtMobileNum").value);
      pin.EmailAddress = this.registerFormGroup
        .get("txtEmail")
        .value.toLowerCase()
        .trim();
      pin.MobileNumber = "+" + number;

      this.isPhoneEmailValiating = true;

      this.msService.checkPhoneEmail(pin).subscribe((res) => {
        const ret = this.commonSvc.D_FP_ResponseData(res);
        const dtresult: DataResult<CommonResponse> = JSON.parse(ret);
        console.log("DT Success " + dtresult.Success);

        // pin has been verified
        if (dtresult.Success) {
          if (dtresult.Data != null) {
            if (dtresult.Data.Name === "There is no user") {
              console.log("Phone Email Verified unused ");
              this.unusedPhoneEmail = true;
            } else {
              console.log("Account pre-exists");
            }
          }
        }
        this.isPhoneEmailValiating = false;
      });
    }
  }

  public btnSubmit_Click() {
    this.btnSubmit = true;
    window.scrollTo(0, 0);
    if (!this.registerFormGroup.invalid) {
      this.isCaptchaResponse = this.IsCaptchaComplete();
      if (this.isCaptchaResponse) {
        if (this.unusedPhoneEmail && !this.isPhoneEmailValiating) {
          this.SendPin();
          console.log(this.unusedPhoneEmail);
        } else {
          MessageBox.ShowConfirmLogin(
            this.dialog,
            "You are already registered with Fitness Passport. <br><br>Please log in to complete or manage your membership (this includes adding a Partner and/or Dependent/s to your membership)."
          ).subscribe((dialogResult) => {
            if (dialogResult.result.toLowerCase() === DialogResult.Ok) {
              localStorage.setItem("signupFlag", "true");
              this.router.navigate([RouterConstant.ROUTER_LOGIN]);
            } else {
              this.ReloadCaptcha();
            }
          });
        }
      }
    }
  }

  private setCountryToCache(country : string) {

    if (country.toLowerCase() == CommonConstants.COUNTRY_NEW_ZEALAND.toLowerCase() ) {
      this.commonSvc.setUserCountry(this.commonString.NEWZEALAND);
    }
    else {
      this.commonSvc.setUserCountry(this.commonString.AUSTRALIA);
    }
  }

  async SendPin() {
    const pin: PinModel = {};
    pin.IsSendEmail = true;
    pin.IsSendSMS = true;
    let txtMobileNum = Utils.convertPhoneToInternationalFormatWithCountryCode(this.registerFormGroup.get("txtMobileNum").value);

    pin.Email = this.registerFormGroup
      .get("txtEmail")
      .value.toLowerCase()
      .trim();
    pin.MobileNumber = "+" + txtMobileNum;
    this.Invoke(this.msService.sendPinCode(pin), {
      onSuccess: (res: string) => {
        const dectypted_data = this.commonSvc.D_FP_ResponseData(res);
        const rdata: ResultModel = JSON.parse(dectypted_data);
        if (!rdata && !rdata.Success) {
         this.showErrorMessageWithLogin();
        }
      },
      onError: (err: any) => {
        this.showErrorMessageWithLogin();
      },
    });
    this.commonSvc.StopGlobalProgressBar();
    MessageBox.ShowSurveyPinDialog(this.dialog, this);
  }

  
  private getEmployerDetailsByEmpCode() {
    this.empCode = this.route.snapshot.queryParams['empcode'];
    if (this.empCode) {
      this.Invoke(this.employerSvc.getByEmpCodeForMemberSurvey(this.empCode), {
        onSuccess: (res: DataResult<SurveysEmployerDetailModel>) => {
          if (res.Success && res.Data) {
            this.empId = res.Data.EmployerId;
            this.setCountryToCache(res.Data.Country);
            this.empState = res.Data.State;

            if (res.Data.IsPreSignupSurveyEligible) {
              this.registerFormGroup.get("txtEmployerName").setValue(res.Data.Name);
            }
            else if (res.Data.ShowSurveyNotStartedMessage) {
              MessageBox.ShowInfo(this.dialog, '<p align="center">' + 'Thank you for your interest in the Fitness Passport survey.The survey is coming soon.'
                + '<br><br>You can thank your workplace for looking out for you. It was their initiative to offer one of the best employee benefit programs available, so you can reach your health and wellbeing goals.'
                + '<br>And your family can join you too!'
                + '<br><br>Please keep your eye out for workplace communications, advising when the Fitness Passport survey will be open.</p>'
              )
                .subscribe(result => {
                  this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
                });
            }
            else if (res.Data.ShowSurveyExpiredMessage) {
              MessageBox.ShowInfo(this.dialog, '<p align="center">' + 'Thank you for your interest in the Fitness Passport survey. The survey is now closed, and we are currently out building your bespoke gym and pool facility list.'
                + '<br><br>You can thank your workplace for looking out for you. It was their initiative to offer one of the best employee benefit programs available, so you can reach your health and wellbeing goals.'
                + '<br>And your family can join you too!'
                + '<br><br>Please keep your eye out for further communications on how to view your facilities and sign up to Fitness Passport.</p>'
              )
                .subscribe(result => {
                  this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
                });
            }
            else if (res.Data.ShowSurveyEmployerNotEligibleMessage) {
              MessageBox.ShowInfo(this.dialog, '<p align="center">' + 'Thank you for your interest in Fitness Passport.'
                + '<br><br>This survey and program is closed, please contact your workplace for more information.</p>')
                .subscribe(result => {
                  this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
                });
            }
            else if (res.Data.IsRedirectToLogin) {
              this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
            }
            else {
              localStorage.setItem("signupFlag", "true");
              this.showErrorMessageWithLogin();
            }
          }
          else {
            this.showErrorMessageWithLogin();
          }
        },
        onError: (err: any) => {
          this.showErrorMessageWithLogin();
        },
      });
    }
    else {
      this.showErrorMessageWithLogin();
    }
  }

  public SubmitPinClick(
    pincode,
    popup: MatDialogRef<CommonDialogNewComponent>
  ) {
    const pin: PinModel = {};
    pin.Email = this.registerFormGroup.get("txtEmail").value;
    pin.Pin1 = pincode;
    this.Invoke(this.msService.checkPinCode(pin), {
      onSuccess: (res: string) => {
        const dectypted_data = this.commonSvc.D_FP_ResponseData(res);
        const rdata: DataResult<PinModel> = JSON.parse(dectypted_data);
        if (rdata.Success) {
          if (rdata.Data.Status) {
            popup.close();
            MessageBox.ShowSurveyCreatePwdDialog(this.dialog, this);
          } else {
            popup.componentInstance.PinInvalid = true;
            this.codeVerified = false;
          }
        } else {
          this.showErrorMessage();
          console.log(rdata.Errors);
        }
      },
      onError: (err: any) => {
        this.showErrorMessageWithLogin();
      },
    });
  }

  public ResendPinClick() {
    const pin: PinModel = {};
    pin.IsSendEmail = true;
    pin.IsSendSMS = true;
    let txtMobileNum = Utils.convertPhoneToInternationalFormatWithCountryCode(this.registerFormGroup.get("txtMobileNum").value);

    pin.Email = this.registerFormGroup
      .get("txtEmail")
      .value.toLowerCase()
      .trim();
    pin.MobileNumber = "+" + txtMobileNum;
    this.Invoke(this.msService.resendPinCode(pin), {
      onSuccess: (res: DataResult<PinModel>) => {
        if (res.Success) {
          console.log(res.Name);
        } else {
          this.showErrorMessage();
          console.log(res.Errors);
        }
      },
      onError: (err: any) => {
        this.showErrorMessage();
      },
    });
  }

  public CreateMemberClick(
    password,
    popup: MatDialogRef<CommonDialogNewComponent>
  ) {
    let number = this.registerFormGroup.get("txtMobileNum").value;;
    let pin: SurveyMemberModel = {};
    let user: UserModel = {};
    number = Utils.convertPhoneToInternationalFormatWithCountryCode(number);

    user.FirstName = this.registerFormGroup.get("txtFirstName").value;
    user.LastName = this.registerFormGroup.get("txtLastName").value;
    user.EmailAddress = this.registerFormGroup
      .get("txtEmail")
      .value.trim()
      .toLowerCase();
    user.MobileNumber = "+" + number;
    user.Password = password;

    pin.FirstName = this.registerFormGroup.get("txtFirstName").value;
    pin.LastName = this.registerFormGroup.get("txtLastName").value;
    pin.PrimaryEmail = this.registerFormGroup
      .get("txtEmail")
      .value.trim()
      .toLowerCase();
    pin.MobileNumber = "+" + number;
    pin.User = user;
    pin.EmployerId = this.empId;

    this.Invoke(this.msService.createSurveyMember(pin), {
      onSuccess: (res: string) => {
        let dectypted_data = this.commonSvc.D_FP_ResponseData(res);
        let rdata: DataResult<UserModel> = JSON.parse(dectypted_data);
        if (rdata.Success) {
          let employerContainer = new Employer();
          employerContainer.EmployerId = this.empId;
          employerContainer.State = this.empState;

          let loginContainer = new MemberContainer();
          let mData = { 
            UserId: rdata.Data.UserId,
            UserName: rdata.Data.UserName,
            FirstName: pin.FirstName,
            LastName: pin.LastName,
            MobileNumber: pin.MobileNumber,
            EmailAddress: rdata.Data.EmailAddress,
            MemberId: rdata.Data.MemberId,
            EmployerId: this.empId,
            Employer: employerContainer
          };
          let memberData = { Data: mData };
          let memberEncrypt = this.commonSvc.E_FP_AES256(JSON.stringify(memberData));
          loginContainer.Member = memberEncrypt;
          let loginData = JSON.stringify(loginContainer);
          localStorage.setItem(StorageKey.CONTAINER_OBJECT, loginData);
          this.Invoke(
            this.Login(rdata.Data.UserName, rdata.Data.Password, popup),
            {
              onSuccess: (res: boolean) => {},
              onError: (err: any) => {
                this.showErrorMessage();
              },
            });
        } else {
          this.showErrorMessage();
          console.log(rdata.Errors);
        }
      },
      onError: (err: any) => {
        this.showErrorMessage();
      },
    });
  }

  private showErrorMessage(){
    MessageBox.ShowError(
      this.dialog,
      "Sorry, there was a connection issue. Please try submitting the information again."
    );
  }

  private showErrorMessageWithLogin(){
    MessageBox.ShowError(
      this.dialog,
      "Sorry, there was a connection issue. Please try submitting the information again."
    )
      .subscribe((res) => {
        if (res.result.toLowerCase() === DialogResult.Ok) {
          this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
        }
      });
  }

  private Login(
    username: string,
    password: string,
    popup
  ): Observable<boolean> {
    const userPool = this.authService.getUserPool();
    const cognitoUser = this.authService.getUser(userPool, username);
    const authenticationDetails = this.authService.getAuthenticationDetails(
      username,
      password
    );

    return Observable.create(
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => { },
        onFailure: (err) => {
          MessageBox.ShowError(this.dialog, err.message);
          return observableOf(false);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          cognitoUser.completeNewPasswordChallenge(
            password,
            requiredAttributes,
            {
              onSuccess: async (result) => {
                const newAuthenticationDetails =
                  this.authService.getAuthenticationDetails(username, password);
                this.GetCognitoToken(
                  cognitoUser,
                  newAuthenticationDetails,
                  username,
                  popup
                );
                return observableOf(true);
              },
              onFailure: function (err) {
                MessageBox.ShowError(this.dialog, err.message);
                return observableOf(false);
              },
            }
          );
        },
      })
    );
  }

  public GetCognitoToken(cognitoUser, authenticationDetails, user, popup) {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (result) => {
        this.commonSvc.SetUser(user);
        this.authService.setSessionData(result);

        this.userSvc.getByUsernameFirstLoginV2(user).subscribe(
          (res) => {
            if (res.Data) {
              this.commonSvc.SetUserObject(res.Data.UserObject);
              this.commonSvc.SetTermsConditions(res.Data.TermsAndConditions);
              this.commonSvc.SetSecondaryTermsConditions(
                res.Data.TermsAndConditionsSecondary
              );
              this.commonSvc.SetMedicalQuestions(res.Data.MedicalQuestions);
              this.commonSvc.SetEmployers(res.Data.employers);
            }
          },
          (err) => {
            console.error(err);
          },
          () => {
            this.commonSvc.Header.SetUILoginSuccess();
            popup.close();
            this.commonSvc.StopGlobalProgressBar();
            this.router.navigate([RouterConstant.ROUTER_MEMBER_PRE_SIGNUP_SURVEY]);
          }
        );
      },
      onFailure: (err) => {
        this.commonSvc.StopGlobalProgressBar();
        MessageBox.ShowError(this.dialog, err.message);
      },
    });
  }

  //#region ReCaptcha
  private IsCaptchaComplete(): boolean {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      return false;
    } else {
      return true;
    }
  }

  public HandleCaptchaSuccess(captchaResponse: string): void {
    this.isCaptchaResponse = true;
  }

  private ReloadCaptcha() {
    this.captchaElem.reloadCaptcha();
    this.isCaptchaResponse = false;
    this.btnSubmit = false;
  }

  private checkIsLoggedIn(): boolean {
    const user = this.commonSvc.GetUser();
    if (user == undefined || user == null) {
      return false;
    } else {
      return true;
    }
  }
}
