<div [formGroup]="form">
    <app-mobile-number [mandatory]="true" [control]="getControl('PhoneNo')" [submitted]="wasValidated"
        [readonly]="getControl('PhoneNo').disabled" [clsLabel]="'col-md-3 col-form-label col-lg-2'" [clsGroup]="'col-md-8 col-lg-5'">
    </app-mobile-number>
    <div class="form-group row">
        <label for="txtEmail" class="col-md-3 col-lg-2 col-form-label">Email</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="email" id="txtEmail" class="form-control" autocomplete="off" maxlength="50" formControlName="EmailAddress"
                    [fpFormControlValidation]="{controlLabel: 'Email'}">
            </div>
        </div>
    </div>
    <h6 class="text-primary font-weight-bold py-3">Physical Address</h6>
    <div class="form-group row">
        <label for="txtStreet1" class="col-md-3 col-lg-2 col-form-label">Address Line 1<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" id="txtStreet1" class="form-control" maxlength="50" autocomplete="off"
                    formControlName="StreetAddress1_Phy" fpFormControlValidation>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtStreet2" class="col-md-3 col-lg-2 col-form-label">Address Line 2</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" id="txtStreet2" class="form-control" maxlength="50" autocomplete="off"
                    formControlName="StreetAddress2_Phy">
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtSuburb" class="col-md-3 col-lg-2 col-form-label">City/Suburb<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" id="txtSuburb" name="txtSuburb" class="form-control" autocomplete="off" [formControl]="SuburbCtrl"
                    matInput [matAutocomplete]="autoSuburb" (focus)="txtSuburb_Focus($event)" (blur)="txtSuburb_Blur($event)"
                    fpFormControlValidation>
                <mat-autocomplete #autoSuburb="matAutocomplete" (optionSelected)="autoSuburb_OptionSelected($event.value);"
                    [displayWith]="_displayAutoText">
                    <mat-option *ngFor="let suburb of _suburbSearchResults" class="border-default border-bottom py-1" [value]="suburb">
                        <p class="m-0 d-flex align-items-center">{{suburb.Name}}, {{suburb.State.Name}}, {{suburb.Postcode.Name}}</p>
                    </mat-option>
                </mat-autocomplete>
                <mat-spinner [diameter]="14" color="primary" *ngIf="SuburbCtrl.pending">
                </mat-spinner>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlPostcode" class="col-md-3 col-lg-2 col-form-label">Postcode<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" id="ddlPostcode" formControlName="PostCodeId_Phy"
                    [fpFormControlValidation]="{options:{onTouched: true}}">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let postcode of _postcodes" [ngValue]="postcode.Id">
                        {{postcode.Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlState" class="col-md-3 col-lg-2 col-form-label">State<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" id="ddlState" [formControl]="StateCtrl"
                    [fpFormControlValidation]="{options:{onTouched: true}}">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let state of _states" [ngValue]="state.StateId">
                        {{state.Name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlCountry" class="col-md-3 col-lg-2 col-form-label">Country<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" id="ddlCountry" [formControl]="CountryCtrl">
                    <option *ngFor="let country of _countries" [ngValue]="country.CountryId">
                        {{country.Name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtRegionPhy" class="col-md-3 col-lg-2 col-form-label">Region</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="txtRegionPhy" [formControl]="RegionCtrl" readonly="readonly">
            </div>
        </div>
    </div>
    <h6 class="text-primary font-weight-bold py-3">Mailing Address</h6>
    <div class="form-group row">
        <label for="chkSameAsAbove" class="col-md-3 col-lg-2 col-form-label">Same as above</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="chkSameAsAbove" [disabled]="!AddressGrp.valid"
                        (change)="chkSameAsAbove_Change($event)">
                    <label for="chkSameAsAbove" class="custom-control-label">&nbsp;</label>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtMAAddsLine1" class="col-md-3 col-lg-2 col-form-label">Address Line 1</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="txtMAAddsLine1" autocomplete="off" maxlength="50"
                    formControlName="StreetAddress1_Mail">
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtMAAddsLine2" class="col-md-3 col-lg-2 col-form-label">Address Line 2</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" class="form-control" id="txtMAAddsLine2" autocomplete="off" maxlength="50"
                    formControlName="StreetAddress2_Mail">
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtMASuburb" class="col-md-3 col-lg-2 col-form-label">City/Suburb</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" name="txtMASuburb" class="form-control" autocomplete="off" matInput [matAutocomplete]="autoMailingSuburb"
                    [formControl]="MailingSuburbCtrl" (focus)="txtMASuburb_Focus($event)" (blur)="txtMASuburb_Blur($event)">
                <mat-autocomplete #autoMailingSuburb="matAutocomplete" (optionSelected)="autoMailingSuburb_OptionSelected($event);"
                    [displayWith]="_displayAutoText">
                    <mat-option *ngFor="let suburb of _mailingSuburbSearchResults" class="border-default border-bottom py-1"
                        [value]="suburb">
                        <p class="m-0 d-flex align-items-center">{{suburb.Name}}, {{suburb.State.Name}}, {{suburb.Postcode.Name}}</p>
                    </mat-option>
                </mat-autocomplete>
                <mat-spinner [diameter]="14" color="primary" *ngIf="MailingSuburbCtrl.pending">
                </mat-spinner>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlMAPostcode" class="col-md-3 col-lg-2 col-form-label">Postcode</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select id="ddlMAPostcode" class="form-control" formControlName="PostCodeId_Mail">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let postcode of _mailingPostcodes" [ngValue]="postcode.Id">
                        {{postcode.Name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlMAState" class="col-md-3 col-lg-2 col-form-label">State</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select id="ddlMAState" class="form-control" [formControl]="MailingStateCtrl">
                    <option [ngValue]="null" selected>{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let state of _mailingStates" [ngValue]="state.StateId">
                        {{state.Name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlMACountry" class="col-md-3 col-lg-2 col-form-label">Country</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" id="ddlMACountry" [formControl]="MailingCountryCtrl">
                    <option *ngFor="let country of _mailingCountries" [ngValue]="country.CountryId">
                        {{country.Name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="chkMailCards" class="col-md-3 col-lg-2 col-form-label">Mail cards to the employees home address</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="chkMailCards" formControlName="MailCardToHome">
                    <label for="chkMailCards" class="custom-control-label">&nbsp;</label>
                </div>
            </div>
        </div>
    </div>
</div>