import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CommonMessage } from '@fp/constant';
import { StringHelper } from '@fp/helpers';

const DEFAULT_ERROR_TEMPLATES: { [key: string]: any } = {
    'required': CommonMessage.FIELD_REQUIRED,
    'pattern': CommonMessage.FIELD_INVALID,
    'unresolved': CommonMessage.FIELD_INVALID,
    'duplicated': CommonMessage.FIELD_VALUE_ALREADY_EXISTED
};

interface ITemplateOptions {
    onTouched: boolean;
    onDirty: boolean;
    toggleFn?: () => boolean;
}

@Component({
    selector: 'fp-form-control-errors-template',
    template: `
    <div *ngIf="visible">
        <small *ngFor="let error of errors | keyvalue" class="invalid-feedback d-block">{{error.value}}</small>
    </div>`
})
export class FPFormControlErrorsTemplateComponent {
    private _target: AbstractControl;
    get target(): AbstractControl {
        return this._target;
    }
    @Input() set target(value: AbstractControl) {
        this._target = value;
    }

    //TODO: Currently, temporarily use "label" to keep the control's display name for message's token replacement.
    // Should use a dictionary (key/value pair) in the future and switch the ordered tokens in the messages to
    // named tokens to support more flexible message templates.
    // e.g. "{0} is required" -> "{controlName} is required"
    @Input() label = 'field';
    private _toggle;
    get visible() {
        return typeof this._toggle === 'boolean' ?
            this._toggle : this.target && this.target.invalid &&
            ((this.options.onDirty && this.target.dirty) ||
                (this.options.onTouched && this.target.touched) ||
                (typeof this.options.toggleFn === 'function' && this.options.toggleFn()));
    }
    @Input() set visible(value) { this._toggle = value; }

    @Input() errorTemplates: { [key: string]: any };
    private _options: ITemplateOptions = {
        onTouched: false,
        onDirty: true
    };
    @Input() set options(value: ITemplateOptions) {
        this._options = Object.assign({}, this._options, value);
    }
    get options() { return this._options; }

    get errors() {
        const control = this.target;
        const errors = {};
        if (control && control.invalid) {
            const templates = Object.assign({}, DEFAULT_ERROR_TEMPLATES, this.errorTemplates);
            this.constructErrorMessages(errors, '', templates, control.errors);
        }
        return errors;
    }

    private constructErrorMessages(errorMessages: { [key: string]: string }, errorKey: string, templates: { [key: string]: any }, sourceError: { [key: string]: any }) {
        for (const key in sourceError) {
            const innerError = sourceError[key];
            const template = templates[key];
            if (template) {
                const currentKey = (errorKey + '.' + key).replace(/^\./, '');
                if (typeof template === 'string') {
                    errorMessages[currentKey] = StringHelper.format(template, this.label);
                }
                if (typeof template === 'object' && typeof innerError === 'object') {
                    this.constructErrorMessages(errorMessages, currentKey, template, innerError);
                }
            }
        }
    }
}
