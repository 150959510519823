<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Suspend Membership</h4>
<div class="mt-lg-4">
    <form [formGroup]="MemberSuspendFormGroup">
        <div *ngIf="IsMsgShow">
            <p>If you wish to suspend your Fitness Passport membership, please complete the following details.
                Please note the following guidelines for suspension requests:</p>
            <ul class="list-unstyled">
                <li>• You can suspend your membership for a minimum of 2 weeks and up to a maximum of 2 months at a time
                </li>
                <li>• You can only have a maximum of 2 months suspension in any calendar year </li>
                <li>• Suspension start and end dates are inclusive, i.e. no access will be granted to facilities during
                    this time period</li>
                <li>• If a visit is made by you or anyone on your membership during the suspension period, we will automatically reactivate your membership for you</li>
                <li>• Suspension fees are $5 per fortnight (pro-rated) and charged on your regular direct debit dates</li>
                <li>• Don’t forget with Fitness Passport you can use multiple gyms and pools so you can still workout
                     if going on holiday! For a list of all facilities on your program please refer to your  <a [routerLink]="
                     RouterConstant.NAVIGATOR_MEMBER_DETAILS
                   " class="text-primary"
                     ><strong><u>membership package</u></strong></a></li>
            </ul>
            <p>If your suspension request is outside of these guidelines, please contact our Customer Service team by
                clicking <a href="https://fitnesspassport.freshdesk.com/support/tickets/new" class="text-primary"
                    target="_blank"><strong>here</strong></a> and they will be able to assist</p>
            <div>
                <button class="px-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                    (click)="btnBackOut_Click()"><i class="fas fa-ban mr-2"></i>CANCEL</button>
                <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="btnContinue_Click()">
                    <i class="fas fa-arrow-circle-right mr-2"></i>
                    Continue</button>
            </div>
        </div>
        <div *ngIf="IsMsgShow==false && IsWaiting==false">
            <div class="form-group row">
                <label class="col-md-3 col-lg-2 col-form-label">Reason<span class="text-danger">*</span></label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <select class="form-control" formControlName="ddlReason"
                            [ngClass]="SetClassForControl(MemberSuspendFormGroup,'ddlReason')">
                            <option value="" selected>Please select a value</option>
                            <option *ngFor="let r of reasonlist" [ngValue]="r.Id">{{r.Name}}</option>
                        </select>
                        <div *ngIf="IsControlInvalid(MemberSuspendFormGroup,'ddlReason')">
                            <small *ngIf="MemberSuspendFormGroup.get('ddlReason').errors.required"
                                class="invalid-feedback d-block">
                                Reason is required
                            </small>
                        </div>

                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="dtpStartDate" class="col-md-3 col-lg-2 col-form-label">Start Date<span
                        class="text-danger">*</span></label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <div class="input-group date-input-group"
                            [ngClass]="SetClassForInputGroup(MemberSuspendFormGroup,'dtpStartDate')"
                            [class.focused]="parentFocus==1">
                            <input placeholder="DD-MM-YYYY" name="dp" ngbDatepicker #dtpStartDate="ngbDatepicker"
                                formControlName="dtpStartDate" class="form-control date-picker" (blur)="onBlurParent(0)"
                                (focus)="onFocusParent(1)" [minDate]="GetCurrentDate()" autocomplete="off"
                                [readonly]="StartDateReadonly">
                            <div class="input-group-append">
                                <button class="btn bg-white" (click)="dtpStartDate.toggle()" type="button">
                                    <i class="far fa-calendar-alt"></i>
                                </button>
                            </div>
                        </div>

                        <div *ngIf="IsControlInvalid(MemberSuspendFormGroup,'dtpStartDate')">
                            <small *ngIf="MemberSuspendFormGroup.get('dtpStartDate').errors.required"
                                 class="invalid-feedback d-block">Start Date is required</small>
                            <small *ngIf="MemberSuspendFormGroup.get('dtpStartDate').errors.mindateinvalid"
                                 class="invalid-feedback d-block">Start Date is invalid</small>
                            <small *ngIf="MemberSuspendFormGroup.get('dtpStartDate').errors.rangedateinvalid"
                                 class="invalid-feedback d-block">The Start Date should be less than the End
                                Date</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="dtpEndDate" class="col-md-3 col-lg-2 col-form-label">End Date<span
                        class="text-danger">*</span></label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <div class="input-group" [ngClass]="SetClassForInputGroup(MemberSuspendFormGroup,'dtpEndDate')"
                            [class.focused]="parentFocus==2">
                            <input placeholder="DD-MM-YYYY" ngbDatepicker #dtpEndDate="ngbDatepicker"
                                formControlName="dtpEndDate" class="form-control date-picker" (blur)="onBlurParent(0)"
                                (focus)="onFocusParent(2)" [minDate]="GetCurrentDate()" autocomplete="off">
                            <div class="input-group-append">
                                <button class="btn bg-white" (click)="dtpEndDate.toggle()" type="button">
                                    <i class="far fa-calendar-alt"></i>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="IsControlInvalid(MemberSuspendFormGroup,'dtpEndDate')">
                            <small *ngIf="MemberSuspendFormGroup.get('dtpEndDate').errors.required"
                                 class="invalid-feedback d-block">End Date is required</small>
                            <small *ngIf="MemberSuspendFormGroup.get('dtpEndDate').errors.mindateinvalid"
                                 class="invalid-feedback d-block">End Date is invalid</small>
                            <small *ngIf="MemberSuspendFormGroup.get('dtpEndDate').errors.rangedateinvalid"
                                 class="invalid-feedback d-block">The End Date should be greater than the Start
                                Date</small>
                            <small *ngIf="MemberSuspendFormGroup.get('dtpEndDate').errors.suspensioninvalid_2w"
                                 class="invalid-feedback d-block">Suspension period cannot be shorter than 2
                                weeks</small>
                            <small *ngIf="MemberSuspendFormGroup.get('dtpEndDate').errors.suspensioninvalid_2m"
                                 class="invalid-feedback d-block">Suspension period cannot be longer than 2
                                months</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row" *ngIf="cancelSuspensionBtn">
                <div class="col-md-8 col-lg-5 offset-md-3 offset-lg-2">
                    <div class="d-flex flex-row-reverse w-100">
                        <button class="px-2 py-1 btn btn-danger text-light" type="button"
                            (click)="confirmCancelSuspension()">
                            Cancel Suspension</button>
                    </div>
                </div>
            </div>
            <div class="form-group row" *ngIf="MemberSuspendFormGroup.get('ddlReason').value==-1">
                <div class="col-md-8 col-lg-5 offset-md-3 offset-lg-2">
                    <div class="d-flex w-100 align-items-center rounded-10 bg-default text-white px-3 py-2">
                        <i class="fa fa-exclamation-circle fa-2x mr-2"></i>
                        <div>
                            <p class="m-0">Suspensions request for reasons other than available reasons need to be
                                discussed with our Customer Service team.</p>
                            <p class="m-0">Please click <a
                                    href="https://fitnesspassport.freshdesk.com/support/tickets/new"
                                    class="text-primary" target="_blank">here</a> to contact them.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row mt-4">
                <div class="col-md-8 col-lg-5 offset-md-3 offset-lg-2">
                    <button class="px-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                        (click)="btnCancel_Click()">
                        <i class="fas fa-ban mr-2"></i>CANCEL</button>
                    <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="btnSubmit_Click()"
                        *ngIf="MemberSuspendFormGroup.get('ddlReason').value!=-1"><i class="fas fa-check mr-2"></i>
                        Submit</button>
                </div>
            </div>
        </div>
    </form>

</div>