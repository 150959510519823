import { Component, Injector, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FPAbstractComponent } from "@fp/components/base";
import { CommonMessage } from "@fp/constant";
import { CommonConstants } from "@fp/constant/common-constants";
import { TermTypes } from "@fp/constant/term-types-constant";
import { UploadAdapter } from "@fp/helpers/upload-adapter";
import { NewTermsAndConditions } from "@fp/models";
import { CommonDataService, CommonService, MessageBox } from "@fp/services";

@Component({
  selector: "app-edit-terms-conditions",
  templateUrl: "./edit-terms-conditions.component.html",
  styleUrls: ["./edit-terms-conditions.component.css"],
})
export class EditTermsConditionsComponent extends FPAbstractComponent implements OnInit {
  public EditTermFormGroup: UntypedFormGroup;
  public Editor = ClassicEditor;
  ckeConfig: any;
  public allTermsData: any;
  finalSubmitArray = []
  public mainTerm: NewTermsAndConditions;
  public keyTerm: NewTermsAndConditions;
  public goCardlessTerm: NewTermsAndConditions;
  countriesId = CommonConstants.GET_COUNTRIES;
  disableSave = true;
  public selectTermsRegions = CommonConstants.GET_TERM_TYPES;
  isInit = false;

  selectGoCardlessOnly: boolean = false;

  get SelectTermCtrl() {
    return <UntypedFormControl>this.EditTermFormGroup.get("selectTerm");
  }
  get MainTermCtrl() {
    return <UntypedFormControl>this.EditTermFormGroup.get("mainTerm");
  }
  get KeyTermCtrl() {
    return <UntypedFormControl>this.EditTermFormGroup.get("keyTerm");
  }
  get GoCardlessTermCtrl() {
    return <UntypedFormControl>this.EditTermFormGroup.get("goCardlessTerm");
  }

  constructor(injector: Injector, private _formBuilder: UntypedFormBuilder, private cmsrv: CommonService, private commonDataSvc: CommonDataService) {

    super(injector);
    this.ckeConfig = {
      allowedContent: true,
      forcePasteAsPlainText: true,
      mediaEmbed: {
        previewsInData: true
      },
      removeButtons:
        "Radio,TextField,Textarea,Checkbox,Form,Select,Button," +
        "ImageButton,HiddenField,CreateDiv,Flash,Iframe,PasteFromWord,Language" +
        ",Anchor,PageBreak,Maximize,ShowBlocks,About,Source,Save,NewPage,Print",

    };
  }

  ngOnInit(): void {

    this.cmsrv.Header.title="Edit Terms and Conditions";
    this.EditTermFormGroup = this._formBuilder.group({
      selectTerm: [null, Validators.required],
      mainTerm: ["", Validators.required],
      keyTerm: ["", [Validators.required]],
      goCardlessTerm: ["", Validators.required]
    });
  
    this.getTermsData();
  }

  getTermsData(){
    this.commonDataSvc.getAllTermsAndConditions().subscribe((res) => {
      if (res.Success) {
        this.allTermsData = res.Data
        console.log("res", this.allTermsData)
        const preferedTerm = this.SelectTermCtrl.value;
        const defaultTerm = this.selectTermsRegions.find((term) =>
          preferedTerm ? preferedTerm.id === term.Id : term.Name === TermTypes.PRIMARY_AU
        );

        if (defaultTerm != null) {
          this.SelectTermCtrl.setValue(defaultTerm, { emitEvent: false });
        }
        this.isInit = true;
      }
    });
  }

  checkTerm(e) {
    if (e.Name === TermTypes.PRIMARY_AU) {

      this.selectGoCardlessOnly = false;
      this.mainTerm = this.allTermsData.filter(term => term.CountryCode === this.countriesId[0].CountryId && term.TermConditionType === CommonConstants.PREMIUM_TERMS)[0];
      this.keyTerm = this.allTermsData.filter(term => term.CountryCode === this.countriesId[0].CountryId && term.TermConditionType === CommonConstants.PREMIUM_KEY_TERMS)[0];
      console.log("primaryTermAU", this.mainTerm)
      console.log("primaryKeyTermAU", this.keyTerm)

      this.MainTermCtrl.setValue(this.mainTerm.Content, { emitEvent: false });
      this.KeyTermCtrl.setValue(this.keyTerm.Content, { emitEvent: false });
    } else if (e.Name === TermTypes.SECONDARY_AU) {

      this.selectGoCardlessOnly = false;
      this.mainTerm = this.allTermsData.filter(term => term.CountryCode === this.countriesId[0].CountryId && term.TermConditionType === CommonConstants.SECONDARY_TERMS)[0];
      this.keyTerm = this.allTermsData.filter(term => term.CountryCode === this.countriesId[0].CountryId && term.TermConditionType === CommonConstants.SECONDARY_KEY_TERMS)[0];
      console.log("secondaryTermAU", this.mainTerm)
      console.log("secondaryKeyTermAU", this.keyTerm)

      this.MainTermCtrl.setValue(this.mainTerm.Content, { emitEvent: false });
      this.KeyTermCtrl.setValue(this.keyTerm.Content, { emitEvent: false });
    } else if (e.Name === TermTypes.PRIMARY_NZ) {

      this.selectGoCardlessOnly = false;
      this.mainTerm = this.allTermsData.filter(term => term.CountryCode === this.countriesId[1].CountryId && term.TermConditionType === CommonConstants.PREMIUM_TERMS)[0];
      this.keyTerm = this.allTermsData.filter(term => term.CountryCode === this.countriesId[1].CountryId && term.TermConditionType === CommonConstants.PREMIUM_KEY_TERMS)[0];
      console.log("primaryTermNZ", this.mainTerm)
      console.log("primaryKeyTermNZ", this.keyTerm)

      this.MainTermCtrl.setValue(this.mainTerm.Content, { emitEvent: false });
      this.KeyTermCtrl.setValue(this.keyTerm.Content, { emitEvent: false });
    } else if (e.Name === TermTypes.SECONDARY_NZ) {

      this.selectGoCardlessOnly = false;
      this.mainTerm = this.allTermsData.filter(term => term.CountryCode === this.countriesId[1].CountryId && term.TermConditionType === CommonConstants.SECONDARY_TERMS)[0];
      this.keyTerm = this.allTermsData.filter(term => term.CountryCode === this.countriesId[1].CountryId && term.TermConditionType === CommonConstants.SECONDARY_KEY_TERMS)[0];
      console.log("secondaryTermNZ", this.mainTerm)
      console.log("secondaryKeyTermNZ", this.keyTerm)

      this.MainTermCtrl.setValue(this.mainTerm.Content, { emitEvent: false });
      this.KeyTermCtrl.setValue(this.keyTerm.Content, { emitEvent: false });
    } else if (e.Name === TermTypes.GOCARDLESS_NZ) {
      
      this.selectGoCardlessOnly = true;
      this.goCardlessTerm = this.allTermsData.filter(term => term.CountryCode === this.countriesId[1].CountryId && term.TermConditionType === CommonConstants.GOCARDLESS_TERMS)[0];
      console.log("goCardlessTermNZ", this.goCardlessTerm);

      this.GoCardlessTermCtrl.setValue(this.goCardlessTerm.Content, { emitEvent: false });
    }
  }

  public btnSubmit_Click() {
    const submitValue = this.EditTermFormGroup.value;
    if(this.selectGoCardlessOnly){
      if((submitValue.goCardlessTerm !== this.goCardlessTerm.Content)){
        const goCardlessTermObject = { ...this.goCardlessTerm, Content: submitValue.goCardlessTerm }
        this.finalSubmitArray = [goCardlessTermObject]
        this.disableSave = false;
        console.log("finalSubmit 4", this.finalSubmitArray);
      }else{
        this.disableSave = true;        
      }
    }else{
      if ((submitValue.mainTerm !== this.mainTerm.Content) && (submitValue.keyTerm !== this.keyTerm.Content)) {
        const mainTermObject = { ...this.mainTerm, Content: submitValue.mainTerm }
        const keyTermObject = { ...this.keyTerm, Content: submitValue.keyTerm }
        this.finalSubmitArray = [mainTermObject, keyTermObject]
        this.disableSave = false;      
        console.log("finalSubmit 1", this.finalSubmitArray);
      } else if ((submitValue.mainTerm !== this.mainTerm.Content) && (submitValue.keyTerm === this.keyTerm.Content)) {
        const mainTermObject = { ...this.mainTerm, Content: submitValue.mainTerm }
        this.finalSubmitArray = [mainTermObject]
        this.disableSave = false;     
        console.log("finalSubmit 2", this.finalSubmitArray);
      } else if ((submitValue.mainTerm === this.mainTerm.Content) && (submitValue.keyTerm !== this.keyTerm.Content)) {
        const keyTermObject = { ...this.keyTerm, Content: submitValue.keyTerm }
        this.finalSubmitArray = [keyTermObject]
        this.disableSave = false;
        console.log("finalSubmit 3", this.finalSubmitArray);
      } else {
        this.disableSave = true;        
      }
    }
   

    if (!this.disableSave) {
      this.Invoke(
        this.cmsrv.updateTerm(this.finalSubmitArray),
        {
          onSuccess: (res) => {
            if (res.Success) {
              console.log("success", this.finalSubmitArray)
              this.getTermsData();
              MessageBox.ShowInfo(
                this.dialog,
                CommonMessage.DATA_SAVE_SUCCESS_MESSAGE
              ).subscribe(data => {
                  console.log("data",res.Data);                  
              });
            } else {
              this.HandleResponseError(res);
            }
          }
        }
      );
    }

  }

  onReady(eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      console.log(btoa(loader.file));
      return new UploadAdapter(loader);
    };
  }
}
