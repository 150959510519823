import { StepperSelectionEvent } from "@angular/cdk/stepper";
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  isDevMode,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MemberFormBase } from "src/app/components/base/member-form-base";
import {
  MemberEmployerInfoComponent,
  MemberMembershipPackagesComponent,
  MemberPaymentDetailsComponent,
  MemberPersonalInfoComponent,
  MemberFamilyMembersComponent,
} from "src/app/components/members/shared";
import { CommonMessage, RouterConstant } from "src/app/constant";
import { APIConstant } from "src/app/constant/apiconstant";
import { StorageKey } from "src/app/constant/storage-key";
import { EMemberStatus, EMemberType } from "src/app/enums";
import {
  DataResult,
  Employer,
  Member,
  ResultModel,
  TermsAndConditions,
  User,
} from "src/app/models";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CommonDataService } from "src/app/services/common-data.service";
import { MessageBox, MessageBoxButton } from "src/app/services/common-dialog.service";
import { CommonService } from "src/app/services/common.service";
import { EmployerService } from "src/app/services/employer.service";
import { MemberService } from "src/app/services/member.service";
import { Utils } from "@fp/helpers";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { link } from "fs";
import { MemberContainer } from "@fp/models/member-container.model";
import { HttpDAO } from "@fp/services";
import { HttpClient } from "@angular/common/http";
export interface PeriodicElement {
  LastName: string;
  FirstName: string;
  MemberType: string;
  MemberNumber: string;
  MemberCardStatus: string;
  MemberCardNumber: string;
  PhotoLocation: string;
  action: string;
}

enum MemberSignupTabIndex {
  EmployerInfo,
  MembershipPackages,
  PersonalDetails,
  FamilyMembers,
  ShoppingCart,
  PaymentDetails,
}

@Component({
  selector: "app-member-signup",
  templateUrl: "./member-signup.component.html",
  styleUrls: ["./member-signup.component.css"],
  host: {
    "(window:resize)": "onResize($event)",
  },
  providers: [{ provide: MemberFormBase, useExisting: MemberSignupComponent }],
})
export class MemberSignupComponent
  extends MemberFormBase
  implements AfterViewChecked {
  currentUser: User;
  currentTermsAndConditions: TermsAndConditions;
  termsAndConsContent: SafeHtml;
  isTermsAndConsAccepted = false;
  stepProgress: boolean[] = [];
  disablechkTermCons = true;
  checkscroll = false;
  httpdao: HttpDAO | null;

  heighttabs: string;
  heightTCText: string;
  hasInit = false;

  MemberSignupTabIndex = MemberSignupTabIndex;

  public interval;

  @ViewChild("stepper", {static: true}) stepper: MatStepper;
  @ViewChild("tabfpstepper") tabfpstepper: ElementRef;

  // TODO: remove after implementation completed.
  get isDebugging() {
    return isDevMode() && window["enableDebugging"] === true;
  }
  JSON = JSON;

  @ViewChild("employerInfo", {static: true}) employerInfo: MemberEmployerInfoComponent;
  @ViewChild("membershipPackages", {static: true}) membershipPackages: MemberMembershipPackagesComponent;
  @ViewChild("personalInfo", {static: true}) personalInfo: MemberPersonalInfoComponent;
  @ViewChild("familyMembers", {static: true}) familyMembers: MemberFamilyMembersComponent;
  @ViewChild("paymentDetails", {static: true}) paymentDetails: MemberPaymentDetailsComponent;
  @ViewChild("dlgTermsConsContent", {static: true}) dlgTermsCons: ElementRef;

  TermAndConditionContent: SafeHtml;

  static getFormGroup() {
    const fb = new UntypedFormBuilder();
    return fb.group({
      EmployerInfo: MemberEmployerInfoComponent.getFormGroup(),
      MembershipPackages: MemberMembershipPackagesComponent.getFormGroup(),
      PersonalInfo: MemberPersonalInfoComponent.getFormGroup(),
      FamilyMembersTab: MemberFamilyMembersComponent.getFormGroup(),
      PaymentDetails: MemberPaymentDetailsComponent.getFormGroup(true),
    });
  }

  constructor(
    injector: Injector,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private commonDataSvc: CommonDataService,
    private memberSvc: MemberService,
    private employerSvc: EmployerService,
    private modal: NgbModal,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private commonservice: CommonService,
    private authService: AuthenticationService
  ) {
    super(injector);
    const username = this.commonservice.GetUser();
    if (!username) {
      this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
    }
    this.form = MemberSignupComponent.getFormGroup();
    this.async = false;
  }

  OnInit() {
    console.log("Init run");
    this.stepper._stepHeader.forEach((header, index) => {
      header._getHostElement().addEventListener(
        "click",
        (e: MouseEvent) => {
          const showMessage = !(
            this.stepper.selectedIndex ===
              MemberSignupTabIndex.MembershipPackages &&
            index === MemberSignupTabIndex.EmployerInfo
          );
          this.membershipPackages.showMessageBox = showMessage;
          if (index > this.stepper.selectedIndex) {
            // Validate all steps starting from current step before the next step is selected
            for (let i = this.stepper.selectedIndex; i < index; i++) {
              if (!this.validateStep(i)) {
                e.preventDefault();
                e.stopImmediatePropagation();
                return false;
              }
            }
          }
        },
        true
      );
    });

    this.personalInfo.personalDetails.StatusDiv.style.setProperty(
      "display",
      "none"
    );
    this.familyMembers.IsMemberSignup = true;
    this.httpdao = new HttpDAO(this.http);
    super.OnInit();
  }

  ngOnInit() {
    this.commonservice.App.isMemberSignup = true;
    this.commonservice.App.opened = false;
  }

  ngAfterViewChecked() {
    if (!this.commonSvc.App.mobileQuery.matches) {
      this.heighttabs =
        this.tabfpstepper.nativeElement.querySelector(
          "#membrsignuptb" + this.stepper.selectedIndex
        ).clientHeight + "px";
    } else {
      this.heighttabs = "100%";
    }

    // if(this.disablechkTermCons) {
    //     this.checkScrollEndContent();
    // }

    if (this.disablechkTermCons) {
      var termscondObj = document.getElementById("fp-termscondText");
      var termscondContObj = document.getElementById("fp-tcContent");
      if (termscondObj) {
        if (termscondContObj.innerHTML && this.checkscroll) {
          if (termscondObj.scrollHeight == termscondObj.offsetHeight) {
            this.disablechkTermCons = false;
          }
        }
      }
      if (!this.heightTCText) {
        this.setHeightContent();
      }
    }

    this.cdRef.detectChanges();

    // Author: Yen
    this.commonservice.Header.title = "Member Registration";
    if(!this.hasInit) {
      this.hasInit = true;
      this.OnInit();
      this.OnLoad();
    }
  }

  OnLoad() {
    this.stepProgress = Array(this.stepper._steps.length).fill(false);
    this.currentUser = <User>(
      JSON.parse(
        this.commonservice.D_FP_AES256(
          localStorage.getItem(StorageKey.USER_OBJECT)
        )
      )
    );
    console.log("current user: ", this.currentUser);
    if (this.currentUser && this.currentUser.UserId > 0) {
      this.Invoke(this.memberSvc.getByUserForMember(this.currentUser.UserId), {
        onSuccess: (res: DataResult<Member>) => {
          if (res.Success) {
            let isSigningUp = Boolean(localStorage.getItem("signupFlag"));
            if (res.Data == null && !isSigningUp) {
              const supportLink =
                '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
              MessageBox.ShowOKCustom(
                this.dialog,
                "Error",
                "Sorry, we're unable to complete the registration. \
                                Please try again. If you continue to experience problems, send us an enquiry \
                                via our support site " +
                  supportLink,
                "Take me back"
              ).subscribe((res) => {
                if (res.result.toLowerCase() === DialogResult.Ok) {
                  this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
                }
              });
              super.OnLoad();
            } else {
              if (res.Data != null) {
                this.data.model = res.Data;
                if (this.data.model.IsExistingMember == null) {
                  this.data.model.IsExistingMember = false;
                }
                if (this.data.model.IsInContract == null) {
                  this.data.model.IsInContract = false;
                }
              } else {
                this.data.model.UserId = this.currentUser.UserId;
                this.data.model.FirstName = this.currentUser.FirstName;
                this.data.model.MiddleName = this.currentUser.MiddleName;
                this.data.model.LastName = this.currentUser.LastName;
                this.data.model.MobileNumber = this.currentUser.MobileNumber;
                this.data.model.PrimaryEmail = this.currentUser.EmailAddress;
                this.data.model.GenderId = this.currentUser.GenderId;
                this.data.model.DateOfBirth =
                  this.currentUser.DateOfBirth != null
                    ? new Date(this.currentUser.DateOfBirth)
                    : null;
                this.data.model.MemberStatusId = EMemberStatus.CandidateMember;
                this.data.model.MemberTypeId = EMemberType.Primary;
                this.data.model.IsExistingMember = false;
                this.data.model.MemberType = {
                  MemberTypeId: 1,
                  Name: "Primary",
                  DisplayOrder: null,
                  IsPrimary: null,
                  IsActive: null,
                };
              }
              // fix phone
              this.data.model.MobileNumber = this.convertPhoneToLocalFormat(
                this.data.model.MobileNumber
              );
              //
              const employerId = parseInt(
                this.commonservice.EmployerIDForMemberSignUp,
                10
              );
              if (employerId > 0) {
                this.Invoke(this.employerSvc.getForMemberSignup(employerId), {
                  onSuccess: (getEmployerResult: DataResult<Employer>) => {
                    if (getEmployerResult.Success) {
                      const employer = getEmployerResult.Data;
                      this.data.model.Employer = employer;
                      this.data.model.EmployerId = employerId;
                      try {
                        if (
                          this.data.model.EmployerId > 0 &&
                          this.data.model.Memberships &&
                          this.data.model.Memberships[0] &&
                          !this.data.model.Memberships[0].MembershipPackage
                        ) {
                          this.data.model.Memberships[0].MembershipPackage = <
                            any
                          >{
                            MembershipPackageId: this.data.model.Memberships[0]
                              .MembershipPackageId,
                            EmployerId: this.data.model.EmployerId,
                          };
                        }
                        this.PatchValue(this.data.model, { emitEvent: false });
                      } catch (err) {
                        MessageBox.ShowOKCustom(
                          this.dialog,
                          "Error",
                          "Sorry, something went wrong before we could complete your \
                                                        registration. Please log in and complete the registration process. You can use \
                                                        your email and new password to login.",
                          "Take me back"
                        ).subscribe((res) => {
                          if (res.result.toLowerCase() === DialogResult.Ok) {
                            this.router.navigate([
                              RouterConstant.NAVIGATOR_LOGIN,
                            ]);
                          }
                        });
                        this._logger.error(err);
                      }
                      this.form.markAsPristine();
                    } else {
                      MessageBox.ShowOKCustom(
                        this.dialog,
                        "Error",
                        "Sorry, something went wrong before we could complete your \
                                                    registration. Please log in and complete the registration process. You can use \
                                                    your email and new password to login.",
                        "Take me back"
                      ).subscribe((res) => {
                        if (res.result.toLowerCase() === DialogResult.Ok) {
                          this.router.navigate([
                            RouterConstant.NAVIGATOR_LOGIN,
                          ]);
                        }
                      });
                      this._logger.error(getEmployerResult);
                    }
                    super.OnLoad();
                  },
                  onError: (err: any) => {
                    MessageBox.ShowOKCustom(
                      this.dialog,
                      "Error",
                      "Sorry, something went wrong before we could complete your \
                                                registration. Please log in and complete the registration process. You can use \
                                                your email and new password to login.",
                      "Take me back"
                    ).subscribe((res) => {
                      if (res.result.toLowerCase() === DialogResult.Ok) {
                        this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
                      }
                    });
                    this._logger.error(err);
                    super.OnLoad();
                  },
                });
              } else {
                try {
                  if (
                    this.data.model.EmployerId > 0 &&
                    this.data.model.Memberships &&
                    this.data.model.Memberships[0]
                  ) {
                    this.data.model.Memberships[0].MembershipPackage = <any>{
                      EmployerId: this.data.model.EmployerId,
                    };
                  }
                  this.PatchValue(this.data.model, { emitEvent: false });
                } catch (err) {
                  MessageBox.ShowOKCustom(
                    this.dialog,
                    "Error",
                    "Sorry, something went wrong before we could complete your \
                                        registration. Please log in and complete the registration process. You can use \
                                        your email and new password to login.",
                    "Take me back"
                  ).subscribe((res) => {
                    if (res.result.toLowerCase() === DialogResult.Ok) {
                      this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
                    }
                  });
                  this._logger.error(err);
                }
                this.form.markAsPristine();
                super.OnLoad();
              }
            }
          } else {
            const supportLink =
              '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
            MessageBox.ShowOKCustom(
              this.dialog,
              "Error",
              "Sorry, we're unable to complete the registration. \
                            Please try again. If you continue to experience problems, send us an enquiry \
                            via our support site " +
                supportLink,
              "Take me back"
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
              }
            });
            this._logger.error(res);
          }
        },
        onError: (err: any) => {
          MessageBox.ShowOKCustom(
            this.dialog,
            "Error",
            "Sorry, something went wrong before we could complete your \
                        registration. Please log in and complete the registration process. You can use \
                        your email and new password to login.",
            "Take me back"
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
            }
          });
          this._logger.error(err);
          super.OnLoad();
        },
      });
    } else {
      const supportLink =
        '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
      MessageBox.ShowOKCustom(
        this.dialog,
        "Error",
        "Sorry, we're unable to complete the registration. \
            Please try again. If you continue to experience problems, send us an enquiry \
            via our support site " +
          supportLink,
        "Take me back"
      ).subscribe((res) => {
        if (res.result.toLowerCase() === DialogResult.Ok) {
          this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
        }
      });
    }
  }

  LoadComplete() {
    setTimeout(() => {
      this.stepper.selectionChange.subscribe((e: StepperSelectionEvent) => {
        const index = e.selectedIndex;
        this.stepProgress[e.previouslySelectedIndex] =
          e.previouslySelectedStep.completed;
        this.applyStepValue(e.previouslySelectedIndex);
        this.PatchValue(this.data.model, { emitEvent: false });
        const com = this.children.toArray()[index];
        if (com && !com.loaded) {
          com.Load();
        }
        if (
          index === MemberSignupTabIndex.FamilyMembers ||
          index === MemberSignupTabIndex.ShoppingCart
        ) {
          // Apply primary member's personal details to reflect on members grid
          this.applyStepValue(MemberSignupTabIndex.PersonalDetails);

          if (index === MemberSignupTabIndex.FamilyMembers) {
            this.familyMembers.RefreshDataSource();
          }
        } else if (
          e.selectedIndex === MemberSignupTabIndex.MembershipPackages
        ) {
          if (this.membershipPackages.loaded) {
            // Refresh membership packages with new Employer ID
            this.membershipPackages.RefreshMembershipPackages();
          }
        }
      });
    });
    super.LoadComplete();
  }

  _stepProgressBackgroundVisible(index: number) {
    return this.stepProgress[index] || this.stepper.selectedIndex === index;
  }

  _stepProgressCompleteIconVisible(index: number) {
    return this.stepProgress[index] && this.stepper.selectedIndex !== index;
  }

  SetSubmitButton(event) {
    this.isTermsAndConsAccepted = !this.isTermsAndConsAccepted;
  }

  btnSaveForLater_Click() {
    let sectionValid = true;
    switch (this.stepper.selectedIndex) {
      case MemberSignupTabIndex.PaymentDetails:
        this.paymentDetails.Validate();
        sectionValid = this.paymentDetails.valid && sectionValid;
        if (sectionValid) {
          this.data.model = this.applyValue(
            this.data.model,
            this.paymentDetails
          );
        }
      case MemberSignupTabIndex.ShoppingCart:
        sectionValid = true && sectionValid;
        this.data.model = this.applyValue(this.data.model, this.familyMembers);
      case MemberSignupTabIndex.FamilyMembers:
        sectionValid = true && sectionValid;
        this.data.model = this.applyValue(this.data.model, this.familyMembers);
      case MemberSignupTabIndex.PersonalDetails:
        this.personalInfo.Validate();
        sectionValid = this.personalInfo.valid && sectionValid;
        if (sectionValid) {
          this.data.model = this.applyValue(this.data.model, this.personalInfo);
        }
      case MemberSignupTabIndex.MembershipPackages:
        sectionValid = true && sectionValid;
        this.data.model = this.applyValue(
          this.data.model,
          this.membershipPackages
        );
      case MemberSignupTabIndex.EmployerInfo:
        this.employerInfo.Validate();
        sectionValid = this.employerInfo.valid && sectionValid;
        if (sectionValid) {
          this.data.model = this.applyValue(this.data.model, this.employerInfo);
        }
        break;
      default:
        sectionValid = false;
        break;
    }
    if (sectionValid) {
      this.data.model.TermConditionDateTimeStamp = null;
      const processedData = this.processDataForSubmission(this.data.model);
      this.Invoke(this.memberSvc.update(processedData), {
        onSuccess: (res: ResultModel) => {
          if (res.Success) {
            this.SaveMemberAddress(this.data.model.MemberId);
            MessageBox.ShowInfo(
              this.dialog,
              CommonMessage.DRAFT_SAVE_SUCCESS_MESSAGE
            );
          } else {
            MessageBox.ShowOKCustom(
              this.dialog,
              "Error",
              "Sorry, something went wrong before we could complete your \
                            registration. Please log in and complete the registration process. You can use \
                            your email and new password to login.",
              "Take me back"
            ).subscribe((res) => {
              if (res.result.toLowerCase() === DialogResult.Ok) {
                this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
              }
            });
            this._logger.error(res);
          }
        },
        onError: (err: any) => {
          MessageBox.ShowOKCustom(
            this.dialog,
            "Error",
            "Sorry, something went wrong before we could complete your \
                        registration. Please log in and complete the registration process. You can use \
                        your email and new password to login.",
            "Take me back"
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
            }
          });
          this._logger.error(err);
        },
      });
    }
  }

  clearMembershipPackage(event) {
    this.stepProgress[1] = false;
    this.form.get("MembershipPackages").reset();
  }

  applyStepValue(index: number) {
    switch (index) {
      case MemberSignupTabIndex.EmployerInfo:
        this.data.model = this.applyValue(this.data.model, this.employerInfo);
        break;
      case MemberSignupTabIndex.MembershipPackages:
        this.data.model = this.applyValue(
          this.data.model,
          this.membershipPackages
        );
        break;
      case MemberSignupTabIndex.PersonalDetails:
        this.data.model = this.applyValue(this.data.model, this.personalInfo);
        break;
      case MemberSignupTabIndex.FamilyMembers:
      case MemberSignupTabIndex.ShoppingCart:
        this.data.model = this.applyValue(this.data.model, this.familyMembers);
        break;
      case MemberSignupTabIndex.PaymentDetails:
        this.data.model = this.applyValue(this.data.model, this.paymentDetails);
        break;
    }
    if (this.membershipPackages.selectedPackage !== undefined) {
      this.personalInfo.membershipPkg = this.membershipPackages.selectedPackage.MembershipPackageId;
      this.familyMembers.MembershipPackageId = this.membershipPackages.selectedPackage.MembershipPackageId;
    }
  }

  validateStep(index) {
    let valid = false;
    switch (index) {
      case MemberSignupTabIndex.EmployerInfo:
        this.employerInfo.Validate();
        valid = this.employerInfo.valid;
        break;
      case MemberSignupTabIndex.MembershipPackages:
        this.membershipPackages.Validate();
        valid = this.membershipPackages.valid;
        break;
      case MemberSignupTabIndex.PersonalDetails:
        this.personalInfo.Validate();
        valid = this.personalInfo.valid;
        break;
      case MemberSignupTabIndex.FamilyMembers:
      case MemberSignupTabIndex.ShoppingCart:
        this.familyMembers.Validate();
        valid = this.familyMembers.valid;
        break;
      case MemberSignupTabIndex.PaymentDetails:
        this.paymentDetails.Validate();
        valid = this.paymentDetails.valid;
        break;
    }
    return valid;
  }

  disableNextButton() {
    return this.employerInfo.DisableNextButton();
  }

  btnBack_Click() {
    document.documentElement.scrollTop = 0;
    this.stepper.previous();
  }

  buttonNextEventHandler(isNext: boolean) {
    if(isNext) {
      this.btnNext_Click();
    } else {
      this.btnBack_Click();
    }
  }

  canGoBack(): boolean {
    if (this.stepper.selectedIndex > 0) {
      return true;
    }
    return false;
  }

  public ShowPopupRemindPhoto(dialog: MatDialog) {
    const PhotoUploadElement = document.querySelectorAll(
      ".fas.fa-user-tie.fa-2x.text-secondary"
    );
    if (PhotoUploadElement.length > 0) {
      const ele = <HTMLElement>PhotoUploadElement[0];
      // MessageBox.ShowYesNo(
      //   dialog,
      //   "Do you want to upload a photo for your membership card? " +
      //     "You may need to bring secondary ID to access facilities if your card does not have a photo. " +
      //     "We highly recommend you upload one before you finish sign up in the Personal Details tab."
      // );
      MessageBox.ShowUploadRequest(this.dialog, "Do you want to upload a photo for your membership card? " +
      "You may need to bring secondary ID to access facilities if your card does not have a photo. " +
      "We highly recommend you upload one before you finish sign up in the Personal Details tab.").subscribe(r => {
        if (r.result == DialogResult.Yes) {
          document.documentElement.scrollTop = 0;
        }
        if (r.result == DialogResult.No) {
          document.documentElement.scrollTop = 0;
          this.stepper.next();
        }
      })
      return;
    } else {
      document.documentElement.scrollTop = 0;
      this.stepper.next();
      return;
    }
  }

  btnNext_Click() {
    const stepValid = this.validateStep(this.stepper.selectedIndex);
    if (!stepValid) {
      this.commonSvc.ShowPopupValidator(this.dialog);
      return;
    }
    if (this.stepper.selectedIndex === 2) {
      this.ShowPopupRemindPhoto(this.dialog);
      return;
    }
    document.documentElement.scrollTop = 0;
    if (this.stepper.selectedIndex < this.stepper._steps.length - 1) {
      this.stepper.next();
    } else {
      this.applyStepValue(this.stepper.selectedIndex);

      if (this.commonservice.GetTermsConditions()) {
        let currentMember: Member;
        const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
        if (getContainer != null) {
          let container = <MemberContainer>JSON.parse(getContainer);
          currentMember = (<DataResult<Member>>(
            JSON.parse(this.commonSvc.D_FP_AES256(container.Member))
          )).Data;
        }
        // if(currentMember.MemberType.Name == "Primary") {
        //   this.currentTermsAndConditions = this.commonservice.GetTermsConditions();
        // } else {
        //   this.currentTermsAndConditions = this.commonservice.GetSecondaryTermsConditions();
        // }
        this.currentTermsAndConditions = this.commonservice.GetTermsConditions();
        this.termsAndConsContent = this.sanitizer.bypassSecurityTrustHtml(
          this.currentTermsAndConditions.Content
        );
        this.modal.open(this.dlgTermsCons, {
          windowClass: "membersignup-dialog termconditions-dialog",
          backdrop: "static",
        });
        this.checkscroll = true;
        return;
      }
      this.Invoke(
        this.commonDataSvc.getLatestTermAndCondition(
          this.data.model.MemberId.toString()
        ),
        {
          onSuccess: (res: DataResult<TermsAndConditions>) => {
            if (res.Success) {
              this.currentTermsAndConditions = res.Data;
              this.termsAndConsContent = this.sanitizer.bypassSecurityTrustHtml(
                this.currentTermsAndConditions.Content
              );
              this.modal.open(this.dlgTermsCons, {
                windowClass: "membersignup-dialog termconditions-dialog",
                backdrop: "static",
              });
              this.checkscroll = true;
            } else {
              MessageBox.ShowOKCustom(
                this.dialog,
                "Error",
                "Sorry, something went wrong before we could complete your \
                            registration. Please log in and complete the registration process. You can use \
                            your email and new password to login.",
                "Take me back"
              ).subscribe((res) => {
                if (res.result.toLowerCase() === DialogResult.Ok) {
                  this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
                }
              });
              this._logger.error(res);
            }
          },
          onError: (err) => {
            const errMsg =
              "Sorry, there was a connection issue. Please try submitting the information again.";
            this.handleError(err, errMsg);
          },
        }
      );
    }
  }

  private processDataForSubmission(source: Member) {
    const target = Object.assign({}, source);
    target.MobileNumber = this.convertPhoneToInternationalFormat(
      target.MobileNumber
    );
    if (target.Employer) {
      target.EmployerId = target.Employer.EmployerId;
      delete target.Employer;
    }
    if (target.MemberType) {
      target.MemberTypeId = target.MemberType.MemberTypeId;
      delete target.MemberType;
    }
    if (target.MemberStatus) {
      target.MemberStatusId = target.MemberStatus.MemberStatusId;
      delete target.MemberStatus;
    }
    if (target.FavouredFacility) {
      target.FavouredFacilityId = target.FavouredFacility.FacilityId;
      delete target.FavouredFacility;
    }
    if (target.ExistingFacility) {
      target.ExistingMemberFacilityId = target.ExistingFacility.FacilityId;
      delete target.ExistingFacility;
    }
    if (target.MedicalAnswers instanceof Array) {
      target.MedicalAnswers.forEach((answer) => {
        delete answer.MedicalQuestion;
      });
    }
    if (target.FamilyMembers instanceof Array) {
      target.FamilyMembers = target.FamilyMembers.map((member) => {
        return this.processDataForSubmission(member);
      });
    }
    delete target.Suburb;
    delete target.Postcode;
    delete target.State;
    delete target.Country;
    for (const key in target) {
      if (target.hasOwnProperty(key) && target[key] == null) {
        delete target[key];
      }
    }
    return target;
  }

  btnSubmit_Click() {
    this.Validate();
    this.data.model = this.applyValue(this.data.model, this);
    const processedData = this.processDataForSubmission(this.data.model);

    if (!this.valid) {
      // TODO: remove this code block after implementation complete.
      if (this.isDebugging) {
        const invalidCtrls = this._findInvalidControls(this.form);
        console.log("Invalid controls:\n" + invalidCtrls.join("\n"));
      }
      return;
    }

    if (this.isTermsAndConsAccepted) {
      processedData.TermConditionDateTimeStamp = new Date();
      processedData.TermConditionId = this.currentTermsAndConditions.TermConditionId;
    } else {
      return;
    }

    MessageBox.ShowInfo(
      this.dialog,
      "Your application is being submitted. Don't press back or refresh your screen"
    )
    this.Invoke(this.memberSvc.updateSignupInfo(processedData), {
      onSuccess: (res: ResultModel) => {
        if (res.Success) {
          this.completeSignup();
        } else {
          const supportLink =
            '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
          MessageBox.ShowOKCustom(
            this.dialog,
            "Error",
            "Sorry, we're unable to complete the registration. \
                        Please try again. If you continue to experience problems, send us an enquiry \
                        via our support site " +
              supportLink,
            "Take me back"
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
            }
          });
          this._logger.error(res);
        }
      },
      onError: (err) => {
        const supportLink =
          '<a href="https://fitnesspassport.freshdesk.com">here.</a>';
        MessageBox.ShowOKCustom(
          this.dialog,
          "Error",
          "Sorry, we're unable to complete the registration. \
                    Please try again. If you continue to experience problems, send us an enquiry \
                    via our support site " +
            supportLink,
          "Take me back"
        ).subscribe((res) => {
          if (res.result.toLowerCase() === DialogResult.Ok) {
            this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
          }
        });
        this._logger.error(err);
      },
    });
  }

  private completeSignup() {
    localStorage.removeItem("signupFlag");
    this.commonservice.App.isLoginSuccessDisplay = true;
    this.commonservice.App.isMemberSignup = false;
    console.log("Setting Member to Container again");
    const currentUser = <User>JSON.parse(this.commonservice.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
    this.memberSvc.getByUserForMember(currentUser.UserId)
      .subscribe((getMember) => {
        let memberEncrypt = this.commonservice.E_FP_AES256(
          JSON.stringify(getMember)
        );
        if (getMember.Data && !getMember.Data.MemberIdPrimary) {
          if (getMember.Data.MemberCard) {
            this.commonservice.setCardForMemberPortal(
              getMember.Data.MemberCard.Data.Results
            );
          }
          if (getMember.Data.MembershipSuspension) {
            this.commonservice.SetMemSuspension(
              getMember.Data.MembershipSuspension.Data
            );
          }
          if (getMember.Data.FamilyObject) {
            this.commonservice.SetMemFamObj(
              getMember.Data.FamilyObject
            );
          }
        }

        this.httpdao!.getSingleData(
          APIConstant.API_GET_ALL_PRIVILEGES_V2
        ).subscribe((getPrivileges) => {
          let newContainer = new MemberContainer();
          newContainer.Privileges = getPrivileges;
          newContainer.Member = memberEncrypt;
          const loginData = JSON.stringify(newContainer);
          localStorage.setItem(
            StorageKey.CONTAINER_OBJECT,
            loginData
          );
          console.log("Success setting Member");
          this.router.navigate([RouterConstant.NAVIGATOR_END_MEMBER_SIGNUP]);
        });
    });
  }

  private SaveMemberAddress(memberid: number) {
    this.Invoke(this.memberSvc.updateaddressfamilyformember(memberid), {
      onSuccess: (res: ResultModel) => {
        if (res.Success) {
          localStorage.removeItem("signupFlag");
          this.commonservice.App.isLoginSuccessDisplay = true;
          console.log("Setting Member to Container again");
          const currentUser = <User>JSON.parse(this.commonservice.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
          this.memberSvc.getByUserForMember(currentUser.UserId)
            .subscribe((getMember) => {
              let memberEncrypt = this.commonservice.E_FP_AES256(
                JSON.stringify(getMember)
              );
              if (getMember.Data && !getMember.Data.MemberIdPrimary) {
                if (getMember.Data.MemberCard) {
                  this.commonservice.setCardForMemberPortal(
                    getMember.Data.MemberCard.Data.Results
                  );
                }
                if (getMember.Data.MembershipSuspension) {
                  this.commonservice.SetMemSuspension(
                    getMember.Data.MembershipSuspension.Data
                  );
                }
                if (getMember.Data.FamilyObject) {
                  this.commonservice.SetMemFamObj(
                    getMember.Data.FamilyObject
                  );
                }
              }

              this.httpdao!.getSingleData(
                APIConstant.API_GET_ALL_PRIVILEGES_V2
              ).subscribe((getPrivileges) => {
                let newContainer = new MemberContainer();
                newContainer.Privileges = getPrivileges;
                newContainer.Member = memberEncrypt;
                const loginData = JSON.stringify(newContainer);
                localStorage.setItem(
                  StorageKey.CONTAINER_OBJECT,
                  loginData
                );
                console.log("Success setting Member");
                this.router.navigate([RouterConstant.NAVIGATOR_END_MEMBER_SIGNUP]);
              });
          });
        } else {
          MessageBox.ShowOKCustom(
            this.dialog,
            "Error",
            "Sorry, something went wrong before we could complete your \
                        registration. Please log in and complete the registration process. You can use \
                        your email and new password to login.",
            "Take me back"
          ).subscribe((res) => {
            if (res.result.toLowerCase() === DialogResult.Ok) {
              this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
            }
          });
          this._logger.error(res);
        }
      },
      onError: (err) => {
        MessageBox.ShowOKCustom(
          this.dialog,
          "Error",
          "Sorry, something went wrong before we could complete your \
                    registration. Please log in and complete the registration process. You can use \
                    your email and new password to login.",
          "Take me back"
        ).subscribe((res) => {
          if (res.result.toLowerCase() === DialogResult.Ok) {
            this.router.navigate([RouterConstant.NAVIGATOR_LOGIN]);
          }
        });
        this._logger.error(err);
      },
    });
  }

  // ----------------------------------------
  private convertPhoneToLocalFormat(phone: string) {
    if (phone && phone.length > 0) {
      if (phone.startsWith("+61")) {
        phone = "0" + phone.substring(3);
      }
    }
    return phone;
  }

  private convertPhoneToInternationalFormat(phone: string) {
    if (phone && phone.length > 0) {
      if (!phone.startsWith("+61")) {
        if (phone.startsWith("0")) {
          phone = phone.substring(1);
        }
        phone = "+61" + phone;
      }
    }
    return phone;
  }

  public onScroll() {
    if (this.disablechkTermCons) {
      this.checkScrollEndContent();
    }
  }
  public onResize() {
    this.setHeightContent();
  }
  public checkScrollEndContent() {
    var termscondObj = document.getElementById("fp-termscondText");
    var termscondContObj = document.getElementById("fp-tcContent");
    var termscondEndContObj = document.getElementById("fp-tcEndContent");
    if (termscondContObj) {
      if (
        termscondContObj.innerHTML &&
        this.checkscroll &&
        this.disablechkTermCons
      ) {
        console.log(
          "termscondEndContObj.getBoundingClientRect().bottom: " +
            termscondEndContObj.getBoundingClientRect().bottom
        );
        console.log(
          "termscondObj.getBoundingClientRect().bottom: " +
            termscondObj.getBoundingClientRect().bottom
        );
        if (
          termscondEndContObj.getBoundingClientRect().bottom <
          termscondObj.getBoundingClientRect().bottom
        ) {
          console.log(
            "termscondEndContObj end: " +
              termscondEndContObj.getBoundingClientRect().bottom
          );
          this.disablechkTermCons = false;
          this.checkscroll = false;
        }
      }
    }
  }

  public setHeightContent() {
    var termscondContObj = document.getElementById("fp-tcContent");
    if (termscondContObj) {
      this.heightTCText =
        window.innerHeight -
        document.getElementById("modalheader").offsetHeight -
        document.getElementById("chkTC").offsetHeight -
        document.getElementById("btnTC").offsetHeight +
        "px";
    }
  }
}
