import { Injectable } from "@angular/core";
import { HttpDAO } from "./httpdao.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import * as Models from "src/app/models";
import { APIConstant } from "src/app/constant/apiconstant";
import { User, DataResult } from "src/app/models";
import { StorageKey } from "@fp/constant";
import { AuditLog, AuditLogVM, BotLogs } from "@fp/models/audit-logging.model";
import { catchError, map, debounceTime } from 'rxjs/operators';
import { CommonService } from ".";

@Injectable({
  providedIn: "root"
})
export class AuditLoggingService {
  private httpDao: HttpDAO | null;

  constructor(private httpClient: HttpClient, private cmsvc: CommonService) {
    this.httpDao = new HttpDAO(httpClient);
  }

  GetAuditLog(): Observable<DataResult<AuditLog>> {
    return this.httpDao.getSingleData(APIConstant.API_GET_AUDIT_LOGS);
  }

  getEmployerAuditLog(
    employerId: string
  ): Observable<DataResult<AuditLogVM[]>> {
    const request = { "id": employerId };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_GET_EMPLOYER_AUDIT_LOGS, {
      "Data": encrypteddata
    });
  }

  getEmployerAuditLogFilter(
    employerId: string,
    period: Date
  ): Observable<DataResult<AuditLogVM[]>> {
    const request = { "name" : period, "id": employerId };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_GET_EMPLOYER_AUDIT_LOGS, {
      "Data": encrypteddata
    });
  }

  getMemberAuditLog(
    memberId: string
  ): Observable<DataResult<AuditLogVM[]>> {
    const request = { "id": memberId };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(
      APIConstant.API_GET_MEMBER_AUDIT_LOGS,
      { "Data": encrypteddata }
    );
  }

  getMemberAuditLogFilter(
    memberId: string,
    period: Date
  ): Observable<DataResult<AuditLogVM[]>> {
    const request = { "name" : period, "id": memberId };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(
      APIConstant.API_GET_MEMBER_AUDIT_LOGS,
      { "Data": encrypteddata }
    );
  }

  getFacilityAuditLog(
    facilityId: string
  ): Observable<DataResult<AuditLogVM[]>> {
    const request = { "id": facilityId };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_GET_FACILITY_AUDIT_LOG, {
      "Data": encrypteddata
    });
  }

  getFacilityAuditLogFilter(
    facilityId: string,
    period: Date
  ): Observable<DataResult<AuditLogVM[]>> {
    const request = { "name" : period, "id": facilityId };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_GET_FACILITY_AUDIT_LOG, {
      "Data": encrypteddata
    });
  }

  getPackageAuditLog(packageId: string): Observable<DataResult<AuditLogVM[]>> {
    const request = { "id": packageId };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_GET_PACKAGE_AUDIT_LOG, {
      "Data": encrypteddata
    });
  }

  getPackageAuditLogFilter(
    packageId: string,
    period: Date
  ): Observable<DataResult<AuditLogVM[]>> {
    const request = { "name" : period,  "id": packageId };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_GET_PACKAGE_AUDIT_LOG, {
      "Data": encrypteddata
    });
  }

  getRegionAuditLog(regionId: string): Observable<DataResult<AuditLogVM[]>> {
    const request = { "id": regionId };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_GET_REGION_AUDIT_LOG, {
      "Data": encrypteddata
    });
  }

  getRegionAuditLogFilter(
    regionId: string,
    period: Date
  ): Observable<DataResult<AuditLogVM[]>> {
    const request = { "name" : period,  "id": regionId };
    const encrypteddata = this.cmsvc.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_GET_REGION_AUDIT_LOG, {
      "Data": encrypteddata
    });
  }

  getBotLogs(): Observable<DataResult<BotLogs[]>> {
    return this.httpDao.getSingleData(APIConstant.API_GET_BOT_LOGS);
  }

  postData(apiString: string, subscribeDataCallback: Function, dataInput: any, mapDataCallback: Function = () => { }, params: any = {}) {
    this.httpDao = new HttpDAO(this.httpClient);
    if (Object.keys(params).length == 0)
      this.cmsvc.StartProgressBar();
    else
      params.isBool = true;
    this.httpDao.postData(apiString, dataInput).pipe(
      map(data => {
        mapDataCallback(data);
        return data
      }),
      catchError(
        err => {
          if ((params.isBool == true))
            params.isBool = false;
          else
            this.cmsvc.StopProgressBar();
          throw err;
        }
      )
    ).subscribe(data => {
      subscribeDataCallback(data);
      if ((params.isBool == true))
        params.isBool = false;
      else
        this.cmsvc.StopProgressBar();
    });
  }
}
