<h3 class="font-weight-normal pb-2 text-primary text-center text-lg-left">
    Manually Upload Raw Visit Data
</h3>
<div>
    <!-- Main Mat Spinner -->
    <div class="loading-shade-global" *ngIf="isLoading">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <div class="container-fluid">
        <div class="row">
            Uploaded files should be in a CSV format and data should be tabled
            according to the appropriate upload template. The template can be
            downloaded by clicking this link:
        </div>
    </div>
    <br />
    <div class="container-fluid">
        <div class="row">
            <a href="../../../../assets/sample_csv/Sample_Raw_Visit.csv"><u>Download a Manual Upload of Raw Visits CSV
                    template</u></a>
        </div>
    </div>
    <br />
    <br />
</div>

<div class="container-fluid">
    <div class="row">
        <!-- This will open file explorer for user to select CSV file. -->

        <app-fp-file-upload-r2 filterFile=".csv" fileCategory="uploadRawVisits" [buttonlabel]="'Click to Select File'"
            (UploadCompleted)="AddFile_UploadCompleted($event)" [columnParams]="colParameter"
            [requiresCheck]="checkNecessary" [showProgressBar]="true"
            [buttonStyle]="{'height': '3em', 'width': '18em'}">
        </app-fp-file-upload-r2>

        <!-- This will allow user to check the CSV file uploaded.  -->
        <!-- <button id="file_integrity_btn"  type="hidden" > -->
        <button class="btn btn-primary text-light" (click)="CheckRawVisitsRowsAffected($event)"
            [disabled]="checkFileBtn" style="width: 18em; height: 3em; margin-left: 2em">
            <i></i>CHECK FILE INTEGRITY
        </button>
        <app-describe-state-machine [MachineType]="StepFunctions.CheckRawVisits" #checkRawDesc
            (SuccessReturned)="handleIntegrityCheckReturn($event)">
        </app-describe-state-machine>
    </div>
</div>

<div style="margin-top: 1em">
    <label class="text-primary" style="width: 20em; height: 2em"><b>Raw Visits File Name</b></label>

    <!-- This is placeholder for selected filename. -->
    <label class="p-3 border-default rounded-10 border-2"
        style="max-width: 30em; word-wrap: break-word;">{{ documentName }}</label>
</div>

<div id="ErrorTable" class="tablecontainer border-default rounded-10 border-2" style="width: 38em">
    <table frame="void" rules="rows">
        <tr>
            <th>Error Type</th>
            <th>Rows Affected</th>
            <th>Include?</th>
            <th>Download CSV</th>
        </tr>
        <tr>
            <td style="text-align: left">Member Non-existent</td>
            <td>
                {{ RowsAffectedMemberNotExistCount }}
            </td>
            <td>
                <input type="checkbox" (change)="includeErrors($event)" id="chkMemberNotExist"
                    [style.display]="hideChkMemberNotExist ? 'none' : 'inline-block'" name="chkMemberNotExist" />
            </td>
            <td>
                <input type="image" src="../../../../assets/images/download_icon_manually_upload.png" [style.display]="
                    downloadMemberNotExistBtn ? 'none' : 'inline-block'
                  " (click)="
                    DownloadErrorsCSV($event)
                  " name="downloadMemberNotExist" />
            </td>
        </tr>
        <tr>
            <td style="text-align: left">Member Status Inactive/Invalid/Banned</td>
            <td>
                {{ RowsAffectedMemberStatusInvalidCount }}
            </td>
            <td>
                <input type="checkbox" (change)="includeErrors($event)" id="chkMemberInvalidStatus"
                    [style.display]="hideChkMemberInvalidStatus ? 'none' : 'inline-block'"
                    name="chkMemberInvalidStatus" />
            </td>

            <td>
                <input type="image" src="../../../../assets/images/download_icon_manually_upload.png" [style.display]="
                    downloadMemberInvalidStatusBtn ? 'none' : 'inline-block'
                  " (click)="
                    DownloadErrorsCSV($event)
                  " name="downloadMemberInvalidStatus" />
            </td>
        </tr>
        <tr>
            <td style="text-align: left">Member Ineligible</td>
            <td>
                {{ RowsAffectedMemberIneligibleCount }}
            </td>
            <td>
                <input type="checkbox" (change)="includeErrors($event)" id="chkMemberIneligible"
                    [style.display]="hideChkMemberIneligible ? 'none' : 'inline-block'" name="chkMemberIneligible" />
            </td>

            <td>
                <input type="image" src="../../../../assets/images/download_icon_manually_upload.png" [style.display]="
                    downloadMemberIneligibleBtn ? 'none' : 'inline-block'
                  " (click)="
                    DownloadErrorsCSV($event)
                  " name="downloadMemberIneligible" />
            </td>
        </tr>
        <tr>
            <td style="text-align: left">Facility/Service Non-existent</td>
            <td>
                {{ RowsAffectedFacilityServiceNotExistCount }}
            </td>
            <td></td>
            <td>
                <input type="image" src="../../../../assets/images/download_icon_manually_upload.png" (click)="
                    DownloadErrorsCSV(
                      $event
                    )
                  " [style.display]="
                    downloadFacilityServiceNonExistentBtn
                      ? 'none'
                      : 'inline-block'
                  " name="downloadFacilityServiceNonExistent" />
            </td>
        </tr>
        <tr>
            <td style="text-align: left">Facility/Service Inactive</td>
            <td>
                {{ RowsAffectedFacilityServiceInactiveCount }}
            </td>
            <td>
                <input type="checkbox" (change)="includeErrors($event)" id="chkFacilityServiceInactive"
                    [style.display]="hideChkFacilityServiceInactive ? 'none' : 'inline-block'"
                    name="chkFacilityServiceInactive" />
            </td>

            <td>
                <input type="image" src="../../../../assets/images/download_icon_manually_upload.png" (click)="
                    DownloadErrorsCSV(
                      $event
                    )
                  " [style.display]="
                    downloadFacilityServiceInactiveBtn ? 'none' : 'inline-block'
                  " name="downloadFacilityServiceInactive" />
            </td>
        </tr>

        <tr>
            <td style="text-align: left"><strong>All Erroneous Rows</strong></td>
            <td>
                {{ InvalidRows }}
            </td>
            <td>
                <input type="checkbox" (change)="includeAllErrors($event)" name="chkAllErrors"
                    [style.display]="hideChkAllErrors ? 'none' : 'inline-block'" />
            </td>

            <td>
                <input type="image" src="../../../../assets/images/download_icon_manually_upload.png"
                    (click)="DownloadErrorsCSV($event)" [style.display]="
                    downloadAllErrorsBtn ? 'none' : 'inline-block'
                  " name="downloadAllErrors" />
            </td>
        </tr>
        <br />
        <tr>
            <td style="text-align: left">
                <strong>Total Rows: </strong>{{ TotalRows }}<br />
                <strong>Total Valid/Invalid Rows: </strong>
                <font color="green">{{ ValidRows }}</font>/
                <font color="red">{{ InvalidRows }}</font>
            </td>
        </tr>
    </table>
</div>

<div class="container-fluid" style="margin-top: 2em">
    <div class="row">
        <button *ngIf="isFutureDate" class="px-5 py-2 btn btn-primary text-light" id="save_btn" style="width: 18em"
            [disabled]="saveBtn" (click)="CheckFutureVisitDates($event)">
            Upload File
        </button>
        <button *ngIf="!isFutureDate" class="px-5 py-2 btn btn-primary text-light" id="save_btn" style="width: 18em"
            [disabled]="saveBtn" (click)="Save($event)">
            Upload File
        </button>
        <app-describe-state-machine [MachineType]="StepFunctions.SaveRawVisits" #saveRawDesc
            (SuccessReturned)="handleUploadReturn($event)">
        </app-describe-state-machine>
    </div>
</div>

<app-fp-progress-bar #progressBar></app-fp-progress-bar>