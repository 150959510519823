import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  AfterViewChecked,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PatternConstant } from "../../constant/patternconstant";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { ReCaptcha2Component } from "ngx-captcha";
import { UserRegisterComponent } from "../signup-component/user-register/user-register.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-common-dialog-new",
  templateUrl: "./common-dialog-new.component.html",
  styleUrls: ["./common-dialog-new.component.css"],
})
export class CommonDialogNewComponent implements OnInit, AfterViewChecked {
  style: number;
  title: string;
  message: string;
  information: SafeHtml;
  button: number;
  allow_outside_click: boolean;
  countdown: string;
  Pincode = "";
  PinRequired = false;
  IsPinSubmitDisabled = true;
  PinInvalid = false;
  PinVerified = false;
  ConfPasswordNotMatch = false;
  PinSignUpPage: UserRegisterComponent;
  passwordFormGroup: UntypedFormGroup;
  btnSubmit = false;
  buttonlabel = "OK";

  // Author: Da Do - ReCaptcha - Plugin
  @ViewChild("captchaElem") captchaElem: ReCaptcha2Component;

  isCaptchaResponse = false;
  public siteKey = environment.CaptchaSiteKey;
  public ReCaptchaFormGroup: UntypedFormGroup;
  btnResent = false;
  // End -

  constructor(
    private sanitized: DomSanitizer,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CommonDialogNewComponent>,
    private _formBuilder: UntypedFormBuilder,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.style = data.style || 0;
    this.title = data.title;
    this.message = data.message;
    this.information = this.sanitized.bypassSecurityTrustHtml(data.information);
    this.button = data.button;
    this.dialogRef.disableClose = !data.allow_outside_click || false;
    this.PinSignUpPage = data.PinSignUpPage;
    this.buttonlabel = data.buttonlabel;
  }

  ngOnInit() {
    this.CreatePasswordForm();
    this.CreateCaptchaForm();
  }

  ngAfterViewChecked() {}

  private CreatePasswordForm() {
    this.passwordFormGroup = this._formBuilder.group({
      txtPassword: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.CREATE_NEW_PASSWORD)],
      ],
      txtPasswordConf: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.CREATE_NEW_PASSWORD)],
      ],
    });
  }

  public ResendPinClick() {
    this.btnResent = true;
    this.PinSignUpPage.ResendPinClick();
  }

  public SubmitPinClick(
    pincode,
    popup: MatDialogRef<CommonDialogNewComponent>
  ) {
    this.PinSignUpPage.SubmitPinClick(pincode, popup);
  }

  public SubmitPasswordClick(
    password,
    popup: MatDialogRef<CommonDialogNewComponent>
  ) {
     this.PinSignUpPage.CreateMemberClick(password, popup);
  }

  public CancelPinClick() {
    this.PinSignUpPage.CancelPinClick();
  }

  public CancelPasswordClick() {
    this.PinSignUpPage.CancelPasswordClick();
  }

  checkMatchPassword() {
    const password = this.passwordFormGroup.get("txtPassword").value;
    const cfpassword = this.passwordFormGroup.get("txtPasswordConf").value;
    if (password != cfpassword && !this.passwordFormGroup.invalid) {
      this.ConfPasswordNotMatch = true;
    } else {
      this.ConfPasswordNotMatch = false;
    }
  }

  private CreateCaptchaForm() {
    this.ReCaptchaFormGroup = this._formBuilder.group({
      rcpSignUpCaptcha: ["", Validators.required],
    });
  }

  public HandleCaptchaSuccess(captchaResponse: string): void {
    this.isCaptchaResponse = true;
  }

  private ReloadCaptcha() {
    if (this.captchaElem !== undefined) {
      this.captchaElem.reloadCaptcha();
      this.isCaptchaResponse = false;
      this.btnSubmit = false;
      this.btnResent = false;
    }
  }  

  public OnPinChange() {
    if (this.Pincode.trim() === "") {
      this.PinRequired = true;
      this.IsPinSubmitDisabled = true;
      this.PinInvalid = false;
    }
    else{
      this.PinRequired = false;
      this.IsPinSubmitDisabled = false;
    }
  }

  btnOK_Click() {
    this.btnSubmit = true;
    if (this.button == 4 || this.button == 15) {
      if (this.Pincode.trim() == "") {
        this.PinRequired = true;
        this.IsPinSubmitDisabled = true;
      } else {
        this.PinRequired = false;
        this.IsPinSubmitDisabled = false;
        this.SubmitPinClick(this.Pincode, this.dialogRef);
      }
    } else if (this.button === 5) {
      if (!this.passwordFormGroup.invalid && !this.ConfPasswordNotMatch) {
        this.SubmitPasswordClick(
          this.passwordFormGroup.get("txtPassword").value,
          this.dialogRef
        );
      }
    } else {
      this.dialogRef.close({ result: DialogResult.Ok });
    }
  }
  btnCancel_Click() {
    if (this.button == 4 ) {
      this.CancelPinClick();
    }
    if (this.button == 5) {
      this.CancelPasswordClick();
    }
    this.dialogRef.close({ result: DialogResult.Cancel });
  }
  btnYes_Click() {
    this.dialogRef.close({ result: DialogResult.Yes });
  }
  btnNo_Click() {
    this.dialogRef.close({ result: DialogResult.No });
  }
  btnAccept_Click() {
    this.dialogRef.close({ result: DialogResult.Accept });
  }
  btnReject_Click() {
    this.dialogRef.close({ result: DialogResult.Reject });
  }
  btnContinue_Click() {
    this.dialogRef.close({ result: DialogResult.Continue });
  }
  getSpecialCharacters(): string {
    return "^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ \` + =";
  }
}
export abstract class DialogResult {
  public static Ok = "ok";
  public static Cancel = "cancel";
  public static Yes = "yes";
  public static No = "no";
  public static Accept = "accept";
  public static Reject = "reject";
  public static Continue = "continue";
  result: string;
}
