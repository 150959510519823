import  {jsPDF} from 'jspdf';
import { fp_logo } from "src/assets/images/logo.dataimage";
import { Injectable } from "@angular/core";
import { MemberInvoiceData, TransactionViewModel } from "@fp/models/member-tax-invoice.model";
import OpenSansRegular from 'src/assets/data/OpenSans-Regular-normal';
import OpenSansItalic from 'src/assets/data/OpenSans-Italic-italic';
import OpenSansBold from 'src/assets/data/OpenSans-Bold-bold';
import {CommonService} from "@fp/services";

@Injectable({
    providedIn: "root"
})
export class PdfMemberInvoiceReport {

    public doc = null;
    public userCountry=null;

    private lineHeight = 1.3;
    private FPBusinessName = 'Fitness Passport Pty Limited';
    private FPBusinessNameNZ = 'Fitness Passport NZ Limited';
    private FP_ID = 'ID HO627';
    private FP_PO = 'PO Box 491';
    private FPAddress = 'KELLYVILLE';
    private FPTownOfOps = 'NSW 2155';
    private FPDisplayedEmail_header = "info@fitnesspassport.com.au";
    private FPDisplayedEmail_footer = "info@fitnesspassport.com.au";
    private FPLinkedEmail_header = "info@fitnesspassport.com.au";
    private FPLinkedEmail_footer = "mailto:info@fitnesspassport.com.au";
    private FPABN = "ABN: 50 118 426 029";
    private FPGSTNumber="GST Number: 131-669-704";
    private FPContactLink_Display = "https://fitnesspassport.freshdesk.com/";
    private FPContactLink_URL = "https://fitnesspassport.freshdesk.com/";
    private AnyQuestions = "For any questions, please contact our team at ";

    constructor(
        public commonservice: CommonService) { 
    }


    /**
     * Saves a Member Invoice to the User's Downloads
     * @param filename The name of the file to be saved
     * @param invoiceData The Invoice Data to use to format the file
     * @param transactions The list of transactions to save
     */
    public SavePdfToFile(filename: string, invoiceData: MemberInvoiceData, transactions: TransactionViewModel[]) {


        if (this.doc == null) {
            this.doc = new jsPDF('p', 'em', 'a4');
        }
        this.generateMemberInvoice(invoiceData, transactions);
        this.doc.save(filename);
        this.doc = null;
    }

    public ExportBlob(): Blob {
        const blob: Blob = this.doc.output('blob');
        return blob;
    }

    /**
     * Generates a new Member Invoice
     * @param invoiceData The Member/Invoice Data set
     * @param transactions The array of Transactions to display
     */
    private generateMemberInvoice(invoiceData: MemberInvoiceData, transactions: TransactionViewModel[]) {

        //Check the user's country
        this.userCountry=this.commonservice.getUserCountry();

        /** If you need to make adjustments,
         * you can copy this function's contents into here: http://raw.githack.com/MrRio/jsPDF/master/#
         * to visualise them as you go!
         */

        // Sorry for the lack of comments, did this in a rush.

        if (transactions == undefined) {
            transactions = [];
        }

        // if (invoiceData.TotalAmountDue < 0) {
        //     invoiceData.TotalAmountDue = 0;
        //     invoiceData.Gst = 0;
        // }

        // Colours
        const fp_blue_r = 0;
        const fp_blue_g = 147;
        const fp_blue_b = 211;
        const white = 255;
        const black = 0;

        // Position Offsets
        const col_left_x = 7;
        const table_left_x = 8;
        const col_right_x = 27;
        const anchor_1 = 14;
        const anchor_2 = 23;
        const anchor_3 = 34;
        const table_money_col_x = 32;
        const table_trans_desc_col_x = 30;
        const table_header_box_width = 36;
        const table_width = 43;
        const table_start_y = 31.5;
        const last_period_height = 4 * this.lineHeight;

        // Font Shenanigans
        const header_font_size = 38;
        const small_font_size = 10;
        const med_font_size = 11;
        const large_font_size = 12;
        const max_transactions_page1 = 16;
        // Multipage Constants
        const max_trasactions_multipage = 37;
        const total_amount_due_top = 2 * this.lineHeight;
        const total_amount_due_bottom = 4.5 * this.lineHeight;
        const table_start_y_multipage = 6;
        const transaction_line_offset_multipage = table_start_y_multipage + (4 * this.lineHeight);

        // Border
        this.doc.setDrawColor(fp_blue_r, fp_blue_g, fp_blue_b);
        this.doc.rect(5, 5, 40, 60);

        // FP Logo
        this.doc.addImage(fp_logo, 'JPEG', col_left_x, 6, 9.138, 7, undefined, 'FAST');

        this.doc.addFileToVFS('OpenSans-Regular-normal.ttf', OpenSansRegular);
        this.doc.addFileToVFS('OpenSans-Italic-italic.ttf', OpenSansItalic);
        this.doc.addFileToVFS('OpenSans-Bold-bold.ttf', OpenSansBold);

        this.doc.addFont('OpenSans-Regular-normal.ttf', 'OpenSans', 'normal');
        this.doc.addFont('OpenSans-Italic-italic.ttf', 'OpenSans', 'italic');
        this.doc.addFont('OpenSans-Bold-bold.ttf', 'OpenSans', 'bold');

        this.doc.setFont('OpenSans');

        // Title
        this.doc.setFontSize(header_font_size);
        this.doc.setTextColor(fp_blue_r, fp_blue_g, fp_blue_b);
        this.doc.text('Tax Invoice', col_right_x, 10.5);

        this.doc.setFontSize(small_font_size);
        this.doc.setFont("OpenSans","italic");
        this.doc.setTextColor(black, black, black);

        // Fp Details Panel
        if(this.userCountry=="Australia"){
            this.doc.text(this.FPBusinessName, col_left_x, anchor_1);
            this.doc.text(this.FP_ID, col_left_x, anchor_1 + (1 * this.lineHeight));
            this.doc.text(this.FP_PO, col_left_x, anchor_1 + (2 * this.lineHeight));
            this.doc.text(this.FPAddress, col_left_x, anchor_1 + (3 * this.lineHeight));
            this.doc.text(this.FPTownOfOps, col_left_x, anchor_1 + (4 * this.lineHeight));
            this.doc.textWithLink(this.FPDisplayedEmail_header, col_left_x, anchor_1 + (5 * this.lineHeight), { url: this.FPLinkedEmail_header });
            this.doc.text(this.FPABN, col_left_x, anchor_1 + (6 * this.lineHeight));
        
        }else if(this.userCountry=="New Zealand"){
            this.doc.text(this.FPBusinessNameNZ, col_left_x, anchor_1);
            this.doc.textWithLink(this.FPDisplayedEmail_header, col_left_x, anchor_1 + (1 * this.lineHeight), { url: this.FPLinkedEmail_header });
            this.doc.text(this.FPGSTNumber, col_left_x, anchor_1 + (2 * this.lineHeight));
        }

        this.doc.setFontSize(med_font_size);
        this.doc.setFont("OpenSans","normal");

        // Date & Invoice Panel
        this.doc.text("Date: " + invoiceData.PeriodCloseDate.toLocaleDateString("en-AU", { year: 'numeric', month: 'long', day: 'numeric' }), col_right_x, anchor_1);
        this.doc.text("Invoice No. " + invoiceData.InvoiceNumber, col_right_x, anchor_1 + 1.5);
        // Sold To Panel
        this.doc.setFont("OpenSans","bold");
        this.doc.text('Sold To:', col_left_x, anchor_2);
        this.doc.setFont("OpenSans","normal");
        this.doc.text(invoiceData.PrimaryMemberName, col_left_x, anchor_2 + (1 * this.lineHeight));
        this.doc.text(invoiceData.EmployerName, col_left_x, anchor_2 + (2 * this.lineHeight));
        this.doc.text('Membership Number: ' + invoiceData.MembershipId, col_left_x, anchor_2 + (3 * this.lineHeight));
        this.doc.text("Member Number: " + invoiceData.MemberNumber, col_left_x, anchor_2 + (4 * this.lineHeight));

        // Account Summary Line
        this.doc.text("Account Summary (" +
            invoiceData.PeriodOpenDate.toLocaleDateString("en-AU", { year: 'numeric', month: 'numeric', day: 'numeric' }) + " - " +
            invoiceData.PeriodCloseDate.toLocaleDateString("en-AU", { year: 'numeric', month: 'numeric', day: 'numeric' }) + ")",
            col_left_x, anchor_2 + (5.5 * this.lineHeight)
        );
        this.doc.setLineWidth(0.05);
        this.doc.setDrawColor(0, 0, 0);
        this.doc.line(col_left_x, anchor_2 + (5.7 * this.lineHeight), 25.5, anchor_2 + (5.7 * this.lineHeight));

        // Table Borders
        this.doc.setDrawColor(black, black, black);
        this.doc.setFillColor(fp_blue_r, fp_blue_g, fp_blue_b);
        this.doc.rect(col_left_x, anchor_2 + (6.5 * this.lineHeight), table_header_box_width, 2, 'FD');
        this.doc.rect(col_left_x, anchor_2 + (10.5 * this.lineHeight), table_header_box_width, 2, 'FD');
        this.doc.setTextColor(white, white, white);
        this.doc.setFont("OpenSans","bold");
        this.doc.setFontSize(large_font_size);
        this.doc.text("Your Last Period Account", table_left_x, anchor_2 + (7.5 * this.lineHeight));
        this.doc.text("Your Current Period Account", table_left_x, anchor_2 + (11.5 * this.lineHeight));
        // Your Previous Account Section Vertical Lines
        this.doc.line(col_left_x, table_start_y, col_left_x, table_start_y + last_period_height);
        this.doc.line(table_width, table_start_y, table_width, table_start_y + last_period_height);
        // Your Current Account Section Vertical Lines
        if (transactions.length < max_transactions_page1) {
            this.doc.line(col_left_x, table_start_y + last_period_height, col_left_x, (anchor_3 + last_period_height + total_amount_due_top + (transactions.length * this.lineHeight)));
            this.doc.line(table_width, table_start_y + last_period_height, table_width, (anchor_3 + last_period_height + total_amount_due_top + (transactions.length * this.lineHeight)));
        }
        else {
            this.doc.line(col_left_x, table_start_y + last_period_height, col_left_x, (anchor_3 + last_period_height + total_amount_due_top + (max_transactions_page1 * this.lineHeight)));
            this.doc.line(table_width, table_start_y + last_period_height, table_width, (anchor_3 + last_period_height + total_amount_due_top + (max_transactions_page1 * this.lineHeight)));
        }

        // Table Text
        this.doc.setFontSize(med_font_size);
        this.doc.setFont("OpenSans","normal");
        this.doc.setTextColor(black, black, black);
        this.doc.text('Opening Balance', table_left_x, anchor_2 + (9 * this.lineHeight));
        // Check if opening balance is positive or negative
        if (invoiceData.OpeningBalance > 0) {
            this.doc.text("$" + invoiceData.OpeningBalance.toFixed(2) + " CR", table_money_col_x, anchor_2 + (9 * this.lineHeight));
        }
        else {
            this.doc.text("$" + Math.abs(invoiceData.OpeningBalance).toFixed(2), table_money_col_x, anchor_2 + (9 * this.lineHeight));
        }
        this.doc.text('Payment Received', table_left_x, anchor_2 + (10 * this.lineHeight));
        this.doc.text("$" + invoiceData.PaymentReceived.toFixed(2) + " CR", table_money_col_x, anchor_2 + (10 * this.lineHeight));
        this.doc.text('Balance Carried Forward', table_left_x, anchor_2 + (13 * this.lineHeight));
        // Check if balance carried forward is positive or negative
        if (invoiceData.BalanceCarriedForward > 0) {
            this.doc.text("$" + invoiceData.BalanceCarriedForward.toFixed(2) + " CR", table_money_col_x, anchor_2 + (13 * this.lineHeight));
        }
        else {
            this.doc.text("$" + Math.abs(invoiceData.BalanceCarriedForward).toFixed(2), table_money_col_x, anchor_2 + (13 * this.lineHeight));
        }
        // Transactions Header
        this.doc.setFont("OpenSans","bold");
        this.doc.text('Transactions', table_left_x, anchor_2 + (14 * this.lineHeight));
        this.doc.setFont("OpenSans","normal");

        var page_count = 1;
        var line_num = 0;
        // Print the First Page Transactions
        while (line_num < max_transactions_page1 && transactions.length > 0) {
            var this_trans = transactions.shift();
            this.doc.text(this_trans.Description, table_trans_desc_col_x, anchor_2 + (15 * this.lineHeight) + (line_num * this.lineHeight), null, null, 'right');
            if (this_trans.Credit) {
                this.doc.text("$" + Math.abs(this_trans.Charge).toFixed(2) + " CR", table_money_col_x, (anchor_2 + (15 * this.lineHeight)) + (line_num * this.lineHeight));
            }
            else {
                this.doc.text("$" + Math.abs(this_trans.Charge).toFixed(2), table_money_col_x, (anchor_2 + (15 * this.lineHeight)) + (line_num * this.lineHeight));
            }
            line_num++;
        }

        // If there's still transactions to print, ask for another page and draw all the headers again. Loop for as many pages as needed.
        line_num = transactions.length == 0 ? line_num : max_trasactions_multipage + 1;
        while (transactions.length > 0) {
            if (line_num > max_trasactions_multipage) {
                // Bottom Line on Multipage Table
                if (page_count > 1) {
                    // Bottom Line for Table
                    var multipage_end_line = transaction_line_offset_multipage + 1 + (max_trasactions_multipage * this.lineHeight);
                    this.doc.line(col_left_x, multipage_end_line, table_width, multipage_end_line);
                    // Side Lines for Table
                    this.doc.line(col_left_x, table_start_y_multipage, col_left_x, multipage_end_line);
                    this.doc.line(table_width, table_start_y_multipage, table_width, multipage_end_line);
                } else {

                    var page1_end_line = (anchor_3 + last_period_height + total_amount_due_top) + (max_transactions_page1 * this.lineHeight);
                    this.doc.line(col_left_x, page1_end_line, table_width, page1_end_line);
                }
                this.doc.addPage();
                page_count++;

                // Border
                this.doc.setDrawColor(fp_blue_r, fp_blue_g, fp_blue_b);
                this.doc.rect(5, 5, 40, 60);

                // Table Borders
                this.doc.setLineWidth(0.05);
                this.doc.setDrawColor(black, black, black);
                this.doc.setFillColor(fp_blue_r, fp_blue_g, fp_blue_b);
                this.doc.rect(col_left_x, table_start_y_multipage, table_header_box_width, 2, 'FD');
                this.doc.setTextColor(white, white, white);
                this.doc.setFont("OpenSans","bold");
                this.doc.setFontSize(large_font_size);
                this.doc.text("Your Current Period Account Cont.", table_left_x, table_start_y_multipage + 1.3);
                this.doc.setTextColor(black, black, black);

                this.doc.setFont("OpenSans","bold");
                this.doc.text('Transactions', table_left_x, transaction_line_offset_multipage - 2);
                this.doc.setFont("OpenSans","normal");
                line_num = 0;
            }
            // Dequeue the top transaction and print it
            this_trans = transactions.shift();

            this.doc.text(this_trans.Description, table_trans_desc_col_x, transaction_line_offset_multipage + (line_num * this.lineHeight), null, null, 'right');
            if (this_trans.Credit) {
                this.doc.text("$" + Math.abs(this_trans.Charge).toFixed(2) + " CR", table_money_col_x, transaction_line_offset_multipage + (line_num * this.lineHeight));
            }
            else {
                this.doc.text("$" + Math.abs(this_trans.Charge).toFixed(2), table_money_col_x, transaction_line_offset_multipage + (line_num * this.lineHeight));
            }
            line_num++;
        }

        // Print the "Total Due" box
        // Anchor points for Page 1 and other pages are different
        var page1_end_line = 0;
        var bottom_line_x1 = 0;
        var total_amount_due_line = 0;

        if (page_count == 1) {
            // Total Due Section Horz lines
            page1_end_line = (anchor_3 + last_period_height + total_amount_due_top) + (line_num * this.lineHeight);
            bottom_line_x1 = (anchor_3 + last_period_height + total_amount_due_bottom) + (line_num * this.lineHeight);
            total_amount_due_line = (anchor_3 + (col_left_x * this.lineHeight));
        } else {
            page1_end_line = transaction_line_offset_multipage + (line_num * this.lineHeight);
            bottom_line_x1 = transaction_line_offset_multipage + ((line_num + 2.5) * this.lineHeight);
            total_amount_due_line = transaction_line_offset_multipage;
            // Main Table Vert Lines
            this.doc.line(col_left_x, table_start_y_multipage, col_left_x, page1_end_line);
            this.doc.line(table_width, table_start_y_multipage, table_width, page1_end_line);
            line_num++;
        }
        // Total Due Section Horz lines
        this.doc.line(col_left_x, page1_end_line, table_width, page1_end_line);
        this.doc.line(col_left_x, bottom_line_x1, table_width, bottom_line_x1);
        // Total Due Section Vert Lines
        this.doc.line(col_left_x, page1_end_line, col_left_x, bottom_line_x1);
        this.doc.line(table_width, page1_end_line, table_width, bottom_line_x1);

        if (invoiceData.Gst != undefined) {
            this.doc.text("Total Amount Due (inc. GST)", table_left_x, total_amount_due_line + (line_num * this.lineHeight));
            this.doc.text("$" + Math.abs(invoiceData.TotalAmountDue).toFixed(2), table_money_col_x, total_amount_due_line + (line_num * this.lineHeight));
            line_num++;
            this.doc.text("GST", table_left_x, total_amount_due_line + (line_num * this.lineHeight));
            this.doc.text("$" + Math.abs(invoiceData.Gst).toFixed(2), table_money_col_x, total_amount_due_line + (line_num * this.lineHeight));
        } else {
            this.doc.text("Total Amount Due", table_left_x, total_amount_due_line + (line_num * this.lineHeight));
            this.doc.text("$" + Math.abs(invoiceData.TotalAmountDue).toFixed(2), table_money_col_x, total_amount_due_line + (line_num * this.lineHeight));
        }
        // Print the "Any Questions" Panel
        this.doc.setFontSize(small_font_size);
        this.doc.setFont("OpenSans","italic");
        this.doc.text(this.AnyQuestions, table_left_x, 67);
        this.doc.textWithLink(this.FPContactLink_Display, table_left_x + this.AnyQuestions.length * .38, 67, { url: this.FPContactLink_URL });
        this.doc.textWithLink("or email us at " + this.FPDisplayedEmail_footer, table_left_x, 68, { url: this.FPLinkedEmail_footer });
    }
}