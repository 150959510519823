import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { NavbarComponent } from '../navbar-component/navbar.component';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewChecked {
    public title = 'Fitness Administration';
    public isCollapsed = false;
    heightHeader: number;
    isLogin = false;
    currentLogin = false;
    opened: boolean = false;
    mobile: boolean = false;

    @ViewChild('fpheader', { static: true }) fpheader: ElementRef;
    @ViewChild('navbarmd', { static: true }) navbarmd: NavbarComponent;

    constructor(
        public commonservice: CommonService,
        private cdRef: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.SetUILoginSuccess();
    }

    get showSideNavToggle(): boolean {
        return !this.commonservice.App.isHideSideNav
    }

    public SetUILoginSuccess() {
        this.commonservice.Header = this;
        this.commonservice.Navbarmd = this.navbarmd;

        if (this.commonservice.App.mobileQuery.matches) {
            this.isCollapsed = true;
        }

        const user = this.commonservice.GetUser();
        if (user == 'null' || user == undefined || user == null) {
            this.isLogin = false;
            if (this.commonservice.Navbar) {
                this.commonservice.Navbar.isLoginDisplay = true;
                this.commonservice.Navbar.isLoginSuccessDisplay = false;
            }
            if (this.commonservice.Navbarmd) {
                this.commonservice.Navbarmd.isLoginDisplay = true;
                this.commonservice.Navbarmd.isLoginSuccessDisplay = false;
            }
            if (this.commonservice.Header) {
                this.commonservice.Header.isLogin = false;
            }
        } else {
            if (this.commonservice.Navbar) {
                this.commonservice.Navbar.isLoginDisplay = false;
                this.commonservice.Navbar.isLoginSuccessDisplay = true;
            }
            if (this.commonservice.Navbarmd) {
                this.commonservice.Navbarmd.isLoginDisplay = false;
                this.commonservice.Navbarmd.isLoginSuccessDisplay = true;
            }
            if (this.commonservice.Header) {
                this.commonservice.Header.isLogin = true;
            }
        }
    }

    ngAfterViewChecked() {

        if (this.commonservice.App.mobileQuery.matches) {
            this.heightHeader = this.fpheader.nativeElement.clientHeight;
            this.isCollapsed = true;
        } else {
            this.isCollapsed = false;
        }
        this.opened = this.commonservice.App.opened;
        this.cdRef.detectChanges();
    }
    public toggleSidenav() {
        console.log({ App: this.commonservice.App })
        this.commonservice.App.toggleSidenav();
    }
    public toggleSearch() {

        setTimeout(() => {
            this.commonservice.App.heightHeader = this.fpheader.nativeElement.clientHeight;
        }, 500);

    }
}
