<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Add/Edit User</h4>
<div class="mx-auto tabProgress w-75 d-none d-lg-flex mt-3">
    <div class="flex-fill text-center">
        <div class='card-icon rounded-circle mx-auto btn'
            [ngClass]="(stepper.selectedIndex == 0 || userDetailsFormGroup.valid)? 'bg-primary': null"
            (click)="ChangeTab(0);">
            <i [ngClass]="userDetailsFormGroup.valid && stepper.selectedIndex != 0?'fas fa-check text-light':''"></i>
        </div>
        <p class="card-text mb-3">User Details</p>
    </div>
    <div class="flex-fill text-center">
        <div class='card-icon rounded-circle mx-auto btn'
            [ngClass]="(stepper.selectedIndex == 1 || rolesFormGroup.valid)? 'bg-primary': null"
            (click)="ChangeTab(0);">
            <i [ngClass]="rolesFormGroup.valid && stepper.selectedIndex != 1 ?'fas fa-check text-light':''"></i>
        </div>
        <p class="card-text mb-3">Roles</p>
    </div>
    <div class="flex-fill text-center">
        <div class='card-icon rounded-circle mx-auto btn' [ngClass]="stepper.selectedIndex == 2 ? 'bg-primary': null"
            (click)="ChangeTab(0);">
            <i [ngClass]="stepper.selectedIndex != 2 ? 'fas fa-check text-light':''"></i>
        </div>
        <p class="card-text mb-3">Account</p>
    </div>
</div>
<div class="tabFPTheme mb-5" #stepperWrapperRef [style.height]="tabHeight">
    <mat-vertical-stepper [linear]="setLinear()" #stepper (selectionChange)="stepClick($event)">
        <mat-step [stepControl]="userDetailsFormGroup" #stepper1>
            <form [formGroup]="userDetailsFormGroup" id="userroletb0">
                <ng-template matStepLabel><i class="fas fa-check icon-fp d-lg-none"></i>
                    <span>User Details</span>
                </ng-template>
                <ngb-accordion [destroyOnHide]="false" activeIds="panelUserDetail, panelContact">
                    <ngb-panel id="panelUserDetail">
                        <ng-template ngbPanelTitle>
                            <i [ngClass]="ValidateUserDetailsSection()==true?'fas fa-check icon-fp':'icon-fp'"></i>
                            User Details
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-select [name]="'Title'" [mandatory]="false" [control]="control('slcTitle')"
                                [data]="titles" formControlName="slcTitle"></app-select>

                            <app-textbox [maxlength]='50' [name]="'First Name'" [mandatory]="true"
                                [control]="control('txtFirstName')" [flagValidation]="btnNextClicked"
                                formControlName="txtFirstName"></app-textbox>

                            <app-textbox [maxlength]='50' [name]="'Middle Name'" [mandatory]="false"
                                [control]="control('txtMiddleName')" formControlName="txtMiddleName"></app-textbox>

                            <app-textbox [maxlength]='50' [name]="'Last Name'" [mandatory]="true"
                                [control]="control('txtLastName')" [flagValidation]="btnNextClicked"
                                formControlName="txtLastName"></app-textbox>

                            <app-textbox [maxlength]='50' [name]="'Preferred Name'" [mandatory]="false"
                                [control]="control('txtPreferredName')" formControlName="txtPreferredName">
                            </app-textbox>

                            <!--this Date of Birth field isn't required for Admin and Super Admin.-->
                            <div class="form-group row">
                                <label for="dtpDateOfBirth" class="col-md-3 col-lg-2 col-form-label">Date of Birth
                                </label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="input-group" [ngClass]="userDetailsFormGroup.get('dtpDateOfBirth').value != '' && userDetailsFormGroup.get('dtpDateOfBirth').invalid
                                        && (userDetailsFormGroup.get('dtpDateOfBirth').dirty ||
                                        userDetailsFormGroup.get('dtpDateOfBirth').touched)?'is-invalid':''"
                                            [class.focused]="parentFocus==1">
                                            <input placeholder="DD-MM-YYYY" name="dp" ngbDatepicker #d="ngbDatepicker"
                                                formControlName="dtpDateOfBirth" class="form-control date-picker"
                                                [maxDate]="presentTime" [minDate]="minDate" (blur)="onBlurParent(0)"
                                                (focus)="onFocusParent(1)">
                                            <div class="input-group-append">
                                                <button class="btn bg-white" (click)="d.toggle()" type="button">
                                                    <i class="far fa-calendar-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <small *ngIf="userDetailsFormGroup.get('dtpDateOfBirth').value != '' && userDetailsFormGroup.get('dtpDateOfBirth').invalid
                                            && (userDetailsFormGroup.get('dtpDateOfBirth').dirty
                                            || userDetailsFormGroup.get('dtpDateOfBirth').touched)"
                                            class="invalid-feedback d-block">Date
                                            of Birth is invalid</small>
                                    </div>
                                </div>
                            </div>
                            <app-select [name]="'Gender'" [mandatory]="false" [control]="control('slcGender')"
                                [data]="genders" formControlName="slcGender"></app-select>


                            <app-select [name]="'Status'" [mandatory]="true" [control]="control('slcStatus')"
                                [data]="statuses" formControlName="slcStatus" [flagValidation]="btnNextClicked">
                            </app-select>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="panelContact">
                        <ng-template ngbPanelTitle>
                            Contact Details
                            <i [ngClass]="ValidateContactSection()==true?'fas fa-check icon-fp':'icon-fp'"></i>
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col-md-11 col-lg-7">
                                    <ngb-alert type="secondary" [dismissible]="false">
                                        We encourage you to provide a mobile number to
                                        allow your user to reset their own passwords
                                    </ngb-alert>
                                </div>
                            </div>

                            <app-mobile-number-country [mandatory]="false"
                                [control]="userDetailsFormGroup.get('txtMobileNumber')"
                                formControlName="txtMobileNumber" [clsLabel]="'col-md-3 col-form-label col-lg-2'"
                                [clsGroup]="'col-md-9 col-lg-5'" [label]="'Mobile Number'" [checkExist]="true">
                            </app-mobile-number-country>

                            <div class="form-group row">
                                <label for="txtEmailAddress" class="col-md-3 col-lg-2 col-form-label">Email Address<span
                                        class="text-danger">*</span>
                                </label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="input-group">
                                            <input type="text" maxlength="100"
                                                [ngClass]="(userDetailsFormGroup.get('txtEmailAddress').invalid && (userDetailsFormGroup.get('txtEmailAddress').dirty || userDetailsFormGroup.get('txtEmailAddress').touched || btnNextClicked))?'form-control is-invalid':'form-control'"
                                                id="txtEmailAddress" formControlName="txtEmailAddress"
                                                (blur)="checkExistEmail()">
                                            <mat-spinner [diameter]="14" *ngIf="mailSpinner"></mat-spinner>
                                        </div>
                                        <div *ngIf="userDetailsFormGroup.get('txtEmailAddress').invalid
                                            && (userDetailsFormGroup.get('txtEmailAddress').dirty
                                            || userDetailsFormGroup.get('txtEmailAddress').touched
                                            || btnNextClicked)">
                                            <small *ngIf="userDetailsFormGroup.get('txtEmailAddress').errors.required"
                                                class="invalid-feedback d-block">Email Address is required</small>
                                            <small *ngIf="userDetailsFormGroup.get('txtEmailAddress').errors.pattern"
                                                class="invalid-feedback d-block">Email Address is invalid</small>
                                            <small
                                                *ngIf="userDetailsFormGroup.get('txtEmailAddress').errors['duplicated']"
                                                class="invalid-feedback d-block">The email address already
                                                exists</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                    <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" matStepperNext
                        (click)="btnNextRoles_Click()">
                        <i class="fas fa-arrow-circle-right mr-2"></i>
                        Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step [stepControl]="rolesFormGroup">
            <form [formGroup]="rolesFormGroup" #stepper2 id="userroletb1">
                <ng-template matStepLabel><i class="icon-fp d-lg-none"></i>
                    <span (click)="btnNextRoles_Click()">Roles</span>
                </ng-template>
                <ngb-accordion activeIds="panelRoles">
                    <ngb-panel id="panelRoles">
                        <ng-template ngbPanelTitle>
                            Roles
                            <i [ngClass]="this.rolesFormGroup.invalid==false?'fas fa-check icon-fp':'icon-fp'"></i>
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="form-group row">
                                <label for="slcRole" class="col-md-3 col-lg-2 col-form-label">Role<span
                                        class="text-danger">*</span>
                                </label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select [ngClass]="(roleTypesSelected.length == 0
                                            && (rolesFormGroup.get('slcRole').dirty ||
                                            rolesFormGroup.get('slcRole').touched
                                            || btnNextClickedRoles))?'form-control is-invalid':'form-control'"
                                            id="slcRole" formControlName="slcRole" (ngModelChange)="selectRole($event)">
                                            <option [ngValue]="0">Please select a value</option>
                                            <option *ngFor="let role of roles" [ngValue]="role.Id"
                                                [disabled]="setRoleDisabled(role)">{{
                                                role.Name }}</option>
                                        </select>
                                        <div *ngIf="roleTypesSelected.length == 0
                                            && (rolesFormGroup.get('slcRole').dirty
                                            || rolesFormGroup.get('slcRole').touched
                                            || btnNextClickedRoles)">
                                            <small *ngIf="roleTypesSelected == ''"
                                                class="invalid-feedback d-block">Roles
                                                is required</small>
                                        </div>
                                        <div class="mt-2 slctSelectedRoles w-100 border-2 rounded-10 border-default text-default"
                                            *ngIf="roleTypesSelected.length > 0">
                                            <div class="d-flex border-default border-bottom py-1 align-content-center"
                                                *ngFor="let rt of roleTypesSelected">
                                                <span class="text-primary d-flex align-items-center"
                                                    (click)="unsetRoleSelected(rt)">
                                                    <i class="fas fa-minus-circle fa-2x"></i>
                                                </span>
                                                <p class="d-flex align-items-center m-0 ml-2">{{ rt.Name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="employer-facilitator" *ngIf="showRoleDetails('facilitator')">
                                <div class="form-group row">
                                    <label for="txtAddEmployer" class="col-md-3 col-lg-2 col-form-label">Add
                                        Employer<span class="text-danger">*</span>
                                    </label>
                                    <div class="col-md-8 col-lg-5">
                                        <div class="d-flex flex-column w-100">
                                            <div [ngClass]="(rolesFormGroup.get('FacilitatorsId').invalid && (rolesFormGroup.get('FacilitatorsId').dirty || rolesFormGroup.get('FacilitatorsId').touched
                                            || btnNextClickedRoles))?'input-group is-invalid':'input-group'">
                                                <input class="form-control" type="text"
                                                    placeholder="Search employer by employer name" id="txtAddEmployer"
                                                    formControlName="addFacilitator" [matAutocomplete]="auto">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">
                                                        <i class="fas fa-search text-primary"
                                                            *ngIf="!facilitatorSpinner.isBool"></i>
                                                        <mat-spinner *ngIf="facilitatorSpinner.isBool" [diameter]="14">
                                                        </mat-spinner>
                                                    </span>
                                                </div>
                                            </div>
                                            <div *ngIf="rolesFormGroup.get('FacilitatorsId').invalid
                                            && (rolesFormGroup.get('FacilitatorsId').dirty ||
                                            rolesFormGroup.get('FacilitatorsId').touched
                                            || btnNextClickedRoles)">
                                                <small class="invalid-feedback d-block">Employer
                                                    is required</small>
                                            </div>

                                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith">
                                                <mat-option *ngFor="let f of searchFacilitators" [value]="">
                                                    <p class="m-0 d-flex align-items-center" title={{f.Name}}
                                                        style="text-overflow: ellipsis; white-space: nowrap;overflow: hidden;"
                                                        (click)="setFacilitatorsSelected(f);">
                                                        {{ f.Name }} ({{f.Code }})</p>
                                                    <span class="text-primary d-flex align-items-center"
                                                        (click)="setFacilitatorsSelected(f)">
                                                        <i class="fas fa-plus-circle fa-2x"></i>
                                                    </span>
                                                </mat-option>
                                            </mat-autocomplete>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row popover-error" *ngIf="facilitatorsSelected.length > 0">
                                    <label for="txtSearch" class="col-md-3 col-lg-2 col-form-label">Select Primary
                                        Facilitator</label>
                                    <div class="col-md-8 col-lg-5">
                                        <div class="d-flex flex-column w-100">
                                            <div
                                                class="slctRoles height1 fpscrolly border-2 rounded-10 border-default mt-3">
                                                <div class="d-flex justify-content-between border-default border-bottom py-1 align-content-center"
                                                    *ngFor="let f of facilitatorsSelected" [ngbPopover]="popContent"
                                                    placement="right" triggers="manual" #popover="ngbPopover">
                                                    <div class="d-flex align-items-center">
                                                        <div class="custom-control custom-checkbox pt-0 m-0">
                                                            <input type="checkbox" class="custom-control-input"
                                                                (click)="setPrimaryFacilitator(popover,f,$event)">
                                                            <label class="custom-control-label">{{ f.Name }}
                                                                ({{f.Code}})</label>
                                                        </div>
                                                    </div>
                                                    <span class="text-primary d-flex align-items-center"
                                                        (click)="unsetFacilitatorsSelected(f)">
                                                        <i class="fas fa-minus-circle fa-2x"></i>
                                                        <p class="d-flex align-items-center m-0 ml-2">{{ f.Name }}</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-template #popContent>
                                        <div class="d-flex align-items-center">
                                            <i class="fa fa-exclamation-circle mr-2"></i>
                                            <span>ERROR - <br>Primary facilitator has already been selected for this
                                                employer.</span>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>

                            <div class="facility-staff-member form-group row" *ngIf="showRoleDetails('facility')">
                                <label for="txtFacility" class="col-md-3 col-lg-2 col-form-label">Facility<span
                                        class="text-danger">*</span>
                                </label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div [ngClass]="(rolesFormGroup.get('FacilitiesId').invalid
                                        && (rolesFormGroup.get('FacilitiesId').dirty ||
                                        rolesFormGroup.get('FacilitiesId').touched
                                        || btnNextClickedRoles))?'input-group is-invalid':'input-group'">
                                            <input class="form-control" type="text"
                                                placeholder="Search facility by facility name" id="txtFacility"
                                                formControlName="addFacility" [matAutocomplete]="auto1">
                                            <div class="input-group-append">
                                                <span class="input-group-text">
                                                    <i class="fas fa-search text-primary"
                                                        *ngIf="!facilitySpinner.isBool"></i>
                                                    <mat-spinner *ngIf="facilitySpinner.isBool" [diameter]="14">
                                                    </mat-spinner>
                                                </span>
                                            </div>
                                        </div>
                                        <div *ngIf="rolesFormGroup.get('FacilitiesId').invalid
                                        && (rolesFormGroup.get('FacilitiesId').dirty ||
                                        rolesFormGroup.get('FacilitiesId').touched
                                        || btnNextClickedRoles)">
                                            <small class="invalid-feedback d-block">Facility
                                                is required</small>
                                        </div>

                                        <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayWith">
                                            <mat-option *ngFor="let f of searchFacilities" [value]="">
                                                <p class="m-0 d-flex align-items-center" title={{f.Name}}
                                                    style="text-overflow: ellipsis; white-space: nowrap;overflow: hidden;"
                                                    (click)="setFacilitiesSelected(f);">
                                                    {{ f.Name }}</p>
                                                <span class="text-primary d-flex align-items-center"
                                                    (click)="setFacilitiesSelected(f)">
                                                    <i class="fas fa-plus-circle fa-2x"></i>
                                                </span>
                                            </mat-option>
                                        </mat-autocomplete>
                                        <div class="mt-2 slctSelectedRoles w-100 border-2 rounded-10 border-default text-default"
                                            *ngIf="facilitiesSelected.length > 0">
                                            <div class="d-flex border-default border-bottom py-1 align-content-center"
                                                *ngFor="let f of facilitiesSelected">
                                                <span class="text-primary d-flex align-items-center"
                                                    (click)="unsetFacilitiesSelected(f)">
                                                    <i class="fas fa-minus-circle fa-2x"></i>
                                                </span>
                                                <p class="d-flex align-items-center m-0 ml-2">{{ f.Name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                    <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" matStepperNext
                        (click)="btnNextAccount_Click()">
                        <i class="fas fa-arrow-circle-right mr-2"></i>
                        Next</button>
                </div>
            </form>
        </mat-step>
        <mat-step>
            <form #stepper3 id="userroletb2">
                <ng-template matStepLabel><i class="icon-fp d-lg-none"></i>
                    <span (click)="btnNextAccount_Click()">Account</span>
                </ng-template>
                <ngb-accordion activeIds="panelAccount">
                    <ngb-panel id="panelAccount">
                        <ng-template ngbPanelTitle>
                            Account
                            <i class="icon-fp fas fa-check"></i>
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="form-group row">
                                <label class="col-md-3 col-lg-2 col-form-label">Username</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="faux-text-input-field" [ngClass]="{'loading': loadingUsername}">
                                            <span>{{loadingUsername ? 'Loading...' : username}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-lg-2 col-form-label">Password</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="faux-text-input-field user-select-none">
                                            <span>***********</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-lg-2 col-form-label">Confirm
                                    Password
                                </label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="faux-text-input-field user-select-none">
                                            <span>***********</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                    <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="createUser()">
                        <i class="fas fa-check mr-2"></i>
                        Submit</button>
                </div>
            </form>
        </mat-step>
    </mat-vertical-stepper>
</div>