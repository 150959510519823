import { Component, OnInit } from '@angular/core';
import { AuthenticatorService } from '@FitnessPassport/ui-angular';
import { AuthenticationService } from '@fp/services';

@Component({
  selector: 'app-amplify-authenticator-account-created-message',
  templateUrl: './amplify-authenticator-account-created-message.component.html',
  styleUrls: ['./amplify-authenticator-account-created-message.component.css']
})
export class AmplifyAuthenticatorAccountCreatedMessageComponent implements OnInit {
  public isSignupCompleted: boolean = false;

  constructor(
    private authService: AuthenticationService,
    private authenticator: AuthenticatorService
  ) {
    this.isSignupCompleted = this.authService.isSignupCompleted;
  }

  ngOnInit(): void {
    this.authService.signupCompletionChanged.subscribe((isCompleted) => {
      this.isSignupCompleted = isCompleted;
      if (isCompleted) {
        this.authenticator.toSignIn();
      }
    });
  }
}
