<!-- <h3 class="font-weight-normal text-primary">Parent Groups</h3>
<div class="my-4">
</div>
<button class="px-5 py-2 btn btn-primary" type="button" (click)="createNew()">Create New</button> -->

<h3 class="font-weight-normal text-primary">Parent Groups</h3>
<div class="my-4">
    <div class="border-default rounded-10 border-2">
        <div class="tablecontainer">
            <table mat-table matSort [dataSource]="dataSource" class="rounded-10" (matSortChange)="sortChange($event)"
                        matSort matSortActive="name" matSortDirection="desc" matSortStart="desc" matSortDisableClear>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Group Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                </ng-container>

                <!-- Edit Column -->
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef> Edit </th>
                    <td mat-cell *matCellDef="let row">
                        <a class="px-2" [routerLink]="[routerConstant.NAVIGATOR_PARENT_GROUP_EDIT, row.id]">
                            <i class="fas fa-pen"></i>
                        </a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef> Delete </th>
                    <td mat-cell *matCellDef="let row">
                        <a class="px-2" (click)="pressDeleteButton(row.id)">
                            <i class="fas fa-trash"></i>
                        </a>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>
        </div>
        <mat-toolbar [hidden]="dataSource == null" class="bg-white">
            <mat-toolbar-row [hidden]="dataSource != null && dataSource.data.length == 0" class="p-0">
                <mat-paginator class="w-100" 
                    [length]="length" 
                    [pageSize]="pageSize" 
                    [pageSizeOptions]="pageSizeOptions"
                    (page)="paginatorChange($event)" showFirstLastButtons
                >
                </mat-paginator>
            </mat-toolbar-row>
            <mat-toolbar-row *ngIf="dataSource != null && dataSource.data.length == 0" class="text-muted d-block p-2 bg-light text-center">
                {{commonString.TABLE_NO_RECORD_FOUND}}
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
</div>
<button class="px-5 py-2 btn btn-primary" type="button" (click)="createNew()">Create New</button>