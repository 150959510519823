<div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner [diameter]=50></mat-spinner>
</div>
<div class="container-fluid">
    <div class="pt-3 pl-3 pl-lg-0 fpsideCont-px-3">
        <form [formGroup]="userFG">
            <div class="tabFPTheme mb-5">
                <ngb-accordion [destroyOnHide]="false" activeIds="panelUserDetail, panelContact, panelAccount">
                    <ngb-panel id="panelUserDetail">
                        <ng-template ngbPanelTitle>
                            <i [ngClass]="validateUserDetailsSection()?'fas fa-check icon-fp':'icon-fp'"></i>
                            User Details
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="form-group row">
                                <label for="slcTitle" class="col-md-3 col-lg-2 col-form-label">Title</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select
                                            [ngClass]="checkValidate('titleId') ? 'form-control is-invalid':'form-control'"
                                            id="slcTitle" formControlName="titleId">
                                            <option [ngValue]="undefined">Please select a value</option>
                                            <option *ngFor="let t of titles" [ngValue]="t.id">{{ t.name }}</option>
                                        </select>
                                        <small *ngIf="checkValidate('titleId')" class="invalid-feedback d-block">
                                            Title is required
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtFirstName" class="col-md-3 col-lg-2 col-form-label">First Name<span
                                        class="text-danger">*</span>
                                </label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" maxlength="50"
                                            [ngClass]="checkValidate('firstName') ? 'form-control is-invalid':'form-control'"
                                            id="txtFirstName" formControlName="firstName">
                                        <div *ngIf="checkValidate('firstName')">
                                            <small *ngIf="userFG.get('firstName').errors.required"
                                                class="invalid-feedback d-block">
                                                First Name is required
                                            </small>
                                            <small *ngIf="userFG.get('firstName').errors.pattern"
                                                 class="invalid-feedback d-block">This
                                                field does not accept special characters. Please try again.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtMiddleName" class="col-md-3 col-lg-2 col-form-label">Middle Name</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" class="form-control" id="txtMiddleName" maxlength="50"
                                            formControlName="middleName">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtLastName" class="col-md-3 col-lg-2 col-form-label">Last Name<span
                                        class="text-danger">*</span>
                                </label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" id="txtLastName" maxlength="50"
                                            [ngClass]="checkValidate('lastName') ? 'form-control is-invalid':'form-control'"
                                            formControlName="lastName">
                                        <div *ngIf="checkValidate('lastName')">
                                            <small *ngIf="userFG.get('lastName').errors.required"
                                                class="invalid-feedback d-block">
                                                Last Name is required
                                            </small>
                                            <small *ngIf="userFG.get('lastName').errors.pattern"
                                                 class="invalid-feedback d-block">This
                                                field does not accept special characters. Please try again.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="txtPreferredName" class="col-md-3 col-lg-2 col-form-label">Preferred
                                    Name</label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" class="form-control" id="txtPreferredName" maxlength="50"
                                            formControlName="preferredName">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-md-3 col-lg-2 col-form-label">Date of Birth<span
                                        class="text-danger">*</span>
                                </label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <div class="input-group"
                                            [ngClass]="checkValidate('birthday') ? 'form-control is-invalid':'form-control'"
                                            [class.focused]="parentFocus==1">
                                            <input placeholder="DD-MM-YYYY" name="dp" ngbDatepicker #d="ngbDatepicker"
                                                class="form-control date-picker" [maxDate]="presentTime"
                                                [minDate]="minTime" formControlName="birthday" (blur)="onBlurParent(0)"
                                                (focus)="onFocusParent(1)">
                                            <div class="input-group-append">
                                                <button class="btn bg-white" (click)="d.toggle()" type="button">
                                                    <i class="far fa-calendar-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <small
                                            *ngIf="checkValidate('birthday') && userFG.get('birthday').errors?.required; else DateOfBirthInvalid"
                                            class="invalid-feedback d-block">
                                            Date of Birth is required
                                        </small>
                                        <ng-template #DateOfBirthInvalid>
                                            <small *ngIf="checkValidate('birthday')" class="invalid-feedback d-block">
                                                Date of Birth is invalid
                                            </small>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="slcGender" class="col-md-3 col-lg-2 col-form-label">Gender<span
                                        class="text-danger">*</span>
                                </label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <select
                                            [ngClass]="checkValidate('genderId') ? 'form-control is-invalid':'form-control'"
                                            id="slcGender" formControlName="genderId">
                                            <option [ngValue]="undefined">Please select a value</option>
                                            <option *ngFor="let g of genders" [ngValue]="g.id">{{ g.name }}</option>
                                        </select>
                                        <small *ngIf="checkValidate('genderId')"  class="invalid-feedback d-block">
                                            Gender is required
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="panelContact">
                        <ng-template ngbPanelTitle>
                            Contact Details
                            <i [ngClass]="validateContactDetails()?'fas fa-check icon-fp':'icon-fp'"></i>
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-mobile-number-country [mandatory]="true" [submitted]="submitClick"
                                [control]="userFG.get('phone')" formControlName="phone"
                                [clsLabel]="'col-md-3 col-form-label col-lg-2'" [clsGroup]="'col-md-9 col-lg-5'"
                                [label]="'Mobile Number'" [checkExist]="true" [currentPhone]="editCurrentPhone">
                            </app-mobile-number-country>


                            <div class="form-group row">
                                <label for="txtEmailAddress" class="col-md-3 col-lg-2 col-form-label">Email Address<span
                                        class="text-danger">*</span>
                                </label>
                                <div class="col-md-8 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" class="form-control" id="txtEmailAddress"
                                            formControlName="email" maxlength="100" [ngClass]="(userFG.get('email').invalid
                                            && (userFG.get('email').dirty ||
                                            userFG.get('email').touched))?'form-control is-invalid':'form-control'"
                                            formControlName="email" (blur)="checkExistEmail()">
                                        <mat-spinner [diameter]="14" *ngIf="mailSpinter"></mat-spinner>
                                        <div *ngIf="userFG.get('email').invalid
                                            && (userFG.get('email').dirty
                                            || userFG.get('email').touched)">
                                            <small *ngIf="userFG.get('email').errors.required"
                                                 class="invalid-feedback d-block">Email Address is required</small>
                                            <small *ngIf="userFG.get('email').errors.pattern"
                                                class="invalid-feedback d-block">Email Address is invalid</small>
                                            <small *ngIf="userFG.get('email').errors['duplicated']"
                                                 class="invalid-feedback d-block">The email address already
                                                exists</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="panelAccount">
                        <ng-template ngbPanelTitle>
                            Account
                            <i class="fas fa-check icon-fp"></i>
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="form-group row">
                                <label for="txtUserName" class="col-md-3 col-lg-2 col-form-label">Username</label>
                                <div class="col-md-7 col-lg-5">
                                    <div class="d-flex flex-column w-100">
                                        <input type="text" class="form-control" id="txtUserName" readonly
                                            formControlName="username">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
                    <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="submit()">
                        <i class="fas fa-check mr-2"></i>
                        Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>