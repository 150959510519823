<div class="modal-header px-0 justify-content-start" id="modalheader">
    <h5 class="modal-title" id="exampleModalLabel">Add/Edit Product</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body py-3 noscrollx fpscrolly height100">
    <app-facility-product-details [form]="form.get('Details')" [data]="data" [flag]="flag"></app-facility-product-details>
    <div class="form-group row">
        <div class="col-md-8 col-lg-5 offset-sm-2">
            <button class="px-5 py-2 btn btn-primary" type="button" (click)="submit()">
                <i class="fas fa-check mr-2"></i> SUBMIT</button>
        </div>
    </div>
</div>