export class SystemParameters {
    ParameterID: number;
    ParameterName: string;
    DataType: string;
    Value: string;
}

export class UpdateSysPara {
    SystemParameters: UpdatedSystemParameter [];
}
export class UpdatedSystemParameter {
    ParameterName: string;
    DataType: string;
    Value: string;
}

export class SystemParametersFrontEnd {
    Field: string; // Parameter name
    Edit: boolean;
    Legend: number; // Parameter id
    Id: string; // Parameter name acronym
    Input: string; // value
    Type: string; // datatype
    Options: TFDropdown [];
}

export class TFDropdown {
    value: number;
    name: string;
}