<div class="select-package-wrapper  accordion-custom form-wrapper-new" *ngIf="EmpPageObj.ShowPricingPackages">

    <div class="container">
        <div class="row ">

            <div *ngFor="let memPackage of mbsplst; let i = index" class="col-md-4 "
                (click)="selectMembershipPackage(memPackage)">
                <div class="selected-package-detailsSection">
                    <div class="card package-details-wrapper pkg-{{memPackage.MembershipPackageType.Name | lowercase}}"
                        [class.opened]="memPackage.MembershipPackageId == selectedPackage?.MembershipPackageId">
                        <div class="togglebtn-package">
                            <div class="">{{memPackage.MembershipPackageType.Name}}</div>
                            <div class="package-price-wrapper">
                                {{Utils.convertPriceFormat(memPackage.SinglePrice)}} / week
                            </div>
                        </div>
                        <div class="card-body position-relative">
                            <hr class="d-md-none card-title-border" />
                            <h2 class="card-title text-center d-none d-md-block">
                                {{memPackage.MembershipPackageType.Name}}</h2>
                            <div class="package-ptice-info d-none d-md-block text-md-center">
                                <div class="package-price-wrapper">
                                    <strong class="package-price">{{Utils.convertPriceFormat(memPackage.SinglePrice)}}
                                    </strong>/ week
                                </div>
                                <div class="package-members">Single Member</div>
                            </div>
                            <div class="package-members-price">
                                <div class="package-family-price">
                                    <div class=" d-flex justify-content-between">
                                        <div class="pck-mbr-info">
                                            Family
                                        </div>
                                        <div class="pkg-mbr-pre_price">
                                            <strong
                                                class="pkg-mbr-price">{{Utils.convertPriceFormat(memPackage.FamilyPrice)}}
                                            </strong>/
                                            week
                                        </div>
                                    </div>
                                    <div class="pck-mbr-info small">
                                        (You, your partner and children under 18)
                                    </div>
                                </div>
                                <div class="package-family-price">
                                    <div class="d-flex justify-content-between">
                                        <div class="pck-mbr-info">
                                            Dependent
                                        </div>
                                        <div class="pkg-mbr-pre_price">
                                            <strong
                                                class="pkg-mbr-price">{{Utils.convertPriceFormat(memPackage.DependantPrice)}}
                                            </strong>/
                                            week
                                        </div>
                                    </div>
                                    <div class="pck-mbr-info small">
                                        (18-24 year olds)
                                    </div>
                                </div>
                            </div>
                            <div class="package-info">
                                <div class="pkg-info-item">
                                    <i class="fas fa-check"></i>
                                    Access to {{memPackage.TotalFacilityCount}} Facilities
                                </div>
                                <div class="pkg-info-item">
                                    {{memPackage.Description==='null' ? "" : memPackage.Description}}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-4">
                <div class="selected-package-detailsSection">
                    <div class="card package-details-wrapper pkg-silver" [class.opened]="openPackage == 2">
                        <div class="togglebtn-package" (click)="togglePackage(2)">
                            <div class="">Silver</div>
                            <div class="package-price-wrapper">
                                $19.50 / week
                            </div>
                        </div>
                        <div class="card-body position-relative">
                            <hr class="d-md-none card-title-border" />
                            <h2 class="card-title text-center d-none d-md-block">Silver</h2>
                            <div class="package-ptice-info d-none d-md-block text-md-center">
                                <div class="package-price-wrapper">
                                    <strong class="package-price">$14.50 </strong>/ week
                                </div>
                                <div class="package-members">Single Member</div>
                            </div>
                            <div class="package-members-price">
                                <div class="package-family-price d-flex justify-content-between">
                                    <div class="pck-mbr-info">
                                        Family
                                    </div>
                                    <div class="pkg-mbr-pre_price">
                                        <strong class="pkg-mbr-price">$24.50 </strong>/ week
                                    </div>
                                </div>
                                <div class="package-family-price d-flex justify-content-between">
                                    <div class="pck-mbr-info">
                                        Depedant
                                    </div>
                                    <div class="pkg-mbr-pre_price">
                                        <strong class="pkg-mbr-price">$14.50 </strong>/ week
                                    </div>
                                </div>
                            </div>
                            <div class="package-info">
                                <div class="pkg-info-item">
                                    <i class="fas fa-check"></i>
                                    Nationwide Facilities
                                </div>
                                <div class="pkg-info-item">
                                    <i class="fas fa-check"></i>
                                    Up to X dependants
                                </div>
                                <div class="pkg-info-item">
                                    <i class="fas fa-check"></i>
                                    Up to X dependants
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="selected-package-detailsSection">
                    <div class="card package-details-wrapper pkg-bronze" [class.opened]="openPackage == 3">
                        <div class="togglebtn-package" (click)="togglePackage(3)">
                            <div class="">Bronze</div>
                            <div class="package-price-wrapper">
                                $19.50 / week
                            </div>
                        </div>
                        <div class="card-body position-relative">
                            <hr class="d-md-none card-title-border" />
                            <h2 class="card-title text-center d-none d-md-block ">Bronze</h2>
                            <div class="package-ptice-info d-none d-md-block text-md-center">
                                <div class="package-price-wrapper">
                                    <strong class="package-price">$9.50 </strong>/ week
                                </div>
                                <div class="package-members">Single Member</div>
                            </div>
                            <div class="package-members-price">
                                <div class="package-family-price d-flex justify-content-between">
                                    <div class="pck-mbr-info">
                                        Family
                                    </div>
                                    <div class="pkg-mbr-pre_price">
                                        <strong class="pkg-mbr-price">$19.50 </strong>/ week
                                    </div>
                                </div>
                                <div class="package-family-price d-flex justify-content-between">
                                    <div class="pck-mbr-info">
                                        Depedant
                                    </div>
                                    <div class="pkg-mbr-pre_price">
                                        <strong class="pkg-mbr-price">$9.50 </strong>/ week
                                    </div>
                                </div>
                            </div>
                            <div class="package-info">
                                <div class="pkg-info-item">
                                    <i class="fas fa-check"></i>
                                    Nationwide Facilities
                                </div>
                                <div class="pkg-info-item">
                                    <i class="fas fa-check"></i>
                                    Up to X dependants
                                </div>
                                <div class="pkg-info-item">
                                    <i class="fas fa-check"></i>
                                    Up to X dependants
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>