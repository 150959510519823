import { CommonService } from './../../../services/common.service';
import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { RolePermissionService } from 'src/app/services/role-permission.service';

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styleUrls: ['./no-permission.component.css']
})
export class NoPermissionComponent implements OnInit, AfterViewChecked {

  constructor(public commonservice: CommonService,
    public rolepermission: RolePermissionService) { }

  ngOnInit() {
    this.commonservice.StopProgressBar();
  }

  ngAfterViewChecked() {
    this.commonservice.StopProgressBar();
  }

}
