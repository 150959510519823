export class PaymentInvoiceModel {
    public PaymentRequestId: number;
    public Amount: number;
    public InvoiceNumber: string;
    public PaymentCalculationMethod: string;
    public PaymentPeriodEnd: Date;
    public PaymentPeriodStart: Date;
    public PaymentPeriodEndDisplay: string;
    public PaymentPeriodStartDisplay: string;
    public PaymentRequestStatus: string;
    public PaymentRequestStatusDisplay: string;
    public PaymentRequestStatusId: number;
    public ServiceName: string;
    public ServiceId: number;
    public VisitCount: number;
    public FacilityInfo: FacilitySumaryModel;
    public CheckedForApproval: boolean = false;
    public InvoiceAttachment: string;
    public InvoiceFileName: string;
}

export class FacilitySumaryModel {
    public ABN: string;
    public Email: string;
    public Id: number;
    public Name: string;
    public PostCode: string;
    public State: string;
    public StreetAddress: string;
    public Suburb: string;
    public Code: string;
    public CompanyName: string;
}

export class InvoiceSearchRequest {
    public ServiceIds: number[];
    public FacilityStaffMemberUserId: number;
    public FromDate: Date;
    public ToDate: Date;
    public OrderBy: string;
    public OrderByAsc: boolean;
    public FacilityId: string = '';
}

export class ApprovePaymentRequestModel {
    public FacilityCode: string;
    public ServiceId: number;
    public PaymentRequestId: number;
    public InvoiceAttachment: string;
    public InvoiceFileName: string;
}

export class ListApprovePaymentRequestModel {
    public ApprovePaymentRequests: ApprovePaymentRequestModel[] = [];
    public ModifiedBy: string;
}