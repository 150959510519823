<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">
    Add New Facility
</h4>
<div class="py-4">
    <a class="d-block d-sm-inline-block py-2 btn btn-primary btn-fp"
        [routerLink]="[RouterConstant.NAVIGATOR_FACILITY_ADD]">Create New</a>
    <span class="ml-md-2 d-block d-sm-inline-block text-center text-sm-left">Create from new form</span>
</div>
<div class="pb-4" style="display: none;">
    <a class="d-block d-sm-inline-block py-2 btn btn-primary btn-fp"
        [routerLink]="[RouterConstant.NAVIGATOR_FACILITY_DRAFT]">Continue from saved draft</a>
    <span class="ml-md-2 d-block d-sm-inline-block text-center text-sm-left">Continue incomplete saved forms</span>
</div>