import { Injectable, Type } from "@angular/core";
import AWS, { Location, CognitoIdentityCredentials } from "aws-sdk";
import { promise } from "protractor";
import { environment } from "src/environments/environment";
import { CommonService } from "./common.service";
@Injectable({
  providedIn: "root",
})

export class LocationService {
  location: Location;
  credentials: CognitoIdentityCredentials;
  placesName: string;
  filterCountry: string[];

  constructor(private commonService: CommonService) {
    const identityPoolId = environment.LocationPoolId;
    const mapName = "AutoFillMAp";
    this.placesName = environment.LocationIndexName;
    AWS.config.region = identityPoolId.split(":")[0];

    console.log('Location Pool - ' + identityPoolId);

    this.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: identityPoolId,
    });

    this.location = new Location({
      credentials: this.credentials,
      region: AWS.config.region,
    });
  }

  async getLocation(query: string, country: string) {
    this.filterCountry = [country];

    let params = {
      IndexName: this.placesName,
      FilterCountries: this.filterCountry,
      MaxResults: 5,
      Text: query,
    };
    
    return this.location.searchPlaceIndexForText(params).promise();
  }

  async getLocationSuggestions(query: string, country: string) {
    this.filterCountry = [country];

    let params = {
      IndexName: this.placesName,
      FilterCountries: this.filterCountry,
      MaxResults: 5,
      Text: query,
    };
    
    return this.location.searchPlaceIndexForSuggestions(params).promise();
  }

  async getUserCountryByGeoLocation(longitude :  number, latitude  : number){
    let params = {
      IndexName: this.placesName,
      Position: [longitude, latitude],
      MaxResults: 1
    };

    return this.location.searchPlaceIndexForPosition(params).promise();
  }
  
}
