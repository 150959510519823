<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">
    Edit Debit Processing Period
</h4>



<div class="form-align">
    <!-- Main Mat Spinner -->
    <div class="loading-shade-global" *ngIf="isLoading || retrievingAbaFiles">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <br />
    <div class="form-group row">
        <label class="col-md-3 col-lg-2 col-form-label" class="text-primary form-header"><b>Payment Processing
                Date</b></label>
        <div name="processingDate" class="col-md-3 col-lg-2">
            <input class="form-control" type="text" [placeholder]="processingDate | dateFormatPipe" readonly />
        </div>
    </div>

    <br />

    <small>Note: Multiple files may be downloaded on this page. Please enable multiple pop up permissions to avoid any
        possible conflicts.
    </small>
    <div class="mt-lg-4">
        <div id="abaFilesTable">
            <div class="form-group">
                <div class="border-default rounded-10 border-2">
                    <div class="tablecontainer">
                        <table mat-table #abaSort="matSort" [dataSource]="dataSource" matSort matSortActive="FileName"
                            matSortDirection="desc" class="rounded-10">
                            <ng-container matColumnDef="FileName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    File Name
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.FileName }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef>Total</td>
                            </ng-container>

                            <ng-container matColumnDef="Rows">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Total Rows
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.Rows }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef> {{totalRows}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Amount">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    $ Amount
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.Amount | currency }}
                                </td>
                                <td mat-footer-cell *matFooterCellDef> {{totalAmount | currency }} </td>
                            </ng-container>

                            <ng-container matColumnDef="Download">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Download
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <input type="image"
                                        src="../../../../assets/images/download_icon_manually_upload.png"
                                        (click)="downloadABAFile(element.FileName)" />
                                </td>
                                <td mat-footer-cell *matFooterCellDef><input type="image"
                                        src="../../../../assets/images/download_icon_manually_upload.png"
                                        (click)="downloadABAFiles()" /> </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                            <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
                        </table>
                    </div>

                </div>
            </div>
            <mat-paginator #filepagi [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons>
            </mat-paginator>
        </div>
    </div>
    <br>
    <br>

    <div class="tabFPTheme tbFPPackagePUTheme mb-5">

        <ngb-accordion [destroyOnHide]="false" activeIds="pnlExcludeRows">
                <ngb-panel id="pnlExcludeRows">
                    <ng-template ngbPanelTitle>
                        <i class="icon-fp fas fa-check"></i>
                        Exclude Rows
                        <i class="fas fas fa-chevron-down"></i>
                        <i class="fas fas fa-chevron-up"></i>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <form [formGroup]="excludeForm">
                            <div class="form-align">
                                <div class="form-group row">
                                    <div class="form-group">
                                        <label for="txtrDescription" class="col-form-label"
                                            class="text-primary form-header"><b>File
                                                Name</b></label>
                                        <div style="min-width: 15em; margin-left: 1em">
                                            <div class="d-flex flex-column w-100">
                                                <select #ABADropdown id="fileNameOption" class="form-control"
                                                    formControlName="fileNameSelected" (change)="fileSelected()"
                                                    [(ngModel)]="valueFileDropDown">
                                                    <option value="" disabled selected>
                                                        {{fileSelectDropdownText}}</option>
                                                    <option *ngFor="let abaFileName of abaFileNames"
                                                        [ngValue]="abaFileName">
                                                        {{abaFileName}}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-align">
                                            <label for="txtrDescription" class="col-form-label"
                                                class="text-primary form-header"><b>From
                                                    Row:</b></label>
                                            <div style="min-width: 4em; margin-left: 1em">
                                                <input #ABAFromRow id="fromRow" class="form-control" type="text"
                                                    placeholder="" formControlName="fromRow"
                                                    [(ngModel)]="valueFromRow" />
                                            </div>
                                        </div>

                                        <div class="form-align">
                                            <label for="txtrDescription" class="col-form-label"
                                                class="text-primary form-header"><b>To
                                                    Row:</b></label>
                                            <div style="min-width: 4em; margin-left: 1em">
                                                <input #ABAToRow id="toRow" class="form-control" type="text"
                                                    placeholder="" formControlName="toRow" [(ngModel)]="valueToRow" />
                                            </div>
                                        </div>

                                        <div class="form-align">
                                            <label for="txtrDescription" class="col-form-label"
                                                class="text-primary form-header"><b>Internal Exclusion</b></label>
                                            <input id="excludeCheckbox" style="margin-left: 1em" type="checkbox"
                                                class="form-control" formControlName="internalExclusion"
                                                [(ngModel)]="valueInternalExclusion">
                                            <button type="button" class="btn btn-light" data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="If ticked, the member's BCF will not be incremented, the Membership Ledger will be balanced and no notification will be sent.">
                                                <i class="fas fa-info-circle"></i>
                                            </button>
                                        </div>
                                        <div class="divider"></div>

                                        <div class="form-align">
                                            <button class="btn btn-primary btn-sm" [disabled]="isAbaFinalised
                                                    || (
                                                        ABADropdown.value === ''
                                                    || (!ABAFromRow.value || ABAFromRow.value == '')
                                                    || (!ABAToRow.value || ABAToRow.value == '')
                                                       )" (click)="validateExcludeFilter()">
                                                <b>Exclude</b>
                                            </button>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </form>




                        <div class="mt-lg-4">
                            <div id="excludeFilterTable">
                                <div class="form-group">
                                    <div class="border-default rounded-10 border-2">
                                        <div class="tablecontainer">
                                            <table mat-table [dataSource]="excludeRowDataSource" matSort
                                                #excludeSort="matSort" matSortActive="FileName" matSortDirection="desc"
                                                class="rounded-10">
                                                <ng-container matColumnDef="FileName">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        File Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let element">
                                                        {{ element.FileName }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="Row">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        Row Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let element">
                                                        {{ element.Row }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="Amount">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        $ Amount
                                                    </th>
                                                    <td mat-cell *matCellDef="let element">
                                                        {{ element.Amount | currency }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="Employer">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        Employer
                                                    </th>
                                                    <td mat-cell *matCellDef="let element">
                                                        {{ element.Employer }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="MemberNumber">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        Member Number
                                                    </th>
                                                    <td mat-cell *matCellDef="let element">
                                                        {{ element.MemberNumber }}
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="InternalExclusion">
                                                    <th mat-header-cell *matHeaderCellDef>Internal Exclusion</th>
                                                    <td mat-cell *matCellDef="let element; let j = index">
                                                        <div class="custom-control custom-checkbox p-0 m-0">
                                                            <input type="checkbox" id="mycheck" disabled="true"
                                                                class="custom-control-input"
                                                                [(ngModel)]="element.InternalExclusion"
                                                                [ngModelOptions]="{ standalone: true }" />
                                                            <label class="custom-control-label">&nbsp;</label>
                                                        </div>
                                                    </td>
                                                </ng-container>



                                                <tr mat-header-row *matHeaderRowDef="excludedRowdDisplayedColumns; sticky: true"></tr>
                                                <tr mat-row *matRowDef="let row; columns: excludedRowdDisplayedColumns">
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <mat-paginator #rawpagi [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons>
                                </mat-paginator>
                            </div>
                        </div>

                        <br />
                        <div id="regenrateAbaBtn">
                            <button class="btn btn-primary" [disabled]="isAbaFinalised" (click)="regenerateAbaFiles()">
                                <b>Regenerate {{generatedFileType}}s</b></button><button type="button" class="btn btn-light"
                                data-toggle="tooltip" data-placement="top"
                                title="Regenerate all {{generatedFileType}}s with the exclusion of all the rows in the 'Exclude Rows' table.">
                                <i class="fas fa-info-circle"></i>
                            </button>
                            <app-describe-state-machine #regenDesc [MachineType]="StepFunctions.RegenerateAbas"
                                (SuccessReturned)="handleRegenResponse($event)" class="inline">
                            </app-describe-state-machine>
                        </div>
                    </ng-template>
                </ngb-panel>
        </ngb-accordion>
    </div>


    <div id="finaliseAbaBtn">
        <button class="btn btn-primary" [disabled]="isAbaFinalised" (click)="finaliseAbaFiles()">
            <b>{{finalizeButtonText}}</b></button><button type="button" class="btn btn-light" data-toggle="tooltip"
            data-placement="top"
            title= "{{finalizeButtonDescription}}">
            <i class="fas fa-info-circle"></i>
        </button>

        <app-describe-state-machine [MachineType]="StepFunctions.FinaliseAbas"
            (SuccessReturned)="handleFinaliseResult($event)" (FailureReturned)="handleFinaliseResultForFailure($event)" #finaliseDesc></app-describe-state-machine>
    </div>

    <br />
    <br />
    <br />


    <div class="tabFPTheme tbFPPackagePUTheme mb-5">

        <ngb-accordion [destroyOnHide]="false" activeIds="pnlFailiures">
                <ngb-panel id="pnlFailiures">
                    <ng-template ngbPanelTitle>
                        <i class="icon-fp fas fa-check"></i>
                        Failures
                        <i class="fas fas fa-chevron-down"></i>
                        <i class="fas fas fa-chevron-up"></i>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="container-fluid" id="resultUploadFailedTransactions">
                            <div class="row">
                                Total Failures: {{ this.totalFailiures}}
                                <br />
                                Total Amount: {{this.totalFailedAmount | currency }}

                                <div class="divider"></div>

                                <button class="btn btn-primary" (click)="confirmFailedTransactionUpload()" onclick="this.value=null;">
                                    <b>CONFIRM UPLOAD</b></button>
                                <app-describe-state-machine
                                    [MachineType]="StepFunctions.ConfirmUploadFailedTransactions" #confirmDesc
                                    (SuccessReturned)="handleConfirmReturn($event)">
                                </app-describe-state-machine>
                            </div>
                        </div>

                        <div class="row" [hidden]="totalFailedAmount !== undefined">
                            <app-fp-file-upload-r2 filterFile=".csv, text/csv" fileCategory="uploadFailedTransactions"
                                (UploadCompleted)="addFileUploadCompleted($event)" [isUploadCompletedDefault]="false"
                                [columnParams]="colParameter" [requiresCheck]="checkNecessary" [fileProcessingComponent]="fileProcessingComponent"
                                [isDisabled]="!isAbaFinalised">
                            </app-fp-file-upload-r2>
                            <button type="button" class="btn btn-light" data-toggle="tooltip" data-placement="top"
                                title="Uploads a file with all failed transactions data, writes these debits back to the ledger and updates the BCF.">
                                <i class="fas fa-info-circle"></i>
                            </button>
                            <app-describe-state-machine [MachineType]="StepFunctions.UploadFailedTransactions"
                                #uploadDesc (SuccessReturned)="handleUploadReturn($event)"></app-describe-state-machine>
                        </div>

                    </ng-template>
                </ngb-panel>
        </ngb-accordion>
    </div>

    <br />


</div>

<app-fp-progress-bar #progressBar></app-fp-progress-bar>