<div [formGroup]="form">
    <div class="form-group row">
        <label for="txtABN" class="col-md-3 col-lg-2 col-form-label">ABN</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" id="txtABN" class="form-control" maxlength="11" autocomplete="off" formControlName="Abn">
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtEmployerCode" class="col-md-3 col-lg-2 col-form-label">Employer Code<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input type="text" id="txtEmployerCode" class="form-control" autocomplete="off" maxlength="3" formControlName="Code"
                    [restrictedPattern]="'^[A-Za-z]{0,3}$'" [fpFormControlValidation]="{errorTemplates: {
                        'duplicated': '{0} already exists, please select a unique code',
                        'minlength': 'Employer Code should be 3 letters long'
                    }}">
                <mat-spinner [diameter]="14" color="primary" *ngIf="getControl('Code').pending"></mat-spinner>
            </div>
        </div>
    </div>
</div>