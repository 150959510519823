//Author: Da Do
//Description: Entity for Result that is used to catch the result from API

export class ResultModel {
    Name: string;
    Message: string;
    WaitInSeconds: number;
    Success: boolean;
    Errors: string[];
    ErrorNumber?: number;
    ModifiedBy?: string;
}

export class ResultDataModel {
    Data: boolean;
    Name: string;
    Message: string;
    WaitInSeconds: number;
    Success: boolean;
    Errors: string[];
    ErrorNumber?: number;
}

export class AWSStartExecutionResponse {
    executionArn: string;
    startDate: number;
}

export class AWSDescribeExecutionRequest {
    ExecutionArn: string;
}

export class AWSDescribeExecutionResponse<T> {
    executionArn: string;
    input: string;
    name: string;
    output: any;
    startDate: number;
    stateMachineArn: string;
    status: string;
    stopDate: number;
}

export class CDPEState {

    /** The User who initiated the process */
    userName: string;

    /** The Dictionary of ABA Files to the list of each ABA File's Contents */
    abaFileIds: number[];

    /** The current Debit Processing entry */
    debitProcessingId: number;

    /** True if the process to this point has succeeded. */
    success: boolean;

    /** Any messages that may be passed */
    messages: string;
}
