import { Injector, QueryList, ViewChildren, Directive } from '@angular/core';
import { FPFormBaseComponent, ModelBasedFormSharedData } from '@fp/components/base';
import { ServiceDetails } from '@fp/models';

export enum FacilityServiceFormMode {
    Add,
    Edit
}

export class FacilityServiceFormSharedData extends ModelBasedFormSharedData<ServiceDetails> {
    mode: FacilityServiceFormMode;
    constructor() {
        super();
        this.model.Id = 0;
        this.mode = FacilityServiceFormMode.Add;
    }
}

@Directive()
export abstract class FacilityServiceFormBase extends FPFormBaseComponent<FacilityServiceFormSharedData> {
    @ViewChildren(FacilityServiceFormBase) protected _children: QueryList<FacilityServiceFormBase>;
    /**
     * List of direct descendant components.
     * @readonly
     */
    get children(): QueryList<FacilityServiceFormBase> { return this._children; }

    constructor(injector: Injector) {
        super(injector);
        this.data = new FacilityServiceFormSharedData();
    }
}
