export class Gender {
    GenderId: number;
    Name: string;
    IsActive: boolean;
    DisplayOrder: number;

    constructor(ID?: number, name?: string, active?: boolean, order?: number) {
        this.GenderId = ID;
        this.Name = name;
        this.IsActive = active;
        this.DisplayOrder = order;
    }
}
