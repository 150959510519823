import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonConstants } from '@fp/constant/common-constants';
import { empty, Observable } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap } from 'rxjs/operators';
import { RouterConstant } from 'src/app/constant/routerconstant';
import { DataResult, Region, State, RequestPagination, SearchPaginationResult, SearchPaginationRequest } from 'src/app/models';
import { MessageBox } from 'src/app/services/common-dialog.service';
import { CommonService } from 'src/app/services/common.service';
import { RegionService } from 'src/app/services/region.service';

@Component({
    selector: 'app-searchregions',
    templateUrl: './searchregions.component.html',
    styleUrls: ['./searchregions.component.css']
})
export class SearchregionsComponent implements OnInit, AfterViewInit {
    parentFocus = 0;
    regionSearchResults: Region[];
    states: State[];
    selectedState: State;
    searchTextControl = new UntypedFormControl();
    isSearchingRegion = false;

    @ViewChild('regionSearchBox') regionSearchBox: ElementRef<HTMLDivElement>;
    @ViewChild('regionAutocomplete') regionAutocomplete: MatAutocomplete;

    paging: SearchPaginationRequest = new SearchPaginationRequest();

    // #region Constant references
    routerConstant = RouterConstant;
    // #endregion Constant references

    constructor(
        private svc: RegionService,
        private commonSvc: CommonService,
        private router: Router,
        private dialog: MatDialog) {
        this.states = [];
    }

    ngOnInit() {
        this.paging.OrderBy = 'name';
        this.LoadStates();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.searchTextControl.valueChanges.
                pipe(
                    debounceTime(500),
                    switchMap((value) => {
                        if (value != null && value.length >= 3) {
                            this.isSearchingRegion = true;
                            this.paging.PageNumber = 0;
                            this.paging.PageSize = 0;
                            this.paging.Params = [{ Name: 'name_postcode', Value: value }];
                            return this.svc.search(this.paging);
                        } else {
                            this.regionSearchResults = [];
                            this.isSearchingRegion = false;
                        }
                        return empty();
                    }),
                    catchError((e) => { throw e; })
                ).subscribe(
                    (result: DataResult<SearchPaginationResult<Region>>) => {
                        this.isSearchingRegion = false;
                        if (result != null) {
                            if (result.Success) {
                                this.regionSearchResults = result.Data.Results;/*.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1); */
                            } else {
                                // TODO: display a message specifying the error occurred at server side.
                                console.error(result);
                            }
                        }
                    },
                    error => {
                        this.isSearchingRegion = false;
                        this.errorHandler(error);
                    });

            // Fix autocomplete panel width.
            this.regionAutocomplete.panelWidth = this.regionSearchBox.nativeElement.clientWidth;
        });
    }

    private errorHandler(error, message: string = null) {
        MessageBox.ShowError(this.dialog, message || 'An error occurred while trying to call a service');
        console.error(error);
    }

    Invoke(source: Observable<any>, handleResultCallback: Function) {
        this.commonSvc.StartProgressBar();
        source.pipe(
            catchError((e) => { throw e; }),
            finalize(() => { this.commonSvc.StopProgressBar(); }))
            .subscribe(
                res => { handleResultCallback(res); },
                err => { this.errorHandler(err); }
            );
    }

    LoadStates() {
        this.states = CommonConstants.GET_STATES;
    }

    AutoComplete_DisplayWithFn(option: any) {
        if (!option) {
            return '';
        }
        return option.Name;
    }

    ViewByState() {
        if (this.selectedState == null)
            return;

        this.router.navigate(
            [RouterConstant.NAVIGATOR_REGION_VIEWLIST],
            {
                queryParams: {
                    id: this.selectedState.StateId,
                    name: this.selectedState.Name
                },
                skipLocationChange: true
            });
    }

    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }
    onBlurParent(focusNumbr) {
        this.parentFocus = focusNumbr;
    }
    getClassFocused(vlFocused) {
        if(this.parentFocus == vlFocused) {
            return "focused";
        } else {
            return "";
        }
    }
}
