<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">
    Create Payment Request
</h4>

<!-- Main Mat Spinner -->
<div class="loading-shade-global" *ngIf="isLoading">
    <mat-spinner [diameter]="50"></mat-spinner>
</div>

<form [formGroup]="dateChangeForm"
    action="/src/app/components/finance-components/view-payment-requests/create-payment-request/create-payment-request.component.html"
    method="GET">
    <br />
    <div class="form-align">
        <!--Facility and the text box-->
        <div class="form-group row">
            <label for="txtrDescription" class="col-md-3 col-lg-5 col-form-label"
                class="text-primary facility-label"><b>Facility</b><span class="text-danger"> *</span></label>
            <div class="col-md-3 col-lg-5">
                <div class="input-group">
                    <input id="facilityName" class="form-control" type="text" (keyup)="findFacilityByName($event)"
                        [matAutocomplete]="facilityAutocomplete" />
                    <div class="input-group-append">
                        <button class="input-group-text" type="submit" disabled>
                            <i class="fas fa-search text-primary" *ngIf="!isSearchingFacilityService"></i>
                            <mat-spinner *ngIf="isSearchingFacilityService" [diameter]="14"></mat-spinner>
                        </button>
                    </div>
                </div>

                <mat-autocomplete #facilityAutocomplete="matAutocomplete">
                    <!-- *ngFor="let state of (slide.checked ? [] : (filteredStates| async) -->
                    <mat-option
                        *ngFor="let facility of (!this.facilitySearchResult.length? [] : (facilitySearchResult))"
                        [value]="facility.Name" (onSelectionChange)="findServiceFromFacilityId(facility.Id)">
                        <p class="d-flex align-items-center m-0 ml-2 w-100">
                            {{ facility.Name }}
                        </p>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>

        <!--Service and the text box-->
        <div class="form-group row">
            <label for="txtrDescription" class="col-md-3 col-lg-5 col-form-label"
                class="text-primary form-header"><b>Service</b><span class="text-danger"> *</span></label>

            <!--Dropdown Menu for Service-->

            <div class="d-flex flex-column">
                <select class="form-control" id="serviceName" (change)="findVisitAmount()" tabindex="2">
                    <option *ngFor="let option of serviceOptions">
                        {{ option }}
                    </option>
                </select>
            </div>
        </div>

        <!--Payment Period-->

        <div class="form-group row">
            <label for="txtrDescription" class="text-primary form-header"><b>Payment Period</b><span
                    class="text-danger">
                    *</span></label>

            <div style="display: inline-block">
                <label style="display: block" class="text-primary"><b>From</b></label>
                <div name="fromDate" class="input-group">
                    <input id="fromDate" name="dp" ngbDatepicker #fromDate="ngbDatepicker" class="form-control"
                        formControlName="dateFrom" placeholder="DD-MMM-YYYY" [maxDate]="fromDateMax"
                        (change)="findVisitAmount()" (dateSelect)="findVisitAmount()" tabindex="3" />
                    <div class="input-group-append" (click)="findVisitAmount()">
                        <button class="btn bg-white" (click)="fromDate.toggle()" type="button">
                            <i class="far fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
            </div>
            &nbsp;
            <div style="display: inline-block">
                <label style="display: block" class="col-md-3 col-lg-2 col-form-label"
                    class="text-primary"><b>To</b></label>
                <div name="toDate" class="input-group">
                    <input id="toDate" name="dp" ngbDatepicker #toDate="ngbDatepicker" class="form-control"
                        formControlName="dateTo" placeholder="DD-MMM-YYYY" [minDate]="selectedFromDate"
                        (change)="findVisitAmount()" (dateSelect)="findVisitAmount()" tabindex="4" />
                    <div class="input-group-append" (click)="findVisitAmount()">
                        <button class="btn bg-white" (click)="toDate.toggle()" type="button">
                            <i class="far fa-calendar-alt"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row"
            *ngIf="finalLoadingTable">
            <label for="txtrDescription" class="col-md-3 col-lg-5 col-form-label"
                class="text-primary facility-label"></label>
            <div class="col-md-9 paid-table-align">
                <table mat-table [dataSource]="finalPaymentLogDataSource" matSort matSortActive="DateCreated"
                    matSortDirection="desc" class="rounded-10">

                    <ng-container matColumnDef="Service">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Service
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.Service }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="DateCreated">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Payment Period
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.PaymentPeriodStart | dateFormatPipe }} -
                            {{ element.PaymentPeriodEnd | dateFormatPipe }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="VisitCount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Visit Count
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.VisitCount }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="DollarAmount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Dollar Amount
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.DollarAmount | currency }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="PaymentCalculationMethod">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Calculation Method
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.PaymentCalculationMethod }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="PRStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            PR Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.PRStatus }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="PRID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            PR Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.PaymentRequestID }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
                </table>                
                <mat-toolbar [hidden]="finalDataSource && finalDataSource.length > 0" class="bg-white">
                    <mat-toolbar-row class="text-muted d-block p-2 bg-light text-center">
                        {{ CommonString.TABLE_NO_RECORD_FOUND }}
                    </mat-toolbar-row>
                </mat-toolbar>
            </div>
        </div>
        <br />
        <!--Visits in the System - Text Box and Button to Add manual visits-->

        <div class="form-group row">
            <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label"
                class="text-primary form-header"><b>Visits
                    in the System</b></label>
            <div class="col-md-5 col-lg-2">
                <div class="d-flex flex-column w-100">
                    <input class="form-control" type="text" readonly [value]="visitAmount" />
                </div>
            </div>
            <button mat-button (click)="openDialog()" class="btn btn-primary btn-sm" tabindex="5">
                Manually Add Visits
            </button>
        </div>

        <!--Total Visits for this payment text box-->
        <div class="form-group row">
            <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label"
                class="text-primary form-header"><b>Total
                    Visits for this Payment</b><span class="text-danger"> *</span></label>

            <div class="col-md-5 col-lg-2">
                <div class="d-flex flex-column w-100">
                    <input class="form-control" type="number" min="0" required [value]="tempVisitAmount" id="finalVisit"
                        (keyup)="perVisitCalc()" />
                </div>
            </div>
        </div>

        <!--System Calculated Payment text box-->
        <div class="form-group row">
            <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label"
                class="text-primary form-header"><b>System
                    Calculated Payment</b></label>
            <div class="col-md-5 col-lg-2">
                <div class="d-flex flex-column w-100">
                    <input class="form-control" type="text" id="calculatedPayment" readonly
                        [value]="paymentAmount | currency" />
                </div>
            </div>
            <div *ngIf="isFirstPR">
                <button type="button" class="btn btn-primary form-button" (click)="finalChange()">
                    Recalculate Payment by Total
                </button>
            </div>
        </div>

        <!-- Payment Method and Payment Visit Info -->
        <div class="form-group row">
            <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label"
                class="text-primary form-header"><b>Payment Method</b></label>
            <div class="col-md-5 col-lg-2">
                <div class="d-flex flex-column w-100">
                    <input class="form-control" type="text" id="paymentMethod" readonly [value]="PRCalculationMethod" />
                </div>
            </div>
        </div>

        <!-- Sanity Check Calculation info -->
        <div class="form-group row">
            <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label"
                class="text-primary form-header"><b>Per Visit Calculation Check</b></label>
            <div class="col-md-5 col-lg-2">
                <div class="d-flex flex-column w-100">
                    <input class="form-control" type="text" id="calcCheck" readonly
                        [value]="perVisitCalcCheck | currency" />
                </div>
            </div>
            Final Amount to Pay / Total Visits for this Payment
        </div>

        <!--Amount to Pay text box and Recalculate Payment by Total-->
        <div class="form-group row">
            <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label"
                class="text-primary form-header"><b>Final
                    Amount to Pay</b><span class="text-danger"> *</span></label>
            <div class="col-md-5 col-lg-2">
                <div class="d-flex flex-column w-100">
                    <input class="form-control" type="text" min="0" id="finalPayment" (change)="manualTotalPayChange()"
                        [value]="finalPayment | currency" tabindex="8" (keyup)="perVisitCalc()" />
                </div>
            </div>
        </div>

        <!--Invoice number text box, attach invoice button and show file name-->
        <div class="form-group row">
            <label for="txtrDescription" class="col-md-3 col-lg-2 col-form-label"
                class="text-primary form-header"><b>Invoice
                    Number</b></label>
            <div class="col-md-5 col-lg-2">
                <div class="d-flex flex-column w-100">
                    <input class="form-control" type="text" id="invoiceNumber" tabindex="9" />
                </div>
            </div>

            <div>
                <label for="Attach_invoice" class="px-5 py-2 btn btn-primary btn-sm">
                    <input type="file" class="hidden-file-input" id="Attach_invoice" (change)="attachInvoice($event)"
                        onclick="this.value=null;" />
                    <i class="fas fa-upload"></i>
                    Attach Invoice
                </label>
                <label for="Attach_invoice" class="col-md-3 col-lg-2 col-form-label d-table">{{ documentName }}</label>

                <!--bin button-->
                <button class="btn btn-light" (click)="removeInvoice()">
                    <i class="fas fa-trash-alt" style="color:red"></i>
                </button>
                &nbsp;&nbsp;
            </div>
        </div>

        <form [formGroup]="PaymentRequestNoteFormGroup">
            <div class="form-group row">
                <label for="paymentRequestNoteText" class="col-md-3 col-lg-2 col-form-label"
                    class="text-primary form-header"><b>Notes</b></label>
                <div class="col-md-8 col-lg-5">
                    <div class="d-flex flex-column w-100">
                        <textarea class="form-control" id="paymentRequestNoteText" rows="3"
                            formControlName="paymentRequestNoteText" (keyup)="noteInputs($event)"
                            tabindex="10"></textarea>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-3 offset-md-3 offset-lg-2">
                    <div class="d-flex flex-column w-100">
                        <button class="px-2 py-2 btn btn-primary text-transform-none" type="button"
                            (click)="addPaymentRequestNotes()" [disabled]="btnAddNoteClick">
                            <i class="fas fa-plus-circle mr-2"></i>Add Note</button>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-md-9 col-lg-10 offset-md-3 offset-lg-2">
                    <div class="d-flex flex-column w-100">
                        <div class="border-default rounded-10 border-2">
                            <div class="tablecontainer">
                                <table mat-table [dataSource]="dtmatnotes" class="rounded-10">

                                    <!-- User Column -->
                                    <ng-container matColumnDef="Username">
                                        <th mat-header-cell *matHeaderCellDef>User</th>
                                        <td mat-cell *matCellDef="let element"> {{element.User}} </td>
                                    </ng-container>

                                    <!-- DateTime Column -->
                                    <ng-container matColumnDef="DateTimeDisplay">
                                        <th mat-header-cell *matHeaderCellDef>Date/Time</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.DateTime}} </td>
                                    </ng-container>

                                    <!-- Note Column -->
                                    <ng-container matColumnDef="Note">
                                        <th mat-header-cell *matHeaderCellDef>Note</th>
                                        <td mat-cell *matCellDef="let element"> {{element.Note}} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns;">
                                    </tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <br /><br />

        <!--Submit and cancel button-->
        <button class="btn btn-ouline-light" (click)="reinitialise()" tabindex="-1">
            <b>Cancel</b>
        </button>
        &nbsp;&nbsp;
        <button (click)="submitButton()" class="btn btn-primary" tabindex="-1">
            <b>Submit</b>
        </button>
    </div>
</form>