import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonService } from "@fp/services/common.service";
import { HttpDAO, MessageBox, MemberService } from "@fp/services";
import {
  CommonResponse,
  SearchPaginationRequest,
  DataResult,
  SearchPaginationResult,
  Facility,
  Employer,
  MemberSearchResultRecord,
  RequestPagination,
} from "@fp/models";
import { ERole } from "@fp/enums/role.enum";
import { merge, of as observableOf, forkJoin, Subject, empty } from "rxjs";
import {
  startWith,
  switchMap,
  map,
  catchError,
  shareReplay,
  filter,
  debounceTime,
} from "rxjs/operators";
import { APIConstant, RouterConstant, CommonString } from "@fp/constant";
import { Router } from "@angular/router";
import { CommonDataType } from "@fp/enums";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { FPValidators } from "@fp/helpers";
import { EoMScannerVisitLogPDFConvert } from "../download-PDF/EoM-Scanner-VisitLog-PDFConvert";

@Component({
  selector: "app-generate-reports-EF-FSM",
  templateUrl: "./generate-reports-EF-FSM.component.html",
  styleUrls: ["./generate-reports-EF-FSM.component.css"],
})
export class GenerateReportsEFFSMComponent implements OnInit {
  private httpdao: HttpDAO | null;
  public ReportFormGroup: UntypedFormGroup;
  public ReportItems: CommonResponse[] = [];
  public FilterItems: CommonResponse[] = [];
  public FilterItems2: CommonResponse[] = [];
  public FilterItems3: CommonResponse[] = [];
  public AvailableFacilities: Facility[] = [];
  public AvailableEmployers: Employer[] = [];
  public IsFilter = false;
  public IsFilterNormalDLL = false;
  public IsFilterNormalDLL2 = false;
  public IsFilterNormalTXT = false;
  public IsFilterDate = false;
  public IsFilterAutoMember = false;
  public FilterLabel = "";
  public FilterLabel2 = "";
  public FilterLabel3 = "";
  public btnGenerateClicked = false;
  public displayedColumns: string[] = [];
  public seconddisplayedColumns: string[] = [];
  public serverdisplayedColumns: string[] = [];
  public dataSource = new MatTableDataSource();
  public seconddataSource = new MatTableDataSource();
  public serverpagingSource = new MatTableDataSource();
  public IsNoDataFirstTable = false;
  public IsNoDataSecondTable = false;
  public IsNoDataServerTable = false;
  public ReportTypeName = "";
  public IsSecondTable = false;
  public IsShowingExport = false;
  public IsCSVOnly = false;
  public IsShowingServerDataPaging = false;
  public DownloadFileSubject: Subject<boolean> = new Subject();
  public IsEmpRequired = false;
  public IsFacRequired = false;
  public memberSearchResults: MemberSearchResultRecord[];
  public isSearchingMember = false;
  public IsShowingHeaderTable1 = false;
  public IsShowingHeaderTable2 = false;
  public Header1 = "";
  public Header2 = "";
  public listReportData = [];
  // Paging server
  public length: number;
  public pageSize: number;
  public pageSizeOptions: number[];
  public pageEvent: PageEvent;
  public pagingreq: RequestPagination = new RequestPagination();
  //End

  private paging: SearchPaginationRequest = new SearchPaginationRequest();
  private WAITING_TIME = 15000;
  private MemberID = 0;
  @ViewChild("rppagi") rp_paginator: MatPaginator;
  @ViewChild("rpsecondpagi") rp_second_paginator: MatPaginator;
  @ViewChild("firstSort") firstSort: MatSort;
  @ViewChild("secondSort") secondSort: MatSort;

  //Eom Report
  public DefaultFilter = "";
  public DefaultFilter2 = "";

  get noRecordFoundText(): string {
    return CommonString.TABLE_NO_RECORD_FOUND;
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private http: HttpClient,
    private comsvr: CommonService,
    private memberSvc: MemberService,
    private router: Router
  ) {}

  ngOnInit() {
    this.pageSizeOptions = CommonString.PAGE_SIZE_OPTIONS;

    this.httpdao = new HttpDAO(this.http);
    this.ReportFormGroup = this._formBuilder.group({
      ddlEmployer: [""],
      ddlReport: ["", Validators.required],
      ddlFacility: [""],
      ddlFilter: [""],
      ddlFilter2: [""],
      ddlFilter3: [""],
      dtpStartDate: [""],
      dtpEndDate: [""],
      txtFilter: [""],
      ddlMemberSearch: [""],
    });

    const slrole = this.comsvr.GetSelectedRole();
    if (slrole === ERole.Facility_Staff_Member) {
      this.LoadAvailableFacilities();
    } else {
      this.LoadAvailableEmployers();
    }

    this.DownloadFileSubject.pipe(debounceTime(this.WAITING_TIME)).subscribe(
      (val) => {
        this.comsvr.StopProgressBar();
      }
    );

    this.InitMemberSearch();
  }

  private InitMemberSearch() {
    this.ReportFormGroup.get("ddlMemberSearch")
      .valueChanges.pipe(
        debounceTime(500),
        switchMap((value) => {
          const isfacilitatorrole =
            this.comsvr.GetSelectedRole() === ERole.Employer_Facilitator
              ? "1"
              : "0";
          const isfsm =
            this.comsvr.GetSelectedRole() === ERole.Facility_Staff_Member
              ? "1"
              : "0";

          let facilityIds_memberSearch = this.ReportFormGroup.get('ddlFacility').value;
          // this.AvailableFacilities.forEach((element, index, array) => {
          //   if (index === (array.length - 1)) {
          //       facilityIds_memberSearch += element.FacilityId;
          //   } else {
          //       facilityIds_memberSearch += element.FacilityId + ',';
          //   }
          // }, this);

          if (this.paging !== null && this.paging !== undefined) {
            this.paging.PageNumber = 1;
            this.paging.PageSize = 500;
            this.paging.OrderBy = "lastname";
            this.paging.Params = [
              { Name: "membername_number_email", Value: value },
              { Name: "userid", Value: this.comsvr.GetUser() },
              { Name: "isfacilitatorrole", Value: isfacilitatorrole },
              { Name: 'facility_ids', Value: facilityIds_memberSearch },
            ];
          }
          if (value != null && value.length >= 3) {
            if(value.trim().length >= 3) {
              this.isSearchingMember = true;
              // console.log("ROLE: ", isfsm);
              if (isfsm == "1") {
                return this.memberSvc.searchpagingforfsm(this.paging);
              } else {
                return this.memberSvc.searchpaging(this.paging);
              }
            } else {
              this.memberSearchResults = [];
              this.isSearchingMember = false;
            }
          } else {
            this.memberSearchResults = [];
            this.isSearchingMember = false;
          }
          return empty();
        }),
        catchError((e) => {
          throw e;
        })
      )
      .subscribe(
        (
          result: DataResult<SearchPaginationResult<MemberSearchResultRecord>>
        ) => {
          this.isSearchingMember = false;
          if (result != null) {
            if (result.Success) {
              this.memberSearchResults = result.Data.Results;
            } else {
              console.error(result);
            }
          }
        },
        (error) => {
          this.isSearchingMember = false;
          MessageBox.ShowError(
            this.dialog,
            "An error occurred while trying to call a service"
          );
          console.error(error);
        }
      );
  }

  private GenerateReportItems() {
    const slrole = this.comsvr.GetSelectedRole();
    if (slrole === ERole.Employer_Facilitator) {
      this.ReportItems.push({
        Name: "Member Tally",
        Id: ReportOptions.MemberTally.valueOf(),
      });
      this.ReportItems.push({
        Name: "List of Contacts for Members",
        Id: ReportOptions.ListofContactsforMembers.valueOf(),
      });
    } else if (slrole === ERole.Facility_Staff_Member) {
      this.ReportItems.push({
        Name: "Member Visit Live Feed",
        Id: ReportOptions.MemberVisitLiveFeed.valueOf(),
      });
      this.ReportItems.push({
        Name: "Cancelled FP Members who have visited",
        Id: ReportOptions.CancelledFPMemberswhohavevisited.valueOf(),
      });
      this.ReportItems.push({
        Name: "Visit Log (EoM visitation report)",
        Id: ReportOptions.VisitLogEoMvisitationreport.valueOf(),
      });
      this.ReportItems.push({
        Name: "Member Emergency Contacts and Health Questions",
        Id: ReportOptions.MemberEmergencyContactHealthQuestions.valueOf(),
      });
    }
  }

  public IsEF() {
    const slrole = this.comsvr.GetSelectedRole();
    let display = true;
    if (this.AvailableEmployers.length > 0) {
      if (
        this.AvailableEmployers.length === 1 &&
        this.AvailableEmployers[0].EmployerId > 0
      ) {
        display = false;
        this.ReportFormGroup.get("ddlEmployer").setValue(
          this.AvailableEmployers[0].EmployerId
        );
      } else {
        display = true;
      }
    } else {
      display = false;
    }
    return slrole === ERole.Employer_Facilitator && display === true;
  }

  public IsEFOrFSM() {
    const slrole = this.comsvr.GetSelectedRole();
    if (
      slrole !== ERole.Employer_Facilitator &&
      slrole !== ERole.Facility_Staff_Member
    ) {
      this.router.navigate([RouterConstant.NAVIGATOR_NO_PERMISSION]);
      return false;
    } else {
      return true;
    }
  }

  public IsFSM() {
    const slrole = this.comsvr.GetSelectedRole();
    let display = true;
    if (this.AvailableFacilities.length > 0) {
      if (
        this.AvailableFacilities.length === 1 &&
        this.AvailableFacilities[0].FacilityId > 0
      ) {
        display = false;
        this.ReportFormGroup.get("ddlFacility").setValue(
          this.AvailableFacilities[0].FacilityId
        );
      } else {
        display = true;
      }
    } else {
      display = false;
    }
    return slrole === ERole.Facility_Staff_Member && display === true;
  }

  private SaveFileDialog(url: string, filename: string) {
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.comsvr.StopProgressBar();
  }

  private LoadAvailableFacilities() {
    const searchRequest: SearchPaginationRequest = {
      OrderBy: "name",
      OrderByAsc: true,
      PageNumber: 0,
      PageSize: 0,
      Params: [
        {
          Name: "user",
          Value: this.comsvr.GetUserObject().UserId,
        },
      ],
    };
    const encrypteddata = this.comsvr.E_FP_RequestData(
      JSON.stringify(searchRequest)
    );
    this.comsvr.StartProgressBar();
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.httpdao!.postData(APIConstant.API_SEARCH_FACILITIES, {
            Data: encrypteddata,
          });
        }),
        map((data) => {
          const dectypted_data = this.comsvr.D_FP_ResponseData(data);
          const rdata = JSON.parse(dectypted_data);
          let res: DataResult<SearchPaginationResult<Facility>> = rdata;
          this.comsvr.StopProgressBar();
          if (res.Success) {
            this.AvailableFacilities = res.Data.Results;
            this.GenerateReportItems();
          } else {
            MessageBox.ShowError(
              this.dialog,
              data.Message ||
                "An error has been occured while getting available Facilities"
            );
          }
          return data;
        }),
        catchError(() => {
          this.comsvr.StopProgressBar();
          return observableOf([]);
        })
      )
      .subscribe();
  }

  private LoadAvailableEmployers() {
    const searchRequest: SearchPaginationRequest = {
      OrderBy: "name",
      OrderByAsc: true,
      PageNumber: 0,
      PageSize: 0,
      Params: [
        {
          Name: "employer_facilitator",
          Value: this.comsvr.GetUserObject().UserId,
        },
      ],
    };
    const encrypteddata = this.comsvr.E_FP_RequestData(
      JSON.stringify(searchRequest)
    );
    this.comsvr.StartProgressBar();
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.httpdao!.postData(APIConstant.API_SEARCH_EMPLOYERS, {
            Data: encrypteddata,
          });
        }),
        map((data) => {
          const dectypted_data = this.comsvr.D_FP_ResponseData(data);
          const rdata = JSON.parse(dectypted_data);
          let res: DataResult<SearchPaginationResult<Employer>> = rdata;
          this.comsvr.StopProgressBar();
          if (res.Success) {
            this.AvailableEmployers = res.Data.Results;
            this.GenerateReportItems();
          } else {
            MessageBox.ShowError(
              this.dialog,
              data.Message ||
                "An error has been occured while getting available Employers"
            );
          }
          return data;
        }),
        catchError(() => {
          this.comsvr.StopProgressBar();
          return observableOf([]);
        })
      )
      .subscribe();
  }

  private GetReportFSM_EF(report_type: number) {
    const e_dta = this.GetRequestData(report_type);
    const encryptedtoken = this.comsvr.E_FP_RequestData(this.comsvr.GetToken());
    // console.log("e_dta: ", e_dta);
    this.comsvr.StartProgressBar();
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.httpdao!.postData(APIConstant.API_GENERATE_EF_FSM_REPORT, {
            Data: e_dta,
            Header: encryptedtoken,
          });
        }),
        map((edata) => {
          const decrypted_data = this.comsvr.D_FP_ResponseData(edata);
          // console.log("Decrypted Data: ", dectypted_data);
          const rdata = JSON.parse(decrypted_data);
          if (rdata.Success) {
            if(report_type == ReportOptions.VisitLogEoMvisitationreport ) {
              let report: any = JSON.parse(rdata.Data);
              if(report == null) {
                MessageBox.ShowInfo(this.dialog, this.noRecordFoundText);
                this.comsvr.StopProgressBar();
                return;
              }
              if(report.fileName.endsWith(".pdf")) {
                this.SaveFileDialog(report.fileSignedURL, report.fileName);
              } else {
                this.httpdao!.getRemoteFileData(report.fileSignedURL).subscribe((results) => {
                  const content: string = results;
                  const arr_cont: string[] = content.split('\n');
                  const ReportHeader = arr_cont[0];
                  const fileName = report.fileName;
                  const eomsc = new EoMScannerVisitLogPDFConvert();
                  eomsc.SaveReportToPDFFile(ReportHeader, arr_cont, fileName);
                  this.comsvr.StopProgressBar();
                  return;
                },
                (error) => {
                  MessageBox.ShowInfo(this.dialog, this.noRecordFoundText);
                  this.comsvr.StopProgressBar();
                  return;
                });
              }
            } else {
              if (
                ReportOptions.VisitLogEoMvisitationreport &&
                !ReportOptions.CancelledFPMemberswhohavevisited
              ) {
                //console.log("EoM Result: ", rdata.Data);
                window.open(rdata.Data, "_blank");
                for (let i = 0; i < rdata.Data.length; i++) {
                  window.open(rdata.Data[i], "_blank");
                }
              }
              if (this.IsCSVOnly) {
                if (report_type === ReportOptions.ListofContactsforMembers) {
                  if (rdata.Message === "no data found") {
                    MessageBox.ShowInfo(this.dialog, this.noRecordFoundText);
                    this.comsvr.StopProgressBar();
                  } else {
                    const url = rdata.Data;
                    this.SaveFileDialog(
                      url,
                      this.ReportTypeName +
                        new Date().toDateString() +
                        CommonString.CSV_EXTENSION
                    );
                  }
                } else {
                  const ret = JSON.parse(rdata.Data);
                  this.DisplayDataBasedOnReportType(report_type, ret);
                  this.btnExportExcel_Click();
                  this.comsvr.StopProgressBar();
                }
              } else {
                const ret = JSON.parse(rdata.Data);
                this.DisplayDataBasedOnReportType(report_type, ret);
                this.comsvr.StopProgressBar();
              }
            }
          } else {
            this.comsvr.StopProgressBar();
            MessageBox.ShowError(
              this.dialog,
              "An error has occurred while fetching this report."
            );
          }
          return rdata;
        }),
        catchError(() => {
          this.comsvr.StopProgressBar();
          return observableOf([]);
        })
      )
      .subscribe();
  }

  private DisplayDataBasedOnReportType(report_type: number, ret: any) {
    switch (report_type) {
      case ReportOptions.MemberTally:
        this.IsShowingHeaderTable1 = true;
        this.IsShowingHeaderTable2 = true;
        this.Header1 = "Number of Memberships based on Membership Type";
        this.Header2 = "Number of Members based on Member Type";
        const first_ret = [];
        for (let i = 0; i < 5; i++) {
          first_ret.push(ret[i]);
        }
        const second_ret = [];
        for (let i = 5; i < ret.length; i++) {
          second_ret.push(ret[i]);
        }
        this.SetDataToFirstGrid(first_ret);
        this.SetDataToSecondGrid(second_ret);
        break;
      case ReportOptions.ListofContactsforMembers:
      case ReportOptions.MemberVisitLiveFeed:
      case ReportOptions.CancelledFPMemberswhohavevisited:
        this.SetDataToFirstGrid(ret);
        break;
      case ReportOptions.MemberEmergencyContactHealthQuestions:
        this.listReportData = ret;
        break;
      case ReportOptions.VisitLogEoMvisitationreport:
        for (let i = 0; i < ret.length; i++) {
          const url: string = ret[i].ReportURL;
          ret[i].ReportURL = "/fpdownloadpdf" + url.slice(url.lastIndexOf("/"));
          ret[i].FileName = ret[i].FileName.replace(".csv", ".pdf");
        }
        this.SetDataToFirstGrid(ret, ["FacilityID", "Year", "ReportURL"]);
        break;
    }
  }

  private SetDataToFirstGrid(ret: any, exceptColumns: string[] = []) {
    this.DefineDisplayColumnsForFirstTable(ret, exceptColumns);
    this.dataSource = new MatTableDataSource(ret);
    this.dataSource.sort = this.firstSort;
    this.dataSource.paginator = this.rp_paginator;
  }

  private SetDataToServerPagingGrid(ret: any, exceptColumns: string[] = []) {
    this.DefineDisplayColumnsForServerTable(ret, exceptColumns);
    this.serverpagingSource = new MatTableDataSource(ret);
  }

  private SetDataToSecondGrid(second_ret: any) {
    this.DefineDisplayColumnsForSecondTable(second_ret);
    this.seconddataSource = new MatTableDataSource(second_ret);
    this.seconddataSource.sort = this.secondSort;
    this.seconddataSource.paginator = this.rp_second_paginator;
  }

  private GetFSMReportRequest(report_type: number): string {
    // console.log("report type: ", report_type)
    let fac: string = this.ReportFormGroup.get("ddlFacility").value;
    const slrole = this.comsvr.GetSelectedRole();
    let filter = this.ReportFormGroup.get("ddlFilter").value;
    let filter2 = this.ReportFormGroup.get("ddlFilter2").value;
    let filter3 = this.ReportFormGroup.get("ddlFilter3").value;
    const monthNum = this.monthNameToNum(filter3);
    const user = this.comsvr.GetUser();

    // console.log ("Selected facility: ", fac);
    // console.log ("DDLFilter : ", filter);
    // console.log ("DDLFilter2 : ", filter2);
    // console.log ("MonthNum : ", monthNum);
    // console.log("Selected role :", slrole);
    // console.log("UserName : ", user);

    if (fac === "") {
      const arrstr: string[] = [];
      for (let i = 0; i < this.AvailableFacilities.length; i++) {
        arrstr.push(this.AvailableFacilities[i].FacilityId.toString());
      }
      fac = arrstr.join(",");
    }
    let request;

    switch (report_type) {
      case ReportOptions.MemberEmergencyContactHealthQuestions:
      filter = this.MemberID;
      request = { 
        ReportType: report_type, 
        Param1: filter, 
        Param3: fac,
        Header: slrole };
        break;
      case ReportOptions.VisitLogEoMvisitationreport:
        request = {
          ReportType: report_type,
          Param1: filter,
          Param2: monthNum.toString(),
          Param3: fac,
          Param4: filter2,
          Header: slrole,
          UserName: user,
        };
        break;
      default:
        request = {
          ReportType: report_type,
          Param1: fac,
          Param2: filter,
          Header: slrole,
        };}

    const e_dta = this.comsvr.E_FP_RequestData(JSON.stringify(request));
    return e_dta;
  }

  private GetEFReportRequest(report_type: number): string {
    let emp: string = this.ReportFormGroup.get("ddlEmployer").value;
    const slrole = this.comsvr.GetSelectedRole();
    let request;
    let filter = this.ReportFormGroup.get("ddlFilter").value;
    if (emp === "") {
      const arrstr: string[] = [];
      for (let i = 0; i < this.AvailableEmployers.length; i++) {
        arrstr.push(this.AvailableEmployers[i].EmployerId.toString());
      }
      emp = arrstr.join(",");
    }
    switch (report_type) {
      case ReportOptions.MemberTally:
        request = {
          ReportType: report_type,
          Param1: emp,
          Param2: filter,
          Header: slrole,
        };
        break;
      case ReportOptions.ListofContactsforMembers:
        request = {
          ReportType: report_type,
          Param1: emp,
          Param2: filter,
          Header: slrole,
        };
        break;
    }
    const e_dta = this.comsvr.E_FP_RequestData(JSON.stringify(request));
    return e_dta;
  }

  private GetRequestData(report_type: number): string {
    let e_dta;
    switch (report_type) {
      case ReportOptions.CancelledFPMemberswhohavevisited:
      case ReportOptions.MemberVisitLiveFeed:
      case ReportOptions.VisitLogEoMvisitationreport:
        e_dta = this.GetFSMReportRequest(report_type);
        return e_dta;
      case ReportOptions.MemberEmergencyContactHealthQuestions:
        e_dta = this.GetFSMReportRequest(report_type);
        return e_dta;
      case ReportOptions.MemberTally:
      case ReportOptions.ListofContactsforMembers:
        e_dta = this.GetEFReportRequest(report_type);
        return e_dta;
    }
  }

  private DefineDisplayColumnsForFirstTable(
    jsondata: any,
    exceptColumns: string[] = []
  ) {
    this.displayedColumns = [];
    if (jsondata.length > 0) {
      this.IsNoDataFirstTable = false;
      var columnsIn = jsondata[0];
      for (var key in columnsIn) {
        if (exceptColumns.indexOf(key) === -1) {
          this.displayedColumns.push(key);
        }
      }
    } else {
      this.displayedColumns = [];
      this.IsNoDataFirstTable = true;
    }
  }

  private DefineDisplayColumnsForServerTable(
    jsondata: any,
    exceptColumns: string[] = []
  ) {
    this.IsNoDataFirstTable = false;
    this.IsNoDataSecondTable = false;
    this.serverdisplayedColumns = [];
    if (jsondata.length > 0) {
      this.IsNoDataServerTable = false;
      var columnsIn = jsondata[0];
      for (var key in columnsIn) {
        if (exceptColumns.indexOf(key) === -1) {
          this.serverdisplayedColumns.push(key);
        }
      }
    } else {
      this.serverdisplayedColumns = [];
      this.IsNoDataServerTable = true;
    }
  }

  private DefineDisplayColumnsForSecondTable(jsondata: any) {
    this.seconddisplayedColumns = [];
    if (jsondata.length > 0) {
      this.IsNoDataSecondTable = false;
      var columnsIn = jsondata[0];
      for (var key in columnsIn) {
        this.seconddisplayedColumns.push(key);
      }
    } else {
      this.seconddisplayedColumns = [];
      this.IsNoDataSecondTable = true;
    }
  }

  private GenerateDataForTesting(jsondata: any) {
    for (let i = 1; i < 100; i++) {
      const o2 = JSON.stringify(jsondata[0]);
      const abc = JSON.parse(o2);
      abc["ServiceID"] = 1000 + i;
      jsondata.push(abc);
    }
  }

  public btnGenerateReport_Click() {
    const valstr = this.ReportFormGroup.get("ddlReport").value;
    const val = parseInt(valstr, 10);
    this.btnGenerateClicked = true;
    if (this.ReportFormGroup.valid) {
      this.GetReportFSM_EF(val);
    }
  }

  private LoadCommonData(rptype: number) {
    this.comsvr.StartProgressBar();
    this.FilterItems = [];
    this.FilterItems2 = [];
    this.ReportFormGroup.get("ddlFilter").setValue("");
    let param: number = -1;
    switch (rptype) {
      case ReportOptions.CancelledFPMemberswhohavevisited:
        param = CommonDataType.MemberCancellationReason;
        break;
      case ReportOptions.ListofContactsforMembers:
        param = CommonDataType.MemberStatus;
        break;
      case ReportOptions.VisitLogEoMvisitationreport:
        param = CommonDataType.EoMReportYears;
        break;
    }
    if (param !== -1) {
      forkJoin(
        this.httpdao!.getData(
          APIConstant.API_GET_COMMON_DATA + param.toString()
        )
      )
        .pipe(
          map(([cmdata]) => {
            return { cmdata };
          }),
          shareReplay(1),
          catchError(() => {
            this.comsvr.StopProgressBar();
            return observableOf(null);
          })
        )
        .subscribe((results) => {
          if (results != null) {
            const retdata: CommonResponse[] = results.cmdata.Data;
            this.FormatFilter(rptype, retdata);
          }
          this.comsvr.StopProgressBar();
        });
    }
  }

  private FormatFilter(rptype: number, retdata) {
    switch (rptype) {
      case ReportOptions.VisitLogEoMvisitationreport:
        const facid = this.ReportFormGroup.get("ddlFacility").value;
        let tempfilter: CommonResponse[] = [];
        let tempfilter2: CommonResponse[] = [];
        let tempfilter3: CommonResponse[] = [];

        this.LoadServices(tempfilter, facid);
        this.LoadYears(tempfilter2);
        this.LoadMonths(tempfilter3)

        for (let j = 0; j < tempfilter.length; j++) {
          this.FilterItems.push(tempfilter[j]);
        }

        for (let j = 0; j < tempfilter2.length; j++) {
          this.FilterItems2.push(tempfilter2[j]);
        }

        for (let j = 0; j < tempfilter3.length; j++) {
          this.FilterItems3.push(tempfilter3[j]);
        }

        break;
      default:
        this.FilterItems = retdata;
        break;
    }
  }

  private LoadServices(tempfilter: CommonResponse[], facilityID: number) {
    let selectedFacility: Facility = null;
    for(var x = 0; x < this.AvailableFacilities.length; x++) {
      if(this.AvailableFacilities[x].FacilityId == facilityID) {
        selectedFacility = this.AvailableFacilities[x];
        break;
      }
    }
    if(selectedFacility != null) {
      for (let i = 0; i < selectedFacility.Services.length; i++) {
        let temp = new CommonResponse();
        temp.Id = selectedFacility.Services[i].Id;
        temp.Name = selectedFacility.Services[i].Id + " - " + selectedFacility.Services[i].Name;
        tempfilter.push(temp);
      }
    }
  }

  private LoadYears(tempfilter: CommonResponse[]) {
    let currentYear = new Date().getFullYear();

    //Default value in dropdown will be the current year
    for (let i = 0; i < 10; i++) {
      let temp = new CommonResponse();
      let year: number = currentYear - i;
      temp.Id = year;
      temp.Name = year.toString();
      tempfilter.push(temp);
    }
  }

  private monthNameToNum(monthname) {
    let allMonths = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var month = allMonths.indexOf(monthname);
    return month != -1 ? month + 1 : undefined;
    // return month ? month + 1 : 0;
  }

  private LoadMonths(tempfilter: CommonResponse[]) {
    let months: string[] = [];
    months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    //Default value in dropdown will be January
    for (let i = 0; i < months.length; i++) {
      let temp = new CommonResponse();
      temp.Name = months[i];

      tempfilter.push(temp);
    }
  }

  public ddlReport_Change(event) {
    const val = parseInt(event.target.value, 10);
    
    this.memberSearchResults = [];
    this.listReportData = [];
    this.IsNoDataFirstTable = false;
    this.IsNoDataSecondTable = false;
    this.IsNoDataServerTable = false;
    this.dataSource = new MatTableDataSource();
    this.seconddataSource = new MatTableDataSource();
    this.serverpagingSource = new MatTableDataSource();
    this.pagingreq = new RequestPagination();
    this.displayedColumns = [];
    this.seconddisplayedColumns = [];
    this.serverdisplayedColumns = [];
    this.FilterItems = [];
    this.FilterItems2 = [];
    this.FilterItems3 = [];
    this.ReportFormGroup.get("ddlFilter").clearValidators();
    this.ReportFormGroup.get("ddlFilter2").clearValidators();
    this.ReportFormGroup.get("ddlFilter3").clearValidators();
    this.ReportFormGroup.get("txtFilter").setValue("");
    this.ReportFormGroup.get("ddlFilter").setValue("");
    this.ReportFormGroup.get("ddlFilter2").setValue("");
    this.ReportFormGroup.get("ddlFilter3").setValue("");
    this.ReportFormGroup.get("ddlMemberSearch").setValue("");
    this.ReportTypeName = ReportOptions[val];
    this.IsShowingExport = false;
    this.IsCSVOnly = false;
    this.ReportFormGroup.get("ddlEmployer").clearValidators();
    this.ReportFormGroup.get("ddlEmployer").updateValueAndValidity({
      onlySelf: false,
    });
    this.ReportFormGroup.get("ddlFacility").clearValidators();
    this.ReportFormGroup.get("ddlFacility").updateValueAndValidity({
      onlySelf: false,
    });
    this.ReportFormGroup.get("ddlMemberSearch").clearValidators();
    this.ReportFormGroup.get("ddlMemberSearch").updateValueAndValidity({
      onlySelf: false,
    });
    this.IsEmpRequired = false;
    this.IsFacRequired = false;
    this.IsShowingServerDataPaging = false;
    this.IsShowingHeaderTable1 = false;
    this.IsShowingHeaderTable2 = false;
    this.Header1 = "";
    this.Header2 = "";
    switch (val) {
      case ReportOptions.MemberTally:
        this.ReportFormGroup.get("ddlEmployer").setValidators(
          Validators.required
        );
        this.ReportFormGroup.get("ddlEmployer").updateValueAndValidity({
          onlySelf: false,
        });
        this.IsEmpRequired = true;
        this.SetupFilter(FilterOptions.NoFilter);
        break;
      case ReportOptions.ListofContactsforMembers:
        this.IsCSVOnly = true;
        this.SetupFilter(FilterOptions.OnlyDLL, "Member Status");
        this.DefaultFilter = "All";
        this.LoadCommonData(val);
        this.IsShowingExport = true;
        this.ReportFormGroup.get("ddlEmployer").setValidators(
          Validators.required
        );
        this.ReportFormGroup.get("ddlEmployer").updateValueAndValidity({
          onlySelf: false,
        });
        this.IsEmpRequired = true;
        break;
      case ReportOptions.VisitLogEoMvisitationreport:
        this.SetupFilter(FilterOptions.OnlyDLLEom, "Service", "Year", "Month");
        // this.DefaultFilter = new Date().getFullYear().toString();
        // this.DefaultFilter2 = 'January';
        this.LoadCommonData(val);
        this.IsShowingExport = false;
        this.ReportFormGroup.get("ddlFilter").setValidators(
          Validators.required
        );
        this.ReportFormGroup.get("ddlFilter").updateValueAndValidity({
          onlySelf: false,
        });
        this.ReportFormGroup.get("ddlFilter2").setValidators(
          Validators.required
        );
        this.ReportFormGroup.get("ddlFilter2").updateValueAndValidity({
          onlySelf: false,
        });
        this.ReportFormGroup.get("ddlFilter3").setValidators(
          Validators.required
        );
        this.ReportFormGroup.get("ddlFilter3").updateValueAndValidity({
          onlySelf: false,
        });
        this.ReportFormGroup.get("ddlFacility").setValidators(
          Validators.required
        );
        this.ReportFormGroup.get("ddlFacility").updateValueAndValidity({
          onlySelf: false,
        });
        this.IsFacRequired = true;
        break;
      case ReportOptions.Facilitypaymentstatements:
        this.SetupFilter(FilterOptions.OnlyStartEndDate);
        this.IsShowingExport = true;
        break;
      case ReportOptions.MemberEmergencyContactHealthQuestions:
        this.ReportFormGroup.get("ddlMemberSearch").setValidators([
          Validators.required,
          FPValidators.resolvableAutocompleteOption,
        ]);
        this.ReportFormGroup.get("ddlMemberSearch").updateValueAndValidity({
          onlySelf: false,
        });
        this.SetupFilter(FilterOptions.OnlyAutoComplete, "Search Member");
        this.IsShowingServerDataPaging = true;
        break;
      default:
        this.SetupFilter(FilterOptions.NoFilter);
    }
  }

  private SetupFilter(
    fopt: FilterOptions,
    label: string = "",
    label2: string = "",
    label3: string = ""
  ) {
    this.IsFilter = true;
    this.IsFilterNormalDLL = false;
    this.IsFilterNormalDLL2 = false;
    this.IsFilterDate = false;
    this.IsFilterNormalTXT = false;
    this.IsFilterAutoMember = false;
    switch (fopt) {
      case FilterOptions.OnlyDLL:
        this.IsFilterNormalDLL = true;
        this.FilterLabel = label;
        break;
      case FilterOptions.OnlyStartEndDate:
        this.IsFilterDate = true;
        break;
      case FilterOptions.OnlyTXT:
        this.IsFilterNormalTXT = true;
        this.FilterLabel = label;
        break;
      case FilterOptions.NoFilter:
        this.IsFilterNormalDLL = false;
        this.IsFilterDate = false;
        this.IsFilterNormalTXT = false;
        this.IsFilter = false;
        this.IsFilterAutoMember = false;
        break;
      case FilterOptions.OnlyAutoComplete:
        this.IsFilterAutoMember = true;
        break;
      case FilterOptions.OnlyDLLEom:
        this.IsFilterNormalDLL = true;
        this.IsFilterNormalDLL2 = true;
        this.FilterLabel = label;
        this.FilterLabel2 = label2;
        this.FilterLabel3 = label3;
        break;
    }
  }

  public SetClassForControl(
    tmpformgroup: UntypedFormGroup,
    controlname: string
  ): string {
    if (
      tmpformgroup.get(controlname).invalid &&
      (tmpformgroup.get(controlname).dirty ||
        tmpformgroup.get(controlname).touched ||
        this.btnGenerateClicked)
    ) {
      return "form-control is-invalid";
    } else {
      return "form-control";
    }
  }

  public IsControlInvalid(
    tmpformgroup: UntypedFormGroup,
    controlname: string
  ): boolean {

    return (
      tmpformgroup.get(controlname).invalid &&
      (tmpformgroup.get(controlname).dirty ||
        tmpformgroup.get(controlname).touched ||
        this.btnGenerateClicked)
    );
  }

  public SetClassForAutoFill(
    tmpformgroup: UntypedFormGroup,
    controlname: string
  ): string {
    if (
      tmpformgroup.get(controlname).invalid &&
      (tmpformgroup.get(controlname).dirty ||
        tmpformgroup.get(controlname).touched ||
        this.btnGenerateClicked)
    ) {
      return "input-group is-invalid";
    } else {
      return "input-group";
    }
  }

  public btnExportExcel_Click() {
    const valstr = this.ReportFormGroup.get("ddlReport").value;
    const val = parseInt(valstr, 10);
    switch (val) {
      case ReportOptions.CancelledFPMemberswhohavevisited:
      case ReportOptions.ListofContactsforMembers:
      case ReportOptions.VisitLogEoMvisitationreport:
        this.ExportExcel(this.dataSource.data);
        break;
      case ReportOptions.MemberTally:
        this.ExportTwoExcel(this.dataSource.data, this.seconddataSource.data);
        break;
    }
  }

  private ExportExcel(json: any) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    var wscols = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const wcol = { wch: 20 };
      wscols.push(wcol);
    }
    worksheet["!cols"] = wscols;
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data: Blob = new Blob([excelBuffer], {
      type: CommonString.EXCEL_TYPE,
    });
    const reptype = FileSaver.saveAs(
      data,
      this.ReportTypeName +
        new Date().toDateString() +
        CommonString.EXCEL_EXTENSION
    );
  }

  private ExportTwoExcel(json1: any, json2: any) {
    const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json1);
    const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json2);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet1, data2: worksheet2 },
      SheetNames: ["data", "data2"],
    };
    var wscols1 = [];
    var wscols2 = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const wcol = { wch: 20 };
      wscols1.push(wcol);
    }
    for (let i = 0; i < this.seconddisplayedColumns.length; i++) {
      const wcol = { wch: 20 };
      wscols2.push(wcol);
    }
    worksheet1["!cols"] = wscols1;
    worksheet2["!cols"] = wscols2;
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data: Blob = new Blob([excelBuffer], {
      type: CommonString.EXCEL_TYPE,
    });
    const reptype = FileSaver.saveAs(
      data,
      this.ReportTypeName +
        new Date().toDateString() +
        CommonString.EXCEL_EXTENSION
    );
  }

  public ddlSearchMember_DisplayWithFn(option: any) {
    if (!option) {
      return "";
    }
    return option.LastName + ", " + option.FirstName;
  }

  public ddlSearchMemberAutoComplete_Selected(event) {
    this.MemberID = event.MemberId;
  }

  public paginatorChange(event: PageEvent) {
    this.pagingreq.PageNumber = event.pageIndex + 1;
    this.pagingreq.PageSize = event.pageSize;
    this.GetReportFSM_EF(ReportOptions.MemberEmergencyContactHealthQuestions);
  }

  public sortChange(sort: Sort) {
    const activeSort: string = sort.active.toLowerCase();
    this.pagingreq.OrderBy = activeSort;
    this.pagingreq.OrderByAsc = sort.direction === "asc" ? false : true;
    this.GetReportFSM_EF(ReportOptions.MemberEmergencyContactHealthQuestions);
  }

  public replace(content: string) {
    const val = parseInt(this.ReportFormGroup.get("ddlReport").value, 10);
    if (val === ReportOptions.MemberEmergencyContactHealthQuestions) {
      return content.replace(/ /g, "_");
    } else {
      return content;
    }
  }

  public ddlFacility_Change(event) {
    const rptype = parseInt(this.ReportFormGroup.get("ddlReport").value, 10);
    if(rptype === ReportOptions.MemberEmergencyContactHealthQuestions) {
      this.memberSearchResults = [];
      this.serverdisplayedColumns = [];
      this.listReportData = [];
      this.ReportFormGroup.get("ddlMemberSearch").setValue("");
    }
    if (rptype === ReportOptions.MemberVisitLiveFeed) {
      this.IsNoDataFirstTable = false;
      this.IsNoDataSecondTable = false;
      this.IsNoDataServerTable = false;
      this.dataSource = new MatTableDataSource();
      this.seconddataSource = new MatTableDataSource();
      this.serverpagingSource = new MatTableDataSource();
      this.displayedColumns = [];
      this.seconddisplayedColumns = [];
      this.serverdisplayedColumns = [];
    }
    if (rptype === ReportOptions.VisitLogEoMvisitationreport) {
      this.FilterItems = [];
      this.FilterItems2 = [];
      this.FilterItems3 = [];
      this.displayedColumns = [];
      this.dataSource = new MatTableDataSource();
      this.LoadCommonData(rptype);
    }
  }

  public formatObjectKeyToLabel(key: string): string {
    return key.replace(/([a-z])([A-Z])/g, "$1 $2").replace("?:", "?");
  }

  // used by keyvalue pipe to return null, thus preserving object sort order
  public sortNull() {}
}

export enum ReportOptions {
  MemberTally = 1,
  ListofContactsforMembers = 2,
  MemberVisitLiveFeed = 3,
  CancelledFPMemberswhohavevisited = 4,
  VisitLogEoMvisitationreport = 5,
  Facilitypaymentstatements = 6,
  MemberEmergencyContactHealthQuestions = 7,
}

export enum FilterOptions {
  NoFilter = 0,
  OnlyDLL = 1,
  OnlyTXT = 2,
  OnlyStartEndDate = 3,
  OnlyAutoComplete = 4,
  OnlyDLLEom = 5,
}
