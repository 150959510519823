import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConstant } from 'src/app/constant/apiconstant';
import { CommonResponse, DataResult, Product1, SearchPaginationRequest, SearchPaginationResult } from 'src/app/models';
import { HttpDAO } from './httpdao.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private httpDao: HttpDAO | null;

  constructor(httpClient: HttpClient) {
    this.httpDao = new HttpDAO(httpClient);
  }

  search(request: SearchPaginationRequest): Observable<DataResult<SearchPaginationResult<any>>> {
    return this.httpDao.postData(APIConstant.API_SEARCH_PRODUCTS, request);
  }

  getTypes(): Observable<DataResult<CommonResponse[]>> {
    return this.httpDao.getSingleData(APIConstant.API_GET_PRODUCT_TYPES);
  }

  getDetails(id: number): Observable<DataResult<Product1>> {
    return this.httpDao.getSingleData(APIConstant.API_GET_PRODUCT_DETAILS + id);
  }

}
