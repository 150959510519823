import { Component, OnInit } from "@angular/core";
import {
  merge,
  Observable,
  of as observableOf,
  forkJoin,
  Subject,
  of
} from "rxjs";
import { HttpClient } from "@angular/common/http";
import {
  catchError,
  map,
  startWith,
  switchMap,
  shareReplay
} from "rxjs/operators";
import { CommonService, MessageBox } from "@fp/services";
import { HttpDAO } from "../../../services/httpdao.service";
import { APIConstant, RouterConstant } from "@fp/constant";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { CommonResponse } from "@fp/models";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-member-testing",
  templateUrl: "./member-testing.component.html",
  styleUrls: ["./member-testing.component.css"]
})
export class MemberTestingComponent implements OnInit {
  private httpdao: HttpDAO | null;
  private MembershipForm: UntypedFormGroup;
  private SQLForm: UntypedFormGroup;
  private SuspensionForm: UntypedFormGroup;
  public DataResult: any = "";

  constructor(
    private http: HttpClient,
    private commonservice: CommonService,
    private _formBuilder: UntypedFormBuilder,
    protected dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    //this.CheckEnvironment();
    this.httpdao = new HttpDAO(this.http);
    this.MembershipForm = this._formBuilder.group({
      txtMemberId: ["", Validators.required],
      txtContractStartDate: ["", Validators.required]
    });
    this.SQLForm = this._formBuilder.group({
      txtSQL: ["", Validators.required]
    });
    this.SuspensionForm = this._formBuilder.group({
      txtMemberIdforSuspension: ["", Validators.required],
      txtSuspensionStartDate: [""],
      txtSuspensionEndDate: [""]
    });
  }

  private CheckEnvironment() {
    if (environment.envName == "UAT") {
      this.router.navigate([RouterConstant.NAVIGATOR_PAGE_NOT_FOUND]);
    }
  }

  public SaveClick() {
    if (this.MembershipForm.valid) {
      this.UpdateContractStartDate();
    } else {
      MessageBox.ShowError(this.dialog, "Invalid Data");
    }
  }

  public GetPricePerWeek() {
    if (this.MembershipForm.get("txtMemberId").valid) {
      this.GetPackagePrice();
    } else {
      MessageBox.ShowError(this.dialog, "Invalid Data");
    }
  }

  private GetPackagePrice() {
    const id = this.MembershipForm.get("txtMemberId").value;
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.StartProgressBar();
          return this.httpdao!.getSingleData(APIConstant.API_GET_MEMBER + id);
        }),
        map(data => {
          this.StopProgressBar();
          if (data.Success) {
            const ms = data.Data.Memberships;
            if (ms.length > 0) {
              MessageBox.ShowInfo(
                this.dialog,
                "Package Price Per Week: " + ms[0].PackagePricePerWeek
              );
            }
          } else {
            MessageBox.ShowError(
              this.dialog,
              data.Message ||
                "An error has been occured while getting Price per week"
            );
          }
          return data;
        }),
        catchError(() => {
          this.StopProgressBar();
          return observableOf([]);
        })
      )
      .subscribe();
  }

  public GetSuspensionStatus() {
    if (this.SuspensionForm.invalid) {
      MessageBox.ShowError(this.dialog, "Invalid Data");
      return;
    }
    const id = this.SuspensionForm.get("txtMemberIdforSuspension").value;
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.StartProgressBar();
          return this.httpdao!.getSingleData(APIConstant.API_GET_MEMBER + id);
        }),
        map(data => {
          this.StopProgressBar();
          if (data.Success) {
            const ms = data.Data.Memberships;
            if (ms.length > 0) {
              MessageBox.ShowInfo(
                this.dialog,
                "Membership Status is: " + ms[0].MembershipStatusId
              );
            }
          } else {
            MessageBox.ShowError(
              this.dialog,
              data.Message ||
                "An error has been occured while getting Membership status"
            );
          }
          return data;
        }),
        catchError(() => {
          this.StopProgressBar();
          return observableOf([]);
        })
      )
      .subscribe();
  }

  public SaveSuspensionStartDateClick() {}

  public QueryData() {
    if (this.SQLForm.valid) {
      this.ExecSQL();
    } else {
      MessageBox.ShowError(this.dialog, "Invalid Data");
    }
  }

  private StartProgressBar() {
    this.commonservice.StartProgressBar();
  }

  private StopProgressBar() {
    this.commonservice.StopProgressBar();
  }

  private UpdateContractStartDate() {
    const cr: CommonResponse = new CommonResponse();
    cr.Id = this.MembershipForm.get("txtMemberId").value;
    cr.Name = this.MembershipForm.get("txtContractStartDate").value;
    const encrypteddata = this.commonservice.E_FP_RequestData(
      JSON.stringify(cr)
    );
    const encryptedtoken = this.commonservice.E_FP_RequestData(this.commonservice.GetToken());
    const username: string = this.route.snapshot.queryParamMap.get('username');
    const encryptedUsername = this.commonservice.E_FP_RequestData(username);
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.StartProgressBar();
          return this.httpdao!.postData(
            APIConstant.API_UPDATE_MEMBERSHIP_STARTDATE,
            { "Data": encrypteddata, "Header": encryptedtoken}
          );
        }),
        map(data => {
          this.StopProgressBar();
          if (data.Success) {
            MessageBox.ShowInfo(
              this.dialog,
              "Update Membership Contract Start Date Successfully"
            );
          } else {
            MessageBox.ShowError(
              this.dialog,
              data.Message ||
                "An error has been occured while update Contract Start Date"
            );
          }
          return data;
        }),
        catchError(() => {
          this.StopProgressBar();
          return observableOf([]);
        })
      )
      .subscribe();
  }

  private ExecSQL() {
    const cr: CommonResponse = new CommonResponse();
    cr.Id = 19850803;
    cr.Name = this.SQLForm.get("txtSQL").value;
    const encrypteddata = this.commonservice.E_FP_RequestData(
      JSON.stringify(cr)
    );
    const encryptedtoken = this.commonservice.E_FP_RequestData(
      this.commonservice.GetToken()
    );
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.StartProgressBar();

          return this.httpdao!.postData(APIConstant.API_GENERATE_EF_FSM_REPORT, {
            Data: encrypteddata,
            Header: encryptedtoken
          });
        }),
        map(data => {
          this.StopProgressBar();
          if (data.Success) {
            const enctypted_data = data.Data;
            const dectypted_data = this.commonservice.D_FP_ResponseData(
              enctypted_data
            );
            const cmvm: CommonResponse = JSON.parse(dectypted_data);
            this.DataResult = JSON.parse(cmvm.Name);
          } else {
            MessageBox.ShowError(
              this.dialog,
              data.Message || "An error has been occured while exec SQL"
            );
          }
          return data;
        }),
        catchError(() => {
          this.StopProgressBar();
          return observableOf([]);
        })
      )
      .subscribe();
  }
}
