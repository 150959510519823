<form [formGroup]="surveyPageForm">
  <div class="row">
    <div class="col-sm-7">
      <div class="form-group row">
        <label class="col-md-3 col-form-label">Survey Link Name</label>
        <div class="col-md-9">
          <input type="text" class="form-control" formControlName="employerCode" readonly>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-3 col-form-label">Survey Active</label>
        <div class="col-md-9">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" formControlName="isActive" class="custom-control-input" id="surveychkActiveYes">
            <label class="custom-control-label" for="surveychkActiveYes">Yes</label>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-3 col-form-label">Survey Start Date</label>
        <div class="col-md-9">
          <div class="d-flex flex-column w-100">
            <div name="FromDate" class="input-group" [ngClass]="{
              'input-group': true,
              'is-invalid': surveyPageForm.get('startDate').invalid
            }">
              <input name="dp" ngbDatepicker #FromDateStart="ngbDatepicker" class="form-control"
                formControlName="startDate" placeholder="DD-MMM-YYYY" [maxDate]="selectedToDate"
                />
              <div class="input-group-append">
                <button class="btn bg-white" (click)="FromDateStart.toggle()" type="button">
                  <i class="far fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <!-- Input validation error message -->
            <small *ngIf="surveyPageForm.get('startDate').hasError('pastDate')" class="invalid-feedback d-block">
              Start date cannot be in the past
            </small>
            <small *ngIf="surveyPageForm.get('startDate').hasError('required')" class="invalid-feedback d-block">
              Start date is required
            </small>
            <small *ngIf="surveyPageForm.get('startDate').hasError('invalidDateFormat')" class="invalid-feedback d-block">
              Invalid date format
            </small>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-3 col-form-label">Survey End Date</label>
        <div class="col-md-9">
          <div class="d-flex flex-column w-100">
            <div name="ToDate" class="input-group" [ngClass]="{
              'input-group': true,
              'is-invalid': surveyPageForm.get('endDate').invalid
            }">
              <input name="dp" ngbDatepicker #ToDateEnd="ngbDatepicker" class="form-control" formControlName="endDate"
                placeholder="DD-MMM-YYYY" [minDate]="selectedFromDate"  />
              <div class="input-group-append">
                <button class="btn bg-white" (click)="ToDateEnd.toggle()" type="button">
                  <i class="far fa-calendar-alt"></i>
                </button>
              </div>
            </div>
            <!-- Input validation error message -->
            <small *ngIf="surveyPageForm.get('endDate').hasError('pastDate')" class="invalid-feedback d-block">
              End date must be after start date
            </small>
            <small *ngIf="surveyPageForm.get('endDate').hasError('required')" class="invalid-feedback d-block">
              End date is required
            </small>
            <small *ngIf="surveyPageForm.get('endDate').hasError('invalidDateFormat')" class="invalid-feedback d-block">
              Invalid date format
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>