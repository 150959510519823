import { CommonString, RouterConstant } from "src/app/constant";
import { AddManualVisitsComponent } from "./add-manual-visits/add-manual-visits.component";
import { PaymentRequestDetailsService } from "../../../../services/payment-request-details.service";
import { MessageBox } from "./../../../../services/common-dialog.service";
import { Component, OnInit, HostListener, ViewChild, Injector } from "@angular/core";
import {
  FacilityId,
  CreatePaymentRequest,
  ResultModel,
  NewPaymentRequestNote,
  User,
  PaymentCalculationMethod,
  PostPaymentRequest,
} from "../../../../models";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { FPAbstractComponent, FPEvent } from "@fp/components/base";
import { FpFileUploadR2Component } from "@fp/components/control";
import { FileNameService } from "@fp/services/file-name.service";
import {
  FacilityService,
  ServiceService,
  CommonService,
  PaymentService,
} from "@fp/services";
import { HttpClient } from "@angular/common/http";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { Utils, DateHelper, DataSourceHelper } from "@fp/helpers";
import { Router } from "@angular/router";
import { IfStmt } from "@angular/compiler";
import { MatSort } from "@angular/material/sort";

export class FpFileEvent extends FPEvent {
  file: File;
}

@Component({
  selector: "app-create-payment-request",
  templateUrl: "./create-payment-request.component.html",
  styleUrls: ["./create-payment-request.component.css"],
})
export class CreatePaymentRequestComponent extends FPAbstractComponent implements OnInit {
  public PaymentRequestNoteFormGroup = new UntypedFormGroup({
    paymentRequestNoteText: new UntypedFormControl(""),
  });
  public dtmatnotes: MatTableDataSource<
    NewPaymentRequestNote
  > = new MatTableDataSource<NewPaymentRequestNote>();
  private noteList: NewPaymentRequestNote[] = [];
  public displayedColumns: string[] = ["Username", "DateTimeDisplay", "Note"];
  public btnAddNoteClick = true;

  private uploadFileToS3: FpFileUploadR2Component;
  documentName = "No File Selected";
  RouterConstant = RouterConstant;
  file: any;
  dialogClose = false;
  facilityName: string;
  serviceOptions = [];
  serviceName: string;
  toDate: any;
  fromDate: any;
  visitAmount = 0;
  finalVisit = 0;
  paymentAmount = 0;
  finalPayment = 0;
  facilityId: number;
  incompleteMessage = "";
  searchChanged = false;
  invoiceNumber = "";
  isFinal = false;
  pageIndex = 1;
  description: string;
  selectedFacility = "";
  facilitySearchResult: FacilityId[] = [];
  isSearchingFacilityService = false;
  newHwmNumMem = 0;
  isThereCost = false;
  isNewHwm = false;
  tempVisitAmount: number;
  isLoading = false;
  currentUser = this.commonSvc.GetUser();
  page = "cPaymentReq";
  attachmentNameString = "";
  attachmentUuidString = "";
  messageRead = false;

  finalLoadingTable = false;  
  finalLoadingData = false;
  finalDataSource = [];
  finalPaymentLogDataSource: MatTableDataSource<PostPaymentRequest> = new MatTableDataSource<PostPaymentRequest>([]);

  CreatePaymentRequestDataToSend = new CreatePaymentRequest();
  uploadedInvoiceName: string[];
  uploadedInvoiceUuid: string[];
  uploadedManualVisitsAttchName: string[];
  uploadedManualVisitsAttchUuid: string[];
  uncheckedVisitCount: number;
  isFirstPR: boolean;
  PRCalculationMethod = "";
  perVisitCalcCheck: number;
  visitCost: number;

  dateChangeForm = new UntypedFormGroup({
    dateFrom: new UntypedFormControl(""),
    dateTo: new UntypedFormControl(""),
  });
  selectedFromDate: any;
  selectedToDate: any;
  fromDateMax = this.setTodayDate();

  CommonString = CommonString;  
  @ViewChild(MatSort) sort: MatSort;
  displayedColumnsTable: string[] = [
    "Service",
    "DateCreated",
    "VisitCount",
    "DollarAmount",
    "PRStatus",
    "PaymentCalculationMethod"
  ];
  checkSelectedDate: Date;
  isDateSelected = false;

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    private prDetailsSvc: PaymentRequestDetailsService,
    private modalSvc: NgbModal,
    private fileNameSvc: FileNameService,
    private facilitySvc: FacilityService,
    public router: Router,
    private serviceSvc: ServiceService,
    private paymentSvc: PaymentService,
    private commonSvc: CommonService,
    private http: HttpClient
  ) {
    super(injector)
    this.uploadFileToS3 = new FpFileUploadR2Component(
      this.modalSvc,
      this.dialog,
      this.http,
      this.fileNameSvc,
      this.commonSvc
    );
  }

  ngOnInit() {
    this.commonSvc.Header.title = "Create Payment Request"
    this.file = null;
    console.log(this.facilitySearchResult.length);  

    // set initial focus to facility field
    // document.getElementById("facilityName").focus();
    // resolved the matAutocomplete error
    document.getElementById("facilityName");
    // initialising values and associating to clones in service
    this.prDetailsSvc.$currentFacilityName.subscribe(
      (facilityName) => (this.facilityName = facilityName)
    );
    this.prDetailsSvc.$currentServiceName.subscribe(
      (serviceName) => (this.serviceName = serviceName)
    );
    this.prDetailsSvc.$currentFromDate.subscribe(
      (fromDate) => (this.fromDate = fromDate)
    );
    this.prDetailsSvc.$currentToDate.subscribe(
      (toDate) => (this.toDate = toDate)
    );
    this.prDetailsSvc.$currentVisitAmount.subscribe((visitAmount) => {
      this.visitAmount = visitAmount;
    });
    this.prDetailsSvc.$currentPaymentAmount.subscribe(
      (paymentAmount) => (this.paymentAmount = paymentAmount)
    );
    this.prDetailsSvc.$currentAdditionalVisits.subscribe(
      (additionalVisits) => (this.tempVisitAmount = additionalVisits)
    );
    this.prDetailsSvc.$currentNewHwmMem.subscribe(
      (newHwmNumMem) => (this.newHwmNumMem = newHwmNumMem)
    );
    this.prDetailsSvc.$currentCostFlag.subscribe(
      (costFlag) => (this.isThereCost = costFlag)
    );
    this.prDetailsSvc.$currentNewHwmFlag.subscribe(
      (hwmFlag) => (this.isNewHwm = hwmFlag)
    );
    this.prDetailsSvc.$currentFinalPaymentAmount.subscribe(
      (finalPayment) => (this.finalPayment = finalPayment)
    );
    //Reset file data
    this.fileNameSvc.resetFileData();
    //Need to fix this
    this.fileNameSvc.$currentUploadedFileUuid.subscribe(
      (uploadedInvoiceUuid) => (this.uploadedInvoiceUuid = uploadedInvoiceUuid)
    );
    // this.fileNameSvc.$currentUploadedFileName.subscribe(
    //   uploadedInvoiceName => (this.uploadedInvoiceName = uploadedInvoiceName)
    // );
    this.fileNameSvc.$currentChildUploadedFileUuid.subscribe(
      (manualAttchUuid) =>
        (this.uploadedManualVisitsAttchUuid = manualAttchUuid)
    );
    this.fileNameSvc.$currentChildUploadedFileName.subscribe(
      (manualAttchName) =>
        (this.uploadedManualVisitsAttchName = manualAttchName)
    );
    this.prDetailsSvc.$currentUncheckedVisits.subscribe(
      (uncheckedVisits) => (this.uncheckedVisitCount = uncheckedVisits)
    );
    this.prDetailsSvc.$currentIsFirstPR.subscribe(
      (isFirstPr) => (this.isFirstPR = isFirstPr)
    );
    this.prDetailsSvc.$currentVisitCost.subscribe(
      (visitCost) => (this.perVisitCalcCheck = visitCost)
    );
    this.prDetailsSvc.$currentPRCalculationMethod.subscribe(
      (prCalcMethod) => (this.PRCalculationMethod = prCalcMethod)
    );

    this.prDetailsSvc.$currentInitialLoadingTable.subscribe((finalLoadingTable) => {
      this.finalLoadingTable = finalLoadingTable;
    });  
    
    this.prDetailsSvc.$currentInitialLoadingData.subscribe((finalLoadingData) => {
      this.finalLoadingData = finalLoadingData;
    });  

    this.prDetailsSvc.$currentInitialDataSource.subscribe((finalDataSource) => {
      this.finalDataSource = finalDataSource;
    }); 

    this.prDetailsSvc.$currentInitialPaymentLogDataSource.subscribe((finalPaymentLogDataSource) => {
      this.finalPaymentLogDataSource = finalPaymentLogDataSource;      
    });    

    // Re-initializing the visits and payment amounts to zero.
    this.finalLoadingTable = false;    
    this.finalDataSource = [];
    this.finalPaymentLogDataSource = new MatTableDataSource<PostPaymentRequest>([])
    this.visitAmount = 0;
    this.finalVisit = 0;
    this.paymentAmount = 0;
    this.finalPayment = 0;
    this.serviceName = "";
    this.facilityName = "";
    this.toDate = "";
    this.fromDate = "";
    this.prDetailsSvc.reinitialiseValues();
  }

  ngAfterViewChecked() {
    if(this.finalLoadingData){ 
      if(this.finalDataSource && this.finalDataSource.length > 0) {
        this.finalPaymentLogDataSource.sortingDataAccessor = DataSourceHelper.localeLowerCaseSortingDataAccessor;    
        this.finalPaymentLogDataSource.sort = this.sort;
        this.finalLoadingData = false;      
      }       
    }    
  }
  
  // creating a function to attach invoices by assigning file metadata to 'fileArray'
  attachInvoice($event) {
    console.log("What is in event? ", $event);
    const headerString = "Uploading an Additional Invoice";
    const bodyString =
      "Only one invoice can be uploaded per payment request." +
      " Uploading another invoice will result in discarding the existing one." +
      " Do you wish to continue with this operation?";
    if (this.documentName !== "No File Selected") {
      MessageBox.ShowCancelContinue(
        this.dialog,
        headerString,
        bodyString
      ).subscribe((dialogResult) => {
        if (dialogResult.result.toLowerCase() === DialogResult.Continue) {
          this.file = (<HTMLInputElement>event.target).files[0];
          this.documentName = this.file.name;
          this.uploadedInvoiceName[0] = this.file.name;
          console.log(
            "Is the invoice name correct: this.documentName ",
            this.documentName
          );
          console.log(
            "Is the invoice name correct: this.file ",
            this.file.name
          );
        }
      });
    } else {
      this.file = (<HTMLInputElement>event.target).files[0];
      this.documentName = this.file.name;
    }
  }

  // removing the metadata for file from 'fileArray'
  removeInvoice() {
    this.file = null;
    this.documentName = "No File Selected";
  }

  // function created to open the dialog box
  openDialog() {
    // settings for dialog box
    const dialogRef = this.dialog.open(AddManualVisitsComponent, {
      height: "550px",
      width: "800px",
    });

    // functions to complete after dialog box is closed
    dialogRef.afterClosed().subscribe((result) => {
      var isFinalCalc = false;
      this.prDetailsSvc.calculateCost(
        this.facilityName,
        this.serviceName,
        this.fromDate,
        this.toDate,
        this.visitAmount,
        this.pageIndex,
        isFinalCalc
      );
      document.getElementById("finalPayment").focus();
      this.finalVisit = this.visitAmount;
    });

    // populates the values inside dialog box
    this.autoPopulateValues();
  }

  // function for populating dialog box values
  autoPopulateValues() {
    this.prDetailsSvc.populateValues(
      this.facilityName,
      this.serviceName,
      this.fromDate,
      this.toDate
    );
  }

  findFacilityByName(event: KeyboardEvent) {
    // this excludes functionality for keycodes for arrow keys (37, 38, 39, 40)
    if (event.keyCode < 41 && event.keyCode > 36) {
      return;
    } else {
      this.isSearchingFacilityService = true;
      this.selectedFacility = (<HTMLInputElement>(
        document.getElementById("facilityName")
      )).value;
      this.InvokeGet(
        this.facilitySvc.findByName(this.selectedFacility),
        (data: FacilityId[]) => {
          // console.log(data);
          this.isSearchingFacilityService = false;
          this.facilitySearchResult = data;
        }
      );
      this.findVisitAmount();
    }
  }

  findServiceFromFacilityId(facilityId) {
    this.isSearchingFacilityService = true;
    this.InvokeGet(
      this.serviceSvc.getServicesByFacility(facilityId),
      (data: any) => {
        const serviceList = data.Data;
        // console.log("All services: ", serviceList);
        // console.log("length services: ", serviceList.length);
        this.serviceOptions = [];
        for (let i = 0; i < serviceList.length; i++) {
          if (serviceList[i].ServiceStatusName == "Active") {
            this.serviceOptions.push(serviceList[i].Name);
          }
        }
        if (this.serviceOptions.length == 0) {
          this.serviceOptions.push(
            "No active services found for this facility"
          );
        }
        this.isSearchingFacilityService = false;
      }
    );
    this.findVisitAmount();
  }

  // function to calculate visit amount based on facility, service, to and from date
  findVisitAmount() {
    // If the first of the month is selected, grab the last date of the month as the end date.
    let checkMonth = this.dateChangeForm.value.dateFrom.month;
    let checkYear = this.dateChangeForm.value.dateFrom.year;
    let checkDay = this.dateChangeForm.value.dateFrom.day;    
   

    if (checkMonth && checkYear && checkDay) {
      this.checkSelectedDate = new Date(checkYear, checkMonth, checkDay);

      if (this.checkSelectedDate) {
        if (!(this.checkSelectedDate.getFullYear() == checkYear && this.checkSelectedDate.getMonth() == checkMonth && this.checkSelectedDate.getDate() == checkDay)) {
          console.log("date1",this.dateChangeForm.value.dateFrom)
          this.isDateSelected = false;
        }else{
          this.isDateSelected = true;
          console.log("date2",this.dateChangeForm.value.dateFrom)
        }
      }
    }
    this.setDateToLastMonthDay();
    this.messageRead = false;
    //Set date ranges for FROM and TO date-pickers
    this.selectedFromDate = this.dateChangeForm.value.dateFrom;
    this.selectedToDate = this.dateChangeForm.value.dateTo;

    if (
      this.selectedToDate.year < this.setTodayDate().year ||
      (this.selectedToDate.year === this.setTodayDate().year &&
        (this.selectedToDate.month < this.setTodayDate().month ||
          (this.selectedToDate.month === this.setTodayDate().month &&
            this.selectedToDate.day < this.setTodayDate().day)))
    ) {
      this.fromDateMax = this.selectedToDate;
    } else {
      this.fromDateMax = this.setTodayDate();
    }

    this.facilityName = (<HTMLInputElement>(
      document.getElementById("facilityName")
    )).value;
    this.serviceName = (<HTMLInputElement>(
      document.getElementById("serviceName")
    )).value;
    this.fromDate = (<HTMLInputElement>(
      document.getElementById("fromDate")
    )).value;
    this.toDate = (<HTMLInputElement>document.getElementById("toDate")).value;

    if (
      this.facilityName !== "" &&
      this.serviceName !== "" &&
      this.fromDate !== "" &&
      this.toDate !== ""
    ) {
      // executes visit and cost calcuation if variables are not null
      this.prDetailsSvc.calculateVisits(
        this.facilityName,
        this.serviceName,
        this.fromDate,
        this.toDate,
        this.pageIndex,
        this.currentUser
      );
      this.incompleteMessage = "";
      this.finalVisit = this.visitAmount;
    }
    this.searchChanged = true;
  }

  manualTotalPayChange() {
    this.finalPayment = +(<HTMLInputElement>(
      document.getElementById("finalPayment")
    )).value.replace("$", "").replace(",", "");
    this.searchChanged = false;
  }

  perVisitCalc() {
    var finalAmountToPay = (<HTMLInputElement>(
      document.getElementById("finalPayment")
    )).value;
    var finalAmountToPayNum = +finalAmountToPay
      .replace("$", "")
      .replace(",", "");
    var totalVisitsForPayment = +(<HTMLInputElement>(
      document.getElementById("finalVisit")
    )).value;
    this.prDetailsSvc.perVisitCalc(finalAmountToPayNum, totalVisitsForPayment);
  }

  // creates a final changes if the recalculate button is pressed
  finalChange() {
    this.finalVisit = +(<HTMLInputElement>document.getElementById("finalVisit"))
      .value;
    var isFinalCalc = true;
    this.prDetailsSvc.calculateCost(
      this.facilityName,
      this.serviceName,
      this.fromDate,
      this.toDate,
      this.finalVisit,
      this.pageIndex,
      isFinalCalc
    );
  }

  InvokeGet(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError((e) => {
          throw e;
        })
      )
      .subscribe(
        (res) => {
          handleResultCallback(res);
        },
        (err) => {
          this.errorHandler(err);
        }
      );
  }

  private errorHandler(error, message: string = null) {
    console.error(error);
  }

  submitButton() {
    // console.log(this.uncheckedVisitCount);
    if (this.uncheckedVisitCount > 0 && this.messageRead === false) {
      MessageBox.ShowOKCustom(
        this.dialog,
        "Warning: Duplicate Visits May Exist",
        `This service has ${this.uncheckedVisitCount} visits that have not yet been checked for\
        duplicates and are not included in the 'Visits in System' total.\
        Recommended action is to create this payment request once all visits have been checked.`,
        "OK",
        "450px"
      );
      this.messageRead = true;
    } else {
      this.isLoading = true;
      this.invoiceNumber = (<HTMLInputElement>(
        document.getElementById("invoiceNumber")
      )).value;
      var finalVisitString: string;
      finalVisitString = (<HTMLInputElement>(
        document.getElementById("finalVisit")
      )).value;
      // console.log(finalVisitString);
      this.finalVisit = +finalVisitString;

      this.serviceName = (<HTMLInputElement>(
        document.getElementById("serviceName")
      )).value;

      const missingValues = [];
      if (this.facilityName === "" || this.facilityName === null) {
        missingValues.push(" Facility Name");
      }
      if (this.serviceName === "" || this.serviceName === null) {
        missingValues.push(" Service Name");
      }
      if (this.fromDate === "" || this.fromDate === null) {
        missingValues.push(" Start Date");
      }
      if (this.toDate === "" || this.toDate === null) {
        missingValues.push(" End Date");
      }
      if (this.finalVisit === null || this.finalVisit < 0) {
        missingValues.push(" Visit Count");
      }
      if (finalVisitString === "") {
        missingValues.push("Visit Count");
      }
      if (
        (this.finalPayment <= 0 || this.finalPayment === null) &&
        this.isThereCost
      ) {
        missingValues.push(" Final Cost Amount");
      }
      if (missingValues.length > 0) {
        MessageBox.ShowInfo(
          this.dialog,
          `Unable to submit payment request; ${missingValues} missing.`
        );
        // Kill the Mat Spinner
        this.isLoading = false;
      } else {
        const tempFinalPayment =
          "" +
          (<HTMLInputElement>document.getElementById("finalPayment")).value;
        this.finalPayment = +tempFinalPayment.replace("$", "").replace(",", "");
        for (let i = 0; i < this.uploadedManualVisitsAttchName.length; i++) {
          this.attachmentNameString +=
            this.uploadedManualVisitsAttchName[i] + ",";
          this.attachmentUuidString +=
            this.uploadedManualVisitsAttchUuid[i] + ",";
        }
        if (this.file === undefined || this.file === null) {
          this.CreatePaymentRequestDataToSend.FacilityName = this.facilityName;
          this.CreatePaymentRequestDataToSend.ServiceName = this.serviceName;
          this.CreatePaymentRequestDataToSend.ToDate = this.toDate;
          this.CreatePaymentRequestDataToSend.FromDate = this.fromDate;
          this.CreatePaymentRequestDataToSend.VisitInSystem = this.visitAmount;
          this.CreatePaymentRequestDataToSend.TotalVisits = this.finalVisit;
          this.CreatePaymentRequestDataToSend.SystemCalculatedAmount = +this
            .paymentAmount;
          this.CreatePaymentRequestDataToSend.FinalAmount = +this.finalPayment;
          this.CreatePaymentRequestDataToSend.InvoiceNumber = this.invoiceNumber;
          this.CreatePaymentRequestDataToSend.CreatedBy = this.currentUser;
          this.CreatePaymentRequestDataToSend.InvoiceName = "";
          this.CreatePaymentRequestDataToSend.InvoiceUuid = this.uploadedInvoiceUuid[0];
          this.CreatePaymentRequestDataToSend.AttachmentsName = this.attachmentNameString.slice(
            0,
            -1
          ); //slice() to get rid of the appended comma at the end
          this.CreatePaymentRequestDataToSend.AttachmentsUuid = this.attachmentUuidString.slice(
            0,
            -1
          ); //slice() to get rid of the appended comma at the end
          this.CreatePaymentRequestDataToSend.NotesInformation = this.noteList;
          this.CreatePaymentRequestDataToSend.HWM_NumberMemberships = this.newHwmNumMem;
          this.CreatePaymentRequestDataToSend.HWM_ChangedFromLastMonth = this.isNewHwm;
          this.CreatePaymentRequestDataToSend.AdditionalManualVisits = this.tempVisitAmount;
          // console.log(this.CreatePaymentRequestDataToSend);
          this.InvokeGet(
            this.paymentSvc.submitPaymentRequest(
              this.CreatePaymentRequestDataToSend
            ),
            (data: ResultModel) => {
              if (data.Success) {
                this.isLoading = false;
                // console.log(data);
                MessageBox.ShowOKCustom(
                  this.dialog,
                  'Information',
                  'Success! Your payment request has been created!'
                ).subscribe((dialogResult) => {
                  if (dialogResult.result.toLowerCase() === DialogResult.Ok) {
                    this.fileNameSvc.resetFileData();
                    window.location.reload();
                  }
                });
              } else {
                this.isLoading = false;
                this._logger.error(data);
                MessageBox.ShowError(
                  this.dialog,
                  "Sorry, something went wrong. Let's try that again."
                ).subscribe((res) => {
                  if (res.result.toLowerCase() === DialogResult.Ok) {
                    window.location.reload();
                  }
                });
              }
            }
          );
        } else {
          const checkNecessary = false;
          this.InvokeGet(
            this.uploadFileToS3.fileEventUsingFile(
              this.file,
              checkNecessary,
              this.page
            ),
            (uploadResult: boolean) => {
              if (uploadResult == true) {
                this.CreatePaymentRequestDataToSend.FacilityName = this.facilityName;
                this.CreatePaymentRequestDataToSend.ServiceName = this.serviceName;
                this.CreatePaymentRequestDataToSend.ToDate = this.toDate;
                this.CreatePaymentRequestDataToSend.FromDate = this.fromDate;
                this.CreatePaymentRequestDataToSend.VisitInSystem = this.visitAmount;
                this.CreatePaymentRequestDataToSend.TotalVisits = this.finalVisit;
                this.CreatePaymentRequestDataToSend.SystemCalculatedAmount = +this
                  .paymentAmount;
                this.CreatePaymentRequestDataToSend.FinalAmount = +this
                  .finalPayment;
                this.CreatePaymentRequestDataToSend.InvoiceNumber = this.invoiceNumber;
                this.CreatePaymentRequestDataToSend.CreatedBy = this.currentUser;
                this.CreatePaymentRequestDataToSend.InvoiceName = this.file.name;
                this.CreatePaymentRequestDataToSend.InvoiceUuid = this.uploadedInvoiceUuid[0];
                this.CreatePaymentRequestDataToSend.AttachmentsName = this.attachmentNameString.slice(
                  0,
                  -1
                ); //slice() to get rid of the appended comma at the end
                this.CreatePaymentRequestDataToSend.AttachmentsUuid = this.attachmentUuidString.slice(
                  0,
                  -1
                ); //slice() to get rid of the appended comma at the end
                this.CreatePaymentRequestDataToSend.NotesInformation = this.noteList;
                this.CreatePaymentRequestDataToSend.HWM_NumberMemberships = this.newHwmNumMem;
                this.CreatePaymentRequestDataToSend.HWM_ChangedFromLastMonth = this.isNewHwm;
                this.CreatePaymentRequestDataToSend.AdditionalManualVisits = this.tempVisitAmount;
                // console.log(this.CreatePaymentRequestDataToSend);
                this.InvokeGet(
                  this.paymentSvc.submitPaymentRequest(
                    this.CreatePaymentRequestDataToSend
                  ),
                  (data: ResultModel) => {
                    if (data.Success) {
                      this.isLoading = false;
                      // console.log(data);
                      MessageBox.ShowOKCustom(
                        this.dialog,
                        'Information',
                        'Success! Your payment request has been created!'
                      ).subscribe((dialogResult) => {
                        if (dialogResult.result.toLowerCase() === DialogResult.Ok) {
                          this.fileNameSvc.resetFileData();
                          window.location.reload();
                        }
                      });
                    } else {          
                      this.isLoading = false;
                      this._logger.error(data);         
                      MessageBox.ShowError(
                        this.dialog,
                        "Sorry, something went wrong. Let's try that again."
                      ).subscribe((res) => {
                        if (res.result.toLowerCase() === DialogResult.Ok) {
                          window.location.reload();
                        }
                      });
                    }
                  }
                );
              } else {
                this.isLoading = false;
                // console.log(uploadResult);
                MessageBox.ShowError(
                  this.dialog,
                  `The file "${this.uploadedInvoiceName[0]}" failed to upload. Please try again.`
                );
              }
            }
          );
        }
      }
    }
  }

  /**
   * Clears all the text box and dropdown values on the Create PR page.
   */
  reinitialise() {
    this.router.navigate(["/dashboard/viewpaymentrequests"]);
    this.prDetailsSvc.reinitialiseValues();
  }

  private setTodayDate() {
    let todayDate = new Date();
    let todayYear = todayDate.getFullYear();
    let todayMonth = todayDate.getMonth() + 1;
    let todayDay = todayDate.getDate();
    let todayTime =
      todayDate.getHours() +
      ":" +
      todayDate.getMinutes() +
      ":" +
      todayDate.getSeconds();
    let today = {
      year: todayYear,
      month: todayMonth,
      day: todayDay,
      time: todayTime,
    };
    return today;
  }

  private ConvertToLocalTime(d: Date) {
    const localdate: Date = new Date(d);
    return DateHelper.format(localdate, "DD-MMM-YYYY HH:mm"); //Without seconds required here
  }

  addPaymentRequestNotes() {
    const item: NewPaymentRequestNote = new NewPaymentRequestNote();
    item.DateTime = this.ConvertToLocalTime(new Date());
    item.Note = this.PaymentRequestNoteFormGroup.get(
      "paymentRequestNoteText"
    ).value;
    item.User = this.currentUser;
    this.noteList.unshift(item);

    this.dtmatnotes = new MatTableDataSource<NewPaymentRequestNote>(
      this.noteList
    );
    this.PaymentRequestNoteFormGroup.get("paymentRequestNoteText").setValue("");
    this.btnAddNoteClick = true;
  }

  noteInputs(event: KeyboardEvent) {
    if (
      this.PaymentRequestNoteFormGroup.get("paymentRequestNoteText").value
        .length > 0
    ) {
      this.btnAddNoteClick = false;
    } else {
      this.btnAddNoteClick = true;
    }
    // adds non-empty notes when "enter" key is pressed
    if (
      event.keyCode === 13 &&
      this.PaymentRequestNoteFormGroup.get(
        "paymentRequestNoteText"
      ).value.replace(/\s/g, "") !== ""
    ) {
      this.addPaymentRequestNotes();
    }
  }
  /**
   * Sets the "DateTo" DatePicker value to the last day of the month,
   * if the value for the "DateFrom" picker is set to the first of the month,
   * and the "DateTo" picker value isn't set.
   */
  private setDateToLastMonthDay() {
    if (
      this.dateChangeForm.value.dateFrom.day == 1 &&
      this.dateChangeForm.value.dateTo == ""
    ) {
      var year = this.dateChangeForm.value.dateFrom.year;
      var month = this.dateChangeForm.value.dateFrom.month;
      this.dateChangeForm.controls.dateTo.setValue({
        year: year,
        month: month,
        day: Utils.getLastDateOfMonthFromInts(year, month),
      });
    }
  }
}