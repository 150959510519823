<div class="form-group row">
  <label [attr.for]="getName(control)" class="col-md-3 col-lg-2 col-form-label">{{ name }}<span class="text-danger">{{ mandatory ? '*' : '' }}</span></label>
  <div class="col-md-8 col-lg-5">
      <div class="d-flex flex-column w-100">
        <select 
            class="form-control"
            [attr.id]="getName(control)" 
            [(ngModel)]="value"
            (blur)="onTouched()" 
            [ngClass]="checkValidate() ? 'form-control is-invalid':'form-control'"
        >
              <option [ngValue]="null">Please select a value</option>
              <option *ngFor="let d of data" [ngValue]="d.Id">{{ d.Name }}</option>
          </select>
          <small *ngIf="checkValidate()" class="invalid-feedback d-block">
                {{ name }} is required
            </small>
      </div>
  </div>
</div>