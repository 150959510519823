import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogResult } from '@fp/components/common-dialog/common-dialog.component';
import { Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService, HttpDAO, MessageBox } from '@fp/services';
import { APIConstant, RouterConstant, CommonString } from '@fp/constant';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { forkJoin, of as observableOf } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CommonResponse } from '@fp/models';
import { FpFileData } from '../control';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ListMaterialModel, MaterialModel } from '@fp/models/material.model';
import { DateHelper } from '@fp/helpers';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ERole } from '@fp/enums/role.enum';
import { ActivatedRoute } from '@angular/router';
import { RolePermissionService } from '@fp/services/role-permission.service';

export interface PeriodicElement {
  Name: string;
  Type: string;
  Recipient: string;
  Description: string;
  DateUploaded: string;
  Link: string;
  Action: string;
}

@Component({
  selector: 'app-training-material',
  templateUrl: './training-material.component.html',
  styleUrls: ['./training-material.component.css']
})
export class TrainingMaterialComponent implements OnInit {
  private httpdao: HttpDAO | null;
  public MaterialFormGroup: UntypedFormGroup;
  public MaterialRecipient: CommonResponse[] = [];
  public MaterialRecipientSelected: CommonResponse[] = [];
  public Materialtype: CommonResponse[] = [];
  public btnSubmitClicked: boolean = false;
  private uploadlink: string = '';
  public LstMaterialDocument: MaterialModel[] = [];
  public dtmatMD: MatTableDataSource<MaterialModel> = new MatTableDataSource<MaterialModel>();
  public YoutubeSrc: SafeResourceUrl;
  public YoutubeName: string;
  private openModal: NgbModalRef;
  @ViewChild('ContentYouTube') popupYouTubecontent: ElementRef;
  @ViewChild(MatSort) sort: MatSort;

  public displayedColumns: string[] = ['Name', 'MaterialTypeName', 'MaterialRecipientName', 'Description', 'DateUpload', 'Link', 'Action'];

  constructor(private _formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private comsvr: CommonService,
    protected dialog: MatDialog,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private rolepermission: RolePermissionService, ) { }

  ngOnInit() {
    this.httpdao = new HttpDAO(this.http);
    this.MaterialFormGroup = this._formBuilder.group({
      ddlRecipient: [''],
      ddlMaterialType: ['', Validators.required],
      txtNameDocVideo: ['', Validators.required],
      txtDescription: [''],
      txtYouTube: [''],
      txtDocFile: [''],
      ddlRecipientFilter: [''],
      ddlMaterialTypeFilter: [''],
    });
    this.InitForm();
  }

  private InitForm() {
    this.MaterialRecipientSelected = [];
    this.LoadCommonData();
    this.MaterialFormGroup.get('ddlRecipient').setValue('');
    this.MaterialFormGroup.get('ddlMaterialType').setValue('');
    this.MaterialFormGroup.get('ddlRecipientFilter').setValue('');
    this.MaterialFormGroup.get('ddlMaterialTypeFilter').setValue('');
  }

  private LoadCommonData() {
    this.comsvr.StartProgressBar();

    forkJoin(
      this.httpdao!.getSingleData(APIConstant.API_GET_COMMON_DATA + '50'),
      this.httpdao!.getSingleData(APIConstant.API_GET_COMMON_DATA + '51')
    ).pipe(
      map(([mr, mt]) => {
        return { mr, mt };
      }),
      catchError(() => {
        this.comsvr.StopProgressBar();
        return observableOf(null);
      })
    ).subscribe(res => {
      this.MaterialRecipient = res.mr.Data;
      this.Materialtype = res.mt.Data;
      const r = this.comsvr.GetSelectedRole();
      if (r === ERole.Employer_Facilitator) {
        this.LoadMaterialData(1, 0);
      } else if (r === ERole.Facility_Staff_Member) {
        this.LoadMaterialData(2, 0);
      } else {
        this.LoadMaterialData(0, 0);
      }
      this.comsvr.StopProgressBar();
    });
  }

  private LoadMaterialData(repid: number, martypeid: number, controlprogressbar: boolean = false) {
    if (controlprogressbar) {
      this.comsvr.StartProgressBar();
    }

    forkJoin(
      this.httpdao!.getSingleData(APIConstant.API_GET_MATERIAL(repid, martypeid))
    ).pipe(
      map(([lstmt]) => {
        return { lstmt };
      }),
      catchError(() => {
        this.comsvr.StopProgressBar();
        return observableOf(null);
      })
    ).subscribe(res => {
      this.LstMaterialDocument = res.lstmt.Data;
      this.FormatMaterialDisplay();
      if (controlprogressbar) {
        this.comsvr.StopProgressBar();
      }
    });
  }

  private FormatMaterialDisplay() {
    for (let i = 0; i < this.LstMaterialDocument.length; i++) {
      this.LstMaterialDocument[i].MaterialRecipientName = this.LstMaterialDocument[i].MaterialRecipientId === 1 ? 'EF' : 'FSM';
      this.LstMaterialDocument[i].MaterialTypeName = this.LstMaterialDocument[i].MaterialTypeId === 1 ? 'Document' : 'Video';
      this.LstMaterialDocument[i].DateUploadDisplay = this.ConvertToLocalTime(this.LstMaterialDocument[i].DateUpload);
    }
    this.dtmatMD = new MatTableDataSource<MaterialModel>(this.LstMaterialDocument);
    this.dtmatMD.sort = this.sort;
  }

  private ConvertToLocalTime(d: Date): string {
    const localdate: Date = new Date(d.toString() + 'Z');
    return DateHelper.format(localdate, 'DD-MMM-YYYY');
  }

  public FilterChange() {
    const RecipientVal = this.MaterialFormGroup.get('ddlRecipientFilter').value === '' ? 0 : this.MaterialFormGroup.get('ddlRecipientFilter').value;
    const MaterialVal = this.MaterialFormGroup.get('ddlMaterialTypeFilter').value === '' ? 0 : this.MaterialFormGroup.get('ddlMaterialTypeFilter').value;
    const r = this.comsvr.GetSelectedRole();
    if (r === ERole.Employer_Facilitator) {
      this.LoadMaterialData(1, MaterialVal, true);
    } else if (r === ERole.Facility_Staff_Member) {
      this.LoadMaterialData(2, MaterialVal, true);
    } else {
      this.LoadMaterialData(RecipientVal, MaterialVal, true);
    }
  }

  public ddlRecipient_Select(event) {
    const id: number = parseInt(event.target.value, 10);
    this.SelectMR(id);
  }

  private SelectMR(id: number) {
    if (id === null || id === undefined || id === 0) {
      return;
    }
    let smr = this.MaterialRecipient.find(item => item.Id === id);
    this.MaterialRecipientSelected.push(smr);
    this.MaterialFormGroup.get('ddlRecipient').setValue('');
  }

  public SetMRDisabled(nt: CommonResponse) {
    let isBool = this.MaterialRecipientSelected.some(nts => nts.Id === nt.Id);
    return isBool;
  }

  public UnsetMRSelected(nt: CommonResponse) {
    this.RemoveItemFromArray(this.MaterialRecipientSelected, nt);
  }

  private RemoveItemFromArray(arr: Array<any>, item: any) {
    var index = arr.indexOf(item);
    if (index > -1) {
      arr.splice(index, 1);
    }
  }

  public txtDocUpload_UploadCompleted(event) {
    const fobj: FpFileData = event;
    this.MaterialFormGroup.get('txtDocFile').setValue(fobj.originfilename);
    this.uploadlink = fobj.filedata.Location;
  }

  public txtDocUpload_Selected(event) {
    const exts = '.pdf,.doc,.docx,.xls,.xlsx,.csv';
    const ext = event.file.name.split('.').pop();
    if (exts.indexOf(ext) === -1) {
      event.cancel();
      MessageBox.ShowErrorCustomerHeader(this.dialog, 'The file type you selected is incompatible' +
        '. Please select either Microsoft Word documents (doc, docx), Microsoft Excel spreadsheets (xls,xlsx,csv) or PDFs', 'Incompatible File Type');
    }
  }

  public SetClassForControl(tmpformgroup: UntypedFormGroup, controlname: string): string {
    if (tmpformgroup.get(controlname).invalid
      && (tmpformgroup.get(controlname).dirty ||
        tmpformgroup.get(controlname).touched ||
        this.btnSubmitClicked)) {
      return 'form-control is-invalid';
    } else {
      return 'form-control';
    }
  }

  public IsControlInvalid(tmpformgroup: UntypedFormGroup, controlname: string): boolean {
    return (tmpformgroup.get(controlname).invalid
      && (tmpformgroup.get(controlname).dirty ||
        tmpformgroup.get(controlname).touched ||
        this.btnSubmitClicked));
  }

  public SetClassForControlManually(tmpformgroup: UntypedFormGroup, controlname: string, condition: boolean): string {
    if (condition
      && (tmpformgroup.get(controlname).dirty ||
        tmpformgroup.get(controlname).touched ||
        this.btnSubmitClicked)) {
      return 'form-control is-invalid';
    } else {
      return 'form-control';
    }
  }

  public IsControlInvalidManually(tmpformgroup: UntypedFormGroup, controlname: string, condition: boolean): boolean {
    return (condition
      && (tmpformgroup.get(controlname).dirty ||
        tmpformgroup.get(controlname).touched ||
        this.btnSubmitClicked));
  }


  public btnSubmit_Click() {
    this.btnSubmitClicked = true;

    if (this.MaterialFormGroup.valid === false) {
      return;
    } else {
      const val = this.MaterialFormGroup.get('ddlMaterialType').value;
      if (val === '1') {
        const docname = this.MaterialFormGroup.get('txtDocFile').value;
        if (docname === null || docname === '' || this.MaterialRecipientSelected.length === 0) {
          return;
        }
        else {
          this.SaveMaterial();
        }
      } else {
        const videoname = this.MaterialFormGroup.get('txtYouTube').value;
        if (videoname === null || videoname === '' || this.MaterialRecipientSelected.length === 0) {
          return;
        }
        else {
          this.SaveMaterial();
        }
      }
    }

  }

  private SaveMaterial() {
    const lstMModel: ListMaterialModel = new ListMaterialModel();
    for (let i = 0; i < this.MaterialRecipientSelected.length; i++) {
      const obj: MaterialModel = new MaterialModel();
      obj.MaterialId = -1;
      obj.MaterialRecipientId = this.MaterialRecipientSelected[i].Id;
      obj.MaterialTypeId = this.MaterialFormGroup.get('ddlMaterialType').value;
      obj.Name = this.MaterialFormGroup.get('txtNameDocVideo').value;
      obj.DocumentFileName = this.MaterialFormGroup.get('txtDocFile').value;
      obj.Description = this.MaterialFormGroup.get('txtDescription').value;
      if (obj.MaterialTypeId.toString() === '1') {
        obj.Link = this.uploadlink;
      } else {
        obj.Link = this.MaterialFormGroup.get('txtYouTube').value;
      }
      lstMModel.MaterialViewModels.push(obj);
    }

    this.comsvr.StartProgressBar();
    const self = this;
    forkJoin(
      this.httpdao!.postData(APIConstant.API_ADD_MATERIAL, lstMModel)
    ).pipe(
      map(([rs]) => {
        return { rs };
      }),
      catchError(() => {
        this.comsvr.StopProgressBar();
        return observableOf(null);
      })
    ).subscribe(res => {
      this.comsvr.StopProgressBar();
      const result = res.rs;
      if (result.Success) {
        MessageBox.ShowInfo(this.dialog, 'Data has been saved successfully').subscribe(ret => {
          self.ResetForm();
          self.InitForm();
        });
      } else {
        MessageBox.ShowError(this.dialog, result.Message || 'An error has been occured while add new traning material');
      }
    });
  }

  public PopupCenter(elem: MaterialModel) {
    const vid = this.GetVideoID(elem.Link);
    this.YoutubeSrc = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + vid);
    this.YoutubeName = elem.Name
    this.Open();
  }

  private GetVideoID(url: string): string {
    const video_id = url.split('v=')[1];
    return video_id;
  }

  private Open() {
    this.openModal = this.modalService.open(this.popupYouTubecontent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    });
    this.openModal.result.then((result) => {

    }, (reason) => {
    });
  }

  public CloseYouTube() {
    this.openModal.close();
  }

  public DeleteMaterial(marterial: MaterialModel) {
    const self = this;
    MessageBox.ShowCancelContinue(this.dialog, 'Material Deletion',
      'The following document/video will be deleted for this selected recipient.' +
      ' This operation is irreversible.' +
      '<br/>Do you wish to continue?').subscribe(res => {
        if (res.result.toLowerCase() === DialogResult.Continue) {
          self.CallDeleteMaterial(marterial);
        }
      });
  }

  private CallDeleteMaterial(marterial: MaterialModel) {
    this.comsvr.StartProgressBar();
    forkJoin(
      this.httpdao!.postData(APIConstant.API_DEL_MATERIAL, marterial)
    ).pipe(
      map(([rs]) => {
        return { rs };
      }),
      catchError(() => {
        this.comsvr.StopProgressBar();
        return observableOf(null);
      })
    ).subscribe(res => {
      this.comsvr.StopProgressBar();
      const result = res.rs;
      if (result.Success) {
        this.ResetForm();
        this.InitForm();
      } else {
        MessageBox.ShowError(this.dialog, result.Message || 'An error has been occured while delete training material');
      }
    });
  }

  private ResetForm() {
    this.MaterialFormGroup.get('ddlRecipient').setValue('');
    this.MaterialFormGroup.get('ddlMaterialType').setValue('');
    this.MaterialFormGroup.get('txtNameDocVideo').setValue('');
    this.btnSubmitClicked = false;
    this.MaterialFormGroup.reset();
  }

  public CanAddnew(): boolean {
    return this.rolepermission.MenuUploadTrainingMaterial;
  }

  public CanView(): boolean {
    return this.rolepermission.MenuViewTrainingMaterial;
  }

  public SetTitle(): string {
    if (this.rolepermission.MenuViewTrainingMaterial === true && this.rolepermission.MenuUploadTrainingMaterial === true) {
      return 'Upload/View Training Material';
    }
    else if (this.rolepermission.MenuViewTrainingMaterial === false && this.rolepermission.MenuUploadTrainingMaterial === true) {
      return 'Upload Training Material';
    }
    else if (this.rolepermission.MenuViewTrainingMaterial === true && this.rolepermission.MenuUploadTrainingMaterial === false) {
      return 'View Training Material';
    }
  }

  public IsFEorFSM(): boolean {
    const r = this.comsvr.GetSelectedRole();
    return (r === ERole.Facility_Staff_Member || r === ERole.Employer_Facilitator);
  }

  get noRecordFoundText(): string {
    return CommonString.TABLE_NO_RECORD_FOUND;
  }
}
