<div [formGroup]="form">
  <div class="mx-auto tabProgress w-50 d-none d-lg-flex mt-3">
    <div class="flex-fill text-center ">
      <div class="card-icon rounded-circle mx-auto"
        [ngClass]="stepper.selectedIndex == tabIndex.MemberDetailTab || memberDetails?.valid ? 'fas fa-checked' : null">
        <i
          [ngClass]="memberDetails?.valid && stepper.selectedIndex != tabIndex.MemberDetailTab ? 'fas fa-check text-light' : null"></i>
      </div>
      <p class="card-text mb-3">Member</p>
    </div>
    <div class="flex-fill text-center ">
      <div class="card-icon rounded-circle mx-auto"
        [ngClass]="stepper.selectedIndex == tabIndex.EmployerDetailsTab || employerDetails?.valid ? 'fas fa-checked' : null">
        <i
          [ngClass]="employerDetails?.valid && stepper.selectedIndex != tabIndex.EmployerDetailsTab ? 'fas fa-check text-light' : null"></i>
      </div>
      <p class="card-text mb-3">Employer</p>
    </div>
    <div class="flex-fill text-center ">
      <div class="card-icon rounded-circle mx-auto"
        [ngClass]="stepper.selectedIndex == tabIndex.MemberShipPackagesTab || membershipDetails?.valid ? 'fas fa-checked' : null">
        <i
          [ngClass]="membershipDetails?.valid && stepper.selectedIndex != tabIndex.MemberShipPackagesTab ? 'fas fa-check text-light' : null"></i>
      </div>
      <p class="card-text mb-3">Membership Package</p>
    </div>
    <div class="flex-fill text-center ">
      <div class="card-icon rounded-circle mx-auto"
        [ngClass]="stepper.selectedIndex == tabIndex.PaymentDetailsTab || paymentDetails?.valid ? 'fas fa-checked' : null">
        <i
          [ngClass]="paymentDetails?.valid && stepper.selectedIndex != tabIndex.PaymentDetailsTab ? 'fas fa-check text-light' : null"></i>
      </div>
      <p class="card-text mb-3">Payment Details</p>
    </div>
  </div>
  <div class="tabFPTheme mb-5" #tabfpstepper [style.height]="heighttabs">
    <mat-vertical-stepper #stepper [linear]="true" (selectionChange)="onStepChange($event)">
      <mat-step [stepControl]="form.get('MemberDetails')">
        <form id="membrreactivationtb0">
          <ng-template matStepLabel>
            <i class="icon-fp d-lg-none" [ngClass]="{'fas fa-check': memberDetails?.valid}"></i>Member</ng-template>
          <app-reactivation-member-details #memberDetails [data]="data"
            [form]="form.get('MemberDetails')"></app-reactivation-member-details>
            <div *ngIf="partnerValidationText.length > 0" [innerHTML]="partnerValidationText"
              class="invalid-feedback d-block family-member-error">
            </div>
            <div *ngIf="dependantAgeValidationText.length > 0" [innerHTML]="dependantAgeValidationText"
              class="invalid-feedback d-block family-member-error">
            </div>
            <div *ngIf="familyMemberValidationText.length > 0" [innerHTML]="familyMemberValidationText"
             class="invalid-feedback d-block family-member-error">
            </div>
          <div class="d-flex">
            <button mat-button (click)="btnBack_Click()" type="button"
              class="btn btn-outline-primary back-btn d-flex align-items-center justify-content-center mr-4"><i
                class="icon-previous-white"></i>Back</button>
            <button mat-button (click)="btnNext_Click()" type="button"
              class="btn btn-primary btn-new d-flex align-items-center justify-content-center"
              [disabled]="!memberDetails.valid">Next<i class="icon-next"></i></button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="form.get('EmployerDetails')">
        <form id="membrreactivationtb1">
          <ng-template matStepLabel>
            <i class="icon-fp d-lg-none" [ngClass]="{'fas fa-check': employerDetails?.valid}"></i>Employer</ng-template>
          <app-reactivation-employer-details #employerDetails [data]="data" [form]="form.get('EmployerDetails')"
            [loadOnDemand]="true"></app-reactivation-employer-details>
          <div class="d-flex">
            <button mat-button (click)="btnBack_Click()" type="button"
              class="btn btn-outline-primary back-btn d-flex align-items-center justify-content-center mr-4"><i
                class="icon-previous-white"></i>Back</button>
            <button mat-button (click)="btnNext_Click()" type="button"
              class="btn btn-primary btn-new d-flex align-items-center justify-content-center"
              [disabled]="!employerDetails.valid">Next<i class="icon-next"></i></button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="form.get('MembershipDetails')">
        <form id="membrreactivationtb2">
          <ng-template matStepLabel>
            <i class="icon-fp d-lg-none" [ngClass]="{'fas fa-check': membershipDetails?.valid}"></i>Membership
            Package</ng-template>
          <app-reactivation-membership-package-details #membershipDetails [data]="data"
            [form]="form.get('MembershipDetails')" [loadOnDemand]="true"
            [data]="data"></app-reactivation-membership-package-details>
          <div class="d-flex">
            <button mat-button (click)="btnBack_Click()" type="button"
              class="btn btn-outline-primary back-btn d-flex align-items-center justify-content-center mr-4"><i
                class="icon-previous-white"></i>Back</button>
            <button mat-button (click)="btnNext_Click()" type="button"
              class="btn btn-primary btn-new d-flex align-items-center justify-content-center">Next<i
                class="icon-next"></i></button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="form.get('PaymentDetails')">
        <form id="membrreactivationtb3">
          <ng-template matStepLabel>
            <i class="icon-fp d-lg-none" [ngClass]="{'fas fa-check': paymentDetails?.valid}"></i>Payment
            Details</ng-template>
          <app-reactivation-payment-details #paymentDetails [data]="data" [form]="form.get('PaymentDetails')"
            [loadOnDemand]="true"></app-reactivation-payment-details>
          <div class="d-flex">
            <button mat-button (click)="btnBack_Click()" type="button"
              class="btn btn-outline-primary back-btn d-flex align-items-center justify-content-center mr-4"><i
                class="icon-previous-white"></i>Back</button>
            <button mat-button (click)="btnNext_Click()" [disabled]="!form?.valid || !paymentDetails?.valid" type="button"
              class="btn btn-primary btn-new d-flex align-items-center justify-content-center">Submit<i
                class="icon-next"></i></button>
          </div>
        </form>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</div>

<!-- Terms and Conditions Modal -->

<div class="modal fade" #termsConditionsModalctrl id="termsConditionsModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true" #dlgTermsCons data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <ng-template #dlgTermsConsContent let-modal>
        <div class="{{fadeMainTerm ? 'main-term-test-fade' : 'main-term-test-show'}}">
          <div class="modal-header" id="modalheader">
            <h5 class="modal-title" id="exampleModalLabel" ngbAutofocus>Terms and Conditions</h5>
          </div>
          <div class="modal-body">
            <div class="fpscrolly py-3" id="fp-termscondText" [style.height]="heightTCText" (scroll)="onScroll($event)">
              <div [innerHtml]="termsAndConsContent" id="fp-tcContent"></div>
              <div id="fp-tcEndContent">&nbsp;</div>
            </div>
            <div class="position-relative pt-4" id="chkTC">
              <span class="font-weight-bold" style="color: red; text-align: center;">Please scroll to the bottom and
                read
                all the Terms and Conditions.<br />Please click Next to proceed.</span>
            </div>
            <div class="py-4 d-flex justify-content-center" id="btnTC">
              <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                data-dismiss="modal" (click)="termModalBack(modal)">
                <i class="fas fa-arrow-circle-left mr-2"></i>Back</button>
              <div class="tooltip">
                <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                  data-dismiss="modal" [disabled]="disablechkTermCons" (click)="showKeyTermsAndConditions()">
                  Next&nbsp;<i class="fas fa-arrow-circle-right mr-2"></i></button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<!-- Key Terms and Conditions Modal -->

<div class="modal fade" #keyTermsConditionsModalctrl id="termsConditionsModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true" #dlgKeyTermsCons data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <ng-template #dlgKeyTermsConsContent let-modal>
        <div class="modal-header" id="keyModalheader">
          <h5 class="modal-title" id="exampleModalLabel" ngbAutofocus>Key Terms and Conditions</h5>
        </div>
        <div class="modal-body">
          <div class="fpscrolly py-3" id="fp-keyTermscondText" [style.height]="heightKeyTCText"
            (scroll)="keyTermOnScroll($event)">
            <div [innerHtml]="keyTermsAndConsContent" id="fp-KeytcContent"></div>
            <div id="fp-KeytcEndContent">&nbsp;</div>
          </div>
          <div class="position-relative pt-4" id="keyChkTC">
            <span class="font-weight-bold" style="color: red; text-align: center;">I have read and accepted the terms
              and conditions</span>
            <div class="d-inline-block ml-3">
              <div class="custom-control custom-checkbox">
                <input [disabled]="disablechkKeyTermCons" type="checkbox" class="custom-control-input"
                  id="chkKeyTermsCons" #chkKeyTermsCons [checked]="isKeyTermsAndConsAccepted"
                  (change)="isKeyTermsAndConsAccepted = $event.target.checked">
                <label class="custom-control-label" for="chkKeyTermsCons">Yes</label>
              </div>
            </div>
          </div>
          <div class="py-4 d-flex justify-content-center" id="keyBtnTC">
            <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
              data-dismiss="modal" (click)="keyTermModalBack(modal)" [disabled]="isDataSubmitted">
              <i class="fas fa-arrow-circle-left mr-2"></i>Back</button>
            <div class="tooltip">
              <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" [disabled]="!isKeyTermsAndConsAccepted || isDataSubmitted"
                data-dismiss="modal" data-target="dlgKeyTermsConsContent" (click)="btnSubmit_Click();">
                Submit</button>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>