import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { googleAnalyticsHeadScripts } from './assets/scripts/GoogleAnalyticsScript';

import * as Sentry from '@sentry/angular';
import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: environment.cognitoUserPoolId,
      userPoolClientId: environment.cognitoUserPoolClientId,
      identityPoolId: environment.cognitoIdentityPoolId,
      loginWith: {
        email: true,
        username: true,
        oauth: {
          domain: environment.cognitoDomainUrl,
          redirectSignIn: [environment.cognitoRedirectUrl],
          redirectSignOut: [environment.logoutUrl],
          responseType: 'code',
          scopes: [
            'openid',
            'email',
            'phone',
            'profile',
            'aws.cognito.signin.user.admin',
          ],
        },
      },
      signUpVerificationMethod: 'code',
      userAttributes: {
        // Those two will be removed once we talk to the new userpool
        given_name: {
          required: true,
        },
        family_name: {
          required: true,
        },
        phone_number: {
          required: true,
        },
      },
      allowGuestAccess: false,
      passwordFormat: {
        minLength: 8,
        requireLowercase: true,
        requireUppercase: true,
        requireNumbers: true,
        requireSpecialCharacters: true,
      },
    },
  },
});

if (environment.production) {
  enableProdMode();
}

googleAnalyticsHeadScripts();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(async () => {
    Sentry.init({
      dsn: environment.sentryDSN,
      environment: environment.sentryEnvironment,
      integrations: [
        // Registers the Replay integration,
        // which automatically captures Session Replays
        Sentry.replayIntegration(),
      ],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      beforeSend: async (event) => {
        // Get the feature flag.
        if (window.location.href.startsWith('http://localhost')) {
          return null;
        }
        return event;
      },
    });
  })
  .catch((err) => console.log(err));
