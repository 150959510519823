import { Component, Injector } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FacilityFormBase } from 'src/app/components/base';
import { FacilityProductsListComponent } from './products-list/products-list.component';
import { FacilityServicesListComponent } from './services-list/services-list.component';

@Component({
    selector: 'app-facility-products-services',
    templateUrl: './products-services.component.html',
    styleUrls: ['./products-services.component.css'],
    providers: [{ provide: FacilityFormBase, useExisting: FacilityProductsServicesComponent }]
})
export class FacilityProductsServicesComponent extends FacilityFormBase {
    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            ServicesList: FacilityServicesListComponent.getFormGroup(),
            ProductsList: FacilityProductsListComponent.getFormGroup()
        });
    }

    constructor(injector: Injector) { super(injector); }
}
