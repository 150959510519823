<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">View/Edit Employer</h4>
<div class="mt-lg-4">
    <form>
        <div class="form-group row">
            <label for="searchName" class="col-md-3 col-lg-2 col-form-label">Search Employer</label>
            <div class="col-md-10 col-lg-7">
                <div class="input-group" [ngClass]="getClassFocused(1)">
                    <input class="form-control" type="text"
                        placeholder="Search Employer by Name, Code, Alt Tags or Facilitator" matInput
                        [formControl]="myControl" [matAutocomplete]="auto" name="txtEmployerSearch"
                        (keyup)="txtEmployerSearch_Change($event)" (blur)="onBlurParent(0)" (focus)="onFocusParent(1)"
                        (keydown.enter)="btnEmployerSearch_Click($event)">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                        (optionSelected)="ddlEmployerAutoComplete_Selected($event);" id="ddlEmployerAutoComplete">
                        <mat-option *ngFor="let emp of filteredEmployers | async" [value]="emp"
                            style="height: auto !important;">
                            <div>
                                <div
                                    style="width: 300px !important;overflow: hidden;text-overflow:ellipsis;float: left;">
                                    {{emp.Name}}
                                </div>
                            </div>
                        </mat-option>
                        <!-- <mat-option *ngIf="(isEmployerSearch==true && emps!=null && emps!=undefined && emps.length==0)">
                            No record found
                        </mat-option> -->
                    </mat-autocomplete>
                    <div class="input-group-append">
                        <button class="input-group-text" type="submit">
                            <i class="fas fa-search text-primary" *ngIf="!isShowSpinter"></i>
                        </button>
                        <mat-spinner *ngIf="isShowSpinter" [diameter]="14"></mat-spinner>
                    </div>
                </div>
                <div class="ml-2">
                    <button class="btn btn-primary px-4 text-white btnMenberSearch" id="btnEmployerSearch"
                        (click)="btnEmployerSearch_Click($event)">SEARCH</button>
                </div>
            </div>
        </div>

    </form>
    <p class="mb-0 py-2 h5">OR</p>
    <form>
        <div class="form-group row">
            <label for="slctViewEmp" class="col-md-3 col-lg-2 col-form-label">View Employers by Region</label>
            <div class="col-md-10 col-lg-7">
                <div class="input-group" [ngClass]="getClassFocused(2)">
                    <input class="form-control" type="text"
                        placeholder="Search Regions by Region Name, Suburb, Postcode" matInput
                        [formControl]="txtRegionControl" [matAutocomplete]="ddlRegion"
                        (keyup)="txtRegion_Change($event)" (blur)="onBlurParent(0)" (focus)="onFocusParent(2)">
                    <mat-autocomplete #ddlRegion="matAutocomplete" [displayWith]="displayFnRegion"
                        (optionSelected)="ddlRegionAutoComplete_Selected($event);">
                        <mat-option *ngFor="let region of filteredRegions | async" [value]="region"
                            style="height: auto !important;">
                            <div>
                                <div
                                    style="width: 300px !important;overflow: hidden;text-overflow:ellipsis;float: left;">
                                    {{region.Name}}
                                </div>
                            </div>
                        </mat-option>
                        <!-- <mat-option *ngIf="(isRegionSearch==true && regions!=null && regions!=undefined && regions.length==0)">
                            No record found
                        </mat-option> -->
                    </mat-autocomplete>
                    <div class="input-group-append">
                        <button class="input-group-text" type="submit">
                            <i class="fas fa-search text-primary" *ngIf="!isShowSpinterRegion"></i>
                        </button>
                        <mat-spinner *ngIf="isShowSpinterRegion" [diameter]="14"></mat-spinner>
                    </div>

                </div>
            </div>
        </div>
    </form>
</div>