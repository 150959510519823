export class RequestPagination {
    constructor(
        public PageNumber: number = 1,
        public PageSize: number = 10,
        public OrderBy: string = 'lastname',
        public OrderByAsc: boolean = true,
        public Params: Array<ParamPagination> = [new ParamPagination()]
    ) { }
}

export class ParamPagination {
    constructor(
        public Name: string = 'all',
        public Value: string | number = '',
        public Conditions?: Array<ConditionPagination>,
        public Filters?: Array<FilterPagination>
    ) { }
}

export class ConditionPagination {
    SearchKey: string;
    Value: number;
}

export class FilterPagination {
    Name: string;
    Value: string;
}