import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  DebitProcessingFilterData,
  DebitProcessingDates,
  DataResult,
  FileAttachmentModel,
  ResultModel,
  BulkUploadResult,
  AWSStartExecutionResponse
} from "@fp/models";
import { Observable, Subject, interval } from "rxjs";
import { PaymentService, MessageBox, CommonService } from "@fp/services";
import { catchError, debounceTime } from "rxjs/operators";
import { DataSourceHelper } from "@fp/helpers";
import { FpFileDataR2 } from "@fp/components/control";
import { FileNameService } from "@fp/services/file-name.service";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { FpProgressBarComponent } from "@fp/components/control/fp-progress-bar/fp-progress-bar.component";
import { StepFunctions } from "@fp/enums/step-functions.enum";
import { AwsServiceService } from "@fp/services/aws-service.service";
import { DescribeStateMachineComponent } from "@fp/components/describe-state-machine/describe-state-machine.component";

@Component({
  selector: "app-bulk-upload-debit-credit",
  templateUrl: "./bulk-upload-debit-credit.component.html",
  styleUrls: ["./bulk-upload-debit-credit.component.css"]
})
export class BulkUploadDebitCreditComponent implements OnInit {
  dataDocumentAttachments: FileAttachmentModel[] = [];
  documentName = "No File Selected";
  colParameter = [
    "membernumber",
    "transactiontype",
    "transactionamount",
    "description",
    "postdate"
  ];

  StepFunctions = StepFunctions;
  uploadedFileName: string[];
  errorHandler: any;
  bulkUploadConfirmationMsg: string;
  errorMsg: string;
  checkNecessary = true;
  isLoading = false;

  /** The Progress Bar Modal in this Page */
  @ViewChild('progressBar') progressBar: FpProgressBarComponent;
  @ViewChild(DescribeStateMachineComponent) describeSpinner: DescribeStateMachineComponent;

  constructor(
    protected fileSvc: FileNameService,
    private paymentSvc: PaymentService,
    private awsService: AwsServiceService,
    protected dialog: MatDialog,
    private cmsrv: CommonService
  ) { }

  ngOnInit() {
    this.cmsrv.Header.title="Bulk Upload Debit/Credit";
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError(e => {
          throw e;
        })
      )
      .subscribe(
        res => {
          handleResultCallback(res);
        },
        err => {
          this.errorHandler(err);
        }
      );
  }

  public AddFile_UploadCompleted(event) {
    const ret: FpFileDataR2 = event;
    this.dataDocumentAttachments.push({
      Location: ret.filedata.Location,
      Base64: "",
      FileName: ret.originfilename,
      Status: "Addnew",
      Id: 0
    });
    this.documentName = ret.originfilename;
    console.log(this.dataDocumentAttachments);

    this.CheckBulkUpload();
  }

  // this function checks the Raw Visits from CSV and populates the number of Errors on the page
  public CheckBulkUpload() {
    this.bulkUploadConfirmationMsg =
      "You are about to upload multiple debit/credit transactions. This operation is irreversible and will update the Membership Ledger. Do you wish to continue?";
    MessageBox.ShowCancelContinue(
      this.dialog,
      "Bulk Debit/Credit Upload Confirmation",
      this.bulkUploadConfirmationMsg
    ).subscribe(dialogResult => {
      if (dialogResult.result.toLowerCase() === DialogResult.Continue) {
        // Open the Progress Bar
        this.progressBar.Open("Grabbing File");

        this.fileSvc.$currentUploadedFileUuid.subscribe(
          uploadedFileName => (this.uploadedFileName = uploadedFileName)
        );
        const fileNameString = this.uploadedFileName[0];
        const modifiedBy = this.cmsrv.GetUser();

        if (fileNameString !== null) {
          // Update Progress Bar Status and increment the Percentage every 1/5 of a second
          this.progressBar.upload_status_subject.next("Uploading File: " + this.dataDocumentAttachments[0].FileName);
          this.progressBar.StartPercentTimer();

          this.Invoke(
            this.awsService.StartStepExecution(StepFunctions.BulkUpload,
              null, modifiedBy, fileNameString, null),
            (result: AWSStartExecutionResponse) => {
              // Kill the Progress Bar
              this.progressBar.upload_percent_subject.next(100);
              this.progressBar.upload_status_subject.next("Upload Complete!");
              this.progressBar.close_subject.next(true);

              this.describeSpinner.stepMachineIsRunning = true;
              this.describeSpinner.executionArn = result.executionArn;
              this.describeSpinner.StartDescriptionTimer(5);
            }
          );
        }
      }
    });
  }

  handleBulkUploadResponse(result: DataResult<BulkUploadResult[]>) {
    if (result.Data.length == 0) {
      MessageBox.ShowInfo(
        this.dialog,
        "Upload is successful. All rows posted to Membership Ledger."
      ).subscribe(dialogResult => {
        if (dialogResult.result.toLowerCase() === DialogResult.Ok) {
          this.fileSvc.resetFileData();
          window.location.reload();
        }
      });
    } else {
      var row = result.Data[0].RowNumber + 1;
      var errorCode = result.Data[0].ErrorType;
      this.fileSvc.resetFileData();
      this.errorMsg =
        `<strong>${errorCode}</strong>; first failed row is row [${row}] in the spreadsheet. No transactions were posted to the Membership Ledger. Please review the uploaded CSV, correct all errors and try again.`;
      MessageBox.ShowError(this.dialog, this.errorMsg);
    }
  }
}
