<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">View/Edit Members</h4>
<div class="mt-lg-4">
    <form>
        <div class="form-group row">
            <label for="searchMember" class="col-md-3 col-lg-2 col-form-label">Search Member</label>
            <div class="col-md-9 col-lg-9">
                <div class="input-group" [ngClass]="getClassFocused(1)">
                    <input class="form-control" type="text"
                        placeholder="Search Member by First Name, Last Name, Membership Number, Email, Member ID"
                        [formControl]="memberSearch" matInput [matAutocomplete]="ddlSearchMember" name="searchMember"
                        (blur)="onBlurParent(0)" (focus)="onFocusParent(1)"
                        (keydown.enter)="btnMemberSearch_Click($event)">
                    <mat-autocomplete #ddlSearchMember="matAutocomplete" [displayWith]="ddlSearchMember_DisplayWithFn"
                        (optionSelected)="ddlSearchMemberAutoComplete_Selected($event.option.value);">
                        <mat-option *ngFor="let member of memberSearchResults" [value]="member">
                            <p class="d-flex align-items-center m-0 ml-2 w-100">
                                <span>{{member.LastName}}, {{member.FirstName}}</span></p>
                        </mat-option>
                    </mat-autocomplete>
                    <div class="input-group-append">
                        <button *ngIf="!isSearchingMember" class="input-group-text" type="submit">
                            <i class="fas fa-search text-primary"></i>
                        </button>
                        <mat-spinner *ngIf="isSearchingMember" [diameter]="14"></mat-spinner>
                    </div>
                </div>
                <div class="ml-2">
                    <button class="btn btn-primary px-4 text-white btnMenberSearch" id="btnMemberSearch"
                        (click)="btnMemberSearch_Click($event)">SEARCH</button>
                </div>
            </div>
        </div>

    </form>
    <p class="mb-0 py-2 h5">OR</p>
    <form>
        <div class="form-group row">
            <label for="txtViewMember" class="col-md-3 col-lg-2 col-form-label">View Members by Employer</label>
            <div class="col-md-9 col-lg-8">
                <div class="input-group" [ngClass]="getClassFocused(2)">
                    <input class="form-control" type="text"
                        placeholder="Search Employer by Employer Name, Employer Code" [formControl]="employerName"
                        matInput [matAutocomplete]="ddlViewMember" (blur)="onBlurParent(0)" (focus)="onFocusParent(2)">
                    <mat-autocomplete #ddlViewMember="matAutocomplete" [displayWith]="ddlViewMember_DisplayWithFn"
                        (optionSelected)="ddlViewMemberAutoComplete_Selected($event.option.value);">
                        <mat-option *ngFor="let employer of employerSearchResults" [value]="employer">
                            <p class="d-flex align-items-center m-0 ml-2 w-100">
                                <span>{{employer.Name}}</span></p>
                        </mat-option>
                    </mat-autocomplete>
                    <div class="input-group-append">
                        <button *ngIf="!isSearchingEmployer" class="input-group-text" type="submit">
                            <i class="fas fa-search text-primary"></i>
                        </button>
                        <mat-spinner *ngIf="isSearchingEmployer" [diameter]="14"></mat-spinner>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
