import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RouterConstant } from 'src/app/constant';

@Component({
  selector: 'app-endmembersignup',
  templateUrl: './endmembersignup.component.html',
  styleUrls: ['./endmembersignup.component.css'],
})
export class EndmembersignupComponent {
  constructor(private router: Router) {}

  ReturnHome() {
    this.router.navigate([RouterConstant.ROUTER_HOME]);
  }

  NavigateToSecurityPreferences() {
    this.router.navigate([
      RouterConstant.NAVIGATOR_MEMBER_SECURITY_PREFERENCES,
    ]);
  }
}
