import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormControl, NgModel, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef, NgbPopover, NgbDateStruct, NgbCalendar, NgbInputDatepicker, NgbDateAdapter, NgbDateNativeUTCAdapter } from '@ng-bootstrap/ng-bootstrap';
import { empty, Observable } from 'rxjs';
import { catchError, debounceTime, finalize, switchMap } from 'rxjs/operators';
import { DialogResult } from 'src/app/components/common-dialog/common-dialog.component';
import { CommonMessage, CommonString, RouterConstant, StorageKey, APIConstant } from 'src/app/constant';
import { EFacilityStatus } from 'src/app/enums';
import { EServiceStatus } from 'src/app/enums/service-status.enum';
import { DataSourceHelper, FPValidators, StringHelper, DateHelper } from 'src/app/helpers';
import { Country, DataResult, EmployerRegion, Facility, Postcode, ReassignPostcodeToRegionRequest, Region, ResultModel, SearchPaginationRequest, SearchPaginationResult, SearchPostcodeRequest, State, Suburb, FacilityMap, RegionNoteModel, User, RegionAdditionalOption } from 'src/app/models';
import { MessageBox, MessageBoxButton } from 'src/app/services/common-dialog.service';
import { CommonService } from 'src/app/services/common.service';
import { EmployerService } from 'src/app/services/employer.service';
import { FacilityService } from 'src/app/services/facility.service';
import { RegionService } from 'src/app/services/region.service';
import { Utils } from 'src/app/helpers';
import { DatePipe } from '@angular/common';
import { FacilityGoogleMapComponent } from '@fp/components/members/shared/facitily-google-map/facitily-google-map.component';
import { CommonConstants } from '@fp/constant/common-constants';

@Component({
    selector: 'app-editregions',
    templateUrl: './editregions.component.html',
    styleUrls: ['./editregions.component.css'],
    providers: [
        { provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter }
    ]
})
export class EditregionsComponent implements OnInit, AfterViewInit, OnDestroy {
    Utils = Utils;
    parentFocus = 0;
    /** Indicates whether current user can edit. */
    canEdit = false;

    /** Indicates whether the form is in edit mode. */
    isEditMode = false;
    currentRegion: Region;

    regionName = '';
    regionNameInTitleArea = '';
    regionDescription = '';
    countries: Country[];
    selectedCountry: Country;
    states: State[];
    selectedState: State;
    availablePostcodes: Postcode[];
    selectedPostcodes: Postcode[];
    selectedSuburbs: Suburb[];
    isCheckingName = false;
    isSearchingPostcode = false;
    isValidated = false;
    isDirty = false;
    bSearchingRegionForAssignment = false;
    newRegionAssignmentSearchResults: Region[];
    selectedNewRegion: Region;

    CountryCtrl = new UntypedFormControl(null, Validators.required);
    StateCtrl = new UntypedFormControl(null, Validators.required);
    postcodeSearchTextControl = new UntypedFormControl();
    StatusCtrl = new UntypedFormControl({ value: null, disabled: true }, Validators.required);
    NewRegionAssignmentCtrl = new UntypedFormControl(null, [Validators.required, FPValidators.resolvableAutocompleteOption]);

    // Keeps track of the number of requests to show/hide progress bar.
    requestQueueCount = 0;

    displayedColumns: string[] = ['Name', 'edit'];
    facilitiesDS: MatTableDataSource<Facility>;
    employersDS: MatTableDataSource<EmployerRegion>;
    facilityMapData: Array<FacilityMap> = [];
    filteredFacilityMapData: Array<FacilityMap> = [];

    @ViewChild('txtRgName') regionNameViewModel: NgModel;
    @ViewChild('postcodeSearchBox') postcodeSearchBox: ElementRef<HTMLDivElement>;
    @ViewChild('postcodeAutocomplete') postcodeAutocomplete: MatAutocomplete;
    @ViewChild('facilitiesSort') facilitiesSort: MatSort;
    @ViewChild('employersSort') employersSort: MatSort;
    @ViewChild('popStatusChangeError') popStatusChangeError: NgbPopover;
    @ViewChild('dlgTransferPostcode') dlgTransferPostcode: TemplateRef<any>;
    @ViewChild('googlemap') googlemap: FacilityGoogleMapComponent;

    // #region Constant references
    CommonMessage = CommonMessage;
    CommonString = CommonString;
    RouterConstant = RouterConstant;
    StringHelper = StringHelper;
    // #endregion Constant references

    // Map parameter preferences
    infoWindowMaxHeight = 'fit-content';
    infoWindowMaxWidth = 'fit-content';
    allFacilitiesVisibleMap: boolean;
    addFacilitytoMapList: string = '';

    accessCardYesNo: string;
    servicesHtmlString: string;
    operatingHoursHtmlString: string;
    accessCardFeeHtmlString: string;

    _mapListFilter = '';

    facilityIconUrl: string;

    previousInfoWindow = null;

    presentDate: NgbDateStruct;
    OpenLargeEmployersCtrl = new UntypedFormControl({ value: null, disabled: true });
    OpenSmallAdditionsCtrl = new UntypedFormControl({ value: null, disabled: true });
    Under50EmployeesCtrl = new UntypedFormControl({ value: null, disabled: true });
    regionETAOpening = new UntypedFormControl({ value: null, disabled: true });
    regionWaitListedEmployer: string;
    txtPDBDNotesControl = new UntypedFormControl({ value: null, disabled: true });
    dataSourceNotes = new MatTableDataSource<RegionNoteModel>();
    displayedColumns1: string[] = ['dateTime', 'note', 'user'];
    additionalOptions: RegionAdditionalOption[];
    private databinding: RegionNoteModel[] = [];

    constructor(
        protected svc: RegionService,
        private facilitySvc: FacilityService,
        private employerSvc: EmployerService,
        protected commonSvc: CommonService,
        private dialog: MatDialog,
        private modal: NgbModal,
        private route: ActivatedRoute,
        public router: Router,
        private datePipe: DatePipe,
        private ngbCalendar: NgbCalendar) {
        this.countries = [];
        this.states = [];
        this.selectedPostcodes = [];
        this.selectedSuburbs = [];

    }

    ngOnDestroy() {
        this.presentDate = null;
        this.dataSourceNotes = null;
    }

    ngOnInit() {
        this.countries = [];
        this.states = [];
        this.selectedPostcodes = [];
        this.selectedSuburbs = [];
        this.additionalOptions = [];
        this.LoadRegion();

        this.allFacilitiesVisibleMap = true;

        const today = this.ngbCalendar.getToday();
        this.presentDate = today;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.regionNameViewModel.valueChanges.
                pipe(
                    debounceTime(500),
                    switchMap(() => {
                        const newName = this.regionNameViewModel.value.trim();
                        if (newName.length > 0 && newName !== this.currentRegion.Name) {
                            this.Dirty();
                            this.isCheckingName = true;
                            return this.svc.getByName(this.regionNameViewModel.value);
                        } else {
                            this.isCheckingName = false;
                        }
                        return empty();
                    }),
                    catchError((e) => { throw e; }))
                .subscribe(
                    (result: DataResult<Region>) => {
                        this.isCheckingName = false;
                        if (result.Success) {
                            if (result.Data != null) {
                                this.regionNameViewModel.control.setErrors({ duplicated: true });
                            } else {
                                this.regionNameViewModel.control.setErrors(null);
                            }
                        } else {
                            this.errorHandler(result, 'An error has occurred. Please contact the administrator');
                        }
                    },
                    error => {
                        this.isCheckingName = false;
                        this.errorHandler(error);
                    });

            this.CountryCtrl.valueChanges.subscribe((value: Country) => {
                if (!this.selectedCountry || !value || value.CountryId !== this.selectedCountry.CountryId) {
                    this.ChangeCountry(value);
                }
            });

            this.StateCtrl.valueChanges.subscribe((value: State) => {
                if (!this.selectedState || !value || value.StateId !== this.selectedState.StateId) {
                    if (this.selectedPostcodes.length > 0) {
                        MessageBox.ShowCustom(this.dialog,
                            CommonMessage.STATE_CHANGE_CONFIRMATION_TITLE,
                            CommonMessage.STATE_CHANGE_CONFIRMATION_TITLE,
                            CommonMessage.STATE_CHANGE_CONFIRMATION_MESSAGE,
                            MessageBoxButton.YesNo).subscribe(r => {
                                if (r.result === DialogResult.Yes) {
                                    this.ChangeState(value);
                                } else {
                                    this.StateCtrl.setValue(this.selectedState, { emitEvent: false });
                                }
                            });
                    } else {
                        this.ChangeState(value);
                    }
                }
            });

            this.postcodeSearchTextControl.valueChanges.
                pipe(
                    debounceTime(500),
                    switchMap(() => {
                        this.Dirty();
                        if (this.CountryCtrl.valid && this.StateCtrl.valid && this.postcodeSearchTextControl.value.length >= 3) {
                            const input: SearchPostcodeRequest = {
                                StateId: this.selectedState.StateId,
                                SearchText: this.postcodeSearchTextControl.value
                            };
                            this.isSearchingPostcode = true;
                            return this.svc.searchPostcode(input);
                        } else {
                            this.isSearchingPostcode = false;
                        }
                        return empty();
                    }),
                    catchError((e) => { throw e; })
                ).subscribe(
                    result => {
                        this.isSearchingPostcode = false;
                        if (result != null) {
                            if (result instanceof Array) {
                                this.UpdateAvailablePostcodes(result);
                                if (this.postcodeAutocomplete.panel != null) {
                                }
                            } else {
                                this.errorHandler(result, 'An error has occurred. Please contact the administrator.');
                            }
                        }
                    },
                    error => {
                        this.isSearchingPostcode = false;
                        this.errorHandler(error);
                    });

            this.StatusCtrl.valueChanges.subscribe(value => {
                const hasActiveFacilitiesAndServices = this.hasActiveFacilitiesAndServices();
                if (value === false && hasActiveFacilitiesAndServices) {
                    this.popStatusChangeError.open();
                }
                this.Dirty();
            });

            this.NewRegionAssignmentCtrl.valueChanges.pipe(
                debounceTime(500),
                switchMap(value => {
                    if (typeof value === 'string' && value.length > 2) {
                        this.selectedNewRegion = null;
                        const request = new SearchPaginationRequest();
                        request.Params.push({ Name: 'name', Value: value });
                        this.bSearchingRegionForAssignment = true;
                        return this.svc.search(request);
                    } else {
                        this.bSearchingRegionForAssignment = false;
                    }
                    return empty();
                }),
                catchError((e) => { throw e; })
            ).subscribe(
                (res: DataResult<SearchPaginationResult<Region>>) => {
                    this.bSearchingRegionForAssignment = false;
                    if (res.Success) {
                        this.newRegionAssignmentSearchResults = res.Data ? res.Data.Results.filter(r => r.RegionId !== this.currentRegion.RegionId) : [];
                    } else {
                        console.error(res);
                    }
                },
                error => {
                    this.bSearchingRegionForAssignment = false;
                    this.errorHandler(error);
                });

            // Fix postcode autocomplete's width.
            this.postcodeAutocomplete.panelWidth = this.postcodeSearchBox.nativeElement.clientWidth;
        });
    }

    //#region Additional Notes
    public BtnClickAddNote() {
        const id = parseInt(this.route.snapshot.params.id, 10);
        const currentUser = <User>JSON.parse(this.commonSvc.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
        const dataNote = {
            RegionNoteId: 0,
            RegionId: id,
            Username: currentUser.FirstName + ', ' + currentUser.LastName,
            UserId: Number(currentUser.UserId),
            Note: this.txtPDBDNotesControl.value,
            CreateOrUpdateDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            DateTimeDisplay: DateHelper.format(new Date(), 'DD-MMM-YYYY HH:mm')
        };
        if (!StringHelper.isNullOrEmpty(dataNote.Note)) {
            this.databinding.unshift(dataNote);
            this.dataSourceNotes = new MatTableDataSource<RegionNoteModel>(this.databinding);
        }
        // reset input text
        this.txtPDBDNotesControl.setValue('');
    }
    //#endregion

    private errorHandler(error, message: string = null) {
        MessageBox.ShowError(this.dialog, message || 'An error occurred while trying to call a service');
        console.error(error);
    }

    private setFacilitiesDataSource(data: Facility[]) {
        this.facilitiesDS = new MatTableDataSource<Facility>(data);
        this.facilitiesDS.sortingDataAccessor = DataSourceHelper.localeLowerCaseSortingDataAccessor;
        this.facilitiesDS.sort = this.facilitiesSort;
    }

    private setEmployersDataSource(data: EmployerRegion[]) {
        this.employersDS = new MatTableDataSource<EmployerRegion>(data);
        this.employersDS.sortingDataAccessor = DataSourceHelper.localeLowerCaseSortingDataAccessor;
        this.employersDS.sort = this.employersSort;
    }

    Invoke(source: Observable<any>, handleResultCallback: Function) {
        this.requestQueueCount++;
        this.commonSvc.StartProgressBar();
        source.pipe(
            catchError((e) => { throw e; }),
            finalize(() => {
                this.requestQueueCount--;
                if (this.requestQueueCount <= 0) {
                    this.commonSvc.StopProgressBar();
                    this.requestQueueCount = 0;
                }
            }))
            .subscribe(
                res => { handleResultCallback(res); },
                err => { this.errorHandler(err); }
            );
    }

    systemPostcodes: Postcode[] = [];
    LoadRegion() {
        const id = parseInt(this.route.snapshot.params.id, 10);
        if (id > 0) {
            this.Invoke(
                this.svc.get(id),
                (data) => {
                    if (data != null && data.RegionId !== undefined) {
                        // TODO: get user permissions to determine whether user has permission to edit.
                        this.canEdit = true;

                        //#region FP-1369
                        if (data.Postcode != null) {
                            if (data.Postcode.length > 0) {
                                this.systemPostcodes = data.Postcode;
                            }
                        }
                        //#endregion FP-1369

                        this.currentRegion = data;
                        this.FetchRegionInfo(this.currentRegion);
                        this.markFormAsPristine();
                        this.ToggleMode(this.isEditMode);
                    } else {
                        this.router.navigate([RouterConstant.NAVIGATOR_PAGE_NOT_FOUND]);
                    }
                }
            );
        } else {
            this.router.navigate([RouterConstant.NAVIGATOR_PAGE_NOT_FOUND]);
        }
    }

    FetchRegionInfo(region: Region) {
        this.regionName = region.Name;
        this.regionNameInTitleArea = region.Name;
        this.regionDescription = region.Description;
        this.selectedState = region.State;
        this.StatusCtrl.setValue(region.IsActive, { emitEvent: false });
        this.selectedPostcodes = region.Postcode.slice(0);
        this.OpenLargeEmployersCtrl.setValue(region.OpenForLargeEmployerId, { emitEvent: false });
        this.OpenSmallAdditionsCtrl.setValue(region.OpenForSmallAdditionId, { emitEvent: false });
        this.Under50EmployeesCtrl.setValue(region.Under50EmployerId, { emitEvent: false });
        this.regionETAOpening.setValue(Utils.parseUTCDate(region.EtaonOpening), { emitEvent: false });
        this.regionWaitListedEmployer = region.WaitListedEmployer;
        this.dataSourceNotes.data = region.RegionNotes;
        this.databinding = region.RegionNotes;
        for (let i = 0; i < this.databinding.length; i++) {
            this.databinding[i].DateTimeDisplay = this.ConvertToLocalTime(this.databinding[i].CreateOrUpdateDate);
        }
        this.databinding = this.databinding.sort((a, b) => {
            return new Date(a.CreateOrUpdateDate).getTime() > new Date(b.CreateOrUpdateDate).getTime() ? -1 : 1;
        });
        // set data to region notes
        this.dataSourceNotes = new MatTableDataSource<RegionNoteModel>(this.databinding);

        this.RefreshSuburbList();
        this.LoadCountries();
        this.LoadFacilitiesByRegionId(region.RegionId);
        this.LoadEmployersByRegionId(region.RegionId);
        this.LoadAdditionalOption();
    }

    private ConvertToLocalTime(d: Date) {
        const localdate: Date = new Date(d.toString() + 'Z');
        return DateHelper.format(localdate, 'DD-MMM-YYYY HH:mm');
    }

    LoadAdditionalOption() {
        this.Invoke(
            this.svc.getAdditionalOption(),
            (result: DataResult<RegionAdditionalOption[]>) => {
                if (typeof result.Success !== 'undefined') {
                    if (result.Success) {
                        this.additionalOptions = result.Data;
                    } else {
                        console.error(result);
                    }
                } else {
                    console.error(result);
                }
            });
    }

    LoadCountries() {
        this.countries = CommonConstants.GET_COUNTRIES.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
        let country: Country = null;
        const state = this.currentRegion.State;
        this.selectedState = state;
        if (state) {
            country = this.countries.find(c => c.CountryId === state.CountryId);
        } else {
            // TODO: should find a more generic way to determine default country.
            country = this.countries.find(c => c.Name === 'Australia');
        }
        this.selectedCountry = country;
        this.CountryCtrl.setValue(country, { emitEvent: false });
        if (country) {
            this.states = CommonConstants.GET_STATES.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
            if (this.selectedState && this.selectedState.CountryId === this.selectedCountry.CountryId) {
                this.StateCtrl.setValue(this.selectedState, { emitEvent: false });
            }
        }
    }

    UpdateAvailablePostcodes(postcodes: Postcode[]) {
        this.availablePostcodes = postcodes
            .filter(postcode => this.selectedPostcodes.findIndex(p => p.PostcodeId === postcode.PostcodeId) < 0)
            .sort((a, b) => parseInt(a.Code) - parseInt(b.Code));
    }

    RefreshSuburbList() {
        let _selectedSuburbs = <Suburb[]>[];
        this.selectedPostcodes.forEach(postcode => {
            if (postcode.Suburb instanceof Array) {
                postcode.Suburb.forEach(s => {
                    (<any>s.Postcode) = {
                        PostcodeId: postcode.PostcodeId,
                        Code: postcode.Code
                    };
                });
                _selectedSuburbs = _selectedSuburbs.concat(postcode.Suburb);
            }
        });
        this.selectedSuburbs = _selectedSuburbs;
    }

    AutoComplete_DisplayWithFn(option: any) {
        if (!option) {
            return '';
        }
        return option.Name || option.Code;
    }

    slctCountryOption_CompareFn(opt1: Country, opt2: Country) {
        return (opt1 && opt2) && opt1.CountryId === opt2.CountryId;
    }

    slctStateOption_CompareFn(opt1: State, opt2: State) {
        return (!opt1 && !opt2) || (opt1 && opt2 && opt1.StateId === opt2.StateId);
    }

    AddPostcode(postcode: Postcode, popover: NgbPopover, autoComplete: MatAutocomplete, option: MatOption) {
        if (postcode.Region != null && postcode.Region.RegionId !== this.currentRegion.RegionId) {
            if (popover.isOpen()) {
                popover.close();
            }
            popover.open({ regionName: postcode.Region.Name, regionId: postcode.Region.RegionId });
        } else {
            const index = this.availablePostcodes.indexOf(postcode);
            if (index > -1) {
                this.selectedPostcodes.push(postcode);
                // option._getHostElement().hidden = true;
                this.availablePostcodes.splice(index, 1);
                this.selectedPostcodes = this.selectedPostcodes.sort((a, b) => parseInt(a.Code) - parseInt(b.Code));
                this.Dirty();
                this.RefreshSuburbList();
                this.LoadFacilitiesByPostcodeId(postcode.PostcodeId);
                this.LoadEmployersByPostcodeId(postcode.PostcodeId);
            }
        }
    }

    allocatePostCode: Postcode;
    dialogRef: NgbModalRef;
    ConfirmRemovePostcode(postcode: Postcode) {
        if (postcode.Region && postcode.Region.RegionId === this.currentRegion.RegionId) {
            this.dialogRef = this.modal.open(this.dlgTransferPostcode, { backdrop: 'static' });

            this.dialogRef.result.then(dialogResult => {
                this.selectedNewRegion = null;
                this.NewRegionAssignmentCtrl.setValue(null, { emitEvent: false });
                this.NewRegionAssignmentCtrl.markAsPristine();
                this.newRegionAssignmentSearchResults = [];
            }, reason => {
                this.selectedNewRegion = null;
                this.NewRegionAssignmentCtrl.setValue(null, { emitEvent: false });
                this.NewRegionAssignmentCtrl.markAsPristine();
                this.newRegionAssignmentSearchResults = [];
            });
            this.allocatePostCode = postcode;
        } else {
            this.RemovePostcode(postcode);
        }
    }

    clickSubmitAllocation = false;
    allocateToNewRegion(newRegion: Region) {
        if (newRegion == null || newRegion === undefined) {
            this.clickSubmitAllocation = true;
            return;
        }

        this.clickSubmitAllocation = false;
        const newRegionId: number = newRegion.RegionId;
        if (newRegionId > 0) {
            const reassignPostcodeReq: ReassignPostcodeToRegionRequest = {
                PostcodeId: this.allocatePostCode.PostcodeId,
                NewRegionId: newRegionId,
                ModifiedBy: this.commonSvc.GetUser()
            };

            this.Invoke(
                this.svc.reassignPostcode(reassignPostcodeReq),
                (res: ResultModel) => {
                    //#region FP-1370
                    if (res.Success) {
                        if (!res.Message) {
                            MessageBox.ShowInfo(this.dialog, `Postcode '${this.allocatePostCode.Code}' was re-allocated successfully`)
                                .subscribe(r => {
                                    const index: number = this.systemPostcodes.indexOf(this.allocatePostCode);
                                    index > -1 ? this.systemPostcodes.splice(index, 1) : null;

                                    this.RemovePostcode(this.allocatePostCode);
                                    this.dialogRef.close();
                                });
                        } else {
                            MessageBox.ShowError(this.dialog, res.Message);
                        }
                    } else {
                        MessageBox.ShowError(this.dialog, 'Failed to re-allocate postcode. Please try again later');
                        console.error(res);
                    }
                    //#endregion FP-1370
                }
            );
        }
    }
    RemovePostcode(postcode: Postcode) {
        const index = this.selectedPostcodes.indexOf(postcode);
        if (index > -1) {
            this.selectedPostcodes.splice(index, 1);
            if (this.availablePostcodes) {
                this.availablePostcodes.push(postcode);
                this.availablePostcodes.sort((a, b) => a.Code > b.Code ? 1 : -1);
            }
            this.Dirty();
            this.RefreshSuburbList();
            this.RemoveFacilitiesByPostcodeId(postcode.PostcodeId);
            this.RemoveEmployersByPostcodeId(postcode.PostcodeId);
        }
    }

    OpenRegion(regionId: number) {
        window.open(RouterConstant.NAVIGATOR_REGION_EDIT + '/' + regionId);
    }

    ChangeCountry(country: Country) {
        if (country) {
            if (!this.selectedCountry || this.selectedCountry.CountryId !== country.CountryId) {
                this.selectedCountry = country;
                this.states = CommonConstants.GET_STATES.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
                this.ChangeState(null);
                // this.Invoke(
                //     this.svc.getStatesByCountryId(country.CountryId),
                //     (result: DataResult<State[]>) => {
                //         if (typeof result.Success !== 'undefined') {
                //             if (result.Success) {
                //                 this.states = result.Data.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
                //                 this.ChangeState(null);
                //             } else {
                //                 console.error(result);
                //             }
                //         } else {
                //             console.error(result);
                //         }
                //     }
                // );
            }
        } else {
            this.selectedCountry = country;
            this.states = [];
            this.ChangeState(null);
        }
    }

    ChangeState(state: State) {
        this.selectedState = state;
        this.postcodeSearchTextControl.setValue('', { emitEvent: false });
        this.availablePostcodes = [];
        this.selectedPostcodes = [];
        this.RefreshSuburbList();
        this.facilitiesDS.data = [];
        this.employersDS.data = [];
    }

    LoadFacilitiesByRegionId(regionId: number) {
        const request = new SearchPaginationRequest();
        request.Params = [{ Name: 'region_id', Value: regionId }];
        request.PageSize = 0;
        request.ViewColumns = ['address', 'suburb', 'postcode', 'state', 'country',
            'phone_number', 'access_card_fee', 'staffed_hours', 'website_url', 'services'];
        this.Invoke(
            this.facilitySvc.search(request),
            (res: string) => {
                const dectypted_data = this.commonSvc.D_FP_ResponseData(res);
                const rdata = JSON.parse(dectypted_data);
                console.log(rdata);
                if (rdata.Success && rdata.Data.Results instanceof Array) {
                    this.googlemap.startMapLoadSpinner(); 
                    this.setFacilitiesDataSource(rdata.Data.Results);
                    this.googlemap.LoadFacilitiesByRegionId(regionId);
                    console.log(rdata);
                } else {
                    console.error(rdata);
                }
            }
        );
    }

    LoadFacilitiesByPostcodeId(postcodeId: number) {
        const request = new SearchPaginationRequest();
        request.Params = [{ Name: 'postcode_id', Value: postcodeId }];
        request.PageSize = 0;
        request.ViewColumns = ['address', 'suburb', 'postcode', 'state', 'country',
            'phone_number', 'access_card_fee', 'staffed_hours', 'website_url', 'services'];
        this.Invoke(
            this.facilitySvc.search(request),
            (res: string) => {
                const dectypted_data = this.commonSvc.D_FP_ResponseData(res);
                const rdata = JSON.parse(dectypted_data);
                if (rdata.Success && rdata.Data.Results instanceof Array) {
                    const data = rdata.Data.Results
                        .filter(newFacility => this.facilitiesDS.data.findIndex(oldFacility => oldFacility.PostcodeId !== newFacility.PostcodeId) > -1);
                    this.facilitiesDS.data = this.facilitiesDS.data.concat(data);
                } else {
                    console.error(res);
                }
            }
        );
    }

    RemoveFacilitiesByPostcodeId(postcodeId: number) {
        if (this.facilitiesDS === undefined) {
            return;
        }
        this.facilitiesDS.data = this.facilitiesDS.data
            .filter(f => f.PostcodeId !== postcodeId);
        // this.setFacilitiesMapData(this.facilitiesDS.data);
    }

    LoadEmployersByRegionId(regionId: number) {
        this.Invoke(
            this.employerSvc.getByRegion(regionId),
            (data: EmployerRegion[]) => {
                if (data instanceof Array) {
                    this.setEmployersDataSource(data);
                } else {
                    console.error(data);
                }
            }
        );
    }

    LoadEmployersByPostcodeId(postcodeId: number) {
        this.Invoke(
            this.employerSvc.getByPostcode(postcodeId),
            (result: DataResult<EmployerRegion[]>) => {
                if (typeof result.Success !== 'undefined') {
                    if (result.Success && result.Data instanceof Array) {
                        if (result.Data.length > 0) {
                            this.employersDS.data = this.employersDS.data.concat(result.Data);
                        }
                    } else {
                        console.error(result);
                    }
                } else if (result != null) {
                    console.error(result);
                }
            }
        );
    }

    RemoveEmployersByPostcodeId(postcodeId: number) {
        this.employersDS.data = this.employersDS.data.filter(e => e.PostcodeId !== postcodeId);
    }

    ToggleMode(editable: boolean) {
        this.isEditMode = editable;
        if (editable) {
            this.StatusCtrl.enable({ emitEvent: false });
            this.txtPDBDNotesControl.enable({ emitEvent: false });
            this.OpenLargeEmployersCtrl.enable({ emitEvent: false });
            this.OpenSmallAdditionsCtrl.enable({ emitEvent: false });
            this.Under50EmployeesCtrl.enable({ emitEvent: false });
            this.regionETAOpening.enable({ emitEvent: false });
        } else {
            this.StatusCtrl.disable({ emitEvent: false });
            this.txtPDBDNotesControl.disable({ emitEvent: false });
            this.OpenLargeEmployersCtrl.disable({ emitEvent: false });
            this.OpenSmallAdditionsCtrl.disable({ emitEvent: false });
            this.Under50EmployeesCtrl.disable({ emitEvent: false });
            this.regionETAOpening.disable({ emitEvent: false });
        }
    }

    Discard() {
        if (this.isDirty) {
            MessageBox.ShowCustom(this.dialog,
                CommonMessage.DISCARD_CHANGES_CONFIRMATION_TITLE,
                CommonMessage.DISCARD_CHANGES_CONFIRMATION_TITLE,
                CommonMessage.DISCARD_CHANGES_CONFIRMATION_MESSAGE, MessageBoxButton.OkCancel)
                .subscribe(
                    dialogResult => {
                        if (dialogResult.result === DialogResult.Ok) {
                            this.postcodeSearchTextControl.setValue('', { emitEvent: false });
                            this.markFormAsPristine();
                            this.ToggleMode(!this.isEditMode);
                            this.ngOnInit();
                        }
                    }
                );
        } else {
            this.ToggleMode(!this.isEditMode);
        }
    }

    Validate(region: Region): boolean {
        this.StatusCtrl.updateValueAndValidity();
        const valid = region.Name != null &&
            this.regionNameViewModel.control.valid &&
            this.CountryCtrl.valid &&
            this.StateCtrl.valid &&
            this.StatusCtrl.valid &&
            (region.IsActive || !this.hasActiveFacilitiesAndServices()) &&
            region.Postcode.length > 0;
        this.isValidated = true;
        return valid;
    }

    Dirty() {
        this.isDirty = true;
        this.isValidated = false;
    }

    private markFormAsPristine() {
        this.regionNameViewModel.control.markAsPristine();
        this.CountryCtrl.markAsPristine();
        this.StateCtrl.markAsPristine();
        this.postcodeSearchTextControl.markAsPristine();
        this.StatusCtrl.markAsPristine();
        this.isDirty = false;
        this.isValidated = false;
    }

    private hasActiveFacilitiesAndServices() {
        return this.facilitiesDS.data.find(f =>
            f.FacilityStatusId === EFacilityStatus.Active ||
            (f.FacilityStatus && f.FacilityStatus.FacilityStatusId === EFacilityStatus.Active) ||
            (f.Services.find(s => s.ServiceStatusId === EServiceStatus.Active) != null)) != null;
    }

    //#region Event handlers
    txtNewRegionAssignment_Blur(e) {
        if (this.NewRegionAssignmentCtrl.dirty) {
            this.NewRegionAssignmentCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
            this.NewRegionAssignmentCtrl.updateValueAndValidity();
        }
        this.parentFocus = 0;
    }

    txtNewRegionAssignment_Input(e) {
        if (this.NewRegionAssignmentCtrl.dirty) {
            if (typeof this.NewRegionAssignmentCtrl.value === 'string' &&
                this.NewRegionAssignmentCtrl.value.length === 0 &&
                this.selectedNewRegion != null) {
                this.selectedNewRegion = null;
            }
            this.NewRegionAssignmentCtrl.setValidators(Validators.required);
        }
    }

    btnUpdateRegion_Click() {
        const updatedRegion = Object.assign({}, this.currentRegion);
        updatedRegion.Name = this.regionName;
        updatedRegion.Description = this.regionDescription;
        updatedRegion.Postcode = this.selectedPostcodes.slice(0);
        updatedRegion.IsActive = this.StatusCtrl.value;
        updatedRegion.OpenForLargeEmployerId = this.OpenLargeEmployersCtrl.value;
        updatedRegion.OpenForSmallAdditionId = this.OpenSmallAdditionsCtrl.value;
        updatedRegion.Under50EmployerId = this.Under50EmployeesCtrl.value;
        updatedRegion.EtaonOpening = this.regionETAOpening.value;
        updatedRegion.WaitListedEmployer = this.regionWaitListedEmployer;
        updatedRegion.RegionNotes = this.dataSourceNotes.data;
        updatedRegion.ModifiedBy = this.commonSvc.GetUser();
        if (!this.Validate(updatedRegion)) {
            return;
        }

        updatedRegion.Postcode.forEach(p => {
            delete p.Suburb;
            delete p.State;
        });
        this.Invoke(
            this.svc.update(updatedRegion),
            (result: ResultModel) => {
                if (result.Success) {
                    this.currentRegion = updatedRegion;
                    MessageBox.ShowCustom(this.dialog, 'Information', 'Information', CommonMessage.DATA_SAVE_SUCCESS_MESSAGE).subscribe(
                        (dialogResult) => {
                            this.regionNameInTitleArea = this.currentRegion.Name;
                            this.postcodeSearchTextControl.setValue('', { emitEvent: false });
                            this.markFormAsPristine();
                            this.ToggleMode(false);
                            this.ngOnInit();
                        });
                } else {
                    this.errorHandler(result, 'Unable to update region. Please contact administrator');
                }
            });
    }
    //#endregion Event handlers
    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }
    onBlurParent(focusNumbr) {
        this.parentFocus = focusNumbr;
    }
    getClassFocused(vlFocused) {
        if (this.parentFocus === vlFocused) {
            return 'focused';
        } else {
            return '';
        }
    }
    formatTime(time) {
        const hours = (time.split(':'))[0];
        const mins = (time.split(':'))[1];
        return (hours + ':' + mins);
    }
}
