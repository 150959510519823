import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subject, interval } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-fp-progress-bar',
  templateUrl: './fp-progress-bar.component.html',
  styleUrls: ['./fp-progress-bar.component.css']
})

export class FpProgressBarComponent implements OnInit {

  private openModal: NgbModalRef;
  /** Sets the Status Text of the Progress Bar on .next("NewStatus"); */
  public upload_status_subject: Subject<string> = new Subject();
  /** Sets the Status Percentage of the Progress Bar on .next("NewPercentage"); */
  public upload_percent_subject: Subject<number> = new Subject();
  /** Closes the Progress Bar on .next(true); */
  public close_subject: Subject<boolean> = new Subject();
  /** Closes the Progress Bar on .next(true); */
  public allclose_subject: Subject<boolean> = new Subject();
  /** The current Percentage of the Modal */
  public percent: number = 0;
  /** The current Status of the Modal */
  public status: string = "";

  @ViewChild('content') modalcontent: ElementRef;

  constructor(
    private modalService: NgbModal,
    protected dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.upload_percent_subject.pipe(debounceTime(50)).subscribe(val => {
      this.percent = val;
    });

    this.upload_status_subject.pipe(debounceTime(50)).subscribe(val => {
      this.status = val;
    });

    this.close_subject.pipe(
      debounceTime(200)
    ).subscribe(val => {
      this.allclose_subject.next(true);
    });

    this.allclose_subject.pipe(
      debounceTime(200)
    ).subscribe(val => {
      this.status = '';
      this.Close();
      this.ResetAllConfig();
    });
  }

  /**
   * Sets the Percent Timer to 0 and begins filling it according to time.
   * @param timeToFull The time to fill the Progress Bar by in Seconds.
   */
  public StartPercentTimer(timeToFull: number = 20) {
    this.upload_percent_subject.next(0);

    const intervalCount = interval(10 * timeToFull);
    intervalCount.subscribe(() =>
      this.upload_percent_subject.next(this.percent < 95 ? this.percent + 1 : 95)
    );
  }

  /**
   * Opens this Progress Bar Modal
   * @param status The Status to start the Progress Bar with.
   */
  public Open(status: string = "") {
    this.openModal = this.modalService.open(this.modalcontent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    this.openModal.result.then(
      (result) => {
      },
      (reason) => {
      }
    );

    this.upload_status_subject.next(status);
  }

  /**
   * Closes this Modal
   */
  private Close() {
    if (this.openModal) {
      this.openModal.close();
    }
  }

  /**
   * Resets the config for this Modal
   */
  private ResetConfig() {
    this.percent = 0;
  }

  /**
   * Resets the Config for all modals
   */
  private ResetAllConfig() {
    this.percent = 0;
  }

}
