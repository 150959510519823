import { Component, Injector, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FPFormBaseComponent, IFormSharedData } from '@fp/components/base';
import { CommonConstants } from '@fp/constant/common-constants';
import { CommonMessage, CommonString } from '@fp/constants';
import { CommonDataType, EEmployerStatus } from '@fp/enums';
import { DateHelper, StringHelper, Utils } from '@fp/helpers';
import { CommonData, DataResult, ReportRequest, SearchExpressionLogicGroup, SearchFieldOperator, State } from '@fp/models';
import { CommonDataService, MembershipPackagesService } from '@fp/services';
import { NgbDateAdapter, NgbDateNativeUTCAdapter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-employer-membership-package-exporter',
    templateUrl: './membership-package-exporter.component.html',
    styleUrls: ['./membership-package-exporter.component.css'],
    providers: [
        FPFormBaseComponent.provideExisting(EmployerMembershipPackageExporterComponent),
        { provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter }]
})
export class EmployerMembershipPackageExporterComponent extends FPFormBaseComponent<IFormSharedData> {
    membershipPkgform: UntypedFormGroup;
    states: State[];
    selectedStates: State[] = [];
    statuses: CommonData[];
    StateCtrl = new UntypedFormControl();
    statusId: number;

    @ViewChild('dpLastPriceChangeFrom') private dpLastPriceChangeFrom: NgbInputDatepicker;
    @ViewChild('dpLastPriceChangeTo') private dpLastPriceChangeTo: NgbInputDatepicker;

    CommonMessage = CommonMessage;
    CommonString = CommonString;
    StringHelper = StringHelper;

    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            States: [[]],
            Status: [EEmployerStatus.Active],
            LastPriceChangeFrom: [null],
            LastPriceChangeTo: [null]
        });
    }

    constructor(injector: Injector,
        private commonDataSvc: CommonDataService,
        private membershipPackageSvc: MembershipPackagesService) {
        super(injector);
        this.membershipPkgform = EmployerMembershipPackageExporterComponent.getFormGroup();
    }

    ngAfterViewInit() {
        this.OnLoad();
    }

    OnLoad() {
        this.InvokeBatch([
            this.commonDataSvc.getData(CommonDataType.EmployerStatus)
        ], {
            onSuccess: (res: any[]): void => {
                if (res instanceof Array) {
                    this.handleGetStatesResult();
                    this.handleEmployerStatusesResult(res[0]);
                    super.OnLoad();
                } else {
                    this.handleError(res);
                }
            }
        }
        );
    }

    LoadComplete() {
        setTimeout(() => {
            this.StateCtrl.valueChanges.subscribe((index: number) => {
                this.addState(index);
            });

            this.getControl('LastPriceChangeFrom').valueChanges.subscribe((value: Date) => {
                this.dpLastPriceChangeTo.minDate = this.dpLastPriceChangeFrom['_model'];
                this.getControl('LastPriceChangeTo').updateValueAndValidity({ emitEvent: false });
            });

            this.getControl('LastPriceChangeTo').valueChanges.subscribe((value: Date) => {
                this.dpLastPriceChangeFrom.maxDate = this.dpLastPriceChangeTo['_model'];
                this.getControl('LastPriceChangeFrom').updateValueAndValidity({ emitEvent: false });
            });
        });
        super.LoadComplete();
    }

    private handleGetStatesResult() {
        this.states = CommonConstants.GET_STATES.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
    }

    private handleEmployerStatusesResult(res: DataResult<CommonData[]>) {
        if (res.Success) {
            this.statuses = Utils.sortCommonData(
                res.Data.filter(_item => [
                    EEmployerStatus.Active,
                    EEmployerStatus.Onboarding,
                    EEmployerStatus.CloseForNewMembers].indexOf(_item.Id) > -1));
            this.membershipPkgform.get('Status').setValue(null);
        } else {
            this.HandleResponseError(res);
        }
    }

    private swapItem(index: number, source: any[], target: any[]) {
        const item = source.splice(index, 1)[0];
        target.push(item);
    }

    private addState(index: number) {
        if (index != null) {
            let appendedStateIds = [];
            if (index === -1) {
                // Add all states to selected list
                const appendedStates = this.states.splice(0);
                appendedStateIds = appendedStates.map(st => st.StateId);
                this.selectedStates.push(...appendedStates);
            } else {
                appendedStateIds.push(this.states[index].StateId);
                this.swapItem(index, this.states, this.selectedStates);
            }
            // Reset selection
            this.StateCtrl.setValue(null, { emitEvent: false });
            this.selectedStates = this.selectedStates.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
            this.getControl('States').setValue(this.selectedStates);
        }
    }
    /** @internal */
    _removeState(index: number) {
        this.swapItem(index, this.selectedStates, this.states);
        this.states = this.states.sort((a, b) => a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase() ? -1 : 1);
        this.getControl('States').setValue(this.selectedStates);
    }

    //FP-1073
    onStatusSelectionChange(selectedStatusId: number | null){
        this.getControl('Status').setValue(selectedStatusId);
        const id = selectedStatusId != null ? parseInt(selectedStatusId.toString().split(':')[1], 10) : null;
        this.statusId = id;
    }

    saveAs(uri: string, fileName: string) {
        this.Invoke(
            Utils.getFileBlob(uri),
            {
                onSuccess: (blob: Blob) => {
                    const url = URL.createObjectURL(blob);
                    const linkElem = document.createElement('a');
                    linkElem.setAttribute('href', url);
                    linkElem.setAttribute('download', fileName);

                    if (typeof linkElem.download === 'string') {
                        document.body.appendChild(linkElem); // Firefox requires the link to be in the body

                        if (document.createEvent) {
                            const event = document.createEvent('MouseEvents');
                            event.initEvent('click', true, true);
                            linkElem.dispatchEvent(event);
                        } else {
                            linkElem.click();
                        }

                        document.body.removeChild(linkElem); // remove the link when done
                    } else if (typeof navigator.msSaveBlob === 'function') {
                        // IE does not support 'download' attribute of <a> element. Use the built-in "msSaveBlob" method instead.
                        if (!navigator.msSaveBlob(blob, fileName)) {
                            this.handleError('Attempts to save file in IE unsuccessfully', 'An unknown error occurred when trying to save file');
                        }
                    } else {
                        // Fallback to open the file from address bar.
                        location.replace(url);
                    }
                },
                onError: err => {
                    if (err instanceof Response && err.status === 404) {
                        this.handleError(err, 'The following requested file could not be found: ' + uri);
                    } else {
                        this.handleError(err);
                    }
                }
            });
    }

    Export() {
        if (this.membershipPkgform.invalid) {
            return;
        }
        const criteria = <ReportRequest>{
            ReportType: 'csv',
            Criteria: {
                LogicGroup: SearchExpressionLogicGroup.And,
                Fields: []
            }
        };
        if (!isNaN(this.statusId)) {
            criteria.Criteria.Fields.push({
                Name: 'employer_status_id',
                Operator: SearchFieldOperator.Equals,
                Value: this.statusId
            });
        } else {
            this.statusId = 0;
            criteria.Criteria.Fields.push({
                Name: 'all',
                Operator: SearchFieldOperator.Equals,
                Value: this.statusId
            });
        }
        if (this.getControl('States').value instanceof Array && this.getControl('States').value.length > 0) {
            criteria.Criteria.Fields.push({
                Name: 'employer_state_ids',
                Operator: SearchFieldOperator.In,
                Value: (<State[]>this.getControl('States').value).map(_item => _item.StateId)
            });
        }
        if (this.getControl('LastPriceChangeFrom').value && this.getControl('LastPriceChangeTo').value) {
            criteria.Criteria.Fields.push(
                {
                    Name: 'last_price_change_date_from',
                    Operator: SearchFieldOperator.GreaterThanOrEquals,
                    Value: this.getControl('LastPriceChangeFrom').value
                },
                {
                    Name: 'last_price_change_date_to',
                    Operator: SearchFieldOperator.LessThanOrEquals,
                    Value: this.getControl('LastPriceChangeTo').value
                });
        }
        this.Invoke(
            this.membershipPackageSvc.generateReport(criteria),
            {
                onSuccess: res => {
                    if (res.Success) {
                        const url = res.Data;
                        const fileName = StringHelper.format('EmployerMembershipPackagePrice_{0}.csv', DateHelper.format(new Date(), 'DDMMMYY'));
                        this.saveAs(url, fileName);
                    } else {
                        this.HandleResponseError(res);
                    }
                }
            });
    }
}
