export class ActivityConstant {
    public static CYCLING = 'Cycling';
    public static GROUP_FITNESS = 'Group Fitness';
    public static GYM_ACCESS = 'Gym Access';
    public static HIIT = 'HIIT';
    public static LADIES_ONLY = 'Ladies Only';
    public static PILATES = 'Pilates';
    public static POOL = 'Pool';
    public static YOGA = 'Yoga';
    public static ACCESSIBLE_OPTIONS = "Accessible Options";
    public static FACILITY_ACCESSIBLE_OPTIONS = "Accessible Options (See Additional Info)";
    public static CYCLING_ICONURL = 'assets/images/facility-icon-pin/Activity Icon_Cycling - Pin.png';
    public static GROUP_FITNESS_ICONURL = 'assets/images/facility-icon-pin/Activity Icon_Group Fitness - Pin.png';
    public static GYM_ACCESS_ICONURL = 'assets/images/facility-icon-pin/Activity Icon_Gym Access - Pin.png';
    public static HIIT_ICONURL = 'assets/images/facility-icon-pin/Activity Icon_HIIT - Pin v2.png';
    public static LADIES_ONLY_ICONURL = 'assets/images/facility-icon-pin/Activity Icon_Ladies Only - Pin.png';
    public static PILATES_ICONURL = 'assets/images/facility-icon-pin/Activity Icon_Pilates - Pin.png';
    public static POOL_ICONURL = 'assets/images/facility-icon-pin/Activity Icon_Pool - Pin v2.png';
    public static YOGA_ICONURL = 'assets/images/facility-icon-pin/Activity Icon_Yoga - Pin.png';
    public static ACCESSIBLE_OPTIONS_ICONURL = 'assets/images/facility-icon-pin/Activity Icon_Accessible_Options - Pin.png';
    public static DEFAULT_ICONURL = 'assets/images/fp-map-icon.png';
}


