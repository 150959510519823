import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PatternConstant } from '@fp/constant';
import { CommonService, MessageBox, UserService } from '@fp/services';
import { BaseControlComponent, MakeProvider } from '../base/base-control';
import { empty, merge } from 'rxjs';
import { catchError, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-mobile-number-country',
  templateUrl: './mobile-number-country.component.html',
  styleUrls: ['./mobile-number-country.component.css'],
  host: { '(blur)': 'onTouched()' },
  providers: [MakeProvider(MobileNumberCountryComponent)]
})
export class MobileNumberCountryComponent<T> extends BaseControlComponent<string> implements OnInit {

  countryPhoneCode = "";
  countryCodeSelect = '+61';
  @Input() countryCodeSelectEmergency: string;
  @Input() control: AbstractControl; // required
  @Input() label: string;
  @Input() placeholder: string;
  @Input() mandatory: boolean;
  @Input() submitted: boolean; // flag when click on submit button
  @Input() readonly: boolean;
  @Input() mobileLength = 13;
  @Input() pattern = PatternConstant.PATTERN_PHONE;
  @Input() checkExist: false;
  @Input() clsLabel: string;
  @Input() clsGroup: string;
  @Input() currentPhone: string = "";
  @Output() runSingupCheck = new EventEmitter();

  _validators: ValidatorFn[];

  invalidPhone = false;
  phoneSpinter = false;
  parentFocus = 0;
  isAusValidationMessage: boolean;

  constructor(private userService: UserService,
    private dialog: MatDialog,
    private cmsrv: CommonService) {
    super();
    this._validators = [];
  }

  ngAfterViewInit() {
    this.countryPhoneCode = this.cmsrv.SetMobileCountryCode();
    this.countryCodeSelect = '+'.concat(this.countryPhoneCode);
    this.countryCodeSelectEmergency = '+'.concat(this.countryPhoneCode);
  }



  ngOnInit() {
  }

  private CheckValidatePhone() {
    if (this.control.value != null) {
      if (this.control.value.indexOf('00') === 0) {
        this.invalidPhone = true;
      } else {
        this.invalidPhone = false;
      }
    }
  }

  private SetValidator() {
    if (this.control.value == null) {
      return;
    } else {
      let number = ''
      if (this.control.value.includes("+61")) {
        this.mobileLength = 13;
        number = this.control.value.replace('+61', '');
        if (this.mandatory) {
          if (number.indexOf('0') === 0) {
            this.control.setValidators([Validators.required, Validators.pattern(PatternConstant.PATTERN_PHONE_5)]);
          } else {
            this.control.setValidators([Validators.required, Validators.pattern(PatternConstant.PATTERN_PHONE_4)]);
          }
        } else {
          if (number.indexOf('0') === 0) {
            this.control.setValidators([Validators.pattern(PatternConstant.PATTERN_PHONE_5)]);
          } else {
            this.control.setValidators([Validators.pattern(PatternConstant.PATTERN_PHONE_4)]);
          }
        }

      } else if (this.control.value.includes("+64")) {
        this.mobileLength = 15;
        number = this.control.value.replace('+64', '');
        if (this.mandatory) {
          if (number.indexOf('0') === 0) {
            this.control.setValidators([Validators.required, Validators.pattern(PatternConstant.PATTERN_PHONE_7)]);
          } else {
            this.control.setValidators([Validators.required, Validators.pattern(PatternConstant.PATTERN_PHONE_8)]);
          }
        } else {
          if (number.indexOf('0') === 0) {
            this.control.setValidators([Validators.pattern(PatternConstant.PATTERN_PHONE_7)]);
          } else {
            this.control.setValidators([Validators.pattern(PatternConstant.PATTERN_PHONE_8)]);
          }
        }
      } else {
        if (this.mandatory) {
          if (number.indexOf('0') === 0) {
            this.control.setValidators([Validators.required, Validators.pattern(PatternConstant.PATTERN_PHONE_5)]);
          } else {
            this.control.setValidators([Validators.required, Validators.pattern(PatternConstant.PATTERN_PHONE_4)]);
          }
        } else {
          if (number.indexOf('0') === 0) {
            this.control.setValidators([Validators.pattern(PatternConstant.PATTERN_PHONE_5)]);
          } else {
            this.control.setValidators([Validators.pattern(PatternConstant.PATTERN_PHONE_4)]);
          }
        }
      }
      this.CheckIsAUMobile()
    }
  }

  CheckIsAUMobile() {
    if (this.control.value.includes("+64")) {
      this.isAusValidationMessage = false;
    }
    else {
      this.isAusValidationMessage = true;
    }
  }

  public checkPhoneEmailResponsive() {

    let number = '';
    if (this.control.value.includes("+61")) {
      number = this.control.value.replace('+61', '');
    } else if (this.control.value.includes("+64")) {
      number = this.control.value.replace('+64', '');
    }

    if (number != null) {
      if (number.length >= 8) {
        this.runSingupCheck.emit('RunCheck');
      }
    }
  }

  public OnChange(event) {
    this.checkPhoneEmailResponsive();
    this.CheckValidatePhone();

    this.control.clearValidators();

    this.SetValidator();

    let val = event.target.value;
    const evkey = PatternConstant.WITH_PLUS_NOT_NUMBER;
    const re = new RegExp(evkey, 'g');
    val = val.replace(re, '');
    this.control.setValue(val);
  }

  public CallOnChange(value) {
    this.CheckValidatePhone();

    this.control.clearValidators();

    let val = value;
    const evkey = PatternConstant.NOT_NUMBER;
    const re = new RegExp(evkey, 'g');
    val = val.replace(re, '');
    this.control.setValue(val);
    this.SetValidator();
  }

  OnNotCheckExistPhone() {
    this.onTouched();
    this.onBlurParent(0);
  }

  CheckExistPhone() {
    this.onTouched();
    this.onBlurParent(0);
    merge().pipe(
      startWith({}),
      switchMap(() => {
        let number = '';
        let removeCountryCode = '';
        let withCountryCode = '';

        if (this.control.value.includes("+61")) {
          removeCountryCode = this.control.value.replace('+61', '');
          withCountryCode = '+61';
        } else if (this.control.value.includes("+64")) {
          removeCountryCode = this.control.value.replace('+64', '');
          withCountryCode = '+64';
        }

        const length = removeCountryCode.length;

        if (length >= 9 && length <= 12) {
          number = removeCountryCode.replace("0", '');
        } else {
          number = removeCountryCode;
        }

        let currentPhoneWC = "";
        if (this.currentPhone.includes("+61")) {
          currentPhoneWC = this.currentPhone.replace('+61', '');
        } else if (this.currentPhone.includes("+64")) {
          currentPhoneWC = this.currentPhone.replace('+64', '');
        }

        if (number.length > 0 && this.control.valid && !this.invalidPhone) {
          if (currentPhoneWC != number) {
            this.phoneSpinter = true;
            this.control.setErrors({ waitingcheck: true });
            return this.userService.checkExistPhonev2(withCountryCode + number);
          }
        } else {
          this.phoneSpinter = false;
        }
        return empty();
      }),
      catchError((e) => { throw e; }))
      .subscribe(
        (result) => {
          const enctypted_data = result.Data;
          const dectypted_data = this.cmsrv.D_FP_ResponseData(enctypted_data);
          const ret = JSON.parse(dectypted_data);
          this.phoneSpinter = false;
          this.control.setErrors({ waitingcheck: false });
          this.control.updateValueAndValidity();
          if (result.Success) {
            if (result.Data !== null && ret.IsExist) {
              this.control.setErrors({ duplicated: true });
            } else {
              this.control.setErrors(null);
            }
          } else {
            MessageBox.ShowError(this.dialog, 'An error has occurred. Please contact the administrator');
          }
        });
  }
  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }
  onBlurParent(focusNumbr) {
    this.parentFocus = focusNumbr;
  }
  getClassFocused(vlFocused) {
    if (this.parentFocus === vlFocused) {
      return 'focused';
    } else {
      return '';
    }
  }
}
