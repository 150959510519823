import { Component, ElementRef, Injector, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { FacilityFormBase } from "@fp/components/base";
import { CommonMessage, CommonString } from "@fp/constants";
import { EFacilityStatus } from "@fp/enums";
import { FPValidators, StringHelper } from "@fp/helpers";
import {
  DataResult,
  Facility,
  FacilityStatus,
  FacilityType,
  FacilityCategory,
  SearchPaginationRequest,
  SearchPaginationResult,
  Message,
} from "@fp/models";
import { FacilityService, MessageService } from "@fp/services";
import {
  NgbDateAdapter,
  NgbDateNativeUTCAdapter,
  NgbDateStruct,
  NgbInputDatepicker,
} from "@ng-bootstrap/ng-bootstrap";
import { Subscription, empty } from "rxjs";
import { catchError, debounceTime, switchMap } from "rxjs/operators";
import { ParentGroup } from 'src/app/models/parent-group.model';

@Component({
  selector: "app-facility-identification-details",
  templateUrl: "./facility-identification-details.component.html",
  styleUrls: ["./facility-identification-details.component.css"],
  providers: [
    FacilityFormBase.provideExisting(FacilityIdentificationDetailsComponent),
    { provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter },
  ],
})
export class FacilityIdentificationDetailsComponent extends FacilityFormBase {
  get FacilityNameCtrl() {
    return <UntypedFormControl>this.form.get("Name");
  }
  get CompanyNameCtrl() {
    return <UntypedFormControl>this.form.get("CompanyName");
  }
  get FacilityOwnerGroupCtrl() {
    return <UntypedFormControl>this.form.get("FacilityOwnerGroup");
  }
  get FacilityIdParentCtrl() {
    return <UntypedFormControl>this.form.get("FacilityIdParent");
  }
  get FacilityParentNameCtrl() {
    return <UntypedFormControl>this.form.get("FacilityParentName");
  }
  get ParentGroupCtrl() {
    return <UntypedFormControl>this.form.get("ParentGroup");
  }
  get FacilityStatusCtrl() {
    return <UntypedFormControl>this.form.get("FacilityStatus");
  }
  get FacilityTypeCtrl() {
    return <UntypedFormControl>this.form.get("FacilityType");
  }
  get FacilityCategoryCtrl() {
    return <UntypedFormControl>this.form.get("FacilityCategory");
  }
  get FacilityPhoneNumberCtrl() {
    return <UntypedFormControl>this.form.get("FacilityPhoneNumber");
  }
  get FacilityStartDateCtrl() {
    return <UntypedFormControl>this.form.get("FacilityStartDate");
  }
  get AbnCtrl() {
    return <UntypedFormControl>this.form.get("Abn");
  }
  get NoNewMemberDateCtrl() {
    return <UntypedFormControl>this.form.get("NoNewMembersDate");
  }

  //availableParentFacilityResults: Facility[] = [];
  availableParentGroupResults: ParentGroup[] = [];
  //selectedParentGroup: Facility = null;
  selectedParentGroup: ParentGroup = null;
  types: FacilityType[];
  categories: FacilityCategory[];
  statuses: FacilityStatus[];
  isCheckingName = false;
  isSearchingParent = false;
  previousStatusId: number;
  facilitPhoneMaxLenght = 12;
  facilitPhoneMinLenght = 9;
  subscription: Subscription;

  StringHelper = StringHelper;
  CommonString = CommonString;
  CommonMessage = CommonMessage;

  model = {};
  parentFocus = 0;

  minDate: NgbDateStruct;
  EFacilityStatus = EFacilityStatus;
  currentDate = new Date();

  @ViewChild("dpNNM") dpNoNewMembers: NgbInputDatepicker;
  @ViewChild("identificationTemplate") identificationTemplate: ElementRef;

  static getFormGroup(): UntypedFormGroup {
    const fb = new UntypedFormBuilder();
    return fb.group({
      Code: [null],
      Name: [null, Validators.required],
      CompanyName: [null, Validators.required],
      FacilityOwnerGroup: [null, Validators.required],
      IsHeadOffice: [false],
      IsRestrictedFacility: [false],
      FacilityIdParent: [null],
      FacilityParentName: [null],
      ParentGroup: [null],
      FacilityType: [null, Validators.required],
      FacilityTypeId: [0],
      FacilityCategory: [null, Validators.required],
      FacilityCategoryId: [0],
      WebsiteUrl: [null],
      FacebookUrl: [null],
      FacilityPhoneNumber: [null, Validators.required],
      FacilityStartDate:[null],
      IsCriticalFacility: [false],
      IsFraudRisk: [false],
      Abn: [null, Validators.required],
      FacilityStatus: [null, Validators.required],
      FacilityStatusId: [0],
      NoNewMembersDate: [null],
      FacilityDesc: [null],
    });
  }

  constructor(injector: Injector, private svc: FacilityService, private messageService: MessageService) {
    super(injector);
  }

  ngAfterViewInit() {
    this.OnLoad();
  }

  OnLoad() {
    this.InvokeBatch([this.svc.getTypes(), this.svc.getCategories(), this.svc.getStatuses()], {
      onSuccess: (res) => {
        this.handleGetTypesResult(res[0]);
        this.handleGetCategoriesResult(res[1]);
        this.handleGetStatusesResult(res[2]);
        super.OnLoad();
      },
    });

    this.dpNoNewMembers.minDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
  }

  LoadComplete() {
    super.LoadComplete();
    setTimeout(() => {
      this.FacilityNameCtrl.valueChanges
        .pipe(
          debounceTime(500),
          switchMap(() => {
            const newName = this.FacilityNameCtrl.value
              ? this.FacilityNameCtrl.value.trim()
              : "";
            if (
              (!this.data.model.Name ||
                newName.toLocaleLowerCase() !==
                  this.data.model.Name.toLocaleLowerCase()) &&
              newName.length > 0
            ) {
              this.isCheckingName = true;
              return this.svc.getByName(this.FacilityNameCtrl.value);
            } else {
              this.isCheckingName = false;
            }
            return empty();
          }),
          catchError((e) => {
            throw e;
          })
        )
        .subscribe(
          (result: DataResult<Facility>) => {
            this.isCheckingName = false;
            if (result.Success) {
              if (result.Data != null) {
                this.FacilityNameCtrl.setErrors({ duplicated: true });
              } else {
                this.FacilityNameCtrl.setErrors(null);
              }
            } else {
              this.HandleResponseError(result);
            }
          },
          (error) => {
            this.isCheckingName = false;
            this.handleError(error);
          }
        );

      this.ParentGroupCtrl.valueChanges
        .pipe(
          debounceTime(500),
          switchMap(() => {
            if (typeof this.ParentGroupCtrl.value === "string") {
              const searchText = this.ParentGroupCtrl.value;
              if (searchText.length > 2) {
                this.isSearchingParent = true;
                const searchRequest: SearchPaginationRequest = {
                  OrderBy: "Name",
                  OrderByAsc: true,
                  PageNumber: 0,
                  PageSize: 0,
                  Params: [
                    {
                      Name: "Name",
                      Value: searchText,
                    },
                  ],
                  ViewColumns: ["name"],
                };
                return this.svc.searchParentGroup(searchRequest);
              } else {
                this.isSearchingParent = false;
              }
            }
            return empty();
          }),
          catchError((e) => {
            throw e;
          })
        )
        .subscribe(
          (result: string) => {
            const dectypted_data = this.commonSvc.D_FP_ResponseData(result);
            const rdata = JSON.parse(dectypted_data);
            this.isSearchingParent = false;
            if (rdata.Success) {
              if (rdata.Data != null) {
                this.availableParentGroupResults = rdata.Data.Results.filter(
                  (pg) => pg.ParentGroupId !== this.data.model.FacilityId
                );
                this.changeDetectorRef.detectChanges();
              }
            } else {
              this.HandleResponseError(rdata);
            }
          },
          (error) => {
            this.isSearchingParent = false;
            this.handleError(error);
          }
        );

      //no new members
      this.NoNewMemberDateCtrl.valueChanges.subscribe((result: Date) => {
        if (result) {
          if (this.FacilityStatusCtrl.value) {
            if (
              this.FacilityStatusCtrl.value.FacilityStatusId ===
              EFacilityStatus.NoNewMembers
            ) {
              this.FacilityStatusCtrl.setValue(null, { emitEvent: false });
              this.getControl("FacilityStatusId").setValue(null, {
                emitEvent: false,
              });
            }
          }

          let dateSelected = {
            year: result.getFullYear(),
            month: result.getMonth() + 1,
            day: result.getDate(),
          };
          if (
            JSON.stringify(dateSelected) ==
            JSON.stringify(this.dpNoNewMembers.minDate)
          ) {
            this.FacilityStatusCtrl.setValue(
              this.statuses.find(
                (s) => s.FacilityStatusId === EFacilityStatus.NoNewMembers
              ),
              { emitEvent: false }
            );
            this.getControl(
              "FacilityStatusId"
            ).setValue(EFacilityStatus.NoNewMembers, { emitEvent: false });
          }
        }
      });

      this.FacilityStatusCtrl.valueChanges.subscribe(
        (result: FacilityStatus) => {
          if (result) {
            if (this.previousStatusId === EFacilityStatus.NoNewMembers) {
              this.NoNewMemberDateCtrl.setValue(null, { emitEvent: false });
            }
            if (result.FacilityStatusId === EFacilityStatus.Potential) {
              let message: Message = {
                Identifier: CommonString.FacilityPotentialMessageIdentifier,
                Content: 'True',
              };
              this.messageService.changeMessage(message);
              this.updateValidators(false);
            } else {
              let message: Message = {
                Identifier: CommonString.FacilityPotentialMessageIdentifier,
                Content: 'False',
              };
              this.messageService.changeMessage(message);
              this.updateValidators(true);
            }
          }
          this.getControl("FacilityStatusId").setValue(
            result ? result.FacilityStatusId : null
          );

        }
      );

      this.FacilityTypeCtrl.valueChanges.subscribe((value: FacilityType) => {
        this.getControl("FacilityTypeId").setValue(
          value ? value.FacilityTypeId : null
        );
      });

      this.FacilityCategoryCtrl.valueChanges.subscribe((value: FacilityCategory) => {
        this.getControl("FacilityCategoryId").setValue(
          value ? value.FacilityCategoryId : null
        );
      });

      this.subscription = this.messageService.currentMessage.subscribe((message: Message) => {
        if (message && message.Identifier == CommonString.FacilityPotentialMessageIdentifier) {
          if (message.Content == 'True') {
            this.updateValidators(false);
  
          }
          else {
            this.updateValidators(true);
          }
        }
      });
    });
  }

  SetFacilityPhoneNumber(isAus: boolean){
    if(isAus){
      this.facilitPhoneMaxLenght = 10;
      this.facilitPhoneMinLenght = 9;
    }else{
      this.facilitPhoneMaxLenght = 12;
      this.facilitPhoneMinLenght = 7;
    }
  }

  PatchValue(value, opts) {
    this.NoNewMemberDateCtrl.clearValidators();
    // this.NoNewMemberDateCtrl.updateValueAndValidity();
    super.PatchValue(value, opts);
    // Restricted Facilities are marked as "Not being available for all employers"
    // IE there is data on the Employer Access Page indicating some employers can or cannot access the facility
    const IsRestrictedFacility = !this.data.model.IsAvailableForAllEmployers;
    this.form.get("IsRestrictedFacility").setValue(IsRestrictedFacility);
  }

  AutoComplete_DisplayWithFn(option: any) {
    if (!option) {
      return "";
    }
    return option.ParentGroupName || option.ParentGroupId;
  }

  private handleGetTypesResult(result: DataResult<FacilityType[]>) {
    if (result.Success) {
      this.types = result.Data.sort((a, b) => {
        if (a.DisplayOrder != null && b.DisplayOrder != null) {
          return a.DisplayOrder - b.DisplayOrder;
        } else {
          return a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase()
            ? -1
            : 1;
        }
      });
    } else {
      this.HandleResponseError(result);
    }
  }

  private handleGetCategoriesResult(result: DataResult<FacilityCategory[]>) {
    if (result.Success) {
      this.categories = result.Data.sort((a, b) => {
        if (a.DisplayOrder != null && b.DisplayOrder != null) {
          return a.DisplayOrder - b.DisplayOrder;
        } else {
          return a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase()
            ? -1
            : 1;
        }
      });
    } else {
      this.HandleResponseError(result);
    }
  }

  private handleGetStatusesResult(result: DataResult<FacilityStatus[]>) {
    if (result.Success) {
      this.statuses = result.Data.sort((a, b) => {
        if (a.DisplayOrder != null && b.DisplayOrder != null) {
          return a.DisplayOrder - b.DisplayOrder;
        } else {
          return a.Name.toLocaleLowerCase() < b.Name.toLocaleLowerCase()
            ? -1
            : 1;
        }
      });
    } else {
      this.HandleResponseError(result);
    }
  }

  private updateValidators(required: boolean) {
    let requiredFields = ['CompanyName', 'FacilityType', 'FacilityCategory', 'Abn', 'FacilityPhoneNumber', 'FacilityOwnerGroup'];
    requiredFields.forEach(fieldName => {
      let control = this.getControl(fieldName);
      if (control) {
        let validators = required ? [Validators.required] : [];
        control.setValidators(validators);
        control.updateValueAndValidity();
      }
    });
  }

  SelectParentFacility(parentGroup: ParentGroup) {
    this.selectedParentGroup = null;
    this.FacilityIdParentCtrl.setValue(parentGroup ? parentGroup.ParentGroupId : null);
    this.FacilityParentNameCtrl.setValue(parentGroup ? parentGroup.ParentGroupName : null);
    this.ParentGroupCtrl.setValue(parentGroup);
    // Clear current search results
    //this.availableParentFacilityResults = [];
  }

  txtParentGroup_Blur(e) {
    if (this.ParentGroupCtrl.dirty) {
      this.ParentGroupCtrl.setValidators(
        FPValidators.resolvableAutocompleteOption
      );
      this.ParentGroupCtrl.updateValueAndValidity();
    }
  }

  txtParentGroup_Input(e) {
    if (this.ParentGroupCtrl.dirty) {
      if (
        typeof this.ParentGroupCtrl.value === "string" &&
        this.ParentGroupCtrl.value.length == 0 &&
        this.selectedParentGroup != null
      ) {
        this.SelectParentFacility(null);
      }
      this.ParentGroupCtrl.setValidators(Validators.nullValidator);
    }
  }

  ddlTypeOption_CompareFn(opt1: FacilityType, opt2: FacilityType) {
    return (
      (!opt1 && !opt2) ||
      (opt1 && opt2 && opt1.FacilityTypeId === opt2.FacilityTypeId)
    );
  }

  ddlCategoryOption_CompareFn(opt1: FacilityCategory, opt2: FacilityCategory) {
    return (
      (!opt1 && !opt2) ||
      (opt1 && opt2 && opt1.FacilityCategoryId === opt2.FacilityCategoryId)
    );
  }


  ddlStatus_Focus(e) {
    this.previousStatusId = this.getControl("FacilityStatusId").value;
  }

  ddlStatusOption_CompareFn(opt1: FacilityStatus, opt2: FacilityStatus) {
    return (
      (!opt1 && !opt2) ||
      (opt1 && opt2 && opt1.FacilityStatusId === opt2.FacilityStatusId)
    );
  }

  onFocusParent(blurNumbr) {
    this.parentFocus = blurNumbr;
  }
  onBlurParent(focusNumbr) {
    this.parentFocus = focusNumbr;
  }
  getClassFocused(vlFocused) {
    if (this.parentFocus == vlFocused) {
      return "focused";
    } else {
      return "";
    }
  }
}
