
<form [formGroup]="medicalQuestionFormGroup">
    <div class="according-custom--panel-content-medical" >
        <div class="medical-content-item" *ngFor="let medicalQuestionAnswer of medicalQuestionAnswers">
            <div class="mediacal-content-wrapper row align-items-center">
                <div class="col-md-8">
                    <p>
                        {{medicalQuestionAnswer.MedicalQuestion.Question}}
                    </p>
                </div>
                <div class="col-md-4">
                    <mat-radio-group aria-label="Select an option"
                        class="d-flex w-md-100 mt-3 mt-md-0 justify-content-md-around">
                        <mat-radio-button value="1" [checked] = "medicalQuestionAnswer.Answer? true : false" (change)="markYesAnswer(medicalQuestionAnswer.MemberMedicalQuestionId)">Yes</mat-radio-button>
                        <mat-radio-button value="2" [checked] = "(medicalQuestionAnswer.Answer == null || medicalQuestionAnswer.Answer == true)? false : true" (change)="markNoAnswer(medicalQuestionAnswer.MemberMedicalQuestionId)">No</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>
</form>
