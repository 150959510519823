// Author: Da Do
// Description: This form is used to: Create new employer, Edit an existing Employer and Add clone an employer.
import { debounceTime, pairwise, distinctUntilChanged, catchError, map, startWith, switchMap, shareReplay, skipWhile } from 'rxjs/operators';
import {
    Component, OnInit, ChangeDetectorRef, AfterViewChecked,
    Injector, OnDestroy, ViewChild, ElementRef, AfterViewInit, HostListener
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { merge, Observable, of as observableOf, forkJoin, Subject, of, empty } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl } from '@angular/forms';
import { NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { CommonService } from '../../../services/common.service';
import { HttpDAO } from '../../../services/httpdao.service';
import { PatternConstant } from '../../../constant/patternconstant';
import { EmployerDetailModel } from '../../../models/employer.detail.model';
import { FpFileData, FpFileUploadComponent, FpFileEvent } from './../../control/fp-file-upload/fp-file-upload.component';
import {
    User, EmployerNoteModel, SearchPaginationCriteria, SuburbSearchResultRecord,
    EmployerFacilitatorModel, EmployerContractModel,EmployerSurveyModel,
    EmployerContractUploadFile, ResultDataModel, DataResult, CommonData, Suburb, Postcode, State, Country
} from 'src/app/models';
import { APIConstant } from '../../../constant/apiconstant';
import { RouterConstant } from '../../../constant/routerconstant';
import { MessageBox, MessageBoxButton } from '../../../services/common-dialog.service';
import { CommonResponse } from '../../../models/commonresponsemodel';
import { CommonString } from 'src/app/constant/common-string';
import { StringHelper } from 'src/app/helpers/string-helper';
import { Utils, DateHelper, DataSourceHelper, FPValidators } from 'src/app/helpers';
import { EmployerService } from 'src/app/services/employer.service';
import { UserService } from '../../../services/admin/user/user.service';

import { EmployerPageConstant } from './employerPageConstants';
import { FPAbstractComponent } from 'src/app/components/base';
import { StorageKey, CommonMessage } from 'src/app/constant';
import { EEmployerStatus } from 'src/app/enums';
import { SearchFieldOperator } from '../../../models/search-pagination-request.model';
import { EmployerMembershipPackageComponent } from './membership-package/membership-package.component';
import { EmployerAdditionalAttachmentsComponent } from './additional-attachments/additional-attachments.component';
import { EmployerBranchsComponent, EmployerBranchFormSharedData } from './branchs/branchs.component';
import { EmployerPageComponent, EmployerPageFormSharedData } from './page/page.component';
import { AuditLoggingService } from '@fp/services/audit-logging.service';
import { DialogResult } from 'src/app/components/common-dialog/common-dialog.component';
import { ValidatorMessageModel } from '@fp/models/validator-message.model';
import { AuditLogComponent } from "@fp/components/audit-log/audit-log.component";
import { RolePermissionService } from '@fp/services/role-permission.service';
import { PrivilegeModel } from '@fp/models/privilege.model';
import { CommonConstants } from '@fp/constant/common-constants';
import { DateTime } from 'aws-sdk/clients/devicefarm';
import { City } from '@fp/models/city.model';
import { AddressService } from '@fp/services/address.service';
import { SearchForSuggestionsResult } from 'aws-sdk/clients/location';
import { AddressLocation } from '@fp/models/address-location.model';
import { LocationService } from '@fp/services/location.service';
import { PhoneNumberComponent } from '@fp/components/control';
import { EmployerSurveyPageFormSharedData, SurveyPageComponent } from './survey-page/survey-page.component';



enum EmployerTab {
    EmployerDetails = 0,
    Contracts = 1,
    Facilitators = 2,
    BranchLocations = 3,
    MembershipPackages = 4,
    EmployerPage = 5,
    AdditionalAttachments = 6,
    AuditLog = 7
}

const AccountManageRoleName = {
    BUSINESS_DEVELOPMENT_OR_OPERATION: 1,
    BUSINESS_DEVELOPMENT: 2,
};

const FileAttachmetStatus = {
    Addnew: 'Addnew',
    Removw: 'Remove',
}

@Component({
    selector: 'app-addemployer',
    templateUrl: './addemployer.component.html',
    styleUrls: ['./addemployer.component.css']
})
export class AddemployerComponent extends FPAbstractComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
    [x: string]: any;
    heighttabs: string;
    btnNextClicked = false;
    btnNextEmployerPage = false;
    isEditMode: Boolean = false;
    nextDisabled: boolean = false;
    private cityList: City[];

    CommonMessage = CommonMessage;
    commonString = CommonString;
    stringHelper = StringHelper;
    EEmployerStatus = EEmployerStatus;
    Utils = Utils;

    EmployerDtlFormGroup: UntypedFormGroup;
    ContractFormGroup: UntypedFormGroup;
    FacilitatorFormGroup: UntypedFormGroup;
    RegionFormGroup: UntypedFormGroup;
    MembershipPackagesFormGroup: UntypedFormGroup;
    CustomFieldsFormGroup: UntypedFormGroup;

    isFromAustraliaPhy = true;
    isFromAustraliaMA = true;
    countriesId = CommonConstants.GET_COUNTRIES;
    title = 'Add Employer';
    isLinear = true;
    isReadonly = false;
    isSpinerShowName = false;
    isSpinerShowCode = false;
    isSpinerParentGroup = false;
    isSpinerAcc = false;
    isSpinerDevelopAcc = false;
    isSpinerPhysub = false;
    isSpinerPhyMail = false;

    pg_subject: Subject<string> = new Subject();
    acc_subject: Subject<string> = new Subject();
    bDevelopManage_subject: Subject<string> = new Subject();
    suphy_subject: Subject<string> = new Subject();
    sumail_subject: Subject<string> = new Subject();

    employerdtlobj: EmployerDetailModel;
    industrysectors: CommonResponse[] = [];
    employerstatus: CommonResponse[] = [];
    accmanagers: CommonResponse[] = [];
    emptCategories: CommonResponse[] = [];
    busicessDevelopManager: CommonResponse[] = [];
    emptypes: CommonResponse[] = [];
    suburbphys: SuburbSearchResultRecord[] = [];
    suburbmails: SuburbSearchResultRecord[] = [];
    postcodesphy: CommonResponse[] = [];
    postcodesmail: CommonResponse[] = [];
    statesPhy: CommonResponse[] = [];
    statesMA: CommonResponse[] = [];
    eligibleEmps: CommonData[] = [];
    oEligibleEmps: CommonData[] = [];
    selectedEligibleEmps: CommonData[] = [];
    paymentperiods: CommonResponse[] = [];
    countries: CommonResponse[] = [];
    citySearchResults: City[];
    cityMailSearchResults: City[];

    txtParentGroupControl = new UntypedFormControl();        
    txtAccountManagerControl = new UntypedFormControl('', [Validators.required]);
    txtBDevManagerControl = new UntypedFormControl('', [Validators.required]);

    filteredPGroup: Observable<CommonResponse[]> = of([]);
    filteredSuburbPhy: Observable<SuburbSearchResultRecord[]>;
    filteredSuburbMail: Observable<SuburbSearchResultRecord[]>;
    filteredACCGroup: Observable<CommonResponse[]>;
    filteredBDevelopManager: Observable<CommonResponse[]>;

    parentgroups: CommonResponse[] = [];

    httpdao: HttpDAO | null;
    panelDetail: string[] = ['panelDetail'];

    command = '';
    ADDNEW = 'addnew';
    ADDCLONE = 'addclone';
    EDIT = 'edit';
    READ_ONLY = 'readonly';
    tabpassed: number[] = [0];
    disableNextPaymentRunDate = true;

    ckeConfig: any;

    // Author: Yen
    searchUsers: EmployerFacilitatorModel[] = [];
    facilitatorsSelected: EmployerFacilitatorModel[] = [];
    surveySelected: EmployerSurveyModel[] = [];
    isUserSpinner = false;
    primaryFacilitator: number = null;
    facilitatorEmployers: EmployerFacilitatorModel[] = [];

    imageSrc: string = '';
    imageDefault = true;
    signedContract: EmployerContractUploadFile[] = [];
    attachAdditional: EmployerContractUploadFile[] = [];
    logoContract: EmployerContractUploadFile[] = [];
    logoSelected: EmployerContractUploadFile = new EmployerContractUploadFile();

    // Flag check employer page change
    EmployerPageChanged = false;
    // End

    //Vinh Hoang
    contractDurationEnable: boolean = false;
    // End---------------------

    length: number;
    pageSize: number;
    pageSizeOptions: number[] = [10, 20, 50];
    pageEvent: PageEvent;

    parentFocus = 0;
    // #Begin Additional Notes
    dataSourceNotes = new MatTableDataSource<EmployerNoteModel>();
    txtAdditionalEmployerNoteControl = new UntypedFormControl();
    // #End Additional Notes

    filteredAdressList: SearchForSuggestionsResult[];
    private selectedAddress1: AddressLocation;
    private suburbList: SuburbSearchResultRecord[];
    filteredAdressMailList: SearchForSuggestionsResult[];
    private selectedAddress1Mail: AddressLocation;
    addressSelected = false;
    suburbSelected = false;


    @ViewChild('stepper1', { static: true }) stepper1: MatStep;
    @ViewChild('stepper', { static: true }) stepper: MatStepper;
    @ViewChild('txtEmployerName') txtEmployerName: ElementRef;
    @ViewChild('ddlParentGroupAutoComplete') ddlParentGroupAutoComplete: MatAutocomplete;
    @ViewChild('ddlAccountManagerAutoComplete') ddlAccountManagerAutoComplete: MatAutocomplete;
    @ViewChild('ddlBDevManagerAutoComplete') ddlBDevManagerAutoComplete: MatAutocomplete;
    @ViewChild('ddlSuburbPhyAutoComplete') ddlSuburbPhyAutoComplete: MatAutocomplete;
    @ViewChild('ddlSuburbAutoComplete') ddlSuburbMailAutoComplete: MatAutocomplete;
    @ViewChild('dtpStartDate') dtpStartDateEle: ElementRef;
    @ViewChild('txtDescriptionHtml') txtDescriptionHtml: any;
    @ViewChild('tabfpstepper') tabfpstepper: ElementRef;
    @ViewChild('txtAttachAdditional') txtAttachAdditional: FpFileUploadComponent;
    @ViewChild('txtSignedContract') txtSignedContract: FpFileUploadComponent;
    @ViewChild('txtAttachLogo') txtAttachLogo: FpFileUploadComponent;
    @ViewChild('txtCDuration') txtCDuration: ElementRef<HTMLInputElement>;
    @ViewChild('membershipPackage') membershipPackage: EmployerMembershipPackageComponent;
    @ViewChild('additionalAttachments') additionalAttachs: EmployerAdditionalAttachmentsComponent;
    @ViewChild(AuditLogComponent) auditLog: AuditLogComponent;
    @ViewChild('notepagi') note_paginator: MatPaginator;
    @ViewChild("mobileNumberComponent") mobileNumber: PhoneNumberComponent<any>;

    displayedColumns1: string[] = ['user', 'dateTime', 'note'];

    //branch
    branchsData: EmployerBranchFormSharedData;
    @ViewChild(EmployerBranchsComponent) employerBranchs: EmployerBranchsComponent;
    branchFormGroup: UntypedFormGroup;

    //page
    pageData: EmployerPageFormSharedData;
    @ViewChild(EmployerPageComponent) employerPage: EmployerPageComponent;
    EmployerPageFormGroup: UntypedFormGroup;
    submitDisabled: boolean;
    @ViewChild('d1') private dpContractStartDate: NgbDatepicker;

    //survey-page
    surveypageData: EmployerSurveyPageFormSharedData;
    @ViewChild(SurveyPageComponent) employerSurveyPage: SurveyPageComponent;
    EmployerSurveyPageFormGroup: UntypedFormGroup;

    errorHandler: any;
    @ViewChild(MatSort) sort: MatSort;
    optTooltip: string;

    validlong: boolean = false;
    currentStartDate: DateTime;
    previousStartDate: DateTime;
    currentNextPDate: DateTime;
    previousNextPDate: DateTime;
    checkChangedStartDate: boolean = false;

    ngOnDestroy() {
        this.pg_subject = null;
        this.acc_subject = null;
        this.bDevelopManage_subject = null;
        this.suphy_subject = null;
        this.sumail_subject = null;

        this.employerdtlobj = null;
        this.industrysectors = null;
        this.employerstatus = null;
        this.accmanagers = null;
        this.busicessDevelopManager = null;
        this.emptypes = null;
        this.emptCategories = null;
        this.suburbphys = null;
        this.suburbmails = null;
        this.postcodesphy = null;
        this.postcodesmail = null;
        this.statesPhy = null;
        this.statesMA = null;
        this.eligibleEmps = null;
        this.oEligibleEmps = null;
        this.selectedEligibleEmps = null;
        this.paymentperiods = null;
        this.countries = null;

        this.txtParentGroupControl = null;             
        this.txtAccountManagerControl = null;
        this.txtBDevManagerControl = null;

        this.filteredPGroup = null;
        this.filteredSuburbPhy = null;
        this.filteredSuburbMail = null;
        this.filteredACCGroup = null;
        this.filteredBDevelopManager = null;

        this.parentgroups = null;

        this.httpdao = null;
        this.panelDetail = null;
        this.tabpassed = null;

        this.ckeConfig = null;

        this.searchUsers = null;
        this.facilitatorsSelected = null;
        this.primaryFacilitator = null;
        this.facilitatorEmployers = null;

        this.imageSrc = null;
        this.signedContract = null;
        this.attachAdditional = null;
        this.logoContract = null;
        this.logoSelected = null;

        this.length = null;
        this.pageSize = null;
        this.pageSizeOptions = null;

        this.parentFocus = null;
        this.dataSourceNotes = null;
        this.txtAdditionalEmployerNoteControl = null;
        this.displayedColumns1 = null;
    }

    constructor(
        injector: Injector,
        private _formBuilder: UntypedFormBuilder,
        private commonservice: CommonService,
        private http: HttpClient,
        private datePipe: DatePipe,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        private router: Router,
        protected dialog: MatDialog,
        private cdRef: ChangeDetectorRef,
        private svc: EmployerService,
        private userService: UserService,
        private auditService: AuditLoggingService,
        private addressService: AddressService,
        private locationservice: LocationService,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.isLinear = true;
        this.httpdao = new HttpDAO(this.http);
        this.SetValidator();
        this.GetUrlPath();
        this.panelDetail = ['panelDetail', 'panelCode', 'panelAddress', 'panelAddInf'];
        // Debounce Time for Parent Group
        this.pg_subject.pipe(
            debounceTime(APIConstant.API_DEBOUNCE_TIME)
        ).subscribe(val => {
            this.LoadParentGroup(val);
        });

        // Debounce Time for Account Manager
        this.acc_subject.pipe(
            debounceTime(APIConstant.API_DEBOUNCE_TIME)
        ).subscribe(val => {
            this.LoadAccountManager(val);
        });

        // Debounce Time for Account Manager
        this.bDevelopManage_subject.pipe(
            debounceTime(APIConstant.API_DEBOUNCE_TIME)
        ).subscribe(val => {
            this.LoadBusinessDevelopManager(val);
        });

        // Debounce Time for Suburb Physical
        this.suphy_subject.pipe(
            debounceTime(APIConstant.API_DEBOUNCE_TIME)
        ).subscribe(val => {
            this.LoadSuburbPhy(val);
        });

        // Debounce Time for Suburb Mail
        this.sumail_subject.pipe(
            debounceTime(APIConstant.API_DEBOUNCE_TIME)
        ).subscribe(val => {
            this.LoadSuburbMail(val);
        });

        // Init CK Editor
        this.ckeConfig = {
            allowedContent: true,
            extraPlugins: 'divarea',
            forcePasteAsPlainText: true,
            removeButtons: 'Radio,TextField,Textarea,Checkbox,Form,Select,Button,' +
                'ImageButton,HiddenField,CreateDiv,Flash,Iframe,PasteFromWord,Language' +
                ',Anchor,PageBreak,Maximize,ShowBlocks,About,Source,Save,NewPage,Print'
        };

        this.checkPrivilegesForReadOnly();

        this.LoadData();

        if (this.command == this.EDIT || this.command == this.READ_ONLY) {
            this.EmployerPageFormGroup.get('heading').setValue(' ');
            this.EmployerPageFormGroup.get('linkName').setValue(' ');
        }

        // Warm up API
        //this.warmupApiservice.WarmupAuthorizeHttpAPI();
        this.branchFormGroup = EmployerBranchsComponent.getFormGroup();
        if (this.commonservice.GetSelectedRole() === "Admin") {
            this.disableNextPaymentRunDate = false;
        };

        this.EmployerDtlFormGroup.get('ddlCountryPhy').valueChanges.subscribe((value: CommonResponse) => {
            if (value.Id !== this.EmployerDtlFormGroup.get('ddlCountryPhy').value) {
                this.changeCountryPhy(value);
            }
        });

        this.EmployerDtlFormGroup.get('ddlMACountry').valueChanges.subscribe((value: CommonResponse) => {
            if (value.Id !== this.EmployerDtlFormGroup.get('ddlMACountry').value) {
                this.changeCountryMA(value);
            }
        });
    }

    private changeCountryPhy(country: any) {
        if (country === "1" || country == 1) {
            this.isFromAustraliaPhy = true;
            this.statesPhy = CommonConstants.GET_STATE_NAMES;
            this.CityChangeEvent();
            this.CityCtrl.setValue(null);
            this.CityCtrl.setValidators(null);
            this.CityCtrl.clearValidators();
            this.CityCtrl.updateValueAndValidity();
        }
        else {
            this.isFromAustraliaPhy = false;
            this.statesPhy = CommonConstants.GET_NZ_STATE_NAMES;
            this.CityChangeEvent();
            this.StateCtrl.setValue(this.statesPhy[0].Id, { emitEvent: false });
            this.CityCtrl.setValidators(Validators.required);    
            this.CityCtrl.updateValueAndValidity();
        }
        this.setMobileValidations();
    }

    private setMobileValidations(){
        if(!this.isFromAustraliaPhy){
            this.mobileNumber.SetMobileLenghtValidators(false);
        }
        else{
            this.mobileNumber.SetMobileLenghtValidators(true);
        }
    }

    private changeCountryMA(country: any) {
        if (country === "1" || country == 1) {
            this.isFromAustraliaMA = true;
            this.statesMA = CommonConstants.GET_STATE_NAMES;
            this.CityMailChangeEvent();
            this.CityMailCtrl.setValue(null);
            this.CityMailCtrl.setValidators(null);
            this.CityMailCtrl.clearValidators();
            this.CityMailCtrl.updateValueAndValidity();
        }
        else {
            this.isFromAustraliaMA = false;
            this.statesMA = CommonConstants.GET_NZ_STATE_NAMES;
            this.CityMailChangeEvent();
            this.StateMailCtrl.setValue(this.statesMA[0].Id, { emitEvent: false });
            this.CityMailCtrl.setValidators(Validators.required);    
            this.CityMailCtrl.updateValueAndValidity();
        }
    }

    changeCountryStatesPhy(e) {
        this.Addressline1Ctrl.setValue(null, { emitEvent: false });
        this.CityCtrl.setValue(null, { emitEvent: false });
        this.SuburbPhyCtrl.setValue(null, { emitEvent: false });
        this.PostcodeCtrl.setValue(null, { emitEvent: false });
        if (this.isFromAustraliaPhy) {
            this.StateCtrl.setValue(null, { emitEvent: false });
        }

        this.RegionCtrl.setValue(null, { emitEvent: false });

        this.filteredAdressList = [];
        this.suburbphys = [];
        this.postcodesphy = [];

    }

    changeCountryStatesMail(e) {
        this.Addressline1MailCtrl.setValue(null, { emitEvent: false });
        this.CityMailCtrl.setValue(null, { emitEvent: false });
        this.SuburbMailCtrl.setValue(null, { emitEvent: false });
        this.PostcodeMailCtrl.setValue(null, { emitEvent: false });
        if (this.isFromAustraliaMA) {
            this.StateMailCtrl.setValue(null, { emitEvent: false });
        }

        this.filteredAdressMailList = [];
        this.suburbmails = [];
        this.postcodesmail = [];

    }

    selectEligibleEmpl(id: number) {
        if (id != -1) {
            this._addEligibleEmpls(id);
        }
    }

    private swapItem(index: number, source: any[], target: any[]) {
        const item = source.splice(index, 1)[0];
        target.push(item);
    }

    _removeEligible(index: number) {
        this.swapItem(index, this.selectedEligibleEmps, this.eligibleEmps);
        this.eligibleEmps = this.eligibleEmps.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    }

    _addEligibleEmpls(index: number) {
        if (index != -1) {
            this.swapItem(index, this.eligibleEmps, this.selectedEligibleEmps);
            // Reset selection
            this.EmployerDtlFormGroup.get('EligibleEmplsCtrl').setValue('-1', { emitEvent: false });
            this.selectedEligibleEmps = this.selectedEligibleEmps.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
        }
    }

    setOptTitle(name: string) {
        switch (name) {
            case 'Casual employees':
                return "Casual employees are paid by the organisation's payroll department and work more than 20 hours per week and have at least 3 months consistent working history";
            case 'Contract/Temporary employees':
                return "Contract/Temporary employees are paid by the organisation's payroll department and have at least 3 months consistent working history (or at least 6 months remaining on their existing contract)";
            default:
                return '';
        }
    }

    ngAfterViewInit() {
        switch (this.command) {
            case this.ADDNEW:
                this.isEditMode = false;
                break;
            case this.ADDNEW:
                this.isEditMode = false;
                break;
            case this.EDIT:
                this.isEditMode = true;
                break;
            default:
                this.isEditMode = false;
                break;
        }
        this.OnLoad();
    }

    ngAfterViewChecked() {
        if (!this.commonservice.App.mobileQuery.matches) {
            this.heighttabs = this.tabfpstepper
                .nativeElement
                .querySelector('#employertb' + this.stepper.selectedIndex)
                .clientHeight + 'px';

        } else {
            this.heighttabs = '100%';
        }

        this.cdRef.detectChanges();
    }

    public HandleStepsBarBgColor(index): string {
        if (this.stepper.selectedIndex === index) {
            return 'card-icon rounded-circle bg-primary mx-auto btn';
        } else {
            switch (index) {
                case EmployerTab.EmployerDetails:
                    return this.CheckBgofStep(this.EmployerDtlFormGroup.valid, EmployerTab.EmployerDetails);
                case EmployerTab.Contracts:
                    return this.CheckBgofStep(this.ContractFormGroup.valid, EmployerTab.Contracts);
                case EmployerTab.Facilitators:
                    return this.CheckBgofStep(this.FacilitatorFormGroup.valid, EmployerTab.Facilitators);
                case EmployerTab.BranchLocations:
                    return this.CheckBgofStep(this.RegionFormGroup.valid, EmployerTab.BranchLocations);
                case EmployerTab.MembershipPackages:
                    return this.CheckBgofStep(true, EmployerTab.MembershipPackages);
                case EmployerTab.EmployerPage:
                    return this.CheckBgofStep(this.EmployerPageFormGroup.valid, EmployerTab.EmployerPage);
                case EmployerTab.AdditionalAttachments:
                    return this.CheckBgofStep(true, EmployerTab.AdditionalAttachments);
                case EmployerTab.AuditLog:
                    return this.CheckBgofStep(true, EmployerTab.AuditLog);
            }
        }
    }

    private CheckBgofStep(stepvalid: boolean, index) {
        if (this.command == this.ADDNEW) {
            if (stepvalid == true && this.tabpassed.indexOf(index) > -1) {
                return 'card-icon rounded-circle bg-primary mx-auto btn';
            } else {
                return 'card-icon rounded-circle mx-auto btn';
            }
        } else if (this.command == this.EDIT) /* Edit Case */ {
            if (stepvalid == true) {
                return 'card-icon rounded-circle bg-primary mx-auto btn';
            } else {
                return 'card-icon rounded-circle mx-auto btn';
            }
        } else {
            return 'card-icon rounded-circle bg-primary mx-auto btn';
        }
    }

    public HandleTickofSteps(index): string {
        if (this.stepper.selectedIndex == index) {
            return '';
        } else {
            switch (index) {
                case EmployerTab.EmployerDetails:
                    return this.CheckTickofStep(this.EmployerDtlFormGroup.valid, EmployerTab.EmployerDetails);
                case EmployerTab.Contracts:
                    return this.CheckTickofStep(this.ContractFormGroup.valid, EmployerTab.Contracts);
                case EmployerTab.Facilitators:
                    return this.CheckTickofStep(this.FacilitatorFormGroup.valid, EmployerTab.Facilitators);
                case EmployerTab.BranchLocations:
                    return this.CheckTickofStep(this.RegionFormGroup.valid, EmployerTab.BranchLocations);
                case EmployerTab.MembershipPackages:
                    return this.CheckTickofStep(true, EmployerTab.MembershipPackages);
                case EmployerTab.EmployerPage:
                    return this.CheckTickofStep(this.EmployerPageFormGroup.valid, EmployerTab.EmployerPage);
                case EmployerTab.AdditionalAttachments:
                    return this.CheckTickofStep(true, EmployerTab.AdditionalAttachments);
                case EmployerTab.AuditLog:
                    return this.CheckTickofStep(true, EmployerTab.AuditLog);
            }
        }
    }

    private CheckTickofStep(stepvalid: boolean, index): string {
        if (this.command == this.ADDNEW) {
            if (stepvalid == true && this.tabpassed.indexOf(index) > -1) {
                return 'fas fa-check text-light';
            } else {
                return '';
            }
        } else if (this.command == this.EDIT)/* Edit Case */ {
            if (stepvalid == true) {
                return 'fas fa-check text-light';
            } else {
                return '';
            }
        } else {
            return 'fas fa-check text-light';
        }
    }

    //#region Employer Detail
    /**
     * Sets the view and all fields to read only if any read only attribute on user role is true
     */
    private checkPrivilegesForReadOnly(): void {
        const rolePermissionService: RolePermissionService = this.injector.get(RolePermissionService);

        this.isReadonly = rolePermissionService.checkPrivilegesForReadOnly('View/edit employers (Restrict to Read Only)');

        if (this.isReadonly) {
            this.command = this.READ_ONLY;
            this.title = 'View Employer';
            this.commonservice.EmployerID = this.route.snapshot.params.id;
        }
    }

    private CityChangeEvent() {
        let currentValue = '';
        if (!this.isFromAustraliaPhy) {
            this.CityCtrl.valueChanges.pipe(
                skipWhile((value) => (value && typeof value === 'object')),
                debounceTime(500),
                catchError((e) => { throw e; })
            ).subscribe((value: string) => {
                this.isSearchingCity = false;
                if (currentValue != this.CityCtrl.value) {
                    currentValue = this.CityCtrl.value;
                    let cityList: City[] = this.cityList;
                    cityList = cityList.filter(x => x.Name.toLowerCase().trim().includes(this.CityCtrl.value?.toString().toLowerCase().trim()));
                    cityList.sort((a, b) => a.Name.localeCompare(b.Name));
                    let uniqueCities: City[] = [];
                    cityList.map(x => uniqueCities.filter(a => a.Name == x.Name).length > 0 ? null : uniqueCities.push(x));
                    this.citySearchResults = uniqueCities
                    this.changeDetectorRef.markForCheck();
                }
            }, error => {
                this.isSearchingCity = false;
                MessageBox.ShowError(this.dialog, "Sorry, we're having trouble connecting. Let's try that again.")
                    .subscribe(res => {
                        if (res.result.toLowerCase() === DialogResult.Ok) {
                            window.location.reload();
                        }
                    });
                this._logger.error(error);
            });
        }
    }

    private CityMailChangeEvent() {
        let currentValue = '';
        if (!this.isFromAustraliaMA) {
            this.CityMailCtrl.valueChanges.pipe(
                skipWhile((value) => (value && typeof value === 'object')),
                debounceTime(500),
                catchError((e) => { throw e; })
            ).subscribe((value: string) => {
                this.isSearchingCityMail = false;
                if (currentValue != this.CityMailCtrl.value) {
                    currentValue = this.CityMailCtrl.value;
                    let cityList: City[] = this.cityList;
                    cityList = cityList.filter(x => x.Name.toLowerCase().trim().includes(this.CityMailCtrl.value?.toString().toLowerCase().trim()));
                    cityList.sort((a, b) => a.Name.localeCompare(b.Name));
                    let uniqueCities: City[] = [];
                    cityList.map(x => uniqueCities.filter(a => a.Name == x.Name).length > 0 ? null : uniqueCities.push(x));
                    this.cityMailSearchResults = uniqueCities
                    this.changeDetectorRef.markForCheck();
                }
            }, error => {
                this.isSearchingCityMail = false;
                MessageBox.ShowError(this.dialog, "Sorry, we're having trouble connecting. Let's try that again.")
                    .subscribe(res => {
                        if (res.result.toLowerCase() === DialogResult.Ok) {
                            window.location.reload();
                        }
                    });
                this._logger.error(error);
            });
        }
    }
    //FP-518
    private LoadData() {
        this.StartProgressBar();
        forkJoin(
            this.httpdao!.getData(APIConstant.API_GET_LIST_INDUSTRY_SECTOR),
            this.httpdao!.getData(APIConstant.API_GET_EMPLOYER_STATUS_TYPE),
            this.httpdao!.getData(APIConstant.API_GET_EMPLOYER_TYPE),
            this.httpdao!.getData(APIConstant.API_GET_PAYMENT_PERIOD_NAME),
            this.httpdao!.getData(APIConstant.API_GET_LIST_COUNTRY),
            this.httpdao!.getData(APIConstant.API_GET_ELIGIBLE_EMPLOYEES),
            this.httpdao!.getData(APIConstant.API_GET_EMPLOYER_CATEGORY),
        ).pipe(
            map(([indus, empstatus, emptypes, payments, countries, eligibleemp, emptCategories]) => {
                return { indus, empstatus, emptypes, payments, countries, eligibleemp, emptCategories};
            }),
            shareReplay(1),
            catchError(() => {
                this.StopProgressBar();
                return observableOf(null);
            })
        ).subscribe(results => {
            if (results != null) {
                this.industrysectors = results.indus.sort((a, b) => parseInt(a.Name) - parseInt(b.Name));
                this.eligibleEmps = results.eligibleemp.Data.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
                this.oEligibleEmps = results.eligibleemp.Data.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
                this.employerstatus = results.empstatus.sort((a, b) => parseInt(a.Name) - parseInt(b.Name));

                this.isSearchingCity = true;
                this.isSearchingCityMail = true;
                this.addressService.getCityDetail().subscribe((res) => {
                    this.cityList = res.Data;
                    this.isSearchingCity = false;
                    this.isSearchingCityMail = false;
                });

                this.employerstatus.forEach(element => {
                    if (element.Name.trim() === CommonString.EMPLOYER_STATUS_DEFAULT) {
                        this.EmployerDtlFormGroup.get('ddlStatus').setValue(element.Id);
                    }
                });
                this.emptypes = results.emptypes.sort((a, b) => parseInt(a.Name) - parseInt(b.Name));
                this.emptCategories = results.emptCategories.sort((a, b) => parseInt(a.Name) - parseInt(b.Name));
                
                this.paymentperiods = results.payments;
                this.paymentperiods.forEach(element => {
                    if (element.Name.includes('Fortnightly')) {
                        this.EmployerDtlFormGroup.get('ddlPayPeriod').setValue(element.Id);
                    }
                });

                this.countries = results.countries;
                this.countries.forEach(element => {
                    if (element.Name === 'Australia') {
                        this.EmployerDtlFormGroup.get('ddlCountryPhy').setValue(element.Id);
                        this.EmployerDtlFormGroup.get('ddlMACountry').setValue(element.Id);

                    }
                });

                if (this.EmployerDtlFormGroup.get('ddlCountryPhy').value == 1) {
                    this.statesPhy = CommonConstants.GET_STATE_NAMES;
                } else {
                    this.statesPhy = CommonConstants.GET_NZ_STATE_NAMES;
                }

                if (this.EmployerDtlFormGroup.get('ddlMACountry').value == 1) {
                    this.statesMA = CommonConstants.GET_STATE_NAMES;
                } else {
                    this.statesMA = CommonConstants.GET_NZ_STATE_NAMES;
                }

                this.CityChangeEvent();
                this.CityMailChangeEvent();

                if (this.command == this.EDIT || this.command == this.READ_ONLY || this.command == this.ADDCLONE) {
                    this.LoadEmployerForEditCase();
                } else {
                    this.StopProgressBar();
                }
            }
        });
    }

    private StartProgressBar() {
        this.commonservice.StartProgressBar();
    }

    private StopProgressBar() {
        this.commonservice.StopProgressBar();
    }

    public SetClassForFormControl(tmpformControl: UntypedFormControl): string {
        if (tmpformControl.invalid
            && (tmpformControl.dirty ||
                tmpformControl.touched ||
                this.btnNextClicked)) {
            return 'form-control is-invalid';
        } else {
            return 'form-control';
        }
    }

    public SetClassForControl(tmpformgroup: UntypedFormGroup, controlname: string): string {
        if (tmpformgroup.get(controlname).invalid
            && (tmpformgroup.get(controlname).dirty ||
                tmpformgroup.get(controlname).touched ||
                this.btnNextClicked)) {
            return 'form-control is-invalid';
        } else {
            return 'form-control';
        }
    }

    public SetClassForInputGroup(tmpformgroup: UntypedFormGroup, controlname: string): string {
        let classList = '';
        if (tmpformgroup.get(controlname).invalid
            && (tmpformgroup.get(controlname).dirty ||
                tmpformgroup.get(controlname).touched ||
                this.btnNextClicked)) {
            classList = 'input-group is-invalid';
        } else {
            classList = 'input-group';
        }

        if (tmpformgroup.get(controlname).disabled) {
            classList += ' disabled';
        }

        return classList;
    }

    public IsControlInvalid(tmpformgroup: UntypedFormGroup, controlname: string): boolean {
        return (tmpformgroup.get(controlname).invalid
            && (tmpformgroup.get(controlname).dirty ||
                tmpformgroup.get(controlname).touched ||
                this.btnNextClicked));
    }

    private GetUrlPath() {
        const path = this.route.snapshot.url.toString();

        if (path.toString().indexOf('editemployer') > -1) {
            this.command = this.EDIT;
            this.title = 'Edit Employer';
            this.commonservice.EmployerID = this.route.snapshot.params.id;
            this.isReadonly = false;
            this.EmployerDtlFormGroup.get('chkSameAsAbove').enable();
        } else if (path.toString().indexOf('addclone') > -1) {
            this.command = this.ADDCLONE;
            this.title = 'Add Copy Employer';
            this.commonservice.EmployerID = this.route.snapshot.params.id;
            this.isReadonly = false;
            this.EmployerDtlFormGroup.get('chkSameAsAbove').enable();
        } else if (path.toString().indexOf('addemployer') > -1) {
            this.command = this.ADDNEW;
            this.title = 'Add Employer';
            this.commonservice.EmployerID = '';
            this.isReadonly = false;
            this.employerdtlobj = new EmployerDetailModel();
            // Set User Name
            const userName = this.commonservice.GetUser();
            this.employerdtlobj.ModifiedBy = userName;
            //------
            this.EmployerDtlFormGroup.get('chkSameAsAbove').disable();
        } else if (path.toString().indexOf('readonlyemployer') > -1) {
            this.isReadonly = true;
            this.command = this.READ_ONLY;
            this.title = 'View Employer';
            this.commonservice.EmployerID = this.route.snapshot.params.id;
        }
    }

    public ChangeTab(index) {
        this.stepper.selectedIndex = index;
    }

    get CityCtrl() { return this.EmployerDtlFormGroup.get('City'); }
    get Addressline1Ctrl() { return this.EmployerDtlFormGroup.get('txtStreet1'); }
    get PostcodeCtrl() { return this.EmployerDtlFormGroup.get('ddlPostCodePhy'); }
    get CountryCtrl() { return this.EmployerDtlFormGroup.get('ddlCountryPhy'); }
    get StateCtrl() { return this.EmployerDtlFormGroup.get('ddlStatePhy'); }
    get RegionCtrl() { return this.EmployerDtlFormGroup.get('txtRegionPhy'); }
    get Addressline1MailCtrl() { return this.EmployerDtlFormGroup.get('txtMAAddsLine1'); }
    get CityMailCtrl() { return this.EmployerDtlFormGroup.get('CityMail'); }
    get PostcodeMailCtrl() { return this.EmployerDtlFormGroup.get('ddlPostCodeMail'); }
    get StateMailCtrl() { return this.EmployerDtlFormGroup.get('ddlStateMail'); }
    get CountryMailCtrl() { return this.EmployerDtlFormGroup.get('ddlMACountry'); }
    get SuburbPhyCtrl() { return this.EmployerDtlFormGroup.get('txtSuburbPhy'); }
    get SuburbMailCtrl() { return this.EmployerDtlFormGroup.get('txtSuburbMail'); }

    /** @internal */
    txtSuburbPhy_Blur(e) {
        if (this.SuburbPhyCtrl.dirty) {
            this.SuburbPhyCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
            this.SuburbPhyCtrl.updateValueAndValidity({ emitEvent: false });
        }
    }

     /** @internal */
     txtSuburbMail_Blur(e) {
        if (this.SuburbMailCtrl.dirty) {
            this.SuburbMailCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
            this.SuburbMailCtrl.updateValueAndValidity({ emitEvent: false });
        }
    }

    /** @internal */
    txtCity_Blur(e) {
        if (this.CityCtrl.dirty) {
            this.CityCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
            this.CityCtrl.updateValueAndValidity({ emitEvent: false });
        }
    }

    /** @internal */
    txtCity_Input(e) {
        if (this.CityCtrl.dirty) {
            if (typeof this.CityCtrl.value === 'string' &&
                this.CityCtrl.value.length == 0 &&
                this.selectedCityId > 0) {
                this.SelectCity(null);
            }
            this.CityCtrl.setValidators(Validators.required);
            this.EmployerDtlFormGroup.get('chkSameAsAbove').disable();
        }
    }

    /** @internal */
    AutoCompleteCity_DisplayWithFn(option: any) {
        if (option) {
            if (option.Name) {
                const val = option.Name;
                return (val);
            } else {
                return option;
            }
        } else {
            return '';
        }
    }

    /** @internal */
    SelectCity(city: City) {
        this.citySearchResults = [];
        this.CityCtrl.markAsDirty();
        this.CityCtrl.markAsTouched();
        this.HandleDisableSameAsAdd();


    }

    /** @internal */
    txtCityMail_Blur(e) {
        if (this.CityMailCtrl.dirty) {
            this.CityMailCtrl.setValidators([Validators.required, FPValidators.resolvableAutocompleteOption]);
            this.CityMailCtrl.updateValueAndValidity({ emitEvent: false });
        }
    }

    /** @internal */
    txtCityMail_Input(e) {
        if (this.CityMailCtrl.dirty) {
            if (typeof this.CityMailCtrl.value === 'string' &&
                this.CityMailCtrl.value.length == 0 &&
                this.selectedCityId > 0) {
                this.SelectCityMail(null);
            }
            this.CityMailCtrl.setValidators(Validators.required);
        }
    }

    /** @internal */
    AutoCompleteCityMail_DisplayWithFn(option: any) {
        if (option) {
            if (option.Name) {
                const val = option.Name;
                return (val);
            } else {
                return option;
            }
        } else {
            return '';
        }
    }

    /** @internal */
    SelectCityMail(city: City) {
        this.cityMailSearchResults = [];
        this.CityMailCtrl.markAsDirty();
        this.CityMailCtrl.markAsTouched();
    }

    private SetValidator() {
        const validatorArr = [];
        validatorArr.push(Validators.required);
        validatorArr.push(Validators.pattern(PatternConstant.PATTERN_PHONE));               
        this.txtAccountManagerControl.setValidators(Validators.required);
        this.txtBDevManagerControl.setValidators(Validators.required);

        this.EmployerDtlFormGroup = this._formBuilder.group({
            txtEmployerName: ['', Validators.required],
            txtAlternateNameTags: [''],
            ddlIndustrySector: [''],
            ddlAccountManagerName: this.txtAccountManagerControl,
            ddlBDevelopManagerName: this.txtBDevManagerControl,
            ddlEmployerType: ['', Validators.required],
            ddlEmployerCategory: ['', Validators.required],
            txtEmployerCode: ['', Validators.required],
            txtPhone: ['', Validators.compose(validatorArr)],
            txtEmail: ['', Validators.pattern(PatternConstant.EMAIL)],
            txtStreet1: ['', Validators.required],
            txtStreet2: [''],
            ddlPostCodePhy: ['', Validators.required],
            ddlStatePhy: ['', Validators.required],
            City: [null],
            CityMail: [null],
            ddlCountryPhy: ['', Validators.required],
            ddlMACountry: ['', Validators.required],
            txtRegionPhy: [''],
            txtABN: [''],
            txtAccountManager: [''],
            txtMAAddsLine1: ['', Validators.required],
            txtMAAddsLine2: [''],
            ddlPostCodeMail: ['', Validators.required],
            ddlStateMail: ['', Validators.required],
            chkMailCards: [true],
            chkSameAsAbove: [false],
            ddlStatus: [''],
            dtpDateClosed: ['', [this.RequiredValidatorForDateClose, this.MinDateValidatorForDateClose, this.RangeDateValidatorForDateClosed]],
            dtpStartDate: ['', [Validators.required, this.MinDateValidator, this.RangeDateValidatorForDateClosed]],
            //https://jira.csc.com/browse/FP-3971 - dtpNextPayment Field should be made writable, i.e. non-read-only
            dtpNextPayment: ['', [Validators.required, this.MinDateValidator, this.RangeDateValidator]],
            ddlPayPeriod: ['', Validators.required],
            txtNumbrOfEmplr: [''],
            EligibleEmplsCtrl: [-1],
            AddEligInfoCtrl: [''],
            EligibleEmployeeNumber: [0],
            txtTarNumbr: [''],
            txtAddJEntry: [''],
            txtSuburbPhy: ['', Validators.required],
            txtSuburbMail: ['', Validators.required]
        });

        this.ContractFormGroup = this._formBuilder.group({
            // Author: Yen
            dtpCTStartDate: [],
            dtpCTEndDate: [],
            txtCDuration: [, Validators.max(99)],
            txtCSignatory: ['']
        });

        this.FacilitatorFormGroup = this._formBuilder.group({
            // Author: Yen
            txtAddFacr: [''],
            txtrDsc: ['']
        });
        this.RegionFormGroup = this._formBuilder.group({});

        this.EmployerPageFormGroup = EmployerPageComponent.getFormGroup();
        this.EmployerSurveyPageFormGroup = SurveyPageComponent.getFormGroup();

        // Author: Trang Nguyen
        this.CustomFieldsFormGroup = this._formBuilder.group({
        });

        this.MembershipPackagesFormGroup = this._formBuilder.group({
        });
    }

    public ValidateEmployerDetailSection(): boolean {
        if (this.EmployerDtlFormGroup.get('txtEmployerName').valid && this.txtAccountManagerControl.valid
            && this.EmployerDtlFormGroup.get('ddlEmployerType').valid
            && this.txtBDevManagerControl.valid) {
            return true;
        } else {
            return false;
        }
    }

    public ValidateEmployerCodeSection(): boolean {
        if (this.EmployerDtlFormGroup.get('txtEmployerCode').valid) {
            return true;
        } else {
            return false;
        }
    }

    public ValidateAddressSection(): boolean {
        if (this.EmployerDtlFormGroup.get('txtPhone').valid &&
            this.EmployerDtlFormGroup.get('txtStreet1').valid &&
            this.EmployerDtlFormGroup.get('txtMAAddsLine1').valid &&
            this.EmployerDtlFormGroup.get('ddlPostCodeMail').valid &&
            this.EmployerDtlFormGroup.get('ddlPostCodePhy').valid) {
            return true;
        } else {
            return false;
        }
    }

    public ValidateAdditionalInformation(): boolean {
        if (this.EmployerDtlFormGroup.get('ddlStatus').valid &&
            this.EmployerDtlFormGroup.get('dtpStartDate').valid &&
            this.EmployerDtlFormGroup.get('ddlPayPeriod').valid &&
            this.EmployerDtlFormGroup.get('dtpDateClosed').valid &&
            this.EmployerDtlFormGroup.get('ddlStatus').valid) {
            return true;
        } else {
            return false;
        }
    }

    private ConvertNullOrUndefined(val: number): any {
        if (val == null || val == undefined) {
            return '';
        } else {
            return val;
        }
    }

    private SetEmployerDtlToConttrol() {
        this.EmployerDtlFormGroup.get('txtEmployerName').setValue(this.employerdtlobj.Name);
        this.EmployerDtlFormGroup.get('txtAlternateNameTags').setValue(this.employerdtlobj.AlternateNameTags);
        const pgobj = { Id: this.employerdtlobj.EmployerGroupId, Name: this.employerdtlobj.EmployerGroupName };
        this.txtParentGroupControl.setValue(pgobj);

        if (!StringHelper.isNullOrEmpty(this.employerdtlobj.AccountManagerName)) {
            const accobj = { Id: this.employerdtlobj.AccountManagerId, Name: this.employerdtlobj.AccountManagerName };
            this.txtAccountManagerControl.setValue(accobj);
        }
        if (!StringHelper.isNullOrEmpty(this.employerdtlobj.BusinessDevelopmentManagerName)) {
            const bDManegerobj = {
                Id: this.employerdtlobj.BusinessDevelopmentManagerId,
                Name: this.employerdtlobj.BusinessDevelopmentManagerName
            };
            this.txtBDevManagerControl.setValue(bDManegerobj);
        }

        this.EmployerDtlFormGroup.get('ddlIndustrySector').setValue(
            this.ConvertNullOrUndefined(this.employerdtlobj.IndustrySectorId));

        this.EmployerDtlFormGroup.get('ddlEmployerType').setValue(
            this.ConvertNullOrUndefined(this.employerdtlobj.EmployerTypeId));

        //FP-518  
        this.EmployerDtlFormGroup.get('ddlEmployerCategory').setValue(
            this.ConvertNullOrUndefined(this.employerdtlobj.EmployerCategoryId));

        this.EmployerDtlFormGroup.get('txtPhone').setValue(this.employerdtlobj.PhoneNo);
        this.setMobileValidations();
        this.EmployerDtlFormGroup.get('txtEmail').setValue(this.employerdtlobj.EmailAddress);
        this.EmployerDtlFormGroup.get('txtStreet1').setValue(this.employerdtlobj.StreetAddress1_Phy);
        this.EmployerDtlFormGroup.get('txtMAAddsLine1').setValue(this.employerdtlobj.StreetAddress1_Mail);
        this.EmployerDtlFormGroup.get('txtStreet2').setValue(this.employerdtlobj.StreetAddress2_Phy);

        //For Suburb Physical
        const phyobj = {
            Id: this.employerdtlobj.SuburbId_Phy,
            Name: this.employerdtlobj.Suburb_Phy,
        };
        this.SuburbPhyCtrl.setValue(phyobj);

        //For City Physical
        const cityphyobj = {
            Id: this.employerdtlobj.CityId_Phy,
            Name: this.employerdtlobj.City_Phy,
        };
        this.CityCtrl.setValue(cityphyobj);

        const citymailobj = {
            Id: this.employerdtlobj.CityId_Mail,
            Name: this.employerdtlobj.City_Mail,
        };
        this.CityMailCtrl.setValue(citymailobj);

        if(this.CityMailCtrl){
            this.mobileNumber.SetMobileLenghtValidators(false);
        }
        else{
            this.mobileNumber.SetMobileLenghtValidators(true);
        }

        this.EmployerDtlFormGroup.get('txtPhone').setValue(this.employerdtlobj.PhoneNo);
        
        // ----------------
        // this.EmployerDtlFormGroup.get('txtMAAddsLine1').setValue(this.employerdtlobj.StreetAddress1_Mail);
        this.EmployerDtlFormGroup.get('txtMAAddsLine2').setValue(this.employerdtlobj.StreetAddress2_Mail);

        //For Suburb Physical
        const mailobj = { Id: this.employerdtlobj.SuburbId_Mail, Name: this.employerdtlobj.Suburb_Mail };
        this.SuburbMailCtrl.setValue(mailobj);
        // ----------------

        this.EmployerDtlFormGroup.get('chkMailCards').setValue(this.employerdtlobj.MailCardToHome);
        // -------
        this.EmployerDtlFormGroup.get('txtABN').setValue(this.employerdtlobj.Abn);
        this.EmployerDtlFormGroup.get('txtEmployerCode').setValue(this.employerdtlobj.Code);

        // --------
        if (this.employerdtlobj.EmployerStatusId == null
            || this.employerdtlobj.EmployerStatusId == undefined) {
            this.employerstatus.forEach(element => {
                if (element.Name.trim() == CommonString.EMPLOYER_STATUS_DEFAULT) {
                    this.EmployerDtlFormGroup.get('ddlStatus').setValue(element.Id);
                }
            });
        } else {
            this.EmployerDtlFormGroup.get('ddlStatus').setValue(this.employerdtlobj.EmployerStatusId);
        }

        this.selectedEligibleEmps = this.eligibleEmps.filter(item => this.employerdtlobj.EligibleEmployeeIds.indexOf(item.Id) > -1);
        this.eligibleEmps = this.eligibleEmps.filter(item => this.employerdtlobj.EligibleEmployeeIds.indexOf(item.Id) === -1);
        const dtpStartDate: NgbDateStruct = {
            day: (Utils.parseUTCDate(this.employerdtlobj.EmployerStartDate)).getDate(),
            month: (Utils.parseUTCDate(this.employerdtlobj.EmployerStartDate)).getMonth() + 1,
            year: (Utils.parseUTCDate(this.employerdtlobj.EmployerStartDate)).getFullYear()
        };
        this.EmployerDtlFormGroup.get('dtpStartDate').setValue(dtpStartDate);
        const dtpNextPayment: NgbDateStruct = {
            day: (Utils.parseUTCDate(this.employerdtlobj.NextPaymentRunDate)).getDate(),
            month: (Utils.parseUTCDate(this.employerdtlobj.NextPaymentRunDate)).getMonth() + 1,
            year: (Utils.parseUTCDate(this.employerdtlobj.NextPaymentRunDate)).getFullYear()
        }
        this.EmployerDtlFormGroup.get('dtpNextPayment').setValue(dtpNextPayment);
        if (this.employerdtlobj.DateClosed) {
            const dtpDateClosed: NgbDateStruct = {
                day: (Utils.parseUTCDate(this.employerdtlobj.DateClosed)).getDate(),
                month: (Utils.parseUTCDate(this.employerdtlobj.DateClosed)).getMonth() + 1,
                year: (Utils.parseUTCDate(this.employerdtlobj.DateClosed)).getFullYear()
            };
            this.EmployerDtlFormGroup.get('dtpDateClosed').setValue(dtpDateClosed);
        }
        this.EmployerDtlFormGroup.get('ddlPayPeriod').setValue(this.employerdtlobj.PaymentPeriodId);
        this.EmployerDtlFormGroup.get('txtNumbrOfEmplr').setValue(this.employerdtlobj.NoEmployeeAnswer);
        // this.EmployerDtlFormGroup.get('EligibleEmplsCtrl').setValue(this.eligibleEmps);
        this.EmployerDtlFormGroup.get('AddEligInfoCtrl').setValue(this.employerdtlobj.EligibilityInformation);
        this.EmployerDtlFormGroup.get('EligibleEmployeeNumber').setValue(this.employerdtlobj.EligibleEmployeeNumber || 0);
        this.EmployerDtlFormGroup.get('txtTarNumbr').setValue(this.employerdtlobj.TargetNumber);
        this.EmployerDtlFormGroup.get('txtAddJEntry').setValue(this.employerdtlobj.AddJournalEntry);

        // Author: Yen
        this.primaryFacilitator = this.employerdtlobj.PrimaryFacilitatorId;
        this.facilitatorsSelected = this.employerdtlobj.FacilitatorEmployers;
        this.FacilitatorFormGroup.get('txtrDsc').setValue(this.employerdtlobj.FacillitatorAdditionalNote);
        if (this.employerdtlobj.EmployerContract != null) {
            const parseStartDate = Utils.parseUTCDate(this.employerdtlobj.EmployerContract.ContractStartDate);
            if (parseStartDate != null) {
                const dtpContractStartDate: NgbDateStruct = {
                    day: (Utils.parseUTCDate(this.employerdtlobj.EmployerContract.ContractStartDate)).getDate(),
                    month: (Utils.parseUTCDate(this.employerdtlobj.EmployerContract.ContractStartDate)).getMonth() + 1,
                    year: (Utils.parseUTCDate(this.employerdtlobj.EmployerContract.ContractStartDate)).getFullYear()
                }
                this.ContractFormGroup.get('dtpCTStartDate').setValue(dtpContractStartDate);
            }
            const parseEndDate = Utils.parseUTCDate(this.employerdtlobj.EmployerContract.ContractEndDate);
            if (parseEndDate != null) {
                const dtpContractEndDate: NgbDateStruct = {
                    day: (Utils.parseUTCDate(this.employerdtlobj.EmployerContract.ContractEndDate)).getDate(),
                    month: (Utils.parseUTCDate(this.employerdtlobj.EmployerContract.ContractEndDate)).getMonth() + 1,
                    year: (Utils.parseUTCDate(this.employerdtlobj.EmployerContract.ContractEndDate)).getFullYear()
                }
                this.ContractFormGroup.get('dtpCTEndDate').setValue(dtpContractEndDate);
            }
            this.ContractFormGroup.get('txtCDuration').setValue(this.employerdtlobj.EmployerContract.ContractDuration);
            this.ContractFormGroup.get('txtCSignatory').setValue(this.employerdtlobj.EmployerContract.ContractSignatory);
            this.signedContract = this.employerdtlobj.EmployerContract.EmployerSignedContract;
            this.attachAdditional = this.employerdtlobj.EmployerContract.EmployerContractAttachFile;
            this.logoContract = this.employerdtlobj.EmployerContract.EmployerContractLogo;
            this.imageSrc = this.employerdtlobj.EmployerContract.EmployerContractLogo['Location'];
            //For image default of logo contract
            this.logoContract.length != 0 ? this.imageDefault = false : this.imageDefault = true;
        }
        // load state, country, region by suburb and postcode
        this.LoadPostCodePhyBySubrubName(this.employerdtlobj.Suburb_Phy, this.employerdtlobj.PostCodeId_Phy, true);

        this.SetReadonly();

        // set modified by
        let username = this.commonservice.GetUser();
        this.employerdtlobj.ModifiedBy = username;
    }


    private SetReadonly() {
        if (this.isReadonly) {
            this.CustomFieldsFormGroup.disable();
            this.EmployerDtlFormGroup.disable();
            this.ContractFormGroup.disable();
            this.FacilitatorFormGroup.disable();
            this.RegionFormGroup.disable();
            this.MembershipPackagesFormGroup.disable();
            this.CustomFieldsFormGroup.disable();
            this.branchFormGroup.disable();
            this.EmployerPageFormGroup.disable();

            this.txtAdditionalEmployerNoteControl.disable();
        }
    }

    private SetControlToEmployerDtl() {
        this.employerdtlobj.Name = this.EmployerDtlFormGroup.get('txtEmployerName').value;
        this.employerdtlobj.AlternateNameTags = this.EmployerDtlFormGroup.get('txtAlternateNameTags').value;

        if (this.ddlParentGroupAutoComplete.options.length == 1) {
            this.employerdtlobj.EmployerGroupName = this.ddlParentGroupAutoComplete.options.first.viewValue;
            this.employerdtlobj.EmployerGroupId = this.ddlParentGroupAutoComplete.options.first.value.Id;
        } else if (this.ddlParentGroupAutoComplete.options.length > 1) {
            if (this.txtParentGroupControl.value != null
                && this.txtParentGroupControl.value != ''
                && this.txtParentGroupControl.value != undefined) {
                this.employerdtlobj.EmployerGroupName = this.txtParentGroupControl.value.Name;
                this.employerdtlobj.EmployerGroupId = this.txtParentGroupControl.value.Id;
            } else {
                this.employerdtlobj.EmployerGroupName = null;
            }
        } else if (this.ddlParentGroupAutoComplete.options.length == 0
            && (this.txtParentGroupControl.value == null
                || this.txtParentGroupControl.value == undefined
                || this.txtParentGroupControl.value == '')) {
            this.employerdtlobj.EmployerGroupName = '';
            this.employerdtlobj.EmployerGroupId = null;
        }

        if (this.ddlAccountManagerAutoComplete.options.length == 1) {
            this.employerdtlobj.AccountManagerName = this.ddlAccountManagerAutoComplete.options.first.viewValue;
            this.employerdtlobj.AccountManagerId = this.ddlAccountManagerAutoComplete.options.first.value.Id;
        } else if (this.ddlAccountManagerAutoComplete.options.length > 1) {
            if (this.txtAccountManagerControl.value != null
                && this.txtAccountManagerControl.value != ''
                && this.txtAccountManagerControl.value != undefined) {
                this.employerdtlobj.AccountManagerName = this.txtAccountManagerControl.value.Name;
                this.employerdtlobj.AccountManagerId = this.txtAccountManagerControl.value.Id;
            } else {
                this.employerdtlobj.AccountManagerName = null;
            }
        } else if (this.ddlAccountManagerAutoComplete.options.length == 0
            && (this.txtAccountManagerControl.value == null
                || this.txtAccountManagerControl.value == undefined
                || this.txtAccountManagerControl.value == '')) {
            this.employerdtlobj.AccountManagerName = '';
            this.employerdtlobj.AccountManagerId = null;
        }

        if (this.ddlBDevManagerAutoComplete.options.length === 1) {
            this.employerdtlobj.BusinessDevelopmentManagerName = this.ddlBDevManagerAutoComplete.options.first.viewValue;
            this.employerdtlobj.BusinessDevelopmentManagerId = this.ddlBDevManagerAutoComplete.options.first.value.Id;
        } else if (this.ddlBDevManagerAutoComplete.options.length > 1) {
            if (!StringHelper.isNullOrEmpty(this.txtBDevManagerControl.value)) {
                this.employerdtlobj.BusinessDevelopmentManagerName = this.txtBDevManagerControl.value.Name;
                this.employerdtlobj.BusinessDevelopmentManagerId = this.txtBDevManagerControl.value.Id;
            } else {
                this.employerdtlobj.BusinessDevelopmentManagerName = null;
            }
        } else if (this.ddlBDevManagerAutoComplete.options.length === 0
            && (StringHelper.isNullOrEmpty(this.txtBDevManagerControl.value))) {
            this.employerdtlobj.BusinessDevelopmentManagerName = '';
            this.employerdtlobj.BusinessDevelopmentManagerId = null;
        }

        this.employerdtlobj.IndustrySectorId = this.EmployerDtlFormGroup.get('ddlIndustrySector').value;
        if (this.employerdtlobj.IndustrySectorId == -1) {
            this.employerdtlobj.IndustrySectorId = null;
        }

        this.employerdtlobj.EmployerTypeId = this.EmployerDtlFormGroup.get('ddlEmployerType').value;
        if (this.employerdtlobj.EmployerTypeId == -1) {
            this.employerdtlobj.EmployerTypeId = null;
        }

        //FP-518
        this.employerdtlobj.EmployerCategoryId = this.EmployerDtlFormGroup.get('ddlEmployerCategory').value;
        if (this.employerdtlobj.EmployerCategoryId == -1) {
            this.employerdtlobj.EmployerCategoryId = null;
        }

        this.employerdtlobj.PhoneNo = this.EmployerDtlFormGroup.get('txtPhone').value;
        this.employerdtlobj.EmailAddress = this.EmployerDtlFormGroup.get('txtEmail').value.toLowerCase().trim();

        if (this.EmployerDtlFormGroup.get('txtStreet1').value && this.EmployerDtlFormGroup.get('txtStreet1').value.Text) {
            this.employerdtlobj.StreetAddress1_Phy = this.EmployerDtlFormGroup.get('txtStreet1').value.Text;
        } else {
            this.employerdtlobj.StreetAddress1_Phy = this.EmployerDtlFormGroup.get('txtStreet1').value;
        }

        if (this.EmployerDtlFormGroup.get('txtMAAddsLine1').value && this.EmployerDtlFormGroup.get('txtMAAddsLine1').value.Text) {
            this.employerdtlobj.StreetAddress1_Mail = this.EmployerDtlFormGroup.get('txtMAAddsLine1').value.Text;
        } else {
            this.employerdtlobj.StreetAddress1_Mail = this.EmployerDtlFormGroup.get('txtMAAddsLine1').value;
        }

        this.employerdtlobj.PostCodeId_Mail = this.EmployerDtlFormGroup.get('ddlPostCodeMail').value;
        this.employerdtlobj.StreetAddress2_Phy = this.EmployerDtlFormGroup.get('txtStreet2').value;
        this.employerdtlobj.PostCodeId_Phy = Number(this.EmployerDtlFormGroup.get('ddlPostCodePhy').value);
        this.employerdtlobj.PostCode_Phy = this.EmployerDtlFormGroup.get('ddlPostCodePhy').value;
        this.employerdtlobj.IsDirectDebit = true;
        if (this.EmployerDtlFormGroup.get('City').value !== null) {
            if(this.EmployerDtlFormGroup.get('City').value.Id){
                this.employerdtlobj.CityId_Phy = this.EmployerDtlFormGroup.get('City').value.Id;
            }else if(this.EmployerDtlFormGroup.get('City').value.CityId){
                this.employerdtlobj.CityId_Phy = this.EmployerDtlFormGroup.get('City').value.CityId;
            }             
        }
        if (this.EmployerDtlFormGroup.get('CityMail').value !== null) {           
            if(this.EmployerDtlFormGroup.get('CityMail').value.Id){
                this.employerdtlobj.CityId_Mail = this.EmployerDtlFormGroup.get('CityMail').value.Id;
            }else if(this.EmployerDtlFormGroup.get('CityMail').value.CityId){
                this.employerdtlobj.CityId_Mail = this.EmployerDtlFormGroup.get('CityMail').value.CityId;
            }                    
        }

        // const cityPhyVal = this.CityCtrl.value;
        // if (typeof cityPhyVal === 'object') {
        //     this.employerdtlobj.CityId_Phy = cityPhyVal.CityId;
        // }

        // const cityMailVal = this.CityCtrl.value;
        // if (typeof cityMailVal === 'object') {
        //     this.employerdtlobj.CityId_Mail = cityMailVal.CityId;
        // }

        // Query SuburbId from PostcodeID
        // const postcodeobj: SuburbPostcodeStateModel = this.GetSuburbPhyIDByCodeID(this.employerdtlobj.PostCodeId_Phy);
        const suburdPhyVal = this.SuburbPhyCtrl.value;
        if (typeof suburdPhyVal === 'object') {
            this.employerdtlobj.SuburbId_Phy = suburdPhyVal.Id;
        }
        // this.employerdtlobj.PostCodeId_Phy = postcodeobj.PostcodeId;
        // this.employerdtlobj.SuburbId_Phy = postcodeobj.SuburbId;
        // End
        // ----------------
        // this.employerdtlobj.StreetAddress1_Mail = this.EmployerDtlFormGroup.get('txtMAAddsLine1').value;
        this.employerdtlobj.StreetAddress2_Mail = this.EmployerDtlFormGroup.get('txtMAAddsLine2').value;
        if (this.EmployerDtlFormGroup.get('ddlPostCodeMail').value != null
            && this.EmployerDtlFormGroup.get('ddlPostCodeMail').value != ''
            && this.EmployerDtlFormGroup.get('ddlPostCodeMail').value != undefined) {
            // Query SuburbId from PostcodeID
            this.employerdtlobj.PostCodeId_Mail = this.EmployerDtlFormGroup.get('ddlPostCodeMail').value;
            // const postcode_mailobj: SuburbPostcodeStateModel = this.GetSuburbMailIDByCodeID(this.employerdtlobj.PostCodeId_Mail);
            const suburdMailVal = this.SuburbMailCtrl.value;
            // this.employerdtlobj.PostCodeId_Mail = postcode_mailobj.PostcodeId;
            this.employerdtlobj.SuburbId_Mail = suburdMailVal.Id;
            // End
        } else {
            this.employerdtlobj.PostCodeId_Mail = null;
        }

        this.employerdtlobj.MailCardToHome = this.EmployerDtlFormGroup.get('chkMailCards').value;
        // -------
        this.employerdtlobj.Abn = this.EmployerDtlFormGroup.get('txtABN').value;
        this.employerdtlobj.Code = this.EmployerDtlFormGroup.get('txtEmployerCode').value;
        // --------
        this.employerdtlobj.EmployerStatusId = this.EmployerDtlFormGroup.get('ddlStatus').value;

        if (this.EmployerDtlFormGroup.get('dtpStartDate').value != ''
            && this.EmployerDtlFormGroup.get('dtpStartDate').value != null
            && this.EmployerDtlFormGroup.get('dtpStartDate').value != undefined) {
            const startDate = new Date(this.EmployerDtlFormGroup.get('dtpStartDate').value.year, this.EmployerDtlFormGroup.get('dtpStartDate').value.month - 1, this.EmployerDtlFormGroup.get('dtpStartDate').value.day + 1, 0, 0, 0);
            const nextPaymentDate = new Date(this.EmployerDtlFormGroup.get('dtpNextPayment').value.year, this.EmployerDtlFormGroup.get('dtpNextPayment').value.month - 1, this.EmployerDtlFormGroup.get('dtpNextPayment').value.day + 1, 0, 0, 0);

            this.employerdtlobj.EmployerStartDate = startDate;
            this.employerdtlobj.NextPaymentRunDate = nextPaymentDate;
        } else {
            this.employerdtlobj.EmployerStartDate = null;
            this.employerdtlobj.NextPaymentRunDate = null;
        }

        if (!StringHelper.isNullOrEmpty(this.EmployerDtlFormGroup.get('dtpDateClosed').value)) {
            if (this.employerdtlobj.EmployerStatusId == this.EEmployerStatus.CloseForNewMembers
                || this.employerdtlobj.EmployerStatusId == this.EEmployerStatus.FailedLaunch) {
                const nextPaymentDate = new Date(this.EmployerDtlFormGroup.get('dtpDateClosed').value.year, this.EmployerDtlFormGroup.get('dtpDateClosed').value.month - 1, this.EmployerDtlFormGroup.get('dtpDateClosed').value.day + 1, 0, 0, 0);
                this.employerdtlobj.DateClosed = nextPaymentDate;
            } else {
                this.employerdtlobj.DateClosed = null;
            }
        } else {
            this.employerdtlobj.DateClosed = null;
        }

        this.employerdtlobj.PaymentPeriodId = this.EmployerDtlFormGroup.get('ddlPayPeriod').value;
        this.employerdtlobj.NoEmployeeAnswer = this.EmployerDtlFormGroup.get('txtNumbrOfEmplr').value;

        this.employerdtlobj.EligibleEmployeeIds = this.selectedEligibleEmps.map(e => e.Id);
        this.employerdtlobj.EligibilityInformation = this.EmployerDtlFormGroup.get('AddEligInfoCtrl').value;
        this.employerdtlobj.EligibleEmployeeNumber = this.EmployerDtlFormGroup.get('EligibleEmployeeNumber').value || 0;
        this.employerdtlobj.TargetNumber = this.EmployerDtlFormGroup.get('txtTarNumbr').value;
        this.employerdtlobj.AddJournalEntry = this.EmployerDtlFormGroup.get('txtAddJEntry').value;

        // Author: Yen
        //Contract tab
        let employerContract: EmployerContractModel = {};
        if (this.ContractFormGroup.get('dtpCTStartDate').value !== ''
            && this.ContractFormGroup.get('dtpCTStartDate').value != null
            && this.ContractFormGroup.get('dtpCTStartDate').value != undefined) {
            const contractStart = new Date(this.ContractFormGroup.get('dtpCTStartDate').value.year, this.ContractFormGroup.get('dtpCTStartDate').value.month - 1, this.ContractFormGroup.get('dtpCTStartDate').value.day + 1, 0, 0, 0);
            employerContract.ContractStartDate = contractStart.toUTCString();
        } else {
            employerContract.ContractStartDate = null;
        }

        if (this.ContractFormGroup.get('dtpCTEndDate').value !== ''
            && this.ContractFormGroup.get('dtpCTEndDate').value != null
            && this.ContractFormGroup.get('dtpCTEndDate').value != undefined) {
            const contractEnd = new Date(this.ContractFormGroup.get('dtpCTEndDate').value.year, this.ContractFormGroup.get('dtpCTEndDate').value.month - 1, this.ContractFormGroup.get('dtpCTEndDate').value.day + 1, 0, 0, 0);
            employerContract.ContractEndDate = contractEnd.toUTCString();
        } else {
            employerContract.ContractEndDate = null;
        }
        employerContract.ContractDuration = this.ContractFormGroup.get('txtCDuration').value;
        employerContract.ContractSignatory = this.ContractFormGroup.get('txtCSignatory').value;
        employerContract.EmployerSignedContract = this.signedContract;
        employerContract.EmployerContractAttachFile = this.attachAdditional;
        employerContract.EmployerContractLogo = this.logoContract;
        if (this.command == this.EDIT) {
            employerContract.EmployerContractId = this.employerdtlobj.EmployerContract.EmployerContractId;
        }
        this.employerdtlobj.EmployerContract = employerContract;

        // Author: Yen
        //Facilitator tab
        this.employerdtlobj.PrimaryFacilitatorId = this.primaryFacilitator;
        let ef: EmployerFacilitatorModel;
        this.facilitatorEmployers = [];
        this.facilitatorsSelected.forEach(r => {
            ef = {};
            ef.UserId = r.UserId;
            ef.EmployerId = this.employerdtlobj.EmployerId;
            this.facilitatorEmployers.push(ef);
        });
        this.employerdtlobj.FacilitatorEmployers = this.facilitatorEmployers;
        this.employerdtlobj.FacillitatorAdditionalNote = this.FacilitatorFormGroup.get('txtrDsc').value;

        // Author: Trang Nguyen
        this.employerdtlobj.EmployerNotes = this.dataSourceNotes.data;
    }

    private LoadEmployerForEditCase(thisisloadfornoteonly = false) {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                this.StartProgressBar();
                this.commonservice.EmployerID = this.route.snapshot.params.id;
                return this.httpdao!.getSingleData(APIConstant.API_GET_EMPLOYER_DETAIL + this.commonservice.EmployerID);
            }),
            map(data => {

                return data;
                              
            }),
            catchError(() => {
                this.StopProgressBar();
                return observableOf([]);
            })
        ).subscribe((data) => {
            if (data == null) {
                this.router.navigate([RouterConstant.ROUTER_PAGE_NOT_FOUND]);
                return;
            }
            
            if (thisisloadfornoteonly === true) {
                data.EmployerNotes.forEach(element => {
                    const date = new Date(element.DateTimeStamp + 'Z');
                    element.DateTimeDisplay = DateHelper.format(date, 'DD-MMM-YYYY HH:mm');
                    element.DateTimeStamp = this.TransferLocalTime(element.DateTimeStamp, true);
                });
                // set data to employer notes
                this.dataSourceNotes = new MatTableDataSource<EmployerNoteModel>(data.EmployerNotes);
                this.dataSourceNotes.paginator = this.note_paginator;
                this.StopProgressBar();
                this.changeDetectorRef.detectChanges();
                return;
            }
            this.employerdtlobj = data;
            if (this.command === this.ADDCLONE) {
                this.employerdtlobj.EmployerId = undefined;
                this.employerdtlobj.EmployerNotes = [];
                this.employerdtlobj.MembershipPackages = [];
                this.employerdtlobj.FacilitatorEmployers = [];
                this.employerdtlobj.EmployerContract = null;
                this.employerdtlobj.Logo = null;
                this.employerdtlobj.Code = null;
                this.employerdtlobj.Name = this.employerdtlobj.Name + '_Copy';
                this.employerdtlobj.FacillitatorAdditionalNote = null;
            }
            // Set User Name
            const userName = this.commonservice.GetUser();
            this.employerdtlobj.ModifiedBy = userName;
            this.currentStartDate = Utils.parseUTCDate(this.employerdtlobj.EmployerStartDate);
            this.currentNextPDate = Utils.parseUTCDate(this.employerdtlobj.NextPaymentRunDate);

            //page
            this.pageData = new EmployerPageFormSharedData();
            this.surveypageData = new EmployerSurveyPageFormSharedData();
            this.pageData.additionalData.EmployerPageChanged = this.EmployerPageChanged;
            this.pageData.additionalData.btnNextEmployerPage = this.btnNextEmployerPage;
            this.pageData.model = this.employerdtlobj;

            data.EmployerNotes.forEach(element => {
                const date = new Date(element.DateTimeStamp + 'Z');
                element.DateTimeDisplay = DateHelper.format(date, 'DD-MMM-YYYY HH:mm');
                element.DateTimeStamp = this.TransferLocalTime(element.DateTimeStamp, true);
            });
            // set data to employer notes
            this.dataSourceNotes = new MatTableDataSource<EmployerNoteModel>(data.EmployerNotes);
            this.dataSourceNotes.paginator = this.note_paginator;
            this.SetEmployerDtlToConttrol();
            this.selectedEligibleEmps = this.oEligibleEmps.filter(item => this.employerdtlobj.EligibleEmployeeIds.indexOf(item.Id) > -1);
            this.changeDetectorRef.detectChanges();
        });
    }

    private TransferLocalTime(str: string, isTime: boolean) {
        let d = new Date(str + 'Z');
        //const offseth = (APIConstant.UTC_SYDNEY + (d.getTimezoneOffset() / 60)) + 1;
        //d.setHours(d.getHours() - offseth);
        return this.datePipe.transform(d, isTime ? APIConstant.DATE_HOUR_TIME_FORMAT : APIConstant.DATE_HOUR_FORMAT);
    }

    private filterText(name: string): CommonResponse[] {
        const filterValue = name.toLowerCase();
        return this.parentgroups.filter(option => option.Name.toLowerCase().indexOf(filterValue) == 0);
    }

    private filterTextAccountManager(data: CommonResponse[], name: string): CommonResponse[] {
        const filterValue = name.toLowerCase();
        return data.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
    }

    private filterSuburbText(data: SuburbSearchResultRecord[], name: string): SuburbSearchResultRecord[] {
        const filterValue = name.toLowerCase();
        return data.filter(option => option.Name.toLowerCase().indexOf(filterValue) == 0);
    }

    public displayAutoText(obj?: CommonResponse): string | undefined {
        return obj ? obj.Name : undefined;
    }

    public displayAutoSuburbText(obj?: SuburbSearchResultRecord): string | undefined {
        return obj ? obj.Name : undefined;
    }

    private LoadParentGroup(pgname) {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                this.isSpinerParentGroup = true;

                // Turn on Waiting checking
                this.EmployerDtlFormGroup.setErrors({ waitingcheck: true });
                return this.httpdao!.getData(APIConstant.API_GET_EMPLOYER_NAME_BY_NAME + encodeURIComponent(pgname));
            }),
            map(data => {
                this.filteredPGroup = this.txtParentGroupControl.valueChanges.pipe(
                    startWith<string | CommonResponse>(''),
                    map(value => typeof value == 'string' ? value : value.Name),
                    map(name => name ? this.filterText(name) : data.slice())
                );
                this.isSpinerParentGroup = false;
                return data;
            }),
            shareReplay(1),
            catchError(() => {
                this.isSpinerParentGroup = false;
                return observableOf([]);
            })
        ).subscribe(data => {
            this.parentgroups = data;

            // Turn off Waiting checking
            this.EmployerDtlFormGroup.setErrors({ waitingcheck: false });

            // Check if there is any Parent Group
            if (data.length == 0) {
                this.EmployerDtlFormGroup.updateValueAndValidity({
                    onlySelf: true
                });
                this.EmployerDtlFormGroup.setErrors({ notexisting: true });
                this.txtParentGroupControl.setErrors({ notexisting: true });
            }
            // End
        });
    }

    public txtParentGroupControl_Lostfocus(event) {
        const val = event.target.value.trim();
        if (!StringHelper.isNullOrEmpty(val)) {
            if ((this.ddlParentGroupAutoComplete.options.length > 0
                && StringHelper.isNullOrEmpty(this.txtParentGroupControl.value.Name))
                || typeof this.txtParentGroupControl.value === 'string') {
                this.EmployerDtlFormGroup.setErrors({ notexisting: true });
                this.txtParentGroupControl.setErrors({ notexisting: true });
            }
        }
        if (event.target.value.trim() == ''
            && this.txtParentGroupControl.errors == null
            && this.ddlParentGroupAutoComplete.options.length == 0) {
            this.EmployerDtlFormGroup.setErrors({ notexisting: false });
            this.EmployerDtlFormGroup.updateValueAndValidity({
                onlySelf: true
            });

            this.txtParentGroupControl.setErrors({ notexisting: false });
            this.txtParentGroupControl.updateValueAndValidity({
                onlySelf: true
            });
        }
    }

    public txtAccountManagerControl_Lostfocus(event) {
        const val = event.target.value.trim();
        if (!StringHelper.isNullOrEmpty(val)) {
            if ((this.ddlAccountManagerAutoComplete.options.length > 0
                && StringHelper.isNullOrEmpty(this.txtAccountManagerControl.value.Name))
                || typeof this.txtAccountManagerControl.value === 'string') {
                // || this.ddlAccountManagerAutoComplete.options.length === 0
                this.EmployerDtlFormGroup.setErrors({ notexisting: true });
                this.txtAccountManagerControl.setErrors({ notexisting: true });
            }
        }
        if (val === ''
            && this.txtAccountManagerControl.errors == null
            && this.ddlAccountManagerAutoComplete.options.length === 0) {
            this.EmployerDtlFormGroup.setErrors({ notexisting: false });
            this.EmployerDtlFormGroup.updateValueAndValidity({
                onlySelf: true
            });

            this.txtAccountManagerControl.setErrors({ notexisting: false });
            this.txtAccountManagerControl.updateValueAndValidity({
                onlySelf: true
            });
        }
    }

    public txtBDevelopManagerControl_Lostfocus(event) {
        const val = event.target.value.trim();
        if (!StringHelper.isNullOrEmpty(val)) {
            if ((this.ddlBDevManagerAutoComplete.options.length > 0
                && StringHelper.isNullOrEmpty(this.txtBDevManagerControl.value.Name))
                || typeof this.txtBDevManagerControl.value === 'string') {
                this.EmployerDtlFormGroup.setErrors({ notexisting: true });
                this.txtBDevManagerControl.setErrors({ notexisting: true });
            }
        }
        if (event.target.value.trim() === ''
            && this.txtBDevManagerControl.errors == null
            && this.ddlBDevManagerAutoComplete.options.length === 0) {
            this.EmployerDtlFormGroup.setErrors({ notexisting: false });
            this.EmployerDtlFormGroup.updateValueAndValidity({
                onlySelf: true
            });

            this.txtBDevManagerControl.setErrors({ notexisting: false });
            this.txtBDevManagerControl.updateValueAndValidity({
                onlySelf: true
            });
        }
    }

    private LoadAccountManager(accname) {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                // this.StartProgressBar();
                this.isSpinerAcc = true;
                const params = {
                    name: accname,
                    id: AccountManageRoleName.BUSINESS_DEVELOPMENT_OR_OPERATION
                };

                // Turn on Waiting checking
                // this.EmployerDtlFormGroup.setErrors({ waitingcheck: true });
                this.txtAccountManagerControl.markAsPending();
                const encrypteddata = this.commonservice.E_FP_RequestData(JSON.stringify(params));
                return this.httpdao!.postData(APIConstant.API_GET_ACCOUNT_MANAGER_BY_NAME, { "Data": encrypteddata });
            }),
            map(edata => {
                const dectypted_data = this.commonservice.D_FP_ResponseData(edata);
                const rdata = JSON.parse(dectypted_data);
                this.filteredACCGroup = this.txtAccountManagerControl.valueChanges.pipe(
                    startWith<string | CommonResponse>(''),
                    map(value => typeof value === 'string' ? value : value.Name),
                    map(name => name ? this.filterTextAccountManager(this.accmanagers, name) : rdata.Data.slice())
                );
                // this.StopProgressBar();
                this.isSpinerAcc = false;
                return rdata.Data;
            }),
            shareReplay(1),
            catchError(() => {
                // this.StopProgressBar();
                this.isSpinerParentGroup = false;
                return observableOf([]);
            })
        ).subscribe(data => {
            this.accmanagers = data;

            // Turn off Waiting checking
            this.txtAccountManagerControl.updateValueAndValidity({
                // onlySelf: true,
                emitEvent: false,
            });

            // Check if there is any Account Manager
            if (data.length === 0) {
                this.EmployerDtlFormGroup.setErrors({ notexisting: true });
                this.txtAccountManagerControl.setErrors({ notexisting: true });
            }
            // End
        });
    }

    private LoadBusinessDevelopManager(accname) {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                this.isSpinerDevelopAcc = true;
                const params = {
                    name: accname,
                    id: AccountManageRoleName.BUSINESS_DEVELOPMENT
                };
                // Turn on Waiting checking
                this.txtBDevManagerControl.markAsPending();
                const encrypteddata = this.commonservice.E_FP_RequestData(JSON.stringify(params));
                return this.httpdao!.postData(APIConstant.API_GET_ACCOUNT_MANAGER_BY_NAME, { "Data": encrypteddata });
            }),
            map(edata => {
                const dectypted_data = this.commonservice.D_FP_ResponseData(edata);
                const rdata = JSON.parse(dectypted_data);
                this.filteredBDevelopManager = this.txtBDevManagerControl.valueChanges.pipe(
                    startWith<string | CommonResponse>(''),
                    map(value => typeof value === 'string' ? value : value.Name),
                    map(name => name ? this.filterTextAccountManager(this.busicessDevelopManager, name) : rdata.Data.slice())
                );
                this.isSpinerDevelopAcc = false;
                return rdata.Data;
            }),
            shareReplay(1),
            catchError(() => {
                this.isSpinerDevelopAcc = false;
                return observableOf([]);
            })
        ).subscribe(data => {
            this.busicessDevelopManager = data;
            // Turn off Waiting checking
            this.txtBDevManagerControl.updateValueAndValidity({
                // onlySelf: true,
                emitEvent: false,
            });

            // Check if there is any Business Development Manager
            if (data.length === 0) {
                // this.EmployerDtlFormGroup.updateValueAndValidity({
                //     onlySelf: true
                // });
                this.EmployerDtlFormGroup.setErrors({ notexisting: true });
                this.txtBDevManagerControl.setErrors({ notexisting: true });
            }
            // End
        });
    }

    private LoadSuburbPhy(suburb) {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                // this.StartProgressBar();
                const criteria = new SearchPaginationCriteria();
                criteria.PageNumber = 0;
                criteria.PageSize = 0;
                criteria.OrderBy = 'name';
                criteria.OrderByAsc = false;
                criteria.ViewColumns = null;
                criteria.Criteria = {
                    Fields: [{ Name: 'name', Operator: SearchFieldOperator.Contains, Value: suburb }],
                    Expressions: [],
                    LogicGroup: 1
                };
                this.isSpinerPhysub = true;
                return this.httpdao!.postData(APIConstant.API_ADVANCED_SEARCH_SUBURB, criteria);
            }),
            map(data => {
                const results = data ? data.Data.Results : [];
                this.filteredSuburbPhy = this.SuburbPhyCtrl.valueChanges.pipe(
                    startWith<string | SuburbSearchResultRecord>(''),
                    map(value => typeof value == 'string' ? value : value.Name),
                    map(name => name ? this.filterSuburbText(results, name) : results.slice())
                );
                // this.StopProgressBar();
                this.isSpinerPhysub = false;
                if (results == null || results == undefined || results.length == 0) {
                    this.EmployerDtlFormGroup.get('chkSameAsAbove').disable();
                }
                return results;
            }),
            shareReplay(1),
            catchError(() => {
                // this.StopProgressBar();
                this.isSpinerPhysub = false;
                return observableOf([]);
            })
        ).subscribe(data => this.suburbphys = data);
    }

    private LoadSuburbMail(suburb) {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                // this.StartProgressBar();
                const criteria = new SearchPaginationCriteria();
                criteria.PageNumber = 0;
                criteria.PageSize = 0;
                criteria.OrderBy = 'name';
                criteria.OrderByAsc = false;
                criteria.ViewColumns = null;
                criteria.Criteria = {
                    Fields: [{ Name: 'name', Operator: SearchFieldOperator.Contains, Value: suburb }],
                    Expressions: [],
                    LogicGroup: 1
                };
                this.isSpinerPhyMail = true;
                return this.httpdao!.postData(APIConstant.API_ADVANCED_SEARCH_SUBURB, criteria);
            }),
            map(data => {
                const results = data ? data.Data.Results : [];
                this.filteredSuburbMail = this.SuburbMailCtrl.valueChanges.pipe(
                    startWith<string | SuburbSearchResultRecord>(''),
                    map(value => typeof value == 'string' ? value : value.Name),
                    map(name => name ? this.filterSuburbText(results, name) : results.slice())
                );
                // this.StopProgressBar();
                this.isSpinerPhyMail = false;
                return results;
            }),
            shareReplay(1),
            catchError(() => {
                // this.StopProgressBar();
                this.isSpinerPhyMail = true;
                return observableOf([]);
            })
        ).subscribe(data => this.suburbmails = data);
    }

    private LoadPostCodePhyBySubrubName(sname, val, firstload) {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                this.StartProgressBar();
                const criteria = new SearchPaginationCriteria();
                criteria.PageNumber = 0;
                criteria.PageSize = 0;
                criteria.OrderBy = 'name';
                criteria.OrderByAsc = false;
                criteria.ViewColumns = null;
                criteria.Criteria = {
                    Fields: [{
                        Name: 'name', Operator: SearchFieldOperator.Equals, Value: sname
                    }, {
                        Name: 'postcode_id', Operator: SearchFieldOperator.In, Value: [val]
                    }],
                    Expressions: [],
                    LogicGroup: 1
                };
                return this.httpdao!.postData(APIConstant.API_ADVANCED_SEARCH_SUBURB, criteria);
                // return this.httpdao!.getData(APIConstant.API_GET_POSTCODE_AND_STATE_BY_SUBURBNAME + encodeURIComponent(sname));
            }),
            map(data => {
                const results = data.Data ? data.Data.Results : [];
                if (results.length > 0) {
                    const suburd = results[0] || {};
                    this.postcodesphy = [];
                    if (firstload) {
                        // this.EmployerDtlFormGroup.get('ddlPostCodePhy').setValue(val);
                        this.SetValueStateCountryPhyBySuburdPostCode(suburd);
                        if (this.isReadonly) {
                            this.EmployerDtlFormGroup.get('ddlPostCodePhy').disable();
                            this.EmployerDtlFormGroup.get('ddlStatePhy').disable();
                        }
                    } else {
                        this.EmployerDtlFormGroup.get('ddlPostCodePhy').setValue('');
                        this.StopProgressBar();
                    }
                }
                return data;
            }),
            shareReplay(1),
            catchError(() => {
                this.StopProgressBar();
                return observableOf([]);
            })
        ).subscribe(() => {
            if (firstload == true && this.employerdtlobj.Suburb_Mail != null
                && this.employerdtlobj.Suburb_Mail != ''
                && this.employerdtlobj.Suburb_Mail != undefined) {
                this.LoadPostCodeMailBySubrubName(this.employerdtlobj.Suburb_Mail, this.employerdtlobj.PostCodeId_Mail, true, false);
            } else {
                this.StopProgressBar();
            }
            if (this.command === this.EDIT) {
                this.commonservice.AddChangeListenerInput();
            }
        });
    }

    private LoadPostCodeMailBySubrubName(sname, val, firstload, isSameAsChecked) {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                this.StartProgressBar();
                this.StartProgressBar();
                const criteria = new SearchPaginationCriteria();
                criteria.PageNumber = 0;
                criteria.PageSize = 0;
                criteria.OrderBy = 'name';
                criteria.OrderByAsc = false;
                criteria.ViewColumns = null;
                criteria.Criteria = {
                    Fields: [{
                        Name: 'name', Operator: SearchFieldOperator.Equals, Value: sname
                    }, {
                        Name: 'postcode_id', Operator: SearchFieldOperator.In, Value: [val]
                    }],
                    Expressions: [],
                    LogicGroup: 1
                };
                return this.httpdao!.postData(APIConstant.API_ADVANCED_SEARCH_SUBURB, criteria);
            }),
            map(data => {
                const results = data.Data ? data.Data.Results : [];
                if (results.length > 0) {
                    const suburd = results[0] || {};
                    this.postcodesmail = [];
                    if (firstload) {
                        this.SetValueStateCountryMailBySuburdPostCode(suburd);
                        if (this.isReadonly) {
                            this.EmployerDtlFormGroup.get('ddlPostCodeMail').disable();
                            this.EmployerDtlFormGroup.get('ddlStateMail').disable();
                        }
                    } else {
                        this.EmployerDtlFormGroup.get('ddlPostCodeMail').setValue('');
                    }
                }
                this.StopProgressBar();
                return data;
            }),
            shareReplay(1),
            catchError(() => {
                this.StopProgressBar();
                return observableOf([]);
            })
        ).subscribe(data => {

        });
    }

    private handleSaveEdit() {
        if (this.command == this.EDIT) {
            if (Number(this.employerdtlobj.EmployerStatusId) === EEmployerStatus.FailedLaunch) {
                // check employer active member
                this.CheckEmployerActiveMember((isActive) => {
                    if (isActive) {
                        // show message box
                        MessageBox.ShowError(this.dialog,
                            'Employer cannot be moved into ‘Failed Launch’ status since there is active member(s)');
                        return;
                    } else {
                        const surveyValues = this.employerSurveyPage.getSurveyValues();
                        this.UpdateEmployer(this.employerdtlobj, surveyValues);
                    }
                    
                });
            } else {
                const surveyValues = this.employerSurveyPage.getSurveyValues();
                this.UpdateEmployer(this.employerdtlobj, surveyValues);
            }
        } else if (this.command == this.ADDCLONE || this.command == this.ADDNEW) {
            const surveyValues = this.employerSurveyPage.getSurveyValues();
            this.CreateEmployer(this.employerdtlobj, surveyValues);
        } 
    }

    public SaveClick() {
        if (this.EmployerPageFormGroup.invalid || this.EmployerDtlFormGroup.invalid) {
            this.pageData.additionalData.isSubmit = true;
            window.scrollTo(0, 0);
            return;
        }
        if (this.EmployerSurveyPageFormGroup.invalid) {
            this.pageData.additionalData.isSubmit = true;
            return;
        }
        // else this.isSubmit = false;
        this.SetControlToEmployerDtl();
        this.handleSaveEdit();
        // Save for Employer Page Tab
    }

    private ShowMessageBoxSaveSuccess(EmployerId: number, isUpdate: boolean) {
        MessageBox.ShowInfo(this.dialog, 'Data has been saved successfully').subscribe(
            data => {
                if (!isUpdate) {
                    this.router.navigate([RouterConstant.NAVIGATOR_EMPLOYER_EDIT + EmployerId]);
                } else {
                    // reload data
                    this.LoadEmployerForEditCase();
                    // reload membership package
                    this.membershipPackage.LoadMembershipPackages();
                }
            }
        );
    }

    private UpdateEmployer(EmpDtlObj: EmployerDetailModel, surveyValues: EmployerSurveyModel, updatechildrendata = true) {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                //Update Survey
                if(surveyValues){
                    EmpDtlObj.EmployerSurvey.push(surveyValues);
                }
                
                // EmpDtlObj.EmpNextPaymentDateChanged = this.EmployerDtlFormGroup.controls.dtpStartDate.dirty;
                if (this.EmployerDtlFormGroup.controls.dtpStartDate.dirty || this.EmployerDtlFormGroup.controls.dtpNextPayment.dirty) {
                    EmpDtlObj.EmpNextPaymentDateChanged = true;
                }
                this.StartProgressBar();
                const encrypteddata = this.commonservice.E_FP_RequestData(JSON.stringify(EmpDtlObj));
                return this.httpdao!.postData(APIConstant.API_UPDATE_EMPLOYER_DETAIL, { Data: encrypteddata });
            }),
            map(value => {
                const dectypted_data = this.commonservice.D_FP_ResponseData(value);
                const rdata: any = JSON.parse(dectypted_data);
                this.StopProgressBar();
                if (rdata.Success == true) {
                    if (updatechildrendata == true) {
                        const EmployerId = this.employerdtlobj.EmployerId;
                        let UserName = this.commonservice.GetUser();
                        this.employerdtlobj.ModifiedBy = UserName;
                        this.membershipPackage.UpdateMembershipPackage(EmployerId, () => {
                            if (this.EmployerPageChanged) {
                                this.employerPage.CreateEmployerPage(EmployerId, () => {
                                    this.additionalAttachs.SaveAdditionalAttachment(EmployerId, () => {
                                        this.employerBranchs.SaveBranchs(EmployerId, () => {
                                            this.ShowMessageBoxSaveSuccess(EmployerId, true);
                                        });
                                    });
                                });
                            } else {
                                this.additionalAttachs.SaveAdditionalAttachment(EmployerId, () => {
                                    this.employerBranchs.SaveBranchs(EmployerId, () => {
                                        this.ShowMessageBoxSaveSuccess(EmployerId, true);
                                    });
                                });
                            }
                        });
                    } else {
                        this.LoadEmployerForEditCase(true);
                    }
                } else {
                    MessageBox.ShowError(this.dialog, rdata.Message || 'An error has been occured while update Employer data');
                }
                return rdata;
            }),
            catchError(() => {
                this.StopProgressBar();
                return observableOf([]);
            })
        ).subscribe();
    }

    private CreateEmployer(EmpDtlObj: EmployerDetailModel ,surveyValues: EmployerSurveyModel) {

        //Create Survey
        if (!EmpDtlObj.EmployerSurvey && surveyValues ) {
            EmpDtlObj.EmployerSurvey = [];
            EmpDtlObj.EmployerSurvey.push(surveyValues);
        }else{
            EmpDtlObj.EmployerSurvey = [];
        }
        const encrypteddata = this.commonservice.E_FP_RequestData(JSON.stringify(EmpDtlObj));
        merge().pipe(
            startWith({}),
            switchMap(() => {
                this.StartProgressBar();
                return this.httpdao!.postData(APIConstant.API_CREATE_EMPLOYER_DETAIL, { Data: encrypteddata });
            }),
            map(value => {
                const dectypted_data = this.commonservice.D_FP_ResponseData(value);
                const rdata: any = JSON.parse(dectypted_data);
                this.StopProgressBar();
                if (rdata.Success === true) {
                    const empid = rdata.Message;
                    const UserName = localStorage.getItem("User");

                    console.log('Create Employer Username: ', UserName);

                    const that = this;
                    this.membershipPackage.UpdateMembershipPackage(empid, () => {
                        this.employerPage.CreateEmployerPage(empid, () => {
                            this.additionalAttachs.SaveAdditionalAttachment(empid, () => {
                                this.employerBranchs.SaveBranchs(empid, () => {
                                    this.ShowMessageBoxSaveSuccess(empid, false);
                                });
                            });
                        });
                    });
                } else {
                    MessageBox.ShowError(this.dialog, rdata.Message || 'An error has been occured while creating Employer data');
                }
                return rdata;
            }),
            catchError(() => {
                this.StopProgressBar();
                return observableOf([]);
            })
        ).subscribe();
    }

    public btnNext_Click(tabName?: string) {
        this.btnNextClicked = true;
        if (tabName === 'EmployerPage') {
            if (!this.pageData) {
                this.pageData = new EmployerPageFormSharedData();
            }
            this.btnNextEmployerPage = true;
            this.pageData.additionalData.btnNextEmployerPage = this.btnNextEmployerPage;
        } else if (tabName === 'EmployerDetailTab') {
            const ret: ValidatorMessageModel[] = this.commonservice.GetValidateMessages(this.EmployerDtlFormGroup);
            if (ret.length > 0) {
                const msg: string = this.commonservice.FormatValidateMessage(ret);
                MessageBox.ShowError(this.dialog, 'Required field is missing');
            }
        }
    }

    public stepClick(event) {
        const selectedIndex = event.selectedIndex;
        // Author: Da Do
        // Add passed tab to array
        if (this.tabpassed.indexOf(event.selectedIndex) == -1) {
            this.tabpassed.push(event.selectedIndex);
        }
        // End
        switch (selectedIndex) {
            case EmployerTab.BranchLocations:
                this.branchsData = {
                    additionalData: {
                        states: this.states,
                        countries: this.countries
                    },
                    model: this.employerdtlobj,
                    requestQueueCount: 0
                };
                setTimeout(() => { this.employerBranchs.LoadLocations(); });
                break;
            case EmployerTab.MembershipPackages:
                // Author: Trang Nguyen
                // load data init
                if (this.membershipPackage.getMembershipPackageTypesData() == null ||
                    this.membershipPackage.getMembershipPackageTypesData().length === 0) {
                    this.membershipPackage.LoadDataInitMembershipPackage();
                }
                // load data membership package
                if ((this.command == this.EDIT || this.command == this.READ_ONLY)
                    && this.employerdtlobj.EmployerId != null && this.employerdtlobj.EmployerId != undefined) {
                    if (this.membershipPackage.getMembershipPackageData() == null ||
                        this.membershipPackage.getMembershipPackageData().length === 0) {
                        this.membershipPackage.LoadMembershipPackages();
                    }
                }
                break;
            case EmployerTab.EmployerPage:
                this.EmployerPageChanged = true;
                if ((this.command == this.EDIT || this.command == this.READ_ONLY)
                    && this.employerdtlobj.EmployerId != null && this.employerdtlobj.EmployerId != undefined) {
                    if (this.command == this.READ_ONLY)
                        this.employerPage.setDisable();
                    this.employerPage.LoadEmployerPage(this.employerdtlobj.EmployerId);
                    this.employerSurveyPage.LoadEmployerSurveyPage(this.employerdtlobj);
                } else {
                    const linkName = this.EmployerDtlFormGroup.controls.txtEmployerCode.value.replace(/ /g, '');
                    this.EmployerPageFormGroup.controls.introduction.patchValue(EmployerPageConstant.pageText);
                    this.EmployerPageFormGroup.controls.eligibility.patchValue(EmployerPageConstant.eligibilityText);
                    this.EmployerPageFormGroup.controls.additionInfo.patchValue(EmployerPageConstant.additionalDetailsText);
                    this.EmployerPageFormGroup.controls.linkName.patchValue(linkName);
                    this.EmployerSurveyPageFormGroup.controls.employerCode.patchValue(window.location.origin + '/' + RouterConstant.ROUTER_SIGNUP_Survey + '?empcode=' + linkName)
                }
                break;
            case EmployerTab.AdditionalAttachments:
                if (!this.pageData) {
                    this.pageData = new EmployerPageFormSharedData();
                }
                this.btnNextEmployerPage = false; // reset
                if (!this.pageData) {
                    this.pageData = new EmployerPageFormSharedData();
                }
                this.pageData.additionalData.btnNextEmployerPage = this.btnNextEmployerPage;
                // Author: Trang Nguyen
                if ((this.command === this.EDIT || this.command === this.READ_ONLY)
                    && this.employerdtlobj.EmployerId != null && this.employerdtlobj.EmployerId !== undefined) {
                    if ((this.additionalAttachs.GetDataImagesAttachments() == null ||
                        this.additionalAttachs.GetDataImagesAttachments().length === 0) &&
                        (this.additionalAttachs.GetDataDocumentAttachments() == null ||
                            this.additionalAttachs.GetDataDocumentAttachments().length === 0)) {
                        this.additionalAttachs.LoadDocumentAttachments();
                        this.additionalAttachs.LoadImageAttachments();
                    }
                }
                break;
            case EmployerTab.AuditLog:
                this.auditLog.OnLoad();
                break;
            default:
                break;
        }
        if (this.commonservice.FormDataChange === true && this.command === this.EDIT) {
            const previousindex = event.previouslySelectedIndex;
            this.commonservice.FormDataChange = false;
            MessageBox.ShowYesNoCancel(this.dialog, 'You have made changes, would you like to save ?').subscribe(ret => {
                if (ret.result.toLowerCase() === DialogResult.Yes) {
                    this.SaveClick();
                } else if (ret.result.toLowerCase() === DialogResult.No) {
                    return;
                } else {
                    this.stepper.selectedIndex = previousindex;
                }
            });
        }
    }

    public HeaderClick(tabName: string) {
        this.btnNextClicked = true; // trigger the validate
        if (tabName === 'Additional') { // next tab Employer page
            if (!this.pageData) {
                this.pageData = new EmployerPageFormSharedData();
            }
            this.btnNextEmployerPage = true;
            this.pageData.additionalData.btnNextEmployerPage = this.btnNextEmployerPage;

        }
    }

    public ddlParentGroupAutoComplete_Selected(event) {
        if (event.option.value.Id == '' || event.option.value.Id == undefined
            || event.option.value.Id == null) {
            return;
        } else {
            if (this.txtAccountManagerControl.errors != null) {
                return;
            }
            // If users select a suggestion, then clear validation.
            this.EmployerDtlFormGroup.setErrors({ notexisting: false });
            this.EmployerDtlFormGroup.updateValueAndValidity({
                onlySelf: true
            });

            this.txtParentGroupControl.setErrors({ notexisting: false });
            this.txtParentGroupControl.updateValueAndValidity({
                onlySelf: true
            });
            // End clear
        }
    }

    public ddlAccountManagerAutoComplete_Selected(event) {
        if (event.option.value.Id == '' || event.option.value.Id == undefined
            || event.option.value.Id == null) {
            return;
        } else {
            if (this.txtParentGroupControl.errors != null) {
                return;
            }
        }
    }

    public ddlBDevelopManagerAutoComplete_Selected(event) {
        if (event.option.value.Id === '' || event.option.value.Id === undefined
            || event.option.value.Id == null) {
            return;
        } else {
            if (this.txtBDevManagerControl.errors != null) {
                return;
            }
        }
    }

    private SetValueStateCountryPhyBySuburdPostCode(suburb: SuburbSearchResultRecord) {
        if (suburb.Postcode) {
            const postcode: CommonResponse = {
                Name: suburb.Postcode ? suburb.Postcode.Name : '',
                Id: suburb.Postcode ? suburb.Postcode.Id : 0,
            };
            this.postcodesphy.push(postcode);
        }
        this.SuburbPhyCtrl.setValue(suburb);
        this.EmployerDtlFormGroup.get('ddlPostCodePhy').setValue(suburb.Postcode ? suburb.Postcode.Id : '');
        this.EmployerDtlFormGroup.get('ddlStatePhy').setValue(suburb.State ? suburb.State.Id : '');
        this.EmployerDtlFormGroup.get('ddlCountryPhy').setValue(suburb.Country ? suburb.Country.Id : '');
        if (suburb.Region === null) {
            this.EmployerDtlFormGroup.get('txtRegionPhy').setValue('There is no region for the postcode '
                + suburb.Postcode.Name);
        } else {
            this.EmployerDtlFormGroup.get('txtRegionPhy').setValue(suburb.Region.Name);
        }
    }

    public ddlSuburbPhyAutoComplete_Selected(event) {
        this.suburbSelected = true;
        this.addressSelected = false;
        const value = event.option.value;
        this.postcodesphy = [];
        this.EmployerDtlFormGroup.get('txtRegionPhy').setValue('');
        if (event.option.value.Name == '' || event.option.value.Name === null) {
            return;
        } else {
            // fill data to postcode, state, country, region
            this.SetValueStateCountryPhyBySuburdPostCode(value);
            this.HandleDisableSameAsAdd();
        }
    }

    private SetValueStateCountryMailBySuburdPostCode(suburb: SuburbSearchResultRecord) {
        if (suburb.Postcode) {
            const postcode: CommonResponse = {
                Name: suburb.Postcode ? suburb.Postcode.Name : '',
                Id: suburb.Postcode ? suburb.Postcode.Id : 0,
            };
            this.postcodesmail.push(postcode);
        }
        this.SuburbMailCtrl.setValue(suburb);
        this.EmployerDtlFormGroup.get('ddlPostCodeMail').setValue(suburb.Postcode ? suburb.Postcode.Id : '');
        this.EmployerDtlFormGroup.get('ddlStateMail').setValue(suburb.State ? suburb.State.Id : '');
        this.EmployerDtlFormGroup.get('ddlMACountry').setValue(suburb.Country ? suburb.Country.Id : '');
    }

    public ddlSuburbMailAutoComplete_Selected(event) {
        const value = event.option.value;
        this.postcodesmail = [];
        if (value.Name === '' || value.Name === null) {
            return;
        } else {
            // fill data to postcode, state, country, region
            this.SetValueStateCountryMailBySuburdPostCode(value);
        }
    }

    public chkSameAsAbove_Change(event) {
        if (event.target.checked == true) {
            this.postcodesmail = []
            const suburdMail = this.SuburbPhyCtrl.value;
            //  this.EmployerDtlFormGroup.get('txtMAAddsLine1').setValue(this.EmployerDtlFormGroup.get('txtStreet1').value);
            this.EmployerDtlFormGroup.get('txtMAAddsLine1').setValue(this.EmployerDtlFormGroup.get('txtStreet1').value);
            this.EmployerDtlFormGroup.get('txtMAAddsLine2').setValue(this.EmployerDtlFormGroup.get('txtStreet2').value);
            this.SuburbMailCtrl.setValue(this.SuburbPhyCtrl.value);
            if (this.addressSelected) {
                const postcode: CommonResponse = {
                    Name: this.EmployerDtlFormGroup.get('ddlPostCodePhy').value,
                    Id: this.EmployerDtlFormGroup.get('ddlPostCodePhy').value,
                };
                this.postcodesmail = [postcode]
                this.EmployerDtlFormGroup.get('ddlPostCodeMail').setValue(this.EmployerDtlFormGroup.get('ddlPostCodePhy').value);
                this.EmployerDtlFormGroup.get('ddlStateMail').setValue(this.EmployerDtlFormGroup.get('ddlStatePhy').value);
                this.EmployerDtlFormGroup.get('ddlMACountry').setValue(this.EmployerDtlFormGroup.get('ddlCountryPhy').value);
            } else if (this.suburbSelected) {
                this.SetValueStateCountryMailBySuburdPostCode(suburdMail);
            }
            if (this.EmployerDtlFormGroup.get('ddlCountryPhy').value == this.countriesId[1].CountryId) {
                this.EmployerDtlFormGroup.get('CityMail').setValue(this.EmployerDtlFormGroup.get('City').value);
            }

        }
    }

    private HandleDisableSameAsAdd() {
        if (this.EmployerDtlFormGroup.get('ddlCountryPhy').value == this.countriesId[1].CountryId) {
            if (this.EmployerDtlFormGroup.get('txtStreet1').valid && this.EmployerDtlFormGroup.get('ddlPostCodePhy').valid &&
                typeof this.EmployerDtlFormGroup.get('City').value === 'object' && this.EmployerDtlFormGroup.get('City').valid
            ) {
                this.EmployerDtlFormGroup.get('chkSameAsAbove').enable();
            } else {
                this.EmployerDtlFormGroup.get('chkSameAsAbove').disable();
            }

        } else {
            if (this.EmployerDtlFormGroup.get('txtStreet1').valid 
            && this.EmployerDtlFormGroup.get('ddlPostCodePhy').valid           
            ) {
                this.EmployerDtlFormGroup.get('chkSameAsAbove').enable();
            } else {
                this.EmployerDtlFormGroup.get('chkSameAsAbove').disable();
            }
        }

    }

    public ddlPostCodePhy_Change(event) {
        this.HandleDisableSameAsAdd();
    }

    public ddlPostCodeMail_Change(event) {
        this.HandleDisableSameAsAdd();
    }

    public txtSuburbPhy_Change(event) {
        const val = event.target.value;
        if (val.length >= 3 && event.code != 'ArrowUp' && event.code != 'ArrowDown'
            && event.code != 'ArrowLeft' && event.code != 'ArrowRight') {
            // this.LoadSuburbPhy(val);
            this.suphy_subject.next(val);
        } else if (val.length < 3) {
            this.filteredSuburbPhy = this.SuburbPhyCtrl.valueChanges.pipe(
                startWith<string | SuburbSearchResultRecord>(''),
                map(value => typeof value === 'string' ? value : value.Name),
                map(name => name ? this.filterSuburbText(this.suburbphys, name) : [])
            );
            this.EmployerDtlFormGroup.get('ddlPostCodePhy').setValue('');
            this.postcodesphy = [];
        }

        if (this.EmployerDtlFormGroup.get('txtStreet1').valid &&
            this.EmployerDtlFormGroup.get('txtMAAddsLine1').valid &&
            this.EmployerDtlFormGroup.get('ddlPostCodePhy').valid) {
            this.EmployerDtlFormGroup.get('chkSameAsAbove').enable();
        } else {
            this.EmployerDtlFormGroup.get('chkSameAsAbove').disable();
        }
    }

    public txtSuburbMail_Change(event) {
        const val = event.target.value;
        if (val.length >= 3 && event.code != 'ArrowUp' && event.code != 'ArrowDown'
            && event.code != 'ArrowLeft' && event.code != 'ArrowRight') {
            //this.LoadSuburbMail(val);
            this.sumail_subject.next(val);
        } else if (val.length < 3) {
            this.filteredSuburbMail = this.SuburbMailCtrl.valueChanges.pipe(
                startWith<string | SuburbSearchResultRecord>(''),
                map(value => typeof value === 'string' ? value : value ? value.Name : ''),
                map(name => name ? this.filterSuburbText(this.suburbmails, name) : [])
            );
            this.EmployerDtlFormGroup.get('ddlPostCodeMail').setValue('');
            this.postcodesmail = [];
        }
    }

    public txtParentGroupControl_Change(event) {
        const val = event.target.value;
        if (val.length >= 3 && event.key != 'ArrowUp' && event.key != 'ArrowDown'
            && event.key != 'ArrowLeft' && event.key != 'ArrowRight' && event.key != 'Enter') {
            //this.LoadParentGroup(val);
            this.pg_subject.next(val);
        } else if (val.length < 3) {
            this.filteredPGroup = this.txtParentGroupControl.valueChanges.pipe(
                startWith<string | CommonResponse>(''),
                map(value => typeof value === 'string' ? value : value ? value.Name : ''),
                map(name => name ? this.filterText(name) : [])
            );
        }
    }

    public txtAccountManagerControl_Change(event) {
        const val = event.target.value;
        if (val.length >= 2 && event.key != 'ArrowUp' && event.key != 'ArrowDown'
            && event.key != 'ArrowLeft' && event.key != 'ArrowRight' && event.key != 'Enter') {
            this.acc_subject.next(val);
        } else if (val.length < 3) {
            this.filteredACCGroup = this.txtAccountManagerControl.valueChanges.pipe(
                startWith<string | CommonResponse>(''),
                map(value => typeof value === 'string' ? value : value.Name),
                map(name => name ? this.filterTextAccountManager(this.accmanagers, name) : [])
            );
        }
    }

    public txtBDevelopManagerControl_Keyup(event) {
        const val = event.target.value;
        if (val.length >= 2 && event.key !== 'ArrowUp' && event.key !== 'ArrowDown'
            && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && event.key !== 'Enter') {
            this.bDevelopManage_subject.next(val);
        } else if (val.length < 3) {
            this.filteredBDevelopManager = this.txtBDevManagerControl.valueChanges.pipe(
                startWith<string | CommonResponse>(''),
                map(value => typeof value === 'string' ? value : value.Name),
                map(name => name ? this.filterTextAccountManager(this.busicessDevelopManager, name) : [])
            );
        }
    }

    public txtEmployerName_Change(event) {
        let val = event.target.value;
        if (val != null && val != undefined) {
            val = val.toLowerCase();
            const originempname = (this.employerdtlobj.Name == null || this.employerdtlobj.Name == undefined) ?
                '' : this.employerdtlobj.Name.toLowerCase();
            if (this.command == this.EDIT && val != '' && val != originempname) {
                this.CheckEmployerName(val, true);
            } else if (this.command == this.ADDNEW && val != '') {
                this.CheckEmployerName(val, true);
            }
        }
    }

    public txtEmployerCode_Change(event) {
        let val = event.target.value;
        if (val.length == 0) {
            this.validlong = false;
            this.EmployerDtlFormGroup.get('txtEmployerCode').setErrors({ validlong: false });
            this.EmployerDtlFormGroup.get('txtEmployerCode').clearValidators();
            this.EmployerDtlFormGroup.get('txtEmployerCode').updateValueAndValidity({
                onlySelf: true
            });
            this.EmployerDtlFormGroup.get('txtEmployerCode').setValidators(Validators.required);
            this.EmployerDtlFormGroup.get('txtEmployerCode').updateValueAndValidity({
                onlySelf: true
            });
            return;
        }
        if (val != null && val != undefined) {
            if (val.length < 3) {
                this.validlong = true;
                this.EmployerDtlFormGroup.get('txtEmployerCode').setErrors({ validlong: true });
                return;
            }
            val = val.toLowerCase();
            const originempname = (this.employerdtlobj.Code == null || this.employerdtlobj.Code == undefined) ?
                '' : this.employerdtlobj.Code.toLowerCase();
            if (this.command == this.EDIT && val != '' && val != originempname) {
                this.CheckEmployerCode(val);
            } else if (this.command == this.ADDNEW && val != '') {
                this.CheckEmployerCode(val);
            }
            else if (this.command == this.ADDCLONE && val != '') {
                this.CheckEmployerCode(val);
            }
        }
    }

    private CheckEmployerName(nameval, turnoffcheck) {
        if (turnoffcheck) {
            return;
        }
        merge().pipe(
            startWith({}),
            switchMap(() => {
                this.isSpinerShowName = true;
                this.EmployerDtlFormGroup.setErrors({ waitingcheck: true });
                return this.httpdao!.getData(APIConstant.API_GET_EXACTLY_EMP_NAME + encodeURIComponent(nameval));
            }),
            map(data => {
                this.isSpinerShowName = false;
                return data;
            }),
            shareReplay(1),
            catchError(() => {
                this.isSpinerShowName = false;
                return observableOf([]);
            })
        ).subscribe(data => {
            this.EmployerDtlFormGroup.setErrors({ waitingcheck: false });
            this.EmployerDtlFormGroup.updateValueAndValidity({
                onlySelf: true
            });
            if (data != null && data.length > 0) {
                // Note - 08 Nov 2018 - The FP asked to remove the duplicate checking employer name.
                this.EmployerDtlFormGroup.get('txtEmployerName').setErrors({ duplicatename: true });
            } else {
                this.EmployerDtlFormGroup.get('txtEmployerName').clearValidators();
                this.EmployerDtlFormGroup.get('txtEmployerName').updateValueAndValidity({
                    onlySelf: true
                });
                this.EmployerDtlFormGroup.get('txtEmployerName').setValidators(Validators.required);
                this.EmployerDtlFormGroup.get('txtEmployerName').updateValueAndValidity({
                    onlySelf: true
                });
            }
        });
    }

    public MinDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
        if (control.value !== undefined && control.value !== "") {
            try {
                const d: Date = new Date(control.value.year, control.value.month - 1, control.value.day, 0, 0, 0);
                const dc = new Date(1900, 1, 1, 0, 0, 0);
                if ((d < dc || d.toString() === 'Invalid Date' || !(d instanceof Date))
                    && control.value !== null) {
                    return { 'mindateinvalid': true };
                } else {
                    return null;
                }
            } catch (e) {
                return { 'mindateinvalid': true };
            }
        } else {
            return null;
        }
    }

    public MinDateValidatorForDateClose(control: AbstractControl): { [key: string]: boolean } | null {
        const t = EEmployerStatus;
        if (control.value !== undefined && control.parent !== undefined && control.parent !== null) {
            const ddlStatusValue = control.parent.get('ddlStatus').value;
            if (Number(ddlStatusValue) === t.CloseForNewMembers ||
                Number(ddlStatusValue) === t.FailedLaunch) {
                const d: Date = new Date(control.value.year, control.value.month - 1, control.value.day, 0, 0, 0);
                const dc = new Date(1900, 1, 1, 0, 0, 0);
                if ((d < dc || d.toString() === 'Invalid Date' || !(d instanceof Date))
                    && control.value !== null) {
                    return { 'mindateinvalid': true };
                } else {
                    return null;
                }
            } else {
                return null;
            }

        } else {
            return null;
        }
    }

    public RequiredValidatorForDateClose(control: AbstractControl): { [key: string]: boolean } | null {
        const t = EEmployerStatus;
        if (control.value !== undefined && control.parent !== undefined && control.parent !== null) {
            const ddlStatusValue = control.parent.get('ddlStatus').value;
            if (Number(ddlStatusValue) === t.CloseForNewMembers ||
                Number(ddlStatusValue) === t.FailedLaunch) {
                if (control.value === null) {
                    return { 'required': true };
                } else {
                    return null;
                }
            } else {
                return null;
            }

        } else {
            return null;
        }
    }

    public RangeDateValidatorForDateClosed(control: AbstractControl): { [key: string]: boolean } | null {
        if (control.value !== undefined && control.parent !== undefined && control.parent !== null) {
            let enddate = control.parent.get('dtpDateClosed').value;
            let startdate = control.parent.get('dtpStartDate').value;
            let empstatus = control.parent.get('ddlStatus').value;
            if (startdate == null || empstatus === 1 || empstatus === 4 || empstatus === 5 || enddate == null || !(control.value instanceof Date)) {
                return null;
            } else {
                enddate = new Date(control.parent.get('dtpDateClosed').value);
                startdate = new Date(control.parent.get('dtpStartDate').value);
                enddate.setHours(0, 0, 0, 0);
                startdate.setHours(0, 0, 0, 0);
                if (enddate <= startdate) {
                    return { 'rangedateclosedinvalid': true };
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }


    public RangeDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
        if (control.value !== undefined && control.parent !== undefined && control.parent !== null) {
            const enddate = new Date(control.parent.get('dtpNextPayment').value);
            const startdate = new Date(control.parent.get('dtpStartDate').value);
            if (startdate == null || enddate == null || !(control.value instanceof Date)) {
                return null;
            } else {
                enddate.setHours(0, 0, 0, 0);
                startdate.setHours(0, 0, 0, 0);
                if (enddate < startdate) {
                    return { 'rangedateinvalid': true };
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }

    public UpdateValidateDateRange(ctrnames: string[]) {
        for (let i = 0; i < ctrnames.length; i++) {
            this.EmployerDtlFormGroup.get(ctrnames[i]).updateValueAndValidity();
        }
    }

    private CheckEmployerCode(nameval) {
        merge().pipe(
            startWith({}),
            switchMap(() => {
                this.isSpinerShowCode = true;
                this.EmployerDtlFormGroup.setErrors({ waitingcheck: true });
                return this.httpdao!.getData(APIConstant.API_GET_EXACTLY_EMP_CODE + encodeURIComponent(nameval));
            }),
            map(data => {
                this.isSpinerShowCode = false;
                return data;
            }),
            shareReplay(1),
            catchError(() => {
                this.isSpinerShowCode = false;
                return observableOf([]);
            })
        ).subscribe(data => {
            this.EmployerDtlFormGroup.setErrors({ waitingcheck: false });
            this.EmployerDtlFormGroup.updateValueAndValidity({
                onlySelf: true
            });

            if (data != null && data.length > 0) {
                this.EmployerDtlFormGroup.get('txtEmployerCode').setErrors({ duplicatename: true });
            } else {
                this.EmployerDtlFormGroup.get('txtEmployerCode').clearValidators();
                this.EmployerDtlFormGroup.get('txtEmployerCode').updateValueAndValidity({
                    onlySelf: true
                });
                this.EmployerDtlFormGroup.get('txtEmployerCode').setValidators(Validators.required);
                this.EmployerDtlFormGroup.get('txtEmployerCode').updateValueAndValidity({
                    onlySelf: true
                });
            }
        });
    }

    // This function is used in Contract Tab
    private ValidateDate(dateval): boolean {
        if (dateval == null) {
            return true;
        }
        if (dateval instanceof Date) {
            if (dateval.getFullYear() < 1900) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    // IsEmployerActiveMember = false;
    private CheckEmployerActiveMember(callback: Function) {
        if (this.getEmployerId() === 0) {
            return;
        }
        this.Invoke(
            this.svc.checkActiveMember(this.getEmployerId()),
            {
                onSuccess: (res: ResultDataModel) => {
                    if (res.Success) {
                        callback(res.Data);
                    }
                },
                onError: err => {
                }
            },
        );
    }

    public ChangeEmployerStatus(event) {
        const val = event.target.value;
        this.EmployerDtlFormGroup.get('ddlStatus').clearValidators();
        this.EmployerDtlFormGroup.get('ddlStatus').setErrors(null);
        this.EmployerDtlFormGroup.get('ddlStatus').updateValueAndValidity({
            onlySelf: true,
            emitEvent: false
        });
        const that = this;
        if (Number(val) === EEmployerStatus.FailedLaunch) {
            // call api check no active member
            this.CheckEmployerActiveMember(function (isActive) {
                if (isActive === true) {
                    that.EmployerDtlFormGroup.get('ddlStatus').setErrors({ isNoActiveMember: true });
                    return;
                } else {
                    that.EmployerDtlFormGroup.get('ddlStatus').clearValidators();
                    that.EmployerDtlFormGroup.get('ddlStatus').setErrors(null);
                    that.EmployerDtlFormGroup.get('ddlStatus').updateValueAndValidity({
                        onlySelf: true,
                        emitEvent: false
                    });
                }
            });
        } else {
            this.EmployerDtlFormGroup.get('ddlStatus').clearValidators();
            this.EmployerDtlFormGroup.get('ddlStatus').setErrors(null);
            this.EmployerDtlFormGroup.get('ddlStatus').updateValueAndValidity({
                onlySelf: true,
                emitEvent: false
            });
        }
        if (Number(val) !== this.EEmployerStatus.CloseForNewMembers &&
            Number(val) !== this.EEmployerStatus.FailedLaunch) {
            this.EmployerDtlFormGroup.get('dtpDateClosed').setValue(null);
            this.EmployerDtlFormGroup.get('dtpDateClosed').updateValueAndValidity();
        }

    }

    public txtNumber_Keyup(event, controlname) {
        let val = event.target.value;
        val = this.ReplaceNotNumber(val);
        this.EmployerDtlFormGroup.get(controlname).setValue(val);
    }

    public txtNumber_Change(event, controlname) {
        let val = event.target.value;
        val = this.ReplaceNotNumber(val);
        this.EmployerDtlFormGroup.get(controlname).setValue(val);
    }

    private ReplaceNotNumber(val) {
        const evkey = PatternConstant.NOT_NUMBER;
        const re = new RegExp(evkey, 'g');
        val = val.replace(re, '');
        return val;
    }

    //#endregion

    //#region Contract
    // Author: Yen
    public checkValueDate(controlname) {
        const startdateInput = this.ContractFormGroup.get('dtpCTStartDate').value;
        const enddateInput = this.ContractFormGroup.get('dtpCTEndDate').value;
        if (startdateInput != null && enddateInput != null) {
            const startdate: Date = new Date(startdateInput.year, startdateInput.month - 1, startdateInput.day, 0, 0, 0);
            const enddate: Date = new Date(enddateInput.year, enddateInput.month - 1, enddateInput.day, 0, 0, 0);
            this.ValidateDateContract(startdate, enddate);

            if (startdate == null && enddate == null) {
                this.contractDurationEnable = false;
                this.txtCDuration.nativeElement.disabled = false;
                this.ContractFormGroup.get('txtCDuration').setValue(0);
            } else {
                this.contractDurationEnable = true;
                this.txtCDuration.nativeElement.disabled = true;
                if (startdate != null && enddate != null) {
                    this.calculateContractDuration(startdate, enddate);
                    this.compareDate(startdate, enddate, controlname);
                } else {
                    this.ContractFormGroup.get('txtCDuration').setValue(0);
                    this.ContractFormGroup.get('dtpCTStartDate').setErrors(null);
                    this.ContractFormGroup.get('dtpCTEndDate').setErrors(null);
                    this.ValidateDateContract(startdate, enddate);
                }
            }
        } else {
            this.contractDurationEnable = false;
            this.txtCDuration.nativeElement.disabled = false;
            this.ContractFormGroup.get('txtCDuration').setValue(0);
        }
    }

    private ValidateDateContract(startdate, enddate) {
        if (this.ValidateDate(startdate) == false) {
            this.ContractFormGroup.get('dtpCTStartDate').setErrors({ invalidFormatDate: true });
            this.ContractFormGroup.get('txtCDuration').setValue(null);
        }
        if (this.ValidateDate(enddate) == false) {
            this.ContractFormGroup.get('dtpCTEndDate').setErrors({ invalidFormatDate: true });
            this.ContractFormGroup.get('txtCDuration').setValue(null);
        }
    }

    private compareDate(startdate, enddate, controlname) {
        if (startdate > enddate) {
            this.ContractFormGroup.get(controlname).setErrors({ invalidDate: true });
            this.ContractFormGroup.get('txtCDuration').setValue(0);
        } else {
            this.ContractFormGroup.get('dtpCTStartDate').setErrors(null);
            this.ContractFormGroup.get('dtpCTEndDate').setErrors(null);
            this.ValidateDateContract(startdate, enddate);
        }
    }

    private calculateContractDuration(startdate: Date, enddate: Date) {
        if (startdate instanceof Date && enddate instanceof Date) {

            const startDateStruct: NgbDateStruct = {
                day: startdate.getDate(),
                month: startdate.getMonth() + 1,
                year: startdate.getFullYear()
            };
            const endDateStruct: NgbDateStruct = {
                day: enddate.getDate(),
                month: enddate.getMonth() + 1,
                year: enddate.getFullYear()
            };
            //const startDateStruct = this.dpContractStartDate['_dateAdapter'].fromModel(startdate);
            //const endDateStruct = this.dpContractStartDate['_dateAdapter'].fromModel(enddate);
            const year = Utils.calculateContractDuration(startDateStruct, endDateStruct);
            this.ContractFormGroup.get('txtCDuration').setValue(year);
        }
    }

    //Author: Da Do
    //Update for upload control
    public SignedContract_UploadCompleted(event) {
        const ret: FpFileData = event;
        const signedContractSelected: EmployerContractUploadFile = new EmployerContractUploadFile();
        signedContractSelected.FileName = ret.originfilename;
        signedContractSelected.Base64 = '';
        signedContractSelected.Status = 'Addnew';
        signedContractSelected.Location = ret.filedata.Location;
        this.signedContract.push(signedContractSelected);
    }

    public _signedContractSelected(event: FpFileEvent) {
        if (event.file.type !== 'application/pdf') {
            event.cancel();
            MessageBox.ShowError(this.dialog,
                StringHelper.format(CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE, 'PDF')
            );
        }
    }

    public AttAdd_UploadCompleted(event) {
        const ret: FpFileData = event;
        const attachAdditionalSelected: EmployerContractUploadFile = new EmployerContractUploadFile();
        attachAdditionalSelected.FileName = ret.originfilename;
        attachAdditionalSelected.Status = 'Addnew';
        attachAdditionalSelected.Location = ret.filedata.Location;
        this.attachAdditional.push(attachAdditionalSelected);
    }

    public _AttachAdditionalSelected(event: FpFileEvent) {
        if (event.file.type !== 'image/png' &&
            event.file.type !== 'image/jpg' &&
            event.file.type !== 'image/jpeg' &&
            event.file.type !== 'application/pdf') {
            event.cancel();
            MessageBox.ShowError(this.dialog,
                StringHelper.format(CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE, 'JPEG or PDF')
            );
        } else if (event.file.type == 'application/pdf' && event.file.size > this.commonString.MAX_UPLOAD_FILE_SIZE_10MB * 10) {
            event.cancel();
            MessageBox.ShowError(this.dialog,
                StringHelper.format(CommonString.MAX_UPLOAD_FILE_SIZE_MESSAGE, '100MB')
            );
        } else if (event.file.type !== 'application/pdf' && event.file.size > this.commonString.MAX_UPLOAD_FILE_SIZE_10MB) {
            event.cancel();
            MessageBox.ShowError(this.dialog,
                StringHelper.format(CommonString.MAX_UPLOAD_FILE_SIZE_MESSAGE, '10MB')
            );
        }
    }

    public Logo_UploadCompleted(event) {
        const ret: FpFileData = event;
        this._handleReaderLoadedLogo(ret);
    }

    public _logoSelected(event: FpFileEvent) {
        if (event.file.type !== 'image/png' &&
            event.file.type !== 'image/jpg' &&
            event.file.type !== 'image/jpeg') {
            event.cancel();
            MessageBox.ShowError(this.dialog,
                StringHelper.format(CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE, 'JPEG or PNG')
            );
        }
    }

    public IsAllSignContractRemoved(): boolean {
        for (let i = 0; i < this.signedContract.length; i++) {
            if (this.signedContract[i].Status == null || this.signedContract[i].Status == 'Addnew') {
                return false;
            }
        }
        return true;
    }

    public IsAllAdditionalFileRemoved(): boolean {
        for (let i = 0; i < this.attachAdditional.length; i++) {
            if (this.attachAdditional[i].Status == null || this.attachAdditional[i].Status == 'Addnew') {
                return false;
            }
        }
        return true;
    }

    public _handleReaderLoadedLogo(e) {
        this.logoSelected.FileName = e.originfilename;
        this.logoSelected.Location = e.filedata.Location;
        this.logoSelected.Status = 'Addnew';
        this.imageSrc = e.filedata.Location;
        if (this.command == this.EDIT) {
            this.logoSelected.EmployerContractId = this.route.snapshot.params.id;
        }
        if (this.employerdtlobj.EmployerContract != null && this.employerdtlobj.EmployerContract.EmployerContractLogo.length > 0) {
            //Edit
            this.removeLogo(this.employerdtlobj.EmployerContract.EmployerContractLogo[0],
                this.employerdtlobj.EmployerContract.EmployerContractLogo);
        } else if (this.employerdtlobj.EmployerContract == null && this.logoContract.length > 0) {
            //Add new
            this.removeLogo(this.logoContract[0], this.logoContract);
        }

        this.logoContract.push(this.logoSelected);
        this.imageDefault = false;
    }

    public removeSignedContract(e, arr: Array<any>) {
        this.txtSignedContract.ResetFileInputValue();
        if (e.Status == null) {
            e.Status = 'Remove';
        } else {
            const index = arr.indexOf(e);
            if (index > -1) {
                arr.splice(index, 1);
            }
        }
    }

    public removeAttachAdditional(e, arr: Array<any>) {
        this.txtAttachAdditional.ResetFileInputValue();
        if (e.Status == null) {
            e.Status = 'Remove';
        } else {
            const index = arr.indexOf(e);
            if (index > -1) {
                arr.splice(index, 1);
            }
        }
    }
    public deleteFileClick(e, arr: Array<any>, num) {
        MessageBox.ShowCustom(this.dialog, 'Confirm', 'Confirm',
            'Are you sure want to delete the file?',
            MessageBoxButton.YesNo).subscribe(r => {
                if (r.result === DialogResult.Yes) {
                    if (num == 1) {
                        this.removeLogo(e, arr);
                    } else if (num == 2) {
                        this.removeSignedContract(e, arr);
                    } else if (num == 3) {
                        this.removeAttachAdditional(e, arr);
                    }
                }
            });
    }
    public removeLogo(e, arr: Array<any>) {
        this.imageDefault = true;
        this.txtAttachLogo.ResetFileInputValue();
        const addLogo = this.employerdtlobj.EmployerContract;
        if (addLogo != undefined && addLogo.EmployerContractLogo.length > 0) {
            //Edit
            if (e.Location && e.Status == null) {
                e.Status = 'Remove';
            } else if (addLogo.EmployerContractLogo.length == 2 || (e.Location && e.Status == 'Remove')) {
                const i = addLogo.EmployerContractLogo[1];
                const index = arr.indexOf(i);
                if (index > -1) {
                    arr.splice(index, 1);
                }
            } else {
                //Case load info not logo
                const j = addLogo.EmployerContractLogo[0];
                const index = arr.indexOf(j);
                if (index > -1) {
                    arr.splice(index, 1);
                }
            }
        } else {
            //Add new
            this.logoContract = [];
            const index = arr.indexOf(e);
            if (index > -1) {
                arr.splice(index, 1);
            }
        }
    }
    //#endregion

    //#region Facilitator
    // Author: Yen
    removeFacilitator(user: EmployerFacilitatorModel) {
        this.userService.removeItemFromArray(this.facilitatorsSelected, user);
        if (user.UserId == this.primaryFacilitator) {
            this.primaryFacilitator = null;
        }
        this.commonservice.FormDataChange = true;
    }

    setPrimaryFacilitator(f: any) {
        this.primaryFacilitator = f.UserId;
        this.commonservice.FormDataChange = true;
    }
    //#endregion

    public txtStreet1_Change(event) {
        this.HandleDisableSameAsAdd();
    }
    public txtMAAddsLine1_Change(event) {
        this.HandleDisableSameAsAdd();
    }

    public getEmployerId = function () {
        if ((this.command === this.EDIT || this.command == this.READ_ONLY)
            && this.employerdtlobj && this.employerdtlobj.EmployerId != null && this.employerdtlobj.EmployerId != undefined) {
            return this.employerdtlobj.EmployerId;
        }
        return 0;
    };

    onFocusParent(blurNumbr) {
        this.parentFocus = blurNumbr;
    }
    onBlurParent(focusNumbr) {
        this.parentFocus = focusNumbr;
    }
    getClassFocused(vlFocused) {
        if (this.parentFocus == vlFocused) {
            return 'focused';
        } else {
            return '';
        }
    }

    //page
    _processingLinkName(event) {
        this.submitDisabled = event;
    }

    // #Author: Trang Nguyen
    //#region Additional Notes
    public BtnClickAddNote() {
        const currentUser = <User>JSON.parse(this.commonservice.D_FP_AES256(localStorage.getItem(StorageKey.USER_OBJECT))) || <User>{};
        const dataNote = {
            EmployerNoteId: 0,
            EmployerId: this.getEmployerId(),
            UserFullName: currentUser.FirstName + ', ' + currentUser.LastName,
            UserId: Number(currentUser.UserId),
            Note: this.txtAdditionalEmployerNoteControl.value,
            DateTimeStamp: this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss'),
            /* Author: thuynh64
            Update Date/Time column to view a date with format dd-mmm-yyyy for Employer Notes grid */
            // DateTimeDisplay: this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm')
            DateTimeDisplay: DateHelper.format(new Date(), 'DD-MMM-YYYY HH:mm')
        };
        if (!StringHelper.isNullOrEmpty(dataNote.Note)) {
            const dtsource = this.dataSourceNotes.data;
            dtsource.unshift(dataNote);
            this.dataSourceNotes.data = dtsource;
            //FP-3901 - Adding notes should save to DB once click on Add Not button. Just for Edit mode.
            if (this.command === this.EDIT) {
                this.employerdtlobj.EmployerNotes = this.dataSourceNotes.data;
                const surveyValues = this.employerSurveyPage.getSurveyValues();
                this.UpdateEmployer(this.employerdtlobj,surveyValues, false);
            }
            //End
        }
        // reset input text
        this.txtAdditionalEmployerNoteControl.setValue('');
    }
    //#endregion

    InvokeNoCallback(source: Observable<any>, handleResultCallback: Function) {
        source.pipe(
            catchError((e) => { throw e; }))
            .subscribe(
                res => { handleResultCallback(res); },
                err => { this.errorHandler(err); }
            );
    }

    setNextPaymentRunDate() {
        const startDate = this.EmployerDtlFormGroup.get('dtpStartDate').value;
        if (startDate != null && startDate != undefined) {
            if (this.command === this.EDIT) {
                if (new Date(startDate).getTime() != this.currentStartDate.getTime()) {
                    this.EmployerDtlFormGroup.get('dtpNextPayment').setValue(startDate);
                } else {
                    this.EmployerDtlFormGroup.get('dtpNextPayment').setValue(this.currentNextPDate);
                }
            } else {
                this.EmployerDtlFormGroup.get('dtpNextPayment').setValue(startDate);
            }
        }
    }

    showChangedEmployerStartDateConfirmation(manualEntry: boolean) {
        let manualEntryValid: boolean;
        manualEntryValid = null;

        //If date entered is through keyboard then check if the input is valid
        if (manualEntry === true && this.EmployerDtlFormGroup.controls.dtpStartDate.dirty) {
            console.log('Valid: ', this.EmployerDtlFormGroup.get('dtpStartDate').valid);

            if (this.EmployerDtlFormGroup.get('dtpStartDate').valid) {
                manualEntryValid = true;
            }
        }

        //Check if in edit employer mode
        if (this.command === this.EDIT) {
            if ((manualEntry === false) || (manualEntry === true && manualEntryValid === true)) {
                const startDateConfirmationMsg = 'Changing the Employer Start Date automatically changes its next payment run date. This field is a critical financial value which will affect the employer\'s next direct debit run. Do you wish to continue?';

                MessageBox.ShowCancelContinue(this.dialog, 'Changing Employer Start Date', startDateConfirmationMsg).subscribe(dialogResult => {
                    if (dialogResult.result.toLowerCase() === DialogResult.Cancel) {
                        //Set employer start date and next payment run date to original values
                        this.EmployerDtlFormGroup.get('dtpStartDate').setValue(this.currentStartDate);
                        this.EmployerDtlFormGroup.get('dtpNextPayment').setValue(this.currentNextPDate);
                    }
                });
            }
        }
    }

    public SetLinearForTab() {
        if (this.command === this.EDIT) {
            if (this.EmployerDtlFormGroup && this.EmployerPageFormGroup && this.EmployerSurveyPageFormGroup) {
                if (this.EmployerDtlFormGroup.valid && this.EmployerPageFormGroup.valid && this.EmployerSurveyPageFormGroup.valid) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    OnLoad() {

        this.commonservice.getLocationData().subscribe(
            (res) => {
                if (res.Success) {
                    this.suburbList = res.Data.suburbs;
                }
            }
        );

    }

    /* Physical Address */

    /** @internal */
    AutoCompleteAddress_DisplayWithFn(option: any) {
        if (!option) {
            return '';
        }
        if (option.Text) {
            return option.Text;
        } else {
            return option
        }
    }

    searchAutoAddress() {
        this.LoadComplete()
    }


    LoadComplete() {
        setTimeout(() => {
            this.Addressline1Ctrl.valueChanges
                .pipe(
                    debounceTime(500),
                    switchMap(value => {
                        if (typeof value === 'string') {
                            this.selectedAddress1 = null;
                            this.filteredAdressList = [];

                            if (value.length > 2) {
                                return observableOf(value);
                            } else if (value.length === 0) {
                                return observableOf(null);
                            }
                        } else if (typeof value === 'object') {
                            return observableOf(value);
                        }
                        return empty();
                    })
                )
                .subscribe((value: string | SearchForSuggestionsResult) => {
                    if (value) {
                        if (typeof value === 'string') {
                            this.searchAddress(value);
                        } else if (typeof value === 'object') {
                            this.SelectAddress1(value);
                        }
                    }
                })
        });
    }

    private async searchAddress(text: string) {
        const country = this.isFromAustraliaPhy ? "AUS" : "NZL";

        const addressResult = await this.locationservice.getLocationSuggestions(text, country);
        this.filteredAdressList = addressResult.Results.map(a => a);
    }

    //async SelectAddress1(address1: SearchForSuggestionsResult) {
    async SelectAddress1(selectedAddressEvent: any) {
        if(selectedAddressEvent.option){
        var address1 = <SearchForSuggestionsResult>selectedAddressEvent.option.value;
        this.addressSelected = true;
        this.suburbSelected = false;

        this.suburbphys = this.suburbList

        const country = this.isFromAustraliaPhy ? "AUS" : "NZL";
        const countryId = this.isFromAustraliaPhy ? this.countriesId[0].CountryId : this.countriesId[1].CountryId;
        const addressResult = await this.locationservice.getLocation(address1.Text, country);

        var resultAddress = addressResult.Results[0];

        if (this.isFromAustraliaPhy) {
            this.selectedAddress1 = new AddressLocation();
            this.selectedAddress1.Address1 = resultAddress.Place.Label;
            this.selectedAddress1.Suburb = new Suburb();
            this.selectedAddress1.Suburb.Name = resultAddress.Place.Neighborhood;
            this.selectedAddress1.Suburb.Postcode = new Postcode();
            this.selectedAddress1.Suburb.Postcode.Code = resultAddress.Place.PostalCode;
            this.selectedAddress1.Suburb.Postcode.State = new State();
            this.selectedAddress1.Suburb.Postcode.State.Name = resultAddress.Place.Region;
        } else {
            this.selectedAddress1 = new AddressLocation();
            this.selectedAddress1.Address1 = resultAddress.Place.Label;
            this.selectedAddress1.City = resultAddress.Place.Municipality;
            this.selectedAddress1.Suburb = new Suburb();
            this.selectedAddress1.Suburb.Postcode = new Postcode();
            this.selectedAddress1.Suburb.Postcode.Code = resultAddress.Place.PostalCode;
            this.selectedAddress1.Suburb.Postcode.State = new State();
            this.selectedAddress1.Suburb.Postcode.State.Name = resultAddress.Place.Region;
        }

        if(this.selectedAddress1 && this.selectedAddress1.Suburb && this.selectedAddress1.Suburb.Postcode && this.selectedAddress1.Suburb.Postcode.Code){
            if(this.selectedAddress1.Suburb.Postcode.Code.startsWith("0")){
                this.selectedAddress1.Suburb.Postcode.Code = resultAddress.Place.PostalCode.substring(1);
            }else{
                this.selectedAddress1.Suburb.Postcode.Code = resultAddress.Place.PostalCode;
            }
        }

        this.filteredAdressList = [];

        this.CityCtrl.setValue(null, { emitEvent: false });
        this.SuburbPhyCtrl.setValue(null, { emitEvent: false });
        this.PostcodeCtrl.setValue(null, { emitEvent: false });
        if (this.isFromAustraliaPhy) {
            this.StateCtrl.setValue(null, { emitEvent: false });
        }
        this.RegionCtrl.setValue(null, { emitEvent: false });

        var postcode;
        var selectedAddrSuburb = null;
        var state;
        var selectedAddrCity: City; 
        this.postcodesphy = [];
        if (this.selectedAddress1) {
            if (this.isFromAustraliaPhy) {

                if (this.selectedAddress1.Suburb && this.selectedAddress1.Suburb.Name && this.selectedAddress1.Suburb.Postcode && this.selectedAddress1.Suburb.Postcode.Code) {

                    selectedAddrSuburb = this.suburbphys.filter(sub => sub.Name.toLowerCase().trim() === this.selectedAddress1.Suburb.Name.toLowerCase().trim() && sub.Country.Id == countryId && sub.Postcode.Name == this.selectedAddress1.Suburb.Postcode.Code)[0];                   

                    if (selectedAddrSuburb) {
                        const phyobj = {
                            Id: selectedAddrSuburb.Id,
                            Name: selectedAddrSuburb.Name,
                        };
                        this.SuburbPhyCtrl.setValue(phyobj, { emitEvent: false });


                        if (selectedAddrSuburb.Region === null) {
                            this.EmployerDtlFormGroup.get('txtRegionPhy').setValue('There is no region for the postcode '
                                + selectedAddrSuburb.Postcode.Name);
                        } else {
                            this.EmployerDtlFormGroup.get('txtRegionPhy').setValue(selectedAddrSuburb.Region.Name);
                        }

                        state = this.statesPhy.find(s => s.Name === selectedAddrSuburb.State.Name);
                        if (state) {
                            this.StateCtrl.setValue(state.Id, { emitEvent: false });
                            var selectedCountry = this.countries.find(c => c.Id === selectedAddrSuburb.Country.Id);
                            this.CountryCtrl.setValue(selectedCountry.Id, { emitEvent: false });
                        }
                    }

                }

                if (this.selectedAddress1.Suburb && this.selectedAddress1.Suburb.Postcode && this.selectedAddress1.Suburb.Postcode.Code) {
                    postcode = {
                        Name: this.selectedAddress1.Suburb.Postcode.Code,
                        Id: Number(this.selectedAddress1.Suburb.Postcode.Code),
                    };
                    this.postcodesphy = [postcode];
                    this.EmployerDtlFormGroup.get('ddlPostCodePhy').setValue((Number(this.selectedAddress1.Suburb.Postcode.Code)), { emitEvent: false });
                }

                if(selectedAddrSuburb && postcode && state){
                    var ausAddrInfoArr = this.selectedAddress1.Address1.split(',');
                    ausAddrInfoArr = ausAddrInfoArr.map(s => s.trim());
                    var suburbIndex = ausAddrInfoArr.indexOf(this.selectedAddress1.Suburb.Name);
                    const removeFromArray = ausAddrInfoArr.slice(0, suburbIndex);
                    const joinFromArray = removeFromArray.join(', ');                   
                    var address1 = <SearchForSuggestionsResult>{
                                Text: joinFromArray
                    };
                    this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });                    
                  }else{
                    this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });
                  }

            }

            if (!this.isFromAustraliaPhy) {

                var addrInfoArr = this.selectedAddress1.Address1.split(',');
                addrInfoArr = addrInfoArr.map(s => s.trim());
                var suburbName = '';
                var cityIndex;

                if (this.selectedAddress1.City) {

                    var sameCityNameCount = addrInfoArr.filter(item => item == this.selectedAddress1.City.valueOf().trim()).length;
                   // var cityIndex;

                    if (sameCityNameCount > 1) {
                        cityIndex = addrInfoArr.indexOf(this.selectedAddress1.City.valueOf().trim(), addrInfoArr.indexOf(this.selectedAddress1.City.valueOf().trim()) + 1);
                    }
                    else {
                        cityIndex = addrInfoArr.findIndex(i => i.valueOf().toLowerCase().trim() == this.selectedAddress1.City.valueOf().toLowerCase().trim());
                    }
                    suburbName  = '';
                    suburbName = addrInfoArr[cityIndex - 1];                    
                        suburbName = addrInfoArr[cityIndex - 1];
                    suburbName = addrInfoArr[cityIndex - 1];                    

                    // if(addrInfoArr.length == 6){
                    //     suburbName = addrInfoArr[cityIndex - 1];
                    // }

                   // let selectedAddrSuburb = null;

                    if(suburbName != '' && suburbName != undefined){
                        selectedAddrSuburb = this.suburbphys.filter(sub => sub.Name.toLowerCase().trim() === suburbName.toLowerCase().trim() && sub.Country.Id == countryId)[0]

                        if(!selectedAddrSuburb){
                            selectedAddrSuburb = this.suburbphys.filter(sub => sub.Name.toLowerCase().trim() === this.selectedAddress1.City.toLowerCase().trim() 
                                && sub.Postcode.Name == this.selectedAddress1.Suburb.Postcode.Code && sub.Country.Id == countryId)[0]
                        }
                    }
                    else{
                        selectedAddrSuburb = this.suburbphys.filter(sub => sub.Name.toLowerCase().trim() === this.selectedAddress1.City.toLowerCase().trim() 
                        && sub.Postcode.Name == this.selectedAddress1.Suburb.Postcode.Code && sub.Country.Id == countryId)[0]
                    }
                    

                    if (selectedAddrSuburb) {
                        const phyobj = {
                            Id: selectedAddrSuburb.Id,
                            Name: selectedAddrSuburb.Name,
                        };
                        this.SuburbPhyCtrl.setValue(phyobj, { emitEvent: false });

                        if (selectedAddrSuburb.Region === null) {
                            this.EmployerDtlFormGroup.get('txtRegionPhy').setValue('There is no region for the postcode '
                                + selectedAddrSuburb.Postcode.Name);
                        } else {
                            this.EmployerDtlFormGroup.get('txtRegionPhy').setValue(selectedAddrSuburb.Region.Name);
                        }

                        state = this.statesPhy.find(s => s.Name === selectedAddrSuburb.State.Name);

                        if (state) {
                            this.StateCtrl.setValue(state.Id, { emitEvent: false });
                            var selectedCountry = this.countries.find(c => c.Id === selectedAddrSuburb.Country.Id);
                            this.CountryCtrl.setValue(selectedCountry.Id, { emitEvent: false });
                        }

                    }

                    selectedAddrCity = this.cityList.filter(x => x.Name.toLowerCase().trim().includes
                        (this.selectedAddress1.City.toLowerCase().trim()))[0];

                    if(selectedAddrCity){
                        const cityphyobj = {
                            Id: selectedAddrCity.CityId,
                            Name: selectedAddrCity.Name,
                        };
                        this.CityCtrl.setValue(cityphyobj, { emitEvent: false });
                    }             
                }

                if (this.selectedAddress1.Suburb && this.selectedAddress1.Suburb.Postcode && this.selectedAddress1.Suburb.Postcode.Code) {
                    postcode = {
                        Name: this.selectedAddress1.Suburb.Postcode.Code,
                        Id: Number(this.selectedAddress1.Suburb.Postcode.Code)
                    };

                    this.postcodesphy = [postcode];
                    const post = this.postcodesphy.find(s => s.Name === postcode.Name);
                    if (post) {
                        this.PostcodeCtrl.setValue(post.Id, { emitEvent: false });
                    }
                }

                if (selectedAddrCity && selectedAddrSuburb && postcode && state) {         
                    if (suburbName != '' && suburbName != undefined) {
                        if (this.suburbphys.filter(sub => sub.Name.toLowerCase().trim() === suburbName.toLowerCase().trim() && sub.Country.Id == countryId).length > 0) {                                          
                            const removeFromArray = addrInfoArr.slice(0, (cityIndex-1));
                            const joinFromArray = removeFromArray.join(', ');
                            var address1 = <SearchForSuggestionsResult>{
                                        Text: joinFromArray
                            };
                            this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });                                      
                        } else {                   
                            const removeFromArray = addrInfoArr.slice(0, cityIndex);
                            const joinFromArray = removeFromArray.join(', ');
                            var address1 = <SearchForSuggestionsResult>{
                                        Text: joinFromArray
                            };
                            this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });            
                        }
                    } else {                
                        const removeFromArray = addrInfoArr.slice(0, cityIndex);
                        const joinFromArray = removeFromArray.join(', ');
                        var address1 = <SearchForSuggestionsResult>{
                                    Text: joinFromArray
                        };
                        this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });                
                    }                                      
                } else {               
                    this.Addressline1Ctrl.setValue({ ...address1 }, { emitEvent: false });                    
                }

            }

        }

        this.HandleDisableSameAsAdd();
    }
    }

    /* Mail Address */


    /** @internal */
    AutoCompleteAddressMail_DisplayWithFn(option: any) {

        if (!option) {
            return '';
        }
        if (option.Text) {
            return option.Text;
        } else {
            return option
        }
    }


    searchAutoAddressMail() {
        this.LoadCompleteMail()
    }

    LoadCompleteMail() {
        setTimeout(() => {
            this.Addressline1MailCtrl.valueChanges
                .pipe(
                    debounceTime(500),
                    switchMap(value => {
                        if (typeof value === 'string') {
                            this.selectedAddress1Mail = null;
                            this.filteredAdressMailList = [];

                            if (value.length > 2) {
                                return observableOf(value);
                            } else if (value.length === 0) {
                                return observableOf(null);
                            }
                        } else if (typeof value === 'object') {
                            return observableOf(value);
                        }
                        return empty();
                    })
                )
                .subscribe((value: string | SearchForSuggestionsResult) => {
                    if (value) {
                        if (typeof value === 'string') {
                            this.searchAddressMail(value);
                        } else if (typeof value === 'object') {
                            this.SelectAddress1Mail(value);
                        }
                    }
                })
        });
    }

    private async searchAddressMail(text: string) {
        const country = this.isFromAustraliaMA ? "AUS" : "NZL";

        const addressResult = await this.locationservice.getLocationSuggestions(text, country);
        this.filteredAdressMailList = addressResult.Results.map(a => a);
    }
    
   // async SelectAddress1Mail(address1: SearchForSuggestionsResult) {
    async SelectAddress1Mail(selectedAddressEvent: any) {
        if(selectedAddressEvent.option){
        var address1 = <SearchForSuggestionsResult>selectedAddressEvent.option.value;
        this.suburbmails = this.suburbList

        const country = this.isFromAustraliaMA ? "AUS" : "NZL";
        const countryId = this.isFromAustraliaMA ? this.countriesId[0].CountryId : this.countriesId[1].CountryId;
        const addressResult = await this.locationservice.getLocation(address1.Text, country);

        var resultAddress = addressResult.Results[0];

        if (this.isFromAustraliaMA) {
            this.selectedAddress1Mail = new AddressLocation();
            this.selectedAddress1Mail.Address1 = resultAddress.Place.Label;
            this.selectedAddress1Mail.Suburb = new Suburb();
            this.selectedAddress1Mail.Suburb.Name = resultAddress.Place.Neighborhood
            this.selectedAddress1Mail.Suburb.Postcode = new Postcode();
            this.selectedAddress1Mail.Suburb.Postcode.Code = resultAddress.Place.PostalCode;
            this.selectedAddress1Mail.Suburb.Postcode.State = new State();
            this.selectedAddress1Mail.Suburb.Postcode.State.Name = resultAddress.Place.Region;
        } else {
            this.selectedAddress1Mail = new AddressLocation();
            this.selectedAddress1Mail.Address1 = resultAddress.Place.Label;
            this.selectedAddress1Mail.City = resultAddress.Place.Municipality;
            this.selectedAddress1Mail.Suburb = new Suburb();
            this.selectedAddress1Mail.Suburb.Postcode = new Postcode();
            this.selectedAddress1Mail.Suburb.Postcode.Code = resultAddress.Place.PostalCode;
            this.selectedAddress1Mail.Suburb.Postcode.State = new State();
            this.selectedAddress1Mail.Suburb.Postcode.State.Name = resultAddress.Place.Region;
        }

        if(this.selectedAddress1Mail && this.selectedAddress1Mail.Suburb && this.selectedAddress1Mail.Suburb.Postcode && this.selectedAddress1Mail.Suburb.Postcode.Code){
            if(this.selectedAddress1Mail.Suburb.Postcode.Code.startsWith("0")){
                this.selectedAddress1Mail.Suburb.Postcode.Code = resultAddress.Place.PostalCode.substring(1);
            }else{
                this.selectedAddress1Mail.Suburb.Postcode.Code = resultAddress.Place.PostalCode;
            }
        }

        this.filteredAdressMailList = [];

        this.CityMailCtrl.setValue(null, { emitEvent: false });
        this.SuburbMailCtrl.setValue(null, { emitEvent: false });
        this.PostcodeMailCtrl.setValue(null, { emitEvent: false });
        if (this.isFromAustraliaMA) {
            this.StateMailCtrl.setValue(null, { emitEvent: false });
        }

        var postcode;
        var selectedAddrSuburb = null;
        var state;
        var selectedAddrCity: City; 
        if (this.selectedAddress1Mail) {
            if (this.isFromAustraliaMA) {
                if (this.selectedAddress1Mail.Suburb && this.selectedAddress1Mail.Suburb.Name && this.selectedAddress1Mail.Suburb.Postcode && this.selectedAddress1Mail.Suburb.Postcode.Code ) {
                    selectedAddrSuburb = this.suburbmails.filter(sub => sub.Name.toLowerCase().trim() === this.selectedAddress1Mail.Suburb.Name.toLowerCase().trim() && sub.Country.Id == countryId && sub.Postcode.Name == this.selectedAddress1Mail.Suburb.Postcode.Code)[0];

                    if (selectedAddrSuburb) {
                        const phyobj = {
                            Id: selectedAddrSuburb.Id,
                            Name: selectedAddrSuburb.Name,
                        };
                        this.SuburbMailCtrl.setValue(phyobj, { emitEvent: false });

                        state = this.statesMA.find(s => s.Name === selectedAddrSuburb.State.Name);
                        if (state) {
                            this.StateMailCtrl.setValue(state.Id, { emitEvent: false });
                            var selectedCountry = this.countries.find(c => c.Id === selectedAddrSuburb.Country.Id);
                            this.CountryMailCtrl.setValue(selectedCountry.Id, { emitEvent: false });
                        }
                    }

                }
                if (this.selectedAddress1Mail.Suburb && this.selectedAddress1Mail.Suburb.Postcode && this.selectedAddress1Mail.Suburb.Postcode.Code) {
                    postcode = {
                        Name: this.selectedAddress1Mail.Suburb.Postcode.Code,
                        Id: Number(this.selectedAddress1Mail.Suburb.Postcode.Code)
                    };

                    this.postcodesmail = [postcode];

                    this.PostcodeMailCtrl.setValue(postcode.Id, { emitEvent: false });
                }

                if(selectedAddrSuburb && postcode && state){
                    var ausAddrInfoArr = this.selectedAddress1Mail.Address1.split(',');
                    ausAddrInfoArr = ausAddrInfoArr.map(s => s.trim());
                    var suburbIndex = ausAddrInfoArr.indexOf(this.selectedAddress1Mail.Suburb.Name);
                    const removeFromArray = ausAddrInfoArr.slice(0, suburbIndex);
                    const joinFromArray = removeFromArray.join(', ');                   
                    var address1 = <SearchForSuggestionsResult>{
                                Text: joinFromArray
                    };
                    this.Addressline1MailCtrl.setValue({ ...address1 }, { emitEvent: false });                    
                  }else{
                    this.Addressline1MailCtrl.setValue({ ...address1 }, { emitEvent: false });
                  }
            }

            if (!this.isFromAustraliaMA) {
                var addrInfoArr = this.selectedAddress1Mail.Address1.split(',');
                addrInfoArr = addrInfoArr.map(s => s.trim());
                var suburbName = '';
                var cityIndex;

                if (this.selectedAddress1Mail.City) {
                    var sameCityNameCount = addrInfoArr.filter(item => item == this.selectedAddress1Mail.City.valueOf().trim()).length;
                    //var cityIndex;

                    if (sameCityNameCount > 1) {
                        cityIndex = addrInfoArr.indexOf(this.selectedAddress1Mail.City.valueOf().trim(), addrInfoArr.indexOf(this.selectedAddress1Mail.City.valueOf().trim()) + 1);
                    }
                    else {
                        cityIndex = addrInfoArr.findIndex(i => i.valueOf().toLowerCase().trim() == this.selectedAddress1Mail.City.valueOf().toLowerCase().trim());
                    }
                    suburbName  = '';
                    suburbName = addrInfoArr[cityIndex - 1];

                    // if(addrInfoArr.length == 6){
                    //     suburbName = addrInfoArr[cityIndex - 1];
                    // }

                    //let selectedAddrSuburb = null;

                    if(suburbName != '' && suburbName != undefined){
                        selectedAddrSuburb = this.suburbmails.filter(sub => sub.Name.toLowerCase().trim() === suburbName.toLowerCase().trim() && sub.Country.Id == countryId)[0];

                        if(!selectedAddrSuburb){
                            selectedAddrSuburb = this.suburbmails.filter(sub => sub.Name.toLowerCase().trim() === this.selectedAddress1Mail.City.toLowerCase().trim() 
                            && sub.Postcode.Name == this.selectedAddress1Mail.Suburb.Postcode.Code && sub.Country.Id == countryId)[0];
                        }
                    }
                    else{
                        selectedAddrSuburb = this.suburbmails.filter(sub => sub.Name.toLowerCase().trim() === this.selectedAddress1Mail.City.toLowerCase().trim() 
                        && sub.Postcode.Name == this.selectedAddress1Mail.Suburb.Postcode.Code && sub.Country.Id == countryId)[0];
                    }
                    if (selectedAddrSuburb) {
                        const phyobj = {
                            Id: selectedAddrSuburb.Id,
                            Name: selectedAddrSuburb.Name,
                        };
                        this.SuburbMailCtrl.setValue(phyobj, { emitEvent: false });

                        state = this.statesMA.find(s => s.Name === selectedAddrSuburb.State.Name);

                        if (state) {
                            this.StateMailCtrl.setValue(state.Id, { emitEvent: false });
                            var selectedCountry = this.countries.find(c => c.Id === selectedAddrSuburb.Country.Id);
                            this.CountryMailCtrl.setValue(selectedCountry.Id, { emitEvent: false });
                        }
                    }

                    selectedAddrCity = this.cityList.filter(x => x.Name.toLowerCase().trim().includes
                        (this.selectedAddress1Mail.City.toLowerCase().trim()))[0];

                    if(selectedAddrCity){
                        const cityphyobj = {
                            Id: selectedAddrCity.CityId,
                            Name: selectedAddrCity.Name,
                        };
                        this.CityMailCtrl.setValue(cityphyobj, { emitEvent: false });
                    }                

                }

                if (this.selectedAddress1Mail.Suburb && this.selectedAddress1Mail.Suburb.Postcode && this.selectedAddress1Mail.Suburb.Postcode.Code) {

                    postcode = {
                        Name: this.selectedAddress1Mail.Suburb.Postcode.Code,
                        Id: Number(this.selectedAddress1Mail.Suburb.Postcode.Code)
                    };

                    this.postcodesmail = [postcode];
                    const post = this.postcodesmail.find(s => s.Name === postcode.Name);
                    if (post) {
                        this.PostcodeMailCtrl.setValue(post.Id, { emitEvent: false });
                    }
                }

                if (selectedAddrCity && selectedAddrSuburb && postcode && state) {         
                    if (suburbName != '' && suburbName != undefined) {
                        if (this.suburbmails.filter(sub => sub.Name.toLowerCase().trim() === suburbName.toLowerCase().trim() && sub.Country.Id == countryId).length > 0) {                                          
                            const removeFromArray = addrInfoArr.slice(0, (cityIndex-1));
                            const joinFromArray = removeFromArray.join(', ');
                            var address1 = <SearchForSuggestionsResult>{
                                        Text: joinFromArray
                            };
                            this.Addressline1MailCtrl.setValue({ ...address1 }, { emitEvent: false });                                      
                        } else {                   
                            const removeFromArray = addrInfoArr.slice(0, cityIndex);
                            const joinFromArray = removeFromArray.join(', ');
                            var address1 = <SearchForSuggestionsResult>{
                                        Text: joinFromArray
                            };
                            this.Addressline1MailCtrl.setValue({ ...address1 }, { emitEvent: false });            
                        }
                    } else {                
                        const removeFromArray = addrInfoArr.slice(0, cityIndex);
                        const joinFromArray = removeFromArray.join(', ');
                        var address1 = <SearchForSuggestionsResult>{
                                    Text: joinFromArray
                        };
                        this.Addressline1MailCtrl.setValue({ ...address1 }, { emitEvent: false });                
                    }                                      
                } else {               
                    this.Addressline1MailCtrl.setValue({ ...address1 }, { emitEvent: false });                    
                }
            }

        }
    }
}
}