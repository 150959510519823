import { Component, OnInit, Injector } from '@angular/core';
import { CommonString } from 'src/app/constant/common-string';
import { RouterConstant } from 'src/app/constant/routerconstant';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { catchError, finalize } from 'rxjs/operators';
import { DataResult, SearchPaginationRequest, SearchPaginationResult, Package, SearchPaginationParam } from 'src/app/models';
import { PackageService } from 'src/app/services/package.service';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RolePermissionService } from '@fp/services/role-permission.service';
export interface PeriodicElement {
    name: string;
    state: string;
    region: string;
    action: string;
}

@Component({
    selector: 'app-viewpackages',
    templateUrl: './viewpackages.component.html',
    styleUrls: ['./viewpackages.component.css']
})
export class ViewpackagesComponent implements OnInit {

    displayedColumns: string[] = ['name', 'state', 'region', 'action'];

    length: number;
    pageSize: number;
    pageSizeOptions: number[] = [10, 20, 50];
    paging: SearchPaginationRequest = new SearchPaginationRequest();
    requestQueueCount: number = 0;
    filter: string;
    value: string;
    packageName: string;
    dataSource: MatTableDataSource<any>;
    params: SearchPaginationParam[] = [];
    isReadonly: boolean;

    // #region Constant references
    CommonString = CommonString;
    RouterConstant = RouterConstant;
    // #endregion Constant references

    constructor(
        private injector: Injector,
        public router: Router,
        protected commonService: CommonService,
        private route: ActivatedRoute,
        private packageSvc: PackageService
    ) { }

    ngOnInit() {
        this.addParamsSearch();
        this.LoadPackages();
        this.checkPrivilegesForReadOnly();
    }

    //Author: Yen
    addParamsSearch() {
        this.filter = this.route.snapshot.queryParamMap.get('filter');
        this.value = this.route.snapshot.queryParamMap.get('value');
        this.packageName = this.route.snapshot.queryParamMap.get('packageName');
        if (this.packageName && this.filter == 'all') {
            this.paging.Params = [{ Name: 'name', Value: this.packageName }];
        } else if (!this.packageName) {
            this.paging.Params = [{ Name: this.filter, Value: this.value }];
        } else {
            this.paging.Params = [{ Name: this.filter, Value: this.value }, { Name: 'name', Value: this.packageName }];
        }
        this.paging.ViewColumns = [];
    }

    Invoke(source: Observable<any>, handleResultCallback: Function) {
        this.requestQueueCount++;
        this.commonService.StartProgressBar();
        source.pipe(
            catchError((e) => { throw e; }),
            finalize(() => {
                this.requestQueueCount--;
                if (this.requestQueueCount <= 0) {
                    this.commonService.StopProgressBar();
                    this.requestQueueCount = 0;
                }
            }))
            .subscribe(
                res => { handleResultCallback(res); },
                err => { console.log(err); }
            );
    }

    //Author: Yen
    LoadPackages() {
        this.Invoke(
            this.packageSvc.search(this.paging),
            (result: DataResult<SearchPaginationResult<Package>>) => {
                if (result.Success) {
                    this.dataSource = new MatTableDataSource<Package>(result.Data.Results);
                    this.length = result.Data.TotalItem;
                } else {
                    this.dataSource = new MatTableDataSource<Package>();
                }
            }
        );
    }

    paginatorChange(event: PageEvent) {
        this.paging.PageNumber = event.pageIndex + 1;
        this.paging.PageSize = event.pageSize
        this.LoadPackages();
    }

    sortChange(sort: Sort) {
        const active: string = sort.active.toLowerCase();
        const isOderByAsc: boolean = sort.direction == 'asc' ? false : true;
        this.paging.OrderBy = active;
        this.paging.OrderByAsc = isOderByAsc;
        this.LoadPackages();
    }

    /**
   * Sets the view and all fields to read only if any read only attribute on user role is true
   */
    private checkPrivilegesForReadOnly(): void {
        const rolePermissionService: RolePermissionService = this.injector.get(RolePermissionService);

        this.isReadonly = rolePermissionService.checkPrivilegesForReadOnly('View/edit packages (Restrict to Read Only)');
    }
}
