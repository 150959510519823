<!-- Validation Error Messages template -->
<ng-template #tmplValidationErrors let-ctrl="control" let-ctrlName="fieldName"
    let-validateOnTouch="validateOnTouch || false">
    <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
        <small *ngIf="ctrl.hasError('required')" class="invalid-feedback d-block">
            {{StringHelper.format(CommonString.FIELD_REQUIRED, ctrlName)}}</small>
        <small *ngIf="ctrl.hasError('min') || ctrl.hasError('ngbDate')" class="invalid-feedback d-block">
            {{StringHelper.format(CommonString.FIELD_INVALID, ctrlName)}}</small>
    </div>
</ng-template>

<div [formGroup]="form">
    <div class="form-group row">
        <label for="ddlServiceType" class="col-md-3 col-lg-2 col-form-label">Service Type<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select id="ddlServiceType" class="form-control" (change)="onServiceTypeSelect($event)"
                    [class.is-invalid]="!isControlValid('ServiceTypeId', true)" formControlName="ServiceTypeId">
                    <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let type of types" [ngValue]="type.Id">{{type.Name}}</option>
                </select>
                <ng-container
                    *ngTemplateOutlet="tmplValidationErrors;context:{control: getControl('ServiceTypeId'), fieldName: 'Service Type', validateOnTouch: true}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtServName" class="col-md-3 col-lg-2 col-form-label">Service Name<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input class="form-control" [class.is-invalid]="!isControlValid('Name')" type="text" id="txtServName"
                    maxlength="100" formControlName="Name">
                <ng-container
                    *ngTemplateOutlet="tmplValidationErrors;context:{control: getControl('Name'), fieldName: 'Service Name'}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlStatus" class="col-md-3 col-lg-2 col-form-label">Status<span class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select id="ddlStatus" class="form-control"
                    [class.is-invalid]="!isControlValid('ServiceStatusId', true)" formControlName="ServiceStatusId">
                    <option *ngFor="let status of statuses" [ngValue]="status.Id">{{status.Name}}</option>
                </select>
                <ng-container
                    *ngTemplateOutlet="tmplValidationErrors;context:{control: getControl('ServiceStatusId'), fieldName: 'Status', validateOnTouch: true}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtMaxCapacity" class="col-md-3 col-lg-2 col-form-label">Max Capacity</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input class="form-control" type="text" id="txtMaxCapacity" maxlength="9" min="0"
                    formControlName="MaxCapacity" numberOnly>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtCurrentCapacity" class="col-md-3 col-lg-2 col-form-label">Current Capacity</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input class="form-control" type="text" id="txtCurrentCapacity" maxlength="9" min="0"
                    formControlName="CurrentCapacity" numberOnly>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtFacilitySqm" class="col-md-3 col-lg-2 col-form-label">Facility Sqm.</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input class="form-control" type="text" id="txtFacilitySqm" maxlength="9" min="0"
                    formControlName="FacilitySqm" numberOnly>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlPayCalcMethod" class="col-md-3 col-lg-2 col-form-label">Payment Calculation Method<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select id="ddlPayCalcMethod" class="form-control"
                    [class.is-invalid]="!isControlValid('PaymentCalculationMethodId', true)"
                    formControlName="PaymentCalculationMethodId">
                    <option *ngFor="let method of paymentCalculationMethods" [ngValue]="method.Id">{{method.Name}}
                    </option>
                </select>
                <ng-container
                    *ngTemplateOutlet="tmplValidationErrors;context:{control: getControl('PaymentCalculationMethodId'), fieldName: 'Payment Calculation Method', validateOnTouch: true}">
                </ng-container>
            </div>
        </div>
    </div>

    <div class="row" [hidden]="getControl('PaymentCalculationMethodId').value != 1">
        <div class="col-md-8 col-lg-5 offset-md-3 offset-lg-2">
            <div class="form-group row">
                <label for="txtNumMems" class="col-md-5 col-lg-4 col-form-label">Number of Memberships<span
                        class="text-danger">*</span></label>
                <div class="col-md-7 col-lg-8">
                    <div class="d-flex flex-column w-100">
                        <input class="form-control" [class.is-invalid]="!isControlValid('HwmNumberMemberships')"
                            type="text" id="txtNumMems" formControlName="HwmNumberMemberships"
                            (input)="txtNumMems_Change($event)" maxlength="4" numberOnly>
                        <ng-container
                            *ngTemplateOutlet="tmplValidationErrors;context:{control: getControl('HwmNumberMemberships'), fieldName: 'Number of Memberships'}">
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="txtPriceMem" class="col-md-5 col-lg-4 col-form-label">Price per Membership<span
                        class="text-danger">*</span></label>
                <div class="col-md-7 col-lg-8">
                    <div class="d-flex flex-column w-100">
                        <div class="input-group" [class.is-invalid]="!isControlValid('HwmPricePerMembership')"
                            [ngClass]="getClassFocused(1)">
                            <span class="input-group-prepend">$</span>
                            <input class="form-control pl-0" type="text" id="txtPriceMem" maxlength="10" (input)="txtNumVMem_Change2($event)"
                                placeholder="0.00" formControlName="HwmPricePerMembership" appCurrencyMask
                                (blur)="onBlurParent(0)" (focus)="onFocusParent(1)">
                        </div>
                        <ng-container
                            *ngTemplateOutlet="tmplValidationErrors;context:{control: getControl('HwmPricePerMembership'), fieldName: 'Price per Membership'}">
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label for="txtNumVMem" class="col-md-5 col-lg-4 col-form-label">Number of Visits per Membership<span
                        class="text-danger">*</span></label>
                <div class="col-md-7 col-lg-8">
                    <div class="d-flex flex-column w-100">
                        <input class="form-control" [class.is-invalid]="!isControlValid('HwmVisitsPerMembership')"
                            type="text" id="txtNumVMem" (input)="txtNumVMem_Change($event)"
                            formControlName="HwmVisitsPerMembership" maxlength="4" numberOnly>
                        <ng-container
                            *ngTemplateOutlet="tmplValidationErrors;context:{control: getControl('HwmVisitsPerMembership'), fieldName: 'Number of Visits per Membership'}">
                        </ng-container>
                    </div>
                </div>
            </div>

            <!-- HWM Payment Amount -->
            <div class="form-group row">
                <ng-template #popHWMAmountContent>
                    <div class="d-flex align-items-center">The HWM payment amount is the result of the Number of memberships x
                        price per membership.
                    </div>
                </ng-template>
                <label for="txtHWMPaymentValue" class="col-md-5 col-lg-4 col-form-label">HWM Payment Amount <i
                        [ngbPopover]="popHWMAmountContent" placement="top" triggers="mouseenter:mouseleave"
                        #popover="ngbPopover" class="fas fa-info-circle ml-1"></i></label>
                <div class="col-md-7 col-lg-8">
                    <div class="d-flex flex-column w-100">
                        <input class="form-control" type="text" id="txtHWMPaymentValue" readonly formControlName="HwmPaymentValue">
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <ng-template #popHWMContent>
                    <div class="d-flex align-items-center">The HWM value is the result of the Number of memberships x
                        Number of visits per membership.
                    </div>
                </ng-template>
                <label for="txtHWMValue" class="col-md-5 col-lg-4 col-form-label">HWM Visit Limit <i
                        [ngbPopover]="popHWMContent" placement="top" triggers="mouseenter:mouseleave"
                        #popover="ngbPopover" class="fas fa-info-circle ml-1"></i></label>
                <div class="col-md-7 col-lg-8">
                    <div class="d-flex flex-column w-100">
                        <input class="form-control" type="text" id="txtHWMValue" readonly formControlName="HwmValue">
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <ng-template #popFPFreContent>
                    <div class="d-flex align-items-center">The Facility Payment Frequency determines the number of days
                        against which the HWM value is checked.
                    </div>
                </ng-template>
                <label for="txtFPFre" class="col-md-5 col-lg-4 col-form-label">Facility Payment Frequency <i
                        [ngbPopover]="popFPFreContent" placement="top" triggers="mouseenter:mouseleave"
                        #popover="ngbPopover" class="fas fa-info-circle ml-1"></i></label>
                <div class="col-md-7 col-lg-8">
                    <div class="d-flex flex-column w-100">
                        <input class="form-control" type="text" id="txtFPFre" formControlName="txtFPFre" readonly>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="form-group row" [hidden]="getControl('PaymentCalculationMethodId').value != 2">
        <label for="txtPriceVisit" class="col-md-3 col-lg-2 col-form-label">Price per visit<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="input-group" [class.is-invalid]="!isControlValid('PricePerVisit')"
                    [ngClass]="getClassFocused(2)">
                    <span class="input-group-prepend">$</span>
                    <input class="form-control pl-0" type="text" id="txtPriceVisit" maxlength="14"
                        formControlName="PricePerVisit" placeholder="0.00" appCurrencyMask (blur)="onBlurParent(0)"
                        (focus)="onFocusParent(2)">
                </div>
                <ng-container
                    *ngTemplateOutlet="tmplValidationErrors;context:{control: getControl('PricePerVisit'), fieldName: 'Price per visit'}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row" [hidden]="getControl('PaymentCalculationMethodId').value != 3">
        <label for="txtOtherNotes" class="col-md-3 col-lg-2 col-form-label">Other Notes<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <input class="form-control" [class.is-invalid]="!isControlValid('OtherNotes')" type="text"
                    id="txtOtherNotes" maxlength="500" formControlName="OtherNotes">
                <ng-container
                    *ngTemplateOutlet="tmplValidationErrors;context:{control: getControl('OtherNotes'), fieldName: 'Other Notes'}">
                </ng-container>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="chkOffpeakService" class="col-md-3 col-lg-2 col-form-label">Off-peak Service</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="chkOffpeakService"
                        formControlName="IsOffPeakService">
                    <label class="custom-control-label" for="chkOffpeakService">&nbsp;</label>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row" [hidden]="!form.get('IsOffPeakService').value">
        <label for="txtOffpeakTimeRange" class="col-md-3 col-lg-2 col-form-label">Off-peak Time Range<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="d-flex align-items-center">
                    <div class="mr-1">From</div>
                    <div class="pr-2">
                        <ngb-timepicker [class.is-invalid]="!isControlValid('OffPeakStartTime')" [minuteStep]="15"
                            formControlName="OffPeakStartTime" numberOnly #tpOffPeakStartTime>
                        </ngb-timepicker>
                    </div>
                    <div class="mx-1">to</div>
                    <div class="pl-2">
                        <ngb-timepicker [class.is-invalid]="!isControlValid('OffPeakEndTime')" [minuteStep]="15"
                            formControlName="OffPeakEndTime" numberOnly #tpOffPeakEndTime>
                        </ngb-timepicker>
                    </div>
                </div>
                <div *ngIf="!isControlValid('OffPeakStartTime') || !isControlValid('OffPeakEndTime')">
                    <small
                        *ngIf="getControl('OffPeakStartTime').hasError('required') || getControl('OffPeakEndTime').hasError('required')"
                        class="invalid-feedback d-block">
                        {{StringHelper.format(CommonString.FIELD_REQUIRED, 'Off-peak Time Range')}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlVisitRecdMethod" class="col-md-3 col-lg-2 col-form-label">Visit Recording Method<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select class="form-control" [class.is-invalid]="!isControlValid('VisitRecordingMethodIds', true)"
                    id="ddlVisitRecdMethod" [formControl]="VisitRecordingMethodCtrl">
                    <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                    <option *ngFor="let method of visitRecordingMethods" [ngValue]="method">{{method.Name}}</option>
                </select>
                <ng-container
                    *ngTemplateOutlet="tmplValidationErrors;context:{control: getControl('VisitRecordingMethodIds'), fieldName: 'Visit Recording Method', validateOnTouch: true}">
                </ng-container>
                <div class="mt-2 slctSelectedRoles w-100 border-2 rounded-10 border-default text-default"
                    [hidden]="selectedVisitRecordingMethods.length == 0">
                    <div *ngFor="let method of selectedVisitRecordingMethods"
                        class="d-flex border-default border-bottom py-1 align-content-center">
                        <span class="text-primary d-flex align-items-center"
                            (click)="_removeVisitRecordingMethod(method)">
                            <i class="fas fa-minus-circle fa-2x"></i>
                        </span>
                        <p class="d-flex align-items-center m-0 ml-2">{{method.Name}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row" [hidden]="getControl('VisitRecordingMethodIds').value?.indexOf(2) == -1"
        formArrayName="Scanners">
        <div class="col-md-9 col-lg-10 offset-md-3 offset-lg-2">
            <div class="tabFPTheme w-100 fpScannerTheme">
                <ngb-accordion [destroyOnHide]="false" #accordionScanners>
                    <ngb-panel *ngFor="let itemRow of ScannersListCtrl.controls; let i=index" [formGroupName]="i"
                        id="pnlScanner#{{i+1}}">
                        <ng-template ngbPanelTitle>
                            <i class="icon-fp" [ngClass]="{'fas fa-check': itemRow?.valid}"></i>
                            Scanner # {{i + 1}}
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <!-- <app-services-scanner-details></app-services-scanner-details> -->
                            <div class="position-relative">
                                <ng-container
                                    *ngTemplateOutlet="tmplScannerDetails;context:{form: itemRow, uniqueId: i}">
                                </ng-container>
                                <span *ngIf="ScannersListCtrl.length > 1"
                                    class="pointer-cursor position-absolute pos-right-top ng-star-inserted text-center">
                                    <i class="fas fa-2x fa-minus-circle text-primary"
                                        (click)="_removeScanner(i)"></i><br>Remove</span>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="w-100">
                    <div class="card-body py-0">
                        <div class="form-group row">
                            <div class="col-md-9 col-lg-6">
                                <button class="px-3 py-2 btn btn-primary" type="button" (click)="_addScanner()">
                                    <i class="fas fa-plus-circle mr-2"></i>Add Additional Scanner</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row" [hidden]="getControl('VisitRecordingMethodIds').value?.indexOf(4) == -1"
        formArrayName="LinkApis">
        <div class="col-md-9 col-lg-10 offset-md-3 offset-lg-2">
            <div class="tabFPTheme w-100 fpScannerTheme">
                <ngb-accordion [destroyOnHide]="false" #accordionLinkApi>
                    <ngb-panel *ngFor="let itemRow of LinkApiListCtrl.controls; let i=index" [formGroupName]="i"
                        id="pnlLinkApi#{{i+1}}">
                        <ng-template ngbPanelTitle>
                            <i class="icon-fp" [ngClass]="{'fas fa-check': itemRow?.valid}"></i>
                            Link API # {{i + 1}}
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="position-relative">
                                <ng-container *ngTemplateOutlet="tmplLinkApi;context:{form: itemRow, uniqueId: i}">
                                </ng-container>
                                <span *ngIf="LinkApiListCtrl.length > 1"
                                    class="pointer-cursor position-absolute pos-right-top ng-star-inserted text-center">
                                    <i class="fas fa-2x fa-minus-circle text-primary"
                                        (click)="_removeLinkApi(i)"></i><br>Remove</span>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="w-100">
                    <div class="card-body py-0">
                        <div class="form-group row">
                            <div class="col-md-9 col-lg-6">
                                <button class="px-3 py-2 btn btn-primary" type="button" (click)="_addLinkApi()">
                                    <i class="fas fa-plus-circle mr-2"></i>Add Additional Link API</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row" [hidden]="getControl('VisitRecordingMethodIds').value?.indexOf(5) == -1"
        formArrayName="PhysicalDevices">
        <div class="col-md-9 col-lg-10 offset-md-3 offset-lg-2">
            <div class="tabFPTheme w-100 fpScannerTheme">
                <ngb-accordion [destroyOnHide]="false" #accordionPhysicalDevice>
                    <ngb-panel *ngFor="let itemRow of PhysicalListCtrl.controls; let i=index" [formGroupName]="i"
                        id="pnlPhysicalDevice#{{i+1}}">
                        <ng-template ngbPanelTitle>
                            <i class="icon-fp" [ngClass]="{'fas fa-check': itemRow?.valid}"></i>
                            Physical Device # {{i + 1}}
                            <i class="fas fas fa-chevron-down"></i>
                            <i class="fas fas fa-chevron-up"></i>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="position-relative">
                                <ng-container *ngTemplateOutlet="tmplPhysicalDevice;context:{form: itemRow, uniqueId: i}">
                                </ng-container>
                                <span *ngIf="PhysicalListCtrl.length > 1"
                                    class="pointer-cursor position-absolute pos-right-top ng-star-inserted text-center">
                                    <i class="fas fa-2x fa-minus-circle text-primary"
                                        (click)="_removePhysicalDevice(i)"></i><br>Remove</span>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="w-100">
                    <div class="card-body py-0">
                        <div class="form-group row">
                            <div class="col-md-9 col-lg-6">
                                <button class="px-3 py-2 btn btn-primary" type="button" (click)="_addPhysicalDevice()">
                                    <i class="fas fa-plus-circle mr-2"></i>Add Additional Physical Device</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="ddlLimitedVisits" class="col-md-3 col-lg-2 col-form-label">Limited Visits<span
                class="text-danger">*</span></label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <select id="ddlLimitedVisits" class="form-control" formControlName="IsLimitVisits">
                    <option [ngValue]="true">Yes</option>
                    <option [ngValue]="false" selected>No</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row" [hidden]="getControl('IsLimitVisits').value != true">
        <div class="col-md-8 col-lg-5 offset-md-3 offset-lg-2">
            <div class="form-group row">
                <label for="txtMaxVM" class="col-md-5 col-lg-4 col-form-label">Max Visits per Month<span
                        class="text-danger">*</span></label>
                <div class="col-md-7 col-lg-8">
                    <div class="d-flex flex-column w-100">
                        <input class="form-control" [class.is-invalid]="!isControlValid('MaxVisitsPerPaymentPeriod')"
                            type="text" id="txtMaxVM" maxlength="9" min="0" formControlName="MaxVisitsPerPaymentPeriod"
                            numberOnly>
                        <ng-container
                            *ngTemplateOutlet="tmplValidationErrors;context:{control: getControl('MaxVisitsPerPaymentPeriod'), fieldName: 'Max Visits per Month'}">
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="txtAddVCharge" class="col-md-5 col-lg-4 col-form-label">Additional Visit Charge<span
                        class="text-danger">*</span></label>
                <div class="col-md-7 col-lg-8">
                    <div class="d-flex flex-column w-100">
                        <div class="input-group" [class.is-invalid]="!isControlValid('AdditionalVisitCharge')"
                            [ngClass]="getClassFocused(3)">
                            <span class="input-group-prepend">$</span>
                            <input class="form-control pl-0" type="text" id="txtAddVCharge" 
                                formControlName="AdditionalVisitCharge" placeholder="0.00" 
                                (blur)="onBlurParent(0)" (focus)="onFocusParent(3)" maxlength="14" appCurrencyMask [allowNegative]="true">
                        </div>
                        <ng-container
                            *ngTemplateOutlet="tmplValidationErrors;context:{control: getControl('AdditionalVisitCharge'), fieldName: 'Additional Visit Charge'}">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group row">
        <label for="txtDeductionAmount" class="col-md-3 col-lg-2 col-form-label">Deduction Amount</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <div class="input-group" [ngClass]="getClassFocused(2)">
                    <span class="input-group-prepend">$</span>
                    <input class="form-control pl-0" type="text" id="txtDeductionAmount" maxlength="10"
                        placeholder="0.00" appCurrencyMask (blur)="onBlurParent(0)" (focus)="onFocusParent(2)"
                        formControlName="DeductionAmount">
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <label for="txtAddInfo" class="col-md-3 col-lg-2 col-form-label">Additional Information</label>
        <div class="col-md-8 col-lg-5">
            <div class="d-flex flex-column w-100">
                <textarea class="form-control" id="txtAddInfo" maxlength="5000"
                    formControlName="AdditionalInformation"></textarea>
            </div>
        </div>
    </div>
</div>

<!-- Physical Details template -->
<ng-template #tmplPhysicalDevice let-formGroup="form" let-uniqueId="uniqueId">
    <div [formGroup]="formGroup">
        <div class="form-group row">
            <label for="txtPhysicalDeviceName_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Device Name<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <input id="txtPhysicalDeviceName_{{uniqueId}}"  type="text"  class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('PhysicalDeviceName'))" autocomplete="off"
                        maxlength="100" formControlName="PhysicalDeviceName">
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('PhysicalDeviceName'), fieldName: 'Device Name'}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="txtSuccessTriggerURL_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Success Trigger URL<span
                    class="text-danger"></span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <input id="txtSuccessTriggerURL_{{uniqueId}}"  type="text"  class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('SuccessTriggerURL'))" autocomplete="off"
                        maxlength="1000" formControlName="SuccessTriggerURL">
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="txtFailureTriggerURL{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Failure Trigger URL<span
                    class="text-danger"></span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <input id="txtFailureTriggerURL_{{uniqueId}}"  type="text"  class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('FailureTriggerURL'))" autocomplete="off"
                        maxlength="1000" formControlName="FailureTriggerURL">
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="ckIsActivepd_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Active Status</label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="ckIsActivepd_{{uniqueId}}" formControlName="IsActive">
                        <label for="ckIsActivepd_{{uniqueId}}" class="custom-control-label">&nbsp;</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Link API Details Template -->
<ng-template #tmplLinkApi let-formGroup="form" let-uniqueId="uniqueId">
    <div [formGroup]="formGroup">

        <div class="form-group row">
            <label for="txtTokenName_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Token Name<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <input id="txtTokenName_{{uniqueId}}"  type="text"  class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('TokenName'))" autocomplete="off"
                        maxlength="100" formControlName="TokenName">
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('TokenName'), fieldName: 'Token Name'}">
                    </ng-container>
                </div>
            </div>
        </div>
        <!-- Will be REMOVED and used for Link UI -->
        <div class="form-group row">
            <label for="txtDeviceToken_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Device Token<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <input id="txtDeviceToken_{{uniqueId}}"  type="text"  class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('DeviceToken'))" autocomplete="off"
                        maxlength="100" formControlName="DeviceToken" readonly
                        style="text-transform: uppercase; font-size: 12px">
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('DeviceToken'), fieldName: 'Device Token', validateOnTouch: true}">
                    </ng-container>
                </div>
                <div class="d-flex flex-column w-35">
                    <button class="px-1 py-2 btn btn-primary" style="margin-left:5px; font-size: 10px" type="button"
                        (click)="confirmRegenerateLinkApi('DeviceToken',uniqueId)">
                        Regenerate</button>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="txtSiteToken_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Site Token<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <input id="txtSiteToken_{{uniqueId}}"  type="text"  class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('SiteToken'))" autocomplete="off"
                        maxlength="100" formControlName="SiteToken" readonly
                        style="text-transform: uppercase; font-size: 12px">
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('SiteToken'), fieldName: 'SiteToken', validateOnTouch: true}">
                    </ng-container>
                </div>
                <div class="d-flex flex-column w-35">
                    <button class="px-1 py-2 btn btn-primary" style="margin-left:5px; font-size: 10px" type="button"
                        (click)="GenerateUUID('SiteToken',uniqueId)" [disabled]="true">
                        Regenerate</button>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="ckIsActivela_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Active Status</label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="ckIsActivela_{{uniqueId}}" formControlName="IsActive">
                        <label for="ckIsActivela_{{uniqueId}}" class="custom-control-label">&nbsp;</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Scanner Details template -->
<ng-template #tmplScannerDetails let-formGroup="form" let-uniqueId="uniqueId">
    <div [formGroup]="formGroup">
        <div class="form-group row">
            <label for="txtScannerInstDate_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Scanner Installation
                Date<span class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <div class="input-group" [class.is-invalid]="!isControlValid(formGroup.get('InstallationDate'))">
                        <input id="txtScannerInstDate_{{uniqueId}}" name="dp" class="form-control"
                            placeholder="DD-MM-YYYY" formControlName="InstallationDate" ngbDatepicker
                            #dtpScannerInstDate="ngbDatepicker" [maxDate]="maxDate">
                        <div class="input-group-append">
                            <button class="btn bg-white" (click)="dtpScannerInstDate.toggle()" type="button"><i
                                    class="far fa-calendar-alt"></i></button>
                        </div>
                    </div>
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('InstallationDate'), fieldName: 'Scanner Installation Date'}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="txtDeviceName_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Device Name<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <input id="txtDeviceName_{{uniqueId}}"  type="text"  class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('DeviceName'))" autocomplete="off"
                        maxlength="100" formControlName="DeviceName">
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('DeviceName'), fieldName: 'Device Name'}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="txtAppleID_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Apple ID<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <input id="txtAppleID_{{uniqueId}}"  type="text"  class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('AppleId'))" autocomplete="off" maxlength="50"
                        formControlName="AppleId">
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('AppleId'), fieldName: 'Apple ID'}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="ddlSIMInstalled_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">SIM Installed<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <select id="ddlSIMInstalled_{{uniqueId}}"  type="text" class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('SIMProviderId'), true)"
                        formControlName="SIMProviderId">
                        <option *ngFor="let provider of simProviders" [ngValue]="provider.Id">{{provider.Name}}</option>
                    </select>
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('SIMProviderId'), fieldName: 'SIM Installed', validateOnTouch: true}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row" [hidden]="formGroup.get('SIMProviderId').value == 4">
            <label for="txtSIMID_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">SIM ID<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <input id="txtSIMID_{{uniqueId}}"  type="text"  class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('SimID'))" autocomplete="off" maxlength="50"
                        formControlName="SimID">
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('SimID'), fieldName: 'SIM ID'}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="ddlInternetService_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Internet Service<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <select id="ddlInternetService_{{uniqueId}}" class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('InternetServiceId'), true)"
                        formControlName="InternetServiceId">
                        <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                        <option *ngFor="let internetService of internetServices" [ngValue]="internetService.Id">
                            {{internetService.Name}}
                        </option>
                    </select>
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('InternetServiceId'), fieldName: 'Internet Service', validateOnTouch: true}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="ddlCaseType_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Case Type<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <select id="ddlCaseType_{{uniqueId}}" class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('CaseTypeId'), true)"
                        formControlName="CaseTypeId">
                        <option *ngFor="let caseType of caseTypes" [ngValue]="caseType.Id">{{caseType.Name}}</option>
                    </select>
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('CaseTypeId'), fieldName: 'Case Type', validateOnTouch: true}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="ddlCaseAttachment_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Case Attachment<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <select id="ddlCaseAttachment_{{uniqueId}}" class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('CaseAttachmentId'), true)"
                        formControlName="CaseAttachmentId">
                        <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                        <option *ngFor="let caseAttachment of caseAttachments" [ngValue]="caseAttachment.Id">
                            {{caseAttachment.Name}}
                        </option>
                    </select>
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('CaseAttachmentId'), fieldName: 'Case Attachment', validateOnTouch: true}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="ddlSecurityCable_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Security Cable<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <select id="ddlSecurityCable_{{uniqueId}}" class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('IsSecurityCable'), true)"
                        formControlName="IsSecurityCable">
                        <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                        <option [ngValue]="true">Yes</option>
                        <option [ngValue]="false">No</option>
                    </select>
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('IsSecurityCable'), fieldName: 'Security Cable', validateOnTouch: true}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="ddlCaseKeyStatus_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Case Key Status<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <select id="ddlCaseKeyStatus_{{uniqueId}}" class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('IsCaseKeyStatusIn'), true)"
                        formControlName="IsCaseKeyStatusIn">
                        <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                        <option [ngValue]="true">In</option>
                        <option [ngValue]="false">Out</option>
                    </select>
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('IsCaseKeyStatusIn'), fieldName: 'Case Key Status', validateOnTouch: true}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="ddlCableKeyStatus_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Cable Key Status<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <select id="ddlCableKeyStatus_{{uniqueId}}" class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('IsCableKeyStatusIn'), true)"
                        formControlName="IsCableKeyStatusIn">
                        <option [ngValue]="null">{{CommonMessage.DROP_DOWN_LIST_DEFAULT_TEXT}}</option>
                        <option [ngValue]="true">In</option>
                        <option [ngValue]="false">Out</option>
                    </select>
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('IsCableKeyStatusIn'), fieldName: 'Cable Key Status', validateOnTouch: true}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="txtDeviceUsername_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Device Username<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <input id="txtDeviceUsername_{{uniqueId}}"  type="text"  class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('DeviceUsername'))" autocomplete="off" maxlength="50"
                        formControlName="DeviceUsername">
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('DeviceUsername'), fieldName: 'Device Username'}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="txtDevicePassword_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Device Password<span
                    class="text-danger">*</span></label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <input id="txtDevicePassword_{{uniqueId}}"  type="text"  class="form-control"
                        [class.is-invalid]="!isControlValid(formGroup.get('DevicePassword'))" autocomplete="off" maxlength="50"
                        formControlName="DevicePassword">
                    <ng-container
                        *ngTemplateOutlet="tmplValidationErrors;context:{control: formGroup.get('DevicePassword'), fieldName: 'Device Password'}">
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="txtAddScannerInfo_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Additional Scanner
                Information</label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <textarea id="txtAddScannerInfo_{{uniqueId}}" class="form-control" maxlength="500"
                        formControlName="AdditionalScannerInformation"></textarea>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="ckIsActivesc_{{uniqueId}}" class="col-md-4 col-lg-3 col-form-label">Active Status</label>
            <div class="col-md-9 col-lg-6">
                <div class="d-flex flex-column w-100">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="ckIsActivesc_{{uniqueId}}" formControlName="IsActive">
                        <label for="ckIsActivesc_{{uniqueId}}" class="custom-control-label">&nbsp;</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>