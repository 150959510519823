import { Component, OnInit, ViewChild } from "@angular/core";
import { MessageBox } from "../../../services/common-dialog.service";
import { MatDialog } from "@angular/material/dialog";
import {
  FileAttachmentModel,
  RawVisit,
  CheckRawVisitCriteria,
  SaveRawVisit,
  ResultModel,
  DataResult,
  AWSStartExecutionResponse,
  body
} from "../../../models";
import { FpFileDataR2 } from "./../../control/fp-file-upload-r2/fp-file-upload-r2.component";
import { PaymentService, CommonService } from "@fp/services";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { FileNameService } from "@fp/services/file-name.service";
import { DialogResult } from "@fp/components/common-dialog/common-dialog.component";
import { FpProgressBarComponent } from "@fp/components/control/fp-progress-bar/fp-progress-bar.component";
import { AwsServiceService } from "@fp/services/aws-service.service";
import { StepFunctions } from "@fp/enums/step-functions.enum";
import { DescribeStateMachineComponent } from "@fp/components/describe-state-machine/describe-state-machine.component";

@Component({
  selector: "app-manually-upload",
  templateUrl: "./manually-upload.component.html",
  styleUrls: ["./manually-upload.component.css"]
})
export class ManuallyUploadComponent implements OnInit {
  documentName = "No File Selected";
  dataDocumentAttachments: FileAttachmentModel[] = [];
  errorHandler: any;
  uploadedFileName: string[];
  originalFileName: string[];
  colParameter = [
    "member_number",
    "member_id",
    "member_name",
    "service_name",
    "unique_facility_code",
    "date_time",
    "visit_count"
  ];
  checkNecessary = true;

  rowsAffected: number;
  RowsAffectedMemberNotExistCount: number;
  RowsAffectedMemberStatusInvalidCount: number;
  RowsAffectedMemberIneligibleCount: number;
  RowsAffectedFacilityServiceInactiveCount: number;
  RowsAffectedFacilityServiceNotExistCount: number;

  includeErrorsCount = 0;

  InvalidRows: number;
  ValidRows: number;
  TotalRows: number;

  rawVisitResult = new RawVisit();
  errorsToInclude = new CheckRawVisitCriteria();

  rawVisitDataToSend = new SaveRawVisit();

  checkFileBtn: boolean;
  saveBtn: boolean;
  hideChkMemberNotExist: boolean;
  hideChkMemberInvalidStatus: boolean;
  hideChkMemberIneligible: boolean;
  hideChkFacilityServiceInactive: boolean;
  hideChkAllErrors: boolean;

  downloadMemberNotExistBtn: boolean;
  downloadMemberInvalidStatusBtn: boolean;
  downloadMemberIneligibleBtn: boolean;
  downloadFacilityServiceInactiveBtn: boolean;
  downloadFacilityServiceNonExistentBtn: boolean;
  downloadAllErrorsBtn: boolean;
  isLoading = false;
  isFutureDate = false;

  /** The Progress Bar Modal in this Page */
  @ViewChild("progressBar") progressBar: FpProgressBarComponent;
  @ViewChild("checkRawDesc") checkRawDescSpinner: DescribeStateMachineComponent;
  @ViewChild("saveRawDesc") saveRawDescSpinner: DescribeStateMachineComponent;

  totalFailedAmount: string;
  totalFailiures: string;
  StepFunctions = StepFunctions;

  constructor(
    protected dialog: MatDialog,
    protected svc: PaymentService,
    protected filesvc: FileNameService,
    private awsService: AwsServiceService,
    private cmsrv: CommonService
  ) { }

  ngOnInit() {
    this.cmsrv.Header.title="Manually Upload Raw Visit Data";
    this.checkFileBtn = true;
    this.saveBtn = true;
    this.hideChkMemberNotExist = true;
    this.hideChkMemberInvalidStatus = true;
    this.hideChkMemberIneligible = true;
    this.hideChkFacilityServiceInactive = true;
    this.hideChkAllErrors = true;

    this.downloadMemberNotExistBtn = true;
    this.downloadMemberInvalidStatusBtn = true;
    this.downloadMemberIneligibleBtn = true;
    this.downloadFacilityServiceInactiveBtn = true;
    this.downloadFacilityServiceNonExistentBtn = true;
    this.downloadAllErrorsBtn = true;

    this.SetErrorsToInclude();
  }

  public SetErrorsToInclude() {
    this.errorsToInclude.MemberNumberNotExist = false;
    this.errorsToInclude.MemberStatusInvalid = false;
    this.errorsToInclude.MemberIneligible = false;
    this.errorsToInclude.FacilityServiceComboInactive = false;
  }

  public AddFile_UploadCompleted(event) {
    this.isLoading = true;
    const ret: FpFileDataR2 = event;
    this.dataDocumentAttachments.push({
      Location: ret.filedata.Location,
      Base64: "",
      FileName: ret.originfilename,
      Status: "Addnew",
      Id: 0
    });

    //Retrieve the name of the file that was uploaded in explorer window
    this.filesvc.$originalFileName.subscribe(
      originalFileName => (this.originalFileName = originalFileName)
    );

    const originalFileNameString = this.originalFileName[this.originalFileName.length - 1];

    this.documentName = originalFileNameString;

    this.checkFileBtn = false;
    this.isLoading = false;
  }

  /**
   *  this function checks the Raw Visits from CSV and populates the number of Errors on the page
   */
  public CheckRawVisitsRowsAffected(event) {
    this.progressBar.Open("Checking File Integrity");
    this.progressBar.StartPercentTimer();

    this.filesvc.$currentUploadedFileUuid.subscribe(
      uploadedFileName => (this.uploadedFileName = uploadedFileName)
    );

    //Retrieve the recently selected CSV to check file integrity
    const fileNameString = this.uploadedFileName[this.uploadedFileName.length - 1];

    if (fileNameString !== null) {
      //Statemachine Step Function trigger
      this.Invoke(
        this.awsService.StartStepExecution(
          StepFunctions.CheckRawVisits,
          null,
          null,
          fileNameString
        ),
        (result: AWSStartExecutionResponse) => {
          this.progressBar.close_subject.next(true);
          console.log("Result: ", result);
          if (
            result.executionArn === undefined ||
            result.executionArn === "" ||
            result.executionArn === null
          ) {
            MessageBox.ShowError(this.dialog, "Something has gone wrong.");
          } else {
            this.checkRawDescSpinner.stepMachineIsRunning = true;
            this.checkRawDescSpinner.executionArn = result.executionArn;
            this.checkRawDescSpinner.StartDescriptionTimer(20);
          }
        }
      );
    }
  }

  handleIntegrityCheckReturn(result: DataResult<RawVisit>) {
    console.log("Result of Integrity Check: ", result);
    console.log("IsFutureDate: ", result.Data.IsFutureDate);
    this.isFutureDate = result.Data.IsFutureDate;
    this.RowsAffectedMemberNotExistCount =
      result.Data.ErrorDataMemberNotExistCount;
    this.RowsAffectedMemberStatusInvalidCount =
      result.Data.ErrorDataMemberStatusInvalidCount;
    this.RowsAffectedMemberIneligibleCount =
      result.Data.ErrorDataMemberIneligibleCount;
    this.RowsAffectedFacilityServiceInactiveCount =
      result.Data.ErrorDataFacilityServiceInactiveCount;
    this.RowsAffectedFacilityServiceNotExistCount =
      result.Data.ErrorDataFacilityServiceNonExistentCount;
    this.InvalidRows = result.Data.TotalErrorRows;
    this.TotalRows = result.Data.TotalRows;
    // Calculate valid rows
    this.ValidRows = this.TotalRows - this.InvalidRows;
    this.rawVisitResult = result.Data;
    this.ShowIncludeErrorCheckboxes();
    this.showDownloadErrorCSVButtons();
    document.getElementById("ErrorTable").style.display = "block";
    this.progressBar.upload_percent_subject.next(100);
    this.progressBar.upload_status_subject.next("Upload Completed!");
    this.progressBar.close_subject.next(true);
    if (
      this.rawVisitResult.ErrorDataFacilityServiceNonExistentCount > 0
    ) {
      MessageBox.ShowError(
        this.dialog,
        "The file contains facilities/services which do not exist. these rows cannot be included. Please download the respective error file, correct the errors and re-upload it."
      );
      this.saveBtn = false;
    } else if (this.ValidRows > 0) {
      MessageBox.ShowInfo(this.dialog, result.Message);
      this.saveBtn = false;
    }
  }


  // Show include checkboxes when the check has an error count more than zero
  public ShowIncludeErrorCheckboxes() {
    if (this.InvalidRows > 0) {
      this.hideChkAllErrors = false;
    }
    if (this.RowsAffectedMemberNotExistCount > 0) {
      this.hideChkMemberNotExist = false;
    }
    if (this.RowsAffectedMemberStatusInvalidCount > 0) {
      this.hideChkMemberInvalidStatus = false;
    }
    if (this.RowsAffectedMemberIneligibleCount > 0) {
      this.hideChkMemberIneligible = false;
    }
    if (this.RowsAffectedFacilityServiceInactiveCount > 0) {
      this.hideChkFacilityServiceInactive = false;
    }
  }

  // this function shows the Download CSV Error links if the errors count is greater than zero
  public showDownloadErrorCSVButtons() {
    if (this.rawVisitResult.ErrorDataMemberNotExistCount > 0) {
      this.downloadMemberNotExistBtn = false;
    }

    if (this.rawVisitResult.ErrorDataMemberStatusInvalidCount > 0) {
      this.downloadMemberInvalidStatusBtn = false;
    }

    if (this.rawVisitResult.ErrorDataMemberIneligibleCount > 0) {
      this.downloadMemberIneligibleBtn = false;
    }

    if (this.rawVisitResult.ErrorDataFacilityServiceInactiveCount > 0) {
      this.downloadFacilityServiceInactiveBtn = false;
    }

    if (this.rawVisitResult.ErrorDataFacilityServiceNonExistentCount > 0) {
      this.downloadFacilityServiceNonExistentBtn = false;
    }

    if (this.InvalidRows > 0) {
      this.downloadAllErrorsBtn = false;
    }
  }

  // this function generates the error CSVs
  public DownloadErrorsCSV(event) {
    this.isLoading = true;

    let abaFileLink = "";
    if (event.target.name.toLowerCase() === "downloadmembernotexist") {
      abaFileLink = this.rawVisitResult.CSVFileData.MemberNotExistURL;
    } else if (
      event.target.name.toLowerCase() === "downloadmemberinvalidstatus"
    ) {
      abaFileLink = this.rawVisitResult.CSVFileData.MemberStatusInvalidURL;
    } else if (event.target.name.toLowerCase() === "downloadmemberineligible") {
      abaFileLink = this.rawVisitResult.CSVFileData.MemberIneligibleURL;
    } else if (
      event.target.name.toLowerCase() === "downloadfacilityserviceinactive"
    ) {
      abaFileLink = this.rawVisitResult.CSVFileData.FacilityServiceInactiveURL;
    } else if (
      event.target.name.toLowerCase() === "downloadfacilityservicenonexistent"
    ) {
      abaFileLink = this.rawVisitResult.CSVFileData
        .FacilityServiceNonExistentURL;
    } else if (event.target.name.toLowerCase() === "downloadallerrors") {
      abaFileLink = this.rawVisitResult.CSVFileData.AllErrorsURL;
    }

    this.isLoading = false;
    window.open(abaFileLink, "_blank");
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError(e => {
          throw e;
        })
      )
      .subscribe(
        res => {
          handleResultCallback(res);
        },
        err => {
          this.errorHandler(err);
        }
      );
  }

  // this function controls the checkbox errors if they are checked/unchecked
  includeErrors(event) {
    this.ValidRows = this.TotalRows - this.InvalidRows;

    if (event.target.name.toLowerCase() === "chkmembernotexist") {
      if (event.target.checked) {
        this.errorsToInclude.MemberNumberNotExist = true;
        this.includeErrorsCount =
          this.includeErrorsCount + this.RowsAffectedMemberNotExistCount;

        if (this.includeErrorsCount > 0 && this.ValidRows === 0) {
          this.saveBtn = false;
        }
      } else {
        this.errorsToInclude.MemberNumberNotExist = false;
        this.includeErrorsCount =
          this.includeErrorsCount - this.RowsAffectedMemberNotExistCount;

        if (this.includeErrorsCount === 0 && this.ValidRows === 0) {
          this.saveBtn = true;
        }
      }
    } else if (event.target.name.toLowerCase() === "chkmemberinvalidstatus") {
      if (event.target.checked) {
        this.errorsToInclude.MemberStatusInvalid = true;
        this.includeErrorsCount =
          this.includeErrorsCount + this.RowsAffectedMemberStatusInvalidCount;

        if (this.includeErrorsCount > 0 && this.ValidRows === 0) {
          this.saveBtn = false;
        }
      } else {
        this.errorsToInclude.MemberStatusInvalid = false;
        this.includeErrorsCount =
          this.includeErrorsCount - this.RowsAffectedMemberStatusInvalidCount;

        if (this.includeErrorsCount === 0 && this.ValidRows === 0) {
          this.saveBtn = true;
        }
      }
    } else if (event.target.name.toLowerCase() === "chkmemberineligible") {
      if (event.target.checked) {
        this.errorsToInclude.MemberIneligible = true;
        this.includeErrorsCount =
          this.includeErrorsCount + this.RowsAffectedMemberIneligibleCount;

        if (this.includeErrorsCount > 0 && this.ValidRows === 0) {
          this.saveBtn = false;
        }
      } else {
        this.errorsToInclude.MemberIneligible = false;
        this.includeErrorsCount =
          this.includeErrorsCount - this.RowsAffectedMemberIneligibleCount;

        if (this.includeErrorsCount === 0 && this.ValidRows === 0) {
          this.saveBtn = true;
        }
      }
    } else if (
      event.target.name.toLowerCase() === "chkfacilityserviceinactive"
    ) {
      if (event.target.checked) {
        this.errorsToInclude.FacilityServiceComboInactive = true;
        this.includeErrorsCount =
          this.includeErrorsCount +
          this.RowsAffectedFacilityServiceInactiveCount;

        if (this.includeErrorsCount > 0 && this.ValidRows === 0) {
          this.saveBtn = false;
        }
      } else {
        this.errorsToInclude.FacilityServiceComboInactive = false;
        this.includeErrorsCount =
          this.includeErrorsCount -
          this.RowsAffectedFacilityServiceInactiveCount;

        if (this.includeErrorsCount === 0 && this.ValidRows === 0) {
          this.saveBtn = true;
        }
      }
    }
  }

  // this function checks/unchecks all the error checkboxes when all errors is checked/unchecked
  includeAllErrors(event) {
    const chkMemberNumberNotExist = <HTMLInputElement>(
      document.getElementById("chkMemberNotExist")
    );
    const chkMemberActive = <HTMLInputElement>(
      document.getElementById("chkMemberInvalidStatus")
    );
    const chkMemberNumberIneligible = <HTMLInputElement>(
      document.getElementById("chkMemberIneligible")
    );
    const chkFacilityServiceInactive = <HTMLInputElement>(
      document.getElementById("chkFacilityServiceInactive")
    );

    if (event.target.checked) {
      // only set the checkbox property to true if it is shown on the page
      if (this.hideChkMemberNotExist === false) {
        chkMemberNumberNotExist.checked = true;
        this.errorsToInclude.MemberNumberNotExist = true;
      }
      if (this.hideChkMemberInvalidStatus === false) {
        chkMemberActive.checked = true;
        this.errorsToInclude.MemberStatusInvalid = true;
      }
      if (this.hideChkMemberIneligible === false) {
        chkMemberNumberIneligible.checked = true;
        this.errorsToInclude.MemberIneligible = true;
      }
      if (this.hideChkFacilityServiceInactive === false) {
        chkFacilityServiceInactive.checked = true;
        this.errorsToInclude.FacilityServiceComboInactive = true;
      }

      if (this.InvalidRows > 0 && this.ValidRows === 0) {
        this.saveBtn = false;
      }
    } else {
      chkMemberNumberNotExist.checked = false;
      chkMemberActive.checked = false;
      chkMemberNumberIneligible.checked = false;
      chkFacilityServiceInactive.checked = false;

      this.errorsToInclude.MemberNumberNotExist = false;
      this.errorsToInclude.MemberStatusInvalid = false;
      this.errorsToInclude.MemberIneligible = false;
      this.errorsToInclude.FacilityServiceComboInactive = false;

      if (this.InvalidRows > 0 && this.ValidRows === 0) {
        this.saveBtn = true;
      }
    }
  }

  public CheckFutureVisitDates(event) {
    MessageBox.ShowCancelContinue(
      this.dialog,
      "Future Visit Data Confirmation",
      "You are about to import visit data into the future, " +
      "Do you wish to continue?"
    ).subscribe(dialogResult => {
      if (dialogResult.result.toLowerCase() === DialogResult.Continue) {
        this.Save(event)
      }
    });
  }

  // this function saves the raw visits to the database
  public Save(event) {
    MessageBox.ShowCancelContinue(
      this.dialog,
      "File Upload Confirmation",
      "Once uploaded, the file will register visits to the Raw Visit History table which will increase the visit count for that facility, " +
      " service and period. Do you wish to continue?"
    ).subscribe(dialogResult => {
      if (dialogResult.result.toLowerCase() === DialogResult.Continue) {
        this.progressBar.Open("Uploading File");
        this.progressBar.StartPercentTimer(25);

        // Send the username of user that uploaded the visits
        const currentUser = this.cmsrv.GetUser();
        this.rawVisitDataToSend.body = new body();
        this.rawVisitDataToSend.body.InsertedBy = currentUser;

        // Send which errors to include
        this.rawVisitDataToSend.body.CheckRawVisitCriteria = this.errorsToInclude;
        this.rawVisitDataToSend.body.CSVFileData = this.rawVisitResult.CSVFileData;

        // Send error row counts for each check (NULL values)
        this.rawVisitDataToSend.body.ErrorDataMemberNotExistCount = this.rawVisitResult.ErrorDataMemberNotExistCount;
        this.rawVisitDataToSend.body.ErrorDataMemberStatusInvalidCount = this.rawVisitResult.ErrorDataMemberStatusInvalidCount;
        this.rawVisitDataToSend.body.ErrorDataMemberIneligibleCount = this.rawVisitResult.ErrorDataMemberIneligibleCount;
        this.rawVisitDataToSend.body.ErrorDataFacilityServiceInactiveCount = this.rawVisitResult.ErrorDataFacilityServiceInactiveCount;

        this.rawVisitDataToSend.body.TotalRows = this.TotalRows;
        this.rawVisitDataToSend.body.TotalErrorRows = this.InvalidRows;

        console.log("Raw Visit Data to Send: ", this.rawVisitDataToSend);

        //Statemachine Step Function trigger
        this.Invoke(
          this.awsService.StartStepExecution(
            StepFunctions.SaveRawVisits,
            null,
            null,
            null,
            this.rawVisitDataToSend
          ),
          (result: AWSStartExecutionResponse) => {
            this.progressBar.close_subject.next(true);
            console.log("Result: ", result);
            if (
              result.executionArn === undefined ||
              result.executionArn === "" ||
              result.executionArn === null
            ) {
              MessageBox.ShowError(this.dialog, "Something has gone wrong.");
            } else {
              this.saveRawDescSpinner.stepMachineIsRunning = true;
              this.saveRawDescSpinner.executionArn = result.executionArn;
              this.saveRawDescSpinner.StartDescriptionTimer(10);
            }
            this.progressBar.upload_percent_subject.next(100);
            this.progressBar.upload_status_subject.next("Upload Completed! ");
            this.progressBar.close_subject.next(true);
          }
        );
      }
    });
  }

  handleUploadReturn(result) {
    console.log("Upload message: ", result);
    MessageBox.ShowInfo(this.dialog, result.Message).subscribe(dialogResult => {
      if (dialogResult.result.toLowerCase() === DialogResult.Ok) {
        window.location.reload();
      }
    });

  }
}
