import { Component, Input } from '@angular/core';
import { BaseComponent, MakeProvider } from '../base/base';
import { CommonResponse } from 'src/app/models';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
  host: { '(blur)': 'onTouched()' },
  providers: [ MakeProvider(SelectComponent) ]
})
export class SelectComponent extends BaseComponent<string> {
  @Input() data: CommonResponse[];

}
