import { Component, OnInit } from '@angular/core';
import { EoMScannerVisitLogPDFConvert } from './EoM-Scanner-VisitLog-PDFConvert';
import { merge, of as observableOf, forkJoin, Subject, empty } from 'rxjs';
import { skipWhile, startWith, switchMap, map, catchError, shareReplay, filter, debounceTime } from 'rxjs/operators';
import { HttpDAO, CommonService } from '@fp/services';
import { HttpClient } from '@angular/common/http';
import { S3Configuration } from '@fp/models/s3configuration.model';
import { APIConstant } from '@fp/constant';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-download-PDF',
  templateUrl: './download-PDF.component.html',
  styleUrls: ['./download-PDF.component.css']
})
export class DownloadPDFComponent implements OnInit {
  private httpdao: HttpDAO | null;
  private ReportHeader: string = '';
  private csvFileURL: string = '';
  public status = 'Download...';

  constructor(private http: HttpClient,
    private comsvr: CommonService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.httpdao = new HttpDAO(this.http);
    this.csvFileURL = this.route.snapshot.queryParams.signedURL;
    this.httpdao.getDataWithoutToken(APIConstant.API_GET_DOWNLOAD_URL + encodeURIComponent(this.csvFileURL)).subscribe(
      (res) => {
          this.csvFileURL = res+'';
          this.LoadCSVFile();
      },
      (err) => {
        console.error("Error in get url", err);
      },
    );
  }

  private LoadCSVFile() {
    this.comsvr.StartGlobalProgressBar();

    forkJoin(
      this.httpdao!.getRemoteFileData(this.csvFileURL)
    ).pipe(
      map(([cmdata]) => {
        return { cmdata };
      }),
      catchError(() => {
        this.comsvr.StopGlobalProgressBar();
        return observableOf(null);
      })
    ).subscribe(results => {
      this.status = "Download completed.";

      if (results != null) {
        const content: string = results.cmdata;
        const arr_cont: string[] = content.split('\n');
        this.ReportHeader = arr_cont[0];
        const fileName = new URL(this.csvFileURL).pathname.split('/')[2];
        const eomsc = new EoMScannerVisitLogPDFConvert();
        eomsc.SaveReportToPDFFile(this.ReportHeader, arr_cont, fileName);
      }

      this.comsvr.StopGlobalProgressBar();
    });
  }
}
