export enum EMemberStatus {
    CandidateMember = 1,
    New = 2,
    Active = 3,
    Invalid = 4,
    Changed = 5,
    Inactive = 6,
    Banned = 7,
    DeIdentified = 8,
    SurveyCandidate = 9
}
