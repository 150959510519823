export class MemberType {
    MemberTypeId: number;
    Name: string;
    DisplayOrder: number | null;
    IsPrimary: boolean;
    IsActive: boolean;
    
    constructor(ID?: number, name?: string, order?: number, active?: boolean) {
        this.MemberTypeId = ID;
        this.Name = name;
        this.DisplayOrder = order;
        this.IsActive = active;
    }
}
