
export class MemberAdditionalNotes {
    public Username: string;
    public UserId: number = null;
    public CreateOrUpdateDate: any;
    public DateTimeDisplay: string = '';
    public HoursofNote: number;
    public Type: string;
    public NoteTypeId: number;
    public Note: string;
    public Action: string;
    public MemberNoteId: number;
    public MemberId: number;
    public Key: string;
    public WasAddedInCancelMode = false;
    public IsSentEmail = false;
}

export class ListMemberNotes {
    public MemberNotes: MemberAdditionalNotes[] = [];
}
