import { Component, Injector } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ModelBasedForm } from '@fp/components/base';
import { FpFileData, FpFileEvent } from '@fp/components/control';
import { CommonMessage, CommonString } from '@fp/constant';
import { StringHelper } from '@fp/helpers';
import { EmployerContractModel, EmployerContractUploadFile } from '@fp/models';
import { MessageBox } from '@fp/services';
import { NgbDateAdapter, NgbDateNativeUTCAdapter } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-employer-contract',
    templateUrl: './contract.component.html',
    styleUrls: ['./contract.component.css'],
    providers: [
        ModelBasedForm.provideExisting(EmployerContractComponent),
        { provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter }]
})
export class EmployerContractComponent<T extends {
    EmployerContract: EmployerContractModel
}> extends ModelBasedForm<T> {
    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            EmployerContract: fb.group({
                EmployerContractId: [0],
                ContractStartDate: [null, [,
                    (ctrl: AbstractControl) => {
                        const parent = ctrl.parent;
                        if (!parent) return null;
                        const startDate = ctrl.value as Date;
                        const endDate = parent.get('ContractEndDate').value as Date;
                        // "ContractStartDate" must be less than "ContractEndDate".
                        return startDate && endDate && startDate.getTime() > endDate.getTime() ? { 'lt': { 'ContractEndDate': endDate } } : null;
                    }]],
                ContractEndDate: [null, [,
                    (ctrl: AbstractControl) => {
                        const parent = ctrl.parent;
                        if (!parent) return null;
                        const endDate = ctrl.value as Date;
                        const startDate = parent.get('ContractStartDate').value as Date;
                        // "ContractEndDate" must be greater than "ContractStartDate".
                        return endDate && startDate && endDate.getTime() < startDate.getTime() ? { 'gt': { 'ContractStartDate': startDate } } : null;
                    }]],
                ContractDuration: new UntypedFormControl(null, [Validators.max(99)]),
                ContractSignatory: [null],
                EmployerContractAttachFile: [[]],
                EmployerContractLogo: [[]],
                EmployerSignedContract: [[]],
            })
        });
    }

    CommonMessage = CommonMessage;

    constructor(injector: Injector) { super(injector); }

    OnLoadComplete() {
        setTimeout(() => {
            const startDateControl = this.getControl('EmployerContract.ContractStartDate');
            const endDateControl = this.getControl('EmployerContract.ContractEndDate');
            const durationControl = this.getControl('EmployerContract.ContractDuration');

            const calculateContractDuration = (startDate: Date, endDate: Date): number => {
                const diffDate = new Date(Math.abs(startDate.getTime() - endDate.getTime()));
                return Math.round((diffDate.getUTCFullYear() - 1970) + diffDate.getUTCMonth() / 12)
            }
            const updateContractDurationControl = () => {
                let duration = 0;
                const startDate = startDateControl.value as Date;
                const endDate = endDateControl.value as Date;
                if (startDate || endDate) {
                    durationControl.disable();
                } else {
                    durationControl.enable();
                }
                if (startDateControl.valid && endDateControl.valid &&
                    startDate instanceof Date && endDate instanceof Date &&
                    !isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
                    duration = calculateContractDuration(startDate, endDate);
                }
                durationControl.setValue(duration);
            }

            startDateControl.valueChanges.subscribe((value: Date) => {
                endDateControl.updateValueAndValidity({ emitEvent: false });
                updateContractDurationControl();
            });

            endDateControl.valueChanges.subscribe((value: Date) => {
                startDateControl.updateValueAndValidity({ emitEvent: false });
                updateContractDurationControl();
            });

        });
        super.OnLoadComplete();
    }

    SignedContract_FileSelected(event: FpFileEvent) {
        if (event.file.type !== 'application/pdf') {
            event.cancel();
            MessageBox.ShowError(this.dialog, StringHelper.format(CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE, 'PDF'));
        }
    }
    SignedContract_FileUploaded(fileData: FpFileData) {
        const uploadFile: EmployerContractUploadFile = {
            EmployerContractId: 0,
            FileName: fileData.originfilename,
            Status: 'Addnew',
            Location: fileData.filedata.Location
        }
        const files = this.getControlValue<EmployerContractUploadFile[]>('EmployerContract.EmployerSignedContract').concat(uploadFile);
        this.getControl('EmployerContract.EmployerSignedContract').setValue(files);
    }

    AdditionalFile_FileSelected(event: FpFileEvent) {
        if (event.file.type !== 'image/png' &&
            event.file.type !== 'image/jpg' &&
            event.file.type !== 'image/jpeg' &&
            event.file.type !== 'application/pdf') {
            event.cancel();
            MessageBox.ShowError(this.dialog, StringHelper.format(CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE, 'JPEG or PDF'));
        } else if (event.file.type == 'application/pdf' && event.file.size > CommonString.MAX_UPLOAD_FILE_SIZE_10MB * 10) {
            event.cancel();
            MessageBox.ShowError(this.dialog, StringHelper.format(CommonString.MAX_UPLOAD_FILE_SIZE_MESSAGE, '100MB'));
        } else if (event.file.type !== 'application/pdf' && event.file.size > CommonString.MAX_UPLOAD_FILE_SIZE_10MB) {
            event.cancel();
            MessageBox.ShowError(this.dialog, StringHelper.format(CommonString.MAX_UPLOAD_FILE_SIZE_MESSAGE, '10MB'));
        }
    }
    AdditionalFile_FileUploaded(fileData: FpFileData) {
        const uploadFile: EmployerContractUploadFile = {
            EmployerContractId: 0,
            FileName: fileData.originfilename,
            Status: 'Addnew',
            Location: fileData.filedata.Location
        }
        const files = this.getControlValue<EmployerContractUploadFile[]>('EmployerContract.EmployerContractAttachFile').concat(uploadFile);
        this.getControl('EmployerContract.EmployerContractAttachFile').setValue(files);
    }

    Logo_FileSelected(event: FpFileEvent) {
        if (event.file.type !== 'image/png' &&
            event.file.type !== 'image/jpg' &&
            event.file.type !== 'image/jpeg') {
            event.cancel();
            MessageBox.ShowError(this.dialog, StringHelper.format(CommonString.WRONG_UPLOAD_FILE_TYPE_MESSAGE, 'JPEG or PNG'));
        }
    }
    Logo_FileUploaded(fileData: FpFileData) {
        const uploadFile: EmployerContractUploadFile = {
            EmployerContractId: 0,
            FileName: fileData.originfilename,
            Status: 'Addnew',
            Location: fileData.filedata.Location
        }
        const files = this.getControlValue<EmployerContractUploadFile[]>('EmployerContract.EmployerContractLogo').filter(_logo => !_logo.Status);
        files.forEach(_logo => _logo.Status = 'Remove')
        this.getControl('EmployerContract.EmployerContractLogo').setValue(files.concat(uploadFile));
    }

    _removeFile(control: UntypedFormControl, file: EmployerContractUploadFile) {
        const items = (<EmployerContractUploadFile[]>control.value);
        const index = items.indexOf(file);
        if (file && file.Status !== 'Remove') {
            if (!file.Status) {
                file.Status = 'Remove';
            } else {
                items.splice(index, 1);
            }
            control.setValue(items);
        }
    }

    _filterAvailableFiles(files: EmployerContractUploadFile[]): EmployerContractUploadFile[] {
        return (files || []).filter(_file => _file.Status !== 'Remove');
    }
}
