import { Component, Injector } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { CommonConstants } from '@fp/constant/common-constants';
import { Country, Message } from '@fp/models';
import { CommonService, MessageService } from '@fp/services';
import { FacilityFormBase } from 'src/app/components/base';
import { CommonString } from 'src/app/constant';
import { StringHelper } from 'src/app/helpers';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-facility-bank-account-details',
    templateUrl: './facility-bank-account-details.component.html',
    styleUrls: ['./facility-bank-account-details.component.css'],
    providers: [{ provide: FacilityFormBase, useExisting: FacilityBankAccountDetailsComponent }]
})
export class FacilityBankAccountDetailsComponent extends FacilityFormBase {
    subscription: Subscription;
    StringHelper = StringHelper;
    CommonString = CommonString;
    isNZFac = false;
    BankCodeLable="BSB";
    static isDisabledBankDetails: boolean;
    static getFormGroup() {
        const fb = new UntypedFormBuilder();
        return fb.group({
            BankAccount: [null]
        });
    }

    static getBankAccountFormGroup() {
        const fb = new UntypedFormBuilder();
        if (FacilityBankAccountDetailsComponent.isDisabledBankDetails) {
            return fb.group({
                BankAccountId: [0],
                BankAccountName: [null],
                BankAccountBsb: [null],
                BankAccountType: [null],
                BankAccountNumber: [null],
                BankName: [null],
                IsActive: [true],
            });
        } else {
            return fb.group({
                BankAccountId: [0],
                BankAccountName: [null, Validators.required],
                BankAccountBsb: [null, Validators.required],
                BankAccountType: [null],
                BankAccountNumber: [null, Validators.required],
                BankName: [null, Validators.required],
                IsActive: [true],
            });
        }
    }

    constructor(injector: Injector,private cmsrv: CommonService, private messageService: MessageService) { 
        super(injector); 
    }
    ngOnInit(){
    this.updateValidators(true);
  
        this.subscription = this.messageService.currentMessage.subscribe((message: Message) => {
            if (message && message.Identifier == CommonString.FacilityPotentialMessageIdentifier) {
                if (message.Content == 'True') {
                    FacilityBankAccountDetailsComponent.isDisabledBankDetails = true;
                    this.updateValidators(false);
                } else {
                    FacilityBankAccountDetailsComponent.isDisabledBankDetails = false;
                    this.updateValidators(true);
                }
            }
        });
    }
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    setFacilityCountry(country: Country) {
        if(country.Name.toUpperCase() == CommonConstants.COUNTRY_NEW_ZEALAND ){           
            this.isNZFac=true;
            this.BankCodeLable="Bank Code";//set bank code as BSB
        }else{           
            this.isNZFac=false;
            this.BankCodeLable="BSB";
        }
    }

    ToggleBankAccountFormGroup(enabled: boolean) {
        if (enabled) {
            this.form.setControl('BankAccount', FacilityBankAccountDetailsComponent.getBankAccountFormGroup());
            this.form.parent.get('PaymentDetails').disabled ? this.form.disable() : null;
            if(this.isNZFac){
                this.form.get('BankAccount.BankAccountType').setValidators(Validators.required);
            }
        } else {
            this.form.setControl('BankAccount', new UntypedFormControl(null));
        }
    }
    
    public trimBSB() {
        var BSB = ""+this.form.get('BankAccount.BankAccountBsb').value;
        var trimBSB = BSB.replace(/\s/g, "");
        this.form.get('BankAccount.BankAccountBsb').setValue(trimBSB);
    }

    public trimBankAccountNum() {
        var BankAccountNum = ""+this.form.get('BankAccount.BankAccountNumber').value;
        var trimNum = BankAccountNum.replace(/\s/g, "");
        this.form.get('BankAccount.BankAccountNumber').setValue(trimNum);
    }

    public trimBankAccountType() {
        var BankAccountNum = ""+this.form.get('BankAccount.BankAccountType').value;
        var trimNum = BankAccountNum.replace(/\s/g, "");
        this.form.get('BankAccount.BankAccountType').setValue(trimNum);
    }

    PatchValue(value, options?) {
        this.ToggleBankAccountFormGroup(value['BankAccount'] != null);
        super.PatchValue(value, options);
    }

    private updateValidators(required: boolean): void {
        let controls = ['BankAccount.BankAccountName', 'BankAccount.BankAccountBsb', 'BankAccount.BankAccountNumber', 'BankAccount.BankName'];

        controls.forEach(controlPath => {
            let control = this.form.get(controlPath);
            if (control) {
                let validators = required ? [Validators.required] : [];
                control.setValidators(validators);
                control.updateValueAndValidity();
            }
        });
    }     
}
