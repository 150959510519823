<div class="border-default rounded-10 border-2">
    <div class="tablecontainer">
        <table mat-table matSort [dataSource]="_dataSource" class="rounded-10">
            <!-- Name Column -->
            <ng-container matColumnDef="PackageName">
                <th mat-header-cell *matHeaderCellDef> Package Name </th>
                <td mat-cell *matCellDef="let element;let i=index">
                    {{i == 0 ? membershipPackage?.MembershipPackageType?.Name : ''}}
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <ng-container matColumnDef="Members">
                <th mat-header-cell *matHeaderCellDef> Members </th>
                <td mat-cell *matCellDef="let element">
                    {{element.MemberType?.Name.replace('Dependent','Dependant')}} - {{element.FirstName + ' ' +
                    element.LastName }}
                </td>
                <td mat-footer-cell *matFooterCellDef class="text-right pr-md-5"><span
                        class="d-block pr-md-5">Total*</span></td>
            </ng-container>

            <ng-container matColumnDef="Price">
                <th mat-header-cell *matHeaderCellDef> Price / Week </th>
                <td mat-cell *matCellDef="let element" class="text-primary">
                    {{getPrice(element) > 0 ? '$' + getPrice(element).toFixed(2) : ''}}
                </td>
                <td mat-footer-cell *matFooterCellDef class="text-primary">
                    {{'$' + getTotalPrice().toFixed(2)}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [hidden]="!isMemberReactivated(row.MemberStatusId, row.IsReactivated)">
            </tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
    </div>
</div>