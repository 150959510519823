<div [formGroup]="form">
    <ngb-accordion [destroyOnHide]="false" activeIds="pnlAddressContactDetails,pnlFamilyMemberDetails">
        <ngb-panel id="pnlAddressContactDetails">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': addressContactDetails?.valid == true}"></i>
                Address and Contact Details
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-member-address-contact-details [form]="form.get('AddressContactDetails')" [data]="data"
                    #addressContactDetails></app-member-address-contact-details>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlFamilyMemberDetails">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': familyMembers?.valid == true}"></i>
                Select Family Members
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <p>If you wish to reactivate anyone other than yourself on your membership, please select them below : </p>
                <app-family-member-reactivation [form]="form.get('FamilyMembers')" [data]="data"
                    #familyMembers></app-family-member-reactivation>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>