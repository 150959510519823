import { DateHelper } from "@fp/helpers";
import { CommonService } from "src/app/services/common.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { APIConstant } from "src/app/constant/apiconstant";
import {
  DataResult,
  Facility,
  FacilityContactType,
  FacilityContract,
  FacilityDocumentViewModel,
  FacilityStatus,
  FacilityType,
  FacilityCategory,
  Image,
  SearchPaginationRequest,
  SearchPaginationResult,
  PostPaymentRequest,
} from "src/app/models";
import { Utils } from "../helpers";
import { HttpDAO } from "./httpdao.service";

@Injectable({
  providedIn: "root",
})
export class FacilityService {
  private httpDao: HttpDAO | null;

  constructor(httpClient: HttpClient, private cmsrv: CommonService) {
    this.httpDao = new HttpDAO(httpClient);
  }

  private processFacilityData(data: Facility) {
    if (!data) {
      return;
    }
    if (typeof data.NoNewMembersDate === "string") {
      try {
        data.NoNewMembersDate = Utils.parseUTCDate(data.NoNewMembersDate);
      } catch (err) {
        console.error(err);
      }
    }

    if (!data.FacilityContract) data.FacilityContract = new FacilityContract();
    if (typeof data.FacilityContract.ContractEndDate === "string") {
      try {
        data.FacilityContract.ContractEndDate = new Date(
          data.FacilityContract.ContractEndDate + "Z"
        );
      } catch (err) {
        console.error(err);
      }
    }
    if (typeof data.FacilityContract.ContractStartDate === "string") {
      try {
        data.FacilityContract.ContractStartDate = new Date(
          data.FacilityContract.ContractStartDate + "Z"
        );
      } catch (err) {
        console.error(err);
      }
    }

    if (!data.Services) {
      data.Services = [];
    }
    if (!data.Products) {
      data.Products = [];
    }
  }

  get(id: number): Observable<DataResult<Facility>> {
    return this.httpDao.getSingleData(APIConstant.API_GET_FACILITY + id).pipe(
      map((value: DataResult<Facility>) => {
        if (value.Success && value.Data) {
          this.processFacilityData(value.Data);
        }
        return value;
      })
    );
  }

  getFPaymentDetails(id: number): Observable<DataResult<Facility>> {
    const request = { id: id.toString() };
    const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao
      .postData(APIConstant.API_GET_FACILITY_PAYMENTDETAILS, {
        Data: encrypteddata,
      })
      .pipe(
        map((edata: string) => {
          const dectypted_data = this.cmsrv.D_FP_ResponseData(edata);
          const rdata: DataResult<Facility> = JSON.parse(dectypted_data);
          if (rdata.Success && rdata.Data) {
            this.processFacilityData(rdata.Data);
          }
          return rdata;
        })
      );
  }

  getByName(name: string): Observable<DataResult<Facility>> {
    return this.httpDao
      .getSingleData(
        APIConstant.API_GET_FACILITY_BY_NAME + encodeURIComponent(name)
      )
      .pipe(
        map((value: DataResult<Facility>) => {
          if (value.Success && value.Data) {
            this.processFacilityData(value.Data);
          }
          return value;
        })
      );
  }

  findByName(name: string): Observable<DataResult<Facility>> {
    return this.httpDao
      .getSingleData(
        APIConstant.API_FIND_FACILITIES_BY_NAME + encodeURIComponent(name)
      )
      .pipe(
        map((value: DataResult<Facility>) => {
          if (value.Success && value.Data) {
            this.processFacilityData(value.Data);
          }
          return value;
        })
      );
  }

  create(facility: Facility): Observable<DataResult<Facility>> {
    facility.PaymentLogs = [];
    if (typeof facility.FacilityContract.ContractEndDate === "object") {
      const ed: Date = facility.FacilityContract.ContractEndDate;
      facility.FacilityContract.ContractEndDate = DateHelper.format(
        ed,
        "YYYY-MM-DD"
      );
    }
    if (typeof facility.FacilityContract.ContractStartDate === "object") {
      const sd: Date = facility.FacilityContract.ContractStartDate;
      facility.FacilityContract.ContractStartDate = DateHelper.format(
        sd,
        "YYYY-MM-DD"
      );
    }
    console.log(JSON.stringify(facility));
    const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(facility));
    return this.httpDao
      .postData(APIConstant.API_CREATE_FACILITY, { Data: encrypteddata })
      .pipe(
        map((value: any) => {
          const dectypted_data = this.cmsrv.D_FP_ResponseData(value);
          const rdata: DataResult<Facility> = JSON.parse(dectypted_data);
          return rdata;
        })
      );
  }

  update(facility: Facility): Observable<DataResult<Facility>> {
    facility.PaymentLogs = [];
    if (typeof facility.FacilityContract.ContractEndDate === "object") {
      const ed: Date = facility.FacilityContract.ContractEndDate;
      facility.FacilityContract.ContractEndDate = DateHelper.format(
        ed,
        "YYYY-MM-DD"
      );
    }
    if (typeof facility.FacilityContract.ContractStartDate === "object") {
      const sd: Date = facility.FacilityContract.ContractStartDate;
      facility.FacilityContract.ContractStartDate = DateHelper.format(
        sd,
        "YYYY-MM-DD"
      );
    }
    const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(facility));
    return this.httpDao
      .postData(APIConstant.API_UPDATE_FACILITY, { Data: encrypteddata })
      .pipe(
        map((value: any) => {
          const dectypted_data = this.cmsrv.D_FP_ResponseData(value);
          const rdata: DataResult<Facility> = JSON.parse(dectypted_data);
          if (rdata.Success && rdata.Data) {
            this.processFacilityData(rdata.Data);
          }
          return rdata;
        })
      );
  }

  // searches the database to find all facilities (active or non-active)
  search(searchRequest: SearchPaginationRequest): Observable<string> {
    const encrypteddata = this.cmsrv.E_FP_RequestData(
      JSON.stringify(searchRequest)
    );
    return this.httpDao.postData(APIConstant.API_SEARCH_FACILITIES, {
      Data: encrypteddata,
    });
  }

  //FP-618 - search services based on facility
  searchServices(searchRequest: SearchPaginationRequest): Observable<string> {
    const encrypteddata = this.cmsrv.E_FP_RequestData(
      JSON.stringify(searchRequest)
    );
    return this.httpDao.postData(APIConstant.API_SEARCH_FSERVICES_FOR_FACILITIES, {
      Data: encrypteddata,
    });
  }

  getPhotos(facilityId: number): Observable<string> {
    const request = { id: facilityId.toString() };
    const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_GET_PHOTOS_BY_FACILITYID, {
      Data: encrypteddata,
    });
  }

  getDocuments(facilityId: number): Observable<string> {
    const request = { id: facilityId.toString() };
    const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_GET_DOCUMENTS_BY_FACILITYID, {
      Data: encrypteddata,
    });
  }

  /**
   * Get all facility types.
   *
   * @returns {Observable<DataResult<FacilityType[]>>} An observable array of `FacilityType` objects.
   * @memberof FacilityService
   */
  getTypes(): Observable<DataResult<FacilityType[]>> {
    return this.httpDao.getSingleData(APIConstant.API_GET_FACILITY_TYPES);
  }

  /**
   * Get all facility types.
   *
   * @returns {Observable<DataResult<FacilityCategory[]>>} An observable array of `FacilityCategory` objects.
   * @memberof FacilityService
   */
  getCategories(): Observable<DataResult<FacilityCategory[]>> {
    console.log()
    return this.httpDao.getSingleData(APIConstant.API_GET_FACILITY_CATEGORIES);

  }

  /**
   * Get all facility statuses.
   *
   * @returns {Observable<DataResult<FacilityStatus[]>>} An observable array of `FacilityStatus` objects.
   * @memberof FacilityService
   */
  getStatuses(): Observable<DataResult<FacilityStatus[]>> {
    return this.httpDao.getSingleData(APIConstant.API_GET_FACILITY_STATUSES);
  }

  /**
   * Get all facility contact types.
   *
   * @returns {Observable<DataResult<FacilityContactType[]>>} An observable array of `FacilityContactType` objects.
   * @memberof FacilityService
   */
  getContactTypes(): Observable<DataResult<FacilityContactType[]>> {
    return this.httpDao.getSingleData(
      APIConstant.API_GET_FACILITY_CONTACT_TYPES
    );
  }

  getFacilitiesByName(facilityName: string): Observable<any[]> {
    return this.httpDao.getData(
      APIConstant.API_FIND_FACILITIES_BY_NAME + facilityName
    );
  }

  getServicesByUsername(userName: string): Observable<any[]> {
    return this.httpDao.getData(
      APIConstant.FIND_SERVICES_BY_USERNAME + userName
    );
  }

  getFacilityPaymentLogs(facilityId: number): Observable<any> {
    const request = { id: facilityId.toString() };
    const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(request));
    return this.httpDao.postData(APIConstant.API_GET_FACILITY_PAYMENT_LOGS, {
      Data: encrypteddata,
    });
  }

  // searches the database to find all parent groups
  searchParentGroup(
    searchRequest: SearchPaginationRequest
  ): Observable<string> {
    const encrypteddata = this.cmsrv.E_FP_RequestData(
      JSON.stringify(searchRequest)
    );
    return this.httpDao.postData(APIConstant.API_SEARCH_PARENT_GROUP, {
      Data: encrypteddata,
    });
  }

  getActivities(): Observable<any[]> {
    return this.httpDao.getSingleData(APIConstant.API_GET_ACTIVITIES);
  }
}
