export class RolePrivilegeConstant {
    public static ADMIN = 'Admin';
    public static SUPER_USER = 'Super_User';
    public static ACCOUNTS_PAYABLE_OFFICER_READONLY = 'Accounts_Payable_Officer_ReadOnly';
    public static ACCOUNTS_PAYABLE_OFFICER = 'Accounts_Payable_Officer';
    public static FINANCE_LEVEL_1_READONLY = 'Finance_Level_1_ReadOnly';
    public static FINANCE_LEVEL_1 = 'Finance_Level_1';
    public static FINANCE_LEVEL_2 = 'Finance_Level_2';
    public static FINANCE_LEVEL_3 = 'Finance_Level_3';
    public static BUSINESS_DEVELOPMENT_READONLY = 'Business_Development_ReadOnly';
    public static BUSINESS_DEVELOPMENT_LEVEL_1 = 'Business_Development_Level_1';
    public static BUSINESS_DEVELOPMENT_LEVEL_2 = 'Business_Development_Level_2';
    public static BUSINESS_DEVELOPMENT_LEVEL_3 = 'Business_Development_Level_3';
    public static PRODUCT_DEVELOPMENT_READONLY = 'Product_Development_ReadOnly';
    public static PRODUCT_DEVELOPMENT_LEVEL_1 = 'Product_Development_Level 1';
    public static PRODUCT_DEVELOPMENT_LEVEL_2 = 'Product_Development_Level 2';
    public static PRODUCT_DEVELOPMENT_LEVEL_3 = 'Product_Development_Level 3';
    public static OPERATION_LEVEL_1_READONLY = 'Operation_Level_1_ReadOnly';
    public static OPERATION_LEVEL_1 = 'Operation_Level_1';
    public static OPERATION_LEVEL_2 = 'Operation_Level_2';
    public static OPERATION_LEVEL_3 = 'Operation_Level_3';
    public static FACILITY_STAFF_MEMBER = 'Facility_Staff_Member';
    public static EMPLOYER_FACILITATOR = 'Employer_Facilitator';
    public static MEMBER = 'Member';
    public static PRIVILEGED_REPORTS_ACCESS = 'Privileged_Reports_Access';
    public static BUSINESS_DEVELOPMENT_LEVEL_4 = 'Business_Development_Level_4';
    public static BUSINESS_DEVELOPMENT_LEVEL_5 = 'Business_Development_Level_5';

    public static PRIVILEGES_ABILITY_TO_ADD_NEW_USER = 'Ability to add new user';
    public static PRIVILEGES_ABILITY_TO_VIEW_EDIT_USER = 'Ability to view/edit user';
    public static PRIVILEGES_CREATE_UPDATE_EMPLOYERS = 'Create/Update Employers';
    public static PRIVILEGES_ABILITY_TO_ADD_NEW_FACILITY = 'Ability to add new facility';
    public static PRIVILEGES_VIEW_EDIT_FACILITY = 'View/Edit facility';
    public static PRIVILEGES_VIEW_MASTER_LIST_OF_REGIONS = 'View master list of regions';
    public static PRIVILEGES_CREATE_EDIT_REGION = 'Create/Edit Region';

}
