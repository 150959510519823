import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { interval, Observable } from "rxjs";
import { AwsServiceService } from "@fp/services/aws-service.service";
import { AWSDescribeExecutionResponse, CDPEState, DataResult } from "@fp/models";
import { catchError } from "rxjs/operators";
import { AWSStateMachineStates, StepFunctions } from "@fp/enums/step-functions.enum";

@Component({
  selector: 'app-describe-state-machine',
  templateUrl: './describe-state-machine.component.html',
  styleUrls: ['./describe-state-machine.component.css']
})
export class DescribeStateMachineComponent implements OnInit {
  @Input() MachineType = StepFunctions.CreateDebitProcessingEntry;
  @Input() IsReport = false;
  @Output() SuccessReturned = new EventEmitter();
  @Output() FailureReturned = new EventEmitter();
  stepMachineIsRunning = false;
  stepMachineSuccess = false;
  stepMachineFailure = false;
  stepMachineTooltip = "Process is running...";
  executionArn: string = "";
  errorHandler: any;

  constructor(
    private awsService: AwsServiceService
  ) { }

  ngOnInit() {
  }

  /**
   * Begins a periodic query to the Describe API fro Step Functions.
   * NOTE: Handle Type should match return value from Backend!
   * @param queryTimeSeconds The time between queries to the Description API in Seconds.
   */
  public StartDescriptionTimer(queryTimeSeconds: number = 30) {
    this.stepMachineIsRunning = true;
    this.stepMachineSuccess = false;
    this.stepMachineFailure = false;
    this.stepMachineTooltip = "Process is running...";
    const intervalCount = interval(1000 * queryTimeSeconds);
    intervalCount
      .subscribe(() => {
        if (this.executionArn !== null && this.executionArn !== undefined && this.executionArn !== "") {
          switch (this.MachineType) {
            case StepFunctions.CreateDebitProcessingEntry:
              this.Invoke(
                this.awsService.DescribeStepExecution(this.executionArn),
                (result: AWSDescribeExecutionResponse<any>) => {
                  this.HandleCDPEResult(result);
                }
              )
              break;
            case StepFunctions.UploadFailedTransactions:
              this.Invoke(
                this.awsService.DescribeStepExecution(this.executionArn),
                (result: AWSDescribeExecutionResponse<any>) => {
                  this.HandleDataResultStringArray(result);
                }
              )
              break;
            case StepFunctions.ConfirmUploadFailedTransactions:
              this.Invoke(
                this.awsService.DescribeStepExecution(this.executionArn),
                (result: AWSDescribeExecutionResponse<any>) => {
                  this.HandleDataResultString(result);
                }
              )
              break;
            case StepFunctions.CheckRawVisits:
              this.Invoke(
                this.awsService.DescribeStepExecution(this.executionArn),
                (result: AWSDescribeExecutionResponse<any>) => {
                  this.HandleDataResultString(result);
                }
              )
              break;
            case StepFunctions.SaveRawVisits:
              this.Invoke(
                this.awsService.DescribeStepExecution(this.executionArn),
                (result: AWSDescribeExecutionResponse<any>) => {
                  this.HandleDataResultString(result);
                }
              )
              break;

            case StepFunctions.RegenerateAbas:
              this.Invoke(
                this.awsService.DescribeStepExecution(this.executionArn),
                (result: AWSDescribeExecutionResponse<any>) => {
                  this.HandleResultModel(result, false);
                }
              )
              break;

            case StepFunctions.BulkUpload:
              this.Invoke(
                this.awsService.DescribeStepExecution(this.executionArn),
                (result: AWSDescribeExecutionResponse<any>) => {
                  this.HandleResultModel(result, true);
                }
              )
              break;

            case StepFunctions.BulkUploadBankDetail:
                this.Invoke(
                  this.awsService.DescribeStepExecution(this.executionArn),
                  (result: AWSDescribeExecutionResponse<any>) => {
                    this.HandleResultModel(result, true);
                  }
                )
                break;
            case StepFunctions.BulkUploadPostcodeDetail:
                this.Invoke(
                  this.awsService.DescribeStepExecution(this.executionArn),
                  (result: AWSDescribeExecutionResponse<any>) => {
                    this.HandleResultModel(result, true);
                  }
                )
                break;

            case StepFunctions.FinaliseAbas:
              this.Invoke(
                this.awsService.DescribeStepExecution(this.executionArn),
                (result: AWSDescribeExecutionResponse<any>) => {
                  this.HandleResultModel(result, false);
                }
              )
              break;

            case StepFunctions.MemberMasterExport:
              this.Invoke(
                this.awsService.DescribeStepExecution(this.executionArn),
                (result: AWSDescribeExecutionResponse<any>) => {
                  this.HandleDataResultString(result);
                }
              )
              break;
            
            case StepFunctions.MemberMasterExportWithoutSensitiveData:
              this.Invoke(
                this.awsService.DescribeStepExecution(this.executionArn),
                (result: AWSDescribeExecutionResponse<any>) => {
                  this.HandleDataResultString(result);
                }
              )
              break;

              case StepFunctions.MemberMasterExportWithoutSensitiveData:
                this.Invoke(
                  this.awsService.DescribeStepExecution(this.executionArn),
                  (result: AWSDescribeExecutionResponse<any>) => {
                    this.HandleDataResultString(result);
                  }
                )
                break;
              
              case StepFunctions.MembershipPackageUpdateByCSVFile:
                this.Invoke(
                    this.awsService.DescribeStepExecution(this.executionArn),
                    (result: AWSDescribeExecutionResponse<any>) => {
                      this.HandleDataResultString(result);
                    }
                )
                break;

            default:
            // Do Nothing
          }
        }
      }
      );
  }

  public HandleResultModel(result: AWSDescribeExecutionResponse<any>, bulkUpload: boolean) {
    if (result.status === AWSStateMachineStates.SUCCEEDED) {
      var obj = JSON.parse(result.output);
      this.stepMachineIsRunning = false;
      this.stepMachineFailure = !obj.Success;
      this.stepMachineSuccess = obj.Success;
      this.stepMachineTooltip = obj.Message;
      this.executionArn = "";
      if(this.stepMachineFailure){
      this.FailureReturned.emit(obj);
      console.log(this.stepMachineFailure);
      }
      if (this.stepMachineSuccess) {
        if(bulkUpload && obj.Message !== "No error exists. File(s) uploaded successfully.") {
          this.stepMachineFailure = obj.Success;
          this.stepMachineSuccess = !obj.Success;
        }
        this.SuccessReturned.emit(obj);
      } else if (bulkUpload) {
        this.SuccessReturned.emit(obj);
      }
    }
    else {
      this.HandleGenericState<DataResult<string>>(result);
    }
  }

  /**
   * Sets the Result Icon correctly dependant on the result returned by the State Machine
   * @param result The Result Object with Output Type DataResult String
   */
  public HandleDataResultString(result: AWSDescribeExecutionResponse<any>) {
    if (result.status === AWSStateMachineStates.SUCCEEDED) {
      var obj = JSON.parse(result.output);
      this.stepMachineIsRunning = false;
      this.stepMachineFailure = !obj.Success;
      this.stepMachineSuccess = obj.Success;
      this.stepMachineTooltip = obj.Message;
      this.executionArn = "";
      console.log(this.stepMachineFailure);
      if (this.stepMachineSuccess) {
        this.SuccessReturned.emit(obj)
      }
    }
    else {
      this.HandleGenericState<DataResult<string>>(result);
    }
  }

  /**
   * Sets the Result Icon correctly dependant on the result returned by the State Machine
   * @param result The Result Object with Output Type DataResult String[]
   */
  public HandleDataResultStringArray(result: AWSDescribeExecutionResponse<any>) {
    if (result.status === AWSStateMachineStates.SUCCEEDED) {
      var obj = JSON.parse(result.output);
      this.stepMachineIsRunning = false;
      this.stepMachineFailure = !obj.Success;
      this.stepMachineSuccess = obj.Success;
      this.stepMachineTooltip = obj.Message;
      this.executionArn = "";
      console.log(this.stepMachineFailure);

      if (this.stepMachineSuccess) {
        this.SuccessReturned.emit(obj)
      }
    }
    else {
      this.HandleGenericState<DataResult<string[]>>(result);
    }
  }

  /**
   * Sets the Result Icon correctly dependant on the result the State Machine Returns
   * @param result The Result Object with Output Type CDPEState
   */
  public HandleCDPEResult(result: AWSDescribeExecutionResponse<CDPEState>) {
    if (result.status === AWSStateMachineStates.SUCCEEDED) {
      var obj = JSON.parse(result.output);
      this.stepMachineIsRunning = false;
      this.stepMachineFailure = !obj.Success;
      this.stepMachineSuccess = obj.Success;
      this.stepMachineTooltip = obj.Messages;
      this.executionArn = "";
    }
    else {
      this.HandleGenericState<CDPEState>(result);
    }
  }

  /**
   * If the State Machine didn't return a Succeeded State, handles the result.
   * @param result The generic result type from the State Machine API
   */
  private HandleGenericState<T>(result: AWSDescribeExecutionResponse<T>) {
    switch (result.status) {
      case AWSStateMachineStates.RUNNING:
        this.stepMachineIsRunning = true;
        this.stepMachineFailure = false;
        this.stepMachineSuccess = false;
        this.stepMachineTooltip = "Process is Running";
        break;
      case AWSStateMachineStates.ABORTED:
        this.SetModeFailure("Process manually aborted");
        break;
      case AWSStateMachineStates.FAILED:
        this.SetModeFailure("Process has timed out. Please Try Again.");
        break;
      case AWSStateMachineStates.TIMED_OUT:
        this.SetModeFailure("Process has timed out");
        break;
      default:
        this.stepMachineTooltip = "Process is Running";
        this.stepMachineIsRunning = true;
        this.stepMachineFailure = false;
        this.stepMachineSuccess = false;
        break;
    }
  }

  /**
   * Sets the page mode to failure.
   * @param toolTip The text to set the tooltip to.
   */
  private SetModeFailure(toolTip: string) {
    this.stepMachineTooltip = toolTip;
    this.stepMachineIsRunning = false;
    this.stepMachineFailure = true;
    this.stepMachineSuccess = false;
    this.executionArn = "";
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError(e => {
          throw e;
        })
      )
      .subscribe(
        res => {
          handleResultCallback(res);
        },
        err => {
          this.errorHandler(err);
        }
      );
  }


}
