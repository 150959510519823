export const environment = {
  production: true,
  envName: 'PROD',
  baseURL:
    'https://2382zcu018.execute-api.ap-southeast-2.amazonaws.com/prod/api/',
  UserPoolId: 'ap-southeast-2_P3UtlbdIa',
  ClientId: '2ngp44g6ih5vo5i8uu92641n4r',
  LocationPoolId: 'ap-southeast-2:40e428d1-29dd-4f36-b13e-e083d898e55d',
  LocationIndexName: 'FPGeolocationservice',
  CaptchaSiteKey: '6Le6e-UZAAAAACauNcVypMhqlTQn08H_I3bgctGz',
  CaptchaSecretKey: '6Le6e-UZAAAAAHkm3BfslVJ7Z72M3xa4_fEluNhX',
  TermAndCondionJsonURL:
    'https://s3-ap-southeast-2.amazonaws.com/fitpassdev-fileupload/TermAndCon.txt',
  IdleTime: 900,
  MapsAPIKey: 'AIzaSyB0SHZK3ngwOu0r26fm3pOrhKumXS7XdHY',
  NCKXXL: '1950277964321',
  ENVKEY: '4',
  TemplateTax:
    'https://s3-ap-southeast-2.amazonaws.com/fitpassprod-fileupload/assets/taxinvoice.pdf',
  NCKXXL2: '7061737323313288',
  NCKXXL3: '7061447324413244',
  GoogleAnalyticsCode: 'UA-50241741-3',
  imageS3Url: 'https://s3.ap-southeast-2.amazonaws.com/fitpassprod-fileupload/',
  imageCFUrl: 'https://dzzq213lrq0hh.cloudfront.net/',
  cognitoUserPoolId: "ap-southeast-2_JdL67lMKI",
  cognitoUserPoolClientId: "1f599n0l0g69dt4kblec9eh2pv",
  cognitoIdentityPoolId: "ap-southeast-2:51914a8c-de54-4583-876d-4811ab10f988",
  cognitoDomainUrl:
    'fp-prod.auth.ap-southeast-2.amazoncognito.com',
  cognitoRedirectUrl: 'https://portal.fitnesspassport.com.au/login',
  AzureADIndetityProviderName: 'AzureAD',
  logoutUrl: 'https://portal.fitnesspassport.com.au/login',
  devcycleKey: 'dvc_client_86060b71_3cf1_4aee_9668_9846f7c58c62_6693d02',
  sentryDSN:
    'https://f405ff3c6880ba0ca326d7c967e647bb@o4507982156726272.ingest.us.sentry.io/4508006333808640',
  sentryTracesSampleRate: 0.1,
  sentryEnvironment: 'production', // mapping to match BE
  datadog: {
    enabled: true,
    trackUserInteractions: true,
    applicationId: '9804e176-b1e6-4008-b513-c98c213b997c',
    clientToken: 'pub5067f7f648eec5ef544caff5f6811345',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.eu',
    service: 'legacyfrontend',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    defaultPrivacyLevel: 'mask-user-input' as const,
    env: 'production',
  },
};
