<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Member Details</h4>
<div class="mt-lg-4">
    <form>
        <div class="my-4">
            <div class="border-default rounded-10 border-2">
                <div class="tablecontainer">
                    <table mat-table matSort [dataSource]="dataSource" class="rounded-10">
                        <ng-container matColumnDef="lastName">
                            <th mat-header-cell *matHeaderCellDef>Last Name</th>
                            <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="firstName">
                            <th mat-header-cell *matHeaderCellDef>First Name</th>
                            <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="memberType">
                            <th mat-header-cell *matHeaderCellDef>Member Type</th>
                            <td mat-cell *matCellDef="let element"> {{element.MemberType.Name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="memberStatus">
                            <th mat-header-cell *matHeaderCellDef>Member Status</th>
                            <td mat-cell *matCellDef="let element"> {{element.MemberStatus.Name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="MembershipStatus">
                            <th mat-header-cell *matHeaderCellDef>Membership Status</th>
                            <td mat-cell *matCellDef="let element"> {{MembershipStatus}} </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element;let i=index">
                                <a class="px-2" (click)="editMember(i)">
                                    <i class="fas fa-pen" title="Edit personal details."></i>
                                </a>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </div>
            <!-- Reference to modal Edit Personal Detail -->
            <div class="py-3" *ngIf="isActivePrimaryMember">
                <button class="px-3 py-2 btn btn-primary" type="button" (click)="addMember()"><i
                        class="fas fa-plus-circle mr-2"></i>Add Members</button>
            </div>
        </div>
    </form>
</div>
<h4 class="font-weight-normal pb-2 text-primary text-center text-lg-left">Membership Details</h4>
<div class="row">
    <div class="col-md-3 py-3" *ngIf="membershipPackageTypes != null">
        <div class="card-mbshiptype bg-primary-gradient">
            <i class="fas fa-user-tie"></i>
            <p class="membership-type">{{ membershipPackageName }}</p>
        </div>
    </div>
    <div class="col-md-9 py-3 d-flex align-items-center">
        <button *ngIf="isActivePrimaryMember" (click)="OnChangeMembershipPackage()"
            class="px-3 py-2 btn bg-white border-primary text-primary" type="button">
            <i class="fas fa-pen mr-2"></i>Change Membership Package</button>
    </div>
</div>
<app-google-map-page-view #googlemap [isAustralia]="isAustralia"></app-google-map-page-view>