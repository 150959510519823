<div class="title-wrapper d-none d-md-block">
  <div class="container">
    <div class="row">
      <h1>Membership Registration</h1>
    </div>
  </div>
</div>
<div class="mobile-fixed-tabProgress-new">
  <div class="mx-auto tabProgress tabProgress-new w-75 d-flex mt-md-3">
    <div class="flex-fill text-center">
      <div class="card-icon rounded-circle mx-auto btn rounded-border-new"
        [ngClass]="{'bg-primary': stepper.selectedIndex == 0,submitted: employerCompleted }"
        (click)="signuptabselection('0')">
        <i *ngIf="employerCompleted" class="fas fa-check text-light"></i>
      </div>
      <div class="card-title-wrap">
        <p class="card-text mb-3" [ngClass]="stepper.selectedIndex == 0 ? 'text-border-bootom' : ''">
          Employer Details
        </p>
      </div>
    </div>
    <div class="flex-fill text-center">
      <div class="card-icon rounded-circle mx-auto btn rounded-border-new"
        [ngClass]="{'bg-primary': stepper.selectedIndex == 1,submitted: personalCompleted }"
        (click)="signuptabselection('1')">
        <i *ngIf="personalCompleted" class="fas fa-check text-light"></i>
      </div>
      <div class="card-title-wrap">
        <p class="card-text mb-3" [ngClass]="stepper.selectedIndex == 1 ? 'text-border-bootom' : ''">
          Personal Details
        </p>
      </div>
    </div>
    <div class="flex-fill text-center">
      <div class="card-icon rounded-circle mx-auto btn rounded-border-new"
        [ngClass]="{'bg-primary': stepper.selectedIndex == 2,submitted: packageCompleted }"
        (click)="signuptabselection('2')">
        <i *ngIf="packageCompleted" class="fas fa-check text-light"></i>
      </div>
      <div class="card-title-wrap">
        <p class="card-text mb-3" [ngClass]="stepper.selectedIndex == 2 ? 'text-border-bootom' : ''">
          Select Package
        </p>
      </div>
    </div>
    <div class="flex-fill text-center">
      <div class="card-icon rounded-circle mx-auto btn rounded-border-new"
        [ngClass]="{'bg-primary': stepper.selectedIndex == 3,submitted: paymentCompleted }"
        (click)="signuptabselection('3')">
        <i *ngIf="paymentCompleted" class="fas fa-check text-light"></i>
      </div>
      <div class="card-title-wrap">
        <p class="card-text mb-3" [ngClass]="stepper.selectedIndex == 3 ? 'text-border-bootom' : ''">
          Payment Details
        </p>
      </div>
    </div>
  </div>
</div>

<div class="my-md-5 horizontal-stepper-new">
  <mat-horizontal-stepper (selectionChange)="onStepperSelectedChange()" hide-header #stepper>
    <mat-step #stepper1>
      <employer-details-new [data]="data" (isCompleteForm)="isCompleteForm($event)" #employerInfo>
      </employer-details-new>
    </mat-step>
    <mat-step #stepper2>
      <personal-details-new [data]="data" (isCompleteForm)="isCompleteForm($event)" #personalInfo>
      </personal-details-new>
    </mat-step>
    <mat-step #stepper3>
      <select-package-page-component [data]="data" (isCompleteForm)="isCompleteForm($event)" #selectPackagePageInfo>
      </select-package-page-component>
    </mat-step>
    <mat-step #stepper4>
      <payment-details [data]="data" (isCompleteForm)="isCompleteForm($event)" #paymentInfo></payment-details>
    </mat-step>
  </mat-horizontal-stepper>
</div>

<!-- Terms and Conditions Modal -->

<div class="modal fade" #termsConditionsModalctrl id="termsConditionsModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true" #dlgTermsCons data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <ng-template #dlgTermsConsContent let-modal>   
        <div class="{{fadeMainTerm ? 'main-term-test-fade' : 'main-term-test-show'}}">
          <div class="modal-header" id="modalheader">
            <h5 class="modal-title" id="exampleModalLabel" ngbAutofocus>Terms and Conditions</h5>
          </div>
          <div class="modal-body">
            <div class="fpscrolly py-3" id="fp-termscondText" [style.height]="heightTCText" (scroll)="onScroll($event)">
              <div [innerHtml]="termsAndConsContent" id="fp-tcContent"></div>
              <div id="fp-tcEndContent">&nbsp;</div>
            </div>
            <div class="position-relative pt-4" id="chkTC">
              <span class="font-weight-bold" style="color: red; text-align: center;">Please scroll to the bottom and read
                all the Terms and Conditions.<br />Please click Next to proceed.</span>
            </div>
            <div class="py-4 d-flex justify-content-center" id="btnTC">
              <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                data-dismiss="modal" (click)="termModalBack(modal)">
                <i class="fas fa-arrow-circle-left mr-2"></i>Back</button>
              <div class="tooltip">
                <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
                  data-dismiss="modal" [disabled]="disablechkTermCons" (click)="showKeyTermsAndConditions()">
                  Next&nbsp;<i class="fas fa-arrow-circle-right mr-2"></i></button>                
              </div>
            </div>
          </div>
        </div>         
      </ng-template>
    </div>
  </div>
</div>

<!-- Key Terms and Conditions Modal -->

<div class="modal fade" #keyTermsConditionsModalctrl id="termsConditionsModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true" #dlgKeyTermsCons data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <ng-template #dlgKeyTermsConsContent let-modal>
        <div class="modal-header" id="keyModalheader">
          <h5 class="modal-title" id="exampleModalLabel" ngbAutofocus>Key Terms and Conditions</h5>
        </div>
        <div class="modal-body">
          <div class="fpscrolly py-3" id="fp-keyTermscondText" [style.height]="heightKeyTCText"
            (scroll)="keyTermOnScroll($event)">
            <div [innerHtml]="keyTermsAndConsContent" id="fp-KeytcContent"></div>
            <div id="fp-KeytcEndContent">&nbsp;</div>
          </div>
          <div class="position-relative pt-4" id="keyChkTC">
            <span class="font-weight-bold" style="color: red; text-align: center;">I have read and accepted the terms
              and conditions</span>
            <div class="d-inline-block ml-3">
              <div class="custom-control custom-checkbox">
                <input [disabled]="disablechkKeyTermCons" type="checkbox" class="custom-control-input"
                  id="chkKeyTermsCons" #chkKeyTermsCons [checked]="isKeyTermsAndConsAccepted"
                  (change)="isKeyTermsAndConsAccepted = $event.target.checked">
                <label class="custom-control-label" for="chkKeyTermsCons">Yes</label>
              </div>
            </div>
          </div>
          <div class="py-4 d-flex justify-content-center" id="keyBtnTC">
            <button class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button"
              data-dismiss="modal" (click)="keyTermModalBack(modal)">
              <i class="fas fa-arrow-circle-left mr-2"></i>Back</button>
            <div class="tooltip">
              <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" [disabled]="!isKeyTermsAndConsAccepted"
                data-dismiss="modal" data-target="dlgKeyTermsConsContent" (click)="btnSubmit_Click();">
                Submit</button>             
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div class="modal fade" #declarationModalctrl id="declarationModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Declaration</h5>
      </div>
      <div class="modal-body py-4">
        <p>I,
          &lt;MEMBER NAME&gt;
          <!--{{memberShipCardsSelected.FirstName + ' ' +
                    memberShipCardsSelected.LastName}}-->,
          do solemnly and sincerely declare that I have undertaken an
          extensive
          search, and
          cannot find the Fitness Passport card(s) with which I was issued.
        </p>
        <p>I understand that if the lost card is found that it will not be able to be used and will be
          destroyed immediately.</p>
        <p>I understand that a breach of this declaration may result in a financial penalty consistent with
          the penalties listed in the terms and conditions (Hyperlink to the FP T & C's page -
          <a class="color-link" href="https://www.fitnesspassport.com.au/termsandconditions"
            target="_blank">https://www.fitnesspassport.com.au/termsandconditions</a>)
          of
          Fitness Passport membership that I agreed to when I initially joined the program.
        </p>
        <p>I understand that a person who intentionally makes a false statement in a statutory declaration is
          guilty of an offence under section 11 of the Statutory Declarations Act 1959, and I believe that
          the statements in this declaration are true in every particular.</p>
        <p>I understand that $10 will be charged for each replacement card</p>
        <p>Declared on
          &lt;DATE AND TIMESTAMP&gt;
          <!--{{currentDate.getTime() | date: 'dd/MM/yyyy HH:mm'}}-->
        </p>
        <div class="py-4 d-flex justify-content-center">
          <button id="ms_cards_newcard_btn_close"
            class="px-2 px-lg-5 py-2 btn bg-white border-primary text-primary mr-3" type="button" data-dismiss="modal">
            <i class="fas fa-arrow-circle-left mr-2"></i>Back</button>
          <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button">
            Accept</button>
        </div>
      </div>
    </div>
  </div>
</div>