import { Region, State, Suburb } from '.';

//Author: Da Do
//Description: Entity for Postcode
export class Postcode {
    PostcodeId: number;
    Code: string;
    IsActive: boolean;
    Suburb: Suburb[];

    SuburbId: number;
    State: State | null;
    StateId: number;
    RegionId: number;
    Region: Region | null;
}