import { Input, forwardRef, Directive } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR, AbstractControl } from '@angular/forms';

export function MakeProvider(type : any){
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => type),
    multi: true
  };
}

@Directive()
export abstract class BaseComponent<T> implements ControlValueAccessor {
  @Input() control: AbstractControl;
  @Input() name: string;
  @Input() mandatory: boolean;
  @Input() flagValidation: boolean;

  constructor() { }

  checkValidate() {
    const isBool: boolean = this.control.invalid &&
      (
        this.control.touched ||
        this.control.dirty ||
        (this.flagValidation == undefined ? false : this.flagValidation)
      );
    return isBool;
  }

  getName(control: AbstractControl): string | null {
    let group = <UntypedFormGroup>control.parent;
    if (!group) {
      return null;
    }
    let name: string;
    Object.keys(group.controls).forEach(key => {
      let childControl = group.get(key);
      if (childControl !== control) {
        return;
      }
      name = key;
    });
    return name;
  }

  _value: T;
    get value(): T { return this._value; };
    set value(v: T) {
      if (v !== this._value) {
        this._value = v;
        this.onChange(v);
      }
    }

    writeValue(value: T) {
      console.log('write value', value); 
      this._value = value;
       this.onChange(value);
    }

    onChange = (_) => {};
    onTouched = () => {};
    registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
    registerOnTouched(fn: () => void): void { this.onTouched = fn; }

}
