<div [formGroup]="form">
    <ng-template #requiredFieldMsgTmpl let-ctrl="control" let-ctrlName="fieldName" let-validateOnTouch="validateOnTouch || false">
        <div *ngIf="!isControlValid(ctrl, validateOnTouch)">
            <small *ngIf="ctrl.errors['required']" class="invalid-feedback d-block">
                {{StringHelper.format(CommonString.FIELD_REQUIRED, ctrlName)}}</small>
        </div>
    </ng-template>
    <div class="p-0 m-0" formArrayName="MedicalAnswers">
        <div *ngFor="let answer of MedicalAnswersCtrl?.controls;let i = index; last as isLast">
            <div class="form-group row" [formGroupName]="i">
                <label class="col-md-8 col-lg-6 col-form-label d-flex"><span class="pr-3">{{i+1}}</span>
                    <span>{{answer.get('MedicalQuestion').value?.Question}}<span class="text-danger">*</span></span></label>
                <div class="col-md-2">
                    <div class="flex-column w-100">
                        <div class="d-flex">
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="{{timeStamp}}_{{answer.get('MemberMedicalQuestionId').value}}Y"
                                    class="custom-control-input" formControlName="Answer" [value]="true"
                                    (change)="displayNotification($event)">
                                <label class="custom-control-label"
                                    for="{{timeStamp}}_{{answer.get('MemberMedicalQuestionId').value}}Y">Yes</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="{{timeStamp}}_{{answer.get('MemberMedicalQuestionId').value}}N"
                                    class="custom-control-input" formControlName="Answer" [value]="false"
                                    (change)="displayNotification($event)">
                                <label class="custom-control-label"
                                    for="{{timeStamp}}_{{answer.get('MemberMedicalQuestionId').value}}N">No</label>
                            </div>
                        </div>
                        <ng-container
                            *ngTemplateOutlet="requiredFieldMsgTmpl;context:{control: answer.get('Answer'), fieldName: 'Question ' + (i + 1)}">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="medicalQuestionNotifyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <ng-container *ngTemplateOutlet="medQuestionNotification"></ng-container>
                    <ng-template #medQuestionNotification let-modal>
                        <div class="modal-body py-3 fpscrolly height100">
                            <p>
                                If you answered 'Yes' to any of the medical questions, please consult a GP or
                                appropriate Allied Health Professional to seek clearance/approval to undertake physical
                                activity/exercise in participating facilities.
                            </p>
                            <p>
                                If a future change in your health, medical condition or physical capacity would lead you to
                                respond 'Yes' to any of the questions, please consult your GP or Allied Health
                                Professional before undertaking further physical activity or exercise in any
                                participating facilities.
                            </p>

                            <div class="pt-4 d-flex justify-content-center">
                                <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" data-dismiss="modal"
                                    (click)="modal.close()">
                                    OK
                                </button>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>