import { Address } from './addressmodel';
import { BankAccount } from './bank-account.model';
import { Country } from './country.model';
import { FacilityContact } from './facility-contact.model';
import { FacilityStaffedHour } from './facility-staffed-hour.model';
import { FacilityStatus } from './facility-status.model';
import { FacilityType } from './facility-type.model';
import { FacilityCategory } from './facility-category.model';
import { Postcode } from './postcodemodel';
import { Product } from './product.model';
import { Region } from './region.model';
import { Service, ServiceDetails } from './service.model';
import { State } from './state.model';
import { Suburb } from './suburb.model';
import { UploadFile } from './upload-file.model';
import { FacilityContract } from './facility-contract.model';
import { CommonResponse } from './commonresponsemodel';
import { Bpay } from './b-pay.model';
import { Cheque } from './cheque.model';
import { ParentGroup } from './parent-group.model';
import { City } from './city.model';
import { FacilityActivityType } from './facility-activity-type.model';

export class Facility {
    FacilityId: number;
    Name: string;
    FacilityCountryName: string;
    FacilityDesc: string;
    Code: string;
    CompanyName: string;
    FacilityOwnerGroup: string;
    IsHeadOffice: boolean;
    FacilityIdParent?: number;
    FacilityParentName: string;
    ParentGroup: ParentGroup;
    FacilityTypeId: number;
    FinanceSystemId: string;
    FacilityType: FacilityType;
    FacilityCategoryId: number;
    FacilityCategory: FacilityCategory;
    WebsiteUrl: string;
    FacebookUrl: string;
    FacilityPhoneNumber: string;
    FacilityStartDate: string;
    IsCriticalFacility: boolean;
    IsFraudRisk: boolean;
    Abn: string;
    FacilityStatusId: number;
    FacilityStatus: FacilityStatus;
    IsAccessCard?: boolean;
    AccessCardFee?: number;
    SoftwarePackage: string;
    Is24Hour?: boolean;
    VisitDuplicationWindow?: number;
    AdditionalInfo?: string;
    //IsStaffedHour: boolean;
    FacilityStaffedHourId?: number;
    FacilityStaffedHour: FacilityStaffedHour;
    AddressId: number;
    Address: Address;
    PaymentMethodId: number;
    PaymentFrequencyId?: number;
    PaymentPreferenceId?: number;
    PaymentDateId: number;
    BankAccountId?: number;
    BankAccount: BankAccount;
    Bpay: Bpay;
    Cheque: Cheque;
    ListFacilityContact: FacilityContact[];
    NoNewMembersDate: Date | string;
    IsAvailableForAllEmployers: boolean;

    Services: Service[];
    Products: Product[];
    Activities: FacilityActivityType[];

    SuburbId: number;
    Suburb: Suburb;
    PostcodeId: number;
    Postcode: Postcode;
    State: State;
    City: City;
    Country: Country;
    RegionId: number;
    Region: Region;
    FacilityContract: FacilityContract;
    IsAllowToSeePaymentCalculation: boolean;
    PaymentLogs: any;
}

export class FacilityMap {
    FacilityId: number;
    Name: string;
    Address: Address;
    Suburb: Suburb;
    Postcode: Postcode;
    State: State;
    Country: Country;
    FacilityPhoneNumber: string;
    IsAccessCard?: boolean;
    AccessCardFee?: number;
    Is24Hour?: boolean;
    IsStaffedHour: boolean;
    FacilityStaffedHour: FacilityStaffedHour;
    WebsiteUrl: string;
    FacilityStatus: FacilityStatus;
    Region: Region;
    IconUrl: string;

    SameLocation?: boolean;
    LocationChecked?: boolean;

    Services: Service[];
    ServicesName?: string;

    constructor(FacilityId: number, Name: string, Address: Address, Suburb: Suburb, Postcode: Postcode,
        State: State, Country: Country, FacilityPhoneNumber: string, IsAccessCard: boolean, AccessCardFee: number,
        Is24Hour: boolean, IsStaffedHour: boolean, FacilityStaffedHour: FacilityStaffedHour, WebsiteUrl: string,
        FacilityStatus: FacilityStatus,
        Region: Region, IconUrl: string, Services: Service[], ServicesName?: string) {
        this.FacilityId = FacilityId;
        this.Name = Name;
        this.Address = Address;
        this.Suburb = Suburb;
        this.Postcode = Postcode;
        this.State = State;
        this.Country = Country;
        this.FacilityPhoneNumber = FacilityPhoneNumber;
        this.IsAccessCard = IsAccessCard;
        this.AccessCardFee = AccessCardFee;
        this.Is24Hour = Is24Hour;
        this.IsStaffedHour = IsStaffedHour;
        this.FacilityStaffedHour = FacilityStaffedHour;
        this.WebsiteUrl = WebsiteUrl;
        this.FacilityStatus = FacilityStatus;
        this.Region = Region;
        this.IconUrl = IconUrl;
        this.Services = Services;
        this.ServicesName = ServicesName;
    }
}

export class FacilityUpdateDetails extends Facility {
    Services: ServiceDetails[];
    Products: Product[];
    UpdatePhotoActivity: UpdatePhotoActivity;
    SaveDocument: SaveDocument;
    MembershipAccesses: MembershipAccess[];
}

export class FacilityPhoto extends UploadFile {
    IsActive: boolean;
}

export class UpdatePhotoActivity {
    RemovedPhotoIds: number[];
    NewOrUpdatedPhotos: FacilityPhoto[];
}

export class SaveDocument {
    RemoveDocumentIds: number[];
    NewOrUpdateDocuments: FacilityDocumentViewModel[];
}
export class MembershipAccess {
    MembershipPackageId: number;
    AllowAccess: boolean;
}

export class FacilityId {
    Id: number;
    Name: string;
}

export class FacilityDocumentViewModel {
    FacilityId?: number;
    FileName?: string;
    DocumentLocation?: string;
    Id?: number;
    Name?: string;
    IsActive?: boolean;
}

export class FacilitySearchResultRecord extends CommonResponse {
    FacilityId: number;
    Name: string;
    FacilityPhoneNumber: string;
    FacilityStaffedHour: FacilityStaffedHour;
    FacilityStatus: CommonResponse;
    FacilityStatusId: number;
    FacilityType: CommonResponse;
    FacilityTypeId: number;
    FacilityCategory: CommonResponse;
    FacilityCategoryId: number;
    Is24Hour: boolean;
    IsAccessCard: boolean;
    AccessCardFee: null;
    WebsiteUrl: string;
    Address: Address;
    Suburb: CommonResponse;
    SuburbId: number;
    Postcode: CommonResponse;
    PostcodeId: number;
    State: CommonResponse;
    StateId: number;
    Country: CommonResponse;
    CountryId: number;
    Region: CommonResponse;
    RegionId: number;
    Services: Service[];
}