import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIConstant } from 'src/app/constant';
import { DataResult, Package, PackageDetails, SearchPaginationRequest, SearchPaginationResult, PackageSearchResultRecord } from '../models';
import { HttpDAO } from './httpdao.service';

@Injectable({
    providedIn: 'root'
})
export class PackageService {
    private httpDao: HttpDAO | null;

    constructor(httpClient: HttpClient) {
        this.httpDao = new HttpDAO(httpClient);
    }

    getDetails(id: number): Observable<DataResult<Package>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_PACKAGE_DETAILS_BY_ID + id);
    }

    getDetailsByName(name: string): Observable<DataResult<Package>> {
        return this.httpDao.getSingleData(APIConstant.API_GET_PACKAGE_DETAILS_BY_NAME + encodeURIComponent(name));
    }

    save(data: PackageDetails): Observable<DataResult<number>> {
        return this.httpDao.postData(APIConstant.API_SAVE_PACKAGE, data);
    }

    search(request: SearchPaginationRequest): Observable<DataResult<SearchPaginationResult<PackageSearchResultRecord>>> {
        return this.httpDao.postData(APIConstant.API_SEARCH_PACKAGES, request);
    }

    searchbyname(request: SearchPaginationRequest): Observable<DataResult<SearchPaginationResult<PackageSearchResultRecord>>> {
        return this.httpDao.postData(APIConstant.API_SEARCH_PACKAGES_NAME, request);
    }
}
