<form [formGroup]="pageForm">
  <div class="row">
    <div class="col-sm-7">
      <div class="form-group row">
        <label for="txtPageHeading" class="col-md-3 col-form-label">Page
          Heading<span class="text-danger">*</span></label>
        <div class="col-md-9">
          <div class="d-flex flex-column w-100">
            <input type="text" formControlName="heading" class="form-control" id="txtPageHeading" autocomplete="off"
              maxlength="200" [ngClass]="checkValidate('heading') ? 'is-invalid':''">
            <small *ngIf="checkValidate('heading')" class="invalid-feedback d-block">
              Page Heading is required
            </small>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="txtLinkName" class="col-md-3 col-form-label">Link Name<span class="text-danger">*</span></label>
        <div class="col-md-9">
          <div class="d-flex flex-column w-100">
            <div class="input-group"
              [ngClass]="{'is-invalid': checkValidate('linkName'), 'disabled': employerPageDisabled}"
              [class.focused]="parentFocus==5">
              <div class="input-group-prepend pr-0">
                <div class="input-group-text p-0">
                  {{ controlPageFG('link').value + '/' }}
                </div>
              </div>
              <input type="text" class="form-control pl-0" id="txtLinkName" formControlName="linkName"
                autocomplete="off" maxlength="100" (focusout)="checkDuplicateLinkName()"
                (keydown.space)="$event.preventDefault()" (blur)="onBlurParent(0)" (focus)="onFocusParent(5)">
              <div class="input-group-append">
                <div class="input-group-text">
                  <mat-spinner [diameter]="14" *ngIf="spinnerLinkName">
                  </mat-spinner>
                </div>
              </div>
            </div>
            <div *ngIf="controlPageFG('linkName').errors != null">
              <small
                *ngIf="checkValidate('linkName') && controlPageFG('linkName').errors['required']; else checkDuplicate"
                class="invalid-feedback d-block">
                Link Name is required
              </small>
              <ng-template #checkDuplicate>
                <small *ngIf="controlPageFG('linkName').errors['duplicate']" class="invalid-feedback d-block">
                  The Link Name already exists
                </small>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-3 col-form-label">Page Active</label>
        <div class="col-md-9">
          <div class="d-flex flex-column w-100">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" formControlName="isActive" class="custom-control-input" id="chkActiveYes">
              <label class="custom-control-label" for="chkActiveYes">Yes</label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-form-label">Show Details
          on
          Employer Page</label>
        <div class="row">
          <div class="col-md-9 offset-md-3">
            <div class="d-flex flex-column w-100">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="chkEMSignup" formControlName="isMemberSignup">
                <label class="custom-control-label" for="chkEMSignup">Enable
                  Member
                  Sign-Up</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="chkSAFacilities"
                  formControlName="isAvailableFacilities">
                <label class="custom-control-label" for="chkSAFacilities">Show
                  Available
                  Facilities</label>
              </div>
              <div class="custom-control custom-checkbox">
                <input type="checkbox" formControlName="isPricingPackages" class="custom-control-input"
                  id="chkSPackgPricing">
                <label class="custom-control-label" for="chkSPackgPricing">Show
                  packages
                  and pricing</label>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-sm-5">
      <div class="fp-profile d-flex justify-content-center">
        <div class="w-50">
          <div
            *ngIf="controlPageFG('logo').value == null || controlPageFG('logo').value.location == null || controlPageFG('logo').value.location == ''; else hasImage"
            class="bg-secondary fp-profilephoto d-flex justify-content-center align-items-center mx-auto">
            <i class="fa fa-image fa-2x text-secondary"></i>
          </div>
          <ng-template #hasImage>
            <div class="container">
              <img
                [attr.src]="controlPageFG('logo').value.data == null ? controlPageFG('logo').value.location : controlPageFG('logo').value.data"
                [attr.alt]="" class="img-fluid">
              <div class="overlay" [hidden]="employerPageDisabled">
                <a class="cursordisplay position-absolute position-topright" title="Remove logo"
                  (click)="this.controlPageFG('logo').patchValue(null)">
                  <i class="text-danger fas fa-times fa-2x-ex"></i>
                </a>
              </div>
            </div>
          </ng-template>
          <div class="custom-file mt-2">
            <div>
              <app-fp-file-upload [filterFile]="'.png, .jpg, .jpeg'" #txtSignedContract
                (UploadCompleted)="LogoEmpPage_UploadCompleted($event)" [isReadonly]="employerPageDisabled"
                buttonlabel="Attach Logo" [ngClassLabel]="employerPageDisabled ? 'employerPageDisabled' : ''"
                (fileSelected)="_fileSelected($event)">
              </app-fp-file-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="txtIntroHtml" class="col-md-3 col-lg-2 col-form-label">Page Text 1
      (Introduction)</label>
    <div class="col-md-9 col-lg-10">
      <ckeditor name="txtIntroHtml"[editor]="Editor" [disabled] = "employerPageDisabled" formControlName="introduction"
        [config]="ckeConfig">
      </ckeditor>
    </div>
  </div>
  <div class="form-group row">
    <label for="txtEligibilityHtml" class="col-md-3 col-lg-2 col-form-label">Page Text 2
      (Eligibility)</label>
    <div class="col-md-9 col-lg-10">
      <ckeditor name="txtEligibilityHtml"[editor]="Editor" [disabled] = "employerPageDisabled" formControlName="eligibility"
        [config]="ckeConfig">
      </ckeditor>
    </div>
  </div>
  <div class="form-group row">
    <label for="txtAdditionalHtml" class="col-md-3 col-lg-2 col-form-label">Page Text 3
      (Need More Details?)</label>
    <div class="col-md-9 col-lg-10">
      <ckeditor name="txtAdditionalHtml"[editor]="Editor" [disabled] = "employerPageDisabled" formControlName="additionInfo" #article
        [config]="ckeConfig">
      </ckeditor>
    </div>
  </div>
</form>