<div [formGroup]="form">
    <ngb-accordion [destroyOnHide]="false" activeIds="pnlFamilyMembers">
        <ngb-panel id="pnlFamilyMembers">
            <ng-template ngbPanelTitle>
                <i class="fas fa-check icon-fp"></i>
                Member Details
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <p class="pb-2" *ngIf="isSignup()">Add additional family members here. If you do not wish add family members, please click on the 'Next' button to proceed to the next screen.</p>
                <div class="border-default rounded-10 border-2">
                    <div class="tablecontainer">
                        <table mat-table matSort [dataSource]="dataSource" class="rounded-10">
                            <!-- Name Column -->
                            <ng-container matColumnDef="FirstName">
                                <th mat-header-cell *matHeaderCellDef> First Name
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="LastName">
                                <th mat-header-cell *matHeaderCellDef> Last Name
                                </th>
                                <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="MemberType">
                                <th mat-header-cell *matHeaderCellDef> Member Type </th>
                                <td mat-cell *matCellDef="let element"> {{element.MemberIdPrimary == null ? 'Primary' :
                                    element.MemberType?.Name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="MemberStatusId">
                                <th mat-header-cell *matHeaderCellDef> Member Status </th>
                                <td mat-cell *matCellDef="let element">
                                    {{changeMemStatusIdToString(element.MemberStatusId)}} </td>
                            </ng-container>

                            <!-- <ng-container matColumnDef="MemberStatusId">
                                <th mat-header-cell *matHeaderCellDef> Membership Status </th>
                                <td mat-cell *matCellDef="let element">
                                    {{changeMemStatusIdToString(element.MemberStatusId)}} </td>
                            </ng-container> -->

                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef width="5%"></th>
                                <td mat-cell *matCellDef="let element; let i = index;">
                                    <a class="px-2" *ngIf="element.MemberIdPrimary == null; else editFamilyMember"
                                        (click)="primaryMemberEditIconClick.emit()">
                                        <i class="fas fa-pen"></i>
                                    </a>
                                    <ng-template #editFamilyMember>
                                        <a class="px-2" (click)="editMember(i - 1)">
                                            <i class="fas fa-pen"></i>
                                        </a>
                                    </ng-template>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </div>
                <div *ngIf="DisplayAddFamilyMemberButton" class="pt-4">
                    <button class="px-4 py-2 btn btn-primary text-transform-none" type="button" (click)="AddMember()">
                        <i class="fas fa-plus-circle mr-2"></i>Add Family Member</button>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>