import { APIConstant } from "@fp/constant";
import { HttpDAO } from "./httpdao.service";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { DataResult, SurveyTemplate, SurveyResponse, ResultModel} from "@fp/models";
import { CommonService } from "./common.service";
import { Injectable } from "@angular/core";
import { PreSignupFacilityData } from "@fp/models/map-facility-data";

@Injectable({
    providedIn: "root"
  })
export class SurveyService {
    constructor(
        private httpDao: HttpDAO,
        private cmsrv: CommonService
    ) { }

    GetActiveSurveyTemplateByType(typeId: number): Observable<DataResult<SurveyTemplate>> {
        return this.httpDao.getSingleData(
                APIConstant.API_GET_ACTIVE_SURVEY_BY_TYPE + encodeURIComponent(typeId.toString())
            )
            .pipe(
                map((value: any) => {
                    const dectypted_data = this.cmsrv.D_FP_ResponseData(value);
                    const rdata: DataResult<SurveyTemplate> = JSON.parse(dectypted_data);
                    if (rdata.Success && rdata.Data) {
                    }
                    return rdata;
                })
            );
    }

    GetPreMemberSignupSurveyFacilityDetails(country: string, pageNumber: number): Observable<DataResult<PreSignupFacilityData>> {
        return this.httpDao.getSingleData(
                APIConstant.API_GET_FACILITY_DATA_FOR_MEMBER_SIGNUP_SURVEY(country, pageNumber)
            )
            .pipe(
                map((value: any) => {
                    const dectypted_data = this.cmsrv.D_FP_ResponseData(value);
                    const rdata: DataResult<PreSignupFacilityData> = JSON.parse(dectypted_data);
                    if (rdata.Success && rdata.Data) {
                    }
                    return rdata;
                })
            );
    }

    saveSurveyResponse(response: SurveyResponse): Observable<ResultModel> {
        const encrypteddata = this.cmsrv.E_FP_RequestData(JSON.stringify(response));
        const encryptedtoken = this.cmsrv.E_FP_RequestData(this.cmsrv.GetToken());
        return this.httpDao.postData(APIConstant.API_SAVE_SURVEY_RESPONSE, {
          Data: encrypteddata,
          Header: encryptedtoken,
        });
      }
}