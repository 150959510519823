<div [formGroup]="form">
    <ngb-accordion [destroyOnHide]="false" activeIds="pnlFacilityServices, pnlFacilityProducts">
        <ngb-panel id="pnlFacilityServices">
            <ng-template ngbPanelTitle>
                <i class="icon-fp" [ngClass]="{'fas fa-check': true}"></i>
                Facility Services
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-facility-services-list [form]="form.get('ServicesList')" [data]="data" [loadOnDemand]="loadOnDemand"></app-facility-services-list>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="pnlFacilityProducts">
            <ng-template ngbPanelTitle>
                Facility Products
                <i class="icon-fp" [ngClass]="{'fas fa-check': true}"></i>
                <i class="fas fas fa-chevron-down"></i>
                <i class="fas fas fa-chevron-up"></i>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-facility-products-list [form]="form.get('ProductsList')" [data]="data" [loadOnDemand]="loadOnDemand"></app-facility-products-list>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>