import { Injectable } from '@angular/core';
import { HttpDAO } from './httpdao.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataResult } from '@fp/models';
import { SystemParameters, UpdatedSystemParameter } from '@fp/models/system-parameters.model';
import { APIConstant } from '@fp/constant';

@Injectable({
  providedIn: 'root'
})
export class ManipulateSysParamsService {
  private httpDao: HttpDAO | null;

  constructor(httpClient: HttpClient) {
    this.httpDao = new HttpDAO(httpClient);
  }

  getSystemParameters(): Observable<DataResult<SystemParameters>> {
    return this.httpDao.getSingleData(APIConstant.API_GET_SYSTEM_PARAMETERS);
  }

  updateSystemParameters(updatedSysParam: UpdatedSystemParameter): Observable<UpdatedSystemParameter> {
    return this.httpDao.postData(APIConstant.API_POST_SYSTEM_PARAMETERS, updatedSysParam);
  }
}
