import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MemberService, CommonService } from "@fp/services";
import { DataResult, Member } from "@fp/models";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { MemberAdditionalNotes } from "@fp/models/MemberAdditionalNotes.model";
import { DateHelper, DataSourceHelper } from "@fp/helpers";
import { StorageKey } from "@fp/constant/storage-key";
import { MemberContainer } from "@fp/models/member-container.model";

@Component({
  selector: "app-membernotifications",
  templateUrl: "./membernotifications.component.html",
  styleUrls: ["./membernotifications.component.css"]
})
export class MembernotificationsComponent implements OnInit {
  displayedColumns: string[];
  dataSource: MatTableDataSource<MemberAdditionalNotes>;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private MemberSvc: MemberService, private commonSvc: CommonService, private cmsrv: CommonService) {}

  ngOnInit() {
    this.getNotifications();
    this.commonSvc.Header.title = "Notifications";
  }

  Invoke(source: Observable<any>, handleResultCallback: Function) {
    source
      .pipe(
        catchError(e => {
          throw e;
        })
      )
      .subscribe(res => {
        handleResultCallback(res);
      });
  }

  notificationResults: MemberAdditionalNotes[];
  getNotifications() {
    this.commonSvc.StartProgressBar();
    this.displayedColumns = ["CreateOrUpdateDate", "Note"];
    const userName = this.cmsrv.GetUser();
    
    const getContainer = localStorage.getItem(StorageKey.CONTAINER_OBJECT);
    if(getContainer != null) {
        let container = <MemberContainer>JSON.parse(getContainer);
        this.notificationResults = (<DataResult<Member>>JSON.parse(this.commonSvc.D_FP_AES256(container.Member))).Data.MemberNotifications;
        console.log(this.notificationResults);
    }
    if(this.notificationResults != null) {
      this.setNotifications(this.notificationResults);
    } else {
      this.Invoke(
        this.MemberSvc.getMemberNotification(userName),
        (result: DataResult<MemberAdditionalNotes[]>) => {
          this.setNotifications(result.Data);
        }
      );
    }
  }

  setNotifications(result: MemberAdditionalNotes[]) {
    //Looping over all the dates and converting to local dateTime before displaying in table
    for (let i = 0; i < result.length; i++) {
      result[i].CreateOrUpdateDate = this.ConvertToLocalTime(
        result[i].CreateOrUpdateDate
      );
    }
    this.dataSource = new MatTableDataSource<MemberAdditionalNotes>(
      result
    );
    this.dataSource.sortingDataAccessor =
      DataSourceHelper.localeLowerCaseSortingDataAccessor;
    this.dataSource.sort = this.sort;
    this.commonSvc.StopProgressBar();
  }

  //Change incoming time from B.E (UTC) to machine's local time
  private ConvertToLocalTime(d: Date) {
    const localdate: Date = new Date(d + "Z");
    return DateHelper.format(localdate, "DD-MMM-YYYY HH:mm:ss");
  }
}
