<div class="modal-header">
    <h5 class="modal-title">Add Family Member Details</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismissDialog()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body py-3 px-md-3 fpscrolly height100">
    <div class="tabFPTheme">
        <app-member-family-member-info [form]="form.get('FamilyMemberInfo')" [data]="data" #memberInfo></app-member-family-member-info>
        <div class="card-body pt-2 pt-lg-4 d-flex d-lg-block justify-content-between justify-content-md-start">
            <button class="px-2 px-lg-5 py-2 btn btn-primary" type="button" (click)="btnConfirm_Click()">
                <i class="fas fa-check-circle mr-2"></i>
                Confirm Member</button>
        </div>
    </div>

    <!-- TODO: remove after implementation completed -->
    <!-- #region DEBUG -->
    <div *ngIf="isDebugging" class="tabFPTheme mt-5">
        <pre>Request count: {{ data.requestQueueCount }}</pre>
        <pre>Form valid: {{ form.valid }}</pre>
        <ngb-accordion>
            <ngb-panel id="pnlFormJson">
                <ng-template ngbPanelTitle>
                    Form JSON
                    <i class="fas fas fa-chevron-down"></i>
                    <i class="fas fas fa-chevron-up"></i>
                </ng-template>
                <ng-template ngbPanelContent>
                    <pre>{{ JSON.stringify(form.value, null, 4) }}</pre>
                </ng-template>
            </ngb-panel>
            <ngb-panel>
                <ng-template ngbPanelTitle>
                    Data JSON
                    <i class="fas fas fa-chevron-down"></i>
                    <i class="fas fas fa-chevron-up"></i>
                </ng-template>
                <ng-template ngbPanelContent>
                    <pre>{{ JSON.stringify(data.model, null, 4) }}</pre>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
    <!-- #endregion DEBUG -->

</div>
