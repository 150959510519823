import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthenticationService } from "../../../services/authentication.service";
import { CommonService } from "../../../services/common.service";
import { MessageBox } from "src/app/services/common-dialog.service";
import { MatDialog } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { PatternConstant } from "../../../constant/patternconstant";
import { HttpClient } from "@angular/common/http";
import { HttpDAO } from "../../../services/httpdao.service";
import { Router } from "@angular/router";
import { MemberService } from "src/app/services/member.service";
import { UserService } from "src/app/services";
import { User } from "src/app/models";
import { StorageKey } from "src/app/constant";

@Component({
  selector: "app-changepassword",
  templateUrl: "./changepassword.component.html",
  styleUrls: ["./changepassword.component.css"],
})
export class ChangepasswordComponent implements OnInit, OnDestroy {
  changePasswordFormGroup: UntypedFormGroup;
  isLoadingResults = false;
  btnSubmitClick = false;
  httpdao: HttpDAO | null;
  password = "";
  confirmPassword = "";

  ngOnDestroy() {
    this.httpdao = null;
    this.password = null;
    this.confirmPassword = null;
  }

  constructor(
    private authService: AuthenticationService,
    private commonservice: CommonService,
    private dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private router: Router,
    private memberSvc: MemberService,
    private userSvc: UserService
  ) {}

  ngOnInit() {
    this.commonservice.Header.title = "Fitness Administration";
    this.httpdao = new HttpDAO(this.http);

    this.changePasswordFormGroup = this._formBuilder.group({
      txtPassword: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.CREATE_NEW_PASSWORD)],
      ],
      txtConfirmPassword: [
        "",
        [Validators.required, Validators.pattern(PatternConstant.CREATE_NEW_PASSWORD)],
      ],
    });
  }

  Save() {
    this.btnSubmitClick = true;
    if (
      this.changePasswordFormGroup.invalid ||
      this.confirmPassword != this.password
    ) {
      return;
    }
    this.isLoadingResults = true;
    const userPool = this.authService.getUserPool();
    const cognitoUser = this.authService.getUser(
      userPool,
      this.commonservice.Username
    );
    const authenticationDetails = this.authService.getAuthenticationDetails(
      this.commonservice.Username,
      this.commonservice.Password
    );

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (result) => {},
      onFailure: (err) => {
        MessageBox.ShowError(this.dialog, err.message);
        this.isLoadingResults = false;
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        var self = this;
        const newPassword = this.changePasswordFormGroup.get("txtPassword")
          .value;
        cognitoUser.completeNewPasswordChallenge(
          newPassword,
          requiredAttributes,
          {
            onSuccess: function (result) {
              const newAuthenticationDetails = self.authService.getAuthenticationDetails(
                self.commonservice.Username,
                newPassword
              );
              self.GetCognitoToken(cognitoUser, newAuthenticationDetails);
            },
            onFailure: function (err) {
              MessageBox.ShowError(self.dialog, err.message);
              self.isLoadingResults = false;
            },
          }
        );
      },
    });
  }

  private GetCognitoToken(cognitoUser, authenticationDetails) {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (result) => {
        console.log(result);
        this.authService.setSessionData(result);
        const uname = result.accessToken.payload.username;
        this.commonservice.SetUser(uname);

        //Author: Yen
        //Author: Da Do - Add get User Object to set it in the localStorage.
        this.userSvc.getByUsernameFirstLoginV2(uname).subscribe(
          (res) => {
            if (res.Data) {
              localStorage.setItem(
                StorageKey.USER_OBJECT,
                this.commonservice.E_FP_AES256(
                  JSON.stringify(res.Data.UserObject)
                )
              );
              if (res.Data.UserObject) {
                if (res.Data.UserObject.Country) {
                  this.commonservice.setUserCountry(res.Data.UserObject.Country);
                }
              }
            }
          },
          (err) => {
            console.error(err);
            this.isLoadingResults = false;
          },
          () => {
            this.router.navigate(["dashboard"]);
          }
        );
      },
      onFailure: (err) => {
        MessageBox.ShowError(this.dialog, err.message);
        this.isLoadingResults = false;
      },
    });
  }

  public IsInvalidControl(ctrlname) {
    return (
      (this.changePasswordFormGroup.get(ctrlname).invalid &&
        (this.changePasswordFormGroup.get(ctrlname).dirty ||
          this.changePasswordFormGroup.get(ctrlname).touched)) ||
      (this.btnSubmitClick &&
        this.changePasswordFormGroup.get(ctrlname).invalid)
    );
  }

  public IsValidConfirmPassword() {
    return this.confirmPassword == this.password;
  }

  getSpecialCharacters(): string {
    return "^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ \` + =";
  }
}
